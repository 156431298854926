import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Autocomplete,
    Chip,
    ClickAwayListener,
    InputAdornment,
    TextField,
    TextFieldProps,
    Tooltip
} from "@mui/material";
import i18next from "i18next";
import { AutocompleteTranslations } from "./objects/autocompleteTranslations";
import { Recipient } from "./objects/recipient";
import { AppState } from "../../../../Reducers/Reducers";

type Props = {
    size?: 'medium' | 'small',
    variant?: TextFieldProps['variant'],
    startAdornment?: JSX.Element,
    mainRecipient: Recipient | null,
    value: Recipient[],
    onChange: (value: Recipient[]) => void
}

export function MailTemplateCCRecipients(props: Props): JSX.Element {
    const { t } = useTranslation();
    const autocomplete = useSelector((state: AppState) => state.mailTemplate.others.autocomplete);
    const networkRecipients = useSelector((state: AppState) => state.mailTemplate.others.recipients);
    const [recipients, setRecipients] = useState<Recipient[]>([]);
    const [input, setInput] = useState('');
    const translations = autocomplete.state === 'success' ?
        autocomplete.data.translations :
        { action: {}, fields: {}, recipients: {} } as AutocompleteTranslations;

    const translateRecipient = (recipient: Recipient) => {
        if (recipient.type === 'variable') {
            const label = translations.recipients[recipient.value] ?? {};
            return label[i18next.language] ?? Object.values(label)[0] ?? recipient.value;
        }
        return recipient.value;
    };

    const onConfirm = () => {
        if (input.trim().length > 0) {
            props.onChange(
                props.value.concat([
                    {
                        type: 'static',
                        value: input,
                        empty: false
                    }
                ])
            );
            setInput('');
        }
    };

    useEffect(() => {
        if (networkRecipients.state === 'success') {
            const recipients = Object.keys(networkRecipients.data).map((variable): Recipient => ({
                type: 'variable',
                empty: !networkRecipients.data[variable],
                value: variable,
                info: networkRecipients.data[variable] ?? undefined
            }));
            setRecipients(recipients);
        }   
    }, [networkRecipients.state]);

    return (
        <ClickAwayListener onClickAway={onConfirm}>
            <Autocomplete
                size={props.size}
                options={recipients}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    <InputAdornment position="start">
                                        {props.startAdornment}
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                </>
                            )
                        }}
                        variant={props.variant}
                        value={input}
                        label={
                            !props.startAdornment ?
                                t<string>('shared.mail-template-cc-recipients') :
                                undefined
                        }
                        onChange={(event) => setInput(event.target.value)}
                    />
                )}
                loading={autocomplete.state === 'loading'}
                renderTags={(value, getTagProps) => value.map((item, index) => {
                    const { key, ...rest } = getTagProps({ index });
                    return (
                        <Tooltip key={key} title={item.info}>
                            <Chip {...rest} label={translateRecipient(item)} />
                        </Tooltip>
                    );
                })}
                getOptionDisabled={(option) => option.empty}
                getOptionLabel={(option) => {
                    const label = translateRecipient(option);
                    if (option.empty) {
                        return t('menu.email-history.empty-recipient', { recipient: label });
                    }
                    return label;
                }}
                value={props.value}
                isOptionEqualToValue={(a, b) => a.value === b.value}
                onChange={(_, value) => props.onChange(value)}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        onConfirm();
                    }
                }}
                filterOptions={(options) => {
                    return options.filter((item) => {
                        return !props.value.map((item) => {
                            return item.value;
                        }).includes(item.value) &&
                    item.value !== props.mainRecipient?.value;
                    });
                }}
                sx={{
                    height: props.size === 'small' ? '32px' : undefined
                }}
                multiple
                fullWidth
            />
        </ClickAwayListener>
    );
}
