import { useDrag } from "react-dnd";
import { isNumber } from "lodash";
import { ItineraryStepDragObject } from "../objects/itineraryStepDragObject";
import { Block } from "../objects/block";
import { TripBlock } from "../objects/tripBlock";

type Options = {
    circuit: Block | null,
    typicalTrip: TripBlock | null,
    version: number,
    tripId: number,
    draggableProducts: number[],
    selectedSteps: number[],
    selectedVariant: number | null,
    selectedVariantDate: {
        [variant: number]: string
    }
}

export function useBlockDrag(options: Options) {
    return useDrag(() => ({
        type: 'block',
        item: {
            drag: 'add',
            data: {
                circuitId: options.circuit?.id ?? null,
                circuitVersion: options.version,
                circuitVariant: options.selectedVariant,
                circuitDate: isNumber(options.selectedVariant) ?
                    options.selectedVariantDate[options.selectedVariant] ??
                    'PERIOD' :
                    'PERIOD',
                circuit: options.circuit,
                typicalTrip: options.typicalTrip,
                tripId: options.tripId,
                tripVersion: options.version,
                steps: options.selectedSteps,
                products: isNumber(options.circuit?.id) ?
                    options.draggableProducts :
                    undefined
            }
        } as ItineraryStepDragObject,
        collect() {
            return {};
        }
    }), [
        options.selectedSteps,
        options.selectedVariant,
        options.selectedVariantDate,
        options.draggableProducts
    ]);
}
