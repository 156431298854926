import { LightDestination } from "../objects/lightDestination";
import { ReducedLightDestination } from "../objects/reducedLightDestination";

export function getLightDestinationName(
    locale: number | null,
    destination: LightDestination | ReducedLightDestination
): string {
    const localization = destination.localization.find((item) => {
        return item.locale === locale;
    });

    if (localization) {
        return localization.name;
    }

    return destination.international_name;
}
