import { CreatePageOptions } from "../objects/createPageOptions";
import { Template } from "../utils/handlebars/roadbook.handlebars";

export function createTravelCertificateContent(options: CreatePageOptions): Parameters<Template>[0]['contents'][0] {
    return {
        type: 'travel-certificate',
        anchor: 'travel-certificate',
        summaryTitle: options.title,
        title: options.title,
        showInSummary: false,
        ref: options.trip.trip_reference.toString(),
        lead: `${options.contactTraveler.title} ${[options.contactTraveler.first_name, options.contactTraveler.last_name].filter((item): item is string => !!item).join(' ')}`,
        travelers: options.travelers.map((traveler) => [traveler.first_name, traveler.last_name].filter((item): item is string => !!item).join(' ')),
        travelersCount: options.travelers.length,
        destination: options.tripVersion.destination?.data?.localization.find((item) => {
            return item.locale === options.locale;
        })?.name ??
        options.tripVersion.destination?.data?.international_name ??
        options.tripVersion.destination?.data?.name ??
        '',
        startDate: window.moment.utc(options.tripStartDate).format('LL'),
        endDate: window.moment.utc(options.tripEndDate).format('LL')
    };
}
