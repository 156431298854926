import { Itinerary } from "../../Itinerary/objects/itinerary";

type ProductDates = {
    start_date: string,
    end_date: string
}

type Options = ({
    type: 'start',
    product: {start_destination?: {id: number} | null} & ProductDates
} | {
    type: 'end',
    product: {end_destination?: {id: number} | null} & ProductDates
}) & {
    granularity: 'days' | 'seconds'
    itinerary: Itinerary[],
}

export function findProductAssociatedItineraryStep(options: Options): Itinerary | null {
    const date = window.moment.utc(
        options.type === 'start' ?
            options.product.start_date :
            options.product.end_date
    );
    return options.itinerary.filter((item) => {
        const destination = options.type === 'start' ?
            options.product.start_destination :
            options.product.end_destination;
        return item.destination?.id === destination?.id;
    }).sort((a, b) => {
        const aStartDate = window.moment.utc(a.start_date);
        const aEndDate = window.moment.utc(a.end_date);

        if (
            date.isBetween(
                aStartDate,
                aEndDate,
                options.granularity,
                '[]'
            )
        ) {
            return -1;
        }

        const bStartDate = window.moment.utc(b.start_date);
        const bEndDate = window.moment.utc(b.end_date);

        if (
            date.isBetween(
                bStartDate,
                bEndDate,
                options.granularity,
                '[]'
            )
        ) {
            return 1;
        }

        const diffA = Math.abs(
            window.moment.utc(
                options.type === 'start' ?
                    options.product.start_date :
                    options.product.end_date
            ).diff(
                window.moment.utc(
                    options.type === 'start' ?
                        a.end_date :
                        a.start_date
                ),
                'seconds'
            )
        );
        const diffB = Math.abs(
            window.moment.utc(
                options.type === 'start' ?
                    options.product.start_date :
                    options.product.end_date
            ).diff(
                window.moment.utc(
                    options.type === 'start' ?
                        b.end_date :
                        b.start_date
                ),
                'seconds'
            )
        );
        return diffA < diffB ? -1 : 1;
    })[0] ?? null;
}
