const GetProviderById = (id, providers, manual_providers) => {
    for (let i = 0; i < providers.length; i++) {
        if (providers[i].provider.id === id) {
            return providers[i].provider;
        }
    }
    for (let i = 0; i < manual_providers.length; i++) {
        if (manual_providers[i].provider.id === id) {
            return manual_providers[i].provider;
        }
    }
    return null;
};

export default GetProviderById;