import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';

import IconButton from "@material-ui/core/IconButton";
import Skeleton from '@material-ui/lab/Skeleton';
import Radio from '@material-ui/core/Radio';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from "@material-ui/icons/ChevronRight";
import { Check, Clear } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
    absoluteCenter: {
        margin: '0',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    matrixTitle: {
        color: 'rgba(0, 0, 0, 0.54)',
        textAlign: 'center',
        fontWeight: 'bold',
        marginTop: '10px'
    },
    pointer: {
        cursor: "pointer"
    },
    arrowLeft: {
        position: 'absolute',
        left: '-4%',
        top: -4,
        border: '1px solid',
        padding: '4px',
        zIndex: 2
    },
    arrowRight: {
        position: 'absolute',
        right: '-16px',
        top: -4,
        border: '1px solid',
        padding: '4px',
        zIndex: 2
    },
    carsCategoryName: {
        fontSize: '15px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        whiteSpace: "nowrap"
    },
    textCenter: {
        textAlign: 'center'
    },
    height60: {
        height: '60px !important'
    },
    height35: {
        height: '35px !important'
    },
    noBorder: {
        border: 0,
        padding: 2
    }
}));

const RenderContract = ({ car_contract, contract_info }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const downDesktop = useMediaQuery('(min-width:1440px)');

    const [offset, setOffset] = useState(downDesktop ? 3 : 2);
    const [index, setIndex] = useState(0);
    const [step, setStep] = useState(0);
    const [maxStep, setMaxStep] = useState(0);
    const [selectedValue, setSelectedValue] = useState(-1);
    const currency_list = useSelector((store) => {
        return store.base.currency_list;
    });
    const language = useSelector((store) => {
        return store.header.tmp_language;
    });
    useEffect(() => {
        setOffset(downDesktop ? 3 : 2);
    }, [downDesktop]);
    useEffect(() => {
        if (car_contract !== null) {
            setMaxStep(Math.ceil(car_contract.contracts.length / offset));
            if (car_contract.selected !== undefined && car_contract.selected !== null) {
                let indexContract = car_contract.contracts.findIndex((el) => {
                    return el.contract_id === car_contract.selected;
                });
                let newStep = car_contract.selected === 0 ? 0 : Math.floor(indexContract / offset);
                let newIndex = 0;
                for (let i = 0; i < Math.floor(indexContract / offset); i++) {
                    newIndex = newIndex + offset;
                }
                setSelectedValue(car_contract.selected);
                setIndex(newIndex);
                setStep(newStep);
            }
        }
    }, [car_contract]);
    const addOffset = () => {
        setIndex(index + offset);
        setStep(step + 1);
    };
    const subtractOffset = () => {
        setIndex(index - offset);
        setStep(step - 1);
    };
    const selected_car = contract_info.selected_car;

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        dispatch({ type: "CARS_SELECTED_CONTRACT", payload: { contract_id: parseInt(event.target.value) } });
    };
    const getSkeletonHeader = () => {
        let tmp = [];
        tmp.push(
            <TableCell key={"empty"} className={classes.noBorder}>{""}</TableCell>
        );
        for (let i = 0; i < offset; i++) {
            tmp.push(
                <TableCell key={i} className={classes.noBorder}>
                    <Skeleton height={100} animation={"wave"}/>
                </TableCell>
            );
        }
        return tmp;
    };
    const getSkeletonBody = () => {
        let tmp = [];
        tmp.push(
            <TableCell key={"empty"} className={classes.noBorder}>
                <Skeleton height={100} animation={"wave"}/>
            </TableCell>
        );
        for (let i = 0; i < offset; i++) {
            tmp.push(
                <TableCell key={i} className={classes.noBorder}>
                    <Skeleton height={100} animation={"wave"}/>
                </TableCell>
            );
        }
        return tmp;
    };
    const renderHeader = () => {
        let tmp = [];
        tmp.push(
            <TableCell key={"empty"} className={classes.noBorder}>{""}</TableCell>
        );
        for (let i = index; i < (index + offset < car_contract.contracts.length ? index + offset : car_contract.contracts.length); i++) {
            let contract = car_contract.contracts[i];
            tmp.push(
                <TableCell key={i} className={ `${classes.carsCategoryName} ${classes.noBorder}` } align={"center"}>{contract.name}</TableCell>
            );
        }
        return tmp;
    };
    const renderBody = (service) => {
        let tmp = [];
        tmp.push(
            <TableCell key={service.title} className={`${classes.pointer} ${classes.noBorder}`} component={"th"} scope={"row"}>
                <Tooltip title={service.description} placement={ "bottom" }>
                    <span>{service.title}</span>
                </Tooltip>
            </TableCell>
        );
        for (let i = index; i < (index + offset < car_contract.contracts.length ? index + offset : car_contract.contracts.length); i++) {
            let contract = car_contract.contracts[i];
            tmp.push(
                <TableCell key={i} className={ `${classes.textCenter} ${classes.noBorder}` }>
                    {service.contracts.includes(contract.contract_id) ? <Check style={{ color: green[500], stroke: green[500] }}/> : <Clear style={{ color: red[500], stroke: red[500] }}/>}
                </TableCell>
            );
        }
        return tmp;
    };
    const renderPrice = () => {
        let tmp = [];
        tmp.push(
            <TableCell key={"empty"} className={classes.noBorder}>{""}</TableCell>
        );
        for (let i = index; i < (index + offset < car_contract.contracts.length ? index + offset : car_contract.contracts.length); i++) {
            let contract = car_contract.contracts[i];
            let currency_code = currency_list.find((currency) => {
                return (currency.id === contract.prices[0].selling_currency);
            });
            let selected_contract = null;
            if (car_contract.selected !== undefined	&& car_contract.selected !== null) {
                selected_contract = car_contract.contracts.find((contract) => {
                    return contract.contract_id === car_contract.selected;
                });
            }
            let base_price = selected_car.station_prices ? selected_car.station_prices[0].prices[0].selling_price : selected_car.selling_price;
            let price = null;
            if (car_contract.selected !== undefined && car_contract.selected !== null) {
                price = contract.contract_id === car_contract.selected ? new Intl.NumberFormat(language, { style: 'currency', currency: currency_code.iso_code }).format(parseFloat(0)) :
                    new Intl.NumberFormat(language, { style: 'currency', currency: currency_code.iso_code }).format(parseFloat(parseFloat(contract.prices[0].selling_price) - parseFloat(selected_contract.prices[0].selling_price)));
            } else {
                price = new Intl.NumberFormat(language, { style: 'currency', currency: currency_code.iso_code }).format(parseFloat(parseFloat(contract.prices[0].selling_price) - parseFloat(base_price)));
            }
            tmp.push(
                <TableCell key={i} className={ `${classes.noBorder}` } align={"center"}>
                    <FormControlLabel
                        value={parseFloat(price) > 0 ? "+" + price : price}
                        control={
                            <Radio
                                checked={parseInt(selectedValue) === contract.contract_id}
                                onChange={handleChange}
                                value={contract.contract_id}
                                inputProps={{ 'aria-label': contract.name }}
                            />
                        }
                        label={parseFloat(price) > 0 ? "+" + price : price}
                        labelPlacement="bottom"
                    />
                </TableCell>
            );
        }
        return tmp;
    };
    if (car_contract !== null) {
        return (
            <Fragment>
                <div>
                    {
                        index > 0 && (
                            <IconButton className={ `${classes.arrowLeft}` } onClick={() => subtractOffset()}>
                                <ChevronLeft/>
                            </IconButton>
                        )
                    }
                    {
                        step < maxStep - 1 && (
                            <IconButton className={ `${classes.arrowRight}` } onClick={() => addOffset()}>
                                <ChevronRight/>
                            </IconButton>
                        )
                    }
                </div>
                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {renderHeader()}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                car_contract.services.map((service, service_index) => {
                                    return (
                                        <TableRow key={service_index}>
                                            {renderBody(service)}
                                        </TableRow>
                                    );
                                })
                            }
                            <TableRow>
                                {renderPrice()}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Fragment>
        );
    }
    return (
        <TableContainer>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {getSkeletonHeader()}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {getSkeletonBody()}
                    </TableRow>
                    <TableRow>
                        {getSkeletonBody()}
                    </TableRow>
                    <TableRow>
                        {getSkeletonBody()}
                    </TableRow>
                    <TableRow>
                        {getSkeletonBody()}
                    </TableRow>
                    <TableRow>
                        {getSkeletonBody()}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default React.memo(RenderContract);
