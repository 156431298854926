import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Divider,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    MenuProps,
    Select,
    Typography
} from "@mui/material";
import { changeBlocksSort } from "./redux/reducer";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    open: boolean,
} & Pick<MenuProps, 'anchorEl' | 'onClose'>

export function ItineraryBlocksSorts(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sortByFilter = useSelector((state: AppState) => state.itinerarySlice.blocks.sortBy);

    const onChange = (value: 'none' | 'asc' | 'desc') => {
        dispatch(
            changeBlocksSort(
                value !== 'none' ?
                    {
                        field: 'nights-count',
                        direction: value
                    } :
                    null
            )
        );
    };

    return (
        <Menu
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
        >
            <ListItem>
                <ListItemText
                    primary={
                        <>
                            <Typography
                                sx={{ fontWeight: 'bold', marginLeft: 2.5 }}
                                gutterBottom
                            >
                                {t<string>('itinerary.sort-by-duration')}
                            </Typography>
                            <Divider sx={{ marginBottom: 1.5 }} />
                        </>
                    }
                    secondary={
                        <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                            <Select
                                sx={{ width: 250 }}
                                value={sortByFilter?.direction ?? 'none'}
                                onChange={(event) => onChange(event.target.value as 'asc' | 'desc' | 'none')}
                            >
                                <MenuItem value="none">
                                    {t<string>('itinerary.no-sort')}
                                </MenuItem>
                                <MenuItem value="asc">
                                    {t<string>('itinerary.asc-order')}
                                </MenuItem>
                                <MenuItem value="desc">
                                    {t<string>('itinerary.desc-order')}
                                </MenuItem>
                            </Select>
                        </Box>
                    }
                />
            </ListItem>
        </Menu>
    );
}
