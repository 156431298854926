export function SetCurrentGroup(groups) {
    return {
        type: "POI_SET_GROUPS",
        payload: {
            groups: groups
        }
    }
}

export function SetSearch(search) {
    return {
        type: "POI_SET_SEARCH",
        payload: {
            search: search
        }
    }
}

export function SetDates(start_date, end_date, force_request = false) {
    return {
        type: "POI_SET_DATES",
        payload: {
            start_date: start_date,
            end_date: end_date,
            force_request: force_request
        }
    }
}

export function ChangeGripType(grid_type) {
    return {
        type: "POI_CHANGE_GRID_TYPE",
        payload: {
            grid_type: grid_type
        }
    }
}

export function ChangeActivePage(active_page) {
    return {
        type: "POI_CHANGE_ACTIVE_PAGE",
        payload: {
            active_page: active_page
        }
    }
}

// export function ToggleFavorite(show_favorite) {
//     return {
//         type: "POI_TOGGLE_FAVORITE",
//         payload: {
//             show_favorite: show_favorite
//         }
//     }
// }

export function ToggleSorts(key) {
    return {
        type: "POI_TOGGLE_SORTS",
        payload: {
            key: key
        }
    }
}

export function ToggleShowSorts(show_sorts) {
    return {
        type: "POI_TOGGLE_SHOW_SORTS",
        payload: {
            show_sorts: show_sorts
        }
    }
}

export function SetPoiFullList(full_list) {
    return {
        type: "POI_SET_FULL_LIST",
        payload: {
            full_list: full_list
        }
    }
}

export function SetOver(id) {
    return {
        type: "POI_SET_OVER",
        payload: {
            over: id
        }
    }
}

export function EmptyFilters() {
    return {
        type: "POI_EMPTY_FILTERS"
    }
}

export function ToggleFilters() {
    return {
        type: "POI_TOGGLE_FILTERS",
    }
}

export function ToggleComposition(show_group_composition) {
    return {
        type: "POI_TOGGLE_ROOM_COMPOSITION",
        payload: {
            show_group_composition: show_group_composition
        }
    }
}

export function AddPrices(prices, custom_provider) {
    return {
        type: "POI_ADD_PRICES",
        payload: {
            prices: prices,
            custom_provider: custom_provider
        }
    }
}

export function AddToCart(poi) {
    return {
        type: "POI_ADD_TO_CART",
        payload: {
            poi: poi
        }
    }
}

export function SetPoiCart(cart) {
    return {
        type: "POI_SET_CART",
        payload: {
            cart: cart
        }
    }
}

export function EditPoiCart(item, index) {
    return {
        type: "POI_EDIT_CART",
        payload: {
            item: item,
            index: index
        }
    }
}


export function EditPoiCartById(poi) {
    return {
        type: "POI_EDIT_CART_BY_ID",
        payload: poi
    }
}

export function RemovePoiFromCart(poi_index)  {
    return {
        type: "POI_REMOVE_CART",
        payload: {
            poi_index: poi_index
        }
    }
}

export function RemoveFromCartById(poi_id) {
    return {
        type: "POI_REMOVE_CART_BY_ID",
        payload: poi_id
    }
}

export function SetDateAndGroup(date, group) {
    return {
        type: "POI_SET_DATE_AND_GROUP",
        payload: {
            date: date,
            group: group
        }
    }
}

export function TogglePoiFull() {
    return {
        type: "POI_TOGGLE_FULL",
    }
}

export function TogglePoiRecommended() {
    return {
        type: "POI_TOGGLE_RECOMMENDED"
    }
}

export function EditPoiCartMargin(edit, index) {
    return {
        type: "POI_SET_MANUAL_MARGIN_EDIT",
        payload: {
            edit: edit,
            index: index
        }
    }
}
export function SetRequestUrl(request_url) {
    return {
        type: "POI_SET_REQUEST_URL",
        payload: {
            request_url: request_url
        }
    }
}

export function TogglePoiSorts() {
    return {
        type: "POI_TOGGLE_SORTS"
    }
}

export function ToggleParameters() {
    return {
        type: "POI_TOGGLE_PARAMETERS"
    }
}

export function SetProviderFailed() {
    return {
        type: "MATERIAL_PROVIDER_FAILED"
    }
}

export function SetProviderRunning() {
    return {
        type: "MATERIAL_PROVIDER_RUNNING"
    }
}

export function UpdateHovered(hovered) {
    return {
        type: "MATERIAL_UPDATE_HOVERED",
        payload: {
            hovered: hovered
        }
    }
}

export function UpdateParameters(current_groups) {
    return {
        type: "POI_UPDATE_PARAMETERS",
        payload: {
            "current_groups": current_groups
        }
    }
}

export function UnmountCard() {
    return {
        type: "MATERIAL_POI_CARD_UNMOUNT_BACK",
        payload: {}
    }
}

export function ResetUnmountCard() {
    return {
        type: "MATERIAL_POI_RESET_CARD_UNMOUNT",
        payload: {}
    }
}

export function SetPricesLoaded(prices_loaded) {
    return {
        type: "POI_SET_PRICES_LOADED",
        payload: {
            prices_loaded: prices_loaded
        }
    }
}

export function SetActiveDestination(destination) {
    return {
        type: "MATERIAL_POI_DESTINATION",
        payload: destination
    }
}

export function SetRequestSent(request_sent) {
    return {
        type: "POI_SET_REQUEST_SENT",
        payload: {
            request_sent: request_sent
        }
    }
}

export function SetCustomTimeError(is_error) {
    return {
        type: "POI_SET_CUSTOM_TIME_ERROR",
        payload: {
            custom_time_error: is_error
        }
    }
}