import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useSnackbar } from "notistack";
import { InputAdornment, makeStyles, useTheme } from "@material-ui/core";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";

import CheckBeforeRequest from "../Common/CheckBeforeRequest";

import { EditTraveler } from "../../Actions/Trip";
import { ReloadTempTravelers } from '../../Actions/Booking';
import RefreshToBook from './Functions/RefreshToBook';

import axios from "axios";

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400:{
        fontWeight: 400
    },
    fontSize12: {
        fontSize: 12
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter:{
        textAlign: "center"
    },
    genericPadding:{
        padding: "8px 0px"
    },
    formControlRootSmall: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    formControlRootSmallRed: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    circleBorder:{
        borderRadius: 8
    },
    validateButton: {
        color: "#FFFFFF",
        backgroundColor: "#2C98F0",
        float: "right",
        marginTop: 8
    },
    errorInfo:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        letterSpacing: 1.25,
        color: "red",
        width: "100%",
        fontSize: 10
    }
}))
type PaxHeightProps = {
    product: any,
    group: any,
    tempTravelers: any,
    setTempTravelers: any
}
export function PaxHeight (props: PaxHeightProps): JSX.Element {
    const { product, group, tempTravelers, setTempTravelers } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));

    const checks_passed = useSelector(store => store.booking.checks_passed);
    const end_date = useSelector(store => store.trip.end_date);
    const travelers = useSelector(store => store.trip.travelers);

    const heightLabel = useRef(null);
    const heightLabelWidth = heightLabel.current ? heightLabel.current.clientWidth : 100;

    useEffect(() => {
        let temp_list = [];

        tempTravelers.map((traveler) => {
            let temp_traveler = Object.assign({}, traveler);
            temp_traveler.height = temp_traveler.height !== null ? temp_traveler.height : "";
            temp_list.push(temp_traveler);
        })

        setTempTravelers(temp_list);
        
    }, [group])

    const handleHeight = (e, traveler_index) => {
        let temp_list = tempTravelers.slice();
        temp_list[traveler_index].height = e.target.value;
        setTempTravelers(temp_list);
    }

    const confirmTempTravelers = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        
        if(pass_check){
            tempTravelers.map((traveler) => {
                let request = {
                    "first_name": traveler.first_name !== "" ? traveler.first_name : t("global.first_name"),
                    "last_name": traveler.last_name !== "" ? traveler.last_name : t("global.last_name"),
                    "height": traveler.height !== "" ? traveler.height : null
                }
                axios({
                    method: "PATCH",
                    url: `${API_HREF}client/${window.id_owner}/travelers/${traveler.id}/`,
                    headers: headers,
                    data: JSON.stringify(request)
                }).then(function(response){
                    enqueueSnackbar(t("menu.trip_list.traveler") + ` ${traveler.age_label} ` + t("menu.trip_list.success_change_travelers"), { variant: "success", disableWindowBlurListener: true, autoHideDuration: 2000 });
                    dispatch(ReloadTempTravelers(false));
                    travelers.map((trip_traveler, traveler_index) => {
                        if (trip_traveler.id === traveler.id){
                            dispatch(EditTraveler(response.data, traveler_index));
                        }
                    })
                    RefreshToBook(product, end_date, dispatch, "PaxHeight");
                }).catch(function(error){
                    if(error.response.status === 409 && error.response.data.detail !== undefined){
                        enqueueSnackbar(error.response.data.detail, { variant: "error", disableWindowBlurListener: true, autoHideDuration: 6000 });  
                    }else {
                        enqueueSnackbar(t("menu.trip_list.traveler") + ` ${traveler.age_label} ` + t("menu.trip_list.failed_change_travelers"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 3500 });
                    }
                })
            })
        }

    }

    return(
        <Grid>
            {
                tempTravelers.map((traveler, traveler_index) => {
                    if (group.travelers.includes(traveler.id)){
                        return(
                            <Grid container key={`height${traveler.id}`} alignItems="baseline" justify="space-between" className={classes.genericPadding} style={!downDesktop ? { paddingTop: 16 } : {}}>
                                <Grid item md={6} xs={12} className={`${!downDesktop ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                    { t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name }
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <FormControl variant="outlined" className={checks_passed === false && traveler.height === "" ? classes.formControlRootSmallRed : classes.formControlRootSmall}>
                                        <InputLabel id={`heightlabel-${traveler.id}`} ref={heightLabel}>
                                            { t<string>("booking.pax_height") }
                                        </InputLabel>
                                        <OutlinedInput
                                            type={"number"}
                                            name={`heightlabel-${traveler.id}`}
                                            id={`heightinput-${traveler.id}`}
                                            labelWidth={heightLabelWidth}
                                            value={traveler.height}
                                            className={classes.circleBorder}
                                            onChange={(e) => handleHeight(e, traveler_index)}
                                            autoComplete="off"
                                            endAdornment={<InputAdornment position="end">{product.variant !== undefined && product.variant !== null && product.variant.pax_height_unit !== null && product.variant.pax_height_unit !== '' ? (product.variant.pax_height_unit === 'CENTIMETRE' ? 'cm' : 'in') : ''}</InputAdornment>}
                                        />
                                    </FormControl>
                                    {
                                        checks_passed === false && traveler.height === "" && (
                                            <Grid className={classes.errorInfo}>
                                                { t("booking.traveler_height_err") }
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                })
            }
            <Button variant="contained" className={classes.validateButton} onClick={() => { confirmTempTravelers() }} size="small">
                {t<string>("global.validate")}
            </Button>
        </Grid>
    )
}