export default (prices_terrestrial_results, prices_flight_results, promos_results, periods_results, variant_selected, current_version) => {
    let price_data = [];
    if (variant_selected !== "") {
        prices_terrestrial_results.map((terrestrial_results) => {
            let terrestrial = Object.assign({}, terrestrial_results);
            let tmp_price = price_data.slice();
            if (tmp_price.length === 0) {
                let dates = [];
                let good = false;
                let variant = null;
                periods_results.map((date) => {
                    if (terrestrial.periods.includes(date.id) && date.trip_version === current_version) {
                        variant = date.variant;
                        good = true;
                        dates.push(date);
                        terrestrial.trip_version = date.trip_version;
                    }
                });
                let tmp_terrestrial = [];
                if (good) {
                    tmp_terrestrial.push(terrestrial);
                    dates = dates.sort((a, b) => {
                        return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0);
                    });
                    tmp_price.push({
                        terrestrial_price: tmp_terrestrial,
                        flight_price: [{
                            airports: [],
                            price: 0
                        }],
                        promo_price: [],
                        dates: dates,
                        periods: good ? terrestrial.periods.sort() : [],
                        variant: variant
                    });
                }
            }
            else {
                let already_add = tmp_price.findIndex((el) => {
                    return el.periods.join() === terrestrial.periods.sort().join();
                });
                if (already_add !== -1) {
                    tmp_price[already_add].terrestrial_price.push(terrestrial);
                    tmp_price[already_add].terrestrial_price = tmp_price[already_add].terrestrial_price.sort((a, b) => {
                        return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0);
                    });
                }
                else {
                    let dates = [];
                    let good = false;
                    let variant = null;
                    for (let i = 0; i < periods_results.length; i++) {
                        let date = periods_results[i];
                        if (terrestrial.periods.includes(date.id)  && date.trip_version === current_version) {
                            good = true;
                            variant = date.variant;
                            dates.push(date);
                        }
                    }
                    if (good) {
                        let tmp_terrestrial = [];
                        dates = dates.sort((a, b) => {
                            return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0);
                        });
                        tmp_terrestrial.push(terrestrial);
                        tmp_terrestrial = tmp_terrestrial.sort((a, b) => {
                            return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0);
                        });
                        tmp_price.push({
                            terrestrial_price: tmp_terrestrial,
                            flight_price: [{
                                price: 0,
                                airports: []
                            }],
                            promo_price: [],
                            dates: dates,
                            periods: good ? terrestrial.periods.sort() : [],
                            variant: variant
                        });
                        good = false;
                    }
                }
            }
            price_data = tmp_price;
        });
        prices_flight_results.map((flight_results) => {
            let flight = Object.assign({}, flight_results)
            let tmp_price = price_data.slice();
            if (tmp_price.length === 0) {
                let dates = [];
                let variant = null;
                let good = false;
                for (let i = 0; i < periods_results.length; i++) {
                    let date = periods_results[i];
                    if (flight.periods.includes(date.id) && date.trip_version === current_version) {
                        variant = date.variant;
                        good = true;
                        dates.push(date);
                        flight.trip_version = date.trip_version;
                    }
                }
                let tmp_flight = [];
                if (good) {
                    tmp_flight.push(flight);
                    dates = dates.sort((a, b) => {
                        return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0);
                    });
                    tmp_price.push({
                        terrestrial_price: [],
                        flight_price: tmp_flight,
                        promo_price: [],
                        dates: dates,
                        periods: good ? flight.periods.sort() : [],
                        variant: variant
                    });
                }
            }
            else {
                let already_add = tmp_price.findIndex((el) => {
                    return el.periods.join() === flight.periods.sort().join();
                });
                if (already_add !== -1) {
                    tmp_price[already_add].flight_price.push(flight);
                    tmp_price[already_add].flight_price = tmp_price[already_add].flight_price.filter((price) => {
                        return price.airports.length > 0;
                    });
                }
            }
            price_data = tmp_price
        });
        promos_results.map((promo) => {
            let tmp_price = price_data.slice();
            if (tmp_price.length === 0) {
                let dates = [];
                let good = false;
                let variant = null;
                for (let i = 0; i < periods_results.length; i++) {
                    let date = periods_results[i];
                    if (promo.periods.includes(date.id) && date.trip_version === current_version) {
                        good = true;
                        variant = date.variant;
                        dates.push(date);
                    }
                }
                let tmp_promo = [];
                if (good) {
                    tmp_promo.push(promo);
                    dates = dates.sort((a, b) => {
                        return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0);
                    });
                    tmp_price.push({
                        terrestrial_price: [],
                        flight_price: [{
                            price: 0,
                            airports: []
                        }],
                        promo_price: tmp_promo,
                        dates: dates,
                        periods: good ? promo.periods.sort() : [],
                        variant: variant
                    });
                }
            }
            else {
                let already_add = tmp_price.findIndex((el) => {
                    return el.periods.join() === promo.periods.sort().join();
                });
                if (already_add !== -1) {
                    tmp_price[already_add].promo_price.push(promo);
                }
            }
            price_data = tmp_price;
        });
        if (price_data.length === 0) {
            price_data.push({
                terrestrial_price: [],
                flight_price: [{
                    price: 0,
                    airports: []
                }],
                promo_price: [],
                dates: [{
                    end_date: null,
                    for_promo_only: false,
                    start_date: null,
                    start_days: []
                }],
                variant: null
            });
        }
    }
    let find_data = price_data.find((el) => {
        return el.flight_price.find((el1) => {
            return el1.airports.length !== 0;
        });
    });
    price_data.map((data) => {
        let is_empty = false;
        data.flight_price.map((flight) => {
            if (flight.airports.length === 0) {
                is_empty = true;
            }
        });
        if (is_empty && find_data !== undefined) {
            let find_airports = find_data.flight_price.slice();
            let airports = [];
            for (let i = 0; i < find_airports.length; i++) {
                airports.push({
                    airports: find_airports[i].airports,
                    periods: find_airports[i].periods,
                    price: "0"
                });
            }
            data.flight_price = airports;
        }
    });
    price_data = price_data.sort((a, b) => {
        return a.variant - b.variant;
    });
    return price_data
}