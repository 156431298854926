//---- Dependencies ----//
import React, {Fragment, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import i18n from "../../i18n";
import { LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import localeText from "../Functions/localeText";
import clsx from "clsx";
import { AddHistorydest, SetItineraryTypeDetailsDataAndDayAndNight } from "../../Actions/ItineraryType";
import CheckBeforeRequest from '../Common/CheckBeforeRequest';

const useStyles = makeStyles({
    pointer: {
        cursor: "pointer"
    },
    paddingCell: {
        "& .MuiTableCell-root" : {
            padding: "10px 0px 10px 16px !important",
            fontSize: 12
        },
    },
    hover: {
        "&:hover": {
            backgroundColor: '#f1f1f1'
        }
    },
    noWrap: {
        whiteSpace: 'nowrap'
    }
});

const VisiteursProductsListRow = ({row, router}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const locales = useSelector(state => state.user.locales);
    const current_locale = locales.find((el) => {return el.language_code === i18n.language});
    const dispatch = useDispatch();

    const [totalNights, setTotalNights] = useState(null);
    const [totalDays, setTotalDays] = useState(null);
    const [destination, setDestination] = useState('-')
    const [type, setType] = useState('')
    const {pass_check, headers} = CheckBeforeRequest();

    const replaceAll = (str, find, replace) => {
		return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
	};
	const escapeRegExp = (str) => {
	    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
	};
    
    useEffect(() => {
        if (row.destination !== undefined && row.destination !== null) {
            setTotalDays(row.duration_day);
            setDestination(localeText(current_locale.id, row.destination.data.localization, row.destination.data.international_name, 'name'));
        } else if (row.data !== undefined && row.data !== null && row.data.length !== 0) {
            let data = row.data.find(el => el.id === row.current_version);
            if (data.destination !== undefined && data.destination !== null) {
                setDestination(localeText(current_locale.id, data.destination.data.localization, data.destination.data.international_name, 'name'));
            }
            if (data.start_date !== null && data.end_date !== null) {
                setTotalDays(moment.utc(data.end_date).diff(moment.utc(data.start_date), "d") + 1);
            }
        }
        if (row.itinerary_type !== undefined && row.itinerary_type) {
            setType(t('itineraryType.itinerary_type'));
        } else if (row.description_version !== undefined) {
            setType(t('itineraryType.circuits'));
        } else {
            setType(t('itineraryType.packages'));
        }
        if (row.destination !== undefined && row.destination !== null) {
            setTotalNights(row.duration_night);
        } else {
            getNights();
        }
    });

    const getNights = () => {
        if (pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${row.id}/versions/${row.current_version}/itinerary/get_nb_nights/?token=${row.token}`,
            }).then(function (response) {
                setTotalNights(response.data.nights);
            }).catch(function (error) {
                console.log('error itinerary:', error); 
            });
        }
    }
    return (
        <TableRow className={ clsx(classes.hover, classes.pointer, classes.paddingCell)} onClick={() => {
            //dispatch(SetTripToDuplicateAndOpenDuplicateModalAndSetStep(iti, true, 1));
            let tmp_obj = {
                name: row.name,
                data: row,
                need_redirect: true
            };
            dispatch(AddHistorydest(tmp_obj));
            dispatch(SetItineraryTypeDetailsDataAndDayAndNight(row, 0, 0));
            let a = replaceAll(row.name, ' ', '-');
            let clean_name = replaceAll(a, '/', '');
            console.log('clean_name:', clean_name);
            if (row.itinerary_type !== undefined) {
                router.push(`/${window.url_name}/itinerary-type-details/${clean_name}/${row.id}`);
            } else if (row.description_version !== undefined) {
                router.push(`/${window.url_name}/circuits-details/${clean_name}/${row.id}`);
            } else {
                router.push(`/${window.url_name}/packages-details/${clean_name}/${row.id}`);
            }
        }}>
            <TableCell align={"left"} className={classes.noWrap}>
                {localeText(current_locale.id, row.localization, row.name, 'name')}
            </TableCell>
            <TableCell align={"left"} className={classes.noWrap}>
                { destination }
            </TableCell>
            <TableCell align={"left"} className={classes.noWrap}>
                { type }
            </TableCell>
            <TableCell align={"left"} className={classes.noWrap}>
                { totalDays !== null && totalNights !== null ? totalDays + " " + (totalDays > 1 ? t("global.days") : t("global.day")) + '   / ' + totalNights + " " + (totalNights > 1 ? t("itinerary.nights") : t("itinerary.night")) : '' }
            </TableCell>
        </TableRow>
    )
};
export default VisiteursProductsListRow;