//---- Functions ----//
import CheckResponse from "../../Flight/FlightSelected/Functions/CheckResponse";

//---- Actions ----//
import { SetFlightCart } from "../../../Actions/Flight";
import { SetCarsCart } from "../../../Actions/CarsSearch";
import { SetCart } from "../../../Actions/Accommodation";
import { SetManualCart } from "../../../Actions/Cart";
import { SetPoiCart } from "../../../Actions/Poi";
import { SetTransfersCart } from "../../../Actions/Transfers";

//---- Commons ----//
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import {SetItinerary} from "../../../Actions/Itinerary";

let is_done = 0;
let transfers = [];

function redirect(dispatch) {
    if (is_done === 2) {
        dispatch(SetTransfersCart(transfers));
    }
}

export default (dispatch, end_date) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/?token=${GetCookie("trip_token")}`,
            headers: headers,
            success: function (data) {
                dispatch(SetItinerary(data));
            },
            error: function (error) {
                console.log('error:', error);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/accommodation/`,
            headers: headers,
            success: function (data) {
                dispatch(SetCart(data));
                // redirect(component);
            },
            error: function (error) {
                console.log(error.responseJSON);
                // redirect(component);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/custom-products/`,
            headers: headers,
            success: function (data) {
                let tmp_poi = [];
                data.map((el) => {
                    if (el.product_type === 4) {
                        transfers.push(el);
                    }
                    else {
                        tmp_poi.push(el);
                    }
                });
                is_done++
                redirect(dispatch);
                dispatch(SetPoiCart(tmp_poi));
            },
            error: function (error) {
                console.log(error.responseJSON);
                // redirect(component);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/transfers/`,
            headers: headers,
            success: function (data) {
                transfers = transfers.concat(data);
                is_done++;
                redirect(dispatch);
            },
            error: function (error) {
                console.log(error.responseJSON);
                // redirect(component);
            }
        });
         $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/manual-products/`,
            headers: headers,
            success: function (data) {
                if (data.length > 0) {
                    let products = [];
                    data.map((product) => {
                        if (Object.keys(product).length > 0) {
                            products.push(product);
                        }
                    });
                    dispatch(SetManualCart(products));
                }
                // redirect(component);
            },
            error: function (error) {
                console.log(error.responseJSON);
                // redirect(component);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/car/`,
            headers: headers,
            success: function (data) {
                if (data.length > 0) {
                    data.map((item) => {
                        item.detail = false;
                        item.charges.sort((a, b) => {
                            return ((parseFloat(a.amount) < parseFloat(b.amount)) ? -1 : ((parseFloat(a.amount) > parseFloat(b.amount)) ? 1 : 0));
                        });
                    });
                }
                dispatch(SetCarsCart(data));
                // redirect(component);
            },
            error: function (error) {
                console.log(error.responseJSON);
                // redirect(component);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/flight/`,
            headers: headers,
            success: function (data) {
                if (data.length > 0) {
                    let arr = CheckResponse(data, end_date);
                    dispatch(SetFlightCart(arr));
                }
                // redirect(component);
            },
            error: function (error) {
                console.log(error.responseJSON);
                // redirect(component);
            }
        });
    }
};
