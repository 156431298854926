import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import React, { Fragment } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import {DateRangeDelimiter, DateRangePicker, LocalizationProvider} from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import TextField from '@material-ui/core/TextField';
import AccommodationCardGroups from './AccommodationCardGroups';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import AccommodationCardAmerigo from './AccommodationCardAmerigo';
import AccommodationCardVerdie from './AccommodationCardVerdie';
import GetPictureBySize from '../Functions/GetPictureBySize';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: 'bold'
    },
    inline: {
        display: 'inline-block'
    },
    priceContainer: {
        marginBottom: theme.spacing(2)
    },
    perNight: {
        marginLeft: theme.spacing(1)
    },
    floatRight: {
        float: 'right'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white',
        borderRadius: 24
    },
    alignCenter: {
        textAlign: 'center'
    },
    paddingBottom: {
        paddingBottom: theme.spacing(2),
    },
    providerWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    negMarginTop: {
        marginTop: -theme.spacing(1)
    },
    validateWrapper: {
        position: 'relative'
    },
    fullWidth: {
        width: '100%'
    }
}));

const AccommodationCardPricesModal = ({open, onClose, min_date, max_date, cart, pre_cart, prices, start_date, end_date, diff_day, onDateChange, group_modal_open, current_groups, current_travelers, toggleGroupModal, provider_loading, adding, setNewGroups, getRoomPrices, onValidate}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const language = useSelector(state => state.header.tmp_language);
    const user = useSelector(state => state.user.user);
    const currency = useSelector(state => state.trip.currency);
    const config = JSON.parse(localStorage.getItem('config'));
    return (
        <Dialog fullScreen={ true } open={ open } fullWidth onClose={ onClose } disableEscapeKeyDown>
            <DialogTitle>
                <Grid container justify={ 'space-between' } alignItems={ 'center' }>
                    <Grid item className={ classes.bold }>
                        <IconButton onClick={ onClose }><NavigateBefore/></IconButton> { t('accommodation_list.parameters') }
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ language }>
                    <DateRangePicker
                        startText={ t('accommodation.start_date') }
                        endText={ t('accommodation.end_date') }
                        value={[start_date, end_date]}
                        onChange={dates => onDateChange(dates)}
                        minDate={min_date}
                        maxDate={max_date}
                        calendars={ 1 }
                        desktopModeMediaQuery={ '@media (min-width: 0px)' }
                        DialogProps={{className: classes.fullWidth}}
                        renderInput={(startProps, endProps) => {
                            startProps.helperText = undefined;
                            startProps.fullWidth = true;
                            endProps.helperText = undefined;
                            endProps.fullWidth = true;
                            return (
                                <Fragment>
                                    <TextField {...startProps} />
                                    <DateRangeDelimiter>{ t('accommodation.to') }</DateRangeDelimiter>
                                    <TextField {...endProps} />
                                </Fragment>
                            )
                        }}
                    />
                </LocalizationProvider>
                <br/>
                {
                    current_groups !== null && (
                        <AccommodationCardGroups group_modal_open={ group_modal_open } current_groups={ current_groups } current_travelers={ current_travelers } toggleGroupModal={ toggleGroupModal } setNewGroups={ setNewGroups }/>
                    )
                }
                {
                    !group_modal_open && user.client_full.type === 2 && current_groups.length !== pre_cart.length && config.quotation_code === 'amerigo' && prices !== null && (
                        <AccommodationCardAmerigo prices={ prices } diff_day={ diff_day } iso_code={ currency.iso_code } language={ language } provider_loading={ provider_loading } getRoomPrices={ getRoomPrices }/>
                    )
                }
                {
                    !group_modal_open && user.client_full.type === 2 && current_groups.length !== pre_cart.length && config.quotation_code === 'verdie' && prices !== null && (
                        <AccommodationCardVerdie prices={ prices } diff_day={ diff_day } iso_code={ currency.iso_code } language={ language } provider_loading={ provider_loading } getRoomPrices={ getRoomPrices }/>
                    )
                }
                {
                    !group_modal_open && user.client_full.type !== 2 && current_groups.length !== pre_cart.length && prices !== null && prices.map((price, index_price) => {
                        if (index_price === 0 && pre_cart.length === 0) {
                            return (
                                <div key={ index_price } className={ classes.alignCenter }>
                                    <img src={ GetPictureBySize(price.data.logo, 'MEDIUM') } width={ '50%' } alt={ 'provider logo' }/>
                                    <Typography variant={ 'h6' } className={ `${classes.bold} ${classes.paddingBottom}` }>{ prices.length > 0 ? (new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(Math.ceil(parseFloat( price.price / diff_day )))) : t('accommodation.full') }</Typography>
                                    <div className={ classes.providerWrapper }>
                                        <Button variant={ 'contained' } className={ classes.orangeButton } fullWidth={ true } disabled={ provider_loading === price.data.id } onClick={() => { getRoomPrices(price.data); } }>{ t('accommodation.choose') }</Button>
                                        { provider_loading === price.data.id && <CircularProgress size={24} className={ classes.buttonProgress } /> }
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div key={ index_price }>
                                <br/>
                                <img className={ classes.inline } src={ GetPictureBySize(price.data.logo, 'MEDIUM') } width={ 75 } alt={ 'provider logo' }/>
                                <div className={ `${classes.providerWrapper} ${classes.inline} ${classes.floatRight} ${classes.negMarginTop}` }>
                                    <Button variant={ 'contained' } size={ 'small' } className={ classes.orangeButton } disabled={ provider_loading === price.data.id } onClick={() => { getRoomPrices(price.data); } }>{ t('accommodation.choose') }</Button>
                                    { provider_loading === price.data.id && <CircularProgress size={24} className={ classes.buttonProgress } /> }
                                </div>
                                <Typography className={ `${classes.bold} ${classes.inline} ${classes.marginRight} ${classes.floatRight}` }>{ prices.length > 0 ? (new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(Math.ceil(parseFloat( price.price / diff_day )))) : t('accommodation.full') }</Typography>
                            </div>
                        )
                    })
                }
            </DialogContent>
        </Dialog>
    );
};

export default React.memo(AccommodationCardPricesModal);