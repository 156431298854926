import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from "@material-ui/core/IconButton";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import Slider from "@material-ui/core/Slider";
import CloseIcon from '@material-ui/icons/Close';

import Transition from "../../../Common/Transition";

import { SetFlightHoursSlide, SetFlightHours, SetMaxDurationSlider, SetMaxDurationFilter,
         ToggleDayFlight,ToggleNightFlight, } from "../../../../Actions/FlightSearch";

import ConvertTime from "../../Functions/ConvertTime";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    verticalSpacer: {
        padding: "8px 0px"
    },
    horizontalSpacer: {
        padding: "0px 16px"
    },
    sliderSpacer:{
        padding: "8px 50px"
    },  
    filterButton: {
        border: "1.03872px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: 31.1617,
        padding: "8px 12px"
    },
    closeMenu: {
        marginRight: 5
    }
}))

const HoursButton = (({ searched_group }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const language = useSelector(store => store.header.language);
    const slider_time = useSelector(store => store.flight_search.slider_time);
    const slider_min_duration = useSelector(store => store.flight_search.slider_min_duration);
    const slider_max_duration = useSelector(store => store.flight_search.slider_max_duration);
    const min_duration = useSelector(store => store.flight_search.min_duration);
    const max_duration = useSelector(store => store.flight_search.max_duration);
    const is_day_flight = useSelector(store => store.flight_search.is_day_flight);
    const is_night_flight = useSelector(store => store.flight_search.is_night_flight);

    const [openHours, setOpenHours] = useState(null);

    const hours_marks = [
        {
            value: 0,
            label: 0
        },
        {
            value: 6,
            label: 6
        },
        {
            value: 12,
            label: 12    
        },
        {
            value: 18,
            label: 18
        },
        {
            value: 24,
            label: 24
        }
    ];

    return(
        <Grid item>
            <Button size="small" className={classes.filterButton} onClick={(event) => setOpenHours(event.currentTarget)}>
                <QueryBuilderIcon className={classes.genericText} />
                <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`} style={{ paddingLeft: 8, textTransform: "capitalize" }}>
                    { t("filters.hours") }
                </div>
            </Button>
            {
                isHandHeld && (
                    <Dialog open={Boolean(openHours)} fullScreen TransitionComponent={Transition}>
                        <DialogTitle>
                            <Grid container alignItems="center" justify="flex-end">
                                <Grid item xs={4} />
                                <Grid item xs={4} className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                    { t("filters.hours") }
                                </Grid>
                                <Grid item xs={4} className={classes.textRight}>
                                    <IconButton size="small" className={classes.closeMenu} onClick={() => setOpenHours(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ padding: "0px 18px 0px 8px" }}>
                            {
                                slider_time !== null && searched_group.journey.map((journey, journey_index) => {
                                    return(
                                        <Grid key={journey_index}>
                                            {
                                                searched_group.journey_type === 1 && journey_index === 0 && (
                                                    <Grid className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                                        { t("filters.go") }
                                                    </Grid>
                                                )
                                            }
                                            {
                                                searched_group.journey_type === 1 && journey_index === 1 && (
                                                    <Grid className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                                        { t("filters.return") }
                                                    </Grid>
                                                )
                                            }
                                            {
                                                searched_group.journey_type === 2 && (
                                                    <Grid className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                                        { t("global.flight") + " " + (journey_index + 1) }
                                                    </Grid>
                                                )
                                            }
                                            {
                                                (searched_group.journey_type === 1 || searched_group.journey_type === 2) && (
                                                    <Divider />
                                                )
                                            }
                                            <Grid container alignItems="center" justify="center" spacing={3} className={classes.verticalSpacer}>
                                                <Grid item xs={5} className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                                    { t("filters.start_hours") + " " + (journey.origin !== null ? journey.origin.city : "") }
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Slider
                                                        value={slider_time[journey_index].departure}
                                                        onChange={(event, value) => dispatch(SetFlightHoursSlide(journey_index, "departure", value[0], value[1]))}
                                                        onChangeCommitted={(event, value) => dispatch(SetFlightHours(journey_index, "departure", value[0], value[1]))}
                                                        min={0}
                                                        max={24}
                                                        valueLabelDisplay="auto"
                                                        marks={hours_marks}
                                                        style={{ color: `${main_color}` }}
                                                        />
                                                </Grid>
                                            </Grid>
                                            <Grid container alignItems="center" justify="center" spacing={3}>
                                                <Grid item xs={5} className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                                    { t("filters.end_hours") + " " + ( journey.destination !== null ? journey.destination.city : "") }
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Slider
                                                        value={slider_time[journey_index].arrival}
                                                        onChange={(event, value) => dispatch(SetFlightHoursSlide(journey_index, "arrival", value[0], value[1]))}
                                                        onChangeCommitted={(event, value) => dispatch(SetFlightHours(journey_index, "arrival", value[0], value[1]))}
                                                        min={0}
                                                        max={24}
                                                        valueLabelDisplay="auto"
                                                        marks={hours_marks}
                                                        style={{ color: `${main_color}` }}
                                                        />
                                                </Grid>
                                            </Grid>
                                            {
                                                (searched_group.journey_type === 1 || searched_group.journey_type === 2) && journey_index + 1 !== searched_group.journey.length && (
                                                    <Divider />
                                                )
                                            }
                                        </Grid>
                                        
                                    )
                                })
                            }
                            <Divider />
                            <div className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                { t("filters.flight_duration") }
                            </div>
                            <Divider />
                            <Grid className={classes.sliderSpacer} style={{ marginTop: 40 }}>
                                <Slider
                                    value={[slider_min_duration, slider_max_duration]}
                                    onChange={(event, value) => dispatch(SetMaxDurationSlider(value[0], value[1]))}
                                    onChangeCommitted={(event, value) => dispatch(SetMaxDurationFilter(value[0], value[1]))}
                                    min={min_duration}
                                    max={max_duration}
                                    valueLabelDisplay="on"
                                    valueLabelFormat={(x) => ConvertTime(x, "minutes", language, t).replace(t("global.min"), "")}
                                    style={{ color: `${main_color}` }}
                                    classes={{ valueLabel: classes.fontSize10 }}
                                    />
                            </Grid>
                            <Divider />
                            <div className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                { t("filters.day_night_flight") }
                            </div>
                            <Divider />
                            <FormGroup className={classes.horizontalSpacer}>
                                {
                                    is_day_flight !== null && (
                                        <FormControlLabel 
                                            control={<Checkbox sx={{
                                                '&.Mui-checked': {
                                                    color: `${main_color}`,
                                                },
                                            }} checked={is_day_flight} onChange={() => dispatch(ToggleDayFlight())} name="day_flight" size="small" />}
                                            label={t("filters.day_flight")}
                                            classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                        />
                                    )
                                }
                                {
                                    is_night_flight !== null && (
                                        <FormControlLabel 
                                            control={<Checkbox sx={{
                                                '&.Mui-checked': {
                                                    color: `${main_color}`,
                                                },
                                            }} checked={is_night_flight} onChange={() => dispatch(ToggleNightFlight())} name="night_flight" size="small" />}
                                            label={t("filters.night_flight")}
                                            classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                        />
                                    )
                                }
                            </FormGroup>
                        </DialogContent>
                    </Dialog>
                )
            }
            {
                !isHandHeld && (
                    <Menu 
                        id="hours-menu"
                        anchorEl={openHours}
                        keepMounted
                        open={Boolean(openHours)}
                        onClose={() => setOpenHours(false)}
                        disableScrollLock={true}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        PaperProps={{ style: { overflow: "visible" }}}
                        >
                        <Grid container alignItems="center" justify="flex-end" style={{ width: 500 }}>
                            <Grid item xs={4} />
                            <Grid item xs={4} className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                { t("filters.hours") }
                            </Grid>
                            <Grid item xs={4} className={classes.textRight}>
                                <IconButton size="small" className={classes.closeMenu} onClick={() => setOpenHours(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider />
                        {
                            slider_time !== null && searched_group.journey.map((journey, journey_index) => {
                                return(
                                    <Grid key={journey_index}>
                                        {
                                            searched_group.journey_type === 1 && journey_index === 0 && (
                                                <Grid className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                                    { t("filters.go") }
                                                </Grid>
                                            )
                                        }
                                        {
                                            searched_group.journey_type === 1 && journey_index === 1 && (
                                                <Grid className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                                    { t("filters.return") }
                                                </Grid>
                                            )
                                        }
                                        {
                                            searched_group.journey_type === 2 && (
                                                <Grid className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                                    { t("global.flight") + " " + (journey_index + 1) }
                                                </Grid>
                                            )
                                        }
                                        {
                                            (searched_group.journey_type === 1 || searched_group.journey_type === 2) && (
                                                <Divider />
                                            )
                                        }
                                        <Grid container alignItems="center" justify="center" spacing={3} className={classes.verticalSpacer}>
                                            <Grid item xs={5} className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                                { t("filters.start_hours") + " " + (journey.origin !== null ? journey.origin.city : "") }
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Slider
                                                    value={slider_time[journey_index].departure}
                                                    onChange={(event, value) => dispatch(SetFlightHoursSlide(journey_index, "departure", value[0], value[1]))}
                                                    onChangeCommitted={(event, value) => dispatch(SetFlightHours(journey_index, "departure", value[0], value[1]))}
                                                    min={0}
                                                    max={24}
                                                    valueLabelDisplay="auto"
                                                    marks={hours_marks}
                                                    style={{ color: `${main_color}` }}
                                                    />
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center" justify="center" spacing={3}>
                                            <Grid item xs={5} className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                                { t("filters.end_hours") + " " + ( journey.destination !== null ? journey.destination.city : "") }
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Slider
                                                    value={slider_time[journey_index].arrival}
                                                    onChange={(event, value) => dispatch(SetFlightHoursSlide(journey_index, "arrival", value[0], value[1]))}
                                                    onChangeCommitted={(event, value) => dispatch(SetFlightHours(journey_index, "arrival", value[0], value[1]))}
                                                    min={0}
                                                    max={24}
                                                    valueLabelDisplay="auto"
                                                    marks={hours_marks}
                                                    style={{ color: `${main_color}` }}
                                                    />
                                            </Grid>
                                        </Grid>
                                        {
                                            (searched_group.journey_type === 1 || searched_group.journey_type === 2) && journey_index + 1 !== searched_group.journey.length && (
                                                <Divider />
                                            )
                                        }
                                    </Grid>
                                    
                                )
                            })
                        }
                        <Divider />
                        <div className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                            { t("filters.flight_duration") }
                        </div>
                        <Divider />
                        <Grid className={classes.sliderSpacer} style={{ marginTop: 40 }}>
                            <Slider
                                value={[slider_min_duration, slider_max_duration]}
                                onChange={(event, value) => dispatch(SetMaxDurationSlider(value[0], value[1]))}
                                onChangeCommitted={(event, value) => dispatch(SetMaxDurationFilter(value[0], value[1]))}
                                min={min_duration}
                                max={max_duration}
                                valueLabelDisplay="on"
                                valueLabelFormat={(x) => ConvertTime(x, "minutes", language, t).replace(t("global.min"), "")}
                                style={{ color: `${main_color}` }}
                                classes={{ valueLabel: classes.fontSize10 }}
                                />
                        </Grid>
                        <Divider />
                        <div className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                            { t("filters.day_night_flight") }
                        </div>
                        <Divider />
                        <FormGroup className={classes.horizontalSpacer}>
                            {
                                is_day_flight !== null && (
                                    <FormControlLabel 
                                        control={<Checkbox sx={{
                                        '&.Mui-checked': {
                                            color: `${main_color}`,
                                        },
                                    }} checked={is_day_flight} onChange={() => dispatch(ToggleDayFlight())} name="day_flight" size="small" />}
                                        label={t("filters.day_flight")}
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                )
                            }
                            {
                                is_night_flight !== null && (
                                    <FormControlLabel 
                                        control={<Checkbox sx={{
                                        '&.Mui-checked': {
                                            color: `${main_color}`,
                                        },
                                    }} checked={is_night_flight} onChange={() => dispatch(ToggleNightFlight())} name="night_flight" size="small" />}
                                        label={t("filters.night_flight")}
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                )
                            }
                        </FormGroup>
                    </Menu>
                )
            }
        </Grid>
    )
})

export default React.memo(HoursButton)