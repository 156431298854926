import React from "react";
import { Stack, StackProps, styled, Typography } from "@mui/material";
import { ItineraryStepDragObject } from "./objects/itineraryStepDragObject";

export type ItineraryStepItemDragPreviewProps = ItineraryStepDragObject & Pick<StackProps, 'sx'>

export const ItineraryStepItemDragPreview = React.forwardRef<
    HTMLDivElement,
    ItineraryStepItemDragPreviewProps
>(
    function ItineraryStepItemDragPreview(props, ref): JSX.Element {
        return (
            <Container
                ref={ref}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={props.sx}
            >
                <Typography
                    variant="subtitle1"
                    sx={{ lineHeight: 1, fontWeight: 700, whiteSpace: 'nowrap' }}
                    component="div"
                >
                    {props.drag === 'reorder' && props.data?.title}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        lineHeight: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        marginLeft: 1,
                        marginRight: 1
                    }}
                    component="div"
                >
                    {props.drag === 'reorder' && props.data?.destination}
                </Typography>
                <Typography
                    variant="caption"
                    sx={{ lineHeight: 1, whiteSpace: 'nowrap' }}
                    component="div"
                >
                    {
                        props.drag === 'reorder' &&
                        (
                            props.data?.isBloc ?
                                `id: ${props.data?.id}` :
                                window.moment(props.data?.arrival).format('DD/MM/YYYY | hh:mm')
                        )
                    }
                </Typography>
            </Container>
    
        );
    }
);

const Container = styled(Stack)((props) => ({
    width: '100%',
    border: '1px solid #ddd',
    padding: props.theme.spacing(1),
    borderRadius: props.theme.shape.borderRadius,
    color: '#999'
}));
