//---- Commons ----//
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

//---- Actions ----//
import { SetPoiList, SetPrices } from "../../../Actions/Poi";
import GetCookie from "../../Common/Functions/GetCookie";

let xhr;

export default (start_date, end_date, current_group, component, start_destination) => {
    const { dispatch } = component.props;
    const { id } = component.props.params;
    console.log(id);
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        if (xhr !== undefined)
            xhr.abort();
        if (component.state.loading === false)
            component.setState({loading: true});
        xhr = $.ajax({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/custom-products/${id}/`,
            success: function (data) {
                console.log(data);
                let results = [];
                results.push(data);
                let list = {
                    count: 1,
                    next: null,
                    prev: null,
                    results: results
                };
                dispatch(SetPoiList(list, `${API_HREF}client/${window.id_owner}/custom-products/${id}/`));
                component.setState({loading: false});
                if (list.results.length > 0) {
                    let providers = [];
                    for (let i = 0; i < list.results.length; i++) {
                        let added = false;
                        for (let j = 0; j < providers.length; j++) {
                            if (list.results[i].provider.id === providers[j].id) {
                                added = true;
                                providers[j].identifiers.push(list.results[i].id);
                            }
                        }
                        if (!added) {
                            let tmp_identifiers = [];
                            tmp_identifiers.push((list.results[i].is_custom ? list.results[i].id : list.results[i].product_code));
                            providers.push({
                                id: list.results[i].provider.id,
                                identifiers: tmp_identifiers,
                                is_custom: list.results[i].is_custom
                            })
                        }
                    }
                    for (let i = 0; i < providers.length; i++) {
                        $.ajax({
                            method: "POST",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/custom-products/minimal_price/?${(providers[i].is_custom ? "custom_provider=true" : "custom_provider=false")}&arrival_date=${start_date}&departure_date=${end_date}&provider_id=${providers[i].id}&start_destination=${start_destination}&comps=${current_group.id}&trip=${GetCookie("trip_id")}`,
                            data: JSON.stringify({
                                product_identifiers: providers[i].identifiers
                            }),
                            success: function (data) {
                                console.log(data);
                                dispatch(SetPrices(data));
                            }
                        });
                    }
                }
            }
        });
    }
}