//---- Commons ----//
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import GetCookie from "../../../Common/Functions/GetCookie";

//---- Functions ----//
import GetCategories from "./GetCategories";
import GetPassenger from "./GetPassenger";

//---- Actions ----//
import { SetFetchedOptions, SetFetchErrorOptions, ResetFetchErrorOptions, SetEmptyOptions, SetSelectedOption } from "../../../../Actions/FlightSearch";
import { SetOptionCart, SetSelectedCartOption, SetFetchErrorCartOptions, ResetFetchErrorCartOptions } from "../../../../Actions/Flight";

export default (flight, travelers_list, flight_index, segment_index, airports, airlines, currency, trace_id, travelers, dispatch, provider) => {
	const location = window.location;
	if (location.pathname.includes("cart")) {
		dispatch(ResetFetchErrorCartOptions(flight_index, segment_index));
	}
	else {
		dispatch(ResetFetchErrorOptions(flight_index, segment_index));
	}
	const { pass_check, headers } = CheckBeforeRequest();
	if (pass_check) {
		let url = `${API_HREF}client/${window.id_owner}/flights/option/?trip=${GetCookie("trip_id")}&trip_version=${GetCookie("trip_id_version")}&outbound_segments=`;
		let pax_passenger = travelers ? travelers : GetPassenger(travelers_list);
		for (let i = 0; i < flight.outbounds.length; i++)
		{
			let leg = flight.outbounds[i].legs;
			let outbound_segment = "";
			for (let j = 0; j < leg.length; j++)
			{
				let marketing_airline = [];
				if (!leg[j].marketing_airline.airline_code) {
					airlines.map((airline) => {
					    if (airline.id === leg[j].marketing_airline) {
					        marketing_airline = airline;
					    }
					});
				}
				if (j < leg.length - 1) {
				    outbound_segment += leg[j].segment_key + "," + (leg[j].marketing_airline.airline_code ? leg[j].marketing_airline.airline_code : marketing_airline.code) + "," + (leg[j].marketing_flight_number ? leg[j].marketing_flight_number : leg[j].flight_number) + "," + (leg[j].origin_airport ? airports[leg[j].origin_airport].code : leg[j].origin.airport_code) + "," + (leg[j].destination_airport ? airports[leg[j].destination_airport].code : leg[j].destination.airport_code) + "," + (leg[j].departure_datetime_locale ? leg[j].departure_datetime_locale : leg[j].departure_time) + "," + (leg[j].arrival_datetime_locale ? leg[j].arrival_datetime_locale : leg[j].arrival_time) + "," + i + "," + leg[j].booking_code + "," + leg[j].fare_basis + "|";
				}
				else {
				    outbound_segment += leg[j].segment_key + "," + (leg[j].marketing_airline.airline_code ? leg[j].marketing_airline.airline_code : marketing_airline.code) + "," + (leg[j].marketing_flight_number ? leg[j].marketing_flight_number : leg[j].flight_number) + "," + (leg[j].origin_airport ? airports[leg[j].origin_airport].code : leg[j].origin.airport_code) + "," + (leg[j].destination_airport ? airports[leg[j].destination_airport].code : leg[j].destination.airport_code) + "," + (leg[j].departure_datetime_locale ? leg[j].departure_datetime_locale : leg[j].departure_time) + "," + (leg[j].arrival_datetime_locale ? leg[j].arrival_datetime_locale : leg[j].arrival_time) + "," + i + "," + leg[j].booking_code + "," + leg[j].fare_basis;
				}
			}
			if (i !== (flight.outbounds.length - 1)) {
				url += outbound_segment + "|";
			}
			else {
				url += outbound_segment;
			}
		}
		url += "&pax_type=ADT:" + pax_passenger.ADT + ",CNN:" + pax_passenger.CNN + "&currency=" + currency.iso_code + "&trace_id=" + trace_id + "&provider_id=" + provider;
		const res = url.replace(/\+/g, "%2B");
		console.log('res:', res);
		$.ajax({
		    method: "GET",
		    url: res,
		    headers: headers,
		    success: function (data) {
		    	console.log('data:', data);
		    	let options_data = data;
		    	let is_empty = false;
		    	let more_option = false;
		    	options_data.fares.map((fare) => {
		    		fare.option_category.map((option_category) => {
		    			if (option_category.option_segment[segment_index].option.length === 0)
		    				is_empty = true;
		    		});
		    		if (fare.more_option.length > 0) {
		    			more_option = true;
		    			fare.more_option.map((more_option) => {
		    				more_option.checked = false;
		    				more_option.quantity = 1;
		    			});
		    		}
		    	});
		    	if (!is_empty) {
		    		let categories = GetCategories(options_data.fares, segment_index);
		    		options_data.fares.map((fare) => {
		    			fare.option_category.map((option_category) => {
		    				let option = option_category.option_segment[segment_index].option;
		    				let sort_option = option.sort(function (a, b) {
		    					let keyA = a.type;
		    					let keyB = b.type;
		    					// Compare the 2 keys
		    					if(keyA < keyB) return -1;
		    					if(keyA > keyB) return 1;
		    					if(keyA === keyB) {
	    					        let keyC = a.description;
	    					        let keyD = b.description;
	    					        if(keyC > keyD) return 1;
	    					        if(keyC < keyD) return -1;
	    					        return 0;
		    					}
		    				});
		    				let i = 0, j = 0;
	    					let tmp_option = [];
	    					while (i < categories.length && j < sort_option.length) {
	    						if (categories[i].type === sort_option[j].type && categories[i].description === sort_option[j].description) {
	    							tmp_option.push(sort_option[j])
		    						i++;
		    						j++;
	    						}
	    						else {
	    							tmp_option.push({
	    								type: categories[i].type,
	    								description: categories[i].description,
	    								available: false
	    							})
	    							i++;
	    						}
	    					}
	    					option_category.option_segment[segment_index].option = tmp_option;
		    			});
		    		});
		    		let total_price = 0;
		    		if (location.pathname.includes("cart")) {
		    			total_price = parseFloat(flight.prices[0].selling_price);
		    		}
		    		else {
		    			total_price = (flight.adult ? parseFloat(flight.adult.total_price) * pax_passenger.ADT : 0) + (flight.children ? parseFloat(flight.children.total_price) * pax_passenger.CNN : 0) + (flight.baby ? parseFloat(flight.baby.total_price) * pax_passenger.INF : 0);
		    		}
		    		options_data.fares.map((fare, fare_index) => {
		    			let tmp_total_price = 0;
		    			fare.pax_pricing.map((pax) => {
		    				Object.keys(pax_passenger).map((key) => {
		    					if (key !== "total") {
		    						if (key === pax.pax_code) {
		    							tmp_total_price += pax_passenger[key] * parseFloat(pax.prices[0].selling_price);
		    						}
		    					}
		    				});
		    			});
		    			if (tmp_total_price === total_price) {
		    				console.log("fare:", fare);
		    				if (location.pathname.includes("cart")) {
		    					dispatch(SetSelectedCartOption(flight_index, segment_index, fare_index, fare.key_pricing_solution));
		    				}
		    				else {
		    					dispatch(SetSelectedOption(flight_index, segment_index, fare_index, fare.key_pricing_solution));
		    				}

		    			}
		    		});
		    		if (location.pathname.includes("cart")) {
		    			dispatch(SetOptionCart(flight_index, segment_index, options_data, more_option, is_empty));
		    		}
		    		else {
		    			dispatch(SetFetchedOptions(flight_index, segment_index, options_data, more_option, is_empty));
		    		}
		    	}
		    	else {
		    		if (location.pathname.includes("cart")) {
		    			dispatch(SetOptionCart(flight_index, segment_index, options_data, more_option, is_empty));
		    		}
		    		else {
		    			dispatch(SetFetchedOptions(flight_index, segment_index, options_data, more_option, is_empty));
		    		}
		    	}
		    },
		    error: function (error) {
		    	console.log('error:', error);
		    	let error_msg = error.responseJSON ? error.responseJSON : error.statusText;
		    	if (location.pathname.includes("cart")) {
                	dispatch(SetFetchErrorCartOptions(flight_index, segment_index, error_msg));
		    	}
		    	else {
                	dispatch(SetFetchErrorOptions(flight_index, segment_index, error_msg));
		    	}
            }
		});
	}
	else {
	    console.log('error');
	}
}