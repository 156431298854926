import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from "@material-ui/lab/Alert";
import Person from "@material-ui/icons/Person";

import DestinationInput from './DestinationInput';
import DateRangePickerInput from './DateRangePickerInput';
import DatePickerInput from './DatePickerInput';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2)
        }
    },
    gridTop: {
        marginTop: theme.spacing(1)
    },
    gridPadding: {
        padding: '0 30px 0 30px'
    },
    gridTop1: {
        marginTop: theme.spacing(2)
    },
    addSearch: {
        verticalAlign: 'middle',
        marginRight: "10px",
        paddingLeft: '10px'
    },
    formControlRoot: {
        "width": '30%',
        "&& .mui-jss-MuiFormControlLabel-labelPlacementStart": {
            justifyContent: 'space-between'
        },
        "& .mui-jss-MuiInputLabel-outlined": {
            transform: 'translate(14px, 13px) scale(1)'
        },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '9.5px 15px'
        }
    },
    buttonPadding: {
        padding: "17px 4px"
    },
    matchingPadding: {
        padding: 15
    },
    relativePosition: {
        position: "relative"
    },
    marginTop25: {
        marginTop: 25
    },
    paddingLeft10: {
        paddingLeft: 10
    },
    checkboxLabel: {
        ...theme.typography.overline,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.55rem'
        }
    },
    separator: {
        borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
        display: "inline",
        position: "absolute",
        left: 0,
        top: "25%",
        bottom: "25%"
    },
    checkbox: {
        '&:not(.Mui-checked) .PrivateSwitchBase-input': {
            "width": 'auto',
            "height": 'auto',
            "top": 'auto',
            "left": 'auto',
            "opacity": '1',
            "visibility": 'hidden',
            '&::before': {
                content: '""',
                position: 'absolute',
                background: 'white',
                height: "100%",
                width: "100%",
                visibility: "visible"
            }
        }
    }
}));

const RenderInput = ({ search, setSearch, can_return, transfers_list, iti_dates, groups, setGroups, travelers, error1, error2, error3, setError1, setError2, setError3, setTransferList }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between(768, 1024));
    const { origin_index, dest_index, is_return } = search;

    const port_time_warning = useSelector(store => store.transfers.port_time_warning);

    const [groupModal, setGroupModal] = useState(false);

    function toggleGroupModal() {
        setGroupModal(!groupModal);
    }
    function toggleReturn() {
        let tmp_search = Object.assign({}, search);
        tmp_search.is_return = !tmp_search.is_return;
        setSearch(tmp_search);
    }
    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className={`${matches ? classes.gridPadding : ""}`}>
                <form className={classes.root} noValidate autoComplete={"off"}>
                    <Grid container spacing={matches ? 0 : 2}>
                        <Grid item sm={6} md={3} xs={12}>
                            <DestinationInput label={t("transfers.starting_point")} search={search} setSearch={setSearch} type={"origin"} transfers_list={transfers_list} error={error1} setError={setError1} setTransferList={setTransferList}/>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12} style={{ position: "relative" }}>
                            <DestinationInput label={t("transfers.ending_point")} search={search} setSearch={setSearch} type={"dest"} transfers_list={transfers_list} error={error2} setError={setError2} setTransferList={setTransferList}/>
                            {
                                (matches || isTablet) && (
                                    <div className={classes.separator}/>
                                )
                            }
                        </Grid>
                        <Grid container item md={6} sm={12} xs={12} className={isTablet ? classes.gridTop1 : ""}>
                            <Grid item xs={12} style={{ position: "relative" }}>
                                {
                                    is_return && (
                                        <DateRangePickerInput search={search} setSearch={setSearch} iti_dates={iti_dates} transfers_list={transfers_list}/>
                                    )
                                }
                                {
                                    !is_return && (
                                        <DatePickerInput search={search} setSearch={setSearch} iti_dates={iti_dates} transfers_list={transfers_list}/>
                                    )
                                }
                                {
                                    (matches && !isTablet) && (
                                        <div className={classes.separator}/>
                                    )
                                }
                            </Grid>
                        </Grid>
                        {
                            groups.length !== 0 && (
                                <Grid container item md={3} sm={6} xs={12} className={clsx({
                                    [classes.gridTop1]: isTablet,
                                    [classes.relativePosition]: true,
                                    [classes.marginTop25]: matches
                                })}>
                                    <Button variant={ "outlined" } color={ error3 ? "secondary" : "default" } fullWidth={ true } style={{ background: 'white' }} startIcon={<Person/>} onClick={ () => {
                                        toggleGroupModal();
                                    }} className={`${!matches ? classes.buttonPadding : ""}`}>{ groups[0].travelers_list.length } { (groups[0].travelers_list.length > 1 ? t("accommodation.travelers") : t("accommodation.traveler")) }</Button>
                                    {
                                        groupModal && (
                                            <Paper className={ classes.groupModal } style={{ position: "absolute", top: 36, width: "100%", zIndex: 1 }}>
                                                {
                                                    travelers.map((traveler, index_traveler) => {
                                                        let found = groups[0].travelers_list.find((el) => el.id === traveler.id);
                                                        return (
                                                            <div key={ index_traveler } className={ classes.paddingLeft10 }>
                                                                <FormControlLabel control={ <Checkbox sx={{
                                                                    '&.Mui-checked': {
                                                                        color: `${main_color}`
                                                                    }
                                                                }} checked={ found !== undefined } onChange={(e) => {
                                                                    let tmp_groups = groups.slice();
                                                                    let travelers_list = tmp_groups[0].travelers_list.slice();
                                                                    let add = true;
                                                                    for (let i = 0; i < travelers_list.length; i++) {
                                                                        if (travelers_list[i].id === traveler.id) {
                                                                            travelers_list.splice(i, 1);
                                                                            add = false;
                                                                            break;
                                                                        }
                                                                    }
                                                                    if (add === true) {
                                                                        travelers_list.push(traveler);
                                                                    }
                                                                    if (travelers_list.length > 0) {
                                                                        setError3(false);
                                                                    } else {
                                                                        setError3(true);
                                                                    }
                                                                    tmp_groups[0].travelers_list = travelers_list;
                                                                    setGroups(tmp_groups);
                                                                }}/> } label={ traveler.age_label }/>
                                                            </div>
                                                        );
                                                    })
                                                }
                                                <Button variant={ "contained" } className={ `${classes.orangeButton} ${classes.marginTop}` } fullWidth={ true } onClick={() => {
                                                    toggleGroupModal();
                                                }}>{ t("global.finish") }</Button>
                                            </Paper>
                                        )
                                    }
                                </Grid>
                            )
                        }
                        <Grid item sm={6} xs={12} className={clsx({
                            [classes.gridTop1]: isTablet,
                            [classes.relativePosition]: true,
                            [classes.marginTop25]: matches
                        })}>
                            {
                                // can_return && (
                                <FormControlLabel
                                    style={{ marginLeft: 20 }}
                                    control={<Checkbox checked={search.is_return} onChange={() => {
                                        toggleReturn();
                                    }} sx={{
                                        '&.Mui-checked': {
                                            color: `${main_color}`
                                        }
                                    }} className={classes.checkbox}/>}
                                    label={ <span className={classes.checkboxLabel}>{t("transfers.return_transfer")}</span> }
                                />
                                // )
                            }
                        </Grid>
                    </Grid>
                    {
                        port_time_warning && (
                            <Alert severity={ "warning" } variant={ "filled" }>
                                { t("transfers.requested_port_time_warning")}
                            </Alert>
                        )
                    }
                    {
                        search.origin_type === "accommodation" && (search.dest_type === "airport" || search.dest_type === "station") && (
                            <Alert severity={ "warning" } variant={ "filled" }>
                                { t("transfers.requested_time_warning")}
                            </Alert>
                        )
                    }
                </form>
            </Grid>
        </Grid>

    );
};
export default React.memo(RenderInput);
