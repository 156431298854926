// Dependencies
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Core & Lab
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CartAssistanceVip = () => {
  const dispatch = useDispatch();
  const assistance_pax = useSelector(state => state.cart.assistance_pax);
  const travelers = useSelector(state => state.trip.travelers);
  const toggleTraveler = (traveler_id) => () => {
    dispatch({type: 'CART_TOGGLE_ASSISTANCE_TRAVELER', payload: traveler_id});
  };
  return (
    <>
      {
        travelers.map(traveler => (
          <FormGroup>
            <FormControlLabel control={<Checkbox defaultChecked checked={assistance_pax.includes(traveler.id)} onChange={toggleTraveler(traveler.id)}/>}  label={ `${traveler.first_name} ${traveler.last_name}` } />
          </FormGroup>
        ))
      }
    </>
  );
};

export default CartAssistanceVip;