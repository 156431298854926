/*eslint-disable max-params */
/*eslint-disable curly */
import axios from "axios";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest.js";

export const getTrip = (client_id, params, callback, errorCallback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: 'GET',
            url: `${API_HREF}client/${client_id}/trip/?${params}`,
            headers,
            params: {
                limit: 10
            }
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            console.log('error:', error);
            let result = "";
            if (error?.response?.data) {
                for (const [key, value] of Object.entries(error.response.data)) {
                    result += `${key}: ${value[0]}\n`;
                }
            }
            if (errorCallback !== undefined) errorCallback(result);
        });
    }
};

export const getTripDocuments = (client_id, trip_id, version_id, callback, errorCallback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: 'GET',
            url: `${API_HREF}client/${client_id}/trip/${trip_id}/versions/${version_id}/file/`,
            headers
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            console.log('error:', error);
            let result = "";
            if (error?.response?.data) {
                for (const [key, value] of Object.entries(error.response.data)) {
                    result += `${key}: ${value[0]}\n`;
                }
            }
            if (errorCallback !== undefined) errorCallback(result);
        });
    }
};

//DELETE DOCUMENTS
export const deleteTripDocuments = (client, trip_id, version_id, file_id, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "DELETE",
            headers: headers,
            url: `${API_HREF}client/${client}/trip/${trip_id}/versions/${version_id}/file/${file_id}/`
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

export const getTripById = (client_id, type, trip_id, callback, errorCallback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: 'GET',
            url: `${API_HREF}client/${client_id}/trip/${trip_id}/?${type}`,
            headers
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            console.log('error:', error);
            let result = "";
            if (error?.response?.data) {
                for (const [key, value] of Object.entries(error.response.data)) {
                    result += `${key}: ${value[0]}\n`;
                }
            }
            if (errorCallback !== undefined) errorCallback(result);
        });
    }
};

export const getDemands = (client, limit, offset, filter, ordering, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client}/trip/?is_sketch=true&limit=${limit ? limit : 100}&offset=${offset ? offset : 0}&ordering=${ordering ? ordering : '-date_joined'}`,
            params: filter
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

export const getOrganisations = (client_id, search, callback, errorCallback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: 'GET',
            url: `${API_HREF}client/${client_id}/children/`,
            headers,
            params: {
                search,
                limit: 250,
                is_active: true,
                type: "1,2"
            }
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            console.log('error:', error);
            let result = "";
            if (error?.response?.data) {
                for (const [key, value] of Object.entries(error.response.data)) {
                    result += `${key}: ${value[0]}\n`;
                }
            }
            if (errorCallback !== undefined) errorCallback(result);
        });
    }
};

export const getCountries = (client_id, search, callback, errorCallback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: 'GET',
            url: `${API_HREF}iata-countries/`,
            headers,
            params: {
                search,
                limit: 1000
            }
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            console.log('error:', error);
            let result = "";
            if (error?.response?.data) {
                for (const [key, value] of Object.entries(error.response.data)) {
                    result += `${key}: ${value[0]}\n`;
                }
            }
            if (errorCallback !== undefined) errorCallback(result);
        });
    }
};

let cancelToken = null;
export const getDestinations = (client_id, search, callback, errorCallback) => {
    if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: 'GET',
            url: `${API_HREF}client/${client_id}/destinations/`,
            headers,
            cancelToken: cancelToken.token,
            params: {
                reduced: true,
                limit: 20,
                search
            }
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            console.log('error:', error);
            let result = "";
            if (error?.response?.data) {
                for (const [key, value] of Object.entries(error.response.data)) {
                    result += `${key}: ${value[0]}\n`;
                }
            }
            if (errorCallback !== undefined) {
                errorCallback(result);
            }
        });
    }
};

export const getCurrencies = (client_id, search, callback, errorCallback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: 'GET',
            url: `${API_HREF}currencies/`,
            headers,
            params: {
                search,
                limit: 250
            }
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            console.log('error:', error);
            let result = "";
            if (error?.response?.data) {
                for (const [key, value] of Object.entries(error.response.data)) {
                    result += `${key}: ${value[0]}\n`;
                }
            }
            if (errorCallback !== undefined) errorCallback(result);
        });
    }
};

export const patchTrip = (client_id, trip_id, data, params, callback, errorCallback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: 'PATCH',
            url: `${API_HREF}client/${client_id}/trip/${trip_id}/`,
            headers,
            params,
            data
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            console.log('error:', error);
            let result = "";
            if (error?.response?.data) {
                for (const [key, value] of Object.entries(error.response.data)) {
                    result += `${key}: ${value[0]}\n`;
                }
            }
            if (errorCallback !== undefined) errorCallback(result);
        });
    }
};

export const patchTripVersion = (client_id, trip_id, verions_id, data, params, callback, errorCallback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: 'PATCH',
            url: `${API_HREF}client/${client_id}/trip/${trip_id}/versions/${verions_id}/`,
            headers,
            params,
            data
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            console.log('error:', error);
            let result = "";
            if (error?.response?.data) {
                for (const [key, value] of Object.entries(error.response.data)) {
                    result += `${key}: ${value[0]}\n`;
                }
            }
            if (errorCallback !== undefined) errorCallback(result);
        });
    }
};

export const postTrip = (client_id, data, callback, errorCallback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: 'POST',
            url: `${API_HREF}client/${client_id}/trip/`,
            headers,
            data
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            console.log('error:', error);
            let result = "";
            if (error?.response?.data) {
                for (const [key, value] of Object.entries(error.response.data)) {
                    result += `${key}: ${value[0]}\n`;
                }
            }
            if (errorCallback !== undefined) errorCallback(result);
        });
    }
};
