export default (a, b) => {
    let is_same = true;
    if ((a.fare_type !== b.fare_type) || (a.flight_stop_over !== b.flight_stop_over)
        || (a.provider_id !== b.provider_id) || (a.total_price !== b.total_price)){
            is_same = false;
    }else{
        if (a.outbounds.length !== b.outbounds.length) {
            is_same = false;
        } else {
            a.outbounds.map((a_outbound, outbound_index) => {
                if ((a_outbound.flight_duration !== b.outbounds[outbound_index].flight_duration) || (a_outbound.operate_by !== b.outbounds[outbound_index].operate_by)){
                    is_same = false;
                }else{
                    if (a_outbound.legs.length !== b.outbounds[outbound_index].legs.length){
                        is_same = false;
                    } else {
                        a_outbound.legs.map((a_leg, leg_index) => {
                            let b_leg = b.outbounds[outbound_index].legs[leg_index];
                            if ((a_leg.arrival_datetime_lt !== b_leg.arrival_datetime_lt) || (a_leg.baggage_allowance.number_of_pieces !== b_leg.baggage_allowance.number_of_pieces)
                                || (a_leg.baggage_allowance.max_weight.value !== b_leg.baggage_allowance.max_weight.value) || (a_leg.baggage_allowance.max_weight.unit !== b_leg.baggage_allowance.max_weight.unit)
                                || (a_leg.booking_code !== b_leg.booking_code) || (a_leg.cabin_class !== b_leg.cabin_class) || (a_leg.departure_datetime_lt !== b_leg.departure_datetime_lt)
                                || (a_leg.destination_airport !== b_leg.destination_airport) || (a_leg.destination_station !== b_leg.destination_station) || (a_leg.fare_basis !== b_leg.fare_basis)
                                || (a_leg.marketing_airline !== b_leg.marketing_airline) || (a_leg.marketing_flight_number !== b_leg.marketing_flight_number)
                                || (a_leg.operating_airline !== b_leg.operating_airline) || (a_leg.operating_flight_number !== b_leg.operating_flight_number)
                                || (a_leg.origin_airport !== b_leg.origin_airport) || (a_leg.origin_station !== b_leg.origin_station ) || (a_leg.segment_key !== b_leg.segment_key)){
                                is_same = false;
                            }
                        })
                    }
                }
            });
        }
    }
    return is_same;
}