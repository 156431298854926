// Dependencies
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Core
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemText from '@material-ui/core/ListItemText';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Icons
import Close from '@material-ui/icons/Close';
import NavigateBefore from '@material-ui/icons/NavigateBefore';

const useStyles = makeStyles({
    bold: {
        fontWeight: 'bold'
    }
});

const TransferDialog = ({ openDialog, setOpenDialog, cardDescription, cardCancellation }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return(
        <Dialog fullScreen={ fullScreen } PaperProps={{style: { borderRadius: fullScreen ? 0 : 16 } }} fullWidth maxWidth={ 'md' } open={ openDialog } onClose={() => {
            setOpenDialog(false);
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
            }}>
            <DialogTitle onClose={() => { setOpenDialog(false) } }>
                <Grid container justify={ 'space-between' } alignItems={ 'center' }>
                    {
                        fullScreen ? (
                            <Grid item style={{fontWeight: 'bold'}}>
                                <IconButton edge={ 'start' } onClick={() => { setOpenDialog(false) }}><NavigateBefore/></IconButton> { cardDescription !== null && cardDescription.name !== undefined ? cardDescription.name : '' }
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item style={{fontWeight: 'bold', width: '90%'}}>
                                    { cardDescription !== null && cardDescription.name !== undefined ? cardDescription.name : '' }
                                    { cardCancellation !== null ? t('transfers.cancellation_title') + ' :': '' }
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => { setOpenDialog(false) }}><Close/></IconButton>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
            {
                 cardDescription !== null && cardDescription.split_description !== undefined && cardDescription.split_description.length !== 0 &&
                 <Grid container>
                     <Typography className={ classes.bold } variant={ 'h6' }>{ t('transfers.general_information') }</Typography>
                     <List>
                         {
                             cardDescription.split_description.map((description, description_index) => (
                                 <ListItem key={ description_index }>
                                     <ListItemText primary={ description }/>
                                 </ListItem>
                             ))
                         }
                     </List>
                 </Grid>
            }
            {
                 cardDescription !== null && ((cardDescription.supplier_transfer_info !== undefined && cardDescription.supplier_transfer_info !== null && cardDescription.supplier_transfer_info.length !== 0) || (cardDescription.customer_transfer_info !== undefined && cardDescription.customer_transfer_info !== null && cardDescription.customer_transfer_info.length !== 0))  && (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className={ classes.bold } variant={ 'h6' }>{ t('transfers.waiting_time') }</Typography>
                        </Grid>
                        {
                            (cardDescription.customer_transfer_info !== undefined && cardDescription.customer_transfer_info !== null && cardDescription.customer_transfer_info.length !== 0) && (
                                <Grid item xs={ 12 }>
                                    { cardDescription.customer_transfer_info[0].value === 0 ? t('transfers.no_waiting_time') : t('transfers.waiting_time_customer') + cardDescription.customer_transfer_info[0].value + ' ' + t('global.' +cardDescription.customer_transfer_info[0].metric) }
                                </Grid>
                            )
                        }
                        {
                            (cardDescription.supplier_transfer_info !== undefined && cardDescription.supplier_transfer_info !== null && cardDescription.supplier_transfer_info.length !== 0) && (
                                <Grid item xs={12}>
                                { t('transfers.supplier_max_waiting_time') }
                                {
                                    cardDescription.supplier_transfer_info.map((waiting, waiting_index) => {
                                        let domestic = (waiting.type.toLowerCase()).includes('domestic');
                                        return <span key={waiting_index}>{waiting.value + ' ' + waiting.metric + ' (' + (domestic ? t('transfers.domestic') : t('transfers.international')) + ')'}</span>;
                                    })
                                }
                                </Grid>
                            )
                        }
                    </Grid>
                )
            }
            {
                (cardCancellation !== null && cardCancellation.rules !== undefined) && (
                    <Grid item xs={ 12 }>
                        {
                            cardCancellation.rules.map((rule, index) => {
                                let limit_date = moment(rule.from_when).format('L');
                                let currency = cardCancellation.currency_list.find((currency) => { return currency.id === rule.currency });
                                return <span key={ index }>{ t('transfers.cancellation') } { limit_date } : { rule.amount } { currency !== undefined && currency !== null ? currency.symbol : '' }</span>;
                            })
                        }
                    </Grid>
                )
            }
            </DialogContent>
        </Dialog>
    );
};

export default React.memo(TransferDialog);