import { Picture } from "../../Menu/MaterialTripList/picture/objects/picture";

export function findPictureUrl(picture: Picture): string {
    return [
        picture.url,
        picture.thumbnail_big,
        picture.thumbnail_medium,
        picture.thumbnail_little
    ].find((picture) => {
        return (picture?.length ?? 0) > 0; 
    }) ?? '';
}
