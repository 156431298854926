//Dependencies
import clsx from 'clsx';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, Fragment, useRef } from 'react';

//Actions
//Core
import { List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

//Icons
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';


//Actions
import { CarCart } from '../Itinerary/objects/carCart';
import { AppState } from '../../Reducers/Reducers';
import { useSelector } from 'react-redux';

type Props = {
    car: CarCart,
    car_index: number
}

export const CarDetails = withRouter<Props>(
    function CarDetails({ car }): JSX.Element {
        const classes = useStyles();
        const { t } = useTranslation();
        const language = useSelector((store: AppState) => {
            return store.header.tmp_language;
        });
        const currency = useSelector((store: AppState) => {
            return store.trip.currency;
        });
        return (
            <>
                <Grid
                    id={`product-${car.id}`}
                    className={classes.scrollMargin}
                    container
                    item
                    xs
                    direction={"column"}
                    spacing={ 2 }
                >
                    <Grid item container spacing={2}>
                        <Grid item xs>
                            <Grid container>
                                {
                                    (car.vehicle_info.return_fuel_full !== null
                                        || car.vehicle_info.accident_franchise !== null
                                        || car.vehicle_info.theft_franchise !== null
                                        || car.vehicle_info.young_taxes_age !== null
                                        || car.vehicle_info.young_taxes_amount !== null) && (
                                        <Grid item container className={`${classes.ftMt20}`}>
                                            {
                                                //<Grid item xs={12} className={ classes.carInfoTitle }/>
                                            }
                                            <Grid item container direction="row" alignItems="center" xs={12} className={clsx({ [classes.ftGray]: true, [classes.bold]: true, [classes.font20]: true })}>
                                                {t<string>("cars.important_information")}
                                            </Grid>
                                            <Grid item container direction="row" alignItems="center" xs={12}>
                                                <List dense={true}>
                                                    {
                                                        car.vehicle_info.return_fuel_full !== null && (
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary={car.vehicle_info.return_fuel_full ? t<string>("cars.return_fuel_full") : t<string>("cars.return_fuel_not_full") }
                                                                />
                                                            </ListItem>
                                                        )
                                                    }
                                                    {
                                                        car.vehicle_info.accident_franchise !== null && (
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary={t<string>("cars.accident_franchise") + new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(car.vehicle_info.accident_franchise))}
                                                                />
                                                            </ListItem>
                                                        )
                                                    }
                                                    {
                                                        car.vehicle_info.theft_franchise !== null && (
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary={t<string>("cars.theft_franchise") + new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(car.vehicle_info.theft_franchise))}
                                                                />
                                                            </ListItem>
                                                        )
                                                    }
                                                    {
                                                        car.vehicle_info.young_taxes_age !== null && (
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary={t<string>("cars.young_taxes_age") + car.vehicle_info.young_taxes_age + " " + t<string>("global.years")}
                                                                />
                                                            </ListItem>
                                                        )
                                                    }
                                                    {
                                                        car.vehicle_info.young_taxes_amount !== null && (
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary={t<string>("cars.young_taxes_amount") + new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(car.vehicle_info.young_taxes_amount))}
                                                                />
                                                            </ListItem>
                                                        )
                                                    }
                                                </List>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
);

const useStyles = makeStyles(() => ({
    scrollMargin: {
        scrollMarginTop: '75px'
    },
    gridContent: {
        padding: '0 15px'
    },
    containerMargin: {
        marginBottom: 4
    },
    service: {
        float: 'left',
        marginRight: 4
    },
    bold: {
        fontWeight: 'bold'
    },
    font20: {
        fontSize: 20
    },
    ftMt20: {
        marginTop: 20
    },
    ftGray: {
        opacity: '54%'
    }
}));
