export function SetCartToBook(cart_to_book) {
   return {
        type: "BOOKING_SET_CART_TO_BOOK",
        payload: {
            cart_to_book: cart_to_book
        }
    }; 
}

export function SetOldCart(old_cart) {
   return {
        type: "BOOKING_SET_OLD_CART",
        payload: {
            old_cart: old_cart
        }
    }; 
}


export function SetBookingInfo(booking_info) {
   return {
        type: "BOOKING_SET_INFO",
        payload: {
            booking_info: booking_info
        }
    }; 
}

export function ToggleBookingModal(booking_modal) {
   return {
        type: "BOOKING_MODAL",
        payload: {
            booking_modal: booking_modal
        }
    }; 
}

export function ToggleModalMultiRoomDetailBooking(modal_multi_room_detail_booking, room_booked_info_multi) {
   return {
        type: "BOOKING_TOGGLE_MODAL_MULTI_ROOM_DETAIL_BOOKING",
        payload: {
            modal_multi_room_detail_booking: modal_multi_room_detail_booking,
            room_booked_info_multi: room_booked_info_multi
        }
    }; 
}
export function ToggleModalDetailBooking(modal_detail_booking, room_booked_info, type) {
   return {
        type: "BOOKING_TOGGLE_MODAL_DETAIL_BOOKING",
        payload: {
            modal_detail_booking: modal_detail_booking,
            room_booked_info: room_booked_info,
            booking_info_type: type
        }
    }; 
}

export function ChangeModalState(modal_state) {
   return {
        type: "BOOKING_CHANGE_MODAL_STATE",
        payload: {
            modal_state: modal_state
        }
    }; 
}
export function ChangeModalStateAndSetBookingState(modal_state, is_booking) {
   return {
        type: "BOOKING_CHANGE_MODAL_STATE_AND_BOOKING_STATE",
        payload: {
            modal_state: modal_state,
            is_booking: is_booking
        }
    }; 
}
export function SetIsBooking(is_booking) {
   return {
        type: "BOOKING_SET_IS_BOOKING",
        payload: {
            is_booking: is_booking
        }
    }; 
}

export function ChangeModalStateAndSetError(modal_state, error) {
    return {
        type: "BOOKING_CHANGE_MODAL_STATE_AND_SET_ERROR",
        payload: {
            modal_state: modal_state,
            error: error
        }
    };
}

export function AddCartToBook(cart_to_book) {
    return {
        type: "BOOKING_ADD_CART_TO_BOOK",
        payload: {
            cart_to_book: cart_to_book
        }
    };
}

export function RemoveFromToBook(index) {
    return {
        type: "BOOKING_REMOVE_FROM_TO_BOOK",
        payload: {
            index: index
        }
    };
}

export function AddBoookingLaunched(number) {
    return {
        type: "BOOKING_ADD_BOOKING_LAUNCHED",
        payload: {
            number: number
        }
    }
}

export function SetChecksPassed(checks_passed) {
    return {
        type: "BOOKING_SET_CHECKS_PASSED",
        payload: {
            checks_passed: checks_passed
        }
    }
}

export function SetSameTravelerID(same_traveler_id) {
    return {
        type: "BOOKING_SET_SAME_TRAVELER_ID",
        payload: {
            same_traveler_id: same_traveler_id
        }
    }
}

export function ResetBookingLaunched() {
    return {
        type: "BOOKING_RESET_LAUNCHED",
        payload: {}
    }
}

export function AddRefreshedProduct() {
    return {
        type: "BOOKING_ADD_REFRESHED_PRODUCT",
        payload: {}
    }
}

export function EmptyRefreshedProduct() {
    return {
        type: "BOOKING_EMPTY_REFRESHED_PRODUCT",
        payload: {}
    }
}

export function ReloadTempTravelers(reload_temp_travelers){
    return {
        type: "BOOKING_RELOAD_TEMP_TRAVELERS",
        payload:{
            reload_temp_travelers: reload_temp_travelers
        }
    }
}

export function AddError(error){
    return {
        type: "BOOKING_ADD_ERROR",
        payload: {
            error: error
        }
    }
}

export function EmptyError(error){
    return{
        type: "BOOKING_EMPTY_ERROR",
        payload: {}
    }
}