export function ToggleRightPanel(panel_type) {
    return {
        type: "BASE_TOGGLE_PANEL_TYPE",
        payload: {
            panel_type: panel_type
        }
    }
}

export function SetCurrencyList(currency) {
   return {
        type: "BASE_SET_CURRENCY",
        payload: {
            currency: currency
        }
    }
}

export function SetDefaultPictures(pictures) {
    return {
        type: "BASE_SET_DEFAULT_PICTURES",
        payload: {
            pictures: pictures
        }
    }
}

export function SetCenterZoomBounds(center_zoom_bounds, active) {
    return {
        type: "BASE_SET_CENTER_ZOOM_BOUNDS",
        payload: {
            center_zoom_bounds: center_zoom_bounds,
            active: active
        }
    }
}

export function ZoomOnDestination(bounds, active) {
    return {
        type: "BASE_ZOOM_ON_DESTINATION",
        payload: {
            bounds: bounds,
            active: active
        }
    }
}

export function ZoomOnItinerary() {
    return {
        type: "BASE_ZOOM_ON_ITINERARY"
    }
}

export function LockItineraryActions() {
    return {
        type: "BASE_LOCK_ITINERARY_ACTIONS"
    }
}

export function UnlockItineraryActions() {
    return {
        type: "BASE_UNLOCK_ITINERARY_ACTIONS"
    }
}
export function SetItineraryDone(itinerary_done) {
    return {
        type: "BASE_SET_ITINERARY_DONE",
        payload: {
            itinerary_done: itinerary_done
        }
    }
}
export function SetPlaneDone(plane_done) {
    return {
        type: "BASE_SET_PLANE_DONE",
        payload: {
            plane_done: plane_done
        }
    }
}
export function SetCarsDone(cars_done) {
    return {
        type: "BASE_SET_CARS_DONE",
        payload: {
            cars_done: cars_done
        }
    }
}
export function SetAccommodationDone(accommodation_done) {
    return {
        type: "BASE_SET_ACCOMMODATION_DONE",
        payload: {
            accommodation_done: accommodation_done
        }
    }
}
export function SetPoiDone(poi_done) {
    return {
        type: "BASE_SET_POI_DONE",
        payload: {
            poi_done: poi_done
        }
    }
}
