import restorers from "./restore";
import { Block } from "../objects/block";
import { compare, compareVersions } from "compare-versions";
import { isBlock } from "../utils/is-block";

type Options = {
    type: string,
    html: string,
    quotationCode: string | null,
    registerChildren?: (blocks: Block<any>[]) => void
}

class Restorer {
    public restoreFromVersion(
        version: string,
        options: Options
    ): Block<any> | null {
        const compatibleVersion = Object.keys(restorers).filter((item) => {
            return compare(item, version, '<=');
        }).sort(compareVersions).reverse()[0] ?? '0.0.0';
        const blockRestorer = (restorers[compatibleVersion] ?? {})[options.type];

        if (blockRestorer) {
            const result = blockRestorer.restore({
                version: compatibleVersion,
                html: options.html,
                quotationCode: options.quotationCode
            });

            if (!isBlock(result)) {
                if (options.registerChildren) {
                    options.registerChildren(result.children);
                }
                return result.content;
            }

            return result;
        }

        return null;
    }
}

export const restorer = new Restorer();
