export function SetTripData(data_trip, travelers, budget, currency, contact_traveler, contact_traveler_detail, trip_reference) {
    return {
        type: "TRIP_SET_DATA",
        payload: {
            data_trip: data_trip,
            travelers: travelers,
            budget: budget,
            currency: currency,
            contact_traveler: contact_traveler,
            contact_traveler_detail: contact_traveler_detail,
            trip_reference: trip_reference
        }
    };
}

export function SetDataTrip(data_trip) {
    return {
        type: "TRIP_SET_DATA_TRIP",
        payload: {
            data_trip: data_trip
        }
    };
}
export function EditTripMarginFlight(flag) {
    return {
        type: "TRIP_EDIT_MARGIN_FLIGHT",
        payload: {
            flag: flag
        }
    };
}
export function EditTripMarginTerrestrial(flag) {
    return {
        type: "TRIP_EDIT_MARGIN_TERRESTRIAL",
        payload: {
            flag: flag
        }
    };
}
export function EditTravelerGroups(traveler_group, index) {
    return {
        type: "TRIP_EDIT_TRAVELER_GROUPS",
        payload: {
            traveler_group: traveler_group,
            index: index
        }
    };
}

export function SetBudget(budget) {
    return {
        type: "TRIP_SET_BUDGET",
        payload: {
            budget: budget
        }
    };
}

export function SetContactTraveler(contact_traveler, contact_traveler_detail) {
    return {
        type: "TRIP_SET_CONTACT_TRAVELER",
        payload: {
            contact_traveler: contact_traveler,
            contact_traveler_detail: contact_traveler_detail
        }
    };
}

export function SetCurrency(currency) {
    return {
        type: "TRIP_SET_CURRENCY",
        payload: {
            currency: currency
        }
    };
}

export function SetDates({startDate, endDate}) {
    return {
        type: "TRIP_SET_DATES",
        payload: {
            start_date: startDate,
            end_date: endDate
        }
    };
}

export function SetStartDate(start_date) {
    return {
        type: "TRIP_SET_START_DATE",
        payload: {
            start_date: (start_date === null ? null : start_date.format())
        }
    };
}

export function SetEndDate(end_date) {
    return {
        type: "TRIP_SET_END_DATE",
        payload: {
            end_date: (end_date === null ? null : end_date.format())
        }
    };
}

export function SetTripId(trip_id) {
    return {
        type: "TRIP_SET_ID",
        payload: {
            trip_id: trip_id
        }
    };
}

export function SetTravelers(travelers) {
    return {
        type: "TRIP_SET_TRAVELERS",
        payload: {
            travelers: travelers
        }
    };
}

export function EditTraveler(traveler, index) {
    return {
        type: "TRIP_EDIT_TRAVELERS",
        payload: {
            traveler: traveler,
            index: index
        }
    };
}

export function AddTravelers(traveler) {
    return {
        type: "TRIP_ADD_TRAVELERS",
        payload: {
            traveler: traveler
        }
    };
}


export function RemoveTravelers(traveler) {
    return {
        type: "TRIP_REMOVE_TRAVELER",
        payload: {
            traveler: traveler
        }
    };
}

export function ResetTravelers() {
    return {
        type: "TRIP_RESET_TRAVELERS"
    };
}
export function SetTravelerGroups(traveler_groups) {
    return {
        type: "TRIP_SET_TRAVELER_GROUPS",
        payload: {
            traveler_groups: traveler_groups
        }
    };
}
export function AddTravelerGroups(traveler_group) {
    return {
        type: "TRIP_ADD_TRAVELER_GROUPS",
        payload: {
            traveler_group: traveler_group
        }
    };
}

export function SetProviders(providers) {
    return {
        type: "TRIP_SET_PROVIDERS",
        payload: {
            providers: providers
        }
    };
}

export function SetManualProviders(manual_providers) {
    return {
        type: "TRIP_SET_MANUAL_PROVIDERS",
        payload: {
            manual_providers: manual_providers
        }
    };
}

export function SetTravelerId(index, traveler_id) {
    return {
        type: "TRIP_SET_TRAVELER_ID",
        payload: {
            index: index,
            traveler_id: traveler_id
        }
    };
}
