function CheckIfPermChanged(type, old_perm, new_perm) {
	let has_changed = false;

	if (old_perm.length !== 0 && type === "add") {
		for (let i = 0; i < old_perm.length; i++) {
			if (old_perm[i].codename === new_perm) {
				has_changed = true;
				break;
			} else {
				has_changed = false;
			}
		}
	} 
	if (old_perm.length === 0 && type === "add") {
		has_changed = true;
	}

	if (old_perm.length !== 0 && type === "delete") {
		for (let i = 0; i < old_perm.length; i++) {
			if (old_perm[i].codename === new_perm) {
				has_changed = true;
				break;
			} else {
				has_changed = false;
			}
		}
	}
	if (old_perm.length === 0 && type === "delete") {
		has_changed = false;
	}
	return has_changed;
}

function GetPermId(old_perm, new_perm) {
	let id;

	for (let i = 0; i < old_perm.length; i++) {
		if (old_perm[i].codename === new_perm) {
			id = old_perm[i].id;
			break;
		}
	}
	return id;
}

export default (permissions, userPermission, viewUser, addUser,	changeUser,	deleteUser,	viewMarginPrice, addMarginPrice, changeMarginPrice, deleteMarginPrice, viewAuthGroup, addAuthGroup, changeAuthGroup,	deleteAuthGroup, viewTrip, addTrip,	changeTrip,	deleteTrip) => {
	let ids_arr_to_post = [];
	// let ids_arr_to_delete = [];
	let old_perm = userPermission;
	// if (CheckIfPermChanged("delete", old_perm, "view_user") === true && component.state.view_user === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "view_user"));
	// }
	// if (CheckIfPermChanged("delete", old_perm, "add_user") === true && component.state.add_user === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "add_user"));
	// }
	// if (CheckIfPermChanged("delete", old_perm, "change_user") === true && component.state.change_user === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "change_user"));
	// }
	// if (CheckIfPermChanged("delete", old_perm, "delete_user") === true && component.state.delete_user === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "delete_user"));
	// }
	// if (CheckIfPermChanged("delete", old_perm, "view_marginprice") === true && component.state.view_marginprice === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "view_marginprice"));
	// }
	// if (CheckIfPermChanged("delete", old_perm, "add_marginprice") === true && component.state.add_marginprice === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "add_marginprice"));
	// }
	// if (CheckIfPermChanged("delete", old_perm, "change_marginprice") === true && component.state.change_marginprice === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "change_marginprice"));
	// }
	// if (CheckIfPermChanged("delete", old_perm, "delete_marginprice") === true && component.state.delete_marginprice === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "delete_marginprice"));
	// }
	// if (CheckIfPermChanged("delete", old_perm, "view_authgroup") === true && component.state.view_authgroup === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "view_authgroup"));
	// }
	// if (CheckIfPermChanged("delete", old_perm, "add_authgroup") === true && component.state.add_authgroup === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "add_authgroup"));
	// }
	// if (CheckIfPermChanged("delete", old_perm, "change_authgroup") === true && component.state.change_authgroup === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "change_authgroup"));
	// }
	// if (CheckIfPermChanged("delete", old_perm, "delete_authgroup") === true && component.state.delete_authgroup === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "delete_authgroup"));
	// }
	// if (CheckIfPermChanged("delete", old_perm, "view_trip") === true && component.state.view_trip === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "view_trip"));
	// }
	// if (CheckIfPermChanged("delete", old_perm, "add_trip") === true && component.state.add_trip === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "add_trip"));
	// }
	// if (CheckIfPermChanged("delete", old_perm, "change_trip") === true && component.state.change_trip === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "change_trip"));
	// }
	// if (CheckIfPermChanged("delete", old_perm, "delete_trip") === true && component.state.delete_trip === false) {
	// 	ids_arr_to_delete.push(GetPermId(permissions, "delete_trip"));
	// }

	if (viewUser === true) {
		ids_arr_to_post.push(GetPermId(permissions, "view_user"));
	}
	if (addUser === true) {
		ids_arr_to_post.push(GetPermId(permissions, "add_user"));
	}
	if (changeUser === true) {
		ids_arr_to_post.push(GetPermId(permissions, "change_user"));
	}
	if (deleteUser === true) {
		ids_arr_to_post.push(GetPermId(permissions, "delete_user"));
	}
	if (viewMarginPrice === true) {
		ids_arr_to_post.push(GetPermId(permissions, "view_marginprice"));
	}
	if (addMarginPrice === true) {
		ids_arr_to_post.push(GetPermId(permissions, "add_marginprice"));
	}
	if (changeMarginPrice === true) {
		ids_arr_to_post.push(GetPermId(permissions, "change_marginprice"));
	}
	if (deleteMarginPrice === true) {
		ids_arr_to_post.push(GetPermId(permissions, "delete_marginprice"));
	}
	if (viewAuthGroup === true) {
		ids_arr_to_post.push(GetPermId(permissions, "view_authgroup"));
	}
	if (addAuthGroup === true) {
		ids_arr_to_post.push(GetPermId(permissions, "add_authgroup"));
	}
	if (changeAuthGroup === true) {
		ids_arr_to_post.push(GetPermId(permissions, "change_authgroup"));
	}
	if (deleteAuthGroup === true) {
		ids_arr_to_post.push(GetPermId(permissions, "delete_authgroup"));
	}
	if (viewTrip === true) {
		ids_arr_to_post.push(GetPermId(permissions, "view_trip"));
	}
	if (addTrip === true) {
		ids_arr_to_post.push(GetPermId(permissions, "add_trip"));
	}
	if (changeTrip === true) {
		ids_arr_to_post.push(GetPermId(permissions, "change_trip"));
	}
	if (deleteTrip === true) {
		ids_arr_to_post.push(GetPermId(permissions, "delete_trip"));
	}

	return ids_arr_to_post;	
};
