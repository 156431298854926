import React, { useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { styled, Typography } from "@mui/material";
import { Editor, EditorState, Modifier } from "draft-js";
import { Map } from "immutable";
import { clone } from "lodash";
import RichEditor, { RichEditorStyleMap } from '../../utils/editor/editor';
import { MailTemplateVisualEditorBackgroundColorOption } from "../mailTemplateVisualEditorBackgroundColorOption";
import { MailTemplateVisualEditorBorderStyle, MailTemplateVisualEditorBorderStyleOption } from "../mailTemplateVisualEditorBorderStyleOption";
import { MailTemplateVisualEditorBorderWidthOption } from "../mailTemplateVisualEditorBorderWidthOption";
import { MailTemplateVisualEditorBorderCornersOption, MailTemplateVisualEditorCornersOption } from "../mailTemplateVisualEditorBorderCornersOption";
import { MailTemplateVisualEditorBorderColorOption } from "../mailTemplateVisualEditorBorderColorOption";
import { MailTemplateVisualEditorPaddingOptions } from '../mailTemplateVisualEditorPaddingOptions';
import { MailTemplateVariablePicker } from "../mailTemplateVariablePicker";
import { MailTemplateVisualEditorContext } from "../mailTemplateVisualEditorContext";
import { EditorDefaultActivatedToolbarControls } from "../../utils/editor/editorToolbar";
import { customColors } from "../../utils/editor/customColors";
import { registerBlock } from "../utils/registered-blocks";
import { generateBlockId } from "../utils/block-id";
import { renderRichEditorContentHTML } from "../../utils/editor/renderRichEditorContentHtml";
import { insertTextInEditorstate } from "../utils/insert-text-in-editorstate";
import { setVisualEditorBlockOptions } from "../redux/actions";
import { MailTemplateVisualEditorBodyStyles } from "../objects/mailTemplateVisualEditorState";
import { Block } from "../objects/block";
import { AppState } from "../../../../../Reducers/Reducers";
import Icon from "@mui/icons-material/TextFields";

type Options = {
    editorState: EditorState,
    backgroundColor: string,
    borderStyle: MailTemplateVisualEditorBorderStyle,
    borderWidth: number,
    borderCorners: MailTemplateVisualEditorCornersOption,
    borderColor: string,
    padding: {
        top: number,
        bottom: number,
        left: number
        right: number
    }
}

type Props = {
    id: number,
    options: Options
}

type OptionsComponentProps = {
    id: number,
    options: Options
}


function MailTemplateVisualEditorTextBlock(props: Props): JSX.Element {
    const context = useContext(MailTemplateVisualEditorContext);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectedBlockId = useSelector((state: AppState) => {
        return state.mailTemplate.others.instances[context.instanceId]?.visualEditorSelectedBlockId;
    });
    const locale = useSelector((state: AppState) => context.locale ?? state.locale.current_locale);
    const bodyStyles = useSelector((state: AppState) => {
        return state.mailTemplate.visualEditor.present.instances[context.instanceId]?.visualEditorBodyStyles;
    });
    const containerRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<Editor>(null);

    const onChangeEditorState = (state: EditorState) => {
        if (locale !== null) {
            const dispatchData = setVisualEditorBlockOptions<Options>(
                context.instanceId,
                locale,
                props.id,
                {
                    ...props.options,
                    editorState: state
                }
            );
            dispatch(dispatchData);
        }
    };

    useEffect(() => {
        if (selectedBlockId === props.id && editorRef.current) {
            //delay focus a little because modal tries to get focus too
            setTimeout(editorRef.current.focus, 300);
        }
    }, [editorRef.current, selectedBlockId, props.id]);

    return (
        <MailTemplateVisualEditorContext.Consumer>
            {
                (context) => (
                    <BlockContainer ref={containerRef}>
                        {
                            props.options.editorState.getCurrentContent().getPlainText().replace(/\s/g, '').length > 0 ||
                            selectedBlockId === props.id ||
                            context.disableBlocksEdit ||
                            context.disableHoverInteraction ?
                                <RichEditor
                                    ref={editorRef}
                                    pictures={[]}
                                    editorState={props.options.editorState}
                                    setEditorState={onChangeEditorState}
                                    values={{}}
                                    contentContainerStyles={{
                                        paddingTop: `${props.options.padding.top}px`,
                                        paddingBottom: `${props.options.padding.bottom}px`,
                                        paddingLeft: `${props.options.padding.left}px`,
                                        paddingRight: `${props.options.padding.right}px`,
                                        backgroundColor: props.options.backgroundColor,
                                        borderStyle: props.options.borderStyle,
                                        borderWidth: `${props.options.borderWidth}px`,
                                        borderRadius: `
                                            ${props.options.borderCorners.topLeft}px
                                            ${props.options.borderCorners.topRight}px
                                            ${props.options.borderCorners.bottomRight}px
                                            ${props.options.borderCorners.bottomLeft}px
                                        `,
                                        borderColor: props.options.borderColor,
                                        fontSize: 16,
                                        fontFamily: `'${bodyStyles?.fontFamily.name}', Ubuntu, Helvetica, Arial, sans-serif`
                                    }}
                                    toolbars={
                                        EditorDefaultActivatedToolbarControls
                                            .filter((item) => item !== 'image')
                                            .concat(['font-size', 'translate'])
                                    }
                                    hideToolbar={
                                        context.disableBlocksEdit ||
                                        (
                                            props.id !== selectedBlockId &&
                                            !context.forceSelectedState
                                        )
                                    }
                                    noDefault
                                    controlledState
                                /> :
                                <NoTextContainer>
                                    <Icon fontSize="large" />
                                    <Typography>
                                        {t<string>('shared.mail-template-visual-editor-no-text')}
                                    </Typography>
                                </NoTextContainer>
                        }
                    </BlockContainer>
                )
            }
        </MailTemplateVisualEditorContext.Consumer>
    );
}

const BlockContainer = styled('div')(() => ({
    width: '100%'
}));

const NoTextContainer = styled('div')((props) => ({
    "display": 'flex',
    "flexDirection": 'column',
    "justifyContent": 'center',
    "alignItems": 'center',
    "width": '100%',
    "padding": props.theme.spacing(1),
    "backgroundColor": '#4A90E2',
    "border": '2px solid #4A90F2',
    "color": props.theme.palette.getContrastText('#4A90E2'),
    '& svg': {
        color: props.theme.palette.getContrastText('#4A90E2')
    }
}));

function MailTemplateVisualEditorTextBlockOptions(props: OptionsComponentProps): JSX.Element {
    const context = useContext(MailTemplateVisualEditorContext);
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => context.locale ?? state.locale.current_locale);

    const onChangeOption = (type: keyof Options, value: any) => {
        if (locale !== null) {
            const dispatchData = setVisualEditorBlockOptions<Options>(
                context.instanceId,
                locale,
                props.id,
                {
                    ...props.options,
                    [type]: value
                }
            );
            dispatch(dispatchData);
        }
    };

    const onChangePadding = (type: keyof Options["padding"], value: number) => {
        onChangeOption("padding", { ...props.options.padding, [type]: value });
    };

    function onChangeCorner<K extends keyof Options["borderCorners"]>(
        type: K,
        value: Options["borderCorners"][K]
    ): void {
        onChangeOption("borderCorners", { ...props.options.borderCorners, [type]: value });
    }

    const onInsertVariable = (variable: string) => {
        onChangeOption(
            'editorState',
            insertTextInEditorstate(
                props.options.editorState,
                `{{${variable}}}`
            )
        );
    };

    return (
        <div>
            <MailTemplateVariablePicker
                onChoose={onInsertVariable}
            />
            <MailTemplateVisualEditorBackgroundColorOption
                backgroundColor={props.options.backgroundColor}
                onChangeBackgroundColor={(color) => onChangeOption("backgroundColor", color)}
            />
            <MailTemplateVisualEditorBorderWidthOption
                width={props.options.borderWidth}
                onChangeWidth={(width) => onChangeOption("borderWidth", width)}
            />
            <MailTemplateVisualEditorBorderStyleOption
                style={props.options.borderStyle}
                onChangeStyle={(style) => onChangeOption("borderStyle", style)}
            />
            <MailTemplateVisualEditorBorderColorOption
                color={props.options.borderColor}
                onChangeColor={(color) => onChangeOption("borderColor", color)}
            />
            <MailTemplateVisualEditorBorderCornersOption
                corners={props.options.borderCorners}
                onChangeCorner={onChangeCorner}
            />
            <MailTemplateVisualEditorPaddingOptions
                padding={props.options.padding}
                onChangePadding={onChangePadding}
            />
        </div>
    );
}

export class TextBlock implements Block<Options> {
    private id;
    private options: Options;

    public constructor() {
        this.id = generateBlockId();
        let editorState = EditorState.createEmpty();
        const contentState = Modifier.setBlockData(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            Map({ alignment: 'justify' })
        );
        this.options = {
            editorState: EditorState.push(editorState, contentState, 'change-block-data'),
            backgroundColor: "rgba(255, 255, 255, 0)",
            borderStyle: 'solid',
            borderWidth: 0,
            borderCorners: {
                topLeft: 0,
                topRight: 0,
                bottomLeft: 0,
                bottomRight: 0
            },
            borderColor: '#000',
            padding: {
                top: 5,
                bottom: 5,
                left: 5,
                right: 5
            }
        };
    }

    public getType(): string {
        return "text";
    }

    public getId(): number {
        return this.id;
    }

    public setOptions(options: Options): void {
        this.options = options;
    }

    public getOptions(): Options {
        return this.options;
    }

    public getExtraStyles(): string[] {
        if (this.options.borderWidth > 0) {
            return [
                `
                    .text-block.${this.id} {
                        border: ${this.options.borderWidth}px ${this.options.borderStyle} ${this.options.borderColor};
                        border-radius: ${this.options.borderCorners.topLeft}px ${this.options.borderCorners.topRight}px ${this.options.borderCorners.bottomRight}px ${this.options.borderCorners.bottomLeft}px;
                    }
                `
            ];
        }
        return [];
    }

    public renderMjml(
        bodyStyles: MailTemplateVisualEditorBodyStyles,
        quotationCode: string | null
    ): string {
        const options = this.getOptions();
        const styles = {
            ...RichEditorStyleMap,
            ...(quotationCode ? customColors[quotationCode] : null)
        };
        const html = renderRichEditorContentHTML(
            styles,
            options.editorState.getCurrentContent()
        );
        return `
            <mj-text
                font-size="16px"
                font-family="'${bodyStyles.fontFamily.name}', Ubuntu, Helvetica, Arial, sans-serif"
                line-height="1px"
                align="left"
                container-background-color="${options.backgroundColor}"
                padding-top="${options.padding.top}px"
                padding-bottom="${options.padding.bottom}px"
                padding-left="${options.padding.left}px"
                padding-right="${options.padding.right}px"
                css-class="text-block ${this.id}"
            >
                ${html}
            </mj-text>
        `;
    }

    public clone(options?: Options): TextBlock {
        let block: TextBlock;

        if (options) {
            block = clone(this);
        } else {
            block = new TextBlock();
        }

        const blockOptions = options ?? this.getOptions();
        block.setOptions(blockOptions);

        return block;
    }
}

registerBlock({
    type: "text",
    icon: <Icon />,
    label: "shared.mail-template-visual-editor-text-block-label",
    component: MailTemplateVisualEditorTextBlock,
    optionsComponent: MailTemplateVisualEditorTextBlockOptions,
    htmlAttributes: [],
    styles: [
        `
            .text-block table,
            .text-block td {
                border-collapse: separate;
            }
            ol, ul {
                line-height: 1.5;
            }
        `
    ],
    factory: () => new TextBlock()
});
