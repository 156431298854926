import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import AirportShuttleOutlinedIcon from '@material-ui/icons/AirportShuttleOutlined';
import ArrowRightAltOutlinedIcon from '@material-ui/icons/ArrowRightAltOutlined';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { isNumber } from 'lodash';

import TravelerInfo from '../Menu/MaterialTripList/TravelerInfo.js';
import TravelerMoreInfo from '../Menu/MaterialTripList/TravelerMoreInfo.js';
import TravelerMoreInfoMobile from '../Menu/MaterialTripList/TravelerMoreInfoMobile.js';
import BookingPackageFlightInfo from './BookingPackageFlightInfo.tsx';
import SelectLead from './SelectLead.js';

import moment from 'moment';
import { isProductPackaged } from '../CartMaterial/utils/isProductPackaged';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight700: {
        fontWeight: 700
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: '14px !important'
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter: {
        textAlign: "center"
    },
    genericPadding: {
        padding: "4px 8px"
    },
    genericBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box"
    },
    genericMargin: {
        margin: "12px 0px"
    },
    titleBorder: {
        borderBottom: "0.5px solid rgba(0, 0, 0, 0.25)"
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    marginEnd: {
        marginBottom: 16
    },
    spacer: {
        padding: "8px 0px"
    },
    bold: {
        fontWeight: "bold !important"
    }
}));


const BookingTravelerInfo = ({ rooms, tempContactLead, setTempContactLead, tempTravelers, setTempTravelers, moreInfo, setMoreInfo, infoIncomplete }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch()
    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));

    const end_date = useSelector(store => store.trip.end_date);
    const travelers = useSelector(store => store.trip.travelers);
    const to_book = useSelector(store => store.cart.to_book);
    const trip = useSelector(store => store.trip.data_trip);
    const all_data = useSelector(store => store.trip.all_data);
    //number of [adults, teens, children, babies]
    const [travelersAges, setTravelersAge] = useState([0, 0, 0, 0]);
    //number of [flights, cars, activities, transfers]
    const [productNb, setProductNb] = useState([0, 0, 0, 0]);
    const [roomDisplayed, setRoomsDisplayed] = useState([]);
    const [productByRoom, setProductByRoom] = useState([]);
    const [hasFlightInformation, setHasFlightInformation] = useState(false);
    useEffect(() => {
        let nb_adults = 0;
        let nb_teens = 0;
        let nb_children = 0;
        let nb_babies = 0;
        travelers.map((traveler) => {
            let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
            if (age > 12 && age < 18) {
                nb_teens += 1;
            } else if (age > 2 && age <= 12) {
                nb_children += 1;
            } else if (age <= 2) {
                nb_babies += 1;
            } else if (age >= 18) {
                nb_adults += 1;
            }
        });
        setTravelersAge([nb_adults, nb_teens, nb_children, nb_babies]);
    }, [travelers]);

    useEffect(() => {
        let nb_flights = 0;
        let nb_cars = 0;
        let nb_activities = 0;
        let nb_transfers = 0;

        to_book.map((product) => {
            if (product.is_displayed === true && (product.booking_status === null || (product.booking_status !== null && product.booking_status.status_booking === "ERROR"))) {
                if (product.product_type === 2) {
                    nb_cars += 1;
                } else if (product.product_type === 4) {
                    nb_transfers += 1;
                } else if (product.product_type === 6) {
                    nb_flights += 1;
                } else if (product.product_type === 11) {
                    nb_activities += 1;
                }
            }
        });

        setProductNb([nb_flights, nb_cars, nb_activities, nb_transfers]);
    }, [to_book]);

    useEffect(() => {
        let temp_products_by_room = [];
        let temp_rooms_displayed = [];

        rooms.map((room) => {
            let temp_products = [];
            let is_cars_to_book = false;
            let room_displayed = false;

            to_book.map((product) => {
                if (product.is_displayed === true && (product.booking_status === null || (product.booking_status !== null && product.booking_status.status_booking === "ERROR"))) {
                    if (product.group_passenger !== null && product.group_passenger.id === room.id && product.product_type !== 2) {
                        //save products for this room
                        temp_products.push(product);
                    }

                    if (product.product_type === 2) {
                        //display all rooms for car
                        is_cars_to_book = true;
                    } else if (product.group_passenger !== null) {
                        product.group_passenger.travelers.map((traveler_id) => {
                            if (room.travelers.includes(traveler_id)) {
                                //display room if traveler of room is included in product to be booked
                                room_displayed = true;
                            }
                        });
                    }
                }
            });

            temp_products.sort((a, b) => {
                return a.product_type - b.product_type;
            });

            //if (temp_products.length > 0) {
            temp_products_by_room.push(temp_products);
            //}

            if (temp_products.length !== 0 || is_cars_to_book || room_displayed) {
                temp_rooms_displayed.push(true);
            } else {
                temp_rooms_displayed.push(false);
            }
        });
        setProductByRoom(temp_products_by_room);
        setRoomsDisplayed(temp_rooms_displayed);
    }, [to_book, rooms]);
    useEffect(() => {
        if (!hasFlightInformation) {
            dispatch({type: "BOOKING_RESET_FLIGHT_INFO"});
        }
    }, [hasFlightInformation]);
    const handleHasFlightInformation = () => {
        setHasFlightInformation(!hasFlightInformation);
    }
    return (
        <Grid>
            <Grid className={`${classes.genericText} ${classes.letterSpacing}`}>
                <Grid container alignItems="baseline" justify="space-between">
                    <Grid item className={`${classes.fontSize14} ${classes.fontWeight900}`}>
                        {t("menu.trip_list.travelers_info") + " : "}
                    </Grid>
                    <Grid item className={`${classes.fontSize12} ${classes.fontWeight700}`}>
                        { rooms.length > 0 ? (rooms.length > 1 ? rooms.length + " " + t("global.rooms") : rooms.length + " " + t("global.room")) + " - " : " "}
                        { travelersAges[0] > 0 ? (travelersAges[0] > 1 ? travelersAges[0] + " " + t("flight_search.adults") : travelersAges[0] + " " + t("flight_search.adult")) : "" }{ travelersAges[1] > 0 ? " - " : "" }
                        { travelersAges[1] > 0 ? (travelersAges[1] > 1 ? travelersAges[1] + " " + t("flight_search.ados") : travelersAges[1] + " " + t("flight_search.ado")) : "" }{ travelersAges[2] > 0 ? " - " : "" }
                        { travelersAges[2] > 0 ? (travelersAges[2] > 1 ? travelersAges[2] + " " + t("flight_search.children") : travelersAges[2] + " " + t("flight_search.child")) : "" }{ travelersAges[3] > 0 ? " - " : "" }
                        { travelersAges[3] > 0 ? (travelersAges[3] > 1 ? travelersAges[3] + " " + t("flight_search.babies") : travelersAges[3] + " " + t("flight_search.baby")) : "" }
                        <br/>
                        { productNb[0] > 0 ? (productNb[0] > 1 ? productNb[0] + " " + t("global.flights") : productNb[0] + " " + t("global.flight")) : "" } { productNb[0] > 0 && productNb[1] > 0 ? " - " : "" }
                        { productNb[1] > 0 ? (productNb[1] > 1 ? productNb[1] + " " + t("global.cars") : productNb[1] + " " + t("global.car")) : "" } { productNb[2] > 0 ? " - " : "" }
                        { productNb[2] > 0 ? (productNb[2] > 1 ? productNb[2] + " " + t("cart.activities") : productNb[2] + " " + t("cart.activity")) : "" } { productNb[3] > 0 ? " - " : "" }
                        { productNb[3] > 0 ? (productNb[3] > 1 ? productNb[3] + " " + t("accommodation.transfers") : productNb[3] + " " + t("accommodation.transfer")) : "" }
                    </Grid>
                </Grid>
            </Grid>
            {
                roomDisplayed.length !== 0 && rooms.map((room, index_room) => {
                    let nb_adults = 0;
                    let nb_teens = 0;
                    let nb_children = 0;
                    let nb_babies = 0;

                    room.travelers_list.map((traveler) => {
                        let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
                        if (age > 12 && age < 18) {
                            nb_teens += 1;
                        } else if (age > 2 && age <= 12) {
                            nb_children += 1;
                        } else if (age <= 2) {
                            nb_babies += 1;
                        } else if (age >= 18) {
                            nb_adults += 1;
                        }
                    });
                    if (roomDisplayed[index_room]) {
                        return (
                            <Grid key={`room-${index_room}`} className={`${classes.genericBorder} ${classes.genericMargin}`}>
                                <Grid className={`${classes.genericPadding} ${classes.marginEnd} ${classes.titleBorder} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.upperCase} ${classes.letterSpacing}`}>
                                    {t("global.room") + " " + (index_room + 1) + " : "}
                                    { nb_adults > 0 ? (nb_adults > 1 ? nb_adults + " " + t("flight_search.adults") : nb_adults + " " + t("flight_search.adult")) : "" }{ nb_teens > 0 ? " - " : "" }
                                    { nb_teens > 0 ? (nb_teens > 1 ? nb_teens + " " + t("flight_search.ados") : nb_teens + " " + t("flight_search.ado")) : "" }{ nb_children > 0 ? " - " : "" }
                                    { nb_children > 0 ? (nb_children > 1 ? nb_children + " " + t("flight_search.children") : nb_children + " " + t("flight_search.child")) : "" }{ nb_babies > 0 ? " - " : "" }
                                    { nb_babies > 0 ? (nb_babies > 1 ? nb_babies + " " + t("flight_search.babies") : nb_babies + " " + t("flight_search.baby")) : "" }
                                </Grid>
                                {
                                    tempTravelers.map((traveler, traveler_index) => {
                                        if (traveler.room_index === index_room) {
                                            let open_more_info = moreInfo[traveler_index];
                                            return (
                                                <Grid key={`room-${index_room}-traveler-${traveler.id}`} container alignItems="baseline" justify="space-between" className={classes.genericPadding}>
                                                    <Grid item className={`${classes.genericPadding} ${classes.genericText} ${classes.fontWeight700} ${classes.fontSize12} ${classes.upperCase} ${classes.letterSpacing}`}>
                                                        {traveler.age_label}
                                                    </Grid>
                                                    <Grid item style={{ width: "85%" }}>
                                                        <TravelerInfo tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} traveler={traveler} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={false} redFormControl={false} nationality_option={false} size={"small"} />
                                                        {
                                                            downDesktop && (
                                                                <TravelerMoreInfoMobile traveler={traveler} traveler_index={traveler_index} moreInfo={moreInfo} setMoreInfo={setMoreInfo} open_more_info={open_more_info} infoIncomplete={infoIncomplete} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} size="small" />
                                                            )
                                                        }
                                                        {
                                                            !downDesktop && (
                                                                <TravelerMoreInfo traveler={traveler} traveler_index={traveler_index} moreInfo={moreInfo} setMoreInfo={setMoreInfo} open_more_info={open_more_info} infoIncomplete={infoIncomplete} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} size="small" />
                                                            )
                                                        }
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                    })
                                }
                                {
                                    productByRoom.length !== 0 && productByRoom[index_room].length !== 0 && (
                                        <Grid>
                                            <Grid container alignItems="baseline" justify="space-between" className={classes.genericPadding} style={productByRoom.length === 0 ? { paddingBottom: 8 } : {}}>
                                                <Grid item md={6} xs={12} className={`${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize14 : classes.fontSize12} ${classes.upperCase} ${classes.letterSpacing}`}>
                                                    { t("booking.is_contact_lead") + (index_room + 1)}
                                                </Grid>
                                                <Grid item md={6} xs={12} className={!downDesktop ? "" : classes.spacer}>
                                                    <SelectLead group={room} mode={"group"} product={null} product_list={productByRoom[index_room]} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                                                </Grid>
                                            </Grid>
                                            <Grid className={`${classes.genericPadding} ${classes.marginEnd} ${classes.genericText} ${classes.fontWeight700} ${classes.letterSpacing}`}>
                                                <Grid className={classes.fontSize14} style={{ paddingBottom: 8 }}>
                                                    { t("booking.product_list") }
                                                </Grid>                                        
                                                {
                                                    productByRoom[index_room].filter((product, index, array) => {
                                                        return !isProductPackaged({
                                                            product,
                                                            stackInfos: trip?.stack_info ?? null,
                                                            connected: true
                                                        }) ||
                                                        array.slice(0, index).findIndex((product) => {
                                                            return isProductPackaged({
                                                                product,
                                                                stackInfos: trip?.stack_info ?? null,
                                                                connected: true
                                                            });
                                                        }) < 0;
                                                    }).map((product) => {
                                                        if (product.product_type === 0) {
                                                            return (
                                                                <Stack direction={'column'} key={`room-${room.id}-${product.id}`}>
                                                                    <Grid container alignItems="center" justify="flex-start" spacing={!downDesktop ? 2 : 0} style={!downDesktop ? { paddingLeft: 16 } : {}} className={!downDesktop ? "" : `${classes.textCenter} ${classes.spacer}`} >
                                                                        <Grid item style={!downDesktop ? {} : { width: "100%" }}>
                                                                            <HomeOutlinedIcon />
                                                                        </Grid>
                                                                        <Grid
                                                                            style={{
                                                                                paddingTop: isProductPackaged({
                                                                                    product,
                                                                                    stackInfos: trip?.stack_info ?? null,
                                                                                    connected: true
                                                                                }) ? 0 : undefined
                                                                            }}
                                                                            item
                                                                        >
                                                                            {
                                                                                !isProductPackaged({
                                                                                    product,
                                                                                    stackInfos: trip?.stack_info ?? null,
                                                                                    connected: true
                                                                                }) &&
                                                                                <div className={classes.fontSize12}>
                                                                                    {
                                                                                        product.is_manual ?
                                                                                            product.name :
                                                                                            product.hotel?.[0]?.name
                                                                                    } {
                                                                                        isProductPackaged({
                                                                                            product,
                                                                                            stackInfos: trip?.stack_info ?? null
                                                                                        }) &&
                                                                                        <Box sx={{ color: '#2F80ED' }} component="span">
                                                                                            ({t('cart-material.cart-construction-package-name', { name: product.stack_number })})
                                                                                        </Box>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            {
                                                                                isProductPackaged({
                                                                                    product,
                                                                                    stackInfos: trip?.stack_info ?? null,
                                                                                    connected: true
                                                                                }) &&
                                                                                <div className={classes.fontSize12}>
                                                                                    <Box sx={{ color: '#2F80ED', textTransform: 'uppercase' }} component="span">
                                                                                        {t('cart-material.cart-construction-connected-package')}
                                                                                    </Box>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                !isProductPackaged({
                                                                                    product,
                                                                                    stackInfos: trip?.stack_info ?? null,
                                                                                    connected: true
                                                                                }) &&
                                                                                <>
                                                                                    <div className={`${classes.fontWeight500} ${classes.fontSize10}`}>
                                                                                        { product.hotel?.[0]?.city_name ? product.hotel[0].city_name : product.start_destination?.data?.international_name }
                                                                                    </div>
                                                                                    <div className={`${classes.fontWeight500} ${classes.fontSize10}`}>
                                                                                        { t("global.from") + " " + (!downDesktop ? moment.utc(product.start_date).format('DD MMMM') : moment.utc(product.start_date).format('DD/MM')) + " " }
                                                                                        { t("global.to") + " " + (!downDesktop ? moment.utc(product.end_date).format('DD MMMM') : moment.utc(product.end_date).format('DD/MM')) }
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                    {
                                                                        isProductPackaged({
                                                                            product,
                                                                            stackInfos: trip?.stack_info ?? null,
                                                                            connected: true
                                                                        }) &&
                                                                        <Stack direction={'column'}>
                                                                            <Grid container alignItems="center" justify="flex-start" spacing={2} style={{ paddingLeft: 16 }}>
                                                                                <Grid item>
                                                                                    <FormControlLabel
                                                                                        control={<Checkbox checked={hasFlightInformation}
                                                                                        onChange={handleHasFlightInformation} 
                                                                                        sx={{
                                                                                            'color': `${main_color}`,
                                                                                            '&.Mui-checked': {
                                                                                                color: `${main_color}`
                                                                                            }
                                                                                        }}
                                                                                        />} 
                                                                                        label={t('cart-material.cart-construction-flight-information')}
                                                                                        classes={{ label: `${classes.genericText} ${classes.fontSize14} ${classes.bold}` }}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            {
                                                                                hasFlightInformation &&
                                                                                <BookingPackageFlightInfo/>
                                                                            }
                                                                        </Stack>
                                                                    }
                                                                </Stack>
                                                            );
                                                        }
                                                        if (product.product_type === 4) {
                                                            if (downDesktop) {
                                                                return (
                                                                    <Grid key={`room-${room.id}-${product.id}`} container alignItems="center" justify="flex-start" direction="column" className={`${classes.textCenter} ${classes.spacer}`} >
                                                                        <Grid item style={!downDesktop ? {} : { width: "100%" }}>
                                                                            <AirportShuttleOutlinedIcon />
                                                                        </Grid>
                                                                        {
                                                                            !isProductPackaged({
                                                                                product,
                                                                                stackInfos: trip?.stack_info ?? null,
                                                                                connected: true
                                                                            }) &&
                                                                            <>
                                                                                <Grid item className={classes.fontSize12}>
                                                                                    { product.is_custom ? product.custom_product.title + " " : product.name + " " } {
                                                                                        isProductPackaged({
                                                                                            product,
                                                                                            stackInfos: trip?.stack_info ?? null
                                                                                        }) &&
                                                                                        <Box sx={{ color: '#2F80ED' }} component="span">
                                                                                        ({t('cart-material.cart-construction-package-name', { name: product.stack_number })})
                                                                                        </Box>
                                                                                    }
                                                                                </Grid>
                                                                                <Grid item className={`${classes.fontWeight500} ${classes.fontSize10}`}>
                                                                                    { product.is_custom ? product.origin_name + " " : product.station_name_pickup + " " }
                                                                                </Grid>
                                                                                <Grid item style={!downDesktop ? {} : { width: "100%" }}>
                                                                                    <ArrowDownwardOutlinedIcon />
                                                                                </Grid>
                                                                                <Grid item className={`${classes.fontWeight500} ${classes.fontSize10}`}>
                                                                                    { product.is_custom ? product.destination_name : product.station_name_return }
                                                                                </Grid>
                                                                                <Grid item className={`${classes.fontWeight500} ${classes.fontSize10}`}>
                                                                                    { t("global.from") + " " + moment.utc(product.start_date).format('DD/MM') + " " + t("global.to") + " " + (product.end_date !== null ? moment.utc(product.end_date).format('DD/MM') : "") }
                                                                                </Grid>
                                                                            </>
                                                                        }
                                                                        {
                                                                            isProductPackaged({
                                                                                product,
                                                                                stackInfos: trip?.stack_info ?? null,
                                                                                connected: true
                                                                            }) &&
                                                                            <Grid style={{ paddingTop: isProductPackaged({
                                                                                product,
                                                                                stackInfos: trip?.stack_info ?? null,
                                                                                connected: true
                                                                            }) ? 0 : undefined }} item>
                                                                                <div className={classes.fontSize12}>
                                                                                    <Box sx={{ color: '#2F80ED' }} component="span">
                                                                                        {t('cart-material.cart-construction-connected-package')}
                                                                                    </Box>
                                                                                </div>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                );
                                                            }
                                                            return (
                                                                <Grid key={`room-${room.id}-${product.id}`} container alignItems="center" justify="flex-start" spacing={2} style={{ paddingLeft: 16 }} >
                                                                    <Grid item >
                                                                        <AirportShuttleOutlinedIcon />
                                                                    </Grid>
                                                                    <Grid style={{ paddingTop: isProductPackaged({
                                                                        product,
                                                                        stackInfos: trip?.stack_info ?? null,
                                                                        connected: true
                                                                    }) ? 0 : undefined }} item>
                                                                        {
                                                                            !isProductPackaged({
                                                                                product: room,
                                                                                stackInfos: trip?.stack_info ?? null,
                                                                                connected: true
                                                                            }) &&
                                                                            <>
                                                                                <div className={classes.fontSize12}>
                                                                                    { product.is_custom ? product.custom_product.title + " " : product.name + " " } {
                                                                                        isProductPackaged({
                                                                                            product,
                                                                                            stackInfos: trip?.stack_info ?? null
                                                                                        }) &&
                                                                                    <Box sx={{ color: '#2F80ED' }} component="span">
                                                                                        ({t('cart-material.cart-construction-package-name', { name: product.stack_number })})
                                                                                    </Box>
                                                                                    }
                                                                                </div>
                                                                                <Grid container alignItems="center" justify="space-between" className={`${classes.fontWeight500} ${classes.fontSize10}`} spacing={1} style={{ marginTop: -12 }}>
                                                                                    <Grid item>
                                                                                        { product.is_custom ? product.origin_name + " " : product.station_name_pickup + " " }
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <ArrowRightAltOutlinedIcon />
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        { product.is_custom ? product.destination_name : product.station_name_return }
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <div className={`${classes.fontWeight500} ${classes.fontSize10}`} style={{ marginTop: -10 }}>
                                                                                    { t("global.from") + " " + moment.utc(product.start_date).format('DD MMMM') + " " + t("global.to") + " " + moment.utc(product.end_date).format('DD MMMM') }
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        {
                                                                            isProductPackaged({
                                                                                product,
                                                                                stackInfos: trip?.stack_info ?? null,
                                                                                connected: true
                                                                            }) &&
                                                                            <div className={classes.fontSize12}>
                                                                                <Box sx={{ color: '#2F80ED' }} component="span">
                                                                                    {t('cart-material.cart-construction-connected-package')}
                                                                                </Box>
                                                                            </div>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        }
                                                        if (product.product_type === 11 || product.product_type === 12) {
                                                            return (
                                                                <Grid key={`room-${room.id}-${product.id}`} container alignItems="center" justify="flex-start" spacing={!downDesktop ? 2 : 0} style={!downDesktop ? { paddingLeft: 16 } : {}} className={!downDesktop ? "" : `${classes.textCenter} ${classes.spacer}`} >
                                                                    <Grid item style={!downDesktop ? {} : { width: "100%" }}>
                                                                        <VisibilityIcon />
                                                                    </Grid>
                                                                    <Grid style={{ paddingTop: isProductPackaged({
                                                                        product,
                                                                        stackInfos: trip?.stack_info ?? null,
                                                                        connected: true
                                                                    }) ? 0 : undefined }} item>
                                                                        {
                                                                            !isProductPackaged({
                                                                                product,
                                                                                stackInfos: trip?.stack_info ?? null,
                                                                                connected: true
                                                                            }) &&
                                                                            <>
                                                                                <div className={classes.fontSize12}>
                                                                                    { product.custom_product.title } {
                                                                                        isProductPackaged({
                                                                                            product,
                                                                                            stackInfos: trip?.stack_info ?? null
                                                                                        }) &&
                                                                                        <Box sx={{ color: '#2F80ED' }} component="span">
                                                                                        ({t('cart-material.cart-construction-package-name', { name: product.stack_number })})
                                                                                        </Box>
                                                                                    }
                                                                                </div>
                                                                                <div className={`${classes.fontWeight500} ${classes.fontSize10}`}>
                                                                                    { product.custom_product.place.address !== null ? product.custom_product.place.address : "" }
                                                                                    { product.custom_product.place.city_name !== null ? product.custom_product.place.city_name : "" }
                                                                                </div>
                                                                                <div className={`${classes.fontWeight500} ${classes.fontSize10}`}>
                                                                                    { product.custom_product.place.city_name !== null ? product.custom_product.place.city_name : "" }
                                                                                </div>
                                                                                <div className={`${classes.fontWeight500} ${classes.fontSize10}`}>
                                                                                    {
                                                                                        product.custom_product.duration >= 1440 ? Math.floor(product.custom_product.duration / 1440) + ' ' + (Math.floor(product.custom_product.duration / 1440) > 1 ? t("poi.days") : t("poi.day")) : ""
                                                                                    }
                                                                                    {
                                                                                        product.custom_product.duration >= 60 && product.custom_product.duration < 1440 ? product.custom_product.duration / 60 + ' ' + (product.custom_product.duration / 60 > 1 ? t("poi.hours") : t("poi.hour")) : ""
                                                                                    }
                                                                                    {
                                                                                        product.custom_product.duration < 60 ? product.custom_product.duration + ' ' + t("poi.minutes") : ""
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        {
                                                                            isProductPackaged({
                                                                                product,
                                                                                stackInfos: trip?.stack_info ?? null,
                                                                                connected: true
                                                                            }) &&
                                                                            <div className={classes.fontSize12}>
                                                                                <Box sx={{ color: '#2F80ED' }} component="span">
                                                                                    {t('cart-material.cart-construction-connected-package')}
                                                                                </Box>
                                                                            </div>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        }
                                                    })
                                                }
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        );
                    }
                })
            }
        </Grid>
    );
};

export default React.memo(BookingTravelerInfo);
