import SetCookie from "../Components/Common/Functions/SetCookie";
import GetCookie from "../Components/Common/Functions/GetCookie";
import PoiCardRun from "../Components/Poi/Runners/PoiCardRun";
import { addProviderDates } from "../Components/CartMaterial/utils/addProviderDates";

const initial_state = {
    audio_guide: false,
    active_filters: 0,
    active_page: 1,
    auto_reload: true,
    bounds: null,
    card_unmount: false,
    cart: [],
    cart_cancellations: [],
    cart_cancellations_start_date: null,
    cart_currency_iso: null,
    cart_questions_id: null,
    cart_questions: [],
    count: 0,
    current_card_id: null,
    current_details: null,
    current_groups: null,
    cut_line: false,
    custom_time_error: false,
    destination: null,
    duration_inf: 0,
    duration_sup: Infinity,
    end_date: null,
    escort_guide: false,
    eco_filter: false,
    nugget_filter: false,
    //favorite: [],
    filters: {},
    for_children: false,
    for_disabled: false,
    force_request: false,
    full: false,
    full_list: [],
    grid_type: 4,
    guide: false,
    hovered: null,
    multiple_days: false,
    multiple_dates: [],
    open_cart_cancellation: false,
    open_cart_questions: false,
    over: null,
    page_list: [],
    price_list: [],
    prices_loaded: false,
    providers: [],
    providers_running_count: 0,
    recommended: false,
    request_url: null,
    request_sent: false,
    search: "",
    selected_providers: [],
    show_group_composition: false,
    //show_favorite: false,
    show_filters: false,
    show_sorts: false,
    show_parameters: false,
    sort: 7,
    start_date: null,
    sort_cart: [],
    refresh: null,
    transport_pass: false,
    poi_filter: false,

    pickup_point: null,
    return_point: null,
    meeting_point: null
};

const getPageResults = (list, page) => {
    console.log('list:', list);
    let page_list = [];
    for (let i = 30 * (page - 1); i < 30 * page && i < list.length; i++) {
        page_list.push(list[i]);
    }
    console.log('page_list:', page_list);
    return page_list;
};

const filterResults = (price_list, new_state) => {
    let filtered_results = [];
    price_list.map((poi) =>{
        if (new_state.bounds.north > parseFloat(poi.place.latitude) && parseFloat(poi.place.latitude) > new_state.bounds.south && new_state.bounds.east > parseFloat(poi.place.longitude) && parseFloat(poi.place.longitude) > new_state.bounds.west) {
            if (poi.is_custom) {
                if (poi.variants !== undefined && poi.variants !== null) {
                    if ((!new_state.recommended || new_state.recommended === poi.recommended)
                        //&& (new_state.search === "" || poi.title.match(new RegExp(new_state.search, 'i')))
                        && (new_state.selected_providers.length === 0 || new_state.selected_providers.find(r=> poi.providers.some(el => el.id === r || el.provider_id === r)))
                        && (!new_state.poi_filter || poi.poi_type)
                        && (!new_state.eco_filter || poi.label_eco)
                        && (!new_state.nugget_filter || poi.is_pepite)) {
                        if (poi.variants.length === 0) {
                            filtered_results.push(poi);
                        }
                        for (let i = 0; i < poi.variants.length; i++) {
                            if ((new_state.duration_inf <= poi.variants[i].duration)
                            && (poi.variants[i].duration <= new_state.duration_sup)
                            && (!new_state.audio_guide || new_state.audio_guide === poi.variants[i].audio_guide)
                            && (!new_state.guide || new_state.guide === poi.variants[i].guide)
                            && (!new_state.escort_guide || (new_state.escort_guide && poi.variants[i].special_attribute !== undefined && poi.variants[i].special_attribute !== null && poi.variants[i].special_attribute.includes("GUIDE")))
                            && (!new_state.transport_pass || (new_state.transport_pass && poi.variants[i].special_attribute !== undefined && poi.variants[i].special_attribute !== null && poi.variants[i].special_attribute.includes("TRANSPORT_PASS")))
                            && (!new_state.for_children || new_state.for_children === poi.variants[i].for_children)
                            && (!new_state.for_disabled || new_state.for_disabled === poi.variants[i].for_disabled)
                            && (!new_state.cut_line || new_state.cut_line === poi.variants[i].cut_line)
                            && (!new_state.multiple_days || new_state.multiple_days === poi.variants[i].multiple_days)
                            && (!new_state.poi_filter || poi.poi_type)
                            && (!new_state.eco_filter || poi.label_eco)
                            && (!new_state.nugget_filter || poi.is_pepite)) {
                                filtered_results.push(poi);
                                break;
                            }
                        }
                    }
                } else if ((!new_state.recommended || new_state.recommended === poi.recommended)
                        //&& (new_state.search === "" || poi.title.match(new RegExp(new_state.search, 'i')))
                        && (new_state.selected_providers.length === 0 || new_state.selected_providers.includes(poi.provider.provider.id))
                        && (new_state.duration_inf <= poi.duration)
                        && (poi.duration <= new_state.duration_sup)
                        && (!new_state.audio_guide || new_state.audio_guide === poi.audio_guide)
                        && (!new_state.guide || new_state.guide === poi.guide)
                        && (!new_state.escort_guide || (new_state.escort_guide && poi.special_attribute !== null && poi.special_attribute.includes("GUIDE")))
                        && (!new_state.transport_pass || (new_state.transport_pass && poi.special_attribute !== null && poi.special_attribute.includes("TRANSPORT_PASS")))
                        && (!new_state.for_children || new_state.for_children === poi.for_children)
                        && (!new_state.for_disabled || new_state.for_disabled === poi.for_disabled)
                        && (!new_state.cut_line || new_state.cut_line === poi.cut_line)
                        && (!new_state.multiple_days || new_state.multiple_days === poi.multiple_days)
                        && (!new_state.poi_filter || poi.poi_type)
                        && (!new_state.eco_filter || poi.label_eco)
                        && (!new_state.nugget_filter || poi.is_pepite)) {
                    filtered_results.push(poi);
                }
            } else if ((!new_state.recommended || new_state.recommended === poi.recommended)
                    //&& (new_state.search === "" || poi.title.match(new RegExp(new_state.search, 'i')))
                    && (new_state.selected_providers.length === 0 || new_state.selected_providers.includes(poi.provider.id))
                    && (new_state.duration_inf <= poi.duration)
                    && (poi.duration <= new_state.duration_sup)
                    && (!new_state.audio_guide || new_state.audio_guide === poi.audio_guide)
                    && (!new_state.guide || new_state.guide === poi.guide)
                    && !new_state.escort_guide
                    && !new_state.transport_pass
                    && !new_state.for_children
                    && !new_state.for_disabled
                    && !new_state.cut_line
                    && !new_state.multiple_days
                    && (!new_state.poi_filter || poi.poi_type)
                    && (!new_state.eco_filter || poi.label_eco)
                    && (!new_state.nugget_filter || poi.is_pepite)) {
                filtered_results.push(poi);
            }
        }
    });
    return filtered_results;
};

const sortResults = (list, state) => {
    if (list === null) {
        return list;
    }
    let sorted_results = [];
    //handle custom poi without defined price by adding false price
    list.map((poi) => {
        if (poi.is_custom && poi.price === null) {
            let temp_poi = Object.assign({}, poi);
            temp_poi.price = { selling_price: 0 };
            sorted_results.push(temp_poi);
        } else {
            sorted_results.push(poi);
        }
    });

    switch (state.sort) {
        case 0:
            sorted_results.sort((a, b) => {
                return a.price.selling_price - b.price.selling_price;
            });
            break;
        case 1:
            sorted_results.sort((a, b) => {
                return b.price.selling_price - a.price.selling_price;
            });
            break;
        case 2:
            sorted_results.sort((a, b) => {
                return a.title.localeCompare(b.title);
            });
            break;
        case 3:
            sorted_results.sort((a, b) => {
                return b.title.localeCompare(a.title);
            });
            break;
        case 4:
            sorted_results.sort((a, b) => {
                return a.duration - b.duration;
            });
            break;
        case 5:
            sorted_results.sort((a, b) => {
                return b.duration - a.duration;
            });
            break;
        case 6:
            sorted_results.sort((a, b) => {
                return a.rating - b.rating;
            });
            break;
        case 7:
            sorted_results.sort((a, b) => {
                if (a === null) {
                    return 1;
                }
                if (b === null) {
                    return -1;
                }
                return b.rating - a.rating;
            });
            break;
    }

    //remove false price for custom poi without defined price
    sorted_results.map((poi, poi_index) => {
        if (poi.is_custom && poi.price.selling_price === 0 && poi.price.selling_currency === undefined) {
            let temp_poi = Object.assign({}, poi);
            temp_poi.price = null;
            sorted_results[poi_index] = temp_poi;
        }
    });
    return sorted_results;
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.poi !== undefined) {
                Object.keys(action.payload.poi).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.poi[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            const grid_type = Object.assign({}, new_state).grid_type;
            new_state = initial_state;
            new_state.grid_type = grid_type;
            return new_state;
        }
        case "SMALL_FLUSH": {
            const grid_type = Object.assign({}, new_state).grid_type;
            new_state = initial_state;
            new_state.grid_type = grid_type;
            return new_state;
        }


        case "POI_SET_BASIC_DATA": {
            new_state.destination = action.payload.destination;
            new_state.start_date = action.payload.start_date;
            new_state.end_date = action.payload.end_date;
            new_state.bounds = action.payload.bounds;
            new_state.current_groups = action.payload.current_groups;
            SetCookie("current_groups", JSON.stringify(new_state.current_groups), 365, `/${window.url_name}`, (window.location.host === "localhost:3000" ? null : window.location.host));
            new_state.full_list = [];
            new_state.price_list = [];
            new_state.page_list = [];
            new_state.count = 0;
            new_state.providers = [];
            new_state.providers_running_count = 0;
            new_state.active_page = 1;
            new_state.active_filters = 0;
            new_state.sort = 7;
            new_state.selected_providers = [];
            new_state.duration_inf = 0;
            new_state.duration_sup = Infinity;
            return new_state;
        }
        case "POI_SET_RELOAD_DATA": {
            new_state.destination = action.payload.destination;
            new_state.start_date = action.payload.start_date;
            new_state.end_date = action.payload.end_date;
            new_state.bounds = action.payload.bounds;
            new_state.current_groups = action.payload.current_groups;
            SetCookie("current_groups", JSON.stringify(new_state.current_groups), 365, `/${window.url_name}`, (window.location.host === "localhost:3000" ? null : window.location.host));
            return new_state;
        }
        case "MATERIAL_UPDATE_MAP": {
            let poi_origin = false;
            action.payload.origin.map((origin) => {
                if (origin.path === "poi") {
                    poi_origin = true;
                }
            });
            if (poi_origin) {
                if (new_state.card_unmount) {
                    new_state.card_unmount = false;
                } else if (new_state.auto_reload) {
                    new_state.bounds = action.payload.bounds;
                    if (new_state.full_list.length !== 0 && new_state.price_list.length !== 0) {
                        new_state.price_list = filterResults(new_state.price_list, new_state);
                        new_state.price_list = sortResults(new_state.price_list, new_state);
                        new_state.page_list = getPageResults(new_state.price_list, 1);
                        new_state.count = new_state.price_list.length;
                    }
                    new_state.active_page = 1;
                }
            }
            return new_state;
        }
        case "POI_UPDATE_BOUNDS": {
            new_state.bounds = action.payload.bounds;
            break;
        }
        case "POI_UPDATE_PARAMETERS": {
            new_state.current_groups = action.payload.current_groups.slice();
            new_state.price_list = [];
            new_state.page_list = [];
            new_state.count = 0;
            new_state.prices_loaded = false;
            new_state.show_parameters = false;
            return new_state;
        }
        case "MATERIAL_POI_DESTINATION": {
            new_state.destination = action.payload;
            return new_state;
        }
        case "MATERIAL_SET_ACTIVE_DESTINATION": {
            new_state.destination = action.payload.destination;
            new_state.start_date = action.payload.start_date;
            new_state.end_date = action.payload.end_date;
            //clear filters and results
            new_state.providers = [];
            new_state.price_list = [];
            new_state.page_list = [];
            new_state.count = 0;
            new_state.recommended = false;
            new_state.search = "";
            new_state.selected_providers = [];
            new_state.duration_inf = 0;
            new_state.duration_sup = Infinity;
            new_state.audio_guide = false;
            new_state.guide = false;
            new_state.escort_guide = false;
            new_state.transport_pass = false;
            new_state.for_children = false;
            new_state.for_disabled = false;
            new_state.cut_line = false;
            new_state.multiple_days = false;
            return new_state;
        }
        case "MATERIAL_POI_OPEN_QUESTIONS": {
            new_state.open_cart_questions = true;
            let questions = [];
            action.payload.questions.map(question => {
                questions.push({ ...question });
            });
            new_state.cart_questions = questions;
            new_state.cart_questions_id = action.payload.id;
            return new_state;
        }
        case "MATERIAL_POI_CLOSE_QUESTIONS": {
            new_state.open_cart_questions = false;
            new_state.cart_questions = [];
            new_state.cart_questions_id = null;
            return new_state;
        }
        case "MATERIAL_POI_OPEN_CANCELLATIONS": {
            new_state.open_cart_cancellation = true;
            new_state.cart_cancellations = action.payload.cancellations;
            new_state.cart_cancellations_start_date = action.payload.start_date;
            new_state.cart_currency_iso = action.payload.cart_currency_iso;
            return new_state;
        }
        case "MATERIAL_POI_CLOSE_CANCELLATIONS": {
            new_state.open_cart_cancellation = false;
            new_state.cart_cancellations = [];
            new_state.cart_cancellations_start_date = null;
            new_state.cart_currency_iso = null;
            return new_state;
        }
        case "MATERIAL_POI_CARD_UNMOUNT_BACK": {
            new_state.card_unmount = true;
            new_state.prices_loaded = true;
            new_state.hovered = null;
            return new_state;
        }
        case "MATERIAL_POI_RESET_CARD_UNMOUNT": {
            new_state.card_unmount = false;
            //new_state.price_list = [];
            return new_state;
        }


        //case "POI_TOGGLE_FAVORITE": {
        //new_state.show_favorite = action.payload.show_favorite;
        //return new_state;
        //}
        case "POI_SET_FULL_LIST": {
            new_state.full_list = action.payload.full_list;
            return new_state;
        }
        case "POI_TOGGLE_FULL": {
            new_state.full = !new_state.full;
            return new_state;
        }
        case "POI_CHANGE_GRID_TYPE": {
            new_state.grid_type = action.payload.grid_type;
            return new_state;
        }
        case "POI_ADD_SEEN": {
            new_state.seen = [].concat(new_state.seen, action.payload.poi);
            return new_state;
        }
        //case "POI_ADD_FAVORITE": {
        //new_state.favorite = [].concat(new_state.favorite, action.payload.poi);
        //return new_state;
        //}
        //case "POI_REMOVE_FAVORITE": {
        //let favorite = new_state.favorite.slice();
        //favorite.splice(action.payload.index, 1);
        //new_state.favorite = favorite.slice();
        //return new_state;
        //}
        case "POI_SET_GROUPS": {
            new_state.current_groups = action.payload.groups;
            SetCookie("current_groups", JSON.stringify(new_state.current_groups), 365, `/${window.url_name}`, (window.location.host === "localhost:3000" ? null : window.location.host));
            new_state.force_request = true;
            new_state.show_group_composition = false;
            return new_state;
        }
        case "POI_SET_SEARCH": {
            new_state.search = action.payload.search;
            if (new_state.price_list.length > 0) {
                new_state.price_list = sortResults(new_state.price_list, new_state);
                let filtered_list = filterResults(new_state.price_list, new_state);
                new_state.page_list = getPageResults(filtered_list, 1);
                new_state.count = filtered_list.length;
            }
            return new_state;
        }
        case "POI_CHANGE_ACTIVE_PAGE": {
            new_state.active_page = action.payload.active_page;
            new_state.price_list = sortResults(new_state.price_list, new_state);
            let filtered_list = filterResults(new_state.price_list, new_state);
            new_state.count = filtered_list.length;
            new_state.page_list = getPageResults(filtered_list, action.payload.active_page);
            console.log('new_state.page_list:', new_state.page_list);
            return new_state;
        }
        case "POI_SET_OVER": {
            new_state.over = action.payload.over;
            return new_state;
        }
        case "POI_SET_START_DATE": {
            new_state.start_date = action.payload.start_date;
            new_state.force_request = true;
            return new_state;
        }
        case "POI_SET_DATES": {
            new_state.start_date = action.payload.start_date;
            new_state.end_date = action.payload.end_date;
            new_state.force_request = action.payload.force_request;
            new_state.show_group_composition = false;
            return new_state;
        }
        case "POI_EMPTY_FILTERS": {
            new_state.filters = {
            };
            new_state.full = false;
            return new_state;
        }
        case "POI_TOGGLE_FILTERS": {
            new_state.show_filters = !new_state.show_filters;
            return new_state;
        }
        case "POI_TOGGLE_ROOM_COMPOSITION": {
            new_state.show_group_composition = action.payload.show_group_composition;
            return new_state;
        }
        case "POI_ADD_PRICES": {
            let results = new_state.full_list.slice();
            let new_prices_list = new_state.price_list.slice();
            let new_providers_list = new_state.providers.slice();
            let prices = action.payload.prices.slice();
            let custom_provider = action.payload.custom_provider !== null ? { ...action.payload.custom_provider } : null;
            for (let i = 0; i < results.length; i++) {
                let poi = Object.assign({}, results[i]);
                if (poi.price === undefined) {
                    poi.price = null;
                }
                for (let j = 0; j < prices.length; j++) {
                    if ((poi.is_custom && poi.id === parseInt(prices[j].product_id)) || (!poi.is_custom && poi.product_code === prices[j].product_id)) {
                        poi.price = prices[j].prices.length !== 0 ? prices[j].prices[0] : [];
                        poi.number_product_model = prices[j].number_product_model;
                        poi.catalog_price = prices[j].catalog_price;
                        poi.variant_id = prices[j].variant_id;
                    }
                }
                if (!new_prices_list.some(item => item.id === poi.id)) {
                    if (poi.price !== null) {
                        new_prices_list.push(poi);
                        if (poi.is_custom) {
                            if (poi.provider !== null) {
                                if (!new_providers_list.some(item => poi.providers.indexOf(item.id) >= 0)) {
                                    new_providers_list = [...new_providers_list, ...poi.providers];
                                }
                            } else if (custom_provider !== undefined && custom_provider !== null) {
                                new_providers_list.push({
                                    id: custom_provider.id,
                                    name: custom_provider.provider.name,
                                    logo: custom_provider.provider.logo,
                                    is_custom: true,
                                    code: custom_provider.provider.code
                                });
                            }
                        } else if (!new_providers_list.some(item => item.id === poi.provider.id)) {
                            new_providers_list.push(poi.provider);
                        }
                    }
                } else if (poi.is_custom) {
                    if (poi.price !== null) {
                        let poi_index = new_prices_list.findIndex(element => element.id === poi.id);
                        if (parseFloat(poi.price.selling_price) < parseFloat(new_prices_list[poi_index].price.selling_price)) {
                            new_prices_list[poi_index] = poi;
                        }
                    }
                    if (!new_providers_list.some(item => poi.providers.indexOf(item.id) >= 0)) {
                        new_providers_list = [...new_providers_list, ...poi.providers];
                    }
                }
            }
            new_providers_list = new_providers_list.filter((provider, index, array) => array.findIndex(item => item.id == provider.id || item.provider_id == provider.id) == index);
            console.log('new_providers_list:', new_providers_list);
            new_state.price_list = new_prices_list;
            new_state.price_list = sortResults(new_state.price_list, new_state);
            let filtered_list = filterResults(new_state.price_list, new_state);
            new_state.count = filtered_list.length;
            new_state.page_list = getPageResults(filtered_list, 1);
            new_state.active_page = 1;
            new_state.providers = new_providers_list;
            new_state.providers_running_count = new_state.providers_running_count - 1;
            return new_state;
        }
        case "POI_ADD_TO_CART": {
            new_state.cart = [].concat(new_state.cart, addProviderDates(action.payload.poi));
            new_state.search = '';
            return new_state;
        }
        case "POI_SET_CART": {
            new_state.cart = action.payload.cart.map((item) => {
                return addProviderDates(item);
            });
            if (new_state.refresh !== null) {
                let cart = [...new_state.cart];
                Object.keys(new_state.refresh).map(key => {
                    for (let i = 0; i < cart.length; i++) {
                        if (cart[i].id === parseInt(key)) {
                            let poi = { ...cart[i] };
                            poi.price_change = new_state.refresh[key];
                            cart[i] = poi;
                            break;
                        }
                    }
                });
                new_state.cart = cart;
            }
            return new_state;
        }
        case 'POI_UPDATE_CART': {
            let cart = [...new_state.cart];
            for (let i = 0; i < cart.length; i++) {
                action.payload.map(item => {
                    item.map(new_poi => {
                        if (cart[i].id === new_poi.id) {
                            cart[i] = addProviderDates(new_poi);
                        }
                    });
                });
            }
            new_state.cart = cart;
            return new_state;
        }
        case "POI_EDIT_CART": {
            let cart = new_state.cart.slice();
            cart[action.payload.index] = action.payload.item;
            new_state.cart = cart;
            return new_state;
        }
        case "POI_REMOVE_CART": {
            let copy_cart = new_state.cart.slice();
            copy_cart.splice(action.payload.poi_index, 1);
            new_state.cart = copy_cart;
            return new_state;
        }
        case "POI_REMOVE_CART_BY_ID": {
            let copy_cart = new_state.cart.slice();
            for (let i = 0; i < copy_cart.length; i++) {
                if (copy_cart[i].id === action.payload) {
                    copy_cart.splice(i, 1);
                    break;
                }
            }
            new_state.cart = copy_cart;
            return new_state;
        }
        case "POI_SET_DATE_AND_GROUP": {
            new_state.start_date = action.payload.date;
            new_state.current_groups = action.payload.group;
            return new_state;
        }
        case "POI_TOGGLE_RECOMMENDED": {
            new_state.recommended = !new_state.recommended;
            if (new_state.price_list.length > 0) {
                new_state.price_list = sortResults(new_state.price_list, new_state);
                let filtered_list = filterResults(new_state.price_list, new_state);
                new_state.page_list = getPageResults(filtered_list, 1);
                new_state.count = filtered_list.length;
            }
            new_state.active_page = 1;
            return new_state;
        }
        case "POI_SET_MANUAL_MARGIN_EDIT" : {
            let cart = new_state.cart.slice();
            let copy_cart = [];
            cart.map((item, item_index) => {
                if (item_index === action.payload.index) {
                    let copy_item = Object.assign({}, item);
                    copy_item.edit_margin = action.payload.edit;
                    item = copy_item;
                }
                copy_cart.push(item);
            });
            new_state.cart = copy_cart;
            return new_state;
        }
        case "POI_EDIT_CART_BY_ID": {
            let copy_cart = new_state.cart.slice();
            for (let i = 0; i < copy_cart.length; i++) {
                if (copy_cart[i].id === action.payload.id) {
                    copy_cart[i] = addProviderDates(action.payload);
                    break;
                }
            }
            new_state.cart = copy_cart;
            return new_state;
        }
        case "POI_SET_REQUEST_URL": {
            new_state.request_url = action.payload.request_url;
            return new_state;
        }
        case 'CART_REFRESH_PRICE': {
            if (action.payload !== null) {
                new_state.refresh = action.payload.activity;
            }
            return new_state;
        }
        case "POI_TOGGLE_SORTS": {
            new_state.show_sorts = !new_state.show_sorts;
            return new_state;
        }
        case "POI_TOGGLE_PARAMETERS": {
            new_state.show_parameters = !new_state.show_parameters;
            return new_state;
        }
        case "MATERIAL_PROVIDER_FAILED": {
            new_state.providers_running_count = new_state.providers_running_count - 1;
            return new_state;
        }
        case "MATERIAL_PROVIDER_RUNNING": {
            new_state.providers_running_count = new_state.providers_running_count + 1;
            return new_state;
        }
        case "MATERIAL_UPDATE_HOVERED": {
            new_state.hovered = action.payload.hovered;
            return new_state;
        }
        case "MATERIAL_SORT_LIST": {
            new_state.sort = action.payload.value;
            new_state.price_list = sortResults(new_state.price_list, new_state);
            if (new_state.price_list.length !== 0) {
                let filtered_list = filterResults(new_state.price_list, new_state);
                new_state.page_list = getPageResults(filtered_list, 1);
                new_state.count = filtered_list.length;
            }
            new_state.active_page = 1;
            new_state.show_sorts = false;
            return new_state;
        }
        case "POI_FILTER_LIST": {
            new_state.selected_providers = action.payload.selected_providers;
            new_state.duration_inf = action.payload.duration_inf;
            new_state.duration_sup = action.payload.duration_sup;
            new_state.audio_guide = action.payload.audio_guide;
            new_state.guide = action.payload.guide;
            new_state.escort_guide = action.payload.escort_guide;
            new_state.transport_pass = action.payload.transport_pass;
            new_state.for_children = action.payload.for_children;
            new_state.for_disabled = action.payload.for_disabled;
            new_state.cut_line = action.payload.cut_line;
            new_state.multiple_days = action.payload.multiple_days;
            new_state.poi_filter = action.payload.poi;
            new_state.eco_filter = action.payload.eco;
            new_state.nugget_filter = action.payload.nugget;

            new_state.price_list = sortResults(new_state.price_list, new_state);
            if (new_state.price_list.length !== 0) {
                let filtered_list = filterResults(new_state.price_list, new_state);
                new_state.page_list = getPageResults(filtered_list, 1);
                new_state.count = filtered_list.length;
            }
            new_state.active_page = 1;
            new_state.show_filters = false;
            new_state.active_filters = 0;
            Object.keys(action.payload).forEach((key, index) => {
                if (key === 'selected_providers') {
                    new_state.active_filters += action.payload[key].length;
                } else if (key === "duration_inf" && action.payload[key] !== 0) {
                    new_state.active_filters += 1;
                } else if (key === "duration_sup" && action.payload[key] !== Infinity) {
                    new_state.active_filters += 1;
                } else if ((key === "audio_guide" || key === "guide" || key === "escort_guide" || key === "transport_pass" || key === "for_children" || key === "for_disabled" || key === "cut_line" || key === "multiple_days") && action.payload[key]) {
                    new_state.active_filters += 1;
                }
            });
            return new_state;
        }
        case "POI_SET_PRICES_LOADED": {
            new_state.prices_loaded = action.payload.prices_loaded;
            return new_state;
        }
        case "POI_SET_REQUEST_SENT": {
            new_state.request_sent = action.payload.request_sent;
            return new_state;
        }
        case "POI_SET_CUSTOM_TIME_ERROR": {
            new_state.custom_time_error = action.payload.custom_time_error;
            return new_state;
        }
        case 'POI_TOGGLE_POI_FILTER': {
            new_state.poi_filter = action.payload;
            let filtered_list = filterResults(new_state.price_list, new_state);
            new_state.page_list = getPageResults(filtered_list, 1);
            new_state.count = filtered_list.length;
            return new_state;
        }
        case 'POI_TOGGLE_ECO_FILTER': {
            new_state.eco_filter = action.payload;
            let filtered_list = filterResults(new_state.price_list, new_state);
            new_state.page_list = getPageResults(filtered_list, 1);
            new_state.count = filtered_list.length;
            return new_state;
        }
        case 'POI_TOGGLE_NUGGET_FILTER': {
            new_state.nugget_filter = action.payload;
            let filtered_list = filterResults(new_state.price_list, new_state);
            new_state.page_list = getPageResults(filtered_list, 1);
            new_state.count = filtered_list.length;
            return new_state;
        }
        case 'POI_SET_MEETING_POINT': {
            return { ...new_state, meeting_point: action.payload };
        }
        case 'POI_SET_PICKUP_POINT': {
            return { ...new_state, pickup_point: action.payload };
        }
        case 'POI_SET_RETURN_POINT': {
            return { ...new_state, return_point: action.payload };
        }
        case 'POI_SET_LIST': {
            if (new_state.price_list.length > 0) {
                //new_state.active_page = 1;
                let tmp_list = [...action.payload];
                let filtered_list = filterResults(tmp_list, new_state);
                new_state.page_list = getPageResults(filtered_list, new_state.active_page);
                new_state.count = filtered_list.length;
            }
            return new_state;
        }
        case 'POI_SET_MULTIPLE_DATES': {
            return {...new_state, multiple_dates: action.payload};
        }
    }
    return new_state;
}
