//Dependencies
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useTranslation } from 'react-i18next';
import React, { Fragment, useRef } from 'react';
import { useDispatch } from 'react-redux';
//Core & Lab
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
//Icons
import Cached from '@material-ui/icons/Cached';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
//Actions
import { setCropper } from '../../Actions/Pictures';

const CartPictureCropper = ({ picture }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const cropperRef = useRef(null);

    const onSetCropper = (instance) => {
        dispatch(setCropper(instance));
    };
    
    const onZoom = (value) => () => {
        cropperRef.current.cropper.zoom(value);
    };
    const onResetCrop = () => {
        cropperRef.current.cropper.reset();
    };

    return (
        <Fragment>
            <Grid item xs={ 12 }>
                <Cropper
                    src={ picture.url }
                    style={{ height: 350, width: '100%' }}
                    viewMode={ 1 }
                    background={ false }
                    autoCropArea={ 1 }
                    checkOrientation={ false }
                    zoomable={ true }
                    zoomOnWheel={ true }
                    preview={ '.img-preview' }
                    ref={(instance) => {
                        cropperRef.current = instance;
                        onSetCropper(instance);
                    }}
                    crossOrigin=""
                />
            </Grid>
            <Grid item container xs={ 12 } justify={ 'center' } spacing={ 2 }>
                <Grid item xs={ 12 }/>
                <Grid item>
                    <IconButton onClick={ onZoom(0.1) }>
                        <ZoomIn/>
                    </IconButton>
                    <IconButton onClick={ onZoom(-0.1) }>
                        <ZoomOut/>
                    </IconButton>
                    <Tooltip title={ t('menu.profile.reset_zoom') }>
                        <IconButton onClick={ onResetCrop }>
                            <Cached/>
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={ 12 }/>
            </Grid>
        </Fragment>
    );
};

export default CartPictureCropper;
