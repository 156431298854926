/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import '../../crm.css';
import { SetActiveUserNotes, SetSelectedNote } from "../../../../../Actions/Crm";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import decorator from '../../utils/editor/decorator';
import RichEditor, { RichEditorStyleMap } from '../../utils/editor/editor';
import { EditorDefaultActivatedToolbarControls } from '../../utils/editor/editorToolbar';
import { renderRichEditorContentHTML } from '../../utils/editor/renderRichEditorContentHtml';
import { restoreRichEditorStateFromHtml } from '../../utils/editor/restoreRichEditorStateFromHtml';
import { EditorState } from 'draft-js';
import { createUserNote } from "../../Functions/UserFunctions";
import CloseIcon from '@mui/icons-material/Close';

const default_note = EditorState.createEmpty(decorator);

const CrmUserDetailNoteDialog = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const styles = RichEditorStyleMap;

    const active_user_notes = useSelector(store => store.crm.active_user_notes);
    const selected_note = useSelector(store => store.crm.selected_note);
    const selected_user = useSelector(store => store.crm.selected_user);
    const user = useSelector(store => store.user.user);

    const [editorState, setEditorState] = React.useState(default_note);
    const [dialogSize, setDialogSize] = React.useState('sm');

    const handleClose = () => {
        dispatch(SetSelectedNote(null));
        setEditorState(restoreRichEditorStateFromHtml(styles, ''));
    };

    const onChangeEditorState = (state) => {
        setEditorState(state);
    };

    const save = () => {
        const note = renderRichEditorContentHTML(
            styles,
            editorState.getCurrentContent()
        );
        createUserNote(user.client, selected_user?.id, { note, is_comment: selected_note.is_comment }, (response) => {
            let user_notes = [];
            if (active_user_notes && active_user_notes.user_id === selected_user?.id) {
                user_notes = JSON.parse(JSON.stringify(active_user_notes.notes));
            }
            user_notes.push(response.data);
            dispatch(SetActiveUserNotes(selected_user?.id, user_notes));
            handleClose();
        });
    };

    return (
        <Dialog open={Boolean(selected_note)} maxWidth={dialogSize}>
            <div className="note-dialog-container">
                <div>
                    <p>{selected_note?.is_comment ? t("crm.create_remark") : t("crm.create_note")}</p>
                    <div className="close-div">
                        {dialogSize === 'sm' && <OpenInFullIcon onClick={() => setDialogSize('lg')} />}
                        {dialogSize === 'lg' && <CloseFullscreenIcon onClick={() => setDialogSize('sm')} />}
                        <CloseIcon onClick={handleClose} />
                    </div>
                </div>
                <RichEditor pictures={[]}
                    editorState={editorState}
                    controlledState
                    setEditorState={onChangeEditorState}
                    from_toolkit={true}
                    values={null}
                    toolbars={
                        EditorDefaultActivatedToolbarControls
                            .filter((item) => item !== 'image')
                            .concat(['font-size'])
                    }
                />
                <div>
                    <Button onClick={handleClose}>{t("crm.cancel")}</Button>
                    <Button onClick={save}>{t("crm.save")}</Button>
                </div>
            </div>
        </Dialog>
    );
};
export default React.memo(CrmUserDetailNoteDialog);
