import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import React from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Room from "@material-ui/icons/Room";
import { Link } from "react-router";
import Language from "@material-ui/icons/Language";
import Star from "@material-ui/icons/Star";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    inline: {
        display: "inline"
    },
    returnButton: {
        display: "inline",
        marginRight: 10,
        float: "left",
        marginTop: 6
    },
    bold: {
        fontWeight: "bold"
    },
    pin: {
        fontSize: 14,
        float: "left",
        marginTop: 2,
        marginLeft: -2
    },
    alignRight: {
        textAlign: "right"
    },
    paddingBottom: {
        paddingBottom: theme.spacing(2),
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    stars: {
        display: "inline",
        marginRight: 4,
        verticalAlign: 'sub',
    },
    returnButtonContainer: {
        width: 48
    },
    tripadvisorContainer: {
        width: 120
    },
    inlineBlock: {
        display: 'inline-block'
    },
    noPaddingMargin: {
        padding: '0 !important',
        margin: 0
    },
    marginLeft: {
        marginLeft: 8
    }
}));

const AccommodationCardHeader = ({router, detail, destination, tripadvisor, toggleTripadvisorModal}) => {
    console.log("accommodation header render");
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const bounds = useSelector(state => state.base.bounds);
    const start_date = useSelector(state => state.accommodation.start_date);
    const end_date = useSelector(state => state.accommodation.end_date);
    const current_groups = useSelector(state => state.accommodation.current_groups);
    const returnToList = () => {
        if (bounds !== null) {
            dispatch({type:'MATERIAL_ACCOMMODATION_CARD_UNMOUNT_BACK'});
        } else if (destination !== null) {
            const dest_bounds = JSON.parse(destination.destination.data.bounds.replace(/'/g,'"'));
            dispatch({
                type:'MATERIAL_ACCOMMODATION_CARD_UNMOUNT', payload: {
                    active: destination.id,
                    bounds: {
                        north: dest_bounds.north,
                        east: dest_bounds.east,
                        south: dest_bounds.south,
                        west: dest_bounds.west,
                    },
                    zoom: destination.destination.data.zoom_level,
                    start_date: start_date,
                    end_date: end_date,
                    destination: destination,
                    current_groups: current_groups,
                    reset: false
                }
            });
        }
        router.push(`/${window.url_name}/apps/accommodation`);
    };
    const config = JSON.parse(localStorage.getItem("config"));
    let stamp_recommended = null;
    if (config !== null && config.stamp_recommended !== undefined && config.stamp_recommended !== null && config.stamp_recommended.url !== undefined && config.stamp_recommended.url !== null) {
        stamp_recommended = config.stamp_recommended.url;
    }
    let stars = [];
    for (let i = 0; i < detail.stars; i++) {
        stars.push(<Star key={ i } className={ classes.stars }/>);
    }
    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid container spacing={ 2 }>
            <Grid className={ `${classes.returnButtonContainer} ${classes.noPaddingMargin}` } container item xs={ 12 } alignItems={ "center" }>
                <IconButton className={ `${classes.returnButton} ${classes.noPaddingMargin}` } onClick={ returnToList }>
                    <NavigateBefore fontSize={ "large" }/>
                </IconButton>
                <Typography className={ `${classes.bold} ${classes.marginLeft}` }>{ t("accommodation.other_accommodations") }</Typography>
            </Grid>
            <Grid item xs>
                {
                    detail.recommended && (
                        <img src={ stamp_recommended } alt={ "stamp" }/>
                    )
                }
                <Typography className={ `${classes.inline} ${classes.bold} ${classes.marginRight}` } variant={ "h6" }>{ detail.name }</Typography>
                <Typography className={ `${classes.inlineBlock} ${classes.bold}` } variant={ "h6" }>{ stars }</Typography>
                <br/>
                <Room className={ classes.pin }/>
                <Typography variant={ "body2" }>{ detail.house_number !== null ? `${detail.house_number} ` : '' }{ detail.street !== null ? `${detail.street},` : '' }{ detail.city_name !== null ? detail.city_name : '' }</Typography>
                {
                    destination !== undefined && destination !== null && destination.destination !== undefined && destination.destination !== null && destination.destination.data !== undefined && destination.destination.data !== null && (
                        <Typography className={ `${classes.destination} ${classes.bold}` } variant={ "body2" }>{ destination.destination.data.name !== undefined ? destination.destination.data.name : destination.destination.data.international_name }</Typography>
                    )
                }
            </Grid>
            {
                tablet ? (
                    <Grid item container justify={ "space-between" }>
                        <Grid item>
                            {
                                detail.website !== null && (
                                    <Link className={ classes.marginRight } to={ detail.website } target={ "_blank" } onlyActiveOnIndex>
                                        <Language className={ `${classes.inline} ${classes.pin} ${classes.marginRight}` }/>
                                        <Typography className={ classes.inline }>{ t("accommodation.website") }</Typography>
                                    </Link>
                                )
                            }
                        </Grid>
                        <Grid item>
                            {
                                tripadvisor !== null && (
                                    <ButtonBase className={ classes.paddingBottom } onClick={ toggleTripadvisorModal }>
                                        <img src={ tripadvisor.rating_image_url } alt={ "" } id={ "accommodation-tripadvisor" }/>
                                    </ButtonBase>
                                )
                            }
                        </Grid>
                    </Grid>
                ) : (
                    <Grid className={ classes.tripadvisorContainer } item container justify={ "flex-end" }>
                        <Grid item className={ classes.alignRight }>
                            {
                                tripadvisor !== null && (
                                    <ButtonBase className={ classes.paddingBottom } onClick={ toggleTripadvisorModal }>
                                        <img src={ tripadvisor.rating_image_url } alt={ "" } id={ "accommodation-tripadvisor" }/>
                                    </ButtonBase>
                                )
                            }
                            <br/>
                            {
                                detail.website !== null && (
                                    <Link className={ classes.marginRight } to={ detail.website } target={ "_blank" } onlyActiveOnIndex>
                                        <Language className={ `${classes.inline} ${classes.pin} ${classes.marginRight}` }/>
                                        <Typography className={ classes.inline }>{ t("accommodation.website") }</Typography>
                                    </Link>
                                )
                            }
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    )
};

export default React.memo(AccommodationCardHeader);