import { CartProductStatus } from "../../../CartMaterial/CartConstructionProductsTableItemMenu";
import { useCartProducts } from "../../../Itinerary/network/cartProducts";
import { BookingStatus } from "../../../Itinerary/objects/bookingStatus";
import { StatusBooking } from "../../../Itinerary/objects/statusBooking";
import { AppState } from "../../../../Reducers/Reducers";

type Product = {
    booking_status: BookingStatus | null,
    variant: unknown | null,
    is_optional: boolean,
    is_hidden_for_traveler: boolean,
    is_hidden_for_traveler_count_price: boolean
}

export function filterCartProductsByStatus(
    cart: ReturnType<typeof useCartProducts>,
    itinerary_type_details_data: AppState['itinerary_type']['itinerary_type_details_data'] | null,
    forTravelers: boolean
): typeof cart {
    return {
        accommodations: cart.accommodations.filter((item) => {
            return checkStatus(
                item.accommodation,
                itinerary_type_details_data,
                forTravelers
            );
        }),
        cars: cart.cars.filter((item) => {
            return checkStatus(
                item.car,
                itinerary_type_details_data,
                forTravelers
            );
        }),
        flights: cart.flights.filter((item) => {
            return checkStatus(
                item.flight,
                itinerary_type_details_data,
                forTravelers
            );
        }),
        pois: cart.pois.filter((item) => {
            return checkStatus(
                item.poi,
                itinerary_type_details_data,
                forTravelers
            );
        }),
        transfers: cart.transfers.filter((item) => {
            return checkStatus(
                item.transfer,
                itinerary_type_details_data,
                forTravelers
            );
        }),
        trains: cart.trains.filter((item) => {
            return checkStatus(
                item.train,
                itinerary_type_details_data,
                forTravelers
            );
        }),
        cruises: cart.cruises.filter((item) => {
            return checkStatus(
                item.cruise,
                itinerary_type_details_data,
                forTravelers
            );
        }),
        ferries: cart.ferries.filter((item) => {
            return checkStatus(
                item.ferry,
                itinerary_type_details_data,
                forTravelers
            );
        }),
        assistances: cart.assistances.filter((item) => {
            return checkStatus(
                item.assistance,
                itinerary_type_details_data,
                forTravelers
            );
        }),
        insurances: cart.insurances.filter((item) => {
            return checkStatus(
                item.insurance,
                itinerary_type_details_data,
                forTravelers
            );
        })
    };
}

function checkStatus(
    product: Product,
    itinerary_type_details_data: AppState['itinerary_type']['itinerary_type_details_data'],
    forTravelers: boolean
): boolean {
    let status: CartProductStatus = 'DEFAULT';

    if (product.is_optional) {
        status = 'OPTION';
    } else if (product.is_hidden_for_traveler) {
        status = 'HIDE';
    } else if (product.is_hidden_for_traveler_count_price) {
        status = 'HIDE_COUNT';
    } else if (product.variant && itinerary_type_details_data && itinerary_type_details_data.variant_name) {
        status = 'VARIANT';
    }

    return (
        !product.booking_status?.status_booking ||
        ![
            StatusBooking.CANCELLED,
            StatusBooking.ERROR
        ].includes(product.booking_status.status_booking)
    ) &&
    (
        !forTravelers ||
        !['HIDE', 'HIDE_COUNT'].includes(status)
    );
}
