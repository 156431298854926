import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Checkbox,
    IconButton,
    InputAdornment,
    styled,
    TextField,
    Typography
} from "@mui/material";
import { useDrag, useDragLayer, useDrop, XYCoord } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { clone, flatten } from "lodash";
import { Block } from "../objects/block";
import { generateBlockId } from "../utils/block-id";
import { registerBlock } from "../utils/registered-blocks";
import { setVisualEditorBlockOptions } from "../redux/actions";
import { AppState } from "../../../../../Reducers/Reducers";
import { MailTemplateVisualEditorPaddingOptions } from "../mailTemplateVisualEditorPaddingOptions";
import { MailTemplateVisualEditorBackgroundColorOption } from "../mailTemplateVisualEditorBackgroundColorOption";
import { MailTemplateVisualEditorColorOption } from "../mailTemplateVisualEditorColorOption";
import { MailTemplateVisualEditorSizeOption } from "../mailTemplateVisualEditorSizeOption";
import { MailTemplateVisualEditorBorderRadiusOption } from "../mailTemplateVisualEditorBorderRadiusOption";
import { MailTemplateVisualEditorOptionsSection } from "../mailTemplateVisualEditorOptionsSection";
import { MailTemplateVisualEditorContext } from "../mailTemplateVisualEditorContext";
import { Alignment, MailTemplateVisualEditorAlignmentOption } from "../mailTemplateVisualEditorAlignmentOption";
import SocialIcon from "@mui/icons-material/People";
import DragVerticallyIcon from '@mui/icons-material/UnfoldMore';
import FacebookIconUrl from "../assets/facebook-f-brands.png";
import TwitterIconUrl from "../assets/twitter-brands-min.png";
import InstagramIconUrl from "../assets/instagram-brands.png";
import PinterestIconUrl from "../assets/pinterest-p-brands.png";
import LinkedinIconUrl from "../assets/linkedin-in-brands.png";
import YoutubeIconUrl from "../assets/youtube-brands.png";
import EnvelopeIconUrl from "../assets/envelope.png";
import WebsiteIconUrl from "../assets/website-link.png";
import { BlockHtmlAttribute } from "../objects/blockHtmlAttributes";

export type IconData = {
    name: string,
    url: string,
    logoUrl: string,
    labelKey: string,
    activated: boolean
}

type Options = {
    icons: IconData[],
    backgroundColor: string,
    iconColor: string,
    iconSize: number,
    borderRadius: number,
    iconAlignment: Alignment,
    padding: MailTemplateVisualEditorPaddingOptions,
}

type Props = {
    id: number,
    options: Options
}

type OptionsComponentProps = {
    id: number,
    options: Options
}

function MailTemplateVisualEditorSocialBlock(props: Props): JSX.Element {
    return (
        <div
            style={{
                backgroundColor: props.options.backgroundColor,
                paddingTop: props.options.padding.top,
                paddingBottom: props.options.padding.bottom,
                paddingLeft: props.options.padding.left,
                paddingRight: props.options.padding.right,
                textAlign: props.options.iconAlignment
            }}
            data-border-radius={props.options.borderRadius}
            data-icon-color={props.options.iconColor}
            data-icon-size={props.options.iconSize}
            className="social-block-container"
        >
            {
                props.options.icons.filter((icon) => icon.activated).map((icon) => (
                    <Icon
                        {...icon}
                        {...props}
                        key={icon.name}
                    />
                ))
            }
        </div>
    );
}

type IconProps = IconData & Props;

const Icon = (props: IconProps) => {
    return (
        <a
            href={
                props.name === 'mail' ?
                    `mailto:${props.url}` :
                    props.url
            }
            onClick={(event) => event.preventDefault()}
            target="__blank"
            data-name={props.name}
            data-label-key={props.labelKey}
            data-url={props.url}
            data-icon-url={props.logoUrl}
        >
            <div
                style={{
                    display: 'inline-block',
                    width: props.options.iconSize,
                    height: props.options.iconSize,
                    padding: '10px',
                    backgroundColor: props.options.iconColor,
                    borderRadius: props.options.borderRadius,
                    marginLeft: 2.5,
                    marginRight: 2.5,
                    boxSizing: 'unset'
                }}
            >
                <img
                    src={props.logoUrl}
                    style={{ width: 'auto', height: '100%' }}
                />
            </div>
        </a>
    );
};

function MailTemplateVisualEditorSocialBlockOptions(props: OptionsComponentProps): JSX.Element {
    const context = useContext(MailTemplateVisualEditorContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => context.locale ?? state.locale.current_locale);
    const controlsContainerRef = useRef<HTMLDivElement>(null);

    const onChangeOption = (key: keyof OptionsComponentProps["options"], value: any) => {
        if (locale !== null) {
            const dispatchData = setVisualEditorBlockOptions(
                context.instanceId,
                locale,
                props.id,
                {
                    ...props.options,
                    [key]: value
                }
            );
            dispatch(dispatchData);
        }
    };

    const onChangePaddingOption = (type: keyof MailTemplateVisualEditorPaddingOptions, value: number) => {
        if (locale !== null) {
            const dispatchData = setVisualEditorBlockOptions(
                context.instanceId,
                locale,
                props.id,
                {
                    ...props.options,
                    padding: {
                        ...props.options.padding,
                        [type]: value
                    }
                }
            );
            dispatch(dispatchData);
        }
    };

    const onChangeIconUrl = (name: string, url: string) => {
        onChangeOption(
            "icons",
            props.options.icons.map((item) => {
                if (item.name === name) {
                    return {
                        ...item,
                        url
                    };
                }
                return item;
            })
        );
    };

    const changeIconState = (name: string, activate: boolean) => {
        onChangeOption(
            "icons",
            props.options.icons.map((item) => {
                if (item.name === name) {
                    return {
                        ...item,
                        activated: activate
                    };
                }
                return item;
            })
        );
    };

    const moveIcon = (dragIndex: number, hoverIndex: number) => {
        const swapA = props.options.icons[dragIndex];
        const swapB = props.options.icons[hoverIndex];

        if (swapA && swapB) {
            onChangeOption(
                "icons",
                props.options.icons.map((item) => {
                    if (item.name === swapA.name) {
                        return swapB;
                    } else if (item.name === swapB.name) {
                        return swapA;
                    }
                    return item;
                })
            );
        }
    };

    return (
        <div>
            <MailTemplateVisualEditorSizeOption
                size={props.options.iconSize}
                onChangeSize={(size) => onChangeOption("iconSize", size)}
                min={17}
            />
            <MailTemplateVisualEditorBackgroundColorOption
                backgroundColor={props.options.backgroundColor}
                onChangeBackgroundColor={(color) => onChangeOption("backgroundColor", color)}
            />
            <MailTemplateVisualEditorColorOption
                color={props.options.iconColor}
                onChangeColor={(color) => onChangeOption("iconColor", color)}
            />
            <MailTemplateVisualEditorBorderRadiusOption
                borderRadius={props.options.borderRadius}
                onChangeBorderRadius={(borderRadius) => onChangeOption("borderRadius", borderRadius)}
            />
            <MailTemplateVisualEditorPaddingOptions
                padding={props.options.padding}
                onChangePadding={onChangePaddingOption}
            />
            <MailTemplateVisualEditorAlignmentOption
                alignment={props.options.iconAlignment}
                onChangeAlignment={(alignment) => onChangeOption("iconAlignment", alignment)}
            />
            <MailTemplateVisualEditorOptionsSection>
                <Typography>{t<string>('shared.mail-template-visual-editor-social-urls-title')}</Typography>
                <URLControlsContainer ref={controlsContainerRef}>
                    <IconControlDragLayer parentRef={controlsContainerRef} />
                    {
                        props.options.icons.map((icon, index) => (
                            <IconControl
                                key={icon.name}
                                index={index}
                                icon={icon}
                                onChangeIconUrl={onChangeIconUrl}
                                changeIconState={changeIconState}
                                moveIcon={moveIcon}
                            />
                        ))
                    }
                </URLControlsContainer>
            </MailTemplateVisualEditorOptionsSection>
        </div>
    );
}

const URLControlsContainer = styled('div')((props) => ({
    display: 'flex',
    marginTop: props.theme.spacing(1),
    gap: props.theme.spacing(2.5),
    flexDirection: 'column'
}));

type IconControlProps = {
    index: number,
    icon: IconData,
    onChangeIconUrl: (name: string, url: string) => void,
    changeIconState: (name: string, activate: boolean) => void,
    moveIcon: (dragIndex: number, hoverIndex: number) => void
}

/**
 * @see https://react-dnd.github.io/react-dnd/examples/sortable/simple
 * */
const IconControl = (props: IconControlProps): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);
    const parentRef = useRef<HTMLDivElement>(null);
    const [{ isDragging }, drag, preview] = useDrag({
        type: 'social-icon-control',
        item: () => ({
            index: props.index,
            icon: props.icon
        }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });
    const [, drop] = useDrop({
        accept: 'social-icon-control',
        hover(item: {index: number}, monitor) {
            if (!parentRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.index;

            //Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            //Determine rectangle on screen
            const hoverBoundingRect = parentRef.current.getBoundingClientRect();

            //Get vertical middle
            const hoverMiddleY =
              (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            //Determine mouse position
            const clientOffset = monitor.getClientOffset();

            //Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            //Only perform the move when the mouse has crossed half of the items height
            //When dragging downwards, only move when the cursor is below 50%
            //When dragging upwards, only move when the cursor is above 50%

            //Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            //Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            //Time to actually perform the action
            props.moveIcon(dragIndex, hoverIndex);

            //Note: we're mutating the monitor item here!
            //Generally it's better to avoid mutations,
            //but it's good here for the sake of performance
            //to avoid expensive index searches.
            item.index = hoverIndex;
        }
    });
    drag(ref);
    drop(parentRef);

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    return (
        <IconControlItem ref={ref} parentRef={parentRef} isDragging={isDragging} {...props} />
    );
};

type IconControlDragLayerProps = {
    parentRef: React.RefObject<HTMLDivElement>
}

/**
 * @see https://github.com/react-dnd/react-dnd/issues/397#issuecomment-192327717
 */
const IconControlDragLayer = (props: IconControlDragLayerProps): JSX.Element | null => {
    const { isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        isDragging: monitor.isDragging(),
        item: monitor.getItem() as {index: number, icon?: IconData} | null,
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset()
    }));

    if (
        !isDragging ||
        !item ||
        !item.icon ||
        props.parentRef.current?.offsetWidth === undefined
    ) {
        return null;
    }

    return (
        <IconControlDragLayerContainer
            sx={
                getIconControlDragLayerStyles(
                    initialOffset,
                    currentOffset,
                    props.parentRef.current.offsetWidth
                )
            }
        >
            <IconControlItem
                index={0}
                icon={item.icon}
                changeIconState={() => null}
                moveIcon={() => null}
                onChangeIconUrl={() => null}
            />
        </IconControlDragLayerContainer>
    );
};

const IconControlDragLayerContainer = styled('div')((props) => ({
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: props.theme.zIndex.drawer + 1,
    top: 0
}));

function getIconControlDragLayerStyles(
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null,
    parentWidth: number
) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none'
        };
    }

    const transform = `translateY(${currentOffset.y}px)`;

    return {
        width: parentWidth,
        transform,
        WebkitTransform: transform
    };
}

type IconControlItemProps = {
    parentRef?: React.RefObject<HTMLDivElement>,
    isDragging?: boolean
} & IconControlProps

//eslint-disable-next-line react/display-name
const IconControlItem = React.forwardRef<HTMLDivElement, IconControlItemProps>((props, ref) => {
    const { t } = useTranslation();
    return (
        <IconControlContainer ref={props.parentRef} sx={{ opacity: props.isDragging ? 0 : 1 }}>
            <TextField
                label={t<string>(props.icon.labelKey)}
                variant="outlined"
                value={props.icon.url}
                onChange={(event) => props.onChangeIconUrl(props.icon.name, event.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Checkbox
                                checked={props.icon.activated}
                                onChange={(event, checked) => {
                                    props.changeIconState(props.icon.name, checked);
                                }}
                            />
                        </InputAdornment>
                    )
                }}
                sx={{ flexGrow: 1 }}
                disabled={!props.icon.activated}
            />
            <div ref={ref}>
                <IconButton disableFocusRipple>
                    <DragVerticallyIcon />
                </IconButton>
            </div>
        </IconControlContainer>
    );
});

const IconControlContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center'
}));

export class SocialBlock implements Block<Options> {
    private id;
    private options: Options;

    public constructor() {
        this.id = generateBlockId();
        this.options = {
            icons: defaultIcons,
            backgroundColor: "rgba(255, 255, 255, 0)",
            iconColor: "#fff",
            iconSize: 26,
            borderRadius: 5,
            iconAlignment: 'center',
            padding: {
                top: 5,
                bottom: 5,
                left: 5,
                right: 5
            }
        };
    }

    public getType(): string {
        return "social";
    }

    public getId(): number {
        return this.id;
    }

    public setOptions(options: Options): void {
        this.options = options;
    }

    public getOptions(): Options {
        return this.options;
    }

    public getExtraHtmlAttributes(): BlockHtmlAttribute[] {
        return [
            {
                path: `.social-block.${this.id}`,
                name: 'data-border-radius',
                content: this.options.borderRadius.toString()
            },
            {
                path: `.social-block.${this.id}`,
                name: 'data-icon-color',
                content: this.options.iconColor
            },
            {
                path: `.social-block.${this.id}`,
                name: 'data-icon-size',
                content: this.options.iconSize.toString()
            },
            ...flatten(this.options.icons.filter((icon) => {
                return icon.activated;
            }).map((icon, index): BlockHtmlAttribute[] => {
                return [
                    {
                        path: `.social-block.${this.id} .social-block-element.${index}`,
                        name: 'data-name',
                        content: icon.name
                    },
                    {
                        path: `.social-block.${this.id} .social-block-element.${index}`,
                        name: 'data-url',
                        content: icon.url
                    },
                    {
                        path: `.social-block.${this.id} .social-block-element.${index}`,
                        name: 'data-logo-url',
                        content: icon.logoUrl
                    },
                    {
                        path: `.social-block.${this.id} .social-block-element.${index}`,
                        name: 'data-label-key',
                        content: icon.labelKey
                    }
                ];
            }))
        ];
    }

    public renderMjml(): string {
        const icons = this.options.icons.filter((icon) => {
            return icon.activated;
        }).map((icon, index) => `
            <mj-social-element
                background-color="${this.options.iconColor}"
                href="${icon.url}"
                src="${icon.logoUrl}"
                css-class="social-block-element ${index}"
            >
            </mj-social-element>
        `);
        return `
            <mj-social
                align="${this.options.iconAlignment}"
                icon-size="${this.options.iconSize}px"
                border-radius="${this.options.borderRadius}px"
                container-background-color="${this.options.backgroundColor}"
                padding-top="${this.options.padding.top}px"
                padding-bottom="${this.options.padding.bottom}px"
                padding-left="${this.options.padding.left}px"
                padding-right="${this.options.padding.right}px"
                icon-padding="10px"
                inner-padding="2.5px"
                css-class="social-block ${this.id}"
            >
                ${icons}
            </mj-social>
        `;
    }

    public clone(options?: Options): SocialBlock {
        let block: SocialBlock;

        if (options) {
            block = clone(this);
        } else {
            block = new SocialBlock();
        }

        const blockOptions = options ?? this.getOptions();
        block.setOptions(blockOptions);

        return block;
    }
}

registerBlock({
    type: "social",
    icon: <SocialIcon />,
    label: "shared.mail-template-visual-editor-social-block-label",
    component: MailTemplateVisualEditorSocialBlock,
    optionsComponent: MailTemplateVisualEditorSocialBlockOptions,
    factory: () => new SocialBlock(),
    styles: [
        `
            .social-block-element img {
                border-radius: 0 !important;
            }
        `
    ]
});

export const defaultIcons: IconData[] = [
    {
        name: "facebook",
        url: "https://www.facebook.com",
        logoUrl: transformToAbsUrl(FacebookIconUrl),
        labelKey: "shared.mail-template-visual-editor-facebook-url",
        activated: true
    },
    {
        name: "twitter",
        url: "https://www.twitter.com",
        logoUrl: transformToAbsUrl(TwitterIconUrl),
        labelKey: "shared.mail-template-visual-editor-twitter-url",
        activated: true
    },
    {
        name: "instagram",
        url: "https://www.instagram.com",
        logoUrl: transformToAbsUrl(InstagramIconUrl),
        labelKey: "shared.mail-template-visual-editor-instagram-url",
        activated: true
    },
    {
        name: "youtube",
        url: "https://www.youtube.com",
        logoUrl: transformToAbsUrl(YoutubeIconUrl),
        labelKey: "shared.mail-template-visual-editor-youtube-url",
        activated: true
    },
    {
        name: "pinterest",
        url: "https://www.pinterest.com",
        logoUrl: transformToAbsUrl(PinterestIconUrl),
        labelKey: "shared.mail-template-visual-editor-pinterest-url",
        activated: true
    },
    {
        name: "linkedin",
        url: "https://www.linkedin.com",
        logoUrl: transformToAbsUrl(LinkedinIconUrl),
        labelKey: "shared.mail-template-visual-editor-linkedin-url",
        activated: true
    },
    {
        name: "mail",
        url: "example@facilitatrip.com",
        logoUrl: transformToAbsUrl(EnvelopeIconUrl),
        labelKey: "shared.mail-template-visual-editor-contact-mail",
        activated: true
    },
    {
        name: "website",
        url: "https://wwww.example.com",
        logoUrl: transformToAbsUrl(WebsiteIconUrl),
        labelKey: "shared.mail-template-visual-editor-website-url",
        activated: true
    }
];

function transformToAbsUrl(url: string): string {
    if (url.startsWith('http')) {
        return url;
    }
    return `${window.location.origin}${url.startsWith('/') ? url : '/' + url}`;
}
