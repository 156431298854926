import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSnackbar } from "notistack";
import clsx from "clsx";
import axios from "axios";

import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { SetMarginStep } from "../../../Actions/Menu";
import PushMargin from "./Functions/PushMargin";

const useRowStyles = makeStyles({
    returnButton: {
	    "color": "grey",
	    "backgroundColor": "white",
	    '&:hover': {
	        color: "white",
	        backgroundColor: "grey"
	    }
    },
    inputBorder: {
	    "&& .mui-jss-MuiOutlinedInput-root": {
	        borderRadius: 8
	    }
    },
    genericText: {
        color: "#0000008A",
        fontStyle: "normal",
        fontFamily: "Roboto"
    },
    bold: {
    	fontWeight: "bold"
    },
    footer: {
        position: "sticky",
        bottom: 0,
        zIndex: 2,
        backgroundColor: "white"
    },
    footerMobile: {
        position: "sticky",
        bottom: 64,
        zIndex: 2,
        backgroundColor: "white"
    },
    orangeButton: {
        color: "white",
        backgroundColor: "#E6592F"
    },
    green: {
        color: green[500]
    },
    red: {
        color: red[500]
    }
});

const AddMargin = () => {
    const { t } = useTranslation();
    const classes = useRowStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down("sm"));

    const currency_list = useSelector(store => store.base.currency_list);
    const user = useSelector(store => store.user.user);
    const language = useSelector(store => store.header.language);
    const [typeOfMargin, setTypeOfMargin] = useState("");
    const [contentMargin, setContentMargin] = useState("");
    const [marginType, setMarginType] = useState("");
    const [currency, setCurrency] = useState(null);
    const [marginName, setMarginName] = useState("");
    const [marginRate, setMarginRate] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [error, setError] = useState({
        emptyCurrency: false,
        emptyName: false,
        emptyRate: false
    });
    const returnButton = () => {
        dispatch(SetMarginStep(1));
    };
    const handleTypeOfMargin = (e) => {
        setTypeOfMargin(e.target.value);
    };
    const handleContentMargin = (e) => {
        setContentMargin(e.target.value);
    };
    const handleMarginType = (e) => {
        setCurrency(null);
        setMarginType(e.target.value);
    };
    const handleMarginName = (e) => {
        setMarginName(e.target.value);
    };
    const handleMarginRate = (e) => {
        setMarginRate(e.target.value);
    };
    const resetDates = () => {
        setStartDate(null);
        setEndDate(null);
    };
    const checkEmpty = () => {
        let tmp_error = Object.assign({}, error);
        let request = {
            content_types: contentMargin !== "" ? [contentMargin] : [],
            currency_rate: currency !== null ? currency.id : null,
            end_date: endDate !== null ? moment.utc(endDate).format("YYYY-MM-DD") : null,
            name: marginName,
            rate: marginRate,
            start_date: startDate !== null ? moment.utc(startDate).format("YYYY-MM-DD") : null,
            type_rate: marginType
        };
        if (marginRate !== "" && marginName !== "") {
            if (marginType === "FIX") {
                if (currency !== "") {
                    PushMargin(request, dispatch, user, enqueueSnackbar);
                    tmp_error.emptyCurrency = false;
                } else {
                    tmp_error.emptyCurrency = true;
                    enqueueSnackbar(t("menu.margin.error"), { variant: "error" });
                }
            } else {
                PushMargin(request, dispatch, user, enqueueSnackbar);
                tmp_error.emptyName = false;
                tmp_error.emptyRate = false;
            }
        } else {
            if (marginType === "FIX") {
                if (currency === "") {
                    tmp_error.emptyCurrency = true;
                }
            }
            tmp_error.emptyName = true;
            tmp_error.emptyRate = true;
            enqueueSnackbar(t("menu.margin.error"), { variant: "error" });
        }
        setError(tmp_error);
    };
    return (
        <Fragment>
            <Grid item xs={12}>
                <Grid container direction={"row"} justify={"flex-start"} alignItems={"center"}>
                    <Grid item>
                        <Button
                            variant={"contained"}
                            className={classes.returnButton}
                            onClick={returnButton}
                        >
                            {t("menu.margin.return_to_tab")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid container direction={"column"} justify={"center"} alignItems={"center"} style={{ marginBottom: 10 }}>
                    <Grid item><Typography variant={downDesktop ? "h6" : "h5"}>{ t("menu.margin.create_margin_title") }</Typography></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid container direction={"column"} spacing={2} style={{ marginBottom: 10 }}>
                    <Grid item sm={4} xs={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="select-type-margin">{ t("menu.margin.type_of_margin") }</InputLabel>
                            <Select
                                MenuProps={{ disableScrollLock: true }}
                                labelId="select-type-margin"
                                value={typeOfMargin}
                                onChange={handleTypeOfMargin}
                                label={ t("menu.margin.type_of_margin") }
                            >
                                <MenuItem value={0}>{t("menu.margin.service_margin")}</MenuItem>
                                <MenuItem value={1}>{t("menu.margin.content_margin")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        typeOfMargin === 1 && (
                            <Grid item sm={4} xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="select-content-margin">{t("menu.margin.margin_for_content")}</InputLabel>
                                    <Select
                                        MenuProps={{ disableScrollLock: true }}
                                        labelId="select-content-margin"
                                        value={contentMargin}
                                        onChange={handleContentMargin}
                                        label={t("menu.margin.margin_for_content")}
                                    >
                                        <MenuItem value={0}>{t("providers.hotels")}</MenuItem>
                                        <MenuItem value={1}>{t("providers.excursions")}</MenuItem>
                                        <MenuItem value={2}>{t("providers.rental_car")}</MenuItem>
                                        {/*<MenuItem value={3}>{t("apps_navigation.restaurant")}</MenuItem> */}
                                        <MenuItem value={4}>{t("providers.transfers")}</MenuItem>
                                        {/*<MenuItem value={5}>{t("providers.trains")}</MenuItem> */}
                                        <MenuItem value={6}>{t("providers.flight")}</MenuItem>
                                        <MenuItem value={7}>{t("providers.accommodations")}</MenuItem>
                                        <MenuItem value={8}>{t("providers.insurances")}</MenuItem>
                                        <MenuItem value={16}>{t("providers.administration_fees")}</MenuItem>
                                        {/*<MenuItem value={17}>{t("providers.travel_book")}</MenuItem> */}
                                        {/*<MenuItem value={9}>{t("providers.cruises")}</MenuItem> */}
                                        {/*<MenuItem value={10}>{t("providers.touristic_guide")}</MenuItem> */}
                                        <MenuItem value={12}>{t("providers.activities")}</MenuItem>
                                        <MenuItem value={11}>{t("providers.other_product")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
            <Divider/>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid container direction={"column"} spacing={2} style={{ marginBottom: 10 }}>
                    <Grid item><Typography variant={"h6"}>{ t("menu.margin.margin_name") }</Typography></Grid>
                    <Grid item sm={4} xs={12}>
                        <TextField fullWidth label={t("global.name")} error={error.emptyName} variant={"outlined"} value={marginName} onChange={handleMarginName} />
                    </Grid>
                </Grid>
            </Grid>
            <Divider/>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid container direction={"column"} spacing={2} style={{ marginBottom: 10 }}>
                    <Grid item><Typography variant={"h6"}>{ t("menu.margin.margin") }</Typography></Grid>
                    <Grid item sm={4} xs={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="select-margin">{ t("menu.margin.margin_in") }</InputLabel>
                            <Select
                                MenuProps={{ disableScrollLock: true }}
                                labelId="select-margin"
                                value={marginType}
                                onChange={handleMarginType}
                                label={ t("menu.margin.margin_in") }
                            >
                                <MenuItem value={"FIX"}>{t("menu.margin.fixe")}</MenuItem>
                                <MenuItem value={"PER"}>{t("menu.margin.percentage")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        marginType === "FIX" && (
                            <Grid item sm={4} xs={12}>
                                <Autocomplete
                                    id="autocomplete-currency"
                                    freeSolo
                                    //value={currency}
                                    onChange={(event, newValue) => {
                                        setCurrency(newValue);
                                    }}
                                    getOptionLabel={(option) => `${option.name} - ${option.symbol}`}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    options={currency_list}
                                    renderInput={(params) => (
                                        <TextField {...params} error={error.emptyCurrency} label={t("global.currency")} margin="normal" variant="outlined" />
                                    )}
                                />
                            </Grid>
                        )
                    }
                    {
                        marginType !== "" && (
                            <Grid item sm={4} xs={12}>
                                <TextField fullWidth onChange={handleMarginRate} error={error.emptyRate} value={marginRate} label={ marginType === "FIX" ? t("placeholder.price_in_currency") : t("menu.margin.percentage") } variant={"outlined"} />
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
            <Divider/>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid container direction={"column"} spacing={2} style={{ marginBottom: 10 }}>
                    <Grid item><Typography variant={"h6"}>{ t("menu.margin.date_of_margin") }</Typography></Grid>
                    <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale={language}>
                        <Grid item>
                            <Grid container direction={"row"} alignItems={"center"} spacing={2} style={{ marginTop: 10 }}>
                                <Grid item sm={4} xs={12}>
                                    <DatePicker
                                        label={t("header.responsive.start")}
                                        value={startDate}
                                        onChange={(newValue) => setStartDate(newValue)}
                                        views={["year", "month", "date"]}
                                        clearable={true}
                                        renderInput={(props) => 
                                            (<TextField
                                                {...props}
                                                label={t("header.responsive.start")}
                                                variant={"outlined"}
                                                //className={classes.formControlRoot}
                                                helperText={""}
                                                error={false}
                                                fullWidth={true}
                                            />)
                                        }
                                    />
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <DatePicker
                                        label={t("header.responsive.end")}
                                        value={endDate}
                                        onChange={(newValue) => setEndDate(newValue)}
                                        minDate={startDate !== null ? startDate : ""}
                                        clearable={true}
                                        views={["year", "month", "date"]}
                                        renderInput={(props) => 
                                            (<TextField
                                                {...props}
                                                label={t("header.responsive.end")}
                                                variant={"outlined"}
                                                helperText={""}
                                                //className={classes.formControlRoot}
                                                error={false}
                                                fullWidth={true}
                                            />)
                                        }
                                    />
                                </Grid>
                                <Grid item sm={2} xs={12}>
                                    <Button fullWidth onClick={resetDates} variant={"outlined"}>{t("accommodation_filters.reset_filters")}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Divider/>
            <Grid item xs={12} className={!downDesktop ? classes.footer : classes.footerMobile}>
                <Grid container direction="row" spacing={2} style={{ width: "100%" }}>
                    <Grid item><Typography variant={"subtitle1"}>{t("menu.margin.margin_info")}</Typography></Grid>
                </Grid>
                <Grid container direction="row" justify={"flex-end"} spacing={2} style={{ width: "100%" }}>
                    <Grid item>
                        <Button onClick={returnButton}>{t("accommodation.cancel")}</Button>
                    </Grid>
                    <Grid item>
                        <Button className={classes.orangeButton} onClick={checkEmpty} variant={"outlined"}>{t("header.save")}</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
};
export default React.memo(AddMargin);
