import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import { Navigation } from "@mui/icons-material";
import GoogleMapsLoader from "google-maps";
import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { debounce, isNumber } from "lodash";
import ItineraryMuiGooglePlaceAutocomplete from "../Itinerary/itineraryMuiGooglePlaceAutocomplete";
import { ItineraryMapStatus } from "./itineraryMapStatus";
import { getLightDestinationName } from "../Itinerary/utils/getLightDestinationName";
import { getTransportColor } from "../Itinerary/utils/getTransportColor";
import { getDestinationInheritance } from "../Itinerary/utils/getDestinationInheritance";
import { getDestinationPicture } from "../Itinerary/utils/getDestinationPicture";
import { findDestinationChildren } from "../Itinerary/utils/findDestinationChildren";
import { getQuickDestination } from "../Itinerary/utils/getQuickDestination";
import { filterDestinationsBasedOnZoom } from "../Itinerary/utils/filterDestinationsBasedOnZoom";
import { transformStepInputsToGroups } from "../Itinerary/utils/transformStepInputsToGroups";
import { useItineraryStepAdd } from "../Itinerary/network/itineraryStepAdd";
import { useItineraryStepSee } from "../Itinerary/network/itineraryStepSee";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { createItineraryDestinationInfoWindowClass } from "../Itinerary/itineraryDestinationInfoWindow";
import { createItineraryDestinationMarkerClass } from "../Itinerary/itineraryDestinationMarker";
import { createDestinationMarkerImage } from "../Itinerary/utils/createDestinationMarkerImage";
import { StepsDirectionsManager } from "../Itinerary/utils/stepsDirectionsManager";
import { ItineraryMarkerBag } from "./utils/itineraryMarkerBag";
import {
    changeStepDaysCount,
    deleteStepInput,
    setBlocksCircuits,
    setBlocksTypicalTrips,
    setDestinations,
    setMap,
    setSearching
} from "../Itinerary/redux/reducer";
import { setCurrentBounds, setCurrentZoom } from "./redux/reducer";
import { store } from "../../Store";
import { LightDestination } from "../Itinerary/objects/lightDestination";
import { ItineraryInput } from "../Itinerary/objects/itineraryState";
import { LockBox } from "../Itinerary/objects/lockBox";
import { Block } from "../Itinerary/objects/block";
import { TripBlock } from "../Itinerary/objects/tripBlock";
import { AppState, Key } from "../../Reducers/Reducers";

export function ItineraryMap(): JSX.Element | null {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const destination = useSelector((state: AppState) => state.header.destination);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const destinations = useSelector((state: AppState) => state.itinerarySlice.destinations);
    const steps = useSelector((state: AppState) => state.itinerarySlice.stepsInputs);
    const map = useSelector((state: AppState) => state.itinerarySlice.map);
    const bounds = useSelector((state: AppState) => state.map.currentBounds);
    const zoom = useSelector((state: AppState) => state.map.currentZoom);
    const isUserTO = useSelector((state: AppState) => state.user.user?.client_full?.type !== 2);
    const blocksSearch = useSelector((state: AppState) => state.itinerarySlice.blocks.filters.search);
    const lockBoxes = useSelector((state: AppState) => state.itinerarySlice.blocks.lockBoxes);
    const parentDestination = useSelector((state: AppState) => state.itinerarySlice.parentDestination);
    const destinationsTab = useSelector((state: AppState) => state.itinerarySlice.destinationsTab);
    const ref = useRef<HTMLDivElement>(null);
    const renderedPolylines = useRef<google.maps.Polyline[]>([]);
    const fireDestinationsListNetworkRequest = useCallback(
        debounce(
            async (
                options: {
                    isUserTO: boolean,
                    bounds: google.maps.LatLngBounds,
                    zoom: number,
                    cancelToken: CancelTokenSource
                }
            ) => {
                if (options.bounds) {
                    try {
                        dispatch(
                            setDestinations({
                                state: 'loading'
                            })
                        );
                        const response = await makeDestinationsRequest(options);
                        dispatch(
                            setDestinations({
                                state: 'success',
                                data: filterDestinationsBasedOnZoom(response.data, options.zoom)
                            })
                        );
                    } catch (error: any) {
                        dispatch(
                            setDestinations({
                                state: 'error',
                                error
                            })
                        );
                    }
                }
            },
            500
        ),
        []
    );
    const fireBlocksNetworkRequests = useCallback(
        debounce(
            async (
                parentDestination: number | null,
                options: {
                    search: string,
                    isUserTO: boolean,
                    cancelToken: CancelTokenSource,
                    excludeCircuits?: number[],
                    excludeTypicalTrips?: number[]
                }
            ) => {
                if (parentDestination) {
                    dispatch(setBlocksCircuits({ state: 'loading' }));
                    dispatch(setBlocksTypicalTrips({ state: 'loading' }));

                    dispatch(setSearching(true));
                    if (options.search.trim().length > 0) {
                        await Promise.all([
                            (async () => {
                                const circuits = await makePackageBlocksRequests(options);
                                dispatch(setBlocksCircuits({ state: 'success', data: circuits, error: null }));
                            })(),
                            (async () => {
                                const trips = await makeTypicalTripBlocksRequests(options);
                                dispatch(setBlocksTypicalTrips({ state: 'success', data: trips, error: null }));
                            })()
                        ]);
                    } else {
                        await Promise.all([
                            (async () => {
                                const circuits = await makePackageBlocksRequests({ ...options, parentDestination });
                                dispatch(setBlocksCircuits({ state: 'success', data: circuits, error: null }));
                            })(),
                            (async () => {
                                const trips = await makeTypicalTripBlocksRequests({ ...options, parentDestination });
                                dispatch(setBlocksTypicalTrips({ state: 'success', data: trips, error: null }));
                            })()
                        ]);
                    }
                    dispatch(setSearching(false));
                } else {
                    dispatch(setBlocksCircuits({ state: 'success', data: [], error: null }));
                    dispatch(setBlocksTypicalTrips({ state: 'success', data: [], error: null }));
                }
            },
            500
        ),
        []
    );
    const onUpdateDestinations = useCallback(
        debounce(
            (
                options: {
                    isUserTO: typeof isUserTO,
                    bounds: typeof bounds,
                    zoom: typeof zoom,
                    map: typeof map,
                    cancelToken: typeof destinationsCancelToken
                }
            ) => {
                if (options.map && !options.map.get('noNotify') && options.bounds) {
                    fireDestinationsListNetworkRequest({
                        isUserTO: options.isUserTO,
                        bounds: options.bounds,
                        zoom: options.zoom,
                        cancelToken: options.cancelToken!
                    });
                }

                if (options.map?.get('noNotify')) {
                    options.map.set('noNotify', false);
                }
            },
            500
        ),
        []
    );
    const IwClass = useMemo(() => {
        if (map) {
            return createItineraryDestinationInfoWindowClass();
        }
        return null;
    }, [map]);
    const DestinationMarkerClass = useMemo(() => {
        if (map) {
            return createItineraryDestinationMarkerClass();
        }
    }, [map]);
    const stepsMarkerBag = useMemo(() => {
        if (map) {
            return new ItineraryMarkerBag<ItineraryInput, google.maps.Marker>(
                async (step, index) => {
                    const manager = StepsDirectionsManager.getInstance();
                    const position = await manager.transformStepToCoordinates(step);
                    switch (step.step_type) {
                        case 'START': return new google.maps.Marker({
                            position,
                            icon: {
                                url: "/Img/Map/home.png"
                            },
                            zIndex: 3,
                            visible: Boolean(step.destination || step.places_id.length > 5),
                            map
                        });
                        case 'STEP': {
                            const marker = new google.maps.Marker({
                                position,
                                label: {
                                    text: trip?.has_trip_starting_point ?
                                        index.toString() :
                                        (index + 1).toString(),
                                    color: "#76B6C2",
                                    fontWeight: "bold"
                                },
                                icon: {
                                    url: "/Img/Map/pin.png",
                                    labelOrigin: new google.maps.Point(15, 15)
                                },
                                clickable: true,
                                zIndex: 3,
                                map
                            });
                            marker.addListener('click', () => {
                                if (stepsInfoWindowBag) {
                                    const allInfoWindows = Object.values(stepsInfoWindowBag.getItems()).map(({ item }) => {
                                        return item;
                                    });
                                    for (const item of allInfoWindows) {
                                        item.hide();
                                    }
                                    const infoWindow = stepsInfoWindowBag.getMarker(step);
                                    infoWindow?.show();
                                    map.panTo(position);
                                }
                            });
                            return marker;
                        }
                        default: return null;
                    }
                },
                (step) => step.destination?.id.toString() ?? step.id.toString(),
                (marker) => {
                    marker.setMap(null);
                    marker.setPosition(null);
                }
            );
        }
        return null;
    }, [map]);
    const stepsInfoWindowBag = useMemo(() => {
        if (map) {
            return new ItineraryMarkerBag<ItineraryInput, InstanceType<NonNullable<typeof IwClass>>>(
                async (step) => {
                    if (IwClass) {
                        const manager = StepsDirectionsManager.getInstance();
                        const position = step.destination ?
                            new google.maps.LatLng({
                                lat: step.destination.latitude,
                                lng: step.destination.longitude
                            }) :
                            await manager.transformPlaceIdToCoordinates(step.places_id);
                        return new IwClass({
                            position,
                            info: {
                                id: step.destination?.id ?? 0,
                                name: step.destination ?
                                    getLightDestinationName(locale, step.destination) :
                                    '',
                                picture: getDestinationPicture(step.destination),
                                locked: false,
                                tripStartDate: new Date().toISOString(),
                                tripEndDate: new Date().toISOString(),
                                steps: []
                            },
                            pinHeight: 35,
                            map,
                            t
                        });
                    }
                    return null;
                },
                (step) => step.destination?.id.toString() ?? step.id.toString(),
                (marker) => marker.setMap(null)
            );
        }
        return null;
    }, [map]);
    const destinationsMarkerBag = useMemo(() => {
        if (map && DestinationMarkerClass) {
            return new ItineraryMarkerBag<LightDestination, google.maps.Marker>(
                async (destination) => {
                    const name = getLightDestinationName(locale, destination);

                    const x = 4;
                    const y = 4;
                    const radius = 5;
                    const width = 110;
                    const height = 60;

                    const blob = await createDestinationMarkerImage(
                        getDestinationPicture(destination),
                        name,
                        { x, y, radius, width, height }
                    );

                    if (blob) {
                        const destinationMarker = new google.maps.Marker({
                            position: {
                                lat: destination.latitude,
                                lng: destination.longitude
                            },
                            title: name,
                            shape: {
                                coords: [0, 0, width + 8, 0, width + 8, height + 4, 0, height + 4],
                                type: 'poly'
                            },
                            clickable: true,
                            icon: {
                                url: URL.createObjectURL(blob),
                                size: new google.maps.Size(width + 8, height + 16),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point((width + 8) / 2, height + 16)
                            },
                            map
                        });
                        destinationMarker.setZIndex(1);
                        destinationMarker.addListener('mouseover', () => {
                            destinationMarker.setZIndex(2);
                        });
                        destinationMarker.addListener('mouseout', () => {
                            destinationMarker.setZIndex(1);
                        });
                        return destinationMarker;
                    }
                    return null;
                },
                (destination) => destination.id.toString(),
                (marker) => marker.setMap(null)
            );
        }
    }, [map]);
    const destinationsInfoWindowBag = useMemo(() => {
        if (map) {
            return new ItineraryMarkerBag<LightDestination, InstanceType<NonNullable<typeof IwClass>>>(
                async (destination) => {
                    if (IwClass) {
                        return new IwClass({
                            position: new google.maps.LatLng({ lat: destination.latitude, lng: destination.longitude }),
                            info: {
                                id: destination.id,
                                name: getLightDestinationName(locale, destination),
                                picture: getDestinationPicture(destination),
                                locked: false,
                                tripStartDate: new Date().toISOString(),
                                tripEndDate: new Date().toISOString(),
                                steps: []
                            },
                            pinHeight: 71.55,
                            map,
                            t
                        });
                    }
                    return null;
                },
                (destination) => destination.id.toString(),
                (marker) => marker.setMap(null)
            );
        }
        return null;
    }, [map]);
    const addStep = useItineraryStepAdd({});
    const seeStep = useItineraryStepSee();

    const onReplaceStepMarkers = useCallback(
        debounce(
            async (
                options: {
                    map: typeof map,
                    stepsMarkerBag: typeof stepsMarkerBag,
                    steps: typeof steps
                }
            ) => {
                if (options.map && options.stepsMarkerBag) {
                    await options.stepsMarkerBag.replaceOwners(options.steps);
                    const hasReturnStep = options.steps.findIndex((item) => item.step_type === 'END') >= 0;
                    const manager = StepsDirectionsManager.getInstance();
                    for (let i = 0; i < options.steps.length; i++) {
                        const step = options.steps[i]!;

                        const marker = options.stepsMarkerBag.getMarker(step);
                        if (options.stepsMarkerBag.getReferences(step) > 1) {
                            marker?.setIcon({
                                url: "/Img/Map/repeat.png"
                            });
                            marker?.setLabel(null);
                        } else {
                            switch (step.step_type) {
                                case 'START': {
                                    if (step.destination || step.places_id.length > 5) {
                                        marker?.setVisible(true);
                                    }
                                    marker?.setIcon({
                                        url: "/Img/Map/home.png"
                                    });
                                    marker?.setLabel(null);
                                    const position = step.destination ?
                                        {
                                            lat: step.destination.latitude,
                                            lng: step.destination.longitude
                                        } as google.maps.LatLngLiteral :
                                        await manager.transformPlaceIdToCoordinates(step.places_id);
                                    marker?.setPosition(position);
                                    break;
                                }
                                case 'STEP': {
                                    marker?.setVisible(true);
                                    marker?.setIcon({
                                        url: "/Img/Map/pin.png",
                                        labelOrigin: new google.maps.Point(15, 15)
                                    });
                                    //@see https://github.com/googlemaps/v3-utility-library/issues/375
                                    marker?.setZIndex(3 + i);
                                    marker?.setLabel({
                                        text: hasReturnStep ?
                                            i.toString() :
                                            (i + 1).toString(),
                                        color: "#76B6C2",
                                        fontWeight: "bold"
                                    });
                                    break;
                                }
                                case 'END': {
                                    marker?.setVisible(false);
                                    break;
                                }
                            }
                        }
                    }
                }
            },
            500
        ),
        []
    );

    const onReplaceStepInfoWindows = useCallback(
        debounce(
            async (
                options: {
                    map: typeof map,
                    stepsInfoWindowBag: typeof stepsInfoWindowBag,
                    steps: typeof steps,
                    lockBoxes: LockBox[],
                    tripStartDate: typeof tripStartDate,
                    tripEndDate: typeof tripEndDate
                }
            ) => {
                if (options.map && options.stepsInfoWindowBag) {
                    await options.stepsInfoWindowBag.replaceOwners(options.steps);

                    const groupped: ItineraryInput[][] = [];
                    const alreadyGroupped: number[] = [];

                    for (let i = 0; i < options.steps.length; i++) {
                        const step = options.steps[i]!;
                        if (!alreadyGroupped.includes(step.destination?.id ?? -1)) {
                            const sameSteps = options.steps.slice(i + 1).filter((item) => {
                                return item.destination?.id === step.destination?.id;
                            });
                            groupped.push([step, ...sameSteps]);
                            alreadyGroupped.push(step.destination?.id ?? -1);
                        }
                    }

                    const blockGroups = transformStepInputsToGroups(options.steps);

                    for (let i = 0; i < groupped.length; i++) {
                        const firstStep = groupped[i]![0]!;
                        const infoWindow = options.stepsInfoWindowBag.getMarker(firstStep);

                        //register event handlers
                        infoWindow?.setFeatureListener('close', () => infoWindow.hide());
                        infoWindow?.setFeatureListener(
                            'addAsStep',
                            () => {
                                if (firstStep.destination) {
                                    onAddStepInput(firstStep.destination);
                                }
                                infoWindow.hide();
                            }
                        );
                        infoWindow?.setFeatureListener('seeInfo', () => firstStep.destination && onSeeDestination(firstStep.destination));
                        infoWindow?.setFeatureListener(
                            'removeStep',
                            (id) => {
                                dispatch(deleteStepInput({ id }));
                            }
                        );
                        infoWindow?.setFeatureListener(
                            'changeStepNightsCount',
                            (id, count) => {
                                if (options.tripStartDate && options.tripEndDate) {
                                    dispatch(
                                        changeStepDaysCount({
                                            stepId: id,
                                            daysCount: count,
                                            tripStartDate: options.tripStartDate,
                                            tripEndDate: options.tripEndDate
                                        })
                                    );
                                }
                            }
                        );

                        //replaces steps
                        infoWindow?.replaceSteps(groupped[i]!);

                        const blockGroupIndex = blockGroups.findIndex((group) => {
                            return group.findIndex((step) => {
                                return step.id === firstStep.id;
                            }) >= 0;
                        });
                        const lockBox = options.lockBoxes.find((item) => {
                            return item.group === blockGroupIndex;
                        });

                        //replace info
                        infoWindow?.replaceInfo({
                            id: firstStep.destination?.id ?? 0,
                            name: firstStep.destination ?
                                getLightDestinationName(locale, firstStep.destination) :
                                '',
                            picture: getDestinationPicture(firstStep.destination),
                            locked: !!lockBox?.locked,
                            tripStartDate: options.tripStartDate ?? new Date().toISOString(),
                            tripEndDate: options.tripEndDate ?? new Date().toISOString(),
                            steps: options.steps
                        });
                    }
                }
            },
            500
        ),
        []
    );

    const onReplaceDestinations = useCallback(
        debounce(
            async (
                options: {
                    destinations: LightDestination[],
                    destinationsMarkerBag: typeof destinationsMarkerBag,
                    destinationsInfoWindowBag: typeof destinationsInfoWindowBag,
                    isUserTO: boolean,
                    onAddStepInput: typeof onAddStepInput,
                    onSeeDestination: typeof onSeeDestination,
                    map: typeof map
                }
            ) => {
                if (options.destinationsMarkerBag && options.destinationsInfoWindowBag) {
                    await options.destinationsInfoWindowBag.replaceOwners(options.destinations);
                    await options.destinationsMarkerBag.replaceOwners(options.destinations);
                    for (const destination of options.destinations) {
                        const infoWindow = options.destinationsInfoWindowBag.getMarker(destination);
                        infoWindow?.setFeatureListener(
                            'close',
                            () => {
                                infoWindow?.hide();
                                marker?.setVisible(true);
                            }
                        );
                        infoWindow?.setFeatureListener(
                            'addAsStep',
                            () => {
                                options.onAddStepInput(destination);
                                infoWindow?.hide();
                            }
                        );
                        infoWindow?.setFeatureListener('seeInfo', () => options.onSeeDestination(destination));

                        const marker = options.destinationsMarkerBag.getMarker(destination);
                        if (marker) {
                            google.maps.event.clearListeners(marker, 'click');
                        }
                        marker?.addListener(
                            'click',
                            async () => {
                                const children = await findDestinationChildren(destination.id, options.isUserTO);

                                //if there is only one child then just center on it
                                if (children.length === 1) {
                                    const child = children[0];
                                    const data = child?.id ? await getQuickDestination(child.id) : null;
                                    if (data && child) {
                                        dispatch(
                                            setDestinations({
                                                state: 'success',
                                                data: filterDestinationsBasedOnZoom(children, options.map?.getZoom() ?? 8)
                                            })
                                        );
                                        options.map?.set('noNotify', true);
                                        options.map?.setCenter({ lat: child.latitude, lng: child.longitude });
                                        options.map?.setZoom(data.zoom_level);
                                    }
                                    //else if there are multiple children, create a bounds and make map fit it
                                } else if (children.length > 0) {
                                    const bounds = new google.maps.LatLngBounds();
                                    children.forEach((item) => {
                                        bounds.extend({ lat: item.latitude, lng: item.longitude });
                                    });
                                    dispatch(
                                        setDestinations({
                                            state: 'success',
                                            data: filterDestinationsBasedOnZoom(children, options.map?.getZoom() ?? 8)
                                        })
                                    );
                                    options.map?.set('noNotify', true);
                                    options.map?.fitBounds(bounds);
                                    //else center on the marker and show info window
                                } else {
                                    const allOtherInfoWindows = Object.values(options.destinationsInfoWindowBag?.getItems() ?? {}).map(({ item }) => {
                                        return item;
                                    });
                                    for (const item of allOtherInfoWindows) {
                                        item.hide();
                                    }

                                    marker.setVisible(false);
                                    infoWindow?.show();
                                    const data = await getQuickDestination(destination.id);
                                    const position = new google.maps.LatLng({
                                        lat: destination.latitude,
                                        lng: destination.longitude
                                    });
                                    if (position && data) {
                                        options.map?.set('noNotify', true);
                                        options.map?.setCenter(position);
                                    }
                                }
                            }
                        );
                    }
                }
            },
            500
        ),
        []
    );

    const onAddStepInput = (destination: LightDestination) => {
        addStep(destination);
    };

    const onSeeDestination = async (destination: LightDestination) => {
        seeStep(destination);
    };

    const onRecenter = async () => {
        if (map && window.google) {
            const manager = StepsDirectionsManager.getInstance();
            const bounds = new google.maps.LatLngBounds();
            for (const step of steps.filter((item) => item.step_type === 'STEP')) {
                bounds.extend(
                    await manager.transformStepToCoordinates(step)
                );
            }
            map.fitBounds(bounds);
        }
    };

    const onCenterOnPlace = (place: google.maps.places.PlaceResult | null) => {
        if (map && place?.geometry?.viewport) {
            map?.fitBounds(place.geometry?.viewport);
        }
    };

    useEffect(() => {
        return () => {
            dispatch(setMap(null));
        };
    }, []);

    //initialize map
    useEffect(() => {
        if (ref.current && map === null) {
            const config = JSON.parse(localStorage.getItem('config') ?? '{}') as { keys?: Key[] };
            const key = config.keys?.find((item) => item.identifier === 'google_api');

            if (key) {
                GoogleMapsLoader.KEY = key.value;
                GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];
                GoogleMapsLoader.LANGUAGE = "fr";
                GoogleMapsLoader.VERSION = '3.50';
                GoogleMapsLoader.load((google) => {
                    dispatch(
                        setMap(
                            new google.maps.Map(
                                ref.current!,
                                {
                                    center: destination?.data ?
                                        { lat: parseFloat(destination.data.latitude), lng: parseFloat(destination.data.longitude) } :
                                        { lat: -34.397, lng: 150.644 },
                                    zoom: 4,
                                    zoomControl: true,
                                    scaleControl: true,
                                    disableDefaultUI: true
                                }
                            )
                        )
                    );
                });
            }
        }
    }, [ref.current, map]);

    //make destinations list request
    useEffect(() => {
        destinationsCancelToken?.cancel('Request cancelled.');
        destinationsCancelToken = axios.CancelToken.source();
        onUpdateDestinations({
            isUserTO,
            bounds,
            map,
            zoom,
            cancelToken: destinationsCancelToken
        });
    }, [map, bounds, zoom, isUserTO]);

    useEffect(() => {
        blocksCancelToken?.cancel('Request cancelled.');
        blocksCancelToken = axios.CancelToken.source();
        if (destinationsTab === 1) {
            (async () => {
                const circuitsCache = (store.getState() as AppState).itinerarySlice.blocks.circuits.cache;
                const typicalTripsCache = (store.getState() as AppState).itinerarySlice.blocks.typicalTrips.cache;
                if (destinations.data[0]?.id && parentDestination) {
                    fireBlocksNetworkRequests(
                        parentDestination.id,
                        {
                            search: blocksSearch,
                            isUserTO,
                            cancelToken: blocksCancelToken,
                            excludeCircuits: circuitsCache.map((item) => {
                                return item.id;
                            }),
                            excludeTypicalTrips: typicalTripsCache.map((item) => {
                                return item.id;
                            })
                        }
                    );
                } else {
                    fireBlocksNetworkRequests(
                        null,
                        {
                            search: blocksSearch,
                            isUserTO,
                            cancelToken: blocksCancelToken,
                            excludeCircuits: circuitsCache.map((item) => {
                                return item.id;
                            }),
                            excludeTypicalTrips: typicalTripsCache.map((item) => {
                                return item.id;
                            })
                        }
                    );
                }
            })();
        }
    }, [
        isUserTO,
        parentDestination,
        blocksSearch,
        destinations,
        destinationsTab
    ]);

    //register event listeners
    useEffect(() => {
        if (map) {
            dispatch(setCurrentBounds(map.getBounds() ?? null));
            const listeners = [
                map.addListener('bounds_changed', () => {
                    dispatch(setCurrentBounds(map.getBounds() ?? null));
                }),
                map.addListener('zoom_changed', () => {
                    const zoom = map.getZoom();
                    if (zoom) {
                        dispatch(setCurrentZoom(zoom));
                    }
                })
            ];
            return () => {
                listeners.forEach((listener) => window.google.maps.event.removeListener(listener));
            };
        }
    }, [map]);

    //render destination markers
    useEffect(() => {
        onReplaceDestinations({
            destinations: destinations.data,
            destinationsInfoWindowBag,
            destinationsMarkerBag,
            map,
            isUserTO,
            onAddStepInput,
            onSeeDestination
        });
    }, [
        map,
        destinationsInfoWindowBag,
        destinationsMarkerBag,
        destinations,
        isUserTO
    ]);

    //render steps markers
    useEffect(() => {
        onReplaceStepMarkers({
            map,
            steps,
            stepsMarkerBag
        });
    }, [map, steps, stepsMarkerBag]);

    useEffect(() => {
        onReplaceStepInfoWindows({
            map,
            steps,
            stepsInfoWindowBag,
            lockBoxes,
            tripStartDate,
            tripEndDate
        });
    }, [
        map,
        steps,
        stepsInfoWindowBag,
        lockBoxes,
        tripStartDate,
        tripEndDate
    ]);

    //render routes between steps
    useEffect(() => {
        if (map) {
            for (const polyline of renderedPolylines.current) {
                polyline.setMap(null);
            }

            const round_plane = {
                path: "M135.764,213.765c-100.494,100.493-100.493,263.976,0,364.472c100.495,100.494,263.975,100.494,364.471-0.002" +
                    "c100.494-100.492,100.494-263.977,0-364.47C399.741,113.271,236.259,113.27,135.764,213.765z",
                anchor: new google.maps.Point(320, 500),
                scale: 0.05,
                fillOpacity: 1
            };
            const round_other = {
                path: "M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0" +
                    "c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267" +
                    "c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407" +
                    "s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062" +
                    "C438.533,179.485,428.732,142.795,409.133,109.203z M361.74,259.517l-29.123,29.129c-3.621,3.614-7.901,5.424-12.847,5.424" +
                    "c-4.948,0-9.236-1.81-12.847-5.424l-87.654-87.653l-87.646,87.653c-3.616,3.614-7.898,5.424-12.847,5.424" +
                    "c-4.95,0-9.233-1.81-12.85-5.424l-29.12-29.129c-3.617-3.607-5.426-7.898-5.426-12.847c0-4.942,1.809-9.227,5.426-12.848" +
                    "l129.62-129.616c3.617-3.617,7.898-5.424,12.847-5.424s9.238,1.807,12.846,5.424L361.74,233.822" +
                    "c3.613,3.621,5.424,7.905,5.424,12.848C367.164,251.618,365.357,255.909,361.74,259.517z",
                anchor: new google.maps.Point(185, 500),
                scale: 0.045,
                fillOpacity: 1
            };
            const arrow = {
                path: "M361.74,259.517l-29.123,29.129c-3.621,3.613-7.901,5.424-12.848,5.424" +
                    "c-4.947,0-9.235-1.811-12.847-5.424l-87.654-87.653l-87.646,87.653c-3.616,3.613-7.897,5.424-12.847,5.424" +
                    "c-4.95,0-9.233-1.811-12.85-5.424l-29.121-29.129c-3.617-3.607-5.426-7.898-5.426-12.848c0-4.941,1.809-9.227,5.426-12.848" +
                    "l129.621-129.616c3.616-3.617,7.897-5.424,12.846-5.424c4.949,0,9.238,1.807,12.847,5.424L361.74,233.821" +
                    "c3.612,3.621,5.424,7.906,5.424,12.848C367.164,251.618,365.357,255.909,361.74,259.517z",
                anchor: new google.maps.Point(185, 500),
                scale: 0.045,
                fillOpacity: 1,
                fillColor: "#FFF"
            };
            const plane = {
                path: "M359.775,232.343c-0.178-19.052-5.917-36.275-15.649-45.531c-5.243-4.986-11.759-8.183-18.345-8.158" +
                    "c-6.588,0.097-13.043,3.415-18.192,8.498c-9.56,9.436-14.976,26.764-14.8,45.815c-1.283,29.64-0.579,60.689,0.851,91.344" +
                    "l-142.57,96.974c-1.672,1.138-2.665,3.035-2.646,5.058l0.212,22.814c0.038,4.08,4.022,6.951,7.905,5.699l137.835-51.379" +
                    "l9.817,108.527c0.223,2.482-0.968,4.878-3.082,6.198l-29.623,18.485c-3.242,2.023-5.198,5.586-5.162,9.409l0.056,6.012" +
                    "c0.022,2.447,2.025,4.412,4.472,4.389l116.872-1.086c2.447-0.022,4.412-2.025,4.39-4.474l-0.056-6.011" +
                    "c-0.036-3.823-2.057-7.35-5.338-9.314l-29.959-17.929c-2.139-1.279-3.376-3.654-3.197-6.139l7.795-108.691l138.769,48.807" +
                    "c3.904,1.179,7.835-1.767,7.797-5.846L507.713,423c-0.02-2.022-1.047-3.901-2.74-5.006l-144.348-94.307" +
                    "C361.482,293.011,361.609,261.954,359.775,232.343z",
                scale: 0.04,
                strokeWeight: 1,
                fillColor: "#fff",
                fillOpacity: 1,
                strokeColor: "#fff",
                anchor: new google.maps.Point(320, 500)
            };

            const polylines = [];
            for (const step of steps) {
                const segments = step.r2r_json?.segments ?? [];
                for (let i = 0; i < segments.length; i++) {
                    const segment = segments[i]!;

                    const isPlane = ['plane', 'air-taxi'].includes(
                        step.r2r_json?.data?.vehicles[segment.vehicle]?.kind ?? ''
                    );

                    let path = segment.path ? google.maps.geometry.encoding.decodePath(segment.path) : [];
                    if (isPlane && path.length === 0) {
                        path = [
                            new google.maps.LatLng(step.r2r_json?.data?.places[segment.depPlace]?.lat ?? 0, step.r2r_json?.data?.places[segment.depPlace]?.lng),
                            new google.maps.LatLng(step.r2r_json?.data?.places[segment.arrPlace]?.lat ?? 0, step.r2r_json?.data?.places[segment.arrPlace]?.lng)
                        ];
                    }

                    const polyline = new google.maps.Polyline({
                        path,
                        strokeColor: getTransportColor(step.r2r_json?.data?.vehicles[segment.vehicle]?.kind),
                        strokeOpacity: 1.0,
                        strokeWeight: 6,
                        icons: [
                            {
                                icon: (isPlane ? round_plane : round_other),
                                offset: '60%'
                            },
                            {
                                icon: (isPlane ? plane : arrow),
                                offset: '60%'
                            }
                        ],
                        geodesic: isPlane
                    });
                    polyline.setMap(map);
                    polylines.push(polyline);
                }
            }
            renderedPolylines.current = polylines;
        }
    }, [map, steps]);

    return (
        <Box sx={{ height: '100%', position: 'relative' }}>
            <div ref={ref} style={{ height: '100%' }} />
            <ItineraryMapStatus />
            <Box
                sx={{
                    position: 'absolute',
                    top: 15,
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}
            >
                <ItineraryMuiGooglePlaceAutocomplete
                    placeholder={t('itinerary.find-a-place')}
                    noLocationsLabel={t('itinerary.no-results')}
                    sx={{
                        width: 300,
                        backgroundColor: '#fff',
                        borderRadius: 20,
                        ['.MuiInputBase-root']: {
                            borderRadius: 20
                        }
                    }}
                    onChange={onCenterOnPlace}
                />
            </Box>
            <Button
                variant="contained"
                startIcon={<Navigation />}
                sx={{
                    position: 'absolute',
                    bottom: 25,
                    left: 20,
                    borderRadius: 20
                }}
                onClick={onRecenter}
            >
                {t('itinerary.recenter-on-itinerary')}
            </Button>
        </Box>
    );
}

let destinationsCancelToken: CancelTokenSource | null = null;
let blocksCancelToken: CancelTokenSource | null = null;

type DestinationsRequestOptions = {
    isUserTO: boolean,
    bounds: google.maps.LatLngBounds,
    cancelToken: CancelTokenSource
}

function makeDestinationsRequest(options: DestinationsRequestOptions): Promise<AxiosResponse<LightDestination[]>> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        return axios.get(
            `${API_HREF}client/${window.id_owner}/destinations/quick_search/`,
            {
                headers,
                cancelToken: options.cancelToken.token,
                params: {
                    limit: 500,
                    current_version__type__in: '0,1,2,3,4',
                    ordering: 'current_version__type',
                    latitude_range: `${options.bounds.getNorthEast().lat()},${options.bounds.getSouthWest().lat()}`,
                    longitude_range: `${options.bounds.getNorthEast().lng()},${options.bounds.getSouthWest().lng()}`,
                    visibility__in: options.isUserTO ?
                        'PUBLIC,PRIVATE_TO' :
                        'PUBLIC'
                }
            }
        );
    }

    throw new Error('Please login.');
}

type BlocksRequestsOptions = {
    isUserTO: boolean,
    parentDestination?: number,
    search: string,
    cancelToken: CancelTokenSource,
    excludeCircuits?: number[],
    excludeTypicalTrips?: number[]
}

async function makePackageBlocksRequests(options: BlocksRequestsOptions): Promise<Block[]> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const destinations = isNumber(options.parentDestination) ?
            await getDestinationInheritance(options.parentDestination) :
            [];

        try {
            const response = await axios.post<Block[]>(
                `${API_HREF}client/${window.id_owner}/circuits/by_destination/`,
                {
                    destination_list: isNumber(options.parentDestination) ? destinations : undefined,
                    exclude_circuit_ids: options.excludeCircuits
                },
                {
                    headers,
                    cancelToken: options.cancelToken.token,
                    params: {
                        visibility__in: options.isUserTO ?
                            'PUBLIC,PRIVATE_TO' :
                            'PUBLIC',
                        search: options.search.trim().length > 0 ? options.search : undefined,
                        detailed: true
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    return [];
}

async function makeTypicalTripBlocksRequests(options: BlocksRequestsOptions): Promise<TripBlock[]> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const destinations = isNumber(options.parentDestination) ?
            await getDestinationInheritance(options.parentDestination) :
            [];

        try {
            const response = await axios.post<TripBlock[]>(
                `${API_HREF}client/${window.id_owner}/trip/by_destination/`,
                {
                    destination_list: isNumber(options.parentDestination) ? destinations : undefined,
                    exclude_trip_ids: options.excludeTypicalTrips
                },
                {
                    headers,
                    cancelToken: options.cancelToken.token,
                    params: {
                        typical: 1,
                        visibility__in: options.isUserTO ?
                            'PUBLIC,PRIVATE_TO' :
                            'PUBLIC',
                        search: options.search.trim().length > 0 ? options.search : undefined,
                        detailed: true
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    return [];
}
