import { isProductPackaged } from "../../../CartMaterial/utils/isProductPackaged";

const priceToDisplay = (price, currency_list) => {
    return {
        cost: parseFloat(price.selling_price),
        currency: currency_list.find(currency => currency.id === price.selling_currency)
    };
};

const getPrice = (prices, currency_list, user, currency) => {
    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;
    if (user.client_full.type === 1 || user.client_full.type === 3 || quotation_code === 'verdie') {
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].is_tva) {
                return priceToDisplay(prices[i], currency_list);
            }
        }
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].is_surcom) {
                return priceToDisplay(prices[i], currency_list);
            }
        }
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].master_price) {
                return priceToDisplay(prices[i], currency_list);
            }
        }
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].owner === user.client) {
                return priceToDisplay(prices[i], currency_list);
            }
        }
    } else {
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].is_tva) {
                return priceToDisplay(prices[i]);
            }
        }
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].owner === user.client) {
                return priceToDisplay(prices[i], currency_list);
            }
        }
    }
    return {
        cost: 0,
        currency: currency
    };
};

export default (flights, cars, accommodations, pois, transfers, manual_products, trip, currency_list, user, currency, assistance_cart) => {
    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;
    let total_cost = [];
    let total_manual_products = [];
    let total_accommodations = [];
    let total_assistances = [];
    let total_cars = [];
    let total_pois = [];
    let total_transfers = [];
    let total_flights = [];
    let custom_cost = false;
    let flight_taxes = 0;
    if (trip.prices_flight.length > 0) {
        custom_cost = true;
        let price = getPrice(trip.prices_flight, currency_list, user, currency);
        let matched = false;
        if (trip.flight_taxes !== null) {
            flight_taxes = parseFloat(trip.flight_taxes);
        }
        total_cost.map(currency_cost => {
            if (currency_cost.currency.id === price.currency.id) {
                matched = true;
                currency_cost.cost += price.cost + (trip.flight_taxes !== null ? parseFloat(trip.flight_taxes) : 0);
            }
        });
        if (!matched) {
            total_cost.push({
                cost: price.cost + (trip.flight_taxes !== null ? parseFloat(trip.flight_taxes) : 0),
                currency: price.currency
            });
        }
    }
    if (trip.prices_terrestrial.length > 0) {
        custom_cost = true;
        let price = getPrice(trip.prices_terrestrial, currency_list, user, currency);
        let matched = false;
        total_cost.map(currency_cost => {
            if (currency_cost.currency.id === price.currency.id) {
                matched = true;
                currency_cost.cost += price.cost;
            }
        });
        if (!matched) {
            total_cost.push({
                cost: price.cost,
                currency: price.currency
            });
        }
    }
    accommodations.map(accommodation => {
        accommodation.rooms.map(room => {
            if ((room.is_available || (room.booking_status !== null && room.booking_status.status_booking === 'CONFIRMED')) && room.is_displayed && !room.deletion_restriction && !room.is_optional && !room.is_hidden_for_traveler && (room.booking_status === null || (room.booking_process_state !== 'CANCEL' && room.booking_status.status_booking !== 'CANCELLED'))) {
                if (!isProductPackaged({ product: room, stackInfos: trip?.stack_info ?? null }) && !custom_cost) {
                    let price = getPrice(room.prices, currency_list, user, currency);
                    let matched = false;
                    total_cost.map(currency_cost => {
                        if (currency_cost.currency.id === price.currency.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                    matched = false;
                    total_accommodations.map(currency_cost => {
                        if (currency_cost.currency.id === price.currency.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_accommodations.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                }
            }
        });
    });
    cars.map(car => {
        if ((car.is_available || (car.booking_status !== null && car.booking_status.status_booking === 'CONFIRMED')) && car.is_displayed && !car.is_optional && !car.is_hidden_for_traveler && !car.deletion_restriction && (car.booking_status === null || (car.booking_process_state !== 'CANCEL' && car.booking_status.status_booking !== 'CANCELLED'))) {
            if (!isProductPackaged({ product: car, stackInfos: trip?.stack_info ?? null }) && !custom_cost) {
                let price = getPrice(car.prices, currency_list, user, currency);
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency.id === price.currency.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost,
                        currency: price.currency
                    });
                }
                matched = false;
                total_cars.map(currency_cost => {
                    if (currency_cost.currency.id === price.currency.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                    }
                });
                if (!matched) {
                    total_cars.push({
                        cost: price.cost,
                        currency: price.currency
                    });
                }
            }
        }
    });
    manual_products.map(manual_product => {
        if ((manual_product.is_available || (manual_product.booking_status !== null && manual_product.booking_status.status_booking === 'CONFIRMED')) && (manual_product.is_displayed || quotation_code === 'cercledesvoyages') && !manual_product.is_optional && !manual_product.is_hidden_for_traveler && !manual_product.deletion_restriction && (manual_product.booking_status === null || (manual_product.booking_process_state !== 'CANCEL' && manual_product.booking_status.status_booking !== 'CANCELLED'))) {
            if (!isProductPackaged({ product: manual_product, stackInfos: trip?.stack_info ?? null }) && !custom_cost) {
                if (manual_product.taxes !== null) {
                    flight_taxes += parseFloat(manual_product.taxes);
                }
                let price = getPrice(manual_product.prices, currency_list, user, currency);
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency.id === price.currency.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                        if (manual_product.taxes !== null) {
                            currency_cost.cost += parseFloat(manual_product.taxes);
                        }
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost + (manual_product.taxes !== null ? parseFloat(manual_product.taxes) : 0),
                        currency: price.currency
                    });
                }
                matched = false;
                total_manual_products.map(currency_cost => {
                    if (currency_cost.currency.id === price.currency.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                        if (manual_product.taxes !== null) {
                            currency_cost.cost += parseFloat(manual_product.taxes);
                        }
                    }
                });
                if (!matched) {
                    total_manual_products.push({
                        cost: price.cost + (manual_product.taxes !== null ? parseFloat(manual_product.taxes) : 0),
                        currency: price.currency
                    });
                }
            }
        }
    });
    assistance_cart.map(assistance => {
        if ((assistance.is_available || (assistance.booking_status !== null && assistance.booking_status.status_booking === 'CONFIRMED')) && (assistance.is_displayed || quotation_code === 'cercledesvoyages') && !assistance.is_optional && !assistance.is_hidden_for_traveler && !assistance.deletion_restriction && (assistance.booking_status === null || (assistance.booking_process_state !== 'CANCEL' && assistance.booking_status.status_booking !== 'CANCELLED'))) {
            if (!isProductPackaged({ product: assistance, stackInfos: trip?.stack_info ?? null }) && !custom_cost) {
                let price = getPrice(assistance.prices, currency_list, user, currency);
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency.id === price.currency.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost,
                        currency: price.currency
                    });
                }
                matched = false;
                total_assistances.map(currency_cost => {
                    if (currency_cost.currency.id === price.currency.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                    }
                });
                if (!matched) {
                    total_assistances.push({
                        cost: price.cost,
                        currency: price.currency
                    });
                }
            }
        }
    });
    pois.map(poi => {
        if ((poi.is_available || (poi.booking_status !== null && poi.booking_status.status_booking === 'CONFIRMED')) && poi.is_displayed && !poi.is_optional && !poi.is_hidden_for_traveler && !poi.deletion_restriction && (poi.booking_status === null || (poi.booking_process_state !== 'CANCEL' && poi.booking_status.status_booking !== 'CANCELLED'))) {
            if (!isProductPackaged({ product: poi, stackInfos: trip?.stack_info ?? null }) && !custom_cost) {
                let price = getPrice(poi.prices, currency_list, user, currency);
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency.id === price.currency.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost,
                        currency: price.currency
                    });
                }
                matched = false;
                total_pois.map(currency_cost => {
                    if (currency_cost.currency.id === price.currency.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                    }
                });
                if (!matched) {
                    total_pois.push({
                        cost: price.cost,
                        currency: price.currency
                    });
                }
            }
        }
    });
    transfers.map(transfer => {
        if ((transfer.is_available || (transfer.booking_status !== null && transfer.booking_status.status_booking === 'CONFIRMED')) && transfer.is_displayed && !transfer.is_optional && !transfer.is_hidden_for_traveler && !transfer.deletion_restriction && (transfer.booking_status === null || (transfer.booking_process_state !== 'CANCEL' && transfer.booking_status.status_booking !== 'CANCELLED'))) {
            if (!isProductPackaged({ product: transfer, stackInfos: trip?.stack_info ?? null }) && !custom_cost) {
                let price = getPrice(transfer.prices, currency_list, user, currency);
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency.id === price.currency.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost,
                        currency: price.currency
                    });
                }
                matched = false;
                total_transfers.map(currency_cost => {
                    if (currency_cost.currency.id === price.currency.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                    }
                });
                if (!matched) {
                    total_transfers.push({
                        cost: price.cost,
                        currency: price.currency
                    });
                }
            }
        }
    });
    flights.map(flight => {
        if ((flight.is_available || (flight.booking_status !== null && flight.booking_status.status_booking === 'CONFIRMED')) && flight.is_displayed && !flight.is_optional && !flight.is_hidden_for_traveler && !flight.deletion_restriction && (flight.booking_status === null || (flight.booking_process_state !== 'CANCEL' && flight.booking_status.status_booking !== 'CANCELLED'))) {
            if (!isProductPackaged({ product: flight, stackInfos: trip?.stack_info ?? null }) && !custom_cost) {
                let master_price = flight.prices.find((price) => price.master_price);
                flight_taxes += parseFloat(flight.taxes) * (master_price !== undefined ? parseFloat(master_price.factor_used) : 1);
                let price = getPrice(flight.prices, currency_list, user, currency);
                let matched = false;
                let option_cost = 0;
                let option_on_flight = [];
                flight.outbounds.map(outbound => {
                    let option_on_group = [];
                    outbound.legs.map(leg => {
                        if (leg.paid_options && leg.paid_options.length > 0) {
                            leg.paid_options.map(option => {
                                let optionPrice = 0;
                                if ((option.prices?.length ?? 0) > 0) {
                                    optionPrice = getPrice(option.prices).cost;
                                } else {
                                    optionPrice = parseFloat(option.price);
                                }
                                if (option.option_on_flight) {
                                    if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        option_cost += optionPrice;
                                        option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                } else if (option.option_on_group) {
                                    if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        option_cost += optionPrice;
                                        option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                } else {
                                    option_cost += optionPrice;
                                }
                            });
                        }
                    });
                });
                total_cost.map(currency_cost => {
                    if (currency_cost.currency.id === price.currency.id) {
                        matched = true;
                        currency_cost.cost += price.cost + (parseFloat(flight.taxes) * (master_price !== undefined ? parseFloat(master_price.factor_used) : 1)) + option_cost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost + (parseFloat(flight.taxes) * (master_price !== undefined ? parseFloat(master_price.factor_used) : 1)) + option_cost,
                        currency: price.currency
                    });
                }
                matched = false;
                total_flights.map(currency_cost => {
                    if (currency_cost.currency.id === price.currency.id) {
                        matched = true;
                        currency_cost.cost += price.cost + (parseFloat(flight.taxes) * (master_price !== undefined ? parseFloat(master_price.factor_used) : 1)) + option_cost;
                    }
                });
                if (!matched) {
                    total_flights.push({
                        cost: price.cost + (parseFloat(flight.taxes) * (master_price !== undefined ? parseFloat(master_price.factor_used) : 1)) + option_cost,
                        currency: price.currency
                    });
                }
            }
        }
    });
    if (trip.prices_stack_product !== null && trip.prices_stack_product.length > 0 && !custom_cost) {
        let price = getPrice(trip.prices_stack_product, currency_list, user, currency);
        let matched = false;
        total_cost.map(currency_cost => {
            if (currency_cost.currency.id === price.currency.id) {
                matched = true;
                currency_cost.cost += price.cost;
            }
        });
        if (!matched) {
            total_cost.push({
                cost: price.cost,
                currency: price.currency
            });
        }
    }
    return total_cost;
};
