import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    styled,
    Tooltip,
    Typography
} from "@mui/material";
import {
    Close,
    DirectionsCar,
    DragIndicator,
    HelpOutline,
    HighlightOff,
    Hotel,
    KeyboardArrowDown,
    TransferWithinAStation,
    Visibility
} from "@mui/icons-material";
import { isNumber, startCase } from "lodash";
import { ItineraryBorderedHomeIcon } from "./itineraryBorderedHomeIcon";
import { ItineraryStepItemDragPreview } from "./itineraryStepItemDragPreview";
import { ItineraryGooglePlaceAutocomplete } from "./itineraryGooglePlaceAutocomplete";
import { ItineraryStepItemTimePicker } from "./itineraryStepItemTimePicker";
import { ItineraryStepItemDropTarget } from "./itineraryStepItemDropTarget";
import { ItineraryStepItemChangeTooltip } from "./itineraryStepItemChangeTooltip";
import { ItineraryStepItemCarTooltip } from "./itineraryStepItemCarTooltip";
import { ItineraryStepItemAccommodationTooltip } from "./itineraryStepItemAccommodationTooltip";
import { ItineraryStepItemPoiTooltip } from "./itineraryStepItemPoiTooltip";
import { ItineraryStepItemTransferTooltip } from "./itineraryStepItemTransferTooltip";
import { ItineraryTransportItemInsertStepButton } from "./itineraryTransportItemInsertStepButton";
import { ItineraryTransportItemAddInput } from "./itineraryTransportItemAddInput";
import { ItineraryTransportItemAddInputContainer } from "./itineraryTransportItemAddInputContainer";
import { ItineraryContext } from "./utils/itineraryContext";
import { StepsDatesManager } from "./utils/stepsDatesManager";
import { getDestination } from "./utils/getDestination";
import { useItineraryStepAdd } from "./network/itineraryStepAdd";
import { useItineraryCartProducts } from "./network/itineraryCartProducts";
import {
    changeStepArrivalDate,
    changeStepDaysCount,
    changeStepDepartureDate,
    changeStepDestination,
    deleteStepInput,
    setBothDepartureReturnDestinations,
    setDepartureDestination,
    setReturnDestination
} from "./redux/reducer";
import { ItineraryStepDragObject } from "./objects/itineraryStepDragObject";
import { LightDestination } from "./objects/lightDestination";
import { VehicleKind } from "./objects/itinerary";
import { AppState } from "../../Reducers/Reducers";

export type ItineraryStepItemProps = ({
    type: 'STEP',
    id: number | string,
    index: number,
    title: string,
    transport: VehicleKind | null,
    destination: string,
    destinationId: number,
    group: number | null,
    isInGroup: boolean,
    groupName: string | null,
    locked: boolean,
    first: boolean,
    last: boolean,
    arrival: string,
    departure: string,
    picture: string,
    daysCount: number,
    prependInsertStepField?: boolean,
    editableArrivalHourly?: boolean
} | {
    type: 'START',
    id: number | string,
    index: number,
    title: string,
    destination: string | null,
    departure: string,
    transport: VehicleKind | null,
} | {
    type: 'END',
    id: number | string,
    index: number,
    title: string,
    destination: string | null,
    arrival: string,
})

export const ItineraryStepItem = withRouter<ItineraryStepItemProps>(
    React.memo(
        function ItineraryStepItem(props): JSX.Element | null {
            const { t } = useTranslation();
            const dispatch = useDispatch();
            const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
            const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
            const count = useSelector((state: AppState) => {
                if (props.type === 'STEP') {
                    const stepsInputs = state.itinerarySlice.stepsInputs;
                    const datesManager = new StepsDatesManager(
                        tripStartDate ?? new Date().toISOString(),
                        tripEndDate ?? new Date().toISOString()
                    );
                    let count = props.daysCount +
                        (datesManager.countTripNights(stepsInputs) -
                            datesManager.countTotalNights(stepsInputs)) + 1;
                    return count <= props.daysCount ? props.daysCount + 1 : count;
                }
                return 0;
            });
            const isCalculatingAtIndex = useSelector((state: AppState) => {
                return !!state.itinerarySlice.calculatingTransportAtIndex[-1] &&
                    props.index === 0;
            });
            const products = useItineraryCartProducts({
                arrivalDate: props.type === 'STEP' ?
                    props.arrival :
                    '',
                departureDate: props.type === 'STEP' ?
                    props.departure :
                    ''
            });
            const [dragCollected, dragHandle, previewHandle] = useDrag(() => ({
                type: 'step',
                item: { drag: 'reorder', data: props } as ItineraryStepDragObject,
                collect(monitor) {
                    return {
                        isDragging: monitor.isDragging()
                    };
                }
            }), [props]);
            const context = useContext(ItineraryContext);
            const [openEditDestination, setOpenEditDestination] = useState(false);
            const [sameDestination, setSameDestination] = useState(true);
            const [showAddStepInput, setShowAddStepInput] = useState(false);
            const [loadingAddDestination, setLoadingAddDestination] = useState(false);
            const ref = useRef<HTMLDivElement>(null);
            const addStep = useItineraryStepAdd({ index: -1 });
            const datesManager = useMemo(() => {
                if (tripStartDate && tripEndDate) {
                    return new StepsDatesManager(tripStartDate, tripEndDate);
                }
                return null;
            }, [tripStartDate, tripEndDate]);
            const shouldShowPreview = context.isDragging && props.type === 'STEP';
            let position: string | undefined;

            if (props.type === 'STEP') {
                if (props.first && props.last) {
                    position = 'both';
                } else if (props.first) {
                    position = 'first';
                } else if (props.last) {
                    position = 'last';
                }
            }

            const onDelete = () => {
                if (props.type === 'STEP') {
                    dispatch(deleteStepInput({ id: props.id }));
                }
            };

            const onChangeDestination = (place: google.maps.places.PlaceResult | null) => {
                switch (props.type) {
                    case 'START': {
                        if (sameDestination) {
                            dispatch(setBothDepartureReturnDestinations(place));
                        } else {
                            dispatch(setDepartureDestination(place));
                        }
                        break;
                    }
                    case 'END': dispatch(setReturnDestination(place)); break;
                }
                setOpenEditDestination(false);
            };

            const onChangeDaysCount = (id: number | string, count: number) => {
                if (tripStartDate && tripEndDate) {
                    dispatch(
                        changeStepDaysCount({
                            stepId: id,
                            daysCount: count,
                            tripStartDate,
                            tripEndDate
                        })
                    );
                }
            };

            const onChangeArrivalDate = (id: number | string, date: string) => {
                dispatch(changeStepArrivalDate({ stepId: id, date }));
            };

            const onChangeDepartureDate = (id: number | string, date: string) => {
                dispatch(changeStepDepartureDate({ stepId: id, date }));
            };

            const onChangeStepDestination = (destination: LightDestination) => {
                dispatch(changeStepDestination({ id: props.id, destination }));
            };

            const onViewCartCar = () => {
                props.router.push(`/${window.url_name}/apps/material-cars-search`);
            };

            const onViewCartAccommodation = async () => {
                if (props.type === 'STEP') {
                    const destination = await getDestination(props.destinationId);
                    if (destination) {
                        let lateArrival: boolean | undefined = false;
                        let lateDeparture: boolean | undefined = false;
                        let start_date = props.arrival.split('T')[0];
                        let end_date = props.departure.split('T')[0];
                        lateArrival = datesManager?.isLateArrival(props.arrival);
                        lateDeparture = datesManager?.isLateArrival(props.departure);
                        if (lateArrival) {
                            start_date = (window.moment(props.arrival).subtract(1, 'days')).format("YYYY-MM-DD");
                        }
                        if (lateDeparture) {
                            end_date = (window.moment(props.departure).subtract(1, 'days')).format("YYYY-MM-DD");
                        }
                        dispatch({ type: 'ACCOMMODATION_LATE_ARRIVAL_DEPARTURE', payload: { hasLateArrival: lateArrival, hasLateDeparture: lateDeparture } });
                        dispatch({
                            type: "MATERIAL_SET_ACTIVE_DESTINATION",
                            payload: {
                                active: props.id,
                                bounds: JSON.parse(destination.data?.bounds?.replace(/'/g, '"') ?? '{}'),
                                zoom: destination.data?.zoom_level ?? 8,
                                start_date: start_date,
                                end_date: end_date,
                                destination: { destination },
                                reset: true
                            }
                        });
                        props.router.push(`/${window.url_name}/apps/accommodation`);
                    }
                }
            };

            const onViewCartPoi = async () => {
                if (props.type === 'STEP') {
                    const destination = await getDestination(props.destinationId);
                    if (destination) {
                        dispatch({
                            type: "MATERIAL_SET_ACTIVE_DESTINATION",
                            payload: {
                                active: props.id,
                                bounds: JSON.parse(destination.data?.bounds?.replace(/'/g, '"') ?? '{}'),
                                zoom: destination.data?.zoom_level ?? 8,
                                start_date: props.arrival.split('T')[0],
                                end_date: props.departure.split('T')[0],
                                destination: { destination },
                                reset: true
                            }
                        });
                        props.router.push(`/${window.url_name}/apps/poi`);
                    }
                }
            };

            const onViewTransfer = () => {
                props.router.push(`/${window.url_name}/apps/transfers`);
            };

            const onAddStep = async (destination: LightDestination) => {
                setLoadingAddDestination(true);
                await addStep(destination);
                setShowAddStepInput(false);
                setLoadingAddDestination(false);
            };

            useEffect(() => {
                previewHandle(getEmptyImage(), { captureDraggingState: false });
            }, []);

            if (context.isDragging && props.type === 'STEP' && props.isInGroup && !props.first) {
                return null;
            }

            return (
                <>
                    {
                        context.isDragging &&
                        (props.type === 'STEP' || props.type === 'END') &&
                        <ItineraryStepItemDropTarget
                            index={props.index}
                        />
                    }
                    {
                        (showAddStepInput || isCalculatingAtIndex) &&
                        <ListItem sx={{ paddingLeft: 4, paddingRight: 4 }}>
                            <ItineraryTransportItemAddInputContainer
                                transportColor="#000"
                                sx={(theme) => ({
                                    "width": '100%',
                                    '&:before': {
                                        height: theme.spacing(5.25),
                                        bottom: theme.spacing(-5.75)
                                    }
                                })}
                                disableClose={loadingAddDestination || isCalculatingAtIndex}
                                onClose={() => setShowAddStepInput(false)}
                                reverseTail
                            >
                                <ItineraryTransportItemAddInput
                                    loading={loadingAddDestination || isCalculatingAtIndex}
                                    onChoose={onAddStep}
                                />
                            </ItineraryTransportItemAddInputContainer>
                        </ListItem>
                    }
                    {
                        props.type === 'STEP' &&
                        props.prependInsertStepField &&
                        !context.isDragging &&
                        <ListItem sx={{ justifyContent: 'center' }}>
                            <ItineraryTransportItemInsertStepButton
                                onClick={() => setShowAddStepInput(true)}
                            />
                        </ListItem>
                    }
                    <Container
                        alignItems="flex-start"
                        shouldShowPreview={shouldShowPreview}
                        lockItinerary={props.type === 'STEP' && props.locked}
                        sx={{
                            position: 'relative',
                            opacity: dragCollected.isDragging ?
                                0.5 :
                                1
                        }}
                        data-group={props.type === 'STEP' ? props.group : undefined}
                        data-step-position={position}
                    >
                        <div className="wrapper">
                            {
                                props.type === 'STEP' &&
                                <Tooltip
                                    title={t('itinerary.drag-to-reorder')}
                                    disableHoverListener={context.isDragging}
                                >
                                    <IconButton
                                        ref={dragHandle}
                                        sx={{
                                            padding: 0,
                                            paddingRight: 0.75,
                                            position: 'absolute',
                                            top: '50%',
                                            left: 0,
                                            transform: 'translate(0px, -50%)',
                                            marginLeft: 0.5,
                                            cursor: 'move',
                                            visibility: context.isDragging ? 'hidden' : 'visible'
                                        }}
                                        className="side-button"
                                        disableRipple
                                    >
                                        <DragIndicator />
                                    </IconButton>
                                </Tooltip>
                            }
                            {
                                shouldShowPreview &&
                                <ItineraryStepItemDragPreview
                                    ref={ref}
                                    drag="reorder"
                                    data={{
                                        ...props,
                                        title: props.type === 'STEP' && props.isInGroup && props.first ?
                                            'Bloc' :
                                            props.title,
                                        destination: props.type === 'STEP' && props.isInGroup && props.first && props.groupName ?
                                            props.groupName :
                                            props.destination,
                                        isBloc: props.type === 'STEP' && props.isInGroup && props.first
                                    }}
                                />
                            }
                            {
                                !shouldShowPreview &&
                                <>
                                    <Box sx={{ display: 'flex', width: '100%', paddingRight: 0.5 }}>
                                        {
                                            props.type !== 'STEP' &&
                                            <StepIcon>
                                                <ItineraryBorderedHomeIcon />
                                            </StepIcon>
                                        }
                                        {
                                            props.type === 'STEP' &&
                                            <Picture picture={props.picture} />
                                        }
                                        <ListItemText
                                            primary={props.title}
                                            secondary={
                                                <>
                                                    <Stack direction="row">
                                                        {
                                                            (!openEditDestination || props.type === 'STEP') && props.destination &&
                                                            <ItineraryStepItemChangeTooltip
                                                                open={props.type === 'STEP' && openEditDestination}
                                                                onChoose={onChangeStepDestination}
                                                                onClose={() => setOpenEditDestination(false)}
                                                            >
                                                                <Typography
                                                                    fontSize="0.75rem"
                                                                    lineHeight={1}
                                                                    component="div"
                                                                >
                                                                    <Box sx={{ fontWeight: 'bold' }} component="span">
                                                                        {props.destination.split(',')[0]}
                                                                        {props.destination.split(',').length > 1 ? ',' : null}
                                                                    </Box>
                                                                    {props.destination.split(',').slice(1).join(', ')}
                                                                </Typography>
                                                            </ItineraryStepItemChangeTooltip>
                                                        }
                                                        {
                                                            ['START', 'END'].includes(props.type) &&
                                                            (openEditDestination || !props.destination) &&
                                                            <Stack>
                                                                <div>
                                                                    <ItineraryGooglePlaceAutocomplete
                                                                        onChange={(place) => place && onChangeDestination(place)}
                                                                    />
                                                                    {
                                                                        props.destination &&
                                                                        <IconButton
                                                                            sx={{
                                                                                outline: 0,
                                                                                lineHeight: 1,
                                                                                padding: 0,
                                                                                fontSize: '1rem',
                                                                                alignSelf: 'flex-end',
                                                                                color: '#000',
                                                                                textTransform: 'none',
                                                                                marginLeft: 1.5
                                                                            }}
                                                                            onClick={() => setOpenEditDestination(false)}
                                                                        >
                                                                            <Close fontSize="inherit" />
                                                                        </IconButton>
                                                                    }
                                                                </div>
                                                                {
                                                                    props.type === 'START' &&
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                size="small"
                                                                                checked={sameDestination}
                                                                                onChange={(_, value) => setSameDestination(value)}
                                                                            />
                                                                        }
                                                                        label={
                                                                            <Typography variant="caption" component="div">
                                                                                {t('itinerary.return_same')}
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                }
                                                            </Stack>
                                                        }
                                                        {
                                                            !openEditDestination &&
                                                            <Button
                                                                sx={{
                                                                    outline: 0,
                                                                    lineHeight: 1,
                                                                    padding: 0,
                                                                    fontSize: '0.55rem',
                                                                    alignSelf: 'flex-end',
                                                                    color: '#000',
                                                                    textTransform: 'none'
                                                                }}
                                                                onClick={() => setOpenEditDestination(true)}
                                                                disabled={props.type === 'STEP' && props.locked}
                                                            >
                                                                {t('global.edit')}
                                                            </Button>
                                                        }
                                                    </Stack>
                                                    <Grid container>
                                                        {
                                                            props.type === 'STEP' &&
                                                            <Grid item xs={6}>
                                                                <Stack
                                                                    direction="row"
                                                                    flexWrap="wrap"
                                                                    alignItems="center"
                                                                    spacing={1.5}
                                                                >
                                                                    <ItineraryStepItemCarTooltip cars={products.cars}>
                                                                        <IconButton
                                                                            sx={{
                                                                                fontSize: 18,
                                                                                padding: 0,
                                                                                color: products.cars.length === 0 ? '#ddd' : '#4BC84B'
                                                                            }}
                                                                            onClick={onViewCartCar}
                                                                        >
                                                                            <DirectionsCar
                                                                                fontSize="inherit"
                                                                                color="inherit"
                                                                            />
                                                                        </IconButton>
                                                                    </ItineraryStepItemCarTooltip>
                                                                    <ItineraryStepItemAccommodationTooltip
                                                                        accommodations={products.accommodations}
                                                                    >
                                                                        <IconButton
                                                                            sx={{
                                                                                fontSize: 18,
                                                                                padding: 0,
                                                                                color: products.accommodations.length === 0 ? '#ddd' : '#4BC84B'
                                                                            }}
                                                                            onClick={onViewCartAccommodation}
                                                                        >
                                                                            <Hotel
                                                                                fontSize="inherit"
                                                                                color="inherit"
                                                                            />
                                                                        </IconButton>
                                                                    </ItineraryStepItemAccommodationTooltip>
                                                                    <ItineraryStepItemPoiTooltip pois={products.pois}>
                                                                        <IconButton
                                                                            sx={{
                                                                                fontSize: 18,
                                                                                padding: 0,
                                                                                color: products.pois.length === 0 ? '#ddd' : '#4BC84B'
                                                                            }}
                                                                            onClick={onViewCartPoi}
                                                                        >
                                                                            <Visibility
                                                                                fontSize="inherit"
                                                                                color="inherit"
                                                                            />
                                                                        </IconButton>
                                                                    </ItineraryStepItemPoiTooltip>
                                                                    <ItineraryStepItemTransferTooltip transfers={products.transfers}>
                                                                        <IconButton
                                                                            sx={{
                                                                                fontSize: 18,
                                                                                padding: 0,
                                                                                color: products.transfers.length === 0 ? '#ddd' : '#4BC84B'
                                                                            }}
                                                                            onClick={onViewTransfer}
                                                                        >
                                                                            <TransferWithinAStation
                                                                                fontSize="inherit"
                                                                                color="inherit"
                                                                            />
                                                                        </IconButton>
                                                                    </ItineraryStepItemTransferTooltip>
                                                                </Stack>
                                                            </Grid>
                                                        }
                                                        {
                                                            props.type === 'END' &&
                                                            <Grid item xs={12} sx={{ marginTop: 0.8, marginBottom: 0.8 }}>
                                                                <Divider />
                                                            </Grid>
                                                        }
                                                        {
                                                            (props.type === 'STEP' || props.type === 'END') &&
                                                            <Grid
                                                                item
                                                                display="flex"
                                                                alignItems="flex-end"
                                                                justifyContent="flex-end"
                                                                xs={6}
                                                                sx={{ marginLeft: 'auto' }}
                                                            >
                                                                {
                                                                    props.arrival &&
                                                                    (props.type === 'END' || !props.editableArrivalHourly) &&
                                                                    <Typography
                                                                        fontSize="0.62rem"
                                                                        lineHeight={1}
                                                                        component="div"
                                                                    >
                                                                        <Box sx={{ fontWeight: 'bold' }} component="span">
                                                                            {t('itinerary.arrive-on')}
                                                                        </Box> : {window.moment.utc(props.arrival).format('DD/MM/YYYY | HH:mm')}
                                                                    </Typography>
                                                                }
                                                                {
                                                                    props.arrival &&
                                                                    props.type === 'STEP' &&
                                                                    props.editableArrivalHourly &&
                                                                    <Tooltip
                                                                        title={
                                                                            startCase(
                                                                                window.moment.utc(props.arrival).format('dddd')
                                                                            )
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            fontSize="0.62rem"
                                                                            lineHeight={1}
                                                                            component="div"
                                                                        >
                                                                            <Box sx={{ fontWeight: 'bold' }} component="span">
                                                                                {t('itinerary.arrive-on')}
                                                                            </Box> : {window.moment.utc(props.arrival).format('DD/MM/YYYY')} |
                                                                            {' '}
                                                                            <ItineraryStepItemTimePicker
                                                                                value={props.arrival}
                                                                                onChange={(date) => date && onChangeArrivalDate(props.id, date)}
                                                                                disabled={
                                                                                    (props.type === 'STEP' && props.locked)
                                                                                }
                                                                            />
                                                                        </Typography>
                                                                    </Tooltip>
                                                                }
                                                            </Grid>
                                                        }
                                                        {
                                                            props.type !== 'END' &&
                                                            <Grid item xs={12} sx={{ marginTop: 0.8, marginBottom: 0.8 }}>
                                                                <Divider />
                                                            </Grid>
                                                        }
                                                        {
                                                            props.type === 'STEP' &&
                                                            <Grid item xs={6}>
                                                                {
                                                                    isNumber(props.daysCount) &&
                                                                    <DaysSelect
                                                                        value={props.daysCount ?? ''}
                                                                        size="small"
                                                                        IconComponent={
                                                                            () => (
                                                                                <Box
                                                                                    sx={{
                                                                                        display: 'flex',
                                                                                        fontSize: 15,
                                                                                        marginLeft: -2,
                                                                                        zIndex: -1,
                                                                                        position: 'relative',
                                                                                        left: '-1px'
                                                                                    }}
                                                                                >
                                                                                    <KeyboardArrowDown fontSize="inherit" />
                                                                                </Box>
                                                                            )
                                                                        }
                                                                        onChange={(event) => isNumber(event.target.value) && onChangeDaysCount(props.id, event.target.value)}
                                                                        sx={{ marginLeft: -1.2 }}
                                                                        disabled={props.type === 'STEP' && props.locked}
                                                                    >
                                                                        {
                                                                            (() => {
                                                                                const hasLateArrival = datesManager?.isLateArrival(props.arrival);
                                                                                return new Array(count >= 0 ? count : 0).fill(null).map((_, index) => (
                                                                                    <MenuItem key={index} value={index} disabled={hasLateArrival && index === 0}>
                                                                                        <span>
                                                                                            {index}N {
                                                                                                hasLateArrival && index === 0 ?
                                                                                                    t('itinerary.late-arrival') :
                                                                                                    null
                                                                                            }
                                                                                        </span>
                                                                                    </MenuItem>
                                                                                ));
                                                                            })()
                                                                        }
                                                                    </DaysSelect>
                                                                }
                                                            </Grid>
                                                        }
                                                        {
                                                            (props.type === 'START' || props.type === 'STEP') &&
                                                            <Grid
                                                                item
                                                                display="flex"
                                                                alignItems="flex-end"
                                                                justifyContent="flex-end"
                                                                xs={6}
                                                                sx={{ marginLeft: 'auto' }}
                                                            >
                                                                {
                                                                    props.departure &&
                                                                    <>
                                                                        {
                                                                            datesManager?.isLateDeparture(props.departure) &&
                                                                            props.transport === 'plane' &&
                                                                            <Tooltip title={t('itinerary.late-departure-help')}>
                                                                                <HelpOutline
                                                                                    sx={{
                                                                                        position: 'relative',
                                                                                        top: '3px',
                                                                                        cursor: 'help',
                                                                                        fontSize: '1rem',
                                                                                        marginRight: 0.5
                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                        }
                                                                        <Tooltip
                                                                            title={
                                                                                startCase(
                                                                                    window.moment.utc(props.departure).format('dddd')
                                                                                )
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                fontSize="0.62rem"
                                                                                lineHeight={1}
                                                                                sx={{ whiteSpace: 'nowrap' }}
                                                                                component="div"
                                                                            >
                                                                                <Box sx={{ fontWeight: 'bold' }} component="span">
                                                                                    {t('itinerary.depart-on')}
                                                                                </Box> : {window.moment.utc(props.departure).format('DD/MM/YYYY')} |
                                                                                {' '}
                                                                                <ItineraryStepItemTimePicker
                                                                                    value={props.departure}
                                                                                    onChange={(date) => date && onChangeDepartureDate(props.id, date)}
                                                                                    disabled={(props.type === 'STEP' && props.locked)}
                                                                                />
                                                                            </Typography>
                                                                        </Tooltip>
                                                                    </>
                                                                }
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </>
                                            }
                                            primaryTypographyProps={{
                                                sx: {
                                                    color: '#2C98F0',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                    lineHeight: 1,
                                                    marginBottom: 0.3,
                                                    textTransform: props.type !== 'STEP' ?
                                                        'uppercase' :
                                                        undefined
                                                },
                                                component: 'div'
                                            }}
                                            secondaryTypographyProps={{
                                                sx: {
                                                    fontSize: 9,
                                                    fontWeight: 500,
                                                    color: '#757575'
                                                },
                                                component: 'div'
                                            }}
                                            sx={{
                                                paddingTop: 0.8,
                                                paddingBottom: 0.8,
                                                marginTop: 0,
                                                marginBottom: 0
                                            }}
                                        />
                                    </Box>
                                    {
                                        props.type === 'STEP' &&
                                        <Tooltip title={t('itinerary.delete-step')}>
                                            <IconButton
                                                sx={{
                                                    padding: 0,
                                                    paddingLeft: 0.75,
                                                    position: 'absolute',
                                                    top: '50%',
                                                    right: 0,
                                                    transform: 'translate(0px, -50%)',
                                                    marginRight: 0.5
                                                }}
                                                className="side-button"
                                                onClick={onDelete}
                                                disableRipple
                                            >
                                                <HighlightOff />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </>
                            }
                        </div>
                    </Container>
                </>
            );
        }
    )
) as unknown as (props: ItineraryStepItemProps) => JSX.Element;

const Container = styled(
    ListItem,
    {
        shouldForwardProp(propName) {
            return propName !== 'shouldShowPreview' &&
                propName !== 'lockItinerary';
        }
    }
)<{ shouldShowPreview: boolean, lockItinerary: boolean }>((props) => ({
    "position": 'relative',
    "padding": 0,
    "paddingLeft": props.theme.spacing(4),
    "paddingRight": props.theme.spacing(4),
    '.side-button': {
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 0s, opacity 0.5s linear',
        outline: 0
    },
    '.wrapper': {
        width: '100%',
        flex: 1,
        border: props.shouldShowPreview ?
            'none' :
            '1px solid #ddd',
        borderRadius: 8,
        backgroundColor: '#fff'
    },
    '&:hover .side-button': {
        visibility: props.lockItinerary ? 'hidden' : 'visible',
        opacity: props.lockItinerary ? 0 : 1
    }
}));

const Picture = styled(
    ListItemAvatar,
    {
        shouldForwardProp(propName) {
            return propName !== 'picture';
        }
    }
)<{
    picture: string
}>((props) => ({
    color: '#fff',
    display: 'flex',
    alignSelf: 'stretch',
    backgroundImage: `url(${props.picture})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginTop: 0,
    marginRight: props.theme.spacing(2),
    minWidth: '30%',
    minHeight: 56,
    borderRadius: 8,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
}));

const StepIcon = styled(ListItemAvatar)((props) => ({
    paddingLeft: props.theme.spacing(5),
    minWidth: '30%',
    fontSize: 40,
    marginRight: props.theme.spacing(2)
}));

const DaysSelect = styled(Select)((props) => ({
    '.MuiInputBase-input': {
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: props.theme.spacing(0.5),
        paddingRight: `${props.theme.spacing(2.2)} !important`,
        fontSize: 13,
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center'
    }
}));
