import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
    Box,
    Button,
    Collapse,
    Fab,
    IconButton,
    Stack,
    TextField,
    Typography,
    keyframes
} from "@mui/material";
import {
    Check,
    CheckCircle,
    Close,
    Edit,
    Equalizer,
    ReportProblemOutlined,
    Sync,
    UnfoldLess
} from "@mui/icons-material";
import { green } from "@mui/material/colors";
import { Container, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import axios from "axios";
import { CartConstructionHeaderPriceAdjustmentModal } from "./CartConstructionHeaderPriceAdjustmentModal";
import LightTooltip from "../Menu/MaterialTripList/utils/tooltip/lightTooltip";
import { useCartTotalPrices } from "./utils/cartTotalPrices";
import { useGoToBooking } from "./utils/goToBooking";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import { SetDataTrip } from "../../Actions/Trip";
import { setSeeProductsMargins } from "./redux/cartConstructionReducer";
import { AppState } from "../../Reducers/Reducers";
import { isProductPackaged } from "./utils/isProductPackaged";

type Props = {
    actions?: () => React.ReactNode,
    onChangeStickyHeaderOffset: (offset: number) => void
}

export const CartConstructionHeader = withRouter<Props>(
    function CartConstructionHeader(props): JSX.Element {
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const { enqueueSnackbar } = useSnackbar();
        const hasStackedItems = useSelector((state: AppState) => state.cart.hasStackedItems);
        const language = useSelector((state: AppState) => state.header.tmp_language);
        const user = useSelector((state: AppState) => state.user.user);
        const all_data = useSelector((state: AppState) => state.trip.all_data);
        const currency = useSelector((state: AppState) => state.trip.currency);
        const trip = useSelector((state: AppState) => state.trip.data_trip);
        const seeMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);
        const step = useSelector((state: AppState) => state.cart.step);
        const [refreshing, setRefreshing] = useState(false);
        const [editAdvancePayment, setEditAdvancePayment] = useState(false);
        const [editPrices, setEditPrices] = useState(false);
        const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);
        const [priceAdjustmentModalInputs, setPriceAdustmentModalInputs] = useState<{
            open: boolean,
            type: 'selling-price' | 'margin',
            sellingPrice: number,
            margin: number,
        }>({
            open: false,
            type: 'selling-price',
            sellingPrice: 0,
            margin: 0
        });
        const [newAdvancePayment, setNewAdvancePayment] = useState(0);
        const classes = useStyles();
        const prices = useCartTotalPrices();
        const goToBooking = useGoToBooking(props);
        const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

        const onClosePriceAdjustmentModal = () => {
            onChangePriceAdjustmentModalInput('open', false);
            onChangePriceAdjustmentModalInput('sellingPrice', 0);
            onChangePriceAdjustmentModalInput('margin', 0);
            setEditPrices(false);
        };

        function onChangePriceAdjustmentModalInput<K extends keyof typeof priceAdjustmentModalInputs>(
            key: K,
            value: typeof priceAdjustmentModalInputs[K]
        ): void {
            setPriceAdustmentModalInputs((state) => ({
                ...state,
                [key]: value
            }));
        }

        const onToggleMargins = () => {
            dispatch({ type: 'CART_TOGGLE_MARGIN' });
            if (seeMargins) {
                dispatch(setSeeProductsMargins(false));
            } else {
                dispatch(setSeeProductsMargins(true));
            }
        };

        const onToggleAdvancePayment = () => {
            setEditAdvancePayment((state) => !state);
        };

        const onChangeAdvancePayment = (e: any) => {
            setNewAdvancePayment(e.target.value);
        };

        const onToggleCancelAdvancePayment = () => {
            setNewAdvancePayment(prices.advancePayment);
            setEditAdvancePayment(false);
        };

        const onToggleValidateAdvancePayment = () => {
            const { headers } = CheckBeforeRequest();
            let request = {
                expected_deposit: newAdvancePayment,
                has_manual_expected_deposit: true
            };
            axios({
                method: "PATCH",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/`,
                data: request
            }).then(function (response) {
                onToggleAdvancePayment();
                dispatch(SetDataTrip(response.data));
            }).catch(function (error) {
                console.log("data trip refresh error", error);
            });
        };

        const onRefreshPrice = () => {
            const { headers } = CheckBeforeRequest();
            setRefreshing(true);
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/refresh_prices/?token=${GetCookie('trip_token')}`
            }).then(response => {
                dispatch({ type: 'CART_REFRESH_PRICE', payload: response.data });
            }).catch(error => {
                console.error(error);
                enqueueSnackbar(t<string>('cart-material.refresh-price-not-available'), { variant: 'warning' });
            }).finally(() => {
                setRefreshing(false);
            });
        };

        const onGoToBooking = async () => {
            await goToBooking('selection');
        };

        const onConfirmBooking = async () => {
            await goToBooking('book');
        };

        useEffect(() => {
            setNewAdvancePayment(prices.advancePayment);
        }, [prices]);

        useEffect(() => {
            if (editPrices) {
                onChangePriceAdjustmentModalInput('type', 'selling-price');
                onChangePriceAdjustmentModalInput('sellingPrice', parseFloat(prices.totalCartCost.toFixed(2)));
                onChangePriceAdjustmentModalInput('margin', parseFloat(prices.margin.value.toFixed(2)));
            }
        }, [editPrices, prices.totalCartCost]);

        useEffect(() => {
            if (containerElement) {
                const resizeObserver = new ResizeObserver((entries) => {
                    const entry = entries[0];
                    if (entry) {
                        props.onChangeStickyHeaderOffset(entry.borderBoxSize[0]?.blockSize ?? entry.contentRect.height);
                    }
                });
                resizeObserver.observe(containerElement);
                return () => {
                    resizeObserver.disconnect();
                };
            }
        }, [containerElement, props.onChangeStickyHeaderOffset]);
    
        return (
            <>
                <Stack
                    ref={(element) => {
                        setContainerElement(element);
                    }}
                    sx={{
                        marginTop: '5px',
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                        position: 'sticky',
                        top: 70,
                        backgroundColor: '#fff',
                        zIndex: 999
                    }}
                >
                    <Container maxWidth="lg">
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2.5}
                        >
                            <Stack direction="row" spacing={1}>
                                <LightTooltip title={ t<string>('cart.refresh_price_tooltip') }>
                                    <IconButton onClick={onRefreshPrice}>
                                        <Sync
                                            sx={
                                                refreshing ?
                                                    { animation: `${SpinnerAnimation} 1.2s linear infinite` } :
                                                    undefined
                                            }
                                        />
                                    </IconButton>
                                </LightTooltip>
                                <Typography
                                    variant="h4"
                                    fontWeight="bold"
                                    component="div"
                                >
                                    { t<string>('cart-material.cart') }
                                </Typography>
                            </Stack>
                            <Stack
                                direction="row"
                                sx={{
                                    '.MuiTypography-root': {
                                        "whiteSpace": 'nowrap',
                                        "fontWeight": 500,
                                        '& strong:first-child': {
                                            fontWeight: 900
                                        },
                                        '& span:nth-child(2)': {
                                            fontWeight: 100
                                        }
                                    }
                                }}
                                alignItems="center"
                                spacing={2}
                                flexWrap="wrap"
                            >
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    {
                                        !editPrices &&
                                        <>
                                            <Typography variant="h6">
                                                <Trans
                                                    i18nKey={
                                                        quotationCode !== "cercledesvoyages" ?
                                                            'cart-material.cart-construction-selling-price-with-items' :
                                                            "cart-material.cart-construction-selling-price"
                                                    }
                                                    defaults="Prix de vente : <strong>{{ price }}</strong>"
                                                    values={{
                                                        count: prices.itemsCount,
                                                        currency: currency.symbol,
                                                        price: prices.totalCost.map((item) => {
                                                            return `${item.cost.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${item.currency?.symbol}`;
                                                        }, 0).join(' + ')
                                                    }}
                                                />
                                                {
                                                    ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines'].includes(quotationCode) && all_data &&
                                                    all_data?.agreed_price &&
                                                    (
                                                        <span>
                                                            {` (${parseFloat(all_data?.agreed_price).toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${currency && currency.symbol})`}
                                                        </span>
                                                    )
                                                }
                                            </Typography>
                                        </>
                                    }
                                    {
                                        editPrices &&
                                        <TextField
                                            type="number"
                                            size="small"
                                            label={t<string>('shared.selling-price')}
                                            value={priceAdjustmentModalInputs.sellingPrice}
                                            onChange={(event) => {
                                                onChangePriceAdjustmentModalInput('type', 'selling-price');
                                                onChangePriceAdjustmentModalInput('sellingPrice', parseFloat(parseFloat(event.target.value).toFixed(2)));
                                            }}
                                        />
                                    }
                                </Stack>
                                <LightTooltip title={
                                    !seeMargins ?
                                        t<string>('cart-material.cart-construction-show-margins') :
                                        t<string>('cart-material.cart-construction-hide-margins')
                                }>
                                    <IconButton size="small" color="inherit" onClick={onToggleMargins}>
                                        {
                                            !seeMargins &&
                                            <Equalizer />
                                        }
                                        {
                                            seeMargins &&
                                            <UnfoldLess />
                                        }
                                    </IconButton>
                                </LightTooltip>
                            </Stack>
                            {
                                props.actions &&
                                props.actions()
                            }
                            {
                                step !== 4 &&
                                <Fab
                                    color="success"
                                    variant="extended"
                                    size="small"
                                    onClick={onGoToBooking}
                                >
                                    <CheckCircle sx={{ marginRight: 1 }} />
                                    <Box sx={{ lineHeight: 1 }} component="span">
                                        {
                                            user?.client_full?.type !== 2 ||
                                            quotationCode === 'beachcomber' ?
                                                t<string>('cart-material.cart-construction-book') :
                                                t<string>('cart-material.send-demand-to-book')
                                        }
                                    </Box>
                                </Fab>
                            }
                            {
                                step === 4 &&
                                <Fab
                                    color="success"
                                    variant="extended"
                                    size="small"
                                    onClick={onConfirmBooking}
                                >
                                    <CheckCircle sx={{ marginRight: 1 }} />
                                    <Box sx={{ lineHeight: 1 }} component="span">
                                        {t<string>('cart-material.cart-construction-confirm-booking')}
                                    </Box>
                                </Fab>
                            }
                        </Stack>
                        <Collapse
                            in={
                                quotationCode !== 'marcovasco' &&
                                seeMargins
                            }
                        >
                            <div>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{ marginTop: 1 }}
                                >
                                    <Typography>
                                        {
                                            quotationCode !== "cercledesvoyages" ?
                                                t<string>('cart-material.total-price-ht') :
                                                t<string>('cart.buying_price_1') + " " + currency.symbol
                                        } :{' '}
                                        <strong>
                                            {
                                                !['cercledesvoyages'].includes(quotationCode) ?
                                                    prices.margin.purchase_converted.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
                                                    (
                                                        prices.margin.purchase_converted +
                                                        prices.flightTaxes + prices.surcom.value +
                                                        prices.vat
                                                    ).toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                            } {
                                            prices.margin.selling_currency &&
                                            prices.margin.selling_currency.symbol
                                        }
                                        </strong>
                                        {
                                            (() => {
                                                const terrestrialPrices = trip?.prices_terrestrial?.filter((item) => {
                                                    return !isProductPackaged({
                                                        product: item,
                                                        stackInfos: trip?.stack_info ?? null
                                                    });
                                                });
                                                const flightPrices = trip?.prices_flight?.filter((item) => {
                                                    return !isProductPackaged({
                                                        product: item,
                                                        stackInfos: trip?.stack_info ?? null
                                                    });
                                                });
                                                return ((terrestrialPrices?.length ?? 0) > 0 || (flightPrices?.length ?? 0) > 0) &&
                                                       !hasStackedItems &&
                                                        <LightTooltip title={
                                                            //eslint-disable-next-line no-nested-ternary
                                                            ((terrestrialPrices?.length ?? 0) > 0 && (flightPrices?.length ?? 0) > 0) ?
                                                                t<string>('cart-material.warning-global-terrestrial-flight') :
                                                                (terrestrialPrices?.length ?? 0) > 0 ? t<string>('cart-material.warning-global-terrestrial') : t<string>('cart-material.warning-global-flight') }>
                                                            <ReportProblemOutlined className={ classes.globalPriceWarningIcon }/>
                                                        </LightTooltip>;
                                            })()
                                        }
                                    </Typography>
                                    {
                                        !['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines'].includes(quotationCode) && (
                                            <>
                                                <Typography>
                                                    { t<string>('cart-material.airport-taxes') } : <strong>{ prices.flightTaxes.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } { currency.symbol }</strong>
                                                    {
                                                        (trip?.prices_flight?.length ?? 0) > 0 &&
                                                        trip?.flight_taxes &&
                                                        <LightTooltip title={ t<string>('cart-material.warning-global-flight') }>
                                                            <ReportProblemOutlined className={ classes.globalPriceWarningIcon }/>
                                                        </LightTooltip>
                                                    }
                                                </Typography>
                                                {
                                                    !editPrices &&
                                                    <>
                                                        <Typography>
                                                            {
                                                                user?.client_full?.type !== 2 ?
                                                                    t<string>('cart-material.margin-to-in-value') :
                                                                    t<string>('cart-material.margin-agency-in-value')
                                                            } : <strong>
                                                            {
                                                                prices.margin.value.toLocaleString(
                                                                    language,
                                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                                )
                                                            } {prices.margin.selling_currency?.symbol}
                                                        </strong>
                                                            <span className={ classes.signedPrice }>
                                                                {' '}({
                                                                prices.margin.percent.toLocaleString(
                                                                    language,
                                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                                )
                                                            } %)
                                                            </span>
                                                        </Typography>
                                                        {
                                                            prices.vat !== 0 && (
                                                                <Typography>
                                                                    { t<string>('cart-material.manual-product-margin-vat-value') + " " + currency.symbol } : <strong>
                                                                    {
                                                                        prices.vat.toLocaleString(
                                                                            language,
                                                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                                        )
                                                                    } { prices.margin.selling_currency?.symbol }
                                                                </strong>
                                                                </Typography>
                                                            )
                                                        }
                                                    </>
                                                }
                                                {
                                                    editPrices &&
                                                    <>
                                                        <TextField
                                                            type="number"
                                                            size="small"
                                                            label={t<string>('cart-material.manual-product-margin-value') + " " + currency.symbol}
                                                            value={priceAdjustmentModalInputs.margin}
                                                            onChange={(event) => {
                                                                onChangePriceAdjustmentModalInput('type', 'margin');
                                                                onChangePriceAdjustmentModalInput('margin', parseFloat(event.target.value));
                                                            }}
                                                        />
                                                        <TextField
                                                            type="number"
                                                            size="small"
                                                            label={t<string>('cart-material.manual-product-margin-per')}
                                                            value={
                                                                (() => {
                                                                    let purchasePrice = !['cercledesvoyages'].includes(quotationCode) ?
                                                                        prices.margin.purchase_converted :
                                                                        prices.margin.purchase_converted +
                                                                        prices.flightTaxes + prices.surcom.value +
                                                                        prices.vat;
                                                                    purchasePrice = parseFloat(purchasePrice.toFixed(2));
                                                                    return parseFloat(
                                                                        (
                                                                            (
                                                                                priceAdjustmentModalInputs.margin /
                                                                                (purchasePrice + priceAdjustmentModalInputs.margin)
                                                                            ) * 100
                                                                        ).toFixed(2)
                                                                    );
                                                                })()
                                                            }
                                                            onChange={(event) => {
                                                                let purchasePrice = !['cercledesvoyages'].includes(quotationCode) ?
                                                                    prices.margin.purchase_converted :
                                                                    prices.margin.purchase_converted +
                                                                    prices.flightTaxes + prices.surcom.value +
                                                                    prices.vat;
                                                                purchasePrice = parseFloat(purchasePrice.toFixed(2));
                                                                const percent = parseFloat((parseFloat(event.target.value) / 100).toFixed(4));
                                                                const margin = parseFloat(((percent * purchasePrice) / (1 - percent)).toFixed(2));
                                                                onChangePriceAdjustmentModalInput('type', 'margin');
                                                                onChangePriceAdjustmentModalInput('margin', margin);
                                                            }}
                                                        />
                                                    </>
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines'].includes(quotationCode) && (
                                            <>
                                                {
                                                    !editPrices &&
                                                    <>
                                                        <Typography>
                                                            { t<string>('cart-material.manual-product-margin-value') + " " + currency.symbol + " : " }
                                                            <strong>
                                                                {
                                                                    prices.margin.value.toLocaleString(
                                                                        language,
                                                                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                                    )
                                                                } {prices.margin.selling_currency?.symbol}
                                                                {
                                                                    all_data && all_data?.agreed_margin && prices.margin && (
                                                                        <span className={ classes.signedPrice }>
                                                                            {
                                                                                //eslint-disable-next-line max-len
                                                                                ` (${parseFloat(all_data?.agreed_margin).toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } ${ currency && currency.symbol })`
                                                                            }
                                                                        </span>
                                                                    )
                                                                }
                                                                <span className={ classes.signedPrice }>
                                                                    {' '}({
                                                                    prices.margin.percent.toLocaleString(
                                                                        language,
                                                                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                                    )
                                                                } %)
                                                                </span>
                                                            </strong>
                                                        </Typography>
                                                        {
                                                            prices.vat !== 0 && (
                                                                <Typography>
                                                                    { t<string>('cart-material.manual-product-margin-vat-value') + " " + currency.symbol } : <strong>
                                                                    {
                                                                        prices.vat.toLocaleString(
                                                                            language,
                                                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                                        )
                                                                    } { prices.margin.selling_currency?.symbol }
                                                                </strong>
                                                                </Typography>
                                                            )
                                                        }
                                                    </>
                                                }
                                                {
                                                    editPrices &&
                                                    <>
                                                        <TextField
                                                            type="number"
                                                            size="small"
                                                            label={t<string>('cart-material.manual-product-margin-value') + " " + currency.symbol}
                                                            value={priceAdjustmentModalInputs.margin}
                                                            onChange={(event) => {
                                                                onChangePriceAdjustmentModalInput('type', 'margin');
                                                                onChangePriceAdjustmentModalInput('margin', parseFloat(event.target.value));
                                                            }}
                                                        />
                                                        <TextField
                                                            type="number"
                                                            size="small"
                                                            label={t<string>('cart-material.manual-product-margin-per')}
                                                            value={
                                                                (() => {
                                                                    let purchasePrice = !['cercledesvoyages'].includes(quotationCode) ?
                                                                        prices.margin.purchase_converted :
                                                                        prices.margin.purchase_converted +
                                                                        prices.flightTaxes + prices.surcom.value +
                                                                        prices.vat;
                                                                    purchasePrice = parseFloat(purchasePrice.toFixed(2));
                                                                    return parseFloat(
                                                                        (
                                                                            (
                                                                                priceAdjustmentModalInputs.margin /
                                                                                (purchasePrice + priceAdjustmentModalInputs.margin)
                                                                            ) * 100
                                                                        ).toFixed(2)
                                                                    );
                                                                })()
                                                            }
                                                            onChange={(event) => {
                                                                let purchasePrice = !['cercledesvoyages'].includes(quotationCode) ?
                                                                    prices.margin.purchase_converted :
                                                                    prices.margin.purchase_converted +
                                                                    prices.flightTaxes + prices.surcom.value +
                                                                    prices.vat;
                                                                purchasePrice = parseFloat(purchasePrice.toFixed(2));
                                                                const percent = parseFloat((parseFloat(event.target.value) / 100).toFixed(4));
                                                                const margin = parseFloat(((percent * purchasePrice) / (1 - percent)).toFixed(2));
                                                                onChangePriceAdjustmentModalInput('type', 'margin');
                                                                onChangePriceAdjustmentModalInput('margin', margin);
                                                            }}
                                                        />
                                                    </>
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        (quotationCode !== "cercledesvoyages" && quotationCode !== "continentsinsolites") && user?.client_full?.type !== 2 && prices.surcom && prices.surcom.currency && (
                                            <>
                                                <Typography>
                                                    {
                                                        quotationCode === 'verdie' ?
                                                            t<string>('cart-material.assistance-to-in-value') :
                                                            t<string>('cart-material.surcom-to-in-value') } : <strong>
                                                    { prices.surcom.value.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } { prices.surcom.currency?.symbol }
                                                </strong>
                                                    <span className={ classes.signedPrice }>
                                                        {' '}({
                                                        prices.surcom.percent.toLocaleString(
                                                            language,
                                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                        )
                                                    } %)
                                                    </span>
                                                </Typography>
                                            </>
                                        )
                                    }
                                    {
                                        ['cercledesvoyages'].includes(quotationCode) && user?.groups?.some(group => (group as {is_admin?: boolean}).is_admin === true) && prices.surcom.currency &&
                                        <Typography>
                                            { t<string>('menu.trip_list.surcom') + " " + prices.surcom.currency.symbol + " : "}
                                            <strong>
                                                { prices.surcom.value.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } { prices.surcom.currency.symbol }
                                            </strong>
                                        </Typography>
                                    }
                                    {
                                        ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines'].includes(quotationCode) && (
                                            <Typography component="div">
                                                <Stack direction="row" alignItems="center">
                                                    <span style={{ marginRight: 5 }}>{t<string>("menu.trip_list.advance_payment")} :</span>
                                                    {
                                                        !editAdvancePayment && (prices.paymentDone < newAdvancePayment || newAdvancePayment === 0) && (
                                                            <>
                                                                <strong>
                                                                    {
                                                                        prices.advancePayment !== 0 ?
                                                                            new Intl.NumberFormat(
                                                                                language,
                                                                                {
                                                                                    style: 'currency',
                                                                                    currency: currency.iso_code,
                                                                                    minimumFractionDigits: 0,
                                                                                    maximumFractionDigits: 2
                                                                                }
                                                                            ).format(prices.advancePayment) :
                                                                            0
                                                                    }
                                                                </strong>
                                                                <LightTooltip title={ t<string>('global.edit') }>
                                                                    <IconButton size={"small"} onClick={onToggleAdvancePayment}>
                                                                        <Edit fontSize={"small"} style={{ marginBottom: 3 }}/>
                                                                    </IconButton>
                                                                </LightTooltip>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        !editAdvancePayment && prices.paymentDone >= newAdvancePayment && prices.paymentDone !== 0 && (
                                                            <strong>{t<string>("menu.trip_list.advance_payment_paid")}</strong>
                                                        )
                                                    }
                                                    {
                                                        editAdvancePayment && (
                                                            <>
                                                                <TextField
                                                                    value={newAdvancePayment}
                                                                    onChange={onChangeAdvancePayment}
                                                                    style={{ width: 100 }}
                                                                    sx={{
                                                                        input: {
                                                                            padding: 0,
                                                                            paddingLeft: 1,
                                                                            paddingRight: 1
                                                                        }
                                                                    }}
                                                                />
                                                                <LightTooltip title={ t<string>('global.validate') }>
                                                                    <IconButton size={"small"} onClick={onToggleValidateAdvancePayment}>
                                                                        <Check fontSize={"small"} style={{ marginBottom: 3 }}/>
                                                                    </IconButton>
                                                                </LightTooltip>
                                                                <LightTooltip title={ t<string>('global.cancel') }>
                                                                    <IconButton size={"small"} onClick={onToggleCancelAdvancePayment}>
                                                                        <Close fontSize={"small"} style={{ marginBottom: 3 }}/>
                                                                    </IconButton>
                                                                </LightTooltip>
                                                            </>
                                                        )
                                                    }
                                                </Stack>
                                            </Typography>
                                        )
                                    }
                                </Stack>
                                {
                                    !editPrices && (quotationCode !== "continentsinsolites" || all_data?.branch_code == "fr") &&
                                    <Button
                                        onClick={() => setEditPrices(true)}
                                        sx={{
                                            display: 'flex',
                                            marginLeft: 'auto'
                                        }}
                                    >
                                        {t<string>('global.edit')}
                                    </Button>
                                }
                                {
                                    editPrices &&
                                    <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                        <Button onClick={() => setEditPrices(false)}>
                                            {t<string>('shared.cancel')}
                                        </Button>
                                        <Button onClick={() => onChangePriceAdjustmentModalInput('open', true)}>
                                            {t<string>('shared.save')}
                                        </Button>
                                    </Stack>
                                }
                            </div>
                        </Collapse>
                    </Container>
                </Stack>
                <CartConstructionHeaderPriceAdjustmentModal
                    open={priceAdjustmentModalInputs.open}
                    type={priceAdjustmentModalInputs.type}
                    value={
                        priceAdjustmentModalInputs.type === 'selling-price' ?
                            priceAdjustmentModalInputs.sellingPrice :
                            priceAdjustmentModalInputs.margin
                    }
                    onClose={onClosePriceAdjustmentModal}
                />
            </>
        );
    }
);

const SpinnerAnimation = keyframes(`
0% {
    transform: rotateZ(0deg);
}
100% {
    transform: rotateZ(360deg);
}
`);

const useStyles = makeStyles({
    stickyPaper: {
        //position: 'sticky',
        //top: 94,
        borderRadius: 16,
        padding: 16
    },
    refreshButton: {
        padding: 8,
        marginTop: -9,
        marginRight: -9
    },
    marginToggle: {
        cursor: 'pointer'
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    blueButtonContainer: {
        marginBottom: 4,
        marginTop: -4
    },
    blueButton: {
        border: '1px solid #76B6C2',
        backgroundColor: 'white',
        color: '#76B6C2'
    },
    blueButtonFilled: {
        backgroundColor: '#76B6C2',
        color: 'white'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    cancelAll: {
        marginTop: 8,
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    },
    cancelAllCancel: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    },
    cancelAllWrapper: {
        position: 'relative'
    },
    cancelAllRunning: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -16
    },
    selector: {
        marginBottom: 4
    },
    checkBoxLabel: {
        marginBottom: 0
    },
    checked: {
        fill: 'black'
    },
    globalPriceWarningIcon: {
        verticalAlign: 'text-bottom',
        marginLeft: 6
    },
    signedPrice: {
        fontStyle: "italic",
        fontSize: 14,
        color: "gray",
        fontWeight: "normal"
    },
    orangeSwitchLabel: {
        color: '#E6592F',
        textTransform: 'uppercase',
        fontSize: 14
    },
    orangeBackground: {
        '& .mui-jss-MuiSwitch-track': {
            backgroundColor: '#E6592F',
            opacity: "13%"
        }
    }
});
