//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import InfoIcon from '@material-ui/icons/Info';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import DateRange from '@material-ui/icons/DateRange';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import Room from '@material-ui/icons/Room';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PersonIcon from '@material-ui/icons/Person';

import { TogleInfoHotelAndSetStep } from '../../../Actions/Summary';
import MosaicPicture from '../../Accommodation/FunctionalComponent/MosaicPicture';
import Picture from '../../Accommodation/FunctionalComponent/Picture';
import Slider from '../../Accommodation/FunctionalComponent/Slider';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 30
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 19px)',
        margin: '0 4px',
        left: "-16px"
    },
	containerMargin: {
        marginLeft: 5
    },
	includedAmenities: {
        verticalAlign: '-3px',
		fontSize: 16,
        marginRight: 4
    },
	bold: {
		fontWeight: "bold"
	},
	italic: {
		fontStyle: "italic"
	},
	font12: {
		fontSize: 12
	},
	font14: {
		fontSize: 14
	},
	font17: {
		fontSize: 17
	},
	lowerCase: {
        textTransform: "capitalize"
	},
	calendar: {
        float: 'left',
        marginRight: 4
    },
    pin: {
        float: 'left',
        marginRight: 4,
        fontSize: 17
    },
    pointer: {
        cursor: "pointer"
    },
    underline: {
        textDecoration: "underline"
    },
    pictureContainer: {
	    overflow: "hidden",
	    height: 170,
	    // width: 300,
	    position: "relative",
	    cursor: "pointer"
    },
}));

const SummaryManualCard = ({manual, mainPoi, type, selectedProducts, setSelectedProducts, step_index, from, otherProduct}) => {
    const dispatch = useDispatch();
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const trip_info = useSelector(state => state.summary.trip_info);
    const currency_list = useSelector(state => state.base.currency_list);

    const [showChevron, setShowChevron] = useState(false);
	const [openMozaic, setOpenMozaic] = useState(false);
	const [openPicture, setOpenPicture] = useState(false);
	const [current_picture, setCurrentPicture] = useState(0);

    let manual_picture = ["/Img/accommodation_default.jpg"];

	if (manual !== null) {
		if (manual.picture !== undefined && manual.picture !== null) {
			manual_picture = [manual.picture];
		}
	}
    const variantIsChecked = (product) => {
        return selectedProducts.manual[step_index].includes(product.id);
    }
    const handleChange = () => {
        let tmp_selectedProducts = Object.assign({}, selectedProducts);
        let tmp_manual = tmp_selectedProducts.manual.slice();
        if (tmp_manual[step_index].includes(manual.id)) {
            tmp_manual[step_index] = tmp_manual[step_index].filter((el) => {
                return el !== manual.id
            });
        }
        else {
            tmp_manual[step_index].push(manual.id);
        }
        tmp_selectedProducts.manual = tmp_manual;
        setSelectedProducts(tmp_selectedProducts);
    }
    const getClientPrice = (prices) => {
        let current_price = prices.find((el) => {
            return !el.is_commissioned && !el.is_network && !el.is_surcom && !el.master_price;
        });
        if (current_price === undefined) {
            current_price = prices.find((el) => {
                return !el.is_commissioned && !el.is_network && !el.is_surcom && el.master_price;
            });
        }
        return current_price;
    }
    const showContractDetail = () => {
        dispatch({type: 'CART_TOGGLE_CONTRACT_DETAIL', payload: manual});
    };
    const onMouseEnter = () => {
	    setShowChevron(true);
	};
	const onMouseLeave = () => {
	    setShowChevron(false);
	};
	const openModal = () => {
		if (!fullScreen) {
			togglePictureModal(manual_picture)
		}
		else {
			toggleMosaicModal(manual_picture);
		}
	}
	const togglePictureModal = (pictures, index_picture = 0) => {
		setOpenPicture(!openPicture);
		setCurrentPicture(index_picture);
	};
	const toggleMosaicModal = (pictures) => {
		setOpenMozaic(!openMozaic);
    };
    let price = parseFloat(getClientPrice(manual.prices).selling_price);
    let selling_currency = currency_list.find((currency) => currency.id === getClientPrice(manual.prices).selling_currency);

    let adults = 0;
	let child = 0;
	if (manual.group_passenger !== null) {
		manual.group_passenger.travelers_list.map((traveler) => {
			let age = moment.utc(trip_info.end_date).diff(moment.utc(traveler.birth_date), "y");
			if (age >= 18) {
				adults += 1;
			}
			if (age < 18) {
				child += 1;
			}
		});
	}
    return (
        <Grid container direction={"row"} spacing={2} style={{marginBottom: 10, background: from === "tripSummary" ? (type === "option" ? "#EEEEEE" : "white") : "white"}}>
            {
                from !== "tripSummary" && otherProduct === undefined && (trip_info.status === "TO_UPDATED" || trip_info.status === "ONGOING" || trip_info.status === "VALIDATED" || trip_info.status === "TOBOOK_VERDIE") && (
                    <Grid item>
                        <div className={ classes.checkboxContainer }>
                            <Checkbox
                                className={ classes.checkbox }
                                edge={ 'start' }
                                checked={ variantIsChecked(manual) }
                                tabIndex={ -1 }
                                disableRipple
                                onChange={ handleChange }
                                icon={<CircleUnchecked />}
                                checkedIcon={<RadioButtonCheckedIcon  />}
                            />
                        </div>
        			</Grid>
                )
            }
            <Grid item xs container direction={"column"} alignItems={"flex-start"} className={classes.font14}>
            {
                from !== "tripSummary" && (
                    <Fragment>
                        <Grid item style={{width: "100%"}}>
                            <Grid container direction={"row"} justify={"space-between"}>
                                <Grid item>
                                    <VisibilityIcon className={classes.genericText} style={{verticalAlign: "sub", marginRight: 10}}/>
                                    <span className={classes.genericText}>{  manual.name } </span>
                                    {
                                        ((manual.description !== null && manual.description !== "")) && (
                                            <span className={`${classes.genericText} ${classes.pointer} ${classes.underline}`} onClick={ showContractDetail } style={{paddingLeft: 5}}>{ t("global.short_more_info") }</span>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            manual.address !== null && (
                                <Grid item style={{width: "100%"}}>
                                    <Room className={ classes.pin }/><Typography className={`${classes.font14} ${classes.genericText}`}>{ manual.address }</Typography>
                                </Grid>
                            )
                        }
                        {
                            manual.start_date !== null &&
                            <Grid item style={{width: "100%"}}>
                                <Grid container direction={"row"} justify={"space-between"}>
                                    <Grid item xs>
                                        <DateRange className={ classes.calendar } style={{fontSize: 20, verticalAlign: "text-top"}}/>
                                        <span className={`${classes.font14} ${classes.genericText}`}>{ moment.utc(manual.start_date).format('DD MMMM HH:mm') }</span>
                                        {adults !== 0 || child !== 0 ? " - " : ""}
                                        <span className={`${classes.font14} ${classes.genericText}`}>{`${adults !== 0 ? adults : ""} ${adults !== 0 ? (adults > 1 ? t("flight_search.adults") : t("flight_search.adult")) : ""} ${child !== 0 ? child : ""} ${child !== 0 ? (child > 1 ? t("flight_search.children") : t("flight_search.child")) : ""}`}</span>
                                    </Grid>

                                </Grid>
                            </Grid>
                        }
                    </Fragment>
                )
            }
			{
                from === "tripSummary" && (
                    <Fragment>
                        <Grid item container direction={from === "tripSummary" ? (manual.is_optional ? "row-reverse" : "row") : "column"} spacing={from === "tripSummary" ? 2 : 0} style={{paddingTop: 20}}>
                            <Grid item xs={12} style={{marginTop: 10}}>
                                <Grid container direction={manual.is_optional ? "row-reverse" : "row"} spacing={2} style={{marginBottom: manual.is_optional ? 20 : 0}}>
                                    <Grid item sm={5} xs={12} className={classes.pictureContainer} onMouseEnter={ onMouseEnter } onMouseLeave={ onMouseLeave } onClick={openModal} style={{maxHeight: 170}}>
                                        <Slider pictures={ manual_picture } showChevron={ showChevron } size={ 0 } dotSize={ 1 }/>
                                    </Grid>
                                    <Grid item sm={7} xs={12}>
                                        <Grid container direction={"column"}>
                                            <Grid item style={{width: "100%"}}>
                                                <Grid container direction={"row"} justify={"space-between"}>
                                                    <Grid item>
                                                        <VisibilityIcon className={classes.genericText} style={{verticalAlign: "sub", marginRight: 10}}/>
                                                        <span className={classes.genericText}>{  manual.name } </span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {
                                                manual.address !== null && (
                                                    <Grid item style={{width: "100%"}}>
                                                        <Room className={ classes.pin }/><Typography className={`${classes.font14} ${classes.genericText}`}>{ manual.address }</Typography>
                                                    </Grid>
                                                )
                                            }
                                            {
                                                manual.start_date !== null &&
                                                <Grid item style={{width: "100%"}}>
                                                    <Grid container direction={"row"} justify={"space-between"}>
                                                        <Grid item xs>
                                                            <DateRange className={ classes.calendar } style={{fontSize: 20, verticalAlign: "text-top"}}/>
                                                            <span className={`${classes.font14} ${classes.genericText}`}>{ moment.utc(manual.start_date).format('DD MMMM HH:mm') }</span>
                                                            {adults !== 0 || child !== 0 ? " - " : ""}
                                                            <span className={`${classes.font14} ${classes.genericText}`}>{`${adults !== 0 ? adults : ""} ${adults !== 0 ? (adults > 1 ? t("flight_search.adults") : t("flight_search.adult")) : ""} ${child !== 0 ? child : ""} ${child !== 0 ? (child > 1 ? t("flight_search.children") : t("flight_search.child")) : ""}`}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fragment>
                )
            }
            {
                manual.custom_information !== undefined && manual.custom_information !== null && (
                    <Grid item style={{width: "100%", marginTop: 10}}>
                        <Alert variant={ 'filled' } severity={ 'info' } >
                            <AlertTitle>{ t('cart-material.note') } :</AlertTitle>
                            { manual.custom_information }
                        </Alert>
                    </Grid>
                )
            }
            {
                type === "option" && (
                    <Grid item style={{width: "100%", marginTop: 10, fontSize: 20}} xs>
                        <span style={{float: "right"}} className={classes.bold}>+ {Intl.NumberFormat(i18n.language, { style: "currency", currency: selling_currency.iso_code, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(price) }</span>
                    </Grid>
                )
            }
			</Grid>
            <MosaicPicture open={ openMozaic } onClose={ toggleMosaicModal } pictures={ manual_picture } openModal={ togglePictureModal }/>
			<Picture open={ openPicture } onClose={ togglePictureModal } pictures={ manual_picture } current_picture={ current_picture } setCurrentPicture={ setCurrentPicture }/>
        </Grid>
    )
}
export default React.memo(SummaryManualCard);