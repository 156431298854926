import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Button,
    Checkbox,
    Collapse,
    Stack,
    TableCell,
    TableRow,
    Typography,
    styled
} from "@mui/material";
import {
    Dashboard,
    ExpandLess,
    ExpandMore,
    Send
} from "@mui/icons-material";
import { CartConstructionProductsTable } from "./CartConstructionProductsTable";
import { CartSendProviderQuotationDemandModal } from "./CartSendProviderQuotationDemandModal";
import { CartConstructionProductsByType } from "./CartConstructionProductsByType";
import { CartConstructionProductsByDay } from "./CartConstructionProductsByDay";
import { CartConstructionPackageMargin } from "./CartConstructionPackageMargin";
import {
    CartPackageDraggableItem,
    CartPackageDraggableItemProps
} from "./CartPackageDraggableItem";
import { CartStackedCancellationRules } from "./CartStackedCancellationRules";
import { usePackagePrice } from "./utils/packagePrice";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { AppState } from "../../Reducers/Reducers";

type Props = ({
    cart: {
        [key: string]: Omit<
            ReturnType<typeof useCartProducts> & {manualProducts: ManualProduct[]},
            'trains' | 'ferries' | 'cruises' | 'insurances'
        >
    },
    cartDisplay: 'product' | 'chronological',
    selectable?: false
} | {
    cart: {
        [key: string]: CartPackageDraggableItemProps[]
    }, 
    itemActions?: (item: CartPackageDraggableItemProps) => JSX.Element,
    selection: {
        [stackNumber: number]: boolean
    },
    onChangeSelection: React.Dispatch<React.SetStateAction<{
        [stackNumber: number]: boolean;
    }>>,
    selectable: true
}) & {
    disablePrices?: boolean
}

export function CartConstructionProductsPackages(props: Props): JSX.Element {
    const { t } = useTranslation();
    const step = useSelector((state: AppState) => state.cart.step);
    const user = useSelector((state: AppState) => state.user.user);
    const [openQuotationDemandModal, setOpenQuotationDemandModal] = useState(false);

    return (
        <>
            <CartConstructionProductsTable
                sections={[
                    {
                        title: t<string>('cart-material.cart-construction-packages'),
                        action: !props.selectable &&
                                step !== 4 &&
                                user?.client_full?.type !== 2 ?
                            (
                                <Button
                                    variant="outlined"
                                    endIcon={
                                        <Send />
                                    }
                                    onClick={() => setOpenQuotationDemandModal(true)}
                                >
                                    {t<string>('cart-material.provider-quotation-send-demand')}
                                </Button>
                            ) :
                            null,
                        content: Object.keys(props.cart).map((key) => {
                            const stackNumber = key.split('-')[0] ? parseInt(key.split('-')[0]!) : null;
                            const stackInfoId = key.split('-')[1] ? parseInt(key.split('-')[1]!) : null;

                            if (props.cart[`${stackNumber ?? ''}-${stackInfoId ?? ''}`]) {
                                if (props.selectable) {
                                    return (
                                        <Row
                                            key={key}
                                            stackNumber={stackNumber}
                                            stackInfoId={stackInfoId}
                                            packageContent={props.cart[key]!}
                                            selection={props.selection}
                                            onChangeSelection={props.onChangeSelection}
                                            itemActions={props.itemActions}
                                            disablePrices={props.disablePrices}
                                            selectable
                                        />
                                    );
                                }
                                return (
                                    <Row
                                        key={key}
                                        stackNumber={stackNumber}
                                        stackInfoId={stackInfoId}
                                        packageContent={props.cart[key]!}
                                        cartDisplay={props.cartDisplay}
                                        disablePrices={props.disablePrices}
                                    />
                                );
                            }

                            return null;
                        })
                    }
                ]}
            />
            {
                openQuotationDemandModal &&
                <CartSendProviderQuotationDemandModal
                    onClose={() => setOpenQuotationDemandModal(false)}
                />
            }
        </>
    );
}

type RowProps = {
    stackInfoId: number | null,
    stackNumber: number | null,
} & ({
    packageContent: Omit<
        ReturnType<typeof useCartProducts> & {manualProducts: ManualProduct[]},
        'trains' | 'ferries' | 'cruises' | 'insurances'
    >,
    cartDisplay: 'product' | 'chronological',
    selectable?: false
} | {
    packageContent: CartPackageDraggableItemProps[],
    itemActions?: (item: CartPackageDraggableItemProps) => JSX.Element,
    selection: {
        [key: string]: boolean
    },
    onChangeSelection: React.Dispatch<React.SetStateAction<{
        [key: string]: boolean;
    }>>,
    selectable: true
}) & {
    disablePrices?: boolean
}

function Row(props: RowProps): JSX.Element {
    const { t } = useTranslation();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const [open, setOpen] = useState(false);
    const prices = usePackagePrice({ stackNumber: props.stackNumber, stackInfoId: props.stackInfoId });

    const onChangeSelection = (checked: boolean) => {
        if (props.selectable) {
            props.onChangeSelection((state) => {
                return {
                    ...state,
                    [`${props.stackNumber}-${props.stackInfoId}`]: checked
                };
            });
        }
    };

    return (
        <>
            <TableRow
                sx={{
                    "cursor": 'pointer',
                    '& > *': { borderBottom: 'unset' }
                }}
                onClick={() => setOpen((state) => !state)}
                hover
            >
                <TableCell colSpan={20}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ paddingTop: 1.5, paddingBottom: 1.5 }}
                    >
                        <Stack
                            direction="row"
                            spacing={1.5}
                        >
                            {
                                props.selectable &&
                                <Checkbox
                                    sx={{ padding: 0 }}
                                    onClick={(event) => event.stopPropagation()}
                                    checked={props.selection[`${props.stackNumber}-${props.stackInfoId}`] ?? false}
                                    onChange={(_, checked) => onChangeSelection(checked)}
                                />
                            }
                            <Dashboard color="primary" />
                            <Typography color="primary">
                                {
                                    props.stackNumber !== 0 &&
                                    t<string>(
                                        'cart-material.cart-construction-package-name',
                                        { name: props.stackNumber }
                                    )
                                }
                                {
                                    props.stackNumber === 0 &&
                                    t<string>(
                                        'cart-material.cart-construction-connected-package',
                                        { name: props.stackNumber }
                                    )
                                }
                            </Typography>
                        </Stack>
                        <Typography color="GrayText">
                            {
                                t<string>(
                                    'cart-material.cart-construction-package-price',
                                    {
                                        price: prices.map((item) => {
                                            return new Intl.NumberFormat(
                                                language,
                                                {
                                                    style: 'currency',
                                                    currency: item.currency?.iso_code ?? 'EUR',
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 2
                                                }
                                            ).format(item.cost);
                                        }).join(' + ')
                                    }
                                )
                            }
                        </Typography>
                        {
                            open ?
                                <ExpandLess /> :
                                <ExpandMore />
                        }
                    </Stack>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ paddingLeft: 0, paddingTop: 0 }} colSpan={20}>
                    <CartStackedCancellationRules
                        stackNumber={props.stackNumber}
                        stackInfoId={props.stackInfoId}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <MarginWrapper
                    stackNumber={props.stackNumber}
                    stackInfoId={props.stackInfoId}
                    disablePrices={props.disablePrices}
                />
            </TableRow>
            <TableRow>
                <TableCell
                    sx={{
                        "padding": 0,
                        '& td:first-child': {
                            paddingLeft: 8
                        }
                    }}
                    colSpan={20}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {
                            !props.selectable &&
                            <>
                                {
                                    props.cartDisplay === 'product' &&
                                    <CartConstructionProductsByType cart={props.packageContent} package />
                                }
                                {
                                    props.cartDisplay === 'chronological' &&
                                    <CartConstructionProductsByDay cart={props.packageContent} package />
                                }
                            </>
                        }
                        {
                            props.selectable &&
                            <CartConstructionProductsTable
                                sections={[
                                    {
                                        content: (
                                            <List>
                                                {
                                                    props.packageContent.map((product) => (
                                                        <CartPackageDraggableItem
                                                            key={`${product.type}-${product.id}`}
                                                            item={product}
                                                            itemActions={props.itemActions}
                                                            disableDnd
                                                        />
                                                    ))
                                                }
                                            </List>
                                        )
                                    }
                                ]}
                                singleCell
                            />
                        }
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

type MarginWrapperProps = {
    disablePrices?: boolean,
    stackNumber: number | null,
    stackInfoId: number | null
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <TableCell
            sx={
                seeAllProductsMargins && !props.disablePrices ?
                    undefined :
                    { paddingTop: 0, paddingBottom: 0 }
            }
            colSpan={20}
        >
            <Collapse
                in={seeAllProductsMargins && !props.disablePrices}
                unmountOnExit
            >
                <CartConstructionPackageMargin
                    stackNumber={props.stackNumber}
                    stackInfoId={props.stackInfoId}
                />
            </Collapse>
        </TableCell>
    );
}

const List = styled('ul')(() => ({
    listStyle: 'none',
    paddingLeft: 0
}));
