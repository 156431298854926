import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { TripListRoadbookModal } from "./TripListRoadbookModal";

export function TripListRoadbookAddButton(): JSX.Element {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    return (
        <>
            {
                open &&
                <TripListRoadbookModal onClose={() => setOpen(false)} />
            }
            <Button
                startIcon={<Add />}
                sx={{ marginLeft: 1 }}
                onClick={() => setOpen(true)}
            >
                {t<string>('roadbook.generate')}
            </Button>
        </>
    );
}
