//---- Dependencies ----//
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n.jsx";

//---- Commons ----//
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import Notify from "../Common/Notify";

//---- CSS ----//
import "../../Style/Summary.css";
import "../../Style/Global.css";

//---- Actions ----//
import { ToggleLanguage, ToggleAndSetLanguage, ToggleMenu } from "../../Actions/Header";

//---- Functions & Components ----//
import GetAcco from "./Functions/GetAcco.js";
import GetPoi from "./Functions/GetPoi.js";
import GetFlight from "./Functions/GetFlight.js";
import GetCar from "./Functions/GetCar.js";
import GetManual from "./Functions/GetManual.js";
import GetTripInfo from "./Functions/GetTripInfo.js";
import GetCustom from "./Functions/GetCustom.js";

//---- Class ----//
@connect((store) => {
    return {
        list_currency: store.base.currency_list,
        language: store.header.tmp_language,
        show_language: store.header.show_language
    }
})
@withTranslation()
class ProviderCancel extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            product: null, 
            group: [],
            trip: null,
            booking_nb: null,
            price: null,
            more_info: null
        };
    }
    
     componentWillMount() {
        const { language, dispatch } = this.props;
        if (language === null) {
            let lang = i18n.languages.filter((lng) => {
                return lng.length === 2;
            });
            dispatch(ToggleAndSetLanguage(false, lang[0]))
        }
    }

    setLanguage(language) {
        i18n.changeLanguage(language);
        moment.locale(language);
    }

    getPricesId(prices) {
        if (prices.length > 0) {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].master_price === true) {
                    return i;
                }
            }
        }
        return 0;
    }

    componentDidMount() {
        GetTripInfo(this.props.params.trip_id, this.props.params.trip_id_version, this.props.params.trip_token, this)
        if (this.props.params.product_type === "accommodation") {
            GetAcco(this.props.params.trip_id, this.props.params.trip_id_version, this, this.props.params.product_id);
        }
        if (this.props.params.product_type === "poi") {
            GetPoi(this.props.params.trip_id, this.props.params.trip_id_version, this, this.props.params.product_id);
        }
        if (this.props.params.product_type === "flight") {
            GetFlight(this.props.params.trip_id, this.props.params.trip_id_version, this, this.props.params.product_id);
        }
        if (this.props.params.product_type === "car") {
            GetCar(this.props.params.trip_id, this.props.params.trip_id_version, this, this.props.params.product_id);
        }
        if (this.props.params.product_type === "manual-products") {
            GetManual(this.props.params.trip_id, this.props.params.trip_id_version, this, this.props.params.product_id);
        }

        if (this.props.params.product_type === "custom-products") {
            GetCustom(this.props.params.trip_id, this.props.params.trip_id_version, this, this.props.params.product_id);
        }
    }

    confirmBooking(status) {
        const { index, item, dispatch, t } = this.props;
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let request = {
                "id": this.state.product.id,
                "ref": this.state.booking_nb,
                "details": this.state.more_info,
                "price":  this.state.price,
                "status": status
            };
            $.ajax({
                method: "POST",
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/booking/valid_booking/`,
                headers: headers,
                data: JSON.stringify(request),
                success: function (data) {     
                    Notify(t("provider_website.success_change_status"), {status:"success",pos:"top-center"});              
                },
                error: function (error) {
                    Notify(t("provider_website.error_change_status"), {status:"danger",pos:"top-center"});
                }
            });
        }
    }

    render() {
        const { show_language, language, list_currency, dispatch, t } = this.props;
        let name = "-";
        let my_currency = null;
        let price = 0;
        let reference = this.state.product !== null ? (this.state.product.booking_status !== null ? this.state.product.booking_status.item_reference : 0) : 0;
        if (this.state.product !== null) {
            price = parseFloat(this.state.product.prices[this.getPricesId(this.state.product.prices)].purchase_price);
            if (this.state.product.name !== undefined && this.state.product.name !== null) {
                name = this.state.product.name;
            }
            else {
                if (this.state.product.product_type === 0) {
                    name = this.state.product.hotel[0].name;
                }
                if (this.state.product.product_type === 6) {
                    if (this.state.product.segments !== undefined && this.state.product.segments !== null) {
                        name = this.state.product.segments[0].marketing_airline.commercial_name + " : " + this.state.product.segments[0].origin.airport_code + " - " + this.state.product.segments[0].destination.airport_code
                    }
                }
                if (this.state.product.custom_product !== undefined && this.state.product.custom_product.title !== undefined) {
                    name = this.state.product.custom_product.title;
                }
            }            
        my_currency = list_currency.find((currency) => {return currency.id === this.state.product.prices[this.getPricesId(this.state.product.prices)].purchase_currency});
        }
        let img = "/Img/bg-summary-connexion.jpg";
        if (this.state.trip !== null) {
            if (this.state.trip.data[0].destination.data.cover_picture !== undefined && this.state.trip.data[0].destination.data.cover_picture !== null && this.state.trip.data[0].destination.data.cover_picture.url !== null) {
                img = this.state.trip.data[0].destination.data.cover_picture.url;
            } else {
                if (this.state.trip.data[0].destination.data.pictures !== undefined && this.state.trip.data[0].destination.data.pictures !== null && this.state.trip.data[0].destination.data.pictures.length > 0) {
                    if (this.state.trip.data[0].destination.data.pictures.url !== null) {
                        img = this.state.trip.data[0].destination.data.pictures.url;
                    }
                }
            }
        }
        return (
           <div id={"summary"} >
                <div className={"smaller-container-header-pic booking-confirmation-header"} >
                    <img src={ img } className={"ft-cover-img"} />
                </div>                
                <div className={"smaller-header-text-container booking-confirmation-header"}>             
                    <div id={"summary-header-text-container"} className={"absolute-center text-center" }> 
                        <div className={"first-text"} > { t("provider_website.confirm_cancellation") }</div>             
                    </div>                    
                </div>

                <div className={"grey-bg p30 center-content booking-website-container"} >
                    <div id={"header-container-flag"} className={"basic-header-container"} >
                        <div id={"flag-selection"} >
                            <span className={ language !== null ? "pointer mt15 flag-icon flag-icon-" + (language === "en" ? "gb" : language) :  "pointer mt15 flag-icon flag-icon-fr" } onClick={ () => { dispatch(ToggleLanguage(true)); } } />  { t("global.change_language") } 
                            {
                                show_language && (
                                    <div className={"container-flag"} >
                                        <div onClick={ () => { this.setLanguage("fr"); dispatch(ToggleAndSetLanguage(false, "fr")); } } ><i className={"pointer flag-icon flag-icon-fr"} /> { t("global.french") } </div>
                                        <div onClick={ () => { this.setLanguage("en"); dispatch(ToggleAndSetLanguage(false, "gb")); } } ><i className={"pointer flag-icon flag-icon-gb"} /> { t("global.english") } </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={"header-separator"} />
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-6"} >
                        <div>
                            <h1 className={"with-border ft-dark-green mt15"} > { t("provider_website.traveler_infos") } </h1>                  
                            <div className={"row"} >   
                                {
                                    this.state.group.map((traveler, index_traveler) => {
                                        let age = this.state.trip !== null ?  moment.utc(this.state.trip.data[0].end_date).diff(moment.utc(traveler.birth_date), "y") : 0;
                                        return (
                                            <div key={ index_traveler } className={"col-md-12"} > { t("global.adult") + " " + (index_traveler + 1) + " : " + traveler.last_name + " " + traveler.first_name + " " + t("global.born_in") + " " + traveler.birth_date + " (" + age + " " + t("global.years") + ")" } </div>
                                        );                               
                                    })
                                }        
                            </div>
                        </div>
                            <div>
                                <h1 className={"with-border mt15 ft-dark-green"} > { t("provider_website.product_info") } </h1>                  
                                <div className={"row"} >   
                                   <span className={"col-md-12 ft-light-green"} >
                                        { t("provider_website.product_name") } 
                                   </span>      
                                   <span className={"col-md-12"} >
                                        {name}
                                   </span>
                                   <span className={"col-md-12 ft-light-green"} >
                                        { t("provider_website.product_date") }
                                   </span>      
                                   <span className={"col-md-12"} >
                                        { t("cart.from") + " " + (this.state.product !== null ? (moment.utc(this.state.product.start_date).format("YYYY-MM-DD") + " " + t("cart.to") + " " + moment.utc(this.state.product.end_date).format("YYYY-MM-DD")) : null)}
                                   </span>
                                   <span className={"col-md-12 ft-light-green"} >
                                        { t("provider_website.product_hours") }
                                   </span>      
                                   <span className={"col-md-12"} >
                                        { t("provider_website.starting_hours") + " " + (this.state.product !== null ? (moment.utc(this.state.product.start_date).format("HH:mm") + " - " + t("provider_website.end_hours") + " " + moment.utc(this.state.product.end_date).format("HH:mm")) : null)}
                                   </span>
                                   <span className={"col-md-12 ft-light-green"} >
                                        { t("provider_website.internal_ref") }
                                   </span>      
                                   <span className={"col-md-12"} >
                                        { reference } 
                                   </span>    
                                   <span className={"col-md-12 ft-light-green"} >
                                        { t("provider_website.product_price") }
                                   </span>      
                                   <span className={"col-md-12"} >
                                        {
                                            (my_currency && price) && (
                                                Intl.NumberFormat(language, { style: "currency", currency: my_currency.iso_code }).format(Math.round(price))                                                                             
                                            )
                                        }
                                        {
                                            (my_currency === null || price === null) && (
                                                Math.round(price)                                                                             
                                            )
                                        }
                                   </span> 
                                </div>
                            </div>
                        </div>
                        
                         <div className={"col-md-6"}>                            
                                <div className={"btn-orange ft-fit ft-mt10 ft-ml5"} onClick={() => {
                                    this.confirmBooking("CANCELLED");
                                }} >
                                    { t("provider_website.confirm_cancellation") }
                                </div>
                        </div>
                    </div>

                    
                </div>

                
           </div>
        );
    }
}

export default ProviderCancel;
