import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import ReactDOM from "react-dom";
import Fab from "@material-ui/core/Fab";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    markerColor: {
        backgroundColor: 'black',
        color: 'white'
    }
});

function CustomMarker(latlng, map) {
    this.latlng = latlng;
    this.setMap(map);
}

const PoiMap = ({lat, lng}) => {
    const classes = useStyles();
    const [initialized, setInitialized] = useState(false);
    const google = useSelector(state => state.base.google);
    useEffect(() => {
        if (!initialized && google !== null) {
            const mapContainer = document.getElementById('poi-card-map');
            if (mapContainer !== null) {
                let options = {
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    draggable: true,
                    clickableIcons: false,
                    scaleControl: true,
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    center: {lat: lat, lng: lng},
                    zoom: 11,
                    styles: [
                        {
                            featureType: 'poi',
                            stylers: [{visibility: 'off'}]
                        }
                    ]
                };
                const map = new google.maps.Map(mapContainer, options);
                CustomMarker.prototype = new google.maps.OverlayView();
                CustomMarker.prototype.createDiv = function() {
                    this.div = ReactDOM.render(<div><Fab className={ classes.markerColor } size={ "small" }><VisibilityIcon/></Fab></div>, document.createElement("div"));
                    this.div.style.position = "absolute";
                };
                CustomMarker.prototype.appendDivToOverlay = function() {
                    const panes = this.getPanes();
                    if (panes !== null) {
                        panes.overlayImage.appendChild(this.div);
                    }
                };
                CustomMarker.prototype.positionDiv = function() {
                    const projection = this.getProjection();
                    if (projection !== null) {
                        const point = projection.fromLatLngToDivPixel(this.latlng);
                        let offset = 25;
                        if (point) {
                            this.div.style.left = `${point.x - offset}px`;
                            this.div.style.top = `${point.y - offset}px`;
                        }
                    }
                };
                CustomMarker.prototype.draw = function() {
                    if (!this.div) {
                        this.createDiv();
                        this.appendDivToOverlay();
                    }
                    this.positionDiv();
                };
                CustomMarker.prototype.remove = function() {
                    this.setMap(null);
                    if (this.div) {
                        this.div.parentNode.removeChild(this.div);
                        this.div = null;
                    }
                };
                new CustomMarker(new google.maps.LatLng(lat, lng), map);
                setInitialized(true);
            }
        }
    });
    return (
        <div id={ 'poi-card-map' } style={{width: '100%', height:'400px'}}/>
    )
};

export default React.memo(PoiMap);