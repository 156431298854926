import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WithRouterProps } from "react-router";
import { CartConstructionReplaceRoomInputs } from "./CartConstructionReplaceRoomInputs";
import MaterialAccommodationCard from "../Accommodation/MaterialAccommodationCard";
import { CartConstructionReplaceProductContext } from "./utils/cartConstructionReplaceProductContext";
import {
    CartConstructionReplaceProductModalStep
} from "./objects/cartConstructionReplaceProductModalStep";
import { AccommodationSearchResult } from "./objects/accommodationSearchResult";
import { AppState } from "../../Reducers/Reducers";

type Result = {
    accommodation: AccommodationSearchResult,
    destination: number
}


type Props = {
    accommodationParams?: {
        id: number
    },
} & WithRouterProps

export function CartConstructionReplaceAccommodationContent(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const context = useContext(CartConstructionReplaceProductContext);
    const [searchedAccommodation, setSearchedAccommodation] = useState<Result | null>(null);
    const { accommodationParams, ...rest } = props;

    useEffect(() => {
        if (!props.accommodationParams && !searchedAccommodation) {
            context.onHideNextButton();
        } else {
            context.onShowNextButton();
        }
    }, [searchedAccommodation, accommodationParams?.id]);

    useEffect(() => {
        if (searchedAccommodation) {
            const step = itinerary.find((item) => {
                return item.destination?.id === searchedAccommodation?.destination;
            });
            const bounds = JSON.parse(step?.destination?.data?.bounds?.replace(/'/g, '"') ?? '{}');
            dispatch({
                type: "MATERIAL_SET_ACTIVE_DESTINATION",
                payload: {
                    active: step?.id,
                    bounds: {
                        north: bounds.north,
                        east: bounds.east,
                        south: bounds.south,
                        west: bounds.west
                    },
                    zoom: step?.destination?.data?.zoom_level ?? 4,
                    start_date: step?.start_date.split('T')[0],
                    end_date: step?.end_date.split('T')[0],
                    destination: step,
                    reset: true
                }
            });
        }
    }, [searchedAccommodation, itinerary]);

    switch (context.step) {
        case CartConstructionReplaceProductModalStep.INPUTS: {
            return (
                <CartConstructionReplaceRoomInputs
                    searchedAccommodation={searchedAccommodation}
                    onChangeSearchedAccommodation={setSearchedAccommodation}
                />
            );
        }
    }

    return (
        <MaterialAccommodationCard
            {...rest}
            params={
                accommodationParams ??
                { id: searchedAccommodation?.accommodation.id ?? -1 }
            }
        />
    );
}
