import moment from "moment";

export default GetOutOfCart = (itinerary, flight_cart, hotel_cart, manual_cart, poi_cart, cars_cart, transfers_cart) => {
    let tmp_flights = [];
    let tmp_hotels = [];
    let tmp_cars = [];
    let tmp_transfers = [];
    let tmp_manuals = [];
    let tmp_poi = [];
    let tmp_hidden_product = [];
    flight_cart.map((flight) => {
        flight.outbounds.map((outbound) => {
            let found_flight = itinerary.find((step) => {
                return (flight.is_displayed && !flight.is_optional && flight.booking_status !== undefined && ((flight.booking_status !== null && flight.booking_status.status_booking !== "CANCELLED") || flight.booking_status === null) && moment.utc(outbound.legs[0].departure_time, "YYYY-MM-DD").isSame(moment.utc(step.end_date, "YYYY-MM-DD"), "day"))
            });
            if (found_flight === undefined && flight.is_displayed && !flight.is_optional) {
                tmp_flights.push(flight);
            }
        });
    });
    cars_cart.map((car) => {
        let found_car = itinerary.find((step, step_index) => {
            return (step_index !== 0 && step_index !== itinerary.length -1 && car.is_displayed && !car.is_optional && car.booking_status !== undefined && ((car.booking_status !== null && car.booking_status.status_booking !== "CANCELLED") || car.booking_status === null) && ((moment.utc(car.start_date).isSameOrAfter(moment.utc(step.start_date), 'd') && car.start_destination.id === step.destination.id) || (moment.utc(car.start_date).isSameOrBefore(moment.utc(step.start_date), 'd') && moment.utc(car.end_date).isSameOrAfter(moment.utc(step.end_date), 'd'))))
        });
        if (found_car === undefined && car.is_displayed && !car.is_optional) {
            tmp_cars.push(car);
        }
    });
    transfers_cart.map((transfer) => {
        let found_transfer = itinerary.find((step, step_index) => {
            return (step_index !== 0 && step_index !== itinerary.length -1 && transfer.is_displayed && !transfer.is_optional && transfer.booking_status !== undefined && ((transfer.booking_status !== null && transfer.booking_status.status_booking !== "CANCELLED") || transfer.booking_status === null) && moment.utc(transfer.start_date, "YYYY-MM-DD").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD")) && moment.utc(transfer.end_date, "YYYY-MM-DD").isSameOrBefore(moment.utc(step.end_date, "YYYY-MM-DD")))
        });
        if (found_transfer === undefined && transfer.is_displayed && !transfer.is_optional) {
            tmp_transfers.push(transfer);
        }
    });
    manual_cart.map((manual) => {
        let found_manual = itinerary.find((step, step_index) => {
            return (step.step_type !== 'START' && step.step_type !== 'END' && manual.is_displayed && !manual.is_optional && manual.booking_status !== undefined && ((manual.booking_status !== null && manual.booking_status.status_booking !== "CANCELLED") || manual.booking_status === null) && moment.utc(manual.start_date, "YYYY-MM-DD HH:mm").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD HH:mm")) && moment.utc(manual.end_date, "YYYY-MM-DD HH:mm").isSameOrBefore(moment.utc(step.end_date, "YYYY-MM-DD HH:mm")))
        });
        if (manual.product_type === 17 && !manual.is_optional) {
            tmp_manuals.push(manual);
        }
        if (found_manual === undefined && !manual.is_optional) {
            if (manual.is_displayed) {
                if (manual.product_type === 4) {
                    tmp_transfers.push(manual);
                }
                if ([0, 7].includes(manual.product_type)) {
                    tmp_hotels.push(manual);
                }
                if (manual.product_type === 2) {
                    tmp_cars.push(manual);
                }
                if ([11, 12].includes(manual.product_type)) {
                    tmp_poi.push(manual);
                }
            } else {
                tmp_hidden_product.push(manual);
            }
        }
    });
    poi_cart.map((poi) => {
        let found_poi = itinerary.find((step, step_index) => {
            return (step_index !== 0 && step_index !== itinerary.length -1 && poi.is_displayed && !poi.is_optional && poi.booking_status !== undefined && ((poi.booking_status !== null && poi.booking_status.status_booking !== "CANCELLED") || poi.booking_status === null) && moment.utc(poi.start_date, "YYYY-MM-DD").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD")) && moment.utc(poi.start_date).isSameOrBefore(moment.utc(step.end_date)))
        });
        if (found_poi === undefined && poi.is_displayed && !poi.is_optional) {
            tmp_poi.push(poi);
        }
    });
    hotel_cart.map((hotel) => {
        let found_hotel = itinerary.find((step, step_index) => {
            return (step_index !== 0 && step_index !== itinerary.length - 1 && hotel.is_displayed && !hotel.is_optional && hotel.booking_status !== undefined && moment.utc(hotel.start_date, "YYYY-MM-DD").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD")) && moment.utc(hotel.end_date, "YYYY-MM-DD").isSameOrBefore(moment.utc(step.end_date, "YYYY-MM-DD")))
        });
        if (found_hotel === undefined && hotel.is_displayed && !hotel.is_optional) {
            tmp_hotels.push(hotel);
        }
    });
    return {
        flights: tmp_flights,
        hotels: tmp_hotels,
        poi: tmp_poi,
        cars: tmp_cars,
        manuals: tmp_manuals,
        transfers: tmp_transfers,
        hidden_product: tmp_hidden_product
    };
}