//---- Dependencies ----//
import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { Link, History } from "react-router";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

//---- Functions ----//
import GetIconType from "../../ItineraryTypeDetails/Functions/GetIconType.js";
import GetColor from "../../ItineraryTypeDetails/Functions/GetColor.js";

//---- Components ----//

//---- Actions ----//

//---- CSS ----//
import "../../../Style/ItineraryType.css";
import "../../../Style/GlobalAgencies.css";

//---- Class ----//
@connect((store) => {
    return {
        manual_cart: store.summary.manual_cart
    };
})
@withTranslation()
@withRouter
class SummaryStepManual extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {  
        };
    }

     render() {
        const { manual_cart, step, t } = this.props;        
        return (
            <div > 
                {
                    manual_cart.map((manual_product, index_manual_product) => {
                        let displayed_manual_product = null;
                        if (manual_product.step !== null && manual_product.step === step.id) {
                            if (manual_product.product_type !== 2 && manual_product.product_type !== 5 && manual_product.product_type !== 6 && manual_product.product_type !== 13 && manual_product.product_type !== 8) {
                                displayed_manual_product = manual_product;
                            }
                        }
                        if (manual_product.step === null) {
                            if ((moment.utc(manual_product.start_date).isAfter(moment.utc(step.start_date)) || moment.utc(manual_product.start_date).isSame(moment.utc(step.start_date), "d")) && (moment.utc(manual_product.end_date).isBefore(moment.utc(step.end_date)) || moment.utc(manual_product.end_date).isSame(moment.utc(step.end_date), "d"))) {
                                if (manual_product.product_type !== 2 && manual_product.product_type !== 5 && manual_product.product_type !== 6 && manual_product.product_type !== 13 && manual_product.product_type !== 8) {
                                    displayed_manual_product = manual_product;
                                }
                            }                            
                        }
                        let icon = GetIconType(manual_product.product_type);
                        let color = GetColor(manual_product.product_type);
                        let stars = [];
                        if (displayed_manual_product !== null) {                           
                            for (let i = 0; i < displayed_manual_product.stars; i++) {
                                stars.push(<i key={ i } className={ "icon-045-star" }/>);
                            }            
                        }
                        let display_hour = true;
                        let display_one_date = false;
                        if (moment.utc(manual_product.start_date).format("DD-MM-YYYY") === moment.utc(manual_product.end_date).format("DD-MM-YYYY")) {
                            display_one_date = true;
                        }
                        if (moment.utc(manual_product.start_date).format("HH:mm") === "00:00" && moment.utc(manual_product.end_date).format("HH:mm") === "00:00") {
                            display_hour = false;
                        }
                        let duration = null;
                        let duration_in_days = false;
                        let start = moment.utc(manual_product.start_date);
                        let end = moment.utc(manual_product.end_date);

                        let time = moment.duration(end.diff(start));
                        let hours = time.asHours();

                        if (hours >= 24) {
                            duration = end.diff(start, 'days');
                            duration_in_days = true;
                        } else {
                            duration = moment.utc(moment.duration(time, "seconds").asMilliseconds()).format("HH:mm").replace(":", "H");
                            duration_in_days = false;
                        }

                        if (displayed_manual_product !== null) {
                            return (
                                <div key={index_manual_product} className={"step-hotel-main-container" + " " + color }>
                                    <div className={"icon-step-transport-container" + " " + color } >
                                        <i className={"ft-contain-img icon-step-transport-icon icon-" + icon } />
                                    </div>
                                    <div className={"step-hotel-title-container"}>
                                        <span className={"ft-dark-green bold"}>{ displayed_manual_product.name } </span>
                                        <span> { stars } </span>   
                                        {
                                            display_one_date === true && (
                                                <div className={ "italic ft-light-green ft-font-13" }>
                                                    <i className={ "icon-075-calendar pr10" }/>{moment.utc(displayed_manual_product.start_date).format("dddd Do MMMM YYYY") }
                                                    {
                                                        (manual_product.product_type === 12 && duration !== "00H00") && (
                                                            <span>
                                                                { " (" + (duration_in_days === false ? duration : (duration > 1 ? duration + " " + t("global.days") : duration + " " + t("global.day") )) + ")" }
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                            )
                                        } 
                                        {
                                            display_one_date === false && (
                                                <Fragment>
                                                    {
                                                        (displayed_manual_product.start_date !== null && displayed_manual_product.end_date !== null) && (
                                                            <div className={ "italic ft-light-green ft-font-13" }>
                                                                <i className={ "icon-075-calendar pr10" }/>{ t("cart.from") + " " + (display_hour === true ? (moment.utc(displayed_manual_product.start_date).format("DD MMMM, HH:mm") + " " + t("cart.to") + " " + moment.utc(displayed_manual_product.end_date).format("DD MMMM, HH:mm")) : (moment.utc(displayed_manual_product.start_date).format("DD MMMM") + " " + t("cart.to") + " " + moment.utc(displayed_manual_product.end_date).format("DD MMMM"))) }
                                                                {
                                                                    (manual_product.product_type === 12 && duration !== "00H00") && (
                                                                        <span>
                                                                            { " (" + (duration_in_days === false ? duration : (duration > 1 ? duration + " " + t("global.days") : duration + " " + t("global.day") )) + ")" }
                                                                        </span>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        (displayed_manual_product.start_date === null && displayed_manual_product.end_date !== null) && (
                                                            <div className={ "italic ft-light-green ft-font-13" }>
                                                                <i className={ "icon-075-calendar pr10" }/>{ t("cart.from") + " " + (display_hour === true ? (moment.utc(displayed_manual_product.end_date).format("DD MMMM, HH:mm") + " " + t("cart.to") + " " + moment.utc(displayed_manual_product.end_date).format("DD MMMM, HH:mm")) : moment.utc(displayed_manual_product.end_date).format("DD MMMM")) }
                                                                {
                                                                    (manual_product.product_type === 12 && duration !== "00H00") && (
                                                                        <span>
                                                                            { " (" + (duration_in_days === false ? duration : (duration > 1 ? duration + " " + t("global.days") : duration + " " + t("global.day") )) + ")" }
                                                                        </span>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        (displayed_manual_product.start_date !== null && displayed_manual_product.end_date === null) && (
                                                            <div className={ "italic ft-light-green ft-font-13" }>
                                                                <i className={ "icon-075-calendar pr10" }/>{ t("cart.from") + " " + (display_hour === true ? (moment.utc(displayed_manual_product.start_date).format("DD MMMM, HH:mm") + " " + t("cart.to") + " " + moment.utc(displayed_manual_product.start_date).format("DD MMMM, HH:mm")) : moment.utc(displayed_manual_product.start_date).format("DD MMMM")) }
                                                                {
                                                                    (manual_product.product_type === 12 && duration !== "00H00") && (
                                                                        <span>
                                                                            { " (" + (duration_in_days === false ? duration : (duration > 1 ? duration + " " + t("global.days") : duration + " " + t("global.day") )) + ")" }
                                                                        </span>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    } 
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                    <div className={"ft-no-margin row"} >
                                        <div className={"col-md-5 col-12 no-padding step-hotel-container-img mt15"} >
                                            <img className={"ft-cover-img"} src={(displayed_manual_product.picture !== null) ? (displayed_manual_product.picture.thumbnail_little !== null ? displayed_manual_product.picture.thumbnail_little : displayed_manual_product.picture.url ) : "/Img/accommodation_default.jpg"} />
                                        </div>
                                        <div className={"col-md-6 no-padding step-hotel-right-container mt15"} >
                                            <div>
                                                <h4 className={"ft-dark-green col-md-12"} > { t("footer.about") } </h4>
                                                <div className={"step-hotel-description col-md-12"} > { displayed_manual_product.description } </div>
                                            </div>
                                            {
                                                displayed_manual_product.product_type === 0 && (
                                                    <Fragment>
                                                        {
                                                            (displayed_manual_product.address !== null) && (
                                                                <span className={ "col-md-12 italic ft-mt5" }>
                                                                        <i className={ "icon-028-pin pr10" }/>{ displayed_manual_product.address }
                                                                </span>
                                                            )
                                                        }
                                                        <div className={"col-md-12 ft-mt5"}>
                                                            <span className={""} >
                                                                <i className={(displayed_manual_product.breakfast_included !== undefined && displayed_manual_product.breakfast_included !== null && displayed_manual_product.breakfast_included) ? "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view green ft-pr5" : "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view ft-pr5"}/>
                                                                <span className={(displayed_manual_product.breakfast_included !== undefined && displayed_manual_product.breakfast_included !== null && displayed_manual_product.breakfast_included) ? "ft-green" : ""} > {
                                                                    (displayed_manual_product.breakfast_included !== undefined && displayed_manual_product.breakfast_included !== null && displayed_manual_product.breakfast_included) ? t("accommodation.breakfast_included") : t("accommodation.breakfast_not_included")
                                                                } </span>
                                                            </span>
                                                            {
                                                                (displayed_manual_product.lunch_included !== undefined && displayed_manual_product.lunch_included !== null && displayed_manual_product.lunch_included) && (
                                                                    <span className={"ml15"} >
                                                                        <i className={(displayed_manual_product.lunch_included !== undefined && displayed_manual_product.lunch_included !== null && displayed_manual_product.lunch_included) ? "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view green ft-pr5" : "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view ft-pr5"}/>
                                                                        <span className={(displayed_manual_product.lunch_included !== undefined && displayed_manual_product.lunch_included !== null && displayed_manual_product.lunch_included) ? "ft-green" : ""} > {
                                                                            (displayed_manual_product.lunch_included !== undefined && displayed_manual_product.lunch_included !== null && displayed_manual_product.lunch_included) ? t("accommodation.lunch_included") : t("accommodation.lunch_not_included")
                                                                        } </span>
                                                                    </span>
                                                                )
                                                            }
                                                            {
                                                                (displayed_manual_product.dinner_included !== undefined && displayed_manual_product.dinner_included !== null && displayed_manual_product.dinner_included) && (
                                                                    <span className={"ml15"} >
                                                                        <i className={(displayed_manual_product.dinner_included !== undefined && displayed_manual_product.dinner_included !== null && displayed_manual_product.dinner_included) ? "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view green ft-pr5" : "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view ft-pr5"}/>
                                                                        <span className={(displayed_manual_product.dinner_included !== undefined && displayed_manual_product.dinner_included !== null && displayed_manual_product.dinner_included) ? "ft-green" : ""} > {
                                                                            (displayed_manual_product.dinner_included !== undefined && displayed_manual_product.dinner_included !== null && displayed_manual_product.dinner_included) ? t("accommodation.dinner_included") : t("accommodation.dinner_not_included")
                                                                        } </span>
                                                                    </span>
                                                                )
                                                            }
                                                        </div>
                                                    </Fragment>
                                                )
                                            }
                                            {
                                                displayed_manual_product.custom_information !== null && (
                                                    <div>
                                                        <div className={"comment-title-hotel ft-mt5"} >
                                                            <svg x="0px" y="0px"
                                                                 width="15px" height="15px" viewBox="0 0 511.626 511.626"
                                                                 >
                                                            <g>
                                                                <path className={"to-comment-icon"} d="M477.371,127.44c-22.843-28.074-53.871-50.249-93.076-66.523c-39.204-16.272-82.035-24.41-128.478-24.41
                                                                    c-34.643,0-67.762,4.805-99.357,14.417c-31.595,9.611-58.812,22.602-81.653,38.97c-22.845,16.37-41.018,35.832-54.534,58.385
                                                                    C6.757,170.833,0,194.484,0,219.228c0,28.549,8.61,55.3,25.837,80.234c17.227,24.931,40.778,45.871,70.664,62.811
                                                                    c-2.096,7.611-4.57,14.846-7.426,21.693c-2.855,6.852-5.424,12.474-7.708,16.851c-2.286,4.377-5.376,9.233-9.281,14.562
                                                                    c-3.899,5.328-6.849,9.089-8.848,11.275c-1.997,2.19-5.28,5.812-9.851,10.849c-4.565,5.048-7.517,8.329-8.848,9.855
                                                                    c-0.193,0.089-0.953,0.952-2.285,2.567c-1.331,1.615-1.999,2.423-1.999,2.423l-1.713,2.566c-0.953,1.431-1.381,2.334-1.287,2.707
                                                                    c0.096,0.373-0.094,1.331-0.57,2.851c-0.477,1.526-0.428,2.669,0.142,3.433v0.284c0.765,3.429,2.43,6.187,4.998,8.277
                                                                    c2.568,2.092,5.474,2.95,8.708,2.563c12.375-1.522,23.223-3.606,32.548-6.276c49.87-12.758,93.649-35.782,131.334-69.097
                                                                    c14.272,1.522,28.072,2.286,41.396,2.286c46.442,0,89.271-8.138,128.479-24.417c39.208-16.272,70.233-38.448,93.072-66.517
                                                                    c22.843-28.062,34.263-58.663,34.263-91.781C511.626,186.108,500.207,155.509,477.371,127.44z"/>
                                                            </g>
                                                            </svg>
                                                            <h4 className={"ft-orange col-md-12 ft-mt5 "} > 
                                                                { t("summary.expert_remark") }
                                                            </h4>
                                                        </div>
                                                        <div className={"step-hotel-description col-md-12 ft-mt5"} >                                                        
                                                            { displayed_manual_product.custom_information }
                                                        </div>
                                                    </div>
                                                )
                                            }     
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })                        
                }
            </div>
        );
    }
}

export default SummaryStepManual;
