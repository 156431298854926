export function ResetState() {
    return {
        type: "FLUSH_REMOVE_STATE",
    }
}
export function SetItineraryStep(steps) {
    return {
        type: "CARS_SET_STEPS",
        payload: {
        	steps: steps
        }
    }
}
export function SetSelectedStep(selected_step) {
    return {
        type: "CARS_SET_SELECTED_STEP",
        payload: {
            selected_step: selected_step
        }
    }
}
export function SetSelectedDestniation(selected_step) {
    return {
        type: "CARS_SET_SELECTED_NEW_DEST",
        payload: {
        	selected_step: selected_step
        }
    }
}
export function SetLocationPoint(location_point) {
    return {
        type: "CARS_SET_LOCATION_POINT",
        payload: {
        	location_point: location_point
        }
    }
}
export function SetAgency(agency, index, type) {
    return {
        type: "CARS_SET_AGENCY",
        payload: {
        	agency: agency,
        	index: index,
        	type: type
        }
    }
}
export function SelectedAllStep(select_all) {
    return {
        type: "CARS_SELECT_ALL_STEP",
        payload: {
        	select_all: select_all
        }
    }
}
export function ToggleAllStep(select_all) {
    return {
        type: "CARS_TOGGLE_ALL_STEP",
        payload: {
        	select_all: select_all
        }
    }
}
export function SetCarsNumber(cars_nbr, step_index, add) {
    return {
        type: "CARS_SET_CARS_NUMBER",
        payload: {
        	cars_nbr: cars_nbr,
        	step_index: step_index,
        	type: add
        }
    }
}
export function SetDriverAge(age, step_index, driver) {
    return {
        type: "CARS_SET_DRIVER_AGE",
        payload: {
        	driver_age: age,
        	driver: driver,
        	step_index: step_index
        }
    }
}
export function SetInputFocus(focus) {
    return {
        type: "CARS_SET_INPUT_FOCUS",
        payload: {
        	focus: focus
        }
    }
}
export function SetHours(hours, index, type) {
    return {
        type: "CARS_SET_HOURS",
        payload: {
        	hours: parseInt(hours),
        	index: index,
        	type: type
        }
    }
}
export function SetMinutes(minutes, index, type) {
    return {
        type: "CARS_SET_MINUTES",
        payload: {
			minutes: parseInt(minutes),
        	index: index,
        	type: type
        }
    }
}
export function SetStepInfo(step_info) {
    return {
        type: "CARS_SET_STEP_INFO",
        payload: {
        	step_info: step_info
        }
    }
}
export function ChangeFocus(focus, index, type) {
    return {
        type: "CARS_CHANGE_FOCUS",
        payload: {
        	focus: focus,
        	index: index,
        	type: type
        }
    }
}
export function SetOriginDate(date, index) {
    return {
        type: "CARS_SET_ORIGIN_DATE",
        payload: {
        	date: (date === null ? null : date.format()),
        	index: index
        }
    }
}
export function SetReturnDate(date, index) {
    return {
        type: "CARS_SET_RETURN_DATE",
        payload: {
            date: (date === null ? null : date.format()),
            index: index
        }
    }
}
export function ToggleServiceModal(open_modal) {
    return {
        type: "CARS_TOGGLE_SERVICE_MODAL",
        payload: {
            open_modal: open_modal,
        }
    }
}
export function ToggleAgenciesModal(open_agencies_modal) {
    return {
        type: "CARS_TOGGLE_AGENCIES_MODAL",
        payload: {
            open_agencies_modal: open_agencies_modal,
        }
    }
}
export function SetServiceModal(car_services, contract_name, contract_logo, notice) {
    return {
        type: "CARS_SET_SERVICES",
        payload: {
            car_services: car_services,
            contract_name: contract_name,
            contract_logo: contract_logo,
            important_notice: notice
        }
    }
}
export function SetAgenciesModal(selected_car) {
    return {
        type: "CARS_SET_AGENCIES_MODAL",
        payload: {
            selected_car: selected_car,
        }
    }
}
export function SetCarsResults(cars_results) {
    return {
        type: "CARS_SET_CARS_RESULTS",
        payload: {
            cars_results: cars_results
        }
    }
}
export function AllCars(flag) {
    return {
        type: "CARS_ALL_CARS",
        payload: {
            flag: flag
        }
    }
}
export function SetIndexLocation(index) {
    return {
        type: "CARS_SET_LOCATION_INDEX",
        payload: {
            index: index
        }
    }
}
export function SetCopyIndexLocation(index) {
    return {
        type: "CARS_SET_COPY_LOCATION_INDEX",
        payload: {
            index: index
        }
    }
}
export function SetRedirectionFlag(flag) {
    return {
        type: "CARS_SET_REDIRECTION_FLAG",
        payload: {
            redirection_flag: flag
        }
    }
}
export function SetCarsErrors(error, index) {
    return {
        type: "CARS_SET_ERROR",
        payload: {
            cars_errors: error,
            index: index
        }
    }
}
export function RemoveCarsError(index, error) {
    return {
        type: "CARS_REMOVE_ERROR",
        payload: {
            cars_errors: error,
            index: index
        }
    }
}
export function SetCarsCategories(categories) {
    return {
        type: "CARS_SET_CATEGORIES",
        payload: {
            categories: categories,
        }
    }
}
export function SetTotalCars(total_cars) {
    return {
        type: "CARS_SET_TOTAL_CARS",
        payload: {
            total_cars: total_cars,
        }
    }
}
export function FiltersCars(provider, category, sub_name) {
    return {
        type: "CARS_FILTERS_CARS",
        payload: {
            category: category,
            provider: provider,
            sub_name: sub_name
        }
    }
}
export function InitCarsResult(index_location, index_car, status) {
    return {
        type: "CARS_INIT_CARS_RESULTS",
        payload: {
            index_location: index_location,
            index_car: index_car,
            status: status
        }
    };
}
export function ClearData() {
    return {
        type: "CARS_CLEAR_DATA"
    };
}
export function DisplayCars(display_cars) {
    return {
        type: "CARS_DISPLAY_CARS",
        payload: {
            display_cars: display_cars
        }
    }
}
export function SetVehiclesPriceError(error) {
    return {
        type: "CARS_VEHICLES_PRICE_ERROR",
        payload: {
            error: error
        }
    }
}
export function SetFilters(filters) {
    return {
        type: "CARS_SET_FILTERS",
        payload: {
            filters: filters
        }
    }
}
export function ChangeFilter(key, elem_index, flag) {
    return {
        type: "CARS_CHANGE_FILTER",
        payload: {
            key: key,
            elem_index: elem_index,
            flag: flag
        }
    }
}
export function SelectedCar(car) {
    return {
        type: "CARS_SELECTED_CAR",
        payload: {
            selected_car: car
        }
    }
}
export function SetFetchOption(status) {
    return {
        type: "CARS_SET_OPTION_STATUS",
        payload: {
            status: status
        }
    };
}
export function ChangeCarPack(step_info, selected_car, special_equipments, cart_id, cart_index, status, from_cart, contract_name) {
    return {
        type: "CARS_CHANGE_CAR_PACK",
        payload: {
            selected_car: selected_car,
            step_info: step_info,
            special_equipments: special_equipments,
            cart_id: cart_id,
            cart_index: cart_index,
            status: status,
            from_cart: from_cart,
            contract_name: contract_name
        }
    }
}
export function PackError() {
    return {
        type: "CARS_PACK_ERROR"
    }
}
export function GetContract(contract) {
    return {
        type: "CARS_SET_CONTRACT",
        payload: {
            contract: contract
        }
    };
}
export function ResetContractStatus() {
    return {
        type: "CARS_RESET_CONTRACT_STATUS",
    };
}
export function SelectedContract(contract_id) {
    return {
        type: "CARS_SELECTED_CONTRACT",
        payload: {
            contract_id: contract_id
        }
    }
}
export function EditCarsCartMargin(edit, index) {
    return {
        type: "CARS_SET_MANUAL_MARGIN_EDIT",
        payload: {
            edit: edit,
            index: index
        }
    }
}
export function CarAddToCart(car)  {
    return {
        type: "CARS_ADD_TO_CART",
        payload: {
            car: car
        }
    }
}
export function RemoveCarFromCart(car_index)  {
    return {
        type: "CARS_REMOVE_CART",
        payload: {
            car_index: car_index
        }
    }
}
export function RemoveCarFromCurrentCart(car_index)  {
    return {
        type: "CARS_REMOVE_CURRENT_CART",
        payload: {
            car_index: car_index
        }
    }
}
export function EditCarCart(car) {
    return {
        type: "CARS_EDIT_CART",
        payload: {
            car: car
        }
    }
}
export function SetCarsCart(cars) {
    return {
        type: "CARS_SET_CARS_CART",
        payload: {
            cars: cars
        }
    }
}
export function GetOptions(options) {
    return {
        type: "CARS_SET_AGENCY_OPTIONS",
        payload: {
            agency_options: options
        }
    }
}
export function SelectedOption(option_index, flag, option_id) {
    return {
        type: "CARS_SELECT_AGENCY_OPTIONS",
        payload: {
            option_index: option_index,
            flag: flag,
            option_id: option_id
        }
    }
}
export function SelectedStep(selected_step) {
    return {
        type: "CARS_SELECTED_STEP",
        payload: {
            selected_step: selected_step
        }
    }
}
export function ShowInput(show_input) {
    return {
        type: "CARS_SHOW_INPUT",
        payload: {
            show_input: show_input
        }
    }
}
export function SetCarIndex(car_index) {
    return {
        type: "CARS_SET_CAR_INDEX",
        payload: {
            car_index: car_index
        }
    }
}
export function SetCopyCarIndex(car_index) {
    return {
        type: "CARS_SET_COPY_CAR_INDEX",
        payload: {
            car_index: car_index
        }
    }
}
export function AddSelectedCar(car, contract) {
    return {
        type: "CARS_ADD_CAR_TO_CURRENT_CART",
        payload: {
            car: car,
            contract: contract
        }
    }
}
export function ResetCurrentCart() {
    return {
        type: "CARS_RESET_CURRENT_CART",
    }
}
export function SetRequestStatus(status) {
    return {
        type: "CARS_REQUEST_STATUS",
        payload: {
            status: status
        }
    }
}
export function SetAgencyType(type) {
    return {
        type: "CARS_SET_AGENCY_TYPE",
        payload: {
            type: type
        }
    }
}
export function SetAgencyLocation(type, location) {
    return {
        type: "CARS_SET_AGENCY_LOCATION",
        payload: {
            type: type,
            location: location
        }
    }
}
export function RemoveAgencyError(error) {
    return {
        type: "CARS_REMOVE_AGENCY_ERROR",
        payload: {
            error: error
        }
    }
}
export function SetAgencyErrors(error) {
    return {
        type: "CARS_SET_AGENCY_ERROR",
        payload: {
            error: error
        }
    }
}
export function SetQuantity(quantity, option_id) {
    return {
        type: "CARS_SET_OPTION_QUANTITY",
        payload: {
            quantity: Number(quantity),
            option_id: option_id
        }
    }
}
export function SetCarsPanel(panel) {
    return {
        type: "CARS_SET_CARS_PANEL",
        payload: {
            cars_panel: panel
        }
    };
}
export function CheckAvailabilityStatus(status) {
    return {
        type: "CARS_CHECK_AVAILABILITY_STATUS",
        payload: {
            status: status
        }
    };
}
export function SetReadOnly(flag, type, index) {
    return {
        type: "CARS_SET_READ_ONLY",
        payload: {
            flag: flag,
            type: type,
            index: index
        }
    };
}
export function SetContractError(error) {
    return {
        type: "CARS_CONTRACT_ERROR",
        payload: {
            contract_error: error
        }
    }
}
export function ToggleDetail(index) {
    return {
        type: "CARS_TOGGLE_DETAIL",
        payload: {
            index: index
        }
    }
}
export function TogglePreDetail(index) {
    return {
        type: "CARS_TOGGLE_PRE_DETAIL",
        payload: {
            index: index
        }
    }
}
export function ToggleCartDetail(index) {
    return {
        type: "CARS_TOGGLE_CART_DETAIL",
        payload: {
            index: index
        }
    }
}
export function ItineraryError(flag) {
    return {
        type: "CARS_ITINERARY_ERROR",
        payload: {
            itinerary_error: flag
        }
    }
}
export function CarSetDetail(index, type) {
    return {
        type: "CARS_SET_DETAIL",
        payload: {
            detailed_index: index,
            type: type
        }
    }
}