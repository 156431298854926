// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Core & Lab
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Box, Tooltip, Typography, makeStyles } from '@material-ui/core';

const useStyle = makeStyles({
    label: {
        width: "100%",
    },
  });

const CartAssistancePremium = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyle();
    const assistance_package = useSelector(state => state.cart.assistance_package);
    const onToggleAssistance = () => {
        dispatch({type: 'CART_ASSISTANCE_PACKAGE', payload: 'Premium'});
    };
    const Label = (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className={classes.label}
        >
            <Typography>{t('cart-material.assistance-premium-option')}</Typography>
            <Tooltip placement={"right"} title={'Inclus tous les services du forfait Plus ainsi qu’une ligne téléphonique prioritaire, une protection bagage en cas de retard, dommage ou perte, un appel de bienvenue et d’au revoir ainsi que la reconfirmation automatique des transferts, hébergements et activités avant le départ de vos clients.'}>
                <InfoIcon style={{marginLeft: 5}}/>
            </Tooltip>
        </Box>
    );
    return (
        <FormControl fullWidth>
            <FormControlLabel control={ <Checkbox checked={ assistance_package === 'Premium' } onChange={ onToggleAssistance } color={ 'primary' }/> } label={ Label }/>
        </FormControl>
    );
};

export default CartAssistancePremium;