import HasPermission from "../../Common/Functions/HasPermission";
import { isProductPackaged } from "./isProductPackaged";
import { BookingStatus } from "../../Itinerary/objects/bookingStatus";
import { User } from "../../Menu/MaterialTripList/objects/user";
import { TripVersion } from "../../Menu/MaterialTripList/objects/tripVersion";
import { AppState } from "../../../Reducers/Reducers";

type Options = {
    item: Item,
    user: User | null,
    permissions: AppState['user']['permissions'],
    trip: TripVersion | null
}

type Product = {
    auto_product: {} | null,
    deletion_restriction: boolean,
    has_deletion_restriction: boolean,
    booking_status: BookingStatus | null,
    is_optional: boolean
}

type Item = {
    type: 'car' | 'poi' | 'assistance' | 'accommodation' | 'transfer' | 'flight' | 'manual',
    product: Product
}

export function isDeleteButtonEnabled(options: Options): boolean {
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    if (
        isProductPackaged({
            product: options.item.product as any,
            stackInfos: options.trip?.stack_info ?? null,
            connected: true
        }) &&
        !options.item.product.is_optional
    ) {
        return false;
    }

    if (
        options.item.type === 'accommodation' &&
        (options.user?.client_full?.type !== 2 || !options.item.product.deletion_restriction) &&
        (
            options.item.product.booking_status === null ||
            options.item.product.booking_status.status_booking === 'ERROR' ||
            (quotation_code !== 'marcovasco' && options.item.product.booking_status.status_booking === 'CANCELLED')
        )
    ) {
        return true;
    } else if (
        options.item.type === 'car' &&
        (
            options.user?.client_full?.type !== 2 ||
            !options.item.product.deletion_restriction
        ) &&
        (
            options.item.product.booking_status === null ||
            options.item.product.booking_status.status_booking === 'ERROR' ||
            (quotation_code !== 'marcovasco' && options.item.product.booking_status.status_booking === 'CANCELLED')
        )
    ) {
        return true;
    } else if (
        options.item.type === 'transfer' &&
        (options.user?.client_full?.type !== 2 || !options.item.product.deletion_restriction) &&
        (
            options.item.product.booking_status === null ||
            options.item.product.booking_status.status_booking === 'ERROR' ||
            (quotation_code !== 'marcovasco' && options.item.product.booking_status.status_booking === 'CANCELLED')
        )
    ) {
        return true;
    } else if (
        options.item.type === 'poi' &&
        (options.user?.client_full?.type !== 2 || !options.item.product.deletion_restriction) &&
        (
            options.item.product.booking_status === null ||
            options.item.product.booking_status.status_booking === 'ERROR' ||
            (quotation_code !== 'marcovasco' && options.item.product.booking_status.status_booking === 'CANCELLED')
        )
    ) {
        return true;
    } else if (
        options.item.type === 'flight' &&
        (options.user?.client_full?.type !== 2 || !options.item.product.deletion_restriction) &&
        (
            options.item.product.booking_status === null ||
            options.item.product.booking_status.status_booking === 'ERROR' ||
            (quotation_code !== 'marcovasco' && options.item.product.booking_status.status_booking === 'CANCELLED')
        )
    ) {
        return true;
    } else if (
        options.item.type === 'assistance' &&
        (options.user?.client_full?.type !== 2 || !options.item.product.deletion_restriction) &&
        (
            options.item.product.booking_status === null ||
            options.item.product.booking_status.status_booking === 'ERROR' ||
            (quotation_code !== 'marcovasco' && options.item.product.booking_status.status_booking === 'CANCELLED')
        )
    ) {
        return true;
    } else if (
        options.item.type === 'manual' &&
        (
            options.user?.groups?.some(group => (group as {is_admin?: boolean}).is_admin === true) ||
            HasPermission(options.permissions, "delete_restricted") ||
            (options.item.product.auto_product === null || !options.item.product.has_deletion_restriction)
        )
    ) {
        return true;
    }

    return false;
}
