import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { differenceInCalendarDays } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { sortItinerary } from "../../Itinerary/utils/sortItinerary";
import { getItineraryDayDifference } from "../../Menu/MaterialTripList/utils/getItineraryDifference";
import { AppState } from "../../../Reducers/Reducers";

type Callback = (startDate: string, endDate?: string) => number[]

export function useProductDays(): Callback {
    const itineraryList = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const itinerary = useMemo(() => {
        return itineraryList.filter((item) => {
            return item.step_type === 'STEP';
        }).sort(sortItinerary);
    }, [itineraryList]);
    return useCallback(
        (startDate, endDate) => {
            if (
                !window.moment.utc(startDate).isValid() ||
                (endDate && !window.moment.utc(endDate).isValid())
            ) {
                return [];
            }
            const daysDifference = getItineraryDayDifference(
                {
                    start_date: startDate,
                    end_date: endDate ?? startDate
                }
            );
            return daysDifference.map((item) => {
                return differenceInCalendarDays(
                    item,
                    utcToZonedTime(itinerary[0]?.start_date ?? item, 'Etc/UTC')
                ) + 1;
            }).sort((a, b) => a - b);
        },
        [itineraryList, itinerary]
    );
}
