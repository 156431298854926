//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Container from '@material-ui/core/Container';

import CheckBeforeRequest from '../../Common/CheckBeforeRequest';
import { SetCustomFilters } from '../../../Actions/Menu';
import FilterCard from './FilterCard';

const useStyles = makeStyles(theme => ({
    fontSize12: {
        fontSize: "12px !important",
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
}));

const OrderFiltersDialog = ({orderFiltersOpen, handleEditFiltersOrder}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const customFilters = useSelector(store => store.menu.customFilters);
    const sortFilters = useSelector(store => store.menu.sortFilters);

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const handleReset = () => {
        let new_filter = customFilters.filter(filter => filter.filters_type === 'company');
        dispatch({type: "MENU_SET_SORT_FILTERS", payload: [...new_filter]});
        handleEditFiltersOrder();
    };
    const handleSave = () => {
        let request_sort = [];
        let tmp_sort_filters = [...sortFilters]
        tmp_sort_filters.forEach((filter, filter_index) => request_sort.push({id: filter.id, order: filter_index + 1}));
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url:  `${API_HREF}client/${window.id_owner}/trip-filter-preference/array_patch/`,
            data: request_sort
        }).then((response) => {
            let update_filters = customFilters.filter(filter => filter.filters_type !== 'company');
            tmp_sort_filters.forEach((filter, filter_index) => filter.order = filter_index + 1);
            update_filters = update_filters.concat(tmp_sort_filters);
            dispatch(SetCustomFilters(update_filters));
            handleEditFiltersOrder();
        }).catch((error) => {
            console.log('trip filter order error:', error);
        });
    };
    useEffect(() => {
        if (customFilters.length !== 0) {
            let new_filter = customFilters.filter(filter => filter.filters_type === 'company');
            dispatch({type: "MENU_SET_SORT_FILTERS", payload: [...new_filter]});
            // dispatch({type: "MENU_SET_SORT_FILTERS", payload: customFilters.filter(filter => filter.filters_type === 'company')});
        }
    }, [customFilters.length]);

    const renderCard = useCallback((filter, filter_index) => {
        return <FilterCard key={filter.id} filter={filter} id={filter.id} index={filter_index}/>;
    }, []);
    return (
        <Dialog open={orderFiltersOpen} onClose={handleReset} fullWidth maxWidth={ "md" }>
            <DialogTitle>{ t("menu.user_management.order_filters") }</DialogTitle>
            <DialogContent>
                <Container>
                    <Grid container spacing={ 2 } style={{margin: '20px 0 20px 0'}}>
                        <DndProvider backend={ HTML5Backend }>
                            <List component={"nav"}>
                            { sortFilters.map((filter, filter_index) => renderCard(filter, filter_index)) }
                            </List>
                        </DndProvider>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} color={"primary"} fullWidth className={`${classes.fontSize12} ${classes.noWrap}`} onClick={handleReset}>{t("global.cancel")}</Button>
                <Button variant={"outlined"} color={"primary"} fullWidth className={`${classes.fontSize12} ${classes.noWrap}`} onClick={handleSave}>{t("global.validate")}</Button>
            </DialogActions>
        </Dialog>
    )
}
export default OrderFiltersDialog;