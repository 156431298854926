import Axios from "axios";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";
import { ArrowBackOutlined, Close } from "@mui/icons-material";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment-timezone";
import { isBoolean } from "lodash";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import { checkIfOnRoad } from "./utils/checkIfOnRoad";
import { findProductAssociatedItineraryStep } from "./utils/findProductAssociatedItineraryStep";
import GetCookie from "../Common/Functions/GetCookie";
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import { PoiCart } from "../Itinerary/objects/poiCart";
import { CarCart } from "../Itinerary/objects/carCart";
import { TransferCart } from "../Itinerary/objects/transferCart";
import { AccommodationCart } from "../Itinerary/objects/accommodationCart";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    initialStartDate?: string,
    minStartDate?: Moment,
    maxStartDate?: Moment,
    onClose: () => void,
    onGoBack?: () => void,
    enablePreviousButton?: boolean
} & ({
    type: 'normal',
    product: PoiCart | CarCart | TransferCart | AccommodationCart,
} | {
    type: 'manual',
    product: ManualProduct
})

export function CartClientTimeModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const [inputs, setInputs] = useState<{
        startDate: Moment | null,
        endDate: Moment | null
    }>({
        startDate: null,
        endDate: null
    });
    const [clientStartTimeError, setClientStartTimeError] = useState<boolean>(false);
    const [clientEndTimeError, setClientEndTimeError] = useState<boolean>(false);

    function onChangeInput<K extends keyof typeof inputs>(key: K, value: typeof inputs[K]): void {
        setInputs((state) => ({
            ...state,
            [key]: value
        }));
    }

    const onValidateTime = () => {
        if (
            !inputs.startDate?.isValid() ||
            (
                inputs.startDate &&
                props.minStartDate &&
                inputs.startDate.isBefore(props.minStartDate, 'minutes')
            ) ||
            (
                inputs.startDate &&
                props.maxStartDate &&
                inputs.startDate.isAfter(props.maxStartDate, 'minutes')
            )
        ) {
            setClientStartTimeError(true);
            return;
        }
        if (!inputs.endDate?.isValid() && props.product.product_type !== 12 && props.product.product_type !== 11) {
            setClientEndTimeError(true);
            return;
        }
        if (
            props.product.product_type === 0 &&
            inputs.startDate.clone().startOf('day').isSame(inputs.endDate?.clone().startOf('day'), 'day')
        ) {
            setClientStartTimeError(true);
            setClientEndTimeError(true);
            return;
        }
        const { headers } = CheckBeforeRequest();
        let url = `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/`;
        if (props.type === 'manual') {
            url += 'manual-products';
        } else if ((props.product.product_type === 12 || props.product.product_type === 11)) {
            url += 'custom-products';
        } else if (props.product.product_type === 4 && isBoolean(props.product.is_custom)) {
            if (props.product.is_custom) {
                url += 'custom-products';
            } else {
                url += 'transfers';
            }
        } else if (props.product.product_type === 2) {
            url += 'car';
        } else if ([0, 7].includes(props.product.product_type)) {
            url += 'accommodation';
        }
        let diffInSeconds = 0;

        if (props.type === 'normal') {
            diffInSeconds = window.moment.utc(props.product.provider_end_date).diff(
                window.moment.utc(props.product.provider_start_date),
                'seconds'
            );
        } else {
            diffInSeconds = window.moment.utc(props.product.end_date).diff(
                window.moment.utc(props.product.start_date),
                'seconds'
            );
        }

        if (props.type === 'manual') {
            Axios({
                method: 'PATCH',
                headers: headers,
                url: `${url}/${props.product.id}/`,
                data: {
                    start_date: inputs.startDate.isValid() ?
                        inputs.startDate.toISOString() :
                        undefined,
                    end_date: inputs.endDate?.isValid() ?
                        inputs.endDate.toISOString() :
                        undefined
                }
            }).then(response => {
                dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
                props.onClose();
            }).catch(error => {
                console.log(error);
            });
        } else if ([0, 7].includes(props.product.product_type)) {
            const product = props.product as AccommodationCart;
            Promise.all(
                product.rooms.map((room) => {
                    return Axios({
                        method: 'PATCH',
                        headers: headers,
                        url: `${url}/${room.id}/`,
                        data: {
                            client_start_date: inputs.startDate?.isValid() ?
                                inputs.startDate.format('YYYY-MM-DD') :
                                undefined,
                            client_start_time: inputs.startDate?.isValid() ?
                                inputs.startDate.format('HH:mm') :
                                undefined,
                            client_end_date: inputs.endDate?.isValid() ?
                                inputs.endDate.format('YYYY-MM-DD') :
                                inputs.startDate?.clone().add(diffInSeconds, 'seconds').format('YYYY-MM-DD'),
                            client_end_time: inputs.endDate?.isValid() ?
                                inputs.endDate.format('HH:mm') :
                                inputs.startDate?.clone().add(diffInSeconds, 'seconds').format('HH:mm')
                        }
                    }).then(response => {
                        dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                    }).catch(error => {
                        console.log(error);
                    });
                })
            ).then(() => {
                props.onClose();
            });
        } else {
            let endDestination: number | undefined;
            if ([11, 12].includes(props.product.product_type)) {
                const steps = itinerary.filter((item) => {
                    return item.step_type === 'STEP';
                }).sort(sortItinerary);
                const associatedStep = findProductAssociatedItineraryStep({
                    type: 'start',
                    itinerary: steps,
                    product: props.product,
                    granularity: 'seconds'
                });
                if (associatedStep) {
                    const onRoadSteps = checkIfOnRoad({
                        type: 'poi',
                        itinerary: steps,
                        step: associatedStep,
                        product: {
                            ...props.product,
                            start_date: inputs.startDate.toISOString(),
                            end_date: inputs.endDate?.toISOString() ?? props.product.end_date
                        },
                        noEndDestinationCheck: true
                    });
                    if (onRoadSteps) {
                        endDestination = onRoadSteps[0].destination?.id === props.product.start_destination?.id ?
                            onRoadSteps[1].destination?.id :
                            onRoadSteps[0].destination?.id;
                    }
                }
            }
            Axios({
                method: 'PATCH',
                headers: headers,
                url: `${url}/${props.product.id}/`,
                data: {
                    client_start_date: inputs.startDate.isValid() ?
                        inputs.startDate.format('YYYY-MM-DD') :
                        undefined,
                    client_start_time: inputs.startDate.isValid() ?
                        inputs.startDate.format('HH:mm') :
                        undefined,
                    client_end_date: inputs.endDate?.isValid() ?
                        inputs.endDate.format('YYYY-MM-DD') :
                        inputs.startDate.clone().add(diffInSeconds, 'seconds').format('YYYY-MM-DD'),
                    client_end_time: inputs.endDate?.isValid() ?
                        inputs.endDate.format('HH:mm') :
                        inputs.startDate.clone().add(diffInSeconds, 'seconds').format('HH:mm'),
                    end_destination: endDestination
                }
            }).then(response => {
                if ((props.product.product_type === 12 || props.product.product_type === 11)) {
                    dispatch({ type: 'POI_EDIT_CART_BY_ID', payload: response.data });
                } else if (props.product.product_type === 4) {
                    dispatch({ type: 'TRANSFER_EDIT_CART_BY_ID', payload: response.data });
                } else if (props.product.product_type === 2) {
                    dispatch({ type: 'CAR_EDIT_CART_BY_ID', payload: response.data });
                }
                props.onClose();
            }).catch(error => {
                console.log(error);
            });
        }
    };

    useEffect(() => {
        onChangeInput('startDate', window.moment.utc(props.product.start_date));
        onChangeInput('endDate', window.moment.utc(props.product.end_date));
    }, [props.product]);

    useEffect(() => {
        if (inputs.endDate && inputs.startDate?.isAfter(inputs.endDate)) {
            onChangeInput('endDate', inputs.startDate);
        }
    }, [inputs.startDate, inputs.endDate]);

    useEffect(() => {
        let diffInSeconds = 0;

        if (props.type === 'normal') {
            diffInSeconds = window.moment.utc(props.product.provider_end_date).diff(
                window.moment.utc(props.product.provider_start_date),
                'seconds'
            );
        } else {
            diffInSeconds = window.moment.utc(props.product.end_date).diff(
                window.moment.utc(props.product.start_date),
                'seconds'
            );
        }

        if (
            inputs.startDate &&
            (
                [1, 12].includes(props.product.product_type) ||
                (props.type === 'normal' && props.product.product_type === 11)
            )
        ) {
            onChangeInput(
                'endDate',
                inputs.startDate.clone().add(diffInSeconds, 'seconds')
            );
        }
    }, [
        props.type,
        props.product,
        inputs.startDate
    ]);

    useEffect(() => {
        if (props.initialStartDate) {
            onChangeInput('startDate', window.moment.utc(props.initialStartDate));
        }
    }, [props.initialStartDate]);

    return (
        <Dialog open onClose={ props.onClose } maxWidth={ "md" } disableEscapeKeyDown>
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack direction="row" alignItems="center" columnGap={1}>
                        {
                            props.enablePreviousButton &&
                            <IconButton onClick={props.onGoBack}>
                                <ArrowBackOutlined />
                            </IconButton>
                        }
                        <Typography fontWeight="bold">
                            { t<string>('cart-material.edit-time') }
                        </Typography>
                    </Stack>
                    <IconButton size={"small"} onClick={ props.onClose }>
                        <Close/>
                    </IconButton>
                </Stack>
                <hr className={ classes.hr }/>
            </DialogTitle>
            <DialogContent className={ classes.ftContainer }>
                <Grid container justifyContent="center" columnSpacing={2}>
                    <Grid item xs={12} sx={{ marginBottom: 2 }}>
                        <Alert severity="info" sx={{ marginBottom: 1 }}>
                            {
                                (props.product.product_type === 12 || props.product.product_type === 11) ?
                                    t<string>('cart-material.client-mandatory-time') :
                                    t<string>('cart-material.client-mandatory-time-tranfers')
                            }
                        </Alert>
                        {
                            props.minStartDate &&
                            <Alert severity="warning" sx={{ marginBottom: 1 }}>
                                {
                                    t<string>(
                                        'cart-material.cart-construction-client-time-modal-min-start-date-hint',
                                        { date: props.minStartDate.format('LLL') }
                                    )
                                }
                            </Alert>
                        }
                        {
                            props.maxStartDate &&
                            <Alert severity="warning" sx={{ marginBottom: 1 }}>
                                {
                                    t<string>(
                                        'cart-material.cart-construction-client-time-modal-max-start-date-hint',
                                        { date: props.maxStartDate.format('LLL') }
                                    )
                                }
                            </Alert>
                        }
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={ AdapterMoment } locale={language}>
                            {
                                [0, 7].includes(props.product.product_type) ?
                                    <DatePicker
                                        label={ t<string>('shared.start-date') + '*' }
                                        value={ inputs.startDate }
                                        minDate={props.minStartDate}
                                        maxDate={props.maxStartDate}
                                        onChange={(value) => onChangeInput('startDate', value)}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                variant: 'outlined',
                                                error: !!clientStartTimeError,
                                                helperText: props.type === 'normal' ?
                                                    t<string>(
                                                        'cart-material.provider-time-start-only',
                                                        {
                                                            start_time: props.product.provider_start_date ?
                                                                window.moment.utc(props.product.provider_start_date).format('LLL') :
                                                                window.moment.utc(props.product.start_date).format('LLL')
                                                        }
                                                    ) :
                                                    undefined
                                            }
                                        }}
                                    /> :
                                    <DateTimePicker
                                        label={ t<string>('shared.start-date') + '*' }
                                        value={ inputs.startDate }
                                        minDate={props.minStartDate}
                                        maxDate={props.maxStartDate}
                                        onChange={(value) => onChangeInput('startDate', value)}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                variant: 'outlined',
                                                error: !!clientStartTimeError,
                                                helperText: props.type === 'normal' ?
                                                    t<string>(
                                                        'cart-material.provider-time-start-only',
                                                        {
                                                            start_time: props.product.provider_start_date ?
                                                                window.moment.utc(props.product.provider_start_date).format('LLL') :
                                                                window.moment.utc(props.product.start_date).format('LLL')
                                                        }
                                                    ) :
                                                    undefined
                                            }
                                        }}
                                    />
                            }
                        </LocalizationProvider>
                    </Grid>
                    <Grid item sx={{ paddingTop: 1 }}>
                        -
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={ AdapterMoment } locale={language}>
                            {
                                [0, 7].includes(props.product.product_type) ?
                                    <DatePicker
                                        label={ t<string>('shared.end-date') }
                                        value={ inputs.endDate }
                                        minDate={
                                            props.product.product_type === 0 ?
                                                inputs.startDate?.clone().startOf('day').add(1, 'day') :
                                                inputs.startDate
                                        }
                                        onChange={(value) => onChangeInput('endDate', value)}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                variant: 'outlined',
                                                error: !!clientEndTimeError && props.product.product_type !== 12,
                                                helperText: props.type === 'normal' && props.product.end_date ?
                                                    t<string>(
                                                        'cart-material.provider-time-start-only',
                                                        {
                                                            start_time: props.product.provider_end_date ?
                                                                window.moment.utc(props.product.provider_end_date).format('LLL') :
                                                                window.moment.utc(props.product.end_date).format('LLL')
                                                        }
                                                    ) :
                                                    undefined
                                            }
                                        }}
                                    /> :
                                    <DateTimePicker
                                        label={ t<string>('shared.end-date') }
                                        value={ inputs.endDate }
                                        minDate={inputs.startDate}
                                        onChange={(value) => onChangeInput('endDate', value)}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                variant: 'outlined',
                                                error: !!clientEndTimeError && props.product.product_type !== 12,
                                                helperText: props.type === 'normal' && props.product.end_date ?
                                                    t<string>(
                                                        'cart-material.provider-time-start-only',
                                                        {
                                                            start_time: props.product.provider_end_date ?
                                                                window.moment.utc(props.product.provider_end_date).format('LLL') :
                                                                window.moment.utc(props.product.end_date).format('LLL')
                                                        }
                                                    ) :
                                                    undefined
                                            }
                                        }}
                                    />
                            }
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={ props.onClose }>
                    {t<string>('global.cancel')}
                </Button>
                <Button onClick={ onValidateTime }>
                    {t<string>('global.validate')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(() => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    hr: {
        border: 0,
        height: 0,
        borderTop: '1px solid rgba(0, 0, 0, .1)',
        borderBottom: '1px solid rgba(255, 255, 255, .3)',
        width: '100%',
        marginBottom: 0
    },
    ftContainer: {
        //marginTop: "5px !important",
        marginBottom: "32px !important"
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    }
}));
