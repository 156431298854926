import React from "react";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { Paper } from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    spacer: {
        padding: "12px 0px"
    },
    formShadow: {
        borderRadius: 4,
        // boxShadow: '0 1px 6px rgba(32,33,36,0.28)',
        margin: "16px 4px"
    },
    skeletonPadding: {
        padding: "16px"
    }
}));

const FlightSearchSkeleton = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    return (
        <Grid>
            <Grid className={classes.spacer}>
                <LinearProgress />
                <Paper elevation={0} variant="outlined" className={classes.formShadow}>
                    <Grid container alignItems="center" justify="space-between" style={{ padding: "8px 16px" }}>
                        <Grid item xs={5}>
                            <Grid container alignItems="center" justify="flex-start" spacing={5}>
                                <Grid item>
                                    <Skeleton variant="rect" animation="wave" width={147} height={10} />
                                </Grid>
                                <Grid item>
                                    <Skeleton variant="rect" animation="wave" width={130} height={10} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Skeleton variant="rect" width={185} height={10} />
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container alignItems="center" justify="flex-end" spacing={4}>
                                <Grid item>
                                    <Skeleton variant="rect" animation="wave" width={120} height={10} />
                                </Grid>
                                <Grid item>
                                    <Skeleton variant="rect" animation="wave" width={106} height={10} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container alignItems="center" justify="space-between" className={classes.skeletonPadding}>
                        <Grid item>
                            <Skeleton variant="rect" animation="wave" width={145} height={10} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="rect" animation="wave" width={140} height={10} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="rect" animation="wave" width={230} height={10} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="rect" animation="wave" width={220} height={10} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="rect" animation="wave" width={170} height={10} />
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container alignItems="center" justify="space-between" className={classes.skeletonPadding}>
                        <Grid item>
                            <Skeleton variant="rect" animation="wave" width={145} height={10} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="rect" animation="wave" width={140} height={10} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="rect" animation="wave" width={230} height={10} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="rect" animation="wave" width={220} height={10} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="rect" animation="wave" width={170} height={10} />
                        </Grid>
                    </Grid>
                    {/* <Grid container alignItems="center" justify="space-between" className={classes.skeletonPadding} spacing={2}>
                        <Grid item xs={12}>
                            <Skeleton variant="rect" animation="wave" width={60} height={10} />
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton variant="rect" animation="wave" width="100%" height={10} />
                        </Grid>
                    </Grid> */}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default React.memo(FlightSearchSkeleton);
