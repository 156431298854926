//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import clsx from "clsx";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';

import CustomHomeSlider from '../Home/CustomHomeSlider.jsx';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';

import "../../Style/Home.css";
import "../../Style/Global.css";
import "../../Style/GlobalAgencies.css";

import SummaryMainMap from '../SummaryQuotation/Summary/SummaryMainMap.jsx';
import SummaryOfSummary from '../SummaryQuotation/Summary/SummaryOfSummary.jsx';
import SummaryMapDetail from './SummaryMapDetail';
import TripSummaryRightPanel from './TripSummaryRightPanel.js';
import DetailStep from '../ItineraryType/ItiTypes/DetailStep.tsx';
import DetailDay from '../ItineraryType/ItiTypes/DetailDay.js';

import { SetCurrentStep } from '../../Actions/Summary.js';
import FlightsCardStep from '../ItineraryType/PackagesMaterial/FlightsCardStep.tsx';
import HotelInfo from '../ItineraryType/PackagesMaterial/HotelInfo.tsx';
import RenderSummaryFlight from './RenderProducts/RenderSummaryFlight.js';
import RenderAllProduct from './RenderAllProduct.js';
import GetQuotationDefaultInclusions from '../Menu/MaterialTripList/Functions/GetQuotationDefaultInclusions.js';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    headerPicture: {
        height: "50vh"
    },
    absoluteCenter: {
        margin: 0,
        position: "absolute",
        top: "24%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    customButton: {
        background: "#e6592f",
        // padding: "15px 40px",
        textAlign: "center",
        color: "#fff",
        fontSize: 20,
        textTransform: "none",
        "&:hover": {
            background: "#cc4f2a"
        }
    },
    headerContainer: {
        height: "50vh",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    },
    destinationTitle: {
        textAlign: "center",
        color: "white",
        textShadow: "black 1px 0 10px",
        fontFamily: "Roboto",
        // marginBottom: 50,
        fontWeight: 400
    },
    hoverContainer: {
        "&:hover": {
            borderLeft: '6px solid #E6592F'
        }
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 50
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
    staticPriceContainer: {
        position: 'fixed',
        bottom: 0,
        backgroundColor: 'white',
        marginLeft: -16,
        padding: 16,
        borderTop: '1px solid lightgray'
    },
    orangeButton: {
		backgroundColor: "#E6592F",
		color: "white"
	},
    stickyPaper: {
        position: "sticky",
        top: 80,
        borderRadius: 12
    },
    fullWidth: {
        width: "100%"
    },
    textJustify: {
        textAlign: "justify"
    },
    hrText: {
        lineHeight: "20px",
        position: "relative",
        outline: "0",
        border: "0",
        textAlign: "center",
        height: 20,
        fontSize: 34,
        '&::before': {
            content: "''",
            background: "#0000008A",
            position: "absolute",
            left: "0",
            top: "50%",
            width: "100%",
            height: "1px"
        },
        '&::after': {
            content: "attr(data-content)",
            position: "relative",
            display: "inline-block",
            color: "#0000008A",
            padding: "0 .5em",
            // lineHeight: "1.5em",
            backgroundColor: "white"
        }
    },
    bold: {
        fontWeight: "bold"
    }
}));

const TripSummary = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const { pass_check, headers } = CheckBeforeRequest();

    const trip_info = useSelector((store) => store.summary.trip_info);
    const trip_main_info = useSelector((store) => store.summary.trip_main_info);
    const itinerary = useSelector(store => store.summary.itinerary);
    const hotel_cart = useSelector(store => store.summary.hotel_cart);
    const flight_cart = useSelector(store => store.summary.flight_cart);
    const manual_cart = useSelector(state => state.summary.manual_cart);
    const transfers_cart = useSelector(state => state.summary.transfers_cart);
    const poi_cart = useSelector(store => store.summary.poi_cart);
    const cars_cart = useSelector(store => store.summary.cars_cart);
    const current_step = useSelector(store => store.summary.current_step);
    const currency_list = useSelector(state => state.base.currency_list);

    const [dayByDay, setDayByDay] = useState(false);
    const [coverPic, setCoverPic] = useState(null);
    const [inclusions, setInclusions] = useState("");
    const [nonInclusions, setNonInclusions] = useState("");
    const [defaultInclusions, setDefaultInclusions] = useState("");
    const [defaultNonInclusions, setDefaultNonInclusions] = useState("");
    const [selectedProducts, setSelectedProducts] = useState({
        mainFlights: [],
        hotels: [],
        cars: [],
        transfers: [],
        poi: [],
        step_flight: []
    });

    const contentRef = useRef([]);

    useEffect(() => {
        let id = trip_info !== null ? trip_info.destination.id : null;
        getDestinationType(id);
    }, []);
    useEffect(() => {
        if (trip_main_info !== null) {
            let config_code = JSON.parse(localStorage.getItem("config")).quotation_code;
            let trip_currency = trip_main_info.currency;
            let quotation_default  = GetQuotationDefaultInclusions(config_code, hotel_cart, cars_cart, flight_cart, poi_cart, transfers_cart, manual_cart, trip_info, trip_currency, currency_list, t);
            if (trip_info.manual_quotation_fields && trip_info.quotation_included !== null){
                // convert to formatted string with dash
                setInclusions(trip_info.quotation_included);
            }
            if (trip_info.manual_quotation_fields && trip_info.quotation_not_included !== null){
                // convert to formatted string with dash
                setNonInclusions(trip_info.quotation_not_included);
            }
            setDefaultInclusions(quotation_default.inclusions);
            setDefaultNonInclusions(quotation_default.non_inclusions);
        }
    }, [trip_main_info]);
    useEffect(() => {
        window.addEventListener("scroll", checkCurrentStep, false)

        return function cleanup() {
            window.removeEventListener("scroll", checkCurrentStep)
        }
    });

    useEffect(() => {
        contentRef.current = contentRef.current.slice(0, itinerary.length);
    }, [itinerary]);

    useEffect(() => {
        if (trip_info !== null) {
            axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/quotation-template/?destination=${trip_info?.destination?.id}`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                setCoverPic(response?.data?.cover_pic)
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [trip_info]);

    const checkCurrentStep = () => {
        for (let i = 0; i < contentRef.current.length; i++) {
            if (contentRef.current[i] !== undefined && contentRef.current[i] !== null) {
                if (contentRef.current[i].getBoundingClientRect().top > 500) {
                    if (i === 0) {
                        break;
                    } else {
                        let prev_item = i - 1;
                        if (prev_item !== current_step) {
                            dispatch(SetCurrentStep(prev_item));
                        }
                    }
                    break;
                }
            }
            if (i === contentRef.current.length - 1) {
                dispatch(SetCurrentStep(i));
            }
        }
    }
    const getDestinationType = (id) => {
        if (id !== null && pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/destinations/${id}/get_quotation_type/`,
            }).then((response) => {
                setDayByDay(!response.data.step_by_step)
            }).catch((error) => {
                console.log('error:', error);
            })
        }
    }

    return (
        <Grid container style={{display: "block"}}>
            <Grid item className={classes.headerPicture}>
                <CustomHomeSlider height={"Packages"} pictures={coverPic !== null ? [coverPic] : []} />
            </Grid>
            <Grid item className={classes.absoluteCenter} style={{width: fullScreen ? "100%" : "auto"}}>
                <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                    {
                        trip_info !== null && (
                            <Grid item container direction={"column"} justify={"center"} alignItems={"center"} style={{marginBottom: 50}}>
                                <Grid item>
                                    <Typography variant={!isHandHeld ? "h4" : ""} className={classes.destinationTitle}>{trip_main_info.name}</Typography>
                                </Grid>
                                {
                                    trip_info.contact_traveler_detail !== null && (
                                        <Grid item>
                                            <Typography variant={"h5"} style={{color: "white", textShadow: "black 1px 0 10px", textAlign: "center"}} className={classes.genericText}>{ trip_info.contact_traveler_detail.first_name + " " + trip_info.contact_traveler_detail.last_name }</Typography>
                                        </Grid>
                                    )
                                }
                                <Grid item>
                                    <Typography variant={"h5"} style={{color: "white", textShadow: "black 1px 0 10px", textAlign: "center"}} className={classes.genericText}>{(t("accommodation.from") + " " + moment.utc(trip_info.start_date).format("DD/MM/YYYY") + " " + t("accommodation.to") + " " + moment.utc(trip_info.end_date).format("DD/MM/YYYY"))}</Typography>
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
            {
                trip_info !== null && (
                    <Container>
                        <Grid container direction={"row"} justify={"flex-start"} spacing={fullScreen ? 0 : 4} style={{marginTop: 20}}>
                            <Grid item xs={12}>
                                <Grid container direction={"column"} justify={"center"} alignItems={"flex-start"} spacing={2} >
                                    <Grid item style={{width: "100%"}}>
                                        <hr className={`${classes.genericText} ${classes.hrText}`} data-content={ t("summary.trip_summary") }/>
                                    </Grid>
                                    <Grid item container direction={"row"} style={{width: "100%"}} spacing={2}>
                                        <Grid item sm={isTablet ? 12 : 6} xs={12} style={{width: "100%"}}>
                                            <div id={"map"} className={classes.stickyPaper}>
                                                <SummaryMainMap />
                                            </div>
                                        </Grid>
                                        <Grid item sm={isTablet ? 12 : 6} xs={12} id={"summary-of-summary"} style={{width: "100%"}}>
                                            <SummaryMapDetail />
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{width: "100%"}}>
                                        <hr className={`${classes.genericText} ${classes.hrText}`} data-content={ t("summary.trip_detail") }/>
                                        <Grid container direction={"row"} justify={"flex-start"} spacing={4}>
                                            <Grid item container sm={isTablet ? 12 : 8} xs={12} className={classes.genericText}>
                                                <RenderAllProduct from={"tripSummary"} dayByDay={dayByDay} ref={contentRef} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts}/>
                                                {
                                                    !trip_info.manual_quotation_fields && (defaultInclusions !== "" || defaultNonInclusions !== "") && (
                                                        <Grid container direction={"row"} spacing={2} className={`${classes.genericText}`} style={{marginTop: 100}}>
                                                        {
                                                            defaultInclusions !== "" && (
                                                                <Grid item xs container direction={"column"} style={{borderLeft: "1px solid"}}>
                                                                    <Grid item className={classes.bold} style={{marginBottom: 5}}>{t("summary.included_in_price") + " : "}</Grid>
                                                                    {
                                                                        defaultInclusions.map((inclusion, inclusion_index) => {
                                                                            return (
                                                                                <Grid item key={inclusion_index}>{`- ${inclusion}`}</Grid>
                                                                            )
                                                                        })
                                                                    }
                                                                </Grid>
                                                            )
                                                        }
                                                        {
                                                            defaultNonInclusions !== "" && (
                                                                <Grid item xs container direction={"column"} style={{borderLeft: "1px solid"}}>
                                                                    <Grid item className={classes.bold} style={{marginBottom: 5}}>{t("summary.not_included_in_price") + " : "}</Grid>
                                                                    {
                                                                        defaultNonInclusions.map((nonInclusion, nonInclusion_index) => {
                                                                            return (
                                                                                <Grid item key={nonInclusion_index}>{`- ${nonInclusion}`}</Grid>
                                                                            )
                                                                        })
                                                                    }
                                                                </Grid>
                                                            )
                                                        }
                                                        </Grid>
                                                    )
                                                }
                                                {
                                                    trip_info.manual_quotation_fields && (inclusions !== "" || nonInclusions !== "") && (
                                                        <Grid container direction={"row"} spacing={2} className={`${classes.genericText}`} style={{marginTop: 100}}>
                                                        {
                                                            inclusions !== "" && (
                                                                <Grid item xs container direction={"column"} style={{borderLeft: "1px solid"}}>
                                                                    <Grid item className={classes.bold} style={{marginBottom: 5}}>{t("summary.included_in_price") + " : "}</Grid>
                                                                    {
                                                                        inclusions.map((inclusion, inclusion_index) => {
                                                                            return (
                                                                                <Grid item key={inclusion_index}>{`- ${inclusion}`}</Grid>
                                                                            )
                                                                        })
                                                                    }
                                                                </Grid>
                                                            )
                                                        }
                                                        {
                                                            nonInclusions !== "" && (
                                                                <Grid item xs container direction={"column"} style={{borderLeft: "1px solid"}}>
                                                                    <Grid item className={classes.bold} style={{marginBottom: 5}}>{t("summary.not_included_in_price") + " : "}</Grid>
                                                                    {
                                                                        nonInclusions.map((nonInclusion, nonInclusion_index) => {
                                                                            return (
                                                                                <Grid item key={nonInclusion_index}>{`- ${nonInclusion}`}</Grid>
                                                                            )
                                                                        })
                                                                    }
                                                                </Grid>
                                                            )
                                                        }
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                            {
                                                !isHandHeld && (
                                                    <Grid item sm={4}>
                                                        <Card className={ `${classes.stickyPaper} ${classes.genericText}` } raised>
                                                            <TripSummaryRightPanel/>
                                                        </Card>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <HotelInfo/>
                    </Container>
                )
            }
        </Grid>
    )
}
export default TripSummary;