/*eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";

const CrmDemandRooms = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const selected_demand = useSelector(store => store.crm.selected_demand);
    const user_client = useSelector(store => store.user.user);

    const [travelerRooms, setTravelerRooms] = useState([]);
    const [version, setVersion] = useState(null);

    //TRAVELER GROUPS
    useEffect(() => {
        if (user_client && selected_demand && selected_demand.data?.length && selected_demand.data[selected_demand.data.length - 1]) {
            const version = selected_demand.data[selected_demand.data.length - 1];
            setVersion(version);
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'GET',
                url: `${API_HREF}client/${user_client.client}/trip/${selected_demand.id}/versions/${version.id}/travelers-group/?is_sketch=true`,
                headers
            }).then((response) => {
                const defaults = response.data?.results?.filter(e => e.default)?.sort((a, b) => a.id - b.id);
                if (defaults?.length) {
                    setTravelerRooms(defaults);
                    console.log("defaultss", defaults);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [user_client, selected_demand]);

    const getTravelerRoom = (id) => {
        let roomindex = 0;
        if (travelerRooms?.length) {
            roomindex = travelerRooms.findIndex(r => r.travelers.includes(id));
        }
        return roomindex + 1;
    };

    const displayName = (traveler) => {
        return `${traveler.title ? traveler.title + " " : ''}${traveler.first_name ? traveler.first_name + " " : ''}${traveler.last_name ? traveler.last_name + " " : ''}`;
    };

    if (selected_demand && version) {
        return (
            <div>
                {version.travelers && (
                    version.travelers.map((traveler, index) => {
                        return (
                            <React.Fragment key={traveler.id}>
                                <p><b>{displayName(traveler)}</b></p>
                                <p>{t('crm.traveler_validation.room')}{getTravelerRoom(traveler.id)}</p>
                            </React.Fragment>
                        );
                    })
                )}
            </div>
        );
    }
    return null;
};
export default CrmDemandRooms;
