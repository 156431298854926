//---- Dependencies ----//
import React, { Fragment } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles({
    destinationArrow: {
        verticalAlign: "middle"
    },
    ftRed: {
        color: "red"
    },
    mt15: {
        marginTop: 15
    },
    mb15: {
        marginBottom: 15
    }
});

const ReloadCartCars = ({ ranked }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const update_infos = useSelector(state => state.cart.update_infos);
    const cars_cart = useSelector(state => state.cars_search.cart);
    const onToggleExtended = (key) => () => {
        dispatch({type: "RECALC_TOGGLE_CARS_EXTENDED", payload: {key: key}});
    };
    return (
        <Fragment>
            <h5 className={ classes.mt15 }>{ ranked }) { t('itinerary.cart_reload_cars') }</h5>
            <Table className={ classes.mb15 }>
                <TableHead>
                    <TableRow>
                        <TableCell>{ t("recalc.vehicle_name") }</TableCell>
                        <TableCell>{ t("footer.destinations") }</TableCell>
                        <TableCell>{ t("recalc.old_date") }</TableCell>
                        <TableCell>{ t("recalc.new_date") }</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.keys(update_infos.cars).map((key) => {
                            if ((update_infos.cars[key].start_date_moved && moment(update_infos.cars[key].new_start_date).isBefore(moment(update_infos.cars[key].start_date))) || (update_infos.cars[key].end_date_moved && moment(update_infos.cars[key].new_end_date).isAfter(moment(update_infos.cars[key].end_date)))) {
                                let found = cars_cart.find((car) => {
                                    return (parseInt(car.id) === parseInt(key))
                                });
                                let diff = moment(update_infos.cars[key].new_end_date).diff(moment(update_infos.cars[key].end_date), 'days');
                                return (
                                    <TableRow key={ `reacalc-cart-${key}` }>
                                        <TableCell component="th" scope="row">
                                            { found.name }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                found.station_name_pickup !== found.station_name_return ? (
                                                    <Fragment>
                                                        { update_infos.cars[key].reversed_destination ? found.station_name_return : found.station_name_pickup }
                                                        <ArrowRightAltIcon className={ classes.destinationArrow }/>
                                                        { update_infos.cars[key].reversed_destination ? found.station_name_pickup : found.station_name_return }
                                                    </Fragment>
                                                ) : (
                                                    found.station_name_pickup
                                                )
                                            }
                                        </TableCell>
                                        <TableCell>
                                            { moment(update_infos.cars[key].start_date).format("DD-MM-YYYY") + " - " + moment(update_infos.cars[key].end_date).format("DD-MM-YYYY") }
                                        </TableCell>
                                        <TableCell>
                                            { moment(update_infos.cars[key].new_start_date).format("DD-MM-YYYY") + " - " + moment(update_infos.cars[key].new_end_date).format("DD-MM-YYYY") }
                                            <span className={ classes.ftRed }>
                                            { ` (+${diff} ${diff > 1 ? t('global.days') : t('global.day')})` }
                                        </span>
                                        </TableCell>
                                        <TableCell>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={ update_infos.cars[key].car_extended }
                                                        color={"primary"}
                                                        onChange={ onToggleExtended(key) }
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                }
                                                label={ t("global.yes") }
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        })
                    }
                </TableBody>
            </Table>
            <br/>
        </Fragment>
    );
};

export default ReloadCartCars;