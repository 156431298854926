import { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
    cloneDeep,
    groupBy,
    isNumber,
    mapKeys,
    mapValues
} from "lodash";
import { areStepsEqual } from "../utils/areStepsEqual";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import { setBeingSavedStepsdStep } from "../redux/reducer";
import { Itinerary } from "../objects/itinerary";
import { ItineraryInput } from "../objects/itineraryState";
import { AppState } from "../../../Reducers/Reducers";

type Callback = (
    origin: Itinerary[],
    target: ItineraryInput[],
) => Promise<Itinerary[]>;

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: (itinerary: Itinerary[]) => void,
    onError: (error: Error) => void,
    onFinally: () => void
}>

type RequestOptions = {
    circuitId: number | null,
    circuitVersion: number | null,
    tripId: number,
    origin: Itinerary[],
    target: ItineraryInput[],
    notifyBeingSavedSteps: (steps: ItineraryInput[]) => void,
    signal?: AbortSignal
}

export function useItineraryUpdate(options: Options): Callback {
    const dispatch = useDispatch();
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const circuitId = useSelector((state: AppState) => state.trip.all_data?.circuit);
    return useCallback(
        async (origin, target) => {
            if (tripId) {
                const circuitVersion = GetCookie('trip_id_version');
                try {
                    if (options.onTrigger) {
                        options.onTrigger();
                    }
                    const response = await makeRequest({
                        origin,
                        target,
                        tripId,
                        circuitId: circuitId ?? null,
                        circuitVersion: circuitId && circuitVersion ?
                            parseInt(circuitVersion) :
                            null,
                        notifyBeingSavedSteps(steps) {
                            dispatch(
                                setBeingSavedStepsdStep(
                                    mapValues(
                                        mapKeys(
                                            steps,
                                            (step) => step.id
                                        ),
                                        () => true
                                    )
                                )
                            );
                        }
                    });
                    if (options.onSuccess) {
                        options.onSuccess(response);
                    }
                    return response;
                } catch (error: any) {
                    if (options.onError) {
                        options.onError(error);
                    }
                } finally {
                    if (options.onFinally) {
                        options.onFinally();
                    }
                    dispatch(setBeingSavedStepsdStep({}));
                }
            }
            return origin;
        },
        [tripId, circuitId]
    );
}

async function makeRequest(options: RequestOptions): Promise<Itinerary[]> {
    const target = extractIds(options.origin, options.target);
    const targetIds = target.map((item) => item.id);
    const toBeRemoved = options.origin.filter((item) => {
        return !targetIds.includes(item.id) ||
            (
                item.step_type === 'STEP' &&
                !item.destination
            );
    });
    const updated = target.filter((item) => {
        return options.origin.findIndex((originItem) => {
            return areStepsEqual(item, originItem);
        }) < 0;
    });
    
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const cancelToken = axios.CancelToken.source();
        options.signal?.addEventListener('abort', () => {
            cancelToken.cancel();
        });
        options.notifyBeingSavedSteps(updated);
        const response = await axios.post<Itinerary[]>(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${GetCookie("trip_id_version")}/itinerary/array_patch/`,
            [
                ...toBeRemoved.map((item) => ({
                    id: item.id,
                    delete: true
                })),
                ...updated.map((item) => ({
                    ...item,
                    circuit: item.step_type === 'STEP' && options.circuitId ?
                        options.circuitId :
                        item.circuit,
                    circuit_trip_version: item.step_type === 'STEP' && options.circuitVersion ?
                        options.circuitVersion :
                        item.circuit_trip_version,
                    trip: undefined,
                    trip_version: undefined,
                    destination: item.destination?.id ?? undefined,
                    id: isNumber(item.id) ? item.id : undefined
                }))
            ],
            {
                headers,
                cancelToken: cancelToken.token
            }
        );
        return response.data;
    }

    return options.origin;
}

function extractIds(steps: Itinerary[], inputs: ItineraryInput[]): ItineraryInput[] {
    const result = cloneDeep(inputs);
    const startStepId = steps.find((item) => {
        return item.step_type === 'START';
    })?.id;
    const endStepId = steps.find((item) => {
        return item.step_type === 'END';
    })?.id;
    const ids = groupBy(
        steps.filter((item) => {
            return item.step_type === 'STEP';
        }),
        (item) => item.destination?.id
    );

    for (const step of result) {
        switch (step.step_type) {
            case 'START': {
                if (startStepId) {
                    step.id = startStepId;
                //only regenerate id if it is a number so there is no unecessary re-render
                } else if (isNumber(step.id)) {
                    step.id = Math.random().toString();
                }
                break;
            }
            case 'STEP': {
                if (step.destination) {
                    const group = ids[step.destination.id] ?? [];
                    const id = group[0]?.id;
                    ids[step.destination.id] = group.slice(1);
                    if (id) {
                        step.id = id;
                    //only regenerate id if it is a number so there is no unecessary re-render
                    } else if (isNumber(step.id)) {
                        step.id = Math.random().toString();
                    }
                }
                break;
            }
            case 'END': {
                if (endStepId) {
                    step.id = endStepId;
                //only regenerate id if it is a number so there is no unecessary re-render
                } else if (isNumber(step.id)) {
                    step.id = Math.random().toString();
                }
                break;
            }
        }
    }

    return result;
}
