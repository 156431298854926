//---- Dependencies ----//
import Axios from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

export default (id, id_version, setProduct, setGroup, id_product, trip_token) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        Axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/custom-products/${id_product}/?token=${trip_token}`,
        }).then(function (response) {
            let data = Object.assign({}, response.data);
            setProduct(data);
            if (data.group_passenger !== undefined && data.group_passenger !== null) {
                setGroup(data.group_passenger.travelers_list);
            }
        }).catch(function (error) {
            console.log('err:', error);
        }) 
    }
}   
