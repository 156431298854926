import { getDestinationName } from "../../../Itinerary/utils/getDestinationName";
import { getRoadbookStepTransportIcon } from "./getRoadbookStepTransportIcon";
import { CreatePageOptions } from "../objects/createPageOptions";
import { Template } from "../utils/handlebars/roadbook.handlebars";

export function createStepsContent(options: CreatePageOptions): Parameters<Template>[0]['contents'][0] | null {
    const stepsPageContent = options.itinerary.filter((item) => {
        return item.step_type === 'STEP';
    }).map((step, index, array) => {
        const nightsCount = window.moment.utc(step.end_date).startOf('day').diff(
            window.moment.utc(step.start_date).startOf('day'),
            'days'
        );
        let duration = step.r2r_json?.duration_string ?? '00h00';
        const regex = /^([\d\.]+)h([\d\.]+)$/;
        const matches = duration.match(regex);

        if (matches && matches[1] && matches[2]) {
            const hours = parseInt(matches[1]);
            const minutes = parseInt(matches[2]);
            duration = `${hours.toFixed(0).padStart(2, '0')}h${minutes.toFixed(0).padStart(2, '0')}`;
        } else {
            throw new Error('Invalid duration_string');
        }

        return {
            no: index + 1,
            nightsCount: options.t(
                'roadbook.steps-nights-count',
                { count: nightsCount }
            ),
            place: step.destination ?
                getDestinationName(options.locale, step.destination).split(',')[0] ?? '' :
                '',
            transport: array[index + 1] ?
                {
                    url: getRoadbookStepTransportIcon(step),
                    distance: `${step.distance_transport_km} km`,
                    duration
                } :
                undefined
        };
    });
    return stepsPageContent.length > 1 ?
        {
            type: 'steps',
            anchor: 'steps',
            showInSummary: true,
            summaryTitle: options.title,
            title: options.title,
            steps: stepsPageContent
        } :
        null;
}
