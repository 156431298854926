import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Alert,
    AlertTitle,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    Tooltip
} from "@mui/material";
import { Remove } from "@mui/icons-material";
import { flatten } from "lodash";
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";
import { isContentStepEqual } from "./utils/isContentStepEqual";

type Props = {
    input: ItineraryContentStep,
    onChangeInputs: React.Dispatch<React.SetStateAction<ItineraryContentStep[]>>,
}

export function CartConstructionItineraryContentStepsListDivider(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [openDissociateModal, setOpenDissociateModal] = useState(false);

    const onDissociateSteps = (pulled: number[]) => {
        props.onChangeInputs((state) => {
            return [
                ...flatten(state.map((item) => {
                    if (isContentStepEqual(item, props.input)) {
                        return [
                            item.mode === 'by-day' ?
                                {
                                    ...item,
                                    content: {
                                        ...item.content,
                                        day: item.content.day.filter((day) => !pulled.includes(day))
                                    }
                                } :
                                item,
                            ...pulled.map((day) => {
                            //eslint-disable-next-line @typescript-eslint/no-unused-vars
                                const { id, ...data } = item.content;
                                return {
                                    ...item,
                                    content: {
                                        ...data,
                                        title: '',
                                        short_description: '',
                                        long_description: '',
                                        localization: [],
                                        picture: null,
                                        pictures: [],
                                        interval: [],
                                        day: [day]
                                    }
                                } as ItineraryContentStep;
                            })
                        ];
                    }
                    return item;
                }) ?? [])
            ].sort((a, b) => {
                if (a.mode === 'by-day' && b.mode === 'by-day') {
                    return (a.content.day[0] ?? 0) - (b.content.day[0] ?? 0);
                }
                return -1;
            });
        });
    };

    return (
        <>
            <Tooltip title={t<string>('shared.circuit-steps-divide')}>
                <IconButton onClick={() => setOpenDissociateModal(true)}>
                    <Remove />
                </IconButton>
            </Tooltip>
            <Modal
                open={openDissociateModal}
                days={
                    props.input.mode === 'by-day' ?
                        props.input.content.day :
                        []
                }
                onClose={() => setOpenDissociateModal(false)}
                onConfirm={onDissociateSteps}
            />
        </>
    );
}

type ModalProps = {
    open: boolean,
    days: number[],
    onConfirm: (pulled: number[]) => void,
    onClose: () => void
}

function Modal(props: ModalProps): JSX.Element {
    const { t } = useTranslation();
    const [checked, setChecked] = useState<number[]>([]);

    const onChange = (day: number, checked: boolean) => {
        setChecked((state) => {
            if (checked) {
                return state.concat([day]);
            }

            //a step should always contain at least one day
            if (state.length === 1) {
                return state;
            }

            return state.filter((item) => item !== day);
        });
    };

    const onConfirm = () => {
        props.onConfirm(props.days.filter((day) => !checked.includes(day)));
        props.onClose();
    };

    useEffect(() => {
        setChecked(props.days);
    }, [props.days]);

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                {t<string>('shared.circuit-choose-to-be-pulled-days')}
            </DialogTitle>
            <DialogContent>
                {
                    checked.length <= 1 &&
                    <Alert severity="warning" sx={{ marginBottom: 2 }}>
                        <AlertTitle>Info</AlertTitle>
                        {t<string>('shared.circuit-steps-aggregate-modal-hint')}
                    </Alert>
                }
                <List>
                    {
                        props.days.map((day, index) => (
                            <ListItem key={day}>
                                <FormControlLabel
                                    control={<Checkbox checked={checked.includes(day)} />}
                                    label={t<string>('shared.circuit-step-days', { days: day })}
                                    onChange={(_, checked) => onChange(day, checked)}
                                    disabled={index === 0}
                                />
                            </ListItem>
                        ))
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{ t<string>('shared.cancel') }</Button>
                <Button onClick={onConfirm}>
                    { t<string>('shared.confirm') }
                </Button>
            </DialogActions>
        </Dialog>
    );
}
