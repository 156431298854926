//---- Dependencies ----//
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import HotelIcon from './Icons/HotelIcon';
import Lock from "@material-ui/icons/LockOutlined";
import LockOpen from "@material-ui/icons/LockOpenOutlined";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { AddHistorydest, SetItineraryTypeDetailsDataAndDayAndNight } from '../../Actions/ItineraryType';

import localeText from "../Functions/localeText";
import i18n from '../../i18n';
import VisiteursMoreInfo from '../VisiteursHome/VisiteursMoreInfo';
import GetPictureBySize from '../Accommodation/Functions/GetPictureBySize';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    cardContainer: {
        "width": "100%",
        "height": "auto",
        "boxShadow": "1px 1px 3px rgb(0 0 0 / 10%)",
        "overflow": "hidden",
        "transition": "transform .2s",
        "&:hover": {
            "&& .mui-jss-MuiCardActionArea-root": {
                transform: "scale(1.2)"
            },
            "&& .mui-jss-MuiCardActions-root": {
                backgroundColor: `${main_color}`,
                color: "white !important",
                transition: "all .2s ease",
                transformOrigin: "top"
            },
            "&& .lower-price": {
                color: "white !important"
            }
        }
    },
    cardContainerV: {
        "width": "100%",
        "height": "auto",
        "boxShadow": "1px 1px 3px rgb(0 0 0 / 10%)",
        "overflow": "hidden",
        "transition": "transform .2s",
        "&:hover": {
            "&& .MuiCardActionArea-root": {
                transform: "scale(1.2)"
            },
            "&& .MuiCardActions-root": {
                backgroundColor: "#BF202D",
                color: "white !important",
                transition: "all .2s ease",
                transformOrigin: "top"
            },
            "&& .lower-price": {
                color: "white !important"
            }
        }
    },
    cardImg: {
        height: "auto",
        zIndex: 1,
        position: "relative",
        transition: "all .6s ease-out",
        display: "block",
        width: "100%",
        objectFit: "cover"
    },
    cardTitle: {
        width: "100%",
        background: "white",
        height: "auto",
        display: "flex",
        zIndex: 2,
        padding: "5px",
        position: "relative",
        cursor: "pointer"
    },
    pink: {
        color: "#E0368D"
    },
    listContainer: {
        "&& ul": {
            "listStyleType": "'-'",
            "paddingInlineStart": "0px",
            "marginBottom": 4,
            "& li": {
                paddingLeft: 5
            }
        }
    }
}));

const MaterialPackageCard = ({ package_info, router, desti_id }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const config = JSON.parse(localStorage.getItem("config"));
    const quotation_code = config.quotation_code;

    const locales = useSelector(state => state.user.locales);
    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });
    const [moreInfoModal, setMoreInfoModal] = useState(false);

    const replaceAll = (str, find, replace) => {
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    };
    const escapeRegExp = (str) => {
        return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    };
    const handleMoreInfo = () => {
        setMoreInfoModal(!moreInfoModal);
    };
    let cover_picture = null;
    if (package_info !== null && package_info.pictures !== null && package_info.pictures.length > 0) {
        cover_picture = package_info.pictures[0];
    }
    let total_days = package_info.duration_day;
    let tmp_total_nights = package_info.duration_night;
    let currency_symbol = "-";
    if (package_info !== null) {
        if (package_info.trip.currency !== undefined && package_info.trip.currency !== null) {
            currency_symbol = package_info.trip.currency.symbol;
        }
    }
    let destination = null;
    if (package_info.destination !== undefined && package_info.destination !== null) {
        destination = localeText(current_locale.id, package_info.destination.data.localization, package_info.destination.data.international_name !== undefined ? package_info.destination.data.international_name : package_info.destination.data.name, 'name').split(',');
    }
    let key = null;
    if (package_info.lowest_price !== null && typeof package_info.lowest_price !== 'number') {
        key = Object.keys(package_info.lowest_price);
    }
    return (
        <Grid item sm={['visiteurs'].includes(quotation_code) ? 3 : 4} xs={12}>
            <Card className={`${quotation_code === 'visiteurs' ? classes.cardContainerV : classes.cardContainer} ${classes.genericText}`} >
                <CardActionArea className={classes.cardImg} onClick={() => {
                    //dispatch(SetTripToDuplicateAndOpenDuplicateModalAndSetStep(iti, true, 1));
                    let tmp_obj = {
                        name: package_info.name,
                        data: package_info,
                        need_redirect: true
                    };
                    dispatch(AddHistorydest(tmp_obj));
                    // dispatch(SetItineraryTypeDetailsDataAndDayAndNight(null, 0, 0));
                    dispatch(SetItineraryTypeDetailsDataAndDayAndNight(package_info, 0, 0));
                    let a = replaceAll(package_info.name, ' ', '-');
                    let clean_name = replaceAll(a, '/', '');
                    if (package_info.type !== undefined) {
                        router.push(`/${window.url_name}/circuits-details/${encodeURIComponent(clean_name)}/${package_info.id}`);
                    } else {
                        router.push(`/${window.url_name}/packages-details/${encodeURIComponent(clean_name)}/${package_info.id}`);
                    }
                }}>
                    <CardMedia
                        image={package_info !== null ? ((cover_picture !== undefined && cover_picture !== null) ? GetPictureBySize(cover_picture, 'BIG') : "/Img/default_dest1.jpeg") : "/Img/default_dest1.jpeg"} className={"ft-cover-img ft-card-img"}
                        title={localeText(current_locale.id, package_info.localization, package_info.name, 'name')}
                    />
                </CardActionArea>
                <CardActions className={classes.cardTitle}>
                    <Grid container direction={"column"} justify={"center"} alignItems={"flex-start"}>
                        <Grid item style={{ width: "100%" }}>
                            <Grid container direction={"column"} justify={"space-between"}>
                                <Grid item xs={12}>
                                    {
                                        package_info.name !== undefined && 
                                        <Tooltip title={package_info.name}>
                                            <Typography variant="body2" noWrap component="p" style={{ fontWeight: "bold", fontSize: 16, paddingLeft: 4 }}>
                                                {localeText(current_locale.id, package_info.localization, package_info.name, 'name')}
                                            </Typography>
                                        </Tooltip>
                                    }
                                </Grid>
                                {
                                    destination !== undefined && destination !== null && (
                                        <Grid item xs={12}>
                                            <Typography variant="body2" component="p" style={{ fontSize: 14, textAlign: "left", paddingLeft: 4 }}>
                                                { destination[0]}
                                            </Typography>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                            <Grid container direction={"row"} justify={"space-between"} alignItems={"center"} spacing={fullScreen ? 1 : 0}>
                                <Grid item xs={12}>
                                    <HotelIcon fontSize={"small"}/>
                                    {
                                        ['visiteurs'].includes(quotation_code) &&
                                        <span> { t("itineraryType.packages") }  <InfoOutlinedIcon style={{ marginLeft: 5, verticalAlign: 'bottom' }} onClick={handleMoreInfo}/></span>
                                    }
                                    {
                                        !['visiteurs'].includes(quotation_code) &&
                                        <span>
                                            { package_info.categories.length !== 0 ? localeText(current_locale.id, package_info.categories[0].localization, package_info.categories[0].name, 'name') : (package_info.category !== null ? package_info.category.name : "")}
                                        </span>
                                    }
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "right" }}>
        							<span style={{ paddingLeft: 4, fontSize: 14 }}>
                                        { total_days + " " + (total_days > 1 ? t("global.days") : t("global.day")) } / { tmp_total_nights + " " + (tmp_total_nights > 1 ? t("itinerary.nights") : t("itinerary.night")) }
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{ width: "100%" }}>
                            <Grid container direction={"row"} justify={"space-between"} alignItems={"center"} spacing={fullScreen ? 1 : 0}>
                                <Grid item xs={5}>
                                    {
                                        (!package_info.lock_agency && !package_info.product_locked) ? (
                                            <Fragment>
                                                <LockOpen style={{ verticalAlign: "text-bottom", fontSize: 18 }}/>
                                                <span style={{ paddingLeft: 5, fontSize: 12 }}>{ t('itineraryType.unlocked-trip') }</span>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <Lock style={{ verticalAlign: "text-bottom", fontSize: 18 }}/>
                                                <span style={{ paddingLeft: 5, fontSize: 12 }}>{ t('itineraryType.locked-trip') }</span>
                                            </Fragment>
                                        )
                                    }
                                </Grid>
                                {
                                    (package_info.lowest_price !== null && (key === null || (package_info.lowest_price[key[0]] !== undefined && package_info.lowest_price[key[0]] !== null))) && (
                                        <Grid item xs={7} style={{ textAlign: "right" }}>
                                            <span>
                                                <span style={{ fontSize: 11 }}>{ t("global.starting_from") + " " }</span>
                                                <span className={ `${classes.pink} lower-price` }>{ Math.round(key === null ? package_info.lowest_price : package_info.lowest_price[key[0]]) }</span>
                                                { " " + currency_symbol }
                                                <span style={{ fontSize: 11 }}>{" / pers"}</span>
                                            </span>
                                        </Grid>
                                    )
							    }
                            </Grid>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
            <VisiteursMoreInfo info={package_info} moreInfoModal={moreInfoModal} setMoreInfoModal={setMoreInfoModal} type={'package'}/>
        </Grid>
    );
};
export default MaterialPackageCard;
