var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li>\n                        <div class=\"steps-page-item-text\">\n                            <span>Etape "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"no") : depth0), depth0))
    + " : </span>\n                            <span style=\"font-weight: bold;\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"place") : depth0), depth0))
    + " - "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"nightsCount") : depth0), depth0))
    + "</span>\n                        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"transport") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":22,"column":31}}})) != null ? stack1 : "")
    + "                    </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"steps-page-item-transport\">\n                                <img src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"transport") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\">\n                                <div>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"transport") : depth0)) != null ? lookupProperty(stack1,"duration") : stack1), depth0))
    + "</div>\n                                <div>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"transport") : depth0)) != null ? lookupProperty(stack1,"distance") : stack1), depth0))
    + "</div>\n                                <div class=\"steps-page-item-transport-triangle\"></div>\n                            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"chapter fingerprint-background\">\n    <span class=\"chapter-title\">ETAPES</span>\n    <div id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"anchor") || (depth0 != null ? lookupProperty(depth0,"anchor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchor","hash":{},"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":25}}}) : helper)))
    + "\" class=\"steps-page\">\n        <h1>\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n        </h1>\n        <div class=\"steps-page-content\">\n            <ul class=\"steps-page-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"steps") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":24,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n    </div>\n</div>";
},"useData":true});