//---- Actions ----//
import { SelectCalendarDay } from "../../../Actions/Calendar";

export default (start_date, end_date, itinerary_list, departure_destination, return_destination, select_day, dispatch) => {
    dispatch(SelectCalendarDay(select_day));
	$('.cityCalendar').each(function () {
        $('.cityCalendar').html("");
    });
    $('.fc-day-header').each(function () {
        let currentDate = new Date($(this).attr("data-date"));
        if (select_day === $(this).attr("data-date")) {
            $(this).css({'background-color' : 'rgb(101, 185, 196)'});
        }
        else {
            $(this).css({'background-color' : 'rgb(186, 186, 186)'});
        }
        let momentCurrentDate = moment.utc(currentDate);
        momentCurrentDate.set({hour: 0, minute: 0, second: 0, millisecond: 0});
        let firstDate = moment.utc(start_date);
        let lastDate = moment.utc(end_date);
        firstDate.set({hour: 0, minute: 0, second: 0, millisecond: 0});
        lastDate.set({hour: 0, minute: 0, second: 0, millisecond: 0});
        for (let i = 0; i < itinerary_list.length; i++) {
            let start = moment.utc(itinerary_list[i].start_date);
            let end = moment.utc(itinerary_list[i].end_date);
            start.set({hour: 0, minute: 0, second: 0, millisecond: 0});
            end.set({hour: 0, minute: 0, second: 0, millisecond: 0});
            let city = itinerary_list[i].destination !== null ? itinerary_list[i].destination.data.name !== undefined ? itinerary_list[i].destination.data.name.split(",") : itinerary_list[i].destination.data.international_name.split(",") : ( i === 0 ? departure_destination.city : return_destination.city);
            if (firstDate.isValid() && firstDate.date() === momentCurrentDate.date() && firstDate.month() === momentCurrentDate.month()) {
                if ($(this).find('.cityCalendar').length === 0) {
                    $(this).append('<br>' + '<div class="cityCalendar">' + departure_destination.city + '</div>');
                }
                else {
                    if ($(this).find('.cityCalendar').html().indexOf(departure_destination.city) === -1) {
                        if ($(this).find('.cityCalendar').html().length > 0)
                            $(this).find('.cityCalendar').append(" / ");
                        $(this).find('.cityCalendar').append(departure_destination.city);
                    }
                }
            }
            if (lastDate.isValid() && lastDate.date() === momentCurrentDate.date() && lastDate.month() === momentCurrentDate.month()) {
                if ($(this).find('.cityCalendar').length === 0) {
                    $(this).append('<br>' + '<div class="cityCalendar">' + return_destination.city + '</div>');
                }
                else {
                    if ($(this).find('.cityCalendar').html().indexOf(return_destination.city) === -1) {
                        if ($(this).find('.cityCalendar').html().length > 0)
                            $(this).find('.cityCalendar').append(" / ");
                        $(this).find('.cityCalendar').append(return_destination.city);
                    }
                }
            }
            if (start <= momentCurrentDate && end >= momentCurrentDate) {
                if ($(this).find('.cityCalendar').length == 0) {
                    $(this).append('<br>' + '<div class="cityCalendar">' + (typeof(city) === "string" ? city : city[0]) + '</div>');
                }
                else {
                    if ((typeof(city) === "string" ? city : city[0]) !== $(this).find('.cityCalendar').html()) {
                        if ($(this).find('.cityCalendar').html().length > 0)
                            $(this).find('.cityCalendar').append(" / ");
                        $(this).find('.cityCalendar').append((typeof(city) === "string" ? city : city[0]));
                    }
                }
            }
            else if (i === itinerary_list.length - 1 && end < momentCurrentDate) {
                if ($(this).find('.cityCalendar').length === 0) {
                    $(this).append('<br>' + '<div class="cityCalendar">' + return_destination.city + '</div>');
                }
                else {
                    if ($(this).find('.cityCalendar').html().indexOf(return_destination.city) === -1) {
                        if ($(this).find('.cityCalendar').html().length > 0)
                            $(this).find('.cityCalendar').append(" / ");
                        $(this).find('.cityCalendar').append(return_destination.city);
                    }
                }
            }
        }
    });
}