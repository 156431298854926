import { countBy } from "lodash";

export class ItineraryMarkerBag<O, T> {
    private createItem: (owner: O, index: number) => Promise<T | null>;
    private getKey: (owner: O) => string;
    private garbageCollector: (item: T) => void;
    private items: {
        [key: string]: {
            item: T,
            references: number
        }
    };

    constructor(
        createItem: (owner: O, index: number) => Promise<T | null>,
        getKey: (owner: O) => string,
        garbageCollector: (item: T) => void
    ) {
        this.createItem = createItem;
        this.getKey = getKey;
        this.garbageCollector = garbageCollector;
        this.items = {};
    }

    public async replaceOwners(owners: O[]): Promise<void> {
        //from here, remove unused items
        const ownerKeys = owners.map((owner) => {
            return this.getKey(owner);
        });

        for (const key of Object.keys(this.items)) {
            const references = ownerKeys.filter((item) => item === key).length;
            this.items[key]!.references = references;

            if (this.items[key]!.references === 0) {
                this.garbageCollector(this.items[key]!.item);
                delete this.items[key];
            }
        }

        //create items for new owners
        for (let i = 0; i < owners.length; i++) {
            const owner = owners[i]!;
            let item = this.items[this.getKey(owner)];

            if (!item) {
                const newItem = await this.createItem(owner, i);
                if (newItem) {
                    this.items[this.getKey(owner)] = {
                        item: newItem,
                        references: 1
                    };
                }
            }
        }

        //recompute references count
        const groupped = countBy(
            ownerKeys,
            (key) => key
        );

        for (const key of Object.keys(groupped)) {
            if (this.items[key]) {
                this.items[key]!.references = groupped[key] ?? 1;
            }
        }
    }

    public getMarker(owner: O): T | undefined {
        return this.items[this.getKey(owner)]?.item;
    }

    public getReferences(owner: O): number {
        return this.items[this.getKey(owner)]?.references ?? 0;
    }

    public getItems(): ItineraryMarkerBag<O, T>['items'] {
        return this.items;
    }
}
