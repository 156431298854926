//---- Dependencies ----//
import React, { Fragment } from "react";

//---- Functions ----//
import CompareArray  from "../Functions/CompareArray";
import GetPricesId from "../../Cart/Functions/Margin/GetPricesId.js";

export default (infos_reloaded, t) => {
	return (
		<Fragment>
            {
                (infos_reloaded.notes).map((note, index) => {
                    if (note !== "draganddrop locked") {
                        return (
                            <div key={ index } className={"resort-fees-box-cart row"} >
                                <div className={"icon-warning ft-font-40 col-md-2"} /> 
                                <div className={"fees-text col-md-10"}> { note } </div>
                            </div>                                                                
                        )                                                                    
                    }
                })
            }
        </Fragment>
	)
}