import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

export function CartScrollers(): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isBottom, setIsBottom] = useState(false);
    const [isTop, setIsTop] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            behavior: 'smooth',
            top: 0,
            left: 0
        });
    };

    const scrollToBottom = () => {
        window.scrollTo({
            behavior: 'smooth',
            top: document.body.scrollHeight,
            left: 0
        });
    };

    useEffect(() => {
        const scrollHandler = () => {
            setIsBottom(window.innerHeight + window.scrollY >= document.body.scrollHeight);
            setIsTop(window.scrollY === 0);
        };
        document.addEventListener('scroll', scrollHandler);
        return () => {
            dispatch({ type: 'CART_RESET_RECALC' });
            document.removeEventListener('scroll', scrollHandler);
            //if (localStorage.getItem('collapse_cart')) {
            //localStorage.removeItem('collapse_cart');
            //}
            //dispatch({type: 'CART_LEAVE_CART'});
        };
    }, []);

    return (
        <>
            {
                !isBottom && (
                    <Tooltip title={t<string>("global.scrollBottom")}>
                        <ArrowDownward style={{ position: "fixed", bottom: 19, right: 44, cursor: "pointer", fontSize: 30 }} onClick={ scrollToBottom }/>
                    </Tooltip>
                )
            }
            {
                isBottom && (
                    <Tooltip title={t<string>("global.scrollBottom")}>
                        <ArrowDownward style={{ position: "fixed", bottom: 19, right: 44, fontSize: 30, color: "gray" }}/>
                    </Tooltip>
                )
            }
            {
                !isTop && (
                    <Tooltip title={t<string>("global.scrollTop")}>
                        <ArrowUpward style={{ position: "fixed", bottom: 19, right: 10, cursor: "pointer", fontSize: 30 }} onClick={ scrollToTop}/>
                    </Tooltip>
                )
            }
            {
                isTop && (
                    <Tooltip title={t<string>("global.scrollTop")}>
                        <ArrowUpward style={{ position: "fixed", bottom: 19, right: 10, fontSize: 30, color: "gray" }}/>
                    </Tooltip>
                )
            }
        </>
    );
}
