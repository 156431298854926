import React from "react";
import { useTranslation } from "react-i18next";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@material-ui/core";

export function TripListInvoiceCreateCreditNoteModal(props) {
    const { t } = useTranslation();
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="xs"
        >
            <DialogTitle>
                {t('global.warning')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('menu.invoice.create-credit-note-warning')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{t('global.ok')}</Button>
            </DialogActions>
        </Dialog>
    );
}
