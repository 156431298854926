/*eslint-disable no-inline-comments */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import IconButton from '@mui/material/IconButton';
import Grid from "@material-ui/core/Grid";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircleIcon from '@mui/icons-material/Circle';

import '../crm.css';
import '../list.css';

import { type_options_v2 } from "../objects/data";

import { getUsers, postUser } from "../Functions/UserFunctions";
import { useTranslation } from "react-i18next";
import { SetSelectedRow, SetSelectedUser, SetLastPage } from "../../../../Actions/Crm";
import { addPreferences, getPreferences, updatePreference } from "../Functions/PreferenceFunction";
import ListHandler from "./ListHandler";
import { makeStyles } from "@material-ui/core";
import CrmUserDetailTravelerFormDialog from "../CrmUserDetail/dialogs/CrmUserDetailTravelerFormDialog";

const useStyles = makeStyles({
    paginationItemActive: {
        outline: "5px auto -webkit-focus-ring-color"
    },
    pagination: {
        "& button:focus": {
            outline: "none !important"
        }
    }
});

const CrmUserList = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const user_list = useSelector(store => store.crm.users);
    const user = useSelector(store => store.user.user);
    const selected_row = useSelector(store => store.crm.selected_row);
    const last_page = useSelector(store => store.crm.last_page);
    const selected_contact_type = useSelector(store => store.crm.selected_contact_type);
    const selected_user = useSelector(store => store.crm.selected_user);

    const [allUsersCount, setAllUsersCount] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [active_page, setActivePage] = useState(1);
    const [filterValue, setFilterValue] = useState('');
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [rowSyle, setRowSyle] = useState(null);
    const [prefExist, setPrefExist] = useState(false);
    const [openTravelerForm, setOpenTravelerForm] = React.useState(false);
    const [densityPref, setDensityPref] = React.useState(null);
    const [ordering, setOrdering] = React.useState('-date_joined');
    const [filterData, setFilterData] = React.useState({});

    useEffect(() => {
        let cols = [
            { order: 1, field: 'type', label: t("crm.type"), width: 100, valueGetter: displayClientType, sortable: true, active: true },
            { order: 2, field: 'first_name', label: t("crm.first_name"), width: 150, sortable: true, active: true },
            { order: 3, field: 'last_name', label: t("crm.last_name"), sortable: true, active: true },
            { order: 4, field: 'email', label: t("crm.email"), width: 250, sortable: true, active: true },
            { order: 5, field: 'phone_number', label: t("crm.phone"), sortable: true, active: true },
            { order: 6, field: 'last_com', label: t("crm.last_communication"), width: 200, sortable: true, active: false }, //TODO: Use the correct field
            { order: 7, field: 'date_joined', label: t('crm.creation_date'), sortable: true, active: true }
        ];

        onChangeDensity(null);

        if (!user_list?.length) {
            loadRows(1, null, selected_contact_type, ordering, filterData);
        }

        if (user?.client) {
            getPreferences(user.client, "APPS_CRM", (res) => {
                const prefs = res.data?.results;
                if (prefs.length) {
                    setPrefExist(true);
                    cols.forEach((col, col_index) => {
                        const pref = prefs.find(p => p.field_name === col.field.toUpperCase());
                        if (pref) {
                            cols[col_index].order = pref.order;
                            cols[col_index].ordering_asc = pref.ordering_asc;
                            cols[col_index].active = pref.active;
                            cols[col_index].id = pref.id;

                            if (pref.ordering_asc !== null) {
                                setOrdering(`${pref.ordering_asc ? '' : '-'}${col.field}`);
                            }
                        }
                    });

                    //density
                    const dens = prefs.find(p => p.field_name === "DENSITY");
                    if (dens) {
                        setDensityPref(dens);
                    }
                }
                cols = cols.sort((a, b) => a.order - b.order);
                setColumns(cols);
            });
        }
    }, [user_list]);

    useEffect(() => {
        loadRows(1, null, selected_contact_type, ordering, filterData);
    }, [selected_contact_type]);

    useEffect(() => {
        handlePageChange(last_page, null, selected_contact_type);
    }, []);

    useEffect(() => {
        if (selected_user?.createCode === "CREATE") {
            setOpenTravelerForm(true);
        }
    }, [selected_user]);

    const loadRows = (page, name, type, ordering, filterData) => {
        let filter = null;
        if (name) {
            filter = { search: name };
        }
        // if (type) {
        //     if (!filter) {
        //         filter = { type: type };
        //     } else {
        //         filter.type = type;
        //     }
        // }
        if (filterData) {
            if (filterData.types?.length) {
                filterData.types = filterData.types.join(',');
            }
            if (!filter) {
                filter = filterData;
            } else {
                filter = { ...filter, ...filterData };
            }
        }
        getUsers(user.client, pageSize, (page <= 0 ? 0 : page - 1) * pageSize, filter, ordering, (resp) => {
            if (resp?.data) {
                setAllUsersCount(resp.data.count);
                setRows(resp.data.results?.map(e => {
                    return { ...e, date_joined: moment(e.date_joined).format("DD/MM/YYYY") };
                }));
            }
        });
    };

    const displayClientType = (row) => {
        let color = '#939393';
        switch (row.type) {
            case 'LEAD':
                color = '#fbff08';
                break;
            case 'PROSPECT':
                color = '#ff8c16';
                break;
            case 'NON_CLIENT':
                color = '#939393';
                break;
            case 'CLIENT':
            case 'CL':
                color = '#45ff16';
                break;
            default:
                break;
        }
        const label = type_options_v2.find(e => e.value == row.type)?.label;
        return <div style={{ display: "flex", alignItems: "center", gap: 5 }}> <CircleIcon style={{ fill: color, width: 15 }} /> {label}</div>;
    };

    //CLICK ITEM
    const clickUser = (id) => {
        if (id) {
            dispatch(SetSelectedRow(id));
            props.router.push(`/${window.url_name}/menu/crm-user/${id}`);
        }
    };

    //PAGINATION
    const handlePageChange = (page) => {
        setActivePage(page);
        dispatch(SetLastPage(page));
        loadRows(page, null, selected_contact_type, ordering, filterData);
    };

    //SEARCH CLIENT 
    const handleFilterChange = (event) => {
        setFilterValue(event.target.value);
    };
    const handleFilterApply = () => {
        loadRows(1, filterValue, selected_contact_type, ordering, filterData);
    };

    //REFRESH LIST
    const refreshList = () => {
        setFilterValue('');
        setActivePage(1);
        dispatch(SetLastPage(1));
        loadRows(1, null, selected_contact_type, ordering, filterData);
    };

    //HANDLE COLUMNS
    const onUpdateColumns = (columns) => {
        setColumns(columns);
        setPreferences(columns);
    };

    const setPreferences = (columns) => {
        const prefs = columns.map((c, index) => {
            return {
                active: c.active,
                can_edit: true,
                field_name: c.field.toUpperCase(),
                module: "APPS_CRM",
                order: index + 1,
                ordering_asc: c.ordering_asc,
                id: c.id
            };
        });
        if (prefExist) {
            updatePreference(user.client, prefs, () => {
                console.log('prefs updated');
            });
        } else {
            addPreferences(user.client, prefs, () => {
                console.log('prefs added');
            });
        }
    };

    //HANDLE DENSITY
    const onChangeDensity = (density) => {
        let densPref = densityPref;
        if (!densPref) {
            densPref = {
                active: false,
                can_edit: true,
                field_name: "DENSITY",
                module: "APPS_CRM",
                order: 1,
                ordering_asc: null
            };
        }
        switch (density) {
            case 'medium':
                setRowSyle({ paddingTop: 20, paddingBottom: 20, fontSize: 13 });
                densPref.order = 2;
                break;
            case 'low':
                setRowSyle({ paddingTop: 30, paddingBottom: 30, fontSize: 14 });
                densPref.order = 1;
                break;
            default:
                setRowSyle({ paddingTop: 10, paddingBottom: 10, fontSize: 12 });
                densPref.order = 3;
                break;
        }
        //TODO: save density here
    };

    //HANDLE SORT
    const switchColumnSort = (field) => {
        let updatedColumns = JSON.parse(JSON.stringify(columns));
        const colIndex = updatedColumns.findIndex(item => item.field === field);
        let newOrdering = null;
        if (colIndex !== -1) {
            updatedColumns = updatedColumns.map(e => ({ ...e, ordering_asc: null }));

            if (!ordering.includes(field) || ordering === `-${field}`) {
                updatedColumns[colIndex].ordering_asc = true;
                newOrdering = field;
            } else {
                updatedColumns[colIndex].ordering_asc = false;
                newOrdering = `-${field}`;
            }
        }
        setOrdering(newOrdering);
        setColumns(updatedColumns);
        setPreferences(updatedColumns);
        loadRows(1, null, selected_contact_type, newOrdering);
    };

    //Create traveler
    const closeDialog = () => {
        setOpenTravelerForm(false);
        dispatch(SetSelectedUser(null));
    };
    const transformBirthDate = (inputDate) => {
        const dateObject = new Date(inputDate.substring(0, 10));
        //eslint-disable-next-line no-extend-native
        Date.prototype.toLocaleISOString = function () {
            return this.getFullYear() +
                '-' + ('0' + (this.getMonth() + 1)).slice(-2) +
                '-' + ('0' + this.getDate()).slice(-2) +
                'T' + ('0' + this.getHours()).slice(-2) +
                ':' + ('0' + this.getMinutes()).slice(-2) +
                ':' + ('0' + this.getSeconds()).slice(-2) +
                '.' + ('00' + this.getMilliseconds()).slice(-3) +
                'Z';
        };
        return dateObject.toLocaleISOString();
    };
    const createUser = (traveler) => {
        setOpenTravelerForm(false);
        let isoDate = null;
        let isoWeddingDate = null;
        if (traveler.birth_date) {
            isoDate = transformBirthDate(traveler.birth_date);
        }
        if (traveler.wedding_date) {
            isoWeddingDate = transformBirthDate(traveler.wedding_date);
        }
        const formatted_user = { ...traveler, zip_code: traveler.postal_code, street: traveler.address, birth_date: isoDate, wedding_date: isoWeddingDate, type: "LEAD" };

        postUser(user.client, formatted_user, (resp) => {
            if (resp) {
                // let user_list_updated = JSON.parse(JSON.stringify(user_list));
                // user_list_updated.push(resp.data);
                loadRows(1, null, selected_contact_type, ordering);
                dispatch(SetSelectedUser(null));
            }
        });
    };

    //FILTERS
    useEffect(() => {
        loadRows(1, null, selected_contact_type, ordering, filterData);
    }, [filterData]);

    return (
        <Container className="crm-user-list">
            <Grid container>
                <Grid itme xs={3}>
                    <Typography variant={'h6'} >{allUsersCount} {t("crm.list_title")}</Typography>
                    <IconButton className="refresh-button" onClick={refreshList}><RefreshIcon /></IconButton>
                </Grid>
                <Grid itme xs={7}>
                    <TextField
                        variant="outlined" InputLabelProps={{ shrink: false }} size="small" value={filterValue} onChange={handleFilterChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
                        }}
                    />
                    <Button variant="contained" onClick={handleFilterApply}>Rechercher</Button>
                </Grid>
            </Grid>

            <div className="list-container">
                <ListHandler
                    columns={columns}
                    onUpdateColumns={onUpdateColumns}
                    onChangeDensity={onChangeDensity}
                    customFilter={{
                        options: type_options_v2,
                        onChange: (v) => setFilterData({ ...filterData, types: v }),
                        multiple: true,
                        label: t('crm.choose_contact_type')
                    }}
                    onCreatorChange={(v) => setFilterData({ ...filterData, creator__username: v })}
                    onEditorChange={(v) => setFilterData({ ...filterData, last_author__username: v })}
                    periodFilter={{
                        onChange: (qp) => {
                            const fil_c = JSON.parse(JSON.stringify(filterData));
                            const [property, value] = qp.split('=');
                            fil_c[property] = value;
                            setFilterData(fil_c);
                        },
                        options: [
                            { value: "date_joined", label: t('crm.creation_date') },
                            { value: "modified_date", label: t('crm.modified_date') },
                            { value: "last_communication", label: t('crm.last_communication') }
                        ]
                    }}
                />
                <table>
                    <tr>
                        {
                            columns.map(column => {
                                if (!column.active) {
                                    return <></>;
                                }
                                return (
                                    <th className={column.sortable ? 'sortable-th' : ''} onClick={() => switchColumnSort(column.field)}>
                                        {column.label} {
                                            column.sortable &&
                                            <>
                                                {ordering === `-${column.field}` && <ArrowDownwardIcon style={{ opacity: 1 }} />}
                                                {ordering === column.field && <ArrowUpwardIcon style={{ opacity: 1 }} />}
                                                {!ordering?.includes(column.field) && <ArrowUpwardIcon onClick={() => switchColumnSort(column.field)} />}
                                            </>
                                        }
                                    </th>
                                );
                            })
                        }
                    </tr>
                    {
                        rows?.map(row => {
                            return (
                                <tr className={`clickable_row ${selected_row === row.id ? "selected_row" : ""}`} onClick={() => clickUser(row.id)}>
                                    {
                                        columns.map(column => {
                                            if (!column.active) {
                                                return <></>;
                                            }
                                            return (
                                                <td style={{ ...rowSyle, width: column.flex ? '' : column.width }}>{column.valueGetter !== undefined ? column.valueGetter(row) : row[column.field]}</td>
                                            );
                                        })
                                    }
                                </tr>
                            );
                        })
                    }
                </table>
            </div>

            <div className="list-pagination">
                {
                    rows && rows.length > 0 &&
                    <Pagination
                        className={classes.pagination}
                        size={"medium"}
                        page={active_page}
                        count={Math.ceil(allUsersCount / pageSize)}
                        variant={"outlined"}
                        hideNextButton={(allUsersCount === 0 || active_page === Math.ceil(allUsersCount / pageSize))}
                        hidePrevButton={active_page === 1} disabled={allUsersCount === 0}
                        onChange={(e, v) => handlePageChange(v)}
                        renderItem={(item) => {
                            return (
                                <PaginationItem className={`${item.selected ? classes.paginationItemActive : ""}`} {...item} />
                            );
                        }}
                    />
                }
            </div>
            {
                selected_user &&
                <CrmUserDetailTravelerFormDialog
                    open={openTravelerForm}
                    handleClose={closeDialog}
                    onConfirm={createUser}
                    mode={'user'}
                    title={t('crm.create_contact')}
                />
            }
        </Container>
    );
};
export default React.memo(CrmUserList);
