import React, { useEffect, useState, useRef, Fragment } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import Webcam from "react-webcam";
import Cropper from 'react-cropper';
import {useSnackbar} from "notistack";
import axios from "axios";
import clsx from "clsx";


import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';

import AddAPhotoIcon from '@material-ui/icons/AddAPhotoOutlined';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibraryOutlined';
import CheckIcon from '@material-ui/icons/Check';
import CachedIcon from '@material-ui/icons/Cached';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import i18n from "../../../i18n.jsx";
import GetCookie from "../../Common/Functions/GetCookie.js";

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
  }

const useStyles = makeStyles((theme) => ({
    input: {
      display: 'none',
    },
    margintop10: {
        marginTop: 10
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}));

const ChangePicture = ({openModal, setOpenModal, setAvatar, setAvatarId, avatar}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const [step, setStep] = useState(1);
    const [tmpAvatar, setTmpAvatar] = useState(null);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const cropperRef = useRef();
    const camRef = useRef();

    const user = useSelector(store => store.user.user);

    const videoConstraints = {
        width: 340,
        height: fullScreen ? 272 : 335,
        facingMode: "user"
    };

    useEffect(() => {
        if (avatar !== null) {
            resetAvatar();
        }
    }, [avatar, openModal])

    const handleClose = () => {
        resetAvatar();
        setOpenModal(false);
        setStep(1);
    }
    const changeImgHandler = (img) => {
        let reader = new FileReader();
        let file = img;
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setTmpAvatar(reader.result);
            setFile(file);
        };
    }
    const handleValidate = () => {
        setLoading(true);
        savePicture();
    }
    const savePicture = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            cropperRef.current.getCroppedCanvas().toBlob(function (blob) {
                let request = new FormData();
                request.append("name", "Avatar");
                request.append("description", "New avatar");
                request.append('image', blob);
                axios({
                    method: "POST",
                    headers: {
                        "Authorization": "Token " + GetCookie("token"),
                        "Accept": "application/json",
                        "Accept-Language": i18n.language
                    },
                    url: `${API_HREF}client/${user.client}/pictures/?type=avatar`,
                    data: request,
                    onUploadProgress: function (progressEvent) {
                        // Do whatever you want with the native progress event
                        setProgress(progressEvent.loaded / progressEvent.total * 100)
                    },
                }).then(function (response) {
                    let new_avatar = {
                        description: response.data.description,
                        id: response.data.id,
                        name: response.data.name,
                        thumbnail_little: response.data.thumbnail_little,
                        url: response.data.url
                    };
                    let request1 = {
                        avatar_id: new_avatar.id
                    };
                    axios({
                        method: "PATCH",
                        headers: headers,
                        url:`${API_HREF}client/${user.client}/user/${user.id}/`,
                        data: JSON.stringify(request1),
                    }).then(function (response) {
                        let data = Object.assign({}, response.data);
                        dispatch({
                            type: "USER_SET_USER",
                            payload: {
                                user: data
                            }
                        });
                        setAvatarId(new_avatar.id);
                        setLoading(false);
                        setProgress(0);
                        handleClose();
                    }).catch(function (error) {
                        //TODO: show snackbar error
                        console.log('error:', error);
                        enqueueSnackbar(t("notify.changed_failed"), {
                            variant: "error",
                        });
                    });
                }).catch(function (error) {
                    setLoading(false);
                    setProgress(0);
                    console.log('error:', error);
                });
            });
        }
    }
    const resetAvatar = () => {
        setTmpAvatar(avatar !== null ? (avatar.thumbnail_little !== null ? avatar.thumbnail_little : avatar.url) : "Img/avatar.png");
    }
    const takePicture = () => {
        setTmpAvatar(camRef.current.getScreenshot())
        setStep(1);
    }
    return (
        <Dialog
            fullScreen={fullScreen}
            open={openModal}
            onClose={handleClose}
        >
        {
            step === 1 && (
                <Fragment>
                    <DialogTitle>
                        <Grid container justify={ "space-between" } alignItems={ "center" }>
                        {
                            fullScreen ? (
                                <Grid item>
                                    <IconButton edge={ "end" } onClick={ handleClose }>
                                        <NavigateBeforeIcon/>
                                    </IconButton>
                                    <span style={{fontSize: 16}}>{t("menu.profile.explication_title")}</span>
                                </Grid>
                            ) : (
                                <Fragment>
                                    <Grid item>
                                        {t("menu.profile.explication_title")}
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={ handleClose }><CloseIcon/></IconButton>
                                    </Grid>
                                </Fragment>
                            )
                        }
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction={"row"}>
                            <Grid item md={11} xs={12}>
                                <Cropper
                                    ref={cropperRef}
                                    src={tmpAvatar}
                                    style={{height: 350, width: '100%'}}
                                    // Cropper.js options
                                    aspectRatio={1}
                                    guides={false} 
                                    viewMode= {1}
                                    highlight= {false}
                                    cropBoxResizable= {false}
                                    cropBoxMovable= {false}
                                    dragMode={'move'}
                                    background= {false}
                                    zoomable= {true}
                                    zoomOnWheel= {true}
                                    autoCropArea= {0.8}
                                    toggleDragModeOnDblclick= {false}
                                    checkCrossOrigin= {true}
                                    preview= {'.img-preview'}
                                />
                            </Grid>
                            <Grid item md={1} xs={12} className={clsx({[classes.margintop10]: fullScreen})}>
                                <Grid container direction={ fullScreen ? "row" : "column" } justify={"center"} alignItems={"center"}>
                                    <Grid item>
                                        <IconButton onClick={() => cropperRef.current.zoom(0.1)}>
                                            <ZoomInIcon/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={() => cropperRef.current.zoom(-0.1)}>
                                            <ZoomOutIcon/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={t("menu.profile.reset_zoom")}>
                                            <IconButton onClick={() => cropperRef.current.reset()}>
                                                <CachedIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={t("accommodation.cancel")}>
                                            <IconButton onClick={ resetAvatar }>
                                                <CloseIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={t("cart.edit_booking.validate")}>
                                            <IconButton style={{backgroundColor: "#E6592F", color: "white"}} onClick={handleValidate}>
                                                <CheckIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                loading && (
                                    <Grid item xs={12} style={{marginBottom: 10}}>
                                        <LinearProgressWithLabel value={progress}/>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                            <Grid item>
                                <input
                                    accept="image/*"
                                    className={classes.input}
                                    id="contained-button-file"
                                    type="file"
                                    onChange={ (event) => { changeImgHandler(event.target.files[0]) }}
                                />
                                <label htmlFor="contained-button-file" style={{marginBottom: 0}}>
                                    <Button startIcon={<PhotoLibraryIcon/>} component="span">{t("menu.profile.from_img")}</Button>
                                </label>
                            </Grid>
                            <Grid item>
                                <Button startIcon={<AddAPhotoIcon/>} onClick={() => setStep(2)}>{t("menu.profile.from_camera")}</Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Fragment>
                )
            }
        {
            step === 2 && (
                <Fragment>
                    <DialogTitle>
                        <Grid container justify={ "space-between" } alignItems={ "center" }>
                        {
                            fullScreen ? (
                                <Grid item>
                                    <IconButton edge={ "end" } onClick={ () => setStep(1) }>
                                        <NavigateBeforeIcon/>
                                    </IconButton>
                                    <span style={{fontSize: 16}}>{t("menu.profile.webcam")}</span>
                                </Grid>
                            ) : (
                                <Fragment>
                                    <Grid item>
                                        {t("menu.profile.webcam")}
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={ () => setStep(1) }><CloseIcon/></IconButton>
                                    </Grid>
                                </Fragment>
                            )
                        }
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                    <Grid container direction={"row"}>
                        <Grid item xs={12} style={{ height: 335, background: "gray"}}>
                            <Webcam
                                audio={false}
                                ref={camRef}
                                screenshotFormat="image/jpeg"
                                height={335}
                                width={fullScreen ? 272 : 335}
                                videoConstraints={videoConstraints}
                            />
                        </Grid>
                    </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                            <Grid item>
                                <Button startIcon={<AddAPhotoIcon/>} onClick={takePicture}>{t("menu.profile.from_camera")}</Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Fragment>
            )
        }
        </Dialog>
    )
};
export default React.memo(ChangePicture);