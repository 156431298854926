//---- Dependencies ----//
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

//---- Commons ----//
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

//---- CSS ----//

//---- Actions ----//
import { SetHover } from "../../../Actions/Summary";

//---- Functions & Components ----//
import SummaryStep from "./SummaryStep.jsx";
import SummaryMainMap from "./SummaryMainMap.jsx";
import GetIconType from "../../ItineraryTypeDetails/Functions/GetIconType.js";

//---- Class ----//
@connect((store) => {
    return {
        itinerary: store.summary.itinerary,
        trip_info: store.summary.trip_info,
        cars_cart: store.summary.cars_cart,
        flight_cart: store.summary.flight_cart,
        manual_cart: store.summary.manual_cart,
        hotel_cart: store.summary.hotel_cart
    }
})
@withTranslation()
class Summary extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            has_start_flight: [],
            has_end_flight: []
        }
    }

    getNumberType(number) {
        let nb_type = 0;
        if (number % 2 === 0)
            nb_type = 1;
        else
            nb_type = 2;
        return nb_type;
    }

    checkStep(step, index_step) {
        const { itinerary, manual_cart, flight_cart, cars_cart, hotel_cart } = this.props;
        let tmp_obj = {
            more_details_flight: [],
            more_details_car: [],
            more_details_hotel: [],
            more_details_manual: []
        };
        hotel_cart.map((room, index_room) => {
            if ((moment.utc(room.start_date).isAfter(moment.utc(step.start_date)) || moment.utc(room.start_date).isSame(moment.utc(step.start_date), "d")) && (moment.utc(room.end_date).isBefore(moment.utc(step.end_date)) || moment.utc(room.end_date).isSame(moment.utc(step.end_date), "d"))) {
                if (room.is_displayed === true) {
                   tmp_obj.more_details_hotel.push(room);
                }
            }
        });
       manual_cart.map((manual_product, index_room) => {
            if ((moment.utc(manual_product.start_date).isAfter(moment.utc(step.start_date)) || moment.utc(manual_product.start_date).isSame(moment.utc(step.start_date), "d")) && (moment.utc(manual_product.end_date).isBefore(moment.utc(step.end_date)) || moment.utc(manual_product.end_date).isSame(moment.utc(step.end_date), "d"))) {
                if (manual_product.product_type !== 2 && manual_product.product_type !== 5 && manual_product.product_type !== 13 && manual_product.product_type !== 8) {
                    let do_not_push = false;
                    if ((manual_product.product_type === 12 || manual_product.product_type === 1) && moment.utc(manual_product.end_date).isSame(moment.utc(step.end_date), "d") && moment.utc(manual_product.start_date).isSame(moment.utc(step.end_date), "d")) {
                        do_not_push = true;
                    }
                    if (do_not_push === false) {
                        tmp_obj.more_details_manual.push(manual_product);
                    }
                }
            }
        });
        if (step.r2r_json.vehicle !== undefined) {
            if (step.r2r_json.vehicle.kind === "car" || step.r2r_json.vehicle.kind === "plane") {
                if (step.r2r_json.vehicle.kind === "car") {
                    cars_cart.map((car) => {
                        if ((moment.utc(car.start_date).isAfter(moment.utc(step.start_date)) || moment.utc(car.start_date).isSame(moment.utc(step.start_date), "d")) && (moment.utc(car.end_date).isBefore(moment.utc(step.end_date)) || moment.utc(car.end_date).isSame(moment.utc(step.end_date), "d"))) {
                            if (car.is_displayed === true) {
                                tmp_obj.more_details_car.push(car);
                            }
                        } else if ((moment.utc(step.start_date).isAfter(moment.utc(car.start_date)) || moment.utc(step.start_date).isSame(moment.utc(car.start_date), "d")) && (moment.utc(step.end_date).isBefore(moment.utc(car.end_date)) || moment.utc(step.end_date).isSame(moment.utc(car.end_date), "d"))) {
                            if (car.is_displayed === true) {
                               tmp_obj.more_details_car.push(car);
                            }
                        }
                    });
                }
                if (step.r2r_json.vehicle.kind === "plane") {
                    flight_cart.map((flight, flight_index) => {
                        for (let i = 0; i < flight.outbounds.length; i++) {
                            let tmp_obj_flight = {
                                more_details: null,
                                segment: null
                            };
                            let legs = flight.outbounds[i].legs;
                            if (itinerary[index_step + 1] !== undefined) {
                                if ((moment.utc(legs[0].departure_time).isAfter(moment.utc(itinerary[index_step].start_date)) || moment.utc(legs[0].departure_time).isSame(moment.utc(itinerary[index_step].start_date), "d"))
                                    && (moment.utc(legs[legs.length - 1].arrival_time).isBefore(moment.utc(itinerary[index_step + 1].end_date)) || moment.utc(legs[legs.length - 1].arrival_time).isSame(moment.utc(itinerary[index_step + 1].end_date), "d"))) {
                                    if (flight.is_displayed === true) {
                                        tmp_obj_flight.more_details = flight;
                                        tmp_obj_flight.segment = flight.outbounds[i];
                                    }
                                } else if ((moment.utc(itinerary[index_step].start_date).isAfter(moment.utc(legs[0].departure_time)) || moment.utc(itinerary[index_step].start_date).isSame(moment.utc(legs[0].departure_time), "d"))
                                    && (moment.utc(itinerary[index_step + 1].end_date).isBefore(moment.utc(legs[legs.length - 1].arrival_time)) || moment.utc(itinerary[index_step + 1].end_date).isSame(moment.utc(legs[legs.length - 1].arrival_time), "d"))) {
                                    if (flight.is_displayed === true) {
                                        tmp_obj_flight.more_details = flight;
                                        tmp_obj_flight.segment = flight.outbounds[i];
                                    }
                                }
                            }

                            if (tmp_obj_flight.more_details !== null) {
                                tmp_obj.more_details_flight.push(tmp_obj_flight);
                            }
                        }
                    });
                }
            }
        }
        return tmp_obj;
    }

    componentWillMount() {
        const { itinerary, manual_cart, flight_cart, trip_info } = this.props;

        let tmp_arr_end = [];
        let tmp_arr_start = [];
        let no_double_flight = [];


        flight_cart.map((flight, flight_index) => {
            let tmp_obj_end = {
                more_details: null,
                segment: null
            };
            let tmp_obj_start = {
                more_details: null,
                segment: null
            };
            for (let i = 0; i < flight.outbounds.length; i++) {
                let legs = flight.outbounds[i].legs;
                if ((moment.utc(legs[0].departure_time).isAfter(moment.utc(itinerary[0].start_date)) || moment.utc(legs[0].departure_time).isSame(moment.utc(itinerary[0].start_date), "d"))
                    && (moment.utc(legs[legs.length - 1].arrival_time).isBefore(moment.utc(itinerary[1].end_date)) || moment.utc(legs[legs.length - 1].arrival_time).isSame(moment.utc(itinerary[1].end_date), "d"))) {
                    if (flight.is_displayed === true) {
                        tmp_obj_start.more_details = flight;
                        tmp_obj_start.segment = flight.outbounds[i];
                    }
                } else if ((moment.utc(itinerary[0].start_date).isAfter(moment.utc(legs[0].departure_time)) || moment.utc(itinerary[0].start_date).isSame(moment.utc(legs[0].departure_time), "d"))
                    && (moment.utc(itinerary[1].end_date).isBefore(moment.utc(legs[legs.length - 1].arrival_time)) || moment.utc(itinerary[1].end_date).isSame(moment.utc(legs[legs.length - 1].arrival_time), "d"))) {
                    if (flight.is_displayed === true) {
                        tmp_obj_start.more_details = flight;
                        tmp_obj_start.segment = flight.outbounds[i];
                    }
                }

                if ((moment.utc(legs[0].departure_time).isAfter(moment.utc(itinerary[itinerary.length - 1].end_date)) || moment.utc(legs[0].departure_time).isSame(moment.utc(itinerary[itinerary.length - 1].end_date), "d"))
                    && (moment.utc(legs[legs.length - 1].arrival_time).isBefore(moment.utc(trip_info.end_date)) || moment.utc(legs[legs.length - 1].arrival_time).isSame(moment.utc(trip_info.end_date), "d"))) {
                    if (flight.is_displayed === true) {
                        tmp_obj_end.more_details = flight;
                        tmp_obj_end.segment = flight.outbounds[i];
                    }
                } else if ((moment.utc(itinerary[itinerary.length - 1].end_date).isAfter(moment.utc(legs[0].departure_time)) || moment.utc(itinerary[itinerary.length - 1].end_date).isSame(moment.utc(legs[0].departure_time), "d"))
                    && (moment.utc(trip_info.end_date).isBefore(moment.utc(legs[legs.length - 1].arrival_time)) || moment.utc(trip_info.end_date).isSame(moment.utc(legs[legs.length - 1].arrival_time), "d"))) {
                    if (flight.is_displayed === true) {
                        tmp_obj_end.more_details = flight;
                        tmp_obj_end.segment = flight.outbounds[i];
                    }
                }
                if (tmp_obj_end.more_details !== null) {
                    tmp_arr_end.push(tmp_obj_end);
                }

                if (tmp_obj_start.more_details !== null) {
                    let is_in = false;
                    for (let t = 0; t < no_double_flight.length; t++) {
                        if (no_double_flight[t] === flight.id + flight_index) {
                            is_in = true;
                        }
                    }
                    if (is_in === false) {
                        tmp_arr_start.push(tmp_obj_start);
                        no_double_flight.push(flight.id + flight_index);
                    }
                    tmp_obj_start = {
                        more_details: null,
                        segment: null
                    };
                }
            }
        });

        this.setState({
            has_start_flight: tmp_arr_start,
            has_end_flight: tmp_arr_end
        });

    }

    render() {
        const { itinerary, dispatch, t } = this.props;
        let last_day = 0;
        let nb_nights = 0;
        return (
            <div id={"summary-of-summary-container"} >
                {
                    this.state.has_start_flight.length > 0 && (
                        <div className={"first-type-container row ft-no-margin" + " " + ( this.getNumberType(0) === 1 ? "light-orange" : "white") }>
                            <div className={"col-md-2 col-4 summary-of-summary-first-text"} >
                                <h4 >{ t("header.start_date") } </h4>
                            </div>
                            <div className={"pt5 col-md-10 col-8 row"} >
                                {
                                    this.state.has_start_flight.map((flight, index_flight) => {
                                        let last_leg = 0;
                                        if (flight.segment.legs.length > 1) {
                                            last_leg = flight.segment.legs.length - 1;
                                        } else {
                                            last_leg = 0;
                                        }
                                        let departure_locale = flight.segment.legs[0].departure_time.split("Z");
                                        let departure_date = moment.utc(departure_locale[0]).format("dddd Do MMMM YYYY");
                                        let origin_city = flight.segment.legs[0].origin !== null ? flight.segment.legs[0].origin.iata_city.international_name : flight.segment.legs[0].origin_station.iata_city.international_name;
                                        let dest_city = flight.segment.legs[last_leg].destination !== null ? flight.segment.legs[last_leg].destination.iata_city.international_name : flight.segment.legs[last_leg].destination_station.iata_city.international_name;
                                        return (
                                            <div className={"end-and-start-summary-container col-md-12 no-padding"} key={index_flight}>
                                                <i className={"icon-020-flight ft-pr5"} />
                                                { origin_city + " - " } {flight.segment.legs[0].origin !== null ? flight.segment.legs[0].origin.iata_city.international_name : flight.segment.legs[0].origin_station.iata_city.international_name} {flight.segment.legs[0].origin !== null ? (flight.segment.legs[0].origin.name !== undefined ? flight.segment.legs[0].origin.name : flight.segment.legs[0].origin.international_name) : flight.segment.legs[0].origin_station.international_name} ({flight.segment.legs[0].origin !== null ? flight.segment.legs[0].origin.airport_code : flight.segment.legs[0].origin_station.station_code}) { " → " + dest_city + ", " + departure_date }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    )
                }
                {
                    itinerary.map((step, index) => {
                        let info = this.checkStep(step, index);
                        let color = this.getNumberType(index);
                        let first_day = last_day;
                        last_day = first_day + moment.utc(step.end_date).diff(moment.utc(step.start_date), "d") + 1;
                        nb_nights = moment.utc(step.end_date).diff(moment.utc(step.start_date), "d") + 1;
                        if (step.step_type === "STEP") {
                            let nb_char = 0;
                            if (step.destination.data.name !== undefined && step.destination.data.name !== null) {
                                nb_char = (step.destination.data.name).length;
                            } else {
                                nb_char = (step.destination.data.international_name).length;
                            }
                            let size_font = "normal";
                            if (nb_char >= 50) {
                                size_font = "little";
                            }
                            if (nb_char >= 100) {
                                size_font = "smaller";
                            }
                            if (nb_char >= 150) {
                                size_font = "more_small";
                            }
                            return (
                                <div key={index} className={"first-type-container row ft-no-margin" + " " + (color === 1 ? "light-orange" : "white") } onMouseEnter={() => { dispatch(SetHover(step.id)); }} onMouseLeave={() => { dispatch(SetHover(null)); }}>
                                    <div className={"col-md-2 col-4 summary-of-summary-first-text"} >
                                        <h4>Etape { index } </h4>
                                        <span className={"day-infos"} >Jour { first_day } à  { last_day } </span>
                                        <div className={"summary-nb-nights"}> ({ nb_nights > 1 ? nb_nights + " " + t("itinerary.nights") : nb_nights + " " + t("itinerary.night") }) </div>
                                    </div>
                                    <div className={"col-md-10 col-8 row"} >
                                        <h1 className={"desti-name-summary col-md-12 no-padding" + " " + size_font} > { (step.destination.data.name !== undefined && step.destination.data.name !== null) ? step.destination.data.name : step.destination.data.international_name }<span className={"col-md-12 no-padding "} >{", "} Du { moment.utc(step.start_date).format("DD/MM") } au { moment.utc(step.end_date).format("DD/MM") } </span> </h1>
                                        {
                                            info.more_details_hotel.length > 0 && (
                                                <div className={"col-md-12 no-padding"} >
                                                    {
                                                        info.more_details_hotel.map((hotel, index_hotel) => {
                                                            let stars = [];
                                                            for (let i = 0; i < hotel.hotel[0].stars; i++) {
                                                                stars.push(<i key={ i } className={ "icon-045-star" }/>)
                                                            }
                                                            return (
                                                                <span key={index_hotel}>
                                                                    <i className={"icon-doublebed ft-pr5"} d/>
                                                                    { hotel.hotel[0].name }
                                                                     <span> { stars } </span>
                                                                </span>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            info.more_details_manual.length > 0 && (
                                                <div className={"col-md-12 no-padding"} >
                                                    {
                                                        info.more_details_manual.map((manual, index_manual) => {
                                                            let stars = [];
                                                            for (let i = 0; i < manual.stars; i++) {
                                                                stars.push(<i key={ i } className={ "icon-045-star" }/>)
                                                            }
                                                            return (
                                                                <span key={index_manual}>
                                                                    <i className={"ft-pr5 icon-" + GetIconType(manual.product_type)} />
                                                                    { manual.name }
                                                                <span> { stars } </span>
                                                                </span>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            info.more_details_car.length > 0 && (
                                                <div className={"col-md-12 no-padding"} >
                                                    {
                                                        info.more_details_car.map((car, index_car) => {
                                                            return (
                                                                <span key={index_car}>
                                                                    <i className={"icon-car ft-pr5"} />
                                                                    { car.name }
                                                                </span>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            info.more_details_flight.length > 0 && (
                                                <div className={"pt5 col-md-10 col-8 row"} >
                                                    {
                                                        info.more_details_flight.map((flight, index_flight) => {
                                                            let last_leg = 0;
                                                            if (flight.segment.legs.length > 1) {
                                                                last_leg = flight.segment.legs.length - 1;
                                                            } else {
                                                                last_leg = 0;
                                                            }
                                                            let departure_locale = flight.segment.legs[0].departure_time.split("Z");
                                                            let departure_date = moment.utc(departure_locale[0]).format("dddd Do MMMM YYYY");
                                                            let origin_city = flight.segment.legs[0].origin !== null ? flight.segment.legs[0].origin.iata_city.international_name : flight.segment.legs[0].origin_station.iata_city.international_name;
                                                            let dest_city = flight.segment.legs[last_leg].destination !== null ? flight.segment.legs[last_leg].destination.iata_city.international_name : flight.segment.legs[last_leg].destination_station.iata_city.international_name;
                                                            if (index + 2 !== itinerary.length) {
                                                                return (
                                                                    <div className={"end-and-start-summary-container col-md-12 no-padding"} key={index_flight}>
                                                                        <i className={"icon-020-flight ft-pr5"} />
                                                                        { origin_city + " - " } {flight.segment.legs[0].origin !== null ? flight.segment.legs[0].origin.iata_city.international_name : flight.segment.legs[0].origin_station.iata_city.international_name} {flight.segment.legs[0].origin !== null ? (flight.segment.legs[0].origin.name !== undefined ? flight.segment.legs[0].origin.name : flight.segment.legs[0].origin.international_name) : flight.segment.legs[0].origin_station.international_name} ({flight.segment.legs[0].origin !== null ? flight.segment.legs[0].origin.airport_code : flight.segment.legs[0].origin_station.station_code}) { " → " + dest_city + ", " + departure_date }
                                                                    </div>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            );
                        }
                    })
                }
                {
                    this.state.has_end_flight.length > 0 && (
                        <div className={"first-type-container row ft-no-margin" + " " + ( this.getNumberType(itinerary.length + 1) === 1 ? "light-orange" : "white") }>
                            <div className={"col-md-2 col-4 summary-of-summary-first-text"} >
                                <h4 >{ t("header.end_date") } </h4>
                            </div>
                            <div className={"pt5 col-md-10 col-8 row"} >
                                {
                                    this.state.has_end_flight.map((flight, index_flight) => {
                                        let last_leg = 0;
                                        if (flight.segment.legs.length > 1) {
                                            last_leg = flight.segment.legs.length - 1;
                                        } else {
                                            last_leg = 0;
                                        }
                                        let departure_locale = flight.segment.legs[0].departure_time.split("Z");
                                        let departure_date = moment.utc(departure_locale[0]).format("dddd Do MMMM YYYY");
                                        let origin_city = flight.segment.legs[0].origin !== null ? flight.segment.legs[0].origin.iata_city.international_name : flight.segment.legs[0].origin_station.iata_city.international_name;
                                        let dest_city = flight.segment.legs[last_leg].destination !== null ? flight.segment.legs[last_leg].destination.iata_city.international_name : flight.segment.legs[last_leg].destination_station.iata_city.international_name;
                                        return (
                                            <div className={"end-and-start-summary-container col-md-12 no-padding"} key={index_flight}>
                                                <i className={"icon-020-flight ft-pr5"} />
                                                { origin_city + " - " } {flight.segment.legs[0].origin !== null ? flight.segment.legs[0].origin.iata_city.international_name : flight.segment.legs[0].origin_station.iata_city.international_name} {flight.segment.legs[0].origin !== null ? (flight.segment.legs[0].origin.name !== undefined ? flight.segment.legs[0].origin.name : flight.segment.legs[0].origin.international_name) : flight.segment.legs[0].origin_station.international_name} ({flight.segment.legs[0].origin !== null ? flight.segment.legs[0].origin.airport_code : flight.segment.legs[0].origin_station.station_code}) { " → " + dest_city + ", " + departure_date }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

export default Summary;