import React from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import CartAccommodationNote from "./CartAccommodationNote";
import { AccommodationCart } from "../Itinerary/objects/accommodationCart";

type Props = {
    open: boolean,
    accommodation: AccommodationCart,
    onClose: () => void,
    onCloseMenu: () => void,
}

export function CartConstructionProductsTableEditHotelNoteModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            onClick={(event) => event.stopPropagation()}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" fontWeight="bold" component="div">
                        {t<string>('cart-material.add-note')}
                    </Typography>
                    <IconButton onClick={props.onClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ marginTop: 1 }}>
                    <CartAccommodationNote
                        accommodation={props.accommodation}
                        onAddNote={props.onCloseMenu}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
}
