import GetPricesArr from "./GetPricesArr.js";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import axios from "axios";

function Callback(prices_terrestrial_results, prices_flight_results, promos_results, periods_results, setPriceData, variant_selected, current_version, dispatch) {
    let price_data = GetPricesArr(prices_terrestrial_results, prices_flight_results, promos_results, periods_results, variant_selected, current_version);
    dispatch({ type: 'ITINERARY_TYPE_SET_PERIODS', payload: periods_results });
    setPriceData(price_data);
}

export default (package_info, package_id, setPriceData, current_version, variant_selected, type, dispatch) => {
    const { pass_check, headers } = CheckBeforeRequest();
    let owner = window.id_owner;
    if (pass_check) {
        let request_product = [];
        for (const contract of package_info?.contracts ?? []) {
            request_product.push(
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${owner}/${type}/${package_id}/contracts/${contract.id}/product-period/?margin=true&limit=1000&start_date_after_today=true`
                })
            );
            request_product.push(
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${owner}/${type}/${package_id}/contracts/${contract.id}/prices-terrestrial/?margin=true&limit=1000`
                })
            );
            request_product.push(
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${owner}/${type}/${package_id}/contracts/${contract.id}/prices-flight/?margin=true&limit=1000`
                })
            );
            request_product.push(
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${owner}/${type}/${package_id}/contracts/${contract.id}/promos/?margin=true`
                })
            );
        }
        axios.all([...request_product]).then(axios.spread((...responses) => {
            let periods_results = [];
            let prices_terrestrial_results = [];
            let prices_flight_results = [];
            let promos_results = [];
            for (let i = 0; i < responses.length; i++) {
                if (responses[i].config.url.includes('product-period')) {
                    periods_results = [...periods_results, ...responses[i].data.results];
                } else if (responses[i].config.url.includes('prices-terrestrial')) {
                    prices_terrestrial_results = [...prices_terrestrial_results, ...responses[i].data];
                } else if (responses[i].config.url.includes('prices-flight')) {
                    prices_flight_results = [...prices_flight_results, ...responses[i].data];
                } else if (responses[i].config.url.includes('promos')) {
                    promos_results = [...promos_results, ...responses[i].data];
                }
            }
            Callback(prices_terrestrial_results, prices_flight_results, promos_results, periods_results, setPriceData, variant_selected, current_version, dispatch);
        })).catch((errors) => {
            console.log('errors:', errors);
        });
    }
};

