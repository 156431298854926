/*eslint-disable eqeqeq */
/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import axios from "axios";

import Container from '@material-ui/core/Container';
import { Button, Grid, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Edit from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import InfoIcon from '@mui/icons-material/Info';
import NoteIcon from '@mui/icons-material/Note';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import SmsIcon from '@mui/icons-material/Sms';

import { getUsersContactSheet, getUsers, getTripInvoiceHistory, patchUser, getUser } from "../Functions/UserFunctions";

import '../crm.css';

import CrmUserDetailSidePanel from "./CrmCustomerCard/CrmUserDetailSidePanel";
import CrmUserDetailActivity from "./CrmUserActivity/CrmUserDetailActivity";
import CrmUserDetailNoteDialog from './dialogs/CrmUserDetailNoteDialog';
import CrmUserDetailEmailDialog from './dialogs/CrmUserDetailEmailDialog';

import CheckIcon from '@mui/icons-material/Check';

import moment from "moment";
import { SetSelectedDemand, SetSelectedUser } from "../../../../Actions/Crm";
import TripList from "../../MaterialTripList/TripList";
import CrmUserDetailContactField from "./CrmCustomerCard/CrmUserDetailContactField";
import { SetUserList } from "../../../../Actions/User";
import CrmUserDetailTripFormDialog from "./dialogs/CrmUserDetailTripFormDialog";
import CrmDemandFormDialog from "../CrmDemandList/CrmDemandFormDialog";
import { SetTripInfo } from "../../../../Actions/Menu";

import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";

const CrmUserDetail = withRouter((props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const user_list = useSelector(store => store.crm.users);
    const user = useSelector(store => store.user.user);
    const selected_user = useSelector(store => store.crm.selected_user);
    const selected_demand = useSelector(store => store.crm.selected_demand);

    const [expand, setExpand] = useState(null);
    const [invoice_trip_id, setInvoiceTripId] = useState(null);
    const [invoices, setInvoices] = useState(null);
    const [openCreateTrip, setOpenCreateTrip] = useState(false);
    const [currentAction, setCurrentAction] = useState(null);
    const [openDemandForm, setOpenDemandForm] = React.useState(false);
    const [trip_stats, setTripStats] = React.useState({});

    const returnToList = () => {
        props.router.push(`/${window.url_name}/menu/crm`);
    };

    useEffect(() => {
        if (user?.client && props.params?.user_id && !trip_stats.set) {
            getUser(user.client, props.params.user_id, (resp) => {
                if (resp?.data) {
                    dispatch(SetSelectedUser(resp.data));
                    let { pass_check, headers } = CheckBeforeRequest();
                    let url = `${API_HREF}client/${user.client}/trip/?limit=1000&offset=0&user_id=${resp.data.id}&main`;
                    axios({
                        method: "GET",
                        headers: headers,
                        url: url
                    }).then(function (response) {
                        const stats = {
                            currency: 'EUR', // TODO: change me
                            total_ca: 0,
                            total_margin: 0,
                            total_paid: 0,
                            total_rest: 0,
                            set: true
                        };
                        response.data?.results?.filter((el) => el.data.length !== 0)?.forEach(trip => {
                            trip.data?.forEach(version => {
                                stats.total_ca = stats.total_ca + Math.ceil(version.total_cost);
                                stats.total_margin = stats.total_margin + Math.ceil(0);
                                const total_paid = version.payments_done.reduce((acc, obj) => { return acc + parseFloat(obj.amount ?? '0'); }, 0);
                                stats.total_paid = stats.total_paid + total_paid;
                                stats.total_rest = stats.total_rest + Math.ceil(version.total_cost) - total_paid;
                            });
                        });
                        setTripStats(stats);
                    }).catch(function (error) {
                        if (!axios.isCancel(error)) {
                            console.log('Request canceled', error.message);
                        }
                    });
                }
            });
        }
    }, [user, props.params]);

    const handleExpand = (type) => {
        if (type == expand) {
            setExpand(null);
        } else {
            setExpand(type);
        }
    };

    const getTripInvoices = (trip) => {
        if (!trip?.id) {
            setInvoiceTripId(null);
            setInvoices([]);
            dispatch(SetTripInfo(null));
        } else {
            getTripInvoiceHistory(user.client, trip?.id, (response) => {
                setInvoiceTripId(trip?.id);
                setInvoices(response?.data?.results);
                dispatch(SetTripInfo(trip));
            });
        }
    };

    const updateFullname = (firstnameValue, lastnameValue, callback) => {
        if (selected_user) {
            let user_temp = JSON.parse(JSON.stringify({ ...selected_user }));
            user_temp.first_name = firstnameValue;
            user_temp.last_name = lastnameValue;
            patchUser(user.client, user_temp, ['first_name', 'last_name'], () => {
                if (user_list) {
                    let user_list_updated = JSON.parse(JSON.stringify(user_list));
                    const index = user_list_updated.findIndex(u => u.id === user_temp.id);
                    if (index !== -1) {
                        user_list_updated[index] = user_temp;
                        dispatch(SetUserList(user_list_updated));
                    }
                }
                dispatch(SetSelectedUser(user_temp));
                if (callback !== null && callback !== undefined) {
                    callback();
                }
            });
        }
    };

    const createTrip = () => {

    };

    /**ACTIONS */
    const actionCreate = (type) => {
        setCurrentAction(type);
    };

    const copyToClipboard = (text) => {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    };

    //Handle popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    //Demand
    const closeDemandDialog = () => {
        setOpenDemandForm(false);
        dispatch(SetSelectedDemand(null));
    };
    const openDemand = () => {
        setOpenDemandForm(true);
        dispatch(SetSelectedDemand({ createCode: "CREATE_FROM_CONTACT" }));
    };
    const createDemand = (trip) => {

    };

    if (selected_user) {
        return (
            <Container className="crm-user-detail">
                <Grid container>
                    <Grid xs={3} item className="crm-user-detail-contact">
                        <Button variant="text" size="small" style={{ textTransform: "unset" }} onClick={returnToList}><ArrowBackIosIcon fontSize="inherit" />{t("crm.back_to_list")}</Button>
                        <Grid className="crm-user-detail-contact-header">
                            <Grid>
                                <CrmUserDetailContactField value={`${selected_user.first_name} ${selected_user.last_name}`}
                                    callback={(v) => updateFullname(v.newValue.first_name, v.newValue.last_name, v.onFinish)}
                                    fullname={{ first_name: selected_user.first_name, last_name: selected_user.last_name }}
                                    expandable={true}
                                />
                                <IconButton className="info-btn" onClick={handleClick}><InfoIcon /></IconButton>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom'
                                    }}
                                >
                                    <div style={{ padding: 15 }}>
                                        <p>{t("crm.created_on")} : {selected_user.date_joined ? moment.utc(selected_user.date_joined).format("DD/MM/YYYY HH:mm") : ''}</p>
                                        <p>{t("crm.created_by")} : {selected_user.creator?.username}</p>
                                        <p>{t("crm.edited_on")} : {selected_user.modified_date ? moment.utc(selected_user.modified_date).format("DD/MM/YYYY HH:mm") : ''}</p>
                                        <p>{t("crm.edited_by")} : {selected_user.last_author?.username}</p>
                                    </div>
                                </Popover>
                                <p style={{ textAlign: "center", marginLeft: -18 }}>
                                    {selected_user.email}
                                    <Tooltip title={t('crm.copy_email')} >
                                        <IconButton size="small" className="small-copy-btn" onClick={() => copyToClipboard(selected_user.email)}><ContentCopyIcon fontSize="inherit" /></IconButton>
                                    </Tooltip>
                                </p>
                                {selected_user.repeater && <p style={{ display: "flex", alignItem: "center", gap: 5 }}><CheckIcon /> {t("crm.repeater")}</p>}
                            </Grid>
                        </Grid>
                        {/*<Grid> actions go here </Grid> */}
                        <hr />
                        <div style={{ display: "flex", justifyContent: "center", gap: "5px", flexWrap: "wrap" }}>
                            <Button style={{ textTransform: "none", margin: 2 }} variant="outlined" onClick={openDemand}>{t("crm.create_demand")}</Button>
                            <Button style={{ textTransform: "none", margin: 2 }} variant="outlined" onClick={() => setOpenCreateTrip(true)}>{t("crm.create_trip")}</Button>
                        </div>
                        <div className="user-detail-actions">
                            <div>
                                <IconButton onClick={() => actionCreate('call')}><CallIcon /></IconButton>
                                <p>{t('crm.title.call')}</p>
                            </div>
                            <div>
                                <IconButton onClick={() => actionCreate('email')}><EmailIcon /></IconButton>
                                <p>{t('crm.title.email')}</p>
                            </div>
                            <div>
                                <IconButton onClick={() => actionCreate('sms')}><SmsIcon /></IconButton>
                                <p>{t('crm.title.sms')}</p>
                            </div>
                            <div>
                                <IconButton onClick={() => actionCreate('note')}><NoteIcon /></IconButton>
                                <p>{t('crm.title.note')}</p>
                            </div>
                            <div>
                                <IconButton onClick={() => actionCreate('remark')}><TextSnippetIcon /></IconButton>
                                <p>{t('crm.title.remark')}</p>
                            </div>
                        </div>
                        <CrmUserDetailSidePanel user={selected_user} />
                    </Grid>

                    <Grid xs={expand == 'activity' ? 8.5 : 5} display={expand && expand != "activity" ? "none" : "block"} item className="crm-user-detail-activity">
                        <CrmUserDetailActivity user={selected_user} action={currentAction} />
                    </Grid>

                    <Grid xs={expand == 'history' ? 9 : (expand ? 0.5 : 4)} item className="crm-user-detail-history">
                        {
                            (!expand || expand == 'activity') &&
                            <div className="expand-button" onClick={() => handleExpand('activity')} style={{ top: 70 }} >
                                {expand == 'activity' ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                            </div>
                        }
                        {
                            (!expand || expand == 'history') &&
                            <div className="expand-button" onClick={() => handleExpand('history')}>
                                {expand == 'history' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </div>
                        }
                        <TripList crmData={
                            {
                                user: selected_user.id,
                                invoiceAction: getTripInvoices,
                                invoice_trip_id,
                                invoices,
                                currency: trip_stats.currency,
                                total_ca: trip_stats.total_ca,
                                total_margin: trip_stats.total_margin,
                                total_paid: trip_stats.total_paid,
                                total_rest: trip_stats.total_rest
                            }
                        } />
                    </Grid>
                </Grid>
                <CrmUserDetailNoteDialog />
                <CrmUserDetailEmailDialog />
                <CrmUserDetailTripFormDialog open={openCreateTrip} handleClose={() => setOpenCreateTrip(false)} onConfirm={createTrip} mode={'companion'} />
                {
                    selected_demand && <CrmDemandFormDialog open={openDemandForm} handleClose={closeDemandDialog} onConfirm={createDemand} />
                }
            </Container>
        );
    }
    return null;
});
export default CrmUserDetail;
