//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Badge from '@material-ui/core/Badge';
import Select from '@material-ui/core/Select';

import GetPricesId from "../Cart/Functions/Margin/GetPricesId";

import ManIcon from "../Header/ManIcon";
import KidsIcon from "../Header/KidsIcon";
import DoorIcon from "../Header/DoorIcon";
import RoomIcon from '@material-ui/icons/Room';

import { SetSummaryStep } from "../../Actions/Summary";

const useStyles = makeStyles(theme => ({
    root: {
        height: "100%"
    },
    headerContainer: {
        top: "0px !important",
        height: "70px !important",
        backgroundColor: "white",
        textAlign: "center",
        boxShadow: "0 1px 4px rgba(0,0,0,0.3)",
        position: "fixed !important",
        left: "0px !important",
        width: "100% !important",
        zIndex: "1000 !important"
    },
    homeHeader: {
        backgroundColor: "transparent",
        boxShadow: "none"
    },
    stickyHeader: {
        position: "fixed",
        top: 0,
        width: "100%",
        height: 70,
        background: "white",
        zIndex: 100,
        transition: "all .2s ease-out"
    },
    logo: {
        margin: '4px 12px'
    },
    leftSideContainer: {
        marginLeft: 20,
        display: 'inline-block',
        borderRadius: 22,
        boxShadow: '0px 8px 16px rgb(0 0 0 / 15%), 0px 3px 8px rgb(0 0 0 / 10%) !important'/*'0px 16px 32px rgb(0 0 0 / 15%), 0px 3px 8px rgb(0 0 0 / 10%) !important'*/
    },
    inlineBlock: {
        display: 'inline-block'
    },
    homeLink: {
        display: "inline-block",
        padding: 15,
        float: "left"
    },
    separator: {
        borderRight: "1px solid rgba(0, 0, 0, 0.23)",
    	display: "inline"
    },
    rightSideMain: {
        marginRight: 16
    },
    fitLogo: {
        width: 350,
        height: 60,
        objectFit: "contain",
        maxWidth: 350
    },
    soloButton: {
        borderRadius: 20
    },
    orange: {
        color: "#E6592F !important"
    },
    mobileContainer: {
        width: "100% !important",
        height: "48px !important",
        display: "inline-flex !important",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 6px 20px !important",
        color: "rgb(34, 34, 34) !important",
        background: "padding-box rgb(255, 255, 255) !important",
        borderColor: "rgba(0, 0, 0, 0.04) !important",
        borderRadius: "24px !important",
        borderStyle: "solid !important",
        borderWidth: "1px !important"
    },
    leftSide: {
        WebkitBoxPack: "center !important",
        WebkitBoxAlign: "center !important",
        flexBasis: "48px !important",
        flexShrink: "0 !important",
        minWidth: "0px !important",
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important"
    },
    leftButton: {
        WebkitBoxAlign: "center !important",
        appearance: "none !important",
        display: "inline-flex !important",
        borderRadius: "50% !important",
        border: "1px solid rgba(0, 0, 0, 0.03) !important",
        outline: "0px !important",
        margin: "0px !important",
        padding: "0px !important",
        color: "rgb(34, 34, 34) !important",
        backgroundColor: "rgb(255, 255, 255) !important",
        cursor: "pointer !important",
        touchAction: "manipulation !important",
        alignItems: "center !important",
        justifyContent: "center !important",
        transition: "box-shadow 0.2s ease 0s, -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s !important",
        width: "44px !important",
        height: "44px !important",
        backgroundClip: "padding-box !important",
        boxShadow: "rgb(0 0 0 / 15%) 0px 5px 12px !important"
    },
    middleSide: {
        WebkitBoxFlex: "1 !important",
  		flexGrow: "1 !important"
    },
    middleButton: {
        cursor: "pointer !important",
        position: "relative !important",
        touchAction: "manipulation !important",
        fontFamily: "inherit !important",
        fontSize: "inherit !important",
        lineHeight: "inherit !important",
        fontWeight: "inherit !important",
        borderRadius: "0px !important",
        outline: "none !important",
        transition: "box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important",
        background: "transparent !important",
        border: "none !important",
        color: "inherit !important",
        display: "block !important",
        margin: "0px !important",
        padding: "0px !important",
        textAlign: "inherit !important",
        textDecoration: "none !important",
        height: "100% !important",
        width: "100% !important"
    },
    middleContainer: {
        WebkitBoxAlign: "center !important",
        alignItems: "center !important",
        display: "flex !important",
        paddingRight: "12px !important",
        borderRight: "1px solid rgba(0, 0, 0, 0.15) !important"
    },
    leftIcon: {
        WebkitBoxFlex: "1 !important",
        fontSize: "22px !important",
        lineHeight: "18px !important",
        fontWeight: "600 !important",
        flexGrow: "1 !important",
        paddingRight: "4px !important"
    },
    rightIcon: {
        fontSize: "14px !important",
        lineHeight: "18px !important",
        maxHeight: "18px !important",
        overflow: "hidden !important",
        display: "-webkit-box !important",
        color: "rgb(113, 113, 113) !important",
        fontWeight: "600 !important",
        textAlign: "right !important",
        flexGrow: "1 !important",
        textTransform: "capitalize"
    },
    rightSide: {
        flexBasis: "48px !important",
        flexShrink: "0 !important",
        minWidth: "48px !important"
    },
    rightButton: {
        WebkitBoxAlign: "center !important",
        WebkitBoxPack: "center !important",
        cursor: "pointer !important",
        position: "relative !important",
        touchAction: "manipulation !important",
        fontFamily: "inherit !important",
        fontSize: "inherit !important",
        lineHeight: "inherit !important",
        fontWeight: "inherit !important",
        borderRadius: "0px 24px 24px 0px !important",
        outline: "none !important",
        transition: "box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important",
        background: "transparent !important",
        border: "none !important",
        color: "inherit !important",
        display: "inline-flex !important",
        margin: "0px !important",
        padding: "0px 8px 0px 0px !important",
        textAlign: "inherit !important",
        textDecoration: "none !important",
        height: "100% !important",
        width: "100% !important",
        justifyContent: "center !important",
        alignItems: "center !important"
    },
    formControlRoot: {
        borderRight: 0,
        borderTopLeftRadius: "20px",
        borderTopRightRadius: 0,
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: 0,
        padding: '6px 13px',
        position: "relative",
        textTransform: "capitalize",
        height: 41
  	},
    formControlRoot1: {
        borderRadius: '0',
        borderLeft: 0,
        borderRight: 0,
        padding: '6px 13px !important',
        position: "relative",
        textTransform: "capitalize",
        height: 41
    },
    formControlRoot2: {
        borderLeft: 0,
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        padding: '6px 13px !important',
        position: "relative",
        textTransform: "capitalize",
        height: 41
    },
    activeButton: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        fontWeight: "bold",
        color: "#e6592f"
    },
    bold: {
        fontWeight: "bold"
    },
    pink: {
        color: "#e0368d!important"
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    italic: {
        fontStyle: "italic"
    },
    underStatus: {
        verticalAlign: "-6px !important"
    },
    pointer: {
        cursor: "pointer"
    }
    //select: {
    //"& .mui-jss-MuiSelect-select.mui-jss-MuiSelect-select": {
    //paddingRight: 0
    //}
    //}
}));

const MaterialProviderHeader = ({ tripInfo }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const config = JSON.parse(localStorage.getItem("config"));
    const dispatch = useDispatch();

    const [flagMenu, setFlagMenu] = useState(false);
    const [language, setLanguage] = useState(i18n.language);

    const handleOpen = () => {
        setFlagMenu(true);
    };
    const handleClose = () => {
        setFlagMenu(false);
    };
    return (
        <div id={"header"} className={classes.headerContainer}>
            <Grid container className={classes.root} alignItems={"center"} direction={"row"}>
                {
                    !matches && (
                        <Grid item xs sm/>
                    )
                }
                {
                    tripInfo !== null && (
                        <Grid className={ `${classes.genericText}` } item container alignItems={"center"} justify={"center"} sm={6} xs={6}>
                            <Grid className={ classes.logoContainer } item>
                                <img className={ classes.fitLogo} src={ tripInfo !== null ? (tripInfo.target_client.logo !== null ? (tripInfo.target_client.logo.thumbnail_little !== null ? tripInfo.target_client.logo.thumbnail_little : tripInfo.target_client.logo.url) : "/Img/inner_logo_header.png") : "/Img/inner_logo_header.png" } alt={ "" }/>
                            </Grid>
                        </Grid>
                    )
                }
                <Grid className={ `${classes.genericText} ${!matches ? classes.rightSideMain : ""}` } item container sm xs={6} justify={"flex-end"} alignItems={"center"}>
                    <Select
                        //className={classes.select}
                        open={flagMenu}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={language}
                        disableUnderline 
                        onChange={(event) => {
                            i18n.changeLanguage(event.target.value);
                            moment.locale(event.target.value);
                            localStorage.setItem("i18nextLng", event.target.value);
                            setLanguage(event.target.value);
                        }}
                        //renderValue={() => {
                        //<span className={`${"flag-icon flag-icon-" + (language === "en" ? "us" : language)}`}/>
                        //}}
                    >
                        <MenuItem value="fr">
                            <span className={`${"flag-icon flag-icon-fr" }`}/>
                        </MenuItem>
                        <MenuItem value="en">
                            <span className={`${"flag-icon flag-icon-us" }`}/>
                        </MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </div>
    );
};
export default MaterialProviderHeader;
