import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import { Close, AddOutlined } from "@mui/icons-material";
import { isEqual } from "lodash";
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";

type Props = {
    index: number,
    input: ItineraryContentStep,
    inputs: ItineraryContentStep[],
    onChangeInputs: React.Dispatch<React.SetStateAction<ItineraryContentStep[]>>,
}

export function CartConstructionItineraryContentStepsListAggregator(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const onGroupSteps = (choice: ItineraryContentStep) => {
        const first = props.input;
        const second = props.inputs[props.index + 1];
        if (first && second) {
            props.onChangeInputs((state) => {
                return (
                    state.filter((item) => {
                        if (
                            first.mode === 'by-day' &&
                            second.mode === 'by-day' &&
                            item.mode === 'by-day'
                        ) {
                            return !isEqual(first.content.day, item.content.day) &&
                                   !isEqual(second.content.day, item.content.day);
                        }
                    }) ?? []
                ).concat(
                    [
                        first.mode === 'by-day' &&
                        second.mode === 'by-day' &&
                        choice.mode === 'by-day' ?
                            {
                                ...choice,
                                content: {
                                    ...choice.content,
                                    day: [...first.content.day.concat(second.content.day)].sort()
                                }
                            } :
                            null
                    ].filter((item): item is NonNullable<typeof item> => !!item)
                ).sort((a, b) => {
                    if (a.mode === 'by-day' && b.mode === 'by-day') {
                        return (a.content.day[0] ?? 0) - (b.content.day[0] ?? 0);
                    }
                    return -1;
                });
            });
            setOpenConfirmModal(false);
        }
    };

    const currentDestinations = (
        props.input.mode === 'by-day' ?
            props.input.content.destinations?.map((item) => item.id) :
            [props.input.content.destination?.id ?? -1]
    ) ?? [];
    const nextInput = props.inputs[props.index + 1];
    const nextDestinations = (
        nextInput?.mode === 'by-day' ?
            nextInput.content.destinations?.map((item) => item.id) :
            [nextInput?.content.destination?.id ?? -1]
    ) ?? [];
    const disabled = !isEqual(
        currentDestinations.sort((a, b) => a - b),
        nextDestinations.sort((a, b) => a - b)
    );

    return (
        <>
            <Tooltip
                title={
                    !disabled ?
                        t<string>('shared.circuit-steps-regroup') :
                        t<string>('shared.circuit-steps-regroup-disabled-hint')
                }
            >
                <span>
                    <IconButton
                        size="small"
                        onClick={() => setOpenConfirmModal(true)}
                        disabled={disabled}
                    >
                        <AddOutlined />
                    </IconButton>
                </span>
            </Tooltip>
            {
                props.inputs[props.index + 1] &&
                <ConfirmModal
                    open={openConfirmModal}
                    index={props.index}
                    a={props.input}
                    b={props.inputs[props.index + 1]!}
                    onConfirm={onGroupSteps}
                    onClose={() => setOpenConfirmModal(false)}
                />
            }
        </>
    );
}

type ConfirmModalProps = {
    open: boolean,
    index: number,
    a: ItineraryContentStep,
    b: ItineraryContentStep,
    onConfirm: (choice: ItineraryContentStep) => void,
    onClose: () => void
}

function ConfirmModal(props: ConfirmModalProps): JSX.Element {
    const { t } = useTranslation();

    const getName = (item: ItineraryContentStep, index: number) => {
        if (item.mode === 'by-day') {
            return t<string>('shared.circuit-step-days', { days: item.content.day.join(', ') });
        }
        return t<string>('itinerary.step-no', { no: index + 1 });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography fontWeight="bold">
                        {t<string>('shared.circuit-step-aggregate-confirm')}
                    </Typography>
                    <IconButton onClick={props.onClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Typography gutterBottom>
                    <Trans
                        i18nKey="shared.circuit-step-aggregate-confirm-modal-hint"
                        values={{
                            a: getName(props.a, props.index).toLowerCase(),
                            b: getName(props.b, props.index).toLowerCase()
                        }}
                        components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                    />
                </Typography>
                <Stack direction="row" spacing={2} justifyContent="center">
                    <Button variant="outlined" onClick={() => props.onConfirm(props.a)}>
                        {getName(props.a, props.index)}
                    </Button>
                    <Button variant="outlined" onClick={() => props.onConfirm(props.b)}>
                        {getName(props.b, props.index + 1)}
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
