import { useCallback } from "react";
import { useSelector } from "react-redux";
import { isNumber } from "lodash";
import { StepsDatesManager } from "../utils/stepsDatesManager";
import { ItineraryInput } from "../objects/itineraryState";
import { AppState } from "../../../Reducers/Reducers";

type Options = {
    aboveStep: ItineraryInput,
    step: ItineraryInput,
    belowStep: ItineraryInput
}

type Callback = (options: Options) => boolean

export function useFixedDateBlockCheck(): Callback {
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);

    return useCallback((options) => {
        if (tripStartDate && tripEndDate) {
            const datesManager = new StepsDatesManager(
                tripStartDate,
                tripEndDate
            );

            if (
                isNumber(options.step.circuit) &&
                options.step.circuit_start_date &&
                (
                    options.aboveStep.circuit !== options.step.circuit ||
                    options.aboveStep.circuit_trip_version !== options.step.circuit_trip_version
                ) &&
                !datesManager.areDatesValidBetween(
                    options.aboveStep,
                    options.step,
                    true
                ) &&
                window.moment.utc(options.step.start_date).isBefore(
                    window.moment.utc(options.aboveStep.end_date)
                )
            ) {
                return false;
            }

            if (
                isNumber(options.belowStep.circuit) &&
                options.belowStep.circuit_start_date &&
                (
                    options.step.circuit !== options.belowStep.circuit ||
                    options.step.circuit_trip_version !== options.belowStep.circuit_trip_version
                ) &&
                !datesManager.areDatesValidBetween(
                    options.step,
                    options.belowStep,
                    true
                ) &&
                window.moment.utc(options.belowStep.start_date).isBefore(
                    window.moment.utc(options.step.end_date)
                )
            ) {
                return false;
            }
        }

        return true;
    }, [tripStartDate, tripEndDate]);
}
