import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DatePicker, LocalizationProvider, PickersDayProps, PickersDay } from '@material-ui/pickers'
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Grid from '@material-ui/core/Grid';

import clsx from "clsx";
import { useSelector } from 'react-redux';
import RenderTime from './RenderTime';

const useStyles = makeStyles(theme => ({
    formControlRoot: {
        "& .mui-jss-MuiInputLabel-outlined": {
            transform: 'translate(14px, 13px) scale(1)',
            [theme.breakpoints.between(768, 1440)]: {
		      	fontSize: '14px',
		    }
        },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '9.5px 20px'
        }
    },
    formControlRoot1: {
	    "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(14px, 20px) scale(1)'
	    },
	    "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    },
	    "& .mui-jss-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
	    	padding: '0'
	    },
	    "& .mui-jss-MuiInputBase-root.mui-jss-MuiOutlinedInput-root.mui-jss-MuiAutocomplete-inputRoot.mui-jss-MuiInputBase-fullWidth.mui-jss-MuiInputBase-formControl.mui-jss-MuiInputBase-adornedEnd.mui-jss-MuiOutlinedInput-adornedEnd" : {
	    	paddingRight: '10px'
	    },
	    "& .mui-jss-MuiOutlinedInput-input": {
	    	padding: '17px 4px'
	    }
  	},
    inputBorder1: {
        "&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: '0',
            borderRight: '0'
        }
    },
    inputBorder2: {
        "& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: '0px 8px 8px 0px',
        }
    },
    inputBorder3: {
        borderRadius: '8px',
        width: '100%'
    },
  	inputBorder4: {
  		"&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: '8px 0px 0px 8px',
  		}
  	},
  	selectedDay: {
  		"& .mui-jss-MuiPickersDay-root.mui-jss-Mui-selected": {
  	    	opacity: '70%'
  		}
  	},
  	highlight: {
  	    borderRadius: 0,
  	    opacity: '50%',
  	    backgroundColor: theme.palette.primary.main,
  	    color: theme.palette.common.white,
  	    "&:hover, &:focus": {
  	      backgroundColor: theme.palette.primary.dark,
  	    },
  	},
    firstHighlight: {
  	    borderTopLeftRadius: "50%",
  	    borderBottomLeftRadius: "50%",
  	},
  	endHighlight: {
  	    borderTopRightRadius: "50%",
  	    borderBottomRightRadius: "50%",
  	},
  	bold: {
  		fontWeight: 'bold'
  	},
    noBorder: {
        borderLeft: "none",
        borderRight: "none"
    },
    noBorderRight: {
        borderRight: "none"
    },
    separator: {
        borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
    	display: "inline",
        position: "absolute",
        left: 0,
        top: "25%",
        bottom: "25%",
        zIndex: 1
    }
}));

const DatePickerInput = ({search, setSearch, iti_dates, transfers_list}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between(768, 1024));
    const trip_start_date = useSelector(store => store.trip.start_date);
    const trip_end_date = useSelector(store => store.trip.end_date);
    const language = useSelector(store => store.header.language);
    const [selectedDate, setDate] = useState(
        search.start_date !== null && search.end_date !== null ? 
            moment(search.start_date, "YYYY-MM-DD")
            :
            moment(new Date(), "YYYY-MM-DD")
    );
    const [minDate, setMinDate] = useState(trip_start_date);
    const [maxDate, setMaxDate] = useState(trip_end_date);
    const [is_open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    // useEffect(() => {
    // 	let tmp_date = selectedDate;
    // 	if (search.origin_index !== null && search.dest_index !== null && iti_dates.length !== 0) {
    // 		tmp_date = moment(iti_dates[0].start_date, "YYYY-MM-DD");
    // 		setMinDate(moment(iti_dates[0].start_date, "YYYY-MM-DD"));
    // 		setMaxDate(moment(iti_dates[0].end_date, "YYYY-MM-DD"));
    // 		handleDateChange(tmp_date);
    // 	}
    // }, [iti_dates]);
    const handleDateChange = (newValue) => {
        console.log('newValue:', newValue);
  		let tmp_search = Object.assign({}, search);
        tmp_search.start_date = newValue;
  		setSearch(tmp_search);
  		// setError(false);
        setDate(newValue);
    }
 	const renderWeekPickerDay = (date, _selectedDates, Props) => {
 	    let dayIsBetween = false;
 	    let isFirstDay = false;
 	    let isLastDay = false;
 	    const dateClone = moment(date);
 	    let start = moment(minDate, "YYYY-MM-DD");
 	    let end = moment(maxDate, "YYYY-MM-DD");
 	    if (moment(dateClone).isSameOrAfter(start) && moment(dateClone).isSameOrBefore(end)) {
 	    	dayIsBetween = true;
 	    }
 	    if (moment(dateClone).isSame(start)) {
 	    	isFirstDay = true;
 	    }
 	    if (moment(dateClone).isSame(end)) {
 	    	isLastDay = true;
 	    }
 	    return (
 	    	<PickersDay 
 	        	{...Props}
 	        	key={date}
 	        	disableMargin
 	        	className={clsx({
 	          		[classes.highlight]: dayIsBetween,
 	          		[classes.firstHighlight]: isFirstDay,
 	          		[classes.endHighlight]: isLastDay,
 	        	})}
 	      	/>	
 	    );
  	}
    return (
        <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ language }>
            <DatePicker
                open={is_open}
                onClose={() => {setOpen(false)}}
                // disabled={search.origin_index !== null && search.dest_index !== null ? false : true}
                allowSameDateSelection={true}
		        calendars={2}
		        value={selectedDate}
		        minDate={minDate}
		        maxDate={maxDate}
		        onChange={(newValue) => handleDateChange(newValue)}
		        className={classes.selectedDay}
		        renderDay={renderWeekPickerDay}
		        renderInput={(props) => {
		        	props.InputProps.endAdornment = undefined;
		        	return (
		          		<Grid container spacing={matches ? 0 : 2}>
				            <Grid container item sm={4} xs={12}>
					            <TextField
					      			{...props}
					      			error={error}
					      			onClick={() => {setOpen(!is_open)}}
					      			label={t("home.modal.start_date_placeholder")}
					      			className={`${matches ? classes.formControlRoot : classes.formControlRoot1} ` + `${matches ? (isTablet ? classes.inputBorder4 : classes.inputBorder1) : classes.inputBorder3}`}
					      			variant={"outlined"}
                                    style={{ background: 'white' }}
					      			helperText={""}
                                    InputProps={{
                                        ...props.InputProps,
                                        classes: { notchedOutline: matches ? (isTablet ? classes.noBorderRight : classes.noBorder) : "" }
                                    }}
					      		/>
					      	</Grid>
				            <Grid container item sm={2} xs={12} style={{ position: "relative" }}>
                                {
                                    matches && (
                                    	<div className={classes.separator}/>
                                    )
                                }
				            	<RenderTime type={"origin"} search={search} setSearch={setSearch}/>		            	
				            </Grid>		            	
			          	</Grid>
			        );
		        } 
		    }
	      	/>
        </LocalizationProvider>
    );
};
export default React.memo(DatePickerInput);
