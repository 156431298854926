import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    bold: {
        fontWeight: "bold"
    },
    inline: {
        display: "inline"
    }
});

const CurrencyFormat = ({language, fontSize, price, symbol, with_precision, with_bold, with_bold_symbol}) => {
    const classes = useStyles();
    if (price !== null) {
        const split = price.split(".");
        return (
            <Fragment>
                <Typography className={ `${classes.inline} ${fontSize}${(with_bold === 1 ? ` ${classes.bold}` : "")}` } variant={ "body2" }>{ split[0]/*.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")*/ }</Typography>
                {
                    with_precision === 1 && (
                        <Typography variant={ "body2" } className={ classes.inline }>,{ split[1] }</Typography>
                    )
                }
                <Typography className={ `${classes.inline} ${fontSize}${(with_bold_symbol === 1 ? ` ${classes.bold}` : "")}` } variant={ "body2" }> { symbol }</Typography>
            </Fragment>
        );
    }
    return null;
};

export default CurrencyFormat;