import { itineraryToPoints } from "./itineraryToPoints";
import { Itinerary } from "../../../Itinerary/objects/itinerary";

export function itineraryToPolylinePath(steps: Itinerary[]): string {
    const inputs = steps.filter((step) => step.step_type === 'STEP');
    if (steps.findIndex((item) => item.step_type === 'END') >= 0) {
        inputs.pop();
    }
    const points = itineraryToPoints(inputs);
    const tolerance = calculateToleranceForMaxSize(points, 4);
    return google.maps.geometry.encoding.encodePath(simplifyDouglasPeucker(points, tolerance));
}

function getPerpendicularDistance(
    point: google.maps.LatLng,
    lineStart: google.maps.LatLng,
    lineEnd: google.maps.LatLng
): number {
    const pointX = point.lng();
    const pointY = point.lat();
    const startX = lineStart.lng();
    const startY = lineStart.lat();
    const endX = lineEnd.lng();
    const endY = lineEnd.lat();
  
    const slope = (endY - startY) / (endX - startX);
    const intercept = startY - slope * startX;
  
    const distance =
      Math.abs(slope * pointX - pointY + intercept) /
      Math.sqrt(slope * slope + 1);
  
    return distance;
}
  
function simplifyDouglasPeucker(
    points: google.maps.LatLng[],
    tolerance: number
): google.maps.LatLng[] {
    const firstPoint = points[0]!;
    const lastPoint = points[points.length - 1]!;
  
    if (points.length < 3) {
        return points;
    }
  
    let maxDistance = 0;
    let maxIndex = 0;
  
    for (let i = 1; i < points.length - 1; i++) {
        const distance = getPerpendicularDistance(
            points[i]!,
            firstPoint,
            lastPoint
        );
  
        if (distance > maxDistance) {
            maxDistance = distance;
            maxIndex = i;
        }
    }
  
    const simplifiedPoints: google.maps.LatLng[] = [];
  
    if (maxDistance > tolerance) {
        const leftPoints = points.slice(0, maxIndex + 1);
        const rightPoints = points.slice(maxIndex);
  
        const simplifiedLeft = simplifyDouglasPeucker(leftPoints, tolerance);
        const simplifiedRight = simplifyDouglasPeucker(rightPoints, tolerance);
  
        const simplifiedLeftSlice = simplifiedLeft.slice(0, -1);
        for (let i = 0; i < simplifiedLeftSlice.length; i++) {
            simplifiedPoints.push(simplifiedLeftSlice[i]!);
        }

        for (let i = 0; i < simplifiedRight.length; i++) {
            simplifiedPoints.push(simplifiedRight[i]!);
        }
    } else {
        simplifiedPoints.push(firstPoint, lastPoint);
    }
  
    return simplifiedPoints;
}

function calculateToleranceForMaxSize(points: google.maps.LatLng[], maxSizeInKB: number): number {
    const initialTolerance = 1e-5;
    let tolerance = initialTolerance;
    let simplifiedPoints = simplifyDouglasPeucker(points, tolerance);
    let encodedSize = calculateEncodedSize(simplifiedPoints);
  
    while (encodedSize > maxSizeInKB * 1024 && tolerance < 1) {
        tolerance *= 10;
        simplifiedPoints = simplifyDouglasPeucker(points, tolerance);
        encodedSize = calculateEncodedSize(simplifiedPoints);
    }
  
    if (encodedSize > maxSizeInKB * 1024) {
        console.log("Warning: Unable to achieve the desired maximum size. Consider adjusting the maximum size or tolerance.");
    }
  
    return tolerance;
}
  
function calculateEncodedSize(points: google.maps.LatLng[]): number {
    const encodedString = google.maps.geometry.encoding.encodePath(points);
    return encodedString.length;
}
