import { chunk } from "lodash";
import { groupCartProductsByDate } from "./groupCartProductsByDate";
import { getDestinationName } from "../../../Itinerary/utils/getDestinationName";
import { transformFlightsToCalendarTransports } from "./transformFlightsToCalendarTransports";
import { transformCarsToCalendarTransports } from "./transformCarsToCalendarTransports";
import { transformPoiToCalendarProducts } from "./transformPoiToCalendarProducts";
import { transformTransfersToCalendarTransports } from "./transformTransfersToCalendarTransports";
import { transformTrainsToCalendarTransports } from "./transformTrainsToCalendarTransports";
import { transformCruisesToCalendarTransports } from "./transformCruisesToCalendarTransports";
import { transformFerriesToCalendarTransports } from "./transformFerriesToCalendarTransports";
import { CreatePageOptions } from "../objects/createPageOptions";
import { Template } from "../utils/handlebars/roadbook.handlebars";

export function createCalendarContent(options: CreatePageOptions):Parameters<Template>[0]['contents'][0] | null {
    const grouppedProducts = groupCartProductsByDate(options.cart);
    const dates = Object.keys(grouppedProducts).sort((a, b) => {
        const aDate = window.moment.utc(a, 'YYYY-MM-DD');
        const bDate = window.moment.utc(b, 'YYYY-MM-DD');
        return aDate.startOf('day').isBefore(bDate.startOf('day')) ?
            -1 :
            1;
    });
    const calendarContent = chunk(dates, 3).map((group) => {
        return {
            items: group.map((item) => {
                const date = window.moment.utc(item, "YYYY-MM-DD").startOf('day');
                const hotel = grouppedProducts[item]?.accommodations.find((item) => {
                    return !item.accommodation.is_optional;
                });
                const hotelName = hotel?.type === 'normal' ?
                    hotel?.accommodation.hotel[0]?.name :
                    hotel?.accommodation.name;
                return {
                    day: date.format('ddd'),
                    date: date.format('DD MMM YYYY'),
                    hotel: hotelName,
                    route: options.itinerary.filter((item) => {
                        return (
                            window.moment.utc(item.start_date).isBefore(date, 'day') &&
                            window.moment.utc(item.end_date).isAfter(date, 'day')
                        ) ||
                        window.moment.utc(item.start_date).isSame(date, 'day') ||
                        window.moment.utc(item.end_date).isSame(date, 'day');
                    }).map((item) => {
                        return item.destination ?
                            getDestinationName(options.locale, item.destination).split(',')[0] :
                            item.city_name?.split(',')[0];
                    }).join(' - '),
                    products: transformFlightsToCalendarTransports({
                        cart: options.cart,
                        dateString: item,
                        t: options.t
                    }).concat(
                        transformCarsToCalendarTransports({
                            cart: options.cart,
                            dateString: item,
                            t: options.t
                        })
                    ).concat(
                        transformTransfersToCalendarTransports({
                            cart: options.cart,
                            dateString: item,
                            t: options.t
                        })
                    ).concat(
                        transformPoiToCalendarProducts({
                            cart: options.cart,
                            dateString: item,
                            locale: options.locale,
                            t: options.t
                        }) as any //@TODO: fix this
                    ).concat(
                        transformTrainsToCalendarTransports({
                            cart: options.cart,
                            dateString: item,
                            t: options.t
                        })
                    ).concat(
                        transformCruisesToCalendarTransports({
                            cart: options.cart,
                            dateString: item,
                            t: options.t
                        }) as any //@TODO: fix this
                    ).concat(
                        transformFerriesToCalendarTransports({
                            cart: options.cart,
                            dateString: item,
                            t: options.t
                        }) as any //@TODO: fix this
                    ).sort((a, b) => {
                        return window.moment.utc(a.dateOrder).isBefore(window.moment.utc(b.dateOrder)) ?
                            -1 :
                            1;
                    }),
                    suggestions: options.cart.pois.filter((item) => {
                        return (
                            window.moment.utc(item.poi.start_date).startOf('day').isSame(date, 'day') ||
                            window.moment.utc(item.poi.end_date).startOf('day').isSame(date, 'day')
                        ) &&
                        item.poi.poi_type &&
                        !item.poi.is_optional;
                    }).map((item) => {
                        return item.type === 'normal' ?
                            item.poi.custom_product?.title ?? '' :
                            item.poi.name;
                    })
                };
            })
        };
    });
    return calendarContent.length > 0 ?
        {
            type: 'calendar',
            anchor: 'calendar',
            showInSummary: true,
            summaryTitle: options.title,
            title: options.title,
            contents: calendarContent
        } :
        null;
}
