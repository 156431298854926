import { Transport } from "../objects/transport";

type Options = {
    endDate: string,
    fromTimezone: string,
    toTimezone: string,
    transport: Transport['alternatives'][number],
}

export function computeDaysCountForPlane(options: Options): number {
    const leadingCarSteps: typeof options.transport.steps = [];
                
    for (let i = 0; i < options.transport.steps.length; i++) {
        const step = options.transport.steps[i]!;
        if (step.vehicle.kind === 'plane') {
            break;
        } else {
            leadingCarSteps.push(step);
        }
    }
    
    const leadingDuration = leadingCarSteps.reduce((prev, current) => {
        return prev + current.duration;
    }, 0);

    const firstAirStep = options.transport.steps.find((step) => step.vehicle.kind === 'plane');

    const endDate = window.moment.tz(
        options.endDate.replace('Z', ''),
        options.fromTimezone
    );
    const nextStartDate = endDate.clone().add(
        leadingDuration + (firstAirStep?.duration ?? 0),
        'seconds'
    ).tz(options.toTimezone);

    const result = window.moment.utc(
        nextStartDate.format('YYYY-MM-DD')
    ).diff(
        window.moment.utc(
            endDate.format('YYYY-MM-DD')
        ),
        'days'
    );

    return result;
}
