import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useGetPrice } from "./getPrice";
import GetPricesId from "../../Cart/Functions/Margin/GetPricesId";
import { Flight } from "../../Itinerary/network/flight";
import { AppState } from "../../../Reducers/Reducers";

export function useGetFlightSellingPrice() {
    const getPrice = useGetPrice();
    const user = useSelector((state: AppState) => state.user.user);
    const getFlightOptionCost = useCallback(
        (flight: Flight, force_network = false) => {
            let seatsCost = 0;
            let option_cost = 0;
            let option_on_flight: any[] = [];
            flight.outbounds.map(outbound => {
                let option_on_group: any[] = [];
                outbound.legs.map(leg => {
                    for (const seat of leg.seats ?? []) {
                        let seatPrice = 0;
                        if ((seat.prices?.length ?? 0) > 0) {
                            seatPrice = getPrice(seat.prices!, undefined, force_network).cost;
                        } else {
                            seatPrice = parseFloat(seat.price ?? '0');
                        }
                        seatsCost += seatPrice;
                    }

                    if (leg.paid_options && leg.paid_options.length > 0) {
                        leg.paid_options.map(option => {
                            let optionPrice = 0;
                            if ((option.prices?.length ?? 0) > 0) {
                                optionPrice = getPrice(option.prices!, undefined, force_network).cost;
                            } else {
                                optionPrice = parseFloat(option.price);
                            }
                            if (option.option_on_flight) {
                                if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                    option_cost += optionPrice;
                                    option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                }
                            } else if (option.option_on_group) {
                                if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                    option_cost += optionPrice;
                                    option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                }
                            } else {
                                option_cost += optionPrice;
                            }
                        });
                    }
                });
            });
            return [option_cost, seatsCost] as const;
        },
        [user, getPrice]
    );

    const getFlightPrice = useCallback(
        (flight: Flight, force_network = false) => {
            let total_cost = [];
            let matched = false;
            let price = getPrice(flight.prices, undefined, force_network);
            const [option_cost, seatsCost] = getFlightOptionCost(flight, force_network);
            let master_price = flight.prices[GetPricesId(flight.prices, user?.client, user, true)];
            if (!matched) {
                total_cost.push({
                    cost: price.cost +
                          (parseFloat(flight.taxes) * (master_price ? parseFloat(master_price.factor_used) : 1)) +
                          option_cost +
                          seatsCost,
                    currency: price.currency
                });
            }
            return total_cost;
        },
        [user, getPrice, getFlightOptionCost]
    );
    return [getFlightPrice, getFlightOptionCost] as const;
}
