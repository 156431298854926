import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';

import { ClearClass } from "../../../../Actions/Flight";
import { DisplayList, ClearFilter } from "../../../../Actions/FlightSearch";

import FilterResults from "../../Functions/FilterResults.js";
import ApplySort from "../../Functions/ApplySort.js";
import UpdateFilters from "../../Functions/UpdateFilters";

import StopoversButton from "./StopoversButton";
import LuggageButton from "./LuggageButton";
import HoursButton from "./HoursButton";
import AirlinesButton from "./AirlinesButton";
import AirportsStationsButton from "./AirportsStationsButton";
import PriceButton from "./PriceButton";
import MoreFiltersButton from "./MoreFiltersButton";
import SortButton from "./SortButton";
import { CartConstructionReplaceProductContext } from "../../../CartMaterial/utils/cartConstructionReplaceProductContext";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    stickyBar: {
        position: "sticky",
        top: 65,
        zIndex: 10,
        backgroundColor: "#FFFFFF"
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontSize12: {
        fontSize: 12
    },
    textRight: {
        textAlign: "right"
    },
    spacer: {
        padding: "12px 0px"
    }
}));

const FiltersBar = ({ searched_group }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    const user = useSelector(state => state.user.user);
    const index_group = useSelector(store => store.flight.index_group);
    const airlines = useSelector(store => store.flight_search.airlines);
    const airports = useSelector(store => store.flight_search.airports);
    const alliances = useSelector(store => store.flight_search.alliances);
    const change_airport = useSelector(store => store.flight_search.change_airport);
    const checked_luggage = useSelector(store => store.flight_search.checked_luggage);
    const filter_max_duration = useSelector(store => store.flight_search.filter_max_duration);
    const filter_min_duration = useSelector(store => store.flight_search.filter_min_duration);
    const filter_max_price = useSelector(store => store.flight_search.filter_max_price);
    const filter_min_price = useSelector(store => store.flight_search.filter_min_price);
    const filter_time = useSelector(store => store.flight_search.filter_time);
    const flight_list = useSelector(store => store.flight_search.flight_list);
    const is_day_flight = useSelector(store => store.flight_search.is_day_flight);
    const is_night_flight = useSelector(store => store.flight_search.is_night_flight);
    const isUpdate = useSelector(store => store.flight_search.isUpdate);
    const max_duration = useSelector(store => store.flight_search.max_duration);
    const max_price = useSelector(store => store.flight_search.max_price);
    const min_duration = useSelector(store => store.flight_search.min_duration);
    const min_price = useSelector(store => store.flight_search.min_price);
    const not_refundable = useSelector(store => store.flight_search.not_refundable);
    const providers = useSelector(store => store.flight_search.providers);
    const private_price = useSelector(store => store.flight_search.private_price);
    const public_price = useSelector(store => store.flight_search.public_price);
    const flight_source_ndc = useSelector(store => store.flight_search.flight_source_ndc);
    const flight_source_gds = useSelector(store => store.flight_search.flight_source_gds);
    const refundable = useSelector(store => store.flight_search.refundable);
    const show_all_airports = useSelector(store => store.flight_search.show_all_airports);
    const show_all_airlines = useSelector(store => store.flight_search.show_all_airlines);
    const show_all_alliances = useSelector(store => store.flight_search.show_all_alliances);
    const show_all_providers = useSelector(store => store.flight_search.show_all_providers);
    const show_all_stations = useSelector(store => store.flight_search.show_all_stations);
    const show_all_stop_over = useSelector(store => store.flight_search.show_all_stop_over);
    const show_all_stop_over_airport = useSelector(store => store.flight_search.show_all_stop_over_airport);
    const show_all_stop_over_duration = useSelector(store => store.flight_search.show_all_stop_over_duration);
    const sort_value = useSelector(store => store.flight_search.sort_value);
    const stations = useSelector(store => store.flight_search.stations);
    const stop_over_active = useSelector(store => store.flight_search.stop_over_active);
    const stopover_airport = useSelector(store => store.flight_search.stopover_airport);
    const stopover_duration = useSelector(store => store.flight_search.stopover_duration);

    const [is_filter_active, setIsFilterActive] = useState(false);

    const replaceProductContext = useContext(CartConstructionReplaceProductContext);

    useEffect(() => {
        if (flight_list.length > 0) {
            UpdateFilters(
                flight_list,
                airlines,
                airports,
                show_all_airports,
                alliances,
                stop_over_active,
                show_all_stop_over,
                is_day_flight,
                is_night_flight,
                stopover_airport,
                show_all_stop_over_airport,
                checked_luggage,
                stations,
                show_all_stations,
                show_all_stop_over_duration,
                providers,
                show_all_providers,
                private_price,
                public_price,
                flight_source_gds,
                flight_source_ndc,
                dispatch
            );
        }
    }, []);

    useEffect(() => {
        if (isUpdate) {
            let flight_list_render = FilterResults(
                flight_list,
                airports,
                show_all_airports,
                airlines,
                show_all_airlines,
                alliances,
                show_all_alliances,
                stop_over_active,
                show_all_stop_over,
                filter_min_price,
                filter_max_price,
                is_day_flight,
                is_night_flight,
                refundable,
                not_refundable,
                show_all_stop_over_airport,
                stopover_airport,
                filter_time,
                checked_luggage,
                filter_max_duration,
                filter_min_duration,
                stations,
                stopover_duration,
                providers,
                show_all_providers,
                private_price,
                public_price,
                change_airport,
                flight_source_gds,
                flight_source_ndc,
            );
            // dispatch({type: 'FLIGHT_SET_HIDDEN_FLIGHTS', payload: hidden_flight});
            dispatch(DisplayList(flight_list_render));
            // if (!searched_group.flexible_date) {
            //     ApplySort(sort_value, flight_list_render, dispatch);
            // }
            UpdateFilters(
                flight_list_render,
                airlines,
                airports,
                show_all_airports,
                alliances,
                stop_over_active,
                show_all_stop_over,
                is_day_flight,
                is_night_flight,
                stopover_airport,
                show_all_stop_over_airport,
                checked_luggage,
                stations,
                show_all_stations,
                show_all_stop_over_duration,
                providers,
                show_all_providers,
                private_price,
                public_price,
                flight_source_gds,
                flight_source_ndc,
                dispatch
            );
        }
        checkFiltersActive();
    }, [isUpdate]);

    const checkFiltersActive = () => {
        let temp_active = !show_all_stop_over || !show_all_airlines || !show_all_airports || !show_all_stop_over_airport
                            || public_price || private_price || is_day_flight || is_night_flight || change_airport;
        searched_group.journey.map((journey, journey_index) => {
            Object.keys(stopover_duration[journey_index]).forEach(key => {
                if (key !== "displayed" && stopover_duration[journey_index][key]) {
                    temp_active = true;
                }
            });

            if (filter_time[journey_index].departure[0] > 0 || filter_time[journey_index].departure[1] <= 22) {
                temp_active = true;
            }
            if (filter_time[journey_index].arrival[0] > 0 || filter_time[journey_index].arrival[1] <= 22) {
                temp_active = true;
            }
        });

        Object.keys(checked_luggage).forEach(key => {
            if (key !== "disabled" && checked_luggage[key]) {
                temp_active = true;
            }
        });

        Object.keys(stations).forEach(key => {
            if (stations[key].displayed) {
                temp_active = true;
            }
        });

        if (min_price !== filter_min_price) {
            temp_active = true;
        }
        if (max_price !== filter_max_price) {
            temp_active = true;
        }

        if (min_duration !== filter_min_duration) {
            temp_active = true;
        }

        if (max_duration !== filter_max_duration) {
            temp_active = true;
        }

        alliances.map((alliance) => {
            if (alliance.displayed) {
                temp_active = true;
            }
        });

        providers.map((provider) => {
            if (provider.displayed) {
                temp_active = true;
            }
        });

        setIsFilterActive(temp_active);
    };

    return (
        <Grid
            container
            alignItems="center"
            justify="space-between"
            className={
                clsx({
                    [classes.stickyBar]: !replaceProductContext.enableReplace
                })
            }
            direction={isHandHeld ? "column" : "row"}
        >
            <Grid item xs={isHandHeld ? 12 : 11}>
                <Grid container alignItems="center" justify={isMobile ? "space-evenly" : "flex-start"} spacing={isMobile ? 1 : 2} className={classes.spacer}>
                    <StopoversButton searched_group={searched_group} />
                    <LuggageButton />
                    <HoursButton searched_group={searched_group} />
                    <AirlinesButton />
                    <AirportsStationsButton searched_group={searched_group} />
                    <PriceButton />
                    { (JSON.parse(localStorage.getItem("config")).quotation_code === "verdie" || user.client_full.type !== 2) && <MoreFiltersButton /> }
                    {
                        !isHandHeld && is_filter_active && (
                            <Grid item style={{ padding: 0 }}>
                                <Button size="small" onClick={() => {
                                    let default_filter_time = filter_time.slice();
                                    default_filter_time.map((journey_time) => {
                                        journey_time.departure = [0, 24];
                                        journey_time.arrival = [0, 24];
                                    });
                                    dispatch(ClearFilter(default_filter_time));
                                    //dispatch(ClearClass(index_group));
                                }}>
                                    <CloseIcon className={classes.genericText} />
                                    <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`} style={{ paddingLeft: 8, textTransform: "capitalize" }}>
                                        { t("flight_search.remove_filters") }
                                    </div>
                                </Button>
                            </Grid>
                        )
                    }
                    {
                        isHandHeld && <SortButton searched_group={searched_group} />
                    }
                </Grid>
            </Grid>
            {
                !isHandHeld && (
                    <Grid item xs={1} className={classes.textRight}>
                        <SortButton searched_group={searched_group} />
                    </Grid>
                )
            }
            {
                isHandHeld && is_filter_active && (
                    <Grid item xs={12}>
                        <Button size="small" onClick={() => {
                            let default_filter_time = filter_time.slice();
                            default_filter_time.map((journey_time) => {
                                journey_time.departure = [0, 24];
                                journey_time.arrival = [0, 24];
                            });
                            dispatch(ClearFilter(default_filter_time));
                            //dispatch(ClearClass(index_group));
                        }}>
                            <CloseIcon className={classes.genericText} />
                            <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`} style={{ paddingLeft: 8, textTransform: "capitalize" }}>
                                { t("flight_search.remove_filters") }
                            </div>
                        </Button>
                    </Grid>
                )
            }
        </Grid>
    );
};

export default React.memo(FiltersBar);
