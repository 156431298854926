//---- Dependencies ----//
import React from 'react';
import { connect } from "react-redux";
import { Link, History } from "react-router";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

//---- Functions ----//

//---- Components ----//
import RenderDetailedItinerary from "../../Cart/Flight/CartDetailedItinerary.jsx";
import RenderSummarySeat from "../../Flight/FlightSearch/Desktop/RenderSummarySeat.jsx";
import RenderDetailedLuggage from "../../Flight/FlightSearch/Desktop/RenderDetailedLuggage.jsx";
import RenderDetailedOptions from "../../Flight/FlightSearch/Desktop/RenderDetailedOptions.jsx";

//---- Actions ----//

//---- CSS ----//
import "../../../Style/FlightSearch.css";
import "../../../Style/Cart.css";

//---- Class ----//
@connect((store) => {
    return {
        flight_cart: store.summary.flight_cart,
        trip_info: store.summary.trip_info
    };
})
@withTranslation()
@withRouter
class SummaryStepFlight extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {  
            tab: 0
        };
    }

    componentDidMount() {
    }   

    render() {
        const { transport, trip_info, details, t } = this.props;
        let last_leg = transport.segment.legs.length - 1;
        let origin_city = transport.segment.legs[0].origin !== null ? transport.segment.legs[0].origin.iata_city.international_name : transport.segment.legs[0].origin_station.iata_city.international_name;
        let dest_city = transport.segment.legs[last_leg].destination !== null ? transport.segment.legs[last_leg].destination.iata_city.international_name : transport.segment.legs[last_leg].destination_station.iata_city.international_name;        
        let departure_locale = transport.segment.legs[0].departure_time.split("Z");
        let departure_date = moment.utc(departure_locale[0]).format("dddd Do MMMM");
        let seats = false;                        
        let luggage = 0;
        transport.segment.legs.map((leg, leg_index) => {
            if (Object.keys(leg.seats).length !== 0) {
                seats = true;
            }
            if (leg_index === 0) {
                leg.baggage.map((baggage) => {
                    if (!baggage.is_carry_on) {
                        luggage = baggage.quantity;
                    }
                });
                if (leg.paid_options.length > 0) {
                    luggage += leg.paid_options[0].quantity;
                }
            }
        });
        return (
            <div className={"more-details-summary-box flight-search-result"} >
                <div id={"flight-search-main-selected-container"} className={ "flight-result-info" }>
                    <div className={ "flight-departure-cabine row"}>
                        <div className={ "flight-result-info-departure col-sm-8 col-md-8" }>
                            <span className={ "flight-result-info-outbound-city"}>
                                {origin_city}
                            </span>
                            <span className={"icon-103-long-arrow-pointing-to-the-right result-info-outbound-city"}/>
                            <span className={ "flight-result-info-city-arrival"}>
                                {dest_city}
                            </span>
                            <p>{departure_date}</p>
                        </div>
                        <div className={ "flight-result-info-cabin row col-sm-4 col-md-4" }>
                            <span className={ "col-sm-12 col-md-12" }>{t("global.class")} {transport.segment.legs[0].class_name}</span>
                            {
                                transport.segment.convert_distance_total > 0 && (
                                    <div className="distance-flight col-sm-12 col-md-12">{t("flight_search.distance")} {transport.segment.convert_distance_total} km</div>
                                )
                            }
                        </div>
                        <div className={ "detail-tab" }>
                            <span className={ this.state.tab === 0 ? "active" : "" } onClick={() => { 
                                this.setState({
                                    tab: 0
                                }); 
                            } }>{ t("global.itinerary") }</span>
                            {
                                // <span className={ this.state.tab === 1 ? "active" : "" } onClick={() => { dispatch(SetTab(1, flight_index, segment_index)); } }>{ t("global.condition") }</span>
                            }
                                <span className={ this.state.tab === 2 ? "active" : "" } onClick={() => { 
                                    this.setState({
                                        tab: 2
                                    }); 
                                } }>{ t("global.luggages") }</span>
                            {
                                // <span className={ this.state.tab === 3 ? "active" : "" } onClick={() => { dispatch(SetTab(3, flight_index, segment_index)); } }>{ t("global.options") }</span>
                            }
                            {
                               /* seats && (
                                    <span className={ this.state.tab === 4 ? "active" : "" } onClick={() => { 
                                        this.setState({
                                            tab: 4
                                        }); 
                                    } }>{ t("global.my_options") }</span>
                                )*/
                            }
                        </div>
                    </div>
                    {
                        this.state.tab === 0 && (
                            <RenderDetailedItinerary segment={transport.segment}/>
                        )
                    }
                    {
                        this.state.tab === 2 && (
                            <RenderDetailedLuggage flight={details} segment_index={transport.segment_index} segment={transport.segment} travelers_list={trip_info.travelers}/>
                        )
                    }

                    {
                       /* this.state.tab === 3 && (
                            <RenderDetailedOptions flight={flight} flight_index={flight_index} segment_index={segment_index} segment={segment} travelers={trip_info.travelers}
                             trace_id={flight.trace_id} provider={flight.provider} />
                        )*/
                    }
                    {
                        /*this.state.tab === 4 && (
                            <div className={ "mt20" }>
                                <div className={ "ft-ml20" }>
                                    <div className={ "title-seat-summary ft-dark-green underline" }>
                                        {t("flight_search.seats_summary")}
                                    </div>
                                </div>
                                <RenderSummarySeat flight={details} travelers_list={trip_info.travelers_list}/>
                            </div>
                        )*/
                    }
                </div>    
                
            </div>
        );
    }
}

export default SummaryStepFlight;
