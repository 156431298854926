import { flatten, uniq } from "lodash";
import { transformItineraryContentToDestinationGroups } from "./transformItineraryContentToDestinationGroups";
import { transformItineraryToDestinationGroups } from "./transformItineraryToDestinationGroups";
import { ItineraryByDay } from "../objects/itineraryByDay";
import { Destination } from "../objects/destination";

export function transformItineraryGroupsToContent(
    stepGroup: ReturnType<typeof transformItineraryToDestinationGroups>[number],
    contentGroup: ReturnType<typeof transformItineraryContentToDestinationGroups<ItineraryByDay>>[number],
    destinationsData: Destination[]
): ItineraryByDay[] {
    if (contentGroup.length === 0) {
        return [];
    }

    const result: ItineraryByDay[] = [];

    // if there are multiple destinations, we can directly return it
    const destinations = uniq(
        stepGroup.map((step) => {
            return step.destination;
        })
    );

    if (destinations.length > 1) {
        result.push({
            ...contentGroup[0]!.data,
            destinations: stepGroup.map((group) => {
                return destinationsData.find((destination) => {
                    return destination.id === group.destination;
                });
            }).filter((destination): destination is NonNullable<typeof destination> => !!destination),
            circuit: stepGroup[stepGroup.length - 1]?.circuit ?? null,
            circuit_trip_version: stepGroup[stepGroup.length - 1]?.circuit_trip_version ?? null,
            iti_type: stepGroup[stepGroup.length - 1]?.iti_type ?? null,
            day: stepGroup[0]!.days
        });
        return result;
    }

    const totalDays = flatten(
        stepGroup.map((step) => {
            return step.days;
        })
    );
    const totalContentDays = flatten(
        contentGroup.map((content) => {
            return content.days;
        })
    );
    const daysDiff = totalDays.length - totalContentDays.length;

    // if it is a single destination case
    if (stepGroup.length === 1) {
        if (totalContentDays.length < totalDays.length) {
            for (let i = 0; i < contentGroup.length; i++) {
                const item = contentGroup[i]!;
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { destination, ...data } = item.data;
                result.push({
                    ...data,
                    destinations: stepGroup.map((group) => {
                        return destinationsData.find((destination) => {
                            return destination.id === group.destination;
                        });
                    }).filter((destination): destination is NonNullable<typeof destination> => !!destination),
                    circuit: stepGroup[0]!.circuit,
                    circuit_trip_version: stepGroup[0]!.circuit_trip_version,
                    iti_type: stepGroup[0]!.iti_type,
                    day: i === contentGroup.length - 1 ?
                        new Array(item.days.length + daysDiff).fill(0) :
                        item.days
                });
            }
        } else if (totalContentDays.length > totalDays.length) {
            let diff = Math.abs(daysDiff);
            let contents = contentGroup.map((content) => {
                return content.data;
            });
            contents = [...contents].reverse().map((item) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { destination, ...data } = item;
                const result = {
                    ...data,
                    destinations: stepGroup.map((group) => {
                        return destinationsData.find((destination) => {
                            return destination.id === group.destination;
                        });
                    }).filter((destination): destination is NonNullable<typeof destination> => !!destination),
                    circuit: stepGroup[0]!.circuit,
                    circuit_trip_version: stepGroup[0]!.circuit_trip_version,
                    iti_type: stepGroup[0]!.iti_type,
                    day: new Array(
                        diff >= item.day.length ?
                            0 :
                            item.day.length - diff
                    ).fill(0)
                };
                diff -= diff >= item.day.length ?
                    item.day.length :
                    0;
                return result;
            });
            contents = contents.filter((item) => {
                return item.day.length > 0;
            });
            for (const content of contents) {
                result.push(content);
            }
        } else {
            for (let i = 0; i < contentGroup.length; i++) {
                const content = contentGroup[i]!;
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { destination, ...data } = content.data;
                result.push({
                    ...data,
                    destinations: stepGroup.map((group) => {
                        return destinationsData.find((destination) => {
                            return destination.id === group.destination;
                        });
                    }).filter((destination): destination is NonNullable<typeof destination> => !!destination),
                    day: content.days,
                    circuit: stepGroup[0]!.circuit,
                    circuit_trip_version: stepGroup[0]!.circuit_trip_version,
                    iti_type: stepGroup[0]!.iti_type
                });
            }
        }
        return result.filter((item) => {
            return item.day.length > 0;
        });
    }

    return result;
}
