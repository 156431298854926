export function createDomObjectsFromHtml(html: string): {
    document: Document,
    walker: TreeWalker
} {
    const _document = document.implementation.createHTMLDocument();
    _document.documentElement.innerHTML = html;
    const walker = _document.createTreeWalker(
        _document.documentElement,
        NodeFilter.SHOW_ELEMENT
    );
    return { walker, document: _document };
}
