import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

export default(props) => {
	return (
		<SvgIcon {...props} viewBox={ "0 0 512 512" }>
            <path d="M368 112h-64a8 8 0 000 16h64a8 8 0 000-16zM336 336h-32a8 8 0 000 16h32a8 8 0 000-16z"/>
            <path d="M232 96H80a8 8 0 00-5.657 2.343l-32 32a8 8 0 000 11.314l32 32A8 8 0 0080 176h152v32H112a8 8 0 00-5.657 2.343l-32 32a8 8 0 000 11.314l32 32A8 8 0 00112 288h120v200a8 8 0 008 8h32a8 8 0 008-8V384h88a8 8 0 005.657-2.343l32-32a8 8 0 000-11.314l-32-32A8 8 0 00368 304h-88v-32h152a8 8 0 005.657-2.343l32-32a8 8 0 000-11.314l-32-32A8 8 0 00432 192H280v-32h120a8 8 0 005.657-2.343l32-32a8 8 0 000-11.314l-32-32A8 8 0 00400 80H280V56a7.972 7.972 0 00-2.346-5.659 24 24 0 10-43.308 0A7.972 7.972 0 00232 56zm0 176H115.313l-24-24 24-24H232zm0-112H83.313l-24-24 24-24H232zm48-64h116.687l24 24-24 24H280zm0 112h148.687l24 24-24 24H280zm0 112h84.687l24 24-24 24H280zM256 32a8 8 0 11-8 8 8.009 8.009 0 018-8zm-8 32h16v416h-16z"/>
            <path d="M400 224h-96a8 8 0 000 16h96a8 8 0 000-16zM112 144h96a8 8 0 000-16h-96a8 8 0 000 16zM144 256h64a8 8 0 000-16h-64a8 8 0 000 16z"/>
        </SvgIcon>
	)
}