import { addProviderDates } from "../Components/CartMaterial/utils/addProviderDates";

const initial_state = {
    cart: [],
    start_date: null,
    current_group: null,
    modal_info: null,
    list_ports: [],
    list_stations: [],
    port_time_warning: false,
    refresh: null
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.transfers !== undefined) {
                Object.keys(action.payload.transfers).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.transfers[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            new_state = initial_state;
            return new_state;
        }
        case "SMALL_FLUSH": {
            new_state = initial_state;
            return new_state;
        }
        case "TRANSFERS_ADD_TO_CART": {
        	new_state.cart = [].concat(
                new_state.cart,
                action.payload.cart?.map((item) => {
                    return addProviderDates(item);
                }) ?? []
            );
        	return new_state;
        }
        case 'TRANSFERS_UPDATE_CART': {
            let cart = [...new_state.cart];
            for (let i = 0; i < cart.length; i++) {
                action.payload.map(item => {
                    item.map(new_transfer => {
                        if (cart[i].id === new_transfer.id) {
                            cart[i] = addProviderDates(new_transfer);
                        }
                    });
                });
            }
            new_state.cart = cart;
            return new_state;
        }
        case "TRANSFERS_SET_CART": {
            new_state.cart = action.payload.cart.map((item) => {
                return addProviderDates(item);
            });
            if (new_state.refresh !== null) {
                let cart = [...new_state.cart];
                Object.keys(new_state.refresh).map(key => {
                    for (let i = 0; i < cart.length; i++) {
                        if (cart[i].id === parseInt(key)) {
                            let transfer = { ...cart[i] };
                            transfer.price_change = new_state.refresh[key];
                            cart[i] = transfer;
                            break;
                        }
                    }
                });
                new_state.cart = cart;
            }
            return new_state;
        }
        case "TRANSFERS_CONCAT_CART": {
            let newCart = [...state.cart];
            action.payload.cart.map(item => {
                let added = false;
                newCart.map(current => {
                    if (item.id === current.id) {
                        added = true;
                    }
                });
                if (!added) {
                    newCart.push(addProviderDates(item));
                }
            });
            //new_state.cart = new_state.cart.concat(action.payload.cart);
            if (new_state.refresh !== null) {
                Object.keys(new_state.refresh).map(key => {
                    for (let i = 0; i < newCart.length; i++) {
                        if (newCart[i].id === parseInt(key)) {
                            let transfer = { ...newCart[i] };
                            transfer.price_change = new_state.refresh[key];
                            newCart[i] = transfer;
                            break;
                        }
                    }
                });
            }
            new_state.cart = newCart;
            return new_state;
        }
        case "TREANSFERS_REMOVE_CART": {
            let copy_cart = new_state.cart.slice();
            copy_cart.splice(action.payload.transfers_index, 1);
            new_state.cart = copy_cart;
            return new_state;
        }
        case "TRANSFERS_SET_DATE_AND_GROUP": {
            new_state.start_date = action.payload.date;
            new_state.current_group = action.payload.group;
            return new_state;
        }
        case "TRANSFER_EDIT_CART_BY_ID": {
            let copy_cart = new_state.cart.slice();
            for (let i = 0; i < copy_cart.length; i++) {
                if (copy_cart[i].id === action.payload.id) {
                    copy_cart[i] = addProviderDates(action.payload);
                    break;
                }
            }
            new_state.cart = copy_cart;
            return new_state;
        }
        case "TRANSFERS_EDIT_CART": {
            let cart = new_state.cart.slice();
            cart[action.payload.index] = action.payload.item;
            new_state.cart = cart;
            return new_state;
        }
        case "TRANSFERS_SET_MANUAL_MARGIN_EDIT": {
            let cart = new_state.cart.slice();
            let copy_cart = [];
            cart.map((item, item_index) => {
                if (item_index === action.payload.index) {
                    let copy_item = Object.assign({}, item);
                    copy_item.edit_margin = action.payload.edit;
                    item = copy_item;
                }
                copy_cart.push(item);
            });
            new_state.cart = copy_cart;
            return new_state;
        }
        case "TRANSFER_ON_FLIGHT_OPEN": {
            new_state.modal_info = action.payload;
            return new_state;
        }
        case "TRANSFER_ON_FLIGHT_CLOSE": {
            new_state.modal_info = null;
            return new_state;
        }
        case "TRANSFER_UPDATE_CART_FLIGHT": {
            let cart = new_state.cart.slice();
            let copy_cart = [];
            cart.map((item) => {
                if (item.id === action.payload.id) {
                    item = action.payload;
                }
                copy_cart.push(item);
            });
            new_state.cart = copy_cart;
            new_state.modal_info = null;
            return new_state;
        }
        case "TRANSFER_ON_FLIGHT_TYPE_CHANGE": {
            let modal_info = Object.assign({}, new_state.modal_info);
            modal_info.transport_type = action.payload;
            new_state.modal_info = modal_info;
            return new_state;
        }
        case "TRANSFER_ON_FLIGHT_NUMBER_CHANGE": {
            let modal_info = Object.assign({}, new_state.modal_info);
            modal_info.transport_code = action.payload;
            new_state.modal_info = modal_info;
            return new_state;
        }
        case "TRANSFER_ON_FLIGHT_DIRECTION_CHANGE": {
            let modal_info = Object.assign({}, new_state.modal_info);
            modal_info.direction = action.payload;
            new_state.modal_info = modal_info;
            return new_state;
        }
        case 'TRANSFER_REMOVE_FROM_CART_BY_ID': {
            let cart = new_state.cart.slice();
            for (let i = 0; i < cart.length; i++) {
                if (cart[i].id === action.payload) {
                    cart.splice(i, 1);
                    break;
                }
            }
            new_state.cart = cart;
            return new_state;
        }
        case 'TRANSFERS_FLUSH_TERMINALS': {
            new_state.list_ports = [];
            new_state.list_stations = [];
            return new_state;
        }
        case 'TRANSFERS_ADD_PORT':{
            let list_ports = new_state.list_ports.slice();
            list_ports.push(action.payload.port);
            new_state.list_ports = list_ports;
        	return new_state;
        }
        case 'TRANSFERS_ADD_STATION':{
            let list_stations = new_state.list_stations.slice();
            list_stations.push(action.payload.station);

            new_state.list_stations = list_stations;
        	return new_state;
        }
        case 'TRANSFERS_SET_PORT_TIME_WARNING': {
            new_state.port_time_warning = action.payload.port_time_warning;
            return new_state;
        }
        case 'CART_REFRESH_PRICE': {
            if (action.payload !== null) {
                new_state.refresh = action.payload.activity;
            }
            return new_state;
        }
    }
    return new_state;
}
