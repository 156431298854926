import React from "react";
import { SvgIcon } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export function TripListEditIcon(props) {
    return (
        <IconWrapper {...props}>
            <path
                //eslint-disable-next-line max-len
                d="m 6.7986311,18.572633 -3.2810475,0.798024 -0.013382,-4.070775 c -0.00142,-0.417526 0.4604895,-0.192635 0.9200345,-0.65517 L 16.192304,2.6078588 c 1.300076,-1.3084674 2.622822,-2.69631058 3.931401,-1.3963682 1.308418,1.2999409 1.964282,2.4343509 0.664364,3.7428183 L 7.5851629,18.379305 c -0.294241,0.29613 -0.3690214,0.192068 -0.7865318,0.193328 z"
                fill="#100000"
                id="path27"
                style={{ strokeWidth: 1.57433 }} />
            <path
                d="m 9.1640522,19.771165 c 1.7649738,-3.261053 4.8166418,0.07919 6.5562688,0.84557 1.863684,0.821009 3.523651,-0.769215 4.988086,-1.507416"
                stroke="#000000"
                strokeWidth="0.314865"
                strokeLinecap="round"
                id="path29" />
            <path
                d="m 3.1880778,21.974116 c 0.987573,-0.994028 1.8685643,-1.392647 3.1181049,-0.772994 0.9406582,0.466473 1.5571634,1.234113 2.3633735,1.691297 1.3898138,0.788106 3.7834148,0.503154 4.5046118,-0.950893"
                stroke="#000000"
                strokeWidth="0.314865"
                strokeLinecap="round"
                id="path31" />
        </IconWrapper>
    );
}

const IconWrapper = styled(SvgIcon)(() => ({
    padding: 1.5
}));
