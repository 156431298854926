import { VehicleKind } from "../objects/itinerary";

export function getTransportColor(kind: VehicleKind | null | undefined): string {
    switch (kind) {
        case 'air-taxi':
        case 'plane': return '#06CCA9';
        case 'train':
        case 'tram': return '#6B4C85';
        case 'car': return '#606060';
        case 'bus': return '#D97A39';
        case 'ferry': return '#55B7D1';
        case 'animal': return '#58c22b';
        case 'subway': return '#c4cfc0';
        case 'bike': return '#F7BF45';
        default: return '#000';
    }
}
