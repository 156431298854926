import Grid from '@material-ui/core/Grid';
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import Skeleton from "@material-ui/lab/Skeleton";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: 'bold'
    },
    inline: {
        display: 'inline-block'
    },
    staticPriceContainer: {
        position: 'fixed',
        bottom: 0,
        backgroundColor: 'white',
        marginLeft: -16,
        padding: 16,
        borderTop: '1px solid lightgray'
    },
    chooseButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    perNight: {
        marginLeft: 8,
        fontSize: 12
    }
}));

const AccommodationCardResponsiveChoose = ({pre_cart, adding, onValidate, detail, prices, diff_day, prices_loaded, togglePriceModal}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const language = useSelector(state => state.header.tmp_language);
    const currency = useSelector(state => state.trip.currency);
    return (
        <Grid className={ classes.staticPriceContainer } container justify={ 'space-between' } alignItems={ "center" }>
            {
                pre_cart.length > 0 ? (
                    <Grid item>
                        <Button variant={ "contained" } className={ classes.chooseButton } fullWidth={ true } disabled={ adding } onClick={ onValidate }>{ t("accommodation.validate_cart") }</Button>
                        { adding && <CircularProgress size={ 24 } className={ classes.buttonProgress }/> }
                    </Grid>
                ) : detail !== null ? (
                    <Fragment>
                        <Grid item>
                            {
                                prices !== null || prices_loaded ? (
                                    <Fragment>
                                        <Typography className={`${classes.bold} ${classes.inline}`}>{prices !== null && prices.length > 0 ? (new Intl.NumberFormat(language, {style: 'currency', currency: currency.iso_code}).format(Math.ceil(parseFloat(prices[0].price / diff_day)))) : t('accommodation.full')}</Typography>
                                        {
                                            prices !== null && prices.length > 0 && (
                                                <Typography className={`${classes.inline} ${classes.perNight}`}> / {t('accommodation.room_price_night_responsive')}</Typography>
                                            )
                                        }
                                    </Fragment>
                                ) : (
                                    <Skeleton width={ 300 } height={ 36 }/>
                                )
                            }
                        </Grid>
                        <Grid item>
                            <Button className={ classes.chooseButton } onClick={ togglePriceModal } variant='contained'>{ t('accommodation.choose') }</Button>
                        </Grid>
                    </Fragment>
                ) : (
                    <Grid item xs={ 12 }>
                        <Skeleton className={ classes.staticPriceContainerSkeleton } variant={ "rect" } width={ "100%" } height={ 36 }/>
                    </Grid>
                )
            }
        </Grid>
    )
};

export default React.memo(AccommodationCardResponsiveChoose);