import axios from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

type Callback = (
    data: {
        tripId: number,
        version: number,
        productId: number
    }
) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: () => void,
    onError: (error: Error) => void,
    onFinally: () => void
}>

export function useProviderDeleteManualProduct(options: Options): Callback {
    return async (data) => {
        try {
            if (options.onTrigger) {
                options.onTrigger();
            }
            await makeRequest(data);
            if (options.onSuccess) {
                options.onSuccess();
            }
        } catch (error: any) {
            if (options.onError) {
                options.onError(error);
            }
        } finally {
            if (options.onFinally) {
                options.onFinally();
            }
        }
    };
}

type RequestOptions = {
    tripId: number,
    version: number,
    productId: number
}

async function makeRequest(options: RequestOptions): Promise<void> {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        await axios.delete(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/manual-products/${options.productId}/`,
            { headers }
        );
    }
}
