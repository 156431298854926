//---- Dependencies ----//
import React from 'react';
import { connect } from "react-redux";
import { Link, History } from "react-router";
import { withTranslation } from "react-i18next";

//---- Actions ----//
import { SetSelectedPage } from "../../../Actions/Menu";

//---- Components ----//


//---- CSS ----//
import "../../../Style/Menu/Settings.css";

//---- Class ----//
@connect((store) => {
    return {
    }
})
@withTranslation()
class Settings extends React.Component {

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(SetSelectedPage("settings"));
    }
    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(SetSelectedPage(null));
    }

    render() {
        const { dispatch, t } = this.props;
        return (
            <div className={"wrapper"} >
                Settings
            </div>
        );
    }
}

export default Settings;