import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { List, ListItem } from "@mui/material";

const AutomaticProductUpdate = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const new_manual_product_list = useSelector(state => state.cart.new_manual_product_list);
    const deleted_manual_product_list = useSelector(state => state.cart.deleted_manual_product_list);
    const automatic_product_update = useSelector(state => state.header.automatic_product_update);
    const user = useSelector(state => state.user.user);
    const currency_list = useSelector(state => state.base.currency_list);
    const language = useSelector(state => state.header.tmp_language);

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const onClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            dispatch({ type: 'CART_SET_NEW_MANUAL_PRODUCT_LIST', payload: [] });
            dispatch({ type: 'CART_SET_DELETED_MANUAL_PRODUCT_LIST', payload: [] });
            dispatch({ type: 'HEADER_AUTOMATIC_PRODUCT_UPDATE', payload: false });
        }
    };
    const onValidate = () => {
        // dispatch({type: 'CART_SET_MANUAL_CART', payload: {manual_cart: new_manual_product_list}});
        dispatch({ type: 'CART_SET_NEW_MANUAL_PRODUCT_LIST', payload: [] });
        dispatch({ type: 'CART_SET_DELETED_MANUAL_PRODUCT_LIST', payload: [] });
        dispatch({ type: 'HEADER_AUTOMATIC_PRODUCT_UPDATE', payload: false });
    };
    const getPrice = manual_product => {
        const prices = manual_product.prices;
        if (user.client_full.type === 1 || user.client_full.type === 3 || quotation_code === 'verdie') {
            if (manual_product.creator === null || (manual_product.creator !== null && manual_product.creator.client.type === 2)) {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i].master_price) {
                        return prices[i];
                    }
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_tva) {
                    return prices[i];
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_surcom) {
                    return prices[i];
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].master_price) {
                    return prices[i];
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user.client) {
                    return prices[i];
                }
            }
        } else {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_tva) {
                    return prices[i];
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user.client) {
                    return prices[i];
                }
            }
        }
    };
    return (
        <Dialog open={ automatic_product_update } fullWidth maxWidth={ "md" } onClose={ onClose } disableEscapeKeyDown>
            <DialogTitle>{ t('cart-material.automatic-products') }</DialogTitle>
            <DialogContent>
                <Grid container direction={'column'}>
                    {
                        new_manual_product_list.length !== 0 &&
                        <Grid item>
                            <Typography>{new_manual_product_list.length > 1 ? t('cart-material.add-auto-products') : t('cart-material.add-auto-product')} :</Typography>
                            <List sx={{ "listStyleType": 'disc', "pl": 4, '& .MuiListItem-root': {
                                display: 'list-item'
                            } }} >
                                {
                                    new_manual_product_list.map((product) => {
                                        let price = getPrice(product);
                                        let currency = currency_list.find(item => price.selling_currency === item.id);
                                        return (
                                            <ListItem key={product.id}>{product.name} ({new Intl.NumberFormat(language, { style: "currency", currency: currency.iso_code }).format(price.selling_price)})</ListItem>
                                        );
                                    })
                                }
                            </List>
                        </Grid>
                    }
                    {
                        deleted_manual_product_list.length !== 0 &&
                        <Grid item>
                            <Typography>{deleted_manual_product_list.length > 1 ? t('cart-material.deleted-auto-products') : t('cart-material.deleted-auto-product')} :</Typography>
                            <List sx={{ "listStyleType": 'disc', "pl": 4, '& .MuiListItem-root': {
                                display: 'list-item'
                            } }} >
                                {
                                    deleted_manual_product_list.map((product) => {
                                        let price = getPrice(product);
                                        let currency = currency_list.find(item => price.selling_currency === item.id);
                                        return (
                                            <ListItem key={product.id}>{product.name} ({new Intl.NumberFormat(language, { style: "currency", currency: currency.iso_code }).format(price.selling_price)})</ListItem>
                                        );
                                    })
                                }
                            </List>
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onValidate} color={ "primary" }>{ t("global.ok") }</Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(AutomaticProductUpdate);
