//---- Dependencies ----//
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import { OverlayTrigger, Overlay } from 'react-bootstrap';

//---- Component ----//
import SelectedSeatInfo from './SelectedSeatInfo.jsx';

//---- Actions ----//
import { SetUpperCabin, ShowPopOver } from "../../../../Actions/FlightSearch";

@connect((store) => {
    return {
        seat_data: store.flight_search.seat_info,
        flight_groups: store.flight.flight_groups,
        index_group: store.flight.index_group,
        currency: store.trip.currency,
        language: store.header.tmp_language
    };
})
@withTranslation()
class SeatTabMap extends Component {
	render() {
		const { seat_data, flight, flight_groups, index_group, flight_index, dispatch, language, currency, t } = this.props;
		let seat_info = seat_data.flight[seat_data.tab].upper ? seat_data.flight[seat_data.tab].seat_upper_rows : seat_data.flight[seat_data.tab].seat_rows;
		let outbound_index = seat_data.flight_info[seat_data.tab].outbound_index;
		let leg_index = seat_data.flight_info[seat_data.tab].leg_index;
		let selected_seats = flight.outbounds[outbound_index].legs[leg_index].seats;
		let travelers_list = flight.group_passenger ? flight.group_passenger.travelers_list : flight_groups[index_group].travelers;
		return (
			<div className={ "row" }>
				<div className={ "col-md-3" }/>
				<div className={ "modal-seat-map block-center" }>
				{
					seat_data.flight[seat_data.tab].seat_upper_rows.length !== 0 && (
						<div className={ "detail-tab text-center" }>
							<span className={ !seat_data.flight[seat_data.tab].upper ? "active" : "" } onClick={() => {dispatch(SetUpperCabin(false, seat_data.tab))} } >
			    				{t("flight_search.principal_cabin")}
			    			</span>
			    			<span className={ seat_data.flight[seat_data.tab].upper ? "active" : "" } onClick={() => {dispatch(SetUpperCabin(true, seat_data.tab))} } >
			    				{t("flight_search.upper_cabin")}
			    			</span>
						</div>
					)
				}
				{
					seat_info.map((seat_rows, rows_index) => {
						return (
							<div className={ "seat-row" } key={seat_rows.number}>
								<div className={ (seat_rows.wings ? "wing normal" : "paroie normal") + (seat_rows.seats[0].exit_row ? " exit" : "") }>
									{
										seat_rows.seats[0].exit_row && (
											<img src={ "/Img/flightSeatMap/exit-door.png" }/>
										)
									}
								</div>
								{
									seat_rows.seats.map((seats, seats_index) => {
										if (seats.seat_type === "Seat") {
											if (seats.availability === "NoSeat")
											{
											    return (
											        <span key={seats_index} className="noSeat"/>
											    );
											}
											else {
												let seat_classname = "seatMapEco";
												if (seat_rows.number < 10)
													seat_classname += " resize"
												if (seats.availability === "Available")
												{
													if (Object.keys(selected_seats).length > 0) {
														Object.keys(selected_seats).map((key) => {
															if ((Object.values(selected_seats[key])).includes(seats.code))
																seat_classname += " selected pointer";
															else
																seat_classname += " available pointer";
														})
													}
													else
														seat_classname += " available pointer";
												}
												if (seats.availability === "Occupied")
												    seat_classname += " occupied";
												else if (seats.availability === "Blocked")
												    seat_classname += " blocked";
												else if (seats.availability === "Reserved")
												    seat_classname += " reserved";
												if (seats.extra_legroom)
												    seat_classname += " extra-legroom";
												if (seats.bulkhead)
												    seat_classname += " bulkhead";
												if (!seats.extra_legroom && !seats.bulkhead)
												    seat_classname += " eco";
												if (seats.seat_type === "Seat") {
													if (seats.availability === "Available") {
														return (
															<span key={seats_index}>
																<a className={ seat_classname } ref={rows_index + "_" + seats_index} onClick={() => {dispatch(ShowPopOver(true, seat_data.tab, seat_rows.number, seats_index))}}>
																	<span className={ "seatCode" }>{seats.code + "  "}</span>
																</a>
																<Overlay show={seats.show} onHide={() => {dispatch(dispatch(ShowPopOver(false, seat_data.tab, seat_rows.number, seats_index)))}} placement="top" rootClose  target={() => ReactDOM.findDOMNode(this.refs[rows_index + "_" + seats_index])}>
																	<SelectedSeatInfo seat={seats.code} price={seats.prices ? seats.prices[0].selling_price : null} flight_index={flight_index} outbound_index={outbound_index} leg_index={leg_index} seat_index={seats_index} row_index={seat_rows.number} flight={flight}/>
																</Overlay>
															</span>
														);
													}
													else {
														return (
															<span key={seats_index}>
																<a className={ seat_classname } ><span className={ "seatCode" }>{seats.code + "  "}</span></a>
															</span>
														);
														
													}
												}
											}
										}
										else if (seats.seat_type === "Aisle") {
										    return (
										        <span key={seats_index} className={ "cabin-aisle" }/>
										    );
										}
										else if (seats.seat_type === "Toilet") {
										    return (
										        <span key={seats_index} className={ "toilet" }/>
										    );
										}
									})
								}
							<div className={ (seat_rows.wings ? "wing reverse" : "paroie reverse") + (seat_rows.seats[0].exit_row ? " exit" : "")  }>
							{
								seat_rows.seats[seat_rows.seats.length - 1].exit_row && (
									<img src={ "/Img/flightSeatMap/exit-door.png" }/>
								)
							}
							</div>
						</div>
						)
					})
				}
				</div>
				<div className="col-md-3">
					<div className={ "bold" }>{t("flight_search.selected_seats")}</div>
					<div>
						<ul className={ "passenger-seat-list" }>						
						{
							travelers_list.map((traveler, traveler_index) => {
								return (
									<li key={traveler_index}>{traveler.first_name + " " + traveler.last_name + " : "}
										{
											Object.keys(selected_seats).map((id) => {
												if (traveler.id === Number(id)) {
													return (
														<span key={id} >
                                                            <span className={ "bold" }>
                                                                {selected_seats[id].seat}
                                                            </span>
                                                            {
                                                                selected_seats[id].price && (
                                                                    <span>
                                                                        <span>{" / " + t("flight_search.seat_price")}</span>
                                                                        <span className={ "bold" }>{new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(selected_seats[id].price))}</span>
                                                                    </span>
                                                                )
                                                            }
                                                        </span>
													);
												}
											})
										}
									</li>
								);
							})
						}
						</ul>
					</div>
				</div>
			</div>
		)
	}
}
export default SeatTabMap;
