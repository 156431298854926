//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { Alert, Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useSnackbar } from "notistack";

import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import Axios from 'axios';
import GetCookie from '../Common/Functions/GetCookie';
import RefreshToBook from './Functions/RefreshToBook';
import { AppState } from "../../../Reducers/Reducers";
import i18n from '../../i18n';
import localeText from '../Functions/localeText';

const useStyles = makeStyles((theme) => ({
    formControlRootSmall: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
}));

type BookingActivityReturnPoint = {
    product: any;
};

export function BookingActivityReturnPoint (props: BookingActivityReturnPoint): JSX.Element {
    const { product } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const end_date = useSelector((state: AppState) => state.trip.end_date);
    const locales = useSelector(state => state.user.locales);
    const current_locale = locales.find((el:any) => {return el.language_code === i18n.language});
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const [selectedValue, setSelectedValue] = useState<any>('');

    let return_point = product.cust_variant.return_point;
    
    useEffect(() => {
        if (return_point === 'OPEN_FIELD') {
            setSelectedValue(product.open_field_return_point);
        } else if (return_point === 'PLACE') {
            setSelectedValue(product.place_return_point);
        } else if (return_point === 'HOTEL' && product.cust_variant.hotel_return_point !== null) {
            let found = product.cust_variant.hotel_return_point.find((el:any) => {return el.name === product.hotel_return_point});
            setSelectedValue(found);
        }
    }, []);

    const handleChange = (event:any, value:any) => {
        let { pass_check, headers } = CheckBeforeRequest(); 
        if (pass_check) {
            let request = {};
            if (return_point === 'PLACE') {
                setSelectedValue(value);
                request.place_return_point = value;
            }
            if (return_point === 'OPEN_FIELD') {
                console.log('event.target.value:', event.target.value);
                request.open_field_return_point = event.target.value === '' ? null : event.target.value;
                setSelectedValue(event.target.value);
            }
            if (return_point === 'HOTEL') {
                setSelectedValue(value);
                request.hotel_return_point = value !== null ? value.name : null;
            }
            Axios({
                method: "PATCH",
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${product.id}/`,
                headers: headers,
                data: JSON.stringify(request)
            }).then(function(response){
                enqueueSnackbar(t<string>('poi.success_return_point'), { variant: "success", disableWindowBlurListener: true, autoHideDuration: 6000 });  
                dispatch({type: 'POI_EDIT_CART_BY_ID', payload: response.data});
                RefreshToBook(product, end_date, dispatch, "return_point");
            }).catch(function(error){
                if(error.response.status === 409 && error.response.data.detail !== undefined){
                    enqueueSnackbar(error.response.data.detail, { variant: "error", disableWindowBlurListener: true, autoHideDuration: 6000 });  
                }else {
                    enqueueSnackbar(t<string>("cart.product_fail"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 3500 });
                }
            })
        }
    }
    return (
        <Fragment>
        {
            return_point === 'PLACE' && product.cust_variant.place_return_point !== null && product.cust_variant.place_return_point.length !== 0 &&
            <Grid>
                <Autocomplete
                    value={selectedValue}
                    onChange={handleChange}
                    options={product.cust_variant.place_return_point}
                    getOptionLabel={(option:any) => option.displayed_destination}
                    renderInput={(params) => <TextField {...params} className={classes.formControlRootSmall} variant="outlined" label={t<string>('poi.return_point')} />}
                />
            </Grid>
        }
        {
            return_point === 'OPEN_FIELD' && localeText(current_locale.id, product.cust_variant.localization, product.cust_variant.open_field_return_point, 'open_field_return_point') !== undefined &&
            <Grid>
                <FormControl style={{width: '100%'}} className={classes.formControlRootSmall}>
                    <InputLabel>{t<string>('poi.return_point')}</InputLabel>
                    <Select
                        value={selectedValue}
                        label={t<string>('poi.return_point')}
                        onChange={handleChange}
                    >
                        {/* <MenuItem value="">
                            <em>{t<string>('cart.none')}</em>
                        </MenuItem> */}
                        <MenuItem value={localeText(current_locale.id, product.cust_variant.localization, product.cust_variant.open_field_return_point, 'open_field_return_point')}>{localeText(current_locale.id, product.cust_variant.localization, product.cust_variant.open_field_return_point, 'open_field_return_point')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        }
        {
            return_point === 'HOTEL' && product.cust_variant.hotel_return_point !== null && product.cust_variant.hotel_return_point.length !== 0 &&
            <Grid>
                <Autocomplete
                    value={selectedValue}
                    options={product.cust_variant.hotel_return_point}
                    getOptionLabel={(option:any) => option.name}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} className={classes.formControlRootSmall} variant="outlined" label={t<string>('poi.return_point')} />}
                />
            </Grid>
        }
        {
            quotation_code === 'cercledesvoyages' && return_point === 'HOTEL' && product.cust_variant.hotel_return_point === null &&
            <Grid>
                <Alert severity={'warning'}>{t<string>('cart-material.return-point-error')}</Alert>
            </Grid>
        }
        </Fragment>
    )
};