export default (first_price, first_currency, trip_currency, factor_used) => {
	if (first_currency === undefined || trip_currency === undefined || factor_used === undefined)
		return first_price;
	
	if (trip_currency.iso_code !== first_currency.iso_code && factor_used === null) {
		let dollar_price = 0;
		if (first_currency.iso_code !== "USD")
			dollar_price = first_price / first_currency.usd_factor;
		else
			dollar_price = first_price;
		let trip_currency_price = dollar_price * trip_currency.usd_factor;
		return trip_currency_price;

	}  else if(trip_currency.iso_code !== first_currency.iso_code && factor_used !== null) {
		let trip_currency_price = first_price * factor_used;
		return trip_currency_price;

	} else {
		return first_price;
	}
}