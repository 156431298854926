//---- Dependencies ----//
import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

//---- Commons ----//
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import CustomOverlay from "../../Common/CustomOverlay.jsx";

//---- Actions ----//
import { SetLocationPoint, SetAgency } from "../../../Actions/CarsSearch";

@connect((store) => {
    return {
        location_point: store.cars_search.location_point,
    }
})
@withTranslation()
export default class CarsLocationList extends React.Component {
	constructor(props) {
	    super(props);
	    this.setWrapperRef = this.setWrapperRef.bind(this);
	    this.handleClickOutside = this.handleClickOutside.bind(this);
	}
	setWrapperRef(node) {
	    this.wrapperRef = node;
	}
	handleClickOutside(event) {
	    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
	        this.props.dispatch(SetLocationPoint([]));
	    }
	}
	selectAgency(agency) {
		const { index, type, getInputRef, return_dest, index_ref, dispatch } = this.props;
		let search = "search=" + agency.airport_code;
		const { pass_check, headers } = CheckBeforeRequest();
		if (pass_check && agency.airport_code) {
			$.ajax({
			    method: "GET",
			    headers: headers,
			    url: `${API_HREF}iata-airports/?search=${agency.airport_code}`,
			    success: function (data) {
			        agency.country_code = data.results[0].iata_city.iata_country.country_code;
			    },
			    error: function (error) {
			        console.log(error);
			    }
			});
		}
		getInputRef(index_ref).value = (agency.international_name).toLowerCase();
		dispatch(SetAgency(agency, index, type));
		if (type === "origin" && !return_dest)
			dispatch(SetAgency(agency, 0, "return"));
		dispatch(SetLocationPoint([]))
		dispatch(SetAgency(agency, index, type));
	}
	render() {
		const { location_point } = this.props;
		if (location_point.length === 0) {
		    document.removeEventListener("mousedown", this.handleClickOutside);
		} else {
		    document.addEventListener('mousedown', this.handleClickOutside);
		}
		return (
			<div>
				{
					location_point.length > 0 && (
						<div ref={ (node) => { this.wrapperRef = node; } } className={ "cars-search-location-list-container" }>
						{
							location_point.map((location, location_index) => {
								let city = "";
								if (location.iata_city !== undefined) {
									city = location.iata_city.name ? location.iata_city.name : location.iata_city.international_name;
								}
								return (
									<div key={location_index} onClick={ () => {this.selectAgency(location)} } className={ "location-list" }>
										<div className="row">
										{
											location.address !== null && location.address !== "" && (
												<CustomOverlay id={ "tooltip-agency-" + location_index} tooltip={ location.address } placement={ "bottom" }>
													<h5 className={"location-name col-md-12"}>{(location.international_name).toLowerCase() + (location.airport_code ? " (" + location.airport_code + ")" : "")}</h5>
												</CustomOverlay>
											)
										}
										{
											(location.address === null || location.address === "") && (
												<h5 className={"location-name col-md-12"}>{(city !== "" ? city + ", " : city) + (location.international_name).toLowerCase() + (location.airport_code ? " (" + location.airport_code + ")" : "")}</h5>
											)
										}
										</div>
									</div>
								)
							})
						}
						</div>
					)
				}
			</div>
		)
	}
}