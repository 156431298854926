//Dependencies
import axios from 'axios';
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
//Core
import { Button, Stack } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
//Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import { useSnackbar } from 'notistack';
import SelectLead from '../BookingMaterial/SelectLead';
import { ReloadTempTravelers } from '../../Actions/Booking';

const useStyles = makeStyles({
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    cancelButton: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    }
});

const CartManualProductsManualBooking = ({ manual_product, onManualBooking }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const reload_temp_travelers = useSelector(store => store.booking.reload_temp_travelers);
    const traveler_groups = useSelector(store => store.trip.traveler_groups);
    const travelers = useSelector(store => store.trip.travelers);
    const contact_traveler = useSelector(store => store.trip.contact_traveler_detail);
    const end_date = useSelector(store => store.trip.end_date);

    const [tempContactLead, setTempContactLead] = useState(null);
    const [tempTravelers, setTempTravelers] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [status, setStatus] = useState(manual_product.booking_status !== null ? manual_product.booking_status.status_booking : 'DEFAULT');
    const [reference, setReference] = useState(manual_product.booking_status !== null && manual_product.booking_status.item_reference !== null ? manual_product.booking_status.item_reference : '');
    useEffect(() => {
        if (contact_traveler !== null) {
            let temp_contact_lead = Object.assign({}, contact_traveler);

            if (temp_contact_lead.first_name === t('global.first_name') || temp_contact_lead.first_name === t("trip.traveler")) {
                temp_contact_lead.first_name = "";
            }
            if (temp_contact_lead.last_name === t('global.last_name') || temp_contact_lead.last_name.includes(t("global.adult")) || temp_contact_lead.last_name.includes(t("global.child"))) {
                temp_contact_lead.last_name = "";
            }
            if (temp_contact_lead.city === null) {
                temp_contact_lead.city = "";
            }
            if (temp_contact_lead.postal_code === null) {
                temp_contact_lead.postal_code = "";
            }
            if (temp_contact_lead.address === null) {
                temp_contact_lead.address = "";
            }
            if (temp_contact_lead.address_details === null) {
                temp_contact_lead.address_details = "";
            }
            temp_contact_lead.temp_phone = temp_contact_lead.phone_country_code + temp_contact_lead.phone_number;
            setTempContactLead(temp_contact_lead);
        }
    }, [contact_traveler]);
    useEffect(() => {
        if (reload_temp_travelers) {
            createTempTravelers();
        } else {
            dispatch(ReloadTempTravelers(true));
        }
    }, [travelers]);
    const onStatusChange = event => {
        setStatus(event.target.value);
    };
    const onReferenceChange = event => {
        setReference(event.target.value);
    };
    const onValidateManualBooking = () => {
        if (reference.length > 50) {
            enqueueSnackbar(t('cart-material.reference-length-error'), { variant: 'error' });
        } else {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'POST',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/status-manager/${manual_product.id}/status/`,
                data: {
                    status_booking: status === 'DEFAULT' ? null : status,
                    item_reference: reference
                }
            }).then(response => {
                dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
                onManualBooking();
            }).catch(error => {
                console.log(error);
            });
        }
    };
    const onCancelManualBooking = () => {
        onManualBooking();
    };
    const createTempTravelers = () => {
        let temp_travelers = [];
        let numero_adult = 1;
        let numero_teen = 1;
        let numero_child = 1;
        let numero_baby = 1;

        travelers.map((traveler, traveler_index) =>{
            let temp_traveler = Object.assign({}, traveler);
            let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
            if (age > 12 && age < 18) {
                temp_traveler.age_label = t("flight_search.ado") + " " + numero_teen;
                numero_teen += 1;
            } else if (age > 2 && age <= 12) {
                temp_traveler.age_label = t("flight_search.child") + " " + numero_child;
                numero_child += 1;
            } else if (age <= 2) {
                temp_traveler.age_label = t("flight_search.baby") + " " + numero_baby;
                numero_baby += 1;
            } else if (age >= 18) {
                temp_traveler.age_label = t("flight_search.adult") + " " + numero_adult;
                numero_adult += 1;
            }

            if (rooms.length !== 0) {
                for (room_index = 0; room_index < rooms.length; room_index++) {
                    if (rooms[room_index].travelers.includes(traveler.id)) {
                        temp_traveler.room_index = room_index;
                    }
                }
            }

            if (traveler.first_name === t('global.first_name') || traveler.first_name === t("trip.traveler")) {
                temp_traveler.first_name = "";
            }
            if (traveler.last_name === t('global.last_name') || traveler.last_name.includes(t("global.adult")) || traveler.last_name.includes(t("global.child"))) {
                temp_traveler.last_name = "";
            }

            if (traveler.identity_doc_number === null) {
                temp_traveler.identity_doc_number = "";
            }
            if (traveler.identity_doc_country_emission === null) {
                temp_traveler.identity_doc_country_emission = "";
            }
            if (traveler.country === null) {
                temp_traveler.country = "";
            }
            if (temp_traveler.nationality === null) {
                if (JSON.parse(localStorage.getItem("config")).quotation_code === "verdie") {
                    temp_traveler.nationality = frenchCountry;
                } else {
                    temp_traveler.nationality = "";
                }
            }
            if (traveler.email === null) {
                temp_traveler.email = "";
            }

            temp_traveler.temp_phone = temp_traveler.phone_country_code + temp_traveler.phone_number;
            temp_traveler.real_index = traveler_index;

            temp_travelers.push(temp_traveler);
        });

        temp_travelers.sort((a, b) => {
            let da = new Date(a.birth_date);
            let db = new Date(b.birth_date);
            return (da - db);
        });

        setTempTravelers(temp_travelers);
    };
    return (
        <Grid container spacing={ 4 }>
            <Grid item xs={ 6 }>
                <FormControl fullWidth size="small" variant={ 'outlined' }>
                    <InputLabel htmlFor={ 'status' }>{ t('cart-material.status') }</InputLabel>
                    <Select id={ 'status' } value={ status } label={ t('cart-material.status') } onChange={ onStatusChange }>
                        <MenuItem value={ 'DEFAULT' }>{ t('cart-material.none') }</MenuItem>
                        <MenuItem value={ 'CONFIRMED' }>{ t('cart-material.confirmed') }</MenuItem>
                        <MenuItem value={ 'WAITING' }>{ t('cart-material.pending') }</MenuItem>
                        <MenuItem value={ 'CANCELLED' }>{ t('cart-material.cancelled') }</MenuItem>
                        <MenuItem value={ 'ERROR' }>{ t('cart-material.error') }</MenuItem>
                        <MenuItem value={ 'REFUSED'}> { t("cart-material.refused")} </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={ 6 }>
                <FormControl fullWidth size="small" variant={ 'outlined' }>
                    <InputLabel htmlFor={ 'note' }>{ t('cart-material.reference') }</InputLabel>
                    <OutlinedInput
                        id={ 'note' }
                        type={ 'text' }
                        onChange={ onReferenceChange }
                        label={ t('cart-material.reference') }
                        value={ reference }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={ 12 }>
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                    <Button onClick={ onCancelManualBooking }>{ t('global.cancel') }</Button>
                    <Button onClick={ onValidateManualBooking }>{ t('global.validate') }</Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default CartManualProductsManualBooking;
