import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MailTemplateVisualEditorColorPicker } from "./mailTemplateVisualEditorColorPicker";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";

type Props = {
    color: string,
    onChangeColor: (color: string) => void
}

export function MailTemplateVisualEditorBorderColorOption(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <MailTemplateVisualEditorOptionsSection>
            <Typography>{t<string>('shared.mail-template-visual-editor-border-color')}</Typography>
            <MailTemplateVisualEditorColorPicker
                color={props.color}
                onChangeColor={props.onChangeColor}
            />
        </MailTemplateVisualEditorOptionsSection>
    );
}
