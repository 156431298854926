import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { withRouter } from "react-router";

import { makeStyles } from "@material-ui/core";

import { Box } from '@mui/material';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { BookingManualProducts } from "./BookingManualProducts"
import BookingCard from './BookingCard.js';
import BookingModal from './BookingModal.js';
import BookingTravelerInfo from './BookingTravelerInfo.js';
import BookingRoomInfo from './BookingRoomInfo.js';
import BookingCarInfo from './BookingCarInfo.js';
import BookingFlightInfo from './BookingFlightInfo.js';
import BookingTransferInfo from './BookingTransferInfo.js';
import BookingActivityInfo from './BookingActivityInfo.js';
import BookingAssistanceInfo from "./BookingAssistanceInfo";
import BookingInsuranceInfo from "./BookingInsuranceInfo";
import CartStepper from '../CartMaterial/CartStepper';
import RefreshPrice from '../RefreshPrice/RefreshPrice.js';

import { ReloadTempTravelers, SetChecksPassed, SetSameTravelerID } from '../../Actions/Booking.js';

import CheckBeforeRequest from '../Common/CheckBeforeRequest.js';

import axios from "axios";

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        padding: '15px 0',
        fontSize: 36,
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    genericPadding: {
        padding: 12
    }
}));

const Booking = withRouter(({ router }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const step = useSelector(state => state.cart.step);
    const end_date = useSelector(store => store.trip.end_date);
    const traveler_groups = useSelector(store => store.trip.traveler_groups);
    const travelers = useSelector(store => store.trip.travelers);
    const contact_traveler = useSelector(store => store.trip.contact_traveler_detail);
    const reload_temp_travelers = useSelector(store => store.booking.reload_temp_travelers);

    const [rooms, setRooms] = useState([]);
    const [tempContactLead, setTempContactLead] = useState(null);
    const [tempTravelers, setTempTravelers] = useState([]);
    const [moreInfo, setMoreInfo] = useState([]);
    const [infoIncomplete, setInfoIncomplete] = useState([]);
    const [frenchCountry, setFrenchCountry] = useState(null);

    useEffect(() => {
        getFrenchCountry();
    }, []);

    useEffect(() => {
        //reset checks
        dispatch(SetChecksPassed(null));
        dispatch(SetSameTravelerID([]));
    }, []);

    useEffect(() => {
        if (step !== 4) {
            router.push(`/${window.url_name}/apps/cart`);
        }
    }, [step]);

    useEffect(() => {
        let temp_rooms = [];
        traveler_groups.map((room) => {
            if (room.default === true) {
                let temp_room = Object.assign({}, room);
                temp_room.lead_traveler_info = temp_room.lead_traveler_info !== null ? temp_room.lead_traveler_info : "";
                temp_room.lead_traveler = temp_room.lead_traveler !== null ? temp_room.lead_traveler : "";
                temp_rooms.push(temp_room);
            }
        });

        //look for travelers not in rooms
        travelers.map((traveler) => {
            let found = false;
            temp_rooms.map((room) => {
                if (room.travelers.includes(traveler.id)) {
                    found = true;
                }
            });
            if (found === false) {
                let group_found = traveler_groups.find(item => item.travelers.includes(traveler.id));
                if (group_found !== undefined) {
                    temp_rooms.push(group_found);
                }
            }
        });
        setRooms(temp_rooms);
    }, [traveler_groups, travelers]);

    useEffect(() => {
        if (frenchCountry !== null) {
            let keep_more_info = moreInfo.slice();
            let keep_info_incomplete = infoIncomplete.slice();
            if (reload_temp_travelers) {
                createTempTravelers();
            } else {
                dispatch(ReloadTempTravelers(true));
            }
            if (keep_more_info.length > 0) {
                setMoreInfo(keep_more_info);
            }
            if (keep_info_incomplete.length > 0) {
                setInfoIncomplete(keep_info_incomplete);
            }
        }
    }, [travelers, rooms, frenchCountry]);

    useEffect(() => {
        if (contact_traveler !== null) {
            let temp_contact_lead = Object.assign({}, contact_traveler);

            if (temp_contact_lead.first_name === t('global.first_name') || temp_contact_lead.first_name === t("trip.traveler")) {
                temp_contact_lead.first_name = "";
            }
            if (temp_contact_lead.last_name === t('global.last_name') || temp_contact_lead.last_name.includes(t("global.adult")) || temp_contact_lead.last_name.includes(t("global.child"))) {
                temp_contact_lead.last_name = "";
            }
            if (temp_contact_lead.city === null) {
                temp_contact_lead.city = "";
            }
            if (temp_contact_lead.postal_code === null) {
                temp_contact_lead.postal_code = "";
            }
            if (temp_contact_lead.address === null) {
                temp_contact_lead.address = "";
            }
            if (temp_contact_lead.address_details === null) {
                temp_contact_lead.address_details = "";
            }
            temp_contact_lead.temp_phone = temp_contact_lead.phone_country_code + temp_contact_lead.phone_number;
            setTempContactLead(temp_contact_lead);
        }
    }, [contact_traveler]);

    const getFrenchCountry = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "GET",
                url: `${API_HREF}iata-countries/?search=fr`,
                headers: headers
            }).then(function (response) {
                response.data.results.map((country) => {
                    if (country.country_code === "FR") {
                        setFrenchCountry(country);
                    }
                });
            });
        }
    };

    const createTempTravelers = () => {
        let temp_travelers = [];
        let numero_adult = 1;
        let numero_teen = 1;
        let numero_child = 1;
        let numero_baby = 1;
        let temp_more_info = [];
        let temp_info_incomplete = [];

        travelers.map((traveler, traveler_index) => {
            let temp_traveler = Object.assign({}, traveler);
            let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
            if (age > 12 && age < 18) {
                temp_traveler.age_label = t("flight_search.ado") + " " + numero_teen;
                numero_teen += 1;
            } else if (age > 2 && age <= 12) {
                temp_traveler.age_label = t("flight_search.child") + " " + numero_child;
                numero_child += 1;
            } else if (age <= 2) {
                temp_traveler.age_label = t("flight_search.baby") + " " + numero_baby;
                numero_baby += 1;
            } else if (age >= 18) {
                temp_traveler.age_label = t("flight_search.adult") + " " + numero_adult;
                numero_adult += 1;
            }

            if (rooms.length !== 0) {
                for (room_index = 0; room_index < rooms.length; room_index++) {
                    if (rooms[room_index].travelers.includes(traveler.id)) {
                        temp_traveler.room_index = room_index;
                    }
                }
            }

            if (traveler.first_name === t('global.first_name') || traveler.first_name === t("trip.traveler")) {
                temp_traveler.first_name = "";
            }
            if (traveler.last_name === t('global.last_name') || traveler.last_name.includes(t("global.adult")) || traveler.last_name.includes(t("global.child"))) {
                temp_traveler.last_name = "";
            }

            if (traveler.identity_doc_number === null) {
                temp_traveler.identity_doc_number = "";
            }
            if (traveler.identity_doc_country_emission === null) {
                temp_traveler.identity_doc_country_emission = "";
            }
            if (traveler.country === null) {
                temp_traveler.country = "";
            }
            if (temp_traveler.nationality === null) {
                if (JSON.parse(localStorage.getItem("config")).quotation_code === "verdie") {
                    temp_traveler.nationality = frenchCountry;
                } else {
                    temp_traveler.nationality = "";
                }
            }
            if (traveler.email === null) {
                temp_traveler.email = "";
            }

            temp_traveler.temp_phone = temp_traveler.phone_country_code + temp_traveler.phone_number;
            temp_traveler.real_index = traveler_index;

            temp_travelers.push(temp_traveler);
            temp_more_info.push(false);
            temp_info_incomplete.push(false);
        });

        temp_travelers.sort((a, b) => {
            let da = new Date(a.birth_date);
            let db = new Date(b.birth_date);
            return (da - db);
        });

        setTempTravelers(temp_travelers);
        setMoreInfo(temp_more_info);
        setInfoIncomplete(temp_info_incomplete);
    };

    return (
        <Container maxWidth={'lg'}>
            <Typography className={classes.title}>{t('cart-material.booking')}</Typography>

            <Box sx={{ marginBottom: 2 }}>
                <CartStepper active_step={4} />
            </Box>

            <Grid container alignItems="flex-start" justify="space-between" direction="row-reverse" spacing={3} className={classes.genericPadding}>
                <Grid item md={4} xs={12}>
                    <BookingCard tempTravelers={tempTravelers} infoIncomplete={infoIncomplete} setInfoIncomplete={setInfoIncomplete} setRooms={setRooms} />
                </Grid>
                <Grid item md={8} xs={12}>
                    <BookingTravelerInfo rooms={rooms} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} moreInfo={moreInfo} setMoreInfo={setMoreInfo} infoIncomplete={infoIncomplete} />
                    <BookingRoomInfo tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                    <BookingCarInfo tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                    <BookingTransferInfo tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                    <BookingFlightInfo tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                    <BookingActivityInfo tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                    <BookingAssistanceInfo tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                    <BookingInsuranceInfo tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                    <BookingManualProducts />
                </Grid>
            </Grid>
            <BookingModal />
            <RefreshPrice />
        </Container>
    );
});

export default Booking;
