import { eachDayOfInterval } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

type Step = {
    start_date: string,
    end_date: string
}

export function getItineraryDayDifference(step: Step): Date[] {
    const startDate = utcToZonedTime(step.start_date, 'Etc/UTC');
    const endDate = utcToZonedTime(step.end_date, 'Etc/UTC');
    let daysDifference: Date[] = [];
    try {
        daysDifference = eachDayOfInterval({
            start: startDate,
            end: endDate
        });
    } catch (error) {
        if (error instanceof RangeError && error.message === 'Invalid interval') {
            console.error(error);
        } else {
            throw error;
        }
    }

    if (daysDifference.length === 0) {
        daysDifference = [utcToZonedTime(step.start_date, 'Etc/UTC')];
    }

    return daysDifference;
}
