import React, { useState } from "react";
import { Box, IconButton, styled } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import clsx from "clsx";
import { Picture } from "../Menu/MaterialTripList/picture/objects/picture";

type Props = {
    pictures: Picture[]
}

export function ItineraryDestinationInfoCarousel(props: Props): JSX.Element {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const currentSlide = props.pictures[currentSlideIndex];

    const onNextSlide = (event: { stopPropagation: () => void; }) => {
        event.stopPropagation();
        setCurrentSlideIndex((index) => index + 1 >= props.pictures.length ? 0 : index + 1);
    };

    const onPrevSlide = (event: { stopPropagation: () => void; }) => {
        event.stopPropagation();
        setCurrentSlideIndex((index) => index - 1 < 0 ? props.pictures.length - 1 : index - 1);
    };

    return (
        <Box sx={{ height: 400, position: 'relative' }}>
            {
                props.pictures.map((picture) => (
                    <Image key={picture.id}
                        className={
                            clsx(
                                'cart-pictures-slide',
                                'fade',
                                { ['show']: currentSlide?.id === picture.id }
                            )
                        }
                        src={ picture.url ?? ''}
                    />
                ))
            }

            <IconButton className={ 'cart-pictures-button' } onClick={onPrevSlide}>
                <ChevronLeft fontSize={ 'large' }/>
            </IconButton>
            <IconButton className={ 'cart-pictures-button cart-pictures-button-right' } onClick={onNextSlide}>
                <ChevronRight fontSize={ 'large' }/>
            </IconButton>
        </Box>
    );
}

const Image = styled(
    'div',
    {
        shouldForwardProp(propName) {
            return propName !== 'src';
        }
    }
)<{src: string}>((props) => ({
    backgroundImage: `url(${props.src})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center'
}));
