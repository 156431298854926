import axios from "axios";

import CheckBeforeRequest from "../../../Common/CheckBeforeRequest.js";

/*LIST USERS*/
export const getCountries = (callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}iata-countries/?limit=1000`
        }).then(function (response) {
            if (response.data.results) {
                callback(response.data.results.map(e => {
                    return { ...e, value: e.id, label: e.name };
                }));
            }
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};
