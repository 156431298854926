//---- Dependencies ----//
let GoogleMapsLoader = require('google-maps');

export default (component, index, custom_info) => { 
    let map = null;
    let key = "";
    let config = JSON.parse(localStorage.getItem("config"));
    for (let i = 0; i < config.keys.length; i++) {
        if (config.keys[i].identifier === "google_api") {
            key = config.keys[i].value;
        }
    }
    GoogleMapsLoader.KEY = key;
    GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];
    GoogleMapsLoader.LANGUAGE = "fr";
    GoogleMapsLoader.load(function(google) {
        if (document.getElementById('custom-info-map-' + index) !== null) {
            let options = {
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                center: {lat: parseFloat(0), lng: parseFloat(0)},
                zoom: 15,
                draggable: false,
                clickableIcons: false,
                scaleControl: true
            };
            map = new google.maps.Map(document.getElementById('custom-info-map-' + index), options);
            if (custom_info !== null) {
                let marker = null;
                map.setCenter(new google.maps.LatLng(parseFloat(custom_info.custom_product.destination.data.latitude), parseFloat(custom_info.custom_product.destination.data.longitude)));
                if (marker !== null) {
                    marker.setMap(null);
                }
                let marker_option = {
                    position: new google.maps.LatLng(parseFloat(custom_info.custom_product.destination.data.latitude), parseFloat(custom_info.custom_product.destination.data.longitude)),
                    map: map,
                    /*
                    label: {
                        text: (current_step + 1).toString(),
                        color: "#76B6C2",
                        fontWeight: "bold"
                    },
                     */
                    icon: {
                        url: "/Img/Pin/bleu-2.png",
    //                    labelOrigin: new google.maps.Point(15, 15)
                    }
                };                
                marker = new google.maps.Marker(marker_option);
                marker.setMap(map);
            }
            //component.setState({google: google, map: map});
        }
    });  
};
