import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import axios from "axios";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import CheckResponse from "../Flight/FlightSelected/Functions/CheckResponse";
import { Flight } from "../Itinerary/network/flight";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    open: boolean,
    flight: Flight,
    onClose: () => void
}

export function CartFlightCardCancelBookingModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const end_date = useSelector((state: AppState) => state.trip.end_date);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const onConfirm = () => {
        setLoading(true);
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'DELETE',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/destroy/?product_types=${props.flight.product_type}`,
            data: {
                items: [props.flight.id]
            }
        }).then(response => {
            console.log('response.data : ', response.data);
            enqueueSnackbar(t<string>('cart-material.booking-deleted'), { variant: 'success' });
            let new_flight = [];
            new_flight.push(response.data[0][0]);
            let arr = CheckResponse(new_flight, end_date);
            dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
            props.onClose();
        }).catch(error => {
            console.log(error);
            enqueueSnackbar(t<string>('cart-material.booking-not-deleted'), { variant: 'error' });
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h5" fontWeight="bold" component="div">
                            {t<string>('cart-material.cart-construction-cancel-booking-title')}
                        </Typography>
                        <IconButton onClick={props.onClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    {
                        t<string>(
                            'cart-material.cart-construction-cancel-booking-confirmation',
                            { ref: props.flight.booking_status?.item_reference }
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>
                        {t<string>('shared.cancel')}
                    </Button>
                    <Button onClick={onConfirm}>
                        {t<string>('shared.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingBackDrop open={loading} />
        </>
    );
}
