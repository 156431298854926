import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    IconButton,
    Stack,
    Tooltip,
    TooltipProps,
    Typography,
    styled,
    tooltipClasses
} from "@mui/material";
import { Close, Edit } from "@mui/icons-material";
import {
    LocalizationProvider,
    TimeField,
    TimeValidationError
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment-timezone";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { useItineraryUpdate } from "../Itinerary/network/itineraryUpdate";
import { useShowError } from "../Utils/showError";
import { matchItineraryContentWithItinerary } from "./utils/matchItineraryContentWithItinerary";
import { itineraryToItineraryInput } from "../Itinerary/utils/itineraryToItineraryInput";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import { StepsDatesManager } from "../Itinerary/utils/stepsDatesManager";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    item: ReturnType<typeof matchItineraryContentWithItinerary>[number],
    index: number,
    disableDatesEdit?: boolean
}

export function CartConstructionContentStepperByStepEditableDates(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const itineraryList = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const [openStartDateEditTooltip, setOpenStartDateEditTooltip] = useState(false);
    const [openEndDateEditTooltip, setOpenEndDateEditTooltip] = useState(false);
    const [startDateInput, setStartDateInput] = useState<Moment | null>(null);
    const [endDateInput, setEndDateInput] = useState<Moment | null>(null);
    const [endDateError, setEndDateError] = useState<TimeValidationError | null>(null);
    const [loading, setLoading] = useState(false);
    const sortedItineraryList = useMemo(() => {
        return itineraryList.map(itineraryToItineraryInput).sort(sortItinerary);
    }, [itineraryList]);
    const itinerary = useMemo(() => {
        return itineraryList.map(itineraryToItineraryInput).filter((item) => {
            return item.step_type === 'STEP';
        }).sort(sortItinerary);
    }, [itineraryList]);
    const endDateErrorMessage = React.useMemo(() => {
        switch (endDateError) {
            case 'minTime': {
                return t<string>('cart-material.cart-construction-step-min-time-error');
            }
            default: {
                return '';
            }
        }
    }, [endDateError]);
    const showError = useShowError();
    const update = useItineraryUpdate({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(itinerary) {
            dispatch({
                type: 'ITINERARY_SET_ITINERARY_LIST',
                payload: {
                    itinerary_list: itinerary
                }
            });
            setOpenStartDateEditTooltip(false);
            setOpenEndDateEditTooltip(false);
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const [from, to] = [props.item.steps[0]?.start_date, props.item.steps[0]?.end_date];

    const onOpenStartDateTooltip = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setOpenStartDateEditTooltip(true);
    };

    const onOpenEndDateTooltip = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setOpenEndDateEditTooltip(true);
    };

    const onCloseStartDateTooltip = () => {
        setOpenStartDateEditTooltip(false);
        setStartDateInput(window.moment.utc(from));
    };

    const onCloseEndDateTooltip = () => {
        setOpenEndDateEditTooltip(false);
        setEndDateInput(window.moment.utc(to));
    };

    const onChangeStepDate = (field: 'start' | 'end', date: Moment) => {
        if (tripStartDate && tripEndDate) {
            const manager = new StepsDatesManager(tripStartDate, tripEndDate);
            let result = itinerary.map((item, index) => {
                if (
                    props.index === index &&
                    props.item.content.mode === 'by-step' &&
                    props.item.content.content.destination?.id &&
                    props.item.content.content.destination.id === item.destination?.id
                ) {
                    const startDate = window.moment.utc(item.start_date);
                    const endDate = window.moment.utc(item.end_date);
                    switch (field) {
                        case 'start':{
                            const newDate = date.toISOString();
                            return {
                                ...item,
                                start_date: newDate,
                                end_date: startDate.isAfter(endDate) ?
                                    newDate :
                                    item.end_date
                            };
                        }
                        case 'end': {
                            const newDate = date.toISOString();
                            return {
                                ...item,
                                start_date: endDate.isBefore(startDate) ?
                                    newDate : 
                                    item.start_date,
                                end_date: newDate
                            };
                        }
                    }
                }
                return item;
            });
            const preSlice = sortedItineraryList.filter((item) => {
                return item.step_type === 'START';
            }).concat(
                result.slice(0, props.index + 1)
            ).reverse().map((current, index, array) => {
                const prev = array[index - 1];

                if (prev) {
                    const result = manager.recomputeDatesInReverse(current, prev);
                    return result[0];
                }

                return current;
            }).reverse();
            const postSlice = result.slice(props.index).concat(
                sortedItineraryList.filter((item) => {
                    return item.step_type === 'END';
                })
            ).map((current, index, array) => {
                const prev = array[index - 1];

                if (prev) {
                    const result = manager.recomputeDates(prev, current);
                    return result[1];
                }

                return current;
            });
            update(
                itineraryList,
                preSlice.concat(postSlice.slice(1))
            );
        }
    };

    useEffect(() => {
        setStartDateInput(window.moment.utc(from));
        setEndDateInput(window.moment.utc(to));
    }, [from, to]);

    return (
        <Box sx={{ display: 'inline-block', margin: 'auto' }}>
            <Stack
                direction="row"
                alignItems="center"
                spacing={0.5}
                flexWrap="nowrap"
            >
                <DatesEditTooltip
                    open={openStartDateEditTooltip}
                    placement="top"
                    title={
                        <Box
                            sx={{ padding: 2.5, paddingTop: 1, paddingBottom: 1 }}
                            onClick={(event) => event.stopPropagation()}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ marginBottom: 1.5 }}
                            >
                                <Typography>
                                    {t<string>('cart-material.cart-construction-step-time-change')}
                                </Typography>
                                <IconButton
                                    size="small"
                                    onClick={onCloseStartDateTooltip}
                                >
                                    <Close />
                                </IconButton>
                            </Stack>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimeField
                                    value={startDateInput}
                                    onChange={(value) => setStartDateInput(value)}
                                />
                            </LocalizationProvider>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                spacing={1}
                                sx={{ marginTop: 1.5 }}
                            >
                                <Button size="small" onClick={onCloseStartDateTooltip}>
                                    {t<string>('shared.cancel')}
                                </Button>
                                <Button
                                    size="small"
                                    onClick={() => startDateInput && onChangeStepDate('start', startDateInput)}
                                >
                                    {t<string>('shared.save')}
                                </Button>
                            </Stack>
                        </Box>
                    }
                    arrow
                >
                    <Box sx={{ whiteSpace: 'nowrap' }} component="span">
                        {
                            t<string>(
                                'itinerary.step-from',
                                { date: window.moment.utc(from).format('L HH:mm') }
                            )
                        }
                    </Box>
                </DatesEditTooltip>
                {
                    !props.disableDatesEdit &&
                    props.index === 0 && itineraryList.findIndex((item) => {
                        return ['START', 'END'].includes(item.step_type);
                    }) < 0 &&
                    <IconButton
                        size="small"
                        sx={{ fontSize: 14 }}
                        onClick={onOpenStartDateTooltip}
                    >
                        <Edit fontSize="inherit" />
                    </IconButton>
                }
            </Stack>
            <Stack
                direction="row"
                alignItems="center"
                spacing={0.5}
                flexWrap="nowrap"
            >
                <DatesEditTooltip
                    open={openEndDateEditTooltip}
                    placement="top"
                    title={
                        <Box
                            sx={{ padding: 2.5, paddingTop: 1, paddingBottom: 1 }}
                            onClick={(event) => event.stopPropagation()}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ marginBottom: 1.5 }}
                            >
                                <Typography>
                                    {t<string>('cart-material.cart-construction-step-time-change')}
                                </Typography>
                                <IconButton
                                    size="small"
                                    onClick={onCloseEndDateTooltip}
                                >
                                    <Close />
                                </IconButton>
                            </Stack>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimeField
                                    value={endDateInput}
                                    minTime={
                                        window.moment.utc(from).isSame(
                                            window.moment.utc(to),
                                            'day'
                                        ) ?
                                            window.moment.utc(from) :
                                            undefined
                                    }
                                    slotProps={{
                                        textField: {
                                            helperText: endDateErrorMessage
                                        }
                                    }}
                                    onError={(error) => setEndDateError(error)}
                                    onChange={(value) => setEndDateInput(value)}
                                />
                            </LocalizationProvider>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                spacing={1}
                                sx={{ marginTop: 1.5 }}
                            >
                                <Button size="small" onClick={onCloseEndDateTooltip}>
                                    {t<string>('shared.cancel')}
                                </Button>
                                <Button
                                    size="small"
                                    onClick={() => endDateInput && onChangeStepDate('end', endDateInput)}
                                    disabled={!!endDateError}
                                >
                                    {t<string>('shared.save')}
                                </Button>
                            </Stack>
                        </Box>
                    }
                    arrow
                >
                    <Box sx={{ whiteSpace: 'nowrap' }} component="span">
                        {
                            t<string>(
                                'itinerary.step-to',
                                { date: window.moment.utc(to).format('L HH:mm') }
                            )
                        }
                    </Box>
                </DatesEditTooltip>
                {
                    !props.disableDatesEdit &&
                    <IconButton
                        size="small"
                        sx={{ fontSize: 14 }}
                        onClick={onOpenEndDateTooltip}
                    >
                        <Edit fontSize="inherit" />
                    </IconButton>
                }
            </Stack>
            <LoadingBackDrop open={loading} />
        </Box>
    );
}

const DatesEditTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    zIndex: theme.zIndex.modal - 1,
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1]
    },
    [`& .${tooltipClasses.arrow}`]: {
        "color": theme.palette.common.white,
        '&:before': {
            border: '1px solid rgba(100, 100, 100, 0.25)'
        }
    }
}));
