const initial_state = {
    itinerary_type_data: null,
    itinerary_type_details_data: null,
    itinerary_type_trip_data: null,
    dest_history: [],
    second_dest_list: null,
    manual_cart: [],
    all_hotels: [],
    hotel_cart: [],
    flight_cart: [],
    cars_cart: [],
    itinerary: [],
    guide_list: [],
    nights: 0,
    days: 0,
    ititype_is_loading: false,
    detail_is_loading: false,
    save_destination: {},
    packageType: [],
    circuitType: [],
    itiType: [],
    version: null,
    price_data: [],
    type: "",
    lowest_price: null,
    periods_results: null,
    flight_circuit: []
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.itinerary_type !== undefined) {
                Object.keys(action.payload.itinerary_type).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.itinerary_type[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
           new_state = initial_state;
           return new_state;
        }
        case "ITINERARY_TYPE_SET_ITINERARY_TYPE_DATA": {
            new_state.itinerary_type_data = action.payload.itinerary_type_data;
            return new_state;
        }
        case "ITINERARY_TYPE_SET_ITINERARY_TRIP_DATA": {
            return {...state, itinerary_type_trip_data: action.payload}
        }
        case "ITINERARY_TYPE_SET_ITINERARY_TYPE_DETAILS_DATA": {
            new_state.itinerary_type_details_data = action.payload.itinerary_type_details_data;
            new_state.days = action.payload.days;
            new_state.nights = action.payload.nights;
            return new_state;
        }

        case "ITINERARY_TYPE_SET_ITINERARY_TYPE_DETAILS_DATA_ONLY": {
            new_state.itinerary_type_details_data = action.payload.itinerary_type_details_data;
            return new_state;
        }
        case "ITINERARY_TYPE_SET_ITINERARY_TYPE_DETAILS_DATA_VERSION": {
            new_state.itinerary_type_details_data = action.payload.itinerary_type_details_data;
            new_state.version = action.payload.version;
            return new_state;
        }
        case "ITINERARY_TYPE_SET_MANUAL_CART": {
            new_state.manual_cart = action.payload.manual_cart;
            return new_state;
        }
        case "ITINERARY_TYPE_SET_CARS_CART": {
            new_state.cars_cart = action.payload.cars_cart;
            return new_state;
        }
        case "ITINERARY_TYPE_SET_FLIGHT_CART": {
            new_state.flight_cart = action.payload.flight_cart;
            return new_state;
        }
        case "ITINERARY_TYPE_SET_HOTEL_CART": {
            new_state.hotel_cart = action.payload.hotel_cart;
            return new_state;
        }
        case "ITINERARY_TYPE_SET_ITINERARY": {
            new_state.itinerary = action.payload.itinerary;
            return new_state;
        }

        case "ITINERARY_TYPE_SET_HISTORY_DEST": {
            new_state.dest_history = action.payload.dest_history;
            return new_state;
        }
        case "ITINERARY_TYPE_ADD_HISTORY_DEST": {
            new_state.dest_history = [].concat(new_state.dest_history, action.payload.new_dest);
            return new_state;
        } 
        case "ITINERARY_TYPE_SET_SECOND_DEST_LIST": {
            new_state.second_dest_list = action.payload.second_dest_list;
            return new_state;
        }
        case "ITINERARY_TYPE_SET_GUIDE_LIST": {
            new_state.guide_list = action.payload.guide_list;
            return new_state;
        }
        case "ITINERARY_TYPE_SET_ITINERARY_TYPE_DETAILS_LOADING": {
            new_state.detail_is_loading = action.payload.detail_is_loading;
            return new_state;
        }
        case "ITINERARY_TYPE_SET_ITINERARY_TYPE_LOADING": {
            new_state.ititype_is_loading = action.payload.ititype_is_loading;
            return new_state;
        }
        case "ITINERARY_TYPE_REMOVE_X_HISTORY_DEST": {
            let new_history = new_state.dest_history.slice();

            for (let i = 0; i < action.payload.index; i++) {
                new_history.pop();
            }
            new_state.dest_history = new_history;
            // new_state.itinerary_type_data = action.payload.itinerary_type_data;
            return new_state;
        }
        case "ITINERARY_SAVE_DESTINATION": {
            let save_destination = Object.assign({}, new_state.save_destination);
            save_destination[action.payload.destination_id] = action.payload.new_data;
            new_state.save_destination = save_destination;
            return new_state;
        }
        case "ITINERARY_SET_PACKAGES": {
            new_state.packageType = action.payload.packageType;
            return new_state;
        }
        case "ITINERARY_SET_CIRCUITS": {
            new_state.circuitType = action.payload.circuitType;
            return new_state;
        }
        case "ITINERARY_SET_ITI_TYPE": {
            new_state.itiType = action.payload.itiType;
            return new_state;
        }
        case "ITINERARY_SET_PRICE_DATA": {
            return {...state, price_data: action.payload};
        }
        case "ITINERARY_SET_TYPE": {
            return {...state, type: action.payload};
        }
        case "ITINERARY_SET_LOWEST_PRICE": {
            return {...state, lowest_price: action.payload};
        }
        case "ITINERARY_SET_ALL_HOTELS": {
            return {...state, all_hotels: action.payload};
        }
        case "ITINERARY_TYPE_SET_PERIODS": {
            return {...state, periods_results: action.payload};
        }
        case "ITINERARY_TYPE_SET_FLIGHT_CIRCUIT": {
            return {...state, flight_circuit: action.payload};
        }
   }
   
    return new_state;
}
