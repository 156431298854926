import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Divider,
    Grid,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import DoorIcon from "./DoorIcon.js";
import GearIcon from "./GearIcon.js";
import { AcUnitOutlined, CheckCircleOutline, DriveEtaOutlined, PermIdentity, PlaceOutlined, WorkOutline } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import { makeStyles } from "@material-ui/core";
import { CartProductCardProps } from "../CartMaterial/CartProductCard";
import { CartProductCardProviderLogo } from "../CartMaterial/CartProductCardProviderLogo";
import { useGetPrice } from "../CartMaterial/utils/getPrice";
import { CarCart } from "../Itinerary/objects/carCart";
import { AppState } from "../../Reducers/Reducers";
import { CarProduct } from "./CarProduct";
import { CarDetails } from "./CarDetails";
import { CartProductIconText } from "../CartMaterial/CartProductIconText";
import clsx from "clsx";


type Props = {
    index: number,
    car: CarCart,
    getContractInfo: any,
    results: any
} & Pick<CartProductCardProps, 'alwaysOpen'>

export function RenderCars(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
    const [providerErrors, setProviderErrors] = useState<{
        provider: boolean,
        subprovider: boolean
    }>({ provider: false, subprovider: false });
    const provider = useMemo(() => {
        return providers.concat(manualProviders).find((item) => {
            return item.provider.id === props.car.provider_id;
        })?.provider;
    }, [props.car, providers, manualProviders]);
    const classes = useStyles();
    const getPrice = useGetPrice();
    const totalCost = getPrice(props.car.station_prices[0].prices);
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    const picture = props.car.vehicle_info?.pict_url;
    const name = [
        props.car.vehicle_info?.name,
        quotation_code === 'marcovasco' &&
        `(${ props.car.vehicle_info?.vehicle_code })`
    ].filter((item) => item).join(' ');
    return (
        <>
            <CarProduct
                type="car"
                index={props.index}
                item={props.car}
                getContractInfo={props.getContractInfo}
                results={props.results}
                mainContent={
                    <Stack spacing={1} sx={{width: '100%'}}>
                        <CartProductIconText
                            size="medium"
                            fontWeight="bold"
                            text={name}
                            startIcon={DriveEtaOutlined}
                        />
                        <CartProductIconText
                            text={
                                <>
                                    { t<string>("cars.or_similiar") + " | " + t<string>("global.category") + t<string>("cars.categories." + ((props.car.vehicle_info.vehicle_category).toLowerCase()).replace(" ", "_")) + " (" + props.car.vehicle_info.code + ")" }
                                </>
                            }
                        />
                        <Stack direction={'row'} spacing={1} sx={{marginTop: '0px !important'}}>
                            <div className={ classes.carCardInfo } style={{ marginLeft: '-2px' }}>
                                <Tooltip title={t<string>("global.luggages")} placement={ "bottom" }>
                                    <WorkOutline fontSize={"small"} style={{ marginRight: 2, verticalAlign: 'sub', opacity: '54%' }}/>
                                </Tooltip>
                                <span className={ clsx({ [classes.ftGray]: true, [classes.font14]: true })}>{props.car.vehicle_info.baggage}</span>
                            </div>
                            <div className={ classes.carCardInfo }>
                                <Tooltip title={t<string>("flight_groups.passengers")} placement={ "bottom" }>
                                    <PermIdentity fontSize={"small"} style={{ marginRight: 2, verticalAlign: 'sub', opacity: '54%' }}/>
                                </Tooltip>
                                <span className={ clsx({ [classes.ftGray]: true, [classes.font14]: true })}>{props.car.vehicle_info.passenger}</span>
                            </div>
                            <div className={ classes.carCardInfoFlex }>
                                <Tooltip title={t<string>("tooltip.door_nbr")} placement={ "bottom" }>
                                    <div>
                                        <DoorIcon fontSize={"small"} style={{ marginRight: 2, verticalAlign: 'sub', opacity: '54%' }}/>
                                    </div>
                                </Tooltip>
                                <span className={ clsx({ [classes.ftGray]: true, [classes.font14]: true })} style={{ paddingTop: '3px', paddingLeft: '4px' }}>{props.car.vehicle_info.door_count}</span>
                            </div>
                            {
                                props.car.vehicle_info.air_condition && (
                                    <div className={ classes.carCardInfo }>
                                        <Tooltip title={t<string>("accommodation.equipment.air_conditioning")} placement={ "bottom" }>
                                            <AcUnitOutlined fontSize={"small"} style={{ marginRight: 2, verticalAlign: 'sub', opacity: '54%' }}/>
                                        </Tooltip>
                                    </div>
                                )
                            }
                            <div className={ classes.carCardInfoFlex }>
                                <Tooltip title={t<string>("cars.transmission.transmission")} placement={ "bottom" }>
                                    <div>
                                        <GearIcon fontSize={"small"} style={{ marginRight: 2, verticalAlign: 'sub', opacity: '54%' }}/>
                                    </div>
                                </Tooltip>
                                <span className={ clsx({ [classes.ftGray]: true, [classes.font14]: true })} style={{ paddingTop: '3px', paddingLeft: '4px', textTransform: 'capitalize' }}>{t<string>("cars.transmission." + (props.car.vehicle_info.transmission).toLowerCase())}</span>
                            </div>
                        </Stack>
                        {
                            props.car.vehicle_info.minimal_age && 
                                <CartProductIconText
                                    text={
                                        <>
                                            { t<string>("cars.minimal_age") + props.car.vehicle_info.minimal_age }
                                        </>
                                    }
                                />
                        }
                        {
                            props.car.vehicle_info.licence_time &&
                                <CartProductIconText
                                    text={
                                        <>
                                            { t<string>("cars.licence_time") + props.car.vehicle_info.licence_time }
                                        </>
                                    }
                                />
                        }
                        {
                            props.car.contract_type !== null && props.car.contract_type !== "" &&
                                <CartProductIconText
                                    text={
                                        <>
                                            { t<string>("global.contract") + " : " + props.car.contract_type }
                                        </>
                                    }
                                />
                        }
                    </Stack>
                }
                sideContent={
                    <Stack spacing={1} alignItems="center">
                        <Typography variant="caption" sx={{ opacity: 0.6 }}>
                            {t<string>('cart-material.cart-construction-amount')}
                        </Typography>
                        <Typography fontWeight="bold" textAlign="center">
                            {
                                new Intl.NumberFormat(
                                    language,
                                    {
                                        style: 'currency', 
                                        currency: totalCost.currency?.iso_code ?? 'EUR'
                                    }
                                ).format(totalCost.cost)
                            }
                        </Typography>
                        <Stack direction="row">
                            {
                                props.car.subprovider_logo && props.car.provider_id !== props.car.subprovider_id &&
                                <>
                                    {
                                        !providerErrors.subprovider &&
                                        <Box
                                            src={props.car.subprovider_logo}
                                            component="img"
                                            sx={{
                                                width: 50,
                                                height: 25,
                                                objectFit: 'contain'
                                            }}
                                            onError={() => {
                                                setProviderErrors((state) => ({
                                                    ...state,
                                                    subprovider: true
                                                }));
                                            }}
                                        />
                                    }
                                    {
                                        providerErrors.subprovider &&
                                        <Tooltip title={props.car.subprovider}>
                                            <Typography
                                                sx={{
                                                    width: 75,
                                                    maxWidth: 75,
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}>
                                                {props.car.subprovider}
                                            </Typography>
                                        </Tooltip>
                                    }
                                </>
                            }
                            {
                                !props.car.subprovider_logo && props.car.provider_id !== props.car.subprovider_id &&
                                <Tooltip title={props.car.subprovider}>
                                    <Typography
                                        sx={{
                                            width: 75,
                                            maxWidth: 75,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>
                                        {props.car.subprovider}
                                    </Typography>
                                </Tooltip>
                            }
                            <CartProductCardProviderLogo
                                logoUrl={
                                    provider?.logo?.url ??
                                    provider?.logo?.thumbnail_big ??
                                    provider?.logo?.thumbnail_medium ??
                                    provider?.logo?.thumbnail_little ??
                                    null
                                }
                                height={25}
                                width={50}
                                name={provider?.name ?? null}
                            />
                        </Stack>
                    </Stack>
                }
                footer={
                    <Stack>
                        {
                            props.car.vehicle_services.length !== 0 &&
                            <Grid container direction={'row'} spacing={1} sx={{marginLeft: '-8px !important', marginTop: '10px !important'}}>
                                <>
                                    {
                                        props.car.vehicle_services.map((service:any, index_service:number) => {
                                            return <Grid item key={ `car-service-${index_service}` }><CheckCircleOutline className={ classes.service }/>{ service.title }</Grid>;
                                        })
                                    }
                                </>
                            </Grid>
                        }
                    </Stack>
                }
                picture={picture}
                alwaysOpen={props.alwaysOpen}
                showEntirePictures
            >
                <CarDetails car_index={props.index} car={props.car} />
            </CarProduct>
        </>
    );
}

const useStyles = makeStyles(() => ({
    service: {
        float: 'left',
        marginRight: 4
    },
    lowerCase: {
        textTransform: "capitalize"
    },
    carCardInfo: {
        display: 'inline-block',
        // marginRight: 11
    },
    carCardInfoFlex: {
        display: 'inline-flex',
        marginRight: 11
    },
    ftGray: {
        opacity: '54%'
    },
    font14: {
        fontSize: 14
    }
}));
