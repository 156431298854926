// Dependencies
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// Core
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles({
    select: {
        width: '100%'
    }
});

const CartManualProductFormManualFlight = ({flightPasteError}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const flight_paste = useSelector(state => state.cart.manual_product_flight_paste);
    const baggages = useSelector(state => state.cart.manual_product_baggages);
    const flight_taxes = useSelector(state => state.cart.manual_product_flight_taxes);
    const onFlightPaste = event => {
        dispatch({type: 'CART_SET_MANUAL_PRODUCT_FLIGHT_PASTE', payload: event.target.value});
    };
    const onBaggageChange = event => {
        dispatch({type: 'CART_SET_MANUAL_PRODUCT_BAGGAGES', payload: event.target.value});
    };
    const onFlightTaxes = event => {
        dispatch({type: 'CART_SET_MANUAL_PRODUCT_FLIGHT_TAXES', payload: event.target.value});
    };
    return (
        <Fragment>
            <Grid item xs={ 12 }>
                <FormControl fullWidth variant={ 'outlined' } error={ flightPasteError }>
                    <InputLabel htmlFor={ 'custom-product-flight-paste' }>{ t('cart-material.manual-product-flight-paste') }</InputLabel>
                    <OutlinedInput
                        id={ 'custom-product-flight-paste' }
                        type={ 'text' }
                        onChange={ onFlightPaste }
                        rows={ 10 }
                        multiline
                        label={ t('cart-material.manual-product-flight-paste') }
                        value={ flight_paste === null ? '' : flight_paste }
                    />
                    { flightPasteError && <FormHelperText>{ t('cart-material.manual-product-no-flight-paste') }</FormHelperText> }
                </FormControl>
            </Grid>
            <Grid item xs={ 12 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <InputLabel id={ 'manual-product-baggages-label' }>{ t('cart-material.manual-product-baggages') }</InputLabel>
                    <Select className={ classes.select } labelId={ 'manual-product-baggages-label' } label={ t('cart-material.manual-product-baggages') } value={ baggages } onChange={ onBaggageChange }>
                        <MenuItem value={ 0 }>0</MenuItem>
                        <MenuItem value={ 1 }>1</MenuItem>
                        <MenuItem value={ 2 }>2</MenuItem>
                        <MenuItem value={ 3 }>3</MenuItem>
                        <MenuItem value={ 4 }>4</MenuItem>
                        <MenuItem value={ 5 }>5</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={ 12 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <InputLabel htmlFor={ 'manual-product-flight-taxes' }>{ t('cart-material.manual-product-flight-taxes') }</InputLabel>
                    <OutlinedInput
                        id={ 'manual-product-flight-taxes' }
                        type={ 'number' }
                        onChange={ onFlightTaxes }
                        label={ t('cart-material.manual-product-flight-taxes') }
                        value={ flight_taxes !== null ? flight_taxes : '' }
                    />
                </FormControl>
            </Grid>
        </Fragment>
    );
};

export default CartManualProductFormManualFlight;