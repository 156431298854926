import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ListItem, styled } from "@mui/material";
import { OpenWith } from "@mui/icons-material";
import { isString } from "lodash";
import ColorHash from 'color-hash';
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";

type DragndropType = 'source' | 'target'

type CircuitItineraryDraggableStepProps = {
    type: DragndropType,
    index: number,
    input: ItineraryContentStep,
    onChangeInput: (
        index: number,
        input: ItineraryContentStep
    ) => void,
    onClick?: () => void,
    children: any
}

const colorHash = new ColorHash();

export function CartConstructionItineraryContentDraggableStep(props: CircuitItineraryDraggableStepProps): JSX.Element {
    const [droppedColor, setDroppedColor] = useState<string | null>(null);
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, drag] = useDrag(() => ({
        canDrag(): boolean {
            return props.type === 'source';
        },
        type: 'circuit-duplicate',
        item: props.input,
        end(item, monitor) {
            if (monitor.didDrop()) {
                setDroppedColor(
                    colorHash.hex(
                        (
                            item.mode === 'by-day' ?
                                item.content.day.join(',') :
                                'step'
                        ) +
                        (
                            item.mode === 'by-day' ?
                                item.content.destinations?.map((item) => item.id).join('|') :
                                item.content.destination?.id
                        ) +
                        item.content.title +
                        item.content.long_description
                    )
                );
            }
        }
    }), [props.type, props.index, props.input]);
    const [dropCollected, drop] = useDrop<
        ItineraryContentStep,
        unknown,
        {isOver: boolean, canDrop: boolean}
    >(() => ({
        canDrop(): boolean {
            return props.type === 'target';
        },
        accept: 'circuit-duplicate',
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        drop(input): void {
            props.onChangeInput(props.index, input);
            setDroppedColor(
                colorHash.hex(
                    (
                        input.mode === 'by-day' ?
                            input.content.day.join(',') :
                            'step'
                    ) +
                    (
                        input.mode === 'by-day' ?
                            input.content.destinations?.map((item) => item.id).join('|') :
                            input.content.destination?.id
                    ) +
                    input.content.title +
                    input.content.long_description
                )
            );
        }
    }), [props.type, props.input, props.onChangeInput]);

    return (
        <StepItem
            ref={props.type === 'source' ? drag : drop}
            type={props.type}
            isOver={dropCollected.isOver}
            canDrop={dropCollected.canDrop}
            droppedColor={droppedColor}
            secondaryAction={
                props.type === 'source' ?
                    <OpenWith /> :
                    undefined
            }
        >
            {props.children}
        </StepItem>
    );
}

const StepItem = styled(
    ListItem,
    {
        shouldForwardProp(propName) {
            return !isString(propName) || ![
                'isOver',
                'canDrop',
                'type',
                'color'
            ].includes(propName);
        }
    }
)<{
    type: DragndropType,
    canDrop: boolean,
    isOver: boolean,
    droppedColor: string | null
}>((props) => ({
    "width": '90%',
    "margin": 'auto',
    "cursor": props.type === 'source' ? 'move' : 'inherit',
    "border": props.isOver ?
        '1px solid rgba(0, 217, 0, 0.2)' :
        props.canDrop ?
            '1px solid rgba(224, 146, 11, 0.5)' :
            '1px solid #cdcdcd',
    "backgroundColor": props.droppedColor ?? '#fff',
    "color": props.theme.palette.getContrastText(props.droppedColor ?? '#fff'),
    '& *': {
        color: props.theme.palette.getContrastText(props.droppedColor ?? '#fff')
    }
}));
