export default (t, action_id) => {
    let label = "";
    switch(action_id){
        case 0:
            label = t("menu.history.action_label.file_creation");
            return {"label": label, "id": action_id}
        case 1:
            label = t("menu.history.action_label.file_duplication");
            return {"label": label, "id": action_id}
        case 2:
            label = t("menu.history.action_label.create_new_version");
            return {"label": label, "id": action_id}
        case 3:
            label = t("menu.history.action_label.activate_version");
            return {"label": label, "id": action_id}
        case 4:
            label = t("menu.history.action_label.rename_trip");
            return {"label": label, "id": action_id}
        case 5:
            label = t("menu.history.action_label.change_status");
            return {"label": label, "id": action_id}
        case 6:
            label = t("menu.history.action_label.rename_version");
            return {"label": label, "id": action_id}
        case 7:
            label = t("menu.history.action_label.change_desti");
            return {"label": label, "id": action_id}
        case 8:
            label = t("menu.history.action_label.change_date");
            return {"label": label, "id": action_id}
        case 9:
            label = t("menu.history.action_label.change_budget");
            return {"label": label, "id": action_id}
        case 10:
            label = t("menu.history.action_label.change_quotation");
            return {"label": label, "id": action_id}
        case 11:
            label = t("menu.history.action_label.flight_prices");
            return {"label": label, "id": action_id}
        case 12:
            label = t("menu.history.action_label.terrestrial_prices");
            return {"label": label, "id": action_id}
        case 13:
            label = t("menu.history.action_label.add_traveler");
            return {"label": label, "id": action_id}
        case 14:
            if (JSON.parse(localStorage.getItem("config")).quotation_code !== "marcovasco"){
                label = t("menu.history.action_label.change_due_date");
            }
            return {"label": label, "id": action_id}
        case 15:
            label = t("menu.history.action_label.client_website");
            return {"label": label, "id": action_id}
        case 16:
            label = t("menu.history.action_label.mcto");
            return {"label": label, "id": action_id}
        case 17:
            label = t("menu.history.action_label.pgi");
            return {"label": label, "id": action_id}
        case 18:
            label = t("menu.history.action_label.contact_traveler");
            return {"label": label, "id": action_id}
        case 19:
            label = t("menu.history.action_label.add_payment");
            return {"label": label, "id": action_id}
        case 20:
            label = t("menu.history.action_label.remove_step");
            return {"label": label, "id": action_id}
        case 21:
            label = t("menu.history.action_label.add_step");
            return {"label": label, "id": action_id}
        case 22:
            label = t("menu.history.action_label.change_start");
            return {"label": label, "id": action_id}
        case 23:
            label = t("menu.history.action_label.change_return");
            return {"label": label, "id": action_id}
        case 24:
            label = t("menu.history.action_label.step_arrival");
            return {"label": label, "id": action_id}
        case 25:
            label = t("menu.history.action_label.step_departure");
            return {"label": label, "id": action_id}
        case 26:
            label = t("menu.history.action_label.step_transport");
            return {"label": label, "id": action_id}
        case 27:
            label = t("menu.history.action_label.reorder_iti");
            return {"label": label, "id": action_id}
        case 28:
            label = t("menu.history.action_label.reverse_iti");
            return {"label": label, "id": action_id}
        case 30:
            label = t("menu.history.action_label.edit_traveler");
            return {"label": label, "id": action_id}
        case 31:
            label = t("menu.history.action_label.change_comps");
            return {"label": label, "id": action_id}
        case 32:
            label = t("menu.history.action_label.lead_traveler");
            return {"label": label, "id": action_id}
        case 40:
            label = t("menu.history.action_label.add_product");
            return {"label": label, "id": action_id}
        case 41:
            label = t("menu.history.action_label.del_product");
            return {"label": label, "id": action_id}
        case 42:
            label = t("menu.history.action_label.info_product");
            return {"label": label, "id": action_id}
        case 43:
            label = t("menu.history.action_label.margin");
            return {"label": label, "id": action_id}
        case 44:
            label = t("menu.history.action_label.change_driver");
            return {"label": label, "id": action_id}
        case 45:
            label = t("menu.history.action_label.transfer_transportation");
            return {"label": label, "id": action_id}
        case 46:
            label = t("menu.history.action_label.booking_status");
            return {"label": label, "id": action_id}
        case 47:
            label = t("menu.history.action_label.booking_ref");
            return {"label": label, "id": action_id}
        case 48:
            label = t("menu.history.action_label.book");
            return {"label": label, "id": action_id}
        case 49:
            label = t("menu.history.action_label.cancel");
            return {"label": label, "id": action_id}
        case 50:
            label = t("menu.history.action_label.refresh_cart");
            return {"label": label, "id": action_id}
        case 51:
            label = t("menu.history.action_label.is_optional");
            return {"label": label, "id": action_id}
        case 52:
            label = t("menu.history.action_label.issue_flight");
            return {"label": label, "id": action_id}
        case 53:
            label = t("menu.history.action_label.check_status");
            return {"label": label, "id": action_id}
        case 60:
            label = t("menu.history.action_label.upload_doc");
            return {"label": label, "id": action_id}
        case 61:
            label = t("menu.history.action_label.download_doc");
            return {"label": label, "id": action_id}
        case 70:
            label = t("menu.history.action_label.delete_payment");
            return {"label": label, "id": action_id}
        case 80:
            label = t("menu.history.action_label.edit_purchase");
            return {"label": label, "id": action_id}
        case 81:
            label = t("menu.history.action_label.create_invoice");
            return {"label": label, "id": action_id}
        case 82:
            label = t("menu.history.action_label.update_invoice");
            return {"label": label, "id": action_id}
        case 83:
            label = t("menu.history.action_label.reprice_flight");
            return {"label": label, "id": action_id}
        case 84:
            label = t("menu.history.action_label.send_custom");
            return {"label": label, "id": action_id}
        case 85:
            label = t("menu.history.action_label.send_contract");
            return {"label": label, "id": action_id}
        case 86:
            label = t("menu.history.action_label.add_annex_product");
            return {"label": label, "id": action_id}
        case 87:
            label = t("menu.history.action_label.export_doc");
            return {"label": label, "id": action_id}
        case 88:
            label = t("menu.history.action_label.edit_annulation");
            return {"label": label, "id": action_id}
        case 89:
            label = t("menu.history.action_label.trip_agreed_price_change");
            return {"label": label, "id": action_id}
        case 90:
            label = t("menu.history.action_label.mtrip");
            return {"label": label, "id": action_id}
    }
}