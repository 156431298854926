import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Skeleton from '@material-ui/lab/Skeleton';

import AcUnitIcon from '@material-ui/icons/AcUnit';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import AddIcon from '@material-ui/icons/Add';
import green from "@material-ui/core/colors/green";

import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import CustomMenuFilters from "./CustomMenuFilters";
import ProviderListFilters from "./ProviderListFilters";
import CategoryListFilters from "./CategoryListFilters";
import DisplayAllFilters from "./DisplayAllFilters";

import GetProvider from "../Common/Functions/GetProvider.js";
import GetMinPrice from "../Cars/Functions/GetMinPrice";

const useStyles = makeStyles((theme) => ({
    inline: {
	    display: "inline-block"
    },
    container: {
	    padding: 16
    },
    settingsButton: {
	    fontStyle: "italic",
	    textTransform: "inherit"
    },
    filterButton: {
	    borderRadius: 40,
	    padding: 8,
	    height: 40,
	    fontSize: 10
    },
    filterButtonActive: {
	    border: '2px solid black'
    },
    sortButton: {
	    padding: 8
    },
    active: {
	    color: green[500],
	    border: '2px solid black'
    },
    alignCenter: {
	    textAlign: "center"
    },
    marginTop: {
        marginTop: 10
    },
    marginRight15: {
	    marginRight: 15
    },
    marginLeft: {
	    marginLeft: 4
    },
    settingIcon: {
	    position: "absolute"
    },
    noResults: {
	    width: "100%",
	    padding: "50px 0",
	    textAlign: "center"
    },
    marginBottom: {
	    marginBottom: 8
    },
    paginationItemActive: {
	    backgroundColor: "black",
    },
    resetFilter: {
        cursor: 'pointer',
        textDecoration: 'underline'
    }
}));
const RenderFilters = ({setCategoryID, setProviderID, setDisplayCars, setPageList, getDisplayCars}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const dispatch = useDispatch();
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const [clim, setClim] = useState(false);
    const [show_transmission, setShowTransmission] = useState(false);
    const [passenger1, setPassenger1] = useState(false);
    const [passenger2, setPassenger2] = useState(false);
    const [manual, setManual] = useState(false);
    const [automatic, setAutomatic] = useState(false);
    const [two_door, setTwoDoor] = useState(false);
    const [more_door, setMoreDoor] = useState(false);
    const [show_provider, setShowProvider] = useState(false);
    const [show_category, setShowCategory] = useState(false);
    const [provider_list, setProviderFilters] = useState([]);
    const [isUpdate, setUpdate] = useState(false);
    const [show_all_filters, setShowAll] = useState(false);

    const cars_results = useSelector(store => store.cars_search.cars_results);
    const cars_categories = useSelector(store => store.cars_search.cars_categories);
    const all_cars = useSelector(store => store.cars_search.all_cars);
    const user = useSelector(store => store.user.user);

    const applyFilters = () => {
        let copy_cars_results = [];
        let count_cars = 0;
        cars_results.map((provider) => {
            let copy_vehicles = Object.assign({}, provider.vehicles);
            Object.keys(copy_vehicles).map((key) => {
                let cars = copy_vehicles[key].slice();
                let copy_cars = [];
                cars.map((car) => {
                    let copy_car = Object.assign({}, car);
                    copy_car.to_render = false;
                    let vehicle_info = copy_car.vehicle_info;
                    let vehicle_category = vehicle_info.vehicle_category.toLowerCase();
                    let isDisplayed = false;
                    let category_display = false;
                    cars_categories.map((category) => {
                        if (category.name === vehicle_category) {
                            category_display = category.to_render;
                        }
                    });
                    provider_list.map((tmp_provider) => {
					    if (provider.provider_name === tmp_provider.provider_name && provider.subprovider_name === tmp_provider.subprovider_name && tmp_provider.checked) {
					        isDisplayed = true;
					    }
                    });
                    if ( (((!two_door && !more_door) || (two_door && more_door)) || (two_door && (vehicle_info.door_count === "2-4" || vehicle_info.door_count === "2" || vehicle_info.door_count === "3")) || (more_door && parseInt(vehicle_info.door_count) <= 4)) && (!clim || (clim && vehicle_info.air_condition)) && (((!manual && !automatic) || (manual && automatic)) || (manual && vehicle_info.transmission === "Manual") || (automatic && vehicle_info.transmission === "Automatic")) && (((passenger1 && passenger2) || !passenger1 && !passenger2) || (passenger1 && vehicle_info.passenger <= 5) || (passenger2 && vehicle_info.passenger > 5)) && isDisplayed && category_display) {
                        copy_car.to_render = true;
                        count_cars++;
                    }
                    car = copy_car;
                    copy_cars.push(car);
                });
                copy_vehicles[key] = copy_cars;
            });
            provider.vehicles = copy_vehicles;
            provider_list.map((tmp_provider) => {
			    if (provider.provider_name === tmp_provider.provider_name && provider.subprovider_name === tmp_provider.subprovider_name) {
			        provider.to_render = tmp_provider.checked
			    }
            });
            copy_cars_results.push(provider);
        });
        if (matches) {
            setCategoryID(null);
            setDisplayCars([]);
            setProviderID(null);
            setPageList([]);
        }
        dispatch({
            type: "CARS_ADD_TOTAL_CARS",
            payload: {
			    total_cars: count_cars,
            }
        });
        dispatch({
            type: "MATERIAL_CARS_SET_RESULTS",
        	payload: {
            	cars_results: copy_cars_results
        	}
        });
    }
    const checkMinPrice = (cars_categories) => {
        let tmp_categories = cars_categories.slice();
        let copy_category = [];
        tmp_categories.map((category) => {
            let to_render = false;
            let tmp_category = Object.assign({}, category);
            cars_results.map((provider) => {
                if (provider.vehicles[tmp_category.name] !== undefined) {
                    let car =  provider.vehicles[tmp_category.name].find((el) => {
                        return el.to_render;
                    });
                    if (car !== undefined && car !== null && tmp_category.to_render) {
                        to_render = true;
                    }
                }
            });
            tmp_category.to_render = to_render;
            tmp_category.min_price = Object.assign({}, GetMinPrice(cars_results, tmp_category.name, quotation_code));
            copy_category.push(tmp_category);
        });
        dispatch({
            type: "CARS_SET_CATEGORIES",
            payload: {
			    categories: copy_category,
            }
        });
    }
    useEffect(() => {
        let tmp_provider_list = [];
        let count_cars = 0;
        cars_results.map((car) => {
            tmp_provider_list.push({
                checked: true,
                provider_name: car.provider_name,
                subprovider_name: car.subprovider_name
            });
            let copy_vehicles = Object.assign({}, car.vehicles);
            Object.keys(copy_vehicles).map((key) => {
                copy_vehicles[key].map((vehicles) => {
                    if (vehicles.station_prices !== null) {
                        count_cars++;
                    }
                });
            });
        });
        setProviderFilters(tmp_provider_list);
        dispatch({
            type: "CARS_ADD_TOTAL_CARS",
            payload: {
			    total_cars: count_cars,
            }
        });	
    }, [all_cars]);

    useEffect(() => {
        if (isUpdate) {
            applyFilters();
            checkMinPrice(cars_categories);
            setUpdate(false);
            if (!matches) {
                getDisplayCars()
            }
        }
    }, [isUpdate]);
    const resetFilter = () => {
        let tmp_provider_list = [];
        let tmp_categories = cars_categories.slice();
        let copy_category = [];
        let tmp_cars_results = [...cars_results];
        tmp_categories.map((category) => {
            let tmp_category = Object.assign({}, category);
            tmp_category.to_render = true;
            copy_category.push(tmp_category);
        });
        cars_results.map((car) => {
            tmp_provider_list.push({
                checked: true,
                provider_name: car.provider_name,
                subprovider_name: car.subprovider_name
            });
        });
        tmp_cars_results.forEach((provider) => {
            Object.keys(provider.vehicles).forEach((key) => {
                provider.vehicles[key].forEach((vehicle) => {
                    vehicle.to_render = true;
                });
            });
        });
        dispatch({
            type: "MATERIAL_CARS_SET_RESULTS",
        	payload: {
            	cars_results: tmp_cars_results
        	}
        });
        setProviderFilters(tmp_provider_list);
        setPassenger1(false);
        setPassenger2(false);
        setManual(false);
        setAutomatic(false);
        setTwoDoor(false);
        setMoreDoor(false);
        dispatch({type: "CARS_SET_CATEGORIES", payload: {
		    categories: copy_category,
        }})
    };
    const checkAllCategories = () => {
        let tmp_categories = cars_categories.slice();
        let copy_category = [];
        tmp_categories.map((category) => {
            let tmp_category = Object.assign({}, category);
            tmp_category.to_render = true;
            copy_category.push(tmp_category);
        });
        dispatch({type: "CARS_SET_CATEGORIES", payload: {
		    categories: copy_category,
        }})
    }
    let passengers = [
        {
            name: '1-5',
            checked: passenger1,
            func: function() {
                checkAllCategories();
                setPassenger1(!passenger1);
                if (matches) {
                    setUpdate(true);
                }
            }
        },
        {
            name: '> 5',
            checked: passenger2,
            func: function() {
                checkAllCategories();
                setPassenger2(!passenger2);
                if (matches) {
                    setUpdate(true);
                }
            }
        }
    ];
    let transmission = [
        {
            name: t("cars.transmission.manual"),
            checked: manual,
            func: function() {
                setManual(!manual);
                if (matches) {
                    setUpdate(true);
                }
            }
        },
        {
            name: t("cars.transmission.automatic"),
            checked: automatic,
            func: function() {
                setAutomatic(!automatic);
                if (matches) {
                    setUpdate(true);
                }
            }
        }
    ];
    let door = [
        {
            name: '2-4',
            checked: two_door,
            func: function() {
                setTwoDoor(!two_door);
                if (matches) {
                    setUpdate(true);
                }
            }
        },
        {
            name: '4+',
            checked: more_door,
            func: function() {
                setMoreDoor(!more_door);
                if (matches) {
                    setUpdate(true);
                }
            }
        }
    ];
    let count_provider = 0;
    provider_list.map((provider) => {
        if (provider.checked) {
            count_provider++;
        }
    });
    let count_category = 0;
    cars_categories.map((category) => {
        if (category.to_render) {
            count_category++;
        }
    });
    return (
        <Fragment>
            {
                matches && (
                    <Grid container item xs={12} >
                        <Grid item className={classes.marginRight15}>
		            	<Button className={ `${classes.filterButton}${clim ? ` ${classes.active}` : ""}` } variant={ "outlined" } startIcon={ <AcUnitIcon fontSize={ "small" }/> } onClick={() => {
		            		setUpdate(true);
		            		setClim(!clim);
		            	}}>{ "climatisation" }</Button>
		            </Grid>
		            <Grid item className={classes.marginRight15}>
		            	<CustomMenuFilters filters={passengers} type={t("placeholder.nb_passengers")} />
		            </Grid>
		            <Grid item className={classes.marginRight15}>
		            	<CustomMenuFilters filters={transmission} type={t("cars.transmission.transmission")}/>
		            </Grid>
		            <Grid item className={classes.marginRight15}>
		            	<CustomMenuFilters filters={door} type={t("cars.doors")}/>
		            </Grid>
		            <Grid item>
		            {
		            	// all_cars ? (
		                    <Button className={ clsx(classes.filterButton, classes.marginRight15, {[classes.filterButtonActive]: count_provider < provider_list.length }) } variant={ "outlined" } onClick={() => {setShowProvider(!show_provider) }}>{t("cars.renters")}{ count_provider < provider_list.length ? ` - ${count_provider}` : '' }</Button>
		            	// ) : (
		            	// 	<Skeleton animation="wave" width={100} height={35}/>
		            	// )
		            }
	                </Grid>
    	            <Grid item>
    	            {
	                    <Button className={ clsx(classes.filterButton, {[classes.filterButtonActive]: count_category < cars_categories.length }) } variant={ "outlined" } onClick={() => {setShowCategory(!show_category) }}>{t("global.categories")}{ count_category < cars_categories.length ? ` - ${count_category}` : '' }</Button>
    	            }
                        </Grid>
	                <Grid item>
	                	<Typography className={ clsx(classes.filterButton, classes.resetFilter) } variant="overline" display="block" gutterBottom onClick={() => resetFilter()}>
                	        {t("menu.trip_list.clean_filter")}
            	      	</Typography>
	                </Grid>
                    </Grid>
                )
            }
            {
                !matches && (
                    <Grid container item xs={12} >
		            <Grid item>
	                    <Button className={ clsx(classes.filterButton) } variant={ "outlined" } onClick={() => {setShowAll(true)}}>
	                    	<AddIcon/>
	                    	{t("menu.filter.filters")}
	                    </Button>
	                </Grid>
	                <Grid item>
	                	<Typography className={ clsx(classes.filterButton, classes.resetFilter) } variant="overline" display="block" gutterBottom onClick={() => resetFilter()}>
                	        {t("menu.trip_list.clean_filter")}
            	      	</Typography>
	                </Grid>
                    </Grid>
                )
            }
            {
                matches && (
                    <Fragment>
                        <ProviderListFilters open={show_provider} setShowProvider={setShowProvider} provider_list={provider_list} setProviderFilters={setProviderFilters} setUpdate={setUpdate} user={user} />
                        <CategoryListFilters open={show_category} setShowCategory={setShowCategory} cars_categories={cars_categories} setUpdate={setUpdate}/>
                    </Fragment>
                )
            }
            {
                !matches && (
                    <DisplayAllFilters open={show_all_filters} setShowAll={setShowAll} provider_list={provider_list} cars_categories={cars_categories} setUpdate={setUpdate} passengers={passengers} transmission={transmission} doors={door} setProviderFilters={setProviderFilters} user={user} resetFilter={resetFilter}/>
                )
            }
        </Fragment>
    )
};
export default React.memo(RenderFilters);