import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Autocomplete, TextField } from "@mui/material";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { Currency } from "../../Reducers/objects/currency";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    value: number | null,
    label?: string,
    onChange: (value: number | null) => void
}

export function ProviderQuotationCurrencyPicker(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const options = useSelector((state: AppState) => state.trip.currencies);

    useEffect(() => {
        const { pass_check, headers } = CheckBeforeRequest();
        if (pass_check && !options) {
            (async () => {
                const response = await axios.get<{results: Currency[]}>(
                    `${API_HREF}currencies/`,
                    {
                        headers,
                        params: { limit: 10000 }
                    }
                );
                dispatch({
                    type: 'TRIP_SET_CURRENCIES',
                    payload: {
                        currencies: response.data.results
                    }
                });
            })();
        }
    }, [options]);

    return (
        <Autocomplete
            size="small"
            options={options ?? []}
            value={
                options?.find((item) => {
                    return item.id === props.value;
                }) ?? null
            }
            getOptionLabel={(option) => option.international_name}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label ?? t<string>('cart-material.provider-quotation-currency')}
                />
            )}
            onChange={(_, value) => props.onChange(value?.id ?? null)}
            fullWidth
        />
    );
}
