import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../Reducers/Reducers';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type CartCancelPriceProps = {
    product: any;
    cancellation: any;
}

export function CartCancelPrice(props: CartCancelPriceProps) : JSX.Element | null {
    const { product, cancellation } = props;
    const { t } = useTranslation();
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const prices = product.prices.find((price: any) => price.is_tva);
    const currency_price = prices ? currency_list.find((currency: {id: number, iso_code: string}) => currency.id === prices.selling_currency) : { id: 47, iso_code: 'EUR' };
    return (
        <Grid>
            <Typography variant="h6">{`${t<string>('cart-material.cancellation-fee')} : `}</Typography>
            {
                cancellation.type === 'STANDARD' &&
                <Fragment>
                    {
                        cancellation.refundEligibility.map((refundEligibility: any, index: number) => {
                            if (refundEligibility.percentageRefundable === 100) {
                                return (
                                    <Typography key={index} variant={"body2"}>{`${t<string>('cart-material.until-24-hours-message')} : ${new Intl.NumberFormat(language, { style: "currency", currency: currency_price?.iso_code }).format(0)}`}</Typography>
                                );
                            } 
                            return (
                                <Typography key={index} variant={"body2"}>{`${t<string>('cart-material.less-24-hours-message')} : ${new Intl.NumberFormat(language, { style: "currency", currency: currency_price?.iso_code }).format(prices.selling_price)}`}</Typography>
                            );
                        })
                    }
                </Fragment>
            }
            {
                cancellation.type === 'ALL_SALES_FINAL' &&
                <Typography variant={"body2"}>{`${t<string>('cart-material.all-sales-final-message')} : ${new Intl.NumberFormat(language, { style: "currency", currency: currency_price?.iso_code }).format(prices.selling_price)}`}</Typography>
            }
            {
                cancellation.type === 'CUSTOM' && (
                    <Fragment>
                        {
                            cancellation.refundEligibility.map((refundEligibility: any, index: number) => {
                                let new_price = prices.selling_price - (prices.selling_price * (refundEligibility.percentageRefundable / 100));
                                if (refundEligibility.dayRangeMax === undefined) {
                                    return (
                                        <Typography key={index} variant={"body2"}>{`${t<string>('cart-material.until-custom-days-message', { days: refundEligibility.dayRangeMin })} : ${new Intl.NumberFormat(language, { style: "currency", currency: currency_price?.iso_code }).format(new_price)}`}</Typography>
                                    );
                                } 
                                if (refundEligibility.dayRangeMin === 0) {
                                    return (
                                        <Typography key={index} variant={"body2"}>{`${t<string>('cart-material.min-custom-days-message', { days: refundEligibility.dayRangeMax - 1 })} : ${new Intl.NumberFormat(language, { style: "currency", currency: currency_price?.iso_code }).format(new_price)}`}</Typography>
                                    ); 
                                } 
                                return (
                                    <Typography key={index} variant={"body2"}>{`${t<string>('cart-material.range-custom-days-message', { min_day: refundEligibility.dayRangeMin, max_day: refundEligibility.dayRangeMax - 1 })} : ${new Intl.NumberFormat(language, { style: "currency", currency: currency_price?.iso_code }).format(new_price)}`}</Typography>
                                );
                            })
                        }
                    </Fragment>
                )
            }
        </Grid>
    );
}
