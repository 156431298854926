import React, { useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, styled } from "@mui/material";
import { ContentState, Editor, EditorState } from "draft-js";
import { clone } from "lodash";
import RichEditor from "../../utils/editor/editor";
import { MailTemplateVisualEditorBorderStyle, MailTemplateVisualEditorBorderStyleOption } from "../mailTemplateVisualEditorBorderStyleOption";
import { MailTemplateVisualEditorBorderCornersOption, MailTemplateVisualEditorCornersOption } from "../mailTemplateVisualEditorBorderCornersOption";
import { MailTemplateVisualEditorPaddingOptions } from "../mailTemplateVisualEditorPaddingOptions";
import { Alignment, MailTemplateVisualEditorAlignmentOption } from "../mailTemplateVisualEditorAlignmentOption";
import { MailTemplateVisualEditorBackgroundColorOption } from "../mailTemplateVisualEditorBackgroundColorOption";
import { MailTemplateVisualEditorColorOption } from "../mailTemplateVisualEditorColorOption";
import { MailTemplateVisualEditorLinkUrlOption } from "../mailTemplateVisualEditorLinkUrlOption";
import { MailTemplateVisualEditorBorderColorOption } from "../mailTemplateVisualEditorBorderColorOption";
import { MailTemplateVisualEditorBorderWidthOption } from "../mailTemplateVisualEditorBorderWidthOption";
import { MailTemplateVariablePicker } from "../mailTemplateVariablePicker";
import { MailTemplateVisualEditorContext } from "../mailTemplateVisualEditorContext";
import { registerBlock } from "../utils/registered-blocks";
import { generateBlockId } from "../utils/block-id";
import { insertTextInEditorstate } from "../utils/insert-text-in-editorstate";
import { setVisualEditorBlockOptions } from "../redux/actions";
import { MailTemplateVisualEditorBodyStyles } from "../objects/mailTemplateVisualEditorState";
import { AppState } from "../../../../../Reducers/Reducers";
import { Block } from "../objects/block";
import { BlockHtmlAttribute } from "../objects/blockHtmlAttributes";
import Icon from '@mui/icons-material/Crop75';

type Options = {
    url: string | null,
    alignment: Alignment,
    containerBackgroundColor: string,
    backgroundColor: string,
    color: string,
    borderWidth: number,
    borderStyle: MailTemplateVisualEditorBorderStyle,
    borderCorners: MailTemplateVisualEditorCornersOption,
    borderColor: string,
    padding: MailTemplateVisualEditorPaddingOptions,
    editorState: EditorState
}

type Props = {
    id: number,
    options: Options
}

type OptionsComponentProps = {
    id: number,
    options: Options
}

const editorInitialState = EditorState.createWithContent(ContentState.createFromText('Button'));

function MailTemplateVisualEditorButtonBlock(props: Props): JSX.Element {
    const context = useContext(MailTemplateVisualEditorContext);
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => state.locale.current_locale);
    const bodyStyles = useSelector((state: AppState) => {
        return state.mailTemplate.visualEditor.present.instances[context.instanceId]?.visualEditorBodyStyles;
    });
    const selectedBlockId = useSelector((state: AppState) => {
        return state.mailTemplate.others.instances[context.instanceId]?.visualEditorSelectedBlockId;
    });
    const editorRef = useRef<Editor>(null);

    const onChangeEditorState = (state: EditorState) => {
        if (locale !== null) {
            const dispatchData = setVisualEditorBlockOptions<Options>(
                context.instanceId,
                locale,
                props.id,
                {
                    ...props.options,
                    editorState: state
                }
            );
            dispatch(dispatchData);
        }
    };

    const focus = () => {
        //delay focus a little because modal tries to get focus too
        setTimeout(() => editorRef.current?.focus(), 300);
    };

    useEffect(() => {
        if (selectedBlockId === props.id && editorRef.current) {
            //delay focus a little because modal tries to get focus too
            setTimeout(editorRef.current.focus, 300);
        }
    }, [editorRef.current, selectedBlockId, props.id]);

    return (
        <MailTemplateVisualEditorContext.Consumer>
            {
                (context) => (
                    <RichEditor
                        ref={editorRef}
                        pictures={[]}
                        editorState={props.options.editorState}
                        setEditorState={onChangeEditorState}
                        values={{}}
                        hideToolbar={
                            context.disableBlocksEdit ||
                            props.id !== selectedBlockId
                        }
                        toolbars={[
                            'font-size',
                            'format',
                            'undo'
                        ]}
                        noDefault
                        controlledState
                        centerToolbars
                        readOnly={context.disableBlocksEdit}
                    >
                        {(editor) => (
                            <Container
                                onMouseDown={focus}
                                sx={{
                                    textAlign: props.options.alignment,
                                    fontFamily: `'${bodyStyles?.fontFamily.name}', Ubuntu, Helvetica, Arial, sans-serif`,
                                    backgroundColor: props.options.containerBackgroundColor
                                }}
                            >
                                <Button
                                    sx={{
                                        backgroundColor: props.options.backgroundColor,
                                        color: props.options.color,
                                        paddingTop: props.options.padding.top + 'px',
                                        paddingBottom: props.options.padding.bottom + 'px',
                                        paddingLeft: props.options.padding.left + 'px',
                                        paddingRight: props.options.padding.right + 'px',
                                        borderStyle: props.options.borderStyle,
                                        borderWidth: `${props.options.borderWidth}px`,
                                        borderColor: props.options.borderColor,
                                        borderRadius: `
                                            ${props.options.borderCorners.topLeft}px
                                            ${props.options.borderCorners.topRight}px
                                            ${props.options.borderCorners.bottomRight}px
                                            ${props.options.borderCorners.bottomLeft}px
                                        `
                                    }}
                                >
                                    {editor}
                                </Button>
                            </Container>
                        )}
                    </RichEditor>
                )
            }
        </MailTemplateVisualEditorContext.Consumer>
    );
}

const Container = styled(Box)(() => ({
    width: '100%'
}));

const Button = styled(Box)(() => ({
    display: 'inline-block'
}));

function MailTemplateVisualEditorButtonBlockOptions(props: OptionsComponentProps): JSX.Element {
    const context = useContext(MailTemplateVisualEditorContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => context.locale ?? state.locale.current_locale);

    const onChangeOption = (key: keyof OptionsComponentProps["options"], value: any) => {
        if (locale !== null) {
            const dispatchData = setVisualEditorBlockOptions(
                context.instanceId,
                locale,
                props.id,
                {
                    ...props.options,
                    [key]: value
                }
            );
            dispatch(dispatchData);
        }
    };

    const onChangePadding = (type: keyof Options["padding"], value: number) => {
        onChangeOption("padding", { ...props.options.padding, [type]: value });
    };

    function onChangeCorner<K extends keyof Options["borderCorners"]>(
        type: K,
        value: Options["borderCorners"][K]
    ): void {
        onChangeOption("borderCorners", { ...props.options.borderCorners, [type]: value });
    }

    const onInsertVariable = (variable: string) => {
        onChangeOption(
            'editorState',
            insertTextInEditorstate(
                props.options.editorState,
                `{{${variable}}}`
            )
        );
    };

    return (
        <div>
            <MailTemplateVariablePicker
                onChoose={onInsertVariable}
            />
            <MailTemplateVisualEditorLinkUrlOption
                url={props.options.url}
                onChangeUrl={(url) => onChangeOption('url', url)}
            />
            <MailTemplateVisualEditorBackgroundColorOption
                backgroundColor={props.options.containerBackgroundColor}
                onChangeBackgroundColor={(color) => onChangeOption('containerBackgroundColor', color)}
            />
            <MailTemplateVisualEditorBackgroundColorOption
                title={t('shared.mail-template-visual-editor-button-color')}
                backgroundColor={props.options.backgroundColor}
                onChangeBackgroundColor={(color) => onChangeOption('backgroundColor', color)}
            />
            <MailTemplateVisualEditorColorOption
                title={t('shared.mail-template-visual-editor-text-color')}
                color={props.options.color}
                onChangeColor={(color) => onChangeOption('color', color)}
            />
            <MailTemplateVisualEditorAlignmentOption
                alignment={props.options.alignment}
                onChangeAlignment={(alignment) => onChangeOption('alignment', alignment)}
            />
            <MailTemplateVisualEditorBorderWidthOption
                width={props.options.borderWidth}
                onChangeWidth={(width) => onChangeOption("borderWidth", width)}
            />
            <MailTemplateVisualEditorBorderStyleOption
                style={props.options.borderStyle}
                onChangeStyle={(style) => onChangeOption("borderStyle", style)}
            />
            <MailTemplateVisualEditorBorderColorOption
                color={props.options.borderColor}
                onChangeColor={(color) => onChangeOption("borderColor", color)}
            />
            <MailTemplateVisualEditorBorderCornersOption
                corners={props.options.borderCorners}
                onChangeCorner={onChangeCorner}
            />
            <MailTemplateVisualEditorPaddingOptions
                padding={props.options.padding}
                onChangePadding={onChangePadding}
            />
        </div>
    );
}

export class ButtonBlock implements Block<Options> {
    private id;
    private options: Options;


    public constructor() {
        this.id = generateBlockId();
        this.options = {
            alignment: 'center',
            url: 'https://example.com',
            containerBackgroundColor: 'rgba(255, 255, 255, 0)',
            backgroundColor: '#4A90E2',
            color: '#fff',
            borderColor: '#000',
            borderStyle: 'solid',
            borderWidth: 0,
            borderCorners: {
                topLeft: 4,
                topRight: 4,
                bottomLeft: 4,
                bottomRight: 4
            },
            padding: {
                top: 10,
                bottom: 10,
                left: 20,
                right: 20
            },
            editorState: editorInitialState
        };
    }

    public getType(): string {
        return 'button';
    }
    public getId(): number {
        return this.id;
    }

    public setOptions(options: Options): void {
        this.options = options;
    }

    public getOptions(): Options {
        return this.options;
    }

    public getExtraHtmlAttributes(): BlockHtmlAttribute[] {
        return [
            {
                path: `.button-block.${this.id}`,
                name: 'data-url',
                content: this.options.url ?? ''
            }
        ];
    }

    public renderMjml(bodyStyles: MailTemplateVisualEditorBodyStyles): string {
        const options = this.getOptions();
        const content = options.editorState.getCurrentContent().getPlainText();
        const className = ['button-block', this.id].join(' ');
        return `
            <mj-button
                align="${options.alignment}"
                ${options.url ? 'href="' + options.url + '"' : ''}
                font-size="16px"
                font-family="'${bodyStyles.fontFamily.name}', Ubuntu, Helvetica, Arial, sans-serif"
                inner-padding="${options.padding.top}px ${options.padding.right}px ${options.padding.bottom}px ${options.padding.left}px"
                border="${options.borderWidth}px ${options.borderStyle} ${options.borderColor}"
                border-radius="${options.borderCorners.topLeft}px ${options.borderCorners.topRight}px ${options.borderCorners.bottomRight}px ${options.borderCorners.bottomLeft}px"
                container-background-color="${options.containerBackgroundColor}"
                background-color="${options.backgroundColor}"
                color="${options.color}"
                css-class="${className}"
            >
                ${content}
            </mj-button>
        `;
    }

    public clone(options?: Options): ButtonBlock {
        let block: ButtonBlock;

        if (options) {
            block = clone(this);
        } else {
            block = new ButtonBlock();
        }

        const blockOptions = options ?? this.getOptions();
        block.setOptions(blockOptions);

        return block;
    }
}

registerBlock({
    type: "button",
    icon: <Icon />,
    label: "shared.mail-template-visual-editor-button-block-label",
    component: MailTemplateVisualEditorButtonBlock,
    optionsComponent: MailTemplateVisualEditorButtonBlockOptions,
    factory: () => new ButtonBlock()
});
