//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import CheckBeforeRequest from '../../Common/CheckBeforeRequest';
import { SetCustomFilters } from '../../../Actions/Menu';

const useStyles = makeStyles(theme => ({
    fontSize12: {
        fontSize: "12px !important",
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
}));

const SaveFiltersDialog = ({saveFiltersOpen, handleSaveFiltersOpen}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const customFilters = useSelector(store => store.menu.customFilters);
    const customFiltersSelected = useSelector(store => store.menu.customFiltersSelected);
    const valueDateType = useSelector(store => store.menu.valueDateType);
    const dateType = useSelector(store => store.menu.dateType);
    const tripStatusFilter = useSelector(store => store.menu.tripStatusFilter);
    const userTO = useSelector(store => store.menu.userTO);
    const agencyFilter = useSelector(store => store.menu.agencyFilter);
    const userAgence = useSelector(store => store.menu.userAgence);
    const creator = useSelector(store => store.menu.creator);
    const creatorList = useSelector(store => store.menu.creatorList);
    const paxNumber = useSelector(store => store.menu.paxNumber);
    const nightNumber = useSelector(store => store.menu.nightNumber);
    const tripInitBy = useSelector(store => store.menu.tripInitBy);
    const completionPercentageFilter = useSelector(store => store.menu.completionPercentageFilter);
    const tripTags = useSelector(store => store.menu.tripTags);
    const userList = useSelector(store => store.menu.userList);
    const userAgenceList = useSelector(store => store.menu.userAgenceList);
    const myFiles = useSelector(store => store.menu.myFiles);

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const [saveType, setSaveType] = useState('user');
    const [saveFiltersName, setSaveFiltersName] = useState('');
    const [saveFiltersNameError, setSaveFiltersNameError] = useState(false);
    const handleName = (event) => {
        setSaveFiltersName(event.target.value);
        setSaveFiltersNameError(false);
    };
    const handleReset = () => {
        setSaveFiltersName('');
        handleSaveFiltersOpen();
    }
    const handleSave = () => {
        const { headers } = CheckBeforeRequest();
        const paxArray = [{label: "1-2"}, {label: "3-4"}, {label: "5-7"}, {label: "8-10"}, {label: "+10"}];
        const nightArray = [{label: "1-2"}, {label: "3-4"}, {label: "5-7"}, {label: "8-10"}, {label: "+10"}];
        if (saveFiltersName !== '') {
            let user_to_in = [];
            let user_ta_in = [];
            let creator_in = [];
            let nb_pax = '';
            let nb_nights = '';
            let creator_client_type = '';
            let status = [];
            let status_contract = [];
            let status_modification = [];
            let status_booking_progress = [];
            let url = saveType === 'user' ? `${API_HREF}client/${window.id_owner}/user/${user.id}/trip-filter-preference/` : `${API_HREF}client/${window.id_owner}/trip-filter-preference/`
            let completion_percentage_gte = completionPercentageFilter[0];
            let completion_percentage_lte  = completionPercentageFilter[1];
            let is_paid_balance = null;
            let is_signed = null;
            let has_invoice = null;
            let has_roadbook = null;
            let has_router = null;
            let is_processed = null;
            let is_paid_deposit = null;
            let user_ta_isnull = false;
            let user_to_isnull = false;
            let user_to_all = false;
            let user_ta_all = false;
            let minisite_link_sent = null;
            let minisite_link_sent_modified = false;
            let minisite_link_amendment = false;
            if (customFiltersSelected !== null && (customFiltersSelected.filters_type === 'user' || (['cercledesvoyages', 'tropicalementvotre', 'terreslointaines'].includes(quotation_code) && user.groups.some(group => group.is_admin === true)))) {
                url += `${customFiltersSelected.id}/`
            }
            if (tripStatusFilter.length !== 0) {
                if (!['cercledesvoyages', 'tropicalementvotre', 'connections', 'terreslointaines'].includes(quotation_code)) {
                    tripStatusFilter.forEach((el) => status.push(el.label));
                } else {
                    tripStatusFilter.forEach((el) => {
                        if (el.group === "TRIP_STATUS_CONTRACT") {
                            status_contract.push(el.label);
                        } else if (el.group === "TRIP_STATUS_MODIFICATION") {
                            status_modification.push(el.label);
                        } else {
                            status_booking_progress.push(el.label);
                        }
                    });
                }
            }
            if (userTO.length !== 0 && !myFiles) {
                let tmp = "";
                if (userTO.length === userList.length - 1) {
                    user_to_all = true;
                } else {
                    for (let i = 0; i < userTO.length; i++) {
                        if (userTO[i].id !== null) {
                            user_to_in.push(userTO[i].id)
                        } else {
                            user_to_isnull = true;
                        }
                    }
                }
            }
            if (userAgence.length !== 0 && !myFiles) {
                let tmp = "";
                if (userAgence.length === userAgenceList.length - 1) {
                    user_ta_all = true;
                } else {
                    for (let i = 0; i < userAgence.length; i++) {
                        if (userAgence[i].id !== null) {
                            user_ta_in.push(userAgence[i].id)
                        } else {
                            user_ta_isnull = true;
                        }
                    }
                }
            }
            if (creatorList.length !== 0) {
                let tmp = "";
                for (let i = 0; i < creator.length; i++) {
                    creator_in.push(creator[i].id);
                }
            }
            if (paxNumber.length !== 0) {
                let sortPax = paxNumber.sort((a, b) => {
                    let indexA = paxArray.findIndex(element => element.label === a.label);
                    let indexB = paxArray.findIndex(element => element.label === b.label);
                    return indexA - indexB;
                });
                let filter1 = sortPax.filter(el => el.label === "+10");
                let filter2 = sortPax.filter(el => el.label !== "+10");
                let tmp = "";
                if (filter1.length !== 0) {
                    nb_pax = "10"
                }
                if (filter2.length !== 0) {
                    for (let i = 0; i < filter2.length; i++) {
                        if (i === 0) {
                            let tmp_split = filter2[i].label.split("-");
                            tmp += tmp_split[0] + ',' + tmp_split[1];
                        }
                        else {
                            let tmp_split = filter2[i].label.split("-");
                            tmp += "," + tmp_split[0] + ',' + tmp_split[1];
                        }
                    }
                }
                if (tmp !== "") {
                    nb_pax = tmp;
                }
            }
            if (nightNumber.length !== 0) {
                let sortNight = nightNumber.sort((a, b) => {
                    let indexA = nightArray.findIndex(element => element.label === a.label);
                    let indexB = nightArray.findIndex(element => element.label === b.label);
                    return indexA - indexB;
                });
                let filter1 = sortNight.filter(el => el.label === "+10");
                let filter2 = sortNight.filter(el => el.label !== "+10");
                let tmp = "";
                if (filter1.length !== 0) {
                    nb_nights = "10"
                }
                if (filter2.length !== 0) {
                    for (let i = 0; i < filter2.length; i++) {
                        if (i === 0) {
                            let tmp_split = filter2[i].label.split("-");
                            tmp += tmp_split[0] + ',' + tmp_split[1];
                        }
                        else {
                            let tmp_split = filter2[i].label.split("-");
                            tmp += "," + tmp_split[0] + ',' + tmp_split[1];
                        }
                    }
                }
                if (tmp !== "") {
                    nb_nights = tmp;
                }
            }
            if (tripInitBy.length !== 0) {
                if (tripInitBy.length === 1) {
                    if (tripInitBy[0].label === "TO") {
                        creator_client_type = "1,3"
                    }
                    else {
                        creator_client_type = "2"
                    }
                }
                else {
                    creator_client_type = "1,2,3"
                }
            }
            if (tripTags.length !== 0) {
                tripTags.map((tag) => {
                    if (tag.label === "is_paid_balance") {
                        is_paid_balance = true;
                    }
                    if (tag.label === "is_no_paid_balance") {
                        is_paid_balance = false;
                    }
                    if (tag.label === "is_signed") {
                        is_signed = true;
                    }
                    if (tag.label === "has_invoice") {
                        has_invoice = true;
                    }
                    if (tag.label === "has_no_invoice") {
                        has_invoice = false;
                    }
                    if (tag.label === "has_roadbook") {
                        has_roadbook = true;
                    }
                    if (tag.label === "no_roadbook") {
                        has_roadbook = false;
                    }
                    if (tag.label === "has_router") {
                        has_router = true;
                    }
                    if (tag.label === "no_router") {
                        has_router = false;
                    }
                    if (tag.label === "is_processed") {
                        is_processed = true;
                    }
                    if (tag.label === "is_paid_deposit") {
                        is_paid_deposit = true;
                    }
                    if (tag.label === "minisite_link_sent") {
                        minisite_link_sent = true;
                    }
                    if (tag.label === "minisite_link_sent_modified") {
                        minisite_link_sent_modified = true;
                    }
                    if (tag.label === "minisite_link_amendment") {
                        minisite_link_amendment = true;
                    }
                    if (tag.label === "minisite_not_sent") {
                        minisite_link_sent = false;
                    }
                })
            }
            console.log('has_invoice:', has_invoice);
            axios({
                method: customFiltersSelected !== null && (customFiltersSelected.filters_type === 'user' || (['cercledesvoyages', 'tropicalementvotre', 'terreslointaines'].includes(quotation_code) && user.groups.some(group => group.is_admin === true))) ? 'PATCH' : 'POST',
                headers: headers,
                url: url,
                data: {
                    name: saveFiltersName,
                    created_date_filter: (valueDateType === 'creation' ? dateType : null),
                    start_date: (valueDateType === 'trip' ? dateType : null),
                    due_date: (valueDateType === 'deadline' ? dateType : null),
                    modified_date: (valueDateType === 'edit' ? dateType : null),
                    confirmation_date: (valueDateType === 'confirmation' ? dateType : null),
                    status: (status.length !== 0 ? status.join(",") : null),
                    status_contract: status_contract.length !== 0 ? status_contract.join(",") : null,
                    status_modification: status_modification.length !== 0 ? status_modification.join(",") : null,
                    status_booking_progress: status_booking_progress.length !== 0 ? status_booking_progress.join(",") : null,
                    nb_pax: (nb_pax !== '' ? nb_pax : null),
                    nb_nights: (nb_nights !== '' ? nb_nights : null),
                    creator_client_type: (creator_client_type !== '' ? creator_client_type : null),
                    target_client: agencyFilter !== null ? agencyFilter.id : null,
                    destination: null,
                    user_to_in: user_to_in,
                    user_ta_in: user_ta_in,
                    creator_in: creator_in,
                    completion_percentage_gte: completion_percentage_gte,
                    completion_percentage_lte: completion_percentage_lte,
                    is_paid: is_paid_balance,
                    is_signed: is_signed,
                    current_version_has_invoice: has_invoice,
                    current_version_has_roadbook: has_roadbook,
                    current_version_has_router: has_router,
                    current_version_is_processed: is_processed,
                    current_version_is_paid: is_paid_deposit,
                    user_ta_isnull: user_ta_isnull,
                    user_to_isnull: user_to_isnull,
                    user_to_all: user_to_all,
                    user_ta_all: user_ta_all,
                    my_file: myFiles ? myFiles : undefined,
                    minisite_link_sent: minisite_link_sent,
                    minisite_link_sent_date: valueDateType === 'minisite_link_sent_date' ? dateType : null,
                    minisite_link_sent_modified: minisite_link_sent_modified,
                    minisite_link_sent_modified_date: valueDateType === 'minisite_link_sent_modified_date' ? dateType : null,
                    minisite_link_amendment: minisite_link_amendment,
                    minisite_link_amendment_date: valueDateType === 'minisite_link_amendment_date' ? dateType : null
                }
            }).then((response) => {
                console.log('response:', response);
                let data = response.data;
                data.label = data.name;
                data.filters_type = saveType === "user" ? "user" : "company";
                if (response.config.method === 'post') {
                // if (customFiltersSelected !== null && (customFiltersSelected.filters_type === 'user' || (['cercledesvoyages'].includes(quotation_code) && user.groups.some(group => group.is_admin === true)))) {
                    let sort_filter = [...customFilters, data].sort((a, b) => {
                        if (a.filters_type === 'user') {
                            if (a.filters_type === b.filters_type) {
                                return 0;
                            }
                            return -1;
                        }
                    });
                    dispatch(SetCustomFilters(sort_filter));
                    handleReset();
                } else {
                    const index = customFilters.findIndex((filter) => filter.id === data.id);
                    dispatch(SetCustomFilters([...customFilters.slice(0, index), data, ...customFilters.slice(index + 1)]));
                    dispatch({
                        type: "MENU_SET_CUSTOM_FILTERS_SELECTED",
                        payload: {
                            selectedOptions: data,
                            t: t,
                            userAgenceList: userAgenceList,
                            userList: userList,
                            user: user
                        }
                    });
                    handleSaveFiltersOpen();
                }
            }).catch((error) => {
                console.log(error);
            });
        } else {
            setSaveFiltersNameError(true);
        }
    }
    const handleSaveType = (event) => {
        setSaveType(event.target.name);
    }
    useEffect(() => {
        if (customFiltersSelected !== null) {
            setSaveFiltersName(customFiltersSelected.name);
            if (['cercledesvoyages', 'connections', 'terreslointaines'].includes(quotation_code) && user.groups.some(group => group.is_admin === true)) {
                setSaveType(customFiltersSelected.filters_type);
            }
        }
    }, [customFiltersSelected]);
    return (
        <Dialog open={saveFiltersOpen} onClose={handleReset} fullWidth maxWidth={ "md" }>
            <DialogTitle>{customFiltersSelected !== null && (customFiltersSelected.filters_type === 'user' || (['cercledesvoyages', 'terreslointaines'].includes(quotation_code) && user.groups.some(group => group.is_admin === true))) ? t("menu.user_management.edit_filters") : t("menu.user_management.save_filters") }</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={ 12 }>
                        <TextField
                            autoFocus
                            margin={ "dense" }
                            id={ "custom-filters-name" }
                            label={ t("menu.user_management.give_filters_name") }
                            fullWidth
                            variant={ "outlined" }
                            value={saveFiltersName}
                            onChange={handleName}
                            error={saveFiltersNameError}
                        />
                    </Grid>
                    {
                        customFiltersSelected === null && (user.client_full.type !== 2 && (['cercledesvoyages', 'connections', 'terreslointaines'].includes(quotation_code) && user.groups.some(group => group.is_admin === true))) && (
                            <Grid item xs={12}>
                                <FormControl component={"fieldset"}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={saveType === 'user'} onChange={handleSaveType} name={ 'user' } color={"primary"}/>}
                                            label={t("menu.user_management.filters_user")}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={saveType !== 'user'} onChange={handleSaveType} name={ 'company' } color={"primary"}/>}
                                            label={t("menu.user_management.filters_company")}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        )
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} color={"primary"} fullWidth className={`${classes.fontSize12} ${classes.noWrap}`} onClick={handleReset}>{t("global.cancel")}</Button>
                <Button variant={"outlined"} color={"primary"} fullWidth className={`${classes.fontSize12} ${classes.noWrap}`} onClick={handleSave}>{t("global.validate")}</Button>
            </DialogActions>
        </Dialog>
    )
}
export default SaveFiltersDialog;