export default (travelers_list) => {
	let pax_passenger = {
	    ADT : 0,
	    CNN : 0,
	    INF : 0,
	    total: 0
	};
	travelers_list.map((travelers) => {
	    if (travelers.flight_type === "ADT")
	        pax_passenger.ADT += 1;
	    else if (travelers.flight_type === "CNN")
	        pax_passenger.CNN += 1;
	    else if (travelers.flight_type === "INF")
	        pax_passenger.INF += 1;
	});
    pax_passenger.total = travelers_list.length;
	return pax_passenger;
}