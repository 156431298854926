import { useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { ItineraryContentStep } from "../objects/itineraryContentStep";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { AppState } from "../../../Reducers/Reducers";
import { ItineraryContents } from "../../CartMaterial/objects/itineraryContents";
import { ItineraryByDay } from "../objects/itineraryByDay";
import { ItineraryByStep } from "../objects/itineraryByStep";

type Callback = (
    source: string,
    tripVersion: number,
    steps: ItineraryContentStep[]
) => Promise<ItineraryContents | undefined>

type Options = Partial<{
    onTrigger: (source: string) => void,
    onSuccess: (source: string, version: number, steps: ItineraryContents) => void,
    onError: (error: Error, source: string) => void,
    onFinally: (source: string) => void
}>

type RequestOptions = {
    tripId: number,
    version: number,
    items: ItineraryContents,
    steps: ItineraryContentStep[]
}

export function useItineraryContentUpdate(options: Options): Callback {
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const itineraryContent = useSelector((state: AppState) => {
        return state.itinerarySlice.content;
    });

    return useCallback(
        async (source, version, steps) => {
            const content = itineraryContent[version];
            if (tripId && version && content?.state === 'success') {
                try {
                    if (options.onTrigger) {
                        options.onTrigger(source);
                    }
                    const response = await makeRequest({
                        tripId,
                        version,
                        items: content?.data,
                        steps
                    });
                    if (options.onSuccess && response) {
                        options.onSuccess(
                            source,
                            version,
                            response
                        );
                    }
                    return response;
                } catch (error: any) {
                    if (options.onError) {
                        options.onError(error, source);
                    }
                } finally {
                    if (options.onFinally) {
                        options.onFinally(source);
                    }
                }
            }
        },
        [tripId, itineraryContent]
    );
}

/**
 * @TODO use a PUT request.
 */
async function makeRequest(options: RequestOptions): Promise<ItineraryContents | undefined> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const alreadyUsedIds: number[] = [];
        const data = options.steps.map((step) => {
            const defaultLocalization = step.content.localization[0];

            const result = {
                id: step.content.id && !alreadyUsedIds.includes(step.content.id) ?
                    step.content.id :
                    undefined,
                contract: null,
                destinations: step.mode === 'by-day' ?
                    step.content.destinations?.map((item) => {
                        return item.id;
                    }) :
                    null,
                destination: step.mode === 'by-step' ?
                    step.content.destination?.id :
                    null,
                day: step.mode === 'by-day' ? step.content.day : null,
                title: !step.content.title || step.content.title.length === 0 ? (defaultLocalization?.title ?? '') : step.content.title,
                short_description: !step.content.short_description || step.content.short_description.length === 0 ? (defaultLocalization?.short_description ?? '') : step.content.short_description,
                long_description: !step.content.long_description || step.content.long_description.length === 0 ? (defaultLocalization?.long_description ?? '') : step.content.long_description,
                localization: step.content.localization.map((item) => {
                    //eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { id, ...rest } = item as any; //@TODO fix type :'(
                    return rest;
                }),
                interval: step.content.interval.map((item) => {
                    return {
                        ...item,
                        localization: item.localization.map((item) => {
                            return { ...item, id: undefined };
                        }),
                        id: undefined
                    };
                }),
                trip_version: options.version,
                distance: step.content.distance,
                picture: null,
                pictures: step.content.pictures?.map((item) => {
                    return item.id;
                }) ?? [],
                circuit: step.mode === 'by-day' ?
                    step.content.circuit :
                    undefined,
                circuit_trip_version: step.mode === 'by-day' ?
                    step.content.circuit_trip_version :
                    undefined,
                iti_type: step.mode === 'by-day' ?
                    step.content.iti_type :
                    undefined,
                order: step.mode === 'by-step' ?
                    step.content.order :
                    undefined
            };

            if (step.content.id) {
                alreadyUsedIds.push(step.content.id);
            }

            return result;
        });
        const inputIds = options.steps.map((item) => item.content.id);
        const toBeDeletedIds = options.items.content.map((item) => item.id).filter((id) => {
            return !inputIds.includes(id);
        });
        const toBeDeleted = toBeDeletedIds.map((item) => ({ id: item, pictures: [], delete: true }));
    
        const payload = [...data, ...toBeDeleted];

        if (options.items.mode === 'by-day') {
            const response = await axios.patch<ItineraryByDay[]>(
                `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/by-day/array_patch/`,
                payload,
                { headers }
            );
            return {
                mode: 'by-day',
                content: response.data.sort((a, b) => {
                    const aDays = [...a.day].sort((a, b) => a - b);
                    const bDays = [...b.day].sort((a, b) => a - b);
                    return (aDays[0] ?? 0) < (bDays[0] ?? 0) ? -1 : 1;
                })
            };
        }

        const response = await axios.patch<ItineraryByStep[]>(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/by-step/array_patch/`,
            payload,
            { headers }
        );
        return {
            mode: 'by-step',
            content: response.data
        };
    }
}
