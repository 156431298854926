//Dependencies
import clsx from "clsx";
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, Fragment } from 'react';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
//Core
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Autocomplete } from "@material-ui/lab";
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DateRangeDelimiter, DateRangePicker, LocalizationProvider } from '@material-ui/pickers';
import { EditorState } from "draft-js";
import { isNumber } from "lodash";
//Components
import ActivityStandaloneProductFormPrice from './ActivityStandaloneProductFormPrice';
import RichEditor, { RichEditorStyleMap } from "../../Menu/MaterialTripList/utils/editor/editor";
import { customColors } from "../../Menu/MaterialTripList/utils/editor/customColors";
import { CartManualProductFormAddressPicker } from "../../CartMaterial/CartManualProductFormAddressPicker";
//Common
import { useShowError } from "../../Utils/showError";
import { restoreRichEditorStateFromHtml } from "../../Menu/MaterialTripList/utils/editor/restoreRichEditorStateFromHtml";
import { renderRichEditorContentHTML } from "../../Menu/MaterialTripList/utils/editor/renderRichEditorContentHtml";
import GetCookie from '../../Common/Functions/GetCookie';
import CheckBeforeRequest from '../../Common/CheckBeforeRequest';
import localeText from "../../Functions/localeText";
import i18n from "../../../i18n";

const useStyles = makeStyles({
    formContainer: {
        paddingTop: 8
    },
    gridImage: {
        width: 250
    },
    pictureContainer: {
        position: 'relative'
    },
    removePicture: {
        position: 'absolute',
        right: 8,
        top: 8,
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: '50%',
        padding: 2
    },
    imageButton: {
        marginTop: 8
    },
    gridContent: {
        marginLeft: 8
    },
    image: {
        borderRadius: 16
    },
    select: {
        width: '100%'
    },
    travelersContainer: {
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        padding: '12px 12px 0 12px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    travelersError: {
        borderColor: 'red'
    },
    datesErrorText: {
        color: 'red',
        fontSize: 12
    },
    buttonSeparator: {
        margin: '16px 0 0 0'
    },
    manualProductCancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    manualProductCreateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    menuHeight: {
        maxHeight: 216
    }
});

const EmptyState = EditorState.createEmpty();

const ActivityStandaloneProductForm = ({
    open,
    activity,
    pictures,
    current_groups,
    start_date_init,
    end_date_init,
    destination,
    onClose,
    standaloneSuccess
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const itinerary_list = useSelector(
        (state) => state.itinerary.itinerary_list
    );
    const locales = useSelector(state => state.user.locales);
    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });
    const name = activity.is_custom ? activity.title : localeText(current_locale.id, activity.localization, activity.title, 'title');
    const product_type = 12;
    const destination_associated = destination.id;
    const provider_id = useSelector(
        (state) => state.cart.manual_product_provider_id
    );
    const trip_start_date = useSelector(state => state.trip.start_date);
    const trip_end_date = useSelector(state => state.trip.end_date);
    const provider_name = useSelector(
        (state) => state.cart.manual_product_provider_name
    );
    const manual_product_groups = useSelector(
        (state) => state.cart.manual_product_groups
    );
    const manual_product_id = useSelector(
        (state) => state.cart.manual_product_id
    );
    const price_id = useSelector((state) => state.cart.manual_product_price_id);
    const default_pictures = useSelector(
        (state) => state.base.default_pictures
    );
    const start_date = useSelector(
        (state) => state.cart.manual_product_start_date
    );
    const start_hour = useSelector(
        (state) => state.cart.manual_product_start_hour
    );
    const end_date = useSelector((state) => state.cart.manual_product_end_date);
    const end_hour = useSelector((state) => state.cart.manual_product_end_hour);
    const stars = useSelector((state) => state.cart.manual_product_stars);
    const rooms = useSelector((state) => state.cart.manual_product_rooms);
    const user = useSelector(state => state.user.user);
    const picture = pictures?.[0];
    const cgos_reduction = useSelector(
        (state) => state.cart.manual_product_cgos_reduction
    );
    const breakfast_included = useSelector(
        (state) => state.cart.manual_product_breakfast_included
    );
    const lunch_included = useSelector(
        (state) => state.cart.manual_product_lunch_included
    );
    const diner_included = useSelector(
        (state) => state.cart.manual_product_diner_included
    );
    const resort_fees = useSelector(
        (state) => state.cart.manual_product_resort_fees
    );
    const resort_fees_currency = useSelector(
        (state) => state.cart.manual_product_resort_fees_currency
    );
    const flight_paste = useSelector(
        (state) => state.cart.manual_product_flight_paste
    );
    const flight_taxes = useSelector(
        (state) => state.cart.manual_product_flight_taxes
    );
    const baggages = useSelector((state) => state.cart.manual_product_baggages);
    const margin_type = useSelector(
        (state) => state.cart.manual_product_margin_type
    );
    const margin_value = useSelector(
        (state) => state.cart.manual_product_margin_value
    );
    const purchase_price = useSelector(
        (state) => state.cart.manual_product_purchase_price
    );
    const purchase_currency = useSelector(
        (state) => state.cart.manual_product_purchase_currency
    );
    const description = useSelector(
        (state) => state.cart.manual_product_description
    );
    const note = useSelector((state) => state.cart.manual_product_note);
    const manual_product_poi = useSelector((state) => state.cart.manual_product_poi);
    const manual_item_list = useSelector((state) => state.cart.manual_item_list);
    const providers = useSelector((state) => state.trip.providers);
    const travelers = useSelector((state) => state.trip.travelers);
    const manual_providers = useSelector((state) => state.trip.manual_providers);
    const data_trip = useSelector((state) => state.trip.data_trip);

    const [groups, setGroups] = useState([]);
    const [nameError, setNameError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [providerError, setProviderError] = useState(false);
    const [datesError, setDatesError] = useState(false);
    const [travelersError, setTravelersError] = useState(false);
    const [roomNameError, setRoomNameError] = useState(false);
    const [geolocationError, setGeolocationError] = useState(false);
    const [stepError, setStepError] = useState(false);
    const [stateModified, setStateModified] = useState(false);
    const [openStartMenu, setOpenStartHour] = useState(null);
    const [openEndMenu, setOpenEndHour] = useState(null);
    const [providersOption, setProvidersOption] = useState([]);
    const [productProvider, setProductProvider] = useState(null);
    const [loading, setLoading] = useState(false);
    const [descriptionEditorState, setDescriptionEditorState] = useState(EmptyState);
    const [geolocation, setGeolocation] = useState({
        address: '',
        position: {
            latitude: null,
            longitude: null
        }
    });
    const showError = useShowError();

    const quotation_code = JSON.parse(
        localStorage.getItem('config')
    ).quotation_code;
    const hours = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23
    ];
    const minutes = [0, 15, 30, 45];
    const onSetDates = dates => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_DATES', payload: { start_date: dates[0] === null ? null : dates[0].format('YYYY-MM-DD'), end_date: dates[1] === null ? null : dates[1].format('YYYY-MM-DD') } });
    };

    const onChangeGeolocation = (key, value) => {
        setGeolocation((state) => ({
            ...state,
            [key]: value
        }));
    };

    if (!stateModified) {
        let set_description = [];
        
        if (quotation_code === 'cercledesvoyages' || quotation_code === 'tropicalementvotre') {
            if ((activity.short_description !== null || (activity.is_custom && localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') !== ''))) {
                set_description.push(localeText(current_locale.id, activity.localization, activity.short_description, 'short_description'));
                // set_description.push((activity.is_custom && activity.short_description !== null) ? activity.short_description : localeText(current_locale.id, activity.localization, activity.short_description, 'short_description'));
            }
            if ((activity.long_description !== null || (activity.is_custom && localeText(current_locale.id, activity.localization, activity.long_description, 'long_description') !== ''))) {
                set_description.push(localeText(current_locale.id, activity.localization, activity.long_description, 'long_description'));
                // set_description.push((activity.is_custom && activity.long_description !== null) ? activity.long_description : localeText(current_locale.id, activity.localization, activity.long_description, 'long_description'));
            }
        } else {
            if (activity.long_description !== null) {
                set_description.push(activity.is_custom ? activity.long_description : localeText(current_locale.id, activity.localization, activity.long_description, 'long_description'));
            }
            if (activity.long_description === null && activity.short_description !== null) {
                set_description.push(activity.is_custom ? activity.short_description : localeText(current_locale.id, activity.localization, activity.short_description, 'short_description'));
            }
        }
        dispatch({
            type: 'CART_MANUAL_PRODUCT_NAME_DESC',
            payload: {
                name: name,
                description: set_description.join('\n')
            }
        });
        if (itinerary_list) {
            dispatch({
                type: 'CART_MANUAL_PRODUCT_DESTINATION_ASSOCIATED',
                payload: {
                    value: destination.id,
                    itinerary_list: itinerary_list
                }
            });
        }
        setStateModified(true);
    }

    const onNameChange = (event) => {
        dispatch({
            type: 'CART_MANUAL_PRODUCT_NAME',
            payload: event.target.value
        });
    };
    const onProductTypeChange = (event) => {
        dispatch({
            type: 'CART_MANUAL_PRODUCT_TYPE',
            payload: {
                value: parseInt(event.target.value),
                default_pictures: default_pictures
            }
        });
        setNameError(false);
        setStepError(false);
        setProviderError(false);
        setDatesError(false);
        setRoomNameError(false);
        setTravelersError(false);
    };
    const onDestinationAssociatedChange = (event) => {
        dispatch({
            type: 'CART_MANUAL_PRODUCT_DESTINATION_ASSOCIATED',
            payload: {
                value: parseInt(event.target.value),
                itinerary_list: itinerary_list
            }
        });
    };
    const onDescriptionChange = event => {
        dispatch({ type: 'CART_MANUAL_PRODUCT_DESCRIPTION', payload: event.target.value });
    };
    const onProviderChange = (event, value) => {
        setProductProvider(value);
        dispatch({ type: 'CART_MANUAL_PRODUCT_PROVIDER', payload: value });
    };
    const onProviderNameChange = (event) => {
        dispatch({
            type: 'CART_MANUAL_PRODUCT_PROVIDER_NAME',
            payload: event.target.value
        });
    };
    const onNoteChange = (event) => {
        dispatch({
            type: 'CART_MANUAL_PRODUCT_NOTE',
            payload: event.target.value
        });
    };
    const onCancelManualProduct = () => {
        dispatch({ type: 'CART_MANUAL_PRODUCT_CANCEL' });
        onClose();
    };
    const toggleTraveler = id => () => {
        let tmp_groups = [...groups];
        if (tmp_groups.includes(id)) {
            tmp_groups.splice(tmp_groups.indexOf(id), 1);
        } else {
            tmp_groups.push(id);
        }
        setGroups(tmp_groups);
    };
    const onCheckGroups = async () => {
        setLoading(true);
        const { headers } = CheckBeforeRequest();
        setNameError(false);
        setStepError(false);
        setProviderError(false);
        setDatesError(false);
        setRoomNameError(false);
        setTravelersError(false);
        setGeolocationError(false);
        let pass_control = true;
        setDescriptionError(false);
        const styles = {
            ...RichEditorStyleMap,
            ...(quotation_code ? customColors[quotation_code] : null)
        };
        const description = renderRichEditorContentHTML(
            styles,
            descriptionEditorState.getCurrentContent()
        );
        const doc = new DOMParser().parseFromString(description, 'text/html');
        if (doc.body.innerText.length > 12000) {
            enqueueSnackbar(t('cart-material.manual-product-description-too-big'), {
                variant: 'warning'
            });
            pass_control = false;
            setDescriptionError(true);
        }
        if (purchase_currency === null) {
            enqueueSnackbar(
                t('cart-material.manual-product-no-purchase-currency'),
                {
                    variant: 'warning'
                }
            );
            pass_control = false;
        }
        if (resort_fees !== null && resort_fees_currency === null) {
            enqueueSnackbar(
                t('cart-material.manual-product-no-resort-fees-currency'),
                { variant: 'warning' }
            );
            pass_control = false;
        }
        if (name === null || name === '') {
            enqueueSnackbar(t('cart-material.manual-product-no-name'), {
                variant: 'warning'
            });
            pass_control = false;
            setNameError(true);
        }
        if (
            product_type === 61 &&
            (flight_paste === null || flight_paste === '')
        ) {
            enqueueSnackbar(t('cart-material.manual-product-no-flight-paste'), {
                variant: 'warning'
            });
            pass_control = false;
        }
        if (
            [0, 12, 1, 3].includes(product_type) &&
            destination_associated === -1
        ) {
            enqueueSnackbar(
                t('cart-material.manual-product-no-destination-associated'),
                { variant: 'warning' }
            );
            pass_control = false;
            setStepError(true);
        }
        if (
            ![18].includes(product_type) &&
            provider_id === -1 &&
            provider_name === ''
        ) {
            enqueueSnackbar(
                t('cart-material.manual-product-no-provider-selected'),
                {
                    variant: 'warning'
                }
            );
            pass_control = false;
            setProviderError(true);
        }
        if (
            [0, 1, 2, 3, 4, 5, 7, 9, 10, 12, 13].includes(product_type) &&
            (start_date === null || end_date === null)
        ) {
            enqueueSnackbar(t('cart-material.manual-product-no-dates'), {
                variant: 'warning'
            });
            pass_control = false;
            setDatesError(true);
        }
        if (product_type === 0) {
            rooms.map((room) => {
                if (room.name === '') {
                    enqueueSnackbar(
                        t('cart-material.manual-product-no-room-name'),
                        {
                            variant: 'warning'
                        }
                    );
                    pass_control = false;
                    setRoomNameError(true);
                }
                if (room.group.length === 0) {
                    enqueueSnackbar(
                        t('cart-material.manual-product-no-travelers'),
                        {
                            variant: 'warning'
                        }
                    );
                    pass_control = false;
                    setTravelersError(true);
                }
            });
        } else if (product_type !== 18 && groups.length === 0) {
            enqueueSnackbar(t('cart-material.manual-product-no-travelers'), {
                variant: 'warning'
            });
            pass_control = false;
            setTravelersError(true);
        }
        if (
            quotation_code === 'cercledesvoyages' &&
            [0, 1, 12].includes(product_type) &&
            (
                geolocation.address.trim().length === 0 ||
                geolocation.position.latitude === null ||
                geolocation.position.longitude === null
            )
        ) {
            enqueueSnackbar(t('cart-material.manual-product-no-geolocation'), { variant: 'warning' });
            pass_control = false;
            setGeolocationError(true);
        }
        if (pass_control) {
            if (product_type === 0) {
                let group_request = 0;
                await Promise.all(
                    rooms.map(async (room) => {
                        try {
                            const response = await axios({
                                method: 'POST',
                                headers: headers,
                                url: `${API_HREF}client/${
                                    window.id_owner
                                }/trip/${GetCookie('trip_id')}/versions/${GetCookie(
                                    'trip_id_version'
                                )}/travelers-group/?token=${GetCookie('trip_token')}`,
                                data: {
                                    travelers: room.group,
                                    default: false
                                }
                            });
                            room.group = undefined;
                            room.group_passenger = undefined;
                            room.group_passenger_id = response.data.id;
                            group_request++;
                            if (group_request === rooms.length) {
                                await onCreateManualProduct(null);
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    })
                );
            } else if (product_type === 18) {
                await onCreateManualProduct(null);
            } else if (groups.length > 0) {
                await axios({
                    method: 'POST',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie(
                        'trip_id'
                    )}/versions/${GetCookie(
                        'trip_id_version'
                    )}/travelers-group/?token=${GetCookie('trip_token')}`,
                    data: {
                        travelers: groups,
                        default: false
                    }
                })
                    .then((response) => {
                        onCreateManualProduct(response.data.id);
                    })
                    .catch((error) => {});
            }
            setLoading(false);
        } else {
            setLoading(false);
        }
    };
    const onCreateManualProduct = (group_passenger) => {
        const { headers } = CheckBeforeRequest();
        let flight_segment = [];
        const styles = {
            ...RichEditorStyleMap,
            ...(quotation_code ? customColors[quotation_code] : null)
        };
        return axios({
            method: manual_product_id !== null ? 'PATCH' : 'POST',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie(
                'trip_id'
            )}/versions/${GetCookie('trip_id_version')}/manual-products/${
                manual_product_id !== null ? `${manual_product_id}/` : ''
            }`,
            data: {
                name: name,
                address: geolocation.address.trim().length > 0 ?
                    geolocation.address :
                    null,
                latitude: geolocation.position.latitude?.toString().slice(0, 12),
                longitude: geolocation.position.longitude?.toString().slice(0, 12),
                product_type: product_type === 61 ? 6 : product_type,
                step:
                    destination_associated === -1
                        ? null
                        : destination_associated,
                provider_name: provider_name === '' ? null : provider_name,
                provider_id: !provider_id || provider_id === -1 ? null : provider_id.provider.id,
                start_date:
                    start_date !== null
                        ? moment
                            .utc(start_date)
                            .set('hours', start_hour.split(':')[0])
                            .set('minutes', start_hour.split(':')[1])
                            .format()
                        : null,
                end_date:
                    end_date !== null
                        ? moment
                            .utc(end_date)
                            .set('hours', end_hour.split(':')[0])
                            .set('minutes', end_hour.split(':')[1])
                            .format()
                        : null,
                stars: stars,
                breakfast_included: breakfast_included,
                lunch_included: lunch_included,
                diner_included: diner_included,
                rooms: product_type === 0 ? rooms : null,
                resort_fees: resort_fees,
                resort_fees_currency:
                    resort_fees_currency !== null
                        ? resort_fees_currency === 47
                            ? 47
                            : resort_fees_currency.id
                        : null,
                flight_paste: flight_paste,
                flight_segment:
                    flight_segment === null || flight_segment.length === 0
                        ? null
                        : flight_segment,
                cgos_reduction: cgos_reduction,
                description: renderRichEditorContentHTML(
                    styles,
                    descriptionEditorState.getCurrentContent()
                ),
                custom_information: note !== '' ? note : null,
                group_passenger:
                    group_passenger === null ? undefined : group_passenger,
                taxes: flight_taxes,
                nb_baggages: baggages,
                picture_id: picture !== null ? picture.id : null,
                prices: [
                    {
                        purchase_price: purchase_price,
                        purchase_currency:
                            purchase_currency !== null
                                ? purchase_currency === 47
                                    ? 47
                                    : purchase_currency.id
                                : null,
                        custom_rate: true,
                        custom_rate_type: margin_type,
                        custom_value: margin_value,
                        id: price_id === null ? undefined : price_id
                    }
                ],
                poi_type: manual_product_poi && product_type === 12
            }
        })
            .then((response) => {
                console.log('response.data : ', response.data);
                if (manual_product_id !== null) {
                    dispatch({
                        type: 'CART_EDIT_MANUAL_ITEM',
                        payload: response.data
                    });
                    enqueueSnackbar(t('cart-material.manual-product-edited'), {
                        variant: 'success'
                    });
                } else {
                    dispatch({
                        type: 'CART_ADD_MANUAL_ITEM',
                        payload: response.data
                    });
                    enqueueSnackbar(t('cart-material.manual-product-added'), { variant: 'success' });
                }
                standaloneSuccess();
                //onclose();
            })
            .catch((error) => {
                showError(error);
            });
    };
    const onOpenStartHour = event => {
        setOpenStartHour(event.currentTarget);
    };
    const onSetStartHour = value => () => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_START_HOUR', payload: value });
    };
    const onCloseStartHour = () => {
        setOpenStartHour(null);
    };
    const onOpenEndHour = event => {
        setOpenEndHour(event.currentTarget);
    };
    const onSetEndHour = value => () => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_END_HOUR', payload: value });
    };
    const onCloseEndHour = () => {
        setOpenEndHour(null);
    };

    const onCloseModal = () => {
        if (!loading) {
            onClose();
        }
    };

    const handlePOI = () => {
        dispatch({ type: 'CART_SET_MANUAL_POI' });
    };
    let start_time = [];
    let end_time = [];
    hours.map((hour) => {
        minutes.map((minute) => {
            let tmp_hour = hour;
            let tmp_minute = minute;
            if (tmp_hour < 10) {
                tmp_hour = `0${tmp_hour}`;
            }
            if (tmp_minute < 10) {
                tmp_minute = `0${tmp_minute}`;
            }
            start_time.push(
                <MenuItem
                    key={`manual-product-start-hour-${tmp_hour}:${tmp_minute}`}
                    onClick={onSetStartHour(`${tmp_hour}:${tmp_minute}`)}
                >
                    {tmp_hour}:{tmp_minute}
                </MenuItem>
            );
            end_time.push(
                <MenuItem
                    key={`manual-product-end-hour-${tmp_hour}:${tmp_minute}`}
                    onClick={onSetEndHour(`${tmp_hour}:${tmp_minute}`)}
                >
                    {tmp_hour}:{tmp_minute}
                </MenuItem>
            );
        });
    });
    useEffect(() => {
        setGroups(manual_product_groups);
    }, [manual_product_groups]);
    useEffect(() => {
        const element = document.getElementById('cart-manual-product-form');
        if (element !== undefined && element !== null) {
            let headerOffset = 70;
            let elementPosition = element.getBoundingClientRect().top;
            let offsetPosition =
                elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
        }
        let tmp_groups = [];
        travelers.map((traveler) => tmp_groups.push(traveler.id));
        let option_provider = [];
        providers.map(provider => {
            if (provider.enabled_content_types.includes(product_type === 61 ? 6 : product_type)) {
                option_provider.push(provider);
            }
        });
        manual_providers.map(provider => {
            if (provider.available_content_types.includes(product_type === 61 ? 6 : product_type) && (provider.is_xml === undefined || quotation_code === 'cercledesvoyages') && (!provider.activation_countries || provider.activation_countries.length === 0 || provider.activation_countries.map(country => country.id).includes(data_trip?.destination?.data?.country?.id))) {
                option_provider.push(provider);
            }
        });
        if (provider_id !== null && option_provider.find(provider => provider.provider.id === provider_id.provider.id) !== undefined) {
            setProductProvider(provider_id !== null && option_provider.find(provider => provider.provider.id === provider_id.provider.id));
        }
        setProvidersOption(option_provider.sort((a, b) => a.provider.name < b.provider.name));
        setGroups(tmp_groups);
    }, []);
    useEffect(() => {
        setOpenStartHour(null);
    }, [start_hour]);
    useEffect(() => {
        setOpenEndHour(null);
    }, [end_hour]);

    useEffect(() => {
        if (description) {
            const styles = {
                ...RichEditorStyleMap,
                ...(quotation_code ? customColors[quotation_code] : null)
            };
            setDescriptionEditorState(
                restoreRichEditorStateFromHtml(
                    styles,
                    description
                )
            );
        }
    }, [description]);

    useEffect(() => {
        setGeolocation({
            address: (activity.place?.address?.length > 0 ?? 0) > 0 ?
                activity.place.address :
                activity.place?.city_name ?? '',
            position: {
                latitude: activity.place?.latitude ?? null,
                longitude: activity.place?.longitude ?? null
            }
        });
        dispatch({
            type: 'CART_SET_MANUAL_POI_TYPE',
            payload: activity.poi_type
        });
    }, [activity]);

    return (
        <>
            <Dialog
                open={open}
                maxWidth="md"
                onClose={onCloseModal}
                fullWidth
            >
                <DialogTitle>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography fontWeight="bold">
                            {t("poi.standalone_activity")}
                        </Typography>
                        <IconButton onClick={onCloseModal}>
                            <Close />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Grid
                        id={'cart-manual-product-form'}
                        className={classes.formContainer}
                        container
                    >
                        <Grid className={classes.gridContent} container item xs spacing={2}>
                            {/*NAME */}
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth
                                    variant={'outlined'}
                                    error={nameError}
                                >
                                    <InputLabel htmlFor={'custom-product-name'}>
                                        {t('cart-material.manual-product-name')}
                                    </InputLabel>
                                    <OutlinedInput
                                        id={'custom-product-name'}
                                        type={'text'}
                                        onChange={onNameChange}
                                        label={t('cart-material.manual-product-name')}
                                        value={name}
                                        disabled={true}
                                    />
                                    {nameError && (
                                        <FormHelperText>
                                            {t('cart-material.manual-product-no-name')}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {/*HOTEL */}
                            <Grid item xs={6}>
                                <Select
                                    className={classes.select}
                                    variant={'outlined'}
                                    value={product_type}
                                    onChange={onProductTypeChange}
                                    disabled={true}
                                >
                                    <MenuItem value={12}>
                                        {t('providers.activities')}
                                    </MenuItem>
                                    <MenuItem value={8}>
                                        {t('providers.insurances')}
                                    </MenuItem>
                                    {quotation_code !== 'verdie' && (
                                        <MenuItem value={11}>
                                            {t('providers.other_product')}
                                        </MenuItem>
                                    )}
                                    <MenuItem value={7}>
                                        {t('providers.accommodations')}
                                    </MenuItem>
                                    <MenuItem value={17}>
                                        {t('providers.travel_book')}
                                    </MenuItem>
                                    <MenuItem value={9}>{t('providers.cruises')}</MenuItem>
                                    <MenuItem value={1}>
                                        {t('providers.excursions')}
                                    </MenuItem>
                                    <MenuItem value={13}>{t('providers.ferries')}</MenuItem>
                                    <MenuItem value={16}>
                                        {t('providers.administration_fees')}
                                    </MenuItem>
                                    <MenuItem value={10}>
                                        {t('providers.touristic_guide')}
                                    </MenuItem>
                                    <MenuItem value={0}>{t('providers.hotels')}</MenuItem>
                                    <MenuItem value={18}>
                                        {t('providers.reduction')}
                                    </MenuItem>
                                    <MenuItem value={3}>
                                        {t('providers.restaurant')}
                                    </MenuItem>
                                    <MenuItem value={5}>{t('providers.trains')}</MenuItem>
                                    <MenuItem value={4}>
                                        {t('providers.transfers')}
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        {t('providers.rental_car')}
                                    </MenuItem>
                                    {/*<MenuItem value={ 6 }>{ t('providers.flight') }</MenuItem>*/}
                                    <MenuItem value={61}>
                                        {t('providers.manual_flight')}
                                    </MenuItem>
                                    {
                                        ((quotation_code === 'cercledesvoyages' ||
                                    quotation_code === 'continentsinsolites' || quotation_code === 'tropicalementvotre') &&
                                    manual_product_id === null
                                            ? manual_item_list.find(
                                                (item) => item.product_type === 18
                                            ) === undefined
                                            : manual_item_list.find(
                                                (item) => item.id === manual_product_id
                                            ) !== undefined) && (
                                            <MenuItem value={18}>
                                                {t('header.adjustment_product_name')}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </Grid>
                            {/*DESTINATION */}
                            <Grid item xs={6}>
                                <FormControl fullWidth error={stepError}>
                                    <Select
                                        variant={'outlined'}
                                        value={destination_associated}
                                        onChange={onDestinationAssociatedChange}
                                        disabled={true}
                                    >
                                        <MenuItem value={-1}>
                                            {t(
                                                'cart-material.manual-product-choose-destination'
                                            )}
                                        </MenuItem>
                                        {itinerary_list.map(
                                            (step) =>
                                                step.step_type === 'STEP' && (
                                                    <MenuItem
                                                        key={`manual-product-step-${step.id}`}
                                                        value={step.id}
                                                    >
                                                        {step.destination.data.name !==
                                            undefined
                                                            ? step.destination.data.name
                                                            : step.destination.data
                                                                .international_name}
                                                    </MenuItem>
                                                )
                                        )}
                                    </Select>
                                    {stepError && (
                                        <FormHelperText>
                                            {t(
                                                'cart-material.manual-product-no-destination-associated'
                                            )}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {
                                [12].includes(product_type) &&
                                <Grid item xs={12}>
                                    <FormControlLabel control={
                                        <Checkbox checked={ manual_product_poi } onChange={ handlePOI } name={ "activity-poi" } color={ "primary" }/>
                                    } label={ t("cart-material.exploration") }
                                    />
                                </Grid>
                            }
                            <Fragment>
                                <Grid item xs={ 12 }>
                                    <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ 'fr' }>
                                        <DateRangePicker
                                            startText={ t('accommodation.start_date') }
                                            endText={ t('accommodation.end_date') }
                                            value={ [(start_date !== null ? start_date : trip_start_date), (end_date !== null ? end_date : trip_end_date)] }
                                            onChange={ onSetDates }
                                            minDate={ trip_start_date }
                                            maxDate={ trip_end_date }
                                            calendars={ 2 }
                                            desktopModeMediaQuery={ '@media (min-width: 0px)' }
                                            renderInput={(startProps, endProps) => {
                                                startProps.helperText = undefined;
                                                startProps.fullWidth = true;
                                                endProps.helperText = undefined;
                                                endProps.fullWidth = true;
                                                return (
                                                    <Fragment>
                                                        <TextField {...startProps} error={ datesError }/>
                                                        <DateRangeDelimiter>{ t('accommodation.to') }</DateRangeDelimiter>
                                                        <TextField {...endProps} error={ datesError }/>
                                                    </Fragment>
                                                );
                                            }}
                                        />
                                    </LocalizationProvider>
                                    { datesError && <Typography className={ classes.datesErrorText }>{ t('cart-material.manual-product-no-dates') }</Typography> }
                                </Grid>
                                <Grid item xs={ 6 }>
                                    <FormControl fullWidth variant={ 'outlined' }>
                                        <InputLabel htmlFor={ 'manual-product-start-hour' }>{ t('cart-material.manual-product-start-hour') }</InputLabel>
                                        <OutlinedInput
                                            id={ 'manual-product-start-hour' }
                                            type={ 'text' }
                                            onClick={ onOpenStartHour }
                                            label={ t('cart-material.manual-product-start-hour') }
                                            value={ start_hour }
                                        />
                                    </FormControl>
                                    <Menu id={ 'manual-product-start-hour' } anchorEl={ openStartMenu } keepMounted open={ Boolean(openStartMenu) } onClose={ onCloseStartHour } disableScrollLock PaperProps={{ className: classes.menuHeight }}>
                                        { start_time }
                                    </Menu>
                                </Grid>
                                <Grid item xs={ 6 }>
                                    <FormControl fullWidth variant={ 'outlined' }>
                                        <InputLabel htmlFor={ 'manual-product-end-hour' }>{ t('cart-material.manual-product-end-hour') }</InputLabel>
                                        <OutlinedInput
                                            id={ 'manual-product-end-hour' }
                                            type={ 'text' }
                                            onClick={ onOpenEndHour }
                                            label={ t('cart-material.manual-product-end-hour') }
                                            value={ end_hour }
                                        />
                                    </FormControl>
                                    <Menu id={ 'manual-product-start-hour' } anchorEl={ openEndMenu } keepMounted open={ Boolean(openEndMenu) } onClose={ onCloseEndHour } disableScrollLock PaperProps={{ className: classes.menuHeight }}>
                                        { end_time }
                                    </Menu>
                                </Grid>
                            </Fragment>
                            <Grid item xs={12}>
                                <CartManualProductFormAddressPicker
                                    {...geolocation}
                                    errors={
                                        geolocationError ?
                                            {
                                                address: geolocation.address.trim().length === 0,
                                                position: {
                                                    latitude: !isNumber(geolocation.position.latitude),
                                                    longitude: !isNumber(geolocation.position.longitude)
                                                }
                                            } :
                                            undefined
                                    }
                                    onChangeLocation={onChangeGeolocation}
                                />
                            </Grid>
                            {/*CHOOSE PROVIDER */}
                            <Fragment>
                                {
                                    (
                                        quotation_code !== 'visiteurs' ||
                                        user?.client_full?.type !== 2
                                    ) &&
                                    <Grid item xs={12}>
                                        <FormControl
                                            fullWidth
                                            variant={'outlined'}
                                            error={providerError}
                                        >
                                            <Autocomplete
                                                id={"manual-product-choose-provider-label"}
                                                options={providersOption}
                                                value={ productProvider }
                                                getOptionLabel={(option) => `${option.provider.name}`}
                                                getOptionSelected={(option, value) => option.provider.id === value.provider.id}
                                                onChange={onProviderChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('cart-material.manual-product-choose-provider')}
                                                        margin={"normal"}
                                                        variant={"outlined"}
                                                        InputProps={{ ...params.InputProps, type: 'search' }}
                                                    />
                                                )}
                                            />
                                            {providerError && (
                                                <FormHelperText>
                                                    {t(
                                                        'cart-material.manual-product-no-provider-selected'
                                                    )}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    {
                                        ((quotation_code !== "cercledesvoyages" && user.client_full.type !== 2) || quotation_code === "visiteurs") && (
                                            <FormControl fullWidth variant={'outlined'}>
                                                <InputLabel htmlFor={'manual-product-provider'}>
                                                    {t(
                                                        'cart-material.manual-product-provider-name'
                                                    )}
                                                </InputLabel>
                                                <OutlinedInput
                                                    id={'manual-product-provider'}
                                                    type={'text'}
                                                    onChange={onProviderNameChange}
                                                    label={t(
                                                        'cart-material.manual-product-provider-name'
                                                    )}
                                                    value={provider_name}
                                                />
                                            </FormControl>
                                        )}
                                </Grid>
                            </Fragment>
                            <Grid item xs={ 12 }>
                                <div className={ clsx(classes.travelersContainer, { [classes.travelersError]: travelersError }) }>
                                    <Typography>{ t('cart-material.manual-product-travelers') } :</Typography>
                                    { travelers.map((traveler, index_traveler) => <FormControlLabel key={traveler.id} disabled={true} control={ <Checkbox checked={ groups.includes(traveler.id) } onChange={ toggleTraveler(traveler.id) }/> } label={ `${traveler.first_name === t('global.first_name') ? t('global.adult') : traveler.first_name} ${traveler.last_name === t('global.last_name') ? index_traveler + 1 : traveler.last_name}` }/>) }
                                </div>
                            </Grid>
                            <ActivityStandaloneProductFormPrice />
                            <Grid item xs={12} style={{ border: descriptionError ? '1px solid red' : undefined }}>
                                <Typography sx={{ marginTop: 2, marginBottom: 1 }}>
                                    {
                                        quotation_code !== "cercledesvoyages" &&
                                        quotation_code !== "continentsinsolites" &&
                                        quotation_code !== 'tropicalementvotre' ?
                                            t('cart-material.manual-product-description') :
                                            t('cart-material.manual-product-client-description')
                                    } :
                                </Typography>
                                <RichEditor
                                    values={{}}
                                    pictures={null}
                                    editorState={descriptionEditorState}
                                    setEditorState={setDescriptionEditorState}
                                    controlledState
                                />
                            </Grid>
                            {/*FRAIS - DEVISE - NOTE */}
                            <Grid item xs={12}>
                                <FormControl fullWidth variant={'outlined'}>
                                    <InputLabel htmlFor={'manual-product-note'}>
                                        {quotation_code !== 'cercledesvoyages' &&
                                        quotation_code !== 'continentsinsolites' && quotation_code !== 'tropicalementvotre'
                                            ? t('cart-material.manual-product-note')
                                            : t('cart-material.manual-product-intern-note')}
                                    </InputLabel>
                                    <OutlinedInput
                                        id={'manual-product-note'}
                                        type={'text'}
                                        rowsMax={Infinity}
                                        rows={3}
                                        multiline
                                        onChange={onNoteChange}
                                        label={
                                            quotation_code !== 'cercledesvoyages' &&
                                            quotation_code !== 'continentsinsolites' && quotation_code !== 'tropicalementvotre'
                                                ? t('cart-material.manual-product-note')
                                                : t(
                                                    'cart-material.manual-product-intern-note'
                                                )
                                        }
                                        value={note}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
                                <Typography fontWeight="bold" component="div" sx={{ marginBottom: 2 }}>
                                    {t('shared.circuit-step-image')}
                                </Typography>
                                {
                                    picture &&
                                    <Card sx={{ maxWidth: 300 }}>
                                        <CardMedia
                                            component={ 'img' }
                                            alt={picture.alt}
                                            height={200}
                                            image={picture.url ?? ''}
                                        />
                                        <CardContent>
                                            <Typography>{ picture.name }</Typography>
                                        </CardContent>
                                    </Card>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelManualProduct} disabled={loading}>
                        {t('global.cancel')}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={onCheckGroups}
                        disabled={loading}
                    >
                        {
                            loading &&
                            <CircularProgress size="20px" />
                        }
                        {
                            !loading &&
                            t('global.validate')
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ActivityStandaloneProductForm;
