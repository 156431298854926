//---- Dependencies ----//
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import CheckResponse from "../../Flight/FlightSelected/Functions/CheckResponse";

let allCart = 0;
let big_cart = [];

function redirect(setProduct) {
    if (allCart === 6) {
        let available_cart = [];
        for (let i = 0; i < big_cart.length; i++) {
            if (big_cart[i].is_available === undefined || big_cart[i].is_available === true) {
                if (big_cart[i].booking_status === null || (big_cart[i].booking_status !== undefined && big_cart[i].booking_status !== null && big_cart[i].booking_status.status_booking !== "CANCELLED")) {
                    available_cart.push(big_cart[i]);
                }
                if (big_cart[i].booking_status === undefined) {
                    available_cart.push(big_cart[i]);
                }
            }
        }
        setProduct(available_cart);
        // component.setState({
        //     cart: available_cart
        // })
    }
}

export default (id, id_version, token, setProduct) => {
    allCart = 0;
    big_cart = [];
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/accommodation/?token=${token}&full_content=true`,
            headers: headers,
            success: function (response) {
                let sort_accommodation = [];
                let data = response.slice();
                data.forEach(element => {
                    element.same_hotel = false;
                });
                data.map(item => {
                    let same = false;
                    sort_accommodation.map(sort_item => {
                        if (sort_item.variant === item.variant && item.is_displayed && sort_item.hotel[0].id === item.hotel[0].id && (sort_item.provider === item.provider || new_state.owner !== 2) && sort_item.start_date === item.start_date &&
                            sort_item.end_date === item.end_date && sort_item.on_request === item.on_request && sort_item.is_optional === item.is_optional &&
                            ((sort_item.booking_status === null && item.booking_status === null) || (sort_item.booking_status !== null && item.booking_status !== null &&
                                ((sort_item.booking_status.status_booking !== 'CANCELLED' && item.booking_status.status_booking !== 'CANCELLED' && sort_item.booking_process_state !== 'CANCEL' && item.booking_process_state !== 'CANCEL') ||
                                    (sort_item.booking_status.status_booking === 'CANCELLED' && item.booking_status.status_booking === 'CANCELLED'))))) {
                            
                        }
                        if (item.is_displayed && sort_item.hotel[0].id === item.hotel[0].id && (sort_item.provider === item.provider || new_state.owner !== 2) && sort_item.start_date === item.start_date &&
                            sort_item.end_date === item.end_date && sort_item.on_request === item.on_request && sort_item.is_optional === item.is_optional && 
                            ((sort_item.booking_status === null && item.booking_status === null) || (sort_item.booking_status !== null && item.booking_status !== null &&
                                ((sort_item.booking_status.status_booking !== 'CANCELLED' && item.booking_status.status_booking !== 'CANCELLED' && sort_item.booking_process_state !== 'CANCEL' && item.booking_process_state !== 'CANCEL') ||
                                (sort_item.booking_status.status_booking === 'CANCELLED' && item.booking_status.status_booking === 'CANCELLED'))))) {
                            if (sort_item.variant === item.variant) {
                                same = true;
                                sort_item.rooms.push(item);
                            }
                            else {
                                sort_item.same_hotel = true;
                                item.same_hotel = true;
                            }
                        }
                    });
                    if (!same) {
                        sort_accommodation.push({...item, rooms: [item]});
                    }
                });
                for (let i = 0; i < sort_accommodation.length; i++) {
                    sort_accommodation[i].is_manual = false;
                    sort_accommodation[i].is_custom = false;
                    sort_accommodation[i].hotel[0].dist = [];
                    sort_accommodation[i].hotel[0].sports = [];
                    sort_accommodation[i].hotel[0].meals = [];
                    sort_accommodation[i].hotel[0].room = [];
                    sort_accommodation[i].hotel[0].facility = [];
                    sort_accommodation[i].hotel[0].payment = [];
                    sort_accommodation[i].hotel[0].building = [];
                    for (let j = 0; j < sort_accommodation[i].hotel[0].facts.length; j++) {
                        if (sort_accommodation[i].hotel[0].facts[j].codename.includes("hoteltype") || sort_accommodation[i].hotel[0].facts[j].codename === "located_on_main_road" || sort_accommodation[i].hotel[0].facts[j].codename === "year_construction" || sort_accommodation[i].hotel[0].facts[j].codename === "year_renovation" || sort_accommodation[i].hotel[0].facts[j].codename === "annexe_buildings" || sort_accommodation[i].hotel[0].facts[j].codename === "num_floors_main" || sort_accommodation[i].hotel[0].facts[j].codename === "num_floors_annexe" || sort_accommodation[i].hotel[0].facts[j].codename === "m2_garden" || sort_accommodation[i].hotel[0].facts[j].codename === "m2_terrace" || sort_accommodation[i].hotel[0].facts[j].codename === "num_rooms_total" || sort_accommodation[i].hotel[0].facts[j].codename === "num_rooms_single" || sort_accommodation[i].hotel[0].facts[j].codename === "num_rooms_double" || sort_accommodation[i].hotel[0].facts[j].codename === "num_suites" || sort_accommodation[i].hotel[0].facts[j].codename === "num_apartments" || sort_accommodation[i].hotel[0].facts[j].codename === "num_junior_suites" || sort_accommodation[i].hotel[0].facts[j].codename === "num_studios" || sort_accommodation[i].hotel[0].facts[j].codename === "num_bungalows" || sort_accommodation[i].hotel[0].facts[j].codename === "num_villas") {
                            sort_accommodation[i].hotel[0].building.push(sort_accommodation[i].hotel[0].facts[j]);
                        }
                        else if (sort_accommodation[i].hotel[0].facts[j].codename.includes("dist")) {
                            sort_accommodation[i].hotel[0].dist.push(sort_accommodation[i].hotel[0].facts[j]);
                        } else if (sort_accommodation[i].hotel[0].facts[j].codename.includes("sports") && sort_accommodation[i].hotel[0].facts[j].codename !== "sports_numberofpools" && sort_accommodation[i].hotel[0].facts[j].codename !== "sports_poolheated" && sort_accommodation[i].hotel[0].facts[j].codename !== "sports_poolfreshwater" && sort_accommodation[i].hotel[0].facts[j].codename !== "sports_poolsaltwater" && sort_accommodation[i].hotel[0].facts[j].codename !== "sports_poolbar") {
                            sort_accommodation[i].hotel[0].sports.push(sort_accommodation[i].hotel[0].facts[j]);
                        } else if (sort_accommodation[i].hotel[0].facts[j].codename.includes("meals") && sort_accommodation[i].hotel[0].facts[j].codename !== "meals_specialoffers" && sort_accommodation[i].hotel[0].facts[j].codename !== "meals_lunchcarte" && sort_accommodation[i].hotel[0].facts[j].codename !== "meals_dinnerchoice") {
                            sort_accommodation[i].hotel[0].meals.push(sort_accommodation[i].hotel[0].facts[j]);
                        } else if (sort_accommodation[i].hotel[0].facts[j].codename.includes("room") && sort_accommodation[i].hotel[0].facts[j].codename !== "facility_roomservice" && sort_accommodation[i].hotel[0].facts[j].codename !== "facility_conferenceroom") {
                            sort_accommodation[i].hotel[0].room.push(sort_accommodation[i].hotel[0].facts[j]);
                        } else if (sort_accommodation[i].hotel[0].facts[j].codename.includes("facility")) {
                            sort_accommodation[i].hotel[0].facility.push(sort_accommodation[i].hotel[0].facts[j]);
                        } else if (sort_accommodation[i].hotel[0].facts[j].codename.includes("beach")) {
                            sort_accommodation[i].hotel[0].facility.push(sort_accommodation[i].hotel[0].facts[j]);
                        } else if (sort_accommodation[i].hotel[0].facts[j].codename.includes("payment")) {
                            sort_accommodation[i].hotel[0].payment.push(sort_accommodation[i].hotel[0].facts[j]);
                        }
                    }
                    console.log('sort_accommodation:', sort_accommodation);
                    big_cart.push(sort_accommodation[i]);
                }
                allCart++;
                redirect(setProduct);
            },
            error: function (error) {
                console.log(error.responseJSON);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/manual-products/?token=${token}`,
            headers: headers,
            success: function (data) {
                console.log('manual:', data);
                for (let i = 0; i < data.length; i++) {
                    data[i].is_manual = true;
                    data[i].is_custom = false;
                    big_cart.push(data[i]);
                }
                allCart++;
                redirect(setProduct);
            },
            error: function (error) {
                console.log(error.responseJSON);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/custom-products/?token=${token}`,
            headers: headers,
            success: function (data) {
                console.log('manual:', data);
                for (let i = 0; i < data.length; i++) {
                    data[i].is_manual = false;
                    data[i].is_custom = true;
                    big_cart.push(data[i]);
                }
                allCart++;
                redirect(setProduct);
            },
            error: function (error) {
                console.log(error.responseJSON);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/transfers/?token=${token}`,
            headers: headers,
            success: function (data) {
                console.log('manual:', data);
                for (let i = 0; i < data.length; i++) {
                    data[i].is_manual = false;
                    data[i].is_custom = false;
                    big_cart.push(data[i]);
                }
                allCart++;
                redirect(setProduct);
            },
            error: function (error) {
                console.log(error.responseJSON);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/car/?token=${token}`,
            headers: headers,
            success: function (data) {
                console.log('cars:', data);
                for (let i = 0; i < data.length; i++) {
                    data[i].is_manual = false;
                    data[i].is_custom = false;
                    big_cart.push(data[i]);
                }
                allCart++;
                redirect(setProduct);
            },
            error: function (error) {
                console.log(error.responseJSON);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${id_version}/flight/?token=${token}`,
            headers: headers,
            success: function (data) {
                console.log('flight:', data);
                let arr = CheckResponse(data);
                for (let i = 0; i < arr.length; i++) {
                    arr[i].is_manual = false;
                    arr[i].is_custom = false;
                    big_cart.push(arr[i]);
                }
                allCart++;
                redirect(setProduct);
            },
            error: function (error) {
                console.log(error.responseJSON);
            }
        });
    }
};
