import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import RoomIcon from '@material-ui/icons/Room';
import HotelIcon from '@material-ui/icons/Hotel';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import DirectionsBoatOutlinedIcon from '@material-ui/icons/DirectionsBoatOutlined';
import TrainOutlinedIcon from '@material-ui/icons/TrainOutlined';
import axios from "axios";
import grey from "@material-ui/core/colors/grey";

import { SetPortTimeWarning } from "../../../Actions/Transfers";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetAccommodationSegments from "./GetAccommodationSegments";
import i18n from "../../../i18n";

const useStyles = makeStyles(() => ({
    formControlRoot: {
	    "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(14px, 13px) scale(1)'
	    },
	    "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    },
	    "& .mui-jss-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
	    	padding: '0'
	    },
	    "& .mui-jss-MuiInputBase-root.mui-jss-MuiOutlinedInput-root.mui-jss-MuiAutocomplete-inputRoot.mui-jss-MuiInputBase-fullWidth.mui-jss-MuiInputBase-formControl.mui-jss-MuiInputBase-adornedEnd.mui-jss-MuiOutlinedInput-adornedEnd": {
	    	paddingRight: '10px'
	    }
  	},
    formControlRoot1: {
	    "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(14px, 20px) scale(1)'
	    },
	    "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    },
	    "& .mui-jss-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
	    	padding: '0'
	    },
	    "& .mui-jss-MuiInputBase-root.mui-jss-MuiOutlinedInput-root.mui-jss-MuiAutocomplete-inputRoot.mui-jss-MuiInputBase-fullWidth.mui-jss-MuiInputBase-formControl.mui-jss-MuiInputBase-adornedEnd.mui-jss-MuiOutlinedInput-adornedEnd": {
	    	paddingRight: '10px'
	    },
	    "& .mui-jss-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .mui-jss-MuiAutocomplete-input": {
	    	padding: '17px 4px'
	    }
  	},
  	inputBorder1: {
  		"&& .mui-jss-MuiOutlinedInput-root": {
  			borderRadius: '8px 0px 0px 8px',
  			borderRight: '0'
  		}
  	},
  	inputBorder2: {
  		"& .mui-jss-MuiOutlinedInput-root": {
  			borderRadius: '0'
  		}
  	},
  	inputBorder3: {
  		borderRadius: '8px'
  	},
  	inputBorder4: {
  		"&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: '0px 8px 8px 0px'
  		}
  	},
  	greyColor: {
  		color: grey[500],
  		marginLeft: '4px'
  	},
    noBorderLeft: {
        borderLeft: "none"
    },
    noBorderRight: {
        borderRight: "none"
    },
    noBorder: {
        borderRight: "none",
        borderLeft: "none"
    },
    backgroundWhite: {
        backgroundColor: 'white'
    }
}));

let source = null;

const PopperMy = function (props) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return (<Popper {...props} style={{ width: matches ? 500 : 300 }} placement={'bottom-start'} />);
};

const DestinationInput = ({ label, search, setSearch, type, transfers_list, error, setError, setTransferList }) => {
    const classes = useStyles();
    // const borderStyle = inputStyle();
    const theme = useTheme();
    const dispatch = useDispatch();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between(768, 1024));
    const { t } = useTranslation();
    const { origin_name, dest_name, origin_index } = search;

    const locales = useSelector(state => state.user.locales);
    const accommodation_cart = useSelector(state => state.accommodation.cart);
    const providers = useSelector(state => state.trip.providers);
    const manual_providers = useSelector(state => state.trip.manual_providers);
    const list_ports = useSelector(state => state.transfers.list_ports);
    const list_stations = useSelector(state => state.transfers.list_stations);

    const [inputValue, setInputValue] = useState('');
  	const [loading, setLoading] = useState(false);
  	const [open, setOpen] = useState(false);

    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });
    const type_def = {
        1: 'hotels',
        2: 'airports',
        3: 'destinations',
        4: 'ports',
        5: 'stations'
    };
    useEffect(() => {
        if (inputValue.length >= 3 && open) {
            getDestinationData(inputValue);
        }
    }, [inputValue]);
  	let options = transfers_list.sort((a, b) => {
        return a.type - b.type;
    });
    console.log('options:', options);
    const getDestinationData = (newInputValue) => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            if (newInputValue.length >= 3) {
                if (source !== null) {
                    source.cancel('Operation canceled by the user.');
                }
                //Save the cancel token for the current request
                source = axios.CancelToken.source();
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/location/deep_search/`,
                    params: {
                        query: newInputValue
                    },
                    cancelToken: source.token
                }).then(function (response) {
                    let data = response.data;
                    if (data.locations.length > 0) {
                        let new_list = GetAccommodationSegments(accommodation_cart, providers, manual_providers, data.locations);
				       	setTransferList(new_list);
                    }
                }).catch(function (error) {
                    if (!axios.isCancel(error)) {
                        console.log('Request canceled', error.message);
                        setError(true);
                    }
					   //TODO: show snackbar error
				   });
            }
        }
    };
    return (
	    <Autocomplete
	        freeSolo
	        disabled={type !== "origin" && origin_name === ''}
	        onOpen={() => {
		        setOpen(true);
	      	}}
	        onClose={() => {
	        	setLoading(false);
	        	setOpen(false);
	      	}}
	        onChange={(event, newValue) => {
	        	if (newValue !== "" && newValue !== null) {
		            let new_search = Object.assign({}, search);
		            if (type === "origin") {
			            new_search.origin_name = newValue.origin_name;
                        new_search.origin_type = newValue.type;
                        new_search.origin_id = newValue.start_destination !== undefined ? newValue.start_destination[0] : newValue.origin_dest.id;
                        new_search.country_code = newValue.country_code;
                        new_search.origin_location_code = newValue.location_code_pickup;
                        // new_search.dest_type = "";
                        dispatch(SetPortTimeWarning(false));
		            } else {
		            	new_search.dest_name = newValue.origin_name;
                        new_search.dest_type = newValue.type;
                        new_search.dest_id = newValue.start_destination !== undefined ? newValue.start_destination[0] : newValue.origin_dest.id;
                        new_search.dest_country_code = newValue.country_code;
                        new_search.dest_location_code = newValue.location_code_pickup;
                        if (newValue.type === 4) {
                            dispatch(SetPortTimeWarning(true));
                        } else {
                            dispatch(SetPortTimeWarning(false));
                        }
		            }
                    setTransferList([]);
		            setSearch(new_search);
		            setError(false);
	        	}
	        }}
            filterOptions={(options) => options}
	        onInputChange={(event, newInputValue) => {
                if (open) {
                    setLoading(true);
                    setInputValue(newInputValue);
                }
            }}
            onBlur={() => {
                setTransferList([]);
                setInputValue('');
            }}
	      	selectOnFocus
  	      	clearOnBlur
	        value={type === "origin" ? origin_name : dest_name}
	        className={matches ? (type === "origin" ? classes.inputBorder1 : (isTablet ? classes.inputBorder4 : classes.inputBorder2)) : classes.inputBorder3}
	        options={options}
            groupBy={(option) => t(`transfers.${type_def[option.type]}`)}
	        getOptionLabel={
	        	(option) => {
                    if (option === undefined || option === "") {
                        return "";
                    }
	        		return typeof option === "string" ? option : option.origin_name;
	        	}
	        }
	        renderOption={option => {
               	return (
                   	<Fragment>
                       	<IconButton>
                           	{
                           		option.type === 1 && (
                           			<HotelIcon/>
                           		)
                           	}
                           	{
                           		option.type === 2 && type === "origin" && (
                           			<FlightLandIcon/>
                           		)
                           	}
                           	{
                           		option.type === 2 && type !== "origin" && (
                           			<FlightTakeoffIcon/>
                           		)
                           	}
                           	{
                           		option.type === 3 && (
                           			<LocationCityIcon/>
                           		)
                           	}
                            {
                                option.type === 4 && (
                                    <DirectionsBoatOutlinedIcon/>
                                )
                            }
                            {
                                option.type === 5 && (
                                    <TrainOutlinedIcon/>
                                )
                            }
                       	</IconButton>
                       	{option.origin_name}
                   	</Fragment>
                );
           	}}
	        renderInput={(params) => (
	            <TextField
	            	{...params}
	            	label={label}
	            	InputProps={{
  		            	...params.InputProps,
  		            	endAdornment: (
  		              		<Fragment>
  		                		{
  		                			loading ? <CircularProgress color="inherit" size={20} /> : null
  		                		}
  		              		</Fragment>
  		            	),
                        classes:{notchedOutline: type === "origin" ? ((matches || isTablet) ? classes.noBorderRight : "") : (matches ? (isTablet ? classes.noBorderLeft : classes.noBorder) : "")}
  		            }}
	            	className={matches ? classes.formControlRoot : classes.formControlRoot1}
	            	variant="outlined"
	            	error={type === "origin" ? (error) : (origin_index !== null && error)}
                    style={{ backgroundColor: 'white', borderRadius: 4 }}
	            	helperText={type === "origin" ? (error ? t('global.empty_check') : "") : (origin_index !== null && error ? t('global.empty_check') : "")}
	            />
	        )}
            PopperComponent={PopperMy}
	    />
    );
};
export default DestinationInput;
