import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    onChange: (place: google.maps.places.PlaceResult | null) => void
}

export function ItineraryGooglePlaceAutocomplete(props: Props): JSX.Element {
    const map = useSelector((state: AppState) => state.itinerarySlice.map);
    const ref = useRef<HTMLInputElement>(null);
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

    useEffect(() => {
        if (map && ref.current) {
            const autocomplete = new google.maps.places.Autocomplete(ref.current);
            setAutocomplete(autocomplete);
        }
    }, [map, ref.current]);

    useEffect(() => {
        if (autocomplete) {
            google.maps.event.clearInstanceListeners(autocomplete);
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (!place.geometry?.location) {
                    props.onChange(null);
                } else {
                    props.onChange(place);
                }
            });
        }
    }, [autocomplete, props.onChange]);

    return (
        <Input ref={ref} type="text" />
    );
}

const Input = styled('input')((props) => ({
    width: 200,
    maxWidth: '100%',
    fontSize: 12,
    border: '1px solid #000',
    marginTop: props.theme.spacing(0.5),
    paddingLeft: props.theme.spacing(1),
    paddingRight: props.theme.spacing(1),
    borderRadius: props.theme.shape.borderRadius
}));
