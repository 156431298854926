import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
    Box,
    Paper
} from "@mui/material";
import { CartConstructionContentsForm } from "./CartConstructionContentsForm";
import { CartConstructionContentsHeader } from "./CartConstructionContentsHeader";
import { CartConstructionContentsRoadbookForm } from "./CartConstructionContentsRoadbookForm";
import { CartConstructionContentsContext } from "./utils/cartConstructionContentsContext";
import GetCookie from "../Common/Functions/GetCookie";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    stickedActions?: (content: React.ReactNode) => React.ReactNode,
    extraStickedActions?: () => React.ReactNode,
    stickyOffset?: number,
    minimal?: boolean
}

export function CartConstructionContents(props: Props): JSX.Element {
    const textsView = useSelector((state: AppState) => state.cartConstruction.textsView);
    const [locale, setLocale] = useState(1);
    const context = useMemo((): Parameters<typeof CartConstructionContentsContext.Provider>[0]['value'] => {
        const versionString = GetCookie("trip_id_version");
        const version = versionString ? parseInt(versionString) : -1;
        return { mode: 'text', version };
    }, []);

    return (
        <Paper
            elevation={0}
            sx={{
                paddingBottom: 2.5,
                backgroundColor: '#fff'
            }}
        >
            <CartConstructionContentsHeader
                stickedActions={props.stickedActions}
                extraStickedActions={props.extraStickedActions}
                stickyOffset={props.stickyOffset}
                minimal={props.minimal}
            />
            <Box sx={{ display: textsView === 'quotation' ? 'block' : 'none' }}>
                <CartConstructionContentsContext.Provider value={context}>
                    <CartConstructionContentsForm
                        locale={locale}
                        stickyOffset={props.stickyOffset}
                        onChangeLocale={setLocale}
                    />
                </CartConstructionContentsContext.Provider>
            </Box>
            {
                !props.minimal &&
                textsView === 'roadbook' &&
                <CartConstructionContentsRoadbookForm />
            }
        </Paper>
    );
}
