import React, { Component } from 'react';

class customInputIcon extends React.Component {
    render() {
        return (
            <div>
                {
                    this.props.custom_icon && (
                        <i className={ "fa fa-plane" }/>
                    )
                }
                <i className={ "icon-calendar" }/>
            </div>
        );
    }
}

export default customInputIcon;