//---- Dependencies ----//
import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

//---- Common ----//

//---- Component ----//

//---- Functions ----//
import FilterCars from '../Functions/FilterCars'
//---- Actions ----//
import { DisplayCars, SetFilters, ChangeFilter } from "../../../Actions/CarsSearch";

//---- CSS ----//
import "../../../Style/CarsSearch.css";
import GetProvider from "../../Common/Functions/GetProvider";

@connect((store) => {
    return {
    	filters_cars: store.cars_search.filters_cars,
    	selected_category: store.cars_search.selected_category,
    	selected_provider: store.cars_search.selected_provider,
    	cars_categories: store.cars_search.cars_categories,
    	cars_results: store.cars_search.cars_results,
    	filters: store.cars_search.filters,
		provider_list: store.trip.providers,
    }
})
@withTranslation()
export default class CarsFilters extends React.Component {
	componentWillMount() {
		this.InitFilters();
	}
	componentDidUpdate(prevProps) {
		const { filters_cars, filters, dispatch, t } = this.props;
		if (prevProps.filters_cars !== filters_cars)
			this.InitFilters();
		if (prevProps.filters !== filters)
			FilterCars(filters_cars, filters, dispatch, t);
	}
	InitFilters() {
		const { provider_list, dispatch, filters_cars, selected_provider, selected_category, cars_categories, cars_results } = this.props;
		let filters = {};
		const cars_type = ["2/4 Door", "Passenger Van", "SUV", "4-5 Door"];
		if (selected_category === null) {
			let categories = [];
			cars_categories.map((category) => {
				categories.push({
					name: category.name,
					checked: false
				})
			});
			filters.categories = categories;
		}
		if (selected_provider === null) {
			let tmp_provider = [];
			cars_results.map((provider) => {
				let recommended = false;
				let active_provider = GetProvider(provider_list, provider.provider_id);
				for (let i = 0; i < active_provider.configuration.extra_params.length; i++) {
					if (active_provider.configuration.extra_params[i].key === "car_preferred" && active_provider.configuration.extra_params[i].value === "1") {
						recommended = true;
					}
				}
				tmp_provider.push({
					name: provider.provider_name === provider.subprovider_name ? provider.provider_name : provider.subprovider_name,
					checked: false,
					id: provider.provider_id,
					subprovider_name: provider.subprovider_name,
					recommended: recommended
				})
			});
			filters.provider = tmp_provider;
		}
		filters.passenger = [{
			name: "1-5 passengers",
			checked: false
		},
		{
			name: "> 5 passengers",
			checked: false
		}];
		filters.options = [{
			name: "air_condition",
			checked: false
		},
		{
			name: "automatic",
			checked: false
		}];
		// filters.cars_type = cars_type.map((name) => {
		// 	return ({
		// 		name : name,
		// 		checked: false
		// 	})
		// })
		dispatch(SetFilters(filters));
	}
	render() {
		const { filters, dispatch, t } = this.props;
		const config = JSON.parse(localStorage.getItem("config"));
		let stamp_recommended = null;
		if (config !== null && config.stamp_recommended !== undefined && config.stamp_recommended !== null && config.stamp_recommended.url !== undefined && config.stamp_recommended.url !== null) {
			stamp_recommended = config.stamp_recommended.url;
		}
		const arr = [t("cars.filter.categories"), t("cars.filter.cars_type"), t("cars.filter.provider")];
		return (
			<div className={ "ft-basic-card" }>
			{
				Object.keys(filters).map((filter, index) => {
					return(
						<div key={index} className={"cars-filter-category"}>
							<div className={ "cars-filter-title" }>
								{t("cars.filter." + filter)}
							</div>
							<div className={ "cars-filter ft-relative" }>
								{
									filters[filter].map((elem, elem_index) => {
										if (!(JSON.parse(localStorage.getItem("config")).quotation_code === "marcovasco" && elem.name === "economy")) {
											return (
												<label key={elem_index} className={ "flight-search-checkbox" }>
													{ elem.recommended !== undefined && elem.recommended && <img src={ stamp_recommended } height={ 20 } width={ 20 } style={{marginRight: "5px"}}/> }
													{!arr.includes(t("cars.filter." + filter)) ? t("cars.filter." + elem.name) : elem.name}
													<input type={ "checkbox" } checked={ elem.checked } value={elem.name} onChange={() => {dispatch(ChangeFilter(filter, elem_index, !elem.checked))}}/>
													<span className={ "flight-search-check-mark-cars" }/>
												</label>
											);
										}
									})
								}
							</div>
						</div>
					)
				})
			}
			</div>
		);
	}
}