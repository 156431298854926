//---- Dependencies ----//
import React from "react";
import { withTranslation } from "react-i18next";

@withTranslation()
export default class Base extends React.Component {
    constructor(props, context) {
        super(props, context);
    }
    render() {
        return (
            <div id={ "wrapper" }>
            </div>
        );
    }
};