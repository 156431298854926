import React from "react";
import { connect } from "react-redux";
import { WithRouterProps, withRouter } from "react-router";
import { WithTranslation, withTranslation } from "react-i18next";
import { SaveRequestsQueue } from "../Itinerary/utils/saveRequestsQueue";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    route: any
    fetchingTexts: boolean,
    recreatingTextInputs: boolean,
    syncingTexts: boolean
} & WithTranslation & WithRouterProps

export const SavingRouteBlock = withRouter(
    withTranslation()(
        connect((state: AppState) => ({
            fetchingTexts: state.cartConstruction.fetchingTexts,
            recreatingTextInputs: state.cartConstruction.recreatingTextInputs,
            syncingTexts: state.cartConstruction.syncingTexts
        }))(
            class ItinerarySavingRouteBlock extends React.Component<Props> {
                private isDataSaved(): boolean {
                    return !SaveRequestsQueue.hasPending();
                }

                public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
                    window.onbeforeunload = () => {
                        if (!this.isDataSaved()) {
                            return nextProps.t('itinerary.unsaved-data');
                        }
                    };
                    return false;
                }

                public componentDidMount() {
                    this.props.router.setRouteLeaveHook(
                        this.props.route,
                        this.routerWillLeave.bind(this)
                    );
                }

                public routerWillLeave() {
                    if (
                        !this.isDataSaved() ||
                        this.props.fetchingTexts ||
                        this.props.recreatingTextInputs ||
                        this.props.syncingTexts
                    ) {
                        return this.props.t('itinerary.unsaved-data');
                    }
                }

                public render() {
                    return null;
                }
            }
        )
    )
);
