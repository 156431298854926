import React, { useState, useEffect, Fragment, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from "notistack";
//import { withRouter } from 'react-router';

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { CircularProgress } from '@mui/material';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import AcUnitOutlinedIcon from '@material-ui/icons/AcUnitOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import Typography from "@material-ui/core/Typography";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from "@material-ui/lab/Alert";

import DoorIcon from "../CarsMaterial/DoorIcon.js";

//---- CSS ----//
import "../../Style/FlightGroups.css";
import "../../Style/FlightSearch.css";
import "../../Style/CarsSearch.css";
import "../../Style/Poi.css";

//---- Common ----//
import CustomOverlay from "../Common/CustomOverlay.jsx";
import Notify from "../Common/Notify";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";

//---- Funcitons ----//
import { CartConstructionReplaceProductContext } from '../CartMaterial/utils/cartConstructionReplaceProductContext';
import GetPricesId from "../Cart/Functions/Margin/GetPricesId.js";

//---- Actions ----//
import { AddToCart } from "../../Actions/Transfers";
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    gridTop1: {
	    marginTop: theme.spacing(2)
    },
    gridBottom: {
        marginBottom: theme.spacing(2)
    },
    carCardInfo: {
        display: 'inline-block',
        marginRight: 11
    },
    carCardInfoFlex: {
        display: 'inline-flex',
        marginRight: 11
    },
    font14: {
        fontSize: 14
    },
    font24: {
        fontSize: 24
    },
    font34: {
        fontSize: 34
    },
    ftBlack: {
        color: 'black'
    },
    ftOrange: {
        color: '#e6592f'
    },
    ftGray: {
        opacity: '54%'
    },
    bold: {
        fontWeight: 'bold'
    },
    providerLogo: {
        width: 100,
        float: "right"
    },
    smallProviderLogo: {
        width: 100
    },
    similarCar: {
        textTransform: 'uppercase',
        fontWeight: 900,
        fontSize: 16,
        opacity: '54%'
    },
    textCenter: {
        textAlign: 'center'
    },
    textUppercase: {
        textTransform: 'uppercase'
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    pointer: {
        cursor: 'pointer'
    },
    providerName: {
        textAlign: "center",
        fontWeight: "bold"
    },
    bottomAlign: {
        verticalAlign: "bottom"
    },
    formControlRoot: {
        "& .mui-jss-MuiInputLabel-outlined": {
            transform: 'translate(14px, 13px) scale(1)'
        },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },
        "& .mui-jss-MuiOutlinedInput-input": {
            //padding: '9.5px 35px'
            paddingTop: '9.5px',
		    paddingBottom: '9.5px',
		    paddingRight: '26px'
        }
    },
    description: {
        color: '#e6592f',
        border: '1px solid #e6592f',
        margin: '10px 0px'
    },
    ftMt5: {
        marginTop: 5
    },
    ftMt15: {
        marginTop: 15
    }
}));

const VehicleRender = ({ card_index, card, transfers_list, search, groups, router, display_vehicles, setCardDescription, setOpenDescription, setCardCancellation, setOpenCancellation }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const downDesktop = useMediaQuery('(min-width:960px)');
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const replaceProductContext = useContext(CartConstructionReplaceProductContext);

    const { pass_check, headers } = CheckBeforeRequest();

    const currency_list = useSelector((store) => {
        return store.base.currency_list;
    });
    const traveler_groups = useSelector((store) => {
    	return store.trip.traveler_groups;
    });
    const language = useSelector((store) => {
    	return store.header.tmp_language;
    });
    const user = useSelector((store) => {
    	return store.user.user;
    });
    const port_time_warning = useSelector((store) => {
        return store.transfers.port_time_warning;
    });

    const [pic_car_height, setPicCarHeight] = useState(0);
    const [product_quantity, setProductQuantity] = useState(1);

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    function addToCart() {
        let origin_hour = "";
        if (search.origin_hour === t("global.noon")) {
            origin_hour = "12:00";
        } else if (search.origin_hour === t("global.midnight")) {
            origin_hour = "00:00";
        } else {
            origin_hour = search.origin_hour.replace("h", ":");
        }
        let arrival_date = moment(search.start_date).format("YYYY-MM-DD") + "T" + origin_hour;
        let request = {
            trip: GetCookie("trip_id"),
            identifier: card.identifier,
            comps: [groups[0].id],
            provider_id: card.provider_id,
            arrival_date: arrival_date,
            product_quantity: product_quantity,
            // start_destination: search.origin_type !== 'airport' ? search.origin_id : search.dest_id,
            // end_destination: search.dest_type !== 'airport' ? search.dest_id : search.origin_id
        };
        const type_def = {
            1: 'accommodation',
            2: 'airport',
            3: 'city',
            4: 'port',
            5: 'station'
        };
        if (!card.custom_provider) {
		 	request.location_code_pickup = search.origin_location_code !== null ? search.origin_location_code : undefined;
	    	request.location_code_return = search.dest_location_code !== null ? search.dest_location_code : undefined;
            request.pickup_type = type_def[search.origin_type] === "accommodation" ? "Hotel" : type_def[search.origin_type];
            request.return_type = type_def[search.dest_type] === "accommodation" ? "Hotel" : type_def[search.dest_type];
		    request.country_code = search.country_code;
            request.custom_provider = false;
        } else {
            request.custom_provider = true;
            request.product_identifiers = [card.id];
        }
        if (pass_check) {
            setLoading(true);
            $.ajax({
			    method: "POST",
			    headers: headers,
			    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/transfers/`,
			    data: JSON.stringify(request),
			    success: function (data) {
			    	let tmp = [];
			    	tmp.push(data);
                    if (replaceProductContext.enableReplace && replaceProductContext.onDelete) {
                        replaceProductContext.onDelete();
                    }
			    	dispatch(AddToCart(tmp));
			    	if (search.is_return) {
			    		let return_hour = "";
			    		if (search.return_hour === t("global.noon")) {
			    			return_hour = "12:00";
			    		} else if (search.return_hour === t("global.midnight")) {
			    			return_hour = "00:00";
			    		} else {
			    			return_hour = search.return_hour.replace("h", ":");
			    		}
			    		let request1 = {
			    			trip: GetCookie("trip_id"),
			    			identifier: card.identifier,
			    			comps: [groups[0].id],
			    			is_return: true,
			    			provider_id: card.provider_id,
			    			arrival_date: moment(search.end_date).format("YYYY-MM-DD") + "T" + return_hour,
			    			product_quantity: product_quantity,
                            start_destination: search.dest_type !== 'airport' ? search.dest_id : search.origin_id,
                            end_destination: search.origin_type !== 'airport' ? search.origin_id : search.dest_id
			    		};
		    			if (!card.custom_provider) {
		    			 	request1.location_code_return = search.origin_location_code !== null ? search.origin_location_code : undefined;
		    		    	request1.location_code_pickup = search.dest_location_code !== null ? search.dest_location_code : undefined;
		    			    request1.pickup_type = type_def[search.dest_type] === "accommodation" ? "Hotel" : type_def[search.dest_type];
		    				request1.return_type = type_def[search.origin_type] === "accommodation" ? "Hotel" : type_def[search.origin_type];
		    			    request1.country_code = search.country_code;
		    				request1.custom_provider = false;
		    			} else {
		    				request1.custom_provider = true;
		    				request1.product_identifiers = [card.id];
		    			}
			    		$.ajax({
			    		    method: "POST",
			    		    headers: headers,
			    		    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/transfers/`,
			    		    data: JSON.stringify(request1),
			    		    success: function (data1) {
			    		    	let tmp1 = [];
			    		    	tmp1.push(data1);
			    		    	dispatch(AddToCart(tmp1));
                                if (!replaceProductContext.enableReplace) {
                                    dispatch({
                                        type: "CART_SET_STEP",
                                        payload: {
                                            step: 1
                                        }
                                    });
                                    router.push(`/${window.url_name}/apps/cart`);
                                } else if (replaceProductContext.onClose) {
                                    replaceProductContext.onClose();
                                }
                                setLoading(false);
			    		    },
			    		    error: function (error) {
			    		        console.log(error);
                                setLoading(false);
			    		    }
			    		});
			    	} else if (!replaceProductContext.enableReplace) {
                        dispatch({
                            type: "CART_SET_STEP",
                            payload: {
                                step: 1
                            }
                        });
                        router.push(`/${window.url_name}/apps/cart`);
                    } else if (replaceProductContext.onClose) {
                        replaceProductContext.onClose();
                    }

                    if (!search.is_return) {
                        setLoading(false);
                    }
			    	Notify(t("transfers.post_success"), { status: 'success', pos: 'top-center' });
			    },
			    error: function (error) {
			        console.log(error);
                    if (!!error.responseJSON.detail && (error.responseJSON.detail.includes("204") || error.responseJSON.detail === "No matching transfer could be found for this itinerary.")) {
                        enqueueSnackbar(t("transfers.not_available_anymore"), { variant: "error", disableWindowBlurListener: true });
                    }
                    setLoading(false);
			    }
            });
        }
    }
    if (card.price !== null) {
        let tmp_price = parseFloat(card.price[GetPricesId(card.price, user ? user.client : null, user)].selling_price);
        let currency = currency_list.find((currency) => {
            return currency.id === card.price[GetPricesId(card.price, user ? user.client : null, user, true)].selling_currency;
        });
        let price = new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(tmp_price) * product_quantity);
        let quantity_arr = [];
        for (let i = 1; i <= 20; i++) {
            quantity_arr.push(i);
        }

        if (replaceProductContext.enableReplace && replaceProductContext.mode === 'edit') {
            replaceProductContext.onRegisterNextEvent(addToCart);
        }

  		return (
   			<Paper key={card_index} elevation={0} style={{
                width: '100%',
                border: '1px solid rgba(0, 0, 0, 0.2)'
            }}>
   				<Grid container spacing={2}>
   					{
   						!matches && card.provider_logo !== null && (quotation_code === "verdie" || (quotation_code !== "verdie" && user.client_full.type !== 2)) && (
   							<Grid item xs={12}>
                                <img src={ card.provider_logo } className={classes.smallProviderLogo} />
   							</Grid>
   						)
   					}
   					<Grid item container sm={3} xs={12} justify={"center"} alignItems={"center"}>
   						<img src={ (card.pict_url !== null ? card.pict_url : "/Img/accommodation_default.jpg") } className={"car-card-img" + (pic_car_height < 230 ? " cars-small-pic" : "")} onLoad={(event) => {
                            setPicCarHeight(event.target.height);
                        }}/>
   					</Grid>
   					<Grid item sm={1}/>
                    <Grid item sm={5} xs={12}>
	   					<Grid container spacing={0}>
	   						<Grid item container xs={12} className={classes.gridBottom}>
                                <Grid item xs={12} className={ clsx({
                                    [classes.font24]: true,
                                    [classes.ftBlack]: true,
                                    [classes.bold]: true
                                }) }>
                                    {t("cars.categories." + ((card.type).toLowerCase()).replaceAll(" ", "_")) }
                                </Grid>
                                <Grid item xs={12} className={ classes.similarCar}>
                                    {t("global.caracteristics") + " : " + card.name}
                                </Grid>
                                <Grid item xs={12}>
                                    <span>{card.pickup_location}</span>
                                    {
                                        (card.is_meet_connect === undefined || (card.is_meet_connect !== undefined && !card.is_meet_connect)) && (card.is_meet_greet === undefined || (card.is_meet_greet !== undefined && !card.is_meet_greet)) && card.dropoff_location !== "" && (
                                            <Fragment>
                                                <TrendingFlatIcon className={classes.bottomAlign}/>
                                                <span>{card.dropoff_location}</span>
                                            </Fragment>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        card.doors !== null && (
                                            <div className={ classes.carCardInfoFlex }>
                                                <Tooltip title={t("tooltip.door_nbr")} placement={ "bottom" }>
                                                    <div>
                                                        <DoorIcon fontSize={"small"} style={{ marginRight: 2, verticalAlign: 'bottom', opacity: '54%' }}/>
                                                    </div>
                                                </Tooltip>
									    	<span className={ clsx({ [classes.ftGray]: true, [classes.font14]: true })} style={{ paddingTop: '3px', paddingLeft: '4px' }}>{card.doors}</span>
								    	</div>
                                        )
                                    }
                                    {
                                        card.max_capacity !== null && (
                                            <div className={ classes.carCardInfo }>
                                                <Tooltip title={t("flight_groups.passengers_max")} placement={ "bottom" }>
                                                    <PermIdentityIcon style={{ marginRight: 2, verticalAlign: 'bottom', opacity: '54%' }}/>
                                                </Tooltip>
									    	<span className={ clsx({ [classes.ftGray]: true, [classes.font14]: true })}>{card.max_capacity}</span>
                                            </div>

                                        )
                                    }
                                    {
                                        card.baggage !== null && (
                                            <div className={ classes.carCardInfo } style={{ marginLeft: '-2px' }}>
                                                <Tooltip title={t("global.max_luggages")} placement={ "bottom" }>
                                                    <WorkOutlineIcon style={{ marginRight: 2, verticalAlign: 'bottom', opacity: '54%' }}/>
                                                </Tooltip>
									    	<span className={ clsx({ [classes.ftGray]: true, [classes.font14]: true })}>{card.baggage}</span>
                                            </div>

                                        )
                                    }
                                    {
								    card.air_conditioner !== undefined && card.air_conditioner !== null && (
								        <div className={ classes.carCardInfo }>
							    			<Tooltip title={t("accommodation.equipment.air_conditioning")} placement={ "bottom" }>
							    				<AcUnitOutlinedIcon style={{ marginRight: 2, verticalAlign: 'bottom', opacity: '54%' }}/>
							    			</Tooltip>
							    		</div>

								    )
                                    }
                                    {
                                        card.duration !== undefined && card.duration !== null && (
                                            <div className={ classes.carCardInfo } style={{ marginLeft: '-2px' }}>
                                                <Tooltip title={t("duration.title")} placement={ "bottom" }>
                                                    <QueryBuilderOutlinedIcon style={{ marginRight: 2, verticalAlign: 'bottom', opacity: '54%' }}/>
                                                </Tooltip>
									    	<span className={ clsx({ [classes.ftGray]: true, [classes.font14]: true })}>{(parseInt(card.duration / 60) > 0 ? parseInt(card.duration / 60) + "h" : "")}{(card.duration % 60 > 0 ? card.duration % 60 + "min" : "")}</span>
                                            </div>
                                        )
                                    }

                                </Grid>
                            </Grid>
                            {
                                card.pickup_time !== undefined && card.pickup_time !== null && (
                                    <Grid item xs={12} style={{ fontSize: 15 }}>
                                        <div className={ classes.carCardInfo } style={{ marginLeft: '-2px' }}>
                                            <Tooltip title={t("transfers.pickup_time")} placement={ "bottom" }>
                                                <EventOutlinedIcon style={{ marginRight: 2, verticalAlign: 'bottom', opacity: '74%' }}/>
                                            </Tooltip>
                                            <span>{moment(card.pickup_time).format("DD MMMM YYYY - HH:mm")}</span>
                                        </div>
                                    </Grid>
                                )
                            }
                            {
                                card.pickup_check_url !== undefined && card.pickup_check_url !== null && card.hours_before_check !== undefined && card.hours_before_check !== null && (
                                    <Grid item xs={12} style={{ fontSize: 15 }}>
                                        <div>
                                            {t("transfers.check_pickup")} <a href={card.pickup_check_url}>{card.pickup_check_url}</a>
                                        </div>
                                        <div>
                                            {t("transfers.after")} {card.hours_before_check} {t("global.hours")}
                                        </div>
                                    </Grid>
                                )
                            }
                            {
                                card.customer_transfer_info !== null && card.customer_transfer_info.length !== 0 && (
                                    <Grid item xs={12} style={{ fontSize: 15 }}>
                                        <QueryBuilderOutlinedIcon style={{ marginRight: 5, verticalAlign: 'bottom', fontSize: 20 }}/>
                                        {
                                            card.customer_transfer_info[0].value === 0 ? t("transfers.no_waiting_time") : t("transfers.waiting_time_customer") + card.customer_transfer_info[0].value + " " + t("global." + card.customer_transfer_info[0].metric)
                                        }
                                    </Grid>
                                )
                            }
                            {
                                card.supplier_transfer_info !== null && card.supplier_transfer_info.length !== 0 && (
                                    <Grid item container xs={12} style={{ fontSize: 15 }}>
                                        <QueryBuilderOutlinedIcon style={{ marginRight: 5, verticalAlign: 'bottom', fontSize: 20 }}/>
                                        {
                                            card.supplier_transfer_info.map((info, info_index) => {
                                                let domestic = (info.type.toLowerCase()).includes("domestic");
                                                return (
                                                    <div key={info_index}>
                                                        {t("transfers.supplier_max_waiting_time") + info.value + " " + info.metric + " (" + (domestic ? t("transfers.domestic") : t("transfers.international")) + ")"}
                                                    </div>
                                                );
                                            })
                                        }
                                    </Grid>
                                )
                            }
                            {
                                card.cancellation !== undefined && card.cancellation !== null && card.cancellation.length !== 0 && (
                                    <Grid item container xs={12} style={{ fontSize: 15 }} alignItems={"center"}>
                                        <span style={{ verticalAlign: 'text-bottom' }}>{t("global.cancelable")}</span>
                                        <Tooltip title={t("transfers.more_information")} placement={"bottom"}>
                                            <IconButton size={'small'} color={"inherit"} onClick={() => {
                                                let cancellation_rules = {
                                                    rules: card.cancellation,
                                                    currency_list: currency_list
                                                };
                                                setCardCancellation(cancellation_rules);
                                                setOpenCancellation(true);
                                            }}>
                                                <ErrorOutlineOutlinedIcon style={{ verticalAlign: "bottom", fontSize: 20 }}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                )
                            }
                            {
                                card.description !== null && (
                                    <Grid item container xs={12}>
                                        <Button variant={"outlined"} className={classes.description} onClick={() => {
                                            let split_description = card.description.split("*");
                                            let description = {
                                                name: t("cars.categories." + ((card.type).toLowerCase()).replaceAll(" ", "_")) + " " + card.name,
                                                split_description: split_description,
                                                customer_transfer_info: card.customer_transfer_info,
                                                supplier_transfer_info: card.supplier_transfer_info
                                            };
                                            setCardDescription(description);
                                            setOpenDescription(true);
                                        }}>
                                            {t("transfers.more_information")}
                                        </Button>
                                    </Grid>
                                )
                            }
                            {
                                port_time_warning && card.provider_name === "Hotel Beds" && (
                                    <Alert severity={ "warning" } variant={ "filled" }>
                                        { t("transfers.requested_port_time_card_warning")}
                                    </Alert>
                                )
                            }
                        </Grid>
   					</Grid>
   					{
   						matches && (
   							<Grid item sm={3}>
   							{
   								card.provider_logo !== null && user.client_full.type !== 2 && (
   									<img src={ card.provider_logo } className={classes.providerLogo} />
   								)
   							}
   							{
   								card.provider_logo === null && user.client_full.type !== 2 && (
   									<Typography className={classes.providerName}>{card.provider_name}</Typography>
   								)
   							}
   							</Grid>
   						)
   					}
   				</Grid>
   				<Grid container spacing={2} direction={"row-reverse"}>
   					<Grid item container sm={3} xs={12} direction={"column-reverse"} justify={"center"} alignItems={"flex-end"}>
                        {
                            (
                                !replaceProductContext.enableReplace ||
                                replaceProductContext.mode === 'replace' ||
                                replaceProductContext.mode === 'add'
                            ) &&
                            <Grid item className={ clsx({ [classes.ftMt15]: true })}>
                                <Button variant={ "contained" } className={clsx({ [classes.orangeButton]: true, [classes.textUppercase]: true })} onClick={() => addToCart()} >
                                    {
                                        loading &&
                                        <CircularProgress size="20px" sx={{ color: '#fff' }} />
                                    }
                                    {t("accommodation.validate_cart") }
                                </Button>
                            </Grid>
                        }
                        <Grid item className={ clsx({ [classes.ftMt5]: true })}>
                            {t("accommodation.for") + " " + groups[0].travelers.length + " " + t("global.person_short")}
                        </Grid>
                        <Grid item >
                            <div className={clsx({ [classes.bold]: true, [classes.font34]: true })}>
                                {price}
                            </div>
                        </Grid>
                        <Grid item>
                            <FormControl variant={"outlined"} className={classes.formControlRoot} fullWidth>
						       	<InputLabel>{t("transfers.quantity")}</InputLabel>
						       	<Select
						         	labelId="quantity-select-label"
						         	id="quantity-select"
						         	value={product_quantity}
						         	onChange={(e) => {
                                        setProductQuantity(e.target.value);
                                    }}
						         	label={t("transfers.quantity")}
						       	>
						       	{
						       		quantity_arr.map((quantity) => {
						       			return (
								         	<MenuItem key={quantity} value={quantity}>{quantity}</MenuItem>
						       			);
						       		})
						       	}
						       	</Select>
					     	</FormControl>
                        </Grid>
                    </Grid>
                    {
                        card_index !== display_vehicles.length - 1 && (
		   					<Grid item xs={12}>
                                <hr/>
                            </Grid>
                        )
                    }
   				</Grid>
   			</Paper>
  		);
    }
};
export default React.memo(VehicleRender);
