//Dependencies
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useSelector } from 'react-redux';
//Core
import { Box } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
//Components
import { CartAccommodationCard } from "./CartAccommodationCard";
import { useUnpackagedProducts } from "./utils/unpackagedProduts";

const useStyles = makeStyles({
    title: {
        fontSize: 24,
        fontWeight: 700
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    marginBottom: {
        marginBottom: 15
    }
});

const CartAccommodations = () => {
    const unpackagedProducts = useUnpackagedProducts();

    if (unpackagedProducts.accommodations.length > 0) {
        return (
            <div>
                <TitleWrapper />
                <hr/>
                {
                    unpackagedProducts.accommodations.map((item) => {
                        if (item.type === 'manual') {
                            return (
                                <Grid key={ `manual-product-${item.accommodation.id}` } id={ `manual-product-${item.accommodation.id}` }>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            marginBottom: 3
                                        }}
                                    >
                                        <CartAccommodationCard
                                            type="manual"
                                            accommodation={ item.accommodation }
                                        />
                                    </Box>
                                </Grid>
                            );
                        }
                        return (
                            <Grid key={ `accommodation-${item.accommodation.id}` } container>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            marginBottom: 3
                                        }}
                                    >
                                        <CartAccommodationCard
                                            type="accommodation"
                                            accommodation={ item.accommodation }
                                        />
                                    </Box>
                                </Grid>
                                {/*<Grid className={ classes.marginBottom } item xs={ 12 }/> */}
                            </Grid>
                        );
                    })
                }
            </div>
        );
    }
    return null;
};

function TitleWrapper() {
    const classes = useStyles();
    const { t } = useTranslation();
    const language = useSelector(state => state.header.tmp_language);
    const total_accommodations = useSelector(state => state.cart.total_accommodations);
    const unpackagedProducts = useUnpackagedProducts();

    return (
        <Typography className={ classes.title }>
            { t('cart-material.total-accommodation') } ({unpackagedProducts.accommodations.length}) :{' '}
            {
                total_accommodations.map((currency_cost, currency_index) => {
                    return `${currency_index > 0 ? ' + ' : ''}${currency_cost.cost.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}${currency_cost.currency.symbol}`;
                })
            }
        </Typography>
    );
}

export default CartAccommodations;
