import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { useTranslation } from "react-i18next";

const AccommodationAddToCart = ({open, onClose, onValidate, setPassLength, pass_length, already_in_cart, add_directly_to_variant}) => {
    console.log('open:', open);
    const { t } = useTranslation();
    if (!pass_length) {
        return (
            <Dialog open={ open } fullWidth maxWidth={ "md" } onClose={ onClose } disableEscapeKeyDown>
                <DialogTitle>{ t("accommodation.validate_cart") }</DialogTitle>
                <DialogContent>
                    <DialogContentText>{ t("accommodation.not_all_room_added") }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { if(!already_in_cart && !add_directly_to_variant) { onValidate(false, false, false); } else { setPassLength(true); } }} color={ "primary" }>{ t("accommodation.add") }</Button>
                    <Button onClick={ onClose } color={ "primary" }>{ t("accommodation.cancel") }</Button>
                </DialogActions>
            </Dialog>
        );
    } else if (already_in_cart) {
        return (
            <Dialog open={ open } fullWidth maxWidth={ "md" } onClose={ onClose } disableEscapeKeyDown>
                <DialogTitle>{ t("accommodation.validate_cart") }</DialogTitle>
                <DialogContent>
                    <DialogContentText>{ t("accommodation.replace_or_option_or_variant") }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { onValidate(true, false, false);} } color={ "primary" }>{ t("accommodation.replace") }</Button>
                    <Button onClick={() => { onValidate(false, true, false);} } color={ "primary" }>{ t("accommodation.add_option") }</Button>
                    {
                        add_directly_to_variant && (
                            <Button onClick={() => { onValidate(false, false, true);} } color={ "primary" }>{ t("accommodation.add_variant") }</Button>
                        )
                    }
                    <Button onClick={ onClose } color={ "primary" }>{ t("accommodation.cancel") }</Button>
                </DialogActions>
            </Dialog>
        );
    }
    return null;
};

export default React.memo(AccommodationAddToCart);