import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { List, AutoSizer, WindowScroller, CellMeasurer , CellMeasurerCache } from "react-virtualized";
import Grid from '@material-ui/core/Grid';

import VehicleRender from './VehicleRender.jsx';

// let cache = new CellMeasurerCache({
// 	fixedWidth: true,
// 	defaultHeight: 305,
//   	keyMapper: () => ""
// });
class GenerateCars extends Component {
	constructor(props, context) {
		super(props, context);
		this.cache = new CellMeasurerCache({
			fixedWidth: true,
		  	defaultHeight: 326,
		  	// keyMapper: () => 1,
		});
	    this.renderRow = this.renderRow.bind(this);
	}
	componentWillReceiveProps() { //Really important !!
		this.cache.clearAll(); //Clear the cache if row heights are recompute to be sure there are no "blank spaces" (some row are erased)
		this.virtualizedList && this.virtualizedList.recomputeRowHeights();
	}
	renderRow({ index, parent, key, style }) {
		const { display_vehicles, search, transfers_list, groups, router } = this.props;
		return (
			<CellMeasurer
				key={key}
				cache={this.cache}
				parent={parent}
				columnIndex={0}
				rowIndex={index}
			>
				{
					({ measure }) => {
						return (
							<div key={key} style={style} onLoad={measure}>
								<VehicleRender
									card={parent.props.data[index]}
									card_index={index}
									search={search}
									transfers_list={transfers_list}
									groups={groups}
									router={router}
									display_vehicles={display_vehicles}
								/>
							</div>
						);
					}
				}
			</CellMeasurer>
		);
	}
	render() {
		const { display_vehicles } = this.props;
		return (
			<WindowScroller> 
				{
					({ height, isScrolling, onChildScroll, registerChild, scrollTop }) => {
						return (
							<div ref={registerChild}>
								<AutoSizer disableHeight>
						        {({ width }) => {
									return (
										<List
											autoHeight
											rowCount={display_vehicles.length}
											isScrolling={isScrolling}
											width={width}
											height={height}
											scrollTop={scrollTop}
											deferredMeasurementCache={this.cache}
											rowHeight={this.cache.rowHeight}
											rowRenderer={this.renderRow}
											data={display_vehicles}
											onScroll={onChildScroll}
											overscan={10}
										/>
									);
					        	}}
					        	</AutoSizer>
							</div>
						)
					}
				}
			</WindowScroller>
		);
	}
}
export default GenerateCars;
