//---- Dependencies ----//
import React from 'react';
import { connect } from "react-redux";
import { Link, History } from "react-router";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

//---- Functions ----//
import MapRun from "./Functions/MapRun.js";

//---- Components ----//

//---- Actions ----//

//---- CSS ----//
import "../../Style/ItineraryType.css";
import "../../Style/GlobalAgencies.css";

let itinerary_markers = [];
let segments_drawn = [];


//---- Class ----//
@connect((store) => {
    return {
        itinerary_type_details_data: store.itinerary_type.itinerary_type_details_data,
        itinerary: store.itinerary_type.itinerary
    };
})
@withTranslation()
@withRouter
class ItineraryTypeDetailsMap extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        	map: null,
        	google: null
        };
    }

    componentDidUpdate(prevProps) {
        const { contentRef } = this.props;
        const itinerary = this.props.itinerary ?? this.props.itinerary_data ?? null;
        let { map, google } = this.state;
        if (map === null) {
            if (itinerary !== null && itinerary.length > 0) {
                MapRun(this, itinerary[0].step_type === 'STEP' ? itinerary[0].destination.parent.data : itinerary[1].destination.parent.data);
            }
        } else {
            if (prevProps.itinerary !== itinerary) {
                for (let i = 0; i < itinerary_markers.length; i++) {
                    itinerary_markers[i].setVisible(false);
                }
                for (let i = 0; i < segments_drawn.length; i++) {
                    segments_drawn[i].polyline.setVisible(false);
                }
                for (let i = 0; i < itinerary_markers.length; i++) {
                    itinerary_markers[i].setMap(null);
                }
                itinerary_markers = [];
            }

            if (this.state.google !== null && this.state.map !== null && itinerary.length > 0) {
                let first_iti = null;
                google.maps.event.addListenerOnce(map, 'idle', () => {
                    let bounds = new google.maps.LatLngBounds();
                    let destination_zoom_tmp = [];
                    let itinerary_to_add = [];
                    for (let i = 0; i < itinerary.length; i++) {
                        if (itinerary[i].step_type === "STEP") {
                            if (first_iti === null) {
                                first_iti = itinerary[i];
                            }
                            destination_zoom_tmp.push(itinerary[i]);
                            itinerary_to_add.push(itinerary[i]);
                            bounds.extend(new google.maps.LatLng(parseFloat(itinerary[i].destination.data.latitude), parseFloat(itinerary[i].destination.data.longitude)));
                        }
                    }
                    this.state.map.fitBounds(bounds);
                    for (let i = 0; i < itinerary_to_add.length; i++) {
                        if (i !== (itinerary_to_add.length - 1) && Object.keys(itinerary_to_add[i].r2r_json).length !== 0 && itinerary_to_add[i].r2r_json.segments !== undefined) {
                            for (let k = 0; k < itinerary_to_add[i].r2r_json.segments.length; k++) {
                                let route = [];
                                if (itinerary_to_add[i].r2r_json.data.vehicles[itinerary_to_add[i].r2r_json.segments[k].vehicle].kind === "plane") {
                                    route = [
                                        new google.maps.LatLng(itinerary_to_add[i].r2r_json.data.places[itinerary_to_add[i].r2r_json.segments[k].depPlace].lat, itinerary_to_add[i].r2r_json.data.places[itinerary_to_add[i].r2r_json.segments[k].depPlace].lng),
                                        new google.maps.LatLng(itinerary_to_add[i].r2r_json.data.places[itinerary_to_add[i].r2r_json.segments[k].arrPlace].lat, itinerary_to_add[i].r2r_json.data.places[itinerary_to_add[i].r2r_json.segments[k].arrPlace].lng) //arrival
                                    ];
                                } else {
                                    let encoded = itinerary_to_add[i].r2r_json.segments[k].path;
                                    let index = 0;
                                    let len = encoded.length;
                                    let lat = 0;
                                    let lng = 0;
                                    while (index < len) {
                                        let b;
                                        let shift = 0;
                                        let result = 0;
                                        do {
                                            b = encoded.charAt(index++).charCodeAt(0) - 63;
                                            result |= (b & 0x1f) << shift;
                                            shift += 5;
                                        } while (b >= 0x20);
                                        lat += ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));
                                        shift = 0;
                                        result = 0;
                                        do {
                                            b = encoded.charAt(index++).charCodeAt(0) - 63;
                                            result |= (b & 0x1f) << shift;
                                            shift += 5;
                                        } while (b >= 0x20);
                                        lng += ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));
                                        route.push(new google.maps.LatLng((lat / 1E5), (lng / 1E5)));
                                    }
                                }
                                let strokeColor = "#000";
                                if (itinerary_to_add[i].r2r_json.data.vehicles[itinerary_to_add[i].r2r_json.segments[k].vehicle].kind === "plane") {
                                    strokeColor = "#06CCA9";
                                } else if (itinerary_to_add[i].r2r_json.data.vehicles[itinerary_to_add[i].r2r_json.segments[k].vehicle].kind === "train") {
                                    strokeColor = "#6B4C85";
                                } else if (itinerary_to_add[i].r2r_json.data.vehicles[itinerary_to_add[i].r2r_json.segments[k].vehicle].kind === "car") {
                                    strokeColor = "#606060";
                                } else if (itinerary_to_add[i].r2r_json.data.vehicles[itinerary_to_add[i].r2r_json.segments[k].vehicle].kind === "bus") {
                                    strokeColor = "#D97A39";
                                } else if (itinerary_to_add[i].r2r_json.data.vehicles[itinerary_to_add[i].r2r_json.segments[k].vehicle].kind === "ferry") {
                                    strokeColor = "#55B7D1";
                                }
                                let polyline = new google.maps.Polyline({
                                    map: this.state.map,
                                    path: route,
                                    strokeColor: strokeColor,
                                    strokeOpacity: 1,
                                    strokeWeight: 6,
                                    geodesic: itinerary_to_add[i].r2r_json.data.vehicles[itinerary_to_add[i].r2r_json.segments[k].vehicle].kind === "plane"
                                });
                                segments_drawn.push({
                                    id: itinerary_to_add[i].id,
                                    selected_transport: itinerary_to_add[i].selected_transport,
                                    polyline: polyline
                                });
                                polyline.setMap(this.state.map);
                            }
                        }
                        let to_render = true;
                        let is_repeat = false;
                        for (let j = 0; j < itinerary.length; j++) {
                            if (itinerary[j].destination !== null && itinerary_to_add[i].destination !== null) {
                                if (itinerary[j].id !== itinerary_to_add[i].id) {
                                    if (itinerary[j].destination.id === itinerary_to_add[i].destination.id) {
                                        is_repeat = true;
                                    }
                                }
                            }
                        }
                        if (to_render === true) {
                            let marker_option = (is_repeat !== true) ? {
                                position: new google.maps.LatLng(parseFloat(itinerary_to_add[i].destination.data.latitude), parseFloat(itinerary_to_add[i].destination.data.longitude)),
                                label: {
                                    text: (i + 1).toString(),
                                    color: "#76B6C2",
                                    fontWeight: "bold"
                                },
                                map: this.state.map,
                                clickable: true,
                                icon: {
                                    url: "/Img/Map/pin.png",
                                    labelOrigin: new google.maps.Point(15, 15)
                                }
                            } : {
                                position: new google.maps.LatLng(parseFloat(itinerary_to_add[i].destination.data.latitude), parseFloat(itinerary_to_add[i].destination.data.longitude)),
                                map: this.state.map,
                                icon: {
                                    url: "/Img/Map/repeat.png"
                                }
                            };
                            let marker = new google.maps.Marker(marker_option);
                            marker.addListener("click", () => {
                                console.log('Je clique:', itinerary[i]);
                                console.log('contentRef:', contentRef.current[i + 1]);
                                contentRef.current[i + 1].scrollIntoView({
                                    behavior: "smooth",
                                    block: "nearest",
                                    inline: "start"
                                });
                            });
                            marker.setMap(map);
                            itinerary_markers.push(marker);
                        }
                    }
                });
                this.state.map.setZoom(map.getZoom());
            }
        }
    }

    componentDidMount() {
        const { itinerary } = this.props;
        const { map } = this.state;
        if (map === null && itinerary !== null && itinerary.length > 0 && itinerary.find((iti) => iti.step_type === 'STEP') !== undefined) {
            MapRun(this, itinerary[0].step_type === 'STEP' ? itinerary[0].destination.parent.data : itinerary[1].destination.parent.data);
        }
    }

    render() {
        return (
            <div id={ "map-itinerary-type" }/>
        );
    }
}

export default ItineraryTypeDetailsMap;
