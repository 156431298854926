import axios from "axios";
import memoize from "memoizee";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { LightDestination } from "../objects/lightDestination";

async function find(destinationId: number, isUserTO: boolean): Promise<LightDestination[]> {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        try {
            const response = await axios.get<LightDestination[]>(
                `${API_HREF}client/${window.id_owner}/destinations/quick_search/`,
                {
                    headers,
                    params: {
                        limit: 10,
                        ordering: 'current_version__type',
                        parent: destinationId,
                        visibility__in: isUserTO ?
                            'PUBLIC,PRIVATE_TO' :
                            'PUBLIC'
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    return [];
}

export const findDestinationChildren = memoize(
    find,
    {
        promise: true,
        normalizer(args) {
            return args[0].toString() + '-' + (args[1] ? 'true' : 'false');
        }
    }
);
