import React from "react";
import AccommodationStandaloneProductForm from "./AccommodationStandaloneProductForm";

const AccommodationCardStandaloneHotel = ({
    open,
    onClose,
    detail,
    current_groups,
    start_date,
    end_date,
    destination,
    standaloneSuccess
}) => {
    if (!open) {
        return null;
    }
    return (
        <AccommodationStandaloneProductForm
            open={open}
            start_date_init={start_date}
            end_date_init={end_date}
            detail={detail}
            current_groups={current_groups}
            destination={destination}
            onClose={onClose}
            standaloneSuccess={standaloneSuccess}
        />
    );
};

export default React.memo(AccommodationCardStandaloneHotel);
