import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { AppState } from "../../../Reducers/Reducers";
import { Flight } from '../../Itinerary/network/flight';
import GetOptions from "../Functions/GetOptions";
import GetSeats from "../Functions/GetSeats.ts";
import { CartProductCardProps } from "../../CartMaterial/CartProductCard";
import FlightProductCard from "./FlightProductCard";
import { SelectFlight } from "../../../Actions/FlightSearch.js";
import { CartConstructionReplaceProductContext } from "../../CartMaterial/utils/cartConstructionReplaceProductContext";



type Props = ({
    flight: Flight,
    searched_group: any,
    flight_index: number,
    router: any,
    measure?: any
}) & Pick<CartProductCardProps, 'alwaysOpen'>

const FlightCardContainer = React.memo(function (props: Props): (JSX.Element | null) {
    const { flight, flight_index, searched_group, alwaysOpen, router, } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
    const user = useSelector((state: AppState) => state.user.user);
    const end_date = useSelector(store => store.trip.end_date);
    const trip_id = useSelector(store => store.trip.trip_id);
    const tripversion_id = useSelector(store => store.trip.data_trip.id);
    const trip_currency = useSelector(store => store.trip.currency);
    const flight_providers = useSelector(store => store.flight.provider_list);
    const airlines = useSelector((state: AppState) => state.flight_search.airlines);
    const airports = useSelector((state: AppState) => state.flight_search.airports);
    const option_loaded = useSelector((state: AppState) => state.flight_search.option_loaded);
    const stations = useSelector((state: AppState) => state.flight_search.stations);
    const trace_id = useSelector((state: AppState) => state.flight_search.trace_id);
    const replaceProductContext = useContext(CartConstructionReplaceProductContext);

    const [optionLoad, setOptionLoad] = useState(false);
    const [seatsLoad, setSeatsLoad] = useState(false);
    const provider = useMemo(() => {
        return providers.concat(manualProviders).find((item) => {
            return item.provider.id === flight.provider_id;
        })?.provider;
    }, [flight, providers, manualProviders]);
    const [selecting, setSelecting] = useState(false);
    const onShowPriceDetail = () => {
        dispatch({ type: 'CART_TOGGLE_FLIGHT_PRICE_DETAIL', payload: flight });
    };
    useEffect(() => {   
        if (props.measure !== undefined) {
            props.measure;
        }
    }, [flight.id]);
    useEffect(() => {
        if (selecting) {
            dispatch(SelectFlight(flight.id));
            GetOptions(flight, flight_providers, flight_index, searched_group, trip_id, tripversion_id, trip_currency, trace_id, airlines, airports, stations, end_date, dispatch, setSelecting, user, setOptionLoad);
            GetSeats(flight, searched_group, trip_currency.iso_code, trace_id, airlines, airports, stations, end_date, dispatch, setSeatsLoad);
        }
    }, [selecting]);
    useEffect(() => {
        // if (optionLoad) {
        if (optionLoad && seatsLoad && !replaceProductContext.enableReplace) {
            setSelecting(false);
            setOptionLoad(false);
            router.push(`/${window.url_name}/apps/selected-flight`);
        }
        else if (optionLoad && replaceProductContext.enableReplace) {
            replaceProductContext.onNextStep();
        }
    }, [optionLoad, seatsLoad]);
    return (
        <FlightProductCard
            direction="vertical"
            type="flight"
            flight={flight}
            searched_group={searched_group}
            provider={provider}
            selecting={selecting}
            setSelecting={setSelecting}
            flight_index={flight_index}
            selected_flight={router.getCurrentLocation().pathname.includes("/selected-flight")}
        />
    );
});

export default FlightCardContainer;
