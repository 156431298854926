// Dependencies
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Core & Lab
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Box, FormGroup, TextField, Tooltip, Typography, makeStyles } from '@material-ui/core';

const useStyle = makeStyles({
    label: {
        width: "100%",
    },
  });

const CartAssistanceOptions = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyle();
    const assistance_option_pocket_wifi = useSelector(state => state.cart.assistance_option_pocket_wifi);
    const pocket_wifi_pickup = useSelector(state => state.cart.pocket_wifi_pickup);
    const pocket_wifi_physical_address = useSelector(state => state.cart.pocket_wifi_physical_address);

    const onToggleAssistanceOption = () => {
        dispatch({type: 'CART_SET_ASSISTANCE_OPTION_POCKET_WIFI', payload: !assistance_option_pocket_wifi});
    };
    const Label = (
        <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        className={classes.label}
        >
            <Typography>{t('cart-material.assistance-pocket-wifi')}</Typography>
        </Box>
    );
    const handlePickupChange = (event) => {
        dispatch({type: 'CART_SET_ASSISTANCE_OPTION_POCKET_WIFI_PICKUP', payload: event.target.name});
    };
    const handlePocketWifiPhysicalAddressChange = (event) => {
        dispatch({type: 'CART_SET_ASSISTANCE_OPTION_POCKET_WIFI_PHYSICAL_ADDRESS', payload: event.target.value});
    };
    return (
        <Fragment>
            <FormControl fullWidth>
                <FormControlLabel control={ <Checkbox checked={ assistance_option_pocket_wifi } onChange={ onToggleAssistanceOption } color={ 'primary' }/> } label={ Label }/>
            </FormControl>
            {
                assistance_option_pocket_wifi &&
                <FormControl fullWidth>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox checked={pocket_wifi_pickup === "Airport"} onChange={handlePickupChange} name="Airport" />}
                            label="Airport pickup"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={pocket_wifi_pickup === "Physical"} onChange={handlePickupChange} name="Physical" />}
                            label="Physical address"
                        />
                    </FormGroup>
                </FormControl>
            }
            {
                assistance_option_pocket_wifi && pocket_wifi_pickup === "Physical" &&
                <FormControl fullWidth>
                    <TextField
                        id={"outlined-basic"}
                        label={"Physical address"}
                        variant={"outlined"}
                        value={pocket_wifi_physical_address}
                        onChange={handlePocketWifiPhysicalAddressChange}
                    />
                </FormControl>
            }
        </Fragment>
    );
};

export default CartAssistanceOptions;