//---- Dependencies ----//
import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router';

//---- Actions ----//
import { SetPoiCart } from "../../../Actions/Summary";

//---- Component ----//
import MoreInfoPoi from "../../Cart/Poi/MoreInfoPoi.jsx";
import CustomOverlay from "../../Common/CustomOverlay.jsx";
import SummaryPicturesModal from "../../SummaryQuotation/Summary/SummaryPicturesModal.jsx";
import CustomInfoModal from "../../SummaryQuotation/Summary/CustomInfoModal.jsx";

//---- Functions ----//
import { TogleInfoCustomAndSetStep, ToggleAndSetPicturesModal } from "../../../Actions/Summary";

//----, user CSS ----//
import "../../../Style/Poi.css";
import moment from "moment";

let rand_id = Math.random();

@connect((store) => {
    return {
        poi_cart: store.summary.poi_cart
    };
})
@withTranslation()
@withRouter
export default class SummaryCustomProducts extends React.Component {
	constructor(props, context) {
	    super(props, context);
	    this.state = {
	    	pic_height: 0,
	    	pic_width: 0,
			edit_booking: false,
			new_item_reference: null,
			new_booking_status: null,
			edit_booking_index: null,
			edit_poi_price: null,
			open: false,
			long_description: "",


			currency_list: [],
			custom_rate_type: "PER",

			currency: null,

			input_focus: 0,

			price: null,
			selling_price: null,
			selling_price_no_margin: 0,
			margin_value: null,
			margin_percent: null,
			index: 0
	    };
	}
	render() {
		const { is_from_itiType, poi_cart, step, dispatch, user, t, router, component } = this.props;
		return (
			<div>
				{
                    poi_cart.map((poi_product, index_poi_product) => {
                    	let product = null;
                        if ((moment.utc(poi_product.start_date).isAfter(moment.utc(step.start_date)) || moment.utc(poi_product.start_date).isSame(moment.utc(step.start_date), "d")) && (moment.utc(poi_product.end_date).isBefore(moment.utc(step.end_date)) || moment.utc(poi_product.end_date).isSame(moment.utc(step.end_date), "d"))) {
                           product = poi_product;
                        }
						let owner_id = user ? user.client : null;
						if (product !== null) {
						    return (
							<div key={index_poi_product} className={"step-hotel-main-container" + " " + "green" }>
							    <div className={"icon-step-transport-container" + " " + "green" } >
							        <i className={"ft-contain-img icon-step-transport-icon icon-" + "064-hiking" } />
							    </div>
							    <div className={"step-hotel-title-container"}>
							        <span className={"ft-dark-green bold"}>{ product.custom_product.title } </span>
							        <div className={ "italic ft-light-green ft-font-13" }>
							        	{ is_from_itiType === false && (
							        		<Fragment>
							        			<i className={ "icon-075-calendar pr10" }/><span className={ "pr10" }>{ moment.utc(product.start_date).format("DD-MM-YYYY") }</span>
							        		</Fragment>
							        	) }
							        	<i className={ "fa fa-clock-o poi-icon" }/> <span className={ "pr10" }>{ moment.utc(product.hour, "HH:mm").format("HH:mm") }</span> { t("poi.duration") + " : " }
							        		{ (Math.floor(product.custom_product.duration/1440) > 0 ? `${Math.floor(product.custom_product.duration/1440)} ${(Math.floor(product.custom_product.duration/1440) > 1 ? t("poi.days") : t("poi.day"))} ` : "") }
							        		{ (((product.custom_product.duration - (product.custom_product.duration % 60)) / 60) % 24 > 0 ? `${((product.custom_product.duration - (product.custom_product.duration % 60)) / 60) % 24} ${(((product.custom_product.duration - (product.custom_product.duration % 60)) / 60) % 24 > 1 ? t("poi.hours") : t("poi.hour"))} ` : "") }
							        		{ (product.custom_product.duration % 60 > 0 ? `${product.custom_product.duration % 60} ${ ((product.custom_product.duration - (product.custom_product.duration % 60)) / 60) % 24 > 0 ? "" : t("poi.minutes")}` : "") }
							        </div>
							    </div>
								<div className={"ft-no-margin row"} >
				                    <div className={"col-md-5 col-12 no-padding step-hotel-container-img-packages mt15"} >
                                        <img className={"ft-cover-img"} src={(product.custom_product.cover_picture !== null ? product.custom_product.cover_picture.url : (product.custom_product.pictures.length > 0 ? product.custom_product.pictures[0].url : "/Img/accommodation_default.jpg"))} />
                                        <div className={"icon-hotel-infos-container block-center text-center"} >
                                            <span>
                                                <CustomOverlay id={"tooltip-header-date"} tooltip={t("summary.pictures")} placement={"bottom"} >
                                                    <div className={"rounded-icon-container"} onClick={ () => { dispatch(ToggleAndSetPicturesModal((product.custom_product.pictures), 0 )); this.setState({index: (rand_id + index_poi_product) }); } }><i className={"icon-hotel-infos icon-camera"}/></div>
                                                </CustomOverlay>
                                                {
                                                    (product.custom_product.destination !== undefined && product.custom_product.destination !== null && product.custom_product.destination.data !== undefined && product.custom_product.destination.data !== null && product.custom_product.destination.data.longitude !== null && product.custom_product.destination.data.latitude !== null) && (
                                                        <CustomOverlay id={"tooltip-header-date"} tooltip={t("summary.google_map")} placement={"bottom"} >
                                                            <div className={"rounded-icon-container"} onClick={ () => { dispatch(TogleInfoCustomAndSetStep((rand_id + index_poi_product), true, 1, product)); this.setState({index: (rand_id + index_poi_product)}); } }><i className={"icon-hotel-infos icon-028-pin"}/></div>
                                                        </CustomOverlay>
                                                    )
                                                }
                                            </span>
                                        </div>
                                    </div>
				                    <div className={"col-md-6 no-padding step-hotel-right-container mt15"} >
				                        <div>
				                            <h4 className={"ft-dark-green col-md-12"} > { t("footer.about") } </h4>
				                            <div className={ "poi-cart-description" }>
				                            	{ product.custom_product.short_description }
				                            </div>
				                            {
				                            	product.custom_product.long_description !== null && (
				                            		<div>
				                            			<button className={ "poi-cart-see-more no-padding pointer ft-container-btn-right" } type={ "button" } onClick={() => { this.setState({open: true, long_description: product.custom_product.long_description}); }}>{ t("poi.see_more") }</button>
				                            		</div>
				                            	)
				                            }
				                        	{
				                        		product.custom_product.taking_address !== null && product.custom_product.taking_address !== "" && (
				                        			<div className={"mt15"}>
				                        				<i className={ "fa fa-map-marker poi-cart-taking-address" }/>{ t("poi.taking_address") } : { product.custom_product.taking_address }
				                        			</div>
				                        		)
				                        	}
				                        </div>
				                        <div className={ "col-md-12 italic mt15" }>
				                            <div className={"checkbox-container"} >
				                                <label className={ "cart-checkbox" }>
				                                    Ce produit est dans le devis
				                                    <input type={ "checkbox" } checked={ poi_product.is_optional === false ? true : false } onChange={(e) => {
				                                        let tmp_poi_cart = poi_cart.slice();

				                                        tmp_poi_cart[index_poi_product].is_optional = !tmp_poi_cart[index_poi_product].is_optional;

				                                        for (let i = 0; i < tmp_poi_cart.length; i++) {
			                                                if (poi_product.id === tmp_poi_cart[i].id) {
			                                                    tmp_poi_cart[i] = poi_product;
			                                                }
				                                        }

				                                        dispatch(SetPoiCart(tmp_poi_cart));

				                                        if (component.state.save_bar === false) {
				                                            component.setState({
				                                                save_bar: true
				                                            })
				                                        }
				                                     }}/>
				                                    <span className={ "cart-check-mark" }/>
				                                </label>
				                            </div>
				                        </div>
				                    </div>
				                </div>
							</div>
						    )
						}
					})
				}
				<CustomInfoModal index={this.state.index} />
                <SummaryPicturesModal index={this.state.index} />
				<MoreInfoPoi open={ this.state.open } component={ this } long_description={ this.state.long_description }/>
            </div>
		);
	}
}
