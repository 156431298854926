import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosResponse } from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { setFetchingTexts } from "../redux/cartConstructionReducer";
import { setItineraryContent, setItineraryContentInputs } from "../../Itinerary/redux/reducer";
import { ItineraryByDay } from "../../Itinerary/objects/itineraryByDay";
import { ItineraryByStep } from "../../Itinerary/objects/itineraryByStep";
import { AppState } from "../../../Reducers/Reducers";

export function useItineraryContentFetch(version: number): void {
    const dispatch = useDispatch();
    const tripAllData = useSelector((state: AppState) => state.trip.all_data);
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const itineraryContent = useSelector((state: AppState) => {
        return version ?
            state.itinerarySlice.content[version] :
            undefined;
    });

    useEffect(() => {
        let isCancelled = false;
        (async () => {
            const { pass_check, headers } = CheckBeforeRequest();
            if (
                pass_check &&
                version &&
                tripId &&
                itineraryContent?.state !== 'success'
            ) {
                try {
                    dispatch(setFetchingTexts(true));
                    if (tripAllData?.is_by_day_view) {
                        let next: string | null = `${API_HREF}client/${window.id_owner}/trip/${tripId}/versions/${version}/by-day/`;
                        const result: ItineraryByDay[] = [];
                        do {
                            const response: AxiosResponse<{
                                next: string | null,
                                results: ItineraryByDay[]
                            }> = await axios.get(
                                next,
                                { headers }
                            );
                            for (const item of response.data.results) {
                                result.push(item);
                            }
                            next = response.data.next;
                        } while (next);
                        if (!isCancelled) {
                            dispatch(
                                setItineraryContent({
                                    version,
                                    data: {
                                        state: 'success',
                                        data: {
                                            mode: 'by-day',
                                            content: [...result].sort((a, b) => {
                                                const aDays = [...a.day].sort((a, b) => a - b);
                                                const bDays = [...b.day].sort((a, b) => a - b);
                                                return (aDays[0] ?? 0) < (bDays[0] ?? 0) ? -1 : 1;
                                            })
                                        }
                                    }
                                })
                            );
                            dispatch(
                                setItineraryContentInputs({
                                    version,
                                    data: {
                                        state: 'success',
                                        data: {
                                            mode: 'by-day',
                                            content: result
                                        }
                                    }
                                })
                            );
                        }
                    } else {
                        let next: string | null = `${API_HREF}client/${window.id_owner}/trip/${tripId}/versions/${version}/by-step/`;
                        const result: ItineraryByStep[] = [];
                        do {
                            const response: AxiosResponse<{
                                next: string | null,
                                results: ItineraryByStep[]
                            }> = await axios.get(
                                next,
                                { headers }
                            );
                            for (const item of response.data.results) {
                                result.push(item);
                            }
                            next = response.data.next;
                        } while (next);
                        result.sort((a, b) => {
                            return (a.order ?? 0) - (b.order ?? 0);
                        });
                        if (!isCancelled) {
                            dispatch(
                                setItineraryContent({
                                    version,
                                    data: {
                                        state: 'success',
                                        data: {
                                            mode: 'by-step',
                                            content: result
                                        }
                                    }
                                })
                            );
                            dispatch(
                                setItineraryContentInputs({
                                    version,
                                    data: {
                                        state: 'success',
                                        data: {
                                            mode: 'by-step',
                                            content: result
                                        }
                                    }
                                })
                            );
                        }
                    }
                } catch (error: any) {
                    if (!isCancelled) {
                        dispatch(
                            setItineraryContent({
                                version,
                                data: { state: 'error', error }
                            })
                        );
                        dispatch(
                            setItineraryContentInputs({
                                version,
                                data: { state: 'error', error }
                            })
                        );
                    }
                } finally {
                    dispatch(setFetchingTexts(false));
                }
            }
        })();
        return () => {
            isCancelled = true;
        };
    }, [
        tripId,
        tripAllData?.is_by_day_view,
        itineraryContent?.state,
        version
    ]);
}
