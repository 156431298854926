import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Stack,
    styled,
    Typography
} from "@mui/material";
import { MailTemplateVisualEditorMode } from "./mailTemplateVisualEditorMode";
import { MailTemplateVisualEditorContext } from "./mailTemplateVisualEditorContext";
import { Mode } from "./objects/mode";
import { AppState } from "../../../../Reducers/Reducers";

type Props = {
    html: string,
    disableMode?: boolean
}

export function MailTemplateVisualEditorPreview(props: Props): JSX.Element {
    const context = useContext(MailTemplateVisualEditorContext);
    const { t } = useTranslation();
    const mode = useSelector((state: AppState) => {
        return state.mailTemplate.visualEditor.present.instances[context.instanceId]?.visualEditorMode;
    });

    return (
        <Stack
            sx={{
                marginTop: 1,
                height: '100%'
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={2.5}
                sx={{ marginBottom: 1 }}
            >
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    {t<string>('shared.mail-template-preview')}
                </Typography>
                {
                    !props.disableMode &&
                    <MailTemplateVisualEditorMode />
                }
            </Stack>
            <Preview>
                <Iframe
                    srcDoc={props.html}
                    sx={
                        mode === Mode.MOBILE ?
                            {
                                border: '1px solid #ccc',
                                width: '375px',
                                maxWidth: '375px'
                            } :
                            undefined
                    }
                />
            </Preview>
        </Stack>
    );
}

const Preview = styled('div')(() => ({
    border: '1px solid #ccc',
    width: '100%',
    height: '100%',
    maxHeight: 'calc(100% - 60px)',
    overflow: 'auto'
}));

const Iframe = styled('iframe')(() => ({
    display: 'block',
    margin: 'auto',
    width: '100%',
    height: '100%',
    border: 'none'
}));
