import moment from "moment";

export default (travelers_list, end_date) => {
    let nb_adults = 0;
    let nb_children = 0;
    let nb_babies = 0;

    travelers_list.map((traveler) => {
        let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
        if (age >= 2 && age < 12) {
            nb_children += 1;
        } else if (age < 2) {
            nb_babies += 1;
        } else if (age >= 12) {
            nb_adults += 1;
        }
    })
    
    let pax_object = {
        ADT: nb_adults,
        CNN: nb_children,
        INF: nb_babies
    }
    return pax_object
}