// Dependencies
import { useTranslation } from 'react-i18next';
import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// Core & Lab
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// Actions
import { toggleKeep } from '../../Actions/Pictures';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    ftMarginTop8: {
        marginTop: 8
    }
}));

const CartPictureKeep = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles()
    const keep = useSelector((state) => state.pictures.keep);
    const onToggleKeep = () => {
        dispatch(toggleKeep());
    };
    return (
        <FormControl className={ classes.ftMarginTop8 } fullWidth>
            <FormControlLabel control={ <Checkbox checked={ keep } onChange={ onToggleKeep } color={ 'primary' }/> } label={ t('cart-material.keep-picture') }/>
        </FormControl>
    );
};

export default CartPictureKeep;