import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Autocomplete, InputAdornment, Stack, TextField } from "@mui/material";
import { Search, Star } from "@mui/icons-material";
import axios, { CancelTokenSource } from "axios";
import { debounce, isNumber, isString } from "lodash";
import { getQuickDestination } from "../Itinerary/utils/getQuickDestination";
import { CartConstructionReplaceProductContext } from "./utils/cartConstructionReplaceProductContext";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { AccommodationSearchResult } from "./objects/accommodationSearchResult";
import { QuickDestination } from "../Itinerary/objects/QuickDestination";
import { AppState } from "../../Reducers/Reducers";

type Result = {
    accommodation: AccommodationSearchResult,
    destination: number
}

type Props = {
    value: Result | null,
    label: string,
    onChange: (value: Result | null) => void
}

export function CartConstructionAddProductAccommodationPicker(props: Props): JSX.Element {
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const [options, setOptions] = useState<Result[]>([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const context = useContext(CartConstructionReplaceProductContext);
    const cancelToken = useRef<CancelTokenSource | null>(null);
    const fireNetworkRequest = useCallback(
        debounce(
            async (search: string) => {
                if (isNumber(tripId)) {
                    try {
                        setLoading(true);
                        cancelToken.current?.cancel('Request cancelled.');
                        cancelToken.current = axios.CancelToken.source();
                        setOptions([]);
                        const results = await makeRequest({
                            trip: tripId,
                            search,
                            destinations: context.contentItem?.data.mode === 'by-day' ?
                                context.contentItem?.data.content.destinations?.map((item) => item.id) ?? [] :
                                [context.contentItem?.data.content.destination?.id].filter((item): item is number => isNumber(item))
                        });
                        if (results) {
                            setOptions(results);
                        }
                    } catch (error: any) {
                        console.error(error);
                    } finally {
                        setLoading(false);
                    }
                }
            },
            500
        ),
        [tripId]
    );

    useEffect(() => {
        if (search.trim().length > 0) {
            fireNetworkRequest(search);
        } else {
            setOptions([]);
            setLoading(false);
        }
    }, [search]);

    return (
        <Autocomplete
            value={props.value}
            loading={loading}
            options={options}
            isOptionEqualToValue={(a, b) => a.accommodation.id === b.accommodation.id}
            getOptionLabel={(option) => isString(option) ? option : option.accommodation.name ?? ''}
            filterOptions={(options) => options}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        )
                    }}
                    label={props.label}
                />
            )}
            renderOption={(props, option) => (
                <li {...props}>
                    <Stack direction="row" alignItems="center" spacing={1.5}>
                        <span>
                            {option.accommodation.name}
                        </span>
                        <Stack direction="row" alignItems="center">
                            {
                                new Array(option.accommodation.stars ?? 0).fill(null).map((_, index) => (
                                    <Star key={index} fontSize="small" />
                                ))
                            }
                        </Stack>
                    </Stack>
                </li>
            )}
            componentsProps={{
                popper: {
                    sx: {
                        minWidth: 230
                    }
                }
            }}
            sx={{ width: '100%' }}
            onChange={(_, value) => !isString(value) && props.onChange(value)}
            onInputChange={(_, value) => isString(value) && setSearch(value)}
            fullWidth
        />
    );
}

type MakeRequestOptions = {
    trip: number,
    destinations: number[],
    search: string
}

async function makeRequest(options: MakeRequestOptions): Promise<Result[]> {
    if (options.destinations.length === 0) {
        return [];
    }

    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const destinations = (
            await Promise.allSettled(
                options.destinations.map(async (destinationId) => {
                    const result = await getQuickDestination(destinationId);
                    if (result) {
                        return {
                            ...result,
                            id: destinationId
                        };
                    }
                    return null;
                })
            )
        //eslint-disable-next-line no-undef
        ).filter((destination): destination is PromiseFulfilledResult<QuickDestination & {id: number}> => {
            return destination.status === 'fulfilled' && !!destination.value;
        }).map((item) => {
            return item.value;
        });
        const responses = await Promise.allSettled(
            destinations.map(async (destination) => {
                const bounds = JSON.parse(destination.bounds?.replace(/'/g, '"') ?? '{}');
                return {
                    destination: destination.id,
                    response: await axios.get<{results: AccommodationSearchResult[]}>(
                        `${API_HREF}client/${window.id_owner}/accommodations/`,
                        {
                            headers,
                            params: {
                                limit: 20,
                                format: 'json',
                                search: options.search,
                                trip: options.trip,
                                latitude_max: bounds.north?.toFixed(6),
                                longitude_max: bounds.east?.toFixed(6),
                                latitude_min: bounds.south?.toFixed(6),
                                longitude_min: bounds.west?.toFixed(6)
                            }
                        }
                    )
                };
            })
        );
        const results: Result[] = [];
        for (const item of responses) {
            if (item.status === 'fulfilled') {
                for (const accommodation of item.value.response.data.results) {
                    results.push({
                        destination: item.value.destination,
                        accommodation
                    });
                }
            }
        }
        return results;
    }

    return [];
}
