export default (providers_list, provider_id) => {
	let provider = null;
	if (providers_list !== null) {
		for (let i = 0; i < providers_list.length; i++) {
		    if (providers_list[i].provider.id === provider_id)
		        provider = providers_list[i];
		}		
	}
	return provider;
};
