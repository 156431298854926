//Dependencies
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useState, Fragment, useEffect } from 'react';
//Core
import { Button, Stack } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
//Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';

const useStyles = makeStyles({
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    cancelButton: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    }
});

const CartAccommodationRemarkBooking = ({ accommodation, provider, onRemarkBooking }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [rooms, setRooms] = useState([]);
    const onRemarkBookingChange = index_room => event => {
        let tmp_rooms = [...rooms];
        let room = { ...tmp_rooms[index_room] };
        room.remark_booking = event.target.value;
        tmp_rooms[index_room] = room;
        setRooms(tmp_rooms);
    };
    const onAtiRemarkBookingChange = index_room => event => {
        console.log('event : ', event.target.value);
        let tmp_rooms = [...rooms];
        let room = { ...tmp_rooms[index_room] };
        room.remark_booking = event.target.value;
        tmp_rooms[index_room] = room;
        setRooms(tmp_rooms);
    };
    const onValidateRemarkBooking = () => {
        const { headers } = CheckBeforeRequest();
        rooms.map(room => {
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                data: {
                    remark_booking: provider.provider.code === 'americantours' ? room.remark_booking === null || room.remark_booking.length === 0 ? null : room.remark_booking.join() : room.remark_booking === '' ? null : room.remark_booking
                }
            }).then(response => {
                dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
            }).catch(error => {
                console.log(error);
            });
        });
        onRemarkBooking();
    };
    const onCancelRemarkBooking = () => {
        onRemarkBooking();
    };
    useEffect(() => {
        let tmp_rooms = [];
        accommodation.rooms.map(room => {
            tmp_rooms.push({
                id: room.id,
                remark_booking: provider.provider.code === 'americantours' ? room.remark_booking === null ? null : room.remark_booking.split(',') : room.remark_booking,
                booked: room.booking_status !== null && room.booking_status.status_booking === 'CONFIRMED'
            });
        });
        setRooms(tmp_rooms);
    }, [accommodation]);
    return (
        <Grid container spacing={ 4 }>
            {
                rooms.map((room, index_room) => (
                    <Fragment key={ `room-manual-booking-${room.id}` }>
                        <Grid item xs={ 12 }>
                            <Typography>{ t('global.room') } : { index_room + 1 }</Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            {
                                provider.provider.code === 'americantours' ? (
                                    <FormControl fullWidth variant={ 'outlined' } disabled={ room.booked }>
                                        <InputLabel htmlFor={ 'remark-booking' }>{ t('cart-material.remark-booking') }</InputLabel>
                                        <Select id={ 'status' } value={ room.remark_booking === null ? [] : room.remark_booking } label={ t('cart-material.remark-booking') } renderValue={(selected) => selected.join(', ')} onChange={ onAtiRemarkBookingChange(index_room) } multiple>
                                            <MenuItem value={ 'Two beds' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Two beds') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-two-beds') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Honeymooners' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Honeymooners') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-honeymooners') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Non-smoking' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Non-smoking') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-non-smoking') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Low floor' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Low floor') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-low-floor') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Early check in' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Early check in') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-early-check-in') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'King size bed' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('King size bed') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-king-size-bed') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Smoking' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Smoking') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-smoking') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Baby crib' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Baby crib') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-baby-crib') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Late check out' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Late check out') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-late-check-out') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Connecting rooms' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Connecting rooms') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-connecting-rooms') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'High floor' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('High floor') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-high-floor') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Rollaway' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Rollaway') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-rollaway') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Anniversary' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Anniversary') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-anniversary') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Ground floor' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Ground floor') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-ground-floor') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Near elevator' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Near elevator') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-near-elevator') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Away from elevator' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Away from elevator') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-away-from-elevator') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Near ice maker' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Near ice maker') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-near-ice-maker') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Away from ice maker' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Away from ice maker') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-away-from-ice-maker') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Adjacent rooms' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Adjacent rooms') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-adjacent-rooms') }/>
                                            </MenuItem>
                                            <MenuItem value={ 'Refrigerator' }>
                                                <Checkbox checked={ room.remark_booking !== null && room.remark_booking.indexOf('Refrigerator') > -1}/>
                                                <ListItemText primary={ t('cart-material.remark-refrigerator') }/>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <FormControl fullWidth variant={ 'outlined' } disabled={ room.booked }>
                                        <InputLabel htmlFor={ 'remark-booking' }>{ t('cart-material.remark-booking') }</InputLabel>
                                        <OutlinedInput
                                            id={ 'remark-booking' }
                                            type={ 'text' }
                                            onChange={ onRemarkBookingChange(index_room) }
                                            label={ t('cart-material.remark-booking') }
                                            value={ room.remark_booking === null ? '' : room.remark_booking }
                                            rows={8}
                                            multiline
                                        />
                                    </FormControl>
                                )
                            }
                        </Grid>
                    </Fragment>
                ))
            }
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                    <Button onClick={ onCancelRemarkBooking }>{ t('global.cancel') }</Button>
                    {
                        !(accommodation.booking_status !== null && accommodation.booking_status.status_booking === 'CONFIRMED') &&
                        <Button onClick={ onValidateRemarkBooking }>{ t('global.validate') }</Button>
                    }
                </Stack>
            </Grid>
        </Grid>
    );
};

export default CartAccommodationRemarkBooking;
