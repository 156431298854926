//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Room from '@material-ui/icons/Room';
import DateRange from '@material-ui/icons/DateRange';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 30
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 19px)',
        margin: '0 4px',
        left: "-16px"
    },
	containerMargin: {
        display: "flex"
    },
	includedAmenities: {
        verticalAlign: '-3px',
		fontSize: 16,
        marginRight: 4
    },
	bold: {
		fontWeight: "bold"
	},
	italic: {
		fontStyle: "italic"
	},
    font12: {
        fontSize: 12
    },
    font14: {
        fontSize: 14
    },
    font17: {
        fontSize: 17
    },
	lowerCase: {
        textTransform: "capitalize"
	},
	calendar: {
        float: 'left',
        marginRight: 4
    },
    pointer: {
        cursor: "pointer"
    },
    underline: {
        textDecoration: "underline"
    },
    subProviderLogo: {
        position: 'absolute',
        left: 0,
        top: -30
    },
    gridImage: {
        width: 250,
        position: 'relative'
    },
    pictureContainer: {
	    overflow: "hidden",
	    height: 170,
	    // width: 300,
	    position: "relative",
	    cursor: "pointer"
    },
    carCardImg: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
	},
}));

const SummarayCarCard = ({car, mainCars, type, selectedProducts, setSelectedProducts, step_index, from, mainPrice, otherProduct}) => {
    const dispatch = useDispatch();
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const trip_info = useSelector(state => state.summary.trip_info);
    const currency_list = useSelector(state => state.base.currency_list);

    const handleChange = () => {
        let tmp_selectedProducts = Object.assign({}, selectedProducts);
        let tmp_cars = tmp_selectedProducts.cars.slice();
        if (mainCars.length === 0 && tmp_cars[step_index].includes(car.id)) {
            tmp_cars[step_index] = [];
        }
        else {
            tmp_cars[step_index] = [car.id];
        }
        tmp_selectedProducts.cars = tmp_cars;
        setSelectedProducts(tmp_selectedProducts);
    }
    const variantIsChecked = (product) => {
        return selectedProducts.cars[step_index].includes(product.id);
    }
    const showContractDetail = () => {
        dispatch({type: 'CART_TOGGLE_CONTRACT_DETAIL', payload: car});
    };
    const getClientPrice = (prices) => {
        let current_price = prices.find((el) => {
            return !el.is_commissioned && !el.is_network && !el.is_surcom && !el.master_price;
        });
        if (current_price === undefined) {
            current_price = prices.find((el) => {
                return !el.is_commissioned && !el.is_network && !el.is_surcom && el.master_price;
            });
        }
        return current_price;
    }
    let price = parseFloat(getClientPrice(car.prices).selling_price);
    if (type === "option") {
		price = price - mainPrice;
	}
    let selling_currency = currency_list.find((currency) => currency.id === getClientPrice(car.prices).selling_currency);
    // let provider = null;
    // provider = providers.find((el) => {
    //     return el.provider.id === car.provider
    // });
    // if (provider === null) {
    //     provider = providers.find((el) => {
    //         return el.provider.id === car.provider
    //     });
    // }
    return (
        <Grid container direction={"row"} spacing={2} style={{marginBottom: 10, background: from === "tripSummary" ? (type === "option" ? "#EEEEEE" : "white") : "white"}}>
            {
                from !== "tripSummary" && !otherProduct && (trip_info.status === "TO_UPDATED" || trip_info.status === "ONGOING" || trip_info.status === "VALIDATED" || trip_info.status === "TOBOOK_VERDIE") && (
                    <Grid item>
                        <div className={ classes.checkboxContainer }>
                            <Checkbox
                                className={ classes.checkbox }
                                edge={ 'start' }
                                checked={ variantIsChecked(car) }
                                tabIndex={ -1 }
                                disableRipple
                                onChange={ handleChange }
                                icon={<CircleUnchecked />}
                                checkedIcon={<RadioButtonCheckedIcon  />}
                            />
                        </div>
                    </Grid>
                )
            }
            <Grid item xs container direction={"column"} alignItems={"flex-start"} className={classes.font14}>
            {
                from !== "tripSummary" && (
                    <Fragment>
                        <Grid item style={{width: "100%"}}>
                            <Grid container direction={"row"} justify={"space-between"}>
                                <Grid item xs style={{width: "100%"}}>
                                    <DriveEtaIcon className={classes.genericText} style={{verticalAlign: "sub", marginRight: 10}}/>
                                    <span className={classes.genericText}>{ car.name } </span>
                                    {
                                        ((car.important_notice !== null && car.important_notice !== "") || (car.included_service !== undefined && car.included_service.length !== 0)) && (
                                            <span className={`${classes.genericText} ${classes.pointer} ${classes.underline}`} onClick={ showContractDetail } style={{paddingLeft: 5}}>{ t("global.short_more_info") }</span>
                                        )
                                    }
                                </Grid>
                                {
                                    <Grid item xs className={ classes.gridImage }>
                                    {/*{ car.subprovider_logo !== null ? <img style={{float: "right"}} src={ car.subprovider_logo } alt={ 'car-renter-picture' } width={ 75 }/> : provider !== null && provider.provider.logo !== null ? <img style={{float: "right"}} src={ provider.provider.logo.url } alt={ 'car-renter-picture' } width={ 75 }/> : <Fragment/> }*/}
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction={"column"}>
                            {
                                car.station_name_pickup === car.station_name_return &&
                                <Grid item className={ classes.containerMargin }>
                                    <Room className={ classes.font17 }/><Typography className={`${classes.lowerCase} ${classes.genericText} ${classes.font12}`}>{ t('cart-material.same-agency') } : { car.station_name_pickup.toLowerCase() } { car.station_name_pickup !== null ? car.station_name_pickup.toLowerCase() : '' }</Typography>
                                </Grid>
                            }
                            {
                                car.station_name_pickup !== car.station_name_return &&
                                <Grid item className={ classes.containerMargin }>
                                    <Room className={ classes.font17 }/><Typography className={`${classes.lowerCase} ${classes.genericText} ${classes.font12}`}>{ t('cart-material.pickup-agency') } : { car.station_name_pickup.toLowerCase() } { car.station_name_pickup !== null ? car.station_name_pickup.toLowerCase() : '' }</Typography>
                                </Grid>
                            }
                            {
                                car.station_name_return !== car.station_name_pickup &&
                                <Grid item className={ classes.containerMargin }>
                                    <Room className={ classes.font17 }/><Typography className={`${classes.lowerCase} ${classes.genericText} ${classes.font12}`}>{ t('cart-material.return-agency') } : { car.station_name_return.toLowerCase() } { car.station_name_return !== null ? car.station_name_return.toLowerCase() : '' }</Typography>
                                </Grid>
                            }
                            </Grid>
                        </Grid>
                        <Grid item style={{width: "100%"}}>
                            <Grid container direction={"row"} justify={"space-between"}>
                                <Grid item xs>
                                    <DateRange className={ classes.calendar } style={{fontSize: 20, verticalAlign: "text-top"}}/>
                                    <Typography className={`${classes.font14} ${classes.genericText}`}>Du { moment.utc(car.start_date).format('DD MMMM HH:mm') } au { moment.utc(car.end_date).format('DD MMMM HH:mm') }</Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Fragment>
                )
            }
            {
                from === "tripSummary" && (
                    <Fragment>
                        <Grid item style={{width: "100%"}}>
                            <Grid container direction={"column"} spacing={2}>
                                <Grid item xs className={ classes.gridImage }>
                                    {/*{ car.subprovider_logo !== null ? <img src={ car.subprovider_logo } alt={ 'car-renter-picture' } width={ 75 }/> : provider !== null && provider.provider.logo !== null ? <img src={ provider.provider.logo.url } alt={ 'car-renter-picture' } width={ 75 }/> : <Fragment/> }*/}
                                </Grid>
                                <Grid item xs={12} style={{marginTop: 10}}>
                                    <Grid container direction={car.is_optional ? "row-reverse" : "row"} spacing={2}>
                                        <Grid item sm={5} xs={12}>
                                            <div className={classes.pictureContainer}>
                                                <img src={car.vehicle_picture_url} className={classes.carCardImg} style={{float: car.is_optional ? "right" : "left", borderRadius: 16}}/>
                                            </div>
                                        </Grid>
                                        <Grid item sm={7} xs={12} container direction={"column"} justify={"center"}>
                                            <Grid item>
                                                <DriveEtaIcon className={classes.genericText} style={{verticalAlign: "sub", marginRight: 10}}/>
                                                <span className={classes.genericText}>{ car.name }</span>
                                            </Grid>
                                            <Grid item style={{width: "100%"}}>
                                                {
                                                    car.station_name_pickup === car.station_name_return &&
                                                    <Grid item className={ classes.containerMargin }>
                                                        <Room className={ classes.font17 }/><Typography className={`${classes.lowerCase} ${classes.genericText} ${classes.font12}`}>{ t('cart-material.same-agency') } : { car.station_name_pickup.toLowerCase() } { car.station_name_pickup !== null ? car.station_name_pickup.toLowerCase() : '' }</Typography>
                                                    </Grid>
                                                }
                                                {
                                                    car.station_name_pickup !== car.station_name_return &&
                                                    <Grid item className={ classes.containerMargin }>
                                                        <Room className={ classes.font17 }/><Typography className={`${classes.lowerCase} ${classes.genericText} ${classes.font12}`}>{ t('cart-material.pickup-agency') } : { car.station_name_pickup.toLowerCase() } { car.station_name_pickup !== null ? car.station_name_pickup.toLowerCase() : '' }</Typography>
                                                    </Grid>
                                                }
                                                {
                                                    car.station_name_return !== car.station_name_pickup &&
                                                    <Grid item className={ classes.containerMargin }>
                                                        <Room className={ classes.font17 }/><Typography className={`${classes.lowerCase} ${classes.genericText} ${classes.font12}`}>{ t('cart-material.return-agency') } : { car.station_name_return.toLowerCase() } { car.station_name_return !== null ? car.station_name_return.toLowerCase() : '' }</Typography>
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Grid item>
                                                <DateRange className={ classes.calendar } style={{fontSize: 20, verticalAlign: "text-top"}}/>
                                                <Typography className={`${classes.font14} ${classes.genericText}`}>Du { moment.utc(car.start_date).format('DD MMMM HH:mm') } au { moment.utc(car.end_date).format('DD MMMM HH:mm') }</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    ((car.important_notice !== null && car.important_notice !== "") || (car.included_service !== undefined && car.included_service.length !== 0)) && (
                                        <Grid item>
                                            <Button className={`${classes.genericText} ${classes.lowerCase}`} variant={"outlined"} onClick={ showContractDetail } style={{float: "right"}}>{ t("global.short_more_info") }</Button>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Fragment>
                )
            }
            {
                car.custom_information !== undefined && car.custom_information !== null && (
                    <Grid item style={{width: "100%", marginTop: 10}}>
                        <Alert variant={ 'filled' } severity={ 'info' } >
                            <AlertTitle>{ t('cart-material.note') } :</AlertTitle>
                            { car.custom_information }
                        </Alert>
                    </Grid>
                )
            }
            {
                type === "option" && (
                    <Grid item style={{width: "100%", marginTop: fullScreen || from !== "tripSummary" ? 0 : 30, fontSize: 20}} xs>
                        <span style={{float: "right"}} className={classes.bold}>{price > 0 ? "+ " : "- " }{Intl.NumberFormat(i18n.language, { style: "currency", currency: selling_currency.iso_code, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(Math.abs(price)) }</span>
                    </Grid>
                )
            }
			</Grid>
        </Grid>
    )
}
export default React.memo(SummarayCarCard);