import { useSelector } from "react-redux";
import axios, { AxiosResponse } from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { PackageInput } from "../CartPackagePackagesList";
import { TripVersion } from "../../Menu/MaterialTripList/objects/tripVersion";
import { AppState } from "../../../Reducers/Reducers";

type Package = Pick<
    PackageInput[number],
    'terrestrialPrice'
> & Partial<
    Pick<
        PackageInput[number],
        'flightPrice'
    >
> & {
    providerId?: number
}

type Callback = (
    tripVersion: number,
    stackNumber: number,
    packageContent: Package
) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: (trip: TripVersion) => void,
    onError: (error: Error) => void,
    onFinally: () => void
}>

export function useCartPackageUpdatePrices(options: Options): Callback {
    const tripId = useSelector((state: AppState) => state.trip.trip_id);

    return async (version, stackNumber, packageContent) => {
        if (tripId) {
            try {
                if (options.onTrigger) {
                    options.onTrigger();
                }
                const response = await makeRequest({
                    tripId,
                    version,
                    stackNumber,
                    package: packageContent
                });
                if (options.onSuccess && response) {
                    options.onSuccess(response.data);
                }
            } catch (error: any) {
                if (options.onError) {
                    options.onError(error);
                }
            } finally {
                if (options.onFinally) {
                    options.onFinally();
                }
            }
        }
    };
}

type RequestOptions = {
    tripId: number,
    version: number,
    stackNumber: number,
    package: Package | undefined
}

async function makeRequest(options: RequestOptions): Promise<AxiosResponse<TripVersion> | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check && options.package) {
        return axios.patch<TripVersion>(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/`,
            options.package.terrestrialPrice.currency &&
            options.package.flightPrice &&
            options.package.providerId ? {
                    flight_purchase_price: options.package.flightPrice.price.toString(),
                    flight_purchase_currency: options.package.flightPrice.currency,
                    flight_custom_rate_type: options.package.flightPrice.marginType,
                    flight_custom_value: options.package.flightPrice.margin.toString(),
                    flight_stack_number: options.stackNumber,
                    flight_provider_id: options.package.providerId,
                    flight_price_id: options.package.flightPrice.id,
                    terrestrial_purchase_price: options.package.terrestrialPrice.price.toString(),
                    terrestrial_purchase_currency: options.package.terrestrialPrice.currency,
                    terrestrial_custom_rate_type: options.package.terrestrialPrice.marginType,
                    terrestrial_custom_value: options.package.terrestrialPrice.margin.toString(),
                    terrestrial_stack_number: options.stackNumber,
                    terrestrial_provider_id: options.package.providerId,
                    terrestrial_price_id: options.package.terrestrialPrice.id
                } : {
                    prices_stack_product: [
                        {
                            id: options.package.terrestrialPrice.id,
                            custom_rate: true,
                            custom_rate_type: options.package.terrestrialPrice.marginType,
                            custom_value: options.package.terrestrialPrice.margin.toString(),
                            purchase_price: options.package.terrestrialPrice.price.toString()
                        }
                    ]
                },
            { headers }
        );
    }

    return null;
}
