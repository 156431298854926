//---- Actions ----// 
import {ToggleSeatModal, SetSeatData, SetErrorSeatData} from "../../../../../Actions/FlightSearch";

//---- Commons ----//
import CheckBeforeRequest from "../../../../Common/CheckBeforeRequest";
import GetCookie from "../../../../Common/Functions/GetCookie";

export default (flight, provider, is_open, fare_index, segment_index, airlines, airports, currency, trace_id, dispatch) => {
	dispatch(ToggleSeatModal(is_open, null, null));
	const { pass_check, headers } = CheckBeforeRequest();
	console.log('flight:', flight);
	if (pass_check) {
		let url = `${API_HREF}client/${window.id_owner}/flights/seat_map/?trip=${GetCookie("trip_id")}&outbound_segments=`;
		for (let i = 0; i < flight.outbounds.length; i++) {
			let leg = flight.outbounds[i].legs;
			let outbound_segment = "";
			console.log('service:', flight.outbounds[segment_index].options[0].fares[fare_index].option_category[0].option_segment[i]);
			let service_class = flight.outbounds[segment_index].options[0].fares[fare_index].option_category[0].option_segment[i].class_of_service;
			for (let j = 0; j < leg.length; j++) {
				let marketing_airline = leg[j].marketing_airline.airline_code ? leg[j].marketing_airline.airline_code : airlines.find((airline) => {return airline.id === leg[j].marketing_airline});
				console.log('marketing_airline:', marketing_airline);
				if (j < leg.length - 1) {
				    outbound_segment += leg[j].segment_key + "," + (typeof(marketing_airline) === "string" ? marketing_airline : marketing_airline.code) + "," + (leg[j].marketing_flight_number ? leg[j].marketing_flight_number : leg[j].flight_number) + "," + (leg[j].origin_airport ? airports[leg[j].origin_airport].code : leg[j].origin.airport_code) + "," + (leg[j].destination_airport ? airports[leg[j].destination_airport].code : leg[j].destination.airport_code) + "," + (leg[j].departure_datetime_locale ? leg[j].departure_datetime_locale : leg[j].departure_time) + "," + (leg[j].arrival_datetime_locale ? leg[j].arrival_datetime_locale : leg[j].arrival_time) + "," + service_class + "," + i + "|";
				}
				else {
				    outbound_segment += leg[j].segment_key + "," + (typeof(marketing_airline) === "string" ? marketing_airline : marketing_airline.code) + "," + (leg[j].marketing_flight_number ? leg[j].marketing_flight_number : leg[j].flight_number) + "," + (leg[j].origin_airport ? airports[leg[j].origin_airport].code : leg[j].origin.airport_code) + "," + (leg[j].destination_airport ? airports[leg[j].destination_airport].code : leg[j].destination.airport_code) + "," + (leg[j].departure_datetime_locale ? leg[j].departure_datetime_locale : leg[j].departure_time) + "," + (leg[j].arrival_datetime_locale ? leg[j].arrival_datetime_locale : leg[j].arrival_time) + "," + service_class + "," + i;
				}
			}
			if (i !== (flight.outbounds.length - 1))
				url += outbound_segment + "|";
			else
				url += outbound_segment;
		}
		url += "&currency=" + currency.iso_code + "&trace_id=" + trace_id + "&provider_id=" + provider;
		let res = url.replace(/\+/g, "%2B");
		console.log('url:', res);
		$.ajax({
			method: "GET",
		    url: res,
		    headers: headers,
		    success: function (data) {
		    	let seat_data = data
		    	seat_data.tab = 0;
		    	let arr = [];
		    	flight.outbounds.map((outbound, outbound_index) => {
		    		outbound.legs.map((leg, leg_index) => {
		    			leg.outbound_index = outbound_index;
		    			leg.leg_index = leg_index;
		    			arr.push(leg);
		    		})
		    	});
		    	seat_data.class_name = flight.outbounds[segment_index].options[0].fares[fare_index].option_category[0].name;
		    	seat_data.flight_info = arr;
		    	seat_data.flight.map((flight) => {
		    		flight.upper = false;
		    		flight.seat_rows.map((seat_rows) => {
		    			seat_rows.seats = seat_rows.seats.reverse();
		    			seat_rows.seats.map((seat) => {
		    				seat.show = false;
		    			})
		    		});
		    	});
		    	console.log('seat_data:', seat_data);
		    	dispatch(SetSeatData(seat_data));
		    },
		    error: function (error) {
		    	console.log('error:', error);
		    	dispatch(SetErrorSeatData(error.statusText));
		    }
		})
	}
}