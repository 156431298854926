import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { WithRouterProps, withRouter } from "react-router";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { CartConstructionReplacePoiContent } from "./CartConstructionReplacePoiContent";
import { CartConstructionReplaceFlightSegmentContent } from "./CartConstructionReplaceFlightSegmentContent";
import { CartConstructionReplaceAccommodationContent } from "./CartConstructionReplaceAccommodationContent";
import MaterialCarsSearch from "../CarsMaterial/MaterialCarsSearch";
import Transfers from "../Transfers/Transfers";
import {
    CartConstructionReplaceProductModalStep
} from "./objects/cartConstructionReplaceProductModalStep";
import { CartConstructionReplaceProductContext } from "./utils/cartConstructionReplaceProductContext";
import { PoiCart } from "../Itinerary/objects/poiCart";
import { TransferCart } from "../Itinerary/objects/transferCart";
import { CarCart } from "../Itinerary/objects/carCart";
import { Flight } from "../Itinerary/network/flight";
import { CartProductStatus } from "./CartConstructionProductsTableItemMenu";

type ContextValue = Parameters<typeof CartConstructionReplaceProductContext.Provider>[0]['value']

type Props = ({
    type: 'car',
    car?: CarCart
} | {
    type: 'accommodation',
    accommodationParams?: {
        id: number
    },
    startDestination?: number,
    status: CartProductStatus
} | {
    type: 'poi',
    poi?: PoiCart
} | {
    type: 'transfer',
    transfer?: TransferCart
} | {
    type: 'flight-segment',
    flight?: Flight,
    segmentIndices?: number[]
}) & {
    onClose: () => void
} & Pick<
    ContextValue,
    'mode' |
    'contentItem' |
    'dataTransformer' |
    'onDelete'
>

export const CartConstructionReplaceProductModal = withRouter<Props>(
    function CartConstructionReplaceProductModal(props): JSX.Element {
        const { t } = useTranslation();
        const [step, setStep] = useState<CartConstructionReplaceProductModalStep>(CartConstructionReplaceProductModalStep.INPUTS);
        const [loading, setLoading] = useState(false);
        const [canValidate, setCanValidate] = useState(false);
        const [showButton, setShowButton] = useState(true);
        const callbackRef = useRef<(() => void) | null>(null);
        const extraDataRef = useRef<unknown | null>(null);
        const [container, setContainer] = useState<HTMLDivElement | null>(null);
        const contextValue = useMemo((): ContextValue => {
            return {
                step,
                loading,
                enableReplace: true,
                mode: props.mode,
                contentItem: props.contentItem,
                dataTransformer: props.dataTransformer,
                extraData: extraDataRef,
                onDelete: props.onDelete,
                onClose: props.onClose,
                onChangeLoading: setLoading,
                onNextStep: () => {
                    setStep((state) => {
                        if (state === CartConstructionReplaceProductModalStep.INPUTS) {
                            return CartConstructionReplaceProductModalStep.PROVIDERS;
                        }
                        return CartConstructionReplaceProductModalStep.ROOMS;
                    });
                },
                onRegisterNextEvent: (callback) => {
                    callbackRef.current = callback;
                },
                onIsFinalStep: () => {
                    setCanValidate(true);
                },
                onHideNextButton: () => {
                    setShowButton(false);
                },
                onShowNextButton: () => {
                    setShowButton(true);
                }
            };
        }, [
            step,
            loading,
            setLoading,
            props.mode,
            props.contentItem,
            props.dataTransformer,
            props.onDelete,
            props.onClose,
            setStep,
            setCanValidate,
            callbackRef,
            extraDataRef
        ]);

        const onContinue = () => {
            if (callbackRef.current) {
                callbackRef.current();
            }
        };

        const onClose = () => {
            if (loading && canValidate) {
                return;
            }
            props.onClose();
        };

        switch (props.type) {
            case 'accommodation': {
                extraDataRef.current = {
                    status: props.status,
                    startDestination: props.startDestination
                };
                break;
            }
            case 'poi': {
                extraDataRef.current = props.poi;
                break;
            }
            case 'car': {
                extraDataRef.current = props.car;
                break;
            }
            case 'transfer': {
                extraDataRef.current = props.transfer;
                break;
            }
            case 'flight-segment': {
                extraDataRef.current = {
                    segmentIndices: props.segmentIndices,
                    flight: props.flight,
                    container
                };
            }
        }

        let maxWidth: 'lg' | 'md' | false = false;

        if (props.type === 'flight-segment') {
            maxWidth = [
                CartConstructionReplaceProductModalStep.PROVIDERS,
                CartConstructionReplaceProductModalStep.ROOMS
            ].includes(step) ?
                false :
                'md';
        } else {
            maxWidth = step === CartConstructionReplaceProductModalStep.ROOMS ? 'lg' : 'md';
        }

        return (
            <CartConstructionReplaceProductContext.Provider value={contextValue}>
                <Dialog
                    open
                    onClose={onClose}
                    maxWidth={maxWidth}
                    fullWidth={
                        props.type !== 'flight-segment' ||
                        ![
                            CartConstructionReplaceProductModalStep.PROVIDERS,
                            CartConstructionReplaceProductModalStep.ROOMS
                        ].includes(step)
                    }
                >
                    <DialogTitle>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6" fontWeight={700}>
                                {
                                    props.mode === 'add' ?
                                        t<string>('cart-material.product-add') :
                                        t<string>('cart-material.product-edit')
                                }
                            </Typography>
                            <IconButton onClick={onClose} disabled={loading && canValidate}>
                                <Close />
                            </IconButton>
                        </Stack>
                    </DialogTitle>
                    <DialogContent
                        ref={(element) => {
                            setContainer(element as HTMLDivElement | null);
                        }}
                    >
                        {
                            loading &&
                            !canValidate &&
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    padding: 4
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        }
                        <Box sx={{ display: loading && !canValidate ? 'none' : undefined }}>
                            <Content {...props} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} disabled={loading && canValidate}>
                            {t<string>('shared.cancel')}
                        </Button>
                        {
                            showButton &&
                            <Button onClick={onContinue} disabled={loading && canValidate}>
                                {
                                    loading &&
                                    canValidate &&
                                    <CircularProgress size="20px" />
                                }
                                {
                                    (
                                        !loading ||
                                        !canValidate
                                    ) &&
                                    <>
                                        {
                                            canValidate ?
                                                t<string>('global.validate') :
                                                t<string>('global.continue')
                                        }
                                    </>
                                }
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </CartConstructionReplaceProductContext.Provider>
        );
    }
) as unknown as (props: Props) => JSX.Element;

type ContentProps = Props & WithRouterProps

function Content(props: ContentProps): JSX.Element {
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { type, onClose, ...rest } = props;

    return (
        <>
            {
                type === 'car' &&
                <MaterialCarsSearch {...rest} />
            }
            {
                type === 'accommodation' &&
                <CartConstructionReplaceAccommodationContent
                    {...rest}
                    accommodationParams={props.accommodationParams}
                />
            }
            {
                type === 'poi' &&
                <CartConstructionReplacePoiContent
                    {...rest}
                    poi={props.poi}
                />
            }
            {
                type === 'transfer' &&
                <Transfers {...rest} />
            }
            {
                type === 'flight-segment' &&
                <CartConstructionReplaceFlightSegmentContent
                    {...rest}
                    flight={props.flight}
                    segmentIndices={props.segmentIndices}
                />
            }
        </>
    );
}
