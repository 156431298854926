import { useEffect } from "react";

export function useCloseDatePicker(ref, popperRef, openPicker, setOpenPicker) {

    useEffect(() => {
        function handleClickOutside(event) {
            if ((ref.current !== null && !ref.current.contains(event.target)) && (popperRef.current !== null && !event.path.includes(popperRef.current))) {
                let temp_open_picker = openPicker.slice();
                let copy_open_picker = []
                temp_open_picker.map((picker, picker_index) => {
                    picker = false;
                    copy_open_picker.push(picker);
                });
                setOpenPicker(copy_open_picker);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, openPicker]);
}
