type Product = {
    start_date: string,
    is_optional: boolean
}

export function sortProducts(a: Product, b: Product): number {
    const aStartDate = window.moment.utc(a.start_date);
    const bStartDate = window.moment.utc(b.start_date);
    if (
        aStartDate.isSame(
            bStartDate,
            'day'
        )
    ) {
        if (a.is_optional && !b.is_optional) {
            return 1;
        } else if (b.is_optional && !a.is_optional) {
            return -1;
        }
    }
    return aStartDate.isBefore(
        bStartDate
    ) ? -1 : 1;
}
