import {
    ADD_CUSTOM_PICTURES,
    ADD_FILES,
    ADD_SELECTION,
    DELETE_FILE,
    DELETE_SELECTION,
    EDIT_PICTURES,
    SET_ADDING,
    SET_CROPPER,
    SET_LIMIT,
    SET_LIST,
    SET_PAGE,
    SET_PICTURE,
    SET_RUNNING,
    SET_SEARCH,
    TOGGLE_CREATE,
    TOGGLE_KEEP,
    UPDATE_ALT,
    UPDATE_CREATE_TAB,
    UPDATE_CREDITS,
    UPDATE_DESCRIPTION,
    UPDATE_NAME,
    UPDATE_PROGRESS
} from '../ActionTypes/Pictures';

const initialState = {
    adding: false,
    alt: '',
    count: 0,
    create: false,
    create_tab: 0,
    credits: '',
    cropper: null,
    description: '',
    description_error: '',
    filter_open: false,
    filter_type: 'PICTURE',
    files: [],
    keep: true,
    limit: 24,
    list: null,
    name: '',
    name_error: '',
    page: 0,
    picture: null,
    progress: 0,
    refresh: false,
    running: false,
    search: '',
    selection: [],
    product: null
};
export default function reducer(state = initialState, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.cart !== undefined) {
                Object.keys(action.payload.cart).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.cart[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            new_state = initialState;
            return new_state;
        }
        case ADD_CUSTOM_PICTURES: {
            return {
                ...state,
                adding: false,
                count: 0,
                create: false,
                create_tab: 0,
                description: '',
                filter_open: false,
                filter_type: 'PICTURE',
                files: [],
                limit: 24,
                list: null,
                name: '',
                page: 0,
                progress: 0,
                refresh: false,
                running: false,
                search: '',
                selection: []
            };
        }
        case ADD_FILES: {
            return { ...state, files: [...state.files, ...action.payload] };
        }
        case ADD_SELECTION: {
            let files = [...state.files];
            if (action.payload.name !== null) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i].name === action.payload.name) {
                        files.splice(i, 1);
                        break;
                    }
                }
            }
            return { ...state, selection: [...state.selection, action.payload.picture], files: files };
        }
        case DELETE_FILE: {
            let files = [...state.files];
            files.splice(action.payload, 1);
            return { ...state, files: files };
        }
        case DELETE_SELECTION: {
            let selection = [...state.selection];
            for (let i = 0; i < selection.length; i++) {
                if (selection[i].id === action.payload) {
                    selection.splice(i, 1);
                    break;
                }
            }
            return { ...state, selection: selection };
        }
        case EDIT_PICTURES : {
            return { ...state, product: action.payload };
        }
        case SET_ADDING: {
            return { ...state, adding: action.payload };
        }
        case SET_CROPPER: {
            return { ...state, cropper: action.payload };
        }
        case SET_LIMIT: {
            return { ...state, list: null, limit: action.payload };
        }
        case SET_LIST: {
            const { count, results } = action.payload;
            return { ...state, count: count, list: results, running: false };
        }
        case SET_PAGE: {
            return { ...state, list: null, page: action.payload };
        }
        case SET_PICTURE: {
            if (action.payload === true) {
                return { ...state, create: true, description: '', name: '', picture: null, progress: 0 };
            } else if (action.payload === false) {
                return { ...state, create: false, description: '', name: '', picture: null, progress: 0 };
            } else if (state.create) {
                return { ...state, create: false, description: '', name: '', picture: null, progress: 0, refresh: !state.refresh };
            } else if (state.picture !== null) {
                const list = state.list ?
                    [...state.list] :
                    [];
                if (state.list) {
                    for (let i = 0; i < list.length; i++) {
                        if (list[i].id === state.picture.id) {
                            list[i] = action.payload;
                            break;
                        }
                    }
                }
                return {
                    ...state,
                    list,
                    create: false,
                    description: '',
                    name: '',
                    picture: state.picture?.id === action.payload.id ?
                        action.payload :
                        null,
                    progress: 0,
                    refresh: !state.refresh
                };
            } 
            return { ...state, description: action.payload.description !== undefined && action.payload.description !== null ? action.payload.description : '', name: action.payload.name !== undefined && action.payload.name !== null ? action.payload.name : '', picture: action.payload, progress: 0 };
        }
        case SET_RUNNING: {
            return { ...state, running: action.payload };
        }
        case SET_SEARCH: {
            return { ...state, list: null, search: action.payload };
        }
        case TOGGLE_CREATE: {
            return { ...state, adding: false, create: !state.create };
        }
        case TOGGLE_KEEP: {
            return { ...state, keep: !state.keep };
        }
        case UPDATE_ALT: {
            return { ...state, alt: action.payload };
        }
        case UPDATE_CREATE_TAB: {
            return { ...state, create_tab: action.payload };
        }
        case UPDATE_CREDITS: {
            return { ...state, credits: action.payload };
        }
        case UPDATE_DESCRIPTION: {
            return { ...state, description: action.payload };
        }
        case UPDATE_NAME: {
            return { ...state, name: action.payload };
        }
        case UPDATE_PROGRESS: {
            return { ...state, progress: action.payload };
        }
        default:
            return state;
    }
}
