import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CartConstructionProductsTable } from "./CartConstructionProductsTable";
import { CartConstructionProductsTableCarItem } from "./CartConstructionProductsTableCarItem";
import { CartConstructionProductsTableTransferItem } from "./CartConstructionProductsTableTransferItem";
import { CartConstructionProductsTableHotelItem } from "./CartConstructionProductsTableHotelItem";
import { CartConstructionProductsTablePoiItem } from "./CartConstructionProductsTablePoiItem";
import { CartConstructionProductsTableFlightsItem } from "./CartConstructionProductsTableFlightItem";
import { CartConstructionProductsTableManualItem } from "./CartConstructionProductsTableManualItem";
import { CartConstructionProductsTableAssistanceItem } from "./CartConstructionProductsTableAssistanceItem";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    cart: Omit<
        ReturnType<typeof useCartProducts> & {manualProducts: ManualProduct[]},
        'trains' | 'ferries' | 'cruises' | 'insurances'
    >,
    package?: boolean
}

export function CartConstructionProductsByType(props: Props): JSX.Element {
    const { t } = useTranslation();
    const total_cars = useSelector((state: AppState) => state.cart.total_cars);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const total_flights = useSelector((state: AppState) => state.cart.total_flights);
    const total_transfers = useSelector((state: AppState) => state.cart.total_transfers);
    const total_accommodations = useSelector((state: AppState) => state.cart.total_accommodations);
    const total_assistances = useSelector((state: AppState) => state.cart.total_assistances);
    const total_pois = useSelector((state: AppState) => state.cart.total_pois);
    const total_manual_products = useSelector((state: AppState) => state.cart.total_manual_products);

    return (
        <CartConstructionProductsTable
            sections={
                [
                    {
                        title: (
                            <>
                                {
                                    t<string>(
                                        'cart-material.cart-construction-total-flights',
                                        { count: props.cart.flights.length }
                                    )
                                }
                                {
                                    total_flights.map((currency_cost, currency_index) => {
                                        const cost = currency_cost.cost.toLocaleString(
                                            language,
                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                        );
                                        return `${currency_index > 0 ? ' + ' : ''}${cost}${currency_cost.currency.symbol}`;
                                    })
                                }
                            </>
                        ),
                        content: props.cart.flights.map((item) => (
                            <CartConstructionProductsTableFlightsItem
                                key={`${item.type}-${item.flight.id}`}
                                item={item}
                            />
                        ))
                    },
                    {
                        title: (
                            <>
                                {
                                    t<string>(
                                        'cart-material.cart-construction-total-transfers',
                                        {
                                            count: props.cart.transfers.length + props.cart.manualProducts.filter((item) => {
                                                return item.product_type === 13;
                                            }).length
                                        }
                                    )
                                }
                                {
                                    total_transfers.map((currency_cost, currency_index) => {
                                        const cost = currency_cost.cost.toLocaleString(
                                            language,
                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                        );
                                        return `${currency_index > 0 ? ' + ' : ''}${cost}${currency_cost.currency.symbol}`;
                                    })
                                }
                            </>
                        ),
                        content: props.cart.transfers.map((item) => (
                            <CartConstructionProductsTableTransferItem
                                key={`${item.type}-${item.transfer.id}`}
                                item={item}
                            />
                        )).concat(
                            props.cart.manualProducts.filter((item) => {
                                return item.product_type === 13;
                            }).map((item) => (
                                <CartConstructionProductsTableManualItem
                                    key={`${item.product_type}-${item.id}`}
                                    item={item}
                                />
                            ))
                        )
                    },
                    {
                        title: (
                            <>
                                {
                                    t<string>(
                                        'cart-material.cart-construction-total-cars',
                                        { count: props.cart.cars.length }
                                    )
                                }
                                {
                                    total_cars.map((currency_cost, currency_index) => {
                                        const cost = currency_cost.cost.toLocaleString(
                                            language,
                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                        );
                                        return `${currency_index > 0 ? ' + ' : ''}${cost}${currency_cost.currency.symbol}`;
                                    })
                                }
                            </>
                        ),
                        content: props.cart.cars.map((item, index) => (
                            <CartConstructionProductsTableCarItem
                                key={`${item.type}-${item.car.id}`}
                                index={index}
                                item={item}
                            />
                        ))
                    },
                    {
                        title: (
                            <>
                                {
                                    t<string>(
                                        'cart-material.cart-construction-total-accommodations',
                                        { count: props.cart.accommodations.length }
                                    )
                                }
                                {
                                    total_accommodations.map((currency_cost, currency_index) => {
                                        const cost = currency_cost.cost.toLocaleString(
                                            language,
                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                        );
                                        return `${currency_index > 0 ? ' + ' : ''}${cost}${currency_cost.currency.symbol}`;
                                    })
                                }
                            </>
                        ),
                        content: props.cart.accommodations.map((item, _, array) => (
                            <CartConstructionProductsTableHotelItem
                                key={`${item.type}-${item.accommodation.id}`}
                                item={item}
                                accommodations={array}
                            />
                        ))
                    },
                    {
                        title: (
                            <>
                                {
                                    t<string>(
                                        'cart-material.cart-construction-total-activities',
                                        { count: props.cart.pois.length }
                                    )
                                }
                                {
                                    total_pois.map((currency_cost, currency_index) => {
                                        const cost = currency_cost.cost.toLocaleString(
                                            language,
                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                        );
                                        return `${currency_index > 0 ? ' + ' : ''}${cost}${currency_cost.currency.symbol}`;
                                    })
                                }
                            </>
                        ),
                        content: props.cart.pois.map((item) => (
                            <CartConstructionProductsTablePoiItem
                                key={`${item.type}-${item.poi.id}`}
                                item={item}
                            />
                        ))
                    },
                    {
                        title: (
                            <>
                                {
                                    t<string>(
                                        'cart-material.cart-construction-total-assistances',
                                        { count: props.cart.assistances.length }
                                    )
                                }
                                {
                                    total_assistances.map((currency_cost, currency_index) => {
                                        const cost = currency_cost.cost.toLocaleString(
                                            language,
                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                        );
                                        return `${currency_index > 0 ? ' + ' : ''}${cost}${currency_cost.currency.symbol}`;
                                    })
                                }
                            </>
                        ),
                        content: props.cart.assistances.map((item) => (
                            <CartConstructionProductsTableAssistanceItem
                                key={`${item.type}-${item.assistance.id}`}
                                item={item}
                            />
                        ))
                    },
                    {
                        title: (
                            <>
                                {
                                    t<string>(
                                        'cart-material.cart-construction-total-auto-products',
                                        { count: props.cart.manualProducts.filter((item) => item.auto_product).length }
                                    )
                                }
                                {
                                    total_manual_products.filter((item) => {
                                        return item.auto_product;
                                    }).map((currency_cost, currency_index) => {
                                        const cost = currency_cost.cost.toLocaleString(
                                            language,
                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                        );
                                        return `${currency_index > 0 ? ' + ' : ''}${cost}${currency_cost.currency.symbol}`;
                                    })
                                }
                            </>
                        ),
                        content: props.cart.manualProducts.filter((item) => {
                            return item.auto_product && (item.product_type as number) !== 13;
                        }).map((item) => (
                            <CartConstructionProductsTableManualItem
                                key={`${item.product_type}-${item.id}`}
                                item={item}
                            />
                        ))
                    },
                    {
                        title: (
                            <>
                                {
                                    t<string>(
                                        'cart-material.cart-construction-total-other-products',
                                        { count: props.cart.manualProducts.filter((item) => !item.auto_product).length }
                                    )
                                }
                                {
                                    total_manual_products.filter((item) => {
                                        return !item.auto_product;
                                    }).map((currency_cost, currency_index) => {
                                        const cost = currency_cost.cost.toLocaleString(
                                            language,
                                            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                        );
                                        return `${currency_index > 0 ? ' + ' : ''}${cost}${currency_cost.currency.symbol}`;
                                    })
                                }
                            </>
                        ),
                        content: props.cart.manualProducts.filter((item) => {
                            return !item.auto_product && (item.product_type as number) !== 13;
                        }).map((item) => (
                            <CartConstructionProductsTableManualItem
                                key={`${item.product_type}-${item.id}`}
                                item={item}
                            />
                        ))
                    }
                ].filter((item) => {
                    return item.content.length > 0;
                })
            }
            indent={props.package}
        />
    );
}
