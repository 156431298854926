import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Dialog,
    IconButton,
    Typography,
    DialogContent,
    Button,
    Card,
    styled,
    Alert,
    AlertTitle,
    List,
    ListItem,
    Stack,
    DialogTitle,
    DialogActions
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";
import { CartConstructionItineraryContentStepsListItem } from "./CartConstructionItineraryContentStepsListItem";
import { CartConstructionItineraryContentStepsListDivider } from "./CartConstructionItineraryContentStepsListDivider";
import { CartConstructionItineraryContentStepsListAggregator } from "./CartConstructionItineraryContentStepsListAggregator";
import { useItineraryContentUpdate } from "../Itinerary/network/itineraryContentUpdate";
import { useShowError } from "../Utils/showError";
import GetCookie from "../Common/Functions/GetCookie";
import { setLoading } from "./redux/cartConstructionReducer";
import { setItineraryContent, setItineraryContentInputs } from "../Itinerary/redux/reducer";

type Props = {
    inputs: ItineraryContentStep[],
    onClose: () => void,
    onChangeInputs: React.Dispatch<React.SetStateAction<ItineraryContentStep[]>>,
    fullscreen?: boolean,
    disableSave?: boolean
}

export function CartConstructionItineraryContentAggregateModal(props: Props): JSX.Element {
    const versionString = GetCookie("trip_id_version");
    const version = versionString ? parseInt(versionString) : -1;
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState<ItineraryContentStep[]>([]);
    const showError = useShowError();
    const update = useItineraryContentUpdate({
        onTrigger() {
            dispatch(setLoading(true));
        },
        onSuccess(_, version, steps) {
            if (version) {
                dispatch(
                    setItineraryContent({
                        version,
                        data: {
                            state: 'success',
                            data: steps
                        }
                    })
                );
                dispatch(
                    setItineraryContentInputs({
                        version,
                        data: {
                            state: 'success',
                            data: steps
                        }
                    })
                );
            }
            enqueueSnackbar(t<string>('shared.circuit-steps-updated'), { variant: 'success' });
            props.onClose();
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            dispatch(setLoading(false));
        }
    });

    const onConfirm = async () => {
        if (!props.disableSave) {
            await update('unknown', version, inputs);
        }
        props.onChangeInputs(inputs);
        props.onClose();
    };

    useEffect(() => {
        setInputs(props.inputs);
    }, [props.inputs]);

    return (
        <Dialog
            open
            onClose={props.onClose}
            fullScreen={props.fullscreen}
            maxWidth="lg"
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                    <Typography variant={ 'h6' }>{t<string>('shared.circuit-aggregate-steps')}</Typography>
                    <IconButton edge={ 'start' } color={ 'inherit' } onClick={props.onClose}>
                        <Close/>
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Alert severity="info" sx={{ marginBottom: 2 }}>
                    <AlertTitle>{t<string>('cart-material.info')}</AlertTitle>
                    {t<string>('shared.circuit-steps-copy-hint')}
                </Alert>
                <VersionContainer>
                    <StepsList
                        inputs={inputs}
                        onChangeInputs={setInputs}
                    />
                </VersionContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{ t<string>('shared.cancel') }</Button>
                <Button onClick={onConfirm}>
                    {
                        !props.disableSave ?
                            t<string>('shared.save') :
                            t<string>('shared.confirm')
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
}

type StepsListProps = {
    inputs: ItineraryContentStep[],
    onChangeInputs: React.Dispatch<React.SetStateAction<ItineraryContentStep[]>>
}

function StepsList(props: StepsListProps): JSX.Element {
    return (
        <List
            component="div"
            dense
        >
            {
                props.inputs.map((input, index, array) => {
                    return (
                        <React.Fragment key={index}>
                            <ListItem>
                                <CartConstructionItineraryContentStepsListItem
                                    index={index}
                                    input={input}
                                />
                            </ListItem>
                            <ListItem>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    spacing={1}
                                    sx={{ width: '100%' }}
                                >
                                    {
                                        input.mode === 'by-day' &&
                                        input.content.day.length > 1 &&
                                        <CartConstructionItineraryContentStepsListDivider
                                            input={input}
                                            onChangeInputs={props.onChangeInputs}
                                        />
                                    }
                                    {
                                        array[index + 1] &&
                                        <CartConstructionItineraryContentStepsListAggregator
                                            index={index}
                                            input={input}
                                            inputs={array}
                                            onChangeInputs={props.onChangeInputs}
                                        />
                                    }
                                </Stack>
                            </ListItem>
                        </React.Fragment>
                    );
                })
            }
        </List>
    );
}

const VersionContainer = styled(Card)(() => ({
    width: '100%',
    minHeight: '100%'
}));
