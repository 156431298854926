//Dependencies
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
//Core
import { Box } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
//Components
import { CartPoiCard } from "./CartPoiCard";
import { CartManualProductCard } from './CartManualProductCard';
import { useUnpackagedProducts } from './utils/unpackagedProduts';

const useStyles = makeStyles({
    title: {
        fontSize: 24,
        fontWeight: 700
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    marginBottom: {
        marginBottom: 15
    }
});

const CartPois = () => {
    const unpackagedProducts = useUnpackagedProducts();

    if (unpackagedProducts.pois.length > 0) {
        return (
            <div>
                <TitleWrapper />
                <hr/>
                {
                    unpackagedProducts.pois.map((item) => {
                        if (item.type === 'manual') {
                            return (
                                <Grid key={ `manual-product-${item.poi.id}` } id={ `manual-product-${item.poi.id}` }>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            marginBottom: 3
                                        }}
                                    >
                                        <CartManualProductCard product={item.poi} />
                                    </Box>
                                </Grid>
                            );
                        }
                        return (
                            <Grid key={ `poi-${item.poi.id}` } container>
                                <Box
                                    sx={{
                                        width: '100%',
                                        marginBottom: 3
                                    }}
                                >
                                    <CartPoiCard poi={item.poi} />
                                </Box>
                            </Grid>
                        );
                    })
                }
            </div>
        );
    }
    return null;
};

function TitleWrapper() {
    const classes = useStyles();
    const { t } = useTranslation();
    const language = useSelector(state => state.header.tmp_language);
    const total_pois = useSelector(state => state.cart.total_pois);
    const unpackagedProducts = useUnpackagedProducts();

    return (
        <Typography className={ classes.title }>
            { t('cart-material.total-poi') } ({unpackagedProducts.pois.length}) :{' '}
            { total_pois.map((currency_cost, currency_index) => `${currency_index > 0 ? ' + ' : ''}${currency_cost.cost.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}${currency_cost.currency.symbol}`) }
        </Typography>
    );
}

export default CartPois;
