import React, { Fragment, useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from "@material-ui/core/Grid";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBefore from '@material-ui/icons/NavigateBefore';

import RenderCardContent  from './RenderCardContent';

const useStyles = makeStyles({
    checked: {
        fill: "black"
    },
    dialogActions: {
        padding: 24
    },
    underline: {
        textDecoration: 'underline'
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    checkbox: {
        fontSize: "2rem"
    },
    capitalized: {
        textTransform: "capitalize"
    }
});

const MobileCard = ({mobileDialog, setMobileDialog, contract_info, car_contract, user, cars_results, step_info, location_index, car_index, setLocationIndex, setCarIndex, agency_options, router, enqueueSnackbar}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const handleClose = () => {
		setMobileDialog(false);
	} 
	return (
    	<Dialog open={ mobileDialog } onClose={ handleClose } fullWidth fullScreen={ fullScreen } maxWidth={ "md" }>
    	    <DialogTitle onClose={ handleClose }>
    	        <Grid container justify={ "space-between" } alignItems={ "center" }>
                    <Grid item>
                        <IconButton edge={ "start" } onClick={ handleClose }><NavigateBefore/></IconButton>
                    </Grid>
    	        </Grid>
    	    </DialogTitle>
	        <DialogContent>
      			<RenderCardContent
		 			contract_info={contract_info}
		 			car_contract={car_contract}
		 			user={user}
		 			cars_results={cars_results}
		 			step_info={step_info}
		 			location_index={location_index}
		 			car_index={car_index}
		 			setLocationIndex={setLocationIndex}
		 			setCarIndex={setCarIndex}
		 			agency_options={agency_options}
		 			router={router}
		 			enqueueSnackbar={enqueueSnackbar}
		 		/>
	        </DialogContent>
      	</Dialog>
	)
}
export default React.memo(MobileCard);