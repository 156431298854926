import Axios from 'axios';
import { Box,
    CircularProgress,
    FormControl,
    formHelperTextClasses,
    Grid,
    InputAdornment,
    keyframes,
    MenuItem,
    outlinedInputClasses,
    Popper,
    Select,
    selectClasses,
    Stack,
    TextField,
    Theme,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import React, { Fragment, useEffect, useState } from 'react';
import { Traveler } from '../../Reducers/objects/Traveler';
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import CrmUserDetailTravelerFormDialog from "../Menu/Crm/CrmUserDetail/dialogs/CrmUserDetailTravelerFormDialog";

import { SetSelectedUser } from "../../Actions/Crm.js";
import { getUsers, postUser } from "../Menu/Crm/Functions/UserFunctions";
import { AccountBoxRounded, AccountCircle } from '@mui/icons-material';
import { TravelerSourceCategory } from './objects/knownTravelerSourceCategory';


const filter = createFilterOptions();

const useStyles = makeStyles(theme => ({
    border: {
        '&& .MuiOutlinedInput-root': {
            borderRadius: 8
        }
    },
}));

type CrmTravelerProps = {
    setTempContactLead: (contact: Traveler) => void,
    traveler: Traveler,
    tempTravelers: Traveler[],
    setTempTravelers: (travelers: Traveler[]) => void,
    tempContactLead: Traveler,
    contactOnly: boolean
}
let source:any = null;

const PopperMy = function (props: any) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return (<Popper {...props} style={{ width: matches ? 700 : 300 }} placement={'bottom-start'} />);
};

export function CrmTraveler(props: CrmTravelerProps): JSX.Element {
    const { setTempContactLead, traveler, tempTravelers, setTempTravelers, tempContactLead } = props;
    
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const selected_user = useSelector(store => store.crm.selected_user);
    const user = useSelector(store => store.user.user);

    const [optionsType, setOptionsType] = useState<TravelerSourceCategory>('contacts');
    const [travelerslist, setTravelerslist] = useState<Traveler[]>([]);
    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [currentTraveler, setCurrentTraveler] = useState<Traveler|null>(null);
    const [openTravelerForm, setOpenTravelerForm] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            if (optionsType === 'contacts') {
                getTravelerData();
            } else {
                let companions:Traveler[] = [];
                tempContactLead.travel_companions.map((companion:Traveler) => {
                    let tmp = { ...companion };
                    tmp.title_name = `${tmp.first_name} ${tmp.last_name}`;
                    companions.push(tmp);
                });
                setTravelerslist(companions);
            }
        }
    }, [open, search]);
    const closeDialog = () => {
        dispatch(SetSelectedUser({ createCode: null }));
        setOpenTravelerForm(false);
    };
    const transformBirthDate = (inputDate:string) => {
        const dateObject = new Date(inputDate.substring(0, 10));
        //eslint-disable-next-line no-extend-native
        Date.prototype.toLocaleISOString = function () {
            return this.getFullYear() +
                '-' + ('0' + (this.getMonth() + 1)).slice(-2) +
                '-' + ('0' + this.getDate()).slice(-2) +
                'T' + ('0' + this.getHours()).slice(-2) +
                ':' + ('0' + this.getMinutes()).slice(-2) +
                ':' + ('0' + this.getSeconds()).slice(-2) +
                '.' + ('00' + this.getMilliseconds()).slice(-3) +
                'Z';
        };
        return dateObject.toLocaleISOString();
    };
    const createUser = (traveler: Traveler) => {
        setOpenTravelerForm(false);
        let isoDate = null;
        let isoWeddingDate = null;
        if (traveler.birth_date) {
            isoDate = transformBirthDate(traveler.birth_date);
        }
        if (traveler.wedding_date) {
            isoWeddingDate = transformBirthDate(traveler.wedding_date);
        }
        const formatted_user = { ...traveler, zip_code: traveler.postal_code, street: traveler.address, birth_date: isoDate, wedding_date: isoWeddingDate, type: "LEAD" };

        postUser(user.client, formatted_user, (resp) => {
            if (resp) {
                // let user_list_updated = JSON.parse(JSON.stringify(user_list));
                // user_list_updated.push(resp.data);
                let traveler_data:Traveler = { ...resp.data };
                traveler_data.title_name = `${traveler.first_name} ${traveler.last_name} - ${traveler.email}`;
                let traveler_list:Traveler[] = [...travelerslist];
                traveler_list.unshift(traveler_data);
                setTravelerslist(traveler_list);
                selectTraveler(resp.data);
                setCurrentTraveler(traveler_data);
                dispatch(SetSelectedUser(null));
            }
        });
    };
    const getTravelerData = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            // if (search.length >= 3) {
            if (source !== null) {
                source.cancel('Operation canceled by the user.');
            }
            //Save the cancel token for the current request
            source = Axios.CancelToken.source();
            Axios({
                method: "GET",
                headers: headers,
                url: search.length >= 3 ? `${API_HREF}crm/${window.id_owner}/user/?ordering=-date_joined&is_system_generated=false&all_user=true&is_crm_contact=true` : `${API_HREF}crm/${window.id_owner}/user/?limit=20&offset=0&ordering=-date_joined&is_system_generated=false&all_user=true&is_crm_contact=true`,
                params: {
                    search: search
                },
                cancelToken: source.token
            }).then(function (response) {
                let data = response.data;
                if (data.results.length > 0) {
                    let traveler_data:Traveler[] = [...data.results];
                    traveler_data.map((traveler:Traveler) => {
                        traveler.title_name = `${traveler.first_name} ${traveler.last_name} - ${traveler.email}`;
                    });
                    setTravelerslist(traveler_data);
                } else {
                    setTravelerslist([]);
                }
                setLoading(false);
            }).catch(function (error) {
                if (!Axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                    setError(true);
                } else {
                    setError(true);
                    setLoading(false);
                }
                //TODO: show snackbar error
            });
            // } else {
            //     if (source !== null) {
            //         source.cancel('Operation canceled by the user.');
            //     }
            //     //Save the cancel token for the current request
            //     source = Axios.CancelToken.source();
            //     Axios({
            //         method: "GET",
            //         headers: headers,
            //         url: `${API_HREF}client/${window.id_owner}/user/?limit=20&offset=0&ordering=-date_joined`,
            //         cancelToken: source.token
            //     }).then(function (response) {
            //         let data = response.data;
            //         if (data.results.length > 0) {
            //             let traveler_data:Traveler[] = [...travelerslist];
            //             data.results.map((traveler:Traveler) => {
            //                 traveler.title_name = `${traveler.first_name} ${traveler.last_name} - ${traveler.email}`;
            //                 traveler_data.push(traveler);
            //             });
			// 	       	setTravelerslist(traveler_data);
            //             setLoading(false);
            //         }
            //     }).catch(function (error) {
            //         if (!Axios.isCancel(error)) {
            //             console.log('Request canceled', error.message);
            //             setError(true);
            //         } else {
            //             setError(true);
            //             setLoading(false);
            //         }
            //         //TODO: show snackbar error
            //     });
            // }
        }
    };
    const selectTraveler = (newValue: Traveler) => {
        let new_contact_lead = Object.assign({}, traveler);
        new_contact_lead.first_name = newValue.first_name;
        new_contact_lead.last_name = newValue.last_name;
        if (newValue.birth_date) {
            new_contact_lead.birth_date = newValue.birth_date;
        }
        new_contact_lead.city = newValue.city;
        new_contact_lead.email = newValue.email;
        if (newValue.gender) {
            new_contact_lead.gender = newValue.gender;
        }
        new_contact_lead.phone_number = newValue.phone_number !== '' ? newValue.phone_number : newValue.cellphone_number;
        new_contact_lead.cellphone_number = newValue.cellphone_number;
        new_contact_lead.address = newValue.street;
        if (newValue.country) {
            new_contact_lead.country = newValue.country;
        }
        new_contact_lead.postal_code = newValue.zip_code;
        if (newValue.title_name) {
            new_contact_lead.title_name = newValue.title_name;
        }
        new_contact_lead.travel_companions = newValue.travel_companions;
        new_contact_lead.from_id = newValue.id;
        setCurrentTraveler(new_contact_lead);
        if (tempContactLead.id === traveler.id) {
            setTempContactLead(new_contact_lead);
        }
        // console.log(newValue);
        for (let traveler_index:number = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let newTravelers:Traveler[] = tempTravelers.slice();
                newTravelers[traveler_index] = new_contact_lead;
                // if (tempContactLead.id !== traveler.id) {
                //     newTravelers[traveler_index].id = newValue.id;
                // }
                setTempTravelers(newTravelers);
            }
        }
    };
    return (
        <Grid>
            <Autocomplete
                freeSolo
                options={travelerslist}
                onOpen={() => {
                    setOpen(true);
                }}
                value={currentTraveler}
                onClose={(event: React.SyntheticEvent, reason: string) => {
                    setLoading(false);
                    setOpen(false);
                }}
                onInputChange={(event, newInputValue) => {
                    setOpen(true);
                    setLoading(true);
                    setSearch(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        // className={classes.border}
                        margin={"normal"}
                        // variant={"outlined"}
                        onChange={(e: any) => setSearch(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FormControl
                                        sx={{
                                            [`& .${outlinedInputClasses.root}`]: {
                                                paddingTop: '0 !important',
                                                paddingBottom: '0 !important',
                                                paddingRight: '0 !important',
                                                marginLeft: '-9px !important'
                                            }
                                        }}
                                    >
                                        <Select
                                            // size="small"
                                            value={optionsType}
                                            onChange={(event) =>
                                                setOptionsType(
                                                    event.target
                                                        .value as typeof optionsType
                                                )
                                            }
                                            renderValue={(value: string) => {
                                                return (
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        {value ===
                                                            'traveler-companions' && (
                                                            <AccountBoxRounded fontSize="inherit" />
                                                        )}
                                                        {value === 'contacts' && (
                                                            <AccountCircle fontSize="inherit" />
                                                        )}
                                                        <Typography
                                                            variant="body2"
                                                            lineHeight={1}
                                                            component="span"
                                                        >
                                                            {t(
                                                                `crm.demands.trip-companion-picker-${value}`
                                                            )}
                                                        </Typography>
                                                    </Stack>
                                                );
                                            }}
                                            sx={{
                                                [`.${selectClasses.select}`]: {
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }
                                            }}
                                        >
                                            {!props.contactOnly && (
                                                <MenuItem value="traveler-companions">
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        <AccountBoxRounded fontSize="inherit" />
                                                        <Typography
                                                            variant="body2"
                                                            lineHeight={1}
                                                            component="span"
                                                        >
                                                            {t(
                                                                'crm.demands.trip-companion-picker-traveler-companions'
                                                            )}
                                                        </Typography>
                                                    </Stack>
                                                </MenuItem>
                                            )}
                                            <MenuItem value="contacts">
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={1}
                                                >
                                                    <AccountCircle fontSize="inherit" />
                                                    <Typography
                                                        variant="body2"
                                                        lineHeight={1}
                                                        component="span"
                                                    >
                                                        {t(
                                                            'crm.demands.trip-companion-picker-contacts'
                                                        )}
                                                    </Typography>
                                                </Stack>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <Fragment>
                                    {
                                        loading ? <CircularProgress color="inherit" size={20} /> : null
                                    }
                                </Fragment>
                            )
                        }}
                    />
                )}
                renderOption={(props, option) => {
                    return (
                        <Box {...props} key={option.id} component="li">
                            {`${option.title_name}`}
                        </Box>
                    );
                }}
                getOptionDisabled={(option) => {
                    return !!tempTravelers?.some((item) => {
                        return (
                            item.id === option.id
                        );
                    });
                }}
                getOptionLabel={(option) => `${option.title_name}`}
                onChange={(event, newValue) => {
                    if (newValue !== null && newValue.id !== undefined && tempTravelers !== undefined) {
                        selectTraveler(newValue);
                    } else {
                		dispatch(SetSelectedUser({ createCode: "CREATE" }));
                        setOpenTravelerForm(true);
                    }
                }}
    			PopperComponent={PopperMy}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
            
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => {
                        return option.title.includes(inputValue);
                    });
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            title_name: `${t('accommodation.add')} "${inputValue}"`
                        });
                    }
            
                    return filtered;
                }}
            />
            {
                selected_user !== undefined && selected_user !== null && selected_user.createCode === 'CREATE' &&
                <CrmUserDetailTravelerFormDialog
                    open={openTravelerForm}
                    handleClose={closeDialog}
                    onConfirm={createUser}
                    mode={'user'}
                    title={t('crm.create_contact')}
                />
            }
        </Grid>
    );
}


