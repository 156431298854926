// Dependencies
import { useTranslation } from 'react-i18next';
import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Core & Lab
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
// Actions
import { setSearch } from './redux/actions';
// Types
import { AppState } from '../../../../Reducers/Reducers';

const PictureModalSearch: FC = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const search = useSelector((state: AppState) => state.picture.search);
    const onSearchChange = (event: { target: { value: any; }; }) => {
        dispatch(setSearch(event.target.value));
    };
    return (
        <FormControl fullWidth variant={ 'outlined' }>
            <InputLabel htmlFor={ 'picture-search' }>{ t<string>('shared.search-picture') }</InputLabel>
            <OutlinedInput
                id={ 'picture-search' }
                type={ 'text' }
                onChange={ onSearchChange }
                label={ t<string>('shared.search-picture') }
                value={ search }
                autoComplete={ 'off' }
            />
        </FormControl>
    );
};

export default PictureModalSearch;
