//---- Dependencies ----//
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles} from "@material-ui/core/styles";
import i18n from "../../../i18n.jsx";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, Slide } from "@mui/material";
import { Close, NavigateBefore } from "@mui/icons-material";


//---- CSS ----//
import "../../../Style/Accommodation.css";

//---- Actions ----//
import { toggleMTRIPModal } from "../../../Actions/Menu";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    listMargin:{
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft:1
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A",
        fontWeight: 500,
        letterSpacing: 1.25
    },
    fontSize24: {
        fontSize: 24
    },
    fontSize13:{
        fontSize: 13
    },
    fontSize18:{
        fontSize: 18
    },
    upperCase:{
        textTransform: "uppercase",
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//---- Class ----//
const MTRIPModal = (() => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const smallScreen = useMediaQuery('(max-width:425px)');
    const mtrip_modal = useSelector(store => store.menu.mtrip_modal);
    const mtrip_data = useSelector(store => store.menu.mtrip_data);
    moment.locale(i18n.language);
    return(
        <Box display='flex'>
            <Dialog open={mtrip_modal} fullScreen={ smallScreen === true ? true : false} TransitionComponent={Transition} fullWidth maxWidth={"sm"}>
                <DialogTitle id={"mcto-dialog"}>
                    <Grid container justifyContent={ smallScreen === true ? "flex-start" : "space-between" } alignItems={ "center" }>
                        {
                            smallScreen && (
                                <Grid item style={{marginRight: "5%"}}>
                                    <IconButton onClick={() => {dispatch(toggleMTRIPModal());}} size='small'> <NavigateBefore /> </IconButton>
                                </Grid>
                            )
                        }
                        <Grid item className={`${classes.genericText} ${classes.upperCase} ${classes.fontSize24}`}>
                            { t<string>("menu.trip_list.mtrip_list") }
                        </Grid>

                        {
                            !smallScreen && (
                                <Grid item>
                                    <IconButton onClick={() => {dispatch(toggleMTRIPModal());}} size='small'> <Close style={{float: "right"}}/> </IconButton>
                                </Grid>
                            )
                        }            
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    {
                        mtrip_data !== null && mtrip_data.length !== 0 && (
                            <List>
                                {
                                    mtrip_data.map((mtrip:any, index_mtrip:number) => {
                                        return(
                                            <ListItem key={"mtrip-" + index_mtrip} className={`${classes.genericText} ${classes.fontSize13} ${classes.listMargin}`}>
                                                {"MTRIP"} {t<string>("global.number") + (mtrip.version + 1) + " "}
                                                {t<string>("global.created_date") + " " + moment.utc(mtrip.date).format("L") + " " + moment.utc(mtrip.date).local().format("LT")}
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                    )
                    }
                    {
                        mtrip_data !== null && mtrip_data.length === 0 && (
                            <div className={`${classes.genericText} ${classes.fontSize18}`}>
                                {t<string>("menu.trip_list.no_mtrip")}
                            </div>
                        )
                    }
                </DialogContent>
            </Dialog>
        </Box>
    )
})

export default React.memo(MTRIPModal);