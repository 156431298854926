/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import '../../crm.css';
import { useTranslation } from "react-i18next";

import { createTraveler, getAllTravelers, getUser, patchUser } from '../../Functions/UserFunctions';

import { useDispatch, useSelector } from "react-redux";
import { SetSelectedUser } from "../../../../../Actions/Crm";
import { SetUserList } from "../../../../../Actions/User";
import CrmUserDetailCompanionField from "./CrmUserDetailCompanionField";
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CrmUserDetailTravelerFormDialog from "../dialogs/CrmUserDetailTravelerFormDialog";

const CrmUserDetailCompanion = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const selected_user = useSelector(store => store.crm.selected_user);
    const user_client = useSelector(store => store.user.user);
    const user_list = useSelector(store => store.user.user_list);

    const [travelers, setTravelers] = React.useState([]);
    useEffect(() => {
        if (user_client) {
            getAllTravelers(user_client.client, 1, 30, null, (res) => {
                setTravelers(res?.data?.results);
            });
        }
    }, [user_client]);

    const addNewAndUpdateCompanion = (traveler) => {
        createTraveler(user_client.client, traveler, (resp) => {
            if (resp.data?.id) {
                let comp_ids = selected_user?.travel_companions?.map(e => e.id);
                if (!comp_ids?.length) {
                    comp_ids = [];
                }
                comp_ids.push(resp.data.id);
                updateCompanion(comp_ids, closeDialog());
            }
        });
    };

    const updateCompanion = (newValue, callback) => {
        if (selected_user) {
            let user_temp = JSON.parse(JSON.stringify({ ...selected_user }));
            user_temp.remove_companions = user_temp.travel_companions?.map(e => e.id)?.filter((value) => !newValue.includes(value));
            user_temp.travel_companions = newValue;
            patchUser(user_client.client, user_temp, ["travel_companions", "remove_companions"], () => {
                getUser(user_client.client, user_temp.id, (resp) => {
                    if (user_list) {
                        let user_list_updated = JSON.parse(JSON.stringify(user_list));
                        const index = user_list_updated.findIndex(u => u.id === resp.data?.id);
                        if (index !== -1) {
                            user_list_updated[index] = resp.data;
                            dispatch(SetUserList(user_list_updated));
                        }
                    }
                    dispatch(SetSelectedUser(resp.data));
                    if (callback !== null && callback !== undefined) {
                        callback();
                    }
                });
            });
        }
    };

    const [openAddTraveler, setOpenAddTraveler] = React.useState(false);
    const closeDialog = () => {
        setOpenAddTraveler(false);
    };

    if (selected_user) {
        return (
            <div className="companion-container">
                <div>
                    <CrmUserDetailCompanionField
                        label={t("crm.title.companion")}
                        options={[]}
                        value={selected_user.travel_companions}
                        callback={(v) => updateCompanion(v.newValue, v.onFinish)} />
                </div>
                <Button variant="text" style={{ textTransform: "unset" }} onClick={() => setOpenAddTraveler(true)} startIcon={<AddCircleOutlineIcon />}>{t('crm.create_companion')}</Button>
                <CrmUserDetailTravelerFormDialog open={openAddTraveler} handleClose={closeDialog} onConfirm={addNewAndUpdateCompanion} mode={'companion'} />
            </div>
        );
    }
    return null;
};
export default CrmUserDetailCompanion;
