import axios from "axios";
import { SetManualProviders, SetProviders } from "../../../Actions/Trip";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";

const GetProviders = (dispatch) => {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        let provider_request = [];
        provider_request.push(
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/providers/?limit=1000`,
            })
        );
        provider_request.push(
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/custom-providers/?limit=10000&reduced=true`,

            })
        );
        axios.all([...provider_request]).then(axios.spread((...responses) => {
            let providers = responses[0].data.results.slice();
            let manual_products = responses[1].data.results.slice();
            dispatch(SetProviders(providers));
            dispatch(SetManualProviders(manual_products));
        }));
    }
}
export default GetProviders;