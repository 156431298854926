import React from "react";
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    TextField,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";

export type MailTemplateVisualEditorCornersOption = {
    rounded?: boolean,
    topLeft: number,
    topRight: number,
    bottomLeft: number,
    bottomRight: number
}

type Props = {
    canBeRounded?: boolean,
    corners: MailTemplateVisualEditorCornersOption,
    onChangeCorner: <K extends keyof MailTemplateVisualEditorCornersOption>(
        type: K,
        value: MailTemplateVisualEditorCornersOption[K]
    ) => void
}

export function MailTemplateVisualEditorBorderCornersOption(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <MailTemplateVisualEditorOptionsSection>
            <Typography>{t<string>('shared.mail-template-visual-editor-border-corners')}</Typography>
            <Grid container spacing={1}>
                {
                    props.canBeRounded &&
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                label={t<string>('shared.mail-template-visual-editor-border-corners-rounded')}
                                control={<Checkbox checked={props.corners.rounded} />}
                                onChange={(_, checked) => props.onChangeCorner('rounded', checked)}
                            />
                        </FormGroup>
                    </Grid>
                }
                <Grid item xs={3}>
                    <TextField
                        type="number"
                        variant="outlined"
                        label={t<string>('shared.mail-template-visual-editor-top-left')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">px</InputAdornment>
                        }}
                        value={props.corners.topLeft}
                        onChange={(event) => {
                            props.onChangeCorner('topLeft', parseInt(event.target.value));
                        }}
                        disabled={props.corners.rounded}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        type="number"
                        variant="outlined"
                        label={t<string>('shared.mail-template-visual-editor-top-right')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">px</InputAdornment>
                        }}
                        value={props.corners.topRight}
                        onChange={(event) => {
                            props.onChangeCorner('topRight', parseInt(event.target.value));
                        }}
                        disabled={props.corners.rounded}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        type="number"
                        variant="outlined"
                        label={t<string>('shared.mail-template-visual-editor-bottom-left')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">px</InputAdornment>
                        }}
                        value={props.corners.bottomLeft}
                        onChange={(event) => {
                            props.onChangeCorner('bottomLeft', parseInt(event.target.value));
                        }}
                        disabled={props.corners.rounded}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        type="number"
                        variant="outlined"
                        label={t<string>('shared.mail-template-visual-editor-bottom-right')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">px</InputAdornment>
                        }}
                        value={props.corners.bottomRight}
                        onChange={(event) => {
                            props.onChangeCorner('bottomRight', parseInt(event.target.value));
                        }}
                        disabled={props.corners.rounded}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </MailTemplateVisualEditorOptionsSection>
    );
}
