//---- Dependencies ----//
import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { Link, History } from "react-router";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

//---- Functions ----//

//---- Components ----//
import SummaryStepCars from "./SummaryStepCars.jsx";
import SummaryStepFlight from "./SummaryStepFlight.jsx";
import SummaryStepFlightMobile from "./SummaryStepFlightMobile.jsx";

//---- Actions ----//
import { SetCarsCart } from "../../../Actions/Summary";

//---- CSS ----//
import "../../../Style/ItineraryType.css";
import "../../../Style/GlobalAgencies.css";
import "../../../Style/Summary.css";

//---- Class ----//
@connect((store) => {
    return {
        itinerary_type_details_data: store.itinerary_type.itinerary_type_details_data,
        cars_cart: store.summary.cars_cart,
        flight_cart: store.summary.flight_cart,
        trip_info: store.summary.trip_info,
        manual_cart: store.summary.manual_cart
    };
})
@withTranslation()
@withRouter
class SummaryStepTransport extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = { 
            show_details: false
        };
    }

    componentWillMount() {
    }

     render() {
        const { cars_cart, trip_info, transport, step, t, component, dispatch } = this.props;
        let origin_city = null;
        let dest_city = null;
        let departure_locale = null;
        let departure_date = null;
        let name = null;
        let total_flight_duration = 0;
        let adults = 0;
        let children = 0;
        let baby = 0;
        let today = new Date();
        let year = today.getFullYear();
        if (transport !== null && transport.type_details === "FLIGHT" && transport.has_more_details === true && transport.segment !== null) {
            $.each(transport.more_details.group_passenger.travelers_list, function (index_travelers, value_travelers) {
                let age = moment.utc(trip_info.end_date).diff(moment.utc(value_travelers.birth_date), "y");
                if (age <= 12) {
                    children += 1;
                } else if (age <= 2) {
                    baby += 1;
                } else if (age > 12) {
                    adults += 1;
                }
            });
            let last_leg = transport.segment.legs.length - 1;
            let flight_duration = 0;
            for (let i = 0; i < transport.segment.legs.length; i++) {
                flight_duration += transport.segment.legs[i].flight_time;
            }
            total_flight_duration = (parseInt(flight_duration / 60) < 10 ? "0" + parseInt(flight_duration / 60) : parseInt(flight_duration / 60)) + 'h' + ((flight_duration%60 < 10) ? "0" + (flight_duration% 60) : (flight_duration%60));       
            departure_locale = transport.segment.legs[0].departure_time.split("Z");
            departure_date = moment.utc(departure_locale[0]).format("dddd Do MMMM YYYY");
            origin_city = transport.segment.legs[0].origin !== null ? transport.segment.legs[0].origin.iata_city.international_name : transport.segment.legs[0].origin_station.iata_city.international_name;
            dest_city = transport.segment.legs[last_leg].destination !== null ? transport.segment.legs[last_leg].destination.iata_city.international_name : transport.segment.legs[last_leg].destination_station.iata_city.international_name;
        }
        console.log('transport:', transport);
        if (transport === null) {
            name = step.r2r_json.name_string;
        } else {
            if (transport.has_more_details === true && transport.type_details === "FLIGHT") {
                name = step.r2r_json.name_string + " " + origin_city + " → " + dest_city + ", " + departure_date;
            } else if (transport.has_more_details === true && transport.type_details === "CAR") {
                name = step.r2r_json.name_string + " : " + transport.more_details.name + " " + transport.more_details.vehicle_category;
            } else {                
                name = step.r2r_json.name_string;
            }
        }
        let transport_distance = (transport === null ? step.r2r_json.distance_string : (transport.has_more_details === false && transport.type_details !== "FLIGHT" || transport.has_more_details === true && transport.type_details !== "FLIGHT") ? step.r2r_json.distance_string : transport.segment.convert_distance_total + "km");
        return (
            <Fragment>
                <div className={"step-transport-main-container" + " " + step.r2r_json.vehicle.kind }>
                    <div className={"icon-step-transport-container" + " " + step.r2r_json.vehicle.kind } >
                        <i className={"ft-contain-img icon-step-transport-icon icon-" + step.r2r_json.vehicle.kind } />
                    </div>
                    <div className={"step-transport-title-container row no-margin"}>
                        <span className={"ft-dark-green bold col-md-9 col-12 ft-mt10"}> { name } </span>
                        <div className={transport_distance !== "0km" ? "mobile-distance col-md-1 col-2 text-right no-padding ft-mt10 bold" : "mobile-distance col-md-2 col-2 text-right no-padding ft-mt10 bold"} >
                            { (transport === null ? step.r2r_json.duration_string : (transport.has_more_details === false && transport.type_details !== "FLIGHT" || transport.has_more_details === true && transport.type_details !== "FLIGHT") ? step.r2r_json.duration_string : total_flight_duration) }
                        </div>
                        {
                            transport_distance !== "0km" && (
                                <div className={"mobile-distance col-md-2 col-2 no-padding text-center ft-mt10 ft-purple bold"} >
                                    { transport_distance }
                                </div>
                            )
                        }
                        {
                            transport !== null && transport.type_details === "FLIGHT" && (
                                <ol className={"adults-container-flight col-md-12 col-5"}>
                                    <li className={"ft-gray"}>
                                        <span className={"text-container-passenger"}>{ adults > 0 ? (adults > 1 ? adults + " " + t("flight_search.adults") : adults + " " + t("flight_search.adult")) : null }</span>
                                    </li>
                                    <li className={"ft-gray"}>
                                        <span className={"text-container-passenger"}>{ children > 0 ? ( children > 1 ? children + " " + t("flight_search.children") : children + " " + t("flight_search.child")) : null }</span>
                                    </li>
                                    {/*                       
                                    <li className={"ft-gray"}>
                                        <span className={"text-container-passenger"}>{ baby }</span>
                                    </li>*/}
                                </ol>
                            )
                        }
                        <div className={ "col-md-6 italic mt15" }>
                            <div className={"checkbox-container"} >
                                <label className={ "cart-checkbox" }>
                                    Ce produit est dans le devis
                                    <input type={ "checkbox" } checked={ transport.more_details.is_optional === false ? true : false } onChange={(e) => {
                                        if (transport.type_details === "CAR") {
                                            let tmp_cars_cart = cars_cart.slice();
                                            let index = null;

                                            for (let i = 0; i < tmp_cars_cart.length; i++) {
                                                if (tmp_cars_cart[i].id === transport.more_details.id) {
                                                    index = i;
                                                }
                                            }

                                            if (index !== null) {
                                                tmp_cars_cart[index].is_optional = !tmp_cars_cart[index].is_optional;
                                            }
                                            
                                            for (let i = 0; i < tmp_cars_cart.length; i++) {
                                                if (transport.more_details.id === tmp_cars_cart[i].id) {
                                                    tmp_cars_cart[i] = transport.more_details;
                                                }
                                            }

                                            dispatch(SetCarsCart(tmp_cars_cart));
                                        }

                                        if (component.state.save_bar === false) {
                                            component.setState({
                                                save_bar: true
                                            })
                                        }
                                     }}/>
                                    <span className={ "cart-check-mark" }/>
                                </label>
                            </div>
                        </div>
                    </div>
                    {
                        transport !== null && (
                            <Fragment>
                                {
                                    transport.has_more_details === true && (
                                        <div className={transport.type_details !== "FLIGHT" ? "content-more-detail ft-basic-link" : "content-more-detail ft-basic-link is-flight"} onClick={() => {
                                            this.setState({
                                                show_details: !this.state.show_details
                                            });
                                        }} > { this.state.show_details === false ? "+ de détails" : "- de détails" }  </div>
                                    )
                                }
                            </Fragment>
                        )
                    }
                   
                </div>
                {
                        transport !== null && (
                            <Fragment>                               
                                {
                                    (this.state.show_details === true && transport.type_details === "CAR") && (
                                        <SummaryStepCars details={transport.more_details} step={step} />
                                    )
                                }
                                {
                                    (this.state.show_details === true && transport.type_details === "FLIGHT" && screen.width >= 992) && (
                                        <SummaryStepFlight details={transport.more_details} transport={transport} step={step} />
                                    )
                                }
                                {
                                    (this.state.show_details === true && transport.type_details === "FLIGHT" && screen.width < 992) && (
                                        <SummaryStepFlightMobile details={transport.more_details} transport={transport} step={step} />
                                    )
                                }
                            </Fragment>
                        )
                    }
            </Fragment>
        );
    }
}

export default SummaryStepTransport;
