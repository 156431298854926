//---- Dependencies ----//
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";

//---- CSS ----//
import "../../Style/TransportPanel.css";
import "../../Style/CarsSearch.css";
import "../../Style/Poi.css";

//---- Functions ----//
import Notify from "../Common/Notify";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import SetInitialData from "../Itinerary/Functions/SetInitialData.js";

//---- Components ----//
import GenerateVehicles from "./GenerateVehicles.jsx";
import RenderTransferDate from "./RenderTransferDate.jsx";

@connect((store) => {
    return {
        itinerary_list: store.itinerary.itinerary_list,
        trip_id: store.trip.trip_id,
        traveler_groups: store.trip.traveler_groups,
        departure_destination: store.itinerary.departure_destination,
        return_destination: store.itinerary.return_destination,
        cars_step: store.cars_search.steps,
        user: store.user.user,
        travelers: store.trip.travelers,
        trip_end_date: store.trip.end_date,
    };
})
@withTranslation()
class CityTransfers extends React.Component {
	constructor(props, context) {
	    super(props, context);
	    const { travelers, traveler_groups } = this.props;
	    let groups = [];
	    for (let i = 0; i < traveler_groups.length; i++) {
	        if (traveler_groups[i].default) {
	            let tmp_group = Object.assign({}, traveler_groups[i]);
	            tmp_group.open = false;
	            groups.push(tmp_group);
	        }
	    }
	    this.state = {
	        vehicle_results: [],
	        segment_list: [],
	        arrival_date: null,
	        checked: null,
	        segment_checked: null,
	        focus_start: null,
	        focus_end: null,
	        start_date: null,
	        end_date: null,
	        hour_start: "12",
	        minute_start: "00",
	        hour_end: "12",
	        minute_end: "00",
	      	is_return: false,
	      	passenger_open: false,
	      	groups: groups,
	      	travelers: [...travelers]
	    };
	}
	componentDidUpdate(prevProps, prevState) {
		const { segment_checked } = this.state;
		const { itinerary_list, departure_destination, return_destination, dispatch } = this.props;
		const { pass_check, headers } = CheckBeforeRequest();
		let component = this;
		if (prevState.segment_checked !== segment_checked && segment_checked !== null) {
			let request = {
				origin_dest: itinerary_list[segment_checked].destination.id,
				town_transfer: true
			};
			if (pass_check) {
				$.ajax({
				    method: "GET",
				    headers: headers,
				    url: `${API_HREF}client/${window.id_owner}/transfer-segment/`,
				    data: request,
				    success: function (data) {
				    	let results = data.results.slice();
				    	results.map((result) => {
					    	result.start_date = moment(itinerary_list[segment_checked].start_date);
					    	result.end_date = moment(itinerary_list[segment_checked].end_date);
				    	});
				    	component.setState({
				    		segment_list: results,
				    		start_date: moment(itinerary_list[segment_checked].start_date),
				    		end_date: moment(itinerary_list[segment_checked].end_date)
				    	}, () => {
				    		SetInitialData(itinerary_list, departure_destination, return_destination, dispatch);
				    	});
				    },
				    error: function (error) {
				        console.log(error);
				    }
				});
			}
		}
	}

	getVehicles() {
		const { checked, segment_list, segment_checked, groups, start_date } = this.state;
		const { pass_check, headers } = CheckBeforeRequest();
		const { trip_id, itinerary_list, traveler_groups, t } = this.props;
		let current_group = traveler_groups.find((group) => {
			return group.default === true;
		});
		let component = this;
		let new_group = false;
		groups[0].travelers_list.map((traveler) => {
			let find = current_group.travelers_list.find((el) => {
				return el.id === traveler.id;
			});
			if (find === undefined) {
				new_group = true;
			}
		});
		if (pass_check) {
			if (new_group) {
				let new_arr = [];
				groups[0].travelers_list.map((el) => {
					new_arr.push(el.id);
				});
				let request = {
				    travelers: new_arr
				};
				$.ajax({
				    method: "POST",
				    headers: headers,
				    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/`,
				    data: JSON.stringify(request),
				    success: function (data) {
				        let request1 = {
				        	custom_provider: true,
				        	segment: segment_list[checked].id,
				        	arrival_date: moment(start_date).format("YYYY-MM-DD"),
				        	comps: data.id,
				        	trip: trip_id,
							start_destination: itinerary_list[segment_checked].destination.id
				        };
				        $.ajax({
				            method: "GET",
				            headers: headers,
				            url: `${API_HREF}client/${window.id_owner}/custom-products/product_transfer_prices/`,
				            data: request1,
				            success: function (data1) {
				            	Notify(t("transfers.get_success"), {status: 'success', pos: 'top-center'});
				            	component.setState({
				            		vehicle_results: data1,
				            		arrival_date: itinerary_list[segment_checked].start_date,
				            		groups: [data]
				            	});
				            },
				            error: function (error) {
				                console.log(error);
				            }
				        });
				    },
				    error: function (error) {
				        console.log(error);
				    }
				});
			}
			else {
				let request1 = {
					custom_provider: true,
					segment: segment_list[checked].id,
					arrival_date: moment(start_date).format("YYYY-MM-DD"),
					comps: current_group.id,
					trip: trip_id,
					start_destination: itinerary_list[segment_checked].destination.id
				};
				$.ajax({
				    method: "GET",
				    headers: headers,
				    url: `${API_HREF}client/${window.id_owner}/custom-products/product_transfer_prices/`,
				    data: request1,
				    success: function (data) {
				    	Notify(t("transfers.get_success"), {status: 'success', pos: 'top-center'});
				    	component.setState({vehicle_results: data, arrival_date: moment(start_date).format("YYYY-MM-DD")});
				    },
				    error: function (error) {
				        console.log(error);
				    }
				});
			}
		}
	}

	render(){
		const { vehicle_results, segment_list, arrival_date, checked, segment_checked, focus_start, focus_end, is_return, passenger_open, minute_start, minute_end, groups } = this.state;
		const { itinerary_list, router, t } = this.props;
		console.log('segment_list:', segment_list);
  		return(
  			<Fragment>
				<div className={ "cars-title ft-relative" }>
				<button className={"ft-btn-return uppercase ft-white cars-steps-return"} onClick={() => { router.push(`/${window.url_name}/apps/transfers`) }}>{t("global.return")}</button>
				{
					vehicle_results.length === 0 && (
						<h2 className={ "text-center ft-gray" }>{ t("transfers.transfer_title_city") }</h2>
					)
				}
				{
					vehicle_results.length !== 0 && (
						<h2 className={ "text-center ft-gray" }>{ t("transfers.vehicle_title")}</h2>
					)
				}
				</div>
				{
					vehicle_results.length === 0 && (
			   			<div className={"container-choose-flight absolute-center"}>
			   				<div>
				   				<Row>
				   					<Col lg={ 5 }>
					   				{
					   					itinerary_list.map((step, step_index) => {
					   						if (step.step_type !== "START" && step.step_type !== "END") {
					   							let origin = step.destination.data.name !== undefined ? step.destination.data.name.split(",") : step.destination.data.international_name.split(",");
						   						return (
								   					<Col lg={ 12 } key={ step_index }>
									                    <label className={ "flight-search-checkbox" }> {step.step_type === "END" ? dest : (typeof(origin) === "string" ? origin : origin[0])} <span className={ "italic" }>{t("global.from") + " " + moment.utc(step.start_date).format("DD MMMM YYYY") + " " + t("global.to") + " " + moment.utc(step.end_date).format("DD MMMM YYYY")}</span>
									                        <input type={ "checkbox" } checked={ segment_checked === step_index } onChange={ () => (this.setState({segment_checked: step_index}))}
									                            />
									                        <span className={ "flight-search-check-mark-cars" }/>
									                    </label>
								   					</Col>
						   						)
						   					}
					   					})
					   				}
				   					</Col>
				   					{
				   						segment_list.length !== 0 && (
						   					<Col lg={ 7 }>
						   						<Col lg={12}>
							   						<h4 className={"text-center ft-mb10"}>
					   									{t("transfers.transfer_title_itinerary")}
							   						</h4>
						   						</Col>
						   					{
						   						segment_list.map((segment, segment_index) => {
						   							return (
						   								<Col lg={ 12 } key={ segment_index }>
							   								<label className={ "flight-search-checkbox" }> { segment.origin_name} <i className={ "icon-103-long-arrow-pointing-to-the-right" }/> {segment.destination_name}
							   								    <input type={ "checkbox" } checked={ checked === segment_index } onChange={ () => (this.setState({checked: segment_index}))}
							   								        />
							   								    <span className={ "flight-search-check-mark-cars" }/>
							   								</label>
							   							</Col>
						   							)
						   						})
						   					}
						   					</Col>
				   						)
				   					}
				   					{
				   						checked !== null && segment_list.length === 0 && (
				   							<Col lg={ 7 }>
				   								<Col lg={ 12 }>
				   									<h4>
					   									{t("transfers.transfer_city_no_result")}
				   									</h4>
					   							</Col>
				   							</Col>
				   						)
				   					}
				   					{
				   						segment_checked !== null && checked !== null && (
				   							<RenderTransferDate component={this} focus_start={focus_start} focus_end={focus_end} is_return={is_return} passenger_open={passenger_open} data_list={segment_list} minute_start={minute_start} minute_end={minute_end} groups={groups} type={ 0 }/>
				   						)
				   					}
				   				</Row>
			   				</div>
			   				{
			   					checked !== null && segment_checked !== null && (
					   				<Button className={ "ft-btn-return uppercase ft-white ft-container-btn-right" } onClick={() => {this.getVehicles()}}>Suivant</Button>
			   					)
			   				}
			   			</div>
					)
				}
				{
					vehicle_results.length !== 0 && (
						<Fragment>
						<Row className={ "vehicle-container" }>
							<Col lg={6} className={ "offset-md-3 text-center" }>
								<GenerateVehicles component={this} display_vehicles={vehicle_results} arrival_date={arrival_date}/>
							</Col>
						</Row>
						{
							<Row>
								<Col lg={6} className={ "offset-md-3 text-center" }>
									<Button className={ "ft-btn-return uppercase ft-white" } onClick={() => {this.setState({vehicle_results: [], checked: null, segment_checked: null});}}>Précedent</Button>
								</Col>
							</Row>
						}
						</Fragment>
					)
				}
  			</Fragment>
  		);
 	}
}
export default CityTransfers