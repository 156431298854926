import { useCartProducts } from "../../../Itinerary/network/cartProducts";

export function filterCartProductsByUser(cart: ReturnType<typeof useCartProducts>): typeof cart {
    return {
        accommodations: cart.accommodations.filter((item) => {
            return checkUser(item.accommodation.creator?.client?.type);
        }),
        cars: cart.cars.filter((item) => {
            return checkUser(item.car.creator?.client?.type);
        }),
        flights: cart.flights.filter((item) => {
            return checkUser(item.flight?.creator?.client?.type);
        }),
        pois: cart.pois.filter((item) => {
            return checkUser(item.poi.creator?.client?.type);
        }),
        transfers: cart.transfers.filter((item) => {
            return checkUser(item.transfer.creator?.client?.type);
        }),
        trains: cart.trains.filter((item) => {
            return checkUser(item.train.creator?.client?.type);
        }),
        cruises: cart.cruises.filter((item) => {
            return checkUser(item.cruise.creator?.client?.type);
        }),
        ferries: cart.ferries.filter((item) => {
            return checkUser(item.ferry.creator?.client?.type);
        }),
        assistances: cart.assistances.filter((item) => {
            return checkUser(item.assistance.creator?.client?.type);
        }),
        insurances: cart.insurances.filter((item) => {
            return checkUser(item.insurance.creator?.client?.type);
        })
    };
}

function checkUser(userType: number | undefined): boolean {
    return JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code === 'visiteurs' ||
        userType !== 2;
}
