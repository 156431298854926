import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core";

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Box } from '@mui/material';
import { isNumber } from 'lodash';

import CheckBeforeRequest from "../Common/CheckBeforeRequest";

import { EditItemToBook } from "../../Actions/Cart";

import SelectLead from './SelectLead.js';
import ProductMoreInfo from './ProductMoreInfo.js';

import axios from "axios";
import { isProductPackaged } from '../CartMaterial/utils/isProductPackaged';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight700: {
        fontWeight: 700
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize16: {
        fontSize: 16
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    spacer: {
        padding: "8px 0px"
    },
    genericBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box"
    },
    genericPadding: {
        padding: "4px 8px"
    },
    formControlRootSmall: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    formControlRootSmallRed: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    circleBorder: {
        borderRadius: 8
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    validateButton: {
        color: "#FFFFFF",
        backgroundColor: "#2C98F0",
        marginTop: 8
    },
    errorInfo: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        letterSpacing: 1.25,
        color: "red",
        fontSize: 10
    }
}));

const BookingTransferInfo = ({ tempTravelers, setTempTravelers }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const checks_passed = useSelector(store => store.booking.checks_passed);
    const to_book = useSelector(store => store.cart.to_book);
    const trip_id = useSelector(store => store.trip.trip_id);
    const version = useSelector(store => store.trip.data_trip);

    const [transfersToBook, setTransfersToBook] = useState([]);
    const [transfersMoreInfo, setTransfersMoreInfo] = useState([]);
    const [transportCodeError, setTransportCodeError] = useState(false);
    const connectedPackageProducts = useMemo(() => {
        return transfersToBook.filter((transfer) => {
            return isProductPackaged({
                product: transfer,
                stackInfos: version?.stack_info ?? null
            });
        });
    }, [transfersToBook]);

    const transportTypeLabel = useRef(null);
    const transportTypeLabelWidth = transportTypeLabel.current ? transportTypeLabel.current.clientWidth : 111;

    const transportCodeLabel = useRef(null);
    const transportCodeLabelWidth = transportCodeLabel.current ? transportCodeLabel.current.clientWidth : 113;

    const directionLabel = useRef(null);
    const directionLabelWidth = directionLabel.current ? directionLabel.current.clientWidth : 49;

    const transportCompanyLabel = useRef(null);
    const transportCompanyLabelWidth = transportCompanyLabel.current ? transportCompanyLabel.current.clientWidth : 62;

    useEffect(() => {
        let temp_transfers = [];
        let temp_more_info = [];

        to_book.map((product) => {
            if (product.is_displayed === true && (product.booking_status === null || (product.booking_status !== null && product.booking_status.status_booking === "ERROR"))) {
                if (product.product_type === 4) {
                    let temp_product = Object.assign({}, product);
                    temp_product.direction = temp_product.direction !== null ? temp_product.direction : "";
                    temp_product.transport_type = temp_product.transport_type !== null ? temp_product.transport_type : "";
                    temp_product.transport_code = temp_product.transport_code !== null ? temp_product.transport_code : "";
                    temp_product.transport_company = temp_product.transport_company !== null ? temp_product.transport_company : "";
                    temp_transfers.push(temp_product);
                    temp_more_info.push(false);
                }
            }
        });
        setTransfersMoreInfo(temp_more_info);
        setTransfersToBook(temp_transfers);
    }, [to_book]);

    const openMoreInfo = (transfer_index) => {
        let temp_more_info = transfersMoreInfo.slice();
        temp_more_info[transfer_index] = true;
        setTransfersMoreInfo(temp_more_info);
    };

    const handleTransportType = (e, transfer_index) => {
        let temp_transfers = transfersToBook.slice();
        temp_transfers[transfer_index].transport_type = e.target.value;
        setTransfersToBook(temp_transfers);
    };

    const handleTransportCode = (e, transfer_index) => {
        let temp_transfers = transfersToBook.slice();
        temp_transfers[transfer_index].transport_code = e.target.value;
        setTransfersToBook(temp_transfers);
    };

    const handleDirection = (e, transfer_index) => {
        let temp_transfers = transfersToBook.slice();
        temp_transfers[transfer_index].direction = e.target.value;
        setTransfersToBook(temp_transfers);
    };

    const handleTransportCompany = (e, transfer_index) => {
        let temp_transfers = transfersToBook.slice();
        temp_transfers[transfer_index].transport_company = e.target.value;
        setTransfersToBook(temp_transfers);
    };

    const confirmTransfer = (transfer) => {
        if (transfer.transport_code !== "" && transfer.transport_code.length > 7) {
            setTransportCodeError(true);
            enqueueSnackbar(t("booking.transfer_transport_code_limit"), { variant: "error", disableWindowBlurListener: true });
        } else {
            setTransportCodeError(false);
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                let request = {
                    transport_type: transfer.transport_type !== "" ? transfer.transport_type : null,
                    transport_code: transfer.transport_code !== "" ? transfer.transport_code : null,
                    direction: transfer.direction !== "" ? transfer.direction : null,
                    transport_company: transfer.transport_company !== "" ? transfer.transport_company : null
                };
                if (transfer.is_custom) {
                    axios({
                        method: "PATCH",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${version.id}/custom-products/${transfer.id}/`,
                        data: request
                    }).then(function (response) {
                        enqueueSnackbar(t("booking.transfer_confirm_success"), { variant: "success", disableWindowBlurListener: true, autoHideDuration: 3500 });
                        dispatch(EditItemToBook(response.data));
                    }).catch(function (error) {
                        console.log(error.responseJSON);
                        enqueueSnackbar(t("booking.transfer_confirm_failed"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 3500 });
                    });
                } else if (!transfer.is_custom) {
                    axios({
                        method: "PATCH",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${version.id}/transfers/${transfer.id}/`,
                        data: request
                    }).then(function (response) {
                        enqueueSnackbar(t("booking.transfer_confirm_success"), { variant: "success", disableWindowBlurListener: true, autoHideDuration: 3500 });
                        dispatch(EditItemToBook(response.data));
                    }).catch(function (error) {
                        console.log(error.responseJSON);
                        enqueueSnackbar(t("booking.transfer_confirm_failed"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 3500 });
                    });
                }
            }
        }
    };

    return (
        <Grid>
            {
                transfersToBook.length !== 0 && (
                    <Grid className={`${classes.genericPadding} ${classes.genericBorder}`} style={{ marginBottom: 12 }}>
                        <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.upperCase} ${classes.letterSpacing}`}>
                            { t("booking.transfer_info") }
                        </Grid>
                        <Grid className={`${classes.genericPadding} ${classes.genericText} ${classes.fontWeight500} ${classes.fontSize10} ${classes.upperCase} ${classes.letterSpacing}`}>
                            { t("booking.transfer_details") }
                        </Grid>
                        <Grid style={{ marginBottom: 8 }}>
                            {
                                transfersToBook.filter((transfer, index, array) => {
                                    return !isProductPackaged({
                                        product: transfer,
                                        stackInfos: version?.stack_info ?? null,
                                        connected: true
                                    }) ||
                                    array.slice(0, index).findIndex((transfer) => {
                                        return isProductPackaged({
                                            product: transfer,
                                            stackInfos: version?.stack_info ?? null,
                                            connected: true
                                        });
                                    }) < 0;
                                }).map((transfer, transfer_index) => {
                                    return (
                                        <Grid key={`transfer-${transfer.id}`}>
                                            <Grid container alignItems="baseline" justify="space-between" className={classes.genericPadding}>
                                                <Grid item md={5} xs={11} className={`${classes.genericText} ${classes.fontWeight700} ${classes.fontSize14} ${classes.upperCase} ${classes.letterSpacing}`}>
                                                    {
                                                        !isProductPackaged({
                                                            product: transfer,
                                                            stackInfos: version?.stack_info ?? null,
                                                            connected: true
                                                        }) &&
                                                        <>
                                                            { t("booking.transfer") + " - " + (transfer_index + 1) + " " }
                                                            { transfer.is_custom ? transfer.custom_product.title : transfer.name } {
                                                                isProductPackaged({
                                                                    product: transfer,
                                                                    stackInfos: version?.stack_info ?? null
                                                                }) &&
                                                                <Box sx={{ color: '#2F80ED' }} component="span">
                                                                    ({t('cart-material.cart-construction-package-name', { name: transfer.stack_number })})
                                                                </Box>
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        isProductPackaged({
                                                            product: transfer,
                                                            stackInfos: version?.stack_info ?? null,
                                                            connected: true
                                                        }) &&
                                                        <Box sx={{ color: '#2F80ED' }} component="span">
                                                            {t('cart-material.cart-construction-connected-package')}
                                                        </Box>
                                                    }
                                                </Grid>
                                                {
                                                    !isProductPackaged({
                                                        product: transfer,
                                                        stackInfos: version?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    <Grid item md={1} xs={1} className={classes.textCenter}>
                                                        <IconButton size="small" onClick={() => openMoreInfo(transfer_index)}>
                                                            <InfoOutlinedIcon />
                                                        </IconButton>
                                                    </Grid>
                                                }
                                                <ProductMoreInfo product={transfer} product_index={transfer_index} productsMoreInfo={transfersMoreInfo} setProductsMoreInfo={setTransfersMoreInfo} />
                                                <Grid item md={6} xs={12}>
                                                    {
                                                        transfer.group_passenger.default === false && (
                                                            <Grid className={classes.spacer}>
                                                                {
                                                                    !isProductPackaged({
                                                                        product: transfer,
                                                                        stackInfos: version?.stack_info ?? null,
                                                                        connected: true
                                                                    }) &&
                                                                    <SelectLead group={transfer.group_passenger} mode={"transfer"} product={transfer} product_list={null} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                                                                }
                                                                {
                                                                    isProductPackaged({
                                                                        product: transfer,
                                                                        stackInfos: version?.stack_info ?? null,
                                                                        connected: true
                                                                    }) &&
                                                                    <SelectLead
                                                                        group={transfer.group_passenger}
                                                                        mode={"group"}
                                                                        product={null}
                                                                        product_list={connectedPackageProducts}
                                                                        tempTravelers={tempTravelers}
                                                                        setTempTravelers={setTempTravelers}
                                                                    />
                                                                }
                                                            </Grid>
                                                        )
                                                    }
                                                    {
                                                        !isProductPackaged({
                                                            product: transfer,
                                                            stackInfos: version?.stack_info ?? null,
                                                            connected: true
                                                        }) &&
                                                        (transfer.is_manual === undefined || (transfer.is_manual !== undefined && !transfer.is_manual)) && (
                                                            <Grid className={classes.spacer} style={{ width: "100%" }}>
                                                                <FormControl variant="outlined" className={checks_passed === false && transfer.transport_type === "" ? classes.formControlRootSmallRed : classes.formControlRootSmall}>
                                                                    <InputLabel id={`transport_type-label-${transfer.id}`} ref={transportTypeLabel} className={classes.fontSize16}>
                                                                        {t("transfers.transport_type")}
                                                                    </InputLabel>
                                                                    <Select 
                                                                        labelId={`transport_type-label-${transfer.id}`}
                                                                        id={`transport_type-${transfer.id}`}
                                                                        value={transfer.transport_type}
                                                                        className={classes.circleBorder}
                                                                        onChange={(e) => handleTransportType(e, transfer_index)}
                                                                        MenuProps={{ disableScrollLock: true }}
                                                                        input={
                                                                            <OutlinedInput
                                                                                name="transport-type"
                                                                                id={`transport_type-input-${transfer.id}`}
                                                                                labelWidth={transportTypeLabelWidth}
                                                                            />
                                                                        }>
                                                                        <MenuItem value={"FLIGHT"}>
                                                                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize14} ${classes.noWrap}`}>
                                                                                {t("transfers.transport_type_flight")}
                                                                            </Grid>
                                                                        </MenuItem>
                                                                        <MenuItem value={"CRUISE"}>
                                                                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize14} ${classes.noWrap}`}>
                                                                                {t("transfers.transport_type_cruise")}
                                                                            </Grid>
                                                                        </MenuItem>
                                                                        <MenuItem value={"TRAIN"}>
                                                                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize14} ${classes.noWrap}`}>
                                                                                {t("transfers.transport_type_train")}
                                                                            </Grid>
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        )
                                                    }
                                                    {
                                                        !isProductPackaged({
                                                            product: transfer,
                                                            stackInfos: version?.stack_info ?? null,
                                                            connected: true
                                                        }) &&
                                                        (transfer.is_manual === undefined || (transfer.is_manual !== undefined && !transfer.is_manual)) && (
                                                            <Grid className={classes.spacer}>
                                                                <FormControl variant="outlined" className={(checks_passed === false && transfer.transport_type !== "CRUISE" && transfer.transport_code === "") || (transportCodeError && transfer.transport_code.length > 7) ? classes.formControlRootSmallRed : classes.formControlRootSmall}>
                                                                    <InputLabel id={`transport-code-label-${transfer.id}`} ref={transportCodeLabel} className={classes.fontSize16}>
                                                                        {t("transfers.transport_code")}
                                                                    </InputLabel>
                                                                    <OutlinedInput
                                                                        name={`transport-code-${transfer.id}`}
                                                                        id={`transport-code-input-${transfer.id}`}
                                                                        className={classes.circleBorder}
                                                                        labelWidth={transportCodeLabelWidth}
                                                                        value={transfer.transport_code}
                                                                        onChange={(e) => handleTransportCode(e, transfer_index)}
                                                                        autoComplete="off"
                                                                    />
                                                                    {
                                                                        transportCodeError && transfer.transport_code.length > 7 && (
                                                                            <Grid className={`${classes.errorInfo}`}>
                                                                                { t("booking.transfer_transport_code_limit")}
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                </FormControl>
                                                            </Grid>
                                                        )
                                                    }
                                                    {
                                                        !isProductPackaged({
                                                            product: transfer,
                                                            stackInfos: version?.stack_info ?? null,
                                                            connected: true
                                                        }) &&
                                                        (transfer.is_manual === undefined || (transfer.is_manual !== undefined && !transfer.is_manual)) && (
                                                            <Grid className={classes.spacer} style={{ width: "100%" }}>
                                                                <FormControl variant="outlined" className={checks_passed === false && transfer.direction === "" ? classes.formControlRootSmallRed : classes.formControlRootSmall}>
                                                                    <InputLabel id={`direction-label-${transfer.id}`} ref={directionLabel} className={classes.fontSize16}>
                                                                        {t("transfers.transfer_direction")}
                                                                    </InputLabel>
                                                                    <Select 
                                                                        labelId={`direction-label-${transfer.id}`}
                                                                        id={`direction-${transfer.id}`}
                                                                        value={transfer.direction}
                                                                        className={classes.circleBorder}
                                                                        onChange={(e) => handleDirection(e, transfer_index)}
                                                                        MenuProps={{ disableScrollLock: true }}
                                                                        input={
                                                                            <OutlinedInput
                                                                                name="direction"
                                                                                id={`direction-input-${transfer.id}`}
                                                                                labelWidth={directionLabelWidth}
                                                                            />
                                                                        }>
                                                                        <MenuItem value={"arr"}>
                                                                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize14} ${classes.noWrap}`}>
                                                                                {t("transfers.direction_arrival")}
                                                                            </Grid>
                                                                        </MenuItem>
                                                                        <MenuItem value={"dpt"}>
                                                                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize14} ${classes.noWrap}`}>
                                                                                {t("transfers.direction_departure")}
                                                                            </Grid>
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        )
                                                    }
                                                    {
                                                        !isProductPackaged({
                                                            product: transfer,
                                                            stackInfos: version?.stack_info ?? null,
                                                            connected: true
                                                        }) &&
                                                        (transfer.is_manual === undefined || (transfer.is_manual !== undefined && !transfer.is_manual)) && (
                                                            <Grid className={classes.spacer}>
                                                                <FormControl variant="outlined" className={checks_passed === false && transfer.transport_type === "CRUISE" && transfer.transport_company === "" ? classes.formControlRootSmallRed : classes.formControlRootSmall}>
                                                                    <InputLabel id={`transport-company-label-${transfer.id}`} ref={transportCompanyLabel} className={classes.fontSize16}>
                                                                        {t("transfers.transport_company")}
                                                                    </InputLabel>
                                                                    <OutlinedInput
                                                                        name={`transport-code-${transfer.id}`}
                                                                        id={`transport-code-input-${transfer.id}`}
                                                                        className={classes.circleBorder}
                                                                        labelWidth={transportCompanyLabelWidth}
                                                                        value={transfer.transport_company}
                                                                        onChange={(e) => handleTransportCompany(e, transfer_index)}
                                                                        autoComplete="off"
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        )
                                                    }
                                                    {
                                                        (transfer.is_manual === undefined || (transfer.is_manual !== undefined && !transfer.is_manual)) && (
                                                            <Grid container alignItems="center" justify="flex-end" spacing={4}>
                                                                {
                                                                    checks_passed === false && (transfer.direction === "" || transfer.transport_type === "" || transfer.transport_code === "") && (
                                                                        <Grid item className={classes.errorInfo}>
                                                                            { t("booking.transfer_info_err") }
                                                                        </Grid>
                                                                    )
                                                                }
                                                                <Grid item>
                                                                    <Button variant="contained" className={classes.validateButton} onClick={() => {
                                                                        confirmTransfer(transfer); 
                                                                    }} size="small">
                                                                        {t("global.validate")}
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    );
};

export default React.memo(BookingTransferInfo);
