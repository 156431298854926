//Dependencies
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
//Core
import Alert from '@material-ui/lab/Alert';

const CartMarginNotification = () => {
    const { t } = useTranslation();
    const global_margin = useSelector(state => state.cart.global_margin);
    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;
    if (quotation_code === 'verdie' && global_margin !== null && global_margin < 10) {
        return <Fragment><Alert variant={ 'filled' } severity={ 'error' }>{ t('cart-material.verdie-margin-text') }</Alert></Fragment>;
    }
    return null;
};

export default CartMarginNotification;
