import { SetSecondDestList } from "../../../Actions/ItineraryType";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

export default (parent, dispatch) => {
    let { pass_check, headers } = CheckBeforeRequest();
    let desti = null;
    if (parent.data === undefined) {
        desti = parent.destination !== undefined ? parent.destination : parent;
    } else {
        desti = parent.data.destination;
    }
    $.ajax({
        method: "GET",
        headers: headers,
        url: `${API_HREF}client/${window.id_owner}/destinations/?limit=500&reduced=true&parent=${desti}&ordering=-current_version__note&visibility__in=PUBLIC,PRIVATE_TO`,
        success: function (data) {
            console.log("data : ", data);
            dispatch(SetSecondDestList(data.results));
        },
        error: function (error) {
            console.log(error);
        }
    });
};
