import React, { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrop } from "react-dnd";
import { ClickAwayListener, styled } from "@mui/material";
import { EditorState } from "draft-js";
import {
    MailTemplateVisualEditorDropTarget,
    MailTemplateVisualEditorDropTargetProps
} from "./mailTemplateVisualEditorDropTarget";
import { EditorToolbar } from "../utils/editor/editorToolbar";
import { RegisteredBlocks } from "./utils/registered-blocks";
import { MailTemplateVisualEditorContext } from "./mailTemplateVisualEditorContext";
import {
    addVisualEditorBlock,
    deleteVisualEditorBlock,
    duplicateVisualEditorBlock,
    setVisualEditorBlockOptions,
    unselectVisualEditorBlock
} from "./redux/actions";
import { reoderVisualEditorBlocks } from "./redux/thunks";
import { Mode } from "./objects/mode";
import { AppState } from "../../../../Reducers/Reducers";

const emptyEditorState = EditorState.createEmpty();

export function MailTemplateVisualEditorDragArea(): JSX.Element {
    const dispatch = useDispatch();
    const context = useContext(MailTemplateVisualEditorContext);
    const draggedBlockId = useSelector((state: AppState) => state.mailTemplate.others.instances[context.instanceId]?.visualEditorDraggedBlockId);
    const bodyStyles = useSelector((state: AppState) => state.mailTemplate.visualEditor.present.instances[context.instanceId]?.visualEditorBodyStyles);
    const locale = useSelector((state: AppState) => context.locale ?? state.locale.current_locale);
    const order = useSelector((state: AppState) => locale !== null ?
        state.mailTemplate.visualEditor.present.instances[context.instanceId]?.visualEditorBlocks[locale]?.order :
        null
    ) ?? [];
    const mode = useSelector((state: AppState) => state.mailTemplate.visualEditor.present.instances[context.instanceId]?.visualEditorMode);
    const [collected] = useDrop(() => ({
        accept: RegisteredBlocks.map((item) => item.type),
        collect: (monitor) => ({
            choosingBlock: monitor.canDrop()
        })
    }));


    const onNewBlock = (type: string | symbol, prevBlockId: number | null) => {
        const selectedBlock = RegisteredBlocks.find((block) => block.type === type);
        if (selectedBlock && locale !== null) {
            const dispatchData = addVisualEditorBlock(context.instanceId, locale, type, prevBlockId);
            dispatch(dispatchData);
        }
    };

    const onBlockReorder = (
        options: Parameters<MailTemplateVisualEditorDropTargetProps['onBlockReorder']>[0]
    ) => {
        if (locale !== null) {
            dispatch(
                reoderVisualEditorBlocks({
                    fromInstanceId: options.sourceInstanceId,
                    toInstanceId: context.instanceId,
                    locale,
                    id: options.id,
                    index: options.index
                })
            );
        }
    };

    const onUnselectBlock = () => {
        if (!context.isIdle) {
            dispatch(unselectVisualEditorBlock(context.instanceId));
        }
    };

    return (
        <MailTemplateVisualEditorContext.Consumer>
            {
                (context) => (
                    <ClickAwayListener
                        mouseEvent="onMouseDown"
                        onClickAway={onUnselectBlock}

                    >
                        <div>
                            {
                                context.disableBlocksEdit && !context.disableHeader &&
                                <DisabledEditModeToolbarWrapper />
                            }
                            <Container
                                onMouseDown={onUnselectBlock}
                                style={{
                                    backgroundColor: bodyStyles?.backgroundColor,
                                    paddingTop: bodyStyles?.padding.top + 'px',
                                    paddingBottom: bodyStyles?.padding.bottom + 'px',
                                    paddingLeft: bodyStyles?.padding.left + 'px',
                                    paddingRight: bodyStyles?.padding.right + 'px',
                                    margin: 'auto',
                                    ...(
                                        mode === Mode.MOBILE ?
                                            {
                                                width: '375px',
                                                maxWidth: '375px'
                                            } :
                                            undefined
                                    )
                                }}
                            >
                                <div style={{
                                    backgroundColor: bodyStyles?.innerBackgroundColor,
                                    paddingTop: bodyStyles?.innerPadding.top + 'px',
                                    paddingBottom: bodyStyles?.innerPadding.bottom + 'px',
                                    paddingLeft: bodyStyles?.innerPadding.left + 'px',
                                    paddingRight: bodyStyles?.innerPadding.right + 'px'
                                }}>
                                    <div
                                        style={{
                                            border: `${bodyStyles?.innerBorder.width}px solid ${bodyStyles?.innerBorder.color}`
                                        }}
                                    >
                                        {
                                            order.length === 0 &&
                                            !context.disableEmptyIndicator &&
                                            <MailTemplateVisualEditorDropTarget
                                                index={0}
                                                size="big"
                                                targetName="visual-editor-drag-area"
                                                onNewBlock={(type) => onNewBlock(type, null)}
                                                onBlockReorder={onBlockReorder}
                                                forceShow
                                            />
                                        }
                                        {
                                            order.length === 0 &&
                                            context.disableEmptyIndicator &&
                                            <MailTemplateVisualEditorDropTarget
                                                index={0}
                                                targetName="visual-editor-drag-area"
                                                onNewBlock={(type) => onNewBlock(type, null)}
                                                onBlockReorder={onBlockReorder}
                                            />
                                        }
                                        {
                                            order.length > 0 &&
                                            collected.choosingBlock &&
                                            draggedBlockId !== order[0] &&
                                            <MailTemplateVisualEditorDropTarget
                                                index={0}
                                                targetName="visual-editor-drag-area"
                                                onNewBlock={(type) => onNewBlock(type, null)}
                                                onBlockReorder={onBlockReorder}
                                            />
                                        }
                                        {
                                            order.map((id, index) => (
                                                <React.Fragment key={id}>
                                                    <BlockComponentWrapper id={id} />
                                                    {
                                                        draggedBlockId !== order[index + 1] &&
                                                            draggedBlockId !== id &&
                                                            <MailTemplateVisualEditorDropTarget
                                                                index={index + 1}
                                                                targetName="visual-editor-drag-area"
                                                                onNewBlock={(type) => onNewBlock(type, order[index] ?? null)}
                                                                onBlockReorder={onBlockReorder}
                                                            />
                                                    }
                                                </React.Fragment>
                                            ))
                                        }
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </ClickAwayListener>
                )
            }
        </MailTemplateVisualEditorContext.Consumer>
    );
}

type BlockComponentWrapperProps = {
    id: number,
}

function BlockComponentWrapper(props: BlockComponentWrapperProps): JSX.Element | null {
    const context = useContext(MailTemplateVisualEditorContext);
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => context.locale ?? state.locale.current_locale);
    const block = useSelector((state: AppState) => {
        return locale !== null ?
            state.mailTemplate.visualEditor.present.instances[context.instanceId]?.visualEditorBlocks[locale]?.blocks[props.id] :
            null;
    });
    const registeredBlock = RegisteredBlocks.find((item) => item.type === block?.getType());

    const onDuplicateBlock = useCallback((id: number) => {
        if (locale !== null) {
            dispatch(duplicateVisualEditorBlock(context.instanceId, locale, id));
        }
    }, [locale, dispatch]);
    const onDeleteBlock = useCallback((id: number) => {
        if (locale !== null) {
            dispatch(deleteVisualEditorBlock(context.instanceId, locale, id));
        }
    }, [locale, dispatch]);

    if (registeredBlock && block) {
        return (
            <registeredBlock.component
                id={props.id}
                sourceName="visual-editor-drag-area"
                options={block.getOptions()}
                onDuplicate={onDuplicateBlock}
                onDelete={onDeleteBlock}
            />
        );
    }

    return null;
}

function DisabledEditModeToolbarWrapper(): JSX.Element {
    const context = useContext(MailTemplateVisualEditorContext);
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => context.locale ?? state.locale.current_locale);
    const selectedBlock = useSelector((state: AppState) => {
        const content = (
            locale !== null ?
                state.mailTemplate.visualEditor.present.instances[context.instanceId]?.visualEditorBlocks[locale]?.blocks :
                null
        ) ?? {};
        return state.mailTemplate.others.instances[context.instanceId]?.visualEditorSelectedBlockId ?
            content[state.mailTemplate.others.instances[context.instanceId]!.visualEditorSelectedBlockId!] :
            null;
    });

    const onChangeTextOption = (state: EditorState) => {
        if (locale !== null && selectedBlock && selectedBlock.getType() === 'text') {
            const dispatchData = setVisualEditorBlockOptions(
                context.instanceId,
                locale,
                selectedBlock.getId(),
                {
                    ...selectedBlock.getOptions(),
                    editorState: state
                }
            );
            dispatch(dispatchData);
        }
    };

    return (
        <EditorToolbar
            editorState={
                selectedBlock && selectedBlock.getType() === 'text' ?
                    selectedBlock.getOptions().editorState :
                    emptyEditorState
            }
            setEditorState={onChangeTextOption}
            sticky
        />
    );
}

const Container = styled('div')(() => ({
    height: '100%'
}));
