//Dependencies
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
//Core
import { Box } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
//Components
import { CartFlightCard } from "./CartFlightCard";
import { useUnpackagedProducts } from './utils/unpackagedProduts';

const useStyles = makeStyles({
    title: {
        fontSize: 24,
        fontWeight: 700
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    checkboxContainer: {
        position: 'relative',
        //height: '100%',
        width: 50,
        borderLeft: '6px solid #E6592F'
    },
    checkbox: {
        //position: 'absolute',
        //top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
    marginBottom: {
        marginBottom: 32
    }
});

const CartFlights = () => {
    const unpackagedProducts = useUnpackagedProducts();

    if (unpackagedProducts.flights.length > 0) {
        return (
            <div>
                <TitleWrapper />
                <hr/>
                {
                    unpackagedProducts.flights.map((item) => {
                        if (item.type === 'manual') {
                            return (
                                <Grid key={ `manual-product-${item.flight.id}` } id={ `manual-product-${item.flight.id}` }>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            marginBottom: 3
                                        }}
                                    >
                                        <CartFlightCard
                                            type="manual"
                                            flight={item.flight}
                                        />
                                    </Box>
                                </Grid>
                            ); 
                        }
                        return (
                            <Grid key={ `flight-${item.flight.id}` } container>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            marginBottom: 3
                                        }}
                                    >
                                        <CartFlightCard
                                            type="normal"
                                            flight={item.flight}
                                        />
                                    </Box>
                                </Grid>
                                {/*<Grid className={ classes.marginBottom } item xs={ 12 }/>*/}
                            </Grid>
                        );
                    })
                }
            </div>
        );
    }
    return null;
};

function TitleWrapper() {
    const classes = useStyles();
    const { t } = useTranslation();
    const language = useSelector(state => state.header.tmp_language);
    const total_flights = useSelector(state => state.cart.total_flights);
    const unpackagedProducts = useUnpackagedProducts();

    return (
        <Typography className={ classes.title }>
            { t('cart-material.total-flight') } ({unpackagedProducts.flights.length}) :{' '}
            { total_flights.map((currency_cost, currency_index) => `${currency_index > 0 ? ' + ' : ''}${currency_cost.cost.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}${currency_cost.currency.symbol}`) }
        </Typography>
    );
}

export default CartFlights;
