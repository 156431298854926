import React, {Fragment, useEffect, useState} from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { DateRangePicker, LocalizationProvider, DateRangeDelimiter, PickersDay } from "@material-ui/pickers";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import RemoveCircleOutline from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import Close from "@material-ui/icons/Close";
import moment from "moment";
import Red from "@material-ui/core/colors/red";
import axios from "axios";
import GetCookie from "../../Common/Functions/GetCookie";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import {useSnackbar} from "notistack";

const useStyles = makeStyles({
    inline: {
        display: "inline-block"
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    nbRoomButton: {
        padding: 0,
        margin: 16
    },
    inactifContainer: {
        border: "2px solid transparent"
    },
    inactifButton: {
        color: 'rgba(0, 0, 0, 0.54)',
        cursor: 'default',
        pointerEvents: 'none',
        backgroundColor: 'transparent',
        transform: 'scale(1.1)',
        width: 36,
        height: 36,
        padding: 0,
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.66,
        borderRadius: '50%',
        letterSpacing: '0.03333em'
    },
    inactifSpan: {
        transform: 'scale(0.9)'
    },
    dayRender: {
        width: 40,
        margin: 0,
    },
    red: {
        backgroundColor: 'red',
        color: 'white'
    },
    isHighLighting: {
        color: 'white',
        backgroundColor: 'rgba(121, 134, 203, 0.6)',
        borderRadius: 0
    }
});

const AccommodationListParameters = () => {
    console.log("parameters render");
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const store_travelers = useSelector(state => state.trip.travelers);
    const open = useSelector(state => state.accommodation.show_parameters);
    const start_date = useSelector(state => state.accommodation.start_date);
    const end_date = useSelector(state => state.accommodation.end_date);
    const destination = useSelector(state => state.accommodation.destination);
    const traveler_groups = useSelector(state => state.trip.traveler_groups);
    const current_groups = useSelector(state => state.accommodation.current_groups);
    const cart = useSelector(state => state.accommodation.cart);
    const language = useSelector(state => state.header.language);
    const [groups, setGroups] = useState([...current_groups]);
    const [travelers, setTravelers] = useState([...store_travelers]);
    const [dates, setDates] = useState({start_date: start_date, end_date: end_date});
    moment.locale(language);
    useEffect(() => {
        let new_travelers = [];
        let numero_adult = 1;
        let numero_child = 1;
        travelers.map((traveler) => {
            let tmp_traveler = Object.assign({}, traveler);
            tmp_traveler.default_name = false;
            let age = moment.utc(end_date).diff(moment.utc(tmp_traveler.birth_date), "y");
            if (tmp_traveler.first_name === t('global.first_name') && tmp_traveler.last_name === t('global.last_name')) {
                tmp_traveler.default_name = true;
            }
            if (age >= 18) {
                tmp_traveler.age_label = tmp_traveler.default_name ? `${t("flight_search.adult")} ${numero_adult}` : `${tmp_traveler.first_name} ${tmp_traveler.last_name}`;
                numero_adult += 1;
            }
            else {
                tmp_traveler.age_label = tmp_traveler.default_name ? `${t("flight_search.child")} ${numero_child} (${age})` : `${tmp_traveler.first_name} ${tmp_traveler.last_name} (${age})`;
                numero_child += 1;
            }
            new_travelers.push(tmp_traveler);
        });
        setTravelers(new_travelers);
    }, []);
    useEffect(() => {
        setGroups([...current_groups]);
        setDates({start_date: start_date, end_date: end_date});
    }, [start_date, end_date, current_groups]);
    if (groups === null || destination === null) {
        return;
    }
    let travelers_without_group = [];
    travelers.map((traveler) => {
        let has_group = false;
        for (let i = 0; i < groups.length; i++) {
            for (let j = 0; j < groups[i].travelers_list.length; j++) {
                if (groups[i].travelers_list[j].id === traveler.id) {
                    has_group = true;
                    break;
                }
            }
        }
        if (!has_group) {
            travelers_without_group.push(traveler);
        }
    });
    const toggleParameters = () => {
        dispatch({type: "MATERIAL_TOGGLE_PARAMETERS"});
    };
    const checkEqualGroups = (group_1, group_2) => {
        if (!Array.isArray(group_1) || ! Array.isArray(group_2) || group_1.length !== group_2.length)
            return false;
        let cpy_group_1 = group_1.concat().sort();
        let cpy_group_2 = group_2.concat().sort();
        for (let i = 0; i < cpy_group_1.length; i++) {
            if (cpy_group_1[i] !== cpy_group_2[i])
                return false;
        }
        return true;
    };
    const newGroupCallback = (new_current_groups, groups_length, dates) => {
        if (new_current_groups.length === groups_length) {
            dispatch({
                type: "MATERIAL_UPDATE_PARAMETERS",
                payload: {
                    start_date: dates.start_date,
                    end_date: dates.end_date,
                    current_groups: new_current_groups
                }
            });
        }
    };
    const setParameters = () => {
        const { headers } = CheckBeforeRequest();
        let new_current_groups = [];
        let current_travelers = 0;
        for (let i = 0; i < groups.length; i++) {
            if (groups[i].travelers.length === 0) {
                groups.splice(i, 1);
                i = 0;
            }
        }
        let same = groups.length === current_groups.length;
        if (same) {
            groups.map((group) => {
                let already_exists = false;
                current_groups.map((current_group) => {
                    if (!already_exists && checkEqualGroups(group.travelers_list, current_group.travelers_list)) {
                        already_exists = true;
                    }
                });
                if (!already_exists) {
                    same = false;
                }
            });
        }
        if (!same) {
            let has_adult = true;
            groups.map((group) => {
                let has_group_adult = false;
                group.travelers_list.map((traveler) => {
                    if (moment().diff(traveler.birth_date, "y") > 18) {
                        has_group_adult = true;
                    }
                });
                if (!has_group_adult) {
                    has_adult = false;
                }
            });
            if (!has_adult) {
                enqueueSnackbar(t("accommodation.room_without_adult"), {
                    variant: "warning",
                });
            } else {
                groups.map((group) => {
                    let already_exists = false;
                    group.travelers = [];
                    group.travelers_list.map((traveler) => {
                        group.travelers.push(traveler.id);
                    });
                    traveler_groups.map((traveler_group) => {
                        if (!already_exists && checkEqualGroups(group.travelers, traveler_group.travelers)) {
                            already_exists = true;
                            new_current_groups.push(traveler_group);
                            current_travelers += traveler_group.travelers.length;
                            newGroupCallback(new_current_groups, groups.length, dates);
                        }
                    });
                    if (!already_exists) {
                        axios({
                            method: "POST",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/?token=${GetCookie("trip_token")}`,
                            data: {
                                travelers: group.travelers,
                                default: false
                            },
                        }).then(function (response) {
                            console.log(response.data);
                            new_current_groups.push(response.data);
                            current_travelers += response.data.travelers.length;
                            newGroupCallback(new_current_groups, groups.length, dates);
                        }).catch(function (error) {
                            //TODO: show snackbar error
                        });
                    }
                });
            }
        } else {
            dispatch({
                type: "MATERIAL_UPDATE_PARAMETERS",
                payload: {
                    start_date: dates.start_date,
                    end_date: dates.end_date,
                    current_groups: current_groups
                }
            });
        }
    };
    const resetParameters = () => {
        //TODO: this doesn't work
        setGroups([...current_groups]);
        setDates({start_date: start_date, end_date: end_date});
        toggleParameters();
    };
    return (
        <Dialog open={ open } PaperProps={{style: { borderRadius: fullScreen ? 0 : 16 } }} onClose={ toggleParameters } fullWidth fullScreen={ fullScreen } maxWidth={ "md" }>
            <DialogTitle onClose={ toggleParameters }>
                <Grid container justify={ "space-between" } alignItems={ "center" }>
                    {
                        fullScreen ? (
                            <Grid item>
                                <IconButton edge={ "start" } onClick={ resetParameters }><NavigateBefore/></IconButton>{ t("accommodation_list.parameters") } :
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item>
                                    { t("accommodation_list.parameters") } :
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={ resetParameters }><Close/></IconButton>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Typography>{ t("accommodation_parameters.dates_change") }</Typography>
                <br/>
                <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ language }>
                    <DateRangePicker
                        startText={ t("accommodation.start_date") }
                        endText={ t("accommodation.end_date") }
                        value={ [dates.start_date, dates.end_date] }
                        onChange={(dates) => { setDates({start_date: dates[0].format("YYYY-MM-DD"), end_date: dates[1].format("YYYY-MM-DD")}); }}
                        minDate={ moment.utc(destination.start_date).subtract(1, 'days') }
                        maxDate={ moment.utc(destination.end_date).add(1, 'days') }
                        calendars={ fullScreen ? 1 : 2 }
                        desktopModeMediaQuery={ "@media (min-width: 0px)" }
                        renderInput={(startProps, endProps) => {
                            startProps.helperText = undefined;
                            startProps.fullWidth = true;
                            endProps.helperText = undefined;
                            endProps.fullWidth = true;
                            return (
                                <Fragment>
                                    <TextField {...startProps} />
                                    <DateRangeDelimiter>{ t("accommodation.to") }</DateRangeDelimiter>
                                    <TextField {...endProps} />
                                </Fragment>
                            )
                        }}
                        /*
                        renderDay={(date, DateRangeDayProps, old) => {
                            console.log("old : ", old);
                            let isRed = false;
                            cart.map((hotel) => {
                                if (date.isBetween(moment.utc(hotel.start_date), moment.utc(hotel.end_date), 'd')) {
                                    isRed = true
                                }
                            });
                            /*
                            className={ clsx(classes.dayRender, {[classes.red]: isRed, [classes.isHighLighting]: DateRangeDayProps.isHighlighting && !DateRangeDayProps.isStartOfHighlighting && !DateRangeDayProps.isEndOfHighlighting}) }
                            return <PickersDay {...DateRangeDayProps}/>
                        }}

                         */
                    />
                </LocalizationProvider>
                <br/>
                <Divider/>
                <br/>
                <Typography>{ t("accommodation_parameters.groups_change") }</Typography>
                <Grid container justify={ "space-between" } alignItems={ "center" }>
                    <Grid item>
                        <Typography>{t('shared.room-nb')} : </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton className={ `${classes.inline} ${classes.nbRoomButton}` } disabled={ groups.length <= 1 } onClick={() => {
                            if (groups.length > 1) {
                                let groups_cpy = [];
                                for (let i = 0; i < groups.length - 1; i++) {
                                    groups_cpy.push({
                                        travelers: [],
                                        travelers_list: []
                                    });
                                    setGroups(groups_cpy);
                                }
                            }
                        }}>
                            <RemoveCircleOutline/>
                        </IconButton>
                        <Typography className={ classes.inline }>{ groups.length }</Typography>
                        <IconButton className={ `${classes.inline} ${classes.nbRoomButton}` } disabled={ groups.length >= travelers.length } onClick={() => {
                            if (groups.length < travelers.length) {
                                let groups_cpy = groups.slice();
                                groups_cpy.push({
                                    travelers: [],
                                    travelers_list: []
                                });
                                setGroups(groups_cpy);
                            }
                        }}>
                            <AddCircleOutline/>
                        </IconButton>
                    </Grid>
                </Grid>
                {
                    travelers_without_group.length > 0 && (
                        <Fragment>
                            <Typography>Il y a { travelers_without_group.length } voyageur(s) non affectés : </Typography>
                            {
                                travelers_without_group.map((traveler, index_traveler) => {
                                    return (
                                        <Typography key={ index_traveler }>{ traveler.first_name } { traveler.last_name }</Typography>
                                    )
                                })
                            }
                            <br/>
                        </Fragment>
                    )
                }
                <Grid container>
                    {
                        groups.map((group, index_group) => {
                            return (
                                <Grid key={ index_group } item md={ 4 } xs={ 6 }>
                                    <Typography>{ t("accommodation.room") } { index_group + 1 } :</Typography>
                                    {
                                        travelers.map((traveler, index_traveler) => {
                                            return (
                                                <div key={ index_traveler }>
                                                    <FormControlLabel control={ <Checkbox checked={ group.travelers.includes(traveler.id) } onChange={(e) => {
                                                        let groups_cpy = [];
                                                        groups.map(group_cpy => {
                                                            groups_cpy.push({...group_cpy});
                                                        });
//                                                        let groups_cpy = groups.map(group => { return {...group}});
//                                                         let groups_cpy = groups.slice();
                                                        if (e.target.checked) {
                                                            groups_cpy.map((group_check, group_check_index) => {
                                                                const index = group_check.travelers.indexOf(traveler.id);
                                                                if (index > -1) {
                                                                    groups_cpy[group_check_index] = Object.assign({}, groups[group_check_index]);
                                                                    groups_cpy[group_check_index].travelers = groups_cpy[group_check_index].travelers.slice();
                                                                    groups_cpy[group_check_index].travelers.splice(index, 1);
                                                                    groups_cpy[group_check_index].travelers_list = groups_cpy[group_check_index].travelers_list.slice();
                                                                    groups_cpy[group_check_index].travelers_list.splice(index, 1);
                                                                }
                                                            });
                                                            groups_cpy[index_group] = Object.assign({}, groups[index_group]);
                                                            groups_cpy[index_group].travelers = groups_cpy[index_group].travelers.slice();
                                                            groups_cpy[index_group].travelers.push(traveler.id);
                                                            groups_cpy[index_group].travelers_list = groups_cpy[index_group].travelers_list.slice();
                                                            groups_cpy[index_group].travelers_list.push(traveler);
                                                        } else {
                                                            const index = groups_cpy[index_group].travelers.indexOf(traveler.id);
                                                            if (index > -1) {
                                                                groups_cpy[index_group].travelers = groups_cpy[index_group].travelers.slice();
                                                                groups_cpy[index_group].travelers.splice(index, 1);
                                                                groups_cpy[index_group].travelers_list = groups_cpy[index_group].travelers_list.slice();
                                                                groups_cpy[index_group].travelers_list.splice(index, 1);
                                                            }
                                                        }
                                                        setGroups(groups_cpy);
                                                    }}/> } label={ traveler.age_label }/>
                                                </div>
                                            )
                                        })
                                    }
                                    <br/>
                                </Grid>
                            );
                        })
                    }
                </Grid>
                <Button variant={ "contained" } className={ classes.orangeButton } fullWidth={ true } onClick={() => { setParameters([...groups], setGroups, dates.start_date, dates.end_date); }}>{ t("global.finish") }</Button>
            </DialogContent>
        </Dialog>
    )
};

export default React.memo(AccommodationListParameters);