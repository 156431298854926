import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Divider, IconButton, styled, Typography } from "@mui/material";
import { isNumber } from "lodash";
import { MailTemplateVisualEditorContext } from "./mailTemplateVisualEditorContext";
import { RegisteredBlocks } from "./utils/registered-blocks";
import { unselectVisualEditorBlock } from "./redux/actions";
import { AppState } from "../../../../Reducers/Reducers";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
    locale?: number,
    minimal?: boolean
}

export function MailTemplateVisualEditorBlockOptionsPanel(props: Props): JSX.Element | null {
    const context = useContext(MailTemplateVisualEditorContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const instanceId = useSelector((state: AppState) => {
        for (const key of Object.keys(state.mailTemplate.others.instances)) {
            if (isNumber(state.mailTemplate.others.instances[key]?.visualEditorSelectedBlockId)) {
                return key;
            }
        }
    });
    const selectedBlockId = useSelector((state: AppState) => {
        return instanceId ?
            state.mailTemplate.others.instances[instanceId]?.visualEditorSelectedBlockId :
            null;
    });
    const locale = useSelector((state: AppState) => props.locale ?? context.locale ?? state.locale.current_locale);
    const selectedBlock = useSelector((state: AppState) => {
        const content = locale !== null && instanceId ?
            state.mailTemplate.visualEditor.present.instances[instanceId]?.visualEditorBlocks[locale] :
            null;
        return isNumber(selectedBlockId) ? content?.blocks[selectedBlockId] : null;
    });

    const registeredBlock = RegisteredBlocks.find((item) => selectedBlock && item.type === selectedBlock.getType());

    const onClose = () => { 
        dispatch(unselectVisualEditorBlock(context.instanceId));
    };

    if (!registeredBlock || !selectedBlock) {
        return null;
    }

    return (
        <MailTemplateVisualEditorContext.Provider
            value={{
                ...context,
                locale,
                instanceId: instanceId ?? context.instanceId
            }}
        >
            <Container 
                onMouseDown={(event) => {
                    event.stopPropagation();
                    event.nativeEvent.stopImmediatePropagation();
                }}
            >
                {
                    !props.minimal &&
                    <>
                        <Header>
                            <Typography variant="h6">
                                {t<string>(registeredBlock.label + '-options')}
                            </Typography>
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Header>
                        <Divider />
                    </>
                }
                <Content>
                    <registeredBlock.optionsComponent id={selectedBlock.getId()} options={selectedBlock.getOptions()} />
                </Content>
            </Container>
        </MailTemplateVisualEditorContext.Provider>
    );
}

const Container = styled('div')(() => ({
    height: '100%'
}));

const Header = styled('div')((props) => ({
    display: 'flex',
    paddingTop: props.theme.spacing(1),
    paddingBottom: props.theme.spacing(1),
    paddingLeft: props.theme.spacing(4),
    paddingRight: props.theme.spacing(4),
    justifyContent: 'space-between',
    alignItems: 'center'
}));

const Content = styled('div')((props) => ({
    padding: props.theme.spacing(2)
}));
