/*eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios, { CancelTokenSource } from 'axios';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

let request_timeout;
let cancelToken;

const TravelerFormCountry = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    let traveler_form = props.traveler;

    const onSearchChange = (event) => {
        setSearch(event.target.value);
    };
    const runRequest = () => {
        setLoading(true);
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.");
        }
        cancelToken = axios.CancelToken.source();
        axios({
            method: 'GET',
            url: `${API_HREF}iata-countries/`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Accept-Language': 'fr'
            },
            cancelToken: cancelToken.token,
            params: {
                search: search !== '' ? search : undefined
            }
        }).then((response) => {
            setOptions(response.data.results?.filter(c => c.international_name && c.id));
            setLoading(true);
        }).catch((error) => {
            if (!axios.isCancel(error)) {
                console.log('error : ', error);
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        if (open) {
            clearTimeout(request_timeout);
            request_timeout = setTimeout(runRequest, 500);
        } else {
            setOptions([]);
        }
    }, [open, search]);

    useEffect(() => {
        if (!props.doc_mode && traveler_form?.country !== null) {
            let included = false;
            for (let i = 0; i < options.length; i++) {
                if (options[i] && options[i].id === traveler_form?.country?.id) {
                    included = true;
                }
            }
            if (!included) {
                setOptions([...options, traveler_form?.country]);
            }
        }

        if (props.doc_mode && traveler_form?.identity_doc_country_emission !== null) {
            let included = false;
            for (let i = 0; i < options.length; i++) {
                if (options[i] && options[i].id === traveler_form?.identity_doc_country_emission.id) {
                    included = true;
                }
            }
            if (!included) {
                setOptions([...options, traveler_form?.identity_doc_country_emission]);
            }
        }
    }, [traveler_form]);

    const handleCountryChange = (value) => {
        if (props.onChange !== undefined) {
            props.onChange(!props.doc_mode ? 'country' : 'identity_doc_country_emission', value);
        }
    };

    return (
        <Autocomplete
            className="traveler-form-country"
            id={'traveler-form-country'}
            open={open}
            onOpen={() => {
                setOpen(true);
                setSearch('');
            }}
            onClose={() => {
                setOpen(false);
            }}
            clearOnBlur={true}
            clearOnEscape={true}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option?.international_name}
            options={options?.filter(o => o?.international_name)}
            loading={loading}
            value={props.doc_mode ? traveler_form?.identity_doc_country_emission : traveler_form?.country}
            filterOptions={(x) => x}
            onChange={(event, value) => handleCountryChange(value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.doc_mode ? t('crm.traveler_validation.document.country') : t('crm.traveler_validation.country')}
                    variant={'outlined'}
                    onChange={onSearchChange}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
        />
    );
};
export default TravelerFormCountry;
