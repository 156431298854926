import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { useSelector } from 'react-redux';

import GetTime from './GetTime';

const useStyles = makeStyles(theme => ({
    formControlRoot: {
        "& .mui-jss-MuiInputLabel-outlined": {
            transform: 'translate(14px, 13px) scale(1)'
        },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },
        "& .mui-jss-MuiOutlinedInput-input": {
            //padding: '9.5px 35px'
            paddingTop: '9.5px',
		    paddingBottom: '9.5px',
		    paddingRight: '26px'
        }
    },
    formControlRoot1: {
	    "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(14px, 20px) scale(1)'
	    },
	    "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    },
	    "& .mui-jss-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
	    	padding: '0'
	    },
	    "& .mui-jss-MuiInputBase-root.mui-jss-MuiOutlinedInput-root.mui-jss-MuiAutocomplete-inputRoot.mui-jss-MuiInputBase-fullWidth.mui-jss-MuiInputBase-formControl.mui-jss-MuiInputBase-adornedEnd.mui-jss-MuiOutlinedInput-adornedEnd": {
	    	paddingRight: '10px'
	    },
	    "& .mui-jss-MuiOutlinedInput-input": {
	    	padding: '17px 4px'
	    }
  	},
    minWidth78: {
        minWidth: 78
    },
    minWidth86: {
        minWidth: 86
    },
    inputBorder1: {
        "&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: '0',
            borderRight: '0'
        }
    },
    inputBorder2: {
        "& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: '0px 8px 8px 0px'
        }
    },
    inputBorder3: {
        borderRadius: '8px',
        width: '100%',
        maxWidth: '100%'
    },
    noBorderLeft: {
        borderLeft: "none"
    },
    noBorder: {
        borderLeft: "none",
        borderRight: "none"
    }
}));

const RenderTime = ({ type, search, setSearch, elem_index }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const mobile = useMediaQuery(theme.breakpoints.down(1122));
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const { t } = useTranslation();
    const [time, setTime] = useState(type === "departure" ? (search[elem_index].origin_hour !== null ? search[elem_index].origin_hour : "") : (search[elem_index].return_hour !== null ? search[elem_index].return_hour : ""));
    const [error, setError] = useState(false);
    const timeArray = [];

    const itinerary_list = useSelector((store) => {
        return store.itinerary.itinerary_list;
    });

    for (let i = 0; i < 24; i++) {
        for (let j = 0; j <= 1; j++) {
            if (i === 0 && j === 0) {
                timeArray.push(t("global.midnight"));
            } else if (i === 12 && j === 0) {
                timeArray.push(t("global.noon"));
            } else {
                timeArray.push((i < 10 ? '0' + i : i) + 'h' + (j === 0 ? "00" : "30"));
            }
        }
    }

  	useEffect(() => {
        setError(type === "departure" ? search[elem_index].origin_hour_error : search[elem_index].return_hour_error);
  	}, [type === "departure" ? search[elem_index].origin_hour_error : search[elem_index].return_hour_error]);
	  useEffect(() => {
        setTime(type === "departure" ? search[elem_index].origin_hour : search[elem_index].return_hour);
    }, [type === "departure" ? search[elem_index].origin_hour : search[elem_index].return_hour]);
  	useEffect(() => {
  		let tmp_search = search.slice();
  		let copy_search = [];
  		if (search[elem_index].origin_dest !== null && type === "departure" && search[elem_index].origin_hour === null) {
  			let new_time = GetTime("departure", itinerary_list, search, elem_index, t);
  			setTime(new_time);
  		}
  	}, [search[elem_index].origin_dest]);
  	useEffect(() => {
  		let tmp_search = search.slice();
  		let copy_search = [];
  		if (search[elem_index].return_dest !== null && type !== "departure" && search[elem_index].return_hour === null) {
  			let new_time = GetTime("return", itinerary_list, search, elem_index, t);
  			setTime(new_time);
  		}
  	}, [search[elem_index].return_dest]);

    function handleChange(e) {
        let tmp_search = search.slice();
        let copy_search = [];
        tmp_search.map((elem, index) => {
            if (index === elem_index) {
                let tmp_elem = Object.assign({}, elem);
                if (type === "departure") {
                    tmp_elem.origin_hour = e.target.value;
                    tmp_elem.origin_hour_error = false;
                } else {
                    tmp_elem.return_hour = e.target.value;
                    tmp_elem.return_hour_error = false;
                }
                elem = tmp_elem;
            }
            copy_search.push(elem);
        });
        //setError(false);
        setSearch(copy_search);
        setTime(e.target.value);
    }
    return (
    //<FormControl fullWidth variant={"outlined"} error={error} className={`${matches ? classes.formControlRoot : classes.formControlRoot1} ${!mobile ? classes.minWidth86 : (isTablet ? classes.minWidth78 : "") } ${matches ? (type === "departure"  ? classes.inputBorder1 : classes.inputBorder2) : classes.inputBorder3}`}>
    //<InputLabel id={"render-time-" + type}>{t("global.hour")}</InputLabel>
	    //<Select
    //MenuProps={{ disableScrollLock: true }}
	    //labelId={"render-time-" + type}
	    //id={"select-render-time-" + type}
	    //value={time}
	    //onChange={(e) => handleChange(e)}
	    //label={t("global.hour")}
	    //>
	    //{
	    //timeArray.map((time, time_index) => {
	    //return (
	    //<MenuItem key={time_index} value={time}>{time}</MenuItem>
	    //)
	    //})
	    //}
	    //</Select>
    //</FormControl>
        <TextField
            select
            fullWidth
            variant={"outlined"}
            label={t("global.hour")}
            error={	error}
            value={time}
            className={`${matches ? classes.formControlRoot : classes.formControlRoot1} ${!mobile ? classes.minWidth86 : (isTablet ? classes.minWidth78 : "") } ${matches ? (type === "departure" ? classes.inputBorder1 : classes.inputBorder2) : classes.inputBorder3}`}
            onChange={(e) => handleChange(e)}
            InputProps={{
                classes: { notchedOutline: (type === "departure" ? (matches ? classes.noBorder : "") : (matches ? classes.noBorderLeft : "")) }
            }}
        >
            {
                timeArray.map((time, time_index) => {
                    return (
                        <MenuItem key={time_index} value={time}>{time}</MenuItem>
                    );
                })
            }
        </TextField>
    );
};
export default React.memo(RenderTime);
