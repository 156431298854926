//---- Dependencies ----//
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

//---- Commons ----//

//---- CSS ----//
import "../../../Style/Menu/TripList.css";

//---- Actions ----//
import { ToggleVideoModal } from "../../../Actions/Menu";

//---- Class ----//
@connect((store) => {
    return {
        video_to_read: store.menu.video_to_read,
        video_modal: store.menu.video_modal
    }
})
@withTranslation()
class VideoModal extends Component {
    constructor(props, context) {
        super(props, context);
    }


    render() {
        const { video_to_read, video_modal, dispatch } = this.props;
        return (
            <Modal id={ "video-modal" } isOpen={ video_modal } toggle={() => { dispatch(ToggleVideoModal(false, null)); }} size={"lg"}>
                <div>                   
                    <ModalBody className={"grey-bg"}>
                        <iframe width="800" height="500" src={video_to_read} frameBorder="0" allowFullscreen="allowfullscreen" />
                    </ModalBody>
                </div>
            </Modal>
        );
    }
}

export default VideoModal;