// Dependencies
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Core & Lab
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
// Actions
import { updateName } from '../../Actions/Pictures';

const CartPictureName = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const name = useSelector((state) => state.pictures.name);
    const name_error = useSelector((state) => state.pictures.name_error);
    const onNameChange = (event) => {
        dispatch(updateName(event.target.value));
    };
    return (
        <FormControl fullWidth required variant={ 'outlined' } error={ name_error !== '' }>
            <InputLabel htmlFor={ 'picture-name' }>{ t('global.last_name') }</InputLabel>
            <OutlinedInput
                id={ 'picture-name' }
                type={ 'text' }
                onChange={ onNameChange }
                label={ t('global.last_name') }
                value={ name }
                autoComplete={ 'off' }
            />
            <FormHelperText>{ name_error }</FormHelperText>
        </FormControl>
    );
};

export default CartPictureName;