import React from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent
} from "@mui/material";
import { CartFlightCard } from "../CartMaterial/CartFlightCard";

type Props = {
    open: boolean,
    flights: any[],
    onClose: () => void
}

export function ItineraryStepItemFlightModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="lg"
        >
            <DialogContent
                sx={{
                    '& > div': {
                        margin: 0,
                        padding: 0,
                        border: 0
                    }
                }}
            >
                {
                    props.flights.map((flight) => {
                        if (flight.is_manual) {
                            return (
                                <CartFlightCard
                                    type="manual"
                                    flight={flight}
                                />
                            );
                        }
                        return (
                            <CartFlightCard
                                type="normal"
                                flight={flight}
                            />
                        );
                    })
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    {t<string>('global.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
