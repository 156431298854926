import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Currency } from "../../../Reducers/objects/currency";
import { AppState } from "../../../Reducers/Reducers";

type ManualProduct = {
    product_type: number,
    moved_with_recalc: boolean,
    one_way_fees_currency: number,
    one_way_fees: string | null
}

export function useManualProductAlerts(manual_product: ManualProduct | null): JSX.Element[] {
    const { t } = useTranslation();
    const currency = useSelector((state: AppState) => state.trip.currency);
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const priceCurrencyConverter = (first_price: any, first_currency: Currency | undefined, second_currency: Currency | undefined) => {
        if (first_currency?.iso_code === second_currency?.iso_code) {
            return first_price;
        } 
        return (first_currency?.iso_code !== 'USD' ? first_price / (first_currency?.usd_factor ?? 1) : first_price) * (second_currency?.usd_factor ?? 0);
    };
    const oneWayFees = useMemo(() => {
        let one_way_fees = null;
        if (manual_product?.one_way_fees) {
            let one_way_fees_currency = currency_list.find(currency => currency.id === manual_product?.one_way_fees_currency);
            one_way_fees = {
                cost: parseFloat(manual_product?.one_way_fees ?? '0').toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                cost_currency: manual_product?.one_way_fees_currency ? one_way_fees_currency : null,
                converted_cost: manual_product?.one_way_fees_currency ?
                    priceCurrencyConverter(parseFloat(manual_product?.one_way_fees ?? '0'), one_way_fees_currency, currency) :
                    parseFloat(manual_product?.one_way_fees),
                converted_currency: currency
            };
        }
        return one_way_fees;
    }, [manual_product]);
    return [
        manual_product?.moved_with_recalc &&
        <Alert
            variant={ 'filled' }
            severity={ 'warning' }
            action={<Close />}
        >
            { t<string>('cart-material.recalc-move') }
        </Alert>,
        (manual_product?.product_type === 2 || manual_product?.product_type === 4) &&
        oneWayFees &&
        <Alert variant={ 'filled' } severity={ 'warning' }>
            { t<string>('cars.one_way') } :{' '}
            { t<string>('cart-material.resort-fees-1') }{' '}
            { oneWayFees.cost }
            { oneWayFees.cost_currency ? oneWayFees.cost_currency?.symbol : '' }{' '}
           ({ t<string>('cart-material.resort-fees-2') }{' '}
            { oneWayFees.converted_cost }
            { oneWayFees.converted_currency ? oneWayFees.converted_currency.symbol : '' }){' '}
            { t<string>('cart-material.resort-fees-3') }
        </Alert>
    ].filter((item): item is NonNullable<Exclude<typeof item, false>> => !!item);
}
