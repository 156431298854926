export enum StatusBooking {
    NONE = 'DEFAULT',
    CONFIRMED = 'CONFIRMED',
    PENDING = 'PENDING',
    CANCELLED = 'CANCELLED',
    ERROR = 'ERROR',
    REFUSED = 'REFUSED',
    WAITING = "WAITING",
    CANCEL_WAITING = "CANCEL_WAITING",
    UNAVAILABLE = 'UNAVAILABLE'
}
