import { ItineraryContentStep } from "../objects/itineraryContentStep";

type Status = 'not-filled' | 'some-missing' | 'correct'

export function getStepStatus(step: ItineraryContentStep): Status {
    const checkThis = [
        step.content.title?.trim().length !== 0,
        step.content.long_description?.trim().length !== 0,
        (step.content.pictures?.length ?? 0) > 0
    ].filter((item) => item);

    switch (checkThis.length) {
        case 0: return 'not-filled';
        case 3: return 'correct';
        default: return 'some-missing';
    }
}
