//Dependencies
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
//Core
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
//Components
import CartManualProduct from './CartManualProduct';
import { useUnpackagedProducts } from './utils/unpackagedProduts';
import { AppState } from '../../Reducers/Reducers';

export function CartAnnexProducts(): JSX.Element | null {
    const classes = useStyles();
    const unpackagedProducts = useUnpackagedProducts();
    const manualProducts = unpackagedProducts.manualProducts.filter((item) => {
        return item.auto_product && (item.product_type as number) !== 13;
    });

    if (manualProducts.length > 0) {
        return (
            <div>
                <TitleWrapper />
                <hr/>
                {
                    manualProducts.map((manual_product) => {
                        return (
                            <Grid key={ `manual-product-${manual_product.id}` } id={ `manual-product-${manual_product.id}` } container>
                                <CartManualProduct manual_product={ manual_product } />
                                <Grid className={ classes.marginBottom } item xs={ 12 }/>
                            </Grid>
                        );
                    })
                }
            </div>
        );
    }

    return null;
}

function TitleWrapper(): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const unpackagedProducts = useUnpackagedProducts();
    const total_manual_products = useSelector((state: AppState) => state.cart.total_manual_products).filter((item) => {
        return item.auto_product;
    });
    const manualProducts = unpackagedProducts.manualProducts.filter((item) => {
        return item.auto_product && (item.product_type as number) !== 13;
    });

    return (
        <Typography className={ classes.title }>
            { t<string>('cart-material.total-auto-product') } ({manualProducts.length}) :{' '}
            {
                total_manual_products.map((currency_cost, currency_index) => {
                    return `${currency_index > 0 ? ' + ' : ''}${currency_cost.cost.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}${currency_cost.currency.symbol}`;
                })
            }
        </Typography>
    );
}

const useStyles = makeStyles({
    title: {
        fontSize: 24,
        fontWeight: 700
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    marginBottom: {
        marginBottom: 15
    },
    manualProductCreateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    }
});
