import { TFunction } from "i18next";
import { StepsDatesManager } from "./utils/stepsDatesManager";
import { ItineraryInput } from "./objects/itineraryState";
import content from "./utils/itineraryDestinationInfoWindow.html?raw";
import "./utils/itineraryDestinationInfoWindow.css";

type Options<T> = {
    position: google.maps.LatLng,
    info: T,
    pinHeight: number,
    steps?: ItineraryInput[],
    map: google.maps.Map,
    t: TFunction
}

const stepElementTemplate = new DOMParser().parseFromString(
    content,
    'text/html'
).getElementsByClassName('destination-iw-steps-item')[0] as HTMLDivElement;

export function createItineraryDestinationInfoWindowClass() {
    return class ItineraryDestinationInfoWindow extends google.maps.OverlayView {
        private position: google.maps.LatLng;
        private container: HTMLDivElement;
        private info: {
            id: number,
            name: string,
            picture: string;
            tripStartDate: string,
            tripEndDate: string,
            locked: boolean,
            steps: ItineraryInput[]
        };
        private pinHeight: number;
        private listeners: Partial<{
            close: () => void,
            addAsStep: () => void,
            seeInfo: () => void,
            removeStep: (id: ItineraryInput['id']) => void,
            changeStepNightsCount: (id: ItineraryInput['id'], nightsCount: number) => void
        }> = {};
        private steps: ItineraryInput[];
        private toBeRestoredMap: google.maps.Map;
        private t: TFunction;
    
        public constructor(options: Options<ItineraryDestinationInfoWindow['info']>) {
            super();
            this.position = options.position;
            this.info = options.info;
            this.pinHeight = options.pinHeight;
            this.steps = options.steps ?? [];
            this.toBeRestoredMap = options.map;
            this.t = options.t;
            this.container = document.createElement('div');
            this.container.innerHTML = content;
            this.container.style.display = "none";
            this.container.style.position = "absolute";
            this.container.style.transform = `translate(-50%, -100%)`;
            this.container.style.zIndex = '3';
            this.container.style.cursor = 'auto';
            this.container.style.visibility = 'hidden';
            this.setMap(this.toBeRestoredMap);
            ItineraryDestinationInfoWindow.preventMapHitsAndGesturesFrom(this.container);
        }

        private render(): void {
            this.renderText();
            this.renderCover();
            this.renderAddStepButton();
            this.renderSteps();
        }

        private renderText(): void {
            const textElement = this.container.getElementsByClassName('destination-iw-cover-text')[0];
            if (textElement) {
                (textElement as HTMLDivElement).innerText = this.info.name;
            }
        }

        private renderCover(): void {
            const coverElement = this.container.getElementsByClassName('destination-iw-cover')[0];
            if (coverElement) {
                (coverElement as HTMLDivElement).style.backgroundImage = `url(${this.info.picture})`;
            }
        }

        private renderAddStepButton(): void {
            const addAsStepButtonElement = this.container.getElementsByClassName('destination-iw-add-to-itinerary-button')[0];
            if (addAsStepButtonElement) {
                (addAsStepButtonElement as HTMLButtonElement).innerText = '+ ' + this.t('itinerary.add-destination-from-map-as-step');
            }
        }

        private renderSteps(): void {
            const stepsContainerElement = this.container.getElementsByClassName('destination-iw-steps')[0];
            const children = this.steps.map((step) => {
                const element = stepElementTemplate.cloneNode(true) as HTMLDivElement;
                const noElement = element.getElementsByClassName('destination-iw-steps-item-no')[0] as HTMLDivElement;
                const textElement = element.getElementsByClassName('destination-iw-steps-item-dates')[0] as HTMLDivElement;
                const from = window.moment.utc(step.start_date).format('DD-MM-YYYY');
                const to = window.moment.utc(step.end_date).format('DD-MM-YYYY');
                const no = this.info.steps.filter((item) => {
                    return item.step_type === 'STEP';
                }).findIndex((item) => {
                    return item.id === step.id;
                }) + 1;
                noElement.innerText = this.t('itinerary.step-no', { no });
                textElement.innerHTML = `${this.t('itinerary.step-from', { date: from })}<br />${this.t('itinerary.step-to', { date: to })}`;

                const removeElement = element.getElementsByClassName('destination-iw-steps-item-remove')[0] as HTMLButtonElement;
                const nightsSelectorElement = element.getElementsByClassName('destination-iw-steps-item-nights')[0] as HTMLSelectElement;

                if (!this.info.locked) {
                    removeElement.style.display = 'inline-block';
                    nightsSelectorElement.style.display = 'flex';

                    //register remove button event handler
                    //@see https://stackoverflow.com/questions/9251837/how-to-remove-all-listeners-in-an-element
                    const newRemoveElement = removeElement.cloneNode(true);
                    removeElement.parentNode?.replaceChild(newRemoveElement, removeElement);
                    newRemoveElement.addEventListener('click', () => {
                        if (this.listeners.removeStep) {
                            this.listeners.removeStep(step.id);
                        }
                    });
    
                    //add nights count selection options
                    const optionElement = stepElementTemplate.querySelector('.destination-iw-steps-item-nights option');
                    if (optionElement) {
                        const manager = new StepsDatesManager(
                            this.info.tripStartDate,
                            this.info.tripEndDate
                        );
                        const count = manager.countStepNights(step) + (manager.countTripNights(this.info.steps) - manager.countTotalNights(this.info.steps)) + 1;
                        const elements = new Array(count >= 0 ? count : 0).fill(null).map((_, index) => {
                            const element = optionElement.cloneNode(true) as HTMLOptionElement;
                            element.innerText = this.t('itinerary.nights-count', { count: index });
                            element.value = index.toString();
                            element.selected = index === manager.countStepNights(step);
                            return element;
                        });
                        const newNightsSelectorElement = nightsSelectorElement.cloneNode(true) as HTMLSelectElement;
                        nightsSelectorElement.parentNode?.replaceChild(newNightsSelectorElement, nightsSelectorElement);
                        newNightsSelectorElement.replaceChildren(...elements);
                        newNightsSelectorElement.addEventListener('change', (event) => {
                            if (this.listeners.changeStepNightsCount) {
                                this.listeners.changeStepNightsCount(step.id, parseFloat((event.target as HTMLSelectElement).value));
                            }
                        });
                    }
                } else {
                    removeElement.style.display = 'none';
                    nightsSelectorElement.style.display = 'none';
                }

                return element;
            });
            stepsContainerElement?.replaceChildren(...children);
        }
    
        public onAdd(): void {
            this.getPanes()?.floatPane.appendChild(this.container);
        }
    
        public onRemove(): void {
            if (this.container.parentElement) {
                this.container.parentElement.removeChild(this.container);
            }
        }
    
        public draw(): void {
            let display = 'none';
            const position = this.getProjection().fromLatLngToDivPixel(this.position);
            this.render();
            if (position) {
                display = Math.abs(position.x) < 4000 && Math.abs(position.y) < 4000
                    ? "block"
                    : "none";
            }

            if (display === "block" && position) {
                this.container.style.left = position.x + "px";
                this.container.style.top = position.y + "px";
            }
        
            if (this.container.style.display !== display) {
                this.container.style.display = display;
            }
        }

        public show(): void {
            this.container.style.visibility = 'visible';
        }

        public hide(): void {
            this.container.style.visibility = 'hidden';
        }

        public setFeatureListener<K extends keyof ItineraryDestinationInfoWindow['listeners']>(
            key: K,
            callback: NonNullable<ItineraryDestinationInfoWindow['listeners'][K]>
        ): void {
            const currentCallback = this.listeners[key];
            const closeButton = this.container.getElementsByClassName('destination-iw-close-button')[0] as HTMLButtonElement | undefined;
            const addAsStepButton = this.container.getElementsByClassName('destination-iw-add-to-itinerary-button')[0] as HTMLButtonElement | undefined;
            const seeInfoButton = this.container.getElementsByClassName('destination-iw-info-button')[0] as HTMLButtonElement | undefined;

            //remove last callback
            if (currentCallback) {
                switch (key) {
                    case 'close': closeButton?.removeEventListener('click', currentCallback as () => void); break;
                    case 'addAsStep': addAsStepButton?.removeEventListener('click', currentCallback as () => void); break;
                    case 'seeInfo': seeInfoButton?.removeEventListener('click', currentCallback as () => void); break;
                }
            }

            //set new callback
            switch (key) {
                case 'close': closeButton?.addEventListener('click', callback as () => void); break;
                case 'addAsStep': addAsStepButton?.addEventListener('click', callback as () => void); break;
                case 'seeInfo': seeInfoButton?.addEventListener('click', callback as () => void); break;
            }

            this.listeners[key] = callback;
        }

        public replaceSteps(steps: ItineraryInput[]): void {
            this.steps = steps;
            this.renderSteps();
        }

        public replaceInfo(info: ItineraryDestinationInfoWindow['info']): void {
            this.info = info;
            this.render();
        }
    };
}
