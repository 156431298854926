import React, { Fragment } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from "@material-ui/core/IconButton";
import Close from '@material-ui/icons/Close';
import NavigateBefore from '@material-ui/icons/NavigateBefore';

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: "bold"
    },
    inline: {
        display: "inline-block"
    },
    tripadvisorRating: {
        marginLeft: "-5px"
    },
    userRating: {
        marginRight: "-15px"
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    alignRight: {
        textAlign: "right"
    },
}));

const TripAdvisor = ({tripadvisor, open, toggleTripadvisorModal}) => {
    console.log("tripadvisor render");
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Dialog fullScreen={ fullScreen } open={ open } onClose={ toggleTripadvisorModal } maxWidth={ "md" }>
            <DialogTitle onClose={ toggleTripadvisorModal }>
                <Grid container justify={ "space-between" } alignItems={ "center" }>
                    {
                        fullScreen ? (
                            <Grid item>
                                <IconButton edge={ "start" } onClick={ toggleTripadvisorModal }><NavigateBefore/></IconButton> { t("global.informations") }
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item>
                                    { t("global.informations") }
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={ toggleTripadvisorModal }><Close/></IconButton>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Typography className={ classes.bold }>{ tripadvisor.name }</Typography>
                <Typography variant={ "body1" }>{ tripadvisor.address_obj.address_string }</Typography>
                <div>
                    <img src={ tripadvisor.rating_image_url } alt={ "tripadvisor rating" } className={ `${classes.inline} ${classes.tripadvisorRating}` }/>
                    <Typography className={ classes.inline }>{ t("accommodation.tripadvisor.based_on") } { tripadvisor.num_reviews } { t("accommodation.tripadvisor.traveler_opinion") }</Typography>
                </div>
                <br/>
                {
                    tripadvisor.ranking_data !== null && (
                        <Typography>{ tripadvisor.ranking_data.ranking_string }</Typography>
                    )
                }
                {
                    tripadvisor.awards !== undefined && tripadvisor.awards !== null && tripadvisor.awards.data !== undefined && tripadvisor.awards.data !== null && (
                        <Fragment>
                            <br/>
                            <Divider/>
                            <br/>
                            <Typography>{ t("tripadvisor.awards") } : </Typography>
                            <br/>
                            {
                                tripadvisor.awards.data.map((award, award_index) => {
                                    return (
                                        <div key={ award_index }>
                                            <img src={ award.images.small } alt={ "award" } className={ classes.inline }/>
                                            <Typography className={ classes.inline }>{ award.award_type } { award.year }</Typography>
                                        </div>
                                    );
                                })
                            }
                        </Fragment>
                    )
                }
                {
                    tripadvisor.reviews !== null && (
                        <Fragment>
                            <br/>
                            <Divider/>
                            <br/>
                            <Typography>{ t("tripadvisor.reviews") } : </Typography>
                            <br/>
                            {
                                tripadvisor.reviews.map((review, review_index) => {
                                    return (
                                        <Paper key={ review_index } className={ classes.paper } variant={ "outlined" }>
                                            <Grid container spacing={ 2 }>
                                                <Grid item xs={ 6 }>
                                                    {
                                                        review.user !== undefined && review.user !== null && review.user.username !== undefined && review.user.username !== null &&
                                                        <Typography>{ review.user?.username }</Typography>
                                                    }
                                                    {
                                                        review.trip_type !== null &&
                                                        <Typography>{ review.trip_type }</Typography>
                                                    }
                                                </Grid>
                                                <Grid item container justify={ "flex-end" } xs={ 6 }>
                                                    <Grid item className={ classes.alignRight }>
                                                        {
                                                            review.rating_image_url !== undefined && review.rating_image_url !== null &&
                                                            <img src={ review.rating_image_url } alt={ "user rating" } className={ classes.userRating }/>
                                                        }
                                                        {
                                                            review.travel_date !== undefined && review.travel_date !== null &&
                                                            <Typography>{ review.travel_date }</Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <br/>
                                            <Divider/>
                                            <br/>
                                            {
                                                review.text !== undefined && review.text !== null &&
                                                <Typography>{ review.text }</Typography>
                                            }
                                            {
                                                review.url !== undefined && review.url !== null &&
                                                <Link to={ review.url } target={ "_blank" } onlyActiveOnIndex>
                                                    <Typography>{ t("global.read_more") }</Typography>
                                                </Link>
                                            }
                                        </Paper>
                                    );
                                })
                            }
                        </Fragment>
                    )
                }
            </DialogContent>
        </Dialog>
    )
};

export default React.memo(TripAdvisor);