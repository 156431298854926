import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from "@material-ui/core/IconButton";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';

import Transition from "../../../Common/Transition";

import { ToggleProvider, TogglePrivatePrice, TogglePublicPrice, ToggleFlightNDC, ToggleFlightGDS } from "../../../../Actions/FlightSearch";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    verticalSpacer: {
        padding: "8px 0px"
    },
    horizontalSpacer: {
        padding: "0px 16px"
    },
    filterButton: {
        border: "1.03872px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: 31.1617,
        padding: "8px 12px"
    },
    closeMenu: {
        marginRight: 5
    }
}));

const MoreFiltersButton = (({}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const providers = useSelector(store => store.flight_search.providers);
    const providers_list = useSelector(store => store.trip.providers);
    const private_price = useSelector(store => store.flight_search.private_price);
    const public_price = useSelector(store => store.flight_search.public_price);
    const flight_source_gds = useSelector(store => store.flight_search.flight_source_gds);
    const flight_source_ndc = useSelector(store => store.flight_search.flight_source_ndc);
    const flight_list = useSelector(store => store.flight_search.flight_list);
    const [openFilters, setOpenFilters] = useState(null);
    return (
        <Grid item>
            <Button size="small" className={classes.filterButton} onClick={(event) => setOpenFilters(event.currentTarget)}>
                <AddCircleOutlineIcon className={classes.genericText} />
                <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`} style={{ paddingLeft: 8, textTransform: "capitalize" }}>
                    { t("flight_search.filters") }
                </div>
            </Button>
            {
                isHandHeld && (
                    <Dialog open={Boolean(openFilters)} fullScreen TransitionComponent={Transition}>
                        <DialogTitle>
                            <Grid container alignItems="center" justify="flex-end">
                                <Grid item xs={4} />
                                <Grid item xs={4} className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                    { t("filters.providers") }
                                </Grid>
                                <Grid item xs={4} className={classes.textRight}>
                                    <IconButton size="small" className={classes.closeMenu} onClick={() => setOpenFilters(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ padding: "0px 18px 0px 8px" }}>
                            <FormGroup className={classes.horizontalSpacer}>
                                {
                                    providers !== null && providers.map((provider) => {
                                        let name = providers_list.find(element => element.provider.id === provider.id).provider.name;
                                        if (provider.displayed !== null) {
                                            return (
                                                <FormControlLabel
                                                    key={provider.id}
                                                    control={<Checkbox sx={{
                                                        '&.Mui-checked': {
                                                            color: `${main_color}`
                                                        }
                                                    }} checked={provider.displayed} onChange={() => dispatch(ToggleProvider(provider.id))} name="private_price" size="small" />}
                                                    label={name}
                                                    classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                                />
                                            );
                                        }
                                    })
                                }
                            </FormGroup>
                            <Divider />
                            <div className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                { t("filters.price_rate") }
                            </div>
                            <Divider />
                            <FormGroup className={classes.horizontalSpacer}>
                                {
                                    private_price !== null && (
                                        <FormControlLabel 
                                            control={<Checkbox sx={{
                                                '&.Mui-checked': {
                                                    color: `${main_color}`
                                                }
                                            }} checked={private_price} onChange={() => dispatch(TogglePrivatePrice())} name="private_price" size="small" />}
                                            label={t("flight_groups.negociated_price")}
                                            classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                        />
                                    )
                                }
                                {
                                    public_price !== null && (
                                        <FormControlLabel 
                                            control={<Checkbox sx={{
                                                '&.Mui-checked': {
                                                    color: `${main_color}`
                                                }
                                            }} checked={public_price} onChange={() => dispatch(TogglePublicPrice())} name="public_price" size="small" />}
                                            label={t("flight_groups.public_price")}
                                            classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                        />
                                    )
                                }
                            </FormGroup>
                        </DialogContent>
                    </Dialog>
                )
            }
            {
                !isHandHeld && (
                    <Menu 
                        id="more-menu"
                        anchorEl={openFilters}
                        keepMounted
                        open={Boolean(openFilters)}
                        onClose={() => setOpenFilters(false)}
                        disableScrollLock={true}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                        <Grid container alignItems="center" justify="flex-end" style={{ width: 500 }}>
                            <Grid item xs={4} />
                            <Grid item xs={4} className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                { t("filters.providers") }
                            </Grid>
                            <Grid item xs={4} className={classes.textRight}>
                                <IconButton size="small" className={classes.closeMenu} onClick={() => setOpenFilters(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider />
                        <FormGroup className={classes.horizontalSpacer}>
                            {
                                providers !== undefined && providers !== null && providers.map((provider) => {
                                    let name = providers_list.find(element => element.provider.id === provider.id).provider.name;
                                    if (provider.displayed !== null) {
                                        return (
                                            <FormControlLabel
                                                key={provider.id}
                                                control={<Checkbox sx={{
                                                    '&.Mui-checked': {
                                                        color: `${main_color}`
                                                    }
                                                }} checked={provider.displayed} onChange={() => dispatch(ToggleProvider(provider.id))} name="private_price" size="small" />}
                                                label={name}
                                                classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                            />
                                        );
                                    }
                                })
                            }
                        </FormGroup>
                        {
                            flight_list.find(flight => flight.flight_source === 'GDS') !== undefined && flight_list.find(flight => flight.flight_source === 'NDC') !== undefined &&
                            <div>
                                <Divider />
                                <div className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                    { t("filters.flight_source") }
                                </div>
                                <Divider />
                                <FormGroup className={classes.horizontalSpacer}>
                                    <FormControlLabel 
                                        control={<Checkbox sx={{
                                            '&.Mui-checked': {
                                                color: `${main_color}`
                                            }
                                        }} checked={flight_source_ndc} onChange={() => dispatch(ToggleFlightNDC())} name="ndc" size="small" />}
                                        label={t("cart-material.cart-construction-ndc")}
                                        classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                    <FormControlLabel 
                                        control={<Checkbox sx={{
                                            '&.Mui-checked': {
                                                color: `${main_color}`
                                            }
                                        }} checked={flight_source_gds} onChange={() => dispatch(ToggleFlightGDS())} name="gds" size="small" />}
                                        label={t("cart-material.cart-construction-standard")}
                                        classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                </FormGroup>
                            </div>
                        }
                        <Divider />
                        <div className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                            { t("filters.price_rate") }
                        </div>
                        <Divider />
                        <FormGroup className={classes.horizontalSpacer}>
                            {
                                private_price !== null && (
                                    <FormControlLabel 
                                        control={<Checkbox sx={{
                                            '&.Mui-checked': {
                                                color: `${main_color}`
                                            }
                                        }} checked={private_price} onChange={() => dispatch(TogglePrivatePrice())} name="private_price" size="small" />}
                                        label={t("flight_groups.negociated_price")}
                                        classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                )
                            }
                            {
                                public_price !== null && (
                                    <FormControlLabel 
                                        control={<Checkbox sx={{
                                            '&.Mui-checked': {
                                                color: `${main_color}`
                                            }
                                        }} checked={public_price} onChange={() => dispatch(TogglePublicPrice())} name="public_price" size="small" />}
                                        label={t("flight_groups.public_price")}
                                        classes={{ label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                )
                            }
                        </FormGroup>
                    </Menu>
                )
            }
        </Grid>
    );
});

export default React.memo(MoreFiltersButton);
