// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Core & Lab
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CartAssistanceBasic = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const assistance_vip = useSelector(state => state.cart.assistance_vip);
    const onToggleAssistanceVip = () => {
        dispatch({type: 'CART_TOGGLE_ASSISTANCE_VIP'});
    };
    return (
        <FormControl fullWidth>
            <FormControlLabel control={ <Checkbox checked={ !assistance_vip } onChange={ onToggleAssistanceVip } color={ 'primary' }/> } label={ t('cart-material.assistance-basic-option') }/>
        </FormControl>
    );
};

export default CartAssistanceBasic;