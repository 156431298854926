import axios, { AxiosResponse } from "axios";
import memoizee from "memoizee";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { Rubric } from "../objects/rubric";

async function fetch(tripId: number): Promise<Rubric[] | null> {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        const data: Rubric[] = [];
        let next: string | null = `${API_HREF}client/${window.id_owner}/roadbook-rubrics/`;
        do {
            try {
                const response: AxiosResponse<{
                    next: string | null, 
                    results: Rubric[]
                }> = await axios.get(
                    next ?? `${API_HREF}client/${window.id_owner}/roadbook-rubrics/`,
                    {
                        headers,
                        params: {
                            trip: tripId
                        }
                    }
                );
                next = response.data.next;
                data.push(...response.data.results);
            } catch (error) {
                console.error(error);
                return null;
            }
        } while (next);
        return data;
    }
    return null;
}

export const findTripRoadbookRubrics = memoizee(
    fetch,
    { promise: true, primitive: true }
);
