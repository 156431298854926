import React, { Fragment, useState, useEffect, useRef, useContext } from "react";

import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import AcUnitOutlinedIcon from '@material-ui/icons/AcUnitOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import DoorIcon from "./DoorIcon.js";
import GearIcon from "./GearIcon.js";
import NavigateBefore from "@material-ui/icons/NavigateBefore";

//import InitMap from "../Cars/Functions/InitMap.js";
import RenderMap from "./RenderMap.js";
import RenderStation from "./RenderStation.js";
import MobileRenderStation from "./MobileRenderStation.js";

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";


//---- Common ----//
import { CartConstructionReplaceProductContext } from "../CartMaterial/utils/cartConstructionReplaceProductContext";
import GetProvider from "../Common/Functions/GetProvider.js";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: theme.spacing(3)
    },
    carCardImg: {
        maxWidth: '100%',
        maxHeight: '100%'
    },
    carsSmallPic: {
        width: 300,
        marginTop: 25
    },
    carCardProvider: {
        position: 'absolute',
        left: 0,
        maxWidth: 90
    },
    providerSmallPic: {
        height: 20
    },
    sub50: {
        left: 50
    },
    sub30: {
        left: 30
    },
    font14: {
        fontSize: 14
    },
    font20: {
        fontSize: 20
    },
    font24: {
        fontSize: 24
    },
    font34: {
        fontSize: 34
    },
    ftBlack: {
        color: 'black'
    },
    ftOrange: {
        color: '#e6592f'
    },
    ftGray: {
        opacity: '54%'
    },
    bold: {
        fontWeight: 'bold'
    },
    carCardCategory: {
        textTransform: 'uppercase',
        fontSize: 12
    },
    similarCar: {
        textTransform: 'uppercase',
        fontWeight: 900,
        fontSize: 16,
        opacity: '54%'
    },
    similarCarMobile: {
        textTransform: 'uppercase',
        fontWeight: 900,
        fontSize: 10,
        opacity: '54%'
    },
    categoryMessage: {
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: 14,
        opacity: '54%',
        marginTop: 15
    },
    categoryMessageMobile: {
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: 10,
        opacity: '54%',
        marginTop: 15
    },
    carCardInfo: {
        display: 'inline-block',
        marginRight: 11
    },
    carCardInfoFlex: {
        display: 'inline-flex',
        marginRight: 11
    },
    ftMt15: {
        marginTop: 15
    },
    ftMt20: {
        marginTop: 20
    },
    ftMr5: {
        marginRight: 5
    },
    ftMt110: {
        marginTop: 110
    },
    carService: {
        "listStyle": 'none',
        "marginTop": 10,
        '& li': {
            height: 26
        }
    },
    iconCheckCircle: {
        position: 'absolute',
        left: '-45px',
        top: 1,
        opacity: '54%'
    },
    carServiceTitle: {
        marginLeft: '-17px',
        fontSize: 14
    },
    carFreeShuttle: {
        '& i': {
            marginRight: 6,
            verticalAlign: 'middle'
        }
    },
    textCenter: {
        textAlign: 'center'
    },
    textUppercase: {
        textTransform: 'uppercase'
    },
    verticalAlignSub: {
        verticalAlign: 'sub'
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white",
        marginRight: 20
    },
    greenButton: {
        backgroundColor: "#76B6C2",
        color: "white"
    },
    pointer: {
        cursor: 'pointer'
    },
    carInfoTitle: {
        borderBottom: '1px solid #e0e5eb',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 16,
        paddingTop: 10,
        width: '90%'
    },
    autoHeight: {
        minHeight: 200
    },
    relativePosition: {
        position: "relative"
    },
    smallLogo: {
        position: "absolute",
        left: "-24px",
        top: 4
    },
    littleLogo: {
        position: "absolute",
        left: "-35px",
        top: "-2px"
    }
}));

function usePrevious(value) {
    const ref = useRef();

    //Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); //Only re-run if value changes

    //Return previous value (happens before update in useEffect above)
    return ref.current;
}

const RenderDisplayCars = ({ card, card_index, display_cars, results, getContractInfo }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const provider_list = useSelector((store) => {
        return store.trip.providers;
    });
    const language = useSelector((store) => {
        return store.header.tmp_language;
    });
    const currency_list = useSelector((store) => {
        return store.base.currency_list;
    });
    const currency = useSelector((store) => {
        return store.trip.currency;
    });
    const user = useSelector((store) => {
        return store.user.user;
    });
    const [isContract, setIsContract] = useState(false);
    const [pic_car_height, setPicHeight] = useState(0);
    const [pic_width, setPicWidth] = useState(0);
    const [subprovider_width, setSubproviderWidth] = useState(0);
    const [initialized, setInitialized] = useState(false);
    const [detail, setDetail] = useState(false);
    const [tab_index, setTab] = useState(0);
    const [station, setStation] = useState([]);
    const [hovered, setHovered] = useState(null);
    const [checked, setChecked] = useState(null);
    const [checked_return, setCheckedReturn] = useState(null);
    const [click_type, setClickType] = useState(false);
    const [click_type1, setClickType1] = useState(false);
    const [mobileStation, setMobileStation] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const prevItem = usePrevious({ initialized, detail });
    const replaceProductContext = useContext(CartConstructionReplaceProductContext);

    const nextTabRef = useRef(null);
    const contractRef = useRef(null);
    const mapRef = useRef(null);
    const detailRef = useRef(null);

    const handleToggle = (value, type) => () => {
        if (type !== "return") {
            if (checked !== value) {
                setChecked(value);
            } else {
                setChecked(null);
            }
        } else if (checked_return !== value) {
            setCheckedReturn(value);
        } else {
            setCheckedReturn(null);
        }
    };
    let stations = null;
    let important_notice = "";
    results.map((provider) => {
        if (provider.provider_name === card.provider && provider.subprovider_name === card.subprovider) {
            stations = provider.stations;
            important_notice = provider.important_notice;
        }
    });
    let stations_arr = {
        pick_up: [],
        return: []
    };
    if (stations !== null) {
        stations.map((station) => {
            if (station.is_pickup_station) {
                stations_arr.pick_up.push(station);
            }
            if (station.is_return_station) {
                stations_arr.return.push(station);
            }
        });
    }
    stations_arr.pick_up = stations_arr.pick_up.sort((a, b) => {
        return Number(b.at_airport) - Number(a.at_airport);
    });
    stations_arr.return = stations_arr.return.sort((a, b) => {
        return Number(b.at_airport) - Number(a.at_airport);
    });
    useEffect(() => {
        if (prevItem !== undefined && prevItem.initialized !== initialized) {
            if (initialized) {
                let station = null;
                if (tab_index === 0) {
                    station = stations_arr.pick_up.slice();
                } else {
                    station = stations_arr.return.slice();
                }
                setStation(station);
            }
        }
    }, [initialized]);
    useEffect(() => {
        let station = null;
        if (tab_index === 0) {
            station = stations_arr.pick_up.slice();
        } else {
            station = stations_arr.return.slice();
        }
        setStation(station);
    }, [tab_index]);
    useEffect(() => {
        setTab(0);
        setInitialized(false);
    }, [display_cars]);

    useEffect(() => {
        if (initialized) {
            setTimeout(() => {
                mapRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
            }, 200);
        }
    }, [initialized]);

    useEffect(() => {
        if (checked !== null) {
            if (stations_arr.return.length !== 0) {
                nextTabRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
            } else {
                contractRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
            }
        }
    }, [checked]);

    useEffect(() => {
        if (checked_return !== null) {
            contractRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest'
            });
        }
    }, [checked_return]);

    //useEffect(() => {
    //if (detail) {
    //detailRef.current.scrollIntoView({behavior: "smooth", block: "start"});
    //}
    //}, [detail]);

    function toggleDetail() {
        setDetail(!detail);
        setClickType(!click_type);
    }

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    const onMouseEnter = station_index => event => {
        setHovered(station_index);
    };
    const onMouseLeave = () => {
        setHovered(null);
    };
    const handleContract = () => {
        setIsContract(true);
        dispatch({ type: "CARS_TOGGLE_TIMELINE", payload: { cars_timeline: false } });
        getContractInfo({
            checked: checked,
            checked_return: checked_return,
            stations: stations_arr,
            selected_car: card,
            card_index: card_index,
            important_notice: important_notice
        });
        if (replaceProductContext.enableReplace) {
            replaceProductContext.onShowNextButton();
            replaceProductContext.onNextStep();
        }
    };
    let provider = GetProvider(provider_list, card.provider_id);
    let subprovider = GetProvider(provider_list, card.subprovider_id);
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    //let quotation_code = "marcovasco";
    const user_type = user.client_full.type;
    //const user_type = 2;
    const logo_to = JSON.parse(localStorage.getItem("config")).favicon.thumbnail_little !== null ? JSON.parse(localStorage.getItem("config")).favicon.thumbnail_little : JSON.parse(localStorage.getItem("config")).favicon.url;
    if (card.prices !== null) {
        let car_name = ((card.vehicle_info.name).toLowerCase()).split(" or similar");
        let selling_currency = currency_list.find((tmp) => tmp.id === card.station_prices[0].prices[0].selling_currency);
        let price = new Intl.NumberFormat(language, { style: 'currency', currency: selling_currency.iso_code }).format(parseFloat(card.station_prices[0].prices[0].selling_price));
        return (
            <Paper key={card_index} elevation={0}>
                <Grid container spacing={ 4 }>
                    <Grid item sm={3} xs={12} className={`${classes.relativePosition} ${!matches ? classes.textCenter : ""}`}>
                        <img src={card.vehicle_info.pict_url} className={
                            clsx({
                                [classes.carCardImg]: true,
                                [classes.carsSmallPic]: pic_car_height < 230
                            })} onLoad={(e) => {
                            setPicHeight(e.target.height);
                        }}/>
                        {
                            card.subprovider_id === card.provider_id && provider.provider.logo !== null && (
                                <Fragment>
                                    {
                                        user_type === 2 && (
                                            <img src={ logo_to } height={ JSON.parse(localStorage.getItem("config")).favicon.thumbnail_little !== null ? 30 : 15 }
												 className={clsx({
													 [classes.smallLogo]: JSON.parse(localStorage.getItem("config")).favicon.thumbnail_little === null,
													 [classes.littleLogo]: JSON.parse(localStorage.getItem("config")).favicon.thumbnail_little !== null
												 })}
                                            />
                                        )
                                    }
                                    <img src={ provider.provider.logo.thumbnail_little !== null ? provider.provider.logo.thumbnail_little : provider.provider.logo.url } className={classes.carCardProvider} />
                                </Fragment>
                            )
                        }
                        {
                            card.subprovider_id !== card.provider_id && provider.provider.logo !== null && (
                                <Fragment>
                                    {
                                        !(user_type === 2) && (
                                            <img src={ provider.provider.logo.thumbnail_little !== null ? provider.provider.logo.thumbnail_little : provider.provider.logo.url } className={
                                                clsx({
                                                    [classes.carCardProvider]: true,
                                                    [classes.providerSmallPic]: true
                                                })} onLoad={(e) => {
                                                setPicWidth(e.target.width);
                                            }} />
                                        )
                                    }
                                    <Fragment>
                                        {
                                            (subprovider !== undefined && subprovider !== null) && subprovider.provider.logo !== null && (
                                                <img src={ subprovider.provider.logo.thumbnail_little !== null ? subprovider.provider.logo.thumbnail_little : subprovider.provider.logo.url } className={
                                                    clsx({
                                                        [classes.carCardProvider]: true,
                                                        [classes.sub30]: pic_width < 25,
                                                        [classes.sub50]: pic_width > 25,
                                                        [classes.providerSmallPic]: true
                                                    })} onLoad={(e) => {
                                                    setSubproviderWidth(e.target.width);
                                                }}/>
                                            )
                                        }
                                        {
                                            (subprovider === undefined || subprovider === null) && (
                                                <img src={ card.subprovider_logo } className={
                                                    clsx({
                                                        [classes.carCardProvider]: true,
                                                        [classes.sub30]: pic_width < 25,
                                                        [classes.sub50]: pic_width > 25,
                                                        [classes.providerSmallPic]: true
                                                    })} onLoad={(e) => {
                                                    setSubproviderWidth(e.target.width);
                                                }}/>
                                            )
                                        }
                                    </Fragment>
                                </Fragment>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Grid container spacing={0}>
                            <Grid item container xs={12}>
                                <Grid item xs={12} className={ clsx({
                                    [classes.font24]: true,
                                    [classes.ftBlack]: true,
                                    [classes.bold]: true
                                }) }>
                                    {car_name[0]}
                                </Grid>
                                <Grid item xs={12} className={ `${matches ? classes.similarCar : classes.similarCarMobile}` }>
                                    {t("cars.or_similiar") + " | " + t("global.category") + t("cars.categories." + ((card.vehicle_info.vehicle_category).toLowerCase()).replace(" ", "_")) + " (" + card.vehicle_info.code + ")"}
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={ classes.carCardInfo } style={{ marginLeft: '-2px' }}>
                                        <Tooltip title={t("global.luggages")} placement={ "bottom" }>
                                            <WorkOutlineIcon fontSize={"small"} style={{ marginRight: 2, verticalAlign: 'sub', opacity: '54%' }}/>
                                        </Tooltip>
                                        <span className={ clsx({ [classes.ftGray]: true, [classes.font14]: true })}>{card.vehicle_info.baggage}</span>
                                    </div>
                                    <div className={ classes.carCardInfo }>
                                        <Tooltip title={t("flight_groups.passengers")} placement={ "bottom" }>
                                            <PermIdentityIcon fontSize={"small"} style={{ marginRight: 2, verticalAlign: 'sub', opacity: '54%' }}/>
                                        </Tooltip>
                                        <span className={ clsx({ [classes.ftGray]: true, [classes.font14]: true })}>{card.vehicle_info.passenger}</span>
                                    </div>
                                    <div className={ classes.carCardInfoFlex }>
                                        <Tooltip title={t("tooltip.door_nbr")} placement={ "bottom" }>
                                            <div>
                                                <DoorIcon fontSize={"small"} style={{ marginRight: 2, verticalAlign: 'sub', opacity: '54%' }}/>
                                            </div>
                                        </Tooltip>
                                        <span className={ clsx({ [classes.ftGray]: true, [classes.font14]: true })} style={{ paddingTop: '3px', paddingLeft: '4px' }}>{card.vehicle_info.door_count}</span>
                                    </div>
                                    {
                                        card.vehicle_info.air_condition && (
                                            <div className={ classes.carCardInfo }>
                                                <Tooltip title={t("accommodation.equipment.air_conditioning")} placement={ "bottom" }>
                                                    <AcUnitOutlinedIcon fontSize={"small"} style={{ marginRight: 2, verticalAlign: 'sub', opacity: '54%' }}/>
                                                </Tooltip>
                                            </div>
                                        )
                                    }
                                    <div className={ classes.carCardInfoFlex }>
                                        <Tooltip title={t("cars.transmission.transmission")} placement={ "bottom" }>
                                            <div>
                                                <GearIcon fontSize={"small"} style={{ marginRight: 2, verticalAlign: 'sub', opacity: '54%' }}/>
                                            </div>
                                        </Tooltip>
                                        <span className={ clsx({ [classes.ftGray]: true, [classes.font14]: true })} style={{ paddingTop: '3px', paddingLeft: '4px', textTransform: 'capitalize' }}>{t("cars.transmission." + (card.vehicle_info.transmission).toLowerCase())}</span>
                                    </div>
                                    {
                                        card.vehicle_info.minimal_age && (
                                            <Grid item xs={12} className={ clsx({ [classes.ftGray]: true, [classes.font14]: true })} style={{ textTransform: 'capitalize' }}>
                                                {t("cars.minimal_age")}
                                                <span className={ classes.ftOrange }>
                                                    {card.vehicle_info.minimal_age}
                                                </span>
                                            </Grid>
                                        )
                                    }
                                    {
                                        card.vehicle_info.licence_time && (
                                            <Grid item xs={12} className={ clsx({ [classes.ftGray]: true, [classes.font14]: true })} style={{ textTransform: 'capitalize' }}>
                                                {t("cars.licence_time")}
                                                <span className={ classes.ftOrange }>
                                                    {card.vehicle_info.licence_time}
                                                </span>
                                            </Grid>
                                        )
                                    }
                                    {
                                        card.contract_type !== "" && card.contract_type !== null && (
                                            <Grid item xs={12} className={ clsx({ [classes.ftGray]: true, [classes.font14]: true })} style={{ textTransform: 'capitalize' }}>
                                                {t("global.contract")}
                                                <span>{" : " + t("cars.contract_" + (card.contract_type.replace(" ", "_")).toLowerCase())}</span>
                                            </Grid>
                                        )
                                    }
                                    {
                                        card.vehicle_services !== null && (
                                            <ul className={ classes.carService }>
                                                {
                                                    card.vehicle_services.map((service, service_index) => {
                                                        return (
                                                            <Fragment key={service_index}>
                                                                <li className={ "ft-relative" }>
                                                                    <span className={classes.carServiceTitle}>{service.title}</span>
                                                                    <CheckCircleOutlinedIcon className={classes.iconCheckCircle}/>
                                                                </li>

                                                            </Fragment>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        )
                                    }
                                    {
                                        card.vehicle_info.vehicle_category_message && (
                                            <Grid item xs={12} className={ `${matches ? classes.categoryMessage : classes.categoryMessageMobile}` }>
                                                {card.vehicle_info.vehicle_category_message}
                                            </Grid>
                                        )
                                    }
                                    {
                                        important_notice !== "" && (
                                            <Grid item xs={12}>
                                                <Button variant={"outlined"} onClick={() => {
                                                    setIsOpen(true);
                                                }}>{t("cars.important_notice")}</Button>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={ 4 } container direction={"column"} justify={"flex-end"} alignItems={"flex-end"}>
                        <Grid item className={ classes.textCenter }>
                            <div className={clsx({ [classes.bold]: true, [classes.font34]: true })}>
                                {price}
                            </div>
                        </Grid>
                        {
                            (card.vehicle_info.return_fuel_full !== null
								|| card.vehicle_info.accident_franchise !== null
								|| card.vehicle_info.theft_franchise !== null
								|| card.vehicle_info.young_taxes_age !== null
								|| card.vehicle_info.young_taxes_amount !== null) && (
                                <Grid item className={ clsx(classes.textCenter, classes.textUppercase, classes.pointer) } onClick={() => {
                                    toggleDetail();
                                }}>
                                    <span className={ classes.ftMr5 }>{t("global.detail")}</span>
                                    {detail ? <RemoveCircleOutlineOutlinedIcon className={classes.verticalAlignSub}/>
                                        : <ControlPointOutlinedIcon className={classes.verticalAlignSub}/>
                                    }
                                </Grid>
                            )
                        }
                        <Grid item className={ classes.textCenter }>
                            <Button variant={ "contained" } className={clsx({ [classes.greenButton]: true, [classes.textUppercase]: true })} onClick={() => {
                                if (matches) {
                                    setInitialized(!initialized);
                                    setClickType1(!click_type1);
                                } else {
                                    setMobileStation(true);
                                }
                            }} >{t("cars.agencies") }</Button>
                        </Grid>
                    </Grid>
                    {
                        detail && (card.vehicle_info.return_fuel_full !== null
							|| card.vehicle_info.accident_franchise !== null
							|| card.vehicle_info.theft_franchise !== null
							|| card.vehicle_info.young_taxes_age !== null
							|| card.vehicle_info.young_taxes_amount !== null) && (
                            <Grid ref={detailRef} item container className={`${classes.ftMt20}`}>
                                {
                                    //<Grid item xs={12} className={ classes.carInfoTitle }/>
                                }
                                <Grid item container direction="row" justify="center" alignItems="center" xs={12} className={clsx({ [classes.ftGray]: true, [classes.bold]: true, [classes.font20]: true })}>
                                    {t("cars.important_information")}
                                </Grid>
                                <Grid item container direction="row" justify="center" alignItems="center" xs={12}>
                                    <List dense={true}>
                                        {
                                            card.vehicle_info.return_fuel_full !== null && (
                                                <ListItem>
                                                    <ListItemText
                                                        primary={card.vehicle_info.return_fuel_full ? t("cars.return_fuel_full") : t("cars.return_fuel_not_full") }
                                                    />
                                                </ListItem>
                                            )
                                        }
                                        {
                                            card.vehicle_info.accident_franchise !== null && (
                                                <ListItem>
                                                    <ListItemText
                                                        primary={t("cars.accident_franchise") + new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(card.vehicle_info.accident_franchise))}
                                                    />
                                                </ListItem>
                                            )
                                        }
                                        {
                                            card.vehicle_info.theft_franchise !== null && (
                                                <ListItem>
                                                    <ListItemText
                                                        primary={t("cars.theft_franchise") + new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(card.vehicle_info.theft_franchise))}
                                                    />
                                                </ListItem>
                                            )
                                        }
                                        {
                                            card.vehicle_info.young_taxes_age !== null && (
                                                <ListItem>
                                                    <ListItemText
                                                        primary={t("cars.young_taxes_age") + card.vehicle_info.young_taxes_age + " " + t("global.years")}
                                                    />
                                                </ListItem>
                                            )
                                        }
                                        {
                                            card.vehicle_info.young_taxes_amount !== null && (
                                                <ListItem>
                                                    <ListItemText
                                                        primary={t("cars.young_taxes_amount") + new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(card.vehicle_info.young_taxes_amount))}
                                                    />
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        initialized && matches && (
                            <Grid item container className={classes.ftMt20} ref={mapRef}>
                                <Grid item sm={6}>
                                    <RenderMap card_index={card_index} station={station} hovered={hovered} provider={provider} card={card}/>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <RenderStation tab_index={tab_index} handleChange={handleChange} stations_arr={stations_arr} handleToggle={handleToggle} setHovered={setHovered} checked={checked} checked_return={checked_return} nextTabRef={nextTabRef} contractRef={contractRef} handleContract={handleContract} setTab={setTab} card={card}/>
                                </Grid>
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <hr/>
                    </Grid>
                </Grid>
                {
                    !matches && (
                        <MobileRenderStation mobileStation={mobileStation} setMobileStation={setMobileStation} tab_index={tab_index} handleChange={handleChange} stations_arr={stations_arr} handleToggle={handleToggle} setHovered={setHovered} checked={checked} checked_return={checked_return} nextTabRef={nextTabRef} contractRef={contractRef} handleContract={handleContract} setTab={setTab}/>
                    )
                }
                <Dialog open={ isOpen } onClose={ () => {
                    setIsOpen(false);
                } } fullWidth fullScreen={ fullScreen } maxWidth={ "md" }>
                    <DialogTitle onClose={ () => {
                        setIsOpen(false);
                    } }>
                        <Grid container justify={ "space-between" } alignItems={ "center" }>
                            <Grid item>
                                <IconButton edge={ "start" } onClick={ () => {
                                    setIsOpen(false);
                                } }><NavigateBefore/></IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={{ textAlign: "justify" }}>
                        {
                            important_notice.split('\n').map((str, str_index) => {
                                return (
                                    <p key={str_index}>{str}</p>
                                );
                            })
                        }
                    </DialogContent>
                </Dialog>
            </Paper>
        );
    }
};
export default React.memo(RenderDisplayCars);
