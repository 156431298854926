export default (price_data, flight_included, setPrices) => {
    let prices = [];
    let tmp_prices = [];
    price_data.map((data) => {
        data.terrestrial_price.map((terrestrial) => {
            if (terrestrial.is_price_reference) {
                terrestrial.periods.map((period) => {
                    let obj = {};
                    let found = data.dates.find((el) => {
                        return el.id === period;
                    });
                    if (found !== undefined) {
                        obj.start_date = found.start_date;
                        obj.end_date = found.end_date;
                        obj.periods = terrestrial.periods.sort();
                        obj.price_xml = terrestrial.price;
                        obj.price_flight = "0";
                        obj.price_custom_product = "0";
                        obj.variant = found.variant;
                        obj.airports = [];
                        obj.name = "";
                        if (found.trip_version !== undefined) {
                            obj.trip_version = found.trip_version;
                        }
                        tmp_prices.push(obj);
                    }
                });
            }
        });
        if (flight_included) {
            if (data.flight_price.length !== 0) {
                tmp_prices.map((price) => {
                    data.flight_price.map((flight) => {
                        if (flight.periods !== undefined && flight.airports.length > 0) {
                            if (price.periods.join() === flight.periods.sort().join()) {
                                let copy_price = Object.assign({}, price);
                                copy_price.price_flight = flight.price;
                                copy_price.airports = flight.airports;
                                price = copy_price;
                            }
                            if (price.airports.length !== 0) {
                                prices.push(price);
                            }
                        }
                    });
                });
                if (prices.length === 0) {
                    prices = tmp_prices.slice();
                }
            }
            else {
                prices = tmp_prices.slice();
            }
        }
        else {
            prices = tmp_prices.slice();
        }
    });
    setPrices(prices);
    // parent_component.setState({prices: prices});
}