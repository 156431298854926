//---- Commons ----//
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";

//---- Actions ----//
import { GetContract, SetContractError } from "../../../Actions/CarsSearch";

export default (value, step_info, location_index, country_code, dispatch, t) => {
	const { pass_check, headers } = CheckBeforeRequest();
	if (pass_check) {
		console.log('value:', value);
		const selected_car = value.selected_car;
		let step = step_info[location_index];
		let tmp_origin_hour = null;
		let tmp_return_hour = null;
		if (step.origin_hour === t("global.midnight")) {
			tmp_origin_hour = "00h00";
		}
		else if (step.origin_hour === t("global.noon")) {
			tmp_origin_hour = "12h00";
		}
		else {
			tmp_origin_hour = step.origin_hour;
		}
		if (step.return_hour === t("global.midnight")) {
			tmp_return_hour = "00h00";
		}
		else if (step.return_hour === t("global.noon")) {
			tmp_return_hour = "12h00";
		}
		else {
			tmp_return_hour = step.return_hour;
		}
		let origin_hour = tmp_origin_hour.replace("h", ":");
		let return_hour = tmp_return_hour.replace("h", ":");
		let tmp_date_origin = (step.origin_date !== null ? moment(step.origin_date).format("YYYY-MM-DD") : step.start_date_origin) + "T" + origin_hour;
		let tmp_date_return = (step.return_date !== null ? moment(step.return_date).format("YYYY-MM-DD") : step.end_date_dest) + "T" + return_hour;
//		let date_origin = tmp_date_origin.format("YYYY-MM-DD[T]HH:MM");
//		let date_return = tmp_date_return.format("YYYY-MM-DD[T]HH:MM");
		$.ajax({
		    method: "GET",
		    headers: headers,
		    url: `${API_HREF}client/${window.id_owner}/cars/vehicle_check_contract/?trip=${GetCookie("trip_id")}`,
		    data: {
				provider_id: selected_car.provider_id,
				date_pickup: tmp_date_origin,
				date_return: tmp_date_return,
				driver_age: step.age === "30+" ? 30 : step.age,
				vehicle_category_id: selected_car.vehicle_info.vehicle_category_id,
				vehicle_type_id: selected_car.vehicle_info.vehicle_type_id,
				transmission_type: selected_car.vehicle_info.transmission,
				vehicle_code: selected_car.vehicle_info.code,
				location_code_pickup: value.stations === undefined ? value.selected_car.location_code_pickup : value.stations.pick_up[value.checked].code,
				location_code_return: value.stations === undefined ? value.selected_car.location_code_return : value.stations.return[value.checked_return].code,
				// location_code_pickup: selected_car.location_code_pickup ? selected_car.location_code_pickup : selected_car.station_prices[0].pickup_station,
				// location_code_return: selected_car.location_code_return ? selected_car.location_code_return : (selected_car.location_code_pickup ? selected_car.location_code_pickup : (selected_car.station_prices[0].return_station ? selected_car.station_prices[0].return_station : selected_car.station_prices[0].pickup_station)),
				country_code: country_code,
				start_destination: step.origin_id
		    },
		    success: function (contract) {
				if (contract.contracts.length > 0) {
					let price = selected_car.station_prices? selected_car.station_prices[0].prices[0].selling_price : selected_car.selling_price.selling_price;
					contract.contracts.map((item) => {
						if (item.prices[0].selling_price === price) {
							contract.selected = item.contract_id;
						}
					});
					dispatch(GetContract(contract));
				}
				else {
					dispatch(SetContractError("No contracts"));
				}
		    },
		    error: function (error) {
		    	console.log('error:', error);
		    	dispatch(SetContractError(error.detail));
		    }
		});
	}
}