export default (group_1, group_2) => {
    if (!Array.isArray(group_1) || ! Array.isArray(group_2) || group_1.length !== group_2.length)
        return false;
    let cpy_group_1 = group_1.concat().sort();
    let cpy_group_2 = group_2.concat().sort();
    for (let i = 0; i < cpy_group_1.length; i++) {
        if (cpy_group_1[i] !== cpy_group_2[i])
            return false;
    }
    return true;
};