const ShowParameters = () => {
    return {
        type: ""
    }
};

export function ToggleFilters(show_filters) {
    return {
        type: "ACCOMMODATION_TOGGLE_FILTERS",
        payload: {
            show_filters: show_filters
        }
    }
}

export function RemoveFromCartById(id) {
    return {
        type: "ACCOMMODATION_REMOVE_FROM_CART_BY_ID",
        payload: id
    }
}

export function ToggleRoomPictureModal(toggle_modal, pictures, index) {
    return {
        type: "ACCOMMODATION_TOGGLE_ROOM_PICTURE_MODAL",
        payload: {
            toggle_modal: toggle_modal,
            pictures: pictures,
            index: index
        }
    }
}

export function ToggleCalendarModal(modal_calendar, calendar_price) {
    return {
        type: "ACCOMMODATION_TOGGLE_CALENDAR_MODAL",
        payload: {
            modal_calendar: modal_calendar,
            calendar_price: calendar_price
        }
    }
}

export function EmptyFilters() {
    return {
        type: "ACCOMMODATION_EMPTY_FILTERS"
    }
}

export function ToggleFilter(filter) {
    return {
        type: "ACCOMMODATION_TOGGLE_FILTER",
        payload: {
            filter: filter
        }
    }
}

export function ToggleFavorite(show_favorite) {
    return {
        type: "ACCOMMODATION_TOGGLE_FAVORITE",
        payload: {
            show_favorite: show_favorite
        }
    }
}

export function ChangeGripType(grid_type) {
    return {
        type: "ACCOMMODATION_CHANGE_GRID_TYPE",
        payload: {
            grid_type: grid_type
        }
    }
}

export function ChangeActivePage(active_page) {
    return {
        type: "ACCOMMODATION_CHANGE_ACTIVE_PAGE",
        payload: {
            active_page: active_page
        }
    }
}

export function ToggleRoomComposition(show_room_composition) {
    return {
        type: "ACCOMMODATION_TOGGLE_ROOM_COMPOSITION",
        payload: {
            show_room_composition: show_room_composition
        }
    }
}

export function AccommodationUpdateList(new_accommodation, reset) {
    return {
        type: "ACCOMMODATION_UPDATE_LIST",
        payload: {
            new_accommodation: new_accommodation,
            reset: reset
        }
    }
}

export function AccommodationSetPrices(prices, duration_days, provider, provider_id, client_owner) {
    return {
        type: "ACCOMMODATION_SET_PRICES",
        payload: {
            prices: prices,
            duration_days: duration_days,
            provider: provider,
            provider_id: provider_id,
            client_owner: client_owner
        }
    }
}

export function AccommodationResetPrices() {
    return {
        type: "ACCOMMODATION_RESET_PRICES"
    }
}
/*
export function SetAccommodationList(list, request_url) {
    return {
        type: "ACCOMMODATION_SET_LIST",
        payload: {
            list: list,
            request_url: request_url
        }
    }
}
export function SetPrices(provider, list, owner, weight, preferred_lvl = null) {
    return {
        type: "ACCOMMODATION_SET_PRICES",
        payload: {
            provider: provider,
            list: list,
            owner: owner,
            weight: weight,
            preferred_lvl: preferred_lvl
        }
    }
}
*/
export function AddSeen(accommodation) {
    return {
        type: "ACCOMMODATION_ADD_SEEN",
        payload: {
            accommodation: accommodation
        }
    }
}

export function AddFavorite(accommodation) {
    return {
        type: "ACCOMMODATION_ADD_FAVORITE",
        payload: {
            accommodation: accommodation
        }
    }
}

export function RemoveFavorite(index) {
    return {
        type: "ACCOMMODATION_REMOVE_FAVORITE",
        payload: {
            index: index
        }
    }
}

export function SetCurrentDetails(details) {
    return {
        type: "ACCOMMODATION_SET_CURRENT_DETAILS",
        payload: {
            details: details
        }
    }
}

export function SetCurrentRooms(rooms) {
    return {
        type: "ACCOMMODATION_SET_CURRENT_ROOMS",
        payload: {
            rooms: rooms
        }
    }
}

export function SetTripadvisor(tripadvisor) {
    return {
        type: "ACCOMMODATION_SET_TRIPADVISOR",
        payload: {
            tripadvisor: tripadvisor
        }
    }
}

export function ToggleTripAdvisor(show_tripadvisor) {
    return {
        type: "ACCOMMODATION_TOGGLE_TRIPADVISOR",
        payload: {
            show_tripadvisor: show_tripadvisor
        }
    }
}

export function ToggleSorts(key) {
    return {
        type: "ACCOMMODATION_TOGGLE_SORTS",
        payload: {
            key: key
        }
    }
}

export function ToggleAccommodationFull() {
    return {
        type: "ACCOMMODATION_TOGGLE_FULL"
    }
}

export function ToggleAccommodationRecommended() {
    return {
        type: "ACCOMMODATION_TOGGLE_RECOMMENDED"
    }
}

export function ToggleShowSorts(show_sorts) {
    return {
        type: "ACCOMMODATION_TOGGLE_SHOW_SORTS",
        payload: {
            show_sorts: show_sorts
        }
    }
}

export function SetCart(cart) {
    return {
        type: "ACCOMMODATION_SET_CART",
        payload: {
            cart: cart
        }
    }
}

export function SetSortCart(sort_cart) {
    return {
        type: "ACCOMMODATION_SET_SORT_CART",
        payload: {
            sort_cart: sort_cart
        }
    }
}

export function AddToCart(room) {
    return {
        type: "ACCOMMODATION_ADD_TO_CART",
        payload: {
            room: room
        }
    }
}
export function EditAccommdationStatus(data) {
    return {
        type: "ACCOMMODATION_EDIT_STATUS",
        payload: {
            data: data
        }
    };
}
export function EditAccommodationCart(room, index) {
    return {
        type: "ACCOMMODATION_EDIT_CART",
        payload: {
            room: room,
            index: index
        }
    }
}
export function EditAccommodationCartById(room) {
    return {
        type: "ACCOMMODATION_EDIT_CART_BY_ID",
        payload: room
    }

}
export function EditAccommodationCartFromBDD(room, index) {
    return {
        type: "ACCOMMODATION_EDIT_CART_FROM_BDD",
        payload: {
            room: room,
            index: index
        }
    }
}

export function EditAccommodationCartMargin(edit, index) {
    return {
        type: "ACCOMMODATION_SET_MANUAL_MARGIN_EDIT",
        payload: {
            edit: edit,
            index: index
        }
    }
}

export function SetCurrentCart(cart) {
    return {
        type: "ACCOMMODATION_SET_CURRENT_CART",
        payload: {
            cart: cart
        }
    }
}

export function AddToCurrentCart(room, group, accommodation, current_provider) {
    let room_cart = Object.assign({}, room);
    room_cart.group = [group.id];
    room_cart.provider_id = current_provider;
    room_cart.hotel_identifier = [parseInt(accommodation)];
    return {
        type: "ACCOMMODATION_CURRENT_CART_ADD",
        payload: {
            room: room_cart
        }
    }
}

export function RemoveFromCartAtIndex(index) {
    return {
        type: "ACCOMMODATION_CURRENT_CART_REMOVE_AT_INDEX",
        payload: {
            index: index
        }
    }
}

export function RemoveFromCart(index) {
    return {
        type: "ACCOMMODATION_REMOVE_FROM_CART",
        payload: {
            index: index
        }
    }
}

export function SetRooms(rooms, provider, owner) {
    return {
        type: "ACCOMMODATION_SET_ROOMS",
        payload: {
            rooms: rooms,
            provider: provider,
            owner: owner,
        }
    }
}

export function SetCurrentGroup(groups) {
    return {
        type: "ACCOMMODATION_SET_GROUPS",
        payload: {
            groups: groups
        }
    }
}

export function SetSearch(search) {
    return {
        type: "ACCOMMODATION_SET_SEARCH",
        payload: {
            search: search
        }
    }
}

export function SeeNewCard(id) {
    return {
        type: "ACCOMMODATION_SEE_NEW_CARD",
        payload: {
            id: id
        }
    }
}

export function SetOver(id) {
    return {
        type: "ACCOMMODATION_SET_OVER",
        payload: {
            over: id
        }
    }
}

export function SetStartDate(start_date) {
    return {
        type: "ACCOMMODATION_SET_START_DATE",
        payload: {
            start_date: start_date
        }
    }
}

export function SetEndDate(end_date) {
    return {
        type: "ACCOMMODATION_SET_END_DATE",
        payload: {
            end_date: end_date
        }
    }
}

export function SetGroupsAndDates(groups, start_date, end_date) {
    return {
        type: "ACCOMMODATION_SET_GROUPS_AND_DATES",
        payload: {
            start_date: start_date,
            end_date: end_date,
            groups: groups
        }
    }
}

export function SetDates(start_date, end_date, force_request = false) {
    return {
        type: "ACCOMMODATION_SET_DATES",
        payload: {
            start_date: start_date,
            end_date: end_date,
            force_request: force_request
        }
    }
}

export function SetDetailedPrices(provider, list, owner, start_date, end_date, weight, preferred_lvl = null) {
    return {
        type: "ACCOMMODATION_SET_DETAILED_PRICES",
        payload: {
            provider: provider,
            list: list,
            owner: owner,
            start_date: start_date,
            end_date: end_date,
            weight: weight,
            preferred_lvl: preferred_lvl,
        }
    }
}

export function AddCatalogPrice(catalog_price) {
    return {
        type: "ACCOMMODATION_ADD_CATALOG_PRICE",
        payload: {
            catalog_price: catalog_price
        }
    }
}