const GetPictureBySize = (picture, size) => {
    if (picture !== undefined && picture !== null) {
        if (size === 'URL') {
            if (picture.url !== undefined && picture.url !== null && picture.url !== '')
                return picture.url;
            else
                return "/Img/hotel.png";
        } else if (size === "BIG") {
            if (picture.thumbnail_big !== undefined && picture.thumbnail_big !== null && picture.thumbnail_big !== '')
                return picture.thumbnail_big;
            else
                return GetPictureBySize(picture, "URL");
        } else if (size === "MEDIUM") {
            if (picture.thumbnail_medium !== undefined && picture.thumbnail_medium !== null && picture.thumbnail_medium !== '')
                return picture.thumbnail_medium;
            else
                return GetPictureBySize(picture, "BIG");
        } else if (size === "LITTLE") {
            if (picture.thumbnail_little !== undefined && picture.thumbnail_little !== null && picture.thumbnail_little !== '')
                return picture.thumbnail_little;
            else
                // return "/Img/hotel.png";
                return GetPictureBySize(picture, "MEDIUM");
        }
    } else
        return "/Img/hotel.png";
};

export default GetPictureBySize;