//---- Commons ----//
import axios from "axios";

//---- Functions ----//
import GetUserList from "./GetUserList.js";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest.js";

export default (user, t, dispatch, client, enqueueSnackbar, handleDialogClose) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "DELETE",
            headers: headers,
            url: `${API_HREF}client/${client}/user/${user.id}/`,
        }).then(function (response) {
            enqueueSnackbar(t("menu.user_management.success_user_deletion"), { variant: "success" });
            handleDialogClose();
            GetUserList(dispatch, client);
            
        }).catch(function (error) {
            //TODO: show snackbar error
            let data = Object.assign({}, error.response.data);
            Object.keys(data).map((field) => {
                data[field].map((errorDetail) => {
                    enqueueSnackbar(errorDetail, { variant: "error" });
                })
            })
        });
    }
}
