import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { WithRouterProps } from "react-router";
import GetTrip from "../Base/Functions/GetTrip";
import {
    Box,
    Button,
    Grid,
    Paper,
    Stack,
    Typography,
    styled
} from "@mui/material";
import { ArrowRightAlt, ShoppingCart } from "@mui/icons-material";
import { ProviderBookingNavbar } from "./ProviderBookingNavbar";
import { ProviderBookingProduct } from "./ProviderBookingProduct";
import { ProviderBookingContinueModal } from "./ProviderBookingContinueModal";
import { ProviderBookingContext } from "./utils/providerBookingContext";
import { useProviderBookingProducts } from "./utils/providerBookingProducts";
import { AppState } from "../../Reducers/Reducers";

type Props = WithRouterProps

export default function ProviderBooking(props: Props): JSX.Element {
    const providerId = props.params.providerId;
    const tripId = props.params.tripId;
    const tripVersion = props.params.tripVersion;
    const tripToken = props.params.tripToken;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const travelers = useSelector((state: AppState) => state.trip.travelers);
    const trip = useSelector((state: AppState) => state.trip.all_data);
    const [openContinueModal, setOpenContinueModal] = useState(false);
    const products = useProviderBookingProducts(parseInt(providerId ?? '-1'));

    useEffect(() => {
        if (providerId && tripId && tripVersion && tripToken) {
            GetTrip(
                null,
                dispatch,
                undefined,
                undefined,
                {
                    tripId,
                    tripVersion,
                    tripToken
                }
            );
        }
    }, [
        providerId,
        tripId,
        tripToken,
        tripVersion
    ]);

    return (
        <ProviderBookingContext.Provider
            value={{
                tripId: tripId ? parseInt(tripId) : null,
                version: tripVersion ? parseInt(tripVersion) : null,
                providerId: providerId ? parseInt(providerId) : null,
                tripToken: tripToken ?? null
            }}
        >
            <Page>
                <ProviderBookingNavbar {...props} />
                <Box sx={{ padding: 2.5 }}>
                    <Typography
                        fontWeight={100}
                        textAlign="center"
                        maxWidth="50%"
                        sx={{ margin: 'auto' }}
                    >
                        <div>
                            <Trans
                                i18nKey="cart-material.provider-booking-title"
                                values={{
                                    name: trip?.user_to ?
                                        [
                                            trip.user_to.first_name,
                                            trip.user_to.last_name
                                        ].filter((item) => item).join(' ') :
                                        trip?.owner.name
                                }}
                                components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                            />
                        </div>
                        <div>
                            <Trans
                                i18nKey="cart-material.provider-booking-hint"
                                components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                            />
                        </div>
                    </Typography>
                    <Grid container spacing={5} sx={{ marginTop: 2.5 }}>
                        <Grid item xs={6}>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                                sx={{ marginBottom: 1.5 }}
                            >
                                <ShoppingCart color="primary" />
                                <Typography variant="h6" color="primary" component="div">
                                    {t('cart-material.provider-booking-your-products')}
                                </Typography>
                            </Stack>
                            <Stack
                                spacing={2.5}
                                sx={{
                                    padding: 3,
                                    paddingLeft: 4,
                                    paddingRight: 4
                                }}
                            >
                                {
                                    products.map((product) => {
                                        return (
                                            <ProviderBookingProduct
                                                key={`${product.type}-${product.id}`}
                                                {...product}
                                            />
                                        );
                                    })
                                }
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Box
                                sx={{
                                    position: 'sticky',
                                    top: '36px'
                                }}
                            >
                                <Paper
                                    elevation={1}
                                    sx={(theme) => ({
                                        paddingTop: 2.5,
                                        paddingBottom: 3.5,
                                        paddingLeft: 3.5,
                                        paddingRight: 3.5,
                                        borderRadius: theme.shape.borderRadius,
                                        marginBottom: 2.5
                                    })}
                                >
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        component="div"
                                        sx={{ marginBottom: 1 }}
                                    >
                                        {t('cart-material.provider-booking-travelers')}
                                    </Typography>
                                    {
                                        travelers?.map((traveler, index) => {
                                            const birthDay = window.moment.utc(traveler.birth_date, 'YYYY-MM-DD');
                                            const name = [
                                                traveler.first_name,
                                                traveler.last_name
                                            ].filter((item): item is NonNullable<typeof item> => {
                                                return !!item;
                                            }).join(' ');
                                            return (
                                                <Typography key={index}>
                                                    {
                                                        t(
                                                            'cart-material.provider-booking-traveler',
                                                            {
                                                                title: traveler.title,
                                                                name,
                                                                birthDay: birthDay.format('L'),
                                                                age: window.moment.utc().diff(birthDay, 'years')
                                                            }
                                                        )
                                                    }
                                                </Typography>
                                            );
                                        })
                                    }
                                </Paper>
                                <Button
                                    endIcon={<ArrowRightAlt />}
                                    onClick={() => setOpenContinueModal(true)}
                                >
                                    {t('cart-material.provider-quotation-confirm')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Page>
            <ProviderBookingContinueModal
                open={openContinueModal}
                providerId={parseInt(providerId ?? '-1')}
                version={parseInt(tripVersion ?? '-1')}
                onClose={() => setOpenContinueModal(false)}
            />
        </ProviderBookingContext.Provider>
    );
}

const Page = styled('div')(() => ({
    backgroundColor: '#F8F8F8',
    minHeight: '100vh'
}));
