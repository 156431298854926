import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Paper } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { TripListFolderIcon } from "./TripListFolderIcon";
import { TripListSendIcon } from "./TripListSendIcon";
import { TripListEditIcon } from "./TripListEditIcon";

export function TripListInvoiceSidePanel(props) {
    const { t } = useTranslation();
    return (
        <SidePanel elevation={6}>
            <SidePanelTitle variant="h4" component="div">
                {t('menu.invoice.history')}
            </SidePanelTitle>
            <SidePanelItem className="item">
                <SidePanelIcon className="icon">
                    <TripListFolderIcon fontSize="small" />
                </SidePanelIcon>
                <Typography color="textSecondary" variant="body2" component="div">
                    {t('menu.invoice.created-on')} {
                        props.invoice ?
                            window.moment.utc(props.invoice.created_date).format('DD/MM/YYYY') :
                            window.moment.utc(new Date()).format('DD/MM/YYYY')
                    }
                </Typography>
            </SidePanelItem>
            <SidePanelItem className="item">
                <SidePanelIcon className="icon">
                    <TripListEditIcon fontSize="small" />
                </SidePanelIcon>
                <Typography color="textSecondary" variant="body2" component="div">
                    {t('menu.invoice.modified-on')} {
                        props.invoice ?
                            window.moment.utc(props.invoice.modified_date).format('DD/MM/YYYY') :
                            window.moment.utc(new Date()).format('DD/MM/YYYY')
                    }
                </Typography>
            </SidePanelItem>
            {
                props.invoice && props.invoice.accounting_date &&
                <SidePanelItem className="item">
                    <SidePanelIcon className="icon">
                        <TripListSendIcon fontSize="small" />
                    </SidePanelIcon>
                    <Typography color="textSecondary" variant="body2" component="div">
                        {
                            t(
                                'menu.invoice.sent-on',
                                { date: window.moment.utc(props.invoice.accounting_date).format('DD/MM/YYYY') }
                            )
                        }
                    </Typography>
                </SidePanelItem>
            }
        </SidePanel>
    );
}


const SidePanel = styled(Paper)(({ theme }) => ({
    "borderRadius": 20,
    "padding": theme.spacing(3),
    "marginBottom": theme.spacing(2),
    '& .item:not(:last-child)': {
        marginBottom: theme.spacing(3)
    },
    '& .item:not(:last-child) .icon:after': {
        content: '""',
        backgroundColor: '#E0E0E0',
        position: 'absolute',
        bottom: theme.spacing(-3),
        height: theme.spacing(3),
        width: 2,
        left: '50%',
        transform: 'translateX(-50%)'
    }
}));

const SidePanelTitle = styled(Typography)(({ theme }) => ({
    color: '#E6592F',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 700,
    marginBottom: theme.spacing(2)
}));

const SidePanelItem = styled('div')(() => ({
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold'
}));

const SidePanelIcon = styled('div')(({ theme }) => ({
    display: 'flex',
    backgroundColor: '#D9D9D9',
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    position: 'relative'
}));
