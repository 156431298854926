import { flatten, flattenDeep } from "lodash";
import { TFunction } from "i18next";
import { useCartProducts } from "../../../Itinerary/network/cartProducts";
import { Flight } from "../../../Itinerary/network/flight";

export function transformFlightsToProgramDetailsItems(
    options: {
        cart: ReturnType<typeof useCartProducts>,
        startDate: string,
        endDate: string,
        prevEndDate: string | undefined,
        nextStartDate: string | undefined,
        t: TFunction
    }
) {
    const startDate = window.moment.utc(options.startDate).startOf('day');
    const endDate = window.moment.utc(options.endDate).startOf('day');
    const flights = flattenDeep(
        options.cart.flights.map((item) => {
            if (item.type === 'normal') {
                return item.flight.outbounds.map((outbound) => {
                    return outbound.legs.map((leg) => ({
                        ...leg,
                        note: item.flight.custom_information?.replace(/\n/g, '<br />') ?? undefined
                    }))
                });
            }
            return item.flight.flight_segment.map((segment) => ({
                origin: {
                    international_name: segment.origin_airport?.international_name ??
                        segment.origin_station?.international_name ??
                        '',
                    iata_city: segment.origin_airport?.iata_city ??
                        segment.origin_station?.iata_city ??
                        {
                            id: -1,
                            city_code: '(UNKNOWN)',
                            iata_country: {} as any,
                            international_name: 'UNKNOWN',
                            name: 'UNKNOWN',
                            tsv: null
                        }
                },
                destination: {
                    international_name: segment.destination_airport?.international_name ??
                        segment.destination_station?.international_name ??
                        '',
                    iata_city: segment.destination_airport?.iata_city ??
                        segment.destination_station?.iata_city ??
                        {
                            id: -1,
                            city_code: '(UNKNOWN)',
                            iata_country: {} as any,
                            international_name: 'UNKNOWN',
                            name: 'UNKNOWN',
                            tsv: null
                        }
                },
                operating_airline: {
                    airline_code: segment.airline.airline_code ?? '',
                    commercial_name: segment.airline.commercial_name
                },
                departure_time: segment.start_date,
                arrival_time: segment.end_date,
                origin_terminal: segment.origin_terminal ?? '',
                destination_terminal: segment.destination_terminal ?? '',
                flight_number: segment.flight_number.toString() ?? '',
                note: item.flight.custom_information?.replace(/\n/g, '<br />') ?? undefined
            }));
        })
    ) as (Flight['outbounds'][number]['legs'][number] & {note: string | undefined})[]
    const correspondingFlights = flights.filter((flight) => {
        if (
            !options.prevEndDate &&
            window.moment.utc(flight.arrival_time).isSameOrBefore(
                window.moment.utc(options.startDate)
            )
        ) {
            return true;
        }

        if (
            !options.nextStartDate &&
            window.moment.utc(flight.departure_time).isSameOrAfter(
                window.moment.utc(options.endDate)
            )
        ) {
            return true;
        }

        return window.moment.utc(flight.arrival_time).isBetween(
            window.moment.utc(options.prevEndDate),
            window.moment.utc(options.startDate),
            'minutes',
            '[]'
        ) ||
        window.moment.utc(flight.departure_time).isBetween(
            window.moment.utc(options.endDate),
            window.moment.utc(options.nextStartDate),
            'minutes',
            '[]'
        ) ||
        window.moment.utc(flight.departure_time).isBetween(
            window.moment.utc(options.startDate),
            window.moment.utc(options.endDate),
            'minutes',
            '[]'
        ) ||
        window.moment.utc(flight.arrival_time).isBetween(
            window.moment.utc(options.startDate),
            window.moment.utc(options.endDate),
            'minutes',
            '[]'
        );
    });
    return flatten(
        correspondingFlights.map((flight) => {
            const departure = window.moment.utc(flight.departure_time);
            const arrival = window.moment.utc(flight.arrival_time);
            const result = [
                departure.clone().startOf('day').isSame(
                    endDate,
                    'days'
                ) ?
                    {
                        dateOrder: flight.departure_time,
                        date: window.moment.utc(flight.departure_time).format('DD/MM'),
                        title: options.t(
                            'roadbook.program-details-flight-departure-title',
                            {
                                hourly: window.moment.utc(flight.departure_time).format('HH[h]mm'),
                                place: flight.origin?.iata_city.name ??
                                       flight.origin?.iata_city.international_name ??
                                       flight.origin_station?.iata_city?.name ??
                                       flight.origin_station?.iata_city?.international_name
                            }
                        ),
                        place: flight.origin?.international_name ?? flight.origin_station?.international_name,
                        note: flight.note?.replace(/\n/g, '<br />') ?? undefined
                    } :
                    null,
                arrival.clone().startOf('day').isSame(
                    startDate,
                    'days'
                ) ?
                    {
                        dateOrder: flight.arrival_time,
                        date: window.moment.utc(flight.arrival_time).format('DD/MM'),
                        title: options.t(
                            'roadbook.program-details-flight-arrival-title',
                            {
                                hourly: window.moment.utc(flight.arrival_time).format('HH[h]mm'),
                                place: flight.destination?.iata_city.name ??
                                       flight.destination?.iata_city.international_name ??
                                       flight.destination_station?.iata_city?.name ??
                                       flight.destination_station?.iata_city?.international_name
                            }
                        ),
                        place: flight.destination?.international_name ??
                               flight.destination_station?.international_name,
                        note: flight.note?.replace(/\n/g, '<br />') ?? undefined
                    } :
                    null
            ];
            //eslint-disable-next-line max-nested-callbacks
            return result.filter((item): item is NonNullable<typeof result[0]> => {
                return !!item;
            });
        })
    );
}
