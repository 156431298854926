//---- Dependencies ----//
import React, {Fragment, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';

import i18n from "../../i18n.jsx";
import GetCookie from "../Common/Functions/GetCookie";
import IsIE from "../Common/Functions/IsIE.js";

import CustomHomeSlider from "./CustomHomeSlider.jsx";
import HomeSlider from "./HomeSlider.jsx";
import CheckBeforeRequest from "../Common/CheckBeforeRequest.js";
import MaterialHeader from "../Header/MaterialHeader.jsx";
import { SetDestinations } from "../../Actions/Home.js";

//---- CSS ----//
import "../../Style/Home.css";
import "../../Style/Global.css";
import "../../Style/GlobalAgencies.css";
import MaterialLogin from "./MaterialLogin.js";
import DestinationDialog from "./DestinationDialog.js";
import clsx from "clsx";
import Footer from "../Footer/Footer.jsx";
import { green } from "@material-ui/core/colors";
import MaterialDestCard from "./MaterialDestCard.js";
import Help from "../Base/Help";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
    absoluteCenter: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    customButton: {
        background: `${main_color}`,
        // padding: "15px 40px",
        textAlign: "center",
        color: "#fff",
        fontSize: 20,
        textTransform: "none",
        position: "relative",
        "&:hover": {
            background: `${main_color}CA`
        },
        "&&.mui-jss-MuiButton-contained.mui-jss-Mui-disabled": {
            backgroundColor: `${main_color}`
        }
    },
    customOutlinedButton: {
        color: `${main_color}`,
        borderColor: `${main_color}`,
        "&:hover": {
            borderColor: `${main_color}`,
        }
    },
    fullWidth: {
        width: "100%"
    },
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "49%",
        // marginTop: -20,
        // marginLeft: -12,
    },
}));

const MaterialHome = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const is_connected = useSelector(store => store.user.is_connected);
    const current_dest = useSelector(store => store.home.destinations);
    const destinations = useSelector(store => store.home.destinations);
    const modal = useSelector(store => store.home.modal);

    const config = JSON.parse(localStorage.getItem("config"));
    const quotation_code = config.quotation_code;
    const [isIE, setIsIE] = useState(false);
    const [homepage_img, setHomepageImg] = useState([]);
    const [has_custom_homepage, setHasCustomHompage] = useState(false);
    const [full_dest, setFullDest] = useState([]);
    const [favicon] = useState(config !== null && config.favicon !== undefined && config.favicon !== null && config.favicon.url !== undefined && config.favicon.url !== null ? config.favicon : null);
    const [logo] = useState(config !== null && config.logo !== undefined && config.logo !== null && config.logo.url !== undefined && config.logo.url !== null ? config.logo : null);
    const [loginDialog, setLoginDialog] = useState(false);
    const [destinationDialog, setDestinationDialog] = useState(false);
    const [id, setId] = useState(null);
    const [token, setToken] = useState(null);

    useEffect(() => {
        let { pass_check, headers } = CheckBeforeRequest();
        setIsIE(IsIE());
        if (props.location.query.token !== undefined && props.location.query.token !== null && props.location.query.id !== undefined && props.location.query.id !== null) {
            setToken(props.location.query.token);
            setId(props.location.query.id);
            setLoginDialog(true);
        }
        if (modal) {
            setDestinationDialog(true);
            dispatch({type: "HOME_TOGGLE_MODAL", payload: {modal: false, step: 1}});
        }
        dispatch({type: "TRIP_SET_ALL_DATA", payload: {data: null}});
        dispatch({type: "TRIP_SET_DATA_TRIP", payload: {data_trip: null}});
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/pictures/?type=HOMEPAGE`,
        }).then(function (response) {
            if (response.data.results.length > 0) {
                setHomepageImg(response.data.results);
                setHasCustomHompage(true);
            }
        }).catch(function (error) {
            console.log('pictures error:', error);
        });
        if (['visiteurs'].includes(quotation_code)) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/destinations/?limit=1000&ordering=-current_version__note&visibility__in=PUBLIC,PRIVATE_TO&homepage=true`,
            }).then(function (response) {
                console.log('data:', response);
                let tmp_arr = [];
                for (let i = 0; i < 9; i++) {
                    if (response.data.results[i] !== undefined) {
                        tmp_arr.push(response.data.results[i]);
                    }
                }
                dispatch(SetDestinations(tmp_arr));
                setFullDest([...response.data.results]);
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('destination error:', error);
            });
        }
    }, []);
    useEffect(() => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (GetCookie("token") !== null) {
            if (pass_check) {
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/destinations/?limit=1000&ordering=-current_version__note&visibility__in=PUBLIC,PRIVATE_TO&homepage=true`,
                }).then(function (response) {
                    console.log('data:', response);
                    let tmp_arr = [];
                    for (let i = 0; i < 9; i++) {
                        if (response.data.results[i] !== undefined) {
                            tmp_arr.push(response.data.results[i]);
                        }
                    }
                    dispatch(SetDestinations(tmp_arr));
                    setFullDest([...response.data.results]);
                }).catch(function (error) {
                    //TODO: show snackbar error
                    console.log('destination error:', error);
                });
            }
        }
    }, [is_connected])
    const handleCreateButton = () => {
        if (!['visiteurs'].includes(quotation_code) && !is_connected) {
            setLoginDialog(true);
        }
        else {
            setDestinationDialog(true);
        }
    }
    const loadMoreDest = () => {
		let tmp_arr = [...current_dest];
		if (current_dest.length < full_dest.length) {
			let max = 0;
			if (full_dest.length - current_dest.length >= 6) {
				max = current_dest.length + 6;
			} else {
				max = current_dest.length + (full_dest.length - current_dest.length);
			}
			for (let i = current_dest.length - 1; i < max; i++) {
				tmp_arr.push(full_dest[i]);
			}
			dispatch(SetDestinations(tmp_arr));
		}
	}
    console.log('destination:', destinations);
    return (
        <Grid container style={{display: "block"}}>
            <Grid item id={"home"} className={"wrapper"}>
            {
                has_custom_homepage === true && (
                    <CustomHomeSlider pictures={homepage_img} />
                )
            }
            {
                has_custom_homepage === false && (
                    <HomeSlider />
                )
            }
            </Grid>
            <Grid item className={"header-text-container"} id={"home-picture"} style={{display: "block"}}>
                <Grid container direction={"row"} justify={"flex-end"}>
                    <Grid item className={"home-nav"} id={"home-nav"} style={{top: 0}}>
                    {
						!is_connected  && (
                            <Fragment>
                                <Grid item style={{position: "absolute", left: 16, top: 20}}>
                                    <Link to={ `/home/${window.url_name}/` } id={ "header-home-link" }>
                                        <img src={ logo !== null ? (logo.thumbnail_little !== null ? logo.thumbnail_little : logo.url) : "/Img/inner_favicon_header.png" } height={ 30 } alt={ "" }/>
                                    </Link>
                                </Grid>
                                <Grid item>
                                {
                                    !isIE && (
                                        <Button style={{color: "white"}} onClick={() => setLoginDialog(true)}>{t("home.nav.login")}</Button>
                                    )
                                }
                                </Grid>
                            </Fragment>
						)
					}
                    {
                        is_connected && (
                            <MaterialHeader router={props.router}/>
                        )
                    }
                    </Grid>
                </Grid>
                {
                    !isIE && (
                        <Grid container justify={"center"} alignItems={"center"} alignContent={ "center" } style={{height: '100%'}}>
                            {
                                config !== null && config.quotation_code === 'visiteurs' &&
                                <Grid item xs={ 12 }>
                                    <img src={ "/Img/Home/plan_trip.png" } width={ '30%' } alt={ "" } style={{marginLeft: '10%'}}/>
                                </Grid>
                            }
                            <Grid item>
                                <Button variant={"contained"} fullWidth={fullScreen} disabled={is_connected && destinations.length === 0} className={classes.customButton} onClick={handleCreateButton} >{t("home.create_trip_btn")}</Button>
                                {
                                    is_connected && destinations.length === 0 && (
                                        <CircularProgress className={classes.buttonProgress}/>
                                    )
                                }
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
            {
                (is_connected || ['visiteurs'].includes(quotation_code))  && (
                    <Grid item style={{paddingBottom: 70}}>
                        <Container className={classes.container}>
                            <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={4}>
                                <Grid item className={classes.genericText}>
                                    <Typography variant={"h4"}>{t("home.destinations")}</Typography>
                                </Grid>
                                <Grid item style={{width: "100%"}}>
                                    <Grid container direction={"row"} spacing={2} justify={"center"}>
                                    {
                                        destinations.map((destination, destination_index) => {
                                            return (
                                                <MaterialDestCard key={destination_index} destination={destination} router={props.router} type={"home"}/>
                                            )
                                        })
                                    }
                                    </Grid>
                                </Grid>
                                {
                                    current_dest.length < full_dest.length && (
                                        <Grid item>
                                            <Button variant={"outlined"} fullWidth={fullScreen} color={"primary"} className={classes.customOutlinedButton} onClick={loadMoreDest}>{t("home.load_more_dest")}</Button>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Container>
                    </Grid>
                )
            }
            <MaterialLogin loginDialog={loginDialog} setLoginDialog={setLoginDialog} logo={logo} router={props.router} token={token} id={id}/>
            <DestinationDialog router={props.router} destinationDialog={destinationDialog} setDestinationDialog={setDestinationDialog} full_dest={full_dest}/>
            { JSON.parse(localStorage.getItem("config")).quotation_code === 'visiteurs' && <Help/> }
            <Footer/>
        </Grid>
    )
}
export default MaterialHome