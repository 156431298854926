export default (type, itinerary_list, search, elem_index, t) => {
	if (type === "departure" && search[elem_index].origin_dest !== null) {
		let active = itinerary_list.filter((itinerary) => {
			if (itinerary.destination !== null && itinerary.destination !== undefined) {
				return itinerary.destination.data.name === (search[elem_index].origin_dest.data !== undefined ? search[elem_index].origin_dest.data.name : search[elem_index].origin_dest.name)
			}
		});
		if (active.length > 0) {
			let start_hour = moment.utc(active[0].end_date).format("HH:mm");
			if (start_hour === "12h00") {
				return t("global.noon");
			}
			else if (start_hour === "00h00") {
				return t("global.midnight");
			}
			else {
				let split_hour = start_hour.split(":");
				if (parseInt(split_hour[1]) <= 30) {
				    split_hour[1] = "30";
				}
				else if (parseInt(split_hour[1]) <= 60) {
				    split_hour[0] = (parseInt(split_hour[0]) + 1).toString();
				    split_hour[1] = "00";
				}
				let new_start_hour = (parseInt(split_hour[0]) + 1 < 10 ? "0" : "") + (parseInt(split_hour[0]) + 1).toString() + "h" + split_hour[1];
				if (new_start_hour === "12h00") {
  					return t("global.noon");
				}
				else if (new_start_hour === "00h00") {
  					return t("global.midnight");
				}
				else {
  					return new_start_hour;
				}
			}
		}
		else {
			return t("global.noon");
		}
	}
	else if (type !== "departure" && search[elem_index].return_dest !== null) {
		let active = itinerary_list.filter((itinerary) => {
			if (itinerary.destination !== null && itinerary.destination !== undefined) {
				return itinerary.destination.data.name === search[elem_index].return_dest.data.name
			}
		});
		if (active.length > 0) {
			let return_hours = moment.utc(active[0].start_date).format("HH:mm");
			if (return_hours === "12h00") {
				return t("global.noon");
			}
			else if (return_hours === "00h00") {
				return t("global.midnight");
			}
			else {
				let split_hour = return_hours.split(":");
				if (parseInt(split_hour[1]) <= 30) {
				    split_hour[1] = "30";
				}
				else if (parseInt(split_hour[1]) <= 60) {
				    split_hour[0] = (parseInt(split_hour[0]) + 1).toString();
				    split_hour[1] = "00";
				}
				let new_return_hour = (parseInt(split_hour[0]) - 3 < 10 ? "0" : "") + (parseInt(split_hour[0]) - 3).toString() + "h" + split_hour[1];
				if (new_return_hour === "12h00") {
  					return t("global.noon");
				}
				else if (new_return_hour === "00h00") {
  					return t("global.midnight");
				}
				else {
  					return new_return_hour;
				}
			}
		}
		else {
			return t("global.noon");
		}
	}
}