import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from "@material-ui/core/IconButton";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FlightIcon from '@material-ui/icons/Flight';
import CloseIcon from '@material-ui/icons/Close';

import Transition from "../../../Common/Transition";

import { ToggleAirline, ShowAllAirlines, ToggleAlliance } from "../../../../Actions/FlightSearch";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    verticalSpacer: {
        padding: "8px 0px"
    },
    horizontalSpacer: {
        padding: "0px 16px"
    },
    filterButton: {
        border: "1.03872px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: 31.1617,
        padding: "8px 12px"
    },
    closeMenu: {
        marginRight: 5
    }
}))

const AirlinesButton = (({ }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const airlines = useSelector(store => store.flight_search.airlines);
    const alliances = useSelector(store => store.flight_search.alliances);
    const show_all_airlines = useSelector(store => store.flight_search.show_all_airlines);
    const trip_currency = useSelector(store => store.trip.currency);

    const [openAirlines, setOpenAirlines] = useState(null);

    return (
        <Grid item>
            <Button size="small" className={classes.filterButton} onClick={(event) => setOpenAirlines(event.currentTarget)}>
                <FlightIcon className={classes.genericText} />
                <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`} style={{ paddingLeft: 8, textTransform: "capitalize" }}>
                    { t("filters.companies") }
                </div>
            </Button>
            {
                isHandHeld && (
                    <Dialog open={Boolean(openAirlines)} fullScreen TransitionComponent={Transition}>
                        <DialogTitle>
                            <Grid container alignItems="center" justify="space-between" className={classes.horizontalSpacer}>
                                <Grid item xs={4} />
                                <Grid item xs={4} className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                    { t("filters.companies") }
                                </Grid>
                                <Grid item xs={4} className={classes.textRight}>
                                    <IconButton size="small" className={classes.closeMenu} onClick={() => setOpenAirlines(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ padding: "0px 18px 0px 8px" }}>
                            <Grid container alignItems="center" justify="space-between" className={classes.horizontalSpacer}>
                                <Grid item>
                                    <FormControlLabel 
                                        control={<Checkbox sx={{
                                            '&.Mui-checked': {
                                                color: `${main_color}`,
                                            },
                                        }} checked={show_all_airlines} onChange={() => dispatch(ShowAllAirlines())} name="airlines_null" size="small" />}
                                        label={t("flight_search.filter_null")}
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                </Grid>
                                <Grid item className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`} style={{ marginBottom: -20 }}>
                                    { t("flight_search.price_from") }
                                </Grid>
                            </Grid>
                            {
                                airlines !== null && airlines.map((company) => {
                                    if (company.price !== 0 && company.displayed !== null){
                                        return(
                                            <Grid key={company.code} container alignItems="center" justify="space-between">
                                                <Grid item>
                                                    <FormControlLabel
                                                        key={company.code}
                                                        control={<Checkbox sx={{
                                                            '&.Mui-checked': {
                                                                color: `${main_color}`,
                                                            },
                                                        }} checked={company.displayed} onChange={() => dispatch(ToggleAirline(company.id))} name={company.name} size="small" />}
                                                        label={company.name}
                                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                                    />
                                                </Grid>
                                                <Grid item className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`} style={{ marginBottom: ".5rem"}}>
                                                    { company.price.toFixed(0).replace(".", ",") + " " + trip_currency.symbol }
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                })
                            }
                            <Divider />
                            <div className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                { t("filters.alliances") }
                            </div>
                            <Divider />
                            {
                                alliances !== null && alliances.map((alliance, alliance_index) => {
                                    if (alliance.displayed !== null){
                                        return(
                                            <FormControlLabel 
                                                key={alliance_index}
                                                control={<Checkbox sx={{
                                                    '&.Mui-checked': {
                                                        color: `${main_color}`,
                                                    },
                                                }} checked={alliance.displayed} onChange={() => dispatch(ToggleAlliance(alliance_index))} name={`${alliance.name}`} size="small" />}
                                                label={alliance.name}
                                                classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                            />
                                        )
                                    }
                                    })
                            }
                        </DialogContent>
                    </Dialog>
                )
            }
            {
                !isHandHeld && (
                    <Menu 
                        id="airlines-menu"
                        anchorEl={openAirlines}
                        keepMounted
                        open={Boolean(openAirlines)}
                        onClose={() => setOpenAirlines(false)}
                        disableScrollLock={true}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        >
                        <Grid container alignItems="center" justify="space-between" style={{ width: 500 }} className={classes.horizontalSpacer}>
                            <Grid item xs={4} />
                            <Grid item xs={4} className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                { t("filters.companies") }
                            </Grid>
                            <Grid item xs={4} className={classes.textRight}>
                                <IconButton size="small" className={classes.closeMenu} onClick={() => setOpenAirlines(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider />
                        <FormGroup className={classes.horizontalSpacer}>
                            <Grid container alignItems="center" justify="space-between" className={classes.horizontalSpacer}>
                                <Grid item>
                                    <FormControlLabel 
                                        control={<Checkbox sx={{
                                            '&.Mui-checked': {
                                                color: `${main_color}`,
                                            },
                                        }} checked={show_all_airlines} onChange={() => dispatch(ShowAllAirlines())} name="airlines_null" size="small" />}
                                        label={t("flight_search.filter_null")}
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                </Grid>
                                <Grid item className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`} style={{ marginBottom: -20 }}>
                                    { t("flight_search.price_from") }
                                </Grid>
                            </Grid>
                            {
                                airlines !== null && airlines.map((company) => {
                                    if (company.price !== 0 && company.displayed !== null){
                                        return(
                                            <Grid key={company.code} container alignItems="center" justify="space-between">
                                                <Grid item>
                                                    <FormControlLabel
                                                        key={company.code}
                                                        control={<Checkbox sx={{
                                                            '&.Mui-checked': {
                                                                color: `${main_color}`,
                                                            },
                                                        }} checked={company.displayed} onChange={() => dispatch(ToggleAirline(company.id))} name={company.name} size="small" />}
                                                        label={company.name}
                                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                                    />
                                                </Grid>
                                                <Grid item className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`} style={{ marginBottom: ".5rem"}}>
                                                    { company.price.toFixed(0).replace(".", ",") + " " + trip_currency.symbol }
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                })
                            }
                            <Divider />
                            <div className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                { t("filters.alliances") }
                            </div>
                            <Divider />
                            <FormGroup className={classes.horizontalSpacer}>
                                {
                                alliances !== null && alliances.map((alliance, alliance_index) => {
                                    if (alliance.displayed !== null){
                                        return(
                                            <FormControlLabel 
                                                key={alliance_index}
                                                control={<Checkbox sx={{
                                                    '&.Mui-checked': {
                                                        color: `${main_color}`,
                                                    },
                                                }} checked={alliance.displayed} onChange={() => dispatch(ToggleAlliance(alliance_index))} name={`${alliance.name}`} size="small" />}
                                                label={alliance.name}
                                                classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                            />
                                        )
                                    }
                                    })
                                }
                            </FormGroup>
                        </FormGroup>
                    </Menu>
                )
            }
        </Grid>
    )
})

export default React.memo(AirlinesButton)