//Dependencies
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
//Core
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@mui/material';

//Components
import { CartCarCard } from "./CartCarCard";
import { CartManualProductCard } from "./CartManualProductCard";
import { useUnpackagedProducts } from './utils/unpackagedProduts';

const useStyles = makeStyles({
    title: {
        fontSize: 24,
        fontWeight: 700
    },
    carContainer: {
        //marginTop: 50
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    marginBottom: {
        marginBottom: 15
    }
});

const CartCars = () => {
    const classes = useStyles();
    const unpackagedProducts = useUnpackagedProducts();

    if (unpackagedProducts.cars.length > 0) {
        return (
            <div>
                <TitleWrapper />
                <hr/>
                {
                    unpackagedProducts.cars.map((item, car_index) => {
                        if (item.type === 'manual') {
                            return (
                                <Grid key={ `manual-product-${item.car.id}` } id={ `manual-product-${item.car.id}` }>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            marginBottom: 3
                                        }}
                                    >
                                        <CartManualProductCard product={item.car} />
                                    </Box>
                                </Grid>
                            );
                        }
                        return (
                            <Grid className={ classes.carContainer } key={ `accommodation-${item.car.id}` } container>
                                <Box
                                    sx={{
                                        width: '100%',
                                        marginBottom: 3
                                    }}
                                >
                                    <CartCarCard index={car_index} car={item.car} />
                                </Box>
                            </Grid>
                        );
                    })
                }
            </div>
        );
    }
    return null;
};

function TitleWrapper() {
    const classes = useStyles();
    const { t } = useTranslation();
    const language = useSelector(state => state.header.tmp_language);
    const total_cars = useSelector(state => state.cart.total_cars);
    const unpackagedProducts = useUnpackagedProducts();

    return (
        <Typography className={ classes.title }>
            { t('cart-material.total-car') } ({unpackagedProducts.cars.length}) :{' '}
            { total_cars.map((currency_cost, currency_index) => `${currency_index > 0 ? ' + ' : ''}${currency_cost.cost.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}${currency_cost.currency.symbol}`) }
        </Typography>
    );
}

export default CartCars;
