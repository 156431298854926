//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';

import RoomIcon from '@material-ui/icons/Room';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import PeopleIcon from '@material-ui/icons/People';
import WorkIcon from '@material-ui/icons/Work';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
	italic: {
		fontStyle: "italic !important"
	},
	font14: {
		fontSize: 14
	},
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    cover: {
    	display: "block",
	    width: "100%",
	    height: "100%"
    },
    content: {
    	display: 'flex',
    	alignItems: "center",
    	paddingLeft: theme.spacing(1),
    	paddingBottom: theme.spacing(1),
    },
    pictureContainer: {
	    overflow: "hidden",
	    height: 200,
	    // width: 300,
	    position: "relative",
	    cursor: "pointer",
        "& img" : {
            height: "auto"
        }
    },
    shortDescription: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        display:"-webkit-box !important",
        WebkitLineClamp:3,
        WebkitBoxOrient:"vertical",
        whiteSpace:"normal"
    },
    lowerCase: {
        textTransform: "capitalize"
    },
    paddingLeft25: {
        paddingLeft: 25
    },
    checkbox: {
        // position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
    gridImage: {
        width: 250
    },
    image: {
        borderRadius: 16
    },
    pin: {
        float: 'left',
        marginRight: 4
    },
    moreDetailContainer: {
        paddingBottom: 8
    },
    moreDetail: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
}));

type TransfersCardManualProps = {
    transfer: any,
    variantIsChecked: any,
    onToggleVariant: any,
    transfersHasVariant: any,
    products: any
}

const TransfersCardManual = (props: TransfersCardManualProps) => {
    const {transfer, variantIsChecked, onToggleVariant, transfersHasVariant, products} = props;
    const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const type = useSelector((state) => state.itinerary_type.type);
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    
    const [collapse, setCollapse] = useState(true);
    
    const toggleMoreDetail = () => {
        setCollapse(!collapse);
    }
    const getPicture = (info: any) => {
        let picture = '/Img/destination_default.jpg';
        if (info.picture !== undefined && info.picture !== null && info.picture.length > 0) {
            if (info.picture.url !== null && info.picture.url !== '') {
                picture = info.picture.url;
            } else if (info.picture.thumbnail_little !== null && info.picture.thumbnail_little !== '') {
                picture = info.picture.thumbnail_little;
            }
        }
        return picture;
    };
    let check_in_cart = products.find((product: any) => {
        if (product.start_destination !== undefined && product.start_destination !== null && transfer.start_destination !== undefined && transfer.start_destination !== null) {
    		return transfer.start_destination.data.id === product.start_destination.data.id && transfer.product_type === product.product_type && !product.is_optional;
        }
	});
    let check_option = products.find((product: any) => {
        if (product.start_destination !== undefined && product.start_destination !== null && transfer.start_destination !== undefined && transfer.start_destination !== null) {
    		return transfer.start_destination.data.id === product.start_destination.data.id && transfer.product_type === product.product_type && product.is_optional;
        }
	});
    return (
        <Grid container direction={"row"} spacing={2} style={{marginBottom: 10}}>
            <Grid item xs container direction={"column"} alignItems={"flex-start"}>
                {
                    check_option &&
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox className={ classes.checkbox } color={"primary"} edge={ 'start' } disableRipple checked={ variantIsChecked(transfer) } onChange={ onToggleVariant(transfer) } />}
                            label={ transfer.is_optional ? (check_in_cart ? t<string>("itineraryType.alternate_transfer") : t<string>("itineraryType.optional_transfer")) : t<string>("itineraryType.default_transfer", {type: t('shared.' + type)})}
                        />
                    </Grid>
                }
				<Grid item>
                    <TransferWithinAStationIcon className={classes.genericText} style={{verticalAlign: "sub", marginRight: 10, color: `${main_color}`}}/>
					<span className={classes.genericText} style={{fontWeight: 'bold', textTransform: 'uppercase'}}>{ `${(!!transfer.type || transfer.custom_product !== undefined) ? t<string>('cars.categories.' + ((!!transfer.type ? transfer.type : (transfer.custom_product !== undefined && !!transfer.custom_product.vehicle_type ? transfer.custom_product.vehicle_type : '')).toLowerCase()).replace(" ", "_")) : t<string>('cart-material.transfer')} ${transfer.is_custom ? transfer.origin_name : (transfer.address_pickup !== null ? transfer.address_pickup : '')}` } </span>
				</Grid>
                {
                    transfer.address_pickup !== null && transfer.address_return !== null &&
                    <Grid item>
                        <RoomIcon className={`${classes.genericText}`} style={{fontSize: 16, verticalAlign: "text-top", color: `${main_color}`}}/>
                        <span className={`${classes.genericText} ${classes.italic}`} style={{fontSize: 14}}>{ transfer.address_pickup }</span>
                        <ArrowRightAltIcon className={`${classes.genericText}`} style={{fontSize: 16, verticalAlign: "middle", marginLeft: 2, marginRight: 2}}/>
                        <span className={`${classes.genericText} ${classes.italic}`} style={{fontSize: 14}}>{ transfer.address_return }</span>
                    </Grid>
                }
			</Grid>
            {/* <Grid className={ classes.moreDetailContainer } item xs={ 12 }>
                <Typography className={ classes.moreDetail } align={ 'center' } onClick={ toggleMoreDetail }>{collapse ? t<string>('cart-material.show-more-details') : t<string>('cart-material.show-less-details')}</Typography>
            </Grid> */}
            {
                // !collapse &&
                // <Grid item container direction={transfer.is_optional ? "row-reverse" : "row"} justify={"flex-start"} alignItems={"center"} spacing={transfer.is_optional || fullScreen ? 2 : 0}>
                //     <Grid item sm={5} xs={12} className={ classes.gridImage }>
                //         <img className={ classes.image } src={getPicture(transfer)} alt={ 'transfer-picture' } width={ 250 }/>
                //     </Grid>
                //     {
                //         transfer.description !== "" && (
                //             <Grid item sm={7} xs={12} className={clsx({[classes.paddingLeft25]: !fullScreen})}>
                //                 <Grid container direction={"column"} spacing={2}>
                //                     <Grid item>
                //                         <Typography variant={"h5"}>{ t<string>("footer.about") }</Typography>
                //                     </Grid>
                //                     {
                //                         // ((transfer.is_custom ? (transfer.custom_product.max_passengers !== undefined && transfer.custom_product.max_passengers !== null) : (transfer.max_capacity !== undefined && transfer.max_capacity !== null)) || (transfer.is_custom ? (transfer.custom_product.nb_baggage !== undefined && transfer.custom_product.nb_baggage !== null) : (transfer.baggage !== undefined && transfer.baggage !== null) )) && (
                //                         //     <Grid item container direction={"row"} spacing={2}>
                //                         //         {
                //                         //             (transfer.is_custom ? transfer.custom_product.max_passengers !== null : transfer.max_capacity !== null) && (
                //                         //                 <Grid item>
                //                         //                     <Tooltip title={t("flight_groups.passengers_max")}>
                //                         //                         <PeopleIcon className={classes.genericText} style={{verticalAlign: "sub", marginRight: 10}}/>
                //                         //                     </Tooltip>
                //                         //                     <span className={classes.genericText} style={{fontWeight: "bold"}}>{transfer.is_custom ? transfer.custom_product.max_passengers : transfer.max_capacity}</span>
                //                         //                 </Grid>
                //                         //             )
                //                         //         }
                //                         //         {
                //                         //             (transfer.is_custom ? transfer.custom_product.nb_baggage !== null : transfer.baggage !== null) && (
                //                         //                 <Grid item>
                //                         //                     <Tooltip title={t("global.max_luggages")}>
                //                         //                         <WorkIcon className={classes.genericText} style={{verticalAlign: "sub", marginRight: 10}}/>
                //                         //                     </Tooltip>
                //                         //                     <span className={classes.genericText} style={{fontWeight: "bold"}}>{transfer.is_custom ? transfer.custom_product.nb_baggage : transfer.baggage}</span>
                //                         //                 </Grid>

                //                         //             )
                //                         //         }
                //                         //     </Grid>
                //                         // )
                //                     }
                //                     <Grid item>
                //                         {t("global.description") + " : "}
                //                         <div className={`${classes.genericText} ${classes.shortDescription}`} dangerouslySetInnerHTML={ {__html: transfer.description } } />
                //                     </Grid>
                //                 </Grid>
                //                 {
                //                     // fullScreen && transfersHasVariant && (
                //                     //     <Grid item xs={12}>
                //                     //         <FormControlLabel
                //                     //             control={<Checkbox className={ classes.checkbox } edge={ 'start' } disableRipple checked={ variantIsChecked(transfer) } onChange={ onToggleVariant(transfer) } />}
                //                     //             label={ variantIsChecked(transfer) ? t<string>("itineraryType.chosen_transfer") : t<string>("itineraryType.alternate_transfer")}
                //                     //         />
                //                     //     </Grid>
                //                     // )
                //                 }
                //             </Grid>
                //         )
                //     }
                // </Grid>
            }
            {
                // transfer.is_optional && (
                //     <Grid item style={{width: "100%"}}>
                //         <Alert variant="filled" severity="info" style={{opacity: "70%"}}>
                //         {t("summary.optional_transfer")}
                //         </Alert>
                //     </Grid>
                // )
            }
        </Grid>
    )
};
export default React.memo(TransfersCardManual);