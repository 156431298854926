import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popper from '@material-ui/core/Popper';
import RoomIcon from '@material-ui/icons/Room';
import axios, { AxiosResponse } from "axios";
import grey from "@material-ui/core/colors/grey";

import CheckBeforeRequest from "../Common/CheckBeforeRequest";

const useStyles = makeStyles(theme => ({
    formControlRoot: {
	    "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(14px, 13px) scale(1)'
	    },
	    "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    },
	    "& .mui-jss-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
	    	padding: '0'
	    },
	    "& .mui-jss-MuiInputBase-root.mui-jss-MuiOutlinedInput-root.mui-jss-MuiAutocomplete-inputRoot.mui-jss-MuiInputBase-fullWidth.mui-jss-MuiInputBase-formControl.mui-jss-MuiInputBase-adornedEnd.mui-jss-MuiOutlinedInput-adornedEnd": {
	    	paddingRight: '10px'
	    }
  	},
    formControlRoot1: {
	    "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(14px, 20px) scale(1)'
	    },
	    "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    },
	    "& .mui-jss-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
	    	padding: '0'
	    },
	    "& .mui-jss-MuiInputBase-root.mui-jss-MuiOutlinedInput-root.mui-jss-MuiAutocomplete-inputRoot.mui-jss-MuiInputBase-fullWidth.mui-jss-MuiInputBase-formControl.mui-jss-MuiInputBase-adornedEnd.mui-jss-MuiOutlinedInput-adornedEnd": {
	    	paddingRight: '10px'
	    },
	    "& .mui-jss-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .mui-jss-MuiAutocomplete-input": {
	    	padding: '17px 4px'
	    }
  	},
  	inputBorder1: {
  		"&& .mui-jss-MuiOutlinedInput-root": {
  			borderRadius: '8px 0px 0px 8px',
  			borderRight: '0'
  		}
  	},
  	inputBorder2: {
  		"& .mui-jss-MuiOutlinedInput-root": {
  			borderRadius: '0'
  		}
  	},
  	inputBorder3: {
  		borderRadius: '8px'
  	},
  	inputBorder4: {
  		"&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: '0px 8px 8px 0px'
  		}
  	},
  	inputBorder5: {
  		"&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: '8px'
  		}
  	},
  	greyColor: {
  		color: grey[500],
  		marginLeft: '4px'
  	},
	  noBorderLeft: {
        borderLeft: "none"
    },
    noBorderRight: {
        borderRight: "none"
    },
    noBorder: {
        borderRight: "none",
        borderLeft: "none"
    }
}));

let source = null;

const DestinationInput = ({ label, search, setSearch, type, elem_index, google }) => {
    const classes = useStyles();
    //const borderStyle = inputStyle();
    const theme = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const { t } = useTranslation();
    const { headers } = CheckBeforeRequest();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(type === "departure" ? search[elem_index].origin_dest : search[elem_index].return_dest);
    const [error, setError] = useState(false);
  	const [inputValue, setInputValue] = useState('');
  	const [options, setOptions] = useState([]);
  	const [loading, setLoading] = useState(false);

  	const PopperMy = function (props) {
  	  return (<Popper {...props} style={matches ? { width: 600 } : { width: "100%" }} placement="bottom-start" />);
  	};
  	useEffect(() => {
  		setError(type === "departure" ? search[elem_index].origin_dest_error : search[elem_index].return_dest_error);
	  }, [type === "departure" ? search[elem_index].origin_dest_error : search[elem_index].return_dest_error]);
  	useEffect(() => {
        if (type === "departure") {
            setValue(search[elem_index].origin_dest);
        } else {
            setValue(search[elem_index].return_dest);
        }
	  }, [type === "departure" ? search[elem_index].origin_dest : search[elem_index].return_dest]);
  	useEffect(() => {
	    if (inputValue.length >= 3 && open) {
	    	if (source !== null) {
	    		source.cancel('Operation canceled by the user.');
	    	}
		    let unmount = false;
		    //Save the cancel token for the current request
		    source = axios.CancelToken.source();
	    	axios({
			    method: "GET",
			    headers: headers,
			    url: `${API_HREF}client/${window.id_owner}/destinations/quick_search/?limit=5&ordering=current_version__type&visibility__in=PUBLIC,PRIVATE_TO`,
			    params: {
			    	search: inputValue,
					reduced: true,
					vehicle: true,
			    },
			    cancelToken: source.token
			}).then(function (response) {
				let data = response.data;
				let newOptions = Object.keys(data).map((key) => data[key]);
				console.log('newOptions:', newOptions);
				if (data.length === 0 && google !== null) {
					let autocomplete_service = new google.maps.places.AutocompleteService();
					let service_request = {
						input: inputValue,
						types: ['geocode']
					}
					autocomplete_service.getPlacePredictions(service_request, (results) => {
						if (results) {
							results.map((result) => {
								if (result.types.includes("locality")) {
									let found = newOptions.find((el) => {
										return result.description.includes(el.international_name);
									});
									if (found === undefined) {
										newOptions.push({
											name: result.description,
											id: null,
											reference: result.reference,
											is_pickup_city_name: type === "departure",
										});

									}
								}
							});
						}
						setOptions(newOptions);
						setLoading(false);
					});
					// setOptions(tmp);
				} else {
					setOptions(newOptions);
					setLoading(false);
				}
			}).catch(function (error) {
				if (!axios.isCancel(error)) {
				    console.log('Request canceled', error.message);
                }
	       		//TODO: show snackbar error
	   		});
	    }
  	}, [inputValue]);
  	useEffect(() => {
  	    if (!open) {
  	      setOptions([]);
  	    }
  	}, [open]);
  	function handleChange(newValue) {
		  console.log('newValue : ', newValue);
		if (newValue !== null && (newValue.is_pickup_city_name !== undefined && newValue.is_pickup_city_name !== null) && google !== null) {
			let tmp_newValue = Object.assign({}, newValue);
			let detail_service = new google.maps.Geocoder();
			let detaill_request = {
				placeId: tmp_newValue.reference
			}
			detail_service.geocode(detaill_request, (place, status) => {
				console.log("place:", place);
				if (status == google.maps.places.PlacesServiceStatus.OK) {
					let country = place[0].address_components.find((el) => {
						return el.types.includes("country");
					});
					let city = place[0].address_components.find((el) => {
						return el.types.includes("locality");
					});
					tmp_newValue.name = city.long_name;
					tmp_newValue.international_name = city.long_name;
					tmp_newValue.country_code = country.short_name;
					saveValue(tmp_newValue);
				}
				else {
					console.log("status:", status);
				}
			});
		}
		else {
			saveValue(newValue)
		}
  	}
  	function saveValue(newValue) {
  		let tmp_search = search.slice();
  		let copy_search = [];
  		tmp_search.map((elem, index) => {
  			if (index === elem_index) {
  				let tmp_elem = Object.assign({}, elem);
  				if (type === "departure") {
  					tmp_elem.origin_dest = newValue;
  					if (tmp_elem.same_dest) {
	  					tmp_elem.return_dest = newValue;
  					}
  				} else {
  					tmp_elem.return_dest = newValue;
  				}
  				elem = tmp_elem;
  			}
  			copy_search.push(elem);
  		});
  		setSearch(copy_search);
  		setValue(newValue);
  		setLoading(false);
  	}
  	return (
	    <Autocomplete
	    	onOpen={() => {
		        setOpen(true);
	      	}}
	      	onClose={() => {
	        	setLoading(false);
	        	setOpen(false);
	      	}}
	      	id={`google-maps-${type}-${elem_index}`}
	      	options={options}
	      	loading={loading}
	      	getOptionLabel={(option) => option.data !== undefined ? (option.data.international_name || option.data.name) : (option.international_name || option.name) }
	      	getOptionSelected={(option, value) => option.id === value.id}
	      	freeSolo
	      	selectOnFocus
  	      	clearOnBlur
	      	autoComplete={false}
	      	// includeInputInList
	      	// filterSelectedOptions
			filterOptions={(options, object) => options}

	      	value={value}
	      	onChange={(event, newValue) => {
	      		setError(false);
	      		handleChange(newValue);
	      	}}
	      	onInputChange={(event, newInputValue) => {
	      		setLoading(true);
	        	setInputValue(newInputValue);
	      	}}
	      	className={matches ? (type === "departure" ? (isTablet ? (!search[elem_index].same_dest ? classes.inputBorder1 : classes.inputBorder5) : classes.inputBorder1) : (isTablet ? classes.inputBorder4 : classes.inputBorder2)) : classes.inputBorder3}
	      	PopperComponent={PopperMy}
	      	renderInput={(params) => (
	      		<TextField
  		          	{...params}
  		          	error={error}
  		          	label={label}
  		          	variant="outlined"
  		          	className={matches ? classes.formControlRoot : classes.formControlRoot1}
  		          	InputProps={{
  		            	...params.InputProps,
  		            	endAdornment: (
  		              		<Fragment>
  		                		{
  		                			(loading && inputValue.length > 0 && (type === "departure" ? search[elem_index].origin_dest === null : search[elem_index].return_dest === null)) ? <CircularProgress color="inherit" size={20} /> : null
  		                		}
  		              		</Fragment>
  		            	),
                        classes: { notchedOutline: type === "departure" ? (matches ? (isTablet ? (!search[elem_index].same_dest ? classes.noBorderRight : "") : classes.noBorderRight) : "") : (matches ? (isTablet ? classes.noBorderLeft : classes.noBorder) : "") }
  		          	}}
  		        />
	      	)}
    	/>
    );
};
export default DestinationInput;
