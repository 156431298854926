import { isEqual } from "lodash";
import { ItineraryContentStep } from "../../Itinerary/objects/itineraryContentStep";

export function isContentStepEqual(a: ItineraryContentStep, b: ItineraryContentStep): boolean {
    if (a.mode === 'by-step' && b.mode === 'by-step') {
        return a.content.destination?.id === b.content.destination?.id &&
               a.content.order === b.content.order;
    }

    if (a.mode === 'by-day' && b.mode === 'by-day') {
        const aDestinations = a.content.destinations?.map((item) => {
            return item.id;
        }).sort((a, b) => a - b) ?? [];
        const bDestinations = b.content.destinations?.map((item) => {
            return item.id;
        }).sort((a, b) => a - b) ?? [];
        return isEqual(aDestinations, bDestinations) &&
               isEqual(
                   [...a.content.day].sort((a, b) => a - b),
                   [...b.content.day].sort((a, b) => a - b)
               ) &&
               a.content.circuit === b.content.circuit &&
               a.content.circuit_trip_version === b.content.circuit_trip_version &&
               a.content.iti_type === b.content.iti_type;
    }
    
    return false;
}
