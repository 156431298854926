// Dependencies
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Core
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
// Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';

const useStyles = makeStyles({
    validateButton: {
        padding: '15px 10px',
        backgroundColor: '#E6592F',
        color: 'white'
    },
    cancelButton: {
        padding: '15px 10px',
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    }
});

const CartAccommodationManualCancellationRules = ({accommodation, onManualCancellationRules}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [noRefund, setNoRefund] = useState(accommodation.no_refund);
    const [cancellationRules, setCancellationRules] = useState(accommodation.cancel_condition_text !== null ? accommodation.cancel_condition_text : '');
    const onNoRefundChange = event => {
        if (event.target.value) {
            setCancellationRules(t('cart-material.not-cancellable'));
        } else {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${accommodation.id}/get_cancellation_text_from_charges/`
            }).then(response => {
                setCancellationRules(response.data.cancellation_text);
            }).catch(error => {
            });
        }
        setNoRefund(event.target.value);
    };
    const onCancellationRulesChange = event => {
        setCancellationRules(event.target.value);
    };
    const onValidateManualCancellationRules = () => {
        const { headers } = CheckBeforeRequest();
        accommodation.rooms.map(room => {
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                data: {
                    no_refund: noRefund,
                    cancel_condition_text: cancellationRules === '' ? null : cancellationRules
                }
            }).then(response => {
                dispatch({type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data});
                onManualCancellationRules();
            }).catch(error => {
                console.log(error);
                onManualCancellationRules();
            });
        });
    };
    const onCancelManualCancellationRules = () => {
        onManualCancellationRules();
    };
    useEffect(() => {
        if (accommodation.cancel_condition_text === null) {
            const { headers } = CheckBeforeRequest();
            let ids = '';
            accommodation.rooms.map((room, index_room) => {
                if (index_room > 0) {
                    ids += '&';
                }
                ids += `ids=${room.id}`;
            });
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${accommodation.id}/get_cancellation_text_from_charges_multi/?${ids}`
            }).then(response => {
                setCancellationRules(response.data.cancellation_text);
            }).catch(error => {
            });
        }
    }, []);
    return (
        <Grid container spacing={ 2 }>
            <Grid item xs={ 4 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <Select id={ 'no-refund' } value={ noRefund } onChange={ onNoRefundChange }>
                        <MenuItem value={ false }>{ t('cart-material.cancellable-with-conditions') }</MenuItem>
                        <MenuItem value={ true }>{ t('cart-material.not-cancellable') }</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={ 12 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <InputLabel htmlFor={ 'cancellation-rules' }>{ t('cart-material.manual-cancellation-rules') }</InputLabel>
                    <OutlinedInput
                        id={ 'cancellation-rules' }
                        type={ 'text' }
                        multiline
                        rows={ 4 }
                        onChange={ onCancellationRulesChange }
                        label={ t('cart-material.manual-cancellation-rules') }
                        value={ cancellationRules === t('cart-material.not-cancellable') ? '' : cancellationRules }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={ 2 }>
                <Button variant={ 'contained' } className={ classes.cancelButton } fullWidth={ true } onClick={ onCancelManualCancellationRules }>{ t('global.cancel') }</Button>
            </Grid>
            <Grid item xs={ 2 }>
                <Button variant={ 'contained' } className={ classes.validateButton } fullWidth={ true } onClick={ onValidateManualCancellationRules }>{ t('global.validate') }</Button>
            </Grid>
        </Grid>
    );
};

export default CartAccommodationManualCancellationRules;