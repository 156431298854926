import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import Notify from "../../Common/Notify";

//---- Actions ----//
import { RemoveCarFromCart } from "../../../Actions/CarsSearch";

export default function (id, index, dispatch, enqueueSnackbar, setRequestStatus, t) {
	const {pass_check, headers} = CheckBeforeRequest();
	setRequestStatus(true);
	if (pass_check) {
		$.ajax({
		    method: "DELETE",
		    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/car/${id}/`,
		    headers: {
		        'Authorization': 'Token ' + GetCookie('token'),
		    },
		    success: function () {
	    		dispatch(RemoveCarFromCart(index));
	    		enqueueSnackbar(t("cart.delete_old_car_success"), {
	    		    variant: "success",
	    		});
		    },
		    error: function (error) {
		        console.log("add to cart:", error);
		        setRequestStatus(false);
		        enqueueSnackbar(t("cart.delete_car_fail"), {
		            variant: "error",
		        });
		    }
		});
	}
}