//Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
//Core
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { useCartNeedRecalc } from "../Itinerary/network/cartNeedRecalc";
import GetItineraryFlight from '../Itinerary/InfoReload/GetItineraryFlight';

const useStyles = makeStyles({
    updateButton: {
        border: '1px solid'
    }
});

const CartUpdateNotification = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const itinerary_list = useSelector(state => state.itinerary.itinerary_list);
    const start_date = useSelector(state => state.trip.start_date);
    const departure_destination = useSelector(state => state.itinerary.departure_destination);
    const return_destination = useSelector(state => state.itinerary.return_destination);
    const needRecalc = useCartNeedRecalc();

    const onRecalc = () => {
        GetItineraryFlight(itinerary_list, start_date, departure_destination, return_destination, dispatch);
    };

    if (needRecalc) {
        return (
            <Alert variant={ 'filled' } severity={ 'warning' } action={
                <Tooltip title={ t('cart-material.update-tooltip') }>
                    <Button className={ classes.updateButton } color={ 'inherit' } size={ 'small' } onClick={ onRecalc }>
                        { t('cart-material.update') }
                    </Button>
                </Tooltip>
            }>{ t('cart-material.update-text') }</Alert>
        );
    }
    return null;
};

export default CartUpdateNotification;
