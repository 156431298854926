import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from "@material-ui/core/Grid";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBefore from '@material-ui/icons/NavigateBefore';

import RightPanel from "./RightPanel.tsx";

type MobileRightPanelProps = {
    mobileDialog: boolean;
    setMobileDialog: (value: boolean) => void;
    checkScreen: boolean;
    options: any;
    selectedOptions: any;
    setSelectedOptions: (value: any) => void;
    assurances: any;
    pricesRef: any;
    setSalePitch: (value: any) => void;
    dateIsAfter: (value: any) => void;
    versionAvailable: any;
    versionToShow: any;
    setVersionToShow: (value: any) => void;
    variantToShow: any;
    setVariantToShow: (value: any) => void;
    variantAvailable: any;
    setIsItiLoading: (value: any) => void;
    topContainerRef: any;
    itinerary_data: any;
    selectedVariant: any;
    products: any;
};

const MobileRightPanel = (props: MobileRightPanelProps) => {
    const { mobileDialog, setMobileDialog, checkScreen, options, selectedOptions, setSelectedOptions, assurances, pricesRef, setSalePitch, dateIsAfter, versionAvailable, versionToShow, setVersionToShow, variantToShow, setVariantToShow, variantAvailable, setIsItiLoading, topContainerRef, itinerary_data, selectedVariant, products } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        setMobileDialog(false);
    }; 
    return (
    	<Dialog open={ mobileDialog } onClose={ handleClose } fullWidth fullScreen={ fullScreen } maxWidth={ "md" }>
    	    <DialogTitle>
    	        <Grid container justify={ "space-between" } alignItems={ "center" }>
                    <Grid item style={{ position: "absolute", top: 9, right: 6 }}>
                        <IconButton edge={ "start" } onClick={ handleClose }><CloseIcon/></IconButton>
                    </Grid>
    	        </Grid>
    	    </DialogTitle>
	        <DialogContent>
                <RightPanel
                    checkScreen={checkScreen}
                    options={options}
                    assurances={assurances}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    pricesRef={pricesRef}
                    setSalePitch={setSalePitch}
                    dateIsAfter={dateIsAfter}
                    versionAvailable={versionAvailable}
                    setVersionToShow={setVersionToShow}
                    versionToShow={versionToShow}
                    setIsItiLoading={setIsItiLoading}
                    variantToShow={variantToShow}
                    variantAvailable={variantAvailable}
                    setVariantToShow={setVariantToShow}
                    topContainerRef={topContainerRef}
                    itinerary_data={itinerary_data}
                    selectedVariant={selectedVariant}
                    products={products}
                />
	        </DialogContent>
      	</Dialog>
    );
};
export default React.memo(MobileRightPanel);
