//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import Grid from "@material-ui/core/Grid";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBefore from '@material-ui/icons/NavigateBefore';

import InscriptionRightPanel from "./InscriptionRightPanel";


const SummaryInscriptionModal = ({ activeStep, setCheckValidate, setNextButton, toggleModal, setToggleModal, selectedProducts}) => {
    const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const handleClose = () => {
		setToggleModal(false);
	}
    return (
    	<Dialog open={ toggleModal } onClose={ handleClose } fullWidth fullScreen={ fullScreen } maxWidth={ "md" }>
    	    <DialogTitle onClose={ handleClose }>
    	        <Grid container justify={ "space-between" } alignItems={ "center" }>
					<Grid item style={{position: "absolute", top: 9, right: 6}}>
                        <IconButton edge={ "start" } onClick={ handleClose }><CloseIcon/></IconButton>
                    </Grid>
    	        </Grid>
    	    </DialogTitle>
	        <DialogContent>
                <InscriptionRightPanel activeStep={activeStep} setCheckValidate={setCheckValidate} setNextButton={setNextButton} selectedProducts={selectedProducts}/>
	        </DialogContent>
      	</Dialog>
    )
}
export default React.memo(SummaryInscriptionModal);