import { flatten } from "lodash";
import { TFunction } from "i18next";
import { useCartProducts } from "../../../Itinerary/network/cartProducts";

export function transformCarsToProgramDetailsItems(
    options: {
        locale: number | null,
        destinationId: number | null,
        cart: ReturnType<typeof useCartProducts>,
        startDate: string,
        endDate: string,
        t: TFunction
    }
) {
    const cars = options.cart.cars.filter((item) => {
        return (
            window.moment.utc(item.car.start_date).isBetween(
                window.moment.utc(options.startDate),
                window.moment.utc(options.endDate)
            ) ||
            window.moment.utc(item.car.end_date).isBetween(
                window.moment.utc(options.startDate),
                window.moment.utc(options.endDate)
            )
        )/* && (
            item.type === 'manual' ||
            item.car.start_destination?.id === options.destinationId ||
            item.car.end_destination?.id === options.destinationId
        )*/ &&
        !item.car.is_optional;
    });
    // TODO : start_destination and/or end_destination can be null for normal type
    return flatten(
        cars.map((item) => {
            const result = [
                (
                    window.moment.utc(item.car.start_date).isBetween(
                        window.moment.utc(options.startDate),
                        window.moment.utc(options.endDate)
                    )
                )/* && (
                    item.type === 'manual' ||
                    item.car.start_destination.id === options.destinationId
                )*/ ?
                    {
                        dateOrder: item.car.start_date,
                        date: window.moment.utc(item.car.start_date).format('DD/MM'),
                        ref: item.car.booking_status?.item_reference,
                        title: options.t(
                            'roadbook.program-details-car-pickup-title',
                            {
                                hourly: window.moment.utc(item.car.start_date).format('HH[h]mm'),
                                name: item.car.localization?.find((item) => {
                                    return item.locale === options.locale;
                                })?.name ?? item.car.name
                            }
                        ),
                        place: options.t(
                            'roadbook.program-details-car-pickup-agency',
                            {
                                name: item.car.station_name_pickup ??
                                    item.car.address_pickup
                            }
                        ),
                        note: item.car.custom_information?.replace(/\n/g, '<br />') ?? undefined
                    } :
                    null,
                (
                    window.moment.utc(item.car.end_date).isBetween(
                        window.moment.utc(options.startDate),
                        window.moment.utc(options.endDate)
                    )
                )/* && (
                    item.type === 'manual' ||
                    item.car.end_destination.id === options.destinationId
                )*/ ?
                    {
                        dateOrder: item.car.end_date,
                        date: window.moment.utc(item.car.end_date).format('DD/MM'),
                        ref: item.car.booking_status?.item_reference,
                        title: options.t(
                            'roadbook.program-details-car-return-title',
                            {
                                hourly: window.moment.utc(item.car.end_date).format('HH[h]mm'),
                                name: item.car.localization?.find((item) => {
                                    return item.locale === options.locale;
                                })?.name ?? item.car.name
                            }
                        ),
                        place: options.t(
                            'roadbook.program-details-car-return-agency',
                            {
                                name: item.car.station_name_return ??
                                    item.car.address_return
                            }
                        ),
                        note: item.car.custom_information?.replace(/\n/g, '<br />') ?? undefined
                    } :
                    null
            ];
            //eslint-disable-next-line max-nested-callbacks
            return result.filter((item): item is NonNullable<typeof result[0]> => {
                return !!item;
            });
        })
    );
}
