import { useCartProducts } from "../network/cartProducts";

export function filterCartProducts(
    products: ReturnType<typeof useCartProducts>,
    filters: {
        isUserTO: boolean,
        arrivalDate: string,
        departureDate: string,
        useOnlyDates?: boolean
    }
): ReturnType<typeof useCartProducts> {
    return {
        accommodations: products.accommodations.filter((item) => {
            return (
                filters.useOnlyDates ||
                item.accommodation.booking_status === null ||
                item.accommodation.booking_status.status_booking !== 'CANCELLED'
            ) &&
                (
                    filters.useOnlyDates ||
                    !filters.isUserTO ||
                    JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code === 'visiteurs' ||
                    (
                        filters.isUserTO &&
                        item.accommodation.creator?.client?.type !== 2
                    )
                ) &&
                (
                    window.moment.utc(item.accommodation.start_date).isAfter(filters.arrivalDate, 'day') ||
                    window.moment.utc(item.accommodation.start_date).isSame(filters.arrivalDate, 'day')
                ) &&
                (
                    window.moment.utc(item.accommodation.end_date).isBefore(filters.departureDate, 'day') ||
                    window.moment.utc(item.accommodation.end_date).isSame(filters.departureDate, 'day')
                );
        }),
        cars: products.cars.filter((item) => {
            return (
                filters.useOnlyDates ||
                item.car.booking_status === null ||
                item.car.booking_status.status_booking !== 'CANCELLED'
            ) &&
                (
                    filters.useOnlyDates ||
                    !filters.isUserTO ||
                    JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code === 'visiteurs' ||
                    (
                        filters.isUserTO &&
                        item.car.creator?.client?.type !== 2
                    )
                ) &&
                window.moment.utc(item.car.start_date).isSameOrBefore(filters.arrivalDate, 'day') &&
                window.moment.utc(item.car.end_date).isSameOrAfter(filters.departureDate, 'day');
        }),
        pois: products.pois.filter((item) => {
            return (
                filters.useOnlyDates ||
                item.poi.booking_status === null ||
                item.poi.booking_status.status_booking !== 'CANCELLED'
            ) &&
                (
                    filters.useOnlyDates ||
                    !filters.isUserTO ||
                    JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code === 'visiteurs' ||
                    (
                        filters.isUserTO &&
                        item.poi.creator?.client?.type !== 2
                    )
                ) &&
                (
                    window.moment.utc(item.poi.start_date).isAfter(filters.arrivalDate, 'day') ||
                    window.moment.utc(item.poi.start_date).isSame(filters.arrivalDate, 'day')
                ) &&
                (
                    window.moment.utc(item.poi.end_date).isBefore(filters.departureDate, 'day') ||
                    window.moment.utc(item.poi.end_date).isSame(filters.departureDate, 'day')
                );
        }),
        transfers: products.transfers.filter((item) => {
            return (
                filters.useOnlyDates ||
                item.transfer.booking_status === null ||
                item.transfer.booking_status.status_booking !== 'CANCELLED'
            ) &&
                (
                    filters.useOnlyDates ||
                    !filters.isUserTO ||
                    JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code === 'visiteurs' ||
                    (
                        filters.isUserTO &&
                        item.transfer.creator?.client?.type !== 2
                    )
                ) &&
                (
                    window.moment.utc(item.transfer.start_date).isAfter(filters.arrivalDate, 'day') ||
                    window.moment.utc(item.transfer.start_date).isSame(filters.arrivalDate, 'day')
                ) &&
                (
                    window.moment.utc(item.transfer.end_date).isBefore(filters.departureDate, 'day') ||
                    window.moment.utc(item.transfer.end_date).isSame(filters.departureDate, 'day')
                );
        }),
        flights: products.flights,
        trains: products.trains,
        cruises: products.cruises,
        ferries: products.ferries,
        assistances: products.assistances,
        insurances: products.insurances
    };
}
