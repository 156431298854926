import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import HasPermission from "../Common/Functions/HasPermission.js";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import axios from "axios";

const useStyles = makeStyles(theme => ({
	formControlRoot: {
		borderRadius: "20px",
		textTransform: "capitalize",
        marginTop: 2,
        padding: 7
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    whiteButton: {
        backgroundColor: "white",
        color: "black"
    },
    customSize: {
        width: 41,
        height: 41,
        marginTop: 4,
        backgroundColor: "transparent"
    },
    paperStepper: {
		position: "absolute !important",
        // top: "100% !important",
        // marginLeft: "-232px",
        right: "16px",
        zIndex: "1 !important",
        background: "rgb(255, 255, 255) !important",
        borderRadius: "32px !important",
        boxShadow: "rgb(0 0 0 / 20%) 0px 6px 20px !important",
        marginTop: "12px !important",
        maxHeight: "calc(100vh - 220px) !important",
        overflow: "hidden auto !important",
        padding: "16px 32px !important"
    },
    active: {
        backgroundColor: "rgba(0, 0, 0, 0.04)"
    },
    bold: {
        fontWeight: 'bold'
    },
    menu: {
        '&:hover': {
            color: '#BF202D'
        }
    },
    pointer: {
        cursor: 'pointer'
    },
    relative: {
        position: 'relative'
    }
}));

const VisiteursAccountMenu = ({router}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((store) => {
        return store.user.user;
    });
    const permissions = useSelector(store => store.user.permissions);
	const data_trip = useSelector(store => store.trip.data_trip);
    const hover_nav = useSelector(store => store.header.hover_nav);

    const handleOpen = () => {
        if (hover_nav === null || hover_nav !== 'account') {
            dispatch({type: 'HEADER_SET_HOVER_NAV', payload: 'account'});
        } else {
            dispatch({type: 'HEADER_SET_HOVER_NAV', payload: null});
        }
    };
    const openHelp = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/user/access_support/`,
        }).then(function (response) {
            handleOpen();
            let win = window.open(response.data, '_blank');
            win.focus();
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }

    let current_location = router.getCurrentLocation();
    const config = JSON.parse(localStorage.getItem("config"));
    const openVisiteursContact = () => {
        let win = window.open('https://www.visiteurspro.com/pages/a-la-carte', '_blank');
        win.focus();
    }
    return (
        <Fragment>
            <div className={`${classes.bold} ${classes.pointer} ${classes.menu} ${classes.relative}`} onClick={handleOpen}>
                Mon compte
            </div>
            {
                hover_nav === "account" && (
                    <ClickAwayListener onClickAway={handleOpen}>
                        <Paper
                            className={classes.paperStepper}
                            elevation={5}
                            children={
                                <List>
                                    {
                                        (data_trip === null || (data_trip !== null && data_trip.trip !== undefined && !data_trip.trip.itinerary_type && !data_trip.trip.package_type && !data_trip.trip.circuit_type)) && (
                                            <Fragment>
                                                <ListItem button onClick={() => {handleOpen();current_location.pathname.includes("trip-list") ? dispatch({type: "MENU_CHANGE_TRIP_INFO_PAGE_AND_SET_INFO", payload: {trip_info_page: 1, trip_info: null}}) : router.push(`/${window.url_name}/menu/trip-list`)}} className={`${current_location.pathname.includes("trip-list") ? classes.active : ""}`}>
                                                    <ListItemIcon>
                                                        <FolderOpenOutlinedIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={t("header.menu.list_trip")} />
                                                </ListItem>
                                                <Divider/>
                                                <ListItem button onClick={() => {handleOpen();router.push(`/${window.url_name}/menu/profile`)}} className={`${current_location.pathname.includes("profile") ? classes.active : ""}`}>
                                                    <ListItemIcon>
                                                        <PersonOutlineOutlinedIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={t("header.menu.profile")} />
                                                </ListItem>
                                                <Divider/>
                                                {
                                                    config.quotation_code !== "verdie" && config.quotation_code !== "marcovasco" && user.client_full.type !== 1 && HasPermission(permissions, "view_client") === true && (
                                                        <Fragment>
                                                            <ListItem button onClick={() => {handleOpen();router.push(`/${window.url_name}/menu/agency-user-management`)}} className={`${current_location.pathname.includes("agency-user-management") ? classes.active : ""}`}>
                                                                <ListItemIcon>
                                                                    <GroupOutlinedIcon />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t("header.menu.agency_users")} />
                                                            </ListItem>
                                                            <Divider/>
                                                        </Fragment>
                                                    )
                                                }
                                                {
                                                    config.quotation_code !== "verdie" && config.quotation_code !== "marcovasco" && user.client_full.type !== 1 && HasPermission(permissions, "change_marginprice") === true && (
                                                        <Fragment>
                                                            <ListItem button onClick={() => {handleOpen();router.push(`/${window.url_name}/menu/agency-margin`)}} className={`${current_location.pathname.includes("agency-margin") ? classes.active : ""}`}>
                                                                <ListItemIcon>
                                                                    <StorefrontOutlinedIcon />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t("header.menu.agency_margin")} />
                                                            </ListItem>
                                                            <Divider/>
                                                        </Fragment>
                                                    )
                                                }
                                                {
                                                    config.quotation_code !== "verdie" && config.quotation_code !== "marcovasco" && user.client_full.type !== 1 && HasPermission(permissions, "view_user") === true && (
                                                        <Fragment>
                                                            <ListItem button onClick={() => {handleOpen();router.push(`/${window.url_name}/menu/agency-profile`)}} className={`${current_location.pathname.includes("agency-profile") ? classes.active : ""}`}>
                                                                <ListItemIcon>
                                                                    <AccountBalanceIcon />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t("header.menu.agency_profile")} />
                                                            </ListItem>
                                                            <Divider/>
                                                        </Fragment>
                                                    )
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        user.client_full.type === 2 &&
                                        <>
                                            <ListItem button onClick={openVisiteursContact}>
                                                <ListItemIcon>
                                                    <AlternateEmailIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary={t('global.visiteurs_contact')} />
                                            </ListItem>
                                            <Divider/>
                                        </>
                                    }
                                    <ListItem button onClick={openHelp}>
                                        <ListItemIcon>
                                            <HelpOutlineOutlinedIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={user.client_full.type !== 2 ? t("global.help") : t('global.tutorial')} />
                                    </ListItem>
                                    <Divider/>
                                    <ListItem button onClick={ () => {
                                        let cookies = document.cookie.split("; ");
                                        for (let c = 0; c < cookies.length; c++) {
                                            let d = window.location.hostname.split(".");
                                            while (d.length > 0) {
                                                let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
                                                let p = location.pathname.split("/");
                                                document.cookie = cookieBase + "/";
                                                while (p.length > 0) {
                                                    document.cookie = cookieBase + p.join("/");
                                                    p.pop();
                                                }
                                                d.shift();
                                            }
                                        }
                                        handleOpen();
                                        router.push(`/home`);
                                        dispatch({
                                            type: "USER_SET_CONNECTION",
                                            payload: {
                                                modal_connection: false,
                                                is_connected: false,
                                                user: null,
                                                token: null
                                            }
                                        });
                                    } }>
                                        <ListItemIcon>
                                            <ExitToAppOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t("header.menu.logout")} />
                                    </ListItem>
                                </List>
                            }
                        />
                    </ClickAwayListener>
                )
            }
        </Fragment>
    )
}
export default React.memo(VisiteursAccountMenu);