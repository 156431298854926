import { useMemo } from "react";
import { useSelector } from "react-redux";
import { flatten } from "lodash";
import { useUnpackagedProducts } from "./unpackagedProduts";
import { useFilteredCartProducts } from "../../Itinerary/network/filteredCartProducts";
import { BookingStatus } from "../../Itinerary/objects/bookingStatus";
import { AppState } from "../../../Reducers/Reducers";
import { isProductPackaged } from "./isProductPackaged";

type BookableProduct = {
    id: number,
    product_type: number,
    poi_type?: boolean,
    is_available: boolean,
    is_optional: boolean,
    is_manual: boolean,
    stack_number: number | null,
    stack_info_id: number | null,
    booking_status: BookingStatus | null,
    booking_process_state: string,
    agency_need_to_book: boolean
}

export function useBookableProducts() {
    const user = useSelector((state: AppState) => state.user.user);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const unpackagedProducts = useUnpackagedProducts();
    const filteredCart = useFilteredCartProducts();
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    return useMemo((): BookableProduct[] => {
        return flatten(
            unpackagedProducts.accommodations.map((item) => {
                if (item.type === 'normal') {
                    return (item.accommodation.rooms ?? []).map((room) => {
                        return {
                            ...room,
                            is_manual: false
                        };
                    }) as BookableProduct[];
                }
                return (item.accommodation.rooms ?? []).map((room) => {
                    return {
                        ...item.accommodation,
                        ...room,
                        is_manual: true
                    };
                }) as BookableProduct[];
            })
        ).concat(
            unpackagedProducts.assistances.map((item) => {
                return {
                    ...item.assistance,
                    is_manual: item.type === 'manual'
                };
            })
        ).concat(
            unpackagedProducts.cars.map((item) => {
                return {
                    ...item.car,
                    is_manual: item.type === 'manual'
                };
            })
        ).concat(
            filteredCart.flights.map((item) => {
                return {
                    ...item.flight,
                    is_manual: item.type === 'manual'
                };
            })
        ).concat(
            unpackagedProducts.pois.map((item) => {
                return {
                    ...item.poi,
                    is_manual: item.type === 'manual'
                };
            })
        ).concat(
            unpackagedProducts.transfers.map((item) => {
                return {
                    ...item.transfer,
                    is_manual: item.type === 'manual'
                };
            })
        ).filter((product) => {
            return !isProductPackaged({
                product,
                stackInfos: trip?.stack_info ?? null
            }) &&
            product.is_available &&
            (product.booking_status === null || product.booking_status.status_booking === "ERROR") &&
            product.booking_process_state === "NOTHING" &&
            (
                !product.agency_need_to_book ||
                 user?.client_full?.type !== 2
            ) &&
            (
                !product.is_manual ||
                (
                    user?.client_full?.type === 2 &&
                    quotationCode !== 'beachcomber'
                )
            );
        });
    }, [
        unpackagedProducts,
        filteredCart,
        user
    ]);
}
