//---- Dependencies ----//
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

//---- Commons ----//
import CustomInfoMapRun from "./Functions/CustomInfoMapRun";

//---- CSS ----//
import "../../../Style/Accommodation.css";

//---- Actions ----//
import { TogleInfoCustomAndSetStep } from "../../../Actions/Summary";

let marker = null;

//---- Class ----//
@connect((store) => {
    return {
        custom_info: store.summary.custom_info,
        modal_custom_info: store.summary.modal_custom_info,        
        step_custom_info: store.summary.step_custom_info
    }
})
@withTranslation()
class CustomInfoModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            map: null,
            google: null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { index, custom_info, modal_custom_info } = this.props;
        const { map, google } = this.state;
        if (map !== null && !modal_custom_info) {
            this.setState({map: null, google: null});
        } else if (map !== null && custom_info !== null && modal_custom_info) {
            
            CustomInfoMapRun(this, index, custom_info);
        } else if (map === null && modal_custom_info) {
            CustomInfoMapRun(this, index, custom_info);
        }
    }
/*
    componentDidMount() {
        const { index } = this.props;
        CustomInfoMapRun(this, index);
    }
*/

    render() {
        const { index, custom_info, step_custom_info, modal_custom_info, dispatch, t } = this.props;
        return (
            <Modal id={ "summary-hotel-info-modal" } isOpen={ modal_custom_info[index] } toggle={() => { dispatch(TogleInfoCustomAndSetStep(index, false, 0, null)); }} size={"lg"}>
            <ModalHeader toggle={() => { dispatch(TogleInfoCustomAndSetStep(index, false, 0, null)); }} />
                <div>                   
                    <ModalBody className={"grey-bg"}>
                        {
                            custom_info !== null && (
                                <div id={"accommodation-bloc-1-3"}>                                
                                    {
                                        step_custom_info === 1 && (
                                           <div >
                                                <h1>{ t("summary.google_map") }</h1>
                                                <div className={ "custom-info-map" } id={ "custom-info-map-" + index }/>
                                           </div>
                                        )
                                    }
                                </div>
                            )
                        }
                    </ModalBody>
                </div>
            </Modal>
        );
    }
}

export default CustomInfoModal;