import Button from "@material-ui/core/Button";
import React, { Fragment } from "react";
import ReloadCart from "../Header/Functions/ReloadCart";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import InfoReloadAccommodation from "./InfoReload/InfoReloadAccommodation.jsx";
import InfoReloadCars from "./InfoReload/InfoReloadCars.jsx";
import InfoReloadCustomProducts from "./InfoReload/InfoReloadCustomProducts.jsx";
import InfoReloadFlights from "./InfoReload/InfoReloadFlights.jsx";
import InfoReloadNotes from "./InfoReload/InfoReloadNotes.jsx";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import GetPricesId from "../Cart/Functions/Margin/GetPricesId";
import Grid from "@material-ui/core/Grid";
import InfoReloadTransfers from "./InfoReload/InfoReloadTransfers";
import { Typography } from "@mui/material";

const useStyles = makeStyles({
    continueButton: {
        color: 'white',
        backgroundColor: "#e6592f"
    },
    closeButton: {
        float: "right",
        cursor: "pointer"
    },
    tableRowDialog: {
        whiteSpace: "nowrap"
    },
    textCenter: {
        textAlign: "center"
    },
    mb15: {
        marginBottom: 15
    }
});

const ReloadCartResults = ({ component }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const currency = useSelector(state => state.trip.currency);
    const end_date = useSelector(state => state.trip.end_date);
    const language = useSelector(state => state.trip.tmp_language);
    const update_infos = useSelector(state => state.cart.update_infos);
    const onValidateRecalc = () => {
        ReloadCart(dispatch, end_date);
        dispatch({ type: 'RECALC_RESET' });
    };
    if (update_infos === null) {
        return <Fragment/>;
    }
    let diff = 0;
    if (update_infos.cars !== undefined && update_infos.cars !== null && update_infos.cars.length > 0) {
        update_infos.cars.map((infos) => {
            if (infos.new !== undefined && infos.new !== null && Object.keys(infos.new).length !== 0 && infos.new.error === undefined && infos.old !== null) {
                diff += (parseFloat(infos.new.prices[GetPricesId(infos.new.prices, user.client, user, true)].selling_price) - parseFloat(infos.old.prices[GetPricesId(infos.old.prices, user.client, user, true)].selling_price));
            }
        });
    }
    if (update_infos.accommodations !== undefined && update_infos.accommodations !== null && update_infos.accommodations.length > 0) {
        update_infos.accommodations.map((infos) => {
            if (infos.new !== undefined && infos.new !== null && Object.keys(infos.new).length !== 0 && infos.new.error === undefined && infos.old !== null) {
                diff += (parseFloat(infos.new.prices[GetPricesId(infos.new.prices, user.client, user, true)].selling_price) - parseFloat(infos.old.prices[GetPricesId(infos.old.prices, user.client, user, true)].selling_price));
            }
        });
    }
    if (update_infos.custom_products !== undefined && update_infos.custom_products !== null && update_infos.custom_products.length > 0) {
        update_infos.custom_products.map((infos) => {
            if (infos.new !== undefined && infos.new !== null && Object.keys(infos.new).length !== 0 && infos.new.error === undefined && infos.old !== null) {
                diff += (parseFloat(infos.new.prices[GetPricesId(infos.new.prices, user.client, user, true)].selling_price) - parseFloat(infos.old.prices[GetPricesId(infos.old.prices, user.client, user, true)].selling_price));
            }
        });
    }
    if (update_infos.manual_products !== undefined && update_infos.manual_products !== null && update_infos.manual_products.length > 0) {
        update_infos.manual_products.map((infos) => {
            if (infos.new !== undefined && infos.new !== null && Object.keys(infos.new).length !== 0 && infos.new.error === undefined && infos.old !== null) {
                diff += (parseFloat(infos.new.prices[GetPricesId(infos.new.prices, user.client, user, true)].selling_price) - parseFloat(infos.old.prices[GetPricesId(infos.old.prices, user.client, user, true)].selling_price));
            }
        });
    }
    if (update_infos.flights !== undefined && update_infos.flights !== null && update_infos.flights.length > 0) {
        update_infos.flights.map((infos) => {
            if (infos.new !== undefined && infos.new !== null && Object.keys(infos.new).length !== 0 && infos.new.error === undefined && infos.old !== null) {
                diff += (parseFloat(infos.new.prices[GetPricesId(infos.new.prices, user.client, user, true)].selling_price) - parseFloat(infos.old.prices[GetPricesId(infos.old.prices, user.client, user, true)].selling_price));
            }
        });
    }
    if (update_infos.transfers !== undefined && update_infos.transfers !== null && update_infos.transfers.length > 0) {
        update_infos.transfers.map((infos) => {
            if (infos.new !== undefined && infos.new !== null && Object.keys(infos.new).length !== 0 && infos.new.error === undefined && infos.old !== null) {
                diff += (parseFloat(infos.new.prices[GetPricesId(infos.new.prices, user.client, user, true)].selling_price) - parseFloat(infos.old.prices[GetPricesId(infos.old.prices, user.client, user, true)].selling_price));
            }
        });
    }
    return (
        <Fragment>
            {
                diff !== 0 &&
                <TableContainer className={ classes.mb15 } component={ Paper }>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{ t("global.date") }</TableCell>
                                <TableCell>{ t("global.place") }</TableCell>
                                <TableCell>{ t("itinerary.old_product") }</TableCell>
                                <TableCell>{ t("itinerary.new_product") }</TableCell>
                                <TableCell>{ t("itinerary.price_in") + " " + currency.symbol + ")" }</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { update_infos.accommodations !== undefined && update_infos.accommodations !== null && update_infos.accommodations.length > 0 && <InfoReloadAccommodation/> }
                            { update_infos.cars !== undefined && update_infos.cars !== null && update_infos.cars.length > 0 && <InfoReloadCars classes={ classes }/> }
                            { update_infos.custom_products !== undefined && update_infos.custom_products !== null && update_infos.custom_products.length > 0 && <InfoReloadCustomProducts classes={ classes }/> }
                            { update_infos.flights !== undefined && update_infos.flights !== null && update_infos.flights.length > 0 && <InfoReloadFlights classes={ classes }/> }
                            { update_infos.transfers !== undefined && update_infos.transfers !== null && update_infos.transfers.length > 0 && <InfoReloadTransfers classes={ classes }/> }
                        </TableBody>
                    </Table>
                    <div className={ "ft-mt10 ft-mb10 " + (diff === 0 ? "ft-pl20" : (diff < 0 ? "ft-pl20 ft-green" : "ft-pl20 ft-red")) }>
                        { t("itinerary.price_changed") + " " + (diff > 0 ? "+ " + Intl.NumberFormat(language, { style: "currency", currency: currency.iso_code }).format(diff.toFixed(2)) : Intl.NumberFormat(language, { style: "currency", currency: currency.iso_code }).format(diff.toFixed(2))) }
                    </div>
                    { (update_infos.notes !== undefined && update_infos.notes !== null) && (InfoReloadNotes(update_infos, t)) }
                </TableContainer>
            }
            {
                diff === 0 &&
                <Typography sx={{ marginBottom: 2 }}>
                    {t('itinerary.price-did-not-change')}
                </Typography>
            }
            <Grid container justify={"center"}>
                <Grid item>
                    <Button className={ classes.continueButton } variant="contained" onClick={ onValidateRecalc }>OK</Button>
                </Grid>
            </Grid>
            <br/>
        </Fragment>
    );
};

export default ReloadCartResults;
