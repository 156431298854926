export default (products_to_book) => {
    let pass_check = true;
    products_to_book.map((product) => {
        //no lead of group needed for cars
        if (product.product_type !== 2 && product.is_displayed === true && (product.booking_status === null || (product.booking_status !== null && product.booking_status.status_booking === "ERROR")) && (!product.group_passenger?.lead_traveler || product.group_passenger.lead_traveler === "")) {
            if (product.product_type === 0 && product.is_manual) {
                for (const room of product.rooms ?? []) {
                    if (!room.group_passenger?.lead_traveler || room.group_passenger.lead_traveler === "") {
                        pass_check = false;
                        break;
                    }
                }
            } else {
                pass_check = false;
            }
        }
    });
    return pass_check;
};
