import { differenceInCalendarDays } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { flatten, groupBy, uniq } from "lodash";
import { getItineraryDayDifference } from "../../Menu/MaterialTripList/utils/getItineraryDifference";
import { Itinerary } from "../objects/itinerary";

type Item = Pick<Itinerary, 'start_date' | 'end_date' | 'circuit_trip_version'> & {
    destination: NonNullable<Itinerary['destination']>['id'],
    circuit: number | null,
    circuit_trip_version: number | null,
    iti_type: number | null,
}

type Group = {
    destination: number,
    circuit: number | null,
    circuit_trip_version: number | null,
    iti_type: number | null,
    days: number[]
}

export function transformItineraryToDestinationGroups(
    itinerary: Item[]
): Group[][] {
    const startDate = itinerary[0]?.start_date ?
        utcToZonedTime(itinerary[0]?.start_date, 'Etc/UTC') :
        utcToZonedTime(new Date(), 'Etc/UTC');

    const groups: Group[] = itinerary.map((step) => {
        const daysDifference = getItineraryDayDifference(step);
        const days = daysDifference.map((item) => {
            return differenceInCalendarDays(
                item,
                startDate
            ) + 1;
        }).sort((a, b) => a - b);
        return {
            circuit: step.circuit,
            circuit_trip_version: step.circuit_trip_version,
            destination: step.destination,
            iti_type: step.iti_type,
            days
        };
    });

    return Object.values(
        groupBy(
            flatten(
                groups.map((group) => {
                    return group.days.map((day) => {
                        return {
                            ...group,
                            days: [day]
                        };
                    });
                })
            ),
            (item) => item.days[0]
        )
    ).reduce((prev, current) => {
        const lastGroup = prev[prev.length - 1];
        const lastItem = lastGroup?.[lastGroup.length - 1];

        if (
            lastGroup?.length === 1 &&
            current.length === 1 &&
            lastItem &&
            lastItem.destination === current[0]?.destination &&
            lastItem.circuit === current[0]?.circuit &&
            lastItem.circuit_trip_version === current[0]?.circuit_trip_version &&
            lastItem.iti_type === current[0]?.iti_type
        ) {
            return prev.slice(0, prev.length - 1).concat([
                [
                    {
                        ...lastItem,
                        days: lastItem.days.concat(current[0]?.days ?? [])
                    }
                ]
            ]);
        } else if (
            current.length > 1 &&
            uniq(
                current.map((item) => {
                    return item.destination;
                })
            ).length === 1
        ) {
            const result = [...current];
            while (result.length !== 1) {
                result.shift();
            }
            return prev.concat([result]);
        }

        return prev.concat([current]);
    }, [] as Group[][]).sort((a, b) => {
        return (a[0]?.days[0] ?? 0) < (b[0]?.days[0] ?? 0) ? -1 : 1;
    });
}
