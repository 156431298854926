var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"chapter\">\n    <div id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"anchor") || (depth0 != null ? lookupProperty(depth0,"anchor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchor","hash":{},"data":data,"loc":{"start":{"line":2,"column":13},"end":{"line":2,"column":25}}}) : helper)))
    + "\" class=\"itinerary-page\">\n        <h1>\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n        </h1>\n        <img class=\"itinerary-page-tape\" alt=\"Tape\">\n        <img src=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":7,"column":18},"end":{"line":7,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\"\n            class=\"itinerary-page-map\" alt=\"Itinéraires\">\n        <div class=\"itinerary-page-notes\">\n            Vos notes :\n        </div>\n        <div class=\"itinerary-page-notes-line\"></div>\n        <div class=\"itinerary-page-notes-line\"></div>\n        <div class=\"itinerary-page-notes-line\"></div>\n        <div class=\"itinerary-page-notes-line\"></div>\n        <div class=\"itinerary-page-notes-line\"></div>\n        <div class=\"itinerary-page-notes-line last-line\"></div>\n        <div class=\"itinerary-page-notes-background\"></div>\n    </div>\n</div>";
},"useData":true});