import React, { useEffect, useRef, useState, ChangeEvent } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Close } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    hr: {
        border: 0,
        height: 0,
        borderTop: '1px solid rgba(0, 0, 0, .1)',
        borderBottom: '1px solid rgba(255, 255, 255, .3)',
        width: '100%'
    },
    ftContainer: {
        // marginTop: "5px !important",
        marginBottom: "32px !important"
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    }
}));

type Props = {
    modalDelete: boolean;
    deleteDocument: () => void;
    setModalDelete: (value: boolean) => void;
}

export function ModalDeleteCustomVoucher(props: Props): JSX.Element {
    const { modalDelete, deleteDocument, setModalDelete } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const onClose = (event: React.MouseEvent<HTMLButtonElement>, reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick') => {
        if (reason !== 'backdropClick') {
            setModalDelete(false);
        }
    };
    return (
        <Dialog open={modalDelete} onClose={onClose} fullWidth maxWidth={ "md" } disableEscapeKeyDown>
            <DialogTitle>
                <Grid container justifyContent={ 'space-between' } alignItems={ 'center' }>
                    <Grid item>{ t<string>('summary-payment.delete-confirmation-title') }</Grid>
                    <Grid item>
                        <IconButton size={"small"} onClick={ onClose }><Close/></IconButton>
                    </Grid>
                </Grid>
                <hr className={ classes.hr }/>
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent={ 'center' } alignItems={ 'center' }>
                    <Grid item xs={ 12 } className={ classes.textJustify }>
                        { t<string>('menu.trip_list.delete_manual_voucher') }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t<string>('shared.cancel')}
                </Button>
                <Button onClick={deleteDocument} color="primary">
                    {t<string>('shared.validate')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}