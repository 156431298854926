import React from "react";
import {
    Stack,
    StackProps,
    SvgIconProps,
    Typography,
    TypographyProps
} from "@mui/material";

type IconTextProps = {
    fontWeight?: TypographyProps['fontWeight'],
    size?: 'small' | 'medium',
    color?: string,
    text: React.ReactNode,
    startIcon?: React.FunctionComponent<SvgIconProps>,
    endContent?: React.ReactNode,
    onClick?: StackProps['onClick'],
}

export const CartProductIconText = React.forwardRef<HTMLDivElement, IconTextProps>(
    function CartProductIconText(props, ref): JSX.Element {
        return (
            <Stack
                ref={ref}
                {...props}
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                    color: props.color,
                    minHeight: '28px'
                }}
                onClick={props.onClick}
            >
                {
                    props.startIcon !== undefined &&
                    <props.startIcon
                        fontSize={props.size ?? 'small'}
                        color="inherit"
                        sx={{ fontWeight: props.fontWeight, cursor: props.onClick !== undefined ? 'pointer' : undefined }}
                    />
                }
                <Typography
                    variant={
                        props.size === 'medium' ?
                            'body1' :
                            'caption'
                    }
                    color="inherit"
                    textTransform="uppercase"
                    fontWeight={props.fontWeight}
                    sx={{ lineHeight: 1, cursor: props.onClick !== undefined ? 'pointer' : undefined }}
                    component="div"
                >
                    {props.text}
                </Typography>
                {props.endContent}
            </Stack>
        );
    }
);
