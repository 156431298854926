import axios from "axios";
import { SetItineraryTypeDetailsLoading } from "../../../Actions/ItineraryType";
import { SetManualCart, SetCarsCart, SetFlightCart, SetHotelCart, SetIti, SetGuideList, SetTripInfo, SetTravelersGroup, SetPoiCart, SetTransfersCart } from "../../../Actions/Summary";

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import CheckResponse from "../../FlightMaterial/Functions/CheckResponse";
import CheckHotelInfo from "./CheckHotelInfo";
import CheckProducts from "./CheckProducts";

const reorderCart = (accommodations_result) => {
    if (accommodations_result === null || accommodations_result.length === 0) {
        return []
    }
    // let new_acc_cart = [];
    // accommodations_result[0].rooms = [];
    // new_acc_cart.push(accommodations_result[0]);
    let sort_cart = [];
    accommodations_result.map(item => {

        let same = false;
        sort_cart.map(sort_item => {
            if (item.is_displayed && sort_item.hotel[0].id === item.hotel[0].id && (sort_item.provider === item.provider) && sort_item.start_date === item.start_date &&
                sort_item.end_date === item.end_date && sort_item.on_request === item.on_request && sort_item.is_optional === item.is_optional && sort_item.option === item.option && sort_item.variant === item.variant &&
                ((sort_item.booking_status === null && item.booking_status === null) || (sort_item.booking_status !== null && item.booking_status !== null &&
                    ((sort_item.booking_status.status_booking !== 'CANCELLED' && item.booking_status.status_booking !== 'CANCELLED' && sort_item.booking_process_state !== 'CANCEL' && item.booking_process_state !== 'CANCEL') ||
                        (sort_item.booking_status.status_booking === 'CANCELLED' && item.booking_status.status_booking === 'CANCELLED') )) && ((sort_item.booking_status.status_booking !== 'ERROR' && item.booking_status.status_booking !== 'ERROR') ||
                        (sort_item.booking_status.status_booking === 'ERROR' && item.booking_status.status_booking === 'ERROR')))) {
                same = true;
                sort_item.rooms.push(item);
                sort_item.rooms = sort_item.rooms.sort((a, b) => {
                    return a.room_ordering - b.room_ordering
                });
            }
        });
        if (!same) {
            sort_cart.push({...item, rooms: [item]});
        }
    });

    console.log('accommodations_result:', accommodations_result);
    console.log('sort_cart:', sort_cart);
    // for (let j = 1; j < accommodations_result.length; j++) {
    //     let cart_cpy = new_acc_cart.slice();
        // let same = false;
        // for (let i = 0; i < cart_cpy.length; i++) {
        //     if(cart_cpy[i].hotel[0].id === accommodations_result[j].hotel[0].id && accommodations_result[j].start_date === cart_cpy[i].start_date && accommodations_result[j].end_date === cart_cpy[i].end_date){
        //         same = true;
        //         cart_cpy[i].rooms.push(accommodations_result[j]);
        //     }
        // }
        // if (same === true)
        //     new_acc_cart = cart_cpy;
        // if (same === false) {
        //     accommodations_result[j].rooms = [];
        //     new_acc_cart.push(accommodations_result[j]);
        // }

    // }
    return sort_cart;
}

const GetAllCart = (trip_id, trip_id_version, trip_token, dispatch, setIsLoading) => {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        let cart_request = [];
        cart_request.push(
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/flight/?token=${trip_token}`,
            })
        );
        cart_request.push(
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/car/?token=${trip_token}`,
            })
        );
        cart_request.push(
            axios({
                method: "GET",
                headers: headers,
                // url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/accommodation/?token=${trip_token}`,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/accommodation/?token=${trip_token}&full_content=true`,
            })
        );
        cart_request.push(
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/travelers-group/?token=${trip_token}&ordering=-default`,
            })
        );
        cart_request.push(
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/manual-products/?token=${trip_token}`,
            })
        );
        cart_request.push(
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/custom-products/?token=${trip_token}`,
            })
        );
        cart_request.push(
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/transfers/?token=${trip_token}`,
            })
        );
        cart_request.push(
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/?token=${trip_token}`,
            })
        );
        // cart_request.push(
        //     axios({
        //         method: "GET",
        //         headers: headers,
        //         url: `${API_HREF}client/${window.id_owner}/providers/`,
        //     })
        // );
        // cart_request.push(
        //     axios({
        //         method: "GET",
        //         headers: headers,
        //         url: `${API_HREF}client/${window.id_owner}/custom-providers/?limit=1000`,
        //     })
        // );

        axios.all([...cart_request]).then(axios.spread((...responses) => {
            let flights_result = responses[0].data.slice();
            let cars_result = responses[1].data.slice();
            let accommodations_result = responses[2].data.slice();
            let travelers_group_result = responses[3].data.results.slice();
            let manual_products_result = responses[4].data.slice();
            let custom_products_result = responses[5].data.slice();
            let transfers_result = responses[6].data.slice();
            let version_result = Object.assign({}, responses[7].data);
            // let providers = responses[8].data.results.slice();
            // let custom_providers = responses[9].data.results.slice();
            let arr = [];
            let new_accommodations_result = [];
            let transfers = [];
            let poi = [];
            let products = [];
            let cars = [];
            let flights = [];
            // dispatch({
            //     type: "SUMMARY_SET_PROVIDERS",
            //     payload: {
            //         providers: providers,
            //         custom_providers: custom_providers
            //     }
            // });
            flights_result = flights_result.sort((value) => {
                return value.is_optional ? 1 : -1;
            });
            flights_result.map((flight) => {
                if (!flight.is_hidden_for_traveler) {
                    let tmp_flight = Object.assign({}, flight);
                    tmp_flight.is_manual = false;
                    tmp_flight.is_custom = false;
                    flights.push(tmp_flight);
                }
            })
            if (flights.length > 0) {
                arr = CheckResponse(flights, version_result.end_date);
                dispatch(SetFlightCart(arr));
            }
            if (cars_result.length > 0) {
                cars_result.map((car) => {
                    if (!car.is_hidden_for_traveler) {
                        let tmp_car = Object.assign({}, car)
                        tmp_car.is_manual = false;
                        tmp_car.is_custom = false;
                        tmp_car.detail = false;
                        tmp_car.charges.sort((a, b) => {
                            return ((parseFloat(a.amount) < parseFloat(b.amount)) ? -1 : ((parseFloat(a.amount) > parseFloat(b.amount)) ? 1 : 0));
                        });
                        cars.push(tmp_car);
                    }
                });
                cars = cars.sort((value) => {
                    return value.is_optional ? 1 : -1;
                });
                dispatch(SetCarsCart(cars));
            }
            let tmp_accommodation = [];

            accommodations_result.map((accommodation) => {
                if (!accommodation.is_hidden_for_traveler) {
                    let copy_accommodation = Object.assign({}, accommodation);
                    copy_accommodation.is_manual = false;
                    copy_accommodation.is_custom = false;
                    let tmp_hotel = [];
                    copy_accommodation.hotel.map((hotel) => {
                        let copy_hotel = CheckHotelInfo(hotel);
                        tmp_hotel.push(copy_hotel);
                    });
                    copy_accommodation.hotel = tmp_hotel;
                    tmp_accommodation.push(copy_accommodation);
                }
            });
            new_accommodations_result = reorderCart(tmp_accommodation);
            new_accommodations_result = new_accommodations_result.sort((value) => {
                return value.is_optional ? 1 : -1;
            });
            dispatch(SetHotelCart(new_accommodations_result));
            dispatch(SetTravelersGroup(travelers_group_result));
            if (manual_products_result.length > 0) {
                manual_products_result.map((product) => {
                    product.is_manual = true;
                    product.is_custom = false;
                    if (Object.keys(product).length > 0 && product.product_type !== 20 && !product.is_hidden_for_traveler) {
                        products.push(product);
                    }
                    // if (product.product_type === 6) {
                    //     console.log('product:', product);
                    //     let arr = CheckResponse(product.flight_segment, version_result.end_date);
                    //     console.log('arr:', arr);
                    // }
                });
                dispatch(SetManualCart(products));
            }
            if (custom_products_result.length > 0) {
                let tmp_poi = [];
                let tmp_transfers = [];
                custom_products_result.map((el) => {
                    if (!el.is_hidden_for_traveler) {
                        el.is_manual = false;
                        el.is_custom = true;
                        if (el.product_type === 4) {
                            tmp_transfers.push(el);
                            transfers.push(el);
                        }
                        else {
                            tmp_poi.push(el);
                            poi.push(el);
                        }
                    }
                });
                dispatch(SetPoiCart(tmp_poi));
                dispatch(SetTransfersCart(tmp_transfers));
            }
            if (transfers_result.length > 0) {
                transfers_result.map((transfer) => {
                    if (!transfer.is_hidden_for_traveler) {{
                        transfer.is_manual = false;
                        transfer.is_custom = false;
                        transfers.push(transfer);
                    }}
                });
                dispatch(SetTransfersCart(transfers_result));
            }
            dispatch(SetTripInfo(version_result));
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/itinerary/?token=${trip_token}`,
            }).then(function (response) {
                let data = response.data.slice();
                let url = `${API_HREF}client/${window.id_owner}/rubrics/get_by_destinations/`;
                let has_first = false;
                for (let i = 0; i < data.length; i++) {
                    data[i].description = null;
                    if (data[i].destination !== null) {
                        if (has_first === false) {
                            url += "?dest_id=" + data[i].destination.id;
                            has_first = true;
                        } else {
                            url += "&dest_id=" + data[i].destination.id;
                        }
                    }
                }
                let departure_destination = {
                    city: null,
                    country: null,
                    place_id: null,
                    lat: null,
                    lng: null,
                    start_date: null,
                    end_date: null,
                    retrieve: true
                };
                let return_destination = {
                    city: null,
                    country: null,
                    place_id: null,
                    lat: null,
                    lng: null,
                    start_date: null,
                    end_date: null,
                    retrieve: true
                };
                for (let i = 0; i < data.length; i++) {
                    if (data[i].step_type === "START") {
                        departure_destination.place_id = data[i].places_id;
                        departure_destination.start_date = data[i].start_date;
                        departure_destination.end_date = data[i].end_date;
                    } else if (data[i].step_type === "END") {
                        return_destination.place_id = data[i].places_id;
                        return_destination.start_date = data[i].start_date;
                        return_destination.end_date = data[i].end_date;
                    }
                }
                // dispatch(SetIti(data));
                axios({
                    method: "GET",
                    headers: headers,
                    url: url,
                }).then(function (rubric_results) {
                    let data_rubric = rubric_results.data.slice();
                    dispatch(SetGuideList(data_rubric));
                    setIsLoading(false);
                }).catch(function (rubric_error) {
                    console.log('rubric error:', rubric_error);
                });
                const {tmp_selected_product, tmp_itinerary} = CheckProducts(data, arr, new_accommodations_result, transfers, cars_result, poi, products);
                // setSelectedProducts(Object.assign({}, tmp_selected_product));
                dispatch({
                    type: "SUMMARY_SET_SELECTED_PRODUCTS_ITINERARY",
                    payload: {
                        products: tmp_selected_product,
                        itinerary: tmp_itinerary
                    }
                });
            }).catch(function (error) {
                console.log('itinerary error:', error);
            })
        })).catch(function (error) {
            console.log('error traveler:', error);
                //TODO: show snackbar error
        });
    }
}
export default GetAllCart;