//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Notify from "../../Common/Notify.js";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

import CheckBeforeRequest from '../../Common/CheckBeforeRequest';

const useStyles = makeStyles(theme => ({
    fontSize12: {
        fontSize: "12px !important",
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
}));

const DeleteFiltersDialog = ({deleteFiltersOpen, handleDeleteFiltersOpen, id, setDeleteId}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const customFilters = useSelector(state => state.menu.customFilters);

    const handleReset = () => {
        setDeleteId(null);
        handleDeleteFiltersOpen();
    }
    const handleDelete = () => {
        const { headers } = CheckBeforeRequest();
        if (id !== null) {
            const filter_to_delete = customFilters.find((el) => el.id === id);
            let url = filter_to_delete.filters_type === 'user' ? `${API_HREF}client/${window.id_owner}/user/${user.id}/trip-filter-preference/${id}/` : `${API_HREF}client/${window.id_owner}/trip-filter-preference/${id}/`
            axios({
                method: 'DELETE',
                headers: headers,
                url: url,
            }).then((response) => {
                Notify(t("menu.user_management.delete_filter_success"), {status: 'success', pos: 'top-center'});
                dispatch({
                    type: "MENU_DELETE_CUSTOM_FILTER",
                    payload: id
                });
                handleReset();
            }).catch((error) => {
                console.log(error);
            });
        }
    }
    return (
        <Dialog open={deleteFiltersOpen} onClose={handleReset} fullWidth maxWidth={ "md" }>
            <DialogTitle>{ t("menu.user_management.delete_filter") }</DialogTitle>
            <DialogContent>
                <DialogContentText>{t("menu.user_management.delete_filter_confirmation")}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} color={"primary"} fullWidth className={`${classes.fontSize12} ${classes.noWrap}`} onClick={handleReset}>{t("global.cancel")}</Button>
                <Button variant={"outlined"} color={"primary"} fullWidth className={`${classes.fontSize12} ${classes.noWrap}`} onClick={handleDelete}>{t("global.validate")}</Button>
            </DialogActions>
        </Dialog>
    )
}
export default DeleteFiltersDialog;