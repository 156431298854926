import { Invoice } from "../objects/invoice";

type Anomaly = 'CREDIT_NOTES_DONT_MATCH'

export function detectInvoicesAnomalies(source: Invoice[] | null): Anomaly[] {
    const invoices = source?.filter((invoice) => invoice.invoice_type === 'INVOICE') ?? [];
    const creditNotes = source?.filter((invoice) => invoice.invoice_type === 'CREDIT_NOTE') ?? [];
    const creditNotesDontMatch = invoices.length !== creditNotes.length;
    return [
        creditNotesDontMatch && 'CREDIT_NOTES_DONT_MATCH'
    ].filter((item) => item) as Anomaly[];
}
