//---- Dependencies ----//
import React from 'react';
import Slider from 'react-slick';

//---- Actions ----//
import { ToggleRoomPictureModal } from "../../Actions/Accommodation";

class RoomPicturesSlider extends React.Component {
    constructor(props, context) {
        super(props, context);
        const { type } = this.props;
        this.state = {
            height: type === "modal" ? 550 : 270,
            settings: {
                dots: false,
                arrows: true,
                fade: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                cssEase: "linear",
                adaptiveHeight: false
            }
        }
    }
    componentDidMount() {
        const { type, index } = this.props;
        if (type === "card") {
            let height = $('#height-for-img-room-hotel' + index).height();
            console.log(height);
            this.setState({height: 200});
        }
    }
    render() {
        const { dispatch, index, pictures, list } = this.props;
        return (
            <div id="room-pictures-slider">
                <Slider { ...this.state.settings }>
                {
                    pictures.map((img, img_index) => {
                        return (
                            <div key={img_index} >
                                <img className={ list === undefined ? "accommodation-card-choose-room-room-picture" : "accommodation-card-choose-room-room-picture-grid-list"} style={{height: this.state.height}} onClick={() => { dispatch(ToggleRoomPictureModal(true, pictures, index)) }} src={ (img.thumbnail_big !== undefined && img.thumbnail_big !== null) ? img.thumbnail_big : ((img.url !== undefined && img.url !== null) ? img.url : "/Img/accommodation_default.jpg") } height={200}/>
                            </div>
                        )
                    })
                }
                {
                    pictures.length === 0 && (
                        <div>
                            <img className={ list === undefined ? "accommodation-card-choose-room-room-picture" : "accommodation-card-choose-room-room-picture-grid-list"} style={{height: this.state.height}} src={ "/Img/accommodation_default.jpg" } height={200}/>
                        </div>
                    )
                }
                </Slider>
            </div>
        );
    }
}

export default RoomPicturesSlider;