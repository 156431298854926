import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import { StaticDateRangePicker, LocalizationProvider } from "@material-ui/pickers";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from "moment";
import { useSnackbar } from "notistack";

import DatesChange from "../Functions/DatesChange";

const useStyles = makeStyles(theme => ({
    formControlRoot: {
        borderRadius: '0',
        borderLeft: 0,
        borderRight: 0,
        padding: '6px 13px !important',
        position: "relative",
        textTransform: "capitalize",
        height: 41
    },
    datepickerStyle: {
        "& .mui-jss-MuiInputLabel-outlined": {
            transform: 'translate(14px, 13px) scale(1)',
            [theme.breakpoints.between(768, 1440)]: {
                fontSize: '14px',
            }
        },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '9.5px 20px',
            borderRadius: '0',
            textAlign: "center",
            cursor: "pointer"
        }
    },
    datepickerStyleLeft: {
        "&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: "20px 0 0 20px",
        }
    },
    datepickerStyleRight: {
        "&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: "0 20px 20px 0",
        }
    },
    paperStepper: {
        position: "absolute !important",
        top: "100% !important",
        zIndex: "1 !important",
        background: "rgb(255, 255, 255) !important",
        borderRadius: "32px !important",
        boxShadow: "rgb(0 0 0 / 20%) 0px 6px 20px !important",
        marginTop: "12px !important",
        maxHeight: "calc(100vh - 220px) !important",
        padding: "16px 32px !important"
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
}));

const MobileHeaderDatePicker = ({ dialog_type, setDialogType }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const accommodation_cart = useSelector((store) => {
        return store.accommodation.cart;
    });
    const car_cart = useSelector((store) => {
        return store.cars_search.cart;
    });
    const flight_cart = useSelector((store) => {
        return store.flight.cart;
    });
    const manual_item_list = useSelector((store) => {
        return store.cart.manual_item_list;
    });
    const trip_start_date = useSelector((store) => {
        return store.trip.start_date;
    });
    const trip_end_date = useSelector((store) => {
        return store.trip.end_date;
    });
    const user = useSelector(store => store.user.user);
    const itinerary = useSelector((store) => {
        return store.itinerary.itinerary_list;
    });
    const language = useSelector(state => state.header.language);
    const [value, setValue] = useState([trip_start_date !== null ? trip_start_date : new Date(), trip_end_date !== null ? trip_end_date : new Date()]);

    return (
        <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale={language}>
            {/* <DialogTitle>
                <Tooltip title={t("header.departure_date_tooltip")}>
                    <TextField
                        className={`${classes.datepickerStyle} ${classes.datepickerStyleLeft}`}
                        label={""}
                        variant={"outlined"}
                        helperText={""}
                        value={value[0] !== null ? moment(value[0]).format("DD/MM/YYYY") : moment(value[1]).format("DD/MM/YYYY")}
                        onClick={() => {setDatePickerState("start")}}
                        inputRef={inputRefStart}
                        InputProps={{
                            readOnly: true,
                        }}
                        />
                </Tooltip>
                <Tooltip title={t("header.arrival_date_tooltip")}>
                    <TextField
                        className={`${classes.datepickerStyle} ${classes.datepickerStyleRight}`}
                        label={""}
                        variant={"outlined"}
                        helperText={""}
                        value={value[1] !== null ? moment(value[1]).format("DD/MM/YYYY") : moment(value[0]).format("DD/MM/YYYY")}
                        onClick={() => {setDatePickerState("end")}}
                        inputRef={inputRefEnd}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Tooltip>
            </DialogTitle> */}
            <StaticDateRangePicker
                startText={t("header.responsive.start")}
                endText={t("header.responsive.end")}
                displayStaticWrapperAs={isTablet ? "desktop" : "mobile"}
                toolbarTitle={""}
                value={value}
                calendars={2}
                open={true}
                onChange={(newValue) => {
                    console.log('newValue:', newValue);
                    setValue(newValue)
                }}
                // currentlySelectingRangeEnd={datePickerState}
                // setCurrentlySelectingRangeEnd={(newValue) => {
                //     setDatePickerState(newValue);
                // }}
                variant={"inline"}
                renderInput={(startProps, endProps) => (
                    <Fragment>
                        <TextField
                            {...startProps}
                            className={`${classes.datepickerStyle}`}
                            label={""}
                            variant={"outlined"}
                            helperText={""}
                        />
                        <TextField
                            {...endProps}
                            className={`${classes.datepickerStyle}`}
                            label={""}
                            variant={"outlined"}
                            helperText={""}
                        />
                    </Fragment>
                )}
            >
            </StaticDateRangePicker>
            <DialogActions>
                <Button onClick={() => {
                    setDialogType(null);
                }}>{t("accommodation.cancel")}</Button>
                <Button variant={"contained"} className={classes.orangeButton} onClick={() => {
                    DatesChange(accommodation_cart, car_cart, flight_cart, manual_item_list, trip_start_date, trip_end_date, { startDate: value[0], endDate: value[1] }, itinerary, user, dispatch, t, enqueueSnackbar);
                    setDialogType(null);
                }}>{t("global.validate")}</Button>
            </DialogActions>
        </LocalizationProvider>
    )
}
export default React.memo(MobileHeaderDatePicker);