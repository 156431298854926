import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    Stack,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import axios from "axios";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { useShowError } from "../Utils/showError";
import GetCookie from "../Common/Functions/GetCookie";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    onClose: () => void
}

export function CartConstructionDaveModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const [data, setData] = useState<{
        id: number,
        date: string,
        version: number,
        filename: string | null,
        trip: number,
        trip_version: number
    }[]>([]);
    const [loading, setLoading] = useState(false);
    const showError = useShowError();

    useEffect(() => {
        const { pass_check, headers } = CheckBeforeRequest();
        const version = parseInt(GetCookie('trip_id_version') ?? '-1');
        if (pass_check) {
            setLoading(true);
            axios({
                method: "GET",
                url: `${API_HREF}client/${window.id_owner}/trip/${tripId}/versions/${version}/dave-mcto/`,
                headers: headers
            }).then(function (response) {
                setData(response.data.results);
            }).catch((error) => {
                showError(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, []);

    return (
        <>
            <Dialog open onClose={props.onClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography fontWeight="bold">
                            {t<string>('cart-material.cart-construction-dave-list')}
                        </Typography>
                        <IconButton onClick={props.onClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    {
                        data.length === 0 &&
                        <Alert severity="info">
                            {t<string>('shared.no-results-found')}
                        </Alert>
                    }
                    <List>
                        {
                            data.map((item) => {
                                return (
                                    <ListItem key={item.id}>
                                        {
                                            t<string>(
                                                'cart-material.cart-construction-dave-list-item',
                                                {
                                                    no: item.version,
                                                    date: window.moment.utc(item.date).format("L LT")
                                                }
                                            )
                                        }
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                </DialogContent>
            </Dialog>
            <LoadingBackDrop open={loading} />
        </>
    );
}
