import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Autocomplete, CircularProgress, styled, TextField } from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import axios, { CancelTokenSource } from "axios";
import { debounce } from "lodash";
import { getQuickDestination } from "./utils/getQuickDestination";
import { getLightDestinationName } from "./utils/getLightDestinationName";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { LightDestination } from "./objects/lightDestination";
import { ReducedLightDestination } from "./objects/reducedLightDestination";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    label?: string,
    placeholder?: string,
    loading: boolean,
    onChoose: (destination: LightDestination) => void
} & Pick<Parameters<typeof Autocomplete>[0], 'sx'>

export function ItineraryTransportItemAddInput(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const isUserTO = useSelector((state: AppState) => state.user.user?.client_full?.type !== 2);
    const [options, setOptions] = useState<ReducedLightDestination[]>([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [value, setValue] = useState<ReducedLightDestination | null>(null);
    const cancelToken = useRef<CancelTokenSource | null>(null);
    const fireNetworkRequest = useCallback(
        debounce(async (search: string, isUserTO: boolean) => {
            try {
                setLoading(true);
                const { pass_check, headers } = CheckBeforeRequest();
                if (pass_check) {
                    cancelToken.current?.cancel('Request cancelled.');
                    cancelToken.current = axios.CancelToken.source();
                    const response = await axios.get<ReducedLightDestination[]>(
                        `${API_HREF}client/${window.id_owner}/destinations/quick_search/`,
                        {
                            headers,
                            cancelToken: cancelToken.current.token,
                            params: {
                                limit: 10,
                                ordering: 'current_version__type',
                                current_version__type__in: '4',
                                search,
                                reduced: true,
                                visibility__in: isUserTO ?
                                    'PUBLIC,PRIVATE_TO' :
                                    'PUBLIC'
                            }
                        }
                    );
                    setOptions(response.data);
                }
            } catch (error: any) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }, 500),
        [setLoading, setOptions]
    );

    const onChoose = async (destination: ReducedLightDestination) => {
        const data = await getQuickDestination(destination.id);

        if (data) {
            setValue(destination);
            props.onChoose({
                ...data,
                id: destination.id,
                bounds: JSON.parse(data.bounds?.replace(/'/g, '"') ?? '{}'),
                latitude: parseFloat(data.latitude),
                longitude: parseFloat(data.longitude),
                type_dest: data.type,
                parent_international_name: '',
                parent_name: []
            });
        }
    };

    useEffect(() => {
        if (search.trim().length !== 0) {
            fireNetworkRequest(search, isUserTO);
        } else {
            setOptions([]);
            setLoading(false);
        }
    }, [search, isUserTO]);

    return (
        <Autocomplete
            value={value}
            loading={loading}
            options={options}
            filterOptions={(options) => options}
            getOptionLabel={(option) => option.international_name}
            renderInput={(params) => (
                <AddStepInput
                    {...params}
                    value={search}
                    label={ props.label ?? t<string>('itinerary.add-step') }
                    placeholder={ props.placeholder ?? t<string>('itinerary.step-name') }
                    size="small"
                    InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {
                                    props.loading ?
                                        <CircularProgress color="inherit" size={20} /> :
                                        null
                                }
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                    autoFocus
                    fullWidth
                />
            )}
            renderOption={(props, option) => (
                <li {...props}>
                    <LocationOn sx={{ marginRight: 1 }} />
                    {getLightDestinationName(locale, option)}
                </li>
            )}
            componentsProps={{
                popper: {
                    sx: (theme) => ({
                        zIndex: theme.zIndex.tooltip + 1
                    })
                }
            }}
            disabled={props.loading}
            forcePopupIcon={!props.loading}
            sx={props.sx}
            onInputChange={(_, value) => setSearch(value)}
            onChange={(_, value) => value && onChoose(value)}
            fullWidth
        />
    );
}

const AddStepInput = styled(TextField)((props) => ({
    '.MuiInputBase-input': {
        "paddingTop": props.theme.spacing(1),
        "paddingBottom": props.theme.spacing(1),
        "fontSize": 12,
        '&::placeholder': {
            fontSize: 12
        }
    }
}));
