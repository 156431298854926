/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import CrmUserDetailActivityMonth from "./CrmUserDetailActivityMonth";
import '../../crm.css';
import moment from "moment";
import { SetSelectedNote, SetSelectedEmail, SetActiveUserNotes } from '../../../../../Actions/Crm';
import TripListCommunication from '../../../MaterialTripList/TripListCommunication';
import { getUserEmails, getUserNotes } from "../../Functions/UserFunctions";
import _ from 'lodash';
import Axios from "axios";
import CheckBeforeRequest from "../../../../Common/CheckBeforeRequest";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        "id": `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const CrmUserDetailActivity = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const active_user_notes = useSelector(store => store.crm.active_user_notes);
    const user = useSelector(store => store.user.user);
    const selected_user = useSelector(store => store.crm.selected_user);

    const [value, setValue] = React.useState(0);
    const [emails, setEmails] = React.useState([]);
    const [mappedNotes, setMappedNotes] = React.useState([]);
    const [mappedRemarks, setMappedRemarks] = React.useState([]);
    const [mappedEmails, setMappedEmails] = React.useState([]);
    const [mappedAllActivities, setMappedAllActivities] = React.useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const searchInput = () => {
        return (
            <TextField
                label={t("crm.search")}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
                variant="outlined"
                size="small"
            />
        );
    };

    const createNote = (is_comment) => {
        dispatch(SetSelectedNote({ id: -1, is_comment }));
    };
    const createEmail = () => {
        dispatch(SetSelectedEmail({ id: -1 }));
    };

    useEffect(() => {
        if (active_user_notes && active_user_notes.notes?.length) {
            const mapped = active_user_notes.notes?.filter(n => !n.is_comment)?.map(e => {
                return { ...e, date: e.created_date, title: e.is_comment ? t('crm.title.remark') : t('crm.title.note'), type: "note" };
            });
            const grouped = _.groupBy(mapped, ({ created_date }) => new Date(created_date).getMonth());
            const final_mapped = [];
            for (const [key, value] of Object.entries(grouped)) {
                final_mapped.push(value);
            }
            setMappedNotes(final_mapped.reverse());
            const mapped_rem = active_user_notes.notes?.filter(n => n.is_comment)?.map(e => {
                return { ...e, date: e.created_date, title: e.is_comment ? t('crm.title.remark') : t('crm.title.note'), type: "note" };
            });
            const grouped_rem = _.groupBy(mapped_rem, ({ created_date }) => new Date(created_date).getMonth());
            const final_mapped_rem = [];
            for (const [key, value] of Object.entries(grouped_rem)) {
                final_mapped_rem.push(value);
            }
            setMappedRemarks(final_mapped_rem.reverse());
        }
    }, [active_user_notes]);

    /**MAIL */
    //send mail
    function makeRequest(mail) {
        let { pass_check, headers } = CheckBeforeRequest();

        if (pass_check) {
            console.log('SENDING ...', mail);
            return Axios.post(
                `${API_HREF}client/${user.client}/send_email/`,
                {
                    subject: mail.object,
                    email_content: mail.content,
                    recipient: mail.to,
                    sender_email: mail.from.email,
                    sender_name: mail.from.name.normalize("NFD").replace(/\p{Diacritic}/gu, ""),
                    ccs: mail.cc,
                    user: selected_user?.id
                },
                { headers }
            );
        }
        return new Promise((resolve) => resolve());
    }

    //HANDLE PORPS ACTIONS
    useEffect(() => {
        if (props.action) {
            switch (props.action) {
                case "note":
                    handleChange(null, 1);
                    createNote(false);
                    break;
                case "remark":
                    handleChange(null, 2);
                    createNote(true);
                    break;
                case "email":
                    handleChange(null, 3);
                    break;
                default:
                    break;
            }
        }
    }, [props.action]);

    useEffect(() => {
        if (user && selected_user) {
            getUserEmails(user.client, selected_user.id, (resp) => {
                console.log('USER EMAILS', resp.data);
                const active_user_emails = resp.data;
                if (active_user_emails?.length) {
                    setEmails(active_user_emails);
                    const mapped = active_user_emails.map(e => {
                        return { ...e, date: e.created_date, title: t('crm.title.email'), type: "email" };
                    });
                    const grouped = _.groupBy(mapped, ({ created_date }) => new Date(created_date).getMonth());
                    const final_mapped = [];
                    for (const [key, value] of Object.entries(grouped)) {
                        final_mapped.push(value);
                    }
                    setMappedEmails(final_mapped.reverse());
                }
            });
        }

        if (selected_user?.id && (!active_user_notes || !active_user_notes.user_id !== selected_user.id)) {
            getUserNotes(user.client, selected_user.id, (response) => {
                dispatch(SetActiveUserNotes(selected_user.id, response.data));
            });
        }
    }, [user, selected_user]);

    useEffect(() => {
        let all = [];
        if (active_user_notes?.notes?.length) {
            all = [...all, ...active_user_notes.notes];
        }
        if (emails?.length) {
            all = [...all, ...emails];
        }
        const mapped = all.map(e => {
            let type = "note";
            let title = e.is_comment ? t('crm.title.remark') : t('crm.title.note');
            if (e.sender) {
                title = t('crm.title.email');
                type = "email";
            }
            return { ...e, date: e.created_date, title, type };
        });
        const grouped = _.groupBy(mapped, ({ created_date }) => new Date(created_date).getMonth());
        const final_mapped = [];
        for (const [key, value] of Object.entries(grouped)) {
            final_mapped.push(value);
        }
        setMappedAllActivities(final_mapped.reverse());
    }, [emails, active_user_notes]);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label={t("crm.title.activity")} {...a11yProps(0)} />
                    <Tab label={t("crm.title.notes")} {...a11yProps(1)} />
                    <Tab label={t("crm.title.remarks")} {...a11yProps(2)} />
                    <Tab label={t("crm.title.emails")} {...a11yProps(3)} />
                    <Tab label={t("crm.title.tasks")} {...a11yProps(4)} />
                    <Tab label={t("crm.title.meetings")} {...a11yProps(5)} />
                    <Tab label={t("crm.title.calls")} {...a11yProps(6)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} className="activities-tab">
                <div className="activity-header">
                    {searchInput()}
                </div>
                <div className="activity-content">
                    {mappedAllActivities?.map((monthActivities) => {
                        const first_el_date = monthActivities[0] ? new Date(monthActivities[0].created_date) : new Date();
                        return <CrmUserDetailActivityMonth title={moment(first_el_date).format("MMMM YYYY")} activities={monthActivities} />;
                    })}
                </div>
            </TabPanel>
            <TabPanel value={value} index={1} className="activities-tab">
                <div className="activity-header">
                    {searchInput()}
                    <Button variant="contained" onClick={() => createNote(false)}>{t("crm.create_note")}</Button>
                </div>
                <div className="activity-content">
                    {mappedNotes?.map((monthNotes) => {
                        const first_el_date = monthNotes[0] ? new Date(monthNotes[0].created_date) : new Date();
                        return <CrmUserDetailActivityMonth title={moment(first_el_date).format("MMMM YYYY")} activities={monthNotes} />;
                    })}
                </div>
            </TabPanel>
            <TabPanel value={value} index={2} className="activities-tab">
                <div className="activity-header">
                    {searchInput()}
                    <Button variant="contained" onClick={() => createNote(true)}>{t("crm.create_remark")}</Button>
                </div>
                <div className="activity-content">
                    {mappedRemarks?.map((monthNotes) => {
                        const first_el_date = monthNotes[0] ? new Date(monthNotes[0].created_date) : new Date();
                        return <CrmUserDetailActivityMonth title={moment(first_el_date).format("MMMM YYYY")} activities={monthNotes} />;
                    })}
                </div>
            </TabPanel>
            <TabPanel value={value} index={3} className="activities-tab">
                <div className="activity-header">
                    {searchInput()}
                    {/* <Button variant="contained" onClick={createEmail}>{t("crm.create_email")}</Button> */}
                </div>
                <div className="activity-content activity-mail-content">
                    <TripListCommunication customData={{ isFromCrm: true, callback: makeRequest }} />
                    {mappedEmails?.map((monthEmails) => {
                        const first_el_date = monthEmails[0] ? new Date(monthEmails[0].created_date) : new Date();
                        return <CrmUserDetailActivityMonth title={moment(first_el_date).format("MMMM YYYY")} activities={monthEmails} />;
                    })}
                </div>
            </TabPanel>
            <TabPanel value={value} index={4} className="activities-tab">
                <div className="activity-header">
                    {searchInput()}
                    <Button variant="contained">{t("crm.create_task")}</Button>
                </div>
            </TabPanel>
            <TabPanel value={value} index={5} className="activities-tab">
                <div className="activity-header">
                    {searchInput()}
                    <Button variant="contained">{t("crm.create_meeting")}</Button>
                </div>
            </TabPanel>
            <TabPanel value={value} index={6} className="activities-tab">
                <div className="activity-header">
                    {searchInput()}
                    <Button variant="contained">{t("crm.create_call")}</Button>
                </div>
            </TabPanel>
        </>
    );
};
export default React.memo(CrmUserDetailActivity);
