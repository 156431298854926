import axios from "axios";
import memoizee from "memoizee";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

async function get(destinationId: number): Promise<string[]> {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.get<string>(
            `${API_HREF}client/${window.id_owner}/destinations/${destinationId}/get_inheritance/`,
            {
                headers,
                params: {
                    id: destinationId
                }
            }
        );
        return response.data.split(',');
    }

    return [];
}

export const getDestinationInheritance = memoizee(
    get,
    { promise: true, primitive: true }
);
