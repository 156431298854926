//---- Dependencies ----//
import i18n from "../../i18n.jsx";

//---- Common ----//
import GetCookie from "./Functions/GetCookie";

export default () => {
    let pass_check = true;
    let headers = null;
    if (GetCookie("token") === "") {
        headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Accept-Language": i18n.language
        };
    } else {
        headers = {
            "Authorization": "Token " + GetCookie("token"),
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Accept-Language": i18n.language
        };
    }
    return {
        pass_check, headers
    }
}