//---- Dependencies ----//
import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Slider from "react-slick";

//---- Common ----//

//---- Component ----//
import CarsResultsHeader from "./CarsResultsHeader.jsx";
import CarsResultsMatrix from "./CarsResultsMatrix.jsx";

//---- Functions ----//
import CheckAvailability from "../Functions/CheckAvailability";
import GetMinPrice from "../Functions/GetMinPrice";

//---- Actions ----//
import { ResetCurrentCart, SetCarsCategories, InitCarsResult, SetIndexLocation, SetCarIndex, FiltersCars, ClearData } from "../../../Actions/CarsSearch";

//---- CSS ----//
import "../../../Style/FlightGroups.css";
import "../../../Style/FlightSearch.css";
import "../../../Style/CarsSearch.css";

@connect((store) => {
    return {
    	step_info: store.cars_search.step_info,
    	cars_results: store.cars_search.cars_results,
    	copy_location_index: store.cars_search.copy_location_index,
    	copy_car_index: store.cars_search.copy_car_index,
    	provider_list: store.trip.providers,
    	cars_categories: store.cars_search.cars_categories,
    	selected_provider: store.cars_search.selected_provider,
		selected_category: store.cars_search.selected_category,
		filters_status: store.cars_search.filters_status,
		check_availability: store.cars_search.check_availability,
		user: store.user.user
    }
})
@withTranslation()
export default class CarsResult extends React.Component {
	componentWillMount() {
		const { step_info, check_availability, copy_car_index, copy_location_index, cars_results, provider_list, user, dispatch, t } = this.props;
		if (check_availability === null) {
			dispatch(InitCarsResult());
			CheckAvailability(step_info, copy_car_index, copy_location_index, provider_list, user, dispatch, t);
		}
		else {
			dispatch(ClearData());
		}
	    dispatch(SetIndexLocation(copy_location_index));
	    dispatch(SetCarIndex(copy_car_index));
	}
	componentDidUpdate(prevProps, prevState) {
		const { cars_results, cars_categories, selected_category, selected_provider, filters_status, dispatch } = this.props;
		if (prevProps.cars_results.length !== cars_results.length) {
			let tmp_categories = cars_categories.slice();
			tmp_categories.map((category) => {
				const { min, currency } = GetMinPrice(cars_results, category.name);
				category.min_price = min;
				category.min_currency = currency;
			});
			if (filters_status === "update") {
				dispatch(FiltersCars(selected_provider, selected_category));
			}
			dispatch(SetCarsCategories(tmp_categories));
		}
	}
	redirection() {
		const {router, dispatch}= this.props;
		dispatch(ResetCurrentCart());
		this.props.params.id_step === "0" ? router.push(`/${window.url_name}/apps/cars-search-one-step`) : router.push(`/${window.url_name}/apps/cars-search`)
	}
	render() {
		const { t } = this.props;
		return (
			<div id={ "cars-search-wrapper" } className={ "cars-search ft-beige-bg" }>
				<div className="cars-main-container">
					<button className="ft-btn-return ft-white mt15 ft-ml25" onClick={() => {
						this.redirection()
					}}>
						{t("flight_search.edit_search")}
					</button>
					<CarsResultsHeader />
					<CarsResultsMatrix />
				</div>
			</div>
		);
	}
}