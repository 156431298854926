import { isNumber } from "lodash";
import { ItineraryInput } from "../objects/itineraryState";
import { Transport } from "../objects/transport";

export function takeBestTransport(
    step: ItineraryInput,
    nextStep: ItineraryInput,
    availableTransports: Transport[]
): {
    transport: Transport,
    alternative: Transport['alternatives'][number]
} | null {
    const stepDelta = Math.abs(
        window.moment.utc(step.end_date).diff(
            window.moment.utc(step.end_date).startOf('day'),
            'seconds'
        )
    );

    for (const transport of availableTransports) {
        const bestAlternatives = transport.alternatives.filter((item) => {
            return !transport.types.includes('plane') ||
                item.operatingDays.includes(parseInt(window.moment.utc(step.end_date).format('d')));
        }).sort((a, b) => {
            if (
                isNumber(nextStep.circuit) &&
                nextStep.circuit_start_date &&
                (
                    step.circuit !== nextStep.circuit ||
                    step.circuit_trip_version !== nextStep.circuit_trip_version
                )
            ) {
                const aDuration = a.steps.reduce((prev, current) => {
                    return prev + current.duration;
                }, 0);
                const bDuration = b.steps.reduce((prev, current) => {
                    return prev + current.duration;
                }, 0);
                const aDiff = window.moment.utc(nextStep.start_date).diff(
                    window.moment.utc(step.end_date).add(
                        aDuration,
                        'minutes'
                    )
                );
                const bDiff = window.moment.utc(nextStep.start_date).diff(
                    window.moment.utc(step.end_date).add(
                        bDuration,
                        'minutes'
                    )
                );
                return aDiff <= 0 && aDiff < bDiff ? -1 : 1;
            }
            const aDelta = Math.abs(stepDelta - (a.steps[0]?.departureTime ?? 0));
            const bDelta = Math.abs(stepDelta - (b.steps[0]?.departureTime ?? 0));
            return aDelta < bDelta ? -1 : 1;
        });

        if (bestAlternatives[0]) {
            return { transport, alternative: bestAlternatives[0] };
        }
    }

    const defaultTransport = availableTransports[0];
    const defaultAlternative = defaultTransport?.alternatives[0];
    if (defaultTransport && defaultAlternative) {
        return { transport: defaultTransport, alternative: defaultAlternative };
    }

    return null;
}
