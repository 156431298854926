import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Grid, Stack, styled, Tab, Tabs } from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useHotkeys } from 'react-hotkeys-hook';
import { MailTemplateVisualEditorDragArea } from "./mailTemplateVisualEditorDragArea";
import { MailTemplateVisualEditorOptionsArea } from "./mailTemplateVisualEditorOptionsArea";
import { MailTemplateVisualEditorPreview } from "./mailTemplateVisualEditorPreview";
import { MailTemplateVisualEditorUndoRedo } from "./mailTemplateVisualEditorUndoRedo";
import { MailTemplateVisualEditorScrollIndicator } from "./mailTemplateVisualEditorScrollIndicator";
import { MailTemplateVisualEditorMode } from "./mailTemplateVisualEditorMode";
import { renderHtml } from "./utils/render-html";
import {
    MailTemplateVisualEditorContext,
    MailTemplateVisualEditorProps
} from "./mailTemplateVisualEditorContext";
import {
    createVisualEditorLocalInitialContent,
    redoVisualEditor,
    restoreVisualEditorBlocksFromHtml,
    undoVisualEditor
} from "./redux/actions";
import { AppState } from "../../../../Reducers/Reducers";
import "./blocks";
import "./css/index.css";

type Props = {
    renderContentArea?: () => JSX.Element,
    defaultHtml?: string
} & MailTemplateVisualEditorProps

export function MailTemplateVisualEditor(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [tab, setTab] = useState(0);
    const locale = useSelector((state: AppState) => props.locale ?? state.locale.current_locale);
    const contentContainerRef = useRef<HTMLDivElement>(null);
    const quotationCode = JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code;

    useHotkeys('ctrl+z', () => {
        dispatch(undoVisualEditor());
    });
    useHotkeys('ctrl+y', () => {
        dispatch(redoVisualEditor());
    });

    const onChangeTab = (event: React.SyntheticEvent<Element, Event>, tabNumber: number) => {
        setTab(tabNumber);
    };

    useEffect(() => {
        if (locale !== null && !props.disableAutoCreation) {
            dispatch(createVisualEditorLocalInitialContent(props.instanceId, locale));
        }
    }, [props.instanceId, props.disableAutoCreation, locale]);

    useEffect(() => {
        if (props.defaultHtml && locale !== null) {
            dispatch(restoreVisualEditorBlocksFromHtml({
                instanceId: props.instanceId,
                locale,
                html: props.defaultHtml,
                quotationCode
            }));
        }
    }, [props.defaultHtml]);

    const MainContent = (
        <Container>
            {
                !props.disableHeader &&
                <Header>
                    <TabsHeader value={tab} onChange={onChangeTab}>
                        <Tab label={t<string>('shared.mail-template-visual-editor-build-content')} />
                        {
                            !props.disablePreview &&
                            <Tab label={t<string>('shared.mail-template-visual-editor-preview-content')} />
                        }
                    </TabsHeader>
                    <Stack direction="row" spacing={1.5}>
                        <MailTemplateVisualEditorMode />
                        <MailTemplateVisualEditorUndoRedo />
                    </Stack>
                </Header>
            }
            {
                props.renderContentArea && tab === 0 &&
                props.renderContentArea()
            }
            {
                !props.renderContentArea && tab === 0 &&
                <ContentContainer ref={contentContainerRef} container spacing={2}>
                    <Content item xs={5} sx={{ position: 'sticky', top: 0 }}>
                        <MailTemplateVisualEditorOptionsArea />
                    </Content>
                    <Content item xs={7}>
                        <MailTemplateVisualEditorDragArea />
                    </Content>
                    <MailTemplateVisualEditorScrollIndicator
                        ref={contentContainerRef}
                        position="top"
                    />
                    <MailTemplateVisualEditorScrollIndicator
                        ref={contentContainerRef}
                        position="bottom"
                    />
                </ContentContainer>
            }
            {
                tab === 1 &&
                <Box sx={{ height: '70vh' }}>
                    <PreviewWrapper />
                </Box>
            }
        </Container>
    );
 
    return (
        <MailTemplateVisualEditorContext.Provider value={props}>
            {
                props.useCustomDndContext &&
                MainContent
            }
            {
                !props.useCustomDndContext &&
                <DndProvider backend={HTML5Backend}>
                    {MainContent}
                </DndProvider>
            }
        </MailTemplateVisualEditorContext.Provider>
    );
}

function PreviewWrapper(): JSX.Element | null {
    const context = useContext(MailTemplateVisualEditorContext);
    const locale = useSelector((state: AppState) => context.locale ?? state.locale.current_locale);
    const quotationCode = useSelector((state: AppState) => state.header.quotation_code);
    const bodyStyles = useSelector((state: AppState) => {
        return state.mailTemplate.visualEditor.present.instances[context.instanceId]?.visualEditorBodyStyles;
    });
    const blocks = useSelector((state: AppState) => {
        return locale !== null ?
            state.mailTemplate.visualEditor.present.instances[context.instanceId]?.visualEditorBlocks[locale] :
            null;
    }) ?? { order: [], blocks: {} };

    if (bodyStyles) {
        return (
            <MailTemplateVisualEditorPreview
                html={renderHtml(bodyStyles, blocks, quotationCode)}
                disableMode
            />
        );
    }

    return null;
}

const Container = styled('div')(() => ({
    width: '100%',
    height: '100%'
}));

const ContentContainer = styled(Grid)(() => ({
    height: 'calc(100% - 56px)',
    maxHeight: 'calc(100% - 56px)',
    overflow: 'auto',
    direction: 'rtl',
    marginTop: 0,
    border: '1px solid #ccc'
}));

const Content = styled(Grid)(() => ({
    height: '100%',
    paddingTop: '0 !important',
    direction: 'ltr'
}));

const Header = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between'
}));

const TabsHeader = styled(Tabs)((props) => ({
    marginBottom: props.theme.spacing(1)
}));
