import React from "react";
import { ButtonProps, IconButton, styled } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

type Props = {
    direction: 'left' | 'right',
    position: 'left' | 'right',
    sx?: ButtonProps['sx'],
    onClick?: ButtonProps['onClick']
}

export function CartConstructionViewSwitchButton(props: Props): JSX.Element {
    return (
        <SwitchButton
            size="small"
            className={`cart-construction-view-switch-button ${props.direction}`}
            color="primary"
            direction={props.position}
            sx={props.sx}
            onClick={props.onClick}
        >
            {
                props.direction === 'left' &&
                <ChevronLeft />
            }
            {
                props.direction === 'right' &&
                <ChevronRight />
            }
        </SwitchButton>
    );
}

const SwitchButton = styled(
    IconButton,
    {
        shouldForwardProp(propName) {
            return propName !== 'direction';
        }
    }
)<{
    direction: Props['direction'],
}>((props) => ({
    "position": 'absolute',
    "top": '50%',
    "left": props.direction === 'left' ?
        0 :
        undefined,
    "right": props.direction === 'right' ?
        0 :
        undefined,
    "transform": props.direction === 'left' ?
        'translate(7.5px, -50%) scale(0)' :
        'translate(50%, -50%) scale(0)',
    "transition": 'all .2s ease-in-out',
    "backgroundColor": '#2196F3',
    "color": props.theme.palette.getContrastText('#2196F3'),
    '&:hover': {
        backgroundColor: '#ccc',
        color: props.theme.palette.getContrastText('#ccc')
    }
}));
