import React from "react";
import { useSelector } from "react-redux";
import {
    MailTemplateVisualEditor
} from "../Menu/MaterialTripList/MailVisualEditor/mailTemplateVisualEditor";
import {
    MailTemplateVisualEditorDragArea
} from "../Menu/MaterialTripList/MailVisualEditor/mailTemplateVisualEditorDragArea";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    id: number,
    tripId: number | null,
    locale: number,
}

export const CartConstructionContentsRoadbookFormDescriptionInput = React.memo(
    function (props: Props): JSX.Element | null {
        const step = useSelector((state: AppState) => state.cart.step);

        return (
            <MailTemplateVisualEditor
                instanceId={`cart-construction-roadbook-rubric-${props.tripId}-${props.id}`}
                locale={props.locale}
                renderContentArea={() => (
                    <MailTemplateVisualEditorDragArea /> 
                )}
                isIdle={step !== 2}
                disableAutoCreation
                disablePreview
                disableHeader
                disableHoverInteraction
                useCustomDndContext
            />
        );
    }
);
