export function AddFlightGroup(flight_group) {
    return {
        type: "FLIGHT_ADD_FLIGHT_GROUP",
        payload: {
            flight_group: flight_group
        }
    };
}
export function ResetFlightGroup(flight_group) {
    return {
        type: "FLIGHT_RESET_GROUP",
        payload: {
            flight_group: flight_group
        }
    };
}
export function ResetFlightTravelers(flight_group) {
    return {
        type: "FLIGHT_RESET_TRAVELERS"
    };
}
export function UpdateGroup(flight_groups) {
    return {
        type: "FLIGHT_UPDATE_FLIGHT_GROUPS_AT_INDEX",
        payload: {
            flight_groups: flight_groups
        }
    }
}
export function DeleteFlightGroupsAtIndex(index) {
    return {
        type: "FLIGHT_DELETE_FLIGHT_GROUPS_AT_INDEX",
        payload: {
            index: index
        }
    };
}

export function AddToCart(flight) {
    return {
        type: "FLIGHT_ADD_TO_CART",
        payload: {
            flight: flight
        }
    };
}

export function SetFlightCart(flight) {
    return {
        type: "FLIGHT_SET_FLIGHT_CART",
        payload: {
            flight: flight
        }
    };
}

export function EditFlightCartMargin(edit, index) {
    return {
        type: "FLIGHT_SET_MANUAL_MARGIN_EDIT",
        payload: {
            edit: edit,
            index: index
        }
    };
}
export function EditFlightStatus(data) {
    return {
        type: "FLIGHT_EDIT_STATUS",
        payload: {
            data: data
        }
    };
}
export function EditFlightCart(item, index) {
    return {
        type: "FLIGHT_EDIT_CART",
        payload: {
            item: item,
            index: index
        }
    };
}
export function EditFlightCartById(flight) {
    return {
        type: "FLIGHT_EDIT_CART_BY_ID",
        payload: flight
    };
}
export function RemoveFlightFromCart(index) {
    return {
        type: "FLIGHT_REMOVE_FLIGHT_FROM_CART",
        payload: {
            index: index
        }
    };
}
export function SetJourneyType(journey, index) {
    return {
        type: "FLIGHT_SET_JOURNEY_TYPE",
        payload: {
            type: journey,
            index: index
        }
    };
}

export function SetAirportList(airport_list) {
    return {
        type: "FLIGHT_SET_AIRPORT_LIST",
        payload: {
            airport_list: airport_list
        }
    };
}

export function SetOnewayOriginDate(date, index) {
    return {
        type: "FLIGHT_SET_ONEWAY_ORIGIN_DATE",
        payload: {
            date: (date === null ? null : date.format()),
            index: index
        }
    };
}
export function SetRoundtripReturnDate(date, index) {
    return {
        type: "FLIGHT_SET_ROUNDTRIP_RETURN_DATE",
        payload: {
            date: (date === null ? null : date.format()),
            index: index
        }
    };
}
export function SetMultiDestStartDate(date, index, key) {
    return {
        type: "FLIGHT_SET_MULTIDEST_START_DATE",
        payload: {
            date: (date === null ? null : date.format("YYYY-MM-DD")),
            index: index,
            key: key
        }
    };
}
export function SetOnewayClass(value, index) {
    return {
        type: "FLIGHT_SET_ONEWAY_CLASS",
        payload: {
            value: Number(value),
            index: index
        }
    };
}

export function SetRoundtripClass(value, index) {
    return {
        type: "FLIGHT_SET_ROUNDTRIP_CLASS",
        payload: {
            value: Number(value),
            index: index
        }
    };
}

export function SetMultiDestClass(value, index, key) {
    return {
        type: "FLIGHT_SET_MULTIDEST_CLASS",
        payload: {
            value: Number(value),
            index: index,
            key: key
        }
    };
}

export function SetGroupsClassType(index_group, index_journey, class_type) {
    return {
        type: "FLIGHT_SET_GROUPS_CLASS_TYPE",
        payload: {
            index_group: index_group,
            index_journey: index_journey,
            class_type: class_type
        }
    };
}
export function ClearClass(index_group) {
    return {
        type: "FLIGHT_CLEAR_CLASS",
        payload: {
            index_group: index_group
        }
    };
}
export function SetPrevClass(class_type) {
    return {
        type: "FLIGHT_SET_PREV_CLASS",
        payload: {
            class_type: class_type
        }
    };
}

export function TooglePassenger(index, value) {
    return {
        type: "FLIGHT_TOOGLE_PASSENGER",
        payload: {
            value: value,
            index: index
        }
    };
}

export function SetFlexibleDate(index, value) {
    return {
        type: "FLIGHT_SET_FLEXIBLE_DATE",
        payload: {
            value: value,
            index: index
        }
    };
}

export function SetDirectFlight(index, value) {
    return {
        type: "FLIGHT_SET_DIRECT_FLIGHT",
        payload: {
            value: value,
            index: index
        }
    };
}

export function SetPrivatePrice(index, value) {
    return {
        type: "FLIGHT_SET_PRIVATE_PRICE",
        payload: {
            index: index,
            private_price: value
        }
    };
}
export function SetPublicPrice(index, value) {
    return {
        type: "FLIGHT_SET_PUBLIC_PRICE",
        payload: {
            index: index,
            public_price: value
        }
    };
}
export function SplicePassengerList(index, passenger_id) {
    return {
        type: "FLIGHT_SPLICE_PASSENGER_LIST",
        payload: {
            index: index,
            passenger_id: passenger_id
        }
    };
}
export function SetTravelersList(index, travelers) {
    return {
        type: "FLIGHT_SET_TRAVELERS_LIST",
        payload: {
            index: index,
            travelers: travelers
        }
    };
}
export function PutTravelersList(index, travelers) {
    return {
        type: "FLIGHT_PUT_TRAVELERS_LIST",
        payload: {
            index: index,
            travelers: travelers
        }
    };
}
export function AddJourneyToflight(journey, index) {
    return {
        type: "FLIGHT_ADD_FLIGHT_JOURNEY",
        payload: {
            index: index,
            journey: journey
        }
    };
}
export function SetManualJourney(group_index, journey) {
    return {
        type: "FLIGHT_MANUAL_JOURNEY",
        payload: {
            group_index: group_index,
            journey: journey
        }
    };
}
export function AddJourneyToflightFromItinerary(journey, index) {
    return {
        type: "FLIGHT_ADD_FLIGHT_JOURNEY_FROM_ITINERARY",
        payload: {
            index: index,
            journey: journey
        }
    };
}
export function SubJourneyToFlight(index, key) {
    return {
        type: "FLIGHT_SUB_FLIGHT_JOURNEY",
        payload: {
            index: index,
            key: key
        }
    };
}
export function SetAirportJourney(airport, index, type, key) {
    return {
        type: "FLIGHT_SET_AIRPORT_JOURNEY",
        payload: {
            index: index,
            key: key,
            type: type,
            airport: airport
        }
    };
}
export function SetAirportJourneyRoundtrip(airport, index, type, key) {
    return {
        type: "FLIGHT_SET_AIRPORT_JOURNEY_ROUNDTRIP",
        payload: {
            index: index,
            key: key,
            type: type,
            airport: airport
        }
    };
}
export function SetAirportJourneyMultiDest(airport, index, type, key) {
    return {
        type: "FLIGHT_SET_AIRPORT_JOURNEY_MULTIDEST",
        payload: {
            index: index,
            key: key,
            type: type,
            airport: airport
        }
    };
}
export function SetError(index, error) {
    return {
        type: "FLIGHT_SET_ERROR",
        payload: {
            index: index,
            error: error
        }
    };
}
export function RemoveError(index, error) {
    return {
        type: "FLIGHT_REMOVE_ERROR",
        payload: {
            index: index,
            error: error
        }
    };
}
export function SetOnewayJourney(index) {
    return {
        type: "FLIGHT_SET_ONEWAY_JOURNEY",
        payload: {
            index: index
        }
    };
}
export function SetGroupIndex(index) {
    return {
        type: "FLIGHT_SET_GROUP_INDEX",
        payload: {
            index_group: index
        }
    };
}
export function SetGroupCopyIndex(index) {
    return {
        type: "FLIGHT_SET_GROUP_COPY_INDEX",
        payload: {
            index_group: index
        }
    };
}
export function ResetError(index) {
    return {
        type: "FLIGHT_RESET_ERROR",
        payload: {
            index: index
        }
    };
}
export function SetRedirection(redirection) {
    return {
        type: "FLIGHT_SET_REDIRECTION",
        payload: {
            redirection: redirection
        }
    };
}
export function SetTab(tab, flight_index, segment_index) {
    return {
        type: "FLIGHT_CART_SET_TAB",
        payload: {
            flight_index: flight_index,
            segment_index: segment_index,
            tab: tab
        }
    };
}
export function ToggleDetail(flight_index, outbound_index, detail) {
    return {
        type: "FLIGHT_CART_TOGGLE_DETAIL",
        payload: {
            flight_index: flight_index,
            outbound_index: outbound_index,
            detail: !detail
        }
    };
}
export function SetRoundtripJourney(index) {
    return {
        type: "FLIGHT_SET_ROUNDTRIP_JOURNEY",
        payload: {
            index: index
        }
    };
}
export function SwapMultidestJourney(index, old_key, new_key) {
    return {
        type: "FLIGHT_SWAP_MULTIDEST_JOURNEY",
        payload:{
            index: index,
            old_key: old_key,
            new_key: new_key
        }
    }
}
export function SetInputFocus(index, focus) {
    return {
        type: "FLIGHT_SET_INPUT_FOCUS",
        payload: {
            index: index,
            focus: focus
        }
    };
}
export function SelectAirlines(airlines, index) {
    return {
        type: "FLIGHT_SELECT_AIRLINES",
        payload: {
            airlines: airlines,
            index: index
        }
    };
}
export function SetOptionCart(flight_index, segment_index, options, more_option, is_empty) {
    return {
        type: "FLIGHT_SET_FETCHED_CART_OPTIONS",
        payload: {
            flight_index: flight_index,
            segment_index: segment_index,
            options: options,
            more_option: more_option,
            is_empty: is_empty
        }
    };
}
export function SetSelectedCartOption(flight_index, segment_index, selected_option, key_pricing) {
    return {
        type: "FLIGHT_SET_SELECTED_CART_OPTION",
        payload: {
            flight_index: flight_index,
            segment_index: segment_index,
            selected_option: selected_option,
            key_pricing: key_pricing
        }
    };
}
export function SetEmptyCartOptions(flight_index, segment_index, more_option) {
    return {
        type: "FLIGHT_SET_EMPTY_CART_OPTIONS",
        payload: {
            flight_index: flight_index,
            segment_index: segment_index,
            more_option: more_option
        }
    };
}
export function SetFetchErrorCartOptions(flight_index, segment_index, fetch_error) {
    return {
        type: "FLIGHT_SET_FETCH_ERROR_CART_OPTIONS",
        payload: {
            fetch_error: fetch_error,
            flight_index: flight_index,
            segment_index: segment_index
        }
    };
}
export function ResetFetchErrorCartOptions(flight_index, segment_index) {
    return {
        type: "FLIGHT_RESET_FETCH_ERROR_CART_OPTIONS",
        payload: {
            flight_index: flight_index,
            segment_index: segment_index
        }
    };
}
export function AddCartPassengerSeat(flight_index, traveler, leg_index, outbound_index, seat, price) {
    return {
        type: "FLIGHT_ADD_CART_PASSENGER_SEAT",
        payload: {
            flight_index: flight_index,
            outbound_index: outbound_index,
            leg_index: leg_index,
            traveler: traveler,
            seat: seat,
            price: price
        }
    };
}
export function SelectCartQuantity(flight_index, segment_index, key_optional_service, quantity, traveler) {
    return {
        type: "FLIGHT_SELECT_CART_QUANTITY",
        payload: {
            flight_index: flight_index,
            segment_index: segment_index,
            key_optional_service: key_optional_service,
            quantity: quantity,
            traveler: traveler
        }
    };
}
export function AddCartBaggageOption(key, options, flight_index, segment_index, traveler) {
    let tmp_option = null;
    options.map((option) => {
        if (option.key_optional_service === key) {
            tmp_option = option;
        }
    });
    return {
        type: "FLIGHT_ADD_CART_BAGGAGE_OPTION",
        payload: {
            baggage: tmp_option,
            flight_index: flight_index,
            segment_index: segment_index,
            traveler: traveler
        }
    };
}
export function RemoveCartBaggageOption(baggage_id, flight_index, segment_index, traveler) {
    return {
        type: "FLIGHT_REMOVE_CART_BAGGAGE_OPTION",
        payload: {
            baggage: baggage_id,
            flight_index: flight_index,
            segment_index: segment_index,
            traveler: traveler
        }
    };
}
export function SetCartUpdated(flag) {
    return {
        type: "FLIGHT_CART_UPDATED",
        payload: {
            flag: flag
        }
    };
}
export function ClearAirlines() {
    return {
        type: "FLIGHT_CLEAR_AIRLINES"
    };
}
export function GetProvider(provider_list, index, config) {
    return {
        type: "FLIGHT_GET_PROVIDERS",
        payload: {
            provider_list: provider_list,
            index: index,
            config: config
        }
    };
}
export function SelectProvider(provider, index) {
    return {
        type: "FLIGHT_SELECT_PROVIDER",
        payload: {
            provider: provider,
            index: index
        }
    };
}
export function ToggleDetailPriceCart(flight_index, detail) {
    return {
        type: "FLIGHT_TOGGLE_DETAIL_PRICE_CART",
        payload: {
            flight_index: flight_index,
            detail: detail
        }
    };
}
export function SetIncludedLuggage(flight_index, included_luggage) {
    return {
        type: "FLIGHT_TOGGLE_INCLUDED_LUGGAGE",
        payload: {
            flight_index: flight_index,
            included_luggage: included_luggage
        }
    };
}
export function SaveFlightGroups() {
    return {
        type: "FLIGHT_SAVE_FLIGHT_GROUPS",
        payload: {}
    }
}
export function ResetPreviousFlightGroups() {
    return {
        type: "FLIGHT_RESET_PREVIOUS_FLIGHT_GROUPS",
        payload: {}
    }
}
export function ReplaceGroup(group, index) {
    return {
        type: "FLIGHT_REPLACE_GROUP",
        payload: {
            group: group,
            index: index
        }
    }
}
export function RevertJourneys(index) {
    return {
        type: "FLIGHT_REVERT_JOURNEYS",
        payload: {
            index: index
        }
    }
}

export function SetGroupsManualRequest(group_index, manual_request) {
    return {
        type: "FLIGHT_SET_GROUPS_MANUAL_REQUEST",
        payload: {
            group_index: group_index,
            manual_request: manual_request
        }
    }
}

export function SetFirstRender(first_render){
    return {
        type: "FLIGHT_SET_FIRST_RENDER",
        payload: {
            first_render: first_render
        }
    }
}

export function FillMultiDest(){
    return {
        type: "FILL_MULTI_DEST",
        payload: null
    }
}

export function SetOpenDatePickers(open_date_pickers){
    return {
        type: "FLIGHT_SET_OPEN_DATE_PICKERS",
        payload: {
            open_date_pickers: open_date_pickers
        }
    }
}

export function SetManualLuggage(group_index, manual_luggage){
    return {
        type: "FLIGHT_SET_MANUAL_LUGGAGE",
        payload: {
            group_index: group_index,
            manual_luggage: manual_luggage
        }
    }
}