export function recreateInputsFromInvoice(invoiceData) {
    return [
        {
            companyName: {
                data: invoiceData.company_name !== null ? invoiceData.company_name : '',
                edit: false
            }
        },
        invoiceData.invoice_product.map((invoice) => ({
            id: invoice.id,
            description: invoice.name,
            totalWithoutTax: invoice.total_without_tax,
            flightTax: invoice.flight_tax
        }))
    ];
}
