import { Traveler } from "../../../../Reducers/objects/Traveler";

export default (travelers: Traveler[]) => {
    let pass_check = true;
    travelers.map((traveler) => {
        // check if traverler has email and phone number
        if (traveler.email === null || traveler.email === "" || traveler.phone_number === null || traveler.phone_number === ""){
            pass_check = false;
        }
    })
    return pass_check
}
