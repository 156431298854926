//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { Link, History } from "react-router";

//---- Core ----//
import Grid from '@material-ui/core/Grid';
import { green } from "@material-ui/core/colors";
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
//---- Icons ----//
import MoreVert from '@material-ui/icons/MoreVert';
//---- Component ----//
import Footer from '../Footer/Footer';
import VisiteursAccountMenu from './VisiteursAccountMenu';
import VisiteursDestinationMenu from './VisiteursDestinationMenu';
//---- Common ----//
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    absoluteCenter: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    customButton: {
        background: "#e6592f",
        // padding: "15px 40px",
        textAlign: "center",
        color: "#fff",
        fontSize: 20,
        textTransform: "none",
        position: "relative",
        "&:hover": {
            background: "#cc4f2a"
        },
        "&&.MuiButton-contained.Mui-disabled": {
            backgroundColor: "#e6592f"
        }
    },
    customOutlinedButton: {
        color: "#e6592f",
        borderColor: "#e6592f",
        "&:hover": {
            borderColor: "#e6592f",
        }
    },
    fullWidth: {
        width: "100%"
    },
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "49%",
        // marginTop: -20,
        // marginLeft: -12,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
    },
    title: {
        flexGrow: 1,
    },
    bold: {
        fontWeight: 'bold'
    },
    menu: {
        '&:hover': {
            color: '#BF202D'
        }
    },
    pointer: {
        cursor: 'pointer'
    }
}));

const VisiteurHeader = ({router, setLoginDialog, full_dest}) => {
    const config = JSON.parse(localStorage.getItem("config"));
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    
    const user = useSelector(store => store.user.user);
    const is_connected = useSelector(store => store.user.is_connected);
    
    const [logo] = useState(config !== null && config.logo !== undefined && config.logo !== null && config.logo.url !== undefined && config.logo.url !== null ? config.logo : null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const mobileMenuId = 'menu-mobile';
    
    const onAccessHelp = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/user/access_support/`,
        }).then(function (response) {
            let win = window.open(response.data, '_blank');
            win.focus();
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>Destinations</MenuItem>
            {
                user && user.client_full && user.client_full.type === 2 &&
                <MenuItem onClick={onAccessHelp}>Aide</MenuItem>
            }
            <MenuItem>Mon Compte</MenuItem>
        </Menu>
    );

    return (
        <AppBar position={'static'} color={'transparent'} style={{boxShadow: 'none'}}>
            <Toolbar variant={'dense'} style={{maxHeight: 40}}>
                <Grid container justify={ !fullScreen ? 'space-between' : undefined} alignItems={'center'}>
                    <Grid className={ classes.logo } item>
                        <Link to={ `/home/${window.url_name}/` }>
                            {/* <img src={ logo !== null ? (logo.thumbnail_little !== null ? logo.thumbnail_little : logo.url) : "/Img/inner_logo_header.png" } height={ 30 } alt={ "" }/> */}
                            <div style={{height: 'auto', width: 'auto', maxWidth: 250, maxHeight: 300}}>
                                <img src={ "/Img/visiteurs/Plan&TripPetit.png" } width={ '56%' } alt={ "" } />
                            </div>
                        </Link>
                    </Grid>                       
                    <Grid item className={classes.sectionDesktop}>
                        <Grid container spacing={ 6 } justify={'space-between'}>
                            <Grid item >
                                <VisiteursDestinationMenu router={router} full_dest={full_dest}/>
                            </Grid>
                            {
                                user && user.client_full && user.client_full.type === 2 &&
                                <Grid item className={`${classes.bold} ${classes.pointer} ${classes.menu}`} onClick={onAccessHelp}>
                                    {t('global.help')}
                                </Grid>
                            }
                            <Grid item>
                            {
                                is_connected ?
                                    <VisiteursAccountMenu router={router}/> :
                                    <div className={`${classes.bold} ${classes.pointer} ${classes.menu} ${classes.relative}`} onClick={() => setLoginDialog(true)}>
                                        Mon compte
                                    </div>
                            }
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        fullScreen &&
                        <Grid item className={classes.title}/>
                    }
                    <Grid item className={classes.sectionMobile}>
                        <IconButton
                            onClick={handleMobileMenuOpen}
                            color={"inherit"}
                            size={'small'}
                        >
                            <MoreVert />
                        </IconButton>
                    </Grid>
                    {renderMobileMenu}
                </Grid>
            </Toolbar>
        </AppBar>
    )
};
export default VisiteurHeader