//---- Dependencies ----//
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Grid from "@material-ui/core/Grid";

import { CircuitCategory } from "../Itinerary/objects/circuitCategory";
import localeText from "../Functions/localeText";
import { Checkbox, TextField } from "@mui/material";
import { Language } from "./Functions/objects/language";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
    underline: {
        textDecoration: 'underline'
    },
    pointer: {
        cursor: 'pointer'
    },
    customAutocomplete: {
        '&& .mui-jss-MuiAutocomplete-inputRoot': {
            paddingRight: '0px !important'
        }
    }
}));

type Props = {
    destinations: string[],
    categories: CircuitCategory[],
    languages: Language[],
}

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const MaterialProductsFilters = (props: Props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const locales = useSelector(state => state.user.locales);
    const filters_type = useSelector(store => store.home.filters_type);
    const list_type = useSelector(store => store.home.list_type);
    const filter_destinations = useSelector(store => store.home.filter_destinations);
    const filter_categories = useSelector(store => store.home.filter_categories);
    const guide_languages = useSelector(store => store.home.guide_languages);
    const user = useSelector(store => store.user.user);
    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });
    let product_type = [
        t('itineraryType.all_packages'),
        t('itineraryType.all_circuits'),
        t('itineraryType.all_itinerary_type')
    ];
    if (user.client_full.type !== 2) {
        product_type.push(
            t('itineraryType.connected_circuits')
        );
        product_type.push(
            t('itineraryType.offline_circuits')
        );
    }
    const handleReset = () => {
        dispatch({ type: 'HOME_RESET_FILTERS' });
    };
    const getLabel = (option) => {
        if (option !== undefined) {
            let label = option.LocalTranslation.find(n => n.localeTranslate === current_locale.id);
            if (label !== undefined) {
                return label.translated_name;
            }
            return option.full_name;
        }
    };
    return (
        <Grid container direction={"row"} justify={'space-between'} alignItems={"center"} spacing={4}>
            <Grid item md={ 11 } xs={ 12 }>
                {/* <MaterialBreadcrumb router={{destinations}.router}/> */}
                <Grid container spacing={ 2 }>
                    <Grid item md={ 3 } xs={ 12 }>
                        <Autocomplete
                            fullWidth
                            multiple
                            id={'filter-destinations'}
                            options={props.destinations}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option}
                            value={filter_destinations}
                            renderTags={(value) => {
                                let value_length = value.length;
                                return (
                                    <span style={{ marginLeft: 0 }}>
                                        {
                                            value_length !== props.destinations.length ? value_length + " " + t("global.over") + " " + props.destinations.length + " destinations" : "Toutes les destinations (" + value_length + ")"
                                        }
                                    </span>
                                );
                            }}
                            renderOption={(option, { selected }) => (
                                <Fragment>
                                    <Checkbox
                                        sx={{
                                            '&.Mui-checked': {
                                                color: `${main_color}`
                                            }
                                        }}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                </Fragment>
                            )}
                            onChange={(event, newValue) => {
                                dispatch({ type: 'HOME_SET_FILTER_DESTINATIONS', payload: newValue });
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant={'outlined'} label={t<string>('itinerary_type.destinations')} />
                            )}
                        />
                    </Grid>
                    <Grid item md={ 3 } xs={ 12 }>
                        <Autocomplete
                            fullWidth
                            multiple
                            id={'filter-type'}
                            options={product_type}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option}
                            value={filters_type}
                            renderTags={(value) => {
                                let value_length = value.length;
                                return (
                                    <span style={{ marginLeft: 0 }}>
                                        {
                                            value_length !== product_type.length ? value_length + " " + t("global.over") + " " + product_type.length + " types" : "Touts les types (" + value_length + ")"
                                        }
                                    </span>
                                );
                            }}
                            renderOption={(option, { selected }) => (
                                <Fragment>
                                    <Checkbox
                                        sx={{
                                            '&.Mui-checked': {
                                                color: `${main_color}`
                                            }
                                        }}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                </Fragment>
                            )}
                            onChange={(event, newValue) => {
                                dispatch({ type: 'HOME_SET_FILTERS_TYPE', payload: newValue });
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant={'outlined'} label={t<string>('itinerary_type.types')} />
                            )}
                        />
                    </Grid>
                    <Grid item md={ 3 } xs={ 12 }>
                        <Autocomplete
                            fullWidth
                            multiple
                            id={'filter-categories'}
                            options={props.categories}
                            disableCloseOnSelect
                            getOptionLabel={(option) => localeText(current_locale.id, option.localization, option.name, 'name')}
                            value={filter_categories}
                            renderTags={(value) => {
                                let value_length = value.length;
                                return (
                                    <span style={{ marginLeft: 0 }}>
                                        {
                                            value_length !== props.categories.length ? value_length + " " + t("global.over") + " " + props.categories.length + " types" : "Touts les types (" + value_length + ")"
                                        }
                                    </span>
                                );
                            }}
                            renderOption={(option, { selected }) => (
                                <Fragment>
                                    <Checkbox
                                        sx={{
                                            '&.Mui-checked': {
                                                color: `${main_color}`
                                            }
                                        }}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {localeText(current_locale.id, option.localization, option.name, 'name')}
                                </Fragment>
                            )}
                            onChange={(event, newValue) => {
                                dispatch({ type: 'HOME_SET_FILTER_CATEGORIES', payload: newValue });
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant={'outlined'} label={t<string>('itinerary_type.categories')} />
                            )}
                        />
                    </Grid>
                    <Grid item md={ 3 } xs={ 12 }>
                        <Autocomplete
                            fullWidth
                            multiple
                            id={'filter-guide-languages'}
                            options={props.languages}
                            disableCloseOnSelect
                            getOptionLabel={(option) => getLabel(option)}
                            value={guide_languages}
                            renderTags={(value) => {
                                let value_length = value.length;
                                return (
                                    <span>
                                        {
                                            value_length !== props.languages.length ? value_length + " " + t("global.over") + " " + props.languages.length + " types" : "Touts les types (" + value_length + ")"
                                        }
                                    </span>
                                );
                            }}
                            renderOption={(option, { selected }) => (
                                <Fragment>
                                    <Checkbox
                                        sx={{
                                            '&.Mui-checked': {
                                                color: `${main_color}`
                                            }
                                        }}
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {getLabel(option)}
                                </Fragment>
                            )}
                            onChange={(event, newValue) => {
                                dispatch({ type: 'HOME_SET_GUIDE_LANGUAGES', payload: newValue });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={classes.customAutocomplete}
                                    variant={'outlined'}
                                    label={t<string>('shared.guide-lang')}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={ 4 }>
                    <Grid item className={`${classes.underline} ${classes.pointer}`} onClick={handleReset}>{t('flight_search.remove_filters')}</Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default MaterialProductsFilters;
