import { useSelector } from 'react-redux';
import axios, { AxiosResponse } from "axios";
import { useSnackbar } from 'notistack';
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { MailHistory } from '../objects/mail-history';
import { Trip } from '../objects/trip';
import { AppState } from '../../../../Reducers/Reducers';

type Document = MailHistory['attached_docs'][0]

type Callback = (
    document: Document,
    callback: (blob: Blob) => void
) => void

type Options = {
    setLoading: (loading: boolean) => void
}

type RequestOptions = {
    trip_info: Trip,
    version: number
}

export function useAwsFileDownload(options: Options): Callback {
    const { enqueueSnackbar } = useSnackbar();
    const trip_info = useSelector((store: AppState) => store.menu.trip_info);
    const to_show_version = useSelector((store: AppState) => store.menu.to_show_version);
    const version = to_show_version !== null ? to_show_version : trip_info?.current_version;

    return (document, callback) => {
        if (trip_info && version !== undefined) {
            options.setLoading(true);
            makeRequest(
                {
                    trip_info,
                    version
                },
                document
            ).then((response) => {
                if (response) {
                    callback(response.data);
                }
            }).catch((error) => {
                console.error(error);
                enqueueSnackbar(error.message, { variant: 'error' });
            }).finally(() => {
                options.setLoading(false);
            });
        }
    };
}

function makeRequest(options: RequestOptions, document: Document): Promise<AxiosResponse<Blob> | null> {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const bucketName = "facilitatrip-documents";
        return axios.get(
            //eslint-disable-next-line no-undef
            `${API_HREF}client/${window.id_owner}/trip/${options.trip_info.id}/versions/${options.version}/file/download_from_aws/`,
            {
                responseType: 'blob',
                params: {
                    bucket_name: bucketName,
                    object_name: document.object_name,
                    file_name: document.file_name ?
                        document.file_name :
                        'null'
                },
                headers
            }
        );
    }
    return new Promise((resolve) => resolve(null));
}
