//---- Commons ----//
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

//---- Actions ----//
import { SetLocationPoint } from "../../../Actions/CarsSearch";

let xhr;

function GetStation(headers, city_id, results, dispatch) {
	if (xhr !== undefined)
        xhr.abort();
	xhr = $.ajax({
			method: "GET",
			headers: headers,
			url: `${API_HREF}client/${window.id_owner}/cars/get_bsp_stations/`,
			data: {
			    destination_id: city_id
			},
			success: function(stations) {
				stations.map((station) => {
					let find = results.find((result) => {
						if (station.iata_code)
							return (station.iata_code === result.airport_code);
					});
					if (find === undefined) {
						results.push({
							city_code: station.bsp_id,
							international_name: station.name,
							address: station.address,
							is_bsp: true,
							country_code: station.country_code ? station.country_code : null,
							airport_code: station.iata_code ? station.iata_code : null
						});
					}
				});
	    		dispatch(SetLocationPoint(results));
			},
			error: function(error) {
	    		dispatch(SetLocationPoint(results));
			}
		})
}

export default (destination, array_airports, destination_id, dispatch, t, readOnly) => {
	const { pass_check, headers } = CheckBeforeRequest();
	if (destination.length > 0) {
		let results = [];
		if (readOnly) {
			results = array_airports.slice();
			results.map((elem) => {
				// elem.city = destination;
				elem.is_bsp = false;
				elem.address = null;
			});
			let city_id = destination_id;
			results.push({
				city_code: destination_id,
				is_bsp: false,
				international_name: t("cars.all_agengies_in_city") + destination,
				airport_code: null,
				address: null
			});
			GetStation(headers, city_id, results, dispatch);
		}
		else {
			if (pass_check) {
				let city_id = null;
				$.ajax({
				    method: "GET",
				    headers: headers,
				    url: `${API_HREF}iata-airports/`,
				    data: {
				        search: destination
				    },
				    success: function (data) {
			        	data.results.map((result) => {
			        		if (result.airport_code.length === 3) {
			        			result.is_bsp = false;
			        			result.address = null;
			        			results.push(result);
			        		}
			        	});
			        	if (xhr !== undefined)
				            xhr.abort();
			        	xhr = $.ajax({
		        			    method: "GET",
		        			    headers: headers,
		        			    url: `${API_HREF}client/${window.id_owner}/destinations/?reduced=true&visibility__in=PUBLIC,PRIVATE_TO`,
		        			    data: {
		        			    	current_version__localization__name__unaccent__icontains: destination,
		        			    	current_version__type: 4
		        			    },
		        			    success: function (data) {
		        			        if (data.results.length > 0) {
		        			        	let find = data.results.find((el) => {
		        			        		let international_name = (el.data.international_name).split(",");
		        			        		return (international_name[0].toLowerCase()).includes((destination).toLowerCase());
		        			        	});
		        			        	let all_agency = (find.data.international_name).split(",");
		        			        	city_id = find.id;
					        			results.push({
					        				city_code: find.id,
					        				is_bsp: false,
					        				international_name: t("cars.all_agengies_in_city") + all_agency[0],
					        				airport_code: null,
					        				address: null
					        			});
					        			GetStation(headers, city_id, results, dispatch);
		        			        }
		        			    }
		        			});
				    },
				    error: function (error) {
				        console.log(error);
				    }
				});
			}
		}
	}
	else {
		dispatch(SetLocationPoint([]));
	}
}