//---- Dependencies ----//
import axios from 'axios';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import i18n from "../../i18n.jsx";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from '@material-ui/core/CircularProgress';

import Close from "@material-ui/icons/Close";

import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import GetCookie from '../Common/Functions/GetCookie';
import SetCookie from "../Common/Functions/SetCookie";
import GetTrip from "../Base/Functions/GetTrip";

import { SetTripInfo } from '../../Actions/Menu';
import GetPricesId from '../Cart/Functions/Margin/GetPricesId.js';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    cancelButton: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    },
}));

const CartPriceVariationDialog = ({openPriceVariation, handlePriceVariation, diffPrice, newProduct}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { enqueueSnackbar } = useSnackbar();

    const trip_currency = useSelector((store) => store.trip.currency);
    const data_trip = useSelector((store) => store.trip.data_trip);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    const displayed_version = to_show_version !== null ? to_show_version : GetCookie("trip_id_version");
    const pois = useSelector(state => state.poi.cart);
    const flights = useSelector(state => state.flight.cart);
    const cars = useSelector(state => state.cars_search.cart);
    const transfers = useSelector(state => state.transfers.cart);
    const accommodations = useSelector(state => state.accommodation.cart);
    const manual_products = useSelector(state => state.cart.manual_item_list);
    const user = useSelector(state => state.user.user);
    const total_cost = useSelector(store => store.trip.total_cost);
    const tva_value = useSelector(store => store.cart.tva_value);
    const margin_value = useSelector(store => store.cart.margin_value);

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const [loading, setLoading] = useState(false);

    let find_product = manual_products.find((product) => product.product_type === 20);

    const getPrice = (prices) => {
        if (user.client_full.type === 1 || user.client_full.type === 3 || quotation_code === 'verdie') {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_tva) {
                    return prices[i];
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_surcom) {
                    return prices[i];
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].master_price) {
                    return prices[i];
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user.client) {
                    return prices[i];
                }
            }
        } else {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_tva) {
                    return prices[i];
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user.client) {
                    return prices[i];
                }
            }
        }
        return null
    }
    const resetCookie = (data_res, id) => {
        let user_token = GetCookie("token");
        let id_user = GetCookie("id_user");
        let trip_token = GetCookie("trip_token");
        let client_user = GetCookie("client_user");

        //Delete the cookie
        let cookies = document.cookie.split("; ");
        for (let c = 0; c < cookies.length; c++) {
            let d = window.location.hostname.split(".");
            while (d.length > 0) {
                let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
                let p = location.pathname.split("/");
                document.cookie = cookieBase + "/";
                while (p.length > 0) {
                    document.cookie = cookieBase + p.join("/");
                    p.pop();
                }
                d.shift();
            }
        }
        SetCookie("trip_id", id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("trip_id_version", data_res.id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("trip_token", trip_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("token", user_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("id_user", id_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("client_user", client_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        dispatch({type: "FLUSH_REMOVE_STATE"});
        dispatch({
            type: "HEADER_TOGGLE_AND_SET_LANGUAGE",
            payload: {
                show_language: false,
                language: i18n.language
            }
        });
        GetTrip(user, dispatch, t);
    };
    const getProductsToDelete = () => {
        const { pass_check, headers } = CheckBeforeRequest();

        let product_to_delete = [];
        let status_contract_confirmed_date = data_trip.status_contract_confirmed_date
        accommodations.map((accommodation) => {
            if (moment(accommodation.created_date).isAfter(status_contract_confirmed_date)) {
                product_to_delete.push(
                    axios({
                        method: "DELETE",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${accommodation.id}/`,
                    })
                )
            }
        });
        cars.map((car) =>{
            if (moment(car.created_date).isAfter(status_contract_confirmed_date)) {
                product_to_delete.push(
                    axios({
                        method: "DELETE",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${car.id}/`,
                    })
                )
            }
        });
        flights.map((flight) => {
            if (moment(flight.created_date).isAfter(status_contract_confirmed_date)) {
                product_to_delete.push(
                    axios({
                        method: "DELETE",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`,
                    })
                )
            }
        });
        pois.map((poi) => {
            if (moment(poi.created_date).isAfter(status_contract_confirmed_date)) {
                product_to_delete.push(
                    axios({
                        method: "DELETE",
                        headers: headers,
                        url:  `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${poi.id}/`,
                    })
                )
            }
        });
        transfers.map((transfer) => {
            if (moment(transfer.created_date).isAfter(status_contract_confirmed_date)) {
                product_to_delete.push(
                    axios({
                        method: "DELETE",
                        headers: headers,
                        url:  `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/transfers/${transfer.id}/`,
                    })
                )
            }
        });
        manual_products.map((manual_product) => {
            if (moment(manual_product.created_date).isAfter(status_contract_confirmed_date)) {
                product_to_delete.push(
                    axios({
                        method: "DELETE",
                        headers: headers,
                        url:  `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
                    })
                )
            }
        });
        axios.all([...product_to_delete]).catch(function (error) {
            console.log('error delete product:', error);
            //TODO: show snackbar error
        });
    }
    const createManualProduct = () => {
        const { headers } = CheckBeforeRequest();
        if (find_product === undefined) {
            let new_price = 0;
            let tva_percentage = tva_value / margin_value * 100;
            console.log('newProduct:', newProduct);
            if (newProduct.length !== 0) {
                newProduct.map((product) => {
                    new_price += diffPrice < 0 ? parseFloat(product.price.cost.selling_price) : parseFloat(product.price.cost.selling_price) * -1;
                });
            } else {
                new_price = diffPrice < 0 ? diffPrice : diffPrice * -1;
            }
            console.log('new_price:', new_price);
            // const adjustment_abs = Math.abs(new_price);
            const adjustment_abs = diffPrice * -1;
            // const adjustment_tmp = (tva_value + margin_value - adjustment_abs);
            // const adjustment_margin = (tva_percentage !== 0 ? adjustment_tmp / (1 + (tva_percentage / 100)) : adjustment_abs) * -1;
            // const adjustment_tva = tva_percentage !== 0 ? (adjustment_tmp - (adjustment_tmp / (1 + (tva_percentage / 100)))) * -1 : 0;
            const adjustment_tmp = (tva_value + margin_value - adjustment_abs);
            const adjustment_margin = (tva_percentage !== 0 ? adjustment_abs / (1 + (tva_percentage / 100)) : adjustment_abs);
            const adjustment_tva = tva_percentage !== 0 ? (adjustment_abs - adjustment_margin) : 0;
            console.log('adjustment abs : ', adjustment_abs);
            console.log('adjustment abs margin : ', adjustment_margin.toFixed(2));
            console.log('adjustment abs tva : ', adjustment_tva.toFixed(2));
            axios({
                method: 'POST',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/`,
                data: {
                    name: t("header.adjustment_product_name"),
                    product_type: 20,
                    step: null,
                    provider_name: null,
                    provider_id: null,
                    start_date: null,
                    end_date: null,
                    address: null,
                    stars: null,
                    breakfast_included: false,
                    lunch_included: false,
                    diner_included: false,
                    rooms: null,
                    resort_fees: null,
                    resort_fees_currency: null,
                    flight_paste: null,
                    flight_segment: null,
                    cgos_reduction: false,
                    description: "",
                    custom_information: null,
                    group_passenger: undefined,
                    taxes: null,
                    nb_baggages: null,
                    picture_id: null,
                    forced_tva_price: adjustment_tva !== 0 ? adjustment_tva.toFixed(2) : undefined,
                    prices: [{
                        purchase_price: 0,
                        purchase_currency: trip_currency.id,
                        custom_rate: true,
                        custom_rate_type: "FIX",
                        custom_value: adjustment_margin.toFixed(2),
                        id: undefined
                    }]
                }
            }).then((response) => {
                dispatch({type: 'CART_ADD_MANUAL_ITEM', payload: response.data});
                enqueueSnackbar(t('cart-material.manual-product-added'), {variant: 'success'});
                dispatch({
                    type: 'CART_UPDATE_DATE',
                    payload: true
                });
                handlePriceVariation();
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t('cart-material.manual-product-not-added'), {variant: 'danger'});
            });
        } else {
            let price = parseFloat(getPrice(find_product.prices).selling_price);
            console.log('price:', price);
            let new_price = 0;
            let tva_percentage = tva_value / margin_value * 100;
            let tmp_selling = 0;
            if (newProduct.length !== 0) {
                newProduct.map((product) => {
                    new_price += diffPrice < 0 ? parseFloat(product.price.adjustment.selling_price) : parseFloat(product.price.adjustment.selling_price) * -1;
                });
            } else {
                let selling_price = parseFloat(getPrice(find_product.prices).selling_price);
                tmp_selling = selling_price - diffPrice;
            }
            // const adjustment_abs = newProduct.length !== 0 ? Math.abs(parseFloat(new_price) + parseFloat(price)) : Math.abs(tmp_selling);
            const adjustment_abs = (diffPrice * -1) + parseFloat(price);
            const adjustment_tmp = (tva_value + margin_value - adjustment_abs);
            // const adjustment_margin = (tva_percentage !== 0 ? adjustment_tmp / (1 + (tva_percentage / 100)) : adjustment_abs) * -1;
            // const adjustment_tva = tva_percentage !== 0 ? (adjustment_tmp - (adjustment_tmp / (1 + (tva_percentage / 100)))) * -1 : 0;
            const adjustment_margin = (tva_percentage !== 0 ? adjustment_abs / (1 + (tva_percentage / 100)) : adjustment_abs);
            const adjustment_tva = tva_percentage !== 0 ? (adjustment_abs - adjustment_margin) : 0;
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${find_product.id}/`,
                data: {
                    forced_tva_price: adjustment_tva !== 0 ? adjustment_tva : undefined,
                    prices: [{
                        purchase_price: 0,
                        purchase_currency: trip_currency.id,
                        custom_rate: true,
                        custom_rate_type: "FIX",
                        custom_value: adjustment_margin.toFixed(2),
                        id: find_product.prices[GetPricesId(find_product.prices, user.client, user, true)].id
                    }]
                }
            }).then((response) => {
                dispatch({type: 'CART_EDIT_MANUAL_ITEM', payload: response.data});
                enqueueSnackbar(t('cart-material.manual-product-edited'), {variant: 'success'});
                dispatch({
                    type: 'CART_UPDATE_DATE',
                    payload: true
                });
                handlePriceVariation();
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t('cart-material.manual-product-not-added'), {variant: 'danger'});
            });
        }
    }
    const createAmendment = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            setLoading(true);
            let id = GetCookie("trip_id");
            let version = displayed_version;
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${version}/copy/?copy_booked_product=true`,
            }).then(function (response) {
                console.log('data:', response);
                let request = {
                    name: null,
                    expected_deposit: null,
                    has_manual_expected_deposit: false
                };
                if (['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'connections', 'terreslointaines'].includes(quotation_code) && data_trip.status_contract === "CONFIRMED" && data_trip.status_modification === "FIXED_PV") {
                    request.status_contract = "CONFIRMED";
                    request.status_modification = "AMENDMENT";
                    request.status_booking_progress = "IN_PROGRESS";
                }
                getProductsToDelete();
                version = response.data.trip.current_version;
                axios({
                    method: "PATCH",
                    headers: headers,
                    data: JSON.stringify(request),
                    url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${version}/`,
                }).then(function (response1) {
                    enqueueSnackbar(t("header.success_to_create_new_version"), {
                        variant: "success",
                    });
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${id}/`,
                        headers: headers,
                        data: {
                            current_version: displayed_version
                        }
                    }).then(function(response2){
                        setLoading(false);
                        resetCookie(response1.data, id);
                        dispatch(SetTripInfo(response2.data));
                    }).catch(function(error){
                        console.log(error.response);
                    })

                }).catch(function (error) {
                    //TODO: show snackbar error
                    console.log('error:', error);
                    enqueueSnackbar(t("header.failed_to_create_new_version"), {
                        variant: "error",
                    });
                });
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
                enqueueSnackbar(t("header.failed_to_create_new_version"), {
                    variant: "error",
                });
            });
        }
    }
    return (
        <Dialog open={ openPriceVariation } fullWidth maxWidth={ 'md' } onClose={ handlePriceVariation }>
            <DialogTitle>
                <Grid container justify={ "space-between" } alignItems={ "center" }>
                    {
                        fullScreen ? (
                            <Grid item>
                                <IconButton edge={ "start" } onClick={ handlePriceVariation }><NavigateBefore/></IconButton> { t("accommodation.additional_information") }
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item>
                                    { t('cart-material.selling-price-changed') } :
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={ handlePriceVariation }><Close/></IconButton>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                    <Grid item xs={6}>
                        <Grid container item direction={"row"} justify={"center"} alignItems={"center"}>
                            <Button variant={ 'contained' } onClick={createManualProduct}>{find_product === undefined ? t("header.adjustment_product_creation") : t("header.adjustment_product_modification")}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container item direction={"row"} justify={"center"} alignItems={"center"}>
                            <Button variant={ 'contained' } disabled={loading} onClick={createAmendment} style={{position: 'relative'}}>{t("header.amendment_creation")}</Button>
                            {loading && <CircularProgress size={20} style={{position: 'absolute'}}/>}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions></DialogActions>
        </Dialog>
    )
}
export default CartPriceVariationDialog;