import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import GetPictureBySize from "../Functions/GetPictureBySize";

const useStyles = makeStyles({
    inline: {
        display: "inline-block"
    },
    buttonBase: {
        height: '100%',
        width: '100%',
        backgroundColor: 'black'
    }
});

const MosaicPicture = ({open, onClose, pictures, openModal}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Dialog open={ open } onClose={() => { onClose(pictures); }} fullScreen disableEscapeKeyDown>
            <DialogTitle onClose={() => { onClose(pictures); }}>
                <Button variant={ "contained" } color={ "default" } startIcon={ <Close/> } onClick={() => { onClose(pictures); }} disableElevation>
                    <Typography className={ classes.inline }>{ t("global.close") }</Typography>
                </Button>
            </DialogTitle>
            <DialogContent dividers>
                <GridList cellHeight={ 200 } spacing={ 2 }>
                    {
                        pictures.map((picture, index_picture) => {
                            return (
                                <GridListTile key={ index_picture } cols={ (index_picture % 3 === 0 ? 2 : 1) }>
                                    <ButtonBase className={ classes.buttonBase } onClick={() => { openModal(pictures, index_picture); }}>
                                        <img src={ GetPictureBySize(picture, "URL") } width={ "100%" } alt={ "hotel picture" }/>
                                    </ButtonBase>
                                </GridListTile>
                            )
                        })
                    }
                </GridList>
            </DialogContent>
        </Dialog>
    );
};

export default React.memo(MosaicPicture);