import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import HotelInfoMapRun from "../../SummaryQuotation/Summary/Functions/HotelInfoMapRun";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    hotelInfoMap: {
        width: "100%",
        height: 350
    }
}));
type HotelInfoMapProps = {
    info_hotel: any
}
const HotelInfoMap = (props: HotelInfoMapProps) => {
    const {info_hotel} = props;
    const { t } = useTranslation();
    const classes = useStyles();
    
    useEffect(() => {
        if (info_hotel !== null && !info_hotel.is_manual) {
            HotelInfoMapRun(null, info_hotel);
        }
    });

    return (
        <Grid item xs={12}>
            {/* <h1>{ t("summary.google_map") }</h1> */}
            <div className={ classes.hotelInfoMap } id={ "hotel-info-map-" + info_hotel.id }/>
        </Grid>
    )
}
export default React.memo(HotelInfoMap);