import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from "@material-ui/core/IconButton";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import CloseIcon from '@material-ui/icons/Close';

import Transition from "../../../Common/Transition";

import { SetCheckedLuggage } from "../../../../Actions/FlightSearch";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    verticalSpacer: {
        padding: "8px 0px"
    },
    horizontalSpacer: {
        padding: "0px 16px"
    },
    filterButton: {
        border: "1.03872px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: 31.1617,
        padding: "8px 12px"
    },
    closeMenu: {
        marginRight: 5
    }
}))

const LuggageButton = (({ }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const checked_luggage = useSelector(store => store.flight_search.checked_luggage)

    const [openLuggages, setOpenLuggages] = useState(null);

    return(
        <Grid item>
            <Button size="small" className={classes.filterButton} onClick={(event) => setOpenLuggages(event.currentTarget)}>
                <WorkOutlineIcon className={classes.genericText} />
                <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`} style={{ paddingLeft: 8, textTransform: "capitalize" }}>
                    { t("global.luggages") }
                </div>
            </Button>
            {
                isHandHeld && (
                    <Dialog open={Boolean(openLuggages)} fullScreen TransitionComponent={Transition}>
                        <DialogTitle>
                            <Grid container alignItems="center" justify="center">
                                <Grid item xs={2} />
                                <Grid item xs={8} className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                    { t("flight_search.luggage_check") }
                                </Grid>
                                <Grid item xs={2} className={classes.textRight}>
                                    <IconButton size="small" className={classes.closeMenu} onClick={() => setOpenLuggages(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ padding: "0px 18px 0px 8px" }}>
                            <FormGroup className={classes.horizontalSpacer}>
                                <FormControlLabel 
                                    control={<Checkbox sx={{
                                        '&.Mui-checked': {
                                            color: `${main_color}`,
                                        },
                                    }} checked={checked_luggage.disabled} onChange={() => dispatch(SetCheckedLuggage("disabled"))} name="checked_luggage_disabled" size="small" />}
                                    label={t("flight_search.filter_null")}
                                    classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                {
                                    checked_luggage.zero !== null && (
                                        <FormControlLabel 
                                            control={<Checkbox sx={{
                                                '&.Mui-checked': {
                                                    color: `${main_color}`,
                                                },
                                            }} checked={checked_luggage.zero} onChange={() => dispatch(SetCheckedLuggage("zero"))} name="checked_luggage_zero" size="small" />}
                                            label={t("flight_groups.zero_luggage")}
                                            classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                            />
                                    )
                                }
                                {
                                    checked_luggage.included !== null && (
                                        <FormControlLabel 
                                            control={<Checkbox sx={{
                                                '&.Mui-checked': {
                                                    color: `${main_color}`,
                                                },
                                            }} checked={checked_luggage.included} onChange={() => dispatch(SetCheckedLuggage("included"))} name="checked_luggage_included" size="small" />}
                                            label={t("flight_groups.included_luggage")}
                                            classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                        />
                                    )
                                }
                                {
                                    checked_luggage.one !== null && (
                                        <FormControlLabel 
                                            control={<Checkbox sx={{
                                                '&.Mui-checked': {
                                                    color: `${main_color}`,
                                                },
                                            }} checked={checked_luggage.one} onChange={() => dispatch(SetCheckedLuggage("one"))} name="checked_luggage_one" size="small" />}
                                            label={t("flight_groups.one_luggage")}
                                            classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                        />
                                    )
                                }
                                {
                                    checked_luggage.two !== null && (
                                        <FormControlLabel 
                                            control={<Checkbox sx={{
                                                '&.Mui-checked': {
                                                    color: `${main_color}`,
                                                },
                                            }} checked={checked_luggage.two} onChange={() => dispatch(SetCheckedLuggage("two"))} name="checked_luggage_two" size="small" />}
                                            label={t("flight_groups.two_luggages")}
                                            classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                        />
                                    )
                                }
                            </FormGroup>
                        </DialogContent>
                    </Dialog>
                )
            }
            {
                !isHandHeld && (
                    <Menu 
                        id="luggages-menu"
                        anchorEl={openLuggages}
                        keepMounted
                        open={Boolean(openLuggages)}
                        onClose={() => setOpenLuggages(false)}
                        disableScrollLock={true}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        >
                        <Grid container alignItems="center" justify="center" style={{ width: 300 }}>
                            <Grid item xs={2} />
                            <Grid item xs={8} className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                { t("flight_search.luggage_check") }
                            </Grid>
                            <Grid item xs={2} className={classes.textRight}>
                                <IconButton size="small" className={classes.closeMenu} onClick={() => setOpenLuggages(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider />
                        <FormGroup className={classes.horizontalSpacer}>
                            <FormControlLabel 
                                control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: `${main_color}`,
                                    },
                                }} checked={checked_luggage.disabled} onChange={() => dispatch(SetCheckedLuggage("disabled"))} name="checked_luggage_disabled" size="small" />}
                                label={t("flight_search.filter_null")}
                                classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                />
                            {
                                checked_luggage.zero !== null && (
                                    <FormControlLabel 
                                        control={<Checkbox sx={{
                                            '&.Mui-checked': {
                                                color: `${main_color}`,
                                            },
                                        }} checked={checked_luggage.zero} onChange={() => dispatch(SetCheckedLuggage("zero"))} name="checked_luggage_zero" size="small" />}
                                        label={t("flight_groups.zero_luggage")}
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                        />
                                )
                            }
                            {
                                checked_luggage.included !== null && (
                                    <FormControlLabel 
                                        control={<Checkbox sx={{
                                            '&.Mui-checked': {
                                                color: `${main_color}`,
                                            },
                                        }} checked={checked_luggage.included} onChange={() => dispatch(SetCheckedLuggage("included"))} name="checked_luggage_included" size="small" />}
                                        label={t("flight_groups.included_luggage")}
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                )
                            }
                            {
                                checked_luggage.one !== null && (
                                    <FormControlLabel 
                                        control={<Checkbox sx={{
                                            '&.Mui-checked': {
                                                color: `${main_color}`,
                                            },
                                        }} checked={checked_luggage.one} onChange={() => dispatch(SetCheckedLuggage("one"))} name="checked_luggage_one" size="small" />}
                                        label={t("flight_groups.one_luggage")}
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                )
                            }
                            {
                                checked_luggage.two !== null && (
                                    <FormControlLabel 
                                        control={<Checkbox sx={{
                                            '&.Mui-checked': {
                                                color: `${main_color}`,
                                            },
                                        }} checked={checked_luggage.two} onChange={() => dispatch(SetCheckedLuggage("two"))} name="checked_luggage_two" size="small" />}
                                        label={t("flight_groups.two_luggages")}
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                )
                            }
                        </FormGroup>
                    </Menu>
                )
            }
        </Grid>
    )
})

export default React.memo(LuggageButton);