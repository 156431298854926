
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDrop } from "react-dnd";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Moment } from "moment-timezone";
import { CartClientTimeModal } from "./CartClientTimeModal";
import { CartConstructionContentsFormProductsInputItem } from "./CartConstructionContentsFormProductsInputItem";
import { useCartConstructionContentsFormProducts } from "./utils/cartConstructionContentsFormProducts";
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";
import { CartConstructionContentsFormProductsInputDragResult } from "./CartConstructionContentsFormProductsInput";

type Props = {
    index: number,
    content: ItineraryContentStep,
    onClose: () => void
}

export function CartConstructionContentsFormReorderProductsModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [inputs, setInputs] = useState<typeof contents>([]);
    const [movedInput, setMovedInput] = useState<typeof inputs[number] | null>(null);
    const [nextStep, setNextStep] = useState(false);
    const [minStartDate, setMinStartDate] = useState<Moment | null>(null);
    const [maxStartDate, setMaxStartDate] = useState<Moment | null>(null);
    const contents = useCartConstructionContentsFormProducts(props.content, props.index);
    const onDrop = useCallback((item: CartConstructionContentsFormProductsInputDragResult) => {
        const itemInput = inputs.find((stateItem) => {
            return stateItem.item.type === item.product.type &&
                   stateItem.item.item.product_type === item.product.item.product_type &&
                   stateItem.item.item.id === item.product.item.id;
        });
        const contentsKeys = contents.map((item) => item.key);
        const inputsKeys = inputs.map((item) => item.key);
        if (
            itemInput &&
            contentsKeys.length === inputsKeys.length &&
            !contentsKeys.every((key, index) => {
                return key === inputsKeys[index]!;
            })
        ) {
            setNextStep(true);
            setMovedInput(itemInput);
        } else {
            setNextStep(false);
            setMovedInput(null);
        }
    }, [inputs, contents, setNextStep, setMovedInput]);

    useEffect(() => {
        if (!nextStep) {
            setInputs(contents);
        }
    }, [contents, nextStep]);

    useEffect(() => {
        if (movedInput) {
            const index = inputs.findIndex((item) => {
                return item.key === movedInput?.key;
            });
            const above = inputs[index - 1];
            const below = inputs[index + 1];
            setMinStartDate(
                above &&
                above.item.type !== 'accommodation' &&
                above.item.type !== 'manual-accommodation' ?
                    window.moment.utc(above.item.item.start_date) :
                    null
            );
            setMaxStartDate(
                below &&
                below.item.type !== 'accommodation' &&
                below.item.type !== 'manual-accommodation' ?
                    window.moment.utc(below.item.item.start_date) :
                    null
            );
        }
    }, [
        inputs,
        contents,
        movedInput
    ]);

    if (nextStep && movedInput) {
        return (
            <CartClientTimeModal
                type={
                    movedInput.item.type !== 'manual' &&
                    movedInput.item.type !== 'manual-accommodation' ?
                        'normal' :
                        'manual'
                }
                product={movedInput.item.item as any} //TODO: fix this type
                onGoBack={() => setNextStep(false)}
                onClose={props.onClose}
                initialStartDate={
                    minStartDate?.toISOString() ??
                    maxStartDate?.toISOString() ??
                    undefined
                }
                minStartDate={minStartDate ?? undefined}
                maxStartDate={maxStartDate ?? undefined}
                enablePreviousButton
            />
        );
    }

    return (
        <Dialog
            open
            maxWidth="sm"
            onClose={props.onClose}
            fullWidth
        >
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography fontWeight="bold">
                        {t<string>('cart-material.cart-construction-reorder-products-title')}
                    </Typography>
                    <IconButton onClick={props.onClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {
                    inputs.map((item, index, array) => (
                        <Item
                            key={item.key}
                            index={index}
                            item={item}
                            inputs={array}
                            onChangeInputs={setInputs}
                            onDrop={onDrop}
                        />
                    ))
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    {t<string>('shared.cancel')}
                </Button>
                <Button>
                    {t<string>('global.continue')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

type ItemProps = {
    index: number,
    item: ReturnType<typeof useCartConstructionContentsFormProducts>[number],
    inputs: ReturnType<typeof useCartConstructionContentsFormProducts>,
    onChangeInputs: React.Dispatch<React.SetStateAction<ReturnType<typeof useCartConstructionContentsFormProducts>>>,
    onDrop: (item: CartConstructionContentsFormProductsInputDragResult) => void,
}

function Item(props: ItemProps): JSX.Element {
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, drop] = useDrop<CartConstructionContentsFormProductsInputDragResult>(() => ({
        accept: 'cart-construction-contents-product-reorder',
        collect(monitor) {
            return {
                isDragging: monitor.canDrop()
            };
        },
        hover(item) {
            props.onChangeInputs((state) => {
                const itemIndex = state.findIndex((stateItem) => {
                    return stateItem.item.type === item.product.type &&
                           stateItem.item.item.product_type === item.product.item.product_type &&
                           stateItem.item.item.id === item.product.item.id;
                });
                const itemInput = state[itemIndex];
                if (
                    itemIndex >= 0 &&
                    itemInput &&
                    itemInput.key !== props.item.key &&
                    props.item.item.type !== 'accommodation' &&
                    props.item.item.type !== 'manual-accommodation'
                ) {
                    if (itemIndex > props.index) {
                        return [
                            ...state.slice(0, props.index),
                            itemInput,
                            ...state.slice(props.index).filter((stateItem) => {
                                return stateItem.key !== itemInput.key;
                            })
                        ];
                    } 
                    return [
                        ...state.slice(0, props.index + 1).filter((stateItem) => {
                            return stateItem.key !== itemInput.key;
                        }),
                        itemInput,
                        ...state.slice(props.index + 1)
                    ];
                }
                return state;
            });
        }
    }), [
        props.index,
        props.item,
        props.onChangeInputs,
        props.onDrop
    ]);

    return (
        <Box
            ref={drop} sx={{ marginBottom: 1.5 }}
        >
            <CartConstructionContentsFormProductsInputItem
                {...props.item}
                itemDragType="cart-construction-contents-product-reorder"
                onDrop={props.onDrop}
                minimal
            />
        </Box>
    );
}
