export default (id, data, to_show_version) => {
	if (to_show_version !== undefined && to_show_version !== null) {
		for (let i = 0; i < data.length; i++) {
			if (data[i].id === to_show_version) {
				return i;
			}
		}
	}
	for (let i = 0; i < data.length; i++) {
		if (data[i].id === id) {
			return i;
		}
	}
	return 0;
};
