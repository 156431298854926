//Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
//Core
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const CartManualProductTvaMargin = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tva_margin = useSelector(state => state.cart.manual_product_tva_margin);
    const onPurchasePriceChange = event => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_TVA_MARGIN', payload: event.target.value });
    };

    return (
        <Grid item xs={ 6 }>
            {
                !props.isForProposition &&
				<FormControl fullWidth variant={ 'outlined' }>
				    <InputLabel htmlFor={ 'manual-product-purchase-price' }>{ t('cart-material.manual-product-tva-margin') }</InputLabel>
				    <OutlinedInput
				        id={ 'manual-product-tva-margin' }
				        type={ 'number' }
				        onChange={ onPurchasePriceChange }
				        label={ t('cart-material.manual-product-tva-margin') }
				        value={ tva_margin }
				    />
				</FormControl>
            }
        </Grid>
    );
};

export default CartManualProductTvaMargin;
