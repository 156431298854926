import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ActivityInfoMapRun from "./ActivityInfoMapRun";
import { Grid } from "@mui/material";

const useStyles = makeStyles(theme => ({
    activityInfoMap: {
        width: "100%",
        height: 350
    }
}));
type ActivityInfoMapProps = {
    info: any
}
const ActivityInfoMap = (props: ActivityInfoMapProps) => {
    const {info} = props;
    const { t } = useTranslation();
    const classes = useStyles();
    useEffect(() => {
        if (info !== null) {
            ActivityInfoMapRun(info);
        }
    }, [])

    return (
        <Grid item xs={12}>
            <div className={ classes.activityInfoMap } id={ "activity-info-map-" + info.id }/>
        </Grid>
    )
}
export default React.memo(ActivityInfoMap);