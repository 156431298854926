//---- Dependencies ----//
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

//---- Functions ----//
import SetInitialData from "../../Itinerary/Functions/SetInitialData.js";
import AddStepInfo from "../../Itinerary/Functions/AddStepInfo.js";
import SelectTransport from "../../Itinerary/Functions/SelectTransport.js";
import CheckCars from "../../Itinerary/Functions/CheckCars.js";
import Notify from "../../Common/Notify";

//---- Actions ----//
import { SetCarsPanel, SetSelectedStep, ItineraryError } from "../../../Actions/CarsSearch";


//---- CSS ----//
import "../../../Style/TransportPanel.css";
import "../../../Style/CarsSearch.css";

@connect((store) => {
    return {
        itinerary_list: store.itinerary.itinerary_list,
        cars_steps: store.cars_search.steps,
        departure_destination: store.itinerary.departure_destination,
        return_destination: store.itinerary.return_destination,
        cars_panel: store.cars_search.cars_panel,
        selectTransportStatus: store.itinerary.selectTransportflag,
        itinerary_error: store.cars_search.itinerary_error,
        user: store.user.user
    };
})
@withTranslation()

class CarStep extends React.Component {
	constructor(props, context) {
	    super(props, context);
	    this.state = {
	        check_cars: null
	    };
	}
	componentWillMount() {
		const { itinerary_list, departure_destination, return_destination, dispatch } = this.props;
		this.getInitialTransport();
		dispatch(ItineraryError(false));
		SetInitialData(itinerary_list, departure_destination, return_destination, dispatch);
	}
	componentDidUpdate(prevProps) {
		const { itinerary_list, departure_destination, return_destination, dispatch, cars_steps, selectTransportStatus } = this.props;
		if (prevProps.selectTransportStatus !== selectTransportStatus) {
		    SetInitialData(itinerary_list, departure_destination, return_destination, dispatch, cars_steps);
		}
		if (prevProps.cars_steps !== cars_steps) {
		    AddStepInfo(cars_steps, dispatch);
		}
	}
	componentWillUnmount() {
		const { dispatch } = this.props;
		dispatch(SetCarsPanel(0));
		this.setState({check_cars: null});
	}
	getInitialTransport() {
	    const { itinerary_list } = this.props;
	    let initial_choice = [];
	    itinerary_list.map((itinerary, itinerary_index) => {
	        if (itinerary.step_type !== "END") {
	            initial_choice.push({
	                index: itinerary_index,
	                data: itinerary.r2r_json,
	                route: itinerary.r2r_json.name_string
	            });
	        }
	    });
	    this.setState({ initial_choice: initial_choice });
	}
	selectStep(itinerary_index, step_index) {
	    const { cars_steps, itinerary_list, dispatch, t } = this.props;
	    const initial_choice = this.state.initial_choice;
	    // if (!cars_steps[step_index].checked) {
	        itinerary_list[itinerary_index].r2r_json.data.routes.map((route) => {
	            if (route.segments.length === 1) {
	                route.segments.map((segment) => {
	                    if (itinerary_list[itinerary_index].r2r_json.data.vehicles[segment.vehicle].kind === "car") {
	                        let choosed_transport = route;
	                        let transport = itinerary_list[itinerary_index].r2r_json;
	                        SelectTransport(choosed_transport, transport, itinerary_index, itinerary_list, dispatch, t);
	                    }
	                });
	            }
	        });
	    // }
	    // else {
	        // itinerary_list[itinerary_index].r2r_json.data.routes.map((route) => {
	        //     if (route.name === initial_choice[itinerary_index].route) {
	        //         let choosed_transport = route;
	        //         let transport = itinerary_list[itinerary_index].r2r_json;
	        //         SelectTransport(choosed_transport, transport, itinerary_index, itinerary_list, dispatch, t);
	        //     }
	        // });
	    // }
	    AddStepInfo(cars_steps, dispatch);
	    dispatch(SetSelectedStep(itinerary_index));
	}
	getVehicles(step) {
		let vehicles = step.vehicles;
		let icon = [];
		if (step.vehicle.kind === "car") {
			let segments = step.segments;
			segments.map((segment, index) => {
				icon.push(
					<img src={"/Img/transport/route-" + vehicles[segment.vehicle].kind + ".png"} height="25px" key={"icons-vehicles-" + (segments.length === 1 ? index + 1 : index)}/>
				);
			});
		}
		else {
			let routes = step.data.routes;
			let tmp;
			let transport = [];
			step.data.routes.map((route) => {
				route.segments.find((segment) => {
					if (vehicles[segment.vehicle].kind === "car" || vehicles[segment.vehicle].kind === "ferry") {
						tmp = route;
					}
				})
			});
			tmp.segments.map((segment, index) => {
				if (tmp.segments.length === 1 && vehicles[segment.vehicle].kind === "ferry") {
					icon.push(
						<img src={"/Img/transport/route-car" + ".png"} height="25px" key={"icons-vehicles-" + (index * 2)}/>
					);
				}
				if (!transport.includes(vehicles[segment.vehicle].kind)) {
				    transport.push(vehicles[segment.vehicle].kind);
					icon.push(
						<img src={"/Img/transport/route-" + vehicles[segment.vehicle].kind + ".png"} height="25px" key={"icons-vehicles-" + ((index * 2) + 1)}/>
					);
				}
			});
		}
		return icon;
	}
	checkedSelected() {
		const { cars_steps, router, t } = this.props;
		let checked = false;
		cars_steps.map((step) => {
			if (step.checked) {
				checked = true;
			}
		});
		if (!checked) {
			Notify(t("itinerary.selected_step"), { status: "danger", pos: "top-center"});
		}
		else {
	        router.push(`/${window.url_name}/apps/cars-search`)
		}

	}
	render() {
		const { cars_panel, itinerary_error, cars_steps, itinerary_list, selectTransportStatus, user, router, t, dispatch } = this.props;
		let tmp = "fetching"
		console.log("cars_steps:", cars_steps);
  		return (
  			<div>
  				<div className={ "cars-title ft-relative" }>
					<button className={"ft-btn-return uppercase ft-white" + (cars_panel === 0 ? " cars-steps-return" : "")} onClick={() => { cars_panel === 0 ?  router.push(`/${window.url_name}/apps/itinerary`) : dispatch(SetCarsPanel(0)); }}>{t("global.return")}</button>
					{
						cars_panel === 0 && (
							<h2 className={ "text-center ft-gray" }>{ t("cars.cars_steps") }</h2>
						)
					}
  				</div>
				<div className={"container-choose-flight cars-steps-container absolute-center"}>
					{
						cars_panel === 0 && (
							<Fragment>
			                    <div className={"row cars-steps-choice"}>
			                         <div className={"block-center text-center flight-choose-panel-card col-md-4"}>
			                            <i className={"icon-028-pin flight-choose-panel-icon cars ft-gray"} />
			                            <p className={"font-15"}>{t("itinerary.step_car")}</p>
			                            <div className={"btn-orange car-choose-btn-position"} onClick={()=> { router.push(`/${window.url_name}/apps/cars-search-one-step`); }}> {t("flight_search.select")} </div>

			                        </div>
			                        {
			                        	cars_steps.length > 0 && (
					                        <div className={"block-center text-center flight-choose-panel-card col-md-4"}>
					                            <i className={"icon-itinerary flight-choose-panel-icon ft-gray"} />
					                            <p className={"font-15"}>{t("itinerary.itinerary_car")}</p>
					                            <div className={"btn-orange car-choose-btn-position"} onClick={()=> { dispatch(SetCarsPanel(1)); }}> {t("flight_search.select")} </div>
					                        </div>
			                        	)
			                        }
			                    </div>
							</Fragment>
						)
					}
					{
						cars_panel === 1 && (
							<Fragment>
							{
							    itinerary_error && (
							    	<Fragment>						    		
								        <div className={"bold ft-mb30 text-center ft-font-25"}>
								            {t("itinerary.error.error_destination")}
								        </div>
								        <div className={ "text-center" }>
			        						<button className={"btn-orange uppercase ft-white" } onClick={() => { router.push(`/${window.url_name}/apps/itinerary`) }}>{t("cart.return_to_itinerary")}</button>
								        </div>
							    	</Fragment>
							    )
							}
							{
								!itinerary_error && (
									<Fragment>
										<div className={"bold ft-mb30 text-center ft-font-25"}>
										    { selectTransportStatus === "fetching" ? t("flight_search.reload_price") :  t("cars.step_title")}
										</div>
										<div className={"cars-steps"}>
										    {
										        selectTransportStatus !== "fetching" && (
										            cars_steps.map((step, step_index) => {
										            	let vehicle = this.getVehicles(step.r2r_json);
										                return (
										                	<div className= {"row"} key={step.index}>
										                		<div className="col-md-2">
										                        	{vehicle}
										                		</div>
										                		<div className="col-md-10">
												                    <label className={ "flight-search-checkbox" }> { step.origin } <i className={ "icon-103-long-arrow-pointing-to-the-right" }></i> { step.destination } 
												                    {
												                    	(user.client_full.type === 1 || user.client_full.type === 3) && (
														                    <span className={ "italic" }>{ " | " + t("global.from") + " " + moment.utc(step.start_date_origin).format("DD-MM-YYYY") + " " + t("global.to") + " " + moment.utc(step.end_date_dest).format("DD-MM-YYYY")}</span> 
												                    	)
												                    }
												                        <input type={ "checkbox" } checked={ step.checked } onChange={ () => this.selectStep(step.index, step_index)}
												                            value={ step.origin + " - " + step.destination }/>
												                        <span className={ "flight-search-check-mark-cars" }/>
												                    </label>
												                </div>								                		
										                	</div>
										                );
										            })
										        )
										    }
										    {
										        selectTransportStatus === "fetching" && (
										            <div className="spinner-orange ft-mt75"></div>
										        )
										    }
										</div>
										{
										    selectTransportStatus !== "fetching" && (
										        <div className={"ft-relative ft-mt75"}>
										            <div className={"row ft-no-margin"}>
										            {
										                //<button className={"ft-cancel-btn uppercase col-md-4"} onClick={() => {console.log('cancel');}}>{t("global.cancel")}</button>                                                            
										            }
										                <button className={"btn-orange uppercase col-md-4 offset-md-4"} onClick={() => {
										                // 	CheckCars(cars_steps, itinerary_list, router, t, dispatch);
										                // 	this.setState({check_cars: true});
										                	this.checkedSelected();
										                }}>
										                {
		                                                    // this.state.check_cars ? <div className={ "spinner-orange" }/> :
		                                                    t("global.validate")
		                                                }</button>
										            </div>
										        </div>
										    )
										}										
									</Fragment>
								)
							}
							</Fragment>
						)
					}
                </div>
   			</div>
  		);
 	}
}
export default CarStep;
