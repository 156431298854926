import axios from "axios";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest.js";

export const getPreferences = (client_id, module_name, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: 'GET',
            url: `${API_HREF}client/${client_id}/user-table-preference/`,
            headers,
            params: {
                limit: 1000,
                module: module_name
            }
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

export const addPreferences = (client_id, preferences, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: 'POST',
            url: `${API_HREF}client/${client_id}/user-table-preference/`,
            headers,
            data: preferences
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

export const updatePreference = (client_id, preferences, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: 'POST',
            url: `${API_HREF}client/${client_id}/user-table-preference/array_patch/`,
            headers,
            data: preferences,
            params: {
                limit: 100000
            }
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};
