import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import { Box } from '@mui/material';
import { isNumber } from 'lodash';

import SelectLead from './SelectLead.js';
import PaxWeight from './PaxWeight.js';
import { PaxHeight } from './PaxHeight.tsx';
import { HotelRequired } from './HotelRequired.tsx';
import { BookingActivityMeetingPoint } from './BookingActivityMeetingPoint.tsx';
import { BookingActivityReturnPoint } from './BookingActivityReturnPoint.tsx';
import { isProductPackaged } from '../CartMaterial/utils/isProductPackaged';

import { ActivitiesQuestions } from './ActivitiesQuestions.tsx';
import { ActivitiesCeetizQuestions } from './ActivitiesCeetizQuestions.tsx';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight700: {
        fontWeight: 700
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize14: {
        fontSize: 14
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter: {
        textAlign: "center"
    },
    genericBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box"
    },
    genericPadding: {
        padding: "4px 8px"
    }
}));

const BookingActivityInfo = ({ tempTravelers, setTempTravelers }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));
    const locale = useSelector((state) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1;
    });

    const trip = useSelector(store => store.trip.data_trip);
    const to_book = useSelector(store => store.cart.to_book);
    const provider_list = useSelector(store => store.trip.providers);
    const manual_provider_list = useSelector(store => store.trip.manual_providers);
    const [activitiesToBook, setActivitiesToBook] = useState([]);
    const connectedPackageProducts = useMemo(() => {
        return activitiesToBook.filter((activity) => {
            return isProductPackaged({
                product: activity,
                stackInfos: trip?.stack_info ?? null,
                connected: true
            });
        });
    }, [activitiesToBook]);

    useEffect(() => {
        let temp_activities = [];
        to_book.map((product) => {
            if (product.is_displayed === true && (product.booking_status === null || (product.booking_status !== null && product.booking_status.status_booking === "ERROR"))) {
                if ((product.product_type === 11 || product.product_type === 12) && (product.group_passenger?.default === false || product.custom_product?.need_pax_weight === true || product.custom_product?.hotel_required === true || product.cust_variant?.need_pax_height || product.cust_variant?.need_pax_weight === true)) {
                    let temp_product = Object.assign({}, product);
                    temp_product.group_passenger.lead_traveler_info = temp_product.group_passenger?.lead_traveler_info !== null ? temp_product.group_passenger?.lead_traveler_info : "";
                    temp_product.group_passenger.lead_traveler = temp_product.group_passenger?.lead_traveler !== null ? temp_product.group_passenger?.lead_traveler : "";
                    temp_activities.push(temp_product);
                }
            }
        });

        setActivitiesToBook(temp_activities);
    }, [to_book]);

    return (
        <Grid>
            {
                activitiesToBook.filter((product, index, array) => {
                    return !isProductPackaged({
                        product: product,
                        stackInfos: trip?.stack_info ?? null,
                        connected: true
                    }) ||
                    array.slice(0, index).findIndex((product) => {
                        return isProductPackaged({
                            product: product,
                            stackInfos: trip?.stack_info ?? null,
                            connected: true
                        });
                    }) < 0;
                }).length !== 0 && (
                    <Grid className={`${classes.genericPadding} ${classes.genericBorder}`} style={{ marginBottom: 12 }}>
                        <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.upperCase} ${classes.letterSpacing}`}>
                            { t("booking.activity_info") }
                        </Grid>
                        <Grid style={{ marginBottom: 8 }}>
                            {
                                activitiesToBook.map((activity, activity_index) => {
                                    console.log('activity:', activity);
                                    let provider = null;
                                    provider = provider_list.find(item => item.provider.id === activity.provider);
                                    if (provider === undefined) {
                                        provider = manual_provider_list.find(item => item.id === activity.provider);
                                    }
                                    if (activity.meeting_point !== undefined && activity.meeting_point !== null && activity.meeting_point !== "" && typeof activity.meeting_point === "string"){
                                        activity.meeting_point = JSON.parse(activity.meeting_point);
                                    }
                                    return(
                                        <Grid key={`activity-${activity.id}`} container alignItems="baseline" justify="space-between" style={{ padding: 8 }} spacing={ 2 }>
                                            <Grid item md={6} xs={12} className={`${classes.genericText} ${classes.fontWeight700} ${classes.fontSize14} ${classes.upperCase} ${classes.letterSpacing}`}>
                                                {
                                                    !isProductPackaged({
                                                        product: activity,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    <>
                                                        { t("cart.activity") + " - " + (activity_index + 1) + " " }
                                                        {
                                                            !activity.is_manual &&
                                                            (() => {
                                                                const localization = activity.custom_product?.localization?.find((item) => {
                                                                    return item.locale === locale;
                                                                });
                                                                if (localization) {
                                                                    return (localization?.title.length ?? 0) > 0 ?
                                                                        localization?.title :
                                                                        activity.custom_product?.title;
                                                                }
                                                                const poiLocalization = activity.localization?.find((item) => {
                                                                    return item.locale === locale;
                                                                });
                                                                return (poiLocalization?.title?.length ?? 0) > 0 ?
                                                                    poiLocalization?.title :
                                                                    activity.custom_product?.title ?? activity.name;
                                                            })()
                                                        }
                                                        {
                                                            activity.is_manual ?
                                                                activity.name :
                                                                null
                                                        } {
                                                            isProductPackaged({
                                                                product: activity,
                                                                stackInfos: trip?.stack_info ?? null
                                                            }) &&
                                                            <Box sx={{ color: '#2F80ED' }} component="span">
                                                                ({t('cart-material.cart-construction-package-name', { name: activity.stack_number })})
                                                            </Box>
                                                        }
                                                    </>
                                                }
                                                {
                                                    isProductPackaged({
                                                        product: activity,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    <Box sx={{ color: '#2F80ED' }} component="span">
                                                        {t('cart-material.cart-construction-connected-package')}
                                                    </Box>
                                                }
                                            </Grid>
                                            <Grid item md={6} xs={12} style={!downDesktop ? {} : { paddingTop: 8 }}>
                                                {
                                                    activity.group_passenger?.default === false && (
                                                        <>
                                                            {
                                                                !isProductPackaged({
                                                                    product: activity,
                                                                    stackInfos: trip?.stack_info ?? null,
                                                                    connected: true
                                                                }) &&
                                                                <SelectLead group={activity.group_passenger} mode={"activity"} product={activity} product_list={null} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                                                            }
                                                            {
                                                                isProductPackaged({
                                                                    product: activity,
                                                                    stackInfos: trip?.stack_info ?? null,
                                                                    connected: true
                                                                }) &&
                                                                <SelectLead
                                                                    group={activity.group_passenger}
                                                                    mode={"group"}
                                                                    product={null}
                                                                    product_list={connectedPackageProducts}
                                                                    tempTravelers={tempTravelers}
                                                                    setTempTravelers={setTempTravelers}
                                                                />
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    !isProductPackaged({
                                                        product: activity,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    (activity.is_manual === undefined || (activity.is_manual !== undefined && !activity.is_manual)) && !activity.is_custom && activity.custom_product?.need_pax_weight && (
                                                        <PaxWeight product={activity} group={activity.group_passenger} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                                                    )
                                                }
                                                {
                                                    !isProductPackaged({
                                                        product: activity,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    (activity.is_manual === undefined || (activity.is_manual !== undefined && !activity.is_manual)) && !activity.is_custom && activity.custom_product?.need_pax_height && (
                                                        <PaxHeight product={activity} group={activity.group_passenger} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                                                    )
                                                }
                                                {               
                                                    !isProductPackaged({
                                                        product: activity,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&                                     
                                                    (activity.is_manual === undefined || (activity.is_manual !== undefined && !activity.is_manual)) && activity.is_custom && activity.custom_product?.hotel_required && (
                                                        <HotelRequired product={activity} />
                                                    )
                                                }
                                                {             
                                                    !isProductPackaged({
                                                        product: activity,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&                                       
                                                    (activity.is_manual === undefined || (activity.is_manual !== undefined && !activity.is_manual)) && activity.is_custom && activity.variant !== null && activity.cust_variant.need_pax_weight && (
                                                        <PaxWeight product={activity} group={activity.group_passenger} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                                                    )
                                                }
                                                {              
                                                    !isProductPackaged({
                                                        product: activity,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&                                      
                                                    (activity.is_manual === undefined || (activity.is_manual !== undefined && !activity.is_manual)) && activity.is_custom && activity.variant !== null && activity.cust_variant?.need_pax_height && (
                                                        <PaxHeight product={activity} group={activity.group_passenger} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                                                    )
                                                }
                                                {
                                                    !isProductPackaged({
                                                        product: activity,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    !!activity.cust_variant?.meeting_point &&
                                                    <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize10} ${classes.upperCase} ${classes.letterSpacing} ${classes.textCenter}`} style={{ marginTop: 8 }}>
                                                        <BookingActivityMeetingPoint product={activity}/>
                                                    </Grid>
                                                }
                                                {
                                                    !isProductPackaged({
                                                        product: activity,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    !!activity.cust_variant?.return_point &&
                                                    <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize10} ${classes.upperCase} ${classes.letterSpacing} ${classes.textCenter}`} style={{ marginTop: 8 }}>
                                                        <BookingActivityReturnPoint product={activity}/>
                                                    </Grid>
                                                }
                                            </Grid>
                                            {
                                                provider !== undefined && provider.provider.code === 'viator' && activity.questions !== undefined && activity.questions.length > 0 && (
                                                    <Grid item xs={12} style={!downDesktop ? {} : { paddingTop: 8 }}>
                                                        <ActivitiesQuestions product={activity} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers}/>
                                                    </Grid>
                                                )
                                            }
                                            {
                                                provider !== undefined && provider.provider.code === 'ceetiz' && activity.questions !== undefined && activity.questions.length > 0 && (
                                                    <Grid item xs={12} style={!downDesktop ? {} : { paddingTop: 8 }}>
                                                        <ActivitiesCeetizQuestions product={activity} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers}/>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    );
};

export default React.memo(BookingActivityInfo);
