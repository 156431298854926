import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Checkbox,
    Collapse,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography,
    iconButtonClasses,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {
    CheckBox,
    CheckBoxOutlineBlankOutlined,
    Close,
    KeyboardArrowLeftOutlined,
    KeyboardArrowRightOutlined,
    MoreVertOutlined
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import axios from "axios";
import { isFunction, isNumber } from "lodash";
import { CartConstructionProductsTableItemMenu } from "../CartMaterial/CartConstructionProductsTableItemMenu";
import { CartProductPicturesModal } from "../CartMaterial/CartProductPicturesModal";
import { getTableItemBackgroundColor } from "../CartMaterial/utils/getTableItemBackgroundColor";
import CartDeleteModal from "../CartMaterial/CartDeleteModal";
import { CartConstructionProductsContext } from "../CartMaterial/utils/cartConstructionProductsContext";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import CheckResponse from "../Flight/FlightSelected/Functions/CheckResponse";
import RenderMap from "./RenderMap.js";
import RenderStation from "./RenderStation.js";
import MobileRenderStation from "./MobileRenderStation.js";

import { AccommodationCart } from "../Itinerary/objects/accommodationCart";
import { CarCart } from "../Itinerary/objects/carCart";
import { ManualProductAccommodationCart } from "../Itinerary/objects/manualProductAccommodationCart";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { TransferCart } from "../Itinerary/objects/transferCart";
import { PoiCart } from "../Itinerary/objects/poiCart";
import { Flight } from "../Itinerary/network/flight";
import { AssistanceCart } from "../Itinerary/objects/assistanceCart";
import { AppState } from "../../Reducers/Reducers";
import { CartConstructionReplaceProductContext } from "../CartMaterial/utils/cartConstructionReplaceProductContext";
import GetProvider from "../Common/Functions/GetProvider";

export type CarProductProps = {
    picture: string[],
    mainContent: React.ReactNode | ((sideActions: (minimal?: boolean) => React.ReactNode) => React.ReactNode),
    sideContent?: React.ReactNode,
    footer?: React.ReactNode,
    alerts?: React.ReactNode,
    margin?: React.ReactNode,
    children: React.ReactNode,
    direction?: 'horizontal' | 'vertical',
    disablePictures?: boolean,
    showEntirePictures?: boolean,
    alwaysOpen?: boolean
} & ({
    type: 'car',
    item: CarCart,
    index: number,
    getContractInfo: any,
    results: any
})

function usePrevious(value) {
    const ref = useRef();

    //Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); //Only re-run if value changes

    //Return previous value (happens before update in useEffect above)
    return ref.current;
}

export function CarProduct(props: CarProductProps): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const user = useSelector((state: AppState) => state.user.user);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const itinerary_type_details_data = useSelector((state: AppState) => state.itinerary_type.itinerary_type_details_data);
    const to_book = useSelector((state: AppState) => state.cart.to_book);
    const openProductsDetails = useSelector((state: AppState) => state.cartConstruction.openProductsDetails);
    const provider_list = useSelector((state: AppState) => state.trip.providers);
    const [showDetails, setShowDetails] = useState(false);
    const [isContract, setIsContract] = useState(false);
    const [currentPictureIndex, setCurrentPictureIndex] = useState(0);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [openPicturesModal, setOpenPicturesModal] = useState(false);
    const [tab_index, setTab] = useState(0);
    const [initialized, setInitialized] = useState(false);
    const [mobileStation, setMobileStation] = useState(false);
    const [station, setStation] = useState([]);
    const [hovered, setHovered] = useState(null);
    const [checked, setChecked] = useState(null);
    const [checked_return, setCheckedReturn] = useState(null);
    const prevItem = usePrevious({ initialized, showDetails });
    const replaceProductContext = useContext(CartConstructionReplaceProductContext);

    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const context = useContext(CartConstructionProductsContext);
    const contentRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);
    const nextTabRef = useRef<HTMLDivElement>(null);
    const contractRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    const main_color = JSON.parse(localStorage.getItem('config') ?? '{}').main_color;
    const color = getTableItemBackgroundColor(
        props.item,
        itinerary_type_details_data ?? { variant_name: [] }
    );
    let stations = null;
    let important_notice = "";
    props.results.map((provider) => {
        if (provider.provider_name === props.item.provider && provider.subprovider_name === props.item.subprovider) {
            stations = provider.stations;
            important_notice = provider.important_notice;
        }
    });
    let stations_arr = {
        pick_up: [],
        return: []
    };
    if (stations !== null) {
        stations.map((station) => {
            if (station.is_pickup_station) {
                stations_arr.pick_up.push(station);
            }
            if (station.is_return_station) {
                stations_arr.return.push(station);
            }
        });
    }
    stations_arr.pick_up = stations_arr.pick_up.sort((a, b) => {
        return Number(b.at_airport) - Number(a.at_airport);
    });
    stations_arr.return = stations_arr.return.sort((a, b) => {
        return Number(b.at_airport) - Number(a.at_airport);
    });
    function hasTextContent(node: Node): boolean {
        if (
            node.nodeType === Node.TEXT_NODE &&
            (node.textContent?.trim().length ?? 0) > 0
        ) {
            return true;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            for (let childNode of Array.from(node.childNodes)) {
                if (hasTextContent(childNode)) {
                    return true;
                }
            }
        }
        return false;
    };

    const SideActions = (minimal = false) => (
        <Stack
            direction={
                !minimal ?
                    { xs: 'row-reverse', md: 'column' } :
                    'row'
            }
            alignItems="flex-start"
        >
        </Stack>
    );
    const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
        setTab(newValue);
    };
    const handleToggle = (value: React.SetStateAction<null>, type: string) => () => {
        if (type !== "return") {
            if (checked !== value) {
                setChecked(value);
            } else {
                setChecked(null);
            }
        } else if (checked_return !== value) {
            setCheckedReturn(value);
        } else {
            setCheckedReturn(null);
        }
    };
    const handleContract = () => {
        setIsContract(true);
        dispatch({ type: "CARS_TOGGLE_TIMELINE", payload: { cars_timeline: false } });
        props.getContractInfo({
            checked: checked,
            checked_return: checked_return,
            stations: stations_arr,
            selected_car: props.item,
            card_index: props.index,
            important_notice: important_notice
        });
        if (replaceProductContext.enableReplace) {
            replaceProductContext.onShowNextButton();
            replaceProductContext.onNextStep();
        }
    };
    let provider = GetProvider(provider_list, props.item.provider_id);
    let subprovider = GetProvider(provider_list, props.item.subprovider_id);
    useEffect(() => {
        setShowDetails(openProductsDetails);
    }, [openProductsDetails]);

    useEffect(() => {
        if (props.alwaysOpen) {
            setShowDetails(true);
        }
    }, [props.alwaysOpen]);
    useEffect(() => {
        if (prevItem !== undefined && prevItem.initialized !== initialized) {
            if (initialized) {
                let station = null;
                if (tab_index === 0) {
                    station = stations_arr.pick_up.slice();
                } else {
                    station = stations_arr.return.slice();
                }
                setStation(station);
            }
        }
    }, [initialized]);
    useEffect(() => {
        let station = null;
        if (tab_index === 0) {
            station = stations_arr.pick_up.slice();
        } else {
            station = stations_arr.return.slice();
        }
        setStation(station);
    }, [tab_index]);
    useEffect(() => {
        if (initialized) {
            setTimeout(() => {
                mapRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
            }, 200);
        }
    }, [initialized]);
    useEffect(() => {
        if (checked !== null) {
            if (stations_arr.return.length !== 0) {
                nextTabRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
            } else {
                contractRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
            }
        }
    }, [checked]);

    useEffect(() => {
        if (checked_return !== null) {
            contractRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest'
            });
        }
    }, [checked_return]);
    return (
        <>
            <Card
                elevation={0}
                sx={{
                    width: '100%',
                    border: '1px solid rgba(0, 0, 0, 0.2)',
                    marginTop: 5
                }}
            >
                <Stack sx={{ width: '100%' }}>
                    <Box
                        sx={(theme) => ({
                            display: 'flex',
                            flexDirection: props.direction === 'vertical' ?
                                'column' :
                                'row',
                        })}>
                        {
                            !props.disablePictures &&
                                <CardMedia
                                    key={props.picture}
                                    image={props.picture}
                                    sx={(theme) => ({
                                        backgroundSize: props.showEntirePictures ? '80%' : 'cover',
                                        width: props.direction === 'vertical' ?
                                            '100%' :
                                            '25%',
                                        height: props.direction === 'vertical' ?
                                            250 :
                                            200,
                                        position: 'relative',
                                        [`.${iconButtonClasses.root}`]: {
                                            display: 'none',
                                            [theme.breakpoints.down('md')]: {
                                                display: 'inline-flex'
                                            }
                                        },
                                        [`&:hover .${iconButtonClasses.root}`]: {
                                            display: 'inline-flex'
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            width: '100%',
                                            height: 200
                                        }
                                    })}
                                >
                                </CardMedia>
                        }
                        <CardContent
                            sx={(theme) => ({
                                width: props.direction === 'vertical' || props.disablePictures ?
                                    '100%' :
                                    '75%',
                                paddingBottom: `${theme.spacing(2)} !important`,
                                [theme.breakpoints.down('md')]: {
                                    width: '100%'
                                }
                            })}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={{ md: 8 }}
                                flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                            >
                                {
                                    !isFunction(props.mainContent) ?
                                        props.mainContent :
                                        props.mainContent(SideActions)
                                }
                                {
                                    (
                                        props.sideContent ||
                                        !props.direction ||
                                        props.direction === 'horizontal'
                                    ) &&
                                    <Stack direction={'column'} spacing={1.5}>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            paddingTop={{ xs: 1.5, md: 0 }}
                                            margin={{ xs: 'auto' }}
                                            sx={{
                                                marginRight: '0px !important'
                                            }}
                                        >
                                            {
                                                props.sideContent &&
                                                !isNumber(props.item.stack_number) &&
                                                props.sideContent
                                            }
                                            {
                                                // (!props.direction || props.direction === 'horizontal') &&
                                                // SideActions()
                                            }
                                        </Stack>
                                        <Stack
                                            direction={{ md: 'row' }}
                                            alignItems="center"
                                            justifyContent={
                                                props.footer ?
                                                    'space-between' :
                                                    'flex-end'
                                            }
                                            spacing={2}
                                        >
                                            <div>
                                                {
                                                    contentRef.current &&
                                                    hasTextContent(contentRef.current) &&
                                                    !props.alwaysOpen &&
                                                    <Typography
                                                        color="inherit"
                                                        sx={(theme) => ({
                                                            // color: '#fff',
                                                            cursor: 'pointer',
                                                            marginLeft: '0px !important',
                                                            marginRight: '0px !important',
                                                            marginTop: '0px !important',
                                                            // backgroundColor: '#FC4400',
                                                            // border: '1px solid #FC4400',
                                                            textTransform: 'none',
                                                            whiteSpace: 'nowrap',
                                                            ['&:hover']: {
                                                                // border: `1px solid ${theme.palette.getContrastText('#f4f4f4')}`,
                                                                textDecoration: 'underline'
                                                            },
                                                            [theme.breakpoints.down('md')]: {
                                                                margin: 'auto !important',
                                                                marginTop: `${theme.spacing(2)} !important`
                                                            }
                                                        })}
                                                        onClick={() => setShowDetails((state) => !state)}
                                                    >
                                                        {
                                                            showDetails ?
                                                                t<string>('cart-material.cart-construction-hide-details') :
                                                                t<string>('cart-material.cart-construction-show-details')
                                                        }
                                                    </Typography>
                                                }
                                            </div>
                                        </Stack>
                                    </Stack>
                                }
                            </Stack>
                        </CardContent>
                    </Box>
                    <Box
                        sx={(theme) => ({
                            display: 'flex',
                            flexDirection: props.direction === 'vertical' ?
                                'column' :
                                'row',
                            borderBottom: showDetails ?
                                '1px solid rgba(0, 0, 0, 0.25)' :
                                undefined,
                            [theme.breakpoints.down('md')]: {
                                flexDirection: 'column'
                            }
                        })}>
                        <CardContent sx={{width: '100%', paddingTop: 0}}>
                            {
                                // (
                                //     (footerRef.current && hasTextContent(footerRef.current)) ||
                                //     (contentRef.current && hasTextContent(contentRef.current))
                                // ) &&
                                // <Divider sx={{ marginTop: 2, marginBottom: 1.5 }} />
                            }
                            <Divider/>
                            <Stack
                                direction={{ md: 'row' }}
                                alignItems="center"
                                justifyContent={
                                    props.footer ?
                                        'space-between' :
                                        'flex-end'
                                }
                                spacing={2}
                            >
                                <div ref={footerRef}>
                                    {props.footer}
                                </div>
                                <div style={{paddingTop: 15}}>
                                    {
                                        // contentRef.current &&
                                        // hasTextContent(contentRef.current) &&
                                        // !props.alwaysOpen &&
                                        <Button
                                            color="inherit"
                                            sx={(theme) => ({
                                                color: '#fff',
                                                borderRadius: 20,
                                                backgroundColor: `${main_color}`,
                                                border: `1px solid ${main_color}`,
                                                textTransform: 'none',
                                                whiteSpace: 'nowrap',
                                                ['&:hover']: {
                                                    border: `1px solid ${theme.palette.getContrastText('#f4f4f4')}`,
                                                    color: theme.palette.getContrastText('#f4f4f4'),
                                                    backgroundColor: '#f4f4f4'
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    margin: 'auto !important',
                                                    marginTop: `${theme.spacing(2)} !important`
                                                }
                                            })}
                                            // onClick={() => setShowDetails((state) => !state)}
                                            onClick={() => {
                                                if (matches) {
                                                    setInitialized(!initialized);
                                                } else {
                                                    setMobileStation(true);
                                                }
                                            }}
                                        >
                                            {
                                                t<string>('cars.agencies')
                                            }
                                        </Button>
                                    }
                                </div>
                            </Stack>
                        </CardContent>
                    </Box>
                    <Collapse
                        in={
                            showDetails &&
                            !!contentRef.current &&
                            hasTextContent(contentRef.current)
                        }
                    >
                        <CardContent ref={contentRef}>
                            {props.children}
                        </CardContent>
                    </Collapse>
                    {
                        initialized && (
                            <Grid item container style={{marginTop: 20}} ref={mapRef}>
                                <Grid item sm={6}>
                                    <RenderMap card_index={props.index} station={station} hovered={hovered} provider={provider} card={props.item}/>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <RenderStation tab_index={tab_index} handleChange={handleChange} stations_arr={stations_arr} handleToggle={handleToggle} setHovered={setHovered} checked={checked} checked_return={checked_return} nextTabRef={nextTabRef} contractRef={contractRef} handleContract={handleContract} setTab={setTab} card={props.item}/>
                                </Grid>
                            </Grid>
                        )
                    }
                </Stack>
            </Card>
            {
                !matches && (
                    <MobileRenderStation mobileStation={mobileStation} setMobileStation={setMobileStation} tab_index={tab_index} handleChange={handleChange} stations_arr={stations_arr} handleToggle={handleToggle} setHovered={setHovered} checked={checked} checked_return={checked_return} nextTabRef={nextTabRef} contractRef={contractRef} handleContract={handleContract} setTab={setTab}/>
                )
            }
            {
                openPicturesModal &&
                <CartProductPicturesModal
                    type={props.type as any} //TODO: fix types
                    item={props.item as any}
                    onClose={() => setOpenPicturesModal(false)}
                />
            }
        </>
    );
}
