//---- Dependencies ----//
import axios from "axios";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { SetCustomFilters } from "../../../../Actions/Menu";

export default (dispatch, user) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        let request_array = [];
        request_array.push(
            axios({
                method: 'GET',
                url: `${API_HREF}client/${user.client}/user/${user.id}/trip-filter-preference/?limit=1000`,
                headers: headers,
            })
        );
        request_array.push(
            axios({
                method: 'GET',
                url: `${API_HREF}client/${user.client}/trip-filter-preference/?limit=1000`,
                headers: headers,
            })
        );
        axios.all([...request_array]).then(axios.spread((...responses) => {
            let custom_filters = [];
            responses.map((response) => {
                let data = response.data.results.slice();
                data.forEach(element => {
                    element.label = `${element.name}`;
                    if (response.config.url.includes("user")) {
                        element.filters_type = "user";
                    } else {
                        element.filters_type = "company";
                    }
                });
                custom_filters = custom_filters.concat(data);
            });
            dispatch(SetCustomFilters(custom_filters));
        }));
    }
}