import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AceEditor from "react-ace";
import { useTranslation } from "react-i18next";
import { Grid, styled, Tab, Tabs, Typography } from "@mui/material";
import { debounce } from "lodash";
import LightTooltip from "../utils/tooltip/lightTooltip";
import { MailTemplateVariablePicker } from "./mailTemplateVariablePicker";
import { MailTemplateTestDataEditor } from "./mailTemplateTestDataEditor";
import { renderWithTestData } from "./utils/render-html";
import { createHtmlCodeEditorLocaleInitialContent, setHtmlCodeEditorContent } from "./redux/actions";
import { AppState } from "../../../../Reducers/Reducers";
import DefaultHTML from './assets/mail-template-default.html?raw';
import "ace-builds/src-noconflict/mode-handlebars";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import HelpIcon from '@mui/icons-material/HelpOutline';

type Props = {
    defaultHtml?: string
}

export function MailTemplateHTMLCodeEditor(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => state.locale.current_locale);
    const contents = useSelector((state: AppState) => state.mailTemplate.others.htmlCodeEditorContents);
    const [tab, setTab] = useState(0);
    const [previewCode, setPreviewCode] = useState(DefaultHTML);
    const [testData, setTestData] = useState({});
    const editorRef = useRef<AceEditor>(null);
    const content = contents.find((item) => item.locale === locale)?.content;

    const changePreviewCode = useCallback(
        debounce(
            (content) => {
                setPreviewCode(content ?? '');
            },
            300
        ),
        [setPreviewCode]
    );

    const onChange = (html: string) => {
        if (locale !== null) {
            const dispatchData = setHtmlCodeEditorContent(
                locale,
                html
            );
            dispatch(dispatchData);
        }
    };

    const onInsertVariable = (variable: string) => {
        if (editorRef.current) {
            editorRef.current.editor.insert(`{{${variable}}}`);
        }
    };

    const onChangeTab = (event: React.SyntheticEvent<Element, Event>, tab: number) => {
        setTab(tab);
    };

    useEffect(() => {
        changePreviewCode(content);
    }, [locale, contents]);

    useEffect(() => {
        if (locale !== null) {
            dispatch(createHtmlCodeEditorLocaleInitialContent(locale));
        }
    }, [locale]);

    useEffect(() => {
        if (props.defaultHtml && locale !== null) {
            dispatch(setHtmlCodeEditorContent(locale, props.defaultHtml));
        }
    }, [props.defaultHtml]);

    return (
        <div>
            <MailTemplateVariablePicker
                onChoose={onInsertVariable}
            />
            <Grid spacing={2} container>
                <Grid item xs={6}>
                    <Tabs
                        value={tab}
                        onChange={onChangeTab}
                    >
                        <Tab LinkComponent={Typography} label="HTML" />
                        <Tab
                            label={t<string>('shared.mail-template-test-data')}
                            icon={
                                <Typography variant="body2" color="text.secondary">
                                    <LightTooltip title={t<string>('shared.mail-template-test-data-help')}>
                                        <HelpIcon className={ 'ft-role-info' }/>
                                    </LightTooltip>
                                </Typography>
                            }
                            iconPosition="end"
                        />
                    </Tabs>
                </Grid>
                <Grid
                    item
                    xs={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'end'
                    }}
                >
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                        {t<string>('shared.mail-template-preview')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid spacing={2} container>
                <Grid item xs={6}>
                    {
                        tab === 0 &&
                        <AceEditor
                            ref={editorRef}
                            mode="handlebars"
                            theme="monokai"
                            fontSize={14}
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            setOptions={{
                                enableBasicAutocompletion: false,
                                enableLiveAutocompletion: false,
                                enableSnippets: false,
                                showLineNumbers: true,
                                tabSize: 4
                            }}
                            width="100%"
                            value={content ?? ''}
                            onChange={onChange}
                        />
                    }
                    <div style={{ display: tab === 1 ? 'block' : 'none' }}>
                        <MailTemplateTestDataEditor onChangeTestData={setTestData} />
                    </div>
                </Grid>
                <Grid
                    item
                    xs={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <PreviewContainer>
                        <Preview
                            srcDoc={renderWithTestData(previewCode, testData)}
                            sandbox=""
                        />
                    </PreviewContainer>
                </Grid>
            </Grid>
        </div>
    );
}

const PreviewContainer = styled('div')(() => ({
    width: '100%',
    height: '100%',
    border: '1px solid #ccc'
}));

const Preview = styled('iframe')(() => ({
    "width": '100%',
    "height": '100%',
    "border": 'none',
    '&::webkit-scrollbar': {
        width: 10
    }
}));
