export default (travelers) => {
    let pass_check = true;
    travelers.map((traveler) => {
        // default names in temporary travelers are represented with empty strings
        if (traveler.first_name === "" || traveler.last_name === ""){
            pass_check = false;
        }
    })
    return pass_check
}
