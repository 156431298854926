import React, { useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Box,
    Card,
    CardContent,
    CardMedia,
    Radio,
    Stack,
    Typography
} from "@mui/material";
import { isArray } from "lodash";
import { CartConstructionReplaceProductContext } from "./utils/cartConstructionReplaceProductContext";
import { Picture } from "../Menu/MaterialTripList/picture/objects/picture";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    callbackRef: React.MutableRefObject<(() => void) | null>,
    getRoomPrices: (price: any) => Promise<void>,
    loaded: boolean,
    prices: {
        price: string,
        price_currency: number | null,
        data: {
            id: number,
            logo?: Picture,
            code: string,
            name: string
        }
    }[] | null
}

export function CartConstructionReplaceProductProviders(props: Props): JSX.Element {
    const { t } = useTranslation();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const user = useSelector((state: AppState) => state.user.user);
    const currencies = useSelector((state: AppState) => state.base.currency_list);
    const [selected, setSelected] = useState<number | null>(null);
    const prices = useMemo(() => {
        const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
        if (user?.client_full?.type === 2 && quotationCode === 'visiteurs') {
            const preferred_array: {
                1: NonNullable<typeof props.prices>[number] | null,
                2: NonNullable<typeof props.prices>[number] | null,
            } = {
                1: null,
                2: null
            };
            for (const price of props.prices ?? []) {
                if (price.data.code === 'hotelbeds' || price.data.code === 'koedia') {
                    if (preferred_array['2'] === null || preferred_array['2'].price > price.price) {
                        preferred_array['2'] = price;
                    }
                } else if (preferred_array['1'] === null || preferred_array['1'].price > price.price) {
                    preferred_array['1'] = price;
                }
            }
            return preferred_array;
        }
        return props.prices;
    }, [user, props.prices]);
    const context = useContext(CartConstructionReplaceProductContext);

    context.onRegisterNextEvent(async () => {
        const price = props.prices?.find((item) => {
            return item.data.id === selected;
        });
        if (price) {
            context.onChangeLoading(true);
            try {
                await props.getRoomPrices(price.data);
            } finally {
                context.onChangeLoading(false);
            }
        }
        context.onIsFinalStep();
        context.onNextStep();
    });

    return (
        <>
            <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: 2 }}>
                {t<string>('cart-material.cart-construction-providers-dot')}
            </Typography>
            {
                (
                    (
                        isArray(prices) &&
                        prices.length === 0
                    ) ||
                    !prices
                ) &&
                <Alert severity="info">
                    {t<string>('shared.no-results-found')}
                </Alert>
            }
            {
                isArray(prices) &&
                prices.map((price) => {
                    return (
                        <Card
                            key={price.data.id}
                            elevation={0}
                            sx={{
                                display: 'flex',
                                marginBottom: 1.5,
                                border: '1px solid rgba(0, 0, 0, 0.4)',
                                cursor: 'pointer'
                            }}
                            onClick={() => setSelected(price.data.id)}
                        >
                            <Box sx={{ padding: 1 }}>
                                {
                                    price.data.logo &&
                                    <CardMedia
                                        component="img"
                                        image={
                                            price.data.logo?.url ??
                                            price.data.logo?.thumbnail_big ??
                                            price.data.logo?.thumbnail_medium ??
                                            price.data.logo?.thumbnail_little ??
                                            ''
                                        }
                                        sx={{
                                            width: 75,
                                            maxHeight: 75,
                                            objectFit: 'contain'
                                        }}
                                    />
                                }
                                {
                                    !price.data.logo &&
                                    <Typography>
                                        {price.data.name}
                                    </Typography>
                                }
                            </Box>
                            <CardContent
                                sx={(theme) => ({
                                    flex: 1,
                                    padding: 1,
                                    paddingBottom: `${theme.spacing(1)} !important`
                                })}
                            >
                                <Stack
                                    alignItems="flex-end"
                                    justifyContent="space-between"
                                    sx={{ height: '100%' }}
                                >
                                    <Typography fontWeight="bold">
                                        {
                                            new Intl.NumberFormat(
                                                language, 
                                                {
                                                    style: 'currency',
                                                    currency: currencies.find((item) => {
                                                        return item.id === price.price_currency;
                                                    })?.iso_code ?? 'EUR'
                                                }
                                            ).format(parseFloat(price.price))
                                        }
                                    </Typography>
                                    <Radio
                                        checked={selected === price.data.id}
                                        onChange={(_, checked) => checked && setSelected(price.data.id)}
                                    />
                                </Stack>
                            </CardContent>
                        </Card>
                    );
                })
            }
            {
                prices &&
                !isArray(prices) &&
                <>
                    {
                        prices[1] &&
                        <Card
                            elevation={0}
                            sx={{
                                display: 'flex',
                                marginBottom: 1.5,
                                border: '1px solid rgba(0, 0, 0, 0.4)',
                                cursor: 'pointer'
                            }}
                            onClick={() => setSelected(prices[1]!.data.id)}
                        >
                            <Box sx={{ padding: 1 }}>
                                <Typography>
                                    { t<string>('accommodation.visiteurs_partners') }
                                </Typography>
                            </Box>
                            <CardContent
                                sx={(theme) => ({
                                    flex: 1,
                                    padding: 1,
                                    paddingBottom: `${theme.spacing(1)} !important`
                                })}
                            >
                                <Stack
                                    alignItems="flex-end"
                                    justifyContent="space-between"
                                    sx={{ height: '100%' }}
                                >
                                    <Typography fontWeight="bold">
                                        {
                                            new Intl.NumberFormat(
                                                language, 
                                                {
                                                    style: 'currency',
                                                    currency: currencies.find((item) => {
                                                        return item.id === prices[1]!.price_currency;
                                                    })?.iso_code ?? 'EUR'
                                                }
                                            ).format(parseFloat(prices[1]!.price))
                                        }
                                    </Typography>
                                    <Radio
                                        checked={selected === prices[1]!.data.id}
                                        onChange={(_, checked) => checked && setSelected(prices[1]!.data.id)}
                                    />
                                </Stack>
                            </CardContent>
                        </Card>
                    }
                    {
                        prices[2] &&
                        <Card
                            elevation={0}
                            sx={{
                                display: 'flex',
                                marginBottom: 1.5,
                                border: '1px solid rgba(0, 0, 0, 0.4)',
                                cursor: 'pointer'
                            }}
                            onClick={() => setSelected(prices[2]!.data.id)}
                        >
                            <Box sx={{ padding: 1 }}>
                                <Typography>
                                    { t<string>('accommodation.visiteurs_bedbanks') }
                                </Typography>
                            </Box>
                            <CardContent
                                sx={(theme) => ({
                                    flex: 1,
                                    padding: 1,
                                    paddingBottom: `${theme.spacing(1)} !important`
                                })}
                            >
                                <Stack
                                    alignItems="flex-end"
                                    justifyContent="space-between"
                                    sx={{ height: '100%' }}
                                >
                                    <Typography fontWeight="bold">
                                        {
                                            new Intl.NumberFormat(
                                                language, 
                                                {
                                                    style: 'currency',
                                                    currency: currencies.find((item) => {
                                                        return item.id === prices[2]!.price_currency;
                                                    })?.iso_code ?? 'EUR'
                                                }
                                            ).format(parseFloat(prices[2]!.price))
                                        }
                                    </Typography>
                                    <Radio
                                        checked={selected === prices[2]!.data.id}
                                        onChange={(_, checked) => checked && setSelected(prices[1]!.data.id)}
                                    />
                                </Stack>
                            </CardContent>
                        </Card>
                    }
                </>
            }
        </>
    );
}
