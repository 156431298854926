// Dependencies
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// Core
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from "@material-ui/core/FormHelperText";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    formContainer: {
        border: '1px solid rgba(0,0,0,.1)',
        borderRadius: 8,
        marginBottom: 32,
        padding: 16
    },
    gridImage: {
        width: 250
    },
    pictureContainer: {
        position: 'relative'
    },
    removePicture: {
        position: 'absolute',
        right: 8,
        top: 8,
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: '50%',
        padding: 2
    },
    imageButton: {
        marginTop: 8
    },
    gridContent: {
        marginLeft: 8
    },
    image: {
        borderRadius: 16
    },
    select: {
        width: '100%'
    },
    travelersContainer: {
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        padding: '12px 12px 0 12px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    travelersError: {
        borderColor: 'red'
    },
    datesErrorText: {
        color: 'red',
        fontSize: 12
    },
    buttonSeparator: {
        margin: '16px 0 0 0'
    },
    manualProductCancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    manualProductCreateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    menuHeight: {
        maxHeight: 216
    }
});

const CartManualProductFormCar = ({carsErrors, product_type}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const address_pickup = useSelector(state => state.cart.manual_product_address_pickup);
    const station_name_pickup = useSelector(state => state.cart.manual_product_station_name_pickup);
    const address_return = useSelector(state => state.cart.manual_product_address_return);
    const station_name_return = useSelector(state => state.cart.manual_product_station_name_return);
    const vehicle_code = useSelector(state => state.cart.manual_product_vehicle_code);
    const one_way_fees = useSelector(state => state.cart.manual_product_one_way_fees);
    const one_way_fees_currency = useSelector(state => state.cart.manual_product_one_way_fees_currency);
    const currency_list = useSelector(state => state.base.currency_list);

    const onAddressPickupChange = (event) => {
        dispatch({type: 'CART_UPDATE_ADDRESS_PICKUP', payload: event.target.value});
    };
    const onAddressReturnChange = (event) => {
        dispatch({type: 'CART_UPDATE_ADDRESS_RETURN', payload: event.target.value});
    };
    const onStationPickupChange = (event) => {
        dispatch({type: 'CART_UPDATE_STATION_PICKUP', payload: event.target.value});
    };
    const onStationReturnChange = (event) => {
        dispatch({type: 'CART_UPDATE_STATION_RETURN', payload: event.target.value});
    };
    const onVehicleCodeChange = (event) => {
        dispatch({type: 'CART_UPDATE_VEHICLE_CODE', payload: event.target.value});
    };
    const onOneWayFeesChange = (event) => {
        dispatch({type: 'CART_UPDATE_ONE_WAY_FEES', payload: event.target.value});
    };
    const onOneWayFeesCurrencyChange = (event, value) => {
        dispatch({type: 'CART_UPDATE_ONE_WAY_FEES_CURRENCY', payload: value});
    };
    const onSearchChange = event => {
        setSearch(event.target.value);
    };
    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);
    useEffect(() => {
        if (one_way_fees_currency !== null) {
            setOptions([...options, one_way_fees_currency]);
        }
    }, [one_way_fees_currency]);

    return (
        <Fragment>
            {
                product_type !== 4 &&
                <Grid item xs={ 12 }>
                    <FormControl fullWidth variant={ 'outlined' } error={ carsErrors.includes('station_name_pickup')}>
                        <InputLabel htmlFor={ 'manual-product-station-pickup' }>{ t('cart-material.station-pickup') }</InputLabel>
                        <OutlinedInput
                            id={ 'manual-product-station-pickup' }
                            type={ 'text' }
                            onChange={ onStationPickupChange }
                            label={ t('cart-material.station-pickup') }
                            value={station_name_pickup}
                        />
                        { carsErrors.includes('station_name_pickup') && <FormHelperText>{ t('global.empty_check') }</FormHelperText> }
                    </FormControl>
                </Grid>
            }
            <Grid item xs={ 12 }>
                <FormControl fullWidth variant={ 'outlined' } error={ carsErrors.includes('address_pickup')}>
                    <InputLabel htmlFor={ 'manual-product-address-pickup' }>{ t('cart-material.pick-up-address') }</InputLabel>
                    <OutlinedInput
                        id={ 'manual-product-address-pickup' }
                        type={ 'text' }
                        onChange={ onAddressPickupChange }
                        label={ t('cart-material.pick-up-address') }
                        value={address_pickup }
                    />
                    { carsErrors.includes('address_pickup') && <FormHelperText>{ t('global.empty_check') }</FormHelperText> }
                </FormControl>
            </Grid>
            {
                product_type !== 4 &&
                <Grid item xs={ 12 }>
                    <FormControl fullWidth variant={ 'outlined' } error={ carsErrors.includes('station_name_return')}>
                        <InputLabel htmlFor={ 'manual-product-station-return' }>{ t('cart-material.station-return') }</InputLabel>
                        <OutlinedInput
                            id={ 'manual-product-station-return' }
                            type={ 'text' }
                            onChange={ onStationReturnChange }
                            label={ t('cart-material.station-return') }
                            value={station_name_return}
                        />
                        { carsErrors.includes('station_name_return') && <FormHelperText>{ t('global.empty_check') }</FormHelperText> }
                    </FormControl>
                </Grid>
            }
            <Grid item xs={ 12 }>
                <FormControl fullWidth variant={ 'outlined' } error={ carsErrors.includes('address_return')}>
                    <InputLabel htmlFor={ 'manual-product-address-return' }>{ t('cart-material.return-address') }</InputLabel>
                    <OutlinedInput
                        id={ 'manual-product-address-return' }
                        type={ 'text' }
                        onChange={ onAddressReturnChange }
                        label={ t('cart-material.return-address') }
                        value={address_return }
                    />
                    { carsErrors.includes('address_return') && <FormHelperText>{ t('global.empty_check') }</FormHelperText> }
                </FormControl>
            </Grid>
            {
                product_type !== 4 && (
                    <Fragment>
                        <Grid item xs={ 6 }>
                            <FormControl fullWidth variant={ 'outlined' } error={ carsErrors.includes('vehicle_code')}>
                                <InputLabel htmlFor={ 'manual-product-acriss-code' }>{ t('cart-material.acriss-code') }</InputLabel>
                                <OutlinedInput
                                    id={ 'manual-product-acriss-code' }
                                    type={ 'text' }
                                    onChange={ onVehicleCodeChange }
                                    label={ t('cart-material.acriss-code') }
                                    value={vehicle_code}
                                />
                                { carsErrors.includes('vehicle_code') && <FormHelperText>{ t('global.empty_check') }</FormHelperText> }
                            </FormControl>
                        </Grid>
                        <Grid item xs={ 6 }/>
                        <Grid item xs={ 6 }>
                            <FormControl fullWidth variant={ 'outlined' } error={ carsErrors.includes('one_way_fees')}>
                                <InputLabel htmlFor={ 'manual-product-one-way-fees' }>{ t('cars.one_way') }</InputLabel>
                                <OutlinedInput
                                    id={ 'manual-product-one-way-fees' }
                                    type={ 'number' }
                                    onChange={ onOneWayFeesChange }
                                    label={ t('cars.one_way') }
                                    value={one_way_fees}
                                />
                                { carsErrors.includes('one_way_fees') && <FormHelperText>{ t('global.empty_check') }</FormHelperText> }
                            </FormControl>
                        </Grid>
                        <Grid item xs={ 6 }>
                            <Autocomplete
                                id={ 'manual-product-one-way-currency' }
                                getOptionSelected={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => `${option.international_name} (${option.iso_code})`}
                                options={ currency_list }
                                loading={ loading }
                                value={ one_way_fees_currency }
                                onChange={ onOneWayFeesCurrencyChange }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={ t('cars.one_way_currency') }
                                        variant={ 'outlined' }
                                        onChange={ onSearchChange }
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color={ 'inherit' } size={ 20 }/> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Fragment>
                )
            }
        </Fragment>
    );

};
export default CartManualProductFormCar;