import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    inline: {
        display: "inline"
    },
    cardContainer: {
        marginBottom: 24,
        cursor: "pointer"
    },
    marginTop: {
        marginTop: 24
    },
    marginBottom: {
        marginBottom: 8
    },
    imgContainer: {
        overflow: "hidden",
        height: 200,
        width: 300,
        position: "relative",
    },
    alignRight: {
        textAlign: "right"
    }
}));

const SkeletonOne = () => {
    const classes = useStyles();
    let items = [];
    for (let i = 0; i < 4; i++) {
        items.push(
            <Fragment key={ i }>
                <Grid className={ `${classes.cardContainer}${(i > 0 ? ` ${classes.marginTop}` : "")}` } item container spacing={ 2 }>
                    <Grid className={ classes.imgContainer } item>
                        <Skeleton variant={ "rect" } height={ "100%" }/>
                    </Grid>
                    <Grid item xs container>
                        <Grid item xs={ 12 }>
                            <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 20 } width={ "100%" }/>
                            <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 20 } width={ "100%" }/>
                            <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 20 } width={ "50%" }/>
                            <Skeleton variant={ "rect" } height={ 20 } width={ "50%" }/>
                        </Grid>
                        <Grid item xs={ 12 } container justify={ "flex-end" } alignItems={ "flex-end" }>
                            <Grid className={ classes.alignRight } item>
                                <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 15 } width={ 150 }/>
                                <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 15 } width={ 150 }/>
                            </Grid>
                        </Grid>
                        <Grid item xs={ 12 } container justify={ "flex-end" } alignItems={ "flex-end" }>
                            <Grid className={ classes.alignRight } item>
                                <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 20 } width={ 75 }/>
                                <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 20 } width={ 75 }/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    items.length < 3 && (
                        <Divider className={ classes.marginBottom }/>
                    )
                }
            </Fragment>
        )
    }
    return items
};

export default React.memo(SkeletonOne);
