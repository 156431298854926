/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import Popover from '@mui/material/Popover';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';

import '../crm.css';

import { useDispatch, useSelector } from "react-redux";

import { countriesPhone } from "../objects/data";

import CrmDemandFormDialog from "../CrmDemandList/CrmDemandFormDialog";
import MaterialUiPhoneNumber from "material-ui-phone-number";

const CrmDemandField = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [newValue, setNewValue] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [displayPressEnter, setDisplayPressEnter] = React.useState(false);

    const selected_demand = useSelector(store => store.crm.selected_demand);

    useEffect(() => {
        setNewValue(props.value);
    }, [props, selected_demand]);

    const [openTripForm, setOpenTripForm] = React.useState(false);

    const closeDialog = () => {
        setOpenTripForm(false);
    };

    const openFullscreen = () => {
        setOpenTripForm(true);
    };

    const updateInfo = (demand) => {
        let demand_c = JSON.parse(JSON.stringify(demand));

        //TODO: update obj here
        closeDialog();
    };

    const handleClick = (event) => {
        if (props.customEditCallback !== undefined) {
            props.customEditCallback(event);
        } else if (props.expandable) {
            openFullscreen();
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleChange = (event) => {
        setNewValue(event.target.value);
    };

    const handleChangeTags = (value) => {
        setNewValue(value);
        setDisplayPressEnter(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const executeCallback = () => {
        if (props.callback !== null && props.callback !== undefined) {
            props.callback({
                newValue, onFinish: () => {
                    setAnchorEl(null);
                }
            });
        }
    };

    const getLabelForValue = () => {
        const label = props.options?.find(e => e.value === props.value)?.label;
        return label ? label : props.value;
    };

    const handlePhoneNumber = (value, country_data) => {
        setNewValue(value);
    };

    const getPhoneCode = () => {
        const defaultLanguage = navigator.language;
        const locale = new Intl.Locale(defaultLanguage);
        const countryCode = locale.region ? locale.region : locale.language?.slice(-2)?.toLocaleUpperCase();
        const found = countriesPhone.find(e => e.code === countryCode);
        if (found) {
            return found.dial_code;
        }
        return "+1";
    };

    const getValue = () => {
        let value = props.value;
        if (props.displayValue) {
            value = props.displayValue;
        } else if (props.options) {
            value = getLabelForValue(props.value);
        } else if (props.is_date) {
            value = moment(new Date(props.value)).format("DD/MM/YYYY");
        }
        let valueDisplay = <p>{value}</p>;
        if (props.useBreakline) {
            return (<div style={{ flexDirection: "column" }}>
                {
                    props.value?.map((e) => {
                        return <p>{e}</p>;
                    })
                }
            </div>);
        }
        return valueDisplay;
    };

    const handleInputChange = (event, value) => {
        const pattern = new RegExp(value, 'i');
        const isOptionExists = props.options.some((option) => pattern.test(option));

        if (!isOptionExists) {
            setDisplayPressEnter(true);
        } else {
            setDisplayPressEnter(false);
        }
    };

    useEffect(() => {
        setOpen(Boolean(anchorEl));
    }, [anchorEl]);

    return (
        <div className="contact-field-item">
            {!props.title && <p>{props.label}</p>}
            <div>
                {props.title && <h2>{props.value}</h2>}
                {!props.title && <>{getValue()}</>}
                <div>
                    {
                        !props.readonly &&
                        <>
                            <EditIcon onClick={handleClick} />
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }}
                            >
                                <div className="close-div" onClick={handleClose}>
                                    <CloseIcon />
                                </div>
                                <div className="contact-field-edit">
                                    {
                                        !props.phone_input && !props.options && !props.tags &&
                                        <TextField label={props.label} onChange={(e) => handleChange(e)} variant="outlined" value={newValue}
                                            multiline={props.multiline}
                                            rows={props.multiline ? 4 : 1}
                                        />
                                    }
                                    {
                                        props.phone_input &&
                                        <MaterialUiPhoneNumber
                                            type={'text'} label={props.label}
                                            onChange={(value, country_data) => handlePhoneNumber(value, country_data)}
                                            value={newValue ? newValue : getPhoneCode()}
                                            variant="outlined"
                                            disableAreaCodes
                                            helperText={`ex: +33 6 12 34 56 78`}
                                        />
                                    }
                                    {
                                        props.options && !props.tags && !props.phone_input &&
                                        <FormControl fullWidth>
                                            <InputLabel>{props.label}</InputLabel>
                                            <Select
                                                value={newValue}
                                                label={props.label}
                                                onChange={(e) => handleChange(e)}
                                            >
                                                {props.options.map(option => {
                                                    return <MenuItem value={option.value}>{option.label}</MenuItem>;
                                                })}
                                            </Select>
                                        </FormControl>
                                    }
                                    {
                                        props.tags && !props.phone_input &&
                                        <>
                                            <Autocomplete
                                                multiple
                                                freeSolo
                                                label={props.label}
                                                options={props.options}
                                                getOptionLabel={(option) => option}
                                                defaultValue={props.value}
                                                filterSelectedOptions
                                                onInputChange={handleInputChange}
                                                onChange={(e, v) => handleChangeTags(v)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={props.label}
                                                    />
                                                )}
                                            />
                                            {displayPressEnter && <small>{t('crm.press_enter')}</small>}
                                        </>
                                    }
                                    <div>
                                        <Button variant="contained" onClick={executeCallback}>{t("crm.save")}</Button>
                                        <Button variant="outlined" onClick={handleClose}>{t("crm.cancel")}</Button>
                                    </div>
                                </div>
                            </Popover>
                        </>
                    }
                    {props.additional_action !== undefined && <>{props.additional_action.icon}</>}
                </div>
            </div>
            <CrmDemandFormDialog open={openTripForm} handleClose={closeDialog} onConfirm={updateInfo} title={t('crm.edit_demand')} />
        </div>
    );
};
export default React.memo(CrmDemandField);
