import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import PoiCardPricesMobile from "./PoiCardPricesMobile";

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: 'bold'
    },
    inline: {
        display: 'inline-block'
    },
    staticPriceContainer: {
        position: 'fixed',
        bottom: 62,
        backgroundColor: 'white',
        marginLeft: -16,
        width: "100%",
        textAlign: "center",
        padding: 8,
        borderTop: '1px solid lightgray'
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    }
}));

const PoiCardPricesBottom = ({ start_date, end_date, activity, current_travelers, productSelected, setProductSelected, assignedGroups, setAssignedGroups, addToCart }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const current_groups = useSelector(store => store.poi.current_groups);


    const [openModal, setOpenModal] = useState(false);

    return (
        <Grid className={ classes.staticPriceContainer }>
            {
                assignedGroups.length !== current_groups.length && (
                    <Button variant={ "contained" } className={ classes.orangeButton } onClick={ () => setOpenModal(true) }>{ t("global.choose") }</Button>
                )
            }
            {
                // add to cart button
                 assignedGroups.length !== 0 && (
                    <Grid>
                        <br />
                        <Button variant="contained" className={ classes.orangeButton } fullWidth={ true } onClick={() => addToCart()} >
                            { t("poi.validate_to_cart") }
                        </Button>
                    </Grid>
                )
            }
            <PoiCardPricesMobile start_date={start_date} end_date={end_date} activity={activity} current_travelers={current_travelers} productSelected={productSelected} setProductSelected={setProductSelected} assignedGroups={assignedGroups} setAssignedGroups={setAssignedGroups} openModal={openModal} setOpenModal={setOpenModal} addToCart={addToCart}/>
        </Grid>
    )
}

export default React.memo(PoiCardPricesBottom)