import axios from "axios";
import memoizee from "memoizee";
import { isBoolean } from "lodash";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { Destination } from "../../../Itinerary/objects/destination";

export type Guide = {
    destination: Destination,
    rubrics: {
        id: number,
        localization: {
            locale: number,
            title: string,
            description: string,
        }[]
    }[]
}

async function fetch(
    destinationIds: number[],
    onRoadbook: boolean | null = true,
    onQuotation: boolean | null = true
): Promise<Guide[]> {
    if (destinationIds.length === 0) {
        return [];
    }

    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        try {
            const response = await axios.get<Guide[]>(
                `${API_HREF}client/${window.id_owner}/guide/by_destinations/`,
                {
                    params: {
                        destination_ids: destinationIds.join(','),
                        on_roadbook: isBoolean(onRoadbook) ? onRoadbook : undefined,
                        on_quotation: isBoolean(onQuotation) ? onQuotation : undefined
                    },
                    headers
                }
            );
            return response.data.filter((item) => {
                return item.rubrics.length > 0;
            });
        } catch (error: any) {
            console.error(error);
        }
    }
    return [];
}

export const findDestinationGuideDescription = memoizee(
    fetch,
    {
        promise: true,
        normalizer(args) {
            return `${args[0].join(',')}-${args[1]}-${args[2]}`;
        }
    }
);
