//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Checkbox } from '@mui/material';

import KingBedIcon from '@mui/icons-material/KingBed';
import HotelIcon from '@material-ui/icons/Hotel';
import RoomIcon from '@material-ui/icons/Room';
import InfoIcon from '@material-ui/icons/Info';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import SettingsIcon from '@material-ui/icons/Settings';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Slider from "../../Accommodation/FunctionalComponent/Slider";
import Picture from "../../Accommodation/FunctionalComponent/Picture";
import AccommodationCardPictures from "../../Accommodation/FunctionalComponent/AccommodationCardPictures";
import MosaicPicture from "../../Accommodation/FunctionalComponent/MosaicPicture";
import GetPictureBySize from "../../Accommodation/Functions/GetPictureBySize";
import ByDayPictures from '../CircuitsMaterial/ByDayPicture';

import { TogleInfoHotelAndSetStep } from '../../../Actions/Summary';
import { differenceInDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
	italic: {
		fontStyle: "italic !important"
	},
	font14: {
		fontSize: 14
	},
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
	alignJustify: {
		textAlign: "justify"
	},
    cover: {
    	display: "block",
	    width: "100%",
	    height: "100%"
    },
    content: {
    	display: 'flex',
    	alignItems: "center",
    	paddingLeft: theme.spacing(1),
    	paddingBottom: theme.spacing(1),
    },
    pictureContainer: {
	    overflow: "hidden",
	    height: 250,
	    // width: 300,
	    position: "relative",
	    cursor: "pointer"
    },
	paddingLeft8: {
		paddingLeft: 8
	},
	paddingRight8: {
		paddingRight: 8
	},
	paddingTop25: {
		paddingTop: 25
	},
	checkbox: {
        // position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
	lowerCase: {
        textTransform: "capitalize"
    },
	accommodationOption: {
		"&>*": {
			border: "1px solid #e5e7eb",
			borderBottom: "none",
		},
		"&>*:first-of-type": {
			borderTopLeftRadius: 10,
			borderTopRightRadius: 10,
		},
		"&>*:last-of-type": {
			borderBottom: "1px solid #e5e7eb",
			borderBottomLeftRadius: 10,
			borderBottomRightRadius: 10,
		},

	},
	accommodationOptionSelected: {
		border: `2px solid ${main_color} !important`,
		backgroundColor: `${main_color}20`,
	},
	bold: {
		fontWeight: 'bold'
	},
	roomChoice: {
		marginBottom: '0px !important',
		'& .mui-jss-MuiFormControlLabel-label': {
			fontWeight: '500 !important',
			fontSize: '0.875rem !important'
		}
	}
}));

type HotelCardManualProps = {
    hotel: any,
	variantIsChecked: (variant: any) => boolean,
	onToggleVariant: (variant: any) => void,
    hotelsHasVariant: any,
    only_one_hotel: boolean,
    index: number,
    nb_active_hotel: number,
    products: any,
}

const HotelCardManual = (props: HotelCardManualProps) => {
    const {only_one_hotel, hotel, index, nb_active_hotel, variantIsChecked, onToggleVariant, products} = props;
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const [openMozaic, setOpenMozaic] = useState<boolean>(false);
	const [openPicture, setOpenPicture] = useState<boolean>(false);
	const [current_picture, setCurrentPicture] = useState<number>(0);
	const [modalPictures, setModalPictures] = useState<[]>([]);
	let stars = [];
	let room_name = "";
	let nb_day =  differenceInDays(utcToZonedTime(new Date(hotel.end_date), 'Etc/UTC'), utcToZonedTime(new Date(hotel.start_date), 'Etc/UTC'));

	if (hotel !== null) {
	    room_name = `${ t<string>("global.room") } ${hotel.rooms !== undefined && hotel.rooms.length > 0 ? "1" : ""} : ${ hotel.description }`;
        for (let i = 0; i < hotel.stars; i++) {
            stars.push(<StarIcon fontSize={'small'} key={i} style={{color: `${main_color}`, verticalAlign: 'sub'}}/>)
        }
	}
    const getAddress = () => {
        let addr: string = '';
        if (hotel.address) addr = hotel.address;
        else addr = `${hotel.house_number ? hotel.house_number : ''} ${hotel.street ? hotel.street : ''}${' '}${hotel.city_name ? hotel.city_name : ''}`;
        if (addr.trim() === '') addr = '';
        return addr;
    }
    const getPicture = () => {
		let pictures = [];
		if (hotel.overload_pictures !== undefined && hotel.overload_pictures !== null && hotel.overload_pictures.length > 0) {
			for (let i = 0; i < hotel.overload_pictures.length && pictures.length < 5; i++) {
                if (hotel.custom_product !== undefined && (hotel.custom_product.cover_picture === null || hotel.custom_product.cover_picture.id !== hotel.overload_pictures[i].id)) {
                    pictures.push(GetPictureBySize(hotel.overload_pictures[i], "BIG"));
                }
            }
		} else {
			if (hotel.picture !== null) {
				pictures.push(GetPictureBySize(hotel.picture, "BIG"))
			}
		}
		return pictures;
    };
    let check_in_cart = products.find((product: any) => {
        if (product.start_destination !== undefined && product.start_destination !== null && hotel.start_destination !== undefined && hotel.start_destination !== null) {
		    return hotel.start_destination.data.id === product.start_destination.data.id && hotel.product_type === product.product_type && !product.is_optional;
        }
	});
    const togglePictureModal = (pictures:any, index_picture = 0) => {
		setOpenPicture(!openPicture);
        setModalPictures(pictures);
		setCurrentPicture(index_picture);
	};
	const toggleMosaicModal = (pictures:any) => {
        setModalPictures(pictures);
		setOpenMozaic(!openMozaic);
    };
	return (
		<Grid container direction={"row"} spacing={2} style={{padding: 10}}>
			<Grid item xs container direction={"column"} alignItems={"flex-start"}>
				<Grid item>
					<HotelIcon className={classes.genericText} style={{verticalAlign: "sub", marginRight: 10, color: `${main_color}`}}/>
					<span className={classes.genericText} style={{fontWeight: 'bold', textTransform: 'uppercase'}}>{`${nb_day > 1 ? t<string>('poi.nightWithCount_other', {count: nb_day}) : t<string>('poi.nightWithCount_one', {count: nb_day})} : `}</span>
					<span className={classes.genericText} style={{fontWeight: 'bold'}}>{ hotel.name } </span>
					<span> { stars } </span>
				</Grid>
				<Grid item>
					<RoomIcon className={`${classes.genericText}`} style={{fontSize: 16, verticalAlign: "text-top", color: `${main_color}`}}/>
					<span className={`${classes.genericText} ${classes.italic}`} style={{fontSize: 14}}>{getAddress()}</span>
				</Grid>
			</Grid>
			<Grid item container direction={"row"} justify={"flex-start"}>
				{
					// type === 'circuit' && (
					// 	<Grid item sm={5} xs={12} >
					// 		<Grid container direction={"column"}>
					// 			<Grid item className={classes.pictureContainer} onMouseEnter={ onMouseEnter } onMouseLeave={ onMouseLeave } onClick={openModal}>
					// 				<Slider pictures={ getPicture() } showChevron={ showChevron } size={ 0 } dotSize={ 1 }/>
					// 			</Grid>
					// 		</Grid>
					// 	</Grid>
					// )
				}
				{
                    getPicture().length === 1 &&
                        <ByDayPictures pictures={hotel.overload_pictures.length !== 0 ? hotel.overload_pictures : [hotel.picture]} is_step={true} />
                }
				{
					// type !== 'circuit' && (
						getPicture().length > 1 &&
						<AccommodationCardPictures size={150} detail={hotel.hotel[0]} pictures={getPicture()} togglePictureModal={togglePictureModal} toggleMosaicModal={toggleMosaicModal}/>
					// )
				}
					<Grid item sm={12} xs={12} style={{marginTop: 22}}>
						<Grid container direction={"column"} spacing={2}>
						{
							!hotel.is_optional && hotel.room_types[0] !== '' &&
							<Fragment>
								<Grid item>
									<Typography variant={"h6"}>{ t<string>("accommodation.room_type") }</Typography>
								</Grid>
								<Grid item>
									<div className={`${classes.genericText} ${classes.alignJustify}`} dangerouslySetInnerHTML={ {__html: hotel.room_types[0] } } />
								</Grid>
							</Fragment>
						}
						{
							// content !== null && content !== "" && (
								<Grid item>
									<Button className={`${classes.genericText} ${classes.lowerCase}`} style={{padding: '15px 40px 15px 40px'}} variant={"outlined"} onClick={ () => { dispatch(TogleInfoHotelAndSetStep(true, 7, hotel)); } }>{ t<string>("itineraryType.more_details")} <ArrowForwardIcon/> </Button>
								</Grid>
							// )
						}
						</Grid>
					</Grid>
				{
					hotel.rooms !== undefined && (hotel.rooms.length > 1 || hotel.rooms.find((room:any) => room.is_optional) !== undefined) &&
						<Grid item xs={12} className={clsx({[classes.paddingLeft8]: !fullScreen, [classes.paddingRight8]: !fullScreen, [classes.paddingTop25]: !fullScreen})}>
							<Grid item xs={12} style={{marginBottom: 15}}>
								<Typography className={`${classes.genericText} ${classes.bold}`} style={{fontSize: '16px !important'}}>{ t<string>("itineraryType.room_choice") }</Typography>
							</Grid>
							<Grid container direction={"row"} spacing={2} className={classes.accommodationOption}>
								{
									// hotel.rooms.find((room: any) => !room.is_optional) !== undefined && hotel.rooms.find((room: any) => room.is_optional) !== undefined && (
										<Fragment>
											{
												hotel.rooms.map((room: any, room_index: number) => {
													// if (!room.is_optional) {
														return (
															<Grid item xs={12} key={room_index} style={{paddingTop: '4px !important', paddingBottom: '0px !important'}} className={clsx(classes.genericText, {[classes.accommodationOptionSelected]: variantIsChecked(room)})}>
																<FormControlLabel
																	value={room_index}
																	control={
																		<Checkbox
																			sx={{color: `${main_color}`,
																				'&.Mui-checked': {
																					color: `${main_color}`,
																				},
																			}}
																		/>
																	}
																	label={room.name !== undefined ? room.name : room.description}
																	labelPlacement="end"
																	className={classes.roomChoice}
																	checked={variantIsChecked(room)}
																	onChange={onToggleVariant(room)}
																/>
															</Grid>
														)
													// }
												})
											}
										</Fragment>
									// )
								}
								{
									// hotel.rooms.find((room: any) => room.is_optional) !== undefined && (
									// 	<Fragment>
									// 		<Grid item xs={12}>
									// 			<Typography variant={"h5"}>{ check_in_cart ? t<string>("itineraryType.alternate_accommodation") : t<string>("itineraryType.optional_accommodation") }</Typography>
									// 		</Grid>
									// 		{
									// 			hotel.rooms.map((room: any, room_index: number) => {
									// 				if (room.is_optional) {
									// 					return (
									// 						<Grid item key={room_index}>
									// 							<FormControlLabel
									// 								value={room_index}
									// 								control={<Radio />}
									// 								label={room.name !== undefined ? room.name : room.description}
									// 								labelPlacement="end"
									// 								checked={variantIsChecked(room)}
									// 								onChange={onToggleVariant(room)}
									// 							/>
									// 						</Grid>
									// 					)
									// 				}
									// 			})
									// 		}
									// 	</Fragment>
									// )
								}
							</Grid>
						</Grid>
					// )
				}
			</Grid>
			<MosaicPicture open={ openMozaic } onClose={ toggleMosaicModal } pictures={ modalPictures } openModal={ togglePictureModal }/>
			<Picture open={ openPicture } onClose={ togglePictureModal } pictures={ modalPictures } current_picture={ current_picture } setCurrentPicture={ setCurrentPicture }/>
		</Grid>
	)
};
export default HotelCardManual;