/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import CrmUserDetailContactField from './CrmUserDetailContactField';

import '../../crm.css';
import { useTranslation } from "react-i18next";

import { createUserMarker, patchUser } from '../../Functions/UserFunctions';

import { type_options } from "../../objects/data";
import { getCountries } from "../../Functions/CountryFunctions";
import { useDispatch, useSelector } from "react-redux";
import { SetCountries, SetSelectedUser } from "../../../../../Actions/Crm";

//ICONS
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { SetUserList } from "../../../../../Actions/User";

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CheckBox } from "@mui/icons-material";
import moment from "moment";

import OutlinedInput from '@mui/material/OutlinedInput';
import Popover from '@mui/material/Popover';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import GenericField from "../../utils/GenericField";
import IconButton from '@mui/material/IconButton';

const CrmUserDetailContact = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const countries = useSelector(store => store.crm.countries);
    const selected_user = useSelector(store => store.crm.selected_user);
    const user_client = useSelector(store => store.user.user);
    const user_list = useSelector(store => store.user.user_list);

    const [openAdditionalContactForm, setOpenAdditionalContactForm] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selected_user_temp, SetSelectedUserTemp] = React.useState(null);

    const handleClickPopover = (event, type) => {
        setAnchorEl(event.currentTarget);
        setOpenAdditionalContactForm(type);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
        setOpenAdditionalContactForm(null);
    };

    useEffect(() => {
        if (!countries || !countries.length) {
            getCountries((data) => {
                dispatch(SetCountries(data));
            });
        }
    }, [countries]);

    useEffect(() => {
        if (selected_user) {
            SetSelectedUserTemp(selected_user);
        }
    }, [selected_user]);

    //TODO: use localization
    const source_options = [
        { value: "N/A", label: "N/A", hidden: true },
        { value: "EM", label: t('crm.contact_type.EM') },
        { value: "TEL", label: t('crm.contact_type.TEL') },
        { value: "PHY", label: t('crm.contact_type.PHY') },
        { value: "SMS", label: t('crm.contact_type.SMS') },
        { value: "WEB", label: t('crm.contact_type.WEB') }
    ];

    const sendMailTo = (email) => {
        window.open('mailto:' + (email ? email : selected_user?.email));
    };
    const call = (phone) => {
        window.open('tel:' + phone);
    };

    //UPDATE INFORMATIONS
    //for every fields (IMPORTANT: no validation)
    const updateGlobal = (newValue, field, callback) => {
        if (selected_user) {
            let user_temp = JSON.parse(JSON.stringify({ ...selected_user }));
            user_temp[field] = newValue;
            patchUser(user_client.client, user_temp, [field], () => {
                if (user_list) {
                    let user_list_updated = JSON.parse(JSON.stringify(user_list));
                    const index = user_list_updated.findIndex(u => u.id === user_temp.id);
                    if (index !== -1) {
                        user_list_updated[index] = user_temp;
                        dispatch(SetUserList(user_list_updated));
                    }
                }
                dispatch(SetSelectedUser(user_temp));
                if (callback !== null && callback !== undefined) {
                    callback();
                }
            });
        }
    };

    const updatePreferedContact = (event) => {
        if (selected_user) {
            const {
                target: { value }
            } = event;
            let user_temp = JSON.parse(JSON.stringify({ ...selected_user }));
            if (!user_temp.preferred_contact?.length) {
                user_temp.preferred_contact = [];
            }
            user_temp.preferred_contact = typeof value === 'string' ? value.split(',') : value;
            patchUser(user_client.client, user_temp, ["preferred_contact"], () => {
                if (user_list) {
                    let user_list_updated = JSON.parse(JSON.stringify(user_list));
                    const index = user_list_updated.findIndex(u => u.id === user_temp.id);
                    if (index !== -1) {
                        user_list_updated[index] = user_temp;
                        dispatch(SetUserList(user_list_updated));
                    }
                }
                dispatch(SetSelectedUser(user_temp));
            });
        }
    };

    const updateProhibit = (event) => {
        if (selected_user) {
            let user_temp = JSON.parse(JSON.stringify({ ...selected_user }));
            user_temp.is_prohibit = event.target.checked;
            patchUser(user_client.client, user_temp, ["is_prohibit"], () => {
                if (user_list) {
                    let user_list_updated = JSON.parse(JSON.stringify(user_list));
                    const index = user_list_updated.findIndex(u => u.id === user_temp.id);
                    if (index !== -1) {
                        user_list_updated[index] = user_temp;
                        dispatch(SetUserList(user_list_updated));
                    }
                }
                dispatch(SetSelectedUser(user_temp));
            });
        }
    };

    const updateUser = (field, value) => {
        const obj_c = JSON.parse(JSON.stringify(selected_user_temp ? selected_user_temp : selected_user));
        const fields = field?.split('.');
        switch (fields.length) {
            case 1:
                obj_c[fields[0]] = value;
                break;
            case 2:
                obj_c[fields[0]][fields[1]] = value;
                break;
            case 3:
                obj_c[fields[0]][fields[1]][fields[2]] = value;
                break;
            default:
                break;
        }
        console.log('selected_user_temp : selected_user', { selected_user_temp, selected_user, obj_c });
        SetSelectedUserTemp(obj_c);
    };

    const add = (type, e) => {
        if (!openAdditionalContactForm) {
            handleClickPopover(e, type);
        }
        if (type === "email") {
            const obj_c = JSON.parse(JSON.stringify(selected_user_temp ? selected_user_temp : selected_user));
            obj_c.additional_emails.push({ email: '', label: '' });
            updateUser('additional_emails', obj_c.additional_emails);
        } else if (type === "phone") {
            const obj_c = JSON.parse(JSON.stringify(selected_user_temp ? selected_user_temp : selected_user));
            obj_c.additional_phone_number.push({ phone: '+33', label: '' });
            updateUser('additional_phone_number', obj_c.additional_phone_number);
        }
    };

    const remove = (type, index) => {
        if (type === "email") {
            const obj_c = JSON.parse(JSON.stringify(selected_user_temp ? selected_user_temp : selected_user));
            obj_c.additional_emails.splice(index, 1);
            updateUser('additional_emails', obj_c.additional_emails);
        } else if (type === "phone") {
            const obj_c = JSON.parse(JSON.stringify(selected_user_temp ? selected_user_temp : selected_user));
            obj_c.additional_phone_number.splice(index, 1);
            updateUser('additional_phone_number', obj_c.additional_phone_number);
        }
    };

    const save = () => {
        const obj_c = JSON.parse(JSON.stringify(selected_user_temp ? selected_user_temp : selected_user));
        obj_c.additional_emails = obj_c.additional_emails ? obj_c.additional_emails?.filter(e => e.email) : [];
        obj_c.additional_phone_number = obj_c.additional_phone_number ? obj_c.additional_phone_number?.filter(e => e.phone_number) : [];
        console.log('obj_c', obj_c);
        patchUser(user_client.client, obj_c, ['additional_emails', 'additional_phone_number'], () => {
            if (user_list) {
                let user_list_updated = JSON.parse(JSON.stringify(user_list));
                const index = user_list_updated.findIndex(u => u.id === selected_user.id);
                if (index !== -1) {
                    user_list_updated[index] = selected_user;
                    dispatch(SetUserList(user_list_updated));
                }
            }
            dispatch(SetSelectedUser(selected_user_temp ? selected_user_temp : selected_user));
            handleClosePopover();
        });
    };

    const getUserAddress = () => {
        let full_address = selected_user.street ? selected_user.street : '';
        if (selected_user.zip_code) {
            full_address = `${full_address} ${selected_user.zip_code}`;
        }
        if (selected_user.city) {
            full_address = `${full_address} ${selected_user.city}`;
        }
        if (selected_user.country?.id && countries?.length) {
            const country = countries.find(c => c.id === selected_user.country.id);
            full_address = `${full_address}, ${country.name}`;
        }
        return full_address;
    };

    const displayPreferredContact = () => {
        let resp = [];
        if (selected_user.preferred_contact?.length) {
            resp = selected_user.preferred_contact.map(p => {
                const value = source_options.find(e => e.value === p)?.label;
                return value;
            });
        }
        if (!resp?.length) {
            resp = [];
        }
        return resp;
    };

    if (selected_user) {
        return (
            <div className="contact-field-container">
                <CrmUserDetailContactField label={t("crm.email")} value={selected_user.email} callback={(v) => updateGlobal(v.newValue, "email", v.onFinish)}
                    additional_action={selected_user.email ? { icon: <EmailIcon onClick={() => sendMailTo()} /> } : undefined} expandable={true}
                />
                <CrmUserDetailContactField label={t("crm.phone")} value={selected_user.phone_number} callback={(v) => updateGlobal(v.newValue, "phone_number", v.onFinish)}
                    additional_action={selected_user.email ? { icon: <CallIcon onClick={() => call(selected_user.phone_number)} /> } : undefined}
                    phone_input={true} expandable={true} />
                <CrmUserDetailContactField label={t("crm.cellphone")} value={selected_user.cellphone_number} callback={(v) => updateGlobal(v.newValue, "cellphone_number", v.onFinish)}
                    additional_action={selected_user.email ? { icon: <CallIcon onClick={() => call(selected_user.cellphone_number)} /> } : undefined} />
                <CrmUserDetailContactField label={t("crm.traveler_validation.birth_date")} value={selected_user.birth_date} is_date={true} expandable={true} />
                <CrmUserDetailContactField label={t("crm.wedding_date")} value={selected_user.wedding_date} is_date={true} expandable={true} />
                <CrmUserDetailContactField label={t("crm.address")} value={getUserAddress()} expandable={true} />
                <CrmUserDetailContactField label={t("crm.type")} options={type_options} value={selected_user.type} callback={(v) => updateGlobal(v.newValue, "type", v.onFinish)} />
                <CrmUserDetailContactField label={t("crm.noreply_email")} value={selected_user.noreply_email} callback={(v) => updateGlobal(v.newValue, "noreply_email", v.onFinish)} />
                <CrmUserDetailContactField label={t("crm.source")} options={source_options} value={selected_user.source} callback={(v) => updateGlobal(v.newValue, "source", v.onFinish)} />
                <CrmUserDetailContactField label={t("crm.contacted_by")} options={source_options} value={selected_user.contacted_by} callback={(v) => updateGlobal(v.newValue, "contacted_by", v.onFinish)} />
                <div className="prefered-contact">
                    <FormControl fullWidth>
                        <InputLabel>{t('crm.prefered_contact')}</InputLabel>
                        <Select
                            multiple
                            value={selected_user.preferred_contact}
                            onChange={updatePreferedContact}
                            input={<OutlinedInput label={t('crm.prefered_contact')} />}
                            renderValue={() => displayPreferredContact().join(', ')}
                        >
                            {source_options.filter(e => !e.hidden).map((opt) => (
                                <MenuItem key={opt} value={opt.value}>
                                    <Checkbox checked={selected_user.preferred_contact?.indexOf(opt.value) > -1} />
                                    <ListItemText primary={opt.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <FormControlLabel control={<Switch checked={selected_user.is_prohibit} onClick={(e) => updateProhibit(e)} />} label={t("crm.ban_on_sale")} />
                {
                    selected_user.is_prohibit &&
                    <CrmUserDetailContactField label={t("crm.ban_on_sale_reason")} multiline={true} value={selected_user.reason_prohibit} callback={(v) => updateGlobal(v.newValue, "reason_prohibit", v.onFinish)} />
                }
                <CrmUserDetailContactField label={t("crm.additional_emails")}
                    value={selected_user.additional_emails?.map(e => { return { value: e.email, label: `${e.email} (${e.label})` }; })} useBreakline={true}
                    customEditCallback={(e) => handleClickPopover(e, "email")}
                    additional_action={{ type: "send_email" }}
                />
                <Button variant="text" onClick={(e) => add('email', e)} startIcon={<AddCircleOutlineIcon />}
                    style={{ textTransform: "unset" }}>{t('crm.add_email')}</Button>
                <br />
                <br />
                <CrmUserDetailContactField label={t("crm.additional_phone_number")}
                    value={selected_user.additional_phone_number?.map(e => { return { value: e.phone_number, label: `${e.phone_number} (${e.label})` }; })} useBreakline={true}
                    customEditCallback={(e) => handleClickPopover(e, "phone")}
                    additional_action={{ type: "call" }}
                />
                <Button onClick={(e) => add('phone', e)} startIcon={<AddCircleOutlineIcon />}
                    variant="text" style={{ textTransform: "unset" }}>{t('crm.add_phone')}</Button>
                <Popover
                    open={Boolean(openAdditionalContactForm)}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center'
                    }}
                >
                    <div className="close-div" onClick={handleClosePopover}>
                        <CloseIcon />
                    </div>
                    <div className="contact-field-edit contact-field-edit-additional">
                        {
                            openAdditionalContactForm === "email" &&
                            <>
                                {
                                    selected_user_temp?.additional_emails?.map((email, email_index) => {
                                        return (
                                            <Grid container spacing={1} key={`add-${email_index}`}>
                                                <Grid item xs={5}>
                                                    <GenericField type="email" label={t('crm.additional_emails_email')} onChange={updateUser} object={selected_user_temp}
                                                        field={`additional_emails.${email_index}.email`} />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <GenericField label={t('crm.additional_emails_label')} onChange={updateUser} object={selected_user_temp}
                                                        field={`additional_emails.${email_index}.label`} />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <IconButton onClick={() => remove('email', email_index)}><DeleteIcon /></IconButton>
                                                </Grid>
                                            </Grid>
                                        );
                                    })
                                }
                                <Button variant="outlined" onClick={save}>{t('crm.save')}</Button>
                            </>
                        }
                        {
                            openAdditionalContactForm === "phone" &&
                            <>
                                {
                                    selected_user_temp?.additional_phone_number?.map((phone, phone_index) => {
                                        return (
                                            <Grid container spacing={1} key={`add-${phone_index}`}>
                                                <Grid item xs={5}>
                                                    <GenericField type="phone" label={t('crm.additional_phone_number_phone')} onChange={updateUser} object={selected_user_temp}
                                                        field={`additional_phone_number.${phone_index}.phone_number`} />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <GenericField label={t('crm.additional_phone_number_label')} onChange={updateUser} object={selected_user_temp}
                                                        field={`additional_phone_number.${phone_index}.label`} />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <IconButton onClick={() => remove('phone', phone_index)}><DeleteIcon /></IconButton>
                                                </Grid>
                                            </Grid>
                                        );
                                    })
                                }
                                <Button variant="outlined" onClick={save}>{t('crm.save')}</Button>
                            </>
                        }
                    </div>
                </Popover>
            </div>
        );
    }
    return null;
};
export default CrmUserDetailContact;
