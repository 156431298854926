// Dependencies
import clsx from 'clsx';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Core
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
// Icons
import Cached from '@material-ui/icons/Cached';
// Utils
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
// Actions
import { EditFlightStatus } from '../../Actions/Flight';

const useStyles = makeStyles({
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '4px 0',
        textTransform: 'uppercase'
    },
    cached: {
        float: 'left',
        marginRight: 8,
        cursor: 'pointer'
    }
});

const CartRefreshPending = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const flights = useSelector(state => state.flight.cart);
    const cars = useSelector(state => state.cars_search.cart);
    const transfers = useSelector(state => state.transfers.cart);
    const accommodations = useSelector(state => state.accommodation.sort_cart);
    const pois = useSelector(state => state.poi.cart);
    const user = useSelector(state => state.user.user);
    const [pendingProducts, setpendingProducts] = useState([]);
    const [refreshStatusRunning, setRefreshStatusRunning] = useState(false);
    const onRefreshPendingProducts = () => {
        const { headers } = CheckBeforeRequest();
        setRefreshStatusRunning(true);
        let request_count = 0;
        pendingProducts.map(product => {
            axios({
                method: 'POST',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/check_status/?product_types=${product.product_type}`,
                data: {
                    items: [product.id]
                }
            }).then(response => {
                request_count++;
                if (request_count === pendingProducts.length) {
                    setRefreshStatusRunning(false);
                }
                if (product.product_type === 6) {
                    dispatch(EditFlightStatus(response.data[0][0]));
                } else if (product.product_type === 2) {
                    dispatch({type: 'CARS_UPDATE_CART', payload: response.data});
                } else if (product.product_type === 4) {
                    dispatch({type: 'TRANSFERS_UPDATE_CART', payload: response.data});
                } else if (product.product_type === 0) {
                    dispatch({type: 'ACCOMMODATION_UPDATE_CART', payload: response.data});
                } else {
                    dispatch({type: 'POI_UPDATE_CART', payload: response.data});
                }
                if (response.data.booking_status_change !== undefined && response.data.booking_status_change === false) {
                    enqueueSnackbar(t('cart.check_booking_status_waiting'), {variant: 'warning'});
                } else {
                    enqueueSnackbar(t('cart.check_booking_status_success'), {variant: 'warning'});
                }
            }).catch(error => {
                request_count++;
                if (request_count === pendingProducts.length) {
                    setRefreshStatusRunning(false);
                }
                console.log(error);
                if (error.detail !== undefined) {
                    enqueueSnackbar(error.detail, {variant: 'danger'});
                }
            });
        });
    };
    useEffect(() => {
        let pending_products = [];
        flights.map(flight => {
            if (flight.is_displayed && (user.client_full.type !== 2 || !flight.deletion_restriction) && (((flight.booking_process_state === 'BOOK' && flight.booking_status === null) || (flight.booking_status !== null && (((flight.booking_status.status_booking === 'WAITING' || flight.booking_status.status_booking === 'PENDING') && flight.booking_process_state !== 'CANCEL') || (flight.booking_process_state === 'BOOK' && flight.booking_status.status_booking !== 'CONFIRMED')))) || flight.booking_process_state === 'CANCEL')) {
                pending_products.push(flight);
            }
        });
        cars.map(car => {
            if (car.is_displayed && (user.client_full.type !== 2 || !car.deletion_restriction) && (((car.booking_process_state === 'BOOK' && car.booking_status === null) || (car.booking_status !== null && (((car.booking_status.status_booking === 'WAITING' || car.booking_status.status_booking === 'PENDING') && car.booking_process_state !== 'CANCEL') || (car.booking_process_state === 'BOOK' && car.booking_status.status_booking !== 'CONFIRMED')))) || car.booking_process_state === 'CANCEL')) {
                pending_products.push(car);
            }
        });
        transfers.map(transfer => {
            if (transfer.is_displayed && (user.client_full.type !== 2 || !transfer.deletion_restriction) && (((transfer.booking_process_state === 'BOOK' && transfer.booking_status === null) || (transfer.booking_status !== null && (((transfer.booking_status.status_booking === 'WAITING' || transfer.booking_status.status_booking === 'PENDING') && transfer.booking_process_state !== 'CANCEL') || (transfer.booking_process_state === 'BOOK' && transfer.booking_status.status_booking !== 'CONFIRMED')))) || transfer.booking_process_state === 'CANCEL')) {
                pending_products.push(transfer);
            }
        });
        accommodations.map(accommodation => {
            accommodation.rooms.map(room => {
                if (room.is_displayed && (user.client_full.type !== 2 || !room.deletion_restriction) && (((room.booking_process_state === 'BOOK' && room.booking_status === null) || (room.booking_status !== null && (((room.booking_status.status_booking === 'WAITING' || room.booking_status.status_booking === 'PENDING') && room.booking_process_state !== 'CANCEL') || (room.booking_process_state === 'BOOK' && room.booking_status.status_booking !== 'CONFIRMED')))) || room.booking_process_state === 'CANCEL')) {
                    pending_products.push(room);
                }
            });
        });
        pois.map(poi => {
            if (poi.is_displayed && (user.client_full.type !== 2 || !poi.deletion_restriction) && (((poi.booking_process_state === 'BOOK' && poi.booking_status === null) || (poi.booking_status !== null && (((poi.booking_status.status_booking === 'WAITING' || poi.booking_status.status_booking === 'PENDING') && poi.booking_process_state !== 'CANCEL') || (poi.booking_process_state === 'BOOK' && poi.booking_status.status_booking !== 'CONFIRMED')))) || poi.booking_process_state === 'CANCEL')) {
                pending_products.push(poi);
            }
        });
        setpendingProducts(pending_products);
    }, [flights, cars, transfers, accommodations, pois]);
    if (pendingProducts.length > 0) {
        return (
            <Typography className={ clsx(classes.booking, classes.pending) } onClick={ onRefreshPendingProducts }>
                { refreshStatusRunning ? <CircularProgress size={ 17 }/> : <Cached className={ classes.cached }/> } { t('cart-material.refresh-all-status') }
            </Typography>
        );
    }
    return null;
};

export default CartRefreshPending;