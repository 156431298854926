/*eslint-disable brace-style */
/*eslint-disable react/self-closing-comp */
/*eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import moment from "moment";

import Avatar from '@mui/material/Avatar';

import '../crm.css';
import { useSelector } from "react-redux";
import { getTrip, patchTrip } from "../Functions/TripFunction";

const Card = (data) => {
    const { t } = useTranslation();
    const language = useSelector((store) => {
        return store.header.tmp_language;
    });
    const [{ isDragging }, drag] = useDrag({
        type: 'CARD',
        item: data,
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });
    const demand = data.data;

    const getIniName = (user) => {
        let ini = '';
        if (user?.first_name) {
            ini = user.first_name.charAt(0);
        }
        if (user?.last_name) {
            ini = ini + user.last_name.charAt(0);
        }
        return ini;
    };

    const getName = (user) => {
        let name = '';
        if (user?.first_name) {
            name = user.first_name;
        }
        if (user?.last_name) {
            name = name + ' ' + user.last_name.toUpperCase();
        }
        return name;
    };

    const openDetail = () => {
        window.location.replace(`/${window.url_name}/menu/crm-demand/${demand.id}`);
    };

    return (
        <div className="crm-list-trip-item" ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <h4 onClick={openDetail}>{demand.accounting_reference} {demand.name}</h4>
            <p><b>{t('crm.demand.destination')} : </b>{(demand.data?.length && demand.data[demand.data.length - 1]?.destination?.data?.international_name) ? demand.data[demand.data.length - 1].destination.data.international_name : null}</p>
            <p>
                <b>{t('crm.demand.budget')} : </b>
                {
                    new Intl.NumberFormat(language, { style: 'currency', currency: demand.currency?.iso_code ? demand.currency.iso_code : "EUR" })
                        .format(Math.ceil(parseFloat((demand.data?.length && demand.data[demand.data.length - 1]?.budget ? demand.data[demand.data.length - 1].budget : "0"))))
                }
            </p>
            <p>
                <b>{t('crm.demand.validity_date')} : </b>
                {
                    demand.data?.length && demand.data[demand.data.length - 1]?.validity_date ? moment.utc(demand.data[demand.data.length - 1].validity_date).format("DD/MM/YYYY") : null
                }
            </p>
            <p>
                <b>{t('crm.contact_traveler')} : </b>
                {
                    getName((demand.data?.length && demand.data[demand.data.length - 1]?.contact_traveler_detail) ? demand.data[demand.data.length - 1].contact_traveler_detail : null)
                }
            </p>
            <hr />
            <div>
                <div style={{ display: "flex", gap: 2 }}>
                    <Avatar>{demand.user_to?.username?.charAt(0)}</Avatar>
                    <p>{demand.user_to?.username}</p>
                </div>
                <i>{t('crm.created_on')} {moment.utc(demand.created_date).format("DD/MM/YYYY")}</i>
            </div>
        </div>
    );
};

const List = ({ title, type, cards, onCardDrop }) => {
    const [{ isOver }, drop] = useDrop({
        accept: 'CARD',
        drop: (item) => {
            if (item.type === type) {
                return;
            }
            onCardDrop(item, type);
        },
        collect: monitor => ({
            isOver: monitor.isOver()
        })
    });

    return (
        <div ref={drop} className={`crm-list-trip ${isOver ? 'over' : ''}`}>
            <h3>{title}</h3>
            {cards.map((card, index) => (
                <Card key={index} data={card} />
            ))}
        </div>
    );
};

const CrmDemandManagement = () => {
    const { t } = useTranslation();

    const user = useSelector(store => store.user.user);

    const [lists, setLists] = useState([
        { title: t('crm.sketch'), type: 'sketch', cards: [] },
        { title: t('crm.offer'), type: 'offer', cards: [] },
        { title: t('crm.ongoing'), type: 'ongoing', cards: [] },
        { title: t('crm.confirmed'), type: 'confirmed', cards: [] },
        { title: t('crm.in_progress'), type: 'in_progress', cards: [] },
        { title: t('crm.finished'), type: 'finished', cards: [] }
    ]);

    const [tripSketchs, setTripSketchs] = useState([]);
    const [tripOffers, setTripOffers] = useState([]);
    const [tripOngoings, setTripOngoings] = useState([]);
    const [tripConfirmeds, setTripConfirmeds] = useState([]);
    const [tripInProgress, setTripInProgress] = useState([]);
    const [tripPaids, setTripPaids] = useState([]);
    const [tripCountSketchs, setTripCountSketchs] = useState(0);
    const [tripCountOffers, setTripCountOffers] = useState(0);

    useEffect(() => {
        if (user.client) {
            loadData();
        }
    }, [user]);

    useEffect(() => {
        setLists([
            { title: t('crm.sketch'), type: 'sketch', cards: tripSketchs },
            { title: t('crm.offer'), type: 'offer', cards: tripOffers },
            { title: t('crm.ongoing'), type: 'ongoing', cards: tripOngoings },
            { title: t('crm.confirmed'), type: 'confirmed', cards: tripConfirmeds },
            { title: t('crm.in_progress'), type: 'in_progress', cards: tripInProgress },
            { title: t('crm.finished'), type: 'finished', cards: tripPaids }
        ]);
    }, [tripSketchs, tripOngoings, tripPaids, tripOffers, tripConfirmeds, tripInProgress]);

    const loadData = () => {
        const currentDate = new Date();
        getTrip(user.client, "is_sketch=true&ordering=-current_version__modified_date", (resp) => {
            if (resp.data?.results) {
                setTripCountSketchs(resp.data.count);
                const trips = resp.data.results?.filter(item => {
                    const version = item.data?.length ? item.data[item.data.length - 1] : { validity_date: new Date() };
                    const itemDate = new Date(version.validity_date);
                    return itemDate > currentDate;
                })?.map(e => { return { ...e, type: "sketch" }; });
                setTripSketchs(trips);
            }
        });
        getTrip(user.client, "current_version__status_contract__in=QUOTATION&ordering=-current_version__modified_date", (resp) => {
            if (resp.data?.results) {
                setTripCountOffers(resp.data.count);
                const trips = resp.data.results?.filter(item => {
                    const version = item.data?.length ? item.data[item.data.length - 1] : { validity_date: new Date() };
                    const itemDate = new Date(version.validity_date);
                    return itemDate > currentDate;
                })?.map(e => { return { ...e, type: "offer" }; });
                setTripOffers(trips);
            }
        });
        // getTrip(user.client, "ongoing", (resp) => {
        //     if (resp.data?.results) {
        //         const trips = resp.data.results?.filter(item => new Date(item.date) > currentDate)?.map(e => { return { ...e, type: "ongoing" }; });
        //         setTripOngoings(trips);
        //     }
        // });
        // getTrip(user.client, "confirmed", (resp) => {
        //     if (resp.data?.results) {
        //         const trips = resp.data.results?.filter(item => new Date(item.date) > currentDate)?.map(e => { return { ...e, type: "confirmed" }; });
        //         setTripConfirmeds(trips);
        //     }
        // });
        // getTrip(user.client, "in_progress", (resp) => {
        //     if (resp.data?.results) {
        //         const trips = resp.data.results?.filter(item => new Date(item.date) > currentDate)?.map(e => { return { ...e, type: "in_progress" }; });
        //         setTripInProgress(trips);
        //     }
        // });
        // getTrip(user.client, "finished", (resp) => {
        //     if (resp.data?.results) {
        //         const trips = resp.data.results?.filter(item => new Date(item.date) > currentDate)?.map(e => { return { ...e, type: "finished" }; });
        //         setTripPaids(trips);
        //     }
        // });
    }

    const handleCardDrop = (card, listType) => {
        if (card.type === listType) {
            return;
        }

        const updatedCard = { ...card, type: listType };
        const version = updatedCard.data?.data?.length ? updatedCard.data.data[updatedCard.data.data.length - 1] : null;
        switch (listType) {
            case "offer":
                patchTrip(user.client, updatedCard.data.id, { is_sketch: false }, { is_sketch: true }, (resp) => {
                    if (resp.data) {
                        loadData();
                    }
                });
                break;
            default:
                break;
        }
        // const updatedLists = lists.map(list => {
        //     if (list.type === card.type) {
        //         return {
        //             ...list,
        //             cards: list.cards.filter(c => c.name !== card.name)
        //         };
        //     }
        //     if (list.type === listType) {
        //         return {
        //             ...list,
        //             cards: [...list.cards, updatedCard]
        //         };
        //     }
        //     return list;
        // });
        // setLists(updatedLists);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="crm-list-management">
                <List
                    title={`${t('crm.sketch')} (${tripCountSketchs})`}
                    type="sketch"
                    cards={lists.find(list => list.type === 'sketch').cards}
                    onCardDrop={handleCardDrop}
                />
                <div className="crm-list-trip-separator"></div>
                <List
                    title={t('crm.offer')}
                    type="offer"
                    cards={lists.find(list => list.type === 'offer').cards}
                    onCardDrop={handleCardDrop}
                />
                <div className="crm-list-trip-separator"></div>
                <List
                    title={t('crm.ongoing')}
                    type="ongoing"
                    cards={lists.find(list => list.type === 'ongoing').cards}
                    onCardDrop={handleCardDrop}
                />
                <div className="crm-list-trip-separator"></div>
                <List
                    title={t('crm.confirmed')}
                    type="confirmed"
                    cards={lists.find(list => list.type === 'confirmed').cards}
                    onCardDrop={handleCardDrop}
                />
                <div className="crm-list-trip-separator"></div>
                <List
                    title={t('crm.in_progress')}
                    type="in_progress"
                    cards={lists.find(list => list.type === 'in_progress').cards}
                    onCardDrop={handleCardDrop}
                />
                <div className="crm-list-trip-separator"></div>
                <List
                    title={t('crm.finished')}
                    type="finished"
                    cards={lists.find(list => list.type === 'finished').cards}
                    onCardDrop={handleCardDrop}
                />
            </div>
            <div className="crm-list-management-footer">
                <div className="crm-list-management-footer-item"><b>0,00€</b>&nbsp; (0%) </div>
                <div className="crm-list-trip-separator"></div>
                <div className="crm-list-management-footer-item"><b>0,00€</b>&nbsp; (0%) </div>
                <div className="crm-list-trip-separator"></div>
                <div className="crm-list-management-footer-item"><b>0,00€</b>&nbsp; (0%) </div>
                <div className="crm-list-trip-separator"></div>
                <div className="crm-list-management-footer-item"><b>0,00€</b>&nbsp; (0%) </div>
                <div className="crm-list-trip-separator"></div>
                <div className="crm-list-management-footer-item"><b>0,00€</b>&nbsp; (0%) </div>
                <div className="crm-list-trip-separator"></div>
                <div className="crm-list-management-footer-item"><b>0,00€</b></div>
            </div>
        </DndProvider>
    );
};
export default CrmDemandManagement;
