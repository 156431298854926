export function SetSummaryStep(summary_step) {
   return {
        type: "SUMMARY_SET_SUMMARY_STEP",
        payload: {
            summary_step: summary_step
        }
    }
}
export function SetTripInfo(trip_info) {
    return {
        type: "SUMMARY_SET_SUMMARY_TRIP_INFO",
        payload: {
            trip_info: trip_info
        }
    };
}
export function SetTripMainInfo(trip_main_info) {
    return {
        type: "SUMMARY_SET_SUMMARY_TRIP_MAIN_INFO",
        payload: {
            trip_main_info: trip_main_info
        }
    };
}
export function SetManualCart(manual_cart) {
    return {
        type: "SUMMARY_SET_MANUAL_CART",
        payload: {
            manual_cart: manual_cart
        }
    };
}
export function SetPoiCart(poi_cart) {
    return {
        type: "SUMMARY_SET_CUSTOM_CART",
        payload: {
            poi_cart: poi_cart
        }
    };
}
export function SetCarsCart(cars_cart) {
    return {
        type: "SUMMARY_SET_CARS_CART",
        payload: {
            cars_cart: cars_cart
        }
    };
}
export function SetFlightCart(flight_cart) {
    return {
        type: "SUMMARY_SET_FLIGHT_CART",
        payload: {
            flight_cart: flight_cart
        }
    };
}
export function SetTransfersCart(transfers_cart) {
    return {
        type: "SUMMARY_SET_TRANSFERS_CART",
        payload: {
            transfers_cart: transfers_cart
        }
    };
}
export function SetHotelCart(hotel_cart) {
    return {
        type: "SUMMARY_SET_HOTEL_CART",
        payload: {
            hotel_cart: hotel_cart
        }
    };
}
export function SetIti(itinerary) {
    return {
        type: "SUMMARY_SET_ITINERARY",
        payload: {
            itinerary: itinerary
        }
    };
}
export function SetHover(index) {
    return {
        type: "SUMMARY_SET_HOVER",
        payload: {
            step_hover: index
        }
    };
}
export function SetCurrentStep(index) {
    return {
        type: "SUMMARY_SET_CURRENT_STEP",
        payload: {
            current_step: index
        }
    };
}
export function SetItiToDraw(itinerary_to_draw) {
    return {
        type: "SUMMARY_SET_ITINERARY_TO_DRAW",
        payload: {
            itinerary_to_draw: itinerary_to_draw
        }
    };
}
export function SetGuideList(guide_list) {
    return {
        type: "SUMMARY_SET_GUIDE_LIST",
        payload: {
            guide_list: guide_list
        }
    };
}
export function SetTravelersGroup(travelers_group) {
    return {
        type: "SUMMARY_SET_TRAVELERS_GROUP",
        payload: {
            travelers_group: travelers_group
        }
    };
}
export function SetTravelers(travelers) {
    return {
        type: "SUMMARY_SET_TRAVELERS",
        payload: {
            travelers: travelers
        }
    };
}
export function TogleInfoCustomAndSetStep(index, modal_custom_info, step_custom_info, custom_info) {
    return {
        type: "SUMMARY_TOGGLE_AND_SET_CUSTOM_MODAL",
        payload: {
            index: index,
            modal_custom_info: modal_custom_info,
            step_custom_info: step_custom_info,
            custom_info: custom_info
        }
    };
}
export function TogleInfoHotelAndSetStep(modal_info_hotel, step_info_hotel, info_hotel) {
    return {
        type: "SUMMARY_TOGGLE_AND_SET_HOTEL_MODAL",
        payload: {
            modal_info_hotel: modal_info_hotel,
            step_info_hotel: step_info_hotel,
            info_hotel: info_hotel
        }
    };
}
export function ToggleAndSetPicturesModal(pictures_arr, index_pictures) {
    return {
        type: "SUMMARY_TOGGLE_AND_SET_PICTURE_MODAL",
        payload: {
            pictures_arr: pictures_arr,
            index_pictures: index_pictures
        }
    };
}
export function EditAccommodationCart(item, index) {
    return {
        type: "SUMMARY_ACCOMMODATION_EDIT_CART",
        payload: {
            item: item,
            index: index
        }
    }
}
export function EditPoiCart(item, index) {
    return {
        type: "SUMMARY_POI_EDIT_CART",
        payload: {
            item: item,
            index: index
        }
    }
}
export function EditCarCart(item, index) {
    return {
        type: "SUMMARY_CARS_EDIT_CART",
        payload: {
            item: item,
            index: index
        }
    }
}
export function setTotalPrice(total_price, out_of_cart) {
    return {
        type: "SUMMARY_SET_TOTAL_PRICE",
        payload: {
            total_price: total_price,
            out_of_cart: out_of_cart
        }
    }
}
