import { Note } from "../../objects/notes";
import { 
    SET_TRIP_LIST_NOTES,
    SET_TRIP_LIST_NOTES_VALUE,
    TripListNotesActionTypes
} from "./actionTypes";

export const setTripListNotes = (notes: Note[]): TripListNotesActionTypes => ({
    type: SET_TRIP_LIST_NOTES,
    payload: notes
});

export const setTripListNotesValue = (id: number, value: string): TripListNotesActionTypes => ({
    type: SET_TRIP_LIST_NOTES_VALUE,
    payload: {
        id: id,
        value: value
    }
});