import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { List, AutoSizer, WindowScroller, CellMeasurer , CellMeasurerCache } from "react-virtualized";
import Grid from '@material-ui/core/Grid';

import RenderDisplayCars from '../../CarsMaterial/RenderDisplayCars';

class GenerateCars extends Component {
	constructor(props, context) {
		super(props, context);
		this.cache = new CellMeasurerCache({
			fixedWidth: true,
		  	defaultHeight: 326,
		  	// keyMapper: () => 1,
		});
		this.list = null;
	    this.renderRow = this.renderRow.bind(this);
	    this.bindListRef = this.bindListRef.bind(this);
	}
	componentDidUpdate(prevProps, prevState) {
		const { detailed_index, click_type } = this.props;
		if (detailed_index !== prevProps.detailed_index && detailed_index !== null && click_type !== prevProps.click_type) {
			let index = this.getIndex();
	    	this.cache.clearAll(index); //Clear the cache if row heights are recompute to be sure there are no "blank spaces" (some row are erased)
	    	this.virtualizedList && this.virtualizedList.recomputeRowHeights();
		}
		if (this.list) {
	      	this.list.forceUpdateGrid();
	    }
	}
	componentWillReceiveProps() { //Really important !!
		let index = this.getIndex();
		if (index !== null) {
			this.cache.clearAll(index); //Clear the cache if row heights are recompute to be sure there are no "blank spaces" (some row are erased)
			this.virtualizedList && this.virtualizedList.recomputeRowHeights();			
		}
		if (this.list) {
	      	this.list.forceUpdateGrid();
	    }
	}
	getIndex() {
		const { detailed_index, display_cars } = this.props;
		let index = null;
		display_cars.map((car, car_index) => {
			if (car_index === detailed_index) {
				index = car_index;
			}
		});
		return index;
	}
	bindListRef(ref) {
	    this.list = ref;
  	}
	renderRow({ index, parent, key, style }) {
		const { setDisplayCars, display_cars, results } = this.props;
		return (
			<CellMeasurer
				key={key}
				cache={this.cache}
				parent={parent}
				columnIndex={0}
				rowIndex={index}
			>
			{
				({ measure }) => {
					return (
						<div key={key} style={style} onLoad={measure} >
							<RenderDisplayCars card={parent.props.data[index]} results={results} card_index={index} setDisplayCars={setDisplayCars} display_cars={display_cars}/>
						</div>
					);
				}
			}
			</CellMeasurer>
		);
	}
	render() {
		const { display_cars } = this.props;
		return (
			<WindowScroller> 
				{
					({ height, isScrolling, registerChild, scrollTop }) => {
						return (
							<div ref={registerChild}>
								<AutoSizer disableHeight>
						        {({ width }) => {
									return (
										<List
											ref={this.bindListRef}
											autoHeight
											rowCount={display_cars.length}
											isScrolling={isScrolling}
											width={width}
											height={height}
											scrollTop={scrollTop}
											deferredMeasurementCache={this.cache}
											rowHeight={this.cache.rowHeight}
											rowRenderer={this.renderRow}
											data={display_cars}
											overscan={10}
										/>
									);
					        	}}
					        	</AutoSizer>
							</div>
						)
					}
				}
			</WindowScroller>
		);
	}
}
export default GenerateCars;
