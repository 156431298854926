//---- Dependencies ----//
import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

//---- Components ----//
import CustomOverlay from "../../Common/CustomOverlay.jsx";
import SummaryPicturesModal from "./SummaryPicturesModal.jsx";
import HotelInfoModal from "./HotelInfoModal.jsx";

//---- Actions ----//
import { TogleInfoHotelAndSetStep, ToggleAndSetPicturesModal, SetHotelCart } from "../../../Actions/Summary";

//---- CSS ----//
import "../../../Style/ItineraryType.css";
import "../../../Style/Accommodation.css";

//---- Class ----//
@connect((store) => {
    return {
        hotel_cart: store.summary.hotel_cart
    };
})
@withTranslation()
@withRouter
class SummaryStepHotel extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            displayed_room_arr: []
        };
    }
    componentDidMount() {
        const { hotel_cart, step } = this.props;
        let displayed_room_arr = [];
        hotel_cart.map((room) => {
            if ((moment.utc(room.start_date).isAfter(moment.utc(step.start_date)) || moment.utc(room.start_date).isSame(moment.utc(step.start_date), "d")) && (moment.utc(room.end_date).isBefore(moment.utc(step.end_date)) || moment.utc(room.end_date).isSame(moment.utc(step.end_date), "d"))) {
                if (room.is_displayed === true) {
                    displayed_room_arr.push(room);
                }
            }
        });
        this.setState({
            displayed_room_arr: displayed_room_arr
        });
    }

    getRoomType(travelers_nb) {
        let room_type = "";
        const { t } = this.props;
        if (travelers_nb === 1) {
            room_type += t("summary.simple_room");
        }
        if (travelers_nb === 2) {
            room_type += t("summary.twin_or_double");
        }
        if (travelers_nb === 3) {
            room_type += t("summary.triple_room");
        }
        if (travelers_nb === 4) {
            room_type += t("summary.quadruple_room");
        }
        if (travelers_nb > 4) {
            room_type += t("summary.information_from_hotel");
        }
        return room_type;
    }

    render() {
        const { index, hotel_cart, step, t, dispatch, component } = this.props;
        const { displayed_room_arr } = this.state;

        return (
            <div >
                {
                    displayed_room_arr !== null && (
                        <Fragment>
                            {
                                displayed_room_arr.map((displayed_room, displayed_room_index) => {
                                    let situation_check = null;
                                    let distances = null;
                                    let equipements = null;
                                    let rooms = null;
                                    let check_in_out = null;
                                    let restauration = null;
                                    let other = null;
                                    let sports_animation = null;
                                    let to_know = null;
                                    let hotel_picture = "/Img/accommodation_default.jpg";
                                    let stars = [];
                                    let nb_day = 0;
                                    let address = "";
                                    let situation = null;
                                    let content = null;
                                    if (displayed_room !== null) {

                                        for (let i = 0; i < displayed_room.hotel[0].content.length; i++) {
                                            if (displayed_room.hotel[0].content[i].section === 100) {
                                                situation_check = displayed_room.hotel[0].content[i];
                                            }
                                            if (displayed_room.hotel[0].content[i].section === 110) {
                                                equipements = displayed_room.hotel[0].content[i];
                                            }
                                            if (displayed_room.hotel[0].content[i].section === 120) {
                                                rooms = displayed_room.hotel[0].content[i];
                                            }
                                            if (displayed_room.hotel[0].content[i].section === 150) {
                                                check_in_out = displayed_room.hotel[0].content[i];
                                            }
                                            if (displayed_room.hotel[0].content[i].section === 160) {
                                                restauration = displayed_room.hotel[0].content[i];
                                            }
                                            if (displayed_room.hotel[0].content[i].section === 170) {
                                                to_know = displayed_room.hotel[0].content[i];
                                            }
                                            if (displayed_room.hotel[0].content[i].section === 200) {
                                                other = displayed_room.hotel[0].content[i];
                                            }
                                            if (displayed_room.hotel[0].content[i].section === 210) {
                                                distances = displayed_room.hotel[0].content[i];
                                            }
                                            if (displayed_room.hotel[0].content[i].section === 103) {
                                                sports_animation = displayed_room.hotel[0].content[i];
                                            }
                                        }
                                        if (displayed_room.hotel[0].cover_picture !== null) {
                                            if (displayed_room.hotel[0].cover_picture.thumbnail_little !== null) {
                                                hotel_picture = displayed_room.hotel[0].cover_picture.thumbnail_little;
                                            } else {
                                                hotel_picture = displayed_room.hotel[0].cover_picture.url;
                                            }
                                        }
                                        if (displayed_room.hotel[0].cover_picture === null) {
                                            if (displayed_room.hotel[0].custom_pictures.length > 0) {
                                                if (displayed_room.hotel[0].custom_pictures[0].thumbnail_little !== null) {
                                                    hotel_picture = displayed_room.hotel[0].custom_pictures[0].thumbnail_little;
                                                } else {
                                                    hotel_picture = displayed_room.hotel[0].custom_pictures[0].url;
                                                }
                                            } else if (displayed_room.hotel[0].pictures.length > 0) {
                                                if (displayed_room.hotel[0].pictures[0].thumbnail_little !== null) {
                                                    hotel_picture = displayed_room.hotel[0].pictures[0].thumbnail_little;
                                                } else {
                                                    hotel_picture = displayed_room.hotel[0].pictures[0].url;
                                                }
                                            }
                                        }
                                        let moment_end_date = moment.utc(displayed_room.end_date);
                                        let night_start_date = moment.utc(displayed_room.start_date);
                                        let inc = 0;
                                        while (!moment.utc(night_start_date.add(inc, "d").format("YYYY-MM-DD")).isSame(moment.utc(moment_end_date.format("YYYY-MM-DD")))) {
                                            inc = 1;
                                            nb_day = nb_day + 1;
                                            if (nb_day === 100) {
                                                break;
                                            }
                                        }
                                        for (let i = 0; i < displayed_room.hotel[0].stars; i++) {
                                            stars.push(<i key={ i } className={ "icon-045-star" }/>)
                                        }
                                        for (let i = 0; i < displayed_room.hotel[0].content.length; i++) {
                                            if (displayed_room.hotel[0].content[i].section === 100) {
                                                situation = displayed_room.hotel[0].content[i];
                                                if (situation.content.length > 250) {
                                                    content = (situation.content).slice(0, 250);
                                                    content += "...";
                                                } else {
                                                    content = situation.content;
                                                }
                                            }
                                        }
                                        if (displayed_room.hotel[0].house_number !== null) {
                                            address += displayed_room.hotel[0].house_number;
                                        }
                                        if (displayed_room.hotel[0].street !== null) {
                                            if (displayed_room.hotel[0].house_number !== null) {
                                                address += " " + displayed_room.hotel[0].street;
                                            } else {
                                                address += displayed_room.hotel[0].street;
                                            }
                                        }
                                        if (displayed_room.hotel[0].zip_code !== null) {
                                            if (displayed_room.hotel[0].house_number !== null || displayed_room.hotel[0].street !== null) {
                                                address += " " + displayed_room.hotel[0].zip_code;
                                            } else {
                                                address += displayed_room.hotel[0].zip_code;
                                            }
                                        }
                                        if (displayed_room.hotel[0].city_name !== null) {
                                            if (displayed_room.hotel[0].house_number !== null || displayed_room.hotel[0].street !== null || displayed_room.hotel[0].zip_code !== null) {
                                                address += " " + displayed_room.hotel[0].city_name;
                                            } else {
                                                address += displayed_room.hotel[0].city_name;
                                            }
                                        }
                                    }
                                    return (
                                        <div className={"step-hotel-main-container"}>
                                            <div className={"icon-step-hotel-container"} >
                                                <img className={"ft-contain-img icon-step-hotel-icon"} src={"Img/ItineraryType/hotel-icon.png"} />
                                            </div>
                                            <div className={"step-hotel-title-container"}>
                                                <span className={"ft-dark-green bold"}>{ displayed_room.hotel[0].name }  </span>
                                                <span> { stars } </span>
                                                <span className={"ft-dark-green italic"}> { displayed_room.is_optional === true ? "(En option)" : "" } </span>
                                                <div className={"italic ft-light-green ft-font-13 ft-mt-5"} >
                                                    <i className={ "icon-075-calendar pr10" }/>
                                                    { t("cart.from") + " " + (displayed_room === true ? (moment.utc(displayed_room.start_date).format("DD MMMM, HH:mm") + " " + t("cart.to") + " " + moment.utc(displayed_room.end_date).format("DD MMMM, HH:mm")) : (moment.utc(displayed_room.start_date).format("DD MMMM") + " " + t("cart.to") + " " + moment.utc(displayed_room.end_date).format("DD MMMM")))}
                                                    { " " + nb_day } nuit(s)
                                                </div>
                                            </div>
                                            <div className={"ft-no-margin row"} >
                                                <div className={"col-md-5 col-12 no-padding step-hotel-container-img mt15"} >
                                                    <img className={"ft-cover-img"} src={hotel_picture} />
                                                    <div className={"icon-hotel-infos-container block-center text-center"} >
                                                        <span>
                                                            <CustomOverlay id={"tooltip-header-date"} tooltip={t("summary.pictures_infos")} placement={"bottom"} >
                                                                <div className={"rounded-icon-container"} onClick={ () => { dispatch(ToggleAndSetPicturesModal((displayed_room.hotel[0].custom_pictures.length > 0 ? displayed_room.hotel[0].custom_pictures : displayed_room.hotel[0].pictures), 0 )); } }><i className={"icon-hotel-infos icon-camera"}/></div>
                                                            </CustomOverlay>
                                                            {
                                                                (situation_check !== null || distances !== null || to_know !== null || other !== null) && (
                                                                    <CustomOverlay id={"tooltip-header-date"} tooltip={t("footer.about")} placement={"bottom"} >
                                                                        <div className={"rounded-icon-container"} onClick={ () => { dispatch(TogleInfoHotelAndSetStep(true, 1, displayed_room)); } }><i className={"icon-hotel-infos icon-info"}/></div>
                                                                    </CustomOverlay>
                                                                )
                                                            }
                                                            {
                                                                (rooms !== null || check_in_out !== null) && (
                                                                    <CustomOverlay id={"tooltip-header-date"} tooltip={t("global.room")} placement={"bottom"} >
                                                                        <div className={"rounded-icon-container"} onClick={ () => { dispatch(TogleInfoHotelAndSetStep(true, 2, displayed_room)); } }><i className={"icon-hotel-infos icon-053-bed"}/></div>
                                                                    </CustomOverlay>
                                                                )
                                                            }
                                                            {
                                                                (equipements !== null || sports_animation !== null ) && (
                                                                    <CustomOverlay id={"tooltip-header-date"} tooltip={t("summary.activity_services")} placement={"bottom"} >
                                                                        <div className={"rounded-icon-container"} onClick={ () => { dispatch(TogleInfoHotelAndSetStep(true, 3, displayed_room)); } }><i className={"icon-hotel-infos icon-116-man-with-bag-and-walking-stick"}/></div>
                                                                    </CustomOverlay>
                                                                )
                                                            }
                                                            {
                                                                (displayed_room.hotel[0].facts.length > 0) && (
                                                                    <CustomOverlay id={"tooltip-header-date"} tooltip={t("accommodation.equipments")} placement={"bottom"} >
                                                                        <div className={"rounded-icon-container"} onClick={ () => { dispatch(TogleInfoHotelAndSetStep(true, 6, displayed_room)); } }><i className={"icon-hotel-infos icon-settings"}/></div>
                                                                    </CustomOverlay>
                                                                )
                                                            }
                                                            {
                                                                (restauration !== null) && (
                                                                    <CustomOverlay id={"tooltip-header-date"} tooltip={t("summary.restauration")} placement={"bottom"} >
                                                                        <div className={"rounded-icon-container"} onClick={ () => { dispatch(TogleInfoHotelAndSetStep(true, 4, displayed_room)); } }><i className={"icon-hotel-infos icon-034-dinner"}/></div>
                                                                    </CustomOverlay>
                                                                )
                                                            }
                                                            {
                                                                (displayed_room.hotel[0].longitude !== null && displayed_room.hotel[0].latitude !== null) && (
                                                                    <CustomOverlay id={"tooltip-header-date"} tooltip={t("summary.google_map")} placement={"bottom"} >
                                                                        <div className={"rounded-icon-container"} onClick={ () => { dispatch(TogleInfoHotelAndSetStep(true, 5, displayed_room)); } }><i className={"icon-hotel-infos icon-028-pin"}/></div>
                                                                    </CustomOverlay>
                                                                )
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className={"col-md-6 no-padding step-hotel-right-container mt15"} >
                                                    {
                                                        situation !== null && (
                                                            <div>
                                                                <h4 className={"ft-dark-green col-md-12"} > { t("footer.about") } </h4>
                                                                <div className={"step-hotel-description col-md-12 ft-mt5"} > { content } </div>
                                                            </div>
                                                        )
                                                    }
                                                    <div className={ "col-md-12 italic ft-mt5" }><i className={ "icon-028-pin pr10" }/>{ address }</div>
                                                    <br />
                                                    <h4 className={"ft-dark-green col-md-12"} > { t("summary.room_information") } </h4>
                                                    <div className={ "col-md-12 ft-mt5" } > <i className={(displayed_room.breakfast_included !== undefined && displayed_room.breakfast_included !== null && displayed_room.breakfast_included) ? "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view green ft-pr5" : "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view ft-pr5"}/> {(displayed_room.breakfast_included !== undefined && displayed_room.breakfast_included !== null && displayed_room.breakfast_included) ? t("accommodation.breakfast_included") : t("accommodation.breakfast_not_included")} </div>
                                                    <div className={ "col-md-12 ft-mt5" }> <i className={"icon-053-bed ft-pr5"} />{ t("global.room") + " " + 1 + " : " + this.getRoomType(displayed_room.group_passenger.travelers_list.length) }</div>
                                                    {
                                                        displayed_room.rooms !== undefined && displayed_room.rooms.length > 0 && (
                                                            <Fragment>
                                                            {
                                                                displayed_room.rooms.map((room, index_room) => {
                                                                    return (
                                                                        <div className={ "col-md-12 ft-mt5" }> <i className={"icon-053-bed ft-pr5"} />{ t("global.room") + " " + (index_room + 2) + " : " + this.getRoomType(room.group_passenger.travelers_list.length) }</div>
                                                                    )

                                                                })
                                                            }
                                                            </Fragment>
                                                        )
                                                    }
                                                    <br />
                                                    {
                                                        displayed_room.hotel[0].remark_favorite !== null && (
                                                            <div>
                                                                <div className={"comment-title-hotel"} >
                                                                    <svg x="0px" y="0px"
                                                                         width="15px" height="15px" viewBox="0 0 511.626 511.626"
                                                                         >
                                                                    <g>
                                                                        <path className={"to-comment-icon"} d="M477.371,127.44c-22.843-28.074-53.871-50.249-93.076-66.523c-39.204-16.272-82.035-24.41-128.478-24.41
                                                                            c-34.643,0-67.762,4.805-99.357,14.417c-31.595,9.611-58.812,22.602-81.653,38.97c-22.845,16.37-41.018,35.832-54.534,58.385
                                                                            C6.757,170.833,0,194.484,0,219.228c0,28.549,8.61,55.3,25.837,80.234c17.227,24.931,40.778,45.871,70.664,62.811
                                                                            c-2.096,7.611-4.57,14.846-7.426,21.693c-2.855,6.852-5.424,12.474-7.708,16.851c-2.286,4.377-5.376,9.233-9.281,14.562
                                                                            c-3.899,5.328-6.849,9.089-8.848,11.275c-1.997,2.19-5.28,5.812-9.851,10.849c-4.565,5.048-7.517,8.329-8.848,9.855
                                                                            c-0.193,0.089-0.953,0.952-2.285,2.567c-1.331,1.615-1.999,2.423-1.999,2.423l-1.713,2.566c-0.953,1.431-1.381,2.334-1.287,2.707
                                                                            c0.096,0.373-0.094,1.331-0.57,2.851c-0.477,1.526-0.428,2.669,0.142,3.433v0.284c0.765,3.429,2.43,6.187,4.998,8.277
                                                                            c2.568,2.092,5.474,2.95,8.708,2.563c12.375-1.522,23.223-3.606,32.548-6.276c49.87-12.758,93.649-35.782,131.334-69.097
                                                                            c14.272,1.522,28.072,2.286,41.396,2.286c46.442,0,89.271-8.138,128.479-24.417c39.208-16.272,70.233-38.448,93.072-66.517
                                                                            c22.843-28.062,34.263-58.663,34.263-91.781C511.626,186.108,500.207,155.509,477.371,127.44z"/>
                                                                    </g>
                                                                    </svg>
                                                                    <h4 className={"ft-orange col-md-12 ft-mt5 "} >
                                                                        { t("summary.expert_remark") }
                                                                    </h4>
                                                                </div>
                                                                <div className={"step-hotel-description col-md-12 ft-mt5"} >
                                                                    { displayed_room.hotel[0].remark_favorite }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    <div className={ "col-md-12 italic mt15" }>
                                                        <div className={"checkbox-container"} >
                                                            <label className={ "cart-checkbox" }>
                                                                Ce produit est dans le devis
                                                                <input type={ "checkbox" } checked={ displayed_room.is_optional === false ? true : false } onChange={(e) => {
                                                                    let displayed_room_arr_cpy = this.state.displayed_room_arr.slice();
                                                                    for (let i = 0; i < displayed_room_arr_cpy.length; i++) {
                                                                        displayed_room_arr_cpy[i].is_optional = true;
                                                                    }
                                                                    displayed_room_arr_cpy[displayed_room_index].is_optional = !displayed_room_arr_cpy[displayed_room_index].is_optional;

                                                                    let tmp_hotel_cart = hotel_cart.slice();
                                                                    for (let i = 0; i < tmp_hotel_cart.length; i++) {
                                                                        for (let j = 0; j < displayed_room_arr_cpy.length; j++) {
                                                                            if (displayed_room_arr_cpy[j].id === tmp_hotel_cart[i].id) {
                                                                                tmp_hotel_cart[i] = displayed_room_arr_cpy[j];
                                                                            }
                                                                        }
                                                                    }

                                                                    dispatch(SetHotelCart(tmp_hotel_cart));

                                                                    if (component.state.save_bar === false) {
                                                                        component.setState({
                                                                            save_bar: true
                                                                        })
                                                                    }

                                                                    this.setState({
                                                                        displayed_room_arr: displayed_room_arr_cpy
                                                                    });
                                                                 }}/>
                                                                <span className={ "cart-check-mark" }/>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Fragment>
                    )
                }
                <HotelInfoModal index={index} />
                <SummaryPicturesModal index={index} />
            </div>
        );
    }
}

export default SummaryStepHotel;
