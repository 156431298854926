import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { makeStyles } from '@material-ui/core/styles';
import { isBoolean } from 'lodash';

const useStyles = makeStyles(() => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    ftContainer: {
        //marginTop: "32px !important",
        marginBottom: "32px !important"
    },
    ftUserTitle: {
        backgroundColor: "rgba(0, 0, 0, .03) !important",
        borderBottom: "1px solid rgba(0, 0, 0, .125) !important"
    },
    ftUserSubContainer: {
        paddingTop: "16px !important"
    },
    ftUserAppBar: {
        position: "relative !important"
        //backgroundColor: "#3367D6 !important"
    },
    ftBlueText: {
        color: "#3367D6 !important"
    },
    ftMarginTop8: {
        marginTop: 8
    }
}));

const CartDeleteModal = ({ loading, deleteModal, setDeleteModal, callback }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const onTogglemodal = () => {
        if (!loading) {
            setDeleteModal(!deleteModal);
        }
    };

    const onToggleValidate = () => {
        if (!isBoolean(loading)) {
            onTogglemodal();
        }
        callback();
    };

    return (
        <Dialog open={deleteModal} maxWidth={"md"} onClose={onTogglemodal} disableEscapeKeyDown>
            <DialogTitle onClose={onTogglemodal}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography fontWeight="bold">
                        {t('cart-material.delete-product')}
                    </Typography>
                    <IconButton size={"small"} onClick={onTogglemodal}>
                        <Close />
                    </IconButton>
                </Stack>
                <hr className={classes.hr} />
            </DialogTitle>
            <DialogContent className={classes.ftContainer}>
                <Container maxWidth={'md'}>
                    {t("cart-material.delete-confirmation")}
                </Container>
            </DialogContent>
            <DialogActions>
                <Button onClick={onTogglemodal} color="primary" disabled={loading}>
                    {t("global.cancel")}
                </Button>
                <Button onClick={onToggleValidate} color="primary" disabled={loading}>
                    {t("global.validate")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CartDeleteModal;
