import { Template } from "../utils/handlebars/roadbook.handlebars";

export function createSummaryContent(): Parameters<Template>[0]['contents'][0] {
    return {
        type: 'summary',
        anchor: 'summary',
        showInSummary: false,
        title: '<span style="font-weight: bold;">Sommaire</span> de votre carnet'
    };
}
