import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import React, { Fragment, useEffect } from "react";
import PlaneLoader from "../PlaneLoader/PlaneLoader.jsx";
import ReloadCartCheckedItems from "./ReloadCartCheckedItems";
import ReloadCartResults from "./ReloadCartResults";
import GetCookie from "../Common/Functions/GetCookie";
import { SetDataTrip } from "../../Actions/Trip";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    continueButton: {
        color: 'white',
        backgroundColor: "#e6592f"
    }
});

const ReloadCartModalNew = ({ component }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const update_type = useSelector(state => state.cart.update_type);
    const update_infos = useSelector(state => state.cart.update_infos);
    const update_error = useSelector(state => state.cart.update_error);
    const update_loading = useSelector(state => state.cart.update_loading);
    const has_checked_items = useSelector(state => state.cart.has_checked_items);
    const onClose = () => {
        dispatch({ type: 'RECALC_RESET' });
    };
    const onValidateRecalc = () => {
        const { headers } = CheckBeforeRequest();
        dispatch({
            type: "RECALC_SET_TYPE",
            payload: "DYNAMIC"
        });
        $.ajax({
            method: "PATCH",
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/update_version/?type=dynamic`,
            headers: headers,
            data: JSON.stringify(update_infos),
            success: function (data) {
                $.ajax({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
                    headers: headers,
                    success: function (data) {
                        dispatch(SetDataTrip(data));
                    }
                });
                dispatch({ type: "RECALC_SET_INFOS", payload: data });
            },
            error: function (error) {
                console.log(error);
                dispatch({ type: "RECALC_SET_ERROR" });
            }
        });
    };
    useEffect(() => {
        if (update_type === "CHECK" && !has_checked_items && !update_loading) {
            onValidateRecalc();
        }
    }, [update_type, has_checked_items, update_loading]);
    return (
        <Dialog fullScreen={ fullScreen } PaperProps={{ style: { borderRadius: 16 } }} open={ update_type !== null } disableBackdropClick={ true } maxWidth={ "lg" } onClose={ onClose }>
            {
                !update_loading && (
                    <DialogTitle>
                        { update_type === "CHECK" && has_checked_items ? t("itinerary.cart_pre_reloaded_info") : t("itinerary.cart_reloaded_info") }
                    </DialogTitle>
                )
            }
            <DialogContent>
                {
                    update_error ? (
                        <Fragment>
                            { t("itinerary.error_from_api") }
                            <br/>
                            <Grid container justify={"center"}>
                                <Grid item>
                                    <Button className={ classes.continueButton } variant="contained" onClick={ onClose }>{ t('global.continue') }</Button>
                                </Grid>
                            </Grid>
                            <br/>
                        </Fragment>
                    ) :
                        update_loading ? (
                            <div><PlaneLoader/><div className={ "ft-light-green text-center" }>{ t("itinerary.loading_new_infos") }</div></div>
                        ) : update_type === "CHECK" && has_checked_items ? (
                            <ReloadCartCheckedItems onValidateRecalc={ onValidateRecalc }/>
                        ) : (
                            <ReloadCartResults component={component}/>
                        )
                }
            </DialogContent>
        </Dialog>
    );
};

export default ReloadCartModalNew;
