import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Person from "@material-ui/icons/Person";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
    inline: {
        display: "inline-block"
    },
    groupModal: {
        width: "100%",
        padding: theme.spacing(2),
        zIndex: 1
    },
    floatRight: {
        float: "right"
    },
    marginTop: {
        marginTop: theme.spacing(2)
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white",
        borderRadius: 24
    },
    adornment: {
        position: "absolute",
        right: -45
    },
    formControl: {
        width: "calc(100% - 28px)"
    },
    nbRoomButton: {
        padding: 0,
        margin: 16
    },
    matchingPadding: {
        padding: 15
    }
}));

const AccommodationCardGroups = ({group_modal_open, current_groups, current_travelers, toggleGroupModal, setNewGroups}) => {
    console.log("accommodation groups render");

    const classes = useStyles();
    const { t } = useTranslation();
    const travelers = useSelector(state => state.trip.travelers);
    const [groups, setGroups] = useState($.extend(true, [], current_groups));
    const end_date = useSelector((store) => store.trip.end_date);
    let travelers_without_group = [];
    if (groups !== null) {
        travelers.map((traveler) => {
            let has_group = false;
            for (let i = 0; i < groups.length; i++) {
                for (let j = 0; j < groups[i].travelers_list.length; j++) {
                    if (groups[i].travelers_list[j].id === traveler.id) {
                        has_group = true;
                        break;
                    }
                }
            }
            if (!has_group) {
                travelers_without_group.push(traveler);
            }
        });
    }
    return (
        <div style={{ position: "relative"}}>
            <Button className={ classes.matchingPadding } variant={ "outlined" } color={ "default" } fullWidth={ true } startIcon={<Person/>} onClick={ toggleGroupModal }>{ current_groups.length } { (current_groups.length > 1 ? t("accommodation.rooms") : t("accommodation.room")) }, { current_travelers } { (current_travelers > 1 ? t("accommodation.travelers") : t("accommodation.traveler")) }</Button>
            {
                group_modal_open && (
                    <Paper className={ classes.groupModal }>
                        <Typography variant={ "h6" }>{ t("accommodation.travelers") }</Typography>
                        <Grid container justify={ "space-between" } alignItems={ "center" }>
                            <Grid item>
                                <Typography>{t('shared.room-nb')} : </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton className={ `${classes.inline} ${classes.nbRoomButton}` } disabled={ groups.length <= 1 } onClick={() => {
                                    if (groups.length > 1) {
                                        let groups_cpy = [];
                                        for (let i = 0; i < groups.length - 1; i++) {
                                            groups_cpy.push({
                                                travelers: [],
                                                travelers_list: []
                                            });
                                            setGroups(groups_cpy);
                                        }
                                    }
                                }}>
                                    <RemoveCircleOutline/>
                                </IconButton>
                                <Typography className={ classes.inline }>{ groups.length }</Typography>
                                <IconButton className={ `${classes.inline} ${classes.nbRoomButton}` } disabled={ groups.length >= travelers.length } onClick={() => {
                                    if (groups.length < travelers.length) {
                                        let groups_cpy = groups.slice();
                                        groups_cpy.push({
                                            travelers: [],
                                            travelers_list: []
                                        });
                                        setGroups(groups_cpy);
                                    }
                                }}>
                                    <AddCircleOutline/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <br/>
                        {
                            travelers_without_group.length > 0 && (
                                <Fragment>
                                    <Typography>Il y a { travelers_without_group.length } voyageur(s) non affecté : </Typography>
                                    {
                                        travelers_without_group.map((traveler, index_traveler) => {
                                            let label_name = "";
                                            let default_name = false;
                                            let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
                                            if (traveler.first_name === t('global.first_name') && traveler.last_name === t('global.last_name')) {
                                                default_name = true;
                                            }
                                            if (default_name) {
                                                if (age >= 18) {
                                                    label_name = `${t("trip.traveler")} ${t("flight_search.adult")}`;
                                                }
                                                else {
                                                    label_name = `${t("trip.traveler")} ${t("flight_search.child")} (${age})`;
                                                }
                                            } else {
                                                label_name = `${traveler.first_name} ${traveler.last_name} ${age < 18 ? `(${age})` : ''}`
                                            }
                                            return (
                                                <Typography key={ index_traveler }>{ `${label_name}` }</Typography>
                                            )
                                        })
                                    }
                                    <br/>
                                </Fragment>
                            )
                        }
                        {
                            groups.map((group, index_group) => {
                                return (
                                    <Fragment key={ index_group }>
                                        <Typography>{ t("accommodation.room") } { index_group + 1 } :</Typography>
                                        {
                                            travelers.map((traveler, index_traveler) => {
                                                let label_name = "";
                                                let default_name = false;
                                                let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
                                                if (traveler.first_name === t('global.first_name') && traveler.last_name === t('global.last_name')) {
                                                    default_name = true;
                                                }
                                                if (default_name) {
                                                    if (age >= 18) {
                                                        label_name = `${t("trip.traveler")} ${t("flight_search.adult")}`;
                                                    }
                                                    else {
                                                        label_name = `${t("trip.traveler")} ${t("flight_search.child")} (${age})`;
                                                    }
                                                } else {
                                                    label_name = `${traveler.first_name} ${traveler.last_name} ${age < 18 ? `(${age})` : ''}`
                                                }
                                                return (
                                                    <div key={ index_traveler }>
                                                        <FormControlLabel control={ <Checkbox checked={ group.travelers.includes(traveler.id) } onChange={(e) => {
                                                            let groups_cpy = groups.slice();
                                                            if (e.target.checked) {
                                                                for (let i = 0; i < groups_cpy.length; i++) {
                                                                    const index = groups_cpy[i].travelers.indexOf(traveler.id);
                                                                    if (index > -1) {
                                                                        groups_cpy[i] = Object.assign({}, groups_cpy[i]);
                                                                        groups_cpy[i].travelers.splice(index, 1);
                                                                        groups_cpy[i].travelers_list.splice(index, 1);
                                                                    }
                                                                }
                                                                groups_cpy[index_group] = Object.assign({}, groups[index_group]);
                                                                groups_cpy[index_group].travelers.push(traveler.id);
                                                                groups_cpy[index_group].travelers_list.push(traveler);
                                                            } else {
                                                                const index = groups_cpy[index_group].travelers.indexOf(traveler.id);
                                                                if (index > -1) {
                                                                    groups_cpy[index_group] = Object.assign({}, groups[index_group]);
                                                                    groups_cpy[index_group].travelers.splice(index, 1);
                                                                    groups_cpy[index_group].travelers_list.splice(index, 1);
                                                                }
                                                            }
                                                            setGroups(groups_cpy);
                                                        }}/> } label={ `${label_name}` }/>
                                                    </div>
                                                )
                                            })
                                        }
                                        <br/>
                                    </Fragment>
                                );
                            })
                        }
                        <Button variant={ "contained" } className={ `${classes.orangeButton} ${classes.marginTop}` } fullWidth={ true } onClick={() => { setNewGroups(groups, setGroups); }}>{ t("global.finish") }</Button>
                    </Paper>
                )
            }
        </div>
    )
};

export default React.memo(AccommodationCardGroups);