export const ADD_PAYMENT_REMARK = "ADD_PAYMENT_REMARK"
export const CHANGE_PAY_REMARK_DESCRIPTION = "CHANGE_PAY_REMARK_DESCRIPTION";
export const CHANGE_PAY_REMARK_IDENTIFIER = "CHANGE_PAY_REMARK_IDENTIFIER";
export const EDIT_PAY_REMARK_DESCRIPTION = "EDIT_PAY_REMARK_DESCRIPTION";
export const CHANGE_PAY_REMARK_TYPE = "CHANGE_PAY_REMARK_TYPE";
export const EDIT_PAY_REMARK_TYPE = "EDIT_PAY_REMARK_TYPE";
export const CHANGE_PAY_REMARK_VALUE = "CHANGE_PAY_REMARK_VALUE";
export const EDIT_PAY_REMARK_VALUE = "EDIT_PAY_REMARK_VALUE";
export const EMPTY_PAYMENT_REMARKS = "EMPTY_PAYMENT_REMARKS";
export const REMOVE_PNR_REMARK = "REMOVE_PNR_REMARK";
export const SET_FEES_REMARK = "SET_FEES_REMARK";
export const SET_OPEN_GIR_REMINDER = "SET_GIR_REMINDER"
export const SET_PVT_REMARK = "SET_PVT_REMARK";
export const SET_RESERVATION_MODE = "SET_RESERVATION_MODE";
export const SET_PAY_DATE = "SET_PAY_DATE";
export const EDIT_PAY_DATE = "EDIT_PAY_DATE";
export const SET_PAYMENT_DONE = "SET_PAYMENT_DONE";
export const SET_CANCEL_EDIT = "SET_CANCEL_EDIT";
export const SET_VALIDATE_EDIT = "SET_VALIDATE_EDIT";
export const SET_ADVANCE_PAYMENT = "SET_ADVANCE_PAYMENT";
