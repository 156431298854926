import React from "react";
import {
    List,
    ListItem,
    Stack
} from "@mui/material";
import { CartConstructionItineraryContentDraggableStep } from "./CartConstructionItineraryContentDraggableStep";
import { CartConstructionItineraryContentStepsListItem } from "./CartConstructionItineraryContentStepsListItem";
import { CartConstructionItineraryContentCopyModalStepsListDivider } from "./CartConstructionItineraryContentCopyModalStepsListDivider";
import { CartConstructionItineraryContentCopyModalStepsListAggregator } from "./CartConstructionItineraryContentCopyModalStepsListAggregator";
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";

type Props = {
    version: number,
    sourceVersion: number,
    targetVersion: number | null,
    steps: {[version: number]: ItineraryContentStep[]}
    inputs: {[version: number]: ItineraryContentStep[]},
    onChangeInputs: React.Dispatch<React.SetStateAction<{
        [version: number]: ItineraryContentStep[];
    }>>
}

export function CartConstructionItineraryContentDragndropStepsList(props: Props): JSX.Element {
    const onChangeInput = (
        index: number,
        input: ItineraryContentStep
    ) => {
        props.onChangeInputs((state) => {
            if (props.targetVersion) {
                return {
                    ...state,
                    [props.targetVersion]: state[props.targetVersion]?.map((item, itemIndex) => {
                        if (itemIndex === index) {
                            if (item.mode === 'by-day') {
                                return {
                                    ...input,
                                    destinations: item.content.destinations,
                                    day: item.content.day
                                };
                            }
                            return {
                                ...input,
                                destination: item.content.destination
                            };
                        }
                        return item;
                    }) ?? []
                };
            }
            return state;
        });
    };

    return (
        <List
            component="div"
            dense
        >
            {
                props.inputs[props.version]?.map((input, index, array) => {
                    return (
                        <React.Fragment key={index}>
                            <CartConstructionItineraryContentDraggableStep
                                index={index}
                                type={props.version === props.sourceVersion ? 'source' : 'target'}
                                input={input}
                                onChangeInput={onChangeInput}
                            >
                                <CartConstructionItineraryContentStepsListItem
                                    index={index}
                                    input={input}
                                />
                            </CartConstructionItineraryContentDraggableStep>
                            <ListItem>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    spacing={1}
                                    sx={{ width: '100%' }}
                                >
                                    <CartConstructionItineraryContentCopyModalStepsListDivider
                                        version={props.version}
                                        input={input}
                                        onChangeInputs={props.onChangeInputs}
                                    />
                                    {
                                        array[index + 1] &&
                                        <CartConstructionItineraryContentCopyModalStepsListAggregator
                                            version={props.version}
                                            index={index}
                                            input={input}
                                            inputs={array}
                                            onChangeInputs={props.onChangeInputs}
                                        />
                                    }
                                </Stack>
                            </ListItem>
                        </React.Fragment>
                    );
                })
            }
        </List>
    );
}
