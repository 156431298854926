import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import axios from "axios";

export default (client, dispatch, role, t, location) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        let url = `${API_HREF}client/${client}/user/`;
        if (role === "is_bo_user") {
            url += "?is_bo_user=true";
        } else if (role === "is_seller_user") {
            url += "?is_seller_user=true";
        }
        url += "&limit=1000"
        axios({
            method: "GET",
            headers: headers,
            url: url
        }).then(function (response) {
            let data = response.data.results.sort((a, b) => {
                let fa = a.first_name.toLowerCase();
                let fb = b.first_name.toLowerCase();
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                if (fa === fb) {
                    let fc = a.last_name.toLowerCase();
                    let fd = b.last_name.toLowerCase();
                    if (fc < fd) {
                        return -1;
                    }
                    if (fc > fd) {
                        return 1;
                    }
                }
            });
            data.forEach(element => {
                element.label = `${element.first_name} ${element.last_name}`
            });
            if (location === "tripList") {
                data.unshift({id: null, label: role === "is_bo_user" ? t('menu.user_management.without_user_bo') : t('menu.user_management.without_seller')});
            } else {
                if (role === "is_bo_user") {
                    data.unshift({id: null, label: t('menu.user_management.not_attributed')});
                }
            }
            console.log('data user:', data);
            if (role === "is_bo_user") {
                dispatch({
                    type: "MENU_SET_USER_LIST",
                    payload: {
                        userList: data
                    }
                });
            } else if (role === "is_seller_user") {
                dispatch({
                    type: "MENU_SET_USER_AGENCE_LIST",
                    payload: {
                        userAgenceList: data
                    }
                });
            }
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }   
}
