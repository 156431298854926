export function SetFetchedData(flight_list, airports, airlines, alliances, min_price, max_price, stop_over, currency, currency_symbol, distance_unit, trace_id, stopover_airport, filter_time, max_duration, min_duration, stations, luggage, stopover_duration, provider) {
    return {
        type: "FLIGHT_SET_FETCHED_DATA",
        payload: {
            flight_list: flight_list,
            airports: airports,
            airlines: airlines,
            alliances: alliances,
            min_price: min_price,
            max_price: max_price,
            stop_over: stop_over,
            currency: currency,
            currency_symbol: currency_symbol,
            distance_unit: distance_unit,
            trace_id: trace_id,
            stopover_airport: stopover_airport,
            filter_time: filter_time,
            max_duration: max_duration,
            min_duration: min_duration,
            stations: stations,
            luggage: luggage,
            stopover_duration: stopover_duration,
            provider: provider
        }
    };
}
export function SetManualInfo(airlines, airports, flight_info, trace_id, last_ticketing, stations) {
    return {
        type: "FLIGHT_SET_MANUAL_INFO",
        payload: {
            flight_list: flight_info,
            airlines: airlines,
            airports: airports,
            trace_id: trace_id,
            last_ticketing: last_ticketing,
            stations: stations
        }
    }
}
export function SetFetchedOptions(flight_index, segment_index, options, more_option, is_empty) {
    return {
        type: "FLIGHT_SET_FETCHED_OPTIONS",
        payload: {
            flight_index: flight_index,
            segment_index: segment_index,
            options: options,
            more_option: more_option,
            is_empty: is_empty
        }
    };
}
export function SetOptionsError(error, flight_index) {
    return {
        type: "FLIGHT_SET_OPTIONS_ERROR",
        payload: {
            error: error,
            flight_index: flight_index
        }
    }
}
export function SetGetOptions(flight_index, options) {
    return {
        type: "FLIGHT_SET_GET_OPTIONS",
        payload: {
            flight_index: flight_index,
            options: options
        }
    };
}
export function SetEmptyOptions(flight_index, segment_index, more_option) {
    return {
        type: "FLIGHT_SET_EMPTY_OPTIONS",
        payload: {
            flight_index: flight_index,
            segment_index: segment_index,
            more_option: more_option
        }
    };
}
export function SetFetchError(fetch_error) {
    return {
        type: "FLIGHT_SET_FETCH_ERROR",
        payload: {
            fetch_error: fetch_error
        }
    };
}
export function SetFetchErrorOptions(flight_index, segment_index, fetch_error) {
    return {
        type: "FLIGHT_SET_FETCH_ERROR_OPTIONS",
        payload: {
            fetch_error: fetch_error,
            flight_index: flight_index,
            segment_index: segment_index
        }
    };
}
export function ResetFetchError() {
    return {
        type: "FLIGHT_RESET_FETCH_ERROR"
    };
}
export function ResetFetchErrorOptions(flight_index, segment_index) {
    return {
        type: "FLIGHT_RESET_FETCH_ERROR_OPTIONS",
        payload: {
            flight_index: flight_index,
            segment_index: segment_index
        }
    };
}
export function ClearFilter(filter_time) {
    return {
        type: "FLIGHT_CLEAR_FILTER",
        payload: {
            filter_time: filter_time
        }
    };
}
export function SetFetchedDataStatus(status) {
    return {
        type: "FLIGHT_FETCHED_STATUS",
        payload: {
            status: status
        }
    };
}
export function SetFetchedMatrixStatus(status) {
    return {
        type: "FLIGHT_MATRIX_STATUS",
        payload: {
            status: status
        }
    };
}
export function ClearFlightList() {
    return {
        type: "FLIGHT_CLEAR_FLIGHT_LIST"
    };
}
export function SetState() {
    return {
        type: "FLIGHT_SET_STATE"
    };
}
export function ToggleStopOverFilter() {
    return {
        type: "FLIGHT_TOGGLE_STOP_OVER_FILTER"
    };
}

export function InitStopOverActive(nb_journey) {
    return {
        type: "FLIGHT_INIT_STOP_OVER_ACTIVE",
        payload: {
            nb_journey: nb_journey
        }
    }
}

export function ToggleShowAllStopOver() {
    return {
        type: "FLIGHT_TOGGLE_SHOW_ALL_STOP_OVER",
        payload: {}
    }
}
export function ToggleActiveStopOverFilter(segment_index, key) {
    return {
        type: "FLIGHT_TOGGLE_ACTIVE_STOP_OVER_FILTER",
        payload: {
            segment_index: segment_index,
            key: key
        }
    };
}
export function SetPriceFilter(filter_min_price, filter_max_price) {
    return {
        type: "FLIGHT_SET_PRICE_FILTER",
        payload: {
            filter_min_price: filter_min_price,
            filter_max_price: filter_max_price
        }
    };
}
export function SetPriceSlider(slider_min_price, slider_max_price) {
    return {
        type: "FLIGHT_SET_PRICE_SLIDER",
        payload: {
            slider_min_price: slider_min_price,
            slider_max_price: slider_max_price
        }
    };
}
export function SetMaxDurationFilter(filter_min_duration, filter_max_duration) {
    return {
        type: "FLIGHT_SET_MAX_DURATION_FILTER",
        payload: {
            filter_min_duration: filter_min_duration,
            filter_max_duration: filter_max_duration
        }
    };
}
export function SetMaxDurationSlider(slider_min_duration, slider_max_duration) {
    return {
        type: "FLIGHT_SET_MAX_DURATION_SLIDER",
        payload: {
            slider_min_duration: slider_min_duration,
            slider_max_duration: slider_max_duration
        }
    };
}
export function TogglePriceFilter() {
    return {
        type: "FLIGHT_TOGGLE_PRICE_FILTER"
    };
}
export function ToggleAllianceFilter() {
    return {
        type: "FLIGHT_TOGGLE_ALLIANCE_FILTER"
    };
}
export function ToggleAlliance(index) {
    return {
        type: "FLIGHT_TOGGLE_ALLIANCE",
        payload: {
            index: index
        }
    };
}
export function ToggleAirportFilter() {
    return {
        type: "FLIGHT_TOGGLE_AIRPORT_FILTER"
    };
}
export function ToggleStopOverAirportFilter() {
    return {
        type: "FLIGHT_TOGGLE_STOPOVER_AIRPORT_FILTER"
    };
}
export function ToggleStopOverDurationFilter() {
    return {
        type: "FLIGHT_TOGGLE_STOPOVER_DURATION_FILTER"
    };
}
export function ShowAllAirports() {
    return {
        type: "FLIGHT_SHOW_ALL_AIRPORTS",
        payload: {}
    }
}
export function ToggleAirport(index) {
    return {
        type: "FLIGHT_TOGGLE_AIRPORT",
        payload: {
            index: index
        }
    };
}
export function ShowAllStopoverAirports() {
    return {
        type: "FLIGHT_SHOW_ALL_STOPOVER_AIRPORT",
        payload: {}
    }
}
export function ToggleStopoverAirport(airport, index_journey,) {
    return {
        type: "FLIGHT_TOGGLE_STOPOVER_AIRPORT",
        payload: {
            index_journey: index_journey,
            airport: airport
        }
    }
}
export function ShowAllStations() {
    return {
        type: "FLIGHT_SHOW_ALL_STATIONS",
        payload: {}
    }
}
export function ToggleStation(index) {
    return {
        type: "FLIGHT_TOGGLE_STATION",
        payload: {
            index: index
        }
    };
}
export function ToggleallStopoverDuration() {
    return {
        type: "FLIGHT_TOGGLE_ALL_STOPOVER_DURATION",
        payload: {}
    }
}
export function CreateStopoverDuration(nb_journeys) {
    return {
        type: "FLIGHT_CREATE_STOPOVER_DURATION",
        payload: {
            nb_journeys: nb_journeys
        }
    }
}
export function ToggleStopoverDuration(index_journey, key) {
    return {
        type: "FLIGHT_TOGGLE_STOPOVER_DURATION",
        payload: {
            index_journey: index_journey,
            key: key
        }
    }
}
export function ToggleCabinFilter() {
    return {
        type: "FLIGHT_TOGGLE_CABIN_FILTER"
    };
}
export function ToggleFlightHoursFilter() {
    return {
        type: "FLIGHT_TOGGLE_FLIGHT_HOURS_FILTER"
    };
}
export function CreateFlightHours(nb_journeys) {
    return {
        type: "FLIGHT_CREATE_FLIGHT_HOURS",
        payload: {
            nb_journeys: nb_journeys
        }
    }
}
export function SetFlightHours(index, type, start, end) {
    return {
        type: "FLIGHT_SET_FLIGHT_HOURS",
        payload: {
            index: index,
            start: start,
            end: end,
            type: type
        }
    };
}
export function SetFlightHoursSlide(index, type, start, end) {
    return {
        type: "FLIGHT_SET_FLIGHT_HOURS_SLIDE",
        payload: {
            index: index,
            start: start,
            end: end,
            type: type
        }
    };
}
export function TogglePriceTypeFilter() {
    return {
        type: "FLIGHT_TOGGLE_PRICE_TYPE_FILTER"
    };
}
export function ToggleRefundable() {
    return {
        type: "FLIGHT_TOGGLE_REFUNDABLE"
    };

}
export function ToggleNotRefundable() {
    return {
        type: "FLIGHT_TOGGLE_NOT_REFUNDABLE"
    };
}
export function ToggleCheckedLuggageFilter() {
    return {
        type: "FLIGHT_TOGGLE_CHECKED_LUGGAGE_FILTER"
    };
}
export function ToggleCheckedLuggageIncluded() {
    return {
        type: "FLIGHT_TOGGLE_CHECKED_LUGGAGE_INCLUDED"
    };
}
export function ToggleAirlineFilter() {
    return {
        type: "FLIGHT_TOGGLE_AIRLINE_FILTER"
    };
}
export function ToggleProvidersFilter() {
    return {
        type: "FLIGHT_TOGGLE_PROVIDERS_FILTER"
    };
}
export function ToggleChangeAirportFilter() {
    return {
        type: "FLIGHT_TOGGLE_CHANGE_AIRPORT_FILTER"
    };
}
export function ToggleChangeAirport() {
    return {
        type: "FLIGHT_TOGGLE_CHANGE_AIRPORT"
    };
}
export function ShowAllAirlines() {
    return {
        type: "FLIGHT_SHOW_ALL_AIRLINES",
        payload: {}
    }
}
export function ToggleAirline(index) {
    return {
        type: "FLIGHT_TOGGLE_AIRLINE",
        payload: {
            index: index
        }
    };
}
export function ToggleProvider(index) {
    return {
        type: "FLIGHT_TOGGLE_PROVIDER",
        payload: {
            index: index
        }
    };
}
export function ToggleDetail(flight_index, outbound_index, detail) {
    return {
        type: "FLIGHT_TOGGLE_DETAIL",
        payload: {
            flight_index: flight_index,
            outbound_index: outbound_index,
            detail: !detail
        }
    };
}
export function SetTab(tab, flight_index, segment_index) {
    return {
        type: "FLIGHT_SET_TAB",
        payload: {
            flight_index: flight_index,
            segment_index: segment_index,
            tab: tab
        }
    };
}
export function ToggleDayNightFilter() {
    return {
        type: "FLIGHT_TOGGLE_DAY_NIGHT_FILTER",
    };
}
export function ToggleDayFlight() {
    return {
        type: "FLIGHT_TOGGLE_DAY_FLIGHT",
    };
}
export function ToggleNightFlight() {
    return {
        type: "FLIGHT_TOGGLE_NIGHT_FLIGHT",
    };
}
export function SelectFlight(flight_id) {
    return {
        type: "FLIGHT_SELECTED_FLIGHT",
        payload: {
            flight_id: flight_id
        }
    };
}
export function ResetSelectedFlight() {
    return {
        type: "FLIGHT_RESET_SELECTED_FLIGHT"
    };
}
export function ToggleAddToCart(toggle) {
    return {
        type: "FLIGHT_TOGGLE_ADD_TO_CART",
        payload: {
            add_to_cart: toggle
        }
    };
}
export function SetPriceVariation(price_variation) {
    return {
        type: "FLIGHT_SET_PRICE_VARIATION",
        payload: {
            price_variation: price_variation
        }
    };
}
export function SetCartError(error) {
    return {
        type: "FLIGHT_SET_CART_ERROR",
        payload: {
            error: error
        }
    };
}
export function SetMatrixData(flight, outbound_date, return_date, length, lowest_price, matrix_data) {
    return {
        type: "FLIGHT_SET_MATRIX_DATA",
        payload: {
            matrix_flight: flight,
            matrix_outbound_date: outbound_date,
            matrix_return_date: return_date,
            matrix_length: length,
            lowest_price: lowest_price,
            matrix_data: matrix_data
        }
    };
}
export function SetMatrixSelectedDay(index_start, index_return) {
    return {
        type: "FLIGHT_MATRIX_SELECTED_DAY",
        payload: {
            index_start: index_start,
            index_return: index_return
        }
    };
}
export function AddHoverToMatrix(index_start, index_return) {
    return {
        type: "FLIGHT_ADD_MATRIX_HOVER",
        payload: {
            index_start: index_start,
            index_return: index_return
        }
    };
}
export function ResetHover() {
    return {
        type: "FLIGHT_RESET_MATRIX_HOVER"
    };
}
export function SetSelectDay(index_start, index_return) {
    return {
        type: "FLIGHT_SET_SELECTED_DAY",
        payload: {
            index_start: index_start,
            index_return: index_return
        }
    };
}
export function SetSelectedOption(flight_index, selected_option, key_pricing) {
    return {
        type: "FLIGHT_SET_SELECTED_OPTION",
        payload: {
            flight_index: flight_index,
            selected_option: selected_option,
            key_pricing: key_pricing
        }
    };
}
export function ToggleSeatModal(is_open, modal_info, error) {
    return {
        type: "FLIGHT_TOGGLE_SEAT_MODAL",
        payload: {
            is_open: is_open,
            modal_info: modal_info,
            error: error
        }
    };
}
export function SetSeatData(modal_info) {
    return {
        type: "FLIGHT_SET_SEAT_DATA",
        payload: {
            modal_info: modal_info
        }
    };
}
export function SetErrorSeatData(error) {
    return {
        type: "FLIGHT_SET_ERROR_SEAT_DATA",
        payload: {
            error: error
        }
    };
}
export function SetSeatTab(tab) {
    return {
        type: "FLIGHT_SET_SEAT_TAB",
        payload: {
            tab: tab
        }
    };
}
export function SelectedSeat(seat, price) {
    return {
        type: "FLIGHT_SELECTED_SEAT",
        payload: {
            seat: seat,
            price: price
        }
    };
}
export function SetUpperCabin(flag, tab) {
    return {
        type: "FLIGHT_SET_UPPER_CABIN",
        payload: {
            flag: flag,
            tab: tab
        }
    };
}
export function AddPassengerSeat(flight_index, traveler, leg_index, outbound_index, seat, price) {
    return {
        type: "FLIGHT_ADD_PASSENGER_SEAT",
        payload: {
            flight_index: flight_index,
            outbound_index: outbound_index,
            leg_index: leg_index,
            traveler: traveler,
            seat: seat,
            price: price
        }
    };
}
export function ShowPopOver(show, tab, row_index, seat_index) {
    return {
        type: "FLIGHT_SHOW_POPOVER",
        payload: {
            show: show,
            tab: tab,
            row_index: row_index,
            seat_index: seat_index
        }
    };
}
export function ResetSeat() {
    return {
        type: "FLIGHT_RESET_SEAT"
    };
}
export function SetConfirmation(flag, cancel) {
    return {
        type: "FLIGHT_SET_CONFIRMATION",
        payload: {
            flag: flag,
            cancel: cancel
        }
    };
}
export function SetSummary(flag) {
    return {
        type: "FLIGHT_SET_SEAT_SUMMARY",
        payload: {
            flag: flag
        }
    };
}
export function SelectQuantity(flight_index, segment_index, key_optional_service, quantity, traveler) {
    return {
        type: "FLIGHT_SELECT_QUANTITY",
        payload: {
            flight_index: flight_index,
            segment_index: segment_index,
            key_optional_service: key_optional_service,
            quantity: quantity,
            traveler: traveler
        }
    };
}
export function AddBaggageOption(key, options, flight_index, outbound_index, segment_key, traveler, type) {
    let tmp_option = null;
    options.map((option) => {
        if (option.key_optional_service === key) {
            tmp_option = option;
        }
    });
    return {
        type: "FLIGHT_ADD_BAGGAGE_OPTION",
        payload: {
            baggage: tmp_option,
            flight_index: flight_index,
            outbound_index: outbound_index,
            segment_key: segment_key,
            traveler: traveler,
            type: type
        }
    };
}
export function RemoveBaggageOption(baggage_index, flight_index, outbound_index, segment_key, traveler) {
    return {
        type: "FLIGHT_REMOVE_BAGGAGE_OPTION",
        payload: {
            baggage_index: baggage_index,
            flight_index: flight_index,
            outbound_index: outbound_index,
            segment_key: segment_key,
            traveler: traveler
        }
    };
}
export function RemovePassengerBaggage(flight_index, outbound_index, segment_key, traveler) {
    return {
        type: "FLIGHT_REMOVE_PASSENGER_BAGGAGE",
        payload: {
            flight_index: flight_index,
            outbound_index: outbound_index,
            segment_key: segment_key,
            traveler: traveler
        }
    }
}
export function UpdateFlightList(flight_list, sort_value) {
    return {
        type: "FLIGHT_UPDATE_FLIGHT_LIST",
        payload: {
            flight_list: flight_list,
            sort_value: sort_value
        }
    };
}
export function DisplayList(list) {
    return {
        type: "FLIGHT_DISPLAY_LIST",
        payload: {
            list: list
        }
    };
}
export function ToggleDetailPrice(flight_index, detail) {
    return {
        type: "FLIGHT_TOGGLE_DETAIL_PRICE",
        payload: {
            flight_index: flight_index,
            detail: detail
        }
    };
}
export function setBackgroundImg(img) {
    return {
        type: "FLIGHT_SET_BACKGROUND",
        payload: {
            background_img: img
        }
    }
}
export function ToggleSynchronizedStopover() {
    return {
        type: "FLIGHT_TOGGLE_SYNCHRONIZED_STOPOVER"
    }
}
export function ToggleRefundCondition() {
    return {
        type: "FLIGHT_TOGGLE_REFUND_CONDITION"
    }
}
export function DateUpdated(date_updated) {
    return {
        type: "FLIGHT_DATE_UPDATED",
        payload: {
            date_updated: date_updated
        }
    }
}
export function TogglePriceRateFilter() {
    return {
        type: "FLIGHT_SHOW_PRICE_RATE"
    }
}
export function TogglePrivatePrice() {
    return {
        type: "FLIGHT_TOGGLE_PRIVATE_PRICE"
    }
}
export function TogglePublicPrice() {
    return {
        type: "FLIGHT_TOGGLE_PUBLIC_PRICE"
    }
}
export function SetCheckedLuggage(key) {
    return {
        type: "FLIGHT_SET_CHECKED_LUGGAGE",
        payload: {
            key: key
        }
    }
}
export function SetOptionLoaded(option_loaded){
    return {
        type: "FLIGHT_SET_OPTION_LOADED",
        payload: {
            option_loaded: option_loaded
        }
    }
}
export function SetSeatsLoaded(seats_loaded){
    return {
        type: "FLIGHT_SET_SEATS_LOADED",
        payload: {
            seats_loaded: seats_loaded
        }
    }
}

export function UpdateFilters(
    airlines,
    airports,
    alliances,
    checked_luggage,
    is_day_flight,
    is_night_flight,
    private_price,
    providers,
    public_price,
    show_all_stop_over_duration,
    stations,
    stop_over_active,
    stop_over_airport,
    flight_source_gds,
    flight_source_ndc){
        return {
            type: "FLIGHT_UPDATE_FILTERS",
            payload: {
                airlines: airlines,
                airports: airports,
                alliances: alliances,
                checked_luggage: checked_luggage,
                is_day_flight: is_day_flight,
                is_night_flight: is_night_flight,
                private_price: private_price,
                providers: providers,
                public_price: public_price,
                show_all_stop_over_duration: show_all_stop_over_duration,
                stations: stations,
                stop_over_active: stop_over_active,
                stop_over_airport: stop_over_airport,
                flight_source_gds: flight_source_gds,
                flight_source_ndc: flight_source_ndc
            }
        }
}

export function SetShowMoreResults(show_more_results){
    return {
        type: "FLIGHT_SET_SHOW_MORE_RESULTS",
        payload: show_more_results
    }
}

export function AddFetchData (
    alliances,
    airlines,
    airports,
    flight_list,
    max_duration,
    max_price,
    min_duration,
    min_price,
    stations,
    stop_over,
    stop_over_airport,
    trace_id
    ){
        return {
            type: "FLIGHT_ADD_FETCH_DATA",
            payload: {
                alliances: alliances,
                airlines: airlines,
                airports: airports,
                flight_list: flight_list,
                max_duration: max_duration,
                max_price: max_price,
                min_duration: min_duration,
                min_price: min_price,
                stations: stations,
                stop_over_airport: stop_over_airport,
                stop_over: stop_over,
                trace_id: trace_id
            }
        }
    }

export function OpenRemoveLuggage(open_remove_luggage){
    return {
        type: "FLIGHT_OPEN_REMOVE_LUGGAGE",
        payload: {
            open_remove_luggage: open_remove_luggage
        }
    }
}

export function CountProvider() {
    return {
        type: "FLIGHT_COUNT_PROVIDER",
        payload: null
    }
}

export function ResetProvider() {
    return {
        type: "FLIGHT_RESET_PROVIDER_COUNT",
        payload: null
    }
}

export function ToggleFlightNDC() {
    return {
        type: "FLIGHT_TOGGLE_FLIGHT_SOURCE_NDC"
    }
}

export function ToggleFlightGDS() {
    return {
        type: "FLIGHT_TOGGLE_FLIGHT_SOURCE_GDS"
    }
}