import moment from "moment";

export default (travelers_list, end_date, t) => {
    let nb_adults = 0;
    let nb_teens = 0;
    let nb_children = 0;
    let nb_babies = 0;

    travelers_list.map((traveler) => {
        let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
        if (age > 12 && age < 18) {
            nb_teens += 1;
        } else if (age > 2 && age <= 12) {
            nb_children += 1;
        } else if (age <= 2) {
            nb_babies += 1;
        } else if (age >= 18) {
            nb_adults += 1;
        }
    })
    let adults_str = (nb_adults > 0 ? (nb_adults > 1 ? nb_adults + " " + t("flight_search.adults") : nb_adults + " " + t("flight_search.adult")) : "") + (nb_teens > 0 ? ", " : "");
    let teens_str = (nb_teens > 0 ? (nb_teens > 1 ? nb_teens + " " + t("flight_search.ados") : nb_teens + " " + t("flight_search.ado")) : "") + (nb_children > 0 ? ", " : "");
    let children_str = (nb_children > 0 ? (nb_children > 1 ? nb_children + " " + t("flight_search.children") : nb_children + " " + t("flight_search.child")) : "") + (nb_babies > 0 ? ", " : "");
    let babies_str = nb_babies > 0 ? (nb_babies > 1 ? nb_babies + " " + t("flight_search.babies") : nb_babies + " " + t("flight_search.baby")) : ""

    return (adults_str + teens_str + children_str + babies_str);
}