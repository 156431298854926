//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useSnackbar } from "notistack";
import axios from "axios";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { DatePicker, DateRangePicker, LocalizationProvider } from "@material-ui/pickers";
import moment from 'moment';

//Material UI Components
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Table from '@material-ui/core/Table';
import TableHead from "@material-ui/core/TableHead";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Close from "@material-ui/icons/Close";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Edit from '@material-ui/icons/Edit';
import Check from '@material-ui/icons/Check';

import { useCloseDatePicker } from './Functions/useClosePicker.js';

import { addPaymentRemark, changePayRemarkDescription, editPayRemarkDescription, changePayRemarkType, editPayRemarkType, changePayRemarkValue, editPayRemarkValue, emptyPaymentRemarks, removePnrRemark, setFeesRemark, SetPaymentDate, editPaymentDate, setPaymentDone, setAdvancePayment, setCancelEdit, setValidateEdit, setOpenGirReminder, SetPvtRemark, changePayRemarkIdentifier } from "../../../Actions/Payment.js";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    tableDepositWidth: {
        width: "75%",
        paddingBottom: 20,
        marginLeft: "auto",
        marginRight: "auto" 
    },
    borderTableCell: {
        borderBottom: "none"
    },
    textCenter: {
        textAlign: "center"
    },
    textLeft: {
        textAlign: "left"
    },
    paddingTop: {
        paddingTop: 12
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize9: {
        fontSize: 9
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize20: {
        fontSize: 20
    },
    fontSize24: {
        fontSize: 24
    },
    blue: {
        color: "#319FE3"
    },
    purple: {
        color: "#D6057F"
    },
    orange: {
        color: "#EE7203"
    },
    green: {
        color: "#67B92C"
    },
    red: {
        color: "#FF0000"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    tableSpace: {
        paddingBottom: 8
    },
    tableCellSpacer: {
        padding: "8px 0px"
    },
    paymentsSpace: {
        padding: "24px 0px 8px"
    },
    textFieldInput: {
        "fontFamily": "Roboto",
        "fontStyle": "normal",
        "fontWeight": 400,
        "color": '#0000008A',
        "fontSize": 12,
        "&& .mui-jss-MuiSelect-iconOutlined": {
            display: "none"
        }
    },
    textFieldLabel: {
        fontWeight: 900
    },
    validateButton: {
        backgroundColor: "#E6592F",
        color: "white",
        padding: "8px 60px"
    },
    datePickerLegend: {
        "& legend": {
            width: '80%'
        }
    }
}));

const TripListPaymentEditRemarks = ({ remark, remark_index, paymentChoices, totalFees, totalPaid, paidAmount, setEditPayment }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const { enqueueSnackbar } = useSnackbar();

    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;

    const currency_list = useSelector((store) => store.base.currency_list);
    const currency = useSelector(state => state.trip.currency);
    const language = useSelector(state => state.header.tmp_language);
    
    const [openPicker, setOpenPicker] = useState(false);
    const datePickerRef = useRef(null);
    const datePopperRef = useRef(null);

    //useCloseDatePicker(datePickerRef, datePopperRef, openPicker, setOpenPicker);

    const editPaymentType = (event) => {
        dispatch(editPayRemarkType(remark_index, event.target.value));
    };
    const editDescriptionValue = (event) => {
        dispatch(editPayRemarkDescription(remark_index, event.target.value));
    };
    const editIdentifierValue = (event) => {
        dispatch(changePayRemarkIdentifier(event.target.value));
    };
    const editPaymentValue = (event) => {
        if (event.target.value.match(/^[0-9.,]+$/) !== null) {
            let final_value = event.target.value;
            if (final_value.startsWith("0") && final_value !== "0") {
                final_value = final_value.substring(1);
            }

            if (final_value.match(/^\d*(\.\d{0,2})?$/) !== null) {
                //if (parseFloat((parseFloat(totalPaid) + parseFloat(paidAmount)).toFixed(2)) > parseFloat(totalFees)){
                //if (parseFloat(final_value) <= payment.amount){
                //dispatch(editPayRemarkValue(remark_index, final_value));
                //}else{
                //enqueueSnackbar(t("menu.trip_list.payment_excess"), { variant: "warning" });
                //}
                //} else {
                //dispatch(editPayRemarkValue(remark_index, final_value));
                //}
                dispatch(editPayRemarkValue(remark_index, final_value));
            } else {
                enqueueSnackbar(t("menu.trip_list.payment_decimals"), { variant: "warning" });
            }
        } else {
            dispatch(editPayRemarkValue(remark_index, 0));
        }
    };
    const handleOpen = () => {
        setOpenPicker(true);
    };

    const handleClose = () => {
        setOpenPicker(false);
    };
    const handleEditPaymentDate = (newValue) => {
        let formatted_value = moment(newValue);
        dispatch(editPaymentDate(formatted_value, remark_index));
    };
    const saveEdit = () => {
        dispatch(setValidateEdit());
        setEditPayment(null);
    };
    const cancelEdit = () => {
        dispatch(setCancelEdit());
        setEditPayment(null);
    };
    return (
        <TableRow key={remark_index} style={{ position: "relative", height: 100 }}>
            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textLeft} ${classes.tableCellSpacer}`} style={{ width: "18%" }}>
                <FormControl variant="outlined" size="small" style={{ width: "90%" }}>
                    <InputLabel id={`remark-${remark_index}`} className={`${classes.fontWeight900}`}>
                        { t("menu.trip_list.payment_type") }
                    </InputLabel>
                    <Select
                        fullWidth
                        labelId={`remark-${remark_index}`}
                        id={`select-remark-${remark_index}`}
                        value={remark.payment_type}
                        MenuProps={{ disableScrollLock: true }}
                        onChange={(event) => editPaymentType(payment_index, event.target.value)}
                        input={
                            <OutlinedInput
                                id={`input-remark-${remark_index}`}
                                labelWidth={150}
                                autoComplete="off"
                                className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12}`}
                            />
                        }
                    >
                        {
                            Object.keys(paymentChoices).map((payment_code) => {
                                return (
                                    <MenuItem key={payment_code} value={payment_code} className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12}`}>
                                        { paymentChoices[payment_code] }
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
            </TableCell>
            {/*{
                quotation_code === "cercledesvoyages" && ( */}
            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.tableCellSpacer}`}>
                <FormControl variant="outlined" style={{ width: "230px" }} size="small">
                    <InputLabel id={`transaction-${remark_index}`} className={`${classes.fontWeight900}`}>
                        { t("menu.trip_list.transaction") }
                    </InputLabel>
                    <OutlinedInput
                        id={`input-transaction-${remark_index}`}
                        labelWidth={130}
                        autoComplete="off"
                        value={remark.identifier}
                        className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12}`}
                        onChange={editIdentifierValue}
                        style={{ width: 200 }}
                    />
                </FormControl>
            </TableCell>
            {/*)
            } */}
            <TableCell padding="none" ref={datePickerRef} className={`${classes.borderTableCell} ${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.tableCellSpacer}`} style={{ position: "relative", width: '13%' }}>
                <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ language }>
                    <DatePicker
                        label={t("menu.trip_list.payment_date")}
                        value={remark.done_date}
                        onChange={(newValue) => handleEditPaymentDate(newValue)}
                        //InputAdornmentProps={{ position: 'start' }}
                        OpenPickerButtonProps={{ onClick: handleOpen } }
                        disableOpenPicker
                        open={openPicker}
                        onClose={handleClose}
                        //PopperProps={{ ref: datePopperRef, anchorEl: datePickerRef.current }}
                        renderInput={(props) =>
                            (<TextField
                                {...props}
                                onClick={handleOpen}
                                InputProps={{ ...props.InputProps, className: `${classes.textFieldInput} ${isHandHeld ? classes.withFullBorder : ""} ${classes.datePickerLegend}` }}
                                helperText={""}
                                error={false}
                                InputLabelProps={{ ...props.InputLabelProps, className: classes.textFieldLabel }}
                                variant={"outlined"}
                                size={"small"}
                                fullWidth={true}
                            />)
                        }
                    />
                </LocalizationProvider>
            </TableCell>
            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.tableCellSpacer}`} style={{ width: "20%" }}>
                <FormControl variant="outlined" style={{ width: "90%" }} size="small">
                    <InputLabel id={`description-${remark_index}`} className={`${classes.fontWeight900}`}>
                        { t("shared.description") }
                    </InputLabel>
                    <OutlinedInput
                        id={`input-description-${remark_index}`}
                        labelWidth={85}
                        autoComplete="off"
                        value={remark.description}
                        className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12}`}
                        onChange={editDescriptionValue}
                    />
                </FormControl>
            </TableCell>
            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.tableCellSpacer}`}>
                {
                    currency !== undefined && currency !== null && (
                        <FormControl variant="outlined" style={{ width: "80%" }} size="small">
                            <InputLabel htmlFor={`input-amount-${remark_index}`} className={`${classes.fontWeight900}`}>
                                { t("menu.trip_list.amount") }
                            </InputLabel>
                            <OutlinedInput
                                id={`input-amount-${remark_index}`}
                                type={ 'number' }
                                onChange={editPaymentValue}
                                label={ t("menu.trip_list.amount") }
                                value={ remark.amount }
                                labelWidth={60}
                                className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.datePickerLegend}`}
                                endAdornment={<InputAdornment> { currency.symbol } </InputAdornment>}
                            />
                        </FormControl>
                    )
                }
            </TableCell>
            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textLeft} ${classes.tableCellSpacer}`}>{remark.creator !== null ? t('shared.seller') : t('menu.trip_list.traveler_website')}</TableCell>
            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textLeft} ${classes.tableCellSpacer}`}>{remark.sent_date !== null ? moment(remark.sent_date).format('L') : ''}</TableCell>
            <div style={{ position: 'absolute', top: '38%', right: 0 }}>
                <Tooltip title={t("global.validate")}>
                    <IconButton size="small" onClick={saveEdit}>
                        <Check />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t("global.cancel")}>
                    <IconButton size="small" onClick={cancelEdit}>
                        <Close />
                    </IconButton>
                </Tooltip>
            </div>
        </TableRow>
    );
};
export default TripListPaymentEditRemarks;
