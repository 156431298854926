export default (arr, flights, flight_key, adult, children, baby, stop_over, flight_stop_over, refundable, total_price, alliances, old_airlines, airlines, airports, distance_unit, is_km, stopover_airport, stations, provider_id, fare_type, flight_source, flight_name, no_time_data, need_segment_key, last_ticketing_date) => {
	let is_nightly = false;
	let stopover_count = null;
	arr.map((segment, segment_index) => {
	    let last_leg = segment.legs.length - 1;
	    let departure_date = moment.utc(segment.legs[0].departure_datetime_lt).format("MM[/]DD[/]YYYY");
	    let arrival_date = moment.utc(segment.legs[last_leg].arrival_datetime_lt).format("MM[/]DD[/]YYYY");
	    let outside_day = moment.utc(new Date(arrival_date)).diff(new Date(departure_date), 'days');
	    let available_seats = 9;
	    let distance_total = 0;
	    let stopover_duration = [];
	    let sum = 0;
	    let prev_flight_duration = 0;
	    let operate_by = null;
	    stopover_count = stopover_count === null ? segment.legs.length : (stopover_count === segment.legs.length ? segment.legs.length : null);
	    if (flight_stop_over < segment.legs.length) {
	        flight_stop_over = segment.legs.length;
	    }
	    if (segment.legs[0].origin_airport !== undefined) {
		    if (airports[segment.legs[0].origin_airport].price === 0 || airports[segment.legs[0].origin_airport].price > total_price) {
		        airports[segment.legs[0].origin_airport].price = total_price;
		    }
	    }
	    else if (segment.legs[0].origin_station !== undefined) {
	    	if (stations[segment.legs[0].origin_station].price === 0 || stations[segment.legs[0].origin_station].price > total_price) {
	    	    stations[segment.legs[0].origin_station].price = total_price;
	    	}
	    }
	    if (segment.legs[last_leg].destination_airport !== undefined) {
		    if (airports[segment.legs[last_leg].destination_airport].price === 0 || airports[segment.legs[last_leg].destination_airport].price > total_price) {
		        airports[segment.legs[last_leg].destination_airport].price = total_price;
		    }
	    }
	    else if (segment.legs[last_leg].destination_station !== undefined) {
	    	if (stations[segment.legs[last_leg].destination_station].price === 0 || stations[segment.legs[last_leg].destination_station].price > total_price) {
	    	    stations[segment.legs[last_leg].destination_station].price = total_price;
	    	}	
	    }
	    segment.legs.map((leg, leg_index) => {
	        leg.seats = {};
			leg.paid_options = {};
	        if (leg_index > 0) {
	            let a = moment.utc(leg.departure_datetime_lt);
	            let b = moment.utc(segment.legs[leg_index - 1].arrival_datetime_lt);
	            let departure_time_stopover = new Date(leg.departure_datetime_lt);
	            let arrival_time_stopover = new Date(segment.legs[leg_index - 1].arrival_datetime_lt);
	            let tmp = (departure_time_stopover.getTime() - arrival_time_stopover.getTime())/1000;
	            stopover_duration.push(tmp);
	            sum += Number(a.diff(b, "hours"));
	            if (prev_flight_duration < leg.flight_time) {
	                prev_flight_duration = leg.flight_time;
	                operate_by = leg.operating_airline;
	                is_nightly = leg.is_nightly;
	            }
	            if (leg_index <= segment.legs.length - 1) {
                    if (leg.origin_airport !== undefined){
                        if (leg_index <= segment.legs.length - 1 && !stopover_airport[segment_index].hasOwnProperty(leg.origin_airport)) {
                            stopover_airport[segment_index][leg.origin_airport] = airports[leg.origin_airport];
                        }
                        if (leg_index <= segment.legs.length - 1 && (stopover_airport[segment_index][leg.origin_airport].price === 0 || stopover_airport[segment_index][leg.origin_airport].price > total_price)) {
                            stopover_airport[segment_index][leg.origin_airport].price = total_price;
                        }
                    }else if (leg.origin_station !== undefined){
                        if (leg_index <= segment.legs.length - 1 && !stopover_airport[segment_index].hasOwnProperty(leg.origin_station)) {
                            stopover_airport[segment_index][leg.origin_station] = stations[leg.origin_station];
                        }
                        if (leg_index <= segment.legs.length - 1 && (stopover_airport[segment_index][leg.origin_station].price === 0 || stopover_airport[segment_index][leg.origin_station].price > total_price)) {
                            stopover_airport[segment_index][leg.origin_station].price = total_price;
                        }
                    }
                }
	        }
	        else {
	            operate_by = leg.operating_airline;
	            is_nightly = leg.is_nightly;
	        }
	        let leg_airline = [];
	        airlines.map((airline) => {
	            if (airline.id === leg.marketing_airline) {
	                leg_airline = airline;
	            }
	        });
	        if (leg_airline.price === 0 || leg_airline.price > total_price) {
	            leg_airline.price = total_price;
	        }
	        for (let k = 0; k < alliances.length; k++) {
	            if (old_airlines[leg.marketing_airline].alliance !== null && alliances[k].name === old_airlines[leg.marketing_airline].alliance.commercial_name) {
	                leg.alliance = Object.assign({}, alliances[k]);
	                if (alliances[k].price === 0 || alliances[k].price > total_price) {
	                    alliances[k].price = total_price;
	                }
	            }
	        }
	        if (leg.available_seats < available_seats) {
	            available_seats = leg.available_seats;
	        }
	        distance_total += leg.distance;
	    });
	    if (distance_unit === "MI" && is_km) {
	        let convert_distance = distance_total * 1.609344;
	        segment.convert_distance_total = Math.ceil(convert_distance);
	    }
	    segment.detail = false;
	    segment.is_nightly = is_nightly;
	    segment.outside_day = outside_day;
	    segment.available_seats = available_seats;
	    segment.distance_total = distance_total;
	    segment.tab = 0;
	    segment.refundable = refundable;
	    segment.stopover_duration = stopover_duration;
	    segment.stopover_duration_sum = sum;
	    segment.options = [];
	    // segment.paid_options = {};
	    segment.selected_option = null;
	    segment.more_option = null;
	    segment.key_pricing = null;
	    segment.empty_option = false;
	    segment.options_error = null;
	    segment.operate_by = operate_by;
		segment.no_time_data = no_time_data;
	});
	if (stopover_count !== null) {
	    switch (stopover_count) {
	        case 1: {
	            if (stop_over.none === 0 || stop_over.none > total_price) {
	                stop_over.none = total_price;
	            }
	            break;
	        }
	        case 2: {
	            if (stop_over.one === 0 || stop_over.one > total_price) {
	                stop_over.one = total_price;
	            }
	            break;
	        }
	        default: {
	            if (stop_over.more === 0 || stop_over.more > total_price) {
	                stop_over.more = total_price;
	            }
	        }
	    }
	}
	flights.push({
	    adult: adult,
	    children: children,
	    baby: baby,
	    outbounds: arr,
	    refundable: refundable,
	    total_price: total_price.toFixed(2),
	    flight_stop_over: flight_stop_over,
	    id: `${flights.length}-${provider_id}-${flight_source}-${fare_type}`,
	    options: null,
	    error_options: null,
	    detail_price: false,
	    provider_id: provider_id,
	    fare_type: fare_type,
		flight_source: flight_source,
		flight_key: flight_key,
		name: flight_name,
		no_time_data: no_time_data,
		need_segment_key: need_segment_key,
		last_ticketing_date: last_ticketing_date
	});
}