var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                        <li>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"chapter travel-certificate-container\">\n    <span class=\"chapter-title\">VOUCHERS</span>\n    <div id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"anchor") || (depth0 != null ? lookupProperty(depth0,"anchor") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchor","hash":{},"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":25}}}) : helper)))
    + "\" class=\"travel-certificate-page\">\n        <h1>\n            Attestation de <span style=\"font-weight: bold;\">voyage</span>\n        </h1>\n        <div class=\"travel-certificate-page-content\">\n            <p>\n                Cercle des Voyages certifie que "
    + alias4(((helper = (helper = lookupProperty(helpers,"lead") || (depth0 != null ? lookupProperty(depth0,"lead") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lead","hash":{},"data":data,"loc":{"start":{"line":9,"column":48},"end":{"line":9,"column":58}}}) : helper)))
    + ", a réservé un voyage à forfait incluant un vol et des\n                prestations sur\n                place.\n            </p>\n            <ul>\n                <li>\n                    <span style=\"font-weight: bold;\">Référence du dossier : </span>\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"ref") || (depth0 != null ? lookupProperty(depth0,"ref") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ref","hash":{},"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":29}}}) : helper)))
    + "\n                </li>\n                <li>\n                    <span style=\"font-weight: bold;\">Destination : </span>\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"destination") || (depth0 != null ? lookupProperty(depth0,"destination") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"destination","hash":{},"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":37}}}) : helper)))
    + "\n                </li>\n                <li>\n                    <span style=\"font-weight: bold;\">Date de départ : </span>\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"startDate") || (depth0 != null ? lookupProperty(depth0,"startDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startDate","hash":{},"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":24,"column":35}}}) : helper)))
    + "\n                </li>\n                <li>\n                    <span style=\"font-weight: bold;\">Date de retour : </span>\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"endDate") || (depth0 != null ? lookupProperty(depth0,"endDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"endDate","hash":{},"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":28,"column":33}}}) : helper)))
    + "\n                </li>\n                <li>\n                    <span style=\"font-weight: bold;\">Nombre de personnes : </span>\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"travelersCount") || (depth0 != null ? lookupProperty(depth0,"travelersCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"travelersCount","hash":{},"data":data,"loc":{"start":{"line":32,"column":20},"end":{"line":32,"column":40}}}) : helper)))
    + "\n                </li>\n                <li>\n                    <span style=\"font-weight: bold;\">Passagers : </span>\n                    <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"travelers") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":39,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n                </li>\n            </ul>\n            <div class=\"travel-certificate-page-content-legal-mention\">\n                Cercle des Vacances, immatriculé au tribunal de Paris, RCS 500 157 532. IATA : 202 47 312\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});