//Dependencies
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, Fragment } from 'react';
//Core
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
//Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';

const CartAccommodationManualCancellationRulesRoom = ({ room, validate, onRequestDone }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [noRefund, setNoRefund] = useState(room.no_refund);
    const [cancellationRules, setCancellationRules] = useState(room.cancel_condition_text !== null ? room.cancel_condition_text : '');
    const onNoRefundChange = event => {
        if (event.target.value) {
            setCancellationRules(t('cart-material.not-cancellable'));
        } else {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/get_cancellation_text_from_charges/`
            }).then(response => {
                setCancellationRules(response.data.cancellation_text);
            }).catch(error => {
            });
        }
        setNoRefund(event.target.value);
    };
    const onCancellationRulesChange = event => {
        setCancellationRules(event.target.value);
    };
    const onValidateManualCancellationRules = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
            data: {
                no_refund: noRefund,
                cancel_condition_text: cancellationRules === '' ? null : cancellationRules
            }
        }).then(response => {
            dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
            onRequestDone();
        }).catch(error => {
            console.log(error);
            onRequestDone();
        });
    };
    useEffect(() => {
        if (validate) {
            onValidateManualCancellationRules();
        }
    }, [validate]);
    useEffect(() => {
        if (room.cancel_condition_text === null) {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/get_cancellation_text_from_charges/`
            }).then(response => {
                setCancellationRules(response.data.cancellation_text);
            }).catch(error => {
            });
        }
    }, []);
    return (
        <Fragment>
            <Grid item xs={ 4 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <Select id={ 'no-refund' } value={ noRefund } onChange={ onNoRefundChange }>
                        <MenuItem value={ false }>{ t('cart-material.cancellable-with-conditions') }</MenuItem>
                        <MenuItem value={ true }>{ t('cart-material.not-cancellable') }</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={ 12 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <InputLabel htmlFor={ 'cancellation-rules' }>{ t('cart-material.manual-cancellation-rules') }</InputLabel>
                    <OutlinedInput
                        id={ 'cancellation-rules' }
                        type={ 'text' }
                        multiline
                        rows={ 8 }
                        onChange={ onCancellationRulesChange }
                        label={ t('cart-material.manual-cancellation-rules') }
                        value={ cancellationRules === t('cart-material.not-cancellable') ? '' : cancellationRules }
                    />
                </FormControl>
            </Grid>
        </Fragment>
    );
};

export default CartAccommodationManualCancellationRulesRoom;
