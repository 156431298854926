import 'jspdf-autotable';
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    IconButton,
    Grid,
    Drawer,
    useTheme,
    useMediaQuery,
    Menu,
    MenuItem
} from "@material-ui/core";
import { Stack, Button, styled } from '@mui/material';
import { jsPDF as JsPdf } from 'jspdf';
import { TripListSendMailForm } from "./TripListSendMailForm";
import { TripListCommunicationHistoryItem } from "./TripListCommunicationHistoryItem";
import { LoadingBackDrop } from '../../Common/LoadingBackdrop';
import { useEmailsHistory } from "./Functions/useEmailsHistory";
import { useMailTemplateAutocomplete } from "./MailVisualEditor/network/mailTemplateAutocomplete";
import { useMailTemplateRecipients } from "./MailVisualEditor/network/mailTemplateRecipients";
import { setLoading } from './MailVisualEditor/redux/actions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

function TripListCommunication(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.mailTemplate.others.loading);
    const exportButtonRef = useRef(null);
    const [openExportMenu, setOpenExport] = useState(false);
    const [openFilters, setOpenFilters] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [refresh, history] = useEmailsHistory();
    useMailTemplateAutocomplete();
    useMailTemplateRecipients();

    const onClickExport = () => {
        setOpenExport(true);
    };

    const onCloseExportMenu = () => {
        setOpenExport(false);
    };
 
    const onCloseFilters = () => {
        setOpenFilters(false);
    };
    
    return (
        <div>
            <Grid container>
                <Grid item md={4} />
                <Grid item xs={12} md={4}>
                    <Title id="email-title">
                        {t('menu.email-history.title')}
                    </Title>
                </Grid>
                <HeaderButtons item xs={12} md={4}>
                    <HeaderButton
                        ref={exportButtonRef}
                        size="small"
                        onClick={onClickExport}
                        disableRipple
                    >
                        <SaveAltIcon />
                        <div className="icon-button-text">
                            { t("menu.history.save") }
                        </div>
                    </HeaderButton>
                </HeaderButtons>
            </Grid>
            <Filters open={openFilters} onClose={onCloseFilters} />
            <ExportMenu
                anchorElement={exportButtonRef.current}
                open={openExportMenu}
                onClose={onCloseExportMenu}
                data={history && history.results}
            />
            {
                !openForm &&
                <NewMailContainer>
                    <NewMailButton
                        variant="contained"
                        onClick={() => setOpenForm(true)}
                        startIcon={<AddCircleIcon sx={{ color: '#E6592F' }} />}
                    >
                        {t('menu.email-history.new-mail')}
                    </NewMailButton>
                </NewMailContainer>
            }
            {
                openForm &&
                <TripListSendMailForm
                    customData={props.customData}
                    onRefresh={refresh}
                    setLoading={(loading) => dispatch(setLoading(loading))}
                    onClose={() => setOpenForm(false)}
                    submitAction={props.submitAction}
                />
            }
            {
                history &&
                <HistoryContainer spacing={2}>
                    {
                        history.results.map((item) => (
                            <TripListCommunicationHistoryItem
                                key={item.id}
                                item={item}
                                setLoading={(loading) => dispatch(setLoading(loading))}
                            />
                        ))
                    }
                </HistoryContainer>
            }
            <LoadingBackDrop open={loading} />
        </div>
    );
}

function Filters(props) {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <FiltersDrawer
            anchor={"right"}
            open={props.open}
            onClose={props.onClose}
            variant={ mobile ? "temporary" : "persistent" }
        >
            <div>Filters.</div>
            {/*<TripListHistoryFilter setOpenFilter={setOpenFilter} setHistory={setHistory} all_history={all_history} setDates={setDates} all_dates={all_dates} /> */}
        </FiltersDrawer>
    );
}

function ExportMenu(props) {
    const { t } = useTranslation();
    const trip_info = useSelector(store => store.menu.trip_info);
    const to_show_version = useSelector(store => store.menu.to_show_version);

    const onExportCsv = () => {
        if (!props.data || props.data.length === 0) {
            return;
        }

        const version = to_show_version !== null ? to_show_version : trip_info.current_version;
        const head = Object.keys(props.data[0]).filter((key) => key !== 'template');
        const data = props.data.map((item) => {
            return head.map((key) => item[key]).join(',');
        });
        let link = document.createElement('a');
        link.href = 'data:text/csv;charset=utf-8,' + encodeURI(`${head.join(',')}\n${data.join('\n')}`);
        link.target = '_blank';
        link.download = t("menu.trip_list.email-history") + "-" + t("menu.file_nb") + trip_info.trip_reference + "-" + t("menu.trip_list.nb_version") + version + ".csv";
        link.click();
        props.onClose();
    };

    const onExportPdf = () => {
        const doc = new JsPdf();
        const version = to_show_version !== null ? to_show_version : trip_info.current_version;
        const head = Object.keys(props.data[0]).filter((key) => {
            return ![
                'opened_dates',
                'message_id',
                'template',
                'creator',
                'last_author',
                'owner',
                'trip',
                'trip_version'
            ].includes(key);
        });
        const body = props.data.map((item) => {
            return head.map((key) => {
                if (key.includes('date')) {
                    return window.moment.utc(item[key]).format('DD/MM/YYYY HH:mm');
                } else if (['is_sent', 'is_opened'].includes(key)) {
                    return item[key] ? t('global.yes') : t('global.no');
                }
                return item[key];
            });
        });
        doc.autoTable({
            head: [head],
            body: body
        });
        doc.save(t("menu.trip_list.email-history") + "-" + t("menu.file_nb") + trip_info.trip_reference + "-" + t("menu.trip_list.nb_version") + version + ".pdf");
    };

    return (
        <Menu
            anchorEl={props.anchorElement}
            open={props.open}
            onClose={props.onClose}
            disableScrollLock
            keepMounted
        >
            <MenuItem onClick={onExportPdf}>
                { t("menu.history.export_pdf") }
            </MenuItem>
            <MenuItem onClick={onExportCsv}>
                { t("menu.history.export_csv") }
            </MenuItem>
        </Menu>
    );
}

const HeaderButtons = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px'
    }
}));

const HeaderButton = styled(IconButton)(({ theme }) => ({
    "fontSize": 14,
    "color": '#4A75DA',
    "fontWeight": 400,
    '&:hover': {
        background: "none"
    },
    [theme.breakpoints.down('xs')]: {
        '& .icon-button-text': {
            paddingLeft: '8px'
        }
    }
}));

const Title = styled('div')(({ theme }) => ({
    fontFamily: "Roboto",
    fontStyle: "normal",
    color: "#0000008A",
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 900,
    fontSize: 24,
    letterSpacing: 1.25,
    margin: '20px 0px',
    [theme.breakpoints.down('xs')]: {
        fontSize: 18
    }
}));

const HistoryContainer = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2)
    }
}));

const FiltersDrawer = styled(Drawer)(({ theme }) => ({
    "& .mui-jss-MuiDrawer-paper": {
        zIndex: 11,
        paddingTop: 70
    },
    [theme.breakpoints.down('xs')]: {
        "& .mui-jss-MuiDrawer-paper": {
            zIndex: 11,
            width: "100%"
        }
    }
}));

const NewMailContainer = styled('div')((props) => ({
    textAlign: 'center',
    marginBottom: props.theme.spacing(1.5)
}));

const NewMailButton = styled(Button)(() => ({
    "background": "#F7C8BA",
    "color": '#000',
    "borderRadius": "20px",
    '&:hover': {
        backgroundColor: '#d5d5d5'
    }
}));

export default React.memo(TripListCommunication);
