//Dependencies
import axios from 'axios';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
//Core
import { Button, Stack } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import clsx from 'clsx';
//Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';

const useStyles = makeStyles({
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    cancelButton: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    },
    hide: {
        display: 'none'
    }
});

const CartCarManualBooking = ({ car, onManualBooking }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [status, setStatus] = useState(car.booking_status !== null ? car.booking_status.status_booking : 'DEFAULT');
    const [reference, setReference] = useState(car.booking_status !== null && car.booking_status.item_reference !== null ? car.booking_status.item_reference : '');
    const activateAllStatuses = useMemo(() => {
        return car.booking_manually_set ||
            car.is_custom ||
            car.booking_status?.status_booking !== "CONFIRMED";
    }, [car]);
    const onStatusChange = event => {
        setStatus(event.target.value);
    };
    const onReferenceChange = event => {
        setReference(event.target.value);
    };
    const onValidateManualBooking = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'POST',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/status-manager/${car.id}/status/`,
            data: {
                status_booking: status === 'DEFAULT' ? null : status,
                item_reference: reference
            }
        }).then(response => {
            dispatch({ type: 'CAR_EDIT_CART_BY_ID', payload: response.data });
            onManualBooking();
        }).catch(error => {
            console.log(error);
        });
    };
    const onCancelManualBooking = () => {
        onManualBooking();
    };
    return (
        <Grid container spacing={4}>
            <Grid item xs={6}>
                <FormControl fullWidth size="small" variant={'outlined'}>
                    <InputLabel htmlFor={'status'}>{t('cart-material.status')}</InputLabel>
                    <Select id={'status'} value={status} label={t('cart-material.status')} onChange={onStatusChange}>
                        <MenuItem
                            value={'DEFAULT'}
                            className={
                                clsx({
                                    [classes.hide]: !activateAllStatuses ||
                                        (
                                            car.booking_manually_set &&
                                            !car.is_manual &&
                                            !car.is_custom
                                        )
                                })
                            }
                        >
                            {t('cart-material.none')}
                        </MenuItem>
                        <MenuItem value={'CONFIRMED'} className={clsx({ [classes.hide]: !activateAllStatuses })}>{t('cart-material.confirmed')}</MenuItem>
                        <MenuItem
                            value={'WAITING'}
                            className={
                                clsx({
                                    [classes.hide]: !activateAllStatuses ||
                                        (
                                            car.booking_manually_set &&
                                            !car.is_manual &&
                                            !car.is_custom
                                        )
                                })
                            }
                        >
                            {t('cart-material.pending')}
                        </MenuItem>
                        <MenuItem
                            value={'ERROR'}
                            className={
                                clsx({
                                    [classes.hide]: !activateAllStatuses ||
                                        (
                                            car.booking_manually_set &&
                                            !car.is_manual &&
                                            !car.is_custom
                                        )
                                })
                            }
                        >
                            {t('cart-material.error')}
                        </MenuItem>
                        <MenuItem
                            value={'REFUSED'}
                            className={
                                clsx({
                                    [classes.hide]: !activateAllStatuses ||
                                        (
                                            car.booking_manually_set &&
                                            !car.is_manual &&
                                            !car.is_custom
                                        )
                                })
                            }
                        >
                            {t("cart-material.refused")}
                        </MenuItem>
                        <MenuItem value="CANCELLED">{t('cart-material.cancelled')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth size="small" variant={'outlined'}>
                    <InputLabel htmlFor={'note'}>{t('cart-material.reference')}</InputLabel>
                    <OutlinedInput
                        id={'note'}
                        type={'text'}
                        onChange={onReferenceChange}
                        label={t('cart-material.reference')}
                        value={reference}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                    <Button onClick={onCancelManualBooking}>{t('global.cancel')}</Button>
                    <Button onClick={onValidateManualBooking}>{t('global.validate')}</Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default CartCarManualBooking;
