//---- Dependencies ----//
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";

import axios from "axios";

//---- Action ----//
import { ChangeActivePage, SetTripListLoader, SetTripList } from "../../../../Actions/Menu";

let source = null;
export default (active_page, trip_list_ordering, trip_list_search, trip_list_filter, dispatch, client, special_user) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        if (source !== null) {
	    		source.cancel('Operation canceled by the user.');
	    	}
        source = axios.CancelToken.source();
        dispatch(SetTripListLoader(true));
        const config = JSON.parse(localStorage.getItem("config"));
        let url = `${API_HREF}client/${client}/trip/?limit=30&offset=${((active_page - 1) * 30)}&shortened=true`;
        if (trip_list_ordering !== null && trip_list_search !== null && trip_list_search !== "") {
            url += "&search=" + trip_list_search + "&ordering=" + trip_list_ordering;
        }
        if (trip_list_search !== null && trip_list_search !== "" && trip_list_ordering === null) {
            url += "&search=" + trip_list_search;
        }
        if (trip_list_ordering !== null && trip_list_search === null || trip_list_search === "") {
            url += "&ordering=" + trip_list_ordering;
        }
        if (trip_list_filter !== null && trip_list_filter !== "") {
            url += trip_list_filter;
        } else if (config.quotation_code === "verdie" && (trip_list_search === null || trip_list_search === "")) {
            url += "&current_version__status__in=ONGOING,VALIDATED,AGENCY_MOD,TO_UPDATED,TOBOOK,USER_VALID,ONREQUEST,BOOKED,DEPOSIT,ADDITEM,INPROGRESS,FINISHED,CANCELLED";
        } else if (config.quotation_code === "marcovasco" && (trip_list_search === null || trip_list_search === "")) {
            url += "&current_version__status__in=ONGOING,VALIDATED,ONREQUEST,BOOKED,ADDITEM,INPROGRESS,FINISHED,CANCELLED,CLOSED";
        }

        //IF SPECIAL USER
        const params = new URLSearchParams(url.substring(url.indexOf("?") + 1));
        if (special_user) {
            params.set("user_id", special_user);
            params.delete("user_ta_id__in");
            url = url.substring(0, url.indexOf("?")) + "?" + params.toString() + "&main";
        } else if (!params.get("current_version__status_contract__in") && config.quotation_code !== "cercledesvoyages") {
            params.set("current_version__status_contract__in", "QUOTATION,CONFIRMED");
            url = url.substring(0, url.indexOf("?")) + "?" + params.toString();
        }

        axios({
            method: "GET",
            headers: headers,
            url: url,
            cancelToken: source.token
        }).then(function (response) {
            console.log('data:', response);
            let data = response.data.results.filter((el) => el.data.length !== 0);
            for (let i = 0; i < data.length; i++) {
                data[i].display_margin = false;
            }
            dispatch(SetTripList(data, false, response.data.count, trip_list_ordering, trip_list_search, trip_list_filter));
            dispatch({type: "MENU_SET_PREV_TRIP_LIST_FILTER", payload: {trip_list_filter_prev: trip_list_filter}});
            if (response.data.count < ((active_page - 1) * 30)) {
                dispatch(ChangeActivePage(1));
            }
        }).catch(function (error) {
            //TODO: show snackbar error
            if (!axios.isCancel(error)) {
                dispatch(SetTripListLoader(false));
				    console.log('Request canceled', error.message);
            }
        });
    }
};
