//---- Dependencies ----//
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import ReactDOM from 'react-dom';

//---- Commons ----//

//---- Components ----//

//---- Actions ----//
import { SetAgencyLocation, SetAgencyType} from "../../../Actions/CarsSearch";

//---- Class ----//

@connect((store) => {
    return {
        selected_car: store.cars_search.selected_car,
    }
})
@withTranslation()

export default class CarsRenderAgencies extends Component {
    constructor(props, context) {
        super(props, context);
    }
    componentDidUpdate(prevProps) {
        const { selected_car, childref, same_agency } = this.props;
        if (prevProps.selected_car !== null) {
            if (selected_car.location_code_return !== null && !same_agency) {
                ReactDOM.findDOMNode(childref).scrollIntoView({behavior: 'smooth', block: 'nearest'});
            }
            if (selected_car.location_code_pickup !== null && same_agency) {
                ReactDOM.findDOMNode(childref).scrollIntoView({behavior: 'smooth', block: 'nearest'});
            }
        }
    }
    handleSelect(agency_type, code) {
        const {dispatch, same_agency} = this.props;
        dispatch(SetAgencyLocation(agency_type, code));
        if (!same_agency) {
            dispatch(SetAgencyType(1));
        }
    }
    render() {
        const { selected_car, stations, same_agency, dispatch} = this.props;
        let agency = selected_car.agency_type === 0 ? selected_car.location_code_pickup : selected_car.location_code_return;
        return (
            <div className={ "container-choose-flight" }>
                <div className={ "cars-steps-agencies" }>
                {
                    stations.map((station, station_id) => {
                        if (selected_car.agency_type === 0 && station.is_pickup_station) {
                            return (
                                <label key={station_id} className={ "flight-search-checkbox" }><span>{station.name + " - " + station.address_line + ", " + station.city_name}</span> 
                                    <input type={ "checkbox" } checked={ station.code ===  agency}
                                        value={station.code} onChange={() => {this.handleSelect(selected_car.agency_type, station.code)} }/>
                                    <span className={ "flight-search-check-mark-cars" }/>
                                </label>
                            )
                        }
                        else if (selected_car.agency_type === 1 && station.is_return_station) {
                            return (
                                <label key={station_id} className={ "flight-search-checkbox" }><span>{station.name + " - " + station.address_line + ", " + station.city_name}</span> 
                                    <input type={ "checkbox" } checked={ station.code ===  agency}
                                        value={station.code} onChange={() => {this.handleSelect(selected_car.agency_type, station.code)} }/>
                                    <span className={ "flight-search-check-mark-cars" }/>
                                </label>
                            )
                        }
                    })
                }
                </div>
            </div>
        )
    }
}