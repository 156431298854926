//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from "@material-ui/core/Grid";
import Checkbox from '@material-ui/core/Checkbox';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import clsx from "clsx";
import ItiTypeInitMap from './ItiTypeInitMap';

const useStyles = makeStyles(theme => ({
    cardPadding: {
        padding: "0 10px 10px 10px"
    },
    alignRight: {
        textAlign: "right"
    },
    alignLeft: {
        textAlign: "left"
    },
    alignCenter: {
        textAlign: "center"
    },
    pink: {
        color: "#E0368D"
    },
    bold: {
        fontWeight: "bold"
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    coverImg: {
        display: "block",
        width: "100%",
        objectFit: "cover",
        height: "100%"
    },
    coverImgMap: {
        width: "100%",
        height: 200
    },
    font12: {
        fontSize: 12
    },
    italic: {
        fontStyle: "italic"
    },
    justify: {
        textAlign: "justify"
    },
    customButton: {
        "background": "#e6592f",
        //padding: "15px 40px",
        "textAlign": "center",
        "color": "#fff",
        "fontSize": 17,
        "padding": "4px 15px 4px 15px",
        "textTransform": "none",
        "&:hover": {
            background: "#cc4f2a"
        }
    },
    customButtonV: {
        "background": "#BF202D",
        //padding: "15px 40px",
        "textAlign": "center",
        "color": "#fff",
        "fontSize": 17,
        "padding": "4px 15px 4px 15px",
        "textTransform": "none",
        "&:hover": {
            background: "#cc4f2a"
        }
    },
    floatRight: {
        float: "right"
    },
    controlLabel: {
        //"& .mui-jss-MuiFormControlLabel-label": {
        //// fontSize: 10
        //textTransform: 'capitalize'
        //},
        marginBottom: 0,
        marginRight: 0
    },
    staticPriceContainer: {
	    position: 'fixed',
	    bottom: 0,
	    backgroundColor: 'white',
	    marginLeft: -33,
	    padding: 16,
	    borderTop: '1px solid lightgray'
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    marginBottom40: {
        marginBottom: 40
    }
}));
let marker = null;

const ItiTypeRightPanel = ({ checkScreen, contentOnly }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const itinerary_type_details_data = useSelector(store => store.itinerary_type.itinerary_type_details_data);
    const current_step = useSelector(store => store.summary.current_step);
    const itinerary = useSelector(store => store.itinerary_type.itinerary);

    const [map, setMap] = useState(null);

    useEffect(() => {
        if (itinerary !== null && itinerary.length > 0) {
            ItiTypeInitMap(setMap, itinerary[0].step_type === 'STEP' ? itinerary[0].destination.parent.data : itinerary[1].destination.parent.data);
        }
    }, [itinerary]);
    useEffect(() => {
        if (map !== null) {
            let pos = null;
            if (itinerary[current_step] !== undefined && itinerary[current_step].destination !== null && itinerary[current_step].step_type === 'STEP') {
                pos = new google.maps.LatLng(parseFloat(itinerary[current_step].destination.data.latitude), parseFloat(itinerary[current_step].destination.data.longitude));
            }
            // else {
            //     pos = new google.maps.LatLng(0, 0);
            // }
            if (pos !== null) {
                map.setCenter(pos);
                map.setZoom(6);
                if (marker !== null) {
                    marker.setMap(null);
                }
                let marker_option = {
                    position: pos,
                    map: map,
                    label: {
                        text: (itinerary[0].step_type === 'STEP' ? current_step + 1 : current_step).toString(),
                        color: "#76B6C2",
                        fontWeight: "bold"
                    },
                    icon: {
                        url: "/Img/Map/pin.png",
                        labelOrigin: new google.maps.Point(15, 15)
                    }
                };
                marker = new google.maps.Marker(marker_option);
                let moment_end_date = moment.utc(itinerary[current_step].end_date);
                let night_start_date = moment.utc(itinerary[current_step].start_date);
                let nb_night = 0;
                let inc = 0;
                let total_day = moment_end_date.diff(night_start_date, 'days');
                console.log('total_day:', total_day);
                while (!moment.utc(night_start_date.add(inc, "d").format("YYYY-MM-DD")).isSame(moment.utc(moment_end_date.format("YYYY-MM-DD")))) {
                    inc = 1;
                    nb_night = nb_night + 1;
                    if (nb_night === 100) {
                        break;
                    }
                }
                const infowindow = new google.maps.InfoWindow({
                    content: nb_night + " " + (nb_night > 1 ? t("itinerary.nights") : t("itinerary.night")) ,
                });
                marker.addListener("click", () => {
                    infowindow.open({
                        anchor: marker,
                        map,
                        shouldFocus: false,
                    });
                });
                marker.setMap(map);
            }
        }
    }, [current_step]);
    return (
        <CardContent className={ clsx({ [classes.marginBottom40]: fullScreen })}>
            <Grid item style={{ marginBottom: 20, marginTop: 20 }}>
                <div className={ classes.coverImgMap } id={ "coverImgMap" }/>
            </Grid>
            <Grid container direction={"column"}>
                {
                    fullScreen && !contentOnly && (
                        <Grid container className={ classes.staticPriceContainer } direction={"column"} justify={ "center" }>
                            <Grid item>
                                <Button variant={ "contained" } className={ `${['visiteurs'].includes(quotation_code) ? classes.customButtonV : classes.customButton}` } fullWidth={ true } onClick={checkScreen} >{t("itineraryType.choose_package")}</Button>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    !fullScreen && !contentOnly && (
                        <Grid item className={classes.alignCenter} style={{ marginTop: 10 }}>
                            <Button variant={"contained"} fullWidth={fullScreen} color={"primary"} className={ `${['visiteurs'].includes(quotation_code) ? classes.customButtonV : classes.customButton}` } onClick={checkScreen} >{t("itineraryType.choose_package")}</Button>
                        </Grid>
                    )
                }
            </Grid>
        </CardContent>
    );
};
export default ItiTypeRightPanel;
