import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export default class CustomOverlay extends React.Component {
	render() {
		return (
			<OverlayTrigger rootClose  overlay={<Tooltip id={this.props.id}>{this.props.tooltip}</Tooltip>} placement={this.props.placement}>
			    {this.props.children}
		    </OverlayTrigger>
		);
	}
}
