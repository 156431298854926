import {
    ADD_PAYMENT_REMARK,
    CHANGE_PAY_REMARK_DESCRIPTION,
    CHANGE_PAY_REMARK_IDENTIFIER,
    EDIT_PAY_REMARK_DESCRIPTION,
    CHANGE_PAY_REMARK_TYPE,
    EDIT_PAY_REMARK_TYPE,
    CHANGE_PAY_REMARK_VALUE,
    EDIT_PAY_REMARK_VALUE,
    EMPTY_PAYMENT_REMARKS,
    REMOVE_PNR_REMARK,
    SET_FEES_REMARK,
    SET_OPEN_GIR_REMINDER,
    SET_PVT_REMARK,
    SET_RESERVATION_MODE,
    SET_PAY_DATE,
    EDIT_PAY_DATE,
    SET_PAYMENT_DONE,
    SET_CANCEL_EDIT,
    SET_VALIDATE_EDIT,
    SET_ADVANCE_PAYMENT
} from '../ActionTypes/Payment';

const initialState = {
    fees_remark: 0,
    payment_remarks: [],
    old_payment_remarks: [],
    payment_done: [],
    open_gir_reminder: false,
    pvt_remark: "",
    reservation_mode: "deposit",
    advance_payment: 0
};

export default function reducer(state = initialState, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.cart !== undefined) {
                Object.keys(action.payload.cart).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.cart[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            new_state = initialState;
            return new_state;
         }
        case ADD_PAYMENT_REMARK: {
            const remark = action.payload;
            let copy_payment_remarks = state.payment_remarks.slice();
            let new_remark= Object.assign({}, remark);
            copy_payment_remarks.push(new_remark);
            return {
                ...state,
                payment_remarks: copy_payment_remarks
            }
        }
        case CHANGE_PAY_REMARK_DESCRIPTION: {
            const { remark_index, new_description } = action.payload;
            let copy_payment_remarks = state.payment_remarks.slice();
            let copy_remark = Object.assign({}, copy_payment_remarks[remark_index]);
            copy_remark.description = new_description;
            copy_payment_remarks[remark_index] = copy_remark;
            return {
                ...state,
                payment_remarks: copy_payment_remarks
            }
        }
        case CHANGE_PAY_REMARK_IDENTIFIER: {
            const { remark_index, new_identifier } = action.payload;
            let copy_payment_remarks = state.payment_remarks.slice();
            let copy_remark = Object.assign({}, copy_payment_remarks[remark_index]);
            copy_remark.identifier = new_identifier;
            copy_payment_remarks[remark_index] = copy_remark;
            return {
                ...state,
                payment_remarks: copy_payment_remarks
            }
        }
        case EDIT_PAY_REMARK_DESCRIPTION: {
            const { remark_index, new_description } = action.payload;
            let copy_payment_done = state.payment_done.slice();
            let copy_remark = Object.assign({}, copy_payment_done[remark_index]);
            copy_remark.description = new_description;
            copy_payment_done[remark_index] = copy_remark;
            return {
                ...state,
                payment_done: copy_payment_done
            }
        }
        case CHANGE_PAY_REMARK_TYPE: {
            const { remark_index, new_type } = action.payload;
            let copy_payment_remarks = state.payment_remarks.slice();
            let copy_remark = Object.assign({}, copy_payment_remarks[remark_index]);
            copy_remark.payment_type = new_type;
            copy_payment_remarks[remark_index] = copy_remark;
            return {
                ...state,
                payment_remarks: copy_payment_remarks
            }
        }
        case EDIT_PAY_REMARK_TYPE: {
            const { remark_index, new_type } = action.payload;
            let copy_payment_done = state.payment_done.slice();
            let copy_remark = Object.assign({}, copy_payment_done[remark_index]);
            copy_remark.payment_type = new_type;
            copy_payment_done[remark_index] = copy_remark;
            return {
                ...state,
                payment_done: copy_payment_done
            }
        }
        case CHANGE_PAY_REMARK_VALUE: {
            const { remark_index, value } = action.payload;
            let payment_remarks = state.payment_remarks.slice();
            let copy_remark = Object.assign({}, payment_remarks[remark_index]);
            copy_remark.amount = value;
            payment_remarks[remark_index] = copy_remark;
            return {
                ...state,
                payment_remarks: payment_remarks
            }
        }
        case EDIT_PAY_REMARK_VALUE: {
            const { remark_index, value } = action.payload;
            let payment_done = state.payment_done.slice();
            let copy_remark = Object.assign({}, payment_done[remark_index]);
            copy_remark.amount = typeof value === "string" ? parseFloat(value) : value;
            payment_done[remark_index] = copy_remark;
            return {
                ...state,
                payment_done: payment_done
            }
        }
        case SET_PAY_DATE: {
            const { remark_index, value } = action.payload;
            let payment_remarks = state.payment_remarks.slice();
            let copy_remark = Object.assign({}, payment_remarks[remark_index]);
            copy_remark.done_date = value;
            payment_remarks[remark_index] = copy_remark;
            return {
                ...state,
                payment_remarks: payment_remarks
            }
        }
        case EDIT_PAY_DATE: {
            const { remark_index, value } = action.payload;
            let payment_done = state.payment_done.slice();
            let copy_remark = Object.assign({}, payment_done[remark_index]);
            copy_remark.done_date = value;
            payment_done[remark_index] = copy_remark;
            return {
                ...state,
                payment_done: payment_done
            }
        }
        case EMPTY_PAYMENT_REMARKS: {
            let old_payment_remarks = state.payment_remarks.slice();
            return {
                ...state,
                payment_remarks: [],
                old_payment_remarks: old_payment_remarks
            }
        }
        case REMOVE_PNR_REMARK: {
            const remark_index = action.payload;
            let copy_payment_remarks = state.payment_remarks.slice();
            copy_payment_remarks.splice(remark_index, 1);
            return {
                ...state,
                payment_remarks: copy_payment_remarks
            }
        }
        case SET_FEES_REMARK: {
            return {
                ...state,
                fees_remark: action.payload
            }
        }
        case SET_OPEN_GIR_REMINDER: {
            return {
                ...state,
                open_gir_reminder: action.payload
            }
        }
        case SET_PVT_REMARK: {
            return {
                ...state,
                pvt_remark: action.payload
            }
        }
        case SET_RESERVATION_MODE: {
            return {
                ...state,
                reservation_mode: action.payload
            }
        }
        case SET_PAYMENT_DONE: {
            return {
                ...state,
                payment_done: action.payload,
                old_payment_remarks: action.payload
            }
        }
        case SET_CANCEL_EDIT: {
            let old_payment_remarks = state.old_payment_remarks.slice();
            return {
                ...state,
                payment_done: old_payment_remarks
            }
        }
        case SET_VALIDATE_EDIT: {
            let payment_done = state.payment_done.slice();
            return {
                ...state,
                old_payment_remarks: payment_done
            }
        }
        case SET_ADVANCE_PAYMENT: {
            return {
                ...state,
                advance_payment: action.payload
            }
        }
        default:
            return state;
    }
}