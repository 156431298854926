import { flatten } from "lodash";
import { useCartProducts } from "../../../Itinerary/network/cartProducts";

export function groupCartProductsByDate(
    products: ReturnType<typeof useCartProducts>
): {
    [date: string]: typeof products
} {
    const result: {
        [date: string]: typeof products
    } = {};

    for (const item of products.flights) {
        const dates = item.type === 'normal' ?
            [item.flight.start_date, item.flight.end_date] :
            flatten(
                item.flight.flight_segment.map((item) => [
                    item.start_date,
                    item.end_date
                ])
            ).sort((a, b) => {
                const aDate = window.moment.utc(a);
                const bDate = window.moment.utc(b);
                return aDate.isBefore(bDate) ?
                    -1 :
                    1;
            });
        const [startDate, endDate] = [
            dates[0],
            dates[dates.length - 1]
        ];

        if (startDate && endDate) {
            const range = rangeBetween(startDate, endDate);
    
            for (const date of range) {
                let assignable = result[window.moment.utc(date).format('YYYY-MM-DD')];
        
                if (!assignable) {
                    assignable = result[window.moment.utc(date).format('YYYY-MM-DD')] = {
                        accommodations: [],
                        cars: [],
                        flights: [],
                        pois: [],
                        transfers: [],
                        trains: [],
                        cruises: [],
                        ferries: [],
                        assistances: [],
                        insurances: []
                    };
                }
        
                assignable.flights.push(item);
            }
        }
    }

    for (const item of products.cars) {
        const range = rangeBetween(item.car.start_date, item.car.end_date);

        for (const date of range) {
            let assignable = result[window.moment.utc(date).format('YYYY-MM-DD')];
    
            if (!assignable) {
                assignable = result[window.moment.utc(date).format('YYYY-MM-DD')] = {
                    accommodations: [],
                    cars: [],
                    flights: [],
                    pois: [],
                    transfers: [],
                    trains: [],
                    cruises: [],
                    ferries: [],
                    assistances: [],
                    insurances: []
                };
            }
    
            assignable.cars.push(item);
        }
    }

    for (const item of products.trains) {
        const range = rangeBetween(item.train.start_date, item.train.end_date);

        for (const date of range) {
            let assignable = result[window.moment.utc(date).format('YYYY-MM-DD')];
    
            if (!assignable) {
                assignable = result[window.moment.utc(date).format('YYYY-MM-DD')] = {
                    accommodations: [],
                    cars: [],
                    flights: [],
                    pois: [],
                    transfers: [],
                    trains: [],
                    cruises: [],
                    ferries: [],
                    assistances: [],
                    insurances: []
                };
            }
    
            assignable.trains.push(item);
        }
    }

    for (const item of products.cruises) {
        const range = rangeBetween(item.cruise.start_date, item.cruise.end_date);

        for (const date of range) {
            let assignable = result[window.moment.utc(date).format('YYYY-MM-DD')];
    
            if (!assignable) {
                assignable = result[window.moment.utc(date).format('YYYY-MM-DD')] = {
                    accommodations: [],
                    cars: [],
                    flights: [],
                    pois: [],
                    transfers: [],
                    trains: [],
                    cruises: [],
                    ferries: [],
                    assistances: [],
                    insurances: []
                };
            }
    
            assignable.cruises.push(item);
        }
    }

    for (const item of products.ferries) {
        const range = rangeBetween(item.ferry.start_date, item.ferry.end_date);

        for (const date of range) {
            let assignable = result[window.moment.utc(date).format('YYYY-MM-DD')];
    
            if (!assignable) {
                assignable = result[window.moment.utc(date).format('YYYY-MM-DD')] = {
                    accommodations: [],
                    cars: [],
                    flights: [],
                    pois: [],
                    transfers: [],
                    trains: [],
                    cruises: [],
                    ferries: [],
                    assistances: [],
                    insurances: []
                };
            }
    
            assignable.ferries.push(item);
        }
    }

    for (const item of products.accommodations) {
        const range = rangeBetween(
            item.accommodation.start_date,
            window.moment.utc(item.accommodation.end_date).startOf(
                'day'
            ).subtract(
                1,
                'day'
            ).toISOString()
        );

        for (const date of range) {
            let assignable = result[window.moment.utc(date).format('YYYY-MM-DD')];
    
            if (!assignable) {
                assignable = result[window.moment.utc(date).format('YYYY-MM-DD')] = {
                    accommodations: [],
                    cars: [],
                    flights: [],
                    pois: [],
                    transfers: [],
                    trains: [],
                    cruises: [],
                    ferries: [],
                    assistances: [],
                    insurances: []
                };
            }
    
            assignable.accommodations.push(item);
        }
    }

    for (const item of products.pois) {
        let assignable = result[window.moment.utc(item.poi.start_date).format('YYYY-MM-DD')];

        if (!assignable) {
            assignable = result[window.moment.utc(item.poi.start_date).format('YYYY-MM-DD')] = {
                accommodations: [],
                cars: [],
                flights: [],
                pois: [],
                transfers: [],
                trains: [],
                cruises: [],
                ferries: [],
                assistances: [],
                insurances: []
            };
        }

        assignable.pois.push(item);
    }

    for (const item of products.transfers) {
        const range = rangeBetween(item.transfer.start_date, item.transfer.end_date);

        for (const date of range) {
            let assignable = result[window.moment.utc(date).format('YYYY-MM-DD')];
    
            if (!assignable) {
                assignable = result[window.moment.utc(date).format('YYYY-MM-DD')] = {
                    accommodations: [],
                    cars: [],
                    flights: [],
                    pois: [],
                    transfers: [],
                    trains: [],
                    cruises: [],
                    ferries: [],
                    assistances: [],
                    insurances: []
                };
            }
    
            assignable.transfers.push(item);
        }
    }

    return result;
}

function rangeBetween(a: string, b: string) {
    const result: typeof aDate[] = [];
    const aDate = window.moment.utc(a);
    const bDate = window.moment.utc(b);
    const delta = bDate.startOf('day').diff(
        aDate.startOf('day'),
        'days'
    ) + 1;
    for (let i = 0; i < delta; i++) {
        result.push(aDate.clone().add(i, 'days'));
    }
    return result;
}
