import { Key } from "../../../../Reducers/Reducers";

type Options = {
    center: string,
    zoom: string,
    points: {
        label: string,
        color: string,
        latitude: string,
        longitude: string
    }[]
}

export function createDestinationMapPageUrl(options: Options): string {
    const config = JSON.parse(localStorage.getItem('config') ?? '{}') as { keys?: Key[] };
    const key = config.keys?.find((item) => item.identifier === 'google_api');
    const itineraryUrl = new URL('https://maps.googleapis.com/maps/api/staticmap');
    itineraryUrl.searchParams.append(
        'size',
        '985x943'
    );
    itineraryUrl.searchParams.append(
        'key',
        key?.value ?? ''
    );
    itineraryUrl.searchParams.append(
        'style',
        'feature:poi|element:labels|visibility:off'
    );
    const markers = options.points.map((item) => {
        return `color:${item.color}|label:${item.label}|${item.latitude},${item.longitude}`;
    });

    if (markers.length > 0) {
        for (const marker of markers) {
            itineraryUrl.searchParams.append(
                'markers',
                marker
            );
        }
    } else {
        itineraryUrl.searchParams.append(
            'center',
            options.center
        );
    
        itineraryUrl.searchParams.append(
            'zoom',
            options.zoom
        );
    }

    return itineraryUrl.toString();
}
