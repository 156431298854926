import { isNumber } from "lodash";
import { ManualProductPoiCart } from "../../../Itinerary/objects/manualProductPoiCart";
import { PoiCart } from "../../../Itinerary/objects/poiCart";
import { htmlHasEmptyContent } from "./htmlHasEmptyContent";

export function getPoiDescription(
    item: {
        type: "normal",
        poi: PoiCart,
    } | {
        type: "manual",
        poi: ManualProductPoiCart,
    },
    options: {locale: number | null}
): [string, string] {
    let longDescription = '';
    let shortDescription = '';
    const localization = item.poi.localization?.find((item) => {
        return item.locale === options.locale;
    });

    const providerLocalization = item.type === 'normal' ?
        item.poi.custom_product?.localization?.find((item) => {
            return item.locale === options.locale;
        }) :
        undefined;

    if (!htmlHasEmptyContent(localization?.description ?? '')) {
        shortDescription = localization?.description ?? '';
    } else if (!htmlHasEmptyContent(item.poi.description ?? '')) {
        shortDescription = item.poi.description ?? '';
    } else if (!htmlHasEmptyContent(providerLocalization?.short_description ?? '')) {
        shortDescription = providerLocalization?.short_description ?? '';
    } else if (!htmlHasEmptyContent(item.poi.custom_product?.short_description ?? '')) {
        shortDescription = item.poi.custom_product?.short_description ?? '';
    }

    let variantDescription: string | null = null;

    if (item.type === 'normal') {
        variantDescription = (
            // eslint-disable-next-line no-nested-ternary
            item.poi.cust_variant &&
                !isNumber(item.poi.cust_variant) ?
                item.poi.cust_variant.localization?.find((item) => {
                    return item.locale === options.locale;
                })?.short_description ??
                item.poi.cust_variant.short_description :
                (
                    item.poi.variant && !isNumber(item.poi.variant) ?
                        item.poi.variant.localization?.find((item) => {
                            return item.locale === options.locale;
                        })?.short_description ??
                        item.poi.variant?.short_description :
                        null
                )
        );
    }

    if (
        htmlHasEmptyContent(localization?.description ?? '') &&
        htmlHasEmptyContent(item.poi.description ?? '') &&
        !htmlHasEmptyContent(variantDescription ?? '')
    ) {
        shortDescription += variantDescription;
    }

    longDescription = localization?.long_description || localization?.long_description?.trim() === '' ?
                        localization?.long_description :
                        (
                            providerLocalization?.long_description ??
                            item.poi.custom_product?.long_description ??
                            ''
                        )

    return [longDescription, shortDescription];
}