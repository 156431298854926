import { Dispatch } from "redux";
import { findOutbounds } from "../utils/findOutbounds";
import UpdateCart from "../Functions/UpdateCart";
import { Itinerary } from "../objects/itinerary";

export default async function (
    itinerary: Itinerary[],
    startDate: string,
    departureStep: Itinerary,
    returnStep: Itinerary,
    dispatch: Dispatch<any>
): Promise<void> {
    const oubounds = await findOutbounds(itinerary, departureStep, returnStep);

    const outbound = oubounds.filter((item) => {
        return item.a && item.b;
    }).map((item) => {
        return item.a!.airport_code + ":" +
               item.b!.airport_code + "," +
               window.moment.utc(item.date).format("YYYY-MM-DD");
    }).join('|');

    if (outbound.length > 0) {
        UpdateCart("CHECK", dispatch, null, null, null, null, null, null, null, null, outbound);
    } else {
        UpdateCart("CHECK", dispatch, null, null, null, null, null, null, null, null, null);
    }
}
