import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import { Box } from '@mui/material';
import { isNumber } from 'lodash';
import SelectLead from './SelectLead.js';
import { isProductPackaged } from '../CartMaterial/utils/isProductPackaged';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight700: {
        fontWeight: 700
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize14: {
        fontSize: 14
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter: {
        textAlign: "center"
    },
    genericBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box"
    },
    genericPadding: {
        padding: "4px 8px"
    }
}));


const BookingFlightInfo = ({ tempTravelers, setTempTravelers }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));

    const to_book = useSelector(store => store.cart.to_book);
    const trip = useSelector(store => store.trip.data_trip);
    const providers = useSelector(store => store.trip.providers);

    const [flightsToBook, setFlightsToBook] = useState([]);
    const [flightsProviders, setFlightsProviders] = useState(null);
    const connectedPackageProducts = useMemo(() => {
        return flightsToBook.filter((flight) => {
            return isProductPackaged({
                product: flight,
                stackInfos: trip?.stack_info ?? null,
                connected: true
            });
        });
    }, [flightsToBook]);


    useEffect(() => {
        let temp_flights = [];

        to_book.map((product) => {
            if (product.is_displayed === true && (product.booking_status === null || (product.booking_status !== null && product.booking_status.status_booking === "ERROR"))) {
                if (product.product_type === 6 && product.group_passenger.default === false) {
                    let temp_product = Object.assign({}, product);
                    temp_product.group_passenger.lead_traveler_info = temp_product.group_passenger.lead_traveler_info !== null ? temp_product.group_passenger.lead_traveler_info : "";
                    temp_product.group_passenger.lead_traveler = temp_product.group_passenger.lead_traveler !== null ? temp_product.group_passenger.lead_traveler : "";
                    temp_flights.push(temp_product);
                }
            }
        });
        setFlightsToBook(temp_flights);
    }, [to_book]);

    useEffect(() => {
        let temp_providers = [];
        
        flightsToBook.map((flight) => {
            providers.map((provider) => {
                if (provider.provider.id === flight.provider) {
                    temp_providers.push(provider);
                }
            });
        });
        setFlightsProviders(temp_providers);
    }, [providers, flightsToBook]);

    return (
        <Grid>
            {
                flightsToBook.length !== 0 && (
                    <Grid className={`${classes.genericPadding} ${classes.genericBorder}`} style={{ marginBottom: 12 }}>
                        <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.upperCase} ${classes.letterSpacing}`} style={!downDesktop ? {} : { paddingBottom: 8 }}>
                            { t("booking.flight_info") }
                        </Grid>
                        <Grid style={{ marginBottom: 8 }}>
                            {
                                flightsToBook.filter((flight, index, array) => {
                                    return !isProductPackaged({
                                        product: flight,
                                        stackInfos: trip?.stack_info ?? null,
                                        connected: true
                                    }) ||
                                    array.slice(0, index).findIndex((flight) => {
                                        return isProductPackaged({
                                            product: flight,
                                            stackInfos: trip?.stack_info ?? null,
                                            connected: true
                                        });
                                    }) < 0;
                                }).map((flight, flight_index) => {
                                    return (
                                        <Grid key={`flight-${flight.id}`} container alignItems={!downDesktop ? "center" : "flex-start"} justify="space-between" direction={!downDesktop ? "row" : "column"} className={classes.genericPadding} spacing={!downDesktop ? 0 : 1}>
                                            <Grid item md={2} xs={12} className={`${classes.genericText} ${classes.textCenter} ${classes.fontWeight700} ${classes.fontSize14} ${classes.upperCase} ${classes.letterSpacing}`}>
                                                {
                                                    !isProductPackaged({
                                                        product: flight,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    <>
                                                        { flight.name !== null ? flight.name : (t("global.flight") + " - " + (flight_index + 1) + " ") } {
                                                            isProductPackaged({
                                                                product: flight,
                                                                stackInfos: trip?.stack_info ?? null
                                                            }) &&
                                                            <Box sx={{ color: '#2F80ED' }} component="span">
                                                                ({t('cart-material.cart-construction-package-name', { name: flight.stack_number })})
                                                            </Box>
                                                        }
                                                    </>
                                                }
                                                {
                                                    isProductPackaged({
                                                        product: flight,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    <Box sx={{ color: '#2F80ED' }} component="span">
                                                        {t('cart-material.cart-construction-connected-package')}
                                                    </Box>
                                                }
                                            </Grid>
                                            {
                                                !isProductPackaged({
                                                    product: flight,
                                                    stackInfos: trip?.stack_info ?? null,
                                                    connected: true
                                                }) &&
                                                <Grid item md={2} xs={12} className={`${classes.genericText} ${classes.textCenter} ${classes.fontWeight700} ${classes.fontSize10}`}>
                                                    {
                                                        flight.outbounds !== undefined && flight.outbounds.map((outbound, outbound_index) => {
                                                            return (
                                                                <div key={`${flight.id}-${outbound_index}`}>
                                                                    {
                                                                        outbound.legs.map((leg, leg_index) => {
                                                                            let origin = leg.origin !== null ? leg.origin.iata_city.international_name : (leg.origin_station !== null ? leg.origin_station.iata_city.international_name : leg.origin_terminal.iata_city.international_name);
                                                                            let destination = leg.destination !== null ? leg.destination.iata_city.international_name : (leg.destination_station !== null ? leg.destination_station.iata_city.international_name : leg.destination_terminal.iata_city.international_name);
                                                                            return (
                                                                                <div key={`${flight.id}-${outbound_index}-${leg_index}`}>
                                                                                    { origin + " --> " + destination}
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    {
                                                        flight.outbounds === undefined && flight.is_manual !== undefined && flight.is_manual && flight.flight_segment.map((outbound, outbound_index) => {
                                                            let origin = outbound.origin_airport !== null ? outbound.origin_airport.iata_city.international_name : (outbound.origin_station !== null ? outbound.origin_station.iata_city.international_name : outbound.origin_terminal.iata_city.international_name);
                                                            let destination = outbound.destination_airport.iata_city.international_name !== null ? outbound.destination_airport.iata_city.international_name : (outbound.destination_station.iata_city.international_name !== null ? outbound.destination_station.iata_city.international_name : outbound.destination_terminal.iata_city.international_name);
                                                            return (
                                                                <div key={`${flight.id}-${outbound_index}`}>
                                                                    {
                                                                        origin + " --> " + destination
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </Grid>
                                            }
                                            {
                                                !isProductPackaged({
                                                    product: flight,
                                                    stackInfos: trip?.stack_info ?? null,
                                                    connected: true
                                                }) &&
                                                flightsProviders.length !== 0 && (
                                                    <Grid item md={2} xs={12} className={classes.textCenter} style={!downDesktop ? {} : { width: "100%" }}>
                                                        <img src={ flightsProviders[flight_index].provider?.logo?.url } alt={ 'flight-provider-picture' } width={ 100 }/>
                                                    </Grid>
                                                )
                                            }
                                            <Grid item md={6} xs={12} style={!downDesktop ? {} : { width: "100%" }}>
                                                {
                                                    !isProductPackaged({
                                                        product: flight,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    <SelectLead group={flight.group_passenger} mode={"flight"} product={flight} product_list={null} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                                                }
                                                {
                                                    isProductPackaged({
                                                        product: flight,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    <SelectLead
                                                        group={flight.group_passenger}
                                                        mode={"group"}
                                                        product={null}
                                                        product_list={connectedPackageProducts}
                                                        tempTravelers={tempTravelers}
                                                        setTempTravelers={setTempTravelers}
                                                    />
                                                }
                                            </Grid>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    );
};

export default React.memo(BookingFlightInfo);
