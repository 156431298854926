export default (vehicles, selected_route) => {
    let biggest_vehicle_index = 0;
    let vehicle_to_return = 0;
     for (let i = 0; i < selected_route.segments.length; i++) {
		let transport_order = ["unknown", "foot", "bicycle", "rideshare", "shuttle", "car", "taxi", "bus", "tram", "subway", "train", "ferry", "helicopter", "plane"];
		let order = transport_order.indexOf(vehicles[selected_route.segments[i].vehicle].kind);
		if (order > biggest_vehicle_index) {
	        biggest_vehicle_index = order;
	        vehicle_to_return = vehicles[selected_route.segments[i].vehicle];
	    }
	}
	return vehicle_to_return;
}