import { CarCart } from "../../Itinerary/objects/carCart";
import { Picture } from "../../Menu/MaterialTripList/picture/objects/picture";

export function getCarPicture(car: CarCart, source?: 'provider'): Picture[] {
    if (car.hide_pictures) {
        return [];
    }

    if (!source && (car.overload_pictures?.length ?? 0) > 0) {
        return car.overload_pictures;
    }

    return [];
}
