//---- Commons ----//
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import Notify from "../../Common/Notify";

//---- Functions ----//
import GetBiggestSegmentVehicle from './GetBiggestSegmentVehicle.js';

//---- Actions ----//
import { SetItinerary } from "../../../Actions/Itinerary";
import { SetRequestStatus } from "../../../Actions/CarsSearch";

let steps_length = 0;
let checked_length = 0;

function redirect(cars_steps, setCarIndex, setLocationIndex, location_index, car_index, step_info, router, dispatch, enqueueSnackbar, t, onClose) {
    const step = step_info[location_index];
    steps_length += 1;
    if (steps_length === checked_length) {
        enqueueSnackbar(t("cars.add_to_cart.success"), {
		    variant: "success"
        });
    	dispatch(SetRequestStatus("Done"));
    	if ((car_index + 1) !== step.nb_vehicle) {
    		dispatch({ type: "CARS_IS_RESULT" });
    		setCarIndex(car_index + 1);
    	} else if ((location_index + 1) !== step_info.length) {
    		dispatch({ type: "CARS_IS_RESULT" });
    		setCarIndex(0);
    		setLocationIndex(location_index + 1);
    	} else if (!onClose) {
            router.push(`/${window.url_name}/apps/itinerary`);
    	} else if (onClose) {
            onClose();
        }
    }
}

export default (cars_steps, itinerary_list, setCarIndex, setLocationIndex, location_index, car_index, step_info, router, enqueueSnackbar, t, dispatch, onClose) => {
    let { pass_check, headers } = CheckBeforeRequest();
    steps_length = 0;
    checked_length = cars_steps.length;
    if (pass_check) {
        if (cars_steps.length === 0) {
            checked_length += 1;
            redirect(cars_steps, setCarIndex, setLocationIndex, location_index, car_index, step_info, router, dispatch, enqueueSnackbar, t, onClose);
        }
        cars_steps.map((step, step_index) => {
            let current_id = null;
            let next_id = null;
            let current_index = null;
            let choosed_transport = null;
            let transport = null;
            itinerary_list.map((itinerary, index) => {
                if (itinerary.id === step.id) {
                    current_id = itinerary.id;
                    current_index = index;
                    next_id = itinerary_list[index + 1].id;
                }
            });
            if (step.r2r_json.data !== undefined && step.r2r_json.data !== null && step.r2r_json.data.routes !== undefined && step.r2r_json.data.routes !== null) {
                step.r2r_json.data.routes.map((route) => {
                    if (route.segments.length === 1) {
                        route.segments.map((segment) => {
                            if (step.r2r_json.data.vehicles[segment.vehicle].kind === "car") {
                                choosed_transport = route;
                                transport = step.r2r_json;
                            }
                        });
                    }
                });
            }
            let h = Math.floor((choosed_transport !== null ? choosed_transport.totalDuration : step.r2r_json.duration) / 60);
		    let m = (choosed_transport !== null ? choosed_transport.totalDuration : step.r2r_json.duration) % 60;
		    h = h < 10 ? "0" + h : h;
		    m = m < 10 ? "0" + m : m;
            let tmp_obj = {
                data: transport !== null ? transport.data : step.r2r_json.data,
                distance: choosed_transport !== null ? choosed_transport.distance : step.r2r_json.distance,
                distance_string: parseInt(choosed_transport !== null ? choosed_transport.distance : step.r2r_json.distance).toLocaleString().replace(",", " ") + " km",
                duration: choosed_transport !== null ? choosed_transport.totalDuration : step.r2r_json.duration,
                duration_string: h + "h" + m,
                id: (choosed_transport !== null ? choosed_transport.distance : step.r2r_json.distance) + Math.random(),
                name_string: choosed_transport !== null ? choosed_transport.name : step.r2r_json.name_string,
                segments: choosed_transport !== null ? choosed_transport.segments : step.r2r_json.segments,
                type: 2,
                vehicle: choosed_transport !== null ? GetBiggestSegmentVehicle(transport !== null ? transport.data.vehicles : step.r2r_json.data.vehicles, choosed_transport) : step.r2r_json.vehicle,
                vehicles: step.r2r_json.data.vehicles,
                selected: step.checked
            };
            let destination_end_date = moment.utc(itinerary_list[current_index].end_date).format("YYYY-MM-DD") === moment.utc(itinerary_list[current_index].start_date).format("YYYY-MM-DD") ? moment.utc(itinerary_list[current_index].end_date) : moment.utc(itinerary_list[current_index].end_date).set({ hour: "09", minute: "00" });
            let destination_start_date = moment.utc(itinerary_list[current_index].end_date).format("YYYY-MM-DD") === moment.utc(itinerary_list[current_index].start_date).format("YYYY-MM-DD") ? moment.utc(itinerary_list[current_index].end_date).add(choosed_transport !== null ? choosed_transport.totalDuration : step.r2r_json.duration, "minutes") : moment.utc(itinerary_list[current_index].end_date).set({ hour: "09", minute: "00" }).add(choosed_transport !== null ? choosed_transport.totalDuration : step.r2r_json.duration, "minutes");
            let request = {
                end_date: destination_end_date.format(),
                r2r_json: tmp_obj,
                selected_transport: choosed_transport !== null ? choosed_transport.totalDuration + "-" + choosed_transport.distance : step.r2r_json.duration + "-" + step.r2r_json.distance
            };
            $.ajax({
			    method: "PATCH",
			    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/${current_id}/?token=${GetCookie("trip_token")}&shift_mode=false`,
			    headers: headers,
			    data: JSON.stringify(request),
			    success: function (data) {
			        dispatch(SetItinerary(data));
			        $.ajax({
			            method: "PATCH",
			            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/${next_id}/?token=${GetCookie("trip_token")}&shift_mode=false`,
			            headers: headers,
			            data: JSON.stringify({
			                start_date: destination_start_date.format()
			            }),
			            success: function (data) {
			        		redirect(cars_steps, setCarIndex, setLocationIndex, location_index, car_index, step_info, router, dispatch, enqueueSnackbar, t, onClose);
			                dispatch(SetItinerary(data));
			            }
			        });
			    },
			    error: function (error) {
                    redirect(cars_steps, setCarIndex, setLocationIndex, location_index, car_index, step_info, router, dispatch, enqueueSnackbar, t, onClose);
			    	console.log('error:', error);
                    enqueueSnackbar(t("cars.add_to_cart.warning"), {
                        variant: "warning"
                    });
			    }
            });
        });
    }
};
