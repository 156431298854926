/* eslint-disable space-before-blocks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { makeStyles, useTheme } from "@material-ui/core";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from "@material-ui/core/Grid";
import CheckBox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MuiPhoneNumber from "material-ui-phone-number";

import CheckTravelerIdentical from "./Functions/CheckTravelerIdentical";
import CheckDefaultNames from "./Functions/CheckDefaultNames";
import CheckNamesCharacters from "./Functions/CheckNamesCharacters";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

import { EditTraveler } from "../../../Actions/Trip";
import { SetChecksPassed, SetSameTravelerID } from "../../../Actions/Booking";

import CountrySearch from "./CountrySearch.js";
import TravelerInfo from "./TravelerInfo.js";
import TravelerMoreInfo from "./TravelerMoreInfo.js";
import TravelerMoreInfoMobile from "./TravelerMoreInfoMobile.js";

import axios from "axios";
import moment from 'moment';
import { getUser } from "../Crm/Functions/UserFunctions";
import { CrmTraveler } from "../../ItineraryType/CrmTraveler";

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400: {
        fontWeight: 400
    },
    fontWeight700: {
        fontWeight: 700
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize18: {
        fontSize: 18
    },
    fontSize20: {
        fontSize: 20
    },
    fontSize24: {
        fontSize: 24
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textLeft: {
        textAlign: "left"
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    spacer: {
        padding: "8px 0px"
    },
    smallPaddingTop: {
        paddingTop: 5
    },
    genericBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box"
    },
    genericPadding: {
        padding: "0px 20px"
    },
    genericMargin: {
        margin: "20px 0px"
    },
    titleBorder: {
        borderBottom: "0.5px solid rgba(0, 0, 0, 0.25)"
    },
    separator: {
        borderTop: "0.5px solid rgba(0, 0, 0, 0.25)"
    },
    noMarginBottom: {
        marginBottom: 0
    },
    formControlRoot1: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 14
            //zIndex: -1
        }
    },
    formControlRootMobile1: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12
            //zIndex: -1
        }
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    outlinedInput: {
        padding: "7px 30px 7px 20px;"
    },
    circleBorder: {
        borderRadius: 8
    },
    cancelButton: {
        color: "#0000008A",
        backgroundColor: "#FFFFFF",
        marginRight: 20
    },
    validateButton: {
        color: "#FFFFFF",
        backgroundColor: "#2C98F0"
    }
}));

const TripListTravelersInfos = (({ trip_displayed_version, contact_lead, setContactLead }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const trip_info = useSelector(store => store.menu.trip_info);
    const user = useSelector(store => store.user.user);
    const travelers = useSelector(store => store.trip.travelers);
    const traveler_groups = useSelector(store => store.trip.traveler_groups);
    const data_trip = useSelector((store) => store.trip.data_trip);

    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));

    const { enqueueSnackbar } = useSnackbar();

    const addressLabel = useRef(null);
    const addressLabelWidth = addressLabel.current ? addressLabel.current.clientWidth : 0;
    const addressDetailsLabel = useRef(null);
    const addressDetailsLabelWidth = addressDetailsLabel.current ? addressDetailsLabel.current.clientWidth : 0;
    const postalCodeLabel = useRef(null);
    const postalCodeLabelWidth = postalCodeLabel.current ? postalCodeLabel.current.clientWidth : 0;
    const cityLabel = useRef(null);
    const cityLabelWidth = cityLabel.current ? cityLabel.current.clientWidth : 0;
    const emailLabel = useRef(null);
    const emailLabelWidth = emailLabel.current ? emailLabel.current.clientWidth : 42;

    const [rooms, setRooms] = useState([]);
    const [withApplicant, setWithApplicant] = useState(false);
    const [tempContactLead, setTempContactLead] = useState(null);
    const [tempTravelers, setTempTravelers] = useState([]);
    const [moreInfo, setMoreInfo] = useState([]);
    const [infoIncomplete, setInfoIncomplete] = useState([]);
    const [frenchCountry, setFrenchCountry] = useState(null);
    const [travelCompanions, setTravelCompanions] = useState([]);
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const has_crm = JSON.parse(localStorage.getItem('config') ?? '{}').has_crm;
    const is_staging = document.location.hostname.split('.')[2] === 'fr' && document.location.hostname !== 'test-aws.facilitatrip.fr';
    const isDisabled = quotation_code === "cercledesvoyages" || quotation_code === 'tropicalementvotre' ? (data_trip !== null && data_trip.status_contract === "CONFIRMED" && data_trip.status_modification === "FIXED_PV") : false;
    useEffect(() => {
        getFrenchCountry();
    }, []);

    useEffect(() =>{
        if (frenchCountry !== null) {
            let keep_more_info = moreInfo.slice();
            let keep_info_incomplete = infoIncomplete.slice();
            createTempTravelers();
            if (keep_more_info.length > 0) {
                setMoreInfo(keep_more_info);
            }
            if (keep_info_incomplete.length > 0) {
                setInfoIncomplete(keep_info_incomplete);
            }
        }
    }, [travelers, rooms, frenchCountry]);

    useEffect(() =>{
        if (travelers.length > 0 && contact_lead !== null) {
            let companion = [];
            let travel_companions = tempContactLead?.travel_companions !== undefined ? tempContactLead.travel_companions : [];
            // let temp_with_applicant = false;
            // if (travelers.some(item => item.temp_lead)) {
            //     temp_with_applicant = true;
            // }
            if (tempContactLead !== null) {
                travelers.map((traveler) => {
                    if (traveler.id !== tempContactLead.id && travel_companions.find((companion) => companion.id === traveler.id) === undefined) {
                        companion.push(traveler.id);
                    }
                });
            }
            setTravelCompanions(companion);
            // setWithApplicant(temp_with_applicant);
        }
    }, [travelers, tempContactLead]);

    useEffect(() => {
        if (frenchCountry !== null) {
            for (version_index = 0; version_index < trip_info.data.length; version_index++) {
                if (trip_info.data[version_index].id === trip_displayed_version) {
                    if (trip_info.data[version_index].contact_traveler !== null) {
                        createTempContactLead(trip_info.data[version_index].contact_traveler_detail);
                    } else {
                        //create new_traveler
                        let { pass_check, headers } = CheckBeforeRequest();
                        let request = {
                            first_name: t("global.first_name"),
                            last_name: t("global.last_name"),
                            birth_date: moment.utc().subtract(5, "years").format("YYYY-MM-DD")
                        };

                        if (pass_check) {
                            axios({
                                method: "POST",
                                headers: headers,
                                url: `${API_HREF}client/${window.id_owner}/travelers/`,
                                data: JSON.stringify(request)
                            }).then(function (response) {
                                createTempContactLead(response.data);
                            });
                        }
                    }
                }
            }
            let temp_rooms = [];
            traveler_groups.map((group) => {
                if (group.default === true) {
                    let sorted_group = Object.assign({}, group);
                    if (sorted_group.travelers_order !== undefined && sorted_group.travelers_order !== null) {
                        sorted_group.travelers_list.sort((a, b) => {
                            return sorted_group.travelers_order.indexOf(a.id) - sorted_group.travelers_order.indexOf(b.id);
                        });
                    }
                    temp_rooms.push(sorted_group);
                }
            });
            setRooms(temp_rooms);
        }
    }, [traveler_groups, frenchCountry]);

    useEffect(() => {
        if (data_trip !== null && tempContactLead !== null) {
            let temp_contact_lead = { ...tempContactLead };
            let for_user = { ...data_trip.for_user };
            if (for_user !== undefined && for_user !== null) {
                temp_contact_lead.travel_companions = for_user.travel_companions;
                setTempContactLead(temp_contact_lead);
            }
        }
    }, [data_trip]);
    const getFrenchCountry = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "GET",
                url: `${API_HREF}iata-countries/?search=fr`,
                headers: headers
            }).then(function (response) {
                response.data.results.map((country) => {
                    if (country.country_code === "FR") {
                        setFrenchCountry(country);
                    }
                });
            });
        }
    };

    const createTempTravelers = () => {
        let end_date = null;
        for (version_index = 0; version_index < trip_info.data.length; version_index++) {
            if (trip_info.data[version_index].id === trip_displayed_version) {
                end_date = trip_info.data[version_index].end_date;
            }
        }
        let travelers_order = traveler_groups.reduce(function (flattenedArray, element) {
            return flattenedArray.concat(element.travelers_order !== undefined && element.travelers_order !== null ? element.travelers_order : []);
        }, []);
        let tmp_traveler = [ ...travelers ];
        if (travelers_order.length !== 0) {
            tmp_traveler = travelers.sort((a, b) => {
                return travelers_order.indexOf(a.id) - travelers_order.indexOf(b.id);
            });
        }
        let temp_travelers = [];
        let numero_adult = 1;
        let numero_teen = 1;
        let numero_child = 1;
        let numero_baby = 1;
        let temp_more_info = [];
        let temp_info_incomplete = [];

        tmp_traveler.map((traveler, traveler_index) =>{
            let temp_traveler = Object.assign({}, traveler);
            let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
            if (age > 12 && age < 18) {
                temp_traveler.age_label = `${t("flight_search.ado")} ${numero_teen} (${age})`;
                numero_teen += 1;
            } else if (age > 2 && age <= 12) {
                temp_traveler.age_label = `${t("flight_search.child")} ${numero_child} (${age})`;
                numero_child += 1;
            } else if (age <= 2) {
                temp_traveler.age_label = `${t("flight_search.baby")} ${numero_baby} (${age})`;
                numero_baby += 1;
            } else if (age >= 18) {
                temp_traveler.age_label = t("flight_search.adult") + " " + numero_adult;
                numero_adult += 1;
            }

            if (rooms.length !== 0) {
                for (room_index = 0; room_index < rooms.length; room_index++) {
                    if (rooms[room_index].travelers.includes(traveler.id)) {
                        temp_traveler.room_label = t("accommodation.room_composition_room") + (room_index + 1);
                    }
                }
            }

            // if (traveler.first_name === t('global.first_name') || traveler.first_name === t("trip.traveler")) {
            //     temp_traveler.first_name = "";
            // }
            // if (traveler.last_name === t('global.last_name') || traveler.last_name.includes(t("global.adult")) || traveler.last_name.includes(t("global.child"))) {
            //     temp_traveler.last_name = "";
            // }

            if (traveler.identity_doc_number === null) {
                temp_traveler.identity_doc_number = "";
            }
            if (traveler.identity_doc_country_emission === null) {
                temp_traveler.identity_doc_country_emission = "";
            }
            if (traveler.country === null) {
                if (traveler.identity_doc_country_emission !== undefined && traveler.identity_doc_country_emission !== null && traveler.identity_doc_country_emission !== "") {
                    temp_traveler.country = traveler.identity_doc_country_emission.name;
                } else {
                    temp_traveler.country = "";
                }
            }
            if (traveler.nationality === null) {
                if (JSON.parse(localStorage.getItem("config")).quotation_code === "verdie") {
                    temp_traveler.nationality = frenchCountry;
                } else if (traveler.identity_doc_country_emission !== undefined && traveler.identity_doc_country_emission !== null) {
                    temp_traveler.nationality = traveler.identity_doc_country_emission;
                } else {
                    temp_traveler.nationality = "";
                }
            }
            if (traveler.email === null) {
                temp_traveler.email = "";
            }

            temp_traveler.temp_phone = temp_traveler.phone_country_code + temp_traveler.phone_number;
            temp_traveler.real_index = traveler_index;

            temp_traveler.temp_lead = false;
            if (contact_lead !== null && temp_traveler.id === contact_lead.id) {
                temp_traveler.temp_lead = true;
                setWithApplicant(true);
                //add variables to tempContactLead
                let temp_contact_lead = Object.assign({}, contact_lead);
                temp_contact_lead.temp_phone = "";
                temp_contact_lead.age_label = temp_traveler.age_label;
                temp_contact_lead.real_index = temp_traveler.real_index;
                createTempContactLead(temp_contact_lead);
            }
            temp_travelers.push(temp_traveler);
            temp_more_info.push(false);
            temp_info_incomplete.push(false);
        });

        // temp_travelers.sort((a, b) => {
        //     let da = new Date(a.birth_date);
        //     let db = new Date(b.birth_date);
        //     return (da - db);
        // });
        setTempTravelers(temp_travelers);
        setMoreInfo(temp_more_info);
        setInfoIncomplete(temp_info_incomplete);
    };

    const handleLeadAddress = (e) => {
        let temp_contact_lead = Object.assign({}, tempContactLead);
        temp_contact_lead.address = e.target.value;
        setTempContactLead(temp_contact_lead);
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === tempContactLead.id) {
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_contact_lead.age_label = age_label;
                temp_contact_lead.room_label = room_label;
                newTravelers[traveler_index] = temp_contact_lead;
                setTempContactLead(temp_contact_lead);
                setTempTravelers(newTravelers);
            }
        }
    };

    const handleLeadAddressDetails = (e) => {
        let temp_contact_lead = Object.assign({}, tempContactLead);
        temp_contact_lead.address_details = e.target.value;
        setTempContactLead(temp_contact_lead);
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === tempContactLead.id) {
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_contact_lead.age_label = age_label;
                temp_contact_lead.room_label = room_label;
                newTravelers[traveler_index] = temp_contact_lead;
                setTempContactLead(temp_contact_lead);
                setTempTravelers(newTravelers);
            }
        }
    };

    const handleLeadPostalCode = (e) => {
        let temp_contact_lead = Object.assign({}, tempContactLead);
        temp_contact_lead.postal_code = e.target.value;
        setTempContactLead(temp_contact_lead);
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === tempContactLead.id) {
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_contact_lead.age_label = age_label;
                temp_contact_lead.room_label = room_label;
                newTravelers[traveler_index] = temp_contact_lead;
                setTempContactLead(temp_contact_lead);
                setTempTravelers(newTravelers);
            }
        }
    };

    const handleLeadCity = (e) => {
        let temp_contact_lead = Object.assign({}, tempContactLead);
        temp_contact_lead.city = e.target.value;
        setTempContactLead(temp_contact_lead);
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === tempContactLead.id) {
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_contact_lead.age_label = age_label;
                temp_contact_lead.room_label = room_label;
                newTravelers[traveler_index] = temp_contact_lead;
                setTempContactLead(temp_contact_lead);
                setTempTravelers(newTravelers);
            }
        }
    };

    const handlePhoneNumber = ((value, country_data, traveler) => {
        let temp_traveler = Object.assign({}, traveler);
        temp_traveler.temp_phone = value;
        //country_data has the country code
        temp_traveler.phone_country_code = "+" + country_data.dialCode;
        temp_traveler.phone_number = value.replace(`+${country_data.dialCode}`, "");
        if (traveler.id === tempContactLead.id) {
            setTempContactLead(temp_traveler);
        }
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let email = tempTravelers[traveler_index].email;
                let newTravelers = tempTravelers.slice();
                temp_traveler.email = email;
                temp_traveler.age_label = age_label;
                temp_traveler.room_label = room_label;
                newTravelers[traveler_index] = temp_traveler;
                setTempTravelers(newTravelers);
            }
        }
    });

    const handleEmail = (e, traveler) => {
        let temp_traveler = Object.assign({}, traveler);
        temp_traveler.email = e.target.value;
        if (traveler.id === tempContactLead.id) {
            setTempContactLead(temp_traveler);
        }
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_traveler.age_label = age_label;
                temp_traveler.room_label = room_label;
                newTravelers[traveler_index] = temp_traveler;
                setTempTravelers(newTravelers);
            }
        }
    };

    const cancelChanges = () => {
        createTempTravelers();
        if (tempTravelers.some(item => item.id === contact_lead.id)) {
            setWithApplicant(true);
        } else {
            setWithApplicant(false);
        }
    };

    const createTempContactLead = (base_contact_lead) => {
        let temp_contact_lead = Object.assign({}, base_contact_lead);
        if (contact_lead === null) {
            setContactLead(temp_contact_lead);
        }
        if (data_trip !== undefined && data_trip !== null) {
            let for_user = { ...data_trip.for_user };
            if (for_user !== undefined && for_user !== null) {
                temp_contact_lead.travel_companions = for_user.travel_companions;
            }
        }
        temp_contact_lead.temp_lead = true;
        if (temp_contact_lead.first_name === t('global.first_name') || temp_contact_lead.first_name === t("trip.traveler")) {
            temp_contact_lead.first_name = "";
        }
        if (temp_contact_lead.last_name === t('global.last_name') || temp_contact_lead.last_name.includes(t("global.adult")) || temp_contact_lead.last_name.includes(t("global.child"))) {
            temp_contact_lead.last_name = "";
        }
        if (temp_contact_lead.identity_doc_number === null) {
            temp_contact_lead.identity_doc_number = "";
        }
        if (temp_contact_lead.identity_doc_country_emission === null) {
            temp_contact_lead.identity_doc_country_emission = "";
        }
        if (temp_contact_lead.country === null) {
            if (temp_contact_lead.identity_doc_country_emission !== undefined && temp_contact_lead.identity_doc_country_emission !== null) {
                temp_contact_lead.country = temp_contact_lead.identity_doc_country_emission.name;
            } else {
                temp_contact_lead.country = "";
            }
        }
        if (temp_contact_lead.nationality === null) {
            if (JSON.parse(localStorage.getItem("config")).quotation_code === "verdie") {
                temp_contact_lead.nationality = frenchCountry;
            } else if (temp_contact_lead.identity_doc_country_emission !== undefined && temp_contact_lead.identity_doc_country_emission !== null) {
                temp_contact_lead.nationality = temp_contact_lead.identity_doc_country_emission;
            } else {
                temp_contact_lead.nationality = "";
            }
        }
        if (temp_contact_lead.email === null) {
            temp_contact_lead.email = "";
        }
        if (temp_contact_lead.city === null) {
            temp_contact_lead.city = "";
        }
        if (temp_contact_lead.postal_code === null) {
            temp_contact_lead.postal_code = "";
        }
        if (temp_contact_lead.address === null) {
            temp_contact_lead.address = "";
        }
        if (temp_contact_lead.address_details === null) {
            temp_contact_lead.address_details = "";
        }

        temp_contact_lead.temp_phone = temp_contact_lead.phone_country_code + temp_contact_lead.phone_number;
        setTempContactLead(Object.assign({}, temp_contact_lead));
    };

    const confirmTempTravelers = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        let temp_info_incomplete = infoIncomplete.slice();

        let default_names_check = CheckDefaultNames(tempTravelers);
        if (!default_names_check) {
            enqueueSnackbar(t("booking.traveler_default_name"), { variant: "error", disableWindowBlurListener: true });
            dispatch(SetChecksPassed(false));
        }

        let identical_traveler_check = CheckTravelerIdentical(tempTravelers);
        if (!identical_traveler_check.check_passed) {
            enqueueSnackbar(t("booking.identical_traveler"), { variant: "error", disableWindowBlurListener: true });
            dispatch(SetChecksPassed(false));
        }
        dispatch(SetSameTravelerID(identical_traveler_check.traveler_identical));

        let characters_check = CheckNamesCharacters(tempTravelers);
        if (!characters_check) {
            enqueueSnackbar(t("booking.traveler_special_char_err"), { variant: "error", disableWindowBlurListener: true });
            dispatch(SetChecksPassed(false));
        }

        let applicant_check = true;
        if (withApplicant && !tempTravelers.some(item => item.id === tempContactLead.id)) {
            applicant_check = false;
            enqueueSnackbar(t("menu.trip_list.no_applicant"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 8000 });
        }

        if (default_names_check && identical_traveler_check.check_passed && pass_check && characters_check && applicant_check) {
            dispatch(SetChecksPassed(true));
            tempTravelers.map((traveler, traveler_index) => {
                temp_info_incomplete[traveler_index] = false;
                let loyalty_cards = [];
                if (traveler.identity_doc_number !== "" && traveler.identity_doc_number !== null) {
                    if (traveler.identity_doc_country_emission === null || traveler.identity_doc_country_emission === "" || traveler.identity_doc_expiration === "" || traveler.identity_doc_expiration === null || traveler.nationality === null || traveler.nationality === "") {
                        enqueueSnackbar(t("booking.passport_issues"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 8000 });
                        temp_info_incomplete[traveler_index] = true;
                    }
                }
                if (traveler.loyalty_cards.length !== 0) {
                    traveler.loyalty_cards.map((card) => {
                        let tmp_card = { ...card };
                        if (tmp_card.product_type === 6 && card.airline) {
                            tmp_card.airline = card.airline.id;
                            card = tmp_card;
                        }
                        loyalty_cards.push(card);
                    });
                }
                if (temp_info_incomplete[traveler_index] === false) {
                    let request = {
                        address: traveler.address,
                        address_details: traveler.address_details,
                        birth_date: window.moment(traveler.birth_date).format('YYYY-MM-DD'),
                        city: traveler.city,
                        country: traveler.country !== undefined && traveler.country !== "" && traveler.country !== null ? traveler.country.id : null,
                        email: traveler.email !== "" ? traveler.email : null,
                        first_name: traveler.first_name !== "" ? traveler.first_name : t("global.first_name"),
                        gender: traveler.gender,
                        identity_doc: traveler.identity_doc,
                        identity_doc_country_emission: traveler.identity_doc_country_emission !== null ? traveler.identity_doc_country_emission.id : null,
                        identity_doc_expiration: traveler.identity_doc_expiration !== "" ? traveler.identity_doc_expiration : null,
                        identity_doc_number: traveler.identity_doc_number !== "" ? traveler.identity_doc_number : null,
                        last_name: traveler.last_name !== "" ? traveler.last_name : t("global.last_name"),
                        loyalty_cards: loyalty_cards,
                        middle_name: traveler.middle_name,
                        nationality: traveler.nationality !== null ? traveler.nationality.id : null,
                        phone_country_code: traveler.phone_country_code,
                        phone_number: traveler.phone_number,
                        postal_code: traveler.postal_code,
                        title: traveler.title,
                        traveler_relationship: traveler.traveler_relationship,
                        trip_reason: traveler.trip_reason
                    };
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/travelers/${traveler.id}/`,
                        headers: headers,
                        data: JSON.stringify(request)
                    }).then(function (response) {
                        enqueueSnackbar(t("menu.trip_list.traveler") + ` ${traveler.age_label} ` + t("menu.trip_list.success_change_travelers"), { variant: "success", disableWindowBlurListener: true, autoHideDuration: 3500 });
                        dispatch(EditTraveler(response.data, traveler.real_index));
                        if (traveler.temp_lead === true) {
                            /** SAVE USER OR UPDATE */
                            console.log('CONFIRMTRAV', data_trip);
                            if (data_trip.for_user) {
                                axios({
                                    method: "GET",
                                    headers: headers,
                                    url: `${API_HREF}client/${window.id_owner}/user/${data_trip.for_user.id}/`
                                }).then(function (res) {
                                    let travelers = [];
                                    if (res.data.travlers?.length) {
                                        travelers = res.data.travlers;
                                    }
                                    if (!travelers.includes(response.data.id)) travelers.push(response.data.id);
                                    axios({
                                        method: "PATCH",
                                        headers: headers,
                                        url: `${API_HREF}client/${window.id_owner}/user/${data_trip.for_user.id}/`,
                                        data: {
                                            travelers,
                                            travel_companions: travelCompanions
                                        }
                                    }).then(function (respo) {
                                        if (respo.data) {
                                            console.log("traveler patched");
                                        }
                                    }).catch(function (error) {
                                        console.log('error:', error);
                                    });
                                }).catch(function (error) {
                                    console.log('error:', error);
                                });
                            }
                            // axios({
                            //     method: "GET",
                            //     headers: headers,
                            //     url: `${API_HREF}client/${window.id_owner}/user/?linked_to_trip=${trip_info.id}/`
                            // }).then(function (res) {
                            //     if(res.data){
                            //         console.log('res.data', res.data);
                            //     }
                            // }).catch(function (error) {
                            //     console.log('error:', error);
                            // });
                            /** ****************** */

                            let temp_contact_lead = Object.assign({}, response.data);
                            setContactLead(response.data);
                            temp_contact_lead.temp_phone = "";
                            temp_contact_lead.age_label = traveler.age_label;
                            temp_contact_lead.real_index = traveler.real_index;
                            createTempContactLead(temp_contact_lead);
                        }

                        let temp_rooms = rooms.slice();
                        temp_rooms.map((room, room_index) => {
                            let temp_travelers_list = room.travelers_list.slice();
                            temp_travelers_list.map((room_traveler, room_traveler_index) => {
                                if (room_traveler.id === response.data.id) {
                                    temp_travelers_list[room_traveler_index] = Object.assign({}, response.data);
                                }
                            });
                            temp_rooms[room_index].travelers_list = temp_travelers_list;
                        });
                        setRooms(temp_rooms);
                    }).catch(function (error) {
                        console.log("error", error);
                        if (error.response.status === 409 && error.response.data.detail !== undefined) {
                            enqueueSnackbar(error.response.data.detail, { variant: "error", disableWindowBlurListener: true, autoHideDuration: 6000 });
                        } else {
                            enqueueSnackbar(t("menu.trip_list.traveler") + ` ${traveler.age_label} ` + t("menu.trip_list.failed_change_travelers"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 3500 });
                        }
                    });
                }
            });
            setInfoIncomplete(temp_info_incomplete);
            if (withApplicant === false) {
                let request = {
                    address: tempContactLead.address,
                    address_details: tempContactLead.address_details,
                    birth_date: tempContactLead.birth_date,
                    city: tempContactLead.city,
                    country: tempContactLead.country ? tempContactLead.country.id : null,
                    email: tempContactLead.email !== "" ? tempContactLead.email : null,
                    first_name: tempContactLead.first_name !== "" ? tempContactLead.first_name : t("global.first_name"),
                    gender: tempContactLead.gender,
                    identity_doc: tempContactLead.identity_doc,
                    identity_doc_country_emission: tempContactLead.identity_doc_country_emission !== null ? tempContactLead.identity_doc_country_emission.id : null,
                    identity_doc_expiration: tempContactLead.identity_doc_expiration !== "" ? tempContactLead.identity_doc_expiration : null,
                    identity_doc_number: tempContactLead.identity_doc_number !== "" ? tempContactLead.identity_doc_number : null,
                    last_name: tempContactLead.last_name !== "" ? tempContactLead.last_name : t("global.last_name"),
                    loyalty_cards: tempContactLead.loyalty_cards,
                    middle_name: tempContactLead.middle_name,
                    nationality: tempContactLead.nationality !== null ? tempContactLead.nationality.id : null,
                    phone_country_code: tempContactLead.phone_country_code,
                    phone_number: tempContactLead.phone_number,
                    postal_code: tempContactLead.postal_code,
                    title: tempContactLead.title,
                    traveler_relationship: tempContactLead.traveler_relationship,
                    trip_reason: tempContactLead.trip_reason
                };
                if (tempContactLead.id !== 0) {
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/travelers/${tempContactLead.id}/`,
                        headers: headers,
                        data: JSON.stringify(request)
                    }).then(function (response) {
                        enqueueSnackbar(t("global.the") + " " + t("menu.trip_list.applicant") + " " + t("menu.trip_list.success_change_travelers"), { variant: "success", disableWindowBlurListener: true, autoHideDuration: 3500 });
                        setTempContactLead(response.data);
                        setContactLead(response.data);
                    }).catch(function (error) {
                        console.log(error.response);
                        if (error.response.status === 409 && error.response.data.detail !== undefined) {
                            enqueueSnackbar(error.response.data.detail, { variant: "error", disableWindowBlurListener: true, autoHideDuration: 6000 });
                        } else {
                            enqueueSnackbar(t("global.the") + " " + t("menu.trip_list.applicant") + " " + t("menu.trip_list.failed_change_travelers"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 3500 });
                        }
                    });
                } else {
                    axios({
                        method: "POST",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/travelers/`,
                        data: JSON.stringify(request)
                    }).then(function (response) {
                        enqueueSnackbar(t("global.the") + " " + t("menu.trip_list.applicant") + " " + t("menu.trip_list.success_change_travelers"), { variant: "success", disableWindowBlurListener: true, autoHideDuration: 3500 });
                        setTempContactLead(response.data);
                        setContactLead(response.data);
                    }).catch(function (error) {
                        console.log(error.response);
                        if (error.response.status === 409 && error.response.data.detail !== undefined) {
                            enqueueSnackbar(error.response.data.detail, { variant: "error", disableWindowBlurListener: true, autoHideDuration: 6000 });
                        } else {
                            enqueueSnackbar(t("global.the") + " " + t("menu.trip_list.applicant") + " " + t("menu.trip_list.failed_change_travelers"), { variant: "error", disableWindowBlurListener: true, autoHideDuration: 3500 });
                        }
                    });
                }
            }
        }
    };

    const handleWithApplicant = () => {
        let temp_new_lead = Object.assign({}, tempContactLead);
        temp_new_lead.id = 0;
        temp_new_lead.first_name = temp_new_lead.first_name !== null ? temp_new_lead.first_name : "";
        temp_new_lead.last_name = temp_new_lead.last_name !== null ? temp_new_lead.last_name : "";
        setTempContactLead(temp_new_lead);
        if (withApplicant) {
            let temp_travelers = [];
            for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
                let temp_traveler = Object.assign({}, tempTravelers[traveler_index]);
                temp_traveler.temp_lead = false;
                temp_travelers.push(temp_traveler);
            }
            setTempTravelers(temp_travelers);
        }
        setWithApplicant((prevState) => !prevState);
    };

    const changeApplicant = (traveler, traveler_index) => {
        let temp_travelers = tempTravelers.slice();
        for (parse_traveler_index = 0; parse_traveler_index < temp_travelers.length; parse_traveler_index++) {
            if (temp_travelers[parse_traveler_index].id === tempContactLead.id && temp_travelers[parse_traveler_index].temp_lead === true) {
                let contact_traveler = Object.assign({}, temp_travelers[parse_traveler_index]);
                contact_traveler.temp_lead = false;
                temp_travelers[parse_traveler_index] = contact_traveler;
            }
        }
        let new_traveler = Object.assign({}, traveler);

        new_traveler.temp_lead = true;
        // new_traveler.id = traveler.id;
        // new_traveler.room_label = traveler.room_label;
        // new_traveler.age_label = traveler.age_label;
        // new_traveler.title = traveler.title;
        // new_traveler.first_name = tempContactLead.first_name !== "" ? tempContactLead.first_name : traveler.first_name;
        // new_traveler.last_name = tempContactLead.last_name !== "" ? tempContactLead.last_name : traveler.last_name;
        // new_traveler.birth_date = tempContactLead.birth_date !== "" ? tempContactLead.birth_date : traveler.birth_date;
        // new_traveler.nationality = tempContactLead.nationality !== null ? tempContactLead.nationality : traveler.nationality;
        // new_traveler.temp_phone = tempContactLead.phone_country_code !== null && tempContactLead.phone_number !== null ? tempContactLead.temp_phone : traveler.temp_phone;
        // new_traveler.phone_country_code = tempContactLead.phone_country_code !== null ? tempContactLead.phone_country_code : traveler.phone_country_code;
        // new_traveler.phone_number = tempContactLead.phone_number !== null ? tempContactLead.phone_number : traveler.phone_number;
        // new_traveler.email = tempContactLead.email !== "" ? tempContactLead.email : traveler.email;
        // new_traveler.identity_doc = traveler.identity_doc;
        // new_traveler.identity_doc_country_emission = traveler.identity_doc_country_emission;
        // new_traveler.identity_doc_expiration = traveler.identity_doc_expiration;
        // new_traveler.identity_doc_number = traveler.identity_doc_number;
        // new_traveler.loyalty_cards = traveler.loyalty_cards;
        // new_traveler.address = tempContactLead.address;
        // new_traveler.address_details = tempContactLead.address_details;
        // new_traveler.postal_code = tempContactLead.postal_code;
        // new_traveler.city = tempContactLead.city;
        // new_traveler.country = tempContactLead.country;
        // new_traveler.real_index = traveler.real_index;

        setTempContactLead(new_traveler);
        temp_travelers[traveler_index] = new_traveler;
        setTempTravelers(temp_travelers);
    };
    return (
        <Grid>
            <Grid style={{ padding: "20px 0px" }} >
                <div className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${!downDesktop ? classes.fontSize24 : classes.fontSize20} ${classes.letterSpacing}`}>
                    { t("menu.trip_list.travelers_info") }
                </div>
                <Grid container alignItems="center" justify={!downDesktop ? "flex-end" : "center"} style={ !downDesktop ? {} : { paddingTop: 8 }}>
                    <Grid item>
                        <Button variant="contained" className={classes.cancelButton} size="small" onClick={cancelChanges}>
                            {t("global.cancel")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" className={classes.validateButton} onClick={() => {
                            confirmTempTravelers();
                        }} size="small">
                            {t("global.validate")}
                        </Button>
                    </Grid>
                </Grid>
                {
                    rooms.length !== 0 && (
                        <Grid className={`${classes.genericBorder} ${classes.genericPadding} ${classes.genericMargin}`}>
                            <div className={`${!downDesktop ? "" : classes.smallPaddingTop} ${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize18 : classes.fontSize16}`} style={!downDesktop ? {} : { marginBottom: 5 }}>
                                { t("accommodation.room_composition") }
                            </div>
                            <Grid container alignItems="flex-start" justify="flex-start" direction={!downDesktop ? "row" : "column"}spacing={5}>
                                {
                                    tempTravelers.length !== 0 && rooms.map((room, room_index) =>{
                                        return (
                                            <Grid item key={room_index}>
                                                <Grid className={`${!downDesktop ? classes.spacer : ""} ${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize14 : classes.fontSize12}`}>
                                                    { t("accommodation.room_composition_room") + (room_index + 1)}
                                                </Grid>
                                                {
                                                    room.travelers_list.map((traveler) =>{
                                                        if (traveler.first_name === t("global.first_name") || traveler.first_name === t("trip.traveler") || traveler.last_name === t('global.last_name') || traveler.last_name.includes(t("global.adult")) || traveler.last_name.includes(t("global.child"))) {
                                                            let age_label = "";
                                                            for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
                                                                if (tempTravelers[traveler_index].id === traveler.id) {
                                                                    age_label = tempTravelers[traveler_index].age_label;
                                                                    return (
                                                                        <Grid key={traveler.id} className={`${classes.genericText} ${classes.fontWeight400} ${!downDesktop ? classes.fontSize12 : classes.fontSize10}`} style={{ marginBottom: 5 }}>
                                                                            {age_label}
                                                                        </Grid>
                                                                    );
                                                                }
                                                            }
                                                        } else {
                                                            return (
                                                                <Grid key={traveler.id} className={`${classes.genericText} ${classes.fontWeight400} ${!downDesktop ? classes.fontSize12 : classes.fontSize10}`} style={{ marginBottom: 5 }}>
                                                                    {t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name}
                                                                </Grid>
                                                            );
                                                        }
                                                    })
                                                }
                                            </Grid>
                                        );
                                    })
                                }
                            </Grid>
                        </Grid>
                    )
                }
                <Grid className={classes.genericBorder}>
                    <Grid container className={classes.genericPadding} alignItems="center" justify="space-between">
                        <Grid item className={`${!downDesktop ? "" : classes.smallPaddingTop} ${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize18 : classes.fontSize16}`}>
                            { t("menu.trip_list.applicant") }
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={ <CheckBox disabled={isDisabled} checked={withApplicant} onChange={() => handleWithApplicant()} /> }
                                label={ t("home.set_first_lead") }
                                classes={{ label: `${classes.genericText} ${classes.fontWeight900} ${!downDesktop ? classes.fontSize12 : classes.fontSize10}` }}
                                className={`${classes.noMarginBottom} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12}`}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.titleBorder} />
                    {
                        tempContactLead !== null && (
                            <Grid className={classes.genericPadding} style={!downDesktop ? {} : { marginBottom: 8 }}>
                                <Grid className={`${classes.spacer} ${classes.textLeft} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                    { t("menu.trip_list.identity") }
                                </Grid>
                                {
                                    (!is_staging || !!has_crm) &&
                                    <CrmTraveler setTempContactLead={setTempContactLead} tempContactLead={tempContactLead} traveler={tempContactLead} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} contactOnly={true}/>
                                }
                                <TravelerInfo tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} traveler={tempContactLead} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={true} redFormControl={false} nationality_option={true} size={"medium"} isFrom={"tripList"} />
                                <Grid className={`${classes.spacer} ${classes.separator} ${classes.textLeft} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`} style={{ marginTop: 8 }}>
                                    { t("menu.trip_list.living_place") }
                                </Grid>
                                {
                                    !downDesktop && (
                                        <Grid>
                                            <Grid container alignItems="center" justify="space-between" className={classes.spacer} spacing={4} >
                                                <Grid item xs={6}>
                                                    <FormControl variant="outlined" className={classes.formControlRoot1}>
                                                        <InputLabel id="address-label" ref={addressLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.address")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            disabled={isDisabled}
                                                            name="address"
                                                            id="address-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={addressLabelWidth}
                                                            value={tempContactLead.address ? tempContactLead.address : ""}
                                                            onChange={handleLeadAddress}
                                                            autoComplete="off"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6} className={classes.textRight}>
                                                    <FormControl variant="outlined" className={classes.formControlRoot1}>
                                                        <InputLabel id="address_complementary-label" ref={addressDetailsLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.address_complementary")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            disabled={isDisabled}
                                                            name="address_complementary"
                                                            id="address_complementary-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={addressDetailsLabelWidth}
                                                            value={tempContactLead.address_details ? tempContactLead.address_details : ""}
                                                            onChange={handleLeadAddressDetails}
                                                            autoComplete="off"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container alignItems="center" justify="space-between" className={classes.spacer} spacing={4}>
                                                <Grid item xs={2}>
                                                    <FormControl variant="outlined" className={classes.formControlRoot1}>
                                                        <InputLabel id="postal-code-label" ref={postalCodeLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.postal_code")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            disabled={isDisabled}
                                                            name="postal-code"
                                                            id="postal-code-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={postalCodeLabelWidth}
                                                            value={tempContactLead.postal_code ? tempContactLead.postal_code : ""}
                                                            onChange={handleLeadPostalCode}
                                                            autoComplete="off"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <FormControl variant="outlined" className={classes.formControlRoot1}>
                                                        <InputLabel id="city-label" ref={cityLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.city")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            disabled={isDisabled}
                                                            name="city"
                                                            id="city-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={cityLabelWidth}
                                                            value={tempContactLead.city ? tempContactLead.city : ""}
                                                            onChange={handleLeadCity}
                                                            autoComplete="off"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={5} className={classes.textRight}>
                                                    <CountrySearch label={t("menu.profile.country")} traveler={tempContactLead} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={true} mode={"country"} redFormControl={false} size={"medium"} isFrom={"tripList"} />
                                                </Grid>
                                            </Grid>
                                            <Grid container alignItems="center" justify="space-between" className={classes.spacer} style={{ paddingBottom: 16 }} spacing={4}>
                                                <Grid item xs={3}>
                                                    <MuiPhoneNumber
                                                        disabled={isDisabled}
                                                        defaultCountry={tempContactLead.nationality !== null && tempContactLead.nationality !== "" ? tempContactLead.nationality.country_code.toLowerCase() : (user.lang !== null && user.lang !== "en") ? user.lang.split('-')[0] : "gb" }
                                                        value={tempContactLead.temp_phone}
                                                        className={classes.formControlRoot1}
                                                        onChange={(value, country_data) => {
                                                            handlePhoneNumber(value, country_data, tempContactLead);
                                                        }}
                                                        variant="outlined"
                                                        label={t("global.phone")}
                                                        InputProps={{ className: classes.circleBorder }}
                                                        disableAreaCodes/>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <FormControl variant="outlined" className={classes.formControlRoot1}>
                                                        <InputLabel id="email-label-lead" ref={emailLabel} className={classes.fontSize16}>
                                                            {t("global.email")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="email"
                                                            id="email-input-lead"
                                                            disabled={isDisabled}
                                                            className={classes.circleBorder}
                                                            labelWidth={emailLabelWidth}
                                                            value={tempContactLead.email ? tempContactLead.email : ""}
                                                            onChange={((event) => {
                                                                handleEmail(event, tempContactLead);
                                                            })}
                                                            autoComplete="off"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {
                                    downDesktop && (
                                        <Grid>
                                            <Grid container alignItems="flex-start" justify="space-between" direction="column" className={classes.spacer} spacing={2} >
                                                <Grid item style={{ width: "100%" }}>
                                                    <FormControl variant="outlined" className={classes.formControlRootMobile1}>
                                                        <InputLabel id="address-label" ref={addressLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.address")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            disabled={isDisabled}
                                                            name="address"
                                                            id="address-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={addressLabelWidth}
                                                            value={tempContactLead.address ? tempContactLead.address : ""}
                                                            onChange={handleLeadAddress}
                                                            autoComplete="off"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item style={{ width: "100%" }}>
                                                    <FormControl variant="outlined" className={classes.formControlRootMobile1}>
                                                        <InputLabel id="address_complementary-label" ref={addressDetailsLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.address_complementary")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            disabled={isDisabled}
                                                            name="address_complementary"
                                                            id="address_complementary-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={addressDetailsLabelWidth}
                                                            value={tempContactLead.address_details ? tempContactLead.address_details : ""}
                                                            onChange={handleLeadAddressDetails}
                                                            autoComplete="off"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container alignItems="center" justify="space-between" className={classes.spacer} spacing={1}>
                                                    <Grid item xs={5}>
                                                        <FormControl variant="outlined" className={classes.formControlRootMobile1}>
                                                            <InputLabel id="postal-code-label" ref={postalCodeLabel} className={classes.fontSize12}>
                                                                {t("menu.profile.postal_code")}
                                                            </InputLabel>
                                                            <OutlinedInput
                                                                disabled={isDisabled}
                                                                name="postal-code"
                                                                id="postal-code-input"
                                                                className={classes.circleBorder}
                                                                labelWidth={postalCodeLabelWidth}
                                                                value={tempContactLead.postal_code ? tempContactLead.postal_code : ""}
                                                                onChange={handleLeadPostalCode}
                                                                autoComplete="off"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <FormControl variant="outlined" className={classes.formControlRootMobile1}>
                                                            <InputLabel id="city-label" ref={cityLabel} className={classes.fontSize12}>
                                                                {t("menu.profile.city")}
                                                            </InputLabel>
                                                            <OutlinedInput
                                                                disabled={isDisabled}
                                                                name="city"
                                                                id="city-input"
                                                                className={classes.circleBorder}
                                                                labelWidth={cityLabelWidth}
                                                                value={tempContactLead.city ? tempContactLead.city : ""}
                                                                onChange={handleLeadCity}
                                                                autoComplete="off"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.spacer}>
                                                <CountrySearch label={t("menu.profile.country")} traveler={tempContactLead} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={true} mode={"country"} redFormControl={false}/>
                                            </Grid>
                                            <Grid item className={classes.spacer}>
                                                <MuiPhoneNumber
                                                    defaultCountry={tempContactLead.nationality !== null && tempContactLead.nationality !== "" ? tempContactLead.nationality.country_code.toLowerCase() : (user.lang !== null && user.lang !== "en") ? user.lang.split('-')[0] : "gb" }
                                                    value={tempContactLead.temp_phone}
                                                    className={classes.formControlRootMobile1}
                                                    onChange={(value, country_data) => {
                                                        handlePhoneNumber(value, country_data, tempContactLead);
                                                    }}
                                                    variant="outlined"
                                                    label={t("global.phone")}
                                                    InputProps={{ className: classes.circleBorder }}
                                                    disabled={isDisabled}
                                                    disableAreaCodes/>
                                            </Grid>
                                            <Grid item className={classes.spacer}>
                                                <FormControl variant="outlined" className={classes.formControlRootMobile1}>
                                                    <InputLabel id="email-label" ref={emailLabel} className={classes.fontSize16}>
                                                        {t("global.email")}
                                                    </InputLabel>
                                                    <OutlinedInput
                                                        name="email"
                                                        id="email-input"
                                                        disabled={isDisabled}
                                                        className={classes.circleBorder}
                                                        labelWidth={emailLabelWidth}
                                                        value={tempContactLead.email ? tempContactLead.email : ""}
                                                        onChange={((event) => {
                                                            handleEmail(event, tempContactLead);
                                                        })}
                                                        autoComplete="off"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        )
                    }
                </Grid>
                {
                    tempContactLead !== null && tempTravelers.map((traveler, traveler_index) =>{
                        let is_applicant = (withApplicant === true) && (traveler.id === tempContactLead.id);
                        let open_more_info = moreInfo[traveler_index];
                        return (
                            <Grid key={traveler_index} className={`${classes.genericBorder} ${classes.genericMargin}`}>
                                <Grid className={classes.genericPadding} container alignItems="center" justify="space-between">
                                    <Grid item className={`${!downDesktop ? "" : classes.smallPaddingTop} ${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize18 : classes.fontSize16}`} style={!downDesktop ? { padding: "12px 0px" } : {}}>
                                        { t("trip.traveler") + " " + t("global.number") + (traveler_index + 1) }
                                    </Grid>
                                    {
                                        withApplicant && (
                                            <Grid item style={!downDesktop ? { paddingRight: 32 } : {}}>
                                                <FormControlLabel
                                                    control={ <CheckBox disabled={isDisabled} checked={is_applicant} onChange={() => {
                                                        changeApplicant(traveler, traveler_index);
                                                    }}/>
                                                    }
                                                    label={ t("menu.trip_list.traveler_is_applicant") }
                                                    classes={{ label: `${classes.genericText} ${classes.fontWeight900} ${!downDesktop ? classes.fontSize12 : classes.fontSize10}` }}
                                                    className={`${classes.noMarginBottom} ${classes.genericText} ${classes.fontWeight900} ${!downDesktop ? classes.fontSize12 : classes.fontSize10}`}
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <div className={classes.titleBorder} style={{ marginBottom: 10 }} />
                                <Grid className={classes.genericPadding}>
                                    <Grid className={`${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize14 : classes.fontSize12} ${classes.letterSpacing}`}>
                                        {traveler.age_label}
                                    </Grid>
                                    <div className={`${classes.genericText} ${classes.fontWeight400} ${!downDesktop ? classes.fontSize12 : classes.fontSize10}`} style={!downDesktop ? {} : { marginBottom: 8 }}>
                                        { t("menu.trip_list.affected_room") + " " }
                                        <span className={`${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize14 : classes.fontSize12} ${classes.letterSpacing}`} style={!downDesktop ? { paddingLeft: 10 } : {}}>
                                            {traveler.room_label}
                                        </span>
                                    </div>
                                    {
                                        !is_applicant && (!is_staging || !!has_crm) &&
                                        <CrmTraveler setTempContactLead={setTempContactLead} tempContactLead={tempContactLead} traveler={traveler} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} contactOnly={false}/>
                                    }
                                    <TravelerInfo tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} traveler={traveler} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={false} redFormControl={infoIncomplete[traveler_index]} nationality_option={true} size={"medium"} isFrom={"tripList"}/>
                                    {
                                        downDesktop && (
                                            <TravelerMoreInfoMobile traveler={traveler} traveler_index={traveler_index} moreInfo={moreInfo} setMoreInfo={setMoreInfo} open_more_info={open_more_info} infoIncomplete={infoIncomplete} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} size={"medium"} isFrom={"tripList"} />
                                        )
                                    }
                                    {
                                        !downDesktop && (
                                            <TravelerMoreInfo traveler={traveler} traveler_index={traveler_index} moreInfo={moreInfo} setMoreInfo={setMoreInfo} open_more_info={open_more_info} infoIncomplete={infoIncomplete} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} size={"medium"} isFrom={"tripList"} />
                                        )
                                    }
                                </Grid>
                            </Grid>
                        );
                    })
                }
                <Grid container alignItems="center" justify="flex-end">
                    <Grid item>
                        <Button variant="contained" className={classes.cancelButton} size="small" onClick={cancelChanges}>
                            {t("global.cancel")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" className={classes.validateButton} onClick={() => {
                            confirmTempTravelers();
                        }} size="small">
                            {t("global.validate")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

export default React.memo(TripListTravelersInfos);
