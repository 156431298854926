import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Tab,
    Tabs,
    TextField
} from "@mui/material";
import { CartPackageProviderPicker } from "./CartPackageProviderPicker";
import { ProviderQuotationCurrencyPicker } from "../ProviderQuotation/ProviderQuotationCurrencyPicker";
import { PackageInput } from "./CartPackagePackagesList";
import { Provider } from "../Common/objects/provider";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    stackNumber: number | null,
    stackInfoId: number | null,
    package: PackageInput[number] | undefined,
    onChangePackages: React.Dispatch<React.SetStateAction<PackageInput>>
}

enum PriceTab {
    TERRESTRIAL_PRICE,
    FLIGHT_PRICE,
}

export function CartPackageItemInfo(props: Props): JSX.Element {
    const { t } = useTranslation();
    const currencies = useSelector((state: AppState) => state.trip.currencies);
    const [tab, setTab] = useState<PriceTab>(PriceTab.TERRESTRIAL_PRICE);

    const onChangeProvider = (provider: Provider | null) => {
        props.onChangePackages((state) => {
            const key = `${props.stackNumber ?? ''}-${props.stackInfoId ?? ''}`;
            const item = state[key];

            if (item) {
                return {
                    ...state,
                    [key]: {
                        ...item,
                        provider
                    }
                };
            }

            return state;
        });
    };

    const onChangeCurrency = (
        tab: PriceTab,
        currency: number | null
    ) => {
        props.onChangePackages((state) => {
            const key = `${props.stackNumber ?? ''}-${props.stackInfoId ?? ''}`;
            const item = state[key];

            if (item) {
                return {
                    ...state,
                    [key]: {
                        ...item,
                        flightPrice: tab === PriceTab.FLIGHT_PRICE ?
                            {
                                ...item.flightPrice,
                                currency: currency ?? 47
                            } :
                            item.flightPrice,
                        terrestrialPrice: tab === PriceTab.TERRESTRIAL_PRICE ?
                            {
                                ...item.terrestrialPrice,
                                currency: currency ?? 47
                            } :
                            item.terrestrialPrice
                    }
                };
            }

            return state;
        });
    };

    const onChangeMarginType = (
        tab: PriceTab,
        type: 'PER' | 'FIX'
    ) => {
        props.onChangePackages((state) => {
            const key = `${props.stackNumber ?? ''}-${props.stackInfoId ?? ''}`;
            const item = state[key];

            if (item) {
                return {
                    ...state,
                    [key]: {
                        ...item,
                        flightPrice: tab === PriceTab.FLIGHT_PRICE ?
                            {
                                ...item.flightPrice,
                                marginType: type
                            } :
                            item.flightPrice,
                        terrestrialPrice: tab === PriceTab.TERRESTRIAL_PRICE ?
                            {
                                ...item.terrestrialPrice,
                                marginType: type
                            } :
                            item.terrestrialPrice
                    }
                };
            }

            return state;
        });
    };

    const onChangePrice = (
        tab: PriceTab,
        price: string
    ) => {
        props.onChangePackages((state) => {
            const key = `${props.stackNumber ?? ''}-${props.stackInfoId ?? ''}`;
            const item = state[key];
            const value = parseFloat(price);

            if (item) {
                return {
                    ...state,
                    [key]: {
                        ...item,
                        flightPrice: tab === PriceTab.FLIGHT_PRICE ?
                            {
                                ...item.flightPrice,
                                price: isNaN(value) ? 0 : value
                            } :
                            item.flightPrice,
                        terrestrialPrice: tab === PriceTab.TERRESTRIAL_PRICE ?
                            {
                                ...item.terrestrialPrice,
                                price: isNaN(value) ? 0 : value
                            } :
                            item.terrestrialPrice
                    }
                };
            }

            return state;
        });
    };

    const onChangeMargin = (
        tab: PriceTab,
        price: string
    ) => {
        props.onChangePackages((state) => {
            const key = `${props.stackNumber ?? ''}-${props.stackInfoId ?? ''}`;
            const item = state[key];
            const value = parseFloat(price);

            if (item) {
                return {
                    ...state,
                    [key]: {
                        ...item,
                        flightPrice: tab === PriceTab.FLIGHT_PRICE ?
                            {
                                ...item.flightPrice,
                                margin: isNaN(value) ? 0 : value
                            } :
                            item.flightPrice,
                        terrestrialPrice: tab === PriceTab.TERRESTRIAL_PRICE ?
                            {
                                ...item.terrestrialPrice,
                                margin: isNaN(value) ? 0 : value
                            } :
                            item.terrestrialPrice
                    }
                };
            }

            return state;
        });
    };

    return (
        <>
            <Box sx={{ marginBottom: 1 }}>
                <CartPackageProviderPicker
                    value={props.package?.provider ?? null}
                    onChange={onChangeProvider}
                />
            </Box>
            <Tabs
                value={tab}
                sx={{ marginBottom: 2.5 }}
                onChange={(_, value) => setTab(value)}
            >
                <Tab
                    label={t<string>('cart-material.cart-construction-package-terrestrial-price')}
                    value={PriceTab.TERRESTRIAL_PRICE}
                />
                <Tab
                    label={t<string>('cart-material.cart-construction-package-flight-price')}
                    value={PriceTab.FLIGHT_PRICE}
                    disabled={
                        (props.package?.products ?? []).findIndex((item) => {
                            return item.type === 'flights' || item.type === 'manual-flights';
                        }) < 0
                    }
                />
            </Tabs>
            {
                tab === PriceTab.TERRESTRIAL_PRICE &&
                <Stack spacing={1.5}>
                    <ProviderQuotationCurrencyPicker
                        label={t<string>('cart-material.cart-construction-package-purchase-currency')}
                        value={props.package?.terrestrialPrice.currency ?? null}
                        onChange={(value) => onChangeCurrency(PriceTab.TERRESTRIAL_PRICE, value)}
                    />
                    <FormControl size="small" fullWidth>
                        <InputLabel>
                            {t<string>('cart-material.cart-construction-package-margin-type')}
                        </InputLabel>
                        <Select
                            label={t<string>('cart-material.cart-construction-package-margin-type')}
                            value={props.package?.terrestrialPrice.marginType ?? ''}
                            onChange={(event) => onChangeMarginType(
                                PriceTab.TERRESTRIAL_PRICE,
                                event.target.value as 'PER' | 'FIX'
                            )}
                        >
                            <MenuItem value="FIX">
                                {t<string>('cart-material.cart-construction-package-margin-fix')}
                            </MenuItem>
                            <MenuItem value="PER">
                                {t<string>('cart-material.cart-construction-package-margin-percent')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Stack direction="row" spacing={1.5}>
                        <TextField
                            type="number"
                            size="small"
                            label={t<string>('cart-material.cart-construction-package-purchase-price')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            currencies?.find((item) => {
                                                return item.id === props.package?.terrestrialPrice.currency;
                                            })?.symbol
                                        }
                                    </InputAdornment>
                                )
                            }}
                            value={props.package?.terrestrialPrice.price.toString() ?? '0'}
                            onChange={(event) => onChangePrice(PriceTab.TERRESTRIAL_PRICE, event.target.value)}
                        />
                        <TextField
                            type="number"
                            size="small"
                            label={t<string>('cart-material.cart-construction-package-margin')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            props.package?.terrestrialPrice.marginType === 'FIX' &&
                                            currencies?.find((item) => {
                                                return item.id === props.package?.terrestrialPrice.currency;
                                            })?.symbol
                                        }
                                        {
                                            props.package?.terrestrialPrice.marginType === 'PER' &&
                                            '%'
                                        }
                                    </InputAdornment>
                                )
                            }}
                            value={props.package?.terrestrialPrice.margin.toString() ?? '0'}
                            onChange={(event) => onChangeMargin(PriceTab.TERRESTRIAL_PRICE, event.target.value)}
                        />
                    </Stack>
                </Stack>
            }
            {
                tab === PriceTab.FLIGHT_PRICE &&
                <Stack spacing={1.5}>
                    <ProviderQuotationCurrencyPicker
                        label={t<string>('cart-material.cart-construction-package-purchase-currency')}
                        value={props.package?.flightPrice.currency ?? null}
                        onChange={(value) => onChangeCurrency(PriceTab.FLIGHT_PRICE, value)}
                    />
                    <FormControl size="small" fullWidth>
                        <InputLabel>
                            {t<string>('cart-material.cart-construction-package-margin-type')}
                        </InputLabel>
                        <Select
                            label={t<string>('cart-material.cart-construction-package-margin-type')}
                            value={props.package?.flightPrice.marginType ?? ''}
                            onChange={(event) => onChangeMarginType(
                                PriceTab.FLIGHT_PRICE,
                                event.target.value as 'PER' | 'FIX'
                            )}
                        >
                            <MenuItem value="FIX">
                                {t<string>('cart-material.cart-construction-package-margin-fix')}
                            </MenuItem>
                            <MenuItem value="PER">
                                {t<string>('cart-material.cart-construction-package-margin-percent')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Stack direction="row" spacing={1.5}>
                        <TextField
                            type="number"
                            size="small"
                            label={t<string>('cart-material.cart-construction-package-purchase-price')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            currencies?.find((item) => {
                                                return item.id === props.package?.flightPrice.currency;
                                            })?.symbol
                                        }
                                    </InputAdornment>
                                )
                            }}
                            value={props.package?.flightPrice.price.toString() ?? '0'}
                            onChange={(event) => onChangePrice(PriceTab.FLIGHT_PRICE, event.target.value)}
                        />
                        <TextField
                            type="number"
                            size="small"
                            label={t<string>('cart-material.cart-construction-package-margin')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            props.package?.flightPrice.marginType === 'FIX' &&
                                            currencies?.find((item) => {
                                                return item.id === props.package?.flightPrice.currency;
                                            })?.symbol
                                        }
                                        {
                                            props.package?.flightPrice.marginType === 'PER' &&
                                            '%'
                                        }
                                    </InputAdornment>
                                )
                            }}
                            value={props.package?.flightPrice.margin.toString() ?? '0'}
                            onChange={(event) => onChangeMargin(PriceTab.FLIGHT_PRICE, event.target.value)}
                        />
                    </Stack>
                </Stack>
            }
        </>
    );
}
