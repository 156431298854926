/*eslint-disable no-inline-comments */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import IconButton from '@mui/material/IconButton';
import Grid from "@material-ui/core/Grid";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RefreshIcon from '@mui/icons-material/Refresh';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import '../crm.css';
import '../list.css';


import { useTranslation } from "react-i18next";
import { SetSelectedRow, SetSelectedDemand, SetLastPage } from "../../../../Actions/Crm";
import { addPreferences, getPreferences, updatePreference } from "../Functions/PreferenceFunction";
import ListHandler from "./ListHandler";
import { makeStyles } from "@material-ui/core";
import CrmDemandFormDialog from "./CrmDemandFormDialog";
import CrmDemandManagement from "./CrmDemandManagement";
import { getDemands } from "../Functions/TripFunction";

const useStyles = makeStyles({
    paginationItemActive: {
        outline: "5px auto -webkit-focus-ring-color"
    },
    pagination: {
        "& button:focus": {
            outline: "none !important"
        }
    }
});

const CrmDemandList = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const demand_list = useSelector(store => store.crm.demands);
    const user = useSelector(store => store.user.user);
    const selected_row = useSelector(store => store.crm.selected_row);
    const last_page = useSelector(store => store.crm.last_page);
    const selected_contact_type = useSelector(store => store.crm.selected_contact_type);
    const selected_demand = useSelector(store => store.crm.selected_demand);

    const [allDemandsCount, setAllDemandsCount] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [active_page, setActivePage] = useState(1);
    const [filterValue, setFilterValue] = useState('');
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [rowSyle, setRowSyle] = useState(null);
    const [prefExist, setPrefExist] = useState(false);
    const [openTripForm, setOpenTripForm] = React.useState(false);
    const [densityPref, setDensityPref] = React.useState(null);
    const [ordering, setOrdering] = React.useState('-date_joined');
    const [displayList, setDisplayList] = React.useState(true);
    const [filterData, setFilterData] = React.useState({});

    useEffect(() => {
        let cols = [
            { order: 1, field: 'trip_reference', label: t("crm.demand.trip_reference"), sortable: true, active: true },
            { order: 2, field: 'name', label: t("crm.demand.name"), width: 150, sortable: true, active: true },
            { order: 3, field: 'accounting_reference', label: t("crm.demand.accounting_reference"), active: true },
            {
                order: 4, field: 'destination', label: t("crm.demand.destination"), active: true,
                valueGetter: (v) => (v.data?.length && v.data[v.data.length - 1]?.destination?.data?.international_name) ? v.data[v.data.length - 1].destination.data.international_name : null
            },
            {
                order: 5, field: 'contact_traveler_detail', label: t("crm.demand.contact_traveler_detail"), active: true,
                valueGetter: (v) => (v.data?.length && v.data[v.data.length - 1]?.contact_traveler_detail) ? v.data[v.data.length - 1].contact_traveler_detail?.first_name : null
            },
            { order: 6, field: 'vendor', label: t("crm.demand.vendor"), active: true },
            { order: 7, field: 'creator', label: t("crm.demand.creator"), active: true, valueGetter: (v) => v.creator?.first_name },
            { order: 8, field: 'last_author', label: t("crm.demand.last_author"), active: false, valueGetter: (v) => v.last_author?.first_name },
            { order: 9, field: 'created_date', label: t("crm.demand.created_date"), sortable: true, active: true, valueGetter: (v) => moment.utc(v.created_date).format("DD/MM/YYYY") },
            { order: 10, field: 'modified_date', label: t("crm.demand.modified_date"), sortable: true, active: false, valueGetter: (v) => moment.utc(v.modified_date).format("DD/MM/YYYY") }
        ];

        onChangeDensity(null);

        if (!demand_list?.length) {
            loadRows(1, null, selected_contact_type, ordering);
        }

        if (user?.client) {
            getPreferences(user.client, "APPS_CRM_DEMANDS", (res) => {
                const prefs = res.data?.results;
                if (prefs.length) {
                    setPrefExist(true);
                    cols.forEach((col, col_index) => {
                        const pref = prefs.find(p => p.field_name === col.field.toUpperCase());
                        if (pref) {
                            cols[col_index].order = pref.order;
                            cols[col_index].ordering_asc = pref.ordering_asc;
                            cols[col_index].active = pref.active;
                            cols[col_index].id = pref.id;

                            if (pref.ordering_asc !== null) {
                                setOrdering(`${pref.ordering_asc ? '' : '-'}${col.field}`);
                            }
                        }
                    });

                    //density
                    const dens = prefs.find(p => p.field_name === "DENSITY");
                    if (dens) {
                        setDensityPref(dens);
                    }
                }
                cols = cols.sort((a, b) => a.order - b.order);
                setColumns(cols);
            });
        }
    }, [demand_list]);

    useEffect(() => {
        loadRows(1, null, selected_contact_type, ordering);
    }, [selected_contact_type, filterData]);

    useEffect(() => {
        handlePageChange(last_page, null, selected_contact_type);
    }, []);

    useEffect(() => {
        if (selected_demand?.createCode === "CREATE") {
            setOpenTripForm(true);
        }
    }, [selected_demand]);

    const loadRows = (page, name, type, ordering) => {
        let filter = {};
        if (filterData) {
            filter = { ...filterData };
        }
        if (name) {
            filter.search = name;
        }
        if (type) {
            filter.type = type;
        }
        getDemands(user.client, pageSize, (page <= 0 ? 0 : page - 1) * pageSize, filter, ordering, (resp) => {
            if (resp?.data) {
                setAllDemandsCount(resp.data.count);
                setRows(resp.data.results?.map(e => {
                    return { ...e, date_joined: moment(e.date_joined).format("DD/MM/YYYY") };
                }));
            }
        });
    };

    //CLICK ITEM
    const clickDemand = (id) => {
        if (id) {
            dispatch(SetSelectedRow(id));
            props.router.push(`/${window.url_name}/menu/crm-demand/${id}`);
        }
    };

    //PAGINATION
    const handlePageChange = (page) => {
        setActivePage(page);
        dispatch(SetLastPage(page));
        loadRows(page, null, selected_contact_type, ordering);
    };

    //SEARCH CLIENT 
    const handleFilterChange = (event) => {
        setFilterValue(event.target.value);
    };
    const handleFilterApply = () => {
        loadRows(1, filterValue, selected_contact_type, ordering);
    };

    //REFRESH LIST
    const refreshList = () => {
        setFilterValue('');
        setActivePage(1);
        dispatch(SetLastPage(1));
        loadRows(1, null, selected_contact_type, ordering);
    };

    //HANDLE COLUMNS
    const onUpdateColumns = (columns) => {
        setColumns(columns);
        setPreferences(columns);
    };

    const setPreferences = (columns) => {
        const prefs = columns.map((c, index) => {
            return {
                active: c.active,
                can_edit: true,
                field_name: c.field.toUpperCase(),
                module: "APPS_CRM",
                order: index + 1,
                ordering_asc: c.ordering_asc,
                id: c.id
            };
        });
        if (prefExist) {
            updatePreference(user.client, prefs, () => {
                console.log('prefs updated');
            });
        } else {
            addPreferences(user.client, prefs, () => {
                console.log('prefs added');
            });
        }
    };

    //HANDLE DENSITY
    const onChangeDensity = (density) => {
        let densPref = densityPref;
        if (!densPref) {
            densPref = {
                active: false,
                can_edit: true,
                field_name: "DENSITY",
                module: "APPS_CRM",
                order: 1,
                ordering_asc: null
            };
        }
        switch (density) {
            case 'medium':
                setRowSyle({ paddingTop: 20, paddingBottom: 20, fontSize: 13 });
                densPref.order = 2;
                break;
            case 'low':
                setRowSyle({ paddingTop: 30, paddingBottom: 30, fontSize: 14 });
                densPref.order = 1;
                break;
            default:
                setRowSyle({ paddingTop: 10, paddingBottom: 10, fontSize: 12 });
                densPref.order = 3;
                break;
        }
        //TODO: save density here
    };

    //HANDLE SORT
    const switchColumnSort = (field) => {
        let updatedColumns = JSON.parse(JSON.stringify(columns));
        const colIndex = updatedColumns.findIndex(item => item.field === field);
        let newOrdering = null;
        if (colIndex !== -1) {
            updatedColumns = updatedColumns.map(e => ({ ...e, ordering_asc: null }));

            if (!ordering.includes(field) || ordering === `-${field}`) {
                updatedColumns[colIndex].ordering_asc = true;
                newOrdering = field;
            } else {
                updatedColumns[colIndex].ordering_asc = false;
                newOrdering = `-${field}`;
            }
        }
        setOrdering(newOrdering);
        setColumns(updatedColumns);
        setPreferences(updatedColumns);
        loadRows(1, null, selected_contact_type, newOrdering);
    };

    const closeDialog = () => {
        setOpenTripForm(false);
        dispatch(SetSelectedDemand(null));
    };
    const createDemand = (trip) => {

    };

    /**ACTIONS */
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    if (displayList) {
        return (
            <Container className="crm-demand-list crm-user-list">
                <Grid container>
                    <Grid itme xs={3}>
                        <Typography variant={'h6'} >{allDemandsCount} {t("crm.demands")}</Typography>
                        <IconButton className="refresh-button" onClick={refreshList}><RefreshIcon /></IconButton>
                    </Grid>
                    <Grid itme xs={7}>
                        <TextField
                            variant="outlined" InputLabelProps={{ shrink: false }} size="small" value={filterValue} onChange={handleFilterChange}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
                            }}
                        />
                        <Button variant="contained" onClick={handleFilterApply}>Rechercher</Button>
                    </Grid>
                </Grid>

                <div className="list-container">
                    <ListHandler
                        columns={columns}
                        onUpdateColumns={onUpdateColumns}
                        onChangeDensity={onChangeDensity}
                        customFilter={{
                            options: [
                                { value: "sketch", label: t('crm.sketch') },
                                { value: "offer", label: t('crm.offer') },
                                { value: "ongoing", label: t('crm.ongoing') },
                                { value: "confirmed", label: t('crm.confirmed') },
                                { value: "in_progress", label: t('crm.in_progress') },
                                { value: "finished", label: t('crm.finished') }
                            ]
                        }}
                        periodFilter={{
                            onChange: (qp) => {
                                const fil_c = JSON.parse(JSON.stringify(filterData));
                                const [property, value] = qp.split('=');
                                fil_c[property] = value;
                                setFilterData(fil_c);
                            },
                            options: [
                                { value: "date_joined", label: t('crm.creation_date') },
                                { value: "modified_date", label: t('crm.modified_date') }
                            ]
                        }}
                        displayList={displayList}
                        setDisplayList={setDisplayList}
                    />
                    <table>
                        <tr>
                            {
                                columns.map(column => {
                                    if (!column.active) {
                                        return <></>;
                                    }
                                    return (
                                        <th className={column.sortable ? 'sortable-th' : ''} onClick={() => switchColumnSort(column.field)}>
                                            {column.label} {
                                                column.sortable &&
                                                <>
                                                    {ordering === `-${column.field}` && <ArrowDownwardIcon style={{ opacity: 1 }} />}
                                                    {ordering === column.field && <ArrowUpwardIcon style={{ opacity: 1 }} />}
                                                    {!ordering?.includes(column.field) && <ArrowUpwardIcon onClick={() => switchColumnSort(column.field)} />}
                                                </>
                                            }
                                        </th>
                                    );
                                })
                            }
                            <th><p style={{ display: "none" }}>Actions</p></th>
                        </tr>
                        {
                            rows?.map(row => {
                                return (
                                    <tr className={`clickable_row ${selected_row === row.id ? "selected_row" : ""}`} onClick={() => clickDemand(row.id)}>
                                        {
                                            columns.map(column => {
                                                if (!column.active) {
                                                    return <></>;
                                                }
                                                return (
                                                    <td style={{ ...rowSyle, width: column.flex ? '' : column.width }}>{column.valueGetter !== undefined ? column.valueGetter(row) : row[column.field]}</td>
                                                );
                                            })
                                        }
                                        <td>
                                            <IconButton onClick={handleClick}> <MoreVertIcon /> </IconButton>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={handleClose}>{t('crm.demand.assign_vendor')}</MenuItem>
                                            </Menu>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </table>
                </div>

                <div className="list-pagination">
                    {
                        rows && rows.length > 0 &&
                        <Pagination
                            className={classes.pagination}
                            size={"medium"}
                            page={active_page}
                            count={Math.ceil(allDemandsCount / pageSize)}
                            variant={"outlined"}
                            hideNextButton={(allDemandsCount === 0 || active_page === Math.ceil(allDemandsCount / pageSize))}
                            hidePrevButton={active_page === 1} disabled={allDemandsCount === 0}
                            onChange={(e, v) => handlePageChange(v)}
                            renderItem={(item) => {
                                return (
                                    <PaginationItem className={`${item.selected ? classes.paginationItemActive : ""}`} {...item} />
                                );
                            }}
                        />
                    }
                </div>
                {
                    selected_demand &&
                    <CrmDemandFormDialog open={openTripForm} handleClose={closeDialog} onConfirm={createDemand} />
                }
            </Container>
        );
    }
    return (
        <Container className="crm-demand-list crm-user-list">
            <Grid container>
                <Grid itme xs={3}>
                    <Typography variant={'h6'} >{allDemandsCount} {t("crm.list_title")}</Typography>
                    <IconButton className="refresh-button" onClick={refreshList}><RefreshIcon /></IconButton>
                </Grid>
                <Grid itme xs={7}>
                    <TextField
                        variant="outlined" InputLabelProps={{ shrink: false }} size="small" value={filterValue} onChange={handleFilterChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
                        }}
                    />
                    <Button variant="contained" onClick={handleFilterApply}>Rechercher</Button>
                </Grid>
            </Grid>

            <div className="list-container" style={{ overflowX: "auto" }}>
                <ListHandler
                    columns={columns}
                    onUpdateColumns={onUpdateColumns}
                    onChangeDensity={onChangeDensity}
                    customFilter={{ options: [{ value: "non_client", label: t('crm.clients.not_a_client') }] }}
                    periodFilter={{
                        options: [
                            { value: "date_joined", label: t('crm.creation_date') },
                            { value: "modified_date", label: t('crm.modified_date') }
                        ]
                    }}
                    displayList={displayList}
                    setDisplayList={setDisplayList}
                />
                <CrmDemandManagement />
            </div>
            {
                selected_demand &&
                <CrmDemandFormDialog open={openTripForm} handleClose={closeDialog} onConfirm={createDemand} />
            }
        </Container>
    );
};
export default React.memo(CrmDemandList);
