import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Star from "@material-ui/icons/Star";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: "bold"
    },
    inline: {
        display: "inline-block"
    },
    inlineFlex: {
        display: "inline-flex"
    },
    floatRight: {
        float: "right"
    },
    stars: {
        marginLeft: 8
    },
    marginBottom: {
        marginBottom: 16
    },
    providerWrapper: {
        position: "relative"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    marginLeft: {
        marginLeft: 8
    }
}));

const AccommodationCardAmerigo = ({prices, diff_day, iso_code, language, provider_loading, getRoomPrices}) => {
    console.log("amerigo render");
    const classes = useStyles();
    const { t } = useTranslation();
    let preferred_array = {
        3: {
            prices: []
        },
        2: {
            prices: []
        },
        1: {
            prices: []
        }
    };
    prices.map((price) => {
        preferred_array[price.preferred_lvl].prices.push(price);
        preferred_array[price.preferred_lvl].prices.sort((a, b) => {
            return b.weight - a.weight;
        });
    });
    return (
        <Fragment>
            <br/>
            {
                Object.keys(preferred_array).map((key, index_lvl) => {
                    if (preferred_array[key].prices.length > 0) {
                        return (
                            <Tooltip key={ key } title={ t(`accommodation.preferred_lvl_${key}_text`) } arrow>
                                <div className={ classes.marginBottom }>
                                    <Typography className={ classes.inlineFlex }>{ t("accommodation.partner") } <Star className={ classes.stars }/>{ (key === "2" || key === "3") && (<Star className={ classes.stars }/>) }{ key === "3" && (<Star className={ classes.stars }/>) } : </Typography>
                                    <div className={ `${classes.inline} ${classes.floatRight}` }>
                                        <Typography className={ `${classes.inlineFlex} ${classes.bold}` }>{ new Intl.NumberFormat(language, { style: 'currency', currency: iso_code }).format(Math.ceil(parseFloat( preferred_array[key].prices[0].price / diff_day ))) }</Typography>
                                        <div className={ `${classes.providerWrapper} ${classes.inline} ${classes.marginLeft}` }>
                                            <Button variant={ "contained" } size={ "small" } className={ classes.orangeButton } disabled={ provider_loading === preferred_array[key].prices[0].data.id } onClick={() => { getRoomPrices(preferred_array[key].prices[0].data); } }>{ t("accommodation.choose") }</Button>
                                            { provider_loading === preferred_array[key].prices[0].data.id && <CircularProgress size={24} className={ classes.buttonProgress } /> }
                                        </div>
                                    </div>
                                </div>
                            </Tooltip>
                        );
                    }
                })
            }
        </Fragment>
    );
};

export default React.memo(AccommodationCardAmerigo);