import GetCookie from "../../Common/Functions/GetCookie";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

import { SetState } from "../../../Actions/FlightSearch";
import { AddToCart, SetGroupIndex } from "../../../Actions/Flight";

import axios from "axios";
import CheckResponse from "../Functions/CheckResponse";

export default (manual_request, flight_groups, flight_providers, group_index, dispatch, enqueueSnackbar, end_date, t, setManualSearching, router) => {
    dispatch(SetGroupIndex(group_index));
    dispatch(SetState());
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        let group = flight_groups[group_index];
        let url = `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/create_flight_from_pnr/`;
        let request = {
            provider_id: group.provider[0],
            pnr: typeof manual_request === "string" ? manual_request : manual_request[group_index]
        };
        axios({
            method: "POST",
            url: url,
            data: JSON.stringify(request),
            headers: headers
        }).then(function (response) {
            let arr = CheckResponse([response.data], end_date);
            dispatch(AddToCart(arr));
            router.push(`/${window.url_name}/apps/cart`);
        }).catch(function (error) {
            console.log("manual_request error:", error);
            console.log("manual_request error:", error.response);
            setManualSearching(false);
            let responseJSON = error.response;
            if (responseJSON.data === "date_too_far") {
                enqueueSnackbar(t("flight.date_too_far_error"), { variant: "error" });
            } else if (responseJSON.data.errors !== undefined) {
                enqueueSnackbar(t("flight.not_available_error"), { variant: "error" });
            } else {
                enqueueSnackbar(responseJSON.data, { variant: "error" });
            }
        });
    }
};
