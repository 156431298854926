//---- Dependencies ----//
import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Slider from "react-slick";

//---- Common ----//

//---- Functions ----//

//---- Actions ----//

//---- CSS ----//
import "../../../Style/FlightGroups.css";
import "../../../Style/FlightSearch.css";
import "../../../Style/CarsSearch.css";

@connect((store) => {
    return {
    	step_info: store.cars_search.step_info,
    	cars_results: store.cars_search.cars_results,
    	total_cars: store.cars_search.total_cars,
    	location_index: store.cars_search.location_index,
    	car_index: store.cars_search.car_index,
    }
})
@withTranslation()

export default class CarsResultHeader extends React.Component {
	printDestinationStep() {
		const { step_info, location_index } = this.props;
		let arr = [];
		let last_index = step_info[location_index].tmp_destination.length - 1;
		step_info[location_index].tmp_destination.map((destination, index_destination) => {
			arr.push(
				<div key={index_destination}>
					<div className={ "cars-slider-city ft-relative" + ((index_destination === last_index) ? " last" : "") }>
						<div className="cars-city-wrap">
							{destination}
						</div>
						{
							index_destination !== last_index && (
								<div className={ "cars-slider-container-car" }>
								    <div className={ "cars-slider-line-left" }/>
								    <i className={ "icon-100-car-compact" }/>
								    <div className={ "cars-slider-line-right" }/>
								</div>
							)
						}
					</div>
				</div>
			)
		});
		return arr;
	}

	render() {
		const { step_info, cars_results, total_cars, location_index, step, car_index, t } = this.props;
		const settings = {
			className: "ft-relative cars-slider",
			slidesToShow: 2,
			slidesToScroll: 1,
			autoplay: false,
			infinite: false,
			speed: 300,
			arrows: true,
			responsive: [
			    {
			        breakpoint: 1650,
			        settings: {
			            slidesToShow: 2,
			        }
			    },
			    {
			        breakpoint: 1024,
			        settings: {
			            slidesToShow: 1,
			        }
			    }
			]
		};
		const info = step_info[location_index];
		return (			
			<div className={ "cars-results-header text-center ft-basic-card mt30 ft-relative" }>
				{
					step !== "add_to_cart" ? (
						<Fragment>
							<div className="cars-results-total-cars ft-absolute bold">{total_cars + t("cars.cars_found")}</div>
							<div className={ "cars-results-location-number ft-font-30 bold" }>{t("cars.rent") + (location_index + 1) + (step_info[location_index].car_nbr > 1 ? " " + t("cars.cars") + (car_index + 1) : "")}</div>
						</Fragment>
					) : (
						<div className={ "cars-results-location-number ft-font-30 bold" }>{t("cars.cars_summary")}</div>
					)
				}
				{
					(info.agency_go && info.agency_go.international_name !== info.agency_return.international_name || info.tmp_destination.length > 0) && (
						<div className={ "cars-results-destinations mt15 row" }>
							<div className={ "col-md-3 no-padding" }>
								<div className={ "cars-slider-destination text-center" }>
									<div className={ "bold ft-font-18" } >{t("cars.agency_origin")}</div>
									<div className={ "bold ft-black ft-font-18" }>{info.agency_go.city ? info.agency_go.city : info.agency_go.international_name}</div>
									<div className={ "ft-light-grey bold" }>{info.origin_date !== null ? moment.utc(info.origin_date).format("dddd DD MMMM") : moment.utc(info.start_date_origin).format("dddd DD MMMM")}</div>
									<div className={ "ft-light-grey bold" }>{info.origin_hours + "H" + info.origin_minutes}</div>
								</div>
							</div>
							{
								info.tmp_destination.length > 0 && (
									<div className={ "col-md-6 no-padding text-center ft-font-13" }>
										<Slider { ...settings }>
										{
											this.printDestinationStep()
										}
										</Slider>
									</div>
								)
							}
							<div className={ (info.tmp_destination.length === 0 ? "offset-md-6 " : "") + "col-md-3 no-padding" }>
								<div className={ "cars-slider-destination text-center" }>
									<div className={ "bold ft-font-18" } >{t("cars.agency_return")}</div>
									<div className={ "ft-black bold ft-font-18" }>{info.agency_return.city ? info.agency_return.city : info.agency_return.international_name}</div>
									<div className={ "ft-light-grey bold" }>{info.return_date !== null ? moment.utc(info.return_date).format("dddd DD MMMM") : moment.utc(info.end_date_dest).format("dddd DD MMMM")}</div>
									<div className={ "ft-light-grey bold" }>{info.return_hours + "H" + info.return_minutes}</div>
								</div>
							</div>
						</div>
					)
				}
				{
					(info.agency_return && info.tmp_destination && info.agency_go.international_name === info.agency_return.international_name && info.tmp_destination.length === 0) && (
						<div className={ "cars-results-destinations mt15 row" }>
							<div className={ "col-md-8 offset-md-2 no-padding" }>
								<div className={ "cars-slider-destination text-center" }>
									<div className={ "bold ft-font-18" } >{t("cars.agency_origin_return")}</div>
										<div className={ "bold ft-black ft-font-18" }>{info.agency_go.city ? info.agency_go.city : info.agency_go.international_name}</div>
									<div className={"row"}>
										<div className={ "col-md-6" }>
											<div className={ "ft-light-grey bold" }>{info.origin_date !== null ? moment.utc(info.origin_date).format("dddd DD MMMM") : moment.utc(info.start_date_origin).format("dddd DD MMMM")}</div>
											<div className={ "ft-light-grey bold" }>{info.origin_hours + "H" + info.origin_minutes}</div>
										</div>
										<div className={"col-md-6"}>
											<div className={ "ft-light-grey bold" }>{info.return_date !== null ? moment.utc(info.return_date).format("dddd DD MMMM") : moment.utc(info.end_date_dest).format("dddd DD MMMM")}</div>
											<div className={ "ft-light-grey bold" }>{info.return_hours + "H" + info.return_minutes}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)
				}
			</div>
		);
	}
}