//Dependencies
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
//Core
import Alert from '@material-ui/lab/Alert';
import { isProductPackaged } from './utils/isProductPackaged';

const CartGlobalTripPriceNotification = () => {
    const { t } = useTranslation();
    const trip = useSelector(state => state.trip.data_trip);
    const terrestrialPrices = trip?.prices_terrestrial?.filter((item) => {
        return !isProductPackaged({
            product: item,
            stackInfos: trip?.stack_info ?? null
        });
    }) ?? [];
    const flightPrices = trip?.prices_flight?.filter((item) => {
        return !isProductPackaged({
            product: item,
            stackInfos: trip?.stack_info ?? null
        });
    }) ?? [];
    return (
        <Fragment>
            {
                (terrestrialPrices.length > 0 && flightPrices.length > 0) ? (
                    <Alert variant={ 'filled' } severity={ 'warning' }>{ t('cart-material.warning-global-terrestrial-flight') }</Alert>
                ) : (
                    <Fragment>
                        {
                            terrestrialPrices.length > 0 &&
                            <Alert variant={ 'filled' } severity={ 'warning' }>{ t('cart-material.warning-global-terrestrial') }</Alert>
                        }
                        {
                            flightPrices.length > 0 &&
                            <Alert variant={ 'filled' } severity={ 'warning' }>{ t('cart-material.warning-global-flight') }</Alert>
                        }
                    </Fragment>
                )
            }
        </Fragment>
    );
};

export default CartGlobalTripPriceNotification;
