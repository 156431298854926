import React, { Fragment } from "react";
import {useSelector} from "react-redux";
import ReloadCartCars from "./ReloadCartCars";
import ReloadCartFlight from "./ReloadCartFlight";
import ReloadCartAccommodation from "./ReloadCartAccommodation";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    continueButton: {
        color: 'white',
        backgroundColor: "#e6592f"
    },
});

const ReloadCartCheckedItems = ({onValidateRecalc}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const has_accommodation_step = useSelector(state => state.cart.has_accommodation_step);
    const has_cars = useSelector(state => state.cart.has_cars);
    const has_custom_transfer = useSelector(state => state.cart.has_custom_transfer);
    const has_flight = useSelector(state => state.cart.has_flight);
    return (
        <Fragment>
            { has_accommodation_step && <ReloadCartAccommodation ranked={ 1 }/> }
            { has_cars && <ReloadCartCars ranked={ has_accommodation_step ? 2 : 1 }/> }
            { has_custom_transfer && <div/> }
            { has_flight && <ReloadCartFlight ranked={ has_accommodation_step && has_cars ? 3 : has_accommodation_step || has_cars ? 2 : 1 }/> }
            <Grid container justify={"center"}>
                <Grid item>
                    <Button className={ classes.continueButton } variant="contained" onClick={ onValidateRecalc }>{ t('global.continue') }</Button>
                </Grid>
            </Grid>
            <br/>
        </Fragment>
    );
};

export default ReloadCartCheckedItems;