import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

export default(props) => {
	return (
		<SvgIcon {...props} viewBox={ "0 0 512 512" }>
            <path d="M406.312 482V97.868h-39.99V32.896H271V0h-30v32.896h-95.322v64.972h-39.99V482h-54.4v30h409.424v-30zM175.678 62.896h160.644v34.972H175.678zM316.312 482H271V372.371h45.312zM241 482h-45.312V372.371H241zm105.312 0V342.371H165.688V482h-30V127.868h240.624V482z"/>
            <path d="M165.688 158.205h30v30h-30zM316.312 158.205h30v30h-30zM241 158.205h30v30h-30zM165.688 218.541h30v30h-30zM316.312 218.541h30v30h-30zM241 218.541h30v30h-30zM165.688 278.878h30v30h-30zM316.312 278.878h30v30h-30zM241 278.878h30v30h-30z"/>
	  </SvgIcon>
	)
}