/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import '../../crm.css';
import CrmUserDetailNoteCard from './CrmUserDetailNoteCard';
import moment from "moment";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NoteIcon from '@mui/icons-material/Note';
import EditIcon from '@mui/icons-material/Edit';
import { deleteNote } from "../../Functions/UserFunctions";
import { SetActiveUserNotes } from "../../../../../Actions/Crm";
import CrmUserDetailEmailCard from "./CrmUserDetailEmailCard";

const CrmUserDetailActivityMonth = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const active_user_notes = useSelector(store => store.crm.active_user_notes);
    const selected_note = useSelector(store => store.crm.selected_note);
    const selected_user = useSelector(store => store.crm.selected_user);
    const user = useSelector(store => store.user.user);

    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [expand, setExpand] = React.useState({});
    const [activityToDelete, setActivityToDelete] = React.useState(null);

    const updateCardState = (cardID, edit) => {
        const expand_copy = { ...expand };
        const hasProperty = expand_copy.hasOwnProperty(cardID);
        if (!hasProperty || !expand_copy[cardID] || !expand_copy[cardID].open) {
            expand_copy[cardID] = { open: true };
        } else {
            expand_copy[cardID] = { open: false };
        }
        if (edit !== undefined && edit !== null) {
            expand_copy[cardID] = { open: edit === true ? true : expand_copy[cardID].open, edit };
        }
        setExpand(expand_copy);
    };

    const openConfirmDeleteDialog = (activity) => {
        setOpenDeleteDialog(true);
        setActivityToDelete(activity);
    };

    const cancelDelete = () => {
        setOpenDeleteDialog(false);
        setActivityToDelete(null);
    };
    const confirmDelete = () => {
        if (activityToDelete) {
            switch (activityToDelete.type) {
                case "note":
                    deleteNote(user.client, activityToDelete.id, () => {
                        let user_notes = [];
                        if (active_user_notes && active_user_notes.user_id === selected_user?.id) {
                            user_notes = JSON.parse(JSON.stringify(active_user_notes.notes));
                        }
                        const findIndex = user_notes.findIndex(e => e.id === activityToDelete.id);
                        if (findIndex !== -1) {
                            user_notes.splice(findIndex, 1);
                        }
                        dispatch(SetActiveUserNotes(selected_user?.id, user_notes));
                        setOpenDeleteDialog(false);
                        setActivityToDelete(null);
                    });
                    break;
                default:
                    break;
            }
        }
    };

    const deleteDialog = () => {
        return (
            <Dialog
                open={openDeleteDialog}
                onClose={cancelDelete}
            >
                <DialogTitle>
                    {t("crm.delete_forever_question")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("crm.delete_forever_tips")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelDelete} color="inherit" autoFocus>{t("crm.cancel")}</Button>
                    <Button onClick={confirmDelete} variant="contained" color="error">{t("crm.delete_forever")}</Button>
                </DialogActions>
            </Dialog>
        );
    };

    const getActivityComponent = (activity) => {
        let component = null;
        switch (activity.type) {
            case "note":
                component = <CrmUserDetailNoteCard key={activity.id} activity={activity} editMode={expand[activity.id]?.edit} cancelEdition={() => updateCardState(activity.id, false)} />;
                break;
            case "email":
                component = <CrmUserDetailEmailCard key={activity.id} email={activity} expand={expand[activity.id]?.open} />;
                break;
            default:
                break;
        }
        return component;
    };
    return (
        <div className="activity-day">
            <h3>{props.title}</h3>
            <div>
                {props.activities && props.activities.length > 0 && props.activities.sort((a, b) => new Date(b.created_date) - new Date(a.created_date)).map((activity, index) => {
                    if (activity.type === "email") {
                        return (
                            <div style={{ marginBottom: 10 }}>
                                <CrmUserDetailEmailCard key={activity.id} email={activity} expand={expand[activity.id]?.open} />
                            </div>
                        );
                    }
                    return (
                        <div className={`activity-day-card`} key={`activity-day-card-${index}`}>
                            <div>
                                <h4>
                                    <NoteIcon style={{ marginRight: 12 }} />
                                    {/*{expand[activity.id]?.open ? <KeyboardArrowDownIcon onClick={() => updateCardState(activity.id)} /> : <KeyboardArrowRightIcon onClick={() => updateCardState(activity.id)} />} */}
                                    {activity.title}
                                    {
                                        (activity.type !== "email" && activity.creator?.first_name) ? ` ${t('global.of')} ${activity.creator?.first_name}` : ''
                                    }
                                </h4>
                                <h6>
                                    <AccessTimeIcon className="crm-time-icon" fontSize="small" />
                                    {activity.date ? moment(activity.date).format("Do MMM YYYY [à] h:mm") : ''}
                                    {activity.type !== "email" && <EditIcon onClick={() => updateCardState(activity.id, true)} />}
                                    <DeleteForeverIcon onClick={() => openConfirmDeleteDialog(activity)} />
                                </h6>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ opacity: 0 }}><NoteIcon style={{ marginRight: 12 }} /></div>
                                {getActivityComponent(activity)}
                            </div>
                        </div>
                    );
                })}
            </div>
            {deleteDialog()}
        </div>
    );
};
export default React.memo(CrmUserDetailActivityMonth);
