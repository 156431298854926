import axios from "axios";
import memoizee from "memoizee";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { Hotel } from "../objects/Hotel";
import { Destination } from "../../../Itinerary/objects/destination";

export type Guide = {
    destination: Destination,
    rubrics: {
        id: number,
        localization: {
            locale: number,
            title: string,
            description: string,
        }[]
    }[]
}

async function fetch(
    hotelId: number,
    trip: number
): Promise<Hotel | null> {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        try {
            const response = await axios.get<{data: Hotel[]}>(
                `${API_HREF}client/${window.id_owner}/accommodations/${hotelId}/`,
                {
                    params: {
                        trip,
                        format: 'json'
                    },
                    headers
                }
            );
            return response.data.data.find((item) => {
                return item.id === hotelId;
            }) ?? null;
        } catch (error: any) {
            console.error(error);
        }
    }
    return null;
}

export const findHotel = memoizee(
    fetch,
    {
        promise: true,
        primitive: true
    }
);
