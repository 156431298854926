import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
    List,
    AutoSizer, 
    CellMeasurer, 
    CellMeasurerCache, 
    WindowScroller 
} from "react-virtualized";
import {
    Divider,
    Grid,
    Pagination,
    PaginationItem
} from "@mui/material";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import FlightCard from "./FlightCard";
import FlightCardContainer from './FlightCardContainer';
import {
    CartConstructionReplaceProductContext
} from "../../CartMaterial/utils/cartConstructionReplaceProductContext";
import FlightSearchSkeleton from "./FlightSearchSkeleton";

const useStyles = makeStyles({
    paginationItemActive: {
        outline: "5px auto -webkit-focus-ring-color"
    },
    // pagination: {
    //     "& button:focus": {
    //         outline: "none !important"
    //     }
    // },
});

const FlightList = ({ searched_group, providersObj, router }) => {
    const dispatch = useDispatch();
    const flight_list_render = useSelector(state => state.flight_search.flight_list_render);
    const detailed_index = useSelector(state => state.flight_search.detailed_index);
    const index_group = useSelector(store => store.flight.index_group);
    const flight_groups = useSelector(store => store.flight.flight_groups);
    const virtualizedList = useRef();

    const context = useContext(CartConstructionReplaceProductContext);

    const cache = React.useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: flight_groups[index_group].journey_type !== 3 ? 300 : 250,
            minHeight: flight_groups[index_group].journey_type !== 3 ? 300 : 250
        })
    );

    useEffect(() => {
        if (detailed_index !== null) {
            let find_index = flight_list_render.findIndex(el => el.id === detailed_index);
            const timer = setTimeout(() => {
                cache.current.clear(find_index, 0);
                virtualizedList.current.recomputeRowHeights(find_index);
                dispatch({ type: 'FLIGHT_RESET_DETAILED_INDEX' });
            }, 250);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [detailed_index]);

    useEffect(() => {
        if (virtualizedList.current !== undefined && flight_list_render.length !== 0) {
            const timer = setTimeout(() => {
                cache.current.clearAll();
                virtualizedList.current.recomputeRowHeights();
            }, 250);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [flight_list_render.length]);

    const renderRow = ({ index, parent, key, style }) => {
        return (
            <CellMeasurer
                key={key}
                cache={cache.current}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
            >
                {
                    ({ measure, registerChild }) => {
                        return (
                            <div style={style} ref={registerChild}>
                                <div onLoad={measure}>
                                    <FlightCardContainer searched_group={searched_group} flight={parent.props.data[index]} flight_index={index} router={router} measure={measure}/>
                                    {/* <FlightCard searched_group={searched_group} flight={parent.props.data[index]} flight_index={index} providersObj={providersObj} small={false} router={router} /> */}
                                </div>
                            </div>
                        );
                    }
                }
            </CellMeasurer>
        );
    };
    console.log('flight_list_render:', flight_list_render);
    if (flight_list_render.length === 0) return null;
    return (

        <WindowScroller
            scrollElement={
                context.enableReplace &&
                context.extraData.current?.container ?
                    context.extraData.current.container :
                    window
            }
        >
            {
                ({ height, isScrolling, registerChild, scrollTop }) => {
                    return (
                        <AutoSizer disableHeight style={{ width: "100%" }}>
                            {
                                ({ width }) => {
                                    return (                                            
                                        <div ref={registerChild}>
                                            <List
                                                ref={virtualizedList}
                                                autoHeight
                                                height={height}
                                                width={width}
                                                isScrolling={isScrolling}
                                                scrollTop={scrollTop}
                                                deferredMeasurementCache={cache.current}
                                                rowCount={flight_list_render.length}    
                                                rowHeight={cache.current.rowHeight}
                                                rowRenderer={renderRow}
                                                data={flight_list_render}
                                                overscanRowCount={2}
                                            />
                                        </div>
                                    );
                                }
                            }
                        </AutoSizer>
                    );
                }
            }
        </WindowScroller>
    );
};

export default React.memo(FlightList);
