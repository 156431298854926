import React, { Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import DialogContent from "@material-ui/core/DialogContent";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Slide from '@material-ui/core/Slide';

import MobileDestinationSearch from "./Mobile/MobileDestinationSearch.js"
import MobileAddNewVersion from "./Mobile/MobileAddNewVersion.js";
import MobileHeaderDatePicker from "./Mobile/MobileHeaderDatePicker.js";
import MobileTripBudget from "./Mobile/MobileTripBudget.js";
import MobileHeaderPassenger from "./Mobile/MobileHeaderPassenger.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MobileHeaderDialog = ({dialog_type, setDialogType, setMobileOpen}) => {
    const { t } = useTranslation();
    const onClose = () => {
        setDialogType(null);
    }
    let title = "";
    if (dialog_type === "destination") {
        title = t("header.research");
    }
    else if (dialog_type === "version") {
        title = t("header.save_new_version");
    }
    else if (dialog_type === "datepicker") {
        title = t("header.edit_trip_dates");
    }
    return (
        <Dialog fullScreen={ true } open={ dialog_type !== null } fullWidth maxWidth={ "md" } onClose={ onClose } TransitionComponent={Transition} disableEscapeKeyDown>
            <DialogTitle onClose={ onClose }>
                <Grid container justify={ "space-between" } alignItems={ "center" }>
                    <Grid item style={{fontSize: 15}}>
                        <IconButton edge={ "start" } onClick={ onClose }><NavigateBefore/></IconButton>
                        {title}
                    </Grid>
                </Grid>
            </DialogTitle>
            {
                dialog_type === "destination" && (
                    <MobileDestinationSearch setMobileOpen={setMobileOpen} dialog_type={dialog_type} setDialogType={setDialogType}/>
                )
            }
            {
                dialog_type === "version" && (
                    <MobileAddNewVersion setMobileOpen={setMobileOpen} dialog_type={dialog_type} setDialogType={setDialogType}/>
                )
            }
            {
                dialog_type === "datepicker" && (
                    <MobileHeaderDatePicker setMobileOpen={setMobileOpen} dialog_type={dialog_type} setDialogType={setDialogType}/>
                )
            }
            {
                dialog_type === "budget" && (
                    <MobileTripBudget setMobileOpen={setMobileOpen} dialog_type={dialog_type} setDialogType={setDialogType}/>
                )
            }
            {
                dialog_type === "travelers" && (
                    <MobileHeaderPassenger setMobileOpen={setMobileOpen} dialog_type={dialog_type} setDialogType={setDialogType}/>
                )
            }
        </Dialog>
    );
};

export default React.memo(MobileHeaderDialog);