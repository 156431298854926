import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    List,
    Stack,
    styled,
    Tab,
    Tabs,
    Tooltip,
    Typography
} from "@mui/material";
import { ItineraryDestinationItem } from "./itineraryDestinationItem";
import { ItineraryDestinationSearch } from "./itineraryDestinationSearch";
import { ItineraryBlocksTreeView } from "./itineraryBlocksTreeView";
import { ItineraryBlocksFilters } from "./itineraryBlocksFilters";
import { ItineraryBlocksSorts } from "./itineraryBlocksSorts";
import { filterDestinationsBasedOnZoom } from "./utils/filterDestinationsBasedOnZoom";
import { getLightDestinationName } from "./utils/getLightDestinationName";
import { getDestination } from "./utils/getDestination";
import { findDestinationChildren } from "./utils/findDestinationChildren";
import { useItineraryStepAdd } from "./network/itineraryStepAdd";
import {
    setDestinations,
    setDestinationsTab,
    setParentDestination
} from "./redux/reducer";
import { LightDestination } from "./objects/lightDestination";
import { AppState } from "../../Reducers/Reducers";
import PlaceIcon from '@mui/icons-material/Place';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

export function ItineraryDestinationsList(): JSX.Element {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const destinations = useSelector((state: AppState) => state.itinerarySlice.destinations);
    const map = useSelector((state: AppState) => state.itinerarySlice.map);
    const isUserTO = useSelector((state: AppState) => state.user.user?.client_full?.type !== 2);
    const parentDestination = useSelector((state: AppState) => state.itinerarySlice.parentDestination);
    const tab = useSelector((state: AppState) => state.itinerarySlice.destinationsTab);
    const [openFilters, setOpenFilters] = useState(false);
    const [openSorts, setOpenSorts] = useState(false);
    const [goingBackOnParent, setGoingBackOnParent] = useState(false);
    const filtersButtonRef = useRef(null);
    const sortsButtonRef = useRef(null);
    const addStep = useItineraryStepAdd({});
    const parentName = parentDestination?.data?.localization?.find((item) => {
        return item.locale === locale;
    })?.name ?? parentDestination?.data?.international_name ?? '';

    const onAddStep = (destination: LightDestination) => {
        addStep(destination);
    };

    const onGoBackOnParent = async () => {
        if (parentDestination?.parent?.id && map) {
            setGoingBackOnParent(true);
            const grandParent = await getDestination(parentDestination.parent.id);
            if (grandParent?.data) {
                const children = await findDestinationChildren(grandParent.id, isUserTO);
                map.set('noNotify', true);
                map.panTo({
                    lat: parseFloat(grandParent.data.latitude),
                    lng: parseFloat(grandParent.data.longitude)
                });
                if (grandParent.data.zoom_level) {
                    map.setZoom(grandParent.data.zoom_level);
                }
                dispatch(
                    setDestinations({
                        state: 'success',
                        data: filterDestinationsBasedOnZoom(children, map.getZoom() ?? 8)
                    })
                );
            }
            setGoingBackOnParent(false);
        }
    };

    useEffect(() => {
        setOpenFilters(false);
        setOpenSorts(false);
    }, [tab]);

    useEffect(() => {
        (async () => {
            const items = filterDestinationsBasedOnZoom(
                destinations.data,
                map?.getZoom() ?? 8
            );
            if (items[0]?.parent_id) {
                const parent = await getDestination(items[0].parent_id);
                dispatch(
                    setParentDestination(parent)
                );
            }
        })();
    }, [map, destinations]);
    
    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ padding: 2, paddingBottom: 0 }}>
                <Grid container>
                    <Grid item flex={1}>
                        <GoBackButton
                            onClick={onGoBackOnParent}
                            disabled={goingBackOnParent}
                        >
                            {
                                goingBackOnParent &&
                                <CircularProgress size="12px" />
                            }
                            {
                                !goingBackOnParent &&
                                <ArrowBackIosNewOutlinedIcon fontSize="inherit" />
                            }
                        </GoBackButton>
                    </Grid>
                    <Grid item sx={{ width: '50%' }} flex={1}>
                        <Tooltip title={parentName}>
                            <Typography
                                variant="subtitle2"
                                align="center"
                                sx={{
                                    opacity: 0.54,
                                    whiteSpace: 'nowrap',
                                    width: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {parentName}
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item flex={1} />
                </Grid>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1.5}
                    sx={{ marginTop: 1.5 }}
                >
                    <ItineraryDestinationSearch
                        tab={tab === 0 ? 'destinations' : 'blocks'}
                        onChoose={onAddStep}
                    />
                    <SortIconButton
                        ref={filtersButtonRef}
                        onClick={() => setOpenFilters(true)}
                    >
                        <FilterListOutlinedIcon fontSize="inherit" />
                    </SortIconButton>
                    <ItineraryBlocksFilters
                        open={openFilters && tab === 1}
                        anchorEl={filtersButtonRef.current}
                        onClose={() => setOpenFilters(false)}
                    />
                    <SortButton
                        ref={sortsButtonRef}
                        variant="outlined"
                        size="small"
                        onClick={() => setOpenSorts(true)}
                    >
                        {t('itinerary.sort')}
                    </SortButton>
                    <ItineraryBlocksSorts
                        open={openSorts && tab === 1}
                        anchorEl={sortsButtonRef.current}
                        onClose={() => setOpenSorts(false)}
                    />
                </Stack>
            </Box>
            <ModeTabs
                variant="fullWidth"
                value={tab}
                onChange={(_, value) => dispatch(setDestinationsTab(value))}
            >
                <ModeTab
                    icon={<PlaceIcon />}
                    label={t('itinerary.destinations')}
                />
                <ModeTab
                    icon={
                        <AccountTreeIcon />
                    }
                    label={t('itinerary.blocs')}
                />
            </ModeTabs>
            <Box sx={{ flex: 1, backgroundColor: '#fff', zIndex: 2 }}>
                {
                    tab === 0 &&
                    <List sx={{ padding: 0, paddingLeft: 1 }}>
                        {
                            [...destinations.data].sort((a, b) => {
                                return getLightDestinationName(locale, a).localeCompare(getLightDestinationName(locale, b));
                            }).map((destination) => (
                                <ItineraryDestinationItem
                                    key={destination.id}
                                    destination={destination}
                                    suggestedDuration={(destination.suggested_hours ?? 0) / 24}
                                />
                            ))
                        }
                    </List>
                }
                {
                    tab === 1 &&
                    <ItineraryBlocksTreeView />
                }
            </Box>
        </Box>
    );
}

const GoBackButton = styled(IconButton)(() => ({
    width: 24,
    height: 24,
    fontSize: 12,
    border: '1px solid #ddd',
    borderRadius: '50%',
    background: 'none',
    color: '#ddd',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const SortIconButton = styled(IconButton)(() => ({
    width: 24,
    height: 24,
    fontSize: 20,
    borderRadius: '50%',
    background: 'none',
    color: '#ddd',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const SortButton = styled(Button)(() => ({
    height: 30,
    textTransform: 'none',
    borderRadius: 15
}));

const ModeTabs = styled(Tabs)((props) => ({
    boxShadow: '0px 4px 5px 0px rgb(0 0 0 / 20%)',
    marginBottom: props.theme.spacing(2),
    minHeight: 72
}));

const ModeTab = styled(Tab)(() => ({
    fontSize: 12,
    textTransform: 'none'
}));
