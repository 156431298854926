import { combineReducers } from "redux";
import { TripListNotesState } from "../objects/TripListNotesState";

import {
    SET_TRIP_LIST_NOTES,
    SET_TRIP_LIST_NOTES_VALUE,
    TripListNotesActionTypes
} from "./actionTypes";

const initialState: TripListNotesState = {
    notes: [],
    value: ''
};

export default (state:TripListNotesState = initialState, action: TripListNotesActionTypes): TripListNotesState => {
    switch (action.type) {
        case SET_TRIP_LIST_NOTES: {
            return { ...state, notes: action.payload };
        }
        case SET_TRIP_LIST_NOTES_VALUE: {
            return { ...state, value: action.payload.value };
        }
        default:
            return state;
    }
};