import mjml2html from "mjml-browser";
import { flatten, mapKeys, mapValues } from "lodash";
import { Block } from "../objects/block";
import { BlockHtmlAttribute } from "../objects/blockHtmlAttributes";
import { MailTemplateVisualEditorBodyStyles } from "../objects/mailTemplateVisualEditorState";
import { RegisteredBlocks } from "./registered-blocks";

type RegisteredBlockHtmlAttributes = {
    [blockType: string]: BlockHtmlAttribute[]
}

type RegisteredBlockStyles = {
    [blockType: string]: string[]
}

export function renderHtml(
    bodyStyles: MailTemplateVisualEditorBodyStyles,
    content: {
        order: number[],
        blocks: {
            [id: number]: Block<any>
        }
    },
    quotationCode: string | null,
    minimal?: boolean
): string {
    const registeredBlocksAttributes: RegisteredBlockHtmlAttributes = mapValues(
        mapKeys(
            RegisteredBlocks,
            (block) => block.type
        ),
        (block) => block.htmlAttributes ?? []
    );
    const registeredBlocksStyles: RegisteredBlockStyles = mapValues(
        mapKeys(
            RegisteredBlocks,
            (block) => block.type
        ),
        (block) => block.styles ?? []
    );

    const data = content.order.map((id) => {
        const block = content.blocks[id];


        if (block) {
            if (block?.getType() === 'columns') {
                return {
                    content: block.renderMjml(bodyStyles, quotationCode, content.blocks),
                    attributes: block.getExtraHtmlAttributes ?
                        block.getExtraHtmlAttributes(content.blocks) :
                        [],
                    styles: block.getExtraStyles ?
                        block.getExtraStyles(content.blocks) :
                        []
                };
            }
            return {
                content: `
                    <mj-section css-class="${block.getType()}-block-root">
                        <mj-column>
                            ${block.renderMjml(bodyStyles, quotationCode, content.blocks)}
                        </mj-column>
                    </mj-section>
                `,
                attributes: block.getExtraHtmlAttributes ?
                    block.getExtraHtmlAttributes(content.blocks) :
                    [],
                styles: block.getExtraStyles ?
                    block.getExtraStyles(content.blocks) :
                    []
            };
        }

        return null;
    }).filter((item) => item) as {
        content: string;
        attributes: BlockHtmlAttribute[];
        styles: string[];
    }[];

    const result = mjmlLayout({
        bodyStyles: {
            ...bodyStyles,
            backgroundColor: minimal ? 'rgba(0, 0, 0, 0)' : bodyStyles.backgroundColor,
            innerBackgroundColor: minimal ? 'rgba(0, 0, 0, 0)' : bodyStyles.backgroundColor
        } as typeof bodyStyles,
        registeredBlocksAttributes: registeredBlocksAttributes,
        registeredBlocksStyles: registeredBlocksStyles,
        attributes: flatten(data.map((item) => item.attributes)),
        styles: flatten(data.map((item) => item.styles)),
        content: data.map((item) => item.content).join('')
    });

    return mjml2html(result).html;
}

type Options = {
    bodyStyles: MailTemplateVisualEditorBodyStyles,
    content: string,
    registeredBlocksAttributes: RegisteredBlockHtmlAttributes,
    registeredBlocksStyles: RegisteredBlockStyles,
    attributes: BlockHtmlAttribute[],
    styles: string[]
}

const mjmlLayout = (options: Options) => {
    const blockSelectors = RegisteredBlocks.map((item) => {
        return `
            <mj-selector path=".${item.type}-block">
                <mj-html-attribute name="data-block-type">${item.type}</mj-html-attribute>
            </mj-selector>
            <mj-selector path=".${item.type}-block-root">
                <mj-html-attribute name="data-block-root">${item.type}</mj-html-attribute>
            </mj-selector>
        `;
    });

    const registeredBlocksAttributes = flatten(
        RegisteredBlocks.map((block) => {
            return options.registeredBlocksAttributes[block.type]?.map((attribute) => {
                return `
                    <mj-selector path="${attribute.path}">
                        <mj-html-attribute name="${attribute.name}">
                            ${attribute.content}
                        </mj-html-attribute>
                    </mj-selector>
                `;
            });
        }).filter((item) => item) as string[][]
    );

    const registeredBlocksStyles = flatten(
        RegisteredBlocks.map((block) => {
            return options.registeredBlocksStyles[block.type]?.map((style) => {
                return `
                    <mj-style inline="inline">
                        ${style}
                    </mj-style>
                `;
            });
        }).filter((item) => item) as string[][]
    );

    const attributes = options.attributes.map((attribute) => {
        return `
            <mj-selector path="${attribute.path}">
                <mj-html-attribute name="${attribute.name}">
                    ${attribute.content}
                </mj-html-attribute>
            </mj-selector>
        `;
    });

    const styles = options.styles.map((style) => {
        return `
            <mj-style inline="inline">
                ${style}
            </mj-style>
        `;
    });

    return `
        <mjml>
            <mj-head>
                <mj-raw>
                    <meta
                        name="application-name"
                        content="ft-mail-template-visual-editor"
                        data-version="${FT_VISUAL_EDITOR_VERSION}"
                    />
                </mj-raw>
                <mj-font name="${options.bodyStyles.fontFamily.name}" href="${options.bodyStyles.fontFamily.url}" />

                <mj-html-attributes>
                    ${blockSelectors.join('\n')}

                    <mj-selector path=".body">
                        <mj-html-attribute name="style">
                            background-color: ${options.bodyStyles.backgroundColor};
                            padding-top: ${options.bodyStyles.padding.top}px;
                            padding-bottom: ${options.bodyStyles.padding.bottom}px;
                            padding-left: ${options.bodyStyles.padding.left}px;
                            padding-right: ${options.bodyStyles.padding.right}px;
                        </mj-html-attribute>
                        <mj-html-attribute name="data-width">
                            ${options.bodyStyles.width}
                        </mj-html-attribute>
                    </mj-selector>

                    <mj-selector path=".ft-align-left">
                        <mj-html-attribute name="style">
                            text-align: left;
                        </mj-html-attribute>
                    </mj-selector>

                    <mj-selector path=".ft-align-center">
                        <mj-html-attribute name="style">
                            text-align: center;
                        </mj-html-attribute>
                    </mj-selector>

                    <mj-selector path=".ft-align-right">
                        <mj-html-attribute name="style">
                            text-align: right;
                        </mj-html-attribute>
                    </mj-selector>

                    <mj-selector path=".ft-align-justify">
                        <mj-html-attribute name="style">
                            text-align: justify;
                        </mj-html-attribute>
                    </mj-selector>

                    ${registeredBlocksAttributes.join('\n')}
                    ${attributes.join('\n')}
                </mj-html-attributes>

                <mj-style inline="inline">
                    p {
                        margin: 0;
                        min-height: 24px;
                        line-height: 24px;
                    }
                </mj-style>

                <mj-style inline="inline">
                    .body-wrapper {
                        background-image: none !important;
                        padding-top: ${options.bodyStyles.innerPadding.top}px;
                        padding-bottom: ${options.bodyStyles.innerPadding.bottom}px;
                        padding-right: ${options.bodyStyles.innerPadding.right}px;
                        padding-left: ${options.bodyStyles.innerPadding.left}px;
                    }
                </mj-style>

                ${registeredBlocksStyles.join('\n')}
                ${styles.join('\n')}

                <mj-attributes>
                    <mj-all padding="0" />
                </mj-attributes>
            </mj-head>
            <mj-body width="${options.bodyStyles.width}px" background-color="${options.bodyStyles.backgroundColor}" css-class="body">
                <mj-wrapper
                    background-color="${options.bodyStyles.innerBackgroundColor}"
                    border="${options.bodyStyles.innerBorder.width}px solid ${options.bodyStyles.innerBorder.color}"
                    css-class="body-wrapper"
                >
                    ${options.content}
                </mj-wrapper>
            </mj-body>
        </mjml>
`;
};

export function renderWithTestData(html: string, data: Record<string, string>): string {
    return Object.keys(data).reduce(
        (html, key) => {
            return html.replace(`{{${key}}}`, data[key] ?? `{{${key}}}`);
        },
        html
    );
}
