//---- Actions ----//
import { DestinationListChange as ItineraryDestinationListChange } from "../../../Actions/Itinerary";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

export default (destination, dispatch, destination_filter) => {
    let { pass_check, headers } = CheckBeforeRequest();
    let filter;
    if (destination_filter === null) {
        filter = "current_version__localization__name";
    } else {
        filter = destination_filter;
    }

    $.ajax({
        method: "GET",
        headers: headers,
        url: `${API_HREF}client/${window.id_owner}/destinations/?limit=500&ordering=${filter}&parent=${destination}&visibility__in=PUBLIC,PRIVATE_TO`,
        success: function (data) {
            dispatch(ItineraryDestinationListChange(data.results));
        },
        error: function (error) {
            console.log("Error: something went wrong while loading destinations");
            console.log(error);
        }
    });
}