// Dependencies
import clsx from 'clsx';
import React, { FC, ReactElement, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
// Core & Lab
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';

// Types
// import { reorderColumns } from '../../functions/reorderColumn';
import { DragIndicator } from '@mui/icons-material';
import { reorderColumns } from './utils/reorderColumns';
type DragColumnProps = {
    column: any,
    column_index: number,
    columns: any,
    setColumns: any,
    handleChangeCheck: (index:number) => void
}

const DragColumn = (props: DragColumnProps): ReactElement => {
    const { column, column_index, columns, setColumns, handleChangeCheck } = props;
    const [{ isDragging }, dragRef] = useDrag({
        type: 'COLUMN',
        item: { column_index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const [{ isOver }, drop] = useDrop({
        accept: 'COLUMN',
        drop: (draggedItem) => {
            if (draggedItem.column_index !== column_index) {
                let newColumns = reorderColumns(columns, draggedItem.column_index, column_index);
                draggedItem.column_index = column_index;
                setColumns(newColumns);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    });

    const onToggleColumn = useCallback((index: number) => () => handleChangeCheck(index), [handleChangeCheck]);
    return (
        <Grid
            ref={(node) => dragRef(drop(node))}
            className={clsx('ft-listColumnConfigurator-column', { ['ft-listColumnConfigurator-lastUpdated']: column.last_updated, 'ft-over': isOver, 'ft-dragging': isDragging })}
            key={`trip-list-column-${column_index}`}
            container
            justifyContent={'space-between'}
        >
            <Grid item>
                <FormControlLabel control={<Checkbox checked={column.active} onChange={onToggleColumn(column_index)} color={'primary'} />} label={column.field_name} />
            </Grid>
            <Grid item> <DragIndicator/> </Grid>
        </Grid>
    );
};

export default DragColumn;
