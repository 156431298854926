//---- Dependencies ----//
import React from 'react';
import Slider from 'react-slick';

class HomeSlider extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            settings: {
                dots: false,
                arrows: false,
                fade: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                speed: 500,
                autoplaySpeed: 8000,
                cssEase: "linear"
            }
        }
    }
    render() {
        return (
            <div id="homeSlider">
                <Slider { ...this.state.settings }>
                    <div className="container-pic">
                        <img className="slider-pic ft-cover-img" src="/Img/caroussel/coliseum1.jpg"/>
                    </div>
                    <div className="container-pic">
                        <img className="slider-pic ft-cover-img" src="/Img/caroussel/coliseum2.jpg"/>
                    </div>
                    <div className="container-pic">
                        <img className="slider-pic ft-cover-img" src="/Img/caroussel/guelle.jpg"/>
                    </div>
                    <div className="container-pic">
                        <img className="slider-pic ft-cover-img" src="/Img/caroussel/halong.jpg"/>
                    </div>
                    <div className="container-pic">
                        <img className="slider-pic ft-cover-img" src="/Img/caroussel/halong3.jpg"/>
                    </div>
                    <div className="container-pic">
                        <img className="slider-pic ft-cover-img" src="/Img/caroussel/jesus.jpg"/>
                    </div>
                    <div className="container-pic">
                        <img className="slider-pic ft-cover-img" src="/Img/caroussel/lisbonneToit.jpg"/>
                    </div>
                    <div className="container-pic">
                        <img className="slider-pic ft-cover-img" src="/Img/caroussel/lisbonneTrain.jpg"/>
                    </div>
                    <div className="container-pic">
                        <img className="slider-pic ft-cover-img" src="/Img/caroussel/romePalace.jpg"/>
                    </div>
                    <div className="container-pic">
                        <img className="slider-pic ft-cover-img" src="/Img/caroussel/windowBarca.jpg"/>
                    </div>
                </Slider>
            </div>
        );
    }
}

export default HomeSlider;