interface ArrayDiff<T> {
    type: "common" | "deleted" | "inserted";
    value: T;
}

export function lcsArrayDiff<T>(arr1: T[], arr2: T[], isEqual: (a: T, b: T) => boolean): ArrayDiff<T>[] {
    const m = arr1.length;
    const n = arr2.length;

    const dp: number[][] = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));

    for (let i = 1; i <= m; i++) {
        for (let j = 1; j <= n; j++) {
            if (isEqual(arr1[i - 1]!, arr2[j - 1]!)) {
                dp[i]![j] = dp[i - 1]![j - 1]! + 1;
            } else {
                dp[i]![j] = Math.max(dp[i - 1]![j]!, dp[i]![j - 1]!);
            }
        }
    }

    let i = m;
    let j = n;
    const diff: ArrayDiff<T>[] = [];

    while (i > 0 || j > 0) {
        if (i > 0 && j > 0 && isEqual(arr1[i - 1]!, arr2[j - 1]!)) {
            diff.unshift({ type: "common", value: arr1[i - 1]! });
            i--;
            j--;
        } else if (j === 0 || (i > 0 && dp[i]![j - 1]! <= dp[i - 1]![j]!)) {
            diff.unshift({ type: "deleted", value: arr1[i - 1]! });
            i--;
        } else {
            diff.unshift({ type: "inserted", value: arr2[j - 1]! });
            j--;
        }
    }

    const result: typeof diff = [];

    for (let k = 0; k < diff.length; k++) {
        const inserts: typeof diff = [];
        if (diff[k]?.type === 'deleted') {
            for (
                let j = k - 1;
                j >= 0 && result[j]?.type === 'inserted';
                j--
            ) {
                inserts.push(result.pop()!);
            }
        }
        result.push(diff[k]!);
        for (const item of inserts) {
            result.push(item);
        }
    }

    return result;
}
