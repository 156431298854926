import { ManualProduct } from "../../../Reducers/objects/manualProduct";
import { Picture } from "../../Menu/MaterialTripList/picture/objects/picture";

export function getManualProductPicture(info: ManualProduct, source?: 'provider'): Picture[] {
    if (info.hide_pictures) {
        return [];
    }

    if (!source && (info.overload_pictures?.length ?? 0) > 0) {
        return info.overload_pictures ?? [];
    } else if (info.picture) {
        return [info.picture];
    }

    return [];
}
