//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import SummaryCarCard from './SummaryCarCard';
import SummaryCarCardManual from './SummaryCarCardManual';
import moment from "moment";

// import SummaryCarCard from './SummaryCarCard';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    bold: {
        fontWeight: "bold"
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    hoverContainer: {
        "&:hover": {
            borderLeft: '6px solid #E6592F'
        }
    },
    borderContainer: {
        borderTop: "1px solid #C4C4C4",
        borderBottom: "1px solid #C4C4C4",
        paddingBottom: "0px !important"
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 50
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
    paddingLeft0: {
        paddingLeft: 0
    }
}));

const RenderSummaryCars = ({cars_cart, manual_cart, step, selectedProducts, setSelectedProducts, step_index, from, byDay}) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();

    const trip_info = useSelector(state => state.summary.trip_info);

    const [mainCars, setMainCars] = useState([]);
    const [optionCars, setOptionCars] = useState([]);
    const [carSelected, setCarSelected] = useState(false);

    useEffect(() => {
        if (cars_cart.length > 0) {
            let main_car = [];
            let optional_car = [];
            let tmp_selected = false;
            cars_cart.map((car) => {
                if (car.booking_status !== undefined && ((car.booking_status !== null && car.booking_status.status_booking !== "CANCELLED") || car.booking_status === null) && ((moment.utc(car.start_date).isSameOrAfter(moment.utc(step.start_date), 'd') && car.start_destination.id === step.destination.id) || (moment.utc(car.start_date).isSameOrBefore(moment.utc(step.start_date), 'd') && moment.utc(car.end_date).isSameOrAfter(moment.utc(step.end_date), 'd')))) {
                    if (car.is_optional) {
                        optional_car.push(car);
                    }
                    else {
                        main_car.push(car);
                        tmp_selected = car.id;
                    }
                }
            });
            manual_cart.map((manual) => {
                if (manual.product_type === 2) {
                    if (manual.booking_status !== undefined && ((manual.booking_status !== null && manual.booking_status.status_booking !== "CANCELLED") || manual.booking_status === null) && moment.utc(manual.start_date, "YYYY-MM-DD HH:mm").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD HH:mm")) && moment.utc(manual.start_date, "YYYY-MM-DD HH:mm").isSameOrBefore(moment.utc(step.end_date, "YYYY-MM-DD HH:mm")) ) {
                        if (manual.is_optional) {
                            optional_car.push(manual);
                        }
                        else {
                            main_car.push(manual);
                        }
                    }
                }
            })
            setMainCars(main_car);
            setOptionCars(optional_car);
        }
    }, [cars_cart, manual_cart]);
    const getClientPrice = (prices) => {
		let current_price = prices.find((el) => {
			return !el.is_commissioned && !el.is_network && !el.is_surcom && !el.master_price;
		});
        if (current_price === undefined) {
            current_price = prices.find((el) => {
                return !el.is_commissioned && !el.is_network && !el.is_surcom && el.master_price;
            });
        }
		return current_price;
	}
    let mainPrice = 0;
    if (mainCars.length !== 0) {
        mainCars.map((car) => {
            mainPrice = parseFloat(getClientPrice(car.prices).selling_price);
        });
    }
    return (
        <Fragment>
        {
            mainCars.length !== 0 && (
                <Grid item style={{marginTop: 10, marginBottom: 10}} className={clsx({[classes.paddingLeft0]: byDay})}>
                    <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h6"}>{t("summary.main_cars")}</Typography>
                </Grid>
            )
        }
        {
            mainCars.map((car, car_index) => {
                if (car.booking_status !== undefined && ((car.booking_status !== null && car.booking_status.status_booking !== "CANCELLED") || car.booking_status === null)) {
                    return (
                        <Grid item key={car_index} style={{marginTop: 10, marginBottom: 10}} className={ clsx({[classes.bookingContainer]: from !== "tripSummary" && selectedProducts.cars[step_index].includes(car.id), [classes.borderContainer]: from !== "tripSummary", [classes.hoverContainer]: trip_info.status !== "USER_VALID" && from !== "tripSummary" && optionCars.length !== 0 && !selectedProducts.cars[step_index].includes(car.id)}) }>
                            {
                                !car.is_manual && (
                                    <SummaryCarCard car={car} type={"main"} mainCars={mainCars} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} from={from}/>
                                )

                            }
                            {
                                car.is_manual && (
                                    <SummaryCarCardManual car={car} type={"main"} mainCars={mainCars} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} from={from}/>
                                )
                            }
                            {
                                from === "tripSummary" && (
                                    <Divider/>
                                )
                            }
                        </Grid>
                    )
                }
            })
        }
        {
            optionCars.length !== 0 && (
                <Grid item style={{marginTop: 10, marginBottom: 10}} className={clsx({[classes.paddingLeft0]: byDay})}>
                    <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h6"}>{t("summary.alternated_cars")}</Typography>
                </Grid>
            )
        }
        {
            optionCars.map((car, car_index) => {
                if (car.booking_status !== undefined && ((car.booking_status !== null && car.booking_status.status_booking !== "CANCELLED") || car.booking_status === null)) {
                    return (
                        <Grid item key={car_index} style={{marginTop: 10, marginBottom: 10}} className={ clsx({[classes.bookingContainer]: trip_info.status !== "USER_VALID" && from !== "tripSummary" && optionCars.length !== 0 && selectedProducts.cars[step_index].includes(car.id), [classes.borderContainer]: from !== "tripSummary", [classes.hoverContainer]: trip_info.status !== "USER_VALID" && from !== "tripSummary" && optionCars.length !== 0 && !selectedProducts.cars[step_index].includes(car.id)}) }>
                            {
                                !car.is_manual && (
                                    <SummaryCarCard car={car} type={"option"} mainCars={mainCars} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} from={from} mainPrice={mainPrice}/>
                                    )
                                }
                            {
                                car.is_manual && (
                                    <SummaryCarCardManual car={car} type={"option"} mainCars={mainCars} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} from={from}/>
                                )
                            }
                            {
                                from === "tripSummary" && (
                                    <Divider/>
                                )
                            }
                        </Grid>
                    )
                }
            })
        }
        </Fragment>
    )
}
export default RenderSummaryCars;