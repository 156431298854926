import axios, { AxiosError, AxiosResponse } from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { TripVersion } from "../../Menu/MaterialTripList/objects/tripVersion";

type Callback = (
    tripId: number,
    tripVersion: number,
    trip: TripUpdateData,
    signal?: AbortSignal
) => Promise<TripVersion | null>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: (trip: TripVersion) => void,
    onError: (error: AxiosError) => void,
    onFinally: () => void
}>

export type TripUpdateData = Partial<{
    startDate: string,
    endDate: string,
    hasTripStartingPoint: boolean,
    lockItinerary: boolean
}>

export function useItineraryTripVersionUpdate(options: Options): Callback {
    return async (tripId, version, trip) => {
        if (options.onTrigger) {
            options.onTrigger();
        }
        try {
            const response = await makeRequest(tripId, version, trip);
            if (options.onSuccess && response) {
                options.onSuccess(response.data);
            }
            return response?.data ?? null;
        } catch (error: any) {
            if (options.onError) {
                options.onError(error);
            }
        } finally {
            if (options.onFinally) {
                options.onFinally();
            }
        }
        return null;
    };
}

async function makeRequest(
    tripId: number,
    version: number,
    trip: TripUpdateData,
    signal?: AbortSignal
): Promise<AxiosResponse<TripVersion> | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const cancelToken = axios.CancelToken.source();
        signal?.addEventListener('abort', () => {
            cancelToken.cancel();
        });
        return await axios.patch(
            `${API_HREF}client/${window.id_owner}/trip/${tripId}/versions/${version}/`,
            {
                start_date: trip.startDate,
                end_date: trip.endDate,
                has_trip_starting_point: trip.hasTripStartingPoint
            },
            {
                headers,
                cancelToken: cancelToken.token
            }
        );
    }

    return null;
}
