import axios from "axios";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import GetUserList from "./GetUserList";
import ManagePermissions from "./ManagePermissions";

import { SetManagementStepAndUser } from "../../../../Actions/Menu";

export default (firstName, lastName, email, userName, password, client, permissions, userPermission, viewUser, addUser,	changeUser,	deleteUser,	viewMarginPrice, addMarginPrice, changeMarginPrice, deleteMarginPrice, viewAuthGroup, addAuthGroup, changeAuthGroup, deleteAuthGroup, viewTrip, addTrip, changeTrip, deleteTrip, setErrorMessages, dispatch, enqueueSnackbar, t) => {
    let { pass_check, headers } = CheckBeforeRequest();
    let permissions_arr = ManagePermissions(permissions, userPermission, viewUser, addUser,	changeUser,	deleteUser,	viewMarginPrice, addMarginPrice, changeMarginPrice, deleteMarginPrice, viewAuthGroup, addAuthGroup, changeAuthGroup,	deleteAuthGroup, viewTrip, addTrip,	changeTrip,	deleteTrip);
    if (pass_check) {
        let request = {
            username: userName,
            email: email,
            last_name: lastName,
            first_name: firstName,
            password: password
        }
        axios({
            method: "POST",
            headers: headers,
            url: `${API_HREF}client/${client}/user/`,
            data: JSON.stringify(request),
        }).then(function (response) {
            let user_id = response.data.id;
            request = {
                ids: permissions_arr
            };
            axios({
                method: "POST",
                headers: headers,
                url: `${API_HREF}client/${client}/user/${user_id}/permissions/`,
                data: JSON.stringify(request),
            }).then(function (response) {
                GetUserList(dispatch, client);
                enqueueSnackbar(t("menu.user_management.success_user_creation"), { variant: "success" });
                dispatch(SetManagementStepAndUser(1, null));
            }).catch(function (error) {
                if (error.response) {
                    if (error.response.status === 400) {
                        for (const [key, value] of Object.entries(error.response.data)) {
                            if (Array.isArray(value)) {
                                value.map(error => enqueueSnackbar(`${key} : ${error}`, {variant: 'error'}));
                            } else {
                                enqueueSnackbar(`${key} : ${value}`, {variant: 'error'});
                            }
                        }
                    } else if (error.response.status === 401 || error.response.status === 403) {
                        enqueueSnackbar(t('errors.permissions'), {variant: 'error'});
                    } else if (error.response.status === 500) {
                        enqueueSnackbar(t('errors.servers'), {variant: 'error'});
                    }
                } else if (error.request) {
                    console.log('error.request : ', error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log('error.config : ', error.config);
            });
        }).catch(function (error) {
            let data = Object.assign({}, error.response.data);
            setErrorMessages(data);
            if (error.response) {
                if (error.response.status === 400) {
                    for (const [key, value] of Object.entries(error.response.data)) {
                        if (Array.isArray(value)) {
                            value.map(error => enqueueSnackbar(`${key} : ${error}`, {variant: 'error'}));
                        } else {
                            enqueueSnackbar(`${key} : ${value}`, {variant: 'error'});
                        }
                    }
                } else if (error.response.status === 401 || error.response.status === 403) {
                    enqueueSnackbar(t('errors.permissions'), {variant: 'error'});
                } else if (error.response.status === 500) {
                    enqueueSnackbar(t('errors.servers'), {variant: 'error'});
                }
            } else if (error.request) {
                console.log('error.request : ', error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log('error.config : ', error.config);
        });
    }
}