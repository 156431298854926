export function getLocalizedValue<
    T extends Record<string, unknown>,
>(
    locale: number,
    data: {localization: Array<{locale: number } & T>} & Omit<T, 'locale'>,
    key: keyof Omit<T, 'locale'>
): Omit<T, 'locale'>[keyof Omit<T, 'locale'>] {
    const localization = data.localization.find((item) => {
        return item.locale === locale;
    });

    if (localization) {
        return localization[key];
    }

    //@TODO fix this :'(
    return data[key] as any;
}
