//---- Dependencies ----//
import { makeStyles, useTheme } from '@material-ui/core';
import { Star } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useSelector } from 'react-redux';

import CheckBeforeRequest from '../../Common/CheckBeforeRequest.js';
import HotelInfoMap from './HotelInfoMap.tsx';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    stars: {
        fontSize: '14px !important',
        verticalAlign: 'middle',
        marginRight: 2
    },
    qualities: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: '0px !important',
        marginBottom: '1em',
    },
    fact: {
        flex: '0 0 33.33%',
        display: 'flex',
        alignItems: 'center',
        gap: '1em',
    },
    address: {
        flex: '0 0 33.33%',
        display: 'flex',
        alignItems: 'center',
        gap: '1em',
    },
    check: {
        opacity: 0.5,
    },
    carouselAccommodationFullScren: {
        position: 'relative',
        float: 'left',
        width: '100%',
        marginRight: '1em',
        marginBottom: 0
    },
    carouselAccommodation: {
        position: 'relative',
        float: 'left',
        width: '50%',
        marginRight: '1em',
        marginBottom: 0
    },
    carouselAccommodationAlt: {
        width: '120% !important',
        overflow: 'hidden',
        display: 'flex',
        position: 'unset'
    },
    carouselAccommodationInteractions: {
        position: 'absolute',
        width: '96%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 5px'
    },
    carouselAccommodationInteractionsSvg: {
        cursor: 'pointer',
        fontSize: '22pt',
        backgroundColor: '#0000004f',
        color: 'white'
    },
    firstImg: {
        marginRight: '2%',
        width: '60% !important'
    },
    secondImg: {
        width: '49% !important'
    },
    containImage: {
        width: '100%',
        objectFit: 'contain'
    },
    onePicture: {
        margin: "15px 0",
        paddingLeft: '2em'
    },
    multiplePictures: {
        margin: "1.5em 0",
        position: "relative",
        overflow: "hidden",
        paddingLeft: '2em'
    }
}));

type Props = {
    accommodation: any;
}

export function DialogHotelInfo(props: Props): JSX.Element {
    const { accommodation } = props;
    const { t } = useTranslation();
    const classes = useStyles();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const itinerary_type_trip_data = useSelector((state: any) => state.itinerary_type.itinerary_type_trip_data);

    const [description, setDescription] = useState<string>('');
    const [images, setImages] = useState<any[]>([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
    
    const move = (step: number | undefined) => {
        if (images) {
            if (images.length <= 0) return;
            if (step == -1) setSelectedImageIndex(selectedImageIndex <= 0 ? images.length - 1 : selectedImageIndex - 1);
            else if (step == 1) setSelectedImageIndex(selectedImageIndex >= images.length - 1 ? 0 : selectedImageIndex + 1);
        }
    };
    const displayMeal = (accommodation: any) => {
        const { t } = useTranslation();
        let display = t<string>('poi.not_included');
        if (accommodation.all_inclusive) display = 'All inclusive';
        if (accommodation.breakfast_included && accommodation.lunch_included && accommodation.dinner_included) display = 'Pension complète';
        else if (accommodation.breakfast_included && !accommodation.lunch_included && !accommodation.dinner_included) display = 'Petit-déjeuner';
        else if (
            (accommodation.breakfast_included && accommodation.lunch_included) ||
            (accommodation.breakfast_included && accommodation.dinner_included) ||
            (accommodation.lunch_included && accommodation.dinner_included)
        ) display = t<string>('accommodation.facts.meals_halfboard');
        return display;
    }
    
    const displayMealRooms = (accommodation: any) => {
        const { t } = useTranslation();
        const default_meal = displayMeal(accommodation);
        if (default_meal == t<string>('poi.not_included')) {
            if (accommodation.rooms?.length) {
                return displayMeal(accommodation.rooms.at(0));
            }
        }
        return default_meal;
    }

    const getStars = (number: number) => {
        let stars = [];
        for (let i = 0; i < number; i++) {
            stars.push(<Star key={i} className={ classes.stars }/>);
        }
        return stars;
    };
    // TODO add accommodation type
    const getAddress = (accommodation: any) => {
        let addr: string = '';
        if (!accommodation.is_manual && accommodation.hotel?.at(0)?.address) addr = accommodation.hotel?.at(0)?.address;
        else if (accommodation.is_manual && accommodation.address !== null) addr = accommodation.address;
        else addr = `${accommodation.hotel?.at(0)?.house_number ? accommodation.hotel?.at(0)?.house_number : ''} ${accommodation.hotel?.at(0)?.street ? accommodation.hotel?.at(0)?.street : ''}${' '}
        ${accommodation.hotel?.at(0)?.city_name ? accommodation.hotel?.at(0)?.city_name : ''}`;
        if (addr.trim() === '') addr = '';
        return addr;
    }

    useEffect(() => {
        const { headers } = CheckBeforeRequest();
        setDescription('');
        if (accommodation?.hotel?.at(0) && !accommodation?.hotel?.at(0)?.is_from_manual && accommodation?.id != accommodation?.hotel?.at(0)?.id) {
            Axios({
                method: 'GET',
                url: `${API_HREF}client/${window.id_owner}/accommodations/${accommodation?.hotel?.at(0)?.id}/`,
                headers: headers,
                params: {
                    format: 'json',
                    token: itinerary_type_trip_data !== undefined && itinerary_type_trip_data !== null && itinerary_type_trip_data.token !== undefined ? itinerary_type_trip_data.token : undefined,
                }
            }).then((response) => {
                if (response.data?.data?.length) {
                    let hotel_data = response.data?.data.find((dt: any) => !dt.is_giata); // TODO: change type
                    if (!hotel_data) {
                        hotel_data = response.data?.data?.at(0);
                    }

                    if (hotel_data) {
                        if (hotel_data.room_desc) setDescription(hotel_data.room_desc);
                        else if (!hotel_data.room_desc && hotel_data.content?.length) {
                            let display_content = hotel_data.content.find(c => c.section == 101);
                            if (!display_content) display_content = hotel_data.content.find(c => c.section == 110);
                            if (!display_content) display_content = hotel_data.content.find(c => c.section == 100);
                            if (!display_content) display_content = hotel_data.content.find(c => c.section == 400);
                            if (!display_content) display_content = hotel_data.content.find(c => c.section == 390);
                            setDescription(`<p>${display_content?.content ? display_content.content : ''}</p>`)
                        }
                    }
                }
            }).catch((error) => {
                console.log('error : ', error);
            });
        }

        if (accommodation) {
            let imgs = [];
            if (!accommodation?.hide_pictures) {
                if (accommodation?.overload_pictures?.length) {
                    imgs = accommodation.overload_pictures;
                } else if (accommodation?.hotel?.at(0)?.custom_pictures?.length) {
                    imgs = accommodation?.hotel?.at(0)?.custom_pictures;
                } else if (!accommodation?.hotel?.at(0)?.ignore_giata_pictures && accommodation?.hotel?.at(0)?.pictures?.length) {
                    imgs = accommodation?.hotel?.at(0)?.pictures;
                }
                if (accommodation?.picture) imgs.push(accommodation.picture);
            }

            if (imgs?.length) {
                imgs = imgs.map(img => {
                    let real_url: string | null = img.url;
                    if (!real_url) real_url = img.thumbnail_big;
                    if (!real_url) real_url = img.thumbnail_medium;
                    return { ...img, url: real_url };
                })
            }
            setImages(imgs ?? []);
        }
    }, [accommodation]);

    return (
        <Grid container spacing={2}>
            <Grid xs={12} item>
                <Typography variant='h4' style={{textAlign: 'center'}}>{t<string>('transfers.more_information')}</Typography>
            </Grid>
            <Grid xs={12} item>
                <Typography className={classes.fact} >
                    {t<string>('global.hotel')} {!accommodation.is_manual ? accommodation.hotel?.at(0)?.name : accommodation.name}
                    <span>{getStars(!accommodation.is_manual ? accommodation.hotel?.at(0)?.stars : accommodation.stars)}</span>
                </Typography>
                {
                    getAddress(accommodation) && 
                    <Typography className={classes.address} >
                        <LocationOnOutlinedIcon />
                        {getAddress(accommodation)}
                    </Typography>
                }
            </Grid>
            {
                fullScreen && images.length !== 0 &&
                <Grid xs={12} item style={{ margin: "15px 0", paddingLeft: '2em' }}>
                {images?.at(0) && (
                    <Grid className={`${classes.carouselAccommodationFullScren}`}>
                        <div className={classes.carouselAccommodationInteractions}>
                            <ArrowBackIosNewIcon className={classes.carouselAccommodationInteractionsSvg} onClick={() => move(-1)} />
                            <ArrowForwardIosIcon className={classes.carouselAccommodationInteractionsSvg} onClick={() => move(1)} />
                        </div>
                        <img
                            className={classes.containImage}
                            src={(selectedImageIndex ? images?.at(selectedImageIndex)?.url : images?.at(0)?.url) ?? undefined}
                            style={{
                                height: images?.at(0)?.url ? "20em" : 0
                            }}
                        />
                    </Grid>
                )}
                </Grid>
            }
            {
                !fullScreen && images.length !== 0 &&
                <Grid xs={12} item className={clsx({[classes.onePicture]: images.length === 1, [classes.multiplePictures]: images.length > 1})}>
                    {images?.at(0) && (
                        <Grid className={clsx({[classes.carouselAccommodation]: images.length > 1, [classes.carouselAccommodationAlt]: images.length > 1, [classes.carouselAccommodationFullScren]: images.length})}>
                            {images && images.length > 1 && (
                                <div className={classes.carouselAccommodationInteractions}>
                                    <ArrowBackIosNewIcon className={classes.carouselAccommodationInteractionsSvg} onClick={() => move(-1)} />
                                    <ArrowForwardIosIcon className={classes.carouselAccommodationInteractionsSvg} onClick={() => move(1)} />
                                </div>
                            )}
                            <img
                                className={clsx({[classes.containImage]: images.length === 1, [classes.firstImg]: images.length > 1})}
                                src={(selectedImageIndex ? images?.at(selectedImageIndex)?.url : images?.at(0)?.url) ?? undefined}
                                style={{ height: images?.at(0)?.url ? "20em" : 0 }}
                            />
                            {images.length > 1 && images?.at(selectedImageIndex === images.length - 1 ? 0 : selectedImageIndex + 1)?.url != images?.at(selectedImageIndex)?.url &&
                                <img
                                    className={classes.secondImg}
                                    src={images?.at(selectedImageIndex === images.length - 1 ? 0 : selectedImageIndex + 1)?.url ?? undefined}
                                    style={{ height: images?.at(selectedImageIndex === images.length - 1 ? 0 : selectedImageIndex + 1)?.url ? "20em" : 0 }}
                                />}
                        </Grid>
                    )}
                </Grid>
            }
            <Grid xs={(!accommodation.is_optional) ? 12 : 8} item>
                <Typography><strong>{t<string>('accommodation.room_type')} :</strong> <span>{accommodation.category}</span></Typography>
                {
                    // displayMealRooms(accommodation) && displayMealRooms(accommodation) !== t<string>('poi.not_included') &&
                    <Typography><strong>{t<string>('shared.fact-meal')} : </strong><span>{displayMealRooms(accommodation)}</span>{' '}</Typography>
                }
                {
                    // accommodation.is_optional &&
                    // <Typography><strong>{t<string>('total_price')} : </strong><span>{price?.includes('-') ? ' ' : ' +'}{price}</span></Typography>
                }
                {!!accommodation?.description && <div style={{ marginTop: 5, textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: accommodation?.description ?? '' }}></div>}
                {!!accommodation?.custom_information && <div style={{ marginTop: 5, textAlign: "justify" }}>{accommodation?.custom_information}</div>}
            </Grid>
            {
                description &&
                <Grid xs={12} item>
                    <h5 style={{ fontSize: "15pt", margin: "0px" }}>{t<string>('transfers.more_information')}</h5>
                    {description && <div style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: description ?? '' }}></div>}
                </Grid>
            }
            {
                (accommodation.hotel?.at(0)?.restaurant_fact ||
                    accommodation.hotel?.at(0)?.parking ||
                    accommodation.hotel?.at(0)?.pool ||
                    accommodation.hotel?.at(0)?.spa ||
                    accommodation.hotel?.at(0)?.bar
                ) && (
                    <>
                        <br />
                        <Grid xs={12} item>
                            <Typography style={{ marginTop: 5 }}><b>{t<string>('itineraryType.avantages')} :</b></Typography>
                        </Grid>
                        <Grid xs={12} item className={classes.qualities}>
                            {/* TODO: some fileds are missing (elevator, beach, ...) */}
                            {/* {accommodation.hotel?.at(0)?. && (
                            <Typography className={classes.fact}><CheckIcon className={classes.check} />Hôtel en plein centre ville</Typography>
                        )} */}  
                            {accommodation.hotel?.at(0)?.restaurant_fact && (
                                <Typography className={classes.fact}><CheckIcon className={classes.check} />{t<string>('accommodation.facts.facility_restaurant')}</Typography>
                            )}
                            {accommodation.hotel?.at(0)?.parking && (
                                <Typography className={classes.fact}><CheckIcon className={classes.check} />{t<string>('accommodation.facts.facility_carpark')}</Typography>
                            )}
                            {/* {accommodation.hotel?.at(0)?. && (
                            <Typography className={classes.fact}><CheckIcon className={classes.check} />Accès pour les personnes handicapées</Typography>
                        )} */}
                            {accommodation.hotel?.at(0)?.pool && (
                                <Typography className={classes.fact}><CheckIcon className={classes.check} />{t<string>('accommodation.equipment.swimming_pool')}</Typography>
                            )}
                            {/* {accommodation.hotel?.at(0)?. && (
                            <Typography className={classes.fact}><CheckIcon className={classes.check} />Location de vélo</Typography>
                        )}
                        {accommodation.hotel?.at(0)?. && (
                            <Typography className={classes.fact}><CheckIcon className={classes.check} />Petit-déjeuner</Typography>
                        )} */}
                            {accommodation.hotel?.at(0)?.spa && (
                                <Typography className={classes.fact}><CheckIcon className={classes.check} />{t<string>('accommodation.facts.spa_spa')} </Typography>
                            )}
                            {/* {accommodation.hotel?.at(0)?. && (
                            <Typography className={classes.fact}><CheckIcon className={classes.check} />Accès internet Wifi</Typography>
                        )} */}
                            {accommodation.hotel?.at(0)?.bar && (
                                <Typography className={classes.fact}><CheckIcon className={classes.check} />{t<string>('accommodation.equipment.bar')}</Typography>
                            )}
                        </Grid>
                    </>
                )
            }
            {
            (accommodation.hotel?.at(0)?.safe ||
                accommodation.hotel?.at(0)?.tv ||
                accommodation.hotel?.at(0)?.clim ||
                accommodation.hotel?.at(0)?.internet
            ) && (
                <>
                    <br />
                    <Grid xs={12} item>
                        <Typography><b>{t<string>('shared.room')} :</b></Typography>
                    </Grid>
                    <Grid xs={12} item className={classes.qualities}>
                        {/* TODO: some fileds are missing*/}
                        {/* {accommodation.hotel?.at(0)?. && (
                        <Typography className={classes.fact}><CheckIcon className={classes.check} />Lit King Size</Typography>
                    )}
                    {accommodation.hotel?.at(0)?. && (
                        <Typography className={classes.fact}><CheckIcon className={classes.check} />Sèche-cheveux</Typography>
                    )} */}
                        {accommodation.hotel?.at(0)?.safe && (
                            <Typography className={classes.fact}><CheckIcon className={classes.check} />{t<string>('accommodation.equipment.safe')}</Typography>
                        )}
                        {accommodation.hotel?.at(0)?.tv && (
                            <Typography className={classes.fact}><CheckIcon className={classes.check} />{t<string>('accommodation.facts.room_tv')}</Typography>
                        )}
                        {accommodation.hotel?.at(0)?.clim && (
                            <Typography className={classes.fact}><CheckIcon className={classes.check} />{t<string>('accommodation.facts.facility_aircon')}</Typography>
                        )}
                        {accommodation.hotel?.at(0)?.internet && (
                            <Typography className={classes.fact}><CheckIcon className={classes.check} />{t<string>('accommodation.facts.room_internet')} </Typography>
                        )}
                        {/* {accommodation.hotel?.at(0)?. && (
                        <Typography className={classes.fact}><CheckIcon className={classes.check} />Baignoir</Typography>
                    )} */}
                        {/* {accommodation.hotel?.at(0)?. && (
                        <Typography className={classes.fact}><CheckIcon className={classes.check} />Balcon/terrasse</Typography>
                    )}
                    {accommodation.hotel?.at(0)?. && (
                        <Typography className={classes.fact}><CheckIcon className={classes.check} />Petit Déjeuner inclus</Typography>
                    )} */}
                    </Grid>
                </>
            )
                        }
            <Grid item xs={12} style={{marginTop: 10, marginLeft: 0}} container spacing={2}>
                <HotelInfoMap info_hotel={accommodation}/>
            </Grid>
        </Grid>
    );
};