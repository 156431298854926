import { useMemo } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../Reducers/Reducers";

export type Versions = {
    id: number,
    name: string | null
}[]

export function useTripVersions(): Versions {
    const allData = useSelector((state: AppState) => state.trip.all_data);
    return useMemo(() => {
        return allData?.data.map((item) => ({
            id: item.id,
            name: item.name
        })) ?? [];
    }, [allData]);
}
