export default (hotel) => {
    let tmp_hotel = Object.assign({}, hotel);
    let parking_facts = ['facility_carpark', 'facility_garage'];
    let internet_facts = ['room_internet', 'facility_internet', 'facility_wlan', 'facility_wifi', 'room_wifi', 'facility_mobilephonenet'];
    let clim_facts = ['room_aircon', 'room_aircon_indiv', 'facility_aircon'];
    let pool_facts = ['sports_pool_indoor', 'sports_pooloutdoor', 'sports_poolsaltwater', 'sports_poolfreshwater', 'facility_poolbar', 'facility_poolroom', 'facility_indoorpool', 'facility_outdoorpool'];

    let dist = [];
    let sports = [];
    let meals = [];
    let room = [];
    let facility = [];
    let payment = [];
    let building = [];
    let parking = [];
    let pool = [];
    let internet = [];
    let clim = [];
    tmp_hotel.facts.map((fact) => {
        if (fact.codename.includes("hoteltype") || fact.codename === "located_on_main_road" || fact.codename === "year_construction" || fact.codename === "year_renovation" || fact.codename === "annexe_buildings" || fact.codename === "num_floors_main" || fact.codename === "num_floors_annexe" || fact.codename === "m2_garden" || fact.codename === "m2_terrace" || fact.codename === "num_rooms_total" || fact.codename === "num_rooms_single" || fact.codename === "num_rooms_double" || fact.codename === "num_suites" || fact.codename === "num_apartments" || fact.codename === "num_junior_suites" || fact.codename === "num_studios" || fact.codename === "num_bungalows" || fact.codename === "num_villas") {
            building.push(fact);
        } if (fact.codename.includes("dist")) {
            dist.push(fact);
        } if (fact.codename.includes("sports") && fact.codename !== "sports_numberofpools" && fact.codename !== "sports_poolheated" && fact.codename !== "sports_poolfreshwater" && fact.codename !== "sports_poolsaltwater" && fact.codename !== "sports_poolbar") {
            sports.push(fact);
        } if (fact.codename.includes("meals") && fact.codename !== "meals_specialoffers" && fact.codename !== "meals_lunchcarte" && fact.codename !== "meals_dinnerchoice") {
            meals.push(fact);
        } if (fact.codename.includes("room") && fact.codename !== "facility_roomservice" && fact.codename !== "facility_conferenceroom") {
            room.push(fact);
        } if (fact.codename.includes("facility")) {
            facility.push(fact);
        } if (fact.codename.includes("beach")) {
            facility.push(fact);
        } if (fact.codename.includes("payment")) {
            payment.push(fact);
        } if (internet_facts.includes(fact.codename)) {
            internet.push(fact);
        } if (parking_facts.includes(fact.codename)) {
            parking.push(fact);
        } if (pool_facts.includes(fact.codename)) {
            pool.push(fact);
        } if (clim_facts.includes(fact.codename)) {
            clim.push(fact);
        }
    });
    tmp_hotel.dist = dist;
    tmp_hotel.sports = sports;
    tmp_hotel.meals = meals;
    tmp_hotel.room = room;
    tmp_hotel.facility = facility;
    tmp_hotel.payment = payment;
    tmp_hotel.building = building;
    tmp_hotel.parking = parking;
    tmp_hotel.pool = pool;
    tmp_hotel.internet = internet;
    tmp_hotel.clim = clim;
    return tmp_hotel;
}