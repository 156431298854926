import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// Core
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles(theme => ({

}));

const CartAssistanceModal = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const travelers = useSelector((state) => state.trip.travelers);
    const assistance_lead_pax = useSelector((state) => state.cart.assistance_lead_pax);
    const onLeadPaxChange = (event) => {
        console.log('onLeadPaxChange : ', event.target.value);
    };
    return (
        <Grid container spacing={ 2 }>
            <Grid item xs={ 12 }>
                <FormControl fullWidth required>
                    <InputLabel id={ 'cart-assistance-lead-pax' }>{ t('cart-material.assistance-select-lead-pax') }</InputLabel>
                    <Select labelId={ 'cart-assistance-lead-pax' } label={ t('cart-material.assistance-select-lead-pax') } value={ assistance_lead_pax } onChange={ onLeadPaxChange } renderValue={(selected) => {
                        console.log('selected : ', selected);
                        return 'test';
                    }}>
                        {
                            travelers.map(traveler => (
                                <MenuItem value={ traveler.id }><Checkbox checked={ assistance_lead_pax === traveler.id }/><ListItemText primary={ t('shared.accommodations') }/></MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default CartAssistanceModal;