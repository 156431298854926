import React, { useState } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

type Props = {
    onChangeTestData: (data: Record<string, string>) => void
}

export function MailTemplateTestDataEditor(props: Props): JSX.Element {
    const [data, setData] = useState('{}');
    const [error, setError] = useState<Error | null>(null);

    const onChangeData = (data: string) => {
        setData(data);
        try {
            const parsedData = JSON.parse(data);
            props.onChangeTestData(parsedData);
            setError(null);
        } catch (_error) {
            setError(_error as Error);
        }
    };

    return (
        <div>
            <AceEditor
                mode="json"
                theme="monokai"
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 4
                }}
                width="100%"
                value={data}
                onChange={onChangeData}
                annotations={
                    error ?
                        [
                            {
                                row: 0,
                                column: 0,
                                text: error.message,
                                type: 'error'
                            }
                        ] :
                        undefined
                }
            />
        </div>
    );
}
