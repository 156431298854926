import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core";

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Box } from '@mui/material';
import { isNumber } from 'lodash';

import SelectLead from './SelectLead.js';
import ProductMoreInfo from './ProductMoreInfo.js';
import { isProductPackaged } from '../CartMaterial/utils/isProductPackaged';


const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight700: {
        fontWeight: 700
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize14: {
        fontSize: 14
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter: {
        textAlign: "center"
    },
    spacer: {
        padding: "8px"
    },
    genericBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box"
    },
    genericPadding: {
        padding: "4px 8px"
    }
}));


const BookingCarInfo = ({ tempTravelers, setTempTravelers }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));

    const to_book = useSelector(store => store.cart.to_book);
    const trip = useSelector(store => store.trip.data_trip);

    const [carsToBook, setCarsToBook] = useState([]);
    const [carsMoreInfo, setCarsMoreInfo] = useState([]);
    const connectedPackageProducts = useMemo(() => {
        return carsToBook.filter((car) => {
            return isProductPackaged({
                product: car,
                stackInfos: trip?.stack_info ?? null,
                connected: true
            });
        });
    }, [carsToBook]);

    useEffect(() => {
        let temp_cars = [];
        let more_info = [];

        to_book.map((product) => {
            if (product.is_displayed === true && (product.booking_status === null || (product.booking_status !== null && product.booking_status.status_booking === "ERROR"))) {
                if (product.product_type === 2) {
                    let temp_product = Object.assign({}, product);
                    temp_product.driver = temp_product.driver !== null ? temp_product.driver : { id: "" };
                    temp_cars.push(temp_product);
                    more_info.push(false);
                }
            }
        });

        setCarsToBook(temp_cars);
        setCarsMoreInfo(more_info);
    }, [to_book]);

    const openMoreInfo = (car_index) => {
        let temp_more_info = carsMoreInfo.slice();
        temp_more_info[car_index] = true;
        setCarsMoreInfo(temp_more_info);
    };

    return (
        <Grid>
            {
                carsToBook.length !== 0 && (
                    <Grid className={`${classes.genericPadding} ${classes.genericBorder}`} style={{ marginBottom: 12 }}>
                        <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.upperCase} ${classes.letterSpacing}`}>
                            { t("booking.driver_car") }
                        </Grid>
                        <Grid className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight500} ${classes.fontSize10} ${classes.upperCase} ${classes.letterSpacing}`}>
                            { t("booking.driver_details") }
                        </Grid>
                        <Grid style={{ marginBottom: 8 }}>
                            {
                                carsToBook.filter((car, index, array) => {
                                    return !isProductPackaged({
                                        product: car,
                                        stackInfos: trip?.stack_info ?? null,
                                        connected: true
                                    }) ||
                                    array.slice(0, index).findIndex((car) => {
                                        return isProductPackaged({
                                            product: car,
                                            stackInfos: trip?.stack_info ?? null,
                                            connected: true
                                        });
                                    }) < 0;
                                }).map((car, car_index) => {
                                    return (
                                        <Grid container key={`car-${car.id}`} alignItems="center" justify="space-between" className={classes.spacer}>
                                            <Grid item md={5} xs={11} className={`${classes.genericText} ${classes.fontWeight700} ${classes.fontSize14} ${classes.upperCase} ${classes.letterSpacing}`}>
                                                {
                                                    !isProductPackaged({
                                                        product: car,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    <>
                                                        { t("booking.vehicle") + " - " + (car_index + 1) + " " + car.name } {
                                                            isProductPackaged({
                                                                product: car,
                                                                stackInfos: trip?.stack_info ?? null
                                                            }) &&
                                                            <Box sx={{ color: '#2F80ED' }} component="span">
                                                            ({t('cart-material.cart-construction-package-name', { name: car.stack_number })})
                                                            </Box>
                                                        }
                                                    </>
                                                }
                                                {
                                                    isProductPackaged({
                                                        product: car,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    <Box sx={{ color: '#2F80ED' }} component="span">
                                                        {t('cart-material.cart-construction-connected-package')}
                                                    </Box>
                                                }
                                            </Grid>
                                            <Grid item md={1} xs={1} className={classes.textCenter}>
                                                <IconButton size="small" onClick={() => openMoreInfo(car_index)}>
                                                    <InfoOutlinedIcon />
                                                </IconButton>
                                            </Grid>
                                            <ProductMoreInfo product={car} product_index={car_index} productsMoreInfo={carsMoreInfo} setProductsMoreInfo={setCarsMoreInfo} />
                                            <Grid item md={6} xs={12} style={!downDesktop ? {} : { paddingTop: 8 }}>
                                                {
                                                    !isProductPackaged({
                                                        product: car,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    <SelectLead
                                                        group={car.is_manual ? car.group_passenger : null}
                                                        mode={car.is_manual ? "car" : "driver"}
                                                        product={car}
                                                        product_list={null}
                                                        tempTravelers={tempTravelers}
                                                        setTempTravelers={setTempTravelers}
                                                    />
                                                }
                                                {
                                                    isProductPackaged({
                                                        product: car,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    <SelectLead
                                                        group={car.group_passenger}
                                                        mode={"group"}
                                                        product={null}
                                                        product_list={connectedPackageProducts}
                                                        tempTravelers={tempTravelers}
                                                        setTempTravelers={setTempTravelers}
                                                    />
                                                }
                                            </Grid>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    );
};

export default React.memo(BookingCarInfo);
