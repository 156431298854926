import axios from "axios";
import memoizee from "memoizee";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { Trip } from "../../Menu/MaterialTripList/objects/trip";

async function get(tripId: number): Promise<Trip | null> {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.get(
            `${API_HREF}client/${window.id_owner}/trip/${tripId}/`,
            { headers }
        );
        return response.data;
    }

    return null;
}

export const getTrip = memoizee(
    get,
    { promise: true, primitive: true }
);
