import React, { useRef } from "react";
import { styled } from '@mui/material/styles';
import { useDrag, useDrop } from 'react-dnd';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CrmUserDetailContactDetails from "../CrmUserDetail/CrmCustomerCard/CrmUserDetailContactDetails";
import CrmUserDetailCompanion from "../CrmUserDetail/CrmCustomerCard/CrmUserDetailCompanion";
import CrmUserDetailDocuments from "../CrmUserDetail/CrmCustomerCard/CrmUserDetailCompanion";
import CrmUserDetailContact from "../CrmUserDetail/CrmCustomerCard/CrmUserDetailContact";
import CrmDemandDescription from "../CrmDemandDetail/CrmDemandDescription";
import CrmDemandDocuments from "../CrmDemandDetail/CrmDemandDocuments";
import CrmDemandContacts from "../CrmDemandDetail/CrmDemandContacts";
import CrmDemandRooms from "../CrmDemandDetail/CrmDemandRooms";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    "border": `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    "backgroundColor": theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    "flexDirection": 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

const CrmUserDetailSidePanelItem = (props) => {
    //ACCORDION
    const [expanded, setExpanded] = React.useState(props.expanded ? props.expanded : 'contact');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    //DRAG & DROP
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId()
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            if (props.reorder !== null && props.reorder !== undefined) {
                props.reorder(dragIndex, hoverIndex);
            }
            item.index = hoverIndex;
        }
    });
    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => {
            return { id: props.id, index: props.index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        previewOptions: { anchorX: 0, anchorY: 0, offsetX: 0, offsetY: 0 }
    });
    drag(ref);

    return (
        <div ref={drop} style={{ borderBottom: isDragging ? "1px solid #1976d2" : "none" }}>
            <Accordion expanded={expanded === props.id} onChange={handleChange(props.id)}>
                <div ref={ref} data-handler-id={handlerId}>
                    <AccordionSummary className="draggable-accordion-summary">
                        <DragIndicatorIcon className="drag-svg" />
                        <h5>{props.title}</h5>
                    </AccordionSummary>
                </div>
                <AccordionDetails>
                    {props.id === "contact" && <CrmUserDetailContact user={props.user} />}
                    {props.id === "contact_detail" && <CrmUserDetailContactDetails user={props.user} />}
                    {props.id === "companion" && <CrmUserDetailCompanion user={props.user} />}
                    {props.id === "documents" && <CrmUserDetailDocuments user={props.user} />}
                    {props.id === "demand_description" && <CrmDemandDescription />}
                    {props.id === "demand_contacts" && <CrmDemandContacts />}
                    {props.id === "demand_documents" && <CrmDemandDocuments />}
                    {props.id === "demand_rooms" && <CrmDemandRooms />}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default CrmUserDetailSidePanelItem;
