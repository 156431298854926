//---- Actions ----//
import moment from "moment";
import {UpdateFlightList} from "../../../Actions/FlightSearch"

export default (value, flight_list_render, dispatch) => {
	let tmp_flight_list_render = flight_list_render.slice();
	
	let rev = value % 2 === 0 ? -1 : 1;
	tmp_flight_list_render.sort(function (a, b) {
		let keyA = null;
		let keyB = null;

		switch (value) {
			case 1:
			case 2:
				keyA = Number(a.total_price);
				keyB = Number(b.total_price);
				break;
			case 5:
			case 6:
				let total_duration_a = 0;
				let total_duration_b = 0;
				a.outbounds.map((outbounds) => {
					if (outbounds.stopover_duration.length !== 0) {
						outbounds.stopover_duration.map((stopover_duration) => {
							total_duration_a += ((moment.utc(moment(stopover_duration)).hours() * 60) * 60) + (moment.utc(moment(stopover_duration)).minutes() * 60);
						});
					}
					total_duration_a += outbounds.flight_duration;
				});
				b.outbounds.map((outbounds) => {
					if (outbounds.stopover_duration.length !== 0) {
						outbounds.stopover_duration.map((stopover_duration) => {
							total_duration_b += ((moment.utc(moment(stopover_duration)).hours() * 60) * 60) + (moment.utc(moment(stopover_duration)).minutes() * 60);
						});
					}
					total_duration_b += outbounds.flight_duration;
				});
				keyA = total_duration_a;
				keyB = total_duration_b;
				break;
			case 11:
			case 12:
				keyA = a.outbounds[0].flight_duration;
				keyB = b.outbounds[0].flight_duration;
				break;
			case 13:
			case 14:
				keyA = a.outbounds[1].flight_duration;
				keyB = b.outbounds[1].flight_duration;
				break;
			case 7:
			case 8:
			case 15:
			case 16:
				keyA = a.outbounds[0].legs[0].departure_datetime_lt;
				keyB = b.outbounds[0].legs[0].departure_datetime_lt;
				break;
			case 17:
			case 18:
				keyA = a.outbounds[1].legs[0].departure_datetime_lt;
				keyB = b.outbounds[1].legs[0].departure_datetime_lt;
				break;
			case 9:
			case 10:
			case 19:
			case 20:
				keyA = a.outbounds[0].legs[a.outbounds[0].legs.length - 1].arrival_datetime_lt;
				keyB = b.outbounds[0].legs[b.outbounds[0].legs.length - 1].arrival_datetime_lt;
				break;
			case 21:
			case 22:
				keyA = a.outbounds[1].legs[a.outbounds[0].legs.length - 1].arrival_datetime_lt;
				keyB = b.outbounds[1].legs[b.outbounds[0].legs.length - 1].arrival_datetime_lt;
				break;
		}
		return ((keyA < keyB) ? -1 : ((keyA > keyB) ? 1 : 0)) * rev;
	});
	dispatch(UpdateFlightList(tmp_flight_list_render, value));
}