export default (cookie_name) => {
    cookie_name += "=";
    let cookie = decodeURIComponent(document.cookie);
    let cookie_array = cookie.split(";");
    for (let i = 0; i < cookie_array.length; i++) {
        while (cookie_array[i].charAt(0) === " ") {
            cookie_array[i] = cookie_array[i].substring(1);
        }
        if (cookie_array[i].indexOf(cookie_name) === 0) {
            return cookie_array[i].substring(cookie_name.length, cookie_array[i].length);
        }
    }
    return "";
}