import { useMemo } from "react";
import { useSelector } from "react-redux";
import { intersection } from "lodash";
import { useProductDays } from "./productDays";
import { sortItinerary } from "../../Itinerary/utils/sortItinerary";
import GetCookie from "../../Common/Functions/GetCookie";
import { ItineraryContentStep } from "../../Itinerary/objects/itineraryContentStep";
import { AppState } from "../../../Reducers/Reducers";

type Callback = (startDate: string, endDate: string) => boolean

export function useApplyDaysFilter(): Callback {
    const versionString = GetCookie("trip_id_version");
    const version = versionString ? parseInt(versionString) : null;
    const step = useSelector((state: AppState) => state.cartConstruction.step);
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const itineraryContent = useSelector((state: AppState) => {
        return version ?
            state.itinerarySlice.content[version] :
            undefined;
    });
    const steps = useMemo(() => {
        return itinerary.filter((item) => {
            return item.step_type === 'STEP';
        }).sort(sortItinerary);
    }, [itinerary]);
    const getDays = useProductDays();
    const correspondingStep = itineraryContent?.state === 'success' && itineraryContent.data.content[step] ?
        {
            mode: itineraryContent.data.mode,
            content: itineraryContent.data.content[step]
        } as ItineraryContentStep :
        null;

    return useMemo(() => {
        return (startDate, endDate) => {
            if (!correspondingStep) {
                return false;
            }
    
            if (correspondingStep.mode === 'by-day') {
                return intersection(
                    correspondingStep.content.day,
                    getDays(startDate, endDate)
                ).length > 0;
            }
    
            const itineraryStep = steps[step];
            if (itineraryStep) {
                const stepDays = getDays(itineraryStep.start_date, itineraryStep.end_date);
                const productStartDay = getDays(startDate, endDate)[0];
                return !!productStartDay && stepDays.includes(productStartDay);
            }
    
            return false;
        };
    }, [
        step,
        itinerary,
        itineraryContent?.state === 'success' ? itineraryContent.data : null,
        getDays
    ]);
}
