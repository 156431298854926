//---- Description : Notification center ----//
//---- Contact : kevin.deffontaine@facilitatrip.com ----//

export default function($message, $option) {
    let message = $message,
        options = $option;
    if (!message)
        $.error('Notify: No message specified');
    $.notify(message, options || {});
}

(function($) {
    let containers = {},
        messages = {},
        notify = function(options) {
            if ($.type(options) === 'string') {
                options = {
                    message: options
                };
            }
            if (arguments[1]) {
                options = $.extend(options, $.type(arguments[1]) === 'string' ? {
                    status: arguments[1]
                } : arguments[1]);
            }
            return (new Message(options)).show();
        },
        closeAll = function(group, instantly) {
            if (group) {
                for (let id in messages) {
                    if (group === messages[id].group) messages[id].close(instantly);
                }
            } else {
                for (let id in messages) {
                    messages[id].close(instantly);
                }
            }
        };
    let Message = function(options) {
        this.options = $.extend({}, Message.defaults, options);
        this.uuid = "ID" + (new Date().getTime()) + "RAND" + (Math.ceil(Math.random() * 100000));
        this.element = $([
            '<div class="uk-notify-message alert-dismissable">',
            '<a class="close">&times;</a>',
            '<div>' + this.options.message + '</div>',
            '</div>'
        ].join('')).data("notifyMessage", this);
        if (this.options.status) {
            this.element.addClass('alert alert-' + this.options.status);
            this.currentstatus = this.options.status;
        }
        this.group = this.options.group;
        messages[this.uuid] = this;
        if (!containers[this.options.pos]) {
            containers[this.options.pos] = $('<div class="uk-notify uk-notify-' + this.options.pos + '"></div>').appendTo('body').on("click", ".uk-notify-message", function() {
                $(this).data("notifyMessage").close();
            });
        }
    };
    $.extend(Message.prototype, {
        uuid: false,
        element: false,
        timout: false,
        currentstatus: "",
        group: false,
        show: function() {
            if (this.element.is(":visible")) return;
            let $this = this;
            containers[this.options.pos].show().prepend(this.element);
            let marginbottom = parseInt(this.element.css("margin-bottom"), 10);
            this.element.css({
                "opacity": 0,
                "margin-top": -1 * this.element.outerHeight(),
                "z-index": 10,
                "margin-bottom": 0,
                "width": "400px"
            }).animate({
                "opacity": 1,
                "margin-top": 0,
                "z-index": 10,
                "margin-bottom": marginbottom
            }, function() {
                if ($this.options.timeout) {
                    let closefn = function() {
                        $this.close();
                    };
                    $this.timeout = setTimeout(closefn, $this.options.timeout);
                    $this.element.hover(
                        function() {
                            clearTimeout($this.timeout);
                        },
                        function() {
                            $this.timeout = setTimeout(closefn, $this.options.timeout);
                        }
                    );
                }
            });
            return this;
        },
        close: function(instantly) {
            let $this = this,
                finalize = function() {
                    $this.element.remove();
                    if (!containers[$this.options.pos].children().length) {
                        containers[$this.options.pos].hide();
                    }
                    delete messages[$this.uuid];
                };
            if (this.timeout) clearTimeout(this.timeout);

            if (instantly) {
                finalize();
            } else {
                this.element.animate({
                    "opacity": 0,
                    "margin-top": -1 * this.element.outerHeight(),
                    "z-index": 10,
                    "margin-bottom": 0
                }, function() {
                    finalize();
                });
            }
        },
        content: function(html) {
            let container = this.element.find(">div");
            if (!html) {
                return container.html();
            }
            container.html(html);
            return this;
        },
        status: function(status) {
            if (!status) {
                return this.currentstatus;
            }
            this.element.removeClass('alert alert-' + this.currentstatus).addClass('alert alert-' + status);
            this.currentstatus = status;
            return this;
        }
    });
    Message.defaults = {
        message: "",
        status: "normal",
        timeout: 7500,
        group: null,
        pos: 'top-center'
    };
    $["notify"] = notify;
    $["notify"].message = Message;
    $["notify"].closeAll = closeAll;
    return notify;
}(jQuery, window, document));