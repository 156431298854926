//----- Functions -----//
import UpdateDestinationsDates from "./UpdateDestinationsDates";

export default(selected_route, transport, index, itinerary_list, plane, dispatch, t) => {
	let plane_departure_time = null;
	let plane_arrival_time = null;
	let add_days = 0;
    if (plane !== null) {
        if (plane.outbound !== undefined) {
            plane_departure_time = plane.outbound[0].hops[0].depTime.split(":");
            plane_arrival_time = plane.outbound[0].hops[plane.outbound[0].hops.length - 1].arrTime.split(":");
            for (let i = 0; i < plane.outbound[0].hops.length; i++) {
                if (plane.outbound[0].hops[i].dayChange !== undefined) {
                    add_days += plane.outbound[0].hops[i].dayChange;
                }
            }
        } else {
            plane = null;
        }
	}
    if (plane !== null) {
        let destination_end_date = moment.utc(itinerary_list[index].end_date).set({"hour": plane_departure_time[0], "minute": plane_departure_time[1]});
        let destination_start_date = moment.utc(itinerary_list[index].end_date).set({"hour": plane_departure_time[0], "minute": plane_departure_time[1]}).add(add_days, "d").set({"hour": plane_arrival_time[0], "minute": plane_arrival_time[1]});
        UpdateDestinationsDates(itinerary_list, index, dispatch, t, {end_date: destination_end_date.format(), r2r_json: transport, r2r_api_version: "1.4", duration_transport_after: transport.duration, distance_transport_km: parseInt(transport.distance), selected_transport: transport.duration + "-" + transport.distance}, {start_date: destination_start_date.format()});
    } else {
        let destination_end_date = moment.utc(itinerary_list[index].end_date).set({"hour": "09", "minute": "00"});
        let destination_start_date = moment.utc(itinerary_list[index].end_date).set({"hour": "09", "minute": "00"}).add(selected_route.totalDuration, "minutes");
        UpdateDestinationsDates(itinerary_list, index, dispatch, t, {end_date: destination_end_date.format(), r2r_json: transport, r2r_api_version: "1.4", duration_transport_after: transport.duration, distance_transport_km: parseInt(transport.distance), selected_transport: transport.duration + "-" + transport.distance}, {start_date: destination_start_date.format()});
    }
}