import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";
import { ItineraryDestinationInfoCarousel } from "./itineraryDestinationInfoCarousel";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { ToggleMoreInfoModal } from "../../Actions/Itinerary";
import { AppState } from "../../Reducers/Reducers";

export function ItineraryDestinationInfoModal(): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const modal_info = useSelector((state: AppState) => state.itinerary.modal_info);
    const destination_info = useSelector((state: AppState) => state.itinerary.destination_info);
    const [rubrics, setRubrics] = useState<string[]>([]);
    const [descriptions, setDescriptions] = useState<string[]>([]);
    const container_ref = useRef<HTMLDivElement>(null);
    const content_ref = useRef<HTMLDivElement[]>([]);
    let nb_days: string | null = null;
    let nb_hours = null;

    if (destination_info?.data?.suggested_hours && destination_info.data.suggested_hours > 0) {
        nb_days = (destination_info.data.suggested_hours / 24).toFixed(0);
        nb_hours = (destination_info.data.suggested_hours % 24);
    }

    //@TODO: implement this
    /*const checkCurrentContent = () => {
        if (container_ref.current) {
            let current_index = 0;
            for (let i = 0; i < content_ref.current.length; i++) {
                if ((container_ref.current.getBoundingClientRect().top - (content_ref.current[i]?.getBoundingClientRect().top ?? 0)) >= 0) {
                    current_index = i;
                }
            }
            if (rubric_ref.current.length > 0) {
                rubric_ref.current[current_index].className = "active";
            }
        }
    }; */

    useEffect(() => {
        if (!modal_info) {
            setRubrics([]);
            setDescriptions([]);
        }
    }, [modal_info]);

    useEffect(() => {
        (async () => {
            if (destination_info) {
                const { pass_check, headers } = CheckBeforeRequest();
                if (pass_check) {
                    try {
                        const response = await axios.get<{results: {description: string, rubric: {name: string} | null}[]}>(
                            `${API_HREF}client/${window.id_owner}/guides/${destination_info.guide_ids[0]}/rubrics/`,
                            { headers }
                        );
                        setRubrics(response.data.results.map((item) => item.rubric?.name).filter((item) => item) as string[]);
                        setDescriptions(response.data.results.map((item) => item.description));
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        })();
    }, [destination_info]);

    return (
        <Modal id={ "modal-more-info-main" } isOpen={ modal_info } toggle={() => {
            dispatch(ToggleMoreInfoModal(!modal_info, null, null)); 
        }} size={ "lg" }>
            <ModalHeader toggle={() => {
                dispatch(ToggleMoreInfoModal(!modal_info, null, null)); 
            }}>
                <div id={ "modal-more-info-header" }>
                    <h1 className={ "title-more-info" }>{ destination_info?.data?.name ? destination_info.data.name : destination_info?.data?.international_name }</h1>
                    {
                        destination_info &&
                    <ItineraryDestinationInfoCarousel pictures={ destination_info.data?.pictures ?? [] }/>
                    }
                    <div className={ "header-body-sep" }/>
                </div>
            </ModalHeader>
            <ModalBody className={ "grey-bg" }>
                <div id={ "modal-more-info" } className={ "row" }>
                    <div className={ "col-md-4" }>
                        <div className={ "left-container" }>
                            <div>
                                <h3>{ t<string>("global.informations") }</h3>
                                <h4><i className={ "icon-023-visits" }/>{ t<string>("global.name") }</h4>
                                <h5>{ destination_info?.data?.name ? destination_info.data.name : destination_info?.data?.international_name }</h5>
                                <div className={ "infos-sep" }/>
                                <h4><i className={ "icon-028-pin" }/>{ t<string>("global.country") }</h4>
                                <h5>{ destination_info?.parent?.data?.name ? destination_info.parent.data.name : destination_info?.parent?.data?.international_name }</h5>
                                {
                                    destination_info?.categories && destination_info.categories.length > 0 &&
                                <div>
                                    <div className={ "infos-sep" }/>
                                    <h4><i className={ "icon-012-sign" }/>{ t<string>("global.categories") }</h4>
                                    <div className={ "row ft-no-margin" }>
                                        {
                                            destination_info.categories.map((categories, index) => {
                                                return (
                                                    <span key={ index } className={ "categories-infos" }>{ categories.name }</span>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                }
                                <div className={ "infos-sep ft-mt10" }/>
                                {
                                    (nb_days !== null || nb_hours !== null) && (
                                        <div>
                                            <h4><i className={ "icon-069-time" }/>{ t<string>("itinerary.suggested_duration") }</h4>
                                            {
                                                nb_days !== null && parseFloat(nb_days) > 0 && parseFloat(nb_days) < 2 && (
                                                    <h5>1 { t<string>("global.day") }</h5>
                                                )
                                            }
                                            {
                                                nb_days !== null && parseFloat(nb_days) >= 2 && (
                                                    <h5>{ nb_days } { t<string>("global.days") } </h5>
                                                )
                                            }
                                            {
                                                nb_hours !== null && nb_hours > 0 && (
                                                    <h5>{ nb_hours }{ t<string>("global.abrev_hour") } </h5>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            <div className={ "mt30" }>
                                <h3>{ t<string>("global.summary") }</h3>
                                <div id={ "container-tab-info" }>
                                    {
                                        rubrics.map((rubric, index) => {
                                            return (
                                                <div
                                                    key={ index }
                                                    className={ "tab-info" }
                                                    onClick={(event) => event.currentTarget.scrollIntoView({ block: 'start', behavior: 'smooth' })}
                                                >
                                                    { rubric }
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={ "col-md-8" }>
                        <div className={ "right-container" }>
                            <div
                                ref={container_ref}
                                id={ "guide-content" }
                                onScroll={ () => {
                                /*checkCurrentContent();  */
                                } }
                            >
                                {
                                    descriptions.map((description, index) => {
                                        return (
                                            <div
                                                key={ index }
                                                ref={ (node) => {
                                                    if (node) {
                                                        content_ref.current.push(node); 
                                                    }
                                                } }
                                                dangerouslySetInnerHTML={ { __html: description } }
                                            />
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}
