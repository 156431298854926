import React, { useState } from "react";
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//Core
import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import { useCartTotalPrices } from './utils/cartTotalPrices';

const useStyles = makeStyles({
    spinRequestContainer: {
        position: 'relative'
    },
    spinRequest: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -16
    }
});

const CartAssistanceValidate = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [spinRequest, setSpinRequest] = useState(false);
    const assistance_package = useSelector(state => state.cart.assistance_package);
    const assistance_travelers_type = useSelector(state => state.cart.assistance_travelers_type);
    const assistance_pax = useSelector(state => state.cart.assistance_pax);
    const assistance_type = useSelector(state => state.cart.assistance_type);
    const assistance_prices = useSelector(state => state.cart.assistance_prices);
    const assistance_index = useSelector(state => state.cart.assistance_index);
    const travelers = useSelector(state => state.trip.travelers);
    const total_cost = useSelector(state => state.trip.total_cost);
    const providers = useSelector((state) => state.trip.providers);
    const start_date = useSelector((state) => state.trip.start_date);
    const end_date = useSelector((state) => state.trip.end_date);
    const data_trip = useSelector((state) => state.trip.data_trip);
    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;
    const prices = useCartTotalPrices();
    const onGetEstimation = () => {
        const { headers } = CheckBeforeRequest();
        for (let i = 0; i < providers.length; i++) {
            if (assistance_type === 1 && providers[i].provider.code === "webassur") {
                setSpinRequest(true);
                axios({
                    method: 'POST',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/assistance/`,
                    data: {
                        provider_id: providers[i].provider.id,
                        custom_provider: false,
                        trip_price: Math.ceil((total_cost / travelers.length) * assistance_pax.length),
                        travelers_to_attach: assistance_pax,
                        contract_reference: assistance_index === 0 ? quotation_code === 'cercledesvoyages' ? '4181-1' : quotation_code === 'americavoyages' ? '609.941G' : '' : assistance_index === 1 ? quotation_code === 'cercledesvoyages' ? '4182-1' : quotation_code === 'americavoyages' ? '602.941.0001-1' : '' : quotation_code === 'cercledesvoyages' ? '9602-1' : ''
                    }
                }).then(response => {
                    setSpinRequest(false);
                    enqueueSnackbar(t('cart-material.insurance-added'), { variant: 'success' });
                    dispatch({ type: 'CART_ASSISTANCE_ADD_TO_CART', payload: response.data });
                }).catch(error => {
                    //TODO add error notification
                    setSpinRequest(false);
                    enqueueSnackbar(t('cart-material.insurance-added-error'), { variant: 'danger' });
                    console.log(error);
                });
                break;
            } else if (assistance_type === 0 && providers[i].provider.code === "onspot") {
                setSpinRequest(true);
                axios({
                    method: 'POST',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/assistance/`,
                    data: {
                        provider_id: providers[i].provider.id,
                        custom_provider: false,
                        //"group_passenger": 17020,
                        //"start_date": moment.utc(start_date).format("YYYY-MM-DD"),
                        //"end_date": moment.utc(end_date).format("YYYY-MM-DD"),
                        //"option_vip": assistance_vip,
                        type_of_package: assistance_package,
                        type_of_traveler: assistance_travelers_type,
                        agent_email: 'laurie@noxcod.com'
                        //"optional_service": "Pocket wifi",
                        //"adress_of_delivry": "Airport pickup",
                        //"adress_airport": 205
                    }
                }).then(response => {
                    setSpinRequest(false);
                    enqueueSnackbar(t('cart-material.assistance-added'), { variant: 'success' });
                    dispatch({ type: 'CART_ASSISTANCE_ADD_TO_CART', payload: response.data });
                }).catch(error => {
                    //TODO add error notification
                    setSpinRequest(false);
                    enqueueSnackbar(t('cart-material.assistance-added-error'), { variant: 'danger' });
                    console.log(error);
                });
                break;
            } else if (assistance_type === 1 && providers[i].provider.code === "gritchen") {
                setSpinRequest(true);
                axios({
                    method: 'POST',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/insurance/`,
                    data: {
                        provider_id: providers[i].provider.id,
                        reference: assistance_prices.insurances[assistance_index].reference,
                        selling_currency: data_trip.trip.currency.iso_code,
                        insurance: {
                            contract_name: assistance_prices.insurances[assistance_index].contract_name,
                            contract_ref: assistance_prices.insurances[assistance_index].contract_ref,
                            price_code: assistance_prices.insurances[assistance_index].price,
                            travelers: assistance_pax.map(traveler => {
                                return {
                                    traveler_id: traveler,
                                    price_cents: Math.ceil((prices.totalCartCost / travelers.length)) * 100,
                                    price: parseFloat((parseFloat(assistance_prices.insurances[assistance_index].price) / assistance_pax.length).toFixed(0)),
                                    currency: data_trip.trip.currency.iso_code
                                };
                            })
                        }
                        //total_price: Math.ceil((total_cost / travelers.length) * assistance_pax.length),
                        //start_date: moment.utc(start_date).format("YYYY-MM-DD"),
                        //end_date: moment.utc(end_date).format("YYYY-MM-DD"),
                        //origin: "",
                        //destination: data_trip?.destination.id,
                        //travelers: assistance_pax,
                    }
                }).then(response => {
                    setSpinRequest(false);
                    enqueueSnackbar(t('cart-material.insurance-added'), { variant: 'success' });
                    dispatch({ type: 'CART_ASSISTANCE_ADD_TO_CART', payload: response.data });
                }).catch(error => {
                    //TODO add error notification
                    setSpinRequest(false);
                    enqueueSnackbar(t('cart-material.insurance-added-error'), { variant: 'danger' });
                    console.log(error);
                });
                break;
            }
        }
    };
    return (
        <div className={ classes.spinRequestContainer }>
            <Button variant="outlined" onClick={ onGetEstimation } disabled={ spinRequest }>
                { t('cart-material.assistance-add-to-cart') }
            </Button>
            { spinRequest && <CircularProgress size={ 24 } className={ classes.spinRequest }/> }
        </div>
    );
};

export default CartAssistanceValidate;
