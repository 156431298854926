/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import _ from 'lodash';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        "id": `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const CrmDemandActivity = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const active_user_notes = useSelector(store => store.crm.active_user_notes);
    const user = useSelector(store => store.user.user);
    const selected_user = useSelector(store => store.crm.selected_user);

    const [value, setValue] = React.useState(0);
    const [emails, setEmails] = React.useState([]);
    const [mappedNotes, setMappedNotes] = React.useState([]);
    const [mappedRemarks, setMappedRemarks] = React.useState([]);
    const [mappedEmails, setMappedEmails] = React.useState([]);
    const [mappedAllActivities, setMappedAllActivities] = React.useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const searchInput = () => {
        return (
            <TextField
                label={t("crm.search")}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
                variant="outlined"
                size="small"
            />
        );
    };

    const createNote = (is_comment) => {
        // dispatch(SetSelectedNote({ id: -1, is_comment }));
    };
    const createEmail = () => {
        // dispatch(SetSelectedEmail({ id: -1 }));
    };

    //HANDLE PORPS ACTIONS
    useEffect(() => {
        if (props.action) {
            switch (props.action) {
                case "note":
                    handleChange(null, 1);
                    createNote(false);
                    break;
                case "remark":
                    handleChange(null, 2);
                    createNote(true);
                    break;
                case "email":
                    handleChange(null, 3);
                    break;
                default:
                    break;
            }
        }
    }, [props.action]);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label={t("crm.title.activity")} {...a11yProps(0)} />
                    <Tab label={t("crm.title.notes")} {...a11yProps(1)} />
                    <Tab label={t("crm.title.remarks")} {...a11yProps(2)} />
                    <Tab label={t("crm.title.emails")} {...a11yProps(3)} />
                    <Tab label={t("crm.title.tasks")} {...a11yProps(4)} />
                    <Tab label={t("crm.title.meetings")} {...a11yProps(5)} />
                    <Tab label={t("crm.title.calls")} {...a11yProps(6)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} className="activities-tab">
                <div className="activity-header">
                    {searchInput()}
                </div>
                <div className="activity-content">
                    
                </div>
            </TabPanel>
            <TabPanel value={value} index={1} className="activities-tab">
                <div className="activity-header">
                    {searchInput()}
                    <Button variant="contained" onClick={() => createNote(false)}>{t("crm.create_note")}</Button>
                </div>
                <div className="activity-content">
                    
                </div>
            </TabPanel>
            <TabPanel value={value} index={2} className="activities-tab">
                <div className="activity-header">
                    {searchInput()}
                    <Button variant="contained" onClick={() => createNote(true)}>{t("crm.create_remark")}</Button>
                </div>
                <div className="activity-content">
                    
                </div>
            </TabPanel>
            <TabPanel value={value} index={3} className="activities-tab">
                <div className="activity-header">
                    {searchInput()}
                    
                </div>
                <div className="activity-content activity-mail-content">
                    {/* <TripListCommunication customData={{ isFromCrm: true, callback: makeRequest }} /> */}
                </div>
            </TabPanel>
            <TabPanel value={value} index={4} className="activities-tab">
                <div className="activity-header">
                    {searchInput()}
                    <Button variant="contained">{t("crm.create_task")}</Button>
                </div>
            </TabPanel>
            <TabPanel value={value} index={5} className="activities-tab">
                <div className="activity-header">
                    {searchInput()}
                    <Button variant="contained">{t("crm.create_meeting")}</Button>
                </div>
            </TabPanel>
            <TabPanel value={value} index={6} className="activities-tab">
                <div className="activity-header">
                    {searchInput()}
                    <Button variant="contained">{t("crm.create_call")}</Button>
                </div>
            </TabPanel>
        </>
    );
};
export default React.memo(CrmDemandActivity);
