import i18n from "../../../../i18n.jsx";
import axios from "axios";

//---- Actions ----//
import { SetUserList } from "../../../../Actions/User";
import GetCookie from "../../../Common/Functions/GetCookie";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest.js";

export default (dispatch, client) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client}/user/`,
        }).then(function (response) {
            dispatch(SetUserList(response.data.results));
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
}

