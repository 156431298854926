//---- Dependencies ----//
import React from 'react';
import Slider from 'react-slick';

//---- Actions ----//
import { ToggleAndSetPicturesModal } from "../../../Actions/Summary";

class RoomPicturesSlider extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            settings: {
                dots: false,
                arrows: true,
                fade: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                speed: 500,
                autoplaySpeed: 8000,
                cssEase: "linear",
                adaptiveHeight: true
            }
        }
    }
    render() {
        const { dispatch, index, pictures } = this.props;
        console.log('pictures:', pictures);
        return (
            <div id="room-pictures-slider">
                <Slider { ...this.state.settings }>
                {
                    pictures.map((img, img_index) => {
                        return (
                            <div key={img_index} >
                                <img className={ "accommodation-card-choose-room-room-picture-mobile" } onClick={() => { dispatch(ToggleAndSetPicturesModal(pictures, 0)) }} src={ (img.thumbnail_big !== undefined && img.thumbnail_big !== null) ? img.thumbnail_big : ((img.url !== undefined && img.url !== null) ? img.url : "/Img/accommodation_default.jpg") } height={200}/>
                            </div>
                        )
                    })
                }
                {
                    pictures.length === 0 && (
                        <div>
                            <img height={200} className={ "accommodation-card-choose-room-room-picture-mobile" } src={ "/Img/accommodation_default.jpg" } />
                        </div>
                    )
                }
                </Slider>
            </div>
        );
    }
}

export default RoomPicturesSlider;
