import React, { useEffect, useState, useRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DeleteLoyaltyCard from "./DeleteLoyaltyCard";

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

import axios from "axios";

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400: {
        fontWeight: 400
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize16: {
        fontSize: 16
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter: {
        textAlign: "center"
    },
    formControlRoot1: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 15,
            zIndex: -1
        }
    },
    formControlRootMobile1: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        }
    },
    formControlRootSmall: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    formControlRootAutoComplete: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 15,
            paddingTop: 10,
            zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-shrink": {
            fontWeight: 400,
            fontSize: 15,
            paddingTop: 0
        },
        "& .mui-jss-MuiAutocomplete-endAdornment": {
            paddingRight: 15
        }
    },
    formControlRootAutoCompleteMobile: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            paddingTop: 10,
            zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-shrink": {
            fontWeight: 400,
            fontSize: 12,
            paddingTop: 0
        },
        "& .mui-jss-MuiAutocomplete-endAdornment": {
            paddingRight: 15
        }
    },
    formControlRootAutoCompleteSmall: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            paddingTop: 10,
            zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(14px, -1px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    },
        "& .mui-jss-MuiInputLabel-shrink": {
            fontWeight: 400,
            fontSize: 12,
            paddingTop: 0
        },
        "& .mui-jss-MuiAutocomplete-endAdornment": {
            paddingRight: 15
        }
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    outlinedInput: {
        padding: "7px 30px 7px 20px;"
    },
    circleBorder: {
        borderRadius: 8
    }
}));

const LoyaltyCard = (({ card, card_index, traveler, tempTravelers, setTempTravelers, size, isFrom }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));

    const data_trip = useSelector((store) => store.trip.data_trip);

    const loyaltyTypeLabel = useRef(null);
    const loyaltyTypeLabelWidth = loyaltyTypeLabel.current ? loyaltyTypeLabel.current.clientWidth : 98;
    const supplierLabel = useRef(null);
    const supplierLabelWidth = supplierLabel.current ? supplierLabel.current.clientWidth : 203;
    const numberLabel = useRef(null);
    const numberLabelWidth = numberLabel.current ? numberLabel.current.clientWidth : 114;

    const [flightSupplier, setFlightSupplier] = useState(null);
    const [flightSupplierInput, setFlightSupplierInput] = useState("");
    const [flightSupplierResults, setFlightSupplierResults] = useState([]);
    const [openDeleteCard, setOpenDeleteCard] = useState(false);

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const isDisabled = ['cercledesvoyages', 'continentsinsolites', 'tropicalementvotre', 'connections', 'terreslointaines'].includes(quotation_code) ? (data_trip !== null && data_trip.status_contract === "CONFIRMED" && data_trip.status_modification === "FIXED_PV" && isFrom === "tripList") : false;

    useEffect(() =>{
        if (card.product_type === 6) {
            if (card.supplier !== "" && card.airline === null) {
                let { pass_check, headers } = CheckBeforeRequest();
                if (pass_check) {
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}iata-airlines/?search=${card.supplier}&limit=1`
                    }).then(function (response) {
                        let data = response.data.results;
                        if (data.length > 0) {
                            setFlightSupplier(data[0]);
                        }
                    }).catch(function (error) {
                        console.log(error.responseJSON);
                    });
                }
            } else if (card.airline !== null) {
                setFlightSupplier(card.airline);
            }
        }
    }, [card]);

    useEffect(() =>{
        if (flightSupplierInput.length >= 3) {
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}iata-airlines/?search=${flightSupplierInput}`
                }).then(function (response) {
                    let data = response.data.results;
                    if (data.length > 0) {
                        data.push("");
                        setFlightSupplierResults(data);
                    }
                }).catch(function (error) {
                    console.log(error.responseJSON);
                });
            }
        }
    }, [flightSupplierInput]);

    const handleType = (e) => {
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let newTravelers = tempTravelers.slice();
                card.product_type = e.target.value;
                traveler.loyalty_cards[card_index] = card;
                newTravelers[traveler_index] = traveler;
                setTempTravelers(newTravelers);
            }
        }
    };

    const handleSupplier = (e) => {
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let newTravelers = tempTravelers.slice();
                card.supplier = e.target.value;
                traveler.loyalty_cards[card_index] = card;
                newTravelers[traveler_index] = traveler;
                setTempTravelers(newTravelers);
            }
        }
    };

    const handleFlightSupplier = (value) => {
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let newTravelers = tempTravelers.slice();
                card.airline = value !== null ? value.id : undefined;
                card.supplier = value !== null ? value.airline_code : "";
                traveler.loyalty_cards[card_index] = card;
                newTravelers[traveler_index] = traveler;
                setTempTravelers(newTravelers);
                setFlightSupplier(value);
            }
        }
    };

    const handleNumber = (e) => {
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let newTravelers = tempTravelers.slice();
                card.card_number = e.target.value;
                traveler.loyalty_cards[card_index] = card;
                newTravelers[traveler_index] = traveler;
                setTempTravelers(newTravelers);
            }
        }
    };

    const deleteCard = () => {
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let newTravelers = tempTravelers.slice();
                traveler.loyalty_cards.splice(card_index, 1);
                newTravelers[traveler_index] = traveler;
                setTempTravelers(newTravelers);
            }
        }
    };
    if (downDesktop) {
        return (
            <Grid container alignItems="flex-start" justify="space-between" direction="column" style={{ padding: "10px 0px", marginTop: 10, borderTop: "0.5px solid rgba(0, 0, 0, 0.25)" }} spacing={2}>
                <Grid item style={{ width: "100%" }}>
                    <FormControl variant="outlined" className={size === "small" ? classes.formControlRootSmall : classes.formControlRootMobile1}>
                        <InputLabel id={`loyalty-type-label-${traveler.id}-${card_index}`} ref={loyaltyTypeLabel} className={classes.fontSize16}>
                            {t("menu.trip_list.card_type")}
                        </InputLabel>
                        <Select
                            disabled={isDisabled}
                            labelId={`loyalty-type-label-${traveler.id}-${card_index}`}
                            id={`loyalty-type-${traveler.id}-${card_index}`}
                            value={card.product_type}
                            className={classes.circleBorder}
                            onChange={handleType}
                            MenuProps={{ disableScrollLock: true }}
                            input={
                                <OutlinedInput
                                    name="loyalty-type"
                                    id={`loyalty-type-input-${traveler.id}-${card_index}`}
                                    labelWidth={loyaltyTypeLabelWidth}
                                />
                            }>
                            <MenuItem value={0}>
                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                    {t("global.hotel")}
                                </Grid>
                            </MenuItem>
                            <MenuItem value={2}>
                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                    {t("providers.rental_car")}
                                </Grid>
                            </MenuItem>
                            <MenuItem value={4}>
                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                    {t("providers.transfers")}
                                </Grid>
                            </MenuItem>
                            <MenuItem value={5}>
                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                    {t("providers.trains")}
                                </Grid>
                            </MenuItem>
                            <MenuItem value={6}>
                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                    {t("global.flight")}
                                </Grid>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                    {
                        card.product_type === 6 &&
                        (
                            <Autocomplete
                                disabled={isDisabled}
                                getOptionSelected={(option, value) => {
                                    if (value === "") {
                                        return option === value;
                                    }
                                    return option.id === value.id;
                                }}
                                getOptionLabel={(option) => {
                                    if (option === "") {
                                        return "";
                                    }
                                    return option.commercial_name;
                                }}
                                onInputChange={((event, value) => {
                                    setFlightSupplierInput(value);
                                }) }
                                onChange={((event, value) => {
                                    handleFlightSupplier(value);
                                })}
                                value={flightSupplier}
                                noOptionsText={t("flight.no_supplier_found")}
                                options={flightSupplierResults}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        label={size === "small" ? t("menu.trip_list.card_supplier_short") : t("menu.trip_list.card_supplier")}
                                        className={size === "small" ? classes.formControlRootAutoCompleteSmall : classes.formControlRootAutoCompleteMobile}
                                        InputProps={{ ...props.InputProps, className: classes.circleBorder }}
                                        inputProps={{
                                            ...props.inputProps,
                                            autoComplete: 'off' //disable autocomplete and autofill
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                            />
                        )
                    }
                    {
                        card.product_type !== 6 && (
                            <FormControl variant="outlined" className={size === "small" ? classes.formControlRootSmall : classes.formControlRootMobile1}>
                                <InputLabel id={`supplier-label-${traveler.id}-${card_index}`} ref={supplierLabel} className={classes.fontSize16} >
                                    {size === "small" ? t("menu.trip_list.card_supplier_short") : t("menu.trip_list.card_supplier")}
                                </InputLabel>
                                <OutlinedInput
                                    disabled={isDisabled}
                                    name={`supplier-label-${traveler.id}-${card_index}`}
                                    id={`supplier-input-${traveler.id}-${card_index}`}
                                    labelWidth={supplierLabelWidth}
                                    className={classes.circleBorder}
                                    value={card.supplier}
                                    onChange={handleSupplier}
                                />
                            </FormControl>
                        )
                    }
                </Grid>
                <Grid item style={{ width: "100%" }}>
                    <FormControl variant="outlined" className={size === "small" ? classes.formControlRootSmall : classes.formControlRootMobile1}>
                        <InputLabel id={`number-label-${traveler.id}-${card_index}`} ref={numberLabel} className={classes.fontSize16}>
                            { size !== "small" ? t("menu.trip_list.card_number") : t("menu.trip_list.id_doc_number_short")}
                        </InputLabel>
                        <OutlinedInput
                            disabled={isDisabled}
                            name={`number-label-${traveler.id}-${card_index}`}
                            id={`number-input-${traveler.id}-${card_index}`}
                            labelWidth={numberLabelWidth}
                            className={classes.circleBorder}
                            value={card.card_number}
                            onChange={handleNumber}
                        />
                    </FormControl>
                </Grid>
                {
                    !isDisabled && (
                        <Fragment>
                            <Grid item style={{ width: "100%" }} className={classes.textCenter}>
                                <IconButton size="small" onClick={() => {
                                    setOpenDeleteCard(true);
                                }}>
                                    <DeleteOutlinedIcon style={{ color: "black" }}/>
                                </IconButton>
                            </Grid>
                            <DeleteLoyaltyCard openDeleteCard={openDeleteCard} setOpenDeleteCard={setOpenDeleteCard} deleteCard={deleteCard} />
                        </Fragment>
                    )
                }
            </Grid>
        );
    }
    return (
        <Grid container alignItems="center" justify="space-between" style={size === "small" ? { padding: "0px 0px 10px" } : { padding: "10px 0px" }} spacing={size === "small" ? 2 : 4}>
            <Grid item xs={3}>
                <FormControl variant="outlined" className={size === "small" ? classes.formControlRootSmall : classes.formControlRoot1}>
                    <InputLabel id={`loyalty-type-label-${traveler.id}-${card_index}`} ref={loyaltyTypeLabel} className={classes.fontSize16}>
                        {t("menu.trip_list.card_type")}
                    </InputLabel>
                    <Select
                        disabled={isDisabled}
                        labelId={`loyalty-type-label-${traveler.id}-${card_index}`}
                        id={`loyalty-type-${traveler.id}-${card_index}`}
                        value={card.product_type}
                        className={classes.circleBorder}
                        onChange={handleType}
                        MenuProps={{ disableScrollLock: true }}
                        input={
                            <OutlinedInput
                                name="loyalty-type"
                                id={`loyalty-type-input-${traveler.id}-${card_index}`}
                                labelWidth={loyaltyTypeLabelWidth}
                            />
                        }>
                        <MenuItem value={0}>
                            <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                {t("global.hotel")}
                            </Grid>
                        </MenuItem>
                        <MenuItem value={2}>
                            <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                {t("providers.rental_car")}
                            </Grid>
                        </MenuItem>
                        <MenuItem value={4}>
                            <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                {t("providers.transfers")}
                            </Grid>
                        </MenuItem>
                        <MenuItem value={5}>
                            <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                {t("providers.trains")}
                            </Grid>
                        </MenuItem>
                        <MenuItem value={6}>
                            <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                {t("global.flight")}
                            </Grid>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                {
                    card.product_type === 6 &&
                        (
                            <Autocomplete
                                disabled={isDisabled}
                                getOptionSelected={(option, value) => {
                                    if (value === "") {
                                        return option === value;
                                    }
                                    return option.id === value.id;
                                }}
                                getOptionLabel={(option) => {
                                    if (option === "") {
                                        return "";
                                    }
                                    return option.commercial_name;
                                }}
                                onInputChange={((event, value) => {
                                    setFlightSupplierInput(value);
                                }) }
                                onChange={((event, value) => {
                                    handleFlightSupplier(value);
                                })}
                                value={flightSupplier}
                                noOptionsText={t("flight.no_supplier_found")}
                                options={flightSupplierResults}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        label={size === "small" ? t("menu.trip_list.card_supplier_short") : t("menu.trip_list.card_supplier")}
                                        className={size === "small" ? classes.formControlRootAutoCompleteSmall : classes.formControlRootAutoComplete}
                                        InputProps={{ ...props.InputProps, className: classes.circleBorder }}
                                        inputProps={{
                                            ...props.inputProps,
                                            autoComplete: 'off' //disable autocomplete and autofill
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                            />
                        )
                }
                {
                    card.product_type !== 6 && (
                        <FormControl variant="outlined" className={size === "small" ? classes.formControlRootSmall : classes.formControlRoot1}>
                            <InputLabel id={`supplier-label-${traveler.id}-${card_index}`} ref={supplierLabel} className={classes.fontSize16} >
                                {size === "small" ? t("menu.trip_list.card_supplier_short") : t("menu.trip_list.card_supplier")}
                            </InputLabel>
                            <OutlinedInput
                                disabled={isDisabled}
                                name={`supplier-label-${traveler.id}-${card_index}`}
                                id={`supplier-input-${traveler.id}-${card_index}`}
                                labelWidth={supplierLabelWidth}
                                className={classes.circleBorder}
                                value={card.supplier}
                                onChange={handleSupplier}
                            />
                        </FormControl>
                    )
                }
            </Grid>
            <Grid item xs={4}>
                <FormControl variant="outlined" className={size === "small" ? classes.formControlRootSmall : classes.formControlRoot1}>
                    <InputLabel id={`number-label-${traveler.id}-${card_index}`} ref={numberLabel} className={classes.fontSize16}>
                        {t("menu.trip_list.card_number")}
                    </InputLabel>
                    <OutlinedInput
                        disabled={isDisabled}
                        name={`number-label-${traveler.id}-${card_index}`}
                        id={`number-input-${traveler.id}-${card_index}`}
                        labelWidth={numberLabelWidth}
                        className={classes.circleBorder}
                        value={card.card_number}
                        onChange={handleNumber}
                    />
                </FormControl>
            </Grid>
            {
                !isDisabled && (
                    <Fragment>
                        <Grid item xs={1}>
                            <IconButton size="small" onClick={() => {
                                setOpenDeleteCard(true);
                            }}>
                                <DeleteOutlinedIcon style={{ color: "black" }}/>
                            </IconButton>
                        </Grid>
                        <DeleteLoyaltyCard openDeleteCard={openDeleteCard} setOpenDeleteCard={setOpenDeleteCard} deleteCard={deleteCard} />
                    </Fragment>
                )
            }
        </Grid>
    );
});

export default React.memo(LoyaltyCard);
