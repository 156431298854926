import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Button,
    IconButton,
    Menu,
    MenuItem,
    styled,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import i18next from "i18next";
import { AutocompleteTranslations } from "./objects/autocompleteTranslations";
import { AppState } from "../../../../Reducers/Reducers";
import CopyIcon from "@mui/icons-material/CopyAll";
import HelpIcon from '@mui/icons-material/HelpOutline';
import LightTooltip from "../utils/tooltip/lightTooltip";

type Props = {
    onChoose: (variable: string) => void,
    disableCopy?: boolean
}

const ACTION = 'TRIP_CUSTOM';

export function MailTemplateVariablePicker(props: Props): JSX.Element {
    const { t } = useTranslation();
    const autocomplete = useSelector((state: AppState) => state.mailTemplate.others.autocomplete);
    const fields = autocomplete.state === 'success' && ACTION !== null ?
        (autocomplete.data.relations[ACTION]?.fields ?? []) :
        [];
    const translations = autocomplete.state === 'success' ?
        autocomplete.data.translations :
        { action: {}, fields: {}, recipients: {} } as AutocompleteTranslations;
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const anchorElement = useRef(null);

    const variables = (
        fields.map((variable) => {
            const field = translations.fields[variable];

            if (field) {
                return { translation: field[i18next.language], variable };
            }

            return null;
        }).filter((item) => {
            return item?.translation && item.translation.includes(search.toLowerCase());
        }) as {translation: string, variable: string}[]
    ).sort((a, b) => a.translation.localeCompare(b.translation));

    const onClose = () => {
        setOpen(false);
    };

    const onChoose = (variable: string) => {
        props.onChoose(variable);
        onClose();
    };

    const onCopy = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, variable: string) => {
        event.stopPropagation();
        const content = `{{${variable}}}`;
        navigator.clipboard.writeText(content);
        onClose();
    };

    return (
        <div>

            <Typography>
                {t<string>('shared.mail-template-variables')}
                <LightTooltip title={t<string>('shared.mail-template-variables-help')}>
                    <HelpIcon className={ 'ft-role-info' }/>
                </LightTooltip>
            </Typography>
            <MenuButton
                variant="contained"
                ref={anchorElement}
                onClick={() => setOpen(true)}
            >
                {t<string>('shared.mail-template-variables-open')}
            </MenuButton>
            <Menu
                open={open}
                anchorEl={anchorElement.current}
                PaperProps={{
                    style: {
                        maxHeight: 450
                    }
                }}
                onClose={onClose}
            >
                <MenuItem
                    sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        backgroundColor: '#fff !important'
                    }}
                >
                    <TextField
                        label={t<string>('shared.search')}
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        onKeyDown={(event) => event.stopPropagation()}
                        variant="outlined"
                        fullWidth
                    />
                </MenuItem>
                {
                    variables.map(({ translation, variable }) => (
                        <MenuItem
                            key={variable}
                            onClick={() => onChoose(variable)}
                            sx={{ justifyContent: 'space-between' }}
                        >
                            {translation}
                            {
                                !props.disableCopy &&
                                <Tooltip title={t<string>('shared.mail-template-variables-copy')}>
                                    <IconButton onClick={(event) => onCopy(event, variable)}>
                                        <CopyIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </MenuItem>
                    ))
                }
                {
                    variables.length === 0 &&
                    <MenuItem disabled>
                        <Typography>
                            {t<string>('shared.mail-template-variables-none-available')}
                        </Typography>
                    </MenuItem>
                }
            </Menu>
        </div>
    );
}

const MenuButton = styled(Button)((props) => ({
    marginTop: props.theme.spacing(2),
    marginBottom: props.theme.spacing(2)
}));
