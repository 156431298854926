import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core";
import { Autocomplete, Divider, FormControl, Grid, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import { styled, lighten, darken } from '@mui/system';
import { AppState } from '../../Reducers/Reducers';
import { useDispatch, useSelector } from 'react-redux';
import { green } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400:{
        fontWeight: 400
    },
    fontSize12: {
        fontSize: 12
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter:{
        textAlign: "center"
    },
    genericPadding:{
        padding: "8px 0px"
    },
    formControlRootSmall: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    formControlRootSmallRed: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    circleBorder:{
        borderRadius: 8
    },
    validateButton: {
        color: "#FFFFFF",
        backgroundColor: "#2C98F0",
        float: "right",
        marginTop: 8
    },
    errorInfo:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        letterSpacing: 1.25,
        color: "red",
        width: "100%",
        fontSize: 10
    },
    orangeButton: {
        backgroundColor: '#E6592F !important',
        color: 'white'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    marginBottom10: {
        marginBottom: 10
    },
}))

type ViatorLocationProps = {
    location: {
        meeting_type: string;
        locations: any[];
        start: any[];
        end: any[];
        allow_custom_traveler_pickup: boolean;
    } | null,
    type: 'start' | 'end' | null,
    departureMode: any | null,
    arrivalMode: any | null
}
export function ViatorLocation(props: ViatorLocationProps): JSX.Element | null {
    const { location, type, departureMode, arrivalMode } = props;
    console.log('location:', location);
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const meeting_point = useSelector((state: AppState) => state.poi.meeting_point);
    const pickup_point = useSelector((state: AppState) => state.poi.pickup_point);
    const return_point = useSelector((state: AppState) => state.poi.return_point);
    const [manualValue, setManualValue] = useState("");
    console.log('meeting_point:', meeting_point);
    console.log('pickup_point:', pickup_point);
    console.log('return_point:', return_point);
    const handleAnswer = (event: { target: { value: any; }; }) => {
        setManualValue(event.target.value);
        dispatch({type: "POI_SET_MANUAL_PICKUP_POINT", payload: event.target.value});
    }
    if (location === null) return null;
    return (
        <Grid>
            {
                type === null &&
                <Fragment>
                    <br />
                    <Divider />
                    <br />
                    <Typography variant="h6"> { t<string>('poi.meeting_pickup') } </Typography>
                </Fragment>
            }
            {/* <br /> */}
            {/* <Typography variant="body1"> { t<string>('poi.meeting_pickup_helper') } </Typography> */}
            {
                (type === null || type === 'start') && (location.meeting_type === 'PICKUP_AND_MEET_AT_START_POINT' || location.meeting_type === 'MEET_EVERYONE_AT_START_POINT') && (location.locations.length > 0 || location.start.length > 0) &&
                <Fragment>
                    {
                        type === null &&
                        <Typography variant="h6"> { t<string>('poi.meeting_point') } </Typography>
                    }
                    {
                        location.locations.length > 1 &&
                        <Fragment>
                            <Typography variant="body1" className={classes.marginBottom10}> { t<string>('poi.meeting_point_helper') } </Typography>
                            <Autocomplete
                                renderGroup={(params) => (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                )}
                                options={location.locations.filter((location) => {
                                    if (location.pick_up_type === 'MANUAL') {
                                        return location;
                                    }
                                    if (arrivalMode !== null && arrivalMode.answer_text !== null) {
                                        let tmp_value = typeof arrivalMode.answer_text === 'string' ? JSON.parse(arrivalMode.answer_text) : arrivalMode.answer_text;
                                        switch (tmp_value.value) {
                                            case 'AIR':
                                                return location.pick_up_type === 'AIRPORT';
                                            case 'SEA':
                                                return location.pick_up_type === 'PORT';
                                            case 'RAIL':
                                                return location.pick_up_type === 'TRAIN_STATION';
                                            case 'OTHER':
                                                return location.pick_up_type === 'LOCATION' || location.pick_up_type === 'HOTEL';
                                        }
                                    } else {
                                        return location;
                                    }
                                })}
                                value={meeting_point}
                                groupBy={(option) => t('poi.questions.pickup_type.' + option.pick_up_type)}
                                getOptionLabel={(option) => `${option.name} ${(option.address !== undefined && option.address !== '') || (option.city_name !== undefined && option.city_name !== '') || (option.country_name !== undefined && option.country_name !== '') ? ' : ' : ''} ${option.address !== undefined && option.address !== '' ? `${option.address}` : ''} ${option.city_name !== undefined && option.city_name !== '' ? ` ${option.city_name}` : ''} ${option.country_name !== undefined && option.country_name !== '' ? `${option.country_name}` : ''}`}
                                isOptionEqualToValue={(option, value) => option.ref === value.ref}
                                onChange={(event, value) => {
                                    dispatch({ type:  'POI_SET_MEETING_POINT', payload: value })
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined" label={t<string>('poi.type_search')} />}
                            />
                            {
                                meeting_point !== undefined && meeting_point !== null && meeting_point.description !== null && meeting_point.description !== "" &&
                                <Typography variant="body1"> { meeting_point.description } </Typography>
                            }
                        </Fragment>
                    }
                    {
                        location.locations.length === 1 &&
                        <Fragment>
                            {
                                location.locations[0].name !== null &&
                                <Typography variant="body1"> { location.locations[0].name } </Typography>
                            }
                            {
                                location.locations[0].address !== null &&
                                <Typography variant="body1"> { location.locations[0].address } </Typography>
                            }
                            {
                                location.locations[0].description !== undefined && location.locations[0].description !== null && location.locations[0].description !== "" &&
                                <Fragment>
                                    <br />
                                    <Typography variant="body1"> { location.locations[0].description } </Typography>
                                </Fragment>
                            }
                        </Fragment>
                    }
                    {
                        location.locations.length === 0 && location.start.length > 1 &&
                        <Fragment>
                            <Typography variant="body1" className={classes.marginBottom10}> { t<string>('poi.meeting_point_helper') } </Typography>
                            <Autocomplete
                                renderGroup={(params) => (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                )}
                                options={location.start.filter((location) => {
                                    if (location.pick_up_type === 'MANUAL') {
                                        return location;
                                    }
                                    if (arrivalMode !== null && arrivalMode.answer_text !== null) {
                                        let tmp_value = typeof arrivalMode.answer_text === 'string' ? JSON.parse(arrivalMode.answer_text) : arrivalMode.answer_text;
                                        switch (tmp_value.value) {
                                            case 'AIR':
                                                return location.pick_up_type === 'AIRPORT';
                                            case 'SEA':
                                                return location.pick_up_type === 'PORT';
                                            case 'RAIL':
                                                return location.pick_up_type === 'TRAIN_STATION';
                                            case 'OTHER':
                                                return location.pick_up_type === 'LOCATION' || location.pick_up_type === 'HOTEL';
                                        }
                                    } else {
                                        return location;
                                    }
                                })}
                                value={meeting_point}
                                groupBy={(option) => t('poi.questions.pickup_type.' + option.pick_up_type)}
                                isOptionEqualToValue={(option, value) => option.ref === value.ref}
                                getOptionLabel={(option) => `${option.name} ${(option.address !== undefined && option.address !== '') || (option.city_name !== undefined && option.city_name !== '') || (option.country_name !== undefined && option.country_name !== '') ? ' : ' : ''} ${option.address !== undefined && option.address !== '' ? `${option.address}` : ''} ${option.city_name !== undefined && option.city_name !== '' ? ` ${option.city_name}` : ''} ${option.country_name !== undefined && option.country_name !== '' ? `${option.country_name}` : ''}`}
                                onChange={(event, value) => {
                                    dispatch({ type:  'POI_SET_MEETING_POINT', payload: value })
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined" label={t<string>('poi.type_search')} />}
                            />
                            {
                                meeting_point !== undefined && meeting_point !== null && meeting_point.description !== null && meeting_point.description !== "" &&
                                <Fragment>
                                    <br />
                                    <Typography variant="body1"> { meeting_point.description } </Typography>
                                </Fragment>
                            }
                        </Fragment>
                    }
                    {
                        location.locations.length === 0 && location.start.length === 1 &&
                        <Fragment>
                            {
                                location.start[0].name !== null &&
                                <Typography variant="body1"> { location.start[0].name } </Typography>
                            }
                            {
                                location.start[0].address !== null &&
                                <Typography variant="body1" style={{fontWeight: 'bold'}}> { location.start[0].address } </Typography>
                            }
                            {
                                location.start[0].description !== undefined && location.start[0].description !== null && location.start[0].description !== "" &&
                                <Fragment>
                                    <br />
                                    <Typography variant="body1"> { location.start[0].description } </Typography>
                                </Fragment>
                            }
                        </Fragment>
                    }
                </Fragment>
            }
            {
                type === null &&
                <br />
            }
            {
                (type === null || type === 'start') && (location.meeting_type === 'PICKUP_AND_MEET_AT_START_POINT' || location.meeting_type === 'PICKUP_EVERYONE') && (location.start.length > 0 || location.locations.length > 0) &&
                <Fragment>
                    {
                        type === null &&
                        <Typography variant="h6" className={classes.marginBottom10}> { t<string>('poi.pickup_point') } </Typography>
                    }
                    {
                        location.start.length > 1 &&
                        <Fragment>
                            <Typography variant="body1"> { t<string>('poi.pickup_point_helper') } </Typography>
                            <Autocomplete
                                renderGroup={(params) => (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                )}
                                options={location.start.filter((location) => {
                                    if (location.pick_up_type === 'MANUAL') {
                                        return location;
                                    }
                                    if (arrivalMode !== null && arrivalMode.answer_text !== null) {
                                        let tmp_value = typeof arrivalMode.answer_text === 'string' ? JSON.parse(arrivalMode.answer_text) : arrivalMode.answer_text;
                                        switch (tmp_value.value) {
                                            case 'AIR':
                                                return location.pick_up_type === 'AIRPORT';
                                            case 'SEA':
                                                return location.pick_up_type === 'PORT';
                                            case 'RAIL':
                                                return location.pick_up_type === 'TRAIN_STATION';
                                            case 'OTHER':
                                                return location.pick_up_type === 'LOCATION' || location.pick_up_type === 'HOTEL';
                                        }
                                    } else {
                                        return location;
                                    }
                                })}
                                value={pickup_point}
                                groupBy={(option) => t('poi.questions.pickup_type.' + option.pick_up_type)}
                                isOptionEqualToValue={(option, value) => option.ref === value.ref}
                                getOptionLabel={(option) => `${option.name} ${(option.address !== undefined && option.address !== '') || (option.city_name !== undefined && option.city_name !== '') || (option.country_name !== undefined && option.country_name !== '') ? ' : ' : ''} ${option.address !== undefined && option.address !== '' ? `${option.address}` : ''} ${option.city_name !== undefined && option.city_name !== '' ? ` ${option.city_name}` : ''} ${option.country_name !== undefined && option.country_name !== '' ? `${option.country_name}` : ''}`}
                                onChange={(event, value) => {
                                    dispatch({ type:  'POI_SET_PICKUP_POINT', payload: value })
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined" label={t<string>('poi.type_search')} />}
                            />
                            {
                                pickup_point !== undefined && pickup_point !== null && pickup_point.description !== null && pickup_point.description !== "" &&
                                <Fragment>
                                    <br />
                                    <Typography variant="body1"> { pickup_point.description } </Typography>
                                </Fragment>
                            }
                        </Fragment>
                    }
                    {
                        location.start.length === 1 && pickup_point !== null &&
                        <Fragment>
                            {
                                pickup_point.name !== undefined && pickup_point.name !== null && pickup_point.name !== "" &&
                                <Typography variant="body1"> { pickup_point.name } </Typography>
                            }
                            {
                                pickup_point.address !== undefined && pickup_point.address !== null && pickup_point.address !== "" &&
                                <Typography variant="body1" style={{fontWeight: 'bold'}}> { pickup_point.address } </Typography>
                            }
                            {
                                pickup_point.description !== undefined && pickup_point.description !== null && pickup_point.description !== "" &&
                                <Fragment>
                                    <br />
                                    <Typography variant="body1"> { pickup_point.description } </Typography>
                                </Fragment>
                            }
                        </Fragment>
                    }
                    {
                        location.start.length === 0 && location.locations.length > 1 &&
                        <Fragment>
                            <Typography variant="body1" className={classes.marginBottom10}> { t<string>('poi.pickup_point_helper') } </Typography>
                            <Autocomplete
                                renderGroup={(params) => (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                )}
                                options={location.locations.filter((location) => {
                                    if (location.pick_up_type === 'MANUAL') {
                                        return location;
                                    }
                                    if (arrivalMode !== null && arrivalMode.answer_text !== null) {
                                        let tmp_value = typeof arrivalMode.answer_text === 'string' ? JSON.parse(arrivalMode.answer_text) : arrivalMode.answer_text;
                                        switch (tmp_value.value) {
                                            case 'AIR':
                                                return location.pick_up_type === 'AIRPORT';
                                            case 'SEA':
                                                return location.pick_up_type === 'PORT';
                                            case 'RAIL':
                                                return location.pick_up_type === 'TRAIN_STATION';
                                            case 'OTHER':
                                                return location.pick_up_type === 'LOCATION' || location.pick_up_type === 'HOTEL';
                                        }
                                    } else {
                                        return location;
                                    }
                                })}
                                value={pickup_point}
                                groupBy={(option) => t('poi.questions.pickup_type.' + option.pick_up_type)}
                                isOptionEqualToValue={(option, value) => option.ref === value.ref}
                                getOptionLabel={(option) => `${option.name} ${(option.address !== undefined && option.address !== '') || (option.city_name !== undefined && option.city_name !== '') || (option.country_name !== undefined && option.country_name !== '') ? ' : ' : ''} ${option.address !== undefined && option.address !== '' ? `${option.address}` : ''} ${option.city_name !== undefined && option.city_name !== '' ? ` ${option.city_name}` : ''} ${option.country_name !== undefined && option.country_name !== '' ? `${option.country_name}` : ''}`}
                                onChange={(event, value) => {
                                    dispatch({ type:  'POI_SET_PICKUP_POINT', payload: value })
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined" label={t<string>('poi.type_search')} />}
                            />
                            {
                                pickup_point !== undefined && pickup_point !== null && pickup_point.description !== null && pickup_point.description !== "" &&
                                <Typography variant="body1"> { pickup_point.description } </Typography>
                            }
                        </Fragment>
                    }
                    {
                        location.start.length === 0 && location.locations.length === 1 &&
                        <Fragment>
                            {
                                location.locations[0].name !== null &&
                                <Typography variant="body1"> { location.locations[0].name } </Typography>
                            }
                            {
                                location.locations[0].address !== null &&
                                <Typography variant="body1"> { location.locations[0].address } </Typography>
                            }
                            {
                                location.locations[0].description !== undefined && location.locations[0].description !== null && location.locations[0].description !== "" &&
                                <Fragment>
                                    <br />
                                    <Typography variant="body1"> { location.locations[0].description } </Typography>
                                </Fragment>
                            }
                        </Fragment>
                    }
                </Fragment>
            }
            {
                (type === null || type === 'start') &&
                <br />
            }
            {
                (type === null || type === 'end') && location.end.length > 0 && 
                <Fragment>
                    {
                        type === null &&
                        <Typography variant="h6" className={classes.marginBottom10}> { t<string>('poi.end_point') } </Typography>
                    }
                    {
                        location.end.length > 1 &&
                        <Fragment>
                            {
                                type === null &&
                                <Typography variant="body1"> { t<string>('poi.end_point_helper') } </Typography>
                            }
                            <Autocomplete
                                renderGroup={(params) => (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                )}
                                options={location.end.filter((location) => {
                                    if (location.pick_up_type === 'MANUAL') {
                                        return location;
                                    }
                                    if (arrivalMode !== null && arrivalMode.answer_text !== null) {
                                        let tmp_value = typeof arrivalMode.answer_text === 'string' ? JSON.parse(arrivalMode.answer_text) : arrivalMode.answer_text;
                                        switch (tmp_value.value) {
                                            case 'AIR':
                                                return location.pick_up_type === 'AIRPORT';
                                            case 'SEA':
                                                return location.pick_up_type === 'PORT';
                                            case 'RAIL':
                                                return location.pick_up_type === 'TRAIN_STATION';
                                            case 'OTHER':
                                                return location.pick_up_type === 'LOCATION' || location.pick_up_type === 'HOTEL';
                                        }
                                    } else {
                                        return location;
                                    }
                                })}
                                value={return_point}
                                groupBy={(option) => t('poi.questions.pickup_type.' + option.pick_up_type)}
                                getOptionLabel={(option) => `${option.name} ${(option.address !== undefined && option.address !== '') || (option.city_name !== undefined && option.city_name !== '') || (option.country_name !== undefined && option.country_name !== '') ? ' : ' : ''} ${option.address !== undefined && option.address !== '' ? `${option.address}` : ''} ${option.city_name !== undefined && option.city_name !== '' ? ` ${option.city_name}` : ''} ${option.country_name !== undefined && option.country_name !== '' ? `${option.country_name}` : ''}`}
                                isOptionEqualToValue={(option, value) => option.ref === value.ref}
                                onChange={(event, value) => {
                                    dispatch({ type:  'POI_SET_RETURN_POINT', payload: value })
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined" label={t<string>('poi.type_search')} />}
                            />
                            {
                                return_point !== undefined && return_point !== null && return_point.description !== null && return_point.description !== "" &&
                                <Typography variant="body1"> { return_point.description } </Typography>
                            }
                        </Fragment>
                    }
                    {
                        location.end.length === 1 && return_point !== null &&
                        <Fragment>
                            {
                                location.start.length === 1 && pickup_point !== null && pickup_point.google_ref === return_point.google_ref &&
                                <Typography variant="body1" > { t<string>('poi.same_end_point') } </Typography>
                            }
                            {
                                location.start.length === 1 && pickup_point !== null && pickup_point.google_ref !== return_point.google_ref &&
                                <Fragment>
                                {
                                    return_point.name !== undefined && return_point.name !== null && return_point.name !== "" &&
                                    <Typography variant="body1"> { return_point.name } </Typography>
                                }
                                {
                                    return_point.address !== undefined && return_point.address !== null && return_point.address !== "" &&
                                    <Typography variant="body1" style={{fontWeight: 'bold'}}> { return_point.address } </Typography>
                                }
                                {
                                    return_point.description !== undefined && return_point.description !== null && return_point.description !== "" &&
                                    <Fragment>
                                        <br />
                                        <Typography variant="body1"> { return_point.description } </Typography>
                                    </Fragment>
                                }
                                </Fragment>
                            }
                        </Fragment>
                    }
                </Fragment>
            }
            {
                ((meeting_point !== null && meeting_point.pick_up_type === 'MANUAL') || (pickup_point !== null && pickup_point.pick_up_type === 'MANUAL') || (return_point !== null && return_point.pick_up_type === 'MANUAL')) &&
                <FormControl variant="outlined" className={ classes.formControlRootSmall}>
                    <InputLabel id={`question-label-manual-pickup`}>{ `${t('poi.questions.manual_pickup')}` }</InputLabel>
                    <OutlinedInput
                        type={'text'}
                        name={`question-label-manual-pickup`}
                        id={`question-input-manual-pickup`}
                        value={manualValue}
                        className={`${classes.circleBorder}`}
                        onChange={handleAnswer}
                        label={`${t('poi.questions.manual_pickup')}`}
                        autoComplete="off"
                    />
                </FormControl>
            }
        </Grid>
    )
}
const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));
  
  const GroupItems = styled('ul')({
    padding: 0,
  });