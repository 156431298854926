import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import axios from "axios";
import { LoadingBackDrop } from "../../Common/LoadingBackdrop";
import { useCartProducts } from "../../Itinerary/network/cartProducts";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";

type CheckFunction = (startDate: string, endDate: string) => void

type Props = {
    children: (check: CheckFunction) => JSX.Element,
    onConfirm: (choice: 'replace' | 'option' | 'no-status') => Promise<void>,
}

export function OverlappingAccommodationsModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const dates = useRef<{startDate: string | null, endDate: string | null}>({ startDate: null, endDate: null });
    const cart = useCartProducts();

    const onClose = () => {
        setOpen(false);
    };

    const onCheck: CheckFunction = (startDate, endDate) => {
        const hasOverlappingAccommodation = cart.accommodations.findIndex((item) => {
            return window.moment.utc(startDate, "YYYY-MM-DD").isBetween(
                window.moment.utc(item.accommodation.start_date, "YYYY-MM-DD"),
                window.moment.utc(item.accommodation.end_date, "YYYY-MM-DD"),
                'days',
                '[]'
            ) &&
            window.moment.utc(endDate, "YYYY-MM-DD").isBetween(
                window.moment.utc(item.accommodation.start_date, "YYYY-MM-DD"),
                window.moment.utc(item.accommodation.end_date, "YYYY-MM-DD"),
                'days',
                '[]'
            );
        }) >= 0;
        if (hasOverlappingAccommodation) {
            dates.current = { startDate, endDate };
            setOpen(true);
        } else {
            onConfirm('no-status');
        }
    };

    const onDeleteAccommodation = (accommodation: typeof cart.accommodations[number]) => {
        if (accommodation.type === 'normal') {
            const { headers } = CheckBeforeRequest();
            Promise.all(
                accommodation.accommodation.rooms.map((room) => {
                    if (room.booking_status !== null && room.booking_status.status_booking === "CANCELLED") {
                        return axios({
                            method: 'PATCH',
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                            data: {
                                is_displayed: false
                            }
                        }).then((response) => {
                            dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                        }).catch(error => {
                            console.log(error);
                        });
                    }
                    return axios({
                        method: 'DELETE',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`
                    }).then(() => {
                        dispatch({ type: 'ACCOMMODATION_REMOVE_FROM_CART_BY_ID', payload: room.id });
                    }).catch(error => {
                        console.log(error);
                    });
                })
            );
        } else {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'DELETE',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${accommodation.accommodation.id}/`
            }).then(() => {
                dispatch({ type: 'CART_MANUAL_PRODUCT_REMOVE_FROM_CART_BY_ID', payload: accommodation.accommodation.id });
            }).catch(error => {
                console.log(error);
            });
        }
    };

    const onConfirm = async (choice: 'replace' | 'option' | 'no-status') => {
        setLoading(true);
        if (choice === 'no-status' || choice === 'option') {
            await props.onConfirm(choice);
            setOpen(false);
        } else {
            try {
                setOpen(false);
                await props.onConfirm(choice);
                if (dates.current) {
                    const overlappingAccommodation = cart.accommodations.find((item) => {
                        return window.moment.utc(item.accommodation.start_date, "YYYY-MM-DD").isSameOrAfter(window.moment.utc(dates.current.startDate!, "YYYY-MM-DD")) &&
                               window.moment.utc(item.accommodation.end_date, "YYYY-MM-DD").isSameOrBefore(window.moment.utc(dates.current.endDate!, "YYYY-MM-DD"));
                    });
                    if (overlappingAccommodation) {
                        onDeleteAccommodation(overlappingAccommodation);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
        setLoading(false);
    };

    return (
        <>
            <Dialog open={open} fullWidth maxWidth={ "md" } onClose={ onClose } disableEscapeKeyDown>
                <DialogTitle>{ t<string>("accommodation.validate_cart") }</DialogTitle>
                <DialogContent>
                    <DialogContentText>{ t<string>("accommodation.replace_or_option_or_variant") }</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color={ "primary" }
                        onClick={() => onConfirm('replace')}
                    >
                        { t<string>("accommodation.replace") }
                    </Button>
                    <Button
                        color={ "primary" }
                        onClick={() => onConfirm('option')}
                    >
                        { t<string>("accommodation.add_option") }
                    </Button>
                    <Button onClick={ onClose } color={ "primary" }>{ t<string>("accommodation.cancel") }</Button>
                </DialogActions>
            </Dialog>
            {props.children(onCheck)}
            <LoadingBackDrop open={loading} />
        </>
    );
}
