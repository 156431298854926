import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';

import grey from "@material-ui/core/colors/grey";
import RoomIcon from '@material-ui/icons/Room';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import TimerIcon from '@material-ui/icons/Timer';
import CheckIcon from '@material-ui/icons/Check';

import clsx from "clsx";

//---- CSS ----//
import "../../../Style/Menu/TripList.css";
import moment from "moment";

const useRowStyles = makeStyles({
    dueDatePast: {
        color: "red"
    },
    dueDateFuture: {
        color: "#71DD13"
    },
    displayRow: {
        display: "none"
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    versionBackground: {
        backgroundColor: grey[200]
    },
    pointer: {
        cursor: "pointer"
    },
    paddingCell: {
        "& .mui-jss-MuiTableCell-root": {
            padding: "10px 0px 10px 16px !important",
            fontSize: 12
        }
    },
    hover: {
        "&:hover": {
            backgroundColor: '#f1f1f1'
        }
    },
    cachedRow: {
        backgroundColor: 'rgb(235, 242, 249)',
        borderLeft: '3px solid rgb(35, 183, 229)'
    },
    ellipsisDestination: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: '54%'
    },
    widthTd: {
        width: "21%"
    },
    topVertivalAlign: {
        verticalAlign: "top",
        marginRight: 4
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight500: {
        fontWeight: 500
    },
    genericBorder: {
        background: "#FFFFFF",
        border: "1px solid #DDDDDD",
        boxSizing: "border-box"
    },
    selectBorder: {
        borderRadius: 30
    }
});

const RenderField = ({ row, version, column, column_index, type, version_index }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useRowStyles();
    const config = JSON.parse(localStorage.getItem("config"));
    moment.locale(i18n.language);
    if (column.field_name === t("menu.file") && type === "main") {
        return (
            <TableCell key={column_index} align={"left"}>
                <span>{(row.created_date.substring(0, 4) + '-' + row.trip_reference + (row.accounting_reference !== null && row.accounting_reference !== "" ? (" / " + row.accounting_reference) : ""))}</span>
                {
                    version.due_date !== null && config.quotation_code !== "marcovasco" && (
                        <Fragment>
                            <br/>
                            <span className={clsx({ [classes.dueDateFuture]: moment(version.due_date).diff(moment(), "d") > 0, [classes.dueDatePast]: moment(version.due_date).diff(moment(), "d") <= 0, [classes.fontSize10]: true })}><TimerIcon className={classes.fontSize10} style={{ verticalAlign: "text-bottom", marginRight: 2 }}/>{moment(version.due_date, "YYYY-MM-DD").format("DD-MM-YYYY")}</span>
                        </Fragment>
                    )
                }
            </TableCell>
        );
    }
    if (column.field_name === t("menu.file") && type === "version") {
        return (
            <TableCell key={column_index} align={"left"}>{"V" + (version_index + 1)}{version.id === row.current_version ? <Tooltip title="Version en cours"><CheckIcon className={`${classes.dueDateFuture} ${classes.fontSize12}`} style={{ verticalAlign: "text-top", marginLeft: 5 }}/></Tooltip> : ""}</TableCell>
        );
    }

    if (column.field_name === t("global.nights")) {
        return (
            <TableCell key={column_index} align={"left"}>{version.nights}</TableCell>
        );
    }
    if (column.field_name === t("menu.trip_list.agency")) {
        return (
            <TableCell key={column_index} align={"left"} className={`${!downDesktop ? classes.widthTd : ""}`}>{((row.target_client !== undefined && row.target_client !== null) ? `${row.target_client.name}${(row.target_client.street !== null ? " / " + row.target_client.street : "") }${(row.target_client.zip_code !== null ? ", " + row.target_client.zip_code : "")}${(row.target_client.city !== null ? ", " + row.target_client.city : "")}` : "")}</TableCell>
        );
    }
    if (column.field_name === t("header.destination")) {
        return (
            <TableCell key={column_index} align={"left"}><div><RoomIcon className={`${classes.fontSize12} ${classes.topVertivalAlign}`}/>{version.destination ? version.destination.data.name !== undefined ? version.destination.data.name : version.destination.data.international_name : " - "}</div></TableCell>
        );
    }
    if (column.field_name === t("itinerary.departure_date")) {
        return (
            <TableCell key={column_index} align={"left"}><FlightTakeoffIcon className={`${classes.fontSize12} ${classes.topVertivalAlign}`}/>{moment.utc(version.start_date).format("L")}</TableCell>

        );
    }
    if (column.field_name === t("itinerary.return_date")) {
        return (
            <TableCell key={column_index} align={"left"}><FlightLandIcon className={`${classes.fontSize12} ${classes.topVertivalAlign}`}/>{moment.utc(version.end_date).format("L")}</TableCell>

        );
    }
    if (column.field_name === t("menu.trip_list.creator_header") || column.field_name === t("menu.trip_list.adviser")) {
        return (
            <TableCell key={column_index} align={"left"}>{ (row.creator !== undefined && row.creator !== null) ? row.creator.username : " - "}</TableCell>
        );
    }
    if (column.field_name === t("menu.created_date")) {
        return (
            <TableCell key={column_index} align={"left"}> {moment(version.created_date).format("L")}<br/>{ moment(version.created_date).format("LT")}</TableCell>
        );
    }
    if (column.field_name === t("menu.edition_day")) {
        return (
            <TableCell key={column_index} align={"left"}> {moment(version.modified_date).format("L")}<br/>{ moment(version.modified_date).format("LT")}</TableCell>
        );
    }
    if (column.field_name === t("menu.trip_list.pax")) {
        return (
            <TableCell key={column_index} align={"left"}>{version.travelers.length + " " + t("menu.trip_list.pax")}</TableCell>
        );
    }
    if ((column.field_name === t("menu.trip_list.user_to") || column.field_name === t("menu.trip_list.user_bo")) && type === "main") {
        return (
            <TableCell key={column_index} align={"left"}>{row.user_to !== null ? row.user_to.username : "-"}</TableCell>
        );
    }
    if ((column.field_name === t("menu.trip_list.user_to") || column.field_name === t("menu.trip_list.user_bo")) && type === "version") {
        return (
            <TableCell key={column_index} align={"left"}>{""}</TableCell>
        );
    }
    if ((column.field_name === t("menu.trip_list.user_ta") || column.field_name === t("menu.trip_list.seller")) && type === "main") {
        return (
            <TableCell key={column_index} align={"left"}>{row.user_ta !== null ? row.user_ta.username : "-"}</TableCell>
        );
    }
    if ((column.field_name === t("menu.trip_list.user_ta") || column.field_name === t("menu.trip_list.seller")) && type === "version") {
        return (
            <TableCell key={column_index} align={"left"}>{""}</TableCell>
        );
    }
    if (column.field_name === t("menu.trip_list.file_name") && type === "main") {
        return (
            <TableCell key={column_index} align={"left"}>{row.name}</TableCell>
        );
    }
    if (column.field_name === t("menu.trip_list.file_name") && type === "version") {
        return (
            <TableCell key={column_index} align={"left"}>{""}</TableCell>
        );
    }
    if (column.field_name === t("menu.trip_list.date_of") + " " + t("menu.trip_list.confirmation")) {
        return (
            <TableCell key={column_index} align={"left"}>{version.confirmation_date !== null ? moment.utc(version.confirmation_date).format("L") : (version.status_contract_confirmed_date !== null ? moment.utc(version.status_contract_confirmed_date).format("L") : "")}</TableCell>
        );
    }
    if (column.field_name === t("menu.trip_list.applicant") || column.field_name === t("menu.trip_list.client")) {
        return (
            <TableCell key={column_index} align={"left"} style={{ width: 'max-content' }}>
                <span className={"uppercase"}> { version.contact_traveler_detail !== null ? version.contact_traveler_detail.last_name : " - " } </span>
                <span className={"capitalize"} > { version.contact_traveler_detail !== null ? version.contact_traveler_detail.first_name : "" } </span>
            </TableCell>
        );
    }
    if (column.field_name === t("menu.trip_list.to_agency")) {
        return (
            <TableCell key={column_index} align={"left"}>
                {row.creator !== undefined && row.creator !== null && row.creator.client !== undefined && row.creator.client !== null && row.creator.client.type !== undefined && row.creator.client.type !== null ? (row.creator.client.type === 2 ? t("menu.trip_list.agency") : "TO") : ""}
            </TableCell>
        );
    }
    if (column.field_name === t("menu.trip_list.completion_percentage")) {
        return (
            <TableCell key={column_index} align={"left"}>
                {version.completion_percentage !== null ? version.completion_percentage + "%" : "-"}
            </TableCell>
        );
    }
};
export default React.memo(RenderField);
