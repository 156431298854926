import React from "react";

export default () => {
	// Internet Explorer 6-11
	let isIE = /*@cc_on!@*/false || !!document.documentMode;
	// Edge 20+
	let isEdge = !isIE && !!window.StyleMedia;
	// Opera 8.0+
	let isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf('Opera') >= 0;
	return isIE || isEdge || isOpera;
}