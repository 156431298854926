//---- Commons ----//
import GetCookie from "../../Common/Functions/GetCookie";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import SortByKey from "../../Common/SortByKey";

//---- Actions ----//
import { SetCarsResults, AllCars, SetCarsCategories, SetTotalCars, SetVehiclesPriceError, CheckAvailabilityStatus } from "../../../Actions/CarsSearch";

//---- functions ----//
import GetMinPrice from "./GetMinPrice";
import axios from "axios";
import moment from "moment";

let getResult = 0;
let getError = 0;
function checkMinPrice(vehicles) {
    let min_price = null;
    vehicles.map((car) => {
        if (car.station_prices !== null) {
            if (min_price === null) {
                min_price = parseFloat(car.station_prices[0].prices[0].selling_price);
            } else {
                min_price = parseFloat(car.station_prices[0].prices[0].selling_price) < min_price ? parseFloat(car.station_prices[0].prices[0].selling_price) : min_price;
            }
        }
    });
    return min_price;
}

function mergeCategory(a, b, prop) {
    let reduced = a.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]));
    return reduced.concat(b);
}

// eslint-disable-next-line max-params
export default (step_info, location_index, provider_list, user, dispatch, t, setLoading, source) => {
    // return new Promise(async (resolve, reject) => {
    let step = step_info[location_index];
    const { pass_check, headers } = CheckBeforeRequest();
    let provider = [];
    getResult = 0;
    getError = 0;
    provider_list.map((tmp) => {
        if (tmp.enabled_content_types.includes(2)) {
            provider.push(tmp.provider.id);
        }
    });
    let tmp_origin_hour = null;
    let tmp_return_hour = null;
    if (step.origin_hour === t("global.midnight")) {
        tmp_origin_hour = "00h00";
    } else if (step.origin_hour === t("global.noon")) {
        tmp_origin_hour = "12h00";
    } else {
        tmp_origin_hour = step.origin_hour;
    }
    if (step.return_hour === t("global.midnight")) {
        tmp_return_hour = "00h00";
    } else if (step.return_hour === t("global.noon")) {
        tmp_return_hour = "12h00";
    } else {
        tmp_return_hour = step.return_hour;
    }
    let origin_hour = tmp_origin_hour.replace("h", ":");
    let return_hour = tmp_return_hour.replace("h", ":");
    let tmp_date_origin = moment((step.origin_date !== null ? moment(step.origin_date).format("YYYY-MM-DD") : step.start_date_origin) + "T" + origin_hour);
    let tmp_date_return = moment((step.return_date !== null ? moment(step.return_date).format("YYYY-MM-DD") : step.end_date_dest) + "T" + return_hour);
    let date_origin = moment(tmp_date_origin).format("YYYY-MM-DD[T]HH:mm");
    let date_return = tmp_date_return.format("YYYY-MM-DD[T]HH:mm");
    let category = [];
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    for (let i = 0; i < provider.length; i++) {
        let url = "provider_id=" + provider[i] + "&date_pickup=" + date_origin + "&date_return=" + date_return + "&driver_age=" + step.age;
        if (provider_list.find(provider_full => provider_full.provider.id === provider[i]).provider.code === "hertz_gsa") {
            url += "&location_code_pickup=" + step.origin_station.oag_code;
            url += "&location_code_return=" + step.return_station.oag_code;
            url += "&pickup_vendor_code=" + step.origin_station.vendor_code;
            url += "&return_vendor_code=" + step.return_station.vendor_code;
        } else {
            if (step.origin_dest.is_pickup_city_name !== undefined) {
                url += "&is_pickup_city_name=true&location_code_pickup=" + (step.origin_dest.data ? step.origin_dest.data.international_name : step.origin_dest.international_name) + "&country_code=" + step.origin_dest.country_code;
            } else {
                url += "&destination_id_pickup=" + step.origin_dest.id + "&country_code=" + (step.origin_dest.country_code !== undefined ? step.origin_dest.country_code : step.origin_dest.data.country.country_code);
            }
            if (step.return_dest.is_pickup_city_name !== undefined) {
                url += "&is_return_city_name=true&location_code_return=" + (step.return_dest.data ? step.return_dest.data.international_name : step.return_dest.international_name);
            } else {
                url += "&destination_id_return=" + step.return_dest.id;
            }
        }
        if (step.contract) {
            url += "&contract_type=" + step.contract;
        }
        if (pass_check) {
            dispatch(SetTotalCars(0));
            dispatch(SetVehiclesPriceError(null));
            dispatch(CheckAvailabilityStatus("Fetching"));
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/cars/vehicle_prices/?trip=${GetCookie("trip_id")}&${url}`,
			    cancelToken: source !== undefined ? source.token : undefined
            }).then((results) => {
                console.log('results:', results);
                let data = [...results.data];
                getResult += 1;
                let total_cars = 0;
                let cars = [];
                data.map((info) => {
                    if (!(user.client_full.type === 2 && JSON.parse(localStorage.getItem("config")).quotation_code === "amerigo" &&
                        (info.subprovider_name === "Budget" || info.subprovider_name === "Dollar" || info.subprovider_name === "Sixt" ||
                            info.subprovider_name === "Thrifty" || info.subprovider_name === "Ucar" || info.subprovider_name === "IWS" ||
                            info.subprovider_name === "Via Route" || info.subprovider_name === "Discount Car" || info.subprovider_name === "Alamo MAX" ||
                            (info.subprovider_name === "Avis" && info.provider_name !== "Avis") || (info.subprovider_name === "alamo" && info.provider_name !== "alamo")))) {
                        if (!(quotation_code === "marcovasco" && (info.subprovider_name === "Budget" || info.subprovider_name === "Dollar" ||
                                info.subprovider_name === "Sixt" || info.subprovider_name === "sixt" || info.subprovider_name === "Thrifty" ||
                                info.subprovider_name === "Ucar" || info.subprovider_name === "IWS" || info.subprovider_name === "Via Route" ||
                                info.subprovider_name === "Discount Car"))) {
                            let empty = true;
                            let tmp_vehicles = [];
                            info.vehicles.map((vehicle) => {
                                let to_render = false;
                                if (!(quotation_code === "marcovasco" && vehicle.vehicle_info.vehicle_category !== null && (vehicle.vehicle_info.vehicle_category).toLowerCase() === "economy")) {
                                    if (step.contract !== "" && info.provider_name === "BSPAuto") {
                                        if (vehicle.contract_type === step.contract) {
                                            to_render = true;
                                        } else {
                                            to_render = false;
                                        }
                                        if (vehicle.station_prices !== null) {
                                            vehicle.station_prices = vehicle.station_prices.sort((a, b) => {
                                                let da = parseFloat(a.prices[0].selling_price);
                                                let db = parseFloat(b.prices[0].selling_price);
                                                return da - db;
                                            });
                                            empty = false;
                                        }
                                    } else {
                                        to_render = true;
                                        if (vehicle.station_prices !== null) {
                                            vehicle.station_prices = vehicle.station_prices.sort((a, b) => {
                                                let da = parseFloat(a.prices[0].selling_price);
                                                let db = parseFloat(b.prices[0].selling_price);
                                                return da - db;
                                            });
                                            empty = false;
                                        }
                                    }
                                }
                                if (to_render) {
                                    tmp_vehicles.push(vehicle);
                                }
                            });
                            info.vehicles = tmp_vehicles;
                            if (!empty) {
                                cars.push(info);
                            }
                        }
                    }
                });
                let tmp_cars = cars.slice();
                tmp_cars.map((info) => {
                    info.vehicles = info.vehicles.sort(SortByKey(true, "vehicle_info", "vehicle_category_order"));
                    info.first_price = checkMinPrice(info.vehicles);
                    info.to_render = true;
                    info.vehicles.map((vehicles) => {
                        vehicles.detail = false;
                        vehicles.provider = info.provider_name;
                        vehicles.provider_id = info.provider_id;
                        vehicles.subprovider_id = info.subprovider_id;
                        vehicles.subprovider = info.subprovider_name;
                        vehicles.subprovider_logo = info.subprovider_logo;
                        vehicles.special_equipments = [];
                        vehicles.forfait_service = [];
                        vehicles.contract_name = null;
                        vehicles.contract_logo = null;
                        vehicles.contract = null;
                        vehicles.agency_type = 0;
                        vehicles.error = [];
                        vehicles.location_code_pickup = null;
                        vehicles.location_code_return = null;
                        vehicles.to_render = true;
                    });
                    info.vehicles = info.vehicles.reduce(function (r, a) {
                        if (a.vehicle_info.vehicle_category !== null) {
                            if (quotation_code !== 'marcovasco' || (a.vehicle_info.vehicle_category_order !== 9 || (info.stations.length > 0 && info.stations[0].state_code === 'HI'))) {
                                //r[t("cars.categories." + ((a.vehicle_info.vehicle_category).toLowerCase()).replace(" ", "_"))] = r[t("cars.categories." + ((a.vehicle_info.vehicle_category).toLowerCase()).replace(" ", "_"))] || [];
                                //r[t("cars.categories." + ((a.vehicle_info.vehicle_category).toLowerCase()).replace(" ", "_"))].push(a);

                                if (a.station_prices !== null) {
                                    total_cars += 1;
                                }
                                let name_supp = (a.vehicle_info.vehicle_category).toLowerCase();
                                if (quotation_code === 'marcovasco') {
                                    if (a.vehicle_info.vehicle_category_order === 1) {
                                        name_supp = 'fullsize-fcar';
                                    } else if (a.vehicle_info.vehicle_category_order === 2) {
                                        name_supp = 'suv-ifar';
                                    } else if (a.vehicle_info.vehicle_category_order === 3) {
                                        name_supp = 'suv-sfar';
                                    } else if (a.vehicle_info.vehicle_category_order === 4) {
                                        name_supp = 'suv-ffar';
                                    } else if (a.vehicle_info.vehicle_category_order === 5) {
                                        if (a.vehicle_info.vehicle_category.toLowerCase() === 'mini') {
                                            a.vehicle_info.vehicle_category = 'minivan';
                                        }
                                        name_supp = 'minivan-mvar';
                                    }
                                }
                                r[name_supp] = r[name_supp] || [];
                                r[name_supp].push(a);
                            }
                        }
                        return r;
                    }, {});
                });
                tmp_cars.map((provider) => {
                    Object.keys(provider.vehicles).map((key) => {
                        provider.vehicles[key] = provider.vehicles[key].sort((a, b) => {
                            if (b.station_prices === null) {
                                return -1;
                            } else if (a.station_prices === null) {
                                return 1;
                            } 
                            let da = parseFloat(a.station_prices[0].prices[0].selling_price);
                            let db = parseFloat(b.station_prices[0].prices[0].selling_price);
                            return da - db;
                        });
                    });
                });
                tmp_cars.sort(SortByKey(true, "first_price"));
                tmp_cars.map((info) => {
                    let tmp_category = [];
                    Object.keys(info.vehicles).map((vehicle) => {
                        if (info.vehicles[vehicle][0].station_prices !== null) {
                            tmp_category.push({
                                name: vehicle,
                                img: vehicle,
                                code: info.vehicles[vehicle][0].vehicle_info.code,
                                order: info.vehicles[vehicle][0].vehicle_info.vehicle_category_order,
                                min_price: GetMinPrice(tmp_cars, vehicle, quotation_code),
                                to_render: true
                            });
                        }
                    });
                    category = mergeCategory(category, tmp_category, "name");
                });
                // let order = [
                //     'economy',
                //     'economy elite',
                //     'compact',
                //     'compact elite',
                //     'intermediate',
                //     'intermediate elite',
                //     'standard',
                //     'standard elite',
                //     'suv',
                //     'convertible',
                //     'fullsize',
                //     'fullsize elite',
                //     'premium',
                //     'premium elite',
                //     'mini',
                //     'mini elite',
                //     'minivan',
                //     'van',
                //     'luxury',
                //     'luxury elite',
                //     'oversize',
                //     'special'
                // ];
                //let sort_category = category.sort((a, b) => {
                //return order.indexOf(a.name) - order.indexOf(b.name)
                //});
                let sort_category = category.sort((a, b) => {
                    return a.order - b.order;
                });
                if (sort_category.length > 0) {
                    dispatch(SetCarsCategories(sort_category));
                }
                //setResults(cars);
                dispatch(SetTotalCars(total_cars));
                dispatch(SetCarsResults(tmp_cars));
                setLoading(false);
                if (getResult + getError === provider.length) {
                    console.log('merde');
                    //Notify(t("cars.get_all_cars"), {status: 'success', pos: 'top-center'});
                    dispatch(AllCars(true));
                    dispatch(CheckAvailabilityStatus("Done"));
                }
            }).catch((error) => {
                console.log('error:', error);
                getError += 1;
                // dispatch(SetVehiclesPriceError(error.responseJSON ? error.responseJSON : error.statusText));
            });
        }
    }
};
