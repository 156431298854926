//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import RenderSummaryAccommodation from "./RenderProducts/RenderSummaryAccommodation";
import RenderSummaryFlight from "./RenderProducts/RenderSummaryFlight";
import RenderSummaryCars from "./RenderProducts/RenderSummaryCars";
import RenderSummaryTransfers from "./RenderProducts/RenderSummaryTransfers";
import RenderSummaryPoi from "./RenderProducts/RenderSummaryPoi";
import RenderDestination from "../ItineraryType/ItiTypes/RenderDestination";
import RenderTransport from '../ItineraryType/CircuitsMaterial/RenderTransport';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fullWidth: {
        width: "100%"
    },
    textJustify: {
        textAlign: "justify"
    },
    textCenter: {
        textAlign: "center"
    },
    bold: {
        fontWeight: "bold"
    },
    stickyPaper: {
        position: "sticky",
        top: 80,
        borderRadius: 12
    },
    buttonContainer: {
        minWidth: 150,
        margin: '0 15px 15px'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    bold: {
        fontWeight: "bold"
    },
    capitalize: {
        textTransform: "capitalize"
    },
    hrText: {
        lineHeight: "20px",
        position: "relative",
        outline: "0",
        border: "0",
        textAlign: "center",
        height: 20,
        fontSize: 34,
        '&::before': {
            content: "''",
            background: "#0000008A",
            position: "absolute",
            left: "0",
            top: "50%",
            width: "100%",
            height: "1px"
        },
        '&::after': {
            content: "attr(data-content)",
            position: "relative",
            display: "inline-block",
            color: "#0000008A",
            padding: "0 .5em",
            // lineHeight: "1.5em",
            backgroundColor: "white"
        }
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    hoverContainer: {
        "&:hover": {
            borderLeft: '6px solid #E6592F'
        }
    },
    borderContainer: {
        borderTop: "1px solid #C4C4C4",
        borderBottom: "1px solid #C4C4C4",
        paddingBottom: "0px !important"
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 50
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
}));

const RenderProductsStep = React.forwardRef((prop, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();

    const from = prop.from;
    const dayByDay = prop.dayByDay;
    const selectedProducts = prop.selectedProducts;
    const setSelectedProducts = prop.setSelectedProducts;

    const hotel_cart = useSelector(store => store.summary.hotel_cart);
    const cars_cart = useSelector(store => store.summary.cars_cart);
    const flight_cart = useSelector(store => store.summary.flight_cart);
    const manual_cart = useSelector(store => store.summary.manual_cart);
	const poi_cart = useSelector(store => store.summary.poi_cart);
	const transfers_cart = useSelector(store => store.summary.transfers_cart);
    const itinerary = useSelector(store => store.summary.itinerary);
    const trip_info = useSelector(store => store.summary.trip_info);
    const guide_list = useSelector(store => store.summary.guide_list);

    let last_day = 1;
    let nb_nights = 0;
    return (
        <Fragment>
        {
            itinerary.map((step, index) => {
                let first_day = last_day;
                last_day = first_day + moment.utc(step.end_date).set('hour', 0).set('minutes', 0).set('second', 0).set('millisecond', 0).diff(moment.utc(step.start_date).set('hour', 0).set('minutes', 0).set('second', 0).set('millisecond', 0), "d");
                nb_nights = moment.utc(step.end_date).set('hour', 0).set('minutes', 0).set('second', 0).set('millisecond', 0).diff(moment.utc(step.start_date).set('hour', 0).set('minutes', 0).set('second', 0).set('millisecond', 0), "d");
                // last_day = first_day + moment.utc(step.end_date).diff(moment.utc(step.start_date), "d") + 1;
                // nb_nights = moment.utc(step.end_date).diff(moment.utc(step.start_date), "d") + 1;
                if (step.step_type === "STEP") {
                    let step_name = ((step.destination.data.name !== undefined && step.destination.data.name !== null) ? step.destination.data.name : step.destination.data.international_name).split(",")
                    let has_hotel = false;
                    let has_car = false;
                    let has_transfers = false;
                    let has_poi = false;
                    let has_flight = false;
                    let has_manual_product = manual_cart.find((manual) => moment.utc(manual.start_date).isSameOrAfter(moment.utc(step.start_date), "d") && moment.utc(manual.start_date).isSameOrBefore(moment.utc(step.end_date), "d"))
                    flight_cart.map((flight) => {
                        flight.outbounds.find((outbound, outbound_index) => {
                            if (index !== itinerary.length - 2 && moment.utc(outbound.legs[0].departure_time).isSame(moment.utc(step.end_date), "d")) {
                                has_flight = true;
                            }
                        })
                    });
                    hotel_cart.map((hotel) => {
                        if (moment.utc(hotel.start_date).isSameOrAfter(moment.utc(step.start_date), "d") && moment.utc(hotel.end_date).isSameOrBefore(moment.utc(step.end_date), "d")) {
                            has_hotel = true;
                        }
                    });
                    cars_cart.map((car) => {
                        if (moment.utc(car.start_date).isSame(moment.utc(step.start_date), 'd') || moment.utc(car.end_date).isSame(moment.utc(step.end_date), 'd')) {
                            has_car = true;
                        // } else if (moment.utc(car.start_date).isSameOrBefore(moment.utc(step.start_date), 'd') && moment.utc(car.end_date).isSameOrAfter(moment.utc(step.end_date), 'd')) {
                        //     has_car = true;
                        }
                        // if (moment.utc(car.start_date).isSameOrAfter(moment.utc(step.start_date), 'd') && car.start_destination.id === step.destination.id) {
                        //     has_car = true;
                        // } else if (moment.utc(car.start_date).isSameOrBefore(moment.utc(step.start_date), 'd') && moment.utc(car.end_date).isSameOrAfter(moment.utc(step.end_date), 'd')) {
                        //     has_car = true;
                        // }
                    });
                    transfers_cart.map((transfer) => {
                        if (moment.utc(transfer.start_date).isSameOrAfter(moment.utc(step.start_date)) && moment.utc(transfer.end_date).isSameOrBefore(moment.utc(step.end_date))) {
                            has_transfers = true;
                        }
                    });
                    poi_cart.find((poi) => {
                        if (moment.utc(poi.start_date).isSameOrAfter(moment.utc(step.start_date)) && moment.utc(poi.start_date).isSameOrBefore(moment.utc(step.end_date))) {
                            has_poi = true;
                        }
                    });
                    manual_cart.map((manual) => {
                        if ((manual.product_type === 0 || manual.product_type === 7) && moment.utc(manual.start_date).isSameOrAfter(moment.utc(step.start_date), "d") && moment.utc(manual.end_date).isSameOrBefore(moment.utc(step.end_date), "d")) {
                            has_hotel = true;
                        }
                        if (manual.product_type === 4 && moment.utc(manual.start_date).isSameOrAfter(moment.utc(step.start_date)) && moment.utc(manual.end_date).isSameOrBefore(moment.utc(step.end_date))) {
                            has_transfers = true;
                        }
                        if (manual.product_type === 2 && moment.utc(manual.start_date, "YYYY-MM-DD HH:mm").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD HH:mm")) && moment.utc(manual.start_date, "YYYY-MM-DD HH:mm").isSameOrBefore(moment.utc(step.end_date, "YYYY-MM-DD HH:mm"))) {
                            has_car = true;
                        }
                        if ((manual.product_type === 11 || manual.product_type === 12)) {
                            if (moment.utc(manual.start_date, "YYYY-MM-DD").isSame(moment.utc(step.start_date, "YYYY-MM-DD"), "day")) {
                                has_poi = true;
                            }
                        }
                    });
                    return (
                        <Fragment key={index}>
                            <Grid item style={{width: "100%"}} ref={ el => ref.current[index] = el }>
                                {
                                    from === "tripSummary" && (
                                        <Grid container direction={"column"} spacing={2}>
                                            <RenderDestination step={step} nb_step={index} day_by_day={dayByDay} from_summary={from === "tripSummary"} first_day={first_day} last_day={last_day} step_name={step_name} nb_nights={nb_nights} guide_list={guide_list}/>
                                        </Grid>
                                    )
                                }
                            </Grid>
                            <Grid item>
                                <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={4}>
                                    {
                                        from !== "tripSummary" && (
                                            <Grid item container direction={"column"} justify={"center"} alignItems={"center"} spacing={2}>
                                                <Grid item style={{width: "100%"}}>
                                                    <span className={classes.bold} style={{fontSize: 30}}>{ t("filters.step") + " " + index }</span>
                                                    <span className={classes.bold}>{" - " + step_name[0] + ", " + t("global.from") + " " + moment.utc(step.start_date).format("DD/MM") + " " + t("global.to")  + " " + moment.utc(step.end_date).format("DD/MM") + " "}</span>
                                                    <span className={classes.bold}>({(nb_nights > 1 ? nb_nights + " " + t("itinerary.nights") : nb_nights + " " + t("itinerary.night")) + " - " + t("menu.history.csv.day") + " " + first_day + " " + t("global.at") + " " + last_day })</span>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                    {
                                        has_hotel && (
                                            <Grid item container direction={"column"} spacing={4} xs style={{width: "100%"}}>
                                                <RenderSummaryAccommodation hotel_cart={hotel_cart} manual_cart={manual_cart} step={step} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={index} from={from} byDay={false} otherProduct={false}/>
                                            </Grid>
                                        )
                                    }
                                    {
                                        has_car && (
                                            <Grid item container direction={"column"} spacing={4} xs style={{width: "100%"}}>
                                                <RenderSummaryCars cars_cart={cars_cart} manual_cart={manual_cart} step={step} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={index} from={from} byDay={false} otherProduct={false}/>
                                            </Grid>
                                        )
                                    }
                                    {
                                        has_transfers && (
                                            <Grid item container direction={"column"} spacing={4} xs style={{width: "100%"}}>
                                                <RenderSummaryTransfers transfers_cart={transfers_cart} manual_cart={manual_cart} step={step} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={index} from={from} byDay={false} otherProduct={false}/>
                                            </Grid>
                                        )
                                    }
                                    {
                                        has_flight && (
                                            <Grid item container direction={"column"} spacing={4} xs style={{width: "100%"}}>
                                                <RenderSummaryFlight flight_cart={flight_cart} manual_cart={manual_cart} type={"departure"} start_date={step.start_date} end_date={step.end_date} position={"step"} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={index} from={from} byDay={false} otherProduct={false}/>
                                            </Grid>
                                        )
                                    }
                                    {
                                        has_poi && (
                                            <Grid item container direction={"column"} spacing={4} xs style={{width: "100%"}}>
                                                <RenderSummaryPoi poi_cart={poi_cart} manual_cart={manual_cart} step={step} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={index} from={from} byDay={false} otherProduct={false}/>
                                            </Grid>
                                        )
                                    }
                                    {
                                        index !== 0 && index < itinerary.length - 2 && (
                                            <Grid item style={{width: "100%"}}>
                                                <RenderTransport iti={step} next_iti={itinerary[index + 1]}/>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Fragment>
                    )
                }
            })
        }
        </Fragment>
    )
})
export default React.memo(RenderProductsStep);