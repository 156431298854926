import GetPricesId from "../../../Cart/Functions/Margin/GetPricesId";

export default(item, data, trip_start_date, trip_end_date, user, owner_id, setCustomCurrency, setHasCustomCurrency, setCustomFactor, setCurrencyBase, type) => {
	let start_date = item.start_date !== null ? item.start_date : trip_start_date;
	let end_date = item.end_date !== null ? item.end_date : trip_end_date;
	let has_custom_currency = false;
	let prices = null;
	if (type === 0) {
		prices = item.prices_flight;
	}
	else if (type === 1) {
		prices = item.prices_terrestrial;
	} else if (type === 2) {
		prices = item.prices_stack_product;
	}
	else {
		prices = item.prices;
	}
	if (data !== undefined && data !== null) {
	    for (let i = 0; i < data.results.length; i++) {
	        if (data.results[i].custom_currency.length > 0) {
	            for (let j = 0; j < data.results[i].custom_currency.length; j++) {
	                if (prices[GetPricesId(prices, owner_id, user, true)].purchase_currency === data.results[i].id
	                    && prices[GetPricesId(prices, owner_id, user, true)].selling_currency === data.results[i].custom_currency[j].currency_to) {
	                    if ((moment.utc(start_date)).isBetween(moment.utc(data.results[i].custom_currency[j].start_date), moment.utc(data.results[i].custom_currency[j].disable_date), "days", '[]') === true
	                        && (moment.utc(end_date)).isBetween(moment.utc(data.results[i].custom_currency[j].start_date), moment.utc(data.results[i].custom_currency[j].disable_date), "days", '[]') === true) {
                                setCustomCurrency(data.results[i].custom_currency[j]);
                                setHasCustomCurrency(true);
                                setCustomFactor(data.results[i].custom_currency[j].factor);
                                setCurrencyBase(data.results[i]);
	                            has_custom_currency = true;
	                    }
	                }
	            }
	        }
	    }
	}
	if (has_custom_currency === false) {
        setCustomCurrency(null);
        setHasCustomCurrency(false);
        setCustomFactor(null);
        setCurrencyBase(null)
	}
}