import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDrop } from "react-dnd";
import {
    Alert,
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Collapse,
    Divider,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    styled
} from "@mui/material";
import {
    Add,
    Dashboard,
    Delete,
    ExpandLess,
    ExpandMore,
    ShoppingCart
} from "@mui/icons-material";
import { green, orange } from "@mui/material/colors";
import { useSnackbar } from "notistack";
import { intersection, mapValues } from "lodash";
import {
    CartPackageDraggableItem,
    CartPackageDraggableItemProps
} from "./CartPackageDraggableItem";
import { PackageInput } from "./CartPackagePackagesList";
import { CartPackageItemInfo } from "./CartPackageItemInfo";

type Props = {
    stackNumber: number | null,
    stackInfoId: number | null,
    package: PackageInput[number] | undefined,
    onChangePackages: React.Dispatch<React.SetStateAction<PackageInput>>
}

export function CartPackageItem(props: Props): JSX.Element {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const filterDroppableItems = (items: CartPackageDraggableItemProps[] | undefined) => {
        return items?.filter((item) => {
            const providerContentTypes = props.package?.provider?.enabled_content_types ??
                                     props.package?.provider?.available_content_types ??
                                     [];
            return !!item &&
                   (
                       providerContentTypes.includes(item.productType) ||
                       (
                           [0, 7].includes(item.productType) &&
                           intersection([0, 7], providerContentTypes).length > 0
                       ) ||
                       (
                           [1, 12].includes(item.productType) &&
                           intersection([1, 12], providerContentTypes).length > 0
                       ) ||
                       providerContentTypes.includes(11)
                   );
        }) ?? [];
    };
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [collected, drop] = useDrop<
        CartPackageDraggableItemProps[],
        unknown,
        {isDragging: boolean, isHover: boolean}
    >(() => ({
        accept: 'package-product',
        collect(monitor) {
            return {
                isDragging: monitor.canDrop(),
                isHover: monitor.isOver()
            };
        },
        drop(items) {
            const droppableItems = filterDroppableItems(items);
            onAddProducts(droppableItems);
            if (items.length !== droppableItems.length) {
                enqueueSnackbar(
                    t<string>('cart-material.cart-construction-package-dnd-provider-error-hint'),
                    { variant: 'error' }
                );
            }
        }
    }), [props.package?.provider]);
    const dropBackgroundColor = useMemo(() => {
        if (collected.isDragging) {
            return orange.A100;
        } else if (collected.isHover) {
            return green.A100;
        }
        return '#fff';
    }, [collected]);

    const onToggleInfo = (stackNumber: number | null, stackInfoId: number | null) => {
        props.onChangePackages((state) => {
            const key = `${stackNumber ?? ''}-${stackInfoId ?? ''}`;
            const item = state[key];

            if (item) {
                return {
                    ...state,
                    [key]: {
                        ...item,
                        openInfo: !item.openInfo
                    }
                };
            }

            return state;
        });
    };

    const onToggleProducts = (
        stackNumber: number | null,
        stackInfoId: number | null
    ) => {
        props.onChangePackages((state) => {
            const key = `${stackNumber ?? ''}-${stackInfoId ?? ''}`;
            const item = state[key];

            if (item) {
                return {
                    ...state,
                    [key]: {
                        ...item,
                        openProducts: !item.openProducts
                    }
                };
            }

            return state;
        });
    };

    const onAddProducts = (products: CartPackageDraggableItemProps[]) => {
        props.onChangePackages((state) => {
            return mapValues(
                state,
                (item, key) => {
                    if (parseInt(key) === props.stackNumber) {
                        return {
                            ...item,
                            products: item.products.concat(products)
                        };
                    }
                    const productIdentifiers = products.map((product) => {
                        return `${product.type}-${product.id}`;
                    });
                    return {
                        ...item,
                        products: item.products.filter((item) => {
                            return !productIdentifiers.includes(`${item.type}-${item.id}`);
                        })
                    };
                }
            );
        });
    };

    const onDelete = () => {
        props.onChangePackages((state) => {
            const key = `${props.stackNumber ?? ''}-${props.stackInfoId ?? ''}`;
            //eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [key]: item, ...rest } = state;
            return rest;
        });
    };

    return (
        <ListItem>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar
                            sx={(theme) => ({
                                width: 24,
                                height: 24,
                                backgroundColor: theme.palette.primary.main
                            })}
                        >
                            <Dashboard sx={{ fontSize: 14 }} />
                        </Avatar>
                    }
                    action={
                        <Tooltip title={t<string>('shared.delete')}>
                            <IconButton onClick={onDelete}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    }
                    title={
                        t<string>(
                            'cart-material.cart-construction-package-name',
                            { name: props.stackNumber }
                        )
                    }
                    titleTypographyProps={{ fontWeight: 700 }}
                />
                <CardContent>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={(theme) => ({
                            "cursor": 'pointer',
                            "paddingTop": theme.spacing(1),
                            "paddingBottom": theme.spacing(1),
                            "paddingLeft": theme.spacing(2),
                            "paddingRight": theme.spacing(2),
                            "marginBottom": theme.spacing(1.5),
                            "marginLeft": theme.spacing(-2),
                            "width": `calc(100% + ${theme.spacing(4)})`,
                            '&:hover': {
                                backgroundColor: theme.palette.action.hover
                            }
                        })}
                        onClick={() => onToggleInfo(props.stackNumber, props.stackInfoId)}
                    >
                        <Typography component="div">
                            {t<string>('cart-material.cart-construction-package-info')}
                        </Typography>
                        {
                            props.package?.openInfo ?
                                <ExpandLess /> :
                                <ExpandMore />
                        }
                    </Stack>
                    <Collapse in={props.package?.openInfo}>
                        <Box sx={{ marginBottom: 3 }}>
                            <CartPackageItemInfo
                                stackNumber={props.stackNumber}
                                stackInfoId={props.stackInfoId}
                                package={props.package}
                                onChangePackages={props.onChangePackages}
                            />
                        </Box>
                    </Collapse>
                    {
                        props.package?.provider &&
                        <>
                            <Divider />
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={(theme) => ({
                                    "cursor": 'pointer',
                                    "paddingTop": theme.spacing(1),
                                    "paddingBottom": theme.spacing(1),
                                    "paddingLeft": theme.spacing(2),
                                    "paddingRight": theme.spacing(2),
                                    "marginTop": theme.spacing(2),
                                    "marginBottom": theme.spacing(1.5),
                                    "marginLeft": theme.spacing(-2),
                                    "width": `calc(100% + ${theme.spacing(4)})`,
                                    '&:hover': {
                                        backgroundColor: theme.palette.action.hover
                                    }
                                })}
                                onClick={() => onToggleProducts(props.stackNumber, props.stackInfoId)}
                            >
                                <Typography component="div">
                                    {t<string>('cart-material.cart-construction-package-products')}
                                </Typography>
                                {
                                    props.package?.openProducts ?
                                        <ExpandLess /> :
                                        <ExpandMore />
                                }
                            </Stack>
                            <Collapse in={props.package?.openProducts}>
                                <List>
                                    {
                                        props.package?.products.map((product) => (
                                            <CartPackageDraggableItem
                                                key={`${product.type}-${product.id}`}
                                                item={product}
                                                onChangePackages={props.onChangePackages}
                                                isRemovableFromPackages
                                                disableDnd
                                            />
                                        ))
                                    }
                                </List>
                                <Stack
                                    ref={drop}
                                    alignItems="center"
                                    spacing={1.5}
                                    sx={(theme) => ({
                                        border: '1px dashed #000',
                                        padding: 2,
                                        backgroundColor: dropBackgroundColor,
                                        color: theme.palette.getContrastText(dropBackgroundColor)
                                    })}
                                >
                                    {
                                        (props.package?.products.length ?? 0) === 0 &&
                                        <>
                                            <ShoppingCart />
                                            <Typography color="inherit" sx={{ textAlign: 'center' }}>
                                                {t<string>('cart-material.cart-construction-package-dnd-hint')}
                                            </Typography>
                                        </>
                                    }
                                    <Add />
                                </Stack>
                            </Collapse>
                        </>
                    }
                    {
                        !props.package?.provider &&
                        (props.package?.products.length ?? 0) > 0 &&
                        <>
                            <Divider />
                            <Alert severity="warning" sx={{ marginTop: 2 }}>
                                {t<string>('cart-material.cart-construction-package-no-provider')}
                            </Alert>
                        </>
                    }
                </CardContent>
            </Card>
        </ListItem>
    );
}

const List = styled('ul')(() => ({
    listStyle: 'none',
    paddingLeft: 0
}));


const ListItem = styled('li')((props) => ({
    marginBottom: props.theme.spacing(1.5)
}));
