//---- Dependencies ----//
let GoogleMapsLoader = require('google-maps');

import React from 'react';
import ReactDOM from "react-dom";
import Fab from "@material-ui/core/Fab";
import Hotel from "@material-ui/icons/Hotel";

function CustomMarker(latlng, map) {
    console.log('latlng:', latlng);
    this.latlng = latlng;
    this.setMap(map);
}

export default (component, info_hotel) => {
    let map = null;
    let key = "";
    let lat = parseFloat(info_hotel.hotel[0].latitude);
    let lng = parseFloat(info_hotel.hotel[0].longitude);
    let config = JSON.parse(localStorage.getItem("config"));
    for (let i = 0; i < config.keys.length; i++) {
        if (config.keys[i].identifier === "google_api") {
            key = config.keys[i].value;
        }
    }
    GoogleMapsLoader.KEY = key;
    GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];
    GoogleMapsLoader.LANGUAGE = "fr";
    GoogleMapsLoader.load(function(google) {
        if (document.getElementById('hotel-info-map-' + info_hotel.id) !== null) {
            let options = {
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                center: {lat: lat, lng: lng},
                zoom: 15,
                draggable: true,
                clickableIcons: false,
                // scaleControl: true,
                disableDefaultUI: true,
                zoomControl: true
            };
            map = new google.maps.Map(document.getElementById('hotel-info-map-' + info_hotel.id), options);
            map.setCenter(new google.maps.LatLng(lat, lng));
            CustomMarker.prototype = new google.maps.OverlayView();
            CustomMarker.prototype.createDiv = function() {
                this.div = ReactDOM.render(<div><Fab style={{backgroundColor: 'black', color: 'white'}} size={ "small" }><Hotel/></Fab></div>, document.createElement("div"));
                this.div.style.position = "absolute";
            };
            CustomMarker.prototype.appendDivToOverlay = function() {
                const panes = this.getPanes();
                if (panes !== null) {
                    panes.overlayImage.appendChild(this.div);
                }
            };
            CustomMarker.prototype.positionDiv = function() {
                const projection = this.getProjection();
                if (projection !== null) {
                    const point = projection.fromLatLngToDivPixel(this.latlng);
                    let offset = 25;
                    if (point) {
                        this.div.style.left = `${point.x - offset}px`;
                        this.div.style.top = `${point.y - offset}px`;
                    }
                }
            };
            CustomMarker.prototype.draw = function() {
                if (!this.div) {
                    this.createDiv();
                    this.appendDivToOverlay();
                }
                this.positionDiv();
            };
            CustomMarker.prototype.remove = function() {
                this.setMap(null);
                if (this.div) {
                    this.div.parentNode.removeChild(this.div);
                    this.div = null;
                }
            };
            new CustomMarker(new google.maps.LatLng(lat, lng), map);
        }
    });
};
