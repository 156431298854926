//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';

import RoomIcon from '@material-ui/icons/Room';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import PeopleIcon from '@material-ui/icons/People';
import WorkIcon from '@material-ui/icons/Work';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';

import MosaicPicture from '../../Accommodation/FunctionalComponent/MosaicPicture';
import Picture from '../../Accommodation/FunctionalComponent/Picture';
import Slider from '../../Accommodation/FunctionalComponent/Slider';
import MoreInfo from './MoreInfo.tsx';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
	italic: {
		fontStyle: "italic !important"
	},
	font14: {
		fontSize: 14
	},
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    cover: {
    	display: "block",
	    width: "100%",
	    height: "100%"
    },
    content: {
    	display: 'flex',
    	alignItems: "center",
    	paddingLeft: theme.spacing(1),
    	paddingBottom: theme.spacing(1),
    },
    pictureContainer: {
	    overflow: "hidden",
	    height: 200,
	    // width: 300,
	    position: "relative",
	    cursor: "pointer",
        "& img" : {
            height: "auto"
        }
    },
    shortDescription: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        display:"-webkit-box !important",
        WebkitLineClamp:3,
        WebkitBoxOrient:"vertical",
        whiteSpace:"normal"
    },
    lowerCase: {
        textTransform: "capitalize"
    },
    paddingLeft25: {
        paddingLeft: 25
    },
    checkbox: {
        // position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px',
        color: `${main_color}`,
        '&.Mui-checked': {
            color: `${main_color}`,
        },
    },
    gridImage: {
        width: 250
    },
    image: {
        borderRadius: 16
    },
    pin: {
        float: 'left',
        marginRight: 4
    },
    moreDetailContainer: {
        paddingBottom: 8
    },
    moreDetail: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    orange: {
        color: `${main_color}`
    },
    transferOptionSelected: {
		border: `2px solid ${main_color} !important`,
		backgroundColor: `${main_color}20`,
        borderRadius: 10
	},
}));

type TransfersCardProps = {
    transfer: any,
    variantIsChecked: any,
    onToggleVariant: any,
    transfersHasVariant: any,
    products: any
}

const TransfersCard = (props: TransfersCardProps) => {
    const {transfer, variantIsChecked, onToggleVariant, transfersHasVariant, products} = props;
    const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const type = useSelector((state) => state.itinerary_type.type);

    const [showChevron, setShowChevron] = useState<boolean>(false);
	const [openMozaic, setOpenMozaic] = useState<boolean>(false);
	const [openPicture, setOpenPicture] = useState<boolean>(false);
    const [moreInfo, setMoreInfo] = useState<boolean>(false);
    const [collapse, setCollapse] = useState<boolean>(true);
	const [current_picture, setCurrentPicture] = useState<number>(0);
    const [step, setStep] = useState<number>(0);
    const custom_product = Object.assign({}, transfer.is_custom ? transfer.custom_product : transfer);

    const onMouseEnter = () => {
	    setShowChevron(true);
	};
	const onMouseLeave = () => {
	    setShowChevron(false);
	};
	// const openModal = () => {
	// 	if (!fullScreen) {
	// 		togglePictureModal(pictures)
	// 	}
	// 	else {
	// 		toggleMosaicModal(pictures);
	// 	}
	// }
    const toggleMoreDetail = () => {
        setCollapse(!collapse);
    }
	// const togglePictureModal = (pictures, index_picture = 0) => {
	// 	setOpenPicture(!openPicture);
	// 	setCurrentPicture(index_picture);
	// };
	// const toggleMosaicModal = (pictures) => {
	// 	setOpenMozaic(!openMozaic);
    // };
    const handleOpenMore = (new_step:number) => () =>  {
        setStep(new_step);
        setMoreInfo(!moreInfo);
    }
    const getPicture = (info: any) => {
        let picture = '/Img/destination_default.jpg';
        if (info.cover_picture !== undefined && info.cover_picture !== null && info.cover_picture.url !== null) {
            picture = info.cover_picture.url;
        } else if (info.custom_pictures !== undefined && info.custom_pictures !== null && info.custom_pictures.length > 0) {
            if (info.pictures[0].url !== null && info.pictures[0].url !== '') {
                picture = info.custom_pictures[0].url;
            } else if (info.custom_pictures[0].thumbnail_little !== null && info.custom_pictures[0].thumbnail_little !== '') {
                picture = info.custom_pictures[0].thumbnail_little;
            }
        } else if (info.pictures !== undefined && info.pictures !== null && info.pictures.length > 0) {
            if (info.pictures[0].url !== null && info.pictures[0].url !== '') {
                picture = info.pictures[0].url;
            } else if (info.pictures[0].thumbnail_little !== null && info.pictures[0].thumbnail_little !== '') {
                picture = info.pictures[0].thumbnail_little;
            }
        }
        return picture;
    };
    let check_in_cart = products.find((product: any) => {
        if (product.start_destination !== undefined && product.start_destination !== null && transfer.start_destination !== undefined && transfer.start_destination !== null) {
    		return transfer.start_destination.data.id === product.start_destination.data.id && transfer.product_type === product.product_type && !product.is_optional;
        }
	});
    let check_option = products.find((product: any) => {
        if (product.start_destination !== undefined && product.start_destination !== null && transfer.start_destination !== undefined && transfer.start_destination !== null) {
    		return transfer.start_destination.data.id === product.start_destination.data.id && transfer.product_type === product.product_type && product.is_optional;
        }
	});
    return (
        <Grid container direction={"row"} spacing={2} style={{marginBottom: 10, padding: 10}}>
            <Grid item xs container direction={"column"} alignItems={"flex-start"}>
				<Grid item>
                    {
                        transfer.product_type === 13 &&
                        <DirectionsBoatIcon className={`${classes.genericText} ${classes.orange}`} style={{verticalAlign: "sub", marginRight: 10}}/>
                    }
                    {
                        transfer.product_type !== 13 &&
                        <TransferWithinAStationIcon className={`${classes.genericText} ${classes.orange}`} style={{verticalAlign: "sub", marginRight: 10}}/>
                    }
					<span className={classes.genericText} style={{fontWeight: 'bold', textTransform: 'uppercase'}}>{ `${(!!transfer.type || transfer.custom_product !== undefined) ? t('cars.categories.' + ((!!transfer.type ? transfer.type : (transfer.custom_product !== undefined && !!transfer.custom_product.vehicle_type ? transfer.custom_product.vehicle_type : '')).toLowerCase()).replaceAll(" ", "_")) : t('cart-material.transfer')} ${transfer.is_custom ? transfer.origin_name : transfer.station_name_pickup}` } </span>
				</Grid>
                <Grid item className={`${classes.genericText} ${classes.italic}`}>{transfer.is_custom ? transfer.custom_product.title : transfer.name}</Grid>
				<Grid item>
					<RoomIcon className={`${classes.genericText}`} style={{fontSize: 16, verticalAlign: "text-top", color: `${main_color}`}}/>
					<span className={`${classes.genericText} ${classes.italic}`} style={{fontSize: 14}}>{ transfer.is_custom ? transfer.origin_name : transfer.station_name_pickup }</span>
					<ArrowRightAltIcon className={`${classes.genericText}`} style={{fontSize: 16, verticalAlign: "middle", marginLeft: 2, marginRight: 2}}/>
					<span className={`${classes.genericText} ${classes.italic}`} style={{fontSize: 14}}>{ transfer.is_custom ? transfer.destination_name : transfer.station_name_return }</span>
				</Grid>
                {
                    check_option &&
                    <Grid item style={{marginTop: 10, border: "1px solid #e5e7eb", width: '100%', borderRadius: 10}} className={clsx(classes.genericText, {[classes.transferOptionSelected]: variantIsChecked(transfer)})}>
                        <FormControlLabel
                            control={<Checkbox className={ classes.checkbox } edge={ 'start' } disableRipple checked={ variantIsChecked(transfer) } onChange={ onToggleVariant(transfer) } />}
                            label={ transfer.is_optional ? (check_in_cart ? t<string>("itineraryType.alternate_transfer") : t<string>("itineraryType.optional_transfer")) : t<string>("itineraryType.default_transfer", {type: t('shared.' + type)})}
                        />
                    </Grid>
                }
			</Grid>
            {/* <Grid className={ classes.moreDetailContainer } item xs={ 12 }>
                <Typography className={ classes.moreDetail } align={ 'center' } onClick={ toggleMoreDetail }>{collapse ? t<string>('cart-material.show-more-details') : t<string>('cart-material.show-less-details')}</Typography>
            </Grid> */}
            {
                // !collapse &&
                // <Grid item container direction={transfer.is_optional ? "row-reverse" : "row"} justify={"flex-start"} alignItems={"center"} spacing={transfer.is_optional || fullScreen ? 2 : 0}>
                //     <Grid item sm={5} xs={12} className={ classes.gridImage }>
                //         {/* <Slider pictures={ pictures } showChevron={ showChevron } size={ 0 } dotSize={ 1 }/> */}
                //         <img className={ classes.image } src={ transfer.is_custom ? getPicture(transfer.custom_product) : transfer.picture_url } alt={ 'transfer-picture' } width={ 250 }/>
                //     </Grid>
                //     <Grid item sm={7} xs={12} className={clsx({[classes.paddingLeft25]: !fullScreen})}>
                //         <Grid container direction={"column"} spacing={2}>
                //             <Grid item>
                //                 <Typography variant={"h5"}>{ t<string>("footer.about") }</Typography>
                //             </Grid>
                //             {/* <Grid item className={classes.genericText}><RoomIcon className={ classes.pin }/></Grid>
                //             <Grid item className={classes.genericText}><Typography>{ transfer.is_custom ? transfer.origin_name : transfer.station_name_pickup }</Typography></Grid>
                //             <Grid item className={classes.genericText}><ArrowRightAltIcon className={ classes.pin }/></Grid>
                //             <Grid item className={classes.genericText}><Typography>{ transfer.is_custom ? transfer.destination_name : transfer.station_name_return }</Typography></Grid> */}
                //             {
                //                 // custom_product.taking_address !== null && custom_product.taking_address !== "" && (
                //                 //     <Grid item className={classes.genericText}>
                //                 //     { t("poi.taking_address") } : { custom_product.taking_address }
                //                 //     </Grid>
                //                 // )
                //             }
                //             {
                //                 ((transfer.is_custom ? (transfer.custom_product.max_passengers !== undefined && transfer.custom_product.max_passengers !== null) : (transfer.max_capacity !== undefined && transfer.max_capacity !== null)) || (transfer.is_custom ? (transfer.custom_product.nb_baggage !== undefined && transfer.custom_product.nb_baggage !== null) : (transfer.baggage !== undefined && transfer.baggage !== null) )) && (
                //                     <Grid item container direction={"row"} spacing={2}>
                //                         {
                //                             (transfer.is_custom ? transfer.custom_product.max_passengers !== null : transfer.max_capacity !== null) && (
                //                                 <Grid item>
                //                                     <Tooltip title={t<string>("flight_groups.passengers_max")}>
                //                                         <PeopleIcon className={classes.genericText} style={{verticalAlign: "sub", marginRight: 10}}/>
                //                                     </Tooltip>
                //                                     <span className={classes.genericText} style={{fontWeight: "bold"}}>{transfer.is_custom ? transfer.custom_product.max_passengers : transfer.max_capacity}</span>
                //                                 </Grid>
                //                             )
                //                         }
                //                         {
                //                             (transfer.is_custom ? transfer.custom_product.nb_baggage !== null : transfer.baggage !== null) && (
                //                                 <Grid item>
                //                                     <Tooltip title={t<string>("global.max_luggages")}>
                //                                         <WorkIcon className={classes.genericText} style={{verticalAlign: "sub", marginRight: 10}}/>
                //                                     </Tooltip>
                //                                     <span className={classes.genericText} style={{fontWeight: "bold"}}>{transfer.is_custom ? transfer.custom_product.nb_baggage : transfer.baggage}</span>
                //                                 </Grid>

                //                             )
                //                         }
                //                     </Grid>
                //                 )
                //             }
                //             {
                //                 !transfer.is_custom && transfer.important_notice !== null && transfer.important_notice !== "" && (
                //                     <Grid item>
                //                         {t<string>("global.description") + " : "}
                //                         <div className={`${classes.genericText} ${classes.shortDescription}`} dangerouslySetInnerHTML={ {__html: transfer.important_notice } } />
                //                         <Button className={`${classes.genericText} ${classes.lowerCase}`} variant={"outlined"} onClick={handleOpenMore(0)} style={{float: "right"}}>{ t<string>("poi.see_more") }</Button>
                //                     </Grid>
                //                 )
                //             }
                //             {
                //                 transfer.is_custom && transfer.custom_product.short_description !== null && transfer.custom_product.short_description !== "" && (
                //                     <Grid item>
                //                         {t("global.description") + " : "}
                //                         <div className={`${classes.genericText} ${classes.shortDescription}`} dangerouslySetInnerHTML={ {__html: transfer.custom_product.short_description } } />
                //                         {
                //                             transfer.custom_product.long_description !== null && transfer.custom_product.long_description !== "" && (
                //                                 <Button className={`${classes.genericText} ${classes.lowerCase}`} variant={"outlined"} onClick={handleOpenMore(0)} style={{float: transfer.is_optional ? "left" :"right"}}>{ t<string>("poi.see_more") }</Button>
                //                             )
                //                         }
                //                     </Grid>
                //                 )
                //             }
                //         </Grid>
                //         {
                //             // fullScreen && transfersHasVariant && (
                //             //     <Grid item xs={12}>
                //             //         <FormControlLabel
                //             //             control={<Checkbox className={ classes.checkbox } edge={ 'start' } disableRipple checked={ variantIsChecked(transfer) } onChange={ onToggleVariant(transfer) } />}
                //             //             label={ variantIsChecked(transfer) ? t("itineraryType.chosen_transfer") : t("itineraryType.alternate_transfer")}
                //             //         />
                //             //     </Grid>
                //             // )
                //         }
                //     </Grid>
                // </Grid>
            }
            {
                // transfer.is_optional && (
                //     <Grid item style={{width: "100%"}}>
                //         <Alert variant="filled" severity="info" style={{opacity: "70%"}}>
                //         {t("summary.optional_transfer")}
                //         </Alert>
                //     </Grid>
                // )
            }
            {/* <MoreInfo activity={transfer} info={custom_product} moreInfo={moreInfo} setMoreInfo={setMoreInfo} step={step} setStep={setStep}/> */}
            {/* <MosaicPicture open={ openMozaic } onClose={ toggleMosaicModal } pictures={ pictures } openModal={ togglePictureModal }/>
			<Picture open={ openPicture } onClose={ togglePictureModal } pictures={ pictures } current_picture={ current_picture } setCurrentPicture={ setCurrentPicture }/> */}
        </Grid>
    )
};
export default React.memo(TransfersCard);