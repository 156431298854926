export function SetUser(user) {
    return {
        type: "USER_SET_USER",
        payload: {
            user: user
        }
    }
}
export function SetUserPermissions(permissions) {
    return {
        type: "USER_SET_PERMISSIONS",
        payload: {
            permissions: permissions
        }
    }
}
export function SetUserList(user_list) {
    return {
        type: "USER_SET_USER_LIST",
        payload: {
            user_list: user_list
        }
    }
}
export function SetClient(client) {
    return {
        type: "USER_SET_CLIENT",
        payload: {
            client: client
        }
    }
}
export function SetUToken(token) {
    return {
        type: "USER_SET_TOKEN",
        payload: {
            token: token
        }
    }
}
export function SetUserConnection(connected) {
    return {
        type: "USER_SET_USER_CONNECTION",
        payload: {
            connected: connected
        }
    }
}

export function ToggleModalConnection(modal_connection, step) {
    return {
        type: "USER_TOGGLE_MODAL_CONNECTION",
        payload: {
            modal_connection: modal_connection,
            step: step
        }
    }
}

export function ToggleModalResetPwd(modal_connection, step, user_id, token_pwd) {
    return {
        type: "USER_TOGGLE_MODAL_RESET_PWD",
        payload: {
            modal_connection: modal_connection,
            step: step, 
            user_id: user_id,
            token_pwd: token_pwd,
        }
    }
}

export function ConnectUser(modal_connection, is_connected, user, token) {
    return {
        type: "USER_SET_CONNECTION",
        payload: {
            modal_connection: modal_connection,
            is_connected: is_connected,
            user: user,
            token: token
        }
    }
}

export function SetUserAndConnection(user, is_connected) {
    return {
        type: "USER_SET_USER_AND_CONNECTION",
        payload: {
            is_connected: is_connected,
            user: user,
        }
    }
}
export function SetLocales(locales) {
    return {
        type: "USER_SET_LOCALES",
        payload: {
            locales: locales
        }
    }
}