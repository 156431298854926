// Dependencies
import React, { useRef } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useDrag, useDrop } from 'react-dnd';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Core
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// Icon
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
// Type
const ItemTypes = {
    ITEM: 'item',
};
const useStyles = makeStyles(theme => ({
    ftMove: {
        cursor: "move"
    },
    ftMoving: {
        opacity: "0.2"
    }
}));
const FilterCard = ({filter, index, id}) => {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const classes = useStyles();

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.ITEM,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            dispatch({type: "MENU_UPDATE_SORT_FILTERS", payload: {drag_index: dragIndex, hover_index: hoverIndex}});
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.ITEM,
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drag(drop(ref));
    return (
        <ListItem ref={ ref } className={ clsx(classes.ftMove, {[classes.ftMoving]: isDragging}) } data-handler-id={ handlerId }>
            <ListItemIcon>
                <DragIndicatorIcon />
            </ListItemIcon>
            <ListItemText primary={filter.name} />
        </ListItem>
    );
};

export default FilterCard;