export default (vehicle) => {
    switch (true) {
        case vehicle === "bicycle" || vehicle === "foot" || vehicle === "car" || vehicle === "rideshare" || vehicle === "taxi":
            return "gray";
        case vehicle === "bus" :
            return "orange";
        case vehicle === "plane" :
            return "green";
        case vehicle === "ferry" || vehicle === "helicopter":
            return "light-blue";
        case vehicle === "train" :
            return "purple";
        case vehicle === "subway" || vehicle === "tram":
            return "blue";
    } 
    return "black";
}
