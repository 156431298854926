// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Core & Lab
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Box, Tooltip, Typography, makeStyles } from '@material-ui/core';

const useStyle = makeStyles({
    label: {
        width: "100%",
    },
  });

const CartAssistanceVip = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyle();
    const assistance_package = useSelector(state => state.cart.assistance_package);
    const onToggleAssistance = () => {
        dispatch({type: 'CART_ASSISTANCE_PACKAGE', payload: 'Vip'});
    };
    const Label = (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className={classes.label}
        >
            <Typography>{t('cart-material.assistance-vip-option')}</Typography>
            <Tooltip placement={"right"} title={'Inclus tous les services du forfait Premium ainsi qu’une ligne téléphonique dédiée, un accès aux services de conciergerie dès la remise du carnet de voyage à vos clients, un briefing quotidien durant leur séjour ainsi qu’un forfait internet offert (carte e-sim).'}>
                <InfoIcon style={{marginLeft: 5}}/>
            </Tooltip>
        </Box>
      );
    return (
        <FormControl fullWidth>
            <FormControlLabel control={ <Checkbox checked={ assistance_package === 'Vip' } onChange={ onToggleAssistance } color={ 'primary' }/> } label={ Label }/>
        </FormControl>
    );
};

export default CartAssistanceVip;