import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';

import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    moreInfoText:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "black"
    },
    fontWeight900:{
        fontWeight: 900
    },
    fontSize12:{
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize16:{
        fontSize: 16
    },
    upperCase:{
        textTransform: "uppercase",
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter:{
        textAlign: "center"
    },
    spacer:{
        padding: "8px 0px"
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProductMoreInfo = ({ product, product_index, productsMoreInfo, setProductsMoreInfo }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const providers = useSelector(store => store.trip.providers);
    const user = useSelector(store => store.user.user);
    const [productProvider, setProductProvider] = useState(null);

    const smallScreen = useMediaQuery('(max-width:425px)');

    useEffect(() => {
        providers.map((provider) => {
            if(provider.provider.id === product.provider){
                setProductProvider(provider);
            }
        })

    }, [providers, product])

    const closeMoreInfo = (product_index) => {
        let temp_more_info = productsMoreInfo.slice();
        temp_more_info[product_index] = false;
        setProductsMoreInfo(temp_more_info);
    }

    return(
        <Dialog open={productsMoreInfo[product_index]} fullScreen={smallScreen ? true : false} TransitionComponent={Transition} fullWidth maxWidth={"xs"}>
            <DialogTitle>
                <Grid container justify={ smallScreen ? "flex-start" : "space-between" } alignItems={ "center" }>
                    {
                        smallScreen && (
                            <Grid item style={{marginRight: "5%"}}>
                                <IconButton onClick={() => closeMoreInfo(product_index)} size='small'> <NavigateBeforeIcon /> </IconButton>
                            </Grid>
                        )
                    }
                    <Grid item className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${!smallScreen ? classes.fontSize16 : classes.fontSize12} ${classes.letterSpacing}`}>
                        { t("booking.vehicle") + " - " + (product_index + 1) + " " }
                        { product.product_type === 4 && product.is_custom ? product.custom_product.title : product.name}
                    </Grid>

                    {
                        !smallScreen && (
                            <Grid item>
                                <IconButton onClick={() => closeMoreInfo(product_index)} size='small'> <CloseIcon style={{float: "right"}}/> </IconButton>
                            </Grid>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent dividers style={ smallScreen ? {padding: "4px 8px"} : {}}>
                <Grid className={`${classes.spacer} ${classes.textCenter}`}>
                    {
                        product.vehicle_picture_url !== null && product.vehicle_picture_url !== "" && (
                            <img src={ product.product_type === 4 ? (product.is_custom ? (product.custom_product.pictures.length > 0 ? product.custom_product.pictures[0].url : "") : product.picture_url) : product.vehicle_picture_url } alt={ 'product-picture' } width={ 100 }/>
                        )
                    }
                    {
                        product.product_type === 2 && product.subprovider_logo !== null && (user.client_full.type !== 2 || JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie') && productProvider !== null && productProvider.provider.logo !== null && (
                            <span>
                                <img src={ productProvider.provider.logo.url } alt={ 'product-provider-picture' } width={ 100 } style={{ paddingLeft: 20 }}/>
                                <img src={ product.subprovider_logo } alt={ 'product-provider-picture' } width={ 100 } style={{ paddingLeft: 20 }}/>
                            </span>
                        )
                    }
                    {
                        product.product_type === 2 && product.subprovider_logo !== null && user.client_full.type === 2 && (
                            <img src={ product.subprovider_logo } alt={ 'product-provider-picture' } width={ 100 } style={{ paddingLeft: 20 }}/>
                        )
                    }
                    {
                        product.product_type === 2 && product.subprovider_logo === null && productProvider !== null && productProvider.provider.logo !== null && (
                            <img src={ productProvider.provider.logo.url } alt={ 'product-provider-picture' } width={ 100 } style={{ paddingLeft: 20 }}/>
                        )
                    }

                    {
                        product.product_type !== 2 && (user.client_full.type !== 2 || JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie') && productProvider !== null && productProvider.provider.logo !== null && (
                            <img src={ productProvider.provider.logo.url } alt={ 'product-provider-picture' } width={ 100 } style={{ paddingLeft: 20 }}/>
                        )
                    }
                </Grid>
                <Grid container alignItems="flex-start" justify="flex-start" spacing={2}>
                    <Grid item>
                        <LocationOnOutlinedIcon />
                    </Grid>
                    <Grid item className={`${classes.moreInfoText} ${classes.fontWeight400} ${!smallScreen ? classes.fontSize14 : classes.fontSize12} ${classes.letterSpacing}`}>
                        { product.is_manual !== undefined && product.is_manual && product.start_destination !== null ? (product.start_destination.data.name !== null ? product.start_destination.data.name : product.start_destination.data.international_name) : (product.product_type === 4 && product.is_custom ? product.origin_name : product.station_name_pickup) }
                    </Grid>
                </Grid>
                {
                    (product.is_manual === undefined || (product.is_manual !== undefined && !product.is_manual)) && (
                        <Grid container alignItems="flex-start" justify="flex-start" spacing={2}>
                            <Grid item>
                                <LocationOnOutlinedIcon />
                            </Grid>
                            <Grid item className={`${classes.moreInfoText} ${classes.fontWeight400} ${!smallScreen ? classes.fontSize14 : classes.fontSize12} ${classes.letterSpacing}`}>
                                { product.product_type === 4 && product.is_custom ? product.destination_name : product.station_name_return }
                            </Grid>
                        </Grid>
                    )
                }
                <Grid container alignItems="flex-start" justify="flex-start" spacing={2}>
                    <Grid item>
                        <DateRangeOutlinedIcon />
                    </Grid>
                    <Grid item className={`${classes.moreInfoText} ${classes.fontWeight400} ${!smallScreen ? classes.fontSize14 : classes.fontSize12} ${classes.letterSpacing}`}>
                        { t("global.from") + " " + (!smallScreen ? moment.utc(product.start_date).format('DD MMMM HH:mm') : moment.utc(product.start_date).format('DD/MM HH:mm')) }
                        { product.end_date !== null ? " " + t("global.to") + " " + (!smallScreen ? moment.utc(product.end_date).format('DD MMMM HH:mm') : moment.utc(product.end_date).format('DD/MM HH:mm')) : "" }
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default React.memo(ProductMoreInfo);