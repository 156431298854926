import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSnackbar } from "notistack";
import clsx from "clsx";

import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from '@material-ui/core/Button';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";

import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import GetUserList from "./Functions/GetUserList";
import HasPermission from "../../Common/Functions/HasPermission";
import RenderUser from "./RenderUser";
import EditUser from "./EditUser.js";

//---- Actions ----//
import { SetManagementStepAndUser } from "../../../Actions/Menu";

const useStyles = makeStyles({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    marginBottom70: {
        marginBottom: 70
    },
    addButton: {
        "color": "#e6592f",
        "backgroundColor": "white",
        '&:hover': {
            color: "white",
            backgroundColor: "#e6592f"
        }
    },
    stickyOverflow: {
        overflowX: 'inherit'
    },
    sticky: {
        position: 'sticky',
        top: 70,
        backgroundColor: 'white',
        zIndex: 1
    },
    paddingCell: {
        "& .mui-jss-MuiTableCell-root": {
            fontSize: 12
        }
    }
});

const AgencyUsesTable = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down("sm"));
    const { enqueueSnackbar } = useSnackbar();

    const user_list = useSelector(store => store.user.user_list);
    const management_step = useSelector(store => store.menu.management_step);
    const user = useSelector(store => store.user.user);
    const permissions = useSelector(store => store.user.permissions);
    const [tableColumn, setTableColumn] = useState(!downDesktop ? [t("global.name"), t("menu.profile.surname"), t("menu.profile.username"), t("menu.user_management.role"), t("menu.created_date")] : [t("menu.profile.username")]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState(!downDesktop ? t("menu.created_date") : t("menu.profile.username"));
    useEffect(() => {
        GetUserList(dispatch, user.client);
    }, []);
    //useEffect(() => {
    //if (user_list !== null && user_list.length !== 0) {
    //sortUser();
    //}
    //}, [user_list, orderBy, order]);
    useEffect(() => {
        if (downDesktop) {
            setTableColumn([t("menu.profile.username")]);
        } else {
            setTableColumn([t("global.name"), t("menu.profile.surname"), t("menu.profile.username"), t("menu.user_management.role"), t("menu.created_date")]);
        }
    }, [downDesktop]);
    const addNewUser = () => {
        if (HasPermission(permissions, "add_user", user) === true) {
            dispatch(SetManagementStepAndUser(2, null)); 
        } else {
            enqueueSnackbar(t("notify.no_permissions"), { variant: "error" });
        }
    };
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const sortUser = () => {
        let new_list = user_list.sort((a, b) => {
            if (orderBy === t("global.name")) {
                if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) {
                    return -1;
                }
                if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) {
                    return 1;
                }        
                return 0;        
            }
            if (orderBy === t("menu.profile.surname")) {
                if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
                    return -1;
                }
                if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
                    return 1;
                }
                return 0;
            }
            if (orderBy === t("menu.profile.username")) {
                if (a.username.toLowerCase() < b.username.toLowerCase()) {
                    return -1;
                }
                if (a.username.toLowerCase() > b.username.toLowerCase()) {
                    return 1;
                }
                return 0;
            }
            if (orderBy === t("menu.user_management.role")) {
                if (a.groups.length !== 0 && b.groups.length !== 0) {
                    return (a.groups[0].id - b.groups[0].id);
                }
            }
            if (orderBy === t("menu.created_date")) {
                let da = new Date(a.date_joined);
                let db = new Date(b.date_joined);
                return (da - db);
            }
        });
        if (order === "desc") {
            new_list = new_list.reverse();
        }
        return new_list;
    };
    return (
        <Container className={classes.container}>
            <Grid container spacing={ 2 }>
                <Grid item xs={12} className={ clsx({ [classes.marginBottom70]: downDesktop }) }>
                    {
                        user_list === null && (
                            <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                                <Grid item>
                                    <CircularProgress />
                                </Grid>
                                <Grid item>
                                    <span> {t("menu.user_management.loading")} </span>
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        user_list !== null && management_step === 1 && (
                            <Fragment>
                                <Grid item xs={12}>
                                    <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                                        <Grid item>
                                            <Button
                                                variant={"contained"}
                                                className={classes.addButton}
                                                startIcon={<AddCircleOutlineOutlinedIcon />}
                                                onClick={addNewUser}
                                            >
                                                {t("menu.user_management.add_user")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={{ marginTop: 10 }}>
                                    <TableContainer className={ classes.stickyOverflow }>
                                        <Table id={"trip-list"}>
                                            <TableHead>
                                                <TableRow className={classes.paddingCell}>
                                                    <TableCell className={ classes.sticky } align={"left"}>
                                                        {t("menu.user_management.avatar")}
                                                    </TableCell>
                                                    {
                                                        tableColumn.map((column, column_index) => {
                                                            return (
                                                                <TableCell align={"left"} key={column_index} className={ classes.sticky } sortDirection={orderBy === column ? order : false}>
                                                                    <TableSortLabel
                                                                        active={orderBy === column}
                                                                        direction={orderBy === column ? order : 'asc'}
                                                                        onClick={createSortHandler(column)}
                                                                    >
                                                                        {column}
                                                                    </TableSortLabel>
                                                                </TableCell>
                                                            );
                                                        })
                                                    }
                                                    <TableCell className={ classes.sticky }/>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    user_list !== null && sortUser().map((row, row_index) => (
                                                        <RenderUser key={row_index} row={row} row_index={row_index} columns={tableColumn} permissions={permissions}/>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Fragment>
                        )
                    }
                    {
                        user_list !== null && management_step === 2 && (
                            <EditUser/>
                        )
                    }
                </Grid>
            </Grid>
        </Container>
    );
};
export default React.memo(AgencyUsesTable);
