//---- Dependencies ----//
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

//---- Commons ----//
import HotelInfoMapRun from "./Functions/HotelInfoMapRun";

//---- CSS ----//
import "../../../Style/Accommodation.css";

//---- Actions ----//
import { TogleInfoHotelAndSetStep } from "../../../Actions/Summary";

//---- Class ----//
@connect((store) => {
    return {
        info_hotel: store.summary.info_hotel,
        modal_info_hotel: store.summary.modal_info_hotel,
        step_info_hotel: store.summary.step_info_hotel
    }
})
@withTranslation()
class HotelInfoModal extends Component {
    constructor(props, context) {
        super(props, context);
    }
    componentDidUpdate(prevProps, prevState) {
        const { info_hotel, step_info_hotel, modal_info_hotel } = this.props;
        if (modal_info_hotel && info_hotel !== null && step_info_hotel === 5 && prevProps.info_hotel !== info_hotel) {
            HotelInfoMapRun(this, info_hotel);
        }
    }
    render() {
        const { info_hotel, step_info_hotel, modal_info_hotel, dispatch, t } = this.props;
        let situation = null;
        let distances = null;
        let equipements = null;
        let rooms = null;
        let check_in_out = null;
        let restauration = null;
        let other = null;
        let sports_animation = null;
        let to_know = null;
        if (info_hotel !== null) {
            for (let i = 0; i < info_hotel.hotel[0].content.length; i++) {
                if (info_hotel.hotel[0].content[i].section === 100) {
                    situation = info_hotel.hotel[0].content[i];
                }
                if (info_hotel.hotel[0].content[i].section === 110) {
                    equipements = info_hotel.hotel[0].content[i];
                }
                if (info_hotel.hotel[0].content[i].section === 120) {
                    rooms = info_hotel.hotel[0].content[i];
                }
                if (info_hotel.hotel[0].content[i].section === 150) {
                    check_in_out = info_hotel.hotel[0].content[i];
                }
                if (info_hotel.hotel[0].content[i].section === 160) {
                    restauration = info_hotel.hotel[0].content[i];
                }
                if (info_hotel.hotel[0].content[i].section === 170) {
                    to_know = info_hotel.hotel[0].content[i];
                }
                if (info_hotel.hotel[0].content[i].section === 200) {
                    other = info_hotel.hotel[0].content[i];
                }
                if (info_hotel.hotel[0].content[i].section === 210) {
                    distances = info_hotel.hotel[0].content[i];
                }
                if (info_hotel.hotel[0].content[i].section === 103) {
                    sports_animation = info_hotel.hotel[0].content[i];
                }
            }
        }
        return (
            <Modal id={ "summary-hotel-info-modal" } isOpen={ modal_info_hotel } toggle={() => { dispatch(TogleInfoHotelAndSetStep(false, 0, null)); }} size={"lg"}>
            <ModalHeader toggle={() => { dispatch(TogleInfoHotelAndSetStep(false, 0, null)); }} />
                <div>
                    <ModalBody className={"grey-bg"}>
                        {
                            info_hotel !== null && (
                                <div id={"accommodation-bloc-1-3"}>
                                    {
                                        step_info_hotel === 1 && (
                                            <div >
                                                <h1>{ t("footer.about") }</h1>
                                                {
                                                    situation !== null && (
                                                        <div className={"ft-text-justify"} >
                                                           <strong> { situation.title } </strong> : { situation.content }
                                                        </div>
                                                    )
                                                }
                                                {
                                                    (situation !== null && distances !== null) && (
                                                        <hr />
                                                    )
                                                }
                                                {
                                                    distances !== null && (
                                                        <div className={"ft-text-justify"} >
                                                           <strong> { distances.title } </strong> : { distances.content }
                                                        </div>
                                                    )
                                                }
                                                {
                                                    ((situation !== null || distances !== null) && to_know !== null) && (
                                                        <hr />
                                                    )
                                                }
                                                {
                                                    to_know !== null && (
                                                        <div className={"ft-text-justify"} >
                                                           <strong> { to_know.title } </strong> : { to_know.content }
                                                        </div>
                                                    )
                                                }
                                                {
                                                    ((situation !== null || distances !== null || to_know !== null) && other !== null) && (
                                                        <hr />
                                                    )
                                                }
                                                {
                                                    other !== null && (
                                                        <div className={"ft-text-justify"} >
                                                           <strong> { other.title } </strong> : { other.content }
                                                        </div>
                                                    )
                                                }
                                                {
                                                    ((situation !== null || distances !== null || to_know !== null || other !== null))
                                                      && (info_hotel.hotel[0].situation !== undefined && info_hotel.hotel[0].situation !== null) && (
                                                        <hr />
                                                    )
                                                }
                                                {
                                                    (info_hotel.hotel[0].situation !== undefined && info_hotel.hotel[0].situation !== null) && (
                                                        <div className={"ft-text-justify"} >
                                                            <strong> Situation de l'hôtel </strong> : <div dangerouslySetInnerHTML={ {__html: info_hotel.hotel[0].situation } } />
                                                        </div>
                                                    )

                                                }
                                                {
                                                    ((situation !== null || distances !== null || to_know !== null || other !== null
                                                      || (info_hotel.hotel[0].situation !== undefined && info_hotel.hotel[0].situation !== null))
                                                      && (info_hotel.hotel[0].room_desc !== undefined && info_hotel.hotel[0].room_desc !== null)) && (
                                                        <hr />
                                                    )
                                                }
                                                {
                                                    (info_hotel.hotel[0].room_desc !== undefined && info_hotel.hotel[0].room_desc !== null) && (
                                                        <div className={"ft-text-justify"} >
                                                            <strong> Description des chambres </strong> : <div dangerouslySetInnerHTML={ {__html: info_hotel.hotel[0].room_desc } } />
                                                        </div>
                                                    )

                                                }
                                                {
                                                    ((situation !== null || distances !== null || to_know !== null || other !== null
                                                      || (info_hotel.hotel[0].situation !== undefined && info_hotel.hotel[0].situation !== null)
                                                      || (info_hotel.hotel[0].room_desc !== undefined && info_hotel.hotel[0].room_desc !== null))
                                                      && (info_hotel.hotel[0].restoration !== undefined && info_hotel.hotel[0].restoration !== null)) && (
                                                        <hr />
                                                    )
                                                }
                                                {
                                                    (info_hotel.hotel[0].restoration !== undefined && info_hotel.hotel[0].restoration !== null) && (
                                                        <div className={"ft-text-justify"} >
                                                            <strong> Restauration </strong> : <div dangerouslySetInnerHTML={ {__html: info_hotel.hotel[0].restoration } } />
                                                        </div>
                                                    )

                                                }
                                                {
                                                    ((situation !== null || distances !== null || to_know !== null || other !== null
                                                      || (info_hotel.hotel[0].situation !== undefined && info_hotel.hotel[0].situation !== null)
                                                      || (info_hotel.hotel[0].room_desc !== undefined && info_hotel.hotel[0].room_desc !== null)
                                                      || (info_hotel.hotel[0].restoration !== undefined && info_hotel.hotel[0].restoration !== null))
                                                      && (info_hotel.hotel[0].children !== undefined && info_hotel.hotel[0].children !== null)) && (
                                                        <hr />
                                                    )
                                                }
                                                {
                                                    (info_hotel.hotel[0].children !== undefined && info_hotel.hotel[0].children !== null) && (
                                                        <div className={"ft-text-justify"} >
                                                            <strong> Enfants </strong> : <div dangerouslySetInnerHTML={ {__html: info_hotel.hotel[0].children } } />
                                                        </div>
                                                    )

                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        step_info_hotel === 2 && (
                                           <div >
                                                <h1>{ t("summary.rooms_infos") }</h1>
                                                 {
                                                    rooms !== null && (
                                                        <div className={"ft-text-justify"} >
                                                           <strong> { rooms.title } </strong> : { rooms.content }
                                                        </div>
                                                    )
                                                }
                                                {
                                                    rooms !== null && (
                                                        <hr />
                                                    )
                                                }
                                                {
                                                    check_in_out !== null && (
                                                        <div className={"ft-text-justify"} >
                                                           <strong> { check_in_out.title } </strong> : { check_in_out.content }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        step_info_hotel === 6 && (
                                           <div >
                                               {
                                                  (info_hotel !== null && info_hotel.hotel[0] !== null && info_hotel.hotel[0].building.length > 0) && (
                                                       <Fragment>
                                                           <h3>{ t("accommodation.info") } :</h3>
                                                           <ul>
                                                               {
                                                                   info_hotel.hotel[0].building.map((building, index) => {
                                                                       return (
                                                                           <li key={ index }>{t('accommodation.facts.' + building.code)} { building.value_int }</li>
                                                                       );
                                                                   })
                                                               }
                                                           </ul>
                                                       </Fragment>
                                                   )
                                               }
                                               {
                                                  (info_hotel !== null && info_hotel.hotel[0] !== null && info_hotel.hotel[0].facility.length > 0) && (
                                                       <Fragment>
                                                           <h3>{ t("accommodation.equipment_title") } :</h3>
                                                           <ul>
                                                               {
                                                                   info_hotel.hotel[0].facility.map((facility, index) => {
                                                                       return (
                                                                           <li key={ index }><i className={ "icon-" + facility.codename.replace(/facility_|beach_|/g, "").replace(/_/g, "-") }/><span data-toggle={"tooltip"} data-placement={"top"} title={t('accommodation.facts.' + facility.codename)} className={"crop-info-hotel-text"} >{t('accommodation.facts.' + facility.codename)}</span></li>
                                                                       );
                                                                   })
                                                               }
                                                           </ul>
                                                       </Fragment>
                                                   )
                                               }
                                               {
                                                  (info_hotel !== null && info_hotel.hotel[0] !== null && info_hotel.hotel[0].room.length > 0) && (
                                                       <Fragment>
                                                           <h3>{ t("accommodation.rooms") } :</h3>
                                                           <ul>
                                                               {
                                                                   info_hotel.hotel[0].room.map((room, index) => {
                                                                       return (
                                                                           <li key={ index }><i className={ "icon-" + room.codename.replace("room_", "").replace(/_/g, "-") }/><span data-toggle={"tooltip"} data-placement={"top"} title={t('accommodation.facts.' + room.codename)} className={"crop-info-hotel-text"}>{t('accommodation.facts.' + room.codename)}</span></li>
                                                                       );
                                                                   })
                                                               }
                                                           </ul>
                                                       </Fragment>
                                                   )
                                               }
                                               {
                                                  (info_hotel !== null && info_hotel.hotel[0] !== null && info_hotel.hotel[0].meals.length > 0) && (
                                                       <Fragment>
                                                           <h3>{ t("accommodation.meal") } :</h3>
                                                           <ul>
                                                               {
                                                                   info_hotel.hotel[0].meals.map((meals, index) => {
                                                                       return (
                                                                           <li key={ index }><i className={ "icon-" + meals.codename.replace(/_/g, "-").replace(/_/g, "-") }/><span data-toggle={"tooltip"} data-placement={"top"} title={t('accommodation.facts.' + meals.codename)} className={"crop-info-hotel-text"}>{t('accommodation.facts.' + meals.codename)}</span></li>
                                                                       );
                                                                   })
                                                               }
                                                           </ul>
                                                       </Fragment>
                                                   )
                                               }
                                               {
                                                  (info_hotel !== null && info_hotel.hotel[0] !== null && info_hotel.hotel[0].sports.length > 0) && (
                                                       <Fragment>
                                                           <h3>{ t("accommodation.sport") } :</h3>
                                                           <ul>
                                                               {
                                                                   info_hotel.hotel[0].sports.map((sports, index) => {
                                                                       return (
                                                                           <li key={ index }><i className={ "icon-" + sports.codename.replace(/_/g, "-") }/><span data-toggle={"tooltip"} data-placement={"top"} title={t('accommodation.facts.' + sports.codename)} className={"crop-info-hotel-text"}>{t('accommodation.facts.' + sports.codename)}</span></li>
                                                                       );
                                                                   })
                                                               }
                                                           </ul>
                                                       </Fragment>
                                                   )
                                               }
                                               {
                                                  (info_hotel !== null && info_hotel.hotel[0] !== null && info_hotel.hotel[0].dist.length > 0) && (
                                                       <Fragment>
                                                           <h3>{ t("accommodation.distance") } :</h3>
                                                           <ul>
                                                               {
                                                                   info_hotel.hotel[0].dist.map((dist, index) => {
                                                                       if (dist.value_int > 0) {
                                                                           return (
                                                                               <li key={ index }>{t('accommodation.facts.' + dist.codename)} { dist.value_int }m</li>
                                                                           );
                                                                       }
                                                                   })
                                                               }
                                                           </ul>
                                                       </Fragment>
                                                   )
                                               }
                                               {
                                                  (info_hotel !== null && info_hotel.hotel[0] !== null && info_hotel.hotel[0].payment.length > 0) && (
                                                       <Fragment>
                                                           <h3>{ t("accommodation.payment") } :</h3>
                                                           <ul className={ "credit-card-list" }>
                                                               {
                                                                   info_hotel.hotel[0].payment.map((payment, index) => {
                                                                       return (
                                                                           <li key={ index }><div style={{display: "inline-block",width: "50px", height: "45px",backgroundSize: "cover",backgroundImage: "url('/Img/payment_icon/" + payment.codename.replace(/payment_/g, "") + ".png')"}}/><span>{t('accommodation.facts.' + payment.codename)}</span></li>
                                                                       );
                                                                   })
                                                               }
                                                           </ul>
                                                       </Fragment>
                                                   )
                                               }
                                            </div>
                                        )
                                    }
                                    {
                                        step_info_hotel === 3 && (
                                            <div >
                                                <h1>{ t("summary.activity_services") }</h1>
                                                 {
                                                    equipements !== null && (
                                                        <div className={"ft-text-justify"} >
                                                           <strong> { equipements.title } </strong> : { equipements.content }
                                                        </div>
                                                    )
                                                }
                                                {
                                                    equipements !== null && (
                                                        <hr />
                                                    )
                                                }
                                                {
                                                    sports_animation !== null && (
                                                        <div className={"ft-text-justify"} >
                                                           <strong> { sports_animation.title } </strong> : { sports_animation.content }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        step_info_hotel === 4 && (
                                            <div >
                                                <h1>{ t("summary.restauration") }</h1>
                                                 {
                                                    restauration !== null && (
                                                        <div className={"ft-text-justify"} >
                                                           <strong> { restauration.title } </strong> : { restauration.content }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        step_info_hotel === 5 && (
                                           <div >
                                                <h1>{ t("summary.google_map") }</h1>
                                                <div className={ "hotel-info-map" } id={ "hotel-info-map-" + info_hotel.id }/>
                                           </div>
                                        )
                                    }
                                </div>
                            )
                        }
                    </ModalBody>
                </div>
            </Modal>
        );
    }
}

export default HotelInfoModal;
