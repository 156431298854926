//---- Dependencies ----//
import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router';
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import swal from 'sweetalert';

//---- Common ----//
import Notify from "../../Common/Notify.js";
//---- CSS ----//
import "../../../Style/FlightSearch.css";
import "../../../Style/CarsSearch.css";

//---- Components ----//
import CustomInputIcon from "../../DatePicker/CustomInputIcon.jsx";
import CarsLocationList from "./CarsLocationList.jsx"
import CustomOverlay from "../../Common/CustomOverlay.jsx";

//---- Functions ----//
import SearchLocationPoint from "../Functions/SearchLocationPoint";

//---- Actions ----//
import { SetCarsNumber, SetDriverAge, SetInputFocus, SetHours, SetMinutes, ChangeFocus, SetCopyCarIndex, ResetCurrentCart,
			SetOriginDate, SetReturnDate, SetCopyIndexLocation, SetCarsErrors, RemoveCarsError, ShowInput, SetAgency, SetReadOnly, CheckAvailabilityStatus } from "../../../Actions/CarsSearch";

@connect((store) => {
    return {
    	step_info: store.cars_search.step_info,
    	input_focus: store.cars_search.input_focus,
    	location_point: store.cars_search.location_point,
    	show_input: store.cars_search.show_input,
    	car_cart: store.cars_search.cart,
    }
})
@withTranslation()
@withRouter

export default class CarsInput extends React.Component {
	constructor(props, context) {
	    super(props, context);
        this.setInputRef = this.setInputRef.bind(this);
        this.getInputRef = this.getInputRef.bind(this);
    }
    componentWillMount() {
    	const { dispatch } = this.props;
    	dispatch(ResetCurrentCart());
    	this.checkCart();
    }
    componentDidUpdate(prevProps) {
    	const { step_info, dispatch, t } = this.props;
    	if (prevProps.step_info !== step_info) {
    		for (let i = 0; i < step_info.length; i++) {
    			this.inputNodes[i * 2].value = step_info[i].agency_go === null ? (step_info[i].readOnly_departure ? t("cars.click_to_choose") + step_info[i].origin : t("cars.type_to_choose"))  : ((step_info[i].agency_go.international_name).toLowerCase());
    			if (this.inputNodes[(i * 2) + 1])
	    			this.inputNodes[(i * 2) + 1].value = step_info[i].agency_return === null ? (step_info[i].readOnly_arrival ? t("cars.click_to_choose") + step_info[i].destination : t("cars.type_to_choose")) : ((step_info[i].agency_return.international_name).toLowerCase());
	    		prevProps.step_info.map((prev_car) => {
	    			if (moment.utc(prev_car.origin_date ? prev_car.origin_date : prev_car.start_date_origin).format("DD/MM/YYYY") !== moment.utc(step_info[i].origin_date ? step_info[i].origin_date : step_info[i].start_date_origin).format("DD/MM/YYYY") || moment.utc(prev_car.return_date ? prev_car.return_date : prev_car.end_date_dest).format("DD/MM/YYYY") !== moment.utc(step_info[i].return_date ? step_info[i].return_date : step_info[i].end_date_dest).format("DD/MM/YYYY")) {
	    				this.checkCart();
	    			}
	    		})
    		}
    		dispatch(SetInputFocus(null));
    	}
    }
    checkCart() {
    	const { car_cart, step_info, dispatch, t } = this.props;
    	car_cart.map((car) => {
    		step_info.map((step) => {
    			if ((car.booking_status === null || car.booking_status.status_booking !== "CANCELLED") && (moment.utc(car.start_date).format("DD/MM/YYYY") === moment.utc(step.origin_date ? step.origin_date : step.start_date_origin).format("DD/MM/YYYY") || moment.utc(car.end_date).format("DD/MM/YYYY") === moment.utc(step.return_date ? step.return_date : step.end_date_dest).format("DD/MM/YYYY"))) {
    				swal(t("cars.already_in_cart") + ". " + t("global.from") + " " + moment.utc(car.start_date).format("DD/MM/YYYY") + " " + t("global.to") + " " + moment.utc(car.end_date).format("DD/MM/YYYY") , {
    				    icon: "warning",
    				    className: "car-check",
    				});
    			}
    		})
    	})
    }
	setInputRef(node) {
	    if (node !== null) {
	        this.inputNodes.push(node);
	    }
	}
	getInputRef(index) {
	    return (this.inputNodes[index]);
	}
	changeFocus(focus) {
		const { step_info, input_focus, dispatch, t } = this.props;
		let index = parseInt(focus / 2);
		let input = focus % 2;
		let destination = input === 0 ? step_info[index].origin : (step_info[index].destination ? step_info[index].destination : step_info[index].origin);
		this.inputNodes[focus].value = "";
		let is_readOnly = input === 0 ? step_info[index].readOnly_departure : step_info[index].readOnly_arrival;
		let iata_airports = input === 0 ? step_info[index].iata_airports_departure : step_info[index].iata_airports_arrival;
		let destination_id = input === 0 ? step_info[index].origin_id : step_info[index].dest_id;
		if (is_readOnly) {
			SearchLocationPoint(destination, iata_airports, destination_id, dispatch, t, true);
		}
		dispatch(SetInputFocus(focus));
	}
	checkInput(focus) {
		const { step_info, dispatch, t } = this.props;
		if (this.inputNodes[focus].value === "")
		{
			let index = parseInt(focus / 2);
			let input = focus % 2;
			this.inputNodes[focus].value = input === 0 ? (step_info[index].agency_go ? ((step_info[index].agency_go.international_name).toLowerCase()) : "") : (step_info[index].agency_return ? ((step_info[index].agency_return.international_name).toLowerCase()) : "");
		}
	}
	printDriverAge(i) {
		const { step_info, dispatch, t } = this.props;
		let cars_errors = step_info[i].cars_errors;
		let arr = [];
		for (let x = 1; x < (parseInt(step_info[i].car_nbr)); x++) {
			arr.push(
				<div className={ x === 1 ? "col-md-2 offset-md-1" : "col-md-2"} key={x}>
					<label className={ "row" }>
						<span className={ "col-md-12 font-13" }>{t("cars.driver_age_multi") + (x + 1)}</span>
						<input className={ "col-md-4 offset-md-2 ft-basic-input" + (cars_errors.includes("age_" + x) ? " error" : "") } type={ "number" } min={ "25" } value={ parseInt(step_info[i].driver_age[x]) } name={ "driver_age" } onChange={(e) => dispatch(SetDriverAge(e.target.value, i, x))} style={{minWidth:"75px"}}/>
					</label>
				</div>
			)
		}
		return arr;
	}
	addCar(car_nbr, index) {
		const { step_info, dispatch } = this.props;
		let add = false;
		if (step_info[index].car_nbr < car_nbr)
			add = true;
		dispatch(SetCarsNumber(car_nbr, index, add));
	}
	searchCity(destination, iata_airports, destination_id, dispatch, t) {
		// let delayTimer;
		// clearTimeout(delayTimer);
		// delayTimer = setTimeout(function() {
			SearchLocationPoint(destination, iata_airports, destination_id, dispatch, t, false);
		// }, 2000);
	}
	checkDestination() {
		const { step_info, show_input, router, dispatch, t } = this.props;
		let checked = true;
		step_info.map((step, step_index) => {
			const cars_errors = step.cars_errors;
			if (step.agency_go === null) {
				Notify(t("cars.error_location_pickup"), {status: 'danger', pos: 'top-center'});
				if (!cars_errors.includes("input_" + (step_index * 2)))
				    dispatch(SetCarsErrors("input_" + (step_index * 2), step_index));
				checked = false;
			}
			else {
				if (cars_errors.includes("input_" + (step_index * 2))) {
				    dispatch(RemoveCarsError(step_index, "input_" + (step_index * 2)));
				}
			}
			if (!show_input && step.agency_return === null) {
				Notify(t("cars.error_location_return"), {status: 'danger', pos: 'top-center'});
				if (!cars_errors.includes("input_" + ((step_index * 2) + 1) ))
				    dispatch(SetCarsErrors("input_" + ((step_index * 2) + 1), step_index));
				checked = false;
			}
			else {
				if (cars_errors.includes("input_" + ((step_index * 2) + 1))) {
					dispatch(RemoveCarsError(step_index, "input_" + ((step_index * 2) + 1)));
			    }
			}
			step.driver_age.map((age, index) => {
				if (age < 25) {
					Notify(t("cars.error_age_return"), {status: 'danger', pos: 'top-center'});
					if (!cars_errors.includes("age_" + index ))
					    dispatch(SetCarsErrors("age_" + index, step_index));
					checked = false;
				}
				else {
					if (cars_errors.includes("age_" + index)) {
						dispatch(RemoveCarsError(step_index, "age_" + index));
				    }
				}
			})
		});
		if (step_info.length > 0 && checked) {
			dispatch(SetCopyIndexLocation(0));
			dispatch(SetCopyCarIndex(0));
			dispatch(CheckAvailabilityStatus(null));
			let id_step = this.props.current === "oneStep" ? 0 : 1;
			router.push(`/${window.url_name}/apps/cars-results/${id_step}`);
		}
	}
	printInfo() {
		const { step_info, input_focus, show_input, dispatch, t } = this.props;
		let arr = [];
		for (let i = 0; i < step_info.length; i++) {
			let start_date_origin  = step_info[i].start_date_origin;
			let end_date_origin  = step_info[i].end_date_origin ? step_info[i].end_date_origin : step_info[i].end_date_dest;
			let start_date_dest = step_info[i].start_date_dest ? step_info[i].start_date_dest :  step_info[i].start_date_origin;
			let end_date_dest = step_info[i].end_date_dest ;
			let origin_date = step_info[i].origin_date;
			let return_date = step_info[i].return_date;
			let cars_errors = step_info[i].cars_errors;
			let readOnly_departure = step_info[i].readOnly_departure;
			let readOnly_arrival = step_info[i].readOnly_arrival;
			arr.push (
				<div key={i} className={ "ft-basic-card mt20 mb20" }>
					<div className={"text-center mb15"}>
						<div className="row">
							<div className="col-md-12 ft-font-30 bold">
								{t("cars.rent") + (i + 1)}
							</div>
							<div className="col-md-12 ft-font-25 cars-destination">
								<span>
									{step_info[i].origin}
								</span>
								{
									step_info[i].destination && (
										<span>
											<i className={ "icon-103-long-arrow-pointing-to-the-right ft-mr10 ft-ml10" }></i>
											<span>
												{step_info[i].destination}
											</span>
										</span>
									)
								}
							</div>
						</div>
					</div>
					{
						!step_info[i].destination && (
							<div className="row">
								<div className="col-md-11 offset-md-1 show-input">
									<label className={ "flight-search-checkbox" }>{t("cars.different_location")}  
									    <input type={ "checkbox" } checked={ show_input } onChange={() => {dispatch(ShowInput(!show_input))} }/>
									    <span className={ "flight-search-check-mark-cars" }/>
									</label>
								</div>
							</div>
						)
					}
					<div className={"row"} >
						<div className={ "step-origin col-md-4 offset-md-1" }>
							<div className={ "col-md-12 row" }>
			                	<label htmlFor={ "input-" + (i * 2) } className={ "ft-mb0 col-md-12 no-padding" }>{t("cars.place_origin")}</label>
		                		<input readOnly={readOnly_departure} id={ "input-" + (i * 2) } className={ "col-md-12 ft-basic-input capitalize-first-letter" + (cars_errors.includes("input_" + (i * 2)) ? " error" : "") + (!step_info[i].readOnly_departure ? " read-only" : "") } ref={ (node) => { this.setInputRef(node); } }  type={ "text" } onBlur={() => {this.checkInput(i * 2)}} onFocus={() => { this.changeFocus(i * 2)}} placeholder={(step_info[i].readOnly_departure ? t("cars.click_to_choose") + step_info[i].origin : t("cars.type_to_choose")) } defaultValue={ step_info[i].agency_go === null ? "" : ((step_info[i].agency_go.international_name).toLowerCase()  ) } onChange={(e) => !step_info[i].readOnly_departure ? this.searchCity(e.target.value, step_info[i].iata_airports_departure, step_info[i].origin_id, dispatch, t) : ""}/>
			                	{
			                		input_focus === (i * 2) && (
			                			<CarsLocationList index={i} type={"origin"} getInputRef={ this.getInputRef } index_ref={ (i * 2)} return_dest={step_info[i].destination ? true : false}/>
			                		)
			                	}
							</div>
							<div className="col-md-12 ft-mt10 row ft-relative pointer">
								<img src="/Img/Cars/edit.svg" className={"ft-height-15 cars-edit-icon"}/>
								{
									step_info[i].readOnly_departure && (
										<span className={ "ft-ml20" } onClick={() => {dispatch(SetReadOnly(false, "departure", i))}}>
											{t("cars.edit_dest_dep")}
										</span>
									)
								}
								{
									!step_info[i].readOnly_departure && (
										<span className={ "ft-ml20" } onClick={() => {dispatch(SetReadOnly(true, "departure", i))}}>
											{t("cars.default_dest_tooltip")}
										</span>
									)
								}
							</div>
						</div>
						{
							!show_input && (
								<div className={ "step-return col-md-4" }>
									<div className={ "col-md-12 row" }>
										<label htmlFor={ "input-" + ((i * 2) + 1) } className={ "ft-mb0 col-md-12 no-padding" }> {t("cars.place_return")}</label>
					                	<input id={ "input-" + ((i * 2) + 1) } readOnly={readOnly_arrival} className={ "col-md-12 ft-basic-input capitalize-first-letter" + (cars_errors.includes("input_" + ((i * 2) + 1)) ? " error" : "") + (!step_info[i].readOnly_arrival ? " read-only" : "") } ref={ (node) => { this.setInputRef(node); } }  type={ "text" } onBlur={() => {this.checkInput((i * 2) + 1)}} onFocus={() => { this.changeFocus((i * 2) + 1)}} placeholder={(step_info[i].readOnly_arrival ? t("cars.click_to_choose") + (!step_info[i].destination ? step_info[i].origin : step_info[i].destination) : t("cars.type_to_choose")) } defaultValue={ step_info[i].agency_return === null ? "" : ((step_info[i].agency_return.international_name).toLowerCase())} onChange={(e) => !step_info[i].readOnly_arrival ? this.searchCity(e.target.value, step_info[i].iata_airports_arrival, step_info[i].dest_id, dispatch, t) : ""}/>
					                	{
					                		input_focus === ((i * 2) + 1) && (
					                			<CarsLocationList index={i} type={ "return" } getInputRef={ this.getInputRef } index_ref={ ((i * 2) + 1)}/>
					                		)
					                	}
									</div>
									<div className="col-md-12 ft-mt10 row ft-relative pointer">
										<img src="/Img/Cars/edit.svg" className={"ft-mr5 ft-height-15 cars-edit-icon"}/>
										{
											step_info[i].readOnly_arrival && (
												<span className={ "ft-ml20" } onClick={() => {dispatch(SetReadOnly(false, "arrival", i))}}>
													{t("cars.edit_dest_arr")}
												</span>
											)
										}
										{
											!step_info[i].readOnly_arrival && (
												<span className={ "ft-ml20" } onClick={() => {dispatch(SetReadOnly(true, "arrival", i))}}>
													{t("cars.default_dest_tooltip")}
												</span>
											)
										}
									</div>
								</div>
							)
						}
						<div className={ "cars-nbr col-md-2" }>
							<label> <span className={ "col-md-12 no-padding" }>{t("cars.cars_nbr")}</span>
								<select className={ "col-md-5 offset-md-2 ft-basic-selector" } name="car_nbr" value={ step_info[i].car_nbr} onChange={(e) => { this.addCar(e.target.value, i) }}>
									<option value={"1"}>1</option>
									<option value={"2"}>2</option>
									<option value={"3"}>3</option>
									<option value={"4"}>4</option>
									<option value={"5"}>5</option>
								</select>
							</label>
						</div>
					</div>
					<div className={ "row mt20" }>
	        			<div id={ "cars-date-picker" } className={ "col-md-2 offset-md-1 ft-relative" }>
	        				<span className={ "sub-title" }>Date et heure de départ</span>
			        		<SingleDatePicker
				        		initialVisibleMonth={ () => {
				        			return (origin_date == null ? moment.utc(start_date_origin) : moment.utc(origin_date));
				        		}}
								readOnly
			        		    placeholder={ t("home.modal.start_date_placeholder") }
			        		    id={ "home_start_date" }
			        		    date={ (origin_date == null ? moment.utc(start_date_origin) : moment.utc(origin_date)) }
			        		    noBorder
			        		    onDateChange={(date) => { dispatch(SetOriginDate(date, i)); }}
			        		    focused={ step_info[i].focus.origin }
			        		    onFocusChange={() => { dispatch(ChangeFocus(!step_info[i].focus.origin, i, "origin"))}}
			        		    isOutsideRange={day => (day.isBefore(moment.utc(start_date_origin), "d") || (day.isAfter(moment.utc(end_date_origin), "d")))}
			        		    isDayHighlighted={day => ((day.isAfter(moment.utc(start_date_origin), "d") && day.isBefore(moment.utc(end_date_origin), "d")) || (day.isSame(moment.utc(start_date_origin), "d")) || (day.isSame(moment.utc(end_date_origin), "d")) )}
			        		    numberOfMonths={ 1 }
			        		    hideKeyboardShortcutsPanel
			        		    showClearDate
			        		    reopenPickerOnClearDate
			        		    customInputIcon={<CustomInputIcon custom_icon={ true }/>}
			        		/>
		        		</div>
						<div className={ "col-md-1 origin-hour" }>
							<label className={ "row" }>
								<span className={ "col-md-12" }>{t("global.hours")}</span>
								<select className={ "col-md-9 offset-md-2 ft-basic-selector" } name="origin_hours" defaultValue={step_info[i].origin_hours } onChange={ (e) => {dispatch(SetHours(e.target.value, i, "origin"))} }>
									<option value={"00"}>00</option>
									<option value={"01"}>01</option>
									<option value={"02"}>02</option>
									<option value={"03"}>03</option>
									<option value={"04"}>04</option>
									<option value={"05"}>05</option>
									<option value={"06"}>06</option>
									<option value={"07"}>07</option>
									<option value={"08"}>08</option>
									<option value={"09"}>09</option>
									<option value={"10"}>10</option>
									<option value={"11"}>11</option>
									<option value={"12"}>12</option>
									<option value={"13"}>13</option>
									<option value={"14"}>14</option>
									<option value={"15"}>15</option>
									<option value={"16"}>16</option>
									<option value={"17"}>17</option>
									<option value={"18"}>18</option>
									<option value={"19"}>19</option>
									<option value={"20"}>20</option>
									<option value={"21"}>21</option>
									<option value={"22"}>22</option>
									<option value={"23"}>23</option>
								</select>
							</label>
						</div>
						<div className={ "col-md-1 origin-minutes" }>
							<label className={ "row" }>
								<span className={ "col-md-12" }>{t("global.minutes")}</span>
								<input className={ "col-md-9 offset-md-2 ft-basic-input" } type={ "number" } min={ "0" } max={ "59" } value={ step_info[i].origin_minutes } name={ "origin_minutes" } onChange={(e) => dispatch(SetMinutes(e.target.value, i, "origin"))}/>
							</label>
						</div>
						<div id={ "cars-date-picker" } className={ "col-md-2 ft-relative" }>
							<span className={ "sub-title" }>Date et heure de retour</span>
			        		<SingleDatePicker
				        		initialVisibleMonth={ () => {
				        			return (return_date === null ? moment.utc(end_date_dest) : moment.utc(return_date));
				        		}}
			        		    placeholder={ t("home.modal.return_date_placeholder") }
			        		    id={ "home_end_date" }
			        		    date={ (return_date === null ? moment.utc(end_date_dest) : moment.utc(return_date)) }
			        		    noBorder
								readOnly
			        		    onDateChange={(date) => { dispatch(SetReturnDate(date, i)); }}
			        		    focused={ step_info[i].focus.return }
			        		    onFocusChange={() => { dispatch(ChangeFocus(!step_info[i].focus.return, i, "return"))} }
			        		    numberOfMonths={ 1 }
			        		    isDayHighlighted={day => ((day.isAfter(moment.utc(start_date_dest), "d") && day.isBefore(moment.utc(end_date_dest), "d")) || (day.isSame(moment.utc(start_date_dest), "d")) || (day.isSame(moment.utc(end_date_dest), "d")) )}
			        		    isOutsideRange={day => (day.isBefore(moment.utc(start_date_dest), "d") || (day.isAfter(moment.utc(end_date_dest), "d")))}
			        		    hideKeyboardShortcutsPanel
			        		    showClearDate
			        		    reopenPickerOnClearDate
			        		    customInputIcon={<CustomInputIcon custom_icon={ true }/>}
			        		/>
		        		</div>
						<div className={ "col-md-1 return-hour" }>
							<label className={ "row" }>
								<span className={ "col-md-12" }>{t("global.hours")}</span>
								<select className={ "col-md-9 offset-md-2 ft-basic-selector" } name="return_hours" defaultValue={step_info[i].return_hours} onChange={ (e) => {dispatch(SetHours(e.target.value, i, "return"))} }>
									<option value={"00"}>00</option>
									<option value={"01"}>01</option>
									<option value={"02"}>02</option>
									<option value={"03"}>03</option>
									<option value={"04"}>04</option>
									<option value={"05"}>05</option>
									<option value={"06"}>06</option>
									<option value={"07"}>07</option>
									<option value={"08"}>08</option>
									<option value={"09"}>09</option>
									<option value={"10"}>10</option>
									<option value={"11"}>11</option>
									<option value={"12"}>12</option>
									<option value={"13"}>13</option>
									<option value={"14"}>14</option>
									<option value={"15"}>15</option>
									<option value={"16"}>16</option>
									<option value={"17"}>17</option>
									<option value={"18"}>18</option>
									<option value={"19"}>19</option>
									<option value={"20"}>20</option>
									<option value={"21"}>21</option>
									<option value={"22"}>22</option>
									<option value={"23"}>23</option>
								</select>
							</label>
						</div>
						<div className={ "col-md-1 return-minutes" }>
							<label className={ "row" }> 
								<span className={ "col-md-12" }>{t("global.minutes")}</span>
								<input className={ "col-md-9 offset-md-2 ft-basic-input" } type={ "number" } min={ "00" } max={ "59" } value={ step_info[i].return_minutes } name={ "return_minutes" } onChange={(e) => dispatch(SetMinutes(e.target.value, i, "return"))}/>
							</label>
						</div>
						<div className={ "driver-age col-md-2" }>
							<label className={ "row" }>
								<span className={ "col-md-12" + (parseInt(step_info[i].car_nbr) > 1 ? " font-13" : "") }>{ parseInt(step_info[i].car_nbr) > 1 ? t("cars.driver_age_multi") + 1 : t("cars.driver_age")}</span>
								<input className={ "col-md-4 offset-md-2 ft-basic-input"  + (cars_errors.includes("age_0") ? " error" : "") } type={ "number" } min={ "25" } value={ step_info[i].driver_age[0] } name={ "driver_age" } onChange={(e) => dispatch(SetDriverAge(e.target.value, i, 0))} style={{minWidth:"75px"}}/>
							</label>
						</div>
					</div>
					{
						parseInt(step_info[i].car_nbr) > 1 && (
							<div className={ "row" }>
								{this.printDriverAge(i)}
							</div>
						)
					}
				</div>
			)
		}
		return arr;
	}
	render() {
		const { step_info, dispatch, t } = this.props;
		this.inputNodes = [];
		return (
			<div>
			{
				step_info.length > 0 && (
					<div>
						{this.printInfo()}
						<button className="btn-orange uppercase ft-white mt25 block-center" onClick={() => {this.checkDestination()}}>
							{t("global.search")}
						</button>
					</div>
				)
			}
			</div>
		);
	}
}