import { flatten } from "lodash";
import { TFunction } from "i18next";
import { useCartProducts } from "../../../Itinerary/network/cartProducts";

export function transformTrainsToProgramDetailsItems(
    options: {
        locale: number | null,
        destinationId: number | null,
        cart: ReturnType<typeof useCartProducts>,
        startDate: string,
        endDate: string,
        t: TFunction
    }
) {
    const trains = options.cart.trains.filter((item) => {
        return (
            window.moment.utc(item.train.start_date).isBetween(
                window.moment.utc(options.startDate),
                window.moment.utc(options.endDate)
            ) ||
            window.moment.utc(item.train.end_date).isBetween(
                window.moment.utc(options.startDate),
                window.moment.utc(options.endDate)
            )
        ) && (
            item.type === 'manual' ||
            item.train.start_destination?.id === options.destinationId ||
            item.train.end_destination?.id === options.destinationId
        ) &&
        !item.train.is_optional;
    });
    return flatten(
        trains.map((item) => {
            const result = [
                (
                    window.moment.utc(item.train.start_date).isBetween(
                        window.moment.utc(options.startDate),
                        window.moment.utc(options.endDate)
                    )
                ) && (
                    item.type === 'manual' ||
                    item.train.start_destination?.id === options.destinationId
                ) ?
                    {
                        dateOrder: item.train.start_date,
                        date: window.moment.utc(item.train.start_date).format('DD/MM'),
                        ref: item.train.booking_status?.item_reference,
                        title: options.t(
                            'roadbook.program-details-train-pickup-title',
                            {
                                hourly: window.moment.utc(item.train.start_date).format('HH[h]mm'),
                                name: item.train.localization?.find((item) => {
                                    return item.locale === options.locale;
                                })?.name ?? item.train.name
                            }
                        ),
                        note: item.train.custom_information?.replace(/\n/g, '<br />') ?? undefined
                    } :
                    null,
                (
                    window.moment.utc(item.train.end_date).isBetween(
                        window.moment.utc(options.startDate),
                        window.moment.utc(options.endDate)
                    )
                ) && (
                    item.type === 'manual' ||
                    item.train.end_destination?.id === options.destinationId
                ) ?
                    {
                        dateOrder: item.train.end_date,
                        date: window.moment.utc(item.train.end_date).format('DD/MM'),
                        ref: item.train.booking_status?.item_reference,
                        title: options.t(
                            'roadbook.program-details-train-return-title',
                            {
                                hourly: window.moment.utc(item.train.end_date).format('HH[h]mm'),
                                name: item.train.localization?.find((item) => {
                                    return item.locale === options.locale;
                                })?.name ?? item.train.name
                            }
                        ),
                        note: item.train.custom_information?.replace(/\n/g, '<br />') ?? undefined
                    } :
                    null
            ];
            //eslint-disable-next-line max-nested-callbacks
            return result.filter((item): item is NonNullable<typeof result[0]> => {
                return !!item;
            });
        })
    );
}
