import React, { Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import DialogContent from "@material-ui/core/DialogContent";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

const AccommodationTaxesInfo = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const open = useSelector(state => state.accommodation.taxes_info);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const onClose = () => {
        dispatch({type: 'MATERIAL_TOGGLE_TAXES_INFO'});
    };
    return (
        <Dialog fullScreen={ fullScreen } PaperProps={{style: { borderRadius: fullScreen ? 0 : 16 } }} open={ open } fullWidth maxWidth={ "md" } onClose={ onClose } disableEscapeKeyDown>
            <DialogTitle onClose={ onClose }>
                <Grid container justify={ "space-between" } alignItems={ "center" }>
                    {
                        fullScreen ? (
                            <Grid item>
                                <IconButton edge={ "start" } onClick={ onClose }><NavigateBefore/></IconButton> { t("accommodation.taxes_information") }
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item>
                                    { t("accommodation.taxes_information") } :
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={ onClose }><Close/></IconButton>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Typography>This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) pays for their taxes, and/or taxes that we pay, to taxing authorities on your booking (including but not limited to sales, occupancy, and value added tax). This amount may also include any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, the hotel supplier and/or the website you booked on, retain as part of the compensation for our and/or their services, which varies based on factors such as location, the amount, and how you booked. For more details, please see the Terms and Conditions.</Typography>
                <br/>
            </DialogContent>
        </Dialog>
    );
};

export default React.memo(AccommodationTaxesInfo);