import React, { useContext } from "react";
import {
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    tableCellClasses
} from "@mui/material";
import { CartConstructionProductsContext } from "./utils/cartConstructionProductsContext";

type Props = {
    sections: {
        title?: React.ReactNode,
        action?: React.ReactNode,
        content: React.ReactNode
    }[],
    indent?: boolean,
    singleCell?: boolean
}

export function CartConstructionProductsTable(props: Props): JSX.Element {
    const context = useContext(CartConstructionProductsContext);
    return (
        <TableContainer>
            <Table
                size="small"
                sx={{
                    "tableLayout": 'fixed',
                    [`.${tableCellClasses.body} .ellipsis`]: {
                        "display": '-webkit-box',
                        '-webkit-line-clamp': '1',
                        '-webkit-box-orient': 'vertical',
                        "overflow": 'hidden',
                        "textOverflow": "ellipsis"
                    },
                    '& td': {
                        textAlign: 'left',
                        paddingRight: 0
                    },
                    '& td:nth-child(4)': {
                        fontWeight: 'bold'
                    }
                }}
                width="100%"
            >
                <colgroup>
                    {
                        !props.singleCell &&
                        <>
                            <Box
                                component="col"
                                sx={{
                                    width: props.indent ?
                                        46 + 64 :
                                        46
                                }}
                            />
                            {
                                context.enableBooking &&
                                <Box component="col" sx={{ width: 46 }} />
                            }
                            <Box component="col" sx={{ width: '17.5%' }} />
                            <Box component="col" sx={{ width: '15%' }} />
                            <Box component="col" sx={{ width: '20%' }} />
                            <Box component="col" sx={{ width: '10%' }} />
                            <Box component="col" sx={{ width: '10%' }} />
                            <Box component="col" sx={{ width: '12.5%' }} />
                            <Box component="col" sx={{ width: '10%' }} />
                            <Box component="col" sx={{ width: '5%' }} />
                        </>
                    }
                    {
                        props.singleCell &&
                        <col />
                    }
                </colgroup>
                <TableBody>
                    {
                        props.sections.map((section, index) => (
                            <React.Fragment key={index}>
                                {
                                    section.title &&
                                    <TableRow sx={{ backgroundColor: '#eee' }}>
                                        <TableCell colSpan={20}>
                                            <Stack direction="row" justifyContent="space-between">
                                                <Typography variant="h6" fontWeight="bold" component="div">
                                                    {section.title}
                                                </Typography>
                                                {section.action}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                }
                                {section.content}
                            </React.Fragment>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
