import { useEffect, useState } from 'react';
import CheckBeforeRequest from '../../Common/CheckBeforeRequest';
import axios from 'axios';
import GetCookie from '../../Common/Functions/GetCookie';
import { useDispatch } from 'react-redux';

const AccommodationLoadAdditionalProducts = ({ provider_loaded, start_date, end_date, destination, id, current_group }) => {
    // const provider_loaded = useState((state) => state.accommodation.provider_loaded);
    const dispatch = useDispatch();
    const getAdditionalProducts = async () => {
        const { headers } = CheckBeforeRequest();
        const response = await axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/additional-product/product_from_hotel/`,
            params: {
                comps: current_group.group.id,
                arrival_date: start_date,
                departure_date: end_date,
                custom_provider: provider_loaded.custom,
                hotels_identifiers: id,
                provider_id: provider_loaded.id,
                trip: GetCookie("trip_id"),
                start_destination: destination.destination.id
            }
        });
        dispatch({type: 'ACCOMMODATION_SET_ADDITIONAL_PRODUCTS', payload: response.data});
    };
    useEffect(() => {
        if (provider_loaded !== null && current_group !== null) {
            if (["tahitinui", "beachcomber"].includes(provider_loaded.code)) {
                console.log("provider_loaded : ", provider_loaded);
                getAdditionalProducts();
            } else {
                dispatch({type: 'ACCOMMODATION_SET_ADDITIONAL_PRODUCTS', payload: []});
            }
        }
    }, [provider_loaded, current_group]);
    return null;
};

export default AccommodationLoadAdditionalProducts;