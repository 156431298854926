//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import SummaryPoiCard from './SummaryPoiCard';
import SummaryPoiCardManual from './SummaryPoiCardManual';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    bold: {
        fontWeight: "bold"
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    hoverContainer: {
        "&:hover": {
            borderLeft: '6px solid #E6592F'
        }
    },
    borderContainer: {
        borderTop: "1px solid #C4C4C4",
        borderBottom: "1px solid #C4C4C4",
        paddingBottom: "0px !important"
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 50
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
    paddingLeft0: {
        paddingLeft: 0
    }
}));

const RenderSummaryPoi = ({poi_cart, manual_cart, step, selectedProducts, setSelectedProducts, step_index, from, byDay}) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();

    const trip_info = useSelector(state => state.summary.trip_info);

    const [mainPoi, setMainPoi] = useState([]);
    const [optionPoi, setOptionPoi] = useState([]);
    useEffect(() => {
        if (poi_cart.length > 0) {
            let main_poi = [];
            let optional_poi = [];
            poi_cart.map((poi) => {
                if (poi.booking_status !== undefined && ((poi.booking_status !== null && poi.booking_status.status_booking !== "CANCELLED") || poi.booking_status === null) && moment.utc(poi.start_date, "YYYY-MM-DD").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD")) && moment.utc(poi.end_date, "YYYY-MM-DD").isSameOrBefore(moment.utc(step.end_date, "YYYY-MM-DD"))) {
                    if (poi.is_optional) {
                        optional_poi.push(poi);
                    }
                    else {
                        main_poi.push(poi);
                    }
                }
            });
            manual_cart.map((manual) => {
                if (manual.product_type === 11 || manual.product_type === 12) {
                    if (manual.booking_status !== undefined && ((manual.booking_status !== null && manual.booking_status.status_booking !== "CANCELLED") || manual.booking_status === null) && moment.utc(manual.start_date, "YYYY-MM-DD").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD")) && moment.utc(manual.start_date, "YYYY-MM-DD").isSameOrBefore(moment.utc(step.end_date, "YYYY-MM-DD"))) {
                        if (manual.is_optional) {
                            optional_poi.push(manual);
                        }
                        else {
                            main_poi.push(manual);
                        }
                    }
                }
            })
            setMainPoi(main_poi);
            setOptionPoi(optional_poi);
        }
    }, [poi_cart, manual_cart]);
    return (
        <Fragment>
        {
            mainPoi.length !== 0 && (
                <Grid item style={{marginTop: 10, marginBottom: 10}} className={clsx({[classes.paddingLeft0]: byDay})}>
                    <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h6"}>{t("summary.main_poi")}</Typography>
                </Grid>
            )
        }
        {
            mainPoi.map((poi, poi_index) => {
                if (poi.booking_status !== undefined && ((poi.booking_status !== null && poi.booking_status.status_booking !== "CANCELLED") || poi.booking_status === null)) {
                    return (
                        <Grid item key={poi_index} style={{marginTop: 10, marginBottom: 10}} className={ clsx({[classes.bookingContainer]: from !== "tripSummary" && selectedProducts.poi[step_index].includes(poi.id), [classes.borderContainer]: from !== "tripSummary", [classes.hoverContainer]: trip_info.status !== "USER_VALID" && from !== "tripSummary" && optionPoi.length !== 0 && !selectedProducts.poi[step_index].includes(poi.id)}) }>
                            {
                                !poi.is_manual && (
                                    <SummaryPoiCard poi={poi} mainPoi={mainPoi} type={"main"} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} from={from}/>
                                )
                            }
                            {
                                poi.is_manual && (
                                    <SummaryPoiCardManual poi={poi} mainPoi={mainPoi} type={"main"} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} from={from}/>
                                )
                            }
                            {
                                from === "tripSummary" && (
                                    <Divider/>
                                )
                            }
                        </Grid>
                    )
                }
            })
        }
        {
            optionPoi.length !== 0 && (
                <Grid item style={{marginTop: 10, marginBottom: 10}} className={clsx({[classes.paddingLeft0]: byDay})}>
                    <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h6"}>{t("summary.alternated_poi")}</Typography>
                </Grid>
            )
        }
        {
            optionPoi.map((poi, poi_index) => {
                if (poi.booking_status !== undefined && ((poi.booking_status !== null && poi.booking_status.status_booking !== "CANCELLED") || poi.booking_status === null)) {
                    return (
                        <Grid item key={poi_index} style={{marginTop: 10, marginBottom: 10}} className={ clsx({[classes.bookingContainer]: from !== "tripSummary" && selectedProducts.poi[step_index].includes(poi.id), [classes.borderContainer]: from !== "tripSummary", [classes.hoverContainer]: trip_info.status !== "USER_VALID" && from !== "tripSummary" && optionPoi.length !== 0 && !selectedProducts.poi[step_index].includes(poi.id)}) }>
                            {
                                !poi.is_manual && (
                                    <SummaryPoiCard poi={poi} mainPoi={mainPoi} type={"option"} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} from={from}/>
                                )
                            }
                            {
                                poi.is_manual && (
                                    <SummaryPoiCardManual poi={poi} mainPoi={mainPoi} type={"option"} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} from={from}/>
                                )
                            }
                            {
                                from === "tripSummary" && (
                                    <Divider/>
                                )
                            }
                        </Grid>
                    )
                }
            })
        }
        </Fragment>
    )
}
export default RenderSummaryPoi;