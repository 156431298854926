// Dependencies
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';
// Core
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles({
    hr: {
        margin: '10px 0 0 0'
    },
    dateContainer: {
        textAlign: 'center',
        border: '1px solid'
    }
});

const CartAccommodationPriceDetail = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const language = useSelector(state => state.header.tmp_language);
    const price_detail = useSelector(state => state.cart.price_detail);
    const [dates, setDates] = useState([]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const onClose = () => {
        dispatch({type: 'CART_TOGGLE_PRICE_DETAIL', payload: null});
    };
    useEffect(() => {
        if (price_detail !== null) {
            let tmp_dates = [];
            price_detail.detailed_pricing.map(price => {
                tmp_dates.push({...price, currency: price_detail.currency});
            });
            /*
            let tmp_dates = [];
            price_detail.rooms.map(room => {
                let matched = false;
                tmp_dates.map(date => {
                    if (date.date === room.detailed_pricing[0].date) {
                        matched = true;
                        date.tax_included_price += room.detailed_pricing[0].tax_included_price
                    }
                });
                if (!matched) {
                    tmp_dates.push({
                        date: room.detailed_pricing[0].date,
                        currency: currency_list.find(currency => currency.id === room.detailed_pricing[0].currency_id),
                        tax_included_price: room.detailed_pricing[0].tax_included_price
                    });
                }
            });
             */
            setDates(tmp_dates);
        }
    }, [price_detail]);
    if (price_detail !== null) {
        return (
            <Dialog fullScreen={ fullScreen } PaperProps={{style: { borderRadius: fullScreen ? 0 : 16 } }} open={ true } fullWidth maxWidth={ 'md' } onClose={ onClose } disableEscapeKeyDown>
                <DialogTitle onClose={ onClose }>
                    <Grid container justify={ 'space-between' } alignItems={ 'center' }>
                        {
                            fullScreen ? (
                                <Grid item>
                                    <IconButton edge={ 'start' } onClick={ onClose }><NavigateBefore/></IconButton> { t('cart-material.price-detail') }
                                </Grid>
                            ) : (
                                <Fragment>
                                    <Grid item>
                                        { t('cart-material.price-detail') } :
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={ onClose }><Close/></IconButton>
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </Grid>
                    <hr className={ classes.hr }/>
                </DialogTitle>
                <DialogContent>
                    <Grid container justify={ 'center' }>
                        {
                            dates.map(date => {
                                let price = date.master_price;
                                if (user.client_full.type === 2) {
                                    if (date.agency_price !== null) {
                                        price = date.agency_price;
                                    } else if (date.network_price !== null) {
                                        price = date.network_price;
                                    }
                                } else if (date.network_price !== null) {
                                    price = date.network_price;
                                }
                                return (
                                    <Grid key={ `price-date-${date.date}` } className={ classes.dateContainer } item md={ 3 }>
                                        <Typography>{ moment.utc(date.date).format("DD-MM-YYYY") }</Typography>
                                        <Typography>{ price.toLocaleString(language, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }{ date.currency.symbol }</Typography>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    } else {
        return null;
    }
};

export default CartAccommodationPriceDetail;