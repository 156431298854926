import { useSelector } from "react-redux";
import axios from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import { Flight } from "../../Itinerary/network/flight";
import { AppState } from "../../../Reducers/Reducers";

type Callback = (flight: Flight) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: (from: Flight, to: Flight) => Promise<void> | void,
    onError: (error: Error) => Promise<void> | void,
    onFinally: () => void
}>

export function useFlightPnrRefresh(options: Options): Callback {
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const versionString = GetCookie("trip_id_version");
    const version = versionString ? parseInt(versionString) : -1;

    return async (flight) => {
        if (tripId && flight.booking_status?.item_reference) {
            try {
                if (options.onTrigger) {
                    options.onTrigger();
                }
                const newFlight = await makeRequest({
                    tripId,
                    version,
                    flightId: flight.id,
                    providerId: flight.provider,
                    pnr: flight.booking_status.item_reference
                });
                if (options.onSuccess) {
                    await options.onSuccess(flight, newFlight);
                }
            } catch (error: any) {
                if (options.onError) {
                    await options.onError(error);
                }
            } finally {
                if (options.onFinally) {
                    options.onFinally();
                }
            }
        }
    };
}

type RequestOptions = {
    tripId: number,
    version: number,
    flightId: number,
    providerId: number,
    pnr: string
}

async function makeRequest(options: RequestOptions): Promise<Flight> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.post<Flight>(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/flight/create_flight_from_pnr/`,
            {
                pnr: options.pnr,
                provider_id: options.providerId
            },
            { headers }
        );
        return response.data;
    }

    throw new Error('Could not make create_flight_from_pnr request.');
}
