import { differenceInCalendarDays } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { intersection } from "lodash";
import { getItineraryDayDifference } from "../../Menu/MaterialTripList/utils/getItineraryDifference";
import { sortItinerary } from "../../Itinerary/utils/sortItinerary";
import { Itinerary } from "../../Itinerary/objects/itinerary";
import { ItineraryContentStep } from "../../Itinerary/objects/itineraryContentStep";

export function matchItineraryContentWithItinerary(
    content: ItineraryContentStep[],
    itinerary: Itinerary[]
): {
    content: ItineraryContentStep,
    steps: Itinerary[]
}[] {
    const steps = itinerary.filter((item) => {
        return item.step_type === 'STEP';
    }).sort(sortItinerary);

    const firstDay = steps[0]?.start_date ?
        utcToZonedTime(steps[0]?.start_date, 'Etc/UTC') :
        utcToZonedTime(new Date(), 'Etc/UTC');
    return content.map((item, index) => {
        if (item.mode === 'by-day') {
            const destinationIds = item.content.destinations?.map((destination) => {
                return destination.id;
            });
            const days = [...item.content.day].sort((a, b) => a - b);
            const result = steps.filter((step) => {
                const daysDifference = getItineraryDayDifference(step);
                const stepDays = daysDifference.map((item) => {
                    return differenceInCalendarDays(
                        item,
                        firstDay
                    ) + 1;
                }).sort((a, b) => a - b);
                return intersection(
                    days,
                    stepDays
                ).length > 0 &&
                step.destination?.id &&
                destinationIds?.includes(step.destination?.id);
            });
            return {
                content: item,
                steps: result
            };
        }
        const step = steps[index];
        return {
            content: item,
            steps: step ? [step] : []
        };
    });
}
