export default (itinerary, flight_cart, hotel_cart, transfers_cart, cars_cart, poi_cart, manual_cart) => {
    let tmp_selected_product = {
        mainFlights: [],
        hotels: [],
        cars: [],
        transfers: [],
        poi: [],
        step_flight: []
    };
    let tmp_itinerary = [];
    if (itinerary.find((el) => { return el.step_type === "START"}) === undefined && itinerary.find((el) => { return el.step_type === "END"}) === undefined) {
        tmp_itinerary[0] = {
            step_type: "START",
            start_date: itinerary[0].end_date,
            end_date: itinerary[0].end_date,
            destination: itinerary[0].destination,
        }
        tmp_itinerary = tmp_itinerary.concat(itinerary);
        tmp_itinerary.push({
            step_type: "END",
            end_date: itinerary[itinerary.length - 1].start_date,
            start_date: itinerary[itinerary.length - 1].start_date,
            destination: itinerary[itinerary.length - 1].destination,
        });
    }
    else if (itinerary.find((el) => { return el.step_type === "START"}) === undefined && itinerary.find((el) => { return el.step_type === "END"}) !== undefined) {
        tmp_itinerary[0] = {
            step_type: "START",
            start_date: itinerary[0].end_date,
            end_date: itinerary[0].end_date,
            destination: itinerary[0].destination,
        }
        tmp_itinerary = tmp_itinerary.concat(itinerary);
    }
    else if (itinerary.find((el) => { return el.step_type === "START"}) !== undefined && itinerary.find((el) => { return el.step_type === "END"}) === undefined) {
        tmp_itinerary = itinerary.slice();
        tmp_itinerary.push({
            step_type: "END",
            end_date: itinerary[itinerary.length - 1].start_date,
            start_date: itinerary[itinerary.length - 1].start_date,
            destination: itinerary[itinerary.length - 1].destination,
        });
    }
    else {
        tmp_itinerary = itinerary.slice();
    }
    Object.keys(tmp_selected_product).map((key) => {
        if (tmp_selected_product[key].length !== itinerary.length - 1) {
            tmp_itinerary.forEach((tmp, index) => {
                if (index !== itinerary.length - 1) {
                    tmp_selected_product[key].push([]);
                }
            });
        }
    });
    tmp_itinerary.map((step, step_index) => {
        flight_cart.map((flight) => {
            flight.outbounds.map((outbound) => {
                if (step_index === 0) {
                    if (!flight.is_optional && flight.booking_status !== undefined && ((flight.booking_status !== null && flight.booking_status.status_booking !== "CANCELLED") || flight.booking_status === null) && !tmp_selected_product.mainFlights[step_index].includes(flight.id) && moment.utc(outbound.legs[0].departure_time, "YYYY-MM-DD").isSame(moment.utc(step.end_date, "YYYY-MM-DD"), "day")) {
                        tmp_selected_product.mainFlights[step_index].push(flight.id);
                    }
                }
                else if (step_index < tmp_itinerary.length - 2) {
                    if (!flight.is_optional && flight.booking_status !== undefined && ((flight.booking_status !== null && flight.booking_status.status_booking !== "CANCELLED") || flight.booking_status === null) && !tmp_selected_product.step_flight[step_index].includes(flight.id) && moment.utc(outbound.legs[0].departure_time, "YYYY-MM-DD").isSame(moment.utc(step.end_date, "YYYY-MM-DD"), "day")) {
                        tmp_selected_product.step_flight[step_index].push(flight.id);
                    }
                }
            });
        });
        hotel_cart.map((hotel) => {
            if (step_index !== 0 && step_index !== tmp_itinerary.length - 1 && !hotel.is_optional && hotel.booking_status !== undefined && ((hotel.booking_status !== null && hotel.booking_status.status_booking !== "CANCELLED") || hotel.booking_status === null) && !tmp_selected_product.hotels[step_index].includes(hotel.id) && moment.utc(hotel.start_date, "YYYY-MM-DD").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD")) && moment.utc(hotel.end_date, "YYYY-MM-DD").isSameOrBefore(moment.utc(step.end_date, "YYYY-MM-DD"))) {
                tmp_selected_product.hotels[step_index].push(hotel.id);
            }
        });
        cars_cart.map((car) => {
            if (step_index !== 0 && step_index !== tmp_itinerary.length -1 && !car.is_optional && car.booking_status !== undefined && ((car.booking_status !== null && car.booking_status.status_booking !== "CANCELLED") || car.booking_status === null) && !tmp_selected_product.cars[step_index].includes(car.id) && ((moment.utc(car.start_date).isSameOrAfter(moment.utc(step.start_date), 'd') && car.start_destination.id === step.destination.id) || (moment.utc(car.start_date).isSameOrBefore(moment.utc(step.start_date), 'd') && moment.utc(car.end_date).isSameOrAfter(moment.utc(step.end_date), 'd')))) {
                tmp_selected_product.cars[step_index].push(car.id);
            }
        });
        transfers_cart.map((transfer) => {
            if (step_index !== 0 && step_index !== tmp_itinerary.length -1 && !transfer.is_optional && transfer.booking_status !== undefined && ((transfer.booking_status !== null && transfer.booking_status.status_booking !== "CANCELLED") || transfer.booking_status === null) && !tmp_selected_product.transfers[step_index].includes(transfer.id) && moment.utc(transfer.start_date, "YYYY-MM-DD").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD")) && moment.utc(transfer.end_date, "YYYY-MM-DD").isSameOrBefore(moment.utc(step.end_date, "YYYY-MM-DD"))) {
                tmp_selected_product.transfers[step_index].push(transfer.id);
            }
        });
        poi_cart.map((poi) => {
            if (step_index !== 0 && step_index !== tmp_itinerary.length -1 && !poi.is_optional && poi.booking_status !== undefined && ((poi.booking_status !== null && poi.booking_status.status_booking !== "CANCELLED") || poi.booking_status === null) && !tmp_selected_product.poi[step_index].includes(poi.id) && moment.utc(poi.start_date, "YYYY-MM-DD").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD")) && moment.utc(poi.start_date).isSameOrBefore(moment.utc(step.end_date)) ) {
                tmp_selected_product.poi[step_index].push(poi.id);
            }
        });
        manual_cart.map((manual) => {
            if (step.step_type !== 'START' && step.step_type !== 'END') {
                if (!manual.is_optional && manual.booking_status !== undefined && ((manual.booking_status !== null && manual.booking_status.status_booking !== "CANCELLED") || manual.booking_status === null)) {
                    if ((manual.product_type === 0 || manual.product_type === 7) && moment.utc(manual.start_date).isSameOrAfter(moment.utc(step.start_date)) && moment.utc(manual.end_date).isSameOrBefore(moment.utc(step.end_date))) {
                        tmp_selected_product.hotels[step_index].push(manual.id);
                    }
                    if (manual.product_type === 4 && moment.utc(manual.start_date).isSameOrAfter(moment.utc(step.start_date)) && moment.utc(manual.end_date).isSameOrBefore(moment.utc(step.end_date), "d")) {
                        tmp_selected_product.transfers[step_index].push(manual.id);
                    }
                    if (manual.product_type === 2 && moment.utc(manual.start_date, "YYYY-MM-DD HH:mm").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD HH:mm")) && moment.utc(manual.start_date, "YYYY-MM-DD HH:mm").isSameOrBefore(moment.utc(step.end_date, "YYYY-MM-DD HH:mm"))) {
                        tmp_selected_product.cars[step_index].push(manual.id);
                    }
                    if ((manual.product_type === 11 || manual.product_type === 12)) {
                        if (moment.utc(manual.start_date, "YYYY-MM-DD").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD")) && moment.utc(manual.end_date, "YYYY-MM-DD").isSameOrBefore(moment.utc(step.end_date, "YYYY-MM-DD"))) {
                            tmp_selected_product.poi[step_index].push(manual.id);
                        }
                    }
                }
            }
        })
    });
    return {
        tmp_selected_product,
        tmp_itinerary
    };
}