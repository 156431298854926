import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { MailTemplateVisualEditorBlockChoice } from "./mailTemplateVisualEditorBlockChoice";
import { RegisteredBlocks } from "./utils/registered-blocks";

type Props = {
    disabledBlocks?: string[]
}

export function MailTemplateVisualEditorBlocksPanel(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <Grid container spacing={1}>
            {
                RegisteredBlocks.filter((item) => {
                    return !props.disabledBlocks?.includes(item.type);
                }).map((block) => (
                    <Grid item xs={4} key={block.type}>
                        <MailTemplateVisualEditorBlockChoice
                            type={block.type}
                            icon={block.icon}
                            label={t(block.label)}
                        />
                    </Grid>
                ))
            }
        </Grid>
    );
}
