//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import FlightIcon from '@material-ui/icons/Flight';
import EventIcon from '@material-ui/icons/Event';
import HotelIcon from '@material-ui/icons/Hotel';
import StarIcon from '@material-ui/icons/Star';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';

import { SetHover } from '../../Actions/Summary';
import { PopoverTitle } from 'reactstrap';
import moment from "moment";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    bold: {
        fontWeight: 'bold'
    },
    italic: {
        fontStyle: "italic"
    },
    pointer: {
        cursor: 'pointer'
    },
    fontSize14: {
        fontSize: 14
    }
}));

const SummaryMapDetail = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const itinerary = useSelector(store => store.summary.itinerary);
    const trip_info = useSelector(store => store.summary.trip_info);
    const flight_cart = useSelector(store => store.summary.flight_cart);
    const manual_cart = useSelector(store => store.summary.manual_cart);
    const hotel_cart = useSelector(store => store.summary.hotel_cart);
    const cars_cart = useSelector(store => store.summary.cars_cart);
    const transfers_cart = useSelector(store => store.summary.transfers_cart);
    const poi_cart = useSelector(store => store.summary.poi_cart);

    const [hasStartFlight, setHasStartFlight] = useState([]);
    const [hasEndFlight, setHasEndFlight] = useState([]);

    let last_day = 1;
    let nb_nights = 0;

    useEffect(() => {
        let tmp_arr_end = [];
        let tmp_arr_start = [];
        let no_double_flight = [];

        flight_cart.map((flight, flight_index) => {
            if (!flight.is_optional && flight.is_displayed) {
                let tmp_obj_end = {
                    more_details: null,
                    segment: null
                };
                let tmp_obj_start = {
                    more_details: null,
                    segment: null
                };
                for (let i = 0; i < flight.outbounds.length; i++) {
                    let legs = flight.outbounds[i].legs;
                    if ((moment.utc(legs[0].departure_time).isAfter(moment.utc(itinerary[0].start_date)) || moment.utc(legs[0].departure_time).isSame(moment.utc(itinerary[0].start_date), "d"))
                        && (moment.utc(legs[legs.length - 1].arrival_time).isBefore(moment.utc(itinerary[1].end_date)) || moment.utc(legs[legs.length - 1].arrival_time).isSame(moment.utc(itinerary[1].end_date), "d"))) {
                        if (flight.is_displayed === true) {
                            tmp_obj_start.more_details = flight;
                            tmp_obj_start.segment = flight.outbounds[i];
                        }
                    } else if ((moment.utc(itinerary[0].start_date).isAfter(moment.utc(legs[0].departure_time)) || moment.utc(itinerary[0].start_date).isSame(moment.utc(legs[0].departure_time), "d"))
                        && (moment.utc(itinerary[1].end_date).isBefore(moment.utc(legs[legs.length - 1].arrival_time)) || moment.utc(itinerary[1].end_date).isSame(moment.utc(legs[legs.length - 1].arrival_time), "d"))) {
                        if (flight.is_displayed === true) {
                            tmp_obj_start.more_details = flight;
                            tmp_obj_start.segment = flight.outbounds[i];
                        }
                    }

                    if ((moment.utc(legs[0].departure_time).isAfter(moment.utc(itinerary[itinerary.length - 1].end_date)) || moment.utc(legs[0].departure_time).isSame(moment.utc(itinerary[itinerary.length - 1].end_date), "d"))
                        && (moment.utc(legs[legs.length - 1].arrival_time).isBefore(moment.utc(trip_info.end_date)) || moment.utc(legs[legs.length - 1].arrival_time).isSame(moment.utc(trip_info.end_date), "d"))) {
                        if (flight.is_displayed === true) {
                            tmp_obj_end.more_details = flight;
                            tmp_obj_end.segment = flight.outbounds[i];
                        }
                    } else if ((moment.utc(itinerary[itinerary.length - 1].end_date).isAfter(moment.utc(legs[0].departure_time)) || moment.utc(itinerary[itinerary.length - 1].end_date).isSame(moment.utc(legs[0].departure_time), "d"))
                        && (moment.utc(trip_info.end_date).isBefore(moment.utc(legs[legs.length - 1].arrival_time)) || moment.utc(trip_info.end_date).isSame(moment.utc(legs[legs.length - 1].arrival_time), "d"))) {
                        if (flight.is_displayed === true) {
                            tmp_obj_end.more_details = flight;
                            tmp_obj_end.segment = flight.outbounds[i];
                        }
                    }
                    if (tmp_obj_end.more_details !== null) {
                        tmp_arr_end.push(tmp_obj_end);
                    }

                    if (tmp_obj_start.more_details !== null) {
                        let is_in = false;
                        for (let t = 0; t < no_double_flight.length; t++) {
                            if (no_double_flight[t] === flight.id + flight_index) {
                                is_in = true;
                            }
                        }
                        if (is_in === false) {
                            tmp_arr_start.push(tmp_obj_start);
                            no_double_flight.push(flight.id + flight_index);
                        }
                        tmp_obj_start = {
                            more_details: null,
                            segment: null
                        };
                    }
                }
            }
        });
        setHasStartFlight(tmp_arr_start);
        setHasEndFlight(tmp_arr_end);
    }, []);

    const checkStep = (step, index_step) => {
        let tmp_obj = {
            more_details_flight: [],
            more_details_car: [],
            more_details_hotel: [],
            more_details_manual: [],
            more_details_poi: [],
            more_details_transfer: []
        };
        hotel_cart.map((room) => {
            if (!room.is_optional && (moment.utc(room.start_date).isAfter(moment.utc(step.start_date)) || moment.utc(room.start_date).isSame(moment.utc(step.start_date), "d")) && (moment.utc(room.end_date).isBefore(moment.utc(step.end_date)) || moment.utc(room.end_date).isSame(moment.utc(step.end_date), "d"))) {
                if (room.is_displayed === true) {
                   tmp_obj.more_details_hotel.push(room);
                }
            }
        });
        manual_cart.map((manual) => {
            if (manual.is_displayed && !manual.is_optional) {
                if ((manual.product_type === 0 || manual.product_type === 7) && moment.utc(manual.start_date).isSameOrAfter(moment.utc(step.start_date), "d") && moment.utc(manual.end_date).isSameOrBefore(moment.utc(step.end_date), "d")) {
                    tmp_obj.more_details_manual.push(manual);
                }
                if (manual.product_type === 4 && moment.utc(manual.start_date).isSameOrAfter(moment.utc(step.start_date)) && moment.utc(manual.end_date).isSameOrBefore(moment.utc(step.end_date))) {
                    tmp_obj.more_details_manual.push(manual);
                }
                if (manual.product_type === 2 && moment.utc(manual.start_date, "YYYY-MM-DD HH:mm").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD HH:mm")) && moment.utc(manual.start_date, "YYYY-MM-DD HH:mm").isSameOrBefore(moment.utc(step.end_date, "YYYY-MM-DD HH:mm"))) {
                    tmp_obj.more_details_manual.push(manual);
                }
                if ((manual.product_type === 11 || manual.product_type === 12)) {
                    if (moment.utc(manual.start_date, "YYYY-MM-DD").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD")) && moment.utc(manual.start_date, "YYYY-MM-DD").isSameOrBefore(moment.utc(step.end_date, "YYYY-MM-DD"))) {
                        tmp_obj.more_details_manual.push(manual);
                    }
                }
            }
        });
        cars_cart.map((car) => {
            if (moment.utc(car.start_date).isSameOrAfter(moment.utc(step.start_date), 'd') && car.start_destination.id === step.destination.id) {
                if (car.is_displayed === true && !car.is_optional) {
                    tmp_obj.more_details_car.push(car);
                }
            } else if (moment.utc(car.start_date).isSameOrBefore(moment.utc(step.start_date), 'd') && moment.utc(car.end_date).isSameOrAfter(moment.utc(step.end_date), 'd')) {
                if (car.is_displayed === true && !car.is_optional) {
                    tmp_obj.more_details_car.push(car);
                }
            }
        });
        poi_cart.map((poi) => {
            if (moment.utc(poi.start_date).isSameOrAfter(moment.utc(step.start_date)) && moment.utc(poi.start_date).isSameOrBefore(moment.utc(step.end_date))) {
                if (poi.is_displayed && !poi.is_optional) {
                    tmp_obj.more_details_poi.push(poi);
                }
            }
        });
        transfers_cart.map((transfer) => {
            if (moment.utc(transfer.start_date).isSameOrAfter(moment.utc(step.start_date)) && moment.utc(transfer.end_date).isSameOrBefore(moment.utc(step.end_date))) {
                if (transfer.is_displayed && !transfer.is_optional) {
                    tmp_obj.more_details_transfer.push(transfer);
                }
            }
        });
        flight_cart.map((flight) => {
            let tmp_obj_flight = {
                more_details: null,
                segment: null
            };
            flight.outbounds.find((outbound, outbound_index) => {
                if (flight.is_displayed && !flight.is_optional && moment.utc(outbound.legs[0].departure_time).isSame(moment.utc(step.end_date), "d")) {
                    tmp_obj_flight.more_details = flight;
                    tmp_obj_flight.segment = outbound;
                }
            });
            if (tmp_obj_flight.more_details !== null) {
                tmp_obj.more_details_flight.push(tmp_obj_flight);
            }
        });
        return tmp_obj;
    }
    return (
        <Grid container direction={"column"} spacing={4} className={classes.genericText}>
        {
            hasStartFlight.length !== 0 && (
                <Fragment>
                    <Grid item container direction={"row"}>
                        <Grid item xs={2} className={`${classes.bold} ${classes.capitalize}`}>{ t("header.start_date") } </Grid>
                        <Grid item xs={10}>
                            <Grid container direction={"column"}>
                            {
                                hasStartFlight.map((flight, index_flight) => {
                                    let last_leg = 0;
                                    if (flight.segment.legs.length > 1) {
                                        last_leg = flight.segment.legs.length - 1;
                                    } else {
                                        last_leg = 0;
                                    }
                                    let departure_locale = flight.segment.legs[0].departure_time.split("Z");
                                    let departure_date = moment.utc(departure_locale[0]).format("dddd Do MMMM YYYY, HH[h]mm");
                                    let origin_city = flight.segment.legs[0].origin !== null ? flight.segment.legs[0].origin.iata_city.international_name : flight.segment.legs[0].origin_station.iata_city.international_name;
                                    let dest_city = flight.segment.legs[last_leg].destination !== null ? flight.segment.legs[last_leg].destination.iata_city.international_name : flight.segment.legs[last_leg].destination_station.iata_city.international_name;
                                    return (
                                        <Grid item key={index_flight}>
                                            <Grid container direction={"column"}>
                                                <Grid item>
                                                    <FlightIcon style={{verticalAlign: 'sub', opacity: '80%', color: '#0000008A', marginRight: 5}} />
                                                    { origin_city } ({flight.segment.legs[0].origin !== null ? flight.segment.legs[0].origin.airport_code : flight.segment.legs[0].origin_station.station_code}) { " → " + dest_city} ({flight.segment.legs[last_leg].destination !== null ? flight.segment.legs[last_leg].destination.airport_code : flight.segment.legs[last_leg].destination_station.station_code})
                                                </Grid>
                                                <Grid item className={`${classes.capitalize} ${classes.fontSize14}`}>
                                                    <EventIcon style={{verticalAlign: 'bottom', opacity: '80%', color: '#0000008A', marginRight: 5}} />
                                                    {departure_date}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })
                            }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider variant={"inset"}/>
                </Fragment>
            )
        }
        {
            itinerary.map((step, index) => {
                let first_day = last_day;
                last_day = first_day + moment.utc(step.end_date).set('hour', 0).set('minutes', 0).set('second', 0).set('millisecond', 0).diff(moment.utc(step.start_date).set('hour', 0).set('minutes', 0).set('second', 0).set('millisecond', 0), "d");
                nb_nights = moment.utc(step.end_date).set('hour', 0).set('minutes', 0).set('second', 0).set('millisecond', 0).diff(moment.utc(step.start_date).set('hour', 0).set('minutes', 0).set('second', 0).set('millisecond', 0), "d");
                if (step.step_type === "STEP") {
                    let info = checkStep(step, index);
                    let step_name = ((step.destination.data.name !== undefined && step.destination.data.name !== null) ? step.destination.data.name : step.destination.data.international_name).split(",")
                    return (
                        <Fragment key={index}>
                            <Grid item container direction={"row"} className={classes.pointer} onMouseEnter={() => { dispatch(SetHover(step.id)); }} onMouseLeave={() => { dispatch(SetHover(null)); }}>
                                <Grid item container direction={"column"} xs={2}>
                                    <Grid item className={`${classes.bold} ${classes.capitalize}`}>{ t("filters.step") + " " + index }</Grid>
                                    <Grid item className={classes.italic}>{t("menu.history.csv.day") + " " + first_day + " " + t("global.at") + " " + last_day }</Grid>
                                    <Grid item className={classes.italic}>({ nb_nights > 1 ? nb_nights + " " + t("itinerary.nights") : nb_nights + " " + t("itinerary.night") })</Grid>
                                </Grid>
                                <Grid item container direction={"column"} xs={10}>
                                    <Grid item>
                                        { step_name[0] + ", " }
                                        <span className={classes.fontSize14}>{t("global.from") + " " + moment.utc(step.start_date).format("DD/MM") + " " + t("global.to")  + " " + moment.utc(step.end_date).format("DD/MM") }</span>
                                    </Grid>
                                    {
                                        info.more_details_hotel.length > 0 && (
                                            <Fragment>
                                            {
                                                 info.more_details_hotel.map((hotel, hotel_index) => {
                                                    let stars = [];
                                                    for (let i = 0; i < hotel.hotel[0].stars; i++) {
                                                        stars.push(<StarIcon key={i} style={{verticalAlign: 'sub', opacity: '80%', color: '#0000008A'}}/>)
                                                    }
                                                    return (
                                                        <Grid item key={hotel_index}>
                                                            <HotelIcon style={{verticalAlign: 'sub', opacity: '80%', color: '#0000008A', marginRight: 5}}/>
                                                            {hotel.hotel[0].name}
                                                            <span style={{marginLeft: 5}}>{stars}</span>
                                                        </Grid>
                                                    )
                                                 })
                                            }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        info.more_details_manual.length > 0 && (
                                            <Fragment >
                                                {
                                                    info.more_details_manual.map((manual, index_manual) => {
                                                        let stars = [];
                                                        for (let i = 0; i < manual.stars; i++) {
                                                            stars.push(<StarIcon key={i} style={{verticalAlign: 'sub', opacity: '80%', color: '#0000008A'}}/>)
                                                        }
                                                        if (manual.product_type === 0 || manual.product_type === 7) {
                                                            return (
                                                                <Grid item key={index_manual}>
                                                                    <HotelIcon style={{verticalAlign: 'sub', opacity: '80%', color: '#0000008A', marginRight: 5}}/>
                                                                    { manual.name }
                                                                    <span> { stars } </span>
                                                                </Grid>
                                                            );
                                                        }
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        info.more_details_transfer.length > 0 && (
                                            <Fragment >
                                                {
                                                    info.more_details_transfer.map((transfer, index_transfer) => {
                                                        return (
                                                            <Grid item key={index_transfer}>
                                                                <TransferWithinAStationIcon style={{verticalAlign: 'sub', opacity: '80%', color: '#0000008A', marginRight: 5}}/>
                                                                { transfer.custom_product !== undefined ? transfer.custom_product.title : transfer.name }
                                                            </Grid>
                                                        );
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        info.more_details_manual.length > 0 && (
                                            <Fragment >
                                                {
                                                    info.more_details_manual.map((manual, index_manual) => {
                                                        if (manual.product_type === 4) {
                                                            return (
                                                                <Grid item key={index_manual}>
                                                                    <TransferWithinAStationIcon style={{verticalAlign: 'sub', opacity: '80%', color: '#0000008A', marginRight: 5}}/>
                                                                    { manual.name }
                                                                </Grid>
                                                            );
                                                        }
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        info.more_details_car.length > 0 && (
                                            <Fragment >
                                                {
                                                    info.more_details_car.map((car, index_car) => {
                                                        return (
                                                            <Grid item key={index_car}>
                                                                <DriveEtaIcon style={{verticalAlign: 'sub', opacity: '80%', color: '#0000008A', marginRight: 5}}/>
                                                                { car.name }
                                                            </Grid>
                                                        );
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        info.more_details_manual.length > 0 && (
                                            <Fragment >
                                                {
                                                    info.more_details_manual.map((manual, index_manual) => {
                                                        if (manual.product_type === 2) {
                                                            return (
                                                                <Grid item key={index_manual}>
                                                                    <DriveEtaIcon style={{verticalAlign: 'sub', opacity: '80%', color: '#0000008A', marginRight: 5}}/>
                                                                    { manual.name }
                                                                </Grid>
                                                            );
                                                        }
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        info.more_details_poi.length > 0 && (
                                            <Fragment >
                                                {
                                                    info.more_details_poi.map((poi, index_poi) => {
                                                        return (
                                                            <Grid item key={index_poi}>
                                                                <VisibilityIcon style={{verticalAlign: 'sub', opacity: '80%', color: '#0000008A', marginRight: 5}}/>
                                                                { poi.custom_product.title }
                                                            </Grid>
                                                        );
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        info.more_details_manual.length > 0 && (
                                            <Fragment >
                                                {
                                                    info.more_details_manual.map((manual, index_manual) => {
                                                        if (manual.product_type === 11 || manual.product_type === 12) {
                                                            return (
                                                                <Grid item key={index_manual}>
                                                                    <VisibilityIcon style={{verticalAlign: 'sub', opacity: '80%', color: '#0000008A', marginRight: 5}}/>
                                                                    { manual.name }
                                                                </Grid>
                                                            );
                                                        }
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        info.more_details_flight.length > 0 && (
                                            <Fragment>
                                                {
                                                    info.more_details_flight.map((flight, index_flight) => {
                                                        let last_leg = 0;
                                                        if (flight.segment.legs.length > 1) {
                                                            last_leg = flight.segment.legs.length - 1;
                                                        } else {
                                                            last_leg = 0;
                                                        }
                                                        let departure_locale = flight.segment.legs[0].departure_time.split("Z");
                                                        let departure_date = moment.utc(departure_locale[0]).format("dddd Do MMMM YYYY");
                                                        let origin_city = flight.segment.legs[0].origin !== null ? flight.segment.legs[0].origin.iata_city.international_name : flight.segment.legs[0].origin_station.iata_city.international_name;
                                                        let dest_city = flight.segment.legs[last_leg].destination !== null ? flight.segment.legs[last_leg].destination.iata_city.international_name : flight.segment.legs[last_leg].destination_station.iata_city.international_name;
                                                        if (index + 2 !== itinerary.length) {
                                                            return (
                                                                <Grid item key={index_flight}>
                                                                    <FlightIcon style={{verticalAlign: 'sub', opacity: '80%', color: '#0000008A', marginRight: 5}} />
                                                                    { origin_city } ({flight.segment.legs[0].origin !== null ? flight.segment.legs[0].origin.airport_code : flight.segment.legs[0].origin_station.station_code}) { " → " + dest_city} ({flight.segment.legs[last_leg].destination !== null ? flight.segment.legs[last_leg].destination.airport_code : flight.segment.legs[last_leg].destination_station.station_code})
                                                                </Grid>
                                                            );
                                                        }
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                </Grid>
                            </Grid>
                            {
                               ( step !== itinerary.length - 2 || (step === itinerary.length - 2 && hasEndFlight.length !== 0)) && (
                                    <Divider variant={"inset"}/>
                                )
                            }
                        </Fragment>
                    )
                }
            })
        }
        {
            hasEndFlight && (
                <Grid item container direction={"row"}>
                    <Grid item xs={2} className={`${classes.bold} ${classes.capitalize}`}>{ t("header.end_date") } </Grid>
                    <Grid item xs={10}>
                        <Grid container direction={"column"}>
                        {
                            hasEndFlight.map((flight, index_flight) => {
                                let last_leg = 0;
                                        if (flight.segment.legs.length > 1) {
                                            last_leg = flight.segment.legs.length - 1;
                                        } else {
                                            last_leg = 0;
                                        }
                                        let departure_locale = flight.segment.legs[0].departure_time.split("Z");
                                        let departure_date = moment.utc(departure_locale[0]).format("dddd Do MMMM YYYY, HH[h]mm");
                                        let origin_city = flight.segment.legs[0].origin !== null ? flight.segment.legs[0].origin.iata_city.international_name : flight.segment.legs[0].origin_station.iata_city.international_name;
                                        let dest_city = flight.segment.legs[last_leg].destination !== null ? flight.segment.legs[last_leg].destination.iata_city.international_name : flight.segment.legs[last_leg].destination_station.iata_city.international_name;
                                return (
                                    <Grid item key={index_flight}>
                                        <Grid container direction={"column"}>
                                            <Grid item>
                                                <FlightIcon style={{verticalAlign: 'sub', opacity: '80%', color: '#0000008A', marginRight: 5}} />
                                                { origin_city } ({flight.segment.legs[0].origin !== null ? flight.segment.legs[0].origin.airport_code : flight.segment.legs[0].origin_station.station_code}) { " → " + dest_city} ({flight.segment.legs[last_leg].destination !== null ? flight.segment.legs[last_leg].destination.airport_code : flight.segment.legs[last_leg].destination_station.station_code})
                                            </Grid>
                                            <Grid item className={`${classes.capitalize} ${classes.fontSize14}`}>
                                                <EventIcon style={{verticalAlign: 'bottom', opacity: '80%', color: '#0000008A', marginRight: 5}} />
                                                {departure_date}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })
                        }
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        </Grid>
    )
}
export default SummaryMapDetail;