//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import Radio from '@mui/material/Radio';
import { Checkbox } from '@mui/material';

import RoomIcon from '@material-ui/icons/Room';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PeopleIcon from '@material-ui/icons/People';
import WorkIcon from '@material-ui/icons/Work';
import AcUnitOutlinedIcon from '@material-ui/icons/AcUnitOutlined';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import DoorIcon from '../../CarsMaterial/DoorIcon';
import GearIcon from "../../CarsMaterial/GearIcon.js";
import i18n from '../../../i18n';
import { AppState } from '../../../Reducers/Reducers';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
	italic: {
		fontStyle: "italic !important"
	},
	font14: {
		fontSize: 14
	},
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    cover: {
    	display: "block",
	    width: "100%",
	    height: "100%"
    },
    content: {
    	display: 'flex',
    	alignItems: "center",
    	paddingLeft: theme.spacing(1),
    	paddingBottom: theme.spacing(1),
    },
    pictureContainer: {
	    overflow: "hidden",
	    height: 200,
	    // width: 300,
	    position: "relative",
	    cursor: "pointer"
    },
    shortDescription: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        display:"-webkit-box !important",
        WebkitLineClamp:3,
        WebkitBoxOrient:"vertical",
        whiteSpace:"normal"
    },
    lowerCase: {
        textTransform: "capitalize"
    },
    carCardInfo: {
		display: 'inline-block',
		marginRight: 11
	},
    carCardInfoFlex: {
		display: 'inline-flex',
		marginRight: 11	
	},
    carService: {
		listStyle: 'none',
		marginTop: 10,
        paddingInlineStart: "50px",
		'& li': {
			height: 26
		}
	},
	iconCheckCircle: {
		position: 'absolute',
		left: '-45px',
		top: 1,
		opacity: '54%'
	},
	carServiceTitle: {
		marginLeft: '-17px',
		fontSize: 14
	},
    similarCar: {
		textTransform: 'uppercase',
		fontWeight: 900,
		fontSize: 16,
		opacity: '54%'
	},
    carCardImg: {
		maxWidth: '100%',
		maxHeight: '100%'
	},
    paddingLeft25: {
        paddingLeft: 25
    },
    checkbox: {
        // position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px',
        color: `${main_color}`,
        '&.Mui-checked': {
            color: `${main_color}`,
        },
    },
    moreDetailContainer: {
        paddingBottom: 8
    },
    moreDetail: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    border: {
        border: '1px solid #ebebeb',
        borderRadius: 13,
        padding: 10,
        marginBottom: 10,
        backgroundColor: 'white',
        marginLeft: 7,
        width: '100%'
    },
    marginBottom10: {
        marginBottom: 10
    },
    marginTop10: {
        marginTop: 10
    },
    carOptionSelected: {
		border: `2px solid ${main_color} !important`,
		backgroundColor: `${main_color}20`,
        borderRadius: 10
	},
    orange: {
        color: `${main_color}`
    }
}));

type CarsCardProps = {
    car: any,
    cars: any,
    variantIsChecked: any,
    onToggleVariant: any,
    products: any
}

const CarsCard = (props: CarsCardProps) => {
    const {car, variantIsChecked, onToggleVariant, cars, products} = props;
    const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [collapse, setCollapse] = useState<boolean>(true);
    const locales = useSelector((state: AppState) => state.user.locales);
	const type = useSelector((state: AppState) => state.itinerary_type.type);

    const current_locale = locales.find((el) => {return el.language_code === i18n.language});
    const start_destination = car.start_destination !== null ? car.start_destination.data.localization.find((el) => {return el.locale === current_locale?.id}) !== undefined ? 
        car.start_destination.data.localization.find((el) => {return el.locale === current_locale?.id}).name.split(',') : car.start_destination.data.international_name.split(',') : null;
    const end_destination = car.end_destination !== null ? car.end_destination.data.localization.find((el) => {return el.locale === current_locale?.id}) !== undefined ? 
        car.end_destination.data.localization.find((el) => {return el.locale === current_locale?.id}).name.split(',') : car.end_destination.data.international_name.split(',') : null;
    
    const toggleMoreDetail = () => {
        setCollapse(!collapse);
    }
    let check_in_cart = products.find((product: any) => {
        if (product.start_destination !== undefined && product.start_destination !== null && car.start_destination !== undefined && car.start_destination !== null) {
		    return car.start_destination.data.id === product.start_destination.data.id && car.product_type === product.product_type && !product.is_optional;
        }
	});
    let check_option = products.find((product: any) => {
        if (product.start_destination !== undefined && product.start_destination !== null && car.start_destination !== undefined && car.start_destination !== null) {
    		return car.start_destination.data.id === product.start_destination.data.id && car.product_type === product.product_type && product.is_optional;
        }
	});
    return (
        <Grid container direction={"row"} spacing={2} style={{marginBottom: 10}} className={classes.border}>
            <Grid item xs container direction={"column"} alignItems={"flex-start"}>
				<Grid item>
                    <DriveEtaIcon className={`${classes.genericText} ${classes.orange}`} style={{verticalAlign: "sub", marginRight: 10}}/>
					<span className={classes.genericText} style={{fontWeight: 'bold', textTransform: 'uppercase'}}>{ `${t<string>('summary.vehicle_rental')} ${start_destination !== null && end_destination !== null ? `${t('global.from_1')} ${start_destination[0]} ${t('global.at')} ${end_destination[0]}` : ''}` } </span>
				</Grid>
                <Grid item className={`${classes.genericText} ${classes.italic}`}>{`${car.name} ${t("cars.or_similiar")}`}</Grid>
				<Grid item>
					<RoomIcon className={`${classes.genericText}`} style={{fontSize: 16, verticalAlign: "text-top"}}/>
					<ArrowForwardIcon className={`${classes.genericText}`} style={{fontSize: 16, verticalAlign: "middle", marginLeft: 2, marginRight: 2}}/>
					<span className={`${classes.genericText} ${classes.italic}`} style={{fontSize: 14}}>{car.address_pickup + " " + car.station_name_pickup}</span>
				</Grid>
                <Grid item>
					<RoomIcon className={`${classes.genericText}`} style={{fontSize: 16, verticalAlign: "text-top"}}/>
					<ArrowBackIcon className={`${classes.genericText}`} style={{fontSize: 16, verticalAlign: "middle", marginLeft: 2, marginRight: 2}}/>
					<span className={`${classes.genericText} ${classes.italic}`} style={{fontSize: 14}}>{car.address_return + " " + car.station_name_return}</span>
				</Grid>
			</Grid>
            <Grid className={ classes.moreDetailContainer } item xs={ 12 }>
                <Typography className={ classes.moreDetail } align={ 'center' } onClick={ toggleMoreDetail }>{collapse ? t<string>('cart-material.show-more-details') : t<string>('cart-material.show-less-details')}</Typography>
            </Grid>
            {
                !collapse &&
                <Grid item container direction={"row"} justify={"flex-start"} alignItems={"center"} spacing={fullScreen ? 2 : 0}>
                    <Grid item sm={5} xs={12} className={classes.pictureContainer}>
                        <img src={car.vehicle_picture_url} className={classes.carCardImg}/>
                    </Grid>
                    <Grid item sm={7} xs={12} className={clsx({[classes.paddingLeft25]: !fullScreen})}>
                        <Grid container direction={"column"} spacing={2}>
                            <Grid item>
                                <Typography variant={"h5"}>{ t<string>("footer.about") }</Typography>
                            </Grid>
                            <Grid item className={ `${classes.similarCar} ${classes.marginTop10}` }>
                                {t("global.category") + t("cars.categories." + ((car.vehicle_category).toLowerCase()).replace(" ", "_")) + " (" + car.vehicle_code + ")"}
                            </Grid>
                            <Grid item className={ classes.marginTop10 }>
                                <div className={ classes.carCardInfo } style={{marginLeft: '-2px'}}>
                                    <Tooltip title={t<string>("global.luggages")} placement={ "bottom" }>
                                        <WorkOutlineIcon className={`${classes.genericText}`} style={{marginRight: 2,verticalAlign: 'sub', fontSize: 20}}/>
                                    </Tooltip>
                                    <span className={ classes.font14 }>{car.baggage}</span>
                                </div>
                                <div className={ classes.carCardInfo }>
                                    <Tooltip title={t<string>("flight_groups.passengers")} placement={ "bottom" }>
                                        <PermIdentityIcon className={`${classes.genericText}`} style={{marginRight: 2, verticalAlign: 'sub', fontSize: 20}}/>
                                    </Tooltip>
                                    <span className={ classes.font14 }>{car.max_passengers}</span>
                                </div>
                                <div className={ classes.carCardInfoFlex }>
                                    <Tooltip title={t<string>("tooltip.door_nbr")} placement={ "bottom" }>
                                        <div>
                                            <DoorIcon className={`${classes.genericText}`} fontSize={"small"} style={{marginRight: 2,verticalAlign: 'sub', fontSize: 20}}/>
                                        </div>
                                    </Tooltip>
                                    <span className={ classes.font14 } style={{paddingTop: '3px', paddingLeft: '4px'}}>{car.door_count}</span>
                                </div>
                                {
                                    car.air_conditioner && (
                                        <div className={ classes.carCardInfo }>
                                            <Tooltip title={t<string>("accommodation.equipment.air_conditioning")} placement={ "bottom" }>
                                                <AcUnitOutlinedIcon className={`${classes.genericText}`} style={{marginRight: 2,verticalAlign: 'sub', fontSize: 20}}/>
                                            </Tooltip>
                                        </div>
                                    )
                                }
                                <div className={ classes.carCardInfoFlex } style={{marginRight: 0}}>
                                    <Tooltip title={t<string>("cars.transmission.transmission")} placement={ "bottom" }>
                                        <div>
                                            <GearIcon className={`${classes.genericText}`} fontSize={"small"} style={{marginRight: 2,verticalAlign: 'sub', fontSize: 20}}/>
                                        </div>
                                    </Tooltip>
                                    <span className={ classes.font14 } style={{paddingTop: '3px', paddingLeft: '4px', textTransform: 'capitalize'}}>{t<string>("cars.transmission." + (car.transmission_type).toLowerCase())}</span>
                                </div>
                            </Grid>
                            {
                                car.minimal_age && (
                                    <Grid item xs={12} className={ classes.font14 } style={{ textTransform: 'capitalize'}}>
                                        {t("cars.minimal_age")}
                                        <span>
                                            {car.minimal_age}
                                        </span>
                                    </Grid>
                                )
                            }
                            {
                                car.licence_time && (
                                    <Grid item xs={12} className={ `${classes.font14} ${classes.marginBottom10}` } style={{ textTransform: 'capitalize'}}>
                                        {t("cars.licence_time")}
                                        <span>
                                            {car.licence_time}
                                        </span>
                                    </Grid>
                                )
                            }
                            {
                                car.included_service !== null && (
                                    <ul className={ classes.carService }>
                                        {
                                            car.included_service.map((service:any, service_index:number) => {
                                                return (
                                                    <Fragment key={service_index}>
                                                        <li className={ "ft-relative" }>
                                                            <span className={classes.carServiceTitle}>{service.title}</span>
                                                            <CheckCircleOutlinedIcon className={classes.iconCheckCircle}/>
                                                        </li>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </ul>
                                )
                            }
                        </Grid>
                    </Grid>                   
                </Grid>
            }
            {
                check_option &&
                <Grid item xs={12} style={{marginTop: 10, border: "1px solid #e5e7eb", width: '100%', borderRadius: 10, margin:'8px 0px 8px 0px', paddingLeft: 20}} className={clsx(classes.genericText, {[classes.carOptionSelected]: variantIsChecked(car)})}>
                    <FormControlLabel
                        control={<Checkbox sx={{color: `${main_color}`,
                        '&.Mui-checked': {
                            color: `${main_color}`,
                        },
                    }} className={ classes.checkbox } edge={ 'start' } disableRipple checked={ variantIsChecked(car) } onChange={ onToggleVariant(car) } />}
                        label={!car.is_optional ? t<string>("itineraryType.default_cars", {type: t<string>('shared.' + type)}) : (check_in_cart ? t<string>("itineraryType.alternate_car") : t<string>("itineraryType.optional_car"))}
                    />
                </Grid>
            }
        </Grid>
    )
}
export default React.memo(CarsCard);