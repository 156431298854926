import { isEqual, uniqBy } from "lodash";

type Item<T> = {
    days: number[],
    destinations: number[],
    circuit: number | null,
    circuit_trip_version: number | null,
    iti_type: number | null,
    data: T
}

export function transformItineraryContentToDestinationGroups<T>(
    items: Item<T>[]
): Item<T>[][] {
    return uniqBy(
        items,
        (item) => `${item.days.join(',')}-${item.circuit}-${item.circuit_trip_version}-${item.iti_type}`
    ).reduce((prev, current) => {
        const lastGroup = prev[prev.length - 1];
        const lastItem = lastGroup?.[lastGroup.length - 1];

        if (
            lastItem &&
            isEqual(lastItem.destinations, current.destinations) &&
            lastItem.circuit === current.circuit &&
            lastItem.circuit_trip_version === current.circuit_trip_version &&
            lastItem.iti_type === current.iti_type
        ) {
            return [
                ...prev.slice(0, prev.length - 1),
                lastGroup.concat([current])
            ];
        }

        return prev.concat([[current]]);
    }, [] as Item<T>[][]);
}
