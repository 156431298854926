import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { flatten, upperFirst } from "lodash";
import GetCookie from "../../../Common/Functions/GetCookie";
import { createItineraryContent } from "./createItineraryContent";
import { createFlightsContent } from "./createFlightsContent";
import { CreatePageOptions } from "../objects/createPageOptions";
import { createSummaryContent } from "./createSummaryContent";
import { createProgramDetailsContent } from "./createProgramDetailsContent";
import { createOtherTransportsContent } from "./createOtherTransportsContent";
import { createVouchersContent } from "./createVouchersContent";
import { createPictureContent } from "./createPictureContent";
import { createDynamicPageContent } from "./createDynamicPageContent";
import { createEditoContent } from "./createEditoContent";
import { createCalendarContent } from "./createCalendarContent";
import { createStepsContent } from "./createStepsContent";
import { createFlightTicketsContent } from "./createFlightTicketsContent";
import { createTravelCertificateContent } from "./createTravelCertificateContent";
import { createContactsContent } from "./createContactsContent";
import { findQuotationTemplate } from "../utils/findQuotationTemplate";
import { findRoadbook } from "../utils/findRoadbook";
import { findTripRoadbookRubrics } from "./findTripRoadbookRubrics";
import { useCartProducts } from "../../../Itinerary/network/cartProducts";
import { useItineraryContentFetch } from "../../../CartMaterial/network/itineraryContentFetch";
import { sortItinerary } from "../../../Itinerary/utils/sortItinerary";
import { AppState } from "../../../../Reducers/Reducers";
import { findRoadbookConfiguration } from "./findRoadbookConfiguration";
import { filterCartProductsByStatus } from "./filterCartProductsByStatus";
import { filterCartProductsByUser } from "./filterCartProductsByUser";
import { RoadbookConfigurationIdentifier } from "../objects/roadbookConfigurationIdentifier";
import { Traveler } from "../../../../Reducers/objects/Traveler";
import template from "../utils/handlebars/roadbook.handlebars";
import A4Style from "../utils/scss/styles/A4.scss";
import A5Style from "../utils/scss/styles/A5.scss";
import EndingPage from "../../../../Img/roadbook-ending-page.jpg?roadbook";
import IvyModeThin from "../../../../Fonts/Ivy Mode/IvyMode-Thin.otf?roadbook";
import IvyModeBold from "../../../../Fonts/Ivy Mode/IvyMode-Bold.otf?roadbook";

type Callback = (
    assignedTraveler: Traveler,
    format: 'A4' | 'A5'
) => Promise<Parameters<typeof template>[0] | undefined>

export function useRoadbook(): Callback {
    const versionString = GetCookie("trip_id_version");
    const version = versionString ? parseInt(versionString) : null;
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? null;
    });
    const trip = useSelector((state: AppState) => state.trip.all_data);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
    const tripVersion = useSelector((state: AppState) => state.trip.data_trip);
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const contactTraveler = useSelector((state: AppState) => state.trip.contact_traveler_detail);
    const itinerary_type_details_data = useSelector((state: AppState) => state.itinerary_type.itinerary_type_details_data);
    const travelers = useSelector((state: AppState) => state.trip.travelers);
    const itineraryContent = useSelector((state: AppState) => {
        return version ?
            state.itinerarySlice.content[version] :
            undefined;
    });
    const currentContent = useMemo(() => {
        return itineraryContent?.state === 'success' ?
            itineraryContent.data :
            null;
    }, [
        itineraryContent?.state,
        itineraryContent?.state === 'success' ? itineraryContent.data : null
    ]);
    const docs = useSelector((state: AppState) => state.menu.trip_docs);
    const cart = filterCartProductsByUser(
        filterCartProductsByStatus(
            useCartProducts({ forRoadbook: true }),
            itinerary_type_details_data,
            true
        )
    );

    useItineraryContentFetch(version ?? -1);

    return async (assignedTraveler, format) => {
        if (
            trip &&
            tripVersion?.destination &&
            tripStartDate &&
            tripEndDate &&
            contactTraveler &&
            travelers &&
            assignedTraveler
        ) {
            try {
                const quotationTemplate = await findQuotationTemplate(tripVersion.destination!.id);
                const roadbook = await findRoadbook(tripVersion.destination!.id);
                const roadbookConfiguration = await findRoadbookConfiguration();
                const tripRubrics = await findTripRoadbookRubrics(trip.id);
                const sortedItinerary = [...itinerary].sort(sortItinerary);
                return {
                    fonts: {
                        ivyMode: {
                            thin: IvyModeThin,
                            bold: IvyModeBold
                        }
                    },
                    style: format === 'A4' ? A4Style : A5Style,
                    endingPagePicture: EndingPage,
                    coverPicture: roadbook?.cover_pictures?.find((item) => {
                        return (
                            window.moment.utc(item.start_date).isBefore(
                                window.moment.utc(tripStartDate)
                            ) ||
                            window.moment.utc(item.start_date).startOf('day').isSame(
                                window.moment.utc(tripStartDate).startOf('day'),
                                'day'
                            )
                        ) &&
                            window.moment.utc(tripEndDate).isBefore(window.moment.utc(item.end_date)) ||
                            window.moment.utc(tripEndDate).startOf('day').isSame(
                                window.moment.utc(item.end_date).startOf('day'),
                                'day'
                            );
                    })?.picture?.url ??
                    (roadbook?.cover_pictures ?? [])[0]?.picture?.url ??
                    quotationTemplate?.cover_pic?.url ??
                    '',
                    trip: {
                        title: trip.name ?? t("menu.trip_list.trip_of") + assignedTraveler.title + " " + assignedTraveler.last_name,
                        date: upperFirst(
                            window.moment.utc(tripStartDate).format('MMMM YYYY')
                        )
                    },
                    traveler: {
                        civility: assignedTraveler.title ?? 'MR/MME',
                        lastName: assignedTraveler.last_name?.toUpperCase() ?? ''
                    },
                    contents: flatten(
                        (
                            await Promise.all(
                                flatten(
                                    roadbookConfiguration?.modules.map(async (module) => {
                                        const options: CreatePageOptions = {
                                            title: module.category?.localization.find((item) => {
                                                return item.locale === locale;
                                            })?.name ?? '',
                                            travelers,
                                            trip,
                                            tripVersion,
                                            tripStartDate,
                                            tripEndDate,
                                            cart,
                                            contactTraveler,
                                            itinerary: sortedItinerary,
                                            itineraryContent: currentContent,
                                            locale,
                                            roadbook,
                                            providers,
                                            manualProviders,
                                            t
                                        };
                                        switch (module.identifier) {
                                            case RoadbookConfigurationIdentifier.EDITO: {
                                                const category = roadbook?.categories.find((item) => {
                                                    return item.category.id === module.category?.id;
                                                });
                                                return createEditoContent({
                                                    ...options,
                                                    text: category?.rubrics.map((item) => {
                                                        const tripRubric = tripRubrics?.find((rubric) => {
                                                            return rubric.parent === item.id;
                                                        });
                                                        return (tripRubric ?? item).localization.find((item) => {
                                                            return item.locale === locale;
                                                        })?.description ?? '';
                                                    }).join('') ?? ''
                                                });
                                            }
                                            case RoadbookConfigurationIdentifier.STEPS: return createStepsContent(options);
                                            case RoadbookConfigurationIdentifier.AGENDA: return createCalendarContent(options);
                                            case RoadbookConfigurationIdentifier.ITINERARY: return createItineraryContent(options);
                                            case RoadbookConfigurationIdentifier.FLIGHT: return createFlightsContent(options);
                                            case RoadbookConfigurationIdentifier.SUMMARY: return createSummaryContent();
                                            case RoadbookConfigurationIdentifier.PROGRAM: return await createProgramDetailsContent(options);
                                            case RoadbookConfigurationIdentifier.OTHER_TRANSPORT_MODE: return createOtherTransportsContent(options);
                                            case RoadbookConfigurationIdentifier.VOUCHER: return [
                                                createFlightTicketsContent(options),
                                                createTravelCertificateContent(options),
                                                ...await createVouchersContent({ ...options, docs: docs ?? [] })
                                            ].filter((item): item is NonNullable<typeof item> => !!item);
                                            case RoadbookConfigurationIdentifier.PICTURE: return createPictureContent(module.picture?.url ?? '');
                                            case RoadbookConfigurationIdentifier.CONTACTS: {
                                                const category = roadbook?.categories.find((item) => {
                                                    return item.category.id === module.category?.id;
                                                });
                                                return createContactsContent({
                                                    ...options,
                                                    rubrics: category?.rubrics.map((item) => {
                                                        const tripRubric = tripRubrics?.find((rubric) => {
                                                            return rubric.parent === item.id;
                                                        });
                                                        return {
                                                            parent: item.id,
                                                            trip: trip.id,
                                                            localization: (tripRubric ?? item).localization
                                                        };
                                                    }) ?? []
                                                });
                                            }
                                            case RoadbookConfigurationIdentifier.ROADBOOK_CATEGORY: {
                                                const category = roadbook?.categories.find((item) => {
                                                    return item.category.id === module.category?.id;
                                                });
                                                const rubrics = category?.rubrics.map((item) => {
                                                    const tripRubric = tripRubrics?.find((rubric) => {
                                                        return rubric.parent === item.id;
                                                    });
                                                    return {
                                                        parent: item.id,
                                                        trip: trip.id,
                                                        localization: (tripRubric ?? item).localization
                                                    };
                                                }) ?? [];
                                                return rubrics.length > 0 ?
                                                    createDynamicPageContent({
                                                        ...options,
                                                        rubrics
                                                    }) :
                                                    null;
                                            }

                                            //for a specific client
                                            case RoadbookConfigurationIdentifier.CGV: return null;
                                        }
                                    }) ?? []
                                )
                            )
                        ).filter((item): item is NonNullable<typeof item> => {
                            return !!item;
                        }) ?? []
                    ).map((item) => {
                        if (item.type === 'other-transports') {
                            return {
                                ...item,
                                categories: item.categories.filter((category) => {
                                    return category.items.length > 0;
                                })
                            };
                        }
                        return item;
                    }).filter((item) => {
                        if (item.type === 'other-transports') {
                            return item.categories.length > 0;
                        }
                        return true;
                    })
                };
            } catch (error) {
                console.error(error);
            }
        }
    };
}
