import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    inline: {
        display: "inline"
    },
    cardContainer: {
        marginBottom: 24,
        cursor: "pointer"
    },
    marginTop: {
        marginTop: 24
    },
    marginBottom: {
        marginBottom: 8
    },
    imgContainer: {
        overflow: "hidden",
        height: 200,
        width: 360,
        position: "relative",
    },
    alignRight: {
        textAlign: "right"
    }
}));

const CarsSkeleton = () => {
    const classes = useStyles();
    let items = [];
    for (let i = 0; i < 4; i++) {
        items.push(
            <Fragment key={ i }>
                <Grid className={ `${classes.cardContainer} ${classes.marginTop}` } item container spacing={ 2 }>
                    <Grid className={ classes.imgContainer } item>
                        <Skeleton variant={ "rect" } height={ "100%" }/>
                    </Grid>
                    <Grid item xs container>
                        <Grid item xs={ 12 } container>
                            <Grid item>
                                <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 15 } width={ 215 }/>
                                <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 15 } width={ 215 }/>
                                <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 20 } width={ 215 }/>
                                <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 20 } width={ 215 }/>
                            </Grid>
                        </Grid>
                        <Grid item xs={ 12 } container justify={ "flex-end" } alignItems={ "flex-end" }>
                            <Grid className={ classes.alignRight } item>
                                <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 20 } width={ 130 }/>
                                <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 20 } width={ 130 }/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    i < 3 && (
                        <Grid item xs={12}>
                            <Divider className={ classes.marginBottom }/>
                        </Grid>
                    )
                }
            </Fragment>
        )
    }
    return items
};

export default React.memo(CarsSkeleton);
