import React from "react";
import { useTranslation } from "react-i18next";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import CartPoiManualCancellationRules from "./CartPoiManualCancellationRules";
import { PoiCart } from "../Itinerary/objects/poiCart";

type Props = {
    open: boolean,
    poi: PoiCart,
    onClose: () => void,
    onCloseMenu: () => void
}

export function CartConstructionProductsTableEditPoiCancellationRulesModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            onClick={(event) => event.stopPropagation()}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" fontWeight="bold" component="div">
                        {t<string>('cart-material.manual-cancellation-rules')}
                    </Typography>
                    <IconButton onClick={props.onClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <CartPoiManualCancellationRules
                    poi={props.poi}
                    onManualCancellationRules={props.onCloseMenu}
                />
            </DialogContent>
        </Dialog>
    );
}
