import { Dispatch } from "redux";
import { isNumber } from "lodash";
import { TextBlock } from "../blocks/mailTemplateVisualEditorTextBlock";
import {
    addVisualEditorBlock,
    addVisualEditorBlocksWithoutOrder,
    appendVisualEditorBlock,
    deleteVisualEditorBlock,
    insertVisualEditorBlock,
    restoreVisualEditorBlocksFromHtml,
    selectVisualEditorBlock,
    setVisualEditorBlockOptions
} from "./actions";
import { ColumnsBlock } from "../blocks/mailTemplateVisualEditorColumnsBlock";
import { Block } from "../objects/block";
import { AppState } from "../../../../../Reducers/Reducers";

type Thunk = (dispatch: Dispatch, getState: () => AppState) => void

export function appendVisualEditBlocksIfEmpty(
    params: {
        instanceId: string,
        locale: number,
        blocks: Block<any>[]
    }
): Thunk {
    return (dispatch, getState) => {
        const instance = getState().mailTemplate.visualEditor.present.instances[params.instanceId];
        const blocks = instance?.visualEditorBlocks[params.locale]?.order.map((blockId) => {
            return instance?.visualEditorBlocks[params.locale]?.blocks[blockId];
        }).filter((item): item is TextBlock => {
            return !!item && item.getType() === 'text';
        }) ?? [];

        if (
            blocks.every((block) => {
                return block.getOptions().editorState.getCurrentContent().getPlainText().trim().length === 0;
            })
        ) {
            for (const block of params.blocks) {
                dispatch(
                    appendVisualEditorBlock(
                        params.instanceId,
                        params.locale,
                        block
                    )
                );
            }
        }
    };
}

export function restoreVisualEditorBlocksFromHtmlIfEmpty(
    params: Parameters<typeof restoreVisualEditorBlocksFromHtml>[0]
): Thunk {
    return (dispatch, getState) => {
        const instance = getState().mailTemplate.visualEditor.present.instances[params.instanceId];
        const blocks = instance?.visualEditorBlocks[params.locale]?.order.map((blockId) => {
            return instance?.visualEditorBlocks[params.locale]?.blocks[blockId];
        }).filter((item): item is TextBlock => {
            return !!item && item.getType() === 'text';
        }) ?? [];

        if (
            blocks.every((block) => {
                return block.getOptions().editorState.getCurrentContent().getPlainText().trim().length === 0;
            })
        ) {
            dispatch(restoreVisualEditorBlocksFromHtml(params));
        }
    };
}

export function reoderVisualEditorBlocks(
    options: {
        fromInstanceId: string,
        toInstanceId: string,
        locale: number,
        id: number,
        index: number
    }
): Thunk {
    return (dispatch, getState) => {
        const state = getState();
        const sourceInstance = state.mailTemplate.visualEditor.present.instances[options.fromInstanceId];
        const block = sourceInstance?.visualEditorBlocks[options.locale]?.blocks[options.id]?.clone();

        const currentIndex = sourceInstance?.visualEditorBlocks[options.locale]?.order.findIndex((id) => {
            return options.id === id;
        }) ?? 0;
        if (block) {
            dispatch(
                deleteVisualEditorBlock(
                    options.fromInstanceId,
                    options.locale,
                    options.id
                )
            );
            dispatch(
                insertVisualEditorBlock({
                    instanceId: options.toInstanceId,
                    locale: options.locale,
                    index: currentIndex < options.index ?
                        options.index - 1 :
                        options.index,
                    block
                })
            );
        }
    };
}

export function reorderVisualEditorBlocksFromColumn(
    options: {
        fromInstanceId: string,
        toInstanceId: string,
        sourceName: string,
        locale: number,
        columnBlockId: number,
        blockId: number,
        columnIndex: number,
    }
): Thunk {
    return (dispatch, getState) => {
        const state = getState();

        if (options.sourceName === 'visual-editor-drag-area') {
            const sourceInstance = state.mailTemplate.visualEditor.present.instances[options.fromInstanceId];
            const targetInstance = state.mailTemplate.visualEditor.present.instances[options.toInstanceId];
            const block = sourceInstance?.visualEditorBlocks[options.locale]?.blocks[options.blockId]?.clone();
            const columnsBlock = targetInstance?.visualEditorBlocks[options.locale]?.blocks[options.columnBlockId] as ColumnsBlock | undefined;

            if (block && columnsBlock) {
                //delete block from source instance
                dispatch(
                    deleteVisualEditorBlock(
                        options.fromInstanceId,
                        options.locale,
                        options.blockId
                    )
                );
                //add block in target instance
                dispatch(
                    addVisualEditorBlocksWithoutOrder(
                        options.toInstanceId,
                        options.locale,
                        [block]
                    )
                );
                //add block in column
                const columnsBlockOptions = columnsBlock.getOptions();
                dispatch(
                    setVisualEditorBlockOptions(
                        options.toInstanceId,
                        options.locale,
                        options.columnBlockId,
                        {
                            ...columnsBlockOptions,
                            columns: columnsBlockOptions.columns.map((column, index) => ({
                                ...column,
                                blockIds: index === options.columnIndex ?
                                    column.blockIds.concat([block.getId()]) :
                                    column.blockIds
                            }))
                        }
                    )
                );
            }
        } else if (options.sourceName.includes('columns-block')) {
            const sourceInstance = state.mailTemplate.visualEditor.present.instances[options.fromInstanceId];
            const targetInstance = state.mailTemplate.visualEditor.present.instances[options.toInstanceId];
            const block = sourceInstance?.visualEditorBlocks[options.locale]?.blocks[options.blockId]?.clone();
            const columnsBlock = targetInstance?.visualEditorBlocks[options.locale]?.blocks[options.columnBlockId] as ColumnsBlock | undefined;

            if (block && columnsBlock) {
                //delete block from source instance
                dispatch(
                    deleteVisualEditorBlock(
                        options.fromInstanceId,
                        options.locale,
                        options.blockId
                    )
                );
                //delete block from all columns blocks
                const columnsBlocks = Object.values(sourceInstance?.visualEditorBlocks[options.locale]?.blocks ?? {});
                for (const item of columnsBlocks) {
                    if (item.getType() === 'columns') {
                        const blockOptions = (item as ColumnsBlock).getOptions();
                        dispatch(
                            setVisualEditorBlockOptions(
                                options.fromInstanceId,
                                options.locale,
                                item.getId(),
                                {
                                    ...blockOptions,
                                    columns: blockOptions.columns.map((column) => ({
                                        ...column,
                                        blockIds: column.blockIds.filter((id) => {
                                            return id !== options.blockId;
                                        })
                                    }))
                                }
                            )
                        );
                    }
                }
                //add block in target instance
                dispatch(
                    addVisualEditorBlocksWithoutOrder(
                        options.toInstanceId,
                        options.locale,
                        [block]
                    )
                );
                //add block in column
                const columnsBlockOptions = columnsBlock.getOptions();
                dispatch(
                    setVisualEditorBlockOptions(
                        options.toInstanceId,
                        options.locale,
                        options.columnBlockId,
                        {
                            ...columnsBlockOptions,
                            columns: columnsBlockOptions.columns.map((column, index) => ({
                                ...column,
                                blockIds: index === options.columnIndex ?
                                    column.blockIds.concat([block.getId()]) :
                                    column.blockIds
                            }))
                        }
                    )
                );
            }
        }
    };
}

export function addAndSelectVisualEditorBlock(
    options: {
        instanceId: string,
        locale: number,
        blockType: string | symbol
    }
): Thunk {
    return (dispatch, getState) => {
        dispatch(
            addVisualEditorBlock(
                options.instanceId,
                options.locale,
                options.blockType,
                null
            )
        );

        const instance = getState().mailTemplate.visualEditor.present.instances[options.instanceId];
        const order = instance?.visualEditorBlocks[options.locale]?.order;
        const lastBlockId = (order ?? [])[(order?.length ?? 0) - 1];

        if (isNumber(lastBlockId)) {
            dispatch(
                selectVisualEditorBlock(
                    options.instanceId,
                    lastBlockId
                )
            );
        }
    };
}
