import { sortPlaneAlternatives } from "./sortPlaneAlternatives";
import { Transport } from "../objects/transport";

type Callback = (a: Transport, b: Transport) => number

export function createSortTransports(aLocation: google.maps.LatLng, bLocation: google.maps.LatLng): Callback {
    return (a, b) => {
        if (a.types.includes('plane') && b.types.includes('plane')) {
            const aWeight = ((a.origin.weight ?? 0) + (a.destination.weight ?? 0));
            const bWeight = ((b.origin.weight ?? 0) + (b.destination.weight ?? 0));

            if (aWeight !== bWeight) {
                return aWeight - bWeight;
            }

            if (a.alternatives[0] && b.alternatives[0]) {
                return sortPlaneAlternatives({
                    a: a.alternatives[0],
                    b: b.alternatives[0],
                    aLocation,
                    bLocation
                });
            }
        }
        const aDuration = a.alternatives[0]?.steps.reduce((prev, current) => {
            return prev + current.duration;
        }, 0) ?? 0;
        const bDuration = b.alternatives[0]?.steps.reduce((prev, current) => {
            return prev + current.duration;
        }, 0) ?? 0;

        if (
            aDuration <= 5 * 3600 &&
        isTransportCar(a) &&
        !isTransportCar(b)
        ) {
            return -1;
        } else if (
            bDuration <= 5 * 3600 &&
        !isTransportCar(a) &&
        isTransportCar(b)
        ) {
            return 1;
        }

        return aDuration < bDuration ? -1 : 1;
    };
}

function isTransportCar(transport: Transport): boolean {
    return !transport.types.includes('plane') &&
            transport.types[0] === 'car';
}
