import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Divider,
    Stack,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography
} from "@mui/material";
import {
    AccessTime,
    AccountCircle,
    CalendarToday,
    LocationOn,
    Place
} from "@mui/icons-material";
import GetProvider from "../Common/Functions/GetProvider";
import { PoiCart } from "./objects/poiCart";
import { ManualProductPoiCart } from "./objects/manualProductPoiCart";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    pois: ({
        type: 'normal',
        poi: PoiCart,
    } | {
        type: 'manual',
        poi: ManualProductPoiCart
    })[]
} & Pick<TooltipProps, 'children'>

export function ItineraryStepItemPoiTooltip(props: Props): JSX.Element {
    const { t } = useTranslation();
    const providers = useSelector((state: AppState) => state.trip.providers);
    const user = useSelector((state: AppState) => state.user.user);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);


    return (
        <LightTooltip
            disableHoverListener={props.pois.length === 0}
            title={
                props.pois.map(({ type, poi }) => {
                    if (type === 'manual') {
                        return (
                            <Box key={poi.id} sx={{ padding: 2.5 }}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-end"
                                    sx={{ marginBottom: 1.5 }}
                                >
                                    <Box sx={{ width: '60%' }}>
                                        <Typography variant="h6" color="#888">
                                            {poi.name}
                                            {
                                                poi.is_optional &&
                                                <Box component="span" sx={{ color: '#E6592F' }}>
                                                    {t<string>('cart.optional_product')}
                                                </Box>
                                            }
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Divider sx={{ marginBottom: 1 }} />
                                {
                                    poi.address &&
                                    <TextWithIcon variant="body2" gutterBottom>
                                        <Place />
                                        { poi.address}
                                    </TextWithIcon>
                                }
                                <TextWithIcon variant="body2" gutterBottom>
                                    <>
                                        <CalendarToday />
                                        <span>
                                            { t<string>("cart.from") }{' '}
                                            {
                                                window.moment.utc(poi.start_date).format("DD MMM")
                                            }, {
                                                window.moment.utc(poi.start_date).format('hh:mm')
                                            }{' '}
                                            { t<string>("cart.to") }{' '}
                                            {
                                                window.moment.utc(poi.end_date).format("DD MMM")
                                            }, {
                                                window.moment.utc(poi.end_date).format('hh:mm')
                                            }
                                        </span>
                                    </>
                                </TextWithIcon>
                                <Text sx={{ marginTop: 1.5 }}>
                                    {poi.custom_information}
                                </Text>
                            </Box>
                        );
                    }

                    const adults = poi.group_passenger.travelers_list.filter((traveler) => {
                        if (tripEndDate) {
                            return window.moment.utc(tripEndDate).diff(window.moment.utc(traveler.birth_date), "y") >= 18;
                        }
                    }) ?? [];
                    const children = poi.group_passenger.travelers_list.filter((traveler) => {
                        if (tripEndDate) {
                            return window.moment.utc(tripEndDate).diff(window.moment.utc(traveler.birth_date), "y") < 18;
                        }
                    }) ?? [];
                
                    let provider = GetProvider(providers, poi.provider ?? 0);
                    const hasProvider = provider && (user?.client_full?.type !== 2 || JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code === 'verdie');
                    return (
                        <Box sx={{ padding: 2.5 }}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                sx={{ marginBottom: 1.5 }}
                            >
                                <Box sx={{ width: hasProvider ? '60%' : '100%' }}>
                                    <Typography variant="h6" color="#888">
                                        {poi.custom_product?.title}
                                        {
                                            poi.is_optional &&
                                            <Box component="span" sx={{ color: '#E6592F' }}>
                                                {t<string>('cart.optional_product')}
                                            </Box>
                                        }
                                    </Typography>
                                </Box>
                                {
                                    hasProvider &&
                                    <Box sx={{ width: '15%' }}>
                                        <img
                                            src={
                                                provider?.provider.logo?.thumbnail_little ?? provider?.provider.logo?.url ?? ''
                                            }
                                            alt={provider?.provider.name}
                                            width="100%"
                                            height="auto"
                                        />
                                    </Box>
                                }
                            </Stack>
                            <Divider sx={{ marginBottom: 1 }} />
                            <TextWithIcon variant="body2" gutterBottom>
                                <CalendarToday />
                                {window.moment.utc(poi.start_date).format("DD-MM-YYYY")}
                            </TextWithIcon>
                            <TextWithIcon variant="body2" gutterBottom>
                                <AccessTime />
                                {window.moment.utc(poi.hour, "HH:mm").format("HH:mm")}{' '}
                                { t("poi.duration") + " : " }
                                {
                                    window.moment.duration(poi.custom_product?.duration ?? 0, 'minutes').humanize()
                                }
                            </TextWithIcon>
                            {
                                poi.custom_product?.taking_address && poi.custom_product.taking_address.length > 0 &&
                                <TextWithIcon variant="body2" gutterBottom>
                                    <>
                                        <LocationOn />
                                        { t("poi.taking_address") } : { poi.custom_product.taking_address }
                                    </>
                                </TextWithIcon>
                            }
                            <TextWithIcon variant="body2" sx={{ marginBottom: 3 }}>
                                <AccountCircle />
                                {
                                    adults.length > 0 &&
                                    (adults.length > 1 ? adults.length + " " + t("global.adults") : adults.length + " " + t("global.adult"))
                                }{' '}
                                {
                                    children.length > 0 &&
                                    (children.length > 1 ? children.length + " " + t("global.children") : children.length + " " + t("global.child"))
                                }
                            </TextWithIcon>
                            <p>{ poi.custom_information }</p>
                            {
                                poi.catalog_price &&
                                <p><i className={ "icon-warning" }/>{ t<string>("poi.catalog_price_warning") }</p>
                            }
                        </Box>
                    );
                })
            }
            arrow
            PopperProps={{
                disablePortal: true
            }}
        >
            {props.children}
        </LightTooltip>
    );
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        maxWidth: 420
    },
    [`& .${tooltipClasses.arrow}`]: {
        "color": theme.palette.common.white,
        '&:before': {
            border: '1px solid #ccc'
        }
    }
}));

const TextWithIcon = styled(Typography)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: 10
})) as typeof Typography;

const Text = styled(Typography)(() => ({
    fontSize: 10
})) as typeof Typography;
