import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Star from "@material-ui/icons/Star";
import Room from "@material-ui/icons/Room";
import Wifi from "@material-ui/icons/Wifi";
import Pool from "@material-ui/icons/Pool";
import AcUnit from "@material-ui/icons/AcUnit";
import DateRange from '@material-ui/icons/DateRange';
import Restaurant from "@material-ui/icons/Restaurant";
import AccessTime from "@material-ui/icons/AccessTime";
import RoomService from "@material-ui/icons/RoomService";
import LocalParking from "@material-ui/icons/LocalParking";
import FreeBreakfastOutlined from "@material-ui/icons/FreeBreakfastOutlined";
import TransferWithinAStation from "@material-ui/icons/TransferWithinAStation";
import GetPrice from "./MaterialGetPrice";
import GetProviderById from "./MaterialGetProviderById";
import CurrencyFormat from "../Accommodation/FunctionalComponent/CurrencyFormat";
import Divider from "@material-ui/core/Divider";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles({
    itemContainer: {
        color: grey[800]
    },
    marginRight: {
        marginRight: 16
    },
    paddingBottom: {
        paddingBottom: 8
    },
    paddingLeft: {
        paddingLeft: 16
    },
    negativeMarginLeft: {
        marginLeft: -42
    },
    floatLeft: {
        float: "left"
    },
    bold: {
        fontWeight: "bold"
    },
    inline: {
        display: 'inline-block'
    },
    stars: {
        display: "inline",
        marginLeft: 4
    },
    name: {
        marginRight: 4,
        color: 'black'
    },
    smallPriceFont: {
        fontSize: "1rem"
    },
    bigPriceFont: {
        fontSize: "1.5rem"
    },
    totalPrice: {
        textAlign: 'right',
        color: 'black'
    },
    underline: {
        textDecoration: 'underline'
    }
});

const MaterialTimelineAccommodation = ({ cart }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const currency = useSelector(state => state.trip.currency);
    const end_date = useSelector(state => state.trip.end_date);
    const providers = useSelector(state => state.trip.providers);
    const manual_providers = useSelector(state => state.trip.manual_providers);
    const user = useSelector(state => state.user.user);
    let displayed_cart = [];
    let manual_cart = [];
    let total_price = 0;
    cart.map((item) => {
        total_price += parseFloat(GetPrice(item.prices, user));
        item.children = 0;
        item.adults = 0;
        if (item.group_passenger !== null) {
            item.group_passenger.travelers_list.map((traveler) => {
                if (moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y") >= 18) {
                    item.adults += 1;
                } else {
                    item.children += 1;
                }
            });
        }
        let has_same_hotel = false;
        displayed_cart.map((hotel) => {
            if (item.room_types === undefined && hotel.hotel[0].id === item.hotel[0].id) {
                has_same_hotel = true;
                if (item.group_passenger !== null) {
                    hotel.travelers += item.group_passenger.travelers.length;
                }
                hotel.rooms.push(item);
            }
        });
        if (!has_same_hotel) {
            if (item.room_types !== undefined) {
                manual_cart.push({
                    ...item,
                    rooms: item.rooms.map((room) => {
                        return {
                            ...room,
                            adults: room.group_passenger?.travelers_list.filter((traveler) => {
                                return moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y") >= 18;
                            }).length ?? 0,
                            children: room.group_passenger?.travelers_list.filter((traveler) => {
                                return moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y") < 18;
                            }).length ?? 0
                        };
                    })
                });
            } else {
                item.rooms = [];
                item.rooms.push(item);
                item.duration_days = moment.utc(item.end_date).diff(moment.utc(item.start_date), 'd');
                if (item.group_passenger !== null) {
                    item.travelers = item.group_passenger.travelers.length;
                }
                displayed_cart.push(item);
            }
        }
    });
    return (
        <Fragment>
            {
                displayed_cart.map((hotel, index_hotel) => {
                    let provider = GetProviderById(hotel.provider, providers, manual_providers);
                    let stars = [];
                    for (let i = 0; i < hotel.hotel[0].stars; i++) {
                        stars.push(<Star key={ i } className={ classes.stars }/>);
                    }
                    return (
                        <Grid className={ classes.itemContainer } key={ index_hotel }>
                            {
                                index_hotel > 0 && (
                                    <Fragment>
                                        <br/>
                                        <Divider/>
                                        <br/>
                                    </Fragment>
                                )
                            }
                            <Grid container justify={ "space-between" }>
                                <Grid item>
                                    <Stack direction="row" spacing={2}>
                                        <Typography className={ classes.name }>{ hotel.hotel[0].name }</Typography>
                                        <div>
                                            { stars }
                                        </div>
                                    </Stack>
                                    <div className={ classes.paddingBottom }>
                                        <Room className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                        <Typography className={ classes.inline }>{ hotel.hotel[0].city_name }</Typography>
                                    </div>
                                    <div className={ classes.paddingBottom }>
                                        <DateRange className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                        <Typography className={ classes.inline }>{ t('timeline.from') } { moment.utc(hotel.start_date).format('DD MMMM') } { t('timeline.to') } { moment.utc(hotel.end_date).format('DD MMMM') } { hotel.duration_days } { t('timeline.nights') }</Typography>
                                    </div>
                                    {
                                        hotel.hotel[0].internet && (
                                            <Wifi className={ classes.marginRight } fontSize={ "large" }/>
                                        )
                                    }
                                    {
                                        hotel.hotel[0].clim && (
                                            <AcUnit className={ classes.marginRight } fontSize={ "large" }/>
                                        )
                                    }
                                    {
                                        hotel.hotel[0].pool && (
                                            <Pool className={ classes.marginRight } fontSize={ "large" }/>
                                        )
                                    }
                                    {
                                        hotel.hotel[0].parking && (
                                            <LocalParking className={ classes.marginRight } fontSize={ "large" }/>
                                        )
                                    }
                                </Grid>
                                {
                                    provider !== null && provider.logo !== null && !!provider.logo.url && (JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie' || user.client_full.type !== 2) && (
                                        <Grid item>
                                            <img src={ provider.logo.url } width={ 100 } alt={ 'provider logo' }/>
                                        </Grid>
                                    )
                                }
                            </Grid>
                            <br/>
                            <Typography>{ hotel.travelers } { t('timeline.travelers') } - { hotel.rooms.length } { t('timeline.rooms') }</Typography>
                            {
                                hotel.rooms.map((room, index_room) => {
                                    return (
                                        <Grid key={ index_room } container justify={ "space-between" }>
                                            <Grid item>
                                                <Typography className={ classes.bold }>{ t('timeline.room') } { index_room + 1 } : { room.name } { room.adults > 0 && `- ${room.adults} ${t('timeline.adults')}` } { room.children > 0 && `${room.children} ${ t('timeline.children') }` }</Typography>
                                                {
                                                    room.is_cancellable && (
                                                        <div className={ classes.paddingBottom }>
                                                            <AccessTime className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                            <Typography className={ classes.inline }>{ t("accommodation.cancellable") }</Typography>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    room.breakfast_included && (
                                                        <div className={ classes.paddingBottom }>
                                                            <FreeBreakfastOutlined className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                            <Typography className={ classes.inline }>{ t("accommodation.breakfast_included") }</Typography>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    room.lunch_included && (
                                                        <div className={ classes.paddingBottom }>
                                                            <Restaurant className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                            <Typography className={ classes.inline }>{ t("accommodation.lunch_included") }</Typography>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    room.dinner_included && (
                                                        <div className={ classes.paddingBottom }>
                                                            <RoomService className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                            <Typography className={ classes.inline }>{ t("accommodation.dinner_included") }</Typography>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    room.transfer_included && (
                                                        <div className={ classes.paddingBottom }>
                                                            <TransferWithinAStation className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                            <Typography className={ classes.inline }>{ t("accommodation.transfer_included") }</Typography>
                                                        </div>
                                                    )
                                                }
                                            </Grid>
                                            <Grid item>
                                                <CurrencyFormat language={ null } fontSize={ classes.smallPriceFont } symbol={ (currency !== null ? (currency.symbol === '$' || currency.symbol === '£' ? `${currency.symbol}${currency.iso_code}` : currency.symbol) : '') } price={ GetPrice(room.prices, user) } with_precision={ 0 } with_bold={ 1 }/>
                                            </Grid>
                                        </Grid>
                                    );
                                })
                            }
                            <div className={ classes.totalPrice }>
                                <CurrencyFormat language={ null } fontSize={ classes.bigPriceFont } symbol={ (currency !== null ? (currency.symbol === '$' || currency.symbol === '£' ? `${currency.symbol}${currency.iso_code}` : currency.symbol) : '') } price={ total_price.toString() } with_precision={ 0 } with_bold={ 1 }/>
                                <Typography className={ classes.underline }>{ hotel.is_optional ? t('cart.optional_product') : '' }</Typography>
                            </div>
                        </Grid>
                    );
                })
            }
            {
                manual_cart.map((hotel, index_hotel) => {
                    let provider = GetProviderById(hotel.provider, providers, manual_providers);
                    let stars = [];
                    for (let i = 0; i < hotel.stars; i++) {
                        stars.push(<Star key={ i } className={ classes.stars }/>);
                    }
                    return (
                        <Grid className={ classes.itemContainer } key={ index_hotel }>
                            {
                                index_hotel > 0 && (
                                    <Fragment>
                                        <br/>
                                        <Divider/>
                                        <br/>
                                    </Fragment>
                                )
                            }
                            <Grid container justify={ "space-between" }>
                                <Grid item>
                                    <Stack direction="row" spacing={2}>
                                        <Typography className={ classes.name }>{ hotel.name }</Typography>
                                        <div>
                                            { stars }
                                        </div>
                                    </Stack>
                                    <div className={ classes.paddingBottom }>
                                        <Room className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                        <Typography className={ classes.inline }>{ hotel.address }</Typography>
                                    </div>
                                    <div className={ classes.paddingBottom }>
                                        <DateRange className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                        <Typography className={ classes.inline }>{ t('timeline.from') } { moment.utc(hotel.start_date).format('DD MMMM') } { t('timeline.to') } { moment.utc(hotel.end_date).format('DD MMMM') } { hotel.duration_days } { t('timeline.nights') }</Typography>
                                    </div>
                                </Grid>
                                {
                                    provider !== null && provider.logo !== null && !!provider.logo.url && (JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie' || user.client_full.type !== 2) && (
                                        <Grid item>
                                            <img src={ provider.logo.url } width={ 100 } alt={ 'provider logo' }/>
                                        </Grid>
                                    )
                                }
                            </Grid>
                            <br/>
                            <Typography>{ hotel.travelers } { t('timeline.travelers') } - { hotel.rooms.length } { t('timeline.rooms') }</Typography>
                            {
                                hotel.rooms.map((room, index_room) => {
                                    return (
                                        <Grid key={ index_room } container justify={ "space-between" }>
                                            <Grid item>
                                                <Typography className={ classes.bold }>{ t('timeline.room') } { index_room + 1 } : { room.name } { room.adults > 0 && `- ${room.adults} ${t('timeline.adults')}` } { room.children > 0 && `${room.children} ${ t('timeline.children') }` }</Typography>
                                                {
                                                    room.is_cancellable && (
                                                        <div className={ classes.paddingBottom }>
                                                            <AccessTime className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                            <Typography className={ classes.inline }>{ t("accommodation.cancellable") }</Typography>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    room.breakfast_included && (
                                                        <div className={ classes.paddingBottom }>
                                                            <FreeBreakfastOutlined className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                            <Typography className={ classes.inline }>{ t("accommodation.breakfast_included") }</Typography>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    room.lunch_included && (
                                                        <div className={ classes.paddingBottom }>
                                                            <Restaurant className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                            <Typography className={ classes.inline }>{ t("accommodation.lunch_included") }</Typography>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    room.dinner_included && (
                                                        <div className={ classes.paddingBottom }>
                                                            <RoomService className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                            <Typography className={ classes.inline }>{ t("accommodation.dinner_included") }</Typography>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    room.transfer_included && (
                                                        <div className={ classes.paddingBottom }>
                                                            <TransferWithinAStation className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                            <Typography className={ classes.inline }>{ t("accommodation.transfer_included") }</Typography>
                                                        </div>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    );
                                })
                            }
                            <div className={ classes.totalPrice }>
                                <CurrencyFormat language={ null } fontSize={ classes.bigPriceFont } symbol={ (currency !== null ? (currency.symbol === '$' || currency.symbol === '£' ? `${currency.symbol}${currency.iso_code}` : currency.symbol) : '') } price={ total_price.toString() } with_precision={ 0 } with_bold={ 1 }/>
                                <Typography className={ classes.underline }>{ hotel.is_optional ? t('cart.optional_product') : '' }</Typography>
                            </div>
                        </Grid>
                    );
                })
            }
        </Fragment>
    );
};

export default React.memo(MaterialTimelineAccommodation);
