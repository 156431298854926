//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import HotelCard from '../../ItineraryType/PackagesMaterial/HotelCard';
import SummaryHotelCard from './SummaryHotelCard';
import SummaryHotelCardManual from './SummaryHotelCardManual';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    bold: {
        fontWeight: "bold"
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    hoverContainer: {
        "&:hover": {
            borderLeft: '6px solid #E6592F'
        }
    },
    borderContainer: {
        borderTop: "1px solid #C4C4C4",
        borderBottom: "1px solid #C4C4C4",
        paddingBottom: "0px !important"
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 50
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
    paddingLeft0: {
        paddingLeft: 0
    }
}));

const RenderSummaryAccommodation = ({hotel_cart, manual_cart, step, selectedProducts, setSelectedProducts, step_index, from, byDay}) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();

    const trip_info = useSelector(state => state.summary.trip_info);

    const [mainHotel, setMainHotel] = useState([]);
    const [optionHotels, setOptionHotels] = useState([]);

    useEffect(() => {
        if (hotel_cart.length > 0) {
            let main_hotel = [];
            let optional_hotel = [];
            hotel_cart.map((hotel) => {
                if (hotel.booking_status !== undefined && ((hotel.booking_status !== null && hotel.booking_status.status_booking !== "CANCELLED") || hotel.booking_status === null) && moment.utc(hotel.start_date, "YYYY-MM-DD").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD"), "day") && moment.utc(hotel.end_date, "YYYY-MM-DD").isSameOrBefore(moment.utc(step.end_date, "YYYY-MM-DD"), "day")) {
                    if (hotel.is_optional) {
                        optional_hotel.push(hotel);
                    }
                    else {
                        main_hotel.push(hotel);
                    }
                }
            });
            manual_cart.map((manual) => {
                if (manual.product_type === 7 || manual.product_type === 0) {
                    if (manual.booking_status !== undefined && ((manual.booking_status !== null && manual.booking_status.status_booking !== "CANCELLED") || manual.booking_status === null) && moment.utc(manual.start_date, "YYYY-MM-DD").isSameOrAfter(moment.utc(step.start_date, "YYYY-MM-DD"), "day") && moment.utc(manual.end_date, "YYYY-MM-DD").isSameOrBefore(moment.utc(step.end_date, "YYYY-MM-DD"), "day")) {
                        if (manual.is_optional) {
                            optional_hotel.push(manual);
                        }
                        else {
                            main_hotel.push(manual);
                        }
                    }
                }
            });
            main_hotel = main_hotel.sort((a, b) => { return  new Date(a.start_date) - new Date(b.start_date)});
            optional_hotel = optional_hotel.sort((a, b) => { return  new Date(a.start_date) - new Date(b.start_date)});
            setMainHotel(main_hotel);
            setOptionHotels(optional_hotel);
        }
    }, [hotel_cart, manual_cart]);
    const getClientPrice = (prices) => {
		let current_price = prices.find((el) => {
			return !el.is_commissioned && !el.is_network && !el.is_surcom && !el.master_price;
		});
        if (current_price === undefined) {
            current_price = prices.find((el) => {
                return !el.is_commissioned && !el.is_network && !el.is_surcom && el.master_price;
            });
        }
		return current_price;
	}
    let mainPrice = 0;
    if (mainHotel.length !== 0) {
        mainHotel.map((hotel) => {
            mainPrice = parseFloat(getClientPrice(hotel.prices).selling_price);
            if (hotel.rooms !== undefined && hotel.rooms.length !== 0) {
                hotel.rooms.map((room) => {
                    if (room.prices !== undefined) {
                        mainPrice += parseFloat(getClientPrice(room.prices).selling_price);
                    }
                });
            }
        });
    }
    return (
        <Fragment>
        {
            mainHotel.length !== 0 && (
                <Grid item style={{marginTop: 10, marginBottom: 10}} className={clsx({[classes.paddingLeft0]: byDay})}>
                    <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h6"}>{t("summary.main_accommodation")}</Typography>
                </Grid>
            )
        }
        {
            mainHotel.map((hotel, hotel_index) => {
                if (hotel.booking_status !== undefined && ((hotel.booking_status !== null && hotel.booking_status.status_booking !== "CANCELLED") || hotel.booking_status === null)) {
                    return (
                        <Grid item key={hotel_index} className={ clsx({[classes.bookingContainer]: from !== "tripSummary" && selectedProducts.hotels[step_index].includes(hotel.id), [classes.borderContainer]: from !== "tripSummary", [classes.hoverContainer]: trip_info.status !== "USER_VALID" && from !== "tripSummary" && optionHotels.length !== 0 && !selectedProducts.hotels[step_index].includes(hotel.id)}) }>
                            {
                                !hotel.is_manual && (
                                    <SummaryHotelCard hotel={hotel} mainHotel={mainHotel} type={"main"} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} from={from}/>
                                )
                            }
                            {
                                hotel.is_manual && (
                                    <SummaryHotelCardManual hotel={hotel} mainHotel={mainHotel} type={"main"} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} from={from}/>
                                )
                            }
                            {
                                from === "tripSummary" && (
                                    <Divider/>
                                )
                            }
                        </Grid>
                    )
                }
            })
        }
        {
            optionHotels.length !== 0 && (
                <Grid item style={{marginTop: 10, marginBottom: 10}} className={clsx({[classes.paddingLeft0]: byDay})}>
                    <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h6"}>{t("summary.alternated_accommodation")}</Typography>
                </Grid>
            )
        }
        {
            optionHotels.map((hotel, hotel_index) => {
                if (hotel.booking_status !== undefined && ((hotel.booking_status !== null && hotel.booking_status.status_booking !== "CANCELLED") || hotel.booking_status === null)) {
                    return (
                        <Grid item key={hotel_index} className={ clsx({[classes.bookingContainer]: trip_info.status !== "USER_VALID" && from !== "tripSummary" && selectedProducts.hotels[step_index].includes(hotel.id), [classes.borderContainer]: from !== "tripSummary", [classes.hoverContainer]: trip_info.status !== "USER_VALID" && from !== "tripSummary" && optionHotels.length !== 0 && !selectedProducts.hotels[step_index].includes(hotel.id)}) } style={{marginTop: 10, marginBottom: 10}}>
                            {
                                !hotel.is_manual && (
                                    <SummaryHotelCard hotel={hotel} mainHotel={mainHotel} type={"option"} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} from={from} mainPrice={mainPrice}/>
                                )
                            }
                            {
                                hotel.is_manual && (
                                    <SummaryHotelCardManual hotel={hotel} mainHotel={mainHotel}type={"option"} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} from={from}/>
                                )
                            }
                            {
                                from === "tripSummary" && (
                                    <Divider/>
                                )
                            }
                        </Grid>
                    )
                }
            })
        }
        </Fragment>
    )
}
export default RenderSummaryAccommodation;