export const ADD_CUSTOM_PICTURES = 'PICTURE_ADD_CUSTOM_PICTURES';
export const ADD_FILES = 'PICTURE_ADD_FILES';
export const ADD_SELECTION = 'PICTURE_ADD_SELECTION';
export const DELETE_FILE = 'PICTURE_DELETE_FILE';
export const DELETE_SELECTION = 'PICTURE_DELETE_SELECTION';
export const EDIT_PICTURES = "PICTURE_EDIT_PRODUCT_PICTURES";
export const SET_ADDING = 'PICTURE_SET_ADDING';
export const SET_CROPPER = 'PICTURE_SET_CROPPER';
export const SET_LIMIT = 'PICTURE_SET_LIMIT';
export const SET_LIST = 'PICTURE_SET_LIST';
export const SET_PAGE = 'PICTURE_SET_PAGE';
export const SET_PICTURE = 'PICTURE_SET_PICTURE';
export const SET_RUNNING = 'PICTURE_SET_RUNNING';
export const SET_SEARCH = 'PICTURE_SET_SEARCH';
export const TOGGLE_CREATE = 'PICTURE_TOGGLE_CREATE';
export const TOGGLE_KEEP = 'PICTURE_TOGGLE_KEEP';
export const UPDATE_ALT = 'PICTURE_UPDATE_ALT';
export const UPDATE_CREATE_TAB = 'PICTURE_UPDATE_CREATE_TAB';
export const UPDATE_CREDITS = 'PICTURE_UPDATE_CREDITS';
export const UPDATE_DESCRIPTION = 'PICTURE_UPDATE_DESCRIPTION';
export const UPDATE_NAME = 'PICTURE_UPDATE_NAME';
export const UPDATE_PROGRESS = 'PICTURE_UPDATE_PROGRESS';