//---- Dependencies ----//
import i18n from "../../i18n.jsx";

//---- Actions ----//
import { SetItinerary } from "../../Actions/Itinerary";
import { UnlockItineraryActions, ZoomOnItinerary } from "../../Actions/Base";
import { SetDates } from "../../Actions/Trip";

//----- Commons -----//
import Notify from '../Common/Notify.js';
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import UpdateCart from "../Itinerary/Functions/UpdateCart.js";

let waiting = 0;
let add_days_for_asia = 0;
let request_count = 0;
let request_1 = {
    index: null,
    request: {
        end_date: null,
        r2r_json: "",
        r2r_api_version: "",
        duration_transport_after: 0,
        distance_transport_km: 0,
        selected_transport: ""
    }
};
let request_2 = {
    index: null,
    request: {
        start_date: null,
    }
};
let request_3 = {
    index: null,
    request: {
        start_date: null,
    }
};

function unlock_when_finished(dispatch) {
    request_count += 1;
    if (request_count === 3) {
        /*
        if ((update_type !== undefined && update_type !== null) && (update_obj !== undefined && update_obj !== null)) {
            if (update_obj.nb_cart_item > 0 && JSON.parse(localStorage.getItem("config")).quotation_code !== "amerigo") {
                UpdateCart(update_type, dispatch, update_obj.start_date, update_obj.end_date, update_obj.compo, update_obj.id_step, update_obj.days, update_obj.old_step_arr, update_obj.new_step_arr, update_obj.is_insert);
            }
        }
         */
        dispatch(ZoomOnItinerary());
        dispatch(UnlockItineraryActions());
    }
}

function patch_everything(itinerary_list, dispatch, t, headers) {
    request_count = 0;
    if (request_1.request.end_date !== undefined && (request_1.request.end_date === null || request_1.request.end_date === "Invalid date")) {delete request_1.request.end_date;}
    if (request_2.request.end_date !== undefined && (request_2.request.end_date === null || request_2.request.end_date === "Invalid date")) {delete request_2.request.end_date;}
    if (request_3.request.end_date !== undefined && (request_3.request.end_date === null || request_3.request.end_date === "Invalid date")) {delete request_3.request.end_date;}
    if (request_1.request.start_date !== undefined && (request_1.request.start_date === null || request_1.request.start_date === "Invalid date")) {delete request_1.request.start_date;}
    if (request_2.request.start_date !== undefined && (request_2.request.start_date === null || request_2.request.start_date === "Invalid date")) {delete request_2.request.start_date;}
    if (request_3.request.start_date !== undefined && (request_3.request.start_date === null || request_3.request.start_date === "Invalid date")) {delete request_3.request.start_date;}
    $.ajax({
        method: "PATCH",
        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/${itinerary_list[request_1.index].id}/?token=${GetCookie("trip_token")}&shift_mode=false`,
        headers: headers,
        data: JSON.stringify(request_1.request),
        success: function (data) {
            dispatch(SetItinerary(data));
            unlock_when_finished(dispatch);
        },
        error: function (error) {
            console.log(error);
            unlock_when_finished(dispatch);
            Notify(t("itinerary.error.cant_apply_new_hour"), {status: 'danger', pos: 'top-center'});
        }
    });
    $.ajax({
        method: "PATCH",
        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/${itinerary_list[request_2.index].id}/?token=${GetCookie("trip_token")}&shift_mode=false`,
        headers: headers,
        data: JSON.stringify(request_2.request),
        success: function (data) {
            dispatch(SetItinerary(data));
            unlock_when_finished(dispatch);
        },
        error: function (error) {
            console.log(error);
            unlock_when_finished(dispatch);
            Notify(t("itinerary.error.cant_apply_new_hour"), {status: 'danger', pos: 'top-center'});
        }
    });
    if (request_3.index !== null) {
        $.ajax({
            method: "PATCH",
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/${itinerary_list[request_3.index].id}/?token=${GetCookie("trip_token")}&shift_mode=false`,
            headers: headers,
            data: JSON.stringify(request_3.request),
            success: function (data) {
                dispatch(SetItinerary(data));
                unlock_when_finished(dispatch);
            },
            error: function (error) {
                console.log(error);
                unlock_when_finished(dispatch);
                Notify(t("itinerary.error.cant_apply_new_hour"), {status: 'danger', pos: 'top-center'});
            }
        });
    } else {
        unlock_when_finished(dispatch);
    }
    waiting = 0;
    request_1 = {
        index: null,
        request: {
            end_date: null,
            r2r_json: "",
            r2r_api_version: "",
            duration_transport_after: 0,
            distance_transport_km: 0,
            selected_transport: ""
        }
    };
    request_2 = {
        index: null,
        request: {
            start_date: null,
        }
    };
    request_3 = {
        index: null,
        request: {
            start_date: null,
        }
    };
    add_days_for_asia = 0;
}

function callback(itinerary_list, dispatch, t, index_waiting, start_date, end_date) {
    if (waiting === 0 || waiting === 2) {
        waiting = 1;
    } else {
        waiting = 2;
    }
    if (waiting === 2 || index_waiting === 2) {
        const { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            if (moment.utc(request_1.request.end_date).diff(moment.utc(itinerary_list[request_1.index].start_date), "minutes") < 10) {
                request_1.request.end_date = moment.utc(itinerary_list[request_1.index].start_date).add(10, "minutes").format();
                request_2.request.start_date = moment.utc(request_1.request.end_date).add(request_1.request.duration_transport_after, "minutes").format();
            }
            if (request_2.request.end_date !== undefined) {
                if (moment.utc(request_2.request.end_date).diff(moment.utc(request_2.request.start_date), "minutes") < 10) {
                    request_2.request.end_date = moment.utc(request_2.request.start_date).add(10, "minutes").format();
                }
            } else {
                if (itinerary_list[request_2.index].end_date !== undefined && request_2.request.start_date !== undefined) {
                    if (moment.utc(itinerary_list[request_2.index].end_date).diff(moment.utc(request_2.request.start_date), "minutes") < 10) {
                        request_2.request.end_date = moment.utc(request_2.request.start_date).add(10, "minutes").format();
                    }
                }
            }
            if (request_3.index !== null) {
                if (request_2.request.end_date !== undefined) {
                    if (request_3.request.start_date === null || moment.utc(request_3.request.start_date).isBefore(moment.utc(request_2.request.end_date))) {
                        request_3.request.start_date = moment.utc(request_2.request.end_date).add(request_2.request.duration_transport_after, "minutes").format();
                    }
                    if (!!itinerary_list[request_3.index] && moment.utc(itinerary_list[request_3.index].end_date).diff(moment.utc(request_3.request.start_date), "minutes") < 10) {
                        request_3.request.end_date = moment.utc(request_3.request.start_date).add(10, "minutes").format();
                    }
                }
            } else {
                if (request_2.request.end_date !== undefined && itinerary_list[request_2.index + 1] !== undefined) {
                    if (moment.utc(itinerary_list[request_2.index + 1].start_date).isBefore(moment.utc(request_2.request.end_date).add(request_2.request.duration_transport_after, "minutes"))) {
                        request_3.index = request_2.index + 1;
                        request_3.request.start_date = moment.utc(request_2.request.end_date).add(request_2.request.duration_transport_after, "minutes").format();
                        if (itinerary_list[request_2.index + 1].step_type === "STEP") {
                            if (moment.utc(itinerary_list[request_2.index + 1].end_date).diff(moment.utc(request_3.request.start_date), "minutes") < 10) {
                                request_3.request.end_date = moment.utc(request_3.request.start_date).add(10, "minutes").format();
                            }
                        } else {
                            request_3.request.end_date = moment.utc(request_3.request.start_date).add(10, "minutes").format();
                        }
                    }
                } else if (itinerary_list[request_2.index + 1] !== undefined) {
                    if (!moment.utc(itinerary_list[request_2.index + 1].start_date).isSame(moment.utc(itinerary_list[request_2.index].end_date).add(itinerary_list[request_2.index].duration_transport_after, "minutes"))) {
                        request_3.index = request_2.index + 1;
                        request_3.request.start_date = moment.utc(itinerary_list[request_2.index].end_date).add(itinerary_list[request_2.index].duration_transport_after, "minutes").format();
                        if (itinerary_list[request_2.index + 1].step_type === "STEP") {
                            if (moment.utc(itinerary_list[request_2.index + 1].end_date).diff(moment.utc(request_3.request.start_date), "minutes") < 10) {
                                request_3.request.end_date = moment.utc(request_3.request.start_date).add(10, "minutes").format();
                            }
                        } else {
                            request_3.request.end_date = moment.utc(request_3.request.start_date).add(10, "minutes").format();
                        }
                    }
                }
            }
            let new_end_date = end_date;
            if (request_2.index !== null) {
                if (itinerary_list[request_2.index].step_type === "END") {
                    request_2.request.end_date = moment.utc(request_2.request.start_date).add(10, "minutes").format();
                }
                while (moment.utc(request_2.request.end_date).isAfter(new_end_date)) {
                    new_end_date = moment.utc(new_end_date).add(1, "d").format();
                }
            }
            if (request_3.index !== null) {
                if (itinerary_list[request_3.index].step_type === "END") {
                    request_3.request.end_date = moment.utc(request_3.request.start_date).add(10, "minutes").format();
                }
                while (moment.utc(request_3.request.end_date).isAfter(new_end_date)) {
                    new_end_date = moment.utc(new_end_date).add(1, "d").format();
                }
            }
            if (new_end_date !== end_date) {
                $.ajax({
                    method: "PATCH",
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
                    headers: headers,
                    data: JSON.stringify({end_date: new_end_date}),
                    success: function (data) {
                        Notify(t("itinerary.warning.trip_dates_updated"), {status: 'danger', pos: 'top-center'});
                        dispatch(SetDates({startDate: data.start_date, endDate: data.end_date}));
                        patch_everything(itinerary_list, dispatch, t, headers);
                    },
                    error: function (error) {
                        console.log(error);
                        Notify(t("itinerary.error.cant_apply_new_hour"), {status: 'danger', pos: 'top-center'});
                    }
                });
            } else {
                patch_everything(itinerary_list, dispatch, t, headers);
            }
        }
    }
}

export default (departure, arrival, index, currency, dispatch, itinerary_list, t, index_waiting, start_date, end_date, user) => {
    console.log("itinerary_list:", itinerary_list);
    let key = "";
    let id = null;
    let config = JSON.parse(localStorage.getItem("config"));
    for (let i = 0; i < config.keys.length; i++) {
        if (config.keys[i].identifier === "rome2rio") {
            key = config.keys[i].value;
            id = config.keys[i].id;
        }
    }
    const { pass_check, headers } = CheckBeforeRequest();
    $.ajax({
        method: "GET",
        headers: {
            "Accept": "application/json",
        },
        url:  API_HREF + "r2r/get_data/?_=" + encodeURIComponent("https://free.rome2rio.com/api/1.4/json/Search?key=" + key + departure + arrival + "&languageCode=" + i18n.language + "&currencyCode=" + currency),
        success: function (data) {
            $.ajax({
                method: "POST",
                url: `${API_HREF}key-usage/`,
                headers: headers,
                data: JSON.stringify({
                    key_id: id
                }),
                success: function (data) {
                    console.log(data);
                },
                error: function (error) {
                    console.log(error);
                }
            });
            if (data.routes.length > 0) {
                let config_r2r = JSON.parse(localStorage.getItem("config")).rome2rio;
                let config_r2r_for_user = null;
                if (config_r2r !== undefined && config_r2r !== null && config_r2r.length > 0) {
                    if (user.client_full.type === 2) {
                        for (let i = 0; i < config_r2r.length; i++) {
                            if (!config_r2r[i].type) {
                                config_r2r_for_user = config_r2r[i];
                            }
                        }
                        if (config_r2r_for_user === null) {
                            config_r2r_for_user = config_r2r[0];
                        }
                    } else {
                        for (let i = 0; i < config_r2r.length; i++) {
                            if (config_r2r[i].type) {
                                config_r2r_for_user = config_r2r[i];
                            }
                        }
                    }
                    for (let i = 0; i < config_r2r.length; i++) {
                        if (user.client_full.type === 2 && !config_r2r[i].type) {
                            config_r2r_for_user = config_r2r[i];
                        }
                    }
                }
                let best_routes = data.routes[0];
                let best_routes_biggest_transport_distance = best_routes.segments[0];
                for (let j = 1; j < best_routes.segments.length; j++) {
                    if (best_routes_biggest_transport_distance.distance < best_routes.segments[j].distance) {
                        best_routes_biggest_transport_distance = best_routes.segments[j];
                    }
                }
                for (let i = 1; i < data.routes.length; i++) {
                    let biggest_transport_distance = data.routes[i].segments[0];
                    for (let j = 1; j < data.routes[i].segments.length; j++) {
                        if (biggest_transport_distance.distance < data.routes[i].segments[j].distance) {
                            biggest_transport_distance = data.routes[i].segments[j];
                        }
                    }
                    if (config_r2r_for_user !== null && config_r2r_for_user[data.vehicles[biggest_transport_distance.vehicle].kind] && !config_r2r_for_user[data.vehicles[best_routes_biggest_transport_distance.vehicle].kind]) {
                        best_routes = data.routes[i];
                        best_routes_biggest_transport_distance = biggest_transport_distance;
                    } else if (best_routes.totalDuration > data.routes[i].totalDuration) {
                        if (config_r2r_for_user !== null) {
                            if (config_r2r_for_user[data.vehicles[biggest_transport_distance.vehicle].kind] && config_r2r_for_user[data.vehicles[best_routes_biggest_transport_distance.vehicle].kind]) {
                                best_routes = data.routes[i];
                                best_routes_biggest_transport_distance = biggest_transport_distance;
                            }
                        } else {
                            best_routes = data.routes[i];
                            best_routes_biggest_transport_distance = biggest_transport_distance;
                        }
                    }
                }
                let biggest_transport_distance = best_routes.segments[0];
                let plane = null;
                if (data.vehicles[biggest_transport_distance.vehicle].kind === "plane" && best_routes.segments[0].outbound !== undefined) {
                    plane = best_routes.segments[0];
                } else {
                    plane = null;
                }
                for (let i = 1; i < best_routes.segments.length; i++) {
                    if (biggest_transport_distance.distance < best_routes.segments[i].distance) {
                        biggest_transport_distance = best_routes.segments[i];
                        if (data.vehicles[biggest_transport_distance.vehicle].kind === "plane" && best_routes.segments[i].outbound !== undefined) {
                            plane = best_routes.segments[i];
                        } else {
                            plane = null;
                        }
                    }
                }
                let h = null;
                let m = null;
                if (plane !== null) {
                    h = Math.floor(plane.transitDuration / 60);
                    m = plane.transitDuration % 60;
                    h = h < 10 ? "0" + h : h;
                    m = m < 10 ? "0" + m : m;
                } else {
                    h = Math.floor(best_routes.totalDuration / 60);
                    m = best_routes.totalDuration % 60;
                    h = h < 10 ? "0" + h : h;
                    m = m < 10 ? "0" + m : m;
                }
                const transport = {
                    id: best_routes.distance + Math.random(),
                    type: 2,
                    vehicle: data.vehicles[biggest_transport_distance.vehicle],
                    vehicles: data.vehicles,
                    duration: (plane !== null ? plane.transitDuration : best_routes.totalDuration),
                    duration_string: h + "h" + m,
                    distance: best_routes.distance,
                    distance_string: parseInt(best_routes.distance).toLocaleString().replace(",", " ") + " km",
                    segments: best_routes.segments,
                    name_string: (data.vehicles[biggest_transport_distance.vehicle].kind === "plane" ? t("global.flight") : best_routes.name),
                    data: data,
                    selected: false,
                };
                let plane_departure_time = null;
                let plane_arrival_time = null;
                let add_days = 0;
                if (plane !== null) {
                    let new_outbound = [];
                    for (let i = 0; i < plane.outbound.length; i++) {
                        let has_xl = false;
                        for (let j = 0; j < plane.outbound[i].hops.length; j++) {
                            if (data.airlines[plane.outbound[i].hops[j].airline].code === "SE") {
                                has_xl = true;
                            }
                        }
                        if (!has_xl) {
                            new_outbound.push(plane.outbound[i])
                        }
                    }
                    plane.outbound = new_outbound;
                    if (plane.outbound !== undefined) {
                        plane_departure_time = plane.outbound[0].hops[0].depTime.split(":");
                        plane_arrival_time = plane.outbound[0].hops[plane.outbound[0].hops.length - 1].arrTime.split(":");
                        for (let i = 0; i < plane.outbound[0].hops.length; i++) {
                            if (plane.outbound[0].hops[i].dayChange !== undefined) {
                                add_days += plane.outbound[0].hops[i].dayChange;
                            }
                            if (plane.outbound[0].hops[i].layoverDayChange !== undefined) {
                                add_days += plane.outbound[0].hops[i].layoverDayChange;
                            }
                        }
                    } else {
                        plane = null;
                    }
                }
                let destination_end_date = null;
                let destination_start_date = null;
                if (plane !== null) {
                    if (index_waiting === 0) {
                        add_days_for_asia = add_days;
                    }
                    destination_end_date = moment.utc(itinerary_list[index].end_date).set({"hour": plane_departure_time[0], "minute": plane_departure_time[1]}).add((index_waiting === 1 ? add_days_for_asia : 0), "d");
                    destination_start_date = moment.utc(itinerary_list[index].end_date).set({"hour": plane_departure_time[0], "minute": plane_departure_time[1]}).add(add_days, "d").set({"hour": plane_arrival_time[0], "minute": plane_arrival_time[1]});
                } else {
                    destination_end_date = moment.utc(itinerary_list[index].end_date).set({"hour": "09", "minute": "00"});
                    let destination_end_date_copy = moment.utc(itinerary_list[index].end_date).set({"hour": "09", "minute": "00"});
                    if (destination_end_date_copy.diff(moment.utc(itinerary_list[index].start_date), "minutes") < 10) {
                        destination_end_date = moment.utc(itinerary_list[index].start_date).add(10, "minutes");
                        destination_end_date_copy = moment.utc(itinerary_list[index].start_date).add(10, "minutes");
                    }
                    destination_start_date = destination_end_date_copy.add(best_routes.totalDuration, "minutes");
                }
                if (index_waiting === 0 || index_waiting === 2) {
                    request_1.index = index;
                    request_1.request.end_date = destination_end_date.format();
                    request_1.request.r2r_json = transport;
                    request_1.request.r2r_api_version = "1.4";
                    request_1.request.duration_transport_after = transport.duration;
                    request_1.request.distance_transport_km = parseInt(transport.distance);
                    request_1.request.selected_transport = transport.duration + "-" + transport.distance;
                    request_2.index = index + 1;
                    request_2.request.start_date = destination_start_date.format();
                } else if (index_waiting === 1) {
                    request_2.request.end_date = destination_end_date.format();
                    request_2.request.r2r_json = transport;
                    request_2.request.r2r_api_version = "1.4";
                    request_2.request.duration_transport_after = transport.duration;
                    request_2.request.distance_transport_km = parseInt(transport.distance);
                    request_2.request.selected_transport = transport.duration + "-" + transport.distance;
                    request_3.index = index + 1;
                    request_3.request.start_date = destination_start_date.format();
                }
                callback(itinerary_list, dispatch, t, index_waiting, start_date, end_date);
            } else {
                const transport = {type: 3, id: Math.random()};
                if (index_waiting === 0 || index_waiting === 2) {
                    request_1.index = index;
                    request_1.request.r2r_json = transport;
                    request_2.index = index + 1;
                } else if (index_waiting === 1) {
                    request_2.request.r2r_json = transport;
                    request_3.index = index + 1;
                }
                callback(itinerary_list, dispatch, t, index_waiting, start_date, end_date);
            }
        },
        error: function (error) {
            console.log(error);
            const transport = {type: 3, id: Math.random()};
            if (index_waiting === 0 || index_waiting === 2) {
                request_1.index = index;
                request_1.request.r2r_json = transport;
                request_2.index = index + 1;
            } else if (index_waiting === 1) {
                request_2.request.r2r_json = transport;
                request_3.index = index + 1;
            }
            callback(itinerary_list, dispatch, t, index_waiting, start_date, end_date);
        }
    });
}