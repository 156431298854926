import React, { Fragment, useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Skeleton from '@material-ui/lab/Skeleton';
import CheckBoxFilled from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Divider from "@material-ui/core/Divider";
import NavigateBefore from '@material-ui/icons/NavigateBefore';

import { useSnackbar } from "notistack";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

// const DialogTitle = withStyles(styles)((props) => {
//     const { children, classes, onClose, ...other } = props;
//     return (
//         <MuiDialogTitle disableTypography className={classes.root} {...other}>
//           <Typography variant="h6">{children}</Typography>
//           {onClose ? (
//             <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//               <CloseIcon />
//             </IconButton>
//           ) : null}
//         </MuiDialogTitle>
//     );
// });

const useStyles = makeStyles({
    checked: {
        fill: "black"
    },
    dialogActions: {
        padding: 24
    },
    underline: {
        textDecoration: 'underline'
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    checkbox: {
        fontSize: "2rem"
    },
    capitalized: {
        textTransform: "capitalize"
    }
});

const DisplayAllFilters = ({open, setShowAll, provider_list, cars_categories, setUpdate, passengers, transmission, doors, setProviderFilters, user, resetFilter}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { enqueueSnackbar } = useSnackbar();
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const dispatch = useDispatch();
    const toggleFilters = () => {
        setShowAll(!open);
    }
    function handleChangeProvider(provider_index) {
        let tmp_provider = [];
        provider_list.map((provider, index) => {
            if (index === provider_index) {
                let copy_provider = Object.assign({}, provider);
                copy_provider.checked = !copy_provider.checked;
                provider = copy_provider;
            }
            tmp_provider.push(provider);
        });
        setProviderFilters(tmp_provider);
        // setUpdate(true);
    }
    function handleChangeCategory(category_index) {
        let tmp_category = [];
        cars_categories.map((category, index) => {
            if (index === category_index) {
                let copy_category = Object.assign({}, category);
                copy_category.to_render = !copy_category.to_render;
                category = copy_category;
            }
            tmp_category.push(category);
        });
        dispatch({type: "CARS_SET_CATEGORIES", payload: {categories: tmp_category}});
        // setUpdate(true);
    }
    const validateFilters = () => {
        setUpdate(true);
        toggleFilters();
    }
    return (
        <Dialog open={ open } onClose={ toggleFilters } fullWidth fullScreen={ fullScreen } maxWidth={ "md" }>
            <DialogTitle onClose={ toggleFilters }>
                <Grid container justify={ "space-between" } alignItems={ "center" }>
                    {
                        fullScreen ? (
                            <Grid item>
                                <IconButton edge={ "start" } onClick={ toggleFilters }><NavigateBefore/></IconButton> { t("menu.filter.filters") }
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item>
                                    { t("menu.filter.filters") }
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={ toggleFilters }><CloseIcon/></IconButton>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Typography className={ classes.bold } variant={ "h6" }>{ t("placeholder.nb_passengers") }</Typography>
                <Grid container>
                {
                    passengers.map((passenger, passenger_index) => {
                        return (
                            <Grid key={ passenger_index } item xs={ 6 }>
                                <FormControlLabel
                                    control={<Checkbox icon={ <CheckBoxOutlineBlank fontSize={ "large" }/> } checkedIcon={ <CheckBoxFilled className={ classes.checked } fontSize={ "large" }/> } checked={ passenger.checked } onChange={(e) => {
                                        passenger.func.call()
                                    }}/>}
                                    label={ passenger.name }
                                />
                            </Grid>
                        )
                    })
                }
                </Grid>
                <br/>
                <Divider/>
                <br/>
                <Typography className={ classes.bold } variant={ "h6" }>{ t("cars.transmission.transmission") }</Typography>
                <br/>
                <Grid container>
                {
                    transmission.map((transmission_elem, transmission_index) => {
                        return (
                            <Grid key={ transmission_index } item xs={ 6 }>
                                <FormControlLabel
                                    control={<Checkbox icon={ <CheckBoxOutlineBlank fontSize={ "large" }/> } checkedIcon={ <CheckBoxFilled className={ classes.checked } fontSize={ "large" }/> } checked={ transmission_elem.checked } onChange={(e) => {
                                        transmission_elem.func.call()
                                    }}/>}
                                    label={ transmission_elem.name }
                                />
                            </Grid>
                        )
                    })
                }
                </Grid>
                <br/>
                <Divider/>
                <br/>
                <Typography className={ classes.bold } variant={ "h6" }>{ t("cars.doors") }</Typography>
                <br/>
                <Grid container>
                {
                    doors.map((door, door_index) => {
                        return (
                            <Grid key={ door_index } item xs={ 6 }>
                                <FormControlLabel
                                    control={<Checkbox icon={ <CheckBoxOutlineBlank fontSize={ "large" }/> } checkedIcon={ <CheckBoxFilled className={ classes.checked } fontSize={ "large" }/> } checked={ door.checked } onChange={(e) => {
                                        door.func.call()
                                    }}/>}
                                    label={ door.name }
                                />
                            </Grid>
                        )
                    })
                }
                </Grid>
                <br/>
                <Divider/>
                <br/>
                <Typography className={ classes.bold } variant={ "h6" }>{ t("cars.renters") }</Typography>
                <br/>
                <Grid container>
                {
                    provider_list.length !== 0 && provider_list.map((provider, provider_index) => {
                        return (
                            <Grid item sm={6} xs={12} key={provider_index}>
                                <FormControlLabel
                                    control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ "small" }/> } checkedIcon={ <CheckBoxFilled className={ classes.checked } fontSize={ "small" }/> } checked={ provider.checked } onChange={() => { handleChangeProvider(provider_index) }} /> }
                                    label={
                                        <Fragment>
                                        {
                                            (quotation_code !== "marcovasco" && user.client_full.type !== 2) && (
                                                <Fragment>
                                                    <span>
                                                       { provider.provider_name}
                                                    </span>
                                                    {
                                                        provider.provider_name !== provider.subprovider_name && (
                                                            <span>
                                                            {
                                                                " - " + provider.subprovider_name
                                                            }
                                                            </span>
                                                        )
                                                    }
                                                </Fragment>
                                            )
                                        }
                                        {
                                            (quotation_code === "marcovasco" || user.client_full.type === 2) && (
                                                <span>
                                                    {provider.subprovider_name}
                                                </span>
                                            )
                                        }
                                        </Fragment> 
                                    }
                                />
                                {
                                    provider_index !== (provider_list.length - 1) && (
                                        <br/>
                                    )
                                }
                            </Grid>
                        )
                    })
                }
                {
                    provider_list.length === 0 && (
                        <Fragment>
                            <Grid item sm={6} xs={12}>
                                <Skeleton width={300} height={35} animation={"wave"}/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Skeleton width={300} height={35} animation={"wave"}/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Skeleton width={300} height={35} animation={"wave"}/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Skeleton width={300} height={35} animation={"wave"}/>
                            </Grid>
                        </Fragment>
                    )
                }
                </Grid>
                <br/>
                <Divider/>
                <br/>
                <Typography className={ classes.bold } variant={ "h6" }>{ t("cars.category_list") }</Typography>
                <br/>
                <Grid container>
                    {
                        cars_categories.length !== 0 && cars_categories.map((category, category_index) => {
                            return (
                                <Grid item sm={6} xs={12} key={category_index}>
                                    <FormControlLabel
                                        control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ "small" }/> } checkedIcon={ <CheckBoxFilled className={ classes.checked } fontSize={ "small" }/> } checked={ category.to_render } onChange={() => { handleChangeCategory(category_index) }} /> }
                                        label={
                                            <span className={classes.capitalized}>
                                               {category.name}
                                            </span>
                                        }
                                    />
                                    {
                                        category_index !== (cars_categories.length - 1) && (
                                            <br/>
                                        )
                                    }
                                </Grid>
                            )
                        })
                    }
                    {
                        cars_categories.length === 0 && (
                            <Fragment>
                                <Grid item sm={6} xs={12}>
                                    <Skeleton width={300} height={35} animation={"wave"}/>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Skeleton width={300} height={35} animation={"wave"}/>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Skeleton width={300} height={35} animation={"wave"}/>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Skeleton width={300} height={35} animation={"wave"}/>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogContent>
            <DialogActions className={ classes.dialogActions }>
                <Button className={ classes.underline } onClick={resetFilter}>{ t("global.cancel") }</Button>
                <Button variant={ "contained" } className={ classes.orangeButton } onClick={validateFilters}>{ t("quotation.ok") }</Button>
            </DialogActions>
        </Dialog>
    )
};
export default React.memo(DisplayAllFilters);