/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import CrmUserDetailContactField from './CrmUserDetailContactField';

import '../../crm.css';
import { useTranslation } from "react-i18next";

import { createUserMarker, deleteUserMarkers, getAllMarkers, patchUser } from '../../Functions/UserFunctions';

import { getCountries } from "../../Functions/CountryFunctions";
import { useDispatch, useSelector } from "react-redux";
import { SetCountries, SetSelectedUser } from "../../../../../Actions/Crm";

//ICONS
import { SetUserList } from "../../../../../Actions/User";

const CrmUserDetailContactDetails = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const countries = useSelector(store => store.crm.countries);
    const selected_user = useSelector(store => store.crm.selected_user);
    const user_client = useSelector(store => store.user.user);
    const user_list = useSelector(store => store.user.user_list);

    const [allMarkers, setAllMarkers] = useState([]);

    useEffect(() => {
        if (!countries || !countries.length) {
            getCountries((data) => {
                dispatch(SetCountries(data));
            });
        }
    }, [countries]);

    useEffect(() => {
        if (!allMarkers.length) {
            getAllMarkers(user_client.client, (resp) => {
                if (resp.data?.results) {
                    setAllMarkers(resp.data.results.map(m => m.name));
                }
            });
        }
    }, []);

    //UPDATE INFORMATIONS
    //for every fields (IMPORTANT: no validation)
    const updateGlobal = (newValue, field, callback) => {
        if (selected_user) {
            let user_temp = JSON.parse(JSON.stringify({ ...selected_user }));
            user_temp[field] = newValue;
            patchUser(user_client.client, user_temp, [field], () => {
                if (user_list) {
                    let user_list_updated = JSON.parse(JSON.stringify(user_list));
                    const index = user_list_updated.findIndex(u => u.id === user_temp.id);
                    if (index !== -1) {
                        user_list_updated[index] = user_temp;
                        dispatch(SetUserList(user_list_updated));
                    }
                }
                dispatch(SetSelectedUser(user_temp));
                if (callback !== null && callback !== undefined) {
                    callback();
                }
            });
        }
    };
    const updateMarkers = (newValue, callback) => {
        if (selected_user && newValue.length) {
            let user_temp = JSON.parse(JSON.stringify({ ...selected_user }));
            user_temp.markers = newValue.map(e => { return { name: e } });
            deleteUserMarkers(user_client.client, user_temp.id, (response) => {
                newValue?.forEach(e => {
                    createUserMarker(user_client.client, user_temp.id, { name: e }, (response) => {
                        let user_list_updated = JSON.parse(JSON.stringify(user_list));
                        if (user_list_updated) {
                            const index = user_list_updated.findIndex(u => u.id === user_temp.id);
                            if (index !== -1) {
                                user_list_updated[index] = user_temp;
                                dispatch(SetUserList(user_list_updated));
                            }
                        }
                        dispatch(SetSelectedUser(user_temp));
                        if (callback !== null && callback !== undefined) {
                            callback();
                        }
                    });
                });
            });
        }
    };


    if (selected_user) {
        return (
            <div className="contact-field-container">
                <CrmUserDetailContactField label={t("crm.description")} value={selected_user.biography} multiline={true} callback={(v) => updateGlobal(v.newValue, "biography", v.onFinish)} />
                <CrmUserDetailContactField label={t("crm.markers")}
                    value={selected_user.markers?.map(e => e.name)} tags={true} options={allMarkers}
                    displayValue={selected_user.markers?.map(e => e.name)?.join(', ')}
                    callback={(v) => updateMarkers(v.newValue, v.onFinish)} />
            </div>
        );
    }
    return null;
};
export default CrmUserDetailContactDetails;
