// Dependencies
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
// Core & Lab
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
// Icons
import Check from '@material-ui/icons/Check';

const useStyles = makeStyles({
    localeCheck: {
        color: 'green',
        marginLeft: 8,
        fontSize: '12px !important'
    }
});

const LocaleAutoTranslate = ({id, values, action, from_toolkit}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const locales = useSelector((state) => state.user.locales);
    const current_locale = useSelector((state) => state.user.current_locale);
    const translate_locale = useSelector((state) => state.user.translate_locale);
    const onCurrentLocaleChange = (event) => {
        dispatch({type: "USER_SET_TRANSLATE_LOCALE", payload: event.target.value});
    };
    const onRenderValue = () => {
        if (translate_locale.length > 0 && locales) {
            const text_value = [];
            for (let i = 0; i < locales.length; i++) {
                if (translate_locale.includes(locales[i].id)) {
                    text_value.push(locales[i].full_name);
                }
            }
            return text_value.join(', ');
        }
        return '';
    };
    const onTranslate = () => {
        let google_key = null;
        let key = "";
        let config = JSON.parse(localStorage.getItem("config"));
        for (let i = 0; i < config.keys.length; i++) {
            if (config.keys[i].identifier === "google_api") {
                google_key = config.keys[i].value;
                break;
            }
        }
        let source_code = '';
        if (current_locale !== null && locales) {
            for (let i = 0; i < locales.length; i++) {
                if (locales[i].id === current_locale) {
                    source_code = locales[i].language_code;
                }
            }
        }
        if (current_locale !== null) {
            translate_locale.map((translate_locale_id) => {
                if (translate_locale_id !== current_locale) {
                    let target_code = null;
                    if (translate_locale !== null && locales) {
                        for (let i = 0; i < locales.length; i++) {
                            if (locales[i].id === translate_locale_id) {
                                target_code = locales[i];
                            }
                        }
                    }
                    if (target_code !== null) {
                        axios({
                            method: 'GET',
                            url: `https://translation.googleapis.com/language/translate/v2`,
                            params: {
                                key: google_key,
                                q: values[current_locale],
                                source: source_code,
                                target: target_code.language_code
                            }
                        }).then((response) => {
                            if (response.data.data.translations[0].translatedText) {
                                enqueueSnackbar(`${t('shared.text-translated-to')} : ${target_code?.full_name}`, {variant: 'success'});
                                if (from_toolkit !== undefined && from_toolkit) {
                                    dispatch(action({id: translate_locale_id, value: response.data.data.translations[0].translatedText}));
                                } else {
                                    dispatch(action(translate_locale_id, response.data.data.translations[0].translatedText));
                                }
                            } else {
                                enqueueSnackbar(`${t('shared.text-not-translated-to')} : ${target_code?.full_name}`, {variant: 'error'});
                            }
                        }).catch((error) => {
                            console.log('traduction error : ', error);
                            enqueueSnackbar(`${t('shared.text-not-translated-to')} : ${target_code?.full_name}`, {variant: 'error'});
                        });
                    }
                }
            });
        }
    };
    if (current_locale !== null) {
        return (
            <Grid container alignItems={ 'center' } spacing={ 2 }>
                <Grid item xs={ 6 }>
                    <FormControl fullWidth variant={ 'outlined' }>
                        <InputLabel id={ `${id}-translate-locale` }>{ t('shared.translate-in') }</InputLabel>
                        <Select labelId={ `${id}-translate-locale` } label={ t('shared.translate-in') } multiple value={ translate_locale } onChange={ onCurrentLocaleChange } renderValue={ onRenderValue }>
                            { locales && locales.map((locale) => current_locale !== locale.id && <MenuItem key={ `${id}-translate-locale-${locale.id}` } value={ locale.id }>{ locale.full_name }{ !!values[locale.id] && <Check className={ classes.localeCheck }/> }</MenuItem>) }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={ 6 }>
                    <Button disabled={ translate_locale.length === 0 } onClick={ onTranslate }>{ t('shared.translate') }</Button>
                </Grid>
            </Grid>
        );
    }
    return null;
};

export default LocaleAutoTranslate;