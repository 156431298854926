import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
    formControlRoot: {
        // minWidth: 86,
        "& .mui-jss-MuiInputLabel-outlined": {
            transform: 'translate(14px, 13px) scale(1)'
        },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },
        "& .mui-jss-MuiOutlinedInput-input": {
            // padding: '9.5px 35px'
            paddingTop: '9.5px',
		    paddingBottom: '9.5px',
		    paddingRight: '26px'
        }
    },
    formControlRoot1: {
	    "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(14px, 20px) scale(1)'
	    },
	    "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    },
	    "& .mui-jss-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
	    	padding: '0'
	    },
	    "& .mui-jss-MuiInputBase-root.mui-jss-MuiOutlinedInput-root.mui-jss-MuiAutocomplete-inputRoot.mui-jss-MuiInputBase-fullWidth.mui-jss-MuiInputBase-formControl.mui-jss-MuiInputBase-adornedEnd.mui-jss-MuiOutlinedInput-adornedEnd" : {
	    	paddingRight: '10px'
	    },
	    "& .mui-jss-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .mui-jss-MuiAutocomplete-input": {
	    	padding: '17px 4px'
	    }
  	},
    inputBorder1: {
        "&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: '0',
            borderRight: '0'
        }
    },
    inputBorder2: {
        "& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: '0px 8px 8px 0px',
        }
    },
    inputBorder3: {
        borderRadius: '8px',
        width: '100%',
        maxWidth: '100%'
    },
    noBorderLeft: {
        borderLeft: "none"
    },
    noBorder: {
        borderLeft: "none",
        borderRight: "none"
    }
}));

const RenderTime = ({ type, search, setSearch }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between(768, 1024));
    const { t } = useTranslation();
    const [time, setTime] = useState(t("global.noon"));
    const timeArray = [];
    for (let i = 0; i < 24; i++) {
        for (let j = 0; j <= 3; j++) {
            if (i === 0 && j === 0) {
                timeArray.push(t("global.midnight"));
            }
            else if (i === 12 && j === 0) {
                timeArray.push(t("global.noon"));
            }
            else {
                timeArray.push((i < 10 ? '0' + i : i) + 'h' + (j === 0 ? "00" : (j === 1 ? "15" : (j === 2 ? "30" : "45"))));
            }
        }
    }
    useEffect(() => {
	    if (type === "origin") {
	    	setTime(search.origin_hour);
	    }
	    else {
	    	setTime(search.return_hour);
	    }
    }, [search.origin_hour, search.return_hour]);
    function handleChange(e) {
        let tmp_search = Object.assign({}, search);
        if (type === "origin") {
            tmp_search.origin_hour = e.target.value;
        }
        else {
            tmp_search.return_hour = e.target.value;
        }
        setSearch(tmp_search);
        setTime(e.target.value);
    }
    return (
    // <FormControl fullWidth variant={"outlined"} className={`${matches ? classes.formControlRoot : classes.formControlRoot1} ` + `${matches ? (type === "origin"  ? (search.is_return ? classes.inputBorder1 : classes.inputBorder2) : classes.inputBorder2) : classes.inputBorder3}`}>
    // 	<InputLabel id={"render-time-" + type}>{t("global.hour")}</InputLabel>
	    //    	<Select
	    //    		labelId={"render-time-" + type}
	    //    		id={"select-render-time-" + type}
	    //      	value={time}
	    //      	onChange={(e) => handleChange(e)}
	    //      	label={t("global.hour")}
    // 		InputProps={{
    // 			classes:{notchedOutline: classes.noBorderLeft}
    // 		}}
	    //    	>
	    //    	{
	    //    		timeArray.map((time, time_index) => {
	    //    			return (
	    //    				<MenuItem key={time_index} value={time}>{time}</MenuItem>
	    //    			)
	    //    		})
	    //    	}
	    //    	</Select>
    // </FormControl>
        <TextField
            select
            fullWidth
            variant={"outlined"}
            label={t("global.hour")}
            style={{ background: 'white' }}
            value={time}
            className={`${matches ? classes.formControlRoot : classes.formControlRoot1} ` + `${matches ? (type === "origin"  ? (search.is_return ? classes.inputBorder1 : classes.inputBorder2) : classes.inputBorder2) : classes.inputBorder3}`}
            onChange={(e) => handleChange(e)}
            InputProps={{
                classes:{notchedOutline: (type === "origin"  ? (search.is_return ? (matches ? classes.noBorder : "") : ((matches) ? classes.noBorderLeft : "")) : ((matches) ? classes.noBorderLeft : "")) }
            }}
        >
            {
                timeArray.map((time, time_index) => {
                    return (
                        <MenuItem key={time_index} value={time}>{time}</MenuItem>
                    );
                })
            }
        </TextField>
    )
}
export default React.memo(RenderTime);
