import i18n from "../../../../i18n.jsx";
import GetCookie from "../../../Common/Functions/GetCookie";

//---- Actions ----//
import { SetClient } from "../../../../Actions/User";
import axios from "axios";

export default (dispatch, user) => {
    if (GetCookie("token") !== null) {
        $.ajax({
            method: "GET",
            headers: {
                "Authorization": "Token " + GetCookie("token"),
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Accept-Language": i18n.language
            },
            url: `${API_HREF}client/${user.client}/`,
            success: function (data) {
                dispatch(SetClient(data));
            },
            error: function (error) {
                console.log(error);
            }
        });        
    }
}
