import { computeTransportDuration } from "./computeTransportDuration";
import { Itinerary } from "../objects/itinerary";
import { ItineraryInput } from "../objects/itineraryState";
import { Transport } from "../objects/transport";
import { Place, Route } from "../objects/r2rSearchResponse";
import { getPlaneStartDate } from "./getPlaneStartDate";

export const transportVehicles: NonNullable<NonNullable<Itinerary['r2r_json']>['vehicles']> = [
    {
        kind: 'plane',
        name: 'Avion'
    },
    {
        kind: 'helicopter',
        name: 'Hélicoptère'
    },
    {
        kind: 'air-taxi',
        name: 'Air taxi'
    },
    {
        kind: 'ferry',
        name: 'Ferry'
    },
    {
        kind: 'train',
        name: 'Train'
    },
    {
        kind: 'subway',
        name: 'Métro'
    },
    {
        kind: 'tram',
        name: 'Tram'
    },
    {
        kind: 'bus',
        name: 'Bus'
    },
    {
        kind: 'taxi',
        name: 'Taxi'
    },
    {
        kind: 'towncar',
        name: 'Voiture citadine'
    },
    {
        kind: 'car',
        name: 'Voiture'
    },
    {
        kind: 'bike',
        name: 'Moto'
    },
    {
        kind: 'shuttle',
        name: 'Navette'
    },
    {
        kind: 'rideshare',
        name: 'Covoiturage'
    },
    {
        kind: 'bicycle',
        name: 'Vélo'
    },
    {
        kind: 'foot',
        name: 'Marche à pied'
    },
    {
        kind: 'unknown',
        name: 'Inconnu'
    },
    {
        kind: 'other',
        name: 'Autre'
    }
];

export function selectStepTransport(
    options: {
        fromTimezone: string,
        toTimezone: string,
        step: ItineraryInput,
        nextStep: ItineraryInput,
        nextStepCoordinates: NonNullable<NonNullable<Itinerary['r2r_json']>['nextStepCoordinates']>,
        transport: Transport['alternatives'][number],
        r2rPlaces: Place[],
        r2rRoutes: Route[],
        isCustom: boolean
    }
): void {
    const duration = computeTransportDuration(options.transport);
    const distance = options.transport.steps.reduce((prev, current) => {
        return prev + current.distance;
    }, 0);
    let h: string | number = Math.floor(duration / 3600);
    let m: string | number = Math.floor((duration % 3600) / 60);
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;

    const endDate = window.moment.tz(
        options.step.end_date.replace('Z', ''),
        options.fromTimezone
    );
    const nextStartDate = endDate.clone().add(duration, 'seconds').tz(options.toTimezone);
    options.step.r2r_api_version = '2.0';
    if (!options.step.r2r_json) {
        options.step.r2r_json = {};
    }
    options.step.r2r_json.isCustom = options.isCustom;
    options.step.r2r_json.type = 2;
    options.step.r2r_json.duration = window.moment.utc(
        nextStartDate.format('YYYY-MM-DD HH:mm')
    ).diff(
        window.moment.utc(
            endDate.format('YYYY-MM-DD HH:mm')
        ),
        'minutes'
    );
    options.step.r2r_json.duration_string = h + 'h' + m;
    if (options.step.r2r_json.data) {
        options.step.r2r_json.data.places = options.r2rPlaces;
    }
    options.step.r2r_json.data = {
        places: options.r2rPlaces,
        routes: options.r2rRoutes,
        agencies: [],
        aircrafts: [],
        airlines: [],
        currencyCode: 'EUR',
        elapsedTime: '0',
        languageCode: 'fr',
        vehicles: []
    };
    options.step.r2r_json.vehicles = transportVehicles;
    options.step.r2r_json.operatingDays = options.transport.operatingDays;
    options.step.r2r_json.segments = options.transport.steps.map((item) => ({
        segmentKind: item.vehicle.kind === 'plane' ? 'air' : 'surface',
        agencies: [],
        arrPlace: item.arrPlace,
        depPlace: item.depPlace,
        distance: item.distance,
        indicativePrices: [],
        path: google.maps.geometry.encoding.encodePath(item.path),
        stops: [],
        transferDuration: 0,
        transitDuration: 0,
        vehicle: transportVehicles.findIndex((vehicle) => vehicle.kind === item.vehicle.kind)
    }));
    options.step.r2r_json.data = {
        agencies: options.step.r2r_json.data?.agencies ?? [],
        aircrafts: options.step.r2r_json.data?.aircrafts ?? [],
        airlines: options.step.r2r_json.data?.airlines ?? [],
        currencyCode: options.step.r2r_json.data?.currencyCode ?? 'EUR',
        elapsedTime: options.step.r2r_json.data?.elapsedTime ?? '0',
        languageCode: options.step.r2r_json.data?.languageCode ?? 'fr',
        places: options.step.r2r_json.data?.places ?? [],
        routes: (options.step.r2r_json.data?.routes ?? []).map((route) => {
            return {
                ...route,
                segments: []
            };
        }),
        vehicles: transportVehicles
    };
    options.step.r2r_json.vehicle = transportVehicles[
        [...options.step.r2r_json.segments].sort((a, b) => {
            return a.vehicle < b.vehicle ? -1 : 1;
        })[0]?.vehicle ?? 0
    ];
    options.step.distance_transport_km = Math.floor(distance / 1000);
    options.step.r2r_json.nextStepCoordinates = options.nextStepCoordinates;
    options.step.r2r_json.noTransport = false;

    const firstAirStep = options.transport.steps.find((item) => {
        return item.vehicle.kind === 'plane';
    });

    const nextEndDate = window.moment.utc(options.step.end_date).set(
        'hours',
        9
    ).set(
        'minutes',
        0
    ).set(
        'seconds',
        0
    );

    if (firstAirStep) {
        const departure = getPlaneStartDate(options.transport, options.step.end_date);
        options.step.end_date = departure.toISOString();
    } else if (options.isCustom) {
        const mainStep = options.transport.steps[0]!;
        const departure = window.moment.utc(
            options.step.end_date
        ).set(
            'hours',
            0
        ).set(
            'minutes',
            0
        ).set(
            'seconds',
            0
        ).add(mainStep.departureTime, 'seconds');
        options.step.end_date = departure.toISOString();
    } else if (
        window.moment.utc(options.step.start_date).isBefore(nextEndDate)
    ) {
        options.step.end_date = nextEndDate.toISOString();
    }
}
