import React, { useContext, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Chip,
    ClickAwayListener,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    styled
} from "@mui/material";
import { green } from "@mui/material/colors";
import {
    Block,
    Cancel,
    Check,
    CheckCircle,
    Close,
    /* ConfirmationNumberOutlined, */
    ContactSupportOutlined,
    DateRangeOutlined,
    Delete,
    Edit,
    Error,
    HourglassEmpty,
    MonetizationOnOutlined,
    Pending,
    PlaceOutlined,
    Save,
    StarBorderOutlined,
    ThumbDown,
    ThumbUp,
    WatchLaterOutlined
} from "@mui/icons-material";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { ProviderQuotationDeletePropositionModal } from "./ProviderQuotationDeletePropositionModal";
import { ProviderQuotationProductCommentModal } from "./ProviderQuotationProductCommentModal";
import LightTooltip from "../Menu/MaterialTripList/utils/tooltip/lightTooltip";
import { ProviderQuotationContext } from "./utils/providerQuotationContext";
import { useProductDays } from "../CartMaterial/utils/productDays";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { useProductPriceChange } from "./network/productPriceChange";
import { useShowError } from "../Utils/showError";
/* import { useProductBookingStatusChange } from "../ProviderBooking/network/productBookingStatusChange"; */
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import CheckResponse from "../Flight/FlightSelected/Functions/CheckResponse";
import { ProviderQuotationProductStatus } from "./objects/providerQuotationProductStatus";
import { StatusBooking } from "../Itinerary/objects/statusBooking";
import { BookingStatus } from "../Itinerary/objects/bookingStatus";
import { Currency } from "../../Reducers/objects/currency";
import { Destination } from "../Itinerary/objects/destination";
import { AppState } from "../../Reducers/Reducers";

export type ProviderQuotationProductProps = {
    id: number,
    productType: number,
    isProposition: boolean,
    isProviderCreated: boolean,
    status: ProviderQuotationProductStatus | null,
    agentComment: string | null,
    providerComment: string | null,
    bookingStatus: BookingStatus | null
    image: string,
    from: string,
    destination: Destination | null,
    to: string,
    startDate: string,
    title: string,
    price: number,
    masterPriceId: number | null,
    customRate: boolean,
    customRateType: 'FIX' | 'PER',
    customValue: number,
    currency: Currency | null,
    counterOfferOfId: number | null,
    counterOfferOf: Omit<ProviderQuotationProductProps, 'counterOfferOf'> | null,
    replacedBy: Omit<ProviderQuotationProductProps, 'replacedBy'> | null,
    bookingNumber: string,
    isCustom: boolean,
    type: keyof ReturnType<typeof useCartProducts> | 'manual'
}

type Props = ProviderQuotationProductProps & {
    onOpenManualProductForm: () => void,
    onChangeStatus: (
        status: ProviderQuotationProductStatus,
        providerComment: string | null
    ) => void,
    onChangeCounterOfferProduct: (id: number) => void
}

export function ProviderQuotationProduct(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const itineraryList = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [editPrice, setEditPrice] = useState(false);
    /* const [editBookingReference, setEditBookingReference] = useState(false); */
    const [priceInput, setPriceInput] = useState('');
    /* const [bookingReferenceInput, setBookingReferenceInput] = useState(''); */
    const [openCommentModal, setOpenCommentModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const context = useContext(ProviderQuotationContext);
    const commentModalAction = useRef<'accept' | 'refuse' | 'new-proposal' | null>(null);
    const showError = useShowError();
    const updatePrice = useProductPriceChange({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(product, data) {
            if (trip) {
                switch (product.type) {
                    case 'flights': {
                        dispatch({
                            type: 'FLIGHT_EDIT_CART_BY_ID',
                            payload: CheckResponse([data], trip.end_date)[0]!
                        });
                        break;
                    }
                    case 'cars': {
                        dispatch({
                            type: 'CAR_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    case 'accommodations': {
                        dispatch({
                            type: 'ACCOMMODATION_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    case 'transfers': {
                        dispatch({
                            type: 'TRANSFER_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    case 'pois': {
                        dispatch({
                            type: 'POI_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    default: {
                        dispatch({
                            type: 'CART_EDIT_MANUAL_ITEM',
                            payload: data
                        });
                        break;
                    }
                }
                setEditPrice(false);
            }
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    /* const changeBookingStatus = useProductBookingStatusChange({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(product) {
            if (trip) {
                switch (props.type) {
                    case 'flights': {
                        dispatch({
                            type: 'FLIGHT_EDIT_CART_BY_ID',
                            payload: CheckResponse([product], trip.end_date)[0]!
                        });
                        break;
                    }
                    case 'cars': {
                        dispatch({
                            type: 'CAR_EDIT_CART_BY_ID',
                            payload: product
                        });
                        break;
                    }
                    case 'accommodations': {
                        dispatch({
                            type: 'ACCOMMODATION_EDIT_CART_BY_ID',
                            payload: product
                        });
                        break;
                    }
                    case 'transfers': {
                        dispatch({
                            type: 'TRANSFER_EDIT_CART_BY_ID',
                            payload: product
                        });
                        break;
                    }
                    case 'pois': {
                        dispatch({
                            type: 'POI_EDIT_CART_BY_ID',
                            payload: product
                        });
                        break;
                    }
                    default: {
                        dispatch({
                            type: 'CART_EDIT_MANUAL_ITEM',
                            payload: product
                        });
                        break;
                    }
                }
                setEditBookingReference(false);
            }
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    }); */
    const getDays = useProductDays();
    const steps = useMemo(() => {
        return itineraryList.filter((item) => {
            return item.step_type === 'STEP';
        }).sort(sortItinerary).filter((step) => {
            return getDays(step.start_date)[0] &&
                getDays(step.start_date)[0] === getDays(props.startDate)[0];
        });
    }, [itineraryList, getDays, locale]);
    const destinations = useMemo(() => {
        return steps.map((step) => {
            return (
                step.destination?.data?.localization.find((item) => {
                    return item.locale === locale;
                })?.name ??
                step.destination?.data?.international_name ??
                ''
            ).split(',')[0] ?? '';
        });
    }, [steps]);
    const from = window.moment.utc(props.from);
    const to = window.moment.utc(props.to);
    const day = getDays(props.startDate)[0];

    const onEditPrice = () => {
        setEditPrice(true);
        setPriceInput(props.price.toString());
    };

    /* const onEditBookingReference = () => {
        setEditBookingReference(true);
        setBookingReferenceInput(props.bookingNumber);
    }; */

    const onChangeStatus = (status: ProviderQuotationProductStatus['provider'], comment?: string | null) => {
        if (props.status) {
            props.onChangeStatus(
                {
                    ...props.status,
                    provider: status
                },
                typeof comment === 'undefined' ?
                    props.providerComment :
                    comment
            );
        }
    };

    const onSavePrice = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | MouseEvent | TouchEvent) => {
        event.stopPropagation();
        if (
            context.tripId &&
            context.version &&
            props.masterPriceId
        ) {
            const price = parseFloat(priceInput);
            updatePrice(
                {
                    tripId: context.tripId,
                    version: context.version,
                    tripToken: context.tripToken
                },
                {
                    id: props.id,
                    type: props.type,
                    isCustom: props.isCustom,
                    price: !isNaN(price) ? price : 0,
                    masterPriceId: props.masterPriceId,
                    customRate: props.customRate,
                    customRateType: props.customRateType,
                    customValue: props.customValue
                }
            );
        }
    };

    /* const onSaveBookingReference = () => {
        if (context.tripId && context.version) {
            changeBookingStatus({
                tripId: context.tripId,
                version: context.version,
                productId: props.id,
                ref: bookingReferenceInput,
                status: props.bookingStatus?.status_booking ?? StatusBooking.NONE
            });
        }
    }; */

    const onAccept = () => {
        commentModalAction.current = 'accept';
        setOpenCommentModal(true);
    };

    const onRefuse = () => {
        commentModalAction.current = 'refuse';
        setOpenCommentModal(true);
    };

    const onMakeNewProposition = () => {
        commentModalAction.current = 'new-proposal';
        setOpenCommentModal(true);
    };

    const onEditComment = () => {
        commentModalAction.current = props.status?.provider === 'accepted' ?
            'accept' :
            'refuse';
        setOpenCommentModal(true);
    };

    const onConfirmCommentModal = (comment: string | null) => {
        switch (commentModalAction.current) {
            case 'accept': {
                onChangeStatus('accepted', comment);
                break;
            }
            case 'refuse': {
                onChangeStatus('refused', comment);
                break;
            }
            case 'new-proposal': {
                props.onOpenManualProductForm();
                dispatch({
                    type: 'CART_MANUAL_PRODUCT_TYPE',
                    payload: {
                        value: props.productType === 6 ?
                            61 :
                            props.productType,
                        default_pictures: []
                    }
                });
                dispatch({ type: 'CART_CREATE_NEW_MANUAL_PRODUCT' });
                if (steps[0]?.id) {
                    dispatch({
                        type: 'CART_MANUAL_PRODUCT_DESTINATION_ASSOCIATED',
                        payload: {
                            value: steps[0].id,
                            itinerary_list: itineraryList
                        }
                    });
                }
                onChangeStatus('refused', comment);
                props.onChangeCounterOfferProduct(props.id);
            }
        }
    };

    return (
        <>
            <Card sx={{ display: 'flex', width: '100%' }}>
                <CardMedia
                    component="img"
                    image={props.image}
                    sx={{ width: 250 }}
                />
                <CardContent sx={{ flex: 1 }}>
                    <Box sx={{ marginBottom: 1 }}>
                        <Typography fontWeight="bold">
                            {
                                day &&
                                t(
                                    'cart-material.provider-quotation-product-title',
                                    {
                                        day: getDays(props.startDate)[0],
                                        title: props.title
                                    }
                                )
                            }
                            {
                                !day &&
                                props.title
                            }
                            {
                                props.isProposition &&
                                <span style={{ fontWeight: 100, color: green[600] }}>
                                    {' '}({t('cart-material.provider-quotation-proposition-marker')})
                                </span>
                            }
                        </Typography>
                        {
                            props.counterOfferOf &&
                            <Chip
                                size="small"
                                label={
                                    t(
                                        'cart-material.provider-quotation-proposition-replaces',
                                        { title: props.counterOfferOf.title }
                                    )
                                }
                            />
                        }
                        {
                            props.replacedBy &&
                            <Chip
                                size="small"
                                label={
                                    t(
                                        'cart-material.provider-quotation-proposition-replaced-by',
                                        { title: props.replacedBy.title }
                                    )
                                }
                            />
                        }
                    </Box>
                    {
                        props.destination &&
                        <Stack direction="row" spacing={1}>
                            <PlaceOutlined fontSize="small" />
                            <Typography variant="caption">
                                {
                                    props.destination?.data?.localization.find((item) => {
                                        return item.locale === locale;
                                    })?.name ??
                                    props.destination?.data?.international_name ??
                                    ''
                                }
                            </Typography>
                        </Stack>
                    }
                    {
                        from.isValid() &&
                        <Stack direction="row" spacing={1}>
                            <DateRangeOutlined fontSize="small" />
                            <Typography variant="caption">
                                {
                                    from.isValid() && to.isValid() &&
                                    t(
                                        'cart-material.cart-construction-products-table-date',
                                        {
                                            from: from.format('LL'),
                                            to: to.format('LL')
                                        }
                                    )
                                }
                                {
                                    from.isValid() && !to.isValid() &&
                                    from.format('LL')
                                }
                            </Typography>
                        </Stack>
                    }
                    {
                        from.isValid() && to.isValid() &&
                        <Stack direction="row" spacing={1}>
                            <WatchLaterOutlined fontSize="small" />
                            <Typography variant="caption">
                                {
                                    t(
                                        'cart-material.provider-quotation-product-hourly',
                                        {
                                            from: from.format('HH:mm'),
                                            to: to.format('HH:mm')
                                        }
                                    )
                                }
                            </Typography>
                        </Stack>
                    }
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <MonetizationOnOutlined fontSize="small" />
                        {
                            !editPrice &&
                            <>
                                <Typography variant="caption">
                                    {
                                        t(
                                            'cart-material.provider-quotation-price',
                                            {
                                                price: new Intl.NumberFormat(
                                                    i18n.language,
                                                    {
                                                        style: 'currency',
                                                        currency: props.currency?.iso_code ?? 'EUR'
                                                    }
                                                ).format(props.price)
                                            }
                                        )
                                    }
                                </Typography>
                                <IconButton
                                    size="small"
                                    onClick={onEditPrice}
                                    sx={{ fontSize: 16 }}
                                >
                                    <Edit fontSize="inherit" />
                                </IconButton>
                            </>
                        }
                        {
                            editPrice &&
                            <>
                                <ClickAwayListener
                                    onClickAway={onSavePrice}
                                    mouseEvent="onMouseDown"
                                >
                                    <PriceInput
                                        value={priceInput}
                                        onChange={(event) => setPriceInput(event.target.value)}
                                    />
                                </ClickAwayListener>
                                <IconButton
                                    size="small"
                                    onMouseDown={onSavePrice}
                                    sx={{ fontSize: 16 }}
                                >
                                    <Save fontSize="inherit" />
                                </IconButton>
                            </>
                        }
                    </Stack>
                    {/* <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ marginBottom: 1 }}
                    >
                        <ConfirmationNumberOutlined fontSize="small" />
                        {
                            !editBookingReference &&
                            <>
                                <Typography variant="caption">
                                    {
                                        t(
                                            'cart-material.provider-quotation-booking-number',
                                            { no: props.bookingNumber }
                                        )
                                    }
                                </Typography>
                                <IconButton
                                    size="small"
                                    onClick={onEditBookingReference}
                                    sx={{ fontSize: 16 }}
                                >
                                    <Edit fontSize="inherit" />
                                </IconButton>
                            </>
                        }
                        {
                            editBookingReference &&
                            <>
                                <ClickAwayListener
                                    onClickAway={onSaveBookingReference}
                                    mouseEvent="onMouseDown"
                                >
                                    <PriceInput
                                        value={bookingReferenceInput}
                                        onChange={(event) => setBookingReferenceInput(event.target.value)}
                                    />
                                </ClickAwayListener>
                                <IconButton
                                    size="small"
                                    onMouseDown={onSaveBookingReference}
                                    sx={{ fontSize: 16 }}
                                >
                                    <Save fontSize="inherit" />
                                </IconButton>
                            </>
                        }
                    </Stack> */}
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        sx={{ marginBottom: 1 }}
                    >
                        <StarBorderOutlined fontSize="small" />
                        <Typography variant="caption">
                            {t('cart-material.provider-quotation-booking-status')}
                        </Typography>
                        {
                            !props.bookingStatus &&
                            <Chip
                                size="small"
                                label={t('cart-material.cart-construction-unbooked')}
                                sx={{ color: 'inherit' }}
                            />
                        }
                        {
                            props.bookingStatus?.status_booking === StatusBooking.NONE &&
                            <Chip
                                size="small"
                                label={t('cart-material.cart-construction-no-status')}
                                sx={{ color: 'inherit' }}
                            />
                        }
                        {
                            props.bookingStatus?.status_booking === StatusBooking.CONFIRMED &&
                            <Chip
                                label={t('cart-material.cart-construction-confirmed')}
                                size="small"
                                sx={{
                                    backgroundColor: '#5cb85c',
                                    color: '#fff'
                                }}
                                icon={<CheckCircle color="inherit" />}
                            />
                        }
                        {
                            props.bookingStatus?.status_booking === StatusBooking.CANCELLED &&
                            <Chip
                                label={t('cart-material.cart-construction-cancelled')}
                                size="small"
                                sx={{
                                    backgroundColor: '#ed3c3f',
                                    color: '#fff'
                                }}
                                icon={<Block color="inherit" />}
                            />
                        }
                        {
                            (
                                props.bookingStatus?.status_booking === StatusBooking.PENDING ||
                                props.bookingStatus?.status_booking === StatusBooking.WAITING
                            ) &&
                            <Chip
                                label={t('cart-material.cart-construction-waiting')}
                                size="small"
                                color="warning"
                                icon={<Pending color="inherit" />}
                            />
                        }
                        {
                            props.bookingStatus?.status_booking === StatusBooking.REFUSED &&
                            <Chip
                                label={t('cart-material.cart-construction-refused')}
                                size="small"
                                sx={{
                                    backgroundColor: '#ed3c3f',
                                    color: '#fff'
                                }}
                                icon={<Cancel color="inherit" />}
                            />
                        }
                        {
                            props.bookingStatus?.status_booking === StatusBooking.ERROR &&
                            <Chip
                                label={t('cart-material.cart-construction-error')}
                                size="small"
                                sx={{
                                    backgroundColor: '#ed3c3f',
                                    color: '#fff'
                                }}
                                icon={<Error color="inherit" />}
                            />
                        }
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ marginTop: 1.5 }}
                    >
                        <Box sx={{ width: '100%' }}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                sx={{ marginBottom: 1 }}
                            >
                                {
                                    !props.status?.agent &&
                                    <HourglassEmpty />
                                }
                                {
                                    props.status?.agent === 'accepted' &&
                                    <ThumbUp color="success" fontSize="small" />
                                }
                                {
                                    props.status?.agent === 'refused' &&
                                    <ThumbDown color="error" fontSize="small" />
                                }
                                <Typography variant="caption">
                                    {
                                        !props.status?.agent &&
                                        t('cart-material.provider-quotation-agent-question')
                                    }
                                    {
                                        props.status?.agent === 'accepted' &&
                                        t('cart-material.provider-quotation-product-confirmed-by-agent')
                                    }
                                    {
                                        props.status?.agent === 'refused' &&
                                        t('cart-material.provider-quotation-product-refused-by-agent')
                                    }
                                </Typography>
                                {
                                    (props.agentComment?.trim().length ?? 0) > 0 &&
                                    <LightTooltip
                                        title={
                                            <Box sx={{ padding: 2 }}>
                                                <Typography
                                                    fontWeight="bold"
                                                    sx={{
                                                        marginBottom: 1,
                                                        textDecoration: 'underline'
                                                    }}
                                                >
                                                    {t('cart-material.provider-quotation-comment-agent-tooltip-title')}
                                                </Typography>
                                                <Typography>
                                                    {props.agentComment}
                                                </Typography>
                                            </Box>
                                        }
                                        arrow
                                    >
                                        <ContactSupportOutlined
                                            fontSize="inherit"
                                            sx={{ cursor: 'help' }}
                                        />
                                    </LightTooltip>
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                {
                                    !props.status?.provider &&
                                    <HourglassEmpty />
                                }
                                {
                                    props.status?.provider === 'accepted' &&
                                    <ThumbUp color="success" fontSize="small" />
                                }
                                {
                                    props.status?.provider === 'refused' &&
                                    <ThumbDown color="error" fontSize="small" />
                                }
                                <Typography variant="caption">
                                    {
                                        !props.status?.provider &&
                                        t('cart-material.provider-quotation-action-question')
                                    }
                                    {
                                        props.status?.provider === 'accepted' &&
                                        t('cart-material.provider-quotation-product-confirmed')
                                    }
                                    {
                                        props.status?.provider === 'refused' &&
                                        t('cart-material.provider-quotation-product-refused')
                                    }
                                </Typography>
                                {
                                    props.status?.provider &&
                                    (props.providerComment?.trim().length ?? 0) > 0 &&
                                    <LightTooltip
                                        title={
                                            <Box sx={{ padding: 2 }}>
                                                <Typography
                                                    fontWeight="bold"
                                                    sx={{
                                                        marginBottom: 1,
                                                        textDecoration: 'underline'
                                                    }}
                                                >
                                                    {t('cart-material.provider-quotation-comment-provider-tooltip-title')}
                                                </Typography>
                                                <Typography>
                                                    {props.providerComment}
                                                </Typography>
                                            </Box>
                                        }
                                        arrow
                                    >
                                        <ContactSupportOutlined
                                            fontSize="inherit"
                                            sx={{ cursor: 'pointer' }}
                                            onClick={onEditComment}
                                        />
                                    </LightTooltip>
                                }
                                {
                                    !props.status?.provider &&
                                    <Box>
                                        <Tooltip title={t('cart-material.provider-quotation-confirm')}>
                                            <IconButton
                                                color="success"
                                                onClick={onAccept}
                                            >
                                                <Check />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t('cart-material.provider-quotation-refuse')}>
                                            <IconButton
                                                color="error"
                                                onClick={onRefuse}
                                            >
                                                <Close />
                                            </IconButton>
                                        </Tooltip>
                                        {
                                            !props.isProviderCreated &&
                                            <Tooltip title={t('cart-material.provider-quotation-new-proposal')}>
                                                <IconButton
                                                    color="primary"
                                                    onClick={onMakeNewProposition}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {
                                            props.isProviderCreated &&
                                            <Tooltip title={t('shared.delete')}>
                                                <IconButton
                                                    color="warning"
                                                    onClick={() => setOpenDeleteModal(true)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </Box>
                                }
                                {
                                    props.status?.provider &&
                                    <Button
                                        onClick={() => onChangeStatus(null)}
                                        sx={{ marginLeft: 'auto !important' }}
                                    >
                                        {t('shared.cancel')}
                                    </Button>
                                }
                            </Stack>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
            <ProviderQuotationDeletePropositionModal
                open={openDeleteModal}
                productId={props.id}
                onClose={() => setOpenDeleteModal(false)}
            />
            {
                openCommentModal &&
                <ProviderQuotationProductCommentModal
                    defaultComment={props.providerComment}
                    onConfirm={onConfirmCommentModal}
                    onClose={() => setOpenCommentModal(false)}
                />
            }
            <LoadingBackDrop open={loading} />
        </>
    );
}

const PriceInput = styled('input')((props) => ({
    fontSize: '0.75rem',
    paddingLeft: props.theme.spacing(1),
    paddingRight: props.theme.spacing(1),
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: props.theme.shape.borderRadius
}));
