import { kebabCase } from "lodash";
import { Template } from "../utils/handlebars/roadbook.handlebars";
import { createDomObjectsFromHtml } from "../MailVisualEditor/utils/dom";
import { Rubric } from "../objects/rubric";
import { CreatePageOptions } from "../objects/createPageOptions";

type Options = {
    title: string,
    rubrics: Omit<Rubric, 'id'>[]
}

export function createDynamicPageContent(options: CreatePageOptions & Options): Parameters<Template>[0]['contents'][0] {
    return {
        type: 'dynamic-page',
        anchor: kebabCase(options.title),
        showInSummary: true,
        summaryTitle: options.title,
        title: options.title,
        rubrics: options.rubrics.map((item) => {
            const localization = item.localization.find((item) => {
                return item.locale === options.locale;
            });
            const { document } = createDomObjectsFromHtml(localization?.description ?? '');

            for (const styleNode of document.body.getElementsByTagName('style')) {
                styleNode.remove();
            }

            for (const table of document.body.getElementsByTagName('table')) {
                table.replaceWith(...table.children);
            }

            return {
                title: localization?.title ?? '',
                text: document.body.innerHTML
            };
        })
    };
}
