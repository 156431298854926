import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import green from "@material-ui/core/colors/green";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import Room from "@material-ui/icons/Room";
import Language from "@material-ui/icons/Language";
import Star from "@material-ui/icons/Star";
import Favorite from "@material-ui/icons/Favorite";
import EcoOutlinedIcon from '@material-ui/icons/EcoOutlined';

import { UnmountCard } from "../../../Actions/Poi";
import i18n from "../../../i18n";
import localeText from "../../Functions/localeText";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles(theme => ({
    inline: {
        display: "inline"
    },
    returnButton: {
        display: "inline",
        marginRight: 10,
        float: "left",
        marginTop: 6
    },
    bold: {
        fontWeight: "bold"
    },
    pin: {
        fontSize: 14,
        float: "left",
        marginLeft: -2
    },
    alignCenter: {
        textAlign: "center"
    },
    alignRight: {
        textAlign: "right"
    },
    paddingBottom: {
        paddingBottom: theme.spacing(2),
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    stars: {
        display: "inline",
        marginRight: 4,
        verticalAlign: 'sub',
    },
    returnButtonContainer: {
        width: 48
    },
    tripadvisorContainer: {
        width: 120
    },
    inlineBlock: {
        display: 'inline-block'
    },
    noPaddingMargin: {
        padding: '0 !important',
        margin: 0
    },
    marginLeft: {
        marginLeft: 8
    },
    recommended: {
        color: green[500],
        padding: "4px 4px 0px 0px"
    }
}));

const PoiCardHeader = ({ router, activity }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const locales = useSelector(state => state.user.locales);
    const bounds = useSelector(state => state.poi.bounds);
    const destination = useSelector(state => state.poi.destination);
    const current_locale = locales.find((el) => { return el.language_code === i18n.language });

    let stars = [];
    if (activity.rating !== null) {
        for (let i = 0; i < activity.rating ; i++) {
            stars.push(<Star key={ i } className={ classes.stars }/>);
        }
    }

    const returnToList = () => {
        if (bounds !== null) {
            dispatch(UnmountCard());
        }
        router.push(`/${window.url_name}/apps/poi`);
    };
    return (
        <Grid container spacing={ 2 }>
            <Grid className={ `${classes.returnButtonContainer} ${classes.noPaddingMargin}` } container item xs={ 12 } alignItems={ "center" }>
                <IconButton className={ `${classes.returnButton} ${classes.noPaddingMargin}` } onClick={ returnToList }>
                    <NavigateBefore fontSize={ "large" }/>
                </IconButton>
                <Typography className={ `${classes.bold} ${classes.marginLeft}` }>{ t("poi.other_poi") }</Typography>
            </Grid>
            <Grid item xs>
                <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 2} direction={mobile ? "column" : "row"}>
                    {
                        activity.recommended && (
                            <Grid item className={classes.recommended}>
                                <Favorite />
                            </Grid>
                        )
                    }
                    <Grid item className={classes.alignCenter}>
                        <Typography className={ `${classes.inline} ${classes.bold} ${classes.marginRight}` } variant={ "h6" }>
                            { activity.localization !== undefined && localeText(current_locale.id, activity.localization, activity.title, 'title') !== "" ? localeText(current_locale.id, activity.localization, activity.title, 'title') : activity.title }
                            {
                                activity.label_eco && 
                                <Tooltip title={t('poi.label_eco')} placement="bottom">
                                    <EcoOutlinedIcon style={{color: '#4caf50', verticalAlign: 'sub'}}/>
                                </Tooltip>
                            }
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={ `${classes.inlineBlock} ${classes.bold}` } variant={ "h6" }>{ stars }</Typography>
                    </Grid>
                </Grid>
                <br/>

                {
                    activity.place !== null && activity.place.address !== null && activity.place.address !== "" && activity.place.city_name !== null && (
                        <Grid container alignItems="center" justify="flex-start" spacing={mobile ? 0 : 1}>
                            <Grid item>
                                <Room className={ classes.pin }/>
                            </Grid>
                            <Grid item>
                                <Typography variant={ "body2" }>
                                    {`${activity.place.address}, ${activity.place.city_name}` }
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }
                <Typography className={ `${classes.destination} ${classes.bold}` } variant={ "body2" }>
                    { activity.place.country_name !== null ? activity.place.country_name : "" }
                </Typography>
                {
                    destination !== null && destination.destination !== null &&
                    <Typography className={ `${classes.destination} ${classes.bold}` } variant={ "body2" }>
                        { destination.destination.data.name !== null ? destination.destination.data.name : destination.destination.data.international_name }
                    </Typography>
                }
            </Grid>
            {
                mobile ? (
                    <Grid item container justify={ "space-between" }>
                        <Grid item>
                            {
                                activity.website !== null && activity.website !== "" &&  (
                                    <Link className={ classes.marginRight } to={ activity.website } target={ "_blank" } onlyActiveOnIndex>
                                        <Language className={ `${classes.inline} ${classes.pin} ${classes.marginRight}` }/>
                                        <Typography className={ classes.inline }>{ t("accommodation.website") }</Typography>
                                    </Link>
                                )
                            }
                        </Grid>
                    </Grid>
                ) : (
                    <Grid className={ classes.tripadvisorContainer } item container justify={ "flex-end" }>
                        <Grid item className={ classes.alignRight }>
                            {
                                activity.website !== null && activity.website !== "" && (
                                    <Link className={ classes.marginRight } to={ activity.website } target={ "_blank" } onlyActiveOnIndex>
                                        <Language className={ `${classes.inline} ${classes.pin} ${classes.marginRight}` }/>
                                        <Typography className={ classes.inline }>{ t("accommodation.website") }</Typography>
                                    </Link>
                                )
                            }
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    )
};

export default React.memo(PoiCardHeader);