import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    Stack,
    Typography
} from "@mui/material";
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { useSnackbar } from "notistack";
import { isEqual } from "lodash";
import { useShowError } from "../Utils/showError";
import { CartConstructionAddProductAccommodationPicker } from "./CartConstructionAddProductAccommodationPicker";
import { CartConstructionReplaceProductContext } from "./utils/cartConstructionReplaceProductContext";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import { Traveler } from "../../Reducers/objects/Traveler";
import { AccommodationSearchResult } from "./objects/accommodationSearchResult";
import { TravelerGroup } from "../../Reducers/objects/travelerGroup";
import { AppState } from "../../Reducers/Reducers";

type Result = {
    accommodation: AccommodationSearchResult,
    destination: number
}

type Props = {
    searchedAccommodation: Result | null,
    onChangeSearchedAccommodation: (value: Result | null) => void
}

export function CartConstructionReplaceRoomInputs(props: Props): JSX.Element | null {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const store_travelers = useSelector((state: AppState) => state.trip.travelers) ?? [];
    const current_groups = useSelector((state: AppState) => state.accommodation.current_groups);
    const start_date = useSelector((state: AppState) => state.accommodation.start_date);
    const end_date = useSelector((state: AppState) => state.accommodation.end_date);
    const traveler_groups = useSelector((state: AppState) => state.trip.traveler_groups);
    const [travelers, setTravelers] = useState([...store_travelers]);
    const [groups, setGroups] = useState<TravelerGroup[]>([]);
    const [dates, setDates] = useState({ start_date: start_date, end_date: end_date });
    const context = useContext(CartConstructionReplaceProductContext);
    const showError = useShowError();

    useEffect(() => {
        let new_travelers: any = [];
        let numero_adult = 1;
        let numero_child = 1;
        travelers.map((traveler) => {
            let tmp_traveler: any = Object.assign({}, traveler);
            tmp_traveler.default_name = false;
            let age = window.moment.utc(end_date ?? undefined).diff(window.moment.utc(tmp_traveler.birth_date), "y");
            if (tmp_traveler.first_name === t('global.first_name') && tmp_traveler.last_name === t('global.last_name')) {
                tmp_traveler.default_name = true;
            }
            if (age >= 18) {
                tmp_traveler.age_label = tmp_traveler.default_name ? `${t("flight_search.adult")} ${numero_adult}` : `${tmp_traveler.first_name} ${tmp_traveler.last_name}`;
                numero_adult += 1;
            } else {
                tmp_traveler.age_label = tmp_traveler.default_name ? `${t("flight_search.child")} ${numero_child} (${age})` : `${tmp_traveler.first_name} ${tmp_traveler.last_name} (${age})`;
                numero_child += 1;
            }
            new_travelers.push(tmp_traveler);
        });
        setTravelers(new_travelers);
    }, []);

    useEffect(() => {
        if (context.mode !== 'add') {
            setDates({ start_date: start_date, end_date: end_date });
        }
        if (current_groups) {
            setGroups(current_groups);
        }
    }, [
        context.mode,
        start_date,
        end_date,
        current_groups
    ]);

    let travelers_without_group = [];
    travelers.map((traveler) => {
        let has_group = false;
        for (let i = 0; i < groups.length; i++) {
            for (let j = 0; j < (groups[i]?.travelers_list?.length ?? 0); j++) {
                if (groups[i]?.travelers_list?.[j]?.id === traveler.id) {
                    has_group = true;
                    break;
                }
            }
        }
        if (!has_group) {
            travelers_without_group.push(traveler);
        }
    });

    context.onRegisterNextEvent(async () => {
        const { headers } = CheckBeforeRequest();
        const filteredGroups = groups.filter((group) => {
            return group.travelers.length > 0;
        });

        if (
            filteredGroups.findIndex((group) => {
                return (
                    group.travelers_list?.filter((traveler) => {
                        return window.moment().diff(traveler.birth_date, "y") >= 18;
                    }) ?? []
                ).length === 0;
            }) >= 0
        ) {
            enqueueSnackbar(t<string>("accommodation.room_without_adult"), {
                variant: "warning"
            });
            return;
        }
        const toBeCreatedGroups = filteredGroups.filter((newGroup) => {
            return traveler_groups.findIndex((group) => {
                const aTravelers = [...newGroup.travelers].sort((a, b) => a - b);
                const bTravelers = [...group.travelers].sort((a, b) => a - b);
                return isEqual(aTravelers, bTravelers);
            }) < 0;
        });
        const alreadyCreatedGroups = filteredGroups.map((newGroup) => {
            return traveler_groups.find((group) => {
                const aTravelers = [...newGroup.travelers].sort((a, b) => a - b);
                const bTravelers = [...group.travelers].sort((a, b) => a - b);
                return isEqual(aTravelers, bTravelers);
            });
        }).filter((item): item is NonNullable<typeof item> => !!item);

        try {
            const createdGroups = (
                await Promise.all(
                    toBeCreatedGroups.map((group) => {
                        return axios.post<TravelerGroup>(
                            `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/?token=${GetCookie("trip_token")}`,
                            {
                                travelers: group.travelers,
                                default: false
                            },
                            { headers }
                        );
                    })
                )
            ).map((response) => {
                return response.data;
            });
            dispatch({
                type: "MATERIAL_UPDATE_PARAMETERS",
                payload: {
                    start_date: dates.start_date,
                    end_date: dates.end_date,
                    current_groups: alreadyCreatedGroups.concat(createdGroups)
                }
            });
            context.onChangeLoading(true);
            context.onNextStep();
        } catch (error) {
            showError(error as Error);
        }
    });

    if (groups === null) {
        return null;
    }

    return (
        <Inputs
            searchedAccommodation={props.searchedAccommodation}
            startDate={dates.start_date}
            endDate={dates.end_date}
            travelers={travelers}
            groups={groups}
            pax={groups.length}
            onChangeSearchedAccommodation={props.onChangeSearchedAccommodation}
            onChangeStartDate={(date) => setDates((state) => ({ ...state, start_date: date }))}
            onChangeEndDate={(date) => setDates((state) => ({ ...state, end_date: date }))}
            onChangePax={setGroups}
        />
    );
}

type InputsProps = {
    searchedAccommodation: Result | null,
    startDate: string | null,
    endDate: string | null,
    travelers: Traveler[],
    groups: any[],
    pax: number,
    onChangeSearchedAccommodation: (value: Result | null) => void
    onChangePax: React.Dispatch<React.SetStateAction<any[]>>,
    onChangeStartDate: (date: string | null) => void,
    onChangeEndDate: (date: string | null) => void
}

const Inputs = (props: InputsProps) => {
    const { t } = useTranslation();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const itinerary = useSelector((state: AppState) => {
        return state.itinerary.itinerary_list;
    });
    const steps = useMemo(() => {
        return [...itinerary].sort(sortItinerary);
    }, [itinerary]);
    const context = useContext(CartConstructionReplaceProductContext);
    const classes = useStyles();

    const onDecrementPax = () => {
        if (props.groups.length > 1) {
            let groups_cpy = [];
            for (let i = 0; i < props.groups.length - 1; i++) {
                groups_cpy.push({
                    travelers: [],
                    travelers_list: []
                });
                props.onChangePax(groups_cpy);
            }
        }
    };

    const onIncrementPax = () => {
        if (props.groups.length < props.travelers.length) {
            let groups_cpy = props.groups.slice();
            groups_cpy.push({
                travelers: [],
                travelers_list: []
            });
            props.onChangePax(groups_cpy);
        }
    };

    useEffect(() => {
        if (context.mode === 'add' && context.contentItem) {
            let destination = -1;

            if (context.contentItem.data.mode === 'by-day') {
                destination = context.contentItem.data.content.destinations?.[
                    (context.contentItem.data.content.destinations?.length ?? 0) - 1
                ]?.id ?? -1;
            } else {
                destination = context.contentItem.data.content.destination?.id ?? -1;
            }

            const startDate = window.moment.utc(context.contentItem.from);
            let endDate = window.moment.utc(context.contentItem.to);
            const step = steps.find((item) => {
                const from = window.moment.utc(item.start_date);
                const to = window.moment.utc(item.end_date);
                return startDate.isBetween(
                    from,
                    to,
                    'day',
                    '[]'
                ) && item.destination?.id === destination;
            });
            if (step) {
                endDate = window.moment.utc(step.end_date);
            }
            props.onChangeStartDate(startDate.format('YYYY-MM-DD'));
            props.onChangeEndDate(endDate.format('YYYY-MM-DD'));
        }
    }, [
        context.mode,
        context.contentItem?.from,
        context.contentItem?.to,
        steps
    ]);

    useEffect(() => {
        const startDate = window.moment.utc(props.startDate ?? undefined);
        const endDate = window.moment.utc(props.endDate ?? undefined);
        if (
            context.mode === 'add' &&
            props.startDate &&
            endDate?.isBefore(startDate)
        ) {
            props.onChangeEndDate(startDate.format('YYYY-MM-DD'));
        }
    }, [
        context.mode,
        props.startDate,
        props.endDate
    ]);

    return (
        <>
            {
                context.mode === 'add' &&
                <Box sx={{ textAlign: 'center', marginTop: 2, marginBottom: 2 }}>
                    <CartConstructionAddProductAccommodationPicker
                        label={t<string>('shared.accommodation-name')}
                        value={props.searchedAccommodation}
                        onChange={props.onChangeSearchedAccommodation}
                    />
                </Box>
            }
            <Typography sx={{ marginBottom: 2.5 }}>
                {t<string>('cart-material.cart-construction-dates-dot')}
            </Typography>
            <Stack direction="row" spacing={1}>
                <LocalizationProvider dateAdapter={ AdapterMoment } locale={language}>
                    <DatePicker
                        label={t<string>('cart-material.cart-construction-start-date')}
                        value={
                            props.startDate ?
                                window.moment.utc(props.startDate) :
                                null
                        }
                        onChange={(value) => props.onChangeStartDate(value?.format('YYYY-MM-DD') ?? null)}
                        sx={{ width: '100%' }}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={ AdapterMoment } locale={language}>
                    <DatePicker
                        label={t<string>('cart-material.cart-construction-end-date')}
                        value={
                            props.endDate ?
                                window.moment.utc(props.endDate) :
                                null
                        }
                        minDate={window.moment.utc(props.startDate ?? undefined)}
                        onChange={(value) => props.onChangeEndDate(value?.format('YYYY-MM-DD') ?? null)}
                        sx={{ width: '100%' }}
                    />
                </LocalizationProvider>
            </Stack>
            <Typography sx={{ marginTop: 3.5 }}>
                {t<string>('cart-material.cart-construction-pax-dot')}
            </Typography>
            <List className={ classes.paddingPassenger}>
                <ListItem className={classes.itemPassenger}>
                    <div className={classes.flexGrow}>
                        <div className={classes.titleStepper}>{t<string>('shared.room-nb')}</div>
                    </div>
                    <div className={classes.stepperPassenger}>
                        <IconButton className={classes.customIconButton} size={'small'} onClick={onDecrementPax}>
                            <RemoveCircleOutlineOutlined className={classes.iconStepper} />
                        </IconButton>
                        <div>{props.groups.length}</div>
                        <IconButton className={classes.customIconButton} size={'small'} onClick={onIncrementPax}>
                            <AddCircleOutlineOutlined className={classes.iconStepper}/>
                        </IconButton>
                    </div>
                </ListItem>
            </List>
            
            <Grid container>
                {
                    props.groups.map((group, index_group) => {
                        return (
                            <Grid key={ index_group } item md={ 4 } xs={ 6 }>
                                <Typography>{ t<string>("accommodation.room") } { index_group + 1 } :</Typography>
                                {
                                    props.travelers.map((traveler, index_traveler) => {
                                        return (
                                            <div key={ index_traveler }>
                                                <FormControlLabel control={ <Checkbox checked={ group.travelers.includes(traveler.id) } onChange={(e) => {
                                                    let groups_cpy: any[] = [];
                                                    props.groups.map(group_cpy => {
                                                        groups_cpy.push({ ...group_cpy });
                                                    });
                                                    //let groups_cpy = groups.map(group => { return {...group}});
                                                    //let groups_cpy = groups.slice();
                                                    if (e.target.checked) {
                                                        groups_cpy.map((group_check, group_check_index) => {
                                                            const index = group_check.travelers.indexOf(traveler.id);
                                                            if (index > -1) {
                                                                groups_cpy[group_check_index] = Object.assign({}, props.groups[group_check_index]);
                                                                groups_cpy[group_check_index].travelers = groups_cpy[group_check_index].travelers.slice();
                                                                groups_cpy[group_check_index].travelers.splice(index, 1);
                                                                groups_cpy[group_check_index].travelers_list = groups_cpy[group_check_index].travelers_list.slice();
                                                                groups_cpy[group_check_index].travelers_list.splice(index, 1);
                                                            }
                                                        });
                                                        groups_cpy[index_group] = Object.assign({}, props.groups[index_group]);
                                                        groups_cpy[index_group].travelers = groups_cpy[index_group].travelers.slice();
                                                        groups_cpy[index_group].travelers.push(traveler.id);
                                                        groups_cpy[index_group].travelers_list = groups_cpy[index_group].travelers_list.slice();
                                                        groups_cpy[index_group].travelers_list.push(traveler);
                                                    } else {
                                                        const index = groups_cpy[index_group].travelers.indexOf(traveler.id);
                                                        if (index > -1) {
                                                            groups_cpy[index_group].travelers = groups_cpy[index_group].travelers.slice();
                                                            groups_cpy[index_group].travelers.splice(index, 1);
                                                            groups_cpy[index_group].travelers_list = groups_cpy[index_group].travelers_list.slice();
                                                            groups_cpy[index_group].travelers_list.splice(index, 1);
                                                        }
                                                    }
                                                    props.onChangePax(groups_cpy);
                                                }}/> } label={ (traveler as any).age_label }/>
                                            </div>
                                        );
                                    })
                                }
                                <br/>
                            </Grid>
                        );
                    })
                }
            </Grid>
        </>
    );
};

const useStyles = makeStyles({
    formControlRoot: {
        borderLeft: 0,
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        padding: '6px 13px !important',
        position: "relative",
        height: 41
    },
    paddingPassenger: {
        paddingLeft: '10px !important',
        paddingRight: '10px !important'
    },
    paperStepper: {
        position: "absolute",
        top: "100% !important",
        zIndex: "1 !important",
        background: "rgb(255, 255, 255) !important",
        borderRadius: "32px !important",
        boxShadow: "rgb(0 0 0 / 20%) 0px 6px 20px !important",
        marginTop: "12px !important",
        padding: "16px 0px 16px 16px !important"
    },
    paperChild: {
        maxHeight: "calc(100vh - 220px) !important",
        overflowX: 'auto'
    },
    flexGrow: {
        WebkitBoxFlex: "1 !important",
        flexGrow: "1 !important"
    },
    itemPassenger: {
        WebkitBoxPack: "justify",
        WebkitBoxAlign: "center",
        color: "rgb(34, 34, 34) !important",
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
        paddingRight: "0px !important",
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "space-between !important"
        //minWidth: "330px !important"
    },
    stepperPassenger: {
        WebkitBoxPack: "justify",
        WebkitBoxAlign: "center",
        display: "inline-flex !important",
        alignItems: "center !important",
        justifyContent: "space-between !important",
        width: "104px !important",
        height: "32px !important",
        color: "rgb(34, 34, 34) !important",
        fontWeight: "400 !important",
        lineHeight: "20px !important"
    },
    iconStepper: {
        cursor: "pointer",
        fontSize: 29
    },
    titleStepper: {
        fontSize: "14px !important",
        lineHeight: "20px !important",
        fontWeight: "600 !important"
    },
    labelStepper: {
        fontSize: "14px !important",
        lineHeight: "18px !important",
        fontWeight: "400 !important",
        color: "rgb(113, 113, 113) !important"
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    buttonContainer: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white',
        width: 'calc(100% - 15px)'
    },
    orangeText: {
        color: "#E6592F"
    },
    sectionTitle: {
        textAlign: "left",
        paddingLeft: "10px !important"
    },
    title: {
        fontSize: 15,
        fontWeight: 'bold'
    },
    customIconButton: {
        '&:focus': {
            outline: 'none !important'
        }
    },
    customSelect: {
        "& .MuiOutlinedInput-input": {
            padding: "16px 14px"
        },
        "fontSize": 14
    },
    selectRoom: {
        fontSize: 13,
        marginTop: 10,
        marginBottom: 15,
        fontStyle: "italic"
    },
    warningAlert: {
        marginRight: 15
    },
    listItem: {
        padding: '0 16px'
    },
    listItemLabel: {
        marginBottom: 0
    },
    bold: {
        fontWeight: 'bold'
    },
    buttonWhite: {
        backgroundColor: 'white'
    },
    responsiveSize: {
        fontSize: 10
    },
    responsiveIcon: {
        fontSize: "12px !important"
    }
} as any);
