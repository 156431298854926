import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, styled, TextField } from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { debounce, isString } from "lodash";
import { getQuickDestination } from "./utils/getQuickDestination";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { setSearchFilter } from "./redux/reducer";
import { LightDestination } from "./objects/lightDestination";
import { ReducedLightDestination } from "./objects/reducedLightDestination";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    tab: 'destinations' | 'blocks',
    onChoose: (destination: LightDestination) => void
}

export function ItineraryDestinationSearch(props: Props): JSX.Element {
    if (props.tab === 'destinations') {
        return (
            <DestinationsSearch onChoose={props.onChoose} />
        );
    }
    return (
        <BlocksSearch />
    );
}

type DestinationsSearchProps = {
    onChoose: (destination: LightDestination) => void
}

function DestinationsSearch(props: DestinationsSearchProps): JSX.Element {
    const { t } = useTranslation();
    const isUserTO = useSelector((state: AppState) => state.user.user?.client_full?.type !== 2);
    const [options, setOptions] = useState<ReducedLightDestination[]>([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const cancelToken = useRef<CancelTokenSource | null>(null);
    const fireNetworkRequest = useCallback(
        debounce(
            async (search: string, isUserTO: boolean) => {
                try {
                    setLoading(true);
                    cancelToken.current?.cancel('Request cancelled.');
                    cancelToken.current = axios.CancelToken.source();
                    const response = await makeRequest({
                        search,
                        isUserTO,
                        cancelToken: cancelToken.current
                    });
                    setOptions(response.data);
                } catch (error: any) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            },
            500
        ),
        []
    );

    const onChoose = async (destination: ReducedLightDestination) => {
        const data = await getQuickDestination(destination.id);

        if (data) {
            props.onChoose({
                ...data,
                id: destination.id,
                note: 0,
                parent_id: null,
                bounds: JSON.parse(data.bounds?.replace(/'/g, '"') ?? '{}'),
                latitude: parseFloat(data.latitude),
                longitude: parseFloat(data.longitude),
                type_dest: data.type,
                parent_international_name: '',
                parent_name: []
            });
        }
    };

    useEffect(() => {
        if (search.trim().length > 0) {
            fireNetworkRequest(search, isUserTO);
        } else {
            setOptions([]);
            setLoading(false);
        }
    }, [search, isUserTO]);

    return (
        <Autocomplete
            value={null}
            loading={loading}
            options={options}
            filterOptions={(options) => options}
            getOptionLabel={(option) => isString(option) ? option : option.international_name}
            renderInput={(params) => (
                <SearchInput
                    {...params}
                    fixLabel={search.length > 0}
                    label={t<string>('global.search')}
                    placeholder={t<string>('itinerary.destination-name')}
                />
            )}
            renderOption={(props, option) => (
                <li {...props}>
                    <LocationOn sx={{ marginRight: 1 }} />
                    {option.international_name}
                </li>
            )}
            componentsProps={{
                popper: {
                    sx: {
                        minWidth: 230
                    }
                }
            }}
            sx={{ width: '100%' }}
            onChange={(_, value) => value && !isString(value) && onChoose(value)}
            onInputChange={(_, value) => isString(value) && setSearch(value)}
            freeSolo
        />
    );
}

function BlocksSearch(): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const search = useSelector((state: AppState) => state.itinerarySlice.blocks.filters.search);

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(setSearchFilter(event.target.value));
    };

    return (
        <SearchInput
            value={search}
            onChange={onSearchChange}
            fixLabel={search.length > 0}
            label={t<string>('global.search')}
            placeholder={t<string>('itinerary.destination-name')}
            sx={{
                "width": '100%',
                '.MuiInputBase-input': {
                    paddingBottom: 0
                }
            }}
        />
    );
}

const SearchInput = styled(
    TextField,
    {
        shouldForwardProp(name) {
            return name !== 'fixLabel';
        }
    }
)<{
    fixLabel: boolean,
}>((props) => ({
    "height": 24,
    '.MuiInputBase-root': {
        padding: 0,
        height: '100%'
    },
    '.MuiInputLabel-root': {
        "fontSize": 15,
        "transform": props.fixLabel ?
            'translate(14px, -9px) scale(0.75)' :
            'translate(14px, 0px) scale(1)',
        '&.Mui-focused': {
            transform: 'translate(14px, -9px) scale(0.75)'
        }
    },
    '.MuiInputBase-input': {
        "paddingTop": '0 !important',
        '&::placeholder': {
            fontSize: 13
        }
    }
}));

type DestinationsRequestOptions = {
    isUserTO: boolean,
    search: string,
    cancelToken: CancelTokenSource
}

function makeRequest(options: DestinationsRequestOptions): Promise<AxiosResponse<ReducedLightDestination[]>> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        return axios.get(
            `${API_HREF}client/${window.id_owner}/destinations/quick_search/`,
            {
                headers,
                cancelToken: options.cancelToken.token,
                params: {
                    limit: 10,
                    ordering: 'current_version__type',
                    search: options.search,
                    reduced: true,
                    visibility__in: options.isUserTO ?
                        'PUBLIC,PRIVATE_TO' :
                        'PUBLIC'
                }
            }
        );
    }

    throw new Error('Please login.');
}
