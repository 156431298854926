import axios from "axios";
import memoizee from "memoizee";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { RoadbookConfiguration } from "../objects/roadbookConfiguration";

async function fetch(): Promise<RoadbookConfiguration | null> {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        try {
            const response = await axios.get<{results: RoadbookConfiguration[]}>(
                `${API_HREF}client/${window.id_owner}/roadbook-configuration/`,
                { headers }
            );
            return response.data.results[0] ?? null;
        } catch (error: any) {
            console.error(error);
        }
    }
    return null;
}

export const findRoadbookConfiguration = memoizee(
    fetch,
    { promise: true, primitive: true }
);
