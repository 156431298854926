import { flatten } from "lodash";
import { TFunction } from "i18next";
import { useCartProducts } from "../../../Itinerary/network/cartProducts";
import Icon from "../../../../Img/noun-car-2558234-3.png?roadbook";

export function transformTransfersToCalendarTransports(
    options: {
        cart: ReturnType<typeof useCartProducts>,
        dateString: string,
        t: TFunction
    }
) {
    const date = window.moment.utc(options.dateString, "YYYY-MM-DD").startOf('day');
    const cars = options.cart.transfers.filter((item) => {
        return (
            window.moment.utc(item.transfer.start_date).isSame(date, 'day')
        ) &&
        !item.transfer.is_optional;
    });
    return flatten(
        cars.map((item) => {
            const departure = window.moment.utc(item.transfer.start_date);
            const result = [
                departure.clone().startOf('day').isSame(
                    date,
                    'days'
                ) ?
                    {
                        dateOrder: item.transfer.start_date,
                        url: Icon,
                        hourly: departure.format('HH:mm'),
                        description: options.t('roadbook.transfer-departure-description')
                    } :
                    null
            ];
            //eslint-disable-next-line max-nested-callbacks
            return result.filter((item): item is NonNullable<typeof result[0]> => {
                return !!item;
            });
        })
    );
}
