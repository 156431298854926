import { SelectedRoom } from "../RoomTypePicker";

export function validateRoomTypes(
    rooms: SelectedRoom[],
    groupsCount: number,
    errorMessage: string,
    hasLanguage: boolean,
    language_code: string | null,
): [
    boolean,
    {
        [index: number]: string
    }
] {
    let valid = true;
    const errors: {[index: number]: string} = {};
    if (groupsCount > 0) {
        for (let i = 0; i < groupsCount; i++) {
            if (!rooms[i]?.code) {
                valid = false;
                errors[i] = errorMessage;
            }
            if (hasLanguage && !language_code) {
                valid = false;
                errors[-2] = errorMessage;
            }
        }
    }
    return [valid, errors];
}
