import { useSelector } from "react-redux";
import axios from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { Trip } from "../../Menu/MaterialTripList/objects/trip";
import { AppState } from "../../../Reducers/Reducers";

type Package = {
    providerId: number,
    stackNumber: number,
}

type Callback = (tripVersion: number, packages: Package[], allowProviderTextsEdit: boolean) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: (trip: Trip) => void,
    onError: (error: Error) => void,
    onFinally: () => void
}>

export function useSendProviderQuotationDemand(options: Options): Callback {
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    return async (version, packages, allowProviderTextsEdit) => {
        if (tripId) {
            try {
                if (options.onTrigger) {
                    options.onTrigger();
                }
                const trip = await makeTripUpdateRequest({
                    tripId,
                    allowProviderTextsEdit
                });
                for (const item of packages) {
                    await makeRequest({
                        tripId,
                        version,
                        package: item
                    });
                }
                if (options.onSuccess && trip) {
                    options.onSuccess(trip);
                }
            } catch (error: any) {
                if (options.onError) {
                    options.onError(error);
                }
            } finally {
                if (options.onFinally) {
                    options.onFinally();
                }
            }
        }
    };
}

type RequestOptions = {
    tripId: number,
    version: number,
    package: Package
}

async function makeRequest(options: RequestOptions): Promise<void> {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        await axios.get(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/send_provider_quotation_request_template/`,
            {
                headers,
                params: {
                    provider_id: options.package.providerId,
                    stack_number: options.package.stackNumber
                }
            }
        );
    }
}

type TripUpdateRequestOptions = {
    tripId: number,
    allowProviderTextsEdit: boolean
}

async function makeTripUpdateRequest(options: TripUpdateRequestOptions): Promise<Trip | null> {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        const response = await axios.patch(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/`,
            {
                provider_allowed_to_edit_byday: options.allowProviderTextsEdit
            },
            { headers }
        );
        return response.data;
    }
    return null;
}
