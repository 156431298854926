export default (prices, client, user, master) => {
	if (prices !== undefined && prices.length > 0 && client === null && user === null) {
		for (let i = 0; i < prices.length; i++) {
			if (prices[i].master_price === false && prices[i].is_commissioned === false && prices[i].is_network === false && prices[i].is_surcom === false) {
				return i;
			}
		}
	}
	if (prices !== undefined && prices.length > 0 && user !== null) {
		if (user.client_full.type === 1 || user.client_full.type === 3 || JSON.parse(localStorage.getItem("config")).quotation_code === "verdie") {
			if (master) {
				for (let i = 0; i < prices.length; i++) {
					if (prices[i].master_price !== undefined && prices[i].master_price === true) {
						return i;
					}
				}
			}
			else {
				if (JSON.parse(localStorage.getItem("config")).quotation_code === "americavoyages") {
					for (let i = 0; i < prices.length; i++) {
						if (prices[i].is_tva !== undefined && prices[i].is_tva === true) {
							return i;
						}
					}
				}
				for (let i = 0; i < prices.length; i++) {
					if (prices[i].is_surcom !== undefined && prices[i].is_surcom === true) {
						return i;
					}
				}
				for (let i = 0; i < prices.length; i++) {
					if (prices[i].master_price !== undefined && prices[i].master_price === true) {
						return i;
					}
				}
				for (let i = 0; i < prices.length; i++) {
					if (prices[i].owner !== undefined && prices[i].owner === client) {
						return i;
					}
				}
			}
		}
		else {
			if (master) {
				for (let i = 0; i < prices.length; i++) {
					if (prices[i].master_price !== undefined && prices[i].master_price === true) {
						return i;
					}
				}
				for (let i = 0; i < prices.length; i++) {
					if (prices[i].master_price !== undefined && prices[i].master_price === false && prices[i].is_surcom === false && prices[i].is_network === false) {
						return i;
					}
				}
			}
			else {
				for (let i = 0; i < prices.length; i++) {
					if (prices[i].owner !== undefined && prices[i].owner === client) {
						return i;
					}
				}
			}
		}
	}
	return 0;
}