import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';
import RenderForm from './RenderForm';
import localeText from "../Functions/localeText";
import i18n from "../../i18n";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
    checkboxLabel: {
        ...theme.typography.overline,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.55rem'
        }
    },
    carsTitle: {
        color: 'rgba(0, 0, 0, 0.54)',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        [theme.breakpoints.up('sm')]: {
            fontSize: '24px'
        }
    },
    carsDate: {
        fontSize: 16,
        marginLeft: 20
    },
    carsLocation: {
        color: 'rgba(0, 0, 0, 0.54)',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 16
    },
    carsSearch: {
        color: 'rgba(0, 0, 0, 0.54)',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 20
    },
    returnButton: {
	    display: "inline",
	    marginRight: 10,
	    float: "left"
	    //marginTop: 6
    },
    marginBottom20: {
        marginBottom: '20px'
    },
    carsRightFloat: {
        float: 'right'
    },
    colorBlack: {
        color: "black"
    },
    orangeButton: {
        border: `2px solid ${main_color}`,
        backgroundColor: `${main_color}`,
        color: "white"
    },
    editButton: {
        border: `2px solid ${main_color}`,
        color: `${main_color}`,
        backgroundColor: "white"
        //margin: 0,
        //position: 'fixed',
        //top: 'auto',
        //zIndex: 2
    },
    extendedIcon: {
   		marginRight: theme.spacing(1)
 	},
 	marginTop46: {
 		marginTop: 46
 	}
}));

const CarLocationHeader = ({ location_index, car_index, search, results, elem, setSearch, checkInput }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const total_cars = useSelector(store => store.cars_search.total_cars);
    const locales = useSelector(state => state.user.locales);

    const [showEdit, setEdit] = useState(false);
    const [copy_search, setCopySearch] = useState(search.slice());
    
    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });

    const toggleSameDest = () => {
        let tmp_search = search.slice();
        let new_search = [];
        tmp_search.map((search, search_index) => {
            if (search_index === location_index) {
                let cpy_search = Object.assign({}, search);
                cpy_search.same_dest = !cpy_search.same_dest;
                if (cpy_search.same_dest) {
                    cpy_search.return_dest = cpy_search.origin_dest;
                    cpy_search.return_station = cpy_search.origin_station;
                }
                search = cpy_search;
            }
            new_search.push(search);
        });
        setSearch(new_search);
    };
    const handleCancel = () => {
        setSearch(copy_search);
        setEdit(false);
    };
    const checkArray = () => {
        let change = false;
        if (search[location_index].origin_dest !== copy_search[location_index].origin_dest || search[location_index].return_dest !== copy_search[location_index].return_dest || !moment(search[location_index].origin_date).isSame(moment(copy_search[location_index].origin_date)) || !moment(search[location_index].return_date).isSame(moment(copy_search[location_index].return_date)) || search[location_index].age !== copy_search[location_index].age || search[location_index].nb_vehicle !== copy_search[location_index].nb_vehicle || search[location_index].origin_hour !== copy_search[location_index].origin_hour || search[location_index].return_hour !== copy_search[location_index].return_hour || search[location_index].contract !== copy_search[location_index].contract) {
            change = true;
        }
        return change;
    };
    const handleSearch = () => {
        let check_array = checkArray();
        if (check_array) {
            checkInput();
            setCopySearch(search.slice());
            setEdit(false);
        } else {
            setEdit(false);
        }
    };
    return (
        <Fragment>
            {
                !showEdit ? (
                    <Collapse in={!showEdit}>
                        <Grid container spacing={0} className={classes.marginBottom20}>
                            <Grid item xs={ 12 } className={classes.marginTop46}>
                                <Typography align={'center'} variant={"h5"} className={ classes.carsTitle} gutterBottom >{t("cars.rent") + (location_index + 1) + (search[location_index].nb_vehicle > 1 ? " | " + t("cars.cars") + (car_index + 1) : "")}</Typography>
                            </Grid>
                            <Grid item xs={ 12 }>
                                <Typography align={'left'} variant={"h6"} className={ classes.carsSearch} gutterBottom >{t("global.your_search")}</Typography>
                            </Grid>
                            <Grid item xs={ 12 }>
                                <span className={ `${classes.carsLocation} ${classes.colorBlack}`} >{t("timeline.address_pickup") + " : "}</span>
                                <span className={ classes.carsLocation}>{search[location_index].origin_dest?.data && search[location_index].origin_dest?.data !== null ? localeText(current_locale.id, search[location_index].origin_dest?.data.localization, search[location_index].origin_dest?.data.international_name, 'name') : localeText(current_locale.id, search[location_index].origin_dest?.localization, search[location_index].origin_dest?.international_name, 'name')}</span>
                                <span className={classes.carsDate}>{moment(search[location_index].origin_date).format("dddd DD MMMM") + " | " + search[location_index].origin_hour}</span>
                            </Grid>
                            <Grid item xs={ 12 }>
                                <span className={ `${classes.carsLocation} ${classes.colorBlack}`}>{t("timeline.address_return") + " : "}</span>
                                <span className={ classes.carsLocation}>{search[location_index].return_dest?.data && search[location_index].return_dest?.data !== null ? localeText(current_locale.id, search[location_index].return_dest?.data.localization, search[location_index].return_dest?.data.international_name, 'name') : localeText(current_locale.id, search[location_index].return_dest?.localization, search[location_index].return_dest?.international_name, 'name')}</span>
                                <span className={classes.carsDate}>{moment(search[location_index].return_date).format("dddd DD MMMM") + " | " + search[location_index].return_hour}</span>
                            </Grid>
                            {
                                search[location_index].contract !== "" && (
                                    <Grid item xs={ 12 }>
                                        <span className={ `${classes.carsLocation} ${classes.colorBlack}`}>{t("cars.selected_contract") + " : "}</span>
                                        <span className={ classes.carsLocation}>{ t("cars.contract_" + (search[location_index].contract?.replaceAll(" ", "_"))?.toLowerCase()) }</span>
                                    </Grid>
                                )
                            }
                            <Grid item xs={12} className={classes.marginBottom20}>
                                <Grid container justify={"flex-end"}>
                                    <Button className={classes.editButton} onClick={() => {
                                        setEdit(true);
                                    }}>
							        {t("global.edit")}
						      	</Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <span className={clsx(classes.carsLocation, { [classes.carsRightFloat]: matches })}>{total_cars + " " + (total_cars > 1 ? t("accommodation.results") : t("accommodation.result"))}</span>
                            </Grid>
                        </Grid>
                    </Collapse>
                ) : (
                    <Collapse in={showEdit}>
                        <Grid container spacing={0} className={classes.marginBottom20}>
                            <Grid item sm={6} xs={12}>
                                <FormControlLabel
							    control={<Checkbox checked={elem.same_dest} onChange={() => {
                                        toggleSameDest();
                                    }} color={"primary"} />}
							    label={ <span className={classes.checkboxLabel}>{t("cars.different_location")}</span> }
                                />
                            </Grid>
                        </Grid>
                        {
                            matches && (
                                <Paper elevation={0} variant={"outlined"} className={classes.marginBottom20}>
                                    <RenderForm search={search} elem={elem} elem_index={location_index} setSearch={setSearch}/>
                                </Paper>
                            )
                        }
                        {
                            !matches && (
                                <RenderForm search={search} elem={elem} elem_index={location_index} setSearch={setSearch}/>
                            )
                        }
                        <Grid container className={classes.marginBottom20}>
                            <Grid item sm={8}/>
                            <Grid container spacing={2} item sm={4} xs={12}>
                                <Grid item sm={6}>
                                    <Button variant={ "contained" } className={ classes.editButton } disableElevation={true} fullWidth={ true } onClick={() => {
                                        handleCancel();
                                    }}>{ t("global.cancel") }</Button>
                                </Grid>
                                <Grid item sm={6}>
                                    <Button variant={ "contained" } className={ classes.orangeButton } disableElevation={true} fullWidth={ true } onClick={() => {
                                        handleSearch();
                                    }}>{ t("global.search") }</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Collapse>
                )
            }
        </Fragment>
    );
};
export default React.memo(CarLocationHeader);
