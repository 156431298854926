//Dependencies
import axios from 'axios';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
//Core
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Grid } from '@material-ui/core';
//Components
import CartFullTripPriceFlight from './CartFullTripPriceFlight';
import CartFullTripPriceTerrestrial from './CartFullTripPriceTerrestrial';
//Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';

const CartFullTripPrice = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const data_trip = useSelector(state => state.trip.data_trip);
    const currency_list = useSelector(state => state.base.currency_list);
    const full_trip_price = useSelector(state => state.cart.full_trip_price);
    const flight_margin_type = useSelector(state => state.cart.full_trip_flight_margin_type);
    const flight_margin_value = useSelector(state => state.cart.full_trip_flight_margin_value);
    const flight_purchase_price = useSelector(state => state.cart.full_trip_flight_purchase_price);
    const flight_purchase_currency = useSelector(state => state.cart.full_trip_flight_purchase_currency);
    const flight_taxes = useSelector(state => state.cart.full_trip_flight_taxes);
    const terrestrial_margin_type = useSelector(state => state.cart.full_trip_terrestrial_margin_type);
    const terrestrial_margin_value = useSelector(state => state.cart.full_trip_terrestrial_margin_value);
    const terrestrial_purchase_price = useSelector(state => state.cart.full_trip_terrestrial_purchase_price);
    const terrestrial_purchase_currency = useSelector(state => state.cart.full_trip_terrestrial_purchase_currency);

    const onClose = () => {
        dispatch({ type: 'CART_FULL_TRIP_PRICE' });
    };

    const onValidate = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/`,
            data: {
                flight_taxes: flight_taxes,
                flight_purchase_price: flight_purchase_price,
                flight_purchase_currency: flight_purchase_currency === 47 ? 47 : flight_purchase_currency.id,
                flight_custom_rate_type: flight_margin_type,
                flight_custom_value: flight_margin_value,
                terrestrial_purchase_price: terrestrial_purchase_price,
                terrestrial_purchase_currency: terrestrial_purchase_currency === 47 ? 47 : terrestrial_purchase_currency.id,
                terrestrial_custom_rate_type: terrestrial_margin_type,
                terrestrial_custom_value: terrestrial_margin_value
            }
        }).then(response => {
            dispatch({ type: 'TRIP_SET_DATA_TRIP', payload: { data_trip: response.data } });
            dispatch({ type: 'CART_FULL_TRIP_PRICE' });
        }).catch(error => {
            console.log(error);
        });
    };
    const onCancel = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/`,
            data: {
                flight_price_delete: true,
                terrestrial_price_delete: true,
                flight_taxes: null
            }
        }).then(response => {
            dispatch({ type: 'TRIP_SET_DATA_TRIP', payload: { data_trip: response.data } });
            dispatch({ type: 'CART_FULL_TRIP_PRICE' });
        }).catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        let tmp_flight_taxes = 0;
        let tmp_flight_purchase_price = 0;
        let tmp_flight_purchase_currency = 47;
        let tmp_flight_custom_rate_type = 'PER';
        let tmp_flight_custom_value = 0;
        let tmp_terrestrial_purchase_price = 0;
        let tmp_terrestrial_purchase_currency = 47;
        let tmp_terrestrial_custom_rate_type = 'PER';
        let tmp_terrestrial_custom_value = 0;
        if (data_trip.flight_taxes !== null) {
            tmp_flight_taxes = parseFloat(data_trip.flight_taxes);
        }
        if (data_trip.prices_flight.length > 0) {
            let price = null;
            for (let i = 0; i < data_trip.prices_flight.length; i++) {
                if (data_trip.prices_flight[i].master_price) {
                    price = data_trip.prices_flight[i];
                    tmp_flight_purchase_price = price.purchase_price;
                    tmp_flight_purchase_currency = currency_list.find(currency => currency.id === price.purchase_currency);
                    tmp_flight_custom_rate_type = price.custom_rate_type;
                    tmp_flight_custom_value = parseFloat(price.custom_value);
                }
            }
        }
        if (data_trip.prices_terrestrial.length > 0) {
            let price = null;
            for (let i = 0; i < data_trip.prices_terrestrial.length; i++) {
                if (data_trip.prices_terrestrial[i].master_price) {
                    price = data_trip.prices_terrestrial[i];
                    tmp_terrestrial_purchase_price = price.purchase_price;
                    tmp_terrestrial_purchase_currency = currency_list.find(currency => currency.id === price.purchase_currency);
                    tmp_terrestrial_custom_rate_type = price.custom_rate_type;
                    tmp_terrestrial_custom_value = parseFloat(price.custom_value);
                }
            }
        }
        dispatch({ type: 'CART_SET_FULL_TRIP_PRICE', payload: {
            flight_taxes: tmp_flight_taxes,
            flight_purchase_price: tmp_flight_purchase_price,
            flight_purchase_currency: tmp_flight_purchase_currency,
            flight_custom_rate_type: tmp_flight_custom_rate_type,
            flight_custom_value: tmp_flight_custom_value,
            terrestrial_purchase_price: tmp_terrestrial_purchase_price,
            terrestrial_purchase_currency: tmp_terrestrial_purchase_currency,
            terrestrial_custom_rate_type: tmp_terrestrial_custom_rate_type,
            terrestrial_custom_value: tmp_terrestrial_custom_value
        } });
    }, []);
    return (
        <Dialog open={ full_trip_price } fullWidth maxWidth={ 'md' } onClose={ onClose }>
            <DialogTitle onClose={ onClose }>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography fontWeight="bold">
                        { t('cart-material.full-trip-price') } :
                    </Typography>
                    <IconButton onClick={ onClose }>
                        <Close />
                    </IconButton>
                </Stack>
                <Divider sx={{ marginTop: 2 }} />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 12 }>
                        <Typography>{ t('cart-material.trip-flight-price') } :</Typography>
                    </Grid>
                    <CartFullTripPriceFlight/>
                    <Grid item xs={ 12 }>
                        <hr/>
                    </Grid>
                    <Grid item xs={ 12 }>
                        <Typography>{ t('cart-material.trip-terrestrial-price') } :</Typography>
                    </Grid>
                    <CartFullTripPriceTerrestrial/>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={ onCancel }>
                    { t('cart-material.cancel-full-trip-price') }
                </Button>
                <Button variant="outlined" onClick={ onValidate }>
                    { t('cart-material.validate-full-trip-price') }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CartFullTripPrice;
