import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader } from "@mui/material";
import { CartConstructionContentsFormProductsInput } from "./CartConstructionContentsFormProductsInput";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import { getLocalizedValue } from "../Itinerary/utils/getLocalizedValue";
import { CartConstructionContentsFormItemProps } from "./objects/cartConstructionContentsFormItemProps";
import { AppState } from "../../Reducers/Reducers";

type Props = CartConstructionContentsFormItemProps

export const CartConstructionContentsFormItemDragging = React.memo<Props>(
    function CartConstructionContentsFormItemDragging(props): JSX.Element {
        const { t, i18n } = useTranslation();
        const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1);
        const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
        const steps = useMemo(() => {
            return itinerary.filter((step) => {
                return step.step_type === 'STEP';
            }).sort(sortItinerary);
        }, [itinerary]);
        const [from, to] = useMemo(() => {
            if (props.mode === 'by-day') {
                const firstDay = window.moment.utc(steps[0]?.start_date);
                return [
                    firstDay.clone().startOf('day').add(
                        (props.content.day[0] ?? 1) - 1,
                        'days'
                    ).toISOString(),
                    firstDay.clone().startOf('day').add(
                        (props.content.day[props.content.day.length - 1] ?? 1) - 1,
                        'days'
                    ).toISOString()
                ];
            }
            const step = steps[props.index ?? -1];
            return [
                step?.start_date ?? window.moment.utc().toISOString(),
                step?.end_date ?? window.moment.utc().toISOString()
            ];
        }, []);
        const cardRef = useRef<HTMLDivElement>(null);
    
        let destination = '';
    
        if (props.mode === 'by-day') {
            destination = props.content.destinations?.map((destination) => {
                let result = getLocalizedValue(
                    locale,
                    destination.data ?? {
                        name: '',
                        localization: []
                    },
                    'name'
                ) ?? '';
                result = result.length > 0 ?
                    result :
                    (
                        destination.data?.international_name ?? ''
                    );
                return result.split(',')[0] ?? '';
            }).join(' - ') ?? '';
        } else {
            destination = getLocalizedValue(
                locale,
                props.content.destination?.data ?? {
                    name: '',
                    localization: []
                },
                'name'
            ) ?? '';
            destination = destination.length > 0 ?
                destination :
                (
                    props.content.destination?.data?.international_name ?? ''
                );
            destination = destination.split(',')[0] ?? '';
        }

        useEffect(() => {
            if (cardRef.current) {
                const observer = new ResizeObserver((entries) => {
                    if ((entries[0]?.contentRect.height ?? 0) > 0) {
                        window.scrollTo(0, document.body.scrollHeight);
                    }
                });
                observer.observe(cardRef.current);
                return () => observer.disconnect();
            }
        }, []);
    
        return (
            <Card ref={cardRef}>
                <CardHeader
                    title={
                        (
                            props.mode === 'by-day' ?
                                t<string>(
                                    'cart-material.cart-construction-content-title-by-day',
                                    {
                                        day: [...props.content.day].sort((a, b) => a - b).join(', ')
                                    }
                                ) :
                                t<string>(
                                    'cart-material.cart-construction-content-title-by-step',
                                    {
                                        no: (props.index ?? -1) + 1
                                    }
                                )
                        ) +
                        (
                            props.titles[props.locale] ?? ''
                        )
                    }
                    subheader={
                        t<string>(
                            'cart-material.cart-construction-content-title-destination',
                            {
                                name: destination,
                                date: window.moment.utc(from).format('LL') !== window.moment.utc(to).format('LL') ?
                                    t<string>(
                                        'cart-material.cart-construction-products-table-date',
                                        {
                                            from: window.moment.utc(from).format('LL'),
                                            to: window.moment.utc(to).format('LL')
                                        }
                                    ) :
                                    window.moment.utc(from).format('LL')
                            }
                        )
                    }
                    titleTypographyProps={{
                        variant: 'caption',
                        sx: {
                            "display": '-webkit-box',
                            '-webkit-line-clamp': '1',
                            '-webkit-box-orient': 'vertical',
                            "overflow": 'hidden',
                            "textOverflow": 'ellipsis'
                        }
                    }}
                    subheaderTypographyProps={{
                        variant: 'caption',
                        sx: {
                            "display": '-webkit-box',
                            '-webkit-line-clamp': '1',
                            '-webkit-box-orient': 'vertical',
                            "overflow": 'hidden',
                            "textOverflow": 'ellipsis'
                        }
                    }}
                />
                <CardContent sx={{ paddingTop: 0 }}>
                    <CartConstructionContentsFormProductsInput
                        index={props.index ?? -1}
                        mode={props.mode as any}
                        content={props.content as any}
                        locale={props.locale}
                        edit={false}
                        startDate={from}
                        endDate={to}
                        hideProducts
                    />
                </CardContent>
            </Card>
        );
    }
);
