import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Stack, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment-timezone";
import { CartConstructionAddProductPoiPicker } from "./CartConstructionAddProductPoiPicker";
import { CartConstructionReplaceProductContext } from "./utils/cartConstructionReplaceProductContext";
import { PoiSearchResult } from "./objects/poiSearchResult";
import { AppState } from "../../Reducers/Reducers";

export type ReplacePoiSearchInputs = {
    searchedPoi: {
        poi: PoiSearchResult,
        destination: number
    } | null,
    startDate: Moment | null,
    endDate: Moment | null,
}

type Props = {
    value: ReplacePoiSearchInputs,
    onChange: React.Dispatch<React.SetStateAction<ReplacePoiSearchInputs>>
}

export function CartConstructionReplacePoiInputs(props: Props): JSX.Element {
    function onChange<K extends keyof ReplacePoiSearchInputs>(
        key: K,
        value: ReplacePoiSearchInputs[K]
    ): void {
        props.onChange((state) => ({
            ...state,
            [key]: value
        }));
    }

    return (
        <Inputs
            inputs={props.value}
            onChange={onChange}
        />
    );
}


type InputsProps = {
    inputs: ReplacePoiSearchInputs,
    onChange: <K extends keyof ReplacePoiSearchInputs>(key: K, value: ReplacePoiSearchInputs[K]) => void,
}

const Inputs = (props: InputsProps) => {
    const { t } = useTranslation();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const context = useContext(CartConstructionReplaceProductContext);

    const onChangeSearchedPoi = (value: ReplacePoiSearchInputs['searchedPoi']) => {
        props.onChange('searchedPoi', value);
    };

    useEffect(() => {
        if (context.mode === 'add' && context.contentItem) {
            const startDate = window.moment.utc(context.contentItem.from);
            const endDate = window.moment.utc(context.contentItem.to);
            props.onChange('startDate', startDate);
            props.onChange('endDate', endDate);
        }
    }, [
        context.mode,
        context.contentItem?.from,
        context.contentItem?.to
    ]);

    useEffect(() => {
        if (
            context.mode === 'add' &&
            props.inputs.startDate &&
            props.inputs.endDate?.isBefore(props.inputs.startDate)
        ) {
            props.onChange('endDate', props.inputs.startDate);
        }
    }, [
        context.mode,
        props.inputs.startDate,
        props.inputs.endDate
    ]);

    return (
        <>
            {
                context.mode === 'add' &&
                <Box sx={{ textAlign: 'center', marginTop: 2, marginBottom: 2 }}>
                    <CartConstructionAddProductPoiPicker
                        label={t<string>('poi.poi_name')}
                        value={props.inputs.searchedPoi}
                        onChange={onChangeSearchedPoi}
                    />
                </Box>
            }
            <Typography sx={{ marginBottom: 1.5 }}>
                {t<string>('cart-material.cart-construction-dates-dot')}
            </Typography>
            <Stack direction="row" spacing={1} sx={{ marginBottom: 1.5 }}>
                <LocalizationProvider dateAdapter={ AdapterMoment } locale={language}>
                    <DatePicker
                        label={t<string>('cart-material.cart-construction-start-date')}
                        value={props.inputs.startDate}
                        onChange={(value) => props.onChange('startDate', value)}
                        sx={{ width: '100%' }}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={ AdapterMoment } locale={language}>
                    <DatePicker
                        label={t<string>('cart-material.cart-construction-end-date')}
                        value={props.inputs.endDate}
                        minDate={props.inputs.startDate}
                        onChange={(value) => props.onChange('endDate', value)}
                        sx={{ width: '100%' }}
                    />
                </LocalizationProvider>
            </Stack>
        </>
    );
};
