//Dependencies
import { CompositeDecorator } from 'draft-js';
//Components
import Link from './link';

const findLinkEntities = (contentBlock: any, callback: any, contentState: any) => {
    contentBlock.findEntityRanges(
        (character: any) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
};

const decorator = new CompositeDecorator([
    {
        strategy: findLinkEntities,
        component: Link
    }
]);

export default decorator;
