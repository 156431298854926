import axios from "axios";
import memoizee from "memoizee";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { QuickDestination } from "../objects/QuickDestination";

async function get(destinationId: number): Promise<QuickDestination | null> {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.get<QuickDestination>(
            `${API_HREF}client/${window.id_owner}/destinations/quick_get/`,
            {
                headers,
                params: {
                    id: destinationId
                }
            }
        );
        return response.data;
    }

    return null;
}

export const getQuickDestination = memoizee(
    get,
    { promise: true, primitive: true }
);
