//---- Dependencies ----//
import React, {useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const useStyles = makeStyles(theme => ({
    toTop: {
        zIndex: 2,
        position: "fixed",
        bottom: "2vh",
        color: "#0000008A",
        backgroundColor: "DCDCDC",
        "&:hover, &.mui-jss-Mui-focusVisible": {
            transition: "0.3s",
            color: "#397BA6",
            backgroundColor: "DCDCDC"
        },
        right: "5%"
    }
}));

const Scroll = ({showBelow}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    const [show, setShow] = useState(showBelow ? false : true);

    useEffect(() => {
        if (showBelow) {
            window.addEventListener('scroll', handleScroll);
            return () =>  window.removeEventListener('scroll', handleScroll);
        }
    })

    const handleScroll = () => {
        if (window.pageYOffset >= showBelow) {
            if (!show) {
                setShow(true);
            }
        }
        else {
            if (show) {
                setShow(false);
            }
        }
    }

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth`})
    }
    return (
        <div>
            {
                show && (
                    <Tooltip title={t("global.back_to_top")}>
                        <IconButton onClick={handleClick} className={classes.toTop}>
                            <ArrowUpwardIcon/>
                        </IconButton>
                    </Tooltip>
                )
            }
        </div>
    )
}
export default Scroll;