import React from "react";
import { TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";

type Props = {
    url: string | null,
    onChangeUrl: (url: string | null) => void
}

export function MailTemplateVisualEditorLinkUrlOption(props: Props): JSX.Element {
    const { t } = useTranslation();

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const url = event.target.value;
        props.onChangeUrl(url === '' ? null : url);
    };

    return (
        <MailTemplateVisualEditorOptionsSection>
            <Typography>
                {t<string>('shared.mail-template-visual-editor-link-url')}
            </Typography>
            <TextField
                variant="outlined"
                value={props.url ?? ''}
                onChange={onChange}
            />
        </MailTemplateVisualEditorOptionsSection>
    );
}
