import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { makeInvoiceCreateRequest } from "./makeInvoiceCreateRequest";
import { makeInvoiceEditRequest } from "./makeInvoiceEditRequest";

export function useSaveInvoice(options) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const trip_info = useSelector(store => store.menu.trip_info);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    // const version = to_show_version !== null ? to_show_version : trip_info.current_version;
    const versions = useSelector((store) => store.trip.all_data && store.trip.all_data.data);
    const version_index = versions !== null ? versions.map(version => version.status_contract).indexOf('CONFIRMED') : -1;
    const version = version_index !== -1 ? versions[version_index].id : to_show_version !== null ? to_show_version : trip_info?.current_version;


    return ({
        invoice,
        detailsInputs,
        productInputs,
        totalAmount
    }) => {
        const type = invoice ? invoice.invoice_type : options.invoiceType;
        if (invoice) {
            options.setLoading(true);
            makeInvoiceEditRequest({
                trip_info,
                version,
                id: invoice.id,
                data: {
                    company_name: detailsInputs.companyName.data !== '' ? detailsInputs.companyName.data : null,
                    amount: parseFloat(totalAmount).toFixed(2).toString(),
                    invoice_product: productInputs.map((input) => ({
                        name: input.description,
                        total_without_tax: input.totalWithoutTax,
                        flight_tax: input.flightTax,
                        total_amount: (
                            parseFloat(input.totalWithoutTax) +
                            parseFloat(input.flightTax)
                        ).toFixed(2).toString()
                    }))
                }
            }).then((response) => {
                dispatch({ type: 'TRIP_UPDATE_INVOICE', payload: response.data });
                enqueueSnackbar(
                    type === 'INVOICE' ?
                        t('menu.invoice.invoice-updated') :
                        t('menu.invoice.credit-note-updated'),
                    { variant: 'success' }
                );
                options.onClose();
            }).catch((error) => {
                console.error(error);
                enqueueSnackbar(
                    error.message,
                    { variant: 'error' }
                );
            }).finally(() => {
                options.setLoading(false);
            });
        } else {
            options.setLoading(true);
            makeInvoiceCreateRequest({
                trip_info,
                version,
                data: {
                    name: 'Invoice',
                    document: null,
                    is_generated: false,
                    invoice_type: options.invoiceType,
                    company_name: detailsInputs.companyName.data !== '' ? detailsInputs.companyName.data : null,
                    amount: parseFloat(totalAmount).toFixed(2).toString(),
                    invoice_date: window.moment.utc(new Date()).format('YYYY-MM-DD'),
                    invoice_product: productInputs.map((input) => ({
                        name: input.description,
                        total_without_tax: input.totalWithoutTax,
                        flight_tax: input.flightTax,
                        total_amount: (
                            parseFloat(input.totalWithoutTax) +
                            parseFloat(input.flightTax)
                        ).toFixed(2).toString()
                    }))
                }
            }).then(() => {
                dispatch({ type: 'TRIP_SET_INVOICES', payload: null });
                enqueueSnackbar(
                    type === 'INVOICE' ?
                        t('menu.invoice.invoice-created') :
                        t('menu.invoice.credit-note-created'),
                    { variant: 'success' }
                );
                options.onClose();
            }).catch((error) => {
                console.error(error);
                enqueueSnackbar(
                    error.message,
                    { variant: 'error' }
                );
            }).finally(() => {
                options.setLoading(false);
            });
        }
    };
}
