import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";
import { flatten, groupBy, isArray } from "lodash";
import { CartConstructionProductsTable } from "./CartConstructionProductsTable";
import { CartConstructionProductsTableCarItem } from "./CartConstructionProductsTableCarItem";
import { CartConstructionProductsTableTransferItem } from "./CartConstructionProductsTableTransferItem";
import { CartConstructionProductsTableHotelItem } from "./CartConstructionProductsTableHotelItem";
import { CartConstructionProductsTablePoiItem } from "./CartConstructionProductsTablePoiItem";
import { CartConstructionProductsTableFlightsItem } from "./CartConstructionProductsTableFlightItem";
import { CartConstructionProductsTableManualItem } from "./CartConstructionProductsTableManualItem";
import { CartConstructionProductsTableAssistanceItem } from "./CartConstructionProductsTableAssistanceItem";
import { CartFlightCard } from "./CartFlightCard";
import { CartCarCard } from "./CartCarCard";
import { CartManualProductCard } from "./CartManualProductCard";
import { CartTransferCard } from "./CartTransferCard";
import { CartPoiCard } from "./CartPoiCard";
import { CartAccommodationCard } from "./CartAccommodationCard";
import { CartAssistanceCard } from "./CartAssistanceCard";
import { useProductDays } from "./utils/productDays";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import { getDestinationName } from "../Itinerary/utils/getDestinationName";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    cart: Omit<
        ReturnType<typeof useCartProducts> & {manualProducts: ManualProduct[]},
        'trains' | 'ferries' | 'cruises' | 'insurances'
    >,
    enableCardView?: boolean,
    package?: boolean
}

export function CartConstructionProductsByDay(props: Props): JSX.Element {
    const { t } = useTranslation();
    const locale = useSelector((state: AppState) => state.locale.current_locale);
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const steps = useMemo(() => {
        return itinerary.filter((step) => {
            return step.step_type === 'STEP';
        }).sort(sortItinerary);
    }, [itinerary]);
    const getDays = useProductDays();
    const days = Object.values(
        groupBy(
            flatten(
                steps.map((item) => {
                    const days = getDays(item.start_date, item.end_date);

                    return days.map((day, index) => {
                        return {
                            day,
                            date: window.moment.utc(item.start_date).add(index, 'day'),
                            destination: item.destination ?
                                getDestinationName(locale, item.destination).split(',')[0] :
                                null
                        };
                    });
                })
            ),
            (item) => item.day
        )
    ).map((item) => {
        return {
            ...item[0]!,
            destination: item.map((item) => item.destination).filter((item): item is NonNullable<typeof item> => {
                return !!item;
            })
        };
    });
    const tripDays = days.map((item) => item.day);

    if (props.enableCardView) {
        const flightsContent = props.cart.flights.map((item) => (
            <Box
                key={item.flight.id}
                sx={{
                    width: '100%',
                    marginBottom: 3
                }}
            >
                {
                    item.type === 'normal' &&
                    <CartFlightCard
                        type="normal"
                        flight={item.flight}
                    />
                }
                {
                    item.type === 'manual' &&
                    <CartFlightCard
                        type="manual"
                        flight={item.flight}
                    />
                }
            </Box>
        ));
        const outsideTripContents = props.cart.cars.filter((product) => {
            const productDays = getDays(product.car.start_date);
            return productDays.length > 0 &&
               !tripDays.includes(productDays[0]!);
        }).map((item, index) => {
            return {
                date: item.car.start_date,
                isOptional: item.car.is_optional,
                content: (
                    <Box key={item.car.id} sx={{ marginBottom: 3 }}>
                        {
                            item.type === 'normal' &&
                            <CartCarCard car={item.car} index={index} />
                        }
                        {
                            item.type === 'manual' &&
                            <CartManualProductCard product={item.car} />
                        }
                    </Box>
                )
            };
        }).concat(
            props.cart.transfers.filter((product) => {
                const productDays = getDays(product.transfer.start_date);
                return productDays.length > 0 &&
                   !tripDays.includes(productDays[0]!);
            }).map((item) => {
                return {
                    date: item.transfer.start_date,
                    isOptional: item.transfer.is_optional,
                    content: (
                        <Box key={item.transfer.id} sx={{ marginBottom: 3 }}>
                            {
                                item.type === 'normal' &&
                                <CartTransferCard transfer={item.transfer} />
                            }
                            {
                                item.type === 'manual' &&
                                <CartManualProductCard product={item.transfer} />
                            }
                        </Box>
                    )
                };
            })
        ).concat(
            props.cart.pois.filter((product) => {
                const productDays = getDays(product.poi.start_date);
                return productDays.length > 0 &&
                   !tripDays.includes(productDays[0]!);
            }).map((item) => {
                return {
                    date: item.poi.start_date,
                    isOptional: item.poi.is_optional,
                    content: (
                        <Box key={item.poi.id} sx={{ marginBottom: 3 }}>
                            {
                                item.type === 'normal' &&
                                <CartPoiCard poi={item.poi} />
                            }
                            {
                                item.type === 'manual' &&
                                <CartManualProductCard product={item.poi} />
                            }
                        </Box>
                    )
                };
            })
        ).concat(
            props.cart.manualProducts.filter((product) => {
                const productDays = getDays(product.start_date);
                return product.product_type !== 11 &&
                       productDays.length > 0 &&
                       !tripDays.includes(productDays[0]!);
            }).map((item) => {
                return {
                    date: item.start_date,
                    isOptional: item.is_optional,
                    content: (
                        <Box key={item.id} sx={{ marginBottom: 3 }}>
                            <CartManualProductCard product={item} />
                        </Box>
                    )
                };
            })
        ).concat(
            props.cart.accommodations.filter((product) => {
                const productDays = getDays(product.accommodation.start_date);
                return productDays.length > 0 &&
                   !tripDays.includes(productDays[0]!);
            }).map((item) => {
                return {
                    date: item.accommodation.start_date,
                    isOptional: item.accommodation.is_optional,
                    content: (
                        <Box key={item.accommodation.id} sx={{ marginBottom: 3 }}>
                            {
                                item.type === 'normal' &&
                                <CartAccommodationCard
                                    type="accommodation"
                                    accommodation={item.accommodation}
                                />
                            }
                            {
                                item.type === 'manual' &&
                                <CartAccommodationCard
                                    type="manual"
                                    accommodation={item.accommodation}
                                />
                            }
                        </Box>
                    )
                };
            })
        ).sort((a, b) => {
            const aStartDate = window.moment.utc(a.date);
            const bStartDate = window.moment.utc(b.date);
            if (aStartDate.isSame(bStartDate, 'minutes')) {
                if (!a.isOptional) {
                    return -1;
                }
            }
            return aStartDate.isBefore(bStartDate) ?
                -1 :
                1;
        }).map((item) => item.content);

        const otherProductContents = props.cart.cars.filter((product) => {
            return getDays(product.car.start_date).length === 0;
        }).map((item, index) => (
            <Box key={item.car.id} sx={{ marginBottom: 3 }}>
                {
                    item.type === 'normal' &&
                    <CartCarCard car={item.car} index={index} />
                }
                {
                    item.type === 'manual' &&
                    <CartManualProductCard product={item.car} />
                }
            </Box>
        )).concat(
            props.cart.transfers.filter((product) => {
                return getDays(product.transfer.start_date).length === 0;
            }).map((item) => (
                <Box key={item.transfer.id} sx={{ marginBottom: 3 }}>
                    {
                        item.type === 'normal' &&
                        <CartTransferCard transfer={item.transfer} />
                    }
                    {
                        item.type === 'manual' &&
                        <CartManualProductCard product={item.transfer} />
                    }
                </Box>
            ))
        ).concat(
            props.cart.accommodations.filter((product) => {
                return getDays(product.accommodation.start_date).length === 0;
            }).map((item) => (
                <Box key={item.accommodation.id} sx={{ marginBottom: 3 }}>
                    {
                        item.type === 'normal' &&
                        <CartAccommodationCard
                            type="accommodation"
                            accommodation={item.accommodation}
                        />
                    }
                    {
                        item.type === 'manual' &&
                        <CartAccommodationCard
                            type="manual"
                            accommodation={item.accommodation}
                        />
                    }
                </Box>
            ))
        ).concat(
            props.cart.pois.filter((product) => {
                return getDays(product.poi.start_date).length === 0;
            }).map((item) => (
                <Box key={item.poi.id} sx={{ marginBottom: 3 }}>
                    {
                        item.type === 'normal' &&
                        <CartPoiCard poi={item.poi} />
                    }
                    {
                        item.type === 'manual' &&
                        <CartManualProductCard product={item.poi} />
                    }
                </Box>
            ))
        ).concat(
            props.cart.assistances.map((item) => (
                <Box key={item.assistance.id} sx={{ marginBottom: 3 }}>
                    {
                        item.type === 'normal' &&
                        <CartAssistanceCard assistance={item.assistance} />
                    }
                    {
                        item.type === 'manual' &&
                        <CartManualProductCard product={item.assistance} />
                    }
                </Box>
            ))
        ).concat(
            props.cart.manualProducts.filter((product) => {
                return ![0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 12, 13, 14, 23].includes(product.product_type) ||
                       getDays(product.start_date).length === 0;
            }).map((item) => (
                <Box key={item.id} sx={{ marginBottom: 3 }}>
                    <CartManualProductCard product={item} />
                </Box>
            ))
        );

        return (
            <>
                {
                    flightsContent.length > 0 &&
                    <>
                        <Box sx={{ marginBottom: 3 }}>
                            <Typography sx={{ fontSize: 30, fontWeight: 'bold', textTransform: 'capitalize' }}>
                                {
                                    t<string>(
                                        'cart-material.cart-construction-total-flights',
                                        { count: props.cart.flights.length }
                                    )
                                }
                            </Typography>
                        </Box>
                        {flightsContent}
                    </>
                }
                {
                    days.map((item) => {
                        const dayContents = props.cart.transfers.filter((product) => {
                            return getDays(product.transfer.start_date).includes(item.day);
                        }).map((item) => (
                            <Box key={item.transfer.id} sx={{ marginBottom: 3 }}>
                                {
                                    item.type === 'normal' &&
                                    <CartTransferCard transfer={item.transfer} />
                                }
                                {
                                    item.type === 'manual' &&
                                    <CartManualProductCard product={item.transfer} />
                                }
                            </Box>
                        )).concat(
                            props.cart.cars.filter((product) => {
                                return getDays(product.car.start_date).includes(item.day);
                            }).map((item, index) => (
                                <Box key={item.car.id} sx={{ marginBottom: 3 }}>
                                    {
                                        item.type === 'normal' &&
                                        <CartCarCard car={item.car} index={index} />
                                    }
                                    {
                                        item.type === 'manual' &&
                                        <CartManualProductCard product={item.car} />
                                    }
                                </Box>
                            ))
                        ).concat(
                            props.cart.accommodations.filter((product) => {
                                return getDays(product.accommodation.start_date).includes(item.day);
                            }).map((item) => (
                                <Box key={item.accommodation.id} sx={{ marginBottom: 3 }}>
                                    {
                                        item.type === 'normal' &&
                                        <CartAccommodationCard
                                            type="accommodation"
                                            accommodation={item.accommodation}
                                        />
                                    }
                                    {
                                        item.type === 'manual' &&
                                        <CartAccommodationCard
                                            type="manual"
                                            accommodation={item.accommodation}
                                        />
                                    }
                                </Box>
                            ))
                        ).concat(
                            props.cart.pois.filter((product) => {
                                return getDays(product.poi.start_date).includes(item.day);
                            }).map((item) => (
                                <Box key={item.poi.id} sx={{ marginBottom: 3 }}>
                                    {
                                        item.type === 'normal' &&
                                        <CartPoiCard poi={item.poi} />
                                    }
                                    {
                                        item.type === 'manual' &&
                                        <CartManualProductCard product={item.poi} />
                                    }
                                </Box>
                            ))
                        ).concat(
                            props.cart.manualProducts.filter((product) => {
                                return [0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 12, 13, 14, 23].includes(product.product_type) &&
                                       getDays(product.start_date).includes(item.day);
                            }).map((item) => (
                                <Box key={item.id} sx={{ marginBottom: 3 }}>
                                    <CartManualProductCard product={item} />
                                </Box>
                            ))
                        );
                        if (dayContents.length > 0) {
                            return (
                                <React.Fragment key={item.day}>
                                    <Box sx={{ marginBottom: 3 }}>
                                        <Typography sx={{ fontSize: 30, fontWeight: 'bold', textTransform: 'capitalize' }}>
                                            {t<string>('cart-material.cart-construction-day-colon', { day: item.day })}{' '}
                                            {
                                                item.destination.reduce((prev, current, index, array) => {
                                                    const result = [
                                                        ...prev,
                                                        current,
                                                        <ArrowRightAlt fontSize="small" sx={{ marginLeft: 0.5, marginRight: 0.5 }} />
                                                    ];
    
                                                    if (index === array.length - 1) {
                                                        result.pop();
                                                    }
    
                                                    return result;
                                                }, [] as React.ReactNode[])
                                            }{' - '}
                                            {
                                                item.date.format('LL')
                                            }
                                        </Typography>
                                    </Box>
                                    {dayContents}
                                </React.Fragment>
                            );
                        }
                    })
                }
                {
                    outsideTripContents.length > 0 &&
                    <>
                        <Box sx={{ marginBottom: 3 }}>
                            <Typography sx={{ fontSize: 30, fontWeight: 'bold', textTransform: 'capitalize' }}>
                                {t<string>('cart-material.cart-construction-products-outside-trip')}
                            </Typography>
                        </Box>
                        {outsideTripContents}
                    </>
                }
                {
                    otherProductContents.length > 0 &&
                    <>
                        <Box sx={{ marginBottom: 3 }}>
                            <Typography sx={{ fontSize: 30, fontWeight: 'bold', textTransform: 'capitalize' }}>
                                {t<string>('cart-material.cart-construction-other-products')}
                            </Typography>
                        </Box>
                        {otherProductContents}
                    </>
                }
            </>
        );
    }

    return (
        <CartConstructionProductsTable
            sections={
                (
                    [
                        {
                            title: t<string>(
                                'cart-material.cart-construction-total-flights',
                                { count: props.cart.flights.length }
                            ),
                            content: props.cart.flights.map((item) => (
                                <CartConstructionProductsTableFlightsItem
                                    key={`${item.type}-${item.flight.id}`}
                                    item={item}
                                />
                            ))
                        }
                    ] as Parameters<typeof CartConstructionProductsTable>[0]['sections']
                ).concat(
                    days.map((item): Parameters<typeof CartConstructionProductsTable>[0]['sections'][number] => {
                        return {
                            title: (
                                <Box sx={{ display: 'flex', alignItems: 'center' }} component="span">
                                    {t<string>('cart-material.cart-construction-day-colon', { day: item.day })}{' '}
                                    {
                                        item.destination.reduce((prev, current, index, array) => {
                                            const result = [
                                                ...prev,
                                                current,
                                                <ArrowRightAlt fontSize="small" sx={{ marginLeft: 0.5, marginRight: 0.5 }} />
                                            ];

                                            if (index === array.length - 1) {
                                                result.pop();
                                            }

                                            return result;
                                        }, [] as React.ReactNode[])
                                    }{' - '}
                                    {
                                        item.date.format('LL')
                                    }
                                </Box>
                            ),
                            content: props.cart.transfers.filter((product) => {
                                return getDays(product.transfer.start_date).includes(item.day);
                            }).map((item) => (
                                <CartConstructionProductsTableTransferItem
                                    key={`${item.type}-${item.transfer.id}`}
                                    item={item}
                                />
                            )).concat(
                                props.cart.cars.filter((product) => {
                                    return getDays(product.car.start_date).includes(item.day);
                                }).map((item, index) => (
                                    <CartConstructionProductsTableCarItem
                                        key={`${item.type}-${item.car.id}`}
                                        index={index}
                                        item={item}
                                    />
                                ))
                            ).concat(
                                props.cart.accommodations.filter((product) => {
                                    return getDays(product.accommodation.start_date).includes(item.day);
                                }).map((item, _, array) => (
                                    <CartConstructionProductsTableHotelItem
                                        key={`${item.type}-${item.accommodation.id}`}
                                        item={item}
                                        accommodations={array}
                                    />
                                ))
                            ).concat(
                                props.cart.pois.filter((product) => {
                                    return getDays(product.poi.start_date).includes(item.day);
                                }).map((item) => (
                                    <CartConstructionProductsTablePoiItem
                                        key={`${item.type}-${item.poi.id}`}
                                        item={item}
                                    />
                                ))
                            ).concat(
                                props.cart.manualProducts.filter((product) => {
                                    return [0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 12, 13, 14, 23].includes(product.product_type) &&
                                           getDays(product.start_date).includes(item.day);
                                }).map((item) => (
                                    <CartConstructionProductsTableManualItem
                                        key={`${item.product_type}-${item.id}`}
                                        item={item}
                                    />
                                ))
                            )
                        };
                    }).concat([
                        {
                            title: t<string>('cart-material.cart-construction-products-outside-trip'),
                            content: props.cart.transfers.filter((product) => {
                                const productDays = getDays(product.transfer.start_date);
                                return productDays.length > 0 &&
                                   !tripDays.includes(productDays[0]!);
                            }).map((item) => {
                                return {
                                    date: item.transfer.start_date,
                                    isOptional: item.transfer.is_optional,
                                    content: (
                                        <CartConstructionProductsTableTransferItem
                                            key={`${item.type}-${item.transfer.id}`}
                                            item={item}
                                        />
                                    )
                                };
                            }).concat(
                                props.cart.cars.filter((product) => {
                                    const productDays = getDays(product.car.start_date);
                                    return productDays.length > 0 &&
                                       !tripDays.includes(productDays[0]!);
                                }).map((item, index) => {
                                    return {
                                        date: item.car.start_date,
                                        isOptional: item.car.is_optional,
                                        content: (
                                            <CartConstructionProductsTableCarItem
                                                key={`${item.type}-${item.car.id}`}
                                                index={index}
                                                item={item}
                                            />
                                        )
                                    };
                                })
                            ).concat(
                                props.cart.accommodations.filter((product) => {
                                    const productDays = getDays(product.accommodation.start_date);
                                    return productDays.length > 0 &&
                                       !tripDays.includes(productDays[0]!);
                                }).map((item, _, array) => {
                                    return {
                                        date: item.accommodation.start_date,
                                        isOptional: item.accommodation.is_optional,
                                        content: (
                                            <CartConstructionProductsTableHotelItem
                                                key={`${item.type}-${item.accommodation.id}`}
                                                item={item}
                                                accommodations={array}
                                            />
                                        )
                                    };
                                })
                            ).concat(
                                props.cart.pois.filter((product) => {
                                    const productDays = getDays(product.poi.start_date);
                                    return productDays.length > 0 &&
                                       !tripDays.includes(productDays[0]!);
                                }).map((item) => {
                                    return {
                                        date: item.poi.start_date,
                                        isOptional: item.poi.is_optional,
                                        content: (
                                            <CartConstructionProductsTablePoiItem
                                                key={`${item.type}-${item.poi.id}`}
                                                item={item}
                                            />
                                        )
                                    };
                                })
                            ).concat(
                                props.cart.manualProducts.filter((product) => {
                                    const productDays = getDays(product.start_date);
                                    return product.product_type !== 11 &&
                                           productDays.length > 0 &&
                                           !tripDays.includes(productDays[0]!);
                                }).map((item) => {
                                    return {
                                        date: item.start_date,
                                        isOptional: item.is_optional,
                                        content: (
                                            <CartConstructionProductsTableManualItem
                                                key={`${item.product_type}-${item.id}`}
                                                item={item}
                                            />
                                        )
                                    };
                                })
                            ).sort((a, b) => {
                                const aStartDate = window.moment.utc(a.date);
                                const bStartDate = window.moment.utc(b.date);
                                if (aStartDate.isSame(bStartDate, 'minutes')) {
                                    if (!a.isOptional) {
                                        return -1;
                                    }
                                }
                                return aStartDate.isBefore(bStartDate) ?
                                    -1 :
                                    1;
                            }).map((item) => item.content)
                        }
                    ]).concat([
                        {
                            title: t<string>('cart-material.cart-construction-other-products'),
                            content: props.cart.transfers.filter((product) => {
                                return getDays(product.transfer.start_date).length === 0;
                            }).map((item) => (
                                <CartConstructionProductsTableTransferItem
                                    key={`${item.type}-${item.transfer.id}`}
                                    item={item}
                                />
                            )).concat(
                                props.cart.cars.filter((product) => {
                                    return getDays(product.car.start_date).length === 0;
                                }).map((item, index) => (
                                    <CartConstructionProductsTableCarItem
                                        key={`${item.type}-${item.car.id}`}
                                        index={index}
                                        item={item}
                                    />
                                ))
                            ).concat(
                                props.cart.accommodations.filter((product) => {
                                    return getDays(product.accommodation.start_date).length === 0;
                                }).map((item, _, array) => (
                                    <CartConstructionProductsTableHotelItem
                                        key={`${item.type}-${item.accommodation.id}`}
                                        item={item}
                                        accommodations={array}
                                    />
                                ))
                            ).concat(
                                props.cart.pois.filter((product) => {
                                    return getDays(product.poi.start_date).length === 0;
                                }).map((item) => (
                                    <CartConstructionProductsTablePoiItem
                                        key={`${item.type}-${item.poi.id}`}
                                        item={item}
                                    />
                                ))
                            ).concat(
                                props.cart.assistances.map((item) => (
                                    <CartConstructionProductsTableAssistanceItem
                                        key={`${item.type}-${item.assistance.id}`}
                                        item={item}
                                    />
                                ))
                            ).concat(
                                props.cart.manualProducts.filter((product) => {
                                    return ![0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 12, 13, 14, 23].includes(product.product_type) ||
                                           getDays(product.start_date).length === 0;
                                }).map((item) => (
                                    <CartConstructionProductsTableManualItem
                                        key={`${item.product_type}-${item.id}`}
                                        item={item}
                                    />
                                ))
                            )
                        }
                    ])
                ).filter((item) => {
                    return isArray(item.content) ?
                        item.content.length > 0 :
                        true;
                })
            }
            indent={props.package}
        />
    );
}
