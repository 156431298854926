//---- Dependencies ----//
import React, {Fragment, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import i18n from "../../i18n";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Chip, FormControl, Input, InputLabel, MenuItem, Select } from "@material-ui/core";

import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { SetHistorydest, SetItineraryTypeData, SetItineraryTypeLoading } from "../../Actions/ItineraryType";
import { DestinationChange } from "../../Actions/Header";
import MaterialHeader from "../Header/MaterialHeader.jsx";
import MaterialLogin from "../Home/MaterialLogin";
import MaterialDestCard from "../Home/MaterialDestCard";
import MaterialItiTypeCard from "../ItineraryType/MaterialItiTypeCard";
import MaterialCircuitCard from "../ItineraryType/MaterialCircuitCard";
import MaterialPackageCard from "../ItineraryType/MaterialPackageCard";
import MaterialBreadcrumb from "../ItineraryType/MaterialBreadcrumb";
import VisiteurHeader from "./VisiteursHeader";
import MaterialDuplicateDialog from "../ItineraryType/MaterialDuplicateDialog";
import GetNewChildren from "../ItineraryType/Functions/GetNewChildren";
import Footer from "../Footer/Footer";
import VisiteursDestinationFilters from "./VisiteursDestinationFilters";
import VisiteursProductsList from "./VisiteursProductsList";
import localeText from "../Functions/localeText";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    headerPicture: {
        height: "50vh"
    },
    absoluteCenter: {
        margin: 0,
        position: "absolute",
        top: "24%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    customButton: {
        background: "#BF202D",
        // padding: "15px 40px",
        textAlign: "center",
        color: "#fff",
        fontSize: 20,
        textTransform: "none",
        "&:hover": {
            background: "#cc4f2a"
        }
    },
    headerContainer: {
        height: "50vh",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    },
    destinationTitle: {
        textAlign: "center",
        color: "white",
        textShadow: "black 1px 0 10px",
        fontFamily: "Roboto",
        marginBottom: 50,
        fontWeight: 400
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    imgContainer: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxHeight: 450
        },
        maxHeight: 450
    },
    coverImg: {
        display: 'block',
        width: '100%',
        objectFit: 'cover',
        maxHeight: 107,
        objectPosition: '25% 55%',
        [theme.breakpoints.up('sm')]: {
            maxHeight: 310,
        },
    },
    largeLogo: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
    },
    underline: {
        textDecoration: 'underline'
    },
    pointer: {
        cursor: 'pointer'
    }
}));


const VisiteursDestinationProducts = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const config = JSON.parse(localStorage.getItem("config"));
    const quotation_code = config.quotation_code;
    const [logo] = useState(config !== null && config.logo !== undefined && config.logo !== null && config.logo.url !== undefined && config.logo.url !== null ? config.logo : null);
    const locales = useSelector(state => state.user.locales);
    const current_locale = locales.find((el) => {return el.language_code === i18n.language});

    const itinerary_type_data = useSelector(store => store.itinerary_type.itinerary_type_data);
    const second_dest_list = useSelector(store => store.itinerary_type.second_dest_list);
    const is_connected = useSelector(store => store.user.is_connected);
    const ititype_is_loading = useSelector(store => store.itinerary_type.ititype_is_loading);
    const save_destination = useSelector(store => store.itinerary_type.save_destination);
    const dest_history = useSelector(store => store.itinerary_type.dest_history);
    const user = useSelector(store => store.user.user);
    const full_dest = useSelector(store => store.home.full_destination);
    const filters_type = useSelector(store => store.home.filters_type);
    const list_type = useSelector(store => store.home.list_type);
    const filter_destinations = useSelector(store => store.home.filter_destinations);

    const [loading, setLoading] = useState(false);
    const [itiType, setItiType] = useState([]);
    const [packageType, setPackageType] = useState([]);
    const [circuitType, setCircuitType] = useState([]);
    const [duplicationOpen, setDuplicationOpen] = useState(false);
    const [loginDialog, setLoginDialog] = useState(false);
    const [allType, setAllType] = useState([]);
    const [displayCard, setDisplayCard] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [destinations, setDestinations] = useState([]);

    // const [second_dest_list, setSecondDestList] = useState([]);

    let id = props.params.desti_id;
    let history = [];
    let loading_nb = 0;
    let data_package = [];
    let data_circuit = [];
    let data_iti = [];

    useEffect(() => {
        console.log('save_destination:', save_destination);
        if (save_destination[id] === undefined) {
            getDestinationData();
        }
        else {
            setItiType(save_destination[id].itiType.slice());
            setPackageType(save_destination[id].packageType.slice());
            setCircuitType(save_destination[id].circuitType.slice());
            setAllType([...save_destination[id].packageType, ...save_destination[id].circuitType, ...save_destination[id].itiType]);
            GetNewChildren(id, dispatch);
        }
        setNewBreadCrumbs();
    }, [id]);
    useEffect(() => {
        if (itinerary_type_data !== null && save_destination[id] === undefined) {
            getNewCards();
            // setNewBreadCrumbs();
        }
    }, [itinerary_type_data]);
    useEffect(() => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (full_dest.length === 0) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/destinations/?limit=1000&ordering=-current_version__note&visibility__in=PUBLIC,PRIVATE_TO&reduced=true&homepage=true`,
            }).then(function (response) {
                console.log('data:', response);
                let tmp_arr = [];
                for (let i = 0; i < 9; i++) {
                    if (response.data.results[i] !== undefined) {
                        tmp_arr.push(response.data.results[i]);
                    }
                }
                dispatch({type: 'HOME_SET_FULL_DESTINATIONS', payload: [...response.data.results]});
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('destination error:', error);
            });
        }
    }, []);
    useEffect(() => {
        let tmp_data = [];
        if (filters_type.length !== 0) {
            // filters_type 0: package 1: circuits, 2: itinerary type
            allType.map((package_info) => {
                if (package_info.itinerary_type !== undefined) {
                    if (filters_type.includes(t('itineraryType.itinerary_type'))) {
                        tmp_data.push(package_info);
                    }
                } else if (package_info.description_version !== undefined) {
                    if (filters_type.includes(t('itineraryType.circuits'))) {
                        tmp_data.push(package_info);
                    }
                } else {
                    if (filters_type.includes(t('itineraryType.packages'))) {
                        tmp_data.push(package_info);
                    }
                }
            });
        } else {
            tmp_data = [...allType];
        }
        tmp_data = tmp_data.sort((a, b) => {
            let fa = '';
            let fb = '';
            if (orderBy === 'name') {
                fa = a.name.toLowerCase();
                fb = b.name.toLowerCase();
            }
            if (orderBy === 'region') {
                if (a.destination !== undefined && a.destination !== null) {
                    fa = localeText(current_locale.id, a.destination.data.localization, a.destination.data.international_name, 'name');
                } else if (a.data !== undefined && a.data !== null && a.data.length !== 0) {
                    let data = a.data.find(el => el.id === a.current_version);
                    if (data.destination !== undefined && data.destination !== null) {
                        fa = localeText(current_locale.id, data.destination.data.localization, data.destination.data.international_name, 'name');
                    }
                }
                if (b.destination !== undefined && b.destination !== null) {
                    fb = localeText(current_locale.id, b.destination.data.localization, b.destination.data.international_name, 'name');
                } else if (b.data !== undefined && b.data !== null && b.data.length !== 0) {
                    let data = b.data.find(el => el.id === b.current_version);
                    if (data.destination !== undefined && data.destination !== null) {
                        fb = localeText(current_locale.id, data.destination.data.localization, data.destination.data.international_name, 'name');
                    }
                }
            }
            if (orderBy === 'type') {
                if (a.itinerary_type !== undefined && a.itinerary_type) {
                    fa = 'iti type';
                } else if (a.description_version !== undefined) {
                    fa = 'circuit';
                } else {
                    fa = 'package';
                }
                if (b.itinerary_type !== undefined && b.itinerary_type) {
                    fb = 'iti type';
                } else if (b.description_version !== undefined) {
                    fb = 'circuit';
                } else {
                    fb = 'package';
                }
            }
            if (fa < fb) {
                return -1 * (order === 'asc' ? 1 : -1);
            }
            if (fa > fb) {
                return 1 * (order === 'asc' ? 1 : -1);
            }
            return 0;
        });
        if (filter_destinations.length !== 0) {
            tmp_data = tmp_data.filter((row) => {
                let desti = '';
                if (row.destination !== undefined && row.destination !== null) {
                    desti = localeText(current_locale.id, row.destination.data.localization, row.destination.data.international_name, 'name');
                } else if (row.data !== undefined && row.data !== null && row.data.length !== 0) {
                    let data = row.data.find(el => el.id === row.current_version);
                    if (data.destination !== undefined && data.destination !== null) {
                        desti = localeText(current_locale.id, data.destination.data.localization, data.destination.data.international_name, 'name');
                    }
                }
                if (filter_destinations.includes(desti)) {
                    return true;
                }
            });
        }
        setDisplayCard(tmp_data);
    }, [allType, filters_type, order, orderBy, filter_destinations]);
    useEffect(() => {
        let tmp_desti = [];
        allType.map((row) => {
            if (row.destination !== undefined && row.destination !== null) {
                let desti = localeText(current_locale.id, row.destination.data.localization, row.destination.data.international_name, 'name');
                if (!tmp_desti.includes(desti)) {
                    tmp_desti.push(desti);
                }
            } else if (row.data !== undefined && row.data !== null && row.data.length !== 0) {
                let data = row.data.find(el => el.id === row.current_version);
                if (data.destination !== undefined && data.destination !== null) {
                    let desti = localeText(current_locale.id, data.destination.data.localization, data.destination.data.international_name, 'name');
                    if (!tmp_desti.includes(desti)) {
                        tmp_desti.push(desti);
                    }
                }
            }
        });
        tmp_desti = tmp_desti.sort();
        setDestinations(tmp_desti);
    }, [allType]);
    const getDestinationData = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
        	dispatch(SetItineraryTypeLoading(true));
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/destinations/${id}/`,
            }).then(function (response) {
                dispatch(SetItineraryTypeData(response.data.data));
                // dispatch(DestinationChange(response.data));
                dispatch(SetItineraryTypeLoading(false));
                // setNewBreadCrumbs(response.data.destination, response.data.name !== undefined ? response.data.name : response.data.international_name);
                GetNewChildren(id, dispatch);
            }).catch(function (error) {
                dispatch(SetItineraryTypeLoading(false));
                console.log('err:', error);
            })
        }
    }
    const setNewBreadCrumbs = () => {
    	let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/destinations/${id}/breadcrumbs/`,
            }).then(function (response) {
                let data = response.data;
                history = [];
                let is_main = (getDestinationPositionOnBreadcrumbs(data.child, id));
                if (is_main.type !== undefined) {
                    if (is_main.type === 1) {
                        let tmp_arr = [];
                        let tmp_obj = {
                            name: t("home.back_home"),
                            data: null,
                            need_redirect: false

                        };
                        tmp_arr.push(tmp_obj);
                        tmp_obj = {
                            name: is_main.name,
                            data: is_main,
                            need_redirect: true
                        };
                        tmp_arr.push(tmp_obj);
                        dispatch(SetHistorydest(tmp_arr));
                    } else {
                        let tmp_arr = [];
                        let tmp_obj = {
                            name: t("home.back_home"),
                            data: null,
                            need_redirect: false

                        };
                        tmp_arr.push(tmp_obj);
                        for (let i = 0; i < history.length; i++) {
                            tmp_obj = {
                                name: history[i].name,
                                data: history[i],
                                need_redirect: true
                            };
                            tmp_arr.push(tmp_obj);
                        }
                        dispatch(SetHistorydest(tmp_arr));
                    }
                }
            }).catch(function (error) {
                console.log('err:', error);
            });
        }
    }
    const getDestinationPositionOnBreadcrumbs = (data, id_to_find) => {
		if (data.id !== parseFloat(id_to_find)) {
			if (data.data.type !== 0) {
				history.push(data.data);
			}
			return (getDestinationPositionOnBreadcrumbs(data.child, id_to_find));
		} else {
			history.push(data.data);
			return (data.data);
		}
	}
    const redirectLoading = () => {
        // if (loading_nb === 3) {
            setAllType([...data_package, ...data_circuit, ...data_iti]);
	    	setLoading(false);
            dispatch({
                type: "ITINERARY_SAVE_DESTINATION",
                payload: {
                    destination_id: id,
                    new_data: {
                        packageType: data_package,
                        circuitType: data_circuit,
                        itiType: data_iti,
                    }
                }
            })
    	// }
    }
    const handleDuplicateOpen = () => {
        if (is_connected) {
            setDuplicationOpen(!duplicationOpen);
        } else {
            setLoginDialog(!loginDialog);
        }
    }
    const getNewCards = () => {
    	let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            if (itinerary_type_data !== null) {
                setLoading(true);
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/destinations/${id}/get_inheritance/`,
                }).then(function (response) {
                    loading_nb = 0;
                    let destination_list = response.data.split(',');
                    let array_request = [];
                    array_request.push(
                        axios({
                            method: "POST",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/by_destination/?typical=1&visibility__in=PUBLIC,PRIVATE_TO`,
                            data: JSON.stringify({destination_list: destination_list}),
                        })
                    );
                    // array_request.push(
                    //     axios({
                    //         method: "POST",
                    //         headers: headers,
                    //         url: `${API_HREF}client/${window.id_owner}/packages/by_destination/?visibility__in=PUBLIC,PRIVATE_TO`,
                    //         data: JSON.stringify({destination_list: destination_list}),
                    //     })
                    // );
                    array_request.push(
                        axios({
                            method: "POST",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/circuits/by_destination/?visibility__in=PUBLIC,PRIVATE_TO`,
                            data: JSON.stringify({destination_list: destination_list}),
                        })
                    );
                    
                    axios.all([...array_request]).then(axios.spread((...responses) => {
                        console.log('responses axios all:', responses);
                        data_iti = [...responses[0].data];
                        // data_package = [...responses[1].data];
                        responses[1].data.map((data) => {
                            if (data.type === "CIRCUIT") {
                                data_circuit.push(data);
                            } else {
                                data_package.push(data);
                            }
                        });
                        console.log('data_package:', data_package);
                        setPackageType(data_package);
                        setCircuitType(data_circuit);
                        setItiType(data_iti);
                        if (responses.length === 2) {
                            redirectLoading();
                        }
                    }));
                    // axios({
                    //     method: "POST",
                    //     headers: headers,
                    //     url: `${API_HREF}client/${window.id_owner}/packages/by_destination/?visibility__in=PUBLIC,PRIVATE_TO`,
                    //     data: JSON.stringify({destination_list: destination_list}),
                    // }).then(function (data) {
                    //     console.log('data package:', data.data);
                    //     data_package = data.data.slice();
                    //     loading_nb++;
                    //     redirectLoading();
                    //     setPackageType(data.data);
                    // }).catch(function (error) {
                    //     loading_nb++;
                    //     console.log('error package:', error);
                    // });
                    // axios({
                    //     method: "POST",
                    //     headers: headers,
                    //     url: `${API_HREF}client/${window.id_owner}/circuits/by_destination/?visibility__in=PUBLIC,PRIVATE_TO`,
                    //     data: JSON.stringify({destination_list: destination_list}),
                    // }).then(function (data) {
                    //     console.log('data circuit:', data.data);
                    //     loading_nb++;
                    //     data_circuit = data.data.slice();
                    //     redirectLoading();
                    //     setCircuitType(data.data);
                    // }).catch(function (error) {
                    //     loading_nb++;
                    //     console.log('error circuit:', error);
                    // });
                    // axios({
                    //     method: "POST",
                    //     headers: headers,
                    //     url: `${API_HREF}client/${window.id_owner}/trip/by_destination/?typical=1&visibility__in=PUBLIC,PRIVATE_TO`,
                    //     data: JSON.stringify({destination_list: destination_list}),
                    // }).then(function (data) {
                    //     console.log('data iti type:', data.data);
                    //     loading_nb++;
                    //     data_iti = data.data.slice();
                    //     redirectLoading();
                    //     setItiType(data.data);
                    // }).catch(function (error) {
                    //     loading_nb++;
                    //     console.log('error circuit:', error);
                    // });
                }).catch(function (error) {
                    console.log('error:', error);
                });
		    }
		}
    }
    let cover_picture = null;
    if (itinerary_type_data !== null && itinerary_type_data.cover_picture !== null) {
        cover_picture = itinerary_type_data.cover_picture;
    }
    else if(itinerary_type_data !== null && itinerary_type_data.cover_picture === null && itinerary_type_data.pictures !== null && itinerary_type_data.pictures.length > 0) {
        cover_picture = itinerary_type_data.pictures[0];
    }
    return (
        <Grid container style={{display: "block"}}>
            <VisiteurHeader router={props.router} setLoginDialog={setLoginDialog} full_dest={full_dest}/>
            <Grid container justify={"center"} alignItems={"center"} alignContent={ "center" }>
                <Grid item className={classes.imgContainer}>
                    <img src={ "/Img/visiteurs/home.avif"} className={classes.coverImg} />
                </Grid>
                <Grid item style={{position: 'absolute'}}>
                    <Grid container direction={'column'} justify={'center'} alignItems={'center'} spacing={ 4 }>
                        <Grid item className={classes.largeLogo}>
                            <img src={ "/Img/visiteurs/Plan&TripBlanc.png"} width={'315px'}/>
                        </Grid>
                        <Grid item>
                            <Button variant={"contained"} fullWidth={fullScreen} className={classes.customButton} onClick={handleDuplicateOpen} >{t("itineraryType.visiteurs_header_btn")}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                (is_connected || ['visiteurs'].includes(quotation_code)) && !loading && (
                    <Grid item style={{paddingBottom: 70}}>
                        <Container className={classes.container}>
                            <VisiteursDestinationFilters destinations={destinations}/>
                            <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={4}>
                            {
                                displayCard.length !== 0 && list_type === 'card' && (
                                    <Grid item style={{width: "100%"}}>
                                        <Grid container direction={"row"} justify={"center"} alignItems={"center"} spacing={2}>
                                        {
                                            displayCard.map((card, package_index) => {
                                                if (card.itinerary_type !== undefined) {
                                                    return <MaterialItiTypeCard key={package_index} iti_info={card} router={props.router} desti_id={id}/>
                                                } else if (card.description_version !== undefined) {
                                                    return <MaterialCircuitCard key={package_index} circuit_info={card} router={props.router} desti_id={id}/>
                                                } else {
                                                    return <MaterialPackageCard key={package_index} package_info={card} router={props.router} desti_id={id}/>
                                                }
                                            })
                                        }
                                        </Grid>
                                    </Grid>
                                )
                            }
                            {
                                displayCard.length !== 0 && list_type === 'list' && (
                                    <Grid item style={{width: "100%"}}>
                                        <VisiteursProductsList displayCard={displayCard} router={props.router} order={order} setOrder={setOrder} orderBy={orderBy} setOrderBy={setOrderBy}/>
                                    </Grid>
                                )
                            }
                            </Grid>
                        </Container>
                    </Grid>
                )
            }
            {
                loading && (
                    <Grid item style={{paddingBottom: 70}}>
                        <Container className={classes.container}>
                            <Grid container direction={"row"} justify={"center"} alignItems={"center"} spacing={4}>
                               <Grid item>
                                    <CircularProgress/>
                               </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                )
            }
            <MaterialDuplicateDialog
                duplicationOpen={duplicationOpen}
                setDuplicationOpen={setDuplicationOpen}
                isFrom={"createNewTrip"}
                duration_day={0}
                router={props.router}
            />
            <MaterialLogin loginDialog={loginDialog} setLoginDialog={setLoginDialog} logo={logo} router={props.router} token={null} id={null}/>
            <Footer/>
        </Grid>
    )
};
export default VisiteursDestinationProducts;