import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import CountAgeStr from "../Functions/CountAgeStr";

import { ReplaceGroup } from "../../../Actions/Flight";
import { CreateStopoverDuration, CreateFlightHours } from "../../../Actions/FlightSearch";

import moment from "moment";

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        // color: '#0000008A',
        letterSpacing: 1.25
    },
    buttonText: {
        fontWeight: "bold",
        fontSize: 10,
        textDecoration: "underline"
    },
    searchText: {
        fontWeight: 900,
        fontSize: 12,
        textTransform: "none"
    },
    gridHover: {
        "&:hover": {
            backgroundColor: "#0000000A"
        }
    },
    spacer: {
        padding: "8px 0px"
    }
}));

const SearchHistory = ({ group_index }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const language = useSelector(store => store.header.language);
    const end_date = useSelector(store => store.trip.end_date);
    const previous_flight_groups = useSelector(store => store.flight.previous_flight_groups);

    const [previousSearches, setPreviousSearches] = useState([]);
    const [displayPrevious, setDisplayPrevious] = useState(false);

    const class_type_mapping = [
        t("flight.class.eco"),
        t("flight.class.premium_eco"),
        t("flight.class.business"),
        t("flight.class.first")
    ];

    moment.locale(language);


    useEffect(() => {
        let temp_previous_searches = [];
        previous_flight_groups.map((group) => {
            let temp_group = Object.assign({}, group);
            let temp_label = [];

            switch (group.journey_type) {
                case 1:
                    // roundtrip
                    let roundtrip_label = "";
                    roundtrip_label += `${group.journey[0].origin.city}, (${group.journey[0].origin.name}) ⇆ ${group.journey[0].destination.city}, ${group.journey[0].destination.name}`;
                    roundtrip_label += ` (${moment(group.journey[0].start_date).format("L")} - ${moment(group.journey[1].start_date).format("L")}) `;
                    roundtrip_label += CountAgeStr(group.travelers, end_date, t);
                    roundtrip_label += ` ${class_type_mapping[group.journey[0].class_type]} - ${class_type_mapping[group.journey[1].class_type]}`;
                    temp_label.push(roundtrip_label);
                    break;
                case 2:
                    // multi
                    group.journey.map((journey_step) => {
                        let journey_label = `${journey_step.origin.city}, (${journey_step.origin.name}) → ${journey_step.destination.city}, ${journey_step.destination.name}`;
                        journey_label += ` (${moment(journey_step.start_date).format("L")}) `;
                        journey_label += CountAgeStr(group.travelers, end_date, t);
                        journey_label += ` ${class_type_mapping[journey_step.class_type]}`;
                        temp_label.push(journey_label);
                    });
                    break;
                case 3:
                    // one way
                    let one_way_label = "";
                    one_way_label += `${group.journey[0].origin.city}, (${group.journey[0].origin.name}) → ${group.journey[0].destination.city}, ${group.journey[0].destination.name}`;
                    one_way_label += ` (${moment(group.journey[0].start_date).format("L")}) `;
                    one_way_label += CountAgeStr(group.travelers, end_date, t);
                    one_way_label += ` ${class_type_mapping[group.journey[0].class_type]}`;
                    temp_label.push(one_way_label);
                    break;
            }

            temp_group.label = temp_label;
            if (!temp_previous_searches.some(item => item.label.join(" | ") === temp_label.join(" | "))) {
                temp_previous_searches.push(temp_group);
            }
        });

        setPreviousSearches(temp_previous_searches);
    }, [previous_flight_groups]);

    const usePreviousSearch = (previous) => {
        dispatch(CreateStopoverDuration(previous.journey.length));
        dispatch(CreateFlightHours(previous.journey.length));
        dispatch(ReplaceGroup(previous, group_index));
    };

    return (
        <Grid>
            <Grid container alignItems={'center'} justify="flex-start" spacing={1}>
                <Grid item xs={1} />
                <Grid item>
                    <Button size="small" className={`${classes.genericText} ${classes.buttonText}`} onClick={() => setDisplayPrevious(!displayPrevious)}>
                        <SearchIcon />
                        { t("flight_search.history") }
                    </Button>
                </Grid>
            </Grid>
            {
                displayPrevious && previousSearches.map((search, search_index) => {
                    return (
                        <Grid key={search_index} container alignItems="center" justify="flex-start" className={classes.spacer}>
                            <Grid item xs={1} />
                            <Grid item xs={11} className={classes.gridHover} onClick={() => usePreviousSearch(search)}>
                                {
                                    search.label.map((flight_label, flight_index) => {
                                        return (
                                            <Grid item className={`${classes.genericText} ${classes.searchText}`} key={flight_index}>
                                                { search.journey_type === 2 ? "- " : "" }
                                                { flight_label }
                                            </Grid>
                                        );
                                    })
                                }
                            </Grid>
                        </Grid>
                    );
                })
            }
        </Grid>
    );
};

export default React.memo(SearchHistory);
