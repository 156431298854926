import React, {Fragment, useEffect, useState, useRef} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import InscriptionRightPanel from "./InscriptionRightPanel";
import InscriptionTravelers from "./InscriptionTravelers";
import RenderAllProduct from "./RenderAllProduct";
import SummaryInscriptionModal from "./SummaryInscriptionModal";
import Footer from "../Footer/Footer.jsx";
import SummaryHeader from "./SummaryHeader";
import PlaneLoader from "../PlaneLoader/PlaneLoader.jsx";
import GetTripInfo from "./Functions/GetTripInfo";
import GetPrice from "./Functions/GetPrice";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";

import { SetSummaryStep } from "../../Actions/Summary";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fullWidth: {
        width: "100%"
    },
    textJustify: {
        textAlign: "justify"
    },
    textCenter: {
        textAlign: "center"
    },
    bold: {
        fontWeight: "bold"
    },
    stickyPaper: {
        position: "sticky",
        top: 80,
        borderRadius: 12
    },
    buttonContainer: {
        minWidth: 150,
        margin: '0 15px 15px'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    staticPriceContainer: {
        position: 'fixed',
        bottom: "8%",
        backgroundColor: 'white',
        marginLeft: -16,
        padding: 16,
        borderTop: '1px solid lightgray',
        width: "100%",
        zIndex: 77
    },
    absoluteCenter: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
}));


const SummaryInscription = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960), { noSsr: true });
	const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));

    const { t } = useTranslation();

    const trip_main_info = useSelector(store => store.summary.trip_main_info);
    const trip_info = useSelector(store => store.summary.trip_info);
    const selectedProducts = useSelector(store => store.summary.selectedProducts);
    const trip_docs = useSelector(store => store.menu.trip_docs);
    const hotel_cart = useSelector(store => store.summary.hotel_cart);
	const cars_cart = useSelector(store => store.summary.cars_cart);
	const flight_cart = useSelector(store => store.summary.flight_cart);
	const manual_cart = useSelector(store => store.summary.manual_cart);
	const transfers_cart = useSelector(store => store.summary.transfers_cart);
	const poi_cart = useSelector(store => store.summary.poi_cart);
	const itinerary = useSelector(store => store.summary.itinerary);

    const [activeStep, setActiveStep] = useState(1);
    const [checkValidate, setCheckValidate] = useState(false);
    const [nextButton, setNextButton] = useState(false);
    const [prevButton, setPrevButton] = useState(false);
    const [toggleModal, setToggleModal] = useState(false);
    const [missingDocs, setMissingDocs] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

	const { headers } = CheckBeforeRequest();

    // const [selectedProducts, setSelectedProducts] = useState({
    //     mainFlights: [],
    //     hotels: [],
    //     cars: [],
    //     transfers: [],
    //     poi: [],
    //     step_flight: []
    // });
    const contentRef = useRef([]);

    useEffect(() => {
        if (nextButton) {
            setActiveStep(2);
            setNextButton(false);
        }
    }, [nextButton]);
    useEffect(() => {
        if (prevButton) {
            setActiveStep(1);
            setPrevButton(false);
        }
    }, [prevButton]);

    useEffect(() => {
        dispatch(SetSummaryStep(4));
        if (trip_info === null) {
            setIsLoading(true);
            GetTripInfo(props.params.trip_id, props.params.trip_token, dispatch, setIsLoading);
        }
    }, []);
    useEffect(() => {
        if (trip_main_info !== null && trip_docs.length === 0) {
            getDoc();
        }
    }, [trip_main_info]);

    useEffect(() => {
        if (!isLoading) {
            GetPrice(itinerary, flight_cart, hotel_cart, manual_cart, poi_cart, cars_cart, transfers_cart, selectedProducts, trip_info, dispatch);
        }
    }, [isLoading]);
    useEffect(() => {
        if (trip_docs.length !== 0) {
            let total_requested = 0;
            let total_received = 0;
            trip_docs.map((document) =>{
                if(document.requested === true){
                    total_requested += 1;
                    if(document.received === true){
                        total_received += 1;
                    }
                }
            })
            let temp_missing_docs = total_requested - total_received;
            setMissingDocs(temp_missing_docs);
        }
    }, [trip_docs]);

    const getDoc = () => {
		axios({
			method: "GET",
			url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_main_info.current_version}/file/?token=${props.params.trip_token}`,
			headers: headers,
		}).then(function(response){
			dispatch(SetTripDocs(response.data.results));
		}).catch(function(error){
			console.log(error.responseJSON);
		});
	}
    const onSetStep = (type, value) => () => {
        let new_step = activeStep;
        if (value === -1) {
            new_step = new_step - 1;
        } else {
            new_step = new_step + 1;
        }
        setActiveStep(new_step);
    };
    const setSelectedProducts = (products) => {
        dispatch({
            type: "SUMMARY_SET_SELECTED_PRODUCTS",
            payload: {
                products: products
            }
        });
    }
    if (isLoading || trip_info === null) {
		return (
			<div className={classes.absoluteCenter} >
				<PlaneLoader />
				<div className={`${classes.textCenter} ${classes.lightGreen}`} > {t('summary.get_all_info')} </div>
			</div>
		);
	}
    return (
        <Grid container style={{display: "block"}}>
            <Grid item>
                <SummaryHeader missingDocs={missingDocs} props={props}/>
            </Grid>
            <Grid item>
                <Container className={`${classes.genericText}`} style={{paddingTop: fullScreen ? 120 : 70, paddingBottom: fullScreen ? 150 : 0}}>
                    {
                        (trip_info.status === "TO_UPDATED" || trip_info.status === "ONGOING" || trip_info.status === "VALIDATED" || trip_info.status === "TOBOOK_VERDIE") && (
                            <Fragment>
                                <Stepper nonLinear activeStep={ activeStep - 1 } alternativeLabel>
                                    <Step>
                                        <StepLabel className={ classes.step }>{ t("summary.quotation_validation") }</StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel className={ classes.step }>{ t("summary.traveler_validation") }</StepLabel>
                                    </Step>
                                </Stepper>
                                <Grid container direction={ 'row' } justify={ 'center' } alignItems={ 'center' } spacing={2}>
                                    <Grid item xs={fullScreen ? 6 : false} className={!fullScreen ? classes.buttonContainer : ""}>
                                        <Button variant={ 'contained' } className={ classes.orangeButton } fullWidth={ true } disabled={ activeStep === 1 } onClick={ onSetStep('DEC', -1) }>{ t('cart-material.prev') }</Button>
                                    </Grid>
                                    <Grid item xs={fullScreen ? 6 : false} className={!fullScreen ? classes.buttonContainer : ""}>
                                        <Button variant={ 'contained' } className={ classes.orangeButton } fullWidth={ true } disabled={ activeStep === 2 } onClick={ onSetStep('INC', 1) }>{ t('cart-material.next') }</Button>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )
                    }
                    <Grid container direction={"row"} justify={"flex-start"} spacing={fullScreen ? 2 : 4}>
                    {/* <Grid container alignItems="baseline" justify="space-between" spacing={2} style={ isHandHeld ? { marginBottom: 64 } : {}}> */}
                        <Grid item sm={!isHandHeld ? 8 : 12} xs={12}>
                        {
                            activeStep === 2 && (
                                <InscriptionTravelers checkValidate={checkValidate} setCheckValidate={setCheckValidate} selectedProducts={selectedProducts} summaryProps={props} setIsLoading={setIsLoading} setPrevButton={setPrevButton}/>
                            )
                        }
                        {
                            activeStep === 1 && (
                                <RenderAllProduct from={"renderAll"} ref={contentRef} checkValidate={checkValidate} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts}/>
                            )
                        }
                        </Grid>
                        {
                            !isHandHeld && (
                                <Grid item sm={4} xs={12}>
                                    <Card className={ `${classes.stickyPaper} ${classes.genericText}` } raised>
                                        <InscriptionRightPanel activeStep={activeStep} setCheckValidate={setCheckValidate} setNextButton={setNextButton}/>
                                    </Card>
                                </Grid>
                            )
                        }
                        {
                            fullScreen && (trip_info.status === "TO_UPDATED" || trip_info.status === "ONGOING" || trip_info.status === "VALIDATED" || trip_info.status === "TOBOOK_VERDIE") && (
                                <Grid item xs={12}>
                                    <Grid container className={ classes.staticPriceContainer } direction={"row"} justify={"center"} alignItems={"center"} spacing={2}>
                                        <Grid item xs={6}>
                                            <Button variant={ 'contained' } className={ classes.orangeButton } fullWidth={ true } disabled={ activeStep === 1 } onClick={ onSetStep('DEC', -1) }>{ t('cart-material.prev') }</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                        {
                                            activeStep === 1 && (
                                                <Button variant={ "contained" } className={ classes.orangeButton } fullWidth={ true } onClick={onSetStep('INC', 1)} >{t('cart-material.next')}</Button>
                                            )
                                        }
                                        {
                                            activeStep === 2 && (
                                                <Button variant={ "contained" } className={ classes.orangeButton } fullWidth={ true } onClick={() => {setToggleModal(true)}} >{ t('global.validate') }</Button>
                                            )
                                        }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                    <SummaryInscriptionModal activeStep={activeStep} setCheckValidate={setCheckValidate} setNextButton={setNextButton} toggleModal={toggleModal} setToggleModal={setToggleModal} selectedProducts={selectedProducts}/>
                </Container>
            </Grid>
            {
                !fullScreen && (
                    <Grid item style={{marginTop: "calc(5% + 60px)", bottom: 0}}>
                        <Footer/>
                    </Grid>
                )
            }
            {
                fullScreen && (
                    <Grid item>
                        <SummaryHeaderMobile missingDocs={missingDocs} props={props}/>
                    </Grid>
                )
            }
        </Grid>
    )
}
export default SummaryInscription;