import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSnackbar } from "notistack";
import clsx from "clsx";
import axios from "axios";

import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from '@material-ui/core/Button';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { SetMarginStep, SetMarginUpdate } from "../../../Actions/Menu";
import MarginListRow from "./MarginListRow";
import RenderMargin from "./RenderMargin";
import HasPermission from "../../Common/Functions/HasPermission";
import AddMargin from "./AddMargin";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    marginBottom70: {
        marginBottom: 70
    },
    addButton: {
        "color": "#e6592f",
        "backgroundColor": "white",
        '&:hover': {
            color: "white",
            backgroundColor: "#e6592f"
        }
    },
    stickyOverflow: {
        overflowX: 'inherit'
    },
    sticky: {
        position: 'sticky',
        top: 70,
        backgroundColor: 'white',
        zIndex: 1
    },
    paddingCell: {
        "& .mui-jss-MuiTableCell-root": {
            fontSize: 12
        }
    },
    fileTitle: {
        fontWeight: "bold",
        textTransform: "uppercase",
        fontSize: 20
    }
});

const MarginList = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down("sm"));
    const { enqueueSnackbar } = useSnackbar();

    const margin_step = useSelector(store => store.menu.margin_step);
    const permissions = useSelector(store => store.user.permissions);
    const user = useSelector(store => store.user.user);
    const need_update_margin_list = useSelector(store => store.menu.need_update_margin_list);

    const [tableColumn, setTableColumn] = useState([t("menu.margin.margin"), t("global.in"), t("menu.margin.active")]);
    const [marginList, setMarginList] = useState(null);
    const [fullList, setFullList] = useState(null);
    const [activeMargin, setActiveMargin] = useState(true);
    const [selectedMargin, setSelectedMargin] = useState(null);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState(t("menu.created_date"));

    useEffect(() => {
        getMargin();
        return () => {
            dispatch(SetMarginStep(1));
        };
    }, []);
    useEffect(() => {
        if (need_update_margin_list) {
            getMargin();
            dispatch(SetMarginUpdate(false));
        }
    }, [need_update_margin_list]);
    useEffect(() => {
        if (selectedMargin !== null) {
            dispatch(SetMarginStep(3));
        }
    }, [selectedMargin]);
    useEffect(() => {
        if (margin_step === 1) {
            setSelectedMargin(null);
        }
    }, [margin_step]);
    useEffect(() => {
        if (fullList !== null) {
            let new_list = [];
            if (!activeMargin) {
                new_list = fullList;
            } else {
                for (let i = fullList.length - 1; i >= 0; i--) {
                    if (fullList[i].disabled_date === null) {
                        new_list.push(fullList[i]);
                    }
                }
            }
            setMarginList(new_list);
        }
    }, [activeMargin]);
    const getMargin = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${user.client}/margins/?limit=350${user.client_full.type === 2 ? '&created_date__gte=2019-04-05T00:00:00' : ''}`
            }).then(function (response) {
                console.log('response:', response.data.results);
                let new_list = [];
                if (activeMargin) {
                    for (let i = response.data.results.length - 1; i >= 0; i--) {
                        if (response.data.results[i].disabled_date === null) {
                            new_list.push(response.data.results[i]);
                        }
                    }
                } else {
                    new_list = response.data.results.slice();
                }
                dispatch(SetMarginUpdate(false));
                setMarginList(new_list);
                setFullList(response.data.results);
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
            });
        }
    };
    const addNewMargin = () => {
        if (HasPermission(permissions, "add_user", user) === true) {
            dispatch(SetMarginStep(2));
        } else {
            enqueueSnackbar(t("notify.no_permissions"), { variant: "error" });
        }
    };
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const sortList = () => {
        let new_list = marginList.sort((a, b) => {
            if (orderBy === t("global.name")) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            }
            if (orderBy === t("menu.created_date")) {
                let da = new Date(a.created_date);
                let db = new Date(b.created_date);
                return (da - db);
            }
        });
        if (order === "desc") {
            new_list = new_list.reverse();
        }
        return new_list;
    };
    return (
        <Container className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12} className={ clsx({ [classes.marginBottom70]: downDesktop }) }>
                    {
                        marginList === null && (
                            <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                                <Grid item>
                                    <CircularProgress />
                                </Grid>
                                <Grid item>
                                    <span> {t("menu.loading_margin")} </span>
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        marginList !== null && margin_step === 1 && (
                            <Fragment>
                                <Grid item xs={12}>
                                    <Grid container direction={"row"} justify={"center"} alignItems={"center"} spacing={2}>
                                        <Grid item sm={4} xs={12}>
                                            {
                                                marginList !== null && (
                                                    <Grid container direction={"row"} alignItems={"center"}>
                                                        <Grid item>
                                                            <span className={classes.fileTitle}>{marginList.length + " " + (marginList.length > 0 ? t("menu.margin.margins") : t("menu.margin.margin"))}</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton style={{ position: "relative" }} onClick={getMargin}>
                                                                <RefreshOutlinedIcon/>
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>

                                                )
                                            }
                                        </Grid>
                                        <Grid item sm={4} xs={12}>
                                            <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                                                <Grid item>
                                                    <Button
                                                        variant={"contained"}
                                                        className={classes.addButton}
                                                        startIcon={<AddCircleOutlineOutlinedIcon />}
                                                        onClick={addNewMargin}
                                                    >{t("menu.margin.create_margin")}</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={4} xs={12}>
                                            <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={activeMargin} onChange={() => {
                                                            setActiveMargin(!activeMargin);
                                                        }} color="primary" />}
                                                        label={t("menu.margin.active_margin")}
                                                        labelPlacement="start"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={ 12 }>
                                    <Alert variant={ 'filled' } severity={ 'error' }>{ t('menu.margin.warning') }</Alert>
                                </Grid>
                                <Grid item style={{ marginTop: 10 }}>
                                    <TableContainer className={ classes.stickyOverflow }>
                                        <Table id={"trip-list"}>
                                            <TableHead>
                                                <TableRow className={classes.paddingCell}>
                                                    <TableCell className={ classes.sticky } align={"left"} sortDirection={orderBy === t("global.name") ? order : false}>
                                                        <TableSortLabel
                                                            active={orderBy === t("global.name")}
                                                            direction={orderBy === t("global.name") ? order : 'asc'}
                                                            onClick={createSortHandler(t("global.name"))}
                                                        >
                                                            {t("global.name")}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    {
                                                        !downDesktop && tableColumn.map((column, column_index) => {
                                                            return (
                                                                <TableCell align={"left"} key={column_index} className={ classes.sticky }>
                                                                    {column}
                                                                </TableCell>
                                                            );
                                                        })
                                                    }
                                                    {
                                                        downDesktop && (
                                                            <TableCell className={ classes.sticky } align={"left"}>
                                                                {t("menu.margin.active")}
                                                            </TableCell>
                                                        )
                                                    }
                                                    {
                                                        !downDesktop && (
                                                            <TableCell className={ classes.sticky } align={"left"}>
                                                                {t("menu.profile.passeport_end_date")}
                                                            </TableCell>
                                                        )
                                                    }
                                                    <TableCell className={ classes.sticky } align={"left"} sortDirection={orderBy === t("menu.created_date") ? order : false}>
                                                        <TableSortLabel
                                                            active={orderBy === t("menu.created_date")}
                                                            direction={orderBy === t("menu.created_date") ? order : 'asc'}
                                                            onClick={createSortHandler(t("menu.created_date"))}
                                                        >
                                                            {t("menu.created_date")}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    marginList !== null && sortList().map((row, row_index) => (
                                                        <MarginListRow key={row_index} row={row} row_index={row_index} columns={tableColumn} setSelectedMargin={setSelectedMargin}/>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Fragment>
                        )
                    }
                    {
                        margin_step === 2 && (
                            <AddMargin/>
                        )
                    }
                    {
                        margin_step === 3 && selectedMargin !== null && (
                            <RenderMargin margin={selectedMargin}/>
                        )
                    }
                </Grid>
            </Grid>
        </Container>
    );
};
export default React.memo(MarginList);
