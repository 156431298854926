//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import { Grid, Skeleton } from '@mui/material';

import NightlightIcon from '@mui/icons-material/Nightlight';
import RoomIcon from '@mui/icons-material/Room';
import KingBedIcon from '@mui/icons-material/KingBed';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    leftSideContainer: {
        marginTop: '10px !important',
        paddingTop: '10px !important',
        paddingBottom: '10px !important',
        // width: '100% !important',
		// display: 'inline-flex',
		// borderRadius: 22,
		// boxShadow: '0px 8px 16px rgb(0 0 0 / 15%), 0px 3px 8px rgb(0 0 0 / 10%) !important'
        border: '1px solid rgba(0, 0, 0, 0.23) !important',
	},
    inlineFlex: {
		display: 'inline-flex'
	},
    separator: {
		borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
    	display: "inline",
		position: "absolute",
		left: 0,
		top: "10%",
		bottom: "3%"
	},
    mainColor: {
        color: `${main_color}`
    },
    bold: {
        fontWeight: 'bold'
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    font10: {
        fontSize: 10
    },
    negMargin: {
        marginBottom: '-5px !important'
    }
}));

type CircuitSummaryProp = {
    itinerary_type_details_data: any,
    hotels: any,
    flights: any,
    poi: any,
    cars: any,
    transfers: any,
    isItiLoading: boolean,
    variantToShow: number,
    versionToShow: number
};

export function CircuitSummary (props: CircuitSummaryProp): JSX.Element {
    const { itinerary_type_details_data, hotels, flights, poi, cars, transfers, isItiLoading, variantToShow, versionToShow } = props;
    const { t } = useTranslation();
    const itinerary = useSelector((state: any) => state.itinerary_type.itinerary);
    const type = useSelector((state:any) => state.itinerary_type.type);
    const classes = useStyles();

    const [distance, setDistance] = useState<number>(0);

    useEffect(() => {
        if (itinerary !== null) {
            let tmp_distance:number = itinerary.reduce((prev:number, current:any) => {
                if (current.distance_transport_km !== undefined) {
                    return prev + current.distance_transport_km;
                }
                return prev;
            }, 0);
            setDistance(Math.ceil(tmp_distance));
        }
    }, [itinerary]);
    const step = itinerary.filter((item: any) => item.step_type === "STEP");
    const tmp_hotels = hotels.filter((item: any) => !item.is_optional && item.trip_version === versionToShow && (item.variant === null || item.variant === variantToShow));
    const tmp_flights = flights.filter((item: any) => !item.is_optional && item.trip_version === versionToShow && (item.variant === null || item.variant === variantToShow));
    const tmp_cars = cars.filter((item: any) => !item.is_optional && item.trip_version === versionToShow && (item.variant === null || item.variant === variantToShow));
    const tmp_transfers = transfers.filter((item: any) => !item.is_optional && item.trip_version === versionToShow && (item.variant === null || item.variant === variantToShow));
    const tmp_poi = poi.filter((item: any) => !item.is_optional && item.trip_version === versionToShow && (item.variant === null || item.variant === variantToShow));
    const transports = tmp_flights.length + tmp_cars.length + tmp_transfers.length;
    return (
        <div>
            <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={ 2 }>
                <Grid item className={ `${classes.genericText} ${classes.leftSideContainer}` }>
                    {
                        ((itinerary_type_details_data.duration_day !== null && itinerary_type_details_data.duration_day !== 0) || (itinerary_type_details_data.duration_night !== null && itinerary_type_details_data.duration_night !== 0)) &&
                        <Grid item className={ classes.inlineFlex }>
                            <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} style={{paddingRight: 10, paddingLeft: 10}}>
                                <Grid item className={ classes.negMargin }><NightlightIcon className={classes.mainColor}/></Grid>
                                {
                                    isItiLoading ? (
                                        <Skeleton variant="text" width={100} height={10} />
                                    ) : (
                                        <Grid item className={`${classes.bold} ${classes.uppercase} ${classes.letterSpacing} ${classes.font10}`}>{itinerary_type_details_data.duration_day !== null ? (itinerary_type_details_data.duration_day > 1 ? itinerary_type_details_data.duration_day + " " + t("global.days") : itinerary_type_details_data.duration_day + " " + t("global.day")) : " " } / { itinerary_type_details_data.duration_night !== null ? (itinerary_type_details_data.duration_night > 1 ? itinerary_type_details_data.duration_night + " " + t("itinerary.nights") : itinerary_type_details_data.duration_night + " " + t("itinerary.nights")) : " " }</Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    }
                    {
                        type === 'circuit' && step.length !== 0 &&
                        <Grid item className={ classes.inlineFlex } style={{ position: "relative" }}>
                            <Fragment>
                                <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} style={{paddingRight: 10, paddingLeft: 10}}>
                                    <Grid item className={ classes.negMargin }><RoomIcon className={classes.mainColor}/></Grid>
                                    {
                                        isItiLoading ? (
                                            <Skeleton variant="text" width={70} height={10} />
                                        ) : (
                                            <Grid item className={`${classes.bold} ${classes.uppercase} ${classes.letterSpacing} ${classes.font10}`}>{`${step.length} ${t<string>('flight.step')}`}</Grid>
                                        )
                                    }
                                </Grid>
                                <div className={classes.separator}/>
                            </Fragment>
                        </Grid>
                    }
                    {
                        tmp_hotels.length !== 0 &&
                        <Grid item className={ classes.inlineFlex } style={{position: "relative"}}>
                            <Fragment>
                                <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} style={{paddingRight: 10, paddingLeft: 10}}>
                                    <Grid item className={ classes.negMargin }><KingBedIcon className={classes.mainColor}/></Grid>
                                    {
                                        isItiLoading ? (
                                            <Skeleton variant="text" width={70} height={10} />
                                        ) : (
                                            <Grid item className={`${classes.bold} ${classes.uppercase} ${classes.letterSpacing} ${classes.font10}`}>{`${tmp_hotels.length} ${tmp_hotels.length > 1 ? t<string>('cart.accommodations') : t<string>('cart.accommodation')}`}</Grid>
                                        )
                                    }
                                </Grid>
                                <div className={classes.separator}/>
                            </Fragment>
                        </Grid>
                    }
                    {
                        tmp_poi.length !== 0 &&
                        <Grid item className={ classes.inlineFlex } style={{position: "relative"}}>
                            <Fragment>
                                <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} style={{paddingRight: 10, paddingLeft: 10}}>
                                    <Grid item className={ classes.negMargin }><VisibilityIcon className={classes.mainColor}/></Grid>
                                    {
                                        isItiLoading ? (
                                            <Skeleton variant="text" width={70} height={10} />
                                        ) : (
                                            <Grid item className={`${classes.bold} ${classes.uppercase} ${classes.letterSpacing} ${classes.font10}`}>{`${tmp_poi.length} ${tmp_poi.length > 1 ? t<string>('cart.activities') : t<string>('cart.activity')}`}</Grid>
                                        )
                                    }
                                </Grid>
                                <div className={classes.separator}/>
                            </Fragment>
                        </Grid>
                    }
                    {
                        transports !== 0 &&
                        <Grid item className={ classes.inlineFlex } style={{position: "relative"}}>
                            <Fragment>
                                <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} style={{paddingRight: 10, paddingLeft: 10}}>
                                    <Grid item className={ classes.negMargin }><DirectionsCarIcon className={classes.mainColor}/></Grid>
                                    {
                                        isItiLoading ? (
                                            <Skeleton variant="text" width={70} height={10} />
                                        ) : (
                                            <Grid item className={`${classes.bold} ${classes.uppercase} ${classes.letterSpacing} ${classes.font10}`}>{`${transports} ${transports > 1 ? t<string>('cart.transports') : t<string>('cart.transport')}`}</Grid>
                                        )
                                    }
                                </Grid>
                                <div className={classes.separator}/>
                            </Fragment>
                        </Grid>
                    }
                    {
                        type === 'circuit' && distance !== 0 &&
                        <Grid item className={ classes.inlineFlex } style={{position: "relative"}}>
                            <Fragment>
                                <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} style={{paddingRight: 10, paddingLeft: 10}}>
                                    <Grid item className={ classes.negMargin }><LocalGasStationIcon className={classes.mainColor}/></Grid>
                                    {
                                        isItiLoading ? (
                                            <Skeleton variant="text" width={100} height={10} />
                                        ) : (
                                            <Grid item className={`${classes.bold} ${classes.uppercase} ${classes.letterSpacing} ${classes.font10}`}>{distance}{t<string>('global.distance_traveled', { unit: "km" })}</Grid>
                                        )
                                    }
                                </Grid>
                                <div className={classes.separator}/>
                            </Fragment>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </div>
    );
}
