import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { StepsDatesManager } from "../../Itinerary/utils/stepsDatesManager";
import { StepsDirectionsManager } from "../../Itinerary/utils/stepsDirectionsManager";
import { ItineraryInput } from "../../Itinerary/objects/itineraryState";
import { AppState } from "../../../Reducers/Reducers";

type Callback = (itinerary: ItineraryInput[]) => Promise<ItineraryInput[] | null>

export function useRecomputeItinerary(): Callback {
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);

    return async (itinerary) => {
        if (tripStartDate && tripEndDate) {
            try {
                const datesManager = new StepsDatesManager(
                    tripStartDate,
                    tripEndDate
                );
                const directionsManager = StepsDirectionsManager.getInstance();
                const result = [...itinerary];
    
                if (
                    !await directionsManager.areTransportsAllValid(result) ||
                    !datesManager.areDatesAllValid(result)
                ) {
                    //recompute transports and dates
                    for (let i = 0; i < result.length; i++) {
                        const current = cloneDeep(result[i]!);
                        const next = result[i + 1];
    
                        if (next) {
                            const transportsPair = await directionsManager.recomputeTransportsBetween(current, next);
                            const resultPair = datesManager.recomputeDates(transportsPair[0], transportsPair[1]);
                            result[i] = resultPair[0];
                            result[i + 1] = resultPair[1];
                        }
                    }
                }

                return result;
            } catch (error: any) {
                if (error !== 'Aborted autocalculation.') {
                    throw error;
                }
            }
        }
        return null;
    };
}
