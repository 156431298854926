import { TransferCart } from "../../Itinerary/objects/transferCart";
import { Picture } from "../../Menu/MaterialTripList/picture/objects/picture";

export function getTransferPicture(transfer: TransferCart, source?: 'provider'): Picture[] {
    if (transfer.hide_pictures) {
        return [];
    }

    if (!source && (transfer.overload_pictures?.length ?? 0) > 0) {
        return transfer.overload_pictures ?? [];
    } else if (transfer.custom_product?.cover_picture) {
        return [transfer.custom_product?.cover_picture];
    } else if ((transfer.custom_product?.custom_pictures?.length ?? 0) > 0) {
        return transfer.custom_product?.custom_pictures ?? [];
    } else if ((transfer.custom_product?.pictures?.length ?? 0) > 0) {
        return transfer.custom_product?.pictures ?? [];
    }

    return [];
}
