//---- Actions ----//
import { SetAirportJourney, SetAirportList, SetAirportJourneyRoundtrip, SetAirportJourneyMultiDest } from "../../../../Actions/Flight";

export default (journey_type, airport, index, type, key, dispatch) => {
	let airport_choice = {
		name: (airport.name !== undefined ? airport.name : airport.international_name),
		airport_code: airport.airport_code,
		city: airport.iata_city.international_name
	};
	if (journey_type === 3)
		dispatch(SetAirportJourney(airport_choice, index, type, key));
	else if (journey_type === 2)
		dispatch(SetAirportJourneyMultiDest(airport_choice, index, type, key))
	else
		dispatch(SetAirportJourneyRoundtrip(airport_choice, index, type, key));
	dispatch(SetAirportList([]));
}