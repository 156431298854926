import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import {useSelector} from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
    inline: {
        display: "inline"
    },
    cardContainer: {
        cursor: "pointer",
        borderRadius: 16,
        paddingBottom: 8,
        paddingTop: 8
    },
    marginTop: {
        marginTop: 24
    },
    marginBottom: {
        marginBottom: 8
    },
    imgContainer: {
        overflow: "hidden",
        height: 270,
        [theme.breakpoints.down('lg')]: {
            height: 200,
        },
        width: '100%',
        position: "relative",
        marginBottom: 4
    },
    alignRight: {
        textAlign: "right"
    },
    paddingLeftRight: {
        paddingLeft: 10,
        paddingRight: 10
    },
    paddingRight: {
        paddingRight: 10,
        paddingLeft: 10
    },
    paddingLeft: {
        paddingRight: 10,
        paddingLeft: 10
    },
}));

const SkeletonTwo = () => {
    const classes = useStyles();
    const grid_type = useSelector(state => state.accommodation.grid_type);
    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.down('sm'));
    const mobile = useMediaQuery(theme.breakpoints.down(600));
    let items = [];
    for (let i = 0; i < (grid_type === 3 ? 9 : 6); i++) {
        items.push(
            <Grid key={ `skeleton-${i}` } className={ `${classes.cardContainer} ${mobile ? '' : grid_type === 3 || tablet ? i % 2 === 0 ? classes.paddingRight : classes.paddingLeft : i % 3 === 0 ? classes.paddingRight : i % 3 === 1 ? classes.paddingLeftRight : classes.paddingLeft}` } item container xs={ mobile ? 12 : grid_type === 3 || tablet ? 6 : 4 }>
                <Grid className={ classes.imgContainer } item>
                    <Skeleton variant={ "rect" } height={ "100%" }/>
                </Grid>
                <Grid item xs container>
                    <Grid item xs={ 12 }>
                        <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 20 } width={ "100%" }/>
                        <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 20 } width={ "100%" }/>
                        <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 20 } width={ "50%" }/>
                        <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 20 } width={ "50%" }/>
                        <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 20 } width={ 75 }/>
                        <Skeleton className={ classes.marginBottom } variant={ "rect" } height={ 20 } width={ 75 }/>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    return items
};

export default React.memo(SkeletonTwo);
