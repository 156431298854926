//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import clsx from "clsx";
import { Link } from "react-router";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';

import CustomHomeSlider from '../../Home/CustomHomeSlider.jsx';
import MaterialHeader from '../../Header/MaterialHeader.jsx';
import CheckBeforeRequest from '../../Common/CheckBeforeRequest.js';
import { SetHistorydest, SetItineraryTypeDetails } from '../../../Actions/ItineraryType.js';
import GetProducts from '../Packages/GetProducts.js';
import MaterialBreadcrumb from '../MaterialBreadcrumb.js';
import SalePitchDialog from './SalePitchDialog.tsx';
import HotelCard from './HotelCard.tsx';
import HotelInfo from './HotelInfo.tsx';
import Help from "../../Base/Help";
import MaterialLogin from '../../Home/MaterialLogin.js';

import ActivitiesCard from './ActivitiesCard.tsx';
import MaterialDuplicateDialog from '../MaterialDuplicateDialog.js';
import TransfersCard from './TransfersCard.tsx';
import CarsCard from './CarsCard.tsx';
import FlightsCard from './FlightsCard.tsx';
import RightPanel from './RightPanel.tsx';
import PriceCalendar from './PriceCalendar.js';
import MobileRightPanel from './MobileRightPanel.tsx';
import RenderExtra from './RenderExtra.js';

//---- CSS ----//
import "../../../Style/Home.css";
import "../../../Style/Global.css";
import "../../../Style/GlobalAgencies.css";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    headerPicture: {
        height: "50vh"
    },
    absoluteCenter: {
        margin: 0,
        position: "absolute",
        top: "24%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    customButton: {
        background: "#e6592f",
        // padding: "15px 40px",
        textAlign: "center",
        color: "#fff",
        fontSize: 20,
        textTransform: "none",
        "&:hover": {
            background: "#cc4f2a"
        }
    },
    customButtonV: {
        background: "#BF202D",
        // padding: "15px 40px",
        textAlign: "center",
        color: "#fff",
        fontSize: 20,
        textTransform: "none",
        "&:hover": {
            background: "#cc4f2a"
        }
    },
    headerContainer: {
        height: "50vh",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    },
    destinationTitle: {
        textAlign: "center",
        color: "white",
        textShadow: "black 1px 0 10px",
        fontFamily: "Roboto",
        // marginBottom: 50,
        fontWeight: 400
    },
    hoverContainer: {
        "&:hover": {
            borderLeft: '6px solid #E6592F'
        }
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 50
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
    staticPriceContainer: {
        position: 'fixed',
        bottom: 0,
        backgroundColor: 'white',
        marginLeft: -16,
        padding: 16,
        borderTop: '1px solid lightgray'
    },
    orangeButton: {
		backgroundColor: "#E6592F",
		color: "white"
	},
    stickyPaper: {
        position: "sticky",
        top: 80,
        borderRadius: 12
    },
    fullWidth: {
        width: "100%"
    },
    textJustify: {
        textAlign: "justify"
    },
    listContainer: {
        "&& ul": {
            listStyleType: "'-'",
            paddingInlineStart: "16px",
            marginBottom: 4,
            "& li": {
                paddingLeft: 5
            }
        }
    },
    border: {
        border: '1px solid #ebebeb',
        borderRadius: 13,
        padding: 10,
        marginBottom: 10
    } 
}));

const PackagesDetails = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const config = JSON.parse(localStorage.getItem("config"));
    const quotation_code = config.quotation_code;
    const [logo] = useState(config !== null && config.logo !== undefined && config.logo !== null && config.logo.url !== undefined && config.logo.url !== null ? config.logo : null);

    const itinerary_type_details_data = useSelector(store => store.itinerary_type.itinerary_type_details_data);
    const is_connected = useSelector(store => store.user.is_connected);
    const dest_history = useSelector(store => store.itinerary_type.dest_history);
    const user = useSelector(store => store.user.user);

    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState([]);
    const [nb_active_hotel, setActiveHotel] = useState(0);
    const [hotels, setHotels] = useState([]);
    const [flights, setFlights] = useState([]);
    const [poi, setPoi] = useState([]);
    const [cars, setCars] = useState([]);
    const [transfers, setTransfers] = useState([]);
    const [duplicationOpen, setDuplicationOpen] = useState(false);
    const [linked_dest, setLinkedDest] = useState([]);
    const [assurances, setAssurances] = useState([]);
    const [simple_product, setSimpleProduct] = useState([]);
    const [options, setOptions] = useState([]);
    const [currency_symbol, setCurrencySymbol] = useState("-");
    const [currency_iso, setCurrencyIso] = useState(null);
    const [flight_included, setFlightIncluded] = useState(false);
    const [salePitch, setSalePitch] = useState(false);
    const [selectedVariant, setSelectedVariant] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [transfersHasVariant, setTransfersHasVariant] = useState(false);
    const [flightsHasVariant, setFlightsHasVariant] = useState(false);
    const [carsHasVariant, setCarsHasVariant] = useState(false);
	const [mobileDialog, setMobileDialog] = useState(false);
    const [dateIsAfter, setDateIsAfter] = useState(false);
    const [loginDialog, setLoginDialog] = useState(false);

    const pricesRef = useRef(null);

    let history = [];

    useEffect(() => {
        console.log('init package');
        dispatch({type: "ITINERARY_SET_TYPE", payload: "package"});
        // if (itinerary_type_details_data === null || (itinerary_type_details_data !== null && itinerary_type_details_data.id !== parseInt(props.params.itinerary_type_id))) {
            getTripFromURL();
        // }
        // else {
        //     setIsLoading(true);
        //     GetProducts(itinerary_type_details_data.trip.id, itinerary_type_details_data.trip.current_version, itinerary_type_details_data.trip.token, setProduct);
        //     setNewBreadCrumbs(itinerary_type_details_data.destination.id, itinerary_type_details_data.name);
        // }
        return () => {
            dispatch(SetItineraryTypeDetails(null));
            dispatch({ type: "ITINERARY_SET_LOWEST_PRICE", payload: 0 });
            dispatch({type: "ITINERARY_SET_TYPE", payload: ""});
            dispatch({type: "ITINERARY_SET_PRICE_DATA", payload: []});
            dispatch({type: "ITINERARY_TYPE_SET_ITINERARY_TRIP_DATA", payload: null});
        }
    }, []);
    useEffect(() => {
        if (itinerary_type_details_data !== null) {
            if (itinerary_type_details_data.trip !== null) {
                getTrip();
            }
        }
    }, [itinerary_type_details_data]);
    useEffect(() => {
        if (product.length > 0) {
            let tmp_nb_active_hotel = 0;
            let tmp_hotel = [];
            let tmp_flight = [];
            let tmp_transfers = [];
            let tmp_linked_dest = [];
            let tmp_assurances = [];
            let tmp_options = [];
            let tmp_poi = [];
            let tmp_cars = [];
            let tmp_simple_product = [];
            let tmp_currency_symbol = "-";
            let tmp_currency_iso = null;
            let tmp_flight_included = false;
            let selected_variant = [];
            let variant = -1;
            let tmp_transfers_has_variant = false;
            let tmp_flights_has_variant = false;
            let tmp_cars_has_variant = false;
            console.log('itinerary_type_details_data:', itinerary_type_details_data);
            tmp_flight_included = itinerary_type_details_data !== null ? itinerary_type_details_data.flight_included : false;
            for (let i = 0; i < product.length; i++) {
                if (!product[i].is_optional) {
                    if (product[i].product_type === 0 || product[i].product_type === 7) {
                        console.log('product:', product[i]);
                        if (product[i].is_optional === false) {
                            tmp_nb_active_hotel++;
                            tmp_hotel.push(product[i]);
                        }
                    }
                    if (product[i].product_type === 8 && product[i].is_optional === true) {
                        assurances.push(product[i]);
                    }
                    if (product[i].product_type === 6) {
                        if (product[i].variant !== null) {
                            tmp_flights_has_variant = true;
                        }
                        tmp_flight.push(product[i]);
                    }
                    if (product[i].product_type === 12) {
                        tmp_poi.push(product[i]);
                    }
                    if (product[i].product_type === 2) {
                        if (product[i].variant !== null) {
                            tmp_cars_has_variant = true;
                        }
                        tmp_cars.push(product[i]);
                    }
                    if (product[i].product_type === 4) {
                        if (product[i].variant !== null) {
                            tmp_transfers_has_variant = true;
                        }
                        tmp_transfers.push(product[i]);
                    }
                    if (product[i].product_type === 8) {
                        tmp_assurances.push(product[i]);
                    }
                    if ((product[i].variant === null || product[i].variant === 0) && !(product[i].product_type === 0 || product[i].product_type === 7)) {
                        selected_variant.push(product[i].id);
                    }
                }
                if (product[i].is_optional === true && product[i].product_type !== 8) {
                    tmp_options.push(product[i]);
                }
            }
            let hotel_smallest_variant = 999;
            let hotel_smallest_variant_id = null;
            for (let i = 0; i < product.length; i++) {
                if (product[i].product_type === 0 || product[i].product_type === 7) {
                    if (product[i].hotel !== undefined && product[i].is_optional === false) {
                        if (product[i].variant === null || (hotel_smallest_variant !== null && product[i].variant < hotel_smallest_variant)) {
                            hotel_smallest_variant = product[i].variant;
                            hotel_smallest_variant_id = product[i].id;
                        }
                    }
                }
            }
            if (hotel_smallest_variant_id !== null) {
                selected_variant.push(hotel_smallest_variant_id);
            }
            if (itinerary_type_details_data !== null && itinerary_type_details_data.keywords !== undefined && itinerary_type_details_data.keywords !== null) {
                tmp_linked_dest = itinerary_type_details_data.keywords.split(",")
            }
            if (itinerary_type_details_data !== null && itinerary_type_details_data.trip.currency !== undefined && itinerary_type_details_data.trip.currency !== null) {
                tmp_currency_symbol = itinerary_type_details_data.trip.currency.symbol;
                tmp_currency_iso = itinerary_type_details_data.trip.currency.iso_code;
            }
            tmp_hotel = tmp_hotel.sort((a, b) => {
                return (b.variant === null) - (a.variant === null) || +(a.variant > b.variant)|| -(a.variant < b.variant);
            });
            tmp_transfers = tmp_transfers.sort((a, b) => {
                return (b.variant === null) - (a.variant === null) || +(a.variant > b.variant)|| -(a.variant < b.variant);
            });
            tmp_cars = tmp_cars.sort((a, b) => {
                return (b.variant === null) - (a.variant === null) || +(a.variant > b.variant)|| -(a.variant < b.variant);
            });
            tmp_flight = tmp_flight.sort((a, b) => {
                return (b.variant === null) - (a.variant === null) || +(a.variant > b.variant)|| -(a.variant < b.variant);
            });
            tmp_poi = tmp_poi.sort((a, b) => {
                return (b.variant === null) - (a.variant === null) || +(a.variant > b.variant)|| -(a.variant < b.variant);
            });

            const base_arr = [0, 7, 4, 2, 11, 12, 1];

            tmp_options = tmp_options.sort((a, b) => {
                return base_arr.indexOf(a.product_type) - base_arr.indexOf(b.product_type)
            });

            setActiveHotel(tmp_nb_active_hotel);
            setHotels(tmp_hotel);
            setFlights(tmp_flight);
            setCars(tmp_cars);
            setPoi(tmp_poi);
            setTransfers(tmp_transfers);
            setLinkedDest(tmp_linked_dest);
            setAssurances(tmp_assurances);
            setOptions(tmp_options);
            setCurrencySymbol(tmp_currency_symbol);
            setCurrencyIso(tmp_currency_iso);
            setFlightIncluded(tmp_flight_included);
            setSimpleProduct(tmp_simple_product);
            setSelectedVariant(selected_variant);
            setTransfersHasVariant(tmp_transfers_has_variant);
            setFlightsHasVariant(tmp_flights_has_variant);
            setCarsHasVariant(tmp_cars_has_variant);
        }
    }, [product]);
    const getTripFromURL = () => {
        const { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            setIsLoading(true);
            axios({
                method: "GET",
                headers: headers,
                // url: `${API_HREF}client/${window.id_owner}/packages/${props.params.itinerary_type_id}/`,
                url: `${API_HREF}client/${window.id_owner}/packages/${props.params.itinerary_type_id}/?reduced=true`,
            }).then(function (response) {
                let data = Object.assign({}, response.data);
                console.log('data:', data);
                setNewBreadCrumbs(data.destination.id, data.name);
                if (data.trip !== null && data.trip.id !== undefined) {
                    GetProducts(data.trip.id, data.trip.current_version, data.trip.token, setProduct);
                }
                dispatch(SetItineraryTypeDetails(data));
            }).catch(function (error) {
                console.log('error:', error);
            });
        }
    }
    const getTrip = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${itinerary_type_details_data.trip.id}/?token=${itinerary_type_details_data.trip.token}`,
        }).then(function (response) {
            console.log('response:', response);
            let data_trip = Object.assign({}, response.data);
            dispatch({type: "ITINERARY_TYPE_SET_ITINERARY_TRIP_DATA", payload: data_trip})
            console.log('data_trip:', data_trip);
        }).catch(function (error) {
            console.log('trip error:', error);
        });
    }
    const setNewBreadCrumbs = (id, name) => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/destinations/${id}/breadcrumbs/`,
            }).then(function (response) {
                let data = response.data;
                history = [];
                let is_main = getDestinationPositionOnBreadcrumbs(data.child, id);
                if (is_main.type !== undefined) {
                    let tmp_arr = [];
                    let tmp_obj = {
                        name: t("home.back_home"),
                        data: null,
                        need_redirect: false
                    };
                    tmp_arr.push(tmp_obj);
                    for (let i = 0; i < history.length; i++) {
                        tmp_obj = {
                            name: history[i].international_name,
                            data: history[i],
                            need_redirect: true
                        };
                        tmp_arr.push(tmp_obj);
                    }
                    let tmp_obj2 = {
                        name: name,
                        data: null,
                        need_redirect: false
                    };
                    tmp_arr.push(tmp_obj2);
                    dispatch(SetHistorydest(tmp_arr));
                    setIsLoading(false);
                }
            }).catch(function (error) {
                console.log('err:', error);
            });
        }
    }
    const getDestinationPositionOnBreadcrumbs = (data, id_to_find) => {
        if (data.id !== parseFloat(id_to_find)) {
            if (data.data.type !== 0) {
                history.push(data.data);
            }
            return (getDestinationPositionOnBreadcrumbs(data.child, id_to_find));
        } else {
            history.push(data.data);
            return (data.data);
        }
    }
    const scrollToTable = () => {
        pricesRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    }
    const variantIsChecked = (product) => {
        return selectedVariant.includes(product.id);
    }
    const onToggleVariant = product => () => {
        console.log('product:', product);
        let variant_arr = selectedVariant.slice();
        let start_destination = product.start_destination.data.id;
        let start_date = product.start_date;
        let end_date = product.end_date;

        if (product.product_type === 0) {
            hotels.map((hotel) => {
                if (hotel.start_destination.data.id === start_destination && hotel.start_date === start_date && variant_arr.includes(hotel.id)) {
                    variant_arr = variant_arr.filter((el) => {
                        return el !== hotel.id;
                    });
                }
            })
        }
        if (product.product_type === 4) {
            transfers.map((transfer) => {
                if (transfer.start_destination.data.id === start_destination && moment(transfer.start_date).isSame(moment(start_date), "day") && variant_arr.includes(transfer.id)) {
                    variant_arr = variant_arr.filter((el) => {
                        return el !== transfer.id;
                    });
                }
            })
        }
        if (product.product_type === 2) {
            cars.map((car) => {
                if (car.start_destination.data.id === start_destination && moment(car.start_date).isSame(moment(start_date), "day") && variant_arr.includes(car.id)) {
                    variant_arr = variant_arr.filter((el) => {
                        return el !== car.id;
                    });
                }
            })
        }
        if (product.product_type === 6) {
            flights.map((flight) => {
                if (flight.start_destination.data.id === start_destination && variant_arr.includes(flight.id)) {
                    variant_arr = variant_arr.filter((el) => {
                        return el !== flight.id;
                    });
                }
            })
        }
        //a retirer une fois tous les produits traités
        if (variant_arr.includes(product.id)) {
            variant_arr = variant_arr.filter((el) => {
                return el !== product.id;
            });
        }
        else {
            variant_arr.push(product.id);
        }
        setSelectedVariant(variant_arr);
    }
    const handleDuplicateOpen = () => {
        setDuplicationOpen(!duplicationOpen);
    }
    const checkScreen = () => {
        if (is_connected) {
            if (!fullScreen) {
                handleDuplicateOpen();
            }
            else {
                setMobileDialog(true);
            }
        } else {
            setLoginDialog(true);
        }
    }
    return (
        <Grid container style={{display: "block"}}>
            <Grid item className={"wrapper"} className={classes.headerPicture}>
                <CustomHomeSlider height={"Packages"} pictures={(itinerary_type_details_data !== null && itinerary_type_details_data.pictures !== null) ? itinerary_type_details_data.pictures : []} />
            </Grid>
            <Grid item className={classes.headerContainer} id={"home-picture"} style={{display: "block"}}>
                <Grid container direction={"row"} justify={"flex-end"}>
                    <Grid item className={"home-nav"} id={"home-nav"} style={{top: 0}}>
                    {
						!is_connected  && (
                            <Fragment>
                                <Grid item style={{position: "absolute", left: 16, top: 20}}>
                                    <Link to={ `/home/${window.url_name}/` } id={ "header-home-link" }>
                                        <img src={ logo !== null ? (logo.thumbnail_little !== null ? logo.thumbnail_little : logo.url) : "/Img/inner_favicon_header.png" } height={ 30 } alt={ "" }/>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Button style={{color: "white"}} onClick={() => setLoginDialog(true)}>{t("home.nav.login")}</Button>
                                </Grid>
                            </Fragment>
						)
					}
                    {
                        is_connected && (
                            <MaterialHeader router={props.router}/>
                        )
                    }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.absoluteCenter} style={{width: fullScreen ? "100%" : "auto"}}>
                <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                    {
                        itinerary_type_details_data !== null && (
                            <Grid item container direction={"column"} justify={"center"} alignItems={"center"} style={{marginBottom: 50}}>
                                <Grid item>
                                    <Typography variant={"h2"} className={classes.destinationTitle}>{itinerary_type_details_data.name}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant={"h5"} style={{color: "white", textShadow: "black 1px 0 10px", textAlign: "center"}} className={classes.genericText}>{ itinerary_type_details_data.destination !== null && itinerary_type_details_data.destination.data.country !== undefined && itinerary_type_details_data.destination.data.country !== null ? itinerary_type_details_data.destination.data.country.name + " - " : "" }{ itinerary_type_details_data.destination !== null ? itinerary_type_details_data.destination.data.name !== undefined ? itinerary_type_details_data.destination.data.name : itinerary_type_details_data.destination.data.international_name : "" }</Typography>
                                </Grid>
                            </Grid>
                        )
                    }
                    <Grid item>
                        <Button variant={"contained"} fullWidth={fullScreen} color={"primary"} className={`${['visiteurs'].includes(quotation_code) ? classes.customButtonV : classes.customButton}`} onClick={checkScreen} >{t("itineraryType.choose_package")}</Button>
                    </Grid>
                </Grid>
            </Grid>
            {
                (is_connected || ['visiteurs'].includes(quotation_code)) && !isLoading && (
                    <Grid item style={{paddingBottom: fullScreen ? 120 : 70}}>
                        <Container className={classes.container}>
                            <Grid container direction={"row"} alignItems={"center"} spacing={4}>
                               <Grid item>
                                   <MaterialBreadcrumb router={props.router}/>
                               </Grid>
                            </Grid>
                            {
                                itinerary_type_details_data !== null && product.length > 0 && (
                                    <Grid container direction={"row"} justify={"flex-start"} spacing={4} style={{marginTop: 20}}>
                                        <Grid item sm={ fullScreen ? 12 : 8} xs={12}>
                                            <Grid container direction={"column"} justify={"center"} alignItems={"flex-start"} spacing={2}>
                                                <Grid item>
                                                    <Grid container direction={"column"} justify={"center"} alignItems={"flex-start"} spacing={2}>
                                                        {/* <Grid item>
                                                            <Typography variant={"h5"} className={classes.genericText}>{ itinerary_type_details_data.destination !== null && itinerary_type_details_data.destination.data.country !== undefined && itinerary_type_details_data.destination.data.country !== null ? itinerary_type_details_data.destination.data.country.name + " - " : "" }{ itinerary_type_details_data.destination !== null ? itinerary_type_details_data.destination.data.name !== undefined ? itinerary_type_details_data.destination.data.name : itinerary_type_details_data.destination.data.international_name : "" }</Typography>
                                                        </Grid> */}
                                                        <Grid item className={`${classes.genericText} ${classes.textJustify} `}>
                                                        { itinerary_type_details_data.description !== null ? itinerary_type_details_data.description : "" }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {
                                                itinerary_type_details_data.strengths !== undefined && itinerary_type_details_data.strengths !== null && itinerary_type_details_data.strengths.length > 0 && (
                                                    <Grid container direction={"column"} spacing={2} style={{marginTop: 20}} className={classes.genericText}>
                                                        <Grid item container xs style={{width: "100%"}} justify={"center"}>
                                                            <Typography variant={"h4"} >{t("itineraryType.strong_points")}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                        {
                                                            itinerary_type_details_data.strengths.map((strength, strength_index) => {
                                                                return (
                                                                    <div className={classes.listContainer} key={strength_index} ><ul><li>{ strength }</li></ul></div>
                                                                )
                                                            })
                                                        }
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                            <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={2} style={{marginTop: 20}}>
                                                <Grid item container xs style={{width: "100%"}} justify={"center"}>
                                                    <Typography variant={"h4"} className={classes.genericText}>{t("cart.accommodations")}</Typography>
                                                </Grid>
                                                {
                                                    hotels.map((hotel, hotel_index) => {
                                                        return (
                                                            <Fragment key={hotel_index}>
                                                                {
                                                                    hotels.length > 1 && hotel_index === 0 && (
                                                                        <Grid item container xs style={{width: "100%", marginBottom: 10}} justify={"flex-start"}>
                                                                            <Typography variant={"h5"} className={classes.genericText}>{t("itineraryType.default_accommodation")}</Typography>
                                                                        </Grid>
                                                                    )
                                                                }
                                                                <Grid item container className={classes.border}>
                                                                    {
                                                                        nb_active_hotel !== 1 && !fullScreen && (
                                                                            <Grid className={ clsx({[classes.bookingContainer]: variantIsChecked(hotel), [classes.hoverContainer]:  !variantIsChecked(hotel)}) } item>
                                                                                <div className={ classes.checkboxContainer }>
                                                                                <Checkbox className={ classes.checkbox } edge={ 'start' } checked={ variantIsChecked(hotel) } tabIndex={ -1 } disableRipple onChange={ onToggleVariant(hotel) }/>
                                                                                </div>
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                    <Grid item xs style={{width: "100%"}}>
                                                                        <HotelCard only_one_hotel={ nb_active_hotel === 1} hotel={hotel} index={hotel_index} nb_active_hotel={ nb_active_hotel } variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant}/>
                                                                        {
                                                                            hotel_index !== hotels.length - 1 && (
                                                                                <Divider style={{marginTop: 10, marginBottom: 10}}/>
                                                                            )
                                                                        }

                                                                    </Grid>
                                                                </Grid>
                                                                {
                                                                    hotels.length > 1 && hotel_index === 0 && (
                                                                        <Grid item container xs style={{width: "100%"}} justify={"flex-start"}>
                                                                            <Typography variant={"h5"} className={classes.genericText}>{t("itineraryType.alternate_accommodations")}</Typography>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            </Fragment>
                                                        );
                                                    })
                                                }
                                            </Grid>
                                            {
                                                flights.length > 0 && (
                                                    <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={2} style={{marginTop: 20}}>
                                                        <Grid item container xs style={{width: "100%"}} justify={"center"}>
                                                            <Typography variant={"h4"} className={classes.genericText}>{t("global.flights")}</Typography>
                                                        </Grid>
                                                        {
                                                            flights.map((flight, flight_index) => {
                                                                return (
                                                                    <Fragment key={flight_index}>
                                                                        {
                                                                            flights.length > 1 && flight_index === 0 && (
                                                                                <Grid item container xs style={{width: "100%", marginBottom: 10}} justify={"flex-start"}>
                                                                                    <Typography variant={"h5"} className={classes.genericText}>{t("itineraryType.default_flights")}</Typography>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                        <Grid item container >
                                                                            {
                                                                                flightsHasVariant && !fullScreen && (
                                                                                    <Grid className={ clsx({[classes.bookingContainer]: variantIsChecked(flight), [classes.hoverContainer]:  !variantIsChecked(flight)}) } item>
                                                                                        <div className={ classes.checkboxContainer }>
                                                                                        <Checkbox className={ classes.checkbox } edge={ 'start' } checked={ variantIsChecked(flight) } tabIndex={ -1 } disableRipple onChange={ onToggleVariant(flight) }/>
                                                                                        </div>
                                                                                    </Grid>
                                                                                )
                                                                            }
                                                                            <Grid item xs style={{width: "100%"}}>
                                                                                <FlightsCard flight={flight} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant} flightsHasVariant={flightsHasVariant}/>
                                                                                {
                                                                                    flight_index !== flights.length - 1 && (
                                                                                        <Divider style={{marginTop: 10, marginBottom: 10}}/>
                                                                                    )
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                        {
                                                                            flights.length > 1 && flight_index === 0 && (
                                                                                <Grid item container xs style={{width: "100%"}} justify={"flex-start"}>
                                                                                    <Typography variant={"h5"} className={classes.genericText}>{t("itineraryType.alternate_flights")}</Typography>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                )
                                            }
                                            {
                                                poi.length > 0 && (
                                                    <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={2} style={{marginTop: 20}}>
                                                        <Grid item container xs style={{width: "100%"}} justify={"center"}>
                                                            <Typography variant={"h4"} className={classes.genericText}>{t("cart.activities")}</Typography>
                                                        </Grid>
                                                        {
                                                            poi.map((activity, activity_index) => {
                                                                console.log('activity:', activity);
                                                                return (
                                                                    <Grid item container key={activity_index}>
                                                                        <Grid item xs style={{width: "100%"}}>
                                                                            <ActivitiesCard activity={activity}/>
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                )
                                            }
                                            {
                                                transfers.length > 0 && (
                                                    <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={2} style={{marginTop: 20}}>
                                                        <Grid item container xs style={{width: "100%"}} justify={"center"}>
                                                            <Typography variant={"h4"} className={classes.genericText}>{t("apps_navigation.transfer")}</Typography>
                                                        </Grid>
                                                        {
                                                            transfers.map((transfer, transfer_index) => {
                                                                return (
                                                                    <Fragment key={transfer_index}>
                                                                        {
                                                                            transfers.length > 1 && transfer_index === 0 && ((transfers[transfer_index + 1] !== undefined && transfers[transfer_index + 1].variant !== null) || (transfers[transfer_index + 2] !== undefined && transfers[transfer_index + 2].variant !== null)) && (
                                                                                <Grid item container xs style={{width: "100%", marginBottom: 10}} justify={"flex-start"}>
                                                                                    <Typography variant={"h5"} className={classes.genericText}>{t("itineraryType.default_transfers")}</Typography>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                        <Grid item container >
                                                                            {
                                                                                transfersHasVariant && !fullScreen && (
                                                                                    <Grid className={ clsx({[classes.bookingContainer]: variantIsChecked(transfer), [classes.hoverContainer]:  !variantIsChecked(transfer)}) } item>
                                                                                        <div className={ classes.checkboxContainer }>
                                                                                        <Checkbox className={ classes.checkbox } edge={ 'start' } checked={ variantIsChecked(transfer) } tabIndex={ -1 } disableRipple onChange={ onToggleVariant(transfer) }/>
                                                                                        </div>
                                                                                    </Grid>
                                                                                )
                                                                            }
                                                                            <Grid item xs style={{width: "100%"}}>
                                                                                <TransfersCard transfer={transfer} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant} transfersHasVariant={transfersHasVariant}/>
                                                                                {
                                                                                    transfer_index !== transfers.length - 1 && (
                                                                                        <Divider style={{marginTop: 10, marginBottom: 10}}/>
                                                                                    )
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                        {
                                                                            transfers.length > 1 && transfer_index <= 1 && transfers[transfer_index + 1] !== undefined && transfers[transfer_index + 1].variant !== null && (
                                                                                <Grid item container xs style={{width: "100%"}} justify={"flex-start"}>
                                                                                    <Typography variant={"h5"} className={classes.genericText}>{t("itineraryType.alternate_transfers")}</Typography>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                )
                                            }
                                            {
                                                cars.length > 0 && (
                                                    <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={2} style={{marginTop: 20}}>
                                                        <Grid item container xs style={{width: "100%"}} justify={"center"}>
                                                            <Typography variant={"h4"} className={classes.genericText}>{t("providers.rental_car")}</Typography>
                                                        </Grid>
                                                        {
                                                            cars.map((car, car_index) => {
                                                                return (
                                                                    <Fragment key={car_index}>
                                                                        {
                                                                            cars.length > 1 && car_index === 0 &&  (
                                                                                <Grid item container xs style={{width: "100%", marginBottom: 10}} justify={"flex-start"}>
                                                                                    <Typography variant={"h5"} className={classes.genericText}>{t("itineraryType.default_cars")}</Typography>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                        <Grid item container >
                                                                            {
                                                                                carsHasVariant && !fullScreen && (
                                                                                    <Grid className={ clsx({[classes.bookingContainer]: variantIsChecked(car), [classes.hoverContainer]:  !variantIsChecked(car)}) } item>
                                                                                        <div className={ classes.checkboxContainer }>
                                                                                        <Checkbox className={ classes.checkbox } edge={ 'start' } checked={ variantIsChecked(car) } tabIndex={ -1 } disableRipple onChange={ onToggleVariant(car) }/>
                                                                                        </div>
                                                                                    </Grid>
                                                                                )
                                                                            }
                                                                            <Grid item xs style={{width: "100%"}}>
                                                                                <CarsCard car={car} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant} carsHasVariant={carsHasVariant}/>
                                                                                {
                                                                                    car_index !== cars.length - 1 && (
                                                                                        <Divider style={{marginTop: 10, marginBottom: 10}}/>
                                                                                    )
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                        {
                                                                            cars.length > 1 && car_index === 0 &&  (
                                                                                <Grid item container xs style={{width: "100%"}} justify={"flex-start"}>
                                                                                    <Typography variant={"h5"} className={classes.genericText}>{t("itineraryType.alternate_cars")}</Typography>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                        {
                                            !fullScreen && (
                                                <Grid item sm={4}>
                                                    <Card className={ `${classes.stickyPaper} ${classes.genericText}` } raised>
                                                        <RightPanel
                                                            checkScreen={checkScreen}
                                                            options={options}
                                                            assurances={assurances}
                                                            selectedOptions={selectedOptions}
                                                            setSelectedOptions={setSelectedOptions}
                                                            pricesRef={pricesRef}
                                                            setSalePitch={setSalePitch}
                                                            dateIsAfter={dateIsAfter}
                                                        />
                                                    </Card>
                                                </Grid>
                                            )
                                        }
                                        <Grid item sm={12} className={fullScreen ? classes.fullWidth : ""}>
                                            <PriceCalendar dateIsAfter={dateIsAfter} setDateIsAfter={setDateIsAfter} type={"packages"}/>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <RenderExtra pricesRef={pricesRef}/>
                                        </Grid>
                                        {
                                            fullScreen && (
                                                <Grid container className={ classes.staticPriceContainer } direction={"column"} justify={ "center" }>
                                                    <Grid item>
                                                        <Button variant={ "contained" } className={ classes.orangeButton } fullWidth={ true } onClick={ checkScreen } >{t("itineraryType.choose_package")}</Button>
                                                    </Grid>
                                                    <Grid item style={{textAlign: "right"}}>
                                                        <div style={{fontSize: 12, textDecoration: "underline", cursor: "pointer"}} onClick={scrollToTable}>{t("itineraryType.see_prices")}</div>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                )
                            }
                        </Container>
                    </Grid>
                )
            }
            {
                (is_connected || ['visiteurs'].includes(quotation_code)) && isLoading && (
                    <Grid item style={{paddingBottom: 70}}>
                        <Container className={classes.container}>
                            <Grid container direction={"row"} justify={"center"} alignItems={"center"} spacing={4}>
                               <Grid item>
                                   <CircularProgress/>
                               </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                )
            }
            {
                fullScreen && (
                    <MobileRightPanel
                        mobileDialog={mobileDialog}
                        setMobileDialog={setMobileDialog}
                        checkScreen={checkScreen}
                        options={options}
                        assurances={assurances}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        pricesRef={pricesRef}
                        setSalePitch={setSalePitch}
                        dateIsAfter={dateIsAfter}
                    />
                )
            }
            <MaterialDuplicateDialog
                duplicationOpen={duplicationOpen}
                setDuplicationOpen={setDuplicationOpen}
                isFrom={"package"}
                duration_day={itinerary_type_details_data !== null ? itinerary_type_details_data.duration_day : 0}
                hotels={hotels}
                flights={flights}
                poi={poi}
                assurances={assurances}
                cars={cars}
                selectedVariant={selectedVariant}
                selectedOptions={selectedOptions}
                router={props.router}
            />
            <HotelInfo/>
            <SalePitchDialog salePitch={salePitch} setSalePitch={setSalePitch} infos={itinerary_type_details_data}/>
            <MaterialLogin loginDialog={loginDialog} setLoginDialog={setLoginDialog} logo={logo} router={props.router} token={null} id={null}/>
            { JSON.parse(localStorage.getItem("config")).quotation_code === 'visiteurs' && <Help/> }
        </Grid>
    )
}
export default PackagesDetails;