//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import AccommodationCardPictures from "../../Accommodation/FunctionalComponent/AccommodationCardPictures";
import { Skeleton } from '@mui/material';

const CircuitSkeleton = () => {
	return (
		<Grid container direction={"row"} spacing={2}
        style={{
            padding: 10,
            border: '1px solid #ebebeb',
            borderRadius: 13,
            marginBottom: 10,
            backgroundColor: 'white',
            marginRight: 7,
            marginLeft: 7
        }}>
			<Grid item xs={12}>
                <Skeleton variant="text" width={'100%'} height={50} />  
			</Grid>
			<Grid item container direction={"row"} justify={"flex-start"}>
                <AccommodationCardPictures size={150} detail={null} pictures={[]}/>
                <Grid item sm={12} xs={12} style={{marginTop: 22}}>
                    <Grid container direction={"column"} spacing={2}>
                        <Grid item>
                            <Skeleton variant="text" width={'100%'} height={50} />
                        </Grid>
                    </Grid>
                </Grid>
			</Grid>
		</Grid>
	)
};
export default CircuitSkeleton;