//---- Dependencies ----//
import React, {Fragment, useEffect, useState, useRef} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";

import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import GetTripInfo from "./Functions/GetTripInfo";
import SummaryHeader from "./SummaryHeader";
import SummaryInscription from "./SummaryInscription";
import PlaneLoader from "../PlaneLoader/PlaneLoader.jsx";
import Footer from "../Footer/Footer.jsx";
import SummaryContact from "./SummaryContact";
import GetPricesId from "../Cart/Functions/Margin/GetPricesId";
import TripSummary from "./TripSummary";
import SummaryDocuments from "./SummaryDocuments";
import SummaryHeaderMobile from "./SummaryHeaderMobile";

import { SetTripDocs } from '../../Actions/Menu.js';
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetTotalPrices from "./Functions/GetTotalPrices";
import GetOutOfCart from "./Functions/GetOutOfCart";
import { SetSummaryStep } from "../../Actions/Summary";
import GetPrice from "./Functions/GetPrice";

const useStyles = makeStyles(theme => ({
    absoluteCenter: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    lightGreen: {
        color: "#76B6C2 !important"
    },
    textCenter: {
        textAlign: "center"
    },
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
}));

const SummaryQuotation = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { pass_check, headers } = CheckBeforeRequest();

    const summary_step = useSelector(store => store.summary.summary_step);
    const hotel_cart = useSelector(store => store.summary.hotel_cart);
    const cars_cart = useSelector(store => store.summary.cars_cart);
    const flight_cart = useSelector(store => store.summary.flight_cart);
    const manual_cart = useSelector(store => store.summary.manual_cart);
	const transfers_cart = useSelector(store => store.summary.transfers_cart);
	const poi_cart = useSelector(store => store.summary.poi_cart);
    const trip_info = useSelector(store => store.summary.trip_info);
    const trip_main_info = useSelector(store => store.summary.trip_main_info);
    const trip_docs = useSelector(store => store.menu.trip_docs);
    const selectedProducts = useSelector(store => store.summary.selectedProducts);
    const itinerary = useSelector(store => store.summary.itinerary);

    const [missingDocs, setMissingDocs] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(SetSummaryStep(2));
        if (trip_info === null) {
            setIsLoading(true);
            GetTripInfo(props.params.trip_id, props.params.trip_token, dispatch, setIsLoading);
        }
    }, []);
    useEffect(() => {
        if (trip_main_info !== null && trip_docs.length === 0) {
            getDoc();
        }
    }, [trip_main_info]);

    useEffect(() => {
        if (!isLoading) {
            GetPrice(itinerary, flight_cart, hotel_cart, manual_cart, poi_cart, cars_cart, transfers_cart, selectedProducts, trip_info, dispatch);
        }
    }, [isLoading]);
    useEffect(() => {
        if (trip_docs.length !== 0) {
            let total_requested = 0;
            let total_received = 0;
            trip_docs.map((document) =>{
                if(document.requested === true){
                    total_requested += 1;
                    if(document.received === true){
                        total_received += 1;
                    }
                }
            })
            let temp_missing_docs = total_requested - total_received;
            setMissingDocs(temp_missing_docs);
        }
    }, [trip_docs]);

    const getDoc = () => {
		axios({
			method: "GET",
			url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_main_info.current_version}/file/?token=${props.params.trip_token}`,
			headers: headers,
		}).then(function(response){
			dispatch(SetTripDocs(response.data.results));
		}).catch(function(error){
			console.log(error.responseJSON);
		});
	}
    if (!isLoading && trip_main_info !== null) {
        return (
            <Grid container style={{display: "block"}}>
                <Grid item>
                    <SummaryHeader missingDocs={missingDocs} props={props}/>
                </Grid>
                <Grid item>
                    <TripSummary/>
                </Grid>
                {
                    !fullScreen && (
                        <Grid item style={{marginTop: "calc(5% + 60px)", bottom: 0}}>
                            <Footer/>
                        </Grid>
                    )
                }
                {
                    fullScreen && (
                        <Grid item>
                            <SummaryHeaderMobile missingDocs={missingDocs} props={props}/>
                        </Grid>
                    )
                }
            </Grid>
        )
    }
    else {
        return (
            <div className={classes.absoluteCenter} >
                <PlaneLoader />
                <div className={`${classes.textCenter} ${classes.lightGreen}`} > {t("summary.get_all_info")} </div>
            </div>
        )
    }
}
export default SummaryQuotation;