//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { Link, History } from "react-router";
import axios from "axios";
//---- Core ----//
import Grid from '@material-ui/core/Grid';
import { green } from "@material-ui/core/colors";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
//---- Icons ----//
import Menu from '@material-ui/icons/Menu';
//---- Component ----//
import Footer from '../Footer/Footer';
import VisiteurHeader from './VisiteursHeader';
import CustomHomeSlider from '../Home/CustomHomeSlider';
import HomeSlider from '../Home/HomeSlider';
import VisiteursDestCard from './VisiteursDestCard';
//---- Common ----/
import IsIE from "../Common/Functions/IsIE.js";
import GetCookie from "../Common/Functions/GetCookie";
import CheckBeforeRequest from "../Common/CheckBeforeRequest.js";
//---- Actions ----//
import { SetDestinations } from '../../Actions/Home';
import MaterialLogin from '../Home/MaterialLogin';
import DestinationDialog from '../Home/DestinationDialog';

const useStyles = makeStyles(theme => ({
    absoluteCenter: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    customButton: {
        background: '#BF202D',
        // padding: "15px 40px",
        textAlign: "center",
        color: "#fff",
        fontSize: 20,
        textTransform: "none",
        position: "relative",
        "&:hover": {
            background: "#cc4f2a"
        },
        "&&.MuiButton-contained.Mui-disabled": {
            backgroundColor: '#BF202D'
        }
    },
    customOutlinedButton: {
        color: '#BF202D',
        borderColor: '#BF202D',
        "&:hover": {
            borderColor: '#BF202D',
        }
    },
    fullWidth: {
        width: "100%"
    },
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "76%",
        [theme.breakpoints.down('sm')]: {
            top: "12%",
        },
        left: "43%",
        // marginTop: -20,
        // marginLeft: -12,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    imgContainer: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxHeight: 450
        },
        maxHeight: 450
    },
    coverImg: {
        display: 'block',
        width: '100%',
        objectFit: 'cover',
        maxHeight: 107,
        objectPosition: '25% 55%',
        [theme.breakpoints.up('sm')]: {
            maxHeight: 310,
        },
    },
    largeLogo: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
    }
}));

const Home = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const config = JSON.parse(localStorage.getItem("config"));

    const is_connected = useSelector(store => store.user.is_connected);
    const current_dest = useSelector(store => store.home.destinations);
    const destinations = useSelector(store => store.home.destinations);
    const modal = useSelector(store => store.home.modal);

    const [isIE, setIsIE] = useState(false);
    const [homepage_img, setHomepageImg] = useState([]);
    const [has_custom_homepage, setHasCustomHompage] = useState(false);
    const [full_dest, setFullDest] = useState([]);
    const [sortDest, setSortDest] = useState([]);
    const [favicon] = useState(config !== null && config.favicon !== undefined && config.favicon !== null && config.favicon.url !== undefined && config.favicon.url !== null ? config.favicon : null);
    const [logo] = useState(config !== null && config.logo !== undefined && config.logo !== null && config.logo.url !== undefined && config.logo.url !== null ? config.logo : null);
    const [loginDialog, setLoginDialog] = useState(false);
    const [destinationDialog, setDestinationDialog] = useState(false);
    const [id, setId] = useState(null);
    const [token, setToken] = useState(null);
    const [sortDestination, setSortDestination] = useState(false);
    const handleCreateButton = () => {
        setDestinationDialog(true);
    };
    const loadMoreDest = () => {
		let tmp_arr = current_dest.slice();
		if (current_dest.length < sortDest.length) {
			let max = 0;
			if (sortDest.length - current_dest.length >= 6) {
				max = current_dest.length + 6;
			} else {
				max = current_dest.length + (sortDest.length - current_dest.length);
			}
			for (let i = current_dest.length; i < max; i++) {
				tmp_arr.push(sortDest[i]);
			}
			dispatch(SetDestinations(tmp_arr));
		}
	};
    const toggleSort = () => {
        let tmp_arr = [];
        let tmp_dest = [];
        if (sortDestination) {
            tmp_arr = full_dest;
        } else {
            tmp_arr = [...full_dest].sort((a, b) => {
                let fa = a.data.international_name.toLowerCase();
                let fb = b.data.international_name.toLowerCase()
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
        }
        for (let i = 0; i < current_dest.length; i++) {
            tmp_dest.push(tmp_arr[i]);
        }
        dispatch(SetDestinations(tmp_dest));
        setSortDest(tmp_arr);
        setSortDestination(!sortDestination);
    };
    useEffect(() => {
        let { pass_check, headers } = CheckBeforeRequest();
        setIsIE(IsIE());
        if (props.location.query.token !== undefined && props.location.query.token !== null && props.location.query.id !== undefined && props.location.query.id !== null) {
            setToken(props.location.query.token);
            setId(props.location.query.id);
            setLoginDialog(true);
        }
        if (modal) {
            setDestinationDialog(true);
            dispatch({type: "HOME_TOGGLE_MODAL", payload: {modal: false, step: 1}});
        }
        dispatch({type: "TRIP_SET_ALL_DATA", payload: {data: null}});
        dispatch({type: "TRIP_SET_DATA_TRIP", payload: {data_trip: null}});
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/pictures/?type=HOMEPAGE`,
        }).then(function (response) {
            if (response.data.results.length > 0) {
                console.log('response.data.results:', response.data.results);
                setHomepageImg(response.data.results);
                setHasCustomHompage(true);
            }
        }).catch(function (error) {
            console.log('pictures error:', error);
        });
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/destinations/?limit=1000&ordering=-current_version__note&visibility__in=PUBLIC,PRIVATE_TO&homepage=true`,
        }).then(function (response) {
            console.log('data:', response);
            let tmp_arr = [];
            for (let i = 0; i < 9; i++) {
                if (response.data.results[i] !== undefined) {
                    tmp_arr.push(response.data.results[i]);
                }
            }
            dispatch(SetDestinations(tmp_arr));
            dispatch({type: 'HOME_SET_FULL_DESTINATIONS', payload: [...response.data.results]});
            setFullDest([...response.data.results]);
            setSortDest([...response.data.results]);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('destination error:', error);
        });
    }, []);
    return (
        <Grid container style={{display: "block"}}>
            <VisiteurHeader router={props.router} setLoginDialog={setLoginDialog} full_dest={full_dest}/>
            <Grid container justify={"center"} alignItems={"center"} alignContent={ "center" }>
                <Grid item className={classes.imgContainer}>
                    <img src={ (homepage_img.length !== 0) ? (homepage_img[0].thumbnail_big !== null ? homepage_img[0].thumbnail_big : homepage_img[0].url ) : "/Img/visiteurs/home.avif"} className={classes.coverImg} />
                </Grid>
                <Grid item style={{position: 'absolute'}}>
                    <Grid container direction={'column'} justify={'center'} alignItems={'center'} spacing={ 4 }>
                        <Grid item className={classes.largeLogo}>
                            <img src={ "/Img/visiteurs/Plan&TripBlanc.png"} width={'315px'}/>
                        </Grid>
                        <Grid item>
                            <Button variant={"contained"} fullWidth={fullScreen} disabled={destinations.length === 0} className={classes.customButton} onClick={handleCreateButton} >{t("home.create_trip_btn")}</Button>
                            {
                                destinations.length === 0 && (
                                    <CircularProgress className={classes.buttonProgress}/>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{paddingBottom: 70}}>
                <Container className={classes.container}>
                    <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={4}>
                        {
                            destinations.length !== 0 &&
                            <Grid item style={{width: '100%'}}>
                                <div style={{textDecoration: 'underline', float: 'right', cursor: 'pointer'}} onClick={toggleSort}>{!sortDestination ? 'Classer par ordre alphabétique' : 'Classer par pertinence'}</div>
                            </Grid>
                        }
                        <Grid item style={{width: "100%"}}>
                            <Grid container direction={"row"} spacing={2} justify={"center"}>
                            {
                                destinations.map((destination, destination_index) => {
                                    return (
                                        <VisiteursDestCard key={destination_index} destination={destination} router={props.router} type={"home"}/>
                                    )
                                })
                            }
                            </Grid>
                        </Grid>
                        {
                            current_dest.length < full_dest.length && (
                                <Grid item>
                                    <Button variant={"outlined"} fullWidth={fullScreen} color={"primary"} className={classes.customOutlinedButton} onClick={loadMoreDest}>{t("home.load_more_dest")}</Button>
                                </Grid>
                            )
                        }
                    </Grid>
                </Container>
            </Grid>
            <DestinationDialog router={props.router} destinationDialog={destinationDialog} setDestinationDialog={setDestinationDialog} full_dest={full_dest}/>
            <MaterialLogin loginDialog={loginDialog} setLoginDialog={setLoginDialog} logo={logo} router={props.router} token={token} id={id}/>
            <Footer/>
        </Grid>
    )
}
export default Home;