import { teal } from "@mui/material/colors";

type Status = 'DEFAULT' | 'OPTION' | 'HIDE' | 'HIDE_COUNT' | 'VARIANT';

type Item = {
    is_optional: boolean,
    is_hidden_for_traveler: boolean,
    is_hidden_for_traveler_count_price: boolean,
    variant: unknown | null
}

export function getTableItemBackgroundColor(
    item: Item,
    itinerary_type_details_data: { variant_name: unknown }
): string {
    let status: Status = 'DEFAULT';

    if (item.is_optional) {
        status = 'OPTION';
    } else if (item.is_hidden_for_traveler) {
        status = 'HIDE';
    } else if (item.is_hidden_for_traveler_count_price) {
        status = 'HIDE_COUNT';
    } else if (item.variant && itinerary_type_details_data && itinerary_type_details_data.variant_name) {
        status = 'VARIANT';
    }

    let color = '#fff';

    switch (status) {
        case 'OPTION': color = '#AFD7F8'; break;
        case 'HIDE': color = '#F3E1E0'; break;
        case 'HIDE_COUNT': color = '#C7B7E3'; break;
        case 'VARIANT': color = teal[500]; break;
        case 'DEFAULT':
        default: color = '#fff'; break;
    }

    return color;
}
