import { Destination } from "../../Itinerary/objects/destination";
import { Itinerary } from "../../Itinerary/objects/itinerary";

type Product = {
    end_destination?: Destination | null,
    start_date: string,
    end_date: string
}

type Options = {
    type: 'poi' | 'transfer',
    itinerary: Itinerary[],
    step: Itinerary,
    product: Product,
    noEndDestinationCheck?: boolean
}

export function checkIfOnRoad(options: Options): [Itinerary, Itinerary] | false {
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    if (quotationCode !== 'cercledesvoyages') {
        return false;
    }

    const index = options.itinerary.findIndex((item) => {
        return item.id === options.step.id;
    });
    const nextStep = options.itinerary[index + 1];
    const previousStep = options.itinerary[index - 1];

    switch (options.type) {
        case 'poi': {
            if (
                nextStep &&
                (
                    nextStep.destination?.id === options.product.end_destination?.id ||
                    options.noEndDestinationCheck
                ) &&
                window.moment.utc(options.product.start_date).isAfter(
                    window.moment.utc(options.step.end_date)
                ) &&
                window.moment.utc(options.product.start_date).isSameOrBefore(
                    window.moment.utc(nextStep.start_date)
                )
            ) {
                return [options.step, nextStep];
            } else if (
                previousStep &&
                (
                    previousStep.destination?.id === options.product.end_destination?.id ||
                    options.noEndDestinationCheck
                ) &&
                window.moment.utc(options.product.start_date).isAfter(
                    window.moment.utc(previousStep.end_date)
                ) &&
                window.moment.utc(options.product.start_date).isSameOrBefore(
                    window.moment.utc(options.step.start_date)
                )
            ) {
                return [previousStep, options.step];
            }
            break;
        }
        case 'transfer': {
            if (
                nextStep &&
                window.moment.utc(options.product.start_date).isAfter(
                    window.moment.utc(options.step.end_date),
                    'minutes'
                )
            ) {
                return [options.step, nextStep];
            }
        }
    }
    return false;
}
