export enum RoadbookConfigurationIdentifier {
    EDITO = 'EDITO',
    AGENDA = 'AGENDA',
    CGV = 'CGV',
    CONTACTS = 'CONTACTS',
    FLIGHT = 'FLIGHT',
    ITINERARY = 'ITINERARY',
    OTHER_TRANSPORT_MODE = 'OTHER_TRANSPORT_MODE',
    PICTURE = 'PICTURE',
    PROGRAM = 'PROGRAM',
    ROADBOOK_CATEGORY = 'ROADBOOK_CATEGORY',
    SUMMARY = 'SUMMARY',
    STEPS = 'STEPS',
    VOUCHER = 'VOUCHER',
}
