import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';

import GetUser from "./Functions/GetUser";
import ChangePicture from "./ChangePicture";
import clsx from "clsx";
import axios from "axios";
import GetCookie from "../../Common/Functions/GetCookie";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { useSnackbar } from "notistack";
import i18n from "../../../i18n.jsx";
import MuiPhoneNumber from "material-ui-phone-number";
import { ToggleAndSetLanguage } from "../../../Actions/Header";
import localeText from '../../Functions/localeText';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    stickyPaper: {
        position: "sticky",
        top: 80,
        borderRadius: 12
    },
    mobileSticky: {
        position: "sticky",
        top: 70,
        zIndex: 2,
        backgroundColor: "white"
    },
    cardTitle: {
    	"& .mui-jss-MuiCardHeader-title": {
	    	fontSize: 22
    	},
    	"fontSize": 22
    },
    cardPadding: {
        padding: "10px 10px 0 10px"
    },
    alignCenter: {
        textAlign: "center"
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    bold: {
        fontWeight: "bold"
    },
    inline: {
        display: "inline"
    },
    inputBorder: {
        "&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: 8
        }
    },
    orangeButton: {
        color: "white",
        backgroundColor: "#E6592F"
    },
    genericText: {
        color: "#0000008A",
        fontStyle: "normal",
        fontFamily: "Roboto"
    },
    footer: {
        marginTop: "8%",
        position: "sticky",
        bottom: 0,
        zIndex: 2,
        backgroundColor: "white"
    },
    footerMobile: {
        marginTop: "29%",
        position: "sticky",
        bottom: 64,
        zIndex: 2,
        backgroundColor: "white"
    },
    avatar: {
        width: 150,
        height: 150
    },
    mobileAvatar: {
        width: 60,
        height: 60
    },
    formControlRoot1: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 30px 7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12
        }
    }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MaterialProfile = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const downDesktop = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector(store => store.user.user);
    const main_locale = useSelector(store => store.user.main_locale);
    const activated_locales = useSelector(store => store.user.activated_locales);
    const current_locale = useSelector(store => store.user.current_locale);

    const [userName, setUserName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [avatarId, setAvatarId] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const languageOptions = [];
    if (activated_locales) {
        activated_locales.map((activated_locale) => {
            languageOptions.push({
                label: localeText(main_locale, activated_locale.localTranslation, activated_locale.full_name, 'translated_name'),
                value: activated_locale.language_code
            });
        });
    }
    //const languageOptions = [
    //{label: t("language.french"), value: "fr"},
    //{label: t("language.english"), value: "en"}
    //]
    let init_lang = null;
    if (localStorage.getItem("i18nextLng") !== null && localStorage.getItem("i18nextLng") !== "null") {
        let tmp = localStorage.getItem("i18nextLng");
        if (tmp.includes("-")) {
            tmp = tmp.split("-");
            init_lang = activated_locales.find((locale) => locale.language_code === tmp[0]);
        } else {
            init_lang = activated_locales.find((locale) => locale.language_code === tmp);
        }
    }
    const [language, setLanguage] = useState(init_lang);
    useEffect(() => {
        if (user !== null) {
            GetUser(dispatch, user.id, user.client);
            reset();
        }
    }, []);
    useEffect(() => {
        if (user !== null) {
            reset();
        }
    }, [user]);

    const reset = () => {
        if (user !== null) {
            setUserName(user.username);
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setPhoneNumber(user.phone_number);
            setEmail(user.email);
            setAvatarId(user.avatar_id);
            setAvatar(user.avatar);
        }
    };
    const checkPassword = () => {
        if (password !== confirmPassword) {
            setPasswordError(true);
        }
        if (password === "" && confirmPassword === "") {
            setPasswordError(false);
        }
    };
    const saveInfo = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        let request = {
            username: userName,
            email: email,
            last_name: lastName,
            first_name: firstName,
            lang: language.language_code
        };
        if (phoneNumber.length > 4) {
            request.phone_number = phoneNumber;
        }
        if (password !== "" && !passwordError) {
            request.password = password;
        }
        if (pass_check) {
            axios({
                method: "PATCH",
                headers: headers,
                url: `${API_HREF}client/${user.client}/user/${user.id}/`,
                data: JSON.stringify(request)
            }).then(function (response) {
                let data = Object.assign({}, response.data);
                dispatch({
                    type: "USER_SET_USER",
                    payload: {
                        user: data
                    }
                });
                dispatch(ToggleAndSetLanguage(false, language.value));
                enqueueSnackbar(t("notify.changed_success"), {
                    variant: "success"
                });
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
                enqueueSnackbar(t("notify.changed_failed"), {
                    variant: "error"
                });
            });
        }
    };
    const handleLeadPhoneNumber = ((value) => {
        setPhoneNumber(value);
    });
    const getLabel = (option) => {
        if (option !== undefined) {
            let label = option.LocalTranslation.find(n => n.localeTranslate === current_locale);
            if (label !== undefined) {
                return label.translated_name;
            }
            return option.full_name;
        }
    };
    return (
        <Container className={classes.container}>
            <Grid container spacing={ 2 }>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom className={classes.genericText}>
                        { t("header.menu.profile") }
                    </Typography>
                </Grid>
                {
                    !downDesktop && (
                        <Grid item md={4}>
                            <Card className={ classes.stickyPaper }>
                                <CardContent className={`${classes.cardTitle} ${classes.cardPadding}`}>
                                    <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                                        <Grid item>
                                            <Avatar className={classes.avatar} src={avatar !== null ? (avatar.thumbnail_little !== null ? avatar.thumbnail_little : avatar.url) : "Img/avatar.png"} />
                                        </Grid>
                                        <Grid item style={{ marginTop: 5 }}>
                                            <Button onClick={() => setOpenModal(true)} startIcon={<BackupOutlinedIcon />}>{t("menu.profile.upload_img")}</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                }
                {
                    downDesktop && (
                        <Grid item xs={12} className={classes.mobileSticky}>
                            <Grid container direction={"row"} justify={"flex-start"} alignItems={"center"}>
                                <Grid item>
                                    <Avatar className={classes.mobileAvatar} src={avatar !== null ? (avatar.thumbnail_little !== null ? avatar.thumbnail_little : avatar.url) : "Img/avatar.png"} />
                                </Grid>
                                <Grid item style={{marginLeft: 5}}>
                                    <Button onClick={() => setOpenModal(true)} startIcon={<BackupOutlinedIcon />}>{t("menu.profile.upload_img")}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
                <Grid item md={8} xs={12}>
                    <Grid container direction="column" spacing={4} style={{ marginBottom: 10 }}>
                        <Grid item><Typography variant={"h6"}>{t("global.personal_information")}</Typography></Grid>
                        <Grid item>
                            <TextField className={classes.inputBorder} fullWidth value={firstName} onChange={(e) => setFirstName(e.target.value)} label={ t("menu.profile.surname") } variant="outlined" />
                        </Grid>
                        <Grid item>
                            <TextField className={classes.inputBorder} fullWidth value={lastName} onChange={(e) => setLastName(e.target.value)} label={ t("global.name") } variant="outlined" />
                        </Grid>
                        <Grid item>
                            <TextField className={classes.inputBorder} fullWidth value={userName} onChange={(e) => setUserName(e.target.value)} label={ t("menu.profile.username") } variant="outlined" helperText={t("menu.profile.info_username")} />
                        </Grid>
                        <Grid item>
                            <MuiPhoneNumber
                                defaultCountry={phoneNumber === '' ? (user.lang !== null ? (user.lang !== "en" ? user.lang.split('-')[0] : "gb") : (language.value !== "en" ? language.value : "gb")) : ''  }
                                value={phoneNumber}
                                className={classes.inputBorder}
                                onChange={handleLeadPhoneNumber}
                                variant="outlined"
                                disableAreaCodes
                                fullWidth
                                label={ t("menu.profile.phone_number") }
                            />
                            {/*<TextField className={classes.inputBorder} fullWidth value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} label={ t("menu.profile.phone_number") } variant="outlined" /> */}
                        </Grid>
                        <Grid item>
                            <TextField className={classes.inputBorder} fullWidth value={email} onChange={(e) => setEmail(e.target.value)} label={ t("global.email") } variant="outlined" />
                        </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container direction="column" spacing={4} style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item><Typography variant={"h6"}>{t("menu.profile.change_pwd")}</Typography></Grid>
                        <Grid item>
                            <TextField className={classes.inputBorder} autoComplete={"new-password"} fullWidth value={password} type={"password"} onChange={(e) => setPassword(e.target.value)} label={ t("home.new_pwd") } variant="outlined" />
                        </Grid>
                        <Grid item>
                            <TextField className={classes.inputBorder} onBlur={checkPassword} error={passwordError} fullWidth value={confirmPassword} type={"password"} onChange={(e) => setConfirmPassword(e.target.value)} label={ t("home.confirm_new_pwd") } variant="outlined" />
                        </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container direction="column" spacing={4} style={{ marginTop: 10 }}>
                        <Grid item><Typography variant={"h6"}>{t("global.preferences")}</Typography></Grid>
                        <Grid item>
                            <Autocomplete
                                options={activated_locales}
                                value={language}
                                disableClearable
                                onChange={(event, selectedOptions) => {
                                    i18n.changeLanguage(selectedOptions.language_code);
                                    moment.locale(selectedOptions.language_code);
                                    localStorage.setItem("i18nextLng", selectedOptions.language_code);
                                    dispatch({ type: 'USER_SET_MAIN_LOCALE', payload: selectedOptions });
                                    setLanguage(selectedOptions);
                                }}
                                getOptionLabel={(option) => getLabel(option)}
                                getOptionSelected={(option, value) => option.language_code === value.language_code}
                                renderOption={(option, { selected }) => (
                                    <Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            color={'primary'}
                                        />
                                        {getLabel(option)}
                                    </Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        className={classes.inputBorder}
                                        label={t("menu.profile.language_interface")}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password' //disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={!downDesktop ? classes.footer : classes.footerMobile}>
                    <Grid container direction="row" justify={"flex-end"} spacing={2} style={{ width: "100%" }}>
                        <Grid item>
                            <Button onClick={reset}>{t("accommodation.cancel")}</Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={saveInfo} className={classes.orangeButton} variant="contained">{t("header.save")}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ChangePicture setAvatar={setAvatar} setAvatarId={setAvatarId} setOpenModal={setOpenModal} openModal={openModal} avatar={avatar}/>
        </Container>
    );
};
export default React.memo(MaterialProfile);
