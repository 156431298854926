//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import SummaryTransferCard from "./RenderProducts/SummaryTransferCard";
import SummaryTransferCardManual from "./RenderProducts/SummaryTransferCardManual";
import SummaryCarCard from "./RenderProducts/SummaryCarCard";
import SummaryCarCardManual from "./RenderProducts/SummaryCarCardManual";
import FlightSummary from "./RenderProducts/FlightSummary";
import FlightSummaryManual from "./RenderProducts/FlightSummaryManual";
import SummaryPoiCard from './RenderProducts/SummaryPoiCard';
import SummaryPoiCardManual from './RenderProducts/SummaryPoiCardManual';
import SummaryHotelCard from './RenderProducts/SummaryHotelCard';
import SummaryHotelCardManual from './RenderProducts/SummaryHotelCardManual';
import SummaryManualCard from './RenderProducts/SummaryManualCard';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    bold: {
        fontWeight: "bold"
    }
}));

const RenderOutOfCartProducts = ({from}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const out_of_cart = useSelector(store => store.summary.out_of_cart);

    console.log('out_of_cart:', out_of_cart);

    return (
        <Grid item>
            <Typography className={`${classes.genericText} ${classes.bold}`} style={{marginBottom: 10}} variant={"h4"}>{t("summary.other_products")}</Typography>
            {
                out_of_cart.flights.length > 0 && (
                    <Grid item container direction={"column"} spacing={4} xs style={{width: "100%"}}>
                        {
                            out_of_cart.flights.map((flight, flight_index) => {
                                return (
                                    <Grid item key={flight_index} style={{marginTop: 10, marginBottom: 10}}>
                                    {
                                        !flight.is_manual && (
                                            <Fragment>
                                            {
                                                flight.outbounds.map((outbound, outbound_index) => {
                                                    return (
                                                        <FlightSummary key={outbound_index} from={from} type={"main"} outbound={outbound} index_outbound={outbound_index} flight={flight} position={"step"} otherProduct={true}/>
                                                    )
                                                })
                                            }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        flight.is_manual && (
                                            <Fragment>
                                            {
                                                flight.flight_segment.map((outbound, outbound_index) => {
                                                    return (
                                                        <FlightSummaryManual key={outbound_index} from={from} type={"main"} outbound={outbound} index_outbound={outbound_index} flight={flight}  position={"step"} otherProduct={true}/>
                                                    )
                                                })
                                            }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        from === "tripSummary" && (
                                            <Divider/>
                                        )
                                    }
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            }
            {
                out_of_cart.hotels.length > 0 && (
                    <Grid item container direction={"column"} spacing={4} xs style={{width: "100%"}}>
                        {
                            out_of_cart.hotels.map((hotel, hotel_index) => {
                                return (
                                    <Grid item key={hotel_index} style={{marginTop: 10, marginBottom: 10}}>
                                    {
                                        !hotel.is_manual && (
                                            <SummaryHotelCard hotel={hotel} type={"main"} from={from} otherProduct={true}/>
                                        )
                                    }
                                    {
                                        hotel.is_manual && (
                                            <SummaryHotelCardManual hotel={hotel} type={"main"} from={from} otherProduct={true}/>
                                        )
                                    }
                                    {
                                        from === "tripSummary" && (
                                            <Divider/>
                                        )
                                    }
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            }
            {
                out_of_cart.poi.length > 0 && (
                    <Grid item container direction={"column"} spacing={4} xs style={{width: "100%"}}>
                        {
                            out_of_cart.poi.map((poi, poi_index) => {
                                return (
                                    <Grid item key={poi_index} style={{marginTop: 10, marginBottom: 10}}>
                                    {
                                        !poi.is_manual && (
                                            <SummaryPoiCard poi={poi} type={"main"} from={from} otherProduct={true}/>
                                        )
                                    }
                                    {
                                        poi.is_manual && (
                                            <SummaryPoiCardManual poi={poi} type={"main"} from={from} otherProduct={true}/>
                                        )
                                    }
                                    {
                                        from === "tripSummary" && (
                                            <Divider/>
                                        )
                                    }
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            }
            {
                out_of_cart.cars.length > 0 && (
                    <Grid item container direction={"column"} spacing={4} xs style={{width: "100%"}}>
                        {/* <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h6"}>{t("providers.rental_car")}</Typography> */}
                        {
                            out_of_cart.cars.map((car, car_index) => {
                                return (
                                    <Grid item key={car_index} style={{marginTop: 10, marginBottom: 10}}>
                                    {
                                        !car.is_manual && (
                                            <SummaryCarCard car={car} type={"main"} from={from} otherProduct={true}/>
                                        )
                                    }
                                    {
                                        car.is_manual && (
                                            <SummaryCarCardManual car={car} type={"main"} from={from} otherProduct={true}/>
                                        )
                                    }
                                    {
                                        from === "tripSummary" && (
                                            <Divider/>
                                        )
                                    }
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            }
            {
                out_of_cart.transfers.length > 0 && (
                    <Grid item container direction={"column"} spacing={4} xs style={{width: "100%"}}>
                        {/* <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h6"}>{t("providers.transfers")}</Typography> */}
                        {
                            out_of_cart.transfers.map((transfer, transfer_index) => {
                                return (
                                    <Grid item key={transfer_index} style={{marginTop: 10, marginBottom: 10}}>
                                    {
                                        !transfer.is_manual && (
                                            <SummaryTransferCard transfer={transfer} type={"main"} from={from} otherProduct={true}/>
                                        )
                                    }
                                    {
                                        transfer.is_manual && (
                                            <SummaryTransferCardManual transfer={transfer} type={"main"} from={from} otherProduct={true}/>
                                        )
                                    }
                                    {
                                        from === "tripSummary" && (
                                            <Divider/>
                                        )
                                    }
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            }
            {
                out_of_cart.manuals.length > 0 && (
                    <Grid item container direction={"column"} spacing={4} xs style={{width: "100%"}}>
                        {/* <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h6"}>{t("providers.transfers")}</Typography> */}
                        {
                            out_of_cart.manuals.map((manual, manual_index) => {
                                return (
                                    <Grid item key={manual_index} style={{marginTop: 10, marginBottom: 10}}>
                                    {
                                        manual.is_manual && (
                                            <SummaryManualCard manual={manual} type={"main"} from={from} otherProduct={true}/>
                                        )
                                    }
                                    {
                                        from === "tripSummary" && (
                                            <Divider/>
                                        )
                                    }
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            }
        </Grid>
    )
}
export default React.memo(RenderOutOfCartProducts);