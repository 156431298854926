/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import Popover from '@mui/material/Popover';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';

import '../../crm.css';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAllTravelers } from "../../Functions/UserFunctions";
import moment from "moment";

const CrmUserDetailCompanionField = (props) => {
    const { t } = useTranslation();

    const user_client = useSelector(store => store.user.user);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [newValue, setNewValue] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [travelers, setTravelers] = React.useState([]);
    const [companions, setCompanions] = React.useState([]);

    useEffect(() => {
        if (user_client) {
            getAllTravelers(user_client.client, 1, 30, null, (res) => {
                setTravelers(res?.data?.results);
            });
        }
        if (props.value) {
            console.log('COMPANION', props.value);
            setCompanions(props.value);
        }
    }, [user_client, props.value]);

    useEffect(() => {
        setNewValue(props.value);
    }, [props]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeTags = (value) => {
        setNewValue(value);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const executeCallback = () => {
        if (props.callback !== null && props.callback !== undefined) {
            props.callback({ newValue: newValue?.map(e => e.id), onFinish: () => { setAnchorEl(null) } });
        }
    };

    useEffect(() => {
        setOpen(Boolean(anchorEl));
    }, [anchorEl]);

    //search travelers
    const handleInputChange = async (event, value) => {
        getAllTravelers(user_client.client, 1, 30, value, (res) => {
            setTravelers(res?.data?.results);
        });
    };

    const getRelationship = (type) => {
        let relationship = '';
        switch (type) {
            case "FRIEND":
                relationship = t('crm.relationship.friend');
                break;
            case "COUPLE":
                relationship = t('crm.relationship.couple');
                break;
            case "ALONE":
                relationship = t('crm.relationship.alone');
                break;
            default:
                relationship = t('crm.relationship.family');
                break;
        }
        return relationship;
    };

    const getAddress = (companion) => {
        if (companion) {
            return (<p>{companion.address} {companion.address_detail}{
                (companion.address || companion.address_detail) ? ',' : ''
            } {companion.postal_code} {companion.city} {
                (companion.postal_code || companion.city) ? ',' : ''
            } {companion.country?.name}</p>);
        }
    };

    return (
        <div className="contact-field-item">
            <div>
                <div className="companion-list">
                    {
                        companions && companions.length > 0 && companions.map(companion => {
                            return (
                                <div>
                                    <p><b>{companion.first_name} {companion.last_name}</b></p>
                                    <p><i>{getRelationship(companion.traveler_relationship)}</i></p>
                                    <p>{t('crm.born_on')} {moment(new Date(companion.birth_date)).format("DD/MM/YYYY")}</p>
                                    <p>{getAddress()}</p>
                                    <p>{companion.phone_number}</p>
                                    <p>{companion.email}</p>
                                </div>
                            );
                        })
                    }
                </div>
                <div>
                    {
                        !props.readonly &&
                        <>
                            <EditIcon onClick={handleClick} />
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }}
                            >
                                <div className="contact-field-edit">
                                    <Autocomplete
                                        multiple
                                        label={props.label}
                                        options={travelers}
                                        getOptionLabel={(option) => `${option.first_name ? option.first_name : ''} ${option.last_name ? option.last_name : ''}`}
                                        defaultValue={props.value}
                                        filterSelectedOptions
                                        onChange={(e, v) => handleChangeTags(v)}
                                        onInputChange={handleInputChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={props.label}
                                            />
                                        )}
                                    />
                                    <div>
                                        <Button variant="contained" onClick={executeCallback}>{t("crm.save")}</Button>
                                        <Button variant="outlined" onClick={handleClose}>{t("crm.cancel")}</Button>
                                    </div>
                                </div>
                            </Popover>
                        </>
                    }
                    {props.additional_action !== undefined && <>{props.additional_action.icon}</>}
                </div>
            </div>
        </div>
    );
};
export default React.memo(CrmUserDetailCompanionField);
