import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CloseIcon from '@material-ui/icons/Close';

import Transition from "../../../Common/Transition";

import ApplySort from "../../Functions/ApplySort";

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    verticalSpacer: {
        padding: "8px 0px"
    },
    horizontalSpacer: {
        padding: "0px 16px"
    },
    sliderSpacer:{
        padding: "8px 50px"
    },
    filterButton: {
        border: "1.03872px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: 31.1617,
        padding: "8px 12px"
    },
    closeMenu: {
        marginRight: 5
    }
}))

const SortButton = (({ searched_group }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const flight_list_render = useSelector(store => store.flight_search.flight_list_render);
    const sort_value = useSelector(store => store.flight_search.sort_value);

    const [openSort, setOpenSort] = useState(null);

    const handleSort = (sort_case) => {
        ApplySort(sort_case, flight_list_render, dispatch);
        setOpenSort(null);
    }

    return(
        <Grid>
            <Button size="small" className={classes.filterButton} onClick={(event) => setOpenSort(event.currentTarget)}>
                <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`} style={{ paddingLeft: 8, textTransform: "capitalize" }}>
                    { t("flight_search.sort") }
                </div>
                { !openSort ? <ArrowDropDownIcon className={classes.genericText} /> : <ArrowDropUpIcon className={classes.genericText} />}
            </Button>
            {
                isHandHeld && (
                    <Dialog open={Boolean(openSort)} fullScreen TransitionComponent={Transition}>
                        <DialogTitle>
                            <Grid container alignItems="center" justify="flex-end">
                                <Grid item xs={4} />
                                <Grid item xs={4} className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                    { t("menu.sort_by") }
                                </Grid>
                                <Grid item xs={4} className={classes.textRight}>
                                    <IconButton size="small" className={classes.closeMenu} onClick={() => setOpenSort(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ padding: "0px 18px 0px 8px" }}>
                            <FormGroup className={classes.horizontalSpacer}>
                                <FormControlLabel 
                                    control={<Checkbox checked={sort_value === 1} onChange={() => handleSort(1)} name="ascending_price" size="small" />}
                                    label={ t("global.price") + " " + t("global.ascending") }
                                    classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                />
                                <FormControlLabel 
                                    control={<Checkbox checked={sort_value === 2} onChange={() => handleSort(2)} name="descending_price" size="small" />}
                                    label={ t("global.price") + " " + t("global.descending") }
                                    classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                />
                            </FormGroup>
                            <FormGroup className={classes.horizontalSpacer}>
                                <FormControlLabel 
                                    control={<Checkbox checked={sort_value === 5} onChange={() => handleSort(5)} name="ascending_duration" size="small" />}
                                    label={ t("filters.flight_duration") + " " + t("global.duration_asc") }
                                    classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                />
                                <FormControlLabel 
                                    control={<Checkbox checked={sort_value === 6} onChange={() => handleSort(6)} name="descending_duration" size="small" />}
                                    label={ t("filters.flight_duration") + " " + t("global.duration_desc") }
                                    classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                />
                            </FormGroup>
                            {
                                quotation_code === "verdie" && (
                                    <FormGroup className={classes.horizontalSpacer}>
                                        <FormControlLabel 
                                            control={<Checkbox checked={sort_value === 11} onChange={() => handleSort(11)} name="ascending_duration_outbound" size="small" />}
                                            label={ t("flight_search.flight_duration_outbound") + " " + t("global.duration_asc") }
                                            classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                        />
                                        <FormControlLabel 
                                            control={<Checkbox checked={sort_value === 12} onChange={() => handleSort(12)} name="descending_duration_outbound" size="small" />}
                                            label={ t("flight_search.flight_duration_outbound") + " " + t("global.duration_desc") }
                                            classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                        />
                                        <FormControlLabel 
                                            control={<Checkbox checked={sort_value === 13} onChange={() => handleSort(13)} name="ascending_duration_return" size="small" />}
                                            label={ t("flight_search.flight_duration_return") + " " + t("global.duration_asc") }
                                            classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                        />
                                        <FormControlLabel 
                                            control={<Checkbox checked={sort_value === 14} onChange={() => handleSort(14)} name="descending_duration_return" size="small" />}
                                            label={ t("flight_search.flight_duration_return") + " " + t("global.duration_desc") }
                                            classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                        />
                                    </FormGroup>
                                )
                            }
                            {
                                // searched_group.journey_type === 1 && JSON.parse(localStorage.getItem("config")).quotation_code !== "verdie" && (
                                //     <FormGroup className={classes.horizontalSpacer}>
                                //         <FormControlLabel 
                                //             control={<Checkbox checked={sort_value === 15} onChange={() => handleSort(15)} name="ascending_departure_time_outbound" size="small" />}
                                //             label={ t("flight_search.departure_time_outbound") + " " + t("global.hours_asc") }
                                //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                //         />
                                //         <FormControlLabel 
                                //             control={<Checkbox checked={sort_value === 16} onChange={() => handleSort(16)} name="descending_departure_time_outbound" size="small" />}
                                //             label={ t("flight_search.departure_time_outbound") + " " + t("global.hours_desc") }
                                //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                //         />
                                //         <FormControlLabel 
                                //             control={<Checkbox checked={sort_value === 17} onChange={() => handleSort(17)} name="ascending_departure_time_return" size="small" />}
                                //             label={ t("flight_search.departure_time_return") + " " + t("global.hours_asc") }
                                //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                //         />
                                //         <FormControlLabel 
                                //             control={<Checkbox checked={sort_value === 18} onChange={() => handleSort(18)} name="descending_departure_time_return" size="small" />}
                                //             label={ t("flight_search.departure_time_return") + " " + t("global.hours_desc") }
                                //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                //         />
                                //         <FormControlLabel 
                                //             control={<Checkbox checked={sort_value === 19} onChange={() => handleSort(19)} name="ascending_arrival_time_outbound" size="small" />}
                                //             label={ t("flight_search.arrival_time_outbound") + " " + t("global.hours_asc") }
                                //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                //         />
                                //         <FormControlLabel 
                                //             control={<Checkbox checked={sort_value === 20} onChange={() => handleSort(20)} name="descending_arrival_time_outbound" size="small" />}
                                //             label={ t("flight_search.arrival_time_outbound") + " " + t("global.hours_desc") }
                                //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                //         />
                                //         <FormControlLabel 
                                //             control={<Checkbox checked={sort_value === 21} onChange={() => handleSort(21)} name="ascending_arrival_time_return" size="small" />}
                                //             label={ t("flight_search.arrival_time_return") + " " + t("global.hours_asc") }
                                //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                //         />
                                //         <FormControlLabel 
                                //             control={<Checkbox checked={sort_value === 22} onChange={() => handleSort(22)} name="descending_arrival_time_return" size="small" />}
                                //             label={ t("flight_search.arrival_time_return") + " " + t("global.hours_desc") }
                                //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                //         />
                                //     </FormGroup>
                                // )
                            }
                        </DialogContent>
                    </Dialog>
                )
            }
            {
                !isHandHeld && (
                    <Menu 
                        id="sort-menu"
                        anchorEl={openSort}
                        keepMounted
                        open={Boolean(openSort)}
                        onClose={() => setOpenSort(false)}
                        disableScrollLock={true}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        >
                        <Grid container alignItems="center" justify="flex-end">
                            <Grid item xs={4} />
                            <Grid item xs={4} className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                { t("menu.sort_by") }
                            </Grid>
                            <Grid item xs={4} className={classes.textRight}>
                                <IconButton size="small" className={classes.closeMenu} onClick={() => setOpenSort(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider />
                        <FormGroup className={classes.horizontalSpacer}>
                            <FormControlLabel 
                                control={<Checkbox checked={sort_value === 1} onChange={() => handleSort(1)} name="ascending_price" size="small" />}
                                label={ t("global.price") + " " + t("global.ascending") }
                                classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                            />
                            <FormControlLabel 
                                control={<Checkbox checked={sort_value === 2} onChange={() => handleSort(2)} name="descending_price" size="small" />}
                                label={ t("global.price") + " " + t("global.descending") }
                                classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                            />
                        </FormGroup>
                        <FormGroup className={classes.horizontalSpacer}>
                            <FormControlLabel 
                                control={<Checkbox checked={sort_value === 5} onChange={() => handleSort(5)} name="ascending_duration" size="small" />}
                                label={ t("filters.flight_duration") + " " + t("global.duration_asc") }
                                classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                            />
                            <FormControlLabel 
                                control={<Checkbox checked={sort_value === 6} onChange={() => handleSort(6)} name="descending_duration" size="small" />}
                                label={ t("filters.flight_duration") + " " + t("global.duration_desc") }
                                classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                            />
                        </FormGroup>
                        {
                            (searched_group.journey_type === 1 || searched_group.journey.length === 2) && quotation_code === "verdie" && (
                                <FormGroup className={classes.horizontalSpacer}>
                                    <FormControlLabel 
                                        control={<Checkbox checked={sort_value === 11} onChange={() => handleSort(11)} name="ascending_duration_outbound" size="small" />}
                                        label={ t("flight_search.flight_duration_outbound") + " " + t("global.duration_asc") }
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                    <FormControlLabel 
                                        control={<Checkbox checked={sort_value === 12} onChange={() => handleSort(12)} name="descending_duration_outbound" size="small" />}
                                        label={ t("flight_search.flight_duration_outbound") + " " + t("global.duration_desc") }
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                    <FormControlLabel 
                                        control={<Checkbox checked={sort_value === 13} onChange={() => handleSort(13)} name="ascending_duration_return" size="small" />}
                                        label={ t("flight_search.flight_duration_return") + " " + t("global.duration_asc") }
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                    <FormControlLabel 
                                        control={<Checkbox checked={sort_value === 14} onChange={() => handleSort(14)} name="descending_duration_return" size="small" />}
                                        label={ t("flight_search.flight_duration_return") + " " + t("global.duration_desc") }
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                </FormGroup>
                            )
                        }
                        {
                            // searched_group.journey_type === 1 && JSON.parse(localStorage.getItem("config")).quotation_code !== "verdie" && (
                            //     <FormGroup className={classes.horizontalSpacer}>
                            //         <FormControlLabel 
                            //             control={<Checkbox checked={sort_value === 15} onChange={() => handleSort(15)} name="ascending_departure_time_outbound" size="small" />}
                            //             label={ t("flight_search.departure_time_outbound") + " " + t("global.hours_asc") }
                            //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                            //         />
                            //         <FormControlLabel 
                            //             control={<Checkbox checked={sort_value === 16} onChange={() => handleSort(16)} name="descending_departure_time_outbound" size="small" />}
                            //             label={ t("flight_search.departure_time_outbound") + " " + t("global.hours_desc") }
                            //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                            //         />
                            //         <FormControlLabel 
                            //             control={<Checkbox checked={sort_value === 17} onChange={() => handleSort(17)} name="ascending_departure_time_return" size="small" />}
                            //             label={ t("flight_search.departure_time_return") + " " + t("global.hours_asc") }
                            //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                            //         />
                            //         <FormControlLabel 
                            //             control={<Checkbox checked={sort_value === 18} onChange={() => handleSort(18)} name="descending_departure_time_return" size="small" />}
                            //             label={ t("flight_search.departure_time_return") + " " + t("global.hours_desc") }
                            //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                            //         />
                            //         <FormControlLabel 
                            //             control={<Checkbox checked={sort_value === 19} onChange={() => handleSort(19)} name="ascending_arrival_time_outbound" size="small" />}
                            //             label={ t("flight_search.arrival_time_outbound") + " " + t("global.hours_asc") }
                            //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                            //         />
                            //         <FormControlLabel 
                            //             control={<Checkbox checked={sort_value === 20} onChange={() => handleSort(20)} name="descending_arrival_time_outbound" size="small" />}
                            //             label={ t("flight_search.arrival_time_outbound") + " " + t("global.hours_desc") }
                            //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                            //         />
                            //         <FormControlLabel 
                            //             control={<Checkbox checked={sort_value === 21} onChange={() => handleSort(21)} name="ascending_arrival_time_return" size="small" />}
                            //             label={ t("flight_search.arrival_time_return") + " " + t("global.hours_asc") }
                            //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                            //         />
                            //         <FormControlLabel 
                            //             control={<Checkbox checked={sort_value === 22} onChange={() => handleSort(22)} name="descending_arrival_time_return" size="small" />}
                            //             label={ t("flight_search.arrival_time_return") + " " + t("global.hours_desc") }
                            //             classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                            //         />
                            //     </FormGroup>
                            // )
                        }
                    </Menu>
                )
            }
        </Grid>
    )
})

export default React.memo(SortButton);