import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { cloneDeep, findLastIndex, isNumber } from "lodash";
import GetCookie from "../../Common/Functions/GetCookie";
import { getQuickDestination } from "../utils/getQuickDestination";
import { findDestinationChildren } from "../utils/findDestinationChildren";
import { filterDestinationsBasedOnZoom } from "../utils/filterDestinationsBasedOnZoom";
import { areStepsEqual } from "../utils/areStepsEqual";
import { createStepFrom } from "../utils/createStepFrom";
import { StepsDirectionsManager } from "../utils/stepsDirectionsManager";
import { StepsDatesManager } from "../utils/stepsDatesManager";
import { useFixedDateBlockCheck } from "./fixedDateBlockCheck";
import {
    addStepInputFromDestinationUsingIndex,
    markIndexAsCalculatingTransport,
    setDestinations
} from "../redux/reducer";
import { LightDestination } from "../objects/lightDestination";
import { AppState } from "../../../Reducers/Reducers";

type Options = {
    index?: number
}

type Callback = (
    destination: LightDestination,
    index?: number,
) => Promise<void>

export function useItineraryStepAdd(options: Options): Callback {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const map = useSelector((state: AppState) => state.itinerarySlice.map);
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const stepsInputs = useSelector((state: AppState) => {
        if (options.index) {
            return null;
        }
        return state.itinerarySlice.stepsInputs;
    }) ?? [];
    const remainingNights = useSelector((state: AppState) => {
        if (state.trip.start_date && state.trip.end_date) {
            const datesManager = new StepsDatesManager(
                state.trip.start_date,
                state.trip.end_date
            );
            return datesManager.countTripNights(
                state.itinerarySlice.stepsInputs
            ) - datesManager.countTotalNights(
                state.itinerarySlice.stepsInputs
            );
        }
        return 0;
    });
    const aboveStep = useSelector(
        (state: AppState) => {
            const realIndex = isNumber(options.index) ? options.index : -1;
            return state.itinerarySlice.stepsInputs[realIndex];
        },
        (a, b) => {
            if (a === b) {
                return true;
            }

            if (a && b) {
                return areStepsEqual(a, b, true);
            }

            return false;
        }
    );
    const belowStep = useSelector(
        (state: AppState) => {
            if (isNumber(options.index)) {
                const realIndex = isNumber(options.index) ? options.index : -1;
                return state.itinerarySlice.stepsInputs[realIndex + 1];
            }
        },
        (a, b) => {
            if (a === b) {
                return true;
            }

            if (a && b) {
                return areStepsEqual(a, b, true);
            }

            return false;
        }
    );
    const isUserTO = useSelector((state: AppState) => state.user.user?.client_full?.type !== 2);
    const checkForFixedDateBlock = useFixedDateBlockCheck();

    return async (destination, index) => {
        if (map) {
            //just get children destinations if destination is not a city
            if (destination.type_dest !== 4) {
                const children = await findDestinationChildren(destination.id, isUserTO);

                //if there is only one child then just center on it
                if (children.length === 1) {
                    const child = children[0];
                    const data = child?.id ? await getQuickDestination(child.id) : null;
                    if (data && child) {
                        map.set('noNotify', true);
                        map.setCenter({ lat: child.latitude, lng: child.longitude });
                        map.setZoom(data.zoom_level);
                    }
                    //else if there are multiple children, create a bounds and make map fit it
                } else if (children.length > 0) {
                    const bounds = new google.maps.LatLngBounds();
                    children.forEach((item) => {
                        bounds.extend({ lat: item.latitude, lng: item.longitude });
                    });
                    dispatch(
                        setDestinations({
                            state: 'success',
                            data: filterDestinationsBasedOnZoom(children, map.getZoom() ?? 8)
                        })
                    );
                    map.set('noNotify', true);
                    map.fitBounds(bounds);
                } else {
                    const data = await getQuickDestination(destination.id);
                    const position = { lat: parseFloat(data?.latitude ?? '0'), lng: parseFloat(data?.longitude ?? '0') };
                    if (position && data) {
                        map.set('noNotify', true);
                        map.setCenter(position);
                        map.setZoom(data.zoom_level);
                    }
                }
                //else, we can add it to the itinerary
            } else if (tripId && tripStartDate && tripEndDate) {
                const manager = StepsDirectionsManager.getInstance();
                const datesManager = new StepsDatesManager(
                    tripStartDate,
                    tripEndDate
                );
                const data = await getQuickDestination(destination.id);
                const toBeUsedIndex = options.index ? options.index : index;
                let realIndex = isNumber(toBeUsedIndex) ?
                    toBeUsedIndex :
                    findLastIndex(
                        stepsInputs,
                        (step) => {
                            return ['START', 'STEP'].includes(step.step_type);
                        }
                    );

                let aboveStepClone = !isNumber(options.index) ?
                    cloneDeep(stepsInputs[realIndex]) :
                    cloneDeep(aboveStep);
                let belowStepClone = !isNumber(options.index) ?
                    cloneDeep(stepsInputs[realIndex + 1]) :
                    cloneDeep(belowStep);
                let step = createStepFrom({
                    destination,
                    tripId,
                    version: parseInt(GetCookie("trip_id_version") ?? '-1'),
                    step: aboveStep ?? null,
                    tripStartDate,
                    tripEndDate,
                    daysCount: 1
                });
                dispatch(
                    markIndexAsCalculatingTransport({
                        index: realIndex,
                        isCalculating: true
                    })
                );
                [aboveStepClone, step] = await manager.recomputeTransportsBetween(
                    aboveStepClone ?? step,
                    step
                );
                [aboveStepClone, step] = datesManager.recomputeDates(aboveStepClone, step);
                [step, belowStepClone] = await manager.recomputeTransportsBetween(
                    step,
                    belowStepClone ?? step
                );
                [step, belowStepClone] = datesManager.recomputeDates(step, belowStepClone);

                if (
                    checkForFixedDateBlock({
                        aboveStep: aboveStepClone,
                        step,
                        belowStep: belowStepClone
                    }) === false
                ) {
                    dispatch(
                        markIndexAsCalculatingTransport({
                            index: realIndex,
                            isCalculating: false
                        })
                    );
                    enqueueSnackbar(
                        t('itinerary.bloc-fixed-date-prepend-warning'),
                        { variant: 'error' }
                    );
                    return;
                }

                const transportsNightsCount = window.moment.utc(
                    belowStepClone.start_date
                ).startOf(
                    'day'
                ).diff(
                    window.moment.utc(aboveStepClone.end_date).startOf('day'),
                    'day'
                ) + window.moment.utc(
                    step.end_date
                ).startOf(
                    'day'
                ).diff(
                    window.moment.utc(belowStepClone.start_date).startOf('day'),
                    'days'
                );

                const stepNightsCount = Math.ceil(destination.suggested_hours / 24);
                let daysCount = remainingNights - transportsNightsCount >= stepNightsCount ?
                    stepNightsCount :
                    remainingNights - transportsNightsCount;
                daysCount = daysCount <= 0 ? 1 : daysCount;

                dispatch(
                    markIndexAsCalculatingTransport({
                        index: realIndex,
                        isCalculating: false
                    })
                );

                dispatch(
                    addStepInputFromDestinationUsingIndex({
                        index: realIndex,
                        destination,
                        tripId,
                        tripStartDate,
                        tripEndDate,
                        version: parseInt(GetCookie("trip_id_version") ?? '-1'),
                        daysCount
                    })
                );

                map.panTo({ lat: destination.latitude, lng: destination.longitude });
                if (data) {
                    map.setZoom(data.zoom_level);
                }
            }
        }
    };
}
