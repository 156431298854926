//---- Dependencies ----//
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router';

//---- Component ----//
import CarsFilters from './CarsFilters.jsx';
import AgenciesModal from './AgenciesModal.jsx';
import GenerateCars from './GenerateCars.jsx';

//---- Actions ----//
import { ToggleAgenciesModal } from '../../../Actions/CarsSearch';

//---- CSS ----//
import '../../../Style/FlightGroups.css';
import '../../../Style/FlightSearch.css';
import '../../../Style/CarsSearch.css';

@connect((store) => {
    return {
    	display_cars: store.cars_search.display_cars
    }
})
@withTranslation()
@withRouter
export default class RenderCardCars extends React.Component {
	componentWillMount() {
		const {dispatch} = this.props;
		dispatch(ToggleAgenciesModal(false));
	}
	componentDidMount() {
		ReactDOM.findDOMNode(this.refs.cars_results).scrollIntoView(true ,{behavior: 'smooth'});
	}
	componentDidUpdate(prevProps, prevState) {
		const { display_cars } = this.props;
		if (prevProps.display_cars !== display_cars) {
			ReactDOM.findDOMNode(this.refs.cars_results).scrollIntoView(true, {behavior: 'smooth'});
		}
	}
	render() {
		const { display_cars, t } = this.props;
		return (
			<div className={ 'ft-mt108' } ref={ 'cars_results' }>
				<div className={ 'cars-results-header bold text-center ft-basic-card ft-mb30 ft-font-35' }>{display_cars.length + ( display_cars.length === 1 ? t('cars.car_found') : t('cars.cars_found'))}</div>
				<div className={ 'cars-results-header ft-mb30' }>
					<div className={ 'row ft-no-margin ' }>
						<div className={ 'col-md-3 cars-filter-container' }>
							<CarsFilters/>
						</div>
						<div className={ 'col-md-9 no-padding' }>
							<GenerateCars display_cars={ display_cars }/>
						</div>
					</div>
				</div>
				<AgenciesModal />
			</div>
		);
	}
}