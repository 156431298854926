/*eslint-disable max-len */
import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export function ItineraryCartTreeViewIcon(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon width="10" height="10" viewBox="0 0 10 10" fill="none" {...props}>
            <path d="M7.2723 5.49796C7.64716 5.49796 7.97704 5.29303 8.14697 4.98315L9.93631 1.73935C10.1212 1.40948 9.88133 0.999629 9.50147 0.999629H2.10422L1.63439 0H0V0.999629H0.999629L2.79896 4.79322L2.12421 6.01277C1.75935 6.68252 2.23917 7.49722 2.99889 7.49722H8.99666V6.49759H2.99889L3.54868 5.49796H7.2723ZM2.57904 1.99926H8.65179L7.2723 4.49833H3.7636L2.57904 1.99926ZM2.99889 7.99703C2.44909 7.99703 2.00426 8.44686 2.00426 8.99666C2.00426 9.54645 2.44909 9.99629 2.99889 9.99629C3.54868 9.99629 3.99852 9.54645 3.99852 8.99666C3.99852 8.44686 3.54868 7.99703 2.99889 7.99703ZM7.99703 7.99703C7.44723 7.99703 7.0024 8.44686 7.0024 8.99666C7.0024 9.54645 7.44723 9.99629 7.99703 9.99629C8.54683 9.99629 8.99666 9.54645 8.99666 8.99666C8.99666 8.44686 8.54683 7.99703 7.99703 7.99703Z" fill="black" fillOpacity="0.54"/>
        </SvgIcon>
    );
}
