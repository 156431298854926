import axios from "axios";
import { SetTripMainInfo } from "../../../Actions/Summary";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetAllCart from "./GetAllCart";

const GetTripInfo = (trip_id, trip_token, dispatch, setIsLoading) => {
    const { pass_check, headers } = CheckBeforeRequest();
    console.log('trip_id:', trip_id);
    console.log('trip_token:', trip_token);
    axios({
        method: "GET",
        headers: headers,
        url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/?token=${trip_token}`,
    }).then(function (response) {
        let data = Object.assign({}, response.data);
        console.log('data:', data);
        GetAllCart(trip_id, data.current_version, trip_token, dispatch, setIsLoading);
        dispatch(SetTripMainInfo(data));
    }).catch(function (error) {
        console.log('err:', error);
    })
}
export default GetTripInfo