import React, { Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import DialogContent from "@material-ui/core/DialogContent";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
//TODO: traduction
const AccommodationCancellationInfo = ({start_date}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const open = useSelector(state => state.accommodation.open_cancellation_info);
    const info = useSelector(state => state.accommodation.cancellation_info);
    const language = useSelector(state => state.header.tmp_language);
    const cancellation_currency = useSelector(state => state.accommodation.cancellation_currency);
    const user = useSelector(state => state.user.user);
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const onClose = () => {
        dispatch({type: 'MATERIAL_CLOSE_CANCELLATION'});
    };
    return (
        <Dialog fullScreen={ fullScreen } PaperProps={{style: { borderRadius: fullScreen ? 0 : 16 } }} open={ open } fullWidth maxWidth={ "md" } onClose={ onClose } disableEscapeKeyDown>
            <DialogTitle onClose={ onClose }>
                <Grid container justify={ "space-between" } alignItems={ "center" }>
                    {
                        fullScreen ? (
                            <Grid item>
                                <IconButton edge={ "start" } onClick={ onClose }><NavigateBefore/></IconButton> { t("accommodation.cancellation_condition") }
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item>
                                    { t("accommodation.cancellation_condition") } :
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={ onClose }><Close/></IconButton>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent>
                {
                    (quotation_code === 'visiteurs' && user.client_full.type === 2) || quotation_code === "tropicalementvotre" ? (
                        <Typography>{ t("accommodation.cancellable-visiteurs") }</Typography>
                        ) : info.map((condition, index_condition) => {
                        if (condition.type_charge === "C") {
                            if (condition.details !== null) {
                                return <Typography key={ index_condition }>{ condition.details }</Typography>
                            } else {
                                if ((condition.from_day === null || condition.from_day === -99) && (condition.to_day !== null && condition.to_day !== -99)) {
                                    if (parseFloat(condition.amount) === 0) {
                                        return <Typography key={ index_condition }>{ t("accommodation.free-cancellation-until") } { moment.utc(start_date).subtract(condition.to_day, "d").format("DD/MM/YYYY") }</Typography>
                                    } else {
                                        return <Typography key={ index_condition }>{ t("global.until") } { moment.utc(start_date).subtract(condition.to_day, "d").format("DD/MM/YYYY") } : { new Intl.NumberFormat(language, { style: "currency", currency: cancellation_currency.iso_code }).format(condition.amount) }</Typography>
                                    }
                                } else if (condition.from_day !== null && condition.from_day !== -99 && condition.to_day !== null && condition.to_day !== -99) {
                                    if (parseFloat(condition.amount) === 0) {
                                        return <Typography key={ index_condition }>{ t("accommodation.free-cancellation-until") } { moment.utc(start_date).subtract(condition.to_day, "d").format("DD/MM/YYYY") }</Typography>
                                    } else {
                                        return <Typography key={ index_condition }>{ t("accommodation.from") } { moment.utc(start_date).subtract(condition.from_day, "d").format("DD/MM/YYYY") } { t("accommodation.to") } { moment.utc(start_date).subtract(condition.to_day, "d").format("DD/MM/YYYY") } : { new Intl.NumberFormat(language, { style: "currency", currency: cancellation_currency.iso_code }).format(condition.amount) }</Typography>
                                    }
                                } else if ((condition.from_day !== null && condition.from_day !== -99) && (condition.to_day === null || condition.to_day !== -99)) {
                                    if (parseFloat(condition.amount) === 0) {
                                        return <Typography key={ index_condition }>{ t("shared.free-cancellation") }</Typography>
                                    } else {
                                        return <Typography key={ index_condition }>{ t("shared.from-the") } { moment.utc(start_date).subtract(condition.from_day, "d").format("DD/MM/YYYY") } : { new Intl.NumberFormat(language, { style: "currency", currency: cancellation_currency.iso_code }).format(condition.amount) }</Typography>
                                    }
                                } else {
                                    return <Typography key={ index_condition }>No show : { (parseFloat(condition.amount) === 0 ? t("global.free") : new Intl.NumberFormat(language, { style: "currency", currency: cancellation_currency.iso_code }).format(condition.amount)) }</Typography>
                                }
                            }
                        }
                    })
                }
                <br/>
            </DialogContent>
        </Dialog>
    );
};

export default React.memo(AccommodationCancellationInfo);
