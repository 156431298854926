// Dependencies
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import i18n from '../../../i18n';

const LocaleText = ({localization, default_text, text_key}) => {
    const locales = useSelector(state => state.user.locales);
    const main_locale = locales.find((el) => {return el.language_code === i18n.language});

    let displayed_text = default_text;
    for (let i = 0; i < localization.length; i++) {
        if (localization[i].locale === main_locale) {
            if (!!localization[i][text_key]) {
                displayed_text = localization[i][text_key];
            }
        }
    }
    return <Fragment>{ displayed_text }</Fragment>;
};

export default LocaleText;