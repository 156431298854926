import { ManualProductPoiCart } from "../../../Itinerary/objects/manualProductPoiCart";
import { PoiCart } from "../../../Itinerary/objects/poiCart";

type Item = {
    type: "normal";
    poi: PoiCart;
} | {
    type: "manual";
    poi: ManualProductPoiCart;
}

export function getPoiPlace(item: Item): string | undefined {
    let place: string | undefined;

    if (item.type === 'normal') {
        if (item.poi.custom_product?.place.address && item.poi.custom_product.place.address.length > 0) {
            place = item.poi.custom_product.place.address;
        } else {
            place = item.poi.custom_product?.place.city_name ?? undefined;
        }
    } else {
        place = item.poi.hide_address ? undefined : item.poi.address ?? undefined;
    }

    return place;
}
