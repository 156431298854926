import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";
import { makeStyles } from '@material-ui/core';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';

import { isProductPackaged } from '../CartMaterial/utils/isProductPackaged';
import { useShowError } from '../Utils/showError';
import { useSendProviderBookingDemand } from './network/sendProviderBookingDemand';
import BookItems from './Functions/BookItems';

import { ToggleBookingModal, SetIsBooking, SetCartToBook, ResetBookingLaunched, EmptyError } from '../../Actions/Booking';

const useStyles = makeStyles({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize18: {
        fontSize: 18
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter: {
        textAlign: "center"
    },
    genericPaddingTop: {
        paddingTop: 20
    },
    returnButton: {
        color: "#0000008A",
        backgroundColor: "#FFFFFF"
    },
    genericButton: {
        color: "#FFFFFF",
        backgroundColor: "#3F51B5"
    },
    fullWidth: {
        width: "100%"
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BookingModal = withRouter(({ router }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const smallScreen = useMediaQuery('(max-width:425px)');

    const booking_modal = useSelector(store => store.booking.booking_modal);
    const nb_products_refreshed = useSelector(store => store.booking.nb_products_refreshed);
    const error = useSelector(store => store.booking.error);
    const info = useSelector(state => state.cart.refresh_price);
    const cart_to_book = useSelector(store => store.booking.cart_to_book);
    const booking_launched = useSelector(store => store.booking.booking_launched);
    const to_book = useSelector(store => store.cart.to_book);
    const end_date = useSelector(store => store.trip.end_date);
    const providers = useSelector(store => store.trip.providers);
    const toBeBookedPackages = useSelector((state) => state.cartConstruction.bookablePackages);
    const manual_providers = useSelector(store => store.trip.manual_providers);
    const trip = useSelector(store => store.trip.data_trip);
    const flight_info = useSelector(store => store.booking.flight_info);

    const [notCancellable, setNotCancellable] = useState(null);
    const [notAvailable, setNotAvailable] = useState(null);
    const [allNotAvailable, setAllNotAvailable] = useState(null);
    const [displayWarning, setDisplayWarning] = useState(null);
    const showError = useShowError();
    const sendBookingDemand = useSendProviderBookingDemand({
        onError(error) {
            showError(error);
        }
    });

    useEffect(() => {
        if (booking_modal && (info !== null || nb_products_refreshed === to_book.length)) {
            let temp_not_cancellable = false;
            let temp_not_available = false;
            let nb_not_available = 0;

            to_book.map((product) => {
                //cars always cancellable even if is_cancellable === false
                if (product.is_displayed && product.is_available && (product.booking_status === null || (product.booking_status !== null && product.booking_status.status_booking === "ERROR")) && product.product_type !== 2 && product.is_cancellable === false) {
                    temp_not_cancellable = true;
                }
                if (product.is_displayed && product.is_available === false && (product.booking_status === null || (product.booking_status !== null && product.booking_status.status_booking === "ERROR"))) {
                    temp_not_available = true;
                    nb_not_available += 1;
                }
            });
            if (nb_not_available === to_book.length) {
                setAllNotAvailable(true);
            }

            setNotCancellable(temp_not_cancellable);
            setNotAvailable(temp_not_available);
            setDisplayWarning(temp_not_available || temp_not_cancellable);
        }
    }, [booking_modal, nb_products_refreshed]);

    useEffect(() => {
        if (notCancellable === false && notAvailable === false) {
            BookItems(
                to_book,
                dispatch,
                providers,
                manual_providers,
                end_date,
                t,
                sendBookingDemand,
                (
                    toBeBookedPackages?.findIndex((packageItem) => {
                        return isProductPackaged({
                            product: packageItem,
                            stackInfos: trip?.stack_info ?? null,
                            connected: true
                        });
                    }) ?? -1
                ) >= 0,
                flight_info,
                trip
            );
        }
    }, [notCancellable, notAvailable]);

    useEffect(() => {
        if (booking_launched !== 0 && booking_launched === cart_to_book.length && error.length === 0) {
            returnToCart();
            enqueueSnackbar(t("booking.booking_success"), { variant: "success", disableWindowBlurListener: true, autoHideDuration: 6000 });
        }
    }, [booking_launched, error]);

    const returnToCart = () => {
        dispatch(ToggleBookingModal(false));
        dispatch(SetIsBooking(false));
        dispatch(SetCartToBook([]));
        dispatch(ResetBookingLaunched());
        dispatch(EmptyError());
        dispatch({ type: 'CART_SET_STEP', payload: { step: 1 } });
        dispatch({ type: "CART_SET_TO_BOOK", payload: [] });
        dispatch({ type: 'CART_REFRESH_PRICE', payload: null });
        router.push(`/${window.url_name}/apps/cart`);
    };

    return (
        <Dialog open={booking_modal} fullScreen={smallScreen ? true : false} TransitionComponent={Transition} fullWidth maxWidth={"sm"}>
            <DialogTitle>
                <Grid className={`${classes.genericText} ${smallScreen ? classes.textCenter : ""} ${classes.upperCase} ${classes.fontWeight900} ${!smallScreen ? classes.fontSize18 : classes.fontSize12} ${classes.letterSpacing}`}>
                    { t("booking.modal_title") }
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                {
                    displayWarning && (
                        <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.letterSpacing}`}>
                            {
                                notAvailable && !allNotAvailable && (
                                    <div>
                                        { t("booking.booking_not_available") }
                                    </div>
                                )
                            }
                            {
                                allNotAvailable && (
                                    <div>
                                        { t("booking.booking_all_not_available")}
                                    </div>
                                )
                            }
                            {
                                notCancellable && (
                                    <div>
                                        { t("booking.booking_not_cancellable") }
                                    </div>
                                )
                            }
                            <Grid container direction={smallScreen ? "column" : "row"} alignItems={smallScreen ? "flex-start" : "center"} justify={ allNotAvailable ? "center" : "space-between"} spacing={smallScreen ? 2 : 0} className={classes.genericPaddingTop}>
                                <Grid item className={smallScreen ? `${classes.fullWidth} ${classes.textCenter}` : ""}>
                                    <Button variant="contained" className={classes.returnButton} onClick={returnToCart}>
                                        { t("booking.back_to_cart") }
                                    </Button>
                                </Grid>
                                {
                                    !allNotAvailable && (
                                        <Grid item className={smallScreen ? `${classes.fullWidth} ${classes.textCenter}` : ""}>
                                            <Button variant="contained" className={classes.genericButton} onClick={() => {
                                                BookItems(
                                                    to_book,
                                                    dispatch,
                                                    providers,
                                                    manual_providers,
                                                    end_date,
                                                    t,
                                                    sendBookingDemand,
                                                    (
                                                        toBeBookedPackages?.findIndex((packageItem) => {
                                                            return isProductPackaged({
                                                                product: packageItem,
                                                                stackInfos: trip?.stack_info ?? null,
                                                                connected: true
                                                            });
                                                        }) ?? -1
                                                    ) >= 0,
                                                    flight_info,
                                                    trip
                                                );
                                                setDisplayWarning(false);
                                            }}>
                                                { t("booking.keep_booking") }
                                            </Button>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    )
                }
                {
                    cart_to_book.length !== 0 && cart_to_book.length !== booking_launched && (
                        <Grid className={classes.textCenter}>
                            <CircularProgress />
                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                { t("booking.booking_items") }
                            </Grid>
                        </Grid>
                    )
                }
                {
                    error.map((error_item, error_index) => {
                        return (
                            <Grid key={`error-${error_index}`} container alignItems="center" justify="space-between" spacing={2}>
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12}`}>
                                    { error_item.name + " :" }
                                </Grid>
                                <Grid item className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                    { error_item.error }
                                </Grid>
                            </Grid>
                        );
                    })
                }
                {
                    error.length !== 0 && (
                        <Grid className={`${classes.textCenter} ${classes.genericPaddingTop}`}>
                            <Button variant="contained" className={classes.returnButton} onClick={returnToCart}>
                                { t("booking.back_to_cart") }
                            </Button>
                        </Grid>
                    )
                }
            </DialogContent>
        </Dialog>
    );
});

export default React.memo(BookingModal);
