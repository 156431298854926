//---- Dependencies ----//
import React from 'react';
import { connect } from "react-redux";
import { Link, History } from "react-router";
import { withTranslation } from "react-i18next";

//---- Common ----//
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";

//---- Actions ----//
import { ToggleVideoModal, SetSelectedPage } from "../../../Actions/Menu";

//----- Functions -----//

//---- Components ----//
import VideoModal from "../TripList/VideoModal.jsx";

//---- CSS ----//
import "../../../Style/Menu/TripList.css";

//---- Class ----//
@connect((store) => {
    return {
        trip_info: store.menu.trip_info,
        to_show_version: store.menu.to_show_version
    };
})
@withTranslation()
class TripListDocument extends React.Component {
     constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(SetSelectedPage("help"));
    }
    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(SetSelectedPage(null));
    }

    render() {
        const { t, dispatch } = this.props; 
        return (
            <div id={"trip-list-settings"} className={"wrapper documents travelers"} >
                 <div className={"block-center text-center"} >
                    <h1 className={"first"} > {t("menu.trip_list.formation_video")} </h1>
                </div>
                <div className={"middle-content"} >
                    <h2 className={"help-center-subtitle"}>{ t("menu.trip_list.configuration_agency_interface") } </h2>
                    <div className={"row"} >
                        <div className={"col-md-4 mb20"} >
                            <div className={"container-youtube-video"} onClick={() => {
                                dispatch(ToggleVideoModal(true, "https://www.youtube.com/embed/_UUxvwZjWmw"));
                            }} >
                                <div className={"minia-youtube-help-center"} >
                                    <img className={"ft-cover-img"} src={"/Img/Help/configuration_de_la_plateforme.jpg"} />
                                </div>
                                <div className={"minia-youtube-help-center-text"}>{ t("menu.trip_list.configuration_interface") }</div>
                            </div>
                        </div>
                        <div className={"col-md-4 mb20"} >
                            <div className={"container-youtube-video"} onClick={() => {
                                dispatch(ToggleVideoModal(true, "https://www.youtube.com/embed/uvOWnpahVpk"));
                            }} >
                                <div className={"minia-youtube-help-center"} >
                                    <img className={"ft-cover-img"} src={"/Img/Help/configuration_profile.jpg"} />
                                </div>
                                <div className={"minia-youtube-help-center-text"}>{ t("menu.trip_list.help_profile") }</div>
                            </div>
                        </div>
                        <div className={"col-md-4 mb20"} >
                            <div className={"container-youtube-video"} onClick={() => {
                                dispatch(ToggleVideoModal(true, "https://www.youtube.com/embed/exNZA7NY2g8"));
                            }} >
                                <div className={"minia-youtube-help-center"} >
                                    <img className={"ft-cover-img"} src={"/Img/Help/profile_agence.jpg"} />
                                </div>
                                <div className={"minia-youtube-help-center-text"}>{ t("menu.trip_list.help_agency_profile") }</div>
                            </div>
                        </div>
                        <div className={"col-md-4 mb20"} >
                            <div className={"container-youtube-video"} onClick={() => {
                                dispatch(ToggleVideoModal(true, "https://www.youtube.com/embed/_MvufXbhSe8"));
                            }} >
                                <div className={"minia-youtube-help-center"} >
                                    <img className={"ft-cover-img"} src={"/Img/Help/marges_agence.jpg"} />
                                </div>
                                <div className={"minia-youtube-help-center-text"}>{ t("menu.trip_list.help_agency_margin") }</div>
                            </div>
                        </div>
                        <div className={"col-md-4 mb20"} >
                            <div className={"container-youtube-video"} onClick={() => {
                                dispatch(ToggleVideoModal(true, "https://www.youtube.com/embed/AIRteAnco7M"));
                            }} >
                                <div className={"minia-youtube-help-center"} >
                                    <img className={"ft-cover-img"} src={"/Img/Help/utilisateur_agence.jpg"} />
                                </div>
                                <div className={"minia-youtube-help-center-text"}>{ t("menu.trip_list.help_add_user") }</div>
                            </div>
                        </div>
                        {
                            /*
                                                    <div className={"col-md-4 mb20"} >
                            <div className={"container-youtube-video"} >
                                <div className={"minia-youtube-help-center"} >
                                    <img className={"ft-cover-img"} src={"/Img/Help/pres-ft.png"} />
                                </div>
                                <div className={"minia-youtube-help-center-text"} >Présentation FacilitaTrip</div>
                            </div>
                        </div>
                        <div className={"col-md-4 mb20"} >
                            <div className={"container-youtube-video"} >
                                <div className={"minia-youtube-help-center"} >
                                    <img className={"ft-cover-img"} src={"/Img/Help/pres-ft.png"} />
                                </div>
                                <div className={"minia-youtube-help-center-text"} >Présentation FacilitaTrip</div>
                            </div>
                        </div>
                        <div className={"col-md-4 mb20"} >
                            <div className={"container-youtube-video"} >
                                <div className={"minia-youtube-help-center"} >
                                    <img className={"ft-cover-img"} src={"/Img/Help/pres-ft.png"} />
                                </div>
                                <div className={"minia-youtube-help-center-text"} >Présentation FacilitaTrip</div>
                            </div>
                        </div>
                             */
                        }
                    </div>
                </div>
                <VideoModal />                
            </div>
        );
    }
}

export default TripListDocument;
