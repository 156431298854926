import React from "react";
import { SvgIcon } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export function TripListFolderIcon(props) {
    return (
        <IconWrapper {...props}>
            <g transform="matrix(1.2 0 0 1.29 12.37 12)" id="2RI4aHrhGQmIo1LWASXiC" >
                <g vectorEffect="non-scaling-stroke" >
                    <g transform="matrix(1 0 0 1 0 0.21)" id="sFGVWCfG9Q8ke-u1cxHXP" >
                        <path
                            style={{
                                stroke: 'rgb(74,75,87)',
                                strokeWidth: 2,
                                strokeDasharray: 'none',
                                strokeLinecap: 'round',
                                strokeDashoffset: 0,
                                strokeLinejoin: 'round',
                                strokeMiterlimit: 4,
                                fill: 'none',
                                fillRule: 'nonzero',
                                opacity: 1
                            }}
                            vectorEffect="non-scaling-stroke"
                            transform=" translate(-10, -9.21)"
                            //eslint-disable-next-line max-len
                            d="M 4.00018 1.70843 C 2.34333 1.70842 1.00019 3.05157 1.00019 4.70842 L 1.00022 13.7084 C 1.00023 15.3653 2.34338 16.7084 4.00023 16.7084 L 16.0002 16.7085 C 17.6571 16.7085 19.0002 15.3653 19.0002 13.7085 L 19.0002 7.70848 C 19.0002 6.05162 17.657 4.70847 16.0002 4.70847 L 11.0002 4.70845 C 10.4479 4.70845 10.0165 4.24844 9.83582 3.72655 C 9.42913 2.55191 8.31316 1.70844 7.00018 1.70844 L 4.00018 1.70843 Z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 0.01 1.28)" id="QVA0OKPYGmIV7Uh1dVeEJ" >
                        <path 
                            style={{
                                stroke: 'rgb(74,75,87)',
                                strokeWidth: 2,
                                strokeDasharray: 'none',
                                strokeLinecap: 'round',
                                strokeDashoffset: 0,
                                strokeLinejoin: 'round',
                                strokeMiterlimit: 4,
                                fill: 'none',
                                fillRule: 'nonzero',
                                opacity: 1
                            }}
                            vectorEffect="non-scaling-stroke"
                            transform=" translate(-10.01, -10.28)"
                            d="M 8.00091 10.2818 L 12.0134 10.2836 M 10.0062 8.00091 L 10.008 12.5644"
                            strokeLinecap="round"
                        />
                    </g>
                </g>
            </g>
        </IconWrapper>
    );
}

const IconWrapper = styled(SvgIcon)(() => ({
    padding: 1.5
}));
