export default (data, attr) => {
	let arr = [];
    for (let prop in data) {
        if (data.hasOwnProperty(prop)) {
            let obj = {};
            obj[prop] = data[prop];
            obj.tempSortName = typeof data[prop][attr] === "string" ? data[prop][attr].toLowerCase() : data[prop][attr];
            arr.push(obj);
        }
    }

    arr.sort(function(a, b) {
        let at = a.tempSortName;
        let bt = b.tempSortName;
        return at > bt ? 1 : ( at < bt ? -1 : 0 );
    });

    let result = [];
    for (let i = 0; i < arr.length; i++) {
        let obj = arr[i];
        let id = null;
        delete obj.tempSortName;
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                id = prop;
            }
        }
        let item = obj[id];
        result.push(item);
    }
    return result;
}