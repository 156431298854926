import { Moment } from "moment-timezone";
import { Transport } from "../objects/transport";

export function getPlaneStartDate(
    item: Transport['alternatives'][number],
    endDate: string
): Moment {
    const firstAirStep = item.steps.find((step) => step.vehicle.kind === 'plane');
    const leadingCarSteps: typeof item.steps = [];
            
    for (let i = 0; i < item.steps.length; i++) {
        const step = item.steps[i]!;
        if (step.vehicle.kind === 'plane') {
            break;
        } else {
            leadingCarSteps.push(step);
        }
    }

    const leadingDuration = leadingCarSteps.reduce((prev, current) => {
        return prev + current.duration;
    }, 0);

    const firstAirStepDate = window.moment.utc(
        endDate
    ).startOf(
        'day'
    ).add(
        firstAirStep?.departureTime ?? 0,
        'seconds'
    ).subtract(
        leadingDuration,
        'seconds'
    );

    return firstAirStepDate;
}
