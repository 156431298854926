import React from 'react';

const Link = (props: any) => {
    const { link } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={ link } target={ 'blank' }>
            { props.children }
        </a>
    );
};

export default Link;
