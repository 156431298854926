//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MosaicPicture from '../../Accommodation/FunctionalComponent/MosaicPicture';
import Picture from '../../Accommodation/FunctionalComponent/Picture';
import Slider from '../../Accommodation/FunctionalComponent/Slider';

const useStyles = makeStyles(theme => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    textJustify: {
        textAlign: "justify"
    },
    textCenter: {
        textAlign: "center"
    },
    picture: {
        // width: "100%",
        // height: "100%",
        objectFit: "cover",
        width: 407,
        height: 271,
        margin: "0px 21px 10px 0",
        float: "left"
    },
    mobilePicture: {
        width: "100%",
        margin: "0px 21px 10px 0"
    },
    bold: {
        fontWeight: "bold"
    },
    italic: {
        fontStyle: "italic"
    },
    capitalize: {
        textTransform: "Capitalize"
    },
    hrText: {
        lineHeight: "20px",
        position: "relative",
        outline: "0",
        border: "0",
        textAlign: "center",
        height: 20,
        fontSize: 34,
        '&::before': {
            content: "''",
            background: "#0000008A",
            position: "absolute",
            left: "0",
            top: "50%",
            width: "100%",
            height: "1px"
        },
        '&::after': {
            content: "attr(data-content)",
            position: "relative",
            display: "inline-block",
            color: "#0000008A",
            padding: "0 .5em",
            // lineHeight: "1.5em",
            backgroundColor: "white"
        }
    },
    category: {
        border: "1px solid #0000008A",
        borderRadius: 5,
        padding: "0 15px",
        color: "#0000008A",
        display: "block",
        position: "relative",
        textTransform: "capitalize",
        marginRight: 15,
        marginTop: 5
    }
}));

const RenderDestination = ({step, nb_step, day_by_day, day, from_summary, first_day, last_day, step_name, nb_nights, day_index, guide_list}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));    

    const [openMozaic, setOpenMozaic] = useState(false);
	const [openPicture, setOpenPicture] = useState(false);
	const [current_picture, setCurrentPicture] = useState(0);
    const [showChevron, setShowChevron] = useState(false);
    let description = null;
    let picture = "/Img/default_dest.jpg";
    let moment_end_date = moment.utc(step.end_date);
    let night_start_date = moment.utc(step.start_date);
    let nb_day = 0;
    let inc = 0;

    let destination_pictures = step.destination.data.pictures
    const openModal = () => {
		if (!fullScreen) {
			togglePictureModal(destination_pictures)
		}
		else {
			toggleMosaicModal(destination_pictures);
		}
	}
	const togglePictureModal = (pictures, index_picture = 0) => {
		setOpenPicture(!openPicture);
		setCurrentPicture(index_picture);
	};
	const toggleMosaicModal = (pictures) => {
		setOpenMozaic(!openMozaic);
    };
	const onMouseEnter = () => {
	    setShowChevron(true);
	};
	const onMouseLeave = () => {
	    setShowChevron(false);
	};
    while (!moment.utc(night_start_date.add(inc, "d").format("YYYY-MM-DD")).isSame(moment.utc(moment_end_date.format("YYYY-MM-DD")))) {
        inc = 1;
        nb_day = nb_day + 1;
        if (nb_day === 100) {
            break;
        }
    }
    if (step !== null && step.destination !== null) {
        if (step.destination.data.cover_picture !== null) {
            if (step.destination.data.cover_picture.thumbnail_big !== null) {
                picture = step.destination.data.cover_picture.thumbnail_big;
            } else {
                picture = step.destination.data.cover_picture.url;
            }
        } else if (step.destination.data.pictures !== null && step.destination.data.pictures.length > 0) {
            if (step.destination.data.pictures[0].thumbnail_big !== null) {
                picture = step.destination.data.pictures[0].thumbnail_big;
            } else {
                picture = step.destination.data.pictures[0].url;
            }
        }
    }
    for (let j = 0; j < guide_list.length; j++) {
        if (step.destination !== null) {
            if(step.destination.id === guide_list[j].guide.destination) {
                if (guide_list[j].rubric !== undefined && guide_list[j].rubric !== null) {
                    if (guide_list[j].rubric.on_quotation === true) {
                        description = guide_list[j].description;
                    }
                }
            }
        }
    }
    if (description !== undefined && description !== null) {
        description = description.replaceAll("&nbsp;", " ");
    }
    let step_day = moment(step.start_date).add(day_index, 'd');
    return (
        <Grid item style={{width: "100%"}}>
            <Grid container direction={"column"} spacing={2}>
                {
                    from_summary && (
                        <Grid item style={{width: "100%"}}>
                            <span className={classes.bold} style={{fontSize: 30}}>{ t("filters.step") + " " + nb_step }</span>
                            <span className={classes.bold}>{" - " + step_name[0] + ", " + t("global.from") + " " + moment.utc(step.start_date).format("DD/MM") + " " + t("global.to")  + " " + moment.utc(step.end_date).format("DD/MM") + " "}</span>
                            <span className={classes.bold}>({(nb_nights > 1 ? nb_nights + " " + t("itinerary.nights") : nb_nights + " " + t("itinerary.night")) + " - " + t("menu.history.csv.day") + " " + first_day + " " + t("global.at") + " " + last_day })</span>
                        </Grid>
                    )
                }
                <Grid item style={{marginBottom: 20, width: "100%"}}>
                {
                    destination_pictures.length === 1 && picture !== null && (
                        <img src={picture} className={ fullScreen ? classes.mobilePicture :  classes.picture} onClick={openModal} style={{cursor: 'pointer'}}/>
                    )
                }
                {
                    destination_pictures !== null && destination_pictures.length > 1 && (
                        <div className={ fullScreen ? classes.mobilePicture :  classes.picture} onMouseEnter={ onMouseEnter } onMouseLeave={ onMouseLeave } onClick={openModal} style={{cursor: 'pointer', overflow: 'hidden', position: "relative",}}>
                            <Slider pictures={ destination_pictures } showChevron={ showChevron } size={ 0 } dotSize={ 1 } shape={"square"}/>
                        </div>
                    )
                }
                {
                    description !== null && (
                        <div className={`${classes.genericText} ${classes.textJustify}`}>
                            <span dangerouslySetInnerHTML={ {__html: description} }/>
                            {
                                // step.destination.data.pictures !== undefined && step.destination.data.pictures !== null && step.destination.data.pictures.length > 1 && (
                                //     <span className={classes.genericText} style={{cursor: "pointer", textDecoration: "underline", float: "right"}} onClick={openModal}>{t("summary.see_more_dest_pic")}</span>
                                // )
                            }
                        </div>
                    )
                }
                {
                    step.destination.categories !== null && step.destination.categories.length > 0 && (
                        <Grid container direction={"row"} alignItems={"center"}>
                        {
                            step.destination.categories.map((categorie, index_categorie) => {
                                return (
                                    <Grid item key={index_categorie} className={classes.category} >{categorie.name}</Grid>
                                );
                            })
                        }
                        </Grid>
                    )
                }
                </Grid>
            </Grid>
            <MosaicPicture open={ openMozaic } onClose={ toggleMosaicModal } pictures={ destination_pictures } openModal={ togglePictureModal }/>
			<Picture open={ openPicture } onClose={ togglePictureModal } pictures={ destination_pictures } current_picture={ current_picture } setCurrentPicture={ setCurrentPicture }/>
        </Grid>
    )
}
export default RenderDestination;