import React, { useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Close from '@material-ui/icons/Close';
import MaterialTimelinePoi from './MaterialTimelinePoi';
import MaterialTimelineTransport from './MaterialTimelineTransport';
import MaterialTimelineItem from './MaterialTimelineItem';
import MaterialTimelineConnector from './MaterialTimelineConnector';
import MaterialTimelineAccommodation from './MaterialTimelineAccommodation';
import grey from '@material-ui/core/colors/grey';
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
    root: {
        padding: 32,
        color: grey[500]
    },
    navigationWrapper: {
        marginTop: 8
    },
    alignCenter: {
        textAlign: 'center'
    },
    buttonBorder: {
        border: '1px solid'
    },
    marginLeft: {
        marginLeft: 8
    },
    marginRight: {
        marginRight: 8
    },
    timelineWrapper: {
        marginTop: 8
    },
    stepper: {
        padding: 0
    },
    relative: {
        position: 'relative'
    },
    tabletRelative: {
        position: 'relative',
        overflow: 'hidden'
    },
    timelineCart: {
        position: 'absolute',
        top: 90,
        left: 0,
        width: 'calc(100% - 32px)',
        zIndex: 3,
        margin: 16
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0
    },
    timelineContentContainer: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
        position: 'absolute',
        overflow: 'hidden',
        zIndex: 0,
        display: '-webkit-inline-box',
        transition: 'left 400ms linear',
        left: 86
    },
    chevronContainer: {
        textAlign: 'center',
        backgroundColor: 'white',
        zIndex: 1,
        padding: '26px 20px !important',
        minWidth: 94,
        minHeight: 86
    },
    step: {
        padding: 0
    }
});

const MaterialTimeline = () => {
    console.log('timeline render');
    const classes = useStyles();
    const slider = useRef(null);
    const dispatch = useDispatch();
    const itinerary = useSelector(state => state.itinerary.itinerary_list);
    const active = useSelector(state => state.base.active);
    const timeline_cart = useSelector(state => state.base.timeline_cart);
    const [offset, setOffset] = React.useState(0);
    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.down('sm'));
    const itineraryHasStartStep = itinerary.findIndex((item) => {
        return item.step_type === 'START';
    }) >= 0;
    const decreaseOffset = offset => event => {
        if (offset > 0) {
            slider.current.style.left = `${86 + -270 * (offset - 1)}px`;
            setOffset(offset - 1);
        }
    };
    const increaseOffset = offset => event => {
        if (offset < itinerary.length - 3 || (tablet && offset < itinerary.length)) {
            slider.current.style.left = `${86 + -270 * (offset + 1)}px`;
            setOffset(offset + 1);
        }
    };
    const onClickAway = event => {
        dispatch({
            type: 'MATERIAL_SET_TIMELINE_CART',
            payload: null
        });
    };
    useEffect(() => {
        for (let i = 0; i < itinerary.length; i++) {
            if (itinerary[i].id === active && i > 0) {
                let currentLeft = slider.current.style.left.replace("px", "");
                if (tablet) {
                    slider.current.style.left = `${86 + -270 * (i)}px`;
                    setOffset(i);
                    break;
                } else if (currentLeft < (86 + -270 * (i)) || currentLeft > (86 + -270 * (i - 2))) {
                    slider.current.style.left = `${86 + -270 * (i - 1)}px`;
                    setOffset(i - 1);
                    break;
                }
            }
        }
    }, [active, tablet, slider]);
    return (
        <div className={ tablet ? classes.tabletRelative : classes.relative }>
            <Grid className={ classes.timelineWrapper } container justify={ 'space-between' } alignItems={ 'center' } spacing={ 2 }>
                <Grid className={ classes.chevronContainer } item>
                    {
                        offset > 0 && (
                            <IconButton className={ `${classes.buttonBorder} ${classes.marginLeft}` } onClick={ decreaseOffset(offset) }>
                                <ChevronLeft fontSize={ "small" }/>
                            </IconButton>
                        )
                    }
                </Grid>
                <Grid ref={ slider } className={ classes.timelineContentContainer } item>
                    {
                        itinerary.map((itinerary_step, index_itinerary_step) => {
                            return (
                                <React.Fragment key={ index_itinerary_step }>
                                    {
                                        index_itinerary_step > 0 && (
                                            <MaterialTimelineConnector index={ index_itinerary_step - 1 }/>
                                        )
                                    }
                                    <MaterialTimelineItem
                                        index={
                                            itineraryHasStartStep ?
                                                index_itinerary_step :
                                                index_itinerary_step + 1
                                        }
                                        itinerary_step={ itinerary_step }
                                    />
                                </React.Fragment>
                            );
                        })
                    }
                </Grid>
                <Grid className={ classes.chevronContainer } item>
                    {
                        (offset < itinerary.length - 3 || (tablet && offset < itinerary.length)) && (
                            <IconButton className={ `${classes.buttonBorder} ${classes.marginRight}` } onClick={ increaseOffset(offset) }>
                                <ChevronRight fontSize={ "small" }/>
                            </IconButton>
                        )
                    }
                </Grid>
            </Grid>
            {
                timeline_cart !== null && timeline_cart.cart.length > 0 && (
                    <ClickAwayListener onClickAway={ onClickAway }>
                        <div className={ classes.timelineCart }>
                            <Paper className={ classes.root } elevation={ 4 }>
                                <IconButton className={ classes.closeButton } onClick={ onClickAway }>
                                    <Close/>
                                </IconButton>
                                {
                                    timeline_cart.type === 0 ? (
                                        <MaterialTimelineAccommodation cart={ timeline_cart.cart }/>
                                    ) : timeline_cart.type === 1 ? (
                                        <MaterialTimelinePoi cart={ timeline_cart.cart }/>
                                    ) : (
                                        <MaterialTimelineTransport cart={ timeline_cart.cart }/>
                                    )
                                }
                            </Paper>
                        </div>
                    </ClickAwayListener>
                )
            }
        </div>
    );
};

export default React.memo(MaterialTimeline);
