import { countBy } from "lodash";
import { Itinerary } from "../../../Itinerary/objects/itinerary";
import { itineraryToPolylinePath } from "./itineraryToPolylinePath";
import { Key } from "../../../../Reducers/Reducers";

export function createItineraryPageMapUrl(steps: Itinerary[]): string {
    const config = JSON.parse(localStorage.getItem('config') ?? '{}') as { keys?: Key[] };
    const key = config.keys?.find((item) => item.identifier === 'google_api');
    const itineraryUrl = new URL('https://maps.googleapis.com/maps/api/staticmap');
    itineraryUrl.searchParams.append(
        'size',
        '985x943'
    );
    itineraryUrl.searchParams.append(
        'key',
        key?.value ?? ''
    );
    itineraryUrl.searchParams.append(
        'path',
        `color:0x48A6C9FF|geodesic:true|enc:${itineraryToPolylinePath(steps)}`
    );
    itineraryUrl.searchParams.append(
        'style',
        'feature:poi|element:labels|visibility:off'
    );

    const filteredSteps = steps.filter((item): item is Itinerary & {destination: NonNullable<Itinerary['destination']>} => {
        return item.step_type === 'STEP' && !!item.destination;
    });
    const stepsCount = countBy(
        steps,
        (step) => step.destination?.id
    );
    const markers = filteredSteps.map((item, index) => {
        if ((stepsCount[item.destination.id] ?? 0) > 1) {
            return `color:yellow|label:S|${item.destination.data?.latitude},${item.destination.data?.longitude}`;
        }
        return `color:blue|label:${index + 1}|${item.destination.data?.latitude},${item.destination.data?.longitude}`;
    });

    if (markers.length > 0) {
        for (const marker of markers) {
            itineraryUrl.searchParams.append(
                'markers',
                marker
            );
        }
    }

    return itineraryUrl.toString();
}
