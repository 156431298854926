import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CircularProgress from "@material-ui/core/CircularProgress";

import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import HasPermission from "../Common/Functions/HasPermission.js";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import axios from "axios";
import { width } from "@mui/system";
import { DestinationChange } from "../../Actions/Header.js";
import { SetItineraryTypeData } from "../../Actions/ItineraryType.js";

const useStyles = makeStyles(theme => ({
	formControlRoot: {
		borderRadius: "20px",
		textTransform: "capitalize",
        marginTop: 2,
        padding: 7
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    whiteButton: {
        backgroundColor: "white",
        color: "black"
    },
    customSize: {
        width: 41,
        height: 41,
        marginTop: 4,
        backgroundColor: "transparent"
    },
    paperStepper: {
		position: "absolute !important",
        // top: "100% !important",
        // marginLeft: "-232px",
        right: "0px",
        zIndex: "20 !important",
        background: "rgb(255, 255, 255) !important",
        // borderRadius: "32px !important",
        boxShadow: "rgb(0 0 0 / 20%) 0px 6px 20px !important",
        marginTop: "12px !important",
        maxHeight: "calc(100vh - 220px) !important",
        overflow: "hidden auto !important",
        padding: "16px 32px !important",
        width: "100%"
    },
    active: {
        backgroundColor: "rgba(0, 0, 0, 0.04)"
    },
    bold: {
        fontWeight: 'bold'
    },
    menu: {
        '&:hover': {
            color: '#BF202D'
        }
    },
    pointer: {
        cursor: 'pointer'
    },
    relative: {
        position: 'relative'
    },
    parentList: {
        [theme.breakpoints.down('sm')]: {
            columns: 1,
        },
        [theme.breakpoints.up('sm')]: {
            columns: 5,
        },
        listStyleType: "none",
        fontWeight: "bold",
        margin: 0,
        padding: 0,
        fontSize: 17,
    },
    childList: {
        listStyleType: "none",
        fontWeight: "normal",
        margin: 0,
        padding: 0,
        fontSize: 14,
        cursor: "pointer"
    },
}));

const VisiteursDestinationMenu = ({router, full_dest}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(store => store.user.user);
    const permissions = useSelector(store => store.user.permissions);
	const data_trip = useSelector(store => store.trip.data_trip);
    const hover_nav = useSelector(store => store.header.hover_nav);
    
    const [orderDestination, setOrderDestination] = useState(null);

    let current_location = router.getCurrentLocation();
    const config = JSON.parse(localStorage.getItem("config"));

    const handleClose = () => {
        dispatch({type: 'HEADER_SET_HOVER_NAV', payload: null})
    };

    const handleChildClick = (e, data) => {
        e.preventDefault();
        handleClose();
        dispatch(DestinationChange(data));
        dispatch(SetItineraryTypeData(data.data));
        router.push(`/${window.url_name}/destination-product/${data.id}`);
    };

    useEffect(() => {
        if (full_dest.length > 0) {
            let sort_dest = full_dest.sort((a, b) => {
                return a.data.type - b.data.type;
            });
            let tmp_arr = {};
            for (let i = 0; i < sort_dest.length; i++) {
                let tmp_obj = {
                    name: null,
                    data: null,
                    children: []
                };
                if (sort_dest[i].parent !== null)
                {
                    let is_parent = false;
                    sort_dest[i].data.destination = sort_dest[i].id;
                    let key = sort_dest[i].parent.data.name !== undefined ? sort_dest[i].parent.data.name : sort_dest[i].parent.data.international_name;
                    Object.keys(tmp_arr).map((destination) => {
                        tmp_arr[destination].map((dest) => {
                            if (dest.name === key) {
                                is_parent = true;
                                dest.children.push({
                                    name: sort_dest[i].data.name !== undefined ? sort_dest[i].data.name : sort_dest[i].data.international_name,
                                    data: sort_dest[i]
                                });
                                dest.children = dest.children.sort((a, b) => {
                                    let fa = a.name.toLowerCase();
                                    let fb = b.name.toLowerCase();

                                    if (fa < fb) {
                                        return -1;
                                    }
                                    if (fa > fb) {
                                        return 1;
                                    }
                                    return 0;
                                });
                            }
                        })
                    })
                    if (!is_parent) {
                        if (tmp_arr[key] === undefined){
                            tmp_arr[key] = [];
                            tmp_obj.name = sort_dest[i].data.name !== undefined ? sort_dest[i].data.name : sort_dest[i].data.international_name;
                            tmp_obj.data = sort_dest[i];
                            tmp_arr[key].push(tmp_obj);
                        } else {
                            tmp_obj.name = sort_dest[i].data.name !== undefined ? sort_dest[i].data.name : sort_dest[i].data.international_name;
                            tmp_obj.data = sort_dest[i];
                            tmp_arr[key].push(tmp_obj);
                        }
                    }
                }
            }
            const ordered = {};
            Object.keys(tmp_arr).sort().forEach(function(key) {
                tmp_arr[key].sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                });
                ordered[key] = tmp_arr[key];
            });
            console.log('ordered:', ordered);
            setOrderDestination(ordered);
        }
    }, [full_dest]);
    return (
        <Fragment>
            <div className={`${classes.bold} ${classes.pointer} ${classes.menu} ${classes.relative}`} onMouseEnter={() => {dispatch({type: 'HEADER_SET_HOVER_NAV', payload: 'destination'})}}>
                Destinations
            </div>
            {
                hover_nav === "destination" && (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Paper
                            className={classes.paperStepper}
                            elevation={5}
                            children={
                                <Grid container style={{paddingBottom: 20}}>
                                    <Grid item className={classes.genericText}>
                                        {
                                            orderDestination !== null && (
                                                <Grid container spacing={ 2 }>
                                                {
                                                    Object.keys(orderDestination).map((key, index) => {
                                                        return (
                                                            <Fragment key={key}>
                                                                <Grid item>
                                                                    <Grid container direction={'column'}>
                                                                        <Grid item style={{color: '#BF202D', fontWeight: 'bold', fontSize: 17}}>{key}</Grid>
                                                                    </Grid>
                                                                    {
                                                                        orderDestination[key].map((child, child_index) => {
                                                                            return (
                                                                                <Grid item key={child_index} onClick={(e) => handleChildClick(e, child.data)}>
                                                                                    <Grid style={{cursor: 'pointer'}}>{child.name}</Grid>
                                                                                    {
                                                                                        child.children.length > 0 && (
                                                                                            <Grid item style={{paddingLeft: 10, fontStyle: "italic", cursor: 'pointer'}}>
                                                                                            {
                                                                                                child.children.map((children, children_index) => {
                                                                                                    return (
                                                                                                        <Grid item key={children_index} onClick={(e) => handleChildClick(e, children.data)}>{children.name}</Grid>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                            </Grid>
                                                                                        )
                                                                                    }
                                                                                </Grid>
                                                                            )
                                                                        })
                                                                    }
                                                                </Grid>
                                                                {
                                                                    index !== Object.keys(orderDestination).length - 1 &&
                                                                    <Divider orientation="vertical" flexItem variant={'middle'}/>
                                                                }
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                                </Grid>
                                            )
                                        }
                                        {
                                        //     orderDestination !== null && (
                                        //         <ul className={classes.parentList}>
                                        //         {
                                        //             Object.keys(orderDestination).map((key) => {
                                        //                 return (
                                        //                     <li key={key}>
                                        //                         {key}
                                        //                         <ul className={classes.childList}>
                                        //                             {
                                        //                                 orderDestination[key].map((child, child_index) => {
                                        //                                     return (
                                        //                                         <li key={child_index}>
                                        //                                             <span onClick={(e) => handleChildClick(e, child.data)}>{child.name}</span>
                                        //                                             {
                                        //                                                 child.children.length > 0 && (
                                        //                                                     <ul style={{listStyleType: "none", paddingLeft: 10, fontStyle: "italic"}}>
                                        //                                                     {
                                        //                                                         child.children.map((children, children_index) => {
                                        //                                                             return (
                                        //                                                                 <li key={children_index} onClick={(e) => handleChildClick(e, children.data)}>{children.name}</li>
                                        //                                                             )
                                        //                                                         })
                                        //                                                     }
                                        //                                                     </ul>
                                        //                                                 )
                                        //                                             }
                                        //                                         </li>
                                        //                                     )
                                        //                                 })
                                        //                             }
                                        //                         </ul>
                                        //                     </li>
                                        //                 )
                                        //             })
                                        //         }
                                        //         </ul>
                                        //     )
                                        }
                                        {
                                            orderDestination === null && (
                                                <CircularProgress/>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            }
                        />
                    </ClickAwayListener>
                )
            }
        </Fragment>
    )
}
export default React.memo(VisiteursDestinationMenu);