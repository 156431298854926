//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@mui/icons-material/Check';

import ActivityInfoMap from './ActivityInfoMap.tsx';
import i18n from '../../../i18n';

const useStyles = makeStyles(theme => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    dialogSize: {
        "& .mui-jss-MuiDialog-paper": {
            width: 500
        },
        "& .mui-jss-MuiDialog-paperWidthSm": {
            maxWidth: 500
        }
    },
    title: {
        fontSize: '30pt',
        margin: '10px 0',
        color: 'black'
    },
    fact: {
        flex: '0 0 33.33%',
        display: 'flex',
        alignItems: 'center',
        gap: '1em',
    },
    check: {
        opacity: 0.5,
    },
    qualities: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: '0px !important',
        marginBottom: '1em',
    },
    carouselAccommodationFullScren: {
        position: 'relative',
        float: 'left',
        width: '100%',
        marginRight: '1em',
        marginBottom: 0
    },
    carouselAccommodation: {
        position: 'relative',
        float: 'left',
        width: '50%',
        marginRight: '1em',
        marginBottom: 0
    },
    carouselAccommodationAlt: {
        width: '120% !important',
        overflow: 'hidden',
        display: 'flex',
        position: 'unset'
    },
    carouselAccommodationInteractions: {
        position: 'absolute',
        width: '96%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 5px'
    },
    carouselAccommodationInteractionsSvg: {
        cursor: 'pointer',
        fontSize: '22pt',
        backgroundColor: '#0000004f',
        color: 'white'
    },
    firstImg: {
        marginRight: '2%',
        width: '60% !important'
    },
    secondImg: {
        width: '49% !important'
    },
    containImage: {
        width: '100%',
        objectFit: 'contain'
    },
    onePicture: {
        margin: "15px 0",
        paddingLeft: '2em'
    },
    multiplePictures: {
        margin: "1.5em 0",
        position: "relative",
        overflow: "hidden",
        paddingLeft: '2em'
    }
}));

type MoreInfoProps = {
    info: any;
    activity: any;
    moreInfo: boolean;
    setMoreInfo: (value:boolean) => void;
    step: number;
    setStep: (value:number) => void;
};

const MoreInfo = (props: MoreInfoProps) => {
    const { info, activity, moreInfo, setMoreInfo, step, setStep } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const locales = useSelector(state => state.user.locales);

    const current_locale = locales.find((el) => {return el.language_code === i18n.language});

    const [images, setImages] = useState<any[]>([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
    const [included, setIncluded] = useState<string[]>([]);
    const [notIncluded, setNotIncluded] = useState<string[]>([]);
    const [notRecommended, setNotRecommended] = useState<string[]>([]);
    const [guideLang, setGuideLang] = useState<string[]>([]);
    const move = (step: number | undefined) => {
        if (images) {
            if (images.length <= 0) return;
            if (step == -1) setSelectedImageIndex(selectedImageIndex <= 0 ? images.length - 1 : selectedImageIndex - 1);
            else if (step == 1) setSelectedImageIndex(selectedImageIndex >= images.length - 1 ? 0 : selectedImageIndex + 1);
        }
    };

    const handleClose = () => {
        setMoreInfo(!moreInfo);
        setStep(0);
    };
    const newlineText = (prop:string) => {
        let text = prop;
        if (text !== undefined && text !== null) {
            let delim = "";
            if (text.includes("<br />")) {
                delim = "<br />";
            } else if (text.includes("<br/>")) {
                delim = "<br/>";
            } else if (text.includes('\n')) {
                delim = '\n';
            }
            if (delim !== "") {
                return text.split(delim).map((str:string, str_index:number) => <p key={str_index}>{str}</p>);
            }
            return <p>{text}</p>
        }
    };
    useEffect(() => {
        if (info) {
            let imgs: any[] = [];
            let localization = info.localization.length !== 0 ? info.localization.find((el: any) => {return el.locale === current_locale.id}) : null;
            if (activity?.overload_pictures?.length) {
                imgs = activity.overload_pictures;
            } else {
                if (info.cover_picture !== undefined && info.cover_picture !== null && info.cover_picture.url !== undefined) {
                    let cover_picture = [info.cover_picture];
                    imgs = [...imgs, ...cover_picture]
                }
                if (info.pictures !== undefined) {
                    imgs = [...imgs, ...info.pictures];
                }
            }
            if (imgs?.length) {
                imgs = imgs.map(img => {
                    let real_url: string | null = img.url;
                    if (!real_url) real_url = img.thumbnail_big;
                    if (!real_url) real_url = img.thumbnail_medium;
                    return { ...img, url: real_url };
                })
            }
            if (localization !== undefined && localization !== null && localization.included !== undefined && localization.included !== null && localization.included.length !== 0) {
                setIncluded(localization.included);
            } else {
                setIncluded(info.included);
            }
            if (localization !== undefined && localization !== null && localization.not_included !== undefined && localization.not_included !== null && localization.not_included.length !== 0) {
                setNotIncluded(localization.not_included);
            } else {
                setNotIncluded(info.not_included);
            }
            if (localization !== undefined && localization !== null && localization.not_recommended !== undefined && localization.not_recommended !== null && localization.not_recommended.length !== 0) {
                setNotRecommended(localization.not_recommended);
            } else {
                setNotRecommended(info.not_recommended);
            }
            if (localization !== undefined && localization !== null && localization.guide_lang !== undefined && localization.guide_lang !== null && localization.guide_lang.length !== 0) {
                setGuideLang(localization.guide_lang);
            } else if (info.guide_lang !== null) {
                setGuideLang(info.guide_lang);
            }
            setImages(imgs ?? []);
        }
    }, [activity]);
    return (
        <Dialog open={moreInfo} onClose={handleClose} fullScreen={fullScreen} fullWidth maxWidth={ "md" }>
            <DialogTitle>
                <IconButton disableRipple={true} disableFocusRipple={true} edge={ "start" } style={{ position: "absolute", top: 0, right: 8 }} onClick={handleClose}><CloseIcon/></IconButton>
            </DialogTitle>
            <DialogContent className={classes.genericText}>
                <div>
                    <Grid container spacing={2}>
                        <Grid xs={12} item>
                            <Typography variant='h4' style={{textAlign: 'center'}}>{t<string>('transfers.more_information')}</Typography>
                        </Grid>
                        {
                            fullScreen && images.length !== 0 &&
                            <Grid xs={12} item style={{ margin: "15px 0", paddingLeft: '2em' }}>
                            {images?.at(0) && (
                                <Grid className={`${classes.carouselAccommodationFullScren}`}>
                                    <div className={classes.carouselAccommodationInteractions}>
                                        <ArrowBackIosNewIcon className={classes.carouselAccommodationInteractionsSvg} onClick={() => move(-1)} />
                                        <ArrowForwardIosIcon className={classes.carouselAccommodationInteractionsSvg} onClick={() => move(1)} />
                                    </div>
                                    <img
                                        className={classes.containImage}
                                        src={(selectedImageIndex ? images?.at(selectedImageIndex)?.url : images?.at(0)?.url) ?? undefined}
                                        style={{
                                            height: images?.at(0)?.url ? "20em" : 0
                                        }}
                                    />
                                </Grid>
                            )}
                            </Grid>
                        }
                        {
                            !fullScreen && images.length !== 0 &&
                            <Grid xs={12} item className={clsx({[classes.onePicture]: images.length === 1, [classes.multiplePictures]: images.length > 1})}>
                                {images?.at(0) && (
                                    <Grid className={clsx({[classes.carouselAccommodation]: images.length > 1, [classes.carouselAccommodationAlt]: images.length > 1, [classes.carouselAccommodationFullScren]: images.length})}>
                                        {images && images.length > 1 && (
                                            <div className={classes.carouselAccommodationInteractions}>
                                                <ArrowBackIosNewIcon className={classes.carouselAccommodationInteractionsSvg} onClick={() => move(-1)} />
                                                <ArrowForwardIosIcon className={classes.carouselAccommodationInteractionsSvg} onClick={() => move(1)} />
                                            </div>
                                        )}
                                        <img
                                            className={clsx({[classes.containImage]: images.length === 1, [classes.firstImg]: images.length > 1})}
                                            src={(selectedImageIndex ? images?.at(selectedImageIndex)?.url : images?.at(0)?.url) ?? undefined}
                                            style={{ height: images?.at(0)?.url ? "20em" : 0 }}
                                        />
                                        {images.length > 1 && images?.at(selectedImageIndex === images.length - 1 ? 0 : selectedImageIndex + 1)?.url != images?.at(selectedImageIndex)?.url &&
                                            <img
                                                className={classes.secondImg}
                                                src={images?.at(selectedImageIndex === images.length - 1 ? 0 : selectedImageIndex + 1)?.url ?? undefined}
                                                style={{ height: images?.at(selectedImageIndex === images.length - 1 ? 0 : selectedImageIndex + 1)?.url ? "20em" : 0 }}
                                            />}
                                    </Grid>
                                )}
                            </Grid>
                        }
                        <Grid item xs={12}>
                        {
                            (info.description === undefined || info.description === null) &&
                            <div dangerouslySetInnerHTML={{__html: info.important_notice === undefined ? (info.long_description !== null ? info.long_description : info.short_description) : info.important_notice}}/>
                        }
                        {
                            info.description !== undefined && info.description !== null &&
                            <div style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{__html: info.description}}/>
                        }
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction={"column"}>
                                {info.guide && (
                                    <Typography className={classes.fact}><CheckIcon className={classes.check} />
                                        {t<string>('poi.guide_included')}
                                        {guideLang.length !== 0 && (' : ' + (typeof guideLang === 'string' ? guideLang : guideLang.join(', ')))}
                                    </Typography>
                                )}
                                {info.audio_guide && (
                                    <Typography className={classes.fact}><CheckIcon className={classes.check} />{t<string>('poi.audio_guide')}</Typography>
                                )}
                                {info.for_children && (
                                    <Typography className={classes.fact}><CheckIcon className={classes.check} />{t<string>('poi.for_children')}</Typography>
                                )}
                                {info.for_disabled && (
                                    <Typography className={classes.fact}><CheckIcon className={classes.check} />{t<string>('poi.for_disabled')}</Typography>
                                )}
                                {info.cut_line && (
                                    <Typography className={classes.fact}><CheckIcon className={classes.check} />{t<string>('poi.cut_line')}</Typography>
                                )}
                                {info.need_pax_weight && (
                                    <Typography className={classes.fact}><CheckIcon className={classes.check} />{t<string>('poi.need_pax_weight')}</Typography>
                                )}
                                {
                                    included !== undefined && included !== null && included.length > 0 && (
                                        <Fragment>
                                            <br/>
                                            <Grid xs={12} item>
                                                <Typography style={{ marginTop: 5 }}><b>{t<string>('poi.included')} :</b></Typography>
                                            </Grid>
                                            {
                                                included.map((item:string, index:number) => {
                                                    return (
                                                        <Typography key={ index } className={classes.fact}><CheckIcon className={classes.check} />{item}</Typography>
                                                    );
                                                })
                                            }
                                        </Fragment>
                                    )
                                }
                                {
                                    notIncluded !== undefined && notIncluded !== null && notIncluded.length > 0 && (
                                        <Fragment>
                                            <Grid xs={12} item>
                                                <Typography style={{ marginTop: 5 }}><b>{t<string>('poi.not_included')} :</b></Typography>
                                            </Grid>
                                            {
                                                notIncluded.map((item:string, index:number) => {
                                                    return (
                                                        <Typography key={ index } className={classes.fact}><CloseIcon className={classes.check} />{item}</Typography>
                                                    );
                                                })
                                            }
                                        </Fragment>
                                    )
                                }
                                {
                                    notRecommended !== undefined && notRecommended !== null && notRecommended.length > 0 && (
                                        <Fragment>
                                            <Grid xs={12} item>
                                                <Typography style={{ marginTop: 5 }}><b>{t<string>('poi.not_recommended')} :</b></Typography>
                                            </Grid>
                                            {
                                                notRecommended.map((item:string, index:number) => {
                                                    return (
                                                        <Typography key={ index } className={classes.fact}><CloseIcon className={classes.check} />{item}</Typography>
                                                    );
                                                })
                                            }
                                        </Fragment>
                                    )
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: 10, marginLeft: 0, display: 'block'}} container spacing={2}>
                            <ActivityInfoMap info={info.place !== undefined && info.place.latitude !== null && info.place.longetude !== null ? info : (activity.is_manual ? activity : activity.custom_product)}/>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
        </Dialog>
    );
};
export default MoreInfo;
