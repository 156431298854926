import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    IconButton,
    Menu,
    Stack,
    styled,
    TextField,
    Typography
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

type Props = {
    value: string,
    onChange: (value: string | null) => void,
    disabled?: boolean
}

const locales = [
    'beBY',
    'csCZ',
    'deDE',
    'enUS',
    'esES',
    'faIR',
    'fiFI',
    'frFR',
    'isIS',
    'itIT',
    'jaJP',
    'koKR',
    'nbNO',
    'nlNL',
    'plPL',
    'ptBR',
    'ruRU',
    'svSE',
    'trTR',
    'ukUA',
    'urPK',
    'zhCN'
];

export function ItineraryStepItemTimePicker(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const ref = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const locale = i18n.language.split('-')[0] ?? 'fr';
    const textsLocale = locales.find((item) => {
        return item.startsWith(locale);
    }) ?? locale;
    const texts = require(`@mui/x-date-pickers/locales/${textsLocale}.js`)[textsLocale];

    const onConfirm = () => {
        if (value && window.moment.utc(value).isValid()) {
            props.onChange(value);
            setOpen(false);
        }
    };

    const onCancel = () => {
        setOpen(false);
        setTimeout(
            () => {
                setValue(props.value);
            },
            150
        );
    };

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (
        <>
            <HourButton
                ref={ref}
                onClick={() => setOpen(true)}
                disabled={props.disabled}
            >
                {window.moment.utc(props.value).format('HH:mm')}
            </HourButton>
            <Menu
                open={open}
                anchorEl={ref.current}
                onClose={onCancel}
            >
                <Box sx={{ padding: 2.5 }}>
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ fontWeight: 200 }}
                        gutterBottom
                    >
                        {t<string>('itinerary.choose-time')}
                    </Typography>
                    <Stack
                        direction="row"
                        spacing={2.5}
                        alignItems="center"
                        sx={{ paddingBottom: 2.5, paddingTop: 2.5 }}
                    >
                        <Typography
                            variant="h3"
                            component="div"
                            sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                        >
                            {
                                window.moment.utc(value).isValid() ?
                                    window.moment.utc(value).format('HH:mm') :
                                    t<string>('itinerary.invalid-time')
                            }
                        </Typography>
                        <IconButton
                            sx={{ backgroundColor: '#eee' }}
                            onClick={() => inputRef.current?.focus()}
                        >
                            <Edit />
                        </IconButton>
                    </Stack>
                    <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={locale}
                        localeText={
                            texts.components.MuiLocalizationProvider.defaultProps.localeText
                        }
                    >
                        <DesktopTimePicker
                            inputRef={inputRef}
                            value={window.moment.utc(value)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            onChange={(value) => value && setValue(value.toISOString())}
                        />
                    </LocalizationProvider>
                    <Stack direction="row" justifyContent="flex-end" sx={{ paddingTop: 1 }}>
                        <Button onClick={onCancel}>
                            {t<string>('global.cancel')}
                        </Button>
                        <Button onClick={() => onConfirm()}>
                            {t<string>('global.ok')}
                        </Button>
                    </Stack>
                </Box>
            </Menu>
        </>
    );
}

const HourButton = styled('button')(() => ({
    "fontWeight": 700,
    "fontSize": '0.55rem',
    "color": '#2C98F0',
    "padding": 0,
    "border": 'none',
    "background": 'none',
    "cursor": 'pointer',
    '&:disabled': {
        cursor: 'auto'
    }
}));
