import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Slide from '@material-ui/core/Slide';
import i18n from "../../../i18n";
import localeText from "../../Functions/localeText";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A",
        letterSpacing: 1.25
    },
    fontSize12:{
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    fontWeight500:{
        fontWeight: 500
    },
    fontWeight900:{
        fontWeight: 900
    },
    upperCase:{
        textTransform: "uppercase",
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PoiDescriptionModal = ({ openDescription, setOpenDescription, activity }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(768));
    const locales = useSelector(state => state.user.locales);
    const current_locale = locales.find((el) => {return el.language_code === i18n.language});

    return(
        <Dialog open={openDescription} fullScreen={ mobile === true ? true : false} TransitionComponent={Transition} fullWidth maxWidth={"md"}>
            <DialogTitle>
                <Grid container justify={ mobile === true ? "flex-start" : "space-between" } alignItems={ "center" }>
                    {
                        mobile && (
                            <Grid item style={{marginRight: "5%"}}>
                                <IconButton onClick={() => { setOpenDescription(false) }} size='small'> <NavigateBeforeIcon /> </IconButton>
                            </Grid>
                        )
                    }
                    <Grid item className={`${classes.genericText} ${classes.upperCase} ${classes.fontSize16} ${classes.fontWeight500}`}>
                            { t("global.description") }
                    </Grid>
                    {
                        !mobile && (
                            <Grid item>
                                <IconButton onClick={() => { setOpenDescription(false) }} size='small'> <CloseIcon style={{float: "right"}}/> </IconButton>
                            </Grid>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                {
                    activity.short_description !== null && (
                        <Grid dangerouslySetInnerHTML={{ __html: activity.short_description}} />
                    )
                }
                {
                    activity.is_custom && localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') !== '' && (
                        <Grid dangerouslySetInnerHTML={{ __html: localeText(current_locale.id, activity.localization, activity.short_description, 'short_description')}} />
                    )
                }
            </DialogContent>
        </Dialog>
    )

}

export default React.memo(PoiDescriptionModal)