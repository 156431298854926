import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid";
import Tooltip from '@material-ui/core/Tooltip';

import LuggageCheckedIcon from "./LuggageCheckedIcon.js";
import ArrowIcon from "./ArrowIcon.js"

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    bold: {
        fontWeight: "bold"
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize9: {
        fontSize: 9
    },
    fontSize12: {
        fontSize: 12
    },
    textCenter: {
        textAlign: "center"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    borderTableCell:{
        borderBottom: "none"
    },
    luggageIcon: {
        fontSize: 24,
        color: `${main_color}`
    },
    luggageIconSmall: {
        fontSize: 20,
        color: `${main_color}`
    },
    luggageNumber: {
        marginRight: -18,
        zIndex: 1,
        color: "#FFFFFF",
        fontSize: 10
    },
    luggageNumberSmall: {
        marginRight: -15,
        zIndex: 1,
        color: "#FFFFFF",
        fontSize: 9
    },
    arrow: {
        fontSize: 90,
        margin: "-38px 20px",
    },
    arrowTablet: {
        fontSize: 80,
        margin: -36
    },
    arrowMini: {
        fontSize: 60,
        margin: "-26px 10px",
    },
    arrowSmall: {
        fontSize: 80,
        margin: "-35px 20px"
    },
}))

const LuggageByPax = (({ luggageNb, luggageType, paxNb, paxLabel, small, paidOptions }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const isSmallLaptop = useMediaQuery(theme.breakpoints.between(960, 1025));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    return (
        <TableRow style={{ verticalAlign: "baseline" }}>
            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter} ${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.uppercase}`} style={ paxLabel === t("flight_search.babies") ? { width: "21%" } : {}}>
                { paxLabel + " :"}
            </TableCell>
            {
                luggageNb === 0 && (
                    <TableCell padding="none" className={`${classes.borderTableCell} ${classes.genericText}  ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.uppercase}`}>
                        { t("flight_search.no_luggage")}
                    </TableCell>
                )
            }
            {
                luggageNb !== 0 && (
                    <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter}`}>
                        <Grid container alignItems="center" justify="flex-start">
                            <Grid item className={small ? classes.luggageNumberSmall : classes.luggageNumber}>
                                { "x" + luggageNb }
                            </Grid>
                            <Grid item>
                                { luggageType === "cabin" && <div className={`icon-cabin-luggage ${small ? classes.luggageIconSmall : classes.luggageIcon}`} /> }
                                { luggageType === "checked" && <LuggageCheckedIcon className={ small ? classes.luggageIconSmall : classes.luggageIcon} /> }
                            </Grid>
                        </Grid>
                    </TableCell>
                )
            }
            {
                luggageNb !== 0 && (
                    <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter}`}>
                        {/* {
                            small ? <div className={"icon-arrow-destination-travel result-information-luggage-small"}/> : <div className={"icon-arrow-destination-travel result-information-luggage"}/>
                        } */}
                        <ArrowIcon className={`${ (small || isSmallLaptop || isMobile ) ? ((origin === "menu" || isSmallLaptop || isMobile) ? classes.arrowMini : classes.arrowSmall) : (isTablet ? classes.arrowTablet : classes.arrow)} ${luggageType === "cabin" ? classes.purple : classes.orange}`} />
                    </TableCell>
                )
            }
            {
                luggageNb !== 0 && (
                    <TableCell padding="none" className={`${classes.borderTableCell} ${classes.genericText}  ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.uppercase} ${classes.textCenter}`}>
                        {  " x" + paxNb + " " + t("global.person_short") }
                    </TableCell>
                )
            }
        </TableRow>
    )
})

export default React.memo(LuggageByPax);