//---- Dependencies ----//
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import grey from '@material-ui/core/colors/grey';

//---- Actions ----//
import { AddHistorydest, SetItineraryTypeData } from "../../Actions/ItineraryType";
import { DestinationChange } from "../../Actions/Header";
import GetNewChildren from '../ItineraryType/Functions/GetNewChildren';


const useStyles = makeStyles(theme => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
	cardContainer: {
		width: "100%",
		height: "auto",
		boxShadow: "1px 1px 3px rgb(0 0 0 / 10%)",
		overflow: "hidden",
		transition: "transform .2s",
		"&:hover" : {
			"&& .mui-jss-MuiCardActionArea-root": {
				transform: "scale(1.2)"
			},
			"&& .mui-jss-MuiCardActions-root": {
				backgroundColor: "#BF202D",
				color: "white !important"
			}
		}
	},
	cardImg: {
		height: "auto",
		zIndex: 1,
		position: "relative",
		transition: "all .6s ease-out",
		display: "block",
		width: "100%",
		objectFit: "cover",
	},
	cardTitle: {
		width: "100%",
		background: "white",
		height: "65px",
		display: "flex",
		zIndex: 2,
		padding: "5px",
		position: "relative",
		cursor: "pointer"
	},
    cardMediaImg: {
        height: '230px !important',
        zIndex: 1,
        position: 'relative',
        transition: 'all .6s ease-out',
        width: '100%',
        objectFit: 'cover'
    }
}));


const VisiteursDestCard = ({destination, router, type}) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const handleClick = () => {
        if (type === "home") {
            dispatch(DestinationChange(destination));
            dispatch(SetItineraryTypeData(destination.data));
            router.push(`/${window.url_name}/destination-product/${destination.id}`);
        }
        else {
            dispatch(DestinationChange(destination));
            dispatch(SetItineraryTypeData(destination.data));
            let tmp_obj = {
                name: destination.data.name !== undefined ? destination.data.name : destination.data.international_name,
                data: destination.data,
                need_redirect: true
            };
            dispatch(AddHistorydest(tmp_obj));
            dispatch(SetItineraryTypeData(destination.data));
            router.push(`/${window.url_name}/destination-product/${destination.data.destination}`);

            GetNewChildren(destination, dispatch);
        }
	};

    return (
        <Grid item sm={3} xs={12}>
            <Card className={`${classes.cardContainer} ${classes.genericText}`} onClick={handleClick}>
                <CardActionArea className={classes.cardImg}>
                    <CardMedia
                        image={(destination.data.pictures !== null && destination.data.pictures.length > 0) ? destination.data.pictures[0].url : "/Img/default_dest.jpg"} className={classes.cardMediaImg}
                        title={ destination.data.name !== undefined ? destination.data.name : destination.data.international_name}
                    />
                </CardActionArea>
                <CardActions  className={classes.cardTitle}>
                    <Grid container justify={"center"}>
                        <Grid item>
                            <Typography variant="body2" component="p" style={{fontWeight: "bold", fontSize: 16}}>
                            { destination.data.name !== undefined ? destination.data.name : destination.data.international_name}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    )
}

export default VisiteursDestCard;
