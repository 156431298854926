//---- Dependencies ----//
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

@connect((store) => {
    return {
        seat_data: store.flight_search.seat_info,
        airlines: store.flight_search.airlines,
        airports: store.flight_search.airports
    }
})
@withTranslation()
export default class SeatTabLegend extends Component {
	render() {
		const { seat_data, airports, airlines, t } = this.props;
		let tmp = seat_data.flight_info[seat_data.tab];
		let marketing_airline = tmp.marketing_airline.airline_code ? tmp.marketing_airline.airline_code : airlines.find((airline) => {return airline.id === tmp.marketing_airline});
		return (
			<div className={ "row legend" }>
				<div className="col-md-4">
					<div className="row">
						<div className="col-md-3">
							<img src={"https://storagefacilitatrip.blob.core.windows.net/iata-company/" + (typeof(marketing_airline) === "number" ? marketing_airline : marketing_airline.code) + ".png"}/>
						</div>
						<div className="col-md-9">
							<div className="row">
								<div className="col-md-12 bold">
									{(typeof(marketing_airline) === "number" ? marketing_airline : marketing_airline.code) + "-" + (tmp.flight_number ? tmp.flight_number : tmp.marketing_flight_number)}
								</div>
								<div className="col-md-12">
									{t("itinerary.departure_date") + " " + moment.utc(tmp.departure_time ? tmp.departure_time : tmp.departure_datetime_locale).format("dddd DD MMM") + " " + t("global.at") + " "
										+  moment.utc(tmp.departure_time ? tmp.departure_time : tmp.departure_datetime_locale).format("HH[h]mm") + " " + t("global.from_1") + " " + (tmp.origin ? tmp.origin.international_name : airports[tmp.origin_airport].international_name)}
								</div>
								<div className="col-md-12">
									{t("itinerary.arrival_date") + " " + moment.utc(tmp.arrival_time ? tmp.arrival_time : tmp.arrival_datetime_locale).format("dddd DD MMM") + " " + t("global.at") + " "
										+  moment.utc(tmp.arrival_time ? tmp.arrival_time : tmp.arrival_datetime_locale).format("HH[h]mm") + " " + t("global.at") + " " + (tmp.destination ? tmp.destination.international_name : airports[tmp.destination_airport].international_name)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={ "col-md-8" }>
					<div className="row">
						<div className={ "col-md-6 text-center" }>
							<div className="row">
								<span className={ "bold ft-font-25 col-md-12 ft-dark-green" }>
									{tmp.aircraft ? tmp.aircraft.commercial_name : ""}
								</span>
								<span className={ "col-md-12 bold text-center" }>
									{seat_data.class_name}
								</span>
							</div>
						</div>
						{
						    (tmp.cabin_class === "Economy" || tmp.cabin_class === "Premium Economy") && (
						        <div className={ "col-md-6 seat-legend" }>
						        	<div className="row">
							            <div className="eco-seat col-md-12"><img src={ "/Img/flightSeatMap/Economy_seat.png" } className={"eco"}/><span>{t("flight_search.standard_seat")}</span></div>
							            <div className="eco-seat bulkhead col-md-12"><img src={ "/Img/flightSeatMap/Economy_seat.png" } className={"eco bulkhead"}/><span>{t("flight_search.bulkhead_seat")}</span></div>
							            <div className="eco-seat extra-legroom col-md-12"><img src={ "/Img/flightSeatMap/Economy_seat.png" } className={"eco extra-legroom"}/><span>{t("flight_search.extra_legroom")}</span></div>
							            <div className="eco-seat selected col-md-12"><img src={ "/Img/flightSeatMap/Economy_seat.png" } className={"eco selected"}/><span>{t("flight_search.selected_seat")}</span></div>
						        	</div>
						        </div>
						    )
						}
						{
						    (tmp.cabin_class === "Business" || tmp.cabin_class === "First") && (
						        <div className={ "col-md-7 seat-legend" }>
						            <div className="business-seat"/>
						            <div className="business-seat bulkhead"/>
						            <div className="business-seat extra-legroom"/>
						        </div>
						    )
						}
					
					</div>
				</div>
			</div>
		)
	}
}