import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core";
import { FormControl, Grid, InputLabel, OutlinedInput } from '@material-ui/core';
import Axios from 'axios';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ActivitiesCeetizQuestionsItem } from './ActivitiesCeetizQuestionsItem';
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from '../Common/Functions/GetCookie';
import { Autocomplete, Button, CircularProgress, Divider, TextField, Typography } from '@mui/material';
import { green } from '@material-ui/core/colors';
import { useSnackbar } from 'notistack';
import { styled, lighten, darken } from '@mui/system';
import { AppState } from '../../Reducers/Reducers';
import { ViatorLocation } from './ViatorLocation';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400:{
        fontWeight: 400
    },
    fontSize12: {
        fontSize: 12
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter:{
        textAlign: "center"
    },
    genericPadding:{
        padding: "8px 0px"
    },
    formControlRootSmall: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    formControlRootSmallRed: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    circleBorder:{
        borderRadius: 8
    },
    validateButton: {
        color: "#FFFFFF",
        backgroundColor: "#2C98F0",
        float: "right",
        marginTop: 8
    },
    errorInfo:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        letterSpacing: 1.25,
        color: "red",
        width: "100%",
        fontSize: 10
    },
    orangeButton: {
        backgroundColor: '#E6592F !important',
        color: 'white'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    marginBottom10: {
        marginBottom: 10
    },
}))

type ActivitiesCeetizQuestionsProps = {
    product: any;
    tempTravelers: any;
    setTempTravelers: any;
}

type Location = {
    name: string | null,
    description: string | null,
    google_ref: string | null,
    pick_up_type: string | null, 
    provider_type: string | null, 
    ref: string | null
}

type ViatorLocation = {
    meeting_type: string | undefined;
    locations: any[];
    start: any[];
    end: any[];
    allow_custom_traveler_pickup: boolean | undefined;
}

type ViatorQuestion = {
    answer_text: any;
    question_code: string;
    question_text: string;
    rules_answer: any;
}

type GooglePlace = {
    address_components: any[];
    adr_address: string;
    formatted_address: string;
    geometry: any;
    html_attributions: any[];
    icon: string;
    id: string;
    name: string;
    place_id: string;
    reference: string;
    scope: string;
    types: string[];
    url: string;
    utc_offset: number;
    vicinity: string;
}

export function ActivitiesCeetizQuestions(props: ActivitiesCeetizQuestionsProps) : JSX.Element | null {
    const { product, tempTravelers, setTempTravelers } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector((state: AppState) => state.user.user);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string[]>([]);
    const [questions, setQuestions] = useState<any[]>([]);

    useEffect(() => {
        if (product.questions.length > 0) {
            setQuestions(product.questions);
        }
    },[product]);

    const editProduct = (tmp_product: any) => {
        dispatch({ type: 'EDIT_ITEM_TO_BOOK', payload: {product: tmp_product} });
    }
    const patchActivity = () => {
        const { headers } = CheckBeforeRequest();
        let tmp_questions = [...product.questions];

        tmp_questions.map((question: any) => {
            if (question.answer_text !== null && question.answer_text.value !== '') {
                if (typeof question.answer_text !== 'string') {
                    question.answer_text = JSON.stringify(question.answer_text);
                }
            } else {
                question.answer_text = null;
            }
        });
        setLoading(true);
        Axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${product.id}/`,
            data: {
                questions: tmp_questions
            }
        }).then(response => {
            setLoading(false);
            enqueueSnackbar(t<string>("cart-material.patch-success"), {
                variant: "success",
            });
            dispatch({type: 'POI_EDIT_CART_BY_ID', payload: response.data});
        }).catch(error => {
            setLoading(false);
            // check error not empty                        
            let tmp_error: any = [];
            if (error.response !== undefined && error.response.data !== undefined && error.response.data !== null && error.response.data.questions !== undefined) {
                let tmp_errors = error.response.data.questions;
                tmp_errors.map((error: any, index: number) => {
                    if (Object.keys(error).length > 0) {
                        let question_code = 
                        tmp_error.push({
                            error: error.answer_text,
                            question_code: product.questions[index].question_code.toLowerCase(),
                        })
                    }
                });
            } 
            tmp_error.map((error: any) => {
                enqueueSnackbar(`${t<string>('poi.questions.' + error.question_code)} : ${error.error.join(' - ')}` , {
                    variant: "error",
                });
            });
        });
    }
    return (
        <Grid container direction={'column'}>
            <Grid item>
            {
                questions.map((question: ViatorQuestion) => {
                    let tmp_question = {...question};
                    // if (typeof tmp_question.answer_text === "string") {
                    //     tmp_question.answer_text = JSON.parse(tmp_question.answer_text);
                    // }
                    return (
                        <ActivitiesCeetizQuestionsItem key={`question-${question.id}`} question={tmp_question} product={product} error={error} editProduct={editProduct} />
                    )
                })
            }
            </Grid>
            <Grid item style={{position: 'relative', float: 'right'}}>
                <Grid container direction={'row'} justify={'flex-end'}>
                    <Grid item>
                        <Button variant={'contained'} className={ classes.orangeButton} disabled={loading} onClick={patchActivity}>{t<string>('menu.invoice.save')}</Button>
                        {
                            loading &&
                            <CircularProgress size={24} className={ classes.buttonProgress }/>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));
  
  const GroupItems = styled('ul')({
    padding: 0,
  });