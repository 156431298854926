import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
    Grid,
    ClickAwayListener,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    OutlinedInput,
    Divider,
    Tooltip
} from "@mui/material";
import {
    FormatAlignLeft,
    FormatAlignCenter,
    FormatAlignRight,
    FormatAlignJustify,
    FormatIndentDecrease,
    FormatIndentIncrease,
    AddLink,
    FormatBold,
    FormatItalic,
    FormatUnderlined,
    StrikethroughS,
    PhotoLibrary,
    FormatListBulleted,
    FormatListNumbered,
    Palette,
    Undo,
    Redo
} from "@mui/icons-material";
import clsx from "clsx";
import { EditorState, Modifier, RichUtils } from "draft-js";
import { RichEditorFontSizeControl } from "./richEditorFontSizeControl";
import { customColors } from "./customColors";
import decorator from "./decorator";
import { RichEditorStyleMap } from "./editor";
//import { toggleCreate } from '../../picture/redux/actions';
//import { AppState } from "../../../reducers";
import { RichEditorTextTypeControl } from "./richEditorTextTypeControl";

export type Toolbar =
    'text-type' |
    'font-size' |
    'alignment' |
    'indent' |
    'color' |
    'link' |
    'format' |
    'image' |
    'lists' |
    'undo'

export type EditorToolbarProps = {
    editorState: EditorState,
    setEditorState: any,
    hideToolbar?: boolean,
    sticky?: boolean,
    toolbars?: Toolbar[],
    centerToolbars?: boolean,
}

export const EditorDefaultActivatedToolbarControls: Toolbar[] = [
    'text-type',
    'alignment',
    'indent',
    'color',
    'link',
    'format',
    'image',
    'lists',
    'undo'
];

export function EditorToolbar(
    {
        editorState,
        setEditorState,
        hideToolbar,
        centerToolbars,
        sticky,
        toolbars = EditorDefaultActivatedToolbarControls
    }: EditorToolbarProps
): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const quotationCode = null; //TODO USE STATE
    const [openPalette, setOpenPalette] = useState(false);
    const [link, setLink] = useState('');
    const [linkText, setLinkText] = useState('');
    const [openLink, setOpenLink] = useState(false);
    const styles = {
        ...RichEditorStyleMap,
        ...(quotationCode ? customColors[quotationCode] : null)
    };
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
    const currentStyle = editorState.getCurrentInlineStyle();

    const onTogglePalette = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setOpenPalette((open) => !open);
    };

    const onColor = (color: string) => (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setOpenPalette(false);
        const currentStyle = editorState.getCurrentInlineStyle();
        console.log('currentStyle : ', currentStyle);
        setEditorState(RichUtils.toggleInlineStyle(editorState, `COLOR_${color}`));
    };

    const onToggleBlockType = (type: string) => (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setEditorState(RichUtils.toggleBlockType(editorState, type));
    };

    const onIndentDecrease = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        const tabIndent = '    ';
        const currentState = editorState;
        const selectionState = editorState.getSelection();
        const anchorKey = selectionState.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentContentBlock = currentContent.getBlockForKey(anchorKey);
        const selectedText = currentContentBlock.getText();
        if (selectedText.substring(0, 4) === tabIndent) {
            const blockSelection = selectionState.merge({
                anchorOffset: 0,
                focusOffset: selectedText.length
            });
            const nextState = Modifier.replaceText(currentContent, blockSelection, selectedText.substring(4));
            setEditorState(EditorState.push(currentState, nextState, 'indent'));
        }
    };

    const onIndentIncrease = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        //const currentState = editorState;
        //const selection = currentState.getSelection();
        //const blockType = currentState
        //.getCurrentContent()
        //.getBlockForKey(selection.getStartKey())
        //.getType();
        //if (blockType === 'unordered-list-item' || blockType === 'ordered-list-item') {
        //setEditorState(RichUtils.onTab(e, currentState, 3));
        //} else {
        const tabIndent = '    ';
        const currentState = editorState;
        const selectionState = editorState.getSelection();
        const anchorKey = selectionState.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentContentBlock = currentContent.getBlockForKey(anchorKey);
        const selectedText = currentContentBlock.getText();
        const blockSelection = selectionState.merge({
            anchorOffset: 0,
            focusOffset: selectedText.length
        });
        const nextState = Modifier.replaceText(currentContent, blockSelection, tabIndent + selectedText);
        setEditorState(EditorState.push(currentState, nextState, 'indent'));
        //}
    };

    const onClickAwayLink = () => {
        setOpenLink(false);
    };

    const onToggleLink = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = selection.getStartKey();
            const anchorKey = selection.getAnchorKey();
            const startOffset = selection.getStartOffset();
            const endOffset = selection.getEndOffset();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
            const currentContentBlock = contentState.getBlockForKey(anchorKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
            const selectedText = currentContentBlock.getText().slice(startOffset, endOffset);
            let url = '';
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().link;
            }
            setLink(url);
            setLinkText(selectedText);
        }
        setOpenLink((state) => !state);
    };

    const onLinkChange = (event: { target: { value: any; }; }) => {
        setLink(event.target.value);
    };

    const onLinkTextChange = (event: { target: { value: any; }; }) => {
        setLinkText(event.target.value);
    };

    const onAddLink = () => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            {
                link: link,
                text: linkText
            }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const selection = editorState.getSelection();
        const textWithEntity = Modifier.replaceText(
            contentState,
            selection,
            linkText,
            editorState.getCurrentInlineStyle(),
            entityKey
        );
        const newEditorState = EditorState.createWithContent(textWithEntity, decorator);
        setOpenLink(true);
        setEditorState(newEditorState);
        setLink('');
        setLinkText('');
    };

    const onToggleInlineStyle = (style: string) => (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };

    const onAddPicture = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        //dispatch(toggleCreate());
    };

    const onUndo = () => {
        setEditorState(EditorState.undo(editorState));
    };
    const onRedo = () => {
        setEditorState(EditorState.redo(editorState));
    };

    return (
        <Grid
            className={ 'ft-editorButtonContainer' }
            item
            container
            sx={{
                display: hideToolbar ? 'none' : 'flex',
                justifyContent: centerToolbars ? 'center' : 'flex-start',
                position: sticky ? 'sticky' : 'static',
                top: 0
            }}
        >
            {
                toolbars.includes('text-type') &&
                <RichEditorTextTypeControl
                    editorState={editorState}
                    onChangeEditorState={setEditorState}
                />
            }
            {
                toolbars.includes('font-size') &&
                <RichEditorFontSizeControl
                    editorState={editorState}
                    setEditorState={setEditorState}
                />
            }
            {
                toolbars.includes('color') &&
                <ClickAwayListener onClickAway={() => setOpenPalette(false)}>
                    <div style={{ position: 'relative' }}>
                        <IconButton disableRipple onMouseDown={ onTogglePalette }>
                            <Palette/>
                        </IconButton>
                        {
                            openPalette &&
                            <Grid
                                className={ 'ft-editorPaletteColorContainer' }
                                sx={(theme) => ({ zIndex: theme.zIndex.modal + 1 })}
                                container
                                spacing={ 1 }
                            >
                                <Grid item container spacing={ 1 }>
                                    {
                                        Object.keys(styles).filter((key) => {
                                            return key.startsWith('COLOR');
                                        }).map((key) => {
                                            return (
                                                <Grid key={key} item>
                                                    <div
                                                        style={{ backgroundColor: styles[key]?.color }}
                                                        className={ 'ft-editorPaletteColor' }
                                                        onMouseDown={ onColor(key.replace('COLOR_', '')) }
                                                    />
                                                </Grid>
                                            );
                                        })
                                    }
                                </Grid>
                            </Grid>
                        }
                    </div>
                </ClickAwayListener>
            }
            {
                toolbars.includes('alignment') &&
                <>
                    <IconButton className={ clsx({ ['ft-editorActive']: blockType === 'align-left' }) } disableRipple onMouseDown={ onToggleBlockType('align-left') }>
                        <FormatAlignLeft/>
                    </IconButton>
                    <IconButton className={ clsx({ ['ft-editorActive']: blockType === 'align-center' }) } disableRipple onMouseDown={ onToggleBlockType('align-center') }>
                        <FormatAlignCenter/>
                    </IconButton>
                    <IconButton className={ clsx({ ['ft-editorActive']: blockType === 'align-right' }) } disableRipple onMouseDown={ onToggleBlockType('align-right') }>
                        <FormatAlignRight/>
                    </IconButton>
                    <IconButton className={ clsx({ ['ft-editorActive']: blockType === 'align-justify' }) } disableRipple onMouseDown={ onToggleBlockType('align-justify') }>
                        <FormatAlignJustify/>
                    </IconButton>
                </>
            }
            {
                toolbars.includes('indent') &&
                <>
                    <IconButton disableRipple onMouseDown={ onIndentDecrease }>
                        <FormatIndentDecrease/>
                    </IconButton>
                    <IconButton disableRipple onMouseDown={ onIndentIncrease }>
                        <FormatIndentIncrease/>
                    </IconButton>
                </>
            }
            {
                toolbars.includes('link') &&
                <ClickAwayListener onClickAway={ onClickAwayLink }>
                    <div style={{ position: 'relative' }}>
                        <IconButton disableRipple onMouseDown={ onToggleLink }>
                            <AddLink/>
                        </IconButton>
                        {
                            openLink &&
                            <div className={ 'ft-editorLinkContainer' }>
                                <Grid container spacing={ 2 } justifyContent={ 'flex-end' }>
                                    <Grid item xs={ 12 }>
                                        <FormControl fullWidth required variant={ 'outlined' }>
                                            <InputLabel>{ t('shared.link') }</InputLabel>
                                            <OutlinedInput
                                                type={ 'text' }
                                                onChange={ onLinkChange }
                                                label={ t('shared.link') }
                                                value={ link }
                                                autoComplete={ 'off' }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={ 12 }>
                                        <FormControl fullWidth required variant={ 'outlined' }>
                                            <InputLabel>{ t('shared.link-text') }</InputLabel>
                                            <OutlinedInput
                                                type={ 'text' }
                                                onChange={ onLinkTextChange }
                                                label={ t('shared.link-text') }
                                                value={ linkText }
                                                autoComplete={ 'off' }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={ onAddLink }>{ t('shared.validate') }</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </div>
                </ClickAwayListener>
            }
            {
                toolbars.includes('format') &&
                <>
                    <Divider orientation={ 'vertical' } flexItem/>
                    <IconButton className={ clsx({ ['ft-editorActive']: currentStyle.has('BOLD') }) } disableRipple onMouseDown={ onToggleInlineStyle('BOLD') }>
                        <FormatBold/>
                    </IconButton>
                    <IconButton className={ clsx({ ['ft-editorActive']: currentStyle.has('ITALIC') }) } disableRipple onMouseDown={ onToggleInlineStyle('ITALIC') }>
                        <FormatItalic/>
                    </IconButton>
                    <IconButton className={ clsx({ ['ft-editorActive']: currentStyle.has('UNDERLINE') }) } disableRipple onMouseDown={ onToggleInlineStyle('UNDERLINE') }>
                        <FormatUnderlined/>
                    </IconButton>
                    <IconButton className={ clsx({ ['ft-editorActive']: currentStyle.has('STRIKETHROUGH') }) } disableRipple onMouseDown={ onToggleInlineStyle('STRIKETHROUGH') }>
                        <StrikethroughS/>
                    </IconButton>
                </>
            }
            {
                toolbars.includes('image') &&
                <>
                    <Divider orientation={ 'vertical' } flexItem/>
                    <IconButton disableRipple onMouseDown={ onAddPicture }>
                        <PhotoLibrary/>
                    </IconButton>
                </>
            }
            {
                toolbars.includes('lists') &&
                <>
                    <IconButton className={ clsx({ ['ft-editorActive']: blockType === 'unordered-list-item' }) } disableRipple onMouseDown={ onToggleBlockType('unordered-list-item') }>
                        <FormatListBulleted/>
                    </IconButton>
                    <IconButton className={ clsx({ ['ft-editorActive']: blockType === 'ordered-list-item' }) } disableRipple onMouseDown={ onToggleBlockType('ordered-list-item') }>
                        <FormatListNumbered/>
                    </IconButton>
                </>
            }
            {
                toolbars.includes('undo') &&
                <>
                    <Divider orientation="vertical" flexItem />
                    <Tooltip title={`${t('shared.undo')} (Ctrl + z)`}>
                        <IconButton onMouseDown={onUndo} disableRipple>
                            <Undo />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={`${t('shared.redo')} (Ctrl + y)`}>
                        <IconButton onMouseDown={onRedo} disableRipple>
                            <Redo />
                        </IconButton>
                    </Tooltip>
                </>
            }
        </Grid>
    );
}
