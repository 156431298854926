// Dependencies
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Core
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({

});

const CartManualProductFormFlight = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {

    }, []);
    return (
        <div/>
    );
};

export default CartManualProductFormFlight;