//---- Actions ----//
import { SetItinerary, SelectTransportFlag, SetJourneyType, SetAirportError, SetFillFlag } from "../../../Actions/Itinerary";

//---- Commons ----//
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";

//---- Functions ----//
import GetBiggestSegmentVehicle from './GetBiggestSegmentVehicle.js';
import SetHourOfDestination from './SetHourOfDestination.js';

export default(selected_route, data, index, itinerary_list, dispatch, t, journey_type) => {
    let plane = null;
    let biggest_transport_distance = selected_route.segments[0];
    if (data.vehicles[biggest_transport_distance.vehicle].kind === "plane") {
    // if (data.vehicles[biggest_transport_distance.vehicle].kind === "plane" || data.vehicles[biggest_transport_distance.vehicle].kind === "car") {
        plane = selected_route.segments[0];
    } else {
        plane = null;
    }
    for (let i = 1; i < selected_route.segments.length; i++) {
        if (biggest_transport_distance.distance < selected_route.segments[i].distance) {
            biggest_transport_distance = selected_route.segments[i];
            if (data.vehicles[biggest_transport_distance.vehicle].kind === "plane") {
            // if (data.vehicles[biggest_transport_distance.vehicle].kind === "plane" || data.vehicles[biggest_transport_distance.vehicle].kind === "car") {
                plane = selected_route.segments[i];
            } else {
                plane = null;
            }
        }
    }
    let h = null;
    let m = null;
    if (plane !== null) {
        h = Math.floor(plane.transitDuration / 60);
        m = plane.transitDuration % 60;
        h = h < 10 ? "0" + h : h;
        m = m < 10 ? "0" + m : m;
    } else {
        h = Math.floor(selected_route.totalDuration / 60);
        m = selected_route.totalDuration % 60;
        h = h < 10 ? "0" + h : h;
        m = m < 10 ? "0" + m : m;
    }
    let tmp_obj = {
        "data": data.data,
        "distance": selected_route.distance,
        "distance_string": parseInt(selected_route.distance).toLocaleString().replace(",", " ") + " km",
        "duration": selected_route.totalDuration,
        "duration_string": h + "h" + m,
        "id": selected_route.distance + Math.random(),
        "name_string": (GetBiggestSegmentVehicle(data.data.vehicles, selected_route).kind === "plane" ? t("global.flight") : selected_route.name),
        "segments": selected_route.segments,
        "type": 2,
        "vehicle": GetBiggestSegmentVehicle(data.data.vehicles, selected_route),
        "vehicles": data.data.vehicles,
        "selected": (plane === null)
    };
    let plane_departure_time = null;
    let plane_arrival_time = null;
    let add_days = 0;
    if (plane !== null) {
        if (plane.outbound !== undefined) {
            plane_departure_time = plane.outbound[0].hops[0].depTime.split(":");
            plane_arrival_time = plane.outbound[0].hops[plane.outbound[0].hops.length - 1].arrTime.split(":");
            for (let i = 0; i < plane.outbound[0].hops.length; i++) {
                if (plane.outbound[0].hops[i].dayChange !== undefined) {
                    add_days += plane.outbound[0].hops[i].dayChange;
                }
            }
        } else {
            plane = null;
        }
    }
    let destination_end_date = null;
    let destination_start_date = null;
    if (plane !== null) {
        destination_end_date = moment.utc(itinerary_list[index].end_date).set({"hour": plane_departure_time[0], "minute": plane_departure_time[1]});
        destination_start_date = moment.utc(itinerary_list[index].end_date).set({"hour": plane_departure_time[0], "minute": plane_departure_time[1]}).add(add_days, "d").set({"hour": plane_arrival_time[0], "minute": plane_arrival_time[1]});
    } else {
        destination_end_date = moment.utc(itinerary_list[index].end_date).format("YYYY-MM-DD") === moment.utc(itinerary_list[index].start_date).format("YYYY-MM-DD") ? moment.utc(itinerary_list[index].end_date) : moment.utc(itinerary_list[index].end_date).set({"hour": "09", "minute": "00"});
        destination_start_date = moment.utc(itinerary_list[index].end_date).format("YYYY-MM-DD") === moment.utc(itinerary_list[index].start_date).format("YYYY-MM-DD") ? moment.utc(itinerary_list[index].end_date).add(selected_route.totalDuration, "minutes") : moment.utc(itinerary_list[index].end_date).set({"hour": "09", "minute": "00"}).add(selected_route.totalDuration, "minutes");
    }
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        let request = {
            end_date: destination_end_date.format(),
            r2r_json : tmp_obj,
            selected_transport: selected_route.totalDuration + "-" + selected_route.distance
        };
        dispatch(SetAirportError(false, null));
        dispatch(SetFillFlag(null));
        dispatch(SelectTransportFlag("fetching"));
        $.ajax({
            method: "PATCH",
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/${itinerary_list[index].id}/?token=${GetCookie("trip_token")}&shift_mode=false`,
            headers: headers,
            data: JSON.stringify(request),
            success: function (data) {
                dispatch(SetItinerary(data));
                dispatch(SelectTransportFlag("fetched"));
                if (journey_type) {
                    dispatch(SetJourneyType(journey_type));
                }
                let request_2 = {
                    start_date: destination_start_date.format()
                };
                if (moment.utc(itinerary_list[index + 1].end_date).diff(destination_start_date, "minutes") < 10) {
                    request_2.end_date = destination_start_date.add(moment.utc(itinerary_list[index + 1].end_date).diff(itinerary_list[index + 1].start_date, "days"), "days").format();
                }
                if (request_2.end_date !== undefined && moment.utc(request_2.end_date).diff(destination_start_date, "minutes") < 10) {
                    request_2.end_date = destination_start_date.add(10, "minutes").format();
                }
                $.ajax({
                    method: "PATCH",
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/${itinerary_list[index + 1].id}/?token=${GetCookie("trip_token")}&shift_mode=true`,
                    headers: headers,
                    data: JSON.stringify(request_2),
                    success: function (data) {
                        dispatch(SetItinerary(data));
                    }
                });
            }
        });
    }
}