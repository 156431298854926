//---- Dependencies ----//
let GoogleMapsLoader = require('google-maps');

import React from 'react';
import ReactDOM from "react-dom";

export default (data, itinerary) => {
    console.log('itinerary:', itinerary);
    let map = null;
    let key = "";
    let config = JSON.parse(localStorage.getItem("config"));
    for (let i = 0; i < config.keys.length; i++) {
        if (config.keys[i].identifier === "google_api") {
            key = config.keys[i].value;
        }
    }
    let address = data.destination.data.international_name;
    GoogleMapsLoader.KEY = key;
    GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];
    GoogleMapsLoader.LANGUAGE = "fr";
    GoogleMapsLoader.load(function(google) {
        if (document.getElementById('coverImgMap') !== null) {
            let options = {
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                center: {lat: parseFloat(0), lng: parseFloat(0)},
                zoom: 7,
                draggable: true,
                clickableIcons: false,
                disableDefaultUI: true,
                zoomControl: true
            };
            map = new google.maps.Map(document.getElementById('coverImgMap'), options);
            let geocoder = new google.maps.Geocoder();
            geocoder.geocode({'address': address}, function(results, status) {
                if (status === 'OK') {
                    map.setCenter(results[0].geometry.location)
                } else {
                    console.log('Geocode was not successful for the following reason: ', status);
                    console.log('address:', address);
                }
           });
        }
    });
};
