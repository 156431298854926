const getClientPrice = (prices, is_tva = true) => {
    if (is_tva) {
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].is_tva) {
                return prices[i];
            }
        }
    }
    for (let i = 0; i < prices.length; i++) {
        if (prices[i].is_surcom) {
            return prices[i];
        }
    }
    for (let i = 0; i < prices.length; i++) {
        if (prices[i].master_price) {
            return prices[i];
        }
    }
    // for (let i = 0; i < prices.length; i++) {
    //     if (prices[i].owner === user.client) {
    //         return prices[i];
    //     }
    // }
}

export default (flight_cart, hotel_cart, manual_cart, poi_cart, cars_cart, transfers_cart, trip_info, hidden_product) => {
    let total_flight_price = 0;
    let total_accommodation_price = 0;
    let total_manual_price = 0;
    let total_cars_price = 0;
    let total_poi_price = 0;
    let taxes = 0;
    if (trip_info !== null && (trip_info.prices_flight.length > 0 || trip_info.prices_terrestrial.length > 0)) {
        if (trip_info.prices_flight.length > 0) {
            total_flight_price += parseFloat(getClientPrice(trip_info.prices_flight).selling_price);
        }
        if (trip_info.prices_terrestrial.length > 0) {
            total_accommodation_price += parseFloat(getClientPrice(trip_info.prices_terrestrial).selling_price);
        }
        if (trip_info.flight_taxes !== null && parseFloat(trip_info.flight_taxes) > 0) {
            taxes = parseFloat(trip_info.flight_taxes);
        }
    }
    else {
        for (let i = 0; i < flight_cart.length; i++) {
            if (flight_cart[i].prices && flight_cart[i].prices.length > 0 && (flight_cart[i].is_available !== undefined && flight_cart[i].is_available !== false) && (flight_cart[i].booking_status === null || (flight_cart[i].booking_status !== null && flight_cart[i].booking_status.status_booking !== "CANCELLED"))) {
                let more_option = 0;
                flight_cart[i].outbounds.map((outbound) => {
                    outbound.legs.map((leg) => {
                        if (leg.paid_options.length > 0) {
                            leg.paid_options.map((option) => {
                                more_option += parseFloat(option.price);
                            });
                        }
                    });
                });
                if (flight_cart[i].taxes !== undefined && flight_cart[i].taxes !== null) {
                    let master_price = flight_cart[i].prices.find((price) => price.master_price);
                    taxes += parseFloat(flight_cart[i].taxes) * (master_price !== undefined ? parseFloat(master_price.factor_used) : 1);;
                }
                total_flight_price += parseFloat(getClientPrice(flight_cart[i].prices).selling_price) + parseFloat(more_option);
            }
        }
        for (let i = 0; i < hotel_cart.length; i++) {
            if (hotel_cart[i].prices && hotel_cart[i].prices.length > 0 && (hotel_cart[i].is_available !== undefined && hotel_cart[i].is_available !== false) && (hotel_cart[i].booking_status === null || (hotel_cart[i].booking_status !== null && hotel_cart[i].booking_status.status_booking !== "CANCELLED"))) {
                total_accommodation_price += parseFloat(getClientPrice(hotel_cart[i].prices).selling_price);
                if (hotel_cart[i].rooms !== undefined && hotel_cart[i].rooms.length > 0) {
                    hotel_cart[i].rooms.map((room) => {
                        if (room.prices !== undefined) {
                            total_accommodation_price += parseFloat(getClientPrice(room.prices).selling_price);
                        }
                    })
                }
                if (hotel_cart[i].taxes !== undefined && hotel_cart[i].taxes !== null) {
                    taxes += parseFloat(hotel_cart[i].taxes);
                }
            }
        }
        for (let i = 0; i < manual_cart.length; i++) {
            if (manual_cart[i].prices) {
                if (manual_cart[i].product_type === 0 || manual_cart[i].product_type === 7) {
                    if (manual_cart[i].taxes !== null) {
                        taxes += parseFloat(manual_cart[i].taxes);
                    }
                    total_accommodation_price += parseFloat(getClientPrice(manual_cart[i].prices).selling_price);
                } else if (manual_cart[i].product_type === 6) {
                    if (manual_cart[i].taxes !== null) {
                        taxes += parseFloat(manual_cart[i].taxes);
                    }
                    total_flight_price += parseFloat(getClientPrice(manual_cart[i].prices).selling_price);
                } else {
                    if (manual_cart[i].taxes !== null) {
                        total_manual_price += parseFloat(manual_cart[i].taxes);
                        taxes += parseFloat(manual_cart[i].taxes);
                    }
                    total_manual_price += parseFloat(getClientPrice(manual_cart[i].prices).selling_price);
                }
            }
        }
        for (let i = 0; i < poi_cart.length; i++) {
            if (poi_cart[i].prices) {
                total_poi_price += parseFloat(getClientPrice(poi_cart[i].prices).selling_price);
            }
        }
        for (let i = 0; i < cars_cart.length; i++) {
            if (cars_cart[i].prices !== null && cars_cart[i].prices.length > 0 && (cars_cart[i].is_available !== undefined && cars_cart[i].is_available !== false) && (cars_cart[i].booking_status === null || (cars_cart[i].booking_status !== null && cars_cart[i].booking_status.status_booking !== "CANCELLED"))) {
                total_cars_price += parseFloat(getClientPrice(cars_cart[i].prices).selling_price);
                if (cars_cart[i].taxes !== undefined && cars_cart[i].taxes !== null) {
                    taxes += parseFloat(cars_cart[i].taxes);
                }
            }
        }
        for (let i = 0; i < transfers_cart.length; i++) {
            if (transfers_cart[i].prices !== null && transfers_cart[i].prices.length > 0 && (transfers_cart[i].is_available !== undefined && transfers_cart[i].is_available !== false) && (transfers_cart[i].booking_status === null || (transfers_cart[i].booking_status !== null && transfers_cart[i].booking_status.status_booking !== "CANCELLED"))) {
                total_cars_price += parseFloat(getClientPrice(transfers_cart[i].prices).selling_price);
            }
        }
        for (let i = 0; i < hidden_product.length; i++) {
            if (hidden_product[i].prices !== null && hidden_product[i].prices.length > 0 && (hidden_product[i].is_available !== undefined && hidden_product[i].is_available !== false) && (hidden_product[i].booking_status === null || (hidden_product[i].booking_status !== null && hidden_product[i].booking_status.status_booking !== "CANCELLED"))) {
                total_manual_price += parseFloat(getClientPrice(hidden_product[i].prices).selling_price);
            }
        }
    }
    let cost = 0;
    if (trip_info !== null && trip_info.prices_stack_product.length > 0) {
        cost = parseFloat(getClientPrice(trip_info.prices_stack_product).selling_price);
    }
    else {
        cost = parseFloat(total_flight_price + total_accommodation_price + total_manual_price + total_cars_price + total_poi_price + taxes);
    }
    let total_price = {
        total_journey: parseFloat(total_accommodation_price + total_cars_price + total_manual_price + total_poi_price).toFixed(2),
        total: cost,
        total_flight: parseFloat(total_flight_price).toFixed(2),
        taxes: parseFloat(taxes).toFixed(2)
    }
    return total_price;
}