import { CreatePageOptions } from "../objects/createPageOptions";
import { Template } from "../utils/handlebars/roadbook.handlebars";
import TrainCategoryIcon from "../../../../Img/noun-train-4560436 1.png?roadbook";
import CarCategoryIcon from "../../../../Img/noun-car-1777740-7.png?roadbook";
import TransferCategoryIcon from "../../../../Img/noun-driver-1574358-1.png?roadbook";
import CruiseCategoryIcon from "../../../../Img/roadbook/noun-boat-4920716 1.png?roadbook";
import LocationIcon from "../../../../Img/noun-location-4973187-7.png?roadbook";
import CalendarIcon from "../../../../Img/noun-calendar-4039454-5.png?roadbook";

export function createOtherTransportsContent(options: CreatePageOptions): Parameters<Template>[0]['contents'][0] | null {
    return {
        type: 'other-transports',
        anchor: 'other-transports',
        showInSummary: false,
        summaryTitle: options.title,
        title: options.title,
        categories: [
            {
                url: CarCategoryIcon,
                title: options.t('roadbook.other-transports-included-cars'),
                items: options.cart.cars.filter((item) => {
                    return !item.car.is_optional;
                }).sort((a, b) => {
                    return window.moment.utc(a.car.start_date).isBefore(window.moment.utc(b.car.start_date)) ? -1 : 1;
                }).map((item) => {
                    const includedInPrice = item.type === 'normal' ?
                        item.car.included_service.filter((item) => {
                            return item.included;
                        }) :
                        [];
                    const notIncludedInPrice = item.type === 'normal' ?
                        item.car.included_service.filter((item) => {
                            return !item.included;
                        }) :
                        [];
                    return {
                        title: item.car.localization?.find((item) => {
                            return item.locale === options.locale;
                        })?.name ?? item.car.name,
                        sections: [
                            {
                                title: options.t('roadbook.other-transports-rental-details'),
                                items: [
                                    {
                                        url: LocationIcon,
                                        text: options.t(
                                            'roadbook.other-transports-car-pickup-agency',
                                            { name: item.car.station_name_pickup }
                                        )
                                    },
                                    {
                                        url: CalendarIcon,
                                        text: options.t(
                                            'roadbook.other-transports-car-pickup-date',
                                            {
                                                date: window.moment.utc(item.car.start_date).format('DD/MM'),
                                                hourly: window.moment.utc(item.car.start_date).format('HH[h]mm')
                                            }
                                        )
                                    },
                                    {
                                        url: LocationIcon,
                                        text: options.t(
                                            'roadbook.other-transports-car-return-agency',
                                            { name: item.car.station_name_return }
                                        )
                                    },
                                    {
                                        url: CalendarIcon,
                                        text: options.t(
                                            'roadbook.other-transports-car-return-date',
                                            {
                                                date: window.moment.utc(item.car.end_date).format('DD/MM'),
                                                hourly: window.moment.utc(item.car.end_date).format('HH[h]mm')
                                            }
                                        )
                                    }
                                ],
                                note: item.car.custom_information?.replace(/\n/g, '<br />') ?? undefined
                            }
                        ],
                        includedInPrice: includedInPrice.length > 0 ?
                            includedInPrice.map((item) => item.title) :
                            undefined,
                        notIncludedInPrice: notIncludedInPrice.length > 0 ?
                            notIncludedInPrice.map((item) => item.title) :
                            undefined
                    };
                })
            },
            {
                url: TransferCategoryIcon,
                title: options.t('roadbook.transfer-category'),
                items: options.cart.transfers.filter((item) => {
                    return !item.transfer.is_optional;
                }).sort((a, b) => {
                    return window.moment.utc(a.transfer.start_date).isBefore(window.moment.utc(b.transfer.start_date)) ? -1 : 1;
                }).map((item) => {
                    const adults = item.transfer.group_passenger?.travelers_list.filter((traveler) => {
                        return window.moment().diff(window.moment.utc(traveler.birth_date), 'years') >= 18;
                    }) ?? [];
                    const children = item.transfer.group_passenger?.travelers_list.filter((traveler) => {
                        return window.moment().diff(window.moment.utc(traveler.birth_date), 'years') < 18;
                    }) ?? [];
                    return {
                        title: options.t(
                            'roadbook.transfer-car-with-driver-no',
                            {
                                no: [
                                    adults.length > 0 ?
                                        options.t('roadbook.adults-count', { count: adults.length }) :
                                        null,
                                    children.length > 0 ?
                                        options.t('roadbook.children-count', { count: children.length }) :
                                        null
                                ].filter((item) => item).join(', ')
                            }
                        ),
                        sections: [
                            {
                                items: [
                                    {
                                        url: LocationIcon,
                                        text: options.t(
                                            'roadbook.transfer-car-pickup-agency',
                                            {
                                                name: item.transfer.station_name_pickup ??
                                                    item.transfer.address_pickup
                                            }
                                        )
                                    },
                                    {
                                        url: CalendarIcon,
                                        text: options.t(
                                            'roadbook.transfer-car-pickup-date',
                                            {
                                                date: window.moment.utc(item.transfer.start_date).format('DD/MM'),
                                                hourly: window.moment.utc(item.transfer.start_date).format('HH[h]mm')
                                            }
                                        )
                                    },
                                    {
                                        url: LocationIcon,
                                        text: options.t(
                                            'roadbook.transfer-car-return-agency',
                                            {
                                                name: item.transfer.station_name_return ??
                                                    item.transfer.address_return
                                            }
                                        )
                                    }
                                ],
                                note: item.transfer.custom_information?.replace(/\n/g, '<br />') ?? undefined
                            }
                        ]
                    };
                })
            },
            {
                url: TrainCategoryIcon,
                title: options.t('roadbook.train-category'),
                items: options.cart.trains.filter((item) => {
                    return !item.train.is_optional;
                }).sort((a, b) => {
                    return window.moment.utc(a.train.start_date).isBefore(window.moment.utc(b.train.start_date)) ? -1 : 1;
                }).map((item) => {
                    return {
                        title: item.train.localization?.find((item) => {
                            return item.locale === options.locale;
                        })?.name ?? item.train.name,
                        sections: [
                            {
                                items: [
                                    {
                                        url: CalendarIcon,
                                        text: options.t(
                                            'roadbook.train-pickup-date',
                                            {
                                                date: window.moment.utc(item.train.start_date).format('DD/MM'),
                                                hourly: window.moment.utc(item.train.start_date).format('HH[h]mm')
                                            }
                                        )
                                    },
                                    {
                                        url: CalendarIcon,
                                        text: options.t(
                                            'roadbook.train-return-date',
                                            {
                                                date: window.moment.utc(item.train.end_date).format('DD/MM'),
                                                hourly: window.moment.utc(item.train.end_date).format('HH[h]mm')
                                            }
                                        )
                                    }
                                ],
                                note: item.train.custom_information?.replace(/\n/g, '<br />') ?? undefined
                            }
                        ]
                    };
                })
            },
            {
                url: CruiseCategoryIcon,
                title: options.t('roadbook.cruise-category'),
                items: options.cart.cruises.filter((item) => {
                    return !item.cruise.is_optional;
                }).sort((a, b) => {
                    return window.moment.utc(a.cruise.start_date).isBefore(window.moment.utc(b.cruise.start_date)) ? -1 : 1;
                }).map((item) => {
                    return {
                        title: item.cruise.localization?.find((item) => {
                            return item.locale === options.locale;
                        })?.name ?? item.cruise.name,
                        sections: [
                            {
                                items: [
                                    {
                                        url: CalendarIcon,
                                        text: options.t(
                                            'roadbook.cruise-pickup-date',
                                            {
                                                date: window.moment.utc(item.cruise.start_date).format('DD/MM'),
                                                hourly: window.moment.utc(item.cruise.start_date).format('HH[h]mm')
                                            }
                                        )
                                    },
                                    {
                                        url: CalendarIcon,
                                        text: options.t(
                                            'roadbook.cruise-return-date',
                                            {
                                                date: window.moment.utc(item.cruise.end_date).format('DD/MM'),
                                                hourly: window.moment.utc(item.cruise.end_date).format('HH[h]mm')
                                            }
                                        )
                                    }
                                ],
                                note: item.cruise.custom_information?.replace(/\n/g, '<br />') ?? undefined
                            }
                        ]
                    };
                }).concat(
                    options.cart.ferries.filter((item) => {
                        return !item.ferry.is_optional;
                    }).map((item) => {
                        return {
                            title: item.ferry.localization?.find((item) => {
                                return item.locale === options.locale;
                            })?.name ?? item.ferry.name,
                            sections: [
                                {
                                    items: [
                                        {
                                            url: CalendarIcon,
                                            text: options.t(
                                                'roadbook.ferry-pickup-date',
                                                {
                                                    date: window.moment.utc(item.ferry.start_date).format('DD/MM'),
                                                    hourly: window.moment.utc(item.ferry.start_date).format('HH[h]mm')
                                                }
                                            )
                                        },
                                        {
                                            url: CalendarIcon,
                                            text: options.t(
                                                'roadbook.ferry-return-date',
                                                {
                                                    date: window.moment.utc(item.ferry.end_date).format('DD/MM'),
                                                    hourly: window.moment.utc(item.ferry.end_date).format('HH[h]mm')
                                                }
                                            )
                                        }
                                    ],
                                    note: item.ferry.custom_information?.replace(/\n/g, '<br />') ?? undefined
                                }
                            ]
                        };
                    })
                )
            }
        ]
    };
}
