import React from "react";
import {
    Box,
    SvgIcon,
    SvgIconProps
} from "@mui/material";

export function ItineraryBorderedHomeIcon(): JSX.Element {
    return (
        <Box sx={{ position: 'relative' }}>
            <Home
                htmlColor="#2C98F0"
                fontSize="inherit"
                sx={{ position: 'absolute', top: 0, left: 0 }}
            />
            <Background
                fontSize="inherit"
                sx={{ position: 'absolute', top: 0, left: 0 }}
            />
        </Box>
    );
}

type BackgroundProps = SvgIconProps

function Background(props: BackgroundProps): JSX.Element {
    return (
        <SvgIcon
            {...props}
            viewBox="0 -7.4 20.15 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="1"
                d="M0.416656 8.95541V24.25H4.24999V12.5H15.75V24.25H19.5833V8.95541L9.99999 0.142914L0.416656 8.95541Z"
                fill="#C0E1FB"
            />
        </SvgIcon>
    );
}

function Home(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon
            {...props}
            viewBox="0 0 40 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20 0.875L0.833344 18.5H6.58334V34.1667H18.0833V22.4167H21.9167V34.1667H33.4167V18.5H39.1667L20 0.875ZM29.5833 30.25H25.75V18.5H14.25V30.25H10.4167V14.9554L20 6.14292L29.5833 14.9554V30.25Z"
                fill="#2C98F0"
            />
        </SvgIcon>
    );
}
