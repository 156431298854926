import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import WarningIcon from '@material-ui/icons/Warning';
import moment from 'moment';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A",
        letterSpacing: 1.25
    },
    fontWeight500:{
        fontWeight: 500
    },
    fontSize16:{
        fontSize: 16
    },
    textCenter: {
        textAlign: "center"
    },
    genericPaddingTop: {
        paddingTop: 20
    },
    returnButton: {
        color: "#0000008A",
        backgroundColor: "#FFFFFF",
    },
    genericButton: {
        color: "#FFFFFF",
        backgroundColor: `${main_color}`
    },
    fullWidth: {
        width: "100%"
    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AlreadyInCart = ({ alreadyInCart, setAlreadyInCart, journey_start_date, setCancelSearch }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const smallScreen = useMediaQuery('(max-width:425px)');

    const cancelSearch = () => {
        setCancelSearch(true);
        setAlreadyInCart(false);
    }

    const continueSearch = () => {
        setCancelSearch(false);
        setAlreadyInCart(false);
    }

    return(
        <Dialog open={alreadyInCart} fullScreen={smallScreen ? true : false} TransitionComponent={Transition} maxWidth={"sm"}>
            <DialogContent dividers>
                <Grid className={classes.textCenter}>
                    <WarningIcon style={{color: "orange", fontSize: 110}} />
                </Grid>
                <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize16}`}>
                    { t("flight_groups.already_in_cart") + moment.utc(journey_start_date).format("DD/MM/YYYY") }
                </Grid>
                <Grid container direction={smallScreen ? "column" : "row"} alignItems={smallScreen ? "flex-start" : "center"} justify="space-evenly" spacing={smallScreen ? 2 : 0} className={classes.genericPaddingTop}>
                    <Grid item className={smallScreen ? `${classes.fullWidth} ${classes.textCenter}` : ""}>
                        <Button variant="contained" className={classes.returnButton} onClick={cancelSearch}>
                            { t("global.cancel") }
                        </Button>
                    </Grid>
                    <Grid item className={smallScreen ? `${classes.fullWidth} ${classes.textCenter}` : ""}>
                        <Button variant="contained" className={classes.genericButton} onClick={continueSearch}>
                            { t("global.continue") }
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default React.memo(AlreadyInCart);