import { flatten } from "lodash";
import { TFunction } from "i18next";
import { useCartProducts } from "../../../Itinerary/network/cartProducts";

export function transformAccommodationsToProgramDetailsItems(
    options: {
        locale: number | null,
        cart: ReturnType<typeof useCartProducts>,
        startDate: string,
        endDate: string,
        t: TFunction
    }
) {
    const accommodations = options.cart.accommodations.filter((item) => {
        return !item.accommodation.is_optional &&
        (
            window.moment.utc(item.accommodation.start_date).isBetween(
                window.moment.utc(options.startDate),
                window.moment.utc(options.endDate),
                'days',
                '[)'
            ) ||
            (
                window.moment.utc(item.accommodation.end_date).startOf('day').diff(
                    window.moment.utc(item.accommodation.start_date).startOf('day'),
                    'days'
                ) > 0 &&
                window.moment.utc(item.accommodation.end_date).startOf('day').subtract(
                    1,
                    'days'
                ).isBetween(
                    window.moment.utc(options.startDate),
                    window.moment.utc(options.endDate),
                    'days',
                    '[)'
                )
            )
        );
    }).sort((a, b) => {
        const aStartDate = window.moment.utc(a.accommodation.start_date);
        const bStartDate = window.moment.utc(b.accommodation.start_date);
        return aStartDate.isBefore(bStartDate) ? -1 : 1;
    });

    return flatten(
        accommodations.map((item) => {
            const address = item.type === 'normal' ?
                //eslint-disable-next-line max-len
                `${ item.accommodation.hotel[0]?.house_number ?? 'None' } - ${ item.accommodation.hotel[0]?.street ?? 'None' } - ${ item.accommodation.hotel[0]?.zip_code ?? 'None' } - ${ item.accommodation.hotel[0]?.city_name ?? 'None' } - ${ item.accommodation.hotel[0]?.destination_name ?? 'None' } - ${ item.accommodation.hotel[0]?.country_code ?? 'None' }` :
                item.accommodation.address ?? '';

            const room = (item.accommodation.rooms ?? [])[0];
            const pension = [
                room?.breakfast_included ? options.t('accommodation.breakfast_included') : null,
                room?.lunch_included ? options.t('accommodation.lunch_included') : null,
                room?.dinner_included ? options.t('accommodation.dinner_included') : null,
                room?.all_inclusive ? options.t('cart-material.manual-product-room-all-inclusive') : null
            ].filter((item): item is string => !!item);

            return {
                title: item.type === 'normal' ?
                    item.accommodation.localization.find((item) => {
                        return item.locale === options.locale;
                    })?.name ??
                    item.accommodation.hotel[0]?.name ??
                    '' :
                    item.accommodation.localization?.find((item) => {
                        return item.locale === options.locale;
                    })?.name ??
                    item.accommodation.name,
                ref: item.type === 'normal' ?
                    item.accommodation.booking_status?.item_reference ?? undefined :
                    undefined,
                date: options.t(
                    'roadbook.program-details-accommodation-date',
                    {
                        from: window.moment.utc(item.accommodation.start_date).format('DD/MM'),
                        to: window.moment.utc(item.accommodation.end_date).format('DD/MM'),
                        nightsCount: window.moment.utc(item.accommodation.end_date).startOf('day').diff(
                            window.moment.utc(item.accommodation.start_date).startOf('day'),
                            'day'
                        )
                    }
                ),
                address,
                tel: item.type === 'normal' ?
                    item.accommodation.customer_service_phone ?? undefined :
                    undefined,
                type: (
                    item.type === 'normal' ?
                        item.accommodation.rooms.map((item) => {
                            return item.description;
                        }) :
                        item.accommodation.rooms?.map((item) => {
                            return item.name;
                        }) ?? []
                ).filter((item) => item.length > 0),
                pension,
                note: item.accommodation.custom_information?.replace(/\n/g, '<br />') ?? undefined
            };
        })
    );
}
