import ButtonBase from "@material-ui/core/ButtonBase";
import React, { Fragment } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Skeleton from "@material-ui/lab/Skeleton";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
    firstTile: {
        "& div": {
            [theme.breakpoints.up("md")]: {
                borderRadius: "12px 0 0 12px"
            }
        }
    },
    topRightTile: {
        "& div": {
            [theme.breakpoints.up("md")]: {
                borderTopRightRadius: "12px"
            }
        }
    },
    bottomRightTile: {
        "& div": {
            [theme.breakpoints.up("md")]: {
                borderBottomRightRadius: "12px"
            }
        }
    },
    noPaddingLeft: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: "4px !important"
        }
    },
    noPaddingRight: {
        [theme.breakpoints.up("md")]: {
            paddingRight: "4px !important"
        }
    },
    alignCenter: {
        textAlign: "center"
    },
    pointer: {
        cursor: "pointer"
    },
    button: {
        width: "100%",
        height: "100%",
        backgroundColor: "black"
    },
    noPaddingTop: {
        [theme.breakpoints.down("sm")]: {
            paddingTop: '0 !important'
        }
    }
}));

const AccommodationCardHeader = ({detail, pictures, togglePictureModal, toggleMosaicModal, size}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.down('sm'));
    const laptop = useMediaQuery(theme.breakpoints.down('lg'));
    let filter_pictures = [];
    if (detail !== null) {
        if (detail.overload_pictures !== undefined && detail.overload_pictures.length !== 0) {
            filter_pictures = detail.overload_pictures.filter(picture => picture.url !== '');
        } else {
            if (detail.custom_pictures !== undefined && detail.custom_pictures !== null && detail.custom_pictures.length > 0) {
                filter_pictures = detail.custom_pictures.filter(picture => picture.url !== '');
            } else {
                filter_pictures = detail.pictures.filter(picture => picture.url !== '');
            }
        }
    }
    return (
        <Fragment>
            <Grid item xs={ 12 } md={ 6 } className={ `${classes.noPaddingRight} ${classes.noPaddingTop}` }>
                <Hidden xsUp={ detail !== null }>
                    <GridList cellHeight={ /*laptop ? 184 : */size } cols={ 2 }>
                        <GridListTile cols={ 2 } rows={ 2 } className={ classes.firstTile }>
                            <Skeleton variant={ "rect" } height={ "100%" }/>
                        </GridListTile>
                    </GridList>
                </Hidden>
                <Hidden xsUp={ detail === null }>
                    <GridList cellHeight={ /*laptop ? 184 : */size } cols={ 2 }>
                        <GridListTile cols={ 2 } rows={ 2 } className={ classes.firstTile }>
                            <ButtonBase className={ classes.button } onClick={() => { if (!tablet) { togglePictureModal(filter_pictures); } else { toggleMosaicModal(filter_pictures); } }}>
                                <img src={ pictures[0] } alt={ "main picture" } height={ "100%" }/>
                            </ButtonBase>
                        </GridListTile>
                    </GridList>
                </Hidden>
            </Grid>
            <Hidden smDown>
                <Grid item xs={ false } md={ 6 } className={ classes.noPaddingLeft }>
                    <Hidden xsUp={ detail !== null }>
                        <GridList cellHeight={ /*laptop ? 180 : */size - 4 } cols={ 2 } spacing={ 8 }>
                            <GridListTile cols={ 1 } rows={ 1 }>
                                <Skeleton variant={ "rect" } height={ "100%" }/>
                            </GridListTile>
                            <GridListTile cols={ 1 } rows={ 1 } className={ classes.topRightTile }>
                                <Skeleton variant={ "rect" } height={ "100%" }/>
                            </GridListTile>
                            <GridListTile cols={ 1 } rows={ 1 }>
                                <Skeleton variant={ "rect" } height={ "100%" }/>
                            </GridListTile>
                            <GridListTile cols={ 1 } rows={ 1 } className={ classes.bottomRightTile }>
                                <Skeleton variant={ "rect" } height={ "100%" }/>
                            </GridListTile>
                        </GridList>
                    </Hidden>
                    <Hidden xsUp={ detail === null }>
                        <GridList cellHeight={ /*laptop ? 180 : */size - 4 } cols={ 2 } spacing={ 8 }>
                            <GridListTile cols={ 1 } rows={ 1 }>
                                <ButtonBase className={ classes.button } onClick={() => { togglePictureModal(filter_pictures); }}>
                                    <img src={ pictures[1] } alt={ "second picture" } height={ "100%" }/>
                                </ButtonBase>
                            </GridListTile>
                            <GridListTile cols={ 1 } rows={ 1 } className={ classes.topRightTile }>
                                <ButtonBase className={ classes.button } onClick={() => { togglePictureModal(filter_pictures); }}>
                                    <img src={ pictures[2] } alt={ "third picture" } height={ "100%" }/>
                                </ButtonBase>
                            </GridListTile>
                            <GridListTile cols={ 1 } rows={ 1 }>
                                <ButtonBase className={ classes.button } onClick={() => { togglePictureModal(filter_pictures); }}>
                                    <img src={ pictures[3] } alt={ "fourth picture" } height={ "100%" }/>
                                </ButtonBase>
                            </GridListTile>
                            <GridListTile cols={ 1 } rows={ 1 } className={ classes.bottomRightTile }>
                                <ButtonBase className={ classes.button } onClick={() => { togglePictureModal(filter_pictures); }}>
                                    <img src={ pictures[4] } alt={ "fifth picture" } height={ "100%" }/>
                                </ButtonBase>
                                <GridListTileBar title={ t("accommodation.see_all_pictures") } onClick={() => { togglePictureModal(filter_pictures); }} className={ `${classes.alignCenter} ${classes.pointer}` }/>
                            </GridListTile>
                        </GridList>
                    </Hidden>
                </Grid>
            </Hidden>
        </Fragment>
    )
};

export default React.memo(AccommodationCardHeader);