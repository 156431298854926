//---- Dependencies ----//
import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

//---- Common ----//

//---- CSS ----//
import "../../../Style/FlightGroups.css";
import "../../../Style/FlightSearch.css";
import "../../../Style/CarsSearch.css";

//---- Components ----//
import CarsInput from "./CarsInput.jsx";
import CarsResultsHeader from "./CarsResultsHeader.jsx";

//---- Actions ----//
import { SelectedStep, SetStepInfo, ShowInput } from "../../../Actions/CarsSearch";

@connect((store) => {
    return {
    	itinerary_list: store.itinerary.itinerary_list,
    	departure_destination: store.itinerary.departure_destination,
    	return_destination: store.itinerary.return_destination,
    	selected_step: store.cars_search.selected_step,
    	step_info: store.cars_search.step_info,
    }
})
@withTranslation()
export default class CarsSearch extends React.Component {
	componentWillMount() {
		const { dispatch } = this.props;
		dispatch(SetStepInfo([]));
		dispatch(SelectedStep(null));
		dispatch(ShowInput(true));
	}
	componentDidUpdate(prevProps) {
		const {selected_step, departure_destination, return_destination, step_info, itinerary_list, dispatch} = this.props;
		if (selected_step !== prevProps.selected_step && selected_step !== null) {
			let origin = itinerary_list[selected_step].destination !== null ? itinerary_list[selected_step].destination.data.name !== undefined ? itinerary_list[selected_step].destination.data.name.split(",") : itinerary_list[selected_step].destination.data.international_name.split(",") : departure_destination.city;
			let dest = return_destination.city;
			let step = [];
			step.push(
			    {
			        agency_go: null,
			        agency_return: null,
			        start_date_origin: moment.utc(itinerary_list[selected_step].start_date).format("YYYY-MM-DD"),
			        end_date_dest: moment.utc(itinerary_list[selected_step].end_date).format("YYYY-MM-DD"),
			        car_nbr: 1,
			        origin: itinerary_list[selected_step].step_type === "END" ? dest : (typeof(origin) === "string" ? origin : origin[0]),
			        driver_age: ["25"],
			        origin_date: null,
			        return_date: null,
			        origin_hours: (moment.utc(itinerary_list[selected_step].start_date).hours()) + 1 < 10 ? "0" + ((moment.utc(itinerary_list[selected_step].start_date).hours()) + 1).toString() : ((moment.utc(itinerary_list[selected_step].start_date).hours()) + 1).toString(),
			        return_hours: (moment.utc(itinerary_list[selected_step].end_date).hours()) - 3 < 10 ? "0" + ((moment.utc(itinerary_list[selected_step].end_date).hours()) - 3).toString() : ((moment.utc(itinerary_list[selected_step].end_date).hours()) - 3).toString() ,
			        origin_minutes: moment.utc(itinerary_list[selected_step].start_date).minutes() < 10 ? "0" + (moment.utc(itinerary_list[selected_step].start_date).minutes()).toString() : (moment.utc(itinerary_list[selected_step].start_date).minutes()).toString() ,
			        return_minutes: moment.utc(itinerary_list[selected_step].end_date).minutes() < 10 ? "0" + (moment.utc(itinerary_list[selected_step].end_date).minutes()).toString() : (moment.utc(itinerary_list[selected_step].end_date).minutes()).toString(),
			        focus: {
			            origin: false,
			            return: false
			        },
			        cars_errors: [],
			        origin_id: itinerary_list[selected_step].destination.data.destination,
			        dest_id: itinerary_list[selected_step].destination.data.destination,
			        tmp_destination: [],
			        readOnly_arrival: true,
			        readOnly_departure: true,
			        iata_airports_departure: itinerary_list[selected_step].destination.data.iata_airports,
			        iata_airports_arrival: itinerary_list[selected_step].destination.data.iata_airports
			    }
			)
			dispatch(SetStepInfo(step));
		}
	}
	render() {
		const {itinerary_list, departure_destination, return_destination, selected_step, step_info, router, dispatch, t } = this.props;
		return (
			<div id={ "cars-search-wrapper" } className={ "cars-search ft-beige-bg" }>
				<div className={ "cars-title text-center" }>
					<button className="ft-btn-return uppercase ft-white" onClick={() => { router.push(`/${window.url_name}/apps/cars-step`); }}>{t("global.return")}</button>
					<h1>
						{t("cars.main_title_one_step")}
					</h1>
				</div>
				<div className={ "cars-wrapper quarters-3" }>
					<div className={ "cars-steps-details" }>
						<div className={ "ft-basic-card mt20 container-choose-flight block-center text-center" }>
							<div className={"cars-steps"}>
								<div className={ "bold mb25 ft-font-20" }>
									{t("cars.step_title_step")}
									<span className={ "italic font-15" }>{t("cars.step_title_step1")}</span>
								</div>
								{
									itinerary_list.map((step, step_index) => {
										if (step.step_type !== "START" && step.step_type !== "END") {
											let origin = step.destination.data.name !== undefined ? step.destination.data.name.split(",") : step.destination.data.international_name.split(",");
											return (
												<label key={step_index} className={ "flight-search-checkbox" }> {step.step_type === "END" ? dest : (typeof(origin) === "string" ? origin : origin[0])} <span className={ "italic" }>{t("global.from") + " " + moment.utc(step.start_date).format("DD MMMM YYYY") + " " + t("global.to") + " " + moment.utc(step.end_date).format("DD MMMM YYYY")}</span>
												    <input type={ "checkbox" } checked={ step_index ===  selected_step}
												        value={ origin } onChange={() => {dispatch(SelectedStep(step_index))} }/>
												    <span className={ "flight-search-check-mark-cars" }/>
												</label>
											)
										}
									})
								}
							</div>
						</div>
						{
							step_info.length > 0 && (
								<CarsInput current={"oneStep"}/>
							)
						}
					</div>
				</div>
			</div>
		);
	}
}