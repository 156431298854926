var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/eq.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"edito",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":47,"column":14},"end":{"line":47,"column":36}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":8},"end":{"line":49,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/eq.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"itinerary",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":51,"column":14},"end":{"line":51,"column":40}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":8},"end":{"line":53,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/eq.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"summary",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":55,"column":14},"end":{"line":55,"column":38}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":8},"end":{"line":57,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/eq.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"dynamic-page",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":59,"column":14},"end":{"line":59,"column":43}}}),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":8},"end":{"line":61,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/eq.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"steps",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":63,"column":14},"end":{"line":63,"column":36}}}),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":8},"end":{"line":65,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/eq.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"calendar",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":67,"column":14},"end":{"line":67,"column":39}}}),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":8},"end":{"line":69,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/eq.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"flights",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":71,"column":14},"end":{"line":71,"column":38}}}),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":8},"end":{"line":73,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/eq.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"program-details",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":75,"column":14},"end":{"line":75,"column":46}}}),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":8},"end":{"line":77,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/eq.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"other-transports",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":79,"column":14},"end":{"line":79,"column":47}}}),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":8},"end":{"line":81,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/eq.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"voucher",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":83,"column":14},"end":{"line":83,"column":38}}}),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":8},"end":{"line":85,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/eq.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"travel-certificate",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":87,"column":14},"end":{"line":87,"column":49}}}),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":8},"end":{"line":89,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/eq.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"flight-tickets",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":91,"column":14},"end":{"line":91,"column":45}}}),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":8},"end":{"line":93,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/eq.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"picture",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":95,"column":14},"end":{"line":95,"column":38}}}),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":8},"end":{"line":97,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("./helpers/eq.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"contacts",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":99,"column":14},"end":{"line":99,"column":39}}}),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":8},"end":{"line":101,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/edito.handlebars"),depth0,{"name":"edito","hash":{"content":depth0},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/itinerary.handlebars"),depth0,{"name":"itinerary","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./partials/summary.handlebars"),depth0,{"name":"summary","hash":{"contents":((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"contents"))},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/dynamic-page.handlebars"),depth0,{"name":"dynamic-page","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/steps.handlebars"),depth0,{"name":"steps","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/calendar.handlebars"),depth0,{"name":"calendar","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/flights.handlebars"),depth0,{"name":"flights","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/program-details.handlebars"),depth0,{"name":"program-details","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/other-transports.handlebars"),depth0,{"name":"other-transports","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/voucher.handlebars"),depth0,{"name":"voucher","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/travel-certificate.handlebars"),depth0,{"name":"travel-certificate","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/flight-tickets.handlebars"),depth0,{"name":"flight-tickets","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/photo.handlebars"),depth0,{"name":"photo","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partials/contacts.handlebars"),depth0,{"name":"contacts","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html>\n<html lang=\"en\">\n\n<head>\n    <meta charset=\"UTF-8\">\n    <meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge\">\n    <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n    <title>Roadbook</title>\n    <link rel=\"preconnect\" href=\"https://fonts.googleapis.com\">\n    <link rel=\"preconnect\" href=\"https://fonts.gstatic.com\" crossorigin>\n    <link href=\"https://fonts.googleapis.com/css2?family=Mulish&family=Roboto&display=swap\" rel=\"stylesheet\">\n    <link rel=\"preconnect\" href=\"https://fonts.googleapis.com\">\n    <link rel=\"preconnect\" href=\"https://fonts.gstatic.com\" crossorigin>\n    <link href=\"https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap\" rel=\"stylesheet\">\n    <link rel=\"preconnect\" href=\"https://fonts.googleapis.com\">\n    <link rel=\"preconnect\" href=\"https://fonts.gstatic.com\" crossorigin>\n    <link href=\"https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&display=swap\" rel=\"stylesheet\">\n    <style>\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"style") || (depth0 != null ? lookupProperty(depth0,"style") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"style","hash":{},"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":19,"column":21}}}) : helper))) != null ? stack1 : "")
    + "\n    </style>\n    <style>\n        @font-face {\n            font-family: 'Ivy Mode';\n            src: url("
    + ((stack1 = alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fonts") : depth0)) != null ? lookupProperty(stack1,"ivyMode") : stack1)) != null ? lookupProperty(stack1,"thin") : stack1), depth0)) != null ? stack1 : "")
    + ") format('opentype');\n            font-weight: 100;\n            font-style: normal;\n        }\n        @font-face {\n            font-family: 'Ivy Mode';\n            src: url("
    + ((stack1 = alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fonts") : depth0)) != null ? lookupProperty(stack1,"ivyMode") : stack1)) != null ? lookupProperty(stack1,"bold") : stack1), depth0)) != null ? stack1 : "")
    + ") format('opentype');\n            font-weight: bold;\n            font-style: normal;\n        }\n    </style>\n</head>\n\n<body>\n    <div class=\"page-squares-top-left\"></div>\n    <div class=\"page-squares-top-right\"></div>\n    <div class=\"page-squares-bottom-right\"></div>\n    <div class=\"page-squares-bottom-left\"></div>\n    <div class=\"page-number\"></div>\n\n"
    + ((stack1 = container.invokePartial(require("./partials/cover.handlebars"),depth0,{"name":"cover","hash":{"trip":(depth0 != null ? lookupProperty(depth0,"trip") : depth0),"traveler":(depth0 != null ? lookupProperty(depth0,"traveler") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"contents") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":4},"end":{"line":102,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./partials/ending.handlebars"),depth0,{"name":"ending","hash":{"url":(depth0 != null ? lookupProperty(depth0,"endingPagePicture") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</body>\n\n<script>\n    function getHeight(element) {\n        const rectangle = element.getBoundingClientRect();\n        return rectangle.height;\n    }\n\n    window.onload = function () {\n        var titleContainer = document.getElementById(\"cover-page-content-titles\");\n        var title = document.getElementById(\"cover-page-content-main-title\");\n\n        var titleHeight = getHeight(title);\n        var titleContainerHeight = getHeight(titleContainer);\n        var currentFontSize = parseFloat(getComputedStyle(title).getPropertyValue('font-size'));\n\n        const ratio = titleHeight / titleContainerHeight;\n        if (ratio > 0.7) {\n            var newFontSize = (currentFontSize * titleContainerHeight * 0.7) / titleHeight;\n            title.style.fontSize = newFontSize + 'px';\n        }\n    }\n</script>\n</html>";
},"usePartial":true,"useData":true});