var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"chapter\">\n    <div class=\"cover-page\">\n        <div class=\"cover-page-banner\" style=\"background-image: url("
    + alias1(((helper = (helper = lookupProperty(helpers,"coverPicture") || (depth0 != null ? lookupProperty(depth0,"coverPicture") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"coverPicture","hash":{},"data":data,"loc":{"start":{"line":3,"column":68},"end":{"line":3,"column":86}}}) : helper)))
    + ");\"></div>\n        <div class=\"cover-page-event\">\n            <div>"
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"trip") : depth0)) != null ? lookupProperty(stack1,"date") : stack1), depth0))
    + "</div>\n            <div>"
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"traveler") : depth0)) != null ? lookupProperty(stack1,"civility") : stack1), depth0))
    + " "
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"traveler") : depth0)) != null ? lookupProperty(stack1,"lastName") : stack1), depth0))
    + "</div>\n        </div>\n        <div class=\"cover-page-content\">\n            <div id=\"cover-page-content-titles\" class=\"cover-page-content-titles\">\n                <h1>Carnet de Voyage</h1>\n                <h2 id=\"cover-page-content-main-title\">"
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"trip") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</h2>\n            </div>\n            <img class=\"cover-page-logo\" src=\"../../../../../../Img/logo-CDV-blanc-2 (1).png?roadbook\" alt=\"logo\">\n        </div>\n    </div>\n</div>";
},"useData":true});