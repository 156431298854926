import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Icon,
    Stack,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import CartAccommodationRemarkBooking from "./CartAccommodationRemarkBooking";
import { AccommodationCart } from "../Itinerary/objects/accommodationCart";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    open: boolean,
    accommodation: AccommodationCart,
    onClose: () => void,
    onCloseMenu: () => void,
}

export function CartConstructionProductsTableSpecificDemandModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
    const provider = useMemo(() => {
        let tmp_provider = null;
        for (let i = 0; i < providers.length; i++) {
            if (providers[i]?.provider.id === props.accommodation.provider) {
                tmp_provider = providers[i]!;
            }
        }
        for (let i = 0; i < manual_providers.length; i++) {
            if (manual_providers[i]?.provider.id === props.accommodation.provider) {
                tmp_provider = manual_providers[i]!;
            }
        }
        return tmp_provider;
    }, [props.accommodation, providers, manual_providers]);

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            onClick={(event) => event.stopPropagation()}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" fontWeight="bold" component="div">
                        {t<string>('cart-material.cart-construction-specific-demand')}
                    </Typography>
                    <Icon onClick={props.onClose}>
                        <Close />
                    </Icon>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <CartAccommodationRemarkBooking
                    accommodation={props.accommodation}
                    provider={provider}
                    onRemarkBooking={props.onCloseMenu}
                />
            </DialogContent>
        </Dialog>
    );
}
