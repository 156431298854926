import React from "react";

type Style = {
    [index: string]: React.CSSProperties
}

export const customColors: {[index: string]: Style} = {
    cercledesvoyages: {
        COLOR_BF8A68: {
            color: '#BF8A68'
        }
    }
};
