const GetPrice = (prices, user) => {
    let returned_price = "0";
    prices.map((price) => {
        if (price.owner === user.client) {
            returned_price = price.selling_price;
        }
    });
    return returned_price;
};

export default GetPrice;