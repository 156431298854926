import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    ListItemText,
    Typography
} from "@mui/material";
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";
import { getLocalizedValue } from "../Itinerary/utils/getLocalizedValue";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    index: number,
    input: ItineraryContentStep,
}

export function CartConstructionItineraryContentStepsListItem(props: Props): JSX.Element {
    const { t } = useTranslation();
    const locale = useSelector((state: AppState) => state.locale.current_locale);

    return (
        <ListItemText
            primary={
                props.input.mode === 'by-day' ?
                    t<string>('shared.circuit-step-days', { days: props.input.content.day.join(', ') }) :
                    t<string>('itinerary.step-no', { no: props.index + 1 })
            }
            secondary={
                <>
                    <Typography variant="body2">
                        {t<string>('shared.circuit-step-title')} : {props.input.content.title}
                    </Typography>
                    <Typography variant="body2">
                        {
                            (() => {
                                let value = '';

                                if (props.input.mode === 'by-day') {
                                    value = props.input.content.destinations?.map((destination) => {
                                        let result = getLocalizedValue(
                                            locale,
                                            destination?.data ?? {
                                                name: '',
                                                localization: []
                                            },
                                            'name'
                                        ) ?? '';
                                        result = result.length > 0 ?
                                            result :
                                            (
                                                destination?.data?.international_name ?? ''
                                            );
                                        return result.split(',')[0] ?? '';
                                    }).join(' - ') ?? '';
                                } else {
                                    const nightDestination = props.input.content.destination;
                                    value = getLocalizedValue(
                                        locale,
                                        nightDestination?.data ?? {
                                            name: '',
                                            localization: []
                                        },
                                        'name'
                                    ) ?? '';
                                    value = value.length > 0 ?
                                        value :
                                        (
                                            nightDestination?.data?.international_name ?? ''
                                        );
                                    value = value.split(',')[0] ?? '';
                                }

                                return t<string>(
                                    'shared.circuit-step-destination',
                                    { name: value }
                                );
                            })()
                        }
                    </Typography>
                </>
            }
        />
    );
}
