import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import CheckResponse from "../../Flight/FlightSelected/Functions/CheckResponse";

import axios from "axios";
import { isProductPackaged } from "../../CartMaterial/utils/isProductPackaged";

export default (to_book, dispatch, end_date, trip) => {
    const { headers, pass_check } = CheckBeforeRequest();
    const toBeBooked = to_book.filter((item) => {
        return !isProductPackaged({
            product: item,
            stackInfos: trip?.stack_info ?? null
        });
    });

    toBeBooked.map((product) => {
        if (product.is_available && (product.booking_status === null || (product.booking_status.status_booking === "ERROR"))) {
            if (pass_check) {
                if (product.is_manual) {
                    axios({
                        method: 'PATCH',
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${product.id}/`,
                        data: {
                            agency_need_to_book: true
                        }
                    }).then(response => {
                        dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
                    }).catch(error => {
                        console.log(error);
                    });
                } else {
                    switch (product.product_type) {
                        case 0:
                            axios({
                                method: 'PATCH',
                                headers: headers,
                                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${product.id}/`,
                                data: {
                                    agency_need_to_book: true
                                }
                            }).then(response => {
                                dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                            }).catch(error => {
                                console.log(error);
                            });
                            break;
                        case 2:
                            axios({
                                method: 'PATCH',
                                headers: headers,
                                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${product.id}/`,
                                data: {
                                    agency_need_to_book: true
                                }
                            }).then(response => {
                                dispatch({ type: 'CAR_EDIT_CART_BY_ID', payload: response.data });
                            }).catch(error => {
                                console.log(error);
                            });
                            break;
                        case 4:
                            axios({
                                method: 'PATCH',
                                headers: headers,
                                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${product.is_custom ? 'custom-products' : 'transfers'}/${product.id}/`,
                                data: {
                                    agency_need_to_book: true
                                }
                            }).then(response => {
                                dispatch({ type: 'TRANSFER_EDIT_CART_BY_ID', payload: response.data });
                            }).catch(error => {
                                console.log(error);
                            });
                            break;
                        case 6:
                            axios({
                                method: 'PATCH',
                                headers: headers,
                                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${product.id}/`,
                                data: {
                                    agency_need_to_book: true
                                }
                            }).then(response => {
                                let new_flight = [];
                                new_flight.push(response.data);
                                let arr = CheckResponse(new_flight, end_date);
                                dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
                            }).catch(error => {
                                console.log(error);
                            });
                            break;
                        case 11:
                            axios({
                                method: 'PATCH',
                                headers: headers,
                                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${product.id}/`,
                                data: {
                                    agency_need_to_book: true
                                }
                            }).then(response => {
                                dispatch({ type: 'POI_EDIT_CART_BY_ID', payload: response.data });
                            }).catch(error => {
                                console.log(error);
                            });
                            break;
                        case 12:
                            axios({
                                method: 'PATCH',
                                headers: headers,
                                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${product.id}/`,
                                data: {
                                    agency_need_to_book: true
                                }
                            }).then(response => {
                                dispatch({ type: 'POI_EDIT_CART_BY_ID', payload: response.data });
                            }).catch(error => {
                                console.log(error);
                            });
                            break;
                    }
                }
            }
        }
    });
    dispatch({ type: 'CART_SET_STEP', payload: { step: 1 } });
};
