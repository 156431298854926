//---- Dependencies ----//
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";

//---- Action ----//
import { SetMarginStepAndUpdate } from "../../../../Actions/Menu";
import axios from "axios";

export default (request, dispatch, user, enqueueSnackbar) => {
   let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "POST",
                headers: headers,
                url: `${API_HREF}client/${user.client}/margins/`,
                data: JSON.stringify(request),
            }).then(function (response) {
                dispatch(SetMarginStepAndUpdate(1, true));
            }).catch(function (error) {
                if (error.response) {
                    console.log('error:', error.response.data);
                    Object.keys(error.response.data).map((key) => {
                        if (error.response.data[key].length > 0) {
                            error.response.data[key].map((error_data) => {
                                enqueueSnackbar(error_data, { variant: "error" })
                            })
                        }
                    });
                }
            });
        }
};
