import React from "react";
import { useTranslation } from "react-i18next";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import { isNumber } from "lodash";
import { useTripVersions } from "./utils/tripVersions";

type Props = {
    onSetVersion: (version: number | null) => void,
    exclude?: number[],
} & ({
    version: number,
    canBeEmpty?: false
} | {
    version: number | null,
    canBeEmpty: true
})

export function CartConstructionItineraryContentVersion(props: Props): JSX.Element {
    const { t } = useTranslation();
    const versions = useTripVersions();

    const onChangeVersion = (event: SelectChangeEvent<string | number>) => {
        props.onSetVersion(isNumber(event.target.value) ? event.target.value : null);
    };

    return (
        <div>
            <FormControl size="small" fullWidth>
                <InputLabel>
                    {t<string>('shared.version')}
                </InputLabel>
                <Select
                    label={t<string>('shared.version')}
                    value={props.version ?? 'none'}
                    onChange={onChangeVersion}
                >
                    {
                        props.canBeEmpty &&
                        <MenuItem value="none">
                            {t<string>('shared.circuit-steps-choose-version')}
                        </MenuItem>
                    }
                    {
                        versions.filter((item) => {
                            return !props.exclude?.includes(item.id);
                        }).map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </div>
    );
}
