import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    Divider,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { CartManualProductFormAddressMap } from "./CartManualProductFormAddressMap";
import {
    CartManualProductFormAddressGoogleSearch
} from "./CartManualProductFormAddressGoogleSearch";

type Location = {
    address: string | null,
    position: {
        latitude: number | null,
        longitude: number | null
    }
}

type Props = Location & {
    errors?: {
        [K in keyof Location]: Location[K] extends object ?
            {
                [L in keyof Location[K]]: boolean
            } :
            boolean
    },
    onChangeLocation: <K extends keyof Location>(
        key: K,
        value: Location[K]
    ) => void
}

export function CartManualProductFormAddressPicker(props: Props): JSX.Element {
    const { t } = useTranslation();
    const map = useRef<google.maps.Map | null>(null);

    const onChangeMarkerPosition = (location: google.maps.LatLng) => {
        props.onChangeLocation(
            'position',
            {
                latitude: location.lat(),
                longitude: location.lng()
            }
        );
    };

    const onChooseGooglePlace = (place: google.maps.places.PlaceResult | null) => {
        if (
            place?.formatted_address &&
            place.geometry?.location &&
            place.geometry.viewport
        ) {
            props.onChangeLocation(
                'address',
                place.formatted_address
            );
            onChangeMarkerPosition(place.geometry.location);
            map.current?.fitBounds(place.geometry.viewport);
        }
    };

    return (
        <Box sx={{ marginTop: 2 }}>
            <Divider sx={{ marginBottom: 3 }} />
            <Stack spacing={2}>
                <Typography fontWeight="bold" component="div">
                    {t<string>('cart-material.geolocation')}
                </Typography>
                <div>
                    <CartManualProductFormAddressGoogleSearch
                        sx={{ marginBottom: 1.5 }}
                        onChange={onChooseGooglePlace}
                    />
                    <TextField
                        label={t<string>('cart-material.address')}
                        value={props.address ?? ''}
                        onChange={(event) => props.onChangeLocation('address', event.target.value)}
                        sx={{ marginBottom: 2 }}
                        error={props.errors?.address}
                        fullWidth
                    />
                    <Stack direction="row" spacing={1}>
                        <TextField
                            value={props.position.latitude ?? ''}
                            label={t<string>('cart-material.latitude')}
                            error={props.errors?.position.latitude}
                            fullWidth
                            disabled
                        />
                        <TextField
                            value={props.position.longitude ?? ''}
                            label={t<string>('cart-material.longitude')}
                            error={props.errors?.position.longitude}
                            fullWidth
                            disabled
                        />
                    </Stack>
                </div>
                <CartManualProductFormAddressMap
                    ref={map}
                    markerLocation={props.position}
                    onChangeMarkerLocation={onChangeMarkerPosition}
                />
            </Stack>
            <Divider sx={{ marginTop: 3 }} />
        </Box>
    );
}
