import { ArrowRightAltOutlined, Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import axios from "axios";
import { useCartTotalPrices } from "./utils/cartTotalPrices";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../Reducers/Reducers";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import GetCookie from "../Common/Functions/GetCookie";
import GetPricesId from "../Cart/Functions/Margin/GetPricesId";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { useGetPrice } from "./utils/getPrice";

export type CartConstructionHeaderPriceAdjustmentModalProps = {
    open: boolean,
    type: 'selling-price' | 'margin',
    value: number,
    onClose: () => void
}

export function CartConstructionHeaderPriceAdjustmentModal(props: CartConstructionHeaderPriceAdjustmentModalProps): JSX.Element {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const trip_currency = useSelector((state: AppState) => state.trip.currency);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const tva_value = useSelector((state: AppState) => state.cart.tva_value);
    const margin_value = useSelector((state: AppState) => state.cart.margin_value);
    const existingAdjustmentProduct = useSelector((state: AppState) => {
        return state.cart.manual_item_list?.find((item) => {
            return item.product_type === 20;
        });
    });
    const user = useSelector((state: AppState) => state.user.user);
    const [loading, setLoading] = useState(false);
    const prices = useCartTotalPrices();
    const getPrice = useGetPrice();
    const newSellingPrice = useMemo(() => {
        if (props.type === 'selling-price') {
            return props.value;
        }
        // const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
        let purchasePrice =
            prices.margin.purchase_converted +
            prices.flightTaxes + prices.surcom.value +
            prices.vat;
        return purchasePrice + props.value;
    }, [
        props.type,
        props.value,
        prices.margin.purchase_converted
    ]);
    const diffPrice = useMemo(() => {
        const existingAdjustementPrice = getPrice(existingAdjustmentProduct?.prices ?? []);
        return newSellingPrice - (prices.totalCartCost - existingAdjustementPrice.cost);
    }, [
        newSellingPrice,
        prices.totalCartCost,
        existingAdjustmentProduct
    ]);

    const onContinue = async () => {
        const { headers } = CheckBeforeRequest();
        setLoading(true);
        if (!existingAdjustmentProduct) {
            let tva_percentage = margin_value !== 0 ? tva_value / margin_value * 100 : 0;
            const adjustment_margin = (tva_percentage !== 0 ? diffPrice / (1 + (tva_percentage / 100)) : diffPrice);
            const adjustment_tva = tva_percentage !== 0 ? (diffPrice - adjustment_margin) : 0;
            try {
                const response = await axios({
                    method: 'POST',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/`,
                    data: {
                        name: t("header.adjustment_product_name"),
                        product_type: 20,
                        step: null,
                        provider_name: null,
                        provider_id: null,
                        start_date: null,
                        end_date: null,
                        address: null,
                        stars: null,
                        breakfast_included: false,
                        lunch_included: false,
                        diner_included: false,
                        rooms: null,
                        resort_fees: null,
                        resort_fees_currency: null,
                        flight_paste: null,
                        flight_segment: null,
                        cgos_reduction: false,
                        description: "",
                        custom_information: null,
                        group_passenger: undefined,
                        taxes: null,
                        nb_baggages: null,
                        picture_id: null,
                        forced_tva_price: adjustment_tva !== 0 ? adjustment_tva.toFixed(2) : undefined,
                        prices: [{
                            purchase_price: 0,
                            purchase_currency: trip_currency.id,
                            custom_rate: true,
                            custom_rate_type: "FIX",
                            custom_value: adjustment_margin.toFixed(2),
                            id: undefined
                        }]
                    }
                });
                dispatch({ type: 'CART_ADD_MANUAL_ITEM', payload: response.data });
                enqueueSnackbar(t<string>('cart-material.manual-product-added'), { variant: 'success' });
                dispatch({
                    type: 'CART_UPDATE_DATE',
                    payload: true
                });
                props.onClose();
            } catch (error) {
                console.error(error);
                enqueueSnackbar(
                    t<string>('cart-material.manual-product-not-added'),
                    { variant: 'error' }
                );
            } finally {
                setLoading(false);
            }
        } else {
            let tva_percentage = margin_value !== 0 ? tva_value / margin_value * 100 : 0;
            //const adjustment_abs = newProduct.length !== 0 ? Math.abs(parseFloat(new_price) + parseFloat(price)) : Math.abs(tmp_selling);
            const adjustment_margin = (tva_percentage !== 0 ? diffPrice / (1 + (tva_percentage / 100)) : diffPrice);
            const adjustment_tva = tva_percentage !== 0 ? (diffPrice - adjustment_margin) : 0;
            try {
                const response = await axios({
                    method: 'PATCH',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${existingAdjustmentProduct.id}/`,
                    data: {
                        forced_tva_price: adjustment_tva !== 0 ? adjustment_tva : undefined,
                        prices: [{
                            purchase_price: 0,
                            purchase_currency: trip_currency.id,
                            custom_rate: true,
                            custom_rate_type: "FIX",
                            custom_value: adjustment_margin.toFixed(2),
                            id: existingAdjustmentProduct.prices[GetPricesId(existingAdjustmentProduct.prices, user?.client, user, true)]?.id
                        }]
                    }
                });
                dispatch({ type: 'CART_EDIT_MANUAL_ITEM', payload: response.data });
                enqueueSnackbar(t<string>('cart-material.manual-product-edited'), { variant: 'success' });
                dispatch({
                    type: 'CART_UPDATE_DATE',
                    payload: true
                });
                props.onClose();
            } catch (error) {
                console.error(error);
                enqueueSnackbar(
                    t<string>('cart-material.manual-product-not-added'),
                    { variant: 'error' }
                );
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <Dialog
                open={props.open}
                maxWidth="sm"
                onClose={props.onClose}
                fullWidth
            >
                <DialogTitle>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography fontWeight="bold">
                            {
                                props.type === 'selling-price' ?
                                    t<string>('cart-material.cart-construction-global-selling-price-edit-title') :
                                    t<string>('cart-material.cart-construction-global-margin-edit-title')
                            }
                        </Typography>
                        <IconButton onClick={props.onClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        {t<string>('cart-material.cart-construction-global-price-change')}
                        {' '}<Stack
                            direction="row"
                            display="inline-flex"
                            alignItems="center"
                            spacing={1}
                            sx={{
                                '& strong:first-of-type': {
                                    color: 'red'
                                },
                                '& strong:nth-of-type(2)': {
                                    color: 'green'
                                }
                            }}
                        >
                            <strong>
                                {
                                    new Intl.NumberFormat(
                                        language,
                                        {
                                            style: 'currency',
                                            currency: prices.totalCost[0]?.currency?.iso_code ?? 'EUR',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 2
                                        }
                                    ).format(prices.totalCartCost)
                                }
                            </strong>
                            <ArrowRightAltOutlined />
                            <strong>
                                {
                                    new Intl.NumberFormat(
                                        language,
                                        {
                                            style: 'currency',
                                            currency: prices.totalCost[0]?.currency?.iso_code ?? 'EUR',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 2
                                        }
                                    ).format(newSellingPrice)
                                }
                            </strong>
                        </Stack>
                    </Typography>
                    <Typography>
                        {
                            existingAdjustmentProduct ?
                                t<string>('cart-material.cart-construction-change-price-adjustement') :
                                t<string>('cart-material.cart-construction-create-price-adjustement')
                        }
                        {' '}
                        <strong>
                            {
                                new Intl.NumberFormat(
                                    language,
                                    {
                                        style: 'currency',
                                        currency: prices.totalCost[0]?.currency?.iso_code ?? 'EUR',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 2
                                    }
                                ).format(diffPrice)
                            }
                        </strong>
                    </Typography>
                    <Typography>
                        {t<string>('cart-material.cart-construction-continue-question')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>
                        {t<string>('shared.cancel')}
                    </Button>
                    <Button onClick={onContinue}>
                        {t<string>('global.continue')}
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingBackDrop open={loading} />
        </>
    );
}
