import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import { Save } from "@mui/icons-material";
import { debounce, flattenDeep, isEqual, pickBy, uniq } from "lodash";
import { convertToRaw } from "draft-js";
import { useItineraryContentCartProducts } from "./utils/itineraryContentCartProducts";
import { renderHtml } from "../Menu/MaterialTripList/MailVisualEditor/utils/render-html";
import { htmlHasEmptyContent } from "../Menu/MaterialTripList/utils/htmlHasEmptyContent";
import { CartConstructionContentsContext } from "./utils/cartConstructionContentsContext";
import { Picture } from "../Menu/MaterialTripList/picture/objects/picture";
import { ItineraryContentItem } from "./objects/itineraryContentItem";
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";
import { MailTemplateVisualEditorBodyStyles } from "../Menu/MaterialTripList/MailVisualEditor/objects/mailTemplateVisualEditorState";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    titles: { [id: number]: { [locale: number]: string } },
    pictures: { [id: number]: Picture[] | null }
}

const INSTANCE_KEYS = [
    'cart-construction-by-day-short-description',
    'cart-construction-by-day-long-description',
    'cart-construction-by-step-short-description',
    'cart-construction-by-step-long-description',
    'cart-construction-by-day-product',
    'cart-construction-by-step-product'
];

export function CartConstructionContentsFormSave(props: Props): JSX.Element {
    const { i18n } = useTranslation();
    const context = useContext(CartConstructionContentsContext);
    const itineraryContent = useSelector((state: AppState) => {
        return context.version > 0 ?
            state.itinerarySlice.content[context.version] :
            undefined;
    });
    const uiLocale = useSelector((state: AppState) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1;
    });
    const instances = useSelector((state: AppState) => state.mailTemplate.visualEditor.present.instances);
    const savingTexts = useSelector((state: AppState) => state.cartConstruction.savingTexts);
    const currentLocale = useSelector((state: AppState) => state.locale.current_locale);
    const saveQueue = useSelector((state: AppState) => state.cartConstruction.textsSaveQueue);
    const fetchingTexts = useSelector((state: AppState) => state.cartConstruction.fetchingTexts);
    const syncingTexts = useSelector((state: AppState) => state.cartConstruction.syncingTexts);
    const recreatingTextInputs = useSelector((state: AppState) => state.cartConstruction.recreatingTextInputs);
    const autoloadingTexts = useSelector((state: AppState) => state.cartConstruction.autoloadingTexts);
    const step = useSelector((state: AppState) => state.cart.step);
    const visualEditorInstances = useMemo(() => {
        return pickBy(
            instances,
            (_, key) => {
                for (const item of INSTANCE_KEYS) {
                    if (key.startsWith(item)) {
                        return true;
                    }
                }
                return false;
            }
        );
    }, [instances]);
    const previousVisualEditorInstances = useRef<typeof visualEditorInstances | null>(null);
    const previousTitles = useRef<typeof props.titles | null>(null);
    const previousPictures = useRef<typeof props.pictures | null>(null);
    const getFilteredCart = useItineraryContentCartProducts();
    const onSave = useCallback(
        debounce(
            (
                options: {
                    content: typeof itineraryContent,
                    previousInstances: typeof previousVisualEditorInstances.current,
                    instances: typeof visualEditorInstances,
                    titles: typeof props.titles,
                    prevTitles: typeof previousTitles.current,
                    pictures: typeof props.pictures,
                    prevPictures: typeof previousPictures.current
                }
            ) => {
                const previousStates = flattenDeep(
                    Object.keys(options.previousInstances ?? options.instances).map((instanceId) => {
                        const instance = (options.previousInstances ?? options.instances)[instanceId];
                        if (instance) {
                            return Object.values(instance.visualEditorBlocks).map((item) => {
                                return item.order.map((id) => {
                                    if (item.blocks[id]) {
                                        return {
                                            instanceId,
                                            block: item.blocks[id]!
                                        };
                                    }
                                });
                            });
                        }
                    })
                ).filter((item): item is NonNullable<typeof item> => !!item);
                const newStates = flattenDeep(
                    Object.keys(options.instances).map((instanceId) => {
                        const instance = options.instances[instanceId];
                        if (instance) {
                            return Object.values(instance.visualEditorBlocks).map((item) => {
                                return item.order.map((id) => {
                                    if (item.blocks[id]) {
                                        return {
                                            instanceId,
                                            block: item.blocks[id]!
                                        };
                                    }
                                });
                            });
                        }
                    })
                ).filter((item): item is NonNullable<typeof item> => !!item);
                if (
                    options.content?.state === 'success' &&
                    (
                        !isEqual(options.prevTitles, options.titles) ||
                        !isEqual(options.prevPictures, options.pictures) ||
                        previousStates.length !== newStates.length ||
                        !newStates.every((state, index) => {
                            const corresponding = previousStates[index]!;

                            if (state.instanceId !== corresponding.instanceId) {
                                return false;
                            }

                            if (state.block.getType() === 'text' && corresponding.block.getType() === 'text') {
                                const oldContent = convertToRaw(corresponding.block.getOptions().editorState.getCurrentContent());
                                const newContent = convertToRaw(state.block.getOptions().editorState.getCurrentContent());
                                const oldOptions = {
                                    ...corresponding.block.getOptions(),
                                    editorState: null
                                };
                                const newOptions = {
                                    ...state.block.getOptions(),
                                    editorState: null
                                };
                                return isEqual(
                                    {
                                        ...oldContent,
                                        blocks: oldContent.blocks.map((item) => {
                                            //eslint-disable-next-line @typescript-eslint/no-unused-vars
                                            const { key, ...data } = item;
                                            return data;
                                        })
                                    },
                                    {
                                        ...newContent,
                                        blocks: newContent.blocks.map((item) => {
                                            //eslint-disable-next-line @typescript-eslint/no-unused-vars
                                            const { key, ...data } = item;
                                            return data;
                                        })
                                    }
                                ) && isEqual(
                                    oldOptions,
                                    newOptions
                                );
                            }

                            return isEqual(state.block.getOptions(), corresponding.block.getOptions());
                        })
                    )
                ) {
                    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
                    const result: ItineraryContentStep[] = options.content.data.content.map((contentItem, index): typeof result[number] => {
                        const item = {
                            mode: options.content?.state === 'success' ?
                                options.content.data.mode :
                                'by-day',
                            content: contentItem
                        } as ItineraryContentItem;
                        const filteredCart = getFilteredCart(item, index);
                        const contentTitles = options.titles[item.content.id] ?? {};
                        // short and long description fields were inverted :')
                        const shortDescriptions = options.instances[
                            item.mode === 'by-day' ?
                                `cart-construction-by-day-long-description-${item.content.id}` :
                                `cart-construction-by-step-long-description-${item.content.id}`
                        ];
                        const longDescriptions = options.instances[
                            item.mode === 'by-day' ?
                                `cart-construction-by-day-short-description-${item.content.id}` :
                                `cart-construction-by-step-short-description-${item.content.id}`
                        ];
                        const products = Object.keys(options.instances).filter((key) => {
                            if (item.mode === 'by-day') {
                                return key.startsWith(`cart-construction-by-day-product-${item.content.id}`);
                            }
                            return key.startsWith(`cart-construction-by-step-product-${item.content.id}`);
                        }).map((key) => {
                            let intervalIndex = '';
                            if (item.mode === 'by-day') {
                                intervalIndex = key.replace(`cart-construction-by-day-product-${item.content.id}-`, '');
                            } else {
                                intervalIndex = key.replace(`cart-construction-by-step-product-${item.content.id}-`, '');
                            }
                            return {
                                intervalIndex: parseInt(intervalIndex),
                                instance: options.instances[key]!
                            };
                        }).filter((item) => {
                            const count = filteredCart.cars.length +
                                filteredCart.accommodations.length +
                                filteredCart.pois.length +
                                filteredCart.transfers.length +
                                filteredCart.manualProducts.length;
                            return item.intervalIndex <= count;
                        });
                        const locales = uniq(
                            Object.keys(contentTitles).concat(
                                Object.keys(shortDescriptions?.visualEditorBlocks ?? {})
                            ).concat(
                                Object.keys(longDescriptions?.visualEditorBlocks ?? {})
                            )
                        );
                        const defaultTitle = contentTitles[currentLocale] ?? Object.values(contentTitles)[0] ?? '';

                        let defaultShortDescription = '';
                        let defaultLongDescription = '';

                        if (shortDescriptions) {
                            defaultShortDescription = renderHtml(
                                createHtmlOutputStyles(shortDescriptions.visualEditorBodyStyles),
                                shortDescriptions.visualEditorBlocks[currentLocale] ??
                                Object.values(shortDescriptions.visualEditorBlocks)[0] ??
                                {
                                    blocks: {},
                                    order: []
                                },
                                quotationCode,
                                true
                            );
                        }

                        if (longDescriptions) {
                            defaultLongDescription = renderHtml(
                                createHtmlOutputStyles(longDescriptions.visualEditorBodyStyles),
                                longDescriptions.visualEditorBlocks[currentLocale] ??
                                Object.values(longDescriptions.visualEditorBlocks)[0] ??
                                {
                                    blocks: {},
                                    order: []
                                },
                                quotationCode,
                                true
                            );
                        }

                        const data = {
                            title: defaultTitle.trim().length > 0 ? defaultTitle : '',
                            short_description: !htmlHasEmptyContent(defaultLongDescription) ?
                                defaultLongDescription :
                                '',
                            long_description: !htmlHasEmptyContent(defaultShortDescription) ?
                                defaultShortDescription :
                                '',
                            picture: null,
                            pictures: options.pictures[item.content.id] ?? null,
                            localization: locales.map((item) => {
                                const locale = parseInt(item);
                                const title = contentTitles[locale] ?? '';

                                let shortDescription = '';
                                let longDescription = '';

                                if (shortDescriptions && shortDescriptions.visualEditorBlocks[locale]) {
                                    shortDescription = renderHtml(
                                        createHtmlOutputStyles(shortDescriptions.visualEditorBodyStyles),
                                        shortDescriptions.visualEditorBlocks[locale]!,
                                        quotationCode,
                                        true
                                    );
                                }

                                if (longDescriptions && longDescriptions.visualEditorBlocks[locale]) {
                                    longDescription = renderHtml(
                                        createHtmlOutputStyles(longDescriptions.visualEditorBodyStyles),
                                        longDescriptions.visualEditorBlocks[locale]!,
                                        quotationCode,
                                        true
                                    );
                                }

                                return {
                                    locale,
                                    title: title.trim().length > 0 ? title : '',
                                    short_description: !htmlHasEmptyContent(longDescription) ?
                                        longDescription :
                                        '',
                                    long_description: !htmlHasEmptyContent(shortDescription) ?
                                        shortDescription :
                                        ''
                                };
                            }),
                            interval: products.map((item): typeof result[number]['content']['interval'][number] => {
                                const locales = Object.keys(item.instance.visualEditorBlocks).map((key) => {
                                    return parseInt(key);
                                });

                                const defaultText = renderHtml(
                                    createHtmlOutputStyles(item.instance.visualEditorBodyStyles),
                                    item.instance.visualEditorBlocks[0] ?? { order: [], blocks: {} },
                                    quotationCode,
                                    true
                                );

                                return {
                                    interval_index: item.intervalIndex,
                                    interval_description: item.instance.visualEditorBlocks[uiLocale] ?
                                        renderHtml(
                                            createHtmlOutputStyles(item.instance.visualEditorBodyStyles),
                                            item.instance.visualEditorBlocks[uiLocale] ?? { order: [], blocks: {} },
                                            quotationCode,
                                            true
                                        ) :
                                        defaultText,
                                    localization: locales.map((locale) => {
                                        return {
                                            locale,
                                            interval_description: renderHtml(
                                                createHtmlOutputStyles(item.instance.visualEditorBodyStyles),
                                                item.instance.visualEditorBlocks[locale] ?? { order: [], blocks: {} },
                                                quotationCode,
                                                true
                                            )
                                        };
                                    })
                                };
                            })
                        };

                        if (item.mode === 'by-day') {
                            return {
                                mode: 'by-day',
                                content: {
                                    ...item.content,
                                    ...data
                                }
                            };
                        }

                        return {
                            ...item,
                            content: {
                                ...item.content,
                                ...data
                            }
                        };
                    });
                    if (result.length > 0) {
                        saveQueue?.add(result);
                        previousTitles.current = options.titles;
                        previousPictures.current = options.pictures;
                        previousVisualEditorInstances.current = options.instances;
                    }
                }
            },
            500
        ),
        [getFilteredCart, saveQueue]
    );

    useEffect(() => {
        if (
            !fetchingTexts &&
            !recreatingTextInputs &&
            !syncingTexts &&
            !autoloadingTexts &&
            step === 2
        ) {
            onSave({
                content: itineraryContent,
                instances: visualEditorInstances,
                previousInstances: previousVisualEditorInstances.current,
                pictures: props.pictures,
                prevPictures: previousPictures.current,
                titles: props.titles,
                prevTitles: previousTitles.current
            });
        }
    }, [
        itineraryContent,
        visualEditorInstances,
        props.titles,
        props.pictures,
        context.version,
        fetchingTexts,
        recreatingTextInputs,
        syncingTexts,
        autoloadingTexts,
        step
    ]);

    return (
        <Box
            sx={(theme) => ({
                position: 'fixed',
                left: theme.spacing(2.5),
                bottom: theme.spacing(2.5),
                display: savingTexts ? 'block' : 'none'
            })}
        >
            <Box sx={{ position: 'relative', display: 'flex' }}>
                <CircularProgress size={25} color="inherit" />
                <Box
                    sx={{
                        position: 'absolute',
                        top: 3,
                        left: 3,
                        width: 19,
                        height: 19,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#5B9AF0'
                    }}
                >
                    <Save
                        color="inherit"
                        fontSize="inherit"
                        sx={{ color: '#fff', fontSize: 10 }}
                    />
                </Box>
            </Box>
        </Box>
    );
}

function createHtmlOutputStyles(styles: MailTemplateVisualEditorBodyStyles): MailTemplateVisualEditorBodyStyles {
    return {
        ...styles,
        width: 1400
    };
}
