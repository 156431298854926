import React, { useContext } from "react";
import { styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MailTemplateVisualEditorBackgroundColorOption } from "./mailTemplateVisualEditorBackgroundColorOption";
import { MailTemplateVisualEditorPaddingOptions } from "./mailTemplateVisualEditorPaddingOptions";
import { MailTemplateVisualEditorFontFamilyOption } from "./mailTemplateVisualEditorFontFamilyOption";
import { MailTemplateVisualEditorSizeOption } from "./mailTemplateVisualEditorSizeOption";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";
import { MailTemplateVisualEditorNumberPicker } from "./mailTemplateVisualEditorNumberPicker";
import { MailTemplateVisualEditorColorPicker } from "./mailTemplateVisualEditorColorPicker";
import { MailTemplateVisualEditorContext } from "./mailTemplateVisualEditorContext";
import { setVisualEditorBodyStyles } from "./redux/actions";
import { MailTemplateVisualEditorBodyStyles } from "./objects/mailTemplateVisualEditorState";
import { AppState } from "../../../../Reducers/Reducers";

export function MailTemplateVisualEditorBodyStylePanel(): JSX.Element {
    const context = useContext(MailTemplateVisualEditorContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const bodyStyles = useSelector((state: AppState) => {
        return state.mailTemplate.visualEditor.present.instances[context.instanceId]?.visualEditorBodyStyles;
    });

    function onChangeOption<K extends keyof MailTemplateVisualEditorBodyStyles>(
        key: K,
        value: MailTemplateVisualEditorBodyStyles[K]
    ): void {
        if (bodyStyles) {
            const dispatchData = setVisualEditorBodyStyles(
                context.instanceId,
                {
                    ...bodyStyles,
                    [key]: value
                }
            );
            dispatch(dispatchData);
        }
    }

    const onChangePadding = (type: keyof MailTemplateVisualEditorPaddingOptions, value: number) => {
        if (bodyStyles) {
            const dispatchData = setVisualEditorBodyStyles(
                context.instanceId,
                {
                    ...bodyStyles,
                    padding: {
                        ...bodyStyles.padding,
                        [type]: value
                    }
                }
            );
            dispatch(dispatchData);
        }
    };

    const onChangeInnerPadding = (type: keyof MailTemplateVisualEditorPaddingOptions, value: number) => {
        if (bodyStyles) {
            const dispatchData = setVisualEditorBodyStyles(
                context.instanceId,
                {
                    ...bodyStyles,
                    innerPadding: {
                        ...bodyStyles.innerPadding,
                        [type]: value
                    }
                }
            );
            dispatch(dispatchData);
        }
    };

    function onChangeInnerBorder<K extends keyof MailTemplateVisualEditorBodyStyles['innerBorder']>(
        key: K,
        value: MailTemplateVisualEditorBodyStyles['innerBorder'][K]
    ): void {
        if (bodyStyles) {
            const dispatchData = setVisualEditorBodyStyles(
                context.instanceId,
                {
                    ...bodyStyles,
                    innerBorder: {
                        ...bodyStyles.innerBorder,
                        [key]: value
                    }
                }
            );
            dispatch(dispatchData);
        }
    }

    return (
        <Container>
            <MailTemplateVisualEditorFontFamilyOption
                fontFamily={bodyStyles?.fontFamily}
                onChangeFontFamily={(fontFamily) => onChangeOption("fontFamily", fontFamily)}
            />
            <MailTemplateVisualEditorBackgroundColorOption
                backgroundColor={bodyStyles?.backgroundColor}
                onChangeBackgroundColor={(color) => onChangeOption("backgroundColor", color)}
            />
            <MailTemplateVisualEditorSizeOption
                size={bodyStyles?.width}
                onChangeSize={(value) => onChangeOption("width", value)}
                min={200}
                max={2000}
            />
            <MailTemplateVisualEditorOptionsSection>
                <Typography>{t<string>('shared.mail-template-visual-editor-inner-border-color')}</Typography>
                <MailTemplateVisualEditorColorPicker
                    color={bodyStyles?.innerBorder.color}
                    onChangeColor={(color) => onChangeInnerBorder("color", color)}
                />
            </MailTemplateVisualEditorOptionsSection>
            <MailTemplateVisualEditorOptionsSection>
                <Typography>{t<string>('shared.mail-template-visual-editor-inner-border-width')}</Typography>
                <MailTemplateVisualEditorNumberPicker
                    measureUnit="px"
                    value={bodyStyles?.innerBorder.width}
                    onChange={(value) => onChangeInnerBorder("width", value)}
                    max={2000}
                />
            </MailTemplateVisualEditorOptionsSection>
            <MailTemplateVisualEditorOptionsSection>
                <Typography>{t<string>('shared.mail-template-visual-editor-inner-background-color')}</Typography>
                <MailTemplateVisualEditorColorPicker
                    color={bodyStyles?.innerBackgroundColor}
                    onChangeColor={(color) => onChangeOption("innerBackgroundColor", color)}
                />
            </MailTemplateVisualEditorOptionsSection>
            <MailTemplateVisualEditorPaddingOptions
                padding={bodyStyles?.padding}
                onChangePadding={onChangePadding}
            />
            <MailTemplateVisualEditorPaddingOptions
                title={t<string>('shared.mail-template-visual-editor-inner-padding')}
                padding={bodyStyles?.innerPadding}
                onChangePadding={onChangeInnerPadding}
            />
        </Container>
    );
}

const Container = styled('div')((props) => ({
    paddingLeft: props.theme.spacing(1),
    paddingRight: props.theme.spacing(1)
}));
