import { useCallback, useEffect, useState } from "react";
import axios, { AxiosError, CancelToken } from "axios";
import { debounce } from "lodash";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { AdalteRoomTypes } from "../objects/AdalteRoomTypes";

type Options = {
    version: number,
    groups: number[][],
    forVersionUpdate: boolean,
    startDate: string,
    skip: boolean
}

export function useGetAdalteRoomTypes(options: Options): AdalteRoomTypes {
    const [roomTypes, setRoomTypes] = useState<AdalteRoomTypes>([]);
    const onLaunchRequest = useCallback(
        debounce((options: Options) => {
            if (!options.skip) {
                const source = axios.CancelToken.source();
                (async () => {
                    setRoomTypes(await makeRequest({ ...options, token: source.token }));
                })();
                return () => {
                    source.cancel();
                };
            }
        }, 1000),
        [setRoomTypes]
    );

    useEffect(() => {
        onLaunchRequest(options);
    }, [options, onLaunchRequest]);

    return roomTypes;
}

async function makeRequest(options: Options & {token: CancelToken}): Promise<AdalteRoomTypes> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        try {
            const response = await axios.get<AdalteRoomTypes>(
                `${API_HREF}client/${window.id_owner}/circuits/adalte_room_types/`,
                {
                    headers,
                    cancelToken: options.token,
                    params: {
                        trip_version: options.version,
                        groups: `[${options.groups.map((group) => {
                            return `[${group.join(',')}]`;
                        }).join(',')}]`,
                        is_update_version: options.forVersionUpdate,
                        start_date: options.startDate
                    }
                }
            );
            return response.data;
        } catch (error) {
            const axiosError = error as Error | AxiosError;
            if (
                axios.isCancel(axiosError) ||
                (
                    'isAxiosError' in axiosError &&
                    axiosError.isAxiosError &&
                    axiosError.response?.status === 404
                )
            ) {
                return [];
            }
            console.error(error);
        }
    }

    return [];
}
