//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@mui/material/Radio';

import VisibilityIcon from '@material-ui/icons/Visibility';
import RoomIcon from '@material-ui/icons/Room';
import CommuteIcon from '@material-ui/icons/Commute';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import i18n from '../../../i18n.jsx';
import MosaicPicture from '../../Accommodation/FunctionalComponent/MosaicPicture';
import Picture from '../../Accommodation/FunctionalComponent/Picture';
import Slider from '../../Accommodation/FunctionalComponent/Slider';
import GetPictureBySize from "../../Accommodation/Functions/GetPictureBySize";
import MoreInfo from './MoreInfo.tsx';
import AccommodationCardPictures from "../../Accommodation/FunctionalComponent/AccommodationCardPictures";
import ByDayPictures from '../CircuitsMaterial/ByDayPicture';
import localeText from '../../Functions/localeText.js';
import { Checkbox } from '@mui/material';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
	italic: {
		fontStyle: "italic !important"
	},
	font14: {
		fontSize: 14
	},
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    cover: {
    	display: "block",
	    width: "100%",
	    height: "100%"
    },
    content: {
    	display: 'flex',
    	alignItems: "center",
    	paddingLeft: theme.spacing(1),
    	paddingBottom: theme.spacing(1),
    },
    pictureContainer: {
	    overflow: "hidden",
	    height: 200,
	    // width: 300,
	    position: "relative",
	    cursor: "pointer"
    },
    shortDescription: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        display: "-webkit-box !important",
        WebkitLineClamp: 5,
        WebkitBoxOrient: "vertical",
        whiteSpace: "normal"
    },
    lowerCase: {
        textTransform: "capitalize"
    },
    paddingLeft25: {
        paddingLeft: 25
    },
    activityOptionSelected: {
		border: `2px solid ${main_color} !important`,
		backgroundColor: `${main_color}20`,
        borderRadius: 10
	},
    checkbox: {
        // position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
}));

type ActivitiesCardProps = {
    activity: any,
    variantIsChecked: any,
    onToggleVariant: any,
    iti: any,
    products: any
}

const ActivitiesCard = (props: ActivitiesCardProps) => {
    const {activity, variantIsChecked, onToggleVariant, iti, products} = props;
    const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const type = useSelector((state:any) => state.itinerary_type.type);

    const locales = useSelector((state:any) => state.user.locales);
    const current_locale = locales.find((el) => {return el.language_code === i18n.language});

    const custom_product = Object.assign({}, activity.cust_variant !== undefined && activity.cust_variant !== null ? activity.cust_variant : activity.custom_product);
	const [showChevron, setShowChevron] = useState<boolean>(false);
	const [openMozaic, setOpenMozaic] = useState<boolean>(false);
	const [openPicture, setOpenPicture] = useState<boolean>(false);
	const [current_picture, setCurrentPicture] = useState<number>(0);
    const [moreInfo, setMoreInfo] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0);
	const [modalPictures, setModalPictures] = useState<any[]>([]);

	const togglePictureModal = () => {
        let toggle_pictures = getTogglePictures();
		setOpenPicture(!openPicture);
        setModalPictures(toggle_pictures);
	};
	const toggleMosaicModal = () => {
        let toggle_pictures = getTogglePictures();
        setModalPictures(toggle_pictures);
		setOpenMozaic(!openMozaic);
    };
    const handleOpenMore = (new_step: number) => () =>  {
        setStep(new_step);
        setMoreInfo(!moreInfo);
    }
    const getTogglePictures = () => {
        if (activity.overload_pictures.length !== 0) {
            return activity.overload_pictures;
        } else {
            let pictures = [];
            if (custom_product.cover_picture !== null && custom_product.cover_picture.url !== undefined) {
                pictures.push(custom_product.cover_picture)
            }
            pictures = [...pictures, ...custom_product.pictures];
            return pictures;
        }
    }
    const getPicture = () => {
        let pictures = [];
        if (activity.overload_pictures.length !== 0) {
            for (let i = 0; i < activity.overload_pictures.length && pictures.length < 5; i++) {
                if (activity.custom_product.cover_picture === null || activity.custom_product.cover_picture.id !== activity.overload_pictures[i].id) {
                    pictures.push(GetPictureBySize(activity.overload_pictures[i], "BIG"));
                }
            }
        } else {
            if (custom_product.cover_picture !== null && custom_product.cover_picture.url !== undefined) {
                pictures.push(GetPictureBySize(custom_product.cover_picture, "BIG"))
            }
            for (let i = 0; i < custom_product.pictures.length && pictures.length < 5; i++) {
                if (custom_product.cover_picture === null || custom_product.cover_picture.url === undefined || custom_product.cover_picture.id !== custom_product.pictures[i].id) {
                    pictures.push(GetPictureBySize(custom_product.pictures[i], "BIG"));
                }
            }
            if (custom_product.pictures.length === 0) {
                for (let i = 0; i < custom_product.pictures.length && pictures.length < 5; i++) {
                    if (custom_product.cover_picture === null || custom_product.cover_picture.id !== custom_product.pictures[i].id) {
                        pictures.push(GetPictureBySize(custom_product.pictures[i], "BIG"));
                    }
                }
            }
        }
        if (pictures.length > 1) {
            while (pictures.length < 5) {
                pictures.push("/Img/hotel.png");
            }
        }
        return pictures;
    }
    let address = "";
    
    if (activity.custom_product.place !== undefined) {
        let place = Object.assign({}, activity.custom_product.place);
        if (place.address !== null && place.address !== "") {
            address += place.address;
        }
        if (place.city_name !== null && place.city_name !== "") {
            address += " " + place.city_name;
        }
        if (place.country_name !== null && place.country_name !== "") {
            address += " " + place.country_name;
        }
    }
    let check_in_cart = products.find((product: any) => {
        if (product.start_destination !== undefined && product.start_destination !== null && activity.start_destination !== undefined && activity.start_destination !== null) {
    		return activity.start_destination.data.id === product.start_destination.data.id && activity.product_type === product.product_type && !product.is_optional;
        }
	});
    let check_option = products.find((product: any) => {
        if (product.start_destination !== undefined && product.start_destination !== null && activity.start_destination !== undefined && activity.start_destination !== null) {
    		return activity.start_destination.data.id === product.start_destination.data.id && activity.product_type === product.product_type && product.is_optional;
        }
	});
    return (
        <Grid container direction={"row"} spacing={2} style={{marginBottom: 10, padding: 10}}>
            <Grid item xs container direction={"column"} alignItems={"flex-start"}>
				<Grid item>
                    {
                        custom_product.special_attribute !== "TRANSPORT_PASS" && (
                            <VisibilityIcon className={classes.genericText} style={{verticalAlign: "sub", marginRight: 10, color: `${main_color}`}}/>
                        )
                    }
                    {
                        custom_product.special_attribute === "TRANSPORT_PASS" && (
                            <CommuteIcon className={classes.genericText} style={{verticalAlign: "sub", marginRight: 10, color: `${main_color}`}}/>
                        )
                    }
					<span className={classes.genericText} style={{fontWeight: 'bold', textTransform: 'uppercase'}}>{ localeText(current_locale.id, activity.custom_product.localization, activity.custom_product.title, 'title') } </span>
				</Grid>
                {
                    address !== null && address !== "" && (
                        <Grid item>
                            <RoomIcon className={`${classes.genericText}`} style={{fontSize: 16, verticalAlign: "text-top", color: `${main_color}`}}/>
                            <span className={`${classes.genericText} ${classes.italic}`} style={{fontSize: 14}}>{address}</span>
                        </Grid>
                    )
                }
			</Grid>
            <Grid item container direction={"row"} justify={"flex-start"} alignItems={"center"} spacing={activity.is_optional || fullScreen ? 2 : 0}>
                {
                    getPicture().length === 1 &&
                        <ByDayPictures pictures={activity.overload_pictures.length !== 0 ? activity.overload_pictures : custom_product.pictures} is_step={true}/>
                }
				{
                    getPicture().length > 1 &&
                        <AccommodationCardPictures size={150} detail={activity.overload_pictures.length !== 0 ? activity : activity.custom_product} pictures={getPicture()} togglePictureModal={togglePictureModal} toggleMosaicModal={toggleMosaicModal}/>
                }
                <Grid item xs={12} className={clsx({[classes.paddingLeft25]: !fullScreen})} style={{marginTop: 10}}>
                    <Grid container direction={"column"} spacing={2}>
                        <Grid item>
                            <Typography variant={"h6"}>{ t<string>("accommodation.description") }</Typography>
                        </Grid>
                        {
                            custom_product.taking_address !== undefined && custom_product.taking_address !== null && custom_product.taking_address !== "" && (
                                <Grid item className={classes.genericText}>
                                { t("poi.taking_address") } : { custom_product.taking_address } 
                                </Grid>
                            )
                        }
                        <Grid item>
                            <div className={`${classes.genericText} ${classes.shortDescription}`} dangerouslySetInnerHTML={ {__html: custom_product.short_description } } />
                            <Button className={`${classes.genericText} ${classes.lowerCase}`} style={{padding: '15px 40px 15px 40px', marginTop: 10}} variant={"outlined"} onClick={handleOpenMore(0)}>{ t<string>("itineraryType.more_details")} <ArrowForwardIcon/></Button>
                        </Grid>
                    </Grid>
				</Grid>
                {
                    check_option &&
                    <Grid item xs={12} style={{marginTop: 10, border: "1px solid #e5e7eb", width: '100%', borderRadius: 10, margin:'8px 0px 8px 0px', paddingLeft: 20}} className={clsx(classes.genericText, {[classes.activityOptionSelected]: variantIsChecked(activity)})}>
                        <FormControlLabel
                            control={<Checkbox sx={{color: `${main_color}`,
                            '&.Mui-checked': {
                                color: `${main_color}`,
                            },
                        }} className={ classes.checkbox } edge={ 'start' } disableRipple checked={ variantIsChecked(activity) } onChange={ onToggleVariant(activity) } />}
                            label={ activity.is_optional ? (check_in_cart ? t<string>("itineraryType.alternate_activity") : t<string>("itineraryType.optional_activity")) : t<string>("itineraryType.default_activity", {type: t('shared.' + type)})}
                        />
                    </Grid>
                }
            </Grid>
            <MoreInfo activity={activity} info={custom_product} moreInfo={moreInfo} setMoreInfo={setMoreInfo} step={step} setStep={setStep}/>
            <MosaicPicture open={ openMozaic } onClose={ toggleMosaicModal } pictures={ modalPictures } openModal={ togglePictureModal }/>
			<Picture open={ openPicture } onClose={ togglePictureModal } pictures={ modalPictures } current_picture={ current_picture } setCurrentPicture={ setCurrentPicture }/>
        </Grid>
    )
}
export default ActivitiesCard