import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../Reducers/Reducers';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type CartCancelPriceProps = {
    product: any;
    cancellation: any;
}

export function CartCancelPriceDate(props: CartCancelPriceProps) : JSX.Element | null {
    const { product, cancellation } = props;
    const { t } = useTranslation();
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const prices = product.prices.find((price: any) => price.is_tva);
    const currency_price = prices !== undefined ? currency_list.find((currency: {id: number, iso_code: string}) => currency.id === prices.selling_currency) : {id: 47, iso_code: 'EUR'};
    const current_date:Date = new Date();
    const [newPrice, setNewPrice] = useState<number>(0);
    useEffect(() => {
        if (cancellation !== null) {
            if (cancellation.type === 'STANDARD') {
                if (moment(product.start_date).isSameOrAfter(moment(current_date), 'd')) {
                    setNewPrice(0);
                } else {
                    setNewPrice(prices.selling_price);
                }
            } else if (cancellation.type === 'ALL_SALES_FINAL') {
                setNewPrice(prices.selling_price);
            } else if (cancellation.type === 'CUSTOM') {
                let diff = moment(product.start_date).diff(moment(current_date), 'days');
                cancellation.refundEligibility.forEach((refundEligibility: any) => {
                    if (refundEligibility.dayRangeMax === undefined && diff >= refundEligibility.dayRangeMin) {
                        setNewPrice(0);
                    } else {
                        if (diff >= refundEligibility.dayRangeMin && diff < refundEligibility.dayRangeMax) {
                            setNewPrice(prices.selling_price - (prices.selling_price * (refundEligibility.percentageRefundable / 100)));
                        }
                    }

                });
            }
        }
    }, [cancellation]);
    return (
        <Grid>
            <Typography variant="h6">{`${t<string>('cart-material.cancellation-fee')} : ${new Intl.NumberFormat(language, { style: "currency", currency: currency_price?.iso_code }).format(newPrice)}`}</Typography>
        </Grid>
    );
}
