import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Tooltip from '@material-ui/core/Tooltip';
import CheckBox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from "@material-ui/lab/Alert";

import CheckIcon from '@material-ui/icons/Check';
// Actions
import { AddFileToDl, ClearFilesToDl, SetTripDocs, SetTripDocsDoc } from "../../Actions/Menu";
import { SetSummaryStep } from "../../Actions/Summary";
// Components
import SummaryHeader from './SummaryHeader';
import PlaneLoader from '../PlaneLoader/PlaneLoader.jsx';
import SummaryHeaderMobile from "./SummaryHeaderMobile";
import Footer from "../Footer/Footer.jsx";
// Functions
import GetTripInfo from "./Functions/GetTripInfo";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import SortByKey from "../Common/SortByKey";
import GetProductType from "../Menu/MaterialTripList/Functions/GetProductType";
import GetOutOfCart from './Functions/GetOutOfCart';
import GetTotalPrices from './Functions/GetTotalPrices';
import GetPrice from "./Functions/GetPrice";

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400:{
        fontWeight: 400
    },
    fontWeight700:{
        fontWeight: 700
    },
    fontWeight900:{
        fontWeight: 900
    },
    fontSize10:{
        fontSize: 10
    },
    fontSize12:{
        fontSize: 12
    },
    fontSize16:{
        fontSize: 16
    },
    fontSize20:{
        fontSize: 20
    },
    fontSize24:{
        fontSize: 24
    },
    upperCase:{
        textTransform: "uppercase",
    },
    capitalize:{
        textTransform: "capitalize"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter:{
        textAlign: "center"
    },
    textRight:{
        textAlign: "right"
    },
    textLeft:{
        textAlign: "left"
    },
    genericBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box"
    },
    genericPadding:{
        padding: "0px 20px"
    },
    genericPaddingMobile:{
        padding: "0px 8px"
    },
    categoryPadding:{
        padding: 8
    },
    spaceTop:{
        marginTop: 20
    },
    spaceTopMobile:{
        marginTop: 10
    },
    spacer:{
        margin: "16px 0px"
    },
    spacerMobile:{
        margin: "8px 0px"
    },
    indentation:{
        paddingLeft: 16
    },
    borderTableCell:{
        borderBottom: "none"
    },
    checkboxCellWidth:{
        width: 150
    },
    colorBlue:{
        color: '#4A75DA',
    },
    colorGrey:{
        color: "#808080"
    },
    hrText: {
        lineHeight: "20px",
        position: "relative",
        outline: "0",
        border: "0",
        textAlign: "center",
        height: 20,
        fontSize: 34,
        '&::before': {
            content: "''",
            background: "#0000008A",
            position: "absolute",
            left: "0",
            top: "50%",
            width: "100%",
            height: "1px"
        },
        '&::after': {
            content: "attr(data-content)",
            position: "relative",
            display: "inline-block",
            color: "#0000008A",
            padding: "0 .5em",
            // lineHeight: "1.5em",
            backgroundColor: "white"
        }
    },
    redText: {
        color: "red"
    },
    greenText: {
        color: "green"
    },
    absoluteCenter: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    lightGreen: {
        color: "#76B6C2 !important"
    }
}));

const getVoucherUrl = (type, voucher) => {
    let url = `${API_HREF}`;
    if (type === 1) {
        url += voucher.url;
    }
    if (type === 2) {
        url += voucher.url_pdf;
    }
    return url;
};

const getQuotationUrl = (preview, trip_main_info, version) => {
    let url = `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_main_info.current_version}/quotation/?price_by_category=y&price_by_product=n&generate_for_target=y`;
    url += preview === true ? "&preview=y" : "&preview=n";
    return url;
};

const getIndexNbForVersion = (array, nb) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i].id === nb) {
            return (i + 1);
        }
    }
    return 1;
};

const getFileName = (trip_main_info, trip_displayed_version, doc, voucher, traveler, label, t) => {
    let index_version = getIndexNbForVersion(trip_main_info.data, trip_displayed_version);
    let voucher_name = null;
    if (voucher !== null){
        voucher_name = voucher.name.replaceAll("/", "-");
    }
    let name_split = doc.file_name !== null ? doc.file_name.split(".") : [];
    let extention = name_split.length !== 0 ? name_split[name_split.length - 1] : "";
    switch(doc.type){
        case "CARD":
            return t("menu.file_nb") + trip_main_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("menu.trip_list.id_card") + " " + t("global.of") + " " + t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name + "." + extention;
        case "PASSPORT":
            return t("menu.file_nb") + trip_main_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("menu.trip_list.passport") + " " + t("global.of") + " " + t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name + "." + extention;
        case "E-VISA":
            return t("menu.file_nb") + trip_main_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + "e-Visa" + " " + t("global.of") + " " + t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name + "." + extention;
        case "VOUCHER_FLIGHT":
            return t("menu.file_nb") + trip_main_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("global.plane_ticket") + " " + t("global.of") + " " + t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name + "." + extention;
        case "VOUCHER":
            return t("menu.file_nb") + trip_main_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("global.voucher") + " " + t("providers." + GetProductType(voucher.product_type)) + " : " + voucher_name + "." + extention;
        case "VOUCHER_HOTELS":
            return t("menu.file_nb") + trip_main_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("global.voucher") + " " + t('accommodation.hotels') + "." + extention;
        case "QUOTATION":
            return t("menu.trip_list.quotation_title") + trip_main_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + ".pdf";
        case "CONDITIONS":
            return t("menu.file_nb") + trip_main_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("menu.trip_list.conditions") + "." + extention;
        case "TRAVELBOOK":
            return t("menu.file_nb") + trip_main_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("menu.trip_list.travelbook") + "." + extention;
        case "HOTELCARD":
            return t("menu.file_nb") + trip_main_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + label + "." + extention;
        case "CONTRACT":
            return t("menu.file_nb") + trip_main_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("menu.trip_list.contract") + "." + extention ;
        default:
            return doc.file_name;
    }
}

const DocumentActionBar = (({ mode, traveler, label, documentObject, trip_displayed_version, voucher, update, setUpdate }) =>{
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(768));

    const trip_info = useSelector(store => store.summary.trip_info);
    const trip_main_info = useSelector(store => store.summary.trip_main_info);
    const user = useSelector(store => store.user.user);

    const { enqueueSnackbar } = useSnackbar();

    const [requested, setRequested] = useState(false);
    const [received, setReceived] = useState(false);
    const bucketName = "facilitatrip-documents";
    const [uploadSpinner, setUploadSpinner] = useState(false);
    const [seeSpinner, setSeeSpinner] = useState(false);
    const [downloadSpinner, setDownloadSpinner] = useState(false);

    useEffect(() =>{
        setReceived(documentObject.received);
        setRequested(documentObject.requested);
    }, []);
    useEffect(() =>{
        if (update) {
            setReceived(documentObject.received);
            setRequested(documentObject.requested);
            setUpdate(false);
        }
    }, [update]);

    useEffect(() => {
        if (bucketName !== "") {
            if (mode === "voucher" && !documentObject.received) {
                // auto generate voucher or quotation if not received yet in AWS
                updateDocument()
            } else if (mode === "quotation") {
                updateDocument()
            }
        }
    }, [bucketName]);

    const handleReceived = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check){
            let request = {
                "received": true,
                "requested": false
            }
            axios({
                method: "PATCH",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_displayed_version}/file/${documentObject.id}/?token=${trip_main_info.token}`,
                headers: headers,
                data: JSON.stringify(request)
            }).then(function(response){
                setReceived(true);
                setRequested(false);
                dispatch(SetTripDocsDoc(response.data));
            })
        }
    }

    const checkFile = (file) => {
        const allowedExtensions = /(\.pdf)$/i;

        if (!allowedExtensions.exec(file.target.value)) {
            return false;
        }
        else {
            return true;
        }
    }
    const uploadDocument = (e) => {
        setUploadSpinner(true);
        selected_file = e.target.files[0];
        let check = checkFile(e);
        if (check) {
            let formData = new FormData();
            const domain = window.location.host.replace("www.", "").split('.');
            formData.append("file_object", selected_file);
            formData.append("bucket_name", bucketName);
            formData.append("prefix", `${domain[0]}-${domain[2] === 'com' ? 'prod' : 'pre-prod'}`);
            formData.append("type", documentObject.type);
            formData.append("tripdocument_id", documentObject.id)
            if(traveler !== null){
                formData.append("traveler", traveler.id)
            }else if (documentObject.product !== null){
                formData.append("product", documentObject.product)
            }

            let { pass_check, headers } = CheckBeforeRequest();
            headers["Content-Type"] = "multipart/form-data";

            if (pass_check){
                axios({
                    method: "POST",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_displayed_version}/file/upload_to_aws/?token=${trip_main_info.token}`,
                    headers: headers,
                    data: formData
                }).then(function(response){
                    enqueueSnackbar(t("menu.trip_list.success_doc_upload"), { variant: "success", disableWindowBlurListener: true });
                    setReceived(true);
                    handleReceived();
                    dispatch(SetTripDocsDoc(response.data));
                    setUploadSpinner(false);
                }).catch(function(error){
                    console.log(error.responseJSON);
                    enqueueSnackbar(t("menu.trip_list.failed_doc_upload"), { variant: "error", disableWindowBlurListener: true });
                    setUploadSpinner(false);
                })
            }
            // clear value to trigger onChange even when uploading same file
            e.target.value = null;
        }
        else {
            e.target.value = null;
            setUploadSpinner(false);
            enqueueSnackbar(t("menu.trip_list.failed_doc_upload_pdf"), { variant: "error", disableWindowBlurListener: true });
        }
    };

    const downloadDocument = () => {
        setDownloadSpinner(true);
        if(received){
            let object_name = `${documentObject.type}-TRIP%23${trip_main_info.id}-VER%23${trip_displayed_version}`;
            if(traveler != null){
                object_name += `-ID-${traveler.id}`;
            }else if (documentObject.product !== null){
                object_name += `-ID-${documentObject.product}`;
            }
            let file_name = getFileName(trip_main_info, trip_displayed_version, documentObject, voucher, traveler, label, t);

            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check){
                const domain = window.location.host.replace("www.", "").split('.');
                axios({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_displayed_version}/file/download_from_aws/?token=${trip_main_info.token}&bucket_name=${bucketName}&object_name=${domain[0]}-${domain[2] === 'com' ? 'prod' : 'pre-prod'}/${object_name}&file_name=${file_name}`,
                    headers: headers,
                    responseType: 'arraybuffer'
                }).then(function(response){
                    let blob = new Blob([response.data], {type: "application/pdf"});
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = file_name;
                    link.click();
                    setDownloadSpinner(false);

                }).catch(function(error){
                    console.log(error.responseJSON);
                    enqueueSnackbar(t("menu.trip_list.failed_doc_download"), { variant: "warning", disableWindowBlurListener: true });
                    setDownloadSpinner(false);
                })
            }
        }
    };

    const seeDocument = () => {
        setSeeSpinner(true);
        if (received){
            let object_name = `${documentObject.type}-TRIP%23${trip_main_info.id}-VER%23${trip_displayed_version}`;
            if(traveler != null){
                object_name += `-ID-${traveler.id}`;
            }else if (documentObject.product !== null){
                object_name += `-ID-${documentObject.product}`;
            }
            let file_name = getFileName(trip_main_info, trip_displayed_version, documentObject, voucher, traveler, label, t);

            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check){
                const domain = window.location.host.replace("www.", "").split('.');
                axios({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_displayed_version}/file/download_from_aws/?token=${trip_main_info.token}&bucket_name=${bucketName}&object_name=${domain[0]}-${domain[2] === 'com' ? 'prod' : 'pre-prod'}/${object_name}&file_name=${file_name}`,
                    headers: headers,
                    responseType: 'arraybuffer'
                }).then(function(response){
                    let blob = new Blob([response.data], {type: "application/pdf"});
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    setSeeSpinner(false);
                    window.open(link)
                }).catch(function(error){
                    console.log(error.responseJSON);
                    enqueueSnackbar(t("menu.trip_list.failed_doc_preview"), { variant: "warning", disableWindowBlurListener: true });
                    setSeeSpinner(false);
                })
            }
        }
    };

    const updateDocument = () =>{
        setUploadSpinner(true);
        let doc_url = "";
        if(mode === "voucher"){
            doc_url = getVoucherUrl(2, voucher);
        }else if(mode === "quotation"){
            doc_url = getQuotationUrl(false, trip_main_info, trip_displayed_version);
        }

        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check){
            const domain = window.location.host.replace("www.", "").split('.');
            axios({
                method: "GET",
                url: doc_url,
                headers: headers,
                responseType: 'arraybuffer'
            }).then(function(response){
                let blob = new Blob([response.data], {type: "application/pdf"});
                let formData = new FormData();
                formData.append("file_object", blob);
                formData.append("bucket_name", bucketName);
                formData.append("type", documentObject.type);
                formData.append("prefix", `${domain[0]}-${domain[2] === 'com' ? 'prod' : 'pre-prod'}`);
                formData.append("tripdocument_id", documentObject.id);
                if(traveler !== null){
                    formData.append("traveler", traveler.id)
                }else if (documentObject.product !== null){
                    formData.append("product", documentObject.product)
                }

                let { pass_check, headers } = CheckBeforeRequest();
                headers["Content-Type"] = "multipart/form-data";

                if (pass_check){
                    axios({
                        method: "POST",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_main_info.current_version}/file/upload_to_aws/?token=${trip_main_info.token}`,
                        headers: headers,
                        data: formData
                    }).then(function(response){
                        enqueueSnackbar(t("menu.trip_list.success_doc_upload"), { variant: "success", disableWindowBlurListener: true });
                        setReceived(true);
                        dispatch(SetTripDocsDoc(response.data));
                        setUploadSpinner(false);
                    }).catch(function(error){
                        console.log(error.responseJSON);
                        enqueueSnackbar(t("menu.trip_list.failed_doc_upload"), { variant: "error", disableWindowBlurListener: true });
                        setUploadSpinner(false);
                    })
                }
            }).catch(function(error){
                console.log(error.responseJSON);
                enqueueSnackbar(t("menu.trip_list.failed_doc_upload"), { variant: "error", disableWindowBlurListener: true });
                setUploadSpinner(false);
            })
        }
    }

    if(mode === "traveler"){
        return(
            <Fragment>
                <TableRow>
                    <TableCell className={`${!mobile ? classes.indentation : ""} ${classes.borderTableCell} ${classes.genericText} ${!mobile ? classes.fontWeight700 : classes.fontWeight400} ${!mobile ? classes.fontSize12 : classes.fontSize10}`} colSpan={mobile ? 2 : 1}>
                        <span>
                            {t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name}
                        </span>
                    </TableCell>
                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${mobile ? classes.textCenter : classes.textLeft} ${!mobile ? classes.fontSize12 : classes.fontSize10}`} colSpan={1}>
                        {
                            requested && !received && (
                                <span className={`${classes.genericText} ${classes.redText}`}>{t("menu.trip_list.requested_doc")}</span>
                                )
                            }
                        {
                            received && !requested && (
                                <span className={`${classes.genericText} ${classes.greenText}`}>
                                    <CheckIcon style={{verticalAlign: "sub", fontSize: 16, marginRight: 5}} />
                                    {t("menu.trip_list.received_doc")}
                                </span>
                            )
                        }
                        {
                            !requested && !received && (
                                <span></span>
                            )
                        }
                    </TableCell>
                    {
                        !mobile && (
                            <Fragment>
                                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                                    <Tooltip title={t("global.upload")}>
                                        <IconButton size="small" className={classes.colorBlue} component="label">
                                            { uploadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <PublishOutlinedIcon /> }
                                            <input hidden type="file" onChange={(e) => uploadDocument(e)} />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                                    <Tooltip title={t("menu.trip_list.see_document")}>
                                        <span>
                                            <IconButton size="small" className={received ? classes.colorBlue: classes.colorGrey} onClick={seeDocument} disabled={!received}>
                                                { seeSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <PictureAsPdfOutlinedIcon /> }
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                                    <Tooltip title={t("global.download")}>
                                        <span>
                                            <IconButton size="small" className={received ? classes.colorBlue: classes.colorGrey} onClick={downloadDocument} disabled={!received}>
                                                { downloadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <SaveAltOutlinedIcon /> }
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </TableCell>
                            </Fragment>
                        )
                    }
                </TableRow>
                {
                    mobile && (
                        <TableRow>
                            <TableCell className={`${classes.borderTableCell} ${classes.textLeft}`} style={{ width: 0 }} colSpan={1}>
                                <Tooltip title={t("global.upload")}>
                                    <IconButton size="small" className={classes.colorBlue} component="label">
                                        { uploadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <PublishOutlinedIcon /> }
                                        <input hidden type="file" onChange={(e) => uploadDocument(e)} />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }} colSpan={mobile ? 2 : 1}>
                                <Tooltip title={t("menu.trip_list.see_document")}>
                                    <span>
                                        <IconButton size="small" className={received ? classes.colorBlue: classes.colorGrey} onClick={seeDocument} disabled={!received}>
                                            { seeSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <PictureAsPdfOutlinedIcon /> }
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={`${classes.borderTableCell} ${classes.textRight}`} style={{ width: 0 }} colSpan={1}>
                                <Tooltip title={t("global.download")}>
                                    <span>
                                        <IconButton size="small" className={received ? classes.colorBlue: classes.colorGrey} onClick={downloadDocument} disabled={!received}>
                                            { downloadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <SaveAltOutlinedIcon /> }
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    )
                }
            </Fragment>
        )
    }else if(mode === "voucher" || mode === "quotation"){
        return(
            <Grid container alignItems="center" justify="space-between" style={{ height: 42 }}>
                <Grid item className={classes.textCenter}>
                    <Tooltip title={documentObject.received ? t("menu.trip_list.update_doc") : t("menu.trip_list.generate_doc")}>
                        <IconButton size="small" className={classes.colorBlue} onClick={updateDocument}>
                        { uploadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <RefreshOutlinedIcon /> }
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item className={classes.textCenter}>
                    <Tooltip title={t("menu.trip_list.see_document")} >
                        <span>
                            <IconButton size="small" className={received ? classes.colorBlue: classes.colorGrey} onClick={seeDocument} disabled={!received}>
                                { seeSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <PictureAsPdfOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item className={classes.textCenter}>
                    <Tooltip title={t("global.download")}>
                        <span>
                            <IconButton size="small" className={received ? classes.colorBlue: classes.colorGrey} onClick={downloadDocument} disabled={!received}>
                                { downloadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <SaveAltOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        )
    }else if(mode === "row"){
        return(
            <TableRow>
                <TableCell className={`${!mobile ? classes.indentation : ""} ${classes.borderTableCell} ${classes.genericText} ${!mobile ? classes.fontWeight700 : classes.fontWeight400} ${!mobile ? classes.fontSize12 : classes.fontSize10}`}>
                    {label}
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("menu.trip_list.see_document")}>
                        <span>
                            <IconButton size="small" className={received ? classes.colorBlue: classes.colorGrey} onClick={seeDocument} disabled={!received}>
                                { seeSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <PictureAsPdfOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                    <Tooltip title={t("global.download")}>
                        <span>
                            <IconButton size="small" className={received ? classes.colorBlue: classes.colorGrey} onClick={downloadDocument} disabled={!received}>
                                { downloadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <SaveAltOutlinedIcon /> }
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>
            </TableRow>
        )
    }
})

const SummaryDocuments = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(768));
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { enqueueSnackbar } = useSnackbar();

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const trip_info = useSelector(store => store.summary.trip_info);
    const trip_main_info = useSelector(store => store.summary.trip_main_info);
    const trip_docs = useSelector(store => store.menu.trip_docs);
	const hotel_cart = useSelector(store => store.summary.hotel_cart);
	const cars_cart = useSelector(store => store.summary.cars_cart);
	const flight_cart = useSelector(store => store.summary.flight_cart);
	const manual_cart = useSelector(store => store.summary.manual_cart);
	const transfers_cart = useSelector(store => store.summary.transfers_cart);
	const poi_cart = useSelector(store => store.summary.poi_cart);
	const selectedProducts = useSelector(store => store.summary.selectedProducts);
	const itinerary = useSelector(store => store.summary.itinerary);
    const files_to_dl = useSelector(store => store.menu.files_to_dl);

    const [isLoading, setIsLoading] = useState(false);
	const [missingDocs, setMissingDocs] = useState(0);
    const [displayIDCard, setDisplayIDCard] = useState(false);
    const [displayPassport, setDisplayPassport] = useState(false);
    const [displayEVisa, setDisplayEVisa] = useState(false);
    const [displayIdentity, setDisplayIdentity] = useState(false);
    const [carVoucher, setCarVoucher] = useState([]);
    const [roomVoucher, setRoomVoucher] = useState([]);
    const [flightVoucher, setFlightVoucher] = useState([]);
    const [activityVoucher, setActivityVoucher] = useState([]);
    const [transferVoucher, setTransferVoucher] = useState([]);
    const [voucherAreLoaded, setVoucherAreLoaded] = useState(false);
    const [update, setUpdate] = useState(false);
    const [requestedDoc, setRequestedDoc] = useState(false);
    const [travelers, setTravelers] = useState([]);
    const [trip_displayed_version, setTripDisplayVersion] = useState(null);

    useEffect(() =>{
        dispatch(SetSummaryStep(5));
        if (trip_info === null) {
            setIsLoading(true);
            GetTripInfo(props.params.trip_id, props.params.trip_token, dispatch, setIsLoading);
        }
    }, []);
    useEffect(() => {
        if (update) {
            checkDocuments();
        }
    }, [update]);
    // useEffect(() =>{
    //     for (travelers_index = 0 ; travelers_index < travelers.length ; travelers_index++){
    //         if(travelers[travelers_index].identity_doc === "PASSPORT" && displayPassport === false){
    //             setDisplayPassport(true);
    //         }
    //         if(travelers[travelers_index].identity_doc === "CARD" && displayIDCard === false){
    //             setDisplayIDCard(true);
    //         }
    //     }
    // }, [travelers])

    useEffect(() =>{
        if(voucherAreLoaded === true && travelers.length !== 0){
            refreshDocs();
            createAllDocs();
            setVoucherAreLoaded(false);
            checkDocuments();
        }
    }, [voucherAreLoaded, travelers])

	useEffect(() => {
        let { pass_check, headers } = CheckBeforeRequest();
		if (trip_main_info !== null) {
            if (trip_docs.length === 0) {
                getDoc();
            }
            let info = trip_main_info.data.find((data) => data.id === trip_main_info.current_version);
            setTravelers(info.travelers);
            setTripDisplayVersion(trip_main_info.current_version);

            if (pass_check){
                axios({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_main_info.current_version}/voucher/get_voucher_list/`,
                    headers:headers
                }).then(function(response){
                    let temp_room_voucher = [];
                    let temp_flight_voucher = [];
                    let temp_car_voucher = [];
                    let temp_activity_voucher = [];
                    let temp_transfer_voucher = [];

                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].product_type === 0 || response.data[i].product_type === 7) {
                            temp_room_voucher.push(response.data[i]);
                        } else if (response.data[i].product_type === 6) {
                            temp_flight_voucher.push(response.data[i]);
                        } else if (response.data[i].product_type === 2) {
                            temp_car_voucher.push(response.data[i]);
                        } else if (response.data[i].product_type === 4) {
                            temp_transfer_voucher.push(response.data[i]);
                        } else {
                            temp_activity_voucher.push(response.data[i]);
                        }
                    }
                    setCarVoucher(temp_car_voucher.sort(SortByKey(true, "start_date")));
                    setRoomVoucher(temp_room_voucher.sort(SortByKey(true, "start_date")));
                    setFlightVoucher(temp_flight_voucher.sort(SortByKey(true, "start_date")));
                    setActivityVoucher(temp_activity_voucher.sort(SortByKey(true, "start_date")));
                    setTransferVoucher(temp_transfer_voucher.sort(SortByKey(true, "start_date")));
                    setVoucherAreLoaded(true);
                })
            }
        }
	}, [trip_main_info]);
	useEffect(() => {
		if (!isLoading) {
            GetPrice(itinerary, flight_cart, hotel_cart, manual_cart, poi_cart, cars_cart, transfers_cart, selectedProducts, trip_info, dispatch);
		}
	}, [isLoading]);
	useEffect(() => {
		if (trip_docs.length !== 0) {
			let total_requested = 0;
			let total_received = 0;
			trip_docs.map((document) =>{
				if(document.requested === true){
					total_requested += 1;
					if(document.received === true){
						total_received += 1;
					}
				}
			});
			let temp_missing_docs = total_requested - total_received;
			setMissingDocs(temp_missing_docs);
		}
	}, [trip_docs]);
    useEffect(() => {
        if (files_to_dl.length > 0){
            let nb_docs = 0;
            trip_docs.map((doc) => {
                if (doc.received && doc.bucket_name !== null)
                nb_docs += 1;
            })
            if (nb_docs === files_to_dl.length){
                let link = document.createElement('a');
                var JSZip = require("jszip");
                var zip = new JSZip();

                files_to_dl.map((file) => {
                    zip.file(file.file_name, file.blob, { "binary": true, createFolders: false });
                })
                zip.generateAsync({type:"blob"})
                .then(function (blob) {
                    link.href = window.URL.createObjectURL(blob);
                    let index_version = getIndexNbForVersion(trip_main_info.data, trip_displayed_version);
                    let zip_name = t("menu.trip_list.documents") + " " + t("menu.file_nb") + trip_main_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version
                    link.download = zip_name;
                    link.click();
                    dispatch(ClearFilesToDl());
                });
            }
        }
    }, [files_to_dl])
	const getDoc = () => {
        const { headers } = CheckBeforeRequest();
        console.log('trip_main_info:', trip_main_info);
		axios({
			method: 'GET',
			url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_main_info.current_version}/file/?token=${props.params.trip_token}`,
			headers: headers,
		}).then(function(response){
			dispatch(SetTripDocs(response.data.results));
		}).catch(function(error){
			console.log(error.responseJSON);
		});
	};

    const checkDocExists = (doc_type, traveler, product_id) => {
        let docFound = false;
        let traveler_to_check = traveler !== null ? traveler.id : traveler;
        trip_docs.map((doc) => {
            if(doc.type === doc_type && doc.traveler === traveler_to_check && doc.product === product_id){
                docFound = true;
            }
        })

        return docFound;
    }
    const checkDocuments = () => {
        let tmp_passport =  trip_docs.find((doc) => {
            return doc.type === "PASSPORT" && (doc.requested || doc.received);
        });
        let tmp_eVisa = trip_docs.find((doc) => {
            return doc.type === "E-VISA" && (doc.requested || doc.received);
        });
        let tmp_requested = trip_docs.find((doc) => {
            return doc.requested;
        });
        setRequestedDoc(tmp_requested !== undefined ? true : false);
        setDisplayPassport(tmp_passport !== undefined ? true : false);
        setDisplayEVisa(tmp_eVisa !== undefined ? true : false);
        setDisplayIdentity(tmp_passport !== undefined || tmp_eVisa !== undefined ? true : false);
    }
    const createDocObject = (doc_type, traveler, product_id) => {
        let docFound = checkDocExists(doc_type, traveler, product_id);
        if (docFound === false){
            let { pass_check, headers } = CheckBeforeRequest();
            let request = {
                "type": doc_type,
                "traveler": traveler !== null ? traveler.id : traveler,
                "product": product_id
            }
            if (pass_check){
                axios({
                    method: "POST",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_main_info.current_version}/file/?token=${trip_main_info.token}`,
                    headers: headers,
                    data: JSON.stringify(request)
                })
            }
        }
    }

    const createAllDocs = () => {
        if(quotation_code !== "marcovasco"){
            createDocObject("QUOTATION", null, null);
        }
        flightVoucher.map((voucher) => {
            createDocObject("VOUCHER", null, voucher.product_ids[0]);
        })
        carVoucher.map((voucher) => {
            createDocObject("VOUCHER", null, voucher.product_ids[0]);
        })
        roomVoucher.map((voucher) => {
            createDocObject("VOUCHER", null, voucher.product_ids[0]);
        })
        activityVoucher.map((voucher) => {
            createDocObject("VOUCHER", null, voucher.product_ids[0]);
        })
        transferVoucher.map((voucher) => {
            createDocObject("VOUCHER", null, voucher.product_ids[0]);
        })

        if(['cercledesvoyages', 'continentsinsolites', 'verdie', 'connections', 'terreslointaines'].includes(quotation_code)){
            createDocObject("CONDITIONS", null, null);
            createDocObject("TRAVELBOOK", null, null);
            createDocObject("CONTRACT", null, null)
            travelers.map((traveler) => {
                createDocObject(traveler.identity_doc, traveler, null);
                createDocObject("E-VISA", traveler, null);
            })
            hotel_cart.map((accommodation) => {
                if (accommodation.booking_status !== null && accommodation.booking_status.status_booking === "CONFIRMED"){
                    createDocObject("HOTELCARD", null, accommodation.id);
                }
            })
        }
    }

    const refreshDocs = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if(pass_check){
            axios({
                method: "GET",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_main_info.current_version}/file/?token=${trip_main_info.token}`,
                headers: headers,
            }).then(function(response){
                dispatch(SetTripDocs(response.data.results));
                setUpdate(true);
            }).catch(function(error){
                console.log(error.responseJSON);
            })
        }
    }
    const downloadAllDocs = () => {
        trip_docs.map((doc) => {
            if (doc.received && doc.bucket_name !== null){
                let object_name = `${doc.type}-TRIP%23${trip_info.id}-VER%23${trip_displayed_version}`;
                let traveler = null;
                let label = "";
                let accommodation = null;

                if(doc.traveler != null){
                    object_name += `-ID-${doc.traveler}`;
                    traveler = travelers.find(item => item.id === doc.traveler);
                }else if (doc.product !== null){
                    object_name += `-ID-${doc.product}`;
                }else if (doc.hotel !== null){
                    object_name += `-ID-${doc.hotel}`;
                    for (room_index=0 ; room_index<accommodation_cart.length ; room_index++){
                        if(accommodation_cart[room_index].booking_status !== null && accommodation_cart[room_index].booking_status.status_booking === "CONFIRMED" && doc.hotel === accommodation_cart[room_index].hotel[0].id){
                            accommodation = accommodation_cart[room_index];
                        }
                    }
                }

                let voucher = null;
                if (doc.type === "VOUCHER"){
                    voucher = allVoucher.find(item => item.product_ids[0] === doc.product);
                }

                if (doc.type === "CONDITIONS"){
                    label = t("menu.trip_list.conditions")
                }else if (doc.type === "TRAVELBOOK"){
                    label = t("menu.trip_list.travelbook");
                }else if (doc.type === "HOTELCARD" && accommodation !== null){
                    label = t("menu.trip_list.hotelcard") + " " + accommodation.hotel[0].name;
                }else if (doc.type === "CONTRACT"){
                    label = t("menu.trip_list.contract");
                }

                let file_name = getFileName(trip_main_info, trip_displayed_version, doc, voucher, traveler, label, t);
                let { pass_check, headers } = CheckBeforeRequest();
                if (pass_check){
                    const domain = window.location.host.replace("www.", "").split('.');
                    axios({
                        method: "GET",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_main_info.id}/versions/${trip_displayed_version}/file/download_from_aws/?bucket_name=${doc.bucket_name}&object_name=${doc.object_name.replaceAll("#", "%23")}&file_name=${file_name}`,
                        headers: headers,
                        responseType: 'arraybuffer'
                    }).then(function(response){
                        let blob = new Blob([response.data], {type: "application/pdf"});
                        dispatch(AddFileToDl(file_name, blob));
                    }).catch(function(error){
                        console.log(error);
                        enqueueSnackbar(t("menu.trip_list.failed_doc_download"), { variant: "warning", disableWindowBlurListener: true });
                    })
                }
            }
        })
    }
    if (isLoading || trip_info === null) {
		return (
			<div className={classes.absoluteCenter} >
				<PlaneLoader />
				<div className={`${classes.textCenter} ${classes.lightGreen}`} > {t('summary.get_all_info')} </div>
			</div>
		);
	}
    return (
        <Grid container style={{display: "block"}}>
            <Grid item>
                <SummaryHeader missingDocs={missingDocs} props={props}/>
            </Grid>
            <Grid item>
                <Container className={`${classes.container} ${classes.genericText} ${classes.textCenter}`} style={{paddingTop: fullScreen ? 120 : 70}}>
                    <Grid item>
                        <Grid container alignItems="center" justify="flex-end">
                            <Grid item>
                                <Tooltip title={t("menu.trip_list.refresh_doc_retrieval")}>
                                    <IconButton size="small" className={classes.colorBlue} onClick={() => {
                                        createAllDocs();
                                        refreshDocs();
                                    }}>
                                        <SyncOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={t("menu.trip_list.download_all_docs")}>
                                    <IconButton size="small" className={classes.colorBlue} onClick={downloadAllDocs}>
                                        <SystemUpdateAltIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        {
                            ['cercledesvoyages', 'continentsinsolites', 'verdie', 'connections', 'terreslointaines'].includes(quotation_code) && travelers.length !== 0 && displayIdentity && (
                                <Fragment>
                                    <Grid item style={{width: "100%"}}>
                                    {
                                        !mobile && (
                                            <hr className={`${classes.genericText} ${classes.hrText}`} data-content={t("summary.asked_documents")}/>
                                        )
                                    }
                                    {

                                        mobile && (
                                            <div className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize20} ${classes.upperCase} ${classes.letterSpacing}`}>
                                                { t("summary.asked_documents")}
                                            </div>
                                        )
                                    }
                                    </Grid>
                                    {
                                        requestedDoc && (
                                            <Grid item>
                                                <Alert variant={ 'filled' } severity={ 'warning' }>{ t('summary.requested_doc_warning') }</Alert>
                                            </Grid>
                                        )
                                    }
                                    <Grid className={`${classes.genericBorder} ${!mobile ? classes.genericPadding : classes.genericPaddingMobile} ${!mobile ? classes.spaceTop : classes.spaceTopMobile}`}>
                                        <Grid className={`${!mobile ? classes.spaceTop : classes.spaceTopMobile} ${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize20 : classes.fontSize16} ${classes.upperCase} ${classes.letterSpacing}`}>
                                            { t("menu.trip_list.identity") }
                                        </Grid>
                                        {
                                            displayIDCard && (
                                                <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                                                    <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12}`}>
                                                        { t("menu.trip_list.id_cards") }
                                                    </div>
                                                    <TableContainer>
                                                        <Table id={'id-cards'} padding="none">
                                                            {/* <TableHead>
                                                                <TableRow>
                                                                    <TableCell className={classes.borderTableCell} />
                                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                                        { !mobile ? t("menu.trip_list.requested_doc") : t("global.requested")}
                                                                    </TableCell>
                                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                                        { !mobile ? t("menu.trip_list.received_doc") : t("global.received")}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead> */}
                                                            <TableBody>
                                                                {
                                                                    trip_docs.map((doc) => {
                                                                        if (doc.type === "CARD"){
                                                                            let traveler = null;
                                                                            for(traveler_index=0 ; traveler_index<travelers.length ; traveler_index++){
                                                                                if(travelers[traveler_index].id === doc.traveler){
                                                                                    traveler = travelers[traveler_index]
                                                                                }
                                                                            }
                                                                            if(traveler !== null){
                                                                                return(
                                                                                    <DocumentActionBar key={`id-card-${doc.traveler}`} mode={"traveler"} traveler={traveler} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate}/>
                                                                                )
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            )
                                        }
                                        {
                                            displayPassport && (
                                                <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                                                    <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12}`}>
                                                        { t("menu.trip_list.passports") }
                                                    </div>
                                                    <TableContainer>
                                                        <Table id={'passports'} padding="none">
                                                            {/* <TableHead>
                                                                <TableRow>
                                                                    <TableCell className={classes.borderTableCell} />
                                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                                        { !mobile ? t("menu.trip_list.requested_doc") : t("global.requested")}
                                                                    </TableCell>
                                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                                        { !mobile ? t("menu.trip_list.received_doc") : t("global.received")}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead> */}
                                                            <TableBody>
                                                                {
                                                                    trip_docs.map((doc) => {
                                                                        if (doc.type === "PASSPORT" && (doc.received || doc.requested)){
                                                                            let traveler = null;
                                                                            for(traveler_index=0 ; traveler_index<travelers.length ; traveler_index++){
                                                                                if(travelers[traveler_index].id === doc.traveler){
                                                                                    traveler = travelers[traveler_index]
                                                                                }
                                                                            }
                                                                            if (traveler !== null){
                                                                                return(
                                                                                    <DocumentActionBar key={`passport-${doc.traveler}`} mode={"traveler"} traveler={traveler} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate}/>
                                                                                )
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            )
                                        }
                                        {
                                            displayEVisa && (
                                                <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                                                    <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12}`}>
                                                        e-Visa
                                                    </div>
                                                    <TableContainer>
                                                        <Table id={'e-Visa'} padding="none">
                                                            {/* <TableHead>
                                                                <TableRow>
                                                                    <TableCell className={classes.borderTableCell} />
                                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                                        { !mobile ? t("menu.trip_list.requested_doc") : t("global.requested")}
                                                                    </TableCell>
                                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                                        { !mobile ? t("menu.trip_list.received_doc") : t("global.received")}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead> */}
                                                            <TableBody>
                                                                {
                                                                    trip_docs.map((doc) => {
                                                                        if (doc.type === "E-VISA" && (doc.received || doc.requested)){
                                                                            let traveler = null;
                                                                            for(traveler_index=0 ; traveler_index<travelers.length ; traveler_index++){
                                                                                if(travelers[traveler_index].id === doc.traveler){
                                                                                    traveler = travelers[traveler_index]
                                                                                }
                                                                            }
                                                                            if (traveler !== null){
                                                                                return(
                                                                                    <DocumentActionBar key={`e-visa-${doc.traveler}`} mode={"traveler"} traveler={traveler} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate}/>
                                                                                )
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Fragment>

                            )
                        }
                        <Grid item style={{width: "100%"}}>
                        {
                            !mobile && (
                                <hr className={`${classes.genericText} ${classes.hrText}`} data-content={t("summary.your_documents")}/>
                            )
                        }
                        {

                            mobile && (
                                <div className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize20} ${classes.upperCase} ${classes.letterSpacing}`}>
                                    { t("summary.your_documents")}
                                </div>
                            )
                        }
                        </Grid>
                        {
                            (flightVoucher.length !== 0 || carVoucher.length !== 0 || roomVoucher.length !== 0 || activityVoucher.length !== 0 || transferVoucher.length !== 0) && (
                                <Grid className={`${classes.genericBorder} ${!mobile ? classes.genericPadding : classes.genericPaddingMobile} ${!mobile ? classes.spaceTop : classes.spaceTopMobile}`}>
                                    <Grid className={`${!mobile ? classes.spaceTop : classes.spaceTopMobile} ${!mobile ? "" : classes.textCenter}  ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize20 : classes.fontSize16} ${classes.upperCase} ${classes.letterSpacing}`}>
                                        { t("menu.trip_list.vouchers") }
                                    </Grid>
                                    {
                                        flightVoucher.length !== 0 && (
                                            <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                                                {
                                                    flightVoucher.map((voucher, voucher_index) => {
                                                        let product_type = GetProductType(voucher.product_type);
                                                        return(
                                                            <Grid key={`voucher-flight-${voucher_index}`}>
                                                                {
                                                                    voucher_index === 0 && (
                                                                        <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12}`}>
                                                                            { t("global.flights") }
                                                                        </div>
                                                                    )
                                                                }
                                                                <Grid container alignItems="center" justify="space-between">
                                                                    <Grid item xs={!mobile ? false : 12} className={`${!mobile ? classes.indentation : ""} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                                        {
                                                                            !mobile && (
                                                                                <div>
                                                                                    { t("global.voucher") + " " + t("providers." + product_type) + " : " + voucher.name }
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            mobile && (
                                                                                <div>
                                                                                    { voucher.name }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={!mobile ? false : 12} >
                                                                        {
                                                                            trip_docs.map((doc) => {
                                                                                if(doc.type === "VOUCHER" && doc.product === voucher.product_ids[0] ){
                                                                                    return(
                                                                                        <DocumentActionBar key={`voucher-${doc.product}`} mode={"voucher"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={voucher} update={update} setUpdate={setUpdate}/>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        )
                                    }
                                    {
                                        carVoucher.length !== 0 && (
                                            <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                                                {
                                                    carVoucher.map((voucher, voucher_index) => {
                                                        let product_type = GetProductType(voucher.product_type);
                                                        return(
                                                            <Grid key={`voucher-car-${voucher_index}`}>
                                                                {
                                                                    voucher_index === 0 && (
                                                                        <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12}`}>
                                                                            { t("global.cars") }
                                                                        </div>
                                                                    )
                                                                }
                                                                <Grid container alignItems="center" justify="space-between">
                                                                    <Grid item xs={!mobile ? false : 12} className={`${!mobile ? classes.indentation : ""} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                                        {
                                                                            !mobile && (
                                                                                <div>
                                                                                    { t("global.voucher") + " " + t("providers." + product_type) + " : " + voucher.name }
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            mobile && (
                                                                                <div>
                                                                                    { voucher.name }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={!mobile ? false : 12}>
                                                                        {
                                                                            trip_docs.map((doc) => {
                                                                                if(doc.type === "VOUCHER" && doc.product === voucher.product_ids[0] ){
                                                                                    return(
                                                                                        <DocumentActionBar key={`voucher-${doc.product}`} mode={"voucher"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={voucher} update={update} setUpdate={setUpdate}/>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        )
                                    }
                                    {
                                        roomVoucher.length !== 0 && (
                                            <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                                                {
                                                    roomVoucher.map((voucher, voucher_index) => {
                                                        let product_type = GetProductType(voucher.product_type);
                                                        return(
                                                            <Grid key={`voucher-activity-${voucher_index}`}>
                                                                {
                                                                    voucher_index === 0 && (
                                                                        <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12} ${classes.capitalize}`}>
                                                                            { t("accommodation.hotels") }
                                                                        </div>
                                                                    )
                                                                }
                                                                <Grid container alignItems="center" justify="space-between">
                                                                    <Grid item xs={!mobile ? false : 12} className={`${!mobile ? classes.indentation : ""} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                                        {
                                                                            !mobile && (
                                                                                <div>
                                                                                    { t("global.voucher") + " " + t("providers." + product_type) + " : " + voucher.name }
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            mobile && (
                                                                                <div>
                                                                                    { voucher.name }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={!mobile ? false : 12}>
                                                                        {
                                                                            trip_docs.map((doc) => {
                                                                                if(doc.type === "VOUCHER" && doc.product === voucher.product_ids[0] ){
                                                                                    return(
                                                                                        <DocumentActionBar key={`voucher-${doc.product}`} mode={"voucher"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={voucher} update={update} setUpdate={setUpdate}/>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        )
                                    }
                                    {
                                        activityVoucher.length !== 0 && (
                                            <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                                                {
                                                    activityVoucher.map((voucher, voucher_index) => {
                                                        let product_type = GetProductType(voucher.product_type);
                                                        return(
                                                            <Grid key={`voucher-activity-${voucher_index}`}>
                                                                {
                                                                    voucher_index === 0 && (
                                                                        <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12}`}>
                                                                            { t("cart.activities") }
                                                                        </div>
                                                                    )
                                                                }
                                                                <Grid container alignItems="center" justify="space-between">
                                                                    <Grid item xs={!mobile ? false : 12} className={`${!mobile ? classes.indentation : ""} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                                        {
                                                                            !mobile && (
                                                                                <div>
                                                                                    { t("global.voucher") + " " + t("providers." + product_type) + " : " + voucher.name }
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            mobile && (
                                                                                <div>
                                                                                    { voucher.name }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={!mobile ? false : 12}>
                                                                        {
                                                                            trip_docs.map((doc) => {
                                                                                if(doc.type === "VOUCHER" && doc.product === voucher.product_ids[0] ){
                                                                                    return(
                                                                                        <DocumentActionBar key={`voucher-${doc.product}`} mode={"voucher"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={voucher} update={update} setUpdate={setUpdate}/>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        )
                                    }
                                    {
                                        transferVoucher.length !== 0 && (
                                            <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                                                {
                                                    transferVoucher.map((voucher, voucher_index) => {
                                                        let product_type = GetProductType(voucher.product_type);
                                                        return(
                                                            <Grid key={`voucher-transfer-${voucher_index}`}>
                                                                {
                                                                    voucher_index === 0 && (
                                                                        <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12} ${classes.capitalize}`}>
                                                                            { t("accommodation.transfers") }
                                                                        </div>
                                                                    )
                                                                }
                                                                <Grid container alignItems="center" justify="space-between">
                                                                    <Grid item xs={!mobile ? false : 12} className={`${!mobile ? classes.indentation : ""} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                                        {
                                                                            !mobile && (
                                                                                <div>
                                                                                    { t("global.voucher") + " " + t("providers." + product_type) + " : " + voucher.name }
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            mobile && (
                                                                                <div>
                                                                                    { voucher.name }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={!mobile ? false : 12}>
                                                                        {
                                                                            trip_docs.map((doc) => {
                                                                                if(doc.type === "VOUCHER" && doc.product === voucher.product_ids[0] ){
                                                                                    return(
                                                                                        <DocumentActionBar key={`voucher-${doc.product}`} mode={"voucher"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={voucher} update={update} setUpdate={setUpdate}/>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            )
                        }
                        {
                            quotation_code !== "marcovasco" && (
                                <Grid className={`${classes.genericBorder} ${!mobile ? classes.genericPadding : classes.genericPaddingMobile} ${!mobile ? classes.spaceTop : classes.spaceTopMobile}`}>
                                    <Grid className={`${!mobile ? classes.spaceTop : classes.spaceTopMobile} ${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize20 : classes.fontSize16} ${classes.upperCase} ${classes.letterSpacing}`}>
                                        { t("menu.user_management.trip") }
                                    </Grid>
                                    <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${classes.genericBorder}`}>
                                        <Grid container alignItems="center" justify="space-between">
                                            <Grid item className={`${!mobile ? classes.indentation : ""} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`} xs={!mobile ? false : 12}>
                                                { t("menu.trip_list.quotation_title") + trip_main_info.trip_reference + " " + t("menu.trip_list.nb_version") + getIndexNbForVersion(trip_main_info.data, trip_displayed_version) }
                                            </Grid>
                                            <Grid item xs={!mobile ? false : 12}>
                                                {
                                                    trip_docs.map((doc) => {
                                                        if(doc.type === "QUOTATION"){
                                                            return(
                                                                <DocumentActionBar key={`quotation-${doc.id}`} mode={"quotation"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate}/>
                                                            )
                                                        }
                                                    })
                                                }
                                            </Grid>
                                        </Grid>
                                        {
                                            ['cercledesvoyages', 'continentsinsolites', 'verdie', 'connections', 'terreslointaines'].includes(quotation_code) && (
                                                <TableContainer>
                                                    <Table id={'e-Visa'} padding="none">
                                                        {/* <TableHead>
                                                            <TableRow>
                                                                <TableCell className={classes.borderTableCell} />
                                                                <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                                    { !mobile ? t("menu.trip_list.requested_doc"): t("global.requested")}
                                                                </TableCell>
                                                                <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                                    { !mobile ? t("menu.trip_list.received_doc") : t("global.received")}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead> */}
                                                        <TableBody>
                                                            {
                                                                trip_docs.map((doc) => {
                                                                    if(doc.type === "CONDITIONS" && doc.received){
                                                                        return(
                                                                            <DocumentActionBar key={`conditions-${doc.id}`} mode={"row"} traveler={null} label={t("menu.trip_list.conditions")} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate}/>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            {
                                                                trip_docs.map((doc) => {
                                                                    if(doc.type === "TRAVELBOOK" && doc.received){
                                                                        return(
                                                                            <DocumentActionBar key={`travelbook-${doc.id}`} mode={"row"} traveler={null} label={t("menu.trip_list.travelbook")} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate}/>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            {
                                                                trip_docs.map((doc) => {
                                                                    if(doc.type === "HOTELCARD" && doc.received){
                                                                        let accommodation = null;
                                                                        for (hotel_index=0 ; hotel_index<hotel_cart.length ; hotel_index++){
                                                                            if(hotel_cart[hotel_index].booking_status !== null && hotel_cart[hotel_index].booking_status.status_booking === "CONFIRMED" && doc.product === hotel_cart[hotel_index].id){
                                                                                accommodation = hotel_cart[hotel_index];
                                                                            }
                                                                        }
                                                                        if (accommodation !== null){
                                                                            return(
                                                                                <DocumentActionBar key={`hotelcard-${doc.product}`} mode={"row"} traveler={null} label={t("menu.trip_list.hotelcard") + " " + accommodation.hotel[0].name} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate}/>
                                                                            )
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                            {
                                                                trip_docs.map((doc) => {
                                                                    if(doc.type === "CONTRACT" && doc.received){
                                                                        return(
                                                                            <DocumentActionBar key={`contract-${doc.id}`} mode={"row"} traveler={null} label={t("menu.trip_list.contract")} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate}/>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                    {
                        !fullScreen && (
                            <Grid item style={{marginTop: "calc(5% + 60px)", bottom: 0}}>
                                <Footer/>
                            </Grid>
                        )
                    }
                    {
                        fullScreen && (
                            <Grid item>
                                <SummaryHeaderMobile missingDocs={missingDocs} props={props}/>
                            </Grid>
                        )
                    }
                </Container>
            </Grid>
        </Grid>
    )
}

export default SummaryDocuments;