//---- Dependencies ----//
import React, {Fragment} from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Slider from "react-slick";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import swal from "sweetalert";

//---- Common ----//
import CustomOverlay from "../../Common/CustomOverlay.jsx";
import GetProvider from "../../Common/Functions/GetProvider.js";
import Notify from "../../Common/Notify.js";

//---- Component ----//
import CarsResultsHeader from "./CarsResultsHeader.jsx";
import CarServiceModal from "../../Cart/Cars/CarsServiceModal.jsx";

//---- Functions ----//
import GetCarContract from '../Functions/GetCarContract';
import AddToCart from '../Functions/AddToCart';
import DeleteCars from '../Functions/DeleteCars';

//---- Actions ----//
import { SelectedContract, ResetCurrentCart, SetRequestStatus, SelectedOption, SetCopyCarIndex, AddSelectedCar, RemoveCarFromCurrentCart,
 ToggleServiceModal, SetServiceModal, GetOptions, SetQuantity, ResetContractStatus, TogglePreDetail } from "../../../Actions/CarsSearch";

//---- CSS ----//
import "../../../Style/CarsSearch.css";
import "../../../Style/Cart.css";

@connect((store) => {
    return {
    	selected_car: store.cars_search.selected_car,
    	step_info: store.cars_search.step_info,
    	location_index: store.cars_search.location_index,
    	car_index: store.cars_search.car_index,
    	currency: store.trip.currency,
    	car_contract: store.cars_search.car_contract,
    	currency_list: store.base.currency_list,
    	agency_options: store.cars_search.agency_options,
    	current_cart: store.cars_search.current_cart,
    	request_status: store.cars_search.request_status,
    	cars_results: store.cars_search.cars_results,
    	language: store.header.tmp_language,
    	travelers: store.trip.traveler_groups,
    	contract_status: store.cars_search.contract_status,
    	provider_list: store.trip.providers,
    	cart_id: store.cars_search.cart_id,
    	cart_index: store.cars_search.cart_index,
    	contract_error: store.cars_search.contract_error,
		user: store.user.user,
		itinerary_list: store.itinerary.itinerary_list,
        cars_steps: store.cars_search.steps,
    }
})
@withTranslation()
export default class CarsAddToCart extends React.Component {
	constructor(props, context) {
	    super(props, context);
	    this.state = {
	    	pic_height: 0,
	    	pic_width: 0,
	    	subprovider: 0
	    };
	}
	componentWillMount() {
		const { selected_car, step_info, car_index, location_index, cars_results, agency_options, dispatch } = this.props;
		let special_equipments = null;
		let country_code = null;
		if (cars_results.length > 0) {
			cars_results.map((provider) => {
				if (provider.provider_id === selected_car.provider_id && provider.subprovider_name === selected_car.subprovider) {
					provider.stations.map((stations) => {
						if (stations.code === selected_car.station_prices[0].pickup_station) {
							special_equipments = stations.special_equipments;
							country_code = stations.country_code;
						}
					});
				}
			});
		}
		else {
			country_code = selected_car.country_code;
			special_equipments = agency_options;
		}
		if (special_equipments) {
			special_equipments.map((elem) => {
				elem.check = false;
				elem.quantity = 0;
	    		if (selected_car.old_equipment && selected_car.old_equipment.length > 0) {
	    			selected_car.old_equipment.map((item) => {
	    				if (item.code_id === elem.code_id) {
	    					elem.quantity = item.quantity;
	    				}
	    			});
	    		}
	    		else {
	    			elem.quantity = 0;
	    		}
	    	});
		}
		dispatch(ResetContractStatus());
    	dispatch(GetOptions(special_equipments));
		GetCarContract(selected_car, car_index, step_info, location_index, country_code, dispatch);
		dispatch(SetRequestStatus(null));
	}
	componentDidUpdate(prevProps) {
		const {step_info, router, car_index, location_index, dispatch, current_cart} = this.props;
		if (prevProps.current_cart.length !== current_cart.length) {
			if (parseInt(step_info[location_index].car_nbr) !== car_index + 1 && current_cart.length !== parseInt(step_info[location_index].car_nbr)) {
				dispatch(SetCopyCarIndex(car_index + 1));
				router.push(`/${window.url_name}/apps/cars-results/${this.props.params.id_step}`);
			}
		}
	}
	printService() {
		const { car_contract } = this.props;
		return (
			<div className={ "car-responsive ft-font-12" }>
				<ListGroup>
				{
					car_contract.services.map((service, index) => {
						return (
							<ListGroupItem key={index}>
								<div className="cars-provider-container">
									<div className="absolute-center full-width">
										<div>
											{service.title}
											<CustomOverlay id={"tooltip-cars-" + index} tooltip={service.description} placement={"top"} >
											      <img src={"/Img/icon_alert.png"} className={ "ft-ml10 car-service-tooltip" }/>
										    </CustomOverlay>
										</div>
									</div>
								</div>
							</ListGroupItem>
						)
					})
				}
				</ListGroup>
			</div>
		)
	}
	printContract() {
		const { car_contract, selected_car, currency_list, language, dispatch } = this.props;
		const settings = {
			className: "cars-slider-price ft-font-10",
			slidesToShow: 4,
			//slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			infinite: false,
			speed: 300,
			arrows: true,
			responsive: [
			    {
			        breakpoint: 1650,
			        settings: {
			            slidesToShow: 3,
			        }
			    },
			    {
			        breakpoint: 1024,
			        settings: {
			            slidesToShow: 1,
			        }
			    }
			]
		};
		return (
			<div>
				<Slider {...settings}>
				{
					car_contract.contracts.map((contract, contract_index) => {
						let currency_code = currency_list.find((currency) => {
							return (currency.id === contract.prices[0].selling_currency)
						});
						let selected_contract = null;
						if (car_contract.selected) {
							car_contract.contracts.map((contract) => {
								if (contract.contract_id === car_contract.selected) {
									selected_contract = contract.prices[0].selling_price;
								}
							})
						}
						// car_contract.selected ? car_contract.contracts[car_contract.selected - 1].prices[0].selling_price : null;
						let base_price = selected_car.station_prices ? selected_car.station_prices[0].prices[0].selling_price : selected_car.selling_price.selling_price;
						let price = null;
						if (car_contract.selected) {
							price = contract.contract_id === car_contract.selected ? new Intl.NumberFormat(language, { style: 'currency', currency: currency_code.iso_code }).format(parseFloat(0)) :
								new Intl.NumberFormat(language, { style: 'currency', currency: currency_code.iso_code }).format(parseFloat(parseFloat(contract.prices[0].selling_price) - parseFloat(selected_contract)));
						}
						else {
							price = new Intl.NumberFormat(language, { style: 'currency', currency: currency_code.iso_code }).format(parseFloat(parseFloat(contract.prices[0].selling_price) - parseFloat(base_price)));
						}
						return (
							<div key={contract_index}>
								<ListGroup>
									<ListGroupItem className={ "text-center" }>
										<img className={ "car-forfait-logo" } src={contract.logo}/>
										<div className={"bold"}>
												<span>
													{contract.name}
												</span>
										</div>
									</ListGroupItem>
									{
										car_contract.services.map((service, service_index) => {
											return (
												<ListGroupItem key={service_index} className={ "text-center" }>
													<div className={ "absolute-center car-service-available" }>
														{service.contracts.includes(contract.contract_id) ? <i className={ "icon-check" }></i> : <i className={ "icon-099-delete" }></i>}
													</div>
												</ListGroupItem>
											)
										})
									}
									<ListGroupItem className={ "text-center" }>
										<div className={ "absolute-center full-width" }>
											<label className={ "flight-search-checkbox ft-no-margin" }>{parseFloat(price) > 0 ? "+" + price : price}
											    <input type={ "checkbox" } checked={ car_contract.selected === contract.contract_id } value={contract_index} onChange={() => {dispatch(SelectedContract(contract.contract_id))}}/>
											    <span className={ "flight-search-check-mark-cars" }/>
											</label>
										</div>
									</ListGroupItem>
								</ListGroup>
							</div>
						)
					})
				}
				</Slider>
			</div>
		)
	}
	checkFare(fares) {
		let empty = true;
		fares.map((fare) => {
			if (fare.price > 0) {
				empty = false;
			}
		});
		return empty;
	}
	checkCart(first_group) {
		const {current_cart, car_contract, step_info, router, car_index, location_index, agency_options, cars_results, cart_id, cart_index, dispatch, itinerary_list, cars_steps, t} = this.props;
		if (cart_id) {
			swal({
			    title: t("accommodation.validate_cart"),
			    text: t("cars.remove_cars"),
			    icon: "warning",
			    buttons: {
			        cancel: {
			            text: "Annuler",
			            value: false,
			            visible: true,
			            className: "btn-danger",
			            closeModal: true,
			        },
			        add: {
			            text: "Ajouter",
			            value: true,
			            visible: true,
			            className: "btn-success",
			            closeModal: true
			        }
			    }
			}).then((value) => {
				if (value) {
					// DeleteCars(cart_id, cart_index, dispatch, t);
					AddToCart(current_cart, car_contract, step_info, router, car_index, location_index, agency_options, this.props.params.id_step, cars_results, first_group.id, dispatch, t, itinerary_list, cars_steps, cart_id, cart_index);
				}
			});
		}
		else {
			AddToCart(current_cart, car_contract, step_info, router, car_index, location_index, agency_options, this.props.params.id_step, cars_results, first_group.id, dispatch, t, itinerary_list, cars_steps)
		}
	}
	printCarInfo() {
		const { user, selected_car, provider_list, currency_list, cars_results, step_info, car_index, location_index, car_contract, language, agency_options, router, dispatch, t } = this.props;
		const step = step_info[location_index];
		const car_name = (selected_car.vehicle_info.name).split(" OR SIMILAR");
		let a = moment.utc(step.return_date ? step.return_date : step.end_date_dest);
		let b = moment.utc(step.origin_date ? step.origin_date : step.start_date_origin);
		let days = a.diff(b, 'd');
		let find = agency_options ? agency_options.find((elem) => {
			return elem.quantity > 0;
		}) : null;
		let tmp_price = null;
		let selected_currency = null;
		if (selected_car.station_prices) {
			selected_car.station_prices.map((station) => {
				if (station.pickup_station === (selected_car.location_code_pickup ? selected_car.location_code_pickup : selected_car.station_prices[0].pickup_station)) {
					tmp_price = station.prices[0].selling_price;
					selected_currency = station.prices[0].selling_currency;
				}
			});
		}
		else {
			tmp_price = selected_car.selling_price.selling_price;
			selected_currency = selected_car.currency;
		}
		let currency_code = currency_list.find((currency) => {
			return (currency.id === selected_currency)
		});
		let agency_go = null;
		let agency_return = null;
		let provider = GetProvider(provider_list, selected_car.provider_id);
		let subprovider = GetProvider(provider_list, selected_car.subprovider_id);
		if (cars_results.length > 0) {
			cars_results.map((provider) => {
				if (provider.provider_id === selected_car.provider_id) {
					provider.stations.map((station) => {
						if (station.code === (selected_car.location_code_pickup ? selected_car.location_code_pickup : selected_car.station_prices[0].pickup_station))
							agency_go = station;
						if (station.code === (selected_car.location_code_return ? selected_car.location_code_return : (selected_car.location_code_pickup ? selected_car.location_code_pickup : (selected_car.station_prices[0].return_station ? selected_car.station_prices[0].return_station : selected_car.station_prices[0].pickup_station))))
							agency_return = station;
					})
				}
			});
		}
		else {
			agency_go = selected_car.location_pickup_name;
			agency_return = selected_car.location_return_name;
		}
		if (agency_return === null) {
			agency_return = agency_go;
		}
		let selected_car_price = new Intl.NumberFormat(language, { style: 'currency', currency: currency_code.iso_code }).format(tmp_price);
		let onsite_total = 0;
		let onsite_currency_code = null;
		if (agency_options !== null && agency_options.length > 0) {
			agency_options.map((option, option_index) => {
				onsite_currency_code = option.currency_code;
			});
		}
		let check_fare = selected_car.station_prices ? this.checkFare(selected_car.station_prices[0].onsite_fares) : selected_car.onsite_fares ? this.checkFare(selected_car.onsite_fares) : "";
		car_contract && car_contract.contracts.map((contract) => {
			if (contract.contract_id === car_contract.selected) {
				check_fare = this.checkFare(contract.onsite_fares);
			}
		});
		return (
			<div>
				<div className={"ft-relative ft-mb10"}>
					<img src={selected_car.vehicle_info.pict_url} className={"car-card-img-info " + (this.state.pic_height < 230 ? " cars-small-pic" : "")} onLoad={(event) => {this.setState({pic_height: event.target.height});}}/>
					{
						selected_car.subprovider_id === selected_car.provider_id && provider.provider.logo !== null && (
							<img src={ provider.provider.logo.thumbnail_little !== null ? provider.provider.logo.thumbnail_little : provider.provider.logo.url } className={"car-card-provider"} />
						)
					}
					{
						selected_car.subprovider_id !== selected_car.provider_id && provider.provider.logo !== null &&(
							<Fragment>
								{
									!(user.client_full.type === 2 || JSON.parse(localStorage.getItem("config")).quotation_code === "pbe") && (
										<img src={ provider.provider.logo.thumbnail_little !== null ? provider.provider.logo.thumbnail_little : provider.provider.logo.url } className={"car-card-provider" + (this.state.pic_width < 30 ? " provider-small-pic" : "")} onLoad={(event) => {this.setState({pic_width: event.target.width});}} />
									)
								}
								<Fragment>
								{
									(subprovider !== undefined && subprovider !== null) && subprovider.provider.logo !== null && (
										<img src={ subprovider.provider.logo.thumbnail_little !== null ? subprovider.provider.logo.thumbnail_little : subprovider.provider.logo.url } className={"car-card-provider sub" + (this.state.subprovider > 30 ? " provider-small-pic" : "")} onLoad={(event) => {this.setState({subprovider: event.target.width});}} />
									)
								}
								{
									(subprovider === undefined || subprovider === null) && (
										<img src={ selected_car.subprovider_logo } className={"car-card-provider sub"} />
									)
								}
								</Fragment>
							</Fragment>
						)
					}
				</div>
				<div>
					<div className={ "ft-light-green ft-font-20" }>
						{car_name[0]}
					</div>
					<div className={ "car-card-category" }>
						{t("cars.or_similiar") + " | " + t("global.category") + t("cars.categories." + (selected_car.vehicle_info.vehicle_category).toLowerCase()) + " (" + selected_car.vehicle_info.code + ")"}
					</div>
					<div className={ "ft-mt10" }>
						<div className={ "car-card-info uppercase" }>
							<CustomOverlay id={ "tooltip-car-transmission"} tooltip={ t("cars.transmission.transmission") } placement={ "bottom" }>
								<i className={ "icon-car-gear" }></i>
							</CustomOverlay>
							<span className={ "ft-font-11 car-card-info-transmission" }>
								{t("cars.transmission." + (selected_car.vehicle_info.transmission).toLowerCase())}
							</span>
						</div>
						<div className={ selected_car.vehicle_info.door_count ? "car-card-info" : "car-card-info-solo" }>
						    <CustomOverlay id={ "tooltip-car-door"} tooltip={ t("tooltip.door_nbr") } placement={ "bottom" }>
							    <i className={ "icon-car-door" }></i>
							</CustomOverlay>
						    <span className={ "ft-orange" }>{selected_car.vehicle_info.door_count}</span>
						</div>
						<div className={ selected_car.vehicle_info.passenger ? "car-card-info" : "car-card-info-solo" }>
							<CustomOverlay id={ "tooltip-car-passenger"} tooltip={ t("flight_groups.passengers") } placement={ "bottom" }>
							    <i className="icon-user-silhouette"></i>
							</CustomOverlay>
						    <span className={ "ft-orange" }>{selected_car.vehicle_info.passenger}</span>
						</div>
						<div className={ "car-card-info"}>
							<CustomOverlay id={ "tooltip-car-luggage"} tooltip={ t("global.luggages") } placement={ "bottom" }>
						    	<i className={ "icon-luggage" }></i>
						   	</CustomOverlay>
						    <span className={ "ft-orange" }>{selected_car.vehicle_info.baggage}</span>
						</div>
						{
						    selected_car.vehicle_info.air_condition && (
						        <div className={ "car-card-info-solo" }>
						        	<CustomOverlay id={ "tooltip-car-aircon"} tooltip={ t("accommodation.equipment.air_conditioning") } placement={ "bottom" }>
							            <i className={ "icon-aircon" }></i>
							        </CustomOverlay>
						        </div>
						    )
						}
					</div>
					{
						selected_car.vehicle_info.minimal_age && (
							<div>
								<span className="bold">
									{t("cars.minimal_age")}
								</span>
								<span className={ "ft-orange" }>
									{selected_car.vehicle_info.minimal_age}
								</span>
							</div>
						)
					}
					{
						car_contract && (
							car_contract.contracts.map((contract) => {
								if (contract.contract_id === car_contract.selected)
									return (
										<div key={contract.contract_id}><span className="bold">{t("cars.forfait_selected")}</span> {contract.name}</div>
									)
							})
						)
					}
					<div className={ "row" }>
						<div className={ "col-md-12" }>
							<div className={ "bold" }>
								{ t("cars.agency_origin")}
							</div>
							<div className={ "ft-font-12" }>
								{agency_go.name ? agency_go.name : agency_go}
							</div>
							<div className={ "ft-font-12" }>
								{b.format("DD MMMM YYYY")}
							</div>
							<div className={ "ft-font-12" }>
								{step.origin_hours + "H" + step.origin_minutes}
							</div>

						</div>
						<div className={ "col-md-12" }>
						{
							agency_return && (
								<Fragment>
									<div className={ "bold" }>
										{ t("cars.agency_return")}
									</div>
									<div className={ "ft-font-12" }>
										{agency_return.name ? agency_return.name : agency_return}
									</div>
									<div className={ "ft-font-12" }>
										{a.format("DD MMMM YYYY")}
									</div>
									<div className={ "ft-font-12" }>
										{step.return_hours + "H" + step.return_minutes}
									</div>
								</Fragment>
							)
						}
						</div>
					</div>
					{
						find !== undefined && (
							<div className={ "bold ft-mt10" }>{t("cars.optional_equipments_card")}</div>
						)
					}
					{
						agency_options && (
							agency_options.map((option, option_index) => {
								if (option.quantity > 0) {
									let option_price = new Intl.NumberFormat(language, { style: 'currency', currency: option.currency_code }).format((option.charge_list.rental_period) * option.quantity);
									onsite_total += option.charge_list.rental_period * option.quantity;
									onsite_currency_code = option.currency_code;
									return (
										<div key={option_index}>
											<span className={ "ft-font-12" }>{option.quantity + " " + option.code_name + " : " + option_price }</span>
										</div>
									)
								}
							})
						)
					}
					{
						!check_fare && car_contract && (
							<div className={"ft-mt10"}>
								<div className={"bold"}>
									{t("cars.onsite_fares") + " :"}
								</div>
								<div className="row ft-font-12">
								{
									car_contract.contracts.map((contract) => {
										if (contract.contract_id === car_contract.selected) {
											contract.onsite_fares.map((fare, fare_index) => {
												onsite_total += fare.price;
												let fare_currency_code = currency_list.find((currency) => {
													return (currency.id === fare.currency)
												});
												onsite_currency_code = fare_currency_code.iso_code;
												if (fare.price > 0) {
													let s = (fare.description).toLowerCase();
													return (
														<Fragment key={fare_index}>
															<div className={"col-md-8 no-padding ft-pl15"}>
																{(s[0].toUpperCase() + s.substring(1) ) + " :"}
															</div>
															<div className={"col-md-4 text-right no-padding pr20"}>
																{new Intl.NumberFormat(language, { style: 'currency', currency: fare_currency_code.iso_code }).format(fare.price)}
															</div>
														</Fragment>
													)
												}
											})
										}
									})
								}
								</div>
							</div>
						)
					}
					{
						selected_car.provider === "Avis" && (agency_go.name !== agency_return.name) &&(
							<div className={"ft-mt10"}>{t("cars.one_way_fare")}</div>
						)
					}
					{
						onsite_total > 0 && (
							<div className={ "ft-mt10 bold ft-font-20" }>
								{t("cars.onsite_total") + " : " + new Intl.NumberFormat(language, { style: 'currency', currency: onsite_currency_code }).format(onsite_total) }
							</div>
						)
					}
					<div className={ "ft-mt10 bold ft-font-20 ft-light-green" }>
						{t("cars.now_total") + " : " + selected_car_price }
					</div>
					{
						selected_car.vehicle_info.vehicle_category_message && (
							<div className={ "col-md-12 no-padding mt15" }>
								{selected_car.vehicle_info.vehicle_category_message}
							</div>
						)
					}
				</div>
			</div>
		)
	}
	printOptions() {
		const { agency_options, currency_list, step_info, location_index, language, car_index, selected_car, dispatch, t } = this.props;
		let step = step_info[location_index];
		let a = moment.utc(step.return_date ? step.return_date : step.end_date_dest);
		let b = moment.utc(step.origin_date ? step.origin_date : step.start_date_origin);
		let days = a.diff(b, 'd');
		return (
			<ListGroup>
			{
				agency_options.map((option, option_index) => {
					let price = new Intl.NumberFormat(language, { style: 'currency', currency: option.currency_code }).format((option.charge_list.rental_period));
					let day_price = new Intl.NumberFormat(language, { style: 'currency', currency: option.currency_code }).format((option.charge_list.day));
					if (option.charge_list.rental_period !== null) {
						return (
							<ListGroupItem key={option_index} className={ "mb20" }>
								<div className={ "absolute-center full-width row grey-bg option-height" }>
									<div className={"col-md-6"}>
										<div className="row">
											<div className={ "ft-light-green col-md-12" }>
												{option.code_name}
											</div>
											{
												option.charge_list.day !== null && (
													<div className={ "col-md-12 ft-font-12" }>
														{day_price + t("global.per_day")}
													</div>
												)
											}
										</div>
									</div>
									<div className={ "col-md-2 option-price" }>
										<div className="absolute-center">
											<div className={"row"}>
												<div className={ "bold" }>
													{t("global.total_price")}
												</div>
												<div>
													{ " " + t("global.over") + " " + days + " " + t("global.days")}
												</div>
											</div>
										</div>
									</div>
									<div className={"col-md-3 ft-light-green bold"}>
										<div className={"absolute-center"}>
											{price}
										</div>
									</div>
									{
										(option.code_name.includes("seat") || option.code_name.includes("stroller")) && (
											<div className={ "col-md-1" }>
												<div className={ "absolute-center" }>
													<select className={"ft-basic-input no-padding"} value={ (option.quantity).toString() } onChange={(e) => { dispatch(SetQuantity(e.target.value, option.code_id)) }}>
														<option value="0">0</option>
														<option value="1">1</option>
														<option value="2">2</option>
													</select>
												</div>
											</div>
										)
									}
									{
										(!option.code_name.includes("seat") && !option.code_name.includes("stroller")) && (
											<div className={ "col-md-1" }>
												<div className={"absolute-center"}>
													<select className={"ft-basic-input no-padding"} value={ (option.quantity).toString() } onChange={(e) => { dispatch(SetQuantity(e.target.value, option.code_id)) }}>
														<option value="0">0</option>
														<option value="1">1</option>
													</select>
												</div>
											</div>
										)
									}
								</div>
							</ListGroupItem>
						)
					}
				})
			}
			</ListGroup>
		)
	}
	deleteCar(card_index) {
		const { dispatch, router } = this.props;
		dispatch(RemoveCarFromCurrentCart(card_index));
		dispatch(SetCopyCarIndex(card_index));
		router.push(`/${window.url_name}/apps/cars-results/${this.props.params.id_step}`);
	}
	updateService(card_index) {
		const { current_cart, cars_results, dispatch } = this.props;
		let notice = null;
		cars_results.map((result) => {
			if (result.provider_id === current_cart[card_index].provider_id) {
				notice = result.important_notice;
			}
		});
		dispatch(SetServiceModal(current_cart[card_index].forfait_service, current_cart[card_index].contract_name, current_cart[card_index].contract_logo, notice));
	}
	checkContract(selected_car, car_contract) {
		const { dispatch, t } = this.props;
		if (car_contract !== null) {
			dispatch(AddSelectedCar(selected_car, car_contract))
		}
		else {
			Notify(t("cars.error_no_contract"), {status: 'danger', pos: 'top-center'});
		}
	}
	redirection() {
		const {router, dispatch} = this.props;
		dispatch(ResetCurrentCart());
		dispatch(SetRequestStatus(null));
		if (Number(this.props.params.id_step) !== 2) {
			router.push(`/${window.url_name}/apps/cars-results/${this.props.params.id_step}`);
		}
		else {
			dispatch({type: 'CART_SET_STEP', payload: {step: 1}});
			router.push(`/${window.url_name}/apps/cart/`);
		}
	}
	render() {
		const { user, car_contract, selected_car, contract_error, step_info, router, travelers, location_index, agency_options, dispatch, current_cart, language, request_status, provider_list, contract_status, currency_list, t } = this.props;
		let step = step_info[location_index];
		let a = moment.utc(step.return_date ? step.return_date : step.end_date_dest);
		let b = moment.utc(step.origin_date ? step.origin_date : step.start_date_origin);
		let days = a.diff(b, 'd');
		let first_group = travelers[travelers.length - 1];
		let return_agency = false;
		if (contract_error) {
			return_agency = contract_error.includes("RETURN");
		}
		let charge_day = null;
		if (selected_car.charges !== null && selected_car.charges.length > 0) {
			charge_day = moment.utc(step.origin_date ? step.origin_date : step.start_date_origin).subtract(selected_car.charges[0].from_day, "day");
		}
		let selected_currency = null;
		if (selected_car.station_prices) {
			selected_car.station_prices.map((station) => {
				if (station.pickup_station === (selected_car.location_code_pickup ? selected_car.location_code_pickup : selected_car.station_prices[0].pickup_station)) {
					selected_currency = currency_list.find((currency) => {
						return currency.id === station.prices[0].selling_currency;
					});
				}
			});
		}
		else {
			if (selected_car.currency.id !== undefined) {
				selected_currency = selected_car.currency;
			} else {
				selected_currency = currency_list.find((currency) => {
					return currency.id === selected_car.currency;
				});
			}
		}
		return (
			<div className="ft-beige-bg max-height overflow">
				<button className="ft-btn-return ft-white mt15 ft-ml25" onClick={() => { this.redirection()}}>{Number(this.props.params.id_step) !== 2 ? t("cars.list_return") : t("cars.cart_return")}</button>
				{
					contract_status === null && (
						<div className="ft-basic-card mt30 cars-results-matrix text-center">
							<div className={"bold ft-font-30 ft-gray"}>{t("cars.waiting_contract")}</div>
							<div className={ "spinner-orange spinner-cars" }/>
						</div>
					)
				}
				{
					(parseInt(step_info[location_index].car_nbr) !== current_cart.length) && (
						<div className={ "cars-contract" }>
							{
								(car_contract !== null && car_contract.contracts.length === 0 && contract_error === null) && (
									<div className={"ft-basic-card quarters-3 cars-results-header text-center"}>
										<div className={"bold ft-font-30 ft-pb30"}>{t("cars.no_contract")}</div>
										<button className={"btn-orange uppercase ft-white mt25 block-center"} onClick={() => {router.push(`/${window.url_name}/apps/cars-results/${this.props.params.id_step}`);}}>{t("cars.new_car")}</button>
									</div>
								)
							}
							{
								(contract_error !== null) && (
									<div className={"ft-basic-card quarters-3 cars-results-header text-center"}>
										<div className={"bold ft-font-30 ft-pb30"}>{return_agency ? t("cars.closed_return_agency") : t("cars.closed_pickup_agency")}</div>
										<button className={"btn-orange uppercase ft-white mt25 block-center"} onClick={() => {router.push(`/${window.url_name}/apps/cars-results/${this.props.params.id_step}`);}}>{t("cars.new_car")}</button>
									</div>
								)
							}
							{
								(car_contract !== null && car_contract.contracts.length !== 0) && (
									<div className={ "row ft-no-margin" }>
										<div className={ "col-md-4 offset-md-3 cars-overflow-panel mb20" }>
											<div className={ "row white-bg" }>
												<div className={ "col-md-12 bold ft-light-green ft-font-18 pt10" }>{t("cars.forfait")}</div>
												<div className={ "car-info-title" }/>
												<div className={ "col-md-6 no-padding" }>
													{this.printService()}
												</div>
												<div className={ "col-md-6 no-padding" }>
													{this.printContract()}
												</div>
											</div>
											{
												agency_options !== null && (
													<div className={ "row white-bg ft-mt40" }>
														<div className={ "full-width" }>
															<div className={ "col-md-12 bold ft-light-green mt20 ft-font-18" }>{t("cars.optional_equipments_card")}</div>
															<div className={ "car-info-title" }/>
															<div className={ "col-md-12" }>
																{this.printOptions()}
															</div>
														</div>
													</div>
												)
											}
											{
												/*
												charge_day !== null && (
													<div className="row white-bg ft-mt40">
														<div className="col-md-12 mt20 ft-light-green bold">
															{t("cars.cancelation_policy")}
														</div>
														<div className={ "car-info-title" }/>
														<div className={"col-md-12"}>
															<ul>
																<li className={"ft-green"}>
																	{t("global.until") + moment.utc(charge_day).format("DD MMMM YYYY") + " : " + t("global.no_charge")}
																</li>
																<li className={"ft-orange"}>
																	{t("global.from_2") + moment.utc(charge_day).format("DD MMMM YYYY") + " : " + new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(selected_car.charges[0].amount))}
																</li>
																<li className={"ft-red"}>
																	{t("global.from_2") + moment.utc(step.origin_date ? step.origin_date : step.start_date_origin).format("DD MMMM YYYY") + " : " + new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(selected_car.charges[1].amount))}
																</li>
															</ul>
														</div>
													</div>
												)
												*/
											}
											{
												(selected_car.vehicle_info.return_fuel_full !== null
													|| selected_car.vehicle_info.accident_franchise !== null
													|| selected_car.vehicle_info.theft_franchise !== null
													|| selected_car.vehicle_info.young_taxes_age !== null
													|| selected_car.vehicle_info.young_taxes_amount !== null) && (
													<div className="row white-bg ft-mt40">
														<div className="col-md-12 mt20 ft-light-green bold">
															{t("cars.important_information")}
														</div>
														<div className={ "car-info-title" }/>
														<div className="col-md-12">
															<ul>
																{
																	selected_car.vehicle_info.return_fuel_full !== null && (
																		<li>{selected_car.vehicle_info.return_fuel_full ? t("cars.return_fuel_full") : t("cars.return_fuel_not_full") }</li>
																	)
																}
																{
																	selected_car.vehicle_info.accident_franchise !== null && (
																		<li className={ "bold" }>{t("cars.accident_franchise") + new Intl.NumberFormat(language, { style: 'currency', currency: selected_currency.iso_code }).format(parseFloat(selected_car.vehicle_info.accident_franchise))}</li>
																	)
																}
																{
																	selected_car.vehicle_info.theft_franchise !== null && (
																		<li className={ "bold" }>{t("cars.theft_franchise") + new Intl.NumberFormat(language, { style: 'currency', currency: selected_currency.iso_code }).format(parseFloat(selected_car.vehicle_info.theft_franchise))}</li>
																	)
																}
																{
																	selected_car.vehicle_info.young_taxes_age !== null && (
																		<li className={ "bold" }>{t("cars.young_taxes_age") + selected_car.vehicle_info.young_taxes_age + " " + t("global.years")}</li>
																	)
																}
																{
																	selected_car.vehicle_info.young_taxes_amount !== null && (
																		<li className={ "bold" }>{t("cars.young_taxes_amount") + new Intl.NumberFormat(language, { style: 'currency', currency: selected_currency.iso_code }).format(parseFloat(selected_car.vehicle_info.young_taxes_amount))}</li>
																	)
																}
															</ul>
														</div>
													</div>
												)
											}
										</div>
										<div className="car-info-right-panel">
											<div className={ "selected-car-info cars-overflow-panel white-bg row ml15" }>
												<div className="col-md-12 no-padding ft-pl10">
													<div className={"bold ft-light-green ft-font-18 pt10"}>
														{t("cars.reservation_information")}
													</div>
													<div className={ "car-info-title" }/>
													{this.printCarInfo()}
												</div>
											</div>
											<div className={ "text-center row ml15" }>
												<button className={ "btn-orange uppercase mt25 full-width" } onClick={() => {this.checkContract(selected_car, car_contract)}}>{t("flight_search.add_to_cart")}</button>
											</div>
										</div>
									</div>
								)
							}
						</div>
					)
				}
				{
					(parseInt(step_info[location_index].car_nbr) === current_cart.length) && (
						<div className={"cars-contract"}>
							<CarsResultsHeader step={"add_to_cart"}/>
							<div className={ "mt25 quarters-3 cars-summary"}>
								{
									current_cart.map((card, card_index) => {
										let car_name = (card.vehicle_info.name).split(" OR SIMILAR");
										let tmp_price = null;
										let tmp_currency = null;
										if (card.contract && typeof card.contract === "object") {
											tmp_price = card.contract.prices[0].selling_price;
											tmp_currency = currency_list.find((currency) => {
												return (currency.id === card.contract.prices[0].selling_currency);
											});
										}
										else {
											const client_price = card.station_prices ? card.station_prices[0].prices[0] : card.selling_price;
											tmp_price = client_price.selling_price;
											tmp_currency = currency_list.find((currency) => {
												return (currency.id === client_price.selling_currency);
											});
										}
										let price = new Intl.NumberFormat(language, { style: 'currency', currency: tmp_currency.iso_code }).format(parseFloat(tmp_price));
										// let price = new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(card.station_prices ? card.station_prices[0].prices[0].selling_price : card.selling_price.selling_price));
										let onsite_total = 0;
										let onsite_currency_code = null;
										if (card.station_prices) {
											card.station_prices[0].onsite_fares.map((fare) => {
												if (fare.price > 0) {
													onsite_total += fare.price;
												}
												onsite_currency_code = currency_list.find((currency) => {
													return (currency.id === fare.currency)
												});
											});
										}
										let tmp = card.onsite_fares ? card.onsite_fares : card.station_prices[0].onsite_fares;
										let onsite_fare = null;
										let onsite_currency = null;
										Object.keys(tmp).find((key) => {
											if (tmp[key].description === "One way fares") {
												onsite_fare = tmp;
											}
										});
										if (onsite_fare !== null) {
											onsite_currency = currency_list.find((currency) => {
												return (currency.id === onsite_fare[0].currency)
											});
										}
										let provider = GetProvider(provider_list, card.provider_id);
										let subprovider = GetProvider(provider_list, card.subprovider_id);
										let charge_day = null;
										let step = step_info[location_index];
										if (card.charges !== null && card.charges.length > 0) {
											charge_day = moment.utc(step.origin_date ? step.origin_date : step.start_date_origin).subtract(card.charges[0].from_day, "day");
										}
										return (
											<div key={card_index} className={ "ft-basic-card mb25 car-card row ft-no-margin ft-relative" }>
												{
													Number(this.props.params.id_step) !== 2 && (
														<i className={ "icon-099-delete delete-car" } onClick={() => {this.deleteCar(card_index)}}></i>
													)
												}
												<div className={ "col-md-3" }>
													<div className={ "ft-relative" }>
														<img src={card.vehicle_info.pict_url} className={"car-card-img" + (this.state.pic_height < 230 ? " cars-small-pic" : "")} onLoad={(event) => {this.setState({pic_height: event.target.height});}}/>
														{
															card.subprovider_id === card.provider_id && provider.provider.logo !== null && (
																<img src={ provider.provider.logo.thumbnail_little !== null ? provider.provider.logo.thumbnail_little : provider.provider.logo.url } className={"car-card-provider"} />
															)
														}
														{
															card.subprovider_id !== card.provider_id && provider.provider.logo !== null && (
																<Fragment>
																	{
																		!(user.client_full.type === 2 || JSON.parse(localStorage.getItem("config")).quotation_code === "pbe") && (
																			<img src={ provider.provider.logo.thumbnail_little !== null ? provider.provider.logo.thumbnail_little : provider.provider.logo.url } className={"car-card-provider" + (this.state.pic_width < 30 ? " provider-small-pic" : "")} onLoad={(event) => {this.setState({pic_width: event.target.width});}} />
																		)
																	}
																	<Fragment>
																	{
																		(subprovider !== undefined && subprovider !== null) && subprovider.provider.logo !== null && (
																			<img src={ subprovider.provider.logo.thumbnail_little !== null ? subprovider.provider.logo.thumbnail_little : subprovider.provider.logo.url } className={"car-card-provider sub" + (this.state.subprovider > 30 ? " provider-small-pic" : "")} onLoad={(event) => {this.setState({subprovider: event.target.width});}} />
																		)
																	}
																	{
																		(subprovider === undefined || subprovider === null) && (
																			<img src={ card.subprovider_logo } className={"car-card-provider sub"} />
																		)
																	}
																	</Fragment>
																</Fragment>
															)
														}
													</div>
												</div>
												<div className={ "col-md-4 offset-md-2" }>
													<div className="row">
														<div className={ "col-md-12 bold row ft-no-margin no-padding" }>
															<div className={ "col-md-12 ft-black ft-no-margin no-padding ft-font-20" }>
																{car_name[0]}
															</div>
															<div className={ "col-md-12 car-card-category no-padding" }>
																{t("cars.or_similiar") + " | " + t("global.category") + t("cars.categories." + (card.vehicle_info.vehicle_category).toLowerCase()) + " (" + card.vehicle_info.code + ")"}
															</div>
														</div>
														<div className={ "col-md-12 ft-mt10 no-padding" }>
															<div className={ "car-card-info uppercase" }>
																<CustomOverlay id={ "tooltip-car-transmission-" + card_index} tooltip={ t("cars.transmission.transmission") } placement={ "bottom" }>
																	<i className={ "icon-car-gear" }></i>
																</CustomOverlay>
																<span className={ "ft-font-11 car-card-info-transmission" }>
																	{t("cars.transmission." + (card.vehicle_info.transmission).toLowerCase())}
																</span>
															</div>
															<div className={ card.vehicle_info.door_count ? "car-card-info" : "car-card-info-solo" }>
															    <CustomOverlay id={ "tooltip-car-door-" + card_index} tooltip={ t("tooltip.door_nbr") } placement={ "bottom" }>
																    <i className={ "icon-car-door" }></i>
																</CustomOverlay>
															    <span className={ "ft-orange" }>{card.vehicle_info.door_count}</span>
															</div>
															<div className={ card.vehicle_info.passenger ? "car-card-info" : "car-card-info-solo" }>
																<CustomOverlay id={ "tooltip-car-passenger-" + card_index} tooltip={ t("flight_groups.passengers") } placement={ "bottom" }>
																    <i className="icon-user-silhouette"></i>
																</CustomOverlay>
															    <span className={ "ft-orange" }>{card.vehicle_info.passenger}</span>
															</div>
															<div className={ "car-card-info"}>
																<CustomOverlay id={ "tooltip-car-luggage-" + card_index} tooltip={ t("global.luggages") } placement={ "bottom" }>
															    	<i className={ "icon-luggage" }></i>
															   	</CustomOverlay>
															    <span className={ "ft-orange" }>{card.vehicle_info.baggage}</span>
															</div>
															{
															    card.vehicle_info.air_condition && (
															        <div className={ "car-card-info-solo" }>
															        	<CustomOverlay id={ "tooltip-car-aircon-" + card_index} tooltip={ t("accommodation.equipment.air_conditioning") } placement={ "bottom" }>
																            <i className={ "icon-aircon" }></i>
																        </CustomOverlay>
															        </div>
															    )
															}
															{
																card.vehicle_info.minimal_age && (
																	<div className={"col-md-12 ft-font-11 no-padding"}>
																		{t("cars.minimal_age")}
																		<span className={ "ft-orange" }>
																			{card.vehicle_info.minimal_age}
																		</span>
																	</div>
																)
															}
														</div>
														{
															card.vehicle_info.vehicle_category_message && (
																<div className={ "col-md-12 no-padding mt15" }>
																	{card.vehicle_info.vehicle_category_message}
																</div>
															)
														}
														{
															card.forfait_service !== null && (
																<ul className={ "car-service" }>
																{
																	card.forfait_service.map((service, service_index) => {
																		return (
																			<Fragment key={service_index}>
																				<li className={ "ft-relative" }>
																					<span className={"ft-font-13 car-service-title"}>{service.title}</span>
																					<span className={ "icon-check-circle" }></span>
																				</li>

																			</Fragment>
																		)
																	})
																}
																</ul>
															)
														}
														{
															card.vehicle_info.free_shuttle !== null && (
																<div className={ "col-md-12 no-padding mt15 car-free-shuttle ft-font-13" }>
																{
																	!card.vehicle_info.free_shuttle && (
																		<Fragment>
																			<i className={"icon-plane"}></i>
																			<span>
																				{t("cars.free_shuttle_false")}
																			</span>
																		</Fragment>
																	)
																}
																{
																	onsite_fare !== null && (
																		<div className={ "col-md-12 no-padding mt15 ft-font-13 ft-red" }>
																			{t("cars.bsp_onsite_fares") + new Intl.NumberFormat(language, { style: 'currency', currency: onsite_currency.iso_code }).format(parseFloat(onsite_fare[0].price))}
																		</div>
																	)
																}
																{
																	card.vehicle_info.free_shuttle && (
																		<Fragment>
																			<i className={"icon-bus"}></i>
																			<span>
																				{t("cars.free_shuttle_true")}
																			</span>
																		</Fragment>
																	)
																}
																</div>
															)
														}
														{
															card.contract_name !== null && (
																<div className={ "col-md-12 no-padding mt15" }>
																	<span className={ "ft-basic-link ft-font-13 italic" } onClick={() => {this.updateService(card_index)}}>{t("cars.forfait_details") + card.contract_name}</span>
																</div>
															)
														}
									                    {
									                    	card.special_equipments && card.special_equipments.length > 0 && (
									                    		<div>
									                    			<div className={ "bold ft-mt10" }>{t("cars.optional_equipments_card")}</div>
										                    		{
										                    			card.special_equipments.map((option, option_index) => {
										                    				let price_option = new Intl.NumberFormat(language, { style: 'currency', currency: option.currency_code }).format((option.charge_list.rental_period));
									                    					return (
									                    						<div key={option_index}>
									                    							<span>{option.quantity + " " + option.code_name + " : " + price_option}</span>
									                    						</div>
									                    					)
										                    			})
										                    		}
									                    		</div>
									                    	)
									                    }
									                    {
									                       (onsite_total > 0) && (
									                            <div className={"resort-fees-box-cart row"} >
									                                <div className={"icon-warning ft-font-40 col-md-2"} />
									                                <div className={"fees-text col-md-10"}> {t("cars.onsite_total_cart") + new Intl.NumberFormat(language, { style: 'currency', currency: onsite_currency_code.iso_code }).format(onsite_total)}</div>
									                            </div>
									                        )
									                    }
									                    {
									                    	card.provider === "Avis" && (card.station_prices[0].pickup_station !== card.station_prices[0].return_station) &&(
									                    		<div className={"ft-mt10"}>{t("cars.one_way_fare")}</div>
									                    	)
									                    }
														{
															card.vehicle_info.vehicle_category_message && (
																<div className={ "col-md-12 no-padding mt15" }>
																	{card.vehicle_info.vehicle_category_message}
																</div>
															)
														}
													</div>
												</div>
												<div className={ "col-md-3" }>
													<div className={ "row" }>
														<div className={ "absolute-center" }>
															<div className={ "col-md-12 text-center" }>
																<div className="bold ft-font-35">
																	{price}
																</div>
															</div>
															{
																(card.vehicle_info.return_fuel_full !== null
																	|| card.vehicle_info.accident_franchise !== null
																	|| card.vehicle_info.theft_franchise !== null
																	|| card.vehicle_info.young_taxes_age !== null
																	|| card.vehicle_info.young_taxes_amount !== null) && (
																	<div className={ "col-md-12 text-center mt15 pointer uppercase" } onClick={() => {dispatch(TogglePreDetail(card_index))}}>
																		<span className={ "ft-mr5" }>{t("global.detail")}</span>
																		{card.detail ? <span className="icon-110-minus-symbol-inside-a-circle"/>
																		    : <span className="icon-077-add-button-inside-black-circle"/> }
																	</div>
																)
															}
														</div>
													</div>
												</div>
												{
													/*
													card.detail && charge_day !== null && (
														<div className="row white-bg ft-mt40">
															<div className="col-md-12 mt20 ft-light-green bold">
																{t("cars.cancelation_policy")}
															</div>
															<div className={ "car-info-title" }/>
															<div className={"col-md-12"}>
																<ul>
																	<li className={"ft-green"}>
																		{t("global.until") + moment.utc(charge_day).format("DD MMMM YYYY") + " : " + t("global.no_charge")}
																	</li>
																	<li className={"ft-orange"}>
																		{t("global.from_2") + moment.utc(charge_day).format("DD MMMM YYYY") + " : " + new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(selected_car.charges[0].amount))}
																	</li>
																	<li className={"ft-red"}>
																		{t("global.from_2") + moment.utc(step.origin_date ? step.origin_date : step.start_date_origin).format("DD MMMM YYYY") + " : " + new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(selected_car.charges[1].amount))}
																	</li>
																</ul>
															</div>
														</div>
													)

													 */
												}
												{
													card.detail && (card.vehicle_info.return_fuel_full !== null
														|| card.vehicle_info.accident_franchise !== null
														|| card.vehicle_info.theft_franchise !== null
														|| card.vehicle_info.young_taxes_age !== null
														|| card.vehicle_info.young_taxes_amount !== null) && (
														<div className="row ft-mt40">
															<div className={ "car-info-title" }/>
															<div className="col-md-12 ft-light-green bold">
																{t("cars.important_information")}
															</div>
															<div className="col-md-12">
																<ul>
																	{
																		card.vehicle_info.return_fuel_full !== null && (
																			<li>{card.vehicle_info.return_fuel_full ? t("cars.return_fuel_full") : t("cars.return_fuel_not_full") }</li>
																		)
																	}
																	{
																		card.vehicle_info.accident_franchise !== null && (
																			<li className={ "bold" }>{t("cars.accident_franchise") + new Intl.NumberFormat(language, { style: 'currency', currency: tmp_currency.iso_code }).format(parseFloat(card.vehicle_info.accident_franchise))}</li>
																		)
																	}
																	{
																		card.vehicle_info.theft_franchise !== null && (
																			<li className={ "bold" }>{t("cars.theft_franchise") + new Intl.NumberFormat(language, { style: 'currency', currency: tmp_currency.iso_code }).format(parseFloat(card.vehicle_info.theft_franchise))}</li>
																		)
																	}
																	{
																		card.vehicle_info.young_taxes_age !== null && (
																			<li className={ "bold" }>{t("cars.young_taxes_age") + card.vehicle_info.young_taxes_age + " " + t("global.years")}</li>
																		)
																	}
																	{
																		card.vehicle_info.young_taxes_amount !== null && (
																			<li className={ "bold" }>{t("cars.young_taxes_amount") + new Intl.NumberFormat(language, { style: 'currency', currency: tmp_currency.iso_code }).format(parseFloat(card.vehicle_info.young_taxes_amount))}</li>
																		)
																	}
																</ul>
															</div>
														</div>
													)
												}
											</div>
										)
									})
								}
							</div>
							<div className={"cars-results-matrix"}>
							{
								request_status !== "Fetching" && (
									<button className={ "btn-orange uppercase block-center mt15" } onClick={() => {this.checkCart(first_group)} }>{t("accommodation.validate_cart")}</button>
								)
							}
							{
								request_status === "Fetching" && (
									<div className="ft-basic-card cars-results-matrix text-center">
										<div className={"bold"}>{t("cars.add_to_cart.adding")}</div>
										<div className={ "spinner-orange" }></div>
									</div>
								)
							}
							</div>
						</div>
					)
				}
				<CarServiceModal />
			</div>
		);
	}
}
