import React from "react";
import ActivityStandaloneProductForm from "./ActivityStandaloneProductForm";

const ActivityCardStandaloneModal = ({
    open,
    onClose,
    activity,
    pictures,
    current_groups,
    start_date,
    end_date,
    destination,
    standaloneSuccess
}) => {
    if (!open) {
        return null;
    }
    return (
        <ActivityStandaloneProductForm
            open={open}
            start_date_init={start_date}
            end_date_init={end_date}
            activity={activity}
            pictures={pictures}
            current_groups={current_groups}
            destination={destination}
            onClose={onClose}
            standaloneSuccess={standaloneSuccess}
        />
    );
};

export default React.memo(ActivityCardStandaloneModal);
