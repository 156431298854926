//---- Commons ----//
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import Notify from "../../Common/Notify";
import DeleteCars from './DeleteCars';
import CheckCars from "../../Itinerary/Functions/CheckCars.js";

//---- Actions ----//
import { CarAddToCart, CheckAvailabilityStatus, SetCopyIndexLocation, SetRequestStatus, SetCopyCarIndex, ResetCurrentCart } from "../../../Actions/CarsSearch";

let cart_length = 0;

function redirect(current_cart_length, router, location_index, step_info, id_step, dispatch, itinerary_list, cars_steps, t) {
    cart_length += 1;
    if (cart_length === current_cart_length) {
        Notify(t("cars.add_to_cart.success"), {status: "success", pos: "top-center"});
    	if (location_index < step_info.length - 1) {
    		dispatch(SetCopyIndexLocation(location_index + 1));
		    dispatch(SetCopyCarIndex(0));
    		dispatch(ResetCurrentCart());
    		dispatch(CheckAvailabilityStatus(null));
    		router.push(`/${window.url_name}/apps/cars-results/${id_step}`);
    	}
    	else {
	    	CheckCars(cars_steps, itinerary_list, router, t, dispatch, step_info[location_index]);
    	}
    }
}

export default (current_cart, car_contract, step_info, router, car_index, location_index, agency_options, id_step, cars_results, travelers, dispatch, t, itinerary_list, cars_steps, cart_id, cart_index) => {
	const { pass_check, headers } = CheckBeforeRequest();
	const step = step_info[location_index];
	cart_length = 0;
	let cart_control = true;
	current_cart.map((car) => {
		if (car.contract === undefined) {
			cart_control = false;
		}
	});
	if (cart_control) {
		current_cart.map((car) => {
			if (pass_check) {
				let a = moment.utc(step.return_date ? step.return_date : step.end_date_dest);
				let b = moment.utc(step.origin_date ? step.origin_date : step.start_date_origin);
				let days = a.diff(b, 'd');
				let tmp_date_origin = moment.utc((step.origin_date !== null ? moment.utc(step.origin_date).format("YYYY-MM-DD") : step.start_date_origin) + "T" + step.origin_hours + ":" + step.origin_minutes);
				let tmp_date_return = moment.utc((step.return_date !== null ? moment.utc(step.return_date).format("YYYY-MM-DD") : step.end_date_dest) + "T" + step.return_hours + ":" + step.return_minutes);
				let date_origin = tmp_date_origin.format("YYYY-MM-DD[T]HH:mm");
				let date_return = tmp_date_return.format("YYYY-MM-DD[T]HH:mm");
				let special_equipment = [];
				let location_code_pickup = car.location_code_pickup ? car.location_code_pickup : car.station_prices[0].pickup_station;
				let stations = null;
				if (car.station_prices) {
					stations = car.station_prices.find((station) => {
						return station.pickup_station === location_code_pickup;
					});
				}
				else {
					stations = car.selling_price;
				}
				let country_code = null;
				let state_code = null;
				if (cars_results.length > 0) {
					cars_results.map((result) => {
						if (result.provider_id === car.provider_id) {
							let car_station = result.stations[0];
							country_code = car_station.country_code;
							state_code = car_station.state_code;
						}
					});
				}
				else {
					country_code = car.country_code;
					state_code = car.state_code;
				}
				car.special_equipments.map((option) => {
					special_equipment.push(
						{
							code_id: option.code_id,
							quantity: option.quantity,
							price: (parseFloat(option.charge_list.rental_period))
						});
				});
				let request = {
					contract_id: car.contract.contract,
					provider_id: car.provider_id,
					location_code_pickup: location_code_pickup,
					location_code_return: car.location_code_return ? car.location_code_return : (car.location_code_pickup ? car.location_code_pickup : (car.station_prices[0].return_station ? car.station_prices[0].return_station : car.station_prices[0].pickup_station)),
					transmission_type: car.vehicle_info.transmission,
					air_conditioner: car.vehicle_info.air_condition,
					vehicle_type_id: car.vehicle_info.vehicle_type_id,
					vehicle_category_id: car.vehicle_info.vehicle_category_id,
					//base_price: stations.base_price[0].selling_price,
					total_price: car.station_prices ? stations.prices[0].selling_price : stations.selling_price,
					driver_age: step.driver_age[car_index],
					start_date: date_origin,
					end_date: date_return,
					special_equipments: special_equipment,
					country_code: country_code,
					state_code: state_code,
					group_passenger: travelers,
					start_destination: step.origin_id,
					end_destination: step.dest_id
				};
				// if (car.provider_id !== 19) {
				// 	request.contract_id = car.contract;
				// 	request.vehicle_type_id = car.vehicle_info.vehicle_type_id;
				// }
				dispatch(SetRequestStatus("Fetching"));
				$.ajax({
					method: "POST",
					url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/car/`,
					headers: headers,
					data: JSON.stringify(request),
					success: function (results) {
						console.log("results:", results);
						if (cart_id !== undefined && cart_index !== undefined) {
							DeleteCars(cart_id, cart_index, dispatch, t);
						}
						dispatch(CarAddToCart(results))
						redirect(current_cart.length, router, location_index, step_info, id_step, dispatch, itinerary_list, cars_steps, t);
					},
					error: function (error) {
						console.log('error:', error);
						Notify(t("cars.add_to_cart.failed"), {status: 'danger', pos: 'top-center'});
					}
				});
			}
		});
	} else {
		Notify(t("cars.add_to_cart.no_contract_checked"), {status: 'danger', pos: 'top-center'});
	}

}