export default (data) => {
	let main_trip_id = 0;
	let lowest_date = data[0].data_trip.created_date;
	for (let i = 0; i < data.length; i++) {
		
	    if (moment.utc(data[i].data_trip.created_date).isBefore(lowest_date) === true) {
	    	lowest_date = data[i].data_trip.created_date;
	    	main_trip_id = i;
	    }
	}
	return main_trip_id;
}