import React, { Fragment } from "react";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import Close from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: "bold"
    }
}));

const AccommodationCardFacility = ({open, onClose, facilities, rooms, sports, meals, dists, payments}) => {
    console.log("facility render");
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Dialog fullScreen={ fullScreen } PaperProps={{style: { borderRadius: fullScreen ? 0 : 16 } }} open={ open } fullWidth maxWidth={ "md" } onClose={ onClose } disableEscapeKeyDown>
            <DialogTitle>
                <Grid container justify={ "space-between" } alignItems={ "center" }>
                    {
                        fullScreen ? (
                            <Grid item className={ classes.bold }>
                                <IconButton edge={ "start" } onClick={ onClose }><NavigateBefore/></IconButton> { t("accommodation.equipment_title") }
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item className={ classes.bold }>
                                    { t("accommodation.equipment_title") }
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={ onClose }><Close/></IconButton>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent>
                {
                    facilities.length > 0 && (
                        <Fragment>
                            <Typography className={ classes.bold }>{ t("accommodation.accommodation_equipment") } :</Typography>
                            {
                                facilities.map((facility, index_facility) => {
                                    return (
                                        <Fragment key={ index_facility }>
                                            {
                                                index_facility > 0 && (
                                                    <Divider/>
                                                )
                                            }
                                            <br/>
                                            <Typography variant={ "body2" }>{ t('accommodation.facts.' + facility.codename) }</Typography>
                                            <br/>
                                        </Fragment>
                                    );
                                })
                            }
                        </Fragment>
                    )
                }
                {
                    rooms.length > 0 && (
                        <Fragment>
                            <Divider/>
                            <br/>
                            <Typography className={ classes.bold }>{ t("accommodation.rooms") } :</Typography>
                            {
                                rooms.map((room, index_room) => {
                                    return (
                                        <Fragment key={ index_room }>
                                            {
                                                index_room > 0 && (
                                                    <Divider/>
                                                )
                                            }
                                            <br/>
                                            <Typography variant={ "body2" }>{ t('accommodation.facts.' + room.codename) }</Typography>
                                            <br/>
                                        </Fragment>
                                    );
                                })
                            }
                        </Fragment>
                    )
                }
                {
                    meals.length > 0 && (
                        <Fragment>
                            <Divider/>
                            <br/>
                            <Typography className={ classes.bold }>{ t("accommodation.meal") } :</Typography>
                            {
                                meals.map((meal, index_meal) => {
                                    return (
                                        <Fragment key={ index_meal }>
                                            {
                                                index_meal > 0 && (
                                                    <Divider/>
                                                )
                                            }
                                            <br/>
                                            <Typography variant={ "body2" }>{ t('accommodation.facts.' + meal.codename) }</Typography>
                                            <br/>
                                        </Fragment>
                                    );
                                })
                            }
                        </Fragment>
                    )
                }
                {
                    sports.length > 0 && (
                        <Fragment>
                            <Divider/>
                            <br/>
                            <Typography className={ classes.bold }>{ t("accommodation.sport") } :</Typography>
                            {
                                sports.map((sport, index_sport) => {
                                    return (
                                        <Fragment key={ index_sport }>
                                            {
                                                index_sport > 0 && (
                                                    <Divider/>
                                                )
                                            }
                                            <br/>
                                            <Typography variant={ "body2" }>{ t('accommodation.facts.' + sport.codename) }</Typography>
                                            <br/>
                                        </Fragment>
                                    );
                                })
                            }
                        </Fragment>
                    )
                }
                {
                    payments.length > 0 && (
                        <Fragment>
                            <Divider/>
                            <br/>
                            <Typography className={ classes.bold }>{ t("accommodation.payment") } :</Typography>
                            {
                                payments.map((payment, index_payment) => {
                                    return (
                                        <Fragment key={ index_payment }>
                                            {
                                                index_payment > 0 && (
                                                    <Divider/>
                                                )
                                            }
                                            <br/>
                                            <Typography variant={ "body2" }>{ t('accommodation.facts.' + payment.codename) }</Typography>
                                            <br/>
                                        </Fragment>
                                    );
                                })
                            }
                        </Fragment>
                    )
                }
                {
                    dists.length > 0 && (
                        <Fragment>
                            <Divider/>
                            <br/>
                            <Typography className={ classes.bold }>{ t("accommodation.distance") } :</Typography>
                            {
                                dists.map((dist, index_dist) => {
                                    return (
                                        <Fragment key={ index_dist }>
                                            {
                                                index_dist > 0 && (
                                                    <Divider/>
                                                )
                                            }
                                            <br/>
                                            <Typography variant={ "body2" }>{ t('accommodation.facts.' + dist.codename) }</Typography>
                                            <br/>
                                        </Fragment>
                                    );
                                })
                            }
                        </Fragment>
                    )
                }
            </DialogContent>
        </Dialog>
    );
};

export default React.memo(AccommodationCardFacility);