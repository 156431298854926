import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import NavigateBefore from '@material-ui/icons/NavigateBefore';

const useStyles = makeStyles(theme => ({
	relativePosition: {
		position: "relative"
	},
	marginTop15: {
		marginTop: 15
	},
	marginBottom100: {
		marginBottom: 100
	},
	pointer: {
		cursor: "pointer"
	},
	justify: {
		textAlign: "justify"
	}
}));

const RenderSpecialEquipments = ({agency_options, step_info, location_index}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('sm'));
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const currency_list = useSelector((store) => {
		return store.base.currency_list;
	});
	const language = useSelector((store) => {
		return store.header.tmp_language;
	});
	const [description, setDescription] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	let step = step_info[location_index];
	let a = moment.utc(step.return_date ? step.return_date : step.end_date_dest);
	let b = moment.utc(step.origin_date ? step.origin_date : step.start_date_origin);
	let days = a.diff(b, 'd');
	return (
		<Fragment>
			<List className={`${classes.marginTop15}`}>
			{
				agency_options.map((option, option_index) => {
					let price = option.charge_list.rental_period !== null ? new Intl.NumberFormat(language, { style: 'currency', currency: option.currency_code }).format((option.charge_list.rental_period)) : new Intl.NumberFormat(language, { style: 'currency', currency: option.currency_code }).format(((option.charge_list.max_price !== null && option.charge_list.day * days > option.charge_list.max_price) || option.charge_list.day === null ? option.charge_list.max_price : option.charge_list.day * days));
					return (
						<ListItem key={option_index}>
							<Grid container>
								<Grid item md={ 6 } xs={ 12 }>
									<div className={ "bold" }>
										{option.code_name}
										{
											option.description !== null && (
												<InfoIcon className={classes.pointer} style={{verticalAlign: "bottom", marginLeft: 5}} onClick={() => {
													setDescription(option.description)
													setIsOpen(true)
												}}/>
											)
										}
									</div>
								</Grid>
								<Grid item md={ 2 } xs={ 4 }>
									<div className={ "bold" }>
										{t("global.total_price")}	
									</div>
									<div>
										{ " " + t("global.over") + " " + days + " " + t("global.days")}
									</div>
								</Grid>
								<Grid item md={ 3 } xs={ 4 } className={ classes.relativePosition }>
									<div className={ "absolute-center"} >
										{price}								
									</div>
								</Grid>
								{
									((option.code_name.toLowerCase()).includes("seat") || (option.code_name.toLowerCase()).includes("stroller") || (option.code_name.toLowerCase()).includes("siège")) && (
										<Grid item sm={ 1 } xs={ 4 } className={ classes.relativePosition }>
											<div className={ "absolute-center" }>
												<FormControl variant={"outlined"}>
													<Select MenuProps={{ disableScrollLock: true }} className={"ft-basic-input no-padding"} value={ (option.quantity).toString() } onChange={(e) => { dispatch({type: "CARS_SET_OPTION_QUANTITY", payload: {option_id: option.code_id, quantity: e.target.value}}) }} >
														<MenuItem value="0">0</MenuItem>
														<MenuItem value="1">1</MenuItem>
														<MenuItem value="2">2</MenuItem>
														<MenuItem value="3">3</MenuItem>
													</Select>
												</FormControl>
											</div>
										</Grid>
									)
								}
								{
									(!(option.code_name.toLowerCase()).includes("seat") && !(option.code_name.toLowerCase()).includes("stroller") && !(option.code_name.toLowerCase()).includes("siège")) && (
										<Grid item sm={ 1 } xs={ 4 } className={ classes.relativePosition }>
											<div className={ "absolute-center" }>
												<FormControl variant={"outlined"}>
													<Select MenuProps={{ disableScrollLock: true }} className={"ft-basic-input no-padding"} value={ (option.quantity).toString() } onChange={(e) => { dispatch({type: "CARS_SET_OPTION_QUANTITY", payload: {option_id: option.code_id, quantity: e.target.value}}) }} >
														<MenuItem value="0">0</MenuItem>
														<MenuItem value="1">1</MenuItem>
													</Select>
												</FormControl>
											</div>
										</Grid>
									)
								}
							</Grid>
						</ListItem>
					)
				})
			}
			</List>
	    	<Dialog open={ isOpen } onClose={ () => {setIsOpen(false)} } fullWidth fullScreen={ fullScreen } maxWidth={ "md" }>
	    	    <DialogTitle onClose={ () => {setIsOpen(false)} }>
	    	        <Grid container justify={ "space-between" } alignItems={ "center" }>
	                    <Grid item>
	                        <IconButton edge={ "start" } onClick={ () => {setIsOpen(false)} }><NavigateBefore/></IconButton>
	                    </Grid>
	    	        </Grid>
	    	    </DialogTitle>
		        <DialogContent className={classes.justify}>
	      			{description}
		        </DialogContent>
	      	</Dialog>
		</Fragment>
	)
}
export default React.memo(RenderSpecialEquipments);