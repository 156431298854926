const GetPictureBySize = (picture, size) => {
    if (picture !== undefined && picture !== null) {
        if (picture.thumbnail_little !== undefined && picture.thumbnail_little !== null && size === "LITTLE")
            return picture.thumbnail_little;
        else if (picture.url !== undefined && picture.url !== "")
            return picture.url;
        else if (picture.thumbnail_little !== undefined && picture.thumbnail_little !== null)
            return GetPictureBySize(picture, "LITTLE");
        else if (!!picture.thumbnail_big)
            return picture.thumbnail_big;
        else
            return "/Img/poi_default.jpg";
    } else
        return "/Img/poi_default.jpg";
};

export default GetPictureBySize;