import { Itinerary } from "../objects/itinerary";

type Step = Pick<
    Itinerary,
    'circuit' | 'iti_type'
>

export function transformStepInputsToGroups<T extends Step>(steps: T[]): T[][] {
    return steps.reduce((prev, current) => {
        const previousGroup = prev[prev.length - 1];
        const previousGroupCircuitId = (previousGroup ?? [])[0]?.circuit;
        const previousGroupTypicalTripId = (previousGroup ?? [])[0]?.iti_type;

        if (
            previousGroup &&
            (
                (
                    previousGroupCircuitId &&
                    previousGroupCircuitId === current.circuit
                ) ||
                (
                    previousGroupTypicalTripId &&
                    previousGroupTypicalTripId === current.iti_type
                )
            )
        ) {
            return prev.slice(0, prev.length - 1).concat([
                [
                    ...previousGroup,
                    current
                ]
            ]);
        }

        return prev.concat([[current]]);
    }, [] as T[][]);
}
