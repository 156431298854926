import axios from "axios";
import { useSelector } from "react-redux";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { Mail } from "../objects/mail";
import { AppState } from "../../../../Reducers/Reducers";

type Result = (mail: Mail, checkMinisiteTag?: boolean) => Promise<void>

type Options = {
    onTrigger: () => void,
    onSuccess: () => void,
    onError: (error: Error) => void,
    onFinally: () => void,
    customData?: any,
}

type RequestOptions = {
    tripId: number,
    version: number,
    mail: Mail,
    checkMinisiteTag: boolean
}

export function useSendMail(options: Options): Result {
    const trip_info = useSelector((store: AppState) => store.menu.trip_info);
    const to_show_version = useSelector((store: AppState) => store.menu.to_show_version);
    const version = to_show_version !== null ? to_show_version : trip_info?.current_version;
    return async (mail, checkMinisiteTag) => {
        if (options.customData && options.customData.callback !== undefined && options.customData.callback !== null) {
            try {
                options.onTrigger();
                console.log('DEBUG xx', options.customData);
                await options.customData.callback(mail);
                options.onSuccess();
            } catch (error: any) {
                options.onError(error);
            } finally {
                options.onFinally();
            }
        } else if (trip_info && version) {
            try {
                options.onTrigger();
                await makeRequest({
                    tripId: trip_info.id,
                    version,
                    mail,
                    checkMinisiteTag: !!checkMinisiteTag
                });
                options.onSuccess();
            } catch (error: any) {
                options.onError(error);
            } finally {
                options.onFinally();
            }
        }
    };
}

function makeRequest(options: RequestOptions): Promise<void> {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        return axios.post(
            //eslint-disable-next-line no-undef
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/send_custom_email/`,
            {
                subject: options.mail.object,
                email_content: options.mail.content,
                recipient: options.mail.to,
                sender_email: options.mail.from.email,
                sender_name: options.mail.from.name.normalize("NFD").replace(/\p{Diacritic}/gu, ""),
                ccs: options.mail.cc,
                check_minisite_tag: options.checkMinisiteTag
            },
            { headers }
        );
    }

    return new Promise((resolve) => resolve());
}
