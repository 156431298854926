//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import CloseIcon from '@material-ui/icons/Close';


import { TogleInfoHotelAndSetStep } from '../../../Actions/Summary';
import HotelInfoMap from './HotelInfoMap.tsx';
import { DialogHotelInfo } from './DialogHotelInfo';
import { AppState } from '../../../Reducers/Reducers';

const useStyles = makeStyles(theme => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    justifyText: {
        textAlign: "justify"
    },
    hotelInfoMap: {
        width: "100%",
        height: 350
    },
    dialogSize: {
        "& .mui-jss-MuiDialog-paper": {
            width: 500
        },
        "& .mui-jss-MuiDialog-paperWidthSm": {
            maxWidth: 500
        }
    }
}));

const HotelInfo = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const info_hotel = useSelector((state:AppState) => state.summary.info_hotel);
    const modal_info_hotel = useSelector((state: AppState) => state.summary.modal_info_hotel);
    const step_info_hotel = useSelector((state:AppState) => state.summary.step_info_hotel);

    let situation = null;
    let distances = null;
    let equipements = null;
    let rooms = null;
    let check_in_out = null;
    let restauration = null;
    let other = null;
    let sports_animation = null;
    let to_know = null;
    let content = null;

    // if (info_hotel !== null) {
    //     for (let i = 0; i < info_hotel.hotel[0].content.length; i++) {
    //         if (info_hotel.hotel[0].content[i].section === 100) {
    //             situation = info_hotel.hotel[0].content[i];
    //         }
    //         if (info_hotel.hotel[0].content[i].section === 110) {
    //             equipements = info_hotel.hotel[0].content[i];
    //         }
    //         if (info_hotel.hotel[0].content[i].section === 120) {
    //             rooms = info_hotel.hotel[0].content[i];
    //         }
    //         if (info_hotel.hotel[0].content[i].section === 150) {
    //             check_in_out = info_hotel.hotel[0].content[i];
    //         }
    //         if (info_hotel.hotel[0].content[i].section === 160) {
    //             restauration = info_hotel.hotel[0].content[i];
    //         }
    //         if (info_hotel.hotel[0].content[i].section === 170) {
    //             to_know = info_hotel.hotel[0].content[i];
    //         }
    //         if (info_hotel.hotel[0].content[i].section === 200) {
    //             other = info_hotel.hotel[0].content[i];
    //         }
    //         if (info_hotel.hotel[0].content[i].section === 210) {
    //             distances = info_hotel.hotel[0].content[i];
    //         }
    //         if (info_hotel.hotel[0].content[i].section === 103) {
    //             sports_animation = info_hotel.hotel[0].content[i];
    //         }
    //     }
    //     if (info_hotel.hotel[0].situation !== null && info_hotel.hotel[0].situation !== "") {
	//         situation = info_hotel.hotel[0].situation;
    //         content = situation;
	//     } else {
	//         for (let i = 0; i < info_hotel.hotel[0].content.length; i++) {
	//             if (info_hotel.hotel[0].content[i].section === 100) {
	//                 situation = info_hotel.hotel[0].content[i];
    //                 content = situation.content;
	//             }
	//         }
	//         if (situation === null) {
	//             for (let i = 0; i < info_hotel.hotel[0].content.length; i++) {
	//                 if (info_hotel.hotel[0].content[i].section === 390 || info_hotel.hotel[0].content[i].section === 110) {
	//                     situation = info_hotel.hotel[0].content[i];
    //                     content = situation.content;
	//                 }
	//             }
	//         }
	//     }
    // }

    return (
        <Dialog open={modal_info_hotel} PaperProps={{ style: { borderRadius: fullScreen ? 0 : 16 } }} onClose={() => {
            dispatch(TogleInfoHotelAndSetStep(false, 0, null));
        }} fullScreen={fullScreen} maxWidth={ "md" } fullWidth disableScrollLock>
            <DialogTitle>
                <IconButton disableRipple={true} disableFocusRipple={true} edge={ "start" } style={{ position: "absolute", top: 0, right: 8 }} onClick={ () => {
                    dispatch(TogleInfoHotelAndSetStep(false, 0, null));
                } }><CloseIcon/></IconButton>
            </DialogTitle>
            <DialogContent className={classes.genericText}>
                {
                    info_hotel !== null && (
                        <div>
                            {
                                step_info_hotel === 1 && (
                                    <div >
                                        <h1>{ t<string>("footer.about") }</h1>
                                        {
                                            situation !== null && (
                                                <div className={classes.justifyText} >
                                                    <strong> { situation?.title } </strong> : { situation?.content }
                                                </div>
                                            )
                                        }
                                        {
                                            (situation !== null && distances !== null) && (
                                                <hr />
                                            )
                                        }
                                        {
                                            distances !== null && (
                                                <div className={classes.justifyText} >
                                                    <strong> { distances?.title } </strong> : { distances?.content }
                                                </div>
                                            )
                                        }
                                        {
                                            ((situation !== null || distances !== null) && to_know !== null) && (
                                                <hr />
                                            )
                                        }
                                        {
                                            to_know !== null && (
                                                <div className={classes.justifyText} >
                                                    <strong> { to_know.title } </strong> : { to_know.content }
                                                </div>
                                            )
                                        }
                                        {
                                            ((situation !== null || distances !== null || to_know !== null) && other !== null) && (
                                                <hr />
                                            )
                                        }
                                        {
                                            other !== null && (
                                                <div className={classes.justifyText} >
                                                    <strong> { other.title } </strong> : { other.content }
                                                </div>
                                            )
                                        }
                                        {
                                            ((situation !== null || distances !== null || to_know !== null || other !== null))
                                            && (info_hotel.hotel[0].situation !== undefined && info_hotel.hotel[0].situation !== null) && (
                                                <hr />
                                            )
                                        }
                                        {
                                            (info_hotel.hotel[0].situation !== undefined && info_hotel.hotel[0].situation !== null) && (
                                                <div className={classes.justifyText} >
                                                    <strong> Situation de l'hôtel </strong> : <div dangerouslySetInnerHTML={ { __html: info_hotel.hotel[0].situation } } />
                                                </div>
                                            )

                                        }
                                        {
                                            ((situation !== null || distances !== null || to_know !== null || other !== null
                                            || (info_hotel.hotel[0].situation !== undefined && info_hotel.hotel[0].situation !== null))
                                            && (info_hotel.hotel[0].room_desc !== undefined && info_hotel.hotel[0].room_desc !== null)) && (
                                                <hr />
                                            )
                                        }
                                        {
                                            (info_hotel.hotel[0].room_desc !== undefined && info_hotel.hotel[0].room_desc !== null) && (
                                                <div className={classes.justifyText} >
                                                    <strong> Description des chambres </strong> : <div dangerouslySetInnerHTML={ { __html: info_hotel.hotel[0].room_desc } } />
                                                </div>
                                            )

                                        }
                                        {
                                            ((situation !== null || distances !== null || to_know !== null || other !== null
                                            || (info_hotel.hotel[0].situation !== undefined && info_hotel.hotel[0].situation !== null)
                                            || (info_hotel.hotel[0].room_desc !== undefined && info_hotel.hotel[0].room_desc !== null))
                                            && (info_hotel.hotel[0].restoration !== undefined && info_hotel.hotel[0].restoration !== null && info_hotel.hotel[0].restoration !== "")) && (
                                                <hr />
                                            )
                                        }
                                        {
                                            (info_hotel.hotel[0].restoration !== undefined && info_hotel.hotel[0].restoration !== null && info_hotel.hotel[0].restoration !== "") && (
                                                <div className={classes.justifyText} >
                                                    <strong> Restauration </strong> : <div dangerouslySetInnerHTML={ { __html: info_hotel.hotel[0].restoration } } />
                                                </div>
                                            )

                                        }
                                        {
                                            ((situation !== null || distances !== null || to_know !== null || other !== null
                                            || (info_hotel.hotel[0].situation !== undefined && info_hotel.hotel[0].situation !== null)
                                            || (info_hotel.hotel[0].room_desc !== undefined && info_hotel.hotel[0].room_desc !== null)
                                            || (info_hotel.hotel[0].restoration !== undefined && info_hotel.hotel[0].restoration !== null))
                                            && (info_hotel.hotel[0].children !== undefined && info_hotel.hotel[0].children !== null && info_hotel.hotel[0].children !== "")) && (
                                                <hr />
                                            )
                                        }
                                        {
                                            (info_hotel.hotel[0].children !== undefined && info_hotel.hotel[0].children !== null && info_hotel.hotel[0].children !== "") && (
                                                <div className={classes.justifyText} >
                                                    <strong> Enfants </strong> : <div dangerouslySetInnerHTML={ { __html: info_hotel.hotel[0].children } } />
                                                </div>
                                            )

                                        }
                                    </div>
                                )
                            }
                            {
                                step_info_hotel === 2 && (
                                    <div >
                                        <h1>{ t<string>("summary.rooms_infos") }</h1>
                                        {
                                            rooms !== null && (
                                                <div className={classes.justifyText} >
                                                    <strong> { rooms.title } </strong> : { rooms.content }
                                                </div>
                                            )
                                        }
                                        {
                                            rooms !== null && (
                                                <hr />
                                            )
                                        }
                                        {
                                            check_in_out !== null && (
                                                <div className={classes.justifyText} >
                                                    <strong> { check_in_out.title } </strong> : { check_in_out.content }
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                step_info_hotel === 6 && (
                                    <div >
                                        {
                                            (info_hotel !== null && info_hotel.hotel[0] !== null && info_hotel.hotel[0].building.length > 0) && (
                                                <Fragment>
                                                    <h3>{ t<string>("accommodation.info") } :</h3>
                                                    {
                                                        info_hotel.hotel[0].building.map((building: any, index: number) => {
                                                            return (
                                                                <Fragment key={ index }>
                                                                    {
                                                                        index > 0 && (
                                                                            <Divider/>
                                                                        )
                                                                    }
                                                                    <br/>
                                                                    <Typography variant={ "body2" }>{ building.value_int }</Typography>
                                                                    <br/>
                                                                </Fragment>
                                                            );
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        }
                                        {
                                            (info_hotel !== null && info_hotel.hotel[0] !== null && info_hotel.hotel[0].facility.length > 0) && (
                                                <Fragment>
                                                    <h3>{ t<string>("accommodation.equipment_title") } :</h3>
                                                    {
                                                        info_hotel.hotel[0].facility.map((facility: any, index: number) => {
                                                            return (
                                                                <Fragment key={ index }>
                                                                    {
                                                                        index > 0 && (
                                                                            <Divider/>
                                                                        )
                                                                    }
                                                                    <br/>
                                                                    <Typography variant={ "body2" }>{ t<string>('accommodation.facts.' + facility.codename) }</Typography>
                                                                    <br/>
                                                                </Fragment>
                                                            );
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        }
                                        {
                                            (info_hotel !== null && info_hotel.hotel[0] !== null && info_hotel.hotel[0].room.length > 0) && (
                                                <Fragment>
                                                    <h3>{ t<string>("accommodation.rooms") } :</h3>
                                                    {
                                                        info_hotel.hotel[0].room.map((room: any, index: number) => {
                                                            return (
                                                                <Fragment key={ index }>
                                                                    {
                                                                        index > 0 && (
                                                                            <Divider/>
                                                                        )
                                                                    }
                                                                    <br/>
                                                                    <Typography variant={ "body2" }>{ t<string>('accommodation.facts.' + room.codename) }</Typography>
                                                                    <br/>
                                                                </Fragment>
                                                            );
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        }
                                        {
                                            (info_hotel !== null && info_hotel.hotel[0] !== null && info_hotel.hotel[0].meals.length > 0) && (
                                                <Fragment>
                                                    <h3>{ t<string>("accommodation.meal") } :</h3>
                                                    {
                                                        info_hotel.hotel[0].meals.map((meals: any, index: number) => {
                                                            return (
                                                                <Fragment key={ index }>
                                                                    {
                                                                        index > 0 && (
                                                                            <Divider/>
                                                                        )
                                                                    }
                                                                    <br/>
                                                                    <Typography variant={ "body2" }>{ t<string>('accommodation.facts.' + meals.codename) }</Typography>
                                                                    <br/>
                                                                </Fragment>
                                                            );
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        }
                                        {
                                            (info_hotel !== null && info_hotel.hotel[0] !== null && info_hotel.hotel[0].sports.length > 0) && (
                                                <Fragment>
                                                    <h3>{ t<string>("accommodation.sport") } :</h3>
                                                    {
                                                        info_hotel.hotel[0].sports.map((sports: any, index: number) => {
                                                            return (
                                                                <Fragment key={ index }>
                                                                    {
                                                                        index > 0 && (
                                                                            <Divider/>
                                                                        )
                                                                    }
                                                                    <br/>
                                                                    <Typography variant={ "body2" }>{ t<string>('accommodation.facts.' + sports.codename) }</Typography>
                                                                    <br/>
                                                                </Fragment>
                                                            );
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        }
                                        {
                                            (info_hotel !== null && info_hotel.hotel[0] !== null && info_hotel.hotel[0].dist.length > 0) && (
                                                <Fragment>
                                                    <h3>{ t<string>("accommodation.distance") } :</h3>
                                                    {
                                                        info_hotel.hotel[0].dist.map((dist: any, index: number) => {
                                                            if (dist.value_int > 0) {
                                                                return (
                                                                    <Fragment key={ index }>
                                                                        {
                                                                            index > 0 && (
                                                                                <Divider/>
                                                                            )
                                                                        }
                                                                        <br/>
                                                                        <Typography variant={ "body2" }>{ dist.value_int }</Typography>
                                                                        <br/>
                                                                    </Fragment>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        }
                                        {
                                            (info_hotel !== null && info_hotel.hotel[0] !== null && info_hotel.hotel[0].payment.length > 0) && (
                                                <Fragment>
                                                    <h3>{ t<string>("accommodation.payment") } :</h3>
                                                    {
                                                        info_hotel.hotel[0].payment.map((payment: any, index: number) => {
                                                            return (
                                                                <Fragment key={ index }>
                                                                    {
                                                                        index > 0 && (
                                                                            <Divider/>
                                                                        )
                                                                    }
                                                                    <br/>
                                                                    <Typography variant={ "body2" }>{ t<string>('accommodation.facts.' + payment.codename) }</Typography>
                                                                    <br/>
                                                                </Fragment>
                                                            );
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                step_info_hotel === 3 && (
                                    <div >
                                        <h1>{ t<string>("summary.activity_services") }</h1>
                                        {
                                            equipements !== null && (
                                                <div className={classes.justifyText} >
                                                    <strong> { equipements.title } </strong> : { equipements.content }
                                                </div>
                                            )
                                        }
                                        {
                                            equipements !== null && (
                                                <hr />
                                            )
                                        }
                                        {
                                            sports_animation !== null && (
                                                <div className={classes.justifyText} >
                                                    <strong> { sports_animation.title } </strong> : { sports_animation.content }
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                step_info_hotel === 4 && (
                                    <div >
                                        <h1>{ t<string>("summary.restauration") }</h1>
                                        {
                                            restauration !== null && (
                                                <div className={classes.justifyText} >
                                                    <strong> { restauration.title } </strong> : { restauration.content }
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                step_info_hotel === 5 && (
                                    <HotelInfoMap info_hotel={info_hotel}/>
                                //<div >
                                //<h1>{ t("summary.google_map") }</h1>
                                //<div className={ classes.hotelInfoMap } id={ "hotel-info-map-" + info_hotel.id }/>
                                //</div>
                                )
                            }
                            {
                                step_info_hotel === 7 && (
                                    // <div className={`${classes.genericText} ${classes.justifyText}`} dangerouslySetInnerHTML={ { __html: content } } />
                                    <DialogHotelInfo accommodation={info_hotel}/>
                                )
                            }
                        </div>
                    )
                }
            </DialogContent>
        </Dialog>
    );
};
export default HotelInfo;
