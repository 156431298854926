//---- Dependencies ----//
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { withTranslation } from "react-i18next";

//---- Actions ----//
import { ToggleAndSetPicturesModal } from "../../../Actions/Summary";

//---- Component ----//
import RoomPicturesSlider from "../../Accommodation/RoomPicturesSlider.jsx";
import RoomPicturesSliderMobile from "./RoomPicturesSliderMobile.jsx";

//---- Class ----//
@connect((store) => {
    return {
        modal_pictures_arr: store.summary.modal_pictures_arr,
        pictures_arr: store.summary.pictures_arr,
        index_pictures: store.summary.index_pictures
    }
})
@withTranslation()
class HotelRoomPicModal extends Component {
    constructor(props, context) {
        super(props, context);
    }
    render() {
        const { modal_pictures_arr, pictures_arr, index_pictures, dispatch, t } = this.props;
        return (
            <Modal id={ "modal-room-picture" } isOpen={ modal_pictures_arr } toggle={() => { dispatch(ToggleAndSetPicturesModal(pictures_arr, index_pictures)); }} size={"lg"}>
                <div className={"delete-icon-modal"} onClick={() => {
                    dispatch(ToggleAndSetPicturesModal(pictures_arr, index_pictures));
                }}>
                    <i className={"icon-099-delete"} />
                </div>
                <div>
                    <ModalBody className={"grey-bg modal-picture-content"}>
                    {
                        screen.width >= 992 && (
                            <RoomPicturesSlider type={"modal"} dispatch={dispatch} pictures={pictures_arr} index={index_pictures} />
                        )
                    }
                    {
                        screen.width < 992 && (
                            <div>
                                <h1>{t("summary.photo_gallery")}</h1>
                                <div>
                                    <RoomPicturesSliderMobile type={"modal"} dispatch={dispatch} pictures={pictures_arr} index={index_pictures} />
                                </div>

                            </div>
                        )
                    }
                    </ModalBody>
                </div>
            </Modal>
        );
    }
}

export default HotelRoomPicModal;
