import React, { useState } from "react"
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import WorkIcon from "@material-ui/icons/Work";
import WorkOffIcon from '@material-ui/icons/WorkOff';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import moment from "moment";

import ConvertTime from "../Functions/ConvertTime";

import Transition from "../../Common/Transition";
import OutboundDetailsMenu from "./OutboundDetailsMenu";
import OutboundDetails from "../FlightSearch/OutboundDetails";
import ArrowIcon from '../FlightSearch/ArrowIcon';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight : 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize9: {
        fontSize: 9
    },
    fontSize12:{
        fontSize: 12
    },
    red: {
        color: "red"
    },
    orange: {
        color: "#E6592F"
    },
    main_color: {
        color: `${main_color}`
    },
    black80: {
        color: '#000000CC'
    },
    white: {
        color: "white"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    textCenter: {
        textAlign: "center"
    },
    borderTableCell:{
        borderBottom: "none"
    },
    durationDaysPosition: {
        position: "relative",
        bottom: 8
    },
    arrow: {
        fontSize: 50,
        margin: -18
    }
}))

const OutboundInfo = (({ searched_group, outbound_flight, outbound_index, outbounds_length }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const language = useSelector(store => store.header.language);
    const airlines = useSelector(store => store.flight_search.airlines);
    const airports = useSelector(store => store.flight_search.airports);
    const stations = useSelector(store => store.flight_search.stations);

    const [showDetails, setShowDetails] = useState(null);

    const airlineObj = airlines !== null ? airlines.find(item => item.id === outbound_flight.legs[0].marketing_airline) : null;
    const flight_list_render = useSelector(store => store.flight_search.flight_list_render);
    const selected_flight = useSelector(store => store.flight_search.selected_flight);
    let current_flight = flight_list_render.find(item => item.id === selected_flight);
    moment.locale(language);

    const GetStopOverCountry = () => {
        let countries = [];
        outbound_flight.legs.map((leg, leg_index) => {
            if(leg_index > 0){
                if (leg.origin_airport !== undefined && airports !== null && airports[leg.origin_airport] !== undefined) {
                    countries.push(airports[leg.origin_airport].iata_city.iata_country.country_code_alpha3);
                }else if (leg.origin_station !== undefined && stations !== null && stations[leg.origin_station] !== undefined) {
                    countries.push(stations[leg.origin_station].iata_city.iata_country.country_code_alpha3);
                }
            }
        })
        return "(" + countries.join(", ") + ")"
	}
    if (current_flight === undefined) return null;
    return(
        <TableContainer style={{ overflow: "hidden" }}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell padding="none" className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase} ${classes.black80}`} style={{ width: 83 }}>
                            {
                                outbounds_length === 2 && ((outbound_index === 0 && t("filters.go")) || (outbound_index === 1 && t("filters.return")))
                            }
                            {
                                outbounds_length > 2  && t("global.flight") + " " + (outbound_index + 1)
                            }
                        </TableCell>
                        {
                            airlineObj !== null && (
                                <TableCell padding="none" className={`${classes.textCenter}  ${classes.textCenter}`} style={{ width: 110 }}>
                                    <img src={ "https://storagefacilitatrip.blob.core.windows.net/iata-company/" + airlineObj.code + ".png" } alt={ "provider logo" } style={{ maxWidth: 81, maxHeight: 23 }} />   
                                </TableCell>
                            )
                        }
                        {
                            airlineObj !== null && current_flight !== null && current_flight.name === null && outbound_flight.legs[0].marketing_flight_number !== undefined && (
                                <TableCell padding="none" className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`} style={{ width: 96 }}>
                                    { `${airlineObj.code}-${outbound_flight.legs[0].marketing_flight_number}`}
                                </TableCell>
                            )
                        }
                        {
                            airlineObj !== null && current_flight !== null && current_flight.name === null && outbound_flight.legs[0].flight_number !== undefined && (
                                <TableCell padding="none" className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`} style={{ width: 96 }}>
                                    { `${airlineObj.code}-${outbound_flight.legs[0].flight_number}`}
                                </TableCell>
                            )
                        }
                        {
                            current_flight !== null && current_flight.name !== null && (
                                <TableCell padding="none" className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`} style={{ width: 96 }}>
                                    { current_flight.name }
                                </TableCell>
                            )
                        }
                        <TableCell padding="none" className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase} ${classes.black80}`} style={{ width: 89 }}>
                            { moment(outbound_flight.legs[0].departure_datetime_lt).format("L") }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell padding="none" className={`${classes.textCenter} ${classes.borderTableCell} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`} style={{ width: 83 }}>
                            { !outbound_flight.no_time_data && (language === "fr" ? moment(outbound_flight.legs[0].departure_datetime_lt).format("LT").replace(":", "h") : moment(outbound_flight.legs[0].departure_datetime_lt).format("LT")) }
                            {
                                outbound_flight.legs[0].origin_airport !== undefined && airports !== null && airports[outbound_flight.legs[0].origin_airport] !== undefined && (
                                    <div>
                                        <div className={`${classes.genericText} ${classes.fontSize9} ${classes.black80}`}>
                                            { airports[outbound_flight.legs[0].origin_airport].iata_city.name !== undefined ? airports[outbound_flight.legs[0].origin_airport].iata_city.name : airports[outbound_flight.legs[0].origin_airport].iata_city.international_name }
                                        </div>
                                        <div className={`${classes.genericText} ${classes.fontSize9} ${classes.black80}`}>
                                            { `(${airports[outbound_flight.legs[0].origin_airport].code})` }
                                        </div>
                                    </div>
                                )
                            }
                            {
                                outbound_flight.legs[0].origin_station !== undefined && stations !== null && stations[outbound_flight.legs[0].origin_station] !== undefined && (
                                    <div>
                                        <div className={`${classes.genericText} ${classes.fontSize9} ${classes.black80}`}>
                                            { stations[outbound_flight.legs[0].origin_station].iata_city.name !== undefined ? stations[outbound_flight.legs[0].origin_station].iata_city.name : stations[outbound_flight.legs[0].origin_station].iata_city.international_name }
                                        </div>
                                        <div className={`${classes.genericText} ${classes.fontSize9} ${classes.black80}`}>
                                            { `(${stations[outbound_flight.legs[0].origin_station].code})` }
                                        </div>
                                    </div>
                                )
                            }
                        </TableCell>
                        <TableCell padding="none" className={`${classes.textCenter} ${classes.borderTableCell}`} style={{ width: 110 }}>
                            {
                                !outbound_flight.no_time_data && (
                                    <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize9} ${classes.uppercase}`} style={{ paddingTop: 6 }}>
                                        { ConvertTime(outbound_flight.flight_duration, "seconds", language, t) }
                                    </div>
                                )
                            }
                            <div>
                                <ArrowIcon className={classes.arrow} />
                            </div>
                            <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize9} ${classes.uppercase}`}>
                                { outbound_flight.legs.length < 2 ? t("flight_search.direct_flight") : (outbound_flight.legs.length - 1) + " " + (outbound_flight.legs.length - 1 > 1 ? t("flight_search.stopovers") : t("flight_search.stopover")) }
                                {
                                    outbound_flight.legs.length >= 2 && (
                                        <div>
                                            { GetStopOverCountry() }
                                        </div>
                                    )
                                }
                            </div>
                        </TableCell>
                        <TableCell padding="none" className={`${classes.textCenter} ${classes.borderTableCell} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`} style={{ width: 96 }}>
                            {
                                !outbound_flight.no_time_data && (
                                    <Grid container alignItems="flex-start" justify="center">
                                        <Grid item className={classes.genericText}>
                                            { language === "fr" ? moment(outbound_flight.legs.slice(-1)[0].arrival_datetime_lt).format("LT").replace(":", "h") : moment(outbound_flight.legs.slice(-1)[0].arrival_datetime_lt).format("LT") }
                                        </Grid>
                                        {
                                            outbound_flight.duration_days !== undefined && outbound_flight.duration_days !== 0 && (
                                                <Grid item className={`${classes.genericText} ${classes.fontSize9} ${classes.red} ${classes.durationDaysPosition}`}>
                                                    { "+" + outbound_flight.duration_days + t("global.abrev_day")}
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                )
                            }
                            {
                                outbound_flight.legs.slice(-1)[0].destination_airport !== undefined && airports !== null && airports[outbound_flight.legs.slice(-1)[0].destination_airport] !== undefined && (
                                    <div>
                                        <div className={`${classes.genericText} ${classes.fontSize9} ${classes.black80}`}>
                                            { airports[outbound_flight.legs.slice(-1)[0].destination_airport].iata_city.name !== null ? airports[outbound_flight.legs.slice(-1)[0].destination_airport].iata_city.name : airports[outbound_flight.legs.slice(-1)[0].destination_airport].international_name }
                                        </div>
                                        <div className={`${classes.genericText} ${classes.fontSize9} ${classes.black80}`}>
                                            { `(${airports[outbound_flight.legs.slice(-1)[0].destination_airport].code})` }
                                        </div>
                                    </div>
                                )
                            }
                            {
                                outbound_flight.legs.slice(-1)[0].destination_station !== undefined && stations !== null && stations[outbound_flight.legs.slice(-1)[0].destination_station] !== undefined && (
                                    <div>
                                        <div className={`${classes.genericText} ${classes.fontSize9} ${classes.black80}`}>
                                            { stations[outbound_flight.legs.slice(-1)[0].destination_station].iata_city.name !== null ? stations[outbound_flight.legs.slice(-1)[0].destination_station].iata_city.name : stations[outbound_flight.legs.slice(-1)[0].destination_station].international_name }
                                        </div>
                                        <div className={`${classes.genericText} ${classes.fontSize9} ${classes.black80}`}>
                                            { `(${stations[outbound_flight.legs.slice(-1)[0].destination_station].code})` }
                                        </div>
                                    </div>
                                )
                            }
                        </TableCell>
                        <TableCell padding="none" className={`${classes.textCenter} ${classes.borderTableCell}`} style={{ width: 89 }}>
                            {
                                outbound_flight.legs[0].baggage_allowance && outbound_flight.legs[0].baggage_allowance.number_of_pieces > 0 && (
                                    <Grid container alignItems='center' justify="center">
                                        <Grid item>
                                            <WorkIcon className={classes.main_color} style={{ fontSize: 28 }} />
                                        </Grid>
                                        <Grid item className={`${classes.genericText} ${classes.fontSize9} ${classes.white}`} style={{ marginLeft: -19 }}>
                                            { "x" + outbound_flight.legs[0].baggage_allowance.number_of_pieces }
                                        </Grid>
                                    </Grid>
                                )
                            }
                            {
                                outbound_flight.legs[0].baggage_allowance && outbound_flight.legs[0].baggage_allowance.number_of_pieces === 0 && (
                                    <WorkOffIcon style={{ fontSize: 28, color: "#989998" }} />
                                )
                            }
                            {
                                outbound_flight.legs[0].cabin_class !== undefined && (
                                    <div className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`} style={outbound_flight.legs[0].baggage_allowance && outbound_flight.legs[0].baggage_allowance.number_of_pieces === 0 ? {} : { paddingLeft: 8 }}>
                                        { t(`flight_search.cabin_class_short.${outbound_flight.legs[0].cabin_class.toLowerCase()}`) }
                                    </div>
                                )
                            }
                        </TableCell>
                        <TableCell padding="none" className={classes.borderTableCell} style={{ width: 31 }}>
                            <IconButton size="small" onClick={(event) => setShowDetails(event.currentTarget.parentNode.parentNode)}>
                                { showDetails ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                            </IconButton>
                            {
                                !isHandHeld && (
                                    <OutboundDetailsMenu setShowDetails={setShowDetails} searched_group={searched_group} outbound_flight={outbound_flight} outbound_index={outbound_index} outbounds_length={outbounds_length} showDetails={showDetails} setShowDetails={setShowDetails} />
                                )
                            }
                            {
                                isHandHeld && (
                                    <Dialog open={showDetails} fullScreen TransitionComponent={Transition}>
                                        <DialogContent style={{ padding: 0, overflowX: "hidden" }}>
                                            <OutboundDetails setShowDetails={setShowDetails} outbound_flight={outbound_flight} outbound_index={outbound_index} travelers={searched_group.travelers} max_index={outbounds_length - 1} small={false} />
                                        </DialogContent>
                                    </Dialog>
                                )
                            }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
})

export default React.memo(OutboundInfo);