//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from "@material-ui/core/Menu";
import green from "@material-ui/core/colors/green";
import ProviderListFilters from '../../CarsMaterial/ProviderListFilters';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontSize12: {
        fontSize: 12
    },
    filterButton: {
        border: "1.03872px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: 31.1617,
        padding: "8px 12px",
        fontFamily: 'Roboto',
        fontStyle: 'normal',
    },
	active: {
	    color: green[500],
	    border: '2px solid black'
	},
    grey: {
        color: '#0000008A'
    },
    resetFilter: {
		cursor: 'pointer',
		textDecoration: 'underline'
	}
}));

const RenderTransfersFilter = ({typeFilters, setTypeFilters, transferType, prestationType, setPrestationType, prestationFilter, transfersProviders, providersFilters, setProvidersFilters, pickUp, dropOff, pickupLocation, setPickupLocation, dropoffLocation, setDropoffLocation, resetFilter}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const providers =  useSelector((store) => {
        return store.trip.providers;
    });
    const manual_providers =  useSelector((store) => {
        return store.trip.manual_providers;
    });
    const user = useSelector((store) => {
        return store.user.user;
    });
    const [openTransferType, setOpenTransferType] = useState(null);
    const [openPrestationType, setOpenPrestationType] = useState(null);
    const [openProviders, setOpenProviders] = useState(null);
    const [openPickup, setOpenPickup] = useState(null);
    const [openDropoff, setOpenDropoff] = useState(null);

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const handleProviders = (id) => {
        let tmp_providers = providersFilters.slice();
        if (providersFilters.includes(id)) {
            tmp_providers = tmp_providers.filter((el) => { return el !== id })
        }
        else {
            tmp_providers.push(id);
        }
        setProvidersFilters(tmp_providers);
    }
    const handleTransfers = (type) => {
        let tmp_type = typeFilters.slice();
        if (typeFilters.includes(type)) {
            tmp_type = tmp_type.filter((el) => { return el !== type })
        }
        else {
            tmp_type.push(type);
        }
        setTypeFilters(tmp_type);
    }
    const handlePickup = (location) => {
        let tmp_pickup = pickupLocation.slice();
        if (pickupLocation.includes(location)) {
            tmp_pickup = tmp_pickup.filter((el) => { return el !== location })
        }
        else {
            tmp_pickup.push(location);
        }
        setPickupLocation(tmp_pickup);
        // setDropoffLocation([]);
    }
    const handleDropoff = (location) => {
        let tmp_dropoff = dropoffLocation.slice();
        if (dropoffLocation.includes(location)) {
            tmp_dropoff = tmp_dropoff.filter((el) => { return el !== location })
        }
        else {
            tmp_dropoff.push(location);
        }
        setDropoffLocation(tmp_dropoff);
    }
    const handlePrestation = (prestation) => {
        if (prestationType === prestation) {
            setPrestationType("");
        }
        else {
            setPrestationType(prestation);
        }
    }
    return (
        <Grid container direction={"row"} spacing={2} alignItems={"center"}>
            <Grid item>
                <Button size="small" className={`${classes.filterButton} ${typeFilters.length !== 0 ? classes.active : classes.grey}`} onClick={(event) => {setOpenTransferType(event.currentTarget)}}>
                    <div className={`${classes.fontWeight500} ${classes.fontSize12}`} style={{ textTransform: "capitalize" }}>
                        { t("transfers.transfer_type") }
                    </div>
                </Button>
                <Menu 
                    id="transfers-type"
                    anchorEl={openTransferType}
                    keepMounted
                    open={Boolean(openTransferType)}
                    onClose={() => setOpenTransferType(false)}
                    disableScrollLock={true}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Grid container direction={"column"} style={{padding: "0px 16px"}}>
                    {
                        transferType.map((type) => {
                            return (
                                <Grid item key={type}>
                                    <FormControlLabel 
                                        control={<Checkbox checked={typeFilters.includes(type)} size="small" onChange={() => {handleTransfers(type)}} />}
                                        label={t("cars.categories." + ((type).toLowerCase()).replace(" ", "_"))}
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                </Grid>
                            )
                        })
                    }
                    </Grid>
                </Menu>
            </Grid>
            <Grid item>
                <Button size="small" className={`${classes.filterButton} ${prestationType.length !== 0 ? classes.active : classes.grey}`} onClick={(event) => {setOpenPrestationType(event.currentTarget)}}>
                    <div className={`${classes.fontWeight500} ${classes.fontSize12}`} style={{ textTransform: "capitalize" }}>
                        { t("transfers.prestation_type") }
                    </div>
                </Button>
                <Menu 
                    id="providers-type"
                    anchorEl={openPrestationType}
                    keepMounted
                    open={Boolean(openPrestationType)}
                    onClose={() => setOpenPrestationType(false)}
                    disableScrollLock={true}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Grid container direction={"column"} style={{padding: "0px 16px"}}>
                    {
                        prestationFilter.map((prestation) => {
                            return (
                                <Grid item key={prestation}>
                                    <FormControlLabel 
                                        control={<Checkbox checked={prestationType.includes(prestation)} size="small" onChange={() => {handlePrestation(prestation)}} />}
                                        label={prestation}
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                </Grid>
                            )
                        })
                    }
                    </Grid>
                </Menu>
                
            </Grid>
            {
                (quotation_code === "verdie" || (quotation_code !== "verdie" && user.client_full.type !== 2)) && (
                    <Grid item>
                        <Button size="small" className={`${classes.filterButton}  ${providersFilters.length !== 0 ? classes.active : classes.grey}`} onClick={(event) => {setOpenProviders(event.currentTarget)}}>
                            <div className={`${classes.fontWeight500} ${classes.fontSize12}`} style={{ textTransform: "capitalize" }}>
                                { t("accommodation_filters.providers") }
                            </div>
                        </Button>
                        <Menu 
                            id="providers-type"
                            anchorEl={openProviders}
                            keepMounted
                            open={Boolean(openProviders)}
                            onClose={() => setOpenProviders(false)}
                            disableScrollLock={true}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        >
                            <Grid container direction={"column"} style={{padding: "0px 16px"}}>
                            {
                                transfersProviders.map((provider) => {
                                    let found_provider = providers.find((el) => {return el.provider.id === provider});
                                    if (found_provider === undefined) {
                                        found_provider = manual_providers.find((el) => {return el.provider.id === provider});
                                    }
                                    if (found_provider !== undefined) {
                                        let provider_name = found_provider.provider.name;
                                        return (
                                            <Grid item key={provider}>
                                                <FormControlLabel 
                                                    control={<Checkbox checked={providersFilters.includes(provider)} size="small" onChange={() => {handleProviders(provider)}} />}
                                                    label={provider_name}
                                                    classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                                />
                                            </Grid>
                                        )
                                    }
                                })
                            }
                            </Grid>
                        </Menu>
                    </Grid>
                )
            }
            <Grid item>
                <Button size="small" className={`${classes.filterButton} ${pickupLocation.length !== 0 ? classes.active : classes.grey}`} onClick={(event) => {setOpenPickup(event.currentTarget)}}>
                    <div className={`${classes.fontWeight500} ${classes.fontSize12}`} style={{ textTransform: "capitalize" }}>
                        { t("transfers.starting_point") }
                    </div>
                </Button>
                <Menu 
                    id="starting-point"
                    anchorEl={openPickup}
                    keepMounted
                    open={Boolean(openPickup)}
                    onClose={() => setOpenPickup(false)}
                    disableScrollLock={true}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Grid container direction={"column"} style={{padding: "0px 16px"}}>
                    {
                        pickUp.map((location) => {
                            return (
                                <Grid item key={location}>
                                    <FormControlLabel 
                                        control={<Checkbox checked={pickupLocation.includes(location)} size="small" onChange={() => {handlePickup(location)}} />}
                                        label={location}
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                </Grid>
                            )
                        })
                    }
                    </Grid>
                </Menu>
            </Grid>
            <Grid item>
                <Button size="small" className={`${classes.filterButton} ${dropoffLocation.length !== 0 ? classes.active : classes.grey}`} onClick={(event) => {setOpenDropoff(event.currentTarget)}}>
                    <div className={`${classes.fontWeight500} ${classes.fontSize12}`} style={{ textTransform: "capitalize" }}>
                        { t("transfers.ending_point") }
                    </div>
                </Button>
                <Menu 
                    id="dropoff-point"
                    anchorEl={openDropoff}
                    keepMounted
                    open={Boolean(openDropoff)}
                    onClose={() => setOpenDropoff(false)}
                    disableScrollLock={true}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Grid container direction={"column"} style={{padding: "0px 16px"}}>
                    {
                        dropOff.map((location) => {
                            return (
                                <Grid item key={location}>
                                    <FormControlLabel 
                                        control={<Checkbox checked={dropoffLocation.includes(location)} size="small" onChange={() => {handleDropoff(location)}} />}
                                        label={location}
                                        classes={{  label: `${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}` }}
                                    />
                                </Grid>
                            )
                        })
                    }
                    </Grid>
                </Menu>
            </Grid>
            <Grid item>
                <Typography className={ clsx(classes.resetFilter, classes.genericText) } variant="overline" display="block" gutterBottom onClick={() => resetFilter()}>
                    {t("menu.trip_list.clean_filter")}
                </Typography>
            </Grid>
        </Grid>
    )
};
export default React.memo(RenderTransfersFilter);