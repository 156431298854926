import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";
import {
    Avatar,
    CircularProgress,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Popover,
    Stack,
    Step,
    StepConnector,
    StepContent,
    StepIconProps,
    StepLabel,
    stepLabelClasses,
    Stepper,
    StepperProps,
    styled,
    Tooltip,
    Typography
} from "@mui/material";
import {
    ArrowBackIosNew,
    ArrowForwardIos,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { AppState } from "../../Reducers/Reducers";
import { findLastIndex } from "lodash";
import { getLocalizedValue } from "../Itinerary/utils/getLocalizedValue";
import { Itinerary } from "../Itinerary/objects/itinerary";
import { StepsDirectionsManager } from "../Itinerary/utils/stepsDirectionsManager";
import { Transport } from "../Itinerary/objects/transport";
import { itineraryToItineraryInput } from "../Itinerary/utils/itineraryToItineraryInput";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import {
    AirIcon,
    AirportShuttleIcon,
    BedroomBabyIcon,
    CarIcon,
    DirectionsBikeIcon,
    DirectionsBoatIcon,
    DirectionsBusIcon,
    DirectionsCarIcon,
    DirectionsSubwayIcon,
    DirectionsTransitIcon,
    DirectionsWalkIcon,
    FlightIcon,
    LocalTaxiIcon,
    TrainIcon,
    TwoWheelerIcon,
    UnknowIcon,
    LoadingIcon
} from "./CarsItineraryTransportsIcon";
import { Place, Route } from "../Itinerary/objects/r2rSearchResponse";
import { changeStepTransport } from "../Itinerary/redux/reducer";
import tzlookup from "@photostructure/tz-lookup";
import { selectStepTransport } from "../Itinerary/utils/selectStepTransport";
import GetCookie from "../Common/Functions/GetCookie";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import Axios from "axios";
import { SetItinerary } from "../../Actions/Itinerary";

type ItemComponentProps = {
    children: JSX.Element,
    index: number,
    step: ItineraryContentStep,
    onClick: () => void
}
export type CarsItineraryProps = {
    step?: number,
    steps: ItineraryContentStep[],
    setStep?: (step: number) => void,
    showPreview?: boolean,
    showStatus?: boolean,
    orientation?: StepperProps['orientation'],
    alternativeLabel?: StepperProps['alternativeLabel'],
    disableDatesEdit?: boolean,
    ItemComponent?: React.FC<ItemComponentProps>
}

export const CarsItinerary = React.forwardRef<HTMLDivElement, CarsItineraryProps>(
    function CarsItinerary(props, ref): JSX.Element {
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const locale = useSelector((state: AppState) => state.locale.current_locale);
        const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
        const containerRef = useRef<HTMLDivElement>(null);
        const itemsRef = useRef<HTMLDivElement[]>([]);
        const filterItinerary = itinerary.filter(step => step.step_type === 'STEP');
        
        const [loadingTransports, setLoadingTransports] = useState<null | number>(null);
        const [transports, setTransports] = useState<Transport[]>([]);
        const [areElementsAllVisible, setAreElementsAllVisible] = useState(false);
        const [biggestElementWidth, setBiggestElementWidth] = useState(0);
        const [stepIndex, setStepIndex] = useState<null | number>(null);
        const [anchorEl, setAnchorEl] = useState(null);
        const onPrev = () => {
            const firstVisibleElementIndex = itemsRef.current.findIndex((item) => {
                return isElementVisible(containerRef.current, item);
            });
            itemsRef.current[firstVisibleElementIndex - 1]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        };
    
        const onNext = () => {
            const lastVisibleElementIndex = findLastIndex(
                itemsRef.current,
                (item) => {
                    return isElementVisible(containerRef.current, item);
                }
            );
            itemsRef.current[lastVisibleElementIndex + 1]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        };
        const onChangeStepTransport = async (
            newTransport: Transport
        ) => {
            const { headers } = CheckBeforeRequest();
            if (stepIndex !== null) {
                const result = filterItinerary.map(itineraryToItineraryInput).sort(sortItinerary);
                let current_step = result[stepIndex - 1];
                let next_step = result[stepIndex];
                if (current_step !== undefined && next_step !== undefined) {
                    const manager = StepsDirectionsManager.getInstance();
                    const aPosition = await manager.transformStepToCoordinates(current_step);
                    const bPosition = await manager.transformStepToCoordinates(next_step);
                    selectStepTransport({
                        step: current_step,
                        nextStep: next_step,
                        fromTimezone: tzlookup(aPosition.lat(), aPosition.lng()),
                        toTimezone: tzlookup(bPosition.lat(), bPosition.lng()),
                        nextStepCoordinates: {
                            latitude: bPosition.lat(),
                            longitude: bPosition.lng()
                        },
                        transport: newTransport.alternatives[0],
                        r2rPlaces: newTransport.r2rPlaces,
                        r2rRoutes: newTransport.r2rRoutes,
                        isCustom: false
                    });
                    console.log('current_step:', current_step);
                    Axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/${current_step.id}/?token=${GetCookie("trip_token")}&shift_mode=true`,
                        headers: headers,
                        data: JSON.stringify({
                            r2r_json: current_step?.r2r_json
                        })
                    }).then(function (data:any) {
                        console.log('data:', data);
                        dispatch(SetItinerary(data.data));
                        handleClose();
                    }).catch(function (error:any) {
                        console.log('error:', error);
                    });
                }
            }
        };

        useEffect(() => {
            itemsRef.current = itemsRef.current.slice(0, filterItinerary.length);
            const resizeObserver = new ResizeObserver((entries) => {
                const maxWidth = entries.reduce((prev, current) => {
                    return prev > current.target.clientWidth ?
                        prev :
                        current.target.clientWidth;
                }, 0);
                setBiggestElementWidth((state) => {
                    return maxWidth > state ?
                        maxWidth + 60 :
                        state;
                });
            });
            const children = itemsRef.current.map((item) => {
                return item.querySelector('.MuiStepLabel-labelContainer > span');
            }).filter((item): item is NonNullable<typeof item> => !!item);
            children.forEach((item) => {
                resizeObserver.observe(item);
            });
            return () => resizeObserver.disconnect();
        }, [filterItinerary]);

        const handleClick = async (index:number) => {
            setLoadingTransports(index);
            const result = filterItinerary.map(itineraryToItineraryInput).sort(sortItinerary);
            let current_step = result[index - 1];
            let next_step = result[index];
            if (current_step !== undefined && next_step !== undefined) {
                const manager = StepsDirectionsManager.getInstance();
                try {
                    let tmp_transports = await manager.findAvailableTransports(current_step, next_step);
                    let unique_transports:Transport[] = [];
                    tmp_transports.map((transport:Transport) => {
                        if (unique_transports.find((unique) => unique.types.join() === transport.types.join()) === undefined) {
                            unique_transports.push(transport);
                        }
                    });
                    setTransports(unique_transports);
                    if (tmp_transports.length !== 0) {
                        setAnchorEl(itemsRef.current[index]);
                        setStepIndex(index);
                    }
                } catch (error: any) {
                    console.error(error);
                } finally {
                    setLoadingTransports(null);
                }
                setLoadingTransports(null);
            }
        };
    
        const handleClose = () => {
            setTransports([]);
            setAnchorEl(null);
            setStepIndex(null);
        };
    
        const open = Boolean(anchorEl);
        const id = open ? 'choose-transport' : undefined;
        return (
            <Stack ref={ref} direction="row" alignItems="center">
                {
                    !areElementsAllVisible &&
                    props.orientation === 'horizontal' &&
                    <IconButton onClick={onPrev}>
                        <ArrowBackIosNew />
                    </IconButton>
                }
                <Stepper
                    ref={containerRef}
                    activeStep={props.step}
                    orientation={props.orientation ?? 'vertical'}
                    // alternativeLabel={props.alternativeLabel}
                    sx={{
                        overflow: 'hidden',
                        flex: 1
                    }}
                    connector={
                        <StepConnector sx={{
                            "maxWidth": biggestElementWidth / filterItinerary.length,
                            // "minWidth": biggestElementWidth / filterItinerary.length,
                            '& .MuiStepConnector-line': {
                                border: '1px solid #bdbdbd'
                            }
                        }}/>
                    }
                    nonLinear
                >
                    {
                        filterItinerary.map((item, index, array) => {
                            const prevStep = array[index - 1];
                            let destination = '';
                            let step_date = `${window.moment.utc(item.start_date).format('L')} - ${window.moment.utc(item.end_date).format('L')}`;
                            let icon = UnknowIcon;
                            let vehicle = prevStep?.r2r_json?.vehicle;
                            if (vehicle !== undefined && (loadingTransports === null || loadingTransports !== index)) {
                                switch (vehicle.kind) {
                                    case 'animal': {
                                        icon = BedroomBabyIcon;
                                        break;
                                    }
                                    case 'bicycle': {
                                        icon = DirectionsBikeIcon;
                                        break;
                                    }
                                    case 'bus': {
                                        icon = DirectionsBusIcon;
                                        break;
                                    }
                                    case 'cablecar': {
                                        icon = DirectionsTransitIcon;
                                        break;
                                    }
                                    case 'car': {
                                        icon = CarIcon;
                                        break;
                                    }
                                    case 'ferry': {
                                        icon = DirectionsBoatIcon;
                                        break;
                                    }
                                    case 'foot': {
                                        icon = DirectionsWalkIcon;
                                        break;
                                    }
                                    case 'helicopter': {
                                        icon = AirIcon;
                                        break;
                                    }
                                    case 'plane': {
                                        icon = FlightIcon;
                                        break;
                                    }
                                    case 'rideshare': {
                                        icon = DirectionsCarIcon;
                                        break;
                                    }
                                    case 'shuttle': {
                                        icon = AirportShuttleIcon;
                                        break;
                                    }
                                    case 'subway': {
                                        icon = DirectionsSubwayIcon;
                                        break;
                                    }
                                    case 'taxi': {
                                        icon = LocalTaxiIcon;
                                        break;
                                    }
                                    case 'towncar': {
                                        icon = DirectionsCarIcon;
                                        break;
                                    }
                                    case 'train': {
                                        icon = TrainIcon;
                                        break;
                                    }
                                    case 'tram': {
                                        icon = DirectionsTransitIcon;
                                        break;
                                    }
                                    case 'bike': {
                                        icon = TwoWheelerIcon;
                                        break;
                                    }
                                    default: {                                            
                                        icon = UnknowIcon;
                                    }
                                }
                            }
                            if (loadingTransports === index) {
                                icon = LoadingIcon;
                            }
                            const nightDestination = item.destination;
                            destination = getLocalizedValue(
                                locale,
                                nightDestination?.data ?? {
                                    name: '',
                                    localization: []
                                },
                                'name'
                            ) ?? '';
                            destination = destination.length > 0 ?
                                destination :
                                (
                                    nightDestination?.data?.international_name ?? ''
                                );
                            destination = destination.split(',')[0] ?? '';
                            const content = (
                                <>
                                    <StepLabel
                                        StepIconProps={{
                                            sx: {
                                                display: index === 0 ? 'none' : undefined
                                            }
                                        }}
                                        StepIconComponent={index !== 0 ? icon : undefined}
                                        sx={{
                                            '& .MuiStepLabel-label': {
                                                display: 'flex !important'
                                            },
                                            '& .MuiStepLabel-label.Mui-active': {
                                                fontWeight: 'normal !important',
                                                color: 'rgba(0, 0, 0, 0.6) !important'
                                            }
                                        }}
                                    >
                                        <>
                                            {
                                                index !== 0 &&
                                                <StepConnector
                                                    sx={{
                                                        // margin: '0 20px 0 20px',
                                                        // "maxWidth": biggestElementWidth / filterItinerary.length,
                                                        "minWidth": biggestElementWidth / filterItinerary.length,
                                                        "marginTop": '35px',
                                                        "marginRight": '5px',
                                                        '& .MuiStepConnector-line': {
                                                            border: '1px solid #bdbdbd'
                                                        }
                                                    }}
                                                />
                                            }
                                            <Stack justifyItems={'center'} alignItems={'center'}>
                                                <Typography>{t<string>('itinerary.step') + (index + 1)}</Typography>
                                                <Typography>{destination}</Typography>
                                                <Typography>{step_date}</Typography>
                                            </Stack>
                                        </>
                                    </StepLabel>
                                </>
                            );
                            return (
                                <StepContainer
                                    key={index}
                                    ref={(element) => {
                                        if (element) {
                                            itemsRef.current[index] = element;
                                            setAreElementsAllVisible(
                                                itemsRef.current.every((item) => {
                                                    return isElementVisible(containerRef.current, item);
                                                })
                                            );
                                        }
                                    }}
                                    onClick={() =>{
                                        handleClick(index);
                                        props.setStep && props.setStep(index);
                                    }}
                                    sx={{ minWidth: 'fit-content' }}
                                    // sx={{ minWidth: 'biggestElementWidth' }}
                                >
                                    {content}
                                </StepContainer>
                            );
                        })
                    }
                </Stepper>
                {
                    !areElementsAllVisible && 
                    props.orientation === 'horizontal' &&
                    <IconButton onClick={onNext}>
                        <ArrowForwardIos />
                    </IconButton>
                }
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <List sx={{ width: 170 }}>
                        {
                            transports.map((transport, index_transport) => {
                                return (
                                    <ListItem key={index_transport} disablePadding>
                                        <ListItemButton onClick={() => onChangeStepTransport(transport)}>
                                            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                                                <Grid item xs={'auto'}>
                                                    <ListItemAvatar sx={{ display: 'inline-flex' }}>
                                                        {
                                                            transport.types.map((type:string, index_type) => (
                                                                <Avatar key={index_type} alt={type} src={`/Img/transport/route-${type}.png`} sx={{ width: 24, height: 24, marginRight: '2px' }} />
                                                                // <img
                                                                //     key={index_type}
                                                                //     src={"/Img/transport/route-" + type + ".png"}
                                                                //     height="25px"   
                                                                //     className={"itinerary-transport-mode-icon"}
                                                                // />
                                                            ))
                                                        }
                                                    </ListItemAvatar>
                                                </Grid>
                                                <Grid item>
                                                    <ListItemText primary={ t<string>(`global.${transport.types[0]}`) }/>
                                                </Grid>
                                            </Grid>
                                        </ListItemButton>
                                        
                                    </ListItem>

                                );
                            })
                        }
                    </List>
                </Popover>
            </Stack>
        );
    }
);

const isElementVisible = (containerElement: HTMLDivElement | null, itemElement: HTMLDivElement) => {
    if (containerElement) {
        const container = containerElement.getBoundingClientRect();
        const element = itemElement.getBoundingClientRect();
        const containerLeft = Math.trunc(container.left);
        const containerRight = Math.trunc(container.right);
        const elementLeft = Math.trunc(element.left);
        const elementRight = Math.trunc(element.right);
        return (
            containerLeft <= elementLeft &&
            elementLeft <= containerRight
        ) && (
            containerLeft <= elementRight &&
            elementRight <= containerRight
        );
    }
    return false;
};
const StepContainer = styled(Step)(() => ({
    cursor: 'pointer'
}));
