//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';

import HotelCard from '../PackagesMaterial/HotelCard.tsx';
import TransfersCard from '../PackagesMaterial/TransfersCard.tsx';
import ActivitiesCard from '../PackagesMaterial/ActivitiesCard.tsx';
import TransfersCardManual from '../PackagesMaterial/TransfersCardManual.tsx';
import ActivitiesCardManual from '../PackagesMaterial/ActivitiesCardManual.tsx';
import CarsCard from '../PackagesMaterial/CarsCard.tsx';
import FlightsCard from '../PackagesMaterial/FlightsCard.tsx';
import ItiDetailStepTransport from '../../ItineraryTypeDetails/ItiDetailStepTransport.jsx';
import RenderTransport from './RenderTransport.js';
import HotelCardManual from '../PackagesMaterial/HotelCardManual.tsx';
import localeText from '../../Functions/localeText.js';
import i18n from '../../../i18n.jsx';
import CircuitStepper from './CircuitStepper.tsx';
import { Box, Fab, Paper, Stack, styled } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import ByDayPicture from './ByDayPicture.tsx';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    textJustify: {
        textAlign: "justify"
    },
    textCenter: {
        textAlign: "center"
    },
    picture: {
        // width: "100%",
        // height: "100%",
        objectFit: "cover",
        width: '100%',
        margin: "0px 21px 10px 0",
    },
    mobilePicture: {
        width: "100%",
        margin: "0px 21px 10px 0"
    },
    bold: {
        fontWeight: "bold"
    },
    capitalize: {
        textTransform: "capitalize"
    },
    hrText: {
        lineHeight: "20px",
        position: "relative",
        outline: "0",
        border: "0",
        textAlign: "center",
        height: 20,
        fontSize: 34,
        '&::before': {
            content: "''",
            background: "#0000008A",
            position: "absolute",
            left: "0",
            top: "50%",
            width: "100%",
            height: "1px"
        },
        '&::after': {
            content: "attr(data-content)",
            position: "relative",
            display: "inline-block",
            color: "#0000008A",
            padding: "0 .5em",
            // lineHeight: "1.5em",
            backgroundColor: "white"
        }
    },
    border: {
        border: '1px solid #ebebeb',
        borderRadius: 13,
        padding: 10,
        marginBottom: 10,
        backgroundColor: 'white',
        borderRadius: 20,
        marginRight: 7,
        marginLeft: 7
    },
    fabColor: {
        boxShadow: 'none !important',
        zIndex: '1 !important',
        backgroundColor: `${main_color} !important`,
        color: 'white !important',
        "&:hover": {
            backgroundColor: `${main_color}CA !important`
        }
    },
    itinerarySticky: {
        position: 'sticky',
        top: 10,
        zIndex: 999,
        width: '100%',
        marginBottom: 10,
        marginLeft: 7,
    },
}));

type renderDetailStepProps = {
    byDay: any,
    itinerary: any,
    versionToShow: any,
    cars: any,
    products: any,
    variantToShow: any,
    variantIsChecked: any,
    onToggleVariant: any,
    flights: any,
    itiRef: React.Ref<HTMLInputElement>;
}

const RenderDetailStep = (props: renderDetailStepProps) => {
    const {byDay, itinerary, versionToShow, cars, products, variantToShow, variantIsChecked, onToggleVariant, flights, itiRef} = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    
    const locales = useSelector((state:any) => state.user.locales);
    const type = useSelector((state:any) => state.itinerary_type.type)

    const current_locale = locales.find((el) => {return el.language_code === i18n.language});
    const [printIti, setPrintIti] = useState([]);
    const [transportProduct, setTransportProduct] = useState([]);
    const [step, setStep] = useState<number>(0);
    const printItiRef = useRef(new Array());

    useEffect(() => {
        if (itinerary.length > 0 || cars.length > 0) {
            setAllStepInfo();
        }
    }, [itinerary, cars]);

    useEffect(() => {
        if (printItiRef.current.length > 0 && step !== 0) {
            printItiRef.current[step - 1].scrollIntoView({behavior: "smooth", block: "center", inline: "start"});
        }
    }, [step]);

    const setAllStepInfo = () => {
        let transport_product = [];
        let tmp_iti = itinerary.slice();
        let end_day_before = 0;
        tmp_iti.map((step, index_step) => {
            let days = [];
            let dates = [];
            let transports = [];
            if (step.step_type !== "START") {
                let end = moment.utc(step.end_date, "YYYY-MM-DD");
                let start = moment.utc(step.start_date, "YYYY-MM-DD");
                let nb = Math.abs(moment.duration(start.diff(end)).asDays());
                let last_nb = end_day_before;
                
                if (nb === 0) {                    
                    days.push((last_nb + 1));
                }

                for (let i = 0; i < nb; i++) {
                    let new_day = 0;
                    if (i === 0) {
                        new_day = ((i + 1) + last_nb);
                    } else {
                         new_day = last_nb + 1;
                    }
                    days.push(new_day);
                    last_nb = new_day;
                }
                end_day_before = last_nb;
                let tmp_products = products.slice();
                tmp_products.map((product) => {
                    if (product.product_type === 2 && (product.variant === variantToShow || product.variant === null || product.variant === 0)) {
                        let product_start_date = moment(product.start_date, "YYYY-MM-DD");
                        let product_end_date = moment(product.end_date, "YYYY-MM-DD");
                        if (moment(product_start_date).isSame(moment(start), "d") || moment(product_end_date).isSame(moment(start), "d")) {
                            transports.push(product);
                            if ((transport_product.find((el) => el.id === product.id)) === undefined) {
                                transport_product.push(product);
                            }
                        }
                    }
                    if (product.product_type === 6 && (product.variant === variantToShow || product.variant === null || product.variant === 0)) {
                        if (product.outbounds !== undefined) {
                            product.outbounds.map((outbound) => {
                                if (moment(outbound.legs[0].departure_time).isSame(moment(start), "d")) {
                                    transports.push(product);
                                    if ((transport_product.find((el) => el.id === product.id)) === undefined) {
                                        transport_product.push(product);
                                    }
                                }
                            })
                        } else {
                            if (moment(product.flight_segment[0].departure_time).isSame(moment(start), "d")) {
                                transports.push(product);
                                if ((transport_product.find((el) => el.id === product.id)) === undefined) {
                                    transport_product.push(product);
                                }
                            }
                        }
                    }
                })
            }
            
            for (let i = 0; i < days.length; i++) {
                if (i === 0) {
                    dates.push(moment(step.start_date));
                } else {
                    let date = new Date(step.start_date);
                    // date.setDate(date.getDate() + i);
                    dates.push(moment(date).add(i, 'day'));
                }
            }
            let tmp_transports = transports.slice();
            let copy_transport = [];
            if (tmp_transports.length > 0) {
                tmp_transports.map((transport) => {
                    let tmp_transport = Object.assign({}, transport);
                    dates.map((date, date_index) => {
                        if (moment(transport.start_date).isSame(moment(date), "day")) {
                            tmp_transport.day = days[date_index];
                            tmp_transport.step = "start"
                        }
                        if (moment(transport.end_date).isSame(moment(date), "day")) {
                            tmp_transport.day = days[date_index];
                            tmp_transport.step = "end"
                        }
                    })
                    transport = tmp_transport;
                    copy_transport.push(transport);
                })
            }
            copy_transport = copy_transport.sort((a, b) => {
                return a.product_type - b.product_type;
            });
            step.transports = copy_transport;
            step.days = days;
            step.dates = dates;
        });

        tmp_iti = tmp_iti.filter((el) => el.step_type !== "START");
        setPrintIti(tmp_iti);
        transport_product = transport_product.sort((a, b) => {
            if (a.product_type === b.product_type) {
                if (!a.is_optional) {
                    return -1;
                }
            }
            return a.product_type - b.product_type;
        });
        setTransportProduct(transport_product);
    }
    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    let filterDay = byDay.filter((el) => {return el.trip_version === versionToShow && el.day.length !== 0});
    return (
        <Grid container direction={"column"} spacing={2} style={{marginTop: 20}} id={'itinerary-box-top'}>
            {
                filterDay.length > 1 &&
                <Box className={classes.itinerarySticky}>
                    <Paper style={{borderRadius: 20, padding: 15, marginBottom: 10}}>
                        <Stack>
                            <Grid item ref={itiRef} style={{marginBottom: 30}}>
                                <Fab className={classes.fabColor} size={'small'} disableRipple={true} disableFocusRipple={true}>
                                    <RoomIcon/>
                                </Fab>
                                <span className={`${classes.genericText}`} style={{marginLeft: 10, fontWeight: 'bold'}}>{t<string>('apps_navigation.itinerary')}</span>
                            </Grid>
                            <CircuitStepper filterDay={filterDay.length !== 0 ? filterDay : printIti} setStep={setStep} step={step}/>
                        </Stack>
                    </Paper>
                </Box>
            }
            {
                filterDay.length !== 0 && filterDay.map((day: { day: any[]; localization: unknown; title: unknown; pictures: string | any[]; long_description: unknown; }, day_index:number) => {
                    let days = day.day.sort((a:number,b:number) => a - b);
                    let iti = printIti.find((el) => {
                        return el.step_type !== "START" && el.days.includes(day.day[0]);
                    });
                    let findIndex = printIti.findIndex((el) => {
                        return el.step_type !== "START" && el.days.includes(day.day[0]);
                    });
                    return (
                        <div key={day_index} style={{marginLeft: 7}}>
                            <Grid item style={{width: "100%", marginTop: 20}}>
                                <Grid container direction={"column"} spacing={2}>
                                    {
                                        // type === 'circuit' &&
                                        <Grid item style={{width: "100%"}} ref={(element) => printItiRef.current.push(element)}>
                                            <Grid container direction={'row'} alignItems={'center'}>
                                                <Grid item>                                                    
                                                    <Fab className={classes.fabColor} style={{verticalAlign: 'bottom'}} size={'small'} disableRipple={true} disableFocusRipple={true}>{day_index + 1}</Fab>
                                                    <span className={`${classes.genericText} ${classes.bold}`} style={{paddingLeft: 10, fontSize: 24}}>{ day.day.length > 1 ? capitalize(t("global.from")) + " " + t("global.day1") + " " + days[0] + " " + t("global.to") + " " + t("global.day1") + " " + days[days.length - 1] + " : " : capitalize(t("global.day1")) + " " + days[0] + " : " }</span>
                                                    <span className={`${classes.genericText} ${classes.bold}`} style={{fontSize: 24}} dangerouslySetInnerHTML={ {__html: localeText(current_locale.id, day.localization, day.title , 'title')} }/>
                                                    {/* <hr className={`${classes.genericText} ${classes.hrText}`} data-content={ day.day.length > 1 ? capitalize(t("global.from")) + " " + t("global.day1") + " " + day.day[0] + " " + t("global.to") + " " + t("global.day1") + " " + day.day[day.day.length - 1] : capitalize(t("global.day1")) + " " + day.day[0] }/> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    {/* <Grid item className={classes.textCenter} style={{width: "100%"}}>
                                        <Typography variant={"h5"} className={`${classes.genericText} ${classes.capitalize}`} >
                                            <span dangerouslySetInnerHTML={ {__html: localeText(current_locale.id, day.localization, day.title , 'title')} }/>
                                        </Typography>
                                    </Grid> */}
                                    <Grid item style={{marginBottom: 20, width: "100%"}}>
                                        <Grid container direction={'column'}>
                                        {
                                            day.pictures.length !== 0 && (
                                                <Grid item style={{width: '100%'}}>
                                                    <ByDayPicture pictures={day.pictures} is_step={true}/>
                                                    {/* <img src={day.picture.thumbnail_big !== null ? day.picture.thumbnail_big : day.picture.url} alt={day.title} className={ fullScreen ? classes.mobilePicture :  classes.picture} /> */}
                                                </Grid>
                                            )
                                        }
                                        {
                                            localeText(current_locale.id, day.localization, day.long_description, 'long_description') !== '' && localeText(current_locale.id, day.localization, day.long_description, 'long_description') !== "<p> </p>" && localeText(current_locale.id, day.localization, day.long_description, 'long_description') !== "<p></p>" &&
                                            <Grid item className={classes.border} style={{ width: '100%', marginLeft: 0, borderRadius: day.pictures.length !== 0 ? '0px 0px 20px 20px' : '20px' }}>
                                                <div >
                                                    <span className={`${classes.genericText} ${classes.textJustify}`} dangerouslySetInnerHTML={ {__html: localeText(current_locale.id, day.localization, day.long_description, 'long_description') }}/>
                                                </div>
                                            </Grid>
                                        }
                                        {
                                            (localeText(current_locale.id, day.localization, day.long_description, 'long_description') === '' || localeText(current_locale.id, day.localization, day.long_description, 'long_description') === "<p> </p>") &&localeText(current_locale.id, day.localization, day.short_description, 'short_description') !== '' && localeText(current_locale.id, day.localization, day.short_description, 'short_description') !== "<p> </p>" && localeText(current_locale.id, day.localization, day.short_description, 'short_description') !== "<p></p>" &&
                                            <Grid item className={classes.border} style={{ width: '100%', marginLeft: 0, borderRadius: day.pictures.length !== 0 ? '0px 0px 20px 20px' : '20px' }}>
                                                <div >
                                                    <span className={`${classes.genericText} ${classes.textJustify}`} dangerouslySetInnerHTML={ {__html: localeText(current_locale.id, day.localization, day.short_description, 'short_description') }}/>
                                                </div>
                                            </Grid>
                                        }
                                        </Grid>
                                    </Grid>
                                    {
                                        iti !== undefined && iti.days[0] === day.day[0] && findIndex - 1 !== -1 && (day_index !== filterDay.length - 1) && (
                                            <Grid item>
                                                <RenderTransport iti={printIti[findIndex - 1]} next_iti={iti}/>
                                            </Grid>
                                        )
                                    }
                                    {
                                        iti !== undefined && (
                                            <Fragment>
                                            {
                                                products.map((product, product_index) => {
                                                    if ((product.variant === variantToShow || product.variant === null || product.variant === 0) && (moment(product.start_date).isAfter(moment(iti.start_date), "d") || moment(product.start_date).isSame(moment(iti.start_date), "d")) && (moment(product.end_date).isBefore(moment(iti.end_date), "d") || moment(product.end_date).isSame(moment(iti.end_date), "d"))) {
                                                        if (product.product_type === 0 || product.product_type === 7) {
                                                            if (product.is_manual) {
                                                                return (
                                                                    <Grid item key={product_index} className={classes.border}>
                                                                        <HotelCardManual only_one_hotel={ true } hotel={product} index={product_index} products={products} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant}/>
                                                                    </Grid>
                                                                )

                                                            } else {
                                                                return (
                                                                    <Grid item key={product_index} className={classes.border} style={{marginTop: 10}}>
                                                                        <HotelCard products={products} only_one_hotel={ product.rooms.length === 1 } hotel={product} index={product_index} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant}/>
                                                                    </Grid>
                                                                )
                                                            }
                                                        }
                                                    }
                                                    if ((product.variant === variantToShow || product.variant === null || product.variant === 0) && ((moment.utc(product.start_date).isSame(moment.utc(iti.start_date), "day") && day.day[0] === iti.days[0]) || (moment.utc(product.start_date).isSame(moment.utc(iti.end_date), "day") && day.day[0] === iti.days[iti.days.length - 1] && day_index === filterDay.length - 1 ))) {
                                                        if (product.product_type === 4) {
                                                            if (product.is_manual) {
                                                                return (
                                                                    <Grid item key={product_index} className={classes.border}>
                                                                        <TransfersCardManual transfer={product} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant} products={products}/>
                                                                    </Grid>
                                                                )
                                                            } else {
                                                                return (
                                                                    <Grid item key={product_index} className={classes.border}>
                                                                        <TransfersCard transfer={product} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant} products={products}/>
                                                                    </Grid>
                                                                )
                                                            }
                                                        }
                                                    }
                                                    if ((product.variant === variantToShow || product.variant === null || product.variant === 0) && iti.days.includes(day.day[0])) {
                                                        if (product.product_type === 11 || product.product_type === 12) {
                                                            let findDay = iti.days.findIndex((el) => el === day.day[0]);
                                                            if (moment.utc(product.start_date).isSame(moment.utc(iti.dates[findDay]), "day")) {
                                                                if (product.is_manual) {
                                                                    return (
                                                                        <Grid item key={product_index} className={classes.border}>
                                                                            <ActivitiesCardManual activity={product} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant} products={products}/>
                                                                        </Grid>
                                                                    )
                                                                }
                                                                return (
                                                                    <Grid item key={product_index} className={classes.border}>
                                                                        <ActivitiesCard activity={product} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant} products={products}/>
                                                                    </Grid>
                                                                )
                                                            }
                                                        }
                                                    }
                                                })
                                            }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        iti !== undefined && iti.days[0] === day.day[0] && findIndex - 1 !== -1 && (day_index === filterDay.length - 1) && (
                                            <Grid item>
                                                <RenderTransport iti={printIti[findIndex - 1]} next_iti={iti}/>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    )
                })
            }
            {
                filterDay.length === 0 && printIti.length !== 0 && printIti.map((iti, iti_index) => {
                    if (iti.destination !== null && iti.step_type === 'STEP') {
                        return (
                            <Grid item key={iti_index} style={{width: "100%", marginTop: 20}}>
                                <Grid container direction={"column"} spacing={2}>
                                    {
                                        type === 'circuit' &&
                                        <Grid item style={{width: "100%"}} ref={(element) => printItiRef.current.push(element)}>
                                            <Grid container direction={'row'} alignItems={'center'}>
                                                <Grid item>
                                                    <Fab className={classes.fabColor} style={{verticalAlign: 'bottom'}} size={'small'} disableRipple={true} disableFocusRipple={true}>{iti_index + 1}</Fab>
                                                    <span className={`${classes.genericText} ${classes.bold}`} style={{paddingLeft: 10, fontSize: 24}}>{ iti.days.length > 1 ? capitalize(t("global.from")) + " " + t("global.day1") + " " + iti.days[0] + " " + t("global.to") + " " + t("global.day1") + " " + iti.days[iti.days.length - 1] + " : " : capitalize(t("global.day1")) + " " + iti.days[0] + " : " }</span>
                                                    <span className={`${classes.genericText} ${classes.bold}`} style={{fontSize: 24}} dangerouslySetInnerHTML={ {__html: localeText(current_locale.id, iti.destination.data.localization, iti.destination.data.international_name , 'name')} }/>
                                                </Grid>
                                            </Grid>
                                            {/* <hr className={`${classes.genericText} ${classes.hrText}`} data-content={ iti.days.length > 1 ? capitalize(t("global.from")) + " " + t("global.day1") + " " + iti.days[0] + " " + t("global.to") + " " + t("global.day1") + " " + iti.days[iti.days.length - 1] : capitalize(t("global.day1")) + " " + iti.days[0] }/> */}
                                        </Grid>
                                    }
                                    {/* <Grid item className={classes.textCenter} style={{width: "100%"}}>
                                        <Typography variant={"h5"} className={`${classes.genericText} ${classes.capitalize}`} >
                                            <span dangerouslySetInnerHTML={ {__html: localeText(current_locale.id, iti.destination.data.localization, iti.destination.data.international_name , 'name')} }/>
                                        </Typography>
                                    </Grid> */}
                                    {/* <Grid item style={{marginBottom: 20, width: "100%"}}>
                                        {
                                            day.picture !== null && (
                                                <img src={day.picture.thumbnail_big !== null ? day.picture.thumbnail_big : day.picture.url} alt={day.title} className={ fullScreen ? classes.mobilePicture :  classes.picture} />
                                            )
                                        }
                                        <div className={`${classes.genericText} ${classes.textJustify}`}>
                                            <span dangerouslySetInnerHTML={ {__html: localeText(current_locale.id, day.localization, day.long_description , 'long_description')} }/>
                                        </div>
                                    </Grid> */}
                                    {
                                        // iti !== undefined && iti.days[0] === day.day[0] && findIndex - 1 !== -1 && (day_index !== filterDay.length - 1) && (
                                        //     <Grid item>
                                        //         <RenderTransport iti={printIti[findIndex - 1]} next_iti={iti}/>
                                        //     </Grid>
                                        // )
                                    }
                                    {
                                        iti !== undefined && (
                                            <Grid item style={{width: '100%'}}>
                                            {
                                                products.map((product, product_index) => {
                                                    if ((product.variant === variantToShow || product.variant === null || product.variant === 0) && (moment(product.start_date).isAfter(moment(iti.start_date)) || moment(product.start_date).isSame(moment(iti.start_date), "d")) && (moment(product.end_date).isBefore(moment(iti.end_date)) || moment(product.end_date).isSame(moment(iti.end_date), "d"))) {
                                                        if (product.product_type === 0 || product.product_type === 7) {
                                                            if (product.is_manual) {
                                                                return (
                                                                    <Grid item key={product_index} className={classes.border}>
                                                                        <HotelCardManual products={products} only_one_hotel={ true } hotel={product} index={product_index} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant}/>
                                                                    </Grid>
                                                                )
        
                                                            } else {
                                                                return (
                                                                    <Grid item key={product_index} className={classes.border}>
                                                                        <HotelCard products={products} only_one_hotel={ product.rooms.length === 1 } hotel={product} index={product_index} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant}/>
                                                                    </Grid>
                                                                )
                                                            }
                                                        }
                                                    }
                                                    if ((product.variant === variantToShow || product.variant === null || product.variant === 0) && ((moment.utc(product.start_date).isSame(moment.utc(iti.start_date), "day")) || (moment.utc(product.start_date).isSame(moment.utc(iti.end_date), "day")))) {
                                                        if (product.product_type === 4) {
                                                            if (product.is_manual) {
                                                                return (
                                                                    <Grid item key={product_index} className={classes.border}>
                                                                        <TransfersCardManual transfer={product} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant}  products={products}/>
                                                                    </Grid>
                                                                )
                                                            } else {
                                                                return (
                                                                    <Grid item key={product_index} className={classes.border}>
                                                                        <TransfersCard transfer={product} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant}  products={products}/>
                                                                    </Grid>
                                                                )
                                                            }
                                                        }
                                                    }
                                                    if ((product.variant === variantToShow || product.variant === null || product.variant === 0)) {
                                                        if (product.product_type === 11 || product.product_type === 12) {
                                                            let findDay = iti.dates.findIndex((date) => {
                                                                return moment.utc(product.start_date).isSame(moment.utc(date), "day");
                                                            });
                                                            if (moment.utc(product.start_date).isSame(moment.utc(iti.dates[findDay]), "day")) {
                                                                if (product.is_manual) {
                                                                    return (
                                                                        <Grid item key={product_index} className={classes.border}>
                                                                            <ActivitiesCardManual activity={product} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant} products={products}/>
                                                                        </Grid>
                                                                    )
                                                                }
                                                                return (
                                                                    <Grid item key={product_index} className={classes.border}>
                                                                        <ActivitiesCard activity={product} variantIsChecked={variantIsChecked} onToggleVariant={onToggleVariant} products={products}/>
                                                                    </Grid>
                                                                )
                                                            }
                                                        }
                                                    }
                                                })
                                            }
                                            </Grid>
                                        )
                                    }
                                    {
                                        // iti !== undefined && iti.days[0] === day.day[0] && findIndex - 1 !== -1 && (day_index === filterDay.length - 1) && (
                                        //     <Grid item>
                                        //         <RenderTransport iti={printIti[findIndex - 1]} next_iti={iti}/>
                                        //     </Grid>
                                        // )
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                })
            }
            {
                transportProduct.length > 0 && (
                    <Grid container direction={'column'} spacing={2} style={{marginBottom: 10}}>
                        <Grid item className={`${classes.genericText} ${classes.capitalize} ${classes.textCenter}`}>
                            <Typography variant={"h4"}>{t<string>('itineraryType.your_transport')}</Typography>
                        </Grid>
                        {
                            transportProduct.map((transport, transport_index) => {
                                if ((transport.variant === variantToShow || transport.variant === null || transport.variant === 0) && transport.product_type === 2) {
                                    return (
                                        <Grid item key={transport_index}>
                                            <CarsCard car={transport} onToggleVariant={onToggleVariant} variantIsChecked={variantIsChecked} cars={cars} products={products}/>
                                        </Grid>
                                    )
                                }
                                if ((transport.variant === variantToShow || transport.variant === null || transport.variant === 0) && transport.product_type === 6) {
                                    return (
                                        <Grid item key={transport_index} >
                                            <FlightsCard flight={transport} onToggleVariant={onToggleVariant} variantIsChecked={variantIsChecked} products={products}/>
                                        </Grid>
                                    )
                                }
                            })
                        }
                    </Grid>
                )
            }
        </Grid>
    )
}
export default RenderDetailStep;