import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import DialogActions  from "@material-ui/core/DialogActions";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

import AddIcon from '@material-ui/icons/Add';

import {useSnackbar} from "notistack";
import axios from "axios";

import i18n from "../../../i18n.jsx";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import SetCookie from "../../Common/Functions/SetCookie";
import GetTrip from "../../Base/Functions/GetTrip";
import { SetTripInfo } from "../../../Actions/Menu.js";

const useStyles = makeStyles(theme => ({
    blueButton: {
        backgroundColor: "#2196F3",
        color: "white"
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    paperStepper: {
		position: "absolute !important",
        top: "100% !important",
        // marginLeft: "-232px",
        right: "16px",
        zIndex: "1 !important",
        background: "rgb(255, 255, 255) !important",
        borderRadius: "32px !important",
        boxShadow: "rgb(0 0 0 / 20%) 0px 6px 20px !important",
        marginTop: "12px !important",
        maxHeight: "calc(100vh - 220px) !important",
        overflow: "hidden auto !important",
        padding: "16px 16px !important"
    },
    inputStepper: {
		"& .mui-jss-MuiOutlinedInput-input": {
			padding: 10
		},
		"& .mui-jss-MuiOutlinedInput-root": {
			position: "relative",
			borderRadius: 20
		}
	},
    customSize: {
        width: 41,
        height: 41,
        marginTop: 4
    },
    italic: {
        fontStyle: "italic",
        marginBottom: 8
    }
}));

const AddNewVersion = ({dialog_type, setDialogType}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = useState("");
    const [version_nb, setVersionNb] = useState(1);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    const user = useSelector(store => store.user.user);
    const displayed_version = to_show_version !== null ? to_show_version : GetCookie("trip_id_version");
    const [versionArray, setversionArray] = useState([]);
    const data_trip = useSelector((store) => {
        return store.trip.data_trip;
    });
    const trip_id = useSelector((store) => {
        return store.trip.trip_id;
    });

    useEffect(() => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check && trip_id !== null) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/`,
            }).then(function (response) {
                console.log('data:', response);
                let sort_versions = response.data.results;
                sort_versions.sort((a, b) => {
                    return a.id - b.id
                })
                setversionArray(sort_versions);
                setVersionNb(response.data.count);

            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
                enqueueSnackbar(error, {
                    variant: "error",
                });
            });
        }
    }, [trip_id]);
    const resetCookie = (data_res, id) => {
    	let user_token = GetCookie("token");
    	let id_user = GetCookie("id_user");
    	let trip_token = GetCookie("trip_token");
        let client_user = GetCookie("client_user");

    	//Delete the cookie
    	let cookies = document.cookie.split("; ");
        for (let c = 0; c < cookies.length; c++) {
            let d = window.location.hostname.split(".");
            while (d.length > 0) {
                let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
                let p = location.pathname.split("/");
                document.cookie = cookieBase + "/";
                while (p.length > 0) {
                    document.cookie = cookieBase + p.join("/");
                    p.pop();
                }
                d.shift();
            }
        }
    	SetCookie("trip_id", id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    	SetCookie("trip_id_version", data_res.id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    	SetCookie("trip_token", trip_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    	SetCookie("token", user_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
    	SetCookie("id_user", id_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        SetCookie("client_user", client_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        dispatch({type: "FLUSH_REMOVE_STATE"});
        dispatch({
            type: "HEADER_TOGGLE_AND_SET_LANGUAGE",
            payload: {
                show_language: false,
                language: i18n.language
            }
        });
        GetTrip(user, dispatch, t);
        setDialogType(null);
	};

	const createNewVersion = () => {
		let { pass_check, headers } = CheckBeforeRequest();
		let id = GetCookie("trip_id");
		let version = displayed_version;
		if (pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${version}/copy/`,
            }).then(function (response) {
                console.log('data:', response);
                let request = {
                    name: (value === "" ? null : value)
                };
                version = response.data.trip.current_version;
                axios({
                    method: "PATCH",
                    headers: headers,
                    data: JSON.stringify(request),
                    url: `${API_HREF}client/${window.id_owner}/trip/${id}/versions/${version}/`,
                }).then(function (response1) {
                    enqueueSnackbar(t("header.success_to_create_new_version"), {
                        variant: "success",
                    });
                    resetCookie(response1.data, id);

                    axios({
                        method: "GET",
                        url: `${API_HREF}client/${window.id_owner}/trip/${id}/`,
                        headers: headers,
                    }).then(function(response){
                        dispatch(SetTripInfo(response.data));
                    }).catch(function(error){
                        console.log(error.response);
                    })
                    
                }).catch(function (error) {
                    //TODO: show snackbar error
                    console.log('error:', error);
                    enqueueSnackbar(t("header.failed_to_create_new_version"), {
                        variant: "error",
                    });
                });
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
                enqueueSnackbar(t("header.failed_to_create_new_version"), {
                    variant: "error",
                });
            });
		}
	};

    const cancel = () => {
        setValue("");
        setDialogType(null);
    };

    return (
        <Fragment>
            <DialogTitle>{t("header.save_version_nb") + (version_nb + 1)}</DialogTitle>
            <DialogContent>
                <div className={classes.italic}>{t("header.custom_version_name")}</div>
                <TextField
                    variant={"outlined"}
                    fullWidth
                    autoFocus={dialog_type === "addNewVersion"}
                    placeholder={t("header.version_name")}
                    className={classes.inputStepper}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                />
                {
                    versionArray.map((version_obj, version_index) => {
                        let version_new_index = version_index + 1;
                        if (version_obj.id === parseInt(displayed_version)){
                            return (
                                <div key={`version-${version_obj.id}`} style={{ paddingTop: 12 }}>
                                    { t("header.version_copied") + " : "}
                                    { t("menu.trip_list.nb_version") + version_new_index}
                                    { version_obj.name !== null && (" (" + version_obj.name + ")")}
                                </div>
                            )
                        }
                    })
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel}>{t("accommodation.cancel")}</Button>
                <Button
                    variant={"contained"}
                    className={ classes.orangeButton }
                    onClick={createNewVersion}
                >
                    {t("global.validate")}
                </Button>
            </DialogActions>
        </Fragment>
    )
}
export default React.memo(AddNewVersion);