import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Slide from '@material-ui/core/Slide';

import AvailabilityCard from "./AvailabilityCard";

const useStyles = makeStyles(theme => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A",
        letterSpacing: 1.25
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize12: {
        fontSize: 12
    },
    fontWeight500:{
        fontWeight: 500
    },
    upperCase:{
        textTransform: "uppercase",
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MoreAvailabilityDates = ({ openModal, setOpenModal, scheduled_prices, selectActivity, poi_type }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(768));
    return (
        <Dialog open={openModal} fullScreen={ mobile === true ? true : false} TransitionComponent={Transition} fullWidth maxWidth={"xs"}>
            <DialogTitle>
                <Grid container justify={ mobile === true ? "flex-start" : "space-between" } alignItems={ "center" }>
                    {
                        mobile && (
                            <Grid item style={{marginRight: "5%"}}>
                                <IconButton onClick={() => { setOpenModal(false) }} size='small'> <NavigateBeforeIcon /> </IconButton>
                            </Grid>
                        )
                    }
                    <Grid item className={`${classes.genericText} ${classes.upperCase} ${mobile ? classes.fontSize12 : classes.fontSize16} ${classes.fontWeight500}`}>
                        { t("poi.pick_time") }
                    </Grid>
                    {
                        !mobile && (
                            <Grid item>
                                <IconButton onClick={() => { setOpenModal(false) }} size='small'> <CloseIcon style={{float: "right"}}/> </IconButton>
                            </Grid>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid>
                    {
                        scheduled_prices.map((day_prices) => {
                            return(
                                <AvailabilityCard key={`${day_prices.date}-${day_prices.hour.length !== 0 ? day_prices.hour[0] : ""}`} day_prices={day_prices} selectActivity={selectActivity} poi_type={poi_type}/>
                            )
                        })
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default React.memo(MoreAvailabilityDates)