import axios from "axios";
import memoizee from "memoizee";
import { getDestination } from "../../../Itinerary/utils/getDestination";
import { Roadbook } from "../objects/roadbook";

async function fetchAll(destinationId: number): Promise<Roadbook | null> {
    let result: Roadbook | null = null;
    let destination = await getDestination(destinationId);
    while (destination && !result) {
        result = await fetch(destination.id);
        if (!result) {
            if (destination.parent?.id) {
                destination = await getDestination(destination.parent.id);
            } else {
                destination = null;
            }
        }
    }
    return result;
}

async function fetch(destinationId: number): Promise<Roadbook | null> {
    try {
        const response = await axios.get<{results: Roadbook[]}>(
            `${API_HREF}client/${window.id_owner}/roadbook/`,
            {
                params: {
                    destination: destinationId
                }
            }
        );
        return response.data.results.find((item) => {
            return item.destination.id === destinationId;
        }) ?? null;
    } catch (error: any) {
        console.error(error);
    }
    return null;
}

export const findRoadbook = memoizee(
    fetchAll,
    { promise: true, primitive: true }
);
