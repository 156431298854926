import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";
import { isEqual } from "lodash";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";

type Props = {
    version: number,
    index: number,
    input: ItineraryContentStep,
    inputs: ItineraryContentStep[],
    onChangeInputs: React.Dispatch<React.SetStateAction<{
        [version: number]: ItineraryContentStep[];
    }>>,
}

export function CartConstructionItineraryContentCopyModalStepsListAggregator(props: Props): JSX.Element {
    const { t } = useTranslation();

    const onGroupSteps = (version: number, first: ItineraryContentStep | undefined, second: ItineraryContentStep | undefined) => {
        if (first && second) {
            props.onChangeInputs((state) => ({
                ...state,
                [version]: (
                    state[version]?.filter((item) => {
                        if (
                            first.mode === 'by-day' &&
                            second.mode === 'by-day' &&
                            item.mode === 'by-day'
                        ) {
                            return !isEqual(first.content.day, item.content.day) &&
                                   !isEqual(second.content.day, item.content.day);
                        }
                    }) ?? []
                ).concat(
                    [
                        first.mode === 'by-day' && second.mode === 'by-day' ?
                            {
                                ...first,
                                content: {
                                    ...first.content,
                                    day: [...first.content.day.concat(second.content.day)].sort()
                                }
                            } :
                            null
                    ].filter((item): item is NonNullable<typeof item> => !!item)
                ).sort((a, b) => {
                    if (a.mode === 'by-day' && b.mode === 'by-day') {
                        return (a.content.day[0] ?? 0) - (b.content.day[0] ?? 0);
                    }
                    return -1;
                })
            }));
        }
    };

    const currentDestinations = (
        props.input.mode === 'by-day' ?
            props.input.content.destinations?.map((item) => item.id) :
            [props.input.content.destination?.id ?? -1]
    ) ?? [];
    const nextInput = props.inputs[props.index + 1];
    const nextDestinations = (
        nextInput?.mode === 'by-day' ?
            nextInput.content.destinations?.map((item) => item.id) :
            [nextInput?.content.destination?.id ?? -1]
    ) ?? [];
    const disabled = !isEqual(
        currentDestinations.sort((a, b) => a - b),
        nextDestinations.sort((a, b) => a - b)
    );

    return (
        <Tooltip
            title={
                !disabled ?
                    t<string>('shared.circuit-steps-regroup') :
                    t<string>('shared.circuit-steps-regroup-disabled-hint')
            }
        >
            <span>
                <IconButton
                    size="small"
                    onClick={() => onGroupSteps(props.version, props.input, props.inputs[props.index + 1])}
                    disabled={disabled}
                >
                    <AddOutlinedIcon />
                </IconButton>
            </span>
        </Tooltip>
    );
}
