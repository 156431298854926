import { createItineraryPageMapUrl } from "./createItineraryPageMapUrl";
import { CreatePageOptions } from "../objects/createPageOptions";
import { Template } from "../utils/handlebars/roadbook.handlebars";

export function createItineraryContent(options: CreatePageOptions): Parameters<Template>[0]['contents'][0] {
    return {
        type: 'itinerary',
        anchor: 'itinerary',
        showInSummary: true,
        summaryTitle: options.title,
        title: options.title,
        url: createItineraryPageMapUrl(options.itinerary)
    };
}
