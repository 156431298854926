import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Grid, InputAdornment, TextField } from "@mui/material";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";
export type MailTemplateVisualEditorPaddingOptions = {
    top: number,
    bottom: number,
    left: number
    right: number
}

type Props = {
    title?: string,
    padding?: MailTemplateVisualEditorPaddingOptions,
    onChangePadding: (
        type: keyof MailTemplateVisualEditorPaddingOptions,
        value: number
    ) => void
}

//eslint-disable-next-line no-redeclare
export function MailTemplateVisualEditorPaddingOptions(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <MailTemplateVisualEditorOptionsSection>
            <Typography>
                {
                    props.title ?? t<string>('shared.mail-template-visual-editor-padding')
                }
            </Typography>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <TextField
                        type="number"
                        variant="outlined"
                        label={t<string>('shared.mail-template-visual-editor-top')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">px</InputAdornment>
                        }}
                        value={props.padding?.top ?? ''}
                        onChange={(event) => {
                            props.onChangePadding('top', parseInt(event.target.value));
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        type="number"
                        variant="outlined"
                        label={t<string>('shared.mail-template-visual-editor-bottom')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">px</InputAdornment>
                        }}
                        value={props.padding?.bottom ?? ''}
                        onChange={(event) => {
                            props.onChangePadding('bottom', parseInt(event.target.value));
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        type="number"
                        variant="outlined"
                        label={t<string>('shared.mail-template-visual-editor-left')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">px</InputAdornment>
                        }}
                        value={props.padding?.left ?? ''}
                        onChange={(event) => {
                            props.onChangePadding('left', parseInt(event.target.value));
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        type="number"
                        variant="outlined"
                        label={t<string>('shared.mail-template-visual-editor-right')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">px</InputAdornment>
                        }}
                        value={props.padding?.right ?? ''}
                        onChange={(event) => {
                            props.onChangePadding('right', parseInt(event.target.value));
                        }}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </MailTemplateVisualEditorOptionsSection>
    );
}
