//---- Dependencies ----//
import React, { Fragment } from "react";
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles({
    destinationArrow: {
        verticalAlign: "middle"
    },
    listPadding: {
        paddingLeft: 0
    },
    mt15: {
        marginTop: 15
    },
    mb15: {
        marginBottom: 15
    }
});

const ReloadCartFlight = ({ ranked }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const update_infos = useSelector(state => state.cart.update_infos);
    const onToggleRecalc = (key) => () => {
        dispatch({type: "RECALC_TOGGLE_FLIGHT_RECALC", payload: {key: key}});
    };
    return (
        <Fragment>
            <h5 className={ classes.mt15 }>{ ranked }) { t('itinerary.cart_reload_flights') }</h5>
            <Table className={ classes.mb15 }>
                <TableHead>
                    <TableRow>
                        <TableCell>{ t("accommodation.end_date") }</TableCell>
                        <TableCell>{ t("recalc.new_flight_trip") }</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.keys(update_infos.flight).map((key) => (
                            <TableRow key={ `reacalc-cart-${key}` }>
                                <TableCell component="th" scope="row">
                                    <List>
                                        {
                                            Object.keys(update_infos.flight[key].segment_data).map((segment_index) => {
                                                let segment = update_infos.flight[key].segment_data[segment_index];
                                                return (
                                                    <ListItem key={ `segment-date-${segment_index}` } className={ classes.listPadding }>
                                                        <ListItemText
                                                            primary={
                                                                <Fragment>
                                                                    { moment(segment.departure).format("DD/MM/YYYY") }
                                                                </Fragment>
                                                            }
                                                        />
                                                    </ListItem>
                                                );
                                            })
                                        }
                                    </List>
                                </TableCell>
                                <TableCell>
                                    <List>
                                        {
                                            Object.keys(update_infos.flight[key].segment_data).map((segment_index) => {
                                                let segment = update_infos.flight[key].segment_data[segment_index];
                                                return (
                                                    <ListItem key={ `sergment-name-${segment_index}` } className={ classes.listPadding }>
                                                        <ListItemText
                                                            primary={
                                                                <Fragment>
                                                                    { segment.origin_info.international_name }
                                                                    <ArrowRightAltIcon className={ classes.destinationArrow }/>
                                                                    { segment.destination_info.international_name }
                                                                </Fragment>
                                                            }
                                                        />
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                </TableCell>
                                <TableCell>
                                    <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>{ t("global.no") }</Grid>
                                        <Grid item>
                                            <Switch checked={ update_infos.flight[key].recalc } onChange={ onToggleRecalc(key) } color={ "primary" }/>
                                        </Grid>
                                        <Grid item>{ t("global.yes") }</Grid>
                                    </Grid>
                                    {
                                        /*
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={ update_infos.flight[key].recalc }
                                                color={ "primary" }
                                                onChange={ onToggleRecalc(key) }
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        }
                                        label={t("global.yes")}
                                    />
                                         */
                                    }
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            <br/>
        </Fragment>
    );
};

export default ReloadCartFlight;