import React, { Fragment } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
	matrixTitle: {
		// color: 'rgba(0, 0, 0, 0.54)',
		// textAlign: 'center',
		// fontWeight: 'bold',
        // background: '#e3eefa',
        marginTop: '10px'
	},
	pointer: {
		cursor: "pointer"
	},
	absoluteCenter: {
		// margin: '0',
		// position: 'absolute',
		// top: '25%',
		// left: '50%',
		// transform: 'translate(-50%, -50%)'
	},
	height35: {
		minHeight: '35px !important'
	},
	height52: {
		height: '52px !important'
	},
	ellipsis: {
	    overflow: 'hidden',
	    textOverflow: 'ellipsis',
	    display: '-webkit-box',
	    '-webkit-line-clamp': 2,
	    '-webkit-box-orient': 'vertical'
	},
	ellipsisMobile: {
		display: "inlineBlock"
	}
}));

const RenderContractService = ({car_contract, contract_info}) => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('sm'));
	const downDesktop = useMediaQuery('(min-width:960px)');
	const currency_list = useSelector((store) => {
		return store.base.currency_list;
	});
	const language = useSelector((store) => {
		return store.header.tmp_language;
	});
	if (car_contract !== null) {
		return (
			<GridList cellHeight={'auto'} cols={1}>
			{
				matches && (
					<GridListTile className={ `${classes.height52}` }>
			  			<ListSubheader component={"div"}>
			  	    	  	<Paper elevation={0} className="cars-category-container text-center">
				  	    	  	<div>
				  	    	  	</div>
			  	    	  	</Paper>
			  	    	</ListSubheader>
					</GridListTile>
				)
			}
			{
				car_contract.services.map((service, index) => {
					return (
						<GridListTile key={index} className={ `${classes.height35}` }>
							<Paper elevation={0} className={ `${classes.absoluteCenter} ${classes.pointer}` }>
								<Tooltip title={service.description} placement={ "bottom" }>
									<span className={ `${ downDesktop ? classes.ellipsis : classes.ellipsisMobile}` }>{service.title}</span>
								</Tooltip>
								{
									!downDesktop && car_contract.selected !== undefined && (
										<span>
										{
											service.contracts.includes(car_contract.selected) ? <CheckIcon style={{ color: green[500], position: "absolute", right: 0 }}/> : <ClearIcon style={{ color: red[500], position: "absolute", right: 0 }}/>
										}
										</span>
									)
								}
							</Paper>
						</GridListTile>
					)
				})
			}
			</GridList>
		)
	}
	else {
		let arr = [];
		for (let i = 0; i < 8; i++) {
			arr.push(
				<GridListTile key={i}>
					<Paper elevation={0} className={ `${matches ? classes.matrixTitle: ""}` }>
						<Skeleton height={40} {...(matches ? {width:"150px"} : {})} animation={"wave"}/>
					</Paper>
				</GridListTile>
			)
		}
		return (
			<GridList cellHeight={40} cols={1}>
				<GridListTile>
		  			<ListSubheader component={"div"}>
		  	    	  	<Paper elevation={0} className="cars-category-container text-center">
			  	    	  	<div>
			  	    	  	</div>
		  	    	  	</Paper>
		  	    	</ListSubheader>
				</GridListTile>
				{arr}
			</GridList>
		)
	}
}
export default React.memo(RenderContractService);