import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { WithRouterProps } from "react-router";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import GetTrip from "../Base/Functions/GetTrip";
import {
    Box,
    Button,
    Grid,
    InputAdornment,
    Paper,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
    styled
} from "@mui/material";
import {
    Add,
    ArrowRightAlt,
    Dashboard,
    Functions,
    LinkOutlined
} from "@mui/icons-material";
import { findLast, keyBy, mapValues } from "lodash";
import { ProviderQuotationNavbar } from "./ProviderQuotationNavbar";
import { ProviderQuotationProduct } from "./ProviderQuotationProduct";
import { ProviderQuotationCurrencyPicker } from "./ProviderQuotationCurrencyPicker";
import { ProviderQuotationProductStatus } from "./objects/providerQuotationProductStatus";
import { ProviderQuotationConfirmationModal } from "./ProviderQuotationConfirmationModal";
import { CartConstructionContents } from "../CartMaterial/CartConstructionContents";
import { CartConstructionCommonViewSwitchButton } from "../CartMaterial/CartConstructionCommonViewSwitchButton";
import { CartConstructionItineraryContentStepper } from "../CartMaterial/CartConstructionItineraryContentStepper";
import {
    MailTemplateVisualEditorOptionsArea
} from "../Menu/MaterialTripList/MailVisualEditor/mailTemplateVisualEditorOptionsArea";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import CartManualProductForm from "../CartMaterial/CartManualProductForm";
import { useProviderQuotationProducts } from "./utils/providerQuotationProducts";
import { useProductCurrencyChange } from "./network/productCurrencyChange";
import { useShowError } from "../Utils/showError";
import { useItineraryContentFetch } from "../CartMaterial/network/itineraryContentFetch";
import { reverseProviderQuotationStatus } from "./utils/getProviderQuotationProductStatus";
import CheckResponse from "../Flight/FlightSelected/Functions/CheckResponse";
import { CartConstructionContentsContext } from "../CartMaterial/utils/cartConstructionContentsContext";
import { setModule, setStep } from "../CartMaterial/redux/cartConstructionReducer";
import { ProviderQuotationContext } from "./utils/providerQuotationContext";
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";
import { AppState } from "../../Reducers/Reducers";

type Props = WithRouterProps

enum QuotationTab {
    PRODUCTS,
    TEXTS
}

export default function ProviderQuotation(props: Props): JSX.Element {
    const providerId = props.params.providerId;
    const tripId = props.params.tripId;
    const tripVersion = props.params.tripVersion;
    const tripToken = props.params.tripToken;
    const version = tripVersion ? parseInt(tripVersion) : -1;
    const stackNumber = parseInt(props.params.stackNumber ?? '-1');
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const travelers = useSelector((state: AppState) => state.trip.travelers);
    const trip = useSelector((state: AppState) => state.trip.all_data);
    const dataTrip = useSelector((state: AppState) => state.trip.data_trip);
    const tripData = useSelector((state: AppState) => state.trip.data_trip);
    const currencies = useSelector((state: AppState) => state.trip.currencies);
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const contentLoading = useSelector((state: AppState) => state.cartConstruction.loading);
    const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
    const view = useSelector((state: AppState) => state.cartConstruction.view);
    const module = useSelector((state: AppState) => state.cartConstruction.module);
    const itineraryContent = useSelector((state: AppState) => {
        return version ?
            state.itinerarySlice.content[version] :
            undefined;
    });
    const step = useSelector((state: AppState) => state.cartConstruction.step);
    const manual_product_form = useSelector((state: AppState) => state.cart.manual_product_form);
    const user = useSelector((state: AppState) => state.user.user);
    const [terrestrialPrice, setTerrestrialPrice] = useState<{
        amount: number,
        currency: number | null
    }>({ amount: 0, currency: 47 });
    const [flightPrice, setFlightPrice] = useState<{
        amount: number,
        currency: number | null
    }>({ amount: 0, currency: 47 });
    const [totalPrice, setTotalPrice] = useState<{
        amount: number,
        currency: number | null
    }>({ amount: 0, currency: 47 });
    const [statuses, setStatuses] = useState<{
        [key: `${string}-${number}`]: {
            id: number,
            isCustom: boolean,
            type: typeof products[number]['type'],
            status: ProviderQuotationProductStatus | null,
            providerComment: string | null
        }
    }>({});
    const [loading, setLoading] = useState(false);
    const [productsCurrency, setProductsCurrency] = useState<number | null>(null);
    const [counterOfferProduct, setCounterOfferProduct] = useState<number | null>(null);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [openCreateProductModal, setOpenCreateProductModal] = useState(false);
    const [openNewPropositionModal, setOpenNewPropositionModal] = useState(false);
    const [tab, setTab] = useState(QuotationTab.PRODUCTS);
    const terrestrialPriceCurrency = useMemo(() => {
        return currencies?.find((item) => {
            return item.id === terrestrialPrice.currency;
        });
    }, [currencies, terrestrialPrice]);
    const flightPriceCurrency = useMemo(() => {
        return currencies?.find((item) => {
            return item.id === flightPrice.currency;
        });
    }, [currencies, flightPrice]);
    const totalPriceCurrency = useMemo(() => {
        return currencies?.find((item) => {
            return item.id === totalPrice.currency;
        });
    }, [currencies, totalPrice]);
    const showError = useShowError();
    const updateProductCurrency = useProductCurrencyChange({
        onSuccess(product, data) {
            if (dataTrip) {
                switch (product.type) {
                    case 'flights': {
                        dispatch({
                            type: 'FLIGHT_EDIT_CART_BY_ID',
                            payload: CheckResponse([data], dataTrip.end_date)[0]!
                        });
                        break;
                    }
                    case 'cars': {
                        dispatch({
                            type: 'CAR_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    case 'accommodations': {
                        dispatch({
                            type: 'ACCOMMODATION_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    case 'transfers': {
                        dispatch({
                            type: 'TRANSFER_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    case 'pois': {
                        dispatch({
                            type: 'POI_EDIT_CART_BY_ID',
                            payload: data
                        });
                        break;
                    }
                    default: {
                        dispatch({
                            type: 'CART_EDIT_MANUAL_ITEM',
                            payload: data
                        });
                        break;
                    }
                }
            }
        },
        onError(error) {
            console.error(error);
            showError(error);
        }
    });
    const products = useProviderQuotationProducts(stackNumber);
    const counterOffersProductIds = products.map((item) => {
        return item.counterOfferOfId;
    }).filter((item): item is NonNullable<typeof item> => !!item);
    const productIdsWithCounterOffers = products.filter((item) => {
        return counterOffersProductIds.includes(item.id);
    }).map((item) => item.id);
    const provider = providers.find((item) => {
        return item.provider.id === parseInt(providerId ?? '-1');
    }) ?? manualProviders.find((item) => {
        return item.provider.id === parseInt(providerId ?? '-1');
    });
    const hasFlight = products.findIndex((item) => {
        return item.type === 'flights';
    }) >= 0;
    const context = {
        tripId: tripId ? parseInt(tripId) : null,
        version: tripVersion ? parseInt(tripVersion) : null,
        providerId: providerId ? parseInt(providerId) : null,
        tripToken: tripToken ?? null
    };

    const onChangeProductsCurrency = async (currency: number | null) => {
        setProductsCurrency(currency);
        if (currency && context.tripId && context.version && currency) {
            setLoading(true);
            for (const product of products) {
                if (product.masterPriceId) {
                    await updateProductCurrency(
                        {
                            tripId: context.tripId,
                            version: context.version
                        },
                        {
                            id: product.id,
                            type: product.type,
                            isCustom: product.isCustom,
                            price: product.price,
                            currency,
                            masterPriceId: product.masterPriceId,
                            customRate: product.customRate,
                            customRateType: product.customRateType,
                            customValue: product.customValue
                        }
                    );
                }
            }
            setLoading(false);
        }
    };

    const onChangeStatus = (
        key: keyof typeof statuses,
        status: ProviderQuotationProductStatus | null,
        providerComment: string | null
    ) => {
        setStatuses((state) => ({
            ...state,
            [key]: {
                ...state[key],
                status,
                providerComment
            }
        }));
    };

    const onChangeCurrency = (type: 'terrestrial' | 'flight' | 'total', currency: number | null) => {
        switch (type) {
            case 'terrestrial': {
                setTerrestrialPrice((state) => ({
                    ...state,
                    currency
                }));
                break;
            }
            case 'flight': {
                setFlightPrice((state) => ({
                    ...state,
                    currency
                }));
                break;
            }
            case 'total': {
                setTotalPrice((state) => ({
                    ...state,
                    currency
                }));
                break;
            }
        }
    };

    const onChangeAmount = (type: 'terrestrial' | 'flight' | 'total', amount: number) => {
        switch (type) {
            case 'terrestrial': {
                setTerrestrialPrice((state) => ({
                    ...state,
                    amount: isNaN(amount) ? 0 : amount
                }));
                break;
            }
            case 'flight': {
                setFlightPrice((state) => ({
                    ...state,
                    amount: isNaN(amount) ? 0 : amount
                }));
                break;
            }
            case 'total': {
                setTotalPrice((state) => ({
                    ...state,
                    amount: isNaN(amount) ? 0 : amount
                }));
                break;
            }
        }
    };

    const onComputeTotalPrice = () => {
        const total = products.reduce((prev, current) => {
            return prev + current.price;
        }, 0);
        setTotalPrice({
            amount: Math.round(total * 100) / 100,
            currency: products[0]?.currency?.id ?? null
        });
    };

    const onOpenCreateProductForm = () => {
        setOpenCreateProductModal(true);
        dispatch({ type: 'CART_CREATE_NEW_MANUAL_PRODUCT' });
    };

    useEffect(() => {
        if (providerId && tripId && tripVersion && tripToken && user) {
            GetTrip(
                user,
                dispatch,
                undefined,
                undefined,
                {
                    tripId,
                    tripVersion,
                    tripToken
                }
            );
        }
    }, [
        providerId,
        tripId,
        tripToken,
        tripVersion
    ]);

    useEffect(() => {
        setStatuses(
            mapValues(
                keyBy(
                    products,
                    (item) => `${item.type}-${item.id}`
                ),
                (item) => ({
                    id: item.id,
                    isCustom: item.isCustom,
                    type: item.type,
                    status: item.status,
                    providerComment: item.providerComment
                })
            )
        );
    }, [products]);

    useEffect(() => {
        if (tripData) {
            const terrestrialPrice = findLast(
                tripData.prices_terrestrial?.filter((item) => {
                    return item.stack_number === stackNumber;
                }) ?? [],
                (item) => item.master_price
            );
            const terrestrialPriceAmount = parseFloat(terrestrialPrice?.purchase_price ?? '0');
            setTerrestrialPrice({
                amount: isNaN(terrestrialPriceAmount) ? 0 : terrestrialPriceAmount,
                currency: terrestrialPrice?.purchase_currency ?? 47
            });
            const flightPrice = findLast(
                tripData.prices_flight?.filter((item) => {
                    return item.stack_number === stackNumber;
                }) ?? [],
                (item) => item.master_price
            );
            const flightPriceAmount = parseFloat(flightPrice?.purchase_price ?? '0');
            setFlightPrice({
                amount: isNaN(flightPriceAmount) ? 0 : flightPriceAmount,
                currency: flightPrice?.purchase_currency ?? 47
            });
            const totalPrice = findLast(
                tripData.prices?.filter((item) => {
                    return item.stack_number === stackNumber;
                }) ?? [],
                (item) => item.master_price
            );
            const totalPriceAmount = parseFloat(totalPrice?.purchase_price ?? '0');
            setTotalPrice({
                amount: isNaN(totalPriceAmount) ? 0 : totalPriceAmount,
                currency: totalPrice?.purchase_currency ?? 47
            });
        }
    }, [tripData, stackNumber]);

    useEffect(() => {
        if (tab === QuotationTab.TEXTS) {
            dispatch(setModule('texts'));
        }
    }, [tab]);

    useEffect(() => {
        if (!manual_product_form) {
            setOpenCreateProductModal(false);
            setOpenNewPropositionModal(false);
        }
    }, [manual_product_form]);

    useItineraryContentFetch(version ?? -1);

    return (
        <ProviderQuotationContext.Provider value={context}>
            <Page>
                <ProviderQuotationNavbar {...props} />
                {
                    trip?.provider_allowed_to_edit_byday &&
                    <Tabs
                        value={tab}
                        onChange={(_, value) => setTab(value)}
                        centered
                    >
                        <Tab label={t('cart-material.provider-quotation-tab-products')} />
                        <Tab label={t('cart-material.provider-quotation-tab-texts')} />
                    </Tabs>
                }
                {
                    tab === QuotationTab.PRODUCTS &&
                    <Box sx={{ padding: 2.5 }}>
                        <Typography
                            fontWeight={100}
                            textAlign="center"
                            maxWidth="50%"
                            sx={{ margin: 'auto' }}
                        >
                            <div>
                                <Trans
                                    i18nKey="cart-material.provider-quotation-title"
                                    values={{
                                        name: trip?.user_to ?
                                            [
                                                trip.user_to.first_name,
                                                trip.user_to.last_name
                                            ].filter((item) => item).join(' ') :
                                            trip?.owner.name
                                    }}
                                    components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                                />
                            </div>
                            <div>
                                <Trans
                                    i18nKey="cart-material.provider-quotation-hint"
                                    components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                                />
                            </div>
                        </Typography>
                        <Grid container spacing={5} sx={{ marginTop: 2.5 }}>
                            <Grid item xs={6}>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={1}
                                    sx={{ marginBottom: 1.5 }}
                                >
                                    <Dashboard color="primary" />
                                    <Typography variant="h6" color="primary" component="div">
                                        {t('cart-material.cart-construction-package')}
                                    </Typography>
                                </Stack>
                                <Stack
                                    spacing={2.5}
                                    sx={(theme) => ({
                                        padding: 3,
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        borderRadius: theme.shape.borderRadius,
                                        border: '1px solid #1976D2'
                                    })}
                                >
                                    <ProviderQuotationCurrencyPicker
                                        value={productsCurrency}
                                        onChange={onChangeProductsCurrency}
                                    />
                                    {
                                        products.map((product) => {
                                            if (product.counterOfferOf) {
                                                return (
                                                    <Stack
                                                        key={`${product.type}-${product.id}`}
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <ProviderQuotationProduct
                                                            {...product}
                                                            status={statuses[`${product.type}-${product.id}`]?.status ?? null}
                                                            providerComment={statuses[`${product.type}-${product.id}`]?.providerComment ?? null}
                                                            onOpenManualProductForm={() => setOpenNewPropositionModal(true)}
                                                            onChangeStatus={(status, comment) => onChangeStatus(
                                                                `${product.type}-${product.id}`,
                                                                status,
                                                                comment
                                                            )}
                                                            onChangeCounterOfferProduct={setCounterOfferProduct}
                                                        />
                                                        <LinkOutlined sx={{ transform: 'rotateZ(90deg) translateY(-100%)' }} />
                                                        <ProviderQuotationProduct
                                                            {...product.counterOfferOf}
                                                            counterOfferOf={null}
                                                            status={statuses[`${product.counterOfferOf.type}-${product.counterOfferOf.id}`]?.status ?? null}
                                                            providerComment={statuses[`${product.counterOfferOf.type}-${product.counterOfferOf.id}`]?.providerComment ?? null}
                                                            onOpenManualProductForm={() => setOpenNewPropositionModal(true)}
                                                            onChangeStatus={(status, comment) => onChangeStatus(
                                                                `${product.counterOfferOf!.type}-${product.counterOfferOf!.id}`,
                                                                status,
                                                                comment
                                                            )}
                                                            onChangeCounterOfferProduct={setCounterOfferProduct}
                                                        />
                                                    </Stack>
                                                );
                                            }

                                            if (!productIdsWithCounterOffers.includes(product.id)) {
                                                return (
                                                    <ProviderQuotationProduct
                                                        key={`${product.type}-${product.id}`}
                                                        {...product}
                                                        status={statuses[`${product.type}-${product.id}`]?.status ?? null}
                                                        providerComment={statuses[`${product.type}-${product.id}`]?.providerComment ?? null}
                                                        onOpenManualProductForm={() => setOpenNewPropositionModal(true)}
                                                        onChangeStatus={(status, comment) => onChangeStatus(
                                                            `${product.type}-${product.id}`,
                                                            status,
                                                            comment
                                                        )}
                                                        onChangeCounterOfferProduct={setCounterOfferProduct}
                                                    />
                                                );
                                            }
                                        })
                                    }
                                    <Button
                                        startIcon={<Add />}
                                        variant="outlined"
                                        onClick={onOpenCreateProductForm}
                                    >
                                        {t('shared.add')}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper
                                    elevation={1}
                                    sx={(theme) => ({
                                        paddingTop: 2.5,
                                        paddingBottom: 3.5,
                                        paddingLeft: 3.5,
                                        paddingRight: 3.5,
                                        borderRadius: theme.shape.borderRadius,
                                        marginBottom: 2.5
                                    })}
                                >
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        component="div"
                                        sx={{ marginBottom: 1 }}
                                    >
                                        {t('cart-material.provider-quotation-travelers')}
                                    </Typography>
                                    {
                                        travelers?.map((traveler, index) => {
                                            const birthDay = window.moment.utc(traveler.birth_date, 'YYYY-MM-DD');
                                            const name = [
                                                traveler.first_name,
                                                traveler.last_name
                                            ].filter((item): item is NonNullable<typeof item> => {
                                                return !!item;
                                            }).join(' ');
                                            return (
                                                <Typography key={index}>
                                                    {
                                                        t(
                                                            'cart-material.provider-quotation-traveler',
                                                            {
                                                                title: traveler.title,
                                                                name,
                                                                birthDay: birthDay.format('L'),
                                                                age: window.moment.utc().diff(birthDay, 'years')
                                                            }
                                                        )
                                                    }
                                                </Typography>
                                            );
                                        })
                                    }
                                </Paper>
                                <Box
                                    sx={{
                                        position: 'sticky',
                                        top: '36px'
                                    }}
                                >
                                    <Paper
                                        elevation={1}
                                        sx={(theme) => ({
                                            paddingTop: 2.5,
                                            paddingBottom: 3.5,
                                            paddingLeft: 3.5,
                                            paddingRight: 3.5,
                                            borderRadius: theme.shape.borderRadius
                                        })}
                                    >
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            component="div"
                                            sx={{ marginBottom: 2.5 }}
                                        >
                                            {t('cart-material.provider-quotation-package-price')}
                                        </Typography>
                                        <Typography fontWeight="bold" sx={{ marginBottom: 2 }}>
                                            {t('cart-material.provider-quotation-package-price-group-title')}
                                        </Typography>
                                        <Stack direction="row" spacing={1} sx={{ marginBottom: 2 }}>
                                            <TextField
                                                type="number"
                                                size="small"
                                                label={t('cart-material.provider-quotation-terrestrial-selling-price')}
                                                value={terrestrialPrice.amount}
                                                onChange={(event) => onChangeAmount('terrestrial', parseFloat(event.target.value))}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {terrestrialPriceCurrency?.symbol}
                                                        </InputAdornment>
                                                    )
                                                }}
                                                fullWidth
                                            />
                                            <ProviderQuotationCurrencyPicker
                                                value={terrestrialPrice.currency}
                                                onChange={(currency) => onChangeCurrency('terrestrial', currency)}
                                            />
                                        </Stack>
                                        {
                                            hasFlight &&
                                            <Stack direction="row" spacing={1} sx={{ marginBottom: 2 }}>
                                                <TextField
                                                    type="number"
                                                    size="small"
                                                    label={t('cart-material.provider-quotation-flight-selling-price')}
                                                    value={flightPrice.amount}
                                                    onChange={(event) => onChangeAmount('flight', parseFloat(event.target.value))}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {flightPriceCurrency?.symbol}
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    fullWidth
                                                />
                                                <ProviderQuotationCurrencyPicker
                                                    value={flightPrice.currency}
                                                    onChange={(currency) => onChangeCurrency('flight', currency)}
                                                />
                                            </Stack>
                                        }
                                        <Typography
                                            textAlign="center"
                                            sx={{ marginBottom: 1.5 }}
                                        >
                                            {t('cart-material.provider-quotation-package-price-or')}
                                        </Typography>
                                        <Typography fontWeight="bold" sx={{ marginBottom: 1 }}>
                                            {t('cart-material.provider-quotation-package-price-total-title')}
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Functions />}
                                            onClick={onComputeTotalPrice}
                                            sx={{ marginBottom: 2 }}
                                        >
                                            {t('cart-material.provider-quotation-compute-total')}
                                        </Button>
                                        <Stack direction="row" spacing={1}>
                                            <TextField
                                                type="number"
                                                size="small"
                                                label={t('cart-material.provider-quotation-total-selling-price')}
                                                value={totalPrice.amount}
                                                onChange={(event) => onChangeAmount('total', parseFloat(event.target.value))}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {totalPriceCurrency?.symbol}
                                                        </InputAdornment>
                                                    )
                                                }}
                                                fullWidth
                                            />
                                            <ProviderQuotationCurrencyPicker
                                                value={totalPrice.currency}
                                                onChange={(currency) => onChangeCurrency('total', currency)}
                                            />
                                        </Stack>
                                    </Paper>
                                    <Button
                                        endIcon={<ArrowRightAlt />}
                                        sx={{ marginTop: 2 }}
                                        onClick={() => setOpenConfirmationModal(true)}
                                    >
                                        {t('cart-material.provider-quotation-continue')}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                }
                {
                    tab === QuotationTab.TEXTS &&
                    <>
                        <Box sx={{ flex: 1, marginTop: 1.5 }}>
                            <CartConstructionItineraryContentStepper
                                orientation="horizontal"
                                step={step}
                                steps={
                                    itineraryContent?.state === 'success' ?
                                        itineraryContent.data.content.map((item) => {
                                            return {
                                                mode: itineraryContent.data.mode,
                                                content: item
                                            } as ItineraryContentStep;
                                        }) :
                                        []
                                }
                                setStep={(step) => dispatch(setStep(step))}
                                disableDatesEdit
                                alternativeLabel
                                showStatus
                            />
                        </Box>
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                "marginTop": 3,
                                "marginBottom": 2.5,
                                '.MuiGrid-item': {
                                    position: 'relative'
                                },
                                '.MuiGrid-item:hover .cart-construction-view-switch-button.left': {
                                    transform: 'translate(7.5px, -50%) scale(1)'
                                },
                                '.MuiGrid-item:hover .cart-construction-view-switch-button.right': {
                                    transform: 'translate(50%, -50%) scale(1)'
                                }
                            }}
                        >
                            <DndProvider backend={HTML5Backend}>
                                <Grid
                                    item
                                    xs={view === 'minimized' ? 6 : 12}
                                    sx={{ display: view === 'right' ? 'none' : undefined }}
                                >
                                    {
                                        module === 'texts' &&
                                        <MailTemplateVisualEditorOptionsArea
                                            sx={{ height: '100%' }}
                                            disabledBlocks={[
                                                'social',
                                                'button'
                                            ]}
                                            useMinimal
                                        />
                                    }
                                </Grid>
                                <Grid
                                    item
                                    xs={view === 'minimized' ? 6 : 12}
                                    sx={{ display: view === 'left' ? 'none' : undefined }}
                                >
                                    <CartConstructionCommonViewSwitchButton direction="left" />
                                    <CartConstructionContentsContext.Provider value={{ mode: 'text', version }}>
                                        <CartConstructionContents minimal />
                                    </CartConstructionContentsContext.Provider>
                                </Grid>
                            </DndProvider>
                        </Grid>
                    </>
                }
            </Page>
            <ProviderQuotationConfirmationModal
                tripId={tripId}
                version={tripVersion}
                providerId={providerId}
                stackNumber={props.params.stackNumber}
                prices={{
                    terrestrial: terrestrialPrice,
                    flight: hasFlight ? flightPrice : undefined,
                    total: totalPrice
                }}
                open={openConfirmationModal}
                statuses={statuses}
                onClose={() => setOpenConfirmationModal(false)}
            />
            {
                provider && openNewPropositionModal &&
                <CartManualProductForm
                    dataTransformer={(data) => ({
                        ...data,
                        stack_number: stackNumber,
                        provider_created: true,
                        localization: [
                            {
                                locale,
                                name: (data as any).name,
                                description: (data as any).description
                            }
                        ],
                        counter_offer_of: counterOfferProduct,
                        ...reverseProviderQuotationStatus({
                            agent: null,
                            provider: 'accepted'
                        })
                    })}
                    provider={provider}
                    isForProposition
                />
            }
            {
                provider && openCreateProductModal &&
                <CartManualProductForm
                    dataTransformer={(data) => ({
                        ...data,
                        stack_number: stackNumber,
                        provider_created: true,
                        localization: [
                            {
                                locale,
                                name: (data as any).name,
                                description: (data as any).description
                            }
                        ]
                    })}
                    provider={provider}
                    isForProviderCreation
                />
            }
            <LoadingBackDrop open={loading || contentLoading} />
        </ProviderQuotationContext.Provider>
    );
}

const Page = styled('div')(() => ({
    backgroundColor: '#F8F8F8',
    minHeight: '100vh'
}));
