import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography
} from "@mui/material";
import { AddShoppingCart, MonetizationOnOutlined } from "@mui/icons-material";
import { useGetPrice } from "./utils/getPrice";
import { AccommodationCart } from "../Itinerary/objects/accommodationCart";

type Props = {
    open: boolean,
    room: AccommodationCart['rooms'][number],
    onClose: () => void
}

export function CartRoomDetailsModal(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const getPrice = useGetPrice();
    const price = getPrice(props.room.prices);

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                {t<string>('cart-material.room-details')}
            </DialogTitle>
            <DialogContent>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ marginBottom: 1 }}
                >
                    <MonetizationOnOutlined />
                    <Typography>
                        <Trans
                            i18nKey="cart-material.room-price"
                            values={{
                                price: new Intl.NumberFormat(
                                    i18n.language,
                                    {
                                        style: 'currency', 
                                        currency: price.currency?.iso_code ?? 'EUR'
                                    }
                                ).format(price.cost)
                            }}
                            components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                        />
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                >
                    <AddShoppingCart />
                    <Typography fontWeight="bold">
                        {t<string>('cart-material.room-extra')}
                    </Typography>
                </Stack>
                <ul>
                    {
                        props.room.elements.filter((item) => {
                            return (item.description?.length ?? 0) > 0;
                        }).map((element) => (
                            <li key={element.product_id}>
                                {element.description}
                            </li>
                        ))
                    }
                </ul>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    {t<string>('global.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
