
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import {
    DriveEtaOutlined,
    HotelOutlined,
    TransferWithinAStationOutlined,
    VisibilityOutlined
} from "@mui/icons-material";
import { AppState } from "../../Reducers/Reducers";

export function BookingManualProducts(): JSX.Element | null {
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1;
    });
    const bookableManualProducts = useSelector((state: AppState) => state.cartConstruction.bookableManualProducts);

    if (bookableManualProducts.length === 0) {
        return null;
    }

    return (
        <div>
            <Typography variant="body1" fontWeight={700} sx={{ marginTop: 2 }} gutterBottom>
                {t('cart-material.cart-construction-manual-products-booking-hint')}
            </Typography>
            <Stack>
                {
                    bookableManualProducts.map((product) => {
                        const name = product.localization?.find((item) => {
                            return item.locale === locale
                        })?.name ?? product.name;
                        return (
                            <Stack key={product.id} direction="row" spacing={1.5} alignItems="center">
                                {
                                    product.product_type === 0 &&
                                    <HotelOutlined fontSize="small" />
                                }
                                {
                                    product.product_type === 12 &&
                                    <VisibilityOutlined fontSize="small" />
                                }
                                {
                                    product.product_type === 2 &&
                                    <DriveEtaOutlined fontSize="small" />
                                }
                                {
                                    product.product_type === 4 &&
                                    <TransferWithinAStationOutlined fontSize="small" />
                                }
                                <Typography variant="body2">
                                    {name}
                                </Typography>
                            </Stack>
                        )
                    })
                }
            </Stack>
        </div>
    );
}
