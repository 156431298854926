import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import ScheduleIcon from '@material-ui/icons/Schedule';
import FlightIcon from '@material-ui/icons/Flight';
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';

import ConvertTime from "../../FlightMaterial/Functions/ConvertTime";

import moment from "moment";

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal"
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    bold: {
        fontWeight: "bold"
    },
    fontSize9: {
        fontSize: '0.85rem'
    },
    fontSize12: {
        fontSize: '1rem'
    },
    fontSize14: {
        fontSize: '1.15rem'
    },
    lightGrey: {
        color: '#00000040'
    },
    red: {
        color: "#FF0000"
    },
    textCenter: {
        textAlign: "center"
    },
    spacer: {
        padding: "8px 0px"
    },
    spacerSmall: {
        padding: 4
    },
    marginIcon: {
        marginTop: 6
    },
    redCircle: {
        border: "3px solid #FF0000",
        width: 15,
        height: 15,
        borderRadius: 8
    },
    redLine: {
        border: "2px solid #FF0000",
        background: "#FF0000",
        width: 0,
        height: 50,
        marginLeft: 5
    },
    redLineSmall: {
        border: "2px solid #FF0000",
        background: "#FF0000",
        width: 0,
        height: 29,
        marginLeft: 5
    },
    durationDaysPosition: {
        position: "relative",
        bottom: 28,
        left: 47
    },
    durationDaysPositionSmall: {
        position: "relative",
        bottom: 23,
        left: 32
    }
}));

const RenderFlightLegDetail = ({ leg, leg_index, max_index, stopover_duration, duration_days, small }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));

    const language = i18n.language;

    const airlineObj = leg.operating_airline;

    moment.locale(language);

    return (
        <Grid className={(isTablet || small) ? "" : classes.spacer}>
            <Grid container alignItems="baseline" justify="flex-start" spacing={1} className={(isTablet || small) ? classes.spacerSmall : classes.spacer}>
                <Grid item>
                    <img
                        src={"https://storagefacilitatrip.blob.core.windows.net/iata-company/" + airlineObj?.airline_code + ".png"} alt={"provider logo"}
                        style={{
                            width: 100,
                            height: 30,
                            maxWidth: 100,
                            maxHeight: 30,
                            objectFit: 'contain'
                        }}
                    />
                </Grid>
                <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize12}`}>
                    {t("flight_search.operate_by") + airlineObj?.commercial_name + " - " + airlineObj?.airline_code + " "}
                    {leg.operating_flight_number !== undefined && leg.operating_flight_number}
                    {leg.operating_flight_number === undefined && leg.flight_number !== undefined && leg.flight_number}
                </Grid>
            </Grid>
            <Grid container alignItems="center" justify="flex-start" spacing={(isTablet || small) ? 1 : 3} className={(isTablet || small) ? classes.spacerSmall : classes.spacer}>
                <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14}`}>
                    {t("global.flight") + " " + (leg_index + 1)}
                </Grid>
                <Grid item>
                    <div className={classes.redCircle} />
                    <div className={(isTablet || small) ? classes.redLineSmall : classes.redLine} />
                    <div className={classes.redCircle} />
                </Grid>
                <Grid item>
                    <Grid container alignItems="center" justify="flex-start" spacing={(isTablet || small) ? 1 : 2}>
                        <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14}`}>
                            {t("flight_search.departure")}
                        </Grid>
                        <Grid item className={`${classes.genericText} ${classes.fontWeight500} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.bold}`} style={small ? { paddingLeft: 9 } : { paddingLeft: 15 }}>
                            {moment.utc(leg.departure_time).format("DD MMM")}
                        </Grid>
                        <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.bold}`}>
                            {language === "fr" ? moment.utc(leg.departure_time).format("HH[h]mm") : moment.utc(leg.departure_time).format("HH[:]mm")}
                        </Grid>
                        <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.red}`}>
                            {leg.origin !== undefined && leg.origin !== null ? leg.origin.airport_code : leg.origin_station.station_code}
                        </Grid>
                        <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14}`}>
                            {
                                leg.origin !== undefined && leg.origin !== null
                                    ? leg.origin.name !== undefined ? leg.origin.name : leg.origin.international_name
                                    : leg.origin_station.name !== undefined ? leg.origin_station.name : leg.origin_station.international_name
                            }
                            {leg.origin_terminal !== null && leg.origin_terminal !== "No data" ? " " + t("flight_search.terminal") + " " + leg.origin_terminal : ""}
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justify="flex-start" spacing={(isTablet || small) ? 1 : 2}>
                        {
                            !!leg.flight_time &&
                            <>
                                <Grid item style={{ paddingRight: 0 }}>
                                    <ScheduleIcon className={`${classes.marginIcon} ${classes.genericText} ${(isTablet || small) ? classes.fontSize12 : classes.fontSize14}`} />
                                </Grid>
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9}`}>
                                    {ConvertTime(leg.flight_time, "minutes", language, t)}
                                </Grid>
                            </>
                        }
                        {
                            leg.aircraft !== undefined && leg.aircraft !== null && (
                                <Grid item style={{ paddingRight: 0 }}>
                                    <FlightIcon className={`${classes.marginIcon} ${classes.genericText} ${(isTablet || small) ? classes.fontSize12 : classes.fontSize14}`} />
                                </Grid>
                            )
                        }
                        {
                            leg.aircraft !== undefined && leg.aircraft !== null && (
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9}`}>
                                    {leg.aircraft !== undefined && leg.aircraft.commercial_name}
                                </Grid>
                            )
                        }
                        {
                            leg.class_name && (
                                <Grid item style={{ paddingRight: 0 }}>
                                    <AirlineSeatReclineExtraIcon className={`${classes.marginIcon} ${classes.genericText} ${(isTablet || small) ? classes.fontSize12 : classes.fontSize14}`} />
                                </Grid>
                            )
                        }
                        {
                            leg.class_name && (
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9}`}>
                                    {t(`flight_search.cabin_class.${leg.class_name?.toLowerCase()}`)}
                                    {
                                        leg.class_of_service &&
                                        ` (${leg.class_of_service})`
                                    }
                                </Grid>
                            )
                        }
                        {
                            JSON.parse(localStorage.getItem("config")).quotation_code === "visiteurs" && leg.class_of_service && (
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9}`}>
                                    {" - " + leg.class_of_service}
                                </Grid>
                            )
                        }
                    </Grid>
                    <Grid container alignItems="center" justify="flex-start" spacing={(isTablet || small) ? 1 : 2}>
                        <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14}`}>
                            {t("flight_search.arrival")}
                        </Grid>
                        <Grid item
                            className={`${classes.genericText} ${classes.fontWeight500} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.bold}`}
                            style={(leg_index === max_index && duration_days !== undefined && duration_days !== 0) ? (small ? { marginBottom: -13 } : { marginBottom: -12 }) : {}}>
                            {moment.utc(leg.arrival_time).format("DD MMM")}
                            {
                                leg_index === max_index && duration_days !== undefined && duration_days !== 0 && (
                                    <div className={`${classes.genericText} ${classes.fontSize9} ${classes.red} ${small ? classes.durationDaysPositionSmall : classes.durationDaysPosition}`}>
                                        {"+" + duration_days + t("global.abrev_day")}
                                    </div>
                                )
                            }
                        </Grid>
                        <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.bold}`}>
                            {language === "fr" ? moment.utc(leg.arrival_time).format("HH[h]mm") : moment.utc(leg.arrival_time).format("HH[:]mm")}
                        </Grid>
                        <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.red}`}>
                            {leg.destination !== undefined && leg.destination !== null ? leg.destination.airport_code : leg.destination_station.station_code}
                        </Grid>
                        <Grid item className={`${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14}`}>
                            {
                                leg.destination !== undefined && leg.destination !== null
                                    ? leg.destination.name !== undefined ? leg.destination.name : leg.destination.international_name
                                    : leg.destination_station.name !== undefined ? leg.destination_station.name : leg.destination_station.international_name
                            }
                            {leg.destination_terminal !== null && leg.destination_terminal !== "No data" ? " " + t("flight_search.terminal") + " " + leg.destination_terminal : ""}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                leg_index !== max_index && (
                    <Grid className={(isTablet || small) ? classes.spacerSmall : classes.spacer}>
                        <Divider />
                        <Grid className={`${(isTablet || small) ? classes.spacerSmall : classes.spacer} ${classes.genericText} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.textCenter}`}>
                            <div className={classes.genericText}>
                                {
                                    leg.destination !== undefined && leg.destination !== null
                                        ? t("flight_search.change_airport") + leg.destination.iata_city.international_name + " - "
                                        : t("flight_search.change_train") + leg.destination_station.iata_city.international_name + " - "
                                }
                                <span className={classes.bold}>
                                    {
                                        leg.destination !== undefined && leg.destination !== null
                                            ? leg.destination.name !== undefined ? leg.destination.name : leg.destination.international_name
                                            : leg.destination_station.name !== undefined ? leg.destination_station.name : leg.destination_station.international_name
                                    }
                                    {` (${leg.destination !== undefined && leg.destination !== null ? leg.destination.airport_code : leg.destination_station.station_code})`}
                                </span>
                            </div>
                            <div className={classes.genericText}>
                                {t("flight_search.stopover_duration")}
                                <span className={classes.bold}>
                                    {/*{ ConvertTime(stopover_duration, "seconds", language, t) } */}
                                    {moment.utc(stopover_duration).format('HH[h]mm')}
                                </span>
                            </div>
                            <div className={classes.genericText}>
                                {t("flight_search.stopover_change_airport")}
                            </div>
                        </Grid>
                        <Divider />
                    </Grid>
                )
            }
        </Grid>
    );
};

export default React.memo(RenderFlightLegDetail);
