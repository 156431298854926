//---- Commons ----//
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import SelectTransport from "../Itinerary/Functions/SelectTransport.js";
import CheckCars from "../Itinerary/Functions/CheckCars.js";

function callback(step_info, setCarIndex, setLocationIndex, location_index, car_index, itinerary_list, dispatch, router, enqueueSnackbar, t, onClose) {
    let copy_itinerary_list = itinerary_list.slice();
    let step = step_info[location_index];
    if (step.origin_dest !== null && step.origin_dest.is_pickup_city_name === undefined && step.return_dest !== null & step.return_dest.is_pickup_city_name === undefined) {
        let active_origin = itinerary_list.find((itinerary) => {
            if (itinerary.destination !== null && itinerary.destination !== undefined) {
                return itinerary.destination.data.id === (step.origin_dest.data !== undefined ? step.origin_dest.data.id : step.origin_dest.id);
            }
        });
        let active_return = itinerary_list.find((itinerary) => {
            if (itinerary.destination !== null && itinerary.destination !== undefined) {
                return itinerary.destination.data.id === (step.return_dest.data !== undefined ? step.return_dest.data.id : step.return_dest.id);
            }
        });
        let steps = [];
        if (active_origin !== undefined && active_return !== undefined) {
            let origin_id = itinerary_list.findIndex((itinerary) => {
                return itinerary.id === active_origin.id;
            });
            for (let i = origin_id; itinerary_list[i].id !== active_return.id; i++) {
                steps.push(itinerary_list[i]);
            }
        }
        CheckCars(steps, itinerary_list, setCarIndex, setLocationIndex, location_index, car_index, step_info, router, enqueueSnackbar, t, dispatch, onClose);
    } else {
        enqueueSnackbar(t("cars.add_to_cart.success"), {
            variant: "success"
        });
        if ((car_index + 1) !== step.nb_vehicle) {
            dispatch({ type: "CARS_IS_RESULT" });
            setCarIndex(car_index + 1);
        } else if ((location_index + 1) !== step_info.length) {
            dispatch({ type: "CARS_IS_RESULT" });
            setCarIndex(0);
            setLocationIndex(location_index + 1);
        } else if (!onClose) {
            router.push(`/${window.url_name}/apps/itinerary`);
        } else if (onClose) {
            onClose();
        }
    }
}

export default function (
    contract_info,
    car_contract,
    step_info,
    location_index,
    car_index,
    agency_options,
    travelers,
    setRequestStatus,
    setCarIndex,
    setLocationIndex,
    itinerary_list,
    dispatch,
    router,
    enqueueSnackbar,
    t,
    context
) {
    const { pass_check, headers } = CheckBeforeRequest();
    const car = contract_info.selected_car;
    const step = step_info[location_index];
    console.log('contract_info:', contract_info);
    if (pass_check) {
        let a = moment(step.return_date ? step.return_date : step.end_date_dest);
        let b = moment(step.origin_date ? step.origin_date : step.start_date_origin);
        let days = a.diff(b, 'd');
        let tmp_origin_hour = null;
        let tmp_return_hour = null;
        if (step.origin_hour === t("global.midnight")) {
            tmp_origin_hour = "00h00";
        } else if (step.origin_hour === t("global.noon")) {
            tmp_origin_hour = "12h00";
        } else {
            tmp_origin_hour = step.origin_hour;
        }
        if (step.return_hour === t("global.midnight")) {
            tmp_return_hour = "00h00";
        } else if (step.return_hour === t("global.noon")) {
            tmp_return_hour = "12h00";
        } else {
            tmp_return_hour = step.return_hour;
        }
        let origin_hour = tmp_origin_hour.replace("h", ":");
        let return_hour = tmp_return_hour.replace("h", ":");
        let tmp_date_origin = moment((step.origin_date !== null ? moment(step.origin_date).format("YYYY-MM-DD") : step.start_date_origin) + "T" + origin_hour);
        let tmp_date_return = moment((step.return_date !== null ? moment(step.return_date).format("YYYY-MM-DD") : step.end_date_dest) + "T" + return_hour);

        let date_origin = tmp_date_origin.format("YYYY-MM-DD[T]HH:mm");
        let date_return = tmp_date_return.format("YYYY-MM-DD[T]HH:mm");

        let special_equipment = [];
        let location_code_pickup = contract_info.stations !== undefined ? contract_info.stations.pick_up[contract_info.checked].code : contract_info.selected_car.location_code_pickup;
        let location_code_return = contract_info.stations !== undefined ? (contract_info.checked_return !== null ? contract_info.stations.return[contract_info.checked_return].code : contract_info.selected_car.location_code_return) : contract_info.selected_car.location_code_pickup;
        let stations = null;
        if (car.station_prices) {
            stations = car.station_prices.find((station) => {
                return station.pickup_station === location_code_pickup;
            });
        }
        let country_code = contract_info.stations !== undefined ? contract_info.stations.pick_up[contract_info.checked].country_code : car.country_code;
        let state_code = contract_info.stations !== undefined ? contract_info.stations.pick_up[contract_info.checked].state_code : car.state_code;
        if (agency_options !== null) {
            agency_options.map((option) => {
                if (option.quantity > 0) {
                    special_equipment.push({
                        code_id: option.code_id,
                        quantity: option.quantity,
                        price: (parseFloat(option.charge_list.rental_period !== null ? option.charge_list.rental_period : option.charge_list.day * days))
                    });
                }
            });
        }
        let tmp_travelers = travelers.find((traveler) => {
            return traveler.default;
        });
        let contract_selected = car_contract.contracts.find((el) => {
            return el.contract_id === car_contract.selected;
        });
        let request = {
            contract_id: contract_selected.contract,
            provider_id: car.provider_id,
            location_code_pickup: location_code_pickup,
            location_code_return: location_code_return,
            transmission_type: car.vehicle_info.transmission,
            air_conditioner: car.vehicle_info.air_condition,
            //door_count: car.vehicle_info.door_count,
            vehicle_type_id: car.vehicle_info.vehicle_type_id,
            vehicle_category_id: car.vehicle_info.vehicle_category_id,
            //base_price: stations.base_price[0].selling_price,
            total_price: car.station_prices ? stations.prices[0].selling_price : car.selling_price.selling_price,
            driver_age: step.age === "30+" ? 30 : step.age,
            start_date: date_origin,
            end_date: date_return,
            special_equipments: special_equipment,
            country_code: country_code,
            state_code: state_code,
            group_passenger: tmp_travelers.id,
            start_destination: step.origin_dest !== null ? (step.origin_dest.is_pickup_city_name !== undefined && step.origin_dest.is_pickup_city_name ? null : step.origin_dest.id) : step.start_destination,
            end_destination: step.return_dest !== null ? (step.return_dest.is_pickup_city_name !== undefined && !step.return_dest.is_pickup_city_name ? null : step.return_dest.id) : step.end_destination
        };
        setRequestStatus(true);
        if (context?.onChangeLoading) {
            context.onChangeLoading(true);
        }
        $.ajax({
            method: "POST",
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/car/`,
            headers: headers,
            data: JSON.stringify(context?.dataTransformer ? context?.dataTransformer(request) : request),
            success: function (results) {
                dispatch({ type: "CARS_ADD_TO_CART", payload: { car: results } });
                //enqueueSnackbar(t("cars.add_to_cart.success"), {
                //variant: "success",
                //});
                callback(step_info, setCarIndex, setLocationIndex, location_index, car_index, itinerary_list, dispatch, router, enqueueSnackbar, t, context?.onClose);
                if (context?.onDelete) {
                    context?.onDelete();
                }
                if (context?.onClose) {
                    context?.onClose();
                }
                if (context?.onChangeLoading) {
                    context.onChangeLoading(false);
                }
            },
            error: function (error) {
                console.log('error:', error);
                enqueueSnackbar(error.responseJSON.detail, {
                    variant: "error"
                });
                setRequestStatus(false);
                if (context?.onChangeLoading) {
                    context.onChangeLoading(false);
                }
            }
        });
    }
}
