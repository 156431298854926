import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoogleFont } from "../objects/googleFont";
import { GoogleFontsApiResponse } from "../objects/googleFontsApiResponse";
import { setGoogleFonts } from "../redux/actions";
import { AppState } from "../../../../../Reducers/Reducers";

type Config = {
    keys: {
        identifier: string,
        value: string
    }[]
}

export function useMailTemplateGoogleFontsQuery(): GoogleFont[] {
    const dispatch = useDispatch();
    const config: Config = JSON.parse(localStorage.getItem("config") ?? '{"keys": []}');
    const apiKey = config.keys.find((key) => {
        return key.identifier === 'google_api';
    })?.value;
    const googleFonts = useSelector((state: AppState) => state.mailTemplate.others.googleFonts);

    useEffect(() => {
        if (apiKey && googleFonts.length === 0) {
            makeRequest(apiKey).then((fonts) => {
                dispatch(setGoogleFonts(fonts));
            });
        }
    }, [apiKey]);

    return googleFonts;
}

function makeRequest(apiKey: string): Promise<GoogleFont[]> {
    const apiEndpointUrl = new URL('https://www.googleapis.com/webfonts/v1/webfonts');
    apiEndpointUrl.searchParams.set('sort', 'popularity');
    apiEndpointUrl.searchParams.set('key', apiKey);
    return axios.get<GoogleFontsApiResponse>(
        apiEndpointUrl.toString()
    ).then((response) => response.data.items.map((font) => {
        const url = new URL('https://fonts.googleapis.com/css');
        url.searchParams.set('display', 'swap');
        url.searchParams.set('global', 'true');
        url.searchParams.set('family', font.family);
        return {
            name: font.family,
            url: url.toString().replace(/%2B/g, '+')
        };
    }));
}
