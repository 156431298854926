import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";
import { redoVisualEditor, undoVisualEditor } from "./redux/actions";
import { AppState } from "../../../../Reducers/Reducers";
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

export function MailTemplateVisualEditorUndoRedo(): JSX.Element {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const canUndo = useSelector((state: AppState) => state.mailTemplate.visualEditor.past.length > 0);
    const canRedo = useSelector((state: AppState) => state.mailTemplate.visualEditor.future.length > 0);

    const onUndo = () => {
        dispatch(undoVisualEditor());
    };

    const onRedo = () => {
        dispatch(redoVisualEditor());
    };

    return (
        <div>
            {
                canUndo ?
                    <Tooltip title={`${t('shared.undo')} (Ctrl + z)`}>
                        <IconButton onClick={onUndo} disabled={!canUndo}>
                            <UndoIcon />
                        </IconButton>
                    </Tooltip> :
                    <IconButton disabled>
                        <UndoIcon />
                    </IconButton>
            }
            {
                canRedo ?
                    <Tooltip title={`${t('shared.redo')} (Ctrl + y)`}>
                        <IconButton onClick={onRedo} disabled={!canRedo}>
                            <RedoIcon />
                        </IconButton>
                    </Tooltip> :
                    <IconButton disabled>
                        <RedoIcon />
                    </IconButton>
            }
        </div>
    );
}