import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import LegDetails from "./LegDetails.js";
import LegDetailsMobile from "./LegDetailsMobile.js";
import LuggageIcon from "./LuggageIcon";
import LuggageByPax from "./LuggageByPax.js";

import CountAgePaxType from "../Functions/CountAgePaxType.js";

import moment from "moment";


const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    bold: {
        fontWeight: "bold"
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize9: {
        fontSize: 9
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize18: {
        fontSize: 18
    },
    fontSize28: {
        fontSize: 28
    },
    fontSize20: {
        fontSize: 20
    },
    red: {
        color: "#FF0000"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    textLeft: {
        textAlign: "left"
    },
    textCenter: {
        textAlign: "center"
    },
    genericBorder: {
        background: "#FFFFFF",
        border: "1px solid #D6057F",
        boxSizing: "border-box"
    },
    genericPadding: {
        padding: "8px 25px 0px"
    },
    genericPaddingSmall: {
        padding: "0px 8px"
    },
    luggageIcon: {
        fontSize: 24,
        color: '#E6592F'
    },
    luggageIconSmall: {
        fontSize: 20,
        color: '#E6592F'
    },
    spacer: {
        padding: "8px 0px"
    }
}))

const OutboundDetails = ({ setShowDetails, outbound_flight, outbound_index, travelers, max_index, small }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    const language = useSelector(store => store.header.language);
    const end_date = useSelector(store => store.trip.end_date);
    const airports = useSelector(store => store.flight_search.airports);
    const stations = useSelector(store => store.flight_search.stations);

    const [carryOnLuggages, setCarryOnLuggages] = useState(0);
    const [checkLuggages, setCheckLuggages] = useState(0)
    
    const travelers_obj = CountAgePaxType(travelers, end_date);

    moment.locale(language);

    useEffect(() => {
        let temp_carry_on = 0;
        let temp_check = 0
        if (outbound_flight.legs[0].baggage !== undefined){
            outbound_flight.legs[0].baggage.map((luggage) => {
                if(luggage.is_carry_on){
                    temp_carry_on += luggage.quantity;
                }else{
                    temp_check += luggage.quantity;
                }
            })
        }else{
            temp_check = outbound_flight.legs[0].baggage_allowance.number_of_pieces;
        }
        
        setCarryOnLuggages(temp_carry_on);
        setCheckLuggages(temp_check);
    }, [])

    const hideDetails = () => {
        setShowDetails(false);
    }

    return(
        <Grid className={ isMobile ? "" : ((isTablet || small) ? classes.genericPaddingSmall : classes.genericPadding)} style={ isHandHeld ? {} : (small ? { width: 800 } : {}) }>
            { !small && !isHandHeld && <Divider /> }
            {
                isHandHeld && (
                    <Grid container alignItems="center" justify="space-between">
                        <Grid item xs={1}>
                            <IconButton className={classes.genericBorder} size="small" onClick={() => hideDetails()}>
                                <NavigateBeforeIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={10} className={`${classes.genericText} ${classes.textCenter} ${classes.fontWeight900} ${isMobile ? classes.fontSize18 : classes.fontSize28} ${classes.uppercase}`}>
                            { t("flight_search.flight_details") }
                        </Grid>
                        <Grid item xs={1} />
                    </Grid>
                )
            }
            {
                !isHandHeld && (
                    <Grid className={`${classes.spacer} ${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize12 : classes.fontSize20} ${classes.uppercase}`}>
                        { t("flight_search.flight_details") }
                    </Grid>
                )
            }
            <Grid container alignItems="center" justify="flex-start" spacing={1} style={ (isTablet || small) ? {} : { paddingBottom: 16 }}>
                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : (isMobile ? classes.fontSize12 : classes.fontSize16)} ${classes.uppercase}`}>
                    { 
                        outbound_flight.legs[0].origin_airport !== undefined && airports !== null
                        ? airports[outbound_flight.legs[0].origin_airport].iata_city.name !== undefined ? airports[outbound_flight.legs[0].origin_airport].iata_city.name : airports[outbound_flight.legs[0].origin_airport].iata_city.international_name
                        : (stations !== null ? stations[outbound_flight.legs[0].origin_station].iata_city.name !== undefined ? stations[outbound_flight.legs[0].origin_station].iata_city.name : stations[outbound_flight.legs[0].origin_station].iata_city.international_name : "")
                    }
                </Grid>
                <Grid item className={classes.genericText} style={{ marginTop: 4 }}>
                    <ArrowRightAltIcon />
                </Grid>
                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : (isMobile ? classes.fontSize12 : classes.fontSize16)} ${classes.uppercase}`}>
                    { 
                        outbound_flight.legs[0].destination_airport !== undefined && airports !== null
                        ? airports[outbound_flight.legs[0].destination_airport].iata_city.name !== undefined ? airports[outbound_flight.legs[0].destination_airport].iata_city.name : airports[outbound_flight.legs[0].destination_airport].iata_city.international_name
                        : (stations !== null ? stations[outbound_flight.legs[0].destination_station].iata_city.name !== undefined ? stations[outbound_flight.legs[0].destination_station].iata_city.name : stations[outbound_flight.legs[0].destination_station].iata_city.international_name : "")
                    }
                </Grid>
                <Grid item xs={1} />
                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${(isMobile || small) ? classes.fontSize9 : classes.fontSize12} ${classes.uppercase}`}>
                    { moment(outbound_flight.legs[0].departure_datetime_lt).format("dddd Do MMMM") }
                </Grid>
            </Grid>
            <Divider />
            <Grid container alignItems={isHandHeld ? "stretch" : "baseline"} justify="flex-start" spacing={small ? 1 : 2} className={classes.spacer} direction={isHandHeld ? "column" : "row" }>
                <Grid item xs={isHandHeld ? 12 : 7}>
                    <Grid className={`${classes.genericText} ${classes.fontWeight900} ${isHandHeld ? classes.fontSize18 : (small ? classes.fontSize9 : classes.fontSize16)} ${classes.uppercase} ${classes.textCenter}`}>
                        { t("flight_search.itinerary")}
                    </Grid>
                    <Grid container alignItems="center" justify="flex-start" spacing={2} className={classes.spacer}>
                        <Grid item className={`${classes.genericText} ${classes.bold} ${(isMobile || small) ? classes.fontSize9 : classes.fontSize16} ${classes.uppercase}`}>
                            { t("flight_search.departure") }
                        </Grid>
                        <Grid item className={`${classes.genericText} ${classes.bold} ${(isMobile || small)  ? classes.fontSize9 : classes.fontSize16} ${classes.uppercase}`}>
                            { moment(outbound_flight.legs[0].departure_datetime_lt).format("dddd Do MMMM YYYY") }
                        </Grid>
                    </Grid>
                    {
                        outbound_flight.legs.map((leg, leg_index) => {
                            if (isMobile){
                                return(
                                    <LegDetailsMobile
                                        key={leg_index}
                                        leg={leg}
                                        leg_index={leg_index}
                                        max_index={outbound_flight.legs.length - 1}
                                        stopover_duration={leg_index < outbound_flight.stopover_duration.length ? outbound_flight.stopover_duration[leg_index] : null}
                                        duration_days={outbound_flight.duration_days}
                                        small={small}
                                        />
                                )
                            }else{
                                return(
                                    <LegDetails
                                        key={leg_index}
                                        leg={leg}
                                        leg_index={leg_index}
                                        max_index={outbound_flight.legs.length - 1}
                                        stopover_duration={leg_index < outbound_flight.stopover_duration.length ? outbound_flight.stopover_duration[leg_index] : null}
                                        duration_days={outbound_flight.duration_days}
                                        small={small}
                                        />
                                )
                            }
                        })
                    }
                    <Grid container alignItems="center" justify="flex-start" spacing={2} className={classes.spacer}>
                        <Grid item className={`${classes.genericText} ${classes.bold} ${(isMobile || small) ? classes.fontSize9 : classes.fontSize16} ${classes.uppercase}`}>
                            { t("flight_search.arrival") }
                        </Grid>
                        <Grid item className={`${classes.genericText} ${classes.bold} ${(isMobile || small) ? classes.fontSize9 : classes.fontSize16} ${classes.uppercase}`}>
                            { moment(outbound_flight.legs.slice(-1)[0].arrival_datetime_lt).format("dddd Do MMMM YYYY") }
                        </Grid>
                    </Grid>
                    {
                        outbound_flight.duration_days !== undefined && outbound_flight.duration_days !== 0 && (
                            <Grid className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize16} ${classes.uppercase} ${classes.red}`}>
                                { t("flight_search.important") + t("flight_search.this_flight_arrive") + outbound_flight.duration_days + " " }
                                { outbound_flight.duration_days > 1 ? t("flight_search.days") : t("flight_search.day")}
                                { t("flight_search.after_departure") }
                            </Grid>
                        )
                    }
                </Grid>
                <Divider orientation={isHandHeld ? "horizontal" : "vertical"} flexItem={isHandHeld ? false : true} style={isHandHeld ? { width: "100%" } : { margin: "30px 15px 14px" }} />
                <Grid item xs={isHandHeld ? 12 : 4}>
                    <Grid container alignItems="center" justify="center" spacing={2}>
                        <Grid item>
                            <LuggageIcon className={ (isTablet || small) ? classes.luggageIconSmall : classes.luggageIcon}/>
                        </Grid>
                        <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${isHandHeld ? classes.fontSize18 : (small ? classes.fontSize9 : classes.fontSize16)} ${classes.uppercase}`}>
                            { t("global.luggages") }
                        </Grid>
                    </Grid>
                    <Grid className={classes.spacer}>
                        <Grid className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.uppercase}`}>
                            { t("flight_search.luggage_carry_on") + " :"}
                        </Grid>
                        <TableContainer style={{ paddingTop: 8, overflowY: "hidden" }}>
                            <Table>
                                <TableBody>
                                    {
                                        travelers_obj.ADT !== 0 && <LuggageByPax luggageNb={carryOnLuggages} luggageType={"cabin"} paxNb={travelers_obj.ADT} paxLabel={t("flight_search.adults")} small={small} />
                                    }
                                    {
                                        travelers_obj.CNN !== 0 && <LuggageByPax luggageNb={carryOnLuggages} luggageType={"cabin"} paxNb={travelers_obj.CNN} paxLabel={t("flight_search.children")} small={small} />
                                    }
                                    {
                                        travelers_obj.INF !== 0 && <LuggageByPax luggageNb={carryOnLuggages} luggageType={"cabin"} paxNb={travelers_obj.INF} paxLabel={t("flight_search.babies")} small={small} />
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid className={classes.spacer}>
                        <Grid className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.uppercase}`}>
                            { t("flight_search.luggage_check") + " :" }
                        </Grid>
                        <TableContainer style={{ paddingTop: 8, marginLeft: isHandHeld ? "auto" : 0, marginRight: isHandHeld ? "auto" : 0, overflowY: "hidden" }}>
                            <Table>
                                <TableBody>
                                    {
                                        travelers_obj.ADT !== 0 && <LuggageByPax luggageNb={checkLuggages} luggageType={"checked"} paxNb={travelers_obj.ADT} paxLabel={t("flight_search.adults")} small={small} />
                                    }
                                    {
                                        travelers_obj.CNN !== 0 && <LuggageByPax luggageNb={checkLuggages} luggageType={"checked"} paxNb={travelers_obj.CNN} paxLabel={t("flight_search.children")} small={small} />
                                    }
                                    {
                                        travelers_obj.INF !== 0 && <LuggageByPax luggageNb={checkLuggages} luggageType={"checked"} paxNb={travelers_obj.INF} paxLabel={t("flight_search.babies")} small={small} />
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
            { outbound_index !== max_index && !small && !isHandHeld && <Divider style={{ margin: "8px 0px" }}/> }
        </Grid>
    )
}

export default React.memo(OutboundDetails);