import { PictureState } from '../objects/pictureState';
import {
    ADD_CUSTOM_PICTURES,
    ADD_FILES,
    ADD_SELECTION,
    DELETE_FILE,
    DELETE_SELECTION,
    PictureActionTypes,
    RESET_CREATE_FORM,
    SET_ADDING,
    SET_CREATE_ALT,
    SET_CREATE_CREDIT,
    SET_CREATE_DESCRIPTION,
    SET_CREATE_NAME,
    SET_CREATE_TYPE,
    SET_CROPPER,
    SET_LIMIT,
    SET_LIST,
    SET_PAGE,
    SET_PICTURE,
    SET_QUERY,
    SET_RUNNING,
    SET_SEARCH,
    TOGGLE_CREATE,
    TOGGLE_FILTER,
    TOGGLE_KEEP,
    TOGGLE_REFRESH,
    UPDATE_ALT,
    UPDATE_CREATE_TAB,
    UPDATE_CREDITS,
    UPDATE_DESCRIPTION,
    UPDATE_FILTER_TYPES,
    UPDATE_NAME,
    UPDATE_PROGRESS
} from './actionTypes';

const initialState: PictureState = {
    adding: false,
    alt: '',
    count: 0,
    create: false,
    create_tab: 0,
    create_alt: null,
    create_credit: null,
    create_description: null,
    create_name: null,
    create_type: 'PICTURE',
    credits: '',
    cropper: null,
    description: '',
    description_error: '',
    filter_open: false,
    filter_types: ['PICTURE'],
    files: [],
    keep: true,
    limit: 24,
    list: null,
    name: '',
    name_error: '',
    page: 0,
    picture: null,
    progress: 0,
    queries: [],
    refresh: false,
    running: false,
    search: '',
    selection: []
};

export default (state: PictureState = initialState, action: PictureActionTypes): PictureState => {
    switch (action.type) {
        case ADD_CUSTOM_PICTURES: {
            return {
                ...state,
                adding: false,
                // count: 0,
                create: false,
                create_tab: 0,
                description: '',
                filter_open: false,
                filter_types: ['PICTURE'],
                files: [],
                limit: 24,
                // list: null,
                name: '',
                page: 0,
                progress: 0,
                refresh: false,
                running: false,
                search: '',
                selection: []
            };
        }
        case ADD_FILES: {
            return {...state, files: [...state.files, ...action.payload]};
        }
        case ADD_SELECTION: {
            const files = [...state.files];
            if (action.payload.name !== null) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i].name === action.payload.name) {
                        files.splice(i, 1);
                        break;
                    }
                }
            }
            return {...state, selection: [...state.selection, action.payload.picture], files: files};
        }
        case DELETE_FILE: {
            const files = [...state.files];
            files.splice(action.payload, 1);
            return {...state, files: files};
        }
        case DELETE_SELECTION: {
            const selection = [...state.selection];
            for (let i = 0; i < selection.length; i++) {
                if (selection[i]?.id === action.payload) {
                    selection.splice(i, 1);
                    break;
                }
            }
            return {...state, selection: selection};
        }
        case RESET_CREATE_FORM: {
            return {
                ...state,
                create_alt: null,
                create_credit: null,
                create_description: null,
                create_name: null,
                create_type: 'PICTURE'
            };
        }
        case SET_ADDING: {
            return {...state, adding: action.payload};
        }
        case SET_CREATE_ALT: {
            return {...state, create_alt: action.payload};
        }
        case SET_CREATE_CREDIT: {
            return {...state, create_credit: action.payload};
        }
        case SET_CREATE_DESCRIPTION: {
            return {...state, create_description: action.payload};
        }
        case SET_CREATE_NAME: {
            return {...state, create_name: action.payload};
        }
        case SET_CREATE_TYPE: {
            return {...state, create_type: action.payload};
        }
        case SET_CROPPER: {
            return {...state, cropper: action.payload};
        }
        case SET_LIMIT: {
            return {...state, list: null, limit: action.payload};
        }
        case SET_LIST: {
            const { count, results } = action.payload;
            return {...state, count: count, list: results, running: false};
        }
        case SET_PAGE: {
            return {...state, list: null, page: action.payload};
        }
        case SET_QUERY: {
            const currentQuery = state.queries.find((query) => query.key === action.payload.key);
            if (currentQuery) {
                return {
                    ...state,
                    queries: state.queries.map((query) => query.key === action.payload.key ? action.payload : query)
                };
            }
            return {
                ...state,
                queries: [...state.queries, action.payload]
            };
        }
        case SET_PICTURE: {
            if (action.payload === true) {
                return {...state, create: true, description: '', name: '', picture: null, progress: 0};
            } else if (action.payload === false) {
                return {...state, create: false, description: '', name: '', picture: null, progress: 0};
            } else if (state.create) {
                return {...state, create: false, description: '', name: '', picture: null, progress: 0, refresh: !state.refresh};
            } else if (state.picture !== null) {
                if (state.list !== null) {
                    const list = [...state.list];
                    for (let i = 0; i < list.length; i++) {
                        if (list[i]?.id === state.picture.id) {
                            list[i] = action.payload;
                            break;
                        }
                    }
                }
                return {...state, create: false, description: '', name: '', picture: null, progress: 0, refresh: !state.refresh};
            } else {
                return {...state, description: action.payload.description !== undefined && action.payload.description !== null ? action.payload.description : '', name: action.payload.name !== undefined && action.payload.name !== null ? action.payload.name : '', picture: action.payload, progress: 0};
            }
        }
        case SET_RUNNING: {
            return {...state, running: action.payload};
        }
        case SET_SEARCH: {
            return {...state, list: null, search: action.payload};
        }
        case TOGGLE_CREATE: {
            return {...state, adding: false, create: !state.create};
        }
        case TOGGLE_FILTER: {
            return {...state, filter_open: !state.filter_open};
        }
        case TOGGLE_KEEP: {
            return {...state, keep: !state.keep};
        }
        case TOGGLE_REFRESH: {
            return {...state, refresh: !state.refresh};
        }
        case UPDATE_ALT: {
            return {...state, alt: action.payload};
        }
        case UPDATE_CREATE_TAB: {
            return {...state, create_tab: action.payload};
        }
        case UPDATE_CREDITS: {
            return {...state, credits: action.payload};
        }
        case UPDATE_DESCRIPTION: {
            return {...state, description: action.payload};
        }
        case UPDATE_FILTER_TYPES: {
            return {...state, filter_types: action.payload};
        }
        case UPDATE_NAME: {
            return {...state, name: action.payload};
        }
        case UPDATE_PROGRESS: {
            return {...state, progress: action.payload};
        }
        default:
            return state;
    }
};