import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//Core
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AlertTitle from "@material-ui/lab/AlertTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
//Icons
import Close from "@material-ui/icons/Close";
//Components
import CartAssistanceVip from "./CartAssistanceVip";
import CartAssistanceLeadPax from "./CartAssistanceLeadPax";
import CartAssistanceEstimation from "./CartAssistanceEstimation";
import { Checkbox, FormControlLabel } from '@mui/material';
import { RadioButtonUnchecked, RadioButtonChecked } from '@mui/icons-material';

const useStyles = makeStyles({
    assistanceAppBar: {
        position: 'relative !important',
        backgroundColor: '#3367D6 !important'
    },
    assistanceContainer: {
        marginTop: 32
    },
    assistanceTitle: {
        backgroundColor: 'rgba(0, 0, 0, .03) !important',
        borderBottom: '1px solid rgba(0, 0, 0, .125) !important'
    }
});

const CartAssistancePrices = ({ index }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [price, setPrice] = useState(null);
    const assistance_modal = useSelector((state) => state.cart.assistance_modal);
    const assistance_prices = useSelector((state) => state.cart.assistance_prices);
    const assistance_index = useSelector((state) => state.cart.assistance_index);
    const user = useSelector(state => state.user.user);
    const language = useSelector(state => state.header.tmp_language);
    const currency = useSelector(state => state.trip.currency);
    const currency_list = useSelector(state => state.base.currency_list);
    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;
    const onSetIndex = () => {
        dispatch({ type: 'CART_SET_ASSISTANCE_INDEX', payload: index });
    };
    const priceToDisplay = price => {
        return {
            cost: parseFloat(price.selling_price),
            currency: currency_list.find(currency => currency.id === price.selling_currency)
        };
    };
    const getPrice = prices => {
        if (user.client_full.type === 1 || user.client_full.type === 3 || quotation_code === 'verdie') {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_tva) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_surcom) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].master_price) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user.client) {
                    return priceToDisplay(prices[i]);
                }
            }
        } else {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_tva) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user.client) {
                    return priceToDisplay(prices[i]);
                }
            }
        }
        return {
            cost: 0,
            currency: currency
        };
    };
    useEffect(() => {
        if (assistance_prices && assistance_prices.length >= index) {
            setPrice(getPrice(assistance_prices[index].price));
        }
    }, [assistance_prices]);
    return (
        <FormControlLabel control={<Checkbox icon={<RadioButtonUnchecked />} checkedIcon={<RadioButtonChecked />} checked={assistance_index === index} onClick={ onSetIndex } />} label={ `Votre estimation pour l'assurance ${index === 0 ? quotation_code === 'cercledesvoyages' ? 'annulation' : 'GROUPE PREMIUM EPIDEMIE' : index === 1 ? quotation_code === 'cercledesvoyages' ? 'multirisque' : 'IND MULTIRISQUE PREMIUM MONDIAL' : 'Complémentaire CB'} est de : ${ price ? `${ price.cost.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } ${ price.currency.symbol }` : '' }`} />
    );
};

export default CartAssistancePrices;
