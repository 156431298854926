import axios from "axios";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";

export function makeInvoiceCreateRequest(options) {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        return axios.post(
            //eslint-disable-next-line no-undef
            `${API_HREF}client/${window.id_owner}/trip/${options.trip_info.id}/versions/${options.version}/invoice-history/`,
            options.data,
            {
                headers: headers
            }
        );
    }

    return null;
}
