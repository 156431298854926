export function DestinationChange(destination) {
    return {
        type: "HEADER_DESTINATION_CHANGE",
        payload: {
            destination: destination
        }
    }
}

export function DestinationListChange(destination_list) {
    return {
        type: "HEADER_DESTINATION_LIST_CHANGE",
        payload: {
            destination_list: destination_list
        }
    }
}

export function ToggleDateFocus(focus) {
    return {
        type: "HEADER_TOGGLE_DATE_FOCUS",
        payload: {
            focus: focus
        }
    }
}

export function ToggleDatePicker(datepicker) {
    return {
        type: "HEADER_TOGGLE_DATEPICKER",
        payload: {
            datepicker: datepicker
        }
    }
}

export function ToggleDatesHighlight(start_date_highlight, end_date_highlight) {
    return {
        type: "HEADER_TOGGLE_DATES_HIGHLIGHT",
        payload: {
            start_date_highlight: start_date_highlight,
            end_date_highlight: end_date_highlight
        }
    }
}

export function ToggleLanguage(show_language) {
    return {
        type: "HEADER_TOGGLE_LANGUAGE",
        payload: {
            show_language: show_language
        }
    }
}
export function ToggleModalVersion(show_version) {
    return {
        type: "HEADER_TOGGLE_VERSION",
        payload: {
            show_version: show_version
        }
    }
}
export function ToggleModalVersionAndId(show_version, trip_id, trip_version) {
    return {
        type: "HEADER_TOGGLE_VERSION_AND_ID",
        payload: {
            show_version: show_version,
            trip_id: trip_id,
            trip_version: trip_version
        }
    }
}

export function ToggleMenu(show_menu) {
    return {
        type: "HEADER_TOGGLE_MENU",
        payload: {
            show_menu: show_menu
        }
    }
}
export function TogglePassengers(show_passengers) {
    return {
        type: "HEADER_TOGGLE_PASSENGERS",
        payload: {
            show_passengers: show_passengers
        }
    }
}

export function ToggleAndSetLanguage(show_language, language) {
    return {
        type: "HEADER_TOGGLE_AND_SET_LANGUAGE",
        payload: {
            show_language: show_language,
            language: language
        }
    }
}