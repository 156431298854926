import React from "react";
import { EditorState } from "draft-js";
import { convertFromHTML } from 'draft-convert';
import decorator from "./decorator";

const convertToRGB = (hex: string) => {
    const r = "0x" + hex[1] + hex[2];
    const g = "0x" + hex[3] + hex[4];
    const b = "0x" + hex[5] + hex[6];
    return `rgb(${+r}, ${+g}, ${+b})`;
};

export function restoreRichEditorStateFromHtml(
    styles: {[index: string]: React.CSSProperties},
    html: string
): EditorState {
    const contentState = convertFromHTML({
        htmlToStyle: (nodeName, node, currentStyle) => {
            if (nodeName === 'span' && !!node.style.fontSize) {
                return currentStyle.add(`FONTSIZE_${parseInt(node.style.fontSize)}`);
            }

            if (nodeName === 'span' && !!node.style.color) {
                let color = '';
                for (const [key, value] of Object.entries(styles)) {
                    if (key.includes('COLOR_') && convertToRGB(value.color ?? '#000000') === node.style.color) {
                        color = key;
                    }
                }
                if (color !== '') {
                    return currentStyle.add(color);
                } 
                return currentStyle;
            }

            return currentStyle;
        },
        htmlToEntity: (nodeName, node, createEntity) => {
            if (nodeName === 'a') {
                const entityConfig: {
                    link?: string,
                    text?: string | null
                } = {};
                entityConfig.link = node.href ? node.getAttribute('href') || node.href : node.href;
                const doc = new DOMParser().parseFromString(node.textContent ?? '', "text/html");
                entityConfig.text = doc.documentElement.textContent;
                node.textContent = doc.documentElement.textContent;
                return createEntity(
                    'LINK',
                    'MUTABLE',
                    entityConfig
                );
            }
            if (nodeName === 'img') {
                const entityConfig: {
                    src?: string,
                    alt?: string,
                    height?: string,
                    width?: string
                } = {};
                entityConfig.src = node.getAttribute ? node.getAttribute('src') || node.src : node.src;
                entityConfig.alt = node.alt;
                entityConfig.height = node.style.height;
                entityConfig.width = node.style.width;

                return createEntity(
                    'IMAGE',
                    'MUTABLE',
                    entityConfig
                );
            }
        },
        htmlToBlock: (nodeName, node) => {
            let type = 'unstyled';
            
            switch (nodeName) {
                case 'h1': {
                    type = 'header-one';
                    break;
                }
                case 'h2': {
                    type = 'header-two';
                    break;
                }
                case 'h3': {
                    type = 'header-three';
                    break;
                }
                case 'h4': {
                    type = 'header-four';
                    break;
                }
                case 'h5': {
                    type = 'header-five';
                    break;
                }
                case 'h6': {
                    type = 'header-six';
                    break;
                }
                case 'blockquote': {
                    type = 'blockquote';
                    break;
                }
                case 'pre': {
                    type = 'code-block';
                    break;
                }
                case 'figure': {
                    type = 'atomic';
                    break;
                }
                case 'li': {
                    if (node.parentElement?.tagName.toLowerCase() === 'ul') {
                        type = 'unordered-list-item';
                    } else {
                        type = 'ordered-list-item';
                    }
                    break;
                }
                case 'p': {
                    type = 'paragraph';
                    break;
                }
                default: {
                    return;
                }
            }
            return {
                type,
                data: node.style.textAlign.length > 0 ?
                    { alignment: node.style.textAlign } :
                    {}
            };
        }
    })(html);
    return EditorState.createWithContent(contentState, decorator);
}
