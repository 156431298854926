import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Divider,
    Stack,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography
} from "@mui/material";
import { CalendarToday, Place } from "@mui/icons-material";
import GetPricesId from "../Cart/Functions/Margin/GetPricesId";
import ConvertPrice from "../Cart/Functions/Manual/ConvertPrice";
import { AccommodationCart } from "./objects/accommodationCart";
import { ManualProductAccommodationCart } from "./objects/manualProductAccommodationCart";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    accommodations: ({
        type: 'normal',
        accommodation: AccommodationCart
    } | {
        type: 'manual',
        accommodation: ManualProductAccommodationCart
    })[]
} & Pick<TooltipProps, 'children'>

export function ItineraryStepItemAccommodationTooltip(props: Props): JSX.Element {
    const { t } = useTranslation();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const currencies = useSelector((state: AppState) => state.base.currency_list);
    const currency = useSelector((state: AppState) => state.trip.currency);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const user = useSelector((state: AppState) => state.user.user);

    return (
        <LightTooltip
            disableHoverListener={props.accommodations.length === 0}
            title={
                props.accommodations.map(({ type, accommodation }) => {
                    if (type === 'manual') {
                        let resort_currency = currencies.find((currency) => {
                            return currency.id === accommodation.resort_fees_currency;
                        });
                        return (
                            <Box key={accommodation.id} sx={{ padding: 2.5 }}>
                                <Stack direction="row" justifyContent="space-between">
                                    <Box sx={{ width: '60%' }}>
                                        <Typography variant="h6" color="#888" gutterBottom>
                                            {accommodation.name}
                                            {
                                                accommodation.is_optional &&
                                                <Box component="span" sx={{ color: '#E6592F' }}>
                                                    {t<string>('cart.optional_product')}
                                                </Box>
                                            }
                                        </Typography>
                                        {
                                            accommodation.stars !== 0 &&
                                            new Array(accommodation.stars).fill(null).map((_, index) => (
                                                <i key={index} className={ "icon-045-star" } style={{ fontSize: '20px' }} />
                                            ))
                                        }
                                    </Box>
                                </Stack>
                                <Divider sx={{ marginBottom: 1 }} />
                                {
                                    accommodation.address &&
                                    <TextWithIcon variant="body2" gutterBottom>
                                        <Place />
                                        { accommodation.address}
                                    </TextWithIcon>
                                }
                                <TextWithIcon variant="body2" sx={{ marginBottom: 2.5 }}>
                                    <>
                                        <CalendarToday />
                                        <span>
                                            { t<string>("cart.from") }{' '}
                                            {
                                                window.moment.utc(accommodation.start_date).format("DD MMM")
                                            }{' '}
                                            { t<string>("cart.to") }{' '}
                                            {
                                                window.moment.utc(accommodation.end_date).format("DD MMM")
                                            }
                                        </span>
                                    </>
                                </TextWithIcon>
                                <p>
                                    <i
                                        className={
                                            (accommodation.breakfast_included !== undefined && accommodation.breakfast_included !== null && accommodation.breakfast_included) ?
                                                "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view green ft-pr5" :
                                                "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view ft-pr5"
                                        }
                                    />
                                    {
                                        (accommodation.breakfast_included !== undefined && accommodation.breakfast_included !== null && accommodation.breakfast_included) ?
                                            t<string>("accommodation.breakfast_included") :
                                            t<string>("accommodation.breakfast_not_included")
                                    }
                                </p>
                                <p>
                                    <i
                                        className={
                                            (accommodation.lunch_included !== undefined && accommodation.lunch_included !== null && accommodation.lunch_included) ?
                                                "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view green ft-pr5" :
                                                "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view ft-pr5"
                                        }
                                    />
                                    {
                                        (accommodation.lunch_included !== undefined && accommodation.lunch_included !== null && accommodation.lunch_included) ?
                                            t<string>("accommodation.lunch_included") :
                                            t<string>("accommodation.lunch_not_included")
                                    }
                                </p>
                                <p>
                                    <i
                                        className={
                                            (accommodation.dinner_included !== undefined && accommodation.dinner_included !== null && accommodation.dinner_included) ?
                                                "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view green ft-pr5" :
                                                "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view ft-pr5"
                                        }
                                    />
                                    {
                                        (accommodation.dinner_included !== undefined && accommodation.dinner_included !== null && accommodation.dinner_included) ?
                                            t<string>("accommodation.dinner_included") :
                                            t<string>("accommodation.dinner_not_included")
                                    }
                                </p>
                                {
                                    accommodation.room_types?.map((room, index_room) => {
                                        if (room !== "" && room !== null) {
                                            return (
                                                <p key={ index_room }>{ t<string>("home.modal.room") + " " + (index_room + 1) + " : " + room }</p>
                                            );
                                        }
                                    })
                                }
                                <p>{ accommodation.description }</p>
                                <p>{ accommodation.custom_information }</p>
                                {
                                    (accommodation.resort_fees && accommodation.resort_fees !== "0.00") && (
                                        <>
                                            <i className={ "icon-warning" }/>
                                            {
                                                t<string>("cart.resort_fees_text_1") + " " + (accommodation.resort_fees_currency === null ?
                                                    Intl.NumberFormat(
                                                        language,
                                                        {
                                                            style: "currency",
                                                            currency: currency.iso_code
                                                        }
                                                    ).format(Math.ceil(parseFloat(accommodation.resort_fees))) :
                                                    (
                                                        Intl.NumberFormat(
                                                            language,
                                                            {
                                                                style: "currency",
                                                                currency: resort_currency?.iso_code ?? 'EUR'
                                                            }
                                                        ).format(Math.ceil(parseFloat(accommodation.resort_fees))) + " (" + t<string>("cart.resort_fees_text_2") +
                                                        Intl.NumberFormat(
                                                            language,
                                                            {
                                                                style: "currency",
                                                                currency: currency.iso_code
                                                            }
                                                        ).format(
                                                            Math.ceil(parseFloat(ConvertPrice(parseFloat(accommodation.resort_fees), resort_currency, currency, null)))
                                                        )
                                                    ) + ")") + " " + t<string>("cart.resort_fees_text_3") }
                                        </>
                                    )
                                }
                            </Box>
                        );
                    }

                    let provider = providers.find((item) => {
                        return item.provider.id === accommodation.provider;
                    });
                    const nightsCount = window.moment.utc(
                        accommodation.end_date
                    ).diff(
                        window.moment.utc(accommodation.start_date),
                        'days',
                        true
                    );
                    const totalResortFees = !accommodation.resort_fees_included ?
                        (
                            accommodation.rooms.reduce((prev, current) => {
                                return prev + parseFloat(current.resort_fees);
                            }, 0) ?? 0
                        ) :
                        0;
                    const resortCurrency = currencies.find((currency) => {
                        return currency.id === accommodation.resort_fees_currency_id;
                    });
                    let price = Math.ceil(
                        parseFloat(
                            accommodation.prices[
                                GetPricesId(
                                    accommodation.prices ?? [],
                                    user?.client ?? null,
                                    user ?? null,
                                    null
                                )
                            ]?.selling_price ?? '0'
                        )
                    );
                    price += price * (accommodation.rooms.length ?? 0);
                    const hasProvider = provider && (user?.client_full?.type !== 2 || JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code === 'verdie');
                    return (
                        <Box sx={{ padding: 2.5 }}>
                            <Stack direction="row" justifyContent="space-between">
                                <Box sx={{ width: hasProvider ? '60%' : '100%' }}>
                                    <Typography variant="h6" color="#888" gutterBottom>
                                        {accommodation.hotel[0]?.name}
                                        {
                                            accommodation.is_optional &&
                                            <Box component="span" sx={{ color: '#E6592F' }}>
                                                {t<string>('cart.optional_product')}
                                            </Box>
                                        }
                                    </Typography>
                                    {
                                        accommodation.hotel[0]?.stars &&
                                        new Array(accommodation.hotel[0].stars).fill(null).map((_, index) => (
                                            <i key={index} className={ "icon-045-star" } style={{ fontSize: '20px' }} />
                                        ))
                                    }
                                </Box>
                                {
                                    hasProvider &&
                                    <Box sx={{ width: '30%' }}>
                                        <img
                                            src={
                                                provider?.provider.logo?.thumbnail_little ?? provider?.provider.logo?.url ?? ''
                                            }
                                            alt={provider?.provider.name}
                                            width="100%"
                                            height="auto"
                                        />
                                    </Box>
                                }
                            </Stack>
                            <Divider sx={{ marginBottom: 1 }} />
                            <TextWithIcon variant="body2" gutterBottom>
                                <Place />
                                { accommodation.hotel[0]?.city_name}
                            </TextWithIcon>
                            <TextWithIcon variant="body2" gutterBottom>
                                <>
                                    <CalendarToday />
                                    <span>
                                        { t<string>("cart.from") }{' '}
                                        {
                                            window.moment.utc(accommodation.start_date).format("DD MMM")
                                        }{' '}
                                        { t<string>("cart.to") }{' '}
                                        {
                                            window.moment.utc(accommodation.end_date).format("DD MMM")
                                        }{' '}
                                        <strong>
                                            {
                                                nightsCount > 1 ?
                                                    `${nightsCount} ${t('itinerary.nights')}` :
                                                    `${nightsCount} ${t('itinerary.night')}`
                                            }
                                        </strong>
                                    </span>
                                </>
                            </TextWithIcon>
                            {
                                accommodation && (!accommodation.rooms || accommodation.rooms.length === 0) &&
                                <>
                                    {
                                        accommodation.check_in && accommodation.check_out && (
                                            <>
                                                <p>{ `${t<string>("accommodation.check_in")} ${accommodation.check_in}` }</p>
                                                <p>{`${t<string>("accommodation.check_out")} ${accommodation.check_out}` }</p>
                                            </>
                                        )
                                    }
                                    {
                                        accommodation.bed_type && 
                                        <p><i className={"icon-053-bed ft-pr5"} />{ accommodation.bed_type }</p>
                                    }
                                </>
                            }
                            {
                                accommodation.rooms.map((description, index_description) => {
                                    return (
                                        <React.Fragment key={ index_description }>
                                            {
                                                description.check_in !== undefined && description.check_in !== null && description.check_out !== undefined && description.check_out !== null && (
                                                    <React.Fragment>
                                                        <p>{ `${t<string>("accommodation.check_in")} ${description.check_in}` }</p>
                                                        <p>{`${t<string>("accommodation.check_out")} ${description.check_out}` }</p>
                                                    </React.Fragment>
                                                )
                                            }
                                            {
                                                description.bed_type !== null && (
                                                    <p><i className={"icon-053-bed ft-pr5"} />{ description.bed_type }</p>
                                                )
                                            }
                                            <p>
                                                <i
                                                    className={
                                                        (description.breakfast_included !== undefined && description.breakfast_included !== null && description.breakfast_included) ?
                                                            "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view green ft-pr5" :
                                                            "icon-hot-coffee-rounded-cup-on-a-plate-from-side-view ft-pr5"
                                                    }
                                                />
                                            </p>
                                            <p>
                                                {
                                                    Intl.NumberFormat(
                                                        language,
                                                        {
                                                            style: 'currency',
                                                            currency: currency.iso_code
                                                        }
                                                    ).format(
                                                        Math.ceil(
                                                            parseFloat(
                                                                description.prices[
                                                                    GetPricesId(description.prices ?? [], user?.client ?? null, user ?? null, null)
                                                                ]?.selling_price ??
                                                    '0'
                                                            )
                                                        )
                                                    )
                                                }
                                            </p>
                                        </React.Fragment>
                                    );
                                })
                            }
                            {
                                accommodation.resort_fees &&
                                accommodation.resort_fees !== '0.00' &&
                                !accommodation.resort_fees_included &&
                                accommodation.resort_fees_mandatory &&
                                <p>
                                    <Box sx={{ marginRight: 1 }} component="span">
                                        <i className={ "icon-warning" }/>
                                    </Box>
                                    {
                                        t<string>("cart.resort_fees_text_1") + " " +
                                        (
                                            accommodation.resort_fees_currency_id === null ?
                                                Intl.NumberFormat(
                                                    language,
                                                    {
                                                        style: 'currency',
                                                        currency: currency.iso_code
                                                    }
                                                ).format(Math.ceil(totalResortFees)) :
                                                (
                                                    Intl.NumberFormat(
                                                        language,
                                                        {
                                                            style: 'currency',
                                                            currency: resortCurrency?.iso_code ?? 'EUR'
                                                        }
                                                    ).format(Math.ceil(totalResortFees)) + " (" + t<string>("cart.resort_fees_text_2") +
                                                    Intl.NumberFormat(
                                                        language,
                                                        {
                                                            style: 'currency',
                                                            currency: currency.iso_code
                                                        }
                                                    ).format(
                                                        Math.ceil(
                                                            parseFloat(
                                                                ConvertPrice(
                                                                    parseFloat(accommodation.resort_fees),
                                                                    resortCurrency,
                                                                    currency,
                                                                    null
                                                                )
                                                            )
                                                        )
                                                    )
                                                ) + ")") + " " + t<string>("cart.resort_fees_text_3")
                                    }
                                </p>
                            }
                            {
                                accommodation.resort_fees &&
                                accommodation.resort_fees !== '0.00' &&
                                !accommodation.resort_fees_included &&
                                !accommodation.resort_fees_mandatory &&
                                <p>
                                    <Box sx={{ marginRight: 1 }} component="span">
                                        <i className={ "icon-warning" }/>
                                    </Box>
                                    {
                                        t<string>("cart.resort_fees_text_1_v2") + " " +
                                        (
                                            accommodation.resort_fees_currency_id === null ?
                                                Intl.NumberFormat(
                                                    language,
                                                    {
                                                        style: 'currency',
                                                        currency: currency.iso_code
                                                    }
                                                ).format(Math.ceil(totalResortFees)) :
                                                (
                                                    Intl.NumberFormat(
                                                        language,
                                                        {
                                                            style: 'currency',
                                                            currency: resortCurrency?.iso_code ?? 'EUR'
                                                        }
                                                    ).format(Math.ceil(totalResortFees)) + " (" + t<string>("cart.resort_fees_text_2") +
                                                    Intl.NumberFormat(
                                                        language,
                                                        {
                                                            style: 'currency',
                                                            currency: currency.iso_code
                                                        }
                                                    ).format(
                                                        Math.ceil(
                                                            parseFloat(
                                                                ConvertPrice(
                                                                    parseFloat(accommodation.resort_fees),
                                                                    resortCurrency,
                                                                    currency,
                                                                    null
                                                                )
                                                            )
                                                        )
                                                    )
                                                ) + ")") + " " + t<string>("cart.resort_fees_text_4") }
                                </p>
                            }
                            {
                                accommodation.custom_information &&
                                 <p>{ t<string>("cart.custom_information") }</p>
                            }
                            {
                                price > 0 &&
                                <>
                                    {
                                        accommodation.on_request && (
                                            <p>{ t<string>("accommodation.on_request") }</p>
                                        )
                                    }
                                    <p>
                                        {
                                            Intl.NumberFormat(
                                                language,
                                                {
                                                    style: 'currency',
                                                    currency: currency.iso_code
                                                }
                                            ).format(Math.ceil(price))
                                        }
                                    </p>
                                </>
                            }
                        </Box>
                    );
                })
            }
            arrow
            PopperProps={{
                disablePortal: true
            }}
        >
            {props.children}
        </LightTooltip>
    );
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        maxWidth: 420,
        minWidth: 300
    },
    [`& .${tooltipClasses.arrow}`]: {
        "color": theme.palette.common.white,
        '&:before': {
            border: '1px solid #ccc'
        }
    }
}));

const TextWithIcon = styled(Typography)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: 10
})) as typeof Typography;
