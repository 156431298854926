// Dependencies
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Core & Lab
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
// Actions
import { updateDescription } from '../../Actions/Pictures';

const CartPictureDescription = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const description = useSelector((state) => state.pictures.description);
    const description_error = useSelector((state) => state.pictures.description_error);
    const onDescriptionChange = (event) => {
        dispatch(updateDescription(event.target.value));
    };
    return (
        <FormControl fullWidth required variant={ 'outlined' } error={ description_error !== '' }>
            <InputLabel htmlFor={ 'picture-description' }>{ t('global.description') }</InputLabel>
            <OutlinedInput
                id={ 'picture-description' }
                type={ 'text' }
                onChange={ onDescriptionChange }
                label={ t('global.description') }
                value={ description }
                autoComplete={ 'off' }
            />
            <FormHelperText>{ description_error }</FormHelperText>
        </FormControl>
    );
};

export default CartPictureDescription;