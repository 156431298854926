import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from "notistack";
import { makeStyles } from '@material-ui/core';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import green from "@material-ui/core/colors/green";

import FlightCartRequest from '../Functions/FlightCartRequest';

const useStyles = makeStyles({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight500:{
        fontWeight: 500
    },
    fontWeight900:{
        fontWeight: 900
    },
    fontSize12:{
        fontSize: 12
    },
    fontSize14:{
        fontSize: 14
    },
    fontSize18:{
        fontSize: 18
    },
    upperCase:{
        textTransform: "uppercase",
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    genericPaddingTop: {
        paddingTop: 20
    },
    returnButton: {
        color: "#0000008A",
        backgroundColor: "#FFFFFF",
    },
    genericButton: {
        color: "#FFFFFF",
        backgroundColor: "#E6592F"
    },
    fullWidth: {
        width: "100%"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PriceVariation = ({ flight, setBackToList, router }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const smallScreen = useMediaQuery('(max-width:425px)');

    const end_date = useSelector(store => store.trip.end_date);
    const trip_currency = useSelector(store => store.trip.currency);
    const filter_time = useSelector(store => store.flight_search.filter_time);
    const flight_groups = useSelector(store => store.flight.flight_groups);
    const index_group = useSelector(store => store.flight.index_group);
    const price_variation = useSelector(store => store.flight_search.price_variation);
    const toggle_add_to_cart = useSelector(store => store.flight_search.toggle_add_to_cart);
    const flight_providers = useSelector(store => store.flight_search.providers);
    const trace_id = useSelector(store => store.flight_search.trace_id);
    const user = useSelector(store => store.user.user);
    const [addingToCart, setAddingToCart] = useState(false);
    const [returnToList, setReturnToList] = useState(false);

    useEffect(() => {
        if(addingToCart){
            FlightCartRequest(flight_groups, flight_providers, price_variation.group_passenger, index_group, flight_groups.length - 1, trip_currency, flight, trace_id, end_date, filter_time, "cart", user, enqueueSnackbar, dispatch, t, router);
        }
    }, [addingToCart])

    useEffect(() => {
        if (returnToList){
            setBackToList(true);
        }
    }, [returnToList])

    return(
        <Dialog open={toggle_add_to_cart} fullScreen={smallScreen ? true : false} TransitionComponent={Transition} fullWidth maxWidth={"sm"}>
            <DialogTitle>
                <Grid className={`${classes.genericText} ${smallScreen ? classes.textCenter : ""} ${classes.upperCase} ${classes.fontWeight900} ${!smallScreen ? classes.fontSize18 : classes.fontSize12} ${classes.letterSpacing}`}>
                    { t("flight.price_variation") }
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container alignItems="center" justify="space-between">
                    <Grid item className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.letterSpacing}`}>
                        { t("flight.price_change") }
                    </Grid>
                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.letterSpacing}`}>
                        { price_variation.variation + " " }
                        { trip_currency.symbol }
                    </Grid>
                </Grid>
                <Grid container direction={smallScreen ? "column" : "row"} alignItems={smallScreen ? "flex-start" : "center"} justify={"space-between"} spacing={smallScreen ? 2 : 0} className={classes.genericPaddingTop}>
                    <Grid item className={smallScreen ? `${classes.fullWidth} ${classes.textCenter}` : ""}>
                        <Button variant="contained" className={classes.returnButton} disabled={returnToList} onClick={() => setReturnToList(true)}>
                            { t("flight_search.go_to_flight_list") }
                            { returnToList && <CircularProgress size={20} className={classes.buttonProgress} /> }
                        </Button>
                    </Grid>
                    <Grid item className={smallScreen ? `${classes.fullWidth} ${classes.textCenter}` : ""}>
                        <Button variant="contained" className={classes.genericButton} disabled={addingToCart} onClick={() => setAddingToCart(true)}>
                            { t("flight_search.add_to_cart") }
                            { addingToCart && <CircularProgress size={20} className={classes.buttonProgress} /> }
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default React.memo(PriceVariation);