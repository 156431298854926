import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AssignmentIcon from '@material-ui/icons/Assignment';

import { useSnackbar } from "notistack";
import clsx from "clsx";
import axios from "axios";

import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import GetStatusColorHexa from "../Menu/MaterialTripList/Functions/GetStatusColorHexa";
import { data } from "jquery";

const useStyles = makeStyles(theme => ({
    whiteButton: {
        backgroundColor: "white",
        color: "black",
        fontWeight: "bold"
    },
    customSize: {
        height: 41,
        marginTop: 4
    },
    formControlRoot: {
        borderRadius: "20px",
        padding: 0,
        position: "relative",
        textTransform: "capitalize",
        backgroundColor: "white",
        color: "black",
        fontWeight: "bold",
        display: "block",
        border: 'none'
        //boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)'
  	},
    fiber: {
        marginRight: 10,
        verticalAlign: "top"
    },
    fiberResponsive: {
        verticalAlign: "middle",
        fontSize: 12
    },
    responsiveSize: {
        fontSize: 10
    },
    lineHeight10: {
        lineHeight: '10px'
    },
    staging: {
        backgroundColor: '#ffcc80'
    }
}));

const RenderFileNb = ({ setIsOpen, router, data_trip, all_data }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.between(960, 1258));
    const is_staging = document.location.hostname.split('.')[2] === 'fr' && document.location.hostname !== 'test-aws.facilitatrip.fr';

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const trip_reference = useSelector((store) => {
        return store.trip.trip_reference;
    });
    const GetTripById = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/`
            }).then(function (response) {
                dispatch({
                    type: "MENU_SET_TRIP_INFO",
                    payload: {
                        trip_info: response.data
                    }
                });
                router.push(`/${window.url_name}/menu/trip-info/${GetCookie("trip_id")}`);
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
            });
        }
    };
    let nb_version = null;
    all_data.data.map((data, data_index) => {
        if (data.id === data_trip.id) {
            nb_version = data_index + 1;
        }
    });
    return (
        <Fragment>
            <Tooltip title={ t('cart.nb_file_tooltip') } >
                <Button className={clsx(classes.formControlRoot, { [classes.responsiveSize]: smallScreen, [classes.lineHeight10]: ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines'].includes(quotation_code), [classes.staging]: is_staging })} variant={"outlined"} fullWidth disableRipple={true} onClick={() => {
                    setIsOpen(null);
                    if (parseFloat(GetCookie("trip_id_version")) !== data_trip.trip.current_version) {
                        dispatch({
                            type: "MENU_TO_SHOW_VERSION",
                            payload: {
                                to_show_version: parseFloat(GetCookie("trip_id_version"))
                            }
                        });
                    }
                    GetTripById();
                }}>
                    {
                        !['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'tropicalementvotre'].includes(quotation_code) && (
                            <FiberManualRecordIcon style={{ color: GetStatusColorHexa(data_trip.status) }} className={clsx({ [classes.fiber]: !smallScreen, [classes.fiberResponsive]: smallScreen })}/>
                        )
                    }
                    {
                        ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'tropicalementvotre'].includes(quotation_code) && (
                            <Fragment>
                                {
                                    data_trip.status_contract !== null && (
                                        <Fragment>
                                            <ReceiptIcon fontSize={"small"} style={{ verticalAlign: "2px", fontSize: 15 }}/>
                                            <FiberManualRecordIcon style={{ color: GetStatusColorHexa(data_trip.status_contract), fontSize: 19 }} />
                                        </Fragment>
                                    )
                                }
                                {
                                    data_trip.status_contract === "CONFIRMED" && data_trip.status_modification !== null && (
                                        <Fragment>
                                            {
                                                data_trip.status_modification === "AMENDMENT" && (
                                                    <SettingsIcon fontSize={"small"} style={{ verticalAlign: "2px", fontSize: 15 }}/>
                                                )
                                            }
                                            {
                                                data_trip.status_modification === "FIXED_PV" && (
                                                    <AssignmentTurnedInIcon fontSize={"small"} style={{ verticalAlign: "2px", fontSize: 15 }}/>
                                                )
                                            }
                                            <FiberManualRecordIcon style={{ color: GetStatusColorHexa(data_trip.status_modification), fontSize: 19 }} />
                                        </Fragment>
                                    )
                                }
                                {
                                    data_trip.status_contract === "CONFIRMED" && data_trip.status_modification === "FIXED_PV" && data_trip.status_booking_progress !== null && (
                                        <Fragment>
                                            <AssignmentIcon fontSize={"small"} style={{ verticalAlign: "2px", fontSize: 15 }}/>
                                            <FiberManualRecordIcon style={{ color: GetStatusColorHexa(data_trip.status_booking_progress), fontSize: 19 }} />
                                        </Fragment>
                                    )
                                }
                            </Fragment>
                        )
                    }
                    {
                        !['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'tropicalementvotre'].includes(quotation_code) && (
                            <span>{"N° " + trip_reference}</span>
                        )

                    }
                    {
                        ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'tropicalementvotre'].includes(quotation_code) && (
                            <div>{"N° " + trip_reference} {"V" + nb_version}</div>
                        )
                    }
                    {
                        !['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'tropicalementvotre'].includes(quotation_code) && (
                            <div style={{ marginTop: "-3px", fontWeight: 100 }}>
                                {"V" + nb_version}
                            </div>
                        )
                    }
                    {
                        ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines'].includes(quotation_code) && data_trip.status_modification === "AMENDMENT" && (
                            <div style={{ fontWeight: 100 }}>
                                {t("header.amendment")}
                            </div>
                        )
                    }
                </Button>
            </Tooltip>
        </Fragment>
    );
};
export default React.memo(RenderFileNb);
