import React from "react";
import { Divider } from "@mui/material";

export function insertDivider(items: (JSX.Element | false | null | '')[]): JSX.Element[] {
    return items.filter((item): item is JSX.Element => {
        return !!item;
    }).reduce((prev, current, index, array) => {
        if (array.length > 1 && index !== array.length - 1) {
            return prev.concat(
                [
                    current,
                    <Divider
                        orientation="vertical"
                        flexItem
                    /> 
                ]
            );
        }
        return prev.concat([current]);
    }, [] as JSX.Element[]);
}
