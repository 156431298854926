// Dependencies
import React from 'react';
import { useSnackbar } from 'notistack';
// Core
import IconButton from '@material-ui/core/IconButton';
// Icons
import Close from '@material-ui/icons/Close';

const CloseSnackbar = ({key}) => {
    const { closeSnackbar } = useSnackbar();
    return (
        <IconButton onClick={() => closeSnackbar(key)}>
            <Close style={{color: 'white'}}/>
        </IconButton>
    );
};

export default CloseSnackbar;