import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import axios from "axios";

export default (client, userList, dispatch) => {
   let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client.id}/user/`,
        }).then(function (response) {
            let data = response.data.results.slice();
            data.forEach(element => {
                element.label = `${element.first_name} ${element.last_name}`
            });
            dispatch({
                type: "MENU_SET_USER_AGENCE_LIST",
                payload: {
                    userAgenceList: data
                }
            });
            dispatch({
                type: "MENU_SET_CREATOR_LIST",
                payload: {
                    creatorList: userList.concat(data)
                }
            });
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }   
}
