export default (type) => {
    switch (type) {
        case 0 :
            return "018-hotel";
        case 1 :
            return "064-hiking";
        case 2 :
            return "100-car-compact";
        case 3 :
            return "restaurants";
        case 4 :
            return "tp-attribute-chauffer-transfer";
        case 5:
            return "train";
        case 6 :
            return "020-flight";
        case 7 :
            return "018-hotel";
        case 8 :
            return "unknown";
        case 9 :
            return "ferry";
        case 10 :
            return "user-silhouette";
        case 11 :
            return "unknown";
        case 12 :
            return "023-visits";
        case 13 :
            return "ferry";
    } 
    return null;
};
