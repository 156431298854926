//Dependencies
import React from 'react';
//Core & Lab
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1]
    },
    [`& .${tooltipClasses.arrow}`]: {
        "color": theme.palette.common.white,
        '&:before': {
            border: '1px solid rgba(100, 100, 100, 0.25)'
        }
    }
}));

export default LightTooltip;
