export default (a) => {
    if (!moment.isMoment(a)) return false;
    const b = moment.utc().add(1, "d");
    const aYear = a.year();
    const aMonth = a.month();
    const bYear = b.year();
    const bMonth = b.month();
    if (aYear === bYear) {
        if (aMonth === bMonth)
            return a.date() < b.date();
        return aMonth < bMonth;
    }
    return aYear < bYear;
}