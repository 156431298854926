export default (type) => {
    switch (type) {
        case 0 :
            return "blue";
        case 1 :
            return "green";
        case 2 :
            return "gray";
        case 3 :
            return "orange";
        case 4 :
            return "gray";
        case 5:
            return "purple";
        case 6 :
            return "cyan";
        case 7 :
            return "blue";
        case 8 :
            return "black";
        case 9 :
            return "lightblue";
        case 10 :
            return "pink";
        case 11 :
            return "black";
        case 12 :
            return "lightgreen";
        case 13 :
            return "lightblue";
    } 
    return null;
};
