import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useShowError } from "../Utils/showError";
import { useSendProviderBookingResponse } from "./network/sendProviderBookingResponse";

type Props = {
    open: boolean,
    version: number,
    providerId: number,
    onClose: () => void
}

export function ProviderBookingContinueModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const showError = useShowError();
    const notify = useSendProviderBookingResponse({
        onTrigger() {
            setLoading(true);
        },
        onSuccess() {
            enqueueSnackbar(
                t<string>('cart-material.provider-booking-send-success'),
                { variant: 'success' }
            );
            props.onClose();
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });

    const onConfirm = () => {
        notify(props.version, props.providerId);
    };

    const onClose = () => {
        if (!loading) {
            props.onClose();
        }
    };

    return (
        <Dialog open={props.open} onClose={onClose}>
            <DialogTitle>
                {t<string>('cart-material.provider-booking-confirmation-title')}
            </DialogTitle>
            <DialogContent>
                <Typography textAlign="center">
                    {t<string>('cart-material.provider-booking-confirmation-hint')}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    disabled={loading}
                >
                    {t<string>('shared.cancel')}
                </Button>
                <LoadingButton
                    variant="outlined"
                    onClick={onConfirm}
                    loading={loading}
                >
                    {t<string>('cart-material.provider-quotation-confirm')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
