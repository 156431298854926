import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { withRouter } from "react-router";
import moment from "moment";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";

import { SelectFlight, SetOptionLoaded, SetSeatsLoaded, ToggleAddToCart } from "../../../Actions/FlightSearch";

import CountAgeStr from "../Functions/CountAgeStr";

import Transition from "../../Common/Transition";
import FlightGroups from "../FlightGroups/FlightGroups";
import OutboundInfo from "./OutboundInfo";
import DetailedPriceButton from "../FlightSearch/DetailedPriceButton";
import FlightStepper from "./FlightStepper";
import AddLuggage from "./AddLuggage";
import CancellationRules from "./CancellationRules";
import FlightRecap from "./FlightRecap";
import PriceVariation from "./PriceVariation";
import FlightSeats from "./FlightSeats";
import GetSeats from "../Functions/GetSeats";
import { CartConstructionReplaceProductContext } from "../../CartMaterial/utils/cartConstructionReplaceProductContext";
import clsx from "clsx";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight700: {
        fontWeight: 700
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize9: {
        fontSize: 9
    },
    fontSize12: {
        fontSize: 12
    },
    black80: {
        color: '#000000CC'
    },
    orange: {
        color: `${main_color}`
    },
    uppercase: {
        textTransform: "uppercase"
    },
    textCenter: {
        textAlign: "center"
    },
    genericBorder: {
        background: "#FFFFFF",
        border: "1px solid #DDDDDD",
        boxSizing: "border-box"
    },
    spacer: {
        padding: 6
    },
    horizontalSpacer: {
        padding: "0px 16px"
    },
    horizontalContainerSpacer: {
        padding: "0px 14px"
    },
    editButton: {
        border: `2px solid ${main_color}`,
        background: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: 4,
        padding: 6
    },
    formShadow: {
        borderRadius: 4,
        // boxShadow: '0 1px 6px rgba(32,33,36,0.28)',
        margin: "16px 4px"
    },
    spaceBetween: {
        justifyContent: 'space-between'
    }
}));

const FlightSelected = withRouter(({ router }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    const language = useSelector(store => store.header.language);
    const end_date = useSelector(store => store.trip.end_date);
    const trip_currency = useSelector(store => store.trip.currency);
    const flight_groups = useSelector(store => store.flight.flight_groups);
    const index_group = useSelector(store => store.flight.index_group);
    const flight_list_render = useSelector(store => store.flight_search.flight_list_render);
    const option_loaded = useSelector(store => store.flight_search.option_loaded);
    const selected_flight = useSelector(store => store.flight_search.selected_flight);
    const currency = useSelector(store => store.flight_search.currency);
    const airports = useSelector(store => store.flight_search.airports);
    const airlines = useSelector(store => store.flight_search.airlines);
    const stations = useSelector(store => store.flight_search.stations);
    const trace_id = useSelector(store => store.flight_search.trace_id);
    const seats_by_segment = useSelector(store => store.flight_search.seats_by_segment);

    const [flight, setFlight] = useState(null);
    const [backToList, setBackToList] = useState(false);
    const [displayFlights, setDisplayFlights] = useState(false);
    const [editSearch, setEditSearch] = useState(false);
    const [searched_group, setSearchGroup] = useState(null);
    const [flightStep, setFlightStep] = useState(2);
    const [luggageOptions, setLuggageOptions] = useState([]);

    const replaceProductContext = useContext(CartConstructionReplaceProductContext);

    const getPricePerPax = () => {
        return (parseFloat(flight.total_price) / searched_group.travelers.length).toFixed(2);
    };

    useEffect(() => {
        let copy_group = Object.assign({}, flight_groups[index_group]);
        let current_flight = flight_list_render.find((item) => item.id === selected_flight);
        setSearchGroup(copy_group);
        if (current_flight !== undefined && current_flight.options !== undefined && current_flight.options !== null) {
            let luggage_options = [];
            console.log('current_flight:', current_flight);
            if (current_flight.options !== null) {
                current_flight.options.fares.map((fare) => {
                    fare.more_option.map((option) => {
                        if (['baggage', 'bags'].includes(option.option_type.toLowerCase())) {
                            luggage_options.push(option);
                        }
                    });
                });
            }
            if (luggage_options.length === 0) {
                let temp_flight_step = 5;
                current_flight.options.fares.map((fare) => {
                    if (fare.refund_detail !== null && fare.cancel_info !== null) {
                        //go to cancellation rules
                        temp_flight_step = 4;
                    }
                });
                if (seats_by_segment.length !== 0) {
                    temp_flight_step = 3;
                }
                setFlightStep(temp_flight_step);
            }
            setLuggageOptions(luggage_options);
        }
    }, []);

    useEffect(() => {
        if (flightStep === 1) {
            setBackToList(true);
        }
    }, [flightStep]);

    useEffect(() => {
        if (backToList) {
            setTimeout(() => {
                dispatch(SelectFlight(null));
                dispatch(SetOptionLoaded(false));
                dispatch(SetSeatsLoaded(false));
                dispatch(ToggleAddToCart(false));
                if (searched_group.journey_type !== 4) {
                    router.push(`/${window.url_name}/apps/flight-search/true`);
                } else {
                    router.push(`/${window.url_name}/apps/flight-groups`);
                }
            }, 200);
        }
    }, [backToList]);

    useEffect(() => {
        if (flight_list_render.length > 0 && selected_flight !== null) {
            let current_flight = flight_list_render.find((item) => item.id === selected_flight);
            if (current_flight !== undefined) {
                setFlight(current_flight);
            }
        } else {
            setFlight(null);
            setFlightStep(2);
            setEditSearch(false);
        }
    }, [flight_list_render]);

    useEffect(() => {
        if (searched_group !== null && flight_groups[index_group].travelers.length === searched_group.travelers.length) {
            setSearchGroup(flight_groups[index_group]);
        }
    }, [flight_groups]);

    useEffect(() => {
        if (replaceProductContext.enableReplace) {
            switch (flightStep) {
                case 3: {
                    replaceProductContext.onHideNextButton();
                    break;
                }
                case 4: {
                    replaceProductContext.onIsFinalStep();
                    replaceProductContext.onShowNextButton();
                    break;
                }
            }
        }
    }, [flightStep]);
    const backToListFunc = () => {
        dispatch({type: 'FLIGHT_RESET_SEATS'})
        setBackToList(true);
    }
    return (
        <Container maxWidth={ "lg" } disableGutters={isHandHeld ? true : false}>
            <Paper elevation={0} variant={isHandHeld ? "elevation" : "outlined"} className={isHandHeld ? "" : classes.formShadow}>
                <Grid className={classes.spacer}>
                    {
                        searched_group !== null && !replaceProductContext.enableReplace && (
                            <Grid>
                                <IconButton className={classes.genericBorder} size="small" onClick={() => setFlightStep(1)}>
                                    { backToList ? <CircularProgress size={14} style={{ color: '#4CAF50' }} /> : <NavigateBeforeIcon style={{ fontSize: 14 }} /> }
                                </IconButton>
                                <Button onClick={() => setFlightStep(1)}>
                                    <span className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                        { searched_group.journey_type === 4 ? t("flight_search.go_to_flight_groups") : t("flight_search.go_to_flight_list") }
                                    </span>
                                </Button>
                            </Grid>
                        )
                    }
                    <Grid container alignItems="center" justify="flex-start" spacing={3} style={{ padding: "0px 30px 6px" }}>
                        <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`} >
                            { t("flight.your_selection")}
                        </Grid>
                        {
                            JSON.parse(localStorage.getItem("config")).quotation_code === "visiteurs" && flight !== null && flight.options !== null && flight.options.fares[0].latest_ticketing !== undefined && (
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`} >
                                    { t("flight.latest_ticketing_date") + ' ' + moment(flight.options.fares[0].latest_ticketing).utc().format("DD/MM/YYYY HH[h]mm") }
                                </Grid>
                            ) 
                        }
                        <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`} >
                            { flight_groups.length !== 1 ? ` (${t("flight_groups.group")}${index_group + 1})` : "" }
                        </Grid>
                    </Grid>
                    {
                        flight === null && (
                            <LinearProgress />
                        )
                    }
                    {
                        flight !== null && flight !== undefined && searched_group !== null && (
                            <Grid>
                                <Grid
                                    container
                                    alignItems="center"
                                    justify="flex-start"
                                    className={
                                        clsx({
                                            [classes.horizontalContainerSpacer]: !isMobile,
                                            [classes.spaceBetween]: !!replaceProductContext.enableReplace
                                        })
                                    }
                                >
                                    {
                                        flight.outbounds.map((outbound, outbound_index) => {
                                            if (outbound_index < 2) {
                                                return (
                                                    <Grid item key={outbound_index} xs={isHandHeld ? 12 : 4} className={isMobile ? "" : classes.horizontalSpacer}>
                                                        <OutboundInfo searched_group={searched_group} flight={flight} outbound_flight={outbound} outbound_index={outbound_index} outbounds_length={flight.outbounds.length} />
                                                    </Grid>
                                                );
                                            }
                                        })
                                    }
                                    { !isHandHeld && flight.outbounds.length === 1 && <Grid item xs={3} /> }
                                    <Grid item xs={isHandHeld ? 6 : (flight.outbounds.length === 1 ? 3 : 2)} className={`${isMobile ? "" : classes.horizontalSpacer} ${classes.textCenter}`}>
                                        <div className={`${classes.genericText} ${classes.fontSize9} ${classes.fontWeight700}`}>
                                            { CountAgeStr(searched_group.travelers, end_date, t) }
                                        </div>
                                        <div className={`${classes.genericText} ${classes.fontSize12} ${classes.fontWeight700}`}>
                                            { new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(getPricePerPax()) }
                                            { " " + t("flight_search.per_travelers") }
                                        </div>
                                        <DetailedPriceButton flight={flight} />
                                    </Grid>
                                    {
                                        !replaceProductContext.enableReplace &&
                                        <Grid item xs={isHandHeld ? 6 : 2} className={isMobile ? classes.textCenter : ""}>
                                            <Button size="small" variant="contained" className={classes.editButton} onClick={() => setEditSearch(!editSearch)}>
                                                <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.orange}`}>
                                                    { isMobile ? t("global.edit") : t("flight.edit_search") }
                                                </div>
                                            </Button>
                                        </Grid>
                                    }
                                </Grid>
                                {
                                    displayFlights && (
                                        <Grid container alignItems="center" justify="flex-start" className={isMobile ? "" : classes.horizontalContainerSpacer}>
                                            {
                                                flight.outbounds.map((outbound, outbound_index) => {
                                                    if (outbound_index >= 2) {
                                                        return (
                                                            <Grid item key={outbound_index} xs={isHandHeld ? 12 : 4} className={isMobile ? "" : classes.horizontalSpacer}>
                                                                <OutboundInfo searched_group={searched_group} outbound_flight={outbound} outbound_index={outbound_index} outbounds_length={flight.outbounds.length} />
                                                            </Grid>
                                                        );
                                                    }
                                                })
                                            }
                                        </Grid>
                                    )
                                }
                                {
                                    flight.outbounds.length > 2 && (
                                        <Grid style={{ padding: "0px 30px 0px" }}>
                                            <Button size="small" onClick={() => setDisplayFlights(!displayFlights)}>
                                                <div className={`${classes.genericText} ${classes.fontWeight700} ${classes.fontSize9} ${classes.black80}`}>
                                                    { displayFlights ? t("flight.hide") : t("flight.display_all") }
                                                </div>
                                            </Button>
                                        </Grid>
                                    )
                                }
                                {
                                    !isHandHeld && (
                                        <FlightGroups origin_url={"selected"} display={editSearch} setDisplay={setEditSearch} />
                                    )
                                }
                                {
                                    isHandHeld && (
                                        <Dialog open={editSearch} fullScreen TransitionComponent={Transition}>
                                            <DialogTitle style={{ padding: "8px 24px" }}>
                                                <Grid container alignItems="center" justify="space-between">
                                                    <Grid item xs={isMobile ? 2 : 1}>
                                                        <IconButton className={classes.genericBorder} size="small" onClick={() => setEditSearch(false)}>
                                                            <NavigateBeforeIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={isMobile ? 8 : 10} className={`${classes.genericText} ${classes.textCenter} ${classes.fontWeight900} ${isMobile ? classes.fontSize16 : classes.fontSize28} ${classes.orange}`}>
                                                        { t("flight.edit_search") }
                                                    </Grid>
                                                    <Grid item xs={isMobile ? 2 : 1} />
                                                </Grid>
                                            </DialogTitle>
                                            <Divider />
                                            <DialogContent style={{ padding: 0, overflowX: "hidden" }}>
                                                <FlightGroups origin_url={"selected"} display={editSearch} setDisplay={setEditSearch} />
                                            </DialogContent>
                                        </Dialog>
                                    )
                                }
                            </Grid>
                        )
                    }
                </Grid>
            </Paper>
            { searched_group !== null && flight !== null && <FlightStepper flight={flight} flightStep={flightStep} setFlightStep={setFlightStep} luggageOptions={luggageOptions} backToList={backToList} seats_by_segment={seats_by_segment}/> }
            { searched_group !== null && flight !== null && flightStep === 2 && <AddLuggage flight={flight} setFlight={setFlight} luggageOptions={luggageOptions} travelers={searched_group.travelers} flightStep={flightStep} setFlightStep={setFlightStep} seats_by_segment={seats_by_segment}/> }
            { searched_group !== null && flight !== null && flightStep === 3 && <FlightSeats searched_group={searched_group} flight={flight} router={router} seats_by_segment={seats_by_segment} end_date={flight.outbounds[0].legs[0].departure_datetime_lt} flightStep={flightStep} setFlightStep={setFlightStep}/> }
            { searched_group !== null && flight !== null && flightStep === 4 && <CancellationRules flight={flight} flightStep={flightStep} setFlightStep={setFlightStep}/> }
            { searched_group !== null && flight !== null && flightStep === 5 && <FlightRecap searched_group={searched_group} flight={flight} router={router} /> }
            <PriceVariation flight={flight} setBackToList={setBackToList} router={router} />
        </Container>
    );
});

export default React.memo(FlightSelected);
