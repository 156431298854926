import { data } from "jquery";

const initial_state = {
    step: 1,
    selected_page: null,
    active_page: 1,
    requested_active_page: 1,
    modal_picture: false,
    management_step: 1,
    edit_user: null,
    trip_info: null,
    trip_info_page: 1,
    trip_list: null,
    trip_list_nb: null,
    trip_list_loading: false,
    trip_list_filter: null,
    trip_list_filter_prev: null,
    options: {
        start_date: null,
        end_date: null,
        file_state: null
    },
    trip_list_search: null,
    trip_list_search_prev: null,
    trip_list_ordering: "-current_version__created_date",
    trip_list_ordering_prev: "-current_version__created_date",
    margin_step: 1,
    trip_list_tab_nb: 0,
    big_cart: null,
    get_cart_done: false,
    need_update_margin_list: false,
    to_show_version: null,
    travelers_info_to_show: null,
    duplicate_modal: false,
    trip_to_duplicate: null,
    duplicate_step: 1,
    video_to_read: null,
    video_modal: false,
    mcto_modal: false,
    mtrip_modal: false,
    mcto_data: null,
    mtrip_modal: false,
    mtrip_data: null,
    valueDateType: "",
    destination: {},
    startDate: moment(new Date(), "YYYY-MM-DD"),
    endDate: moment(new Date(), "YYYY-MM-DD"),
    fileType: "ALL",
    dateType: "current_month",
    tripStatusFilter: [],
    userTO: [],
    agencyFilter: null,
    userAgence: [],
    userAgenceList: [],
    creatorList: [],
    creator: [],
    paxNumber: [],
    nightNumber: [],
    agencies: [],
    userList: [],
    trip_docs: [],
    files_to_dl: [],
    from_duplication: false,
    tripInitBy: [],
    customFilters: [],
    customFiltersSelected: null,
    completionPercentageFilter: [0, 0],
    tripTags: [],
    myFiles: false,
    sortFilters: [],
    honeymoon_filter: false,
    wedding_anniversary_filter: false
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.menu !== undefined) {
                Object.keys(action.payload.menu).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.menu[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            new_state = initial_state;
            return new_state;
        }
        case "MENU_SET_STEP": {
            new_state.step = action.payload.step;
            return new_state;
        }
        case "MENU_TOGGLE_VIDEO_MODAL": {
            new_state.video_modal = action.payload.video_modal;
            new_state.video_to_read = action.payload.video_to_read;
            return new_state;
        }
        case "MENU_SET_TRAVELERS_INFO_TO_SHOW": {
            new_state.travelers_info_to_show = action.payload.travelers_info_to_show;
            return new_state;
        }
        case "MENU_TO_SHOW_VERSION": {
            new_state.to_show_version = action.payload.to_show_version;
            return new_state;
        }
         case "MENU_SET_MANAGEMENT_STEP": {
            new_state.management_step = action.payload.management_step;
            return new_state;
        }
        case "MENU_SET_ARRAY_TAB": {
            new_state.trip_list_tab_nb = action.payload.trip_list_tab_nb;
            return new_state;
        }
         case "MENU_SET_MANAGEMENT_STEP_AND_USER": {
            new_state.management_step = action.payload.management_step;
            new_state.edit_user = action.payload.edit_user;
            return new_state;
        }
         case "MENU_SET_EDIT_USER": {
            new_state.edit_user = action.payload.edit_user;
            return new_state;
        }
        case "MENU_SET_SELECTED_PAGE": {
            new_state.selected_page = action.payload.selected_page;
            return new_state;
        }
        case "MENU_CHANGE_ACTIVE_PAGE": {
            new_state.active_page = action.payload.active_page;
            return new_state;
        }
        case "MENU_TOGGLE_MODAL_PICTURE": {
            new_state.modal_picture = action.payload.modal_picture;
            return new_state;
        }
        case "MENU_CHANGE_TRIP_INFO_PAGE": {
            new_state.trip_info_page = action.payload.trip_info_page;
            return new_state;
        }
        case "MENU_CHANGE_TRIP_INFO_PAGE_AND_SET_INFO": {
            new_state.trip_info_page = action.payload.trip_info_page;
            new_state.trip_info = action.payload.trip_info;
            return new_state;
        }
        case "MENU_SET_BIG_CART": {
            new_state.big_cart = action.payload.big_cart;
            new_state.get_cart_done = action.payload.get_cart_done;
            return new_state;
        }
        case "MENU_SET_TRIP_INFO": {
            new_state.trip_info = action.payload.trip_info;
            return new_state;
        }
        case "MENU_TO_SHOW_VERSION_AND_SET_TRIP": {
            new_state.trip_info = action.payload.trip_info;
            new_state.to_show_version = action.payload.to_show_version;
            return new_state;
        }
        case "MENU_SET_TRIP_INFO_STATUS": {
            let tmp_trip_info = Object.assign({}, new_state.trip_info);
            let copy_data = [];
            tmp_trip_info.data.map((data, index_data) => {
                if (index_data === action.payload.index) {
                    let tmp_data = Object.assign({}, data);
                    tmp_data.status = action.payload.status;
                    data = tmp_data;
                }
                copy_data.push(data);
            });
            tmp_trip_info.data = copy_data;
            new_state.trip_info = tmp_trip_info;
            return new_state;
        }
        case "MENU_SET_TRIP_INFO_VERSION": {
            if (new_state.trip_info !== null) {
                let tmp_trip_info = Object.assign({}, new_state.trip_info);
                let copy_data = [];
                if (tmp_trip_info !== null) {
                    tmp_trip_info.data.map((data) => {
                        if (data.id === action.payload.version.id) {
                            data = action.payload.version;
                        }
                        copy_data.push(data);
                    });
                    tmp_trip_info.data = copy_data;
                    new_state.trip_info = tmp_trip_info;
                    return new_state;
                }
            }
        }
        case "MENU_SET_CART_DONE": {
            new_state.get_cart_done = action.payload.get_cart_done;
            return new_state;
        }
        case "MENU_SET_TRIP_INFO_CONTACT_LEAD": {
            new_state.trip_info.data[0].contact_traveler = action.payload.trip_info_contact_lead_id;
            new_state.trip_info.data[0].contact_traveler_detail = action.payload.trip_info_contact_lead;
            return new_state;
        }
        case "MENU_SET_TRIP_LIST_INFO": {
            new_state.trip_list = action.payload.trip;
            new_state.trip_list_loading = action.payload.loading_state;
            new_state.trip_list_nb = action.payload.nb_result;
            new_state.requested_active_page = new_state.active_page;
            return new_state;
        }
        case "MENU_SET_PREV_TRIP_INFO": {
            new_state.trip_list_ordering_prev = new_state.trip_list_ordering;
            new_state.trip_list_search_prev = new_state.trip_list_search;
            new_state.trip_info = action.payload.trip_info;
            return new_state;
        }
        case "MENU_SET_TRIP_LIST_FILTER": {
            new_state.trip_list_filter = action.payload.trip_list_filter;
            return new_state;
        }
        case "MENU_SET_PREV_TRIP_LIST_FILTER": {
            new_state.trip_list_filter_prev = action.payload.trip_list_filter_prev;
            return new_state;
        }
        case "MENU_SET_TRIP_LIST_ORDERING": {
            new_state.trip_list_ordering = action.payload.trip_list_ordering;
            new_state.trip_list_ordering_prev = action.payload.trip_list_ordering_prev;
            return new_state;
        }
        case "MENU_SET_TRIP_LIST_SEARCH": {
            new_state.trip_list_search = action.payload.trip_list_search;
            new_state.trip_list_search_prev = action.payload.trip_list_search_prev;
            return new_state;
        }
        case "MENU_SET_TRIP_LIST_OPTION": {
            new_state.options = action.payload.options;
            return new_state;
        }
        case "MENU_SET_TRIP_LIST_LOADER": {
            new_state.trip_list_loading = action.payload.loading_state;
            return new_state;
        }
        case "MENU_SET_MARGIN_STEP": {
            new_state.margin_step = action.payload.margin_step;
            return new_state;
        }
        case "MENU_SET_MARGIN_STEP_AND_UPDATE": {
            new_state.margin_step = action.payload.margin_step;
            new_state.need_update_margin_list = action.payload.need_update_margin_list;
            return new_state;
        }
        case "MENU_SET_MARGIN_UPDATE": {
            new_state.need_update_margin_list = action.payload.need_update_margin_list;
            return new_state;
        }
        case "MENU_SET_TRIP_DUPLICATE_AND_OPEN_DUPLICATE_MODAL": {
            new_state.trip_to_duplicate = action.payload.trip_to_duplicate;
            new_state.duplicate_modal = action.payload.duplicate_modal;
            new_state.duplicate_step = action.payload.duplicate_step;
            return new_state;
        }
        case "RESET_TRIP_LIST": {
            new_state.active_page = 1;
            new_state.trip_list_tab_nb = 0;
            return new_state;
        }
        case "MENU_TOGGLE_MCTO_MODAL": {
            new_state.mcto_modal = !new_state.mcto_modal;
            return new_state;
        }
        case "MENU_TOGGLE_MTRIP_MODAL": {
            new_state.mtrip_modal = !new_state.mtrip_modal;
            return new_state;
        }
        case "MENU_SET_MCTO_DATA": {
            new_state.mcto_data = action.payload.data;
            return new_state;
        }
        case "MENU_TOGGLE_MTRIP_MODAL": {
            new_state.mtrip_modal = !new_state.mtrip_modal;
            return new_state;
        }
        case "MENU_SET_MTRIP_DATA": {
            new_state.mtrip_data = action.payload.data;
            return new_state;
        }
        case "MENU_SET_VALUE_DATE_TYPE": {
            new_state.valueDateType = action.payload.valueDateType;
            return new_state;
        }
        case "MENU_SET_TRIP_INIT_BY": {
            new_state.tripInitBy = action.payload.tripInitBy;
            return new_state;
        }
        case "MENU_SET_TRIP_TAGS": {
            new_state.tripTags = action.payload.tripTags;
            return new_state;
        }
        case "MENU_SET_START_DATE": {
            new_state.startDate = action.payload.startDate;
            return new_state;
        }
        case "MENU_SET_END_DATE": {
            new_state.endDate = action.payload.endDate;
            return new_state;
        }
        case "MENU_SET_FILE_TYPE": {
            new_state.fileType = action.payload.fileType;
            return new_state;
        }
        case "MENU_SET_DATE_TYPE": {
            new_state.dateType = action.payload.dateType;
            return new_state;
        }
        case "MENU_SET_TRIP_STATUS_FILTER": {
            new_state.tripStatusFilter = action.payload.tripStatusFilter;
            return new_state;
        }
        case "MENU_SET_USER_TO": {
            new_state.userTO = action.payload.userTO;
            return new_state;
        }
        case "MENU_SET_AGENCY_FILTER": {
            new_state.agencyFilter = action.payload.agencyFilter;
            return new_state;
        }
        case "MENU_SET_USER_AGENCE": {
            new_state.userAgence = action.payload.userAgence;
            return new_state;
        }
        case "MENU_SET_USER_AGENCE_LIST": {
            new_state.userAgenceList = action.payload.userAgenceList;
            return new_state;
        }
        case "MENU_SET_CREATOR_LIST": {
            new_state.creatorList = action.payload.creatorList;
            return new_state;
        }
        case "MENU_SET_CREATOR": {
            new_state.creator = action.payload.creator;
            return new_state;
        }
        case "MENU_SET_PAX_NUMBER": {
            new_state.paxNumber = action.payload.paxNumber;
            return new_state;
        }
        case "MENU_SET_NIGHT_NUMBER": {
            new_state.nightNumber = action.payload.nightNumber;
            return new_state;
        }
        case "MENU_SET_DESTINATION": {
            new_state.destination = action.payload.destination;
            return new_state;
        }
        case "MENU_SET_USER_LIST": {
            new_state.userList = action.payload.userList;
            return new_state;
        }
        case "MENU_SET_AGENCIES": {
            new_state.agencies = action.payload.agencies;
            return new_state;
        }
        case "MENU_RESET_TRIP_FILTER": {
            new_state.customFiltersSelected = null;
            new_state.valueDateType = "";
            new_state.startDate = moment(new Date(), "YYYY-MM-DD");
            new_state.endDate = moment(new Date(), "YYYY-MM-DD");
            new_state.fileType = "ALL";
            new_state.dateType = "current_month";
            new_state.tripStatusFilter = [];
            new_state.userTO = [];
            new_state.agencyFilter = null;
            new_state.userAgence = [];
            new_state.userAgenceList = [];
            new_state.creatorList = [];
            new_state.creator = [];
            new_state.paxNumber = [];
            new_state.nightNumber = [];
            new_state.trip_list_filter = "";
            new_state.destination = {};
            new_state.tripInitBy = [];
            new_state.completionPercentageFilter = [0, 0];
            new_state.tripTags = [];
            new_state.honeymoon_filter = false;
            new_state.wedding_anniversary_filter = false;
            return new_state;
        }
        case "MENU_RESET_TRIP_FILTER_CERCLE": {
            new_state.customFiltersSelected = null;
            new_state.valueDateType = "";
            new_state.startDate = moment(new Date(), "YYYY-MM-DD");
            new_state.endDate = moment(new Date(), "YYYY-MM-DD");
            new_state.fileType = "ALL";
            new_state.dateType = "current_month";
            new_state.tripStatusFilter = [];
            new_state.userTO = [];
            new_state.agencyFilter = null;
            new_state.userAgence = [];
            new_state.creatorList = [];
            new_state.creator = [];
            new_state.paxNumber = [];
            new_state.nightNumber = [];
            new_state.trip_list_filter = "";
            new_state.destination = {};
            new_state.completionPercentageFilter = [0, 0]
            new_state.tripTags = [];
            new_state.myFiles = false;
            new_state.honeymoon_filter = false;
            new_state.wedding_anniversary_filter = false;
            return new_state;
        }
        case "MENU_EDIT_VERSION_STATUS": {
            let trip_list = new_state.trip_list.slice();
            let copy_trip_list = [];
            trip_list.map((trip) => {
                if (trip.trip_reference === action.payload.trip_reference) {
                    let tmp_trip = Object.assign({}, trip);
                    let copy_data = [];
                    tmp_trip.data.map((data) => {
                        if (data.id === action.payload.data.id) {
                            let tmp_data = Object.assign({}, data);
                            tmp_data.status = action.payload.data.status;
                            tmp_data.modified_date = action.payload.data.modified_date;
                            tmp_data.last_author = action.payload.data.last_author.id;
                            tmp_data.due_date = action.payload.data.due_date;
                            data = tmp_data;
                        }
                        copy_data.push(data);
                    });
                    tmp_trip.data = copy_data;
                    trip = tmp_trip;
                }
                copy_trip_list.push(trip);
            });
            new_state.trip_list = copy_trip_list;
        }
        case "MENU_SET_TRIP_DOCS": {
            new_state.trip_docs = action.payload.trip_docs;
            return new_state;
        }
        case "MENU_DELETE_DOC": {
            let trip_docs = new_state.trip_docs.filter((doc) => doc.id !== action.payload);
            return {...new_state, trip_docs: trip_docs}
        }
        case "MENU_ADD_OTHER_DOC": {
            return {...new_state, trip_docs: [...new_state.trip_docs, action.payload]}
        }
        case "MENU_SET_TRIP_DOCS_DOC": {
            let tmp_trip_docs = new_state.trip_docs.slice();
            let copy_docs = [];
            tmp_trip_docs.map((doc) => {
                if (doc.id === action.payload.trip_doc.id) {
                    doc = action.payload.trip_doc;
                }
                copy_docs.push(doc);
            });
            tmp_trip_docs = copy_docs;
            new_state.trip_docs = tmp_trip_docs;
            return new_state;
        }
        case "MENU_UPDATE_TRIP_DOCS_DOC": {
            let tmp_trip_docs = [...new_state.trip_docs];
            let copy_docs = [];
            tmp_trip_docs.map((doc) => {
                let doc_updated = action.payload.trip_docs.find((doc_updated) => doc_updated.id === doc.id);
                if (doc_updated !== undefined) {
                    copy_docs.push(doc_updated);
                } else {
                    copy_docs.push(doc);
                }
            });
            new_state.trip_docs = copy_docs;
            return new_state;
        }
        case "MENU_FROM_DUPLICATION": {
            new_state.from_duplication = action.payload.from_duplication;
            return new_state;
        }
        case "MENU_ADD_FILE_TO_DL": {
            let new_list = new_state.files_to_dl.slice();
            new_list.push(Object.assign({}, action.payload));
            new_state.files_to_dl = new_list;
            return new_state
        }
        case "MENU_CLEAR_FILES_TO_DL": {
            new_state.files_to_dl = [];
            return new_state
        }
        case "MENU_SET_CUSTOM_FILTERS": {
            return {...state, customFilters: action.payload.sort((a, b) => {return a.order - b.order})};
        }
        case "MENU_SET_CUSTOM_FILTERS_SELECTED": {
            if (action.payload.selectedOptions !== null) {
                let customFiltersSelected = action.payload.selectedOptions;
                let user_ta_in = [];
                let user_to_in = [];
                if (customFiltersSelected.confirmation_date !== null) {
                    new_state.dateType = customFiltersSelected.confirmation_date;
                    new_state.valueDateType = "confirmation";
                }
                if (customFiltersSelected.created_date_filter !== null) {
                    new_state.dateType = customFiltersSelected.created_date_filter;
                    new_state.valueDateType = "creation";
                }
                if (customFiltersSelected.confirmation_date !== null) {
                    new_state.dateType = customFiltersSelected.confirmation_date;
                    new_state.valueDateType = "confirmation";
                }
                if (customFiltersSelected.due_date !== null) {
                    new_state.dateType = customFiltersSelected.due_date;
                    new_state.valueDateType = "deadline";
                }
                if (customFiltersSelected.start_date !== null) {
                    new_state.dateType = customFiltersSelected.start_date;
                    new_state.valueDateType = "trip";
                }
                if (customFiltersSelected.minisite_link_sent_date !== null) {
                    new_state.dateType = customFiltersSelected.minisite_link_sent_date;
                    new_state.valueDateType = "minisite_link_sent_date";
                }
                if (customFiltersSelected.minisite_link_sent_modified_date !== null) {
                    new_state.dateType = customFiltersSelected.minisite_link_sent_modified_date;
                    new_state.valueDateType = "minisite_link_sent_modified_date";
                }
                if (customFiltersSelected.minisite_link_amendment_date !== null) {
                    new_state.dateType = customFiltersSelected.minisite_link_amendment_date;
                    new_state.valueDateType = "minisite_link_amendment_date";
                }
                if (customFiltersSelected.user_to_in.length !== 0) {
                    customFiltersSelected.user_to_in.map((user_to) => {
                        let find_user = action.payload.userList.find((userTO) => userTO.id === parseFloat(user_to.id));
                        user_to_in = [...user_to_in, find_user];
                    });
                }
                if (customFiltersSelected.user_ta_in.length !== 0) {
                    customFiltersSelected.user_ta_in.map((user_ta) => {
                        let find_user = action.payload.userAgenceList.find((userAgence) => userAgence.id === parseFloat(user_ta.id));
                        user_ta_in = [...user_ta_in, find_user];
                    });
                }
                if (customFiltersSelected.my_file) {
                    let my_user_to = action.payload.userList.find((userTO) => userTO.id === parseFloat(action.payload.user.id));
                    let my_user_ta = action.payload.userAgenceList.find((userAgence) => userAgence.id === parseFloat(action.payload.user.id));
                    if (my_user_to !== undefined) {
                        user_to_in = [...user_to_in, my_user_to];
                    }
                    if (my_user_ta !== undefined) {
                        user_ta_in = [...user_ta_in, my_user_ta];
                    }
                }
                if (customFiltersSelected.creator_in.length !== 0) {
                    let creator_in = customFiltersSelected.creator_in.slice();
                    creator_in.forEach(element => {
                        element.label = `${element.first_name} ${element.last_name} `;
                    });
                    new_state.creator = creator_in;
                }
                if (customFiltersSelected.status !== null) {
                    let array_status = customFiltersSelected.status.split(",");
                    let status = [];
                    array_status.map((elem) => {
                        status.push({
                            label: elem
                        });
                    });
                    new_state.tripStatusFilter = status;
                }
                if (customFiltersSelected.status_contract !== null || customFiltersSelected.status_modification !== null || customFiltersSelected.status_booking_progress !== null) {
                    let status = [];
                    if (customFiltersSelected.status_contract !== null) {
                        customFiltersSelected.status_contract.split(",").forEach((el) => {
                            status.push({
                                group: "TRIP_STATUS_CONTRACT",
                                label: el
                            });
                        })
                    }
                    if (customFiltersSelected.status_modification !== null) {
                        customFiltersSelected.status_modification.split(",").forEach((el) => {
                            status.push({
                                group: "TRIP_STATUS_MODIFICATION",
                                label: el
                            });
                        })
                    }
                    if (customFiltersSelected.status_booking_progress !== null) {
                        customFiltersSelected.status_booking_progress.split(",").forEach((el) => {
                            status.push({
                                group: "TRIP_STATUS_BOOKING_PROGRESS",
                                label: el
                            });
                        })
                    }
                    new_state.tripStatusFilter = status;
                }
                if (customFiltersSelected.status_contract !== null) {
                    let status = [];
                    customFiltersSelected.status_contract.split(",").forEach((el) => {
                        status.push({
                            group: "TRIP_STATUS_CONTRACT",
                            label: el
                        });
                    })
                }
                if (customFiltersSelected.creator_client_type !== null) {
                    let tripInitBy = [];
                    if (customFiltersSelected.creator_client_type === "1,2,3") {
                        tripInitBy = [{label: "TO"}, {label: "Agence"}];
                    }
                    if (customFiltersSelected.creator_client_type === "1,3") {
                        tripInitBy = [{label: "TO"}]
                    }
                    if (customFiltersSelected.creator_client_type === "2") {
                        tripInitBy = [{label: "Agence"}]
                    }
                    new_state.tripInitBy = tripInitBy;
                }
                if (customFiltersSelected.target_client !== null) {
                    let target_client = Object.assign({}, customFiltersSelected.target_client);
                    target_client.label = customFiltersSelected.target_client.name;
                    new_state.agencyFilter = target_client;
                }
                if (customFiltersSelected.nb_pax !== null) {
                    let paxNumber = [];
                    if (customFiltersSelected.nb_pax === "10") {
                        paxNumber = [{label: "+10"}]
                    }
                    let nb_pax = customFiltersSelected.nb_pax.split(",");
                    for (let i = 0; i < nb_pax.length; i = i + 2) {
                        paxNumber.push({
                            label: nb_pax[i] + "-" + nb_pax[i + 1]
                        });
                    }
                    new_state.paxNumber = paxNumber;
                }
                if (customFiltersSelected.nb_nights !== null) {
                    let nightNumber = [];
                    if (customFiltersSelected.nb_nights === "10") {
                        nightNumber = [{label: "+10"}]
                    }
                    let nb_nights = customFiltersSelected.nb_nights.split(",");
                    for (let i = 0; i < nb_nights.length; i = i + 2) {
                        nightNumber.push({
                            label: nb_nights[i] + "-" + nb_nights[i + 1]
                        });
                    }
                    new_state.nightNumber = nightNumber;
                }
                if (customFiltersSelected.completion_percentage_gte !== null && customFiltersSelected.completion_percentage_lte !== null) {
                    new_state.completionPercentageFilter = [customFiltersSelected.completion_percentage_gte, customFiltersSelected.completion_percentage_lte];
                }
                let tripTags = [];
                if (customFiltersSelected.is_paid) {
                    tripTags.push({label: "is_paid_balance"})
                }
                if (customFiltersSelected.is_signed) {
                    tripTags.push({label: "is_signed"})
                }
                if (customFiltersSelected.current_version_has_invoice) {
                    tripTags.push({label: "has_invoice"})
                }
                if (customFiltersSelected.current_version_has_invoice !== null && !customFiltersSelected.current_version_has_invoice) {
                    tripTags.push({label: "has_no_invoice"})
                }
                if (customFiltersSelected.current_version_has_roadbook) {
                    tripTags.push({label: "has_roadbook"})
                }
                if (customFiltersSelected.current_version_has_roadbook !== null && !customFiltersSelected.current_version_has_roadbook) {
                    tripTags.push({label: "no_roadbook"})
                }
                if (customFiltersSelected.current_version_has_router) {
                    tripTags.push({label: "has_router"})
                }
                if (customFiltersSelected.current_version_has_router !== null && !customFiltersSelected.current_version_has_router) {
                    tripTags.push({label: "no_router"})
                }
                if (customFiltersSelected.current_version_is_processed) {
                    tripTags.push({label: "is_processed"})
                }
                if (customFiltersSelected.current_version_is_paid) {
                    tripTags.push({label: "is_paid_deposit"})
                }
                if (customFiltersSelected.minisite_link_amendment) {
                    tripTags.push({label: "minisite_link_amendment"})
                }
                if (customFiltersSelected.minisite_link_sent) {
                    tripTags.push({label: "minisite_link_sent"})
                }
                if (customFiltersSelected.minisite_link_sent !== null && !customFiltersSelected.minisite_link_sent) {
                    tripTags.push({label: "minisite_not_sent"})
                }
                if (customFiltersSelected.minisite_link_sent_modified) {
                    tripTags.push({label: "minisite_link_sent_modified"})
                }
                if (tripTags.length !== 0) {
                    new_state.tripTags = tripTags;
                }
                if (customFiltersSelected.user_ta_isnull) {
                    user_ta_in.unshift({id: null, label: action.payload.t('menu.user_management.without_seller')});
                }
                if (customFiltersSelected.user_to_isnull) {
                    user_to_in.unshift({id: null, label: action.payload.t('menu.user_management.without_user_bo')});
                }
                if (customFiltersSelected.user_to_all) {
                    user_to_in = new_state.userList.filter((user) => user.id !== null);
                }
                if (customFiltersSelected.user_ta_all) {
                    user_ta_in = new_state.userAgenceList.filter((user) => user.id !== null);
                }
                if (customFiltersSelected.my_file) {
                    new_state.myFiles = customFiltersSelected.my_file;
                }
                if (user_to_in.length !== 0) {
                    user_to_in.forEach(element => {
                        if (element.id !== null) {
                            element.label = `${element.first_name} ${element.last_name}`;
                        }
                    });
                }
                if (user_ta_in.length !== 0) {
                    user_ta_in.forEach(element => {
                        if (element.id !== null) {
                            element.label = `${element.first_name} ${element.last_name}`;
                        }
                    });
                }
                new_state.userTO = user_to_in;
                new_state.userAgence = user_ta_in;
            }
            new_state.customFiltersSelected = action.payload.selectedOptions;
            return new_state;
        }
        case "MENU_DELETE_CUSTOM_FILTER": {
            let custom_filters = new_state.customFilters.filter((el) => el.id !== action.payload);
            new_state.customFilters = custom_filters;
            return new_state;
        }
        case "MENU_SET_COMPLETION_PERCENTAGE_FILTER": {
            new_state.completionPercentageFilter = action.payload;
            return new_state;
        }
        case "MENU_EDIT_CUSTOM_FILE_NAME": {
            let trip_docs = [...new_state.trip_docs];
            const copy_trip_docs = trip_docs.map((doc) => {
                if (doc.id === action.payload.id) {
                    return { ...doc, file_name: action.payload.file_name };
                }
                return doc;
            });
            new_state.trip_docs = copy_trip_docs;
            return new_state;
        }
        case "MENU_TOGGLE_MY_FILES": {
            if (action.payload.checked) {
                new_state.userTO = new_state.userList.filter((user) => user.id === parseFloat(action.payload.id));
                new_state.userAgence = new_state.userAgenceList.filter((user) => user.id === parseFloat(action.payload.id));
            } else {
                new_state.userTO = [];
                new_state.userAgence = [];
            }
            new_state.myFiles = action.payload.checked;
            return new_state;
        }
        case "MENU_TOGGLE_MY_FILES_STATUS": {
            if (action.payload.checked) {
                new_state.userTO = new_state.userList.filter((user) => user.id === parseFloat(action.payload.id));
                new_state.userAgence = new_state.userAgenceList.filter((user) => user.id === parseFloat(action.payload.id));
                // new_state.tripStatusFilter = action.payload.tripStatusFilter;
            } else {
                new_state.userTO = [];
                new_state.userAgence = [];
                new_state.tripStatusFilter = [];
            }
            new_state.myFiles = action.payload.checked;
            return new_state;
        }
        case "MENU_TOGGLE_MY_FILES_STATUS_OTHER": {
            if (action.payload.checked) {
                if (action.payload.user.client_full.type !== 2) {
                    new_state.userTO = [action.payload.user];
                } else {
                    new_state.userAgence = [action.payload.user];
                }
            } else {
                new_state.userTO = [];
                new_state.userAgence = [];
                new_state.tripStatusFilter = [];
            }
            new_state.myFiles = action.payload.checked;
            return new_state;
        }
        case "MENU_SET_SORT_FILTERS": {
            return {...state, sortFilters: action.payload.sort((a, b) => {return a.order - b.order})};
        }
        case "MENU_UPDATE_SORT_FILTERS": {
            const { drag_index, hover_index } = action.payload;
            let sortFilters = [...state.sortFilters];
            sortFilters.splice(drag_index, 1);
            sortFilters.splice(hover_index, 0, state.sortFilters[drag_index]);
            // sortFilters.forEach((filter, filter_index) => filter.order = filter_index + 1);
            return {...state, sortFilters: sortFilters};
        }
        case "MENU_SET_HONEYMOON_FILTER": {
            return {...state, honeymoon_filter: action.payload};
        }
        case "MENU_SET_WEDDING_ANNIVERSARY_FILTER": {
            return {...state, wedding_anniversary_filter: action.payload};
        }
    }
    return new_state;
}
