import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Room from '@material-ui/icons/Room';
import Badge from "@material-ui/core/Badge";
import Slide from '@material-ui/core/Slide';
import IconButton from "@material-ui/core/IconButton";

import NavigateBefore from "@material-ui/icons/NavigateBefore";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import MailOutline from '@material-ui/icons/MailOutline';
import EmojiTransportationOutlinedIcon from '@material-ui/icons/EmojiTransportationOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";

import FillMultiDest from "../Common/Functions/FillMultiDest.js";
import clsx from "clsx";
import { useSnackbar } from "notistack";

import MobileAvatarHeader from "../Header/Mobile/MobileAvatarHeader.js";
import MobileServicesHeader from "../Header/Mobile/MobileServicesHeader.js";

const useStyles = makeStyles({
    navigationWrapper: {
        paddingTop: 8,
        overflow: 'hidden'
    },
    navigationButton: {
        textAlign: "center",
        cursor: "pointer"
    },
    orange: {
        color: "#E6592F"
    },
    grey: {
        color: grey[400]
    },
    green: {
        color: green[500]
    },
    inherit: {
        color: "inherit",
        textDecoration: "inherit"
    },
    navigationWrapperResponsive: {
        position: 'fixed',
        bottom: 0,
        zIndex: 2,
        width: '100%',
        paddingBottom: 8
    },
    mobileFont: {
        fontSize: '10px'
    },
    black: {
        color: "black"
    },
    badge: {
        marginTop: 5
    },
});
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const MaterialNavigationMobile = ({router, current_page}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const data_trip = useSelector((store) => {
        return store.trip.data_trip;
    });
    const user = useSelector((store) => {
        return store.user.user;
    });
    const cars_timeline = useSelector((store) => {
        return store.cars_search.cars_timeline;
    });
    const bdd_cart = useSelector((store) => {
        return store.accommodation.cart;
    });
    const car_cart = useSelector((store) => {
        return store.cars_search.cart;
    });
    const flight_cart = useSelector((store) => {
        return store.flight.cart;
    });
    const manual_item_list = useSelector((store) => {
        return store.cart.manual_item_list;
    });
    const transfers_cart = useSelector((store) => {
        return store.transfers.cart;
    });
    const poi_cart = useSelector((store) => {
        return store.poi.cart;
    });
    const nb_messages = 100;
    const [nbItems, setNbItems] = useState(0);
    const [isOpen, setIsOpen] = useState(null);
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [poi_cart, transfers_cart, manual_item_list, flight_cart, car_cart, bdd_cart]);
    const getData = () => {
        let nb_items = 0;
        let custom_cost = false;
        if (data_trip !== null && data_trip.prices_flight.length > 0) {
            custom_cost = true;
        }
        if (data_trip !== null && data_trip.prices_terrestrial.length > 0) {
            custom_cost = true;
        }
        if (data_trip !== null && data_trip.prices_stack_product.length > 0) {
            custom_cost = true;
        }
        if (user !== undefined && user !== null && user.client !== undefined && user.client !== null) {
            for (let i = 0; i < flight_cart.length; i++) {
                if (flight_cart[i].is_optional === false && flight_cart[i].is_displayed !== undefined && flight_cart[i].is_displayed !== false && (flight_cart[i].is_available !== undefined && flight_cart[i].is_available !== false)) {
                    nb_items++;
                }
            }
            for (let i = 0; i < bdd_cart.length; i++) {
                if (bdd_cart[i].is_optional === false && bdd_cart[i].is_displayed !== undefined && bdd_cart[i].is_displayed !== false && (bdd_cart[i].is_available !== undefined && bdd_cart[i].is_available !== false)) {
                    nb_items++;
                }
            }
            for (let i = 0; i < poi_cart.length; i++) {
                if (poi_cart[i].is_optional === false && poi_cart[i].is_displayed !== undefined && poi_cart[i].is_displayed !== false && (poi_cart[i].is_available !== undefined && poi_cart[i].is_available !== false)) {
                    nb_items++;
                }
            }
            for (let i = 0; i < transfers_cart.length; i++) {
                if (transfers_cart[i].is_optional === false && transfers_cart[i].is_displayed !== undefined && transfers_cart[i].is_displayed !== false && (transfers_cart[i].is_available !== undefined && transfers_cart[i].is_available !== false)) {
                    nb_items++;
                }
            }
            for (let i = 0; i < manual_item_list.length; i++) {
                if (manual_item_list[i].prices) {
                    if (custom_cost && user.client_full !== undefined && user.client_full.type === 2 && user.client_full.type === manual_item_list[i].creator.client.type) {
                        nb_items += 1;
                    } else {
                        if (manual_item_list[i].is_optional === false) {
                            nb_items += 1;
                        }
                    }
                }
            }
            for (let i = 0; i < car_cart.length; i++) {
                if (car_cart[i].is_optional === false && car_cart[i].is_displayed !== undefined && car_cart[i].is_displayed !== false && (car_cart[i].is_available !== undefined && car_cart[i].is_available !== false)) {
                    nb_items++;
                }
            }
        }
        setNbItems(nb_items);
    }
    const onClose = () => {
        setIsOpen(null);
    }
    if (mobile && (current_page === 'accommodation-card/:id' || !cars_timeline)) {
        return null;
    }
    return (
        <Paper className={ clsx(classes.navigationWrapper, {[classes.navigationWrapperResponsive]: mobile}) } elevation={ 3 }>
            <Grid container justify={ "space-evenly" } alignItems={ "center" }>
                {
                    data_trip !== null && (
                        <Fragment>
                            <Grid className={ `${classes.navigationButton} ${current_page === "itinerary" ? classes.orange : classes.grey}` } item onClick={() => {
                                router.push(`/${window.url_name}/apps/itinerary`);
                            }}>
                                <Room fontSize={ "default" }/>
                                <Typography className={classes.mobileFont }>{ t("apps_navigation.itinerary") }</Typography>
                            </Grid>
                            <Grid className={ `${classes.navigationButton} ${(current_page === "flight-groups" || current_page === "material-cars-search" || current_page === "accommodation" || current_page === "poi" || current_page === "transfers" ) ? classes.orange : classes.grey}` }
                            onClick={() => {setIsOpen("services")}}>
                                <EmojiTransportationOutlinedIcon fontSize={ "default" }/>
                                <Typography className={classes.mobileFont }>{ t("header.services") }</Typography>
                            </Grid>
                            <Grid onClick={() => {
                                if (router.location.pathname.includes("cart")) {
                                    dispatch({
                                        type: "CART_SET_STEP",
                                        payload: {
                                            step: 1
                                        }
                                    });
                                }
                                else {
                                    router.push(`/${window.url_name}/apps/cart`)
                                }
                            }} className={ `${classes.navigationButton} ${current_page === "cart" ? classes.orange : (`${nbItems !== 0 ? classes.green : classes.grey}`)}` }>
                                <Badge className={`${current_page === "cart" ? classes.orange : classes.grey}`} badgeContent={ nbItems } max={99} style={{marginTop: 4}} color={"error"}>
                                    <ShoppingCartIcon fontSize={ "default" }/>
                                </Badge >
                                <Typography className={`${classes.mobileFont} ${classes.badge} ` }>{ t("header.cart") }</Typography>
                            </Grid>
                        </Fragment>
                    )
                }
                {
                    // <Grid className={ `${classes.navigationButton} ${current_page === "message" ? classes.orange : classes.grey}`}>
                    //     <Badge className={`${current_page === "message" ? classes.orange : classes.grey}`} badgeContent={ nb_messages } max={99} style={{marginTop: 4}} color={"error"}>
                    //         <MailOutline fontSize={ "default" }/>
                    //     </Badge >
                    //     <Typography className={`${classes.mobileFont} ${classes.badge} ` }>{ t("global.message") }</Typography>
                    // </Grid>
                }
                <Grid className={ `${classes.navigationButton} ${classes.grey}`} onClick={() => {setIsOpen("profil")}}>
                    <AccountCircleOutlinedIcon fontSize={ "default" }/>
                    <Typography className={classes.mobileFont }>{ t("header.menu.profile") }</Typography>
                </Grid>
            </Grid>
            <Dialog fullScreen={ true } open={ isOpen !== null } fullWidth maxWidth={ "md" } onClose={ onClose } TransitionComponent={Transition} disableEscapeKeyDown>
                <DialogTitle onClose={ onClose }>
                    <Grid container justify={ "space-between" } alignItems={ "center" }>
                        <Grid item >
                            <IconButton edge={ "start" } onClick={ onClose }><NavigateBefore/></IconButton>
                            {isOpen === "profil" ? t("header.menu.profile") : t("header.services")}
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                {
                    isOpen === "profil" ?
                        <MobileAvatarHeader router={router} setIsOpen={setIsOpen}/>
                        :
                        <MobileServicesHeader router={router} setIsOpen={setIsOpen} current_page={current_page}/>
                }
                </DialogContent>
            </Dialog>
        </Paper>
    )
};

export default MaterialNavigationMobile;
