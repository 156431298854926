//---- Dependencies ----//
import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

//---- Common ----//

//---- CSS ----//
import "../../../Style/FlightGroups.css";
import "../../../Style/FlightSearch.css";
import "../../../Style/CarsSearch.css";

//---- Components ----//
import CarsInput from "./CarsInput.jsx";
import CarsResultsHeader from "./CarsResultsHeader.jsx";

//---- Actions ----//

import { ShowInput } from "../../../Actions/CarsSearch";

@connect((store) => {
    return {
    	itinerary_list: store.itinerary.itinerary_list,
    	step_info: store.cars_search.step_info,
    	cars_steps: store.cars_search.steps,
    }
})
@withTranslation()
export default class CarsSearch extends React.Component {
	componentWillMount() {
		const { dispatch } = this.props;
		dispatch(ShowInput(false));
	}
	render() {
		const {cars_steps, router, t } = this.props;
		return (
			<div id={ "cars-search-wrapper" } className={ "cars-search ft-beige-bg" }>
				<div className={ "cars-title text-center" }>
					<button className="ft-btn-return uppercase ft-white" onClick={() => { router.push(`/${window.url_name}/apps/itinerary`); }}>{t("global.return")}</button>
					<h1>
						{t("cars.main_title")}
					</h1>
				</div>
				{
					cars_steps.length > 0 && (
						<div>
							<div className={ "cars-wrapper quarters-3" }>
								<div className={ "cars-steps-details" }>
									<CarsInput current={"search"}/>
								</div>
							</div>
						</div>
					)
				}
			</div>
		);
	}
}