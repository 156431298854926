import { PoiCart } from "../../Itinerary/objects/poiCart";
import { Picture } from "../../Menu/MaterialTripList/picture/objects/picture";

export function getPoiPicture(poi: PoiCart, source?: 'provider'): Picture[] {
    if (poi.hide_pictures) {
        return [];
    }

    if (!source && (poi.overload_pictures?.length ?? 0) > 0) {
        return poi.overload_pictures;
    } else if ((poi.cust_variant?.pictures?.length ?? 0) > 0) {
        return poi.cust_variant?.pictures ?? [];
    } else if ((poi.custom_product?.pictures?.length ?? 0) > 0) {
        return poi.custom_product?.pictures ?? [];
    } else if (poi.custom_product?.cover_picture) {
        return [poi.custom_product.cover_picture];
    }

    return [];
}
