//---- Dependencies ----//
import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

//---- Components ----//
import SummaryPicturesModal from "./SummaryPicturesModal.jsx";

//---- Actions ----//
import { SetItiToDraw, ToggleAndSetPicturesModal } from "../../../Actions/Summary";

//---- CSS ----//
import "../../../Style/ItineraryType.css";
import "../../../Style/Summary.css";

//---- Class ----//
@connect((store) => {
    return {
        guide_list: store.summary.guide_list,
        itinerary_to_draw: store.summary.itinerary_to_draw,
        itinerary: store.summary.itinerary
    };
})
@withTranslation()
@withRouter
class SummaryStepDestination extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            description: null
        };
    }
    componentWillMount() {
        const { itinerary_to_draw, nb_step, itinerary, dispatch } = this.props;
        if (itinerary_to_draw.length === 0 && nb_step === 1) {
            let tmp_arr = [];
            tmp_arr.push(itinerary[1]);
            dispatch(SetItiToDraw(tmp_arr));
        }
    }
    componentDidMount() {
        this.getDescription();
    }
    getDescription() {
        const { guide_list, step } = this.props;
        for (let j = 0; j < guide_list.length; j++) {
            if (step.destination !== null) {
                if(step.destination.id === guide_list[j].guide.destination) {
                    if (guide_list[j].rubric !== undefined && guide_list[j].rubric !== null) {
                        if (guide_list[j].rubric.on_quotation === true) {
                            this.setState({description: guide_list[j].description });
                        }
                    }
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { guide_list } = this.props;
        if (prevProps.guide_list !== guide_list) {
            this.getDescription();
        }

    }

     render() {
        const { step, nb_step, t, dispatch } = this.props;
        let moment_end_date = moment.utc(step.end_date);
        let night_start_date = moment.utc(step.start_date);
        let nb_day = 0;
        let inc = 0;
        while (!moment.utc(night_start_date.add(inc, "d").format("YYYY-MM-DD")).isSame(moment.utc(moment_end_date.format("YYYY-MM-DD")))) {
            inc = 1;
            nb_day = nb_day + 1;
            if (nb_day === 100) {
                break;
            }
        }
        let picture = "/Img/default_dest.jpg";
        if (step !== null && step.destination !== null) {
            if (step.destination.data.cover_picture !== null) {
                if (step.destination.data.cover_picture.thumbnail_big !== null) {
                    picture = step.destination.data.cover_picture.thumbnail_big;
                } else {
                    picture = step.destination.data.cover_picture.url;
                }
            } else if (step.destination.data.pictures !== null && step.destination.data.pictures.length > 0) {
                if (step.destination.data.pictures[0].thumbnail_big !== null) {
                    picture = step.destination.data.pictures[0].thumbnail_big;
                } else {
                    picture = step.destination.data.pictures[0].url;
                }
            }
        }

        return (
            <div>
                {
                    step.step_type === "STEP" && (
                        <div className={"container-destination"} >
                            <div className={"step-destination"} >
                                <div className={"step-destination-left"} />
                                <div className={"step-destination-right"} > { t("filters.step") } { nb_step } </div>
                            </div>
                            <div className={"container-destination-img"} >
                                <img className={"ft-cover-img"} src={ picture } />
                            </div>
                            <div className={"container-destination-info"} >
                                <div className={"container-step-desti-nb-night"} >
                                    <span>
                                        { t("accommodation.from") + " " + moment.utc(step.start_date).format("DD/MM") + " " + t("accommodation.to") + " " + moment.utc(step.end_date).format("DD/MM") }
                                    </span>
                                    <span className={"italic"}>
                                        { " (" + nb_day + " " + (nb_day > 1 ? t("itinerary.nights") : t("itinerary.night")) + ")" }
                                    </span>
                                </div>
                                <div className={"container-text"} >
                                    <span className={"step-destination-title"} > { step.destination.data.name !== undefined ? step.destination.data.name : step.destination.data.international_name } </span>
                                    <span className={"step-destination-text ft-text-justify ft-font-13"} dangerouslySetInnerHTML={ {__html: this.state.description} } />
                                </div>
                                {
                                    step.destination.data.pictures.length > 1 && (
                                        <div className={"container-text"} >
                                            <span className={"ft-basic-link"} onClick={() => {
                                                dispatch(ToggleAndSetPicturesModal(step.destination.data.pictures, 0 ));
                                            }} > {t("summary.see_more_dest_pic")} </span>
                                        </div>
                                    )
                                }
                                <div className={"container-step-destination-category-container row ft-no-margin"} >
                                    {
                                        (step.destination.categories !== null && step.destination.categories.length > 0) && (
                                            <Fragment>
                                            {
                                                step.destination.categories.map((categorie, index_categorie) => {
                                                    return (
                                                        <div key={index_categorie} className={"container-step-destination-category"} > {
                                                            categorie.name
                                                        } </div>
                                                    );
                                                })
                                            }
                                            </Fragment>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
                <SummaryPicturesModal index={nb_step} />
            </div>
        );
    }
}

export default SummaryStepDestination;
