import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Avatar,
    IconButton,
    Stack,
    Step,
    StepContent,
    StepIconProps,
    StepLabel,
    stepLabelClasses,
    Stepper,
    StepperProps,
    styled,
    Tooltip,
    Typography
} from "@mui/material";
import {
    ArrowBackIosNew,
    ArrowForwardIos,
    Check,
    PriorityHigh,
    QuestionMark
} from "@mui/icons-material";
import { green, orange, grey } from "@mui/material/colors";
import { findLastIndex } from "lodash";
import {
    CartConstructionContentStepperByStepEditableDates
} from "./CartConstructionContentStepperByStepEditableDates";
import {
    CartConstructionContentStepperByDayEditableDates
} from "./CartConstructionContentStepperByDayEditableDates";
import { getStepStatus } from "../Itinerary/utils/getStepStatus";
import { getLocalizedValue } from "../Itinerary/utils/getLocalizedValue";
import { matchItineraryContentWithItinerary } from "./utils/matchItineraryContentWithItinerary";
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";
import { AppState } from "../../Reducers/Reducers";

type ItemComponentProps = {
    children: JSX.Element,
    index: number,
    step: ItineraryContentStep,
    onClick: () => void
}

export type CartConstructionItineraryContentStepperProps = {
    step?: number,
    steps: ItineraryContentStep[],
    setStep?: (step: number) => void,
    showPreview?: boolean,
    showStatus?: boolean,
    orientation?: StepperProps['orientation'],
    alternativeLabel?: StepperProps['alternativeLabel'],
    disableDatesEdit?: boolean,
    ItemComponent?: React.FC<ItemComponentProps>
}

export const CartConstructionItineraryContentStepper = React.forwardRef<HTMLDivElement, CartConstructionItineraryContentStepperProps>(
    function CartConstructionItineraryContentStepper(props, ref): JSX.Element {
        const { t } = useTranslation();
        const locale = useSelector((state: AppState) => state.locale.current_locale);
        const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
        const containerRef = useRef<HTMLDivElement>(null);
        const itemsRef = useRef<HTMLDivElement[]>([]);
        const [areElementsAllVisible, setAreElementsAllVisible] = useState(false);
        const [biggestElementWidth, setBiggestElementWidth] = useState(0);
        const stepsWithItinerary = useMemo(() => {
            return matchItineraryContentWithItinerary(
                props.steps,
                itinerary
            );
        }, [props.steps, itinerary]);
    
        const onPrev = () => {
            const firstVisibleElementIndex = itemsRef.current.findIndex((item) => {
                return isElementVisible(containerRef.current, item);
            });
            itemsRef.current[firstVisibleElementIndex - 1]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        };
    
        const onNext = () => {
            const lastVisibleElementIndex = findLastIndex(
                itemsRef.current,
                (item) => {
                    return isElementVisible(containerRef.current, item);
                }
            );
            itemsRef.current[lastVisibleElementIndex + 1]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        };
    
        useEffect(() => {
            itemsRef.current = itemsRef.current.slice(0, props.steps.length);
            const resizeObserver = new ResizeObserver((entries) => {
                const maxWidth = entries.reduce((prev, current) => {
                    return prev > current.target.clientWidth ?
                        prev :
                        current.target.clientWidth;
                }, 0);
                setBiggestElementWidth((state) => {
                    return maxWidth > state ?
                        maxWidth + 10 :
                        state;
                });
            });
            const children = itemsRef.current.map((item) => {
                return item.querySelector('.label-content > div');
            }).filter((item): item is NonNullable<typeof item> => !!item);
            children.forEach((item) => {
                resizeObserver.observe(item);
            });
            return () => resizeObserver.disconnect();
        }, [props.steps]);
    
        return (
            <Stack ref={ref} direction="row" alignItems="center">
                {
                    !areElementsAllVisible &&
                    props.orientation === 'horizontal' &&
                    <IconButton onClick={onPrev}>
                        <ArrowBackIosNew />
                    </IconButton>
                }
                <Stepper
                    ref={containerRef}
                    activeStep={props.step}
                    orientation={props.orientation ?? 'vertical'}
                    alternativeLabel={props.alternativeLabel}
                    sx={{ overflow: 'hidden', flex: 1 }}
                    nonLinear
                >
                    {
                        stepsWithItinerary.map((item, index, array) => {
                            const step = item.content;
                            const nextStep = array[index + 1]?.content;
                            const status = getStepStatus(step);
                            let icon = null;
                            let statusMessage = '';
                            switch (status) {
                                case 'not-filled': {
                                    icon = NotFilledIcon;
                                    statusMessage = t<string>('shared.circuit-step-not-filled');
                                    break;
                                }
                                case 'some-missing': {
                                    icon = WarningIcon;
                                    statusMessage = t<string>('shared.circuit-step-some-missing');
                                    break;
                                }
                                case 'correct': {
                                    icon = SuccessIcon;
                                    statusMessage = t<string>('shared.circuit-step-correct');
                                    break;
                                }
                            }
    
                            let destination = '';
    
                            if (step.mode === 'by-day') {
                                destination = step.content.destinations?.map((destination) => {
                                    let result = getLocalizedValue(
                                        locale,
                                        destination?.data ?? {
                                            name: '',
                                            localization: []
                                        },
                                        'name'
                                    ) ?? '';
                                    result = result.length > 0 ?
                                        result :
                                        (
                                            destination?.data?.international_name ?? ''
                                        );
                                    return result.split(',')[0] ?? '';
                                }).join(' - ') ?? '';
                            } else {
                                const nightDestination = step.content.destination;
                                destination = getLocalizedValue(
                                    locale,
                                    nightDestination?.data ?? {
                                        name: '',
                                        localization: []
                                    },
                                    'name'
                                ) ?? '';
                                destination = destination.length > 0 ?
                                    destination :
                                    (
                                        nightDestination?.data?.international_name ?? ''
                                    );
                                destination = destination.split(',')[0] ?? '';
                            }
                            const content = (
                                <>
                                    <StepLabel
                                        StepIconComponent={icon}
                                        optional={
                                            <Stack>
                                                <Tooltip title={destination}>
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            "display": "-webkit-box",
                                                            '-webkit-line-clamp': '1',
                                                            '-webkit-box-orient': 'vertical',
                                                            "overflow": 'hidden',
                                                            "textOverflow": 'ellipsis'
                                                        }}
                                                    >
                                                        {
                                                            t<string>(
                                                                'shared.circuit-step-destination',
                                                                { name: destination }
                                                            )
                                                        }
                                                    </Typography>
                                                </Tooltip>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        '& span': {
                                                            display: 'block'
                                                        }
                                                    }}
                                                    component="div"
                                                    className="label-content"
                                                >
                                                    {
                                                        step.mode === 'by-step' &&
                                                        <CartConstructionContentStepperByStepEditableDates
                                                            index={index}
                                                            item={item}
                                                            disableDatesEdit={props.disableDatesEdit}
                                                        />
                                                    }
                                                    {
                                                        step.mode === 'by-day' &&
                                                        <CartConstructionContentStepperByDayEditableDates
                                                            index={index}
                                                            item={item}
                                                            steps={props.steps}
                                                            isEditable={
                                                                !props.disableDatesEdit &&
                                                                Boolean(
                                                                    (step.content.destinations?.length ?? 0) > 1 ||
                                                                    !nextStep ||
                                                                    (
                                                                        step.content.destinations?.[0]?.id &&
                                                                        nextStep.mode === 'by-day' &&
                                                                        !nextStep.content.destinations?.map((item) => {
                                                                            return item.id;
                                                                        }).includes(step.content.destinations[0]?.id)
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    }
                                                </Typography>
                                            </Stack>
                                        }
                                        sx={{
                                            [`.${stepLabelClasses.active}`]: {
                                                color: '#1976D2 !important',
                                                textDecoration: 'underline'
                                            }
                                        }}
                                    >
                                        {
                                            step.mode === 'by-step' ?
                                                t<string>('itinerary.step-no', { no: index + 1 }) :
                                                t<string>(
                                                    'shared.circuit-step-days',
                                                    { days: [...step.content.day].sort((a, b) => a - b).join(', ') }
                                                )
                                        }
                                    </StepLabel>
                                    {
                                        props.orientation === 'vertical' &&
                                        <StepContent>
                                            {
                                                status === 'not-filled' &&
                                                <Typography variant="body2" color="text.secondary">
                                                    {t<string>('shared.circuit-step-not-filled')}
                                                </Typography>
                                            }
                                            {
                                                status === 'some-missing' &&
                                                <Typography variant="body2" color="text.secondary">
                                                    {t<string>('shared.circuit-step-some-missing')}
                                                </Typography>
                                            }
                                            {
                                                status === 'correct' &&
                                                <Typography variant="body2" color="text.secondary">
                                                    {t<string>('shared.circuit-step-correct')}
                                                </Typography>
                                            }
                                        </StepContent>
                                    }
                                </>
                            );
    
                            let tooltipMessage = '';
    
                            if (props.showStatus) {
                                tooltipMessage = statusMessage;
                            }
    
                            if (props.showPreview) {
                                tooltipMessage = step.content.localization.find((item) => {
                                    return item.locale === locale;
                                })?.title ??
                                step.content.title ??
                                '';
                            }
    
                            return (
                                <Tooltip
                                    key={index}
                                    title={tooltipMessage}
                                    disableInteractive
                                >
                                    {
                                        props.ItemComponent ?
                                            <props.ItemComponent
                                                index={index}
                                                step={step}
                                                onClick={() => props.setStep && props.setStep(index)}
                                            >
                                                {content}
                                            </props.ItemComponent> :
                                            <StepContainer
                                                ref={(element) => {
                                                    if (element) {
                                                        itemsRef.current[index] = element;
                                                        setAreElementsAllVisible(
                                                            itemsRef.current.every((item) => {
                                                                return isElementVisible(containerRef.current, item);
                                                            })
                                                        );
                                                    }
                                                }}
                                                onClick={() => props.setStep && props.setStep(index)}
                                                sx={{ minWidth: biggestElementWidth }}
                                            >
                                                {content}
                                            </StepContainer>
                                    }
                                </Tooltip>
                            );
                        })
                    }
                </Stepper>
                {
                    !areElementsAllVisible && 
                    props.orientation === 'horizontal' &&
                    <IconButton onClick={onNext}>
                        <ArrowForwardIos />
                    </IconButton>
                }
            </Stack>
        );
    }
);

const isElementVisible = (containerElement: HTMLDivElement | null, itemElement: HTMLDivElement) => {
    if (containerElement) {
        const container = containerElement.getBoundingClientRect();
        const element = itemElement.getBoundingClientRect();
        const containerLeft = Math.trunc(container.left);
        const containerRight = Math.trunc(container.right);
        const elementLeft = Math.trunc(element.left);
        const elementRight = Math.trunc(element.right);
        return (
            containerLeft <= elementLeft &&
            elementLeft <= containerRight
        ) && (
            containerLeft <= elementRight &&
            elementRight <= containerRight
        );
    }
    return false;
};

const StepContainer = styled(Step)(() => ({
    cursor: 'pointer'
}));

const SuccessIcon = (props: StepIconProps): JSX.Element => {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : green[500],
                width: 24,
                height: 24
            }}
        >
            <Check sx={{ fontSize: 15 }} />
        </Avatar>
    );
};

const WarningIcon = (props: StepIconProps): JSX.Element => {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : orange[500],
                width: 24,
                height: 24
            }}
        >
            <PriorityHigh sx={{ fontSize: 15 }} />
        </Avatar>
    );
};

const NotFilledIcon = (props: StepIconProps): JSX.Element => {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24
            }}
        >
            <QuestionMark sx={{ fontSize: 15 }} />
        </Avatar>
    );
};
