//---- Dependencies ----//
import React from 'react';
import { useTranslation } from "react-i18next";
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from "@material-ui/core/styles";

//---- CSS ----//
import "../../Style/Footer.css";

const useStyles = makeStyles(theme => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    footer: {
        height: "auto",
        padding: "25px 15%",
        color: "grey"
    },
    textFooter: {
        float: "none",
        display: "block",
        margin: "auto!important",
        textAlign: "center !important"
    }
}));

const Footer = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const today = new Date();
    return (
        <Grid container className={classes.footer} direction={"row"} justify={"center"} alignItems={"center"}>
            <Grid item style={{textAlign: "center"}}>
            {"©2013 - " + today.getFullYear() + " FacilitaTrip - " + t("footer.all_rights")}
            </Grid>
        </Grid>
    )
}

export default Footer;