import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Close, ArrowForward } from "@mui/icons-material";
import {
    Dialog,
    IconButton,
    Typography,
    DialogContent,
    Button,
    Grid,
    Card,
    CardHeader,
    Divider,
    Box,
    styled,
    MenuItem,
    Alert,
    AlertTitle,
    Select,
    FormControl,
    InputLabel,
    DialogTitle,
    Stack,
    DialogActions
} from "@mui/material";
import { isString, mapValues, pickBy } from "lodash";
import { useSnackbar } from "notistack";
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";
import { CartConstructionItineraryContentAutomaticStepsList } from "./CartConstructionItineraryContentAutomaticStepsList";
import { CartConstructionItineraryContentDragndropStepsList } from "./CartConstructionItineraryContentDragndropStepsList";
import { isContentStepEqual } from "./utils/isContentStepEqual";
import { CartConstructionItineraryContentVersion } from "./CartConstructionItineraryContentVersion";
import { useItineraryContentUpdate } from "../Itinerary/network/itineraryContentUpdate";
import { useTripVersions } from "./utils/tripVersions";
import { useShowError } from "../Utils/showError";
import { setLoading } from "./redux/cartConstructionReducer";
import { setItineraryContent, setItineraryContentInputs } from "../Itinerary/redux/reducer";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    onClose: () => void,
}

enum Mode {
    AUTO,
    DRAGNDROP
}

export function CartConstructionItineraryContentCopyModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const itineraryAllContent = useSelector((state: AppState) => state.itinerarySlice.content);
    const [inputs, setInputs] = useState<{[version: number]: ItineraryContentStep[]}>({});
    const [sourceVersion, setSourceVersion] = useState<number | null>(null);
    const [targetVersion, setTargetVersion] = useState<number | null>(null);
    const [simulate, setSimulate] = useState(false);
    const [mode, setMode] = useState<Mode>(Mode.AUTO);
    const originalData = useMemo(() => {
        const result = mapValues(
            itineraryAllContent,
            (item) => {
                if (item.state === 'success') {
                    return item.data.content.map((content) => {
                        return {
                            mode: item.data.mode,
                            content
                        } as ItineraryContentStep;
                    });
                }
            }
        );
        return pickBy(
            result,
            (item) => item
        ) as {[index: number]: NonNullable<typeof result[keyof typeof result]>};
    }, [itineraryAllContent]);
    const versions = useTripVersions();
    const showError = useShowError();
    const update = useItineraryContentUpdate({
        onSuccess(_, version, steps) {
            if (version) {
                dispatch(
                    setItineraryContent({
                        version,
                        data: {
                            state: 'success',
                            data: steps
                        }
                    })
                );
                dispatch(
                    setItineraryContentInputs({
                        version,
                        data: {
                            state: 'success',
                            data: steps
                        }
                    })
                );
            }
        },
        onError(error) {
            console.error(error);
            showError(error);
        }
    });
    const hasMatchInAutoMode = sourceVersion &&
        targetVersion &&
        !inputs[sourceVersion]?.every((source) => {
            return (
                inputs[targetVersion]?.findIndex((target) => {
                    return isContentStepEqual(source, target);
                }) ?? -1
            ) < 0;
        });

    const StepsList = mode === Mode.AUTO ?
        CartConstructionItineraryContentAutomaticStepsList :
        CartConstructionItineraryContentDragndropStepsList;

    const onSave = async () => {
        if (sourceVersion && targetVersion) {
            const source = inputs[sourceVersion] ?? [];
            const target = inputs[targetVersion] ?? [];

            let result: {
                [version: number]: ItineraryContentStep[]
            } = {};

            switch (mode) {
                case Mode.AUTO: {
                    result = simulate ?
                        {
                            ...originalData,
                            [targetVersion]: target.map((item) => {
                                const sourceItem = source.find((value) => {
                                    return isContentStepEqual(item, value);
                                });

                                return sourceItem ?? item;
                            })
                        } :
                        originalData;
                    break;
                }
                case Mode.DRAGNDROP: {
                    result = inputs;
                    break;
                }
            }

            dispatch(setLoading(true));
            for (const key of Object.keys(result)) {
                const version = parseInt(key);
                const data = result[version];
                if (data) {
                    const response = await update('unknown', version, data);
                    if (!response) {
                        dispatch(setLoading(false));
                        return;
                    }
                }
            }
            enqueueSnackbar(t<string>('shared.circuit-steps-updated'), { variant: 'success' });
            props.onClose();
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (versions[0]) {
            setSourceVersion(versions[0].id);
        }
    }, [versions]);

    useEffect(() => {
        setInputs(originalData);
    }, [originalData]);

    useEffect(() => {
        setSimulate(false);
    }, [mode]);

    return (
        <Dialog open onClose={props.onClose} maxWidth="lg">
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant={ 'h6' }>{t<string>('shared.circuit-steps-duplicate')}</Typography>
                    <IconButton edge={ 'start' } color={ 'inherit' } onClick={props.onClose}>
                        <Close/>
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {
                    sourceVersion &&
                    <Grid container spacing={2} justifyContent="center" sx={{ marginTop: 1.5 }}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>{t<string>('shared.circuit-steps-duplicate-mode')}</InputLabel>
                                <Select
                                    label="Mode"
                                    value={mode}
                                    onChange={(event) => setMode(isString(event.target.value) ? Mode.AUTO : event.target.value)}
                                >
                                    <MenuItem
                                        value={Mode.AUTO}
                                        disabled={
                                            targetVersion === null ||
                                            !hasMatchInAutoMode
                                        }
                                    >
                                        {t<string>('shared.circuit-steps-duplicate-mode-auto')}
                                        {
                                            targetVersion === null &&
                                            ` (${t<string>('shared.circuit-steps-choose-version').toLowerCase()})`
                                        }
                                        {
                                            targetVersion && !hasMatchInAutoMode &&
                                            ` (${t<string>('shared.circuit-steps-no-match')})`
                                        }
                                    </MenuItem>
                                    <MenuItem value={Mode.DRAGNDROP}>
                                        {t<string>('shared.circuit-steps-duplicate-mode-dragndrop')}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <Alert severity="info" sx={{ marginTop: 1 }}>
                                <AlertTitle>Info</AlertTitle>
                                {
                                    mode === Mode.DRAGNDROP ?
                                        t<string>('shared.circuit-dragndrop-duplicate-hint') :
                                        t<string>('shared.circuit-automatic-duplicate-hint')
                                }
                            </Alert>
                        </Grid>
                        <Grid item xs={5}>
                            <VersionContainer>
                                <CardHeader
                                    title={
                                        <CartConstructionItineraryContentVersion
                                            version={sourceVersion}
                                            onSetVersion={setSourceVersion}
                                        />
                                    }
                                />
                                <Divider />
                                <StepsList
                                    simulate={simulate}
                                    version={sourceVersion}
                                    sourceVersion={sourceVersion}
                                    targetVersion={targetVersion}
                                    steps={originalData}
                                    inputs={inputs}
                                    onChangeInputs={setInputs}
                                />
                            </VersionContainer>
                        </Grid>
                        <Grid item xs={2}>
                            <Box
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <IconButton onClick={() => setSimulate(true)}>
                                    <ArrowForward />
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <VersionContainer>
                                <CardHeader
                                    title={
                                        <CartConstructionItineraryContentVersion
                                            version={targetVersion}
                                            onSetVersion={setTargetVersion}
                                            exclude={[sourceVersion]}
                                            canBeEmpty
                                        />
                                    }
                                />
                                <Divider />
                                {
                                    targetVersion &&
                                    <StepsList
                                        simulate={simulate}
                                        version={targetVersion}
                                        sourceVersion={sourceVersion}
                                        targetVersion={targetVersion}
                                        steps={originalData}
                                        inputs={inputs}
                                        onChangeInputs={setInputs}
                                    />
                                }
                            </VersionContainer>
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{ t<string>('shared.cancel') }</Button>
                <Button onClick={onSave}>
                    { t<string>('shared.duplicate') }
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const VersionContainer = styled(Card)(() => ({
    width: '100%',
    height: '100%'
}));
