//---- Functions ----//
import PatchDepartureReturn from "./PatchDepartureReturn";

export default (google, itinerary_return_same, itinerary_list, currency, dispatch, t, start_date, end_date, user) => {
    let itinerary_departure_input = document.getElementById('itinerary-departure-input');
    let itinerary_return_input = document.getElementById('itinerary-return-input');
    if (itinerary_departure_input !== null && itinerary_return_input !== null) {
        let autocompleteDeparture = new google.maps.places.Autocomplete(itinerary_departure_input);
        let autocompleteReturn = new google.maps.places.Autocomplete(itinerary_return_input);
        autocompleteDeparture.addListener('place_changed', function () {
            let place = autocompleteDeparture.getPlace();
            if (!!place.geometry) {
                let request = {
                    city_departure: null,
                    country_departure: null,
                    place_id_departure: place.place_id,
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                };
                if (place.address_components !== undefined) {
                    place.address_components.forEach(function(c) {
                        switch(c.types[0]){
                            case 'neighborhood': case 'locality': case 'airport':
                                request.city_departure = c.long_name;
                                break;
                            case 'country':
                                request.country_departure = c.long_name;
                                break;
                        }
                    });
                }
                if (request.city_departure === null) {
                    request.city_departure = place.name;
                }
                if (itinerary_return_same) {
                    request.city_return = request.city_departure;
                    request.country_return = request.country_departure;
                    request.place_id_return = request.place_id_departure;
                }
                PatchDepartureReturn(request, itinerary_list, currency, dispatch, start_date, end_date, t, user);
            }
        });
        autocompleteReturn.addListener('place_changed', function () {
            let place = autocompleteReturn.getPlace();
            if (!!place.geometry) {
                let request = {
                    city_return: null,
                    country_return: null,
                    place_id_return: place.place_id,
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                };
                if (place.address_components !== undefined) {
                    place.address_components.forEach(function(c) {
                        switch(c.types[0]){
                            case 'neighborhood': case 'locality': case 'airport':
                                request.city_return = c.long_name;
                                break;
                            case 'country':
                                request.country_return = c.long_name;
                                break;
                        }
                    });
                }
                if (request.city_departure === null) {
                    request.city_departure = place.name;
                }
                PatchDepartureReturn(request, itinerary_list, currency, dispatch, start_date, end_date, t, user);
            }
        });
    }
}