import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import { Box } from '@mui/material';
import { isNumber } from 'lodash';

import SelectLead from './SelectLead.js';
import Typography from "@material-ui/core/Typography";
import { isProductPackaged } from '../CartMaterial/utils/isProductPackaged';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight700: {
        fontWeight: 700
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize14: {
        fontSize: 14
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter: {
        textAlign: "center"
    },
    genericBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box"
    },
    genericPadding: {
        padding: "4px 8px"
    }
}));

const BookingInsuranceInfo = ({ tempTravelers, setTempTravelers }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));

    const to_book = useSelector(store => store.cart.to_book);
    const trip = useSelector(store => store.trip.data_trip);

    const [insurancesToBook, setInsurancesToBook] = useState([]);
    const connectedPackageProducts = useMemo(() => {
        return insurancesToBook.filter((insurance) => {
            return isProductPackaged({
                product: insurance,
                stackInfos: trip?.stack_info ?? null,
                connected: true
            });
        });
    }, [insurancesToBook]);

    useEffect(() => {
        let temp_insurances = [];

        to_book.map((product) => {
            if (product.is_displayed === true && (product.booking_status === null || (product.booking_status.status_booking === "ERROR"))) {
                if (product.product_type === 8 && product.group_passenger.default === false) {
                    let temp_product = Object.assign({}, product);
                    temp_product.group_passenger.lead_traveler_info = temp_product.group_passenger.lead_traveler_info !== null ? temp_product.group_passenger.lead_traveler_info : "";
                    temp_product.group_passenger.lead_traveler = temp_product.group_passenger.lead_traveler !== null ? temp_product.group_passenger.lead_traveler : "";
                    temp_insurances.push(temp_product);
                }
            }
        });

        setInsurancesToBook(temp_insurances);
    }, [to_book]);

    return (
        <Grid>
            {
                insurancesToBook.length !== 0 && (
                    <Grid className={`${classes.genericPadding} ${classes.genericBorder}`} style={{ marginBottom: 12 }}>
                        <Grid className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.upperCase} ${classes.letterSpacing}`}>
                            { t("shared.insurance") }
                        </Grid>
                        <Grid style={{ marginBottom: 8 }}>
                            {
                                insurancesToBook.filter((insurance, index, array) => {
                                    return !isProductPackaged({
                                        product: insurance,
                                        stackInfos: trip?.stack_info ?? null,
                                        connected: true
                                    }) ||
                                    array.slice(0, index).findIndex((insurance) => {
                                        return isProductPackaged({
                                            product: insurance,
                                            stackInfos: trip?.stack_info ?? null,
                                            connected: true
                                        });
                                    }) < 0;
                                }).map((insurance) => {
                                    return (
                                        <Grid key={`insurance-${insurance.id}`} container alignItems="baseline" justify="space-between" style={{ padding: 8 }}>
                                            <Grid item md={6} xs={12} className={`${classes.genericText} ${classes.fontWeight700} ${classes.fontSize14} ${classes.upperCase} ${classes.letterSpacing}`}>
                                                {
                                                    !isProductPackaged({
                                                        product: insurance,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    <>
                                                        { insurance.contract_name } {
                                                            isProductPackaged({
                                                                product: insurance,
                                                                stackInfos: trip?.stack_info ?? null
                                                            }) &&
                                                            <Box sx={{ color: '#2F80ED' }} component="span">
                                                                ({t('cart-material.cart-construction-package-name', { name: insurance.stack_number })})
                                                            </Box>
                                                        }
                                                        {
                                                            insurance.product_type === 8 && !!insurance.group_passenger && insurance.group_passenger.travelers_list.map(traveler => <Typography key={ `insurance-traveler-${traveler.id}` } variant="subtitle2">{ traveler.first_name } { traveler.last_name }</Typography>)
                                                        }
                                                    </>
                                                }
                                                {
                                                    isProductPackaged({
                                                        product: insurance,
                                                        stackInfos: trip?.stack_info ?? null,
                                                        connected: true
                                                    }) &&
                                                    <Box sx={{ color: '#2F80ED' }} component="span">
                                                        {t('cart-material.cart-construction-connected-package')}
                                                    </Box>
                                                }
                                            </Grid>
                                            <Grid item md={6} xs={12} style={!downDesktop ? {} : { paddingTop: 8 }}>
                                                {
                                                    insurance.group_passenger.default === false && (
                                                        <>
                                                            {
                                                                !isProductPackaged({
                                                                    product: insurance,
                                                                    stackInfos: trip?.stack_info ?? null,
                                                                    connected: true
                                                                }) &&
                                                                <SelectLead group={insurance.group_passenger} mode={"insurance"} product={insurance} product_list={null} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} />
                                                            }
                                                            {
                                                                isProductPackaged({
                                                                    product: insurance,
                                                                    stackInfos: trip?.stack_info ?? null,
                                                                    connected: true
                                                                }) &&
                                                                <SelectLead
                                                                    group={insurance.group_passenger}
                                                                    mode={"group"}
                                                                    product={null}
                                                                    product_list={connectedPackageProducts}
                                                                    tempTravelers={tempTravelers}
                                                                    setTempTravelers={setTempTravelers}
                                                                />
                                                            }
                                                        </>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    );
};

export default React.memo(BookingInsuranceInfo);
