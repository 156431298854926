import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from "@material-ui/core/Button";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles(theme => ({
    formControlRoot: {
        borderRadius: "20px",
        textTransform: "capitalize",
        marginTop: 2,
        padding: 7,
        height: 41,
        boxShadow: 'none'
    },
    orangeButton: {
        //backgroundColor: "#E6592F",
        backgroundColor: "transparent",
        color: "#E6592F",
        padding: '7px 16px'
    },
    whiteButton: {
        backgroundColor: "white",
        color: "black"
    }
}));

const CartHeader = ({ router, setIsOpen }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = useSelector((store) => {
        return store.user.user;
    });
    const bdd_cart = useSelector((store) => {
        return store.accommodation.cart;
    });
    const car_cart = useSelector((store) => {
        return store.cars_search.cart;
    });
    const flight_cart = useSelector((store) => {
        return store.flight.cart;
    });
    const manual_item_list = useSelector((store) => {
        return store.cart.manual_item_list;
    });
    const transfers_cart = useSelector((store) => {
        return store.transfers.cart;
    });
    const poi_cart = useSelector((store) => {
        return store.poi.cart;
    });
    const data_trip = useSelector((store) => {
        return store.trip.data_trip;
    });
    const assistance_cart = useSelector(state => state.cart.assistance_cart);
    const hasStackedItems = useSelector(state => state.cart.hasStackedItems);

    const [nbItems, setNbItems] = useState(0);
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [poi_cart, assistance_cart, transfers_cart, manual_item_list, flight_cart, car_cart, bdd_cart]);
    const getData = () => {
        let nb_items = 0;
        let custom_cost = false;
        if (data_trip !== null && data_trip.prices_flight.length > 0) {
            custom_cost = !hasStackedItems;
        }
        if (data_trip !== null && data_trip.prices_terrestrial.length > 0) {
            custom_cost = !hasStackedItems;
        }
        if (data_trip !== null && data_trip.prices_stack_product.length > 0) {
            custom_cost = true;
        }
        if (user !== undefined && user !== null && user.client !== undefined && user.client !== null) {
            for (let i = 0; i < flight_cart.length; i++) {
                if (flight_cart[i].is_optional === false && flight_cart[i].is_displayed !== undefined && flight_cart[i].is_displayed !== false && (flight_cart[i].is_available !== undefined && flight_cart[i].is_available !== false) && (flight_cart[i].booking_status === null || flight_cart[i].booking_status.status_booking !== "CANCELLED")) {
                    nb_items++;
                }
            }
            for (let i = 0; i < bdd_cart.length; i++) {
                if (bdd_cart[i].is_optional === false && bdd_cart[i].is_displayed !== undefined && bdd_cart[i].is_displayed !== false && (bdd_cart[i].is_available !== undefined && bdd_cart[i].is_available !== false) && (bdd_cart[i].booking_status === null || bdd_cart[i].booking_status.status_booking !== "CANCELLED")) {
                    nb_items++;
                }
            }
            for (let i = 0; i < poi_cart.length; i++) {
                if (poi_cart[i].is_optional === false && poi_cart[i].is_displayed !== undefined && poi_cart[i].is_displayed !== false && (poi_cart[i].is_available !== undefined && poi_cart[i].is_available !== false) && (poi_cart[i].booking_status === null || poi_cart[i].booking_status.status_booking !== "CANCELLED")) {
                    nb_items++;
                }
            }
            for (let i = 0; i < assistance_cart.length; i++) {
                if (assistance_cart[i].is_optional === false && assistance_cart[i].is_displayed !== undefined && assistance_cart[i].is_displayed !== false && (assistance_cart[i].is_available !== undefined && assistance_cart[i].is_available !== false) && (assistance_cart[i].booking_status === null || assistance_cart[i].booking_status.status_booking !== "CANCELLED")) {
                    nb_items++;
                }
            }
            for (let i = 0; i < transfers_cart.length; i++) {
                if (transfers_cart[i].is_optional === false && transfers_cart[i].is_displayed !== undefined && transfers_cart[i].is_displayed !== false && (transfers_cart[i].is_available !== undefined && transfers_cart[i].is_available !== false) && (transfers_cart[i].booking_status === null || transfers_cart[i].booking_status.status_booking !== "CANCELLED")) {
                    nb_items++;
                }
            }
            for (let i = 0; i < manual_item_list.length; i++) {
                if (quotation_code === 'visiteurs' && manual_item_list[i].auto_product === null) {
                    // if (manual_item_list[i].is_available === undefined || manual_item_list[i].is_available === true) {
                    if (user.client_full.type === 2 || (user.client_full.type !== 2 && user.client_full.type === manual_item_list[i].creator.client.type)) {
                        if (manual_item_list[i].prices) {
                            if (custom_cost) {
                                nb_items += 1;
                            } else if (manual_item_list[i].is_optional === false) {
                                nb_items += 1;
                            }
                        }
                    }
                } else {
                    if (manual_item_list[i].prices) {
                        if (custom_cost) {
                            nb_items += 1;
                        } else if (manual_item_list[i].is_optional === false) {
                            nb_items += 1;
                        }
                    }
                }
            }
            for (let i = 0; i < car_cart.length; i++) {
                if (car_cart[i].is_optional === false && car_cart[i].is_displayed !== undefined && car_cart[i].is_displayed !== false && (car_cart[i].is_available !== undefined && car_cart[i].is_available !== false) && (car_cart[i].booking_status === null || car_cart[i].booking_status.status_booking !== "CANCELLED")) {
                    nb_items++;
                }
            }
        }
        setNbItems(nb_items);
    };
    return (
        <Button className={`${classes.formControlRoot} ${classes.orangeButton}`} variant={"text"} disableRipple={true} startIcon={<ShoppingCartIcon/>} onClick={() => {
            setIsOpen(null);
            dispatch({
                type: "CART_SET_STEP",
                payload: {
                    step: 1
                }
            });
            router.push(`/${window.url_name}/apps/cart`);
        }}>
            {`(${nbItems})`}
        </Button>
    );
};

export default React.memo(CartHeader);
