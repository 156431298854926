//Dependencies
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//Core
import { Button } from '@mui/material';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
//Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import CheckResponse from '../Flight/FlightSelected/Functions/CheckResponse';

const useStyles = makeStyles({
    validateButton: {
        marginTop: 15,
        backgroundColor: '#E6592F',
        color: 'white'
    }
});

const CartFlightNote = ({ flight, onAddNote }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [note, setNote] = useState(flight.custom_information !== null ? flight.custom_information : '');
    const end_date = useSelector(state => state.trip.end_date);

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const onNoteChange = event => {
        setNote(event.target.value);
    };
    const onValidateNote = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`,
            data: {
                custom_information: note
            }
        }).then(response => {
            let new_flight = [];
            new_flight.push(response.data);
            let arr = CheckResponse(new_flight, end_date);
            dispatch({ type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0] });
            onAddNote();
        }).catch(error => {
            console.log(error);
        });
    };
    return (
        <Fragment>
            <FormControl fullWidth variant={ 'outlined' }>
                <InputLabel htmlFor={ 'note' }>{ flight.custom_information !== null ? ["cercledesvoyages", "connections", 'terreslointaines'].includes(quotation_code) ? t('cart-material.edit-note-client') : t('cart-material.edit-note') : (["cercledesvoyages"].includes(quotation_code) ? t('cart-material.add-note-client') : t('cart-material.add-note')) }</InputLabel>
                <OutlinedInput
                    id={ 'note' }
                    type={ 'text' }
                    multiline
                    rows={ 8 }
                    onChange={ onNoteChange }
                    label={ ["cercledesvoyages", "connections", 'terreslointaines'].includes(quotation_code) ? t('cart-material.add-note-client') : t('cart-material.add-note') }
                    value={ note }
                />
            </FormControl>
            <Button onClick={ onValidateNote } sx={{ display: 'flex', marginLeft: 'auto', marginTop: 2 }}>
                { t('global.validate') }
            </Button>
        </Fragment>
    );
};

export default CartFlightNote;
