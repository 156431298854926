import React from "react";
import { useTranslation } from "react-i18next";
import {
    IconButton,
    ListItemProps,
    Stack,
    styled,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { isString } from "lodash";

type Props = {
    transportColor: string,
    children: JSX.Element,
    sx?: ListItemProps['sx'],
    reverseTail?: boolean,
    onClose: () => void,
    disableClose?: boolean
}

export function ItineraryTransportItemAddInputContainer(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <NewStepContainer
            direction="row"
            alignItems="center"
            transportColor={props.transportColor}
            reverseTail={!!props.reverseTail}
            sx={props.sx}
        >
            <Typography
                whiteSpace="nowrap"
                textTransform="uppercase"
                fontWeight={500}
                component="div"
                sx={{
                    fontSize: '0.75rem',
                    color: '#2C98F0',
                    marginRight: 1
                }}
            >
                {t<string>('itinerary.next-step')}
            </Typography>
            {props.children}
            {
                !props.disableClose &&
                <IconButton
                    size="small"
                    sx={{ marginRight: -4.5, marginLeft: 1 }}
                    onClick={props.onClose}
                >
                    <Close fontSize="small" />
                </IconButton>
            }
        </NewStepContainer>
    );
}

const NewStepContainer = styled(
    Stack,
    {
        shouldForwardProp(name) {
            return !isString(name) ||
                !['transportColor', 'reverseTail'].includes(name);
        }
    }
)<{
    transportColor: string,
    reverseTail: boolean,
}>((props) => ({
    "border": '1px solid #ddd',
    "borderRadius": 8,
    "marginTop": props.theme.spacing(2),
    "paddingLeft": props.theme.spacing(5),
    "paddingRight": props.theme.spacing(5),
    "paddingTop": '10px',
    "paddingBottom": '10px',
    "position": 'relative',
    '&:before': {
        content: '""',
        width: 3,
        height: props.theme.spacing(1.5),
        backgroundColor: props.transportColor,
        position: 'absolute',
        top: !props.reverseTail ? props.theme.spacing(-1.875) : undefined,
        bottom: props.reverseTail ? 0 : undefined,
        left: 12.5,
        transform: 'translateX(-100%)',
        zIndex: -1
    }
}));
