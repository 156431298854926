import axios, { AxiosResponse } from "axios";
import memoizee from "memoizee";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { ItineraryByDay } from "../../../Itinerary/objects/itineraryByDay";

async function fetch(circuitId: number): Promise<ItineraryByDay[]> {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        try {
            let next: string | null = `${API_HREF}client/${window.id_owner}/circuits/${circuitId}/by-day/`;
            const result: ItineraryByDay[] = [];
            do {
                const response: AxiosResponse<{
                    next: string | null,
                    results: ItineraryByDay[]
                }> = await axios.get(
                    next,
                    { headers }
                );
                result.push(...response.data.results);
                next = response.data.next;
            } while (next);
            return result;
        } catch (error: any) {
            console.error(error);
        }
    }
    return [];
}

export const findCircuitGIRContent = memoizee(
    fetch,
    { promise: true, primitive: true }
);
