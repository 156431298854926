import { Template } from "../utils/handlebars/roadbook.handlebars";

export function createPictureContent(url: string): Parameters<Template>[0]['contents'][0] {
    return {
        type: 'picture',
        anchor: 'picture',
        showInSummary: false,
        title: "Picture",
        url
    };
}
