import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuProps
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { isFunction } from "lodash";
import { AppState } from "../../Reducers/Reducers";

type Filter = 'masked' | 'visible' | 'cancellable' | 'non-cancellable'

type Props = {
    open: boolean,
    anchorEl?: MenuProps['anchorEl'],
}

export function CartConstructionProductCharacteristicMenu(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const filters_masked_items = useSelector((state: AppState) => state.cart.filters_masked_items);
    const filters_visible_items = useSelector((state: AppState) => state.cart.filters_visible_items);
    const filters_cancellable_items = useSelector((state: AppState) => state.cart.filters_cancellable_items);
    const filters_non_cancellable_items = useSelector((state: AppState) => state.cart.filters_non_cancellable_items);
    const [container, setContainer] = useState<HTMLUListElement | null>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [anchorElPosition, setAnchorElPosition] = useState({ top: 0, left: 0 });
    const position = useMemo(() => {
        const top = anchorElPosition.top;
        let left = 0;
        if (anchorElPosition.left - containerWidth - 10 < 0) {
            left = 10 + containerWidth;
        } else {
            left = anchorElPosition.left - 10;
        }
        return { top, left };
    }, [containerWidth, anchorElPosition]);
    const checked: Filter[] = [];

    if (filters_masked_items) {
        checked.push('masked');
    }

    if (filters_visible_items) {
        checked.push('visible');
    }

    if (filters_cancellable_items) {
        checked.push('cancellable');
    }

    if (filters_non_cancellable_items) {
        checked.push('non-cancellable');
    }

    const isAll = checked.length === 4;

    const onToggleFilter = (filter: Filter | 'ALL' | 'REMOVE-ALL') => {
        switch (filter) {
            case 'ALL': {
                dispatch({
                    type: "CART_FILTERS_MASKED_ITEMS",
                    payload: true
                });
                dispatch({
                    type: "CART_FILTERS_VISIBLE_ITEMS",
                    payload: true
                });
                dispatch({
                    type: "CART_FILTERS_CANCELLABLE_ITEMS",
                    payload: true
                });
                dispatch({
                    type: "CART_FILTERS_NON_CANCELLABLE_ITEMS",
                    payload: true
                });
                break;
            }
            case 'REMOVE-ALL': {
                dispatch({
                    type: "CART_FILTERS_MASKED_ITEMS",
                    payload: false
                });
                dispatch({
                    type: "CART_FILTERS_VISIBLE_ITEMS",
                    payload: false
                });
                dispatch({
                    type: "CART_FILTERS_CANCELLABLE_ITEMS",
                    payload: false
                });
                dispatch({
                    type: "CART_FILTERS_NON_CANCELLABLE_ITEMS",
                    payload: false
                });
                break;
            }
            case 'masked': {
                dispatch({
                    type: "CART_FILTERS_MASKED_ITEMS",
                    payload: !filters_masked_items
                });
                break;
            }
            case 'visible': {
                dispatch({
                    type: "CART_FILTERS_VISIBLE_ITEMS",
                    payload: !filters_visible_items
                });
                break;
            }
            case 'cancellable': {
                dispatch({
                    type: "CART_FILTERS_CANCELLABLE_ITEMS",
                    payload: !filters_cancellable_items
                });
                break;
            }
            case 'non-cancellable': {
                dispatch({
                    type: "CART_FILTERS_NON_CANCELLABLE_ITEMS",
                    payload: !filters_non_cancellable_items
                });
                break;
            }
        }
        dispatch({ type: 'CART_SET_TO_BOOK', payload: [] });
    };

    useEffect(() => {
        if (container) {
            const observer = new ResizeObserver((entries) => {
                setContainerWidth(entries[0]?.contentRect.width ?? 0);
            });
            observer.observe(container);
            return () => observer.disconnect();
        }
    }, [container]);

    //needed to detect if filter button has changed position
    useEffect(() => {
        if (props.anchorEl && !isFunction(props.anchorEl)) {
            const checkPosition = () => {
                const currentTop = (props.anchorEl as HTMLUListElement).getBoundingClientRect().top;
                const currentLeft = (props.anchorEl as HTMLUListElement).getBoundingClientRect().left;
    
                setAnchorElPosition((position) => {
                    if (currentTop !== position.top || currentLeft !== position.left) {
                        return {
                            top: currentTop,
                            left: currentLeft
                        };
                    }
                    return position;
                });

                requestAnimationFrame(checkPosition);
            };
            checkPosition();
        }
    }, [props.anchorEl]);

    return (
        <Menu
            open={props.open}
            MenuListProps={{
                ref: (element) => {
                    setContainer(element);
                }
            }}
            PaperProps={{
                sx: {
                    position: 'static',
                    maxWidth: 'none',
                    maxHeight: 'none'
                }
            }}
            sx={{
                ...position,
                right: 'unset',
                bottom: 'unset',
                transform: 'translateX(-100%) !important'
            }}
            anchorEl={props.anchorEl}
            hideBackdrop
        >
            <MenuItem onClick={() => onToggleFilter(!isAll ? 'ALL' : 'REMOVE-ALL')}>
                {
                    isAll &&
                    <ListItemIcon>
                        <Check color="success" />
                    </ListItemIcon>
                }
                <ListItemText inset={!isAll}>
                    {
                        isAll ?
                            t<string>('cart-material.deselect-all') :
                            t<string>('cart-material.select-all')
                    }
                </ListItemText>
            </MenuItem>
            <MenuItem onClick={() => onToggleFilter('masked')}>
                {
                    checked.includes('masked') &&
                    <ListItemIcon>
                        <Check color="success" />
                    </ListItemIcon>
                }
                <ListItemText inset={!checked.includes('masked')}>
                    { t<string>('cart.filters-masked-items') }
                </ListItemText>
            </MenuItem>
            <MenuItem onClick={() => onToggleFilter('visible')}>
                {
                    checked.includes('visible') &&
                    <ListItemIcon>
                        <Check color="success" />
                    </ListItemIcon>
                }
                <ListItemText inset={!checked.includes('visible')}>
                    { t<string>('cart.filters-visible-items') }
                </ListItemText>
            </MenuItem>
            <MenuItem onClick={() => onToggleFilter('cancellable')}>
                {
                    checked.includes('cancellable') &&
                    <ListItemIcon>
                        <Check color="success" />
                    </ListItemIcon>
                }
                <ListItemText inset={!checked.includes('cancellable')}>
                    { t<string>('cart.filters-cancellable-items') }
                </ListItemText>
            </MenuItem>
            <MenuItem onClick={() => onToggleFilter('non-cancellable')}>
                {
                    checked.includes('non-cancellable') &&
                    <ListItemIcon>
                        <Check color="success" />
                    </ListItemIcon>
                }
                <ListItemText inset={!checked.includes('non-cancellable')}>
                    { t<string>('cart.filters-non-cancellable-items') }
                </ListItemText>
            </MenuItem>
        </Menu>
    );
}
