import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CartConstructionReplaceProductContext } from "../../CartMaterial/utils/cartConstructionReplaceProductContext";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize20: {
        fontSize: 20
    },
    uppercase: {
        textTransform: "uppercase"
    },
    textCenter: {
        textAlign: "center"
    },
    genericPadding: {
        padding: "8px 16px"
    },
    spacer: {
        padding: "8px 0px"
    },
    selectSpacer: {
        padding: 16
    },
    spacing3: {
        width: "calc(100% + 24px)",
        margin: -12
    },
    validateButton: {
        backgroundColor: `${main_color}`,
        color: "white",
        padding: "8px 60px"
    }
}));

const CancellationRules = ({ flight, flightStep, setFlightStep }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    const [cancelInfoLines, setCancelInfoLines] = useState([]);

    const replaceProductContext = useContext(CartConstructionReplaceProductContext);

    const onContinue = () => {
        if (replaceProductContext.enableReplace) {
            replaceProductContext.onShowNextButton();
        }
        setFlightStep(
            flight.options.fares[0].refund_detail !== null && flight.options.fares[0].cancel_info !== null ?
                flightStep + 1 :
                flightStep + 2
        );
    };

    useEffect(() => {
        if (flight.options.fares[0].cancel_info !== undefined && flight.options.fares[0].cancel_info !== "") {
            setCancelInfoLines(flight.options.fares[0].cancel_info.split("- "));
        }
    }, []);

    return (
        <Grid className={classes.spacer}>
            <Grid className={`${classes.genericText} ${classes.fontWeight900} ${isMobile ? classes.fontSize16 : classes.fontSize20} ${classes.uppercase} ${classes.textCenter}`} style={{ paddingBottom: 8 }}>
                { t("flight.cancellation_rules") }
            </Grid>
            <Divider />
            <Grid container alignItems="center" justify="space-between" className={`${classes.genericPadding}`} direction={isMobile ? "column" : "row"}>
                <Grid item className={`${classes.genericText} ${classes.genericPadding} ${classes.fontWeight900} ${isMobile ? classes.fontSize12 : classes.fontSize16} ${classes.uppercase}`}>
                    { t("flight.cancellation_rules") + " : "}
                </Grid>
                <Grid item className={`${classes.genericText} ${classes.genericPadding} ${classes.fontWeight900} ${isMobile ? classes.fontSize12 : classes.fontSize16} ${classes.uppercase}`}>
                    { flight.options.fares[0].refund_detail }
                </Grid>
            </Grid>
            {
                cancelInfoLines.length > 0 && (
                    <Grid className={classes.genericPadding}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className={`${classes.genericText} ${classes.fontWeight900} ${isMobile ? classes.fontSize12 : classes.fontSize16} ${classes.uppercase}`}>
                                    { t("flight.detailed_cancellation_rules") + " : "}
                                </div>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: "block" }}>
                                {
                                    JSON.parse(localStorage.getItem("config")).quotation_code !== "visiteurs" && cancelInfoLines.map((line, line_index) => {
                                        return (
                                            <div key={line_index} className={`${classes.genericText} ${classes.genericPadding} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                                                { line }
                                            </div>
                                        );
                                    })
                                }
                                { JSON.parse(localStorage.getItem("config")).quotation_code === "visiteurs" && <div className={`${classes.genericText} ${classes.genericPadding} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>{ t("flight.emitted_non_refundable") } </div> }
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )
            }
            <Grid className={`${classes.textCenter} ${classes.spacer}`}>
                <Button variant="contained" size="small" onClick={onContinue} className={classes.validateButton}>
                    { t("global.continue") }
                </Button>
            </Grid>
        </Grid>
    );
};

export default React.memo(CancellationRules);
