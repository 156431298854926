import React from "react";
import { ContentState } from "draft-js";
import { convertToHTML } from 'draft-convert';
import { htmlHasEmptyContent } from "../htmlHasEmptyContent";
import { RichEditorFontSizeStylesMap } from "./richEditorFontSizeControl";

export function renderRichEditorContentHTML(
    styles: {[index: string]: React.CSSProperties},
    content: ContentState
): string {
    const html = convertToHTML({
        styleToHTML: (style: string) => {
            if (style === 'BOLD') {
                return <strong />;
            } else if (style === 'STRIKETHROUGH') {
                return <del />;
            } else if (style.includes('FONTSIZE_')) {
                return (
                    <span
                        style={{
                            fontSize: RichEditorFontSizeStylesMap[style as keyof typeof RichEditorFontSizeStylesMap]?.fontSize
                        }}
                    />
                );
            }
            return (
                <span
                    style={styles[style]}
                />
            );
        },
        blockToHTML: (block) => {
            switch (block.type) {
                case 'header-one': return (<h1 style={{ textAlign: block.data?.alignment }} />);
                case 'header-two': return (<h2 style={{ textAlign: block.data?.alignment }} />);
                case 'header-three': return (<h3 style={{ textAlign: block.data?.alignment }} />);
                case 'header-four': return (<h4 style={{ textAlign: block.data?.alignment }} />);
                case 'header-five': return (<h5 style={{ textAlign: block.data?.alignment }} />);
                case 'header-six': return (<h6 style={{ textAlign: block.data?.alignment }} />);
                case 'blockquote': return (<blockquote style={{ textAlign: block.data?.alignment }} />);
                case 'code-block': return (<pre style={{ textAlign: block.data?.alignment }} />);
                case 'atomic': return (<figure style={{ textAlign: block.data?.alignment }} />);
                case 'ordered-list-item': {
                    return {
                        start: `<li style="text-align: ${block.data?.alignment ?? 'left'}">`,
                        end: '</li>',
                        nest: '<ol>',
                        nestStart: '<ol>',
                        nestEnd: '</ol>'
                    };
                }
                case 'unordered-list-item': {
                    return {
                        start: `<li style="text-align: ${block.data?.alignment ?? 'left'}">`,
                        end: '</li>',
                        nest: '<ul>',
                        nestStart: '<ul>',
                        nestEnd: '</ul>'
                    };
                }
                default: return (<p style={{ textAlign: block.data?.alignment }} />);
            }
        },
        entityToHTML: (entity, originalText) => {
            if (entity.type === 'LINK') {
                return <a href={ entity.data.link } target={ 'blank' }>{ entity.data.text }</a>;
            } else if (entity.type === 'image') {
                return <img src={ entity.data.src }/>;
            }
            return originalText;
        }
    })(content);

    if (!htmlHasEmptyContent(html)) {
        return html;
    }

    return "";
}
