import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Star from "@material-ui/icons/Star";
import CurrencyFormat from "./CurrencyFormat";
import Slider from "./Slider";
import grey from "@material-ui/core/colors/grey";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Favorite from '@material-ui/icons/Favorite';
import Wifi from "@material-ui/icons/Wifi";
import AcUnit from "@material-ui/icons/AcUnit";
import Pool from "@material-ui/icons/Pool";
import LocalParking from "@material-ui/icons/LocalParking";
import green from "@material-ui/core/colors/green";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    inline: {
        display: "inline"
    },
    bold: {
        fontWeight: "bold"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    cardContainer: {
        cursor: "pointer",
        borderRadius: 16,
        paddingBottom: 8,
        paddingTop: 8
    },
    imgContainer: {
        overflow: "hidden",
        height: 270,
        position: "relative",
        //marginBottom: 1,
        [theme.breakpoints.down('lg')]: {
            height: 200
        }
    },
    imgContainerTwo: {
        overflow: "hidden",
        height: 270,
        position: "relative"
        //marginBottom: 1,
    },
    relative: {
        position: "relative"
    },
    name: {
        display: "inline",
        marginRight: theme.spacing(1)
    },
    stars: {
        display: "inline",
        marginRight: theme.spacing(1)
    },
    alignRight: {
        textAlign: "right"
    },
    currencyFontSize: {
        fontSize: "1.3rem"
    },
    currencyFontSizeTotal: {
        fontSize: "0.9rem"
    },
    marginBottom: {
        marginBottom: 4
    },
    grey: {
        color: grey[800]
    },
    paddingLeftRight: {
        paddingLeft: 10,
        paddingRight: 10
    },
    paddingRight: {
        paddingRight: 10,
        paddingLeft: 10
    },
    paddingLeft: {
        paddingRight: 10,
        paddingLeft: 10
    },
    underline: {
        textDecoration: 'underline'
    },
    recommended: {
        color: green[500]
    },
    hideProduct: {
        background: "#eeee",
        border: "solid 2px #E6592F",
        padding: 5,
        borderTop: 'none',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        marginBottom: 10
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        padding: 5,
        borderTop: 'none',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        marginBottom: 10
    },
    isOption: {
        border: "solid 2px #eeee",
        padding: 5,
        borderTop: 'none',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        marginBottom: 10
    }
}));

let enter_timeout;

const AccommodationListHotelTwo = ({ index, hotel, router }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const language = useSelector(state => state.header.tmp_language);
    const duration_days = useSelector(state => state.accommodation.duration_days);
    const traveler_number = useSelector(state => state.accommodation.traveler_number);
    const room_number = useSelector(state => state.accommodation.room_number);
    const cancellable = useSelector(state => state.accommodation.cancellable);
    const tmp_resort_fees = useSelector(state => state.accommodation.resort_fees);
    const meal_plan = useSelector(state => state.accommodation.meal_plan);
    const grid_type = useSelector(state => state.accommodation.grid_type);
    const currency_list = useSelector(state => state.base.currency_list);
    const accommodation_cart = useSelector(state => state.accommodation.sort_cart);

    const in_cart = accommodation_cart.find((item) => item.hotel[0].id === hotel.id);

    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.down('sm'));
    const mobile = useMediaQuery(theme.breakpoints.down(600));
    const [showChevron, setShowChevron] = useState(false);
    let stars = [];
    for (let i = 0; i < hotel.stars; i++) {
        stars.push(<Star key={ i } className={ classes.stars }/>);
    }
    const onMouseEnter = hotel => event => {
        clearTimeout(enter_timeout);
        setShowChevron(true);
        enter_timeout = setTimeout(() => {
            dispatch({ type: "MATERIAL_UPDATE_HOVERED", payload: { hovered: hotel } });
        }, 1000);
    };
    const onMouseLeave = () => {
        clearTimeout(enter_timeout);
        setShowChevron(false);
        dispatch({ type: "MATERIAL_UPDATE_HOVERED", payload: { hovered: null } });
    };
    let displayed_pictures = [];
    if (hotel.pictures !== undefined) {
        displayed_pictures = hotel.pictures.slice();
        if (hotel.cover_picture !== undefined && hotel.cover_picture !== null) {
            displayed_pictures.unshift(hotel.cover_picture);
        }
    }
    let currency = null;
    let price = 0;
    let resort_fees = null;
    if (meal_plan === null && !cancellable) {
        price = hotel.price;
        resort_fees = hotel.price_resort_fees;
        if (hotel.price_currency !== null) {
            currency = currency_list.find((currency) => {
                return currency.id === hotel.price_currency;
            });
        }
    } else if (meal_plan === null && cancellable) {
        price = hotel.cancellable_price;
        resort_fees = hotel.cancellable_resort_fees;
        if (hotel.cancellable_currency !== null) {
            currency = currency_list.find((currency) => {
                return currency.id === hotel.cancellable_currency;
            });
        }
    } else if (meal_plan === 0 && !cancellable) {
        price = hotel.breakfast_price;
        resort_fees = hotel.breakfast_resort_fees;
        if (hotel.breakfast_currency !== null) {
            currency = currency_list.find((currency) => {
                return currency.id === hotel.breakfast_currency;
            });
        }
    } else if (meal_plan === 1 && !cancellable) {
        price = hotel.half_price;
        resort_fees = hotel.half_resort_fees;
        if (hotel.half_currency !== null) {
            currency = currency_list.find((currency) => {
                return currency.id === hotel.half_currency;
            });
        }
    } else if (meal_plan === 2 && !cancellable) {
        price = hotel.full_price;
        resort_fees = hotel.full_resort_fees;
        if (hotel.full_currency !== null) {
            currency = currency_list.find((currency) => {
                return currency.id === hotel.full_currency;
            });
        }
    } else if (meal_plan === 0 && cancellable) {
        price = hotel.c_breakfast_price;
        resort_fees = hotel.c_breakfast_resort_fees;
        if (hotel.c_breakfast_currency !== null) {
            currency = currency_list.find((currency) => {
                return currency.id === hotel.c_breakfast_currency;
            });
        }
    } else if (meal_plan === 1 && cancellable) {
        price = hotel.c_half_price;
        resort_fees = hotel.c_half_resort_fees;
        if (hotel.c_half_currency !== null) {
            currency = currency_list.find((currency) => {
                return currency.id === hotel.c_half_currency;
            });
        }
    } else if (meal_plan === 2 && cancellable) {
        price = hotel.c_full_price;
        resort_fees = hotel.c_full_resort_fees;
        if (hotel.c_full_currency !== null) {
            currency = currency_list.find((currency) => {
                return currency.id === hotel.c_full_currency;
            });
        }
    }
    const getInProperty = (product) => {
        if (product.is_optional) {
            return 'OPTION';
        } else if (product.is_hidden_for_traveler) {
            return 'HIDE';
        } else if (product.variant !== null) {
            return 'VARIANT';
        } 
        return 'DEFAULT';
    };

    return (
        <Grid
            id={ `hotel-item-${hotel.id}` }
            className={`${classes.cardContainer} ${mobile ? '' : grid_type === 3 || tablet ? index % 2 === 0 ? classes.paddingRight : classes.paddingLeft : index % 3 === 0 ? classes.paddingRight : index % 3 === 1 ? classes.paddingLeftRight : classes.paddingLeft}` }
            item
            container
            onMouseEnter={ onMouseEnter(hotel) }
            onMouseLeave={ onMouseLeave }
            onClick={() => {
                router.push(`/${window.url_name}/apps/accommodation-card/${hotel.id}`);
            }}
            xs={ mobile ? 12 : grid_type === 3 || tablet ? 6 : 4 }
        >
            <Grid item xs={ 12 }>
                <div className={ clsx({ [classes.imgContainerTwo]: grid_type === 3 || tablet, [classes.imgContainer]: grid_type !== 3 && !tablet }) }>
                    <Slider pictures={ displayed_pictures } showChevron={ showChevron } size={ grid_type === 3 ? 4 : 3 } dotSize={ 1 } from_list={in_cart !== undefined}/>
                </div>
                <div className={`${in_cart !== undefined ? (getInProperty(in_cart) === 'HIDE' ? classes.hideProduct : (getInProperty(in_cart) === 'OPTION' || getInProperty(in_cart) === 'VARIANT' ? classes.isOption : classes.inQuotation)) : ''}`}>
                    <div className={ classes.relative }>
                        <Typography className={ classes.name } variant={ "h6" }>{ hotel.name }</Typography>
                    </div>
                    <Grid container justify={ "space-between" }>
                        <Grid item>
                            <Typography>{ stars }</Typography>
                        </Grid>
                        <Grid item>
                            { hotel.internet && <Wifi className={ classes.marginRight } fontSize={ "default" }/> }
                            { hotel.clim && <AcUnit className={ classes.marginRight } fontSize={ "default" }/> }
                            { hotel.pool && <Pool className={ classes.marginRight } fontSize={ "default" }/> }
                            { hotel.parking && <LocalParking className={ classes.marginRight } fontSize={ "default" }/> }
                        </Grid>
                    </Grid>
                    <Typography className={ `${classes.marginBottom} ${classes.grey}` } variant={ "body2" }>{ hotel.street !== null ? hotel.street : "" }{ hotel.city_name !== null ? `${hotel.street !== null ? ", " : ""}${hotel.city_name}` : "" }</Typography>
                    {/*<Typography className={ classes.grey }>{ duration_days } nuits, { traveler_number } voyageurs, { room_number } chambres</Typography> */}
                    <Typography className={ classes.grey }>{ duration_days + " " + (duration_days > 1 ? t("itinerary.nights") : t("itinerary.night")) + ", " + traveler_number + " " + (traveler_number > 1 ? t("accommodation.travelers") : t("accommodation.traveler")) + ", " + room_number + " " + (room_number > 1 ? t("global.rooms") : t("global.room")) }</Typography>
                    <Grid container justify={ "space-between" } alignItems={ "center" }>
                        <Grid item>
                            {
                                hotel.catalog_price ? (
                                    <Typography className={ classes.inline } variant={ "body2" }>{ t("accommodation.catalog-price") }</Typography>
                                ) : (
                                    <Fragment>
                                        <div>
                                            <CurrencyFormat language={ language } fontSize={ classes.currencyFontSize } symbol={ (currency !== null ? (currency.symbol === '$' || currency.symbol === '£' ? `${currency.symbol}${currency.iso_code}` : currency.symbol) : '') } price={ Math.ceil(price / duration_days).toString() } with_precision={ 0 } with_bold={ 1 }/>
                                            <Typography className={ classes.inline } variant={ "body2" }> / { t("accommodation_list.night") }</Typography>
                                        </div>
                                        {
                                            price === hotel.breakfast_price &&
                                            <div>
                                                <Typography>({ t('accommodation.breakfast_included') })</Typography>
                                            </div>
                                        }
                                        {
                                            !!resort_fees && !!resort_fees.currency && !!resort_fees.fees &&
                                            <div>
                                                <Typography>{ t('accommodation_filters.resort_fees') } : { resort_fees.fees.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } { currency_list.find((currency) => {
                                                    return currency.id === resort_fees.currency;
                                                }).iso_code }</Typography>
                                            </div>
                                        }
                                        <div className={ classes.underline }>
                                            <CurrencyFormat language={ language } fontSize={ classes.currencyFontSizeTotal } symbol={ (currency !== null ? (currency.symbol === '$' || currency.symbol === '£' ? `${currency.symbol}${currency.iso_code}` : currency.symbol) : '') } price={ Math.ceil(price).toString() } with_precision={ 0 } with_bold={ 0 }/>
                                            <Typography className={ classes.inline } variant={ "body2" }> { t("accommodation_list.total") }</Typography>
                                        </div>
                                    </Fragment>
                                )
                            }
                        </Grid>
                        {
                            hotel.favorite &&
                            <Grid className={ classes.recommended } item>
                                <Favorite/>
                            </Grid>
                        }
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

export default React.memo(AccommodationListHotelTwo);
