import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Menu,
    MenuItem,
    MenuProps,
    ListItemIcon,
    ListItemText,
    Checkbox
} from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";
import { CartConstructionProductStatusMenu } from "./CartConstructionProductStatusMenu";
import { CartConstructionProductTypeMenu } from "./CartConstructionProductsTypeMenu";
import { CartConstructionProductCharacteristicMenu } from "./CartConstructionProductCharacteristicMenu";
import { changeFiltersStatus, setOpenProductsDetails } from "./redux/cartConstructionReducer";
import { CartProductStatus } from "./CartConstructionProductsTableItemMenu";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    anchorEl: MenuProps['anchorEl'],
    onClose: () => void,
}

export function CartConstructionProductsFilters(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const productTypeFilters = useSelector((state: AppState) => {
        return state.cart.product_type_filters;
    });
    const openProductsDetails = useSelector((state: AppState) => state.cartConstruction.openProductsDetails);
    const statusFilters = useSelector((state: AppState) => state.cartConstruction.filters.status);
    const [openStatus, setOpenStatus] = useState(false);
    const [openTypes, setOpenTypes] = useState(false);
    const [openCharacteristics, setOpenCharacteristics] = useState(false);
    const types = useMemo(() => {
        return productTypeFilters?.map((item) => {
            return item.type;
        }) ?? [];
    }, [productTypeFilters]);
    const menuRef = useRef<HTMLUListElement>(null);

    const PRODUCT_TYPES: {
        type: number,
        value: string
    }[] = useMemo(() => {
        return [
            {
                type: 12,
                value: t('providers.activities')
            },
            {
                type: 998,
                value: t('global.poi')
            },
            {
                type: 8,
                value: t('providers.insurances')
            },
            {
                type: 0,
                value: t('providers.hotels')
            },
            {
                type: 4,
                value: t('providers.transfers')
            },
            {
                type: 2,
                value: t('providers.rental_car')
            },
            {
                type: 6,
                value: t('providers.flight')
            },
            {
                type: 999,
                value: t('shared.others')
            }
        ];
    }, [t]);

    const onToggleProductsDetails = () => {
        dispatch(setOpenProductsDetails(!openProductsDetails));
    };

    const onChangeProductTypes = (value: number[]) => {
        dispatch({
            type: 'CART_SET_PRODUCT_TYPE_FILTERS',
            payload: value.map((item) => {
                return {
                    type: item,
                    value: ''
                };
            })
        });
    };

    const onChangeStatuses = (statuses: CartProductStatus[]) => {
        dispatch(changeFiltersStatus(statuses));
    };

    const onClose = () => {
        setOpenStatus(false);
        setOpenTypes(false);
        setOpenCharacteristics(false);
        props.onClose();
    };

    const onOpen = (type: 'status' | 'type' | 'characteristic') => {
        setOpenStatus(false);
        setOpenTypes(false);
        setOpenCharacteristics(false);
        switch (type) {
            case 'status': {
                setOpenStatus(true);
                break;
            }
            case 'type': {
                setOpenTypes(true);
                break;
            }
            case 'characteristic': {
                setOpenCharacteristics(true);
                break;
            }
        }
    };

    useEffect(() => {
        if (!productTypeFilters) {
            dispatch({
                type: 'CART_SET_PRODUCT_TYPE_FILTERS',
                payload: PRODUCT_TYPES
            });
        }
    }, [productTypeFilters]);

    return (
        <>
            <Menu
                open
                MenuListProps={{ ref: menuRef }}
                anchorEl={props.anchorEl}
                onClose={onClose}
                disableScrollLock
            >
                <MenuItem onClick={onToggleProductsDetails}>
                    <ListItemIcon>
                        <Checkbox
                            size="small"
                            checked={openProductsDetails}
                            sx={{ padding: 0 }}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        {t<string>('cart.display-product-detail')}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => onOpen('status')}>
                    <ListItemIcon>
                        <ArrowBackIosNew fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        {t<string>('cart-material.cart-construction-products-statuses')}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => onOpen('type')}>
                    <ListItemIcon>
                        <ArrowBackIosNew fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        {t<string>('shared.product-type')}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => onOpen('characteristic')}>
                    <ListItemIcon>
                        <ArrowBackIosNew fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        {t<string>('cart-material.cart-construction-product-characteristic')}
                    </ListItemText>
                </MenuItem>
            </Menu>
            <CartConstructionProductStatusMenu
                statuses={statusFilters}
                anchorEl={menuRef.current}
                open={openStatus}
                onChangeStatuses={onChangeStatuses}
                multiple
            />
            <CartConstructionProductTypeMenu
                types={types}
                anchorEl={menuRef.current}
                open={openTypes}
                onChangeTypes={onChangeProductTypes}
                multiple
            />
            <CartConstructionProductCharacteristicMenu
                anchorEl={menuRef.current}
                open={openCharacteristics}
            />
        </>
    );
}
