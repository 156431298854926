import axios from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { useSelector } from "react-redux";
import { AppState } from "../../../Reducers/Reducers";
import GetCookie from "../../Common/Functions/GetCookie";
import { Flight } from "../../Itinerary/network/flight";

type Data = {
    flightId: number,
    newFlightKey: string,
    segmentGroup: number[]
}

type Callback = (data: Data) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: (flight: Flight) => void,
    onError: (error: Error) => void,
    onFinally: () => void
}>

export function useReplaceFlightSegment(options: Options): Callback {
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const versionString = GetCookie("trip_id_version");
    const version = versionString ? parseInt(versionString) : -1;

    return async (data) => {
        if (tripId) {
            try {
                if (options.onTrigger) {
                    options.onTrigger();
                }
                const encryptData = await makeChangeSegmentGetRequest({
                    tripId,
                    version,
                    flightId: data.flightId,
                    newFlightKey: data.newFlightKey,
                    segmentGroup: data.segmentGroup
                });
                const flight = await makeChangeSegmentConfirmRequest({
                    tripId,
                    version,
                    flightId: data.flightId,
                    encryptData
                });
                if (options.onSuccess) {
                    options.onSuccess(flight);
                }
            } catch (error: any) {
                if (options.onError) {
                    options.onError(error);
                }
            } finally {
                if (options.onFinally) {
                    options.onFinally();
                }
            }
        }
    };
}

type ChangeSegmentGetRequestOptions = {
    tripId: number,
    version: number,
    flightId: number,
    segmentGroup: number[],
    newFlightKey: string,
}

async function makeChangeSegmentGetRequest(options: ChangeSegmentGetRequestOptions): Promise<string> {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        const response = await axios.post<{
            error: boolean,
            error_message: string | null,
            message: string | null,
            fares: {
                price_variation: number,
                taxes_variation: number,
                new_ltd: string,
                encrypt_data: string
            }[]
        }>(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/flight/${options.flightId}/change_segment_get_ltd/`,
            {
                group_to_replace: options.segmentGroup,
                flight_key: options.newFlightKey
            },
            { headers }
        );
        if (response.data.error) {
            throw new Error(
                response.data.error_message ??
                response.data.message ??
                'Unknown error occured.'
            );
        }
        const encryptData = response.data.fares[0]?.encrypt_data;
        if (encryptData) {
            return encryptData;
        }
    }
    throw new Error('Could not make change_segment_get_ltd request.');
}

type ChangeSegmentConfirmRequestOptions = {
    tripId: number,
    version: number,
    flightId: number,
    encryptData: string
}

async function makeChangeSegmentConfirmRequest(options: ChangeSegmentConfirmRequestOptions): Promise<Flight> {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        const response = await axios.post<Flight>(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/flight/${options.flightId}/change_segment_confirm/`,
            {
                margin_reduction_percentage: 0,
                encrypt_data: options.encryptData
            },
            { headers }
        );
        return response.data;
    }
    throw new Error('Could not make change_segment_confirm request.');
}
