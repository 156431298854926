import { rgbToHex as base } from "@mui/material";

export function rgbToHex(
    color: string | null | undefined,
    fallback: string
): string {
    return color && color.length > 0 ?
        base(color) :
        fallback;
}
