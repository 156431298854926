import HelpOutline from "@material-ui/icons/HelpOutline";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import axios from "axios";
import Tooltip from '@material-ui/core/Tooltip';
import {useSelector} from "react-redux";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles({
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    customSize: {
        fontSize: 32
    },
    help: {
        position: 'fixed',
        zIndex: 2,
        bottom: 16,
        right: 64,
        cursor: 'pointer'
    }
});

const Help = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const user = useSelector(store => store.user.user);
    const onAccessHelp = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/user/access_support/`,
        }).then(function (response) {
            let win = window.open(response.data, '_blank');
            win.focus();
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    };
    if (user && user.client_full && user.client_full.type === 2) {
        return <Tooltip title={ t('global.help') }><Fab className={ `${classes.orangeButton} ${classes.help}` } disableRipple={ true }><HelpOutline className={ classes.customSize } onClick={ onAccessHelp }/></Fab></Tooltip>;
    } else {
        return null;
    }
};

export default Help;