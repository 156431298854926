import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";

import GetPictureBySize from "../Functions/GetPictureBySize";

const useStyles = makeStyles({
    inline: {
        display: "inline-block"
    },
    alignCenter: {
        textAlign: "center"
    },
    fullHeight: {
        height: "100%"
    },
    buttonBorder: {
        border: "1px solid"
    },
    modalPicture: {
        maxHeight: "calc(100vh - 200px)",
        objectFit: "contain"
    },
});

const Picture = ({open, onClose, pictures, current_picture, setCurrentPicture}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Dialog open={ open } onClose={() => { onClose(pictures); }} fullScreen disableEscapeKeyDown>
            <DialogTitle onClose={() => { onClose(pictures); }}>
                <Grid container justify={ "space-between" } alignItems={ "center" }>
                    <Grid item xs>
                        <Button variant={ "contained" } color={ "default" } startIcon={ <Close/> } onClick={() => { onClose(pictures); }} disableElevation>
                            <Typography className={ classes.inline }>{ t("global.close") }</Typography>
                        </Button>
                    </Grid>
                    <Grid item xs className={ classes.alignCenter }>
                        { current_picture + 1 } / { pictures.length }
                    </Grid>
                    <Grid item xs/>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container justify={ "center" } alignItems={ "center" } className={ classes.fullHeight } spacing={ 2 }>
                    {
                        !mobile && (
                            <Grid item>
                                <IconButton className={ classes.buttonBorder } onClick={() => { setCurrentPicture(current_picture > 0 ? current_picture - 1 : pictures.length - 1); }}>
                                    <ChevronLeft/>
                                </IconButton>
                            </Grid>
                        )
                    }
                    <Grid item xs>
                        {
                            pictures[current_picture] !== undefined && (
                                <img src={ GetPictureBySize(pictures[current_picture], "URL") } width={ "100%" } className={ classes.modalPicture } alt={ "hotel picture" }/>
                            )
                        }
                    </Grid>
                    {
                        !mobile && (
                            <Grid item>
                                <IconButton className={ classes.buttonBorder } onClick={() => { setCurrentPicture(current_picture < pictures.length - 1 ? current_picture + 1 : 0); }}>
                                    <ChevronRight/>
                                </IconButton>
                            </Grid>
                        )
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    )
};

export default React.memo(Picture);