import React, { useRef, useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { Stack, Button, styled, CircularProgress, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, TextField, Dialog, DialogTitle, DialogContent, DialogActions, TableSortLabel } from '@mui/material';
import { setLoading } from './MailVisualEditor/redux/actions';
import axios from 'axios';
import { ContentState, Editor, EditorState } from "draft-js";

// --- Core --- //
import {
    IconButton,
    Grid,
    Drawer,
    useTheme,
    useMediaQuery,
    Menu,
    MenuItem
} from "@material-ui/core";
// --- Common --- //
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
// ---- Types ---- //
import { AppState } from "../../../Reducers/Reducers";
import { setTripListNotes, setTripListNotesValue } from "./TripListNotes/redux/actions";
import moment from "moment";
import { Note } from './objects/notes';
import { AddCircle, Close, NavigateBefore } from "@mui/icons-material";
import RichEditor from "./utils/editor/editor";
import decorator from "./utils/editor/decorator";

export function TripListNotes(): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const notes = useSelector((state:AppState) => state.tripListNotes.notes);
    const value = useSelector((state:AppState) => state.tripListNotes.value);
    const trip_info = useSelector((state:AppState) => state.trip.all_data);

    const [loading, setLoading] = useState(false);
    const [newNote, setNewNote] = useState(false);
    const [modalNote, setModalNote] = useState<Note | null>(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));
    const [order, setOrder] = useState<string | undefined>('desc');
    const [orderBy, setOrderBy] = useState<string>('created_date');
    const textFieldRef = React.useRef(null);
    const editorRef = React.useRef<Editor>(null);

    useEffect(() => {
        textFieldRef.current?.scrollIntoView({
            behavior: "smooth"
        });
        setTimeout(() => editorRef.current?.focus(), 300);
    }, [newNote]);
    useEffect(() => {
        getNotes();
    }, []);
    useEffect(() => {
        getNotes();
    }, [order, orderBy]);
    const getNotes = () => {
        const { pass_check, headers } = CheckBeforeRequest();
        setLoading(true);
        let ordering = `${order === "desc" ? "-" : ""}${orderBy}`;
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/note/?ordering=${ordering}`
        }).then((response) => {
            // let sort_note = response.data.results.sort((a: Note, b: Note) => {
            //     return (a.created_date < b.created_date ? -1 : 1) * -1;
            // });
            dispatch(setTripListNotes(response.data.results));
            // dispatch(setTripListNotes(sort_note));
            setLoading(false);
        }).catch((error) => {
            console.log('notes error:', error);
        });
    }
    const onNewNote = () => {
        setNewNote(true);
    }
    // const onNoteChange = (id: number, value: string) => {
    //     dispatch
	// 	setNewValue(value);
	// };
    const cancelNote = () => {
        dispatch(setTripListNotesValue(1, ''));
        setNewNote(false);
    }
    const saveNote = () => {
        if (value !== '') {
            const { pass_check, headers } = CheckBeforeRequest();
            axios({
                method: "POST",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/note/`,
                data: {
                    note: value
                },
            }).then((response) => {
                let tmp_note:Note[] = [...notes];
                tmp_note.push(response.data);
                let sort_note = tmp_note.sort((a: Note, b: Note) => {
                    return (a.created_date < b.created_date ? -1 : 1) * -1;
                });
                dispatch(setTripListNotes(sort_note));
                cancelNote();
            }).catch((error) => {
                console.log('notes error:', error);
            });
        }
    }
    const openNote = (note: Note | null) => {
        setModalNote(note);
    }
    const createSortHandler = (property: string) => () => {
        const isAsc = orderBy === property && order === 'asc';
        setOrderBy(property);
        setOrder(isAsc ? 'desc' : 'asc');
    };
    return (
        <div>
            <Grid container alignItems={'center'}>
                <Grid item md={4} />
                <Grid item xs={12} md={4}>
                    <Title>
                        {t('menu.trip_list.notes')}
                    </Title>
                </Grid>
                {
                    !newNote &&
                    <Grid item xs={12} md={4}>
                        <NewMailContainer>
                            <NewMailButton
                                variant="contained"
                                onClick={onNewNote}
                                startIcon={<AddCircle sx={{ color: '#E6592F' }} />}
                            >
                                {t<string>('menu.trip_list.new-note')}
                            </NewMailButton>
                        </NewMailContainer>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Grid container justify={ 'center' }>
                        {
                            loading &&
                            <Fragment>
                                <Grid item md={4}/>
                                <Grid item md={4} xs={12}>
                                {
                                    loading &&
                                    <CircularProgress />
                                }
                                </Grid>
                            </Fragment>
                        }
                        {
                            !loading && notes.length !== 0 &&
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={orderBy === 'created_date'}
                                                        direction={orderBy === 'created_date' ? order : 'desc'}
                                                        onClick={createSortHandler('created_date')}
                                                    >
                                                    {t<string>('menu.invoice.created-date')}
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={orderBy === 'creator__username'}
                                                        direction={orderBy === 'creator__username' ? order : 'desc'}
                                                        onClick={createSortHandler('creator__username')}
                                                    >
                                                    {t<string>('shared.created-by')}
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>Note</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {notes.map((note) => (
                                            <TableRow hover key={note.id} style={{cursor: 'pointer'}} onClick={() => openNote(note)}>
                                                <TableCell>
                                                    {moment(note.created_date).format('L LTS')}
                                                </TableCell>
                                                <TableCell>{`${note.creator.first_name} ${note.creator.last_name}`}</TableCell>
                                                <NoteCell><span dangerouslySetInnerHTML={{__html: note.note}}/></NoteCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                {
                    newNote &&
                    <Fragment>
                        <Grid item xs={12} style={{marginTop: 20}}>
                            {/* <TextField label={ t<string>('placeholder.custom_information') } value={ value } fullWidth multiline rows={ 4 } onChange={ onNoteChange }/> */}
                            <RichEditor ref={editorRef} pictures={[]} editorState={editorState} setEditorState={setEditorState} values={value} action={setTripListNotesValue}/>
                        </Grid>
                        <Grid item xs={12} container spacing={ 2 } justify={ 'flex-end' }>
                            <Grid item>
                                <Button onClick={ cancelNote }>{ t<string>('global.cancel') }</Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={ saveNote }>{ t<string>('cart-material.save-change') }</Button>
                            </Grid>
                        </Grid>
                    </Fragment>
                }
                <Grid item ref={textFieldRef}/>
            </Grid>
            <Dialog
                open={modalNote !== null}
                onClose={() => openNote(null)}
                fullWidth
                fullScreen={fullScreen}
                maxWidth={'md'}
            >
                <DialogTitle>
                    <Grid container justify={ "space-between" } alignItems={ "center" }>
                        {
                            fullScreen ? (
                                <Grid item>
                                    <IconButton edge={ "start" } onClick={() => openNote(null)}><NavigateBefore/></IconButton> Note
                                </Grid>
                            ) : (
                                <Fragment>
                                    <Grid item>
                                        Note
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={() => openNote(null)}><Close/></IconButton>
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </Grid>
                </DialogTitle>
                <DialogContent>
                {
                    modalNote !== null &&
                        <Grid container direction={'column'} spacing={2}>
                            <Grid item>{moment(modalNote?.created_date).format('L LTS')}</Grid>
                            <Grid item style={{fontWeight: 'bold'}}>{`${modalNote?.creator.first_name} ${modalNote?.creator.last_name}`}</Grid>
                            <Grid item>
                                <div dangerouslySetInnerHTML={{__html: modalNote?.note}}/>
                            </Grid>
                        </Grid>
                }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => openNote(null)} color={ "primary" }>{ t<string>("global.close") }</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const Title = styled('div')(({ theme }) => ({
    fontFamily: "Roboto",
    fontStyle: "normal",
    color: "#0000008A",
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 900,
    fontSize: 24,
    letterSpacing: 1.25,
    margin: '20px 0px',
    [theme.breakpoints.down('xs')]: {
        fontSize: 18
    }
}));

const HeaderButtons = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px'
    }
}));

const HeaderButton = styled(IconButton)(({ theme }) => ({
    "fontSize": 14,
    "color": '#4A75DA',
    "fontWeight": 400,
    '&:hover': {
        background: "none"
    },
    [theme.breakpoints.down('xs')]: {
        '& .icon-button-text': {
            paddingLeft: '8px'
        }
    }
}));

const NoteCell = styled(TableCell)(({ theme }) => ({
    'minWidth': 840,
    'maxWidth': 840,
    '& span': {
        marginBottom: 0,
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
}));

const NewMailContainer = styled('div')((props) => ({
    textAlign: 'center',
    marginBottom: props.theme.spacing(1.5)
}));

const NewMailButton = styled(Button)(() => ({
    "background": "#F7C8BA",
    "color": '#000',
    "borderRadius": "20px",
    '&:hover': {
        backgroundColor: '#d5d5d5'
    }
}));