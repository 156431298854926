import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Checkbox,
    Collapse,
    Divider,
    IconButton,
    Stack,
    iconButtonClasses
} from "@mui/material";
import {
    CheckBox,
    CheckBoxOutlineBlankOutlined,
    Close,
    KeyboardArrowLeftOutlined,
    KeyboardArrowRightOutlined,
    MoreVertOutlined
} from "@mui/icons-material";
import { isFunction } from "lodash";
import { CartConstructionProductsTableItemMenu } from "./CartConstructionProductsTableItemMenu";
import { CartProductPicturesModal } from "./CartProductPicturesModal";
import { getTableItemBackgroundColor } from "./utils/getTableItemBackgroundColor";
import CartDeleteModal from "./CartDeleteModal";
import { CartConstructionProductsContext } from "./utils/cartConstructionProductsContext";
import { isProductBookable } from "./utils/isProductBookable";
import { isProductPackaged } from "./utils/isProductPackaged";
import { isDeleteButtonEnabled } from "./utils/isDeleteButtonEnabled";
import { useDeleteProduct } from "./utils/deleteProduct";
import { toggleManualProductBooking } from "./redux/cartConstructionReducer";
import { AccommodationCart } from "../Itinerary/objects/accommodationCart";
import { CarCart } from "../Itinerary/objects/carCart";
import { ManualProductAccommodationCart } from "../Itinerary/objects/manualProductAccommodationCart";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { TransferCart } from "../Itinerary/objects/transferCart";
import { PoiCart } from "../Itinerary/objects/poiCart";
import { Flight } from "../Itinerary/network/flight";
import { AssistanceCart } from "../Itinerary/objects/assistanceCart";
import { AppState } from "../../Reducers/Reducers";

export type CartProductCardProps = {
    pictures: string[],
    mainContent: React.ReactNode | ((sideActions: (minimal?: boolean) => React.ReactNode) => React.ReactNode),
    sideContent?: React.ReactNode,
    footer?: React.ReactNode,
    alerts?: React.ReactNode,
    margin?: React.ReactNode,
    children: React.ReactNode,
    direction?: 'horizontal' | 'vertical',
    disablePictures?: boolean,
    showEntirePictures?: boolean,
    alwaysOpen?: boolean
} & ({
    type: 'car',
    item: CarCart,
    index: number
} | {
    type: 'assistance',
    item: AssistanceCart
} | {
    type: 'accommodation',
    item: AccommodationCart
} | {
    type: 'manual-accommodation',
    item: ManualProductAccommodationCart,
} | {
    type: 'poi',
    item: PoiCart
} | {
    type: 'transfer',
    item: TransferCart
} | {
    type: 'flight',
    item: Flight
} | {
    type: 'manual',
    item: ManualProduct
})

export const CartProductCard = React.memo(
    function CartProductCard(props: CartProductCardProps): JSX.Element | null {
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const user = useSelector((state: AppState) => state.user.user);
        const permissions = useSelector((state: AppState) => state.user.permissions);
        const trip = useSelector((state: AppState) => state.trip.data_trip);
        const itinerary_type_details_data = useSelector((state: AppState) => state.itinerary_type.itinerary_type_details_data);
        const to_book = useSelector((state: AppState) => state.cart.to_book);
        const openProductsDetails = useSelector((state: AppState) => state.cartConstruction.openProductsDetails);
        const bookableManualProducts = useSelector((state: AppState) => state.cartConstruction.bookableManualProducts);
        const [showDetails, setShowDetails] = useState(false);
        const [currentPictureIndex, setCurrentPictureIndex] = useState(0);
        const [openDeleteModal, setOpenDeleteModal] = useState(false);
        const [openMenu, setOpenMenu] = useState(false);
        const [openPicturesModal, setOpenPicturesModal] = useState(false);
        const [footerHasContent, setFooterHasContent] = useState(true);
        const [containerHasContent, setContainerHasContent] = useState(true);
        const menuButtonRef = useRef<HTMLButtonElement>(null);
        const checked = useMemo(() => {
            return props.type !== 'manual' && props.type !== 'manual-accommodation' ?
                !!to_book?.find(book_item => book_item.id === props.item.id) :
                bookableManualProducts.findIndex((product) => product.id === props.item.id) >= 0;
        }, [props.type, props.item, to_book, bookableManualProducts]);
        const stackInfo = useMemo(() => {
            return trip?.stack_info?.find((item) => {
                return item.stack_number === props.item.stack_number &&
                    item.id === props.item.stack_info_id;
            });
        }, [trip, props.item]);
        const context = useContext(CartConstructionProductsContext);
        const {
            loading,
            onDeleteAccommodation,
            onDeleteAssistance,
            onDeleteCar,
            onDeleteFlight,
            onDeleteManualProduct,
            onDeletePoi,
            onDeleteTransfer
        } = useDeleteProduct();
        const color = getTableItemBackgroundColor(
            props.item,
            itinerary_type_details_data ?? { variant_name: [] }
        );
        const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

        function hasTextContent(node: Node): boolean {
            if (
                node.nodeType === Node.TEXT_NODE &&
                (node.textContent?.trim().length ?? 0) > 0
            ) {
                return true;
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                for (let childNode of Array.from(node.childNodes)) {
                    if (hasTextContent(childNode)) {
                        return true;
                    }
                }
            }
            return false;
        }

        const onToggleForBooking = () => {
            if (props.type !== 'manual' && props.type !== 'manual-accommodation') {
                dispatch({
                    type: 'CART_TOGGLE_FOR_BOOKING',
                    payload: {
                        ...props.item,
                        is_manual: false
                    }
                });
            } else {
                dispatch(toggleManualProductBooking(props.item));
            }
        };

        const SideActions = (minimal = false) => (
            <Stack
                direction={
                    !minimal ?
                        { xs: 'row-reverse', md: 'column' } :
                        'row'
                }
                alignItems="flex-start"
            >
                {
                    isDeleteButtonEnabled({
                        item: {
                            type: props.type !== 'manual-accommodation' ? props.type : 'manual',
                            product: props.item
                        },
                        trip,
                        permissions,
                        user
                    }) &&
                    <IconButton
                        sx={{
                            position: 'relative',
                            top: !minimal ? '-11px' : 0,
                            padding: minimal ? 0 : undefined
                        }}
                        onClick={() => setOpenDeleteModal(true)}
                    >
                        <Close />
                    </IconButton>
                }
                <IconButton
                    ref={menuButtonRef}
                    onClick={() => setOpenMenu(true)}
                    sx={(theme) => ({
                        position: 'relative',
                        padding: minimal ? 0 : undefined,
                        [theme.breakpoints.down('md')]: {
                            top: '-11px'
                        }
                    })}
                >
                    <MoreVertOutlined />
                </IconButton>
            </Stack>
        );

        useEffect(() => {
            setShowDetails(openProductsDetails);
        }, [openProductsDetails]);

        useEffect(() => {
            if (props.alwaysOpen) {
                setShowDetails(true);
            }
        }, [props.alwaysOpen]);

        return (
            <>
                <Card
                    elevation={0}
                    sx={{
                        width: '100%',
                        backgroundColor: color,
                        border: '1px solid rgba(0, 0, 0, 0.2)'
                    }}
                >
                    <Stack sx={{ width: '100%' }}>
                        <Box
                            sx={(theme) => ({
                                display: 'flex',
                                flexDirection: props.direction === 'vertical' ?
                                    'column' :
                                    'row',
                                borderBottom: showDetails ?
                                    '1px solid rgba(0, 0, 0, 0.25)' :
                                    undefined,
                                [theme.breakpoints.down('md')]: {
                                    flexDirection: 'column'
                                }
                            })}>
                            {
                                !props.disablePictures &&
                                <CardMedia
                                    key={props.pictures[currentPictureIndex] ?? ''}
                                    image={props.pictures[currentPictureIndex] ?? ''}
                                    sx={(theme) => ({
                                        backgroundSize: props.showEntirePictures ? '80%' : 'cover',
                                        width: props.direction === 'vertical' ?
                                            '100%' :
                                            '25%',
                                        height: props.direction === 'vertical' ?
                                            250 :
                                            undefined,
                                        position: 'relative',
                                        [`.${iconButtonClasses.root}`]: {
                                            display: 'none',
                                            [theme.breakpoints.down('md')]: {
                                                display: 'inline-flex'
                                            }
                                        },
                                        [`&:hover .${iconButtonClasses.root}`]: {
                                            display: 'inline-flex'
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            width: '100%',
                                            height: 200
                                        }
                                    })}
                                >
                                    {
                                        context.enableBooking &&
                                        isProductBookable(
                                            props,
                                            user,
                                            trip?.stack_info ?? null,
                                            quotationCode
                                        ) &&
                                        <Checkbox
                                            onChange={onToggleForBooking}
                                            checked={checked}
                                            sx={{
                                                position: 'absolute',
                                                left: 0,
                                                top: 0
                                            }}
                                            icon={
                                                <Box
                                                    sx={{
                                                        width: 24,
                                                        height: 24,
                                                        borderRadius: '3px',
                                                        backgroundColor: '#fff'
                                                    }}
                                                >
                                                    <CheckBoxOutlineBlankOutlined
                                                        viewBox="3 3 18 18"
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%'
                                                        }}
                                                    />
                                                </Box>
                                            }
                                            checkedIcon={
                                                <Box
                                                    sx={{
                                                        width: 24,
                                                        height: 24,
                                                        borderRadius: '3px',
                                                        backgroundColor: '#fff'
                                                    }}
                                                >
                                                    <CheckBox
                                                        viewBox="3 3 18 18"
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            color: '#E6592F'
                                                        }}
                                                    />
                                                </Box>
                                            }
                                            disableRipple
                                        />
                                    }
                                    <IconButton
                                        size="small"
                                        color="inherit"
                                        sx={(theme) => ({
                                            "position": 'absolute',
                                            "top": '50%',
                                            "transform": 'translateY(-50%)',
                                            "backgroundColor": '#f4f4f4',
                                            "color": '#000',
                                            '&:hover': {
                                                color: theme.palette.getContrastText('#121212'),
                                                backgroundColor: '#121212'
                                            }
                                        })}
                                        onClick={() => {
                                            const index = currentPictureIndex - 1 < 0 ?
                                                props.pictures.length - 1 :
                                                currentPictureIndex - 1;
                                            setCurrentPictureIndex(index);
                                        }}
                                    >
                                        <KeyboardArrowLeftOutlined />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        color="inherit"
                                        sx={(theme) => ({
                                            "position": 'absolute',
                                            "right": 0,
                                            "top": '50%',
                                            "transform": 'translateY(-50%)',
                                            "backgroundColor": '#f4f4f4',
                                            "color": '#000',
                                            '&:hover': {

                                                color: theme.palette.getContrastText('#121212'),
                                                backgroundColor: '#121212'
                                            }
                                        })}
                                        onClick={() => {
                                            const index = currentPictureIndex + 1 >= props.pictures.length ?
                                                0 :
                                                currentPictureIndex + 1;
                                            setCurrentPictureIndex(index);
                                        }}
                                    >
                                        <KeyboardArrowRightOutlined />
                                    </IconButton>
                                    <Button
                                        size="small"
                                        color="inherit"
                                        variant="outlined"
                                        sx={{
                                            position: 'absolute',
                                            color: '#fff',
                                            right: 10,
                                            bottom: 10
                                        }}
                                        onClick={() => setOpenPicturesModal(true)}
                                    >
                                        {t('cart-material.edit-pictures')}
                                    </Button>
                                </CardMedia>
                            }
                            <CardContent
                                sx={(theme) => ({
                                    width: props.direction === 'vertical' || props.disablePictures ?
                                        '100%' :
                                        '75%',
                                    paddingBottom: `${theme.spacing(2)} !important`,
                                    [theme.breakpoints.down('md')]: {
                                        width: '100%'
                                    }
                                })}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    spacing={{ md: 8 }}
                                    flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                                >
                                    {
                                        !isFunction(props.mainContent) ?
                                            props.mainContent :
                                            props.mainContent(SideActions)
                                    }
                                    {
                                        (
                                            props.sideContent ||
                                            !props.direction ||
                                            props.direction === 'horizontal'
                                        ) &&
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            paddingTop={{ xs: 1.5, md: 0 }}
                                            margin={{ xs: 'auto', md: 0 }}
                                        >
                                            {
                                                props.sideContent &&
                                                (
                                                    !isProductPackaged({
                                                        product: props.item,
                                                        stackInfos: trip?.stack_info ?? null
                                                    }) ||
                                                    stackInfo?.display_product_price
                                                ) &&
                                                props.sideContent
                                            }
                                            {
                                                (!props.direction || props.direction === 'horizontal') &&
                                                SideActions()
                                            }
                                        </Stack>
                                    }
                                </Stack>
                                {
                                    (
                                        footerHasContent ||
                                        containerHasContent
                                    ) &&
                                    <Divider sx={{ marginTop: 2, marginBottom: 1.5 }} />
                                }
                                <Stack
                                    direction={{ md: 'row' }}
                                    alignItems="center"
                                    justifyContent={
                                        props.footer ?
                                            'space-between' :
                                            'flex-end'
                                    }
                                    paddingRight={{ md: 5 }}
                                    spacing={2}
                                >
                                    <div
                                        ref={(element) => {
                                            if (element && !hasTextContent(element)) {
                                                setFooterHasContent(false);
                                            }
                                        }}
                                    >
                                        {props.footer}
                                    </div>
                                    <div>
                                        {
                                            containerHasContent &&
                                            !props.alwaysOpen &&
                                            <Button
                                                color="inherit"
                                                sx={(theme) => ({
                                                    color: '#fff',
                                                    borderRadius: 20,
                                                    backgroundColor: '#FC4400',
                                                    border: '1px solid #FC4400',
                                                    textTransform: 'none',
                                                    whiteSpace: 'nowrap',
                                                    ['&:hover']: {
                                                        border: `1px solid ${theme.palette.getContrastText('#f4f4f4')}`,
                                                        color: theme.palette.getContrastText('#f4f4f4'),
                                                        backgroundColor: '#f4f4f4'
                                                    },
                                                    [theme.breakpoints.down('md')]: {
                                                        margin: 'auto !important',
                                                        marginTop: `${theme.spacing(2)} !important`
                                                    }
                                                })}
                                                onClick={() => setShowDetails((state) => !state)}
                                            >
                                                {
                                                    showDetails ?
                                                        t('cart-material.cart-construction-hide-details') :
                                                        t('cart-material.cart-construction-show-details')
                                                }
                                            </Button>
                                        }
                                    </div>
                                </Stack>
                            </CardContent>
                        </Box>
                        <Collapse
                            in={
                                showDetails &&
                                containerHasContent
                            }
                        >
                            <CardContent
                                ref={(element) => {
                                    if (element && !hasTextContent(element)) {
                                        setContainerHasContent(false);
                                    }
                                }}
                            >
                                {props.children}
                            </CardContent>
                        </Collapse>
                        {props.alerts}
                        {props.margin}
                    </Stack>
                </Card>
                {
                    props.type === 'accommodation' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeleteAccommodation(props.item)}
                    />
                }
                {
                    props.type === 'car' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeleteCar(props.item)}
                    />
                }
                {
                    props.type === 'flight' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeleteFlight(props.item)}
                    />
                }
                {
                    props.type === 'poi' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeletePoi(props.item)}
                    />
                }
                {
                    props.type === 'transfer' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeleteTransfer(props.item)}
                    />
                }
                {
                    props.type === 'assistance' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeleteAssistance(props.item)}
                    />
                }
                {
                    props.type === 'manual-accommodation' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeleteManualProduct(props.item)}
                    />
                }
                {
                    props.type === 'manual' &&
                    <CartDeleteModal
                        loading={loading}
                        deleteModal={openDeleteModal}
                        setDeleteModal={setOpenDeleteModal}
                        callback={() => onDeleteManualProduct(props.item)}
                    />
                }
                {
                    props.type === 'accommodation' &&
                    <CartConstructionProductsTableItemMenu
                        type="accommodation"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        rooms={props.item.rooms?.map((room) => room.id) ?? []}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    props.type === 'car' &&
                    <CartConstructionProductsTableItemMenu
                        type="car"
                        index={props.index}
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    props.type === 'flight' &&
                    <CartConstructionProductsTableItemMenu
                        type="flight"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    props.type === 'poi' &&
                    <CartConstructionProductsTableItemMenu
                        type="poi"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    props.type === 'transfer' &&
                    <CartConstructionProductsTableItemMenu
                        type="transfer"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        isCustom={props.item.is_custom}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    props.type === 'assistance' &&
                    <CartConstructionProductsTableItemMenu
                        type="assistance"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    props.type === 'manual-accommodation' &&
                    <CartConstructionProductsTableItemMenu
                        type="manual"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    props.type === 'manual' &&
                    <CartConstructionProductsTableItemMenu
                        type="manual"
                        open={openMenu}
                        anchorEl={menuButtonRef.current}
                        value={props.item}
                        onClose={() => setOpenMenu(false)}
                    />
                }
                {
                    openPicturesModal &&
                    <CartProductPicturesModal
                        type={props.type as any} //TODO: fix types
                        item={props.item as any}
                        onClose={() => setOpenPicturesModal(false)}
                    />
                }
            </>
        );
    }
);
