//---- Dependencies ----//
import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { Link, History } from "react-router";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

//---- Functions ----//
import GetProvider from "../../Common/Functions/GetProvider";

//---- Components ----//
import CustomOverlay from "../../Common/CustomOverlay.jsx";

//---- Actions ----//

//---- CSS ----//
import "../../../Style/CarsSearch.css";
import "../../../Style/Cart.css";


//---- Class ----//
@connect((store) => {
    return {
        provider_list: store.trip.providers,
    };
})
@withTranslation()
@withRouter
class SummaryStepCars extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {  
        };
    }

    componentDidMount() {
    }   

     render() {
        const { from_circuit, provider_list, details, t, component } = this.props;
        let car_name = (details.name).split(" OR SIMILAR");
        let tmp_pickup = details.start_date.split(/[a-zA-Z]+/g);
        let tmp_resti = details.end_date.split(/[a-zA-Z]+/g);
        let pickup = "";
        let resti = "";
        let provider = GetProvider(provider_list, details.provider);
        let subprovider = GetProvider(provider_list, details.subprovider_id);
        let a = moment.utc(details.end_date);
        let b = moment.utc(details.start_date);
        let days = Math.ceil(a.diff(b, 'hours', true) / 24);
        tmp_pickup = tmp_pickup[1].split(":");
        for (let i = 0; i < tmp_pickup.length - 1; i++) {
            pickup += tmp_pickup[i];
            i !== tmp_pickup.length - 2 ? pickup += ":" : "";
        }
        tmp_resti = tmp_resti[1].split(":");
        for (let i = 0; i < tmp_resti.length - 1; i++) {
            resti += tmp_resti[i];
            i !== tmp_resti.length - 2 ? resti += ":" : "";
        }
        return (
            <div className={"more-details-summary-box row ft-no-margin"} >
                <div className={ "col-md-4" }>
                    <div className={ "ft-relative" }>
                        <img src={details.vehicle_picture_url} className={"car-card-img" + (this.state.pic_height < 230 ? " cars-small-pic" : "")} onLoad={(event) => {this.setState({pic_height: event.target.height});}}/>
                        {
                            provider !== null && (
                                <Fragment>
                                    {
                                        (details.subprovider_id === details.provider || details.subprovider_id === null) && provider.provider.logo !== null && (
                                            <img src={ provider.provider.logo.thumbnail_little !== null ? provider.provider.logo.thumbnail_little : provider.provider.logo.url } className={"car-card-provider"} />
                                        )
                                    }
                                    {
                                        details.subprovider_id !== null && details.subprovider_id !== details.provider && (
                                            <Fragment>
                                                <Fragment>
                                                {
                                                    subprovider !== undefined && subprovider !== null && subprovider.provider.logo !== null && (
                                                        <img src={ subprovider.provider.logo.thumbnail_little !== null ? subprovider.provider.logo.thumbnail_little : subprovider.provider.logo.url } className={"car-card-provider" + (this.state.subprovider > 30 ? " provider-small-pic" : "")} onLoad={(event) => {this.setState({subprovider: event.target.width});}} />
                                                    )
                                                }
                                                {
                                                    (subprovider === undefined || subprovider === null) && (
                                                        <img src={ details.subprovider_logo } className={"car-card-provider"} />
                                                    )
                                                }
                                                </Fragment>
                                            </Fragment>
                                        )
                                    }
                                </Fragment>
                            )
                        }
                    </div>
                </div>
                <div className={ "col-md-8 more-details-cars" }>
                    <div className="row">
                        <div className={ "col-md-12 bold row ft-no-margin no-padding" }>
                            <div className={ "col-md-12 ft-dark-green ft-no-margin no-padding ft-font-20" }>
                                {car_name[0]}
                            </div>
                            <div className={ "col-md-12 car-card-category no-padding" }>
                                {t("cars.or_similiar") + " | " + t("global.category") + t("cars.categories." + (details.vehicle_category).toLowerCase()) + " (" + details.vehicle_code + ")"}
                            </div>
                            <div className={ "col-md-12 italic no-padding no-bold cars-dest-cart" }><i className={ "icon-028-pin pr10" }/>{details.address_pickup !== null ? details.address_pickup + " (" + details.station_name_pickup + ")" : details.station_name_pickup}</div>
                            {
                                (details.address_return !== details.address_pickup && details.station_name_pickup !== details.station_name_return) && (
                                    <div className={ "col-md-12 italic no-padding no-bold cars-dest-cart" }><i className={ "icon-028-pin pr10" }/>{details.address_return !== null ? details.address_return + " (" + details.station_name_return + ")" : details.station_name_return}</div>     
                                )
                            }
                            {
                                (from_circuit === undefined || from_circuit === null || from_circuit !== true) && (
                                    <div className={ "col-md-12 italic no-padding no-bold" }><i className={ "icon-075-calendar pr10" }/>{ t("cart.from") } { moment.utc(details.start_date).format("DD MMM") + ", " + pickup } { t("cart.to") } { moment.utc(details.end_date).format("DD MMM") + ", " + resti + ". " + t("accommodation.room_price_total_1") + " "} <strong >{ parseFloat(days) > 1 ? " " + days + " " + t("global.days") : " " + days + " " + t("global.day") }</strong> </div>
                                )
                            }
                        </div>
                        <div className={ "col-md-12 ft-mt10 no-padding" }>
                            <div className={ "car-card-info uppercase" }>
                                <CustomOverlay id={ "tooltip-car-transmission"} tooltip={ t("cars.transmission.transmission") } placement={ "bottom" }>
                                    <i className={ "icon-car-gear" } />
                                </CustomOverlay>
                                <span className={ "ft-font-11 car-card-info-transmission" }>
                                    {t("cars.transmission." + (details.transmission_type).toLowerCase())}
                                </span>
                            </div>
                            <div className={ details.door_count ? "car-card-info" : "car-card-info-solo" }>
                                <CustomOverlay id={ "tooltip-car-door-" + 0} tooltip={ t("tooltip.door_nbr") } placement={ "bottom" }>
                                    <i className={ "icon-car-door" } />
                                </CustomOverlay>
                                <span className={ "ft-orange" }>{details.door_count}</span>
                            </div>
                            <div className={ details.max_passengers ? "car-card-info" : "car-card-info-solo" }>
                                <CustomOverlay id={ "tooltip-car-passenger-" + 0} tooltip={ t("flight_groups.passengers") } placement={ "bottom" }>
                                    <i className="icon-user-silhouette" />
                                </CustomOverlay>
                                <span className={ "ft-orange" }>{details.max_passengers}</span>
                            </div>
                            <div className={ "car-card-info"}>
                                <CustomOverlay id={ "tooltip-car-luggage-" + 0} tooltip={ t("global.luggage") } placement={ "bottom" }>
                                    <i className={ "icon-luggage" } />
                                </CustomOverlay>
                                <span className={ "ft-orange" }>{details.baggage}</span>
                            </div>
                            {
                                details.air_conditioner && (
                                    <div className={ "car-card-info-solo" }>
                                        <CustomOverlay id={ "tooltip-car-aircon-" + 0} tooltip={ t("accommodation.equipment.air_conditioning") } placement={ "bottom" }>
                                            <i className={ "icon-aircon" } />
                                        </CustomOverlay>
                                    </div>
                                )
                            }
                        </div>
                        {
                            details.minimal_age && (
                                <div className={"col-md-12 ft-font-11 no-padding"}>
                                    {t("cars.minimal_age")}
                                    <span className={ "ft-orange" }>
                                        {details.minimal_age}
                                    </span>
                                </div>
                            )
                        }
                        {
                            details.vehicle_category_message && (
                                <div className={ "col-md-12 no-padding mt15" }>
                                    {details.vehicle_category_message}
                                </div>
                            )
                        }
                        {
                            details.included_service !== null && (
                                <ul className={ "car-service" }>
                                {
                                    details.included_service.map((service, service_index) => {
                                        if (service.included) {
                                            return (
                                                <Fragment key={service_index}>
                                                    <li className={ "ft-relative" }>
                                                        <span className={"ft-font-13 car-service-title"}>{service.title}</span>
                                                        <span className={ "icon-check-circle" } />
                                                    </li>
                                                </Fragment>
                                            )
                                        }
                                    })
                                }                                                                   
                                </ul>
                            )
                        }
                        {
                            details.free_shuttle !== null && (
                                <div className={ "col-md-12 no-padding mt15 car-free-shuttle ft-font-13" }>
                                {
                                    !details.free_shuttle && (
                                        <Fragment>
                                            <i className={"icon-plane"} />
                                            <span>
                                                {t("cars.free_shuttle_false")}
                                            </span>
                                        </Fragment>
                                    )
                                }
                                {
                                    details.free_shuttle && (
                                        <Fragment>
                                            <i className={"icon-bus"} />
                                            <span>
                                                {t("cars.free_shuttle_true")}
                                            </span>
                                        </Fragment>
                                    )
                                }
                                </div>
                            )
                        }
                        {
                            details.vehicle_category_message && (
                                <div className={ "col-md-12 no-padding mt15" }>
                                    {details.vehicle_category_message}
                                </div>
                            )
                        }
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default SummaryStepCars;
