import { isEmpty } from "lodash";
import { createDomObjectsFromHtml } from "./dom";

interface WalkingStrategy {
    nextNode(walker: TreeWalker): Node | null;
}

export function htmlWalker<T>(
    html: string,
    callback: (node: Node, document: Document) => T,
    strategy: WalkingStrategy
): T[] {
    const result: T[] = [];
    const {walker, document} = createDomObjectsFromHtml(html);
    for (
        let node: Node | null = strategy.nextNode(walker);
        node !== null;
        node = strategy.nextNode(walker)
    ) {
        result.push(callback(node, document));
    }
    return result;
}

export class MailTemplateBodyHtmlWalkingStrategy implements WalkingStrategy {
    private found: boolean;

    private next(walker: TreeWalker): HTMLElement | null {
        return (
            !this.found ?
                walker.nextNode() :
                walker.nextSibling()
        ) as HTMLElement | null;
    }

    public constructor() {
        this.found = false;
    }

    public nextNode(walker: TreeWalker): Node | null {
        for (
            let node = this.next(walker);
            node;
            node = this.next(walker)
        ) {
            if (!isEmpty(node.dataset.blockRoot)) {
                this.found = true;
                return node;
            }

            this.found = false;
        }
        return null;
    }
}

export class MailTemplateColumnsBlockHtmlWalkingStrategy implements WalkingStrategy {
    private found: boolean;

    public constructor() {
        this.found = false;
    }

    public nextNode(walker: TreeWalker): Node | null {
        while (walker.currentNode !== null && this.found) {
            const parent = walker.parentNode() as HTMLElement | null;

            if (parent?.tagName === 'TR') {
                const nextBlock = walker.nextSibling();

                if (!nextBlock) {
                    return null;
                }
                
                this.found = false;
            }
        }

        for (
            let node = walker.nextNode() as HTMLElement | null;
            node;
            node = walker.nextNode() as HTMLElement | null
        ) {
            if (!isEmpty(node.dataset.blockType)) {
                this.found = true;
                return node;
            }
        }
        return null;
    }
}