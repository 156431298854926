import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Room from "@material-ui/icons/Room";
import DateRange from '@material-ui/icons/DateRange';
import grey from "@material-ui/core/colors/grey";
import GetPrice from "./MaterialGetPrice";
import GetProviderById from "./MaterialGetProviderById";
import CurrencyFormat from "../Accommodation/FunctionalComponent/CurrencyFormat";

const useStyles = makeStyles({
    itemContainer: {
        color: grey[800]
    },
    marginRight: {
        marginRight: 16
    },
    paddingBottom: {
        paddingBottom: 8,
    },
    floatLeft: {
        float: "left"
    },
    bold: {
        fontWeight: "bold"
    },
    inline: {
        display: 'inline-block'
    },
    bigPriceFont: {
        fontSize: "1.5rem"
    },
    totalPrice: {
        textAlign: 'right',
        color: 'black'
    },
    underline: {
        textDecoration: 'underline'
    },
    black: {
        color: 'black'
    }
});

const MaterialTimelineTransport = ({cart}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const currency = useSelector(state => state.trip.currency);
    const providers = useSelector(state => state.trip.providers);
    const manual_providers = useSelector(state => state.trip.manual_providers);
    const user = useSelector(state => state.user.user);
    return (
        <Fragment>
            {
                cart.map((transport, index_transport) => {
                    let provider = GetProviderById(transport.provider, providers, manual_providers);
                    return (
                        <Grid className={ classes.itemContainer } key={ index_transport }>
                            {
                                index_transport > 0 && (
                                    <Fragment>
                                        <br/>
                                        <Divider/>
                                        <br/>
                                    </Fragment>
                                )
                            }
                            <Grid container justify={ "space-between" } spacing={ 2 }>
                                <Grid item xs>
                                    <Typography className={ classes.black } variant={ "h6" }>{ transport.name }</Typography>
                                    {
                                        transport.step === undefined && transport.address_pickup !== null && (
                                            <div className={ classes.paddingBottom }>
                                                <Room className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                <Typography className={ classes.inline }>{ t('timeline.address_pickup') } : { transport.address_pickup }</Typography>
                                            </div>
                                        )
                                    }
                                    {
                                        transport.step === undefined && transport.address_pickup !== null && transport.address_return !== null ? (
                                            <div className={ classes.paddingBottom }>
                                                <Room className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                <Typography className={ classes.inline }>{ t('timeline.address_return') } : { transport.address_return }</Typography>
                                            </div>
                                        ) : transport.step === undefined && transport.address_pickup !== null && (
                                            <div className={ classes.paddingBottom }>
                                                <Room className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                <Typography className={ classes.inline }>{ t('timeline.address_return') } : { transport.address_pickup }</Typography>
                                            </div>
                                        )
                                    }
                                    <div className={ `${classes.paddingBottom} ${classes.inline} ${classes.marginRight}` }>
                                        <DateRange className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                        <Typography className={ classes.inline }>{ t('timeline.from') } { moment.utc(transport.start_date).format('DD MMMM') } { t('timeline.to') } { moment.utc(transport.end_date).format('DD MMMM') }</Typography>
                                    </div>
                                    {
                                        transport.step && (
                                            <div>
                                                <br/>
                                                <Typography>{ transport.description }</Typography>
                                            </div>
                                        )
                                    }
                                </Grid>
                                {
                                    transport.step === undefined && provider !== null && (JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie' || user.client_full.type !== 2) && (
                                        <Grid item>
                                            <img src={ provider.logo.url } width={ 100 } alt={ 'provider logo' }/>
                                            {
                                                transport.subprovider_logo !== null && (
                                                    <img src={ transport.subprovider_logo } width={ 50 } alt={ 'sub provider logo' }/>
                                                )
                                            }
                                        </Grid>
                                    )
                                }
                            </Grid>
                            <div className={ classes.totalPrice }>
                                <CurrencyFormat language={ null } fontSize={ classes.bigPriceFont } symbol={ (currency !== null ? (currency.symbol === '$' || currency.symbol === '£' ? `${currency.symbol}${currency.iso_code}` : currency.symbol) : '') } price={ GetPrice(transport.prices, user) } with_precision={ 0 } with_bold={ 1 }/>
                                <Typography className={ classes.underline }>{ transport.is_optional ? t('cart.optional_product') : '' }</Typography>
                            </div>
                        </Grid>
                    )
                })
            }
        </Fragment>
    )
};

export default React.memo(MaterialTimelineTransport);