import { EditCarCart, SetFlightCart, EditAccommodationCart, EditPoiCart } from "../../../../Actions/Summary";

import CheckResponse from "../../../Flight/FlightSelected/Functions/CheckResponse";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import Notify from "../../../Common/Notify";

//---- Actions ----//


let allRefreshed = 0;


function redirect(component) {
    if (allRefreshed === 4) {
        Notify("Les données ont bien été mise à jour", {status:"success",pos:"top-right"});
        component.setState({
            save_bar: false
        });
    }
}

export default (cars_cart, accommodation_cart, flight_cart, poi_cart, dispatch, trip_id, trip_id_version, component) => {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {   
        for (let i = 0; i < accommodation_cart.length; i++) {
            let request = {
                is_optional: accommodation_cart[i].is_optional
            }
            $.ajax({
                method: "PATCH",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/accommodation/${accommodation_cart[i].id}/`,
                headers: headers,
                data: JSON.stringify(request),
                success: function (data) {
                    allRefreshed++;
                    data.is_manual = false;
                    data.is_custom = false;
                    data.hotel[0].dist = [];
                    data.hotel[0].sports = [];
                    data.hotel[0].meals = [];
                    data.hotel[0].room = [];
                    data.hotel[0].facility = [];
                    data.hotel[0].payment = [];
                    data.hotel[0].building = [];
                    if (data.hotel[0].facts !== undefined && data.hotel[0].facts !== null) {
                        for (let j = 0; j < data.hotel[0].facts.length; j++) {
                            if (data.hotel[0].facts[j].codename.includes("hoteltype") || data.hotel[0].facts[j].codename === "located_on_main_road" || data.hotel[0].facts[j].codename === "year_construction" || data.hotel[0].facts[j].codename === "year_renovation" || data.hotel[0].facts[j].codename === "annexe_buildings" || data.hotel[0].facts[j].codename === "num_floors_main" || data.hotel[0].facts[j].codename === "num_floors_annexe" || data.hotel[0].facts[j].codename === "m2_garden" || data.hotel[0].facts[j].codename === "m2_terrace" || data.hotel[0].facts[j].codename === "num_rooms_total" || data.hotel[0].facts[j].codename === "num_rooms_single" || data.hotel[0].facts[j].codename === "num_rooms_double" || data.hotel[0].facts[j].codename === "num_suites" || data.hotel[0].facts[j].codename === "num_apartments" || data.hotel[0].facts[j].codename === "num_junior_suites" || data.hotel[0].facts[j].codename === "num_studios" || data.hotel[0].facts[j].codename === "num_bungalows" || data.hotel[0].facts[j].codename === "num_villas") {
                                data.hotel[0].building.push(data.hotel[0].facts[j]);
                            }
                            else if (data.hotel[0].facts[j].codename.includes("dist")) {
                                data.hotel[0].dist.push(data.hotel[0].facts[j]);
                            } else if (data.hotel[0].facts[j].codename.includes("sports") && data.hotel[0].facts[j].codename !== "sports_numberofpools" && data.hotel[0].facts[j].codename !== "sports_poolheated" && data.hotel[0].facts[j].codename !== "sports_poolfreshwater" && data.hotel[0].facts[j].codename !== "sports_poolsaltwater" && data.hotel[0].facts[j].codename !== "sports_poolbar") {
                                data.hotel[0].sports.push(data.hotel[0].facts[j]);
                            } else if (data.hotel[0].facts[j].codename.includes("meals") && data.hotel[0].facts[j].codename !== "meals_specialoffers" && data.hotel[0].facts[j].codename !== "meals_lunchcarte" && data.hotel[0].facts[j].codename !== "meals_dinnerchoice") {
                                data.hotel[0].meals.push(data.hotel[0].facts[j]);
                            } else if (data.hotel[0].facts[j].codename.includes("room") && data.hotel[0].facts[j].codename !== "facility_roomservice" && data.hotel[0].facts[j].codename !== "facility_conferenceroom") {
                                data.hotel[0].room.push(data.hotel[0].facts[j]);
                            } else if (data.hotel[0].facts[j].codename.includes("facility")) {
                                data.hotel[0].facility.push(data.hotel[0].facts[j]);
                            } else if (data.hotel[0].facts[j].codename.includes("beach")) {
                                data.hotel[0].facility.push(data.hotel[0].facts[j]);
                            } else if (data.hotel[0].facts[j].codename.includes("payment")) {
                                data.hotel[0].payment.push(data.hotel[0].facts[j]);
                            }
                        }                        
                    }
                    dispatch(EditAccommodationCart(data, i));              
                    redirect(component);
                },
                error: function (error) {
                    console.log(error.responseJSON);
                    allRefreshed++;                
                    redirect(component);
                }
            });
        }
        for (let i = 0; i < cars_cart.length; i++) {
            let request = {
                is_optional: cars_cart[i].is_optional
            }
            $.ajax({
                method: "PATCH",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/car/${cars_cart[i].id}/`,
                headers: headers,
                data: JSON.stringify(request),
                success: function (data) {
                    allRefreshed++;
                    data.is_manual = false;
                    data.is_custom = false;   
                    data.detail = false;
                    data.charges.sort((a, b) => {
                        return ((parseFloat(a.amount) < parseFloat(b.amount)) ? -1 : ((parseFloat(a.amount) > parseFloat(b.amount)) ? 1 : 0));
                    });
                        
                    dispatch(EditCarCart(data));
                    redirect(component);
                },
                error: function (error) {
                    console.log(error.responseJSON);
                    allRefreshed++;                
                    redirect(component);
                }
            });
        }
        /*for (let i = 0; i < flight_cart.length; i++) {
            let request = {
                is_optional: flight_cart[i].is_optional
            }
            $.ajax({
                method: "PATCH",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/flight/${flight_cart[i].id}/`,
                headers: headers,
                success: function (data) {
                    data.is_manual = false;
                    data.is_custom = false;
                    if (data.length > 0) {
                        let arr = CheckResponse(data, data.end_date);
                        dispatch(SetFlightCart(arr));
                    }
                    allRefreshed++;             
                    redirect(component);
                },
                error: function (error) {
                    console.log(error.responseJSON);
                    allRefreshed++;                
                    redirect(component);
                }
            });
        }*/
        for (let i = 0; i < poi_cart.length; i++) {
            let request = {
                is_optional: poi_cart[i].is_optional
            }
            $.ajax({
                method: "PATCH",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/custom-products/${poi_cart[i].id}/`,
                headers: headers,
                data: JSON.stringify(request),
                success: function (data) {
                    allRefreshed++;
                    data.is_manual = false;
                    data.is_custom = true;
                    dispatch(EditPoiCart(data, i));             
                    redirect(component);
                },
                error: function (error) {
                    console.log(error.responseJSON);
                    allRefreshed++;                
                    redirect(component);
                }
            });
        }
    }
};
