import React, { createContext } from "react";
import {
    CartConstructionReplaceProductModalStep
} from "../objects/cartConstructionReplaceProductModalStep";
import { ItineraryContentItem } from "../objects/itineraryContentItem";

const initialState: {
    mode: 'edit' | 'replace' | 'add',
    enableReplace?: boolean,
    contentItem?: {
        data: ItineraryContentItem,
        from: string,
        to: string
    },
    step: CartConstructionReplaceProductModalStep,
    loading: boolean,
    dataTransformer?: (data: unknown) => unknown,
    extraData?: React.MutableRefObject<unknown | null>,
    onDelete?: () => void,
    onClose?: () => void,
    onChangeLoading: (loading: boolean) => void,
    onNextStep: () => void,
    onIsFinalStep: () => void,
    onHideNextButton: () => void,
    onShowNextButton: () => void,
    onRegisterNextEvent: (
        callback: () => void
    ) => void
} = {
    mode: 'edit',
    step: CartConstructionReplaceProductModalStep.INPUTS,
    loading: false,
    onChangeLoading: () => null,
    onNextStep: () => null,
    onIsFinalStep: () => null,
    onHideNextButton: () => null,
    onShowNextButton: () => null,
    onRegisterNextEvent: () => null
};

export const CartConstructionReplaceProductContext = createContext(initialState);
