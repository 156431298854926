import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Button,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    styled
} from "@mui/material";
import { Add, AddCircleOutline } from "@mui/icons-material";
import { getLightDestinationName } from "./utils/getLightDestinationName";
import { getDestinationPicture } from "./utils/getDestinationPicture";
import { useItineraryStepAdd } from "./network/itineraryStepAdd";
import { useItineraryStepSee } from "./network/itineraryStepSee";
import { LightDestination } from "./objects/lightDestination";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    destination: LightDestination,
    suggestedDuration: number,
}

export function ItineraryDestinationItem(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const addStep = useItineraryStepAdd({});
    const seeStep = useItineraryStepSee();

    const onAddStepInput = () => {
        addStep(props.destination);
    };

    const onSeeStep = () => {
        seeStep(props.destination);
    };

    return (
        <Container alignItems="center">
            <Avatar
                picture={
                    getDestinationPicture(props.destination)
                }
            >
                <div className="plus-button">
                    <IconButton
                        color="inherit"
                        size="large" sx={{ padding: 0 }}
                        onClick={onAddStepInput}
                    >
                        <Add color="inherit" fontSize="inherit" />
                    </IconButton>
                </div>
            </Avatar>
            <ListItemText
                primary={getLightDestinationName(locale, props.destination)}
                secondary={
                    <>
                        <div>
                            {t<string>('itinerary.recommended-duration')}{' '}
                            <span style={{ fontSize: 10 }}>
                                {t<string>('itinerary.nights-count-literal', { count: parseInt(props.suggestedDuration.toFixed(0)) })}
                            </span>
                        </div>
                        <InfoButton onClick={onSeeStep} startIcon={<AddCircleOutline fontSize="inherit" />}>
                            {t<string>('itinerary.info')}
                        </InfoButton>
                    </>
                }
                primaryTypographyProps={{
                    component: 'div',
                    sx: {
                        fontSize: 10,
                        fontWeight: 500,
                        lineHeight: 1,
                        marginBottom: 0.4,
                        opacity: 0.54
                    }
                }}
                secondaryTypographyProps={{
                    component: 'div',
                    sx: {
                        fontSize: 9,
                        fontWeight: 500,
                        opacity: 0.54
                    }
                }}
                sx={{ marginTop: 0, marginBottom: 0 }}
            />
        </Container>
    );
}

const Container = styled(ListItem)((props) => ({
    "padding": 0,
    "margin": 0,
    "marginBottom": props.theme.spacing(2),
    '.plus-button': {
        opacity: 0,
        visibility: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    '&:hover': {
        "borderRight": '3px solid #E6592F',
        '.plus-button': {
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(1px)',
            opacity: 1,
            visibility: 'visible'
        }
    }
}));

const Avatar = styled(
    ListItemAvatar,
    {
        shouldForwardProp(propName) {
            return propName !== 'picture';
        }
    }
)<{
    picture: string
}>((props) => ({
    color: '#fff',
    display: 'flex',
    alignSelf: 'stretch',
    backgroundImage: `url(${props.picture})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginRight: props.theme.spacing(2),
    minWidth: '35%',
    minHeight: 55,
    borderRadius: props.theme.shape.borderRadius
}));

const InfoButton = styled(Button)((props) => ({
    "color": '#E6592F',
    "fontSize": 10,
    "textTransform": 'none',
    "padding": 0,
    "display": 'flex',
    "marginLeft": 'auto',
    '.MuiButton-startIcon': {
        "marginRight": props.theme.spacing(0.5),
        '.MuiSvgIcon-root': {
            fontSize: '12px !important'
        }
    }
}));
