import { SetMatrixData, SetFetchedMatrixStatus } from "../../../Actions/FlightSearch";
import ProcessSearchResults from "./ProcessSearchResults";

import moment from "moment";

export default (group, dispatch, data, pax_passenger) => {
    let matrice_flights = data.flight !== undefined ? data.flight : data[0].flight;
    let outbound_date = [];
    let return_date = [];
    let flights_matrix = [];
    let matrix_length = 7;
    let lowest_price = Infinity;
    // record lowest price by day to only show flight with lowest_price
    let lowest_price_by_day = {};
    let start_date = moment(group.journey[0].start_date).format("YYYY-MM-DD");
    let end_date = moment(group.journey[group.journey.length - 1].start_date).format("YYYY-MM-DD");

    let data_flight = {
    	airlines: data[0].airlines,
    	airports: data[0].airports,
    	currency: data[0].currency,
    	distance_unit: data[0].distance_unit,
    	stations: data[0].stations,
    	trace_id: data[0].trace_id
    };

    if (group.journey.length > 1) {
        Object.keys(matrice_flights).map((column, column_index) => {
            lowest_price_by_day[column] = {};
            outbound_date.push(column);
            Object.keys(matrice_flights[column]).map((row, row_index) => {
                if (!return_date.includes(row)) {
                    return_date.push(row);
                }
                lowest_price_by_day[column][row] = Infinity;
                matrice_flights[column][row].map((flight, flight_index) => {
                    let total_price = 0;
                    if (flight.pax_pricing !== undefined && flight.pax_pricing.length !== 0) {
                        flight.pax_pricing.map((pax) => {
                            total_price += ((parseFloat(pax.prices[0].selling_price) + parseFloat(pax.taxes[0].selling_price)) * pax_passenger[pax.pax_code]);
                        });
                        if (total_price < lowest_price_by_day[column][row]) {
                            lowest_price_by_day[column][row] = total_price;
                        }
                        if (lowest_price === null || lowest_price > total_price) {
                            lowest_price = total_price;
                        }
                    } else {
                        total_price = null;
                    }
                    matrice_flights[column][row][flight_index].total_price = total_price;
                });
                if (lowest_price_by_day[column][row] === Infinity) {
                    let departure_date = moment(column).format("MM[/]DD[/]YYYY");
                    let arrival_date = moment(row).format("MM[/]DD[/]YYYY");
                    let days = moment(new Date(arrival_date)).diff(new Date(departure_date), 'days');
                    let tmp = {
                        index_start_date: column_index,
                        index_return_date: row_index,
                        total_price: null,
                        days_diff: days,
                        highlight: false,
                        selected: row === end_date && column === start_date,
                        hover: false,
                        hover_selected: false,
                        start_date: column,
                        arrival_date: row,
                        nb_luggage: 0
                    };
                    flights_matrix.push(tmp);
                } else {
                    matrice_flights[column][row].map((flight) => {
                        if (flight.total_price === lowest_price_by_day[column][row] && !flights_matrix.some(item => item.index_start_date === column_index && item.index_return_date === row_index)) {
                            let departure_date = moment(column).format("MM[/]DD[/]YYYY");
                            let arrival_date = moment(row).format("MM[/]DD[/]YYYY");
                            let days = moment(new Date(arrival_date)).diff(new Date(departure_date), 'days');
                            let tmp = {
                                index_start_date: column_index,
                                index_return_date: row_index,
                                total_price: flight.total_price,
                                days_diff: days,
                                highlight: false,
                                selected: row === end_date && column === start_date,
                                hover: false,
                                hover_selected: false,
                                start_date: column,
                                arrival_date: row,
                                nb_luggage: flight.outbound_list[0].choices[0].legs[0].baggage_allowance.number_of_pieces
                            };
                            flights_matrix.push(tmp);
                        }
                    });
                }
            });
        });
    } else {
        Object.keys(matrice_flights).map((column, column_index) => {
            outbound_date.push(column);
            lowest_price_by_day[column] = Infinity;
            matrice_flights[column].map((flight, flight_index) => {
                // fill lowest_price_by_day_by_luggages Matrixes
                let total_price = 0;
                if (flight.total_price !== null && flight.pax_pricing !== undefined && flight.pax_pricing.length !== 0) {
                    flight.pax_pricing.map((pax) => {
                        total_price += ((parseFloat(pax.prices[0].selling_price) + parseFloat(pax.taxes[0].selling_price)) * pax_passenger[pax.pax_code]) / group.travelers.length;
                    });
                    if (total_price < lowest_price_by_day[column]) {
                        lowest_price_by_day[column] = total_price;
                    }
                    if (lowest_price === null || lowest_price > total_price) {
                        lowest_price = total_price;
                    }
                } else {
                    total_price = null;
                }
                matrice_flights[column][flight_index].total_price = total_price;
            });
            if (lowest_price_by_day[column] === Infinity) {
                let tmp = {
                    index_start_date: column_index,
                    total_price: null,
                    highlight: false,
                    selected: column === start_date,
                    hover: false,
                    hover_selected: false,
                    start_date: column,
                    nb_luggage: 0
                };
                flights_matrix.push(tmp);
            } else {
                matrice_flights[column].map((flight) => {
                    if (flight.total_price === lowest_price_by_day[column] && !flights_matrix.some(item => item.index_start_date === column_index)) {
                        let tmp = {
                            index_start_date: column_index,
                            total_price: flight.total_price,
                            highlight: false,
                            selected: column === start_date,
                            hover: false,
                            hover_selected: false,
                            start_date: column,
                            nb_luggage: flight.outbound_list[0].choices[0].legs[0].baggage_allowance.number_of_pieces
                        };
                        flights_matrix.push(tmp);
                    }
                });
            }
        });
    }

    let tmp_flight = [];
    Object.keys(matrice_flights).map((column) => {
    	if (column === start_date) {
    		if (group.journey.length > 1) {
        		Object.keys(matrice_flights[column]).map((row) => {
        			if (row === end_date) {
                        matrice_flights[column][row].map((flight) => {
                            if (flight.total_price !== null) {
                                if (flight.total_price === lowest_price_by_day[column][row]) {
                                    tmp_flight.push(flight);
                                }
                            }
                        });
        			}
        		});
    		} else {
    			tmp_flight.push(matrice_flights[column][0]);
    		}
    	}
    });
    data_flight.flight = tmp_flight;
    // put flight for selected date in flight_list
    ProcessSearchResults(group, dispatch, data_flight, pax_passenger, "matrix_flight", JSON.parse(localStorage.getItem("config")));

    dispatch(SetMatrixData(flights_matrix, outbound_date, return_date, matrix_length, lowest_price, data));
    dispatch(SetFetchedMatrixStatus("Done"));
};
