import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";
import Star from "@material-ui/icons/Star";
import Favorite from '@material-ui/icons/Favorite';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import AccessibleIcon from '@material-ui/icons/Accessible';
import LowPriorityOutlinedIcon from '@material-ui/icons/LowPriorityOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import EcoOutlinedIcon from '@material-ui/icons/EcoOutlined';

import PoiDescriptionModal from "./PoiDescriptionModal";
import PoiSlider from "./PoiSlider";

import { UpdateHovered } from "../../../Actions/Poi";
import clsx from "clsx";
import localeText from "../../Functions/localeText";
import i18n from "../../../i18n";

const useStyles = makeStyles(theme => ({
    inline: {
        display: "inline"
    },
    bold: {
        fontWeight: "bold"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    cardContainer: {
        cursor: "pointer",
        borderRadius: 16,
        paddingBottom: 8,
        paddingTop: 8
    },
    imgContainer: {
        overflow: "hidden",
        height: 270,
        position: "relative",
        //marginBottom: 1,
        [theme.breakpoints.down('lg')]: {
            height: 200
        }
    },
    imgContainerTwo: {
        overflow: "hidden",
        height: 270,
        position: "relative"
        //marginBottom: 1,
    },
    relative: {
        position: "relative"
    },
    name: {
        display: "inline",
        marginRight: theme.spacing(1)
    },
    stars: {
        display: "inline",
        marginRight: theme.spacing(1)
    },
    alignRight: {
        textAlign: "right"
    },
    currencyFontSizeTotal: {
        fontSize: "0.9rem"
    },
    marginBottom: {
        marginBottom: 4
    },
    grey: {
        color: grey[800]
    },
    paddingLeftRight: {
        paddingLeft: 10,
        paddingRight: 10
    },
    paddingRight: {
        paddingRight: 10,
        paddingLeft: 10
    },
    paddingLeft: {
        paddingRight: 10,
        paddingLeft: 10
    },
    underline: {
        textDecoration: 'underline'
    },
    recommended: {
        color: green[500],
        padding: "4px 4px 0px 0px"
    },
    descriptionButton: {
        color: "#0000008A",
        backgroundColor: "#FFFFFF"
    },
    hideProduct: {
        background: "#eeee",
        border: "solid 2px #E6592F",
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderTop: 'none',
        padding: 5,
        marginBottom: 10
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderTop: 'none',
        padding: 5,
        marginBottom: 10
    },
    isOption: {
        border: "solid 2px #eeee",
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderTop: 'none',
        padding: 5,
        marginBottom: 10
    }
}));

let enter_timeout;

const PoiListActivityTwo = ({ index, activity, router }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const language = useSelector(state => state.header.tmp_language);
    const grid_type = useSelector(state => state.poi.grid_type);
    const currency_list = useSelector(state => state.base.currency_list);
    const manual_providers = useSelector(state => state.trip.manual_providers);
    const user = useSelector(state => state.user.user);
    const poi_cart = useSelector(state => state.poi.cart);
    const locales = useSelector(state => state.user.locales); 
    const in_cart = poi_cart.find((item) => {
        if (item.custom_product !== undefined && item.custom_product !== null) {
            return activity.product_code !== undefined ? activity.product_code === item.custom_product.product_code : activity.id === item.custom_product.id;
        }
    });
    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });

    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.down('sm'));
    const mobile = useMediaQuery(theme.breakpoints.down(600));
    const [showChevron, setShowChevron] = useState(false);
    const [openDescription, setOpenDescription] = useState(false);

    let stars = [];
    //if (activity.rating !== null){
    //for (let i = 0; i < activity.rating ; i++) {
    //stars.push(<Star key={ i } className={ classes.stars }/>);
    //}
    //}
    const onMouseEnter = activity => event => {
        clearTimeout(enter_timeout);
        setShowChevron(true);
        enter_timeout = setTimeout(() => {
            dispatch(UpdateHovered(activity));
        }, 1000);
    };
    const onMouseLeave = () => {
        clearTimeout(enter_timeout);
        setShowChevron(false);
        dispatch(UpdateHovered(null));
    };

    let displayed_pictures = [];
    if (!activity.is_custom || activity.poi_type) {
        if (activity.pictures !== undefined) {
            displayed_pictures = activity.pictures.slice();
            // if (activity.cover_picture !== undefined && activity.cover_picture !== null) {
            //     displayed_pictures.unshift(activity.cover_picture);
            // }
        }
    } else if (activity.variants !== undefined && activity.variants !== null && activity.variants.length > 0) {
        let minimal_variant = activity.variants.findIndex(el => el.id === activity.variant_id);
        if (minimal_variant !== undefined && minimal_variant !== null && minimal_variant !== -1 && activity.variants[minimal_variant].pictures !== undefined) {
            displayed_pictures = activity.variants[minimal_variant].pictures.slice();
            // if (activity.variants[minimal_variant].cover_picture !== undefined && activity.variants[minimal_variant].cover_picture !== null && activity.variants[minimal_variant].cover_picture.url !== undefined && activity.variants[minimal_variant].cover_picture.url !== null) {
            //     displayed_pictures.unshift(activity.variants[minimal_variant].cover_picture)
            // }
        }
    }
    let currency = null;
    if (activity.price !== undefined && activity.price !== null) {
        currency = currency_list.find((currency) => {
            return currency.id === activity.price.selling_currency;
        });
    }
    const getInProperty = (product) => {
        if (product.is_optional) {
            return 'OPTION';
        } else if (product.is_hidden_for_traveler) {
            return 'HIDE';
        } else if (product.variant !== null) {
            return 'VARIANT';
        } 
        return 'DEFAULT';
    };
    let custom_provider = null;
    let manualVariant = null;
    if (activity.is_custom && !activity.poi_type) {
        if (activity.variants !== undefined && activity.variants !== null && activity.variants.length > 0) {
            let minimal_variant = activity.variants.findIndex(el => el.id === activity.variant_id);
            if (activity.contracts !== undefined && activity.contracts !== null && activity.contracts.length > 0) {
                let available_contracts = activity.contracts.filter((contract) => {
                    return contract.activated;
                });
                if (available_contracts.length > 0) {
                    custom_provider = manual_providers.find((provider) => {
                        return provider.provider.id === available_contracts[0].provider.provider.id;
                    });
                }
            }
            if (minimal_variant !== undefined && minimal_variant !== null && minimal_variant !== -1) {
                manualVariant = activity.variants[minimal_variant];
            }
            //else {
            //manualVariant = activity.variants[0];
            //}
        }
    }
    return (
        <Grid
            id={ `activity-item-${index}` }
            className={ `${classes.cardContainer} ${mobile ? '' : grid_type === 3 || tablet ? index % 2 === 0 ? classes.paddingRight : classes.paddingLeft : index % 3 === 0 ? classes.paddingRight : index % 3 === 1 ? classes.paddingLeftRight : classes.paddingLeft}` }
            item
            container
            onMouseEnter={ onMouseEnter(activity) }
            onMouseLeave={ onMouseLeave }
            xs={ mobile ? 12 : grid_type === 3 || tablet ? 6 : 4 }
        >
            <Grid item xs={ 12 }>
                <Grid
                    onClick={() => {
                        router.push(`/${window.url_name}/apps/poi-card/${encodeURIComponent(activity.is_custom ? activity.id : activity.product_code)}`);
                    }}
                >
                    <div className={ clsx({ [classes.imgContainerTwo]: grid_type === 3 || tablet, [classes.imgContainer]: grid_type !== 3 && !tablet }) }>
                        <PoiSlider pictures={ displayed_pictures } showChevron={ showChevron } size={ grid_type === 3 ? 4 : 3 } dotSize={ 1 } from_list={in_cart !== undefined}/>
                    </div>
                    <div className={`${in_cart !== undefined ? (getInProperty(in_cart) === 'HIDE' ? classes.hideProduct : (getInProperty(in_cart) === 'OPTION' || getInProperty(in_cart) === 'VARIANT' ? classes.isOption : classes.inQuotation)) : ''}`}>
                        <div className={ classes.relative }>
                            <Typography className={ classes.name } variant={ "h6" }>{ activity.localization !== undefined && localeText(current_locale.id, activity.localization, activity.title, 'title') !== undefined ? localeText(current_locale.id, activity.localization, activity.title, 'title') : activity.title }</Typography>
                            {
                                activity.label_eco &&
                                <Tooltip title={t('poi.label_eco')} placement="bottom">
                                    <EcoOutlinedIcon style={{ color: '#4caf50', verticalAlign: 'sub' }}/>
                                </Tooltip>
                            }
                        </div>
                        <Grid container justify={ "space-between" }>
                            <Grid item>
                                <Typography>{ stars }</Typography>
                            </Grid>
                            <Grid item>
                                {
                                    activity.audio_guide && (
                                        <Tooltip title={t("poi.audio_guide")}>
                                            <GraphicEqIcon className={ classes.marginRight } fontSize={ "default" } />
                                        </Tooltip>
                                    )
                                }
                                {
                                    activity.guide && (
                                        <Tooltip title={t("poi.guide_included")}>
                                            <EmojiPeopleIcon className={ classes.marginRight } fontSize={ "default" } />
                                        </Tooltip>
                                    )
                                }
                                {
                                    activity.special_attribute === "GUIDE" && (
                                        <Tooltip title={t("poi.guide")}>
                                            <SupervisedUserCircleOutlinedIcon className={ classes.marginRight } fontSize={ "default" } />
                                        </Tooltip>
                                    )
                                }
                                {
                                    activity.special_attribute === "TRANSPORT_PASS" && (
                                        <Tooltip title={t("poi.transport_pass")}>
                                            <LoyaltyOutlinedIcon className={ classes.marginRight } fontSize={ "default" } />
                                        </Tooltip>
                                    )
                                }
                                {
                                    activity.for_children && (
                                        <Tooltip title={t("poi.for_children")}>
                                            <ChildCareIcon className={ classes.marginRight } fontSize={ "default" } />
                                        </Tooltip>
                                    )
                                }
                                {
                                    activity.for_disabled && (
                                        <Tooltip title={t("poi.for_disabled")}>
                                            <AccessibleIcon className={ classes.marginRight } fontSize={ "default" } />
                                        </Tooltip>
                                    )
                                }
                                {
                                    activity.cut_line && (
                                        <Tooltip title={t("poi.cut_line")}>
                                            <LowPriorityOutlinedIcon className={ classes.marginRight } fontSize={ "default" } />
                                        </Tooltip>
                                    )
                                }
                                {
                                    activity.multiple_days && (
                                        <Tooltip title={t("poi.multiple_days")}>
                                            <DateRangeOutlinedIcon className={ classes.marginRight } fontSize={ "default" } />
                                        </Tooltip>
                                    )
                                }
                            </Grid>
                        </Grid>
                        <Typography className={ `${classes.marginBottom} ${classes.grey}` } variant={ "body2" }>
                            { activity.place !== null && activity.place.address !== null && activity.place.address !== "" ? activity.place.address : "" }
                            { activity.place !== null && activity.place.city_name !== null ? `${(activity.place !== null && activity.place.address !== null && activity.place.address !== "") ? ", " : ""}${activity.place.city_name}` : "" }
                        </Typography>
                        <Grid container alignItems="baseline" justify="space-between">
                            <Grid item>
                                {
                                    manualVariant !== null && manualVariant.duration >= 1440 && (
                                        <Typography className={ classes.grey }>{ Math.floor(manualVariant.duration / 1440) } { Math.floor(manualVariant.duration / 1440) > 1 ? t("poi.days") : t("poi.day")} { activity.max_pax !== undefined && activity.max_pax !== null ? ', ' + activity.max_pax + " " + t("poi.max_pax") : activity.max_passengers !== undefined && activity.max_passengers !== null ? ', ' + activity.max_passengers + " " + t("poi.max_pax") : "" }</Typography>
                                    )
                                }
                                {
                                    manualVariant !== null && manualVariant.duration >= 60 && manualVariant.duration < 1440 && (
                                        <Typography className={ classes.grey }>{ Math.floor(manualVariant.duration / 60) }{t('global.abrev_hour')}{(manualVariant.duration % 60) !== 0 ? manualVariant.duration % 60 + t("global.min") : ''} { activity.max_pax !== undefined && activity.max_pax !== null ? ', ' + activity.max_pax + " " + t("poi.max_pax") : activity.max_passengers !== undefined && activity.max_passengers !== null ? ', ' + activity.max_passengers + " " + t("poi.max_pax") : "" }</Typography>
                                    )
                                }
                                {
                                    manualVariant !== null && manualVariant.duration < 60 && (
                                        <Typography className={ classes.grey }>{ manualVariant.duration }{t("global.min")} { activity.max_pax !== undefined && activity.max_pax !== null ? ', ' + activity.max_pax + " " + t("poi.max_pax") : activity.max_passengers !== undefined && activity.max_passengers !== null ? ', ' + activity.max_passengers + " " + t("poi.max_pax") : "" }</Typography>
                                    )
                                }
                                {
                                    manualVariant === null && activity.duration >= 1440 && (
                                        <Typography className={ classes.grey }>{ Math.floor(activity.duration / 1440) }{t('global.abrev_hour')} { activity.max_pax !== undefined && activity.max_pax !== null ? ', ' + activity.max_pax + " " + t("poi.max_pax") : activity.max_passengers !== undefined && activity.max_passengers !== null ? ', ' + activity.max_passengers + " " + t("poi.max_pax") : "" }</Typography>
                                    )
                                }
                                {
                                    manualVariant === null && activity.duration >= 60 && activity.duration < 1440 && (
                                        <Typography className={ classes.grey }>{ Math.floor(activity.duration / 60) }{t('global.abrev_hour')} { activity.max_pax !== undefined && activity.max_pax !== null ? ', ' + activity.max_pax + " " + t("poi.max_pax") : activity.max_passengers !== undefined && activity.max_passengers !== null ? ', ' + activity.max_passengers + " " + t("poi.max_pax") : "" }</Typography>
                                    )
                                }
                                {
                                    manualVariant === null && activity.duration < 60 && (
                                        <Typography className={ classes.grey }>{ activity.duration }{t("global.min")} { activity.max_pax !== undefined && activity.max_pax !== null ? ', ' + activity.max_pax + " " + t("poi.max_pax") : activity.max_passengers !== undefined && activity.max_passengers !== null ? ', ' + activity.max_passengers + " " + t("poi.max_pax") : "" }</Typography>
                                    )
                                }
                            </Grid>
                            <Grid>
                                {
                                    !activity.is_custom &&
                                <Fragment>
                                    {
                                        (user.client_full.type !== 2 || JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie') && activity !== null && activity.provider.logo !== null && (
                                            <img src={ activity.provider.logo.url } alt={ "provider logo" } style={{ maxWidth: 120, maxHeight: 50 }} />
                                        )
                                    }
                                    {
                                        (user.client_full.type !== 2 || JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie') && activity !== null && activity.provider.logo === null && (
                                            <Typography className={ classes.grey }>{ activity.provider.name }</Typography>
                                        )
                                    }
                                </Fragment>
                                }
                                {
                                    activity.is_custom && (user.client_full.type !== 2 || JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie') && 
                                <Fragment>
                                    {
                                        activity.provider !== null && activity.provider.provider.logo !== null && (
                                            <img src={ activity.provider.provider.logo.url } alt={ "provider logo" } style={{ maxWidth: 120, maxHeight: 75 }} />
                                        )
                                    }
                                    {
                                        activity.provider !== null && activity.provider.provider.logo === null && (
                                            <Typography className={ classes.grey }>{ activity.provider.provider.name }</Typography>
                                        )
                                    }
                                    {
                                        activity.provider === null && custom_provider !== null && custom_provider.provider.logo !== null && (
                                            <img src={ custom_provider.provider.logo.url } alt={ "provider logo" } style={{ maxWidth: 120, maxHeight: 75 }} />
                                        )
                                    }
                                    {
                                        activity.provider === null && custom_provider !== null && custom_provider.provider.logo === null && (
                                            <Typography className={ classes.grey }>{ custom_provider.provider.name }</Typography>
                                        )
                                    }
                                </Fragment>
                                }
                            </Grid>
                        </Grid>
                        <Grid container justify={ "space-between" } alignItems={ "center" }>
                            {
                                activity.poi_type !== true && activity.price !== undefined && activity.price !== null && (
                                    <Grid item onClick={() => {
                                        router.push(`/${window.url_name}/apps/poi-card/${encodeURIComponent(activity.is_custom ? activity.id : activity.product_code)}`); 
                                    }}>
                                        {
                                            currency !== undefined && currency !== null &&
                                            <div className={ classes.underline }>
                                                <Typography className={ classes.inline } variant={ "body2" }> { t("poi.price_from") + " " }</Typography>
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(activity.price.selling_price)}
                                            </div>
                                        }
                                    </Grid>
                                )
                            }
                            { activity.poi_type === true && <Typography>{ t('global.poi') }</Typography> }
                            {
                                (activity.short_description !== null || (activity.is_custom && localeText(current_locale.id, activity.localization, activity.short_description, 'short_description') !== '')) && (
                                    <Grid className={classes.alignRight} item style={{ marginTop: 20 }}>
                                        <Button variant="contained" size="small" className={classes.descriptionButton} onClick={(e) => {
                                            e.stopPropagation(); setOpenDescription(true);
                                        }}>
                                            { t('global.description') }
                                        </Button>
                                    </Grid>
                                )
                            }
                            {
                                activity.recommended &&
                                <Grid className={ classes.recommended } item onClick={() => {
                                    router.push(`/${window.url_name}/apps/poi-card/${encodeURIComponent(activity.is_custom ? activity.id : activity.product_code)}`); 
                                }}>
                                    <Favorite/>
                                </Grid>
                            }
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            <PoiDescriptionModal openDescription={openDescription} setOpenDescription={setOpenDescription} activity={activity} />
        </Grid>
    );
};

export default React.memo(PoiListActivityTwo);
