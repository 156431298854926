//---- Description :  ----//
//---- Contact : kevin.deffontaine@facilitatrip.com ----//

import GetDiffDays from "./GetDiffDays.js";
import UpdateCalendar from "./UpdateCalendar.js";
import CheckItinerary from "./CheckItinerary.js";

//---- Actions ----//
import { ToggleCalendarModal, SetCurrentCalendarDay, SelectCalendarDay } from "../../../Actions/Calendar";

let previousHeight = 0;
let previousWidth = 0;
let machin = null;

export default (fullpage, dispatch, language, flightEvent, carsEvent, accomodation, start_date, end_date, current_calendar_day, itinerary_list, departure_destination, return_destination, select_day, t) => {
    $("#calendar").fullCalendar({
        header: {
            left: "",
            center: "prev next",
            right: ""
        },
        slotDuration: "00:10:00",
        views: {
            agendaThreeDay: {
                title : "test",
                type: "agenda",
                duration: { days: 3 },
                buttonText: "3 days",
                columnFormat: "dddd D MMMM",
                start: moment.utc(new Date()),
                end: moment.utc(new Date())
            },
            agendaTwoDay: {
                title : "test",
                type: "agenda",
                duration: { days: 2 },
                buttonText: "2 days",
                columnFormat: "dddd D MMMM",
                start: moment.utc(new Date()),
                end: moment.utc(new Date())
            },
            agendaSevenDay: {
                title : "test",
                type: "agenda",
                duration: { days: 7 },
                buttonText: "7 days",
                columnFormat: "dddd D MMMM",
                start: moment.utc(new Date()),
                end: moment.utc(new Date())
            }
        },
        defaultView: fullpage === false ? "agendaThreeDay" : "agendaSevenDay",
        allDayText: "",
        allDaySlot: false,
        eventOverlap: false,
        // intervalDuration: 1,
        slotEventOVerlap: true,
        droppable: true,
        editable: false,
        eventLimit: true,
        locale: language,
        timezone: false,
        slotLabelFormat: "HH[h]",
        textEscape: false,
        firstDay: new Date(start_date).getDay(),
        defaultDate: moment.utc(start_date).format("YYYY-MM-DD"),
        scrollTime: "9:00:00",
        slotLabelInterval: {hours: 1},
        displayEventEnd: true,
        timeFormat: "HH:mm",
        // displayEventTime: false,
        // eventClick: function (calEvent) {
        //     let info = {
        //         title: calEvent.title,
        //         info: calEvent.info,
        //         type: calEvent.type
        //     };
        //     dispatch(ToggleCalendarModal(true, info));
        // },
        viewRender: function (view) {
            let current_day = current_calendar_day;
            let tmp_day = select_day;
            if (machin === null) {
                machin = select_day;
            }
            if (view.start.isValid() && current_calendar_day === null) {
                current_day = view.start;
                dispatch(SetCurrentCalendarDay(view.start));
            }
            let maxDate = new moment.utc(end_date);
            let minDate = new moment.utc(start_date);
            if (current_day > view.start && GetDiffDays(view.start.format(), minDate.format()) <= 4)
            {
                current_day = moment.utc(current_day).subtract(1, "day");
                dispatch(SetCurrentCalendarDay(moment.utc(current_day).subtract(1, "day")));
                $("#calendar").fullCalendar("gotoDate", moment.utc(current_day));
            }
            if (current_day < view.start && GetDiffDays(view.start.format(), maxDate.format()) <= 4)
            {
                current_day = moment.utc(current_day).add(1, "day");
                dispatch(SetCurrentCalendarDay(moment.utc(current_day).add(1, "day")));
                $("#calendar").fullCalendar("gotoDate", moment.utc(current_day));
            }
            else if ($("#calendar").fullCalendar("getDate").isValid()) {
                current_day = $("#calendar").fullCalendar("getDate");
                dispatch(SetCurrentCalendarDay($("#calendar").fullCalendar("getDate")));
            }
            minDate.hours(23);
            if (minDate >= view.start && minDate <= view.end) {
                $(".fc-prev-button").prop("disabled", true);
                $(".fc-prev-button").addClass("fc-state-disabled");
            }
            else {
                $(".fc-prev-button").removeClass("fc-state-disabled");
                $(".fc-prev-button").prop("disabled", false);
            }
            //Future
            if (maxDate >= view.start && maxDate.add(1, "day") <= view.end) {
                $(".fc-next-button").prop("disabled", true);
                $(".fc-next-button").addClass("fc-state-disabled");
            } else {
                $(".fc-next-button").removeClass("fc-state-disabled");
                $(".fc-next-button").prop("disabled", false);
            }
            let table = $(".fc-row.fc-widget-header").children("table");
            table.css({'width': '100%', 'border-spacing' : '15px 10px', 'border-collapse' : 'separate', 'margin-left' : '10px'});
            $(".fc-axis.fc-widget-header").remove();
            $(".fc-day-header").each(function () {
                let currentDate = new Date($(this).attr("data-date"));
                let endDate = new Date(end_date);
                // if (app.mCurrentDayItineraryView == null)
                // {
                //     app.mCurrentDayItineraryView = $(this).attr("data-date");
                //     $(this).css({"background-color" : "#65b9c4"});
                // }
                
                $(this).attr("data-toggle", "tooltip");
                $(this).attr("title", t("calendar.select_day"));
                $(this).html(function(_, txt) {
                    if (txt.indexOf(t("global.day").toUpperCase()) === -1)
                    {
                        let diffDays = GetDiffDays($(this).attr("data-date"), start_date);
                        txt = "<b>" + t("global.day").toUpperCase() + " " + diffDays.toString() + "</b>" + "<br>" + txt;
                    }
                    return txt;
                });
                UpdateCalendar(start_date, end_date, itinerary_list, departure_destination, return_destination, machin, dispatch);
                $(this).css("cursor", "pointer");
                $(this).bind("click", function() {
                    machin = $(this).attr("data-date");
                    UpdateCalendar(start_date, end_date, itinerary_list, departure_destination, return_destination, machin, dispatch);
                    // $(".fc-day-header").each(function() {
                    //   $(this).css({'background-color' : '#bababa'});
                    // });
                    // $(this).css({'background-color' : '#65b9c4'});
                    // app.removeItinerary();
                    // app.changeDay(app.mCurrentDayItineraryView);
                    // app.checkItinerary();
                });
            });
        },
        eventRender: function(event, element) {
            if (event.type === 1)
            {
                element.find(".fc-time span").before('<img class="icoTransportMacro" src="' + event.icoPath + '">');
                element.find(".fc-title").addClass("transport");
                element.css({'border' : 'none'});
                element.find('.fc-time').css("color", event.borderColor);
                element.find('.cardEvent').css("color", event.borderColor);
                element.find('.removeEvent').css("color", event.borderColor);
                element.find('.fc-title').append("<br>");
                element.find('.fc-time span').addClass("transport");
                // if (event.date.length > 30) {
                //     var index = 0;
                //     var nb = 0;
                //     var count = (event.date.match(/ /g) || []).length;
                //     for (var i = 0; i < event.date.length; i++) {
                //         if (event.date[i] == " ") {
                //             nb += 1;
                //             if (nb == Math.round((count / 2))) {
                //                 index = i;
                //             }
                //         }
                //     }
                //     if (event.date.indexOf("<br>") == -1) {
                //         event.date = event.date.slice(0, index) + "<br>" + event.date.slice(index);
                //     }
                // }
                element.find('.fc-title').html(event.calendarTitle);
                element.find('.fc-time span').html(event.date);
            }
            else if (event.type === 4)
            {
                //  element.css({'border' : 'none'});
                element.append('<div class="outer" style="z-index:10000;"> <div class="middle"> <div class="inner"><div class="calendarTransportContent"> </div></div></div></div>');

                let calendarTransportContent = element.find('.calendarTransportContent');
                calendarTransportContent.append('<div class="col-md-12 cityTitle">' + event.start_city + '</div>');
                calendarTransportContent.append('<div class="col-md-12 col-sm-12 no-padding"><img class="col-md-2 col-sm-2 transportIcon" src="' + event.transportIcons + '"> </img><span class="col-md-7 col-sm-7 transportInfo">' + event.transportInfo +'</span></div>');
                // calendarTransportContent.append('<div class="col-md-14 col-sm-14"><div class="col-md-7 col-md-7 transportInfo">' + event.transportInfo +'</div></div>');
                calendarTransportContent.append('<div class="col-md-12 changeMode">' + t("global.edit") + '</div>');
                calendarTransportContent.append('<div class="col-md-12 cityTitle">' + event.end_city + '</div>');
                element.addClass('notokitinerarycalendar');
                element.addClass('smallWidth');
                element.find('.fc-time').css("color", event.borderColor);
                element.find('.cardEvent').css("color", event.borderColor);
                element.find('.removeEvent').css("color", event.borderColor);
                $('<div class="vertical-line"> <div class="outer"> <div class="middle"> <div class="inner"><img class="padlock" src="/Img/Calendar/padlock.png"></div></div></div></div>').insertBefore(element.find('.fc-content'));
                element.find('.fc-time').remove();
                element.find('.fc-content').css("right", "14px");
                element.find('.fc-bg').css('background', 'rgba(0,0,0,0) url("/Img/Calendar/cross-lines.png") repeat scroll 0 0');
                element.find('.vertical-line').on(function() {
                    let height = element.find('.vertical-line').height() / 2;
                    element.find('.padlock').css('margin-top', height + 'px');
                });
            }
            if (event.type === 3) {
                for (let i = 0; i < event.info[0].stars; i++) {
                    if (i === 0) {
                        element.find('.fc-title').append('<br>');
                    }
                    element.find('.fc-title').append("<i class='calendarStars fa fa-star fa-1x'></i>");
                }
                element.find('.fc-title').append('<br><div class="calendarCardAndRemoveContainer"><i data-toggle="tooltip" title=' + t("calendar.duplicate_activity") + ' class="icon-copy duplicate-activity accommodation-color pointer"></i><i data-toggle="tooltip" title=' + t("calendar.delete_activity") + ' class="removeEvent fa fa-trash accommodation-color pull-right"></i><div>');
                element.find(".fc-time span").before("<img src='/Img/Calendar/hebergements.png'>");
                // element.find(".fc-content").prepend("<i class='icon-099-delete closeon'></i>");
                // element.find(".closeon").on('click', function () {
                //     $('#calendar').fullCalendar('removeEvents', event._id);
                // });
                element.find(".removeEvent").on("click", function() {
                    $('#calendar').fullCalendar('removeEvents', event._id);
                });
                element.find(".duplicate-activity").on("click", function() {
                    let clone = Object.assign({}, event);
                    let clone_start = Object.assign({}, clone.end);
                    clone.start = clone_start;
                    let clone_end = Object.assign({}, clone.end);
                    clone.end = moment.utc(clone_end).add(2, "h");
                    $('#calendar').fullCalendar('renderEvent', clone, true);
                });
            }
        },
        eventSources: [
            {
                events: flightEvent,
                allDay: false, // will make the time show
                className: "fc-event-flights"
            },
            // {
            //     events: carsEvent,
            //     allDay : false, // will make the time show
            //     className: "fc-event-cars"
            // },
            {
                events: accomodation,
                allDay: false, // will make the time show
                className: "fc-event-accommodation",
                editable: true
            }
            
        ],
        eventMouseover : function(event, jsEvent, view)
        {
            if (event.type !== 4) {
                if (event.type === 3)
                    $(this).css("cursor", "move");
                else
                    $(this).css("cursor", "default");
            }
            previousWidth =  $(this).width();
            previousHeight =  $(this).height();
            if (previousHeight < $(this).find(".fc-title").height() + 60) {
                $(this).height($(this).find(".fc-title").height() + 60);
            }
            // if (previousWidth < $(this).find(".fc-title").width() + 20) {
            //     $(this).width($(this).find(".fc-title").width() + 20);
            // }
            // if ($(this).hasClass("okitinerarycalendar") && app.mCurrentEvent != null) {
            //     $(this).css("width", "0px");
            // }
            // else if ($(this).hasClass('notokitinerarycalendar') == false) {
            //   $(this).css("background-color", "white");
            // }
        },
        eventMouseout : function(event, jsEvent, view) {
            $(this).height(previousHeight);
            // $(this).width(previousWidth);
        },
        eventDrop: function(event, delta, revertFunc) {
            CheckItinerary(event);
            // var check = app.updateEvent(event, revertFunc);
            // if (check != false) {
            //     app.deleteItem(app.mCurrentEvent);
            //     app.saveItem(app.mCurrentEvent);
            //     app.removeItinerary();
            //     app.checkItinerary();
            //     app.mCurrentEvent = null;
            // }
            // setTimeout(function() {$(".fc-scroller").css("height", ($(document).height() - 167) + "px");}, 200);
        },
        eventResize : function(event, delta, revertFunc) {
            CheckItinerary(event);
            // var check = app.updateEvent(event, revertFunc);
            // if (check != false) {
            //     app.deleteItem(app.mCurrentEvent);
            //     app.saveItem(app.mCurrentEvent);
            //     app.removeItinerary();
            //     app.checkItinerary();
            //     app.mCurrentEvent = null;
            // }
            // setTimeout(function() {$(".fc-scroller").css("height", ($(document).height() - 167) + "px");}, 200);
        }
    });
}