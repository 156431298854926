import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//Core
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Accordion from "@material-ui/core/Accordion";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { makeStyles } from "@material-ui/core/styles";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
//Icons
//Components
import CartAssistanceVip from "./CartAssistanceVip";
import CartAssistancePrice from "./CartAssistancePrice";
import CartAssistanceBasic from "./CartAssistanceBasic";
import CartAssistanceValidate from "./CartAssistanceValidate";
import CartAssistanceEstimation from "./CartAssistanceEstimation";
import CartAssistancePax from "./CartAssistancePax";
import CartAssistancePrices from "./CartAssistancePrices";
import CartAssistanceTravelerType from "./CartAssistanceTravelerType.tsx";
import CartAssistancePlus from "./CartAssistancePlus";
import CartAssistancePremium from "./CartAssistancePremium";
import { Divider } from "@material-ui/core";
import CartAssistanceOptions from "./CartAssistanceOptions";


const useStyles = makeStyles({
    relative: {
        position: 'relative !important'
    },
    assistanceAppBar: {
        position: 'relative !important',
        backgroundColor: '#3367D6 !important',
        color: 'white'
    },
    assistanceTitle: {
        backgroundColor: 'rgba(0, 0, 0, .03) !important',
        borderBottom: '1px solid rgba(0, 0, 0, .125) !important'
    },
    buttonContainer: {
        marginBottom: 32
    },
    assistanceHelper: {
        whiteSpace: 'pre-line',
        textAlign: 'justify'
    }
});

const CartAssistanceModal = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const assistance_modal = useSelector((state) => state.cart.assistance_modal);
    const assistance_type = useSelector((state) => state.cart.assistance_type);
    const assistance_prices = useSelector((state) => state.cart.assistance_prices);
    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;
    const onToggleAssistance = () => {
        dispatch({ type: 'CART_TOGGLE_ASSISTANCE' });
    };
    return (
        <Dialog fullWidth open={ assistance_modal } onClose={ onToggleAssistance } maxWidth={ 'md' }>
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography fontWeight="bold">{ t('cart-material.assistance-estimate') }</Typography>
                    <IconButton onClick={ onToggleAssistance }>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {
                    assistance_prices === null ? assistance_type === 1 ? (
                        <Accordion square expanded>
                            <AccordionSummary className={ classes.assistanceTitle }>
                                <Typography>{ t('cart-material.assistance-select-pax') }</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={ 'ft-user-subContainer' }>
                                <Grid container spacing={ 2 }>
                                    <Grid item xs={ 12 }>
                                        <CartAssistancePax/>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ) : (
                        <Accordion square expanded>
                            <AccordionSummary className={ classes.assistanceTitle }>
                                <Typography>{ t('cart-material.assistance-select-options') }</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={ 'ft-user-subContainer' }>
                                <Grid container spacing={ 2 }>
                                    <Grid item xs={ 12 }>
                                        <Alert className={ 'ft-justify' } severity={ 'info' }>
                                            <AlertTitle>{ t('global.informations') } :</AlertTitle>
                                            <p className={ classes.assistanceHelper }>{ t('cart-material.assistance-select-options-helper') }</p>
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={ 12 }>
                                        <CartAssistanceTravelerType/>
                                    </Grid>
                                    {/* <Grid item xs={ 12 }>
                                        <CartAssistanceBasic/>
                                    </Grid> */}
                                    <Grid item xs={ 12 }>
                                        <CartAssistancePlus/>
                                    </Grid>
                                    <Grid item xs={ 12 }>
                                        <CartAssistancePremium/>
                                    </Grid>
                                    <Grid item xs={ 12 }>
                                        <CartAssistanceVip/>
                                    </Grid>
                                    {/* <Grid item xs={ 12 }>
                                        <Divider/>
                                        <CartAssistanceOptions/>
                                    </Grid> */}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ) : assistance_type === 1 ? (
                        <Accordion square expanded>
                            <AccordionSummary className={ classes.assistanceTitle }>
                                <Typography>{ t('cart-material.assistance-estimation-result') }</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={ 'ft-user-subContainer' }>
                                <Grid container spacing={ 2 }>
                                    <Grid item xs={ 12 }>
                                        <CartAssistancePrices index={ 0 }/>
                                    </Grid>
                                    <Grid item xs={ 12 }>
                                        <CartAssistancePrices index={ 1 }/>
                                    </Grid>
                                    {
                                        quotation_code === 'cercledesvoyages' &&
                                        <Grid item xs={ 12 }>
                                            <CartAssistancePrices index={ 2 }/>
                                        </Grid>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ) : (
                        <Accordion square expanded>
                            <AccordionSummary className={ classes.assistanceTitle }>
                                <Typography>{ t('cart-material.assistance-estimation-result') }</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={ 'ft-user-subContainer' }>
                                <Grid container spacing={ 2 }>
                                    <Grid item xs={ 12 }>
                                        <CartAssistancePrice/>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )
                }
                <DialogActions>
                    {
                        assistance_prices === null ? assistance_type === 1 ? (
                            <>
                                <Button onClick={ onToggleAssistance }>{ t('global.cancel') }</Button>
                                <CartAssistanceEstimation/>
                            </>
                        ) : (
                            <>
                                <Button onClick={ onToggleAssistance }>{ t('global.cancel') }</Button>
                                <CartAssistanceEstimation/>
                            </>
                        ) : assistance_type === 1 ? (
                            <>
                                <Button onClick={ onToggleAssistance }>{ t('global.cancel') }</Button>
                                <CartAssistanceValidate/>
                            </>
                        ) : (
                            <>
                                <Button onClick={ onToggleAssistance }>{ t('global.cancel') }</Button>
                                <CartAssistanceValidate/>
                            </>
                        )
                    }
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default CartAssistanceModal;
