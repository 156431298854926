//---- Dependencies ----//
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ListGroup, ListGroupItem  } from "reactstrap";
import { withRouter } from 'react-router';

//---- Commons ----//
import Notify from "../../Common/Notify.js";

//---- Components ----//
import CarsRenderAgencies from "./CarsRenderAgencies.jsx";

//---- Actions ----//
import { ToggleAgenciesModal, SetAgencyType, SelectedCar, SetAgencyErrors, RemoveAgencyError } from "../../../Actions/CarsSearch";

//---- Class ----//
@connect((store) => {
    return {
        open_agencies_modal: store.cars_search.open_agencies_modal,
        selected_car: store.cars_search.selected_car,
        cars_results: store.cars_search.cars_results,
        step_info: store.cars_search.step_info,
        car_index: store.cars_search.copy_location_index
    }
})
@withTranslation()
@withRouter
export default class CarsServiceModal extends Component {
    constructor(props, context) {
        super(props, context);
    }
    selectCar() {
        const { selected_car, router, dispatch, step_info, car_index, t } = this.props;
        let same_agency = step_info[car_index].agency_go.city_code === step_info[car_index].agency_return.city_code || step_info[car_index].agency_go.airport_code === step_info[car_index].agency_return.airport_code;
        let checked = true;
        if (selected_car.location_code_pickup === null) {
            Notify(t("cars.error_location_pickup"), {status: 'danger', pos: 'top-center'});
            if (!selected_car.error.includes("type_0"))
                dispatch(SetAgencyErrors("type_0"));
            checked = false;
        }
        else {
            if (selected_car.error.includes("type_0")) {
                dispatch(RemoveAgencyError("type_0"));
            }
        }
        if (!same_agency) {
            if (selected_car.location_code_return === null) {
                Notify(t("cars.error_location_return"), {status: 'danger', pos: 'top-center'});
                if (!selected_car.error.includes("type_1"))
                    dispatch(SetAgencyErrors("type_1"));
                checked = false;
            }
            else {
                if (selected_car.error.includes("type_1")) {
                    dispatch(RemoveAgencyError("type_1"));
                }
            }
        }
        if (checked)
            router.push(`/${window.url_name}/apps/cars-add-to-cart/${this.props.params.id_step}`);
    }
    render() {
        const { open_agencies_modal, selected_car, cars_results, car_index, step_info, dispatch, t } = this.props;
        let stations = null;
        let provider_id = null;
        if (selected_car) {
            cars_results.map((provider) => {
                if (provider.provider_id === selected_car.provider_id) {
                    stations = provider.stations;
                    provider_id = selected_car.provider_id;
                }
            })
        }
        let same_agency = false;
        if ((step_info[car_index].agency_go.city_code !== undefined && step_info[car_index].agency_return.city_code !== undefined) && (step_info[car_index].agency_go.city_code !== undefined && step_info[car_index].agency_return.city_code !== undefined)) {
            if (step_info[car_index].agency_go.city_code === step_info[car_index].agency_return.city_code) {
                same_agency = true;
            }
        }
        if ((step_info[car_index].agency_go.airport_code !== undefined && step_info[car_index].agency_return.airport_code !== undefined) && (step_info[car_index].agency_go.airport_code !== null && step_info[car_index].agency_return.airport_code !== null)) {
            if (step_info[car_index].agency_go.airport_code === step_info[car_index].agency_return.airport_code) {
                same_agency = true;
            }
        }
        return (
            <Modal id={ "modal-cars-other-destination" } isOpen={ open_agencies_modal } toggle={() => {  dispatch(ToggleAgenciesModal(false)); }} size={"lg"} backdrop={"static"}>
                <div>
                    <ModalHeader toggle={() => {  dispatch(ToggleAgenciesModal(false)); }}>
                        <div id={"modal-choice-header"}>
                            <h1 className={"title-other-choices"}> {t("cars.agencies_available")} </h1>
                        </div>
                    </ModalHeader>
                    <ModalBody className={"grey-bg"}>
                    {
                        selected_car && (
                            <div>
                            {
                                !same_agency && (
                                    <div className={"cars-modal-type"}>
                                        <span className={selected_car.agency_type === 0 ? (selected_car.error !== undefined && !selected_car.error.includes("type_0") ? "active" : "active error") : (selected_car.error === undefined || selected_car.error.includes("type_0") ? "error" : "")} onClick={() => { dispatch(SetAgencyType(0))}}>
                                            {t("cars.agency_origin")}
                                        </span>
                                        <span className={selected_car.agency_type === 1 ? (selected_car.error !== undefined && !selected_car.error.includes("type_1") ? "active" : "active error") : (selected_car.error === undefined || selected_car.error.includes("type_1") ? "error" : "")} onClick={() => { dispatch(SetAgencyType(1))}}>
                                            {t("cars.agency_return")}
                                        </span>
                                    </div>
                                )
                            }
                            {
                                same_agency && (
                                    <div className={"cars-modal-type"}>
                                        <span className={selected_car.agency_type === 0 ? (selected_car.error !== undefined && !selected_car.error.includes("type_0") ? "active" : "active error") : (selected_car.error === undefined || selected_car.error.includes("type_0") ? "error" : "")} onClick={() => { dispatch(SetAgencyType(0))}}>
                                            {t("cars.agency_origin")}
                                        </span>
                                    </div>
                                )
                            }
                                <CarsRenderAgencies stations={stations} same_agency={same_agency} childref={this.refs.validate_agencies}/>
                                <button ref={ "validate_agencies" } className={ "btn-orange ft-white uppercase block-center" } onClick={() => {this.selectCar()}}>{t("global.validate")}</button>
                            </div>
                        )
                    }
                    </ModalBody>
                </div>
            </Modal>
        );
    }
}