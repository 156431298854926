import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ClickAwayListener } from "@mui/material";
import { EditorState, RichUtils } from "draft-js";
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

type Props = {
    editorState: EditorState,
    onChangeEditorState: (state: EditorState) => void,
}

export function RichEditorTextTypeControl(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const currentType = useMemo(() => {
        const currentSelection = props.editorState.getSelection();
        const blockKey = currentSelection.getStartKey();
        const currentBlock = props.editorState.getCurrentContent().getBlockForKey(blockKey);
        const type = currentBlock.getType();
        return type === 'unstyled' ? 'paragraph' : type;
    }, [props.editorState]);

    const onToggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setOpen((state) => !state);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onChangeFontSize = (type: string) => {
        props.onChangeEditorState(
            RichUtils.toggleBlockType(
                props.editorState,
                type
            )
        );
        onClose();
    };

    return (
        <ClickAwayListener onClickAway={onClose}>
            <div className={ 'ft-editorTextTypeContainer' }>
                <Button
                    className={ 'ft-editorTextType' }
                    color={ 'inherit' }
                    onMouseDown={onToggle}
                    endIcon={ open ? <KeyboardArrowUp/> : <KeyboardArrowDown/> }
                >
                    { t<string>(`shared.${currentType}`) }
                </Button>
                {
                    open &&
                    <div className={ 'ft-editorTextTypeOptionContainer' }>
                        <p className={ 'ft-editorTextTypeOption' } onMouseDown={() => onChangeFontSize('paragraph') }>{ t<string>('shared.paragraph') }</p>
                        <h6 className={ 'ft-editorTextTypeOption' } onMouseDown={() => onChangeFontSize('header-six') }>{ t<string>('shared.header-six') }</h6>
                        <h5 className={ 'ft-editorTextTypeOption' } onMouseDown={() => onChangeFontSize('header-five') }>{ t<string>('shared.header-five') }</h5>
                        <h4 className={ 'ft-editorTextTypeOption' } onMouseDown={() => onChangeFontSize('header-four') }>{ t<string>('shared.header-four') }</h4>
                        <h3 className={ 'ft-editorTextTypeOption' } onMouseDown={() => onChangeFontSize('header-three') }>{ t<string>('shared.header-three') }</h3>
                        <h2 className={ 'ft-editorTextTypeOption' } onMouseDown={() => onChangeFontSize('header-two') }>{ t<string>('shared.header-two') }</h2>
                        <h1 className={ 'ft-editorTextTypeOption' } onMouseDown={() => onChangeFontSize('header-one') }>{ t<string>('shared.header-one') }</h1>
                    </div>
                }
            </div>
        </ClickAwayListener>
    );
}
