import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WithRouterProps } from "react-router";
import { CartConstructionReplacePoiInputs, ReplacePoiSearchInputs } from "./CartConstructionReplacePoiInputs";
import PoiCard from "../PoiMaterial/PoiCard";
import { CartConstructionReplaceProductContext } from "./utils/cartConstructionReplaceProductContext";
import {
    CartConstructionReplaceProductModalStep
} from "./objects/cartConstructionReplaceProductModalStep";
import { PoiCart } from "../Itinerary/objects/poiCart";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    poi?: PoiCart
} & WithRouterProps

export function CartConstructionReplacePoiContent(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState<ReplacePoiSearchInputs>({
        searchedPoi: null,
        startDate: null,
        endDate: null
    });
    const context = useContext(CartConstructionReplaceProductContext);
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const { poi, ...rest } = props;

    let productCode = '';

    if (poi) {
        productCode = poi.is_custom ?
            poi.custom_product?.id.toString() ?? '-1' :
            poi.custom_product?.product_code ?? '-1';
    } else {
        productCode = inputs.searchedPoi?.poi.is_custom ?
            inputs.searchedPoi.poi?.id.toString() ?? '-1' :
            inputs.searchedPoi?.poi.product_code ?? '-1';
    }

    useEffect(() => {
        dispatch({
            type: 'POI_SET_DATES',
            payload: {
                start_date: inputs.startDate?.format('YYYY-MM-DD'),
                end_date: inputs.endDate?.format('YYYY-MM-DD'),
                force_request: true
            }
        });
    }, [inputs]);

    useEffect(() => {
        if (poi) {
            const step = itinerary.find((item) => {
                return item.destination?.id === poi.start_destination?.id;
            });
            dispatch({
                type: 'MATERIAL_POI_DESTINATION',
                payload: step
            });
            setInputs({
                searchedPoi: null,
                startDate: window.moment.utc(poi.start_date),
                endDate: window.moment.utc(poi.end_date)
            });
        }
    }, [poi, itinerary]);

    useEffect(() => {
        if (inputs.searchedPoi) {
            const step = itinerary.find((item) => {
                return item.destination?.id === inputs.searchedPoi?.destination;
            });
            dispatch({
                type: 'MATERIAL_POI_DESTINATION',
                payload: step
            });
        }
    }, [inputs.searchedPoi?.destination, itinerary]);

    switch (context.step) {
        case CartConstructionReplaceProductModalStep.INPUTS: {
            context.onRegisterNextEvent(() => {
                context.onIsFinalStep();
                context.onNextStep();
            });
            return (
                <CartConstructionReplacePoiInputs
                    value={inputs}
                    onChange={setInputs}
                />
            );
        }
    }

    return (
        <PoiCard
            {...rest}
            router={{
                ...rest.router,
                params: {
                    ...rest.params,
                    id: productCode
                }
            } as any}
        />
    );
}
