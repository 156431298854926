/*eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveAltOutlined from '@mui/icons-material/SaveAltOutlined';
import PreviewIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import CircularProgress from "@material-ui/core/CircularProgress";

import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { deleteTripDocuments, getTripDocuments } from "../Functions/TripFunction";

const CrmDemandDocuments = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const selected_demand = useSelector(store => store.crm.selected_demand);
    const user_client = useSelector(store => store.user.user);

    const [documents, setDocuments] = React.useState([]);
    const [progress, setProgress] = React.useState(0);
    const [version, setVersion] = useState(null);

    useEffect(() => {
        if (!documents?.length && selected_demand?.id && selected_demand.data?.length && selected_demand.data[selected_demand.data.length - 1]) {
            const version = selected_demand.data[selected_demand.data.length - 1];
            setVersion(version);
            getTripDocuments(user_client.client, selected_demand.id, version?.id, (res) => {
                setDocuments(res?.data?.results.filter(doc => doc.file_name));
            });
        }
    }, [user_client, selected_demand]);

    //Select file
    const handleButtonClick = () => {
        const fileInput = document.querySelector('#file-selector');
        fileInput.click();
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            let { pass_check, headers } = CheckBeforeRequest();
            headers["Content-Type"] = "multipart/form-data";
            const formData = new FormData();
            const domain = window.location.host.replace("www.", "").split('.');
            formData.append('file_object', file);
            formData.append('bucket_name', 'facilitatrip-documents');
            formData.append('file_name', file.name);
            formData.append('received', true);
            formData.append('type', 'CUSTOM_FILE');
            formData.append('document_id', file.id);
            formData.append("prefix", `${domain[0]}-${domain[2] === 'com' ? 'prod' : 'pre-prod'}`);
            axios({
                method: "POST",
                headers: headers,
                url: `${API_HREF}client/${user_client.client}/trip/${selected_demand.id}/versions/${version?.id}/file/upload_to_aws/`,
                data: formData,
                onUploadProgress: (progressEvent) => {
                    setProgress(progressEvent.loaded / progressEvent.total * 100);
                }
            }).then(function (response) {
                getTripDocuments(user_client.client, selected_demand.id, version?.id, (res) => {
                    setProgress(0);
                    setDocuments(res?.data?.results);
                });
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
            });
        }
    };

    const deleteDoc = (doc_id) => {
        deleteTripDocuments(user_client.client, selected_demand.id, version?.id, doc_id, (res) => {
            getTripDocuments(user_client.client, selected_demand.id, version?.id, (res) => {
                setDocuments(res?.data?.results);
            });
        });
    };

    const [loading, setLoading] = useState(false);
    const download = (file, newTab) => {
        let filename = file.file_name;
        setLoading(true);
        let { pass_check, headers } = CheckBeforeRequest();
        axios({
            method: 'GET',
            url: `${API_HREF}client/${user_client.client}/trip/${selected_demand.id}/versions/${version?.id}/file-contact/download_from_aws/`,
            responseType: 'blob',
            headers,
            params: {
                bucket_name: 'facilitatrip-documents',
                object_name: file.object_name,
                file_name: filename ? filename : 'null'
            }
        }).then((response) => {
            if (response.data) {
                const url = URL.createObjectURL(response.data);
                if (newTab) {
                    window.open(url, '_blank');
                } else {
                    let a = document.createElement("a");
                    a.href = url;
                    a.setAttribute('download', filename ? filename : Date.now().toString());
                    a.dispatchEvent(new MouseEvent('click'));
                }
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => setLoading(false));
    };

    if (selected_demand) {
        return (
            <div>
                {!documents?.length && <p> {t('crm.no_documents')} </p>}
                {
                    documents && documents.length > 0 && documents.map((doc, doc_index) => {
                        return (<div className="contact-docs">
                            <p>{doc.file_name}</p>
                            <div>
                                <IconButton onClick={() => download(doc)}><SaveAltOutlined /></IconButton>
                                <IconButton onClick={() => download(doc, true)}><PreviewIcon /></IconButton>
                                <IconButton onClick={() => deleteDoc(doc.id)}><DeleteIcon /></IconButton>
                            </div>
                        </div>);
                    })
                }
                <Button variant="text" style={{ textTransform: "unset" }} onClick={handleButtonClick} startIcon={<AddCircleOutlineIcon />}>{t('crm.add_document')}</Button>
                <input id="file-selector" type="file" hidden onChange={handleFileInputChange} />
                {
                    (progress > 0 || loading) &&
                    <div className="upload-progress">
                        <Box sx={{ width: '400px' }}>
                            {progress > 0 && <LinearProgress variant="determinate" value={progress} />}
                            {loading && <CircularProgress size={24} />}
                        </Box>
                    </div>
                }
            </div>
        );
    }
    return null;
};
export default CrmDemandDocuments;
