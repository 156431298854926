import {
    ADD_PAYMENT_REMARK,
    CHANGE_PAY_REMARK_DESCRIPTION,
    CHANGE_PAY_REMARK_IDENTIFIER,
    EDIT_PAY_REMARK_DESCRIPTION,
    CHANGE_PAY_REMARK_TYPE,
    CHANGE_PAY_REMARK_VALUE,
    EDIT_PAY_REMARK_VALUE,
    EMPTY_PAYMENT_REMARKS,
    REMOVE_PNR_REMARK,
    SET_FEES_REMARK,
    SET_OPEN_GIR_REMINDER,
    SET_PVT_REMARK,
    SET_RESERVATION_MODE,
    SET_PAY_DATE,
    EDIT_PAY_DATE,
    SET_PAYMENT_DONE,
    EDIT_PAY_REMARK_TYPE,
    SET_CANCEL_EDIT,
    SET_VALIDATE_EDIT,
    SET_ADVANCE_PAYMENT
} from '../ActionTypes/Payment';

export const addPaymentRemark = (remark) => ({
    type: ADD_PAYMENT_REMARK,
    payload: remark
})

export const changePayRemarkDescription = (remark_index, new_description) => ({
    type: CHANGE_PAY_REMARK_DESCRIPTION,
    payload: {
        remark_index: remark_index,
        new_description: new_description
    }
})

export const changePayRemarkIdentifier = (remark_index, new_identifier) => ({
    type: CHANGE_PAY_REMARK_IDENTIFIER,
    payload: {
        remark_index: remark_index,
        new_identifier: new_identifier
    }
})

export const editPayRemarkDescription = (remark_index, new_description) => ({
    type: EDIT_PAY_REMARK_DESCRIPTION,
    payload: {
        remark_index: remark_index,
        new_description: new_description
    }
})

export const changePayRemarkType = (remark_index, new_type) => ({
    type: CHANGE_PAY_REMARK_TYPE,
    payload: {
        remark_index: remark_index,
        new_type: new_type
    }
})

export const editPayRemarkType = (remark_index, new_type) => ({
    type: EDIT_PAY_REMARK_TYPE,
    payload: {
        remark_index: remark_index,
        new_type: new_type
    }
})

export const changePayRemarkValue = (remark_index, value) => ({
    type: CHANGE_PAY_REMARK_VALUE,
    payload: {
        remark_index: remark_index,
        value: value
    }
})

export const editPayRemarkValue = (remark_index, value) => ({
    type: EDIT_PAY_REMARK_VALUE,
    payload: {
        remark_index: remark_index,
        value: value
    }
})

export const emptyPaymentRemarks = () => ({
    type: EMPTY_PAYMENT_REMARKS,
    payload: null
})

export const removePnrRemark = (remark_index) => ({
    type: REMOVE_PNR_REMARK,
    payload: remark_index
})

export const setFeesRemark = (fees) => ({
    type: SET_FEES_REMARK,
    payload: fees
})

export const setOpenGirReminder = (open) => ({
    type: SET_OPEN_GIR_REMINDER,
    payload: open
})

export const SetPvtRemark = (pvt) => ({
    type: SET_PVT_REMARK,
    payload: pvt
})

export const SetReservationMode = (mode) => ({
    type: SET_RESERVATION_MODE,
    payload: mode
})

export const SetPaymentDate = (value, remark_index) => ({
    type: SET_PAY_DATE,
    payload: {
        remark_index: remark_index,
        value: value
    }
})
export const editPaymentDate = (value, remark_index) => ({
    type: EDIT_PAY_DATE,
    payload: {
        remark_index: remark_index,
        value: value
    }
})

export const setPaymentDone = (payment_done) => ({
    type: SET_PAYMENT_DONE,
    payload: payment_done
})

export const setCancelEdit = () => ({
    type: SET_CANCEL_EDIT,
    payload: null
})

export const setValidateEdit = () => ({
    type: SET_VALIDATE_EDIT,
    payload: null
})

export const setAdvancePayment = (advance_payment) => ({
    type: SET_ADVANCE_PAYMENT,
    payload: advance_payment
})