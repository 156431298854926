import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { findGIRContent } from "../../Menu/MaterialTripList/utils/findGIRContent";
import { useShowError } from "../../Utils/showError";
import { useItineraryContentUpdate } from "../../Itinerary/network/itineraryContentUpdate";
import { useAutoloadContents } from "../utils/autoloadContents";
import GetCookie from "../../Common/Functions/GetCookie";
import { setLoading, setSavingTexts, setSyncingTexts } from "../redux/cartConstructionReducer";
import { setItineraryContent, setItineraryContentInputs } from "../../Itinerary/redux/reducer";
import { ItineraryContents } from "../objects/itineraryContents";
import { ItineraryByDay } from "../../Itinerary/objects/itineraryByDay";
import { ItineraryByStep } from "../../Itinerary/objects/itineraryByStep";
import { ItineraryContentStep } from "../../Itinerary/objects/itineraryContentStep";
import { AppState } from "../../../Reducers/Reducers";

export function useItineraryContentSetupSaveRequestsQueue(): void {
    const versionString = GetCookie("trip_id_version");
    const version = versionString ? parseInt(versionString) : -1;
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const saveQueue = useSelector((state: AppState) => state.cartConstruction.textsSaveQueue);
    const tripAllData = useSelector((state: AppState) => state.trip.all_data);
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const showError = useShowError();
    const itineraryContent = useSelector((state: AppState) => {
        return version > 0 ?
            state.itinerarySlice.content[version] :
            undefined;
    });
    const autoloadContents = useAutoloadContents();
    const update = useItineraryContentUpdate({
        onTrigger(source) {
            if (source === 'cart') {
                dispatch(setSyncingTexts(true));
            }
            dispatch(setSavingTexts(true));
        },
        async onSuccess(source, version, steps) {
            if (version) {
                if (source === 'cart') {
                    dispatch(
                        setItineraryContentInputs({
                            version,
                            data: {
                                state: 'success',
                                data: steps
                            }
                        })
                    );
                    dispatch(
                        setItineraryContent({
                            version,
                            data: {
                                state: 'success',
                                data: steps
                            }
                        })
                    );

                    let result: ItineraryContentStep[] = steps.content.map((item) => {
                        return {
                            mode: steps.mode,
                            content: item
                        } as ItineraryContentStep;
                    });

                    const changed = await autoloadContents(
                        steps,
                        {
                            locale,
                            onChangeItemTitle(id, value) {
                                result = result.map((item) => {
                                    if (item.content.id === id) {
                                        const localizationIndex = item.content.localization.findIndex((localization) => {
                                            return localization.locale === locale;
                                        });
                                        return {
                                            mode: item.mode,
                                            content: {
                                                ...item.content,
                                                title: value,
                                                localization: localizationIndex >= 0 ?
                                                    [
                                                        ...item.content.localization.slice(0, localizationIndex),
                                                        {
                                                            ...item.content.localization[localizationIndex],
                                                            title: value
                                                        },
                                                        ...item.content.localization.slice(localizationIndex + 1)
                                                    ] :
                                                    item.content.localization.concat([
                                                        {
                                                            locale,
                                                            long_description: item.content.long_description,
                                                            short_description: item.content.short_description,
                                                            title: value
                                                        }
                                                    ])
                                            }
                                        } as typeof item;
                                    }
                                    return item;
                                });
                            },
                            onChangeItemText(id, texts) {
                                result = result.map((item) => {
                                    if (item.content.id === id) {
                                        const localizationIndex = item.content.localization.findIndex((localization) => {
                                            return localization.locale === locale;
                                        });
                                        return {
                                            mode: item.mode,
                                            content: {
                                                ...item.content,
                                                long_description: texts.join('\n'),
                                                localization: localizationIndex >= 0 ?
                                                    [
                                                        ...item.content.localization.slice(0, localizationIndex),
                                                        {
                                                            ...item.content.localization[localizationIndex],
                                                            long_description: texts.join('\n')
                                                        },
                                                        ...item.content.localization.slice(localizationIndex + 1)
                                                    ] :
                                                    item.content.localization.concat([
                                                        {
                                                            locale,
                                                            title: item.content.title,
                                                            short_description: item.content.short_description,
                                                            long_description: texts.join('\n')
                                                        }
                                                    ])
                                            }
                                        } as typeof item;
                                    }
                                    return item;
                                });
                            },
                            onChangeItemLongText(id, texts) {
                                result = result.map((item) => {
                                    if (item.content.id === id) {
                                        const localizationIndex = item.content.localization.findIndex((localization) => {
                                            return localization.locale === locale;
                                        });
                                        return {
                                            mode: item.mode,
                                            content: {
                                                ...item.content,
                                                short_description: texts.join('\n'),
                                                localization: localizationIndex >= 0 ?
                                                    [
                                                        ...item.content.localization.slice(0, localizationIndex),
                                                        {
                                                            ...item.content.localization[localizationIndex],
                                                            short_description: texts.join('\n')
                                                        },
                                                        ...item.content.localization.slice(localizationIndex + 1)
                                                    ] :
                                                    item.content.localization.concat([
                                                        {
                                                            locale,
                                                            title: item.content.title,
                                                            long_description: item.content.long_description,
                                                            short_description: texts.join('\n')
                                                        }
                                                    ])
                                            }
                                        } as typeof item;
                                    }
                                    return item;
                                });
                            },
                            onChangeItemPictures(id, pictures) {
                                result = result.map((item) => {
                                    if (item.content.id === id) {
                                        return {
                                            mode: item.mode,
                                            content: {
                                                ...item.content,
                                                pictures
                                            }
                                        } as typeof item;
                                    }
                                    return item;
                                });
                            }
                        }
                    );

                    if (changed && saveQueue) {
                        saveQueue.add(
                            result,
                            'cart'
                        );
                        saveQueue.lock();
                    }
                }
                if (tripId) {
                    findGIRContent.clear();
                }
            }
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally(source) {
            if (source === 'cart') {
                dispatch(setSyncingTexts(false));
            }
            dispatch(setSavingTexts(false));
        }
    });

    useEffect(() => {
        if (itineraryContent?.state === 'success') {
            saveQueue?.setInitialData(
                itineraryContent.data.content
            );
        }
    }, [itineraryContent, saveQueue]);

    useEffect(() => {
        saveQueue?.setOnUpdate(async (_, values, from) => {
            if (from === 'cart') {
                dispatch(setLoading(true));
            }
            const result = await update(from ?? 'unknown', version, values);
            if (from === 'cart') {
                dispatch(setLoading(false));
            }
            return result?.content ?? [];
        });
    }, [version, update, saveQueue]);

    useEffect(() => {
        saveQueue?.setOnSuccess((steps) => {
            let result: ItineraryContents = {
                mode: 'by-day',
                content: []
            };
            if ((steps[0] as ItineraryByDay | undefined)?.day) {
                result = {
                    mode: 'by-day',
                    content: steps as ItineraryByDay[]
                };
            } else {
                result = {
                    mode: 'by-step',
                    content: steps as ItineraryByStep[]
                };
            }
            if (
                (
                    tripAllData?.is_by_day_view &&
                    result.mode === 'by-day'
                ) ||
                (
                    tripAllData?.is_by_day_view === false &&
                    result.mode === 'by-step'
                )
            ) {
                dispatch(
                    setItineraryContent({
                        version,
                        data: {
                            state: 'success',
                            data: result
                        }
                    })
                );
            }
        });
    }, [version, tripAllData?.is_by_day_view, saveQueue]);
}
