export default (fares, segment_index) => {
	let options_length = 0;
	let fares_index;
	let categories = [];
	fares.map((fare, fare_index) => {
		if (fare.option_category[0].option_segment[segment_index].option.length > options_length) {
			options_length = fare.option_category[0].option_segment[segment_index].option.length;
			fares_index = fare_index;
		}
	});
	fares[fares_index].option_category[0].option_segment[segment_index].option.map((option) => {
		categories.push({
			type: option.type,
			description: option.description,
			picture: option.image,
			text_marketing: option.text_marketing
		})
	});
	let sort_categories = categories.sort(function (a, b) {
		let keyA = a.type;
		let keyB = b.type;
		// Compare the 2 keys
		if(keyA < keyB) return -1;
		if(keyA > keyB) return 1;
		if(keyA === keyB) {
	        let keyC = a.description;
	        let keyD = b.description;
	        if(keyC > keyD) return 1;
	        if(keyC < keyD) return -1;
	        return 0;
		}
	});
	return sort_categories;
}