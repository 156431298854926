// Dependencies
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';
// Core
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import NavigateBefore from '@material-ui/icons/NavigateBefore';
import QueryBuilderOutlined from '@material-ui/icons/QueryBuilderOutlined';


import { makeStyles, useTheme } from '@material-ui/core/styles';
import HotelInfoMap from '../../ItineraryType/PackagesMaterial/HotelInfoMap.tsx';
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles({
    hr: {
        margin: '10px 0 0 0'
    },
    dateContainer: {
        textAlign: 'center',
        border: '1px solid'
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    gridImage: {
        width: 250
    },
    image: {
        borderRadius: 16
    },
    detailIcon: {
        float: 'left',
        marginRight: 4
    },
    service: {
        float: 'left',
        marginRight: 4
    },
    justifyText: {
        textAlign: "justify"
    },
    resortFees: {
        margin: '15px 0 0 15px'
    },
    alertIcon: {
        alignItems: 'center'
    },
});

const SummaryMoreInfo = ({from}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const car_contract = useSelector(state => state.cart.car_contract);
    const currency_list = useSelector(state => state.base.currency_list);
    const trip_info = useSelector(state => state.summary.trip_main_info);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const [showChevron, setShowChevron] = useState(false);
	const [openMozaic, setOpenMozaic] = useState(false);
	const [openPicture, setOpenPicture] = useState(false);
	const [current_picture, setCurrentPicture] = useState(0);
    const [oneWay, setOneWay] = useState(null);
    const onClose = () => {
        dispatch({type: 'CART_TOGGLE_CONTRACT_DETAIL', payload: null});
    };
    const onMouseEnter = () => {
	    setShowChevron(true);
	};
	const onMouseLeave = () => {
	    setShowChevron(false);
	};
	const openModal = () => {
		if (!fullScreen) {
			togglePictureModal(hotel_picture)
		}
		else {
			toggleMosaicModal(hotel_picture);
		}
	}
	const togglePictureModal = (pictures, index_picture = 0) => {
		setOpenPicture(!openPicture);
		setCurrentPicture(index_picture);
	};
	const toggleMosaicModal = (pictures) => {
		setOpenMozaic(!openMozaic);
    };
    const priceCurrencyConverter = (first_price, first_currency, second_currency) => {
        if (first_currency.iso_code === second_currency.iso_code) {
            return first_price;
        } else {
            return (first_currency.iso_code !== 'USD' ? first_price / first_currency.usd_factor : first_price) * second_currency.usd_factor;
        }
    };


    useEffect(() => {
        if (car_contract !== null && car_contract.onsite_fare !== undefined && !!trip_info.currency) {
            car_contract.onsite_fare.map((onsite_fare, onsite_fare_index) => {
                if (onsite_fare.description === "One way fares" && parseFloat(onsite_fare.price) !== 0) {
                    const currency_one_way = currency_list.find((el) => {
                        return el.id === onsite_fare.currency;
                    });
                    let one_way = {
                        cost: parseFloat(onsite_fare.price),
                        cost_currency: currency_one_way,
                        converted_cost: currency_one_way.iso_code !== trip_info.currency.iso_code ? priceCurrencyConverter(parseFloat(onsite_fare.price), currency_one_way, trip_info.currency) : parseFloat(onsite_fare.price),
                        converted_currency: trip_info.currency
                    }
                    one_way.cost = one_way.cost.toLocaleString(i18n.language, {minimumFractionDigits: 2, maximumFractionDigits: 2});
                    one_way.converted_cost = one_way.converted_cost.toLocaleString(i18n.language, {minimumFractionDigits: 2, maximumFractionDigits: 2});
                    setOneWay(one_way);
                }
            });
        }
    }, [car_contract, trip_info]);
    if (car_contract !== null) {
        let important_notice = null;
        let product_name = "";
        let product_picture = "";

        let situation = null;
        let content = null;

        if (car_contract.product_type === 2 || (car_contract.product_type === 4 && !car_contract.is_custom)) {
            product_name = car_contract.name;
            important_notice = car_contract.important_notice !== null && car_contract.important_notice !== "" ? car_contract.important_notice.split("\n") : null;
            if (car_contract.product_type === 2) {
                product_picture = car_contract.vehicle_picture_url;
            }
            else {
                product_picture = car_contract.picture_url;
            }
        }
        if ((car_contract.product_type === 4 && car_contract.is_custom) || car_contract.product_type === 11) {
            important_notice = car_contract.custom_product.long_description !== null ? car_contract.custom_product.long_description : car_contract.custom_product.short_description;
            product_name = car_contract.custom_product.title;
            if (car_contract.custom_product.cover_picture !== null && car_contract.custom_product.cover_picture.length !== 0) {
                product_picture = car_contract.custom_product.cover_picture.url;
            }
            else if (car_contract.custom_product.pictures !== null && car_contract.custom_product.pictures.length !== 0) {
                product_picture = car_contract.custom_product.pictures[0].url
            }
        }
        if (car_contract.product_type === 0 ) {
            let default_hotel = car_contract.hotel[0];
            for (let i = 0; i < car_contract.hotel.length; i++) {
                if (!car_contract.hotel[i].is_giata) {
                    default_hotel = hotel.hotel[i];
                }
            }
            if (default_hotel.custom_pictures !== null && default_hotel.custom_pictures.length !== 0) {
                product_picture = default_hotel.custom_pictures[0].thumbnail_little !== null ? default_hotel.custom_pictures[0].thumbnail_little : default_hotel.custom_pictures[0].url;
            }
            else if (default_hotel.pictures !== null && default_hotel.pictures.length !== 0) {
                product_picture = default_hotel.pictures[0].thumbnail_little !== null ? default_hotel.pictures[0].thumbnail_little : default_hotel.pictures[0].url;
            }
            else {
                product_picture = "/Img/accommodation_default.jpg";
            }
            if (default_hotel.situation !== null && default_hotel.situation !== "") {
                situation = default_hotel.situation;
                content = situation;
            } else {
                for (let i = 0; i < default_hotel.content.length; i++) {
                    if (default_hotel.content[i].section === 100) {
                        situation = default_hotel.content[i];
                        content = situation.content;
                    }
                }
                if (situation === null) {
                    for (let i = 0; i < default_hotel.content.length; i++) {
                        if (default_hotel.content[i].section === 390 || default_hotel.content[i].section === 110) {
                            situation = default_hotel.content[i];
                            content = situation.content;
                        }
                    }
                }
            }
        }
        return (
            <Dialog fullScreen={ fullScreen } PaperProps={{style: { borderRadius: fullScreen ? 0 : 16 } }} open={ true } fullWidth maxWidth={ 'md' } onClose={ onClose } disableEscapeKeyDown>
                <DialogTitle onClose={ onClose }>
                    <Grid container justify={ 'space-between' } alignItems={ 'center' }>
                        {
                            fullScreen ? (
                                <Grid item>
                                    <IconButton edge={ 'start' } onClick={ onClose }><NavigateBefore/></IconButton> { t('transfers.more_information') }
                                </Grid>
                            ) : (
                                <Fragment>
                                    <Grid item>
                                        { t('transfers.more_information') } :
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={ onClose }><Close/></IconButton>
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </Grid>
                    <hr className={ classes.hr }/>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction={"column"} spacing={4}>
                        <Grid item>
                           <Typography variant={"h4"} className={classes.genericText} style={{textAlign: "center"}}>{product_name}</Typography>
                        </Grid>
                        {
                            from !== "tripSummary" && product_picture !== null && product_picture !== "" && (
                                <Grid item className={ classes.gridImage }>
                                    <img className={ classes.image } src={ product_picture } alt={ 'product-picture' } width={ 250 }/>
                                </Grid>
                            )
                        }
                        {
                            (car_contract.product_type === 4 && !car_contract.is_custom) && (
                                <Fragment>
                                {
                                    car_contract.customer_waiting_time !== undefined && car_contract.customer_waiting_time !== null &&
                                    <Grid item>
                                        <Grid container alignItems='center' >
                                            <Grid item> <QueryBuilderOutlined className={ classes.detailIcon } /> </Grid>
                                            <Grid item>
                                                <Typography variant='body2'>{ car_contract.customer_waiting_time === '0 minutes' ? t('transfers.no_waiting_time') : t('transfers.waiting_time_customer') + car_contract.customer_waiting_time }</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    car_contract.supplier_waiting_time_domestic !== undefined && car_contract.supplier_waiting_time_domestic !== null && (
                                        <Grid item>
                                            <Grid container alignItems='center'>
                                                <Grid item> <QueryBuilderOutlined className={ classes.detailIcon }/> </Grid>
                                                <Grid item>
                                                    <Typography variant='body2'>
                                                        {
                                                            t('transfers.supplier_max_waiting_time_short') + car_contract.supplier_waiting_time_domestic + ' (' + t('transfers.domestic') + ')'
                                                        }
                                                    </Typography>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                </Fragment>
                            )
                        }
                        {
                            (car_contract.product_type === 2 || (car_contract.product_type === 4 && !car_contract.is_custom)) && car_contract.included_service !== undefined && (
                                <Grid item>
                                {
                                    car_contract.included_service.map((service, index_service) => {
                                        if (service.included) {
                                            return <div key={ `car-service-${index_service}` }><CheckCircleOutline className={ classes.service }/><Typography>{ service.title }</Typography></div>;
                                        }
                                    })
                                }
                                </Grid>
                            )
                        }
                        {
                            oneWay !== null &&
                            <Grid item>
                                <Typography className={classes.genericText}><strong>{ t('cars.one_way') } :</strong></Typography>
                                <Typography className={classes.genericText} align={ 'justify' }>{ `${t('cart-material.resort-fees-1')} ${oneWay.cost} ${oneWay.cost_currency !== null ? oneWay.cost_currency.symbol : ''} ${oneWay.cost !== oneWay.converted_cost ? `(${ t('cart-material.resort-fees-2') } ${ oneWay.converted_cost }${ oneWay.converted_currency !== null ? oneWay.converted_currency.symbol : '' })` : ''} ${t('cart-material.one-way-fees-3')}` }</Typography>
                            </Grid>
                        }
                        {
                            (car_contract.product_type === 2 || (car_contract.product_type === 4 && !car_contract.is_custom)) && important_notice !== null &&
                            <Grid item>
                                <br/>
                                <Typography className={classes.genericText}><strong>{ t('cart-material.important') } :</strong></Typography>
                                {
                                    important_notice.map((notice, index_notice) => (
                                        <Fragment key={ `car-notice-${index_notice}` }>
                                            <Typography className={classes.genericText} align={ 'justify' }>{ notice }</Typography>
                                            <br/>
                                        </Fragment>
                                    ))
                                }
                            </Grid>
                        }
                        {
                            ((car_contract.product_type === 4 && car_contract.is_custom) || car_contract.product_type === 11) && (
                                <Grid item>
                                    <Typography className={classes.genericText} align={ 'justify' } dangerouslySetInnerHTML={{__html: important_notice }}/>
                                </Grid>
                            )
                        }
                        {
                            car_contract.product_type === 0 && (
                                <Grid item container direction={"column"} spacing={4} className={classes.genericText}>
                                    <Grid item>
                                        <div className={`${classes.genericText} ${classes.justifyText}`} dangerouslySetInnerHTML={ {__html: content } } />
                                    </Grid>
                                    <Grid item>
                                    {
                                        (car_contract !== null && car_contract.hotel[0] !== null && car_contract.hotel[0].building.length > 0) && (
                                            <Fragment>
                                                <h3>{ t("accommodation.info") } :</h3>
                                                {
                                                    car_contract.hotel[0].building.map((building, index) => {
                                                        console.log('building:', building);
                                                        if (building.value_int !== null || building.value_bool !== null) {
                                                            return (
                                                                <Fragment key={ index }>
                                                                {
                                                                    index > 0 && (
                                                                        <Divider/>
                                                                    )
                                                                }
                                                                    <br/>
                                                                    <Typography variant={ "body2" }>{ t("accommodation.facts." + building.codename) + (building.value_int !== null ? " : " + building.value_int : "") }</Typography>
                                                                    <br/>
                                                                </Fragment>
                                                            );
                                                        }
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (car_contract !== null && car_contract.hotel[0] !== null && car_contract.hotel[0].facility.length > 0) && (
                                            <Fragment>
                                                <h3>{ t("accommodation.equipment_title") } :</h3>
                                                {
                                                    car_contract.hotel[0].facility.map((facility, index) => {
                                                        return (
                                                            <Fragment key={ index }>
                                                            {
                                                                index > 0 && (
                                                                    <Divider/>
                                                                )
                                                            }
                                                                <br/>
                                                                <Typography variant={ "body2" }>{ t('accommodation.facts.' + facility.codename) }</Typography>
                                                                <br/>
                                                            </Fragment>
                                                        );
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (car_contract !== null && car_contract.hotel[0] !== null && car_contract.hotel[0].room.length > 0) && (
                                            <Fragment>
                                                <h3>{ t("accommodation.rooms") } :</h3>
                                                {
                                                    car_contract.hotel[0].room.map((room, index) => {
                                                        return (
                                                            <Fragment key={ index }>
                                                            {
                                                                index > 0 && (
                                                                    <Divider/>
                                                                )
                                                            }
                                                                <br/>
                                                                <Typography variant={ "body2" }>{ t('accommodation.facts.' + room.codename) }</Typography>
                                                                <br/>
                                                            </Fragment>
                                                        );
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (car_contract !== null && car_contract.hotel[0] !== null && car_contract.hotel[0].meals.length > 0) && (
                                            <Fragment>
                                                <h3>{ t("accommodation.meal") } :</h3>
                                                {
                                                    car_contract.hotel[0].meals.map((meals, index) => {
                                                        return (
                                                            <Fragment key={ index }>
                                                            {
                                                                index > 0 && (
                                                                    <Divider/>
                                                                )
                                                            }
                                                                <br/>
                                                                <Typography variant={ "body2" }>{ t('accommodation.facts.' + meals.codename) }</Typography>
                                                                <br/>
                                                            </Fragment>
                                                        );
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (car_contract !== null && car_contract.hotel[0] !== null && car_contract.hotel[0].sports.length > 0) && (
                                            <Fragment>
                                                <h3>{ t("accommodation.sport") } :</h3>
                                                {
                                                    car_contract.hotel[0].sports.map((sports, index) => {
                                                        return (
                                                            <Fragment key={ index }>
                                                            {
                                                                index > 0 && (
                                                                    <Divider/>
                                                                )
                                                            }
                                                                <br/>
                                                                <Typography variant={ "body2" }>{ t('accommodation.facts.' + sports.codename) }</Typography>
                                                                <br/>
                                                            </Fragment>
                                                        );
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (car_contract !== null && car_contract.hotel[0] !== null && car_contract.hotel[0].dist.length > 0) && (
                                            <Fragment>
                                                <h3>{ t("accommodation.distance") } :</h3>
                                                {
                                                    car_contract.hotel[0].dist.map((dist, index) => {
                                                        console.log('dist:', dist);
                                                        if (dist.value_int > 0) {
                                                            return (
                                                                <Fragment key={ index }>
                                                                {
                                                                    index > 0 && (
                                                                        <Divider/>
                                                                    )
                                                                }
                                                                    <br/>
                                                                    <Typography variant={ "body2" }>{ dist.value_int }</Typography>
                                                                    <br/>
                                                                </Fragment>
                                                            );
                                                        }
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (car_contract !== null && car_contract.hotel[0] !== null && car_contract.hotel[0].payment.length > 0) && (
                                            <Fragment>
                                                <h3>{ t("accommodation.payment") } :</h3>
                                                {
                                                    car_contract.hotel[0].payment.map((payment, index) => {
                                                        return (
                                                            <Fragment key={ index }>
                                                                {
                                                                    index > 0 && (
                                                                        <Divider/>
                                                                    )
                                                                }
                                                                <br/>
                                                                <Typography variant={ "body2" }>{ t('accommodation.facts.' + payment.codename) }</Typography>
                                                                <br/>
                                                            </Fragment>
                                                        );
                                                    })
                                                }
                                            </Fragment>
                                        )
                                    }
                                    </Grid>
                                    <Grid item>
                                        <HotelInfoMap info_hotel={car_contract}/>
                                    </Grid>
                                </Grid>

                            )
                        }
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    } else {
        return null;
    }
};

export default SummaryMoreInfo;