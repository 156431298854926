//---- Dependencies ----//
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import Notify from "../../../Common/Notify";
import { SetTripInfo } from "../../../../Actions/Summary";

//---- Actions ----//

export default (traveler, trip, dispatch) => {
	let { pass_check, headers } = CheckBeforeRequest();
	let request_trip = {
		contact_traveler: traveler.id
	};
	if (pass_check) {
		$.ajax({
	        method: "PATCH",
	        headers: headers,
	        url: `${API_HREF}client/${window.id_owner}/trip/${trip.id}/versions/${trip.current_version}/?token=${trip.token}`,
	        data: JSON.stringify(request_trip),
	        success: function (data) {
	        	dispatch(SetTripInfo(data));

	        },
	        error: function (error) {
	            console.log("err", error);          	
	        }
	    });
    }
};
