import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import RenderProvider from './RenderProvider';
import RenderMainMatrix from './RenderMainMatrix';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    relativePos: {
        position: 'relative'
    },
    marginTop8: {
        marginTop: 8
    }
}));

const RenderMatrix = ({setProviderID, setCategoryID}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const cars_results = useSelector((store) => {
        return store.cars_search.cars_results;
    });
    const all_cars = useSelector((store) => {
        return store.cars_search.all_cars;
    });
    const provider_list = useSelector((store) => {
        return store.trip.providers;
    });
    const user = useSelector((store) => {
        return store.user.user;
    });
    const cars_categories = useSelector((store) => {
        return store.cars_search.cars_categories;
    });
    const language = useSelector((store) => {
        return store.header.tmp_language;
    });
    const currency = useSelector((store) => {
        return store.trip.currency;
    });
    return (
        <Grid container item xs={12} className={ classes.marginTop8 }>
            <Grid container item sm={2}>
                <RenderProvider cars_results={cars_results} all_cars={all_cars} provider_list={provider_list} user={user} setProviderID={setProviderID} setCategoryID={setCategoryID} />
            </Grid>
            <Grid container item sm={10} className={classes.relativePos}>
                {
                    cars_results.length !== 0 && cars_categories !== 0 && (
                        <RenderMainMatrix cars_results={cars_results} cars_categories={cars_categories} user={user} language={language} currency={currency} all_cars={all_cars} setProviderID={setProviderID} setCategoryID={setCategoryID}/>
                    )
                }
            </Grid>
            <Grid item xs={12}>
                <hr/>
            </Grid>
        </Grid>
    )
};
export default React.memo(RenderMatrix);