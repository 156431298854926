import { getPlaneStartDate } from "./getPlaneStartDate";
import { ItineraryInput } from "../objects/itineraryState";
import { Transport } from "../objects/transport";

export function filterFlightAlternatives(departureStep: ItineraryInput, transports: Transport[]): Transport[] {
    return transports.map((transport) => {
        if (transport.types.includes('plane')) {
            return {
                ...transport,
                alternatives: transport.alternatives.map((item) => {
                    const departure = getPlaneStartDate(item, departureStep.end_date);
                    return {
                        ...item,
                        disabled: window.moment.utc(departureStep.start_date).isAfter(departure) ||
                            !item.operatingDays.includes(
                                parseInt(departure.format('d'))
                            )
                    };
                })
            };
        }
        return transport;
    });
}
