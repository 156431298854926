import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";

import { AddBaggageOption } from "../../../Actions/FlightSearch";

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize9: {
        fontSize: 9
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize16: {
        fontSize: 16
    },
    uppercase: {
        textTransform: "uppercase"
    },
    textCenter: {
        textAlign: "center"
    },
    spacer: {
        padding: "8px 0px"
    },
}))

const SelectLuggage = ({ luggageOptions, tempPaidOptions, setTempPaidOptions, key_segment, outbound_index, traveler, traveler_index, optionsLevels, setOptionsLevels, luggage_index, checkColumns }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const language = useSelector(store => store.header.language);
    const trip_currency = useSelector(store => store.trip.currency);
    const currency_list = useSelector(store => store.base.currency_list);
    const selected_flight = useSelector(store => store.flight_search.selected_flight);

    const ChooseLuggageLabel = useRef(null);
    const ChooseLuggageLabelWidth = ChooseLuggageLabel.current ? ChooseLuggageLabel.current.clientWidth : 150;

    const AddOption = (option_key, outbound_index, traveler_id, paid_option_index) => {
        let temp_paid_options = tempPaidOptions.slice();
        let chosen_option = luggageOptions.find(item => item.key_optional_service === option_key);
    
        temp_paid_options[outbound_index][key_segment][traveler_id][paid_option_index] = option_key;
        if (paid_option_index !== 2){
            if (option_key === t("flight_search.no_luggage")){
                // remove column
                temp_paid_options[outbound_index][key_segment][traveler_id].splice(paid_option_index + 1, 2-paid_option_index);
            }else if (temp_paid_options[outbound_index][key_segment][traveler_id][paid_option_index + 1] === undefined){
                // add column
                temp_paid_options[outbound_index][key_segment][traveler_id].push(t("flight_search.no_luggage"));
            }
        }

        if (option_key !== t("flight_search.no_luggage") && chosen_option !== undefined ){
            let temp_option_level = optionsLevels.slice();
            if (chosen_option.baggage_level_restriction !== null){
                temp_option_level[outbound_index][key_segment][traveler_id] = chosen_option.baggage_level_restriction;
                if (chosen_option.option_on_group){
                    Object.keys(temp_paid_options[outbound_index]).map(key => {
                        if (key !== key_segment && chosen_option.key_segments.includes(key)){
                            temp_option_level[outbound_index][key][traveler_id] = chosen_option.baggage_level_restriction;
                        }
                    })
                }
                if (chosen_option.option_on_flight){
                    temp_option_level.map((level_by_outbound, parsed_outbound_index) => {
                        Object.keys(level_by_outbound).map(key => {
                            if (key !== key_segment && chosen_option.key_segments.includes(key)){
                                level_by_outbound[key][traveler_id] = chosen_option.baggage_level_restriction;
                            }
                        })
                    })
                }
                setOptionsLevels(temp_option_level);
            }
            
            // process option_on_group
            if (chosen_option.option_on_group){
                enqueueSnackbar(t("flight_search.option_on_group"), { variant: "success" });
                Object.keys(temp_paid_options[outbound_index]).map(key => {
                    if (key !== key_segment && chosen_option.key_segments.includes(key)){
                        let last_index = temp_paid_options[outbound_index][key][traveler_id].length - 1;
                        temp_paid_options[outbound_index][key][traveler_id][last_index] = option_key;
                        if (last_index < 2){
                            temp_paid_options[outbound_index][key][traveler_id].push(t("flight_search.no_luggage"));
                        }
                        dispatch(AddBaggageOption(option_key, luggageOptions, selected_flight, outbound_index, key, traveler_id, last_index));
                    }
                })
            }
            if (chosen_option.option_on_flight){
                enqueueSnackbar(t("flight_search.option_on_flight"), { variant: "success" });
                temp_paid_options.map((option_by_outbound, parsed_outbound_index) => {
                    Object.keys(option_by_outbound).map(key => {
                        if (key !== key_segment && chosen_option.key_segments.includes(key)){
                            let last_index = option_by_outbound[key][traveler_id].length - 1;
                            option_by_outbound[key][traveler_id][last_index] = option_key;
                            if (last_index < 2){
                                option_by_outbound[key][traveler_id].push(t("flight_search.no_luggage"));
                            }
                            dispatch(AddBaggageOption(option_key, luggageOptions, selected_flight, parsed_outbound_index, key, traveler_id, last_index));
                        }
                    })
                })
            }
        }

        checkColumns(temp_paid_options);
        setTempPaidOptions(temp_paid_options);
        dispatch(AddBaggageOption(option_key, luggageOptions, selected_flight, outbound_index, key_segment, traveler_id, paid_option_index));
    }
    return (
        <FormControl variant="outlined" style={isHandHeld ? { width: "100%" } : { width: "100%", maxWidth: 300 }} size="small">
            <InputLabel id={`traveler-${traveler_index}-luggage-${luggage_index}`} ref={ChooseLuggageLabel} className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14}`}>
                { t("flight_search.add_baggage") }
            </InputLabel>
            <Select
                labelId={`traveler-${traveler_index}-luggage-${luggage_index}`}
                id={`select-traveler-${traveler_index}-luggage-${luggage_index}`}
                value={tempPaidOptions[outbound_index][key_segment][traveler.id][luggage_index]}
                MenuProps={{ disableScrollLock: true, variant: "menu" }}
                onChange={(event) => {AddOption(event.target.value, outbound_index, traveler.id, luggage_index)}}
                input={
                    <OutlinedInput
                        id={`input-traveler-${traveler_index}-luggage-${luggage_index}`}
                        labelWidth={ChooseLuggageLabelWidth}
                        autoComplete="off"
                        className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}
                    />
                }
                >
                    <MenuItem value={t("flight_search.no_luggage")} className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                        { t("flight_search.no_luggage") + " : " + new Intl.NumberFormat(language, {style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(0) }
                    </MenuItem>
                    {
                        luggageOptions.map((option, option_index) => {
                            if((option.key_segments.includes(key_segment)
                                    || option.key_segments.includes(key_segment))
                                && (option.baggage_level_restriction === null
                                    || (luggage_index === 0 && option.baggage_level_restriction === 1)
                                    || (luggage_index !== 0 && option.baggage_level_restriction === optionsLevels[outbound_index][key_segment][traveler.id] + 1)
                                    || (luggage_index !== 0 && option.key_optional_service === tempPaidOptions[outbound_index][key_segment][traveler.id][luggage_index])
                                    )
                            ){
                                if (option.baggage_weight === null) {
                                    return(
                                        <MenuItem key={option_index} value={option.key_optional_service} className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9}`}>
                                            { (option.baggage_quantity ? option.baggage_quantity + ' x ' : '') + option.description + " : " + new Intl.NumberFormat(language, {style: 'currency', currency: currency_list.find(item => item.id === option.prices[0].selling_currency).iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(option.prices[0].selling_price) }
                                        </MenuItem>
                                    )
                                }else{
                                    if (!option.baggage_is_both) {
                                        return (
                                            <MenuItem key={option_index} value={option.key_optional_service} className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9}`}>
                                                {option.baggage_quantity + " " + (parseInt(option.baggage_quantity) > 1 ? t("global.luggages") : t("global.luggage")) + " " + (option.baggage_is_carry_on ? t("flight_search.carry_on") : t("flight_search.check")) + " (" + option.baggage_quantity + "X" + option.baggage_weight + "kg) : " + new Intl.NumberFormat(language, {style: 'currency', currency: currency_list.find(item => item.id === option.prices[0].selling_currency).iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseInt(option.prices[0].selling_price)) }
                                            </MenuItem>
                                        )
                                    }
                                    else {
                                        return (
                                            <MenuItem key={option_index} value={option.key_optional_service} className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9}`}>
                                                {option.baggage_quantity + " " + (parseInt(option.baggage_quantity) > 1 ? t("global.luggages") : t("global.luggage")) + " " + t("flight_search.check") + " (" + option.baggage_quantity + "X" + option.baggage_weight + "kg) + " + option.baggage_quantity + " " + t("flight_search.carry_on") + " : " + new Intl.NumberFormat(language, {style: 'currency', currency: currency_list.find(item => item.id === option.prices[0].selling_currency).iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseInt(option.prices[0].selling_price)) }
                                            </MenuItem>
                                        )
                                    }
                                }
                            }
                        })
                    }
            </Select>
        </FormControl>
    )
}

export default React.memo(SelectLuggage);