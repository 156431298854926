import React from "react"

import Menu from "@material-ui/core/Menu";

import OutboundDetails from "../FlightSearch/OutboundDetails";

const OutboundDetailsMenu = (({ searched_group, outbound_flight, outbound_index, outbounds_length, showDetails, setShowDetails }) => {
    return(
        <Menu 
            id="detailed_outbound"
            anchorEl={showDetails}
            keepMounted
            open={Boolean(showDetails)}
            onClose={() => setShowDetails(false)}
            disableScrollLock={true}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                <OutboundDetails setShowDetails={setShowDetails} outbound_flight={outbound_flight} outbound_index={outbound_index} travelers={searched_group.travelers} max_index={outbounds_length-1} small={true} />
        </Menu>
    )
})

export default React.memo(OutboundDetailsMenu);