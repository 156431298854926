import React from "react";
import { Block } from "../objects/block";
import { BlockHtmlAttribute } from "../objects/blockHtmlAttributes";
import { ComponentWithBlockFeatures, withBlockFeatures } from "./block-features";

type Props<T> = {
    id: number,
    options: T
}

type OptionsComponentProps<T> = {
    id: number,
    options: T
}

export type RegisterBlockOptions<Options, WithBlockFeatures extends boolean = false> = {
    type: string,
    icon: JSX.Element,
    label: string,
    htmlAttributes?: BlockHtmlAttribute[],
    styles?: string[],
    component: WithBlockFeatures extends true ?
        ComponentWithBlockFeatures<Options> :
        React.FunctionComponent<Props<Options>> |
        React.ComponentClass<Props<Options>>,
    optionsComponent:
        React.FunctionComponent<OptionsComponentProps<Options>> |
        React.ComponentClass<OptionsComponentProps<Options>>,
    factory: () => Block<Options>,
}

export type RegisteredBlock<
    Options,
    WithBlockFeatures extends boolean = false
> = Omit<RegisterBlockOptions<Options, WithBlockFeatures>, "component"> & {
    component: ComponentWithBlockFeatures<Options>
}

export const RegisteredBlocks: RegisteredBlock<any>[] = [];

export function registerBlock<T>(options: RegisterBlockOptions<T>): void {
    RegisteredBlocks.push({
        ...options,
        component: withBlockFeatures(options)
    });
}
