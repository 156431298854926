export default (products_to_book, providers) => {
    const need_transport_info = ["New World", "Hotel Beds"];
    let pass_check = true;

    products_to_book.map((product) => {
        if (product.product_type === 4 && product.is_displayed === true && (product.booking_status === null || (product.booking_status !== null && product.booking_status.status_booking === "ERROR"))){
            providers.map((provider) => {
                if (provider.provider.id === product.provider && need_transport_info.includes(provider.provider.name)){
                    if (product.direction === "" || product.transport_type === "" || product.direction === null || product.transport_type === null){
                        pass_check = false;
                    }
                    // code mandatory for flight & trains
                    if (product.transport_type !== "CRUISE" && (product.transport_code === "" || product.transport_code === null)){
                        pass_check = false;
                    }
                    // company name mandatory for boat only
                    if (product.transport_type === "CRUISE" && (product.transport_company === "" || product.transport_company === null)){
                        pass_check = false;
                    }
                }
            })
        }
    })
    return pass_check
}