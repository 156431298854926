import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, ButtonProps } from "@mui/material";
import { ControlPoint } from "@mui/icons-material";

export function ItineraryTransportItemInsertStepButton(
    props: ButtonProps
): JSX.Element {
    const { t } = useTranslation();
    return (
        <Button
            {...props}
            startIcon={<ControlPoint fontSize="inherit" color="inherit" />}
            sx={{
                "padding": '2px 0px',
                "lineHeight": 1,
                "fontSize": 10,
                "borderRadius": '10px',
                "alignSelf": 'center',
                "marginBottom": 0,
                "color": "#000",
                "opacity": 0.3,
                '.mui-jss-MuiSvgIcon-root': {
                    fontSize: '15px !important'
                },
                '.mui-jss-MuiButton-startIcon': {
                    marginRight: 1
                },
                ...props.sx
            }}
        >
            <Box
                sx={{ paddingTop: '2px', textTransform: 'none' }}
                component="span"
            >
                {t<string>('itinerary.insert-step')}
            </Box>
        </Button>
    );
}
