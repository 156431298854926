import React from "react";
import { useTranslation } from "react-i18next";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';

export function TripListInvoiceCancelModal(props) {
    const { t } = useTranslation();

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>
                {t('menu.invoice.cancel-modal-title')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('menu.invoice.cancel-modal-content')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    {t('menu.invoice.cancel')}
                </Button>
                <Button onClick={props.onConfirm} color="primary" autoFocus>
                    {t('menu.invoice.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
