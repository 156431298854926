//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import Wifi from '@material-ui/icons/Wifi';
import Pool from '@material-ui/icons/Pool';
import Close from '@material-ui/icons/Close';
import AcUnit from '@material-ui/icons/AcUnit';
import LocalParking from '@material-ui/icons/LocalParking';
import HotelIcon from '@material-ui/icons/Hotel';
import RoomIcon from '@material-ui/icons/Room';
import InfoIcon from '@material-ui/icons/Info';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import SettingsIcon from '@material-ui/icons/Settings';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import StarIcon from '@material-ui/icons/Star';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import DateRange from '@material-ui/icons/DateRange';

import { TogleInfoHotelAndSetStep } from '../../../Actions/Summary';
import MosaicPicture from '../../Accommodation/FunctionalComponent/MosaicPicture';
import Picture from '../../Accommodation/FunctionalComponent/Picture';
import Slider from '../../Accommodation/FunctionalComponent/Slider';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 30
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 19px)',
        margin: '0 4px',
        left: "-16px"
    },
	containerMargin: {
        marginLeft: 5
    },
	includedAmenities: {
        verticalAlign: '-3px',
		fontSize: 16,
        marginRight: 4
    },
	bold: {
		fontWeight: "bold"
	},
	italic: {
		fontStyle: "italic"
	},
	font14: {
		fontSize: 14
	},
	lowerCase: {
        textTransform: "capitalize"
	},
	calendar: {
        float: 'left',
        marginRight: 4
    },
	pictureContainer: {
	    overflow: "hidden",
	    height: 200,
	    // width: 300,
	    position: "relative",
	    cursor: "pointer"
    },
}));

const SummaryHotelCardManual = ({hotel, mainHotel, type, selectedProducts, setSelectedProducts, step_index, from, otherProduct}) => {
    const dispatch = useDispatch();
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const trip_info = useSelector(state => state.summary.trip_info);
    const currency_list = useSelector(state => state.base.currency_list);

	const [travelersCount, setTravelersCount] = useState(0);
	const [showChevron, setShowChevron] = useState(false);
	const [openMozaic, setOpenMozaic] = useState(false);
	const [openPicture, setOpenPicture] = useState(false);
	const [current_picture, setCurrentPicture] = useState(0);

	useEffect(() => {
		let tmp_count = 0;
		hotel.rooms.map((room) => {
			tmp_count += room.group_passenger.travelers.length;
		});
		setTravelersCount(tmp_count);
	}, []);

    let situation = null;
	let content = null;
	let situation_check = null;
	let distances = null;
	let equipements = null;
	let rooms = null;
	let check_in_out = null;
	let restauration = null;
	let other = null;
	let sports_animation = null;
	let to_know = null;
	let stars = [];
	let nb_day = 0;
	let address = "";
	let default_hotel = null;
	let is_custom = false;
	let large_description = false;
	let hotel_picture = ["/Img/accommodation_default.jpg"];

	if (hotel !== null) {
		for (let i = 0; i < hotel.stars; i++) {
			stars.push(<StarIcon key={i} className={classes.genericText} style={{verticalAlign: "-3px", fontSize: 16}}/>)
		}
		if (hotel.picture !== undefined && hotel.picture !== null) {
			hotel_picture = [hotel.picture];
		}
	}

    const handleChange = () => {
		let tmp_selectedProducts = Object.assign({}, selectedProducts);
        let tmp_accommodation = tmp_selectedProducts.hotels.slice();
        if (tmp_accommodation[step_index].includes(hotel.id)) {
            tmp_accommodation[step_index] = tmp_accommodation[step_index].filter((el) => {
                return el !== hotel.id
            });
        }
        else {
            tmp_accommodation[step_index].push(hotel.id);
        }
        tmp_selectedProducts.hotels = tmp_accommodation;
        setSelectedProducts(tmp_selectedProducts);
    }
	const variantIsChecked = (product) => {
        return selectedProducts.hotels[step_index].includes(product.id);
    }
	const getClientPrice = (prices) => {
		let current_price = prices.find((el) => {
			return !el.is_commissioned && !el.is_network && !el.is_surcom && !el.master_price;
		});
		if (current_price === undefined) {
			current_price = prices.find((el) => {
				return !el.is_commissioned && !el.is_network && !el.is_surcom && el.master_price;
			});
		}
		return current_price;
	}
	const showContractDetail = () => {
        dispatch({type: 'CART_TOGGLE_CONTRACT_DETAIL', payload: hotel});
    };
	const onMouseEnter = () => {
	    setShowChevron(true);
	};
	const onMouseLeave = () => {
	    setShowChevron(false);
	};
	const openModal = () => {
		if (!fullScreen) {
			togglePictureModal(hotel_picture)
		}
		else {
			toggleMosaicModal(hotel_picture);
		}
	}
	const togglePictureModal = (pictures, index_picture = 0) => {
		setOpenPicture(!openPicture);
		setCurrentPicture(index_picture);
	};
	const toggleMosaicModal = (pictures) => {
		setOpenMozaic(!openMozaic);
    };
    let price = parseFloat(getClientPrice(hotel.prices).selling_price);
    let selling_currency = currency_list.find((currency) => currency.id === getClientPrice(hotel.prices).selling_currency);
    return (
        <Grid container direction={"row"} spacing={2} style={{marginBottom: 10, background: from === "tripSummary" ? (type === "option" ? "#EEEEEE" : "white") : "white"}}>
			{
				from !== "tripSummary" && otherProduct === undefined && (trip_info.status === "TO_UPDATED" || trip_info.status === "ONGOING" || trip_info.status === "VALIDATED" || trip_info.status === "TOBOOK_VERDIE") && (
					<Grid item>
						<div className={ classes.checkboxContainer }>
							<Checkbox
								className={ classes.checkbox }
								edge={ 'start' }
								checked={ variantIsChecked(hotel) }
								tabIndex={ -1 }
								disableRipple 
								onChange={ handleChange }
								icon={<CircleUnchecked />}
								checkedIcon={<RadioButtonCheckedIcon  />}
							/>
						</div>
					</Grid>
				)
			}
            <Grid item xs container direction={"column"} alignItems={"flex-start"} className={classes.font14}>
				<Grid item>
					<HotelIcon className={classes.genericText} style={{verticalAlign: "sub", marginRight: 10}}/>
					<span className={classes.genericText}>{ hotel.name } </span>
					<span style={{marginLeft: 10, marginRight: 10}}> { stars } </span>
					<span>{ travelersCount + " " + (travelersCount > 1 ? t("home.modal.travelers") : t("home.modal.traveler")) + " - " + (hotel.rooms.length > 0 ? hotel.rooms.length + " " + t('global.rooms') : "1 " + t('global.room')) }</span>					
				</Grid>
				{/* <Grid item>
					<RoomIcon className={`${classes.genericText}`} style={{fontSize: 16, verticalAlign: "text-top"}}/>
					<span className={`${classes.genericText} ${classes.italic}`} style={{fontSize: 14}}>{address}</span>
				</Grid> */}
				<Grid item style={{width: "100%"}}>
					<DateRange className={ classes.calendar } style={{fontSize: 20, verticalAlign: "text-top"}}/>
					<Typography>{t("cart.from")} {moment.utc(hotel.start_date).format('DD MMMM')} {t("cart.to")} { moment.utc(hotel.end_date).format('DD MMMM') } { moment.utc(hotel.end_date).diff(moment.utc(hotel.start_date), 'd') } {t("itinerary.nights")}</Typography>
				</Grid>
				<Grid item container direction={from === "tripSummary" ? (hotel.is_optional ? "row-reverse" : "row") : "column"} spacing={from === "tripSummary" ? 2 : 0} style={{paddingTop: 10}}>
					{
						from === "tripSummary" && (
							<Grid item sm={5} xs={12} className={classes.pictureContainer} onMouseEnter={ onMouseEnter } onMouseLeave={ onMouseLeave } onClick={openModal} style={{maxHeight: 170}}>
								<Slider pictures={ hotel_picture } showChevron={ showChevron } size={ 0 } dotSize={ 1 }/>
							</Grid>
						)
					}
					<Grid item sm={7} xs={12} style={{marginTop: 10}}>
					{
						hotel.rooms.map((room, room_index) => {
							let room_adults = 0;
							let room_child = 0;
							room.group_passenger.travelers_list.map((traveler) => {
								let age = moment.utc(trip_info.end_date).diff(moment.utc(traveler.birth_date), "y");
								if (age >= 18) {
									room_adults += 1;
								}
								if (age < 18) {
									room_child += 1;
								}
							});
							return (
								<Grid item key={room_index} style={{marginTop: 10}}>
									<Grid container direction={"column"}>
										<Grid item>
											<span className={`${classes.italic} ${classes.font14} ${classes.genericText} ${classes.bold}`}>{`${ t("global.room") + " " + (room_index + 2) } : `}</span>
											<span className={`${classes.italic} ${classes.font14} ${classes.genericText}`}>{`${room.name} - ${room_adults !== 0 ? room_adults : ""} ${room_adults !== 0 ? (room_adults > 1 ? t("flight_search.adults") : t("flight_search.adult")) : ""} ${room_child !== 0 ? room_child : ""} ${room_child !== 0 ? (room_child > 1 ? t("flight_search.children") : t("flight_search.child")) : ""} `}</span>
										</Grid>
										{
											room.breakfast_included && (
												<Grid item>
													<LocalCafeIcon className={ classes.includedAmenities } style={{fontSize: 16}}/>
													<span className={`${classes.font14} ${classes.genericText}`}>{t("accommodation_filters.breakfast_included")}</span>
												</Grid>
											)
										}
										{
											room.transfer_included && (
												<Grid item>
													<TransferWithinAStationIcon className={ classes.includedAmenities } style={{fontSize: 16}}/>
													<span className={`${classes.font14} ${classes.genericText}`}>{t("accommodation.transfer_included")}</span>
												</Grid>
											)
										}
									</Grid>
								</Grid>
							)
						})
					}
					</Grid>
				</Grid>
				{
					// content !== null && content !== "" && (
					// 	<Grid item style={{width: "100%"}}>
					// 		<Button className={`${classes.genericText} ${classes.lowerCase}`} variant={"outlined"} onClick={ showContractDetail } style={{float: "right"}}>{ t("global.short_more_info") }</Button>
					// 	</Grid>
					// )
				}
				{
                    type === "option" && (
                        <Grid item style={{width: "100%", marginTop: 10, fontSize: 20}}>
                            <span style={{float: "right"}} className={classes.bold}>+ {Intl.NumberFormat(i18n.language, { style: "currency", currency: selling_currency.iso_code, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(price) }</span>
                        </Grid>
                    )
                }
				{/* <Grid item container direction={"column"} spacing={2} style={{marginTop: 5}}>
				</Grid> */}
			</Grid>
			<MosaicPicture open={ openMozaic } onClose={ toggleMosaicModal } pictures={ hotel_picture } openModal={ togglePictureModal }/>
			<Picture open={ openPicture } onClose={ togglePictureModal } pictures={ hotel_picture } current_picture={ current_picture } setCurrentPicture={ setCurrentPicture }/>
        </Grid>
    )
}
export default React.memo(SummaryHotelCardManual);