//Dependencies
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment } from 'react';
//Core
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles({
    hr: {
        margin: '10px 0 0 0'
    },
    dateContainer: {
        textAlign: 'center',
        border: '1px solid'
    }
});

const CartCarContractDetail = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const car_contract = useSelector(state => state.cart.car_contract);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const onClose = () => {
        dispatch({ type: 'CART_TOGGLE_CONTRACT_DETAIL', payload: null });
    };
    if (car_contract !== null) {
        const important_notice = car_contract.important_notice !== null && car_contract.important_notice !== "" ? car_contract.important_notice.split("\n") : null;
        return (
            <Dialog fullScreen={ fullScreen } open={ true } fullWidth maxWidth={ 'md' } onClose={ onClose } disableEscapeKeyDown>
                <DialogTitle onClose={ onClose }>
                    <Grid container justify={ 'space-between' } alignItems={ 'center' }>
                        {
                            fullScreen ? (
                                <Grid item>
                                    <IconButton edge={ 'start' } onClick={ onClose }><NavigateBefore/></IconButton> { t('cart-material.contract-detail') }
                                </Grid>
                            ) : (
                                <Fragment>
                                    <Grid item>
                                        { t('cart-material.contract-detail') } :
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={ onClose }><Close/></IconButton>
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </Grid>
                    <hr className={ classes.hr }/>
                </DialogTitle>
                <DialogContent>
                    {
                        car_contract.included_service !== undefined && car_contract.included_service.map((service, index_service) => {
                            if (service.included) {
                                return <Typography key={ `car-service-${index_service}` }><strong>{ service.title } : </strong>{ service.description }</Typography>;
                            }
                        })
                    }
                    {
                        important_notice !== null &&
                        <Fragment>
                            <br/>
                            <Typography><strong>{ t('cart-material.important') } :</strong></Typography>
                            {
                                important_notice.map((notice, index_notice) => (
                                    <Fragment key={ `car-notice-${index_notice}` }>
                                        <Typography align={ 'justify' }>{ notice }</Typography>
                                        <br/>
                                    </Fragment>
                                ))
                            }
                        </Fragment>
                    }
                </DialogContent>
            </Dialog>
        );
    } 
    return null;
};

export default CartCarContractDetail;
