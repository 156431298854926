import { SetError, RemoveError, SetJourneyType } from "../../../Actions/Flight";

import moment from "moment";

export default (flight_groups, end_date, manual_request, enqueueSnackbar, t, dispatch) => {
    let error = false;
    flight_groups.map((flight_group, flight_index) => {
        if (flight_group.journey_type !== 5) {
            const flight_error = flight_group.error;
            if (flight_group.travelers.length === 0) {
                enqueueSnackbar(t("flight_groups.error.travelers"), {variant: 'warning', disableWindowBlurListener: true});
                if (!flight_error.includes("travelers"))
                    dispatch(SetError(flight_index, "travelers"));
                error = true;
            }
            else {
                if (flight_error.includes("travelers"))
                    dispatch(RemoveError(flight_index, "travelers"));
            }
            if (flight_group.travelers.length > 9) {
                enqueueSnackbar(t("flight_groups.error.travelers_length"), {variant: 'warning', disableWindowBlurListener: true});
                if (!flight_error.includes("travelers"))
                    dispatch(SetError(flight_index, "travelers"));
                error = true;   
            }
            else {
                if (flight_error.includes("travelers"))
                    dispatch(RemoveError(flight_index, "travelers"));   
            }
            if (flight_group.journey_type === 2 && flight_group.journey.length === 2)
            {
                if (flight_group.journey[0].origin.city === flight_group.journey[1].destination.city && flight_group.journey[0].destination.city === flight_group.journey[1].origin.city)
                    dispatch(SetJourneyType(1, flight_index))
            }
            if (flight_group.journey_type !== 4) {
                flight_group.journey.map((journey, journey_index) => {
                    if (journey.origin == null || journey.destination == null) {
                        if (journey.origin == null)
                        {
                            enqueueSnackbar(t("flight_groups.error.origin"), {variant: 'warning', disableWindowBlurListener: true});
                            if (!flight_error.includes("input_" + (journey_index * 2)))
                                dispatch(SetError(flight_index, "input_" + (journey_index * 2)));
                            error = true;
                        }
                        if (journey.destination == null)
                        {
                            enqueueSnackbar(t("flight_groups.error.destination"), {variant: 'warning', disableWindowBlurListener: true});
                            if (!flight_error.includes("input_" + ((journey_index * 2) + 1)))
                                dispatch(SetError(flight_index, "input_" + ((journey_index * 2) + 1)));
                            error = true;
                        }                    
                    }
                    else if (journey.origin !== null && journey.destination !== null) {
                        if (journey.origin.city === journey.destination.city) {
                            enqueueSnackbar(t("flight_groups.error.origin_dest"), {variant: 'warning', disableWindowBlurListener: true});
                            if (!flight_error.includes("input_" + (journey_index * 2)) && !flight_error.includes("input_" + ((journey_index * 2) + 1))) {
                                dispatch(SetError(flight_index, "input_" + (journey_index * 2)));
                                dispatch(SetError(flight_index, "input_" + ((journey_index * 2) + 1)));
                            }
                            error = true;
                        }
                        else {
                            if (flight_error.includes("input_" + ((journey_index * 2) + 1))) {
                                dispatch(RemoveError(flight_index, "input_" + ((journey_index * 2) + 1)));
                            }
                            if (flight_error.includes("input_" + (journey_index * 2))) {
                                dispatch(RemoveError(flight_index, "input_" + (journey_index * 2)));
                            }
                        }
                    }
                    if (journey.start_date == null)
                    {
                        enqueueSnackbar(t("flight_groups.error.date"), {variant: 'warning', disableWindowBlurListener: true});
                        if (!flight_error.includes("date_" + journey_index))
                            dispatch(SetError(flight_index, "date_" + journey_index));
                        error = true;
                    }
                    else {
                        let out_of_range = false;
                        if (moment.utc(journey.start_date).isSameOrBefore(moment.utc())) {
                            enqueueSnackbar(t("flight_groups.error.start_date_out_of_range"), {variant: 'warning', disableWindowBlurListener: true});
                            if (!flight_error.includes("date_" + journey_index))
                                dispatch(SetError(flight_index, "date_" + journey_index));
                            error = true;
                            out_of_range = true;
                        } else if (moment.utc(journey.start_date).isAfter(moment.utc(end_date)) || moment.utc(journey.start_date).isSameOrBefore(moment.utc())) {
                            enqueueSnackbar(t("flight_groups.error.end_date_out_of_range"), {variant: 'warning', disableWindowBlurListener: true});
                            if (!flight_error.includes("date_" + journey_index))
                                dispatch(SetError(flight_index, "date_" + journey_index));
                            error = true;
                            out_of_range = true;
                        } else if (moment.utc(journey.start_date).isAfter(moment.utc().add(1, "year"))) {
                            enqueueSnackbar(t("flight_groups.error.date_too_far"), {variant: 'warning', disableWindowBlurListener: true});
                            if (!flight_error.includes("date_" + journey_index))
                                dispatch(SetError(flight_index, "date_" + journey_index));
                            error = true;
                            out_of_range = true;
                        }
                        if (!out_of_range) {
                            if (flight_error.includes("date_" + journey_index))
                                dispatch(RemoveError(flight_index, "date_" + journey_index));
                        }
                    }
                })
            }
            else {
                if (manual_request === "") {
                    error = true;
                    enqueueSnackbar(t("flight_groups.error.manual_request"), {variant: 'warning', disableWindowBlurListener: true});
                    if (!flight_error.includes("manual_request")) {
                        dispatch(SetError(flight_index, "manual_request"));
                    }
                }
            }
        } else {
            if (manual_request === "") {
                error = true;
                enqueueSnackbar(t("flight_groups.error.manual_request"), {variant: 'warning', disableWindowBlurListener: true});
                if (!flight_error.includes("manual_request")) {
                    dispatch(SetError(flight_index, "manual_request"));
                }
            }
        }
    });
    return error;
}