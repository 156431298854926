import React from "react";
import { Paper, styled, Typography } from "@mui/material";
import { useDrag } from "react-dnd";

type Props = {
    icon: JSX.Element,
    label: string,
    type: string
}

export function MailTemplateVisualEditorBlockChoice(props: Props): JSX.Element {
    const [collected, drag] = useDrag(() => ({
        type: props.type,
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }));
    return (
        <Container
            ref={drag}
            elevation={2}
            sx={{
                backgroundColor: collected.isDragging ? 'rgba(200, 200, 200, 0.4)' : 'inherit'
            }}
        >
            <div>
                {props.icon}
            </div>
            <Typography variant="body2">
                {props.label}
            </Typography>
        </Container>
    );
}

const Container = styled(Paper)(({ theme }) => ({
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    cursor: 'move'
}));
