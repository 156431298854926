//---- Dependencies ----//
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
//---- Actions ----//
import { SetTripMainInfo } from "../../../../Actions/Summary";
import Notify from "../../../Common/Notify";

export default (trip_id, t, version_id, trip_token, email, dispatch, enqueueSnackbar) => {
	let { pass_check, headers } = CheckBeforeRequest();
	if (pass_check) {
		$.ajax({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${version_id}/user_validation/?token=${trip_token}&email=${email}`,
            success: function (data) {
                enqueueSnackbar(t("home.email_send"), {
                    variant: "success",
                });
                dispatch(SetTripMainInfo(data));
            },
            error: function (error) {   
            	console.log('error:', error);       	
            }
        });
	}
};
