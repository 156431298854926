import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BoxProps, Drawer, styled, Tab, Tabs } from "@mui/material";
import { isNumber } from "lodash";
import { AppState } from "../../../../Reducers/Reducers";
import { MailTemplateVisualEditorBlocksPanel } from "./mailTemplateVisualEditorBlocksPanel";
import { MailTemplateVisualEditorBodyStylePanel } from "./mailTemplateVisualEditorBodyStylePanel";
import { MailTemplateVisualEditorBlockOptionsPanel } from "./mailTemplateVisualEditorBlockOptionsPanel";
import BlocksIcon from '@mui/icons-material/Dashboard';
import BodyStyleIcon from '@mui/icons-material/InvertColors';

type Props = {
    locale?: number,
    useMinimal?: boolean,
    sx?: BoxProps['sx'],
    disabledBlocks?: string[]
}

export function MailTemplateVisualEditorOptionsArea(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [panel, setPanel] = useState(0);
    const isABlockSelected = useSelector((state: AppState) => {
        return Object.values(state.mailTemplate.others.instances).findIndex((item) => {
            return isNumber(item.visualEditorSelectedBlockId);
        }) >= 0;
    });
    const containerRef = useRef(null);

    const onChangeTab = (event: unknown, value: number) => {
        setPanel(value);
    };

    return (
        <Container ref={containerRef} sx={props.sx}>
            <TabsContainer>
                <Tabs variant="fullWidth" value={panel} onChange={onChangeTab}>
                    <Tab icon={<BlocksIcon />} iconPosition="start" label={t<string>('shared.mail-template-visual-editor-blocks')} />
                    {
                        !props.useMinimal &&
                        <Tab
                            icon={<BodyStyleIcon />}
                            iconPosition="start"
                            label={t<string>('shared.mail-template-visual-editor-body-styles')}
                        />
                    }
                </Tabs>
            </TabsContainer>
            <TabsContent>
                {
                    (
                        !isABlockSelected && panel === 0 ||
                        props.useMinimal
                    ) &&
                    <MailTemplateVisualEditorBlocksPanel disabledBlocks={props.disabledBlocks} />}
                {
                    !props.useMinimal &&
                    <div style={{ display: !isABlockSelected && panel === 1 ? 'block' : 'none' }}>
                        <MailTemplateVisualEditorBodyStylePanel />
                    </div>
                }
                <Drawer
                    variant="temporary"
                    open={isABlockSelected}
                    PaperProps={{ style: { position: 'absolute', width: '100%' } }}
                    BackdropProps={{ style: { position: 'absolute' } }}
                    ModalProps={{
                        container: containerRef.current,
                        style: { position: 'absolute' }
                    }}
                    hideBackdrop
                    disableAutoFocus
                    disableEnforceFocus
                >
                    <MailTemplateVisualEditorBlockOptionsPanel locale={props.locale} />
                </Drawer>
            </TabsContent>
        </Container>
    );
}

const Container = styled('div')({
    position: 'relative',
    width: '100%',
    height: '50vh',
    borderColor: "#D9D9D9",
    borderWidth: "1px",
    borderStyle: "solid",
    overflowX: 'hidden'
});

const TabsContainer = styled('div')({
    borderBottom: '1px solid #D9D9D9'
});

const TabsContent = styled('div')(({ theme }) => ({
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1)
}));
