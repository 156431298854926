// Dependencies
import { useTranslation } from 'react-i18next';
import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
// Core & Lab
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
// Icons
import Check from '@material-ui/icons/Check';

const useStyles = makeStyles({
    localeCheck: {
        color: 'green',
        marginLeft: 8,
        fontSize: '12px !important'
    }
});

const LocaleSelector = ({id, values}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const locales = useSelector((state) => state.user.locales);
    const current_locale = useSelector((state) => state.user.current_locale);
    const onCurrentLocaleChange = (event) => {
        dispatch({type: "USER_SET_CURRENT_LOCALE", payload: event.target.value});
    };
    const onRenderValue = () => {
        if (current_locale !== null && locales) {
            for (let i = 0; i < locales.length; i++) {
                if (locales[i].id === current_locale) {
                    return locales[i].full_name;
                }
            }
        }
        return '';
    };
    return (
        <FormControl fullWidth variant={'outlined'}>
            <InputLabel id={ `${id}-locale` }>{ t('poi.language') }</InputLabel>
            <Select labelId={ `${id}-locale` } label={ t('poi.language') } value={ current_locale === null ? '' : current_locale } onChange={ onCurrentLocaleChange } renderValue={ onRenderValue }>
                { locales && locales.map((locale) => <MenuItem key={ `${id}-locale-${locale.id}` } value={ locale.id }>{ locale.full_name }{ !!values[locale.id] && <Check className={ classes.localeCheck } fontSize={ 'small' }/> }</MenuItem>) }
            </Select>
        </FormControl>
    );
};

export default LocaleSelector;