import SetCookie from "../Components/Common/Functions/SetCookie";
import GetCookie from "../Components/Common/Functions/GetCookie";

const initial_state = {
    map: null,
    google: null,
    bounds: null,
    active: (GetCookie("active") !== "" ? parseInt(GetCookie("active")) : null),
    zoom: 17,
    fit_bounds: false,
    auto_reload: true,
    map_moved: false,
    timeline_cart: null,
    card_unmount_back: false,


    panel_type: 0,
    currency_list: [],
    default_pictures: null,
    lock_itinerary_action: false,
    itinerary_done: false,
    plane_done: false,
    cars_done: false,
    poi_done: false,
    accommodation_done: false,
    all_cart_done: false
    /*
        center_zoom_bounds: null,
        active: (GetCookie("active") !== "" ? parseInt(GetCookie("active")) : null),
        zoom_on_itinerary: false,
        zoom_on_destination: false,
        destination_bounds: null,
     */
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.base !== undefined) {
                Object.keys(action.payload.base).forEach(key => {
                    if (new_state[key] !== undefined && key !== "panel_type") {
                        new_state[key] = action.payload.base[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            const currency_list = new_state.currency_list.slice();
            new_state = initial_state;
            new_state.currency_list = currency_list;
            return new_state;
        }
        case "MATERIAL_SET_MAP": {
            new_state.map = action.payload.map;
            new_state.google = action.payload.google;
            return new_state;
        }
        case "MATERIAL_UPDATE_MAP": {
            if (new_state.card_unmount_back) {
                new_state.card_unmount_back = false;
            } else if (new_state.auto_reload) {
                new_state.bounds = action.payload.bounds;
                new_state.zoom = action.payload.zoom;
                new_state.fit_bounds = false;
                new_state.map_moved = false;
            } else {
                new_state.map_moved = true;
            }
            return new_state;
        }
        case "MATERIAL_SET_ACTIVE_DESTINATION": {
            new_state.active = action.payload.active;
            SetCookie("active", new_state.active, 365, `/${window.url_name}`, (window.location.host === "localhost:3000" ? null : window.location.host));
            new_state.bounds = action.payload.bounds;
            new_state.zoom = action.payload.zoom;
            new_state.fit_bounds = action.payload.reset;
            return new_state;
        }
        case "MATERIAL_ACCOMMODATION_CARD_UNMOUNT": {
            new_state.active = action.payload.active;
            SetCookie("active", new_state.active, 365, `/${window.url_name}`, (window.location.host === "localhost:3000" ? null : window.location.host));
            new_state.bounds = action.payload.bounds;
            new_state.zoom = action.payload.zoom;
            new_state.fit_bounds = action.payload.reset;
            return new_state;
        }
        case "MATERIAL_ACCOMMODATION_CARD_UNMOUNT_BACK": {
            new_state.card_unmount_back = true;
            return new_state;
        }
        case "MATERIAL_TOGGLE_AUTO_RELOAD": {
            new_state.auto_reload = !new_state.auto_reload;
            return new_state;
        }
        case "MATERIAL_APPLY_MOVED": {
            new_state.map_moved = false;
            new_state.bounds = action.payload.bounds;
            new_state.zoom = action.payload.zoom;
            new_state.fit_bounds = false;
            return new_state;
        }
        case "MATERIAL_SET_TIMELINE_CART": {
            new_state.timeline_cart = action.payload;
            return new_state;
        }
        case "MATERIAL_SET_BASIC_DATA": {
            new_state.active = action.payload.active;
            SetCookie("active", new_state.active, 365, `/${window.url_name}`, (window.location.host === "localhost:3000" ? null : window.location.host));
            return new_state;
        }










        case "BASE_LOCK_ITINERARY_ACTIONS": {
            new_state.lock_itinerary_action = true;
            return new_state;
        }
        case "BASE_UNLOCK_ITINERARY_ACTIONS": {
            new_state.lock_itinerary_action = false;
            return new_state;
        }
        case "BASE_TOGGLE_PANEL_TYPE": {
            new_state.panel_type = action.payload.panel_type;
            return new_state;
        }
        case "BASE_SET_CENTER_ZOOM_BOUNDS": {
            new_state.center_zoom_bounds = action.payload.center_zoom_bounds;
            if (action.payload.active !== undefined) {
                new_state.active = action.payload.active;
                SetCookie("active", new_state.active, 365, `/${window.url_name}`, (window.location.host === "localhost:3000" ? null : window.location.host));
            }
            new_state.zoom_on_itinerary = false;
            new_state.zoom_on_destination = false;
            return new_state;
        }
        case "BASE_ZOOM_ON_DESTINATION": {
            new_state.destination_bounds = action.payload.bounds;
            new_state.zoom_on_destination = true;
            if (action.payload.active !== undefined) {
                new_state.active = action.payload.active;
                SetCookie("active", new_state.active, 365, `/${window.url_name}`, (window.location.host === "localhost:3000" ? null : window.location.host));
            }
            new_state.zoom_on_itinerary = false;
            return new_state;
        }
        case "BASE_ZOOM_ON_ITINERARY": {
            new_state.zoom_on_itinerary = true;
            new_state.zoom_on_destination = false;
            return new_state;
        }
        case "BASE_SET_CURRENCY": {
            new_state.currency_list = action.payload.currency;
            return new_state;
        }
        case "BASE_SET_DEFAULT_PICTURES": {
            new_state.default_pictures = action.payload.pictures;
            return new_state;
        }
        case "BASE_SET_ITINERARY_DONE": {
            new_state.itinerary_done = action.payload.itinerary_done;
            return new_state;
        }
        case "BASE_SET_PLANE_DONE": {
            new_state.plane_done = action.payload.plane_done;
            return new_state;
        }
        case "BASE_SET_CARS_DONE": {
            new_state.cars_done = action.payload.cars_done;
            return new_state;
        }
        case "BASE_SET_ACCOMMODATION_DONE": {
            new_state.accommodation_done = action.payload.accommodation_done;
            return new_state;
        }
        case "BASE_SET_POI_DONE": {
            new_state.poi_done = action.payload.poi_done;
            return new_state;
        }
        case "BASE_SET_ALL_CART_DONE": {
            return {...new_state, all_cart_done: action.payload}
        }
    }
    return new_state;
}