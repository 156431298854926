//---- Dependencies ----//
import { combineReducers } from 'redux';

//---- Reducers ----//
import user from "./User";
import crm from "./Crm";
import cart from "./Cart";
import menu from "./Menu";
import home from "./Home";
import trip from "./Trip";
import base from "./Base";
import header from "./Header";
import itinerary from "./Itinerary";
import itinerarySlice from "../Components/Itinerary/redux/reducer";
import itinerary_type from "./ItineraryType";
import accommodation from "./Accommodation";
import poi from "./Poi";
import flight from "./Flight";
import flight_search from "./FlightSearch";
import cars_search from "./CarsSearch";
import booking from "./Booking";
import calendar from "./Calendar";
import summary from "./Summary";
import transfers from "./Transfers";
import pictures from "./Pictures";
import payment from "./Payment";
import locale from "./Locale";
import mailTemplate from "../Components/Menu/MaterialTripList/MailVisualEditor/redux/reducer";
import tripListNotes from "../Components/Menu/MaterialTripList/TripListNotes/redux/reducer";
import picture from "../Components/Menu/MaterialTripList/picture/redux/reducer";
import mapSlice from "../Components/Map/redux/reducer";
import cartConstructionSlice from "../Components/CartMaterial/redux/cartConstructionReducer";

const appReducer = combineReducers({
    /*your app’s top-level reducers */
    user,
    crm,
    cart,
    menu,
    home,
    trip,
    base,
    booking,
    header,
    itinerary,
    itinerary_type,
    [itinerarySlice.name]: itinerarySlice.reducer,
    summary,
    accommodation,
    poi,
    flight,
    flight_search,
    cars_search,
    calendar,
    transfers,
    mailTemplate,
    pictures,
    payment,
    picture,
    locale,
    tripListNotes,
    [mapSlice.name]: mapSlice.reducer,
    [cartConstructionSlice.name]: cartConstructionSlice.reducer
});

export default (state, action) => {
    if (action.type === "USER_SET_CONNECTION" && action.payload.is_connected === false) {
        //storage.removeItem('persist:root');
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};
