import { EditorState, Modifier } from "draft-js";

export function insertTextInEditorstate(
    editorState: EditorState,
    text: string
): EditorState {
    const currentContent = editorState.getCurrentContent(),
        currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
        currentContent,
        currentSelection,
        text
    );

    const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
    return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
}