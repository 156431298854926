//Dependencies
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
//Core
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
//Components
import { CartTransferCard } from "./CartTransferCard";
import { CartManualProductCard } from './CartManualProductCard';
import { Box } from '@mui/material';
import { useUnpackagedProducts } from './utils/unpackagedProduts';

const useStyles = makeStyles({
    title: {
        fontSize: 24,
        fontWeight: 700
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    checkboxContainer: {
        position: 'relative',
        //height: '100%',
        width: 50,
        borderLeft: '6px solid #E6592F'
    },
    checkbox: {
        //position: 'absolute',
        //top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
    marginBottom: {
        marginBottom: 32
    }
});

const CartTransfers = () => {
    const unpackagedProducts = useUnpackagedProducts();
    const ferries = unpackagedProducts.manualProducts.filter((item) => {
        return item.product_type === 13;
    });

    if (unpackagedProducts.transfers.length > 0 || ferries.length > 0) {
        return (
            <div>
                <TitleWrapper />
                <hr/>
                {
                    unpackagedProducts.transfers.map((item) => {
                        //let checked = false;
                        //for (let i = 0; i < to_book.length; i++) {
                        //if (to_book[i].id === transfer.id) {
                        //checked = true;
                        //}
                        //}
                        if (item.type === 'manual') {
                            return (
                                <Grid key={ `manual-product-${item.transfer.id}` } id={ `manual-product-${item.transfer.id}` }>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            marginBottom: 3
                                        }}
                                    >
                                        <CartManualProductCard product={item.transfer} />
                                    </Box>
                                </Grid>
                            );
                        }
                        return (
                            <Grid key={ `transfer-${item.transfer.id}` } container>
                                <Box
                                    sx={{
                                        width: '100%',
                                        marginBottom: 3
                                    }}
                                >
                                    <CartTransferCard transfer={item.transfer} />
                                </Box>
                                {/*<Grid className={ classes.marginBottom } item xs={ 12 }/>*/}
                            </Grid>
                        );
                    }).concat(
                        ferries.map((ferry) => {
                            return (
                                <Grid key={ `manual-product-${ferry.id}` } id={ `manual-product-${ferry.id}` }>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            marginBottom: 3
                                        }}
                                    >
                                        <CartManualProductCard product={ferry} />
                                    </Box>
                                </Grid>
                            );
                        })
                    )
                }
            </div>
        );
    }
    return null;
};

function TitleWrapper() {
    const classes = useStyles();
    const { t } = useTranslation();
    const language = useSelector(state => state.header.tmp_language);
    const total_transfers = useSelector(state => state.cart.total_transfers);
    const unpackagedProducts = useUnpackagedProducts();
    const ferries = unpackagedProducts.manualProducts.filter((item) => {
        return item.product_type === 13;
    });

    return (
        <Typography className={ classes.title }>
            { t('cart-material.total-transfer') } ({unpackagedProducts.transfers.length + ferries.length}) :{' '}
            { total_transfers.map((currency_cost, currency_index) => `${currency_index > 0 ? ' + ' : ''}${currency_cost.cost.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}${currency_cost.currency.symbol}`) }
        </Typography>
    );
}

export default CartTransfers;
