//---- Dependencies ----//
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ListGroup, ListGroupItem  } from "reactstrap";

//---- Commons ----//

//---- Components ----//

//---- Actions ----//
import { ToggleServiceModal } from "../../../Actions/CarsSearch";

//---- Class ----//
@connect((store) => {
    return {
        open_modal: store.cars_search.open_modal,
        car_services: store.cars_search.car_services,
        contract_name: store.cars_search.contract_name,
        contract_logo: store.cars_search.contract_logo,
        important_notice: store.cars_search.important_notice,
    }
})
@withTranslation()

export default class CarsServiceModal extends Component {
    constructor(props, context) {
        super(props, context);
    }
    render() {
        const { open_modal, car_services, contract_logo, important_notice, contract_name, dispatch, t } = this.props;
        return (
            <Modal id={ "modal-cars-other-destination" } isOpen={ open_modal } toggle={() => {  dispatch(ToggleServiceModal(false)); }} size={"lg"}>
                <div>
                    <ModalHeader toggle={() => {  dispatch(ToggleServiceModal(false)); }}>
                        <div id={"modal-choice-header"}>
                            <h1 className={"contract-modal-title ft-font-20"}> {t("cars.service_available")} </h1>
                        </div>
                    </ModalHeader>
                    <ModalBody className={"grey-bg"}>
                        {
                            car_services && (
                                <div>
                                    <div className={"text-center"}><img className={"ft-height-100"} src={contract_logo}/></div>
                                    <h1 className={"text-center mb15"}>{contract_name}</h1>
                                    {
                                        car_services.map((service, service_id) => {
                                            if (service.included) {
                                                return (
                                                    <div key={service_id}>
                                                        <span className="bold">{service.title}</span>{" : " + service.description}
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                    <div>
                                        {
                                            important_notice && (
                                                <Fragment>
                                                    <span className="ft-red ft-font-20 underline">{t("flight_search.important") + ": "}</span>
                                                    <br/>
                                                    {
                                                        important_notice.split("\n").map((item, key) => {
                                                            return (
                                                                <span key={key}>{item}<br/></span>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </ModalBody>
                </div>
            </Modal>
        );
    }
}