import React from "react";
import { useTranslation } from "react-i18next";
import { Button, styled } from "@mui/material";
import { Add } from "@mui/icons-material";
import { CartPackageItem } from "./CartPackageItem";
import { CartPackageDraggableItemProps } from "./CartPackageDraggableItem";
import { Provider } from "../Common/objects/provider";

export type PackageInput = {
    [key: string]: {
        provider: Provider | null,
        openInfo: boolean,
        openProducts: boolean,
        flightPrice: {
            id?: number,
            price: number,
            currency: number,
            margin: number,
            marginType: 'PER' | 'FIX'
        },
        terrestrialPrice: {
            id?: number,
            price: number,
            currency: number,
            margin: number,
            marginType: 'PER' | 'FIX'
        },
        products: CartPackageDraggableItemProps[]
    }
}

type Props = {
    packages: PackageInput,
    onAdd: () => void,
    onChangePackages: React.Dispatch<React.SetStateAction<PackageInput>>
}

export function CartPackagePackagesList(props: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <List>
            {
                Object.keys(props.packages).map((key) => {
                    const stackNumber = key.split('-')[0] ? parseInt(key.split('-')[0]!) : null;
                    const stackInfoId = key.split('-')[1] ? parseInt(key.split('-')[1]!) : null;
                    const packageContent = props.packages[key];
                    return (
                        <CartPackageItem
                            key={stackNumber}
                            stackNumber={stackNumber}
                            stackInfoId={stackInfoId}
                            package={packageContent}
                            onChangePackages={props.onChangePackages}
                        />
                    );
                })
            }
            <ListItem>
                <Button
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={props.onAdd}
                    fullWidth
                >
                    {t<string>('shared.add')}
                </Button>
            </ListItem>
        </List>
    );
}

const List = styled('ul')(() => ({
    listStyle: 'none',
    paddingLeft: 0
}));

const ListItem = styled('li')((props) => ({
    marginBottom: props.theme.spacing(1.5)
}));
