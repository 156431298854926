//---- Dependencies ----//
import React, {Fragment} from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

//---- CSS ----//
import "../../../../Style/FlightSearch.css";

@connect((store) => {
    return {
        airports: store.flight_search.airports,
        stations: store.flight_search.stations,
        language: store.header.tmp_language,
        currency: store.trip.currency
    };
})
@withTranslation()
class RenderDetailedLuggage extends React.Component {
	
	render() {
		const { flight, segment_index, airports, travelers_list, language, currency, stations, t } = this.props;
		let outbound = flight.outbounds[segment_index];
		let leg = outbound.legs[0];
		let last_leg = outbound.legs.length - 1;
		let pax = {
			ADT: travelers_list.ADT > 1 ? t("flight_search.adults") : t("flight_search.adult"),
			CNN: travelers_list.CNN > 1 ? t("flight_search.children") : t("flight_search.child"),
			INF: travelers_list.INF > 1 ? t("flight_search.babies") : t("flight_search.baby")
		};
		let cart_passenger = flight.group_passenger ? flight.group_passenger.travelers_list.reduce(function (r, a) {
            r[a.id] = r[a.id] || [];
            r[a.id].push(a);
            return r;
        }, {}) : null;
        let origin_airport = leg.origin_station ? (typeof leg.origin_station === "number" ? stations[leg.origin_station].code : leg.origin_station.station_code) : (leg.origin ? leg.origin.airport_code : airports[leg.origin_airport].code);
        let destination_airport = outbound.legs[last_leg].destination_station ? (typeof outbound.legs[last_leg].destination_station === "number" ? stations[outbound.legs[last_leg].destination_station].code : outbound.legs[last_leg].destination_station.station_code) : (outbound.legs[last_leg].destination ? outbound.legs[last_leg].destination.airport_code : airports[outbound.legs[last_leg].destination_airport].code);
        let origin_city = leg.origin_station ? (typeof leg.origin_station === "number" ? stations[leg.origin_station].iata_city.international_name : leg.origin_station.iata_city.international_name) : (leg.origin ? leg.origin.iata_city.international_name : airports[leg.origin_airport].iata_city.international_name);
        let destination_city = outbound.legs[last_leg].destination_station ? (typeof outbound.legs[last_leg].destination_station === "number" ? stations[outbound.legs[last_leg].destination_station].iata_city.international_name : outbound.legs[last_leg].destination_station.iata_city.international_name) : (outbound.legs[last_leg].destination ? outbound.legs[last_leg].destination.iata_city.international_name : airports[outbound.legs[last_leg].destination_airport].iata_city.international_name);
        let luggage_cabin = leg.baggage ? 
        	leg.baggage.find((luggage) => {
        		return luggage.is_carry_on;
        	}) : null;
        let baggage_allowance = leg.baggage ?
        	leg.baggage.find((luggage) => {
        		return !luggage.is_carry_on;
        	}) : null;
		return (
			<div className="flight-options-container">
				<div className={"detailed-luggage"}>
					<div className={"luggage-destination"}>{origin_airport + " - " + destination_airport}</div>
					<div className={ "luggage-outbound" }>
		                <table className={ "table table-sm"}>
			                <tbody>
			                    <tr className={ "top-data" }>
			                      <td className={ "left-data" }>{origin_city + " - " + destination_city}</td>
			                      <td className={ "middle-data" }>{t("flight_search.luggage_carry_on")}</td>
			                      <td className={ "right-data" }>{t("flight_search.luggage_check")}</td>
			                    </tr>
			                    {
			                    	Object.keys(travelers_list).map((traveler, traveler_index) => {
			                    		if (travelers_list[traveler] > 0 && traveler !== "total" && (luggage_cabin !== undefined || baggage_allowance !== undefined)) {
				                    		return (
							                    <tr className={ "bottom-data" } key={traveler_index}>
							                      <td className={ "left-data" }>{pax[traveler] + " X " + travelers_list[traveler]}</td>
							                      <td className={ "middle-data" }>{(luggage_cabin !== null && luggage_cabin !== undefined) ? luggage_cabin.quantity : "-"}</td>
							                      <td className={ "right-data" }>
							                      	{
							                      		((baggage_allowance !== null && baggage_allowance !== undefined) ?
							                      			(baggage_allowance.quantity > 1 ? baggage_allowance.quantity + " " + t("global.luggages") :
							                      				baggage_allowance.quantity + " " + t("global.luggage")) :
							                      		(leg.baggage_allowance ? (
							                      			leg.baggage_allowance.number_of_pieces > 1 ?
							                      			leg.baggage_allowance.number_of_pieces + " " + t("global.luggages") :
							                      				leg.baggage_allowance.number_of_pieces + " " + t("global.luggage")) : ""
							                      		)) + " X " + travelers_list[traveler]
							                      	}</td>
							                    </tr>
				                    		);
			                    		}
			                    	})
			                    }
			                    {
			                    	leg.paid_options && leg.paid_options.length > 0 && (
			                    		leg.paid_options.map((option, option_index) => {
			                    			let price = new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(option.price));
			                    			if (!option.baggage_is_both) {
				                    			return (
				                    				<tr key={option_index}>
				                    					<td className={ "left-data" }>{cart_passenger[option.traveler][0].first_name + " " + cart_passenger[option.traveler][0].last_name}</td>
				                    					<td className={ "middle-data" }>{option.baggage_is_carry_on ? 
					                    						option.quantity + "X" + option.weight + "kg " + t("global.at") + " " + price :
					                    						" - "
					                    					}</td>
				                    					<td className={ "right-data" }>
				                    					{
			                    							!option.baggage_is_carry_on && option.weight !== null && (
				                    							option.quantity + "X" + option.weight + "kg " + t("global.at") + " " + price
			                    							)
				                    					}
				                    					{
			                    							!option.baggage_is_carry_on && option.weight === null && (
				                    							option.description.toLowerCase() + " " + t("global.at") + " " + price
			                    							)
				                    					}
					                    				</td>
				                    				</tr>
				                    			);
			                    			}
			                    			else {
			                    				return (
				                    				<tr key={option_index}>
				                    					<td className={ "left-data" }>{cart_passenger[option.traveler][0].first_name + " " + cart_passenger[option.traveler][0].last_name}</td>
				                    					<td className={ "middle-data" }>{
					                    						option.quantity
					                    					}</td>
				                    					<td className={ "right-data" }>{
				                    						!option.baggage_is_carry_on ? 
					                    						option.quantity + "X" + option.weight + "kg " + t("global.at") + " " + price :
					                    						" - "
					                    					}</td>
				                    				</tr>
				                    			);						                    				
			                    			}
			                    		})
			                    	)
			                    }
			                </tbody>
		              	</table>
		            </div>
				</div>
			</div>
		);
	}
}
export default RenderDetailedLuggage;
