import { List } from '../objects/list';
import { Picture } from '../objects/picture';
import { PictureType } from '../objects/pictureState';
import {
    ADD_CUSTOM_PICTURES,
    ADD_FILES,
    ADD_SELECTION,
    DELETE_FILE,
    DELETE_SELECTION,
    PictureActionTypes,
    RESET_CREATE_FORM,
    SET_ADDING,
    SET_CREATE_ALT,
    SET_CREATE_CREDIT,
    SET_CREATE_DESCRIPTION,
    SET_CREATE_NAME,
    SET_CREATE_TYPE,
    SET_CROPPER,
    SET_LIMIT,
    SET_LIST,
    SET_PAGE,
    SET_PICTURE,
    SET_QUERY,
    SET_RUNNING,
    SET_SEARCH,
    TOGGLE_CREATE,
    TOGGLE_FILTER,
    TOGGLE_KEEP,
    TOGGLE_REFRESH,
    UPDATE_ALT,
    UPDATE_CREATE_TAB,
    UPDATE_CREDITS,
    UPDATE_DESCRIPTION,
    UPDATE_FILTER_TYPES,
    UPDATE_NAME,
    UPDATE_PROGRESS
} from './actionTypes';

export const addCustomPictures = (pictures: Picture[]): PictureActionTypes => ({
    type: ADD_CUSTOM_PICTURES,
    payload: pictures
});

export const addFiles = (files: any[]): PictureActionTypes => ({
    type: ADD_FILES,
    payload: files
});

export const addSelection = (picture: Picture, name: null | string = null): PictureActionTypes => ({
    type: ADD_SELECTION,
    payload: {
        picture: picture,
        name: name
    }
});

export const deleteFile = (index: number): PictureActionTypes => ({
    type: DELETE_FILE,
    payload: index
});

export const deleteSelection = (id: number): PictureActionTypes => ({
    type: DELETE_SELECTION,
    payload: id
});

export const resetCreateForm = (): PictureActionTypes => ({
    type: RESET_CREATE_FORM
});

export const setAdding = (adding: boolean): PictureActionTypes => ({
    type: SET_ADDING,
    payload: adding
});

export const setCreateAlt = (alt: string | null): PictureActionTypes => ({
    type: SET_CREATE_ALT,
    payload: alt
});

export const setCreateCredit = (credit: string | null): PictureActionTypes => ({
    type: SET_CREATE_CREDIT,
    payload: credit
});

export const setCreateDescription = (description: string | null): PictureActionTypes => ({
    type: SET_CREATE_DESCRIPTION,
    payload: description
});

export const setCreateName = (name: string | null): PictureActionTypes => ({
    type: SET_CREATE_NAME,
    payload: name
});

export const setCreateType = (type: PictureType): PictureActionTypes => ({
    type: SET_CREATE_TYPE,
    payload: type
});

export const setCropper = (cropper: any): PictureActionTypes => ({
    type: SET_CROPPER,
    payload: cropper
});

export const setLimit = (limit: number): PictureActionTypes => ({
    type: SET_LIMIT,
    payload: limit
});

export const setList = (list: List): PictureActionTypes => ({
    type: SET_LIST,
    payload: list
});

export const setPage = (page: number): PictureActionTypes => ({
    type: SET_PAGE,
    payload: page
});

export const setPicture = (picture: boolean | Picture): PictureActionTypes => ({
    type: SET_PICTURE,
    payload: picture
});

export const setQuery = (key: string, query: unknown): PictureActionTypes => ({
    type: SET_QUERY,
    payload: { key, query }
});

export const setRunning = (running: boolean): PictureActionTypes => ({
    type: SET_RUNNING,
    payload: running
});

export const setSearch = (search: string): PictureActionTypes => ({
    type: SET_SEARCH,
    payload: search
});

export const toggleCreate = (): PictureActionTypes => ({
    type: TOGGLE_CREATE
});

export const toggleFilter = (): PictureActionTypes => ({
    type: TOGGLE_FILTER
});

export const toggleKeep = (): PictureActionTypes => ({
    type: TOGGLE_KEEP
});

export const toggleRefresh = (): PictureActionTypes => ({
    type: TOGGLE_REFRESH
});

export const updateAlt = (alt: string): PictureActionTypes => ({
    type: UPDATE_ALT,
    payload: alt
});

export const updateCreateTab = (tab: 0 | 1): PictureActionTypes => ({
    type: UPDATE_CREATE_TAB,
    payload: tab
});

export const updateCredits = (credits: string): PictureActionTypes => ({
    type: UPDATE_CREDITS,
    payload: credits
});

export const updateDescription = (description: string): PictureActionTypes => ({
    type: UPDATE_DESCRIPTION,
    payload: description
});

export const updateFilterTypes = (types: PictureType[]): PictureActionTypes => ({
    type: UPDATE_FILTER_TYPES,
    payload: types
});

export const updateName = (name: string): PictureActionTypes => ({
    type: UPDATE_NAME,
    payload: name
});

export const updateProgress = (progress: number): PictureActionTypes => ({
    type: UPDATE_PROGRESS,
    payload: progress
});
