import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { MailTemplateVisualEditorContext } from "./mailTemplateVisualEditorContext";
import { setVisualEditorMode } from "./redux/actions";
import { Mode } from "./objects/mode";
import { AppState } from "../../../../Reducers/Reducers";

export function MailTemplateVisualEditorMode(): JSX.Element {
    const context = useContext(MailTemplateVisualEditorContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const mode = useSelector((state: AppState) => {
        return state.mailTemplate.visualEditor.present.instances[context.instanceId]?.visualEditorMode;
    });

    const onChangeMode = (mode: Mode) => {
        dispatch(setVisualEditorMode(context.instanceId, mode));
    };

    return (
        <FormControl size="small">
            <InputLabel>
                {t<string>('shared.mail-template-visual-editor-preview-mode')}
            </InputLabel>
            <Select
                label={t<string>('shared.mail-template-visual-editor-preview-mode')}
                value={mode}
                onChange={(event) => onChangeMode(event.target.value as Mode)}
            >
                <MenuItem value={Mode.DESKTOP}>
                    {t<string>('shared.mail-template-visual-editor-desktop')}
                </MenuItem>
                <MenuItem value={Mode.MOBILE}>
                    {t<string>('shared.mail-template-visual-editor-mobile')}
                </MenuItem>
            </Select>
        </FormControl>
    );
}
