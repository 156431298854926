import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MuiPhoneNumber from "material-ui-phone-number";
import axios from "axios";

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";

import PhotoLibraryIcon from '@material-ui/icons/PhotoLibraryOutlined';

import GetClient from "./Functions/GetClient";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    stickyPaper: {
        position: "sticky",
        top: 80,
        borderRadius: 12
    },
    mobileSticky: {
        position: "sticky",
        top: 70,
        zIndex: 2,
        backgroundColor: "white"
    },
    cardTitle: {
    	"& .mui-jss-MuiCardHeader-title": {
	    	fontSize: 22
    	},
    	"fontSize": 22
    },
    cardPadding: {
        padding: "10px 10px 0 10px"
    },
    alignCenter: {
        textAlign: "center"
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    bold: {
        fontWeight: "bold"
    },
    inline: {
        display: "inline"
    },
    inputBorder: {
        "&& .mui-jss-MuiOutlinedInput-root": {
            borderRadius: 8
        }
    },
    input: {
        display: 'none'
    },
    orangeButton: {
        color: "white",
        backgroundColor: "#E6592F"
    },
    genericText: {
        color: "#0000008A",
        fontStyle: "normal",
        fontFamily: "Roboto"
    },
    footer: {
        marginTop: "8%",
        position: "sticky",
        bottom: 0,
        zIndex: 2,
        backgroundColor: "white"
    },
    footerMobile: {
        marginTop: "29%",
        position: "sticky",
        bottom: 64,
        zIndex: 2,
        backgroundColor: "white"
    },
    logo: {
        height: " 100%",
        width: "100%",
        display: "block",
        objectPosition: "center",
        objectFit: "contain"
    },
    formControlRoot1: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 30px 7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12
        }
    },
    italic: {
        fontStyle: "italic"
    }
}));

const MaterialAgencyProfile = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const downDesktop = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const client = useSelector(store => store.user.client);
    const user = useSelector(store => store.user.user);
    let { pass_check, headers } = CheckBeforeRequest();

    let init_lang = null;
    if (localStorage.getItem("i18nextLng") !== null && localStorage.getItem("i18nextLng") !== "null") {
        let tmp = localStorage.getItem("i18nextLng");
        if (tmp.includes("-")) {
            tmp = tmp.split("-");
            init_lang = { value: tmp[0], label: t("language." + tmp[0]) };
        } else {
            init_lang = { value: tmp, label: t("language." + tmp) };
        }
    }
    const [language, setLanguage] = useState(init_lang);

    const [name, setName] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [street, setStreet] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [vatNumber, setVatNumber] = useState("");
    const [siret, setSiret] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [network, setNetwork] = useState(null);
    const [groupeId, setGroupId] = useState("");
    const [logoId, setLogoId] = useState(null);
    const [logo, setLogo] = useState(null);
    const [file, setFile] = useState(null);
    const [tmpLogo, setTmpLogo] = useState("Img/addLogo.png");

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    useEffect(() => {
        if (client === null) {
            GetClient(dispatch, user);
        }
    }, []);
    useEffect(() => {
        if (client !== null) {
            reset();
        }
    }, [client]);
    const reset = () => {
        console.log('client:', client);
        setFile(null);
        setLogoId(client.logo !== null ? client.logo.id : null);
        setLogo(client.logo);
        setName(client.name);
        setContactEmail(client.contact_email);
        setStreet(client.street);
        setZipCode(client.zip_code);
        setCity(client.city);
        setState(client.state);
        setVatNumber(client.vat_number);
        setSiret(client.siret);
        setPhoneNumber(client.phone_number);
        setGroupId(client.group);
        setNetwork(client.group_minimal !== null ? client.group_minimal.name : null);
        setTmpLogo(client.logo !== null ? (client.logo.thumbnail_little !== null ? client.logo.thumbnail_little : client.logo.url) : "Img/addLogo.png");
    };
    const changeImgHandler = (img) => {
        let reader = new FileReader();
        let file = img;
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setTmpLogo(reader.result);
            setFile(file);
        };
    };
    const handleLeadPhoneNumber = ((value) => {
        setPhoneNumber(value);
    });
    const save = () => {
        if (pass_check) {
            if (file !== null) {
                let request = new FormData();
                request.append("name", "Logo");
                request.append("description", "Logo Client");
                request.append('image', file);
                axios({
                    method: "POST",
                    headers: headers,
                    url: `${API_HREF}client/${user.client}/pictures/?type=logo`,
                    data: request
                }).then(function (response) {
                    patchAgency(response.data.id);
                }).catch(function (error) {
                    console.log('error:', error);
                });
            } else {
                patchAgency(null);
            }
        }
    };
    const patchAgency = (logo_id) => {
        let request = {
            name: name,
            contact_email: contactEmail,
            street: street,
            zip_code: zipCode,
            city: city,
            state: state,
            phone_number: phoneNumber,
            siret: siret !== null && siret !== "" ? siret : 0,
            vat_number: vatNumber !== null && vatNumber !== "" ? vatNumber : 0
        };
        if (logo_id !== null) {
            request.logo_id = logo_id;
        }
        if (user.client_full.type !== 2) {
            request.group = groupeId;
        }
        if (pass_check) {
            axios({
                method: "PATCH",
                headers: headers,
                url: `${API_HREF}client/${user.client}/`,
                data: JSON.stringify(request)
            }).then(function (response) {
                let client = Object.assign({}, response.data);
                dispatch({
                    type: "USER_SET_CLIENT",
                    payload: {
                        client: client
                    }
                });
                enqueueSnackbar(t("menu.profile.edit_success"), {
                    variant: "success"
                });
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
                enqueueSnackbar(t("notify.changed_failed"), {
                    variant: "error"
                });
            });
        }
    };
    return (
        <Container className={classes.container}>
            <Grid container spacing={ 2 }>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom className={classes.genericText}>
                        { t("header.menu.agency_profile") }
                    </Typography>
                </Grid>
                {
                    !downDesktop && (
                        <Grid item md={4}>
                            <Card className={ classes.stickyPaper }>
                                <CardContent className={`${classes.cardTitle} ${classes.cardPadding}`}>
                                    <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                                        <Grid item style={{ height: 100, width: 200 }}>
                                            <img className={classes.logo} src={tmpLogo} />
                                        </Grid>
                                        <Grid item style={{ marginTop: 5 }}>
                                            <input
                                                accept="image/*"
                                                className={classes.input}
                                                id="contained-button-file"
                                                type="file"
                                                onChange={ (event) => {
                                                    changeImgHandler(event.target.files[0]); 
                                                }}
                                            />
                                            <label htmlFor="contained-button-file" style={{ marginBottom: 0 }}>
                                                <Button startIcon={<PhotoLibraryIcon/>} component="span">{t("menu.profile.upload_logo")}</Button>
                                            </label>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                }
                {
                    downDesktop && (
                        <Grid item xs={12} className={classes.mobileSticky}>
                            <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                                <Grid item style={{ height: 100, width: 200 }}>
                                    <img className={classes.logo} src={tmpLogo} />
                                </Grid>
                                <Grid item style={{ marginLeft: 5 }}>
                                    <input
                                        accept="image/*"
                                        className={classes.input}
                                        id="contained-button-file"
                                        type="file"
                                        onChange={ (event) => {
                                            changeImgHandler(event.target.files[0]); 
                                        }}
                                    />
                                    <label htmlFor="contained-button-file" style={{ marginBottom: 0 }}>
                                        <Button startIcon={<PhotoLibraryIcon/>} component="span">{t("menu.profile.upload_logo")}</Button>
                                    </label>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
                <Grid item md={8} xs={12}>
                    <Grid container direction="column" spacing={4} style={{ marginBottom: 10 }}>
                        <Grid item><Typography variant={"h6"}>{t("global.global_informations")}</Typography></Grid>
                        <Grid item>
                            <TextField className={classes.inputBorder} disabled={quotation_code === "visiteurs"} fullWidth value={name} onChange={(e) => setName(e.target.value)} label={ t("menu.profile_agency.name") } variant="outlined" helperText={t("menu.profile_agency.info_name")} />
                        </Grid>
                        <Grid item>
                            <TextField className={classes.inputBorder} disabled={quotation_code === "visiteurs"} fullWidth value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} label={ t("menu.profile_agency.email") } variant="outlined" />
                        </Grid>
                        <Grid item>
                            <MuiPhoneNumber
                                disabled={quotation_code === "visiteurs"}
                                defaultCountry={user.lang !== null ? (user.lang !== "en" ? user.lang.split('-')[0] : "gb") : (language.value !== "en" ? language.value : "gb")  }
                                value={phoneNumber}
                                className={classes.inputBorder}
                                onChange={handleLeadPhoneNumber}
                                variant="outlined"
                                disableAreaCodes
                                fullWidth
                                label={ t("menu.profile.phone_number") }
                            />
                        </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container direction="row" spacing={4} style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={12}><Typography variant={"h6"}>{ t("menu.profile.address") }</Typography></Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField className={classes.inputBorder} disabled={quotation_code === "visiteurs"} fullWidth value={street} onChange={(e) => setStreet(e.target.value)} label={ t("menu.profile.address") } variant="outlined" />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField className={classes.inputBorder} disabled={quotation_code === "visiteurs"} fullWidth value={city} onChange={(e) => setCity(e.target.value)} label={ t("menu.profile.city") } variant="outlined" />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField className={classes.inputBorder} disabled={quotation_code === "visiteurs"} fullWidth value={zipCode} onChange={(e) => setZipCode(e.target.value)} label={ t("menu.profile.postal_code") } variant="outlined" />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField className={classes.inputBorder} disabled={quotation_code === "visiteurs"} fullWidth value={state} onChange={(e) => setState(e.target.value)} label={ t("menu.profile.country") } variant="outlined" />
                        </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container direction="column" spacing={4} style={{ marginTop: 10 }}>
                        <Grid item>
                            <TextField className={classes.inputBorder} disabled={quotation_code === "visiteurs"} fullWidth value={siret} onChange={(e) => setSiret(e.target.value)} label={ t("menu.profile_agency.siret") } variant="outlined" />
                        </Grid>
                        <Grid item>
                            <TextField className={classes.inputBorder} disabled={quotation_code === "visiteurs"} fullWidth value={vatNumber} onChange={(e) => setVatNumber(e.target.value)} label={ t("menu.profile_agency.vat_number") } variant="outlined" />
                        </Grid>
                    </Grid>
                    {
                        network !== null && (
                            <Fragment>
                                <Divider/>
                                <Grid container direction="column" spacing={4} style={{ marginTop: 10 }}>
                                    <Grid item><Typography variant={"h6"}>{ t("menu.profile_agency.network") }</Typography></Grid>
                                    <Grid item><Typography variant={"body1"} className={classes.italic}>{ network !== null ? network : t("menu.profile_agency.no_network") }</Typography></Grid>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
                <Grid item xs={12} className={!downDesktop ? classes.footer : classes.footerMobile}>
                    <Grid container direction="row" justify={"flex-end"} spacing={2} style={{ width: "100%" }}>
                        <Grid item>
                            <Button onClick={reset}>{t("accommodation.cancel")}</Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={save} className={classes.orangeButton} variant="contained">{t("header.save")}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
export default React.memo(MaterialAgencyProfile);
