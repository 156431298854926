// Dependencies
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import React, { Fragment, useState } from 'react';
// Core
import Step from '@material-ui/core/Step';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';
// Icons
import Work from '@material-ui/icons/Work';
import Check from '@material-ui/icons/Check';
import { Flight } from '@mui/icons-material';
import { Divider } from '@mui/material';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles({
    smallText: {
        fontSize: 10
    },
    firstRow: {
        // backgroundColor: '#daf6ff',
        margin: 0
    },
    secondRow: {
        margin: 0
    },
    relative: {
        position: 'relative',
        textAlign: 'center'
    },
    noBaggage: {
        color: 'gray',
        fontSize: 48,
    },
    baggage: {
        color: `${main_color}`,
        fontSize: 48
    },
    baggageNumber: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white'
    },
    moreDetailContainer: {
        paddingBottom: 8
    },
    moreDetail: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    legSeparator: {
        border: '1px solid #784af4',
        borderRadius: 1,
        position: 'relative'
    },
    legFlightTime: {
        position: 'absolute',
        width: '100%',
        bottom: 0
    },
    legInfo: {
        position: 'absolute',
        width: '100%',
        top: 0
    },
    legContainer: {
        marginBottom: 8
    },
    stepLabel: {
        color: '#784af4',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    stepCircle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    alternativeLabel: {
        position: 'absolute',
        flex: '1 1 auto',
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
        borderColor: '#784af4',
        borderTopWidth: 3,
        borderRadius: 1,
    },
    active: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    line: {
        borderColor: '#784af4',
        borderTopWidth: 3,
        borderRadius: 1,
        borderTopStyle: 'solid',
        display: 'block',
        textAlign: 'center'
    },
    orange: {
        color: `${main_color}`,
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    bold: {
        fontWeight: 'bold'
    }
});

const CustomStepIcon = () => {
    const classes = useStyles();
    return (
        <div className={ classes.stepLabel }>
            <div className={ classes.stepCircle }/>
        </div>
    );
};

const CustomConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    line: {
        borderColor: '#784af4',
        borderTopWidth: 3,
        borderRadius: 1
    }
})(StepConnector);

const SecondCustomConnector = () => {
    const classes = useStyles();
    return (
        <div className={ classes.alternativeLabel }>
            <span className={ classes.line }/>
        </div>
    )
};

type FlightCardOutboundProps = {
    outbound: any,
    index_outbound: number,
    outbounds_length: number
}

const FlightCardOutbound = (props: FlightCardOutboundProps) => {
    const {outbound, index_outbound, outbounds_length} = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [showMoreDetail, setShowMoreDetail] = useState<boolean>(false);
    let is_nightly = false;
    outbound.legs.map(leg => {
        if (leg.is_nightly) {
            is_nightly = true;
        }
    });
    let outbound_duration_min = parseInt(outbound.flight_duration/60);
    let outbound_duration = (parseInt(outbound_duration_min / 60) < 10 ? "0" + parseInt(outbound_duration_min / 60) : parseInt(outbound_duration_min / 60))  + 'h' + ((outbound_duration_min % 60 >= 10) ? (outbound_duration_min % 60) :  "0" + (outbound_duration_min % 60)) + 'min';

    const toggleMoreDetail = () => {
        setShowMoreDetail(!showMoreDetail);
    };
    return (
        <Fragment>
            <Grid className={ classes.firstRow } container item xs={ 12 } justify={ 'space-between' } spacing={ 2 } alignItems={ 'center' }>
                {/*<Grid item xs={ 2 }>
                    <Typography>{ t('global.flight') } { index_outbound + 1 }</Typography>
                </Grid> */}
                {
                    (index_outbound === 0 || outbounds_length === 0) &&
                    <Grid item xs={ 1 }>
                        <Flight className={classes.orange} style={{verticalAlign: "sub", marginRight: 10}}/>
                    </Grid>
                }
                {
                    index_outbound !== 0 &&
                    <Grid item xs={ 1 }/>
                }
                <Grid item xs container justify={ 'space-between' }>
                    <Grid item>
                        <Typography align={ 'center' } className={`${classes.genericText} ${classes.bold}`}>{ outbound.legs[0].origin.iata_city.name }</Typography>
                    </Grid>
                    <Grid item>
                        <Typography align={ 'center' } className={`${classes.genericText} ${classes.bold}`}>&#10142;</Typography>
                    </Grid>
                    <Grid item>
                        <Typography align={ 'center' } className={`${classes.genericText} ${classes.bold}`}>{ outbound.legs[outbound.legs.length - 1].destination.iata_city.name }</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={ 2 }>
                    <Typography align={ 'center' } className={`${classes.genericText} ${classes.bold}`}>{ outbound.legs[0].class_name }</Typography>
                </Grid>
                <Grid item className={ classes.relative }>
                    <Work className={ outbound.legs[0].baggage.length === 0 ? classes.noBaggage : classes.baggage }/>
                    <Typography className={ classes.baggageNumber }>{ outbound.legs[0].baggage.length }</Typography>
                </Grid>
                {
                    outbounds_length !== 0 && index_outbound !== outbounds_length - 1 &&
                    <Grid item xs={ 12 }>
                        <Divider component={'div'} sx={{marginRight: '7%', marginLeft: '7%'}}/>
                    </Grid>
                }
            </Grid>
            {/* <Grid className={ classes.secondRow } container item xs={ 12 } justify={ 'space-between' } spacing={ 2 } alignItems={ 'center' }>
                <Grid item xs={ 2 }>
                    <img src={ `https://storagefacilitatrip.blob.core.windows.net/iata-company/${outbound.legs[0].marketing_airline.airline_code}.png` } alt={ 'company logo' } width={ '100%' }/>
                </Grid>
                <Grid item xs={ 2 }>
                    <Typography align={ 'center' }>{ outbound.legs[0].marketing_airline.airline_code }-{ outbound.legs[0].flight_number }</Typography>
                    <Typography align={ 'center' } className={ classes.smallText }>{ t('cart-material.operated-by') } : { outbound.legs[0].operating_airline.commercial_name }</Typography>
                </Grid>
                <Grid item xs container justify={ 'space-between' }>
                    <Grid item>
                        <Typography align={ 'center' }>{ moment.utc(outbound.legs[0].departure_time).format('HH[h]mm') }</Typography>
                        <Typography align={ 'center' }>{ outbound.legs[0].origin.airport_code }</Typography>
                    </Grid>
                    <Grid item>
                        <Typography align={ 'center' } style={{ fontSize: 12 }} >{ outbound_duration }</Typography>
                        <Typography align={ 'center' } style={{ fontSize: 12 }} >{ is_nightly ? t('flight_search.night_flight') : t('flight_search.day_flight') }</Typography>
                    </Grid>
                    <Grid item>
                        <Typography align={ 'center' }>{ moment.utc(outbound.legs[outbound.legs.length - 1].arrival_time).format('HH[h]mm') }</Typography>
                        <Typography align={ 'center' }>{ outbound.legs[outbound.legs.length - 1].destination.airport_code }</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={ 2 }>
                    <Typography align={ 'center' }>{ outbound.legs.length === 1 ? t<string>('flight_search.direct_flight') : outbound.legs.length === 2 ? `1 ${t<string>('flight_search.stopover')}` : `${outbound.legs.length} ${t<string>('flight_search.stopovers')}` }</Typography>
                </Grid>
                <Grid className={ classes.relative } item xs={ 2 }> */}
                    {
                        /*
                    <Typography>{ outbound.legs[0].aircraft.commercial_name }</Typography>
                         */
                    }
                    {/* <Work className={ outbound.legs[0].baggage.length === 0 ? classes.noBaggage : classes.baggage }/>
                    <Typography className={ classes.baggageNumber }>{ outbound.legs[0].baggage.length }</Typography>
                </Grid>
            </Grid>
            <Grid className={ classes.moreDetailContainer } item xs={ 12 }>
                <Typography className={ classes.moreDetail } align={ 'center' } onClick={ toggleMoreDetail }>{!showMoreDetail ? t('cart-material.show-more-details') : t('cart-material.show-less-details')}</Typography>
            </Grid> */}
            {
                // showMoreDetail && outbound.legs.map((leg, index_leg) => (
                //     <Fragment key={index_leg}>
                //         {
                //             index_leg > 0 &&
                //             <Grid item xs={ 12 }>
                //                 {
                //                     leg.origin !== null ? (
                //                         <Typography align={ 'center' }>
                //                             { t<string>("flight_search.change_airport") } <strong>{ leg.origin.iata_city.international_name } - { leg.origin.name !== undefined ? leg.origin.name : leg.origin.international_name } ({ leg.origin.airport_code })</strong> { t<string>("flight_search.stopover_duration") } <strong>{ moment.utc(outbound.stopover_duration[index_leg - 1]).format('HH[h]mm') }</strong>
                //                         </Typography>
                //                     ) : (
                //                         <Typography align={ 'center' }>
                //                             { t<string>("flight_search.change_train") } <strong>{ leg.origin_station.iata_city.international_name } - { leg.origin_station.international_name } ({ leg.origin_station.station_code })</strong> { t<string>("flight_search.stopover_duration") } <strong>{ moment.utc(outbound.stopover_duration[index_leg - 1]).format('HH[h]mm') }</strong>
                //                         </Typography>
                //                     )
                //                 }
                //                 <br/>
                //             </Grid>
                //         }
                //         <Grid className={ classes.legContainer } container item xs={ 12 } justify={ 'space-between' } alignItems={ 'center' } spacing={ 2 }>
                //             <Grid item xs={ 2 }>
                //                 <Typography align={ 'center' }><strong>{ leg.origin.name }</strong></Typography>
                //                 <Typography align={ 'center' }>{ moment.utc(leg.departure_time).format('HH[h]mm') }</Typography>
                //             </Grid>
                //             <Grid item>
                //                 <div className={ classes.stepLabel }>
                //                     <div className={ classes.stepCircle }/>
                //                 </div>
                //             </Grid>
                //             <Grid item xs>
                //                 <div className={ classes.legSeparator }>
                //                     <Typography className={ classes.legFlightTime } align={ 'center' }>{ `${Math.floor(leg.flight_time / 60)}h${leg.flight_time % 60}` }</Typography>
                //                     {
                //                         leg.aircraft !== null && (
                //                             <Typography className={ classes.legInfo } align={ 'center' }>{ leg.aircraft.commercial_name }</Typography>
                //                         )
                //                     }
                //                 </div>
                //             </Grid>
                //             <Grid item>
                //                 <div className={ classes.stepLabel }>
                //                     <div className={ classes.stepCircle }/>
                //                 </div>
                //             </Grid>
                //             <Grid item xs={ 2 }>
                //                 <Typography align={ 'center' }><strong>{ leg.destination.name }</strong></Typography>
                //                 <Typography align={ 'center' }>{ moment.utc(leg.arrival_time).format('HH[h]mm') }</Typography>
                //             </Grid>
                //         </Grid>
                //     </Fragment>
                // ))
            }
        </Fragment>
    );
};

export default FlightCardOutbound;
