import moment from "moment";

export default (
    flight_list,
    airports,
    show_all_airports,
    airlines,
    show_all_airlines,
    alliances,
    show_all_alliances,
    stop_over_active,
    show_all_stop_over,
    filter_min_price,
    filter_max_price,
    is_day_flight,
    is_night_flight,
    refundable,
    not_refundable,
    show_all_stop_over_airport,
    stopover_airport,
    filter_time,
    checked_luggage,
    filter_max_duration,
    filter_min_duration,
    stations,
    stopover_duration,
    providers,
    show_all_providers,
    private_price,
    public_price,
    change_airport,
    flight_source_gds,
    flight_source_ndc
) => {
    console.log('filter_time:', filter_time);
    let list = flight_list.filter((flight) => {
        if (show_all_stop_over) {
            return (true);
        } 
        let flight_displayed_list = [];
        flight.outbounds.map((segment, segment_index) => {
            let segment_displayed = false;
            if (stop_over_active[segment_index].none === true && segment.legs.length === 1) {
                segment_displayed = true;
            }
            if (stop_over_active[segment_index].one === true && segment.legs.length === 2) {
                segment_displayed = true;
            }
            if (stop_over_active[segment_index].two === true && segment.legs.length === 3) {
                segment_displayed = true;
            }
            if (stop_over_active[segment_index].more === true && segment.legs.length > 3) {
                segment_displayed = true;
            }

            // display segment if no filter is active
            if (stop_over_active[segment_index].none !== true && stop_over_active[segment_index].one !== true && stop_over_active[segment_index].two !== true && stop_over_active[segment_index].more !== true) {
                segment_displayed = true;
            }
            flight_displayed_list.push(segment_displayed);
        });
        let displayed = true;

        flight_displayed_list.map((segment_displayed) => {
            displayed = displayed && segment_displayed;
        });
        return (displayed);
    }).filter((flight) => {
        return (((flight.total_price) >= filter_min_price) && ((flight.total_price) <= filter_max_price));
    }).filter((flight) => {
        if (show_all_alliances) {
            return (true);
        } 
        let displayed = false;
        for (let i = 0; i < flight.outbounds.length; i++) {
            for (let j = 0; j < flight.outbounds[i].legs.length; j++) {
                if (flight.outbounds[i].legs[j].alliance !== undefined) {
                    displayed = true;
                }
            }
        }
        if (displayed === false) {
            return displayed;
        } 
        displayed = false;
        for (let i = 0; i < flight.outbounds.length; i++) {
            for (let j = 0; j < flight.outbounds[i].legs.length; j++) {
                for (let k = 0; k < alliances.length; k++) {
                    if (flight.outbounds[i].legs[j].alliance !== undefined && flight.outbounds[i].legs[j].alliance.name === alliances[k].name && alliances[k].displayed) {
                        displayed = true;
                    }
                }
            }
        }
        return (displayed);
    }).filter((flight) => {
        if (show_all_airlines) {
            return (true);
        } 
        let is_displayed = false;
        for (let i = 0; i < flight.outbounds.length; i++) {
            for (let j = 0; j < flight.outbounds[i].legs.length; j++) {
                let marketing_airline = [];
                airlines.map((airline) => {
                    if (airline.id === flight.outbounds[i].legs[j].marketing_airline) {
                        marketing_airline = airline;
                    }
                });
                if (marketing_airline.displayed) {
                    is_displayed = true;
                }
            }
        }
        return (is_displayed);
    }).filter((flight) => {
        let displayed = false;
        if (show_all_stop_over_airport) {
            displayed = true;
        } else {
            let check = 0;
            stopover_airport.map((outbound) => {
                Object.keys(outbound).map((airport) => {
                    if (outbound[airport].displayed) {
                        check += 1;
                    }
                });
            });
            let tab = [];
            flight.outbounds.map((outbound, outbound_index) => {
                outbound.legs.map((leg) => {
                    if (stopover_airport[outbound_index][leg.origin_airport !== undefined ? leg.origin_airport : leg.origin_station] !== undefined && stopover_airport[outbound_index][leg.origin_airport ? leg.origin_airport : leg.origin_station].displayed) {
                        tab.push(true);
                    }
                });
                if (tab.length === check) {
                    displayed = true;
                }
            });
        }
        return (displayed);
    }).filter((flight) => {
        if (show_all_airports) {
            return true;
        }
        for (let i = 0; i < flight.outbounds.length; i++) {
            for (let j = 0; j < flight.outbounds[i].legs.length; j++) {
                if (flight.outbounds[i].legs[j].origin_airport !== undefined || flight.outbounds[i].legs[j].destination_airport !== undefined) {
                    if (airports[flight.outbounds[i].legs[j].origin_airport].displayed || airports[flight.outbounds[i].legs[j].destination_airport].displayed) {
                        return true;
                    }
                }
                if (flight.outbounds[i].legs[j].origin_station !== undefined || flight.outbounds[i].legs[j].destination_station !== undefined) {
                    if (stations[flight.outbounds[i].legs[j].origin_station].displayed || stations[flight.outbounds[i].legs[j].destination_station].displayed) {
                        return true;
                    }
                }
            }
        }
        return false;
    }).filter((flight) => {
        if ((is_day_flight && is_night_flight) || (!is_day_flight && !is_night_flight)) {
            return true;
        } else if (is_day_flight) {
            for (let i = 0; i < flight.outbounds.length; i++) {
                if (flight.outbounds[i].is_nightly) {
                    return false;
                }
            }
            return true;
        } 
        for (let i = 0; i < flight.outbounds.length; i++) {
            if (!flight.outbounds[i].is_nightly) {
                return false;
            }
        }
        return true;
    }).filter((flight) => {
        if ((refundable && not_refundable) || (!refundable && !not_refundable)) {
            return true;
        } else if (refundable && flight.refundable) {
            return true;
        } else if (not_refundable && !flight.refundable) {
            return true;
        }
        return false;
    })
        .filter((flight) => {
            let tmp = true;
            flight.outbounds.map((outbound, outbound_index) => {
                if (moment.duration(moment.utc(outbound.legs[0].departure_datetime_lt).format("HH:mm")).asMinutes() < filter_time[outbound_index].departure[0] * 60 ||
                    moment.duration(moment.utc(outbound.legs[0].departure_datetime_lt).format("HH:mm")).asMinutes() > filter_time[outbound_index].departure[1] * 60) {
                    tmp = false;
                }
            });
            return tmp;
        })
        .filter((flight) => {
            let tmp = true;
            flight.outbounds.map((outbound, outbound_index) => {
                let last_leg = outbound.legs.length - 1;
                if (moment.duration(moment.utc(outbound.legs[last_leg].arrival_datetime_lt).format("HH:mm")).asMinutes() < filter_time[outbound_index].arrival[0] * 60 ||
                    moment.duration(moment.utc(outbound.legs[last_leg].arrival_datetime_lt).format("HH:mm")).asMinutes() > filter_time[outbound_index].arrival[1] * 60) {
                    tmp = false;
                }
            });
            return tmp;
        })
        .filter((flight) => {
            if (!checked_luggage.disabled) {
                let flight_displayed_list = [];
                flight.outbounds.map((segment) => {
                    let segment_displayed_list = [];
                    segment.legs.map((leg) => {
                        let leg_displayed = false;
                        if (leg.baggage !== undefined) {
                            let temp_check = 0;
                            leg.baggage.map((luggage) => {
                                if (!luggage.is_carry_on) {
                                    temp_check += luggage.quantity;
                                }
                            });
                            if (checked_luggage.zero && temp_check === 0) {
                                leg_displayed = true;
                            }
                            if (checked_luggage.included && temp_check > 0) {
                                leg_displayed = true;
                            }
                            if (checked_luggage.one && temp_check === 1) {
                                leg_displayed = true;
                            }
                            if (checked_luggage.two && temp_check === 2) {
                                leg_displayed = true;
                            }
                        } else if (leg.baggage_allowance.number_of_pieces !== null) {
                            if (checked_luggage.zero && leg.baggage_allowance.number_of_pieces === 0) {
                                leg_displayed = true;
                            }
                            if (checked_luggage.included && leg.baggage_allowance.number_of_pieces > 0) {
                                leg_displayed = true;
                            }
                            if (checked_luggage.one && leg.baggage_allowance.number_of_pieces === 1) {
                                leg_displayed = true;
                            }
                            if (checked_luggage.two && leg.baggage_allowance.number_of_pieces === 2) {
                                leg_displayed = true;
                            }
                        }
                        segment_displayed_list.push(leg_displayed);
                    });
                    let segment_displayed = true;
                    segment_displayed_list.map((leg_displayed) => {
                        segment_displayed = segment_displayed && leg_displayed;
                    });
                    flight_displayed_list.push(segment_displayed);
                });
                let displayed = true;
                flight_displayed_list.map((segment_displayed) => {
                    displayed = displayed && segment_displayed;
                });
                return displayed;
            } 
            return true;
        })
        .filter((flight) => {
            let displayed = true;
            for (let i = 0; i < flight.outbounds.length; i++) {
                if (Math.ceil(flight.outbounds[i].flight_duration / 60) < filter_min_duration || Math.floor(flight.outbounds[i].flight_duration / 60) > filter_max_duration) {
                    displayed = false;
                    break;
                }
            }
            return displayed;
        }).filter((flight) => {
            let displayed = false;
            for (let i = 0; i < flight.outbounds.length; i++) {
                if (stopover_duration[i].two_hours && stopover_duration[i].two_ten_hours && stopover_duration[i].ten_hours) {
                    displayed = true;
                } else if (!stopover_duration[i].two_hours && !stopover_duration[i].two_ten_hours && !stopover_duration[i].ten_hours) {
                    displayed = true;
                } else {
                    for (let j = 0; j < flight.outbounds[i].legs.length - 1; j++) {
                        let total_minutes = moment.utc(flight.outbounds[i].legs[j + 1].departure_datetime_lt).diff(moment.utc(flight.outbounds[i].legs[j].arrival_datetime_lt), 'minutes');
                        if (stopover_duration[i].two_hours && !stopover_duration[i].two_ten_hours && !stopover_duration[i].ten_hours) {
                            displayed = (total_minutes >= 45 && total_minutes < 120) ? true : false;
                        } else if (!stopover_duration[i].two_hours && stopover_duration[i].two_ten_hours && !stopover_duration[i].ten_hours) {
                            displayed = (total_minutes >= 120 && total_minutes < 600) ? true : false;
                        } else if (!stopover_duration[i].two_hours && !stopover_duration[i].two_ten_hours && stopover_duration[i].ten_hours) {
                            displayed = total_minutes >= 600 ? true : false;
                        } else if (stopover_duration[i].two_hours && stopover_duration[i].two_ten_hours && !stopover_duration[i].ten_hours) {
                            displayed = (total_minutes >= 45 && total_minutes < 600) ? true : false;
                        } else if (stopover_duration[i].two_hours && !stopover_duration[i].two_ten_hours && stopover_duration[i].ten_hours) {
                            displayed = (total_minutes >= 45 && total_minutes < 120 && total_minutes >= 600) ? true : false;
                        } else if (!stopover_duration[i].two_hours && stopover_duration[i].two_ten_hours && stopover_duration[i].ten_hours) {
                            displayed = (total_minutes >= 120 && total_minutes >= 600) ? true : false;
                        } else {
                            displayed = false;
                        }
                    }
                    if (!displayed) {
                        break;
                    }
                }
            }
            return displayed;
        }).filter((flight) => {
            let displayed = false;
            if (show_all_providers) {
                displayed = true;
            }
            for (let i = 0; i < providers.length; i++) {
                if (providers[i].displayed && providers[i].id === flight.provider_id) {
                    displayed = true;
                    break;
                }
            }
            return displayed;
        }).filter((flight) => {
            if (public_price && !private_price) {
                return flight.fare_type === "public" || flight.fare_type === "all" || flight.fare_type === "PUBLISHED";
            }
            if (private_price && !public_price) {
                return flight.fare_type === "private" || flight.fare_type === "all" || flight.fare_type === "NEGOTIATED";
            }

            return true;
        }).filter((flight) => {
            let displayed = true;
            if (!change_airport) {
                flight.outbounds.map((outbound) => {
                    outbound.legs.map((leg, leg_index) => {
                        if (leg_index + 1 < outbound.legs.length) {
                            let first_stop = null;
                            let next_stop = null;
                        
                            if (leg.destination_airport !== undefined) {
                                first_stop = airports[leg.destination_airport];
                            } else {
                                first_stop = stations[leg.destination_station];
                            }
            
                            if (outbound.legs[leg_index + 1].origin_airport !== undefined) {
                                next_stop = airports[outbound.legs[leg_index + 1].origin_airport];
                            } else {
                                next_stop = stations[outbound.legs[leg_index + 1].origin_station];
                            }
                            if (first_stop.code !== next_stop.code) {
                                displayed = false;
                            }
                        }
                    });
                });
            }
            return displayed;
        }).filter((flight) => {
            if (flight_source_gds && !flight_source_ndc) {
                return flight.flight_source === "GDS";
            }
            if (flight_source_ndc && !flight_source_gds) {
                return flight.flight_source === "NDC";
            }
            return true;
        });
    return list;
};
