import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Divider,
    Stack,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography
} from "@mui/material";
import GetProvider from "../Common/Functions/GetProvider";
import { ManualProductTransferCart } from "./objects/manualProductTransferCart";
import { TransferCart } from "./objects/transferCart";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    transfers: ({
        type: 'normal',
        transfer: TransferCart
    } | {
        type: 'manual',
        transfer: ManualProductTransferCart
    })[],
} & Pick<TooltipProps, 'children'>

export function ItineraryStepItemTransferTooltip(props: Props): JSX.Element {
    const { t } = useTranslation();
    const providers = useSelector((state: AppState) => state.trip.providers);
    const user = useSelector((state: AppState) => state.user.user);

    return (
        <LightTooltip
            disableHoverListener={props.transfers.length === 0}
            title={
                props.transfers.map((item) => {
                    let provider = GetProvider(providers, item.transfer.provider ?? 0);
                    const hasProvider = provider && (user?.client_full?.type !== 2 || JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code === 'verdie');
                    return (
                        <Box key={item.transfer.id} sx={{ padding: 2.5 }}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-end"
                                sx={{ marginBottom: 1.5 }}
                            >
                                <Box sx={{ width: hasProvider ? '60%' : '100%' }}>
                                    <Typography variant="h6" color="#888">
                                        {
                                            item.type === 'manual' ?
                                                item.transfer.name :
                                                item.transfer.custom_product?.title
                                        }
                                        {
                                            item.transfer.is_optional &&
                                            <Box component="span" sx={{ color: '#E6592F' }}>
                                                {t<string>('cart.optional_product')}
                                            </Box>
                                        }
                                    </Typography>
                                </Box>
                                {
                                    hasProvider &&
                                    <Box sx={{ width: '15%' }}>
                                        <img
                                            src={
                                                provider?.provider.logo?.thumbnail_little ?? provider?.provider.logo?.url ?? ''
                                            }
                                            alt={provider?.provider.name}
                                            width="100%"
                                            height="auto"
                                        />
                                    </Box>
                                }
                            </Stack>
                            <Divider sx={{ marginBottom: 1 }} />
                            <Text>
                                {
                                    item.type === 'manual' ?
                                        item.transfer.description :
                                        item.transfer.custom_product?.long_description ??
                                        item.transfer.custom_product?.short_description
                                }
                            </Text>
                        </Box>
                    );
                })
            }
            arrow
            PopperProps={{
                disablePortal: true
            }}
        >
            {props.children}
        </LightTooltip>
    );
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        maxWidth: 420,
        minWidth: 300
    },
    [`& .${tooltipClasses.arrow}`]: {
        "color": theme.palette.common.white,
        '&:before': {
            border: '1px solid #ccc'
        }
    }
}));

const Text = styled(Typography)(() => ({
    fontSize: 10
})) as typeof Typography;
