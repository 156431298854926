import axios from "axios";
import { CreatePageOptions } from "../objects/createPageOptions";
import { Voucher } from "../objects/voucher";
import { Template } from "../utils/handlebars/roadbook.handlebars";
import { TripDoc } from "../objects/tripDoc";

type Options = {
    docs: TripDoc[]
}

export async function createVouchersContent(options: CreatePageOptions & Options): Promise<Parameters<Template>[0]['contents'][0][]> {
    const vouchers = [...await makeVouchersListRequest({ tripId: options.trip.id, version: options.tripVersion.id })].filter((item) => {
        const docs = options.docs.filter((doc) => {
            return doc.product && item.product_ids.includes(doc.product);
        });
        return docs.every((item) => item.display_voucher);
    }).sort((a, b) => {
        const aDate = window.moment.utc(a.start_date);
        const bDate = window.moment.utc(b.start_date);
        return aDate.isBefore(bDate) ?
            -1 :
            1;
    });
    const vouchersHtml = await Promise.all(
        vouchers.map(async (item) => {
            const response = await axios.get<string>(
                `${API_HREF}${item.url}`
            );
            return {
                ...item,
                html: response.data
            };
        })
    );
    const vouchersContents = vouchersHtml.map((item) => {
        const document = new DOMParser().parseFromString(item.html, 'text/html');
        let type = '';
        
        switch (document.title) {
            case 'Voucher activité': type = 'activity'; break;
            case 'Voucher Flight': type = 'flight'; break;
            case 'Voucher Hotel': type = 'accommodation'; break;
            case 'Voucher Transfer': type = 'transfer'; break;
            case 'Voucher Car': type = 'car'; break;
        }

        return {
            type,
            name: item.name,
            content: document.body.innerHTML
        };
    });
    return vouchersContents.map((item, index): Parameters<Template>[0]['contents'][number] => {
        return {
            type: 'voucher',
            anchor: `voucher-${item.name}`,
            summaryTitle: options.title,
            title: options.title,
            productType: item.type,
            content: item.content,
            showInSummary: index === 0
        };
    });
}

type RequestOptions = {
    tripId: number,
    version: number
}

async function makeVouchersListRequest(options: RequestOptions): Promise<Voucher[]> {
    const response = await axios.get<Voucher[]>(
        `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/voucher/get_voucher_list/`
    );
    return response.data;
}
