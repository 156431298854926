const initial_state = {
    user: null,
    client: null,
    is_connected: false,
    modal_connection: false,
    token: null,
    token_pwd: null,
    user_id: null,
    step: 1,
    user_list: null,
    permissions: null,
    locales: [],
    current_locale: "",
    main_locale: null,
    previous_locale: null,
    translate_locale: [],
    activated_locales: []
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.user !== undefined) {
                Object.keys(action.payload.user).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.user[key];
                    }
                });
            }
            return new_state;
        }
        case "USER_SET_USER": {
            new_state.user = action.payload.user;
            return new_state;
        }
        case "USER_SET_PERMISSIONS": {
            new_state.permissions = action.payload.permissions;
            return new_state;
        }        
        case "USER_SET_USER_LIST": {
            new_state.user_list = action.payload.user_list;
            return new_state;
        }
        case "USER_SET_USER_AND_CONNECTION": {
            new_state.is_connected = action.payload.is_connected;
            new_state.user = action.payload.user;
            return new_state;
        }
        case "USER_SET_CLIENT": {
            new_state.client = action.payload.client;
            return new_state;
        }
        case "USER_SET_TOKEN": {
            new_state.token = action.payload.token;
            return new_state;
        }
        case "USER_SET_USER_CONNECTION": {
            new_state.is_connected = action.payload.is_connected;
            return new_state;
        }
        case "USER_TOGGLE_MODAL_CONNECTION": {
            new_state.modal_connection = action.payload.modal_connection;
            new_state.step = action.payload.step;
            return new_state;
        }
        case "USER_TOGGLE_MODAL_RESET_PWD": {
            new_state.modal_connection = action.payload.modal_connection;
            new_state.step = action.payload.step;
            new_state.user_id = action.payload.user_id;
            new_state.token_pwd = action.payload.token_pwd;
            return new_state;
        }
        case "USER_SET_CONNECTION": {
            new_state.modal_connection = action.payload.modal_connection;
            new_state.is_connected = action.payload.is_connected;
            new_state.token = action.payload.token;
            new_state.user = action.payload.user;
            return new_state;
        }
        case "USER_SET_LOCALES": {
            new_state.locales = action.payload.locales;
            return new_state;
        }
        case "USER_SET_CURRENT_LOCALE": {
            let translate_locale = state.translate_locale;
            console.log('action.payload:', action.payload);
            const index = translate_locale.indexOf(action.payload);
            if (index !== -1) {
                translate_locale.splice(index, 1);
            }
            return {...state, current_locale: action.payload, previous_locale: state.current_locale, translate_locale: translate_locale};
        }
        case "USER_SET_MAIN_LOCALE": {
            return {...state, main_locale: action.payload, current_locale: action.payload.id}
        }
        case "USER_SET_TRANSLATE_LOCALE": {
            return {...state, translate_locale: action.payload};
        }
        case "USER_SET_ACTIVATED_LOCALES": {
            return { ...state, activated_locales: action.payload };
        }
    }
    return new_state;
}