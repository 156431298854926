import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Slide from '@material-ui/core/Slide';


const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A",
        letterSpacing: 1.25
    },
    fontSize12:{
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    fontWeight500:{
        fontWeight: 500
    },
    fontWeight900:{
        fontWeight: 900
    },
    upperCase:{
        textTransform: "uppercase",
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TripListMobileInfo = (( {openUserInfo, setOpenUserInfo, last_editor} ) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(768));

    const trip_info = useSelector(store => store.menu.trip_info);
    const [currentVersion, setCurrentVersion] = useState(null);

    useEffect(() => {
        trip_info.data.map((version) => {
            if (version.id === trip_info.current_version){
                setCurrentVersion(version);
            }
        })
    }, [trip_info])

    return(
        <Dialog open={openUserInfo} fullScreen={ mobile === true ? true : false} TransitionComponent={Transition} fullWidth maxWidth={"xs"}>
            <DialogTitle>
                <Grid container justify={ mobile === true ? "flex-start" : "space-between" } alignItems={ "center" }>
                    {
                        mobile && (
                            <Grid item style={{marginRight: "5%"}}>
                                <IconButton onClick={() => { setOpenUserInfo(false) }} size='small'> <NavigateBeforeIcon /> </IconButton>
                            </Grid>
                        )
                    }
                    <Grid item className={`${classes.genericText} ${classes.upperCase} ${classes.fontSize16} ${classes.fontWeight500}`}>
                            { t("menu.trip_list.user_info") }
                    </Grid>
                    {
                        !mobile && (
                            <Grid item>
                                <IconButton onClick={() => { setOpenUserInfo(false) }} size='small'> <CloseIcon style={{float: "right"}}/> </IconButton>
                            </Grid>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid>
                    <div className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize12}`}>
                        {t("menu.trip_list.follower")}
                    </div>
                    {
                        trip_info.user_to !== null && (
                            <div className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize12}`}>
                                {"TO : "} 
                                <span className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                    {trip_info.user_to.first_name + " " + trip_info.user_to.last_name}
                                </span>
                            </div>
                        )
                    }
                    {
                        trip_info.user_ta !== null && (
                            <div className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize12}`}>
                                {t("menu.trip_list.agency") + " "}
                                <span className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                    {trip_info.user_ta.first_name + " " + trip_info.user_ta.last_name}
                                </span>
                            </div>
                        )
                    }
                    {
                        JSON.parse(localStorage.getItem("config")).quotation_code !== "marcovasco" && trip_info.target_client !== null && (
                            <div className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                {trip_info.target_client.name}
                            </div>
                        )
                    }
                    {
                        JSON.parse(localStorage.getItem("config")).quotation_code !== "marcovasco" && trip_info.target_client !== null && trip_info.target_client.street && (
                            <div className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                {trip_info.target_client.street}
                            </div>
                        )
                    }
                    {
                        JSON.parse(localStorage.getItem("config")).quotation_code !== "marcovasco" && trip_info.target_client !== null && trip_info.target_client.phone_number && (
                            <div className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`}>
                                {trip_info.target_client.phone_number}
                            </div>
                        )
                    }
                </Grid>
                <Grid style={ mobile === true ? { paddingTop: "15%"} : { paddingTop: "5%" } }>
                     {
                        currentVersion !== null && currentVersion.created_date !== null && (
                            <div className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize12}`}>
                                {t("menu.created_date") + " : "}
                                <span className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                    {moment.utc(trip_info.created_date).format("L")}
                                </span>
                            </div>
                        )
                     }
                     {
                        currentVersion !== null && currentVersion.creator !== null && (
                            <div className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize12}`}>
                                {t("menu.created_by") + " : "}
                                <span className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                    {currentVersion.creator.first_name + " " + currentVersion.creator.last_name}
                                </span>
                            </div>
                        )
                     }
                </Grid>
                <Grid style={ mobile === true ? { paddingTop: "15%"} : { paddingTop: "5%" } }>
                    {
                        currentVersion !== null && currentVersion.modified_date !== null && (
                            <div className={`${classes.textRight} ${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize12}`}>
                                {t("menu.edition_day") + " : "}
                                <span className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12}`}>
                                    {moment.utc(currentVersion.modified_date).format("L")}
                                </span>
                            </div>
                        )
                    }
                    {
                        last_editor !== null && (
                            <div className={`${classes.textRight} ${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize12}`}>
                                {t("menu.edited_by") + " : "}
                                <span className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12}`}>
                                    {last_editor.first_name + " " + last_editor.last_name}
                                </span>
                            </div>
                        )
                     }
                </Grid>
            </DialogContent>
        </Dialog>
    )
})

export default React.memo(TripListMobileInfo);