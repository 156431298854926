import React from "react";
import {
    List,
    ListItem,
    Stack,
    styled
} from "@mui/material";
import ColorHash from "color-hash";
import { CartConstructionItineraryContentCopyModalStepsListDivider } from "./CartConstructionItineraryContentCopyModalStepsListDivider";
import { CartConstructionItineraryContentCopyModalStepsListAggregator } from "./CartConstructionItineraryContentCopyModalStepsListAggregator";
import { CartConstructionItineraryContentStepsListItem } from "./CartConstructionItineraryContentStepsListItem";
import { ItineraryContentStep } from "../Itinerary/objects/itineraryContentStep";
import { isContentStepEqual } from "./utils/isContentStepEqual";

type Props = {
    simulate: boolean,
    version: number,
    sourceVersion: number,
    targetVersion: number | null,
    steps: {[version: number]: ItineraryContentStep[]}
    inputs: {[version: number]: ItineraryContentStep[]},
    onChangeInputs: React.Dispatch<React.SetStateAction<{
        [version: number]: ItineraryContentStep[];
    }>>
}
const colorHash = new ColorHash();

export function CartConstructionItineraryContentAutomaticStepsList(props: Props): JSX.Element {
    const findMatchColor = (version: number, input: ItineraryContentStep) => {
        const inverseVersion = version === props.sourceVersion ? props.targetVersion : props.sourceVersion;
        const inverseInputs = props.inputs[inverseVersion ?? -1] ?? [];
        const match = inverseInputs.findIndex((item) => {
            return isContentStepEqual(item, input);
        }) >= 0;
        return match ?
            colorHash.hex(
                (input.mode === 'by-day' ? input.content.day.join(',') : 'step') + '-' +
                (
                    input.mode === 'by-day' ?
                        input.content.destinations?.map((item) => item.id).join('|') :
                        input.content.destination?.id
                )
            ) :
            null;
    };

    return (
        <List
            component="div"
            dense
        >
            {
                props.inputs[props.version]?.map((input, index, array) => {
                    const id = (
                        input.mode === 'by-day' ?
                            input.content.day.join(',') :
                            `step-${index + 1}`
                    ) + '-' + (
                        input.mode === 'by-day' ?
                            input.content.destinations?.map((item) => item.id).join('|') :
                            input.content.destination?.id
                    ) + '-' + props.version;
                    return (
                        <React.Fragment key={id}>
                            <Step matchColor={props.simulate ? findMatchColor(props.version, input) : null}>
                                <CartConstructionItineraryContentStepsListItem
                                    index={index}
                                    input={input}
                                />
                            </Step>
                            <ListItem>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    spacing={1}
                                    sx={{ width: '100%' }}
                                >
                                    <CartConstructionItineraryContentCopyModalStepsListDivider
                                        version={props.version}
                                        input={input}
                                        onChangeInputs={props.onChangeInputs}
                                    />
                                    {
                                        array[index + 1] &&
                                        <CartConstructionItineraryContentCopyModalStepsListAggregator
                                            version={props.version}
                                            index={index}
                                            input={input}
                                            inputs={array}
                                            onChangeInputs={props.onChangeInputs}
                                        />
                                    }
                                </Stack>
                            </ListItem>
                        </React.Fragment>
                    );
                })
            }
        </List>
    );
}

const Step = styled(
    ListItem,
    {
        shouldForwardProp(propName) {
            return propName !== 'matchColor';
        }
    }
)<{matchColor: string | null}>((props) => ({
    "width": '90%',
    "margin": 'auto',
    "border": '1px solid #cdcdcd',
    "backgroundColor": props.matchColor ?? undefined,
    "color": props.theme.palette.getContrastText(props.matchColor ?? '#fff'),
    '& *': {
        color: props.theme.palette.getContrastText(props.matchColor ?? '#fff')
    }
}));
