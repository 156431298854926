export default (time, unit, language, t) => {
    let minutes = "";

    if (unit === "seconds"){
        minutes = time / 60;
    }else if (unit === "minutes"){
        minutes = time;
    }

    let hours = Math.floor(minutes / 60);
    let minutes_left = minutes % 60;
    minutes_left = Math.floor(minutes_left);
    let string_result = "";

    if (hours < 10){
        hours = "0" + hours;
    }

    if (minutes_left < 10){
        minutes_left = "0" + minutes_left;
    }

    if (language === "fr"){
        string_result = hours + 'h' + minutes_left + " " + t("global.min");
    }else{
        string_result = hours + ':' + minutes_left + " " + t("global.min");
    }

    return string_result
}