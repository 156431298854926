import { AssistanceCart } from "../../Itinerary/objects/assistanceCart";
import { Picture } from "../../Menu/MaterialTripList/picture/objects/picture";

export function getAssistancePicture(assistance: AssistanceCart, source?: 'provider'): Picture[] {
    if (assistance.hide_pictures) {
        return [];
    }

    if (!source && (assistance.overload_pictures?.length ?? 0) > 0) {
        return assistance.overload_pictures ?? [];
    }

    return [];
}
