export function SetItineraryTypeData(itinerary_type_data) {
    return {
        type: "ITINERARY_TYPE_SET_ITINERARY_TYPE_DATA",
        payload: {
            itinerary_type_data: itinerary_type_data
        }
    };
}
export function SetItineraryTypeDetailsDataAndDayAndNight(itinerary_type_details_data, nights, days) {
    return {
        type: "ITINERARY_TYPE_SET_ITINERARY_TYPE_DETAILS_DATA",
        payload: {
            itinerary_type_details_data: itinerary_type_details_data, 
            nights: nights,
            days: days
        }
    };
}
export function SetItineraryTypeDetails(itinerary_type_details_data) {
    return {
        type: "ITINERARY_TYPE_SET_ITINERARY_TYPE_DETAILS_DATA_ONLY",
        payload: {
            itinerary_type_details_data: itinerary_type_details_data
        }
    };
}
export function SetHistorydest(dest_history) {
    return {
        type: "ITINERARY_TYPE_SET_HISTORY_DEST",
        payload: {
            dest_history: dest_history
        }
    };
}
export function AddHistorydest(new_dest) {
    return {
        type: "ITINERARY_TYPE_ADD_HISTORY_DEST",
        payload: {
            new_dest: new_dest
        }
    };
}
export function RemoveXHistorydest(index, itinerary_type_data) {
    return {
        type: "ITINERARY_TYPE_REMOVE_X_HISTORY_DEST",
        payload: {
            index: index, 
            itinerary_type_data: itinerary_type_data
        }
    };
}
export function SetSecondDestList(second_dest_list) {
    return {
        type: "ITINERARY_TYPE_SET_SECOND_DEST_LIST",
        payload: {
            second_dest_list: second_dest_list
        }
    };
}

export function SetManualCart(manual_cart) {
    return {
        type: "ITINERARY_TYPE_SET_MANUAL_CART",
        payload: {
            manual_cart: manual_cart
        }
    };
}
export function SetCarsCart(cars_cart) {
    return {
        type: "ITINERARY_TYPE_SET_CARS_CART",
        payload: {
            cars_cart: cars_cart
        }
    };
}
export function SetFlightCart(flight_cart) {
    return {
        type: "ITINERARY_TYPE_SET_FLIGHT_CART",
        payload: {
            flight_cart: flight_cart
        }
    };
}
export function SetHotelCart(hotel_cart) {
    return {
        type: "ITINERARY_TYPE_SET_HOTEL_CART",
        payload: {
            hotel_cart: hotel_cart
        }
    };
}

export function SetIti(itinerary) {
    return {
        type: "ITINERARY_TYPE_SET_ITINERARY",
        payload: {
            itinerary: itinerary
        }
    };
}

export function SetGuideList(guide_list) {
    return {
        type: "ITINERARY_TYPE_SET_GUIDE_LIST",
        payload: {
            guide_list: guide_list
        }
    };
}
export function SetItineraryTypeDetailsLoading(detail_is_loading) {
    return {
        type: "ITINERARY_TYPE_SET_ITINERARY_TYPE_DETAILS_LOADING",
        payload: {
            detail_is_loading: detail_is_loading
        }
    };
}
export function SetItineraryTypeLoading(ititype_is_loading) {
    return {
        type: "ITINERARY_TYPE_SET_ITINERARY_TYPE_LOADING",
        payload: {
            ititype_is_loading: ititype_is_loading
        }
    };
}