import React from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    CircularProgress,
    Stack,
    Tab,
    Tabs,
    styled,
    tabClasses,
    tabsClasses
} from '@mui/material';
import { useGoToBooking } from "./utils/goToBooking";

const CartStepper = ({ active_step, origin, router }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const fetchingTexts = useSelector((state) => state.cartConstruction.fetchingTexts);
    const recreatingTextInputs = useSelector((state) => state.cartConstruction.recreatingTextInputs);
    const savingTexts = useSelector((state) => state.cartConstruction.savingTexts);
    const autoloadingTexts = useSelector((state) => state.cartConstruction.autoloadingTexts);
    const goToBooking = useGoToBooking({ router });
    const quotation_code = JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code;
    const loading = fetchingTexts || recreatingTextInputs || savingTexts || autoloadingTexts;

    const onChange = async (value) => {
        dispatch({ type: 'CART_SET_STEP', payload: { step: value } });
        if (origin === 'CART') {
            router.push(`/${window.url_name}/apps/cart`);
        }

        if (value === 4 && user?.client_full?.type === 2) {
            goToBooking('selection');
        }
    };

    return (
        <>
            <Tabs
                value={active_step}
                textColor="inherit"
                onChange={(_, value) => onChange(value)}
                sx={{
                    "display": 'inline-flex',
                    "position": 'relative',
                    "color": '#000',
                    '&:after': {
                        content: '""',
                        width: '100%',
                        height: '1px',
                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                        position: 'absolute',
                        bottom: '1px',
                        zIndex: -1
                    },
                    [`.${tabsClasses.indicator}`]: {
                        backgroundColor: '#E6592F',
                        height: '3px'
                    }
                }}
            >
                <FatTab
                    value={1}
                    label={t('cart-material.cart-construction-tab-products')}
                />
                {
                
                    (quotation_code !== 'visiteurs' || user?.client_full?.type !== 2) && !['marcovasco'].includes(quotation_code) &&
                    <FatTab
                        value={2}
                        label={
                            loading ?
                                <>
                                    <Stack direction="row" columnGap={1}>
                                        <CircularProgress
                                            size="20px"
                                            color="inherit"
                                            sx={{
                                                color: '#000'
                                            }}
                                        />
                                        <span>
                                            {t('cart-material.cart-construction-tab-texts')}
                                        </span>
                                    </Stack>
                                </> :
                                t('cart-material.cart-construction-tab-texts')
                        }
                        disabled={loading}
                    />
                }
                {
                    !['marcovasco'].includes(quotation_code) &&
                    <FatTab
                        value={3}
                        label={
                            loading ?
                                <>
                                    <Stack direction="row" columnGap={1}>
                                        <CircularProgress
                                            size="20px"
                                            color="inherit"
                                            sx={{
                                                color: '#000'
                                            }}
                                        />
                                        <span>
                                            {t('cart-material.quotation') }
                                        </span>
                                    </Stack>
                                </> :
                                t('cart-material.quotation') 
                        }
                        disabled={loading}
                    />
                }
                <FatTab
                    value={4}
                    label={t('cart-material.cart-construction-tab-booking')}
                />
            </Tabs>
        </>
    );
};

const FatTab = styled(Tab)((props) => ({
    fontSize: props.theme.typography.body2.fontSize,
    textTransform: 'none',
    paddingLeft: props.theme.spacing(1),
    paddingRight: props.theme.spacing(1),
    paddingBottom: props.theme.spacing(0.5),
    fontWeight: 100,
    opacity: 1,
    [`&.${tabClasses.selected}`]: {
        fontWeight: 500
    }
}));

export default withRouter(CartStepper);
