import { Itinerary } from "../../../Itinerary/objects/itinerary";
import StepsCarIcon from "../../../../Img/noun-car-2558234-15.png?roadbook";
import StepsAnimalIcon from "../../../../Img/bedroom_baby_FILL0_wght400_GRAD0_opsz48.png?roadbook";
import StepsBicycleIcon from "../../../../Img/directions_bike_FILL0_wght400_GRAD0_opsz48.png?roadbook";
import StepsBusIcon from "../../../../Img/directions_bus_FILL0_wght400_GRAD0_opsz48.png?roadbook";
import StepsCableCarIcon from "../../../../Img/tram_FILL0_wght400_GRAD0_opsz48.png?roadbook";
import StepsFerryIcon from "../../../../Img/directions_boat_FILL0_wght400_GRAD0_opsz48.png?roadbook";
import StepsFootIcon from "../../../../Img/directions_walk_FILL0_wght400_GRAD0_opsz48.png?roadbook";
import StepsHelicopterIcon from "../../../../Img/helicopter_FILL0_wght400_GRAD0_opsz48.png?roadbook";
import StepsFlightIcon from "../../../../Img/flight_FILL0_wght400_GRAD0_opsz48.png?roadbook";
import StepsShuttleIcon from "../../../../Img/airport_shuttle_FILL0_wght400_GRAD0_opsz48.png?roadbook";
import StepsSubwayIcon from "../../../../Img/directions_subway_FILL0_wght400_GRAD0_opsz48.png?roadbook";
import StepsTaxiIcon from "../../../../Img/local_taxi_FILL0_wght400_GRAD0_opsz48.png?roadbook";
import StepsTrainIcon from "../../../../Img/directions_railway_FILL0_wght400_GRAD0_opsz48.png?roadbook";
import StepsUnknownIcon from "../../../../Img/question_mark_FILL0_wght400_GRAD0_opsz48.png?roadbook";
import StepsBikeIcon from "../../../../Img/two_wheeler_FILL0_wght400_GRAD0_opsz48.png?roadbook";

export function getRoadbookStepTransportIcon(step: Itinerary): string {
    switch (step.r2r_json?.vehicle?.kind) {
        case 'animal': return StepsAnimalIcon;
        case 'bicycle': return StepsBicycleIcon;
        case 'bus': return StepsBusIcon;
        case 'cablecar':
        case 'tram': return StepsCableCarIcon;
        case 'car':
        case 'rideshare':
        case 'towncar': return StepsCarIcon;
        case 'ferry': return StepsFerryIcon;
        case 'foot': return StepsFootIcon;
        case 'helicopter': return StepsHelicopterIcon;
        case 'plane': return StepsFlightIcon;
        case 'shuttle': return StepsShuttleIcon;
        case 'subway': return StepsSubwayIcon;
        case 'taxi': return StepsTaxiIcon;
        case 'train': return StepsTrainIcon;
        case 'unknown': return StepsUnknownIcon;
        case 'bike': return StepsBikeIcon;
        default : return StepsUnknownIcon;
    }
}
