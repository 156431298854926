//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import InfoIcon from '@material-ui/icons/Info';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import DateRange from '@material-ui/icons/DateRange';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import Room from '@material-ui/icons/Room';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';

import { TogleInfoHotelAndSetStep } from '../../../Actions/Summary';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 30
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 19px)',
        margin: '0 4px',
        left: "-16px"
    },
	containerMargin: {
        marginLeft: 5
    },
	includedAmenities: {
        verticalAlign: '-3px',
		fontSize: 16,
        marginRight: 4
    },
	bold: {
		fontWeight: "bold"
	},
	italic: {
		fontStyle: "italic"
	},
	font12: {
		fontSize: 12
	},
	font14: {
		fontSize: 14
	},
	font17: {
		fontSize: 17
	},
	lowerCase: {
        textTransform: "capitalize"
	},
	calendar: {
        float: 'left',
        marginRight: 4
    },
    pin: {
        float: 'left',
        marginRight: 4,
        fontSize: 17
    },
    pointer: {
        cursor: "pointer"
    },
    underline: {
        textDecoration: "underline"
    },
    pictureContainer: {
	    overflow: "hidden",
	    height: 170,
	    // width: 300,
	    position: "relative",
	    cursor: "pointer"
    },
    carCardImg: {
		width: "100%",
        height: "100%",
        objectFit: "cover",
	},
}));

const SummaryTransferCardManual = ({transfer, mainTransfers, type, selectedProducts, setSelectedProducts, step_index, from, otherProduct}) => {
    const dispatch = useDispatch();
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const trip_info = useSelector(state => state.summary.trip_info);
    const currency_list = useSelector(state => state.base.currency_list);

    const variantIsChecked = (product) => {
        return selectedProducts.transfers[step_index].includes(product.id);
    }
    const handleChange = () => {
        let tmp_selectedProducts = Object.assign({}, selectedProducts);
        let tmp_transfers = tmp_selectedProducts.transfers.slice();
        if (mainTransfers.length === 0 && tmp_transfers[step_index].includes(transfer.id)) {
            tmp_transfers[step_index] = [];
        } else {
            tmp_transfers[step_index] = [transfer.id];
        }
        tmp_selectedProducts.transfers = tmp_transfers;
        setSelectedProducts(tmp_selectedProducts);
    }
    const getClientPrice = (prices) => {
        let current_price = prices.find((el) => {
            return !el.is_commissioned && !el.is_network && !el.is_surcom && !el.master_price;
        });
        if (current_price === undefined) {
            current_price = prices.find((el) => {
                return !el.is_commissioned && !el.is_network && !el.is_surcom && el.master_price;
            });
        }
        return current_price;
    }
    const showContractDetail = () => {
        dispatch({type: 'CART_TOGGLE_CONTRACT_DETAIL', payload: transfer});
    };
    const getPicture = info => {
        let picture = '/Img/destination_default.jpg';
        if (info.picture !== undefined && info.picture !== null) {
            if (info.picture.url !== null && info.picture.url !== '') {
                picture = info.picture.url;
            } else if (info.picture.thumbnail_little !== null && info.picture.thumbnail_little !== '') {
                picture = info.picture.thumbnail_little;
            }
        }
        return picture;
    };
    let price = parseFloat(getClientPrice(transfer.prices).selling_price);
    let selling_currency = currency_list.find((currency) => currency.id === getClientPrice(transfer.prices).selling_currency);

    let adults = 0;
	let child = 0;
	transfer.group_passenger.travelers_list.map((traveler) => {
		let age = moment.utc(trip_info.end_date).diff(moment.utc(traveler.birth_date), "y");
		if (age >= 18) {
			adults += 1;
		}
		if (age < 18) {
			child += 1;
		}
	});
    return (
        <Grid container direction={"row"} spacing={2} style={{marginBottom: 10, background: from === "tripSummary" ? (type === "option" ? "#EEEEEE" : "white") : "white"}}>
            {
                from !== "tripSummary" && !otherProduct && (trip_info.status === "TO_UPDATED" || trip_info.status === "ONGOING" || trip_info.status === "VALIDATED" || trip_info.status === "TOBOOK_VERDIE") && (
                    <Grid item>
                        <div className={ classes.checkboxContainer }>
                            <Checkbox
                                className={ classes.checkbox }
                                edge={ 'start' }
                                checked={ variantIsChecked(transfer) }
                                tabIndex={ -1 }
                                disableRipple 
                                onChange={ handleChange }
                                icon={<CircleUnchecked />}
                                checkedIcon={<RadioButtonCheckedIcon  />}
                            />
                        </div>
                    </Grid>
                )
            }
            <Grid item xs container direction={"column"} alignItems={"flex-start"} className={classes.font14}>
            {
                from !== "tripSummary" && (
                    <Fragment>
                        <Grid item style={{width: "100%"}}>
                            <Grid container direction={"row"} justify={"space-between"}>
                                <Grid item xs style={{width: "100%"}}>
                                    <TransferWithinAStationIcon className={classes.genericText} style={{verticalAlign: "sub", marginRight: 10}}/>
                                    <span className={classes.genericText}>{ transfer.name } </span>
                                    {
                                        // ((transfer.important_notice !== null && transfer.important_notice !== "") || (transfer.included_service !== undefined && transfer.included_service.length !== 0)) && (
                                        //     <span className={`${classes.genericText} ${classes.pointer} ${classes.underline}`} onClick={ showContractDetail } style={{paddingLeft: 5}}>{ t("global.short_more_info") }</span>
                                        // )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            transfer.start_date !== null &&
                            <Grid item>
                                <Grid container direction={"row"} justify={"space-between"}>
                                    <Grid item xs>
                                        <DateRange className={ classes.calendar } style={{fontSize: 20, verticalAlign: "text-top"}}/>
                                        <span className={`${classes.font14} ${classes.genericText}`}>{ moment.utc(transfer.start_date).format('DD MMMM HH:mm') }</span>
                                        {adults !== 0 || child !== 0 ? " - " : ""}
                                        <span className={`${classes.font14} ${classes.genericText}`}>{`${adults !== 0 ? adults : ""} ${adults !== 0 ? (adults > 1 ? t("flight_search.adults") : t("flight_search.adult")) : ""} ${child !== 0 ? child : ""} ${child !== 0 ? (child > 1 ? t("flight_search.children") : t("flight_search.child")) : ""}`}</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Fragment>
                )
            }
            {
                from === "tripSummary" && (
                    <Fragment>
                        <Grid item style={{width: "100%"}}>
                            <Grid container direction={"column"}>
                                <Grid item xs={12} style={{marginTop: 10}}>
                                    <Grid container direction={transfer.is_optional ? "row-reverse" : "row"} spacing={2}>
                                        <Grid item sm={5} xs={12}>
                                            <Grid item className={classes.pictureContainer} style={{width: "100%"}}>
                                                <img className={ classes.carCardImg } src={getPicture(transfer)} alt={ 'transfer-picture' } style={{float: transfer.is_optional ? "right" : "left", borderRadius: 16}}/>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={7} xs={12} container direction={"column"} justify={"center"}>
                                                <Grid item>
                                                    <TransferWithinAStationIcon className={classes.genericText} style={{verticalAlign: "sub", marginRight: 10}}/>
                                                    <span className={classes.genericText}>{ transfer.name } </span>
                                                </Grid>
                                                {
                                                    transfer.start_date &&
                                                    <Grid item xs>
                                                        <DateRange className={ classes.calendar } style={{fontSize: 20, verticalAlign: "text-top"}}/>
                                                        <span className={`${classes.font14} ${classes.genericText}`}>{ moment.utc(transfer.start_date).format('DD MMMM HH:mm') }</span>
                                                        {adults !== 0 || child !== 0 ? " - " : ""}
                                                        <span className={`${classes.font14} ${classes.genericText}`}>{`${adults !== 0 ? adults : ""} ${adults !== 0 ? (adults > 1 ? t("flight_search.adults") : t("flight_search.adult")) : ""} ${child !== 0 ? child : ""} ${child !== 0 ? (child > 1 ? t("flight_search.children") : t("flight_search.child")) : ""}`}</span>
                                                    </Grid>
                                                }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fragment>
                )
            }
            {
                transfer.custom_information !== undefined && transfer.custom_information !== null && (
                    <Grid item style={{width: "100%", marginTop: 10}}>
                        <Alert variant={ 'filled' } severity={ 'info' } >
                            <AlertTitle>{ t('cart-material.note') } :</AlertTitle>
                            { transfer.custom_information }
                        </Alert>
                    </Grid>
                )
            }
            {
                type === "option" && (
                    <Grid item style={{width: "100%", marginTop: 10, fontSize: 20}} xs>
                        <span style={{float: "right"}} className={classes.bold}>+ {Intl.NumberFormat(i18n.language, { style: "currency", currency: selling_currency.iso_code, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(price) }</span>
                    </Grid>
                )
            }
			</Grid>
        </Grid>
    )
}
export default React.memo(SummaryTransferCardManual);