const initial_state = {
    summary_step: 2,
    trip_info: null,
    poi_cart: [],
    manual_cart: [],
    hotel_cart: [],
    flight_cart: [],
    cars_cart: [],
    transfers_cart: [],
    itinerary: [],
    step_hover: null,
    current_step: 0,
    guide_list: [],
    itinerary_to_draw: [],
    modal_info_hotel: false,
    step_info_hotel: 0,
    info_hotel: null,
    modal_custom_info: [],
    step_custom_info: 0,
    custom_info: null,
    trip_main_info: null,
    travelers: null,
    travelers_group: null,
    modal_pictures_arr: false,
    pictures_arr: [],
    index_pictures: 0,
    providers: [],
    custom_providers: [],
    selectedProducts: {
        mainFlights: [],
        hotels: [],
        cars: [],
        transfers: [],
        poi: [],
        step_flight: []
    },
    deposit_amount: 0,
    out_of_cart: [],
    total_price: {}
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.summary !== undefined) {
                Object.keys(action.payload.summary).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.summary[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            new_state = initial_state;
            return new_state;
        }
        case "SUMMARY_SET_SUMMARY_STEP": {
            new_state.summary_step = action.payload.summary_step;
            new_state.current_step = 0;
            return new_state;
        }
        case "SUMMARY_SET_SUMMARY_TRIP_INFO": {
            new_state.trip_info = action.payload.trip_info;
            new_state.minimal_deposit = new_state.trip_info.expected_deposit;
            new_state.deposit_amount = new_state.trip_info.expected_deposit;
            return new_state;
        }
        case "SUMMARY_SET_SUMMARY_TRIP_MAIN_INFO": {
            new_state.trip_main_info = action.payload.trip_main_info;
            return new_state;
        }
        case "SUMMARY_SET_CUSTOM_CART": {
            new_state.poi_cart = action.payload.poi_cart;
            return new_state;
        }
        case "SUMMARY_SET_MANUAL_CART": {
            new_state.manual_cart = action.payload.manual_cart;
            return new_state;
        }
        case "SUMMARY_SET_CARS_CART": {
            new_state.cars_cart = action.payload.cars_cart;
            return new_state;
        }
        case "SUMMARY_SET_FLIGHT_CART": {
            new_state.flight_cart = action.payload.flight_cart;
            return new_state;
        }
        case "SUMMARY_SET_HOTEL_CART": {
            new_state.hotel_cart = action.payload.hotel_cart;
            return new_state;
        }
        case "SUMMARY_SET_TRANSFERS_CART": {
            action.payload.transfers_cart.map(item => {
                let added = false;
                new_state.transfers_cart.map(current => {
                    if (item.id === current.id) {
                        added = true;
                    }
                });
                if (!added) {
                    new_state.transfers_cart.push(item);
                }
            });
            return new_state;
        }
        case "SUMMARY_SET_PROVIDERS": {
            new_state.providers = action.payload.providers;
            new_state.custom_providers = action.payload.custom_providers;
            return new_state;
        }
        case "SUMMARY_SET_ITINERARY": {
            new_state.itinerary = action.payload.itinerary;
            return new_state;
        }
        case "SUMMARY_SET_HOVER": {
            new_state.step_hover = action.payload.step_hover;
            return new_state;
        }
        case "SUMMARY_SET_CURRENT_STEP": {
            new_state.current_step = action.payload.current_step;
            return new_state;
        }
        case "SUMMARY_SET_GUIDE_LIST": {
            new_state.guide_list = action.payload.guide_list;
            return new_state;
        }
        case "SUMMARY_SET_ITINERARY_TO_DRAW": {
            new_state.itinerary_to_draw = action.payload.itinerary_to_draw;
            return new_state;
        }
        case "SUMMARY_SET_TRAVELERS": {
            new_state.travelers = action.payload.travelers;
            return new_state;
        }
        case "SUMMARY_SET_TRAVELERS_GROUP": {
            new_state.travelers_group = action.payload.travelers_group;
            return new_state;
        }
        case "SUMMARY_TOGGLE_AND_SET_HOTEL_MODAL": {
            new_state.modal_info_hotel = !new_state.modal_info_hotel;
            new_state.step_info_hotel = action.payload.step_info_hotel;
            new_state.info_hotel = action.payload.info_hotel;
            return new_state;
        }
        case "SUMMARY_TOGGLE_AND_SET_CUSTOM_MODAL": {
            let copy_arr = new_state.modal_custom_info.slice();
            copy_arr[action.payload.index] = action.payload.modal_custom_info;

            new_state.modal_custom_info = copy_arr;
            new_state.step_custom_info = action.payload.step_custom_info;
            new_state.custom_info = action.payload.custom_info;
            return new_state;
        }
        case "SUMMARY_SET_MODAL_INFO_CUSTOM_TOGGLE": {
            new_state.modal_custom_info = action.payload.modal_custom_info;
            return new_state;
        }
        case "SUMMARY_TOGGLE_AND_SET_PICTURE_MODAL": {
            new_state.modal_pictures_arr = !new_state.modal_pictures_arr;
            new_state.pictures_arr = action.payload.pictures_arr;
            new_state.index_pictures = action.payload.index_pictures;
            return new_state;
        }
        case "SUMMARY_POI_EDIT_CART": {
            let cart = new_state.poi_cart.slice();
            cart[action.payload.index] = action.payload.item;
            new_state.poi_cart = cart;
            return new_state;
        }
        case "SUMMARY_ACCOMMODATION_EDIT_CART": {
            let cart = new_state.hotel_cart.slice();
            cart[action.payload.index] = action.payload.item;
            new_state.hotel_cart = cart;
            return new_state;
        }
        case "SUMMARY_CARS_EDIT_CART": {
            let cart = new_state.cars_cart.slice();
            cart[action.payload.index] = action.payload.item;
            new_state.cars_cart = cart;
            return new_state;
        }
        case "SUMMARY_SET_SELECTED_PRODUCTS": {
            new_state.selectedProducts = action.payload.products;
            return new_state;
        }
        case "SUMMARY_SET_SELECTED_PRODUCTS_ITINERARY": {
            new_state.selectedProducts = action.payload.products;
            new_state.itinerary = action.payload.itinerary;
            return new_state;
        }
        case "SUMMARY_SET_TOTAL_PRICE": {
            new_state.total_price = action.payload.total_price;
            new_state.out_of_cart = action.payload.out_of_cart;
            return new_state;
        }
        case "SUMMARY_UPDATE_DEPOSIT_AMOUNT": {
            new_state.deposit_amount = action.payload;
            return new_state;
        }
        case "SUMMARY_UPDATE_PAYMENT_DONE": {
            let tmp_trip_info = Object.assign({}, new_state.trip_info);
            let copy_data = [];
            tmp_trip_info.data.map((data, index_data) => {
                if (data.id === tmp_trip_info.current_version) {
                    let tmp_data = Object.assign({}, data);
                    tmp_data.payment_done.push(action.payload);
                    data = tmp_data;
                }
                copy_data.push(data);
            });
            tmp_trip_info.data = copy_data;
            new_state.trip_info = tmp_trip_info;
            return new_state;
        }
    }
    return new_state;
}
