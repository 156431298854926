import axios from "axios";
import memoize from "memoizee";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { Quotation } from "./objects/quotation";

async function get(destinationId: number, parentId: number): Promise<Quotation | undefined> {
    let { pass_check, headers } = CheckBeforeRequest();


    if (pass_check) {
        try {
            const response = await axios.get<{
                results: Quotation[]
            }>(
                `${API_HREF}client/${window.id_owner}/new-quotation/by_destinations/`,
                {
                    headers,
                    params: {
                        // limit: 50,
                        // offset: 0,
                        // parent: parentId,
                        destination_ids: destinationId,
                        // activated: true
                    }
                }
            );
            return response.data[0];
        } catch (error) {
            console.error(error);
        }
    }
}

export const getQuotation = memoize(
    get,
    {
        promise: true,
        primitive: true
    }
);
