//---- Dependencies ----//
import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Slider from "react-slick";

//---- Functions ----//
import FetchOptions from "../Functions/FetchOptions.js";
import GetCategories from "../Functions/GetCategories.js";
import GetPassenger from "../Functions/GetPassenger.js";
import SeatModalToggle from "../Functions/SeatFunctions/SeatModalToggle.js";

//---- Runners ----//
import GalleryArrow from "../../../Gallery/GalleryArrow.jsx";

//---- Component ----//
import SeatModal from "./SeatModal.jsx";

//---- Actions ----//
import { SetSelectedOption, ToggleSeatModal, ResetFetchErrorOptions } from "../../../../Actions/FlightSearch";
import { SetSelectedCartOption, ResetFetchErrorCartOptions } from "../../../../Actions/Flight";

//---- CSS ----//
import "../../../../Style/FlightSearch.css";
import "../../../../Style/Gallery.css";

@connect((store) => {
    return {
        currency: store.trip.currency,
        airports: store.flight_search.airports,
        airlines: store.flight_search.airlines,
        seat_modal: store.flight_search.seat_modal,
        language: store.header.tmp_language
    };
})
@withTranslation()
class RenderDetailedOptions extends React.Component {
	componentWillMount() {
		const {flight, provider, travelers_list, travelers, flight_index, segment_index, airlines, airports, currency, trace_id, dispatch } = this.props;
		dispatch(ToggleSeatModal(false, null, null));
		if (location.pathname.includes("cart")) {
			dispatch(ResetFetchErrorCartOptions(flight_index, segment_index));
		}
		else {
			dispatch(ResetFetchErrorOptions(flight_index, segment_index));
		}
		if (flight.outbounds[segment_index].options.length === 0) {
			FetchOptions(flight, travelers_list, flight_index, segment_index, airports, airlines, currency, trace_id, travelers, dispatch, provider);
		}
	}
	printCategories() {
		const { segment, segment_index } = this.props;
		let categories = GetCategories(segment.options[0].fares, segment_index);
		let arr = [];
		categories.map((category, index_category) => {
			arr.push(
				<div key={index_category} className={ "categories-list-container col-md-12" }>
					<div className={ "categories-list-item" }>
					{
						category.picture && (
							<img src={category.picture} className={ "categories-list-item-img" }/>
						)
					}
						<div className={ category.picture ? "categories-list-item-description" : "categories-list-item-description-no-icon" }>
							<div className={ "categories-description" }>
								{(category.description).toLowerCase()}
							</div>
						</div>
					</div>
				</div>
			);
		});
		return (
			<div className={ "categories-list col-md-3 no-padding row" }>
				{arr}
			</div>
		);
	}
	checkSelected(fares, fares_index) {
		const { flight_index, segment_index, segment, dispatch } = this.props;
		const location = window.location;
		if (segment.selected_option !== fares_index) {
			if (location.pathname.includes("cart")) {
				dispatch(SetSelectedCartOption(flight_index, segment_index, fares_index, fares.key_pricing_solution));
			}
			else {
				dispatch(SetSelectedOption(flight_index, fares_index, fares.key_pricing_solution));
			}
		}
	}
	printOption() {
		const { airlines, provider, airports, travelers_list, travelers, segment, segment_index, seat_modal, flight, currency, language, trace_id, dispatch, t } = this.props;
		let settings = {
			className: segment.options[0].fares.length <= 2 ? (segment.options[0].fares.length === 1 ? "min-length-one-fare" : "min-length-fare") : "", 
			infinite: true,
			slidesToShow: segment.options[0].fares.length > 2 ? 3 : segment.options[0].fares.length,
			speed: 500,
			initialSlide: segment.selected_option ? segment.selected_option : 0,
            nextArrow: <GalleryArrow slider_type={ "gallery_arrow_big" }/>,
            prevArrow: <GalleryArrow slider_type={ "gallery_arrow_big" }/>
		};
		let arr = [];
		const seat_selection = [
			"seat assignment",
			"main cabin extra",
			"us pre reserve seats",
			"advanced seat assignment",
			"advance seat reservation",
			"seat selection",
			"choix de siège à lavance",
			"basic seat assignment"
		];
		let base_price = 0;
		let pax_passenger = travelers ? travelers : GetPassenger(travelers_list);
		const location = window.location;
		if (segment.selected_option === null) {
			if (location.pathname.includes("cart")) {
				base_price = parseFloat(flight.prices[0].selling_price);
			}
			else {
				base_price = (flight.adult ? parseFloat(flight.adult.total_price) * pax_passenger.ADT : 0) + (flight.children ? parseFloat(flight.children.total_price) * pax_passenger.CNN : 0) + (flight.baby ? parseFloat(flight.baby.total_price) * pax_passenger.INF : 0);
			}
		}
		else
		{
			segment.options[0].fares[segment.selected_option].pax_pricing.map((pax) => {
				Object.keys(pax_passenger).map((key) => {
					if (key !== "total") {
						if (key === pax.pax_code)
							base_price += pax_passenger[key] * parseFloat(pax.prices[0].selling_price); 
					}
				});
			});
		}
		console.log('base_price:', base_price);
		segment.options[0].fares.map((fares, fares_index) => {
			let tmp_total_price = 0;
			let option_category = fares.option_category[0];
			fares.pax_pricing.map((pax) => {
				Object.keys(pax_passenger).map((key) => {
					if (key !== "total") {
						if (key === pax.pax_code)
							tmp_total_price += pax_passenger[key] * parseFloat(pax.prices[0].selling_price); 
					}
				})
			});
			let total_price = new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(tmp_total_price));
			let total_price_traverler = new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat((tmp_total_price - base_price) / pax_passenger.total));
			arr.push(
				<div key={fares_index} className={ "options-class-wrapper" }>
					<div className={ fares_index === segment.selected_option ? "fare-option active" : "fare-option" }>
						<div className={ "option-class-title" } onClick={ () => {this.checkSelected(fares, fares_index)}}>
							<div className={ fares_index === segment.selected_option ? "option-category-image active" : "option-category-image" }>
								<img className={ "ft-cover-img" } src={option_category.option_segment[segment_index].image_url} />
							</div>
							<div className={ "option-class-radio" }>
								<input type="radio" checked={segment.selected_option === fares_index} value={option_category.name} onChange={ () => {this.checkSelected(fares_index)}}/>
								<span className={ "option-class-check-mark" }/>
							</div>
							<div className={ "option-category-name row ft-no-margin" }>
								<span className="col-md-12">
									{ t("global.class") + option_category.option_segment[segment_index].cabin_class}
								</span>
								<span className="col-md-12">
									{option_category.name}
								</span>
							</div>
							<div className={ "total-price-container" }>
								<div className={ "total-price-content row" }>
									<span className={"col-md-12 bold " + (parseFloat(total_price_traverler) > 0 ? "sup-price" : (parseFloat(total_price_traverler) === 0 ? "equal-price" : "sub-price"))}>
										{ (parseFloat(total_price_traverler) >= 0 ? "+" + total_price_traverler : total_price_traverler) + (pax_passenger.total > 1 ? t("flight_search.per_travelers") : "")}
									</span>
									<span className="col-md-12 italic">
										{ t("global.total_price") + ": " + total_price}
									</span>
								</div>
							</div>
						</div>
						{
							option_category.option_segment[segment_index].option.map((option, option_index) => {
								return (
									<div key={option_index} className={ "option-class-list" }>
										{
											!option.available && (
												<span className={ "absolute-center" }>
													<i className={ "icon-099-delete ft-font-11" }/>
												</span>
											)
										}
										{
											option.available && option.included && (
												<span>
													{
														option.baggage_quantity && (
															<span className={ "absolute-center" }>{option.baggage_weight ? option.baggage_quantity + "x" + option.baggage_weight + "kg" : "x" + option.baggage_quantity}</span>
														)
													}
													{
														!option.baggage_quantity && !option.baggage_weight && (
															<span className={ "absolute-center option-class-check" }>
															{
																seat_selection.includes(option.description.toLowerCase()) && fares_index === segment.selected_option && (
																	<p className={ "seat-selection" } onClick={() => SeatModalToggle(flight, provider, !seat_modal, fares_index, segment_index, airlines, airports, currency, trace_id, dispatch)}>
																		<span className={ "btn-orange" }>
																			{t("flight_search.select_seat")}
																		</span>
																	</p>
																)
															}
															{
																!seat_selection.includes(option.description.toLowerCase()) && fares_index === segment.selected_option && (
																	<i className={ "icon-check" }/>
																)

															}
															{
																fares_index !== segment.selected_option && (
																	<i className={ "icon-check" }/>
																)
															}
																
															</span>
														)
													}
												</span>
											)
										}
										{
											option.available && !option.included && (
												<span className={ "absolute-center" }>
													<i className={ "icon-coins" }/>
												</span>
											)
										}
									</div>
								);
							})
						}
					</div>
				</div>
			);
		});
		return (
			<div className={ "options-list text-center col-md-8" }>
				<Slider {...settings}>
					{arr}
				</Slider>
			</div>
		);
	}
	render() {
		const { segment, travelers_list, flight, flight_index, t } = this.props;
		return (
			<div className="flight-options-container">
				{
					segment.options.length === 0 && segment.options_error === null && !segment.empty_option && (
						<div>
							<div className={ "spinner-orange" }/>
							<div className={ "flight-options-waiting-message text-center" }>{t("flight_search.fetching_options")}</div>
						</div>
					)
				}
				{
					segment.options.length > 0 && segment.options_error === null && !segment.empty_option && (
						<div className={ "row ft-no-margin" }>
							{this.printCategories()}
							{this.printOption()}
						</div>
					)
				}
				{
					segment.options.length >= 0 && segment.empty_option && (
						<div className={ "flight-options-waiting-message text-center" }>
							<div className={"icon-warning ft-font-40"} />
							{t("flight_search.no_options")}
						</div>
					)
				}
				{
					segment.options_error !== null && (
						<p>{segment.options_error.errors ? segment.options_error.errors.unexpected_error : (segment.options_error.detail ? segment.options_error.detail : segment.options_error)}</p>
					)
				}
				<SeatModal flight={flight} flight_index={flight_index} travelers_list={travelers_list}/>
			</div>
		);
	}
}
export default RenderDetailedOptions;
