import i18n from "../../../../i18n.jsx";
import GetCookie from "../../../Common/Functions/GetCookie";

export default (component, search) => {
    if (GetCookie("token") !== null) {
        $.ajax({
            method: "GET",
            headers: {
                "Authorization": "Token " + GetCookie("token"),
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Accept-Language": i18n.language
            },
            url: `${API_HREF}client/${component.props.user.client}/children/?type=2&search=${search}&limit=250&is_active=true`,
            success: function (data) {
                component.setState({
                    client_list: data.results
                });
            },
            error: function (error) {
                console.log(error);
            }
        });
    }
}

