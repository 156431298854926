/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../crm.css';
import decorator from '../../utils/editor/decorator';
import RichEditor, { RichEditorStyleMap } from '../../utils/editor/editor';
import { EditorDefaultActivatedToolbarControls } from '../../utils/editor/editorToolbar';
import { renderRichEditorContentHTML } from '../../utils/editor/renderRichEditorContentHtml';
import { restoreRichEditorStateFromHtml } from '../../utils/editor/restoreRichEditorStateFromHtml';
import { EditorState } from 'draft-js';
import { patchNote } from "../../Functions/UserFunctions";
import { SetActiveUserNotes } from "../../../../../Actions/Crm";
import { CrmEmailCard } from "./CrmEmailCard";

const default_note = EditorState.createEmpty(decorator);

const CrmUserDetailEmailCard = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const styles = RichEditorStyleMap;

    const user = useSelector(store => store.user.user);
    const selected_user = useSelector(store => store.crm.selected_user);

    const [lineHeight, setLineHeight] = React.useState('1.5em');
    const [email, setEmail] = React.useState(null);

    useEffect(() => {
        if (props.email) {
            setEmail(props.email);
        }
    }, [props.email]);

    //get first line
    const getFirstLine = () => {
        const fl = props.email?.template?.split("</p>");
        if (fl && fl[0]) {
            return fl[0] + '</p>';
        }
        return null;
    };
    const getFirstLineHeight = () => {
        const refreshUntilReady = setInterval(() => {
            const divMeasurer = document.getElementById(`div-measurer-email-${props.email.id}`);
            if (divMeasurer) {
                clearInterval(refreshUntilReady);
                setLineHeight(divMeasurer.clientHeight + 'px');
                console.log('DEBUG', divMeasurer.clientHeight, divMeasurer.style.lineHeight);
            }
        }, 100);
    };

    const getRecipient = (recipient) => {
        let recps = '';
        if (recipient) {
            if (recipient.includes("['")) {
                const recipients = JSON.parse(recipient.replace(/'/g, '"'));
                if (recipients) {
                    recps = recipients.join(', ');
                }
            } else {
                recps = recipient;
            }
        }
        return recps;
    };

    /*if (email) {
        return (
            <div className={`activity-email-card`}>
                <b>{email.subject}</b>
                <p> <i>{t('crm.send_to')} {getRecipient(email.recipient)}</i></p>
                <div className={props.expand ? "" : "email-preview-container"}>
                    <div className="email-preview" dangerouslySetInnerHTML={{ __html: props.email.template }} />
                </div>
            </div>
        );
    }*/
    if (email) {
        return (
            <CrmEmailCard
                key={email.id}
                item={email}
                setLoading={(loading) => console.log("TODO: handle loading...")}
            />
        );
    }
};
export default React.memo(CrmUserDetailEmailCard);
