export default (pax_pricing) => {
    let change_penalty = null;
    if (pax_pricing.change_penalty !== undefined && pax_pricing.change_penalty !== null) {
	    if (pax_pricing.change_penalty.percentage !== undefined) {
	        change_penalty = (parseFloat(pax_pricing.prices[0].selling_price) / 100) * pax_pricing.change_penalty.percentage;
	    } else if (pax_pricing.change_penalty.amount !== undefined) {
	        change_penalty = pax_pricing.change_penalty.amount;
	    }
    }
    return change_penalty;
}