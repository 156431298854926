import { cloneDeep, isNumber } from "lodash";
//---- Actions ----//
import { SetDates } from "../../../Actions/Trip";
import { LockItineraryActions } from "../../../Actions/Base";
import { ToggleDatePicker } from "../../../Actions/Header";

//---- Functions ----//
import GetTrip from "../../Base/Functions/GetTrip";
import UpdateCart from "../../Itinerary/Functions/UpdateCart.js";

//---- Commons ----//
import { StepsDatesManager } from "../../Itinerary/utils/stepsDatesManager";
import { itineraryToItineraryInput } from "../../Itinerary/utils/itineraryToItineraryInput";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import Notify from "../../Common/Notify";

export default (accommodation_cart, car_cart, flight_cart, manual_cart, old_start, old_end, dates, itinerary, user, dispatch, t, enqueueSnackbar, callBack = null) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check && (dates.startDate !== null || dates.endDate !== null)) {
        let request = {};
        if (dates.startDate !== null) {
            request.start_date = dates.startDate.set({ hour: 0, minute: 0 });
        }
        if (dates.endDate !== null) {
            request.end_date = dates.endDate.set({ hour: 23, minute: 59 });
        }

        if (
            request.start_date &&
            request.end_date &&
            itinerary.findIndex((item) => {
                return item.circuit_start_date;
            }) >= 0
        ) {
            const recalculatedItinerary = itinerary.map((step) => cloneDeep(step)).map(itineraryToItineraryInput);
            const startDate = request.start_date.clone();
            startDate.set({ hour: 9, minute: 0, second: 0 });
            recalculatedItinerary[0].start_date = startDate.toISOString();
            recalculatedItinerary[0].end_date = startDate.toISOString();
            const datesManager = new StepsDatesManager(
                request.start_date.toISOString(),
                request.end_date.toISOString()
            );
            for (let i = 0; i < recalculatedItinerary.length; i++) {
                const current = cloneDeep(recalculatedItinerary[i]);
                const next = recalculatedItinerary[i + 1];

                if (next) {
                    const resultPair = datesManager.recomputeDates(current, next);
                    recalculatedItinerary[i] = resultPair[0];
                    recalculatedItinerary[i + 1] = resultPair[1];
                }
            }
            for (let i = 0; i < recalculatedItinerary.length; i++) {
                const current = cloneDeep(recalculatedItinerary[i]);
                const next = recalculatedItinerary[i + 1];

                if (
                    next &&
                    isNumber(next.circuit) &&
                    next.circuit_start_date &&
                    (
                        current.circuit !== next.circuit ||
                        current.circuit_trip_version !== next.circuit_trip_version
                    ) &&
                    window.moment.utc(next.start_date).isBefore(
                        window.moment.utc(current.end_date)
                    )
                ) {
                    enqueueSnackbar(
                        t('itinerary.bloc-fixed-date-trip-dates-change-warning'),
                        { variant: 'error' }
                    );
                    return;
                }
            }
        }

        let nb_cart_item = (accommodation_cart.length) + (car_cart.length) + (flight_cart.length) + (manual_cart.length);
        dispatch(LockItineraryActions());
        $.ajax({
            method: "PATCH",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
            data: JSON.stringify(request),
            success: function (data) {
                dispatch(SetDates({ startDate: data.start_date, endDate: data.end_date }));
                if (nb_cart_item > 0) {
                    UpdateCart("DATE", dispatch, old_start, old_end, null, null, null);
                }
                dispatch(ToggleDatePicker(false));
                GetTrip(user, dispatch, t, true);
            },
            error: function (error) {
                console.log('error:', error);
                if (error !== undefined && error.responseJSON !== undefined && error.responseJSON.non_field_errors !== undefined && error.responseJSON.non_field_errors !== null && error.responseJSON.non_field_errors[0] !== undefined && error.responseJSON.non_field_errors[0] !== "")
                    Notify(error.responseJSON.non_field_errors[0], { status: "danger", pos: "top-center" });
                if (callBack !== null) {
                    callBack();
                }
            }
        });
    }
}