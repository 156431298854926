export function SetStep(step) {
    return {
        type: "HOME_SET_STEP",
        payload: {
            step: step
        }
    }
}

export function SetDestinations(destinations) {
    return {
        type: "HOME_SET_DESTINATIONS",
        payload: {
            destinations: destinations
        }
    }
}

export function ToggleModal(modal, step) {
    return {
        type: "HOME_TOGGLE_MODAL",
        payload: {
            modal: modal,
            step: step
        }
    }
}

export function ToggleModalSignIn(modal_signin) {
    return {
        type: "HOME_TOGGLE_MODAL_SIGN_IN",
        payload: {
            modal_signin: modal_signin
        }
    }
}
