//---- Actions ----//
import { SetTripId } from "../../../Actions/Trip";
import { SetItinerary } from "../../../Actions/Itinerary";
import { UnlockItineraryActions } from "../../../Actions/Base";

//---- Functions ----//
import GetCookie from "../../Common/Functions/GetCookie";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

export default (dispatch, trip_data, itinerary_data, accommodation_data, flight_data, travelers_data, providers_data, cars_data, poi_data, transfers_data, manual_providers, manual_product_data) => {
    const { headers } = CheckBeforeRequest();
    if (trip_data !== null && itinerary_data !== null && accommodation_data !== null && flight_data !== null && travelers_data !== null && providers_data !== null && poi_data !== null && transfers_data !== null && manual_product_data !== null) {
        dispatch(UnlockItineraryActions());
        dispatch(SetTripId(trip_data.trip.id));
    }
    if (itinerary_data !== null && cars_data !== null) {
		itinerary_data.map((itinerary, itinerary_index) => {
			cars_data.map((car, car_index) => {
				let start_date = car.start_date;
				let end_date = car.end_date;
				if (Object.keys(itinerary.r2r_json).length > 1 && (moment.utc(itinerary.start_date).isBetween(moment.utc(start_date), moment.utc(end_date), "day", '[)') || moment.utc(itinerary.end_date).isBetween(moment.utc(start_date), moment.utc(end_date), "day", '[)')) && itinerary.r2r_json.vehicle && itinerary.r2r_json.vehicle.kind === "car" && !itinerary.r2r_json.selected) {
				// if (Object.keys(itinerary.r2r_json).length > 1  && (moment.utc(itinerary.start_date).isBetween(moment.utc(start_date), moment.utc(end_date)) || moment.utc(itinerary.end_date).isBetween(moment.utc(start_date), moment.utc(end_date))) && itinerary.r2r_json.vehicle.kind === "car" && !itinerary.r2r_json.selected) {
					let copy_itinerary = Object.assign({}, itinerary);
					copy_itinerary.r2r_json.selected = true;
					let request = {
						r2r_json: copy_itinerary.r2r_json
					};
					$.ajax({
					    method: "PATCH",
					    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/${copy_itinerary.id}/?token=${GetCookie("trip_token")}&shift_mode=false`,
					    headers: headers,
					    data: JSON.stringify(request),
					    success: function (data) {
					        dispatch(SetItinerary(data));
					    },
					    error: function (error) {
					    	console.log('error:', error);
					    }
					});
				}
			});
		});
	}
	if (itinerary_data !== null && cars_data !== null && cars_data.length === 0) {
		itinerary_data.map((itinerary, itinerary_index) => {
			if (Object.keys(itinerary.r2r_json).length > 1 && itinerary.r2r_json.vehicle && itinerary.r2r_json.vehicle.kind === "car" && itinerary.r2r_json.selected) {
				let copy_itinerary = Object.assign({}, itinerary);
				copy_itinerary.r2r_json.selected = false;
				let request = {
					r2r_json: copy_itinerary.r2r_json
				};
				$.ajax({
				    method: "PATCH",
				    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/${copy_itinerary.id}/?token=${GetCookie("trip_token")}&shift_mode=false`,
				    headers: headers,
				    data: JSON.stringify(request),
				    success: function (data) {
				        dispatch(SetItinerary(data));
				    },
				    error: function (error) {
				    	console.log('error:', error);
				    }
				});
			}
		});
	}
}