//---- Dependencies ----//
import React from 'react';
import Slider from 'react-slick';

class CustomHomeSlider extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            settings: {
                dots: false,
                arrows: false,
                fade: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                speed: 500,
                autoplaySpeed: 8000,
                cssEase: "linear"
            }
        }
    }
    render() {
        const { pictures, height } = this.props;
        return (
            <div id={height === "Packages" ? "packagesSlider" : "homeSlider"}>
                <Slider { ...this.state.settings }>
                    {
                        pictures.length === 0 && (
                            <div className={height === "Packages" ? "smaller-container-header-pic" : "container-pic"}>
                                <img className="slider-pic ft-cover-img" src={"/Img/default_dest1.jpeg"} />
                            </div> 
                        )
                    }
                    {
                        pictures.map((picture, index) => {
                            return (
                                <div key={index} className={height === "Packages" ? "smaller-container-header-pic" : "container-pic"}>
                                    <img className="slider-pic ft-cover-img" src={ (picture !== null) ? (picture.thumbnail_big !== null ? picture.thumbnail_big : picture.url ) : "/Img/default_dest1.jpeg"} />
                                </div>                    
                            );
                        })
                    }
                </Slider>
            </div>
        );
    }
}

export default CustomHomeSlider;