//---- Description : Gallery arrow ----//
//---- Contact : kevin.deffontaine@facilitatrip.com ----//

//---- Dependencies ----//
import React from 'react';

class GalleryArrow extends React.Component {
    constructor(props, context) {
        super(props, context);
    }
    render() {
        const {className, onClick} = this.props;
        return (
            <div className={ className + " gallery_arrow " + this.props.slider_type } style={{display: 'block'}} onClick={onClick}>
                {
                    className === "slick-arrow slick-next" ? (
                        <i className={ "fa fa-angle-right" }/>
                    ) : (
                        <i className={ "fa fa-angle-left" }/>
                    )
                }
            </div>
        );
    }
}

export default GalleryArrow;