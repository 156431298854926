import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Chip,
    Stack,
    Typography,
    styled
} from "@mui/material";
import { green } from "@mui/material/colors";
import {
    Block,
    Cancel,
    CheckCircle,
    ConfirmationNumberOutlined,
    DateRangeOutlined,
    Error,
    MonetizationOnOutlined,
    Pending,
    PlaceOutlined,
    StarBorderOutlined,
    WatchLaterOutlined
} from "@mui/icons-material";
import { debounce } from "lodash";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { ProviderBookingProductCommentModal } from "./ProviderBookingProductCommentModal";
import { useShowError } from "../Utils/showError";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { useProductBookingStatusChange } from "./network/productBookingStatusChange";
import { useProviderBookingChangeComment } from "./utils/providerBookingChangeComment";
import { ProviderBookingContext } from "./utils/providerBookingContext";
import CheckResponse from "../Flight/FlightSelected/Functions/CheckResponse";
import { StatusBooking } from "../Itinerary/objects/statusBooking";
import { BookingStatus } from "../Itinerary/objects/bookingStatus";
import { Destination } from "../Itinerary/objects/destination";
import { AppState } from "../../Reducers/Reducers";

export type ProviderBookingProductProps = {
    id: number,
    type: keyof ReturnType<typeof useCartProducts> | 'manual',
    productType: number,
    isProposition: boolean,
    status: StatusBooking | null,
    bookingStatus: BookingStatus | null,
    destination: Destination | null,
    image: string,
    from: string,
    to: string,
    startDate: string,
    title: string,
    price: string,
    bookingNumber: string,
    providerComment: string | null,
    isCustom: boolean,
}

type Props = ProviderBookingProductProps

export function ProviderBookingProduct(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const context = useContext(ProviderBookingContext);
    const [bookingNumber, setBookingNumber] = useState('');
    const [openCommentModal, setOpenCommentModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const showError = useShowError();
    const changeBookingStatus = useProductBookingStatusChange({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(product) {
            if (trip) {
                switch (props.type) {
                    case 'flights': {
                        dispatch({
                            type: 'FLIGHT_EDIT_CART_BY_ID',
                            payload: CheckResponse([product], trip.end_date)[0]!
                        });
                        break;
                    }
                    case 'cars': {
                        dispatch({
                            type: 'CAR_EDIT_CART_BY_ID',
                            payload: product
                        });
                        break;
                    }
                    case 'accommodations': {
                        dispatch({
                            type: 'ACCOMMODATION_EDIT_CART_BY_ID',
                            payload: product
                        });
                        break;
                    }
                    case 'transfers': {
                        dispatch({
                            type: 'TRANSFER_EDIT_CART_BY_ID',
                            payload: product
                        });
                        break;
                    }
                    case 'pois': {
                        dispatch({
                            type: 'POI_EDIT_CART_BY_ID',
                            payload: product
                        });
                        break;
                    }
                    default: {
                        dispatch({
                            type: 'CART_EDIT_MANUAL_ITEM',
                            payload: product
                        });
                        break;
                    }
                }
            }
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const changeComment = useProviderBookingChangeComment({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(product) {
            if (trip) {
                switch (props.type) {
                    case 'flights': {
                        dispatch({
                            type: 'FLIGHT_EDIT_CART_BY_ID',
                            payload: CheckResponse([product], trip.end_date)[0]!
                        });
                        break;
                    }
                    case 'cars': {
                        dispatch({
                            type: 'CAR_EDIT_CART_BY_ID',
                            payload: product
                        });
                        break;
                    }
                    case 'accommodations': {
                        dispatch({
                            type: 'ACCOMMODATION_EDIT_CART_BY_ID',
                            payload: product
                        });
                        break;
                    }
                    case 'transfers': {
                        dispatch({
                            type: 'TRANSFER_EDIT_CART_BY_ID',
                            payload: product
                        });
                        break;
                    }
                    case 'pois': {
                        dispatch({
                            type: 'POI_EDIT_CART_BY_ID',
                            payload: product
                        });
                        break;
                    }
                    default: {
                        dispatch({
                            type: 'CART_EDIT_MANUAL_ITEM',
                            payload: product
                        });
                        break;
                    }
                }
            }
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const onBookingNumberSave = useCallback(
        debounce((bookingNumber: string, status: StatusBooking | null) => {
            if (context.tripId && context.version) {
                changeBookingStatus({
                    tripId: context.tripId,
                    version: context.version,
                    productId: props.id,
                    ref: bookingNumber,
                    status
                });
            }
        }, 500),
        [changeBookingStatus, context.tripId, context.version]
    );
    const from = window.moment.utc(props.from);
    const to = window.moment.utc(props.to);

    const onChangeProductBookingStatus = (status: StatusBooking) => {
        if (context.tripId && context.version) {
            changeBookingStatus({
                tripId: context.tripId,
                version: context.version,
                productId: props.id,
                ref: bookingNumber,
                status
            });
            setOpenCommentModal(true);
        }
    };

    const onConfirmCommentModal = (comment: string | null) => {
        if (context.tripId && context.version && context.providerId) {
            changeComment(
                {
                    id: props.id,
                    type: props.type,
                    providerComment: comment,
                    isCustom: props.isCustom
                },
                {
                    tripId: context.tripId,
                    version: context.version,
                    providerId: context.providerId
                }
            );
        }
    };

    useEffect(() => {
        setBookingNumber(props.bookingNumber);
    }, [props.bookingNumber]);

    useEffect(() => {
        onBookingNumberSave(bookingNumber, props.status);
    }, [bookingNumber])

    return (
        <>
            <Card sx={{ display: 'flex' }}>
                <CardMedia
                    component="img"
                    image={props.image}
                    sx={{ width: 250 }}
                />
                <CardContent sx={{ flex: 1 }}>
                    <Typography fontWeight="bold" sx={{ marginBottom: 1 }}>
                        {props.title}
                        {
                            props.isProposition &&
                            <span style={{ fontWeight: 100, color: green[600] }}>
                                {' '}({t('cart-material.provider-quotation-proposition-marker')})
                            </span>
                        }
                    </Typography>
                    {
                        props.destination &&
                        <Stack direction="row" spacing={1}>
                            <PlaceOutlined fontSize="small" />
                            <Typography variant="caption">
                                {
                                    props.destination?.data?.localization.find((item) => {
                                        return item.locale === locale;
                                    })?.name ??
                                    props.destination?.data?.international_name ??
                                    ''
                                }
                            </Typography>
                        </Stack>
                    }
                    {
                        from.isValid() &&
                        <Stack direction="row" spacing={1}>
                            <DateRangeOutlined fontSize="small" />
                            <Typography variant="caption">
                                {
                                    from.isValid() && to.isValid() &&
                                    t(
                                        'cart-material.cart-construction-products-table-date',
                                        {
                                            from: from.format('LL'),
                                            to: to.format('LL')
                                        }
                                    )
                                }
                                {
                                    from.isValid() && !to.isValid() &&
                                    from.format('LL')
                                }
                            </Typography>
                        </Stack>
                    }
                    {
                        from.isValid() && to.isValid() &&
                        <Stack direction="row" spacing={1}>
                            <WatchLaterOutlined fontSize="small" />
                            <Typography variant="caption">
                                {
                                    t(
                                        'cart-material.provider-quotation-product-hourly',
                                        {
                                            from: from.format('HH:mm'),
                                            to: to.format('HH:mm')
                                        }
                                    )
                                }
                            </Typography>
                        </Stack>
                    }
                    <Stack direction="row" spacing={1}>
                        <MonetizationOnOutlined fontSize="small" />
                        <Typography variant="caption">
                            {
                                t(
                                    'cart-material.provider-quotation-price',
                                    { price: props.price }
                                )
                            }
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        sx={{ marginBottom: 1 }}
                    >
                        <ConfirmationNumberOutlined fontSize="small" />
                        <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
                            {
                                t('cart-material.provider-quotation-booking-number')
                            }
                        </Typography>
                        <BookingNumberInput
                            value={bookingNumber}
                            onChange={(event) => setBookingNumber(event.target.value)}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        sx={{ marginBottom: 1 }}
                    >
                        <StarBorderOutlined fontSize="small" />
                        <Typography variant="caption">
                            {t('cart-material.provider-quotation-booking-status')}
                        </Typography>
                        {
                            !props.bookingStatus &&
                            <Chip
                                size="small"
                                label={t('cart-material.cart-construction-unbooked')}
                                sx={{ color: 'inherit' }}
                            />
                        }
                        {
                            props.bookingStatus?.status_booking === StatusBooking.NONE &&
                            <Chip
                                size="small"
                                label={t('cart-material.cart-construction-no-status')}
                                sx={{ color: 'inherit' }}
                            />
                        }
                        {
                            props.bookingStatus?.status_booking === StatusBooking.CONFIRMED &&
                            <Chip
                                label={t('cart-material.cart-construction-confirmed')}
                                size="small"
                                sx={{
                                    backgroundColor: '#5cb85c',
                                    color: '#fff'
                                }}
                                icon={<CheckCircle color="inherit" />}
                            />
                        }
                        {
                            props.bookingStatus?.status_booking === StatusBooking.CANCELLED &&
                            <Chip
                                label={t('cart-material.cart-construction-cancelled')}
                                size="small"
                                sx={{
                                    backgroundColor: '#ed3c3f',
                                    color: '#fff'
                                }}
                                icon={<Block color="inherit" />}
                            />
                        }
                        {
                            (
                                props.bookingStatus?.status_booking === StatusBooking.PENDING ||
                                props.bookingStatus?.status_booking === StatusBooking.WAITING
                            ) &&
                            <Chip
                                label={t('cart-material.cart-construction-waiting')}
                                size="small"
                                color="warning"
                                icon={<Pending color="inherit" />}
                            />
                        }
                        {
                            props.bookingStatus?.status_booking === StatusBooking.REFUSED &&
                            <Chip
                                label={t('cart-material.cart-construction-refused')}
                                size="small"
                                sx={{
                                    backgroundColor: '#ed3c3f',
                                    color: '#fff'
                                }}
                                icon={<Cancel color="inherit" />}
                            />
                        }
                        {
                            props.bookingStatus?.status_booking === StatusBooking.ERROR &&
                            <Chip
                                label={t('cart-material.cart-construction-error')}
                                size="small"
                                sx={{
                                    backgroundColor: '#ed3c3f',
                                    color: '#fff'
                                }}
                                icon={<Error color="inherit" />}
                            />
                        }
                        {
                            props.bookingStatus?.status_booking === StatusBooking.UNAVAILABLE &&
                            <Chip
                                label={t('cart-material.cart-construction-unavailable')}
                                size="small"
                                sx={{
                                    backgroundColor: '#ed3c3f',
                                    color: '#fff'
                                }}
                                icon={<Block color="inherit" />}
                            />
                        }
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ marginTop: 1.5 }}
                    >
                        {
                            (
                                !props.status ||
                                [
                                    StatusBooking.WAITING,
                                    StatusBooking.PENDING
                                ].includes(props.status)
                            ) &&
                            <Stack direction="row" spacing={1}>
                                <Button onClick={() => onChangeProductBookingStatus(StatusBooking.CONFIRMED)}>
                                    {t('cart-material.provider-quotation-confirm')}
                                </Button>
                                <Button onClick={() => onChangeProductBookingStatus(StatusBooking.REFUSED)}>
                                    {t('cart-material.provider-quotation-refuse')}
                                </Button>
                                <Button onClick={() => onChangeProductBookingStatus(StatusBooking.UNAVAILABLE)}>
                                    {t('cart-material.provider-booking-unavailable')}
                                </Button>
                            </Stack>
                        }
                        {
                            props.status &&
                            [
                                StatusBooking.CONFIRMED,
                                StatusBooking.REFUSED,
                                StatusBooking.UNAVAILABLE
                            ].includes(props.status) &&
                            <Button onClick={() => onChangeProductBookingStatus(StatusBooking.PENDING)}>
                                {t('shared.cancel')}
                            </Button>
                        }
                    </Stack>
                </CardContent>
            </Card>
            {
                openCommentModal &&
                <ProviderBookingProductCommentModal
                    defaultComment={props.providerComment}
                    onConfirm={onConfirmCommentModal}
                    onClose={() => setOpenCommentModal(false)}
                />
            }
            <LoadingBackDrop open={loading} />
        </>
    );
}

const BookingNumberInput = styled('input')((props) => ({
    padding: 0,
    paddingLeft: props.theme.spacing(1),
    paddingRight: props.theme.spacing(1),
    width: '25%',
    border: `1px solid ${props.theme.palette.primary.main}`,
    fontSize: '0.75rem',
    borderRadius: 2,
    minWidth: 145
}));
