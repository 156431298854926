import { useCartProducts } from "../../../Itinerary/network/cartProducts";
import { itineraryToPolylinePath } from "./itineraryToPolylinePath";
import { StepsDirectionsManager } from "../../../Itinerary/utils/stepsDirectionsManager";
import { Itinerary } from "../../../Itinerary/objects/itinerary";
import { Key } from "../../../../Reducers/Reducers";

type Options = {
    from: Itinerary,
    to: Itinerary,
    products: ReturnType<typeof useCartProducts>['pois']
}

export async function createDestinationOnRoadPageUrl(options: Options): Promise<string> {
    const config = JSON.parse(localStorage.getItem('config') ?? '{}') as { keys?: Key[] };
    const key = config.keys?.find((item) => item.identifier === 'google_api');
    const directionsManager = StepsDirectionsManager.getInstance();
    const itineraryUrl = new URL('https://maps.googleapis.com/maps/api/staticmap');
    itineraryUrl.searchParams.append(
        'size',
        '985x943'
    );
    itineraryUrl.searchParams.append(
        'key',
        key?.value ?? ''
    );
    itineraryUrl.searchParams.append(
        'path',
        `color:0x48A6C9FF|geodesic:true|enc:${itineraryToPolylinePath([options.from])}`
    );
    itineraryUrl.searchParams.append(
        'style',
        'feature:poi|element:labels|visibility:off'
    );
    const markers = [
        await directionsManager.transformStepToCoordinates(options.from),
        await directionsManager.transformStepToCoordinates(options.to)
    ].map((item, index) => {
        return `color:blue|label:${index + 1}|${item.lat()},${item.lng()}`;
    });

    for (let i = 0; i < options.products.length; i++) {
        const product = options.products[i]!;
        if (product.type === 'normal') {
            markers.push(
                [
                    'color:0x4E69AFFF',
                    `label:${i + 1}`,
                    `${product.poi.custom_product?.place.latitude ?? '0'},${product.poi.custom_product?.place.longitude ?? '0'}`
                ].join('|')
            );
        }
    }

    for (const marker of markers) {
        itineraryUrl.searchParams.append(
            'markers',
            marker
        );
    }

    return itineraryUrl.toString();
}
