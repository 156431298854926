// Dependencies
import i18n from '../../i18n';
import axios from 'axios';
import React, {Fragment, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

// Core
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Collapse from '@material-ui/core/Collapse';
import green from '@material-ui/core/colors/green';
// Icons
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

// Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
// Functions
import GetProductType from "../Menu/TripList/Functions/GetProductType";
import { SetItineraryTypeDetails } from '../../Actions/ItineraryType';
import GetIdofItem from '../Booking/Functions/GetIdofItem';
import CheckResponse from '../Flight/FlightSelected/Functions/CheckResponse';
import LocaleText from '../Utils/localeText/localeText';
import LocaleTextVariant from '../Utils/localeText/localTextVariant';
import LocaleSelector from '../Utils/localeText/localeSelector';
import LocaleAutoTranslate from '../Utils/localeText/localeAutoTranslate';
import { updateVariantLocale } from '../../Actions/Cart';
// Components
// Actions

const useStyles = makeStyles({
    validateFullTripPrice: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    orangeSwitchLabel: {
        color: '#E6592F',
        textTransform: 'uppercase',
        fontSize: 14
    },
    orangeBackground: {
        '& .MuiSwitch-track': {
            backgroundColor: '#E6592F',
            opacity: "13%"
        }
    },
    selected: {
        background: '#EDF4FB'
    },
    packagedButton: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F',
        borderRadius: 15
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "70%",
        left: "50%",
        marginTop: -20,
        marginLeft: -12,
    },
    hover: {
        cursor: 'pointer'
    },
    blueText: {
        color: '#3367d6 !important'
    }
});


const CartHandleVariantModal = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const variant_modal = useSelector(state => state.cart.variant_modal);
    const all_data = useSelector(state => state.trip.all_data);
    const itinerary_type_details_data = useSelector(state => state.itinerary_type.itinerary_type_details_data);
    const locales = useSelector(state => state.user.locales);
    // all products
    const pois = useSelector(state => state.poi.cart);
    const flights = useSelector(state => state.flight.cart);
    const cars = useSelector(state => state.cars_search.cart);
    const transfers = useSelector(state => state.transfers.cart);
    const accommodations = useSelector(state => state.accommodation.sort_cart);
    const accommodations_cart = useSelector(state => state.accommodation.cart);
    const manual_products = useSelector(state => state.cart.manual_item_list);
    const current_locale = useSelector(state => state.user.current_locale);
    const variant_locales = useSelector(state => state.cart.variant_locales);

    const assistances = useSelector(state => state.cart.assistance_cart);
    const [variantName, setVariantName] = useState(itinerary_type_details_data !== null && itinerary_type_details_data.variant_name !== undefined ? [...itinerary_type_details_data.variant_name] : []);
    const [editIndex, setEditIndex] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [expandMore, setExpandMore] = useState(false);
    const [allProducts, setAllProducts] = useState([]);
    const [deleteWarning, setDeleteWarning] = useState(false);

    useEffect(() => {
        setAllProducts([...pois, ...flights, ...cars, ...transfers, ...accommodations, ...accommodations_cart, ...manual_products])
    }, [pois, flights, cars, transfers, accommodations, accommodations_cart, manual_products]);
    useEffect(() => {
        if (Object.keys(variant_locales).length !== 0 && editIndex !== null) {
            let edit_variant = variantName.find((variant) => variant.id === editIndex && variant.version === parseInt(GetCookie('trip_id_version')));
            let tmp_variant_name = [];
            console.log('edit_variant:', edit_variant);
            variantName.map((variant) => {
                if (edit_variant.id === variant.id && edit_variant.version === variant.version) {
                    let tmp = {...variant};
                    Object.keys(variant_locales).map((key) => {
                        tmp.name[key] = variant_locales[key];
                    });
                    variant = tmp;
                }
                tmp_variant_name.push(variant);
            });
            console.log('tmp_variant_name:', tmp_variant_name);
            // setVariantName(tmp_variant_name);
        }
    }, [variant_locales])
    const onClose = () => {
        if (deleteIndex !== null) {
            setDeleteIndex(null);
            setExpandMore(false);
        } else {
            setEditIndex(null);
            dispatch({type: 'CART_TOGGLE_VARIANTS_HANDLE'});
        }
    };

    const handleSave = () => {
        const { headers } = CheckBeforeRequest();
        let url = `${API_HREF}client/${window.id_owner}/${all_data.circuit_type ? 'circuits' : 'packages'}/${all_data.circuit_type ? all_data.circuit : all_data.package}/`
        axios({
            method: 'PATCH',
            headers: headers,
            url: url,
            data: {
                variant_name: variantName
            }
        }).then((response) => {
            dispatch(SetItineraryTypeDetails(response.data));
            onClose();
        }).catch((error) => {
            console.log('error:', error.response);
        })
    }
    const handleCancel = () => {
        if (!deleteWarning) {
            setVariantName([...itinerary_type_details_data.variant_name]);
            onClose();
        } else {
            setDeleteWarning(false);
        }
    }
    const handleEdit = (id) => () => {
        if (editIndex !== id)
            setEditIndex(id);
        else
            setEditIndex(null);
    }
    const handleDelete = (id) => () => {
        setDeleteIndex(id)
    }
    const onChangeVariantInput = (edit_variant) => (event) => {
        let tmp_variant_name = [];
        variantName.map((variant) => {
            if (edit_variant.id === variant.id && edit_variant.version === variant.version) {
                let tmp = {...variant};
                console.log('tmp:', tmp.name[current_locale]);
                if (typeof tmp.name === 'string') {
                    tmp.name = {}
                    tmp.name[current_locale] = event.target.value;
                } else {
                    tmp.name[current_locale] = event.target.value;
                }
                variant = tmp;
            }
            tmp_variant_name.push(variant);
        });
        setVariantName(tmp_variant_name);
    }
    const onAddVariant = () => {
        let other_variant = [...variantName].filter(variant => variant.version !== parseInt(GetCookie('trip_id_version')))
        let new_variant = [...variantName].filter(variant => variant.version === parseInt(GetCookie('trip_id_version')));
        new_variant.push({
            id: new_variant.length > 0 ? new_variant[new_variant.length -1].id + 1 : 1,
            name: {},
            version: parseInt(GetCookie('trip_id_version'))
        });
        setVariantName([...other_variant, ...new_variant]);
    }
    const handleExpand = () => {
        setExpandMore(!expandMore);
    }
    const patchProduct = (responses) => {
        responses.forEach((response) => {
            console.log('response data:', response);
            if (response.config.url.includes("manual-products")) {
                dispatch({type: 'CART_EDIT_MANUAL_CART_BY_ID', payload: {item: response.data}});
            }
            if (response.config.url.includes("flight")) {
                // let arr = CheckResponse([...response.data], trip_end_date);
                let tmp = [];
                tmp.push(response.data);
                let arr = CheckResponse(tmp);
                console.log('arr:', arr);
                dispatch({type: 'FLIGHT_EDIT_CART_BY_ID', payload: arr[0]});
            }
            if (response.config.url.includes("car")) {
                dispatch({type: 'CAR_EDIT_CART_BY_ID', payload: response.data});
            }
            if (response.config.url.includes("accommodation")) {
                let id = GetIdofItem(response.data.id, accommodations_cart);
                dispatch({type: "ACCOMMODATION_EDIT_CART_FROM_BDD", payload: { room: response.data, index: id}});
            }
            if (response.config.url.includes("transfers")) {
                dispatch({type: 'TRANSFER_EDIT_CART_BY_ID', payload: response.data});
            }
            if (response.config.url.includes("manual-products")) {
                dispatch({type: 'CART_EDIT_MANUAL_CART_BY_ID', payload: {item :response.data}});
            }
            if (response.config.url.includes("custom-products")) {
                if (response.data.product_type === 4) {
                    dispatch({type: 'TRANSFER_EDIT_CART_BY_ID', payload: response.data});
                }
                else {
                    dispatch({type: 'POI_EDIT_CART_BY_ID', payload: response.data});
                }
            }
        });
        setVariantName(variantName.filter((variant) => variant.id !== deleteIndex && variant.version === parseInt(GetCookie('trip_id_version'))));
        setDeleteIndex(null);
        setExpandMore(false);
        handleSave();
    }
    const onRemoveVariant = () => {
        const { headers } = CheckBeforeRequest();
        let request = [];
        accommodations.forEach((accommodation) => {
            accommodation.rooms.forEach((room) => {
                if (room.variant !== null && room.variant === deleteIndex) {
                    request.push(
                        axios({
                            method: "PATCH",
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                            headers: headers,
                            data: {
                                variant: null
                            },
                        })
                    )
                }
            })
        });
        cars.forEach((car) =>{
            if (car.variant !== null && car.variant === deleteIndex) {
                request.push(
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${car.id}/`,
                        headers: headers,
                        data: {
                            variant: null
                        },
                    })
                )
            }
        });
        flights.forEach((flight) => {
            if (flight.variant !== null && flight.variant === deleteIndex) {
                request.push(
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`,
                        headers: headers,
                        data: {
                            variant: null
                        },
                    })
                )
            }
        });
        pois.forEach((poi) => {
            if (poi.variant !== null && poi.variant === deleteIndex) {
                request.push(
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${poi.id}/`,
                        headers: headers,
                        data: {
                            variant: null
                        },
                    })
                )
            }
        });
        transfers.forEach((transfer) => {
            if (transfer.variant !== null && transfer.variant === deleteIndex) {
                request.push(
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/transfers/${transfer.id}/`,
                        headers: headers,
                        data: {
                            variant: null
                        },
                    })
                )
            }
        });
        manual_products.forEach((manual_product) => {
            if (manual_product.variant !== null && manual_product.variant === deleteIndex) {
                request.push(
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
                        headers: headers,
                        data: {
                            variant: null
                        },
                    })
                )
            }
        });
        if (request.length > 0) {
            axios.all([...request]).then((responses) => {
                patchProduct(responses);
            }).catch((error) => {
                console.log('error remove variant:', error.response);
            });
        }
    }
    const handleDeleteProducts = () => {
        const { headers } = CheckBeforeRequest();
        accommodations.forEach((accommodation) => {
            accommodation.rooms.forEach((room) => {
                if (room.variant !== null && room.variant === deleteIndex) {
                    axios({
                        method: "DELETE",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                        headers: headers,
                    }).then(() => {
                        dispatch({type: 'ACCOMMODATION_REMOVE_FROM_CART_BY_ID', payload: room.id});
                    }).catch(error => {
                        console.log('catch delete accommodation error', error);
                    });
                }
            })
        });
        cars.forEach((car) =>{
            if (car.variant !== null && car.variant === deleteIndex) {
                axios({
                    method: "DELETE",
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${car.id}/`,
                    headers: headers,
                }).then(() => {
                    dispatch({type: 'CAR_REMOVE_FROM_CART_BY_ID', payload: car.id});
                }).catch(error => {
                    console.log('catch delete car error', error);
                })
            }
        });
        flights.forEach((flight) => {
            if (flight.variant !== null && flight.variant === deleteIndex) {
                axios({
                    method: "DELETE",
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`,
                    headers: headers,
                }).then(() => {
                    dispatch({type: 'FLIGHT_REMOVE_FLIGHT_FROM_CART_BY_ID', payload: flight.id});
                }).catch(error => {
                    console.log('catch delete flight error', error);
                })
            }
        });
        pois.forEach((poi) => {
            if (poi.variant !== null && poi.variant === deleteIndex) {
                axios({
                    method: "DELETE",
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${poi.id}/`,
                    headers: headers,
                }).then(() => {
                    dispatch({type: 'POI_REMOVE_CART_BY_ID', payload: poi.id});
                }).catch(error => {
                    console.log('catch delete poi error', error);
                })
            }
        });
        transfers.forEach((transfer) => {
            if (transfer.variant !== null && transfer.variant === deleteIndex) {
                axios({
                    method: "DELETE",
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/transfers/${transfer.id}/`,
                    headers: headers,
                }).then(() => {
                    dispatch({type: 'TRANSFER_REMOVE_FROM_CART_BY_ID', payload: transfer.id});
                }).catch(error => {
                    console.log('catch delete transfer error', error);
                })
            }
        });
        manual_products.forEach((manual_product) => {
            if (manual_product.variant !== null && manual_product.variant === deleteIndex) {
                axios({
                    method: "DELETE",
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
                    headers: headers,
                }).then(() => {
                    dispatch({type: 'CART_MANUAL_PRODUCT_REMOVE_FROM_CART_BY_ID', payload: manual_product.id});
                }).catch(error => {
                    console.log('catch delete manual product error', error);
                })
            }
        });
        setVariantName(variantName.filter((variant) => variant.id !== deleteIndex && variant.version === parseInt(GetCookie('trip_id_version'))));
        setDeleteIndex(null);
        setExpandMore(false);
        setDeleteWarning(false);
        handleSave();
    }
    const onDeleteVariant = () => {
        if (allProducts.find((product) => product.variant === deleteIndex && product.trip_version === parseInt(GetCookie('trip_id_version'))) !== undefined) {
            setDeleteWarning(true);
        } else {
            setVariantName(variantName.filter((variant) => variant.version !== parseInt(GetCookie('trip_id_version')) || variant.id !== deleteIndex));
            setDeleteIndex(null);
            setExpandMore(false);
        }
    }
    const onAssignVariant = (newVariant) => () => {
        const { headers } = CheckBeforeRequest();
        let request = [];
        accommodations.forEach((accommodation) => {
            accommodation.rooms.forEach((room) => {
                if (room.variant !== null && room.variant === deleteIndex) {
                    request.push(
                        axios({
                            method: "PATCH",
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                            headers: headers,
                            data: {
                                variant: newVariant
                            },
                        })
                    )
                }
            })
        });
        cars.forEach((car) =>{
            if (car.variant !== null && car.variant === deleteIndex) {
                request.push(
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/car/${car.id}/`,
                        headers: headers,
                        data: {
                            variant: newVariant
                        },
                    })
                )
            }
        });
        flights.forEach((flight) => {
            if (flight.variant !== null && flight.variant === deleteIndex) {
                request.push(
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/flight/${flight.id}/`,
                        headers: headers,
                        data: {
                            variant: newVariant
                        },
                    })
                    )
            }
        });
        pois.forEach((poi) => {
            if (poi.variant !== null && poi.variant === deleteIndex) {
                request.push(
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${poi.id}/`,
                        headers: headers,
                        data: {
                            variant: newVariant
                        },
                    })
                )
            }
        });
        transfers.forEach((transfer) => {
            if (transfer.variant !== null && transfer.variant === deleteIndex) {
                request.push(
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/transfers/${transfer.id}/`,
                        headers: headers,
                        data: {
                            variant: newVariant
                        },
                    })
                )
            }
        });
        manual_products.forEach((manual_product) => {
            if (manual_product.variant !== null && manual_product.variant === deleteIndex) {
                request.push(
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/manual-products/${manual_product.id}/`,
                        headers: headers,
                        data: {
                            variant: newVariant
                        },
                    })
                )
            }
        });
        if (request.length > 0) {
            axios.all([...request]).then((responses) => {
                patchProduct(responses);
            }).catch((error) => {
                console.log('error assign variant:', error);
            });
        }
    }
    console.log('variantName:', variantName);
    return (
        <Dialog fullScreen={ fullScreen } fullWidth maxWidth={'lg'} PaperProps={{style: { borderRadius: fullScreen ? 0 : 16 } }} open={ variant_modal } onClose={ onClose } disableEscapeKeyDown disableBackdropClick>
            <DialogTitle onClose={ onClose }>
                <Grid container justify={ 'space-between' } alignItems={ 'center' }>
                    {
                        fullScreen ? (
                            <Grid item><IconButton edge={ 'start' } onClick={ onClose }><NavigateBefore/></IconButton> { t('cart-material.handle-variants') }</Grid>
                        ) : (
                            <Fragment>
                                <Grid item xs={ 3 }/>
                                <Grid item xs={ 3 } style={{textAlign: 'center'}}>{ t('cart-material.handle-variants') }</Grid>
                                <Grid item xs={ 3 }><IconButton onClick={onClose} style={{float: 'right'}}><Close/></IconButton></Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogTitle>
            {
                deleteIndex === null && (
                    <Fragment>
                        <DialogContent>
                            <Toolbar disableGutters={true}>
                                <Grid container direction={'row-reverse'}>
                                    <Grid item>
                                        <Button className={ classes.blueText } startIcon={ <Add/> } onClick={ onAddVariant }>{ t('cart-material.add-variant') }</Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                            <TableContainer key={parseInt(GetCookie('trip_id_version'))} component={Paper}>
                                <Table>
                                    <TableBody>
                                        {
                                            variantName.map((variant) => {
                                                if (variant.version === parseInt(GetCookie('trip_id_version'))) {
                                                    return (
                                                        <TableRow
                                                            key={variant.id}
                                                            // onClick={() => props.onClickVariant && props.onClickVariant(version.id, variant.id)}
                                                            tabIndex={ -1 }
                                                            hover
                                                            className={`${classes.hover}`}
                                                        >
                                                            <TableCell style={{ paddingLeft: 5 }}>
                                                                <Grid container justify={'space-between'}>
                                                                    <Grid item xs>
                                                                        <Fragment>
                                                                            {
                                                                                editIndex !== variant.id ?
                                                                                <Fragment>
                                                                                    <LocaleTextVariant localization={variant.name} default_text={variant.name !== undefined && typeof variant.name === 'string' && variant.name !== "" ? variant.name : `${t("itineraryType.variant")} ${variant.id}`}/>
                                                                                </Fragment>
                                                                                :
                                                                                // <ClickAwayListener
                                                                                //         mouseEvent="onMouseDown"
                                                                                //         onClickAway={handleEdit(variant.id)}
                                                                                //     >
                                                                                <Accordion defaultExpanded>
                                                                                    <AccordionDetails> 
                                                                                        <Grid container>
                                                                                            <Grid item xs={ 6 }>
                                                                                                <Grid container spacing={ 2 } >
                                                                                                    <Grid item xs={ 6 }>
                                                                                                        <LocaleSelector id={ `variante-name-${variant.id}` } values={typeof variant.name === 'string' ? { [current_locale ?? 1]: variant.name ?? '' } : (variant.name ?? {})}/>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={ 12 }>
                                                                                                        <TextField
                                                                                                            variant={'outlined'}
                                                                                                            size={'small'}
                                                                                                            fullWidth
                                                                                                            value={(!(typeof variant.name === 'string') && current_locale !== null ? variant.name[current_locale] : variant.name) ?? ''}
                                                                                                            onChange={onChangeVariantInput(variant)}
                                                                                                            style={{ marginLeft: 1 }}
                                                                                                        />
                                                                                                        {/* <CartVarianteNameLocale/> */}
                                                                                                    </Grid>
                                                                                                    <Grid item xs={ 12 }>
                                                                                                        <LocaleAutoTranslate id={ `variante-name-${variant.id}` }  values={typeof variant.name === 'string' ? { [current_locale ?? 1]: variant.name ?? '' } : (variant.name ?? {})} action={ updateVariantLocale }/>
                                                                                                    </Grid>
                                                                                                    <Grid item>
                                                                                                        <Button variant={'text'} onClick={handleEdit(variant.id)}>{t('global.close')}</Button>
                                                                                                    </Grid>
                                                                                                </Grid> 
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                                // </ClickAwayListener> 
                                                                            }
                                                                            {
                                                                                editIndex !== variant.id &&
                                                                                <IconButton
                                                                                    onClick={handleEdit(variant.id)}
                                                                                    size={"small"}
                                                                                    style={{marginLeft: 5}}
                                                                                >
                                                                                    <Edit fontSize={"small"}/>
                                                                                </IconButton>
                                                                            }
                                                                        </Fragment>
                                                                    </Grid>
                                                                    {
                                                                        editIndex !== variant.id &&
                                                                        <Grid>
                                                                            <IconButton
                                                                                onClick={handleDelete(variant.id)}
                                                                                size={"small"}
                                                                                style={{marginLeft: 5}}
                                                                            >
                                                                                <Delete fontSize={"small"} />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                }
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel} color="primary">{t('shared.cancel')}</Button>
                            <Button onClick={handleSave} color="primary">{t('shared.save-changes')}</Button>
                        </DialogActions>
                    </Fragment>
                )
            }
            {
                deleteIndex !== null && !deleteWarning && (
                    <Fragment>
                        <DialogContent style={{ overflow: "hidden" }}>
                            <Grid container direction={ 'column' } spacing={ 4 }>
                                <Grid item>
                                    <Typography style={{fontWeight: 'bold', textAlign: 'center'}}>{t('cart-material.delete-variant-confirmation', {index: deleteIndex})}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={ 4 } justify={ 'space-around' } style={{width: '100%'}}>
                                        {
                                            allProducts.find((product) => product.variant === deleteIndex && product.trip_version === parseInt(GetCookie('trip_id_version'))) !== undefined &&
                                            <Fragment>
                                                <Grid item>
                                                    <Grid container direction={'column'}>
                                                        <Grid item>
                                                            <Button color="primary" variant={ 'outlined' } onClick={handleExpand} endIcon={!expandMore ? <ExpandMore/> : <ExpandLess/>}>{t('cart-material.re-assign-product')}</Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Collapse in={expandMore}>
                                                                <List>
                                                                {
                                                                    variantName.map((variant) => {
                                                                        if (variant.id !== deleteIndex && variant.version === parseInt(GetCookie('trip_id_version'))) {
                                                                            return (
                                                                                <ListItem key={variant.id} button onClick={onAssignVariant(variant.id)}>{variant.name !== undefined && variant.name !== "" ? variant.name : `${t("itineraryType.variant")} ${variant.id}`}</ListItem>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                                </List>
                                                            </Collapse>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Button color="primary" variant={ 'outlined' } onClick={onRemoveVariant}>{t('cart-material.remove-products', {index: deleteIndex})}</Button>
                                                </Grid>
                                            </Fragment>
                                        }
                                        <Grid item>
                                            <Button color="primary" variant={ 'outlined' } onClick={onDeleteVariant}>{t('shared.delete')}</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Fragment>
                )
            }
            {
                deleteIndex !== null && deleteWarning && (
                    <DialogContent style={{ overflow: "hidden" }}>
                        <Grid container direction={ 'column' } spacing={ 4 }>
                            <Grid item>
                                <Typography style={{fontWeight: 'bold', textAlign: 'center'}}>{t('cart-material.delete-product-confirmation', {index: deleteIndex})}</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={ 4 } justify={ 'space-around' } style={{width: '100%'}}>
                                    <Button onClick={handleCancel} color="primary">{t('shared.cancel')}</Button>
                                    <Button onClick={handleDeleteProducts} color="primary">{t('menu.invoice.confirm')}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                )
            }
        </Dialog>
    );
};

export default CartHandleVariantModal;