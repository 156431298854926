export default (arr, permission, user) => {
	let has_permission = false;

	if (user !== undefined && user !== null && user.is_superuser === true) {
		return true;
	}

	if (arr !== null) {
		for (let i = 0; i < arr.length; i++) {
			if (arr[i].codename === permission) {
				has_permission = true;
				break;
			}
		}		
	}

	return has_permission;
};
