//---- Actions ----//
import { SetItinerary, SetDepartureDestination, SetReturnDestination } from "../../../Actions/Itinerary";

//---- Commons ----//
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import Rome2Rio from "../../Common/Rome2Rio";
import Notify from "../../Common/Notify";
import GetCookie from "../../Common/Functions/GetCookie";

export default (pre_request, itinerary_list, currency, dispatch, start_date, end_date, t, user) => {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        if (pre_request.place_id_departure !== undefined && pre_request.place_id_departure !== null) {
            let add = true;
            let index = null;
            for (let i = 0; i < itinerary_list.length; i++) {
                if (itinerary_list[i].step_type === "START") {
                    index = i;
                    add = false;
                }
            }
            if (add) {
                let request = {
                    start_date: start_date,
                    end_date: moment.utc(start_date).add(9, "h").format(),
                    places_id: pre_request.place_id_departure,
                    city_name: pre_request.city_departure,
                    country_name: pre_request.country_departure,
                    step_type: "START"
                };
                $.ajax({
                    method: "POST",
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/?token=${GetCookie("trip_token")}&shift_mode=false`,
                    headers: headers,
                    data: JSON.stringify(request),
                    success: function (data) {
                        console.log(data);
                        dispatch(SetItinerary(data));
                        let departure_destination = {
                            city: pre_request.city_departure,
                            country: pre_request.country_departure,
                            place_id: pre_request.place_id_departure,
                            lat: pre_request.lat,
                            lng: pre_request.lng,
                            start_date: data[0].start_date,
                            end_date: data[0].end_date,
                            retrieve: true
                        };
                        dispatch(SetDepartureDestination(departure_destination));
                        if (data[0].step_type === "START" && data.length > 1 && data[1].step_type === "STEP") {
                            let departure_arrival = "&oPos=" + pre_request.lat.toString() + "," + pre_request.lng.toString();
                            let arrival_arrival = "&dPos=" + data[1].destination.data.latitude.toString() + "," + data[1].destination.data.longitude.toString();
                            console.log(departure_arrival);
                            Rome2Rio(departure_arrival, arrival_arrival, 0, currency, dispatch, data, t, 2, start_date, end_date, user);
                        }
                    },
                    error: function (data) {
                        console.log(data);
                        data.responseJSON.error !== undefined && data.responseJSON.error.map((error) => {
                            Notify(error, { status: "danger", pos: "top-center"})
                        });
                    }
                });
            } else if (index !== null) {
                let request = {
                    start_date: start_date,
                    end_date: moment.utc(start_date).add(9, "h").format(),
                    places_id: pre_request.place_id_departure,
                    city_name: pre_request.city_departure,
                    country_name: pre_request.country_departure,
                };
                $.ajax({
                    method: "PATCH",
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/${itinerary_list[index].id}/?token=${GetCookie("trip_token")}&shift_mode=false`,
                    headers: headers,
                    data: JSON.stringify(request),
                    success: function (data) {
                        console.log(data);
                        dispatch(SetItinerary(data));
                        let departure_destination = {
                            city: pre_request.city_departure,
                            country: pre_request.country_departure,
                            place_id: pre_request.place_id_departure,
                            lat: pre_request.lat,
                            lng: pre_request.lng,
                            start_date: data[0].start_date,
                            end_date: data[0].end_date,
                            retrieve: true
                        };
                        dispatch(SetDepartureDestination(departure_destination));
                        if (data[0].step_type === "START" && data.length > 1 && data[1].step_type === "STEP") {
                            let departure_arrival = "&oPos=" + pre_request.lat.toString() + "," + pre_request.lng.toString();
                            let arrival_arrival = "&dPos=" + data[1].destination.data.latitude.toString() + "," + data[1].destination.data.longitude.toString();
                            console.log(departure_arrival);
                            Rome2Rio(departure_arrival, arrival_arrival, 0, currency, dispatch, data, t, 2, start_date, end_date, user);
                        }
                    },
                    error: function (data) {
                        console.log(data);
                        data.responseJSON.error !== undefined && data.responseJSON.error.map((error) => {
                            Notify(error, { status: "danger", pos: "top-center"})
                        });
                    }
                });
            }
        }
        if (pre_request.place_id_return !== undefined && pre_request.place_id_return !== null) {
            let add = true;
            let index = null;
            for (let i = 0; i < itinerary_list.length; i++) {
                if (itinerary_list[i].step_type === "END") {
                    add = false;
                    index = i;
                }
            }
            if (add) {
                let request = {
                    places_id: pre_request.place_id_return,
                    city_name: pre_request.city_return,
                    country_name: pre_request.country_return,
                    step_type: "END"
                };
                if (itinerary_list.length > 0) {
                    request.start_date = moment.utc(itinerary_list[itinerary_list.length - 1].end_date).add(1, "minutes").format();
                    request.end_date = moment.utc(request.start_date).add(10, "minutes").format();
                } else {
                    request.start_date = moment.utc(start_date).add(9, "h").format();
                    request.end_date = moment.utc(request.start_date).add(10, "minutes").format();
                }
                $.ajax({
                    method: "POST",
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/?token=${GetCookie("trip_token")}&shift_mode=false`,
                    headers: headers,
                    data: JSON.stringify(request),
                    success: function (data) {
                        console.log(data);
                        dispatch(SetItinerary(data));
                        let return_destination = {
                            city: pre_request.city_return,
                            country: pre_request.country_return,
                            place_id: pre_request.place_id_return,
                            lat: pre_request.lat,
                            lng: pre_request.lng,
                            start_date: data[data.length - 1].start_date,
                            end_date: data[data.length - 1].end_date,
                            retrieve: true
                        };
                        dispatch(SetReturnDestination(return_destination));
                        if (data[data.length - 1].step_type === "END" && data.length > 1 && data[data.length - 2].step_type === "STEP") {
                            let departure_departure = "&oPos=" + data[data.length - 2].destination.data.latitude.toString() + "," + data[data.length - 2].destination.data.longitude.toString();
                            let arrival_departure = "&dPos=" + pre_request.lat.toString() + "," + pre_request.lng.toString();
                            console.log(arrival_departure);
                            setTimeout(function () {
                                Rome2Rio(departure_departure, arrival_departure, data.length - 2, currency, dispatch, data, t, 2, start_date, end_date, user);
                            }, 1000);
                        }
                    },
                    error: function (data) {
                        console.log(data);
                        data.responseJSON.error !== undefined && data.responseJSON.error.map((error) => {
                            Notify(error, { status: "danger", pos: "top-center"})
                        });
                    }
                });
            } else if (index !== null) {
                let request = {
                    places_id: pre_request.place_id_return,
                    city_name: pre_request.city_return,
                    country_name: pre_request.country_return,
                };
                if (itinerary_list.length > 0) {
                    request.start_date = itinerary_list[itinerary_list.length - 1].end_date;
                    request.end_date = moment.utc(request.start_date).add(10, "minutes").format();
                } else {
                    request.start_date = moment.utc(start_date).add(9, "h").format();
                    request.end_date = moment.utc(request.start_date).add(10, "minutes").format();
                }
                $.ajax({
                    method: "PATCH",
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/${itinerary_list[index].id}/?token=${GetCookie("trip_token")}&shift_mode=false`,
                    headers: headers,
                    data: JSON.stringify(request),
                    success: function (data) {
                        console.log(data);
                        dispatch(SetItinerary(data));
                        let return_destination = {
                            city: pre_request.city_return,
                            country: pre_request.country_return,
                            place_id: pre_request.place_id_return,
                            lat: pre_request.lat,
                            lng: pre_request.lng,
                            start_date: data[data.length - 1].start_date,
                            end_date: data[data.length - 1].end_date,
                            retrieve: true
                        };
                        dispatch(SetReturnDestination(return_destination));
                        if (data[data.length - 1].step_type === "END" && data.length > 1 && data[data.length - 2].step_type === "STEP") {
                            let departure_departure = "&oPos=" + data[data.length - 2].destination.data.latitude.toString() + "," + data[data.length - 2].destination.data.longitude.toString();
                            let arrival_departure = "&dPos=" + pre_request.lat.toString() + "," + pre_request.lng.toString();
                            console.log(arrival_departure);
                            setTimeout(function () {
                                Rome2Rio(departure_departure, arrival_departure, data.length - 2, currency, dispatch, data, t, 2, start_date, end_date, user);
                            }, 1000);
                        }
                    },
                    error: function (data) {
                        console.log(data);
                        data.responseJSON.error !== undefined && data.responseJSON.error.map((error) => {
                            Notify(error, { status: "danger", pos: "top-center"})
                        });
                    }
                });
            }
        }
    }
}