import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSnackbar } from "notistack";
import clsx from "clsx";
import axios from "axios";
import { SetMarginStep, SetMarginStepAndUpdate } from "../../../Actions/Menu";

import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetProductType from "../TripList/Functions/GetProductType";
import HasPermission from "../../Common/Functions/HasPermission";

const useRowStyles = makeStyles({
    returnButton: {
	    "color": "grey",
	    "backgroundColor": "white",
	    '&:hover': {
	        color: "white",
	        backgroundColor: "grey"
	    }
    },
    inputBorder: {
	    "&& .mui-jss-MuiOutlinedInput-root": {
	        borderRadius: 8
	    }
    },
    genericText: {
        color: "#0000008A",
        fontStyle: "normal",
        fontFamily: "Roboto"
    },
    bold: {
    	fontWeight: "bold"
    },
    footer: {
        position: "sticky",
        bottom: 0,
        zIndex: 2,
        backgroundColor: "white"
    },
    footerMobile: {
        position: "sticky",
        bottom: 64,
        zIndex: 2,
        backgroundColor: "white"
    },
    orangeButton: {
        color: "white",
        backgroundColor: "#E6592F"
    },
    green: {
        color: green[500]
    },
    red: {
        color: red[500]
    }
});

const RenderMargin = ({ margin }) => {
    const { t } = useTranslation();
    const classes = useRowStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down("sm"));

    const user = useSelector(store => store.user.user);
    const list_currency = useSelector(store => store.base.currency_list);
    const permissions = useSelector(store => store.user.permissions);

    const returnButton = () => {
        dispatch(SetMarginStep(1));
    };
    const desactivate = () => {
        let today = new Date();
        let { pass_check, headers } = CheckBeforeRequest();
        let request = {
            name: margin.name,
            disabled_date: today
        };
        if (pass_check) {
            axios({
                method: "PATCH",
                headers: headers,
                url: `${API_HREF}client/${user.client}/margins/${margin.id}/`,
                data: JSON.stringify(request)
            }).then(function (response) {
                dispatch(SetMarginStepAndUpdate(1, true));
            }).catch(function (error) {
                //TODO: show snackbar error
                console.log('error:', error);
            });
        }
    };
    let my_currency = null;
    let product_type = GetProductType(margin.content_types[0]);
    if (margin.type_rate !== "PER") {
        my_currency = list_currency.find((currency) => {
            return currency.id === margin.currency_rate.id; 
        });
    }
    return (
        <Fragment>
            <Grid item xs={12}>
                <Grid container direction={"row"} justify={"flex-start"} alignItems={"center"}>
                    <Grid item>
                        <Button
                            variant={"contained"}
                            className={classes.returnButton}
                            onClick={returnButton}
                        >
                            {t("menu.margin.return_to_tab")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <Typography variant="h4" gutterBottom className={clsx(classes.genericText, { [classes.red]: margin.disabled_date !== null, [classes.green]: margin.disabled_date === null })}>
                    {margin.disabled_date === null ? t("menu.margin.active") : t("menu.margin.desactivated")}
                </Typography>
            </Grid>
            <Divider/>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid container direction={"column"} spacing={0} style={{ marginBottom: 10 }}>
                    <Grid item><Typography variant={"h6"}>{ t("menu.margin.margin_name") }</Typography></Grid>
                    <Grid item>{ margin.name }</Grid>
                </Grid>
            </Grid>
            <Divider/>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid container direction={"column"} spacing={0} style={{ marginBottom: 10 }}>
                    <Grid item><Typography variant={"h6"}>{ t("menu.margin.margin") }</Typography></Grid>
                    <Grid item>
                        <span> {margin.rate} </span> 
                        <span>{margin.type_rate === "FIX" ? my_currency.symbol : "%"}</span>
                    </Grid>
                </Grid>
            </Grid>
            <Divider/>
            {
                margin.destinations.length > 0 && (
                    <Fragment>
                        <Grid item xs={12} style={{ marginTop: 20 }}>
                            <Grid container direction={"column"} spacing={0} style={{ marginBottom: 10 }}>
                                <Grid item><Typography variant={"h6"}>{ t("menu.margin.margin_for_dest") }</Typography></Grid>
                                <Grid item>{ margin.destinations[0] }</Grid>
                            </Grid>
                        </Grid>
                        <Divider/>
                    </Fragment>
                )
            }
            {
                margin.content_types.length > 0 && (
                    <Fragment>
                        <Grid item xs={12} style={{ marginTop: 20 }}>
                            <Grid container direction={"column"} spacing={0} style={{ marginBottom: 10 }}>
                                <Grid item><Typography variant={"h6"}>{ t("menu.margin.margin_for_content") }</Typography></Grid>
                                <Grid item> { t("providers." + product_type) }</Grid>
                            </Grid>
                        </Grid>
                        <Divider/>
                    </Fragment>
                )
            }
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid container direction={"column"} spacing={0} style={{ marginBottom: 10 }}>
                    <Grid item><Typography variant={"h6"}>{ t("menu.margin.date_of_margin") }</Typography></Grid>
                    <Grid item>
                        <span>{t("header.responsive.start")} </span>
                        {
                            margin.start_date !== null ? " " + moment.utc(margin.start_date).format("DD-MM-YYYY") + " " : " - "
                        }
                        <span>{t("header.responsive.end")} </span>
                        {
                            margin.end_date !== null ? " " + moment.utc(margin.end_date).format("DD-MM-YYYY") + " " : " - "
                        }  
                    </Grid>
                </Grid>
            </Grid>
            {
                margin.disabled_date === null && (
                    <Grid item style={{ marginTop: 20 }}>
                        <Grid container direction={downDesktop ? "column" : "row"} justify={downDesktop ? "center" : "flex-end"} alignItems={"center"} spacing={0} style={{ marginBottom: 10 }}>
                            <Grid item>
                                <Button className={classes.orangeButton} onClick={() => {
                                    if (HasPermission(permissions, "delete_marginprice", user) === true) {
                                        desactivate();
                                    } else {
                                        enqueueSnackbar(t("notify.no_permissions"), { variant: "error" });
                                    }
                                }}>
                                    {t("menu.margin.desactivate_margin")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )  
            }
        </Fragment>
    );
};
export default React.memo(RenderMargin);
