import React, { Fragment, useState, useEffect, useRef, useContext } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AcUnitOutlinedIcon from '@material-ui/icons/AcUnitOutlined';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';

import { CartConstructionReplaceProductContext } from "../CartMaterial/utils/cartConstructionReplaceProductContext";

import DoorIcon from "./DoorIcon.js";
import GearIcon from "./GearIcon.js";
import AddToCart from "./AddToCart.js";


import GetProvider from "../Common/Functions/GetProvider.js";
import DeleteCars from '../Cars/Functions/DeleteCars';

const main_color = JSON.parse(localStorage.getItem('config') ?? '{}').main_color;

const useStyles = makeStyles(theme => ({
    centerPic: {
        display: "block",
        marginRight: "auto",
        marginLeft: "auto"
    },
    carCardImgInfo: {
        maxWidth: "70%",
        maxHeight: "100%"
    },
    providerSmallPic: {
    	height: 20
    },
    carsSmallPic: {
        width: 300,
        paddingTop: 15
    },
    carCardProvider: {
        position: "absolute",
        left: 0,
        top: 0,
        maxWidth: 90
    },
    sub30: {
	    left: 30
    },
    sub50: {
        left: 50
    },
    relativePosition: {
        position: "relative"
    },
    marginTop10: {
        marginTop: 10
    },
    marginBottom10: {
        marginBottom: 10
    },
    marginBottom100: {
        marginBottom: 100
    },
    marginLeft_4: {
        marginLeft: "-4px"
    },
    marginLeft5: {
        marginLeft: 5
    },
    marginLeft24: {
        marginLeft: 24
    },
    marginTop25: {
        marginTop: 25
    },
    cardPadding: {
        padding: 16
    },
    font12: {
        fontSize: 12
    },
    font14: {
        fontSize: 14
    },
    font15: {
        fontSize: 15
    },
    font20: {
        fontSize: 20
    },
    carCardCategory: {
	    textTransform: "uppercase",
	    fontSize: 12
    },
    carCardInfo: {
        display: 'inline-block',
        marginRight: 11
    },
    carCardInfoFlex: {
        display: 'inline-flex',
        marginRight: 11
    },
    ftGray: {
        opacity: '54%'
    },
    bold: {
        fontWeight: "bold"
    },
    bottomAlign: {
        verticalAlign: "bottom"
    },
    orangeButton: {
        backgroundColor: `${main_color}`,
        color: "white"
    },
    buttonProgress: {
	    color: green[500],
	    position: 'absolute',
        top: "50%",
        left: "50%",
        marginLeft: -12
    },
    textCenter: {
        textAlign: 'center'
    },
    staticPriceContainer: {
	    position: 'fixed',
	    bottom: 0,
	    backgroundColor: 'white',
	    marginLeft: -33,
	    padding: 16,
	    borderTop: '1px solid lightgray'
    }
}));

const RenderCardContent = React.forwardRef((props, ref) => {
    const { contract_info, cars_results, step_info, location_index, car_index, setLocationIndex, setCarIndex, agency_options, car_contract, router, enqueueSnackbar } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const provider_list = useSelector((store) => {
        return store.trip.providers;
    });
    const user = useSelector((store) => {
        return store.user.user;
    });
    const language = useSelector((store) => {
        return store.header.tmp_language;
    });
    const currency_list = useSelector((store) => {
        return store.base.currency_list;
    });
    const travelers = useSelector((store) => {
        return store.trip.traveler_groups;
    });
    const cart_id = useSelector((store) => {
        return store.cars_search.cart_id;
    });
    const cart_index = useSelector((store) => {
        return store.cars_search.cart_index;
    });
    const itinerary_list = useSelector((store) => {
        return store.itinerary.itinerary_list;
    });
    const [pic_height, setPicHeight] = useState(0);
    const [pic_width, setPicWidth] = useState(0);
    const [subprovider_width, setSubProviderWidth] = useState(0);
    const [request_status, setRequestStatus] = useState(false);
    const [open, setOpen] = useState(false);
    const replaceProductContext = useContext(CartConstructionReplaceProductContext);

    const selected_car = contract_info.selected_car;
    const provider = GetProvider(provider_list, selected_car.provider_id);
    const subprovider = GetProvider(provider_list, selected_car.subprovider_id);

    const car_name = (selected_car.vehicle_info.name).split(" OR SIMILAR");

    const step = step_info[location_index];
    let a = moment(step.return_date ? step.return_date : step.end_date_dest);
    let b = moment(step.origin_date ? step.origin_date : step.start_date_origin);
    let days = Math.ceil(a.diff(b, 'hours', true) / 24);
    let agency_go = null;
    let agency_return = null;

    let tmp_price = null;
    let selected_currency = null;
    if (selected_car.station_prices) {
        selected_car.station_prices.map((station) => {
            if (station.pickup_station === (selected_car.location_code_pickup ? selected_car.location_code_pickup : contract_info.stations.pick_up[contract_info.checked].code)) {
                tmp_price = station.prices[0].selling_price;
                selected_currency = station.prices[0].selling_currency;
            }
        });
    } else {
        tmp_price = selected_car.selling_price;
        selected_currency = selected_car.currency;
    }
    if (car_contract !== null && car_contract.selected !== undefined) {
        let tmp_contract = car_contract.contracts.find((el) => {
            return el.contract_id === car_contract.selected;
        });
        tmp_price = tmp_contract.prices[0].selling_price;
        selected_currency = tmp_contract.prices[0].selling_currency;
    }
    let currency_code = currency_list.find((currency) => {
        return (currency.id === selected_currency);
    });
    agency_go = contract_info.stations !== undefined ? contract_info.stations.pick_up[contract_info.checked] : contract_info.selected_car.location_pickup_name;
    agency_return = contract_info.stations !== undefined ? contract_info.stations.return[contract_info.checked_return] : contract_info.selected_car.location_return_name;
    if (agency_return === null) {
        agency_return = agency_go;
    }
    let find = agency_options ? agency_options.find((elem) => {
        return elem.quantity > 0;
    }) : null;
    let selected_car_price = new Intl.NumberFormat(language, currency_code ? { style: 'currency', currency: currency_code.iso_code } : {}).format(tmp_price);
    let onsite_total = 0;
    let onsite_currency_code = null;
    if (agency_options !== null && agency_options.length > 0) {
        agency_options.map((option, option_index) => {
            onsite_currency_code = option.currency_code;
        });
    }
    const checkFare = (fares) => {
        let empty = true;
        fares.map((fare) => {
            if (fare.price > 0) {
                empty = false;
            }
        });
        return empty;
    };
    let check_fare = selected_car.station_prices ? checkFare(selected_car.station_prices[0].onsite_fares) : selected_car.onsite_fares ? checkFare(selected_car.onsite_fares) : "";
    car_contract && car_contract.contracts.map((contract) => {
        if (contract.contract_id === car_contract.selected) {
            check_fare = checkFare(contract.onsite_fares);
        }
    });
    const checkContract = () => {
        if (ref.current !== null) {
            ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        setTimeout(() => {
            if (car_contract.selected !== undefined) {
                if (selected_car.id === undefined) {
                    AddToCart(
                        contract_info,
                        car_contract,
                        step_info,
                        location_index,
                        car_index,
                        agency_options,
                        travelers,
                        setRequestStatus,
                        setCarIndex,
                        setLocationIndex,
                        itinerary_list,
                        dispatch,
                        router,
                        enqueueSnackbar,
                        t,
                        replaceProductContext
                    );
                } else {
                    setOpen(true);
                }
            } else {
                enqueueSnackbar(t("cars.error_no_contract"), {
                    variant: "error"
                });
            }
        }, 300);
    };
    const handleClose = () => {
	  	setOpen(false);
    };
    const handleValidate = () => {
        if (ref.current !== null) {
            ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        DeleteCars(cart_id, cart_index, dispatch, enqueueSnackbar, setRequestStatus, t);
        AddToCart(
            contract_info,
            car_contract,
            step_info,
            location_index,
            car_index,
            agency_options,
            travelers,
            setRequestStatus,
            setCarIndex,
            setLocationIndex,
            itinerary_list,
            dispatch,
            router,
            enqueueSnackbar,
            t,
            replaceProductContext
        );
    };

    if (replaceProductContext.enableReplace) {
        replaceProductContext.onIsFinalStep();
        replaceProductContext.onRegisterNextEvent(checkContract);
    }

    return (
        <CardContent className={classes.cardPadding}>
            <div className={`${classes.relativePosition} ${classes.marginBottom10}`}>
                <img src={selected_car.vehicle_info.pict_url} className={
                    clsx({
                        [classes.carCardImgInfo]: true,
                        [classes.centerPic]: true,
                        [classes.carsSmallPic]: pic_height < 230
                    })} onLoad={(event) => {
                    setPicHeight(event.target.height);
                }}/>
                {
                    selected_car.subprovider_id === selected_car.provider_id && provider.provider.logo !== null && (
                        <img src={ provider.provider.logo.thumbnail_little !== null ? provider.provider.logo.thumbnail_little : provider.provider.logo.url } className={classes.carCardProvider} />
                    )
                }
                {
                    selected_car.subprovider_id !== selected_car.provider_id && provider.provider.logo !== null && (
                        <Fragment>
                            {
                                (user.client_full.type !== 2 || JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie') && (
                                    <img src={ provider.provider.logo.thumbnail_little !== null ? provider.provider.logo.thumbnail_little : provider.provider.logo.url } className={
                                        clsx({
                                            [classes.carCardProvider]: true,
                                            [classes.centerPic]: true,
                                            [classes.providerSmallPic]: true
                                        })}
                                    onLoad={(event) => {
                                        setPicWidth(event.target.width);
                                    }} />
                                )
                            }
                            <Fragment>
                                {
                                    (subprovider !== undefined && subprovider !== null) && subprovider.provider.logo !== null && (
                                        <img src={ subprovider.provider.logo.thumbnail_little !== null ? subprovider.provider.logo.thumbnail_little : subprovider.provider.logo.url } className={
                                            clsx({
                                                [classes.carCardProvider]: true,
                                                [classes.sub30]: pic_width < 40,
                                                [classes.sub50]: pic_width > 40,
                                                [classes.providerSmallPic]: subprovider_width > 30
                                            })}
                                        onLoad={(event) => {
                                            setSubProviderWidth(event.target.width);
                                        }} />
                                    )
                                }
                                {
                                    (subprovider === undefined || subprovider === null) && (
                                        <img src={ selected_car.subprovider_logo } className={
                                            clsx({
                                                [classes.carCardProvider]: true,
                                                [classes.sub30]: true
                                            })} />
                                    )
                                }
                            </Fragment>
                        </Fragment>
                    )
                }
            </div>
            <div>
                <div className={`${classes.font20} ${classes.bold}`}>
                    {car_name[0]}
                </div>
                <div className={ classes.carCardCategory }>
                    {t("cars.or_similiar") + " | " + t("global.category") + t("cars.categories." + (selected_car.vehicle_info.vehicle_category).toLowerCase()) + " (" + selected_car.vehicle_info.code + ")"}
                </div>
                <div className={ classes.marginTop10 }>
                    <div className={ classes.carCardInfo } style={{ marginLeft: '-2px' }}>
                        <Tooltip title={t("global.luggages")} placement={ "bottom" }>
                            <WorkOutlineIcon style={{ marginRight: 2, verticalAlign: 'sub', fontSize: 20 }}/>
                        </Tooltip>
				    	<span className={ classes.font14 }>{selected_car.vehicle_info.baggage}</span>
                    </div>
                    <div className={ classes.carCardInfo }>
                        <Tooltip title={t("flight_groups.passengers")} placement={ "bottom" }>
                            <PermIdentityIcon style={{ marginRight: 2, verticalAlign: 'sub', fontSize: 20 }}/>
                        </Tooltip>
				    	<span className={ classes.font14 }>{selected_car.vehicle_info.passenger}</span>
                    </div>
                    <div className={ classes.carCardInfoFlex }>
                        <Tooltip title={t("tooltip.door_nbr")} placement={ "bottom" }>
                            <div>
                                <DoorIcon fontSize={"small"} style={{ marginRight: 2, verticalAlign: 'sub', fontSize: 20 }}/>
                            </div>
                        </Tooltip>
				    	<span className={ classes.font14 } style={{ paddingTop: '3px', paddingLeft: '4px' }}>{selected_car.vehicle_info.door_count}</span>
                    </div>
                    {
					    selected_car.vehicle_info.air_condition && (
				    		<div className={ classes.carCardInfo }>
				    			<Tooltip title={t("accommodation.equipment.air_conditioning")} placement={ "bottom" }>
				    				<AcUnitOutlinedIcon style={{ marginRight: 2, verticalAlign: 'sub', fontSize: 20 }}/>
				    			</Tooltip>
				    		</div>
					    )
                    }
                    <div className={ classes.carCardInfoFlex } style={{ marginRight: 0 }}>
                        <Tooltip title={t("cars.transmission.transmission")} placement={ "bottom" }>
                            <div>
                                <GearIcon fontSize={"small"} style={{ marginRight: 2, verticalAlign: 'sub', fontSize: 20 }}/>
                            </div>
                        </Tooltip>
				    	<span className={ classes.font14 } style={{ paddingTop: '3px', paddingLeft: '4px', textTransform: 'capitalize' }}>{t("cars.transmission." + (selected_car.vehicle_info.transmission).toLowerCase())}</span>
                    </div>
                </div>
                {
                    selected_car.vehicle_info.minimal_age && (
                        <Grid item xs={12} className={ classes.font14 } style={{ textTransform: 'capitalize' }}>
                            {t("cars.minimal_age")}
                            <span>
                                {selected_car.vehicle_info.minimal_age}
                            </span>
                        </Grid>
                    )
                }
                {
                    selected_car.vehicle_info.licence_time && (
                        <Grid item xs={12} className={ `${classes.font14} ${classes.marginBottom10}` } style={{ textTransform: 'capitalize' }}>
                            {t("cars.licence_time")}
                            <span>
                                {selected_car.vehicle_info.licence_time}
                            </span>
                        </Grid>
                    )
                }
                {
                    car_contract && (
                        car_contract.contracts.map((contract) => {
                            if (contract.contract_id === car_contract.selected) {
                                return (
                                    <div key={contract.contract_id}><span className="bold">{t("cars.forfait_selected")}</span> {contract.name}</div>
                                );
                            }
                        })
                    )
                }
                <Grid item xs={12} className={`${classes.marginTop10}`}>
                    <span>
                        <ScheduleIcon className={ `${classes.bottomAlign} ${classes.marginLeft_4}` }/>
                        <span className={`${classes.bold} ${classes.marginLeft5}`}>
                            {t("poi.duration") + " : "}
                        </span>
                        { parseFloat(days) > 1 ? " " + days + " " + t("global.days") : " " + days + " " + t("global.day") }
                    </span>
                </Grid>
                <Grid container className={ `${classes.marginTop10}` }>
                    <Grid item xs={12} className={ classes.bold }>
                        <span>
                            <RoomOutlinedIcon className={ `${classes.bottomAlign} ${classes.marginLeft_4}` }/>
                            <span className={`${classes.marginLeft5}`}>{ t("cars.agency_origin")}</span>
                        </span>
                    </Grid>
                    <Grid item xs={12} className={ `${classes.font12} ${classes.marginLeft24}` }>
                        {agency_go.name !== undefined ? agency_go.name : agency_go}
                    </Grid>
                    {
                        agency_go.address_line !== undefined && agency_go.address_line !== "" && (
                            <Grid item xs={12} className={ `${classes.font12} ${classes.marginLeft24}` }>
                                {agency_go.address_line}
                            </Grid>
                        )

                    }
                    {
                        agency_go.address_line === undefined && (
                            <Grid item xs={12} className={ `${classes.font12} ${classes.marginLeft24}` }>
                                {contract_info.selected_car.address_pickup}
                            </Grid>
                        )
                    }
                    <Grid item xs={12} className={ `${classes.font12} ${classes.marginLeft24}` }>
                        {b.format("DD MMMM YYYY") + " | " + step.origin_hour}
                    </Grid>
                </Grid>
                {
                    agency_return && (
                        <Grid container className={ `${classes.marginTop10} ${classes.marginBottom10}` }>
                            <Grid item xs={12} className={ classes.bold }>
                                <span>
                                    <RoomOutlinedIcon className={ `${classes.bottomAlign} ${classes.marginLeft_4}` }/>
                                    <span className={`${classes.marginLeft5}`}>{ t("cars.agency_return")}</span>
                                </span>
                            </Grid>
                            <Grid item xs={12} className={ `${classes.font12} ${classes.marginLeft24}` }>
                                {agency_return.name !== undefined ? agency_return.name : agency_return}
                            </Grid>
                            {
                                agency_return.address_line !== undefined && agency_return.address_line !== "" && (
                                    <Grid item xs={12} className={ `${classes.font12} ${classes.marginLeft24}` }>
                                        {agency_return.address_line}
                                    </Grid>
                                )

                            }
                            {
                                agency_return.address_line === undefined && (
                                    <Grid item xs={12} className={ `${classes.font12} ${classes.marginLeft24}` }>
                                        {contract_info.selected_car.address_return}
                                    </Grid>
                                )
                            }
                            <Grid item xs={12} className={ `${classes.font12} ${classes.marginLeft24}` }>
                                {a.format("DD MMMM YYYY") + " | " + step.return_hour}
                            </Grid>
                        </Grid>
                    )
                }
            </div>
            {
                find !== undefined && (
                    <div className={ "bold ft-mt10" }>{t("cars.optional_equipments_card")}</div>
                )
            }
            {
                agency_options && (
                    <div className={ "row" }>
                        {
                            agency_options.map((option, option_index) => {
                                console.log('option:', option);
                                if (option.quantity > 0) {
                                    let option_price = new Intl.NumberFormat(language, { style: 'currency', currency: option.currency_code }).format((option.charge_list.rental_period !== null ? option.charge_list.rental_period : ((option.charge_list.max_price !== null && option.charge_list.day * days > option.charge_list.max_price) || option.charge_list.day === null ? option.charge_list.max_price : option.charge_list.day * days)) * option.quantity);
                                    onsite_total += (option.charge_list.rental_period !== null ? option.charge_list.rental_period : ((option.charge_list.max_price !== null && option.charge_list.day * days > option.charge_list.max_price) || option.charge_list.day === null ? option.charge_list.max_price : option.charge_list.day * days)) * option.quantity;
                                    onsite_currency_code = option.currency_code;
                                    return (
                                        <Fragment key={option_index}>
                                            <div className={"col-md-8 no-padding ft-pl15"}>
                                                <span className={ `${classes.font12}` }>{option.quantity + " " + option.code_name + " : "}</span>
                                            </div>
                                            <div className={"col-md-4 text-right no-padding pr20"}>
                                                <span className={ `${classes.font12}` }>{ option_price }</span>
                                            </div>
                                        </Fragment>
                                    );
                                }
                            })
                        }
                    </div>
                )
            }
            {
                !check_fare && car_contract && (
                    <div className={"ft-mt10"}>
                        <div className={"bold"}>
                            {t("cars.onsite_fares") + " :"}
                        </div>
                        <div className="row ft-font-12">
                            {
                                car_contract.contracts.map((contract) => {
                                    if (contract.contract_id === car_contract.selected) {
                                        return (
                                            contract.onsite_fares.map((fare, fare_index) => {
                                                onsite_total += fare.price;
                                                let fare_currency_code = currency_list.find((currency) => {
                                                    return (currency.id === fare.currency);
                                                });
                                                onsite_currency_code = fare_currency_code.iso_code;
                                                if (fare.price > 0) {
                                                    let s = (fare.description).toLowerCase();
                                                    return (
                                                        <Fragment key={fare_index}>
                                                            <div className={"col-md-8 no-padding ft-pl15"}>
                                                                {(s[0].toUpperCase() + s.substring(1)) + " :"}
                                                            </div>
                                                            <div className={"col-md-4 text-right no-padding pr20"}>
                                                                {new Intl.NumberFormat(language, { style: 'currency', currency: fare_currency_code.iso_code }).format(fare.price)}
                                                            </div>
                                                        </Fragment>
                                                    );
                                                }
                                            })
                                        );
                                    }
                                })
                            }
                        </div>
                    </div>
                )
            }
            {
                selected_car.provider === "Avis" && (agency_return !== undefined && (agency_go.name !== agency_return.name || agency_go !== agency_return)) && (
                    <div className={"ft-mt10 ft-mb10"}>{t("cars.one_way_fare")}</div>
                )
            }
            {
                onsite_total > 0 && (
                    <div className={ "ft-mt10 bold row" + `${fullScreen ? " " + classes.marginBottom100 : "ft-mb10"}`}>
                        <div className={"col-md-8 no-padding ft-pl15"}>
                            {t("cars.onsite_total") + " : "}
                        </div>
                        <div className={"col-md-4 text-right no-padding pr20"}>
                            {new Intl.NumberFormat(language, { style: 'currency', currency: onsite_currency_code }).format(onsite_total) }
                        </div>
                    </div>
                )
            }
            {
                !fullScreen && (
                    <Fragment>
                        <Divider/>
                        <div className={ "ft-mt10 bold row ft-font-20" }>
                            <div className={"col-md-8 no-padding ft-pl15"}>
                                {t("cars.quotation_total") + " : "}
                            </div>
                            <div className={"col-md-4 text-right no-padding pr20"}>
                                {selected_car_price}
                            </div>
                        </div>
                    </Fragment>
                )
            }
            {
                selected_car.vehicle_info.vehicle_category_message && (
                    <div className={ "col-md-12 no-padding mt15" }>
                        {selected_car.vehicle_info.vehicle_category_message}
                    </div>
                )
            }
            {
                !fullScreen && !replaceProductContext.enableReplace && (
                    <div className={ `${classes.relativePosition} ${classes.textCenter}` }>
                        <Button className={`${classes.orangeButton} ${classes.marginTop25}`} fullWidth={ true } disabled={ request_status || car_contract === null } onClick={() => {
                            checkContract();
                        }}>{ t("accommodation.validate_cart") }</Button>
                        { request_status && <CircularProgress size={ 24 } className={ classes.buttonProgress }/> }
                    </div>
                )
            }
            {
                fullScreen && !replaceProductContext.enableReplace && (
                    <Grid container className={ classes.staticPriceContainer } direction={"row-reverse"} alignItems={ "center" } justify={ "space-around" }>
                        <Grid item>
						    <Button className={`${classes.orangeButton}`} fullWidth={ true } disabled={ request_status || car_contract === null } onClick={() => {
                                checkContract();
                            }}>{ t("accommodation.validate_cart") }</Button>
                            { request_status && <CircularProgress size={ 24 } className={ classes.buttonProgress }/> }
                        </Grid>
                        <Grid item>
                            <span className={classes.bold}>{selected_car_price}</span>
                        </Grid>
                    </Grid>
                )
            }
            <Dialog fullScreen={ fullScreen } PaperProps={{ style: { borderRadius: fullScreen ? 0 : 16 } }} open={ open } fullWidth maxWidth={ "md" } onClose={ handleClose } disableEscapeKeyDown>
		        <DialogTitle id="alert-dialog-title">{t("accommodation.validate_cart")}</DialogTitle>
		        <DialogContent>
		          	<DialogContentText id="alert-dialog-description">
		            	{t("cars.remove_cars")}
		          	</DialogContentText>
		        </DialogContent>
		        <DialogActions>
	          		<Button onClick={handleClose} color="primary">
		            	{t("global.cancel")}
		          	</Button>
		          	<Button onClick={handleValidate} disabled={ request_status } color="primary" autoFocus>
		            	{t("global.validate")}
		          	</Button>
		        </DialogActions>
		      </Dialog>
        </CardContent>
    );
});
export default React.memo(RenderCardContent);
