import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Autocomplete,
    Box,
    Button,
    IconButton,
    InputAdornment,
    MenuItem,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {
    DatePicker,
    LocalizationProvider
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
    AirlineSeatReclineExtraOutlined,
    Close,
    FlightTakeoffOutlined
} from "@mui/icons-material";
import axios, { CancelTokenSource } from "axios";
import { debounce, isString } from "lodash";
import ChoosePassenger from "../FlightMaterial/FlightGroups/ChoosePassenger";
import { CartConstructionReplaceProductContext } from "./utils/cartConstructionReplaceProductContext";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { GetProvider } from "../../Actions/Flight";
import { IataAirport } from "../Itinerary/objects/iataAirport";
import { IataAirline } from "./objects/iataAirline";
import { AppState } from "../../Reducers/Reducers";

type FlightProvider = {
    id: number,
    name: string,
    code: string,
    created_date: string
}

export type CartConstructionReplaceFlightSegmentInputsValues = {
    segments: {
        departureAirport: IataAirport | null,
        arrivalAirport: IataAirport | null,
        date: string | null,
        classType: number,
    }[],
    airlines: IataAirline[],
    providers: FlightProvider[]
}

type Props = {
    value: CartConstructionReplaceFlightSegmentInputsValues,
    onChange: React.Dispatch<React.SetStateAction<CartConstructionReplaceFlightSegmentInputsValues>>,
    disableProviderPicker?: boolean
}

export function CartConstructionReplaceFlightSegmentInputs(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const flight_groups = useSelector((state: AppState) => state.flight.flight_groups);
    const provider_list = useSelector((state: AppState) => state.trip.providers);
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const flight_providers = useSelector((state: AppState) => state.flight.provider_list);
    const context = useContext(CartConstructionReplaceProductContext);

    function onChange<K extends keyof CartConstructionReplaceFlightSegmentInputsValues>(
        key: K,
        value: CartConstructionReplaceFlightSegmentInputsValues[K]
    ): void {
        props.onChange((state) => {
            return {
                ...state,
                [key]: value
            };
        });
    }

    function onChangeSegment<K extends keyof CartConstructionReplaceFlightSegmentInputsValues['segments'][number]>(
        index: number,
        key: K,
        value: CartConstructionReplaceFlightSegmentInputsValues['segments'][number][K]
    ): void {
        props.onChange((state) => {
            return {
                ...state,
                segments: state.segments.map((item, itemIndex) => {
                    if (itemIndex === index) {
                        return {
                            ...item,
                            [key]: value
                        };
                    }
                    return item;
                })
            };
        });
    }

    const onAddDestination = () => {
        props.onChange((state) => {
            return {
                ...state,
                segments: state.segments.concat([
                    {
                        departureAirport: null,
                        arrivalAirport: null,
                        date: tripStartDate ?? window.moment.utc().toString(),
                        classType: 0
                    }
                ])
            };
        });
    };

    const onDeleteDestination = (index: number) => {
        props.onChange((state) => {
            return {
                ...state,
                segments: [
                    ...state.segments.slice(0, index),
                    ...state.segments.slice(index + 1)
                ]
            };
        });
    };

    useEffect(() => {
        let flight_providers: {
            id: number,
            name: string,
            code: string,
            created_date: string
        }[] = [];
        provider_list.map((provider) => {
            if (provider.enabled_content_types?.includes(6)) {
                if (["eva_amadeus", "eva_misterfly", "amadeus_rest", "1G", "tahitinui", "amadeus_soap", 'sabre_rest', 'travel_fusion'].includes(provider.provider.code)) {
                    flight_providers.push({
                        id: provider.provider.id,
                        name: provider.provider.name,
                        code: provider.provider.code,
                        created_date: provider.provider.created_date
                    });
                }
            }
        });

        dispatch(
            GetProvider(
                flight_providers,
                0,
                JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code
            )
        );

        dispatch({
            type: 'FLIGHT_SELECT_PROVIDER',
            payload: {
                index: 0,
                provider: flight_providers.map((provider) => provider.id)
            }
        });
    }, [provider_list]);

    useEffect(() => {
        if (
            props.value.segments.length === 0 ||
            props.value.segments.findIndex((item) => {
                return !item.arrivalAirport ||
                       !item.departureAirport ||
                       !item.date;
            }) >= 0 ||
            props.value.providers.length === 0
        ) {
            context.onHideNextButton();
        } else {
            context.onShowNextButton();
        }
    }, [
        context,
        props.value.segments
    ]);

    useEffect(() => {
        onChange('providers', flight_providers ?? []);
    }, [flight_providers]);

    return (
        <>
            <Typography sx={{ marginBottom: 1.5 }}>
                {t<string>('cart-material.cart-construction-destinations-dot')}
            </Typography>
            {
                props.value.segments.map((segment, index) => (
                    <Stack
                        key={index}
                        direction="row"
                        spacing={1}
                        sx={{ marginBottom: 1.5 }}
                    >
                        <AirportPicker
                            label={t<string>('cart-material.cart-construction-departure-airport')}
                            value={segment.departureAirport}
                            onChange={(value) => onChangeSegment(index, 'departureAirport', value)}
                        />
                        <AirportPicker
                            label={t<string>('cart-material.cart-construction-arrival-airport')}
                            value={segment.arrivalAirport}
                            onChange={(value) => onChangeSegment(index, 'arrivalAirport', value)}
                        />
                        <LocalizationProvider dateAdapter={ AdapterMoment } locale={language}>
                            <DatePicker
                                label={t<string>('cart-material.cart-construction-flight-date')}
                                value={window.moment.utc(segment.date ?? undefined)}
                                onChange={(value) => onChangeSegment(index, 'date', value?.toISOString() ?? null)}
                                sx={{
                                    width: '100%',
                                    marginBottom: 1.5
                                }}
                            />
                        </LocalizationProvider>
                        <TextField
                            value={segment.classType}
                            label={ t<string>("flight_groups.class_type") }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AirlineSeatReclineExtraOutlined />
                                    </InputAdornment>
                                )
                            }}
                            onChange={(event) => onChangeSegment(index, 'classType', parseInt(event.target.value))}
                            select
                            fullWidth
                        >
                            <MenuItem value={0}>
                                { t<string>("flight.class.eco") }
                            </MenuItem>
                            <MenuItem value={1}>
                                { t<string>("flight.class.premium_eco") }
                            </MenuItem>
                            <MenuItem value={2}>
                                { t<string>("flight.class.business") }
                            </MenuItem>
                            <MenuItem value={3}>
                                { t<string>("flight.class.first") }
                            </MenuItem>
                        </TextField>
                        {
                            context.mode === 'add' &&
                            <IconButton onClick={() => onDeleteDestination(index)}>
                                <Close />
                            </IconButton>
                        }
                    </Stack>
                ))
            }
            {
                context.mode === 'add' &&
                <>
                    <Button onClick={onAddDestination}>
                        {t<string>('shared.add')}
                    </Button>
                </>
            }
            <Typography sx={{ marginBottom: 1.5 }}>
                {t<string>('cart-material.cart-construction-info-dot')}
            </Typography>
            <Stack direction="row" spacing={1} sx={{ marginBottom: 1.5 }}>
                <AirlinePicker
                    label={t<string>("flight_groups.pick_airlines")}
                    value={props.value.airlines}
                    onChange={(value) => onChange('airlines', value)}
                />
            </Stack>
            {
                !props.disableProviderPicker &&
                <>
                    <Typography sx={{ marginBottom: 1.5 }}>
                        {t<string>('cart-material.cart-construction-package-provider')}
                    </Typography>
                    <ProviderPicker
                        value={props.value.providers}
                        onChange={(value) => onChange('providers', value)}
                    />
                </>
            }
            <Box sx={{ marginTop: 2.5 }}>
                <ChoosePassenger group_index={0} group_travelers={flight_groups?.[0]?.travelers ?? []} />
            </Box>
        </>
    );
}

type AirportPickerProps = {
    value: IataAirport | null,
    label: string,
    onChange: (value: IataAirport | null) => void
}

function AirportPicker(props: AirportPickerProps): JSX.Element {
    const language = useSelector((state: AppState) => state.header.language);
    const [options, setOptions] = useState<IataAirport[]>([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const cancelToken = useRef<CancelTokenSource | null>(null);
    const fireNetworkRequest = useCallback(
        debounce(
            async (search: string) => {
                try {
                    setLoading(true);
                    cancelToken.current?.cancel('Request cancelled.');
                    cancelToken.current = axios.CancelToken.source();
                    const response = await makeAirportsSearchRequest({
                        search,
                        language: language ?? '',
                        cancelToken: cancelToken.current
                    });
                    setOptions(response);
                } catch (error: any) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            },
            500
        ),
        [language]
    );

    useEffect(() => {
        if (search.trim().length > 0) {
            fireNetworkRequest(search);
        } else {
            setOptions([]);
            setLoading(false);
        }
    }, [search]);

    return (
        <Autocomplete
            value={props.value}
            loading={loading}
            options={options}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            filterOptions={(options) => options}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                />
            )}
            getOptionLabel={(option) => {
                if (isString(option)) {
                    return option;
                }

                const name = option.name ?
                    option.name :
                    option.international_name;
                const city = option.iata_city.name ?
                    option.iata_city.name :
                    option.iata_city.international_name;

                return city + ", " + name + " (" + option.airport_code + ")";
            }}
            renderOption={(props, option) => {
                const name = option.name ?
                    option.name :
                    option.international_name;
                const city = option.iata_city.name ?
                    option.iata_city.name :
                    option.iata_city.international_name;

                return (
                    <li {...props}>
                        <FlightTakeoffOutlined sx={{ marginRight: 1 }} />
                        {city + ", " + name + " (" + option.airport_code + ")"}
                    </li>
                );
            }}
            componentsProps={{
                popper: {
                    sx: {
                        minWidth: 230
                    }
                }
            }}
            sx={{ width: '100%' }}
            onChange={(_, value) => value && !isString(value) && props.onChange(value)}
            onInputChange={(_, value) => isString(value) && setSearch(value)}
            fullWidth
            freeSolo
        />
    );
}

type AirportsSearchRequestOptions = {
    search: string,
    language: string,
    cancelToken: CancelTokenSource
}

async function makeAirportsSearchRequest(options: AirportsSearchRequestOptions): Promise<IataAirport[]> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.get<{results: IataAirport[]}>(
            `${API_HREF}iata-airports/`,
            {
                headers,
                cancelToken: options.cancelToken.token,
                params: {
                    lang: options.language,
                    search: options.search,
                    limit: 15
                }
            }
        );
        return response.data.results;
    }

    throw new Error('Please login.');
}

type AirlinePickerProps = {
    value: IataAirline[],
    label: string,
    onChange: (value: IataAirline[]) => void
}

function AirlinePicker(props: AirlinePickerProps): JSX.Element {
    const [options, setOptions] = useState<IataAirline[]>([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const cancelToken = useRef<CancelTokenSource | null>(null);
    const fireNetworkRequest = useCallback(
        debounce(
            async (search: string) => {
                try {
                    setLoading(true);
                    cancelToken.current?.cancel('Request cancelled.');
                    cancelToken.current = axios.CancelToken.source();
                    const response = await makeAirlinesSearchRequest({
                        search,
                        cancelToken: cancelToken.current
                    });
                    setOptions(response);
                } catch (error: any) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            },
            500
        ),
        []
    );

    useEffect(() => {
        if (search.trim().length > 0) {
            fireNetworkRequest(search);
        } else {
            setOptions([]);
            setLoading(false);
        }
    }, [search]);

    return (
        <Autocomplete
            value={props.value}
            loading={loading}
            options={options}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            filterOptions={(options) => options}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                />
            )}
            getOptionLabel={(option) => isString(option) ? option : option.commercial_name}
            componentsProps={{
                popper: {
                    sx: {
                        minWidth: 230
                    }
                }
            }}
            sx={{ width: '100%' }}
            onChange={(_, value) => props.onChange(value.filter((item) => !isString(item)) as IataAirline[])}
            onInputChange={(_, value) => isString(value) && setSearch(value)}
            multiple
            fullWidth
            freeSolo
        />
    );
}

type AirlinesSearchRequestOptions = {
    search: string,
    cancelToken: CancelTokenSource
}

async function makeAirlinesSearchRequest(options: AirlinesSearchRequestOptions): Promise<IataAirline[]> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.get<{results: IataAirline[]}>(
            `${API_HREF}iata-airlines/`,
            {
                headers,
                cancelToken: options.cancelToken.token,
                params: {
                    search: options.search,
                    limit: 15
                }
            }
        );
        return response.data.results;
    }

    throw new Error('Please login.');
}


type ProviderPickerProps = {
    value: FlightProvider[],
    onChange: (value: FlightProvider[]) => void
}

function ProviderPicker(props: ProviderPickerProps): JSX.Element {
    const { t } = useTranslation();
    const flight_providers = useSelector((state: AppState) => state.flight.provider_list);

    return (
        <Autocomplete
            value={props.value}
            options={flight_providers ?? []}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            filterOptions={(options) => options}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t<string>("flight_groups.select_provider")}
                />
            )}
            getOptionLabel={(option) => option.name}
            componentsProps={{
                popper: {
                    sx: {
                        minWidth: 230
                    }
                }
            }}
            sx={{ width: '100%' }}
            onChange={(_, value) => props.onChange(value)}
            multiple
            fullWidth
        />
    );
}
