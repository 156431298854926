import axios from "axios";
import memoizee from "memoizee";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { Destination } from "../objects/destination";

async function get(destinationId: number): Promise<Destination | null> {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        try {
            const response = await axios.get(
                `${API_HREF}client/${window.id_owner}/destinations/${destinationId}/`,
                { headers }
            );
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    return null;
}

export const getDestination = memoizee(
    get,
    { promise: true, primitive: true }
);
