/*eslint-disable curly */

import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import moment from "moment";
import MaterialUiPhoneNumber from "material-ui-phone-number";
import { countriesPhone } from "../objects/data";
import { Autocomplete, FormHelperText } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from "react-redux";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const GenericField = (props) => {
    const user = useSelector(store => store.user.user);

    //AUTOCOMPLETE
    const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
    const [autocompleteOptions, setAutocompleteOptions] = React.useState([]);
    const autocompleteLoading = openAutocomplete && autocompleteOptions.length === 0;
    useEffect(() => {
        if (!openAutocomplete) {
            // setAutocompleteOptions([]);
        } else {
            onAutocompleteChange(null);
        }
    }, [openAutocomplete]);

    useEffect(() => {
        if (props.type === "autocomplete" && props.autocompleteGetter !== undefined) {
            props.autocompleteGetter(user.client, '', (resp) => {
                if (resp.data?.results) {
                    const results = props.field === "data.destination" ? resp.data.results?.filter(c => c.data?.international_name) : resp.data.results;
                    setAutocompleteOptions(results);
                }
            });
        }
    }, []);

    const onAutocompleteChange = (event) => {
        setAutocompleteOptions([]);
        if (props.autocompleteGetter !== undefined) {
            props.autocompleteGetter(user.client, event?.target?.value, (resp) => {
                if (resp.data?.results) {
                    const results = props.field === "data.destination" ? resp.data.results?.filter(c => c.data?.international_name) : resp.data.results;
                    setAutocompleteOptions(results);
                }
            });
        }
    };

    const onChangeAutocomplete = (event, value) => {
        onChange({ target: { value: value?.id } });
    };

    const onChange = (event) => {
        if (props.field && props.onChange !== undefined) {
            props.onChange(props.field, event?.target?.value ? event.target.value : event);
            if (props.required && !event.target.value) {
                props.onChange(props.field + '_error', `ce champ ne peut être vide`);
            }
        }
    };

    const handleDate = (date) => {
        if (props.field && props.onChange !== undefined) {
            props.onChange(props.field, date);
        }
    };

    const checkExist = (value) => {
        return value !== null && value !== undefined;
    };

    let value = null;
    const fields = props.field?.split('.');
    if (props.object) {
        switch (fields.length) {
            case 1:
                if (checkExist(props.object)) value = props.object[fields[0]];
                break;
            case 2:
                if (checkExist(props.object) && checkExist(props.object[fields[0]])) value = props.object[fields[0]][fields[1]];
                break;
            case 3:
                if (checkExist(props.object) && checkExist(props.object[fields[0]]) && checkExist(props.object[fields[0]][fields[1]])) value = props.object[fields[0]][fields[1]][fields[2]];
                break;
            case 4:
                if (checkExist(props.object) && checkExist(props.object[fields[0]]) && checkExist(props.object[fields[0]][fields[1]]) && checkExist(props.object[fields[0]][fields[1]][fields[2]])) {
                    value = props.object[fields[0]][fields[1]][fields[2]][fields[3]];
                }
                break;
            default:
                break;
        }
    }

    const getAutocompleteValue = () => {
        if (!props.multiple && typeof value === "number") {
            return autocompleteOptions.find(option => option.id === value) || null;
        } else if (props.multiple && value?.length && value[0] && typeof value[0] === "number") {
            return value.map(v => autocompleteOptions.find(option => option.id === v)).filter(Boolean);
        }
        return value;
    };

    const getPhoneCode = () => {
        const defaultLanguage = navigator.language;
        const locale = new Intl.Locale(defaultLanguage);
        const countryCode = locale.region ? locale.region : locale.language?.slice(-2)?.toLocaleUpperCase();
        const found = countriesPhone.find(e => e.code === countryCode);
        if (found) {
            return found.dial_code;
        }
        return "+1";
    };

    /**DATEPICKER*/
    const [openPicker, setOpenPicker] = useState(null);
    const handleOpenPicker = (id) => {
        setOpenPicker(id);
    };
    const handleClosePicker = () => {
        setOpenPicker(null);
    };
    const handleClickAway = (type) => {
        if (type === openPicker) {
            setOpenPicker(null);
        }
    };

    if (!props.object) return <></>;

    if (props.type === "autocomplete") {
        return (
            <>
                <FormControl fullWidth>
                    <Autocomplete
                        fullWidth
                        open={openAutocomplete}
                        onOpen={() => {
                            setOpenAutocomplete(true);
                        }}
                        onClose={() => {
                            setOpenAutocomplete(false);
                        }}
                        onChange={onChangeAutocomplete}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={props.getOptionLabel !== undefined ? props.getOptionLabel : (option) => option.international_name ? option.international_name : option.name}
                        options={autocompleteOptions}
                        loading={autocompleteLoading}
                        multiple={Boolean(props.multiple)}
                        value={getAutocompleteValue()}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={props.label}
                                disabled={props.readonly}
                                onKeyUp={onAutocompleteChange}
                                error={props.object[props.field + '_error']}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {autocompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        )}
                    />
                </FormControl>
                <FormHelperText style={{ color: "red" }}>{props.object[props.field + '_error']}</FormHelperText>
            </>
        );
    }
    if (props.type === "datepicker") {
        return (
            <>
                <FormControl fullWidth>
                    <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale={'fr'}>
                        <ClickAwayListener onClickAway={() => handleClickAway(props.field)}>
                            <Grid>
                                <DatePicker
                                    label={props.label}
                                    value={checkExist(value) ? moment(new Date(value), "YYYY-MM-DD") : null}
                                    onChange={(newValue) => handleDate(newValue)}
                                    disabled={props.readonly}
                                    minDate={new Date(props.min_date ? props.min_date : '1910-01-01')}
                                    maxDate={new Date(props.max_date ? props.max_date : undefined)}
                                    views={["year", "month", "date"]}
                                    open={Boolean(openPicker === props.field)}
                                    onOpen={() => handleOpenPicker(props.field)}
                                    onClose={handleClosePicker}
                                    renderInput={(props) => (
                                        <TextField
                                            disabled={props.readonly}
                                            {...props}
                                            variant={"outlined"}
                                            fullWidth={true}
                                            helperText={props.object ? props.object[props.field + '_error'] : null}
                                            error={props.object ? props.object[props.field + '_error'] : null}
                                        />
                                    )}
                                />
                            </Grid>
                        </ClickAwayListener>
                    </LocalizationProvider>
                </FormControl>
                <FormHelperText style={{ color: "red" }}>{props.object ? props.object[props.field + '_error'] : null}</FormHelperText>
            </>
        );
    }
    if (props.type === "select") {
        return (
            <>
                <FormControl fullWidth error={props.object[props.field + '_error']}>
                    <InputLabel>{props.label}</InputLabel>
                    <Select
                        error={props.object[props.field + '_error']}
                        label={props.label}
                        onChange={onChange}
                        value={checkExist(value) ? value : "MR"}
                        disabled={props.readonly}
                    >
                        {props.options.map(option => {
                            return (
                                <MenuItem key={`option-${option.value}`} value={option.value}>{option.label}</MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormHelperText style={{ color: "red" }}>{props.object[props.field + '_error']}</FormHelperText>
            </>
        );
    }
    if (props.type === "phone") {
        return (
            <MaterialUiPhoneNumber
                fullWidth
                type={'text'} label={props.label}
                onChange={onChange}
                value={value ? value : getPhoneCode()}
                variant="outlined"
                disabled={props.readonly}
                disableAreaCodes
                helperText={`ex: +33 6 12 34 56 78`}
                error={props.object[props.field + '_error']}
            />
        );
    }

    return (
        <FormControl fullWidth error={props.object ? props.object[props.field + '_error'] : null}>
            <TextField
                type={props.type ? props.type : 'text'}
                label={props.label + (props.required ? '*' : '')}
                value={value}
                onChange={onChange}
                error={props.object[props.field + '_error']}
                disabled={props.readonly}
                rows={props.multiple ? 4 : 1}
                multiline={Boolean(props.multiple)}
            />
            <FormHelperText>{props.object ? props.object[props.field + '_error'] : null}</FormHelperText>
        </FormControl>
    );
};

export default GenericField;
