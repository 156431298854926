import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, withStyles } from "@material-ui/core";

import Paper from "@material-ui/core/Paper";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import CircularProgress from '@material-ui/core/CircularProgress';
import FlightIcon from '@material-ui/icons/Flight';
import LuggageIcon from "../FlightSearch/LuggageIcon";
import BeenhereIcon from '@material-ui/icons/Beenhere';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtraOutlined';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles((theme) => ({
    formShadow: {
        borderRadius: 15,
        boxShadow: '0 1px 6px rgba(32,33,36,0.28)',
        margin: "16px 4px"
    },
    genericText:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 9,
        letterSpacing: 1.25,
        textTransform: "uppercase"
    },
    stepper: {
        padding: "4px 0px",
        "&.mui-jss-MuiPaper-root": {
            backgroundColor: "transparent"
        }
    },
    stepLabel: {
        cursor: 'pointer',
        "&& .mui-jss-MuiStepLabel-label.mui-jss-MuiStepLabel-alternativeLabel": {
            marginTop: 5
        }
    },
    main_color: {
        color: `${main_color}`,
    },
    grey: {
        color: "#0000005C"
    },
    green: {
        color: "#4CAF50"
    }
}))

const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderTop: `2px dotted ${main_color}`
      }
    },
    completed: {
      '& $line': {
        borderTop: '2px solid #4CAF50'
      }
    },
    line: {
      borderTop: "2px dotted #0000005C"
    },
  })(StepConnector);

const FlightStepper = (({ flight, flightStep, setFlightStep, luggageOptions, backToList, seats_by_segment }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return(
        <Paper elevation={0} variant="outlined" className={classes.formShadow}>
            <Stepper alternativeLabel activeStep={flightStep} connector={<QontoConnector />} className={classes.stepper}>
                <Step>
                    <StepLabel className={`${classes.stepLabel} ${classes.green}`} icon={ backToList ? <CircularProgress size={24} className={classes.green} /> : <FlightIcon /> } onClick={() => setFlightStep(1)}>
                        <div className={`${classes.genericText} ${classes.green}`}>
                            { t("flight.choice") }
                        </div>
                    </StepLabel>
                </Step>
                {
                    luggageOptions.length !== 0 && (
                        <Step>
                            <StepLabel className={`${classes.stepLabel} ${flightStep > 2 ? classes.green : ( flightStep === 2 ? classes.main_color : classes.grey)}`} icon={<LuggageIcon />} onClick={() => setFlightStep(2)}>
                                <div className={`${classes.genericText} ${flightStep > 2 ? classes.green : ( flightStep === 2 ? classes.main_color : classes.grey)}`}>
                                    { t("global.luggages") }
                                </div>
                            </StepLabel>
                        </Step>
                    )
                }
                {
                    seats_by_segment.length !==  0 &&
                    <Step>
                        <StepLabel className={`${classes.stepLabel} ${flightStep > 3 ? classes.green : ( flightStep === 3 ? classes.main_color : classes.grey)}`} icon={<AirlineSeatReclineExtraIcon />} onClick={() => setFlightStep(3)}>
                            <div className={`${classes.genericText} ${flightStep > 3 ? classes.green : ( flightStep === 3 ? classes.main_color : classes.grey)}`}>
                                { t("flight_search.seat") }
                            </div>
                        </StepLabel>
                    </Step>
                }
                {
                     flight.options !== null && flight.options.fares[0].refund_detail !== null && flight.options.fares[0].cancel_info !== null && (
                        <Step>
                            <StepLabel className={`${classes.stepLabel} ${flightStep > 4 ? classes.green : ( flightStep === 4 ? classes.main_color : classes.grey)}`} icon={<BeenhereIcon />} onClick={() => setFlightStep(4)}>
                                <div className={`${classes.genericText} ${flightStep > 4 ? classes.green : ( flightStep === 4 ? classes.main_color : classes.grey)}`}>
                                    { t("flight.conditions") }
                                </div>
                            </StepLabel>
                        </Step>
                    )
                }
                <Step>
                    <StepLabel className={`${classes.stepLabel} ${flightStep > 5 ? classes.green : ( flightStep === 5 ? classes.main_color : classes.grey)}`} icon={<ShoppingCartIcon />} onClick={() => setFlightStep(5)}>
                        <div className={`${classes.genericText} ${flightStep > 5 ? classes.green : ( flightStep === 5 ? classes.main_color : classes.grey)}`}>
                            { t("flight.confirmation") }
                        </div>
                    </StepLabel>
                </Step>
            </Stepper>
        </Paper>
    )
})

export default React.memo(FlightStepper)