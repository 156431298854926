export default (pax_pricing) => {
    let cancel_penalty = null;
    if (pax_pricing.cancel_penalty !== undefined && pax_pricing.cancel_penalty !== null) {
        if (pax_pricing.cancel_penalty.percentage !== undefined) {
            cancel_penalty = (parseFloat(pax_pricing.prices[0].selling_price) / 100) * pax_pricing.cancel_penalty.percentage;
        } else if (pax_pricing.cancel_penalty.amount !== undefined) {
            cancel_penalty = pax_pricing.cancel_penalty.amount;
        }
    }
    return cancel_penalty;
}