//---- Dependencies ----//
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSnackbar } from "notistack";
import axios from "axios";
import clsx from "clsx";
import SetCookie from "../Common/Functions/SetCookie";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import i18n from "../../i18n.jsx";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import CloseIcon from '@material-ui/icons/Close';

import { ConnectUser, SetUserPermissions } from "../../Actions/User";

const useStyles = makeStyles(theme => ({
    logo: {
        width: 350,
        height: 75,
        objectFit: "contain",
        maxWidth: 350
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    iconColor: {
        color: "#0000008A"
    },
    dialogSize: {
        "& .mui-jss-MuiDialog-paper": {
            width: 400
        }
    },
    buttonSize11: {
        "&& .mui-jss-MuiButton-label": {
            fontSize: 11
        }
    }
}));

const MaterialLogin = ({loginDialog, setLoginDialog, logo, router, token, id}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const activated_locales = useSelector(state => state.user.activated_locales);

    const [email, setEmail] = useState("");
    const [resetEmail, setResetEmail] = useState("");
    const [resetEmailError, setResetEmailError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [step, setStep] = useState(0);
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);

    const quotation_code = JSON.parse(localStorage.getItem("config")) !== null ? JSON.parse(localStorage.getItem("config")).quotation_code : "";

    useEffect(() => {
        console.log('window:', window);
        if (loginDialog && window.location.pathname.includes('reset-password') && token !== null && id !== null) {
            handleStep(2);
        }
    }, [loginDialog])

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleClose = () => {
        setStep(0);
        setNewPassword("");
        setResetEmail("");
        setConfirmNewPassword("");
        setShowPassword(false);
        setLoginDialog(!loginDialog);
    };
    const checkEmail = () => {
        let re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(email)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    }
    const checkResetEmail = () => {
        let re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(resetEmail)) {
            setResetEmailError(true);
        } else {
            setResetEmailError(false);
        }
    }
    const connect = (e) => {
        let { pass_check, headers } = CheckBeforeRequest();
        e.preventDefault();
        if (password === "") {
            setPasswordError(true);
        }
        if (!emailError && password !== "" && pass_check) {
            let id_user;
            let token;
            let data_user;
            let client_user;
            let request = {
                email: email,
                password: password
            };
            axios({
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Accept-Language": i18n.language
                },
                url: `${API_HREF}client/${window.id_buyer}/auth/`,
                data: JSON.stringify(request),
            }).then(function (response) {
                let data = Object.assign({}, response.data);
                if (quotation_code === "amerigo" && !data.user.is_superuser) {
                    enqueueSnackbar(t("blocked-client.amerigo"), { variant: "error" });
                } else if (quotation_code === "pbe" && !data.user.is_superuser) {
                    enqueueSnackbar(t("blocked-client.pbe"), { variant: "error" });
                } else {
                    id_user = data.user.id;
                    token = data.token;
                    client_user = data.user.client;
                    data_user = data;
                    SetCookie("token", data.token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                    SetCookie("id_user", data.user.id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                    SetCookie("client_user", data.user.client, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                    if (data.user.lang !== null && activated_locales.find((locale) => locale.language_code === data.user.lang) !== undefined) {
                        i18n.changeLanguage(data.user.lang);
                        moment.locale(data.user.lang);
                        localStorage.setItem("i18nextLng", data.user.lang);
                    } else if (activated_locales.length !== 0) {
                        i18n.changeLanguage(activated_locales[0].language_code);
                        moment.locale(activated_locales[0].language_code);
                        localStorage.setItem("i18nextLng", activated_locales[0].language_code);
                    }
                    axios({
                        method: "GET",
                        headers: {
                            "Authorization": "Token " + data.token,
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            "Accept-Language": i18n.language
                        },
                        url: !data.user.is_superuser ? `${API_HREF}client/${client_user}/user/${id_user}/permissions/?all=true` : `${API_HREF}permissions/?all=true`
                    }).then(function (response) {
                        enqueueSnackbar(t("header.youre_connected"), { variant: "success", anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        } });
                        if (router.location.pathname === "/home") {
                            if (data_user.user.client_full.type !== 2) {
                                if (window.location.host !== "localhost:3000") {
                                    router.push(`/home/${window.location.host.split('.')[0]}`);
                                } else {
                                    router.push(`/home/`);
                                }
                            } else {
                                router.push(`/home/${data_user.user.client}`);
                            }
                        }
                        dispatch(ConnectUser(false, true, data_user.user, data_user.token));
                        dispatch(SetUserPermissions(response.data.slice()));
                        handleClose();
                    });
                }
            }).catch(function (error) {
                if (error.response !== undefined && error.response.data.non_field_errors !== undefined) {
                    let error_txt = error.response.data.non_field_errors[0];
                    enqueueSnackbar(error_txt, { variant: "error" });
                }
            });
        }
    }
    const handleStep = (new_step) => {
        setStep(new_step);
    }
    const askNewEmail = (e) => {
        let { pass_check, headers } = CheckBeforeRequest();
        e.preventDefault();
        let request = {
            email: resetEmail,
        };
        if (pass_check) {
            axios({
                method: "POST",
                headers: headers,
                url: `${API_HREF}client/${window.id_buyer}/user/reset_password/`,
                data: JSON.stringify(request),
            }).then(function (response) {
                enqueueSnackbar(t("home.email_send"), { variant: "success" });
                handleClose();
            }).catch(function (errors) {
                errors.response.data.map(error => {
                    enqueueSnackbar(error, { variant: "error" });
                })
                handleClose();
            });
        }
    }
    const checkPassword = () => {
        if (newPassword === confirmNewPassword) {
            setConfirmNewPasswordError(false);
        } else {
            setConfirmNewPasswordError(true);
        }
    }
    const askNewPassword = (e) => {
        let { pass_check, headers } = CheckBeforeRequest();
        e.preventDefault();
        if (confirmNewPassword.length >= 8 && newPassword.length >= 8 && confirmNewPassword !== "" && !confirmNewPasswordError && pass_check) {
            let request = {
                user_id: id,
                password: newPassword,
                confirm_password: confirmNewPassword,
                token: token
            };
            axios({
                method: "POST",
                headers: headers,
                url: `${API_HREF}client/${window.id_buyer}/user/${id}/edit_password/`,
                data: JSON.stringify(request),
            }).then(function (response) {
                enqueueSnackbar(t("home.pwd_changed"), { variant: "success" });
                setStep(0);
                handleClose();
                router.push(`/home`);
            }).catch(function (error) {
                enqueueSnackbar(error, { variant: "error" });
                handleClose();
            });
        } else if (confirmNewPassword.length < 8 || newPassword.length < 8) {
            enqueueSnackbar(t("home.pwd_length"), { variant: "error" });
        }
    }
    return (
        <Dialog open={loginDialog} onClose={handleClose} fullScreen={fullScreen} className={classes.dialogSize}>
            <DialogTitle>
                <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                    <Grid item style={{position: "absolute", top: 0, right: 6}}>
                        <IconButton edge={ "start" } onClick={ handleClose }><CloseIcon/></IconButton>
                    </Grid>
                    <Grid item>
                        <img className={classes.logo} src={ logo !== null ? (logo.thumbnail_little !== null ? logo.thumbnail_little : logo.url) : "/Img/Home/logoGris.png"} />
                    </Grid>
                    <Grid>
                        <Typography variant="h5" className={classes.genericText}>
                            {
                                step === 0 ? t("home.nav.login") : (step === 1 ? t("home.reset_pwd") : t("home.reinitialisation"))
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{paddingBottom: 20}}>
                {
                    step === 0 && (
                        <form onSubmit={connect}>
                            <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={2}>
                                <Grid item style={{width: "100%"}}>
                                    <TextField
                                        fullWidth
                                        variant={"outlined"}
                                        label={t("home.email")}
                                        type={'text'}
                                        value={email}
                                        error={emailError}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onBlur={checkEmail}
                                        inputProps={{ autoCapitalize: 'none', }}
                                    />
                                </Grid>
                                <Grid item style={{width: "100%"}}>
                                    <TextField
                                        fullWidth
                                        variant={"outlined"}
                                        label={t("home.pwd")}
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        error={passwordError}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item style={{width: "100%"}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showPassword}
                                                onChange={handleClickShowPassword}
                                                color="primary"
                                            />
                                        }
                                        label={t("home.nav.display_password")}
                                    />
                                </Grid>
                                <Grid item style={{width: "100%"}}>
                                    <Grid container direction={"row-reverse"} justify={"space-between"} alignItems={"center"}>
                                        <Grid item xs={6} style={{textAlign: "right"}}>
                                            <Button variant={"contained"} type={"submit"} onClick={connect} color="primary" style={{textTransform: "none"}}>
                                                {t("home.nav.login")}
                                            </Button>
                                        </Grid>
                                        {
                                            quotation_code !== "marcovasco" && (
                                                <Grid item xs={6}>
                                                    <Button onClick={() => handleStep(1)} color="primary" style={{textTransform: "none"}} className={clsx({[classes.buttonSize11]: fullScreen})}>
                                                        {t("home.forgot_pwd")}
                                                    </Button>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }
                {
                    step === 1 && (
                        <form onSubmit={askNewEmail}>
                            <Grid container spacing={3}>
                                <Grid item style={{width: "100%", textAlign: "center"}} className={classes.genericText}>
                                    {t("home.ask_pwd_text")}
                                </Grid>
                                <Grid item style={{width: "100%"}}>
                                    <TextField
                                        fullWidth
                                        variant={"outlined"}
                                        label={t("home.email")}
                                        type={'text'}
                                        value={resetEmail}
                                        error={resetEmailError}
                                        onChange={(e) => setResetEmail(e.target.value)}
                                        onBlur={checkResetEmail}
                                    />
                                </Grid>
                                <Grid item style={{width: "100%"}}>
                                    <Button fullWidth type={"submit"} variant={"contained"} color={"primary"}>{t("home.ask_pwd_link")}</Button>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }
                {
                    step === 2 && (
                        <form onSubmit={askNewPassword}>
                            <Grid container spacing={3}>
                                <Grid item style={{width: "100%"}}>
                                    <TextField
                                        fullWidth
                                        variant={"outlined"}
                                        label={t("home.new_pwd")}
                                        type={showPassword ? 'text' : 'password'}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item style={{width: "100%"}}>
                                    <TextField
                                        fullWidth
                                        variant={"outlined"}
                                        label={t("home.confirm_new_pwd")}
                                        type={showPassword ? 'text' : 'password'}
                                        value={confirmNewPassword}
                                        error={confirmNewPasswordError}
                                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                                        onBlur={checkPassword}
                                    />
                                </Grid>
                                <Grid item style={{width: "100%"}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showPassword}
                                                onChange={handleClickShowPassword}
                                                color="primary"
                                            />
                                        }
                                        label={t("home.nav.display_password")}
                                    />
                                </Grid>
                                <Grid item style={{width: "100%"}}>
                                    <Button fullWidth type={"submit"} variant={"contained"} color={"primary"}>{t("home.save_and_go")}</Button>
                                </Grid>
                                <Grid item style={{width: "100%", textAlign: "center"}} className={classes.genericText}>
                                    {t("home.info_click_and_accept")}
                                </Grid>
                            </Grid>
                        </form>
                    )
                }
            </DialogContent>
        </Dialog>
    )
}
export default MaterialLogin;