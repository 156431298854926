//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Box, Fab, Paper, Stack, styled } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';

import RenderDestination from './RenderDestination';
import HotelCard from '../PackagesMaterial/HotelCard.tsx';
import RenderTransport from '../CircuitsMaterial/RenderTransport';
import ActivitiesCard from '../PackagesMaterial/ActivitiesCard.tsx';
import HotelCardManual from '../PackagesMaterial/HotelCardManual.tsx';
import ActivitiesCardManual from '../PackagesMaterial/ActivitiesCardManual.tsx';
import TransfersCard from '../PackagesMaterial/TransfersCard.tsx';
import TransfersCardManual from '../PackagesMaterial/TransfersCardManual.tsx';
import moment from 'moment';
import CarsCard from '../PackagesMaterial/CarsCard.tsx';
import CircuitStepper from '../CircuitsMaterial/CircuitStepper';
import FlightsCardStep from '../PackagesMaterial/FlightsCardStep.tsx';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    hrText: {
        lineHeight: "20px",
        position: "relative",
        outline: "0",
        border: "0",
        textAlign: "center",
        height: 20,
        fontSize: 34,
        '&::before': {
            content: "''",
            background: "#0000008A",
            position: "absolute",
            left: "0",
            top: "50%",
            width: "100%",
            height: "1px"
        },
        '&::after': {
            content: "attr(data-content)",
            position: "relative",
            display: "inline-block",
            color: "#0000008A",
            padding: "0 .5em",
            // lineHeight: "1.5em",
            backgroundColor: "white"
        }
    },
    border: {
        border: '1px solid #ebebeb',
        borderRadius: 13,
        padding: 10,
        marginBottom: 10,
        backgroundColor: 'white',
    }, 
    fabColor: {
        boxShadow: 'none !important',
        zIndex: '1 !important',
        backgroundColor: `${main_color} !important`,
        color: 'white !important',
        "&:hover": {
            backgroundColor: `${main_color}CA !important`
        }
    },
    itinerarySticky: {
        position: 'sticky',
        top: 10,
        zIndex: 999,
        width: '100%',
        marginBottom: 10,
        marginLeft: 7,
    }
}));

const DetailDay = React.forwardRef((prop, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();

    const itinerary = prop.itinerary.slice();
    const hotel_cart = prop.hotel_cart.slice();
    const cars_cart = prop.cars_cart.slice();
    const poi_cart = prop.poi_cart.slice();
    const manual_cart = prop.manual_cart.slice();
    const transfers_cart = prop.transfers_cart.slice();
    const guide_list = prop.guide_list.slice();
    const itiRef = prop.itiRef;
    const variantIsChecked = prop.variantIsChecked;
    const onToggleVariant = prop.onToggleVariant;
    const products = prop.products;
    const travelFlight = prop.travelFlight;
    const [printIti, setPrintIti] = useState([]);
    const [transportArr, setTransportArr] = useState([]);
    const [step, setStep] = useState(0);
    const printItiRef = useRef(new Array());

    useEffect(() => {
        setAllStepInfo();
    }, []);
    // useEffect(() => {
    //     if (itinerary !== null) {
    //         console.log('tata');
    //         setAllStepInfo();
    //     }
    // }, [itinerary]);

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    
    const setAllStepInfo = () => {
        let tmp_transport_arr = [];
        let tmp_iti = itinerary.slice();
        let end_day_before = 0;
        tmp_iti.map((step, index_step) => {
            let days = [];
            let dates = [];
            if (step.step_type !== "START" && step.step_type !== "END") {
                let end = moment.utc(step.end_date, "YYYY-MM-DD");
                let start = moment.utc(step.start_date, "YYYY-MM-DD");
                let nb = Math.abs(moment.duration(start.diff(end)).asDays());

                let last_nb = end_day_before;
                if (nb === 0) {                    
                    days.push((last_nb + 1));
                }
                for (let i = 0; i < nb; i++) {
                    let new_day = 0;
                    if (i === 0) {
                        new_day = ((i + 1) + last_nb);
                    } else {
                         new_day = last_nb + 1;
                    }
                    days.push(new_day);
                    last_nb = new_day;
                }
                end_day_before = last_nb;
            }
            step.days = days;

            for (let i = 0; i < days.length; i++) {
                if (i === 0) {
                    dates.push(step.start_date);
                } else {
                    let date = new Date(step.start_date);
                    date.setDate(date.getDate() + i);
                    dates.push(date);
                }
            }
            step.dates = dates;

            let tmp_obj = {
                id: step.id,
                has_more_details: false,
                more_details: null,
                type_details: null,
                segment: null,
                segment_index: 0
            };
            if (step.r2r_json.vehicle !== undefined) {
                if (step.r2r_json.vehicle.kind === "car") {
                    cars_cart.map((car) => {
                        if ((moment.utc(car.start_date).isAfter(moment.utc(step.start_date)) || moment.utc(car.start_date).isSame(moment.utc(step.start_date), "d")) && (moment.utc(car.end_date).isBefore(moment.utc(step.end_date)) || moment.utc(car.end_date).isSame(moment.utc(step.end_date), "d"))) {
                            if (car.is_displayed === true) {
                                tmp_obj.type_details = "CAR";
                                tmp_obj.more_details = car;
                                tmp_obj.has_more_details = true;
                            }
                        } else if ((moment.utc(step.start_date).isAfter(moment.utc(car.start_date)) || moment.utc(step.start_date).isSame(moment.utc(car.start_date), "d")) && (moment.utc(step.end_date).isBefore(moment.utc(car.end_date)) || moment.utc(step.end_date).isSame(moment.utc(car.end_date), "d"))) {
                            if (car.is_displayed === true) {
                               tmp_obj.type_details = "CAR";
                               tmp_obj.more_details = car;
                               tmp_obj.has_more_details = true;
                            } 
                        }
                        if (tmp_obj.has_more_details === true) {
                            tmp_transport_arr.push(tmp_obj);
                            tmp_obj = {
                                id: step.id,
                                has_more_details: false,
                                more_details: null,
                                type_details: null,
                                segment: null,
                                segment_index: 0
                            };
                        }
                    });
                } else {
                    tmp_obj.type_details = "OTHER";
                    tmp_obj.more_details = step;
                    tmp_obj.has_more_details = false;
                    tmp_transport_arr.push(tmp_obj);
                    tmp_obj = {
                        id: step.id,
                        has_more_details: false,
                        more_details: null,
                        type_details: null,
                        segment: null,
                        segment_index: 0
                    };
                }
            }
        });
        setTransportArr(tmp_transport_arr);
        setPrintIti(tmp_iti);
    }
    if (itinerary !== undefined && itinerary !== null && printIti !== undefined && printIti.length > 0) {
        return (
            <Grid item container direction={"column"} spacing={2} style={{marginTop: 20}}>
            <Box className={classes.itinerarySticky}>
                <Paper style={{borderRadius: 20, padding: 15, marginBottom: 10}}>
                    <Stack>
                        <Grid item ref={itiRef} style={{marginBottom: 30}}>
                            <Fab className={classes.fabColor} size={'small'} disableRipple={true} disableFocusRipple={true}>
                                <RoomIcon/>
                            </Fab>
                            <span className={`${classes.genericText}`} style={{marginLeft: 10, fontWeight: 'bold'}}>{t('apps_navigation.itinerary')}</span>
                        </Grid>
                        <CircuitStepper filterDay={printIti} setStep={setStep} step={step}/>
                    </Stack>
                </Paper>
            </Box>
            {
                printIti.map((step, step_index) => {
                    if (step.days.length !== 0) {
                        let step_name = ((step.destination.data.name !== undefined && step.destination.data.name !== null) ? step.destination.data.name : step.destination.data.international_name).split(",")
                        return (
                            <div key={step_index} ref={ el => ref.current[step_index] = el }>
                            {
                                step.days.map((day, day_index) => {
                                    let step_day = day_index !== step.days.length - 1 ? moment(step.start_date).add(day_index, 'd') : moment(step.end_date);
                                    return (
                                        <Fragment key={day_index}>
                                            <Grid item  style={{width: "100%"}}>
                                                <Grid container direction={"column"} spacing={2}>
                                                    <Grid item style={{width: "100%"}}>
                                                        <hr className={`${classes.genericText} ${classes.hrText}`} data-content={ capitalize(t("global.day1")) + " " + day }/>
                                                    </Grid>
                                                    <Grid item className={classes.textCenter} style={{width: "100%"}}>
                                                        <Typography variant={"h5"} className={`${classes.genericText} ${classes.capitalize}`} >{ step_name }</Typography>
                                                    </Grid>
                                                    <RenderDestination step={step} nb_step={step_index} day_by_day={true} day={day} from_summary={false} step_name={step_name} guide_list={guide_list}/>
                                                </Grid>
                                            </Grid>
                                            {
                                                hotel_cart.map((room, room_index) => {
                                                    if ((moment.utc(room.start_date).isAfter(moment.utc(step.start_date)) || moment.utc(room.start_date).isSame(moment.utc(step.start_date), "d")) && (moment.utc(room.end_date).isBefore(moment.utc(step.end_date)) || moment.utc(room.end_date).isSame(moment.utc(step.end_date), "d"))) {
                                                        return (
                                                            <Grid item key={room_index} className={classes.border}>
                                                                <HotelCard key={room_index} only_one_hotel={ true } hotel={room} index={room_index} products={hotel_cart}/>
                                                            </Grid>
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                cars_cart.map((car, car_index) => {
                                                    if (moment(car.start_date).isSameOrAfter(moment(step.start_date), "d") && moment(car.start_date).isSameOrBefore(moment(step.end_date), "d")) {
                                                        return (
                                                            <Grid item key={car_index} className={classes.border}>
                                                                <CarsCard car={car} transfersHasVariant={false} cars={cars_cart} products={cars_cart}/>
                                                            </Grid>
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                manual_cart.map((manual, manual_index) => {
                                                    if ((manual.product_type === 0 || manual.product_type === 7) && moment(manual.start_date).isSameOrAfter(moment(step.start_date), "d") && moment(manual.end_date).isSameOrBefore(moment(step.end_date), "d")) {
                                                        return (
                                                            <Grid item key={manual_index} className={classes.border}>
                                                                <HotelCardManual key={manual_index} only_one_hotel={ true } hotel={manual} index={manual_index} products={manual_cart}/>
                                                            </Grid>
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                poi_cart.map((poi, poi_index) => {
                                                    if (moment(poi.start_date).isSame(moment(step_day), "d")) {
                                                        return (
                                                            <Grid item key={poi_index} className={classes.border}>
                                                                <ActivitiesCard activity={poi} product={poi_cart}/>
                                                            </Grid>
                                                        )
                                                    }
                                                    
                                                })
                                            }
                                            {
                                                manual_cart.map((manual, manual_index) => {
                                                    if ((manual.product_type === 11 || manual.product_type === 12)) {
                                                        if (moment(manual.start_date, "YYYY-MM-DD").isSame(moment(step_day, "YYYY-MM-DD"), "day")) {
                                                            return (
                                                                <Grid item key={manual_index} className={classes.border}>
                                                                    <ActivitiesCardManual activity={manual} products={manual_cart}/>
                                                                </Grid>
                                                            )
                                                        }
                                                    }
                                                })
                                            }
                                            {
                                                transfers_cart.map((transfer, transfer_index) => {
                                                    if (moment(transfer.start_date).isSameOrAfter(moment(step.start_date), "d") && moment(transfer.start_date).isSameOrBefore(moment(step.end_date), "d")) {
                                                        return (
                                                            <Grid item key={transfer_index} className={classes.border}>
                                                                <TransfersCard transfer={transfer} transfersHasVariant={false} products={transfers_cart}/>
                                                            </Grid>
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                manual_cart.map((manual, manual_index) => {
                                                    if (manual.product_type === 4 && moment(transfer.start_date).isSameOrAfter(moment(step.start_date), "d") && moment(transfer.start_date).isSameOrBefore(moment(step.end_date), "d")) {
                                                        return (
                                                            <Grid item key={manual_index} className={classes.border}>
                                                                <TransfersCardManual transfer={manual} transfersHasVariant={false} products={manual_cart}/>
                                                            </Grid>
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                day_index === step.days.length - 1 && (
                                                    <Grid item>
                                                        <RenderTransport iti={step} next_iti={printIti[step_index + 1]}/>
                                                        {/* <RenderTransport iti={printIti[step_index - 1]} next_iti={step}/> */}
                                                    </Grid>
                                                )
                                            }
                                        </Fragment>

                                    )
                                })
                            }
                            </div>
                        )
                    }
                })
            }
            </Grid>
        )
    }
})
export default DetailDay;