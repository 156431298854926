import React, { Fragment } from "react";
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '19%'
    },
  	marginTop: {
  		marginTop: '156px !important'
  	},
  	rootTablet: {
  		width: '33%'
  	},
  	absoluteCenter: {
  		margin: '0',
  		position: 'absolute',
  		top: '50%',
  		left: '50%',
  		transform: 'translate(-50%, -50%)'
  	},
  	carsCategoryName: {
  	    width: '140px',
  	    fontSize: '12px',
  	    textTransform: 'uppercase',
  	    fontWeight: 'bold'
  	},
  	categoryHeader: {
  		"height": '155px !important',
  		'& img': {
  			maxWidth: '100%',
  			height: '91.72px'
  		},
  		'& .mui-jss-MuiListSubheader-sticky': {
  			top: '70px'
  		}
  	}
}));

const RenderMatrixSkeleton = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between(768, 1024));
    let arr = [];
    for (let i = 0; i < (isTablet ? 3 : 5); i++) {
        arr.push(
            <GridList cellHeight={50} key={i} cols={1} className={isTablet ? classes.rootTablet : classes.root}>
	  			<GridListTile className={classes.categoryHeader}>
	  	    	  	<Paper elevation={0} className="cars-category-container text-center">
		  	    	  	<div>
		  	    	  		<div className={ classes.carsCategoryName }>
		  	    	  			<Skeleton height={100} animation="wave"/>
		  	    	  		</div>
		  	    	  		<div className={ classes.carsCategoryName }>
		  	    	  			<Skeleton height={50} animation="wave"/>
		  	    	  		</div>
		  	    	  	</div>
	  	    	  	</Paper>
	  	        </GridListTile>
        		<GridListTile cols={1}>
            	  	<Paper elevation={0} className="cars-category-container text-center">
            	  		<div className={ classes.carsCategoryName }>
	            	  		<Skeleton height={50} animation="wave"/>
            	  		</div>
            	  	</Paper>
                </GridListTile>
        		<GridListTile cols={1}>
            	  	<Paper elevation={0} className="cars-category-container text-center">
            	  		<div className={ classes.carsCategoryName }>
	            	  		<Skeleton height={50} animation="wave"/>
            	  		</div>
            	  	</Paper>
                </GridListTile>
        		<GridListTile cols={1}>
            	  	<Paper elevation={0} className="cars-category-container text-center">
            	  		<div className={ classes.carsCategoryName }>
	            	  		<Skeleton height={50} animation="wave"/>
            	  		</div>
            	  	</Paper>
                </GridListTile>
        		<GridListTile cols={1}>
            	  	<Paper elevation={0} className="cars-category-container text-center">
            	  		<div className={ classes.carsCategoryName }>
	            	  		<Skeleton height={50} animation="wave"/>
            	  		</div>
            	  	</Paper>
                </GridListTile>
            </GridList>
        );
    }
    return (
        <Grid container item xs={12}>		
            <Grid container item sm={2}>
                <GridList cellHeight={50} cols={1} className={classes.marginTop}>
                    <GridListTile cols={1}>
			    	  	<Paper elevation={0}>
			    	  		<Skeleton height={50} width={150} animation="wave"/>
			    	  	</Paper>
			        </GridListTile>
	        		<GridListTile cols={1}>
	            	  	<Paper elevation={0}>
	            	  		<Skeleton height={50} width={150} animation="wave"/>
	            	  	</Paper>
	                </GridListTile>
	                <GridListTile cols={1}>
			    	  	<Paper elevation={0}>
			    	  		<Skeleton height={50} width={150} animation="wave"/>
			    	  	</Paper>
			        </GridListTile>
	        		<GridListTile cols={1}>
	            	  	<Paper elevation={0}>
	            	  		<Skeleton height={50} width={150} animation="wave"/>
	            	  	</Paper>
	                </GridListTile>
                </GridList>
		    </Grid>		
            <Grid container item sm={10} className={classes.relativePos}>
                {arr}
            </Grid>
        </Grid>
    );
};
export default React.memo(RenderMatrixSkeleton);
