import Dialog from "@material-ui/core/Dialog";
import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import {useTranslation} from "react-i18next";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import UpdateCart from "../../Itinerary/Functions/UpdateCart";
import GetItineraryFlight from "../../Itinerary/InfoReload/GetItineraryFlight";

const useStyles = makeStyles({
    continueButton: {
        color: 'white',
        backgroundColor: "#e6592f"
    },
});

const UpdateTripDatesFromFlight = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const update_trip_date = useSelector(state => state.flight.update_trip_date);
    const itinerary_list = useSelector(state => state.itinerary.itinerary_list);
    const start_date = useSelector(state => state.trip.start_date);
    const departure_destination = useSelector(state => state.itinerary.departure_destination);
    const return_destination = useSelector(state => state.itinerary.return_destination);
    const onDateChange = () => {
        GetItineraryFlight(itinerary_list, start_date, departure_destination, return_destination, dispatch);
//        UpdateCart("CHECK", dispatch, null, null, null, null, null, null, null, null, null, null);
        dispatch({type: "CLOSE_UPDATE_TRIP_DATES_FROM_FLIGHT"});
    };
    const onClose = () => {
        dispatch({type: "CLOSE_UPDATE_TRIP_DATES_FROM_FLIGHT"});
    };
    return (
        <Dialog fullScreen={ fullScreen } PaperProps={{style: { borderRadius: 16 } }} open={ update_trip_date } disableBackdropClick={ true } fullWidth maxWidth={ 'md' }>
            <DialogTitle>
                { t('flight.update_trip_dates_from_flight') }
            </DialogTitle>
            <DialogActions>
                <Button className={ classes.continueButton } onClick={ onDateChange }>{ t("global.yes") }</Button>
                <Button onClick={ onClose } color={ "primary" }>{ t("global.no") }</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateTripDatesFromFlight;