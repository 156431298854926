import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setRecipients } from "../redux/actions";
import CheckBeforeRequest from "../../../../Common/CheckBeforeRequest";
import { AppState } from "../../../../../Reducers/Reducers";

export function useMailTemplateRecipients(): void {
    const dispatch = useDispatch();
    const recipients = useSelector((state: AppState) => state.mailTemplate.others.recipients);
    const trip_info = useSelector((state: AppState) => state.menu.trip_info);
    const to_show_version = useSelector((store: AppState) => store.menu.to_show_version);
    const clientId = window.id_owner;
    const version = to_show_version !== null ? to_show_version : trip_info?.current_version;

    useEffect(() => {
        if (
            clientId !== null &&
            trip_info &&
            version &&
            recipients.state === 'loading'
        ) {
            (async () => {
                const { pass_check, headers } = CheckBeforeRequest();
                if (pass_check) {
                    try {
                        const response = await axios.get<{[index: string]: string | null}>(
                            `${API_HREF}client/${clientId}/trip/${trip_info.id}/versions/${version}/get_recipient_list/`,
                            { headers }
                        );
                        dispatch(setRecipients({
                            state: 'success',
                            data: response.data
                        }));
                    } catch (error: any) {
                        dispatch(setRecipients({
                            state: 'error',
                            error
                        }));
                    }
                }
            })();
        }
    }, [recipients.state]);
}
