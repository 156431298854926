import { Note } from "../../objects/notes"

export const SET_TRIP_LIST_NOTES = 'MENU_SET_TRIP_LIST_NOTES';
export const SET_TRIP_LIST_NOTES_VALUE = 'MENU_SET_TRIP_LIST_NOTES_VALUE';

interface SetTripListNotesAction {
    type: typeof SET_TRIP_LIST_NOTES,
    payload: Note[]
}

interface SetTripListNotesValueAction {
    type: typeof SET_TRIP_LIST_NOTES_VALUE,
    payload: {
        id: number,
        value: string
    }
}

export type TripListNotesActionTypes = 
    SetTripListNotesAction |
    SetTripListNotesValueAction
