import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";
import { SwapVerticalCircleOutlined } from "@mui/icons-material";
import { invertStepInputs } from "./redux/reducer";
import { AppState } from "../../Reducers/Reducers";

export function ItineraryInvertStepsButton(): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector((state: AppState) => state.map.status);
    const tripVersion = useSelector((state: AppState) => state.trip.data_trip);

    const onInvertSteps = () => {
        dispatch(
            invertStepInputs({
                hasTripStart: !!tripVersion?.has_trip_starting_point,
                hasTripEnd: !!tripVersion?.has_trip_starting_point
            })
        );
    };

    return (
        <Tooltip
            title={
                status !== 'idle' ?
                    t<string>('itinerary.steps-invert-disabled-tooltip') :
                    ''
            }
        >
            <span>
                <IconButton
                    onClick={onInvertSteps}
                    sx={{ padding: 0, alignSelf: 'center' }}
                    disabled={status !== 'idle'}
                >
                    <SwapVerticalCircleOutlined />
                </IconButton>
            </span>
        </Tooltip>
    );
}
