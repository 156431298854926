import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Collapse,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
    iconButtonClasses,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "axios";
import { isFunction, isNumber } from "lodash";
import { CartProductPicturesModal } from "../../CartMaterial/CartProductPicturesModal";
import { CartConstructionProductsContext } from "../../CartMaterial/utils/cartConstructionProductsContext";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";

import { AppState } from "../../Reducers/Reducers";
import { CartConstructionReplaceProductContext } from "../../CartMaterial/utils/cartConstructionReplaceProductContext";
import GetProvider from "../../Common/Functions/GetProvider";
import { AddToCart } from "../../../Actions/Transfers";
import { Transfer } from "../objects/Transfer";

export type TransferCardProps = {
    picture: string,
    mainContent: React.ReactNode | ((sideActions: (minimal?: boolean) => React.ReactNode) => React.ReactNode),
    sideContent?: React.ReactNode,
    footer?: React.ReactNode,
    alerts?: React.ReactNode,
    margin?: React.ReactNode,
    children: React.ReactNode,
    direction?: 'horizontal' | 'vertical',
    disablePictures?: boolean,
    showEntirePictures?: boolean,
    search: any,
    group: any,
    card: any
} & ({
    type: 'transfert',
    item: Transfer,
    index: number,
    router: any,
    getContractInfo: any,
    results: any
})

function usePrevious(value) {
    const ref = useRef();

    //Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); //Only re-run if value changes

    //Return previous value (happens before update in useEffect above)
    return ref.current;
}

export function TransferCard(props: TransferCardProps): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const itinerary_type_details_data = useSelector((state: AppState) => state.itinerary_type.itinerary_type_details_data);
    const openProductsDetails = useSelector((state: AppState) => state.cartConstruction.openProductsDetails);
    const provider_list = useSelector((state: AppState) => state.trip.providers);
    const [showDetails, setShowDetails] = useState(false);
    const [openPicturesModal, setOpenPicturesModal] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [mobileStation, setMobileStation] = useState(false);
    const [productQuantity, setProductQuantity] = useState(1);
    const [loading, setLoading] = useState(false);
    const prevItem = usePrevious({ initialized, showDetails });
    const replaceProductContext = useContext(CartConstructionReplaceProductContext);

    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const context = useContext(CartConstructionProductsContext);
    const contentRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);
    const contractRef = useRef<HTMLDivElement>(null);
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    const main_color = JSON.parse(localStorage.getItem('config') ?? '{}').main_color;


    function hasTextContent(node: Node): boolean {
        if (
            node.nodeType === Node.TEXT_NODE &&
            (node.textContent?.trim().length ?? 0) > 0
        ) {
            return true;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            for (let childNode of Array.from(node.childNodes)) {
                if (hasTextContent(childNode)) {
                    return true;
                }
            }
        }
        return false;
    };

    const SideActions = (minimal = false) => (
        <Stack
            direction={
                !minimal ?
                    { xs: 'row-reverse', md: 'column' } :
                    'row'
            }
            alignItems="flex-start"
        >
        </Stack>
    );
    const addTocart = () => {
        console.log('props.search:', props.search);
        let { pass_check, headers } = CheckBeforeRequest();
        let origin_hour = "";
        if (props.search.origin_hour === t("global.noon")) {
            origin_hour = "12:00";
        } else if (props.search.origin_hour === t("global.midnight")) {
            origin_hour = "00:00";
        } else {
            origin_hour = props.search.origin_hour.replace("h", ":");
        }
        let arrival_date = window.moment(props.search.start_date).format("YYYY-MM-DD") + "T" + origin_hour;
        let request = {
            trip: GetCookie("trip_id"),
            identifier: props.card.identifier,
            comps: [props.group.id],
            provider_id: props.card.provider_id,
            arrival_date: arrival_date,
            product_quantity: productQuantity,
            start_destination: props.search.origin_type !== 2 ? props.search.origin_id : props.search.dest_id,
            end_destination: props.search.dest_type !== 2 ? props.search.dest_id : props.search.origin_id
        };
        const type_def = {
            1: 'accommodation',
            2: 'airport',
            3: 'city',
            4: 'port',
            5: 'station'
        };
        if (!props.card.custom_provider) {
		 	request.location_code_pickup = props.search.origin_location_code !== null ? props.search.origin_location_code : undefined;
	    	request.location_code_return = props.search.dest_location_code !== null ? props.search.dest_location_code : undefined;
            request.pickup_type = type_def[props.search.origin_type] === "accommodation" ? "Hotel" : type_def[props.search.origin_type];
            request.return_type = type_def[props.search.dest_type] === "accommodation" ? "Hotel" : type_def[props.search.dest_type];
		    request.country_code = props.search.country_code;
            request.custom_provider = false;
        } else {
            request.custom_provider = true;
            request.product_identifiers = [props.card.id];
        }
        if (pass_check) {
            setLoading(true);
            axios({
			    method: "POST",
			    headers: headers,
			    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/transfers/`,
			    data: JSON.stringify(request)
            }).then((data) => {
                console.log('data:', data);
                let tmp = [];
                tmp.push(data.data);
                if (replaceProductContext.enableReplace && replaceProductContext.onDelete) {
                    replaceProductContext.onDelete();
                }
                dispatch(AddToCart(tmp));
                if (props.search.is_return) {
                    let return_hour = "";
                    if (props.search.return_hour === t("global.noon")) {
                        return_hour = "12:00";
                    } else if (props.search.return_hour === t("global.midnight")) {
                        return_hour = "00:00";
                    } else {
                        return_hour = props.search.return_hour.replace("h", ":");
                    }
                    let request1 = {
                        trip: GetCookie("trip_id"),
                        identifier: props.card.identifier,
                        comps: [props.group.id],
                        is_return: true,
                        provider_id: props.card.provider_id,
                        arrival_date: window.moment(props.search.end_date).format("YYYY-MM-DD") + "T" + return_hour,
                        product_quantity: productQuantity,
                        start_destination: props.search.dest_type !== 2 ? props.search.dest_id : props.search.origin_id,
                        end_destination: props.search.origin_type !== 2 ? props.search.origin_id : props.search.dest_id
                    };
                    if (!props.card.custom_provider) {
                        request1.location_code_return = props.search.origin_location_code !== null ? props.search.origin_location_code : undefined;
                        request1.location_code_pickup = props.search.dest_location_code !== null ? props.search.dest_location_code : undefined;
                        request1.pickup_type = type_def[props.search.dest_type] === "accommodation" ? "Hotel" : type_def[props.search.dest_type];
                        request1.return_type = type_def[props.search.origin_type] === "accommodation" ? "Hotel" : type_def[props.search.origin_type];
                        request1.country_code = props.search.country_code;
                        request1.custom_provider = false;
                    } else {
                        request1.custom_provider = true;
                        request1.product_identifiers = [props.card.id];
                    }
                    axios({
                        method: "POST",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/transfers/`,
                        data: JSON.stringify(request1)
                    }).then((data1) => {
                        let tmp1 = [];
                        tmp1.push(data1.data);
                        dispatch(AddToCart(tmp1));
                        if (!replaceProductContext.enableReplace) {
                            dispatch({
                                type: "CART_SET_STEP",
                                payload: {
                                    step: 1
                                }
                            });
                            props.router.push(`/${window.url_name}/apps/cart`);
                        } else if (replaceProductContext.onClose) {
                            replaceProductContext.onClose();
                        }
                        setLoading(false);
                    }).catch((error) => {
                        console.log(error.response);
                        setLoading(false);
                    });
                } else if (!replaceProductContext.enableReplace) {
                    dispatch({
                        type: "CART_SET_STEP",
                        payload: {
                            step: 1
                        }
                    });
                    props.router.push(`/${window.url_name}/apps/cart`);
                } else if (replaceProductContext.onClose) {
                    replaceProductContext.onClose();
                }

                if (!props.search.is_return) {
                    setLoading(false);
                }
                enqueueSnackbar(t("transfers.post_success"), { variant: "success", disableWindowBlurListener: true });
            }).catch((error) => {
                console.log(error.response.data);
                if (!!error.response.data.detail && (error.response.data.detail.includes("204") || error.response.data.detail === "No matching transfer could be found for this itinerary.")) {
                    enqueueSnackbar(t("transfers.not_available_anymore"), { variant: "error", disableWindowBlurListener: true });
                } else {
                    enqueueSnackbar(error.response.data.detail, { variant: "error", disableWindowBlurListener: true });
                }
                setLoading(false);
            });
        }
    };
    useEffect(() => {
        setShowDetails(openProductsDetails);
    }, [openProductsDetails]);

    return (
        <>
            <Card
                elevation={0}
                sx={{
                    width: '100%',
                    border: '1px solid rgba(0, 0, 0, 0.2)',
                    marginTop: 5
                }}
            >
                <Stack sx={{ width: '100%' }}>
                    <Box
                        sx={() => ({
                            display: 'flex',
                            flexDirection: props.direction === 'vertical' ?
                                'column' :
                                'row'
                        })}>
                        {
                            !props.disablePictures && props.picture !== null &&
                                <CardMedia
                                    key={props.picture}
                                    image={props.picture}
                                    sx={(theme) => ({
                                        backgroundSize: props.showEntirePictures ? '80%' : 'cover',
                                        width: props.direction === 'vertical' ?
                                            '100%' :
                                            '25%',
                                        height: props.direction === 'vertical' ?
                                            250 :
                                            200,
                                        position: 'relative',
                                        [`.${iconButtonClasses.root}`]: {
                                            display: 'none',
                                            [theme.breakpoints.down('md')]: {
                                                display: 'inline-flex'
                                            }
                                        },
                                        [`&:hover .${iconButtonClasses.root}`]: {
                                            display: 'inline-flex'
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            width: '100%',
                                            height: 200
                                        }
                                    })}
                                />
                        }
                        <CardContent
                            sx={(theme) => ({
                                width: props.direction === 'vertical' || props.disablePictures || props.picture === null ?
                                    '100%' :
                                    '75%',
                                paddingBottom: `${theme.spacing(2)} !important`,
                                [theme.breakpoints.down('md')]: {
                                    width: '100%'
                                }
                            })}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                spacing={{ md: 8 }}
                                flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                            >
                                {
                                    !isFunction(props.mainContent) ?
                                        props.mainContent :
                                        props.mainContent(SideActions)
                                }
                                {
                                    (
                                        props.sideContent ||
                                        !props.direction ||
                                        props.direction === 'horizontal'
                                    ) &&
                                    <Stack direction={'column'} spacing={1.5}>
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            paddingTop={{ xs: 1.5, md: 0 }}
                                            margin={{ xs: 'auto' }}
                                            sx={{
                                                marginRight: '0px !important'
                                            }}
                                        >
                                            {
                                                props.sideContent &&
                                                !isNumber(props.item.stack_number) &&
                                                props.sideContent
                                            }
                                            {
                                                // (!props.direction || props.direction === 'horizontal') &&
                                                // SideActions()
                                            }
                                        </Stack>
                                        <Stack
                                            direction={{ md: 'row' }}
                                            alignItems="center"
                                            justifyContent={
                                                props.footer !== undefined ?
                                                    'space-between' :
                                                    'flex-end'
                                            }
                                            spacing={2}
                                        >
                                            <div>
                                                {
                                                    contentRef.current &&
                                                    hasTextContent(contentRef.current) &&
                                                    <Typography
                                                        color="inherit"
                                                        sx={(theme) => ({
                                                            // color: '#fff',
                                                            cursor: 'pointer',
                                                            marginLeft: '0px !important',
                                                            marginRight: '0px !important',
                                                            marginTop: '0px !important',
                                                            // backgroundColor: '#FC4400',
                                                            // border: '1px solid #FC4400',
                                                            textTransform: 'none',
                                                            whiteSpace: 'nowrap',
                                                            ['&:hover']: {
                                                                // border: `1px solid ${theme.palette.getContrastText('#f4f4f4')}`,
                                                                textDecoration: 'underline'
                                                            },
                                                            [theme.breakpoints.down('md')]: {
                                                                margin: 'auto !important',
                                                                marginTop: `${theme.spacing(2)} !important`
                                                            }
                                                        })}
                                                        onClick={() => setShowDetails((state) => !state)}
                                                    >
                                                        {
                                                            showDetails ?
                                                                t('cart-material.cart-construction-hide-description') :
                                                                t('cart-material.cart-construction-show-description')
                                                        }
                                                    </Typography>
                                                }
                                            </div>
                                        </Stack>
                                    </Stack>
                                }
                            </Stack>
                        </CardContent>
                    </Box>
                    <Collapse
                        in={
                            showDetails &&
                            !!contentRef.current &&
                            hasTextContent(contentRef.current)
                        }
                    >
                        <CardContent ref={contentRef}>
                            <Divider sx={{ marginBottom: 1.5 }}/>
                            <Stack sx={{marginLeft: '14px' }}>{props.children}</Stack>
                        </CardContent>
                    </Collapse>
                    <Box
                        sx={(theme) => ({
                            display: 'flex',
                            flexDirection: props.direction === 'vertical' ?
                                'column' :
                                'row',
                            // borderBottom: showDetails ?
                            //     '1px solid rgba(0, 0, 0, 0.25)' :
                            //     undefined,
                            [theme.breakpoints.down('md')]: {
                                flexDirection: 'column'
                            }
                        })}>
                        <CardContent sx={{ width: '100%', paddingTop: 0 }}>
                            {
                                // (
                                //     (footerRef.current && hasTextContent(footerRef.current)) ||
                                //     (contentRef.current && hasTextContent(contentRef.current))
                                // ) &&
                                // <Divider sx={{ marginTop: 2, marginBottom: 1.5 }} />
                            }
                            <Divider/>
                            <Stack
                                direction={{ md: 'row' }}
                                alignItems="center"
                                justifyContent={
                                    props.footer !== undefined ?
                                        'space-between' :
                                        'flex-end'
                                }
                                spacing={2}
                            >
                                <div ref={footerRef}>
                                    {props.footer}
                                </div>
                                <Stack sx={(theme) => ({
                                    paddingTop: '15px',
                                    width: '7%',
                                    [theme.breakpoints.down('md')]: {
                                        width: '100% !important'
                                    }
                                })}>
                                    <FormControl variant={"outlined"} fullWidth>
                                        <InputLabel>{t("transfers.quantity")}</InputLabel>
                                        <Select
                                            labelId="quantity-select-label"
                                            id="quantity-select"
                                            value={productQuantity}
                                            onChange={(e) => {
                                                setProductQuantity(e.target.value);
                                            }}
                                            label={t("transfers.quantity")}
                                        >
                                            {
                                                Array(10).fill().map((_, i) => i + 1).map((quantity) => {
                                                    return (
                                                        <MenuItem key={quantity} value={quantity}>{quantity}</MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <div style={{ paddingTop: 15 }}>
                                    {
                                        // contentRef.current &&
                                        // hasTextContent(contentRef.current) &&
                                        <Button
                                            color="inherit"
                                            sx={(theme) => ({
                                                color: '#fff',
                                                borderRadius: 20,
                                                backgroundColor: `${main_color}`,
                                                border: `1px solid ${main_color}`,
                                                textTransform: 'none',
                                                whiteSpace: 'nowrap',
                                                ['&:hover']: {
                                                    border: `1px solid ${theme.palette.getContrastText('#f4f4f4')}`,
                                                    color: theme.palette.getContrastText('#f4f4f4'),
                                                    backgroundColor: '#f4f4f4'
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    margin: 'auto !important',
                                                    marginTop: `${theme.spacing(2)} !important`
                                                }
                                            })}
                                            onClick={() => {
                                                addTocart();
                                            }}
                                        >
                                            { t<string>("accommodation.validate_cart") }
                                        </Button>
                                    }
                                </div>
                            </Stack>
                        </CardContent>
                    </Box>
                </Stack>
            </Card>
            {
                openPicturesModal &&
                <CartProductPicturesModal
                    type={props.type as any} //TODO: fix types
                    item={props.item as any}
                    onClose={() => setOpenPicturesModal(false)}
                />
            }
        </>
    );
}
