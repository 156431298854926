/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { addPreferences, getPreferences, updatePreference } from "../Functions/PreferenceFunction";
import CrmUserDetailSidePanelItem from "../utils/CrmUserDetailSidePanelItem";

const CrmDemandRightPanel = (props) => {
    const { t } = useTranslation();

    const selected_demand = useSelector(store => store.crm.selected_demand);
    const user = useSelector(store => store.user.user);

    const [prefExist, setPrefExist] = useState(false);
    const [sections, setSections] = useState([
        { order: 1, name: "demand_contacts", title: t("crm.demand.contacts") },
        { order: 2, name: "demand_rooms", title: t("crm.demand.rooms") }
    ]);

    useEffect(() => {
        if (user) {
            getPreferences(user.client, "APPS_CRM_DEMAND_R_PANEL", (res) => {
                let secs = [...sections];
                const prefs = res.data?.results;
                if (prefs.length) {
                    setPrefExist(true);
                    secs.forEach((col, col_index) => {
                        const pref = prefs.find(p => p.field_name === col.name.toUpperCase());
                        if (pref) {
                            secs[col_index].order = pref.order;
                            secs[col_index].ordering_asc = null;
                            secs[col_index].active = true;
                            secs[col_index].id = pref.id;
                        }
                    });
                }
                secs = secs.sort((a, b) => a.order - b.order);
                setSections(secs);
            });
        }
    }, []);

    const reorder = (startIndex, endIndex) => {
        const result = Array.from(sections);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        setSections(result);
        const prefs = result.map((c, index) => {
            return {
                active: c.active,
                can_edit: true,
                field_name: c.name.toUpperCase(),
                module: "APPS_CRM_DEMAND_R_PANEL",
                order: index + 1,
                ordering_asc: c.ordering_asc,
                id: c.id
            };
        });
        if (prefExist) {
            updatePreference(user.client, prefs, () => {
                console.log('prefs updated');
            });
        } else {
            addPreferences(user.client, prefs, () => {
                console.log('prefs added');
            });
        }
    };

    if (selected_demand) {
        return (
            <div className="crm-contact-tab">
                <DndProvider backend={HTML5Backend}>
                    {sections?.map((section, index) => {
                        return <CrmUserDetailSidePanelItem key={section.name} index={index} id={section.name} expanded={"demand_contacts"} title={section.title} reorder={reorder} />;
                    })}
                </DndProvider>
            </div>
        );
    }
    return null;
};
export default CrmDemandRightPanel;
