import React from "react";
import {
    DriveEta,
    Train,
    Air,
    AirportShuttle,
    BedroomBaby,
    DirectionsBike,
    DirectionsBoat,
    DirectionsBus,
    DirectionsCar,
    DirectionsSubway,
    DirectionsTransit,
    DirectionsWalk,
    Flight,
    LocalTaxi,
    QuestionMark,
    TwoWheeler,
} from "@mui/icons-material";
import { Avatar, CircularProgress, StepIconProps } from "@mui/material";
import { grey } from "@mui/material/colors";

const main_color = JSON.parse(localStorage.getItem('config') ?? '{}').main_color;

export function BedroomBabyIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <BedroomBaby sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function DirectionsBikeIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <DirectionsBike sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function DirectionsBusIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <DirectionsBus sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function DirectionsTransitIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <DirectionsTransit sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function CarIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <DriveEta sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function DirectionsBoatIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <DirectionsBoat sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function DirectionsWalkIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <DirectionsWalk sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function AirIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <Air sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function FlightIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <Flight sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function DirectionsCarIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <DirectionsCar sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function AirportShuttleIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <AirportShuttle sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function DirectionsSubwayIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <DirectionsSubway sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function LocalTaxiIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <LocalTaxi sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function TrainIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <Train sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function TwoWheelerIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <TwoWheeler sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function UnknowIcon(props: StepIconProps) {
    return (
        <Avatar
            sx={{
                bgcolor: props.active ? '#1976D2' : grey[500],
                width: 24,
                height: 24,
                position: 'relative',
                '&:hover': {
                    bgcolor: `${main_color}`
                }
            }}
        >
            <QuestionMark sx={{
                margin: '0',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 15
            }} />
        </Avatar>
    );
};
export function LoadingIcon(props: StepIconProps) {
    return (
        <CircularProgress sx={{width: '24px !important', height: '24px !important'}}/>
    );
};