import { useSelector } from "react-redux";
import axios from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { AppState } from "../../../Reducers/Reducers";

type Callback = (tripVersion: number, providerId: number) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: () => void,
    onError: (error: Error) => void,
    onFinally: () => void
}>

export function useSendProviderBookingResponse(options: Options): Callback {
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    return async (version, providerId) => {
        if (tripId) {
            try {
                if (options.onTrigger) {
                    options.onTrigger();
                }
                await makeRequest({
                    tripId,
                    version,
                    providerId
                });
                if (options.onSuccess) {
                    options.onSuccess();
                }
            } catch (error: any) {
                if (options.onError) {
                    options.onError(error);
                }
            } finally {
                if (options.onFinally) {
                    options.onFinally();
                }
            }
        }
    };
}

type RequestOptions = {
    tripId: number,
    version: number,
    providerId: number,
}

async function makeRequest(options: RequestOptions): Promise<void> {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        await axios.get(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/send_provider_booking_response_template/`,
            {
                headers,
                params: {
                    provider_id: options.providerId
                }
            }
        );
    }
}
