import { createContext } from "react";

const defaultValue: {
    tripId: number | null,
    version: number | null,
    providerId: number | null,
    tripToken: string | null
} = {
    tripId: null,
    version: null,
    providerId: null,
    tripToken: null
};

export const ProviderQuotationContext = createContext(defaultValue);
