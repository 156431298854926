import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MapState } from '../objects/mapState';

const initialState: MapState = {
    currentBounds: null,
    currentZoom: 4,
    status: 'idle'
};

const slice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setCurrentBounds: (
            state,
            action: PayloadAction<MapState['currentBounds']>
        ): void => {
            state.currentBounds = action.payload;
        },
        setCurrentZoom: (
            state,
            action: PayloadAction<MapState['currentZoom']>
        ): void => {
            state.currentZoom = action.payload;
        },
        setStatus: (
            state,
            action: PayloadAction<MapState['status']>
        ): void => {
            state.status = action.payload;
        }
    }
});

export default slice;

export const {
    setCurrentBounds,
    setCurrentZoom,
    setStatus
} = slice.actions;
