import { LightDestination } from "../objects/lightDestination";

export function getDestinationPicture(
    destination: LightDestination | null | undefined
): string {
    if (destination?.pictures && destination.pictures[0]?.thumbnail_little) {
        return destination.pictures[0].thumbnail_little;
    } else if (destination?.cover_picture?.thumbnail_little) {
        return destination.cover_picture.thumbnail_little;
    }
    return '/Img/destination_default.jpg';
}
