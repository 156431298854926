//---- Actions ----//
//import { DestinationChange, DestinationListChange as HeaderDestinationListChange } from "../../../Actions/Header";
//import { DestinationListChange as ItineraryDestinationListChange } from "../../../Actions/Itinerary";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

export default (destination, dispatch, destination_filter, google, map) => {
    let { headers } = CheckBeforeRequest();
    let filter;
    if (destination_filter === null) {
        filter = "current_version__localization__name";
    } else {
        filter = destination_filter;
    }
    if (parseInt(destination.data.type) === 4) {
        let results = [];
        results.push(destination);
        dispatch({
            type: "OPTIMIZATION_DESTINATION_CHANGE",
            payload: {
                destination: destination,
                header_destination_list: [],
                itinerary_destination_list: results
            }
        });
        if (!!map && !!google) {
            map.setCenter(new google.maps.LatLng(parseFloat(destination.data.latitude), parseFloat(destination.data.longitude)));
            map.setZoom((destination.data.zoom_level !== undefined && destination.data.zoom_level !== null ? destination.data.zoom_level : 8));
        }
//        dispatch(DestinationChange(destination));
//        dispatch(HeaderDestinationListChange([]));
//        dispatch(ItineraryDestinationListChange(results, "RESET"));
    } else {
        $.ajax({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/destinations/?limit=500&ordering=${filter}&parent=${destination.id}&visibility__in=PUBLIC,PRIVATE_TO`,
            success: function (data) {
                dispatch({
                    type: "OPTIMIZATION_DESTINATION_CHANGE",
                    payload: {
                        destination: destination,
                        header_destination_list: [],
                        itinerary_destination_list: data.results
                    }
                });
                if (data.results.length === 1) {
                    if (!!map && !!google) {
                        map.setCenter(new google.maps.LatLng(parseFloat(data.results[0].data.latitude), parseFloat(data.results[0].data.longitude)));
                        map.setZoom((data.results[0].data.zoom_level !== undefined && data.results[0].data.zoom_level !== null ? data.results[0].data.zoom_level : 8));
                    }
                } else {
                    if (!!map && !!google) {
                        let bounds = new google.maps.LatLngBounds();
                        for (let i = 0; i < data.results.length; i++) {
                            bounds.extend(new google.maps.LatLng(parseFloat(data.results[i].data.latitude), parseFloat(data.results[i].data.longitude)));
                        }
                        map.fitBounds(bounds);
                    }
                }
//                dispatch(DestinationChange(destination));
//                dispatch(HeaderDestinationListChange([]));
//                dispatch(ItineraryDestinationListChange(data.results, "RESET"));
            },
            error: function (error) {
                console.log("Error: something went wrong while loading destinations");
                console.log(error);
            }
        });
    }
}