import { useMemo } from "react";
import {
    groupBy,
    mergeWith
} from "lodash";
import { useCartProducts } from "../../Itinerary/network/cartProducts";
import { useFilteredCartProducts } from "../../Itinerary/network/filteredCartProducts";
import { ManualProduct } from "../../../Reducers/objects/manualProduct";
import { isProductPackaged } from "./isProductPackaged";
import { useSelector } from "react-redux";
import { AppState } from "../../../Reducers/Reducers";

type Options = {
    returnAll?: boolean
}

type Cart = Omit<
    ReturnType<typeof useCartProducts> & {manualProducts: ManualProduct[]},
    'trains' | 'ferries' | 'cruises' | 'insurances'
>

export function usePackagedProducts(options?: Options): {
    [key: string]: Cart
} {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const filteredCart = useFilteredCartProducts();
    const cart = useCartProducts();
    const usedCart = options?.returnAll ? cart : filteredCart;
    return useMemo((): {[version: number]: typeof filteredCart} => {
        const grouppedProducts = {
            flights: groupBy(
                usedCart.flights.filter((item) => {
                    return isProductPackaged({
                        product: item.flight,
                        stackInfos: trip?.stack_info ?? null
                    });
                }),
                (item) => `${item.flight.stack_number ?? ''}-${item.flight.stack_info_id ?? ''}`
            ),
            accommodations: groupBy(
                usedCart.accommodations.filter((item) => {
                    return isProductPackaged({
                        product: item.accommodation,
                        stackInfos: trip?.stack_info ?? null
                    });
                }),
                (item) => `${item.accommodation.stack_number ?? ''}-${item.accommodation.stack_info_id ?? ''}`
            ),
            cars: groupBy(
                usedCart.cars.filter((item) => {
                    return isProductPackaged({
                        product: item.car,
                        stackInfos: trip?.stack_info ?? null
                    });
                }),
                (item) => `${item.car.stack_number ?? ''}-${item.car.stack_info_id ?? ''}`
            ),
            pois: groupBy(
                usedCart.pois.filter((item) => {
                    return isProductPackaged({
                        product: item.poi,
                        stackInfos: trip?.stack_info ?? null
                    });
                }),
                (item) => `${item.poi.stack_number ?? ''}-${item.poi.stack_info_id ?? ''}`
            ),
            transfers: groupBy(
                usedCart.transfers.filter((item) => {
                    return isProductPackaged({
                        product: item.transfer,
                        stackInfos: trip?.stack_info ?? null
                    });
                }),
                (item) => `${item.transfer.stack_number ?? ''}-${item.transfer.stack_info_id ?? ''}`
            ),
            assistances: groupBy(
                usedCart.assistances.filter((item) => {
                    return isProductPackaged({
                        product: item.assistance,
                        stackInfos: trip?.stack_info ?? null
                    });
                }),
                (item) => `${item.assistance.stack_number ?? ''}-${item.assistance.stack_info_id ?? ''}`
            ),
            manualProducts: groupBy(
                (
                    options?.returnAll ?
                        cart.cruises.map((item) => {
                            return item.cruise;
                        }).concat(
                            cart.ferries.map((item) => {
                                return item.ferry;
                            })
                        ).concat(
                            cart.trains.map((item) => {
                                return item.train;
                            })
                        ) :
                        filteredCart.manualProducts
                ).filter((item) => {
                    return isProductPackaged({
                        product: item,
                        stackInfos: trip?.stack_info ?? null
                    });
                }),
                (item) => `${item.stack_number ?? ''}-${item.stack_info_id ?? ''}`
            )
        };
        let result: {
            [stackNumber: number]: Cart
        } = {};
        result = mergeWith(
            result,
            grouppedProducts.flights,
            (result, flights) => {
                return {
                    accommodations: [],
                    assistances: [],
                    cars: [],
                    pois: [],
                    transfers: [],
                    manualProducts: [],
                    ...result,
                    flights
                };
            }
        );
        result = mergeWith(
            result,
            grouppedProducts.accommodations,
            (result, accommodations) => {
                return {
                    flights: [],
                    assistances: [],
                    cars: [],
                    pois: [],
                    transfers: [],
                    manualProducts: [],
                    ...result,
                    accommodations
                };
            }
        );
        result = mergeWith(
            result,
            grouppedProducts.cars,
            (result, cars) => {
                return {
                    flights: [],
                    assistances: [],
                    accommodations: [],
                    pois: [],
                    transfers: [],
                    manualProducts: [],
                    ...result,
                    cars
                };
            }
        );
        result = mergeWith(
            result,
            grouppedProducts.pois,
            (result, pois) => {
                return {
                    flights: [],
                    cars: [],
                    assistances: [],
                    accommodations: [],
                    transfers: [],
                    manualProducts: [],
                    ...result,
                    pois
                };
            }
        );
        result = mergeWith(
            result,
            grouppedProducts.transfers,
            (result, transfers) => {
                return {
                    flights: [],
                    cars: [],
                    assistances: [],
                    accommodations: [],
                    pois: [],
                    manualProducts: [],
                    ...result,
                    transfers
                };
            }
        );
        result = mergeWith(
            result,
            grouppedProducts.assistances,
            (result, assistances) => {
                return {
                    flights: [],
                    cars: [],
                    accommodations: [],
                    pois: [],
                    transfers: [],
                    manualProducts: [],
                    ...result,
                    assistances
                };
            }
        );
        result = mergeWith(
            result,
            grouppedProducts.manualProducts,
            (result, manualProducts) => {
                return {
                    flights: [],
                    cars: [],
                    assistances: [],
                    accommodations: [],
                    pois: [],
                    transfers: [],
                    ...result,
                    manualProducts
                };
            }
        );
        return result;
    }, [
        options?.returnAll,
        options?.returnAll ? cart : filteredCart
    ]);
}
