import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useSnackbar } from "notistack";

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { grey } from '@material-ui/core/colors';

import DeleteIcon from '@material-ui/icons/Delete';

import HasPermission from "../../Common/Functions/HasPermission";
import DeleteUser from "./Functions/DeleteUser.js";

//---- Actions ----//
import { SetManagementStepAndUser } from "../../../Actions/Menu";

const useRowStyles = makeStyles({
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    pointer: {
        cursor: "pointer"
    },
    paddingCell: {
        "& .mui-jss-MuiTableCell-root": {
            padding: "10px 0px 10px 16px !important",
            fontSize: 12
        }
    },
    hover: {
        "&:hover": {
            backgroundColor: '#f1f1f1'
        }
    }
});

const RenderUser = ({ row, row_index, columns, permissions }) => {
    const { t } = useTranslation();
    const classes = useRowStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const user = useSelector(store => store.user.user);

    const [deleteDialog, setDeleteDialog] = useState(false);

    const renderField = (column, column_index) => {
        if (column === t("global.name")) {
            return (
                <TableCell key={column_index} align={"left"}>{row.last_name}</TableCell>
            );
        }
        if (column === t("menu.profile.surname")) {
            return (
                <TableCell key={column_index} align={"left"}>{row.first_name}</TableCell>
            );
        }
        if (column === t("menu.profile.username")) {
            return (
                <TableCell key={column_index} align={"left"}>{row.username}</TableCell>
            );
        }
        if (column === t("menu.created_date")) {
            return (
                <TableCell key={column_index} align={"left"}>{moment(row.date_joined).format("DD/MM/YYYY")}</TableCell>
            );
        }
        if (column === t("menu.user_management.role")) {
            if (row.groups !== null && row.groups.length !== 0) {
                return (
                    <TableCell key={column_index} align={"left"}>{row.groups[0].name}</TableCell>
                );
            }
                
            return (
                <TableCell key={column_index} align={"left"}/>
            );
        }
    };
    const handleClickOpen = () => {
        setDeleteDialog(true);
    };
	
    const handleDialogClose = () => {
        setDeleteDialog(false);
    };
    return (
        <Fragment key={row_index}>
            <TableRow className={ clsx(classes.hover, classes.pointer, classes.paddingCell)} onClick={ (elem) => { 
                if (HasPermission(permissions, "change_user", user) === true) {
                    dispatch(SetManagementStepAndUser(2, row));
                } else {
                    enqueueSnackbar(t("notify.no_permissions"), { variant: "error" });
                }
            }} >
                <TableCell align={"left"}>
                    <Avatar alt={row.first_name} src={row.avatar !== null ? (row.avatar.thumbnail_little !== null ? row.avatar.thumbnail_little : row.avatar.url) : "" } />
                </TableCell>
                {
                    columns.map((column, column_index) => {
                        return (
                            renderField(column, column_index)
                        );
                    })
                }    
                <TableCell align={"left"}>
                    <DeleteIcon style={{ color: grey[500] }} onClick={(e) => {
                        e.stopPropagation();
                        if (HasPermission(permissions, "delete_user", user) === true) {
                            handleClickOpen();
                            //DeleteUser(row, t, dispatch, user.client); 
                        } else {
                            Notify(t("notify.no_permissions"), { status: "danger", pos: "top-center" });
                        }
                    }}/>
                </TableCell>
            </TableRow>
            <Dialog open={deleteDialog} onClose={handleDialogClose}>
                <DialogTitle>{t("menu.user_management.are_you_sure")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("menu.user_management.user_being_deleted") + " " + row.last_name + " " + row.first_name}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        {t("global.no")}
                    </Button>
                    <Button onClick={() => {
                        DeleteUser(row, t, dispatch, user.client, enqueueSnackbar, handleDialogClose);
                    }} color="primary" autoFocus>
                        {t("global.yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};
export default React.memo(RenderUser);
