import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { ProviderQuotationProductStatus } from "./objects/providerQuotationProductStatus";
import { useProviderQuotationProducts } from "./utils/providerQuotationProducts";
import { useProviderQuotationConfirm } from "./utils/providerQuotationConfirm";
import { useShowError } from "../Utils/showError";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    open: boolean,
    statuses: {
        [key: `${string}-${number}`]: {
            id: number,
            isCustom: boolean,
            type: ReturnType<typeof useProviderQuotationProducts>[number]['type'],
            status: ProviderQuotationProductStatus | null,
            providerComment: string | null
        }
    },
    prices: {
        terrestrial: {
            amount: number,
            currency: number | null
        },
        flight?: {
            amount: number,
            currency: number | null
        },
        total: {
            amount: number,
            currency: number | null
        },
    },
    tripId: string | undefined,
    version: string | undefined,
    stackNumber: string | undefined,
    providerId: string | undefined,
    onClose: () => void
}

export function ProviderQuotationConfirmationModal(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const currencies = useSelector((state: AppState) => state.trip.currencies);
    const [loading, setLoading] = useState(false);
    const terrestrialPriceCurrency = useMemo(() => {
        return currencies?.find((item) => {
            return item.id === props.prices.terrestrial.currency;
        });
    }, [currencies, props.prices.terrestrial.currency]);
    const flightPriceCurrency = useMemo(() => {
        return currencies?.find((item) => {
            return item.id === props.prices.flight?.currency;
        });
    }, [currencies, props.prices.flight?.currency]);
    const totalPriceCurrency = useMemo(() => {
        return currencies?.find((item) => {
            return item.id === props.prices.total.currency;
        });
    }, [currencies, props.prices.flight?.currency]);
    const showError = useShowError();
    const confirm = useProviderQuotationConfirm({
        onTrigger() {
            setLoading(true);
        },
        onSuccess() {
            enqueueSnackbar(
                t<string>('cart-material.provider-quotation-send-success'),
                { variant: 'success' }
            );
            props.onClose();
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });

    const onConfirm = () => {
        if (
            props.tripId &&
            props.version &&
            props.providerId &&
            props.prices.terrestrial.currency &&
            props.prices.total.currency &&
            props.stackNumber
        ) {
            confirm(
                props.statuses,
                {
                    terrestrial: {
                        ...props.prices.terrestrial,
                        currency: props.prices.terrestrial.currency
                    },
                    flight: props.prices.flight ?
                        {
                            ...props.prices.flight,
                            currency: props.prices.flight.currency ?? 47
                        } :
                        undefined,
                    total: {
                        ...props.prices.total,
                        currency: props.prices.total.currency
                    }
                },
                {
                    tripId: parseInt(props.tripId),
                    version: parseInt(props.version),
                    stackNumber: parseInt(props.stackNumber),
                    providerId: parseInt(props.providerId)
                }
            );
        }
    };

    const onClose = () => {
        if (!loading) {
            props.onClose();
        }
    };

    return (
        <Dialog open={props.open} onClose={onClose}>
            <DialogTitle>
                {t<string>('cart-material.provider-quotation-confirmation-title')}
            </DialogTitle>
            <DialogContent>
                {
                    Object.values(props.statuses).findIndex((item) => {
                        return !item.status?.provider;
                    }) >= 0 &&
                    <Alert severity="warning" sx={{ marginBottom: 2 }}>
                        {t<string>('cart-material.provider-quotation-unconfirmed-products-hint')}
                    </Alert>
                }
                <Typography textAlign="center">
                    <Trans
                        i18nKey="cart-material.provider-quotation-terrestrial-price-confirmation-hint"
                        values={{
                            price: new Intl.NumberFormat(
                                i18n.language,
                                {
                                    style: 'currency',
                                    currency: terrestrialPriceCurrency?.iso_code ?? 'EUR',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                }
                            ).format(props.prices.terrestrial.amount)
                        }}
                        components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                    />
                </Typography>
                {
                    props.prices.flight &&
                    <Typography textAlign="center">
                        <Trans
                            i18nKey="cart-material.provider-quotation-flight-price-confirmation-hint"
                            values={{
                                price: new Intl.NumberFormat(
                                    i18n.language,
                                    {
                                        style: 'currency',
                                        currency: flightPriceCurrency?.iso_code ?? 'EUR',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 2
                                    }
                                ).format(props.prices.flight.amount)
                            }}
                            components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                        />
                    </Typography>
                }
                <Typography textAlign="center">
                    <Trans
                        i18nKey="cart-material.provider-quotation-total-price-confirmation-hint"
                        values={{
                            price: new Intl.NumberFormat(
                                i18n.language,
                                {
                                    style: 'currency',
                                    currency: totalPriceCurrency?.iso_code ?? 'EUR',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                }
                            ).format(props.prices.total.amount)
                        }}
                        components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                    />
                </Typography>
                <Typography textAlign="center" sx={{ marginTop: 1.5 }}>
                    {t<string>('cart-material.provider-quotation-continue-confirmation')}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    disabled={loading}
                >
                    {t<string>('shared.cancel')}
                </Button>
                <LoadingButton
                    variant="outlined"
                    onClick={onConfirm}
                    loading={loading}
                >
                    {t<string>('cart-material.provider-quotation-confirm')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
