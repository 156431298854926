export function ToggleCalendarModal(open, info) {
	return {
		type: "CALENDAR_TOGGLE_MODAL",
		payload: {
			open: open,
			info: info
		}
	};
}
export function SetCurrentCalendarDay(day) {
	return {
		type: "CALENDAR_SET_CURRENT_DAY",
		payload: {
			day: day.format()
		}
	};
}
export function SetEvent(accommodations, flights, cars, allEvents) {
	return {
		type: "CALENDAR_SET_EVENT",
		payload: {
			accommodations: accommodations,
			flights: flights,
			cars: cars,
			allEvents: allEvents
		}
	};
}
export function SelectCalendarDay(date) {
	return {
		type: "CALENDAR_SELECT_DAY",
		payload: {
			date: date
		}
	};
}
export function SetFullscreen(fullscreen) {
	return {
		type: "CALENDAR_SET_FULLSCREEN",
		payload: {
			fullscreen: fullscreen
		}
	};
}