//---- Dependencies ----//
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";

//---- CSS ----//
import "../../Style/TransportPanel.css";
import "../../Style/CarsSearch.css";
import "../../Style/Poi.css";

//---- Functions ----//
import Notify from "../Common/Notify";
import GetCookie from "../Common/Functions/GetCookie";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import SetInitialData from "../Itinerary/Functions/SetInitialData.js";

//---- Components ----//
import GenerateVehicles from "./GenerateVehicles.jsx";
import RenderTransferDate from "./RenderTransferDate.jsx";

@connect((store) => {
    return {
        itinerary_list: store.itinerary.itinerary_list,
        trip_id: store.trip.trip_id,
        traveler_groups: store.trip.traveler_groups,
        departure_destination: store.itinerary.departure_destination,
        return_destination: store.itinerary.return_destination,
        cars_step: store.cars_search.steps,
        user: store.user.user,
        travelers: store.trip.travelers,
        trip_end_date: store.trip.end_date,
    };
})
@withTranslation()

class StepTransfers extends React.Component {
	constructor(props, context) {
	    super(props, context);
	    const { travelers, traveler_groups } = this.props;
	    let groups = [];
	    for (let i = 0; i < traveler_groups.length; i++) {
	        if (traveler_groups[i].default) {
	            let tmp_group = Object.assign({}, traveler_groups[i]);
	            tmp_group.open = false;
	            groups.push(tmp_group);
	        }
	    }
	    this.state = {
	    	step_result: [],
	        segment_list: [],
	        vehicle_results: [],
	        arrival_date: null,
	        checked: null,
          	focus_start: null,
    	  	focus_end: null,
          	start_date: null,
          	end_date: null,
          	hour_start: "12",
          	minute_start: "00",
          	hour_end: "12",
          	minute_end: "00",
        	is_return: false,
        	passenger_open: false,
        	groups: groups,
        	travelers: [...travelers]
	    };
	}
	componentWillMount() {
		const { itinerary_list, departure_destination, return_destination, dispatch } = this.props;
		const { pass_check, headers } = CheckBeforeRequest();
		let component = this;
		let step_id_array = [];
		for (let i = 0; i < itinerary_list.length; i++) {
			if (itinerary_list[i].step_type !== "START" && itinerary_list[i].step_type !== "END" && itinerary_list[i + 1].step_type !== "END") {
				step_id_array.push([itinerary_list[i].destination.id, itinerary_list[i + 1].destination.id]);
			}
		}
		let request = {
			destination_list: JSON.stringify(step_id_array)
		};
		if (pass_check) {
			$.ajax({
			    method: "GET",
			    headers: headers,
			    url: `${API_HREF}client/${window.id_owner}/transfer-segment/`,
			    data: request,
			    success: function (data) {
			    	data.results.forEach((el) => {
			    		el.checked = false;
			    	});
			    	component.setState({step_result: data.results}, () => {
			    		SetInitialData(itinerary_list, departure_destination, return_destination, dispatch);
			    	});
			    },
			    error: function (error) {
			        console.log(error);
			    }
			});
		}
	}
	componentDidUpdate(prevProps, prevState) {
		const { step_result } = this.state;
		const { cars_step } = this.props;
		// if (prevState.step_result !== step_result) {
		// 	let checked = [];
		// 	step_result.map((step) => {
		// 		if (step.checked) {
		// 			checked.push(step);
		// 		};
		// 		this.setState({checked: checked});
		// 	})
		// }
		if (prevProps.cars_step !== cars_step && step_result) {
			let tmp_step_result = step_result.slice();
			let copy_step_results = [];
			tmp_step_result.map((result) => {
				let find = cars_step.find((step) => {
					return step.origin_id === result.origin_dest.id && step.dest_id === result.destination_dest.id || step.origin_id === result.destination_dest.id && step.dest_id === result.origin_dest.id;
				});
				if (find !== undefined) {
					let tmp_result = Object.assign({}, result);
					tmp_result.start_date = find.start_date_origin;
					tmp_result.end_date = find.end_date_dest;
					result = tmp_result;
				}
				copy_step_results.push(result);
			});
			this.setState({step_result: copy_step_results});
		}
	}
	checkStep(id) {
		const { step_result } = this.state;
		this.setState({
			start_date: moment(step_result[id].start_date),
			end_date: moment(step_result[id].end_date),
			checked: id
		});
	}
	getVehicles() {
		const { checked, step_result, groups, start_date } = this.state;
		const { pass_check, headers } = CheckBeforeRequest();
		const { trip_id, traveler_groups, cars_step, t } = this.props;
		let current_group = traveler_groups.find((group) => {
			return group.default === true;
		});
		let component = this;
		let new_group = false;
		groups[0].travelers_list.map((traveler) => {
			let find = current_group.travelers_list.find((el) => {
				return el.id === traveler.id;
			});
			if (find === undefined) {
				new_group = true;
			}
		});
		if (pass_check) {
			if (new_group) {
				let new_arr = [];
				groups[0].travelers_list.map((el) => {
					new_arr.push(el.id);
				});
				let request = {
				    travelers: new_arr
				};
				$.ajax({
				    method: "POST",
				    headers: headers,
				    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/travelers-group/`,
				    data: JSON.stringify(request),
				    success: function (data) {
				        let request1 = {
				        	custom_provider: true,
				        	segment: step_result[checked].id,
				        	arrival_date: moment(step_result[checked].start_date).format("YYYY-MM-DD"),
				        	comps: data.id,
				        	trip: trip_id,
							start_destination: step_result[checked].destination.id
				        };
				        $.ajax({
				            method: "GET",
				            headers: headers,
				            url: `${API_HREF}client/${window.id_owner}/custom-products/product_transfer_prices/`,
				            data: request1,
				            success: function (data1) {
				            	Notify(t("transfers.get_success"), {status: 'success', pos: 'top-center'});
				            	component.setState({
				            		vehicle_results: data1,
				            		arrival_date: step_result[checked].start_date,
				            		groups: [data]
				            	});
				            },
				            error: function (error) {
				                console.log(error);
				            }
				        });
				    },
				    error: function (error) {
				        console.log(error);
				    }
				});
			}
			else {
				let request1 = {
					custom_provider: true,
					segment: step_result[checked].id,
					arrival_date: moment(step_result[checked].start_date).format("YYYY-MM-DD"),
					comps: current_group.id,
					trip: trip_id,
					start_destination: step_result[checked].origin_dest.id
				};
				$.ajax({
				    method: "GET",
				    headers: headers,
				    url: `${API_HREF}client/${window.id_owner}/custom-products/product_transfer_prices/`,
				    data: request1,
				    success: function (data1) {
				    	Notify(t("transfers.get_success"), {status: 'success', pos: 'top-center'});
				    	component.setState({vehicle_results: data1, arrival_date: step_result[checked].start_date});
				    },
				    error: function (error) {
				        console.log(error);
				    }
				});
			}
		}
	}
	render(){
		const { step_result, vehicle_results, arrival_date, checked, focus_start, focus_end, is_return, minute_start, minute_end, passenger_open, groups } = this.state;
		const { router, cars_step, t } = this.props;
		console.log('step_result:', step_result);
		console.log('cars_step:', cars_step);
  		return(
  			<Fragment>
				<div className={ "cars-title ft-relative" }>
				<button className={"ft-btn-return uppercase ft-white cars-steps-return"} onClick={() => { router.push(`/${window.url_name}/apps/transfers`) }}>{t("global.return")}</button>
				{
					vehicle_results.length === 0 && (
						<h2 className={ "text-center ft-gray" }>{ t("transfers.transfer_title_itinerary") }</h2>
					)
				}
				{
					vehicle_results.length !== 0 && (
						<Fragment>
							<h2 className={ "text-center ft-gray" }>{ t("transfers.vehicle_title")}</h2>
							<p className={ "text-center ft-gray" }>
								{step_result[checked].origin_name}
								<i className={ "icon-103-long-arrow-pointing-to-the-right transfers-destination-arrow " }/>
								{step_result[checked].destination_name}
							</p>
						</Fragment>
					)
				}
				</div>
				{
					vehicle_results.length === 0 && (
			   			<div className={"container-choose-flight absolute-center"}>
			   				<div className={ "cars-steps-choice" }>
				   				<Row>
				   				{
				   					step_result.length !== 0 && step_result.map((step, step_index) => {
				   						return (
						   					<Col lg={ 12 } key={ step_index }>
							                    <label className={ "flight-search-checkbox" }> { step.origin_name } <i className={ "icon-double-arrow" }></i> { step.destination_name } 
							                        <input type={ "checkbox" } checked={ step_index === checked } onChange={ () => this.checkStep(step_index)}/>
							                        <span className={ "flight-search-check-mark-cars" }/>
							                    </label>
						   					</Col>
				   						)
				   					})
				   				}
				   				{
				   					step_result.length === 0 && (
				   						<Col lg={ 12 }>
					   						<h2 className={"text-center"}>{t("transfers.transfer_itinerary_no_result")}</h2>
					   					</Col>
				   					)
				   				}
				   				{
				   					checked !== null && (
				   						<RenderTransferDate component={this} focus_start={focus_start} focus_end={focus_end} is_return={is_return} passenger_open={passenger_open} data_list={cars_step} minute_start={minute_start} minute_end={minute_end} groups={groups} type={ 1 }/>
				   					)
				   				}
				   				</Row>
			   				</div>
			   				{
			   					step_result.length !== 0 && (
					   				<Button className={ "ft-btn-return uppercase ft-white" } onClick={() => {this.getVehicles()}}>Suivant</Button>
			   					)
			   				}
			   			</div>
					)
				}
				{
					vehicle_results.length !== 0 && (
						<Fragment>
						<Row className={ "vehicle-container" }>
							<Col lg={6} className={ "offset-md-3 text-center" }>
								<GenerateVehicles component={this} display_vehicles={vehicle_results} arrival_date={arrival_date}/>
							</Col>
						</Row>
						{
							<Row>
								<Col lg={6} className={ "offset-md-3 text-center" }>
									<Button className={ "ft-btn-return uppercase ft-white" } onClick={() => {this.setState({vehicle_results: []});}}>Précedent</Button>
								</Col>
							</Row>
						}
						</Fragment>
					)
				}
  			</Fragment>
  		);
 	}
}
export default StepTransfers;