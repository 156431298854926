import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import EuroIcon from '@material-ui/icons/Euro';
import Slider from "@material-ui/core/Slider";
import CloseIcon from '@material-ui/icons/Close';

import Transition from "../../../Common/Transition";

import { SetPriceSlider, SetPriceFilter } from "../../../../Actions/FlightSearch";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize8: {
        fontSize: 8
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    verticalSpacer: {
        padding: "8px 0px"
    },
    sliderSpacer:{
        padding: "8px 50px"
    },
    filterButton: {
        border: "1.03872px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: 31.1617,
        padding: "8px 12px"
    },
    closeMenu: {
        marginRight: 5
    }
}))

const PriceButton = (({}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const slider_min_price = useSelector(store => store.flight_search.slider_min_price);
    const slider_max_price = useSelector(store => store.flight_search.slider_max_price);
    const min_price = useSelector(store => store.flight_search.min_price);
    const max_price = useSelector(store => store.flight_search.max_price);
    const trip_currency = useSelector(store => store.trip.currency);    

    const [openPrice, setOpenPrice] = useState(null);

    return(
        <Grid item>
            <Button size="small" className={classes.filterButton} onClick={(event) => setOpenPrice(event.currentTarget)}>
                <EuroIcon className={classes.genericText} />
                <div className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`} style={{ paddingLeft: 8, textTransform: "capitalize" }}>
                    { t("global.price") }
                </div>
            </Button>
            {
                isHandHeld && (
                    <Dialog open={Boolean(openPrice)} fullScreen TransitionComponent={Transition}>
                        <DialogTitle>
                            <Grid container alignItems="center" justify="flex-end" style={{ width: 500 }}>
                                <Grid item xs={4} />
                                <Grid item xs={4} className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                    { t("flight_search.total_price") }
                                </Grid>
                                <Grid item xs={4} className={classes.textRight}>
                                    <IconButton size="small" className={classes.closeMenu} onClick={() => setOpenPrice(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ padding: "0px 18px 0px 8px" }}>
                            <Grid className={classes.sliderSpacer} style={{ marginTop: 40 }}>
                                <Slider
                                    value={[slider_min_price, slider_max_price]}
                                    onChange={(event, value) => dispatch(SetPriceSlider(value[0], value[1]))}
                                    onChangeCommitted={(event, value) => dispatch(SetPriceFilter(value[0], value[1]))}
                                    min={min_price}
                                    max={max_price}
                                    valueLabelDisplay="on"
                                    valueLabelFormat={(x) => x + " " + trip_currency.symbol}
                                    style={{ color: `${main_color}` }}
                                    classes={{ valueLabel: classes.fontSize8 }}
                                    />
                            </Grid>
                        </DialogContent>
                    </Dialog>
                )
            }
            {
                !isHandHeld && (
                    <Menu 
                        id="price-menu"
                        anchorEl={openPrice}
                        keepMounted
                        open={Boolean(openPrice)}
                        onClose={() => setOpenPrice(false)}
                        disableScrollLock={true}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        PaperProps={{ style: { overflow: "visible" }}}
                        >
                        <Grid container alignItems="center" justify="flex-end" style={{ width: 500 }}>
                            <Grid item xs={4} />
                            <Grid item xs={4} className={`${classes.verticalSpacer} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize14} ${classes.textCenter}`}>
                                { t("flight_search.total_price") }
                            </Grid>
                            <Grid item xs={4} className={classes.textRight}>
                                <IconButton size="small" className={classes.closeMenu} onClick={() => setOpenPrice(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid className={classes.sliderSpacer} style={{ marginTop: 40 }}>
                            <Slider
                                value={[slider_min_price, slider_max_price]}
                                onChange={(event, value) => dispatch(SetPriceSlider(value[0], value[1]))}
                                onChangeCommitted={(event, value) => dispatch(SetPriceFilter(value[0], value[1]))}
                                min={min_price}
                                max={max_price}
                                valueLabelDisplay="on"
                                valueLabelFormat={(x) => x + " " + trip_currency.symbol}
                                style={{ color: `${main_color}` }}
                                classes={{ valueLabel: classes.fontSize8 }}
                                />
                        </Grid>
                    </Menu>
                )
            }
        </Grid>
    )
})

export default React.memo(PriceButton);