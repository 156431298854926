//Dependencies
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
//Core & Lab
import Button from '@material-ui/core/Button';
//Actions
import { setPicture, updateProgress } from '../../Actions/Pictures';
import { updateName, updateDescription } from '../../Actions/Pictures';

import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import GetCookie from '../Common/Functions/GetCookie';
import i18n from '../../i18n';

const useStyles = makeStyles(() => ({
    ftUserEditAddressContactChangeSave: {
        color: "#3367D6 !important"
    }
}));

const CartPictureEditSave = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const cropper = useSelector((state) => state.pictures.cropper);
    const picture = useSelector((state) => state.pictures.picture);
    const keep = useSelector((state) => state.pictures.keep);
    const name = useSelector((state) => state.pictures.name);
    const alt = useSelector((state) => state.pictures.alt);
    const credits = useSelector((state) => state.pictures.credits);
    const description = useSelector((state) => state.pictures.description);
    const { headers } = CheckBeforeRequest();

    const onSaveChange = () => {
        if (picture !== null) {
            if (keep) {
                let request = new FormData();
                request.append('name', name);
                request.append('description', description);
                request.append('alt', alt);
                request.append('credits', credits);
                axios({
                    method: 'PATCH',
                    url: `${API_HREF}client/${window.id_owner}/pictures/${picture.id}/`,
                    headers: {
                        "Authorization": "Token " + GetCookie("token"),
                        "Accept": "application/json",
                        "Accept-Language": i18n.language
                    },
                    data: request
                }).then((response) => {
                    dispatch(setPicture(response.data));
                    dispatch(updateName(response.data.name));
                    dispatch(updateDescription(response.data.description));
                    props.onClose();
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 400) {
                            for (const [key, value] of Object.entries(error.response.data)) {
                                if (Array.isArray(value)) {
                                    value.map(error => enqueueSnackbar(`${key} : ${error}`, { variant: 'error' }));
                                } else {
                                    enqueueSnackbar(`${key} : ${value}`, { variant: 'error' });
                                }
                            }
                        } else if (error.response.status === 401) {
                            enqueueSnackbar(t('errors.permissions'), { variant: 'error' });
                        } else if (error.response.status === 500) {
                            enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                        }
                    } else if (error.request) {
                        console.log('error.request : ', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                    console.log('error.config : ', error.config);
                });
            } else {
                cropper.getCroppedCanvas().toBlob((blob) => {
                    let request = new FormData();
                    request.append('name', name);
                    request.append('description', description);
                    request.append('alt', alt);
                    request.append('credits', credits);
                    request.append('image', blob);
                    axios({
                        method: 'PUT',
                        url: `${API_HREF}client/${window.id_owner}/pictures/${picture.id}/`,
                        headers: headers,
                        data: request,
                        onUploadProgress: (progressEvent) => {
                            dispatch(updateProgress(progressEvent.loaded / progressEvent.total * 100));
                        }
                    }).then((response) => {
                        dispatch(setPicture(response.data));
                        dispatch(updateName(response.data.name));
                        dispatch(updateDescription(response.data.description));
                        props.onClose();
                    }).catch((error) => {
                        if (error.response) {
                            if (error.response.status === 400) {
                                for (const [key, value] of Object.entries(error.response.data)) {
                                    if (Array.isArray(value)) {
                                        value.map(error => enqueueSnackbar(`${key} : ${error}`, { variant: 'error' }));
                                    } else {
                                        enqueueSnackbar(`${key} : ${value}`, { variant: 'error' });
                                    }
                                }
                            } else if (error.response.status === 401) {
                                enqueueSnackbar(t('errors.permissions'), { variant: 'error' });
                            } else if (error.response.status === 500) {
                                enqueueSnackbar(t('errors.servers'), { variant: 'error' });
                            }
                        } else if (error.request) {
                            console.log('error.request : ', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                        console.log('error.config : ', error.config);
                    });
                });
            }
        }
    };
    return <Button className={ classes.ftUserEditAddressContactChangeSave } onClick={ onSaveChange }>{ t('cart-material.update-picture') }</Button>;
};

export default CartPictureEditSave;
