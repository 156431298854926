//---- Dependencies ----//
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles} from "@material-ui/core/styles";
import i18n from "../../../i18n.jsx";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

//---- CSS ----//
import "../../../Style/Accommodation.css";

//---- Actions ----//
import { toggleMCTOModal } from "../../../Actions/Menu";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    listMargin:{
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft:1
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A",
        fontWeight: 500,
        letterSpacing: 1.25
    },
    fontSize24: {
        fontSize: 24
    },
    fontSize13:{
        fontSize: 13
    },
    upperCase:{
        textTransform: "uppercase",
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//---- Class ----//
const MCTOModal = (() => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const smallScreen = useMediaQuery('(max-width:425px)');
    const mcto_modal = useSelector(store => store.menu.mcto_modal);
    const mcto_data = useSelector(store => store.menu.mcto_data);
    moment.locale(i18n.language);
    return(
        <Box display='flex'>
            <Dialog open={mcto_modal} fullScreen={ smallScreen === true ? true : false} TransitionComponent={Transition} fullWidth maxWidth={"xs"}>
                <DialogTitle id={"mcto-dialog"}>
                    <Grid container justify={ smallScreen === true ? "flex-start" : "space-between" } alignItems={ "center" }>
                        {
                            smallScreen && (
                                <Grid item style={{marginRight: "5%"}}>
                                    <IconButton onClick={() => {dispatch(toggleMCTOModal());}} size='small'> <NavigateBeforeIcon /> </IconButton>
                                </Grid>
                            )
                        }
                        <Grid item className={`${classes.genericText} ${classes.upperCase} ${classes.fontSize24}`}>
                            { JSON.parse(localStorage.getItem("config")).quotation_code === "verdie" ? t("menu.trip_list.mcto_list") :  t("menu.trip_list.pgi_list") }
                        </Grid>

                        {
                            !smallScreen && (
                                <Grid item>
                                    <IconButton onClick={() => {dispatch(toggleMCTOModal());}} size='small'> <CloseIcon style={{float: "right"}}/> </IconButton>
                                </Grid>
                            )
                        }            
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    {
                        mcto_data !== null && mcto_data.length !== 0 && (
                            <List>
                                {
                                    mcto_data.map((mcto, index_mcto) => {
                                        return(
                                            <ListItem key={"mcto-" + index_mcto} className={`${classes.genericText} ${classes.fontSize13} ${classes.listMargin}`}>
                                                {JSON.parse(localStorage.getItem("config")).quotation_code === "verdie" ? "MCTO" : "PGI"} {t("global.number") + (mcto.version + 1) + " "}
                                                {t("global.created_date") + " " + moment.utc(mcto.date).format("L") + " " + moment.utc(mcto.date).local().format("LT")}
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                    )
                    }
                    {
                        mcto_data !== null && mcto_data.length === 0 && (
                            <div className={`${classes.genericText} ${classes.fontSize18}`}>
                                {t("menu.trip_list.no_mcto")}
                            </div>
                        )
                    }
                </DialogContent>
            </Dialog>
        </Box>
    )
})

export default React.memo(MCTOModal);