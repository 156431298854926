import React, { Fragment, useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Skeleton from '@material-ui/lab/Skeleton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckBoxFilled from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { useSnackbar } from "notistack";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles({
    checked: {
        fill: "black"
    },
    dialogActions: {
        padding: 24
    },
    underline: {
        textDecoration: 'underline'
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    checkbox: {
        fontSize: "2rem"
    },
    borderRadius: {
        "& .mui-jss-MuiDialog-paper": {
            borderRadius: 18
        }
    }
});

const ProviderListFilters = ({ open, setShowProvider, provider_list, setProviderFilters, setUpdate, user }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [copy_provider_list, setCopyProvider] = useState([]);
    useEffect(() => {
        setCopyProvider(provider_list);
    }, [provider_list]);
    function handleChange(provider_index) {
        let tmp_provider = [];
        copy_provider_list.map((provider, index) => {
            if (index === provider_index) {
                let copy_provider = Object.assign({}, provider);
                copy_provider.checked = !copy_provider.checked;
                provider = copy_provider;
            }
            tmp_provider.push(provider);
        });
        setCopyProvider(tmp_provider);
    }
    //const removeAll = () => {
    //let tmp_provider = [];
    //copy_provider_list.map((provider, index) => {
    //let copy_provider = Object.assign({}, provider);
    //copy_provider.checked = false;
    //tmp_provider.push(copy_provider);
    //});
    //setCopyProvider(tmp_provider);
    //}
    const checkedAll = () => {
        let tmp_provider = [];
        copy_provider_list.map((provider, index) => {
            let copy_provider = Object.assign({}, provider);
            copy_provider.checked = true;
            tmp_provider.push(copy_provider);
        });
        setCopyProvider(tmp_provider);
    };
    const handleCancel = () => {
        setCopyProvider(provider_list);
    };
    const setFilters = () => {
        let diff = false;
        let check_provider = copy_provider_list.find((el) => {
            return el.checked;
        });
        if (check_provider !== undefined) {
            console.log("copy_provider_list:", copy_provider_list);
            for (let i = 0; i < provider_list.length; i++) {
                if (provider_list[i].checked !== copy_provider_list[i].checked) {
                    diff = true;
                }
            }
            if (diff) {
                setProviderFilters(copy_provider_list);
                setUpdate(true);
            }
            setShowProvider(false);
        } else {
            enqueueSnackbar(t("cars.rental_error"), {
                variant: "warning"
            });
        }
    };
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    return (
        <Dialog open={ open } onClose={ () => {
            setCopyProvider(provider_list);
            setShowProvider(false);
        } } fullWidth fullScreen={ fullScreen } maxWidth={ "md" }
        className={classes.borderRadius}
        >
            <DialogTitle onClose={ () => {
                setCopyProvider(provider_list);
                setShowProvider(false);
            } }>{ t("cars.renters_list") }</DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    {
                        copy_provider_list.length !== 0 && copy_provider_list.map((provider, provider_index) => {
                            return (
                                <Grid item sm={6} xs={12} key={provider_index}>
                                    <FormControlLabel
                                        control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ "small" }/> } checkedIcon={ <CheckBoxFilled className={ classes.checked } fontSize={ "small" }/> } checked={ provider.checked } onChange={() => {
                                            handleChange(provider_index); 
                                        }} /> }
                                        label={
                                            <Fragment>
                                                {
                                                    (quotation_code !== "marcovasco" && user.client_full.type !== 2) && (
                                                        <Fragment>
                                                            <span>
                                                                { provider.provider_name}
                                                            </span>
                                                            {
                                                                provider.provider_name !== provider.subprovider_name && (
                                                                    <span>
                                                                        {
                                                                            " - " + provider.subprovider_name
                                                                        }
                                                                    </span>
                                                                )
                                                            }
                                                        </Fragment>
                                                    )
                                                }
                                                {
                                                    (quotation_code === "marcovasco" || user.client_full.type === 2) && (
                                                        <span>
                                                            {provider.subprovider_name}
                                                        </span>
                                                    )
                                                }
                                            </Fragment> 
                                        }
                                    />
                                    {
                                        provider_index !== (copy_provider_list.length - 1) && (
                                            <br/>
                                        )
                                    }
                                </Grid>
                            );
                        })
                    }
                    {
                        copy_provider_list.length === 0 && (
                            <Fragment>
                                <Grid item sm={6} xs={12}>
                                    <Skeleton width={400} height={35} animation={"wave"}/>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Skeleton width={400} height={35} animation={"wave"}/>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Skeleton width={400} height={35} animation={"wave"}/>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Skeleton width={400} height={35} animation={"wave"}/>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogContent>
            <DialogActions className={ classes.dialogActions }>
                <Button onClick={checkedAll} className={ classes.underline }>
                    {t("cars.select_all_true")}
                </Button>
                <div style={{ flex: '1 0 0' }} />
                <Button className={ classes.underline } onClick={ handleCancel }>{ t("global.cancel") }</Button>
                <Button variant={ "contained" } className={ classes.orangeButton } onClick={ setFilters }>{ t("quotation.ok") }</Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(ProviderListFilters);
