//---- Dependencies ----//
import React from 'react';
import { connect } from "react-redux";
import { Link, History } from "react-router";
import { withTranslation } from "react-i18next";

//---- Actions ----//
import { SetSelectedPage } from "../../../Actions/Menu";

//---- Components ----//


//---- CSS ----//
import "../../../Style/Menu/AgencyCustomerManagement.css";

//---- Class ----//
@connect((store) => {
    return {
    }
})
@withTranslation()
class AgencyCustomerManagement extends React.Component {

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(SetSelectedPage("agency-customer-management"));
    }
    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(SetSelectedPage(null));
    }
    
    render() {
        const { dispatch, t } = this.props;
        return (
			<div className={"wrapper"} >
				AgencyCustomerManagement
			</div>
        );
    }
}

export default AgencyCustomerManagement;