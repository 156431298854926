/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import Popover from '@mui/material/Popover';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';

import '../../crm.css';
import { useTranslation } from "react-i18next";
import TagsInput from "../../utils/form/TagsInput";
import MaterialUiPhoneNumber from "material-ui-phone-number";
import { countriesPhone } from "../../objects/data";
import { useDispatch, useSelector } from "react-redux";
import CrmUserDetailTravelerFormDialog from "../dialogs/CrmUserDetailTravelerFormDialog";
import { patchUserFull } from "../../Functions/UserFunctions";
import { SetUserList } from "../../../../../Actions/User";
import { SetSelectedUser } from "../../../../../Actions/Crm";
import moment from "moment";

const CrmUserDetailContactField = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [newValue, setNewValue] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [displayPressEnter, setDisplayPressEnter] = React.useState(false);

    const selected_user = useSelector(store => store.crm.selected_user);
    const user_client = useSelector(store => store.user.user);
    const user_list = useSelector(store => store.user.user_list);

    useEffect(() => {
        if (props.fullname) {
            setNewValue(props.fullname);
        } else {
            setNewValue(props.value);
        }
    }, [props, selected_user]);

    const [openTravelerForm, setOpenTravelerForm] = React.useState(false);

    const closeDialog = () => {
        setOpenTravelerForm(false);
    };

    const openFullscreen = () => {
        setOpenTravelerForm(true);
    };

    const transformBirthDate = (inputDate) => {
        const dateObject = new Date(inputDate.substring(0, 10));
        //eslint-disable-next-line no-extend-native
        Date.prototype.toLocaleISOString = function () {
            return this.getFullYear() +
                '-' + ('0' + (this.getMonth() + 1)).slice(-2) +
                '-' + ('0' + this.getDate()).slice(-2) +
                'T' + ('0' + this.getHours()).slice(-2) +
                ':' + ('0' + this.getMinutes()).slice(-2) +
                ':' + ('0' + this.getSeconds()).slice(-2) +
                '.' + ('00' + this.getMilliseconds()).slice(-3) +
                'Z';
        };
        return dateObject.toLocaleISOString();
    };

    const updateUserInfo = (traveler_resp) => {
        let traveler = JSON.parse(JSON.stringify(traveler_resp));
        let isoDate = null;
        if (traveler.birth_date) {
            isoDate = transformBirthDate(traveler.birth_date);
        }
        traveler.zip_code = traveler.postal_code;
        traveler.street = traveler.address;
        patchUserFull(user_client.client, { ...traveler, id: selected_user.id, birth_date: isoDate }, () => {
            if (user_list) {
                let user_list_updated = JSON.parse(JSON.stringify(user_list));
                const index = user_list_updated.findIndex(u => u.id === traveler.id);
                if (index !== -1) {
                    user_list_updated[index] = { ...selected_user, ...traveler };
                    dispatch(SetUserList(user_list_updated));
                }
            }
            dispatch(SetSelectedUser({ ...selected_user, ...traveler }));
            closeDialog();
        });
    };

    const handleClick = (event) => {
        if (props.customEditCallback !== undefined) {
            props.customEditCallback(event);
        } else if (props.expandable) {
            openFullscreen();
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleChange = (event) => {
        setNewValue(event.target.value);
    };

    const handleChangeFullname = (event, field) => {
        let new_v = { ...newValue };
        new_v[field] = event.target.value;
        setNewValue(new_v);
    };

    const handleChangeTags = (value) => {
        setNewValue(value);
        setDisplayPressEnter(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const executeCallback = () => {
        if (props.callback !== null && props.callback !== undefined) {
            props.callback({ newValue, onFinish: () => { setAnchorEl(null) } });
        }
    };

    const getLabelForValue = () => {
        const label = props.options?.find(e => e.value === props.value)?.label;
        return label ? label : props.value;
    };

    const handlePhoneNumber = (value, country_data) => {
        setNewValue(value);
    };

    const getPhoneCode = () => {
        const defaultLanguage = navigator.language;
        const locale = new Intl.Locale(defaultLanguage);
        const countryCode = locale.region ? locale.region : locale.language?.slice(-2)?.toLocaleUpperCase();
        const found = countriesPhone.find(e => e.code === countryCode);
        if (found) {
            return found.dial_code;
        }
        return "+1";
    };

    const sendMailTo = (email) => {
        window.open('mailto:' + (email ? email : selected_user?.email));
    };
    const call = (phone) => {
        window.open('tel:' + phone);
    };

    const getValue = () => {
        let value = props.value;
        if (props.displayValue) {
            value = props.displayValue;
        } else if (props.options) {
            value = getLabelForValue(props.value);
        } else if (props.is_date) {
            value = moment(new Date(props.value)).format("DD/MM/YYYY");
        }
        let valueDisplay = <p>{value}</p>;
        if (props.useBreakline) {
            return (<div style={{ flexDirection: "column" }}>
                {
                    props.value?.map((e) => {
                        return (
                            <p>
                                {e.label} {props.additional_action?.type === "send_email" && <EmailIcon onClick={() => sendMailTo(e.value)} />}
                                {props.additional_action?.type === "call" && <CallIcon onClick={() => call(e.value)} />}
                            </p>);
                    })
                }
            </div>);
        }
        return valueDisplay;
    };

    const handleInputChange = (event, value) => {
        const pattern = new RegExp(value, 'i');
        const isOptionExists = props.options.some((option) => pattern.test(option));

        if (!isOptionExists) {
            setDisplayPressEnter(true);
        } else {
            setDisplayPressEnter(false);
        }
    };

    useEffect(() => {
        setOpen(Boolean(anchorEl));
    }, [anchorEl]);

    return (
        <div className="contact-field-item">
            {!props.fullname && <p>{props.label}</p>}
            <div>
                {props.fullname && <h2>{props.value}</h2>}
                {!props.fullname && <>{getValue()}</>}
                <div>
                    {
                        !props.readonly &&
                        <>
                            <EditIcon onClick={handleClick} />
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }}
                            >
                                <div className="close-div" onClick={handleClose}>
                                    <CloseIcon />
                                </div>
                                <div className="contact-field-edit">
                                    {
                                        !props.phone_input && !props.options && !props.tags && !props.fullname &&
                                        <TextField label={props.label} onChange={(e) => handleChange(e)} variant="outlined" value={newValue}
                                            multiline={props.multiline}
                                            rows={props.multiline ? 4 : 1}
                                        />
                                    }
                                    {
                                        props.fullname &&
                                        <>
                                            <TextField label={t('global.first_name')}
                                                onChange={(e) => handleChangeFullname(e, 'first_name')} variant="outlined" value={newValue?.first_name}
                                            />
                                            <TextField label={t('global.last_name')}
                                                onChange={(e) => handleChangeFullname(e, 'last_name')} variant="outlined" value={newValue?.last_name}
                                            />
                                        </>
                                    }
                                    {
                                        props.phone_input &&
                                        <MaterialUiPhoneNumber
                                            type={'text'} label={props.label}
                                            onChange={(value, country_data) => handlePhoneNumber(value, country_data)}
                                            value={newValue ? newValue : getPhoneCode()}
                                            variant="outlined"
                                            disableAreaCodes
                                            helperText={`ex: +33 6 12 34 56 78`}
                                        />
                                    }
                                    {
                                        props.options && !props.tags && !props.phone_input &&
                                        <FormControl fullWidth>
                                            <InputLabel>{props.label}</InputLabel>
                                            <Select
                                                value={newValue}
                                                label={props.label}
                                                onChange={(e) => handleChange(e)}
                                            >
                                                {props.options.map(option => {
                                                    return <MenuItem value={option.value}>{option.label}</MenuItem>;
                                                })}
                                            </Select>
                                        </FormControl>
                                    }
                                    {
                                        props.tags && !props.phone_input &&
                                        <>
                                            <Autocomplete
                                                multiple
                                                freeSolo
                                                label={props.label}
                                                options={props.options}
                                                getOptionLabel={(option) => option}
                                                defaultValue={props.value}
                                                filterSelectedOptions
                                                onInputChange={handleInputChange}
                                                onChange={(e, v) => handleChangeTags(v)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={props.label}
                                                    />
                                                )}
                                            />
                                            {displayPressEnter && <small>{t('crm.press_enter')}</small>}
                                        </>
                                    }
                                    <div>
                                        <Button variant="contained" onClick={executeCallback}>{t("crm.save")}</Button>
                                        <Button variant="outlined" onClick={handleClose}>{t("crm.cancel")}</Button>
                                    </div>
                                </div>
                            </Popover>
                        </>
                    }
                    {props.additional_action !== undefined && <>{props.additional_action.icon}</>}
                </div>
            </div>

            <CrmUserDetailTravelerFormDialog
                open={openTravelerForm}
                handleClose={closeDialog}
                onConfirm={updateUserInfo}
                mode={'user'} />
        </div>
    );
};
export default React.memo(CrmUserDetailContactField);
