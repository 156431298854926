//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import FlightSummary from './FlightSummary';
import FlightSummaryManual from './FlightSummaryManual';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fullWidth: {
        width: "100%"
    },
    textJustify: {
        textAlign: "justify"
    },
    textCenter: {
        textAlign: "center"
    },
    bold: {
        fontWeight: "bold"
    },
    stickyPaper: {
        position: "sticky",
        top: 80,
        borderRadius: 12
    },
    buttonContainer: {
        minWidth: 150,
        margin: '0 15px 15px'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    bold: {
        fontWeight: "bold"
    },
    capitalize: {
        textTransform: "capitalize"
    },
    hrText: {
        lineHeight: "20px",
        position: "relative",
        outline: "0",
        border: "0",
        textAlign: "center",
        height: 20,
        fontSize: 34,
        '&::before': {
            content: "''",
            background: "#0000008A",
            position: "absolute",
            left: "0",
            top: "50%",
            width: "100%",
            height: "1px"
        },
        '&::after': {
            content: "attr(data-content)",
            position: "relative",
            display: "inline-block",
            color: "#0000008A",
            padding: "0 .5em",
            // lineHeight: "1.5em",
            backgroundColor: "white"
        }
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    hoverContainer: {
        "&:hover": {
            borderLeft: '6px solid #E6592F'
        }
    },
    borderContainer: {
        borderTop: "1px solid #C4C4C4",
        borderBottom: "1px solid #C4C4C4",
        paddingBottom: "0px !important"
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 50
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
    marginLeft18: {
        marginLeft: 18
    },
    marginLeft8: {
        marginLeft: 8
    },
    paddingLeft0: {
        paddingLeft: "0px !important"
    }
}));

const RenderSummaryFlight = ({flight_cart, manual_cart, type, start_date, end_date, position, selectedProducts, setSelectedProducts, step_index, from}) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();

    const trip_info = useSelector(state => state.summary.trip_info);

    const [travelFlight, setTravelFlight] = useState([]);
    const [optionalFlight, setOptionalFlight] = useState([]);
    useEffect(() => {
        if (flight_cart.length > 0) {
            let main_flight = [];
            let optional_flight = [];
            flight_cart.map((flight) => {
                if (flight.booking_status !== undefined && ((flight.booking_status !== null && flight.booking_status.status_booking !== "CANCELLED") || flight.booking_status === null)) {
                    let last_departure_time = flight.outbounds[flight.outbounds.length - 1].legs[0].departure_time;
                    if (position === "bottom") {
                        if (moment.utc(last_departure_time, "YYYY-MM-DD").isSame(moment.utc(end_date, "YYYY-MM-DD"), "day")) {
                            if (flight.is_optional) {
                                optional_flight.push(flight);
                            }
                            else {
                                main_flight.push(flight);
                            }
                        }
                    }
                    else {
                        if (position === "step") {
                            let has_flight = false
                            flight.outbounds.map((outbound) => {
                                if (moment.utc(outbound.legs[0].departure_time, "YYYY-MM-DD").isSame(moment.utc(end_date, "YYYY-MM-DD"), "day")) {
                                    has_flight = true;
                                }
                            });
                            if (has_flight) {
                                if (flight.is_optional) {
                                    optional_flight.push(flight);
                                }
                                else {
                                    main_flight.push(flight);
                                }
                            }
                        }
                        else {
                            if (moment.utc(flight.start_date, "YYYY-MM-DD").isSame(moment.utc(start_date, "YYYY-MM-DD"), "day") && moment.utc(last_departure_time, "YYYY-MM-DD").isSameOrBefore(moment.utc(end_date, "YYYY-MM-DD"), "day")) {
                                if (flight.is_optional) {
                                    optional_flight.push(flight);
                                }
                                else {
                                    main_flight.push(flight);
                                }
                            }
                        }
                    }
                }
            });
            manual_cart.map((manual) => {
                if (manual.product_type === 6) {
                    manual.flight_segment.map((segment) => {
                        if (position === "bottom") {
                            if (moment.utc(segment.start_date, "YYYY-MM-DD").isSame(moment.utc(end_date, "YYYY-MM-DD"), "day")) {
                                if (manual.is_optional) {
                                    optional_flight.push(manual);
                                }
                                else {
                                    main_flight.push(manual);
                                }
                            }
                        }
                        else {
                            if (position === "step") {
                                let has_flight = false
                                if (moment.utc(segment.start_date, "YYYY-MM-DD").isSame(moment.utc(end_date, "YYYY-MM-DD"), "day")) {
                                    has_flight = true;
                                }
                                if (has_flight) {
                                    if (manual.is_optional) {
                                        optional_flight.push(manual);
                                    }
                                    else {
                                        main_flight.push(manual);
                                    }
                                }
                            }
                            else {
                                if (moment.utc(manual.start_date, "YYYY-MM-DD").isSame(moment.utc(start_date, "YYYY-MM-DD"), "day") && moment.utc(manual.end_date, "YYYY-MM-DD").isSameOrBefore(moment.utc(end_date, "YYYY-MM-DD"), "day")) {
                                    if (manual.is_optional) {
                                        optional_flight.push(manual);
                                    }
                                    else {
                                        main_flight.push(manual);
                                    }
                                }
                            }
                        }
                    });
                }
            });
            setTravelFlight(main_flight);
            setOptionalFlight(optional_flight);
        }
    }, [flight_cart, manual_cart]);
    const getClientPrice = (prices) => {
		let current_price = prices.find((el) => {
			return !el.is_commissioned && !el.is_network && !el.is_surcom && !el.master_price;
		});
        if (current_price === undefined) {
            current_price = prices.find((el) => {
                return !el.is_commissioned && !el.is_network && !el.is_surcom && el.master_price;
            });
        }
		return current_price;
	}
    let mainPrice = 0;
    if (travelFlight.length !== 0) {
        travelFlight.map((flight) => {
            mainPrice = parseFloat(getClientPrice(flight.prices).selling_price);
        });
    }
    return (
        <Fragment>
        {
            type === "departure" && (
                <Fragment>
                    {
                        (travelFlight.length !== 0 || optionalFlight.length !== 0) && position === "top" && (
                            <Grid item>
                                <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h4"}>{t("transfers.direction_departure") + " " + (travelFlight.length !== 0 ? travelFlight[0].outbounds[0].legs[0].origin.iata_city.iata_country.name : optionalFlight[0].outbounds[0].legs[0].origin.iata_city.iata_country.name)}</Typography>
                            </Grid>
                        )
                    }
                    {
                        travelFlight.length !== 0 && (
                            <Grid item>
                                <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h6"}>{t("summary.main_flight")}</Typography>
                            </Grid>
                        )
                    }
                    {
                        position === "top" && travelFlight.map((travel_flight, index_travel_flight) => {
                            let outbound_index = null;
                            let last_departure_time = travel_flight.outbounds[travel_flight.outbounds.length - 1].legs[0].departure_time;
                            outbound_index = travel_flight.outbounds.findIndex((outbound) => {
                                return moment.utc(outbound.legs[0].departure_time, "YYYY-MM-DD").isSame(moment.utc(start_date, "YYYY-MM-DD"), "d") && moment.utc(last_departure_time, "YYYY-MM-DD").isSameOrBefore(moment.utc(end_date, "YYYY-MM-DD"), "day");
                            });
                            if (outbound_index !== null && outbound_index !== -1) {
                                if (from !== "tripSummary") {
                                    return (
                                        <Grid item key={index_travel_flight} className={ clsx({[classes.marginLeft18]: position !== "step" && !fullScreen, [classes.marginLeft8]: position !== "step" && fullScreen, [classes.bookingContainer]: selectedProducts.mainFlights[step_index].includes(travel_flight.id), [classes.borderContainer]: from !== "tripSummary", [classes.hoverContainer]: trip_info.status !== "USER_VALID" && optionalFlight.length !== 0 && !selectedProducts.step_flight[step_index].includes(travel_flight.id)}) }>
                                        {
                                            !travel_flight.is_manual && (
                                                <FlightSummary from={from} travelFlight={travelFlight} type={"main"} outbound={travel_flight.outbounds[outbound_index]} index_outbound={outbound_index} flight={travel_flight} hasOption={optionalFlight.length !== 0} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                            )
                                        }
                                        {
                                            travel_flight.is_manual && (
                                                <FlightSummaryManual from={from} travelFlight={travelFlight} type={"main"} outbound={travel_flight.outbounds[outbound_index]} index_outbound={outbound_index} flight={travel_flight} hasOption={optionalFlight.length !== 0} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                            )
                                        }
                                        </Grid>
                                    )
                                } else {
                                    return (
                                        <Grid item key={index_travel_flight} className={ clsx({[classes.marginLeft8]: position !== "step" && fullScreen, [classes.borderContainer]: from !== "tripSummary"}) }>
                                        {
                                            !travel_flight.is_manual && (
                                                <FlightSummary from={from} travelFlight={travelFlight} type={"main"} outbound={travel_flight.outbounds[outbound_index]} index_outbound={outbound_index} flight={travel_flight} hasOption={optionalFlight.length !== 0} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                            )
                                        }
                                        {
                                            travel_flight.is_manual && (
                                                <Manual from={from} travelFlight={travelFlight} type={"main"} outbound={travel_flight.outbounds[outbound_index]} index_outbound={outbound_index} flight={travel_flight} hasOption={optionalFlight.length !== 0} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                            )
                                        }
                                        </Grid>
                                    )
                                }
                            }
                        })
                    }
                    {
                        position === "step" && travelFlight.map((travel_flight, index_travel_flight) => {
                            let outbound_index = null;
                            if (!travel_flight.is_manual) {
                                outbound_index = travel_flight.outbounds.findIndex((outbound, index) => {
                                    return index !== 0 && index !== travel_flight.outbounds.length - 1 && moment.utc(outbound.legs[0].departure_time, "YYYY-MM-DD HH:mm").isSameOrBefore(moment.utc(end_date, "YYYY-MM-DD HH:mm"));
                                });
                            }
                            else {
                                outbound_index = travel_flight.flight_segment.findIndex((outbound, index) => {
                                    return index !== 0 && index !== travel_flight.flight_segment.length - 1 && moment.utc(outbound.start_date, "YYYY-MM-DD HH:mm").isSameOrBefore(moment.utc(end_date, "YYYY-MM-DD HH:mm"));
                                });
                            }
                            if (outbound_index !== null && outbound_index !== -1) {
                                if (from !== "tripSummary") {
                                    return (
                                        <Grid item key={index_travel_flight} className={ clsx({[classes.marginLeft18]: position !== "step" && !fullScreen, [classes.marginLeft8]: position !== "step" && fullScreen, [classes.bookingContainer]: selectedProducts.step_flight[step_index].includes(travel_flight.id), [classes.borderContainer]: from !== "tripSummary", [classes.hoverContainer]: trip_info.status !== "USER_VALID" && optionalFlight.length !== 0 && !selectedProducts.step_flight[step_index].includes(travel_flight.id)}) } style={{marginTop: 10, marginBottom: 10}}>
                                        {
                                            !travel_flight.is_manual && (
                                                <FlightSummary from={from} travelFlight={travelFlight} type={"main"} outbound={travel_flight.outbounds[outbound_index]} index_outbound={outbound_index} flight={travel_flight} hasOption={optionalFlight.length !== 0} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                            )
                                        }
                                        {
                                            travel_flight.is_manual && (
                                                <FlightSummaryManual from={from} travelFlight={travelFlight} type={"main"} outbound={travel_flight.flight_segment[outbound_index]} index_outbound={outbound_index} flight={travel_flight} hasOption={optionalFlight.length !== 0} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                            )
                                        }
                                        </Grid>
                                    )
                                }
                                else {
                                    return (
                                        <Grid item key={index_travel_flight} className={ clsx({[classes.marginLeft8]: position !== "step" && fullScreen, [classes.borderContainer]: from !== "tripSummary"}) } style={{marginTop: 10, marginBottom: 10}}>
                                            {
                                                !travel_flight.is_manual && (
                                                    <FlightSummary from={from} travelFlight={travelFlight} type={"main"} outbound={travel_flight.outbounds[outbound_index]} index_outbound={outbound_index} flight={travel_flight} hasOption={optionalFlight.length !== 0} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                                )
                                            }
                                            {
                                                travel_flight.is_manual && (
                                                    <FlightSummaryManual from={from} travelFlight={travelFlight} type={"main"} outbound={travel_flight.flight_segment[outbound_index]} index_outbound={outbound_index} flight={travel_flight} hasOption={optionalFlight.length !== 0} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                                )
                                            }
                                        </Grid>
                                    )
                                }
                            }
                        })
                    }
                    {
                        optionalFlight.length !== 0 && (
                            <Grid item>
                                <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h6"}>{t("summary.alternated_flight")}</Typography>
                            </Grid>
                        )
                    }
                    {
                        optionalFlight.map((travel_flight, index_travel_flight) => {
                            if (from !== "tripSummary") {
                                return (
                                    <Grid item key={index_travel_flight} className={ clsx({[classes.marginLeft18]: position !== "step" && !fullScreen, [classes.marginLeft8]: position !== "step" && fullScreen, [classes.bookingContainer]: trip_info.status !== "USER_VALID" && position === "step" ? selectedProducts.step_flight[step_index].includes(travel_flight.id) : selectedProducts.mainFlights[step_index].includes(travel_flight.id), [classes.borderContainer]: from !== "tripSummary", [classes.hoverContainer]: trip_info.status !== "USER_VALID" && optionalFlight.length !== 0 && (position === "step" ? !selectedProducts.step_flight[step_index].includes(travel_flight.id) : !selectedProducts.mainFlights[step_index].includes(travel_flight.id))}) } style={{marginTop: 10, marginBottom: 10}}>
                                    {
                                        !travel_flight.is_manual && (
                                            <FlightSummary from={from} travelFlight={travelFlight} type={"optional"} outbound={travel_flight.outbounds[0]} flight={travel_flight} hasOption={true} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} mainPrice={mainPrice}/>
                                        )
                                    }
                                    {
                                        travel_flight.is_manual && (
                                            <FlightSummaryManual from={from} travelFlight={travelFlight} type={"optional"} outbound={travel_flight.flight_segment[0]} flight={travel_flight} hasOption={true} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                        )
                                    }
                                    </Grid>
                                )
                            } else {
                                return (
                                    <Grid item key={index_travel_flight} className={ clsx({[classes.marginLeft8]: position !== "step" && fullScreen, [classes.borderContainer]: from !== "tripSummary"}) } style={{background: "#EEEEEE", marginTop: 10, marginBottom: 10}}>
                                    {
                                        !travel_flight.is_manual && (
                                            <FlightSummary from={from} travelFlight={travelFlight} type={"optional"} outbound={travel_flight.outbounds[0]} flight={travel_flight} hasOption={true} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} mainPrice={mainPrice}/>
                                        )
                                    }
                                    {
                                        travel_flight.is_manual && (
                                            <FlightSummaryManual from={from} travelFlight={travelFlight} type={"optional"} outbound={travel_flight.flight_segment[0]} flight={travel_flight} hasOption={true} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                        )
                                    }
                                    </Grid>
                                )
                            }
                        })
                    }
                    {
                        (travelFlight.length !== 0 || optionalFlight.length !== 0) && position === "top" && (
                            <Grid item>
                                <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h4"}>{t("transfers.direction_arrival") + " " + travelFlight[0].outbounds[0].legs[travelFlight[0].outbounds[0].legs.length - 1].destination.iata_city.iata_country.name}</Typography>
                            </Grid>
                        )
                    }
                </Fragment>
            )
        }
        {
            type === "return" && (
                <Fragment>
                {
                    (travelFlight.length !== 0 || optionalFlight.length !== 0) && (
                        <Grid item>
                            <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h4"}>{t("transfers.direction_departure") + " " + travelFlight[0].outbounds[travelFlight[0].outbounds.length - 1].legs[0].origin.iata_city.iata_country.name}</Typography>
                        </Grid>
                    )
                }
                {
                    travelFlight.length !== 0 && (
                        <Grid item>
                            <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h6"}>{t("summary.main_flight")}</Typography>
                        </Grid>
                    )
                }
                {
                    travelFlight.map((travel_flight, index_travel_flight) => {
                        if (from !== "tripSummary") {
                            return (
                                <Grid item key={index_travel_flight} className={ clsx({[classes.marginLeft18]: !fullScreen, [classes.marginLeft8]: fullScreen, [classes.bookingContainer]: selectedProducts.mainFlights[step_index].includes(travel_flight.id), [classes.borderContainer]: from !== "tripSummary", [classes.hoverContainer]: trip_info.status !== "USER_VALID" && optionalFlight.length !== 0 && !selectedProducts.step_flight[step_index].includes(travel_flight.id)}) } style={{marginTop: 10, marginBottom: 10}}>
                                {
                                    !travel_flight.is_manual && (
                                        <FlightSummary from={from} travelFlight={travelFlight} type={"main"} outbound={travel_flight.outbounds[travel_flight.outbounds.length - 1]} flight={travel_flight} hasOption={optionalFlight.length !== 0} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                    )
                                }
                                {
                                    travel_flight.is_manual && (
                                        <FlightSummaryManual from={from} travelFlight={travelFlight} type={"main"} outbound={travel_flight.flight_segment[travel_flight.flight_segment.length - 1]} flight={travel_flight} hasOption={optionalFlight.length !== 0} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                    )
                                }
                                </Grid>
                            )
                        }
                        else {
                            return (
                                <Grid item key={index_travel_flight} className={ clsx({[classes.marginLeft8]: position !== "step" && fullScreen, [classes.borderContainer]: from !== "tripSummary"}) }  style={{marginTop: 10, marginBottom: 10}}>
                                {
                                    !travel_flight.is_manual && (
                                        <FlightSummary from={from} travelFlight={travelFlight} type={"main"} outbound={travel_flight.outbounds[travel_flight.outbounds.length - 1]} flight={travel_flight} hasOption={optionalFlight.length !== 0} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                    )
                                }
                                {
                                    travel_flight.is_manual && (
                                        <FlightSummaryManual from={from} travelFlight={travelFlight} type={"main"} outbound={travel_flight.flight_segment[travel_flight.flight_segment.length - 1]} flight={travel_flight} hasOption={optionalFlight.length !== 0} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                    )
                                }
                                </Grid>
                            )
                        }
                    })
                }
                {
                    optionalFlight.length !== 0 && (
                        <Grid item>
                            <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h6"}>{t("summary.alternated_flight")}</Typography>
                        </Grid>
                    )
                }
                {
                    optionalFlight.map((travel_flight, index_travel_flight) => {
                        if (from !== "tripSummary") {
                            return (
                                <Grid item key={index_travel_flight} className={ clsx({[classes.marginLeft18]: !fullScreen, [classes.marginLeft8]: fullScreen, [classes.bookingContainer]: trip_info.status !== "USER_VALID" && selectedProducts.mainFlights[step_index].includes(travel_flight.id), [classes.borderContainer]: from !== "tripSummary", [classes.hoverContainer]: trip_info.status !== "USER_VALID" && optionalFlight.length !== 0 && !selectedProducts.step_flight[step_index].includes(travel_flight.id)}) } style={{marginTop: 10, marginBottom: 10}}>
                                {
                                    !travel_flight.is_manual && (
                                        <FlightSummary from={from} travelFlight={travelFlight} type={"optional"} outbound={travel_flight.outbounds[travel_flight.outbounds.length - 1]} flight={travel_flight} hasOption={true} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} mainPrice={mainPrice}/>
                                    )
                                }
                                {
                                    travel_flight.is_manual && (
                                        <FlightSummaryManual from={from} travelFlight={travelFlight} type={"optional"} outbound={travel_flight.flight_segment[travel_flight.flight_segment.length - 1]} flight={travel_flight} hasOption={true} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                    )
                                }
                                </Grid>
                            )
                        } else {
                            return (
                                <Grid item key={index_travel_flight} className={ clsx({[classes.marginLeft8]: position !== "step" && fullScreen, [classes.borderContainer]: from !== "tripSummary"}) } style={{background: "#EEEEEE", marginTop: 10, marginBottom: 10}}>
                                {
                                    !travel_flight.is_manual && (
                                        <FlightSummary from={from} travelFlight={travelFlight} type={"optional"} outbound={travel_flight.outbounds[travel_flight.outbounds.length - 1]} flight={travel_flight} hasOption={true} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index} mainPrice={mainPrice}/>
                                    )
                                }
                                {
                                    travel_flight.is_manual && (
                                        <FlightSummaryManual from={from} travelFlight={travelFlight} type={"optional"} outbound={travel_flight.flight_segment[travel_flight.flight_segment.length - 1]} flight={travel_flight} hasOption={true} position={position} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} step_index={step_index}/>
                                    )
                                }
                                </Grid>
                            )
                        }
                    })
                }
                {
                    (travelFlight.length !== 0 || optionalFlight.length !== 0) && (
                        <Grid item>
                            <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h4"}>{t("transfers.direction_arrival") + " " + (travelFlight.length !== 0 ? travelFlight[0].outbounds[travelFlight[0].outbounds.length - 1].legs[travelFlight[0].outbounds[travelFlight[0].outbounds.length - 1].legs.length - 1].destination !== null ? travelFlight[0].outbounds[travelFlight[0].outbounds.length - 1].legs[travelFlight[0].outbounds[travelFlight[0].outbounds.length - 1].legs.length - 1].destination.iata_city.iata_country.name : travelFlight[0].outbounds[travelFlight[0].outbounds.length - 1].legs[travelFlight[0].outbounds[travelFlight[0].outbounds.length - 1].legs.length - 1].destination_station.iata_city.iata_country.name : optionalFlight[0].outbounds[optionalFlight[0].outbounds.length - 1].legs[optionalFlight[0].outbounds[optionalFlight[0].outbounds.length - 1].legs.length - 1].destination !== null ? optionalFlight[0].outbounds[optionalFlight[0].outbounds.length - 1].legs[optionalFlight[0].outbounds[optionalFlight[0].outbounds.length - 1].legs.length - 1].destination.iata_city.iata_country.name : optionalFlight[0].outbounds[optionalFlight[0].outbounds.length - 1].legs[optionalFlight[0].outbounds[optionalFlight[0].outbounds.length - 1].legs.length - 1].destination_station.iata_city.iata_country.name)}</Typography>
                        </Grid>
                    )
                }
                </Fragment>
            )
        }
        </Fragment>
    )
}
export default RenderSummaryFlight;