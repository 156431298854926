//---- Dependencies ----//
import React from 'react';
import { connect } from "react-redux";
import { Link, History } from "react-router";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import ReactDOM from "react-dom";

//---- Functions ----//

//---- Components ----//
import SummaryStepDestination from "./SummaryStepDestination.jsx";
import SummaryStepHotel from "./SummaryStepHotel.jsx";
import SummaryStepManual from "./SummaryStepManual.jsx";
import SummaryCustomProducts from "./SummaryCustomProducts.jsx";
import SummaryStepTransport from "./SummaryStepTransport.jsx";
import SaveNewCart from "./Functions/SaveNewCart.js";
import {SetCurrentStep} from "../../../Actions/Summary";

//---- Actions ----//

//---- CSS ----//
import "../../../Style/Summary.css";

//---- Class ----//
@connect((store) => {
    return {
        itinerary: store.summary.itinerary,
        cars_cart: store.summary.cars_cart,
        flight_cart: store.summary.flight_cart,
        poi_cart: store.summary.poi_cart,
        manual_cart: store.summary.manual_cart,
        hotel_cart: store.summary.hotel_cart,
        current_step: store.summary.current_step,
        trip_info: store.summary.trip_info
    };
})
@withTranslation()
@withRouter
class SummaryStep extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {  
            has_more_details: false,
            more_details: null,
            type_details: null,
            tmp_transport_arr: null, 
            save_bar: false
        };
        this.content_ref = [];
        this.checkCurrentContent = this.checkCurrentContent.bind(this);
        this.addContentRef = this.addContentRef.bind(this);
    }
    setAllStepInfo() {
        const { itinerary, manual_cart, flight_cart, cars_cart, trip_info } = this.props;
        let tmp_transport_arr = [];
        itinerary.map((step, index_step) => {
            let no_double_flight = [];
            let tmp_obj = {
                id: step.id,
                has_more_details: false,
                more_details: null,
                type_details: null,
                segment: null,
                segment_index: 0
            };
            if (step.r2r_json.vehicle !== undefined) {
                if (step.r2r_json.vehicle.kind === "car" || step.r2r_json.vehicle.kind === "plane") {
                    if (step.r2r_json.vehicle.kind === "car") {
                        cars_cart.map((car) => {
                            if ((moment.utc(car.start_date).isAfter(moment.utc(step.start_date)) || moment.utc(car.start_date).isSame(moment.utc(step.start_date), "d")) && (moment.utc(car.end_date).isBefore(moment.utc(step.end_date)) || moment.utc(car.end_date).isSame(moment.utc(step.end_date), "d"))) {
                                if (car.is_displayed === true) {
                                    tmp_obj.type_details = "CAR";
                                    tmp_obj.more_details = car;
                                    tmp_obj.has_more_details = true;
                                }
                            } else if ((moment.utc(step.start_date).isAfter(moment.utc(car.start_date)) || moment.utc(step.start_date).isSame(moment.utc(car.start_date), "d")) && (moment.utc(step.end_date).isBefore(moment.utc(car.end_date)) || moment.utc(step.end_date).isSame(moment.utc(car.end_date), "d"))) {
                                if (car.is_displayed === true) {
                                   tmp_obj.type_details = "CAR";
                                   tmp_obj.more_details = car;
                                   tmp_obj.has_more_details = true;
                                } 
                            }
                            if (tmp_obj.has_more_details === true) {
                                tmp_transport_arr.push(tmp_obj);
                                tmp_obj = {
                                    id: step.id,
                                    has_more_details: false,
                                    more_details: null,
                                    type_details: null,
                                    segment: null,
                                    segment_index: 0
                                };
                            }
                        });
                    }
                    if (step.r2r_json.vehicle.kind === "plane") {
                        flight_cart.map((flight, flight_index) => {
                            for (let i = 0; i < flight.outbounds.length; i++) {
                                for (let j = 0; j < flight.outbounds[i].legs.length; j++) {
                                    let legs = flight.outbounds[i].legs;
                                    if (index_step === 0) {                                        
                                        if ((moment.utc(legs[0].departure_time).isAfter(moment.utc(itinerary[index_step].start_date)) || moment.utc(legs[0].departure_time).isSame(moment.utc(itinerary[index_step].start_date), "d"))
                                            && (moment.utc(legs[legs.length - 1].arrival_time).isBefore(moment.utc(itinerary[index_step + 1].end_date)) || moment.utc(legs[legs.length - 1].arrival_time).isSame(moment.utc(itinerary[index_step + 1].end_date), "d"))) {
                                            if (flight.is_displayed === true) {
                                                tmp_obj.type_details = "FLIGHT";
                                                tmp_obj.more_details = flight;
                                                tmp_obj.has_more_details = true;
                                                tmp_obj.segment = flight.outbounds[i];
                                                tmp_obj.segment_index = i;
                                            }
                                        } else if ((moment.utc(itinerary[index_step].start_date).isAfter(moment.utc(legs[0].departure_time)) || moment.utc(itinerary[index_step].start_date).isSame(moment.utc(legs[0].departure_time), "d"))
                                            && (moment.utc(itinerary[index_step + 1].end_date).isBefore(moment.utc(legs[legs.length - 1].arrival_time)) || moment.utc(itinerary[index_step + 1].end_date).isSame(moment.utc(legs[legs.length - 1].arrival_time), "d"))) {
                                            if (flight.is_displayed === true) {
                                                tmp_obj.type_details = "FLIGHT";
                                                tmp_obj.more_details = flight;
                                                tmp_obj.has_more_details = true;
                                                tmp_obj.segment = flight.outbounds[i];
                                                tmp_obj.segment_index = i;
                                            } 
                                        }  
                                    } else if (index_step === itinerary.length - 2) {
                                        if ((moment.utc(legs[0].departure_time).isAfter(moment.utc(itinerary[itinerary.length - 1].end_date)) || moment.utc(legs[0].departure_time).isSame(moment.utc(itinerary[itinerary.length - 1].end_date), "d"))
                                            && (moment.utc(legs[legs.length - 1].arrival_time).isBefore(moment.utc(trip_info.end_date)) || moment.utc(legs[legs.length - 1].arrival_time).isSame(moment.utc(trip_info.end_date), "d"))) {
                                            if (flight.is_displayed === true) {
                                                tmp_obj.type_details = "FLIGHT";
                                                tmp_obj.more_details = flight;
                                                tmp_obj.has_more_details = true;
                                                tmp_obj.segment = flight.outbounds[i];
                                                tmp_obj.segment_index = i;
                                            }
                                        } else if ((moment.utc(itinerary[itinerary.length - 1].end_date).isAfter(moment.utc(legs[0].departure_time)) || moment.utc(itinerary[itinerary.length - 1].end_date).isSame(moment.utc(legs[0].departure_time), "d"))
                                            && (moment.utc(trip_info.end_date).isBefore(moment.utc(legs[legs.length - 1].arrival_time)) || moment.utc(trip_info.end_date).isSame(moment.utc(legs[legs.length - 1].arrival_time), "d"))) {
                                            if (flight.is_displayed === true) {
                                                tmp_obj.type_details = "FLIGHT";
                                                tmp_obj.more_details = flight;
                                                tmp_obj.has_more_details = true;
                                                tmp_obj.segment = flight.outbounds[i];
                                                tmp_obj.segment_index = i;
                                            } 
                                        }  
                                    } else {
                                        if (itinerary[index_step + 1] !== undefined) {
                                            if ((moment.utc(legs[0].departure_time).isAfter(moment.utc(itinerary[index_step].start_date)) || moment.utc(legs[0].departure_time).isSame(moment.utc(itinerary[index_step].start_date), "d"))
                                                && (moment.utc(legs[legs.length - 1].arrival_time).isBefore(moment.utc(itinerary[index_step + 1].end_date)) || moment.utc(legs[legs.length - 1].arrival_time).isSame(moment.utc(itinerary[index_step + 1].end_date), "d"))) {
                                                if (flight.is_displayed === true) {
                                                    tmp_obj.type_details = "FLIGHT";
                                                    tmp_obj.more_details = flight;
                                                    tmp_obj.has_more_details = true;
                                                    tmp_obj.segment = flight.outbounds[i];
                                                    tmp_obj.segment_index = i;
                                                }
                                            } else if ((moment.utc(itinerary[index_step].start_date).isAfter(moment.utc(legs[0].departure_time)) || moment.utc(itinerary[index_step].start_date).isSame(moment.utc(legs[0].departure_time), "d"))
                                                && (moment.utc(itinerary[index_step + 1].end_date).isBefore(moment.utc(legs[legs.length - 1].arrival_time)) || moment.utc(itinerary[index_step + 1].end_date).isSame(moment.utc(legs[legs.length - 1].arrival_time), "d"))) {
                                                if (flight.is_displayed === true) {
                                                    tmp_obj.type_details = "FLIGHT";
                                                    tmp_obj.more_details = flight;
                                                    tmp_obj.has_more_details = true;
                                                    tmp_obj.segment = flight.outbounds[i];
                                                    tmp_obj.segment_index = i;
                                                } 
                                            }                                            
                                        }
                                    }
                                    
                                    if (tmp_obj.has_more_details === true) {
                                        let is_in = false;
                                        for (let t = 0; t < no_double_flight.length; t++) {
                                            if (no_double_flight[t] === flight.id + flight_index) {
                                                is_in = true;
                                            }
                                        }
                                        if (is_in === false) {
                                            tmp_transport_arr.push(tmp_obj);
                                            no_double_flight.push(flight.id + flight_index);                                            
                                        }
                                        tmp_obj = {
                                            id: step.id,
                                            has_more_details: false,
                                            more_details: null,
                                            type_details: null,
                                            segment: null,
                                            segment_index: 0
                                        };
                                    }
                                }
                            }
                        });
                    }
                    // TODO : Also check manual
                }
            }
        });
        this.setState({
            tmp_transport_arr: tmp_transport_arr
        });

    }
    checkCurrentContent() {
        const { dispatch, current_step } = this.props;
        for (let i = 0; i < this.content_ref.length; i++) {
            if (this.content_ref[i] !== null && this.content_ref[i].getBoundingClientRect().top > 100) {
                if (i === 0) {
                    break;
                } else {
                    let prev_item = i - 1;
                    if (prev_item !== current_step) {
                        dispatch(SetCurrentStep(prev_item));
                    }
                }
                break;
            }
            if (i === this.content_ref.length - 1) {
                dispatch(SetCurrentStep(i));
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {             
        const { itinerary } = this.props;

        if (itinerary !== prevProps.itinerary) { 
            this.setAllStepInfo();
        }
    }

    componentDidMount() {
        let current_component = this;
        let isScrolling = false;
        this.setAllStepInfo();
        window.addEventListener('scroll', function ( event ) {
            current_component.checkCurrentContent();
            /*
            window.clearTimeout( isScrolling );
            isScrolling = setTimeout(function() {
                current_component.checkCurrentContent();
            }, 400);

             */
        }, false);
    }
    addContentRef(ref) {
        this.content_ref.push(ref);
    }
    render() {
        const { itinerary, t, hotel_cart, poi_cart, flight_cart, cars_cart, dispatch } = this.props;
        return (
            <div className={"ft-mt-58 remove-margin-on-mobile"}>
                {
                    itinerary.map((step, index_step) => {
                        
                        let transport_arr = [];
                        if (this.state.tmp_transport_arr !== null) {
                            for (let i = 0; i < this.state.tmp_transport_arr.length; i++) {
                                if (this.state.tmp_transport_arr[i].id === step.id) {
                                    transport_arr.push(this.state.tmp_transport_arr[i]);                                    
                                }
                            }
                        }
                        console.log('transport_arr:', transport_arr);

                        if (step.step_type === "STEP") {
                            return (
                                <div ref={ this.addContentRef } id={"container-step"} key={index_step}>
                                    <SummaryStepDestination step={step} nb_step={index_step} />
                                    <SummaryStepHotel step={step} index={index_step} component={this} />
                                    <SummaryCustomProducts step={step} is_from_itiType={false} component={this} />
                                    <SummaryStepManual step={step} component={this} />
                                    {
                                        transport_arr.map((transport, transport_index) => {
                                            let do_not_show = false;
                                            if (step.r2r_json === null || step.r2r_json.vehicle === undefined) {
                                                do_not_show = true;
                                            }
                                            if (transport.has_more_details === false) {
                                                do_not_show = true;
                                            }
                                            if (do_not_show === false) {
                                                return (
                                                    <SummaryStepTransport key={transport_index} transport={transport} step={step} component={this}/>
                                                )                                                
                                            }
                                        })
                                    }
                                </div>
                            );
                        } else {
                            return (
                                <div id={"container-step"} key={index_step}>
                                    <SummaryStepDestination step={step} nb_step={index_step} />
                                    <SummaryStepHotel step={step} index={index_step} component={this} />
                                    <SummaryStepManual step={step} component={this} />
                                    {
                                        transport_arr.map((transport, transport_index) => {
                                            let do_not_show = false;
                                            if (step.r2r_json === null || step.r2r_json.vehicle === undefined) {
                                                do_not_show = true;
                                            }
                                            if (transport.has_more_details === false) {
                                                do_not_show = true;
                                            }
                                            if (do_not_show === false) {
                                                return (
                                                    <SummaryStepTransport key={transport_index} transport={transport} step={step} component={this}/>
                                                )                                                
                                            }
                                        })
                                    }
                                </div>
                            );
                        }
                    })
                }

                {
                    (this.state.save_bar === true) && (
                        <div className={"wrapper-save-bar"} >
                            <div className={"save-bar block-center text-center"}>
                                <button className={"btn-orange save-bar-btn"} onClick={() => { SaveNewCart(cars_cart, hotel_cart, flight_cart, poi_cart, dispatch, this.props.params.trip_id, this.props.params.trip_id_version, this) }} 
                                > {t("global.save_modification")} </button>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

export default SummaryStep;
