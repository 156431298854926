//---- Dependencies ----//
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

//---- Class ----//
@withTranslation()
class MoreInfoPoi extends Component {
    constructor(props, context) {
        super(props, context);
    }
    render() {
        const { long_description, open, component, t } = this.props;
        return (
            <Modal id={ "modal-detail-booking" } isOpen={ open } toggle={() => { component.setState({open: !open}); }} size={ "lg" }>
                <div>
                    <ModalHeader toggle={() => { component.setState({open: !open}); }}>
                        <div id={ "modal-connection-header" }>
                            <h1 className={ "title-connection" }>{ t("poi.full_description") }</h1>
                        </div>
                    </ModalHeader>
                    <ModalBody className={"grey-bg ft-gray"}>
                        <div dangerouslySetInnerHTML={ {__html: long_description} }/>
                    </ModalBody>
                </div>
            </Modal>
        );
    }
}

export default MoreInfoPoi;
