import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import CustomTimePicker from "./CustomTimePicker";

import GetIsoCode from "../Functions/GetIsoCode";
import GetDateFormat from "../Functions/GetDateFormat";

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: "bold"
    },
    spacer: {
        padding: "8px 0px" 
    },
    timeBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        borderRadius: 8,
        boxSizing: "border-box",
        padding: 8
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    }
}))

const AvailabilityCard = ({ day_prices, selectActivity, poi_type }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const language = useSelector(state => state.header.tmp_language);
    const currency = useSelector(state => state.trip.currency);
    const currency_list = useSelector(state => state.base.currency_list);

    const [localCustomTime, setLocalCustomTime] = useState("00:00");

    let symbol = null;
    currency_list.map((currency_obj) => {
        if(currency_obj.id === day_prices.total_price[0].selling_currency){
            symbol = currency_obj.symbol;
        }
    })
    return(
        <Grid className={classes.spacer}>
            <Typography variant='body2' className={classes.bold} style={{ paddingBottom: 4 }}> {day_prices.date.length ===   10 ? GetDateFormat(day_prices.date) : day_prices.date} </Typography>
            <Grid className={classes.timeBorder}>
                <Grid container alignItems="center" justify="space-between">
                    <Grid item>
                        {
                            // display custom time if day_prices is empty or have two hours (bounds)
                            day_prices.hour.length === 1 && (
                                <Grid item>
                                    <Typography variant="body2"> {language === "fr" ? day_prices.hour[0] : day_prices.hour[0].replace("h", ":")} </Typography>
                                </Grid>
                            )
                        }
                        {
                            day_prices.hour.length > 1 && day_prices.areBounds === false && (
                                <CustomTimePicker  value={localCustomTime} setValue={setLocalCustomTime} valueChoices={day_prices.hour} step={null} minValue={null} maxValue={null} />
                            )
                        }
                    </Grid>
                    <Grid item>
                        <Button variant="contained" className={ classes.orangeButton } size='small' onClick={() => {
                            selectActivity(day_prices, day_prices.hour.length > 1 && day_prices.areBounds === false ? localCustomTime : null)}}>
                            { t("poi.select") }
                        </Button>
                    </Grid>
                </Grid>
                {
                    day_prices.catalog_price && (
                        <Typography className={`${classes.bold} ${classes.spacer}`} variant='body2'> { t("poi.price_on_demand") } </Typography>
                    )
                }
                {
                    !day_prices.catalog_price && !poi_type && (
                        <Typography className={`${classes.bold} ${classes.spacer}`} variant='body2'> {new Intl.NumberFormat(language, { style: 'currency', currency: GetIsoCode(currency_list, currency, day_prices.total_price[0].selling_currency) }).format(parseFloat(day_prices.total_price[0].selling_price))} </Typography>
                    )
                }
            </Grid>
        </Grid>
    )
}

export default React.memo(AvailabilityCard)