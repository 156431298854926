//---- Dependencies ----//
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import Notify from "../../../Common/Notify";

//---- Actions ----//

export default (travelers_arr, t, trip_token, status) => {
	let { pass_check, headers } = CheckBeforeRequest();
	if (pass_check) {
		let request;
		for (let i = 0; i < travelers_arr.length; i++) {
			request = {
				birth_date: travelers_arr[i].birth_date !== null ? moment.utc(travelers_arr[i].birth_date).format("YYYY-MM-DD") : null,
				first_name: travelers_arr[i].first_name,
				last_name: travelers_arr[i].last_name,
				title: travelers_arr[i].title,
				gender: travelers_arr[i].gender !== undefined ? travelers_arr[i].gender : "M",
				address: travelers_arr[i].address,
				country: travelers_arr[i].country !== null ? travelers_arr[i].country.id : null,
				city: travelers_arr[i].city,
				postal_code: travelers_arr[i].postal_code,				
				phone_number: travelers_arr[i].phone_number,
				email: travelers_arr[i].email		
			};
			$.ajax({
	            method: "PATCH",
	            headers: headers,
	            url: `${API_HREF}client/${window.id_owner}/travelers/${travelers_arr[i].id}/?token=${trip_token}`,
	            data: JSON.stringify(request),
	            success: function (data) {
	            	if ((i + 1) === travelers_arr.length) {
	            		if (status === "ONREQUEST" || status === "ONGOING") {
	            			Notify(t("summary.traveler_status_update"), {status: "success", pos: "top-center"});
	            		} else {	            		
	            			Notify(t("summary.travelers_success_update"), {status: "success", pos: "top-center"});
	            		}
	            	}
	            },
	            error: function (error) {
	                console.log("err", error);/*
                	let text = t("booking.error_traveler") + " " + (i + 1) + " : " + error.responseJSON.detail;
	          		Notify(text, {status: "danger", pos: "top-center"});*/
	            }
	        });
		}
	}
};