//---- Commons ----//
import Notify from "../../Common/Notify";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import Rome2Rio from "../../Common/Rome2Rio";
import GetCookie from "../../Common/Functions/GetCookie";

//---- Actions ----//
import { SetItinerary } from "../../../Actions/Itinerary";
import { LockItineraryActions, UnlockItineraryActions } from "../../../Actions/Base";

export default (destination, itinerary_list, start_date, end_date, currency, departure_destination, return_destination, dispatch, t, user, resetAddRunning) => {
    let destination_list = [];
    for (let i = 0; i < itinerary_list.length; i++) {
        if (itinerary_list[i].step_type === "STEP") {
            destination_list.push(itinerary_list[i]);
        }
    }
    if (start_date === null) {
        Notify(t("itinerary.add_start_date_first"), { status: "danger", pos: "top-center"});
        resetAddRunning();
    } else if (end_date === null) {
        Notify(t("itinerary.add_end_date_first"), { status: "danger", pos: "top-center"});
        resetAddRunning();
    } else {
        let moment_start_date = moment.utc(start_date);
        let moment_end_date = moment.utc(end_date);
        let total_days = moment_end_date.diff(moment_start_date, "d");
        let booked_days = 0;
        if (destination_list !== null && destination_list.length > 0) {
            booked_days = moment.utc(destination_list[destination_list.length - 1].end_date).diff(moment_start_date, "d");
        }
        let remaining_days = parseInt(total_days - booked_days);
        let suggested_stay = parseInt((destination.data.suggested_hours / 24).toFixed(0));
        if (suggested_stay === 0) {
            suggested_stay = 1;
        }
        if (remaining_days < suggested_stay || (destination_list.length > 0 && destination_list[destination_list.length - 1].duration_transport_after !== undefined && remaining_days === suggested_stay && destination_list[destination_list.length - 1].duration_transport_after > 240)) {
            Notify(t("itinerary.time_reduced"), { status: "warning", pos: "top-center"});
            suggested_stay = remaining_days;
        }
        /*
        let remaining_days = parseInt(total_days - booked_days);
        let suggested_stay = parseInt((destination.data.suggested_hours / 24).toFixed(0));
        if (suggested_stay === 0) {
            suggested_stay = 1;
        }
        if (remaining_days === 0) {
            Notify(t("itinerary.no_days_left"), { status: "danger", pos: "top-center"});
        } else if (remaining_days < suggested_stay || (destination_list.length > 0 && destination_list[destination_list.length - 1].duration_transport_after !== undefined && remaining_days === suggested_stay && destination_list[destination_list.length - 1].duration_transport_after > 240)) {
            Notify(t("itinerary.time_reduced"), { status: "warning", pos: "top-center"});
            suggested_stay = remaining_days - 1;
        }
        if (remaining_days > 0) {
         */
            let juxtaposition = false;
            let arrival_date = null;
            let departure_date = null;
            if (destination_list.length === 0) {
                if (departure_destination.place_id !== null && departure_destination.end_date !== null) {
                    arrival_date = moment.utc(departure_destination.end_date).format();
                } else {
                    arrival_date = moment_start_date.add(9, "h").format();
                }
                departure_date = moment.utc(arrival_date).add(suggested_stay, "d").format();
            } else {
                if (destination_list[destination_list.length - 1].destination.id === destination.id) {
                    juxtaposition = true;
                    departure_date = moment.utc(destination_list[destination_list.length - 1].end_date).add(suggested_stay, "d").format();
                } else {
                    arrival_date = moment.utc(destination_list[destination_list.length - 1].end_date).format();
                    departure_date = moment.utc(destination_list[destination_list.length - 1].end_date).add(suggested_stay, "d").format();
                    if (destination_list[destination_list.length - 1].r2r_json !== undefined && destination_list[destination_list.length - 1].r2r_json !== null && destination_list[destination_list.length - 1].r2r_json.duration !== undefined && moment.utc(destination_list[destination_list.length - 1].end_date).add(suggested_stay, "d").add(destination_list[destination_list.length - 1].r2r_json.duration, 'minutes').add(3, "hours").isAfter(moment_end_date)) {
                        departure_date = moment.utc(destination_list[destination_list.length - 1].end_date).add(suggested_stay - 1, "d").format();
                    }
                    if (moment.utc(arrival_date).isAfter(moment.utc(departure_date))) {
                        departure_date = moment.utc(arrival_date).add(10, "minutes").format();
                    }
                    if (moment.utc(departure_date).isAfter(moment_end_date)) {
                        departure_date = moment_end_date.subtract(10, "minutes").format();
                    }
                }
            }
            if (juxtaposition) {
                let { pass_check, headers } = CheckBeforeRequest();
                if (pass_check) {
                    let request = {
                        end_date: departure_date
                    };
                    dispatch(LockItineraryActions());
                    $.ajax({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/${destination_list[destination_list.length - 1].id}/?token=${GetCookie("trip_token")}&shift_mode=true`,
                        headers: headers,
                        data: JSON.stringify(request),
                        success: function (data) {
                            console.log(data);
                            dispatch(UnlockItineraryActions());
                            dispatch(SetItinerary(data));
                            Notify(t("itinerary.add_juxtaposition"), { status: "warning", pos: "top-center"});
                            resetAddRunning();
                        },
                        error: function () {
                            dispatch(UnlockItineraryActions());
                            resetAddRunning();
                        }
                    });
                }
            } else {
                let { pass_check, headers } = CheckBeforeRequest();
                if (pass_check) {
                    let request = {
                        destination: destination.id,
                        start_date: arrival_date,
                        end_date: departure_date,
                        step_type: "STEP"
                    };
                    dispatch(LockItineraryActions());
                    $.ajax({
                        method: "POST",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/?token=${GetCookie("trip_token")}&shift_mode=true`,
                        headers: headers,
                        data: JSON.stringify(request),
                        success: function (data) {
                            console.log(data);
                            dispatch(SetItinerary(data));
                            let index = data.length - 1;
                            if (return_destination.place_id !== null) {
                                index = data.length - 2;
                            }
                            if (departure_destination.place_id !== null && data[index - 1] !== undefined && data[index - 1].step_type !== undefined && data[index - 1].step_type === "START") {
                                let departure_arrival = "&oPos=" + departure_destination.lat.toString() + "," + departure_destination.lng.toString();
                                let arrival_arrival = "&dPos=" + data[1].destination.data.latitude.toString() + "," + data[1].destination.data.longitude.toString();
                                Rome2Rio(departure_arrival, arrival_arrival, 0, currency, dispatch, data, t, (return_destination.place_id !== null ? 0 : 2), start_date, end_date, user);
                            } else if (index > 0 && !!data[index - 1].destination.data && !!data[index].destination.data) {
                                let departure_arrival = "&oPos=" + data[index - 1].destination.data.latitude.toString() + "," + data[index - 1].destination.data.longitude.toString();
                                let arrival_arrival = "&dPos=" + data[index].destination.data.latitude.toString() + "," + data[index].destination.data.longitude.toString();
                                Rome2Rio(departure_arrival, arrival_arrival, index - 1, currency, dispatch, data, t, (return_destination.place_id !== null ? 0 : 2), start_date, end_date, user);
                            }
                            if (return_destination.place_id !== null && data[data.length - 2] !== undefined) {
                                let departure_departure = "&oPos=" + data[data.length - 2].destination.data.latitude.toString() + "," + data[data.length - 2].destination.data.longitude.toString();
                                let arrival_departure = "&dPos=" + return_destination.lat.toString() + "," + return_destination.lng.toString();
                                Rome2Rio(departure_departure, arrival_departure, data.length - 2, currency, dispatch, data, t, (departure_destination.place_id !== null && data[index - 1].step_type === "START" || index > 0 ? 1 : 2), start_date, end_date, user);
                            }
                            if (departure_destination.place_id === null && return_destination.place_id === null) {
                                dispatch(UnlockItineraryActions());
                            }
                            resetAddRunning();
                        },
                        error: function (data) {
                            console.log(data);
                            Object.keys(data.responseJSON).forEach(key => {
                                Notify(data.responseJSON[key], { status: "danger", pos: "top-center"});
                            });
                            resetAddRunning();
                        }
                    });
                }
            }
            /*
        }
             */
    }
}