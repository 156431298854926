import axios from 'axios';
import React, {Fragment, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// ---- Core ---- //
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { data } from 'jquery';
import i18n from '../../i18n';
import { DialogActions } from '@material-ui/core';
// ---- Common ---- //
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import Axios from 'axios';
import GetCookie from '../Common/Functions/GetCookie';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
    hr: {
        margin: '10px 0 0 0'
    },
    bold: {
        fontWeight: 'bold'
    }
});

const CartNewPriceModal = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const see_new_price = useSelector(state => state.cart.see_new_price);
    const new_price_data = useSelector(state => state.cart.new_price_data);
    const currency_list = useSelector(state => state.base.currency_list);

    const [currency, setCurrency] = useState(null);

    const patchProduct = (accept) => {
        let { pass_check, headers } = CheckBeforeRequest();
        let product = new_price_data.product;
        let copy_price = {...new_price_data.old_price};
        let new_price = [{
            purchase_price: new_price_data.new_price,
            purchase_currency: copy_price.purchase_currency !== null ? copy_price.purchase_currency === 47 ? 47 : copy_price.purchase_currency.id : null,
            custom_rate: copy_price.custom_rate,
            custom_rate_type: copy_price.custom_rate_type,
            custom_value: copy_price.custom_value,
            id: copy_price.id
        }];
        Axios({
            method: "PATCH",
            headers: headers,
            url: `${new_price_data.url}`,
            data: {
                prices: accept ? new_price : undefined,
                provider_wanted_price: null,
            }
        }).then(function (response) {
            // if (accept) {
            //     Axios({
            //         method: 'POST',
            //         headers: headers,
            //         url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/status-manager/${response.data.id}/status/`,
            //         data: {
            //             status_booking: 'CONFIRMED',
            //             item_reference: response.data.booking_status.item_reference
            //         }
            //     }).then((status_response) => {
            //         dispatch({type: new_price_data.custom_dispatch, payload: status_response.data});
            //         enqueueSnackbar(accept ? t("menu.trip_list.new_price_accepted") : t("menu.trip_list.new_price_refused"), {
            //             variant: "success",
            //         });
            //         closeNewPriceModal();
            //     }).catch((error) => {{
            //         console.log('error status:', error.response.data);
            //     }})
            // }
            // else {
                dispatch({type: new_price_data.custom_dispatch, payload: response.data})
                enqueueSnackbar(accept ? t("menu.trip_list.new_price_accepted") : t("menu.trip_list.new_price_refused"), {
                    variant: "success",
                });
                closeNewPriceModal();
            // }
        }).catch(function (error) {
            console.log('err:', error);
            closeNewPriceModal();
        });
    }
    const closeNewPriceModal = () => {
        setCurrency(null);
        dispatch({
            type: 'CART_SEE_NEW_PRICE_MODAL',
            payload: {
                see_new_price: false,
                data: null
            }
        });
    };
    const acceptChange = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        let request = {
            "id": new_price_data.product.id,
            "ref": new_price_data.product.booking_status.item_reference,
            "details": new_price_data.product.booking_status.details,
            "status": new_price_data.product.booking_status.status_booking,
            "price": new_price_data.product.provider_wanted_price
        };
        Axios({
            method: "POST",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/send_accepted_price/`,
            data: JSON.stringify(request),
        }).then(function (response) {
            patchProduct(true);
        }).catch(function (error) {
            console.log('err:', error);
            enqueueSnackbar(t("provider_website.error_change_status"), {
                variant: "error",
            });
            closeNewPriceModal();
        });
    };
    const refuseChange = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            console.log('new_price_data:', new_price_data);
            let request = {
                "id": new_price_data.product.id,
                "ref": new_price_data.product.booking_status.item_reference,
                "details": new_price_data.product.booking_status.details,
                "status": new_price_data.product.booking_status.status_booking,
                "price": new_price_data.product.provider_wanted_price
            };
            Axios({
                method: "POST",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/send_refused_price/`,
                data: JSON.stringify(request),
            }).then(function (response) {
                // enqueueSnackbar(t("menu.trip_list.new_price_refused"), {
                //     variant: "success",
                // });
                patchProduct(false);
            }).catch(function (error) {
                console.log('err:', error);
                enqueueSnackbar(t("provider_website.error_change_status"), {
                    variant: "error",
                });
            });
            console.log('refuse change:', request);
        }
    };
    useEffect(() => {
        if (new_price_data !== null) {
            setCurrency(currency_list.find((tmp) => tmp.id === new_price_data.old_price.purchase_currency));
        }
    }, [new_price_data]);
    return (
        <Dialog fullScreen={ fullScreen } PaperProps={{style: { borderRadius: fullScreen ? 0 : 16 } }} fullWidth maxWidth={ 'md' } open={see_new_price} onClose={closeNewPriceModal}>
            <DialogTitle onClose={ closeNewPriceModal }>
                <Grid container justify={ 'space-between' } alignItems={ 'center' }>
                    {
                        fullScreen ? (
                            <Grid item>
                                <IconButton edge={ 'start' } onClick={ closeNewPriceModal }><NavigateBefore/></IconButton> { t('provider_website.new_product_price') }
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item>
                                    { t('provider_website.new_product_price') } :
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={ closeNewPriceModal }><Close/></IconButton>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
                <hr className={ classes.hr }/>
            </DialogTitle>
            <DialogContent>
            {
                new_price_data !== null && currency !== null && (
                    <Grid container direction={'column'} spacing={ 2 }>
                        <Grid item>
                            <span className={classes.bold}>{ t('provider_website.old_purchase_price') } : </span> {`${Intl.NumberFormat(i18n.language, { style: "currency", currency: currency.iso_code }).format(Math.round(parseFloat(new_price_data.old_price.purchase_price)))}`}
                        </Grid>
                        <Grid item>
                            <span className={classes.bold}>{ t('provider_website.new_product_price') } : </span> {`${Intl.NumberFormat(i18n.language, { style: "currency", currency: currency.iso_code }).format(Math.round(parseFloat(new_price_data.new_price)))}`}
                        </Grid>
                    </Grid>
                )
            }
            </DialogContent>
            <DialogActions>
                <Button onClick={refuseChange}>{t('cart-material.deny-price-change')}</Button>
                <Button onClick={acceptChange}>{t('flight.accept_change')}</Button>
            </DialogActions>
        </Dialog>
    )
};
export default CartNewPriceModal;