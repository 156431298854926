export function safeStringToNumber(value) {
    if (typeof value === 'string') {
        const number = parseFloat(value);

        return isNaN(number) ?
            0 :
            number;
    }

    return isNaN(value) ? 0 : value;
}
