export function DestinationListChange(destination_list, zoom = true) {
    return {
        type: "ITINERARY_SET_DESTINATION_LIST",
        payload: {
            destination_list: destination_list,
            zoom: zoom,
        }
    }
}
export function DestinationFilterChange(filter_destination) {
    return {
        type: "ITINERARY_DESTINATION_FILTER_CHANGE",
        payload: {
            filter_destination: filter_destination
        }
    };
}
export function DestinationListChangeAndArrow(destination_list, show_arrow) {
    return {
        type: "ITINERARY_SET_DESTINATION_LIST_AND_ARROW",
        payload: {
            destination_list: destination_list,
            show_arrow: show_arrow,
        }
    }
}

export function DestinationListChangeOnZoom(destination_list) {
    return {
        type: "ITINERARY_SET_ZOOM_ON_DESTINATION_LIST",
        payload: {
            destination_list: destination_list,
        }
    }
}
export function SetArrow(show_arrow) {
    return {
        type: "ITINERARY_SET_ARROW",
        payload: {
            show_arrow: show_arrow
        }
    }
}

export function ItineraryLoaded(itinerary_loaded) {
    return {
        type: "ITINERARY_LOADED",
        payload: {
            itinerary_loaded: itinerary_loaded
        }
    }
}

export function ItineraryTogglePanel(hidden_panel) {
    return {
        type: "ITINERARY_TOGGLE_PANEL",
        payload: {
            hidden_panel: hidden_panel
        }
    }
}

export function ItineraryEditDestination(id) {
    return {
        type: "ITINERARY_EDIT_DESTINATION",
        payload: {
            id: id
        }
    }
}

export function ItineraryEditDestinationSetList(list) {
    return {
        type: "ITINERARY_EDIT_DESTINATION_SET_LIST",
        payload: {
            list: list
        }
    }
}

export function ItineraryTogglePanelState(old_panel_state) {
    return {
        type: "ITINERARY_TOGGLE_PANEL_STATE",
        payload: {
            old_panel_state: old_panel_state
        }
    }
}

export function ItineraryTogglePanelAndState(hidden_panel, old_panel_state) {
    return {
        type: "ITINERARY_TOGGLE_PANEL_AND_STATE",
        payload: {
            hidden_panel: hidden_panel,
            old_panel_state: old_panel_state
        }
    }
}

export function ZoomOnDestinationList() {
    return {
        type: "ITINERARY_ZOOM_ON_DESTINATION_LIST"
    }
}

export function SetDepartureReturnDestination(departure_destination, return_destination) {
    return {
        type: "ITINERARY_SET_DEPARTURE_RETURN",
        payload: {
            departure_destination: departure_destination,
            return_destination: return_destination,
        }
    }
}

export function SetDepartureDestination(departure_destination) {
    return {
        type: "ITINERARY_SET_DEPARTURE",
        payload: {
            departure_destination: departure_destination,
        }
    }
}

export function SetReturnDestination(return_destination) {
    return {
        type: "ITINERARY_SET_RETURN",
        payload: {
            return_destination: return_destination,
        }
    }
}

export function SetItinerary(itinerary_list) {
    return {
        type: "ITINERARY_SET_ITINERARY_LIST",
        payload: {
            itinerary_list: itinerary_list
        }
    }
}

export function ToggleEditInput(edit_input_triggered) {
    return {
        type: "ITINERARY_TOGGLE_EDIT_INPUT",
        payload: {
            edit_input_triggered: edit_input_triggered
        }
    }
}

export function ToggleItineraryDestinationList(destination_list_show) {
    return {
        type: "ITINERARY_TOGGLE_DESTINATION_LIST",
        payload: {
            destination_list_show: destination_list_show
        }
    }
}

export function ToggleReturnCitySame(same) {
    return {
        type: "ITINERARY_TOGGLE_RETURN_CITY_SAME",
        payload: {
            itinerary_return_same: same
        }
    }
}

export function ToggleDestinationModal(modal, index) {
    return {
        type: "ITINERARY_DESTINATION_TOGGLE_MODAL",
        payload: {
            modal: modal,
            index: index
        }
    }
}

export function ToggleTransportPanel(toggle_panel, transport_panel, step_panel) {
    return {
        type: "ITINERARY_TOGGLE_TRANSPORT_PANEL",
        payload: {
            toggle_panel: toggle_panel,
            transport_panel: transport_panel,
            step_panel: step_panel,
        }
    }
}

export function ToggleMoreInfoModal(modal_info, destination_info, guide) {
    return {
        type: "ITINERARY_TOGGLE_MORE_INFO_MODAL",
        payload: {
            modal_info: modal_info,
            destination_info: destination_info,
            guide: guide,
        }
    }
}

export function ChangeStepPanel(step_panel, transport_mode) {
    return {
        type: "ITINERARY_CHANGE_STEP_PANEL",
        payload: {
            step_panel: step_panel,
            transport_mode: transport_mode,
        }
    }
}

export function SetChoosedTransport(choosed_transport, index_transport) {
    return {
        type: "ITINERARY_SET_CHOOSED_TRANSPORT",
        payload: {
            choosed_transport: choosed_transport,
            index_transport: index_transport,
        }
    }
}

export function SetAirportError(airport_error, airport_error_message) {
    return {
        type: "ITINERARY_SET_AIRPORT_ERROR",
        payload: {
            airport_error: airport_error,
            airport_error_message: airport_error_message,
        }
    }
}
export function SelectTransportFlag(status) {
    return {
        type: "ITINERARY_SELECT_TRANSPORT_FLAG",
        payload: {
            status: status
        }
    }
}

export function DestinationModalListChange(destination_list) {
    return {
        type: "ITINERARY_DESTINATION_MODAL_LIST_CHANGE",
        payload: {
            destination_modal_list: destination_list
        }
    }
}
export function SetJourneyType(journey_type) {
    return {
        type: "ITINERARY_SET_JOURNEY_TYPE",
        payload: {
            journey_type: journey_type
        }
    }
}
export function SetFillFlag(flag) {
    return {
        type: "ITINERARY_SET_FILL",
        payload: {
            flag: flag
        }
    }
}

export function ToggleReloadCartModalAndSetStep(reload_cart_modal, step_reload_cart) {
    return {
        type: "ITINERARY_TOGGLE_RELOAD_CART_MODAL_AND_SET_STEP",
        payload: {
            reload_cart_modal: reload_cart_modal,
            step_reload_cart: step_reload_cart
        }
    }
}

export function ReloadCartModalSetStep(step_reload_cart) {
    return {
        type: "ITINERARY_RELOAD_CART_MODAL_SET_STEP",
        payload: {
            step_reload_cart: step_reload_cart
        }
    }
}
export function ToggleCartUpdate(cart_update) {
    return {
        type: "ITINERARY_TOGGLE_CART_UPDATE",
        payload: {
            cart_update: cart_update
        }
    }
}
export function SetUpdateCartFlag(flag) {
    return {
        type: "ITINERARY_SET_UPDATE_CART_FLAG",
        payload: {
            flag: flag
        }
    }
}
export function SetOutbound(outbound) {
    return {
        type: "ITINERARY_SET_OUTBOUND",
        payload: {
            outbound: outbound
        }
    }
}