//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';

import FlightCardOutbound from './FlightCardOutbound.tsx';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
	italic: {
		fontStyle: "italic !important"
	},
	font14: {
		fontSize: 14
	},
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    cover: {
    	display: "block",
	    width: "100%",
	    height: "100%"
    },
    content: {
    	display: 'flex',
    	alignItems: "center",
    	paddingLeft: theme.spacing(1),
    	paddingBottom: theme.spacing(1),
    },
    pictureContainer: {
	    overflow: "hidden",
	    height: 200,
	    width: 300,
	    position: "relative",
	    cursor: "pointer"
    },
    shortDescription: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        display:"-webkit-box !important",
        WebkitLineClamp:3,
        WebkitBoxOrient:"vertical",
        whiteSpace:"normal"
    },
    lowerCase: {
        textTransform: "capitalize"
    },
    checkbox: {
        // position: 'absolute',
        top: 'calc(50% - 21px)',
        margin: '0 4px'
    },
    flightOptionSelected: {
		border: `2px solid ${main_color} !important`,
		backgroundColor: `${main_color}20`,
        borderRadius: 10
	},
}));

type FlightsCardStepProps = {
    flight: any,
    outbound: any,
    index_outbound: number,
    variantIsChecked: any,
    onToggleVariant: any,
    products: any
}
const FlightsCardStep = (props: FlightsCardStepProps) => {
    const {outbound, index_outbound, flight, variantIsChecked, onToggleVariant, products} = props;
    const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const type = useSelector((state:any) => state.itinerary_type.type);

    let check_in_cart = products.find((product: any) => {
        if (product.start_destination !== undefined && product.start_destination !== null && flight.start_destination !== undefined && flight.start_destination !== null) {
    		return flight.start_destination.data.id === product.start_destination.data.id && flight.product_type === product.product_type && !product.is_optional;
        }
	});
    let check_option = products.find((product: any) => {
        if (product.start_destination !== undefined && product.start_destination !== null && flight.start_destination !== undefined && flight.start_destination !== null) {
    		return flight.start_destination.data.id === product.start_destination.data.id && flight.product_type === product.product_type && product.is_optional;
        }
	});
    return (
        <Grid container item xs spacing={2}>

            <Grid container item>
                <FlightCardOutbound key={ `flight-${flight.id}-outbound-${index_outbound}` } outbound={ outbound } index_outbound={ index_outbound } outbounds_length={0}/>
            </Grid>
            {
                check_option &&
                <Grid style={{marginTop: 10, border: "1px solid #e5e7eb", width: '100%', borderRadius: 10}} className={clsx(classes.genericText, {[classes.flightOptionSelected]: variantIsChecked(flight)})}>
                    <FormControlLabel
                        control={<Checkbox className={ classes.checkbox } color={"primary"}/>}
                        label={!flight.is_optional ? t<string>("itineraryType.default_flights", {type: t('shared.' + type)}) : (check_in_cart ? t<string>("itineraryType.alternate_flight") : t<string>("itineraryType.optional_flight"))}
                        labelPlacement="end"
                        checked={variantIsChecked(flight)}
                        onChange={onToggleVariant(flight)}
                    />
                </Grid>
            }
        </Grid>
    )
}
export default FlightsCardStep;