//---- Dependencies ----//
import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Slider from "react-slick";
import { withRouter } from 'react-router';
import { ListGroup, ListGroupItem } from "react-bootstrap";

//---- Common ----//
import GetProvider from "../../Common/Functions/GetProvider.js";
//---- Component ----//
import RenderCardCars from './RenderCardCars.jsx';
//---- Functions ----//

//---- Actions ----//
import { FiltersCars, ResetCurrentCart, SetVehiclesPriceError, SetCarsResults, GetContract } from "../../../Actions/CarsSearch";

//---- Functions ----//
import CheckAvailability from "../Functions/CheckAvailability";

//---- CSS ----//
import "../../../Style/FlightGroups.css";
import "../../../Style/FlightSearch.css";
import "../../../Style/CarsSearch.css";

@connect((store) => {
    return {
    	cars_results: store.cars_search.cars_results,
    	vehicles_price_error: store.cars_search.vehicles_price_error,
    	filters_cars: store.cars_search.filters_cars,
    	categories: store.cars_search.cars_categories,
    	currency: store.trip.currency,
		currency_list: store.base.currency_list,
    	language: store.header.tmp_language,
    	check_availability: store.cars_search.check_availability,
    	provider_list: store.trip.providers,
    	step_info: store.cars_search.step_info,
    	copy_location_index: store.cars_search.copy_location_index,
    	copy_car_index: store.cars_search.copy_car_index,
    	all_cars: store.cars_search.all_cars,
		user: store.user.user,
    }
})
@withTranslation()
@withRouter
export default class CarsResultMatrix extends React.Component {
	constructor(props, context) {
	    super(props, context);
	    this.state = {
	    	pic_width: 0
	    };
	}
	printProvider() {
		const { user, cars_results, provider_list, dispatch, t } = this.props;
		let arr = [];
		arr.push (
			<ListGroup key={ 0 } className={ "cars-categories text-center" } >
				<ListGroupItem  className={ "cars-provider-lowest pointer" } onClick={() => {dispatch(FiltersCars(null, null))}}>
					<div className="absolute-center bold full-width">
						{t("cars.lowest_price")}
					</div>
				</ListGroupItem>
				{
					cars_results.map((provider, index_provider) => {
						let active_provider = GetProvider(provider_list, provider.provider_id);
						let subprovider = GetProvider(provider_list, provider.subprovider_id);
						const config = JSON.parse(localStorage.getItem("config"));
						let stamp_recommended = null;
						let recommended = false;
						if (config !== null && config.stamp_recommended !== undefined && config.stamp_recommended !== null && config.stamp_recommended.url !== undefined && config.stamp_recommended.url !== null) {
							stamp_recommended = config.stamp_recommended.url;
						}
						for (let i = 0; i < active_provider.configuration.extra_params.length; i++) {
							if (active_provider.configuration.extra_params[i].key === "car_preferred" && active_provider.configuration.extra_params[i].value === "1") {
								recommended = true;
							}
						}
						return (
							<ListGroupItem key={ index_provider + 1 } className={ "cars-provider pointer" } onClick={() => {dispatch(FiltersCars(index_provider, null, provider.subprovider_name))}}>
								<div className="cars-provider-container">
								{
									provider.provider_id === provider.subprovider_id && active_provider.provider.logo !== null && (
										<div className="absolute-center">
											<div>
												{
													recommended && (
														<img src={ stamp_recommended } height={ 50 } style={{position:"absolute",left:"-45px"}}/>
													)
												}
												<img src={ active_provider.provider.logo.thumbnail_little !== null ? active_provider.provider.logo.thumbnail_little : active_provider.provider.logo.url } />
											</div>
										</div>
									)
								}
								{
									provider.provider_id !== provider.subprovider_id && active_provider.provider.logo !== null && (
										<div className={ "pt10" }>
											{
												!(user.client_full.type === 2 || JSON.parse(localStorage.getItem("config")).quotation_code === "pbe") && (
													<img src={ active_provider.provider.logo.thumbnail_little !== null ? active_provider.provider.logo.thumbnail_little : active_provider.provider.logo.url } onLoad={(event) => {this.setState({pic_width: event.target.width});}} className={this.state.pic_width < 30 ? "provider-small-pic" : ""} />
												)
											}
											{
												subprovider && subprovider.provider.logo !== null && (
													<img src={ subprovider.provider.logo.thumbnail_little !== null ? subprovider.provider.logo.thumbnail_little : subprovider.provider.logo.url } className={ "car-sub-logo" }/>
												)
											}
											{
												subprovider === null && (
													<img src={ provider.subprovider_logo } className={ "car-sub-logo" }/>
												)
											}
										</div>
									)
								}
								</div>
							</ListGroupItem>
						)
					})
				}
			</ListGroup>
		)
		return (
			arr
		)
	}
	printPrice() {
		const { cars_results, categories, currency_list, currency, user, dispatch, language, t } = this.props;
		const settings = {
			className: "cars-slider-price",
			slidesToShow: 7,
			//slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			infinite: false,
			speed: 300,
			arrows: true,
			responsive: [
			    {
			        breakpoint: 1650,
			        settings: {
			            slidesToShow: 5,
			        }
			    },
			    {
			        breakpoint: 1024,
			        settings: {
			            slidesToShow: 1,
			        }
			    }
			]
		};
		let arr = [];
		categories.map((category, category_index) => {
			if ((!(user.client_full.type === 2 && JSON.parse(localStorage.getItem("config")).quotation_code === "amerigo" && category.code === "ECAR")) && !(JSON.parse(localStorage.getItem("config")).quotation_code === "marcovasco" && category.code === "ECAR")) {
				console.log('category currency : ', category);
				let min_currency = currency_list.find((currency) => {
					return (currency.id === category.min_currency);
				});
				arr.push(
					<div className={ "cars-price-list" } key={category_index}>
						<ListGroup>
							<ListGroupItem className={ "cars-category no-padding pointer" } onClick={() => {dispatch(FiltersCars(null, category.name))}}>
								<div className="cars-category-container text-center">
									<div className="absolute-center">
										<div>
											<img src={ "/Img/Cars/" + category.img + ".png"} />
										</div>
										<div className={ "cars-category-name" }>
											{ t("cars.categories." + ((category.name).toLowerCase()).replace(" ", "_")) }
											{
												JSON.parse(localStorage.getItem("config")).quotation_code === "marcovasco" && (
													<Fragment>
														<br/>
														({ category.codes.map((code, index_code) => { return `${index_code > 0 ? ', ' : ''}${code}`})})
													</Fragment>
												)
											}
										</div>
									</div>
								</div>
							</ListGroupItem>
							<ListGroupItem className={ "cars-price no-padding" } onClick={() => {dispatch(FiltersCars(null, category.name))}}>
								<div className={ "cars-price-container-lowest pointer" } >
									<div className={ "absolute-center cars-price-details bold"}>
										{ category.min_price !== undefined && min_currency !== undefined && new Intl.NumberFormat(language, { style: 'currency', currency: min_currency.iso_code }).format(parseFloat(category.min_price)) }
									</div>
								</div>
							</ListGroupItem>
							{
								cars_results.map((result, vehicles_index) => {
									let price_currency = null;
									if (result.vehicles[category.name] !== undefined) {
										const client_price = result.vehicles[category.name][0].station_prices !== null ? result.vehicles[category.name][0].station_prices[0].prices[0] : null;
										if (client_price !== null) {
											price_currency = currency_list.find((currency) => {
												return (currency.id === client_price.selling_currency);
											});
										}
									}
									return (
										<ListGroupItem key={vehicles_index} className={ "cars-price no-padding" }>
											{
												result.vehicles[category.name] !== undefined && (
													<div className={ "cars-price-container" + (result.vehicles[category.name][0].station_prices !== null ? " pointer" : "") } onClick={() => {result.vehicles[category.name][0].station_prices !== null ? dispatch(FiltersCars(vehicles_index, category.name)) : ""}}>
														<div className={ "absolute-center cars-price-details" + ( result.vehicles[category.name][0].station_prices !== null ? ((parseFloat(result.vehicles[category.name][0].station_prices[0].prices[0].selling_price) === category.min_price) ? " bold ft-pink" : "") : "" )}>
															{ result.vehicles[category.name][0].station_prices !== null ? new Intl.NumberFormat(language, { style: 'currency', currency: price_currency.iso_code }).format(parseFloat(result.vehicles[category.name][0].station_prices[0].prices[0].selling_price)) : "---"}
														</div>
													</div>
												)
											}
											{
												result.vehicles[category.name] === undefined && (
													<div className={ "cars-price-container" } >
														<div className={ "absolute-center cars-price-details" }>
															{ "---" }
														</div>
													</div>
												)
											}
										</ListGroupItem>
									)
								})
							}
						</ListGroup>
					</div>
				)
			}
		});
		return (
			<Slider {...settings}>
				{arr}
			</Slider>
		)
	}
	redirection(flag) {
		const {router, step_info, copy_car_index, copy_location_index, provider_list, user, dispatch, t} = this.props;
		dispatch(ResetCurrentCart());
		if (flag) {
			router.push(`/${window.url_name}/apps/` + (this.props.params.id_step === "0" ? `cars-search-one-step` : `cars-search`));
		}
		else {
			dispatch(SetVehiclesPriceError(null));
			dispatch(SetCarsResults([]));
			dispatch(GetContract(null));
			CheckAvailability(step_info, copy_car_index, copy_location_index, provider_list, user, dispatch, t);
		}
	}
	render() {
		const { cars_results, all_cars, vehicles_price_error, router, filters_cars, check_availability, t } = this.props;
		const arr_error = {
			car_not_available: ["car not available. available cars are none", "requested rate not offered at this location", "no matching location could be found."],
			location_closed: ["location closed at arrival time", "the return station is not open on this day"],
			no_airport: ["The selected destination does not have an airport nearby."],
			time_out: ["error"]
		};
		return (
			<div>
				{
					(check_availability === "Fetching" || check_availability === "Done") && vehicles_price_error === null && cars_results.length === 0 && !all_cars && (
						<div className="ft-basic-card mt30 cars-results-matrix text-center">
							<div className={"bold"}>{t("cars.waiting_cars")}</div>
							<div className={ "spinner-orange" }></div>
						</div>
					)
				}
				{
					check_availability === "Done" && cars_results.length === 0 && all_cars && (
						<div className="ft-basic-card mt30 cars-results-matrix text-center">
							<div className={"bold"}>{t("cars.error.car_not_available")}</div>
							<div className={"bold"}>{t("cars.error.opening_time")}</div>
						</div>
					)
				}
				{
					check_availability === "Done" && cars_results.length > 0 &&(
						<div className={ "ft-basic-card mt30 cars-results-matrix" }>
							<div className={ "row" }>
								<div className={ "col-md-2" }>
									{this.printProvider()}
								</div>
								<div className={ "col-md-10" }>
									{
										this.printPrice()
									}
								</div>
								{
									!all_cars && (
										<div className={ "spinner-orange" }/>
									)
								}

							</div>
						</div>
					)
				}
				{
					filters_cars.length > 0 && (
						<RenderCardCars />
					)
				}
				{
					vehicles_price_error !== null && cars_results.length === 0 && (
						<div className={ "ft-basic-card cars-results-matrix mt30 ft-relative" }>
							{
								Object.keys(arr_error).map((error) => {
									let count = 0;
									if (typeof(vehicles_price_error) !== "string") {
										return (
											Object.keys(vehicles_price_error).map((error_key) => {
											 	if (typeof(vehicles_price_error[error_key]) !== "string") {
												 	return (
													 	vehicles_price_error[error_key].map((error_msg) => {
															if (arr_error[error].includes(error_msg) && count === 0) {
																count += 1;
																return (
																	<div className="text-center" key={0}>
																		<i className={"icon-warning ft-font-90"}></i>
																		<div className={"bold ft-font-20"} key={0}>
																			{t("cars.error." + error)}
																		</div>
																		<button className={"btn-orange uppercase ft-white mt15"} onClick={() => {this.redirection()}}>{t("flight_search.edit_search")}</button>
																	</div>
																);
															}
														})
													)
												}
												else {
													if (arr_error[error].includes(vehicles_price_error)) {
														return (
															<div className="text-center" key={0}>
																<i className={"icon-warning ft-font-90"}></i>
																<div className={"bold ft-font-20"} key={0}>
																	{t("cars.error." + error)}
																</div>
																<button className={"btn-orange uppercase ft-white mt15"} onClick={() => {this.redirection(error === "time_out" ? false : true)}}>{error === "time_out" ? t("flight_search.reload_search") : t("flight_search.edit_search")}</button>
															</div>
														);
													}
												}
											})
										)
									}
									else {
										if (arr_error[error].includes(vehicles_price_error)) {
											return (
												<div className="text-center" key={0}>
													<i className={"icon-warning ft-font-90"}></i>
													<div className={"bold ft-font-20"} key={0}>
														{t("cars.error." + error)}
													</div>
													<button className={"btn-orange uppercase ft-white mt15"} onClick={() => {this.redirection(error === "time_out" ? false : true)}}>{error === "time_out" ? t("flight_search.reload_search") : t("flight_search.edit_search")}</button>
												</div>
											);
										}
									}
								})
							}
						</div>
					)
				}
			</div>
		);
	}
}