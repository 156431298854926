import { Box, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
};
const traveler_type = ["Aucun", "Exigeant", "Ami/Famille", "Haut contributeur", "Bleisure", "Influenceur", "Repeater"]

const CartAssistanceTravelerType = () => {
    const dispatch = useDispatch();
    const assistance_travelers_type:string[] = useSelector((state: any) => state.cart.assistance_travelers_type);
    // const [travelerType, setTravelerType] = useState<string[]>([]);
    const handleChange = (event: SelectChangeEvent<typeof assistance_travelers_type>) => {
        const {
          target: { value },
        } = event;
        console.log('value:', value);
        dispatch({type: "CART_SET_ASSISTANCE_TRAVELERS_TYPE", payload: typeof value === 'string' ? value.split(',') : value,})
        // setTravelerType(
        //     typeof value === 'string' ? value.split(',') : value,
        // );
    }
    return (
        <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="traveler-type-label">Type de voyageur</InputLabel>
            <Select
            labelId="traveler-type-label"
            id="traveler-type"
            multiple
            value={assistance_travelers_type}
            onChange={handleChange}
            input={<OutlinedInput label="Type de voyageur" />}
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
            )}
            MenuProps={MenuProps}
            >
            {traveler_type.map((type) => (
                <MenuItem key={type} value={type}>
                    <Checkbox checked={assistance_travelers_type.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    )
}
export default CartAssistanceTravelerType;
