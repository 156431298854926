import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuProps
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { isFunction } from "lodash";

type Props = {
    open: boolean,
    anchorEl?: MenuProps['anchorEl']
} & ({
    types: number[],
    onChangeTypes: (types: number[]) => void,
    multiple: true
} | {
    type: number,
    onChangeType: (type: number) => void,
    multiple?: false
})

export function CartConstructionProductTypeMenu(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [container, setContainer] = useState<HTMLUListElement | null>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [anchorElPosition, setAnchorElPosition] = useState({ top: 0, left: 0 });
    const position = useMemo(() => {
        const top = anchorElPosition.top;
        let left = 0;
        if (anchorElPosition.left - containerWidth - 10 < 0) {
            left = 10 + containerWidth;
        } else {
            left = anchorElPosition.left - 10;
        }
        return { top, left };
    }, [containerWidth, anchorElPosition]);
    const productTypes: {
        type: number,
        value: string
    }[] = useMemo(() => {
        return [
            {
                type: 12,
                value: t('providers.activities')
            },
            {
                type: 998,
                value: t('global.poi')
            },
            {
                type: 8,
                value: t('providers.insurances')
            },
            {
                type: 0,
                value: t('providers.hotels')
            },
            {
                type: 4,
                value: t('providers.transfers')
            },
            {
                type: 2,
                value: t('providers.rental_car')
            },
            {
                type: 6,
                value: t('providers.flight')
            },
            {
                type: 999,
                value: t('shared.others')
            }
        ];
    }, [t]);
    const isAll = props.multiple &&
                  (
                      props.types.length === productTypes.length ||
                      props.types.length === 0
                  );
    let checked: number[] = [];

    if (props.multiple) {
        checked = isAll ?
            [] :
            props.types;
    } else {
        checked = [props.type];
    }

    const onToggleType = (type: number | 'ALL') => {
        if (props.multiple && type === 'ALL') {
            props.onChangeTypes(productTypes.map((item) => item.type));
        } else if (props.multiple && type !== 'ALL' && isAll) {
            props.onChangeTypes([type]);
        } else if (props.multiple && type !== 'ALL' && !props.types.includes(type)) {
            props.onChangeTypes(props.types.concat([type]));
        } else if (props.multiple && type !== 'ALL' && props.types.includes(type)) {
            props.onChangeTypes(props.types.filter((item) => item !== type));
        } else if (!props.multiple && type !== 'ALL' && props.type !== type) {
            props.onChangeType(type);
        }
    };

    useEffect(() => {
        if (container) {
            const observer = new ResizeObserver((entries) => {
                setContainerWidth(entries[0]?.contentRect.width ?? 0);
            });
            observer.observe(container);
            return () => observer.disconnect();
        }
    }, [container]);

    //needed to detect if filter button has changed position
    useEffect(() => {
        if (props.anchorEl && !isFunction(props.anchorEl)) {
            const checkPosition = () => {
                const currentTop = (props.anchorEl as HTMLUListElement).getBoundingClientRect().top;
                const currentLeft = (props.anchorEl as HTMLUListElement).getBoundingClientRect().left;
    
                setAnchorElPosition((position) => {
                    if (currentTop !== position.top || currentLeft !== position.left) {
                        return {
                            top: currentTop,
                            left: currentLeft
                        };
                    }
                    return position;
                });

                requestAnimationFrame(checkPosition);
            };
            checkPosition();
        }
    }, [props.anchorEl]);

    return (
        <Menu
            open={props.open}
            MenuListProps={{
                ref: (element) => {
                    setContainer(element);
                }
            }}
            PaperProps={{
                sx: {
                    position: 'static',
                    maxWidth: 'none',
                    maxHeight: 'none'
                }
            }}
            sx={{
                ...position,
                right: 'unset',
                bottom: 'unset',
                transform: 'translateX(-100%) !important'
            }}
            anchorEl={props.anchorEl}
            hideBackdrop
        >
            {
                props.multiple &&
                <MenuItem onClick={() => onToggleType('ALL')}>
                    {
                        isAll &&
                        <ListItemIcon>
                            <Check color="success" />
                        </ListItemIcon>
                    }
                    <ListItemText inset={!isAll}>
                        {t<string>('cart-material.cart-construction-all-products')}
                    </ListItemText>
                </MenuItem>
            }
            {
                productTypes.map((item) => {
                    return (
                        <MenuItem key={item.type} onClick={() => onToggleType(item.type)}>
                            {
                                checked.includes(item.type) &&
                                <ListItemIcon>
                                    <Check color="success" />
                                </ListItemIcon>
                            }
                            <ListItemText inset={!checked.includes(item.type)}>
                                { item.value }
                            </ListItemText>
                        </MenuItem>
                    );
                })
            }
        </Menu>
    );
}
