// Dependencies
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, Fragment, useEffect } from 'react';
// Core
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    select: {
        width: '100%'
    }
});

const CartFullTripPriceTerrestrial = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([{
        custom_currency: [],
        id: 47,
        international_name: "Euro",
        iso_code: "EUR",
        name: "Euro",
        symbol: "€",
        usd_factor: "0.84280100"
    }]);
    const loading = open && options.length === 0;
    const data_trip = useSelector(state => state.trip.data_trip);
    const currency_list = useSelector(state => state.base.currency_list);
    const margin_type = useSelector(state => state.cart.full_trip_terrestrial_margin_type);
    const margin_value = useSelector(state => state.cart.full_trip_terrestrial_margin_value);
    const purchase_price = useSelector(state => state.cart.full_trip_terrestrial_purchase_price);
    const purchase_currency = useSelector(state => state.cart.full_trip_terrestrial_purchase_currency);
    const onPurchasePriceChange = event => {
        dispatch({type: 'CART_SET_FULL_TRIP_TERRESTRIAL_PURCHASE_PRICE', payload: event.target.value});
    };
    const onPurchaseCurrencyChange = (event, value) => {
        dispatch({type: 'CART_SET_FULL_TRIP_TERRESTRIAL_PURCHASE_CURRENCY', payload: value});
    };
    const onMarginTypeChange = event => {
        dispatch({type: 'CART_SET_FULL_TRIP_TERRESTRIAL_MARGIN_TYPE', payload: event.target.value});
    };
    const onMarginValueChange = event => {
        dispatch({type: 'CART_SET_FULL_TRIP_TERRESTRIAL_MARGIN_VALUE', payload: event.target.value});
    };
    const onSearchChange = event => {
        setSearch(event.target.value);
    };
    useEffect(() => {
        let active = true;
        if (open) {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'GET',
                url: `${API_HREF}currencies/`,
                headers: headers,
                params: {
                    search: search,
                    limit: 10000
                }
            }).then((response) => {
                if (active) {
                    setOptions(response.data.results);
                }
            }).catch((error) => {
                console.log('error : ', error);
            });
        }
        return () => {
            active = false;
        };
    }, [loading, search]);
    useEffect(() => {
        if (!open) {
            setOptions([{
                custom_currency: [],
                id: 47,
                international_name: "Euro",
                iso_code: "EUR",
                name: "Euro",
                symbol: "€",
                usd_factor: "0.84280100"
            }]);
        }
    }, [open]);
    useEffect(() => {
        if (purchase_currency !== null) {
            setOptions([...options, currency_list.find(currency => currency.id === purchase_currency)]);
        }
    }, [purchase_currency]);
    return (
        <Fragment>
            <Grid item xs={ 6 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <InputLabel htmlFor={ 'manual-product-purchase-price' }>{ t('cart-material.manual-product-purchase-price') }</InputLabel>
                    <OutlinedInput
                        id={ 'manual-product-purchase-price' }
                        type={ 'number' }
                        onChange={ onPurchasePriceChange }
                        label={ t('cart-material.manual-product-purchase-price') }
                        value={ purchase_price }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={ 6 }>
                <Autocomplete
                    id={ 'manual-product-purchase-currency' }
                    open={ open }
                    onOpen={() => {
                        setOpen(true);
                        setSearch('');
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    clearOnBlur={ true }
                    clearOnEscape={ true }
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.international_name}
                    options={ options }
                    loading={ loading }
                    value={ (typeof purchase_currency === "number" ? currency_list.find(currency => currency.id === purchase_currency) : purchase_currency) }
                    onChange={ onPurchaseCurrencyChange }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={ t('cart-material.manual-product-purchase-currency') }
                            variant={ 'outlined' }
                            onChange={ onSearchChange }
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color={ 'inherit' } size={ 20 }/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={ 6 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <InputLabel id={ 'manual-product-margin-type-label' }>{ t('cart-material.manual-product-margin-type') }</InputLabel>
                    <Select className={ classes.select } labelId={ 'manual-product-margin-type-label' } label={ t('cart-material.manual-product-margin-type') } value={ margin_type } onChange={ onMarginTypeChange }>
                        <MenuItem value={ 'FIX' }>{ t('cart-material.manual-product-margin-fix') }</MenuItem>
                        <MenuItem value={ 'PER' }>{ t('cart-material.manual-product-margin-per') }</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={ 6 }>
                <FormControl fullWidth variant={ 'outlined' }>
                    <InputLabel htmlFor={ 'manual-product-margin-value' }>{ t('cart-material.manual-product-margin-value') }</InputLabel>
                    <OutlinedInput
                        id={ 'manual-product-margin-value' }
                        type={ 'number' }
                        onChange={ onMarginValueChange }
                        label={ t('cart-material.manual-product-margin-value') }
                        value={ margin_value }
                    />
                </FormControl>
            </Grid>
        </Fragment>
    );
};

export default CartFullTripPriceTerrestrial;