import { useSelector } from "react-redux";
import axios from "axios";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { Trip } from "../objects/trip";
import { AppState } from "../../../../Reducers/Reducers";

type TriggerCallback = (invoiceId: number, filename: string, data: Blob) => void

type Options = {
    onTrigger: () => void,
    onSuccess: () => void,
    onError: (error: Error) => void,
    onFinally: () => void
}

type RequestOptions = {
    clientId: number,
    trip: Trip,
    filename: string,
    invoiceId: number,
    data: Blob
}

export function useSendInvoice(options: Options): TriggerCallback {
    const trip = useSelector((state: AppState) => state.menu.trip_info);
    return async (invoiceId, filename, data) => {
        if (trip) {
            options.onTrigger();
            try {
                await makeRequest({
                    clientId: window.id_owner,
                    trip: trip,
                    invoiceId,
                    filename,
                    data
                });
                options.onSuccess();
            } catch (error: any) {
                options.onError(error);
            } finally {
                options.onFinally();
            }
        }
    };
}

function makeRequest(options: RequestOptions): Promise<void> {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const form = new FormData();
        form.append('pdf', options.data, options.filename + '.pdf');
        form.append('invoice_history_id', options.invoiceId.toString());
        return axios.post(
            `${API_HREF}client/${options.clientId}/trip/${options.trip.id}/versions/${options.trip.current_version}/send_invoice/`,
            form,
            { headers }
        );
    }

    return new Promise((resolve) => resolve());
}
