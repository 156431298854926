import React from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from "@material-ui/core/Grid";

import DriveEtaIcon from '@mui/icons-material/DriveEtaOutlined';
import FlightIcon from '@mui/icons-material/FlightOutlined';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxiOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    textJustify: {
        textAlign: "justify"
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    picture: {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    bold: {
        fontWeight: "bold"
    },
    capitalize: {
        textTransform: "Capitalize"
    },
    mainColor: {
        color: `${main_color}`
    }
}));

const RenderTransport = ({ iti, next_iti }) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    if (iti?.r2r_json?.vehicle?.kind && !!next_iti) {
        let origin;
        if (iti.destination !== null) {
            if (iti.destination.data.name !== undefined) {
                origin = iti.destination.data.name;
            } else {
                origin = iti.destination.data.international_name;
            }
        } else {
            origin = iti.city_name;
        }
        origin = origin.split(",");
        let destination = '';
        if (next_iti.destination !== null) {
            destination = next_iti.destination.data.name !== undefined ? next_iti.destination.data.name : next_iti.destination.data.international_name;
        } else {
            destination = next_iti.city_name ?? '';
        }
        destination = destination ? destination.split(",") : null;

        return (
            <Grid container direction={"row"} spacing={fullScreen ? 1 : 2} style={{
                marginBottom: 10, border: "1px solid #ebebeb", borderRadius: 13, width: '100%', marginLeft: 0, background: 'white' }}
            className={classes.genericText} alignItems={"center"} justify={"space-between"}>
                <Grid item sm={2} xs={12}>
                    {
                        iti.r2r_json.vehicle.kind === "car" && (
                            <DriveEtaIcon className={classes.mainColor} style={{ verticalAlign: "sub", marginRight: 10 }}/>
                        )
                    }
                    {
                        iti.r2r_json.vehicle.kind === "plane" && (
                            <FlightIcon className={classes.mainColor} style={{ verticalAlign: "sub", marginRight: 10 }}/>
                        )
                    }
                    {
                        iti.r2r_json.vehicle.kind === "taxi" && (
                            <LocalTaxiIcon className={classes.mainColor} style={{ verticalAlign: "sub", marginRight: 10 }}/>
                        )
                    }
                    {
                        iti.r2r_json.vehicle.kind === "ferry" && (
                            <DirectionsBoatFilledOutlinedIcon className={classes.mainColor} style={{ verticalAlign: "sub", marginRight: 10 }}/>
                        )
                    }
                    <span className={classes.bold}>{iti.r2r_json.vehicle.name + " : "}</span>
                </Grid>
                <Grid item sm={7} xs={7} style={{ textAlign: "center" }}>
                    {origin[0]}
                    <ArrowForwardIcon style={{fontSize: 16, verticalAlign: "middle", marginLeft: 2, marginRight: 2}}/>
                    {destination ? destination[0] : ''}
                </Grid>
                <Grid item sm={2} xs={5} container direction={"row"} spacing={2} justify={fullScreen ? "space-between" : "flex-end"}>
                    <Grid item className={classes.bold} style={{ fontSize: 12 }}>{iti.r2r_json.distance_string}</Grid>
                    <Grid item className={classes.bold} style={{ fontSize: 12 }}>{iti.r2r_json.duration_string}</Grid>
                </Grid>
            </Grid>
        );
    }
    return null;
};
export default RenderTransport;
