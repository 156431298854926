let pool: SaveRequestsQueue<any, any>[] = [];

export class SaveRequestsQueue<T, Q> {
    private locked = false;
    private steps: T[] = [];
    private queue: {inputs: Q[], from?: string}[] = [];
    private isDoingRequest = false;
    private onSuccess: (steps: T[]) => void = () => null;
    private onUpdate?: (
        steps: T[],
        inputs: Q[],
        from?: string
    ) => Promise<T[]>;

    public constructor() {
        pool.push(this);
    }

    private async execute(): Promise<void> {
        if (this.onUpdate && !this.isDoingRequest && this.queue.length > 0) {
            try {
                this.isDoingRequest = true;
                const { inputs, from } = this.queue[this.queue.length - 1]!;
                this.queue = [];
                this.steps = await this.onUpdate(this.steps, inputs, from);
                if (this.queue.length === 0) {
                    this.unlock();
                    this.onSuccess(this.steps);
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.isDoingRequest = false;
            }
            this.execute();
        }
    }

    public add(inputs: Q[], from?: string): void {
        if (!this.locked) {
            this.queue.push({ inputs, from });
            this.execute();
        }
    }

    public setOnSuccess(callback: SaveRequestsQueue<T, Q>['onSuccess']): void {
        this.onSuccess = callback;
    }

    public setOnUpdate(callback: SaveRequestsQueue<T, Q>['onUpdate']): void {
        this.onUpdate = callback;
    }

    public setInitialData(steps: SaveRequestsQueue<T, Q>['steps']): void {
        this.steps = steps;
    }

    public release(): void {
        pool = pool.filter((item) => {
            return item !== this;
        });
    }

    public static hasPending(): boolean {
        for (const item of pool) {
            if (item.queue.length > 0 || item.isDoingRequest) {
                return true;
            }
        }
        return false;
    }

    public lock(): void {
        this.locked = true;
    }

    public unlock(): void {
        this.locked = false;
    }
}
