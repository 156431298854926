//Dependencies
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';

//Core & Lab
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

//Actions
import { setPicture } from '../../Actions/Pictures';
//Components
import CartPictureCropper from './CartPictureCropper';
import CartPictureKeep from './CartPictureKeep';
import CartPictureName from './CartPictureName';
import CartPictureDescription from './CartPictureDescription';
import CartPictureEditSave from './CartPictureEditSave';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    ftContainer: {
        marginTop: "32px !important",
        marginBottom: "32px !important"
    },
    ftUserTitle: {
        backgroundColor: "rgba(0, 0, 0, .03) !important",
        borderBottom: "1px solid rgba(0, 0, 0, .125) !important"
    },
    ftUserSubContainer: {
        paddingTop: "16px !important"
    }
}));

const CartPictureEdit = ({ onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const picture = useSelector((state) => state.pictures.picture);
    return (
        <Container className={ classes.ftContainer } maxWidth={ 'md' }>
            <Accordion square expanded>
                <AccordionSummary className={ classes.ftUserTitle }>
                    <Typography>{ t('cart-material.picture-informations') }</Typography>
                </AccordionSummary>
                <AccordionDetails className={ classes.ftUserSubContainer }>
                    <Grid container spacing={ 2 }>
                        { picture !== null && picture.url !== null && <CartPictureCropper picture={ picture }/> }
                        <Grid item xs={ 12 }>
                            <CartPictureKeep/>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <CartPictureName/>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <CartPictureDescription/>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Grid container spacing={ 2 } justify={ 'flex-end' }>
                <Grid item>
                    <Button onClick={ onClose }>{ t('global.cancel') }</Button>
                </Grid>
                <Grid item>
                    <CartPictureEditSave onClose={onClose} />
                </Grid>
            </Grid>
        </Container>
    );
};
export default CartPictureEdit;
