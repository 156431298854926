//---- Dependencies ----//
import React, {Fragment, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import i18n from "../../i18n";
import { LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import localeText from "../Functions/localeText";
import clsx from "clsx";
import { AddHistorydest, SetItineraryTypeDetailsDataAndDayAndNight } from "../../Actions/ItineraryType";
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import VisiteursProductsListRow from "./VisiteursProductsListRow";

const useStyles = makeStyles({
    pointer: {
        cursor: "pointer"
    },
    paddingCell: {
        "& .MuiTableCell-root" : {
            padding: "10px 0px 10px 16px !important",
            fontSize: 12
        },
    },
    hover: {
        "&:hover": {
            backgroundColor: '#f1f1f1'
        }
    },
});

const VisiteursProdcutsList = ({displayCard, router, order, setOrder, orderBy, setOrderBy}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const locales = useSelector(state => state.user.locales);
    const current_locale = locales.find((el) => {return el.language_code === i18n.language});
    const dispatch = useDispatch();

    const {pass_check, headers} = CheckBeforeRequest();

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    return (
        <TableContainer>
            <Table id={"card-list"}>
                <TableHead>
                    <TableRow className={classes.paddingCell}>
                        <TableCell align={"left"} sortDirection={orderBy === 'name' ? order : false} style={{whiteSpace: 'nowrap'}}>
                            <TableSortLabel
                                active={orderBy === 'name'}
                                direction={orderBy === 'name' ? order : 'asc'}
                                onClick={createSortHandler('name')}
                            >
                            {'Nom'}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align={"left"} sortDirection={orderBy === 'region' ? order : false} style={{whiteSpace: 'nowrap'}}>
                            <TableSortLabel
                                active={orderBy === 'region'}
                                direction={orderBy === 'region' ? order : 'asc'}
                                onClick={createSortHandler('region')}
                            >
                            {'Région'}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align={"left"} sortDirection={orderBy === 'Type' ? order : false} style={{whiteSpace: 'nowrap'}}>
                            <TableSortLabel
                                active={orderBy === 'type'}
                                direction={orderBy === 'type' ? order : 'asc'}
                                onClick={createSortHandler('type')}
                            >
                            {'Type'}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align={"left"} style={{whiteSpace: 'nowrap'}}>
                            {/* <TableSortLabel
                                active={orderBy === 'duration'}
                                direction={orderBy === 'duration' ? order : 'asc'}
                                onClick={createSortHandler('duration')}
                            > */}
                            {'Durée'}
                            {/* </TableSortLabel> */}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                    displayCard.length === 0 && (
                        <TableRow style={{ height: 53 }}>
                            <TableCell colSpan={ 20 }>
                                <LinearProgress/>
                            </TableCell>
                        </TableRow>
                    )
                }
                {displayCard !== null && displayCard.map((row, row_index) => {
                    return (
                        <VisiteursProductsListRow key={row_index} row={row} router={router}/>
                    )
                })}
                </TableBody>
            </Table>
        </TableContainer>
    )
};
export default VisiteursProdcutsList;