// Dependencies
import React, { Fragment } from 'react';
// Core
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// Components
import MaterialNavigationMobile from './MaterialNavigationMobile.js';
import MaterialNavigationDesktop from './MaterialNavigationDesktop.js';

const MaterialNavigation = ({router, current_page, to_render}) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Fragment>
            { !mobile && to_render && <MaterialNavigationDesktop router={ router } current_page={ current_page }/> }
            { mobile && <MaterialNavigationMobile router={ router } current_page={ current_page }/> }
        </Fragment>
    );
};

export default MaterialNavigation;