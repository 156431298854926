/*eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import CrmDemandField from "./CrmDemandField";

const CrmDemandContacts = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const selected_demand = useSelector(store => store.crm.selected_demand);
    const user_client = useSelector(store => store.user.user);

    const displayName = (traveler) => {
        return `${traveler.title ? traveler.title + " " : ''}${traveler.first_name ? traveler.first_name + " " : ''}${traveler.last_name ? traveler.last_name + " " : ''}`;
    };

    if (selected_demand && selected_demand.data?.length && selected_demand.data[selected_demand.data.length - 1]) {
        const version = selected_demand.data[selected_demand.data.length - 1];
        return (
            <div>
                <h6>{t('crm.demand.lead')}</h6>
                {
                    version.contact_traveler && Boolean(version.travelers?.find(e => e.id == version.contact_traveler)) &&
                    <small>{t('crm.lead_is_in_travelers')}</small>
                }
                <CrmDemandField label={""} expandable={true} useBreakline={true}
                    value={version?.contact_traveler_detail
                        ? [
                            displayName(version.contact_traveler_detail),
                            version.contact_traveler_detail.email,
                            version.contact_traveler_detail.birth_date ? moment(new Date(version.contact_traveler_detail.birth_date)).format("DD/MM/YYYY") : null
                        ] : null} />
                <br />
                <h6>{t('crm.demand.travelers')}</h6>
                {
                    version.travelers?.filter(e => e.id !== version.contact_traveler_detail?.id)?.map(trav => {
                        return (
                            <CrmDemandField label={""} expandable={true} useBreakline={true}
                                value={[
                                    displayName(trav),
                                    trav.email,
                                    trav.birth_date ? moment(new Date(trav.birth_date)).format("DD/MM/YYYY") : null
                                ]} />
                        );
                    })
                }
            </div>
        );
    }
    return null;
};
export default CrmDemandContacts;
