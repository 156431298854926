import React, { Fragment, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { makeStyles } from "@material-ui/core/styles";
import GetPictureBySize from "../Functions/GetPictureBySize"

const useStyles = makeStyles(theme => ({
    img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    imgBig: {
        borderRadius: 16
    },
    listImgBig: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16
    },
    buttonBorder: {
        backgroundColor: "rgba(255, 255, 255, 0.80)",
        transition: "background-color 1s",
        "&:hover, &:focus": {
            backgroundColor: "white",
            color: "black"
        }
    },
    leftChevron: {
        position: "absolute",
        left: theme.spacing(2),
        zIndex: 999
    },
    small: {
        top: 85
    },
    big: {
        top: 100
    },
    rightChevron: {
        position: "absolute",
        right: theme.spacing(2),
        zIndex: 999
    },
    sliderDotContainer: {
        position: "absolute",
        bottom: 8,
        textAlign: "center",
        width: "100%"
    },
    sliderDot: {
        fill: "rgba(255, 255, 255, 0.54)"
    },
    sliderDotActive: {
        fill: "white"
    },
    sliderDotSmall: {
        fontSize: "0.7rem"
    },
    medium: {
        top: 120,
        [theme.breakpoints.down('lg')]: {
            top: 85
        }
    },
    mediumFix: {
        top: 120
    }
}));

const PoiSlider = ({pictures, showChevron, size, dotSize, noDot, from_list=false}) => {
    const classes = useStyles();
    const [currentPicture, setCurrentPicture] = useState(0);
    const picture_length = pictures.length;
    let dot = [];
    for (let i = 0; i < 5 && i < picture_length; i++) {
        if (currentPicture === i) {
            dot.push(<FiberManualRecord key={ i } className={ `${classes.sliderDotActive}${dotSize === 1 ? ` ${classes.sliderDotSmall}` : ""}` } onClick={() => { setCurrentPicture(i); }}/>);
        } else {
            dot.push(<FiberManualRecord key={ i } className={ `${classes.sliderDot}${dotSize === 1 ? ` ${classes.sliderDotSmall}` : ""}` } onClick={() => { setCurrentPicture(i); }}/>);
        }
    }
    return (
        <Fragment>
            <img className={ `${classes.img}${size === 1 ? "" : (from_list ? ` ${classes.listImgBig}` : ` ${classes.imgBig}`)}` } src={ pictures[currentPicture] !== undefined ? GetPictureBySize(pictures[currentPicture], "MEDIUM") : "/Img/poi_default.jpg" } alt={ "room picture" }/>
            {
                !noDot &&
                <div className={ classes.sliderDotContainer }>
                    { dot }
                </div>
            }
            {
                !noDot && showChevron && (
                    <Fragment>
                        <IconButton className={ `${classes.buttonBorder} ${classes.leftChevron} ${size === 1 || size === 0 ? classes.small : size === 3 ? classes.medium : size === 4 ? classes.mediumFix : classes.big}` } size={ "small" } onClick={(event) => { event.stopPropagation();setCurrentPicture(currentPicture > 0 ? currentPicture - 1 : (picture_length > 5 ? picture_length - 1 : 4)); }}>
                            <ChevronLeft/>
                        </IconButton>
                        <IconButton className={ `${classes.buttonBorder} ${classes.rightChevron} ${size === 1 || size === 0 ? classes.small : size === 3 ? classes.medium : size === 4 ? classes.mediumFix : classes.big}` } size={ "small" } onClick={(event) => { event.stopPropagation();setCurrentPicture(currentPicture < (picture_length > 5 ? 4 : picture_length - 1) ? currentPicture + 1 : 0); }}>
                            <ChevronRight/>
                        </IconButton>
                    </Fragment>
                )
            }
        </Fragment>
    )
};

export default PoiSlider;
