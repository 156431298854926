import React, { Fragment, useState } from "react";
import clsx from 'clsx';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';

import CheckBoxFilled from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import green from "@material-ui/core/colors/green";

const useStyles = makeStyles((theme) => ({
    filterButton: {
	    borderRadius: 40,
	    padding: 8,
	    height: 40,
	    fontSize: 10
    },
    checked: {
        fill: "black"
    },
    filterButtonActive: {
        //color: green[500],
        border: '2px solid black'
    }
}));
const StyledMenuItem = withStyles((theme) => ({
  	root: {
    	'&:focus': {
      		'& .mui-jss-MuiListItemIcon-root, & .mui-jss-MuiListItemText-primary': {
        		color: theme.palette.common.white
      		}
    	}
    }
}))(MenuItem);
const StyledMenu = withStyles({
  	paper: {
  		marginTop: 5,
    	border: '1px solid #d3d4d5'
  	}
})((props) => (
  	<Menu
	    elevation={0}
	    getContentAnchorEl={null}
	    anchorOrigin={{
	      	vertical: 'bottom',
	      	horizontal: 'center'
	    }}
	    transformOrigin={{
	      	vertical: 'top',
	      	horizontal: 'center'
	    }}
    	{...props}
  	/>
));
const CustomMenuFilters = ({ filters, type }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
	  setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
	  setAnchorEl(null);
    };
    let count = 0;
    filters.map((el) => {
        if (el.checked) {
            count++;
        }
    });
    return (
        <Fragment>
	    	<Button
	        	className={ clsx(classes.filterButton, { [classes.filterButtonActive]: count > 0 }) }
		        aria-haspopup="true"
		        variant="outlined"
		        onClick={handleClick}
	      	>
		    {type}{ count > 0 ? ` - ${count}` : '' }
	      	</Button>
	      	<StyledMenu
	  	        id="customized-menu"
	  	        anchorEl={anchorEl}
	  	        keepMounted
	  	        open={Boolean(anchorEl)}
	  	        onClose={handleClose}
		      	>
		      	{
		      		filters.map((filter, filter_index) => {
		      			return (
					        <StyledMenuItem key={filter_index}>
					          	<FormControlLabel
					                control={<Checkbox icon={ <CheckBoxOutlineBlank fontSize={ "small" }/> } checkedIcon={ <CheckBoxFilled className={ classes.checked } fontSize={ "small" }/> } checked={ filter.checked } onChange={() => {
                                        filter.func.call();
                                    }}/>}
					                label={ filter.name }
					            />
					        </StyledMenuItem>
		      			);
		      		})
		      	}
	      	</StyledMenu>
        </Fragment>
    );
};
export default React.memo(CustomMenuFilters);
