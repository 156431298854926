import axios, { AxiosResponse } from "axios";
import memoizee from "memoizee";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { ItineraryByDay } from "../../../Itinerary/objects/itineraryByDay";

async function fetch(circuit: number): Promise<ItineraryByDay[]> {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        try {
            let next: string | null = `${API_HREF}client/${window.id_owner}/circuits/${circuit}/by-day/`;
            const result: ItineraryByDay[] = [];
            do {
                const response: AxiosResponse<{
                    next: string | null,
                    results: ItineraryByDay[]
                }> = await axios.get(
                    next,
                    { headers }
                );
                for (const item of response.data.results) {
                    result.push(item);
                }
                next = response.data.next;
            } while (next);
            return result;
        } catch (error: any) {
            console.error(error);
        }
    }
    return [];
}

export const findGIRContent = memoizee(
    fetch,
    { promise: true, primitive: true }
);
