let product_type_arr = {
    0: "hotels",
    1: "excursions",
    2: "rental_car",
    3: "restaurants",
    4: "transfers",
    5: "trains",
    6: "flight",
    7: "accommodations",
    8: "insurances",
    9: "cruises",
    10: "touristic_guide",
    11: "activities",
    // 11: "other_product",
    12: "activities",
    13: "ferries",
    16: "administration_fees",
    17: "travel_book"
};

export default (id) => {
    let product_type = null;
    for (let k in product_type_arr) {
        if (parseFloat(k) === id) {
            product_type = product_type_arr[k];
        }
    }
    if (product_type === null) {
        return "unknown_product";
    }
    return product_type;
};
