import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";

import FlightOutlinedIcon from '@material-ui/icons/FlightOutlined';
import CommuteOutlinedIcon from '@material-ui/icons/CommuteOutlined';
import HotelOutlinedIcon from '@material-ui/icons/HotelOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import Train from '@material-ui/icons/TrainOutlined';
import { DirectionsCarOutlined, FlightTakeoffOutlined, SailingOutlined } from '@mui/icons-material';
import DirectionsBoatOutlined from '@material-ui/icons/DirectionsBoatOutlined';

import FillMultiDest from "../../Common/Functions/FillMultiDest.js";
import { useSnackbar } from "notistack";
import { StepsDatesManager } from "../../Itinerary/utils/stepsDatesManager";

const useStyles = makeStyles(theme => ({
    orange: {
        color: "#E6592F"
    },
    grey: {
        color: grey[400]
    },
    green: {
        color: green[500]
    }
}));

const MobileServicesHeader = ({router, setIsOpen, current_page}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector(state => state.user.user);
    const itinerary_list = useSelector(state => state.itinerary.itinerary_list);
    const departure_destination = useSelector(state => state.itinerary.departure_destination);
    const return_destination = useSelector(state => state.itinerary.return_destination);
    const start_date = useSelector(state => state.trip.start_date);
    const flight_cart = useSelector(state => state.flight.cart);
    const car_cart = useSelector(state => state.cars_search.cart);
    const accommodation_cart = useSelector(state => state.accommodation.cart);
    const poi_cart = useSelector(state => state.poi.cart);
    const transfer_cart = useSelector(state => state.transfers.cart);
    const tripStartDate = useSelector(state => state.trip.start_date);
    const tripEndDate = useSelector(state => state.trip.end_date);
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const is_staging = document.location.hostname.split('.')[2] === 'fr' && document.location.hostname !== 'test-aws.facilitatrip.fr';

    const datesManager = useMemo(() => {
        if (tripStartDate && tripEndDate) {
            return new StepsDatesManager(tripStartDate, tripEndDate);
        }
        return null;
    }, [tripStartDate, tripEndDate]);
    const stepControl = (start_date, end_date, accommodation_cart) => {
        for (let j = 0; j < accommodation_cart.length; j++) {
            if (accommodation_cart[j].booking_status === null || accommodation_cart[j].booking_status.status_booking !== "CANCELLED") {
                if (start_date.isSame(moment.utc(accommodation_cart[j].start_date), 'd')) {
                    if (end_date.isSame(moment.utc(accommodation_cart[j].end_date), 'd')) {
                        return true;
                    } else {
                        return stepControl(moment.utc(accommodation_cart[j].end_date), end_date, accommodation_cart);
                    }
                }
            }
        }
    };
    const accommodationCartControl = (itinerary_list, accommodation_cart) => {
        for (let i = 0; i < itinerary_list.length; i++) {
            if (itinerary_list[i].step_type === "STEP") {
                let has_full_step_accommodation = false;
                if (moment.utc(itinerary_list[i].start_date).isSame(moment.utc(itinerary_list[i].end_date), "d")) {
                    has_full_step_accommodation = true;
                } else {
                    has_full_step_accommodation = stepControl(moment.utc(itinerary_list[i].start_date), moment.utc(itinerary_list[i].end_date), accommodation_cart);
                }
                if (!has_full_step_accommodation) {
                    return false;
                }
            }
        }
        return false;
    };
    const carCartControl = (car_cart) => {
        for (let i = 0; i < car_cart.length; i++) {
            if (car_cart[i].booking_status === null || car_cart[i].booking_status.status_booking !== "CANCELLED") {
                return true;
            }
        }
        return false;
    };
    const poiCartControl = (poi_cart) => {
        for (let i = 0; i < poi_cart.length; i++) {
            if (poi_cart[i].booking_status === null || poi_cart[i].booking_status.status_booking !== "CANCELLED") {
                return true;
            }
        }
        return false;
    };
    const transferCartControl = (transfer_cart) => {
        for (let i = 0; i < transfer_cart.length; i++) {
            if (transfer_cart[i].booking_status === null || transfer_cart[i].booking_status.status_booking !== "CANCELLED") {
                return true;
            }
        }
        return false;
    };

    return (
        <List>
            {
                // (quotation_code !== 'visiteurs' || (user.client_full.type !== 2 || [20400, 19984, 22091, 18333, 21925, 18538, 18397, 23024, 18544, 20111, 18376, 22690, 19335, 19587, 19550, 21248, 23341, 22901,
                //     19550, 18538, 23024, 21668, 22690, 19077, 19335, 21285, 22719, 18816, 22091, 19587,
                //     19412, 22707, 18376, 22317, 23462, 21655, 21592, 22908, 21248, 20132, 18473, 18508,
                //     23341, 21559, 23461, 19006, 19929, 18397, 18968, 18971, 18973, 22677, 18724, 18596,
                //     20555, 21888, 22435, 22285, 23018, 20238, 18447, 18444, 21128, 23016, 18291, 21491,
                //     22657, 20466, 18471, 19673, 22909, 20331, 20241, 20752, 19044, 22351, 23131, 23317,
                //     22612, 19520, 23136, 21861, 18243, 22282, 22153, 22281, 22286, 22535, 22511, 22515,
                //     22520, 22545, 22573, 22554, 22568, 18269, 18319, 18288, 18296, 19023, 19616, 18316,
                //     23467, 18322, 23054, 22665, 20007, 21767, 23070, 18364, 21902, 21905, 18419, 21938,
                //     21936, 18446, 18456, 18454, 23460, 18468, 19627, 19710, 19680, 19708, 19714, 18543,
                //     18546, 18544, 23065, 23071, 18552, 18562, 21750, 21459, 20590, 18590, 21977, 23441,
                //     18602, 21309, 21384, 21418, 21392, 21261, 21382, 21330, 23025, 21277, 21420, 21402,
                //     21256, 21349, 18676, 18662, 18674, 21566, 22027, 20056, 18719, 21464, 21568, 21573,
                //     21574, 21576, 20067, 21273, 22051, 22050, 18788, 19984, 19980, 19973, 19970, 20694,
                //     18817, 18828, 18831, 20167, 20249, 20084, 20186, 20094, 20086, 20115, 20114, 20117,
                //     20118, 20120, 20122, 20123, 20188, 20098, 20298, 20189, 20140, 20088, 20143, 20251,
                //     20111, 20156, 20220, 20219, 20164, 20229, 20239, 20321, 23418, 23326, 20343, 20305,
                //     20427, 23523, 21475, 21489, 21474, 21492, 21453, 21483, 21484, 22117, 20373, 23489,
                //     23368, 21659, 21657, 20843, 22185, 23449, 20386, 19071, 21593, 19094, 19092, 20919,
                //     19109, 23311, 19111, 19130, 19131, 23463, 22319, 22323, 19148, 19168, 21608, 21627,
                //     19178, 19184, 20400, 22355, 22359, 22678, 21793, 19255, 19257, 22414, 22416, 22954,
                //     21024, 19315, 19323, 22436, 22437, 21056, 19370, 23063, 22901, 22683, 22686, 22708,
                //     22701, 22709, 22684, 22702, 22695, 22717, 22682, 22705, 22700, 22696, 22694, 23049,
                //     22706, 22715, 19398, 19399, 22975, 19487, 22718, 22659, 22721, 22622, 19499, 19586,
                //     19512, 19515, 21674, 22633, 22634, 19542, 19543, 19547, 21170, 21533, 21204,
                //     20373, 20372, 18302, 18419, 23464, 21342, 21247, 21284, 21284
                // ].includes(user.client))) &&
                quotation_code !== 'marcovasco' &&
                <Fragment>
                    <ListItem button className={ `${current_page === "flight-groups" || current_page === "flight-search/:use_cache" || current_page === "selected-flight" ? classes.orange : (flight_cart.length > 0 ? classes.green : classes.grey)}` } onClick={(e) => {
                        e.preventDefault();
                        for (let i = 0; i < itinerary_list.length; i++) {
                            if (itinerary_list[i].step_type === "STEP") {
                                FillMultiDest(itinerary_list, 2, start_date, dispatch, departure_destination, return_destination, t);
                                break;
                            }
                        }
                        setIsOpen(null);
                        router.push(`/${window.url_name}/apps/flight-groups`);
                    }}>
                        <ListItemIcon>
                            <FlightTakeoffOutlined/>
                        </ListItemIcon>
                        <ListItemText primary={t("apps_navigation.flight")}/>
                    </ListItem>
                    <Divider/>
                </Fragment>
            }
            <ListItem button className={ `${current_page === "material-cars-search" ? classes.orange : (carCartControl(car_cart) ? classes.green : classes.grey)}` } onClick={() => {
                if (current_page === "material-cars-search") {
                    setIsOpen(null);
                    dispatch({type: "CARS_TOGGLE_FROM_CART", payload: {from_cart: false}});
                    dispatch({type: "CARS_IS_FORM"});
                }
                else {
                    setIsOpen(null);
                    router.push(`/${window.url_name}/apps/material-cars-search`);
                }
            }}>
                <ListItemIcon>
                    <DirectionsCarOutlined/>
                </ListItemIcon>
                <ListItemText primary={t('global.car')}/>
            </ListItem>
            <Divider/>
            {
                !is_staging &&
                <>
                    <ListItem button className={ `${classes.grey}` }>
                        <ListItemIcon>
                            <Train fontSize={ "default" }/>
                        </ListItemIcon>
                        <ListItemText primary={t('apps_navigation.train')}/>
                    </ListItem>
                </>
            }
            <ListItem button className={ `${current_page === "accommodation" ? classes.orange : (accommodationCartControl(itinerary_list, accommodation_cart) ? classes.green : classes.grey)}` } onClick={(e) => {
                    e.preventDefault();
                    let has_step = false;
                    for (let i = 0; i < itinerary_list.length; i++) {
                        if (itinerary_list[i].step_type === "STEP") {
                            has_step = true;
                            let proceed = true;
                            if (router.routes !== undefined && router.routes !== null) {
                                for (let j = 0; j < router.routes.length; j++) {
                                    if (router.routes[j].path === "accommodation-card") {
                                        proceed = false;
                                    }
                                }
                            }
                            if (proceed) {
                                let hasLateArrival = false;
                                let hasLateDeparture = false;
                                let start_date = itinerary_list[i].start_date.split('T')[0];
                                let end_date = itinerary_list[i].end_date.split('T')[0];
                                hasLateArrival = datesManager?.isLateArrival(itinerary_list[i].start_date);
                                hasLateDeparture = datesManager?.isLateArrival(itinerary_list[i].end_date);
                                if (hasLateArrival) {
                                    start_date = (moment(itinerary_list[i].start_date).subtract(1, 'days')).format("YYYY-MM-DD");
                                }
                                if (hasLateDeparture) {
                                    end_date = (moment(itinerary_list[i].end_date).subtract(1, 'days')).format("YYYY-MM-DD");
                                }
                                const bounds = JSON.parse(itinerary_list[i].destination.data.bounds.replace(/'/g,'"'));
                                setIsOpen(null);
                                dispatch({
                                    type: "MATERIAL_SET_ACTIVE_DESTINATION",
                                    payload: {
                                        active: itinerary_list[i].id,
                                        bounds: {
                                            north: bounds.north,
                                            east: bounds.east,
                                            south: bounds.south,
                                            west: bounds.west,
                                        },
                                        zoom: itinerary_list[i].destination.data.zoom_level,
                                        start_date: start_date,
                                        end_date: end_date,
                                        destination: itinerary_list[i],
                                        reset: true
                                    }
                                });
                                dispatch({type: 'ACCOMMODATION_LATE_ARRIVAL_DEPARTURE', payload: {hasLateArrival: hasLateArrival, hasLateDeparture: hasLateDeparture}});
                            }
                            break;
                        }
                    }
                    if (has_step) {
                        setIsOpen(null);
                        router.push(`/${window.url_name}/apps/accommodation`);
                    } else {
                        setIsOpen(null);
                        enqueueSnackbar(t("apps_navigation.no_destination"), {
                            variant: "warning",
                        });
                    }
                }}>
                <ListItemIcon>
                    <HotelOutlinedIcon fontSize={ "default" }/>
                </ListItemIcon>
                <ListItemText primary={t("apps_navigation.accommodation")}/>
            </ListItem>
            <Divider/>
            <ListItem button className={ `${current_page === "poi" ? classes.orange : (poiCartControl(poi_cart) ? classes.green : classes.grey)}` } onClick={(e) => {
                    e.preventDefault();
                    let has_step = false;
                    for (let i = 0; i < itinerary_list.length; i++) {
                        if (itinerary_list[i].step_type === "STEP") {
                            has_step = true;
                            const bounds = JSON.parse(itinerary_list[i].destination.data.bounds.replace(/'/g,'"'));
                            setIsOpen(null);
                            dispatch({
                                type: "MATERIAL_SET_ACTIVE_DESTINATION",
                                payload: {
                                    active: itinerary_list[i].id,
                                    bounds: {
                                        north: bounds.north,
                                        east: bounds.east,
                                        south: bounds.south,
                                        west: bounds.west,
                                    },
                                    zoom: itinerary_list[i].destination.data.zoom_level,
                                    start_date: itinerary_list[i].start_date.split('T')[0],
                                    end_date: itinerary_list[i].end_date.split('T')[0],
                                    destination: itinerary_list[i],
                                    reset: true
                                }
                            });
                            break;
                        }
                    }
                    if (has_step) {
                        setIsOpen(null);
                        router.push(`/${window.url_name}/apps/poi`);
                    } else {
                        setIsOpen(null);
                        enqueueSnackbar(t("apps_navigation.no_destination"), {
                            variant: "warning",
                        });
                    }
                }}>
                <ListItemIcon>
                    <VisibilityOutlinedIcon/>
                </ListItemIcon>
                <ListItemText primary={t("apps_navigation.poi")}/>
            </ListItem>
            <Divider/>
            <ListItem button className={ `${current_page === "transfers" || current_page === "step-transfers" || current_page === "city-transfers" ? classes.orange : (transferCartControl(transfer_cart) ? classes.green : classes.grey)}` } onClick={() => {
                setIsOpen(null);
                router.push(`/${window.url_name}/apps/transfers`);
            }}>
                <ListItemIcon>
                    <TransferWithinAStation/>
                </ListItemIcon>
                <ListItemText primary={t('apps_navigation.transfer')}/>
            </ListItem>
            {
                !is_staging &&
                <>
                    <ListItem button className={ `${classes.grey}` }>
                        <ListItemIcon>
                            <SailingOutlined/>
                        </ListItemIcon>
                        <ListItemText primary={t('apps_navigation.cruise')}/>
                    </ListItem>
                </>
            }
            {
                !is_staging &&
                <>
                    <ListItem button className={ `${classes.grey}` }>
                        <ListItemIcon>
                            <DirectionsBoatOutlined fontSize={ "default" }/>
                        </ListItemIcon>
                        <ListItemText primary={t('apps_navigation.ferry')}/>
                    </ListItem>
                </>
            }
        </List>
    )
}
export default React.memo(MobileServicesHeader);