//---- Dependencies ----//
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

//---- Commons ----//

//---- Components ----//
import SeatTabLegend from "./SeatTabLegend.jsx";
import SeatTabMap from "./SeatTabMap.jsx";
import RenderSummarySeat from "./RenderSummarySeat.jsx";

//---- Actions ----//
import { ToggleSeatModal, SetSeatTab, SetConfirmation, SetSummary } from "../../../../Actions/FlightSearch";

//---- Class ----//
@connect((store) => {
    return {
        seat_modal: store.flight_search.seat_modal,
        seat_data: store.flight_search.seat_info,
        error_seat: store.flight_search.error_seat_data,
        flight_list: store.flight_search.flight_list,
        airports: store.flight_search.airports,
        flight_groups: store.flight.flight_groups,
        index_group: store.flight.index_group,
        confirmation_modal: store.flight_search.confirmation_modal,
        cancel_state: store.flight_search.cancel_state,
        summary_seat: store.flight_search.summary_seat,
        currency: store.trip.currency,
        language: store.header.tmp_language
    }
})
@withTranslation()
class SeatModal extends Component {
    constructor(props, context) {
        super(props, context);
    }
    componentDidUpdate(prevProps) {
        const { flight, seat_data, flight_groups, index_group, flight_list, confirmation_modal, flight_index, cancel_state, dispatch} = this.props;
        //let flight = flight_list[flight_index];
        if (seat_data) {
            const seat_segment = seat_data.flight[seat_data.tab].segment_key;
            let travelers_length = flight.group_passenger ? flight.group_passenger.travelers_list.length : flight_groups[index_group].travelers.length;
            flight.outbounds.map((outbound) => {
                outbound.legs.map((leg) => {
                    if (leg.segment_key === seat_segment) {
                        if (cancel_state === null && Object.keys(leg.seats).length === travelers_length) {
                            if (seat_data.tab !== (seat_data.flight.length - 1))
                                dispatch(SetConfirmation(true, null));
                            else
                                dispatch(SetSummary(true, false));
                        }
                    }
                });
            });
        }
    }
    renderTab() {
    	const { seat_data, flight_index, flight, dispatch } = this.props;
    	const flights = seat_data.flight;
    	return (
    		<div>
	    		<div className={ "detail-tab" }>
	    			{
                        flights.map((flight, index) => {
                            return (
                                <span className={ seat_data.tab === index ? "active" : "" } key={index} onClick={() => {dispatch(SetSeatTab(index))} } >
                                    {flight.origin}
                                    <i className={ "icon-103-long-arrow-pointing-to-the-right" }/>
                                    {flight.destination}
                                </span>
                            );
                        })
                    }
	    		</div>
    			<SeatTabLegend />
    			<SeatTabMap flight_index={flight_index} flight={flight}/>
    		</div>
    	)
    }
    redirect() {
        const { seat_data, dispatch } = this.props;
        dispatch(dispatch(SetConfirmation(false, null)));
        dispatch(SetSeatTab(seat_data.tab + 1));
    }
    render() {
        const { flight, error_seat, seat_modal, travelers_list, seat_data, dispatch, confirmation_modal, summary_seat, t } = this.props;
        console.log("this.props:", this.props);
        return (
            <Modal id={ "modal-seat" } isOpen={ seat_modal } toggle={() => { dispatch(ToggleSeatModal(false, null, null)); }} size={"lg"} backdrop={"static"}>
                <div>
                    <ModalHeader toggle={() => { dispatch(ToggleSeatModal(false, null, null)); }}>
                        <div id={"modal-seat-header"}>
                            <h1 className={"title-seat"}> { summary_seat ? t("flight_search.seats_summary") : t("flight_search.seat_modal_title")} </h1>
                        </div>
                    </ModalHeader>
                    <ModalBody className={"grey-bg"}>
                        <div>
                        	{
                        		seat_data === null && error_seat === null && (
                        			<div>
                                        <div className={ "text-center bold ft-font-25" }>
                                            {t("flight_search.seats_request")}
                                        </div>
                                        <div className={ "spinner-orange" } />
                                    </div>
                        		)
                        	}
                        	{
                        		seat_data !== null && error_seat === null && !summary_seat && (
                        			<div className={ "seat-map-container" }>
                        				{this.renderTab()}
                        			</div>
                        		)
                        	}
                        	{
                        		error_seat !== null && (
                        			<span>{error_seat}</span>
                        		)
                        	}
                            {
                                seat_data !== null && summary_seat && (
                                    <div>
                                        <RenderSummarySeat flight={flight} travelers_list={travelers_list}/>
                                    </div>
                                )
                            }
                        </div>
                        <Modal className={"seat-confirmation-modal"} isOpen={confirmation_modal} toggle={() => {dispatch(SetConfirmation(false, null))}} backdrop={"static"}>
                            <ModalHeader>
                                {
                                    seat_data !== null && (
                                        <span>
                                            {seat_data.flight[seat_data.tab].origin + " - " + seat_data.flight[seat_data.tab].destination}
                                        </span>
                                    )
                                }
                            </ModalHeader>
                            <ModalBody>
                                <span className="bold">
                                    {t("flight_search.check_passenger")}
                                </span>
                            </ModalBody>
                            <ModalFooter>
                                <button className="ft-cancel-btn uppercase" onClick={() => {dispatch(SetConfirmation(false, true))}}>{t("global.no")}</button>{' '}
                                <button className="btn-orange uppercase" onClick={() => {this.redirect()}}>{t("global.yes")}</button>
                            </ModalFooter>
                        </Modal>
                    </ModalBody>
                    {
                        seat_data !== null && summary_seat && (
                            <ModalFooter>
                                <button className="ft-cancel-btn uppercase" onClick={() => { dispatch(SetSummary(false)) } }>{t("global.edit")}</button>{' '}
                                <button className="btn-orange uppercase" onClick={() => { dispatch(ToggleSeatModal(false, null, null)) } }>{t("global.validate")}</button>
                            </ModalFooter>
                        )
                    }
                </div>
            </Modal>
        );
    }
}
export default SeatModal;
