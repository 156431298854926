import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDrag } from "react-dnd";
import { useTranslation } from "react-i18next";
import { Box, Button, Collapse, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { DragIndicator, Edit } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { EditorState } from "draft-js";
import { isString } from "lodash";
import { CartConstructionProductEditModal } from "./CartConstructionProductEditModal";
import { CartClientTimeModal } from "./CartClientTimeModal";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import RichEditor, { RichEditorStyleMap } from "../Menu/MaterialTripList/utils/editor/editor";
import { CartConstructionContentsContext } from "./utils/cartConstructionContentsContext";
import { customColors } from "../Menu/MaterialTripList/utils/editor/customColors";
import { useShowError } from "../Utils/showError";
import { useCartProductUpdate } from "./network/cartProductUpdate";
import { restoreRichEditorStateFromHtml } from "../Menu/MaterialTripList/utils/editor/restoreRichEditorStateFromHtml";
import { renderRichEditorContentHTML } from "../Menu/MaterialTripList/utils/editor/renderRichEditorContentHtml";
import { extractHotelDescription } from "./utils/extractHotelDescription";
import { htmlHasEmptyContent } from "../Menu/MaterialTripList/utils/htmlHasEmptyContent";
import { findHotel } from "../Menu/MaterialTripList/utils/findHotel";
import GetCookie from "../Common/Functions/GetCookie";
import { CartConstructionProductsTableItemProps } from "./CartConstructionProductsTableItem";
import { CartConstructionContentsFormProductsInputDragResult } from "./CartConstructionContentsFormProductsInput";
import { StatusBooking } from "../Itinerary/objects/statusBooking";
import { CartProductStatus } from "./CartConstructionProductsTableItemMenu";
import { Hotel } from "../Menu/MaterialTripList/objects/Hotel";
import { AppState } from "../../Reducers/Reducers";

export type CartConstructionContentsFormProductsInputItemProps = {
    typeIcon: JSX.Element,
    image: string,
    title: React.ReactNode,
    name: string,
    destination?: string,
    description?: string,
    localization: {
        locale: number,
        name: string,
        description: string,
        long_description: string | null,
        cancel_text_condition: string
    }[],
    dates: [string, string],
    isDayIncluded: boolean,
    item: CartConstructionProductsTableItemProps,
    onDrop?: (item: CartConstructionContentsFormProductsInputDragResult) => void,
    minimal?: boolean,
    itemDragType?: string
}

export function CartConstructionContentsFormProductsInputItem(props: CartConstructionContentsFormProductsInputItemProps): JSX.Element {
    const versionString = GetCookie("trip_id_version");
    const version = versionString ? parseInt(versionString) : -1;
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const context = useContext(CartConstructionContentsContext);
    const user = useSelector((state: AppState) => state.user?.user);
    const showProductsDescriptions = useSelector((state: AppState) => state.cartConstruction.showProductsDescriptions);
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const itinerary_type_details_data = useSelector((state: AppState) => state.itinerary_type.itinerary_type_details_data);
    const [actions, setActions] = useState<{
        shortDescription: {
            open: boolean,
            edit: boolean,
            input: EditorState | null,
        },
        longDescription: {
            open: boolean,
            edit: boolean,
            input: EditorState | null,
        }
    }>({
        shortDescription: {
            open: false,
            edit: false,
            input: null
        },
        longDescription: {
            open: false,
            edit: false,
            input: null
        }
    });
    const [showDetails, setShowDetails] = useState(false);
    const [editTime, setEditTime] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hotel, setHotel] = useState<Hotel | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const status = useMemo(() => {
        let status: CartProductStatus = 'DEFAULT';

        if (props.item.item.is_optional) {
            status = 'OPTION';
        } else if (props.item.item.is_hidden_for_traveler) {
            status = 'HIDE';
        } else if (props.item.item.is_hidden_for_traveler_count_price) {
            status = 'HIDE_COUNT';
        } else if (props.item.item.variant && itinerary_type_details_data && itinerary_type_details_data.variant_name) {
            status = 'VARIANT';
        }
        return status;
    }, [props.item.item, itinerary_type_details_data]);
    const hotelShortDescription = useMemo(() => {
        return extractHotelDescription({
            locale,
            description: props.description ?? null,
            localization: props.localization,
            hotelLocalization: props.item.type === 'accommodation' ?
                props.item.item.hotel[0]?.localization ?? null :
                null,
            hoteldefaultTexts: {
                situation: props.item.type === 'accommodation' ?
                    props.item.item.hotel[0]?.situation ?? null :
                    null,
                room_desc: props.item.type === 'accommodation' ?
                    props.item.item.hotel[0]?.room_desc ?? null :
                    null,
                restoration: props.item.type === 'accommodation' ?
                    props.item.item.hotel[0]?.restoration ?? null :
                    null,
                children: props.item.type === 'accommodation' ?
                    props.item.item.hotel[0]?.children ?? null :
                    null
            },
            hotel
        });
    }, [
        props.localization,
        props.description,
        locale,
        hotel
    ]);
    const longDescription = useMemo(() => {
        const product = props.item.item as {
            localization: {
                locale: number,
                long_description: string | null,
            }[] | null,
            custom_product?: {
                long_description: string | null,
                localization: {
                    locale: number,
                    long_description: string | null,
                }[] | null
            }
        };
        const customProductLocalization = product.custom_product?.localization?.find((item) => {
            return item.locale === locale;
        });
        const localization = product.localization?.find((item) => {
            return item.locale === locale;
        });
        return localization?.long_description ??
               customProductLocalization?.long_description ??
               product.custom_product?.long_description ?? '';
    }, [locale, props.item.item]);
    const [collected, dragHandle, previewHandle] = useDrag<
        CartConstructionContentsFormProductsInputDragResult,
        unknown,
        { isDragging: boolean }
    >(() => ({
        type: props.itemDragType ?? 'cart-construction-contents-product',
        item: {
            product: props.item
        },
        collect(monitor) {
            return {
                isDragging: monitor.isDragging()
            };
        },
        end(item) {
            setTimeout(() => {
                containerRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                });
            }, 250);
            if (props.onDrop) {
                props.onDrop(item);
            }
        }
    }), [props.item, props.itemDragType, props.onDrop]);
    const showError = useShowError();
    const update = useCartProductUpdate({
        onTrigger() {
            setLoading(true);
        },
        onSuccess() {
            enqueueSnackbar(
                t<string>('cart-material.provider-quotation-product-updated'),
                { variant: 'success' }
            );
            onChangeText('shortDescription', 'edit', false);
            onChangeText('longDescription', 'edit', false);
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    function onChangeText<
        K extends keyof typeof actions,
        A extends keyof typeof actions[K]
    >(key: K, action: A, value: typeof actions[K][A]) {
        setActions((state) => ({
            ...state,
            [key]: {
                ...state[key],
                [action]: value
            }
        }));
    }

    const onSaveDescription = async () => {
        const localization = props.localization.find((item) => {
            return item.locale === locale;
        });
        const styles = {
            ...RichEditorStyleMap,
            ...(quotationCode ? customColors[quotationCode] : null)
        };
        const shortDescription = renderRichEditorContentHTML(
            styles,
            actions.shortDescription.input?.getCurrentContent() ??
            EditorState.createEmpty().getCurrentContent()
        );
        const longDescription = renderRichEditorContentHTML(
            styles,
            actions.longDescription.input?.getCurrentContent() ??
            EditorState.createEmpty().getCurrentContent()
        );
        await update(
            version,
            {
                id: props.item.item.id,
                roomIds: props.item.type === 'accommodation' ?
                    props.item.item.rooms.map((room) => {
                        return room.id;
                    }) :
                    [],
                type: props.item.type,
                isCustom: props.item.item.is_custom
            },
            {
                localization: props.localization.filter((item) => {
                    return item.locale !== locale;
                }).concat([
                    localization ?
                        { ...localization, description: shortDescription, long_description: longDescription } :
                        {
                            locale,
                            name: props.name,
                            description: shortDescription,
                            long_description: longDescription,
                            cancel_text_condition: ''
                        }
                ])
            }
        );
    };

    const onEditDate = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        setEditTime(true);
    };

    useEffect(() => {
        const styles = {
            ...RichEditorStyleMap,
            ...(quotationCode ? customColors[quotationCode] : null)
        };
        onChangeText(
            'shortDescription',
            'input',
            restoreRichEditorStateFromHtml(
                styles,
                hotelShortDescription
            )
        );
    }, [actions.shortDescription.edit, hotelShortDescription]);

    useEffect(() => {
        const styles = {
            ...RichEditorStyleMap,
            ...(quotationCode ? customColors[quotationCode] : null)
        };
        onChangeText(
            'longDescription',
            'input',
            restoreRichEditorStateFromHtml(
                styles,
                longDescription
            )
        );
    }, [actions.longDescription.edit, longDescription]);

    useEffect(() => {
        onChangeText('shortDescription', 'open', showProductsDescriptions);
    }, [showProductsDescriptions]);

    useEffect(() => {
        onChangeText('longDescription', 'open', showProductsDescriptions);
    }, [showProductsDescriptions]);

    useEffect(() => {
        (async () => {
            if (
                tripId &&
                props.item.type === 'accommodation' &&
                props.item.item.hotel[0]?.id &&
                htmlHasEmptyContent(
                    props.item.item.hotel[0].localization?.find((item) => {
                        return item.locale === locale;
                    })?.situation ?? ''
                ) &&
                htmlHasEmptyContent(
                    props.item.item.hotel[0].localization?.find((item) => {
                        return item.locale === locale;
                    })?.room_desc ?? ''
                ) &&
                htmlHasEmptyContent(
                    props.item.item.hotel[0].localization?.find((item) => {
                        return item.locale === locale;
                    })?.restoration ?? ''
                ) &&
                htmlHasEmptyContent(
                    props.item.item.hotel[0].localization?.find((item) => {
                        return item.locale === locale;
                    })?.children ?? ''
                )
            ) {
                setHotel(
                    await findHotel(
                        props.item.item.hotel[0]!.id,
                        tripId
                    )
                );
            }
        })();
    }, [props.item.type, props.item.item, tripId]);

    return (
        <>
            <div ref={containerRef}>
                <Stack
                    ref={previewHandle}
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{
                        opacity: collected.isDragging ? 0.5 : 1,
                        backgroundColor: status === 'OPTION' ? '#AFD7F8' : undefined
                    }}
                >
                    <IconButton
                        ref={dragHandle}
                        sx={{
                            padding: 0,
                            paddingRight: 0.75,
                            marginLeft: !props.minimal ? 0.5 : undefined,
                            cursor: 'move',
                            visibility: props.item.item.booking_status?.status_booking !== StatusBooking.CONFIRMED &&
                                props.item.type !== 'accommodation' &&
                                props.item.type !== 'flight' &&
                                props.item.type !== 'manual-flight' &&
                                (
                                    !props.minimal ||
                                    props.item.type !== 'manual-accommodation'
                                ) &&
                                !props.isDayIncluded ?
                                'visible' :
                                'hidden'
                        }}
                        disableRipple
                    >
                        <DragIndicator />
                    </IconButton>
                    {props.typeIcon}
                    <Box
                        onClick={() => setShowDetails(true)}
                        sx={{
                            "cursor": 'pointer',
                            "whiteSpace": props.minimal ? 'nowrap' : undefined,
                            "overflow": props.minimal ? 'hidden' : undefined,
                            "textOverflow": 'ellipsis',
                            '&:hover': {
                                textDecoration: 'underline'
                            }
                        }}
                    >
                        {
                            isString(props.title) &&
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                lineHeight="1"
                                component="span"
                            >
                                {props.title}
                            </Typography>
                        }
                        {
                            !isString(props.title) &&
                            props.title
                        }
                    </Box>
                    {
                        status === 'OPTION' &&
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                            lineHeight="1"
                            component="span"
                            sx={{ whiteSpace: 'nowrap', marginRight: '12px !important' }}
                        >
                            - {t<string>('cart-material.in-option').toLowerCase()}
                        </Typography>
                    }
                    {
                        props.item.type !== 'accommodation' &&
                        props.item.type !== 'manual-accommodation' &&
                        !props.isDayIncluded &&
                        <Typography
                            variant="h6"
                            lineHeight="1"
                            component="span"
                            fontWeight={400}
                            sx={{ whiteSpace: 'nowrap' }}
                        >
                            {' '}({window.moment.utc(props.dates[0]).format('L HH:mm')})
                        </Typography>
                    }
                    {
                        (
                            props.item.type === 'accommodation' ||
                            props.item.type === 'car' ||
                            props.item.type === 'poi' ||
                            props.item.type === 'transfer' ||
                            props.item.type === 'manual' ||
                            props.item.type === 'manual-accommodation'
                        ) &&
                        (
                            props.item.type.includes('manual') ||
                            props.item.item.is_custom ||
                            props.item.item.booking_status
                        ) &&
                        !props.minimal &&
                        <IconButton size="small" onClick={onEditDate}>
                            <Edit />
                        </IconButton>
                    }
                </Stack>
                {
                    !props.minimal &&
                    !props.isDayIncluded &&
                    <Box sx={{ marginTop: 2 }}>
                        {
                            context.mode === 'roadbook' &&
                            <Typography fontSize={16} fontWeight={100} color="GrayText">
                                {t('cart-material.cart-construction-short-text')}
                            </Typography>
                        }
                        {
                            !actions.shortDescription.edit &&
                            <>
                                {
                                    !actions.shortDescription.open &&
                                    <Typography
                                        sx={{
                                            "display": '-webkit-box',
                                            '-webkit-line-clamp': '2',
                                            '-webkit-box-orient': 'vertical',
                                            "overflow": 'hidden',
                                            "textOverflow": 'ellipsis',
                                            "fontSize": 16
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: hotelShortDescription
                                        }}
                                    />
                                }
                                <Collapse in={actions.shortDescription.open}>
                                    <Typography
                                        sx={{ fontSize: 16 }}
                                        dangerouslySetInnerHTML={{
                                            __html: hotelShortDescription
                                        }}
                                    />
                                </Collapse>
                            </>
                        }
                        {
                            actions.shortDescription.edit &&
                            actions.shortDescription.input &&
                            <RichEditor
                                editorState={actions.shortDescription.input}
                                setEditorState={(editorState: EditorState) => onChangeText('shortDescription', 'input', editorState)}
                                pictures={[]}
                                values={{}}
                                controlledState
                            />
                        }
                        {
                            !actions.shortDescription.edit &&
                            <DescriptionActions
                                open={actions.shortDescription.open}
                                onToggleText={(open) => onChangeText('shortDescription', 'open', open)}
                                onEdit={() => onChangeText('shortDescription', 'edit', true)}
                            />
                        }
                        {
                            actions.shortDescription.edit &&
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{ marginTop: 1, marginBottom: 2 }}
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={onSaveDescription}
                                >
                                    {t<string>('shared.save')}
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => onChangeText('shortDescription', 'edit', false)}
                                >
                                    {t<string>('shared.cancel')}
                                </Button>
                            </Stack>
                        }
                    </Box>
                }
                {
                    !props.minimal &&
                    !props.isDayIncluded &&
                    context.mode === 'roadbook' &&
                    <Box sx={{ marginTop: 2 }}>
                        <Typography fontSize={16} fontWeight={100} color="GrayText">
                            {t('cart-material.cart-construction-long-text')}
                        </Typography>
                        {
                            !actions.longDescription.edit &&
                            <>
                                {
                                    !actions.longDescription.open &&
                                    <Typography
                                        sx={{
                                            "display": '-webkit-box',
                                            '-webkit-line-clamp': '2',
                                            '-webkit-box-orient': 'vertical',
                                            "overflow": 'hidden',
                                            "textOverflow": 'ellipsis',
                                            "fontSize": 16
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: longDescription
                                        }}
                                    />
                                }
                                <Collapse in={actions.longDescription.open}>
                                    <Typography
                                        sx={{ fontSize: 16 }}
                                        dangerouslySetInnerHTML={{
                                            __html: longDescription
                                        }}
                                    />
                                </Collapse>
                            </>
                        }
                        {
                            actions.longDescription.edit &&
                            actions.longDescription.input &&
                            <RichEditor
                                editorState={actions.longDescription.input}
                                setEditorState={(editorState: EditorState) => onChangeText('longDescription', 'input', editorState)}
                                pictures={[]}
                                values={{}}
                                controlledState
                            />
                        }
                        {
                            !actions.longDescription.edit &&
                            <DescriptionActions
                                open={actions.longDescription.open}
                                onToggleText={(open) => onChangeText('longDescription', 'open', open)}
                                onEdit={() => onChangeText('longDescription', 'edit', true)}
                            />
                        }
                        {
                            actions.longDescription.edit &&
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{ marginTop: 1, marginBottom: 2 }}
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={onSaveDescription}
                                >
                                    {t<string>('shared.save')}
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => onChangeText('longDescription', 'edit', false)}
                                >
                                    {t<string>('shared.cancel')}
                                </Button>
                            </Stack>
                        }
                    </Box>
                }
            </div>
            <CartConstructionProductEditModal
                open={showDetails}
                onClose={() => setShowDetails(false)}
                {...props.item}
            />
            {
                user?.client_full?.type !== 2 &&
                editTime &&
                props.item.type !== 'manual' &&
                props.item.type !== 'manual-accommodation' &&
                props.item.type !== 'manual-flight' &&
                props.item.type !== 'flight' &&
                props.item.type !== 'assistance' &&
                <CartClientTimeModal
                    type="normal"
                    product={props.item.item}
                    onClose={() => setEditTime(false)}
                />
            }
            {
                user?.client_full?.type !== 2 &&
                editTime &&
                (
                    props.item.type === 'manual' ||
                    props.item.type === 'manual-accommodation'
                ) &&
                <CartClientTimeModal
                    type="manual"
                    product={props.item.item}
                    onClose={() => setEditTime(false)}
                />
            }
            <LoadingBackDrop open={loading} />
        </>
    );
}

type DescriptionActionsProps = {
    open: boolean,
    onToggleText: (open: boolean) => void,
    onEdit: () => void
}

function DescriptionActions(props: DescriptionActionsProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
        >
            <Typography
                color="primary"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => props.onToggleText(!props.open)}
            >
                {
                    props.open ?
                        t<string>('cart-material.cart-construction-see-less') :
                        t<string>('cart-material.cart-construction-see-more')
                }
            </Typography>
            <Tooltip title={t<string>('cart-material.cart-construction-edit-product')}>
                <IconButton
                    sx={{
                        fontSize: '20px',
                        padding: 0.5
                    }}
                    onClick={props.onEdit}
                >
                    <Edit fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}
