import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import DestinationSelected from "../Functions/DestinationSelected";

const useStyles = makeStyles(theme => ({
	searchIcon: {
		paddingLeft: 9
	},
	inputStepper: {
		"& .mui-jss-MuiOutlinedInput-input": {
			padding: 10
		},
		"& .mui-jss-MuiOutlinedInput-root": {
			position: "relative",
			borderRadius: 20
		}
	},
	orange: {
		color: "#E6592F"
	},
	containerStepper: {
		textAlign: "left",
		paddingLeft: 16,
		paddingRight: 16
	}
}));

let source = null;

const MobileDestinationSearch = ({dialog_type, setDialogType}) => {
    const classes = useStyles();
	const theme = useTheme();
	const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
	const { t } = useTranslation();
	const [error, setError] = useState(false);
  	const [inputValue, setInputValue] = useState('');
  	const [options, setOptions] = useState([]);
  	const [loading, setLoading] = useState(false);
	const { headers } = CheckBeforeRequest();

	const dispatch = useDispatch();
	const google = useSelector((store) => {
		return store.base.google;
	});
	const map = useSelector((store) => {
		return store.base.map;
	});
	const destination_filter = useSelector((store) => {
		return store.itinerary.filter_destination;
	});
	useEffect(() => {
	    if (inputValue.length >= 3) {
	    	if (source !== null) {
	    		source.cancel('Operation canceled by the user.');
	    	}
		    //Save the cancel token for the current request
		    source = axios.CancelToken.source();
	    	axios({
			    method: "GET",
			    headers: headers,
			    url:  `${API_HREF}client/${window.id_owner}/destinations/?limit=5&reduced=true&ordering=current_version__type&search=${inputValue}&visibility__in=PUBLIC,PRIVATE_TO`,
			    cancelToken: source.token
			}).then(function (response) {
				let data = response.data.results;
				if (data.length > 0) {
					setOptions(Object.keys(data).map((key) => data[key]));
					setLoading(false);
				}
			}).catch(function (error) {
				if (!axios.isCancel(error)) {
				    console.log('Request canceled', error.message);
					setError(true);
				}
	       		//TODO: show snackbar error
	   		});
	    }
  	}, [inputValue]);
	return (
        <Fragment>
            <div className={classes.containerStepper}>
                <TextField
                    error={error}
                    placeholder={t("header.destination")}
                    variant={"outlined"}
                    fullWidth={!isTablet}
                    autoFocus={dialog_type === "destination"}
                    className={classes.inputStepper}
                    onChange={(e) => {
                        setLoading(true);
                        setError(false);
                        setInputValue(e.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position={"start"}>
                                <SearchIcon className={`${classes.searchIcon} ${classes.orange}`}/>
                            </InputAdornment>
                        )
                    }}
                />
            </div>
            {
                options.length > 0 && (
                    <List>
                    {
                        options.map((option, option_index) => {
                            return (
                                <ListItem
                                    key={option_index}
                                    button
                                    onClick={() => {
                                        DestinationSelected(option, dispatch, destination_filter, google, map);
                                        setOptions([]);
                                        setInputValue('');
                                        setDialogType(null);
                                    }}
                                >
                                    {option.data.name}
                                </ListItem>

                            )
                        })
                    }
                    </List>
                )
            }
        </Fragment>
	)
}
export default MobileDestinationSearch;