export function downloadBlob(filename) {
    return (blob) => {
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '__blank';
        anchor.download = filename;
        anchor.click();
        URL.revokeObjectURL(url);
    };
}
