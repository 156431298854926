import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from '@mui/material/Container';
import {
    Button,
    FormControl,
    Grid, InputLabel, MenuItem,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from '@mui/material';
import { SetCart } from '../../Actions/Accommodation';
import { isNumber } from 'lodash';
import { SetManualCart } from '../../Actions/Cart';
import CheckResponse from '../Flight/FlightSelected/Functions/CheckResponse';
import { SetFlightCart } from '../../Actions/Flight';
import { SetCarsCart } from '../../Actions/CarsSearch';
import { ConcatTransfersCart } from '../../Actions/Transfers';
import { SetPoiCart } from '../../Actions/Poi';
import { useDispatch, useSelector } from 'react-redux';
import { SetDepartureDestination, SetItinerary, SetReturnDestination } from '../../Actions/Itinerary';
import { format, parseISO } from 'date-fns';
import { useCartTotalPrices } from '../CartMaterial/utils/cartTotalPrices';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from "notistack";
import { utcToZonedTime } from 'date-fns-tz';
let GoogleMapsLoader = require('google-maps');

const AgencyOfflineMargin = (props) => {
    console.log("props : ", props);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    let version_data = null;
    let trip_data = null;
    const { t, i18n } = useTranslation();
    const itinerary_list = useSelector(state => state.itinerary.itinerary_list);
    const all_data = useSelector(state => state.trip.all_data);
    const departure_destination = useSelector(state => state.itinerary.departure_destination);
    const return_destination = useSelector(state => state.itinerary.return_destination);
    const flights = useSelector(state => state.flight.cart);
    const manual_products = useSelector(state => state.cart.manual_item_list);
    const trip_currency = useSelector((store) => store.trip.currency);
    const [agencyMargin, setAgencyMargin] = useState(0);
    const [agencyMarginPer, setAgencyMarginPer] = useState(0);
    const [agencyMarginType, setAgencyMarginType] = useState("FIX");
    const [quotationText, setQuotationText] = useState("");
    const [travelerCount, setTravelerCount] = useState(0);
    const prices = useCartTotalPrices(true, true);
    const config = JSON.parse(localStorage.getItem("config"));
    console.log("config : ", config);
    // const flights = useSelector(state => state.flight.cart);
    const { token, trip_id, trip_token, trip_version } = props.routeParams;
    const onQuotationTextChange = (event) => {
        setQuotationText(event.target.value);
    };
    const onUpdateMargin = (event) => {
        setAgencyMargin(event.target.value);
    };
    const onUpdateMarginPer = (event) => {
        setAgencyMarginPer(event.target.value);
    };
    const onChangeMarginType = (event) => {
        setAgencyMarginType(event.target.value);
    };
    const onSaveChange = () => {
        const requests = [];
        requests.push(
            axios({
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_version}/agency_modify_price/`,
                data: {
                    agency_modification_token: token,
                    percentage_margin: agencyMarginType === "PER" ? agencyMarginPer : undefined,
                    value_margin: agencyMarginType === "FIX" ? agencyMargin : undefined
                }
            })
        );
        requests.push(
            axios({
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_version}/agency_add_quotation_text/`,
                data: {
                    agency_modification_token: token,
                    quotation_text: quotationText
                }
            })
        );
        axios.all([...requests]).then(axios.spread((...responses) => {
            enqueueSnackbar("trip updated", {
                variant: "success"
            });
        }));
    };
    const onSeeQuotation = () => {
        let a = document.createElement("a");
        a.href = `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_version}/quotation/?generate_for_target=y&generate_with_practical_information=y&generate_with_cgv=y`;
        a.target = "_black";
        a.dispatchEvent(new MouseEvent('click'));
    };
    useEffect(() => {
        let itinerary_data = null;
        let accommodation_data = null;
        let poi_data = null;
        let transfers_data = null;
        let flight_data = null;
        let providers_data = null;
        let manual_providers_data = null;
        let travelers_data = null;
        let cars_data = null;
        let manual_product_data = null;
        let has_stacked_items = false;
        let from_circuit_id = null;
        dispatch({ type: 'TRANSFERS_SET_CART', payload: { cart: [] } });
        axios({
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/?token=${trip_token}`
        }).then((response) => {
            console.log("trip : ", response.data);
            dispatch({
                type: "TRIP_SET_ALL_DATA",
                payload: {
                    data: response.data
                }
            });
            trip_data = response.data;
            version_data = response.data.data.find(version => version.id === response.data.current_version);
            if (version_data) {
                setTravelerCount(version_data.travelers.length);
            }
            if (version_data && version_data.quotation_brief) {
                setQuotationText(version_data.quotation_brief);
            }
            axios({
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_version}/itinerary/?token=${trip_token}`
            }).then((response) => {
                console.log("itinerary : ", response.data);
                itinerary_data = response.data;
                console.log("itinerary_data up:", itinerary_data);
                let departure_destination = {
                    city: null,
                    country: null,
                    place_id: null,
                    lat: null,
                    lng: null,
                    start_date: null,
                    end_date: null,
                    retrieve: true
                };
                let return_destination = {
                    city: null,
                    country: null,
                    place_id: null,
                    lat: null,
                    lng: null,
                    start_date: null,
                    end_date: null,
                    retrieve: true
                };
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].step_type === "START") {
                        departure_destination.place_id = response.data[i].places_id;
                        departure_destination.start_date = response.data[i].start_date;
                        departure_destination.end_date = response.data[i].end_date;
                    } else if (response.data[i].step_type === "END") {
                        return_destination.place_id = response.data[i].places_id;
                        return_destination.start_date = response.data[i].start_date;
                        return_destination.end_date = response.data[i].end_date;
                    }
                }
                let key = "";
                let config = JSON.parse(localStorage.getItem("config"));
                for (let i = 0; i < config.keys.length; i++) {
                    if (config.keys[i].identifier === "google_api") {
                        key = config.keys[i].value;
                    }
                }
                let completed_departure_destination = null;
                let completed_return_destination = null;
                GoogleMapsLoader.KEY = key;
                GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];
                GoogleMapsLoader.LANGUAGE = "fr";
                GoogleMapsLoader.load(function (google) {
                    let geocoder = new google.maps.Geocoder();
                    geocoder.geocode({ placeId: departure_destination.place_id }, function (results, status) {
                        if (status === "OK") {
                            completed_departure_destination = {
                                city: null,
                                country: null,
                                place_id: departure_destination.place_id,
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng(),
                                start_date: departure_destination.start_date,
                                end_date: departure_destination.end_date,
                                retrieve: false
                            };
                            if (results[0].address_components !== undefined) {
                                results[0].address_components.forEach(function (c) {
                                    switch (c.types[0]) {
                                        case 'neighborhood': case 'locality':
                                            completed_departure_destination.city = c.long_name;
                                            break;
                                        case 'country':
                                            completed_departure_destination.country = c.long_name;
                                            break;
                                    }
                                });
                            }
                            dispatch(SetDepartureDestination(completed_departure_destination));
                            setTimeout(function () {
                            }, 1000);
                        }
                    });
                    geocoder.geocode({ placeId: return_destination.place_id }, function (results, status) {
                        if (status === "OK") {
                            completed_return_destination = {
                                city: null,
                                country: null,
                                place_id: return_destination.place_id,
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng(),
                                start_date: return_destination.start_date,
                                end_date: return_destination.end_date,
                                retrieve: false
                            };
                            if (results[0].address_components !== undefined) {
                                results[0].address_components.forEach(function (c) {
                                    switch (c.types[0]) {
                                        case 'neighborhood': case 'locality':
                                            completed_return_destination.city = c.long_name;
                                            break;
                                        case 'country':
                                            completed_return_destination.country = c.long_name;
                                            break;
                                    }
                                });
                            }
                            dispatch(SetReturnDestination(completed_return_destination));
                            setTimeout(function () {
                            }, 1000);
                        }
                    });
                });
                dispatch(SetItinerary(response.data));
            });
            let request_product = [];
            request_product.push(
                axios({
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_version}/accommodation/?token=${trip_token}`
                })
            );
            request_product.push(
                axios({
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_version}/assistance/?token=${trip_token}`
                })
            );
            request_product.push(
                axios({
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_version}/custom-products/?token=${trip_token}`
                })
            );
            request_product.push(
                axios({
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_version}/manual-products/?token=${trip_token}`
                })
            );
            request_product.push(
                axios({
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_version}/flight/?token=${trip_token}`
                })
            );
            request_product.push(
                axios({
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_version}/transfers/?token=${trip_token}`
                })
            );
            request_product.push(
                axios({
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_version}/car/?token=${trip_token}`
                })
            );
            request_product.push(
                axios({
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_version}/insurance/?token=${trip_token}`
                })
            );
            axios.all([...request_product]).then(axios.spread((...responses) => {
                responses.forEach((response) => {
                    console.log('response:', response);
                    let url = response.config.url;
                    if (url.startsWith('/')) {
                        url = window.location.origin + url;
                    }
                    const configUrl = new URL(url);
                    console.log('configUrl:', configUrl);
                    if (configUrl.pathname.includes("accommodation")) {
                        accommodation_data = response.data;
                        dispatch(SetCart(response.data));
                        response.data.map(room => {
                            if (isNumber(room.stack_number)) {
                                has_stacked_items = true;
                            }
                        });
                    }
                    if (configUrl.pathname.includes("manual-products")) {
                        if (response.data.length > 0) {
                            let products = [];
                            response.data.map((product) => {
                                if (Object.keys(product).length > 0) {
                                    if (isNumber(product.stack_number)) {
                                        has_stacked_items = true;
                                    }
                                    products.push(product);
                                }
                            });
                            dispatch(SetManualCart(products));
                        }
                        manual_product_data = response.data;
                    }
                    if (configUrl.pathname.includes("flight")) {
                        flight_data = response.data;
                        if (response.data.length > 0) {
                            let arr = CheckResponse(response.data, version_data.end_date);
                            arr.map((el) => {
                                if (isNumber(el.stack_number)) {
                                    has_stacked_items = true;
                                }
                            });
                            dispatch(SetFlightCart(arr));
                        }
                    }
                    if (configUrl.pathname.includes("car")) {
                        cars_data = [...response.data];
                        if (cars_data.length > 0) {
                            cars_data = cars_data.map((item) => {
                                if (isNumber(item.stack_number)) {
                                    has_stacked_items = true;
                                }

                                item.detail = false;
                                item.charges.sort((a, b) => {
                                    return ((parseFloat(a.amount) < parseFloat(b.amount)) ? -1 : ((parseFloat(a.amount) > parseFloat(b.amount)) ? 1 : 0));
                                });

                                return item;
                            });
                            dispatch(SetCarsCart(cars_data));
                        }
                    }

                    if (configUrl.pathname.includes("transfers")) {
                        let arr = [];
                        let tmp_response = [...response.data];
                        tmp_response.map((item) => {
                            if (isNumber(item.stack_number)) {
                                has_stacked_items = true;
                            }

                            arr.push(item);
                        });
                        dispatch(ConcatTransfersCart(arr));
                        if (transfers_data === null) {
                            transfers_data = arr;
                        }
                    }
                    if (configUrl.pathname.includes("custom-products")) {
                        let tmp_poi = [];
                        let tmp_transfers = [];
                        let tmp_response = [...response.data];
                        tmp_response.map((item) => {
                            if (isNumber(item.stack_number)) {
                                has_stacked_items = true;
                            }

                            if (item.product_type === 4) {
                                tmp_transfers.push(item);
                            } else {
                                tmp_poi.push(item);
                            }
                        });
                        dispatch(SetPoiCart(tmp_poi));
                        dispatch(ConcatTransfersCart(tmp_transfers));
                        poi_data = tmp_poi;
                        if (transfers_data === null) {
                            transfers_data = tmp_transfers;
                        }
                    }
                    if (configUrl.pathname.includes("assistance") && response.data.length > 0) {
                        dispatch({ type: 'CART_ASSISTANCE_SET_CART', payload: response.data });
                    }
                    if (configUrl.pathname.includes("insurance") && response.data.results.length > 0) {
                        dispatch({ type: 'CART_ASSISTANCE_SET_CART', payload: response.data.results });
                    }
                });
                dispatch({ type: "BASE_SET_ALL_CART_DONE", payload: true });
                dispatch({ type: "CART_SET_HAS_STACKED_ITEMS", payload: has_stacked_items });
            }));
        });
    }, []);
    useEffect(() => {
        const margin_product = manual_products.find(item => item.product_type === 21);
        if (margin_product) {
            console.log("margin_product : ", margin_product);
            const price = margin_product.prices.find(price => price.is_tva);
            if (price && parseFloat(price.selling_price) > 0) {
                setAgencyMargin(parseFloat(price.selling_price));
                setAgencyMarginType("FIX");
            }
        } else {
            setAgencyMarginType("PER");
        }
    }, [manual_products]);
    useEffect(() => {
        if (prices && prices.margin) {
            setAgencyMarginPer(prices.margin.percent);
            setAgencyMargin(prices.margin.value);
        }
    }, [prices]);
    console.log("prices : ", prices);
    return (
        <Container style={{
            marginTop: 32,
            marginBottom: 32
        }}>
            <Grid container justifyContent={ 'center' } alignItems={ 'center' }>
                {
                    all_data?.owner?.logo?.url &&
                    <Grid item container xs={ 12 } justifyContent={ 'center' } alignItems={ 'center' }>
                        <Grid item xs={ 4 }>
                            <img src={ all_data.owner?.logo.url }/>
                        </Grid>
                    </Grid>
                }
                <Grid item xs>
                    <Paper variant={ 'outlined' } style={{
                        marginTop: 32
                    }}>
                        <div style={{
                            padding: 16,
                            backgroundColor: 'cornflowerblue',
                            borderTopRightRadius: 4,
                            borderTopLeftRadius: 4
                        }}>
                            <Typography color={ "white" }>Itineraro</Typography>
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Check-in</TableCell>
                                        <TableCell>Check-out</TableCell>
                                        <TableCell>Località</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        itinerary_list.map((step) => {
                                            if (step.step_type === "START" && departure_destination) {
                                                return (
                                                    <TableRow
                                                        key={step.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell/>
                                                        <TableCell>{ format(utcToZonedTime(parseISO(step.end_date), "UTC"), "dd/MM/yyyy | HH:mm") }</TableCell>
                                                        <TableCell>{ departure_destination.city }</TableCell>
                                                    </TableRow>
                                                );
                                            } else if (step.step_type === "END" && return_destination) {
                                                return (
                                                    <TableRow
                                                        key={step.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{ format(utcToZonedTime(parseISO(step.start_date), "UTC"), "dd/MM/yyyy | HH:mm") }</TableCell>
                                                        <TableCell/>
                                                        <TableCell>{ departure_destination.city }</TableCell>
                                                    </TableRow>
                                                );
                                            } else if (step.step_type === "STEP") {
                                                return (
                                                    <TableRow
                                                        key={step.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{ format(utcToZonedTime(parseISO(step.start_date), "UTC"), "dd/MM/yyyy | HH:mm") }</TableCell>
                                                        <TableCell>{ format(utcToZonedTime(parseISO(step.end_date), "UTC"), "dd/MM/yyyy | HH:mm") }</TableCell>
                                                        <TableCell>{ step.destination.data.name }</TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    {/*<Paper variant={ 'outlined' } style={{*/}
                    {/*    marginTop: 32*/}
                    {/*}}>*/}
                    {/*    <div style={{*/}
                    {/*        padding: 16,*/}
                    {/*        backgroundColor: 'cornflowerblue',*/}
                    {/*        borderTopRightRadius: 4,*/}
                    {/*        borderTopLeftRadius: 4*/}
                    {/*    }}>*/}
                    {/*        <Typography>Prodoto</Typography>*/}
                    {/*    </div>*/}
                    {/*    <TableContainer component={Paper}>*/}
                    {/*        <Table sx={{ minWidth: 650 }} aria-label="simple table">*/}
                    {/*            <TableHead>*/}
                    {/*                <TableRow>*/}
                    {/*                    <TableCell>Check-in</TableCell>*/}
                    {/*                    <TableCell>Check-out</TableCell>*/}
                    {/*                    <TableCell>Località</TableCell>*/}
                    {/*                    <TableCell>Servizio</TableCell>*/}
                    {/*                    <TableCell>Nome</TableCell>*/}
                    {/*                </TableRow>*/}
                    {/*            </TableHead>*/}
                    {/*            <TableBody>*/}
                    {/*                {*/}
                    {/*                    flights.map(flight => (*/}
                    {/*                        <TableRow*/}
                    {/*                            key={flight.id}*/}
                    {/*                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}*/}
                    {/*                        >*/}
                    {/*                            <TableCell>{ format(new Date(flight.start_date), "dd/MM/yyyy | HH:mm") }</TableCell>*/}
                    {/*                            <TableCell>{ format(new Date(flight.end_date), "dd/MM/yyyy | HH:mm") }</TableCell>*/}
                    {/*                            <TableCell></TableCell>*/}
                    {/*                            <TableCell>Volo</TableCell>*/}
                    {/*                            <TableCell></TableCell>*/}
                    {/*                        </TableRow>*/}
                    {/*                    ))*/}
                    {/*                }*/}
                    {/*            </TableBody>*/}
                    {/*        </Table>*/}
                    {/*    </TableContainer>*/}
                    {/*</Paper>*/}
                    <Paper variant={ 'outlined' } style={{
                        marginTop: 32
                    }}>
                        <div style={{
                            padding: 16,
                            backgroundColor: 'cornflowerblue',
                            borderTopRightRadius: 4,
                            borderTopLeftRadius: 4
                        }}>
                            <Typography color={ "white" }>Quotazione : { all_data?.trip_reference }</Typography>
                        </div>
                        <Grid container spacing={ 2 } style={{
                            padding: 16
                        }}>
                            <Grid item xs={ 12 }>
                                <Typography>Passeggeri : { travelerCount }</Typography>
                            </Grid>
                            <Grid item xs={ 12 }>
                                <Typography>Prezzo totale pratica netto : { prices && trip_currency && new Intl.NumberFormat(i18n.language, { style: 'currency', currency: trip_currency.iso_code }).format(prices.totalCartCost - agencyMargin) }</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper variant={ 'outlined' } style={{
                        marginTop: 32
                    }}>
                        <div style={{
                            padding: 16,
                            backgroundColor: 'cornflowerblue',
                            borderTopRightRadius: 4,
                            borderTopLeftRadius: 4
                        }}>
                            <Typography color={ "white" }>Modifica il preventivo cliente</Typography>
                        </div>
                        <Grid container spacing={ 2 } style={{
                            padding: 16
                        }}>
                            <Grid item xs={ 4 }>
                                {
                                    agencyMarginType === "FIX" ? (
                                        <TextField fullWidth type="number" label="Margine in valore" value={ agencyMargin } onChange={ onUpdateMargin }/>
                                    ) : (
                                        <TextField fullWidth type="number" label="Margine in percentuale" value={ agencyMarginPer } onChange={ onUpdateMarginPer }/>
                                    )
                                }
                            </Grid>
                            <Grid item xs={ 4 }>
                                <FormControl fullWidth>
                                    <InputLabel>
                                        Tipo di margine
                                    </InputLabel>
                                    <Select
                                        label="Tipo di margine"
                                        value={ agencyMarginType }
                                        onChange={ onChangeMarginType }
                                    >
                                        <MenuItem value="FIX">
                                            Margine in valore
                                        </MenuItem>
                                        <MenuItem value="PER">
                                            Margine in percentuale
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={ 12 }>
                                <TextField label="Testo" fullWidth multiline rows={ 10 } value={ quotationText } onChange={ onQuotationTextChange }/>
                            </Grid>
                            <Grid item container justifyContent={ 'flex-end' } xs={ 12 } spacing={ 2 }>
                                <Grid item>
                                    <Button variant="contained" onClick={ onSaveChange }>Salva</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={ onSeeQuotation }>Apri il preventivo cliente</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AgencyOfflineMargin;