import React, { Fragment, useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Skeleton from '@material-ui/lab/Skeleton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckBoxFilled from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { useSnackbar } from "notistack";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles({
    checked: {
        fill: "black"
    },
    dialogActions: {
        padding: 24
    },
    underline: {
        textDecoration: 'underline'
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    checkbox: {
        fontSize: "2rem"
    },
    borderRadius: {
        "& .mui-jss-MuiDialog-paper": {
            borderRadius: 18
        }
    },
    capitalized: {
    	textTransform: "capitalize"
    }
});

const CategoryListFilters = ({ open, setShowCategory, cars_categories, setUpdate }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [copy_category_list, setCopyCategory] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
    	if (cars_categories !== undefined) {
	        setCopyCategory(cars_categories);
    	}
    }, [cars_categories]);
    function handleChange(category_index) {
        let tmp_category = [];
        copy_category_list.map((category, index) => {
            if (index === category_index) {
                let copy_category = Object.assign({}, category);
                copy_category.to_render = !copy_category.to_render;
                category = copy_category;
            }
            tmp_category.push(category);
        });
        setCopyCategory(tmp_category);
    }
    //const removeAll = () => {
    //let tmp_category = [];
    //copy_category_list.map((category, index) => {
    //let copy_category = Object.assign({}, category);
    //copy_category.to_render = false;
    //tmp_category.push(copy_category);
    //});
    //setCopyCategory(tmp_category);
    //}
    const checkedAll = () => {
        let tmp_category = [];
        copy_category_list.map((category, index) => {
            let copy_category = Object.assign({}, category);
            copy_category.to_render = true;
            tmp_category.push(copy_category);
        });
        setCopyCategory(tmp_category);
    };
    const handleCancel = () => {
        setCopyCategory(cars_categories);
    };
    const setFilters = (copy_category_list) => () => {
        let diff = false;
        let check_category = copy_category_list.find((el) => {
            return el.to_render;
        });
        if (check_category !== undefined) {
            for (let i = 0; i < cars_categories.length; i++) {
                if (cars_categories[i].to_render !== copy_category_list[i].to_render) {
                    diff = true;
                }
            }
            if (diff) {
            	dispatch({ type: "CARS_SET_CATEGORIES", payload: { categories: copy_category_list } });
                setUpdate(true);
            }
            setShowCategory(false);
        } else {
            enqueueSnackbar(t("cars.category_error"), {
                variant: "warning"
            });
        }
    };
    return (
        <Dialog open={ open } onClose={ () => {
            setCopyCategory(cars_categories);
            setShowCategory(false);
        } } fullWidth fullScreen={ fullScreen } maxWidth={ "md" }
        className={classes.borderRadius}
        >
            <DialogTitle onClose={ () => {
                setCopyCategory(cars_categories);
                setShowCategory(false);
            } }>{ t("cars.category_list") }</DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    {
                        copy_category_list.length !== 0 && copy_category_list.map((category, category_index) => {
                            return (
                                <Grid item sm={6} xs={12} key={category_index}>
                                    <FormControlLabel
                                        control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ "small" }/> } checkedIcon={ <CheckBoxFilled className={ classes.checked } fontSize={ "small" }/> } checked={ category.to_render } onChange={() => {
                                            handleChange(category_index); 
                                        }} /> }
                                        label={
                                            <span className={classes.capitalized}>
                                                {category.name}
                                            </span>
                                        }
                                    />
                                    {
                                        category_index !== (copy_category_list.length - 1) && (
                                            <br/>
                                        )
                                    }
                                </Grid>
                            );
                        })
                    }
                    {
                        copy_category_list.length === 0 && (
                            <Fragment>
                                <Grid item sm={6} xs={12}>
                                    <Skeleton width={400} height={35} animation={"wave"}/>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Skeleton width={400} height={35} animation={"wave"}/>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Skeleton width={400} height={35} animation={"wave"}/>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Skeleton width={400} height={35} animation={"wave"}/>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogContent>
            <DialogActions className={ classes.dialogActions }>
                <Button onClick={checkedAll} className={ classes.underline }>
                    {t("cars.select_all_true")}
                </Button>
                <div style={{ flex: '1 0 0' }} />
                <Button className={ classes.underline } onClick={handleCancel}>{ t("global.cancel") }</Button>
                <Button variant={ "contained" } className={ classes.orangeButton } onClick={setFilters(copy_category_list)}>{ t("quotation.ok") }</Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(CategoryListFilters);
