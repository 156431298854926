import React, {Fragment, useEffect, useState} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
	cardPadding: {
		padding: "0 10px 10px 10px"
	},
    alignRight: {
        textAlign: "right"
    },
    alignLeft: {
        textAlign: "left"
    },
    alignCenter: {
        textAlign: "center"
    },
    pink: {
        color: "#E0368D"
    },
    bold: {
        fontWeight: "bold"
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    font12: {
        fontSize: 12
    },
    italic: {
        fontStyle: "italic"
    },
    justify: {
        textAlign: "justify"
    },
    marginBottom40: {
        marginBottom: 40
    },
    coverImg: {
        display: "block",
        width: "100%",
        objectFit: "cover",
        height: "100%"
    },
    capitalize: {
        textTransform: "capitalize"
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    formShadow: {
        borderRadius: 15,
        boxShadow: '0 1px 6px rgba(32,33,36,0.28)',
        padding: "0px 16px"
    },
    buttonGrid: {
        textAlign: "center",
        margin: 16
    },
}));

const InscriptionRightPanel = ({activeStep, setCheckValidate, setNextButton}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const trip_main_info = useSelector(store => store.summary.trip_main_info);
    const trip_info = useSelector(store => store.summary.trip_info);
    const itinerary = useSelector(store => store.summary.itinerary);
    const total_price = useSelector(store => store.summary.total_price);

    let tmp_total_days = moment.utc(trip_info.end_date).diff(moment.utc(trip_info.start_date), "d") + 1;
    let tmp_total_nights = 0;
    if (trip_info.start_date !== null && trip_info.end_date !== null) {
            tmp_total_nights = moment.utc(trip_info.end_date).diff(moment.utc(trip_info.start_date), "d");
    }
    for (let i = 1; i < itinerary.length; i++) {
        if (!moment.utc(itinerary[i - 1].end_date).isSame(moment.utc(itinerary[i].start_date), "day")) {
            tmp_total_nights -= 1;
        }
    }
    return (
        <CardContent className={ clsx({[classes.marginBottom40]: fullScreen })}>
        {/* <Paper elevation={0} variant={isHandHeld ? "elevation" : "outlined"} className={isHandHeld ? "" : classes.formShadow}> */}
            <Grid container direction={"column"}>
                {
                    trip_info !== null && (
                        <Grid item style={{marginBottom: 10, marginTop: 20}}>
                            <Typography variant={"h5"} className={classes.genericText}>{trip_info.trip.name}</Typography>
                        </Grid>
                    )
                }
                <Grid item style={{marginBottom: 20, marginTop: 10}}>
                    <img src={(trip_info !== null && trip_info.destination !== null && trip_info.destination.data.cover_pic !== null && trip_info.destination.data.cover_pic.url !== null) ? ( trip_info.destination.data.cover_pic.url ) : ( trip_info.destination.data.pictures !== undefined && trip_info.destination.data.pictures !== null && trip_info.destination.data.pictures.length > 0) ? (trip_info.destination.data.pictures.url !== null ? trip_info.destination.data.pictures.url : "/Img/bg-summary-connexion.jpg" ) : "/Img/bg-summary-connexion.jpg"} className={classes.coverImg} />
                </Grid>
                <Grid item className={classes.genericText}><strong> { t("menu.trip_list.reference") } : </strong> { trip_main_info !== null ? trip_main_info.trip_reference : 0 }</Grid>
                <Grid item className={classes.genericText}><strong> { t("accommodation.end_date") } : </strong> { trip_info !== null ? moment.utc(trip_info.start_date).format("L") : "-" } </Grid>
                <Grid item className={classes.genericText}><strong> { t("menu.trip_list.return_date") } : </strong> { trip_info !== null ? moment.utc(trip_info.end_date).format("L") : "-" } </Grid>
                <Grid item className={classes.genericText}><strong> { t("summary.duration") } : </strong> { tmp_total_days + (tmp_total_days > 0 ? t("global.days") : t("global.day")) + " / " + tmp_total_nights + " " + (tmp_total_days > 0 ? t("itinerary.nights") : t("itinerary.night"))} </Grid>
                <Grid item className={classes.genericText}><strong> { t("itinerary.departure_city") } : </strong> { itinerary !== null && itinerary.length !== 0 ? itinerary[0].city_name + ", " + itinerary[0].country_name : "-" } </Grid>
                <Grid item className={classes.genericText}><strong> { t("summary.nb_ppl") } : </strong> { trip_info !== null ? trip_info.travelers.length : "-" } </Grid>
                <Grid item style={{marginBottom: 10, marginTop: 20}}>
                    <Typography variant={"h5"} className={classes.genericText}>{ t("summary.your_quotation") }</Typography>
                </Grid>
                <Grid item>
                    <Grid container direction={"row"} justify={"space-between"}>
                        <Grid item className={`${classes.bold} ${classes.capitalize}`}>{ t("accommodation.flights") }</Grid>
                        <Grid item className={classes.pink}>{ Intl.NumberFormat(i18n.language, { style: "currency", currency: trip_info.trip.currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(total_price.total_flight)}</Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction={"row"} justify={"space-between"}>
                        <Grid item className={`${classes.bold} ${classes.capitalize}`}>{ t("summary.journey") }</Grid>
                        <Grid item className={classes.pink}>{ Intl.NumberFormat(i18n.language, { style: "currency", currency: trip_info.trip.currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(total_price.total_journey)}</Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction={"row"} justify={"space-between"}>
                        <Grid item className={`${classes.bold} ${classes.capitalize}`}>{ t("summary.including_taxes") }</Grid>
                        <Grid item className={classes.pink}>{ Intl.NumberFormat(i18n.language, { style: "currency", currency: trip_info.trip.currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(total_price.taxes)}</Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction={"row"} justify={"space-between"}>
                        <Grid item className={`${classes.bold} ${classes.capitalize}`}>{ t("flight_search.total") }</Grid>
                        <Grid item className={classes.pink}>{ Intl.NumberFormat(i18n.language, { style: "currency", currency: trip_info.trip.currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(total_price.total)}</Grid>
                    </Grid>
                </Grid>
                {
                    activeStep === 1 && (trip_info.status === "TO_UPDATED" || trip_info.status === "ONGOING" || trip_info.status === "VALIDATED" || trip_info.status === "TOBOOK_VERDIE") && (
                        <Grid item className={classes.buttonGrid}>
                            <Button variant={ 'contained' } className={ classes.orangeButton } fullWidth={ true } onClick={() => {setNextButton(true)} }>{ t('cart-material.next') }</Button>
                        </Grid>
                    )
                }
                {
                    activeStep === 2 && (trip_info.status === "TO_UPDATED" || trip_info.status === "ONGOING" || trip_info.status === "VALIDATED" || trip_info.status === "TOBOOK_VERDIE") && (
                        <Grid item className={classes.buttonGrid}>
                            <Button variant={ 'contained' } className={ classes.orangeButton } fullWidth={ true } onClick={() => {setCheckValidate(true)}}>{ t('global.validate') }</Button>
                        </Grid>
                    )
                }
            </Grid>
        {/* </Paper> */}
        </CardContent>
    )
}
export default InscriptionRightPanel;