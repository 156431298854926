import { SetManualCart, SetCarsCart, SetFlightCart, SetHotelCart, SetIti, SetGuideList } from "../../../Actions/ItineraryType";
import { SetPoiCart, SetTransfersCart } from "../../../Actions/Summary";

import CheckResponse from "../../Flight/FlightSelected/Functions/CheckResponse";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

let allCart = 0;
let big_cart = [];
const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

function redirect(setProducts, setIsItiLoading) {
    if (allCart === 7) {
        let available_cart = [];
        for (let i = 0; i < big_cart.length; i++) {
            if (big_cart[i].is_available === undefined || big_cart[i].is_available === true || (quotation_code !== undefined && quotation_code !== null && quotation_code !== "" && quotation_code === 'verdie')) {
                if (big_cart[i].booking_status === null || (big_cart[i].booking_status !== undefined && big_cart[i].booking_status !== null && big_cart[i].booking_status.status_booking !== "CANCELLED")) {
                    available_cart.push(big_cart[i]);
                }
                if (big_cart[i].booking_status === undefined) {
                    available_cart.push(big_cart[i]);
                }
            }
        }
        available_cart = available_cart.sort((a, b) => {
            let da = moment(a.start_date);
            let db = moment(b.start_date);
            return da - db;
        });
        setProducts(available_cart);
        setIsItiLoading(false);
    }
}

export default (trip_id, trip_id_version, trip_token, setIsItiLoading, dispatch, setProducts) => {
    const { pass_check, headers } = CheckBeforeRequest();
    allCart = 0;
    big_cart = [];
    if (pass_check) {   
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/flight/?token=${trip_token}`,
            headers: headers,
            success: function (data) {
                let tmp_data = data.filter((item) => !item.is_hidden_for_traveler && !item.is_hidden_for_traveler_count_price);
                for (let i = 0; i < tmp_data.length; i++) {
                    let arr = CheckResponse(tmp_data);
                    arr[i].is_manual = false;
                    arr[i].is_custom = false;
                    big_cart.push(arr[i]);
                    dispatch(SetFlightCart(arr));
                }
                allCart++;
                redirect(setProducts, setIsItiLoading);
            },
            error: function (error) {
                allCart++;
                console.log(error);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/car/?token=${trip_token}`,
            headers: headers,
            success: function (data) {
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].is_hidden_for_traveler && !data[i].is_hidden_for_traveler_count_price) {
                        data[i].is_manual = false;
                        data[i].is_custom = false;
                        big_cart.push(data[i]);
                    }
                }
                if (data.length > 0) {
                    let tmp_data = data.filter((item) => !item.is_hidden_for_traveler && !item.is_hidden_for_traveler_count_price);
                    tmp_data.map((item) => {
                        item.detail = false;
                        item.charges.sort((a, b) => {
                            return ((parseFloat(a.amount) < parseFloat(b.amount)) ? -1 : ((parseFloat(a.amount) > parseFloat(b.amount)) ? 1 : 0));
                        });
                    });
                    dispatch(SetCarsCart(tmp_data));
                }
                allCart++;
                redirect(setProducts, setIsItiLoading);
            },
            error: function (error) {
                allCart++;
                console.log(error);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/accommodation/?token=${trip_token}&full_content=true`,
            headers: headers,
            success: function (data) {
                let tmp_data = data.filter((item) => !item.is_hidden_for_traveler && !item.is_hidden_for_traveler_count_price);
                 for (let i = 0; i < tmp_data.length; i++) {
                    tmp_data[i].hotel[0].dist = [];
                    tmp_data[i].hotel[0].sports = [];
                    tmp_data[i].hotel[0].meals = [];
                    tmp_data[i].hotel[0].room = [];
                    tmp_data[i].hotel[0].facility = [];
                    tmp_data[i].hotel[0].payment = [];
                    tmp_data[i].hotel[0].building = [];
                    // for (let j = 0; j < data[i].hotel[0].facts.length; j++) {
                    //     if (data[i].hotel[0].facts[j].codename.includes("hoteltype") || data[i].hotel[0].facts[j].codename === "located_on_main_road" || data[i].hotel[0].facts[j].codename === "year_construction" || data[i].hotel[0].facts[j].codename === "year_renovation" || data[i].hotel[0].facts[j].codename === "annexe_buildings" || data[i].hotel[0].facts[j].codename === "num_floors_main" || data[i].hotel[0].facts[j].codename === "num_floors_annexe" || data[i].hotel[0].facts[j].codename === "m2_garden" || data[i].hotel[0].facts[j].codename === "m2_terrace" || data[i].hotel[0].facts[j].codename === "num_rooms_total" || data[i].hotel[0].facts[j].codename === "num_rooms_single" || data[i].hotel[0].facts[j].codename === "num_rooms_double" || data[i].hotel[0].facts[j].codename === "num_suites" || data[i].hotel[0].facts[j].codename === "num_apartments" || data[i].hotel[0].facts[j].codename === "num_junior_suites" || data[i].hotel[0].facts[j].codename === "num_studios" || data[i].hotel[0].facts[j].codename === "num_bungalows" || data[i].hotel[0].facts[j].codename === "num_villas") {
                    //         data[i].hotel[0].building.push(data[i].hotel[0].facts[j]);
                    //     }
                    //     else if (data[i].hotel[0].facts[j].codename.includes("dist")) {
                    //         data[i].hotel[0].dist.push(data[i].hotel[0].facts[j]);
                    //     } else if (data[i].hotel[0].facts[j].codename.includes("sports") && data[i].hotel[0].facts[j].codename !== "sports_numberofpools" && data[i].hotel[0].facts[j].codename !== "sports_poolheated" && data[i].hotel[0].facts[j].codename !== "sports_poolfreshwater" && data[i].hotel[0].facts[j].codename !== "sports_poolsaltwater" && data[i].hotel[0].facts[j].codename !== "sports_poolbar") {
                    //         data[i].hotel[0].sports.push(data[i].hotel[0].facts[j]);
                    //     } else if (data[i].hotel[0].facts[j].codename.includes("meals") && data[i].hotel[0].facts[j].codename !== "meals_specialoffers" && data[i].hotel[0].facts[j].codename !== "meals_lunchcarte" && data[i].hotel[0].facts[j].codename !== "meals_dinnerchoice") {
                    //         data[i].hotel[0].meals.push(data[i].hotel[0].facts[j]);
                    //     } else if (data[i].hotel[0].facts[j].codename.includes("room") && data[i].hotel[0].facts[j].codename !== "facility_roomservice" && data[i].hotel[0].facts[j].codename !== "facility_conferenceroom") {
                    //         data[i].hotel[0].room.push(data[i].hotel[0].facts[j]);
                    //     } else if (data[i].hotel[0].facts[j].codename.includes("facility")) {
                    //         data[i].hotel[0].facility.push(data[i].hotel[0].facts[j]);
                    //     } else if (data[i].hotel[0].facts[j].codename.includes("beach")) {
                    //         data[i].hotel[0].facility.push(data[i].hotel[0].facts[j]);
                    //     } else if (data[i].hotel[0].facts[j].codename.includes("payment")) {
                    //         data[i].hotel[0].payment.push(data[i].hotel[0].facts[j]);
                    //     }
                    // }
                    big_cart.push(tmp_data[i]);
                }
                dispatch(SetHotelCart(tmp_data));
                allCart++;
                redirect(setProducts, setIsItiLoading);
            },
            error: function (error) {
                allCart++;
                console.log(error);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/manual-products/?token=${trip_token}`,
            headers: headers,
            success: function (data) {
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].is_hidden_for_traveler && !data[i].is_hidden_for_traveler_count_price) {
                        data[i].is_manual = true;
                        data[i].is_custom = false;
                        if (data[i].longitude !== null && data[i].latitude !== null) {
                            let place = {
                                longitude: data[i].longitude,
                                latitude: data[i].latitude
                            };
                            data[i].place = Object.assign({}, place);
                        }
                        big_cart.push(data[i]);
                    }
                }
                let products = [];
                data.map((product) => {
                    if (Object.keys(product).length > 0 && !product.is_hidden_for_traveler === false && !product.is_hidden_for_traveler_count_price === false) {
                        products.push(product);
                    }
                });
                dispatch(SetManualCart(products));
                allCart++;
                redirect(setProducts, setIsItiLoading);
            },
            error: function (error) {
                allCart++;
                console.log(error);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/custom-products/?token=${trip_token}`,
            headers: headers,
            success: function (data) {
                let poi_products = [];
                let tmp_transfers = [];
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].is_hidden_for_traveler && !data[i].is_hidden_for_traveler_count_price) {
                        data[i].is_manual = false;
                        data[i].is_custom = true;
                        big_cart.push(data[i]);
                    }
                }
                if (data.length > 0) {
                    let products = [];
                    data.map((product) => {
                        if (!product.is_hidden_for_traveler === false && !product.is_hidden_for_traveler_count_price === false) {
                            if (product.product_type === 11) {
                                poi_products.push(product);
                            }
                            if (product.product_type === 4 || product.product_type === 13) {
                                tmp_transfers.push(product)
                            }
                        }
                    });
                    dispatch(SetPoiCart(poi_products));
                    dispatch(SetTransfersCart(tmp_transfers));
                }
                allCart++;
                redirect(setProducts, setIsItiLoading);
            },
            error: function (error) {
                allCart++;
                console.log(error);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/transfers/?token=${trip_token}`,
            headers: headers,
            success: function (data) {
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].is_hidden_for_traveler && !data[i].is_hidden_for_traveler_count_price) {
                        data[i].is_manual = false;
                        data[i].is_custom = false;
                        big_cart.push(data[i]);
                    }
                }
                allCart++;
                dispatch(SetTransfersCart(data));
                redirect(setProducts, setIsItiLoading);
            },
            error: function (error) {
                allCart++;
                console.log(error.responseJSON);
            }
        });
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/itinerary/?token=${trip_token}`,
            headers: headers,
            success: function (data) {
                let url = `${API_HREF}client/${window.id_owner}/rubrics/get_by_destinations/?token=${trip_token}`;
                let has_first = false;
                for (let i = 0; i < data.length; i++) {
                    data[i].description = null;
                    if (data[i].destination !== null) {
                        url += "&dest_id=" + data[i].destination.id;
                    }
                }
                $.ajax({
                    method: "GET",
                    url: url,
                    headers: headers,
                    success: function (data_rubric) {
                        dispatch(SetGuideList(data_rubric));
                    },
                    error: function (error) {
                        console.log(error);
                    }
                });
                let departure_destination = {
                    city: null,
                    country: null,
                    place_id: null,
                    lat: null,
                    lng: null,
                    start_date: null,
                    end_date: null,
                    retrieve: true
                };
                let return_destination = {
                    city: null,
                    country: null,
                    place_id: null,
                    lat: null,
                    lng: null,
                    start_date: null,
                    end_date: null,
                    retrieve: true
                };
                for (let i = 0; i < data.length; i++) {
                    if (data[i].step_type === "START") {
                        departure_destination.place_id = data[i].places_id;
                        departure_destination.start_date = data[i].start_date;
                        departure_destination.end_date = data[i].end_date;
                    } else if (data[i].step_type === "END") {
                        return_destination.place_id = data[i].places_id;
                        return_destination.start_date = data[i].start_date;
                        return_destination.end_date = data[i].end_date;
                    }
                }
                dispatch(SetIti(data));
                allCart++;
                redirect(setProducts, setIsItiLoading);
            },
            error: function (error) {
                allCart++;
                console.log(error);
            }
        });
    }
};

