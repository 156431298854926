import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useProviderDeleteManualProduct } from "./network/deleteManualProduct";
import { useShowError } from "../Utils/showError";
import { ProviderQuotationContext } from "./utils/providerQuotationContext";
import GetTrip from "../Base/Functions/GetTrip";

type Props = {
    open: boolean,
    productId: number,
    onClose: () => void
}

export function ProviderQuotationDeletePropositionModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const user = useSelector(state => state.user.user);
    const context = useContext(ProviderQuotationContext);
    const showError = useShowError();
    const deleteManualProduct = useProviderDeleteManualProduct({
        onTrigger() {
            setLoading(true);
        },
        onSuccess() {
            enqueueSnackbar(
                t<string>('cart-material.provider-quotation-proposition-deletion-success'),
                { variant: 'success' }
            );
            if (context.tripId && context.version && context.tripToken) {
                GetTrip(
                    user,
                    dispatch,
                    undefined,
                    undefined,
                    {
                        tripId: context.tripId.toString(),
                        tripVersion: context.version.toString(),
                        tripToken: context.tripToken
                    }
                );
            }
            props.onClose();
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });

    const onConfirm = () => {
        if (context.tripId && context.version) {
            deleteManualProduct({
                tripId: context.tripId,
                version: context.version,
                productId: props.productId
            });
        }
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                {t<string>('cart-material.provider-quotation-proposition-deletion-title')}
            </DialogTitle>
            <DialogContent>
                {t<string>('cart-material.provider-quotation-proposition-deletion-confirmation')}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    {t<string>('shared.cancel')}
                </Button>
                <LoadingButton
                    loading={loading}
                    onClick={onConfirm}
                >
                    {t<string>('cart-material.provider-quotation-confirm')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
