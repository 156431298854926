type Product = {
    provider_start_date: string | null,
    provider_end_date: string | null,
    client_start_time: string | null,
    client_end_time: string | null,
    client_start_date: string | null,
    client_end_date: string | null,
    start_date: string | null,
    end_date: string | null
}

export function addProviderDates<T extends Product>(item: T): T {
    const result = { ...item };
    const defaultDate = window.moment.utc(result.start_date ?? undefined).toISOString();

    if (!result.provider_start_date || !result.provider_end_date) {
        result.provider_start_date = result.start_date;
        result.provider_end_date = result.end_date ?? result.start_date;
    
        const startHour = window.moment.utc(result.client_start_time ?? '', 'HH:mm').isValid() ?
            result.client_start_time :
            window.moment.utc(result.start_date ?? undefined).format('HH:mm');
        const startDate = window.moment.utc(result.client_start_date ?? '', 'YYYY-MM-DD').isValid() ?
            result.client_start_date :
            window.moment.utc(result.start_date ?? undefined).format('YYYY-MM-DD');
        result.start_date = window.moment.utc(`${startDate} ${startHour}`, 'YYYY-MM-DD HH:mm').toISOString();

        const endHour = window.moment.utc(result.client_end_time ?? '', 'HH:mm').isValid() ?
            result.client_end_time :
            window.moment.utc(result.end_date ?? defaultDate).format('HH:mm');
        const endDate = window.moment.utc(result.client_end_date ?? '', 'YYYY-MM-DD').isValid() ?
            result.client_end_date :
            window.moment.utc(result.end_date ?? defaultDate).format('YYYY-MM-DD');
        result.end_date = window.moment.utc(`${endDate} ${endHour}`, 'YYYY-MM-DD HH:mm').toISOString();
    }

    return result;
}
