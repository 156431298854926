export default (travelers) => {
    let check_passed = true;
    let traveler_identical = []
    travelers.map((traveler, traveler_index) => {
        for(i=traveler_index+1 ; i < travelers.length ; i++){
            if (
                travelers[i].id !== traveler.id
                && travelers[i].title === traveler.title
                && travelers[i].first_name === traveler.first_name
                && travelers[i].last_name === traveler.last_name
                && travelers[i].birth_date === traveler.birth_date){
                // check failed
                check_passed = false;
                if (!traveler_identical.some(item => item.id === traveler.id)){
                    traveler_identical.push(traveler);
                }
                if (!traveler_identical.some(item => item.id === travelers[i].id)){
                    traveler_identical.push(travelers[i]);
                }
            }
        }
    })
    // check passed
    return { check_passed : check_passed, traveler_identical: traveler_identical};
}
