import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { GoogleFont } from "./objects/googleFont";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";
import { MailTemplateVisualEditorContext } from "./mailTemplateVisualEditorContext";
import { useMailTemplateGoogleFontsQuery } from "./network/mailTemplateGoogleFontsQuery";
import { AppState } from "../../../../Reducers/Reducers";

type Props = {
    fontFamily?: GoogleFont,
    onChangeFontFamily: (fontFamily: GoogleFont) => void
}

export function MailTemplateVisualEditorFontFamilyOption(props: Props): JSX.Element {
    const context = useContext(MailTemplateVisualEditorContext);
    const { t } = useTranslation();
    const googleFonts = useMailTemplateGoogleFontsQuery();
    const bodyStyles = useSelector((state: AppState) => {
        return state.mailTemplate.visualEditor.present.instances[context.instanceId]?.visualEditorBodyStyles;
    });

    const onChange = (event: React.SyntheticEvent<Element, Event>, value: string | null) => {
        const font = googleFonts.find((font) => font.name === value);
        if (font) {
            props.onChangeFontFamily(font);
        }
    };

    useEffect(() => {
        let styleTag = document.getElementById("mail-template-visual-editor-font") as HTMLLinkElement | null;
        if (!styleTag) {
            styleTag = document.createElement('link');
            styleTag.id = "mail-template-visual-editor-font";
            styleTag.rel = "stylesheet";
            document.head.appendChild(styleTag);
        }
        styleTag.href = bodyStyles?.fontFamily.url ?? '';
    }, [bodyStyles?.fontFamily.name]);

    return (
        <MailTemplateVisualEditorOptionsSection>
            <Typography>{t<string>('shared.mail-template-visual-editor-font-family')}</Typography>
            <Autocomplete
                disablePortal
                options={googleFonts.map((font) => font.name)}
                renderInput={(params) => <TextField {...params} />}
                value={props.fontFamily?.name ?? ''}
                onChange={onChange}
                disableClearable
            />
        </MailTemplateVisualEditorOptionsSection>
    );
}
