import { Itinerary } from "../objects/itinerary";
import { ItineraryInput } from "../objects/itineraryState";

export function itineraryToItineraryInput(item: Itinerary): ItineraryInput {
    return {
        ...item,
        destination: item.destination?.data ?
            {
                id: item.destination.id,
                type_dest: item.destination.data.type,
                version_id: item.destination.current_version,
                bounds: null,
                cover_picture: item.destination.data.cover_picture,
                note: item.destination.data.note,
                parent_id: item.destination.parent?.id ?? null,
                pictures: item.destination.data.pictures,
                international_name: item.destination.data.international_name,
                latitude: parseFloat(item.destination.data.latitude),
                longitude: parseFloat(item.destination.data.longitude),
                localization: item.destination.data.localization,
                suggested_hours: item.destination.data.suggested_hours,
                parent_name: [
                    {
                        id: 1,
                        locale: 1,
                        name: item.destination.parent?.data?.name ?? item.destination.parent?.data?.international_name ?? ''
                    }
                ],
                parent_international_name: item.destination.parent?.data?.international_name ?? ''
            } :
            null
    };
}
