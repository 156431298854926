import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartConstructionViewSwitchButton } from "./CartConstructionViewSwitchButton";
import { setView } from "./redux/cartConstructionReducer";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    direction: 'left' | 'right'
}

export function CartConstructionCommonViewSwitchButton(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const view = useSelector((state: AppState) => state.cartConstruction.view);
    const direction = useMemo(() => {
        if (view === 'minimized') {
            return props.direction;
        }
        return props.direction === 'left' ? 'right' : 'left';
    }, [view, props.direction]);

    const onSwitch = () => {
        if (view === 'minimized') {
            dispatch(setView(props.direction === 'left' ? 'right' : 'left'));
        } else {
            dispatch(setView('minimized'));
        }
    };

    return (
        <CartConstructionViewSwitchButton
            direction={direction}
            position={props.direction}
            onClick={onSwitch}
        />
    );
}
