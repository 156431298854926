import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { CartConstructionReplaceProductModal } from "./CartConstructionReplaceProductModal";
import { CartConstructionReplaceProductContext } from "./utils/cartConstructionReplaceProductContext";

type ContextValue = Parameters<typeof CartConstructionReplaceProductContext.Provider>[0]['value']

type Props = {
    onClose: () => void
} & Pick<
    ContextValue,
    'contentItem'
>

export function CartConstructionAddProductModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [open, setOpen] = useState(true);
    const [type, setType] = useState<
        'car' | 'accommodation' | 'poi' | 'transfer' | 'flight'
    >('accommodation');

    const onContinue = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={props.onClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" fontWeight={700}>
                            {t<string>('cart-material.product-add')}
                        </Typography>
                        <IconButton onClick={props.onClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                        <Typography gutterBottom>
                            {t<string>('cart-material.cart-construction-contents-add-product-type-choice')}
                        </Typography>
                        <Select
                            size="small"
                            value={type}
                            onChange={(event) => setType(event.target.value as typeof type)}
                        >
                            <MenuItem value="accommodation">{t<string>('cart-material.accommodation')}</MenuItem>
                            <MenuItem value="poi">{t<string>('cart-material.poi')}</MenuItem>
                            <MenuItem value="car">{t<string>('cart-material.car')}</MenuItem>
                            <MenuItem value="transfer">{t<string>('cart-material.transfer')}</MenuItem>
                            <MenuItem value="flight">{t<string>('cart-material.flight')}</MenuItem>
                        </Select>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>
                        {t<string>('shared.cancel')}
                    </Button>
                    <Button onClick={onContinue}>
                        {t<string>('global.continue')}
                    </Button>
                </DialogActions>
            </Dialog>
            {
                type === 'car' &&
                !open &&
                <CartConstructionReplaceProductModal
                    mode="add"
                    type="car"
                    contentItem={props.contentItem}
                    onClose={props.onClose}
                />
            }
            {
                type === 'accommodation' &&
                !open &&
                <CartConstructionReplaceProductModal
                    mode="add"
                    type="accommodation"
                    status="DEFAULT"
                    contentItem={props.contentItem}
                    onClose={props.onClose}
                />
            }
            {
                type === 'flight' &&
                !open &&
                <CartConstructionReplaceProductModal
                    mode="add"
                    type="flight-segment"
                    contentItem={props.contentItem}
                    onClose={props.onClose}
                />
            }
            {
                type === 'poi' &&
                !open &&
                <CartConstructionReplaceProductModal
                    mode="add"
                    type="poi"
                    contentItem={props.contentItem}
                    onClose={props.onClose}
                />
            }
            {
                type === 'transfer' &&
                !open &&
                <CartConstructionReplaceProductModal
                    mode="add"
                    type="transfer"
                    contentItem={props.contentItem}
                    onClose={props.onClose}
                />
            }
        </>
    );
}
