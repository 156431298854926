import i18n from '../../../i18n';
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import TvOutlined from '@material-ui/icons/TvOutlined';
import DuoOutlined from '@material-ui/icons/DuoOutlined';
import WifiOutlined from '@material-ui/icons/WifiOutlined';
import RoomOutlined from '@material-ui/icons/RoomOutlined';
import WorkOutlined from '@material-ui/icons/WorkOutlined';
import PoolOutlined from '@material-ui/icons/PoolOutlined';
import PetsOutlined from '@material-ui/icons/PetsOutlined';
import DeckOutlined from '@material-ui/icons/DeckOutlined';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import MoneyOutlined from '@material-ui/icons/MoneyOutlined';
import GamesOutlined from '@material-ui/icons/GamesOutlined';
import NotInterested from '@material-ui/icons/NotInterested';
import HotelOutlined from '@material-ui/icons/HotelOutlined';
import HeightOutlined from '@material-ui/icons/HeightOutlined';
import AcUnitOutlined from '@material-ui/icons/AcUnitOutlined';
import CasinoOutlined from '@material-ui/icons/CasinoOutlined';
import SyncAltOutlined from '@material-ui/icons/SyncAltOutlined';
import AllInboxOutlined from '@material-ui/icons/AllInboxOutlined';
import BusinessOutlined from '@material-ui/icons/BusinessOutlined';
import ComputerOutlined from '@material-ui/icons/ComputerOutlined';
import LocalBarOutlined from '@material-ui/icons/LocalBarOutlined';
import TranslateOutlined from '@material-ui/icons/TranslateOutlined';
import LocalCafeOutlined from '@material-ui/icons/LocalCafeOutlined';
import MusicNoteOutlined from '@material-ui/icons/MusicNoteOutlined';
import SmokeFreeOutlined from '@material-ui/icons/SmokeFreeOutlined';
import RestaurantOutlined from '@material-ui/icons/RestaurantOutlined';
import BeachAccessOutlined from '@material-ui/icons/BeachAccessOutlined';
import NetworkCellOutlined from '@material-ui/icons/NetworkCellOutlined';
import RoomServiceOutlined from '@material-ui/icons/RoomServiceOutlined';
import LocalFloristOutlined from '@material-ui/icons/LocalFloristOutlined';
import SmokingRoomsOutlined from '@material-ui/icons/SmokingRoomsOutlined';
import LocalParkingOutlined from '@material-ui/icons/LocalParkingOutlined';
import SportsTennisOutlined from '@material-ui/icons/SportsTennisOutlined';
import ChildFriendlyOutlined from '@material-ui/icons/ChildFriendlyOutlined';
import LocalHospitalOutlined from '@material-ui/icons/LocalHospitalOutlined';
import AirportShuttleOutlined from '@material-ui/icons/AirportShuttleOutlined';
import DirectionsBikeOutlined from '@material-ui/icons/DirectionsBikeOutlined';
import QuestionAnswerOutlined from '@material-ui/icons/QuestionAnswerOutlined';
import SettingsEthernetOutlined from '@material-ui/icons/SettingsEthernetOutlined';
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import LocalLaundryServiceOutlined from '@material-ui/icons/LocalLaundryServiceOutlined';

const useStyles = makeStyles({
    inline: {
        display: "inline-block"
    },
    bold: {
        fontWeight: "bold"
    },
});

const MatchSectionWithIcon = {
    100: <RoomOutlined style={{ float: "left", marginLeft: -2, marginRight: 6, marginTop: 2 }}/>,// Situation
    101: <AllInboxOutlined style={{ float: "left", marginLeft: -2, marginRight: 8, marginTop: 3 }}/>,// Equipment
    110: <AllInboxOutlined style={{ float: "left", marginLeft: -2, marginRight: 8, marginTop: 3 }}/>,// Equipment
    102: <HotelOutlined style={{ float: "left", marginRight: 8, marginTop: 2 }}/>,// Room
    120: <HotelOutlined style={{ float: "left", marginRight: 8, marginTop: 2 }}/>,// Room
    103: <SportsTennisOutlined style={{ float: "left", marginRight: 8, marginTop: 3 }}/>,// Sport
    130: <SportsTennisOutlined style={{ float: "left", marginRight: 8, marginTop: 3 }}/>,// Sport
    104: <MoneyOutlined style={{ float: "left", marginRight: 8, marginTop: 3 }}/>,// Resort Fees
    140: <MoneyOutlined style={{ float: "left", marginRight: 8, marginTop: 3 }}/>,// Resort Fees
    105: <SyncAltOutlined style={{ float: "left", marginRight: 8, marginTop: 2 }}/>,// Arrival / Departure
    150: <SyncAltOutlined style={{ float: "left", marginRight: 8, marginTop: 2 }}/>,// Arrival / Departure
    106: <RestaurantOutlined style={{ float: "left", marginRight: 8, marginTop: 2 }}/>,// Meal
    160: <RestaurantOutlined style={{ float: "left", marginRight: 8, marginTop: 2 }}/>,// Meal
    107: <InfoOutlined style={{ float: "left", marginRight: 8, marginTop: 3 }}/>,// Good to know
    170: <InfoOutlined style={{ float: "left", marginRight: 8, marginTop: 3 }}/>,// Good to know
    200: <AddCircleOutlineOutlined style={{ float: "left", marginRight: 8, marginTop: 3 }}/>,// Others
    201: <SettingsEthernetOutlined style={{ float: "left", marginRight: 8, marginTop: 3 }}/>,// Distances
    210: <SettingsEthernetOutlined style={{ float: "left", marginRight: 8, marginTop: 3 }}/>,// Distances
};

const MatchFactsWithIcon = {
    "conferencerooms": <DuoOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "located_on_main_road": <RoomOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_babysitter": <ChildFriendlyOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_baggageroom": <WorkOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_bilingualstaff": <TranslateOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_businesscenter": <BusinessOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_childrenspool": <PoolOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_computer": <ComputerOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_conferencerooms": <DuoOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_evelators": <HeightOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_footboy": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//Groom
    "facility_garden": <LocalFloristOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_laundry": <LocalLaundryServiceOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_newsstand": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//"Kiosk à journaux"
    "facility_outdoorpool": <PoolOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_parasols": <BeachAccessOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_pets": <PetsOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_poolbar": <LocalBarOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_reception": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//"Réception"
    "facility_restaurant": <RestaurantOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_smoking": <SmokingRoomsOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_solarium": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//"Solarium"
    "facility_terrace": <DeckOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_tourdesk": <InfoOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_towels": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//"Serviettes"
    "facility_transfert": <AirportShuttleOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_wifi": <WifiOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_aircon": <AcUnitOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_receptionarea": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//"Hall de réception"
    "facility_reception24": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//"Réception 24h/24"
    "facility_checkin24": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//"Enregistrement 24h/24"
    "facility_safe": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//"Coffre-fort"
    "facility_moneyexchange": <MoneyOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_cloakroom": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//"Vestiaire"
    "facility_foyer": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//"Hall d’accueil"
    "facility_lifts": <HeightOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_cafe": <LocalCafeOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_kiosk": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//"Kiosque"
    "facility_supermarket": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//"Supérette"
    "facility_shops": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//"Boutiques"
    "facility_hairdresser": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//"Salon de coiffure"
    "facility_bars": <LocalBarOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_pub": <LocalBarOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_disco": <MusicNoteOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_theatre": <NotInterested style={{ float: "left", marginRight: "8px" }}/>,//"Salle de spectacle"
    "facility_casino": <CasinoOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_gamesroom": <GamesOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_restaurants": <RestaurantOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_restaurants_aircon": <RestaurantOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_restaurants_nosmokingarea": <SmokeFreeOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_restaurants_highchair": <RestaurantOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_conferenceroom": <DuoOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_mobilephonenet": <NetworkCellOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_internet": <WifiOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_wlan": <WifiOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_roomservice": <RoomServiceOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_laundryservice": <LocalLaundryServiceOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_medicalservice": <LocalHospitalOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_bicyclecellar": <DirectionsBikeOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_bicyclehire": <DirectionsBikeOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_carpark": <LocalParkingOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_garage": <LocalParkingOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_miniclub": <LocalBarOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_playground": <GamesOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_tvroom": <TvOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_restaurant_smokingarea": <SmokingRoomsOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "facility_washing": <LocalLaundryServiceOutlined style={{ float: "left", marginRight: "8px" }}/>,
    "room_bathroom": "Salle de Bain",
    "room_childrensbed": "Lit d'enfant",
    "room_electriciron": "Fer à repasser",
    "room_heating": "Chauffage",
    "room_kitchen": "Cuisine",
    "room_nonsmoking": "Non fumeur",
    "room_phone": "Téléphone",
    "room_slippers": "Chaussons",
    "room_wifi": "Wifi",
    "room_bath": "Salle de bain",
    "room_shower": "Douche",
    "room_bathtub": "Baignoire",
    "room_bidet": "Bidet",
    "room_hairdryer": "Sèche-cheveux",
    "room_directdialtel": "Téléphone avec ligne directe",
    "room_satcabletv": "TV par satellite / câble",
    "room_radio": "Radio",
    "room_hifi": "Chaîne hi-fi",
    "room_internet": "Accès Internet",
    "room_kitchenette": "Coin cuisine",
    "room_minibar": "Mini-bar",
    "room_fridge": "Réfrigérateur",
    "room_kingsizedbeds": "Lit king-size",
    "room_tiled": "Carrelage",
    "room_carpeted": "Moquette",
    "room_aircon": "Climatisation à réglage central",
    "room_centralheating": "Chauffage central",
    "room_safe": "Coffre-fort",
    "room_finalcleaning": "Nettoyage après départ",
    "room_lounge": "Lounge",
    "room_balcony": "Balcon/terrasse",
    "room_tv": "Télévision",
    "room_doublebed": "Lit double",
    "room_aircon_indiv": "Climatisation (à réglage individuel)",
    "room_individualheating": "Chauffage (à réglage individuel)",
    "room_oven": "Cuisinère",
    "room_microwave": "Four à micro-ondes",
    "room_tea_coffee": "Bouilloire et cafetière",
    "room_washingmachine": "Lave-linge",
    "room_wheelchair": "Adapté aux fauteuils roulants",
    "meals_bedandbreakfast": "Bed & Breackfast",
    "meals_breakfast": "Petit Déjeuner",
    "meals_lunch": "Déjeuner",
    "meals_halfboard": "Demi-pension",
    "meals_fullboard": "Pension complète",
    "meals_breakfastbuffet": "Buffet de petit-déjeuner",
    "meals_breakfastserved": "Petit-déjeuner servi",
    "meals_lunchbuffet": "Buffet de déjeuner",
    "meals_lunchcarte": "Déjeuner à la carte",
    "meals_lunchchoice": "Déjeuner sous forme de menu",
    "meals_dinner": "Dîner",
    "meals_dinnerbuffet": "Buffet de dîner",
    "meals_dinnercarte": "Dîner à la carte",
    "meals_dinnerchoice": "Dîner sous forme de menu",
    "meals_allinclusive": "Formule tout compris",
    "meals_drinksincluded": "Boissons comprises",
    "meals_specialdiet": "Cuisine diététique",
    "meals_specialoffers": "Offres spéciales",
    "meals_breakfastcontinental": "Petit-déjeuner continental",
    "spa_hammam": "Hammam",
    "spa_spa": "Spa",
    "sports_pool_indoor": "Piscine couverte",
    "sports_pooloutdoor": "Piscine(s) extérieure(s)",
    "sports_poolfreshwater": "Piscine(s) d'eau douce",
    "sports_poolsaltwater": "Piscine(s) d'eau de mer",
    "sports_poolchildrens": "Bassins pour enfants",
    "sports_poolbar": "Pool/snack-bar",
    "sports_sunloungers": "Chaises longues",
    "sports_parasols": "Parasols",
    "sports_wateraerobics": "Aquagym",
    "sports_jacuzzi": "Jacuzzi",
    "sports_sauna": "Sauna",
    "sports_sunbathing_terrace": "Terrasse",
    "sports_steambath": "Hammam",
    "sports_massage": "Massage",
    "sports_special_spa_pack": "Soins wellness",
    "sports_bananaboat": "Bateau \"banane\"",
    "sports_waterskiing": "Ski nautique",
    "sports_jetski": "Jet ski",
    "sports_motorboat": "Bateau à moteur",
    "sports_scuba": "Plongée",
    "sports_surfing": "Surf",
    "sports_windsurfing": "Planche à voile",
    "sports_sailing": "Voile",
    "sports_catamaran": "Catamaran",
    "sports_canoe": "Canoë",
    "sports_pedalo": "Pédalo",
    "sports_tabletennis": "Tennis de table",
    "sports_squash": "Squash",
    "sports_aerobics": "Aérobic",
    "sports_gym": "Salle de fitness",
    "sports_archery": "Tir à l’arc",
    "sports_horseriding": "Equitation",
    "sports_mtb": "Vélo / VTT",
    "sports_basketball": "Basketball",
    "sports_beachvolleyball": "Beach-volley",
    "sports_billiards": "Billard / Snooker",
    "sports_boccia": "Boccia",
    "sports_bowling": "Bowling",
    "sports_minigolf": "Mini-golf",
    "sports_golf": "Golf",
    "sports_entertainment": "Animations",
    "sports_entertainment_kids": "Animations pour enfants",
    "sports_tennis": "Tennis",
    "sports_badminton": "Badminton",
    "sports_numberofpools": "Nombre de piscines",
    "sports_poolheated": "Nombre de piscines chauffées",
    "sports_gymnastics": "Gymnastique",
    "sports_darts": "Fléchettes",
    "sports_tanning_studio": "Solarium",
    "dist_city_centre": "Centre-ville",
    "dist_tourist_centre": "Centre touristique",
    "dist_beach": "Plage",
    "dist_sea": "Mer",
    "dist_lake": "Lac",
    "dist_river": "Rivière/Fleuve",
    "dist_forest": "Forêt",
    "dist_park": "Parc",
    "dist_shopping": "Commerces",
    "dist_restaurants": "Restaurants",
    "dist_bars_pubs": "Bars / pubs",
    "dist_nightclubs": "Discothèque",
    "dist_golf_course": "Terrain de golf",
    "dist_public_transport": "Transports en commun",
    "dist_bus_station": "Arrêt de bus",
    "dist_train_station": "Gare",
    "dist_ski_area": "Domaine skiable",
    "dist_ski_lift": "Remontée mécanique",
    "dist_cross_country_skiing": "Ski de fond",
    "dist_station": "Arrêt de métro / train de banlieue",
    "type_beachhotel": "Hôtel de plage"
};

const AccommodationCardContent = ({detail, openModal}) => {
    console.log("accommodation content render");
    const classes = useStyles();
    const { t } = useTranslation();
    const user = useSelector(state => state.user.user);
    const config = JSON.parse(localStorage.getItem("config"));

    const locales = useSelector(state => state.user.locales);

    const current_locale = locales.find((el) => {return el.language_code === i18n.language});
    const equipment_length = detail.dist.length + detail.sports.length + detail.meals.length + detail.room.length + detail.facility.length + detail.payment.length + detail.building.length;
    if (detail.content.length > 0 && detail.content[0].section > 200) {
        detail.content.reverse();
    }
    let localization = !detail.is_giata && detail.localization.find((el) => {return el.locale === current_locale.id});
    return (
        <Fragment>
            {
                detail.remark_favorite !== null && detail.remark_favorite !== "" && (
                    <Fragment>
                        <QuestionAnswerOutlined style={{ float: "left", marginLeft: -2, marginRight: 8, marginTop: 3 }}/>
                        <Typography variant={ "h6" } className={ classes.bold }>{ t("accommodation.remark_favorite") } :</Typography>
                        <Typography variant={ "subtitle1" } align={ "justify" }>{ detail.remark_favorite }</Typography>
                        <br/>
                        <Divider/>
                        <br/>
                    </Fragment>
                )
            }
            {
                !detail.is_giata && (
                    <Fragment>
                        {
                            !localization && (
                                <Fragment>
                                {
                                    detail.room_desc !== "" && detail.room_desc !== null &&
                                        <Fragment>
                                            <Typography variant={ "h6" } className={ classes.bold }>{ t('accommodation.description') } :</Typography>
                                            <Typography variant={ "subtitle1" } dangerouslySetInnerHTML={{__html: detail.room_desc}}/>
                                        </Fragment>
                                }
                                {
                                    detail.situation !== "" && detail.situation !== null && (
                                        <Fragment>
                                            <Typography variant={ "h6" } className={ classes.bold }>{ t('accommodation.situation') } :</Typography>
                                            <Typography variant={ "subtitle1" } style={{textAlign: "justify"}} dangerouslySetInnerHTML={{__html: detail.situation}}/>
                                        </Fragment>
                                    )
                                }
                                {
                                    detail.restoration !== "" && detail.restoration !== null && (
                                        <Fragment>
                                            <Typography variant={ "h6" } className={ classes.bold }>{ t('accommodation.restoration') } :</Typography>
                                            <Typography variant={ "subtitle1" } dangerouslySetInnerHTML={{__html: detail.restoration}}/>
                                        </Fragment>
                                    )
                                }
                                </Fragment>
                            )
                        }
                        {
                            localization && (
                                <Fragment>
                                {
                                    localization.room_desc !== "" && (
                                        <Fragment>
                                            <Typography variant={ "h6" } className={ classes.bold }>{ t('accommodation.description') } :</Typography>
                                            <Typography variant={ "subtitle1" } dangerouslySetInnerHTML={{__html: localization.room_desc}}/>
                                        </Fragment>
                                    )
                                }
                                {
                                    localization.situation !== "" && (
                                        <Fragment>
                                            <Typography variant={ "h6" } className={ classes.bold }>{ t('accommodation.situation') } :</Typography>
                                            <Typography variant={ "subtitle1" } dangerouslySetInnerHTML={{__html: localization.situation}}/>
                                        </Fragment>
                                    )
                                }
                                {
                                    localization.restoration !== "" && (
                                        <Fragment>
                                            <Typography variant={ "h6" } className={ classes.bold }>{ t('accommodation.restoration') } :</Typography>
                                            <Typography variant={ "subtitle1" } dangerouslySetInnerHTML={{__html: localization.restoration}}/>
                                        </Fragment>
                                    )
                                }
                                </Fragment>
                            )
                        }
                    </Fragment>
                )
            }
            {
                (user.client_full.type !== 2 || config.quotation_code === "verdie") && detail.content.length > 0 && (
                    <Fragment>
                        {
                            detail.content.map((content, index) => {
                                return (
                                    <Fragment key={ index }>
                                        { MatchSectionWithIcon[content.section] }
                                        <Typography variant={ "h6" } className={ classes.bold }>{ content.title } :</Typography>
                                        <Typography variant={ "subtitle1" } align={ "justify" }>{ content.content }</Typography>
                                        <br/>
                                        <Divider/>
                                        <br/>
                                    </Fragment>
                                );
                            })
                        }
                    </Fragment>
                )
            }
            {
                detail.facility.length > 0 && (
                    <Fragment>
                        <Typography variant={ "h6" } className={ classes.bold }>{ t("accommodation.equipment_title") } :</Typography>
                        <br/>
                        <Grid container spacing={ 2 }>
                            {
                                detail.facility.map((facility, index) => {
                                    if (index < 10) {
                                        return (
                                            <Grid key={ index } item xs={ 12 } sm={ 6 }>
                                                { MatchFactsWithIcon[facility.codename] !== undefined ? MatchFactsWithIcon[facility.codename] : <NotInterested style={{ float: "left", marginRight: "8px" }}/> }
                                                <Typography className={ classes.inline } variant={ "subtitle1" } align={ "justify" }>{ t('accommodation.facts.' + facility.codename) }</Typography>
                                            </Grid>
                                        );
                                    }
                                })
                            }
                        </Grid>
                        {
                            equipment_length > 10 && (
                                <Fragment>
                                    <br/>
                                    <Button className={ classes.bold } variant={ "outlined" } onClick={ openModal }>{ t("accommodation.see_more_1") } { equipment_length } { t("accommodation.see_more_2") }</Button>
                                    <br/>
                                </Fragment>
                            )
                        }
                    </Fragment>
                )
            }
        </Fragment>
    )
};

export default React.memo(AccommodationCardContent);