import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Stack,
    Typography
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useGetPrice } from "./utils/getPrice";
import { Flight } from "../Itinerary/network/flight";
import { AppState } from "../../Reducers/Reducers";
import { flatten } from "lodash";
import { Traveler } from "../../Reducers/objects/Traveler";

type Props = {
    flight: Flight,
    outbound: Flight['outbounds'][number],
    searched_group?: any
}

export function CartFlightSegmentInfoPerPax(props: Props): JSX.Element {
    const { t } = useTranslation();
    const currency = useSelector((state: AppState) => state.trip.currency);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const getPrice = useGetPrice();
    const carryOnLuggages = props.outbound.legs[0]?.baggage?.filter((item) => {
        return item.is_carry_on;
    }).map((item) => {
        if (item.weight > 0) {
            return `x${item.quantity} ${item.weight}kg`;
        }
        return `x${item.quantity} PC`;
    });
    const checkLuggages = props.outbound.legs[0]?.baggage?.filter((item) => {
        return !item.is_carry_on;
    }).map((item) => {
        if (item.weight > 0) {
            return `x${item.quantity} ${item.weight}kg`;
        }
        return `x${item.quantity} PC`;
    }) ??
    (
        (props.outbound.legs[0]?.baggage_allowance?.number_of_pieces ?? 0) > 0 ?
            [`x${props.outbound.legs[0]?.baggage_allowance?.number_of_pieces}`] :
            []
    );
    
    const travelers = props.flight.group_passenger !== undefined ? props.flight.group_passenger?.travelers_list : props.searched_group?.travelers
    return (
        <Box
            sx={{
                flex: 1,
                marginRight: 3.5
            }}
        >
            {
                travelers.map((traveler:Traveler, index:number) => {
                    const tickets = props.flight.issued_ticket?.filter((ticket) => {
                        return ticket.traveler === traveler.id;
                    });

                    let option_on_flight: string[] = [];
                    let option_on_group: string[] = [];

                    const paidOptions: Flight['outbounds'][number]['legs'][number]['paid_options'] = [];
                    props.outbound.legs.map((leg) => {
                        const options = leg.paid_options?.length !== undefined ? leg.paid_options?.filter((option) => {
                            return option.traveler === traveler.id;
                        }) : [];
                        if (options && options.length > 0) {
                            for (const option of options) {
                                if (option.option_on_flight) {
                                    if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        paidOptions.push(option);
                                        option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                } else if (option.option_on_group) {
                                    if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        paidOptions.push(option);
                                        option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                } else {
                                    paidOptions.push(option);
                                }
                            }
                        }
                    });

                    const seats = flatten(
                        props.outbound.legs.map((leg) => {
                            return leg.seats?.filter((seat) => {
                                return seat.traveler === traveler.id;
                            }) ?? [];
                        })
                    );
                    return (
                        <Accordion sx={{ backgroundColor: 'inherit' }} key={index}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography>
                                    {
                                        t<string>(
                                            'cart-material.cart-construction-traveler-no',
                                            { no: index + 1 }
                                        )
                                    }
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography
                                    color="GrayText"
                                    component="div"
                                >
                                    {t<string>('cart-material.cart-construction-traveler-info-header')}
                                </Typography>
                                <Typography
                                    color="InfoText"
                                    component="div"
                                >
                                    {traveler.title} / {traveler.first_name} / {traveler.last_name}
                                </Typography>
                                <Typography
                                    color="GrayText"
                                    component="div"
                                >
                                    {
                                        t<string>(
                                            'cart-material.cart-construction-traveler-birthdate',
                                            {
                                                date: window.moment.utc(traveler.birth_date, 'YYYY-MM-DD').format('L')
                                            }
                                        )
                                    }
                                </Typography>
                                <Stack sx={{ marginTop: 1.5 }}>
                                    {
                                        (tickets?.length ?? 0) > 0 &&
                                        <Typography
                                            color="GrayText"
                                            component="div"
                                            sx={{
                                                display: 'flex',
                                                gap: 1.5
                                            }}
                                        >
                                            <span>
                                                {t<string>('cart-material.cart-construction-tickets')}
                                            </span>
                                            <Box
                                                sx={{
                                                    listStylePosition: 'inside',
                                                    paddingLeft: 0
                                                }}
                                                component="ul"
                                            >
                                                {
                                                    tickets?.map((ticket) => (
                                                        <li key={ticket.ticket_number}>
                                                            {
                                                                t<string>(
                                                                    'cart-material.cart-construction-ticket-item',
                                                                    {
                                                                        type: ticket.ticket_type,
                                                                        status: ticket.status,
                                                                        ref: ticket.ticket_number
                                                                    }
                                                                )
                                                            }
                                                        </li>
                                                    ))
                                                }
                                            </Box>
                                        </Typography>
                                    }
                                    <Typography
                                        color="GrayText"
                                        component="div"
                                        sx={{
                                            display: 'flex',
                                            gap: 1.5
                                        }}
                                    >
                                        <span>
                                            {t<string>("global.luggages_included")}
                                        </span>
                                        <Box
                                            sx={{
                                                listStylePosition: 'inside',
                                                paddingLeft: 0
                                            }}
                                            component="ul"
                                        >
                                            <li>
                                                {
                                                    t<string>(
                                                        'cart-material.cart-construction-luggage-carry-on',
                                                        {
                                                            details: (carryOnLuggages?.length ?? 0) > 0 ?
                                                                carryOnLuggages?.join(', ') :
                                                                t<string>('flight_search.no_luggage')
                                                        }
                                                    )
                                                }
                                            </li>
                                            <li>
                                                {
                                                    t<string>(
                                                        'cart-material.cart-construction-luggage-check',
                                                        {
                                                            details: checkLuggages.length > 0 ?
                                                                checkLuggages.join(', ') :
                                                                t<string>('flight_search.no_luggage')
                                                        }
                                                    )
                                                }
                                            </li>
                                        </Box>
                                    </Typography>
                                    <div>
                                        {
                                            paidOptions.map((option, index) => {
                                                const optionPrice = getPrice(option.prices ?? []);
                                                return (
                                                    <Typography
                                                        key={`${option.key_optional_service}-${option.traveler}`}
                                                        color="GrayText"
                                                        component="div"
                                                    >
                                                        {
                                                            t<string>(
                                                                'cart-material.cart-construction-flight-option',
                                                                {
                                                                    no: index + 1,
                                                                    description: option.description,
                                                                    price: new Intl.NumberFormat(
                                                                        language,
                                                                        {
                                                                            style: 'currency', 
                                                                            currency: optionPrice.currency?.iso_code ?? 'EUR'
                                                                        }
                                                                    ).format(
                                                                        (option.prices?.length ?? 0) > 0 ?
                                                                            optionPrice.cost :
                                                                            parseFloat(option.price)
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </Typography>
                                                );
                                            })
                                        }
                                    </div>
                                    <div>
                                        {
                                            seats?.map((seat) => {
                                                const price = getPrice(seat.prices ?? []);
                                                return (
                                                    <Typography
                                                        key={seat.seat_number}
                                                        color="GrayText"
                                                        component="div"
                                                    >
                                                        {
                                                            t<string>(
                                                                'cart-material.cart-construction-flight-seat',
                                                                {
                                                                    no: seat.seat_number,
                                                                    price: new Intl.NumberFormat(
                                                                        language,
                                                                        {
                                                                            style: 'currency', 
                                                                            currency:
                                                                                (seat.prices?.length ?? 0) > 0 ?
                                                                                    price.currency?.iso_code ?? 'EUR' :
                                                                                    currency?.iso_code ?? 'EUR'
                                                                        }
                                                                    ).format(
                                                                        (seat.prices?.length ?? 0) > 0 ?
                                                                            price.cost :
                                                                            parseFloat(seat.price ?? '0')
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </Typography>
                                                );
                                            })
                                        }
                                    </div>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    );
                })
            }
        </Box>
    );
}
