import { isNumber } from "lodash";
import { TripVersion } from "../../Menu/MaterialTripList/objects/tripVersion";

type Product = {
    stack_number: number | null,
    stack_info_id: number | null,
    is_stack_price?: boolean
}

type Options = {
    product: Product,
    stackInfos: TripVersion['stack_info'],
    connected?: boolean,
    for_price?: boolean,
}

export function isProductPackaged(options: Options): boolean {
    const stackInfo = options.stackInfos?.find((item) => {
        return item.id === options.product.stack_info_id;
    });

    if (!stackInfo && !isNumber(options.product.stack_number)) {
        return false;
    }

    if (options.connected) {
        return !!stackInfo && !!stackInfo.provider?.is_dmc;
    }

    if (options.for_price) {
        return !options.product.is_stack_price;
    }

    return true;
}
