/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../crm.css';
import decorator from '../../utils/editor/decorator';
import RichEditor, { RichEditorStyleMap } from '../../utils/editor/editor';
import { EditorDefaultActivatedToolbarControls } from '../../utils/editor/editorToolbar';
import { renderRichEditorContentHTML } from '../../utils/editor/renderRichEditorContentHtml';
import { restoreRichEditorStateFromHtml } from '../../utils/editor/restoreRichEditorStateFromHtml';
import { EditorState } from 'draft-js';
import { patchNote } from "../../Functions/UserFunctions";
import { SetActiveUserNotes } from "../../../../../Actions/Crm";

const default_note = EditorState.createEmpty(decorator);

const CrmUserDetailNoteCard = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const styles = RichEditorStyleMap;

    const user = useSelector(store => store.user.user);
    const selected_user = useSelector(store => store.crm.selected_user);
    const active_user_notes = useSelector(store => store.crm.active_user_notes);

    const [editMode, setEditMode] = React.useState(false);
    const [editorState, setEditorState] = React.useState(default_note);
    const [lineHeight, setLineHeight] = React.useState('1.5em');
    const [expand, setExpand] = React.useState(false);

    useEffect(() => {
        if (props.editMode) {
            setEditMode(true);
        }
    }, [props.editMode]);

    useEffect(() => {
        if (props.activity?.note) {
            setEditorState(restoreRichEditorStateFromHtml(styles, props.activity.note ?? ''));
            getFirstLineHeight();
        }
    }, [props.activity]);

    const onChangeEditorState = (state) => {
        setEditorState(state);
    };

    const cancelEdition = () => {
        setEditMode(false);
        if (props.cancelEdition !== undefined && props.cancelEdition !== null) {
            props.cancelEdition();
        }
    };

    const save = () => {
        const note = renderRichEditorContentHTML(
            styles,
            editorState.getCurrentContent()
        );
        patchNote(user.client, props.activity?.id, { note }, (response) => {
            let user_notes = [];
            if (active_user_notes && active_user_notes.user_id === selected_user?.id) {
                user_notes = JSON.parse(JSON.stringify(active_user_notes.notes));
            }
            const findIndex = user_notes.findIndex(e => e.id === response.data?.id);
            if (findIndex !== -1) {
                user_notes[findIndex] = response.data;
            }
            dispatch(SetActiveUserNotes(selected_user?.id, user_notes));
            setEditMode(false);
            if (props.cancelEdition !== undefined && props.cancelEdition !== null) {
                props.cancelEdition();
            }
        });
    };

    //get first line
    const getFirstLine = () => {
        const fl = props.activity?.note?.split("</p>");
        if (fl && fl[0]) {
            return fl[0] + '</p>';
        }
        return null;
    };
    const getFirstLineHeight = () => {
        const refreshUntilReady = setInterval(() => {
            const divMeasurer = document.getElementById(`div-measurer-note-${props.activity.id}`);
            if (divMeasurer) {
                clearInterval(refreshUntilReady);
                setLineHeight(divMeasurer.clientHeight + 'px');
                console.log('DEBUG', divMeasurer.clientHeight, divMeasurer.style.lineHeight);
            }
        }, 100);
    };

    return (
        <div className={`activity-note-card`} onClick={() => setExpand(!expand)}>
            <div className="div-measurer" id={`div-measurer-note-${props.activity.id}`}
                dangerouslySetInnerHTML={{ __html: getFirstLine() }}
            />
            {
                !editMode &&
                <div className={`${expand ? '' : 'activity-card-ellipsis-text'}`}
                    dangerouslySetInnerHTML={{ __html: props.activity?.note }}
                    style={{ height: expand ? 'auto' : lineHeight }}
                />
            }
            {
                editMode && (
                    <>
                        <RichEditor pictures={[]}
                            editorState={editorState}
                            controlledState
                            setEditorState={onChangeEditorState}
                            from_toolkit={true}
                            toolbars={
                                EditorDefaultActivatedToolbarControls
                                    .filter((item) => item !== 'image')
                                    .concat(['font-size'])
                            }
                        />
                        <div className="crm-action-button-container">
                            <Button onClick={cancelEdition}>{t("crm.cancel")}</Button>
                            <Button onClick={save}>{t("crm.save")}</Button>
                        </div>
                    </>
                )
            }
        </div>
    );
};
export default React.memo(CrmUserDetailNoteCard);
