import axios from "axios";

import CheckBeforeRequest from "../../../Common/CheckBeforeRequest.js";

/*LIST USERS*/
export const getUsers = (client, limit, offset, filter, ordering, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client}/user/?limit=${limit ? limit : 100}&offset=${offset ? offset : 0}&ordering=${ordering ? ordering : '-date_joined'}`,
            params: filter
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

/*GET USER*/
export const getUser = (client, user_id, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client}/user/${user_id}/`
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

/*POST USER*/
export const postUser = (client, user, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "POST",
            headers: headers,
            url: `${API_HREF}crm/${client}/user/`,
            data: user
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

/*CREATE USER NOTE*/
export const createUserNote = (client, user_id, note, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "POST",
            headers: headers,
            url: `${API_HREF}client/${client}/user/add_note/?user_id=${user_id}`,
            data: note
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

/*GET USER NOTES*/
export const getUserNotes = (client, user_id, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client}/user/list_notes/?user_id=${user_id}`
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

/*PATCH USER NOTE*/
export const patchNote = (client, note_id, data, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "PATCH",
            headers: headers,
            url: `${API_HREF}client/${client}/user/update_note/?note_id=${note_id}`,
            data
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

/*DELETE USER NOTE*/
export const deleteNote = (client, note_id, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "DELETE",
            headers: headers,
            url: `${API_HREF}client/${client}/user/delete_note/?note_id=${note_id}`
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

/*GET USER EMAILS*/
export const getUserEmails = (client, user_id, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client}/user/hist_emails/?user_id=${user_id}`
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

/*GET USER INVOCES*/
export const getUserInvoces = (client, user_id, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client}/user/hist_invoices/?user_id=${user_id}`
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

/*SEARCH USER*/
export const searchUser = (client, data, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client}/user/search/`,
            data: {
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                number: data.number
            }
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

/*GET ALL COMPANIONS*/
export const getAllTravelers = (client, offset, limit, first_name, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client}/travelers/?limit=${limit}&offset=${offset}&first_name=${first_name}`
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

/*CREATE TRAVELER*/
export const createTraveler = (client, data, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "POST",
            headers: headers,
            url: `${API_HREF}client/${client}/travelers/`,
            data
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

/*ATTACH COMPANIONS*/
export const attachCompanion = (client, user_id, traveler_ids, remove_ids, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "PATCH",
            headers: headers,
            url: `${API_HREF}client/${client}/user/attach_companion/?user_id=${user_id}`,
            data: {
                traveler_ids,
                remove_ids
            }
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

/*PATCH USER*/
export const patchUser = (client, user, properties, callback) => {
    let body = {};
    if (properties.length) {
        properties.forEach(e => {
            body[e] = user[e];
        });
    }
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "PATCH",
            headers: headers,
            url: `${API_HREF}client/${client}/user/${user.id}/`,
            data: body
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

export const patchUserFull = (client, user, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "PATCH",
            headers: headers,
            url: `${API_HREF}client/${client}/user/${user.id}/`,
            data: user
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

/*GET USER CONTACT*/
export const getUsersContactSheet = (client, user_id, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client}/user/contact_sheet/?user_id=${user_id}`
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

/*PATCH USER CONTACT SHEET*/
export const patchUserContactSheet = (client, user_id, data, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "PATCH",
            headers: headers,
            url: `${API_HREF}client/${client}/user/contact_sheet/?user_id=${user_id}`,
            data
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

//GET USER MARKERS
export const getUserMarkers = (client, user_id, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client}/user/${user_id}/marker/`
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

//GET ALL MARKERS
export const getAllMarkers = (client, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client}/marker/`
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

//CREATE USER MARKER
export const createUserMarker = (client, user_id, data, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "POST",
            headers: headers,
            url: `${API_HREF}client/${client}/user/${user_id}/marker/`,
            data
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

//PATCH USER MARKER
export const patchUserMarker = (client, user_id, data, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "PATCH",
            headers: headers,
            url: `${API_HREF}client/${client}/user/${user_id}/marker/${data.id}`,
            data
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

//DELETE MARKERS
export const deleteUserMarkers = (client, user_id, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "DELETE",
            headers: headers,
            url: `${API_HREF}client/${client}/user/${user_id}/marker/delete_all/`
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

//GET USER DOCUMENTS
export const getUserDocuments = (client, user_id, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client}/user/${user_id}/file-contact/`
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

//UPLOAD USER DOCUMENT
export const uploadUserDocument = (client, user_id, data, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "POST",
            headers: headers,
            url: `${API_HREF}client/${client}/user/${user_id}/file-contact/`,
            data: {
                type: "CUSTOM_FILE"
            }
        }).then(function (resp) {
            console.log('CUSTOM_FILE created');
            headers["Content-Type"] = "multipart/form-data";
            if (resp) {
                axios({
                    method: "POST",
                    headers: headers,
                    url: `${API_HREF}client/${client}/user/${user_id}/file-contact/upload_to_aws/`,
                    data: {
                        ...data,
                        //document_id: resp.id,
                        type: "CUSTOM_FILE"
                    }
                }).then(function (response) {
                    callback(response);
                }).catch(function (error) {
                    //TODO: show snackbar error
                    console.log('error:', error);
                });
            }
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

//DELETE DOCUMENTS
export const deleteUserDocuments = (client, user_id, file_id, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "DELETE",
            headers: headers,
            url: `${API_HREF}client/${client}/user/${user_id}/file-contact/${file_id}/`
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};

//GET TRIP INVOICE HISTORY
export const getTripInvoiceHistory = (client, trip_id, callback) => {
    let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client}/trip/${trip_id}/invoice-history/`
        }).then(function (response) {
            callback(response);
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }
};
