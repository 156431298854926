import axios, { AxiosError, AxiosResponse } from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { Trip } from "../../Menu/MaterialTripList/objects/trip";

type Callback = (
    tripId: number,
    trip: TripUpdateData,
    signal?: AbortSignal
) => Promise<Trip | null>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: (trip: Trip) => void,
    onError: (error: AxiosError) => void,
    onFinally: () => void
}>

export type TripUpdateData = Partial<{
    is_by_day_view: boolean,
    lockItinerary: boolean
}>

export function useItineraryTripUpdate(options: Options): Callback {
    return async (tripId, trip) => {
        if (options.onTrigger) {
            options.onTrigger();
        }
        try {
            const response = await makeRequest(tripId, trip);
            if (options.onSuccess && response) {
                options.onSuccess(response.data);
            }
            return response?.data ?? null;
        } catch (error: any) {
            if (options.onError) {
                options.onError(error);
            }
        } finally {
            if (options.onFinally) {
                options.onFinally();
            }
        }
        return null;
    };
}

async function makeRequest(
    tripId: number,
    trip: TripUpdateData,
    signal?: AbortSignal
): Promise<AxiosResponse<Trip> | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const cancelToken = axios.CancelToken.source();
        signal?.addEventListener('abort', () => {
            cancelToken.cancel();
        });
        return await axios.patch(
            `${API_HREF}client/${window.id_owner}/trip/${tripId}/`,
            {
                lock_itinerary: trip.lockItinerary,
                is_by_day_view: trip.is_by_day_view
            },
            {
                headers,
                cancelToken: cancelToken.token
            }
        );
    }

    return null;
}
