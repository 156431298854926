//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Badge from '@material-ui/core/Badge';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import GetPricesId from "../Cart/Functions/Margin/GetPricesId";
import GetStatusColorHexa from "../Menu/MaterialTripList/Functions/GetStatusColorHexa";
import GetDataFromCurrentVersion from "../Common/Functions/GetDataFromCurrentVersion";

import ManIcon from "../Header/ManIcon";
import KidsIcon from "../Header/KidsIcon";
import DoorIcon from "../Header/DoorIcon";
import RoomIcon from '@material-ui/icons/Room';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AssignmentIcon from '@material-ui/icons/Assignment';

import { SetSummaryStep } from "../../Actions/Summary";

const useStyles = makeStyles(theme => ({
	root: {
		height: "100%"
	},
	headerContainer: {
		top: "0px !important",
		height: "70px !important",
		backgroundColor: "white",
		textAlign: "center",
		boxShadow: "0 1px 4px rgba(0,0,0,0.3)",
		position: "fixed !important",
		left: "0px !important",
		width: "100% !important",
		zIndex: "1000 !important",
	},
	homeHeader: {
		backgroundColor: "transparent",
		boxShadow: "none"
	},
	stickyHeader: {
		position: "fixed",
		top: 0,
		width: "100%",
		height: 70,
		background: "white",
		zIndex: 100,
		transition: "all .2s ease-out",
	},
	logo: {
		margin: '4px 12px'
	},
	leftSideContainer: {
		// marginLeft: 20,
		display: 'inline-block',
		borderRadius: 22,
		boxShadow: '0px 8px 16px rgb(0 0 0 / 15%), 0px 3px 8px rgb(0 0 0 / 10%) !important'/*'0px 16px 32px rgb(0 0 0 / 15%), 0px 3px 8px rgb(0 0 0 / 10%) !important'*/
	},
	inlineBlock: {
		display: 'inline-block'
	},
	homeLink: {
		display: "inline-block",
		padding: 15,
		float: "left"
	},
	separator: {
		borderRight: "1px solid rgba(0, 0, 0, 0.23)",
    	display: "inline"
	},
	rightSideMain: {
		marginRight: 16
	},
	fitLogo: {
		width: 350,
        height: 60,
        objectFit: "contain",
        maxWidth: 350
	},
	soloButton: {
		borderRadius: 20
	},
	orange: {
		color: "#E6592F !important"
	},
	font10: {
		fontSize: 10
	},
	mobileContainer: {
		width:"100% !important",
		height:"48px !important",
		display:"inline-flex !important",
		boxShadow:"rgba(0, 0, 0, 0.2) 0px 6px 20px !important",
		color:"rgb(34, 34, 34) !important",
		background:"padding-box rgb(255, 255, 255) !important",
		borderColor:"rgba(0, 0, 0, 0.04) !important",
		borderRadius:"24px !important",
		borderStyle:"solid !important",
		borderWidth:"1px !important"
	},
	leftSide: {
		WebkitBoxPack: "center !important",
		WebkitBoxAlign: "center !important",
		flexBasis: "48px !important",
		flexShrink: "0 !important",
		minWidth: "0px !important",
		display: "flex !important",
		alignItems: "center !important",
		justifyContent: "center !important"
	},
	leftButton: {
		WebkitBoxAlign: "center !important",
		appearance: "none !important",
		display: "inline-flex !important",
		borderRadius: "50% !important",
		border: "1px solid rgba(0, 0, 0, 0.03) !important",
		outline: "0px !important",
		margin: "0px !important",
		padding: "0px !important",
		color: "rgb(34, 34, 34) !important",
		backgroundColor: "rgb(255, 255, 255) !important",
		cursor: "pointer !important",
		touchAction: "manipulation !important",
		alignItems: "center !important",
		justifyContent: "center !important",
		transition: "box-shadow 0.2s ease 0s, -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s !important",
		width: "44px !important",
		height: "44px !important",
		backgroundClip: "padding-box !important",
		boxShadow: "rgb(0 0 0 / 15%) 0px 5px 12px !important"
	},
	middleSide: {
		WebkitBoxFlex: "1 !important",
  		flexGrow: "1 !important"
	},
	middleButton: {
		cursor: "pointer !important",
		position: "relative !important",
		touchAction: "manipulation !important",
		fontFamily: "inherit !important",
		fontSize: "inherit !important",
		lineHeight: "inherit !important",
		fontWeight: "inherit !important",
		borderRadius: "0px !important",
		outline: "none !important",
		transition: "box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important",
		background: "transparent !important",
		border: "none !important",
		color: "inherit !important",
		display: "block !important",
		margin: "0px !important",
		padding: "0px !important",
		textAlign: "inherit !important",
		textDecoration: "none !important",
		height: "100% !important",
		width: "100% !important"
	},
	middleContainer : {
		WebkitBoxAlign: "center !important",
		alignItems: "center !important",
		display: "flex !important",
		paddingRight: "12px !important",
		borderRight: "1px solid rgba(0, 0, 0, 0.15) !important"
	},
	leftIcon: {
		WebkitBoxFlex: "1 !important",
		fontSize: "22px !important",
		lineHeight: "18px !important",
		fontWeight: "600 !important",
		flexGrow: "1 !important",
		paddingRight: "4px !important",
	},
	rightIcon: {
		fontSize: "14px !important",
		lineHeight: "18px !important",
		maxHeight: "18px !important",
		overflow: "hidden !important",
		display: "-webkit-box !important",
		color: "rgb(113, 113, 113) !important",
		fontWeight: "600 !important",
		textAlign: "right !important",
		flexGrow: "1 !important",
		textTransform: "capitalize"
	},
	rightSide: {
		flexBasis: "48px !important",
		flexShrink: "0 !important",
		minWidth: "48px !important"
	},
	rightButton: {
		WebkitBoxAlign: "center !important",
		WebkitBoxPack: "center !important",
		cursor: "pointer !important",
		position: "relative !important",
		touchAction: "manipulation !important",
		fontFamily: "inherit !important",
		fontSize: "inherit !important",
		lineHeight: "inherit !important",
		fontWeight: "inherit !important",
		borderRadius: "0px 24px 24px 0px !important",
		outline: "none !important",
		transition: "box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important",
		background: "transparent !important",
		border: "none !important",
		color: "inherit !important",
		display: "inline-flex !important",
		margin: "0px !important",
		padding: "0px 8px 0px 0px !important",
		textAlign: "inherit !important",
		textDecoration: "none !important",
		height: "100% !important",
		width: "100% !important",
		justifyContent: "center !important",
		alignItems: "center !important"
	},
	formControlRoot: {
		borderRight: 0,
		borderTopLeftRadius: "20px",
		borderTopRightRadius: 0,
		borderBottomLeftRadius: "20px",
		borderBottomRightRadius: 0,
        padding: '6px 13px',
        position: "relative",
		textTransform: "capitalize",
		height: 41
  	},
	formControlRoot1: {
        borderRadius: '0',
        borderLeft: 0,
        borderRight: 0,
        padding: '6px 13px',
        position: "relative",
        textTransform: "capitalize",
        height: 41
	},
	formControlRoot2: {
		borderLeft: 0,
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        padding: '6px 13px',
        position: "relative",
		textTransform: "capitalize",
        height: 41
    },
	activeButton: {
		backgroundColor: "rgba(0, 0, 0, 0.04)",
		fontWeight: "bold",
		color: "#e6592f"
	},
	bold: {
		fontWeight: "bold"
	},
	pink: {
		color: "#e0368d!important"
	},
	genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
	italic: {
		fontStyle: "italic"
	},
	underStatus: {
		verticalAlign: "-6px !important"
	},
	pointer: {
		cursor: "pointer"
	},
	marginLeft20: {
		marginLeft: 20
	},
	// select: {
	// 	"& .mui-jss-MuiSelect-select.mui-jss-MuiSelect-select": {
	// 		paddingRight: 0
	// 	}
	// }
}));

const SummaryHeader = ({missingDocs, props}) => {
    const { t, i18n } = useTranslation();
	const classes = useStyles();
	const theme = useTheme();
	const isTablet = useMediaQuery(theme.breakpoints.between(600, 960), { noSsr: true });
	const matches = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
	const smallScreen = useMediaQuery(theme.breakpoints.down(1334), { noSsr: true });
	// const smallScreen = useMediaQuery(theme.breakpoints.between(960, 1334));
	const config = JSON.parse(localStorage.getItem("config"));
	const dispatch = useDispatch();

	const trip_main_info = useSelector(store => store.summary.trip_main_info);
	const trip_info = useSelector(store => store.summary.trip_info);
	const travelers_group = useSelector(store => store.summary.travelers_group);
	const summary_step = useSelector(store => store.summary.summary_step);
	const total_price = useSelector(store => store.summary.total_price);

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    
	const current_version = trip_main_info.data[GetDataFromCurrentVersion(trip_main_info.current_version, trip_main_info.data, null)];

	const [adults, setAdults] = useState(0);
	const [children, setChildren] = useState(0);
	const [room, setRoom] = useState(0);
	const [flagMenu, setFlagMenu] = useState(false);
	const [language, setLanguage] = useState(i18n.language);
	const [tripStatus, setTripStatus] = useState("ONGOING");
	useEffect(() => {
		getTraveler();
		getRoom();
	}, []);
	useEffect(() => {
		getTraveler();
		if (trip_info !== null) {
			setTripStatus(trip_info.status);
		}
	}, [trip_info]);
	useEffect(() => {
		getRoom();
	}, [travelers_group]);
	const getTraveler = () => {
		let tmp_children = 0; 
		let tmp_adults = 0;
        if (trip_info !== null) {
            for (let i = 0; i < trip_info.travelers.length; i++) {
                let age = moment.utc(trip_info.end_date).diff(moment.utc(trip_info.travelers[i].birth_date), "y");
                if (age >= 18) {
                    tmp_adults += 1;
                }
                if (age < 18) {
                    tmp_children += 1;
                }
            }
        }
		setAdults(tmp_adults);
		setChildren(tmp_children);
	}
	const getRoom = () => {
        let tmp_nb_room = 0;
		if (travelers_group !== null) {
            for (let i = 0; i < travelers_group.length; i++) {
                if (travelers_group[i].default === true) {
                    tmp_nb_room++;
                }
            }
        }
		setRoom(tmp_nb_room);
	}
	const handleOpen = () => {
		setFlagMenu(true);
	}
	const handleClose = () => {
		setFlagMenu(false);
	}
    return (
		<div id={"header"} className={classes.headerContainer}>
			<Grid container className={classes.root} alignItems={"center"} direction={"row"} justify={"space-evenly"}>
				<Fragment>
				{
					!matches && (
						<Fragment>
							<Grid item container justify={ "flex-start" } xs={4}>
								<Grid item className={ `${classes.genericText} ${classes.leftSideContainer} ${!smallScreen ? classes.marginLeft20 : ""}` }>
									<Grid item className={ classes.inlineBlock }>
										<Button
											// onClick={handleOpen}
											variant={"outlined"}
											fullWidth
											style={{padding: smallScreen ? "5px" : "" }}
											className={clsx(classes.formControlRoot, {[classes.activeButton]: summary_step === 2, [classes.font10]: smallScreen})}
											onClick={() => {
												if (summary_step !== 2) {
													// dispatch(SetSummaryStep(2));
													props.router.push(`/${window.url_name}/summary-quotation/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`);
												}
											}} 
											// disableRipple={true}
										>
											{t("summary.my_trip")}
										</Button>
										<div className={classes.separator}/>
									</Grid>
									<Grid item className={ classes.inlineBlock }>
										<Button
											// onClick={handleOpen}
											variant={"outlined"}
											fullWidth
											style={{padding: smallScreen ? "5px" : "" }}
											className={clsx(classes.formControlRoot1, {[classes.activeButton]: summary_step === 4, [classes.font10]: smallScreen})}
											onClick={() => {
												if (summary_step !== 4) {
													// dispatch(SetSummaryStep(4));
													props.router.push(`/${window.url_name}/summary-inscription/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`);
												}
											}} 
											// disableRipple={true}
										>
											{t("summary.quotation_validation")}
										</Button>
										<div className={classes.separator}/>
									</Grid>
									<Grid item className={ classes.inlineBlock }>
										<Button
											// onClick={handleOpen}
											variant={"outlined"}
											fullWidth
											style={{padding: smallScreen ? "5px" : "" }}
											className={clsx(classes.formControlRoot1, {[classes.activeButton]: summary_step === 7, [classes.font10]: smallScreen})}
											onClick={() => {
												if (summary_step !== 7) {
													// dispatch(SetSummaryStep(7));
													props.router.push(`/${window.url_name}/summary-quotation/payment/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`);
												}
											}} 
											// disableRipple={true}
										>
											{t("menu.trip_list.payments")}
										</Button>
										<div className={classes.separator}/>
									</Grid>
									<Grid item className={ classes.inlineBlock }>
										{
											missingDocs !== 0 && (
												<Badge badgeContent={missingDocs} color="secondary" anchorOrigin={{vertical: "top", horizontal:"right"}}> 
													<Button
														// onClick={handleOpen}
														variant={"outlined"}
														fullWidth
														style={{padding: smallScreen ? "5px" : "" }}
														className={clsx(classes.formControlRoot1, {[classes.activeButton]: summary_step === 5, [classes.font10]: smallScreen})}
														onClick={() => {
															if (summary_step !== 5) {
																// dispatch(SetSummaryStep(5));
																props.router.push(`/${window.url_name}/summary-documents/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`);
															}
														}} 
														// disableRipple={true}
													>
														{t("menu.trip_list.documents")}
													</Button>
												</Badge>

											)
										}
										{
											missingDocs === 0 && (
												<Button
													// onClick={handleOpen}
													variant={"outlined"}
													fullWidth
													style={{padding: smallScreen ? "5px" : "" }}
													className={clsx(classes.formControlRoot1, {[classes.activeButton]: summary_step === 5, [classes.font10]: smallScreen})}
													onClick={() => {
														if (summary_step !== 5) {
															// dispatch(SetSummaryStep(5));
															props.router.push(`/${window.url_name}/summary-documents/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`);
														}
													}} 
													// disableRipple={true}
												>
													{t("menu.trip_list.documents")}
												</Button>
											)
										}
										<div className={classes.separator}/>
									</Grid>
									<Grid item className={ classes.inlineBlock }>
										<Button
											// onClick={handleOpen}
											variant={"outlined"}
											fullWidth
											style={{padding: smallScreen ? "5px" : "" }}
											className={clsx(classes.formControlRoot2, {[classes.activeButton]: summary_step === 6, [classes.font10]: smallScreen})}
											onClick={() => {
												if (summary_step !== 6) {
													// dispatch(SetSummaryStep(6));
													props.router.push(`/${window.url_name}/summary-contact/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`);
												}
											}} 
											// disableRipple={true}
										>
											{t("summary.contact")}
										</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid className={ classes.logoContainer } item container xs={2}>
								<img className={ classes.fitLogo} src={ trip_main_info !== null ? (trip_main_info.target_client.logo !== null ? (trip_main_info.target_client.logo.thumbnail_little !== null ? trip_main_info.target_client.logo.thumbnail_little : trip_main_info.target_client.logo.url) : "/Img/inner_logo_header.png" ) : "/Img/inner_logo_header.png" } alt={ "" }/>
							</Grid>
							<Grid className={ `${classes.genericText} ${!smallScreen ? classes.rightSideMain : ""}` } item container justify={"flex-end"} alignItems={"center"} spacing={ 4 } xs={6}>
								<Grid item className={`${classes.bold} ${classes.genericText} ${smallScreen ? classes.font10 : ""}`}>
									<Grid container direction={"column"}>
										<Grid item>
											{
												!['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines'].includes(quotation_code) && (
													<Fragment>
														<Tooltip title={ trip_info !== null ? t("menu.trip_list." + trip_info.status) : "" }>
															<FiberManualRecordIcon style={{ color: GetStatusColorHexa(tripStatus), marginRight: 5, paddingLeft: 3, fontSize: 33, verticalAlign: "middle" }}/>
														</Tooltip>
														<span >{ trip_main_info !== null ? t("menu.file_nb") + trip_main_info.trip_reference : 0 }</span>
													</Fragment>
												)
											}
											{
												['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines'].includes(quotation_code) && (
													<Fragment>
														{
															current_version.status_contract !== null && (
																<Fragment>
																	<Tooltip title={t("menu.trip_list.TRIP_STATUS_CONTRACT")}>
																		<ReceiptIcon fontSize={"small"} style={{verticalAlign: "-5px"}}/>
																	</Tooltip>
																	<Tooltip title={t("menu.trip_list." + (current_version.status_contract))}>
																		<FiberManualRecordIcon style={{color: GetStatusColorHexa(current_version.status_contract), verticalAlign: "-7px"}} />
																	</Tooltip>
																</Fragment>
															)
														}
														{
															current_version.status_contract === "CONFIRMED" && current_version.status_modification !== null && (
																<Fragment>
																	{
																		current_version.status_modification === "AMENDMENT" && (
																			<Tooltip title={t("menu.trip_list.TRIP_STATUS_MODIFICATION")}>
																				<SettingsIcon fontSize={"small"} style={{verticalAlign: "-5px"}}/>
																			</Tooltip>
																		)
																	}
																	{
																		current_version.status_modification === "FIXED_PV" &&  (
																			<Tooltip title={t("menu.trip_list.TRIP_STATUS_BOOKING_PROGRESS")}>
																				<AssignmentTurnedInIcon fontSize={"small"} style={{verticalAlign: "-5px"}}/>
																			</Tooltip>
																		)
																	}
																	<Tooltip title={t("menu.trip_list." + (current_version.status_modification))}>
																		<FiberManualRecordIcon style={{color: GetStatusColorHexa(current_version.status_modification), verticalAlign: "-7px"}} />
																	</Tooltip>
																</Fragment>
															)
														}
														{
															current_version.status_contract === "CONFIRMED" && current_version.status_modification === "FIXED_PV" && current_version.status_booking_progress !== null && (
																<Fragment>
																	<Tooltip title={t("menu.trip_list.TRIP_STATUS_BOOKING_PROGRESS")}>
																		<AssignmentIcon fontSize={"small"} style={{verticalAlign: "-5px"}}/>
																	</Tooltip>
																	<Tooltip title={t("menu.trip_list." + (current_version.status_booking_progress))}>
																		<FiberManualRecordIcon style={{color: GetStatusColorHexa(current_version.status_booking_progress), verticalAlign: "-7px"}} />
																	</Tooltip>
																</Fragment>
															)
														}
														<span >{ trip_main_info !== null ? t("menu.file_nb") + trip_main_info.trip_reference : 0 }</span>
													</Fragment>
												)
											}
										</Grid>
									</Grid>
								</Grid>
								<Grid item className={`${smallScreen ? classes.font10 : ""}`}>
									<RoomIcon style={{verticalAlign: 'sub', opacity: '80%'}}/>
									<span>{ trip_info !== null ? trip_info.destination.data.name !== undefined ? trip_info.destination.data.name : trip_info.destination.data.international_name : null }</span>
								</Grid>
								<Grid item className={`${classes.bold} ${classes.pink} ${smallScreen ? classes.font10 : ""}`}>{trip_info !== null ? new Intl.NumberFormat(i18n.language).format(total_price.total) + " " + trip_info.trip.currency.symbol : 0 }</Grid>
								<Grid item className={`${smallScreen ? classes.font10 : ""}`}>
									<ManIcon style={{verticalAlign: 'sub', opacity: '50%', fontSize: smallScreen ? 15 : ""}}/>
									<span style={{paddingRight: 4, fontWeight: "bold"}}>{adults}</span>
									<KidsIcon style={{verticalAlign: 'sub', opacity: '80%', fontSize: smallScreen ? 15 : ""}}/>
									<span style={{paddingLeft: 4, fontWeight: "bold"}}>{children}</span>
									<DoorIcon style={{verticalAlign: 'sub', opacity: '80%', fontSize: smallScreen ? 15 : ""}}/>
									<span style={{paddingLeft: 4, fontWeight: "bold"}}>{room}</span>
								</Grid>
								<Grid item>
									<Select
										// className={classes.select}
										open={flagMenu}
										onClose={handleClose}
										onOpen={handleOpen}
										value={language}
										disableUnderline 
										onChange={(event) => {
											i18n.changeLanguage(event.target.value);
											moment.locale(event.target.value);
											localStorage.setItem("i18nextLng", event.target.value);
											setLanguage(event.target.value);
										}}
										// renderValue={() => {
										// 	<span className={`${"flag-icon flag-icon-" + (language === "en" ? "us" : language)}`}/>
										// }}
									>
										<MenuItem value="fr">
											<span className={`${"flag-icon flag-icon-fr" }`}/>
										</MenuItem>
										<MenuItem value="en">
											<span className={`${"flag-icon flag-icon-us" }`}/>
										</MenuItem>
									</Select>
								</Grid>
							</Grid>
						</Fragment>
					)
				}
				{
					matches && (
						<Fragment>
							<Grid item className={`${classes.bold} ${classes.genericText}`}>
								<Grid container direction={"column"}>
									<Grid item>
										<span >{ trip_main_info !== null ? t("menu.file_nb") + trip_main_info.trip_reference : 0 }</span>
									</Grid>
									<Grid item>
										<span className={`${classes.italic}`} >{ trip_info !== null ? t("menu.trip_list." + trip_info.status) : null }</span>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Grid container direction={"column"}>
									<Grid item>
										<RoomIcon style={{verticalAlign: 'sub', opacity: '80%'}}/>
										<span>{ trip_info !== null ? trip_info.destination.data.name !== undefined ? trip_info.destination.data.name : trip_info.destination.data.international_name : null }</span>
									</Grid>
									<Grid item>
										<span className={`${classes.bold} ${classes.pink}`} >{trip_info !== null ? new Intl.NumberFormat(i18n.language).format(Math.ceil(total_price.total)) + " " + trip_info.trip.currency.symbol : 0 }</span>
									</Grid>
								</Grid>
							</Grid>
						</Fragment>
					)
				}
				</Fragment>
			</Grid>
        </div>
    )
}
export default SummaryHeader;