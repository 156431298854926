import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FilterListIcon from '@mui/icons-material/FilterList';
import TableRowsIcon from '@mui/icons-material/TableRows';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from "@material-ui/core";
import TextField from '@mui/material/TextField';

const useStyles = makeStyles({
    customDrawer: {
        zIndex: 1,
        paddingTop: 70
    }
});

const ListHandler = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    //Column popover
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickColumnPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseColumnPopover = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    //Density popover
    const [anchorElDensity, setAnchorElDensity] = useState(null);
    const [density, setDensity] = useState('high');
    const handleClickDensityPopover = (event) => {
        setAnchorElDensity(event.currentTarget);
    };
    const handleCloseDensityPopover = () => {
        setAnchorElDensity(null);
    };
    const setDensityValue = (value) => {
        setAnchorElDensity(null);
        setDensity(value);
        if (props.onChangeDensity !== undefined) {
            props.onChangeDensity(value);
        }
    };
    const openDensity = Boolean(anchorElDensity);

    //Filter drawer
    const [anchorElDrawer, setAnchorElDrawer] = useState(null);
    const handleClickDrawer = (event) => {
        setAnchorElDrawer(event.currentTarget);
    };
    const handleCloseDrawer = () => {
        setAnchorElDrawer(null);
    };

    //Custom filter
    const [customFilter, setCustomFilter] = useState(props.customFilter.multiple ? [] : null);
    useEffect(() => {
        if (props.customFilter?.value) {
            setCustomFilter(props.customFilter.value);
        }
    }, [props.customFilter]);
    const handleChangeCustomFilter = (event) => {
        setCustomFilter(event.target.value);
        if (props.customFilter.onChange !== undefined) {
            props.customFilter.onChange(event.target.value);
        }
    };
    
    //Creator filter
    const [creator, setCreator] = useState(null);
    const handleChangeCreator = (event) => {
        setCreator(event.target.value);
        if (props.onCreatorChange !== undefined) {
            props.onCreatorChange(event.target.value);
        }
    };
    const [editor, setEditor] = useState(null);
    const handleChangeEditor = (event) => {
        setEditor(event.target.value);
        if (props.onEditorChange !== undefined) {
            props.onEditorChange(event.target.value);
        }
    };

    //Period filter
    const [periodFilter, setPeriodFilter] = useState(null);
    const [periodSelection, setPeriodSelection] = useState("current_month");
    const handleChangePeriodFilter = (event) => {
        setPeriodFilter(event.target.value);
    };
    const handleChangePeriodSelection = (event) => {
        setPeriodSelection(event.target.value);
        if (props.periodFilter.onChange !== undefined) {
            const qp = transformDateRange(periodFilter, event.target.value);
            props.periodFilter.onChange(qp);
        }
    };

    const transformDateRange = (type, input) => {
        const currentDate = new Date();
        let startDate;
        //let endDate = currentDate.toISOString();
        console.log("fil_c D", input);
        switch (input) {
            case 'current_week':
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay());
                break;
            case 'last_week':
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay() - 7);
                break;
            case 'current_month':
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                break;
            case 'last_month':
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
                //endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).toISOString();
                break;
            case 'last_30':
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 30);
                break;
            case 'this_year':
                startDate = new Date(currentDate.getFullYear(), 0, 1);
                break;
            default:
                return '';
        }
        const formattedStartDate = startDate.toISOString();
        return `${type}__gte=${formattedStartDate}`;
    };

    //Reset all filter
    const resetFilter = () => {
        setCustomFilter(props.customFilter.multiple ? [] : null);
        setPeriodFilter(null);
        setPeriodSelection("current_month");
    };

    const closeFilter = () => {
        resetFilter();
        setAnchorElDrawer(null);
    };

    //Set column order
    const setColumnOrder = (field, direction) => {
        const list = props.columns;
        const itemIndex = list.findIndex(item => item.field === field);
        if (itemIndex !== -1) {
            const updatedList = [...list];
            const itemToMove = updatedList[itemIndex];
            updatedList.splice(itemIndex, 1);

            if (direction === 'up') {
                updatedList.splice(itemIndex - 1, 0, itemToMove);
            } else if (direction === 'down') {
                updatedList.splice(itemIndex + 1, 0, itemToMove);
            }

            if (props.onUpdateColumns !== undefined) {
                props.onUpdateColumns(updatedList);
            }
        }
    };

    //Set column order
    const switchColumnDisplay = (field) => {
        const list = props.columns;
        const itemIndex = list.findIndex(item => item.field === field);
        if (itemIndex !== -1) {
            let updatedList = [...list];
            updatedList[itemIndex].active = !updatedList[itemIndex].active;
            if (props.onUpdateColumns !== undefined) {
                props.onUpdateColumns(updatedList);
            }
        }
    };

    return (
        <div className="list-handler">
            <Tooltip title={t('list.columns_to_render')}>
                <Button variant="outlined" startIcon={<ViewColumnIcon />} onClick={handleClickColumnPopover}>{t('list.columns')}</Button>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseColumnPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <div className="column-settings">
                    <p>{t('list.column_title')}</p>
                    {
                        props.columns?.map((column, col_index) => {
                            return (
                                <div>
                                    <FormControlLabel control={<Checkbox checked={column.active} onChange={() => switchColumnDisplay(column.field)} />} label={column.label} />
                                    <div>
                                        {col_index !== 0 && <KeyboardArrowUpIcon onClick={() => setColumnOrder(column.field, 'up')} />}
                                        {col_index !== props.columns.length - 1 && <KeyboardArrowDownIcon onClick={() => setColumnOrder(column.field, 'down')} />}
                                        {col_index === props.columns.length - 1 && <div style={{ visibility: "hidden" }}><KeyboardArrowDownIcon /></div>}
                                    </div>
                                </div>
                            );
                        })
                    }
                    {/*<Button variant="outlined">{t('list.save')}</Button> */}
                </div>
            </Popover>
            <Tooltip title={t('list.show_filters')}>
                <Button variant="outlined" startIcon={<FilterListIcon />} onClick={handleClickDrawer}>{t('list.filters')}</Button>
            </Tooltip>
            <Tooltip title={t('list.set_density')}>
                <Button variant="outlined" startIcon={<TableRowsIcon />} onClick={handleClickDensityPopover}>{t('list.density')}</Button>
            </Tooltip>
            <Popover
                open={openDensity}
                anchorEl={anchorElDensity}
                onClose={handleCloseDensityPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <div className="density-settings">
                    <p>{t('list.set_density')}</p>
                    <div>
                        <Button variant="outlined" onClick={() => setDensityValue('high')} style={{ backgroundColor: density === 'high' ? '#d3d3d366' : '' }}>
                            {t('list.density_high')}
                        </Button>
                        <Button variant="outlined" onClick={() => setDensityValue('medium')} style={{ backgroundColor: density === 'medium' ? '#d3d3d366' : '' }}>
                            {t('list.density_medium')}
                        </Button>
                        <Button variant="outlined" onClick={() => setDensityValue('low')} style={{ backgroundColor: density === 'low' ? '#d3d3d366' : '' }}>
                            {t('list.density_low')}
                        </Button>
                    </div>
                    {/*<Button variant="outlined">{t('list.save')}</Button> */}
                </div>
            </Popover>
            <Drawer
                classes={{ paper: classes.customDrawer }}
                anchor={'right'}
                open={Boolean(anchorElDrawer)}
                onClose={handleCloseDrawer}
                variant="persistent"
            >
                <div className="filter-panel">
                    <div> <CloseIcon onClick={closeFilter} /> <h4>{t('list.filters')}</h4> </div>
                    {
                        props.customFilter &&
                        <>
                            <hr />
                            <h6>{t('list.custom_filters')}</h6>
                            <FormControl fullWidth size="small">
                                <InputLabel id="custom-filter-input-label">{props.customFilter.label ? props.customFilter.label : t('list.custom_filters')}</InputLabel>
                                <Select
                                    labelId="custom-filter-input-label"
                                    id="custom-filter-input"
                                    value={customFilter}
                                    label={props.customFilter.label ? props.customFilter.label : t('list.custom_filters')}
                                    onChange={handleChangeCustomFilter}
                                    multiple={Boolean(props.customFilter?.multiple)}
                                >
                                    {
                                        props.customFilter.options?.map(option => {
                                            return (
                                                <MenuItem value={option.value}>{option.label}</MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </>
                    }

                    <br/>
                    <br/>
                    <FormControl fullWidth size="small">
                        <TextField label={t('crm.created_by')} variant="outlined" onChange={handleChangeCreator} value={creator} size="small" />
                    </FormControl>
                    <br/>
                    <br/>
                    <FormControl fullWidth size="small">
                        <TextField label={t('crm.edited_by')} variant="outlined" onChange={handleChangeEditor} value={editor} size="small" />
                    </FormControl>

                    {
                        props.periodFilter &&
                        <>
                            <hr />
                            <h6>{t('list.period_filters')}</h6>
                            <FormControl size="small">
                                <RadioGroup
                                    name="period-filters"
                                    value={periodFilter}
                                    onChange={handleChangePeriodFilter}
                                >
                                    {
                                        props.periodFilter.options?.map(option => {
                                            return <FormControlLabel value={option.value} control={<Radio />} label={option.label} />;
                                        })
                                    }
                                </RadioGroup>
                            </FormControl>
                        </>
                    }
                    {
                        periodFilter &&
                        <>
                            <FormControl fullWidth size="small">
                                <Select
                                    value={periodSelection}
                                    onChange={handleChangePeriodSelection}
                                >
                                    <MenuItem value="current_week">{t('list.current_week')}</MenuItem>
                                    <MenuItem value="last_week">{t('list.last_week')}</MenuItem>
                                    <MenuItem value="current_month">{t('list.current_month')}</MenuItem>
                                    <MenuItem value="last_month">{t('list.last_month')}</MenuItem>
                                    <MenuItem value="last_30">{t('list.last_30')}</MenuItem>
                                    <MenuItem value="this_year">{t('list.this_year')}</MenuItem>
                                </Select>
                            </FormControl>
                        </>
                    }

                    <div>
                        <Button variant="outlined" onClick={resetFilter}>{t('list.reset')}</Button>
                        <Button variant="outlined">{t('list.apply')}</Button>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};
export default React.memo(ListHandler);
