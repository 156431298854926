//---- Description : ----//
//---- Contact : kevin.deffontaine@facilitatrip.com ----//

//---- Dependencies ----//
import React, {Fragment} from 'react';
//import 'fullcalendar';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

//---- Runner ----//
import CalendarRun from "./Functions/CalendarRun.js";
import CheckItinerary from "./Functions/CheckItinerary.js";
import GetDiffDays from "./Functions/GetDiffDays.js";
import SortByKey from "../Common/SortByKey";

//---- Actions ----//
import { ToggleCalendarModal, SetEvent, SelectCalendarDay, SetFullscreen } from "../../Actions/Calendar";
import { SetDates } from "../../Actions/Accommodation";
import { ZoomOnDestination, SetCenterZoomBounds } from "../../Actions/Base";

//---- CSS ----//
import "../../Style/Calendar.css";

@connect((store) => {
    return {
        flight_cart: store.flight.cart,
        cars_cart: store.cars_search.cart,
        accomodation_cart: store.accommodation.cart,
        language: store.header.tmp_language,
        modal_calendar: store.calendar.modal_calendar,
        current_calendar_day: store.calendar.current_calendar_day,
        modal_info: store.calendar.modal_info,
        start_date: store.trip.start_date,
        end_date: store.trip.end_date,
        itinerary_list: store.itinerary.itinerary_list,
        departure_destination: store.itinerary.departure_destination,
        return_destination: store.itinerary.return_destination,
        accommodationEvent: store.calendar.accommodations,
        flightEvent: store.calendar.flights,
        carsEvent: store.calendar.cars,
        select_day: store.calendar.select_day,
        fullscreen: store.calendar.fullscreen
    };
})
@withTranslation()

//---- Class ----//
class Calendar extends React.Component {
    componentWillMount() {
        const { start_date, select_day, dispatch } = this.props;
        this.getEvent();
        if (select_day === null) {
            dispatch(SelectCalendarDay(moment.utc(start_date).format("YYYY-MM-DD")));
        }
    }
    // componentDidMount() {
    //     const {language, dispatch, accommodationEvent, flightEvent, carsEvent, start_date, end_date, current_calendar_day, itinerary_list, departure_destination, return_destination, t} = this.props;
    //     console.log("accommodation:", accommodationEvent);
    //     console.log("flight:", flightEvent);
    //     CalendarRun(false, dispatch, language, flightEvent, carsEvent, accommodationEvent, start_date, end_date, current_calendar_day, itinerary_list, departure_destination, return_destination, t);
    // }
    componentDidUpdate(prevProps) {
        const {flight_cart, fullscreen, itinerary_list, cars_cart, accomodation_cart, language, dispatch, accommodationEvent, flightEvent, carsEvent, start_date, end_date, current_calendar_day, departure_destination, return_destination, select_day, t} = this.props;
        if (prevProps.flight_cart !== flight_cart || prevProps.itinerary_list !== itinerary_list || prevProps.cars_cart !== cars_cart || prevProps.accomodation_cart !== accomodation_cart) {
            this.getEvent();
        }
        if (prevProps.accommodationEvent !== accommodationEvent || prevProps.flightEvent !== flightEvent || prevProps.carsEvent !== carsEvent || prevProps.language !== language) {
            CalendarRun(fullscreen, dispatch, language, flightEvent, carsEvent, accommodationEvent, start_date, end_date, current_calendar_day, itinerary_list, departure_destination, return_destination, select_day, t);
            CheckItinerary();
        }
        if (prevProps.select_day !== select_day) {
            itinerary_list.map((itinerary) => {
                if (moment.utc(select_day).isAfter(itinerary.start_date) && moment.utc(select_day).isBefore(itinerary.end_date) && itinerary.destination !== null) {
                    let bounds = JSON.parse(itinerary.destination.data.bounds.replace(/'/g,'"'));
                    dispatch(ZoomOnDestination({
                        lat_min: bounds.north,
                        lng_min: bounds.east,
                        lat_max: bounds.south,
                        lng_max: bounds.west
                    }, itinerary.id));
                    dispatch(SetCenterZoomBounds({
                        center: {
                            lat: itinerary.destination.data.latitude_center,
                            lng: itinerary.destination.data.longitude_center
                        },
                        zoom: itinerary.destination.data.zoom_level === null ? 7 : itinerary.destination.data.zoom_level,
                        bounds: {
                            lat_min: bounds.north,
                            lng_min: bounds.east,
                            lat_max: bounds.south,
                            lng_max: bounds.west
                        }
                    }));
                    dispatch(SetDates(moment.utc(itinerary.start_date).format("YYYY-MM-DD"), moment.utc(itinerary.end_date).format("YYYY-MM-DD")));
                }
            });
            CalendarRun(fullscreen, dispatch, language, flightEvent, carsEvent, accommodationEvent, start_date, end_date, current_calendar_day, itinerary_list, departure_destination, return_destination, select_day, t);
        }
        if (prevProps.fullscreen !== fullscreen) {
          $('#calendar').fullCalendar('changeView', (!fullscreen ? "agendaThreeDay" : "agendaSevenDay"));
          if (fullscreen) {
            $("#itinerary-wrapper").css({"display": "none"});
            $("#apps-steps-choosing-step").css({"display": "none"});
            $("#panel_container").css({"width": "100%"});
            $(".fc-time-grid-container").css({"height": "calc(100vh - 195px) !important"});
            // $("#calendar").fullCalendar('option', {
            //   header: {
            //     left: "custom1,custom2",
            //     center: "prev next",
            //     right: ""
            //   },
            //   customButtons: {
            //       custom1: {
            //           text: 'custom1',
            //           icon: "",
            //           click: function() {
            //               alert('clicked custom button 1!');
            //           }
            //       },
            //       custom2: {
            //           text: 'custom2',
            //           icon: "",
            //           click: function() {
            //               alert('clicked custom button 2!');
            //           }
            //       }
            //   }
            // });
          }
          else {
            $("#itinerary-wrapper").css({"display": "inline-block"});
            $("#apps-steps-choosing-step").css({"display": "inline-block"});
            $("#panel_container").css({"width": "50%"});
            $(".fc-time-grid-container").css({"height": "calc(100vh - 164px) !important"});
            // $("#calendar").fullCalendar('option', {
            //   header: {
            //     left: "",
            //     center: "prev next",
            //     right: ""
            //   }
            // });
          }
        }
    }
    getEvent() {
       const {flight_cart, itinerary_list, cars_cart, accomodation_cart, departure_destination, return_destination, t, dispatch} = this.props;
       let flights = [];
       let cars = [];
       let accommodations = [];
       let tmp_iti = [];
       //id 1 === plane; 2 === accommodations; 3 === cars; 4 === transport-line
       flight_cart.map((flight) => {
           flight.outbounds.map((outbound) => {
               let arrival_time = outbound.legs[outbound.legs.length - 1].arrival_time.split("Z");
               let departure_time = outbound.legs[0].departure_time.split("Z");
               let a = moment.utc(arrival_time[0]);
               let b = moment.utc(departure_time[0]);
               let timeDiff = a.diff(b, "hours", true);
               let flight_distance = null;
               let flight_duration = null;
               outbound.legs.map((leg) => {
                   flight_distance += leg.distance;
                   flight_duration += leg.flight_time;
               });
               flights.push(
                   {
                       type: 1,
                       date: t("calendar.hour_from") + outbound.legs[0].origin.iata_city.international_name + " " + b.format("HH:mm"),
                       start: b.format("YYYY-MM-DD HH:mm:ss"),
                       end: b.add(1, "h").format("YYYY-MM-DD HH:mm:ss"),
                       city: outbound.legs[0].origin.iata_city.international_name,
                       icoPath: "/Img/transport/transport-plane.png",
                       coord: {
                           lat: outbound.legs[0].origin.latitude,
                           lng: outbound.legs[0].origin.longitude
                       }
                   }
               );
               flights.push(
                   {
                       type: 4,
                       start: b.format("YYYY-MM-DD HH:mm:ss"),
                       end: a.format("YYYY-MM-DD HH:mm:ss"),
                       info: outbound.legs,
                       start_city: outbound.legs[0].origin.iata_city.international_name,
                       end_city: outbound.legs[outbound.legs.length - 1].destination.iata_city.international_name,
                       transportInfo: flight_distance + "km | " + parseInt(flight_duration / 60) + "h" + flight_duration % 60,
                       transportIcons: "/Img/transport/route-plane.png"
                   }
               );
               flights.push(
                   {
                       type: 1,
                       date: t("calendar.hour_from") + outbound.legs[outbound.legs.length - 1].destination.iata_city.international_name + " " + a.format("HH:mm"),
                       start: a.format("YYYY-MM-DD HH:mm:ss"),
                       end: a.add(1, "h").format("YYYY-MM-DD HH:mm:ss"),
                       city: outbound.legs[outbound.legs.length - 1].destination.iata_city.international_name,
                       icoPath: "/Img/transport/city.png",
                       coord: {
                           lat: outbound.legs[outbound.legs.length - 1].destination.latitude,
                           lng: outbound.legs[outbound.legs.length - 1].destination.longitude
                       }
                   }
               );
           });
       });
       if (itinerary_list.length > 2) {
           itinerary_list.map((itinerary, index) => {
               // let vehicle = itinerary.step_type !== "END" ? r2r.vehicles[r2r.segments[0].vehicle].kind : null;
               if (itinerary.step_type === "START") {
                   let city = itinerary_list[index + 1].destination.data.name !== undefined ? itinerary_list[index + 1].destination.data.name.split(",") : itinerary_list[index + 1].destination.data.international_name.split(",");
                   tmp_iti.push({
                       start_city: departure_destination.city,
                       end_city: city[0],
                       start_date: itinerary.end_date,
                       end_date: itinerary_list[index + 1].start_date,
                       transportInfo: itinerary.r2r_json.distance_string + " | " + itinerary.r2r_json.duration_string,
                       transportIcons: "/Img/transport/route-" + itinerary.r2r_json.vehicle.kind + ".png",
                       icoPath: "/Img/transport/transport-" + itinerary.r2r_json.vehicle.kind + ".png",
                       start_coord: {
                           lat: departure_destination.lat,
                           lng: departure_destination.lng
                       },
                       end_coord: {
                           lat: itinerary_list[index + 1].destination.data.latitude,
                           lng: itinerary_list[index + 1].destination.data.longitude,
                       }
                   });
               }
               if (itinerary.step_type === "END") {
                   let city = itinerary_list[index - 1].destination.data.name !== undefined ? itinerary_list[index - 1].destination.data.name.split(",") : itinerary_list[index - 1].destination.data.international_name.split(",");
                   tmp_iti.push({
                       start_city: city[0],
                       end_city: return_destination.city,
                       start_date: itinerary_list[index - 1].end_date,
                       end_date: itinerary.start_date,
                       transportInfo: itinerary_list[index - 1].r2r_json.distance_string + " | " + itinerary_list[index - 1].r2r_json.duration_string,
                       transportIcons: "/Img/transport/route-" + itinerary_list[index - 1].r2r_json.vehicle.kind + ".png",
                       icoPath: "/Img/transport/transport-" + itinerary_list[index - 1].r2r_json.vehicle.kind + ".png",
                       start_coord: {
                           lat: itinerary_list[index - 1].destination.latitude,
                           lng: itinerary_list[index - 1].destination.longitude
                       },
                       end_coord: {
                           lat: return_destination.lat,
                           lng: return_destination.lng,
                       }
                   });
               }
               if (itinerary.step_type === "STEP") {
                   if (itinerary_list[index - 1] !== undefined && itinerary_list[index - 1].step_type !== "START") {
                       let city1 = itinerary_list[index - 1].destination.data.name !== undefined ? itinerary_list[index - 1].destination.data.name.split(",") : itinerary_list[index - 1].destination.data.international_name.split(",");
                       let city2 = itinerary.destination.data.name !== undefined ? itinerary.destination.data.name.split(",") : itinerary.destination.data.international_name.split(",");
                       tmp_iti.push({
                           start_city: city1[0],
                           end_city: city2[0],
                           start_date: itinerary_list[index - 1].end_date,
                           end_date: itinerary.start_date,
                           transportInfo: itinerary_list[index - 1].r2r_json.distance_string + " | " + itinerary_list[index - 1].r2r_json.duration_string,
                           transportIcons: "/Img/transport/route-" + itinerary_list[index - 1].r2r_json.vehicle.kind + ".png",
                           icoPath: "/Img/transport/transport-" + itinerary_list[index - 1].r2r_json.vehicle.kind + ".png",
                           start_coord: {
                               lat: itinerary_list[index - 1].destination.latitude,
                               lng: itinerary_list[index - 1].destination.longitude
                           },
                           end_coord: {
                               lat: itinerary.destination.latitude,
                               lng: itinerary.destination.longitude,
                           }
                       });
                   }
               }
           });
        }
       tmp_iti.map((flight) => {
           let a = moment.utc(flight.end_date);
           let b = moment.utc(flight.start_date);
           let timeDiff = a.diff(b, "hours", true);
           let found = flights.filter((event) => {
               return ((event.start).includes(flight.start_city) && moment.utc(flight.start_date).format("YYYY-MM-DD") === moment.utc(event.start).format("YYYY-MM-DD"));
           });
           if (found.length === 0) {
               flights.push(
                   {
                       type: 1,
                       date: t("calendar.hour_from") + flight.start_city + " " + b.format("HH:mm"),
                       start: b.format("YYYY-MM-DD HH:mm:ss"),
                       end: b.add(1, "h").format("YYYY-MM-DD HH:mm:ss"),
                       city: flight.start_city,
                       icoPath: flight.icoPath,
                       coord: flight.start_coord
                   }
               );
               flights.push(
                   {
                       type: 4,
                       start: b.format("YYYY-MM-DD HH:mm:ss"),
                       end: a.format("YYYY-MM-DD HH:mm:ss"),
                       start_city: flight.start_city,
                       end_city: flight.end_city,
                       transportInfo: flight.transportInfo,
                       transportIcons: flight.transportIcons
                   }
               );
               flights.push(
                   {
                       type: 1,
                       date: t("calendar.hour_from") + flight.end_city + " " + a.format("HH:mm"),
                       start: a.format("YYYY-MM-DD HH:mm:ss"),
                       end: a.add(1, "h").format("YYYY-MM-DD HH:mm:ss"),
                       city: flight.end_city,
                       icoPath: "/Img/transport/city.png",
                       coord: flight.end_coord
                   }
               );
           }
       });
       // cars_cart.map((car) => {
       //     cars.push(
       //     {
       //         title: t("global.car"),
       //         type: 2,
       //         start: moment.utc(car.start_date).format("YYYY-MM-DD HH:mm:ss"),
       //         end: moment.utc(car.end_date).format("YYYY-MM-DD HH:mm:ss")
       //     });
       // });
       accomodation_cart.map((accommodation) => {
           let date = moment.utc(accommodation.start_date).format("YYYY-MM-DD HH:mm:ss");
           while (moment.utc(date).format("YYYY-MM-DD") !== moment.utc(accommodation.end_date).format("YYYY-MM-DD")) {
               accommodations.push(
               {
                   type: 3,
                   start: moment.utc(date).add(21, "h").format("YYYY-MM-DD HH:mm:ss"),
                   end: moment.utc(date).add(1, "d").add(8, "h").format("YYYY-MM-DD HH:mm:ss"),
                   info: accommodation.hotel,
                   title: accommodation.hotel[0].name,
                   coord: {
                       lat: accommodation.hotel[0].latitude,
                       lng: accommodation.hotel[0].longitude
                   }
               });
               date = moment.utc(date).add(1, "d").format("YYYY-MM-DD HH:mm:ss");
           }
       });
       let concat_array = accommodations.concat(flights).concat(cars);
       concat_array.sort(SortByKey(true, "start"));
       let filter_array = concat_array.filter((event) => {
           return event.type !== 4;
       });
       dispatch(SetEvent(accommodations, flights, cars, filter_array));
    }
    render() {
        const {modal_calendar, modal_info, fullscreen, dispatch, t} = this.props;
        return (
            <Fragment>
                <div id={ "calendar" }/>
                <button className={fullscreen ? "icon-small-screen-arrow calendar-fullscreen" : "icon-full-screen-arrow calendar-fullscreen"} onClick={ () => {dispatch(SetFullscreen(!fullscreen))} }/>
                <Modal id={ "calendar-modal-info" } isOpen={ modal_calendar } toggle={() => { dispatch(ToggleCalendarModal(false)); }} size={"lg"}>
                    <ModalHeader toggle={() => { dispatch(ToggleCalendarModal(false)); }}>
                        {modal_info ? modal_info.title : ""}
                    </ModalHeader>
                    <ModalBody className={"grey-bg ft-gray"}>
                        {
                            modal_info && (
                                <Fragment>
                                    {
                                        modal_info.id === 1 && (
                                            <div>
                                                <div className={ "ft-font-20 underline ft-dark-green" }>{modal_info.info[0].origin.name + " - " + modal_info.info[modal_info.info.length - 1].destination.name + ":"}</div>
                                            {
                                                modal_info.info.map((info, info_index) => {
                                                    return (
                                                        <div key={info_index}>
                                                            <div>
                                                                <span className={"bold"}>{t("flight_groups.departure") + " "}</span>{info.origin.name + " " + t("global.at") + " " + moment.utc(info.departure_time).format("HH:mm")}
                                                            </div>
                                                            <div>
                                                                <span className={"bold"}>{t("flight_groups.destination") + " "}</span>{info.destination.name + " " + t("global.at") + " " + moment.utc(info.arrival_time).format("HH:mm")}
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                            </div>
                                        )
                                    }
                                    {
                                        modal_info.id === 3 && (
                                            modal_info.info.map((info, info_index) => {
                                                return (
                                                    <div key={info_index}>
                                                        <div className={"ft-font-20 underline ft-dark-green"}>{info.name}</div>
                                                        <div><span className={"bold"}>{t("menu.profile.house_nb") + ": "}</span>{info.house_number}</div>
                                                        <div><span className={"bold"}>{t("menu.profile.address") + ": "}</span>{info.street}</div>
                                                        <div><span className={"bold"}>{t("menu.profile.city") + ": "}</span>{info.city_name}</div>
                                                        <div><span className={"bold"}>{t("menu.profile.postal_code") + ": "}</span>{info.zip_code}</div>
                                                    </div>
                                                );
                                            })
                                        )
                                    }
                                </Fragment>
                            )
                        }
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

export default Calendar;
