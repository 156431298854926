import { SetItineraryStep, ItineraryError } from "../../../Actions/CarsSearch";

export default (itinerary_list, departure_destination, return_destination, dispatch, cars_steps) => {
	let new_dest = [];
	let error = false;
	for (let i = 0; i < itinerary_list.length; i++) {
	    if (itinerary_list[i].step_type !== "START" && itinerary_list[i].step_type !== "END" && itinerary_list[i + 1] !== undefined && itinerary_list[i + 1].step_type !== "END") {
	    	if (Object.keys(itinerary_list[i].r2r_json).length !== 0) {
		        let origin = itinerary_list[i].destination !== null ? itinerary_list[i].destination.data.name !== undefined ? itinerary_list[i].destination.data.name.split(",") : itinerary_list[i].destination.data.international_name.split(",") : departure_destination.city;
		        let destination = itinerary_list[i + 1].destination!== null ? itinerary_list[i + 1].destination.data.name !== undefined ? itinerary_list[i + 1].destination.data.name.split(",") : itinerary_list[i + 1].destination.data.international_name.split(",") : return_destination.city;
		        if (itinerary_list[i].r2r_json.vehicles !== undefined && itinerary_list[i].r2r_json.data !== undefined) {
		            if (itinerary_list[i].r2r_json.vehicle.kind === "car" || itinerary_list[i].r2r_json.vehicle.kind === "ferry") {
	    		        let checked = true;
	    	        	if (cars_steps) {
	    	        		cars_steps.map((step) => {
	    	        			if (step.start_date_origin === itinerary_list[i].start_date) {
	    	        				checked = step.checked;
	    	        			}
	    	        		});
	    	        	}
		                new_dest.push({
		                    index: i,
		                    origin: typeof(origin) === "string" ? origin : origin[0],
		                    destination: typeof(destination) === "string" ? destination : destination[0],
		                    origin_id: itinerary_list[i].destination.data.destination,
		                    dest_id: itinerary_list[i + 1].destination.data.destination,
		                    start_date_origin: itinerary_list[i].start_date,
		                    end_date_origin: itinerary_list[i].end_date,
		                    start_date_dest: itinerary_list[i + 1].start_date,
		                    end_date_dest: itinerary_list[i + 1].end_date,
		                    checked: checked,
		                    r2r_json: itinerary_list[i].r2r_json,
		                    step_id: itinerary_list[i].id,
		                    iata_airports_departure: itinerary_list[i].destination.data.iata_airports,
		                    iata_airports_arrival: itinerary_list[i + 1].destination.data.iata_airports
		                });
		            }
		            else {
	    		        let checked = false;
	    	        	if (cars_steps) {
	    	        		cars_steps.map((step) => {
	    	        			if (step.start_date_origin === itinerary_list[i].start_date) {
	    	        				checked = step.checked;
	    	        			}
	    	        		});
	    	        	}
		                itinerary_list[i].r2r_json.data.routes.map((route) => {
	                        route.segments.map((segment) => {
	                        	let find = new_dest.find((dest) => {
	                        		return dest.index === i;
	                        	});
	                        	if (find === undefined) {
		                            if (itinerary_list[i].r2r_json.data.vehicles[segment.vehicle].kind === "car" || (route.segments.length === 1 && itinerary_list[i].r2r_json.data.vehicles[segment.vehicle].kind === "ferry")) {
		                                new_dest.push({
		                                    index: i,
		                                    route: route,
		                                    data: itinerary_list[i].r2r_json,
		                                    origin: typeof(origin) === "string" ? origin : origin[0],
		                                    destination: typeof(destination) === "string" ? destination : destination[0],
		                                    start_date_origin: itinerary_list[i].start_date,
		                                    end_date_origin: itinerary_list[i].end_date,
		                                    start_date_dest: itinerary_list[i + 1].start_date,
		                                    end_date_dest: itinerary_list[i + 1].end_date,
		                                    checked: checked,
							                r2r_json: itinerary_list[i].r2r_json,
		            	                    step_id: itinerary_list[i].id,
		            	                    iata_airports_departure: itinerary_list[i].destination.data.iata_airports,
		            	                    iata_airports_arrival: itinerary_list[i + 1].destination.data.iata_airports,
		            	                    origin_id: itinerary_list[i].destination.data.destination,
		            	                    dest_id: itinerary_list[i + 1].destination.data.destination
		                                })
		                            }
	                        	}
	                        })
		                })
		            }
		        }
	    	}
	    	else {
	    		error = true;
	    	}
	    }
	}
	if (!error) {
		dispatch(SetItineraryStep(new_dest));
	}
	else {
		dispatch(ItineraryError(true));
	}
}