const initial_state = {
	modal_calendar: false,
	modal_info: null,
    current_calendar_day: null,
    accommodations: [],
    flights: [],
    cars: [],
    allEvents: [],
    select_day: null,
    fullscreen: false
};
export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.calendar !== undefined) {
                Object.keys(action.payload.calendar).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.calendar[key];
                    }
                });
            }
            return new_state;
        }
        case "CALENDAR_TOGGLE_MODAL": {
        	new_state.modal_calendar = action.payload.open;
        	new_state.modal_info = action.payload.info;
        	return new_state;
        }
        case "CALENDAR_SET_CURRENT_DAY": {
            new_state.current_calendar_day = action.payload.day;
            return new_state;
        }
        case "CALENDAR_SET_EVENT": {
            new_state.accommodations = action.payload.accommodations.slice();
            new_state.flights = action.payload.flights.slice();
            new_state.cars = action.payload.cars.slice();
            new_state.allEvents = action.payload.allEvents.slice();
            return new_state;
        }
        case "CALENDAR_SELECT_DAY": {
            new_state.select_day = action.payload.date;
            return new_state;
        }
        case "CALENDAR_SET_FULLSCREEN": {
            new_state.fullscreen = action.payload.fullscreen;
            return new_state;
        }
    }
    return new_state;
}