import Axios from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

export default (trip_id, trip_token, setTripInfo) => {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        Axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/?token=${trip_token}`,
        }).then(function (response) {
            let data = Object.assign({}, response.data);
            setTripInfo(data);
        }).catch(function (error) {
            console.log('err:', error);
        })
    }
};

