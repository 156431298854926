import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import CheckBeforeRequest from "../../../../Common/CheckBeforeRequest";
import { setAutocomplete } from "../redux/actions";
import { AutocompleteRelations } from "../objects/autocompleteRelations";
import { AutocompleteTranslations } from "../objects/autocompleteTranslations";
import { AppState } from "../../../../../Reducers/Reducers";

export function useMailTemplateAutocomplete(): void {
    const dispatch = useDispatch();
    const autocomplete = useSelector((state: AppState) => state.mailTemplate.others.autocomplete);
    const clientId = window.id_owner;

    useEffect(() => {
        if (
            clientId !== null &&
            autocomplete.state === 'loading'
        ) {
            (async () => {
                const { pass_check, headers } = CheckBeforeRequest();
                if (pass_check) {
                    try {
                        const relationsResponse = await axios.get<AutocompleteRelations>(
                            `${API_HREF}client/${clientId}/templates/get_action_dictionary/`,
                            { headers }
                        );
                        const translationsResponse = await axios.get<AutocompleteTranslations>(
                            `${API_HREF}client/${clientId}/templates/get_definition/`,
                            { headers }
                        );
                        dispatch(setAutocomplete({
                            state: 'success',
                            data: {
                                relations: relationsResponse.data,
                                translations: translationsResponse.data
                            }
                        }));
                    } catch (error: any) {
                        dispatch(setAutocomplete({
                            state: 'error',
                            error
                        }));
                    }
                }
            })();
        }
    }, [autocomplete.state]);
}
