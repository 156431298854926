export default (travelers) => {
    let pass_check = true;
    travelers.map((traveler) => {
        // only alphabetical + space characters
        if (traveler.first_name !== null && traveler.first_name !== "" && traveler.last_name !== null && traveler.last_name !== "" 
            && (traveler.first_name.match(/^[A-Za-z _]+$/) === null || traveler.last_name.match(/^[A-Za-z _]+$/) === null)){
            pass_check = false;
        }
    })
    return pass_check
}
