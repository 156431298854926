//Dependencies
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
//Core
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography
} from '@mui/material';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableContainer from '@material-ui/core/TableContainer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//Icons
import CheckBoxFilled from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
//Common
import GetCookie from '../Common/Functions/GetCookie';
import ReloadCart from '../Header/Functions/ReloadCart';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
//Actions
import { ToggleBookingModal } from '../../Actions/Booking';
import RefreshToBook from '../BookingMaterial/Functions/RefreshToBook';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import GetTrip from "../Base/Functions/GetTrip";
import SetCookie from "../Common/Functions/SetCookie";

const useStyles = makeStyles({
    red: {
        color: 'red'
    },
    green: {
        color: 'green'
    },
    emptyButton: {
        color: '#E6592F',
        border: '1px solid #E6592F'
    },
    tableCell: {
        minWidth: 150
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    }
});
const RefreshPrice = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector(state => state.user.user);
    const end_date = useSelector(state => state.trip.end_date);
    const info = useSelector(state => state.cart.refresh_price);
    const to_book = useSelector(state => state.cart.to_book);
    const data_trip = useSelector(state => state.trip.data_trip);
    const is_booking = useSelector(state => state.booking.is_booking);
    const currency_list = useSelector(state => state.base.currency_list);
    const refresh_apply_to_margin_to_all = useSelector(state => state.cart.refresh_apply_to_margin_to_all);
    const [versionName, setVersionName] = useState('');
    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;
    const toggleApplyMargin = (key, id) => () => {
        dispatch({ type: 'CART_TOGGLE_APPLY_MARGIN', payload: { key: key, id: id } });
    };
    const applyToMarginToAllProduct = () => {
        dispatch({ type: 'CART_APPLY_TO_MARGIN_TO_ALL_PRODUCT' });
    };
    const toggleMakeNewVersion = () => {
        dispatch({ type: 'CART_TOGGLE_MAKE_NEW_VERSION' });
    };
    const onVersionNameChange = event => {
        setVersionName(event.target.value);
    };
    const onClose = () => {
        dispatch({ type: 'CART_REFRESH_PRICE', payload: null });
    };
    const onCancelChange = () => {
        dispatch({ type: 'CART_REFRESH_PRICE', payload: null });
        dispatch({ type: 'CART_SET_STEP', payload: { step: 1 } });
    };
    const onAcceptChange = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'POST',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/refresh_with_data/`,
            data: { ...info, new_version_name: versionName === '' ? null : versionName }
        }).then(response => {
            console.log('response.data : ', response.data);
            dispatch({ type: 'CART_REFRESH_PRICE', payload: null });
            enqueueSnackbar(t('cart-material.changes-applied'), { variant: 'success' });
            if (response.data.new_version) {
                enqueueSnackbar(t('cart-material.new-version-created'), { variant: 'success' });
                let user_token = GetCookie("token");
                let id_user = GetCookie("id_user");
                let trip_token = GetCookie("trip_token");
                let client_user = GetCookie("client_user");
                let id = GetCookie('trip_id');
                //Delete the cookie
                let cookies = document.cookie.split("; ");
                for (let c = 0; c < cookies.length; c++) {
                    let d = window.location.hostname.split(".");
                    while (d.length > 0) {
                        let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
                        let p = location.pathname.split("/");
                        document.cookie = cookieBase + "/";
                        while (p.length > 0) {
                            document.cookie = cookieBase + p.join("/");
                            p.pop();
                        }
                        d.shift();
                    }
                }

                SetCookie("trip_id", id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                SetCookie("trip_id_version", response.data.version_id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                SetCookie("trip_token", trip_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                SetCookie("token", user_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                SetCookie("id_user", id_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                SetCookie("client_user", client_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                dispatch({ type: "FLUSH_REMOVE_STATE" });
                GetTrip(user, dispatch, t);
            } else {
                ReloadCart(dispatch, end_date);
                to_book.map((product) => {
                    RefreshToBook(product, end_date, dispatch, "BookingCard");
                });
                if (is_booking) {
                    dispatch(ToggleBookingModal(true));
                }
            }
        }).catch(error => {
            console.log(error);
            enqueueSnackbar(t('cart-material.could-not-apply-change'), { variant: 'warning' });
            dispatch({ type: 'CART_REFRESH_PRICE', payload: null });
        });
    };
    const getSymbol = (currency_id) => {
        const currency = currency_list.find(currency => currency.id === currency_id);
        return (currency === undefined || currency === null) ? '' : currency.symbol;
    };
    useEffect(() => {
        if (info !== null && !info.price_updated) {
            enqueueSnackbar(t('cart-material.refresh-price-no-change'), { variant: 'success' });
            if (is_booking) {
                dispatch(ToggleBookingModal(true));
            }
        }
    }, [info, is_booking]);
    if (info !== null && info.price_updated) {
        return (
            <Dialog open={ true } fullWidth maxWidth={ 'lg' } disableEscapeKeyDown>
                <DialogTitle>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography fontWeight="bold">
                            { t('cart-material.refresh-price-title') }
                        </Typography>
                        <IconButton onClick={ onClose } size={ 'small' }>
                            <CloseIcon style={{ float: 'right' }}/>
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Typography>{ t('cart-material.refresh-price-subtitle') }</Typography>
                    {
                        data_trip.status !== 'DEPOSIT' && data_trip.status !== 'BOOKED' && data_trip.status !== 'ARCHIVED' && data_trip.status !== 'CLOSED' && data_trip.status !== 'CANCELLED' && user.client_full.type !== 2 && quotation_code !== 'marcovasco' &&
                        <Fragment>
                            <Typography>{ t('cart-material.refresh-info') }</Typography>
                            {/*<Typography>{ t('cart-material.refresh-info-1') } :</Typography>*/}
                            {/*<Typography> - { t('cart-material.refresh-info-2') }</Typography>*/}
                            {/*<Typography> - { t('cart-material.refresh-info-3') }</Typography>*/}
                            <br/>
                        </Fragment>
                    }
                    <TableContainer className={ classes.mb15 } component={ Paper }>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{ t('cart-material.type') }</TableCell>
                                    <TableCell>{ t('cart-material.name') }</TableCell>
                                    <TableCell className={ classes.tableCell }>{ t('cart-material.old-price') }</TableCell>
                                    <TableCell className={ classes.tableCell }>{ t('cart-material.new-price') }</TableCell>
                                    <TableCell>{ t('cart-material.price-diff') }</TableCell>
                                    { data_trip.status !== 'DEPOSIT' && data_trip.status !== 'BOOKED' && data_trip.status !== 'ARCHIVED' && data_trip.status !== 'CLOSED' && data_trip.status !== 'CANCELLED' && user.client_full.type !== 2 && quotation_code !== 'marcovasco' && <TableCell><FormControlLabel control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ 'large' }/> } checkedIcon={ <CheckBoxFilled fontSize={ 'large' }/> } checked={ refresh_apply_to_margin_to_all } onChange={ applyToMarginToAllProduct }/> } label={ t('cart-material.apply-to-margin') }/></TableCell> }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Object.keys(info.flight).map(id => (info.flight[id].price_variation !== 0 || !info.flight[id].is_available) && (
                                        <TableRow>
                                            <TableCell><Typography>{ t('cart-material.flight') }</Typography></TableCell>
                                            <TableCell>
                                                <Typography>{ info.flight[id].name }</Typography>
                                            </TableCell>
                                            <TableCell>
                                                { info.flight[id].is_available && <Typography>{ (info.flight[id].new_selling - info.flight[id].price_variation).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } { getSymbol(info.flight[id].selling_currency) }</Typography> }
                                            </TableCell>
                                            <TableCell>
                                                { info.flight[id].is_available && <Typography>{ info.flight[id].new_selling.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } { getSymbol(info.flight[id].selling_currency) }</Typography> }
                                            </TableCell>
                                            <TableCell>
                                                <Typography className={ info.flight[id].price_variation > 0 || !info.flight[id].is_available ? classes.red : classes.green }>{ !info.flight[id].is_available ? t('cart-material.product-unavailable-2') : `${info.flight[id].price_variation.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${getSymbol(info.flight[id].selling_currency)}` }</Typography>
                                            </TableCell>
                                            {
                                                data_trip.status !== 'DEPOSIT' && data_trip.status !== 'BOOKED' && data_trip.status !== 'ARCHIVED' && data_trip.status !== 'CLOSED' && data_trip.status !== 'CANCELLED' && user.client_full.type !== 2 && quotation_code !== 'marcovasco' &&
                                                <TableCell>
                                                    <FormControlLabel control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ 'large' }/> } checkedIcon={ <CheckBoxFilled fontSize={ 'large' }/> } checked={ info.flight[id].affect_to_margin } onChange={ toggleApplyMargin('flight', id) }/> } label={ '' }/>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    ))
                                }
                                {
                                    Object.keys(info.accommodations).map(id => (info.accommodations[id].price_variation !== 0 || !info.accommodations[id].is_available) && (
                                        <TableRow>
                                            <TableCell><Typography>{ t('cart-material.accommodation') }</Typography></TableCell>
                                            <TableCell>
                                                <Typography>{ info.accommodations[id].hotel_name } : { info.accommodations[id].room }</Typography>
                                            </TableCell>
                                            <TableCell>
                                                { info.accommodations[id].is_available && <Typography>{ (info.accommodations[id].new_selling - info.accommodations[id].price_variation).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } { getSymbol(info.accommodations[id].selling_currency) }</Typography> }
                                            </TableCell>
                                            <TableCell>
                                                { info.accommodations[id].is_available && <Typography>{ info.accommodations[id].new_selling.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } { getSymbol(info.accommodations[id].selling_currency) }</Typography> }
                                            </TableCell>
                                            <TableCell>
                                                <Typography className={ info.accommodations[id].price_variation > 0 || !info.accommodations[id].is_available ? classes.red : classes.green }>{ !info.accommodations[id].is_available ? t('cart-material.product-unavailable-2') : `${info.accommodations[id].price_variation.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${getSymbol(info.accommodations[id].selling_currency)}` }</Typography>
                                            </TableCell>
                                            {
                                                data_trip.status !== 'DEPOSIT' && data_trip.status !== 'BOOKED' && data_trip.status !== 'ARCHIVED' && data_trip.status !== 'CLOSED' && data_trip.status !== 'CANCELLED' && user.client_full.type !== 2 && quotation_code !== 'marcovasco' &&
                                                <TableCell>
                                                    <FormControlLabel control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ 'large' }/> } checkedIcon={ <CheckBoxFilled fontSize={ 'large' }/> } checked={ info.accommodations[id].affect_to_margin } onChange={ toggleApplyMargin('accommodations', id) }/> } label={ '' }/>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    ))
                                }
                                {
                                    Object.keys(info.activity).map(id => (info.activity[id].price_variation !== 0 || !info.activity[id].is_available) && (
                                        <TableRow>
                                            <TableCell><Typography>{ t('cart-material.poi') }</Typography></TableCell>
                                            <TableCell>
                                                <Typography>{ info.activity[id].name }</Typography>
                                            </TableCell>
                                            <TableCell>
                                                { info.activity[id].is_available && <Typography>{ (info.activity[id].new_selling - info.activity[id].price_variation).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } { getSymbol(info.activity[id].selling_currency) }</Typography> }
                                            </TableCell>
                                            <TableCell>
                                                { info.activity[id].is_available && <Typography>{ info.activity[id].new_selling.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } { getSymbol(info.activity[id].selling_currency) }</Typography> }
                                            </TableCell>
                                            <TableCell>
                                                <Typography className={ info.activity[id].price_variation > 0 || !info.activity[id].is_available ? classes.red : classes.green }>{ !info.activity[id].is_available ? t('cart-material.product-unavailable-2') : `${info.activity[id].price_variation.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${getSymbol(info.activity[id].selling_currency)}` }</Typography>
                                            </TableCell>
                                            {
                                                data_trip.status !== 'DEPOSIT' && data_trip.status !== 'BOOKED' && data_trip.status !== 'ARCHIVED' && data_trip.status !== 'CLOSED' && data_trip.status !== 'CANCELLED' && user.client_full.type !== 2 && quotation_code !== 'marcovasco' &&
                                                <TableCell>
                                                    <FormControlLabel control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ 'large' }/> } checkedIcon={ <CheckBoxFilled fontSize={ 'large' }/> } checked={ info.activity[id].affect_to_margin } onChange={ toggleApplyMargin('activity', id) }/> } label={ '' }/>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    ))
                                }
                                {
                                    Object.keys(info.cars).map(id => (info.cars[id].price_variation !== 0 || !info.cars[id].is_available) && (
                                        <TableRow>
                                            <TableCell><Typography>{ t('cart-material.car') }</Typography></TableCell>
                                            <TableCell>
                                                <Typography>{ info.cars[id].name }</Typography>
                                            </TableCell>
                                            <TableCell>
                                                { info.cars[id].is_available && <Typography>{ (info.cars[id].new_selling - info.cars[id].price_variation).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } { getSymbol(info.cars[id].selling_currency) }</Typography> }
                                            </TableCell>
                                            <TableCell>
                                                { info.cars[id].is_available && <Typography>{ info.cars[id].new_selling.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } { getSymbol(info.cars[id].selling_currency) }</Typography> }
                                            </TableCell>
                                            <TableCell>
                                                <Typography className={ info.cars[id].price_variation > 0 || !info.cars[id].is_available ? classes.red : classes.green }>{ !info.cars[id].is_available ? t('cart-material.product-unavailable-2') : `${info.cars[id].price_variation.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${getSymbol(info.cars[id].selling_currency)}` }</Typography>
                                            </TableCell>
                                            {
                                                data_trip.status !== 'DEPOSIT' && data_trip.status !== 'BOOKED' && data_trip.status !== 'ARCHIVED' && data_trip.status !== 'CLOSED' && data_trip.status !== 'CANCELLED' && user.client_full.type !== 2 && quotation_code !== 'marcovasco' &&
                                                <TableCell>
                                                    <FormControlLabel control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ 'large' }/> } checkedIcon={ <CheckBoxFilled fontSize={ 'large' }/> } checked={ info.cars[id].affect_to_margin } onChange={ toggleApplyMargin('cars', id) }/> } label={ '' }/>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    ))
                                }
                                {
                                    Object.keys(info.packages).map(id => (info.packages[id].price_variation !== 0 || !info.packages[id].is_available) && (
                                        <TableRow>
                                            <TableCell><Typography>{ t('cart-material.package') }</Typography></TableCell>
                                            <TableCell>
                                                <Typography>{ info.packages[id].name }</Typography>
                                            </TableCell>
                                            <TableCell>
                                                { info.packages[id].is_available && <Typography>{ (info.packages[id].new_selling - info.packages[id].price_variation).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } { getSymbol(info.packages[id].selling_currency) }</Typography> }
                                            </TableCell>
                                            <TableCell>
                                                { info.packages[id].is_available && <Typography>{ info.packages[id].new_selling.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } { getSymbol(info.packages[id].selling_currency) }</Typography> }
                                            </TableCell>
                                            <TableCell>
                                                <Typography className={ info.packages[id].price_variation > 0 || !info.packages[id].is_available ? classes.red : classes.green }>{ !info.packages[id].is_available ? t('cart-material.product-unavailable-2') : `${info.packages[id].price_variation.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${getSymbol(info.packages[id].selling_currency)}` }</Typography>
                                            </TableCell>
                                            {
                                                data_trip.status !== 'DEPOSIT' && data_trip.status !== 'BOOKED' && data_trip.status !== 'ARCHIVED' && data_trip.status !== 'CLOSED' && data_trip.status !== 'CANCELLED' && user.client_full.type !== 2 && quotation_code !== 'marcovasco' &&
                                                <TableCell>
                                                    <FormControlLabel control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ 'large' }/> } checkedIcon={ <CheckBoxFilled fontSize={ 'large' }/> } checked={ info.packages[id].affect_to_margin } onChange={ toggleApplyMargin('packages', id) }/> } label={ '' }/>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br/>
                    {
                        !is_booking && data_trip.status !== 'DEPOSIT' && data_trip.status !== 'BOOKED' && data_trip.status !== 'ARCHIVED' && data_trip.status !== 'CLOSED' && data_trip.status !== 'CANCELLED' &&
                        <div>
                            <FormControlLabel control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ 'large' }/> } checkedIcon={ <CheckBoxFilled fontSize={ 'large' }/> } checked={ info.make_new_version } onChange={ toggleMakeNewVersion }/> } label={ t('cart-material.create-new-version') }/>
                            {
                                info.make_new_version &&
                                <FormControl fullWidth variant={ 'outlined' }>
                                    <InputLabel htmlFor={ 'version-name' }>{ t('cart-material.version-name') }</InputLabel>
                                    <OutlinedInput
                                        id={ 'version-name' }
                                        type={ 'text' }
                                        onChange={ onVersionNameChange }
                                        label={ t('cart-material.version-name') }
                                        value={ versionName }
                                    />
                                </FormControl>
                            }
                        </div>
                    }
                    <br/>
                    <br/>
                </DialogContent>
                <DialogActions>
                    {
                        is_booking &&
                        <Button onClick={ onCancelChange }>{ t('cart-material.cancel-price-change') }</Button>
                    }
                    <Button variant="outlined" onClick={ onAcceptChange }>{ t('cart-material.accept-price-change') }</Button>
                </DialogActions>
            </Dialog>
        );
    }
    return null;
};

export default RefreshPrice;
