import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import CustomInputIcon from "../DatePicker/CustomInputIcon.jsx";

@connect((store) => {
    return {
        trip_id: store.trip.trip_id,
        user: store.user.user,
        trip_end_date: store.trip.end_date,
    };
})
@withTranslation()

class RenderTransferDate extends React.Component {
	constructor(props, context) {
	    super(props, context);
	    this.setWrapperRef = this.setWrapperRef.bind(this);
	    this.handleClickOutside = this.handleClickOutside.bind(this);
	}
	setWrapperRef(node) {
	    this.wrapperRef1 = node;
	}
	handleClickOutside(event) {
		const { setPassengerOpen } = this.props;
	    if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
	        document.removeEventListener("mousedown", this.handleClickOutside);
	        setPassengerOpen(false);
	    }
	}
	tooglePassenger() {
		const { setPassengerOpen, passenger_open } = this.props;
        setPassengerOpen(!passenger_open);
	}
	handlePassenger(traveler) {
        const { setGroups, groups} = this.props;
        let tmp_groups = groups.slice();
        let travelers_list = tmp_groups[0].travelers_list.slice();
        let add = true;
        for (let i = 0; i < travelers_list.length; i++) {
            if (travelers_list[i].id === traveler.id) {
                travelers_list.splice(i, 1);
                add = false;
                break;
            }
        }
        if (add === true) {
            travelers_list.push(traveler);
        }
        tmp_groups[0].travelers_list = travelers_list;
        setGroups(tmp_groups);
    }
	displayPassenger() {
	    const { trip_end_date, component, groups } = this.props;
	    const { travelers } = component.state;
	    document.addEventListener('mousedown', this.handleClickOutside);
	    let arr = [];
	    travelers.map((passenger, passenger_index) => {
	        if (passenger.passenger_group === null) {
	            let age = moment(trip_end_date).diff(moment(passenger.birth_date), "y");
	            arr.push (
	                <div key={ passenger_index } className={ "passenger-group-flight-info-passenger-choice" } onClick={() => {this.handlePassenger(passenger)}}>
	                    {
	                        (groups[0].travelers_list.find((el) => el.id === passenger.id)) && (
	                            <span className={ "fa fa-check"}/>
	                        )
	                    }
	                    <span>{ passenger.first_name + " " + passenger.last_name + (passenger.flight_type !== "ADT" ? " [" + age + "]" : "") }</span>
	                </div>
	            )
	        }
	    });
	    return arr;
	}
	render(){
		const {
			t,
			focus_start,
			setFocusStart,
			focus_end,
			setFocusEnd,
			start_date,
			setStartDate,
			end_date,
			setEndDate,
			hour_start,
			setHourStart,
			hour_end,
			setHourEnd,
			is_return,
			setReturn,
			passenger_open,
			setPassengerOpen,
			data_list,
			minute_start,
			setMinuteStart,
			minute_end,
			setMinuteEnd,
			groups,
			setGroups,
			type
		} = this.props;
		console.log('data_list:', data_list);
  		return (
			<div className={ "ft-mt70 row" }>
				{
					type === 0 && (
						<Col lg={12} className={ "row" }>
							<div className={"col-md-11 show-input offset-md-1"}>
								<label className={ "flight-search-checkbox" }>{ "Transfert aller-retour" }
									<input type={ "checkbox" } checked={ is_return } onChange={() => {setReturn(!is_return)} }/>
									<span className={ "flight-search-check-mark-cars" }/>
								</label>
							</div>
						</Col>
					)
				}
				<Col lg={12} className={"row"}>
        			<div id={ "cars-date-picker" } className={ (is_return ? "col-md-2 offset-md-1" : "col-md-3 offset-md-1") + "  ft-relative" }>
        				<span className={ "sub-title ft-gray" }>Date et heure de départ</span>
		        		<SingleDatePicker
			        		initialVisibleMonth={ () => {
			        			return moment(data_list[0].start_date);
			        		}}
							readOnly
		        		    placeholder={ t("home.modal.start_date_placeholder") }
		        		    id={ "home_start_date" }
		        		    date={ moment(start_date) }
		        		    noBorder
		        		    onDateChange={(date) => { setStartDate(date)}}
		        		    focused={ focus_start }
		        		    onFocusChange={() => {setFocusStart(!focus_start)}}
		        		    isOutsideRange={day => (day.isBefore(moment(data_list[0].start_date), "d") || (day.isAfter(moment(data_list[0].end_date), "d")))}
		        		    isDayHighlighted={day => ((day.isAfter(moment(data_list[0].start_date), "d") && day.isBefore(moment(data_list[0].end_date), "d")) || (day.isSame(moment(data_list[0].start_date), "d")) || (day.isSame(moment(data_list[0].end_date), "d")) )}
		        		    numberOfMonths={ 1 }
		        		    hideKeyboardShortcutsPanel
		        		    showClearDate
		        		    reopenPickerOnClearDate
		        		    customInputIcon={<CustomInputIcon custom_icon={ true }/>}
		        		/>
	        		</div>
					<div className={ (is_return ? "col-md-1" : "col-md-2") + " origin-hour" }>
						<label className={ "row" }>
							<span className={ "col-md-12 ft-gray" }>{t("global.hours")}</span>
							<select className={ "col-md-9 offset-md-1 ft-basic-selector" } style={{'height': '34px'}} name="origin_hours" defaultValue={hour_start } onChange={ (e) => {setHourStart(e.target.value)} }>
								<option value={"00"}>00</option>
								<option value={"01"}>01</option>
								<option value={"02"}>02</option>
								<option value={"03"}>03</option>
								<option value={"04"}>04</option>
								<option value={"05"}>05</option>
								<option value={"06"}>06</option>
								<option value={"07"}>07</option>
								<option value={"08"}>08</option>
								<option value={"09"}>09</option>
								<option value={"10"}>10</option>
								<option value={"11"}>11</option>
								<option value={"12"}>12</option>
								<option value={"13"}>13</option>
								<option value={"14"}>14</option>
								<option value={"15"}>15</option>
								<option value={"16"}>16</option>
								<option value={"17"}>17</option>
								<option value={"18"}>18</option>
								<option value={"19"}>19</option>
								<option value={"20"}>20</option>
								<option value={"21"}>21</option>
								<option value={"22"}>22</option>
								<option value={"23"}>23</option>
							</select>
						</label>
					</div>
					<div className={ (is_return ? "col-md-1" : "col-md-2") + " origin-minutes" }>
						<label className={ "row" }>
							<span className={ "col-md-12 ft-gray" }>{t("global.minutes")}</span>
							<input className={ "col-md-9 offset-md-1 ft-basic-input" } style={{'height': '34px', 'color': 'gray'}} type={ "number" } min={ "0" } max={ "59" } value={ minute_start } name={ "origin_minutes" } onChange={(e) => {
									let new_minute = e.target.value;
									setMinuteStart(new_minute)
								}}
								onBlur={() => setMinuteStart(minute_start < 10 ?( "0" + minute_start) : minute_start)}
							/>
						</label>
					</div>
					{
						is_return && (
							<Fragment>
							<div id={ "cars-date-picker" } className={ "col-md-2 ft-relative" }>
								<span className={ "sub-title ft-gray" }>Date et heure de retour</span>
				        		<SingleDatePicker
					        		initialVisibleMonth={ () => {
					        			return moment(data_list[type ? 1 : 0].end_date);
					        		}}
				        		    placeholder={ t("home.modal.end_date_placeholder") }
				        		    id={ "home_end_date" }
				        		    date={ moment(data_list[type ? 1 : 0].end_date) }
				        		    noBorder
									readOnly
				        		    onDateChange={(date) => {setEndDate(data)}}
				        		    focused={ focus_end }
				        		    onFocusChange={() => { setFocusEnd(!focus_end)} }
				        		    numberOfMonths={ 1 }
				        		    isDayHighlighted={day => ((day.isAfter(moment(data_list[type ? 1 : 0].start_date), "d") && day.isBefore(moment(data_list[type ? 1 : 0].end_date), "d")) || (day.isSame(moment(data_list[type ? 1 : 0].start_date), "d")) || (day.isSame(moment(data_list[type ? 1 : 0].end_date), "d")) )}
				        		    isOutsideRange={day => (day.isBefore(moment(data_list[type ? 1 : 0].start_date), "d") || (day.isAfter(moment(data_list[type ? 1 : 0].end_date), "d")))}
				        		    hideKeyboardShortcutsPanel
				        		    showClearDate
				        		    reopenPickerOnClearDate
				        		    customInputIcon={<CustomInputIcon custom_icon={ true }/>}
				        		/>
			        		</div>
							<div className={ "col-md-1 return-hour" }>
								<label className={ "row" }>
									<span className={ "col-md-12 ft-gray" }>{t("global.hours")}</span>
									<select className={ "col-md-9 offset-md-1 ft-basic-selector" } style={{'height': '34px', 'color': 'gray'}} name="return_hours" defaultValue={hour_end} onChange={ (e) => {setHourEnd(e.target.value)} }>
										<option value={"00"}>00</option>
										<option value={"01"}>01</option>
										<option value={"02"}>02</option>
										<option value={"03"}>03</option>
										<option value={"04"}>04</option>
										<option value={"05"}>05</option>
										<option value={"06"}>06</option>
										<option value={"07"}>07</option>
										<option value={"08"}>08</option>
										<option value={"09"}>09</option>
										<option value={"10"}>10</option>
										<option value={"11"}>11</option>
										<option value={"12"}>12</option>
										<option value={"13"}>13</option>
										<option value={"14"}>14</option>
										<option value={"15"}>15</option>
										<option value={"16"}>16</option>
										<option value={"17"}>17</option>
										<option value={"18"}>18</option>
										<option value={"19"}>19</option>
										<option value={"20"}>20</option>
										<option value={"21"}>21</option>
										<option value={"22"}>22</option>
										<option value={"23"}>23</option>
									</select>
								</label>
							</div>
							<div className={ "col-md-1 return-minutes" }>
								<label className={ "row" }> 
									<span className={ "col-md-12 ft-gray" }>{t("global.minutes")}</span>
									<input className={ "col-md-9 offset-md-1 ft-basic-input" } type={ "number" } min={ "00" } max={ "59" } value={ minute_end } name={ "return_minutes" } onChange={(e) => {
									let new_minute = e.target.value;
									setMinuteEnd(new_minute)
								}}
								onBlur={() => setMinuteEnd(minute_end < 10 ?( "0" + minute_end) : minute_end)}/>
								</label>
							</div>
							</Fragment>
						)
					}
					<div className={ "passenger-group-flight-info-passenger-container passenger-container-transfer col-md-3" }>
					    <div className={ "passenger-group-flight-info-passenger" } style={{'paddingTop': '26px'}} onClick={() => { this.tooglePassenger()}}>
					        <i className={ "fa fa-user" }/>
					        {
					            !groups[0].travelers_list.length ? (
					                    <span>{t("flight_groups.passenger")}</span>) :
					                (
					                    <span>{ groups[0].travelers_list.length } { groups[0].travelers_list.length === 1 ? t("global.person") : t("global.people") }</span>
					                )
					        }
					    </div>
					    {
					        passenger_open && (
					            <div ref={ (node) => { this.wrapperRef1 = node; } } className={ "passenger-group-flight-info-passenger-choice-container active" }>
					                {
					                    this.displayPassenger()
					                }
					            </div>
					        )
					    }
					</div>
				</Col>
			</div>
  		);
 	}
}
export default RenderTransferDate