import React, { useState, useEffect, Fragment, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Slider from '@material-ui/core/Slider';
import MenuItem from "@material-ui/core/MenuItem";
import Alert from "@material-ui/lab/Alert";
import Close from '@material-ui/icons/Close';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from "react-redux";

import { ToggleFilters } from "../../../Actions/Poi";
import { Checkbox, FormGroup } from "@mui/material";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles({
    inline: {
        display: "inline-block"
    },
    bold: {
        fontWeight: "bold"
    },
    capitalize: {
        textTransform: "capitalize"
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    checked: {
        fill: "black"
    },
    dialogActions: {
        padding: 24
    },
    underline: {
        textDecoration: 'underline'
    },
    formControlRoot1: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 15
        }
    },
    circleBorder: {
        borderRadius: 8
    },
    spacer: {
        padding: "8px 0px"
    },
    infoAlert: {
        fontSize: 12
    }
});

const PoiListFilters = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const open = useSelector(state => state.poi.show_filters);
    const destination = useSelector(state => state.poi.destination);
    const user = useSelector(state => state.user.user);
    const providers = useSelector(state => state.poi.providers);
    const provider_list = useSelector(state => state.trip.providers);
    const manual_provider_list = useSelector(state => state.trip.manual_providers);
    const selected_providers = useSelector(state => state.poi.selected_providers);
    const cut_line = useSelector(state => state.poi.cut_line);
    const duration_inf = useSelector(state => state.poi.duration_inf);
    const duration_sup = useSelector(state => state.poi.duration_sup);
    const audio_guide = useSelector(state => state.poi.audio_guide);
    const guide = useSelector(state => state.poi.guide);
    const escort_guide = useSelector(state => state.poi.escort_guide);
    const transport_pass = useSelector(state => state.poi.transport_pass);
    const for_children = useSelector(state => state.poi.for_children);
    const for_disabled = useSelector(state => state.poi.for_disabled);
    const multiple_days = useSelector(state => state.poi.multiple_days);
    const poi_filter = useSelector(state => state.poi.poi_filter);
    const eco_filter = useSelector(state => state.poi.eco_filter);
    const nugget_filter = useSelector(state => state.poi.nugget_filter);

    const [selectedProviders, setSelectedProviders] = useState(selected_providers);
    const [durationInfType, setDurationInfType] = useState("");
    const [durationInf, setDurationInf] = useState(0);
    const [durationSupType, setDurationSupType] = useState("");
    const [durationSup, setDurationSup] = useState(0);
    const [audioGuide, setAudioGuide] = useState(false);
    const [includedGuide, setIncludedGuide] = useState(false);
    const [escortGuide, setEscortGuide] = useState(false);
    const [transportPass, setTransportPass] = useState(false);
    const [forChildren, setForChildren] = useState(false);
    const [forDisabled, setForDisabled] = useState(false);
    const [cutLine, setCutLine] = useState(false);
    const [multipleDays, setMultipleDays] = useState(false);
    const [poi, setPoi] = useState(false);
    const [eco, setEco] = useState(false);
    const [nugget, setNugget] = useState(false);

    const durationInfLabel = useRef(null);
    const durationInfLabelWidth = durationInfLabel.current ? durationInfLabel.current.clientWidth : 95;
    const durationSupLabel = useRef(null);
    const durationSupLabelWidth = durationSupLabel.current ? durationSupLabel.current.clientWidth : 95;

    const minutes_marks = [
        {
            value: 15,
            label: '15 ' + t("poi.minutes")
        },
        {
            value: 30,
            label: '30 ' + t("poi.minutes")
        },
        {
            value: 45,
            label: '45 ' + t("poi.minutes")
        }
    ];

    const hours_marks = [
        {
            value: 6,
            label: '6 ' + t("poi.hours")
        },
        {
            value: 12,
            label: '12 ' + t("poi.hours")
        },
        {
            value: 18,
            label: '18 ' + t("poi.hours")
        }
    ];
    const days_marks = [
        {
            value: 2,
            label: '2 ' + t("poi.days")
        },
        {
            value: 4,
            label: '4 ' + t("poi.days")        
        },
        {
            value: 6,
            label: '6 ' + t("poi.days")
        }
    ];

    useEffect(() => {
        resetFilters();
    }, [destination]);
    
    useEffect(() => {
        if (open) {
            setSelectedProviders(selected_providers);
            setDurationInfType(duration_inf !== 0 ? "minutes" : "");
            setDurationInf(duration_inf);
            setDurationSupType(duration_sup !== Infinity ? "minutes" : "");
            setDurationSup(duration_sup);
            setAudioGuide(audio_guide);
            setIncludedGuide(guide);
            setEscortGuide(escort_guide);
            setTransportPass(transport_pass);
            setForChildren(for_children);
            setForDisabled(for_disabled);
            setCutLine(cut_line);
            setMultipleDays(multiple_days);
            setPoi(poi_filter);
            setEco(eco_filter);
            setNugget(nugget_filter);
        }
    }, [open]);

    const toggleFilters = () => {
        dispatch(ToggleFilters());
    };
    const setFilters = () => {
        dispatch({
            type: "POI_FILTER_LIST",
            payload: {
                selected_providers: selectedProviders,
                duration_inf: durationInfType !== "" ? durationInf : 0,
                duration_sup: durationSupType !== "" ? durationSup : Infinity,
                audio_guide: audioGuide,
                guide: includedGuide,
                escort_guide: escortGuide,
                transport_pass: transportPass,
                for_children: forChildren,
                for_disabled: forDisabled,
                multiple_days: multipleDays,
                cut_line: cutLine,
                poi: poi,
                eco: eco,
                nugget: nugget
            }
        });
    };
    const resetFilters = () => {
        setSelectedProviders([]);
        setDurationInfType("");
        setDurationInf(0);
        setDurationSupType("");
        setDurationSup(0);
        setAudioGuide(false);
        setIncludedGuide(false);
        setEscortGuide(false);
        setTransportPass(false);
        setForChildren(false);
        setForDisabled(false);
        setMultipleDays(false);
        setCutLine(false);
        setPoi(false);
        setEco(false);
        setNugget(false);
    };
    const closeFilters = () => {
        // resetFilters();
        toggleFilters();
    };

    const changeDurationType = (new_value, bound) => {
        if (bound === "inf") {
            setDurationInfType(new_value);
            switch (new_value) {
                //set default value for new scale
                case "":
                    setDurationInf(0);
                    break;
                case "minutes":
                    setDurationInf(1);
                    break;
                case "hours":
                    setDurationInf(60);
                    break;
                case "days":
                    setDurationInf(1440);
                    break;
            }
        } else if (bound === "sup") {
            setDurationSupType(new_value);
            switch (new_value) {
                //set default value for new scale
                case "":
                    setDurationSup(0);
                    break;
                case "minutes":
                    setDurationSup(1);
                    break;
                case "hours":
                    setDurationSup(60);
                    break;
                case "days":
                    setDurationSup(1440);
                    break;
            }
        }
    };
    return (
        <Dialog open={ open } PaperProps={{ style: { borderRadius: fullScreen ? 0 : 16 } }} onClose={ toggleFilters } fullWidth fullScreen={ fullScreen } maxWidth={ "md" }>
            <DialogTitle onClose={ toggleFilters }>
                <Grid container justify={ "space-between" } alignItems={ "center" }>
                    {
                        fullScreen ? (
                            <Grid item>
                                <IconButton edge={ "start" } onClick={ closeFilters }><NavigateBefore/></IconButton> { t("accommodation_list.filters") }
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item>
                                    { t("accommodation_list.filters") }
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={ closeFilters }><Close/></IconButton>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid>
                    <Typography className={ classes.bold } variant={ "h6" }>{ t("poi.filter.product_categorie") }</Typography>
                    <br/>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: `${main_color}`
                                    }
                                }} checked={poi} onChange={() => setPoi(!poi)} />
                            }
                            label={'Poi'}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: `${main_color}`
                                    }
                                }} checked={eco} onChange={() => setEco(!eco)} />
                            }
                            label={t('poi.eco_filter')}
                        />
                        {
                            ['continentsinsolites'].includes(quotation_code) &&
                            <FormControlLabel
                                control={
                                    <Checkbox sx={{
                                        '&.Mui-checked': {
                                            color: `${main_color}`
                                        }
                                    }} checked={nugget} onChange={() => setNugget(!nugget)} />
                                }
                                label={t('shared.pepite')}
                            />
                        }
                    </FormGroup>
                    <br/>
                    <Divider/>
                    <br/>
                </Grid>
                {
                    user.client_full.type !== 2 && (
                        <Grid>
                            <Fragment>
                                <Typography className={ classes.bold } variant={ "h6" }>{ t("accommodation_filters.providers") }</Typography>
                                <br/>
                                <Grid container>
                                    {
                                        providers.map((provider, index_provider) => {
                                            let tmp_provider = null;
                                            if (provider.provider_id !== undefined) {
                                                tmp_provider = manual_provider_list.find((prov) => prov.id === provider.id);
                                            } else {
                                                tmp_provider = provider.provider !== undefined ? provider.provider : provider;
                                            }
                                            return (
                                                <Grid key={ index_provider } item xs={ 6 }>
                                                    <FormControlLabel
                                                        control={<Checkbox sx={{
                                                                '&.Mui-checked': {
                                                                    color: `${main_color}`
                                                                }
                                                            }} checked={ selectedProviders.includes(tmp_provider.provider !== undefined ? tmp_provider.provider.id : tmp_provider.id) } onChange={(e) => {
                                                            let new_selectedProviders = [...selectedProviders];
                                                            console.log('tmp_provider:', tmp_provider);
                                                            if (e.target.checked) {
                                                                new_selectedProviders.push(tmp_provider.provider !== undefined ? tmp_provider.provider.id : tmp_provider.id);
                                                            } else {
                                                                new_selectedProviders.splice(new_selectedProviders.indexOf(tmp_provider.provider !== undefined ? tmp_provider.provider.id : tmp_provider.id), 1);
                                                            }
                                                            console.log('new_selectedProviders:', new_selectedProviders);
                                                            setSelectedProviders(new_selectedProviders);
                                                        }}/>}
                                                        label={ tmp_provider !== undefined && tmp_provider !== null && tmp_provider.provider ? tmp_provider.provider.name : tmp_provider.name }
                                                    />
                                                </Grid>
                                            );
                                        })
                                    }
                                </Grid>
                            </Fragment>
                            <br/>
                            <Divider/>
                            <br/>
                        </Grid>
                    )
                }
                
                <Typography className={ classes.bold } variant={ "h6" }>{ t("duration.title") }</Typography>
                <br/>
                <Typography variant={ "subtitle1" }>{ t("poi.duration_inf_bound") }</Typography>
                <Grid container alignItems="center" justify="space-between" className={classes.spacer} spacing={4}>
                    <Grid item xs={ 6 }>
                        <FormControl variant="outlined" className={classes.formControlRoot1} fullWidth>
                            <InputLabel ref={durationInfLabel}>
                                {t("poi.duration_type")}
                            </InputLabel>
                            <Select
                                value={durationInfType}
                                className={classes.circleBorder}
                                onChange={(e) => changeDurationType(e.target.value, "inf")}
                                MenuProps={{ disableScrollLock: true }}
                                input={
                                    <OutlinedInput
                                        labelWidth={durationInfLabelWidth}
                                        autoComplete="off"
                                    />}
                            >
                                <MenuItem value="">
                                    { t("poi.duration_empty") }
                                </MenuItem>
                                <MenuItem value="minutes">
                                    { t("global.minutes") }
                                </MenuItem>
                                <MenuItem value="hours">
                                    { t("global.hours") }
                                </MenuItem>
                                <MenuItem value="days">
                                    { t("global.days_maj") }
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={ 6 }>
                        {
                            durationInfType === "" && (
                                <Alert className={classes.infoAlert} severity="info"> {t("poi.duration_one_bound_inf")} </Alert>
                            )
                        }
                        {
                            durationInfType === "minutes" && (
                                <Slider value={durationInf} onChange={(event, value) => {
                                    setDurationInf(value); 
                                }} valueLabelDisplay="auto" marks={minutes_marks} min={1} max={59}/>
                            )
                        }
                        {
                            durationInfType === "hours" && (
                                <Slider value={durationInf / 60} onChange={(event, value) => {
                                    setDurationInf(value * 60); 
                                }} valueLabelDisplay="auto" marks={hours_marks} min={1} max={24}/>
                            )
                        }
                        {
                            durationInfType === 'days' && (
                                <Slider value={durationInf / 1440} onChange={(event, value) => {
                                    setDurationInf(value * 1440); 
                                }} valueLabelDisplay="auto" marks={days_marks} min={1} max={7}/>
                            )
                        }
                    </Grid>
                </Grid>
                <Typography variant={ "subtitle1" }>{ t("poi.duration_sup_bound") }</Typography>
                <Grid container alignItems="center" justify="space-between" className={classes.spacer} spacing={4}>
                    <Grid item xs={ 6 }>
                        <FormControl variant="outlined" className={classes.formControlRoot1} fullWidth>
                            <InputLabel ref={durationSupLabel}>
                                {t("poi.duration_type")}
                            </InputLabel>
                            <Select
                                value={durationSupType}
                                className={`${classes.formControlRoot1} ${classes.circleBorder}`}
                                onChange={(e) => changeDurationType(e.target.value, "sup")}
                                MenuProps={{ disableScrollLock: true }}
                                input={
                                    <OutlinedInput
                                        labelWidth={durationSupLabelWidth}
                                        autoComplete="off"
                                    />}
                            >
                                <MenuItem value="">
                                    { t("poi.duration_empty") }
                                </MenuItem>
                                <MenuItem value="minutes">
                                    { t("global.minutes") }
                                </MenuItem>
                                <MenuItem value="hours">
                                    { t("global.hours") }
                                </MenuItem>
                                <MenuItem value="days">
                                    { t("global.days_maj") }
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={ 6 }>
                        {
                            durationSupType === "" && (
                                <Alert className={classes.infoAlert} severity="info"> {t("poi.duration_one_bound_sup")} </Alert>
                            )
                        }
                        {
                            durationSupType === "minutes" && (
                                <Slider value={durationSup} onChange={(event, value) => {
                                    setDurationSup(value); 
                                }} valueLabelDisplay="auto" marks={minutes_marks} min={1} max={59}/>
                            )
                        }
                        {
                            durationSupType === "hours" && (
                                <Slider value={durationSup / 60} onChange={(event, value) => {
                                    setDurationSup(value * 60); 
                                }} valueLabelDisplay="auto" marks={hours_marks} min={1} max={24}/>
                            )
                        }
                        {
                            durationSupType === 'days' && (
                                <Slider value={durationSup / 1440} onChange={(event, value) => {
                                    setDurationSup(value * 1440); 
                                }} valueLabelDisplay="auto" marks={days_marks} min={1} max={7}/>
                            )
                        }
                    </Grid>
                </Grid>
                <br/>
                <Divider/>
                <br/>
                <Typography className={ classes.bold } variant={ "h6" }>{ t("poi.features") }</Typography>
                <br/>
                <Grid container>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: `${main_color}`
                                    }
                                }} checked={ audioGuide } onChange={(e) => {
                                setAudioGuide(!audioGuide); 
                            }}/>}
                            label={ t("poi.audio_guide") }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: `${main_color}`
                                    }
                                }} checked={ includedGuide } onChange={(e) => {
                                setIncludedGuide(!includedGuide); 
                            }}/>}
                            label={ t("poi.guide_included") }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: `${main_color}`
                                    }
                                }} checked={ escortGuide } onChange={(e) => {
                                setEscortGuide(!escortGuide); 
                            }}/>}
                            label={ t("poi.guide") }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: `${main_color}`
                                    }
                                }} checked={ transportPass } onChange={(e) => {
                                setTransportPass(!transportPass); 
                            }}/>}
                            label={ t("poi.transport_pass") }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: `${main_color}`
                                    }
                                }} checked={ forChildren } onChange={(e) => {
                                setForChildren(!forChildren); 
                            }}/>}
                            label={ t("poi.for_children") }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: `${main_color}`
                                    }
                                }} checked={ forDisabled } onChange={(e) => {
                                setForDisabled(!forDisabled); 
                            }}/>}
                            label={ t("poi.for_disabled") }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: `${main_color}`
                                    }
                                }} checked={ cutLine } onChange={(e) => {
                                setCutLine(!cutLine); 
                            }}/>}
                            label={ t("poi.cut_line") }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox sx={{
                                    '&.Mui-checked': {
                                        color: `${main_color}`
                                    }
                                }} checked={ multipleDays } onChange={(e) => {
                                setMultipleDays(!multipleDays); 
                            }}/>}
                            label={ t("poi.multiple_days") }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={ classes.dialogActions }>
                <Grid container justify={ "space-between" }>
                    <Grid item>
                        <Button className={ classes.underline } onClick={ resetFilters }>{ t("accommodation_filters.reset_filters") }</Button>
                    </Grid>
                    <Grid item>
                        <Button variant={ "contained" } className={ classes.orangeButton } onClick={ setFilters }>{ t("accommodation_filters.apply_change") }</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(PoiListFilters);
