//---- Dependencies ----//
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

//---- Functions ----//
import MapRun from "./Functions/MapRun.js";

//---- CSS ----//
import "../../../Style/Summary.css";

let itinerary_markers = [];
let segments_drawn = [];

//---- Class ----//
@connect((store) => {
    return {
        itinerary: store.summary.itinerary,
        step_hover: store.summary.step_hover
    };
})
@withTranslation()
@withRouter
class SummaryMainMap extends React.Component {
	constructor(props, context) {
        super(props, context);
        this.state = {   
        	map: null,
        	google: null 
        };
    }
    componentDidUpdate(prevProps) {
        const { step_hover, itinerary } = this.props;
        let { map, google } = this.state;
        if (this.state.google !== null && this.state.map !== null && itinerary.length > 0) {
            let bounds = new google.maps.LatLngBounds();
            let destination_zoom_tmp = [];
            let itinerary_to_add = [];
            for (let i = 0; i < itinerary.length; i++) {
                if (itinerary[i].step_type === "STEP") {
                    destination_zoom_tmp.push(itinerary[i]);
                    itinerary_to_add.push(itinerary[i]);
                    bounds.extend(new google.maps.LatLng(parseFloat(itinerary[i].destination.data.latitude), parseFloat(itinerary[i].destination.data.longitude)));
                }
            }
            if (itinerary_markers[step_hover] !== undefined && itinerary_markers[step_hover].marker !== undefined) {
                itinerary_markers[step_hover].marker.setMap(null);
                itinerary_markers.splice(step_hover, 1);
            }
            for (let i = 0; i < itinerary_markers.length; i++) {
                if (itinerary_markers[i].hover) {
                    itinerary_markers[i].marker.setMap(null);
                    itinerary_markers.splice(i, 1);
                }
            }
            this.state.map.fitBounds(bounds);
            for (let i = 0; i < itinerary_to_add.length; i++) {
                if (itinerary_to_add[i].selected_transport !== "" && i !== (itinerary_to_add.length - 1)) {
                    for (let k = 0; k < itinerary_to_add[i].r2r_json.segments.length; k++) {
                        let route = [];
                        if (itinerary_to_add[i].r2r_json.data.vehicles[itinerary_to_add[i].r2r_json.segments[k].vehicle].kind === "plane") {
                            route = [
                                new google.maps.LatLng(itinerary_to_add[i].r2r_json.data.places[itinerary_to_add[i].r2r_json.segments[k].depPlace].lat, itinerary_to_add[i].r2r_json.data.places[itinerary_to_add[i].r2r_json.segments[k].depPlace].lng),
                                new google.maps.LatLng(itinerary_to_add[i].r2r_json.data.places[itinerary_to_add[i].r2r_json.segments[k].arrPlace].lat, itinerary_to_add[i].r2r_json.data.places[itinerary_to_add[i].r2r_json.segments[k].arrPlace].lng) //arrival
                            ];
                        } else {
                            let encoded = itinerary_to_add[i].r2r_json.segments[k].path;
                            let index = 0;
                            let len = encoded.length;
                            let lat = 0;
                            let lng = 0;
                            while (index < len) {
                                let b;
                                let shift = 0;
                                let result = 0;
                                do {
                                    b = encoded.charAt(index++).charCodeAt(0) - 63;
                                    result |= (b & 0x1f) << shift;
                                    shift += 5;
                                } while (b >= 0x20);
                                lat += ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));
                                shift = 0;
                                result = 0;
                                do {
                                    b = encoded.charAt(index++).charCodeAt(0) - 63;
                                    result |= (b & 0x1f) << shift;
                                    shift += 5;
                                } while (b >= 0x20);
                                lng += ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));
                                route.push(new google.maps.LatLng((lat / 1E5), (lng / 1E5)));
                            }
                        }
                        let strokeColor = "#000";
                        if (itinerary_to_add[i].r2r_json.data.vehicles[itinerary_to_add[i].r2r_json.segments[k].vehicle].kind === "plane") {
                            strokeColor = "#06CCA9";
                        } else if (itinerary_to_add[i].r2r_json.data.vehicles[itinerary_to_add[i].r2r_json.segments[k].vehicle].kind === "train") {
                            strokeColor = "#6B4C85";
                        } else if (itinerary_to_add[i].r2r_json.data.vehicles[itinerary_to_add[i].r2r_json.segments[k].vehicle].kind === "car") {
                            strokeColor = "#606060";
                        } else if (itinerary_to_add[i].r2r_json.data.vehicles[itinerary_to_add[i].r2r_json.segments[k].vehicle].kind === "bus") {
                            strokeColor = "#D97A39";
                        } else if (itinerary_to_add[i].r2r_json.data.vehicles[itinerary_to_add[i].r2r_json.segments[k].vehicle].kind === "ferry") {
                            strokeColor = "#55B7D1";
                        }
                        let polyline = new google.maps.Polyline({
                            map: this.state.map,
                            path: route,                                
                            strokeColor: strokeColor,
                            strokeOpacity: 1 ,
                            strokeWeight: 6,
                            geodesic: itinerary_to_add[i].r2r_json.data.vehicles[itinerary_to_add[i].r2r_json.segments[k].vehicle].kind === "plane"
                        });
                        segments_drawn.push({
                            id: itinerary_to_add[i].id,
                            selected_transport: itinerary_to_add[i].selected_transport,
                            polyline: polyline
                        });
                        polyline.setMap(this.state.map);
                    }
                }
                let is_repeat = false;
                for (let j = 0; j < itinerary.length; j++) {
                    if (itinerary[j].destination !== null && itinerary_to_add[i].destination !== null) {
                        if (itinerary[j].id !== itinerary_to_add[i].id) {
                            if (itinerary[j].destination.id === itinerary_to_add[i].destination.id) {
                                is_repeat = true;
                            }
                        }                        
                    }
                }
               
                let marker_option = null;
                if (is_repeat !== true) {
                    marker_option = {
                        position: new google.maps.LatLng(parseFloat(itinerary_to_add[i].destination.data.latitude), parseFloat(itinerary_to_add[i].destination.data.longitude)),
                        map: this.state.map,
                        icon: {
                            url: (itinerary_to_add[i].id === step_hover ? "/Img/Map/pin-orange.png" : "/Img/Map/pin.png"),
                            labelOrigin: new google.maps.Point(15, 15)
                        },
                        label: {
                            text: (i + 1).toString(),
                            color: (itinerary_to_add[i].id === step_hover ? "#e6592f" : "#76B6C2"),
                            fontWeight: "bold"
                        }
                    };
                } else {
                    marker_option = {
                        position: new google.maps.LatLng(parseFloat(itinerary_to_add[i].destination.data.latitude), parseFloat(itinerary_to_add[i].destination.data.longitude)),
                        map: this.state.map,
                        icon: {
                            url: (itinerary_to_add[i].id === step_hover ? "/Img/Map/pin-orange.png" : "/Img/Map/repeat.png"),
                            labelOrigin: new google.maps.Point(15, 15)
                        }
                    };
                    if (itinerary_to_add[i].id === step_hover) {
                        marker_option.label = {
                            text: (i + 1).toString(),
                            color: "#e6592f",
                            fontWeight: "bold"
                        }
                    }
                }
                let marker = new google.maps.Marker(marker_option);
                marker.setMap(map);
                itinerary_markers.push({index: itinerary_to_add[i].id, marker: marker, hover: (itinerary_to_add[i].id === step_hover)});
            }
        }
    }
    componentDidMount() {
        MapRun(this);
    }
     render() {
        return (
            <div id={ "map-summary-main" }/>
        );
    }
}

export default SummaryMainMap;