import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { SetOpenDatePickers } from "../../../Actions/Flight";

export function useCloseDatePicker(ref, popperRef, openDatePickers, group_index) {
    const dispatch = useDispatch();

    useEffect(() => {
        function handleClickOutside(event) {
            const path = event.composedPath ? event.composedPath() : event.path;
            if ((ref.current !== null && !ref.current.contains(event.target)) && (popperRef.current !== null && path !== undefined && !path.includes(popperRef.current))) {
                let temp_open_date_pickers = openDatePickers.slice();
                let temp_open_date_pickers_group = temp_open_date_pickers[group_index].slice();

                temp_open_date_pickers_group.map((date_picker, date_picker_index) => {
                    temp_open_date_pickers_group[date_picker_index] = false;
                });
                temp_open_date_pickers[group_index] = temp_open_date_pickers_group;
                dispatch(SetOpenDatePickers(temp_open_date_pickers));
            }
        }

        //Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            //Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, openDatePickers]);
}
