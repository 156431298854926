import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from '@material-ui/core/Chip';

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { SelectAirlines } from "../../../Actions/Flight";

import axios from "axios";

const useStyles = makeStyles(() => ({
    autoComplete: {
        "&& .mui-jss-MuiAutocomplete-popupIndicator": {
            display: "none"
        }  
    },
    textFieldInput: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 400,
        // color: '#0000008A',
        paddingTop: 4,
        paddingBottom: 4
    },
    withBorder: {
        borderRadius: "0px 8px 8px 0px"
    },
    withFullBorder: {
        borderRadius: 8
    },
    flexWrap: {
        flexWrap: "wrap"
    },
    customMarginDense: {
        transform: "translate(14px, 15px) scale(1)"
    }
}));
let source = null;
const AirlineSearch = (({ group_index, group_airlines }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const [airlineInput, setAirlineInput] = useState("");
    const [airlineResults, setAirlineResults] = useState([]);

    useEffect(() => {
        if (airlineInput.length >= 3) {
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                if (source !== null) {
                    source.cancel();
                }
                source = axios.CancelToken.source();
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}iata-airlines/?&search=${airlineInput}&limit=15`,
                    cancelToken: source.token
                }).then(function (response) {
                    setAirlineResults(response.data.results);
                }).catch(function (error) {
                    console.log(error.response);
                });
            }
        }
    }, [airlineInput]);
    
    return (
        <Autocomplete
            multiple
            filterSelectedOptions
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => {
                if (option === "") {
                    return "";
                }
                return (option.commercial_name);
            }}
            onInputChange={(event, value) => setAirlineInput(value)}
            onChange={(event, value) => dispatch(SelectAirlines(value, group_index))}
            value={group_airlines}
            noOptionsText={t("flight_groups.no_airline_found")}
            options={airlineResults}
            className={classes.autoComplete}
            limitTags={2}
            renderTags={(tagValue, getTagProps) => 
                tagValue.map((option, index) => ( 
                    <Chip size="small" label={option.commercial_name} {...getTagProps({ index })} />
                ))
            }
            renderInput={(props) => (
                <TextField 
                    {...props}
                    label={t("flight_groups.pick_airlines")}
                    InputProps={{
                        ...props.InputProps,
                        className: `${classes.textFieldInput} ${isHandHeld ? classes.withFullBorder : classes.withBorder}`,
                        classes: { focused: `${classes.textFieldInput} ${isHandHeld ? classes.withFullBorder : classes.withBorder} ${classes.flexWrap}` },
                        labelWidth: 200
                    }}
                    InputLabelProps={{ classes: { marginDense: classes.customMarginDense } }}
                    inputProps={{
                        ...props.inputProps,
                        // disable autocomplete and autofill
                        autoComplete: 'off'
                    }}
                    variant="outlined"
                    size="small"
                />
            )}
        />
    );
});

export default React.memo(AirlineSearch);
