//---- Actions ----//
import { DisplayCars } from "../../../Actions/CarsSearch";

export default (filters_cars, filters, dispatch, t) => {
	let tmp_filters = {};
	Object.keys(filters).map((filter) => {
		let arr = [];
		if (filter !== "provider") {
			filters[filter].map((elem) => {
				if (elem.checked) {
					arr.push(elem.name)
				}
				if (arr.length > 0)
					tmp_filters[filter] = arr;
			})
		}
		else if (filter === "provider") {
			filters[filter].map((elem) => {
				if (elem.checked) {
					arr.push({
						id: elem.id,
						subprovider_name: elem.subprovider_name
					})
				}
				if (arr.length > 0)
					tmp_filters[filter] = arr;
			})
		}
	})
	let display_cars = filters_cars.slice();
	Object.keys(tmp_filters).map((key) => {
			display_cars = display_cars.filter((cars) => {
				if (key === "provider") {
					return tmp_filters[key].some((type) => {
						return (cars.provider_id === type.id && cars.subprovider === type.subprovider_name);
					});
				}
				else
					return cars;
			}).filter((cars) => {
				if (key === "categories") {
					return tmp_filters[key].some((type) => {
						if (JSON.parse(localStorage.getItem("config")).quotation_code === "marcovasco" && cars.vehicle_info.code === "ECAR") {
							return false;
						}
						return type === t("cars.categories." + (cars.vehicle_info.vehicle_category).toLowerCase());
					});
				}
				else
					return cars;
			}).filter((cars) => {
				if (key === "passenger") {
					return tmp_filters[key].some((type) => {
						return (type.includes("1-5 passengers") ? cars.vehicle_info.passenger <= 5 : cars.vehicle_info.passenger > 5);
					});
				}
				else
					return cars;
			}).filter((cars) => {
				if (key === "options") {
					return tmp_filters[key].some((type) => {
						return (type.includes("automatic") ? (cars.vehicle_info.transmission).toLowerCase() === type : cars.vehicle_info.air_condition === true);
					});
				}
				else
					return cars;
			})
	})
	dispatch(DisplayCars(display_cars));
}