import { AccommodationCart } from "../../Itinerary/objects/accommodationCart";
import { Itinerary } from "../../Itinerary/objects/itinerary";
import { ManualProductAccommodationCart } from "../../Itinerary/objects/manualProductAccommodationCart";

type Options = {
    step: Itinerary,
    accommodations: (AccommodationCart | ManualProductAccommodationCart)[]
}

export function hasMoreNights(options: Options): number {
    const nights = window.moment.utc(options.step.end_date).startOf('day').diff(
        window.moment.utc(options.step.start_date).startOf('day'),
        'days'
    );

    const sortedAccommodations = options.accommodations.filter((accommodation) => {
        const startDate = window.moment.utc(options.step.start_date);
        const endDate = window.moment.utc(options.step.end_date);
        return window.moment.utc(
            accommodation.start_date
        ).isBetween(
            startDate,
            endDate,
            'days',
            '[]'
        );
    }).sort((a, b) => {
        return window.moment.utc(a.start_date).isBefore(
            window.moment.utc(b.start_date)
        ) ? -1 : 1;
    });
    const stepEndDate = window.moment.utc(options.step.end_date).startOf('day');

    const roomsNights = sortedAccommodations.reduce((prev, current) => {
        const lastDate = window.moment.utc(prev.lastDate).startOf('day');
        const accommodationStartDate = window.moment.utc(current.start_date).startOf('day');
        let startDate = accommodationStartDate.isBefore(
            lastDate
        ) ?
            lastDate :
            accommodationStartDate;

        if (startDate.isAfter(stepEndDate)) {
            startDate = stepEndDate.clone();
        }

        const accommodationEndDate = window.moment.utc(current.end_date).startOf('day');
        let endDate = accommodationEndDate.isAfter(stepEndDate) ?
            stepEndDate :
            accommodationEndDate;

        if (endDate.isBefore(startDate)) {
            endDate = startDate.clone();
        }

        return {
            lastDate: endDate.toISOString(),
            count: prev.count + endDate.diff(
                startDate,
                'days'
            )
        };
    }, {
        lastDate: options.step.start_date,
        count: 0
    });

    return nights - roomsNights.count;
}
