export default (reverse, key, key2) => {
    let rev = reverse ? 1 : -1;
    return function(a, b) {
    	if (key2) {
	        var x = a[key][key2]; var y = b[key][key2];
	        return ((x < y) ? -1 : ((x > y) ? 1 : 0)) * rev;
    	}
    	else {
	        var x = a[key]; var y = b[key];
	        return ((x < y) ? -1 : ((x > y) ? 1 : 0)) * rev;
    	}
    };
}