import React, {Fragment, useEffect, useState, useRef} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import MuiPhoneNumber from "material-ui-phone-number";
import axios from "axios";
import { useSnackbar } from "notistack";

import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import InputLabel   from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";


import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import CountrySearch from "../Menu/MaterialTripList/CountrySearch.js";
import TravelerInfo from "../Menu/MaterialTripList/TravelerInfo.js";
import TravelerMoreInfo from '../Menu/MaterialTripList/TravelerMoreInfo';
import TravelerMoreInfoMobile from '../Menu/MaterialTripList/TravelerMoreInfoMobile';
import Notify from "../Common/Notify";
import UpdateContactTrav from "../SummaryQuotation/Paiement/Functions/UpdateContactTrav";
import UpdateLeadTrav from "../SummaryQuotation/Paiement/Functions/UpdateLeadTrav";
import UpdateTravelers from "../SummaryQuotation/Paiement/Functions/UpdateTravelers";
import UpdateStatusAndMail from "../SummaryQuotation/Paiement/Functions/UpdateStatusAndMail";
import GetAllCart from "./Functions/GetAllCart";


const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400:{
        fontWeight: 400
    },
    fontWeight700:{
        fontWeight: 700
    },
    fontWeight900:{
        fontWeight: 900
    },
    fontSize10:{
        fontSize: 10
    },
    fontSize12:{
        fontSize: 12
    },
    fontSize14:{
        fontSize: 14
    },
    fontSize16:{
        fontSize: 16
    },
    fontSize18:{
        fontSize: 18
    },
    fontSize20:{
        fontSize: 20
    },
    fontSize24:{
        fontSize: 24
    },
    upperCase:{
        textTransform: "uppercase",
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textLeft:{
        textAlign: "left"
    },
    textCenter:{
        textAlign: "center"
    },
    textRight:{
        textAlign: "right"
    },
    textJustify: {
        textAlign: "justify"
    },
    spacer:{
        padding: "8px 0px"
    },
    smallPaddingTop: {
        paddingTop: 5
    },
    genericBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box"
    },
    genericPadding:{
        padding: "0px 20px"
    },
    genericMargin: {
        margin: "20px 0px"
    },
    titleBorder: {
        borderBottom: "0.5px solid rgba(0, 0, 0, 0.25)",
    },
    separator:{
        borderTop: "0.5px solid rgba(0, 0, 0, 0.25)"
    },
    noMarginBottom: {
        marginBottom: 0
    },
    formControlRoot1: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            // zIndex: -1
        }
    },
    formControlRootMobile1: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            // zIndex: -1
        }
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    outlinedInput:{
        padding: "7px 30px 7px 20px;"
    },
    circleBorder:{
        borderRadius: 8
    },
    cancelButton: {
        color: "#0000008A",
        backgroundColor: "#FFFFFF",
        marginRight: 20
    },
    validateButton: {
        color: "#FFFFFF",
        backgroundColor: "#2C98F0"
    },
    warningBorder: {
        borderLeft: "5px solid #ffc487"
    }
}));

const InscriptionTravelers = ({checkValidate, setCheckValidate, selectedProducts, summaryProps, setIsLoading, setPrevButton}) => {
    const classes = useStyles();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();

    const trip_main_info = useSelector(store => store.summary.trip_main_info);
    const trip_info = useSelector(store => store.summary.trip_info);
    const travelers_group = useSelector(store => store.summary.travelers_group);
    const user = useSelector(store => store.user.user);

    const hotel_cart = useSelector(store => store.summary.hotel_cart);
    const cars_cart = useSelector(store => store.summary.cars_cart);
    const flight_cart = useSelector(store => store.summary.flight_cart);
    const manual_cart = useSelector(store => store.summary.manual_cart);
	const poi_cart = useSelector(store => store.summary.poi_cart);
	const transfers_cart = useSelector(store => store.summary.transfers_cart);

    const travelers = trip_info.travelers.slice();
    const addressLabel = useRef(null);
    const addressLabelWidth = addressLabel.current ? addressLabel.current.clientWidth : 45
    const addressDetailsLabel = useRef(null);
    const addressDetailsLabelWidth = addressDetailsLabel.current ? addressDetailsLabel.current.clientWidth : 126
    const postalCodeLabel = useRef(null);
    const postalCodeLabelWidth = postalCodeLabel.current ? postalCodeLabel.current.clientWidth : 66
    const cityLabel = useRef(null);
    const cityLabelWidth = cityLabel.current ? cityLabel.current.clientWidth : 24
    const emailLabel = useRef(null);
    const emailLabelWidth = emailLabel.current ? emailLabel.current.clientWidth : 42

    const [tempTravelers, setTempTravelers] = useState(travelers.slice());
    const [rooms, setRooms] = useState([]);
    const [moreInfo, setMoreInfo] = useState([]);
    const [infoIncomplete, setInfoIncomplete] = useState([]);
    const [tempContactLead, setTempContactLead] = useState(null);
    const [withApplicant, setWithApplicant] = useState(true);
    const [frenchCountry, setFrenchCountry] = useState(null);
    const [leadMoreInfo, setLeadMoreInfo] = useState(false);

    useEffect(() => {
        let temp_rooms = [];
        travelers_group.map((group) => {
            if (group.default === true){
                let sorted_group = Object.assign({}, group);
                sorted_group.travelers_list.sort((a, b) => {
                    let da = new Date(a.birth_date);
                    let db = new Date(b.birth_date);
                    return (da - db);
                })
                temp_rooms.push(sorted_group);
            }
        })
        getFrenchCountry();
        setRooms(temp_rooms);
        createTempTravelers();
        createTempContactLead(trip_info.contact_traveler_detail)
    }, []);
    useEffect(() => {
        if (checkValidate) {
            checkInfo();
            setCheckValidate(false);
        }
    }, [checkValidate]);
    const checkProducts = () => {
        let cart_request = [];
        let trip_id_version = trip_main_info.current_version;
        let trip_id = summaryProps.params.trip_id;
        let trip_token = summaryProps.params.trip_token;
        let checkedHotels = selectedProducts.hotels;
        let checkedCars = selectedProducts.cars;
        let checkedTransfers = selectedProducts.transfers;
        let checkedMainFlight = selectedProducts.mainFlights;
        let checkedStepFlight = selectedProducts.step_flight;
        let checkedPoi = selectedProducts.poi;
        let error = false;
        const { pass_check, headers } = CheckBeforeRequest();

        hotel_cart.map((hotel) => {
            if (hotel.is_displayed && hotel.booking_status !== undefined && ((hotel.booking_status !== null && hotel.booking_status.status_booking !== "CANCELLED") || hotel.booking_status === null)) {
                let isChecked = false;
                let toEdit = false;
                checkedHotels.map((iti) => {
                    if (iti.includes(hotel.id)) {
                        isChecked = true;
                    }
                });
                if (isChecked && hotel.is_optional) {
                    toEdit = true;

                } else if (!isChecked && !hotel.is_optional) {
                    toEdit = true;
                }
                if (toEdit) {
                    cart_request.push(
                        axios({
                            method: "PATCH",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/accommodation/${hotel.id}/`,
                            data: {
                                token: trip_token,
                                is_optional: !hotel.is_optional
                            }
                        })
                    );
                    if (hotel.rooms.length !== 0) {
                        hotel.rooms.map((room) => {
                            cart_request.push(
                                axios({
                                    method: "PATCH",
                                    headers: headers,
                                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/accommodation/${room.id}/`,
                                    data: {
                                        token: trip_token,
                                        is_optional: !room.is_optional
                                    }
                                })
                            );
                        });
                    }
                }
            }
        });
        cars_cart.map((car) => {
            if (car.is_displayed && car.booking_status !== undefined && ((car.booking_status !== null && car.booking_status.status_booking !== "CANCELLED") || car.booking_status === null)) {
                let isChecked = false;
                let toEdit = false;
                checkedCars.map((iti) => {
                    if (iti.includes(car.id)) {
                        isChecked = true;
                    }
                });
                if (isChecked && car.is_optional) {
                    toEdit = true;
                } else if (!isChecked && !car.is_optional) {
                    toEdit = true;
                }
                if (toEdit) {
                    cart_request.push(
                        axios({
                            method: "PATCH",
                            headers: headers,
                            // url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/accommodation/?token=${trip_token}`,
                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/car/${car.id}/`,
                            data: {
                                token: trip_token,
                                is_optional: !car.is_optional
                            }
                        })
                    );
                }
            }
        });
        poi_cart.map((poi) => {
            if (poi.is_displayed && poi.booking_status !== undefined && ((poi.booking_status !== null && poi.booking_status.status_booking !== "CANCELLED") || poi.booking_status === null)) {
                let isChecked = false;
                let toEdit = false;
                checkedPoi.map((iti) => {
                    if (iti.includes(poi.id)) {
                        isChecked = true;
                    }
                });
                if (isChecked && poi.is_optional) {
                    toEdit = true;
                } else if (!isChecked && !poi.is_optional) {
                    toEdit = true;
                }
                if (toEdit) {
                    cart_request.push(
                        axios({
                            method: "PATCH",
                            headers: headers,
                            // url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/accommodation/?token=${trip_token}`,
                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/custom-products/${poi.id}/`,
                            data: {
                                token: trip_token,
                                is_optional: !poi.is_optional
                            }
                        })
                    );
                }
            }
        });
        transfers_cart.map((transfer) => {
            if (transfer.is_displayed && transfer.booking_status !== undefined && ((transfer.booking_status !== null && transfer.booking_status.status_booking !== "CANCELLED") || transfer.booking_status === null)) {
                let isChecked = false;
                let toEdit = false;
                checkedTransfers.map((iti) => {
                    if (iti.includes(transfer.id)) {
                        isChecked = true;
                    }
                });
                if (isChecked && transfer.is_optional) {
                    toEdit = true;
                } else if (!isChecked && !transfer.is_optional) {
                    toEdit = true;
                }
                if (toEdit) {
                    cart_request.push(
                        axios({
                            method: "PATCH",
                            headers: headers,
                            // url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/accommodation/?token=${trip_token}`,
                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/${transfer.is_custom ? "custom-products" : "transfers"}/${transfer.id}/`,
                            data: {
                                token: trip_token,
                                is_optional: !transfer.is_optional
                            }
                        })
                    );
                }
            }
        });
        manual_cart.map((manual) => {
            if (manual.is_displayed && manual.booking_status !== undefined && ((manual.booking_status !== null && manual.booking_status.status_booking !== "CANCELLED") || manual.booking_status === null)) {
                let isChecked = false;
                let toEdit = false;
                Object.keys(selectedProducts).map((key) => {
                    selectedProducts[key].map((iti) => {
                        if (iti.includes(manual.id)) {
                            isChecked = true;
                        }
                    })
                });
                if (isChecked && manual.is_optional) {
                    toEdit = true;
                } else if (!isChecked && !manual.is_optional) {
                    toEdit = true;
                }
                if (toEdit) {
                    cart_request.push(
                        axios({
                            method: "PATCH",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/manual-products/${manual.id}/`,
                            data: {
                                token: trip_token,
                                is_optional: !manual.is_optional
                            }
                        })
                    );
                }
            }
        });
        flight_cart.map((flight) => {
            if (flight.is_displayed && flight.booking_status !== undefined && ((flight.booking_status !== null && flight.booking_status.status_booking !== "CANCELLED") || flight.booking_status === null)) {
                let isChecked = false;
                let toEdit = false;
                checkedMainFlight.map((iti) => {
                    if (iti.includes(flight.id)) {
                        isChecked = true;
                    }
                });
                checkedStepFlight.map((iti) => {
                    if (iti.includes(flight.id)) {
                        isChecked = true;
                    }
                });
                if (isChecked && flight.is_optional) {
                    toEdit = true;
                } else if (!isChecked && !flight.is_optional) {
                    toEdit = true;
                }
                if (toEdit) {
                    cart_request.push(
                        axios({
                            method: "PATCH",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${trip_id_version}/flight/${flight.id}/`,
                            data: {
                                token: trip_token,
                                is_optional: !flight.is_optional
                            }
                        })
                    )
                };
            }
        });
        axios.all([...cart_request]).then(axios.spread((...responses) => {
            GetAllCart(trip_id, trip_id_version, trip_token, dispatch, setIsLoading);
        })).catch((error) => {
            error = true;
        });
        return !error;
    }
    const checkInfo = () => {
        let err = false;
        let tmp_trip = trip_main_info.data.find((el) => {
            return el.id === trip_main_info.current_version;
        });
        if (tmp_trip.status === "TO_UPDATED" || tmp_trip.status === "ONGOING" || tmp_trip.status === "VALIDATED" || tmp_trip.status === "TOBOOK_VERDIE") {
            if (tempContactLead !== null && (tempContactLead.email === null || tempContactLead.email === '')) {
                Notify(t("summary.you_need_to_inform_your_mail"), {status: "danger", pos: "top-center"});
                err = true;
            }
            if (err === false) {
                // UpdateTravelers(this.state.traveler_info, t, trip_main_info.token, trip_main_info.status);
                UpdateContactTrav(tempContactLead, trip_main_info, dispatch);
                UpdateLeadTrav(tempContactLead, t, trip_info.contact_traveler, trip_main_info.token);
                UpdateStatusAndMail(trip_main_info.id, t, trip_main_info.current_version, trip_main_info.token, tempContactLead.email, dispatch, enqueueSnackbar);
                checkProducts();
                setPrevButton(true);
            }
            else {
                enqueueSnackbar(t("summary.cant_update_now"), {
                    variant: "error",
                });
            }
        }
        else {
            enqueueSnackbar(t("summary.cant_update_now"), {
                variant: "error",
            });
        }
    }

    const getFrenchCountry = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check){
            axios({
                method: "GET",
                url: `${API_HREF}iata-countries/?search=fr`,
                headers: headers
            }).then(function(response){
                response.data.results.map((country) => {
                    if (country.country_code === "FR"){
                        setFrenchCountry(country);
                    }
                })
            })
        }
    }
    const createTempTravelers = () => {
        let end_date = null;
        for(version_index=0 ; version_index<trip_main_info.data.length ; version_index++){
            if(trip_main_info.data[version_index].id === trip_main_info.current_version){
                end_date = trip_main_info.data[version_index].end_date;
            }
        }
        let temp_travelers = [];
        let numero_adult = 1;
        let numero_teen = 1;
        let numero_child = 1;
        let numero_baby = 1;
        let temp_more_info = [];
        let temp_info_incomplete = [];

        tempTravelers.map((traveler, traveler_index) =>{
            let temp_traveler = Object.assign({}, traveler);
            let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
            if (age > 12 && age < 18) {
                temp_traveler.age_label = t("flight_search.ado") + " " + numero_teen;
                numero_teen += 1;
            } else if (age > 2 && age <= 12) {
                temp_traveler.age_label = t("flight_search.child") + " " + numero_child;
                numero_child += 1;
            } else if (age <= 2) {
                temp_traveler.age_label = t("flight_search.baby") + " " + numero_baby;
                numero_baby += 1;
            } else if (age >= 18) {
                temp_traveler.age_label = t("flight_search.adult") + " " + numero_adult;
                numero_adult += 1;
            }

            if(rooms.length !== 0){
                for(groupe_index=0 ; groupe_index<groups.length ; groupe_index++){
                    if (groups[groupe_index].travelers.includes(traveler.id)){
                        temp_traveler.room_label = t("accommodation.room_composition_room") + (groupe_index + 1);
                    }
                }
            }
            if(tempContactLead !== null){
                if(tempContactLead.id === temp_traveler.id){
                    temp_traveler.temp_lead = true;
                }
                else{
                    temp_traveler.temp_lead = false;
                }
            }else{
                temp_traveler.temp_lead = false;
            }
            temp_traveler.default_name = false;
            if (traveler.first_name === t('global.first_name') || traveler.first_name === t("trip.traveler")){
                temp_traveler.first_name = traveler.first_name;
                temp_traveler.default_name = true;
            }
            if(traveler.last_name === t('global.last_name') || traveler.last_name.includes(t("global.adult")) || traveler.last_name.includes(t("global.child"))){
                temp_traveler.last_name = traveler.last_name;
                temp_traveler.default_name = true;
            }

            if(traveler.identity_doc_number === null){
                temp_traveler.identity_doc_number = "";
            }
            if(traveler.identity_doc_country_emission === null){
                temp_traveler.identity_doc_country_emission = "";
            }
            // if(traveler.identity_doc_expiration === null){
            //     temp_traveler.identity_doc_expiration = moment.utc().format("YYYY-MM-DD");
            // }
            if(traveler.country === null){
                temp_traveler.country = "";
            }
            if(traveler.nationality === null){
                temp_traveler.nationality = "";
            }
            if(traveler.email === null){
                temp_traveler.email = "";
            }

            temp_traveler.temp_phone = temp_traveler.phone_country_code + temp_traveler.phone_number;
            temp_traveler.real_index = traveler_index;

            temp_travelers.push(temp_traveler);
            temp_more_info.push(false);
            temp_info_incomplete.push(false);
        })

        temp_travelers.sort((a, b) => {
            let da = new Date(a.birth_date);
            let db = new Date(b.birth_date);
            return (da-db);
        })

        setTempTravelers(temp_travelers);
        setMoreInfo(temp_more_info);
        setInfoIncomplete(temp_info_incomplete);
    }
    const createTempContactLead = (base_contact_lead) => {
        let temp_contact_lead = Object.assign({}, base_contact_lead);

        temp_contact_lead.temp_lead = true;
        if (temp_contact_lead.first_name === t('global.first_name') || temp_contact_lead.first_name === t("trip.traveler")){
            temp_contact_lead.first_name = "";
        }
        if(temp_contact_lead.last_name === t('global.last_name') || temp_contact_lead.last_name.includes(t("global.adult")) || temp_contact_lead.last_name.includes(t("global.child"))){
            temp_contact_lead.last_name = "";
        }
        if(temp_contact_lead.identity_doc_number === null){
            temp_contact_lead.identity_doc_number = "";
        }
        if(temp_contact_lead.identity_doc_country_emission === null){
            temp_contact_lead.identity_doc_country_emission = "";
        }
        if(temp_contact_lead.country === null){
            temp_contact_lead.country = "";
        }
        if(temp_contact_lead.nationality === null){
            if (JSON.parse(localStorage.getItem("config")).quotation_code === "verdie"){
                temp_contact_lead.nationality = frenchCountry;
            }else{
                temp_contact_lead.nationality = "";
            }
        }
        if(temp_contact_lead.email === null){
            temp_contact_lead.email = "";
        }
        if(temp_contact_lead.city === null){
            temp_contact_lead.city = "";
        }
        if(temp_contact_lead.postal_code === null){
            temp_contact_lead.postal_code = "";
        }
        if(temp_contact_lead.address === null){
            temp_contact_lead.address = "";
        }
        if(temp_contact_lead.address_details === null){
            temp_contact_lead.address_details = "";
        }

        temp_contact_lead.temp_phone = temp_contact_lead.phone_country_code + temp_contact_lead.phone_number;

        setTempContactLead(Object.assign({}, temp_contact_lead));
    }
    const handleLeadAddress = (e) => {
        let temp_contact_lead = Object.assign({}, tempContactLead)
        temp_contact_lead.address = e.target.value;
        setTempContactLead(temp_contact_lead);
        for (traveler_index=0 ; traveler_index<tempTravelers.length ; traveler_index++){
            if(tempTravelers[traveler_index].id === tempContactLead.id){
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_contact_lead.age_label = age_label;
                temp_contact_lead.room_label = room_label;
                newTravelers[traveler_index] = temp_contact_lead;
                setTempContactLead(temp_contact_lead);
                setTempTravelers(newTravelers);
            }
        }
    };

    const handleLeadAddressDetails = (e) => {
        let temp_contact_lead = Object.assign({}, tempContactLead)
        temp_contact_lead.address_details = e.target.value;
        setTempContactLead(temp_contact_lead);
        for (traveler_index=0 ; traveler_index<tempTravelers.length ; traveler_index++){
            if(tempTravelers[traveler_index].id === tempContactLead.id){
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_contact_lead.age_label = age_label;
                temp_contact_lead.room_label = room_label;
                newTravelers[traveler_index] = temp_contact_lead;
                setTempContactLead(temp_contact_lead);
                setTempTravelers(newTravelers);
            }
        }
    };

    const handleLeadPostalCode = (e) => {
        let temp_contact_lead = Object.assign({}, tempContactLead)
        temp_contact_lead.postal_code = e.target.value;
        setTempContactLead(temp_contact_lead);
        for (traveler_index=0 ; traveler_index<tempTravelers.length ; traveler_index++){
            if(tempTravelers[traveler_index].id === tempContactLead.id){
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_contact_lead.age_label = age_label;
                temp_contact_lead.room_label = room_label;
                newTravelers[traveler_index] = temp_contact_lead;
                setTempContactLead(temp_contact_lead);
                setTempTravelers(newTravelers);
            }
        }
    };

    const handleLeadCity = (e) => {
        let temp_contact_lead = Object.assign({}, tempContactLead);
        temp_contact_lead.city = e.target.value;
        setTempContactLead(temp_contact_lead);
        for (traveler_index=0 ; traveler_index<tempTravelers.length ; traveler_index++){
            if(tempTravelers[traveler_index].id === tempContactLead.id){
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_contact_lead.age_label = age_label;
                temp_contact_lead.room_label = room_label;
                newTravelers[traveler_index] = temp_contact_lead;
                setTempContactLead(temp_contact_lead);
                setTempTravelers(newTravelers);
            }
        }
    };

    const handlePhoneNumber = ((value, country_data, traveler) => {
        let temp_traveler = Object.assign({}, traveler);
        temp_traveler.temp_phone = value;
        // country_data has the country code
        temp_traveler.phone_country_code = "+" + country_data.dialCode;
        temp_traveler.phone_number = value.replace(`+${country_data.dialCode}`, "")
        if(traveler.id === tempContactLead.id){
            setTempContactLead(temp_traveler);
        }
        for (traveler_index=0 ; traveler_index<tempTravelers.length ; traveler_index++){
            if(tempTravelers[traveler_index].id === traveler.id){
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let email = tempTravelers[traveler_index].email;
                let newTravelers = tempTravelers.slice();
                temp_traveler.email = email;
                temp_traveler.age_label = age_label;
                temp_traveler.room_label = room_label;
                newTravelers[traveler_index] = temp_traveler;
                setTempTravelers(newTravelers);
            }
        }
    })

    const handleEmail = (e, traveler) => {
        let temp_traveler = Object.assign({}, traveler);
        temp_traveler.email = e.target.value;
        if(traveler.id === tempContactLead.id){
            setTempContactLead(temp_traveler);
        }
        for (traveler_index=0 ; traveler_index<tempTravelers.length ; traveler_index++){
            if(tempTravelers[traveler_index].id === traveler.id){
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_traveler.age_label = age_label;
                temp_traveler.room_label = room_label;
                newTravelers[traveler_index] = temp_traveler;
                setTempTravelers(newTravelers);
            }
        }
    };
    const handleWithApplicant = () => {
        let temp_new_lead = Object.assign({}, tempContactLead);
        temp_new_lead.id = 0;
        temp_new_lead.first_name = "";
        temp_new_lead.last_name = "";
        setTempContactLead(temp_new_lead);
        setWithApplicant(!withApplicant);

        let temp_travelers = [];
        for (traveler_index=0 ; traveler_index<tempTravelers.length ; traveler_index++){
            let temp_traveler = Object.assign({}, tempTravelers[traveler_index]);
            temp_traveler.temp_lead = false;
            temp_travelers.push(temp_traveler);
        }
        setTempTravelers(temp_travelers);
    }
    const changeApplicant = (traveler, traveler_index) => {
        let temp_travelers = tempTravelers.slice();
        // change id of temp Contact lead
        for(parse_traveler_index=0 ; parse_traveler_index<temp_travelers.length ; parse_traveler_index++){
            if(temp_travelers[parse_traveler_index].id === tempContactLead.id && temp_travelers[parse_traveler_index].temp_lead === true){
                let contact_traveler = Object.assign({}, temp_travelers[parse_traveler_index]);
                contact_traveler.temp_lead = false;
                temp_travelers[parse_traveler_index] = contact_traveler;
            }
        }

        let contact_traveler = Object.assign({}, tempContactLead);

        setTempContactLead(contact_traveler);

        let new_traveler = Object.assign({}, tempContactLead);

        new_traveler.temp_lead = true;
        new_traveler.id = traveler.id;
        new_traveler.room_label = traveler.room_label;
        new_traveler.age_label = traveler.age_label;
        new_traveler.identity_doc = traveler.identity_doc;
        new_traveler.identity_doc_country_emission = traveler.identity_doc_country_emission;
        new_traveler.identity_doc_expiration = traveler.identity_doc_expiration;
        new_traveler.identity_doc_number = traveler.identity_doc_number;
        new_traveler.loyalty_cards = traveler.loyalty_cards;

        setTempContactLead(new_traveler);
        temp_travelers[traveler_index] = new_traveler;
        setTempTravelers(temp_travelers);
    }
    return (
        <Grid>
            <Grid style={{ padding: "20px 0px" }} >
                <div className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${!downDesktop ? classes.fontSize24 : classes.fontSize20} ${classes.upperCase} ${classes.letterSpacing}`}>
                    { t("menu.trip_list.travelers_info") }
                </div>
                <Grid item xs={12} className={`${classes.warningBorder} ${classes.genericMargin}`}>
                    <div className={`${!downDesktop ? "" :classes.smallPaddingTop} ${classes.genericText} ${!downDesktop ? classes.fontSize18 : classes.fontSize16} ${classes.textJustify}`} style={!downDesktop ? {background: "#fef3cd", paddingLeft: 5} : { marginBottom: 5, background: "#fef3cd", paddingLeft: 5 }}>
                        <div className={classes.genericText}>{t("summary.traveler_warning")}</div>
                    </div>
                </Grid>
                {
                    rooms.length !== 0 && (
                        <Grid className={`${classes.genericBorder} ${classes.genericPadding} ${classes.genericMargin}`}>
                            <div className={`${!downDesktop ? "" :classes.smallPaddingTop} ${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize18 : classes.fontSize16} ${classes.upperCase}`} style={!downDesktop ? {} : { marginBottom: 5  }}>
                                { t("summary.room_affectation") }
                            </div>
                            <Grid container alignItems="flex-start" justify="flex-start" direction={!downDesktop ? "row" : "column"}spacing={5}>
                                {
                                    tempTravelers.length !== 0 && rooms.map((room, room_index) =>{
                                        return(
                                            <Grid item key={room_index}>
                                                <Grid className={`${!downDesktop ? classes.spacer : ""} ${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize14 : classes.fontSize12} ${classes.upperCase}`}>
                                                    { t("accommodation.room_composition_room") + (room_index + 1)}
                                                </Grid>
                                                {
                                                    room.travelers_list.map((traveler) =>{
                                                        if(traveler.first_name === t("global.first_name") || traveler.first_name === t("trip.traveler") || traveler.last_name === t('global.last_name') || traveler.last_name.includes(t("global.adult")) || traveler.last_name.includes(t("global.child"))){
                                                            let age_label = "";
                                                            for(traveler_index=0 ; traveler_index<tempTravelers.length ; traveler_index++){
                                                                if(tempTravelers[traveler_index].id === traveler.id){
                                                                    age_label = tempTravelers[traveler_index].age_label;
                                                                    return(
                                                                        <Grid key={traveler.id} className={`${classes.genericText} ${classes.fontWeight400} ${!downDesktop ? classes.fontSize12 : classes.fontSize10} ${classes.upperCase}`} style={{ marginBottom: 5}}>
                                                                            {age_label}
                                                                        </Grid>
                                                                    )
                                                                }
                                                            }

                                                        }else{
                                                            return(
                                                                <Grid key={traveler.id} className={`${classes.genericText} ${classes.fontWeight400} ${!downDesktop ? classes.fontSize12 : classes.fontSize10} ${classes.upperCase}`} style={{ marginBottom: 5}}>
                                                                    {t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name}
                                                                </Grid>
                                                            )
                                                        }
                                                    })
                                                }
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    )
                }
                <Grid className={classes.genericBorder}>
                    <Grid container className={classes.genericPadding} alignItems="center" justify="space-between">
                        <Grid item>
                            <Typography variant={"h6"} className={classes.genericText}> {t("menu.trip_list.applicant")} </Typography>
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={ <Checkbox checked={withApplicant} onChange={() => handleWithApplicant()} /> }
                                label={ t("home.set_first_lead") }
                                classes={{ label: `${classes.genericText} ${classes.fontWeight900} ${!downDesktop ? classes.fontSize12 : classes.fontSize10} ${classes.upperCase}` }}
                                className={`${classes.noMarginBottom} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12}`}
                            />
                        </Grid>
                    </Grid>
                    {
                        tempContactLead !== null && (
                            <Grid className={classes.genericPadding} style={!downDesktop ? {} : {marginBottom: 8 }}>
                                <Grid className={`${classes.spacer} ${classes.textLeft} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.upperCase} ${classes.letterSpacing}`}>
                                    { t("menu.trip_list.identity") }
                                </Grid>
                                <TravelerInfo tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} traveler={tempContactLead} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={true} redFormControl={false} nationality_option={false} size="medium" />
                                <Grid className={`${classes.spacer} ${classes.separator} ${classes.textLeft} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.upperCase} ${classes.letterSpacing}`} style={{ marginTop: 8 }}>
                                    { t("menu.trip_list.living_place") }
                                </Grid>
                                {
                                    !downDesktop && (
                                        <Grid>
                                            <Grid container alignItems="center" justify="space-between" className={classes.spacer} spacing={4} >
                                                <Grid item xs={6}>
                                                    <FormControl variant="outlined" className={classes.formControlRoot1}>
                                                        <InputLabel id="address-label" ref={addressLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.address")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="address"
                                                            id="address-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={addressLabelWidth}
                                                            value={tempContactLead.address}
                                                            onChange={handleLeadAddress}
                                                            autoComplete="off"
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6} className={classes.textRight}>
                                                    <FormControl variant="outlined" className={classes.formControlRoot1}>
                                                        <InputLabel id="address_complementary-label" ref={addressDetailsLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.address_complementary")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="address_complementary"
                                                            id="address_complementary-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={addressDetailsLabelWidth}
                                                            value={tempContactLead.address_details}
                                                            onChange={handleLeadAddressDetails}
                                                            autoComplete="off"
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container alignItems="center" justify="space-between" className={classes.spacer} spacing={4}>
                                                <Grid item xs={2}>
                                                    <FormControl variant="outlined" className={classes.formControlRoot1}>
                                                        <InputLabel id="postal-code-label" ref={postalCodeLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.postal_code")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="postal-code"
                                                            id="postal-code-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={postalCodeLabelWidth}
                                                            value={tempContactLead.postal_code}
                                                            onChange={handleLeadPostalCode}
                                                            autoComplete="off"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <FormControl variant="outlined" className={classes.formControlRoot1}>
                                                        <InputLabel id="city-label" ref={cityLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.city")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="city"
                                                            id="city-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={cityLabelWidth}
                                                            value={tempContactLead.city}
                                                            onChange={handleLeadCity}
                                                            autoComplete="off"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={5} className={classes.textRight}>
                                                    <CountrySearch label={t("menu.profile.country")} traveler={tempContactLead} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={true} mode={"country"} redFormControl={false} size="medium" />
                                                </Grid>
                                            </Grid>
                                            <Grid container alignItems="center" justify="space-between" className={classes.spacer} style={{ paddingBottom: 16 }} spacing={4}>
                                                <Grid item xs={3}>
                                                    <MuiPhoneNumber
                                                        defaultCountry={tempContactLead.nationality !== null && tempContactLead.nationality !== "" ? tempContactLead.nationality.country_code.toLowerCase() : (user !== null && user.lang !== null && user.lang !== "en") ? user.lang.split('-')[0] : "gb" }
                                                        value={tempContactLead.temp_phone}
                                                        className={classes.formControlRoot1}
                                                        onChange={(value, country_data) => {handlePhoneNumber(value, country_data, tempContactLead)}}
                                                        variant="outlined"
                                                        label={t("global.phone")}
                                                        InputProps={{ className: classes.circleBorder }}
                                                        disableAreaCodes/>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <FormControl variant="outlined" className={classes.formControlRoot1}>
                                                        <InputLabel id="email-label-lead" ref={emailLabel} className={classes.fontSize16}>
                                                            {t("global.email")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="email"
                                                            id="email-input-lead"
                                                            className={classes.circleBorder}
                                                            labelWidth={emailLabelWidth}
                                                            value={tempContactLead.email}
                                                            required
                                                            onChange={((event) => { handleEmail(event, tempContactLead) })}
                                                            autoComplete="off"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {
                                    downDesktop && (
                                        <Grid>
                                            <Grid container alignItems="flex-start" justify="space-between" direction="column" className={classes.spacer} spacing={2} >
                                                <Grid item style={{ width: "100%" }}>
                                                    <FormControl variant="outlined" className={classes.formControlRootMobile1}>
                                                        <InputLabel id="address-label" ref={addressLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.address")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="address"
                                                            id="address-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={addressLabelWidth}
                                                            value={tempContactLead.address}
                                                            onChange={handleLeadAddress}
                                                            autoComplete="off"
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item style={{ width: "100%" }}>
                                                    <FormControl variant="outlined" className={classes.formControlRootMobile1}>
                                                        <InputLabel id="address_complementary-label" ref={addressDetailsLabel} className={classes.fontSize16}>
                                                            {t("menu.profile.address_complementary")}
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            name="address_complementary"
                                                            id="address_complementary-input"
                                                            className={classes.circleBorder}
                                                            labelWidth={addressDetailsLabelWidth}
                                                            value={tempContactLead.address_details}
                                                            onChange={handleLeadAddressDetails}
                                                            autoComplete="off"
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container alignItems="center" justify="space-between" className={classes.spacer} spacing={1}>
                                                    <Grid item xs={5}>
                                                        <FormControl variant="outlined" className={classes.formControlRootMobile1}>
                                                            <InputLabel id="postal-code-label" ref={postalCodeLabel} className={classes.fontSize12}>
                                                                {t("menu.profile.postal_code")}
                                                            </InputLabel>
                                                            <OutlinedInput
                                                                name="postal-code"
                                                                id="postal-code-input"
                                                                className={classes.circleBorder}
                                                                labelWidth={postalCodeLabelWidth}
                                                                value={tempContactLead.postal_code}
                                                                onChange={handleLeadPostalCode}
                                                                autoComplete="off"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <FormControl variant="outlined" className={classes.formControlRootMobile1}>
                                                            <InputLabel id="city-label" ref={cityLabel} className={classes.fontSize12}>
                                                                {t("menu.profile.city")}
                                                            </InputLabel>
                                                            <OutlinedInput
                                                                name="city"
                                                                id="city-input"
                                                                className={classes.circleBorder}
                                                                labelWidth={cityLabelWidth}
                                                                value={tempContactLead.city}
                                                                onChange={handleLeadCity}
                                                                autoComplete="off"
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.spacer}>
                                                <CountrySearch label={t("menu.profile.country")} traveler={tempContactLead} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={true} mode={"country"} redFormControl={false}/>
                                            </Grid>
                                            <Grid item className={classes.spacer}>
                                                <MuiPhoneNumber
                                                    defaultCountry={tempContactLead.nationality !== null && tempContactLead.nationality !== "" ? tempContactLead.nationality.country_code.toLowerCase() : (user.lang !== null && user.lang !== "en") ? user.lang.split('-')[0] : "gb" }
                                                    value={tempContactLead.temp_phone}
                                                    className={classes.formControlRootMobile1}
                                                    onChange={(value, country_data) => {handlePhoneNumber(value, country_data, tempContactLead)}}
                                                    variant="outlined"
                                                    label={t("global.phone")}
                                                    InputProps={{ className: classes.circleBorder }}
                                                    disableAreaCodes/>
                                            </Grid>
                                            <Grid item className={classes.spacer}>
                                                <FormControl variant="outlined" className={classes.formControlRootMobile1}>
                                                    <InputLabel id="email-label" ref={emailLabel} className={classes.fontSize16}>
                                                        {t("global.email")}
                                                    </InputLabel>
                                                    <OutlinedInput
                                                        name="email"
                                                        id="email-input"
                                                        className={classes.circleBorder}
                                                        labelWidth={emailLabelWidth}
                                                        value={tempContactLead.email}
                                                        onChange={((event) => { handleEmail(event, tempContactLead) })}
                                                        autoComplete="off"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {/* <Grid className={classes.textRight} style={{ paddingBottom: 8 }}>
                                {
                                    !leadMoreInfo && (
                                        <Button className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`} onClick={ () => {
                                            setLeadMoreInfo(true);
                                        } }>
                                            { t("global.short_more_info") }
                                        </Button>
                                    )
                                }
                                {
                                    leadMoreInfo && (
                                        <Button className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`} onClick={ () => {
                                            setLeadMoreInfo(false);
                                        } }>
                                            { t("global.short_less_info") }
                                        </Button>
                                    )
                                }
                                </Grid> */}
                            </Grid>
                        )
                    }
                </Grid>
                {
                    tempContactLead !== null && tempTravelers.map((traveler, traveler_index) =>{
                        let is_applicant = (withApplicant === true) && (traveler.id === tempContactLead.id);
                        let open_more_info = moreInfo[traveler_index];
                        return(
                            <Grid key={traveler_index} className={`${classes.genericBorder} ${classes.genericMargin}`}>
                                <Grid className={classes.genericPadding} container alignItems="center" justify="space-between">
                                    <Grid item className={`${!downDesktop ? "" : classes.smallPaddingTop} ${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize18 : classes.fontSize16} ${classes.upperCase}`} style={!downDesktop ? { padding: "12px 0px" } : {}}>
                                        { t("trip.traveler") + " " + t("global.number") + (traveler_index + 1) }
                                    </Grid>
                                    {
                                        withApplicant && (
                                            <Grid item style={!downDesktop ? { paddingRight: 32 } : {}}>
                                                <FormControlLabel
                                                    control={ <Checkbox checked={is_applicant} onChange={() => {
                                                        changeApplicant(traveler, traveler_index);
                                                    }}/>
                                                    }
                                                    label={ t("menu.trip_list.traveler_is_applicant") }
                                                    classes={{ label: `${classes.genericText} ${classes.fontWeight900} ${!downDesktop ? classes.fontSize12 : classes.fontSize10} ${classes.upperCase}` }}
                                                    className={`${classes.noMarginBottom} ${classes.genericText} ${classes.fontWeight900} ${!downDesktop ? classes.fontSize12 : classes.fontSize10}`}
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <div className={classes.titleBorder} style={{ marginBottom: 10 }} />
                                <Grid className={classes.genericPadding}>
                                    <Grid className={`${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize14 : classes.fontSize12} ${classes.letterSpacing} ${classes.upperCase}`}>
                                        {traveler.age_label}
                                    </Grid>
                                    <div className={`${classes.genericText} ${classes.fontWeight400} ${!downDesktop ? classes.fontSize12 : classes.fontSize10}`} style={{ marginBottom: 8 }}>
                                        { t("menu.trip_list.affected_room") + " " }
                                        <span className={`${classes.genericText} ${classes.fontWeight700} ${!downDesktop ? classes.fontSize14 : classes.fontSize12} ${classes.letterSpacing} ${classes.upperCase}`} style={!downDesktop ? { paddingLeft: 10 } : {}}>
                                                {traveler.room_label}
                                            </span>
                                    </div>
                                    <TravelerInfo tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} traveler={traveler} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={false} redFormControl={infoIncomplete[traveler_index]} nationality_option={false} size="medium" />
                                    {
                                        downDesktop && (
                                            <TravelerMoreInfoMobile traveler={traveler} traveler_index={traveler_index} moreInfo={moreInfo} setMoreInfo={setMoreInfo} open_more_info={open_more_info} infoIncomplete={infoIncomplete} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} size="medium" />
                                        )
                                    }
                                    {
                                        !downDesktop && (
                                            <TravelerMoreInfo traveler={traveler} traveler_index={traveler_index} moreInfo={moreInfo} setMoreInfo={setMoreInfo} open_more_info={open_more_info} infoIncomplete={infoIncomplete} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} size="medium" />
                                        )
                                    }
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Grid>
    )
}
export default InscriptionTravelers;