import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Flight from '@material-ui/icons/FlightOutlined';
import DirectionsWalk from '@material-ui/icons/DirectionsWalk';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import DirectionsBus from '@material-ui/icons/DirectionsBus';
import LocalTaxi from '@material-ui/icons/LocalTaxi';
import AirportShuttle from '@material-ui/icons/AirportShuttle';
import Train from '@material-ui/icons/Train';
import Subway from '@material-ui/icons/Subway';
import DirectionsBoat from '@material-ui/icons/DirectionsBoat';
import DirectionsBike from '@material-ui/icons/DirectionsBike';
import Block from '@material-ui/icons/Block';
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles({
    relative: {
        position: 'relative',
        borderTop: `1px solid ${grey[400]}`,
        width: 80,
        marginTop: 34
    },
    absolute: {
        position: 'absolute',
        backgroundColor: 'white',
        border: `2px solid ${grey[400]}`,
        color: grey[400],
        borderRadius: '50%',
        width: 40,
        height: 40,
        textAlign: 'center',
        top: -20,
        left: 20,
        paddingTop: 5
    }
});

const MaterialTimelineConnector = ({index}) => {
    const classes = useStyles();
    const itinerary = useSelector(state => state.itinerary.itinerary_list);
    return (
        <div className={ classes.relative }>
            {
                itinerary[index].r2r_json !== null && itinerary[index].r2r_json.vehicle !== undefined && (
                    <div className={ classes.absolute }>
                        {
                            itinerary[index].r2r_json.vehicle.kind === "plane" || itinerary[index].r2r_json.vehicle.kind === "helicopter" ? (
                                <Flight/>
                            ) : itinerary[index].r2r_json.vehicle.kind === "car" || itinerary[index].r2r_json.vehicle.kind === "towncar" ? (
                                <DirectionsCar/>
                            ) : itinerary[index].r2r_json.vehicle.kind === "bus" ? (
                                <DirectionsBus/>
                            ) : itinerary[index].r2r_json.vehicle.kind === "taxi" || itinerary[index].r2r_json.vehicle.kind === "rideshare" ? (
                                <LocalTaxi/>
                            ) : itinerary[index].r2r_json.vehicle.kind === "shuttle" ? (
                                <AirportShuttle/>
                            ) : itinerary[index].r2r_json.vehicle.kind === "train" || itinerary[index].r2r_json.vehicle.kind === "tram" ? (
                                <Train/>
                            ) : itinerary[index].r2r_json.vehicle.kind === "cablecar" || itinerary[index].r2r_json.vehicle.kind === "foot" || itinerary[index].r2r_json.vehicle.kind === "animal" ? (
                                <DirectionsWalk/>
                            ) : itinerary[index].r2r_json.vehicle.kind === "subway" ? (
                                <Subway/>
                            ) : itinerary[index].r2r_json.vehicle.kind === "ferry" ? (
                                <DirectionsBoat/>
                            ) : itinerary[index].r2r_json.vehicle.kind === "bicycle" ? (
                                <DirectionsBike/>
                            ) : (
                                <Block/>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
};

export default React.memo(MaterialTimelineConnector);