import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Stack,
    Typography
} from "@mui/material";
import {
    ArrowRightAltOutlined,
    CheckBox,
    CheckBoxOutlineBlank,
    ReportProblemOutlined
} from "@mui/icons-material";
import { makeStyles } from "@material-ui/core";
import { green } from "@mui/material/colors";
import CartPriceVariationDialog from "./CartPriceVariationDialog";
import { useCartTotalPrices } from "./utils/cartTotalPrices";
import GetPricesId from "../Cart/Functions/Margin/GetPricesId";
import { Currency } from "../../Reducers/objects/currency";
import { ProductPrice } from "../Itinerary/objects/productPrice";
import { AppState } from "../../Reducers/Reducers";

export function CartConstructionProductsHeaderMargin(): JSX.Element {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const all_data = useSelector((state: AppState) => state.trip.all_data);
    const currency = useSelector((state: AppState) => state.trip.currency);
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const accommodations = useSelector((state: AppState) => state.accommodation.sort_cart);
    const hasStackedItems = useSelector((state: AppState) => state.cart.hasStackedItems);
    const cars = useSelector((state: AppState) => state.cars_search.cart);
    const manual_products = useSelector((state: AppState) => state.cart.manual_item_list);
    const pois = useSelector((state: AppState) => state.poi.cart);
    const assistances = useSelector((state: AppState) => state.cart.assistance_cart);
    const transfers = useSelector((state: AppState) => state.transfers.cart);
    const flights = useSelector((state: AppState) => state.flight.cart);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const user = useSelector((state: AppState) => state.user.user);
    const show_cancelled_products = useSelector((state: AppState) => state.cart.show_cancelled_products);
    const [openPriceVariation, setOpenPriceVariation] = useState(false);
    const [openPriceDiffDetails, setOpenPriceDiffDetails] = useState(false);
    const prices = useCartTotalPrices();
    const diffPrice = useMemo(() => {
        if (all_data && all_data.agreed_price) {
            let diff = parseFloat(prices.totalCartCost.toFixed(2)) - parseFloat(all_data.agreed_price);
            return diff;
        }
        return 0;
    }, [
        prices.totalCartCost,
        all_data
    ]);
    const modifiedProduct = useMemo(() => {
        let modified_product: {
            label: string | null | undefined,
            product_type?: string,
            price: {
                cost: ProductPrice,
                adjustment: ProductPrice | undefined,
                currency: Currency | undefined
            }
        }[] = [];
        let adjustment_product = manual_products?.find(product => product.product_type === 20);
        const dates = [
            adjustment_product?.modified_date,
            trip?.status_contract_confirmed_date
        ].filter((item): item is NonNullable<typeof item> => {
            return !!item;
        }).sort((a, b) => {
            return window.moment.utc(a).isBefore(window.moment.utc(b)) ?
                -1 :
                1;
        }).reverse();
        let status_contract_confirmed_date = dates[0];
        /*let custom_cost = false;
        if ((trip?.prices_flight?.length ?? 0) > 0) {
            custom_cost = !hasStackedItems;
        }
        if ((trip?.prices_terrestrial?.length ?? 0) > 0) {
            custom_cost = !hasStackedItems;
        } */
        accommodations?.map(accommodation => {
            accommodation.rooms.map(room => {
                if (
                    (
                        room.is_available ||
                        (room.booking_status && room.booking_status.status_booking === 'CONFIRMED')
                    ) &&
                    room.is_displayed &&
                    !room.is_hidden_for_traveler &&
                    !room.is_optional &&
                    (
                        room.booking_status === null ||
                        (room.booking_process_state !== 'CANCEL' && room.booking_status.status_booking !== 'CANCELLED')
                    )
                ) {
                    let tva_price = room.prices.find((price) => price.is_tva);
                    if (tva_price) {
                        if ((window.moment.utc(tva_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: room.hotel[0]?.name + " - " + room.description,
                                price: {
                                    cost: tva_price,
                                    adjustment: room.prices.find((price) => price.master_price),
                                    currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                                }
                            });
                        }
                    } else {
                        let tmp_price = room.prices.find((price) => price.master_price);
                        if (tmp_price) {
                            if ((window.moment.utc(tmp_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                                modified_product.push({
                                    label: room.hotel[0]?.name + " - " + room.description,
                                    price: {
                                        cost: tmp_price,
                                        adjustment: tmp_price,
                                        currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                    }
                                });
                            }
                        }
                    }
                }
            });
        });
        cars?.map(car => {
            if (
                (car.is_available || (car.booking_status && car.booking_status.status_booking === 'CONFIRMED')) &&
                car.is_displayed &&
                !car.is_hidden_for_traveler &&
                !car.is_optional &&
                (car.booking_status === null || (car.booking_process_state !== 'CANCEL' && car.booking_status.status_booking !== 'CANCELLED'))
            ) {
                let tva_price = car.prices.find((price) => price.is_tva);
                if (tva_price) {
                    if ((window.moment.utc(tva_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                        modified_product.push({
                            label: car.name,
                            price: {
                                cost: tva_price,
                                adjustment: car.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = car.prices.find((price) => price.master_price);
                    if (tmp_price) {
                        if ((window.moment.utc(tmp_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: car.name,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                }
                            });
                        }
                    }
                }
            }
        });
        manual_products?.map(manual_product => {
            if (
                (
                    manual_product.is_available ||
                    (manual_product.booking_status && manual_product.booking_status.status_booking === 'CONFIRMED')
                ) &&
                (manual_product.is_displayed || quotation_code === 'cercledesvoyages') &&
                !manual_product.is_hidden_for_traveler &&
                !manual_product.is_optional &&
                (
                    manual_product.booking_status === null ||
                    (manual_product.booking_process_state !== 'CANCEL' && manual_product.booking_status.status_booking !== 'CANCELLED')
                )
            ) {
                let tva_price = manual_product.prices.find((price) => price.is_tva);
                if (tva_price) {
                    if (manual_product.product_type !== 18 && manual_product.product_type !== 20 && (window.moment.utc(tva_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date))) && manual_product.is_displayed) {
                        modified_product.push({
                            label: manual_product.name,
                            price: {
                                cost: tva_price,
                                adjustment: manual_product.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = manual_product.prices.find((price) => price.master_price);
                    if (tmp_price) {
                        if (manual_product.product_type !== 18 && manual_product.product_type !== 20 && (window.moment.utc(tmp_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: manual_product.name,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                }
                            });
                        }
                    }
                }
            }
        });
        pois?.map(poi => {
            if (
                (poi.is_available || (poi.booking_status && poi.booking_status.status_booking === 'CONFIRMED')) &&
                poi.is_displayed &&
                !poi.is_hidden_for_traveler &&
                !poi.is_optional &&
                (poi.booking_status === null || (poi.booking_process_state !== 'CANCEL' && poi.booking_status.status_booking !== 'CANCELLED'))
            ) {
                let tva_price = poi.prices.find((price) => price.is_tva);
                if (tva_price) {
                    if ((window.moment.utc(tva_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                        modified_product.push({
                            label: poi.custom_product?.title,
                            price: {
                                cost: tva_price,
                                adjustment: poi.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = poi.prices.find((price) => price.master_price);
                    if (tmp_price) {
                        if ((window.moment.utc(tmp_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: poi.custom_product?.title,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                }
                            });
                        }
                    }
                }
            }
        });
        assistances?.map(assistance => {
            if (
                (assistance.is_available || (assistance.booking_status && assistance.booking_status.status_booking === 'CONFIRMED')) &&
                assistance.is_displayed &&
                !assistance.is_hidden_for_traveler &&
                !assistance.is_optional &&
                (
                    assistance.booking_status === null ||
                    (assistance.booking_process_state !== 'CANCEL' && assistance.booking_status.status_booking !== 'CANCELLED')
                )
            ) {
                let tva_price = assistance.prices.find((price) => price.is_tva);
                if (tva_price) {
                    if ((window.moment.utc(tva_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                        modified_product.push({
                            label: t('cart-material.assistance'),
                            price: {
                                cost: tva_price,
                                adjustment: assistance.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = assistance.prices.find((price) => price.master_price);
                    if (tmp_price) {
                        if ((window.moment.utc(tmp_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: t('cart-material.assistance'),
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                }
                            });
                        }
                    }
                }
            }
        });
        transfers?.map(transfer => {
            if (
                (transfer.is_available || (transfer.booking_status && transfer.booking_status.status_booking === 'CONFIRMED')) &&
                transfer.is_displayed &&
                !transfer.is_hidden_for_traveler &&
                !transfer.is_optional &&
                (
                    transfer.booking_status === null ||
                    (transfer.booking_process_state !== 'CANCEL' && transfer.booking_status.status_booking !== 'CANCELLED')
                )
            ) {
                let tva_price = transfer.prices.find((price) => price.is_tva);
                if (tva_price) {
                    if ((window.moment.utc(tva_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                        modified_product.push({
                            label: transfer.is_custom ? transfer.custom_product?.title ?? '' : transfer.name,
                            price: {
                                cost: tva_price,
                                adjustment: transfer.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = transfer.prices.find((price) => price.master_price);
                    if (tmp_price) {
                        if ((window.moment.utc(tmp_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: transfer.is_custom ? transfer.custom_product?.title ?? '' : transfer.name,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                }
                            });
                        }
                    }
                }
            }
        });
        flights?.map(flight => {
            if (
                (flight.is_available || (flight.booking_status && flight.booking_status.status_booking === 'CONFIRMED')) &&
                flight.is_displayed &&
                !flight.is_hidden_for_traveler &&
                !flight.is_optional &&
                (
                    flight.booking_status === null ||
                    (flight.booking_process_state !== 'CANCEL' && flight.booking_status.status_booking !== 'CANCELLED')
                )
            ) {
                let option_cost = 0;
                let option_on_flight: any[] = [];
                flight.outbounds.map((outbound: any) => {
                    let option_on_group: any[] = [];
                    outbound.legs.map((leg: any) => {
                        if (leg.paid_options && leg.paid_options.length > 0) {
                            leg.paid_options.map((option: any) => {
                                if (option.option_on_flight) {
                                    if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        option_cost += parseFloat(option.price);
                                        option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                } else if (option.option_on_group) {
                                    if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        option_cost += parseFloat(option.price);
                                        option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                } else {
                                    option_cost += parseFloat(option.price);
                                }
                            });
                        }
                    });
                });
                let master_price = flight.prices[GetPricesId(flight.prices, user?.client, user, true)];
                let tva_price = flight.prices.find((price: any) => price.is_tva);
                if (tva_price) {
                    if ((window.moment.utc(tva_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                        modified_product.push({
                            label: t("global.flight") + " " + (flight.outbounds.length === 1 ? t('global.one_way') : t('global.multi_destination')),
                            price: {
                                cost: {
                                    ...tva_price,
                                    old_selling_price: parseFloat(tva_price.old_selling_price ?? '0') + (parseFloat(flight.taxes ?? '0') * (master_price ? parseFloat(master_price.factor_used) : 1)) + option_cost,
                                    selling_price: parseFloat(tva_price.selling_price ?? '0') + (parseFloat(flight.taxes ?? '0') * (master_price ? parseFloat(master_price.factor_used) : 1)) + option_cost
                                },
                                adjustment: flight.prices.find((price: any) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = flight.prices.find((price: any) => price.master_price);
                    if (tmp_price) {
                        if ((window.moment.utc(tmp_price.price_variation_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: t("global.flight") + " " + (flight.outbounds.length === 1 ? t('global.one_way') : t('global.multi_destination')),
                                price: {
                                    cost: {
                                        ...tmp_price,
                                        old_selling_price: parseFloat(tmp_price.old_selling_price ?? '0') + (parseFloat(flight.taxes ?? '0') * (master_price ? parseFloat(master_price.factor_used) : 1)) + option_cost,
                                        selling_price: parseFloat(tmp_price.selling_price ?? '0') + (parseFloat(flight.taxes ?? '0') * (master_price ? parseFloat(master_price.factor_used) : 1)) + option_cost
                                    },
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price.selling_currency)
                                }
                            });
                        }
                    }
                }
            }
        });
        return modified_product.filter((item) => {
            return item.price.cost.old_selling_price &&
                   item.price.cost.old_selling_price !== item.price.cost.selling_price;
        });
    }, [
        accommodations,
        cars,
        manual_products,
        pois,
        assistances,
        transfers,
        flights,
        hasStackedItems,
        user
    ]);
    const newProduct = useMemo(() => {
        let new_product: {
            label: string | null | undefined,
            product_type?: string,
            price: {
                cost: ProductPrice,
                adjustment: ProductPrice | undefined,
                currency: Currency | undefined
            }
        }[] = [];
        let adjustment_product = manual_products?.find(product => product.product_type === 20);
        const dates = [
            adjustment_product?.modified_date,
            trip?.status_contract_confirmed_date
        ].filter((item): item is NonNullable<typeof item> => {
            return !!item;
        }).sort((a, b) => {
            return window.moment.utc(a).isBefore(window.moment.utc(b)) ?
                -1 :
                1;
        }).reverse();
        let status_contract_confirmed_date = dates[0];
        /*let custom_cost = false;
        if ((trip?.prices_flight?.length ?? 0) > 0) {
            custom_cost = !hasStackedItems;
        }
        if ((trip?.prices_terrestrial?.length ?? 0) > 0) {
            custom_cost = !hasStackedItems;
        } */
        accommodations?.map(accommodation => {
            accommodation.rooms.map(room => {
                if (
                    (
                        room.is_available ||
                        (room.booking_status && room.booking_status.status_booking === 'CONFIRMED')
                    ) &&
                    room.is_displayed &&
                    !room.is_hidden_for_traveler &&
                    !room.is_optional &&
                    (
                        room.booking_status === null ||
                        (room.booking_process_state !== 'CANCEL' && room.booking_status.status_booking !== 'CANCELLED')
                    )
                ) {
                    let tva_price = room.prices.find((price) => price.is_tva);
                    if (tva_price) {
                        if ((window.moment.utc(accommodation.created_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            new_product.push({
                                label: room.hotel[0]?.name + " - " + room.description,
                                price: {
                                    cost: tva_price,
                                    adjustment: room.prices.find((price) => price.master_price),
                                    currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                                }
                            });
                        }
                    } else {
                        let tmp_price = room.prices.find((price) => price.master_price);
                        if (tmp_price) {
                            if ((window.moment.utc(accommodation.created_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                                new_product.push({
                                    label: room.hotel[0]?.name + " - " + room.description,
                                    price: {
                                        cost: tmp_price,
                                        adjustment: tmp_price,
                                        currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                    }
                                });
                            }
                        }
                    }
                }
            });
        });
        cars?.map(car => {
            if (
                (car.is_available || (car.booking_status && car.booking_status.status_booking === 'CONFIRMED')) &&
                car.is_displayed &&
                !car.is_hidden_for_traveler &&
                !car.is_optional &&
                (car.booking_status === null || (car.booking_process_state !== 'CANCEL' && car.booking_status.status_booking !== 'CANCELLED'))
            ) {
                let tva_price = car.prices.find((price) => price.is_tva);
                if (tva_price) {
                    if ((window.moment.utc(car.created_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                        new_product.push({
                            label: car.name,
                            price: {
                                cost: tva_price,
                                adjustment: car.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = car.prices.find((price) => price.master_price);
                    if (tmp_price) {
                        if ((window.moment.utc(car.created_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            new_product.push({
                                label: car.name,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                }
                            });
                        }
                    }
                }
            }
        });
        manual_products?.map(manual_product => {
            if (
                (
                    manual_product.is_available ||
                    (manual_product.booking_status && manual_product.booking_status.status_booking === 'CONFIRMED')
                ) &&
                (manual_product.is_displayed || quotation_code === 'cercledesvoyages') &&
                !manual_product.is_hidden_for_traveler &&
                !manual_product.is_optional &&
                (
                    manual_product.booking_status === null ||
                    (manual_product.booking_process_state !== 'CANCEL' && manual_product.booking_status.status_booking !== 'CANCELLED')
                )
            ) {
                let tva_price = manual_product.prices.find((price) => price.is_tva);
                if (tva_price) {
                    if (manual_product.product_type !== 18 && manual_product.product_type !== 20 && (window.moment.utc(manual_product.created_date).isAfter(window.moment.utc(status_contract_confirmed_date))) && manual_product.is_displayed) {
                        new_product.push({
                            label: manual_product.name,
                            price: {
                                cost: tva_price,
                                adjustment: manual_product.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = manual_product.prices.find((price) => price.master_price);
                    if (tmp_price) {
                        if (manual_product.product_type !== 18 && manual_product.product_type !== 20 && (window.moment.utc(manual_product.created_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            new_product.push({
                                label: manual_product.name,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                }
                            });
                        }
                    }
                }
            }
        });
        pois?.map(poi => {
            if (
                (poi.is_available || (poi.booking_status && poi.booking_status.status_booking === 'CONFIRMED')) &&
                poi.is_displayed &&
                !poi.is_hidden_for_traveler &&
                !poi.is_optional &&
                (poi.booking_status === null || (poi.booking_process_state !== 'CANCEL' && poi.booking_status.status_booking !== 'CANCELLED'))
            ) {
                let tva_price = poi.prices.find((price) => price.is_tva);
                if (tva_price) {
                    if ((window.moment.utc(poi.created_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                        new_product.push({
                            label: poi.custom_product?.title,
                            price: {
                                cost: tva_price,
                                adjustment: poi.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = poi.prices.find((price) => price.master_price);
                    if (tmp_price) {
                        if ((window.moment.utc(poi.created_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            new_product.push({
                                label: poi.custom_product?.title,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                }
                            });
                        }
                    }
                }
            }
        });
        assistances?.map(assistance => {
            if (
                (assistance.is_available || (assistance.booking_status && assistance.booking_status.status_booking === 'CONFIRMED')) &&
                assistance.is_displayed &&
                !assistance.is_hidden_for_traveler &&
                !assistance.is_optional &&
                (
                    assistance.booking_status === null ||
                    (assistance.booking_process_state !== 'CANCEL' && assistance.booking_status.status_booking !== 'CANCELLED')
                )
            ) {
                let tva_price = assistance.prices.find((price) => price.is_tva);
                if (tva_price) {
                    if ((window.moment.utc(assistance.created_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                        new_product.push({
                            label: t('cart-material.assistance'),
                            price: {
                                cost: tva_price,
                                adjustment: assistance.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = assistance.prices.find((price) => price.master_price);
                    if (tmp_price) {
                        if ((window.moment.utc(assistance.created_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            new_product.push({
                                label: t('cart-material.assistance'),
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                }
                            });
                        }
                    }
                }
            }
        });
        transfers?.map(transfer => {
            if (
                (transfer.is_available || (transfer.booking_status && transfer.booking_status.status_booking === 'CONFIRMED')) &&
                transfer.is_displayed &&
                !transfer.is_hidden_for_traveler &&
                !transfer.is_optional &&
                (
                    transfer.booking_status === null ||
                    (transfer.booking_process_state !== 'CANCEL' && transfer.booking_status.status_booking !== 'CANCELLED')
                )
            ) {
                let tva_price = transfer.prices.find((price) => price.is_tva);
                if (tva_price) {
                    if ((window.moment.utc(transfer.created_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                        new_product.push({
                            label: transfer.is_custom ? transfer.custom_product?.title ?? '' : transfer.name,
                            price: {
                                cost: tva_price,
                                adjustment: transfer.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price?.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = transfer.prices.find((price) => price.master_price);
                    if (tmp_price) {
                        if ((window.moment.utc(transfer.created_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            new_product.push({
                                label: transfer.is_custom ? transfer.custom_product?.title ?? '' : transfer.name,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price?.selling_currency)
                                }
                            });
                        }
                    }
                }
            }
        });
        flights?.map(flight => {
            if (
                (flight.is_available || (flight.booking_status && flight.booking_status.status_booking === 'CONFIRMED')) &&
                flight.is_displayed &&
                !flight.is_hidden_for_traveler &&
                !flight.is_optional &&
                (
                    flight.booking_status === null ||
                    (flight.booking_process_state !== 'CANCEL' && flight.booking_status.status_booking !== 'CANCELLED')
                )
            ) {
                let option_cost = 0;
                let option_on_flight: any[] = [];
                flight.outbounds.map((outbound: any) => {
                    let option_on_group: any[] = [];
                    outbound.legs.map((leg: any) => {
                        if (leg.paid_options && leg.paid_options.length > 0) {
                            leg.paid_options.map((option: any) => {
                                if (option.option_on_flight) {
                                    if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        option_cost += parseFloat(option.price);
                                        option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                } else if (option.option_on_group) {
                                    if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        option_cost += parseFloat(option.price);
                                        option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                } else {
                                    option_cost += parseFloat(option.price);
                                }
                            });
                        }
                    });
                });
                let master_price = flight.prices[GetPricesId(flight.prices, user?.client, user, true)];
                let tva_price = flight.prices.find((price: any) => price.is_tva);
                if (tva_price) {
                    if ((window.moment.utc(flight.created_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                        new_product.push({
                            label: t("global.flight") + " " + (flight.outbounds.length === 1 ? t('global.one_way') : t('global.multi_destination')),
                            price: {
                                cost: {
                                    ...tva_price,
                                    old_selling_price: parseFloat(tva_price.old_selling_price ?? '0') + (parseFloat(flight.taxes ?? '0') * (master_price ? parseFloat(master_price.factor_used) : 1)) + option_cost,
                                    selling_price: parseFloat(tva_price.selling_price ?? '0') + (parseFloat(flight.taxes ?? '0') * (master_price ? parseFloat(master_price.factor_used) : 1)) + option_cost
                                },
                                adjustment: flight.prices.find((price: any) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = flight.prices.find((price: any) => price.master_price);
                    if (tmp_price) {
                        if ((window.moment.utc(flight.created_date).isAfter(window.moment.utc(status_contract_confirmed_date)))) {
                            new_product.push({
                                label: t("global.flight") + " " + (flight.outbounds.length === 1 ? t('global.one_way') : t('global.multi_destination')),
                                price: {
                                    cost: {
                                        ...tmp_price,
                                        old_selling_price: parseFloat(tmp_price.old_selling_price ?? '0') + (parseFloat(flight.taxes ?? '0') * (master_price ? parseFloat(master_price.factor_used) : 1)) + option_cost,
                                        selling_price: parseFloat(tmp_price.selling_price ?? '0') + (parseFloat(flight.taxes ?? '0') * (master_price ? parseFloat(master_price.factor_used) : 1)) + option_cost
                                    },
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price.selling_currency)
                                }
                            });
                        }
                    }
                }
            }
        });
        return new_product.filter((item) => {
            return !item.price.cost.old_selling_price;
        });
    }, [
        accommodations,
        cars,
        manual_products,
        pois,
        assistances,
        transfers,
        flights,
        hasStackedItems,
        user
    ]);
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    //eslint-disable-next-line no-nested-ternary
    const isDisabled = (
        quotation_code === "cercledesvoyages" ||
        quotation_code === "continentsinsolites"
    ) ?
        (
            trip &&
            trip.status_contract === "CONFIRMED" &&
            trip.status_modification === "FIXED_PV" ? true : false
        ) :
        false;
    
    const handlePriceVariation = () => {
        setOpenPriceVariation(!openPriceVariation);
    };

    const onShowCancelledProducts = () => {
        dispatch({ type: 'CART_TOGGLE_SHOW_CANCELLED_PRODUCTS' });
    };

    return (
        <>
            {
                isDisabled &&
                diffPrice !== 0 && (
                    <>
                        <Stack direction="row">
                            <Typography sx={{ color: 'red' }}>
                                <ReportProblemOutlined style={{ verticalAlign: 'text-bottom', marginRight: 6 }}/>
                                {t<string>("cart.purchase_price_modified")}{' '}
                                {diffPrice > 0 ? "+" : ""}
                                {
                                    new Intl.NumberFormat(
                                        language,
                                        {
                                            style: 'currency',
                                            currency: currency.iso_code,
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 2
                                        }
                                    ).format(diffPrice)
                                }
                            </Typography>
                            <Button
                                size="small"
                                onClick={() => setOpenPriceDiffDetails((state) => !state)}
                                sx={{
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    textDecoration: 'underline',
                                    marginLeft: 1.5
                                }}
                            >
                                {
                                    openPriceDiffDetails ?
                                        t<string>('cart-material.cart-construction-less-details') :
                                        t<string>('cart-material.cart-construction-more-details')
                                }
                            </Button>
                        </Stack>
                        {
                            openPriceDiffDetails &&
                            <>
                                <Button
                                    size={'small'}
                                    variant={ 'contained' }
                                    style={{ marginBottom: newProduct.length === 0 ? 10 : 0 }}
                                    onClick={handlePriceVariation}
                                >
                                    {t<string>("itineraryType.what_to_do")}
                                </Button>
                                {
                                    (newProduct.length !== 0 || modifiedProduct.length !== 0) &&
                                    <>
                                        {
                                            newProduct.length > 0 &&
                                            <Typography >{t<string>('cart-material.cart-construction-new-products')}</Typography>
                                        }
                                        {
                                            newProduct.map((product, index) => {
                                                return (
                                                    <Typography
                                                        key={index}
                                                        sx={{
                                                            '& strong:first-of-type': {
                                                                color: 'red'
                                                            },
                                                            '& strong:nth-of-type(2)': {
                                                                color: 'green'
                                                            },
                                                            '& strong:nth-of-type(3)': {
                                                                color: parseFloat(product.price.cost.selling_price) -
                                                                       parseFloat(product.price.cost.old_selling_price ?? '0') < 0 ?
                                                                    'green' :
                                                                    'red'
                                                            }
                                                        }}
                                                    >
                                                    - {product.product_type} {product.label} :{' '}
                                                        <Stack
                                                            direction="row"
                                                            display="inline-flex"
                                                            alignItems="center"
                                                            spacing={1}
                                                        >
                                                            <strong>
                                                                {
                                                                    new Intl.NumberFormat(
                                                                        language,
                                                                        {
                                                                            style: 'currency',
                                                                            currency: product.price.currency?.iso_code,
                                                                            minimumFractionDigits: 0,
                                                                            maximumFractionDigits: 2
                                                                        }
                                                                    ).format(parseFloat(product.price.cost.old_selling_price ?? '0'))
                                                                }
                                                            </strong>
                                                            <ArrowRightAltOutlined />
                                                            <strong>
                                                                {
                                                                    new Intl.NumberFormat(
                                                                        language,
                                                                        {
                                                                            style: 'currency',
                                                                            currency: product.price.currency?.iso_code,
                                                                            minimumFractionDigits: 0,
                                                                            maximumFractionDigits: 2
                                                                        }
                                                                    ).format(parseFloat(product.price.cost.selling_price))
                                                                }
                                                            </strong>
                                                            <strong>
                                                                (
                                                                {
                                                                    new Intl.NumberFormat(
                                                                        language,
                                                                        {
                                                                            style: 'currency',
                                                                            signDisplay: 'exceptZero',
                                                                            currency: product.price.currency?.iso_code,
                                                                            minimumFractionDigits: 0,
                                                                            maximumFractionDigits: 2
                                                                        }
                                                                    ).format(
                                                                        parseFloat(product.price.cost.selling_price) -
                                                                        parseFloat(product.price.cost.old_selling_price ?? '0')
                                                                    )
                                                                }
                                                                )
                                                            </strong>
                                                        </Stack>
                                                    </Typography>
                                                );
                                            })
                                        }
                                        {
                                            modifiedProduct.length > 0 &&
                                            <Typography >{t<string>("global.modification")} : </Typography>
                                        }
                                        {
                                            modifiedProduct.map((product, index) => {
                                                return (
                                                    <Typography
                                                        key={index}
                                                        sx={{
                                                            '& strong:first-of-type': {
                                                                color: 'red'
                                                            },
                                                            '& strong:nth-of-type(2)': {
                                                                color: 'green'
                                                            },
                                                            '& strong:nth-of-type(3)': {
                                                                color: parseFloat(product.price.cost.selling_price) -
                                                                       parseFloat(product.price.cost.old_selling_price ?? '0') < 0 ?
                                                                    'green' :
                                                                    'red'
                                                            }
                                                        }}
                                                    >
                                                    - {product.product_type} {product.label} :{' '}
                                                        <Stack
                                                            direction="row"
                                                            display="inline-flex"
                                                            alignItems="center"
                                                            spacing={1}
                                                        >
                                                            <strong>
                                                                {
                                                                    new Intl.NumberFormat(
                                                                        language,
                                                                        {
                                                                            style: 'currency',
                                                                            currency: product.price.currency?.iso_code,
                                                                            minimumFractionDigits: 0,
                                                                            maximumFractionDigits: 2
                                                                        }
                                                                    ).format(parseFloat(product.price.cost.old_selling_price ?? '0'))
                                                                }
                                                            </strong>
                                                            <ArrowRightAltOutlined />
                                                            <strong>
                                                                {
                                                                    new Intl.NumberFormat(
                                                                        language,
                                                                        {
                                                                            style: 'currency',
                                                                            currency: product.price.currency?.iso_code,
                                                                            minimumFractionDigits: 0,
                                                                            maximumFractionDigits: 2
                                                                        }
                                                                    ).format(parseFloat(product.price.cost.selling_price))
                                                                }
                                                            </strong>
                                                            <strong>
                                                                (
                                                                {
                                                                    new Intl.NumberFormat(
                                                                        language,
                                                                        {
                                                                            style: 'currency',
                                                                            signDisplay: 'exceptZero',
                                                                            currency: product.price.currency?.iso_code,
                                                                            minimumFractionDigits: 0,
                                                                            maximumFractionDigits: 2
                                                                        }
                                                                    ).format(
                                                                        parseFloat(product.price.cost.selling_price) -
                                                                        parseFloat(product.price.cost.old_selling_price ?? '0')
                                                                    )
                                                                }
                                                                )
                                                            </strong>
                                                        </Stack>
                                                    </Typography>
                                                );
                                            })
                                        }
                                    </>
                                }
                            </>
                        }
                    </>
                )
            }
            {
                quotation_code === 'marcovasco' && 
                <FormControlLabel
                    className={ classes.checkBoxLabel }
                    control={
                        <Checkbox
                            icon={ <CheckBoxOutlineBlank /> }
                            checkedIcon={ <CheckBox className={ classes.checked }/> }
                            checked={ show_cancelled_products }
                            onChange={ onShowCancelledProducts }
                        />
                    }
                    label={ t<string>('cart-material.show-cancelled-products') }
                />
            } 
            <CartPriceVariationDialog
                openPriceVariation={openPriceVariation}
                handlePriceVariation={handlePriceVariation}
                diffPrice={diffPrice}
                newProduct={newProduct}
            />
        </>
    );
}

const useStyles = makeStyles({
    stickyPaper: {
        //position: 'sticky',
        //top: 94,
        borderRadius: 16,
        padding: 16
    },
    refreshButton: {
        padding: 8,
        marginTop: -9,
        marginRight: -9
    },
    marginToggle: {
        cursor: 'pointer'
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    blueButtonContainer: {
        marginBottom: 4,
        marginTop: -4
    },
    blueButton: {
        border: '1px solid #76B6C2',
        backgroundColor: 'white',
        color: '#76B6C2'
    },
    blueButtonFilled: {
        backgroundColor: '#76B6C2',
        color: 'white'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    cancelAll: {
        marginTop: 8,
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    },
    cancelAllCancel: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    },
    cancelAllWrapper: {
        position: 'relative'
    },
    cancelAllRunning: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -16
    },
    selector: {
        marginBottom: 4
    },
    checkBoxLabel: {
        marginBottom: 0
    },
    checked: {
        fill: 'black'
    },
    globalPriceWarningIcon: {
        verticalAlign: 'text-bottom',
        marginLeft: 6
    },
    signedPrice: {
        fontStyle: "italic",
        fontSize: 14,
        color: "gray",
        fontWeight: "normal"
    },
    orangeSwitchLabel: {
        color: '#E6592F',
        textTransform: 'uppercase',
        fontSize: 14
    },
    orangeBackground: {
        '& .mui-jss-MuiSwitch-track': {
            backgroundColor: '#E6592F',
            opacity: "13%"
        }
    }
});
