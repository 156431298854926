import { htmlHasEmptyContent } from "../../Menu/MaterialTripList/utils/htmlHasEmptyContent";
import { Hotel } from "../../Menu/MaterialTripList/objects/Hotel";

type Options = {
    locale: number | null,
    description: string | null,
    localization: {
        locale: number,
        name: string,
        description: string,
        cancel_text_condition: string
    }[],
    hoteldefaultTexts: {
        situation: string | null,
        room_desc: string | null,
        restoration: string | null,
        children: string | null
    } | null,
    hotelLocalization: {
        locale: number,
        situation: string,
        room_desc: string,
        restoration: string,
        children: string
    }[] | null,
    hotel: Hotel | null
}

export function extractHotelDescription(options: Options): string {
    const localization = options.localization.find((item) => {
        return item.locale === options.locale;
    });

    if (
        localization?.description &&
        !htmlHasEmptyContent(localization.description)
    ) {
        return localization.description;
    }

    if (options.description && !htmlHasEmptyContent(options.description)) {
        return options.description;
    }

    const hotelLocalization = options.hotelLocalization?.find((item) => {
        return item.locale === options.locale;
    });

    const hotelLocalizedDescription = [
        hotelLocalization?.situation,
        hotelLocalization?.room_desc,
        hotelLocalization?.restoration,
        hotelLocalization?.children
    ].filter((item) => {
        return !htmlHasEmptyContent(item ?? '');
    }).join('<p></p>') ?? '';

    if (!htmlHasEmptyContent(hotelLocalizedDescription)) {
        return hotelLocalizedDescription;
    }

    const hotelDefaultDescription = [
        options.hoteldefaultTexts?.situation,
        options.hoteldefaultTexts?.room_desc,
        options.hoteldefaultTexts?.restoration,
        options.hoteldefaultTexts?.children
    ].filter((item) => {
        return !htmlHasEmptyContent(item ?? '');
    }).join('<p></p>') ?? '';

    if (!htmlHasEmptyContent(hotelDefaultDescription)) {
        return hotelDefaultDescription;
    }

    return options.hotel?.content.map((item) => {
        return `
            <h6>${item.title}</h6>
            <p>${item.content}</p>
        `;
    }).join('<p></p>') ?? '';
}
