import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WithRouterProps } from "react-router";
import {
    CartConstructionReplaceFlightSegmentInputs,
    CartConstructionReplaceFlightSegmentInputsValues
} from "./CartConstructionReplaceFlightSegmentInputs";
import FlightSearch from "../FlightMaterial/FlightSearch/FlightSearch";
import FlightSelected from "../FlightMaterial/FlightSelected/FlightSelected";
import { CartConstructionReplaceProductContext } from "./utils/cartConstructionReplaceProductContext";
import { CartConstructionReplaceProductModalStep } from "./objects/cartConstructionReplaceProductModalStep";
import { Flight } from "../Itinerary/network/flight";
import { IataAirport } from "../Itinerary/objects/iataAirport";
import { AppState } from "../../Reducers/Reducers";


type Props = {
    flight?: Flight,
    segmentIndices?: number[]
} & WithRouterProps

export function CartConstructionReplaceFlightSegmentContent(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const flightProviders = useSelector((state: AppState) => state.flight.provider_list);
    const context = useContext(CartConstructionReplaceProductContext);
    const [inputs, setInputs] = useState<CartConstructionReplaceFlightSegmentInputsValues>({
        segments: [],
        airlines: [],
        providers: []
    });
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { flight, segmentIndices, ...rest } = props;

    useEffect(() => {
        const segments = props.segmentIndices?.filter((index) => {
            const lastIndex = (props.flight!.outbounds[index]?.legs.length ?? 0) - 1;
            return props.flight!.outbounds[index]?.legs[0]?.origin &&
               props.flight!.outbounds[index]?.legs[lastIndex]?.destination &&
               props.flight!.outbounds[index]?.legs[0]?.departure_time;
        }) ?? [];
        setInputs({
            segments: props.flight && props.segmentIndices ?
                segments.map((index) => {
                    const lastIndex = (props.flight!.outbounds[index]?.legs.length ?? 0) - 1;

                    const departureAirport = props.flight!.outbounds[index]!.legs[0]!.origin ??
                                             props.flight!.outbounds[index]!.legs[0]!.origin_station;
                    const departureAirportName = departureAirport?.name ?
                        departureAirport.name :
                        departureAirport?.international_name;
                    const departureAirportNameCity = departureAirport?.iata_city?.name ?
                        departureAirport?.iata_city.name :
                        departureAirport?.iata_city?.international_name;

                    const arrivalAirport = props.flight!.outbounds[index]!.legs[lastIndex]!.destination ??
                                           props.flight!.outbounds[index]!.legs[lastIndex]!.destination_station;
                    const arrivalAirportName = arrivalAirport?.name ?
                        arrivalAirport.name :
                        arrivalAirport?.international_name;
                    const arrivalAirportNameCity = arrivalAirport?.iata_city?.name ?
                        arrivalAirport.iata_city.name :
                        arrivalAirport?.iata_city?.international_name;

                    return {
                        departureAirport: {
                            ...(departureAirport as IataAirport)!, //TODO: fix this
                            name: departureAirportName ?? '',
                            city: departureAirportNameCity ?? ''
                        },
                        arrivalAirport: {
                            ...(arrivalAirport as IataAirport)!,
                            name: arrivalAirportName ?? '',
                            city: arrivalAirportNameCity ?? ''
                        },
                        date: props.flight!.outbounds[index]!.legs[0]!.departure_time,
                        classType: 0
                    };
                }) : [],
            airlines: props.flight && props.segmentIndices ?
                props.segmentIndices.map((index) => {
                    const item = props.flight?.outbounds[index];
                    return item?.legs[0]?.operating_airline ??
                           item?.legs[0]?.marketing_airline;
                }).filter((item): item is NonNullable<typeof item> => !!item) ?? [] :
                [],
            providers: props.flight ?
                [
                    flightProviders?.find((item) => {
                        return item.id === props.flight?.provider;
                    })
                ].filter((item): item is NonNullable<typeof item> => !!item) :
                flightProviders ?? []
        });
    }, [flightProviders, props.flight, props.segmentIndices]);

    useEffect(() => {
        dispatch({
            type: 'FLIGHT_ADD_FLIGHT_JOURNEY_FROM_ITINERARY',
            payload: {
                index: 0,
                journey: inputs.segments.map((segment) => {
                    return {
                        origin: segment.departureAirport,
                        destination: segment.arrivalAirport,
                        start_date: segment.date,
                        class_type: segment.classType
                    };
                })
            }
        });
    }, [inputs.segments]);

    switch (context.step) {
        case CartConstructionReplaceProductModalStep.INPUTS: {
            context.onRegisterNextEvent(() => {
                context.onHideNextButton();
                dispatch({
                    type: 'FLIGHT_SET_JOURNEY_TYPE',
                    payload: {
                        type: 2,
                        index: 0
                    }
                });
                dispatch({
                    type: "FLIGHT_SET_STATE"
                });
                dispatch({
                    type: "FLIGHT_FETCHED_STATUS",
                    payload: {
                        status: null
                    }
                });
                dispatch({
                    type: "FLIGHT_MATRIX_STATUS",
                    payload: {
                        status: null
                    }
                });
                dispatch({
                    type: "FLIGHT_RESET_PROVIDER_COUNT",
                    payload: null
                });
                dispatch({
                    type: "FLIGHT_INIT_STOP_OVER_ACTIVE",
                    payload: {
                        nb_journey: inputs.segments.length
                    }
                });
                dispatch({
                    type: "FLIGHT_SAVE_FLIGHT_GROUPS",
                    payload: {}
                });
                dispatch({
                    type: 'FLIGHT_SET_AIRPORT_LIST',
                    payload: {
                        airport_list: []
                    }
                });
                if (flight) {
                    dispatch({
                        type: 'FLIGHT_PUT_TRAVELERS_LIST',
                        payload: {
                            index: 0,
                            travelers: flight.group_passenger?.travelers_list ?? []
                        }
                    });
                }
                dispatch({
                    type: 'FLIGHT_SELECT_AIRLINES',
                    payload: {
                        index: 0,
                        airlines: inputs.airlines
                    }
                });
                dispatch({
                    type: 'FLIGHT_SELECT_PROVIDER',
                    payload: {
                        index: 0,
                        provider: inputs.providers.map((provider) => provider.id)
                    }
                });
                dispatch({
                    type: 'FLIGHT_SET_GROUP_INDEX',
                    payload: {
                        index_group: 0
                    }
                });
                dispatch({
                    type: 'FILL_MULTI_DEST',
                    payload: null
                });
                context.onNextStep();
            });
            return (
                <CartConstructionReplaceFlightSegmentInputs
                    value={inputs}
                    onChange={setInputs}
                    disableProviderPicker={
                        !!props.flight ||
                        !["localhost:3000", "demo.facilitatrip.com"].includes(window.location.host)
                    }
                />
            );
        }
        case CartConstructionReplaceProductModalStep.PROVIDERS: return (
            <FlightSearch {...rest} />
        );
        case CartConstructionReplaceProductModalStep.ROOMS: return (
            <FlightSelected {...rest} />
        );
    }
}
