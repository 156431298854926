import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import axios from "axios";

export default (client, dispatch) => {
   let { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
        axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${client}/user/?limit=1000&is_system_generated=false`,
        }).then(function (response) {
            let data = response.data.results.sort((a, b) => {
                let fa = a.first_name.toLowerCase();
                let fb = b.first_name.toLowerCase();
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                if (fa === fb) {
                    let fc = a.last_name.toLowerCase();
                    let fd = b.last_name.toLowerCase();
                    if (fc < fd) {
                        return -1;
                    }
                    if (fc > fd) {
                        return 1;
                    }
                }
            });
            data.forEach(element => {
                element.label = `${element.first_name} ${element.last_name}`
            });
            dispatch({
                type: "MENU_SET_USER_LIST",
                payload: {
                    userList: data
                }
            });
        }).catch(function (error) {
            //TODO: show snackbar error
            console.log('error:', error);
        });
    }   
}
