import React from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    Typography
} from "@mui/material";
import { CartAccommodationCard } from "./CartAccommodationCard";
import { CartFlightCard } from "./CartFlightCard";
import { CartConstructionProductsTableItemProps } from "./CartConstructionProductsTableItem";
import { CartCarCard } from "./CartCarCard";
import { CartPoiCard } from "./CartPoiCard";
import { CartTransferCard } from "./CartTransferCard";
import { CartAssistanceCard } from "./CartAssistanceCard";
import { CartManualProductCard } from "./CartManualProductCard";

type Props = {
    open: boolean,
    onClose: () => void
} & CartConstructionProductsTableItemProps

export function CartConstructionProductEditModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <Dialog
            open={props.open}
            maxWidth="lg"
            scroll="body"
            onClose={props.onClose}
            fullWidth
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" fontWeight="bold" component="div">
                        {t<string>('cart-material.cart-construction-edit-product')}
                    </Typography>
                    <Button onClick={props.onClose}>
                        {t<string>('cart-material.cart-construction-close')}
                    </Button>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ marginTop: 1, marginLeft: 2 }}>
                    {
                        props.type === 'flight' &&
                        <CartFlightCard
                            type="normal"
                            flight={props.item}
                            alwaysOpen
                        />
                    }
                    {
                        props.type === 'manual-flight' &&
                        <CartFlightCard
                            type="manual"
                            flight={props.item}
                            alwaysOpen
                        />
                    }
                    {
                        props.type === 'car' &&
                        <CartCarCard
                            car={props.item}
                            index={props.index}
                            alwaysOpen
                        />
                    }
                    {
                        props.type === 'accommodation' &&
                        <CartAccommodationCard
                            type="accommodation"
                            accommodation={props.item}
                            alwaysOpen
                        />
                    }
                    {
                        props.type === 'manual-accommodation' &&
                        <CartAccommodationCard
                            type="manual"
                            accommodation={props.item}
                            alwaysOpen
                        />
                    }
                    {
                        props.type === 'poi' &&
                        <CartPoiCard
                            poi={props.item}
                            alwaysOpen
                        />
                    }
                    {
                        props.type === 'transfer' &&
                        <CartTransferCard
                            transfer={props.item}
                            alwaysOpen
                        />
                    }
                    {
                        props.type === 'assistance' &&
                        <CartAssistanceCard
                            assistance={props.item}
                            alwaysOpen
                        />
                    }
                    {
                        props.type === 'manual' &&
                        <CartManualProductCard
                            product={props.item}
                            alwaysOpen
                        />
                    }
                </Box>
            </DialogContent>
        </Dialog>
    );
}
