import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Divider,
    Stack,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography
} from "@mui/material";
import { CalendarToday, Place } from "@mui/icons-material";
import GetPricesId from "../Cart/Functions/Margin/GetPricesId";
import GetProvider from "../Common/Functions/GetProvider";
import { CarCart } from "./objects/carCart";
import { ManualProductCarCart } from "./objects/manualProductCarCart";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    cars: ({
        type: 'normal',
        car: CarCart
    } | {
        type: 'manual',
        car: ManualProductCarCart
    })[]
} & Pick<TooltipProps, 'children'>

export function ItineraryStepItemCarTooltip(props: Props): JSX.Element {
    const { t } = useTranslation();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const currencies = useSelector((state: AppState) => state.base.currency_list);
    const currency = useSelector((state: AppState) => state.trip.currency);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const user = useSelector((state: AppState) => state.user.user);

    return (
        <LightTooltip
            disableHoverListener={props.cars.length === 0}
            title={
                props.cars.map(({ type, car }) => {
                    if (type === 'manual') {
                        return (
                            <Box key={car.id} sx={{ padding: 2.5 }}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-end"
                                    sx={{ marginBottom: 1.5 }}
                                >
                                    <Box sx={{ width: '60%' }}>
                                        <Typography variant="h6" color="#888">
                                            {car.name}
                                            {
                                                car.is_optional &&
                                                <Box component="span" sx={{ color: '#E6592F' }}>
                                                    {t<string>('cart.optional_product')}
                                                </Box>
                                            }
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Divider sx={{ marginBottom: 1 }} />
                                {
                                    car.address &&
                                    <TextWithIcon variant="body2" gutterBottom>
                                        <Place />
                                        { car.address}
                                    </TextWithIcon>
                                }
                                <TextWithIcon variant="body2" gutterBottom>
                                    <>
                                        <CalendarToday />
                                        <span>
                                            { t<string>("cart.from") }{' '}
                                            {
                                                window.moment.utc(car.start_date).format("DD MMM")
                                            }, {
                                                window.moment.utc(car.start_date).format('hh:mm')
                                            }{' '}
                                            { t<string>("cart.to") }{' '}
                                            {
                                                window.moment.utc(car.end_date).format("DD MMM")
                                            }, {
                                                window.moment.utc(car.end_date).format('hh:mm')
                                            }
                                        </span>
                                    </>
                                </TextWithIcon>
                                <Text sx={{ marginTop: 1.5 }}>
                                    {car.description}
                                </Text>
                                <Text sx={{ marginTop: 1.5 }}>
                                    {car.custom_information}
                                </Text>
                            </Box>
                        );
                    }

                    const onSiteTotal = car.onsite_fare.reduce((prev, current) => {
                        return prev + current.price;
                    }, 0) ?? 0;
                    const onSiteCurrency = currencies.find((item) => {
                        return item.id === car.onsite_fare[0]?.currency;
                    });
                    let price = new Intl.NumberFormat(
                        language,
                        {
                            style: 'currency',
                            currency: currency.iso_code
                        }
                    ).format(
                        Math.ceil(
                            parseFloat(
                                car.prices[
                                    GetPricesId(
                                        car.prices ?? [],
                                        user?.client ?? null,
                                        user ?? null,
                                        null
                                    )
                                ]?.selling_price ?? '0'
                            )
                        )
                    );
                    let provider = GetProvider(providers, car.provider ?? 0);
                    const daysCount = Math.ceil(
                        window.moment.utc(
                            car.end_date
                        ).diff(
                            window.moment.utc(car.start_date),
                            'days',
                            true
                        )
                    );
                    const hasProvider = provider && (user?.client_full?.type !== 2 || JSON.parse(localStorage.getItem("config") ?? '{}').quotation_code === 'verdie');
                    return (
                        <Box key={car.id} sx={{ padding: 2.5 }}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-end"
                                sx={{ marginBottom: 1.5 }}
                            >
                                <Box sx={{ width: hasProvider ? '60%' : '100%' }}>
                                    <Typography variant="h6" color="#888">
                                        {car.name}
                                        {
                                            car.is_optional &&
                                            <Box component="span" sx={{ color: '#E6592F' }}>
                                                {t<string>('cart.optional_product')}
                                            </Box>
                                        }
                                    </Typography>
                                </Box>
                                {
                                    hasProvider &&
                                    <Box sx={{ width: '15%' }}>
                                        <img
                                            src={
                                                provider?.provider.logo?.thumbnail_little ?? provider?.provider.logo?.url ?? ''
                                            }
                                            alt={provider?.provider.name}
                                            width="100%"
                                            height="auto"
                                        />
                                    </Box>
                                }
                            </Stack>
                            <Divider sx={{ marginBottom: 1 }} />
                            <Text sx={{ marginBottom: 2.5 }}>
                                { t<string>("cars.or_similiar") + " | " + t<string>("global.category") + car.vehicle_category + " (" + car.vehicle_code + ")" }
                            </Text>
                            <TextWithIcon variant="body2" gutterBottom>
                                <Place />
                                { car.address_pickup + " (" + car.station_name_pickup + ")" }
                            </TextWithIcon>
                            {
                                car.address_return !== car.address_pickup &&
                                <TextWithIcon variant="body2" gutterBottom>
                                    <Place />
                                    { car.address_return + " (" + car.station_name_return + ")" }
                                </TextWithIcon>
                            }
                            <TextWithIcon variant="body2" gutterBottom>
                                <>
                                    <CalendarToday />
                                    <span>
                                        { t<string>("cart.from") }{' '}
                                        {
                                            window.moment.utc(car.start_date).format("DD MMM")
                                        }, {
                                            window.moment.utc(car.start_date).format('hh:mm')
                                        }{' '}
                                        { t<string>("cart.to") }{' '}
                                        {
                                            window.moment.utc(car.end_date).format("DD MMM")
                                        }, {
                                            window.moment.utc(car.end_date).format('hh:mm')
                                        }.{' '}
                                        {t<string>("accommodation.room_price_total_1")}{' '}
                                        <strong>
                                            {
                                                daysCount > 1 ?
                                                    `${daysCount} ${t('global.days')}` :
                                                    `${daysCount} ${t('global.day')}`
                                        
                                            }
                                        </strong>
                                    </span>
                                </>
                            </TextWithIcon>
                            <DetailsContainer variant="body2">
                                <span><i className={ "icon-car-gear" }/>{ t<string>("cars.transmission." + (car.transmission_type)?.toLowerCase()) }</span>
                                <span><i className={ "icon-car-door" }/>{ car.door_count }</span>
                                <span><i className={ "icon-user-silhouette" }/>{ car.max_passengers }</span>
                                <span><i className={ "icon-luggage" }/>{ car.baggage }</span>
                                {
                                    car.air_conditioner &&
                                    <span><i className={ "icon-aircon" }/></span>
                                }
                            </DetailsContainer>
                            {
                                (car.special_equipments.length ?? 0) > 0 && (
                                    <>
                                        <p>{ t<string>("cars.optional_equipments_card") }</p>
                                        {
                                            car.special_equipments.map((option, option_index) => {
                                                let option_price = new Intl.NumberFormat(language, { style: 'currency', currency: option.currency }).format(Math.ceil(parseFloat(option.price) * option.quantity));
                                                return (
                                                    <p key={ option_index }>{ option.quantity + " X " + option.name + " : " + option_price }</p>
                                                );
                                            })
                                        }
                                    </>
                                )
                            }
                            {
                                car.contract_name &&
                                <p>{ car.contract_name }</p>
                            }
                            {
                                onSiteTotal > 0 && (
                                    <p>
                                        <Box sx={{ marginRight: 1 }} component="span">
                                            <i className={ "icon-warning" }/>
                                        </Box>
                                        { t<string>("cars.onsite_total_cart") + new Intl.NumberFormat(language, { style: 'currency', currency: onSiteCurrency?.iso_code ?? 'EUR' }).format(onSiteTotal) }
                                    </p>
                                )
                            }
                            {
                                provider?.provider.name === "Avis" && (car.location_code_pickup !== car.location_code_return) &&
                                <p>{t<string>("cars.one_way_fare")}</p>
                            }
                            {
                                car.vehicle_category_message &&
                                <p>{ car.vehicle_category_message }</p>
                            }
                            <p>{ price }</p>
                        </Box>
                    );
                })
            }
            arrow
            PopperProps={{
                disablePortal: true
            }}
        >
            {props.children}
        </LightTooltip>
    );
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        maxWidth: 420
    },
    [`& .${tooltipClasses.arrow}`]: {
        "color": theme.palette.common.white,
        '&:before': {
            border: '1px solid #ccc'
        }
    }
}));

const Text = styled(Typography)(() => ({
    fontSize: 10
})) as typeof Typography;

const TextWithIcon = styled(Typography)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: 10
})) as typeof Typography;

const DetailsContainer = styled(Typography)((props) => ({
    "marginTop": props.theme.spacing(1.5),
    "display": 'flex',
    "alignItems": 'center',
    "gap": 10,
    'span': {
        display: 'flex',
        gap: 4
    },
    'span i': {
        display: 'flex',
        alignItems: 'center'
    }
})) as typeof Typography;
