//Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Alert } from "@material-ui/lab";
//Core

const CartStackNotification = () => {
    const { t } = useTranslation();
    const data_trip = useSelector(state => state.trip.data_trip);
    if (data_trip.stack_product_error) {
        return (
            <Alert variant="filled" severity="error">
                {
                    data_trip.stack_product_error === 'DATE' ?
                        t('cart-material.stack-product-error-date') :
                        t(
                            'cart-material.stack-product-error-traveler',
                            { count: data_trip.stack_product_pax_limit }
                        )
                }
            </Alert>
        );
    }
    return null;
};

export default CartStackNotification;
