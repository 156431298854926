import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// Core
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AlertTitle from "@material-ui/lab/AlertTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
// Icons
import Close from "@material-ui/icons/Close";
// Components
import CartAssistanceVip from "./CartAssistanceVip";
import CartAssistancePrice from "./CartAssistancePrice";
import CartAssistanceBasic from "./CartAssistanceBasic";
import CartAssistanceLeadPax from "./CartAssistanceLeadPax";
import CartAssistanceValidate from "./CartAssistanceValidate";
import CartAssistanceEstimation from "./CartAssistanceEstimation";
import CartAssistancePax from "./CartAssistancePax";
import CartAssistancePrices from "./CartAssistancePrices";
import CartGritchenPrices from './CartGritchenPrices';
import CartAssistanceTravelerType from './CartAssistanceTravelerType';
import CartAssistancePlus from './CartAssistancePlus';
import CartAssistancePremium from './CartAssistancePremium';

const useStyles = makeStyles({
    relative: {
        position: 'relative !important'
    },
    assistanceAppBar: {
        position: 'relative !important',
        backgroundColor: '#3367D6 !important',
        color: 'white'
    },
    assistanceContainer: {
        marginTop: 32
    },
    assistanceTitle: {
        backgroundColor: 'rgba(0, 0, 0, .03) !important',
        borderBottom: '1px solid rgba(0, 0, 0, .125) !important'
    },
    buttonContainer: {
        marginBottom: 32
    },
    assistanceHelper: {
        whiteSpace: 'pre-line',
        textAlign: 'justify'
    }
});

const CartGritchenModal = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const assistance_modal = useSelector((state) => state.cart.assistance_modal);
    const assistance_type = useSelector((state) => state.cart.assistance_type);
    const assistance_prices = useSelector((state) => state.cart.assistance_prices);
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const onToggleAssistance = () => {
        dispatch({type: 'CART_TOGGLE_ASSISTANCE'});
    };
    return (
        <Dialog fullWidth open={ assistance_modal } onClose={ onToggleAssistance } maxWidth={ 'md' }>
            <div className={ classes.assistanceAppBar }>
                <Toolbar>
                    <IconButton edge={ 'start' } color={ 'inherit' } onClick={ onToggleAssistance }><Close/></IconButton>
                    <Typography variant={ 'h6' }>{ t('cart-material.assistance-estimate') }</Typography>
                </Toolbar>
            </div>
            {
                assistance_prices === null ? assistance_type === 1 ? (
                    <Container className={ classes.assistanceContainer } maxWidth={ 'md' }>
                        {/*<Accordion square expanded>*/}
                        {/*    <AccordionSummary className={ classes.assistanceTitle }>*/}
                        {/*        <Typography>{ t('cart-material.assistance-select-lead-pax') }</Typography>*/}
                        {/*    </AccordionSummary>*/}
                        {/*    <AccordionDetails className={ 'ft-user-subContainer' }>*/}
                        {/*        <CartAssistanceLeadPax/>*/}
                        {/*    </AccordionDetails>*/}
                        {/*</Accordion>*/}
                        <Accordion square expanded>
                            <AccordionSummary className={ classes.assistanceTitle }>
                                <Typography>{ t('cart-material.assistance-select-pax') }</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={ 'ft-user-subContainer' }>
                                <Grid container spacing={ 2 }>
                                    <Grid item xs={ 12 }>
                                        <CartAssistancePax/>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Grid className={ classes.buttonContainer } container spacing={ 2 } justify={ 'flex-end' }>
                            <Grid item>
                                <Button onClick={ onToggleAssistance }>{ t('global.cancel') }</Button>
                            </Grid>
                            <Grid item>
                                <CartAssistanceEstimation/>
                            </Grid>
                        </Grid>
                    </Container>
                ) : (
                    <Container className={ classes.assistanceContainer } maxWidth={ 'md' }>
                        {/*<Accordion square expanded>*/}
                        {/*    <AccordionSummary className={ classes.assistanceTitle }>*/}
                        {/*        <Typography>{ t('cart-material.assistance-select-lead-pax') }</Typography>*/}
                        {/*    </AccordionSummary>*/}
                        {/*    <AccordionDetails className={ 'ft-user-subContainer' }>*/}
                        {/*        <CartAssistanceLeadPax/>*/}
                        {/*    </AccordionDetails>*/}
                        {/*</Accordion>*/}
                        <Accordion square expanded>
                            <AccordionSummary className={ classes.assistanceTitle }>
                                <Typography>{ t('cart-material.assistance-select-options') }</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={ 'ft-user-subContainer' }>
                                <Grid container spacing={ 2 }>
                                    <Grid item xs={ 12 }>
                                        <Alert className={ 'ft-justify' } severity={ 'info' }>
                                            <AlertTitle>{ t('global.informations') } :</AlertTitle>
                                            <p className={ classes.assistanceHelper }>{ t('cart-material.assistance-select-options-helper') }</p>
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={ 12 }>
                                        <CartAssistanceTravelerType/>
                                    </Grid>
                                    <Grid item xs={ 12 }>
                                        <CartAssistancePlus/>
                                    </Grid>
                                    <Grid item xs={ 12 }>
                                        <CartAssistancePremium/>
                                    </Grid>
                                    <Grid item xs={ 12 }>
                                        <CartAssistanceVip/>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Grid className={ classes.buttonContainer } container spacing={ 2 } justify={ 'flex-end' }>
                            <Grid item>
                                <Button onClick={ onToggleAssistance }>{ t('global.cancel') }</Button>
                            </Grid>
                            <Grid item>
                                <CartAssistanceEstimation/>
                            </Grid>
                        </Grid>
                    </Container>
                ) : assistance_type === 1 ? (
                    <Container className={ classes.assistanceContainer } maxWidth={ 'md' }>
                        <Accordion square expanded>
                            <AccordionSummary className={ classes.assistanceTitle }>
                                <Typography>{ t('cart-material.assistance-estimation-result') }</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={ 'ft-user-subContainer' }>
                                <Grid container spacing={ 2 }>
                                    {
                                        assistance_prices.insurances && assistance_prices.insurances.map((price, price_index) => (
                                            <Grid item xs={ 12 }>
                                                <CartGritchenPrices price={ price } index={ price_index }/>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Grid className={ classes.buttonContainer } container spacing={ 2 } justify={ 'flex-end' }>
                            <Grid item>
                                <Button onClick={ onToggleAssistance }>{ t('global.cancel') }</Button>
                            </Grid>
                            <Grid item>
                                <CartAssistanceValidate/>
                            </Grid>
                        </Grid>
                    </Container>
                ) : (
                    <Container className={ classes.assistanceContainer } maxWidth={ 'md' }>
                        <Accordion square expanded>
                            <AccordionSummary className={ classes.assistanceTitle }>
                                <Typography>{ t('cart-material.assistance-estimation-result') }</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={ 'ft-user-subContainer' }>
                                <Grid container spacing={ 2 }>
                                    <Grid item xs={ 12 }>
                                        <CartAssistancePrice/>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Grid className={ classes.buttonContainer } container spacing={ 2 } justify={ 'flex-end' }>
                            <Grid item>
                                <Button onClick={ onToggleAssistance }>{ t('global.cancel') }</Button>
                            </Grid>
                            <Grid item>
                                <CartAssistanceValidate/>
                            </Grid>
                        </Grid>
                    </Container>
                )
            }
        </Dialog>
    );
};

export default CartGritchenModal;