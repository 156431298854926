import { NewReleasesOutlined } from '@material-ui/icons';
import {
    DELETE_CUSTOM_PICTURE,
    DELETE_ALL_CUSTOM_PICTURE,
    ADD_PROVIDER_PICTURES,
    ADD_CUSTOM_PICTURES_ORDER,
    TOGGLE_PICTURE_ACTIVATED,
    TOGGLE_PICTURE_SELECT_ALL,
    TOGGLE_PICTURE_UNSELECT_ALL,
    UPDATE_PICTURE_ORDER,
    CART_UPDATE_VARIANTE_LOCALE
} from '../ActionTypes/Cart';

export function AddManualItem(manual_item_list) {
    return {
        type: "CART_ADD_MANUAL_ITEM",
        payload: {
            manual_item_list: manual_item_list
        }
    }
}

export function SetInfoReloaded(infos_reloaded, type) {
    return {
        type: "CART_SET_INFO_RELOADED",
        payload: {
            infos_reloaded: infos_reloaded,
            type: type
        }
    }
}

export function ToggleCartCropper(cart_cropper) {
    return {
        type: "CART_TOGGLE_CART_CROPPER",
        payload: {
            cart_cropper: cart_cropper
        }
    }
}
export function ToggleCartGallery(gallery_pic) {
    return {
        type: "CART_TOGGLE_GALLERY",
        payload: {
            gallery_pic: gallery_pic
        }
    }
}

export function ChangeActivePageGallery(active_page_gallery) {
    return {
        type: "CART_CHANGE_ACTIVE_PAGE_GALLERY",
        payload: {
            active_page_gallery: active_page_gallery
        }
    };
}

export function SetManualCart(manual_cart) {
    return {
        type: "CART_SET_MANUAL_CART",
        payload: {
            manual_cart: manual_cart
        }
    }
}

export function EditManualCartMargin(edit, index) {
    return {
        type: "CART_SET_MANUAL_MARGIN_EDIT",
        payload: {
            edit: edit,
            index: index
        }
    }
}

export function EditManualCartById(item) {
    return {
        type: "CART_EDIT_MANUAL_CART_BY_ID",
        payload: {
            item: item,
        }
    }
}

export function EditManualCart(item, index) {
    return {
        type: "CART_EDIT_MANUAL_CART",
        payload: {
            item: item,
            index: index
        }
    }
}
export function ToggleEditManualCart(index) {
    return {
        type: "CART_TOOGLE_EDIT_MANUAL_CART",
        payload: {
            index: index
        }
    }
}

export function RemoveFromManualItemFromIndex(index) {
    return {
        type: "CART_REMOVE_FROM_MANUAL_ITEM",
        payload: {
            index: index
        }
    }
}
export function SetCartStep(step) {
    return {
        type: "CART_SET_STEP",
        payload: {
            step: step
        }
    }
}
export function SetErrorCart(err) {
    return {
        type: "CART_SET_ERROR",
        payload: {
            err: err
        }
    }
}
export function ToggleRefundCondition() {
    return {
        type: "CART_TOGGLE_REFUND_CONDITION"
    }
}

export function EditItemToBook(product) {
    return {
        type: "EDIT_ITEM_TO_BOOK",
        payload: {
            product: product
        }
    }
}

export function SetToBook(to_book) {
    return {
        type: "SET_TO_BOOK",
        payload: {
            to_book: to_book
        }
    }
}

export function deleteCustomPicture(index) {
    return {
        type: DELETE_CUSTOM_PICTURE,
        payload: index
    }
}

export function deleteAllCustomPicture() {
    return {
        type: DELETE_ALL_CUSTOM_PICTURE,
        payload: null
    }
}

export function addProviderPictures(pictures) {
    return {
        type: ADD_PROVIDER_PICTURES,
        payload: pictures
    }
}

export const togglePictureActivated = (index) => ({
    type: TOGGLE_PICTURE_ACTIVATED,
    payload: index
});

export const toggleSelectAll = () => ({
    type: TOGGLE_PICTURE_SELECT_ALL,
    payload: null
});

export const toggleUnSelectAll = () => ({
    type: TOGGLE_PICTURE_UNSELECT_ALL,
    payload: null
});

export const addCustomPicturesOrder = (pictures) => ({
    type: ADD_CUSTOM_PICTURES_ORDER,
    payload: pictures
});

export const updatePictureOrder = (drag_index, hover_index) => ({
    type: UPDATE_PICTURE_ORDER,
    payload: {
        drag_index: drag_index,
        hover_index: hover_index
    }
});

export const updateVariantLocale = (id, value) => ({
    type: CART_UPDATE_VARIANTE_LOCALE,
    payload: {
        id: id,
        value: value
    }
})
