import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Button,
    Collapse,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Menu,
    Stack,
    styled,
    Tooltip,
    Typography
} from "@mui/material";
import i18next from "i18next";
import { MailHistory } from "./objects/mail-history";
import {
    AutocompleteTranslations
} from "./MailVisualEditor/objects/autocompleteTranslations";
import { useAwsFileDownload } from "./Functions/useAwsFileDownload";
import { AppState } from "../../../Reducers/Reducers";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

type Props = {
    item: MailHistory,
    setLoading: (loading: boolean) => void
}

export function TripListCommunicationHistoryItem(props: Props): JSX.Element {
    const { t } = useTranslation();
    const autocomplete = useSelector((state: AppState) => state.mailTemplate.others.autocomplete);
    const [showContent, setShowContent] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const detailsButtonRef = useRef(null);
    const download = useAwsFileDownload({ setLoading: props.setLoading });

    const translations = autocomplete.state === 'success' ?
        autocomplete.data.translations :
        { action: {}, fields: {}, recipients: {} } as AutocompleteTranslations;
    
    const translateAction = (action: string) => {
        const value = translations.action[action] ?? {};
        return value[i18next.language] ?? action;
    };

    const translateRecipient = (recipient: string) => {
        const value = translations.recipients[recipient] ?? {};
        return value[i18next.language] ?? recipient;
    };

    const onToggleContent = () => {
        setShowContent((state) => !state);
    };

    const onToggleDetails = () => {
        setShowDetails((state) => !state);
    };

    const onDownload = (file: MailHistory['attached_docs'][0]) => {
        download(
            file,
            (blob: Blob) => {
                const url = URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.target = '__blank';
                anchor.href = url;
                anchor.download = file.file_name;
                anchor.click();
                URL.revokeObjectURL(url);
            }
        );
    };

    //unknown
    let statusColor = '#eb6060';

    //pending
    if (!props.item.is_error && !props.item.is_sent) {
        statusColor = '#f7cb73';
    //opened
    } else if (props.item.is_opened) {
        statusColor = '#478ad1';
    //error
    } else if (props.item.is_error) {
        statusColor = '#ed4337';
    //success
    } else if (props.item.is_sent) {
        statusColor = '#4BB543';
    }

    return (
        <Container
            direction="row"
            spacing={1.5}
            onClick={onToggleContent}
            sx={{
                borderColor: statusColor
            }}
        >
            <Tooltip title={t<string>('menu.email-history.outgoing-mail')}>
                <ForwardToInboxIcon />
            </Tooltip>
            <ContentContainer>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="caption" color="text.secondary" component="div">
                        {
                            t<string>(
                                'menu.email-history.mail-sent-to',
                                { from: props.item.sender.replace(/<.*?>/gm, '').trim() }
                            )
                        }{' '}
                        <RecipientText>{translateRecipient(props.item.recipient)}</RecipientText>
                    </Typography>
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            alignItems: 'center',
                            marginTop: '-5px'
                        }}
                    >
                        <AccessTimeIcon fontSize="small" />
                        <TimeText variant="caption" color="text.secondary" component="div">
                            {window.moment(props.item.created_date).format('DD/MM/YYYY HH:mm')}
                        </TimeText>
                        <Tooltip title={t<string>('menu.email-history.more-details')}>
                            <IconButton
                                ref={detailsButtonRef}
                                size="small"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onToggleDetails();
                                }}
                            >
                                <InfoIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <Menu
                        open={showDetails}
                        onClose={onToggleDetails}
                        onClick={(event) => event.stopPropagation()}
                        anchorEl={detailsButtonRef.current}
                    >
                        <DetailsMenu>
                            <div>
                                <Typography variant="subtitle2" component="span">
                                    {t<string>('menu.email-history.status')} :{' '}
                                </Typography>
                                <Typography variant="body2" component="span">
                                    {
                                        props.item.is_sent ?
                                            t<string>('menu.email-history.sent') :
                                            t<string>('menu.email-history.not-sent')
                                    }
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="subtitle2" component="span">
                                    {t<string>('menu.email-history.interaction')} :{' '}
                                </Typography>
                                <Typography variant="body2" component="span">
                                    {
                                        props.item.is_opened ?
                                            t<string>(
                                                'menu.email-history.opened',
                                                { count: props.item.opened_dates.length }
                                            ) :
                                            t<string>('menu.email-history.not-opened')
                                    }
                                </Typography>
                                {
                                    props.item.is_opened &&
                                    <List>
                                        {
                                            props.item.opened_dates.map((date, index) => (
                                                <OpenedDateItem key={index}>
                                                    <OpenedDateAvatar>
                                                        <AccountCircleIcon />
                                                    </OpenedDateAvatar>
                                                    <ListItemText>
                                                        <Typography variant="body2" component="div">
                                                            {
                                                                t<string>(
                                                                    'menu.email-history.opened-on',
                                                                    { date: window.moment(date).format('DD/MM/YYYY HH:mm') }
                                                                )
                                                            }
                                                        </Typography>
                                                    </ListItemText>
                                                </OpenedDateItem>
                                            ))
                                        }
                                    </List>
                                }
                            </div>
                            <div>
                                <Typography variant="subtitle2" component="span">
                                    {t<string>('menu.email-history.error')} :{' '}
                                </Typography>
                                <Typography variant="body2" component="span">
                                    {
                                        props.item.is_error ?
                                            t<string>(`menu.email-history.errors.${props.item.bounce_message ?? 'UNKNOWN_FAILURE'}`) :
                                            t<string>('menu.email-history.has-no-error')
                                    }
                                </Typography>
                            </div>
                        </DetailsMenu>
                    </Menu>
                </Stack>
                <div>
                    <Typography variant="subtitle2" component="span">
                        {t<string>('menu.email-history.event')} :{' '}
                    </Typography>
                    <Typography variant="body2" component="span">
                        "{translateAction(props.item.action)}"
                    </Typography>
                </div>
                <div>
                    <Typography variant="subtitle2" component="span">
                        {t<string>('menu.email-history.subject')} :{' '}
                    </Typography>
                    <Typography variant="body2" component="span">
                        {props.item.subject}
                    </Typography>
                </div>
                <ContentPreviewContainer>
                    {
                        !showContent &&
                            <EllipsedContent>
                                {
                                    new DOMParser().parseFromString(
                                        props.item.template,
                                        'text/html'
                                    ).body.textContent?.replace(/(\r\n|\n|\r)/gm, "")
                                }
                            </EllipsedContent>
                    }
                    <Collapse in={showContent}>
                        <div>
                            <Typography variant="subtitle2" component="span">
                                {t<string>('menu.email-history.from')} :{' '}
                            </Typography>
                            <Typography variant="body2" component="span">
                                {props.item.sender}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="subtitle2" component="span">
                                {t<string>('menu.email-history.to')} :{' '}
                            </Typography>
                            <Typography variant="body2" component="span">
                                {props.item.recipient}
                            </Typography>
                        </div>
                        {
                            props.item.ccs.length > 0 &&
                            <div>
                                <Typography variant="subtitle2" component="span">
                                    {t<string>('menu.email-history.cc')}:{' '}
                                </Typography>
                                <Typography variant="body2" component="span">
                                    {props.item.ccs}
                                </Typography>
                            </div>
                        }
                        <ContentIframe srcDoc={props.item.template} sandbox="" />
                    </Collapse>
                </ContentPreviewContainer>
                {
                    showContent &&
                    <Stack direction="row" spacing={2.5} flexWrap="wrap">
                        {
                            props.item.attached_docs.map((file) => (
                                <DownloadButton
                                    key={file.id}
                                    variant="outlined"
                                    startIcon={<InsertDriveFileOutlinedIcon />}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        onDownload(file);
                                    }}
                                >
                                    {file.file_name}
                                </DownloadButton>
                            ))
                        }
                    </Stack>
                }
            </ContentContainer>
        </Container>
    );
}

const Container = styled(Stack)((props) => ({
    cursor: 'pointer',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: props.theme.shape.borderRadius,
    padding: props.theme.spacing(2)
}));

const ContentContainer = styled('div')(() => ({
    flex: 1
}));

const RecipientText = styled('span')(() => ({
    color: '#277ABA'
}));

const TimeText = styled(Typography)(() => ({
    whiteSpace: 'nowrap'
})) as typeof Typography;

const ContentPreviewContainer = styled(Stack)((props) => ({
    marginTop: props.theme.spacing(2)
}));

const EllipsedContent = styled('div')(() => ({
    "display": '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    "overflow": 'hidden',
    "textOverflow": 'ellipsis'
}));

const ContentIframe = styled('iframe')((props) => ({
    border: 0,
    width: '100%',
    height: '70vh',
    marginTop: props.theme.spacing(1)
}));

const DetailsMenu = styled('div')((props) => ({
    paddingTop: props.theme.spacing(2),
    paddingBottom: props.theme.spacing(2),
    paddingLeft: props.theme.spacing(4),
    paddingRight: props.theme.spacing(4)
}));

const OpenedDateItem = styled(ListItem)(() => ({
    padding: 0
}));

const OpenedDateAvatar = styled(ListItemAvatar)((props) => ({
    width: 24,
    height: 24,
    minWidth: 24,
    marginRight: props.theme.spacing(1)
}));

const DownloadButton = styled(Button)((props) => ({
    "textTransform": 'none',
    "border": 'none',
    "borderBottom": `1px dotted transparent`,
    "borderRadius": 0,
    "padding": 0,
    "paddingBottom": props.theme.spacing(0.5),
    '&:hover': {
        border: 'none',
        borderBottom: `1px dotted ${props.theme.palette.primary.main}`
    },
    '&:focus': {
        outline: 0
    }
}));
