//Dependencies
import axios from 'axios';
import Cropper from 'react-cropper';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, useEffect, useRef, useState } from 'react';
//Core
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from '@material-ui/lab/Pagination';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import DialogContent from '@material-ui/core/DialogContent';
import PaginationItem from '@material-ui/lab/PaginationItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
//Icons
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import Cached from '@material-ui/icons/Cached';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
//Commons
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import GetCookie from '../Common/Functions/GetCookie';
import i18n from '../../i18n';

const LinearProgressWithLabel = (props) => {
    return (
        <Box display={ 'flex' } alignItems={ 'center' }>
            <Box width={ '100%' } mr={ 1 }>
                <LinearProgress variant={ 'determinate' } {...props}/>
            </Box>
            <Box minWidth={35}>
                <Typography variant={ 'body2' } color={ 'textSecondary' }>{ `${Math.round(props.value)}%` }</Typography>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles({
    '@global': {
        '.cropper-crop-box': {
            borderRadius: '0 !important'
        },
        '.cropper-view-box': {
            borderRadius: '0 !important'
        }
    },
    "input": {
        display: 'none'
    },
    "picture": {
        borderRadius: 16,
        cursor: 'pointer'
    },
    "paginationItemActive": {
        outline: "5px auto -webkit-focus-ring-color"
    },
    "pagination": {
        "& button:focus": {
            outline: "none !important"
        }
    },
    "pictureProgress": {
        backgroundColor: 'inherit !important',
        boxShadow: 'none !important'
    },
    "pictureProgressHelper": {
        textAlign: 'center !important',
        color: 'white !important',
        marginTop: 8
    }
});

const CartManualProductPictures = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const cropperRef = useRef();
    const user = useSelector(store => store.user.user);
    const picture = useSelector(store => store.cart.manual_product_picture);
    const open = useSelector(store => store.cart.manual_product_picture_modal);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [pictures, setPictures] = useState([]);
    const [tmpAvatar, setTmpAvatar] = useState('/Img/destination_default.jpg');
    const [search, setSearch] = useState('');
    const [count, setCount] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const onClose = () => {
        dispatch({ type: 'CART_TOGGLE_MANUAL_PRODUCT_PICTURE' });
    };
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const changeImgHandler = (img) => {
        let reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onloadend = () => {
            setTmpAvatar(reader.result);
        };
    };
    const handleValidate = () => {
        setLoading(true);
        savePicture();
    };
    const savePicture = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            cropperRef.current.getCroppedCanvas().toBlob(function (blob) {
                let request = new FormData();
                request.append('name', name === '' ? 'Photo produit manuel' : name);
                request.append('description', description);
                request.append('image', blob);
                axios({
                    method: 'POST',
                    headers: {
                        "Authorization": "Token " + GetCookie("token"),
                        "Accept": "application/json",
                        "Accept-Language": i18n.language
                    },
                    url: `${API_HREF}client/${user.client}/pictures/?type=PICTURE`,
                    data: request,
                    onUploadProgress: function (progressEvent) {
                        //Do whatever you want with the native progress event
                        setProgress(progressEvent.loaded / progressEvent.total * 100);
                    }
                }).then(function (response) {
                    setProgress(0);
                    setLoading(false);
                    dispatch({ type: 'CART_SET_MANUAL_PRODUCT_PICTURE', payload: response.data });
                }).catch(function (error) {
                    setLoading(false);
                    setProgress(0);
                    console.log('error:', error);
                });
            });
        }
    };
    const onSearchChange = event => {
        setSearch(event.target.value);
    };
    const onPictureSelected = picture => () => {
        dispatch({ type: 'CART_SET_MANUAL_PRODUCT_PICTURE', payload: picture });
    };
    const onPageChange = (elem, page) => {
        setPage(page);
    };
    const onNameChange = event => {
        setName(event.target.value);
    };
    const onDescriptionChange = event => {
        setDescription(event.target.value);
    };
    useEffect(() => {
        if (picture) {
            setTmpAvatar(picture.thumbnail_little ? picture.thumbnail_little : picture.url);
        }
    }, [picture]);
    useEffect(() => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'GET',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/pictures/?limit=16&offset=${((page - 1) * 16)}&type=PICTURE&search=${search}`
        }).then(response => {
            setPictures(response.data.results);
            setCount(response.data.count);
        }).catch(error => {
            console.log(error);
        });
    }, [page, search]);
    return (
        <Fragment>
            <Dialog fullScreen={ fullScreen } PaperProps={{ style: { borderRadius: fullScreen ? 0 : 16 } }} open={ open } fullWidth maxWidth={ 'md' } onClose={ onClose } disableEscapeKeyDown>
                <DialogTitle onClose={ onClose }>
                    <Grid container justify={ 'space-between' } alignItems={ 'center' }>
                        {
                            fullScreen ? (
                                <Grid item>
                                    <IconButton edge={ 'start' } onClick={ onClose }><NavigateBefore/></IconButton> { t('cart-material.manual-product-picture') }
                                </Grid>
                            ) : (
                                <Fragment>
                                    <Grid item>
                                        { t('cart-material.manual-product-picture') } :
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={ onClose }><Close/></IconButton>
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </Grid>
                    <hr className={ classes.hr }/>
                </DialogTitle>
                <DialogContent>
                    <Tabs value={ value } onChange={ handleChange } indicatorColor={ 'primary' } textColor={ 'primary' }>
                        <Tab label={ t('cart-material.manual-product-picture-upload') }/>
                        <Tab label={ t('cart-material.manual-product-picture-selection') }/>
                    </Tabs>
                    <br/>
                    {
                        value === 0 &&
                        <Fragment>
                            <Grid container spacing={ 2 }>
                                <Grid item xs={ 12 }>
                                    <Cropper
                                        ref={ cropperRef }
                                        style={{ height: 400, width: '100%' }}
                                        zoomTo={ 0.5 }
                                        initialAspectRatio={ 1 }
                                        preview={ '.img-preview' }
                                        src={ tmpAvatar }
                                        viewMode={ 1 }
                                        minCropBoxHeight={ 10 }
                                        minCropBoxWidth={ 10 }
                                        background={ false }
                                        responsive={ true }
                                        autoCropArea={ 1 }
                                        checkOrientation={ false }
                                        guides={ true }
                                    />
                                </Grid>
                                <Grid container direction={ 'row' } justify={ 'center' } alignItems={ 'center' }>
                                    <Grid item>
                                        <br/>
                                        <input
                                            accept={ 'image/*' }
                                            className={ classes.input }
                                            id={ 'contained-button-file' }
                                            type={ 'file' }
                                            onChange={(event) => {
                                                changeImgHandler(event.target.files[0]); 
                                            }}
                                        />
                                        <label htmlFor={ 'contained-button-file' } style={{ marginBottom: 0 }}>
                                            <Button variant={ 'contained' } color={ 'primary' } component={ 'span' }>{ t('cart-material.manual-product-picture-select') }</Button>
                                        </label>
                                    </Grid>
                                </Grid>
                                <Grid item xs={ 12 }>
                                    <Grid container justify={ 'center' } alignItems={ 'center' } spacing={ 2 }>
                                        <Grid item xs={ 12 }>
                                            <FormControl fullWidth variant={ 'outlined' }>
                                                <InputLabel htmlFor={ 'manual-product-picture-name' }>{ t('cart-material.manual-product-picture-name') }</InputLabel>
                                                <OutlinedInput
                                                    id={ 'manual-product-picture-name' }
                                                    type={ 'text' }
                                                    onChange={ onNameChange }
                                                    label={ t('cart-material.manual-product-picture-name') }
                                                    value={ name }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={ 12 }>
                                            <FormControl fullWidth variant={ 'outlined' }>
                                                <InputLabel htmlFor={ 'manual-product-picture-description' }>{ t('cart-material.manual-product-picture-description') }</InputLabel>
                                                <OutlinedInput
                                                    id={ 'manual-product-picture-description' }
                                                    type={ 'text' }
                                                    onChange={ onDescriptionChange }
                                                    label={ t('cart-material.manual-product-picture-description') }
                                                    value={ description }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <IconButton onClick={() => cropperRef.current.zoom(0.1)}>
                                                <ZoomIn/>
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <IconButton onClick={() => cropperRef.current.zoom(-0.1)}>
                                                <ZoomOut/>
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title={ t('menu.profile.reset_zoom') }>
                                                <IconButton onClick={() => cropperRef.current.reset()}>
                                                    <Cached/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title={ t('cart.edit_booking.validate') }>
                                                <IconButton style={{ backgroundColor: '#E6592F', color: 'white' }} onClick={ handleValidate }>
                                                    <Check/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    loading && (
                                        <Grid item xs={ 12 } style={{ marginBottom: 10 }}>
                                            <LinearProgressWithLabel value={ progress }/>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Fragment>
                    }
                    {
                        value === 1 &&
                        <Fragment>
                            <FormControl fullWidth variant={ 'outlined' }>
                                <InputLabel htmlFor={ 'manual-product-picture-search' }>{ t('cart-material.manual-product-picture-search') }</InputLabel>
                                <OutlinedInput
                                    id={ 'manual-product-picture-search' }
                                    type={ 'text' }
                                    onChange={ onSearchChange }
                                    label={ t('cart-material.manual-product-picture-search') }
                                    value={ search }
                                />
                            </FormControl>
                            <div>
                                <br/>
                            </div>
                            <Grid container spacing={ 2 }>
                                {
                                    pictures.map(picture => (
                                        <Grid item xs={ 3 }>
                                            <img className={ classes.picture } src={ picture.url } alt={ 'picture' } width={ '100%' } height={ 150 } onClick={ onPictureSelected(picture) }/>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                            <Grid container justify={ 'center' } spacing={ 4 }>
                                <Grid item>
                                    <Pagination className={classes.pagination} count={ Math.ceil(count / 30) } variant={ 'outlined' } hideNextButton={ (pictures === null || pictures.length === 0 || page === Math.ceil(count / 30)) } hidePrevButton={ page === 1 } disabled={ pictures === null || pictures.length === 0 } onChange={ onPageChange } renderItem={(item) => <PaginationItem className={ `${item.selected ? classes.paginationItemActive : ''}` } {...item}/>}/>
                                </Grid>
                            </Grid>
                        </Fragment>
                    }
                </DialogContent>
            </Dialog>
            <Dialog PaperProps={{ className: classes.pictureProgress }} fullWidth maxWidth={ 'sm' } open={ loading && progress > 0 }>
                <LinearProgress variant={ 'determinate' } value={ progress }/>
                <Typography className={ classes.pictureProgressHelper }>{ t('global.upload-picture(s)-progress') }</Typography>
            </Dialog>
        </Fragment>
    );
};

export default CartManualProductPictures;
