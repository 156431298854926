import axios, { AxiosResponse } from "axios";
import { isEmpty } from "lodash";
import { sortItinerary } from "./sortItinerary";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { Itinerary } from "../objects/itinerary";
import { IataAirport } from "../objects/iataAirport";
import { R2RSearchResponse } from "../objects/r2rSearchResponse";

type Outbound = {
    step: Itinerary,
    index: number,
    a?: IataAirport,
    b?: IataAirport,
    departurePlace?: R2RSearchResponse['places'][number],
    arrivalPlace?: R2RSearchResponse['places'][number],
    date: string
}

export async function findOutbounds(
    itinerary: Itinerary[],
    departureStep: Itinerary,
    returnStep: Itinerary
): Promise<Outbound[]> {
    const outbounds: Outbound[] = [];

    const itineraryWithFlights = itinerary.filter((item) => {
        return !isEmpty(item.r2r_json) &&
               item.r2r_json?.type === 2 &&
               item.r2r_json?.vehicle?.kind === 'plane';
    }).sort(sortItinerary);
    for (let i = 0; i < itineraryWithFlights.length; i++) {
        const step = itineraryWithFlights[i]!;
        const segments = step.r2r_json?.segments?.filter((item) => {
            return item.segmentKind === 'air';
        }) ?? [];
        for (const segment of segments) {
            let departurePlace = step.r2r_json?.data?.places[segment.depPlace];
            let arrivalPlace = step.r2r_json?.data?.places[segment.arrPlace];
            let departureName = '';
            if (step.destination?.data?.international_name) {
                departureName = step.destination.data.international_name.split(',')[0] ?? '';
            } else if (step.step_type === 'START') {
                departureName = departureStep.city_name ?? '';
            } else {
                departureName = returnStep.city_name ?? '';
            }

            const arrivalName = itineraryWithFlights[i + 1]?.destination?.data?.international_name.split(',')[0] ??
                                returnStep.city_name ??
                                '';
            if (
                (
                    departureName !== '' &&
                    !departurePlace?.shortName.includes(departureName)
                ) ||
                (
                    arrivalName !== '' &&
                    !arrivalPlace?.shortName.includes(arrivalName)
                )
            ) {
                const route = step.r2r_json?.data?.routes.find((route) => {
                    return (
                        departureName !== '' &&
                        arrivalName !== '' &&
                        route.name.includes(departureName) &&
                        route.name.includes(arrivalName) &&
                        step.r2r_json?.data?.places[route.depPlace]?.code !== step.r2r_json?.data?.places[route.arrPlace]?.code
                    ) || (
                        departureName !== '' &&
                        route.name.includes(departureName) &&
                        step.r2r_json?.data?.places[route.depPlace]?.code !== step.r2r_json?.data?.places[route.arrPlace]?.code
                    ) || (
                        arrivalName !== '' &&
                        route.name.includes(arrivalName) &&
                        step.r2r_json?.data?.places[route.depPlace]?.code !== step.r2r_json?.data?.places[route.arrPlace]?.code
                    );
                });
                const airSegment = route?.segments.find((item) => item.segmentKind === 'air');
                if (airSegment) {
                    departurePlace = step.r2r_json?.data?.places[airSegment.depPlace];
                    arrivalPlace = step.r2r_json?.data?.places[airSegment.arrPlace];
                }
            }
            let departureDate = step.end_date;
            const departureResponse = departurePlace ?
                await makeAirportSearchRequest(departurePlace.code) :
                null;
            const arrivalResponse = arrivalPlace ?
                await makeAirportSearchRequest(arrivalPlace.code) :
                null;

            const departureAirport = departureResponse?.data.results.find((item) => {
                return item.airport_code.length === 4;
            });
            const arrivalAirport = arrivalResponse?.data.results.find((item) => {
                return item.airport_code.length === 4;
            });
            outbounds.push({
                step,
                index: i,
                a: departureAirport,
                b: arrivalAirport,
                departurePlace,
                arrivalPlace,
                date: departureDate
            });
        }
    }

    return outbounds;
}

async function makeAirportSearchRequest(code: string): Promise<AxiosResponse<{results: IataAirport[]}> | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        try {
            return await axios.get<{results: IataAirport[]}>(
                `${API_HREF}iata-airports/`,
                {
                    headers,
                    params: {
                        search: code
                    }
                }
            );
        } catch (error) {
            console.error(error);
        }
    }

    return null;
}
