// Dependencies
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Core
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import green from '@material-ui/core/colors/green';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// Icons
import Info from '@material-ui/icons/Info';

const useStyles = makeStyles({
    bold: {
        fontWeight: 'bold'
    },
    inline: {
        display: 'inline-block'
    },
    inlineFlex: {
        display: 'inline-flex'
    },
    textWrap: {
        whiteSpace: 'normal',
        maxWidth: 140
    },
    floatRight: {
        float: 'right'
    },
    info: {
        marginLeft: 8
    },
    marginBottom: {
        marginBottom: 16
    },
    providerWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white',
         borderRadius: 24
    },
    marginLeft: {
        marginLeft: 8
    }
});

const AccommodationCardVisiteurs = ({prices, diff_day, language, provider_loading, getRoomPrices}) => {
    console.log('visiteurs render');
    const classes = useStyles();
    const { t } = useTranslation();
    const currency = useSelector(state => state.trip.currency);
    const currency_list = useSelector(state => state.base.currency_list);
    let preferred_array = {
        1: null,
        2: null
    };
    prices.map((price) => {
        if (price.data.code === 'hotelbeds' || price.data.code === 'koedia') {
            if (preferred_array['2'] === null || preferred_array['2'].price > price.price) {
                preferred_array['2'] = price;
            }
        } else {
            if (preferred_array['1'] === null || preferred_array['1'].price > price.price) {
                preferred_array['1'] = price;
            }
        }
    });
    const getIsoCode = (price_currency) => {
        let iso_code = currency.iso_code;
        if (price_currency !== null) {
            iso_code = currency_list.find((currency) => {
                return currency.id === price_currency;
            }).iso_code;
        }
        return iso_code;
    };
    return (
        <Fragment>
            <br/>
            {
                preferred_array['1'] !== null && (
                    <Tooltip title={ t('accommodation.visiteurs_partners') } arrow>
                        <div className={ classes.marginBottom }>
                            <Typography className={ `${classes.inlineFlex} ${classes.textWrap}` }>{ t('accommodation.visiteurs_partners') } <Info className={ classes.info }/> : </Typography>
                            <div className={ `${classes.inline} ${classes.floatRight}` }>
                                <Typography className={ `${classes.inlineFlex} ${classes.bold}` }>{ new Intl.NumberFormat(language, { style: 'currency', currency: getIsoCode(preferred_array['1'].price_currency) }).format(Math.ceil(parseFloat( preferred_array['1'].price / diff_day ))) }</Typography>
                                <div className={ `${classes.providerWrapper} ${classes.inline} ${classes.marginLeft}` }>
                                    <Button variant={ 'contained' } size={ 'small' } className={ classes.orangeButton } disabled={ provider_loading === preferred_array['1'].data.id } onClick={() => { getRoomPrices(preferred_array['1'].data); } }>{ t('accommodation.choose') }</Button>
                                    { provider_loading === preferred_array['1'].data.id && <CircularProgress size={24} className={ classes.buttonProgress } /> }
                                </div>
                            </div>
                        </div>
                    </Tooltip>
                )
            }
            {
                preferred_array['2'] !== null && (
                    <Tooltip title={ t('accommodation.visiteurs_bedbanks') } arrow>
                        <div className={ classes.marginBottom }>
                            <Typography className={ `${classes.inlineFlex} ${classes.textWrap}` }>{ t('accommodation.visiteurs_bedbanks') } <Info className={ classes.info }/> : </Typography>
                            <div className={ `${classes.inline} ${classes.floatRight}` }>
                                <Typography className={ `${classes.inlineFlex} ${classes.bold}` }>{ new Intl.NumberFormat(language, { style: 'currency', currency: getIsoCode(preferred_array['2'].price_currency) }).format(Math.ceil(parseFloat( preferred_array['2'].price / diff_day ))) }</Typography>
                                <div className={ `${classes.providerWrapper} ${classes.inline} ${classes.marginLeft}` }>
                                    <Button variant={ 'contained' } size={ 'small' } className={ classes.orangeButton } disabled={ provider_loading === preferred_array['2'].data.id } onClick={() => { getRoomPrices(preferred_array['2'].data); } }>{ t('accommodation.choose') }</Button>
                                    { provider_loading === preferred_array['2'].data.id && <CircularProgress size={24} className={ classes.buttonProgress } /> }
                                </div>
                            </div>
                        </div>
                    </Tooltip>
                )
            }
        </Fragment>
    )
};

export default React.memo(AccommodationCardVisiteurs);