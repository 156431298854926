import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Transition from "../../Common/Transition";

import { OpenRemoveLuggage, RemoveBaggageOption } from "../../../Actions/FlightSearch";


const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A",
        letterSpacing: 1.25
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    fontWeight500: {
        fontWeight: 500
    },
    alert: {
        fontWeight: 900,
        color: "red",
        padding: 16
    },
    cancelButton: {
        color: "#0000008A",
        backgroundColor: "#FFFFFF",
        marginRight: 20
    },
    validateButton: {
        color: "#FFFFFF",
        backgroundColor: "#2C98F0"
    }
}));

const RemoveLuggage = ({ luggage_index, outbound_index, key_segment, traveler_id }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(768));

    const flight_list_render = useSelector(store => store.flight_search.flight_list_render);
    const selected_flight = useSelector(store => store.flight_search.selected_flight);
    const open_remove_luggage = useSelector(store => store.flight_search.open_remove_luggage);

    let flight = flight_list_render.find(el => el.id === selected_flight);

    const [isOnGroup, setIsOnGroup] = useState(false);
    const [isOnFlight, setIsOnFlight] = useState(false);
    const [optionKey, setOptionKey] = useState("");
    
    useEffect(() => {
        if (luggage_index !== null && outbound_index !== null && key_segment && traveler_id !== null) {
            flight.outbounds[outbound_index].legs.map((leg) => {
                if ((leg.key_segments === key_segment || leg.key === key_segment) && Object.keys(leg.paid_options).length !== 0) {
                    let option = leg.paid_options[traveler_id][luggage_index];
                    setOptionKey(option.key_optional_service);
                    if (option.option_on_group) {
                        setIsOnGroup(true);
                    }
                    if (option.option_on_flight) {
                        setIsOnFlight(true);
                    }
                }
            });
        }
    }, [luggage_index, outbound_index, key_segment, traveler_id]);

    const cancelDelete = () => {
        dispatch(OpenRemoveLuggage(false));
    };

    const validateDelete = () => {
        dispatch(RemoveBaggageOption(luggage_index, flight.id, outbound_index, key_segment, traveler_id));
        if (isOnGroup) {
            flight.outbounds[outbound_index].legs.map((leg) => {
                if ((leg.key_segments === key_segment || leg.key === key_segment) && Object.keys(leg.paid_options).length !== 0 && leg.paid_options[traveler_id] !== undefined && leg.paid_options[traveler_id].length > 0) {
                    leg.paid_options[traveler_id].map((option, option_index) => {
                        if (option.key_optional_service === optionKey) {
                            dispatch(RemoveBaggageOption(option_index, flight.id, outbound_index, leg.key_segments !== undefined ? leg.key_segments : leg.key, traveler_id));
                        }
                    });
                }
            });
        }

        if (isOnFlight) {
            flight.outbounds.map((outbound, parsed_outbound_index) => {
                if (parsed_outbound_index !== outbound_index) {
                    outbound.legs.map((leg) => {
                        if ((leg.key_segments === key_segment || leg.key === key_segment) && Object.keys(leg.paid_options).length !== 0 && leg.paid_options[traveler_id] !== undefined && leg.paid_options[traveler_id].length > 0) {
                            leg.paid_options[traveler_id].map((option, option_index) => {
                                if (option.key_optional_service === optionKey) {
                                    dispatch(RemoveBaggageOption(option_index, flight.id, parsed_outbound_index, leg.key_segments !== undefined ? leg.key_segments : leg.key, traveler_id));
                                }
                            });
                        }
                    });
                }
            });
        }

        dispatch(OpenRemoveLuggage(false));
    };


    return (
        <Dialog open={open_remove_luggage} fullScreen={ false } TransitionComponent={Transition} fullWidth maxWidth={"xs"}>
            <DialogContent dividers>
                <Grid className={`${classes.genericText} ${!mobile ? classes.fontSize16 : classes.fontSize12} ${classes.fontWeight500}`}>
                    { t("flight_search.del_luggage") + " ?" }
                </Grid>
                {
                    isOnGroup && (
                        <Grid className={`${classes.genericText} ${!mobile ? classes.fontSize16 : classes.fontSize12} ${classes.alert}`}>
                            { t("flight_search.del_luggage_on_group") }
                        </Grid>
                    )
                }
                {
                    isOnFlight && (
                        <Grid className={`${classes.genericText} ${!mobile ? classes.fontSize16 : classes.fontSize12} ${classes.alert}`}>
                            { t("flight_search.del_luggage_on_flight") }
                        </Grid>
                    )
                }
                <Grid container alignItems="center" justify="space-evenly" style={{ paddingTop: 20 }}>
                    <Grid item>
                        <Button variant="contained" size="small" className={classes.cancelButton} onClick={cancelDelete}>
                            { t("global.no") }
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" size="small" className={classes.validateButton} onClick={validateDelete}>
                            { t("global.yes") }
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default React.memo(RemoveLuggage);
