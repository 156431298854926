import { useDispatch } from "react-redux";
import axios from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { setLoading } from "../redux/reducer";
import { ToggleMoreInfoModal } from "../../../Actions/Itinerary";
import { LightDestination } from "../objects/lightDestination";

type Callback = (destination: LightDestination) => Promise<void>

export function useItineraryStepSee(): Callback {
    const dispatch = useDispatch();

    return async (destination) => {
        const { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            dispatch(setLoading(true));
            try {
                const response = await axios.get(
                    `${API_HREF}client/${window.id_owner}/destinations/${destination.id}/`,
                    { headers }
                );
                dispatch(ToggleMoreInfoModal(true, response.data, null));
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(setLoading(false));
            }
        }
    };
}
