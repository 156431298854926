import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios, { AxiosResponse } from "axios";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import { AppState } from "../../../../Reducers/Reducers";
import { MailHistory } from "../objects/mail-history";

type Result = [
    () => void,
    any
]

export function useEmailsHistory(): Result {
    const trip_info = useSelector((store: AppState) => store.menu.trip_info);
    const [data, setData] = useState<any>(null);

    const fireNetworkRequest = () => {
        if (trip_info) {
            const pendingResponse = makeRequest(trip_info);
            if (pendingResponse) {
                pendingResponse.then((response) => {
                    const newData = Object.assign({}, response.data);
                    newData.results = response.data.results.sort((a, b) => b.id - a.id);
                    setData(newData);
                });
            }
        }
    };

    useEffect(fireNetworkRequest, [trip_info]);

    return [fireNetworkRequest, data];
}

function makeRequest(trip_info: any): Promise<AxiosResponse<{results: MailHistory[]}>> | null {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        return axios.get(
            //eslint-disable-next-line no-undef
            `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/email-history/`,
            {
                headers: headers,
                params: {
                    limit: 1000,
                    ordering: 'id'
                }
            }
        );
    }

    return null;
}
