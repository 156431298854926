import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import GetProvider from "../Common/Functions/GetProvider.js";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	matrixTitle: {
		color: 'rgba(0, 0, 0, 0.54)',
		textAlign: 'center',
		fontWeight: 'bold',
		// background: '#e3eefa',
		marginTop: '10px'
	},
	providerSmallPic: {
		height: 25,
		marginLeft: 8
	},
	carSubLogo: {
		paddingLeft: '4px',
		height: 25
	},
	carHeight16: {
		paddingLeft: '4px',
		height: 25
	},
	absoluteCenter: {
		margin: '0',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	},
	skeletonWidth: {
		width: '20%'
	},
	marginTop: {
		marginTop: '156px !important'
	},
	littleLogo: {
		marginLeft: 8
	},
	fullWidth: {
		width: "100%"
	},
	marginTop14: {
		marginTop: 14,
		textAlign: 'center'
	}
}));

const RenderProvider = ({cars_results, user, provider_list, all_cars, setProviderID, setCategoryID}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [pic_width, setPicWidth] = useState(0);
	const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
	// let quotation_code = "marcovasco";
	const user_type = user.client_full.type;
	const logo_to = JSON.parse(localStorage.getItem("config")).favicon.thumbnail_little !== null ? JSON.parse(localStorage.getItem("config")).favicon.thumbnail_little : JSON.parse(localStorage.getItem("config")).favicon.url;
	return (
		<GridList cellHeight={50} cols={1} className={`${classes.marginTop} ${classes.fullWidth}`}>
			<GridListTile cols={1} style={{'cursor': 'pointer'}} onClick={() => {
				setCategoryID("all");
				setProviderID(null);
			}}>
				<Paper elevation={0} className={ classes.matrixTitle }>
					{
						cars_results.length !== 0 && (
							t("cars.lowest_price")
						)
					}
					{
						cars_results.length === 0 && (
							<Skeleton height={50} width={150} animation="wave"/>
						)
					}
				</Paper>
			</GridListTile>
			{cars_results.map((provider, index_provider) => {
				let active_provider = GetProvider(provider_list, provider.provider_id);
				let subprovider = GetProvider(provider_list, provider.subprovider_id);
				const config = JSON.parse(localStorage.getItem("config"));
				// let stamp_recommended = null;
				// let recommended = false;
				// if (config !== null && config.stamp_recommended !== undefined && config.stamp_recommended !== null && config.stamp_recommended.url !== undefined && config.stamp_recommended.url !== null) {
				// 	stamp_recommended = config.stamp_recommended.url;
				// }
				// for (let i = 0; i < active_provider.configuration.extra_params.length; i++) {
				// 	if (active_provider.configuration.extra_params[i].key === "car_preferred" && active_provider.configuration.extra_params[i].value === "1") {
				// 		recommended = true;
				// 	}
				// }
				if (provider.to_render) {
					return (
						<GridListTile key={index_provider} cols={1} style={{'cursor': 'pointer'}} onClick={() => {
							setCategoryID(null);
							setProviderID(index_provider);
						}}>
							{
								provider.provider_id === provider.subprovider_id && active_provider.provider.logo !== null && (
									<Paper elevation={0} className={ classes.marginTop14 }>
										<div>
											{
												// recommended && (
												// 	<img src={ stamp_recommended } height={ 30 } style={{position:"absolute",left:"-35px"}}/>
												// )
											}
											<img src={ active_provider.provider.logo.thumbnail_little !== null ? active_provider.provider.logo.thumbnail_little : active_provider.provider.logo.url } className={classes.providerSmallPic} />
											{
												(user_type === 2) && (
													<Tooltip title={t("cars.best_choice")}>
														<img src={ logo_to } height={ 30 }
															 className={ classes.littleLogo }
														/>
													</Tooltip>
												)
											}
										</div>
									</Paper>
								)
							}
							{
								provider.provider_id !== provider.subprovider_id && active_provider.provider.logo !== null && (
									<Paper elevation={0} className={ classes.marginTop14 }>
										<div>
											{
												subprovider && subprovider.provider.logo !== null && (
													<img src={ subprovider.provider.logo.thumbnail_little !== null ? subprovider.provider.logo.thumbnail_little : subprovider.provider.logo.url } className={ (user_type === 2 || quotation_code === "marcovasco") ? classes.carHeight16 : classes.carSubLogo }/>
												)
											}
											{
												subprovider === null && (
													<img src={ provider.subprovider_logo } className={ (user_type === 2 || quotation_code === "marcovasco") ? classes.carHeight16 : classes.carSubLogo }/>
												)
											}
											{
												(user_type !== 2 || quotation_code === 'verdie') && (
													<img src={ active_provider.provider.logo.thumbnail_little !== null ? active_provider.provider.logo.thumbnail_little : active_provider.provider.logo.url } onLoad={(event) => {setPicWidth(event.target.width)}} className={classes.providerSmallPic} />
												)
											}
										</div>
									</Paper>
								)
							}
						</GridListTile>
					)
				}
			})}
			{
				!all_cars && (
					<GridListTile cols={1}>
						<Paper elevation={0}>
							<div className={classes.absoluteCenter}>
								<Skeleton height={50} width={140} animation="wave"/>
							</div>
						</Paper>
					</GridListTile>
				)
			}
			{
				!all_cars && (
					<GridListTile cols={1}>
						<div className={classes.absoluteCenter}>
							<Skeleton height={50} width={140} animation="wave"/>
						</div>
					</GridListTile>
				)
			}
			{
				!all_cars && (
					<GridListTile cols={1}>
						<div className={classes.absoluteCenter}>
							<Skeleton height={50} width={140} animation="wave"/>
						</div>
					</GridListTile>
				)
			}
		</GridList>
	)
}
export default RenderProvider;