//Dependencies
import React from 'react';
//Component
import { CartManualProductCard } from './CartManualProductCard';
import { CartAccommodationCard } from './CartAccommodationCard';
import { CartFlightCard } from './CartFlightCard';

const CartManualProduct = ({ manual_product }) => {
    switch (manual_product.product_type) {
        case 0:
            return (
                <CartAccommodationCard
                    type="manual"
                    accommodation={ manual_product }
                />
            );
        case 6:
            return (
                <CartFlightCard
                    type="manual"
                    flight={manual_product}
                />
            );
        default:
            return (
                <CartManualProductCard product={manual_product} />
            );
    }
};

export default CartManualProduct;
