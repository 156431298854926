//---- Dependencies ----//
import React, {Fragment, useEffect, useState, useRef} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import grey from "@material-ui/core/colors/grey";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MapIcon from '@material-ui/icons/Map';

import { SetSummaryStep } from "../../Actions/Summary";

const useStyles = makeStyles(theme => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    navigationWrapper: {
        paddingTop: 8,
        overflow: 'hidden'
    },
    navigationWrapperResponsive: {
        position: 'fixed',
        bottom: 0,
        zIndex: 2,
        width: '100%',
        paddingBottom: 8
    },
    mobileFont: {
        fontSize: '10px'
    },
    navigationButton: {
        textAlign: "center",
        cursor: "pointer"
    },
    orange: {
        color: "#E6592F"
    },
    grey: {
        color: grey[400]
    },
}));

const SummaryHeaderMobile = ({missingDocs, props}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const classes = useStyles();

    const summary_step = useSelector(store => store.summary.summary_step);

    return (
        <Paper className={ `${classes.navigationWrapper} ${classes.navigationWrapperResponsive}` } elevation={ 3 }>
            <Grid container justify={ "space-evenly" } alignItems={ "center" }>
                <Grid className={ `${classes.navigationButton} ${summary_step === 2 ? classes.orange : classes.grey}` } item onClick={() => {
                    if (summary_step !== 2) {
                        props.router.push(`/${window.url_name}/summary-quotation/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`);
                        // dispatch(SetSummaryStep(2));
                    }
                }}>
                    <MapIcon fontSize={ "default" }/>
                    <Typography className={classes.mobileFont }>{t("summary.my_trip")}</Typography>
                </Grid>
                <Grid className={ `${classes.navigationButton} ${summary_step === 4 ? classes.orange : classes.grey}` } item onClick={() => {
                    if (summary_step !== 4) {
                        dispatch(SetSummaryStep(4));
                    }
                }}>
                    <ReceiptIcon fontSize={ "default" }/>
                    <Typography className={classes.mobileFont }>{t("summary.quotation_validation")}</Typography>
                </Grid>
                {
                    missingDocs !== 0 && (
                        
                        <Grid className={ `${classes.navigationButton} ${summary_step === 5 ? classes.orange : classes.grey}` } item onClick={() => {
                            if (summary_step !== 5) {
                                    // dispatch(SetSummaryStep(5));
                                    props.router.push(`/${window.url_name}/summary-documents/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`);
                                }
                            }}>
                            <Badge badgeContent={missingDocs} color="secondary" anchorOrigin={{vertical: "top", horizontal:"right"}}> 
                                <AssignmentIcon fontSize={ "default" }/>
                            </Badge>
                            <Typography className={classes.mobileFont }>{t("menu.trip_list.documents")}</Typography>
                        </Grid>

                    )
                }
                {
                    missingDocs === 0 && (
                        <Grid className={ `${classes.navigationButton} ${summary_step === 5 ? classes.orange : classes.grey}` } item onClick={() => {
                            if (summary_step !== 5) {
                                // dispatch(SetSummaryStep(5));
                                props.router.push(`/${window.url_name}/summary-documents/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`);
                            }
                        }}>
                            <AssignmentIcon fontSize={ "default" }/>
                            <Typography className={classes.mobileFont }>{t("menu.trip_list.documents")}</Typography>
                        </Grid>
                    )
                }
                <Grid className={ `${classes.navigationButton} ${summary_step === 6 ? classes.orange : classes.grey}` } item onClick={() => {
                    if (summary_step !== 6) {
                        // dispatch(SetSummaryStep(6));
                        props.router.push(`/${window.url_name}/summary-documents/${props.params.trip_id}/${props.params.trip_id_version}/${props.params.trip_token}`);
                    }
                }}>
                    <AccountCircleOutlinedIcon fontSize={ "default" }/>
                    <Typography className={classes.mobileFont }>{t("summary.contact")}</Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}
export default SummaryHeaderMobile;