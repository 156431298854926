import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import { Save } from "@mui/icons-material";
import { convertToRaw } from "draft-js";
import { debounce, flattenDeep, isEqual, pickBy } from "lodash";
import { RubricData, useTripRoadbookRubricsUpdate } from "./network/tripRoadbookRubricsUpdate";
import { useShowError } from "../Utils/showError";
import { findTripRoadbookRubrics } from "../Menu/MaterialTripList/utils/findTripRoadbookRubrics";
import { renderHtml } from "../Menu/MaterialTripList/MailVisualEditor/utils/render-html";
import { SaveRequestsQueue } from "../Itinerary/utils/saveRequestsQueue";
import { MailTemplateVisualEditorBodyStyles } from "../Menu/MaterialTripList/MailVisualEditor/objects/mailTemplateVisualEditorState";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    titles: {[id: number]: {[locale: number]: string}},
}

const INSTANCE_KEYS = [
    'cart-construction-roadbook-rubric'
];

export function CartConstructionContentsRoadbookFormSave(props: Props): JSX.Element {
    const instances = useSelector((state: AppState) => state.mailTemplate.visualEditor.present.instances);
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const [loading, setLoading] = useState(false);
    const visualEditorInstances = useMemo(() => {
        return pickBy(
            instances,
            (_, key) => {
                for (const item of INSTANCE_KEYS) {
                    if (key.startsWith(item)) {
                        return true;
                    }
                }
                return false;
            }
        );
    }, [instances]);
    const saveQueue = useMemo(() => {
        return new SaveRequestsQueue<RubricData, RubricData>();
    }, []);
    const previousVisualEditorInstances = useRef<typeof visualEditorInstances | null>(null);
    const showError = useShowError();
    const update = useTripRoadbookRubricsUpdate({
        onTrigger() {
            setLoading(true);
        },
        onSuccess() {
            findTripRoadbookRubrics.clear();
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });

    const onSave = useCallback(
        debounce(
            (
                options: {
                    usedTripId: typeof tripId,
                    previousInstances: typeof previousVisualEditorInstances.current, 
                    instances: typeof visualEditorInstances,
                    titles: typeof props.titles
                }
            ) => {
                const previousStates = flattenDeep(
                    Object.keys(options.previousInstances ?? options.instances).map((instanceId) => {
                        const instance = (options.previousInstances ?? options.instances)[instanceId];
                        if (instance) {
                            return Object.values(instance.visualEditorBlocks).map((item) => {
                                return item.order.map((id) => {
                                    if (item.blocks[id]) {
                                        return {
                                            instanceId,
                                            block: item.blocks[id]!
                                        };
                                    }
                                });
                            });
                        }
                    })
                ).filter((item): item is NonNullable<typeof item> => !!item);
                const newStates = flattenDeep(
                    Object.keys(options.instances).map((instanceId) => {
                        const instance = options.instances[instanceId];
                        if (instance) {
                            return Object.values(instance.visualEditorBlocks).map((item) => {
                                return item.order.map((id) => {
                                    if (item.blocks[id]) {
                                        return {
                                            instanceId,
                                            block: item.blocks[id]!
                                        };
                                    }
                                });
                            });
                        }
                    })
                ).filter((item): item is NonNullable<typeof item> => !!item);
                if (
                    previousStates.length !== newStates.length ||
                    !newStates.every((state, index) => {
                        const corresponding = previousStates[index]!;

                        if (state.instanceId !== corresponding.instanceId) {
                            return false;
                        }
    
                        if (state.block.getType() === 'text' && corresponding.block.getType() === 'text') {
                            const oldContent = convertToRaw(corresponding.block.getOptions().editorState.getCurrentContent());
                            const newContent = convertToRaw(state.block.getOptions().editorState.getCurrentContent());
                            const oldOptions = {
                                ...corresponding.block.getOptions(),
                                editorState: null
                            };
                            const newOptions = {
                                ...state.block.getOptions(),
                                editorState: null
                            };
                            return isEqual(
                                {
                                    ...oldContent,
                                    blocks: oldContent.blocks.map((item) => {
                                    //eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        const { key, ...data } = item;
                                        return data;
                                    })
                                },
                                {
                                    ...newContent,
                                    blocks: newContent.blocks.map((item) => {
                                    //eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        const { key, ...data } = item;
                                        return data;
                                    })
                                }
                            ) && isEqual(
                                oldOptions,
                                newOptions
                            );
                        }
    
                        return isEqual(state.block.getOptions(), corresponding.block.getOptions());
                    })
                ) {
                    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
                    const rubrics = Object.keys(options.instances).filter((key) => {
                        return key.startsWith(`cart-construction-roadbook-rubric-${options.usedTripId}`);
                    }).map((key) => {
                        const rubricId = key.replace(`cart-construction-roadbook-rubric-${options.usedTripId}-`, '');
                        return {
                            rubricId: parseInt(rubricId),
                            instance: options.instances[key]!
                        };
                    });
                    const result = rubrics.map((rubric) => {
                        const locales = Object.keys(rubric.instance.visualEditorBlocks).map((key) => {
                            return parseInt(key);
                        });
        
                        return {
                            parent: rubric.rubricId,
                            localization: locales.map((locale) => {
                                const defaultTitle = Object.values(options.titles[rubric.rubricId] ?? {})[0] ?? '';
                                const title = (options.titles[rubric.rubricId] ?? {})[locale] ?? defaultTitle;
                                return {
                                    locale,
                                    title,
                                    description: renderHtml(
                                        createHtmlOutputStyles(rubric.instance.visualEditorBodyStyles),
                                        rubric.instance.visualEditorBlocks[locale] ?? { order: [], blocks: {} },
                                        quotationCode,
                                        true
                                    )
                                };
                            })
                        };
                    });
                    if (result.length > 0) {
                        saveQueue.add(result);
                        previousVisualEditorInstances.current = options.instances;
                    }
                }

                if (!previousVisualEditorInstances.current) {
                    previousVisualEditorInstances.current = options.instances;
                }
            },
            500
        ),
        [update]
    );

    useEffect(() => {
        return () => {
            saveQueue.release();
        };
    }, []);

    useEffect(() => {
        saveQueue.setOnUpdate(async (_, values) => {
            return await update(values);
        });
    }, [update]);

    useEffect(() => {
        onSave({
            usedTripId: tripId,
            instances: visualEditorInstances,
            previousInstances: previousVisualEditorInstances.current,
            titles: props.titles
        });
    }, [
        tripId,
        visualEditorInstances,
        props.titles
    ]);

    return (
        <Box
            sx={(theme) => ({
                position: 'fixed',
                left: theme.spacing(2.5),
                bottom: theme.spacing(2.5),
                display: loading ? 'block' : 'none'
            })}
        >
            <Box sx={{ position: 'relative', display: 'flex' }}>
                <CircularProgress size={25} color="inherit" />
                <Box
                    sx={{
                        position: 'absolute',
                        top: 3,
                        left: 3,
                        width: 19,
                        height: 19,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#5B9AF0'
                    }}
                >
                    <Save
                        color="inherit"
                        fontSize="inherit"
                        sx={{ color: '#fff', fontSize: 10 }}
                    />
                </Box>
            </Box>
        </Box>
    );
}

function createHtmlOutputStyles(styles: MailTemplateVisualEditorBodyStyles): MailTemplateVisualEditorBodyStyles {
    return {
        ...styles,
        width: 800
    };
}
