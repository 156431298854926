import { createDomObjectsFromHtml } from "../MailVisualEditor/utils/dom";
import { CreatePageOptions } from "../objects/createPageOptions";
import { Template } from "../utils/handlebars/roadbook.handlebars";

type Options = {
    text: string
}

export function createEditoContent(options: CreatePageOptions & Options): Parameters<Template>[0]['contents'][0] {
    const { document } = createDomObjectsFromHtml(options.text);

    for (const styleNode of document.body.getElementsByTagName('style')) {
        styleNode.remove();
    }

    for (const table of document.body.getElementsByTagName('table')) {
        table.replaceWith(...table.children);
    }

    return {
        type: 'edito',
        anchor: 'edito',
        showInSummary: false,
        summaryTitle: options.title,
        title: options.title,
        text: document.body.innerHTML
    };
}
