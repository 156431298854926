//---- Dependencies ----//
import React, { Component, PropTypes } from 'react';
import { Popover } from 'react-bootstrap';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

//---- Common ----//
import Notify from '../../../Common/Notify.js';

//---- Actions ----//
import { SelectedSeat, AddPassengerSeat, ShowPopOver, SetConfirmation } from "../../../../Actions/FlightSearch";
import { AddCartPassengerSeat } from "../../../../Actions/Flight";

@connect((store) => {
    return {
        flight_groups: store.flight.flight_groups,
        index_group: store.flight.index_group,
        selected_seat: store.flight_search.seat_selected,
        seat_data: store.flight_search.seat_info,
    }
})
@withTranslation()
//---- Class ----//
export default class SelectedSeatInfo extends React.Component {
	constructor(props, context) {
	    super(props, context);
	}
	displayPassenger() {
		const { flight_groups, selected_seat, flight, index_group, price, dispatch, t } = this.props;
		let travelers = flight.group_passenger ? flight.group_passenger.travelers_list : flight_groups[index_group].travelers;
		if (travelers.length < 3) {
			return (
				<div className={ "passenger-choice text-center mt20" } >
					{
						travelers.map((traveler, traveler_index) => {
							return (
								<label key={traveler_index} className={ "flight-search-checkbox" }>{ traveler.first_name + " " + traveler.last_name }
								    <input type={ "checkbox" } checked={ traveler_index ===  (selected_seat ? selected_seat.seat : false)}
								        value={traveler_index} onChange={() => {dispatch(SelectedSeat(traveler_index, price))} }/>
								    <span className={ "flight-search-check-mark" }/>
								</label>
							)
						})
					}
				</div>
			)
		}
		else {
			return (
				<div className={ "passenger-choice mt20 mb20 text-center" }>
					<select className={ "passenger-select ft-basic-input" } onChange={(e) => {e.target.value !== "" ? dispatch(SelectedSeat(e.target.value, price)) : ""} }>
						<option value="">{t("flight_search.error.choose_traveler")}</option>
						{
							travelers.map((traveler, traveler_index) => {
								return (
									<option value={traveler_index} key={traveler_index}>
										{traveler.first_name + " " + traveler.last_name }
									</option>
								);
							})
						}
					</select>
				</div>
			)
		}
	}
	validateSeat() {
		const { flight_groups, flight, index_group, selected_seat, flight_index, outbound_index, leg_index, seat, price, row_index, seat_data, seat_index, dispatch, t } = this.props;
		const location = window.location;
		let traveler = null;
		if (selected_seat !== null) {
			console.log('seat_data:', seat_data);
			console.log('selected_seat:', selected_seat);
			traveler = flight.group_passenger ? flight.group_passenger.travelers_list[selected_seat.seat].id : flight_groups[index_group].travelers[selected_seat.seat].id;
			if (location.pathname.includes("cart")) {
				dispatch(AddCartPassengerSeat(flight_index, traveler, leg_index, outbound_index, seat, price));
			}
			else {
				dispatch(AddPassengerSeat(flight_index, traveler, leg_index, outbound_index, seat, price));
			}
			dispatch(SelectedSeat(null, null));
			dispatch(ShowPopOver(false, seat_data.tab, row_index, seat_index));
			dispatch(SetConfirmation(false, null));
		}
		else
			Notify(t("flight_search.error.choose_traveler"), {status: 'warning', pos: 'top-center'});
	}
	render () {
		const { seat, seat_data, row_index, seat_index, dispatch, t } = this.props;
		let settings = {
			arrowOffsetLeft: this.props.arrowOffsetLeft,
			arrowOffsetTop: this.props.arrowOffsetTop,
			className: this.props.className,
			placement: this.props.placement,
			positionLeft: this.props.positionLeft,
			positionTop: this.props.positionTop,
			style: this.props.style,
		};
		return (
			  <Popover id={"popover-positioned-top"} {...settings}>
			  	<div>
			  		<div className={ "selected-seat-info-title row" }>
			  			<div className={ "col-md-4" }>
			  				<img src="/Img/flightSeatMap/seat_cabin_map.png"/>
			  			</div>
			  			<div className={ "seat-select col-md-7" }>
			  				<div className={ "row" }>
			  					<div className={ "col-md-12" }>
			  						{t("flight_search.seat")}
			  					</div>
			  					<div className={ "col-md-12" }>
			  						{seat}
			  					</div>
			  				</div>
			  			</div>
			  			<i className={ "icon-099-delete delete popover-seat-delete" } onClick={() => { dispatch(ShowPopOver(false, seat_data.tab, row_index, seat_index)) }}></i>
			  		</div>
			  		<div>
				  		<div className={ "selected-seat-info-passenger" }>{t("flight_search.choose_this_seat_for")}</div>
				  		{this.displayPassenger()}
			  		</div>
			  		<div className={ "confimation-button text-center" }>
			  			<button className={"btn-orange uppercase mt20 mb20"} onClick={() => {this.validateSeat()}}>{t("flight_search.select")}</button>
			  		</div>
			  	</div>
			  </Popover>
		)		
	}
}