import React, { Fragment, useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from "@material-ui/core/Grid";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBefore from '@material-ui/icons/NavigateBefore';

import RenderStation  from './RenderStation';

const useStyles = makeStyles({
    checked: {
        fill: "black"
    },
    dialogActions: {
        padding: 24
    },
    underline: {
        textDecoration: 'underline'
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    checkbox: {
        fontSize: "2rem"
    },
    capitalized: {
        textTransform: "capitalize"
    }
});

const MobileRenderStation = ({mobileStation, setMobileStation, tab_index, handleChange, stations_arr, handleToggle, setHovered, checked, checked_return, nextTabRef, contractRef, handleContract, setTab}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const handleClose = () => {
		setMobileStation(false);
	} 
	return (
    	<Dialog open={ mobileStation } onClose={ handleClose } fullWidth fullScreen={ fullScreen } maxWidth={ "md" }>
    	    <DialogTitle onClose={ handleClose }>
    	        <Grid container justify={ "space-between" } alignItems={ "center" }>
                    <Grid item>
                        <IconButton edge={ "start" } onClick={ handleClose }><NavigateBefore/></IconButton>
                    </Grid>
    	        </Grid>
    	    </DialogTitle>
	        <DialogContent>
      			<RenderStation tab_index={tab_index} handleChange={handleChange} stations_arr={stations_arr} handleToggle={handleToggle} setHovered={setHovered} checked={checked} checked_return={checked_return} nextTabRef={nextTabRef} contractRef={contractRef} handleContract={handleContract} setTab={setTab}/>
	        </DialogContent>
      	</Dialog>
	)
}
export default React.memo(MobileRenderStation);