import { setTotalPrice } from "../../../Actions/Summary";
import GetOutOfCart from "./GetOutOfCart";
import GetTotalPrices from "./GetTotalPrices";

export default (itinerary, flight_cart, hotel_cart, manual_cart, poi_cart, cars_cart, transfers_cart, selectedProducts, trip_info, dispatch) => {
    let tmp_flights = [];
    let tmp_hotels = [];
    let tmp_poi = [];
    let tmp_cars = [];
    let tmp_manual = [];
    let tmp_transfers = [];
    let hidden_product = [];
    let out_of_cart = GetOutOfCart(itinerary, flight_cart, hotel_cart, manual_cart, poi_cart, cars_cart, transfers_cart);
    if ( Object.keys(selectedProducts).length !== 0) {
        Object.keys(selectedProducts).map((key) =>{
            selectedProducts[key].map((product) => {
                product.map((id) => {
                    flight_cart.map((flight) => {
                        if (flight.id === id) {
                            tmp_flights.push(flight);
                        }
                    });
                    hotel_cart.map((hotel) => {
                        if (hotel.id === id) {
                            tmp_hotels.push(hotel);
                        }
                    });
                    poi_cart.map((poi) => {
                        if (poi.id === id) {
                            tmp_poi.push(poi);
                        }
                    });
                    cars_cart.map((car) => {
                        if (car.id === id) {
                            tmp_cars.push(car);
                        }
                    });
                    manual_cart.map((manual) => {
                        if (manual.id === id) {
                            tmp_manual.push(manual);
                        }
                    });
                    transfers_cart.map((tranfer) => {
                        if (tranfer.id === id) {
                            tmp_transfers.push(tranfer);
                        }
                    });
                });
            });
        });
    }
    else {
        flight_cart.map((flight) => {
            if (!flight.is_optional) {
                tmp_flights.push(flight);
            }
        });
        hotel_cart.map((hotel) => {
            if (!hotel.is_optional) {
                tmp_hotels.push(hotel);
            }
        });
        poi_cart.map((poi) => {
            if (!poi.is_optional) {
                tmp_poi.push(poi);
            }
        });
        cars_cart.map((car) => {
            if (!car.is_optional) {
                tmp_cars.push(car);
            }
        });
        manual_cart.map((manual) => {
            if (!manual.is_optional) {
                tmp_manual.push(manual);
            }
        });
        transfers_cart.map((transfer) => {
            if (!transfer.is_optional) {
                tmp_transfers.push(transfer);
            }
        });
    }
    // let total_price = GetTotalPrices([...tmp_flights], [...tmp_hotels], [...tmp_manual], [...tmp_poi], [...tmp_cars], [...tmp_transfers], trip_info, []);
    // console.log('tmp_flights 1 : ', tmp_flights);
    // console.log('tmp_hotels 1 : ', tmp_hotels);
    // console.log('tmp_manual 1 : ', tmp_manual);
    // console.log('tmp_poi 1 : ', tmp_poi);
    // console.log('tmp_cars 1 : ', tmp_cars);
    // console.log('tmp_transfers 1 : ', tmp_transfers);
    // console.log('total_price 1 : ', total_price);
    tmp_flights = tmp_flights.concat(out_of_cart.flights);
    tmp_hotels = tmp_hotels.concat(out_of_cart.hotels);
    tmp_manual = tmp_manual.concat(out_of_cart.manuals);
    tmp_poi = tmp_poi.concat(out_of_cart.poi);
    tmp_cars = tmp_cars.concat(out_of_cart.cars);
    tmp_transfers = tmp_transfers.concat(out_of_cart.transfers);
    hidden_product = out_of_cart.hidden_product;


    tmp_flights = tmp_flights.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
    )
    tmp_hotels = tmp_hotels.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
    )
    tmp_manual = tmp_manual.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
    )
    tmp_poi = tmp_poi.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
    )
    tmp_cars = tmp_cars.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
    )
    tmp_transfers = tmp_transfers.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
    )
    hidden_product = hidden_product.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
    )
    let total_price = GetTotalPrices(tmp_flights, tmp_hotels, tmp_manual, tmp_poi, tmp_cars, tmp_transfers, trip_info, hidden_product);
    dispatch(setTotalPrice(total_price, out_of_cart));
}