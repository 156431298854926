export function SetUsers(users) {
    return {
        type: "CRM_USERS",
        payload: users
    };
}

export function SetSelectedNote(note) {
    return {
        type: "CRM_SELECTED_NOTE",
        payload: {
            note: note
        }
    };
}

export function SetSelectedUser(user) {
    return {
        type: "CRM_SELECTED_USER",
        payload: user
    };
}

export function SetSelectedDemand(demand) {
    return {
        type: "CRM_SELECTED_DEMAND",
        payload: demand
    };
}

export function SetActiveUserNotes(user_id, notes) {
    return {
        type: "CRM_ACTIVE_USER_NOTES",
        payload: { user_id, notes }
    };
}

export function SetSelectedEmail(email) {
    return {
        type: "CRM_SELECTED_EMAIL",
        payload: email
    };
}

export function SetSelectedRow(row) {
    return {
        type: "CRM_SELECTED_ROW",
        payload: row
    };
}

export function SetLastPage(page) {
    return {
        type: "CRM_LAST_PAGE",
        payload: page
    };
}

export function SetCountries(countries) {
    return {
        type: "CRM_COUNTRIES",
        payload: countries
    };
}

export function SetSelectedContactType(type) {
    return {
        type: "CRM_SELECTED_CONTACT_TYPE",
        payload: type
    };
}
