import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete"

import Transition from "../../Common/Transition";
import SelectLuggage from "./SelectLuggage";

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    uppercase: {
        textTransform: "uppercase"
    },
    textCenter: {
        textAlign: "center"
    },
    spacerTop: {
        paddingTop: 24
    },
    spacerBottom: {
        paddingBottom: 16
    }
}));

const SelectLuggageModal = ({ openModal, setOpenModal, luggageOptions, tempPaidOptions, setTempPaidOptions, key_segment, outbound_index, traveler, traveler_index, optionsLevels, setOptionsLevels, DeleteAllOptions, checkColumns }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const closeModal = () => {
        let temp_open_modal = openModal.slice();
        temp_open_modal[outbound_index][key_segment][traveler.id] = false;
        setOpenModal(temp_open_modal);
    }

    return (
        <Dialog open={openModal[outbound_index][key_segment][traveler.id]} fullScreen TransitionComponent={Transition}>
            <DialogTitle>
                <Grid container alignItems="center" justify="flex-start">
                    <Grid item xs={1}>
                        <IconButton size="small" onClick={closeModal}>
                            <NavigateBefore />
                        </IconButton>
                    </Grid>
                    <Grid item xs={10} className={`${classes.genericText} ${classes.textCenter} ${classes.fontWeight900} ${classes.fontSize16}`}>
                        { t("flight.add_luggage") }
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid>
                    <Grid className={`${classes.genericText} ${classes.textCenter} ${classes.spacerBottom} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                        {  t("flight_search.add_optional_luggage") + " " + 1 }
                    </Grid>
                    <SelectLuggage luggageOptions={luggageOptions} tempPaidOptions={tempPaidOptions} setTempPaidOptions={setTempPaidOptions} key_segment={key_segment} outbound_index={outbound_index} traveler={traveler} traveler_index={traveler_index} optionsLevels={optionsLevels} setOptionsLevels={setOptionsLevels} luggage_index={0} checkColumns={checkColumns} />
                </Grid>
                {
                    tempPaidOptions[outbound_index][key_segment][traveler.id].length > 1 && (
                    <Grid className={classes.spacerTop}>
                        <Grid className={`${classes.genericText} ${classes.textCenter} ${classes.spacerBottom} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                            {  t("flight_search.add_optional_luggage") + " " + 2 }
                        </Grid>
                        <SelectLuggage luggageOptions={luggageOptions} tempPaidOptions={tempPaidOptions} setTempPaidOptions={setTempPaidOptions} key_segment={key_segment} outbound_index={outbound_index} traveler={traveler} traveler_index={traveler_index} optionsLevels={optionsLevels} setOptionsLevels={setOptionsLevels} luggage_index={1} checkColumns={checkColumns} />
                    </Grid>
                    )
                }
                {
                    tempPaidOptions[outbound_index][key_segment][traveler.id].length > 2 && (
                    <Grid className={classes.spacerTop}>
                        <Grid className={`${classes.genericText} ${classes.textCenter} ${classes.spacerBottom} ${classes.fontWeight900} ${classes.fontSize12} ${classes.uppercase}`}>
                            {  t("flight_search.add_optional_luggage") + " " + 3 }
                        </Grid>
                        <SelectLuggage luggageOptions={luggageOptions} tempPaidOptions={tempPaidOptions} setTempPaidOptions={setTempPaidOptions} key_segment={key_segment} outbound_index={outbound_index} traveler={traveler} traveler_index={traveler_index} optionsLevels={optionsLevels} setOptionsLevels={setOptionsLevels} luggage_index={2} checkColumns={checkColumns} />
                    </Grid>
                    )
                }
                {
                    tempPaidOptions[outbound_index][key_segment][traveler.id].length > 1 && (
                        <Grid className={`${classes.spacerTop} ${classes.textCenter}`}>
                            <Tooltip title={t("flight_search.del_all_luggage")}>
                                <IconButton size="small" onClick={() => DeleteAllOptions(outbound_index, key_segment, traveler.id)}>
                                    <DeleteIcon style={{ color: '#E6592F' }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )
                }
            </DialogContent>
        </Dialog>
    )
}

export default React.memo(SelectLuggageModal);