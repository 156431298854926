export function AddToCart(cart) {
    return {
        type: "TRANSFERS_ADD_TO_CART",
        payload: {
            cart: cart
        }
    }
}
export function SetTransfersCart(cart) {
    return {
        type: "TRANSFERS_SET_CART",
        payload: {
            cart: cart
        }
    }
}
export function ConcatTransfersCart(cart) {
    return {
        type: "TRANSFERS_CONCAT_CART",
        payload: {
            cart: cart
        }
    }
}
export function RemoveTransfersFromCart(transfers_index)  {
    return {
        type: "TREANSFERS_REMOVE_CART",
        payload: {
            transfers_index: transfers_index
        }
    }
}

export function EditTransfersCartById(transfer) {
    return {
        type: "TRANSFER_EDIT_CART_BY_ID",
        payload: transfer
    }
}

export function EditTransfersCart(item, index) {
    return {
        type: "TRANSFERS_EDIT_CART",
        payload: {
            item: item,
            index: index
        }
    }
}
export function EditTransfersCartMargin(edit, index) {
    return {
        type: "TRANSFERS_SET_MANUAL_MARGIN_EDIT",
        payload: {
            edit: edit,
            index: index
        }
    }
}
export function SetDateAndGroup(date, group) {
    return {
        type: "TRANSFERS_SET_DATE_AND_GROUP",
        payload: {
            date: date,
            group: group
        }
    }
}
export function FlushTerminals(){
    return{
        type: "TRANSFERS_FLUSH_TERMINALS",
        payload: null
    }
}
export function AddPort(port){
    return {
        type: "TRANSFERS_ADD_PORT",
        payload: {
            port: port
        }
    }
}
export function AddStation(station){
    return {
        type: "TRANSFERS_ADD_STATION",
        payload: {
            station: station
        }
    }
}
export function SetPortTimeWarning(port_time_warning){
    return {
        type: "TRANSFERS_SET_PORT_TIME_WARNING",
        payload: {
            port_time_warning: port_time_warning
        }
    }
}