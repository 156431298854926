import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useProductDays } from "./productDays";
import { sortItinerary } from "../../Itinerary/utils/sortItinerary";
import { AppState } from "../../../Reducers/Reducers";

export function useTripDays(): number[] {
    const itineraryList = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const itinerary = useMemo(() => {
        return itineraryList.filter((item) => {
            return item.step_type === 'STEP';
        }).sort(sortItinerary);
    }, [itineraryList]);
    const getDays = useProductDays();
    return getDays(
        itinerary[0]?.start_date ?? '',
        itinerary[itinerary.length - 1]?.end_date ?? ''
    );
}
