//---- Dependencies ----//
import React, { Fragment } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";

const useStyles = makeStyles({
    destinationArrow: {
        verticalAlign: "middle"
    },
    listPadding: {
        paddingLeft: 0
    },
    mt15: {
        marginTop: 15
    },
    mb15: {
        marginBottom: 15
    }
});

const ReloadCartAccommodation = ({ranked}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const update_infos = useSelector(state => state.cart.update_infos);
    const accommodation_cart = useSelector(state => state.accommodation.cart);
    const onUpdateNights = (key, id) => (e) => {
        dispatch({type: "RECALC_UPDATE_NIGHTS", payload: {key: key, id: id, nights: e.target.value}});
    };
    return (
        <Fragment>
            <h5 className={ classes.mt15 }>{ ranked }) { t('itinerary.cart_reload_accommodations') }</h5>
            <Table className={ classes.mb15 }>
                <TableHead>
                    <TableRow>
                        <TableCell>{ t("accommodation_list.accommodation") }</TableCell>
                        <TableCell>{ t("accommodation.start_date") }</TableCell>
                        <TableCell>{ t("accommodation.end_date") }</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.keys(update_infos.accommodation_step).map((key) => {
                            let remaning_days = moment.utc(update_infos.accommodation_step[key].step_end_date).diff(moment.utc(update_infos.accommodation_step[key].step_start_date), "d");
                            update_infos.accommodation_step[key].rooms.map((room) => {
                                remaning_days -= moment.utc(room.proposed_end_date).diff(moment.utc(room.proposed_start_date), 'd');
                            });
                            return (
                                <Fragment key={ `reacalc-cart-${key}` }>
                                    {
                                        update_infos.accommodation_step[key].rooms.map((room) => {
                                            const hotel = accommodation_cart.find((accommodation) => accommodation.id === room.id);
                                            const room_diff = moment.utc(room.proposed_end_date).diff(moment.utc(room.proposed_start_date), 'd');
                                            let options = [];
                                            for (let i = 1; i < room.old_duration && remaning_days >= i - room_diff; i++) {
                                                options.push(
                                                    <MenuItem key={ `accommodation-option-${i - room.old_duration}` } value={ i }>
                                                        { i - room.old_duration } { t('global.nights') }
                                                    </MenuItem>
                                                );
                                            }
                                            if (room_diff >= room.old_duration || remaning_days >= room.old_duration - room_diff) {
                                                options.push(
                                                    <MenuItem key={ `accommodation-option-${room.old_duration}` } value={ room.old_duration }>
                                                        { t('itinerary.cart_reload_no_change_accommodation') }
                                                    </MenuItem>
                                                );
                                            }
                                            for (let i = 1; i <= remaning_days - room.old_duration + room_diff; i++) {
                                                options.push(
                                                    <MenuItem key={ `accommodation-option-${i + room.old_duration}` } value={ i + room.old_duration }>
                                                        +{ i } { t('global.nights') }
                                                    </MenuItem>
                                                );
                                            }
                                            return (
                                                <TableRow key={ `reacalc-cart-${room.id}` }>
                                                    <TableCell component="th" scope="row">
                                                        { hotel.hotel[0].name }
                                                        { room_diff === 0 && t('itinerary.cart_reload_no_room_diff') }
                                                    </TableCell>
                                                    <TableCell>
                                                        { moment.utc(room.proposed_start_date).format("DD/MM/YYYY") }
                                                    </TableCell>
                                                    <TableCell>
                                                        { moment.utc(room.proposed_end_date).format("DD/MM/YYYY") }
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormControl variant="outlined">
                                                            <InputLabel id={ `accommodation-nights-select-label-${room.id}` }>{ t('global.nights') }</InputLabel>
                                                            <Select
                                                                labelId={ `accommodation-nights-select-label-${room.id}` }
                                                                id={ `accommodation-nights-select-${room.id}` }
                                                                value={ room.deletion ? "DELETE" : room_diff }
                                                                onChange={ onUpdateNights(key, room.id) }
                                                                label={ t('global.nights') }
                                                            >
                                                                <MenuItem value={ "DELETE" }>{ t('itinerary.cart_reload_delete_accommodation') }</MenuItem>
                                                                { options }
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </Fragment>
                            );
                        })
                    }
                </TableBody>
            </Table>
            <br/>
        </Fragment>
    );
};

export default ReloadCartAccommodation;