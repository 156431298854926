//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";
import { useSnackbar } from "notistack";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import FlightIcon from '@material-ui/icons/Flight';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import WorkIcon from '@material-ui/icons/Work';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import RenderFlightLegDetail from './RenderFlightLegDetail';
import LuggageByPax from '../../FlightMaterial/FlightSearch/LuggageByPax';
import CountAgePaxType from '../../FlightMaterial/Functions/CountAgePaxType';
import LuggageIcon from '../../FlightMaterial/FlightSearch/LuggageIcon';
import RenderFlightLegDetailMobile from './RenderFlightLegDetailMobile';
import PaidLuggageByPax from '../../FlightMaterial/FlightSearch/PaidLuggageByPax';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    hoverContainer: {
        "&:hover": {
            borderLeft: '6px solid #E6592F'
        }
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 30
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 19px)',
        margin: '0 4px',
        left: '-16px'
    },
    font9: {
        fontSize: 9
    },
    font11: {
        fontSize: 11
    },
    font12: {
        fontSize: 12
    },
    font14: {
        fontSize: 14
    },
    font16: {
        fontSize: 16
    },
    font18: {
        fontSize: 18
    },
    bold: {
        fontWeight: "bold"
    },
    relative: {
        position: 'relative',
        textAlign: 'center'
    },
    noBaggage: {
        color: 'gray',
        fontSize: 25,
    },
    baggage: {
        color: '#E6592F',
        fontSize: 25
    },
    baggageNumber: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white'
    },
    underline: {
        textDecoration: "underline"
    },
    pointer: {
        cursor: "pointer"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    luggageIcon: {
        fontSize: 24,
        color: '#E6592F'
    },
    luggageIconSmall: {
        fontSize: 20,
        color: '#E6592F'
    },
    fontWeight900: {
        fontWeight: 900
    },
    spacer: {
        padding: "8px 0px"
    },
    filterButton:{
        border: "1.03872px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: 31.1617,
        padding: "8px 12px"
    },
    filterButtonSmall:{
        border: "1.03872px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: 31.1617
    },
    paddingBottom10: {
        paddingBottom: 10
    }
}));

const FlightSummary = ({outbound, flight, type, hasOption, travelFlight, position, selectedProducts, setSelectedProducts, step_index, from, mainPrice, otherProduct}) => {
    const classes = useStyles();
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
	const { enqueueSnackbar } = useSnackbar();
    const small = true;
    const trip_info = useSelector(state => state.summary.trip_info);
    const currency_list = useSelector(state => state.base.currency_list);

    const [showDetails, setShowDetails] = useState(false);
    const [carryOnLuggages, setCarryOnLuggages] = useState(0);
    const [checkLuggages, setCheckLuggages] = useState(0);
    const [carryOnLuggagesPaid, setCarryOnLuggagesPaid] = useState(0);
    const [checkLuggagesPaid, setCheckLuggagesPaid] = useState(0);
    const [paidOptions, setPaidOptions] = useState({});
    const [travelers, setTravelers] = useState([]);
    const [adult, setAdult] = useState([]);
    const [children, setChildren] = useState([]);

    const travelers_obj = CountAgePaxType(flight.group_passenger.travelers_list, trip_info.end_date);
    useEffect(() => {
        let temp_carry_on = 0;
        let temp_check = 0
        let paid_carry_on = 0;
        let paid_check = 0;
        let paid_options = {};
        if (outbound.legs[0].baggage !== undefined){
            outbound.legs[0].baggage.map((luggage) => {
                if(luggage.is_carry_on){
                    temp_carry_on += luggage.quantity;
                }else{
                    temp_check += luggage.quantity;
                }
            })
        }else{
            temp_check = outbound.legs[0].baggage_allowance.number_of_pieces;
        }
        if (outbound.legs[0].paid_options !== undefined) {
            outbound.legs[0].paid_options.map((luggage) => {
                if (luggage.option_type === "Baggage") {
                    if (paid_options[luggage.traveler] === undefined) {
                        paid_options[luggage.traveler] = [];
                    }
                    paid_options[luggage.traveler].push(luggage);
                }
            })
            let paid_options_array = Object.keys(paid_options);
            paid_options_array.length > 0 && paid_options[paid_options_array[0]].map((luggage) => {
                if (luggage.baggage_is_carry_on){
                    paid_carry_on += luggage.quantity;
                } else {
                    paid_check += luggage.quantity;
                }
            });
        }
        let numero_adult = 1;
        let numero_child = 1;
        let tmp_adult = [];
        let tmp_child = [];
        let new_travelers = [];
        flight.group_passenger.travelers_list.map((traveler) => {
            let tmp_traveler = Object.assign({}, traveler);
            tmp_traveler.default_name = false;
            if (tmp_traveler.first_name === t('global.first_name') && tmp_traveler.last_name === t('global.last_name')) {
                tmp_traveler.default_name = true;
            }
            if (moment.utc(trip_info.end_date).diff(moment.utc(tmp_traveler.birth_date), "y") >= 18) {
                tmp_traveler.age_label = t("flight_search.adult") + " " + numero_adult;
                numero_adult += 1;
                tmp_adult.push(tmp_traveler);
            }
            else {
                tmp_traveler.age_label = t("flight_search.child") + " " + numero_child;
                numero_child += 1;
                tmp_child.push(tmp_traveler);
            }
            new_travelers.push(tmp_traveler);
        });
        setTravelers(new_travelers);
        setAdult(tmp_adult);
        setChildren(tmp_child);
        setCarryOnLuggages(temp_carry_on);
        setCheckLuggages(temp_check);
        setCarryOnLuggagesPaid(paid_carry_on);
        setCheckLuggagesPaid(paid_check);
        setPaidOptions(paid_options);
    }, [])

    const variantIsChecked = (product) => {
        if (position !== "step") {
            return selectedProducts.mainFlights[step_index].includes(product.id);
        }
        else {
            return selectedProducts.step_flight[step_index].includes(product.id);
        }
    }
    const getStopover = () => {
        let stopover_iata = [];
        for (let i = 1; i < outbound.legs.length; i++) {
            if (outbound.legs[i].origin !== undefined && outbound.legs[i].origin !== null) {
                stopover_iata.push (outbound.legs[i].origin.airport_code)
            }
        }
        return stopover_iata;
    }
    const getClientPrice = (prices) => {
        let current_price = prices.find((el) => {
            return !el.is_commissioned && !el.is_network && !el.is_surcom && !el.master_price;
        });
        if (current_price === undefined) {
            current_price = prices.find((el) => {
                return !el.is_commissioned && !el.is_network && !el.is_surcom && el.master_price;
            });
        }
        return current_price;
    }
    const handleChange = () => {
        let tmp_selectedProducts = Object.assign({}, selectedProducts);
        let tmp_flights = position !== "step" ? tmp_selectedProducts.mainFlights.slice() : tmp_selectedProducts.step_flight.slice();
		if (travelFlight.length === 0 && tmp_flights[step_index].includes(flight.id)) {
			tmp_flights[step_index] = [];
		} else {
            if (position === "top") {
                enqueueSnackbar(t("summary.changed_flight_return"), { variant: "warning", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                } });
            } else if (position === "bottom") {
                enqueueSnackbar(t("summary.changed_flight_departure"), { variant: "warning", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                } });
            }
            tmp_flights[step_index] = [flight.id];
		}
        if (position !== "step") {
            tmp_selectedProducts.mainFlights = tmp_flights;
        }
        else {
            tmp_selectedProducts.step_flight = tmp_flights;
        }
        setSelectedProducts(tmp_selectedProducts);
    }
    const OpenDetails = () => {
        setShowDetails(!showDetails);
    }
    const stopover_iata = getStopover();
    const last_index = outbound.legs.length - 1;
    let price = parseFloat(getClientPrice(flight.prices).selling_price);
    if (type === "option") {
		price = price - mainPrice;
	}
    let selling_currency = currency_list.find((currency) => currency.id === getClientPrice(flight.prices).selling_currency);
    return (
        <Grid container item spacing={2} direction={"row"} alignItems={"center"} justify={"space-between"} className={clsx(classes.font11, {[classes.paddingBottom10]: type === "main"})} style={{marginBottom: from !== "tripSummary" && flight.is_optional ? 20 : 0}}>
            {
                from !== "tripSummary" && otherProduct === undefined && (trip_info.status === "TO_UPDATED" || trip_info.status === "ONGOING" || trip_info.status === "VALIDATED" || trip_info.status === "TOBOOK_VERDIE") && (
                    <Grid item>
                        <div className={ classes.checkboxContainer }>
                            <Checkbox
                                className={ classes.checkbox }
                                edge={ 'start' }
                                checked={ variantIsChecked(flight) }
                                tabIndex={ -1 }
                                disableRipple
                                onChange={ handleChange }
                                icon={<CircleUnchecked />}
                                checkedIcon={<RadioButtonCheckedIcon  />}
                            />
                        </div>
                    </Grid>
                )
            }
            <Grid item>
                <FlightIcon/>
            </Grid>
            <Grid item>
                <img src={ `https://storagefacilitatrip.blob.core.windows.net/iata-company/${outbound.legs[0].marketing_airline.airline_code}.png` } alt={ 'company logo' } width={ '100%' } style={{display: "block", marginLeft: "auto", marginRight: "auto"}}/>
            </Grid>
            <Grid item xs>
                <Grid container direction={'column'} alignItems={"center"} justify={"center"}>
                    <Grid item>
                        { outbound.legs[0].marketing_airline.airline_code }-{ outbound.legs[0].flight_number }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={"column"} className={classes.bold} alignItems={"center"}>
                    <Grid item>
                        {outbound.legs[0].origin !== null ? outbound.legs[0].origin.iata_city.name : outbound.legs[0].origin_station.iata_city.name}
                    </Grid>
                    <Grid item>
                        {moment.utc(outbound.legs[0].departure_time).format("HH[H]mm")}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <ArrowRightAltIcon/>
            </Grid>
            <Grid item>
                <Grid container direction={"column"} className={classes.bold} alignItems={"center"}>
                    <Grid item>
                        {outbound.legs[last_index].destination !== null ? outbound.legs[last_index].destination.iata_city.name : outbound.legs[last_index].destination_station.iata_city.name}
                    </Grid>
                    <Grid item>
                        {moment.utc(outbound.legs[last_index].arrival_time).format("HH[H]mm")}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.bold} style={{textAlign: "center"}} xs>
                {t("flight_search.cabin_class." + (outbound.legs[0].class_name).toLowerCase())}
            </Grid>
            {
                outbound.legs.length === 1 ? (
                    <Grid item xs style={{textAlign: "center", textTransform: "capitalize"}}>
                        {t("flight_search.direct_flight")}
                    </Grid>
                ) : (
                    <Grid item xs style={{textAlign: "center"}}>
                        <Grid container direction={"column"} alignItems={"center"} justify={"center"}>
                            {/* <Grid>{outbound.legs.length + " " + (outbound.legs.length >= 2 ? t("flight_search.stopovers") : t("flight_search.stopover")) }</Grid> */}
                            <Grid>{outbound.legs.length === 1 ? t('flight_search.direct_flight') : outbound.legs.length === 2 ? `1 ${t('flight_search.stopover')}` : `${outbound.legs.length} ${t('flight_search.stopovers')}`}</Grid>
                            <Grid item className={classes.font9}>{stopover_iata.join()}</Grid>
                        </Grid>
                    </Grid>
                )
            }
            <Grid item xs className={classes.relative}>
                <WorkIcon className={ `${(outbound.legs[0].baggage.length === 0 && (checkLuggages + carryOnLuggages + checkLuggagesPaid + carryOnLuggagesPaid === 0)) ? classes.noBaggage : classes.baggage}` }/>
                <Typography className={ `${classes.baggageNumber} ${classes.font11}` }>{ carryOnLuggages + checkLuggages + checkLuggagesPaid + carryOnLuggagesPaid !== 0 ? carryOnLuggages + checkLuggages + checkLuggagesPaid + carryOnLuggagesPaid : outbound.legs[0].baggage.length }</Typography>
            </Grid>
            <Grid item xs className={classes.relative}>
                <Button size="small" className={(isTablet || small) ? classes.filterButtonSmall : classes.filterButton} onClick={OpenDetails}>
                    <div className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.font9 : classes.font12} ${classes.uppercase}`} style={{ paddingLeft: 8 }}>
                        { t("global.detail") }
                    </div>
                    {
                        showDetails ? <ArrowDropUpIcon className={classes.genericText} /> : <ArrowDropDownIcon className={classes.genericText} />
                    }
                </Button>
                {
                    type === "optional" && (
                        <div style={{position: "absolute", right: 20, bottom: "-20px"}}>
                            <Typography className={`${classes.bold} ${classes.font14}`}>{price > 0 ? "+ " : "- " }{Intl.NumberFormat(i18n.language, { style: "currency", currency: selling_currency.iso_code, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(Math.abs(price)) }</Typography>
                        </div>
                    )
                }
            </Grid>
            {
                position !== "step" && (
                    <Grid item></Grid>
                )
            }
            {
                showDetails && (
                    <Grid item className={ isMobile ? "" : ((isTablet || small) ? classes.genericPaddingSmall : classes.genericPadding)} style={{width: "100%"}}>
                        <Grid container alignItems={isHandHeld ? "stretch" : "baseline"} justify="flex-start" spacing={small ? 1 : 2} className={classes.spacer} direction={isHandHeld ? "column" : "row" }>
                            <Grid item xs={isHandHeld ? 12 : 7}>
                            {
                                outbound.legs.map((leg, leg_index) => {
                                    if (!isMobile) {
                                        return (
                                            <RenderFlightLegDetail
                                                key={leg_index}
                                                leg={leg}
                                                leg_index={leg_index}
                                                max_index={outbound.legs.length - 1}
                                                stopover_duration={leg_index < outbound.stopover_duration.length ? outbound.stopover_duration[leg_index] : null}
                                                duration_days={outbound.duration_days}
                                                small={true}
                                            />
                                        )
                                    } else {
                                        return (
                                            <RenderFlightLegDetailMobile
                                                key={leg_index}
                                                leg={leg}
                                                leg_index={leg_index}
                                                max_index={outbound.legs.length - 1}
                                                stopover_duration={leg_index < outbound.stopover_duration.length ? outbound.stopover_duration[leg_index] : null}
                                                duration_days={outbound.duration_days}
                                                small={true}
                                            />
                                        )
                                    }
                                })
                            }
                            </Grid>
                            <Divider orientation={isHandHeld ? "horizontal" : "vertical"} flexItem={isHandHeld ? false : true} style={isHandHeld ? { width: "100%" } : { margin: "30px 15px 14px" }} />
                            <Grid item xs={isHandHeld ? 12 : 4}>
                                <Grid container alignItems="center" justify="center" spacing={1}>
                                    <Grid item>
                                        <LuggageIcon className={ (isTablet || small) ? classes.luggageIconSmall : classes.luggageIcon}/>
                                    </Grid>
                                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${isHandHeld ? classes.font18 : (small ? classes.font9 : classes.font16)} ${classes.uppercase}`}>
                                        { t("global.luggages") }
                                    </Grid>
                                </Grid>
                                <Grid className={classes.spacer}>
                                    <Grid className={`${classes.genericText} ${classes.bold} ${small ? classes.font9 : classes.font12} ${classes.uppercase}`}>
                                        { t("flight_search.luggage_carry_on") + " :"}
                                    </Grid>
                                    <TableContainer style={{ paddingTop: 8, overflowY: "hidden" }}>
                                        <Table>
                                            <TableBody>
                                                {
                                                    travelers_obj.ADT !== 0 && <LuggageByPax luggageNb={carryOnLuggages} luggageType={"cabin"} paxNb={travelers_obj.ADT} paxLabel={t("flight_search.adults")} small={small} />
                                                }
                                                {
                                                    travelers_obj.CNN !== 0 && <LuggageByPax luggageNb={carryOnLuggages} luggageType={"cabin"} paxNb={travelers_obj.CNN} paxLabel={t("flight_search.children")} small={small} />
                                                }
                                                {
                                                    travelers_obj.INF !== 0 && <LuggageByPax luggageNb={carryOnLuggages} luggageType={"cabin"} paxNb={travelers_obj.INF} paxLabel={t("flight_search.babies")} small={small} />
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid className={classes.spacer}>
                                    <Grid className={`${classes.genericText} ${classes.bold} ${small ? classes.font9 : classes.font12} ${classes.uppercase}`}>
                                        { t("flight_search.luggage_check") + " :" }
                                    </Grid>
                                    <TableContainer style={{ paddingTop: 8, marginLeft: isHandHeld ? "auto" : 0, marginRight: isHandHeld ? "auto" : 0, overflowY: "hidden" }}>
                                        <Table>
                                            <TableBody>
                                                {
                                                    travelers_obj.ADT !== 0 && <LuggageByPax luggageNb={checkLuggages} luggageType={"checked"} paxNb={travelers_obj.ADT} paxLabel={t("flight_search.adults")} small={small} />
                                                }
                                                {
                                                    travelers_obj.CNN !== 0 && <LuggageByPax luggageNb={checkLuggages} luggageType={"checked"} paxNb={travelers_obj.CNN} paxLabel={t("flight_search.children")} small={small} />
                                                }
                                                {
                                                    travelers_obj.INF !== 0 && <LuggageByPax luggageNb={checkLuggages} luggageType={"checked"} paxNb={travelers_obj.INF} paxLabel={t("flight_search.babies")} small={small} />
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                {
                                    carryOnLuggagesPaid !== 0 || checkLuggagesPaid !== 0 && (
                                        <Fragment>
                                            <Grid container alignItems="center" justify="center" spacing={1} style={{marginTop: 10}}>
                                                <Grid item>
                                                    <LuggageIcon className={ (isTablet || small) ? classes.luggageIconSmall : classes.luggageIcon}/>
                                                </Grid>
                                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${isHandHeld ? classes.font18 : (small ? classes.font9 : classes.font16)} ${classes.uppercase}`}>
                                                    { t("global.additional_luggages") }
                                                </Grid>
                                            </Grid>
                                            <Grid className={classes.spacer}>
                                                <Grid className={`${classes.genericText} ${classes.bold} ${small ? classes.font9 : classes.font12} ${classes.uppercase}`}>
                                                    { t("flight_search.luggage_carry_on") + " :"}
                                                </Grid>
                                                <TableContainer style={{ paddingTop: 8, overflowY: "hidden" }}>
                                                    <Table>
                                                        <TableBody>
                                                            <PaidLuggageByPax small={small} paidOptions={paidOptions} travelers={travelers}/>
                                                            {
                                                                // travelers_obj.ADT !== 0 && <LuggageByPax luggageNb={carryOnLuggagesPaid} luggageType={"cabin"} paxNb={travelers_obj.ADT} paxLabel={t("flight_search.adults")} small={small} paidOptions={paidOptions} />
                                                            }
                                                            {
                                                                // travelers_obj.CNN !== 0 && <LuggageByPax luggageNb={carryOnLuggagesPaid} luggageType={"cabin"} paxNb={travelers_obj.CNN} paxLabel={t("flight_search.children")} small={small} paidOptions={paidOptions} />
                                                            }
                                                            {
                                                                // travelers_obj.INF !== 0 && <LuggageByPax luggageNb={carryOnLuggagesPaid} luggageType={"cabin"} paxNb={travelers_obj.INF} paxLabel={t("flight_search.babies")} small={small} paidOptions={paidOptions} />
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                            {/* <Grid className={classes.spacer}>
                                                <Grid className={`${classes.genericText} ${classes.bold} ${small ? classes.font9 : classes.font12} ${classes.uppercase}`}>
                                                    { t("flight_search.luggage_check") + " :" }
                                                </Grid>
                                                <TableContainer style={{ paddingTop: 8, marginLeft: isHandHeld ? "auto" : 0, marginRight: isHandHeld ? "auto" : 0, overflowY: "hidden" }}>
                                                    <Table>
                                                        <TableBody>
                                                            {
                                                                travelers_obj.ADT !== 0 && <LuggageByPax luggageNb={checkLuggagesPaid} luggageType={"checked"} paxNb={travelers_obj.ADT} paxLabel={t("flight_search.adults")} small={small} paidOptions={paidOptions} />
                                                            }
                                                            {
                                                                travelers_obj.CNN !== 0 && <LuggageByPax luggageNb={checkLuggagesPaid} luggageType={"checked"} paxNb={travelers_obj.CNN} paxLabel={t("flight_search.children")} small={small} paidOptions={paidOptions} />
                                                            }
                                                            {
                                                                travelers_obj.INF !== 0 && <LuggageByPax luggageNb={checkLuggagesPaid} luggageType={"checked"} paxNb={travelers_obj.INF} paxLabel={t("flight_search.babies")} small={small} paidOptions={paidOptions} />
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid> */}
                                        </Fragment>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            {
                flight.custom_information !== undefined && flight.custom_information !== null && (
                    <Grid item style={{width: "100%", marginTop: 10}}>
                        <Alert variant={ 'filled' } severity={ 'info' } >
                            <AlertTitle>{ t('cart-material.note') } :</AlertTitle>
                            { flight.custom_information }
                        </Alert>
                    </Grid>
                )
            }
        </Grid>
    )
}
export default FlightSummary