import { UpdateFilters } from "../../../Actions/FlightSearch";

export default (
    flight_list,
    airlines,
    airports,
	show_all_airports,
    alliances,
    stop_over_active,
    show_all_stop_over,
    is_day_flight,
    is_night_flight,
    stop_over_airport,
	show_all_stop_over_airport,
    checked_luggage,
    stations,
    show_all_stations,
    show_all_stop_over_duration,
    providers,
    show_all_providers,
    private_price,
    public_price,
	flight_source_gds,
	flight_source_ndc,
    dispatch) => {
    let temp_checked_luggage = Object.assign({}, checked_luggage);
	let temp_airlines = airlines !== null ? airlines.slice() : null;
	let temp_alliances = alliances !== null ? alliances.slice() : null;
    let temp_stop_over_active = stop_over_active.slice();
	let temp_show_all_stop_over_duration = show_all_stop_over_duration;
	let temp_is_day_flight = is_day_flight;
	let temp_is_night_flight = is_night_flight;
	let temp_airports = Object.assign({}, airports);
	let temp_stations = Object.assign({}, stations);
	let temp_stop_over_airports = stop_over_airport !== null ? stop_over_airport.slice() : null;
	let temp_providers = providers.slice();
    let temp_private_price = private_price;
	let temp_public_price = public_price;
	let temp_flight_source_gds = flight_source_gds;
	let temp_flight_source_ndc = flight_source_ndc;

    // do not update filter if filter is active or null (=not initialized)
	// put null to relevant value to hide filter
	let update_airlines = true;
	let update_alliances = true;

    if (temp_checked_luggage.disabled){
        Object.keys(temp_checked_luggage).forEach(key => {
            if (key !== "disabled"){
                temp_checked_luggage[key] = null;
            }
        })
    }

    if (temp_airlines !== null){
		temp_airlines.map((airline) => {
			if (airline.displayed)
				update_airlines = false;
		})
	}else{
		update_airlines = false;
	}
    if (update_airlines){
        temp_airlines.map((airline) => {
            airline.displayed = null;
			airline.price = Infinity;
        })
    }

    if (temp_alliances !== null){
		temp_alliances.map((alliance) => {
			if (alliance.displayed)
				update_alliances = false;
		})
	}else{
		update_alliances = false;
	}

	if (update_alliances){
		temp_alliances.map((alliance) => {
			alliance.displayed = null;
		})
	}

    if (show_all_stop_over){
		temp_stop_over_active.map((segment_stop_over) => {
			Object.keys(segment_stop_over).forEach(key => {
				segment_stop_over[key] = null;
			})
		})
	}

    if (show_all_stop_over_duration === null || show_all_stop_over_duration){
		temp_show_all_stop_over_duration = null;
	}

    if (is_day_flight === null || !is_day_flight){
		temp_is_day_flight = null;
	}

	if (is_night_flight === null || !is_night_flight){
		temp_is_night_flight = null;
	}

    if (show_all_airports){
		Object.keys(temp_airports).forEach(key => {
			temp_airports[key].displayed = null;
		})
	}

    if (show_all_stations){
		Object.keys(temp_stations).forEach(key => {
			temp_stations[key].displayed = null;
		})
	}

    if (temp_stop_over_airports !== null && show_all_stop_over_airport){
		temp_stop_over_airports.map((segment) => {
			Object.keys(segment).forEach( key => {
				segment[key].displayed = null;
			})
		})
	}

    if (show_all_providers){
		temp_providers.map((provider) => {
			provider.displayed = null;
		})
	}

    if ((private_price === null || !private_price) && (public_price === null || !public_price)){
		temp_private_price = null;
        temp_public_price = null;
	}
    // if ((flight_source_ndc === null || !flight_source_ndc) && (flight_source_gds === null || !flight_source_gds)){
	// 	temp_flight_source_ndc = null;
    //     temp_flight_source_gds = null;
	// }
    flight_list.map((flight) => {
        // do not hide active filters
		// if (flight.flight_source === 'NDC' && flight_source_ndc === null) {
		// 	temp_flight_source_ndc = false;
		// }
		// if (flight.flight_source === 'GDS' && temp_flight_source_gds === null) {
		// 	temp_flight_source_gds = false;
		// }
		if (temp_checked_luggage.disabled){
			let nb_luggage_by_segment = [];
			flight.outbounds.map((segment) => {
				nb_luggage_by_segment.push(segment.legs[0].baggage_allowance.number_of_pieces);
			})
	
			let all_same_luggage = true;
			nb_luggage_by_segment.map((segment_nb_luggage) => {
				if (segment_nb_luggage !== nb_luggage_by_segment[0]){
					all_same_luggage = false;
				}
			})

			if (all_same_luggage){
				if (nb_luggage_by_segment[0] === 0){
					temp_checked_luggage.zero = false;
				}else if (nb_luggage_by_segment[0] === 1){
					temp_checked_luggage.one = false;
					temp_checked_luggage.included = false;
				}else if (nb_luggage_by_segment[0] === 2){
					temp_checked_luggage.two = false;
					temp_checked_luggage.included = false;
				}
			}
		}

        if (update_airlines){
			flight.outbounds.map((segment) => {
				segment.legs.map((leg) => {
					let flight_airline = temp_airlines.find(item => item.id === leg.marketing_airline);
					let flight_airline_index = temp_airlines.findIndex(item => item.id === leg.marketing_airline);
					if (flight_airline !== undefined && flight_airline_index !== undefined && flight_airline_index !== -1){
						flight_airline.displayed = false;
						if (parseFloat(flight.total_price) < flight_airline.price){
							flight_airline.price = parseFloat(flight.total_price);
						}
						temp_airlines[flight_airline_index] = flight_airline;
					}
				})
			})
		}

        if (update_alliances){
			flight.outbounds.map((segment) => {
				segment.legs.map((leg) => {
                    if (leg.alliance !== undefined){
                        let flight_alliance = temp_alliances.find(item => item.name === leg.alliance.name);
                        let flight_alliance_index =  temp_alliances.findIndex(item => item.name === leg.alliance.name);
                        if (flight_alliance !== undefined && flight_alliance_index !== undefined && flight_alliance_index !== -1){
                            flight_alliance.displayed = false;
                            temp_alliances[flight_alliance_index] = flight_alliance;
                        }
                    }
				})
			})
		}

        if (show_all_stop_over){
			flight.outbounds.map((segment, segment_index) => {
				if (segment.legs.length === 1){
					temp_stop_over_active[segment_index].none = false;
				}else if (segment.legs.length === 2){
					temp_stop_over_active[segment_index].one = false;
				}else if (segment.legs.length === 3){
					temp_stop_over_active[segment_index].two = false;
				}else if (segment.legs.length > 3){
					temp_stop_over_active[segment_index].more = false;
				}
			})
		}

        if (show_all_stop_over_duration === null || show_all_stop_over_duration){
			flight.outbounds.map((segment) => {
				if (segment.legs.length > 1){
					temp_show_all_stop_over_duration = true;
				}
			})
		}

        if (is_day_flight === null || !is_day_flight){
			flight.outbounds.map((segment) => {
				if (!segment.is_nightly){
					temp_is_day_flight = false;
				}
			})
		}
		
		if (is_night_flight === null || !is_night_flight){
			flight.outbounds.map((segment) => {
				if (segment.is_nightly){
					temp_is_night_flight = false;
				}
			})
		}

        if (show_all_airports){
			flight.outbounds.map((segment) => {
				segment.legs.map((leg) => {
					if (leg.origin_airport !== undefined){
						temp_airports[leg.origin_airport].displayed = false;
					}
					if (leg.destination_airport !== undefined){
						temp_airports[leg.destination_airport].displayed = false;
					}
				})
			})
		}

		if (show_all_stations){
			flight.outbounds.map((segment) => {
				segment.legs.map((leg) => {
					if (leg.origin_station !== undefined){
						temp_stations[leg.origin_station].displayed = false;
					}
					if (leg.destination_station !== undefined){
						temp_stations[leg.destination_station].displayed = false;
					}
				})
			})
		}

        if (temp_stop_over_airports !== null && show_all_stop_over_airport){
			flight.outbounds.map((segment, segment_index) => {
				if (segment.legs.length > 1){
					segment.legs.map((leg) => {
						if (leg.origin_airport !== undefined && temp_stop_over_airports[segment_index][leg.origin_airport] !== undefined){
							temp_stop_over_airports[segment_index][leg.origin_airport].displayed = false;
						}else if (leg.origin_station !== undefined && temp_stop_over_airports[segment_index][leg.origin_station] !== undefined){
							temp_stop_over_airports[segment_index][leg.origin_station].displayed = false;
						}
					})
				}
			})
		}

        if (show_all_providers){
			let flight_provider = providers.find(item => item.id === flight.provider_id);
			let flight_provider_index = providers.findIndex(item => item.id === flight.provider_id);
			
			if (flight_provider !== undefined && flight_provider_index !== -1){
				flight_provider.displayed = false;
				temp_providers[flight_provider_index] = flight_provider;
			}
		}

        if ((private_price === null || !private_price) && (public_price === null || !public_price)){
            if (flight.fare_type === "private" || flight.fare_type === "NEGOTIATED"){
                temp_private_price = false;
            }
            if (flight.fare_type === "public" || flight.fare_type === "PUBLISHED"){
                temp_public_price = false;
            }
			if (flight.fare_type === "all"){
				temp_public_price = false;
				temp_private_price = false;
			}
        }
    })

	dispatch(UpdateFilters(
        temp_airlines,
        temp_airports,
        temp_alliances,
        temp_checked_luggage,
        temp_is_day_flight,
		temp_is_night_flight,
        temp_private_price,
        temp_providers,
        temp_public_price,
        temp_show_all_stop_over_duration,
        temp_stations,
        temp_stop_over_active,
        temp_stop_over_airports,
		temp_flight_source_gds,
		temp_flight_source_ndc
    ));
}