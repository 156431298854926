import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";
import { useSnackbar } from "notistack";

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { SetMarginStep } from "../../../Actions/Menu";

const useRowStyles = makeStyles({
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    pointer: {
        cursor: "pointer"
    },
    paddingCell: {
        "& .mui-jss-MuiTableCell-root": {
            padding: "10px 0px 10px 16px !important",
            fontSize: 12
        }
    },
    hover: {
        "&:hover": {
            backgroundColor: '#f1f1f1'
        }
    }
});

const MarginListRow = ({ row, row_index, columns, setSelectedMargin }) => {
    const { t } = useTranslation();
    const classes = useRowStyles();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch();

    const list_currency = useSelector(store => store.base.currency_list);

    const renderField = (column, column_index) => {
        if (column === t("menu.margin.margin")) {
            return (
                <TableCell key={column_index} align={"left"}>{row.rate}</TableCell>
            );
        }
        if (column === t("global.in")) {
            let my_currency = null;
            if (row.type_rate === "FIX") {
                my_currency = list_currency.find((currency) => {
                    return currency.id === row.currency_rate.id; 
                });
            }
            return (
                <TableCell key={column_index} align={"left"}>{(my_currency !== null && my_currency !== undefined) ? my_currency.symbol : "%"}</TableCell>
            );
        }
        if (column === t("menu.margin.active")) {
            return (
                <TableCell key={column_index} align={"left"}>
                    {
                        <FiberManualRecordIcon style={{ color: row.disabled_date === null ? "green" : "red", marginRight: 5, paddingLeft: 3, fontSize: 34 }}/>
                    }
                </TableCell>
            );
        }
    };

    return (
        <TableRow key={row_index} className={ clsx(classes.hover, classes.pointer, classes.paddingCell)} onClick={ (elem) => {
            setSelectedMargin(row);
            //dispatch(SetMarginStep(3));
        }}>
            <TableCell align={"left"}>
                {row.name}
            </TableCell>
            {
                !downDesktop && columns.map((column, column_index) => {
                    return (
                        renderField(column, column_index)
                    );
                })
            }
            {
                downDesktop && (
                    <TableCell align={"left"}>
                        {
                            <FiberManualRecordIcon style={{ color: row.disabled_date === null ? "green" : "red", marginRight: 5, paddingLeft: 3, fontSize: 34 }}/>
                        }
                    </TableCell>
                )
            }
            {
                !downDesktop && (
                    <TableCell align={"left"}>
                        {row.end_date !== null ? moment(row.end_date).format("DD/MM/YYYY") : "-"}
                    </TableCell>
                )
            }
            <TableCell align={"left"}>
                {moment(row.created_date).format("DD/MM/YYYY")}
            </TableCell>
        </TableRow>
    );
};
export default React.memo(MarginListRow);
