import axios from "axios";
import memoizee from "memoizee";
import { getDestination } from "../../../Itinerary/utils/getDestination";
import { QuotationTemplate } from "../objects/quotationTemplate";

async function fetchAll(destinationId: number): Promise<QuotationTemplate | null> {
    let result: QuotationTemplate | null = null;
    let destination = await getDestination(destinationId);
    while (destination && !result) {
        result = await fetch(destination.id);
        if (!result) {
            if (destination.parent?.id) {
                destination = await getDestination(destination.parent.id);
            } else {
                destination = null;
            }
        }
    }
    return result;
}

async function fetch(destinationId: number): Promise<QuotationTemplate | null> {
    try {
        const response = await axios.get(
            `${API_HREF}client/${window.id_owner}/quotation-template/`,
            {
                params: {
                    destination: destinationId
                }
            }
        );
        return response.data;
    } catch (error: any) {
        console.error(error);
    }
    return null;
}

export const findQuotationTemplate = memoizee(
    fetchAll,
    { promise: true, primitive: true }
);
