import { SetStepInfo } from "../../../Actions/CarsSearch";

export default (cars_steps, dispatch) => {
	let copy_info_step = [];
	let step_info_tmp = [];
	cars_steps.map((step, index_step) => {
	    if (step.checked) {
	        copy_info_step.push(
	            {
	                origin: step.origin,
	                destination: step.destination,
	                origin_id: step.origin_id,
	                dest_id: step.dest_id,
	                index_step: index_step,
	                start_date_origin: step.start_date_origin,
	                end_date_origin: step.end_date_origin,
	                start_date_dest: step.start_date_dest,
	                end_date_dest: step.end_date_dest,
	                iata_airports_departure: step.iata_airports_departure,
	                iata_airports_arrival: step.iata_airports_arrival
	            }
	        );
	    }
	});
    let index_array = -1;
	for (let i = 0; i < copy_info_step.length; i++) {
	    if (i === 0 || copy_info_step[i - 1].destination !== copy_info_step[i].origin || copy_info_step[i - 1].index_step + 1 !== copy_info_step[i].index_step) {
		    index_array += 1;
	        step_info_tmp.push(
	            {
	                agency_go: null,
	                agency_return: null,
	                start_date_origin: moment.utc(copy_info_step[i].start_date_origin).format("YYYY-MM-DD"),
	                end_date_origin: moment.utc(copy_info_step[i].end_date_origin).format("YYYY-MM-DD"),
	                start_date_dest: moment.utc(copy_info_step[i].start_date_dest).format("YYYY-MM-DD"),
	                end_date_dest: moment.utc(copy_info_step[i].end_date_dest).format("YYYY-MM-DD"),
	                car_nbr: 1,
	                origin: copy_info_step[i].origin,
	                destination: copy_info_step[i].destination,
	                driver_age: ["25"],
	                origin_date: null,
	                return_date: null,
	                //hours_origin: moment.utc(copy_info_step[i].start_date_origin).format("HH:mm"),
	                origin_hours: (moment.utc(copy_info_step[i].start_date_origin).hours()) + 1 < 10 ? "0" + ((moment.utc(copy_info_step[i].start_date_origin).hours()) + 1).toString() : ((moment.utc(copy_info_step[i].start_date_origin).hours()) + 1).toString(),
	                return_hours: (moment.utc(copy_info_step[i].end_date_dest).hours()) - 3 < 10 ? "0" + ((moment.utc(copy_info_step[i].end_date_dest).hours()) - 3).toString() : ((moment.utc(copy_info_step[i].end_date_dest).hours()) - 3).toString() ,
	                origin_minutes: moment.utc(copy_info_step[i].start_date_origin).minutes() < 10 ? "0" + (moment.utc(copy_info_step[i].start_date_origin).minutes()).toString() : (moment.utc(copy_info_step[i].start_date_origin).minutes()).toString() ,
	                return_minutes: moment.utc(copy_info_step[i].end_date_dest).minutes() < 10 ? "0" + (moment.utc(copy_info_step[i].end_date_dest).minutes()).toString() : (moment.utc(copy_info_step[i].end_date_dest).minutes()).toString(),
	                focus: {
	                    origin: false,
	                    return: false
	                },
	                tmp_destination: [],
	                cars_errors: [],
	                readOnly_arrival: true,
	                readOnly_departure: true,
	                iata_airports_departure: copy_info_step[i].iata_airports_departure,
	                iata_airports_arrival: copy_info_step[i].iata_airports_arrival,
	                origin_id: copy_info_step[i].origin_id,
	                dest_id: copy_info_step[i].dest_id
	            }
	        );
	    }
	    else {
			let copy_step_info_tmp = step_info_tmp.slice();
			let tmp = [];
			copy_step_info_tmp.map((cpy, cpy_index) => {
				if (cpy_index === index_array) {
					let tmp_obj = Object.assign({}, cpy);
			        tmp_obj.tmp_destination.push(cpy.destination);
			        tmp_obj.destination = copy_info_step[i].destination;
			        tmp_obj.start_date_dest = moment.utc(copy_info_step[i].start_date_dest).format("YYYY-MM-DD");
			        tmp_obj.end_date_dest = moment.utc(copy_info_step[i].end_date_dest).format("YYYY-MM-DD");
		            tmp_obj.return_hours = (moment.utc(copy_info_step[i].end_date_dest).hours()) - 3 < 10 ? "0" + ((moment.utc(copy_info_step[i].end_date_dest).hours()) - 3).toString() : ((moment.utc(copy_info_step[i].end_date_dest).hours()) - 3).toString() ,
		            tmp_obj.return_minutes = moment.utc(copy_info_step[i].end_date_dest).minutes() < 10 ? "0" + (moment.utc(copy_info_step[i].end_date_dest).minutes()).toString() : (moment.utc(copy_info_step[i].end_date_dest).minutes()).toString(),
			        tmp_obj.return_date = null;
			        tmp_obj.iata_airports_arrival = copy_info_step[i].iata_airports_arrival;
			        tmp_obj.dest_id = copy_info_step[i].dest_id;
					cpy = tmp_obj;
				}
				tmp.push(cpy);
			})
			step_info_tmp = tmp;
	    }
	}
	dispatch(SetStepInfo(step_info_tmp));
}