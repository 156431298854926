//---- Actions ----//
import { SetItinerary } from "../../../Actions/Itinerary";
import GetCookie from "../../Common/Functions/GetCookie";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
export default (dispatch, t) => {
    const { pass_check, headers } = CheckBeforeRequest();
    $.ajax({
        method: "GET",
        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/itinerary/?token=${GetCookie("trip_token")}&shift_mode=false`,
        headers: headers,
        success: function (data) {
            console.log(data);
            dispatch(SetItinerary(data));
        }
    });
}