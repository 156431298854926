import Notify from "../../Common/Notify.js";

export default (error) => {
	console.log("error", error);
	if (error !== undefined && error !== null) {
		let text = "";
		if (error.non_field_errors !== undefined && error.non_field_errors.length > 0) {
			text = error.non_field_errors[0];
		}
		if (error.detail !== undefined && error.detail !== null) {
			text = error.detail;
		}
		if (text !== "") {
			Notify(text, {status: "danger", pos: "top-center"});
		}
	}
}