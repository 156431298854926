export default (a1, a2) => {
    if (a1.length != a2.length) {
        return false;
    } else {
        for (let a = 0; a < a1.length; ++a) {
            if (a1[a] != a2[a]) {
                return false;
            }
        }
    }
    return true;
}