import React from 'react';
import { SvgIcon } from "@mui/material";

export default (props) => {
    return (
        <SvgIcon {...props} viewBox={ "0 0 512 512" }>
            <path d="M498.82 206.607L305.394 13.18C296.895 4.68 285.594 0 273.574 0H45C20.187 0 0 20.187 0 45v422c0 24.813 20.187 45 45 45h241c5.682 0 10.875-3.21 13.417-8.292C333.65 435.24 404.99 391 481.164 391H497c8.284 0 15-6.716 15-15V238.427c0-12.02-4.681-23.32-13.18-31.82zM482 361h-.836c-43.098 0-85.471 12.164-122.539 35.177-34.306 21.299-62.39 50.844-81.685 85.823H45c-8.271 0-15-6.729-15-15V45c0-8.271 6.729-15 15-15h228.574c4.006 0 7.773 1.56 10.606 4.394L477.606 227.82c2.833 2.833 4.394 6.6 4.394 10.607V361z"/>
            <path d="M446.637 240.282L271.778 64.424A14.996 14.996 0 00261.142 60H75c-8.284 0-15 6.716-15 15v181c0 8.284 6.716 15 15 15h361c8.284 0 15-6.716 15-15v-5.142c0-3.963-1.569-7.766-4.363-10.576zM90 241V90h164.903l150.141 151H90zM165 331H75c-8.284 0-15 6.716-15 15v15c0 33.084 26.916 60 60 60s60-26.916 60-60v-15c0-8.284-6.716-15-15-15zm-45 60c-16.542 0-30-13.558-30-30.1h60c0 16.542-13.458 30.1-30 30.1z"/>
	  </SvgIcon>
    );
};
