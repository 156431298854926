// Dependencies
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

const LocaleTextVariant = ({localization, default_text}) => {
    const main_locale = useSelector(state => state.user.main_locale);
    let displayed_text = default_text;
    if (localization !== undefined && !!localization[main_locale.id]) {
        displayed_text = localization[main_locale.id];
    }
    return <Fragment>{ displayed_text }</Fragment>;
};

export default LocaleTextVariant;