import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Room from "@material-ui/icons/Room";
import Group from '@material-ui/icons/Group';
import DateRange from '@material-ui/icons/DateRange';
import AccessTime from '@material-ui/icons/AccessTime';
import grey from "@material-ui/core/colors/grey";
import GetPrice from "./MaterialGetPrice";
import GetFormattedDuration from "./GetFormattedDuration";
import GetProviderById from "./MaterialGetProviderById";
import CurrencyFormat from "../Accommodation/FunctionalComponent/CurrencyFormat";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles({
    itemContainer: {
        color: grey[800]
    },
    marginRight: {
        marginRight: 16
    },
    paddingBottom: {
        paddingBottom: 8,
    },
    floatLeft: {
        float: "left"
    },
    bold: {
        fontWeight: "bold"
    },
    inline: {
        display: 'inline-block'
    },
    bigPriceFont: {
        fontSize: "1.5rem"
    },
    totalPrice: {
        textAlign: 'right',
        color: 'black'
    },
    underline: {
        textDecoration: 'underline'
    },
    black: {
        color: 'black'
    }
});

const MaterialTimelinePoi = ({cart}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const currency = useSelector(state => state.trip.currency);
    const end_date = useSelector(state => state.trip.end_date);
    const providers = useSelector(state => state.trip.providers);
    const manual_providers = useSelector(state => state.trip.manual_providers);
    const user = useSelector(state => state.user.user);
    const manualProductPrice = (poi) => {
        const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;
        if (user.client_full.type === 1 || user.client_full.type === 3 || quotation_code === 'verdie') {
            if (poi.creator.client.type === 2) {
                for (let i = 0; i < poi.prices.length; i++) {
                    if (poi.prices[i].master_price) {
                        return poi.prices[i].selling_price;
                    }
                }
            }
            for (let i = 0; i < poi.prices.length; i++) {
                if (poi.prices[i].is_surcom) {
                    return poi.prices[i].selling_price;
                }
            }
            for (let i = 0; i < poi.prices.length; i++) {
                if (poi.prices[i].master_price) {
                    return poi.prices[i].selling_price;
                }
            }
            for (let i = 0; i < poi.prices.length; i++) {
                if (poi.prices[i].owner === user.client) {
                    return poi.prices[i].selling_price;
                }
            }
        } else {
            for (let i = 0; i < poi.prices.length; i++) {
                if (poi.prices[i].owner === user.client) {
                    return poi.prices[i].selling_price;
                }
            }
        }
    };
    return (
        <Fragment>
            {
                cart.map((poi, index_poi) => {
                    let provider = GetProviderById(poi.provider, providers, manual_providers);
                    let adults = 0;
                    let children = 0;
                    if (poi.group_passenger !== null) {
                        poi.group_passenger.travelers_list.map((traveler) => {
                            if (moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y") >= 18) {
                                adults += 1;
                            } else {
                                children += 1;
                            }
                        });
                    }
                    if (poi.room_types !== undefined) {
                        return (
                            <Grid className={ classes.itemContainer } key={ index_poi }>
                                {
                                    index_poi > 0 && (
                                        <Fragment>
                                            <br/>
                                            <Divider/>
                                            <br/>
                                        </Fragment>
                                    )
                                }
                                <Grid container justify={ "space-between" } spacing={ 2 }>
                                    <Grid item xs>
                                        <Typography className={ classes.black } variant={ "h6" }>{ poi.name }</Typography>
                                        {
                                            poi.address !== null && (
                                                <div className={ classes.paddingBottom }>
                                                    <Room className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                    <Typography className={ classes.inline }>{ poi.address }</Typography>
                                                </div>
                                            )
                                        }
                                        {
                                            poi.start_date !== null && (
                                                <Fragment>
                                                    <div className={ `${classes.paddingBottom} ${classes.inline} ${classes.marginRight}` }>
                                                        <DateRange className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                        <Typography className={ classes.inline }>{ moment.utc(poi.start_date).format('DD MMMM') }</Typography>
                                                    </div>
                                                    <div className={ `${classes.paddingBottom} ${classes.inline} ${classes.marginRight}` }>
                                                        <AccessTime className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                        <Typography className={ classes.inline }>{ moment.utc(poi.start_date).format('hh[h]mm') }</Typography>
                                                    </div>
                                                </Fragment>
                                            )
                                        }
                                        {
                                             poi.group_passenger !== null && (
                                                <div className={ classes.paddingBottom }>
                                                    <Group className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                    <Typography className={ classes.inline }>{ poi.group_passenger.travelers.length } { t('timeline.travelers') }</Typography>
                                                </div>
                                             )
                                        }
                                        <br/>
                                        <br/>
                                        <Typography>{ poi.description }</Typography>
                                    </Grid>
                                    {
                                        provider !== null && provider.logo !== null && (JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie' || user.client_full.type !== 2) && (
                                            <Grid item>
                                                <img src={ provider.logo.url } width={ 100 } alt={ 'provider logo' }/>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <div className={ classes.totalPrice }>
                                    <CurrencyFormat language={ null } fontSize={ classes.bigPriceFont } symbol={ (currency !== null ? (currency.symbol === '$' || currency.symbol === '£' ? `${currency.symbol}${currency.iso_code}` : currency.symbol) : '') } price={ manualProductPrice(poi) } with_precision={ 0 } with_bold={ 1 }/>
                                    <Typography className={ classes.underline }>{ poi.is_optional ? t('cart.optional_product') : '' }</Typography>
                                </div>
                            </Grid>
                        )
                    }
                    return (
                        <Grid className={ classes.itemContainer } key={ index_poi }>
                            {
                                index_poi > 0 && (
                                    <Fragment>
                                        <br/>
                                        <Divider/>
                                        <br/>
                                    </Fragment>
                                )
                            }
                            <Grid container justify={ "space-between" } spacing={ 2 }>
                                <Grid item xs>
                                    <Typography className={ classes.black } variant={ "h6" }>{ poi.custom_product.title }</Typography>
                                    {
                                        poi.custom_product.place !== null && poi.custom_product.place.city_name !== null && (
                                            <div className={ classes.paddingBottom }>
                                                <Room className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                <Typography className={ classes.inline }>{ poi.custom_product.place.city_name }</Typography>
                                            </div>
                                        )
                                    }
                                    <div className={ `${classes.paddingBottom} ${classes.inline} ${classes.marginRight}` }>
                                        <DateRange className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                        <Typography className={ classes.inline }>{ moment.utc(poi.start_date).format('DD MMMM') }</Typography>
                                    </div>
                                    <div className={ `${classes.paddingBottom} ${classes.inline} ${classes.marginRight}` }>
                                        <AccessTime className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                        <Typography className={ classes.inline }>{ poi.hour !== null && poi.hour.length > 0 && poi.hour[0] }</Typography>
                                    </div>
                                    <div className={ `${classes.paddingBottom} ${classes.inline}` }>
                                        <Typography className={ classes.inline }>{ t('duration.title') } : { GetFormattedDuration(poi.custom_product.duration) }</Typography>
                                    </div>
                                    {
                                        poi.custom_product.taking_place !== undefined && poi.custom_product.taking_place !== null && (
                                            <div className={ classes.paddingBottom }>
                                                <Room className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                                <Typography className={ classes.inline }>{ t('timeline.taking_place') } : { poi.custom_product.taking_place }</Typography>
                                            </div>
                                        )
                                    }
                                    <div className={ classes.paddingBottom }>
                                        <Group className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                        <Typography className={ classes.inline }>{ poi.group_passenger !== null ? poi.group_passenger.travelers.length : '' } { t('timeline.travelers') }</Typography>
                                    </div>
                                    <br/>
                                    <br/>
                                    <Typography dangerouslySetInnerHTML={{__html: poi.custom_product.short_description}}/>
                                </Grid>
                                {
                                    provider !== null && provider.logo !== null && (JSON.parse(localStorage.getItem("config")).quotation_code === 'verdie' || user.client_full.type !== 2) && (
                                        <Grid item>
                                            <img src={ provider.logo.url } width={ 100 } alt={ 'provider logo' }/>
                                        </Grid>
                                    )
                                }
                            </Grid>
                            <div className={ classes.totalPrice }>
                                <CurrencyFormat language={ null } fontSize={ classes.bigPriceFont } symbol={ (currency !== null ? (currency.symbol === '$' || currency.symbol === '£' ? `${currency.symbol}${currency.iso_code}` : currency.symbol) : '') } price={ GetPrice(poi.prices, user) } with_precision={ 0 } with_bold={ 1 }/>
                                <Typography className={ classes.underline }>{ poi.is_optional ? t('cart.optional_product') : '' }</Typography>
                            </div>
                        </Grid>
                    )
                })
            }
        </Fragment>
    )
};

export default React.memo(MaterialTimelinePoi);