import { Moment } from "moment";
import { findLast } from "lodash";
import { Transport } from "../objects/transport";

export function getPlaneEndDate(
    item: Transport['alternatives'][number],
    endDate: string
): Moment {
    const lastAirStep = findLast(
        item.steps,
        (step) => step.vehicle.kind === 'plane'
    );
    const trailingCarSteps: typeof item.steps = [];
            
    for (let i = item.steps.length - 1; i < item.steps.length; i++) {
        const step = item.steps[i]!;
        if (step.vehicle.kind === 'plane') {
            break;
        } else {
            trailingCarSteps.push(step);
        }
    }

    const trailingDuration = trailingCarSteps.reduce((prev, current) => {
        return prev + current.duration;
    }, 0);

    const lastAirStepDate = window.moment.utc(
        endDate
    ).startOf(
        'day'
    ).add(
        lastAirStep?.arrivalTime,
        'seconds'
    ).add(
        trailingDuration,
        'seconds'
    );

    return lastAirStepDate;
}
