export default (flights, end_date) => {
    let arr_flights = [];
    flights.map((flight) => {
        let data = {};
        Object.keys(flight).map((key) => {
            if (key === "segments") {
                let sort_segment = flight[key].sort((a, b) => {
                    return ((a.departure_time < b.departure_time) ? -1 : ((a.departure_time > b.departure_time) ? 1 : 0));
                });
                let legs = sort_segment.reduce(function (r, a) {
	                r[a.group] = r[a.group] || [];
	                r[a.group].push(a);
	                return r;
	            }, []);
	            let outbound = [];
	            legs.map((tmp_leg) => {
	            	let arr_outbound = {};
	            	arr_outbound.legs = tmp_leg;
	            	let last_leg = arr_outbound.legs.length - 1;
	            	let departure_locale = arr_outbound.legs[0].departure_time.split("Z");
	            	let arrival_locale = arr_outbound.legs[last_leg].arrival_time.split("Z");
	            	let departure_date = moment.utc(departure_locale[0]).format("MM[/]DD[/]YYYY");
	            	let arrival_date = moment.utc(arrival_locale[0]).format("MM[/]DD[/]YYYY");
	            	let outside_day = moment.utc(new Date(arrival_date)).diff(new Date(departure_date), 'days');
	            	let available_seats = 9;
	            	let distance_total = 0;
	            	let flight_duration = 0;
	            	let stopover_duration = [];
	            	let is_nightly = false;
	            	let sum = 0;
	            	let prev_flight_duration = 0;
	            	let operate_by = null;
	            	arr_outbound.legs.map((leg, leg_index) => {
	            	    if (leg_index > 0) {
	            	    	let leg_depature_locale = leg.departure_time.split("Z"); 
	            	    	let leg_arrival_locale = arr_outbound.legs[leg_index - 1].arrival_time.split("Z");
	            	        let a = moment.utc(leg_depature_locale[0]);
	            	        let b = moment.utc(leg_arrival_locale[0]);
	            	        let departure_time_stopover = new Date(leg_depature_locale[0]);
	            	        let arrival_time_stopover = new Date(leg_arrival_locale[0]);
	            	        let tmp = new Date(departure_time_stopover.getTime() - arrival_time_stopover.getTime());
	            	        stopover_duration.push(tmp);
	            	        sum += Number(a.diff(b, "hours"));
	            	        flight_duration += leg.flight_time + moment.duration(moment.utc(tmp).format("HH:mm")).asMinutes();
	            	        if (prev_flight_duration < leg.time) {
	            	            prev_flight_duration = leg.time;
	            	            operate_by = leg.operating_carrier ? leg.operating_carrier : leg.operating_airline;
	            	        }
	            	        if (leg.is_nightly) {
                                is_nightly = true;
                            }
	            	    } else {
	            	        operate_by = leg.operating_carrier ? leg.operating_carrier : leg.operating_airline;
	            	        flight_duration = leg.flight_time;
	            	        if (leg.is_nightly) {
                                is_nightly = true;
                            }
	            	    }
	            	    if (leg.available_seats < available_seats) {
                            available_seats = leg.available_seats;
                        }
	            	    distance_total += leg.distance;
	            	});
            	    let convert_distance = distance_total * 1.609344;
            	    arr_outbound.convert_distance_total = Math.ceil(convert_distance);
	            	arr_outbound.outside_day = outside_day;
	            	arr_outbound.available_seats = available_seats;
	            	arr_outbound.distance_total = distance_total;
	            	arr_outbound.tab = 0;
	            	arr_outbound.stopover_duration = stopover_duration;
	            	arr_outbound.stopover_duration_sum = sum;
	            	arr_outbound.options = [];
	            	arr_outbound.more_option = null;
	            	arr_outbound.selected_option = null;
	            	arr_outbound.options_error = null;
	            	arr_outbound.operate_by = operate_by;
	            	arr_outbound.detail = false;
	            	arr_outbound.flight_duration = flight_duration;
	            	arr_outbound.is_nightly = is_nightly;
	            	outbound.push(arr_outbound);
		        });
	            data.outbounds = outbound;
            } else if (key === "group_passenger" && end_date !== undefined) {
                let pax_passenger = {
                    ADT: 0,
                    CNN: 0,
                    INF: 0
                };
                flight[key]?.travelers_list.map((traveler) => {
                    let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
	                if (age >= 12) {
	                	pax_passenger.ADT += 1;
	                    traveler.flight_type = "ADT";
	                } else if (age >= 2) {
	                	pax_passenger.CNN += 1;
	                    traveler.flight_type = "CNN";
	                } else {
	                    pax_passenger.INF += 1;
	                    traveler.flight_type = "INF";
	                }
                });
                pax_passenger.total = flight[key]?.travelers_list.length ?? 0;
                data[key] = flight[key];
                data.passenger = pax_passenger;
            } else {
                data[key] = flight[key];
            }
        });
        data.detail_price = false;
        arr_flights.push(data);
    });
    return (arr_flights);
};
