import { useSelector } from "react-redux";
import { useCartProducts } from "./cartProducts";
import { filterCartProducts } from "../utils/filterCartProducts";
import { AppState } from "../../../Reducers/Reducers";

export function useItineraryCartProducts(
    options: {
        arrivalDate: string,
        departureDate: string,
    }
): ReturnType<typeof useCartProducts> {
    const isUserTO = useSelector((state: AppState) => state.user.user?.client_full?.type !== 2);
    const cartProducts = useCartProducts();
    return filterCartProducts(
        cartProducts,
        {
            isUserTO,
            ...options
        }
    );
}
