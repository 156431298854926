import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Checkbox from "@material-ui/core/Checkbox";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBoxFilled from "@material-ui/icons/CheckBox";
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles({
    checked: {
        fill: "black"
    }
});

const AccommodationListSorts = () => {
    console.log("sorts render");
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const open = useSelector(state => state.accommodation.show_sorts);
    const value = useSelector(state => state.accommodation.sort);
    const toggleSorts = () => {
        dispatch({type: "MATERIAL_TOGGLE_SORTS"});
    };
    const setSort = (value) => {
        dispatch({type: "MATERIAL_SORT_LIST", payload: {value: value}});
    };
    return (
        <Dialog open={ open } PaperProps={{style: { borderRadius: fullScreen ? 0 : 16 } }} onClose={ toggleSorts } fullWidth fullScreen={ fullScreen } maxWidth={ "md" }>
            <DialogTitle onClose={ toggleSorts }>
                <Grid container justify={ "space-between" } alignItems={ "center" }>
                    {
                        fullScreen ? (
                            <Grid item>
                                <IconButton edge={ "start" } onClick={ toggleSorts }><NavigateBefore/></IconButton>{ t("accommodation_list.sort_by") } :
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item>
                                    { t("accommodation_list.sort_by") } :
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={ toggleSorts }><Close/></IconButton>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <FormControlLabel
                    control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ "large" }/> } checkedIcon={ <CheckBoxFilled className={ classes.checked } fontSize={ "large" }/> } checked={ value === 0 } onChange={() => { setSort(0); }}/> }
                    label={ t("accommodation.order.price_asc") }
                />
                <br/>
                <FormControlLabel
                    control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ "large" }/> } checkedIcon={ <CheckBoxFilled className={ classes.checked } fontSize={ "large" }/> } checked={ value === 1 } onChange={() => { setSort(1); }}/> }
                    label={ t("accommodation.order.price_dsc") }
                />
                <br/>
                <FormControlLabel
                    control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ "large" }/> } checkedIcon={ <CheckBoxFilled className={ classes.checked } fontSize={ "large" }/> } checked={ value === 2 } onChange={() => { setSort(2); }}/> }
                    label={ t("accommodation.order.name_asc") }
                />
                <br/>
                <FormControlLabel
                    control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ "large" }/> } checkedIcon={ <CheckBoxFilled className={ classes.checked } fontSize={ "large" }/> } checked={ value === 3 } onChange={() => { setSort(3); }}/> }
                    label={ t("accommodation.order.name_dsc") }
                />
                <br/>
                <FormControlLabel
                    control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ "large" }/> } checkedIcon={ <CheckBoxFilled className={ classes.checked } fontSize={ "large" }/> } checked={ value === 4 } onChange={() => { setSort(4); }}/> }
                    label={ t("accommodation.order.stars_asc") }
                />
                <br/>
                <FormControlLabel
                    control={ <Checkbox icon={ <CheckBoxOutlineBlank fontSize={ "large" }/> } checkedIcon={ <CheckBoxFilled className={ classes.checked } fontSize={ "large" }/> } checked={ value === 5 } onChange={() => { setSort(5); }}/> }
                    label={ t("accommodation.order.stars_dsc") }
                />
            </DialogContent>
            <DialogActions className={ classes.dialogActions }>
                <Grid container justify={ "flex-start" }>
                    <Grid item>
                        <Button className={ classes.underline } onClick={ () => { setSort(null); } }>{ t("accommodation.order.reset_sorts") }</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
};

export default React.memo(AccommodationListSorts);