import {
    ADD_CUSTOM_PICTURES,
    ADD_FILES,
    ADD_SELECTION,
    DELETE_FILE,
    DELETE_SELECTION,
    EDIT_PICTURES,
    SET_ADDING,
    SET_CROPPER,
    SET_LIMIT,
    SET_LIST,
    SET_PAGE,
    SET_PICTURE,
    SET_RUNNING,
    SET_SEARCH,
    TOGGLE_CREATE,
    TOGGLE_KEEP,
    UPDATE_ALT,
    UPDATE_CREATE_TAB,
    UPDATE_CREDITS,
    UPDATE_DESCRIPTION,
    UPDATE_NAME,
    UPDATE_PROGRESS
} from '../ActionTypes/Pictures';

export const addCustomPictures = (pictures) => ({
    type: ADD_CUSTOM_PICTURES,
    payload: pictures
});

export const addFiles = (files) => ({
    type: ADD_FILES,
    payload: files
});

export const addSelection = (picture, name) => ({
    type: ADD_SELECTION,
    payload: {
        picture: picture,
        name: name
    }
});

export const deleteFile = (index) => ({
    type: DELETE_FILE,
    payload: index
});

export const deleteSelection = (id) => ({
    type: DELETE_SELECTION,
    payload: id
});

export const editProductPictures = (product) => ({
    type: EDIT_PICTURES,
    payload: product
});

export const setAdding = (adding) => ({
    type: SET_ADDING,
    payload: adding
});

export const setCropper = (cropper) => ({
    type: SET_CROPPER,
    payload: cropper
});

export const setLimit = (limit) => ({
    type: SET_LIMIT,
    payload: limit
});

export const setList = (list) => ({
    type: SET_LIST,
    payload: list
});

export const setPage = (page) => ({
    type: SET_PAGE,
    payload: page
});

export const setPicture = (picture) => ({
    type: SET_PICTURE,
    payload: picture
});

export const setRunning = (running) => ({
    type: SET_RUNNING,
    payload: running
});

export const setSearch = (search) => ({
    type: SET_SEARCH,
    payload: search
});

export const toggleCreate = () => ({
    type: TOGGLE_CREATE
});

export const toggleKeep = () => ({
    type: TOGGLE_KEEP
});

export const updateAlt = (alt) => ({
    type: UPDATE_ALT,
    payload: alt
});

export const updateCreateTab = (tab) => ({
    type: UPDATE_CREATE_TAB,
    payload: tab
});

export const updateCredits = (credits) => ({
    type: UPDATE_CREDITS,
    payload: credits
});

export const updateDescription = (description) => ({
    type: UPDATE_DESCRIPTION,
    payload: description
});

export const updateName = (name) => ({
    type: UPDATE_NAME,
    payload: name
});

export const updateProgress = (progress) => ({
    type: UPDATE_PROGRESS,
    payload: progress
});
