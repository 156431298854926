import { useCallback } from "react";
import { useSelector } from "react-redux";
import { cloneDeep, isNumber } from "lodash";
import { StepsDatesManager } from "./stepsDatesManager";
import { useShowError } from "../../Utils/showError";
import { PricePeriod } from "../objects/pricePeriod";
import { ItineraryInput } from "../objects/itineraryState";
import { AppState } from "../../../Reducers/Reducers";

type Callback = (options: Options) => Promise<PricePeriod | null>;

type Options = {
    circuitId: number | null,
    circuitVersion: number | null,
    circuitVariants?: number[],
    circuitDate: string,
    periods: PricePeriod[],
    step: ItineraryInput,
    block: ItineraryInput[],
}

export function useCircuitCorrespondingPricePeriod(): Callback {
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const showError = useShowError();

    return useCallback(async (options: Options) => {
        if (tripStartDate && tripEndDate && isNumber(options.circuitId)) {
            const datesManager = new StepsDatesManager(
                tripStartDate,
                tripEndDate
            );

            // recompute block dates
            const recomputedBlock = [options.step].concat(
                options.block.slice(1).map((item) => {
                    return cloneDeep(item);
                }).reduce((previous, current): typeof options.block => {
                    const last = previous[previous.length - 1];

                    if (last) {
                        const [above, step] = datesManager.recomputeDates(last, current);
                        return previous.slice(0, previous.length - 1).concat([above, step]);
                    }

                    return previous.concat([current]);
                }, [options.step] as typeof options.block)
            );

            // find corresponding period
            const blockFirstStep = recomputedBlock[0];
            const blockLastStep = recomputedBlock[recomputedBlock.length - 1];
            const blockFirstStepStartDate = window.moment.utc(blockFirstStep?.start_date).startOf('day');
            const blockLastStepEndDate = window.moment.utc(blockLastStep?.end_date).startOf('day');
            let blockFirstDay = parseInt(blockFirstStepStartDate.format('d')) - 1;
            blockFirstDay = blockFirstDay < 0 ? 6 : blockFirstDay;
            const period = options.periods.filter((period) => {
                const periodStartDate = window.moment.utc(period.start_date).startOf('day');
                const periodEndDate = window.moment.utc(period.end_date).startOf('day');
                return options.circuitDate === 'PERIOD' ||
                    (
                        periodStartDate.isSame(periodEndDate, 'day') &&
                        periodStartDate.isSame(
                            window.moment.utc(options.circuitDate, 'YYYY-MM-DD').startOf('day'),
                            'days'
                        )
                    );
            }).find((period) => {
                const periodStartDate = window.moment.utc(period.start_date).startOf('day');
                const periodEndDate = window.moment.utc(period.end_date).startOf('day');

                if (
                    !blockFirstStepStartDate.isBetween(
                        window.moment.utc(tripStartDate).startOf('day'),
                        window.moment.utc(tripEndDate).startOf('day'),
                        'day',
                        '[]'
                    )
                ) {
                    return false;
                }

                if (
                    periodStartDate.isSame(periodEndDate, 'day') &&
                    periodStartDate.isSame(blockFirstStepStartDate, 'day')
                ) {
                    return true;
                }

                if (
                    period.start_days.includes(blockFirstDay) &&
                    blockFirstStepStartDate.isBetween(periodStartDate, periodEndDate, 'day', '[]')
                ) {
                    return true;
                }

                return false;
            });

            return period ?? null;
        }

        return null;
    }, [tripStartDate, tripEndDate, showError]);
}
