export function SetStep(step) {
    return {
        type: "MENU_SET_STEP",
        payload: {
            step: step
        }
    };
}
export function SetTravelersInfoToShow(travelers_info_to_show) {
    return {
        type: "MENU_SET_TRAVELERS_INFO_TO_SHOW",
        payload: {
            travelers_info_to_show: travelers_info_to_show
        }
    };
}
export function ToShowVersion(to_show_version) {
    return {
        type: "MENU_TO_SHOW_VERSION",
        payload: {
            to_show_version: to_show_version
        }
    };
}
export function SetTripInfoAndSetToShow(trip_info, to_show_version) {
    return {
        type: "MENU_TO_SHOW_VERSION_AND_SET_TRIP",
        payload: {
            trip_info: trip_info,
            to_show_version: to_show_version
        }
    };
}
export function SetArrayTab(trip_list_tab_nb) {
   return {
    type: "MENU_SET_ARRAY_TAB",
    payload: {
        trip_list_tab_nb: trip_list_tab_nb
    }
   };
}
export function SetManagementStep(management_step) {
    return {
        type: "MENU_SET_MANAGEMENT_STEP",
        payload: {
            management_step: management_step
        }
    };
}
export function SetEditUser(edit_user) {
    return {
        type: "MENU_SET_EDIT_USER",
        payload: {
            edit_user: edit_user
        }
    };
}
export function SetManagementStepAndUser(management_step, edit_user) {
    return {
        type: "MENU_SET_MANAGEMENT_STEP_AND_USER",
        payload: {
            edit_user: edit_user,
            management_step: management_step
        }
    };
}
export function SetSelectedPage(selected_page) {
    return {
        type: "MENU_SET_SELECTED_PAGE",
        payload: {
            selected_page: selected_page
        }
    };
}

export function ChangeActivePage(active_page) {
    return {
        type: "MENU_CHANGE_ACTIVE_PAGE",
        payload: {
            active_page: active_page
        }
    };
}

export function ToggleModalChangePicture(modal_picture) {
    return {
        type: "MENU_TOGGLE_MODAL_PICTURE",
        payload: {
            modal_picture: modal_picture
        }
    };
}
export function ChangeTripInfoPage(trip_info_page) {
    return {
        type: "MENU_CHANGE_TRIP_INFO_PAGE",
        payload: {
            trip_info_page: trip_info_page
        }
    };
}
export function ChangeTripInfoPageAndSetInfo(trip_info_page, trip_info) {
    return {
        type: "MENU_CHANGE_TRIP_INFO_PAGE_AND_SET_INFO",
        payload: {
            trip_info_page: trip_info_page,
            trip_info: trip_info
        }
    };
}
export function SetTripInfo(trip_info) {
    return {
        type: "MENU_SET_TRIP_INFO",
        payload: {
            trip_info: trip_info
        }
    };
}
export function SetTripInfoStatus(status, index) {
    return {
        type: "MENU_SET_TRIP_INFO_STATUS",
        payload: {
            status: status,
            index: index
        }
    };
}

export function SetTripInfoVersion(version){
    return {
        type: "MENU_SET_TRIP_INFO_VERSION",
        payload: {
            version: version
        }
    };
}
export function SetBigCart(big_cart, get_cart_done) {
    return {
        type: "MENU_SET_BIG_CART",
        payload: {
            big_cart: big_cart,
            get_cart_done: get_cart_done
        }
    };
}
export function SetCartDone(get_cart_done) {
    return {
        type: "MENU_SET_CART_DONE",
        payload: {
            get_cart_done: get_cart_done
        }
    };
}

export function SetTripInfoContactLead(trip_info_contact_lead_id, trip_info_contact_lead) {
    return {
        type: "MENU_SET_TRIP_INFO_CONTACT_LEAD",
        payload: {
            trip_info_contact_lead: trip_info_contact_lead,
            trip_info_contact_lead_id: trip_info_contact_lead_id
        }
    };
}

export function SetTripList(trip, loading_state, nb_result, trip_list_ordering, trip_list_search, trip_list_filter) {
    return {
        type: "MENU_SET_TRIP_LIST_INFO",
        payload: {
            trip: trip,
            loading_state: loading_state,
            nb_result: nb_result,
            trip_list_ordering: trip_list_ordering,
            trip_list_search: trip_list_search,
            trip_list_filter: trip_list_filter
        }
    };
}

export function SetTripListOrdering(trip_list_ordering) {
    return {
        type: "MENU_SET_TRIP_LIST_ORDERING",
        payload: {
            trip_list_ordering: trip_list_ordering
        }
    };
}
export function SetTripListFilter(trip_list_filter) {
    return {
        type: "MENU_SET_TRIP_LIST_FILTER",
        payload: {
            trip_list_filter: trip_list_filter
        }
    };
}
export function SetTripListSearch(trip_list_search) {
    return {
        type: "MENU_SET_TRIP_LIST_SEARCH",
        payload: {
            trip_list_search: trip_list_search
        }
    };
}
export function SetTripListOptions(options) {
    return {
        type: "MENU_SET_TRIP_LIST_OPTION",
        payload: {
            options: options
        }
    };
}
export function SetTripListLoader(loading_state) {
    return {
        type: "MENU_SET_TRIP_LIST_LOADER",
        payload: {
            loading_state: loading_state
        }
    };
}

export function SetMarginStep(margin_step) {
    return {
        type: "MENU_SET_MARGIN_STEP",
        payload: {
            margin_step: margin_step
        }
    };
}
export function SetMarginStepAndUpdate(margin_step, need_update_margin_list) {
    return {
        type: "MENU_SET_MARGIN_STEP_AND_UPDATE",
        payload: {
            margin_step: margin_step,
            need_update_margin_list: need_update_margin_list
        }
    };
}

export function SetMarginUpdate(need_update_margin_list) {
    return {
        type: "MENU_SET_MARGIN_UPDATE",
        payload: {
            need_update_margin_list: need_update_margin_list
        }
    };
}

export function SetTripToDuplicateAndOpenDuplicateModalAndSetStep(trip_to_duplicate, duplicate_modal, duplicate_step) {
    return {
        type: "MENU_SET_TRIP_DUPLICATE_AND_OPEN_DUPLICATE_MODAL",
        payload: {
            trip_to_duplicate: trip_to_duplicate,
            duplicate_modal: duplicate_modal,
            duplicate_step: duplicate_step
        }
    };
}

export function ToggleVideoModal(video_modal, video_to_read) {
    return {
        type: "MENU_TOGGLE_VIDEO_MODAL",
        payload: {
            video_modal: video_modal,
            video_to_read: video_to_read
        }
    };
}

export function ResetTripList() {
    return {
        type: "RESET_TRIP_LIST"
    };
}

export function toggleMCTOModal() {
    return {
        type: "MENU_TOGGLE_MCTO_MODAL"
    }
}

export function setMCTOData(data) {
    return {
        type: "MENU_SET_MCTO_DATA",
        payload: {
            data: data
        }
    }
}

export function toggleMTRIPModal() {
    return {
        type: "MENU_TOGGLE_MTRIP_MODAL"
    }
}

export function setMTRIPData(data) {
    return {
        type: "MENU_SET_MTRIP_DATA",
        payload: {
            data: data
        }
    }
}

export function SetTripDocs(trip_docs) {
    return {
        type: "MENU_SET_TRIP_DOCS",
        payload: {
            trip_docs: trip_docs
        }
    }
}

export function SetTripDocsDoc(trip_doc){
    return {
        type: "MENU_SET_TRIP_DOCS_DOC",
        payload:{
            trip_doc: trip_doc
        }
    }
}

export function SetUpdateDocsDoc(trip_docs){
    return {
        type: "MENU_UPDATE_TRIP_DOCS_DOC",
        payload:{
            trip_docs: trip_docs
        }
    }
}

export function AddFileToDl(name, blob){
    return {
        type: "MENU_ADD_FILE_TO_DL",
        payload: {
            file_name: name,
            blob: blob
        }
    }
}

export function ClearFilesToDl(){
    return {
        type: "MENU_CLEAR_FILES_TO_DL",
        payload: null
    }
}

export function SetCustomFilters(custom_filters) {
    return {
        type: "MENU_SET_CUSTOM_FILTERS",
        payload: custom_filters
    }
}

export function DeleteDocumentId(id) {
    return {
        type: "MENU_DELETE_DOC",
        payload: id
    }
}

export function AddOtherDocument(doc) {
    return {
        type: "MENU_ADD_OTHER_DOC",
        payload: doc
    }
}
