import { flattenDeep } from "lodash";
import { CreatePageOptions } from "../objects/createPageOptions";
import { Template } from "../utils/handlebars/roadbook.handlebars";

export function createFlightsContent(options: CreatePageOptions): Parameters<Template>[0]['contents'][0] | null {
    const flights = flattenDeep(
        options.cart.flights.map((item) => {
            if (item.type === 'normal') {
                return item.flight.outbounds.map((flight, flightIndex) => {
                    return flight.legs.map((leg, index) => {
                        return {
                            startDate: leg.departure_time,
                            title: options.t(
                                'roadbook.flight-info-destination',
                                {
                                    city: leg.destination?.iata_city.name ??
                                          leg.destination?.iata_city.international_name ??
                                          leg.destination_station?.iata_city?.name ??
                                          leg.destination_station?.iata_city?.international_name
                                }
                            ),
                            departure: options.t(
                                'roadbook.flight-info-departure',
                                {
                                    city: leg.origin?.iata_city.name ??
                                          leg.origin?.iata_city.international_name ??
                                          leg.origin_station?.iata_city?.name ??
                                          leg.origin_station?.iata_city?.international_name,
                                    date: window.moment.utc(leg.departure_time).format('DD MMMM'),
                                    hourly: window.moment.utc(leg.departure_time).format('HH:mm'),
                                    airline: leg.operating_airline?.commercial_name,
                                    airport: leg.origin?.international_name ??
                                             leg.origin_station?.international_name
                                }
                            ),
                            arrival: options.t(
                                'roadbook.flight-info-arrival',
                                {
                                    city: leg.destination?.iata_city.name ??
                                          leg.destination?.iata_city.international_name ??
                                          leg.destination_station?.iata_city?.name ??
                                          leg.destination_station?.iata_city?.international_name,
                                    date: window.moment.utc(leg.arrival_time).format('DD MMMM'),
                                    hourly: window.moment.utc(leg.arrival_time).format('HH:mm'),
                                    airline: leg.operating_airline?.commercial_name,
                                    airport: leg.destination?.international_name ??
                                             leg.destination_station?.international_name
                                }
                            ),
                            note: index === flight.legs.length - 1 && flightIndex === item.flight.outbounds.length - 1 ?
                                item.flight.custom_information?.replace(/\n/g, '<br />') ?? undefined :
                                undefined
                        };
                    });
                });
            }
            return item.flight.flight_segment.map((leg, index) => {
                return {
                    startDate: leg.start_date,
                    title: options.t(
                        'roadbook.flight-info-destination',
                        {
                            city: leg.destination_airport?.iata_city.name ??
                                leg.destination_airport?.iata_city.international_name ??
                                leg.destination_station?.iata_city.name ??
                                leg.destination_airport?.iata_city.international_name
                        }
                    ),
                    departure: options.t(
                        'roadbook.flight-info-departure',
                        {
                            city: leg.origin_airport?.iata_city.name ??
                                leg.origin_airport?.iata_city.international_name ??
                                leg.origin_station?.iata_city.name ??
                                leg.origin_airport?.iata_city.international_name,
                            date: window.moment.utc(leg.start_date).format('DD MMMM'),
                            hourly: window.moment.utc(leg.start_date).format('HH:mm'),
                            airline: leg.airline.commercial_name,
                            airport: leg.origin_airport?.international_name ??
                                leg.origin_station?.international_name
                        }
                    ),
                    arrival: options.t(
                        'roadbook.flight-info-arrival',
                        {
                            city: leg.destination_airport?.iata_city.name ??
                                leg.destination_airport?.iata_city.international_name ??
                                leg.destination_station?.iata_city.name ??
                                leg.destination_station?.iata_city.international_name,
                            date: window.moment.utc(leg.end_date).format('DD MMMM'),
                            hourly: window.moment.utc(leg.end_date).format('HH:mm'),
                            airline: leg.airline.commercial_name,
                            airport: leg.destination_airport?.international_name ??
                                leg.destination_station?.international_name
                        }
                    ),
                    note: index === item.flight.flight_segment.length - 1 ?
                        item.flight.custom_information?.replace(/\n/g, '<br />') ?? undefined :
                        undefined
                };
            });
        })
    ).sort((a, b) => {
        return window.moment.utc(a.startDate).isBefore(window.moment.utc(b.startDate)) ? -1 : 1;
    });
    return flights.length > 0 ?
        {
            type: 'flights',
            anchor: 'flights',
            showInSummary: true,
            summaryTitle: 'DÉTAILS DES VOLS',
            title: options.title,
            contents: flights
        } :
        null;
}
