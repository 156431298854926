import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { FormHelperText, makeStyles, useTheme } from "@material-ui/core";
import { FormControl, Grid, OutlinedInput, MenuItem, Select, InputLabel } from '@material-ui/core';
import { DatePicker , LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as date_locales from 'date-fns/locale';
import format from "date-fns/format";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TextField } from '@mui/material';
import { ViatorLocation } from './ViatorLocation';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400:{
        fontWeight: 400
    },
    fontSize12: {
        fontSize: 12
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter:{
        textAlign: "center"
    },
    genericPadding:{
        padding: "8px 0px"
    },
    formControlRootSmall: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "&& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '90%',
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    formControlRootSmallRed: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "&& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '90%',
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    },
        "&& .MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        }
    },
    circleBorder:{
        borderRadius: 8
    },
    validateButton: {
        color: "#FFFFFF",
        backgroundColor: "#2C98F0",
        float: "right",
        marginTop: 8
    },
    errorInfo:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        letterSpacing: 1.25,
        color: "red",
        width: "100%",
        fontSize: 10
    },
}))
type ViatorLocation = {
    meeting_type: string;
    locations: any[];
    start: any[];
    end: any[];
}
type ActivitiesCeetizQuestionsItemProps = {
    product: any;
    question: any;
    error: string[];
    editProduct: any;
}
const locales = [
    'beBY',
    'csCZ',
    'deDE',
    'enUS',
    'esES',
    'faIR',
    'fiFI',
    'frFR',
    'isIS',
    'itIT',
    'jaJP',
    'koKR',
    'nbNO',
    'nlNL',
    'plPL',
    'ptBR',
    'ruRU',
    'svSE',
    'trTR',
    'ukUA',
    'urPK',
    'zhCN'
];

export function ActivitiesCeetizQuestionsItem(props: ActivitiesCeetizQuestionsItemProps) : JSX.Element | null {
    const { question, product, error, editProduct } = props;
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const locale = i18n.language.split('-')[0] ?? 'fr';
	const locales_cpy : { [key: string]: any } = date_locales;
    const textsLocale = locales.find((item) => {
        return item.startsWith(locale);
    }) ?? locale;
    const texts = require(`@mui/x-date-pickers/locales/${textsLocale}.js`)[textsLocale];

    const [value, setValue] = useState(question.answer_text);
    // const [value, setValue] = useState(question.answer_text !== null ? typeof question.answer_text === 'string' ? JSON.parse(question.answer_text).value : question.answer_text.value : "");
    const questionLabel = useRef(null);
    const questionLabelWidth = questionLabel.current ? questionLabel.current.clientWidth : 100;
    let rules_tojson = question.rules_answer !== null ? (typeof question.rules_answer === 'string' ? JSON.parse(question.rules_answer) : question.rules_answer) : null;
    const handleAnswer = (event: { target: { value: any; }; }) => {
        let tmp_product = {...product};
        setValue(event.target.value);
        let questions = [...product.questions];
        questions.map((cpy_question: any) => {
            if (cpy_question.id === question.id) {
                cpy_question.answer_text = event.target.value;
            }
        });
        tmp_product.questions = questions;
        editProduct(tmp_product);
    }
    const handleValidate = () => {
        let tmp_product = {...product};
        let questions = [...product.questions];
        questions.map((cpy_question: any) => {
            if (cpy_question.id === question.id) {
                cpy_question.answer_text = value
            }
        });
        tmp_product.questions = questions;
        editProduct(tmp_product);
    }
    const handleValidateSelect = (event: { target: { value: any; }; }) => {
        let tmp_product = {...product};
        setValue(event.target.value);
        let questions = [...product.questions];
        questions.map((cpy_question: any) => {
            if (cpy_question.id === question.id) {
                cpy_question.answer_text = event.target.value;
            }
        });
        tmp_product.questions = questions;
        editProduct(tmp_product);
    }
    return (
        <Grid container alignItems="baseline" justify="space-between" className={classes.genericPadding} style={!downDesktop ? { paddingTop: 16 } : {}}>
            <Grid item md={5} xs={12} className={`${!downDesktop ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12} ${classes.letterSpacing}`}>
                { `${question.question_text.toLowerCase()}` }
            </Grid>
            <Grid item md={7} xs={12}>
                {
                    <Fragment>
                    {
                        rules_tojson === null ?
                        <FormControl variant="outlined" className={ error.includes(question.question_code) ? classes.formControlRootSmallRed : classes.formControlRootSmall}>
                            <InputLabel id={`question-label-${question.id}`}>{ `${question.question_text.toLowerCase()}` }</InputLabel>
                            <OutlinedInput
                                type={question.rules_answer !== null && (rules_tojson.type === 'STRING' || rules_tojson.type === 'LOCATION_REF_OR_FREE_TEXT') ? "text" : undefined}
                                name={`question-label-${question.id}`}
                                id={`question-input-${question.id}`}
                                value={value}
                                className={`${classes.circleBorder}`}
                                labelWidth={questionLabelWidth}
                                onChange={handleAnswer}
                                onBlur={handleValidate}
                                label={`${question.question_text.toLowerCase()}`}
                                autoComplete="off"
                            />
                        </FormControl> : 
                        <FormControl variant={ 'outlined' } fullWidth className={ error.includes(question.question_code) ? classes.formControlRootSmallRed : classes.formControlRootSmall}>
                            <InputLabel id={`question-label-${question.id}`}>{ `${question.question_text.toLowerCase()}` }</InputLabel>
                            <Select
                                labelId={`question-label-${question.id}`}
                                id={`question-input-${question.id}`}
                                value={value}
                                className={classes.circleBorder}
                                label={`${question.question_text.toLowerCase()}`}
                                onChange={handleValidateSelect}
                            >
                                {
                                    rules_tojson.choice !== undefined && rules_tojson.choice.map((choice: any, index: number) => {
                                        return (
                                            <MenuItem key={index} value={choice}>{choice}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    }
                    </Fragment>
                }
            </Grid>
        </Grid>
    )
}