import React, { Component } from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

@connect((store) => {
    return {
        currency: store.trip.currency,
        language: store.header.tmp_language,
        airports: store.flight_search.airports
    };
})
@withTranslation()

class RenderSummarySeat extends Component {
	render() {
		const {flight, travelers_list, airports, language, currency, t} = this.props;
        let selected_outbound = flight.outbounds;
        let travelers = flight.group_passenger ? flight.group_passenger.travelers_list : travelers_list;
        return (
            selected_outbound.map((outbound, outbound_index) => {
                return (
                    <div key={outbound_index} className={ "ft-ml20" }>
                    {
                        outbound.legs.map((leg, leg_index) => {
                        let selected_seats = leg.seats;
                        let cart = window.location.pathname.includes("cart") ? true : false;
                        console.log("selected_seats:", selected_seats);
                            return (
                                <div key={leg_index} className={ "mb20" }>
                                    <div className={ "title-seat-summary" }>{(leg.origin_airport ? airports[leg.origin_airport].iata_city.international_name : leg.origin.iata_city.international_name) + " - " + (leg.destination_airport ? airports[leg.destination_airport].iata_city.international_name : leg.destination.iata_city.international_name)}</div>
                                    {
                                        // !cart && (
                                            travelers.map((traveler, traveler_index) => {
                                                return (
                                                    <div key={traveler_index}>{traveler.first_name + " " + traveler.last_name + " : "}
                                                        {

                                                            Object.keys(selected_seats).map((id) => {
                                                                if (traveler.id === Number(id)) {
                                                                    return (
                                                                        <span key={id} >
                                                                            <span className={ "bold" }>
                                                                                {selected_seats[id].seat}
                                                                            </span>
                                                                            {
                                                                                selected_seats[id].price && (
                                                                                    <span>
                                                                                        <span>{" / " + t("flight_search.seat_price")}</span>
                                                                                        <span className={ "bold" }>{new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(selected_seats[id].price))}</span>
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        </span>
                                                                    );
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                );
                                            })
                                        // )
                                    }
                                    {
                                        // cart && (
                                        //     travelers.map((traveler, traveler_index) => {
                                        //         return (
                                        //             <div key={traveler_index}>{traveler.first_name + " " + traveler.last_name + " : "}
                                        //                 {
                                        //                     selected_seats.map((seat) => {
                                        //                         if (traveler.id === seat.traveler) {
                                        //                             return (
                                        //                                 <span key={seat.traveler} >
                                        //                                     <span className={ "bold" }>
                                        //                                         {seat.seat_number}
                                        //                                     </span>
                                        //                                     {
                                        //                                         seat.price && (
                                        //                                             <span>
                                        //                                                 <span>{" / " + t("flight_search.seat_price")}</span>
                                        //                                                 <span className={ "bold" }>{new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(seat.price))}</span>
                                        //                                             </span>
                                        //                                         )
                                        //                                     }
                                        //                                 </span>
                                        //                             );
                                        //                         }
                                        //                     })
                                        //                 }
                                        //             </div>
                                        //         );
                                        //     })
                                        // )
                                    }
                                </div>
                            );
                        })
                    }
                    </div>
                );
            })
        );
	}
}
export default RenderSummarySeat;
