import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';


const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A",
        letterSpacing: 1.25
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    fontWeight500:{
        fontWeight: 500
    },
    cancelButton: {
        color: "#0000008A",
        backgroundColor: "#FFFFFF",
        marginRight: 20
    },
    validateButton: {
        color: "#FFFFFF",
        backgroundColor: "#2C98F0"
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteLoyaltyCard = (( {openDeleteCard, setOpenDeleteCard, deleteCard} ) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(768));

    const cancelDelete = () => {
        setOpenDeleteCard(false);
    }

    const validateDelete = () => {
        deleteCard();
        setOpenDeleteCard(false);
    }

    return(
        <Dialog open={openDeleteCard} fullScreen={ false } TransitionComponent={Transition} fullWidth maxWidth={"xs"}>
            <DialogContent dividers>
                <Grid item className={`${classes.genericText} ${!mobile ? classes.fontSize16 : classes.fontSize12} ${classes.fontWeight500}`}>
                    { t("menu.trip_list.delete_card") }
                </Grid>
                <Grid container alignItems="center" justify="space-evenly" style={{ paddingTop: 20 }}>
                    <Grid item>
                        <Button variant="contained" size="small" className={classes.cancelButton} onClick={cancelDelete}>
                            { t("global.no") }
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" size="small" className={classes.validateButton} onClick={validateDelete}>
                            { t("global.yes") }
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
})

export default React.memo(DeleteLoyaltyCard);