import React from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";

type Props = {
    loading: boolean,
    onDelete: () => void,
    onClose: () => void
}

export function ItineraryLockBoxDeleteModal(props: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <Dialog open onClose={props.onClose}>
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography fontWeight="bold">
                        {t('cart-material.delete-product')}
                    </Typography>
                    <IconButton size={"small"} onClick={props.onClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {t('cart-material.delete-package-itinerary')}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary" disabled={props.loading}>
                    {t("global.cancel")}
                </Button>
                <Button onClick={props.onDelete} color="primary" disabled={props.loading}>
                    {t("global.validate")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
