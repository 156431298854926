//@see https://mui.com/material-ui/react-autocomplete/#google-maps-place
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Autocomplete,
    AutocompleteProps,
    Box,
    Grid,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import { GpsFixed, LocationOn } from '@mui/icons-material';
import { debounce } from '@mui/material/utils';
import { blue } from '@mui/material/colors';
import parse from 'autosuggest-highlight/parse';

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string
}

type Props = {
    onChange: (place: google.maps.places.PlaceResult | null) => void
} & Pick<AutocompleteProps<PlaceType, false, false, false>, 'sx'>

export function CartManualProductFormAddressGoogleSearch(props: Props) {
    const { t } = useTranslation();
    const [value, setValue] = useState<PlaceType | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<readonly PlaceType[]>([]);
    const ref = useRef<HTMLInputElement>(null);
    const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null);
    const placesService = useRef<google.maps.places.PlacesService | null>(null);

    const fetch = useMemo(
        () =>
            debounce(
                (
                    request: { input: string },
                    callback: (results: google.maps.places.AutocompletePrediction[] | null) => void
                ) => {
                    autocompleteService.current?.getPlacePredictions(
                        request,
                        callback
                    );
                },
                400
            ),
        []
    );

    const onChange = (_: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);

        if (!placesService.current && ref.current) {
            placesService.current = new google.maps.places.PlacesService(ref.current);
        }

        if (newValue) {
            placesService.current?.getDetails(
                { placeId: newValue.place_id },
                props.onChange
            );
        } else {
            props.onChange(null);
        }
    };

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results: google.maps.places.AutocompletePrediction[] | null) => {
            if (active) {
                let newOptions: readonly PlaceType[] = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            sx={props.sx}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText="Addresse non trouvée"
            onChange={onChange}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputRef={ref}
                    label={t<string>('cart-material.search-with-google')}
                    helperText={t<string>('cart-material.search-with-google-hint')}
                    FormHelperTextProps={{
                        sx: {
                            color: blue[600]
                        }
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <GpsFixed />
                            </InputAdornment>
                        )
                    }}
                    fullWidth
                />
            )}
            renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings || [];

                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [match.offset, match.offset + match.length])
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOn sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                {parts.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
            forcePopupIcon={false}
            fullWidth
        />
    );
}
