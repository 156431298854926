import axios from "axios";

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

import GetCookie from "../../Common/Functions/GetCookie";

import { SetCartError, SetPriceVariation, ToggleAddToCart, SetOptionLoaded, SelectFlight, SetFetchedDataStatus, ClearFilter, ResetProvider } from "../../../Actions/FlightSearch";
import { AddToCart, SetGroupIndex } from "../../../Actions/Flight";
import { ToggleRightPanel } from "../../../Actions/Base";
import { SetDates } from "../../../Actions/Trip";

import CheckResponse from "./CheckResponse";
import ManualRequestSearch from "./ManualRequestSearch";

export default function FlightCartRequest(flight_groups, flight_providers, group_passenger, group_index, max_group_index, trip_currency, flight, trace_id, end_date, filter_time, step, user, enqueueSnackbar, dispatch, t, router, context) {
    let { pass_check, headers } = CheckBeforeRequest();
	let provider_id = flight.provider_id;
    let quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    if (pass_check) {
        let key_pricing_solution = "No key";
        let private_fare = "all";
        if (quotation_code === "visiteurs" && user.client_full.type === 2){
            private_fare = "private"
        }
        if (flight_groups[group_index].public_price && flight_groups[group_index].private_price) {
            if (flight.adult.pax_code === 'ITX') {
                private_fare = 'private';
            } else {
                private_fare = 'public';
            }
        } else {
            if (flight_groups[group_index].private_price && !flight_groups[group_index].public_price) {
                private_fare = "private";
            } else if (flight_groups[group_index].public_price && !flight_groups[group_index].private_price) {
                private_fare = "public";
            }
        }
        let total_price = (flight.adult !== null ? (flight.adult.number * flight.adult.total_price) : 0) + (flight.children !== null ? (flight.children.number * flight.children.total_price) : 0) + (flight.baby !== null ? (flight.baby.number * flight.baby.total_price) : 0);
        let total_taxes = (flight.adult !== null ? (flight.adult.number * parseFloat(flight.adult.taxes[0].selling_price)) : 0) + (flight.children !== null ? (flight.children.number * parseFloat(flight.children.taxes[0].selling_price)) : 0) + (flight.baby !== null ? (flight.baby.number * parseFloat(flight.baby.taxes[0].selling_price)) : 0);
        let segments = [];
        flight.outbounds.map((outbound, outbound_index) => {
            if (outbound.key_pricing) {
                key_pricing_solution = outbound.key_pricing;
            }
            // let paid_options = [];
            // let outbound_paid_options = outbound.paid_options;
            // if (outbound_paid_options) {
            //     Object.keys(outbound_paid_options).map((key) => {
            //         outbound_paid_options[key].map((baggage) => {
            //             paid_options.push({
            //                 traveler: key,
            //                 key_optional_service: baggage.key_optional_service,
            //                 option_type: baggage.option_type,
            //                 description: baggage.description,
            //                 quantity: baggage.quantity,
            //                 price: parseFloat(baggage.prices[0].selling_price)
            //             });
            //         });
            //     });
            // }
            outbound.legs.map((leg) => {
                let seats = [];
                Object.keys(leg.seats).map((traveler) => {
                    let seat_traveler = {
                        traveler: Number(traveler),
                        seat_number: leg.seats[traveler].seat,
                        price: leg.seats[traveler].price
                    };
                    seats.push(seat_traveler);
                });
                let paid_options = [];
                if (leg.paid_options){
                    Object.keys(leg.paid_options).map((key) => {
                        leg.paid_options[key].map((baggage) => {
                            paid_options.push({
                                traveler: key,
                                key_optional_service: baggage.key_optional_service,
                                option_type: baggage.option_type,
                                description: baggage.description,
                                quantity: baggage.quantity,
                                price: parseFloat(baggage.prices[0].selling_price)
                            })
                        })
                    })
                }

                const destination_terminal = leg.flight_details.terminal.destination ? leg.flight_details.terminal.destination : "No data";
                const origin_terminal = leg.flight_details.terminal.origin ? leg.flight_details.terminal.origin : "No data";
                let new_segment = {
                    operating_airline: leg.operating_airline ? leg.operating_airline : "-",
                    marketing_airline: leg.marketing_airline ? leg.marketing_airline : "-",
                    aircraft: leg.aircraft ? leg.aircraft.id : null,
                    segment_key: leg.key !== undefined ? leg.key : (leg.key_segment !== undefined ? leg.key_segment : leg.key_segments),
                    departure_time: leg.departure_time !== undefined ? leg.departure_time :  (leg.departure_datetime_locale ? leg.departure_datetime_locale : leg.departure_datetime_lt),
                    arrival_time: leg.arrival_time !== undefined ? leg.arrival_time :  (leg.arrival_datetime_locale ? leg.arrival_datetime_locale : leg.arrival_datetime_lt),
                    flight_number: leg.marketing_flight_number !== undefined ? leg.marketing_flight_number : leg.flight_number,
                    flight_time: leg.flight_time ? leg.flight_time : 0,
                    destination_terminal: destination_terminal,
                    origin_terminal: origin_terminal,
                    class_name: leg.cabin_class ? leg.cabin_class : null,
                    // currency: data.currency,
                    currency: trip_currency,
                    group: outbound_index,
                    seats: seats,
                    booking_code: leg.default_class_of_service !== undefined ? leg.default_class_of_service : leg.booking_code,
                    paid_options: paid_options,
                    fare_basis: leg.fare_basis
                };
                if (leg.origin_station !== undefined || leg.destination_station !== undefined) {
                    new_segment.origin = null;
                    new_segment.destination = null;
                    new_segment.origin_station = leg.origin_station;
                    new_segment.destination_station = leg.destination_station;
                }
                else {
                    new_segment.origin = leg.origin_airport;
                    new_segment.destination = leg.destination_airport;
                    new_segment.origin_station = null;
                    new_segment.destination_station = null;
                }
                segments.push(new_segment);
            });
        });

        if (key_pricing_solution === "No key" && flight.options !== null) {
            key_pricing_solution = flight.options.fares[0].key_pricing_solution;
        }

        let params = {
            token: GetCookie("trip_token")
        }
        if (step === "check") {
            params["checking"] = true;
        }
        axios({
            method: "POST",
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/flight/`,
            headers: headers,
            params: params,
            data: {
                total_price: (total_price).toFixed(2),
                taxes: (total_taxes).toFixed(2),
                pricing_solution_key: key_pricing_solution,
                segments: segments,
                group_passenger: group_passenger,
                trace_id: trace_id !== null ? trace_id : "1",
                private_fare: private_fare,
                provider_id: provider_id
            }
        }).then(function(response){
            if(step === "check" && response.data.price_variation !== 0){
                let price_variation = {
                    status: true,
                    variation: response.data.price_variation,
                    reload: true,
                    group_passenger: group_passenger
                };
                dispatch(SetPriceVariation(price_variation));
                dispatch(ToggleAddToCart(true));
            } else if (step === "check" && response.data.price_variation === 0) {
                FlightCartRequest(flight_groups, flight_providers, group_passenger, group_index, max_group_index, trip_currency, flight, trace_id, end_date, filter_time, "cart", user, enqueueSnackbar, dispatch, t, router, context);
            } else if (step === "cart") {
                let data = [];
                data.push(response.data);
                let arr_flights = CheckResponse(data, end_date);
                dispatch(AddToCart(arr_flights));
                enqueueSnackbar(
                    max_group_index !== 0 ? t("add_to_cart.success") + " " + t("flight.for_group") + (group_index + 1) : t("add_to_cart.success"),
                    { variant: 'success',  disableWindowBlurListener: true }
                );
                dispatch(ToggleRightPanel(0));
                dispatch(ResetProvider());

                if (response.data.trip_date_update !== null) {
                    axios({
                        method: "GET",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
                        headers: headers
                    }).then(function(response){
                        dispatch(SetDates({
                            startDate: response.data.start_date,
                            endDate: response.data.end_date,
                        }));
                    })
                    dispatch({type: "UPDATE_TRIP_DATES_FROM_FLIGHT"});
                }
                if (group_index === max_group_index) {
                    if (!context?.enableReplace) {
                        dispatch({ type: "FLUSH_REMOVE_STATE" });
                        router.push(`/${window.url_name}/apps/itinerary`);
                    } else if (context?.onClose) {
                        context.onClose();
                    }
                } else {
                    dispatch(SetGroupIndex(group_index + 1));
                    dispatch(SelectFlight(null));
                    dispatch(SetOptionLoaded(false));
                    dispatch(ToggleAddToCart(false));
                    dispatch(SetFetchedDataStatus(null));
                    let default_filter_time = filter_time.slice();
                    default_filter_time.map((journey_time) => {
                        journey_time.departure = [0, 24];
                        journey_time.arrival = [0, 24]
                    })
                    dispatch(ClearFilter());
                    if (flight_groups[group_index + 1].journey_type !== 4) {
                        router.push(`/${window.url_name}/apps/flight-search/true`);
                    } else {
                        ManualRequestSearch(flight_groups[group_index + 1].manual_request, flight_groups, flight_providers, group_index + 1, dispatch, enqueueSnackbar, end_date, t, router);
                    }
                }
            }
        }).catch(function (error) {
            console.log(error);
            dispatch(SetCartError(error.responseText ? error.responseText : error.statusText));
            enqueueSnackbar(t("add_to_cart.failed"), { variant: 'error', disableWindowBlurListener: true });
        });
    }
}