export default (products_to_book) => {
    let pass_check = true;
    products_to_book.map((product) => {
        if (product.product_type === 11 && product.is_displayed === true && (product.booking_status === null || (product.booking_status !== null && product.booking_status.status_booking === "ERROR")) && product.custom_product?.need_pax_weight === true) {
            product.group_passenger?.travelers_list.map((traveler) => {
                if (traveler.weight === null) {
                    pass_check = false;
                }
            });
        }
    });
    return pass_check;
};
