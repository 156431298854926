//---- Dependencies ----//
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { withRouter } from "react-router";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import i18n from "../../../i18n.jsx";
import SetCookie from "../../Common/Functions/SetCookie";
import GetCookie from "../../Common/Functions/GetCookie";

//---- Functions ----//
import SearchClient from "./Functions/SearchClient.js";
import GetTrip from "../../Base/Functions/GetTrip";
import GetTripMenu from "../LeftMenuPanel/Functions/GetTrip.js";
import HandleError from "../../Common/Functions/HandleError";
import IsInclusivelyAfterDayPlusOne from "../../Menu/TripList/Functions/IsInclusivelyAfterDayPlusOne";

//---- Actions ----//
import { SetTripToDuplicateAndOpenDuplicateModalAndSetStep } from "../../../Actions/Menu";
import { Flush } from "../../../Actions/Flush";
import { ToggleAndSetLanguage } from "../../../Actions/Header";

//---- Component ----//
import PassengerParametersUpgrade from "../../Common/PassengerParametersUpgrade.jsx";
import Notify from "../../Common/Notify";

//---- Class ----//
@connect((store) => {
    return {
        duplicate_modal: store.menu.duplicate_modal,
        trip_to_duplicate: store.menu.trip_to_duplicate,
        duplicate_step: store.menu.duplicate_step,
        active_page: store.menu.active_page,
        trip_list_search: store.menu.trip_list_search,
        user: store.user.user,
        trip_list_ordering: store.menu.trip_list_ordering,
        trip_list_filter: store.menu.trip_list_filter,
        to_show_version: store.menu.to_show_version
    };
})
@withTranslation()
@withRouter
class DuplicateModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        const { user, trip_to_duplicate } = this.props;
        this.state = {
            start_date: moment.utc().add(1, "d"),
            focused_date: false,
            client_name: JSON.parse(localStorage.getItem("config")).quotation_code !== "marcovasco" ? (user !== null ? user.client_full.name : null) : "MARCO VASCO",
            client_id: JSON.parse(localStorage.getItem("config")).quotation_code !== "marcovasco" ? (user !== null ? user.client_full.id : null) : 22873,
            new_client_id: null,
            trip_name: null,
            note: null,
            pgi_ref: null,
            input_focus: 0,
            client_list: null,
            loading_info: false
        };
    }

    componentDidUpdate(prevProps) {
        const { trip_to_duplicate, user } = this.props;
        if (prevProps.trip_to_duplicate !== trip_to_duplicate) {
            this.setState({
                start_date: moment.utc().add(1, "d"),
                focused_date: false,
                client_name: JSON.parse(localStorage.getItem("config")).quotation_code !== "marcovasco" ? (user !== null ? user.client_full.name : null) : "MARCO VASCO",
                client_id: JSON.parse(localStorage.getItem("config")).quotation_code !== "marcovasco" ? (user !== null ? user.client_full.id : null) : 22873,
                new_client_id: null,
                input_focus: 0,
                client_list: null
            });
        }
    }

    duplicateTrip(trip_to_duplicate) {
        let { pass_check, headers } = CheckBeforeRequest();
        const { variant_arr, is_from, pid, hotel, trip_id, active_page, trip_list_ordering, trip_list_search, trip_list_filter, to_show_version, dispatch, user, t } = this.props;
        let component = this;
        let trip_index = (is_from === "Packages" || is_from === "Circuits") ? trip_id : trip_to_duplicate.id;
        let version = null;
        if (is_from === "Circuits") {
            for (let i = 0; i < variant_arr.length; i++) {
                if (variant_arr[i].selected === true) {
                    version = variant_arr[i].data_trip.id;
                }
            }
        } else if (!(is_from === "Packages")) {
            if (to_show_version !== null) {
                version = to_show_version;
            } else {
                version = trip_to_duplicate.current_version;
            }
        }
        let url = `${API_HREF}client/${window.id_owner}/trip/duplication/?id=${trip_index}&start_date=${moment.utc(this.state.start_date).format("YYYY-MM-DD")}&trip_type=0`;

        if (version !== null) {
            url += "&version_id=" + version;
        }
        if (this.state.new_client_id !== null) {
            url += "&new_client=" + this.state.new_client_id;
        } else if (JSON.parse(localStorage.getItem("config")).quotation_code === "marcovasco") {
            url += "&new_client=22873";
        }
        if (user.client_full.type === 2 && JSON.parse(localStorage.getItem("config")).quotation_code !== "marcovasco") {
             url += "&new_client=" + user.client_full.id;
        }
        if (user.client_full.type !== 2) {
            if (this.state.pgi_ref !== null) {
                url += "&pgi_ref=" + this.state.pgi_ref;
            }
            if (this.state.note !== null) {
                url += "&note=" + this.state.note;
            }
            if (this.state.trip_name !== null) {
                url += "&trip_name=" + this.state.trip_name;
            }
        }
        let hotel_option = [];
        if (is_from === "Packages") {
            pid.cart.map((cart) => {
                if (cart.is_optional === true && cart.product_type === 0) {
                    hotel_option.push(cart);
                }
            });
            let all_product_to_add = [];
            for (let i = 0; i < pid.simple_products.length; i++) {
                all_product_to_add.push(pid.simple_products[i].id);
            }
            for (let i = 0; i < pid.selected_assurance.length; i++) {
                all_product_to_add.push(pid.selected_assurance[i]);
            }
            for (let i = 0; i < pid.selected_option.length; i++) {
                all_product_to_add.push(pid.selected_option[i]);
            }
            if (pid.selected_variant !== null) {
                let has_option = false;
                if (hotel !== null && hotel.length > 0) {
                    for (let i = 0; i < hotel.length; i++) {
                        if (hotel[i].id === pid.selected_variant) {
                            pid.selected_option.map((option) => {
                                hotel_option.map((tmp_hotel) => {
                                    if (tmp_hotel.id === option) {
                                        if (tmp_hotel.variant === hotel[i].variant) {
                                            has_option = true;
                                        }
                                    }
                                });
                            });
                            if (!has_option) {
                                all_product_to_add.push(pid.selected_variant);
                            }
                        }
                    }
                }
            } else {
                if (hotel !== null && hotel.length > 0) {
                    for (let i = 0; i < hotel.length; i++) {
                        if (hotel[i].variant === null) {
                            all_product_to_add.push(hotel[i].id);
                            break;
                        }
                    }
                }
            }
            for (let i = 0; i < all_product_to_add.length; i++) {
                 url += "&pid=" + all_product_to_add[i];
            }
        }

        if (pass_check) {
            this.setState({
                loading_info: true
            }, function () {
                Notify(t("menu.trip_list.duplicate_running"), {status: 'success', pos: 'top-center'});
            });
            $.ajax({
                method: "GET",
                url: url,
                headers: headers,
                success: function (data) {
                    let user_token = GetCookie("token");
                    let id_user = GetCookie("id_user");
                    let client_user = GetCookie("client_user");
                    let current_version = data.current_version;
                    //Delete the cookie
                    let cookies = document.cookie.split("; ");
                     for (let c = 0; c < cookies.length; c++) {
                         let d = window.location.hostname.split(".");
                         while (d.length > 0) {
                             let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
                             let p = location.pathname.split("/");
                             document.cookie = cookieBase + "/";
                             while (p.length > 0) {
                                 document.cookie = cookieBase + p.join("/");
                                 p.pop();
                             }
                             d.shift();
                         }
                     }
                    SetCookie("trip_id", data.id, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                    SetCookie("trip_id_version", current_version, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                    SetCookie("trip_token", data.token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                    SetCookie("token", user_token, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                    SetCookie("id_user", id_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                    SetCookie("client_user", client_user, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
                    dispatch(Flush());
                    dispatch(ToggleAndSetLanguage(false, i18n.language));
//                    GetTrip(dispatch, t);
                    GetTripMenu(active_page, trip_list_ordering, trip_list_search, trip_list_filter, dispatch);

                    component.setState({
                        loading_info: false
                    });
                    // if (user.client_full !== undefined && user.client_full.type !== 2) {
                    //     component.props.router.push(`/${window.url_name}/apps/steps`);
                    // } else {
                        component.props.router.push(`/${window.url_name}/apps/itinerary`);
                    // }
                    dispatch(SetTripToDuplicateAndOpenDuplicateModalAndSetStep(data, true, 2));
                },
                error: function (error) {
                    HandleError(error.responseJSON);

                    component.setState({
                        loading_info: false
                    });
                    console.log(error.responseJSON);
                }
            });
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({input_focus:0});
        }
    }
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    render() {
        const { is_from, trip_to_duplicate, duplicate_modal, dispatch, t, user, duplicate_step } = this.props;
        return (
            <Modal id={ "modal-duplicate" } toggle={() => { dispatch(SetTripToDuplicateAndOpenDuplicateModalAndSetStep(null, false, 1)); }} backdrop={"static"} isOpen={ duplicate_modal } size={"lg"} >
                <div>
                    <ModalHeader className={"block-center text-center"} toggle={() => { dispatch(SetTripToDuplicateAndOpenDuplicateModalAndSetStep(null, false, 1)); }}>
                        <div id={"modal-connection-header"}>
                            <h1 className={"title-connection"}> { is_from === "TripList" ? t("menu.trip_list.duplicate_trip") : (is_from === "ItineraryType" ? t("menu.trip_list.new_itinerary") : duplicate_step === 2 ? t("menu.trip_list.room_composition") : t("menu.trip_list.use_trip"))  } </h1>
                        </div>
                    </ModalHeader>
                    <ModalBody className={"grey-bg ft-gray"}>
                        {
                            duplicate_step === 1 && (
                                <Fragment>
                                    {
                                        user !== null && user.client_full.type !== 2 && (
                                            <div className={""} >
                                                <h5 className={"ft-light-green"} > { is_from === "TripList" ? t("menu.trip_list.change_client") : t("menu.trip_list.chose_client") } </h5>
                                                <div className={"col-md-12"} >
                                                    <input ref={"client_name"} id={ "modal-duplicate-client-input" } autoComplete={ "off" } onFocus={() => {
                                                        this.setState({
                                                            input_focus: 1
                                                        });
                                                    }} onChange={(event) => {
                                                        SearchClient(this, event.target.value);
                                                     }} type={"text"} defaultValue={ is_from === "TripList" ? this.state.client_name : null} className={"ft-basic-input"}/>
                                                    {
                                                        this.state.input_focus === 1 && this.state.client_list !== null && (
                                                            <div ref={this.setWrapperRef} className={"ft-basic-container-for-selector"}>
                                                            {
                                                                this.state.client_list.map((client_info, index) => {
                                                                    return (
                                                                        <div key={ index } className={ "" } onClick={ () => {
                                                                            this.setState({
                                                                                client_name: JSON.parse(localStorage.getItem("config")).quotation_code !== "marcovasco" ? `${client_info.name}${client_info.street !== null ? " / " + client_info.street : ""}${(client_info.zip_code !== null ? ", " + client_info.zip_code : "")}${(client_info.city !== null ? ", " + client_info.city : "")}` : client_info.name,
                                                                                new_client_id: client_info.id,
                                                                                input_focus: 0
                                                                            });
                                                                            this.refs.client_name.value = JSON.parse(localStorage.getItem("config")).quotation_code !== "marcovasco" ? `${client_info.name}${client_info.street !== null ? " / " + client_info.street : ""}${(client_info.zip_code !== null ? ", " + client_info.zip_code : "")}${(client_info.city !== null ? ", " + client_info.city : "")}` : client_info.name;
                                                                        } }>
                                                                            <span> { JSON.parse(localStorage.getItem("config")).quotation_code !== "marcovasco" ? `${client_info.name}${client_info.street !== null ? " / " + client_info.street : ""}${(client_info.zip_code !== null ? ", " + client_info.zip_code : "")}${(client_info.city !== null ? ", " + client_info.city : "")}` : client_info.name } </span>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className={"row col-md-12 mt15"} >
                                                    <h5 className={"ft-light-green"} > { t("menu.trip_list.more_info_on_duplicate") } </h5>
                                                    <div className={"col-md-12"} >
                                                        <input className={"ft-basic-input"} name={"pgi"} type={ "text" } placeholder={ JSON.parse(localStorage.getItem("config")).quotation_code !== "marcovasco" ? t("menu.trip_list.pgi") : t("menu.trip_list.sugar") } onChange={(e) => { this.setState({
                                                            pgi_ref: e.target.value
                                                        }); }} />
                                                    </div>
                                                    <div className={"col-md-12 mt15"} >
                                                        <input className={"ft-basic-input col-md-12"} name={"trip_name"} type={ "text" } placeholder={t("cart.trip_name")} onChange={(e) => { this.setState({
                                                            trip_name: e.target.value
                                                        }); }} />
                                                    </div>
                                                    <div className={"col-md-12 mt15"} >
                                                        <textarea className={"ft-basic-input col-md-12"} name={"note"} type={ "text" } placeholder={t("placeholder.custom_information")} onChange={(e) => { this.setState({
                                                            note: e.target.value
                                                        }); }} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className={"mt15"} >
                                        <h5 className={"ft-light-green"} >  { is_from === "TripList" ? t("menu.trip_list.change_dep_date") : t("menu.trip_list.new_dep_date") }</h5>
                                        <div className={ "col-md-12" + (window.innerHeight < 850 && user.client_full.type !== 2 ? " date-picker-up" : "") }>
                                            <SingleDatePicker
                                                id={"start_date_picker"}
                                                placeholder={t("home.modal.start_date_placeholder")}
                                                date={ this.state.start_date !== null ? moment.utc(this.state.start_date) : null}
                                                onDateChange={(date) => {
                                                    this.setState({
                                                        start_date: date,
                                                        focused_date: false
                                                    });
                                                }}
                                                focused={ this.state.focused_date }
                                                onFocusChange={( focused ) => {
                                                    this.setState({focused_date: focused.focused});
                                                }}
                                                readOnly
                                                numberOfMonths={ 1 }
                                                isOutsideRange={ IsInclusivelyAfterDayPlusOne }
                                                hideKeyboardShortcutsPanel
                                                openDirection={ (window.innerHeight < 850 && user.client_full.type !== 2 ? "up": "down") }
                                                verticalSpacing={ 10 }
                                            />
                                        </div>
                                    </div>
                                    {
                                        this.state.loading_info === false && (
                                            <div className={"mt20"}>
                                                <div className={"btn-orange ft-fit block-center text-center"} onClick={() => {
                                                    this.duplicateTrip(trip_to_duplicate);
                                                }}>
                                                    { is_from === "TripList" ? t("menu.trip_list.duplicate_trip_validate") : (is_from === "ItineraryType" ? t("menu.trip_list.new_itinerary") : t("menu.trip_list.use_trip_button")) }
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        this.state.loading_info === true && (
                                            <div className={"mt20"} >
                                                <div className={"spinner-orange"} />
                                            </div>
                                        )
                                    }
                                </Fragment>
                            )
                        }
                        {
                            duplicate_step === 2 && (
                                <PassengerParametersUpgrade with_data={ JSON.parse(localStorage.getItem("config")).quotation_code === "verdie" }/>
                            )
                        }
                    </ModalBody>
                </div>
            </Modal>
        );
    }
}

export default DuplicateModal;
