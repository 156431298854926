import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";
import { uniqBy } from "lodash";
import { Provider } from "../Common/objects/provider";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    value: Provider | null,
    onChange: (provider: Provider | null) => void
}

export function CartPackageProviderPicker(props: Props): JSX.Element {
    const { t } = useTranslation();
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
    const options = useMemo(() => {
        return uniqBy(
            providers.concat(manual_providers),
            (item) => item.provider.name
        );
    }, [providers, manual_providers]);

    return (
        <Autocomplete
            size="small"
            options={options}
            value={props.value}
            isOptionEqualToValue={(option, value) => option.provider.id === value.provider.id}
            getOptionLabel={(option) => option.provider.name}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t<string>('cart-material.cart-construction-package-provider')}
                />
            )}
            onChange={(_, value) => props.onChange(value)}
            fullWidth
        />
    );
}
