//---- Dependencies ----//
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
//---- Core ----// 
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
//---- Icons ----//
import Lock from '@material-ui/icons/LockOutlined';
import LockOpen from "@material-ui/icons/LockOpenOutlined";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Close from '@material-ui/icons/Close';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
//---- utils ----//
import localeText from "../Functions/localeText";
import i18n from '../../i18n';

const useStyles = makeStyles(theme => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    pink: {
        color: "#E0368D"
    },
    listContainer: {
        "&& ul": {
            listStyleType: "'-'",
            paddingInlineStart: "0px",
            marginBottom: 4,
            "& li": {
                paddingLeft: 5
            }
        }
    },
    floatRight: {
        float: "right"
    },
    alignRight: {
        textAlign: "right"
    },
    alignLeft: {
        textAlign: "left"
    },
    alignCenter: {
        textAlign: "center"
    },
    justify: {
        textAlign: "justify"
    },
}));

const VisiteursMoreInfo = ({info, moreInfoModal, setMoreInfoModal, type}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const locales = useSelector(state => state.user.locales);
    const current_locale = locales.find((el) => {return el.language_code === i18n.language});

    const handleMoreInfo = (event) => {
        setMoreInfoModal(!moreInfoModal);
    };
    if (info !== undefined) {
        return (
            <Dialog open={moreInfoModal} onClose={handleMoreInfo} fullScreen={ fullScreen } fullWidth>
                <DialogTitle>
                    <Grid container justify={ "space-between" } alignItems={ "center" }>
                        {
                            fullScreen ? (
                                <Grid item>
                                    <IconButton edge={ "start" } onClick={ handleMoreInfo }><NavigateBefore/></IconButton> { localeText(current_locale.id, info.localization, info.name, 'name') }
                                </Grid>
                            ) : (
                                <Fragment>
                                    <Grid item>
                                        { localeText(current_locale.id, info.localization, info.name, 'name') }
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={ handleMoreInfo }><Close/></IconButton>
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{ padding: 8 }}>
                        <Grid container direction={ 'column' }>
                            <Grid item>
                            {
                                type === 'circuit' &&
                                <Typography>{''}</Typography>
                            }
                            {
                                type === 'package' &&
                                <Typography>{'Farniente au bord d\'une plage paradisiaque ou escapade culturelle, il y a du choix ! Retrouvez notre sélection d\'hôtels et d\'activités complémentaires.'}</Typography>
                            }
                            {
                                type === 'itiType' &&
                                <Typography>{'Un itinéraire conçu par nos spécialistes, à personnaliser avec votre choix d\'hôtels, de véhicule et d\'activités.'}</Typography>
                            }
                            </Grid>
                            {
                                localeText(current_locale.id, info.localization, info.description !== undefined ? info.description : '', 'description') !== '' &&
                                <Grid container direction={"column"} spacing={2} style={{marginTop: 20}} className={classes.genericText}>
                                    <Grid item container xs style={{width: "100%"}} justify={"center"}>
                                        <Typography variant={"h4"} >{'Description'}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <span dangerouslySetInnerHTML={ {__html: localeText(current_locale.id, info.localization, info.description !== undefined ? info.description : '', 'description')} }/>
                                    </Grid>
                                </Grid>
                            }
                            {
                                info.strengths !== undefined && info.strengths !== null && info.strengths.length > 0 && (
                                    <Grid item>
                                        <Grid container direction={"column"} spacing={2} style={{marginTop: 20}} className={classes.genericText}>
                                            <Grid item container xs style={{width: "100%"}} justify={"center"}>
                                                <Typography variant={"h4"} >{t("itineraryType.strong_points")}</Typography>
                                            </Grid>
                                            <Grid item>
                                            {
                                                info.strengths.map((strength, strength_index) => {
                                                    return (
                                                        <div className={classes.listContainer} key={strength_index} ><ul><li>{ strength }</li></ul></div>
                                                    )
                                                })
                                            }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            {
                                localeText(current_locale.id, info.localization, info.sale_pitch !== undefined ? info.sale_pitch : '', 'sale_pitch') !== '' &&
                                <Grid container direction={"column"} spacing={2} style={{marginTop: 20}} className={classes.genericText}>
                                    <Grid item container xs style={{width: "100%"}} justify={"center"}>
                                        <Typography variant={"h4"} >{'Argumentaire de vente'}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <span dangerouslySetInnerHTML={ {__html: localeText(current_locale.id, info.localization, info.sale_pitch !== undefined ? info.sale_pitch : '', 'sale_pitch')} }/>
                                    </Grid>
                                </Grid>
                            }
                            </Grid>
                        <Grid container direction={"row"} className={classes.genericText} justify={"space-between"} spacing={2}>
                        {
                            info.advantage !== undefined && info.advantage !== null && info.advantage.length > 0 && (
                                <Grid item sm={4} xs={12}>
                                    <p style={{fontSize: 15, fontWeight: "bold", marginBottom: 2}}>{t("itineraryType.avantages")}</p>
                                    {
                                        info.advantage.map((advantage, advantage_index) => {
                                            return (
                                                <div key={ advantage_index } className={"col-md-12"}>
                                                    <div className={`${classes.font12} ${classes.listContainer}`}>
                                                        <ul style={{float:"inherit"}}><li>{ advantage.title }</li></ul>
                                                    </div>
                                                    <div className={`${classes.font12} ${classes.italic} ${classes.justify}`} style={{marginLeft: 4, marginBottom: 10, marginTop: "-8px"}}> {advantage.description} </div>
                                                </div>
                                            );
                                        })
                                    }
                                </Grid>
                            )
                        }
                        {
                            info.included_in_price !== undefined && info.included_in_price !== null && info.included_in_price.length > 0 && (
                                <Grid item sm={4} xs={12}>
                                    <p style={{fontSize: 15, fontWeight: "bold", marginBottom: 2}}>{t("itineraryType.price_includes")}</p>
                                    {
                                        info.included_in_price.map((include, include_index) => {
                                            return (
                                                <div key={ include_index } className={"col-md-12"}>
                                                    <div className={`${classes.font12} ${classes.listContainer}`}>
                                                        <ul style={{float:"inherit"}}><li>{ include }</li></ul>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </Grid>
                            )
                        }
                        {
                            info.extra !== undefined && info.extra !== null && info.extra.length > 0 && (
                                <Grid item sm={4} xs={12}>
                                    <p style={{fontSize: 15, fontWeight: "bold", marginBottom: 2}}>{t("itineraryType.supplements")}</p>
                                    {
                                        info.extra.map((extra, extra_index) => {
                                            return (
                                                <div key={ extra_index } className={"col-md-12"}>
                                                    <div className={`${classes.font12} ${classes.listContainer}`}>
                                                        <ul style={{float:"inherit"}}>
                                                            <li>
                                                                { extra.description }
                                                                <span className={`${classes.pink} ${classes.alignRight} ${classes.floatRight} ${classes.bold}`}>{extra.price}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </Grid>
                            )
                        }
                        {
                            info.child_reduction !== undefined && info.child_reduction !== null && info.child_reduction.length > 0 && (
                                <Grid item sm={4} xs={12}>
                                    <p style={{fontSize: 15, fontWeight: "bold", marginBottom: 2}}>{t("itineraryType.child_reduction")}</p>
                                    {
                                        info.child_reduction.map((child_reduction, child_reduction_index) => {
                                            return (
                                                <div key={ child_reduction_index } className={"col-md-12"}>
                                                    <div className={`${classes.font12} ${classes.listContainer}`}>
                                                        <ul style={{float:"inherit"}}>
                                                            <li>
                                                                { child_reduction.description }
                                                                <span className={`${classes.pink} ${classes.alignRight} ${classes.floatRight} ${classes.bold}`}>{child_reduction.price}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </Grid>
                            )
                        }
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
    return null;
};
export default VisiteursMoreInfo;