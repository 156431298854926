import React from "react";
import { RiBuilding2Line } from "react-icons/ri";

type Props = {
    fontSize?: string | number
}

export function CartPoiIcon(props: Props): JSX.Element {
    return (
        <RiBuilding2Line
            style={{
                fontSize: props.fontSize === 'medium' ?
                    '1.5rem' :
                    props.fontSize
            }}
        />
    );
}
