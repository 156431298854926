//---- Dependencies ----//
import React from "react";

//---- CSS ----//
import "../../Style/PlaneLoader.css";

//---- Class----//
class PlaneLoader extends React.Component {
	render() {
		return (
			<div>
				<div className={"plane-loader"}>
					  <div className={"cloud cloud1"}/>
					  <div className={"cloud cloud4"}/>
					  <div className={"cloud cloud3"}/>
					  <div className={"plane-img"}/>
					  <div className={"cloud cloud2"}/>
					  <div className={"steam steam1"}>
					    	<div className={"c1"}/>
					    	<div className={"c2"}/>
					    	<div className={"c3"}/>
					    	<div className={"c4"}/>
					    	<div className={"c5"}/>
					    	<div className={"c6"}/>
					    	<div className={"c7"}/>
					    	<div className={"c8"}/>
					    	<div className={"c9"}/>
					    	<div className={"c10"}/>
					  </div>
					  <div className={"steam steam2"}>
						    <div className={"c1"}/>
						    <div className={"c2"}/>
						    <div className={"c3"}/>
						    <div className={"c4"}/>
						    <div className={"c5"}/>
						    <div className={"c6"}/>
						    <div className={"c7"}/>
						    <div className={"c8"}/>
						    <div className={"c9"}/>
						    <div className={"c10"}/>
					  </div>
					  <div className={"steam steam3"}>
						    <div className={"c1"}/>
						    <div className={"c2"}/>
						    <div className={"c3"}/>
						    <div className={"c4"}/>
						    <div className={"c5"}/>
						    <div className={"c6"}/>
						    <div className={"c7"}/>
						    <div className={"c8"}/>
						    <div className={"c9"}/>
						    <div className={"c10"}/>
					  </div>
					  <div className={"steam steam4"}>
						    <div className={"c1"}/>
						    <div className={"c2"}/>
						    <div className={"c3"}/>
						    <div className={"c4"}/>
						    <div className={"c5"}/>
						    <div className={"c6"}/>
						    <div className={"c7"}/>
						    <div className={"c8"}/>
						    <div className={"c9"}/>
						    <div className={"c10"}/>
					  </div>
				</div>
			</div>
		);
	}
}
export default PlaneLoader;
