//---- Dependencies ----//
import React, {Fragment, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import i18n from "../../i18n";

import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Chip from '@material-ui/core/Chip';
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";

import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { SetHistorydest, SetItineraryTypeData, SetItineraryTypeLoading } from "../../Actions/ItineraryType";
import { DestinationChange } from "../../Actions/Header";
import MaterialDuplicateDialog from "../ItineraryType/MaterialDuplicateDialog";
import GetNewChildren from "../ItineraryType/Functions/GetNewChildren";
import Footer from "../Footer/Footer";



const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    headerPicture: {
        height: "50vh"
    },
    absoluteCenter: {
        margin: 0,
        position: "absolute",
        top: "24%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    customButton: {
        background: "#BF202D",
        // padding: "15px 40px",
        textAlign: "center",
        color: "#fff",
        fontSize: 20,
        textTransform: "none",
        "&:hover": {
            background: "#cc4f2a"
        }
    },
    headerContainer: {
        height: "50vh",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    },
    destinationTitle: {
        textAlign: "center",
        color: "white",
        textShadow: "black 1px 0 10px",
        fontFamily: "Roboto",
        marginBottom: 50,
        fontWeight: 400
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    imgContainer: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxHeight: 450
        },
        maxHeight: 450
    },
    coverImg: {
        display: 'block',
        width: '100%',
        objectFit: 'cover',
        maxHeight: 107,
        objectPosition: '25% 55%',
        [theme.breakpoints.up('sm')]: {
            maxHeight: 310,
        },
    },
    largeLogo: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
    },
    underline: {
        textDecoration: 'underline'
    },
    pointer: {
        cursor: 'pointer'
    }
}));


const VisiteursDestinationFilters = ({destinations}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const config = JSON.parse(localStorage.getItem("config"));
    const quotation_code = config.quotation_code;

    const filters_type = useSelector(store => store.home.filters_type);
    const list_type = useSelector(store => store.home.list_type);
    const filter_destinations = useSelector(store => store.home.filter_destinations);

    // const product_type = {
    //     0: t('itineraryType.packages'),
    //     1: t('itineraryType.circuits'),
    //     2: t('itineraryType.itinerary_type')
    // }
    const product_type = [
        t('itineraryType.packages'),
        t('itineraryType.circuits'),
        t('itineraryType.itinerary_type')
    ]

    const handleChangeFilterType = (event) => {
        dispatch({type: 'HOME_SET_FILTERS_TYPE', payload: event.target.value});
    };
    const handleReset = () => {
        dispatch({type: 'HOME_RESET_FILTERS'});
    };
    const handleListType = () => {
        dispatch({type: 'HOME_LIST_TYPE', payload: list_type === 'list' ? 'card' : 'list'})
    };
    return (
        <Grid container direction={"row"} justify={'space-between'} alignItems={"center"} spacing={4}>
            <Grid item md={ 8 } xs={ 12 }>
                {/* <MaterialBreadcrumb router={{destinations}.router}/> */}
                <Grid container spacing={ 2 }>
                    <Grid item md={ 6 } xs={ 12 }>
                        <Autocomplete
                            fullWidth
                            multiple
                            id={'filter-destinations'}
                            options={destinations}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option}
                            value={filter_destinations}
                            renderTags={(value) => {
                                let value_length = value.length;
                                return (
                                    <span style={{marginLeft: 20}}>
                                    {
                                        value_length !== destinations.length ? value_length + " " + t("global.over") + " " + destinations.length + " destinations" : "Toutes les destinations (" + value_length + ")"
                                    }
                                    </span>
                                )
                            }}
                            renderOption={(option, { selected }) => (
                                <Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                </Fragment>
                            )}
                            onChange={(event, newValue) => {
                                dispatch({type: 'HOME_SET_FILTER_DESTINATIONS', payload: newValue});
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant={'outlined'} label={t('documentation.destinations')} />
                            )}
                        />
                    </Grid>
                    <Grid item md={ 5 } xs={ 12 }>
                        <Autocomplete
                            fullWidth
                            multiple
                            id={'filter-type'}
                            options={product_type}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option}
                            value={filters_type}
                            renderTags={(value) => {
                                let value_length = value.length;
                                return (
                                    <span style={{marginLeft: 20}}>
                                    {
                                        value_length !== product_type.length ? value_length + " " + t("global.over") + " " + product_type.length + " types" : "Touts les types (" + value_length + ")"
                                    }
                                    </span>
                                )
                            }}
                            renderOption={(option, { selected }) => (
                                <Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                </Fragment>
                            )}
                            onChange={(event, newValue) => {
                                dispatch({type: 'HOME_SET_FILTERS_TYPE', payload: newValue});
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant={'outlined'} label={'Types'} />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{paddingTop: 30}}>
                <Grid container spacing={ 4 }>
                    <Grid item className={`${classes.underline} ${classes.pointer}`} onClick={handleListType}>{list_type === 'card' ? t('visiteurs_destination_filters.display_list') : t('visiteurs_destination_filters.display_card')}</Grid>
                    <Grid item className={`${classes.underline} ${classes.pointer}`} onClick={handleReset}>{t('flight_search.remove_filters')}</Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};
export default VisiteursDestinationFilters;