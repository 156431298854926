/*eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import '../../crm.css';
import { SetSelectedEmail } from "../../../../../Actions/Crm";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { TripListSendMailForm } from '../../../MaterialTripList/TripListSendMailForm';
import { setLoading } from '../../../MaterialTripList/MailVisualEditor/redux/actions';
import { useEmailsHistory } from "../../../MaterialTripList/Functions/useEmailsHistory";

const CrmUserDetailEmailDialog = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [refresh] = useEmailsHistory();

    const selected_email = useSelector(store => store.crm.selected_email);

    const handleClose = () => {
        dispatch(SetSelectedEmail(null));
    };
    return (
        <Dialog open={Boolean(selected_email)}>
            <div className="note-dialog-container">
                <div>
                    <p>{t("crm.create_email")}</p>
                </div>
                <TripListSendMailForm
                    onRefresh={refresh}
                    setLoading={(loading) => dispatch(setLoading(loading))}
                    onClose={() => handleClose()}
                />
            </div>
        </Dialog>
    );
};
export default CrmUserDetailEmailDialog;
