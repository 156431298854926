import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/lab/Alert";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import ConvertTime from "../Functions/ConvertTime.js";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize9: {
        fontSize: 9
    },
    fontSize12: {
        fontSize: 12
    },
    uppercase: {
        textTransform: "uppercase"
    },
    red: {
        color: "red"
    },
    textCenter: {
        textAlign: "center"
    },
    spacer: {
        padding: 8
    },
    warningIcon: {
        color: "#FFB050",
        fontSize: 50,
        marginTop: 4
    },
    warningIconSmall: {
        color: "#FFB050",
        fontSize: 40,
        marginTop: 4
    }
}));

const ImportantInfo = ({ flight, small }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    const language = useSelector(store => store.header.language);
    const airports = useSelector(store => store.flight_search.airports);
    const stations = useSelector(store => store.flight_search.stations);
    const isUpdate = useSelector(store => store.flight_search.isUpdate);

    const [sameAirport, setSameAirport] = useState(true);
    const [originStations, setOriginStations] = useState(false);
    const [stopoversChange, setStopoversChange] = useState(false);
    const [secondaryAirports, setSecondaryAirports] = useState(false);
    const [arrivesDayAfter, setArrivesDayAfter] = useState(false);
    const [longStopovers, setLongStopovers] = useState(false);

    moment.locale(language);

    useEffect(() => {
        checkFlight();
    }, []);
    useEffect(() => {
        checkFlight();
    }, [flight]);

    const checkFlight = () => {
        let origin = null;
        let destination = null;

        if (flight.outbounds[0].legs[0].origin_airport !== undefined) {
            origin = airports[flight.outbounds[0].legs[0].origin_airport];
        } else {
            origin = stations[flight.outbounds[0].legs[0].origin_station];
        }

        if (flight.outbounds.slice(-1)[0].legs.slice(-1)[0].destination_airport !== undefined) {
            destination = airports[flight.outbounds.slice(-1)[0].legs.slice(-1)[0].destination_airport];
        } else {
            destination = stations[flight.outbounds.slice(-1)[0].legs.slice(-1)[0].destination_station];
        }

        if (origin.iata_city.city_code === destination.iata_city.city_code && origin.code !== destination.code) {
            setSameAirport(false);
        }
        let temp_stations = false;
        let temp_stopovers_change = false;
        let temp_secondary = [];
        let temp_day_after = false;
        let temp_long_stopovers = false;

        flight.outbounds.map((outbound) => {
            outbound.legs.map((leg, leg_index) => {
                if (leg.origin_station !== undefined) {
                    temp_stations = true;
                }
                if (leg_index + 1 < outbound.legs.length - 1) {
                    let first_stop = null;
                    let next_stop = null;
                    
                    if (leg.destination_airport !== undefined) {
                        first_stop = airports[leg.destination_airport];
                    } else {
                        first_stop = stations[leg.destination_station];
                    }
        
                    if (outbound.legs[leg_index + 1].origin_airport !== undefined) {
                        next_stop = airports[outbound.legs[leg_index + 1].origin_airport];
                    } else {
                        next_stop = stations[outbound.legs[leg_index + 1].origin_station];
                    }
        
                    if (first_stop.code !== next_stop.code) {
                        temp_stopovers_change = true;
                    }
                }

                if (leg.origin_airport !== undefined && airports[leg.origin_airport].weight > 2 && !temp_secondary.includes(airports[leg.origin_airport].international_name)) {
                    temp_secondary.push(airports[leg.origin_airport].international_name);
                }
                if (leg.destination_airport !== undefined && airports[leg.destination_airport].weight > 2 && !temp_secondary.includes(airports[leg.destination_airport].international_name)) {
                    temp_secondary.push(airports[leg.destination_airport].international_name);
                }

                if (leg_index < outbound.stopover_duration.length) {
                    let stopover_duration = outbound.stopover_duration[leg_index];
                    // long stopover if superior to 3 hrs = 10800 s
                    if (stopover_duration >= 10800) {
                        temp_long_stopovers = true;
                    }
                }
            });

            if (outbound.duration_days > 0) {
                temp_day_after = true;
            }
        });
        setOriginStations(temp_stations);
        setStopoversChange(temp_stopovers_change);
        setSecondaryAirports(temp_secondary.length !== 0);
        setArrivesDayAfter(temp_day_after);
        setLongStopovers(temp_long_stopovers);
    };
    const checkImportantInfo = (outbound) => {
        let to_render = [];
        let temp_day_after = null;
        if (outbound.duration_days > 0) {
            let outbound_dest = null;
            if (outbound.legs.slice(-1)[0].destination_airport !== undefined) {
                outbound_dest = airports[outbound.legs.slice(-1)[0].destination_airport];
            } else {
                outbound_dest = stations[outbound.legs.slice(-1)[0].destination_station];
            }
            let destination_str = outbound_dest.iata_city.name !== undefined ? outbound_dest.iata_city.name : outbound_dest.iata_city.international_name;
            destination_str += " - ";
            destination_str += outbound_dest.name !== undefined ? outbound_dest.name : outbound_dest.international_name;

            temp_day_after = {
                destination: destination_str,
                arrival_date: outbound.legs.slice(-1)[0].arrival_datetime_lt,
                days_after: outbound.duration_days
            };
        }
        outbound.legs.map((leg, leg_index) => {
            let temp_stations = null;
            let temp_stopovers_change = null;
            let temp_secondary = [];
            let temp_long_stopovers = null;
            if (leg.origin_station !== undefined) {
                temp_stations = stations[leg.origin_station];
            }
            if (leg_index + 1 < outbound.legs.length - 1) {
                let first_stop = null;
                let next_stop = null;
                
                if (leg.destination_airport !== undefined) {
                    first_stop = airports[leg.destination_airport];
                } else {
                    first_stop = stations[leg.destination_station];
                }
    
                if (outbound.legs[leg_index + 1].origin_airport !== undefined) {
                    next_stop = airports[outbound.legs[leg_index + 1].origin_airport];
                } else {
                    next_stop = stations[outbound.legs[leg_index + 1].origin_station];
                }
    
                if (first_stop.code !== next_stop.code) {
                    temp_stopovers_change = {
                        city: first_stop.iata_city.name !== undefined ? first_stop.iata_city.name : first_stop.iata_city.international_name,
                        first_stop: first_stop,
                        next_stop: next_stop
                    };
                }
            }

            if (leg.origin_airport !== undefined && airports[leg.origin_airport].weight > 2 && !temp_secondary.includes(airports[leg.origin_airport].international_name)) {
                temp_secondary.push(airports[leg.origin_airport].international_name);
            }
            if (leg.destination_airport !== undefined && airports[leg.destination_airport].weight > 2 && !temp_secondary.includes(airports[leg.destination_airport].international_name)) {
                temp_secondary.push(airports[leg.destination_airport].international_name);
            }

            if (leg_index < outbound.stopover_duration.length) {
                let stopover_duration = outbound.stopover_duration[leg_index];
                // long stopover if superior to 3 hrs = 10800 s
                if (stopover_duration >= 10800) {
                    let stop = null;
                    if (leg.destination_airport !== undefined) {
                        stop = airports[leg.destination_airport];
                    } else {
                        stop = stations[leg.destination_station];
                    }
                    temp_long_stopovers = {
                        city: stop.iata_city.name !== undefined ? stop.iata_city.name : stop.iata_city.international_name,
                        duration: ConvertTime(stopover_duration, "seconds", language, t)
                    };
                }
            }
            if (temp_stations !== null) {
                to_render.push(
                    <div className={classes.genericText} key={`${leg_index}-1`}>
                        { "- " + t("global.at") + " "}
                        { temp_stations.iata_city.name !== undefined ? temp_stations.iata_city.name : temp_stations.iata_city.international_name }
                        { ", " }
                        <span className={`${classes.genericText} ${classes.red}`}>
                            { t("flight_search.is_station") }
                            { temp_stations.name !== undefined ? temp_stations.name : temp_stations.international_name }
                        </span>
                    </div>
                );
            }
            if (temp_long_stopovers !== null) {
                to_render.push(
                    <div className={classes.genericText} key={`${leg_index}-2`}>
                        { "- " + t("flight_search.long_stopover") + " " }
                        <span className={`${classes.genericText} ${classes.red}`}>
                            { temp_long_stopovers.city }
                        </span>
                        { " " + t("global.from_1") + " " }
                        <span className={`${classes.genericText} ${classes.red}`}>
                            { temp_long_stopovers.duration }
                        </span>
                    </div>
                );
            }
            if (temp_secondary.length !== 0) {
                to_render.push(
                    <div className={classes.genericText} key={`${leg_index}-3`}>
                        { "- " }
                        {
                            temp_secondary.length > 1 ? t("flight_search.secondary_airports1") : t("flight_search.secondary_airport1")
                        }
                        <span className={`${classes.genericText} ${classes.red}`}> {temp_secondary.join(", ")} </span>
                        {
                            temp_secondary.length > 1 ? t("flight_search.secondary_airports2") : t("flight_search.secondary_airport2")
                        }
                    </div>
                );
            }
            if (temp_stopovers_change !== null) {
                to_render.push(
                    <div className={classes.genericText} key={`${leg_index}-4`}>
                        { "- " + t("global.at") + " "}
                        { temp_stopovers_change.city + ", " }
                        <span className={`${classes.genericText} ${classes.red}`}>
                            { t("flight_search.change_stopover") }
                            { temp_stopovers_change.first_stop.name !== undefined ? temp_stopovers_change.first_stop.name : temp_stopovers_change.first_stop.international_name }
                        </span>
                        {" " + t("global.and") + " "}
                        <span className={`${classes.genericText} ${classes.red}`}>
                            { temp_stopovers_change.next_stop.name !== undefined ? temp_stopovers_change.next_stop.name : temp_stopovers_change.next_stop.international_name }
                        </span>
                    </div>
                );
            }
        });
        if (temp_day_after !== null) {
            to_render.push(
                <div className={classes.genericText}>
                    { "- " + t("flight_search.arrive") + " " }
                    <span className={`${classes.genericText} ${classes.red}`}>
                        { moment(temp_day_after.arrival_date).format("dddd Do MMM") }
                    </span>
                    { " " + t("global.at") + " "}
                    <span className={`${classes.genericText} ${classes.red}`}>
                        { temp_day_after.destination }
                    </span>
                    { t("flight_search.thus") + " "}
                    <span className={`${classes.genericText} ${classes.red}`}>
                        { temp_day_after.days_after + " " }
                    </span>
                    { temp_day_after.days_after > 1 ? t("flight_search.days_after") : t("flight_search.day_after") }
                </div>
            );
        }
        return to_render;
    };
    return (
        <Grid style={{ minHeight: 47 }}>
            {/* { (!sameAirport || originStations.length !== 0 || stopoversChange.length !== 0 || secondaryAirports.length !== 0 || arrivesDayAfter.length !== 0 || longStopovers.length !== 0) && flight.name === null && <Divider /> } */}
            {
                (!sameAirport || originStations || stopoversChange || secondaryAirports || arrivesDayAfter || longStopovers) && (
                    <Grid className={classes.spacer}>
                        <Grid container alignItems="center" justify={isMobile ? "center" : "flex-start"} spacing={isMobile ? 0 : (small ? 1 : 3)} direction={isMobile ? "column" : "row"}>
                            <Grid item xs={isMobile ? 12 : 1} className={classes.textCenter}>
                                <ReportProblemIcon className={small ? classes.warningIconSmall : classes.warningIcon} />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 11} className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize12}`}>
                                <span className={`${classes.genericText} ${classes.red}`}>
                                    { t("flight_search.flight_info") }
                                </span>
                                { t("flight_search.flight_info1") }
                                {
                                    !sameAirport && (
                                        <div className={classes.genericText}>
                                            { "- " + t("global.at") + " "}
                                            {
                                                flight.outbounds[0].legs[0].origin_airport !== undefined && ( airports[flight.outbounds[0].legs[0].origin_airport].iata_city.name !== undefined ? airports[flight.outbounds[0].legs[0].origin_airport].iata_city.name : airports[flight.outbounds[0].legs[0].origin_airport].iata_city.international_name)
                                            }
                                            {
                                                flight.outbounds[0].legs[0].origin_station !== undefined && ( stations[flight.outbounds[0].legs[0].origin_station].iata_city.name !== undefined ? stations[flight.outbounds[0].legs[0].origin_station].iata_city.name : stations[flight.outbounds[0].legs[0].origin_station].iata_city.international_name)
                                            }
                                            { ", " }
                                            <span className={`${classes.genericText} ${classes.red}`}>
                                                { t("flight_search.flight_info_go") }
                                                {
                                                    flight.outbounds[0].legs[0].origin_airport !== undefined && (airports[flight.outbounds[0].legs[0].origin_airport].name !== undefined ? airports[flight.outbounds[0].legs[0].origin_airport].name : airports[flight.outbounds[0].legs[0].origin_airport].international_name)
                                                }
                                                {
                                                    flight.outbounds[0].legs[0].origin_station !== undefined && (stations[flight.outbounds[0].legs[0].origin_station].name !== undefined ? stations[flight.outbounds[0].legs[0].origin_station].name : stations[flight.outbounds[0].legs[0].origin_station].international_name)
                                                }
                                            </span>
                                            {" " + t("global.and") + " " }
                                            <span className={`${classes.genericText} ${classes.red}`}>
                                                { t("flight_search.flight_info_from") }
                                                { 
                                                    flight.outbounds.slice(-1)[0].legs.slice(-1)[0].destination_airport !== undefined && (airports[flight.outbounds.slice(-1)[0].legs.slice(-1)[0].destination_airport].name !== undefined ? airports[flight.outbounds.slice(-1)[0].legs.slice(-1)[0].destination_airport].name : airports[flight.outbounds.slice(-1)[0].legs.slice(-1)[0].destination_airport].international_name)
                                                }
                                                { 
                                                    flight.outbounds.slice(-1)[0].legs.slice(-1)[0].destination_station !== undefined && (stations[flight.outbounds.slice(-1)[0].legs.slice(-1)[0].destination_station].name !== undefined ? stations[flight.outbounds.slice(-1)[0].legs.slice(-1)[0].destination_station].name : stations[flight.outbounds.slice(-1)[0].legs.slice(-1)[0].destination_station].international_name)
                                                }
                                            </span>
                                        </div>
                                    )
                                }
                                {
                                    flight.outbounds.map((outbound) => {
                                        return checkImportantInfo(outbound);
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            {
                flight.error_options !== undefined && flight.error_options !== null && (typeof flight.error_options !== 'string') && Array.isArray(flight.error_options) && flight.error_options.map((error, error_index) => {
                    return (
                        <Fragment key={error_index}>
                            {
                                Object.keys(error).map(key => {
                                    if (key === 'product_not_found') {
                                        return (
                                            <Alert key={key} severity={"error"} variant={'filled'}>{error[key]}</Alert>
                                        );
                                    }
                                })
                            }
                        </Fragment>
                    );
                })
            }
            {
                flight.error_options !== undefined && flight.error_options !== null && (typeof flight.error_options !== 'string') && !Array.isArray(flight.error_options) && Object.keys(flight.error_options).map(key => {
                    if (flight.error_options[key].includes('FLIGHT NOT FOUND')) {
                        return (
                            <Alert key={key} severity={"error"} variant={'filled'}>{t('cart-material.product-unavailable-2')}</Alert>
                        );
                    }
                    return (
                        <Alert key={key} severity={"error"} variant={'filled'}>{key} : { flight.error_options[key]}</Alert>
                    );
                })
            }
            {
                flight.error_options !== undefined && flight.error_options !== null && (typeof flight.error_options === 'string') && 
                    <Alert severity={"error"} variant={'filled'}>{ flight.error_options }</Alert>
            }
            {
                flight.error_options === undefined &&
                    <Alert severity={"error"} variant={'filled'}>{t('cart-material.cart-construction-error')}</Alert>
            }
        </Grid>
    );
};

export default ImportantInfo;
