export default (status) => {
    switch (status) {
        case "ONGOING" :
        case "QUOTATION" :
            return "yellow";
        case "VALIDATED" :
            return "pink";
        case "AGENCY_MOD" :
        case "TRANSMITTED" :
            return "lightgray";
        case "TO_UPDATED" :
        case "AMENDMENT" :
            return "bluemarin";
        case "USER_VALID":
            return "purple";
        case "TOBOOK" :
            return "darkpurple";
        case "DEPOSIT" :
            return "lightgreen";
        case "ONREQUEST":
            return "orange";
        case "BOOKED" :
        case "CONFIRMED" :
        case "FIXED_PV" :
            return "green";
        case "ADDITEM":
            return "lightpurple";
        case "CLOSED":
            return "black";
        case "CANCELED":
            return "red";
        case "CANCELLED":
        case "CANCELLED_CDV":
        case "CANCELLED_CGV":
            return "red";
        case "ARCHIVED":
            return "gray";
        case "INPROGRESS" :
        case "IN_PROGRESS" :
            return "cyan";
        case "FINISHED" :
        case "PROCESSED" :
            return "blue";
    } 
    return null;
};
