import { AccommodationCart } from "../../Itinerary/objects/accommodationCart";
import { Picture } from "../../Menu/MaterialTripList/picture/objects/picture";

export function getAccommodationPictures(accommodation: AccommodationCart, source?: 'provider'): Picture[] {
    if (accommodation.hide_pictures) {
        return [];
    }

    const hotel = accommodation.hotel[0];

    if (!source && (accommodation.overload_pictures?.length ?? 0) > 0) {
        return accommodation.overload_pictures ?? [];
    } else if ((hotel?.custom_pictures?.length ?? 0) > 0) {
        return hotel?.custom_pictures ?? [];
    } else if ((hotel?.pictures?.length ?? 0) > 0 && !hotel?.ignore_giata_pictures) {
        return hotel?.pictures ?? [];
    }

    return [];
}
