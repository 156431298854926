import React, { Fragment, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import PermIdentity from '@material-ui/icons/PermIdentity';
import Button from '@material-ui/core/Button';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import SquareFoot from '@material-ui/icons/SquareFoot';
import Timer from '@material-ui/icons/Timer';
import TimerOff from '@material-ui/icons/TimerOff';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import AccommodationVariant from './Variant';
import GetPictureBySize from '../Functions/GetPictureBySize';
import { useTranslation } from 'react-i18next';
import Hotel from '@material-ui/icons/Hotel';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Assignment from '@material-ui/icons/Assignment';
import CurrencyFormat from './CurrencyFormat';
import { Link } from 'react-router';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { IoBedOutline } from "react-icons/io5";
import { Chip } from '@mui/material';
import { CiMemoPad, CiUser } from 'react-icons/ci';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
    inline: {
        display: 'inline-block',
        verticalAlign: 'super'
    },
    bold: {
        fontWeight: 'bold'
    },
    floatLeft: {
        float: 'left'
    },
    sliderDotContainer: {
        position: 'absolute',
        bottom: 0
    },
    sliderDot: {
        fill: 'rgba(255, 255, 255, 0.54)',
        fontSize: '0.7rem'
    },
    sliderDotActive: {
        fill: 'white',
        fontSize: '0.7rem'
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paperActive: {
        borderColor: 'blue',
        borderWidth: 2
    },
    image: {
        width: 320,
        height: 240,
        overflow: 'hidden',
        borderRadius: 8,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    img: {
        margin: 'auto',
        display: 'block',
        objectFit: 'cover',
        width: '100%',
        height: '100%'
    },
    priceFromContainer: {
        background: grey[200],
        borderRadius: 8,
        marginTop: 8
    },
    priceFrom: {
        backgroundColor: 'white',
        marginTop: '-12px',
        padding: '2px 8px 2px 8px',
        borderRadius: 14,
        marginRight: 8
    },
    priceFromNight: {
        textAlign: 'right',
        marginRight: 8
    },
    priceFromPrice: {
        textAlign: 'right',
        marginRight: 8
    },
    orangeButton: {
        backgroundColor: `${main_color}`,
        // backgroundColor: '#E6592F',
        color: 'white',
        borderRadius: 24
    },
    greenButton: {
        backgroundColor: `${main_color}`,
        color: 'white',
        borderRadius: 24
    },
    variantContainer: {
        marginTop: 4,
        marginBottom: -6
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    marginRightSmall: {
        marginRight: 2
    },
    smallIcon: {
        fontSize: 12,
        marginTop: 2
    },
    smallText: {
        fontSize: 12,
    },
    paddingBottom: {
        paddingBottom: theme.spacing(1)
    },
    buttonBorder: {
        backgroundColor: 'rgba(255, 255, 255, 0.54)',
        transition: 'background-color 1s',
        '&:hover, &:focus': {
            backgroundColor: 'white',
            color: 'black'
        }
    },
    leftChevron: {
        position: 'absolute',
        left: theme.spacing(2),
        top: 105
    },
    rightChevron: {
        position: 'absolute',
        right: theme.spacing(2),
        top: 105
    },
    relative: {
        position: 'relative'
    },
    currencyFontSize: {
        fontSize: '1.5rem'
    },
    marginTop: {
        marginTop: 8
    },
    additionalInformation: {
        '&::before': {
            content: '"..."',
            position: 'absolute',
            right: 15,
            bottom: 0,
            background: 'white',
            paddingLeft: 5
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            right: 15,
            width: '1em',
            height: '1em',
            marginTop: '0.2em',
            background: 'white'
        },
        overflow: 'hidden',
        position: 'relative',
        lineHeight: '1.5em',
        maxHeight: '10.5em',
        textAlign: 'justify',
        marginRight: '-1em',
        paddingRight: '1em'
    },
    checkInInfo: {
        cursor: 'pointer',
        float: 'right',
        marginLeft: 8,
        fontSize: 12,
        marginTop: 2
    },
    floatRight: {
        float: 'right'
    }
}));

const AccommodationRoom = ({index_room, provider_loaded, breakfast_included, lunch_included, dinner_included, all_inclusive, cancellable, diff_day, selected_room, room, onChange, onValidate, togglePictureModal, toggleMosaicModal}) => {
    console.log('room render');
    const classes = useStyles();
    const dispatch = useDispatch();
    const language = useSelector(state => state.header.tmp_language);
    const currency_list = useSelector(state => state.base.currency_list);
    const { t } = useTranslation();
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [currentPicture, setCurrentPicture] = useState(0);
    const [showChevron, setShowChevron] = useState(false);
    const picture_length = room.pictures.length;
    const onCheckInInfo = () => {
        dispatch({type: 'MATERIAL_SET_CHECK_IN_INFO', payload: room.check_in_instructions});
    };
    let dot = [];
    for (let i = 0; i < 5 && i < room.pictures.length; i++) {
        if (currentPicture === i) {
            dot.push(<FiberManualRecord className={ classes.sliderDotActive } key={ i } onClick={() => { setCurrentPicture(i); }}/>);
        } else {
            dot.push(<FiberManualRecord className={ classes.sliderDot } key={ i } onClick={() => { setCurrentPicture(i); }}/>);
        }
    }
    let currency = null;
    if (room.price_from_currency !== null) {
        currency = currency_list.find((currency) => {
            return currency.id === room.price_from_currency;
        });
    }
    return (
        <Paper id={ `room-${index_room}` } className={ `${classes.paper} ${index_room !== selected_room ? '' : classes.paperActive}` } variant={ 'outlined' } onMouseEnter={() => { setShowChevron(true); }} onMouseLeave={() => { setShowChevron(false); }}>
            <Grid container spacing={ 2 }>
                {
                    room.pictures.length > 0 &&
                    <Grid item className={ classes.relative }>
                        <ButtonBase className={ classes.image } onClick={() => { if (window.innerWidth > 959) { togglePictureModal(room.pictures); } else { toggleMosaicModal(room.pictures); } }}>
                            <img className={ classes.img } src={ room.pictures[currentPicture] !== undefined ? GetPictureBySize(room.pictures[currentPicture], 'MEDIUM') : '/Img/accommodation_default.jpg' } alt={ 'room picture' }/>
                            <div className={ classes.sliderDotContainer }>
                                { dot }
                            </div>
                        </ButtonBase>
                        {
                            showChevron &&
                            <Fragment>
                                <IconButton className={ `${classes.buttonBorder} ${classes.leftChevron}` } size={ 'small' } onClick={() => { setCurrentPicture(currentPicture > 0 ? currentPicture - 1 : (picture_length > 5 ? picture_length - 1 : 4)); }}>
                                    <ChevronLeft/>
                                </IconButton>
                                <IconButton className={ `${classes.buttonBorder} ${classes.rightChevron}` } size={ 'small' } onClick={() => { setCurrentPicture(currentPicture < (picture_length > 5 ? 4 : picture_length - 1) ? currentPicture + 1 : 0); }}>
                                    <ChevronRight/>
                                </IconButton>
                            </Fragment>
                        }
                    </Grid>
                }
                <Grid item xs container>
                    <Grid item xs>
                        <Typography className={ `${classes.paddingBottom} ${classes.bold}` }>{ room.description }</Typography>
                        {
                            room.variants[0].static_contract && provider_loaded !== null &&
                            (provider_loaded.logo !== null ? <img src={ provider_loaded.logo.url } height={ 20 }/> : <Typography>{ provider_loaded.name }</Typography>)
                        }
                        {
                            room.variants[0].bed_type !== null && room.variants[0].bed_type.map((bed_type, index_bed_type) => (
                                // <Chip key={ `bed-type-${index_bed_type}` } icon={<IoBedOutline />} style={{marginRight: 8}} label={ (bed_type === 'simple_bed' || bed_type === 'queen_size' || bed_type === 'king_size' || bed_type === 'twin_bed' || bed_type === 'double_bed' || bed_type === 'sofa_bed' || bed_type === 'baby_bed' || bed_type === 'rollaway_bed' || bed_type === 'bunk_bed' || bed_type === 'futon') ? t(`accommodation.${bed_type}`) : bed_type } variant="outlined" />
                                <div key={ `bed-type-${index_bed_type}` }>
                                    <IoBedOutline className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                    <Typography className={ classes.inline } style={{fontSize: 12}}>{ (bed_type === 'simple_bed' || bed_type === 'queen_size' || bed_type === 'king_size' || bed_type === 'twin_bed' || bed_type === 'double_bed' || bed_type === 'sofa_bed' || bed_type === 'baby_bed' || bed_type === 'rollaway_bed' || bed_type === 'bunk_bed' || bed_type === 'futon') ? t(`accommodation.${bed_type}`) : bed_type }</Typography>
                                </div>
                            ))
                        }
                        {
                            provider_loaded && provider_loaded.code === "expedia_rapid" && <Typography>{ t("accommodation.expedia-bed-not-guaranteed") }</Typography>
                        }
                        {
                            room.max_passengers !== null &&
                            <Chip icon={<CiUser />} style={{marginRight: 8, backgroundColor: 'aliceblue', border: 'none', borderRadius: 0}} label={ `${ t('accommodation.maximum') } ${ room.max_passengers }` } variant="outlined" />
                            // <div className={ classes.paddingBottom }>
                            //     <PermIdentity className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                            //     <Typography>{ t('accommodation.maximum') } { room.max_passengers }</Typography>
                            // </div>
                        }
                        {
                            room.size_sq_meters !== null ? (
                                <Chip icon={<SquareFoot />} style={{marginRight: 8, backgroundColor: 'aliceblue', border: 'none', borderRadius: 0}} label={ `${ room.size_sq_meters } m²` } variant="outlined" />
                                // <div className={ classes.paddingBottom }>
                                //     <SquareFoot className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                //     <Typography>{ room.size_sq_meters } m²</Typography>
                                // </div>
                            ) : room.size_sq_feet !== null && (
                                <Chip icon={<SquareFoot />} style={{marginRight: 8, backgroundColor: 'aliceblue', border: 'none', borderRadius: 0}} label={ `${ (room.size_sq_feet / 10.7639).toFixed(0) } m²` } variant="outlined" />
                                // <div className={ classes.paddingBottom }>
                                //     <SquareFoot className={ `${classes.floatLeft} ${classes.marginRight}` }/>
                                //     <Typography>{ (room.size_sq_feet / 10.7639).toFixed(0) } m²</Typography>
                                // </div>
                            )
                        }
                        {
                            index_room === selected_room &&
                            <Fragment>
                                {
                                    (room.check_in !== null || room.check_out !== null || room.check_in_instructions !== null) &&
                                    <Grid container spacing={ 0 } style={{marginTop: 8}}>
                                        {
                                            room.check_in !== null &&
                                            <Grid item xs={ 4 }>
                                                <Timer className={ `${classes.floatLeft} ${classes.marginRightSmall} ${classes.smallIcon}` }/>
                                                <Typography className={ classes.smallText }>{ t('accommodation.check_in') } { room.check_in }</Typography>
                                            </Grid>
                                        }
                                        {
                                            room.check_out !== null &&
                                            <Grid item xs={ 4 }>
                                                <TimerOff className={ `${classes.floatLeft} ${classes.marginRightSmall} ${classes.smallIcon}` }/>
                                                <Typography className={ classes.smallText }>{ t('accommodation.check_out') } { room.check_out }</Typography>
                                            </Grid>
                                        }
                                        {
                                            room.check_in_instructions !== null &&
                                            <Grid item xs={ 4 }>
                                                <div onClick={ onCheckInInfo } style={{cursor: "pointer", width: "fit-content"}}>
                                                    <Timer className={ `${classes.floatLeft} ${classes.marginRightSmall} ${classes.smallIcon}` }/>
                                                    <Typography className={ `${classes.inline} ${classes.smallText}` }>{ t('accommodation.check_in_information') }</Typography>
                                                    <InfoOutlined className={ classes.checkInInfo }/>
                                                </div>
                                            </Grid>
                                        }
                                    </Grid>
                                }
                                {/*{*/}
                                {/*    (room.check_in !== null || room.check_out !== null) && (*/}
                                {/*        <Fragment>*/}
                                {/*            {*/}
                                {/*                room.check_in !== null &&*/}
                                {/*                <div className={ classes.paddingBottom }>*/}
                                {/*                    <Timer className={ `${classes.floatLeft} ${classes.marginRight}` }/>*/}
                                {/*                    <Typography>{ t('accommodation.check_in') } { room.check_in }</Typography>*/}
                                {/*                </div>*/}
                                {/*            }*/}
                                {/*            {*/}
                                {/*                room.check_out !== null &&*/}
                                {/*                <div className={ classes.paddingBottom }>*/}
                                {/*                    <TimerOff className={ `${classes.floatLeft} ${classes.marginRight}` }/>*/}
                                {/*                    <Typography>{ t('accommodation.check_out') } { room.check_out }</Typography>*/}
                                {/*                </div>*/}
                                {/*            }*/}
                                {/*        </Fragment>*/}
                                {/*    )*/}
                                {/*}*/}
                                {/*{*/}
                                {/*    room.check_in_instructions !== null &&*/}
                                {/*    <div className={ classes.paddingBottom } onClick={ onCheckInInfo }>*/}
                                {/*        <Timer className={ `${classes.floatLeft} ${classes.marginRight}` }/>*/}
                                {/*        <Typography className={ classes.inline }>{ t('accommodation.check_in_information') }</Typography>*/}
                                {/*        <InfoOutlined className={ classes.checkInInfo }/>*/}
                                {/*    </div>*/}
                                {/*}*/}
                                <Grid container spacing={ 0 }>
                                    {
                                        room.terms_n_conditions !== null &&
                                        <Grid item xs={ 4 }>
                                            <div className={ classes.paddingBottom }>
                                                <CiMemoPad className={ `${classes.floatLeft} ${classes.marginRightSmall}` }/>
                                                <Link to={ room.terms_n_conditions.split(',')[0] } target={ '_blank' } onlyActiveOnIndex>
                                                    <Typography style={{fontSize: 12}}>{ t('accommodation.terms_n_condition') }</Typography>
                                                </Link>
                                            </div>
                                        </Grid>
                                    }
                                    {
                                        room.terms_n_conditions !== null && room.terms_n_conditions.split(',').length === 2 &&
                                        <Grid item xs={ 4 }>
                                            <div className={ classes.paddingBottom }>
                                                <CiMemoPad className={ `${classes.floatLeft} ${classes.marginRightSmall}` }/>
                                                <Link to={ room.terms_n_conditions.split(',')[1] } target={ '_blank' } onlyActiveOnIndex>
                                                    <Typography style={{fontSize: 12}}>{ t('accommodation.terms_n_condition_agent') }</Typography>
                                                </Link>
                                            </div>
                                        </Grid>
                                    }
                                </Grid>
                                {/*{*/}
                                {/*    room.terms_n_conditions !== null &&*/}
                                {/*    <div className={ classes.paddingBottom }>*/}
                                {/*        <CiMemoPad className={ `${classes.floatLeft} ${classes.marginRight}` }/>*/}
                                {/*        <Link to={ room.terms_n_conditions.split(',')[0] } target={ '_blank' } onlyActiveOnIndex style={{fontSize: 12}}>*/}
                                {/*            <Typography>{ t('accommodation.terms_n_condition') }</Typography>*/}
                                {/*        </Link>*/}
                                {/*    </div>*/}
                                {/*}*/}
                                {/*{*/}
                                {/*    room.terms_n_conditions !== null && room.terms_n_conditions.split(',').length === 2 &&*/}
                                {/*    <div className={ classes.paddingBottom }>*/}
                                {/*        <CiMemoPad className={ `${classes.floatLeft} ${classes.marginRight}` }/>*/}
                                {/*        <Link to={ room.terms_n_conditions.split(',')[1] } target={ '_blank' } onlyActiveOnIndex style={{fontSize: 12}}>*/}
                                {/*            <Typography>{ t('accommodation.terms_n_condition_agent') }</Typography>*/}
                                {/*        </Link>*/}
                                {/*    </div>*/}
                                {/*}*/}
                            </Fragment>
                        }
                    </Grid>
                    {
                        index_room !== selected_room &&
                        <Fragment>
                            <Grid item container direction={ 'row-reverse' } className={ classes.priceFromContainer }>
                                <Grid item>
                                    <Typography className={ classes.priceFrom } variant={ 'body2' }>{ t('accommodation.room_price_from') }</Typography>
                                    <Typography className={ classes.priceFromNight }>{ t('accommodation.for') } { diff_day } { diff_day > 1 ? t('itinerary.night') : t('itinerary.nights') }</Typography>
                                    <div className={ classes.priceFromPrice }>
                                        <CurrencyFormat language={ language } fontSize={ classes.currencyFontSize } symbol={ (currency !== null ? (currency.symbol === '$' || currency.symbol === '£' ? `${currency.symbol}${currency.iso_code}` : currency.symbol) : '') } price={ room.price_from } with_precision={ 1 } with_bold={ 1 }/>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid className={ classes.marginTop } item container direction={ 'row-reverse' } alignContent={ 'flex-end' }>
                                <Button variant={ 'contained' } className={ classes.greenButton } onClick={() => {
                                    onChange(index_room);
                                    let added= false;
                                    room.variants.map((variant) => {
                                        console.log('all_inclusive:', all_inclusive);
                                        if (!added && (!cancellable || (cancellable && variant.is_cancellable)) && ((!breakfast_included || (breakfast_included && variant.breakfast_included)) && (!lunch_included || (lunch_included && variant.lunch_included)) && (!dinner_included || (dinner_included && variant.dinner_included)) && (!all_inclusive || (all_inclusive && variant.all_inclusive)))) {
                                            added = true;
                                            setSelectedVariant(variant);
                                        }
                                    });
                                }}>{ t('accommodation.choose_price') }</Button>
                            </Grid>
                        </Fragment>
                    }
                </Grid>
            </Grid>
            {
                index_room === selected_room &&
                <Grid container spacing={ 4 } className={ classes.variantContainer }>
                    {
                        room.variants.map((variant, index_variant) => {
                            if ((!cancellable || (cancellable && variant.is_cancellable)) && ((!breakfast_included || (breakfast_included && variant.breakfast_included)) && (!lunch_included || (lunch_included && variant.breakfast_included && variant.dinner_included)) && (!dinner_included || (dinner_included && variant.dinner_included && variant.breakfast_included && variant.lunch_included)) && (!all_inclusive || (all_inclusive && variant.all_inclusive)))) {
                                return <AccommodationVariant key={ index_variant } variant={ variant } provider_loaded={ provider_loaded } selected={ selectedVariant } onChange={(value) => { setSelectedVariant(value); }} onValidate={ onValidate } room_code={ room.room_code }/>;
                            }
                        })
                    }
                    {/*{*/}
                    {/*    selectedVariant !== null &&*/}
                    {/*    <Grid item container justify={ 'flex-end' }>*/}
                    {/*        <Button className={ classes.orangeButton } onClick={() => { onValidate(selectedVariant, room.room_code); }}>{ t('accommodation.validate_room') }</Button>*/}
                    {/*    </Grid>*/}
                    {/*}*/}
                </Grid>
            }
        </Paper>
    )
};

export default React.memo(AccommodationRoom);