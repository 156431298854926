import axios from "axios";
import memoizee from "memoizee";
import { flatten } from "lodash";
import { getCircuit } from "./getCircuit";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { PricePeriod } from "../objects/pricePeriod";

async function get(circuitId: number): Promise<PricePeriod[]> {
    let { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        try {
            const circuit = await getCircuit(circuitId);
            let periods: PricePeriod[] = [];
            let terrestrialPrices: { periods: number[] }[] = [];
            let flightPrices: { periods: number[] }[] = [];

            for (const contract of circuit?.contracts ?? []) {
                const terrestrialPricesResponse = await axios.get<{ periods: number[] }[]>(
                    `${API_HREF}client/${window.id_owner}/circuits/${circuitId}/contracts/${contract.id}/prices-terrestrial/`,
                    { headers }
                );
                terrestrialPrices = terrestrialPrices.concat(terrestrialPricesResponse.data);

                const flightPricesResponse = await axios.get<{ periods: number[] }[]>(
                    `${API_HREF}client/${window.id_owner}/circuits/${circuitId}/contracts/${contract.id}/prices-flight/`,
                    { headers }
                );
                flightPrices = flightPrices.concat(flightPricesResponse.data);

                const periodsResponse = await axios.get<{ results: PricePeriod[] }>(
                    `${API_HREF}client/${window.id_owner}/circuits/${circuitId}/contracts/${contract.id}/product-period/?limit=100000`,
                    {
                        headers,
                        params: {
                            start_date_after_today: true
                        }
                    }
                );
                periods = periods.concat(periodsResponse.data.results);
            }

            const periodIds = flatten(
                terrestrialPrices.map((item) => {
                    return item.periods;
                }).concat(flightPrices.map((item) => {
                    return item.periods;
                }))
            );
            periods = periods.filter((period) => {
                return periodIds.includes(period.id);
            });
            return periods;
        } catch (error) {
            console.error(error);
        }
    }

    return [];
}

export const getCircuitPeriods = memoizee(
    get,
    { promise: true, primitive: true }
);
