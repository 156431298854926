import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Info from '@material-ui/icons/Info';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: "bold"
    },
    inline: {
        display: "inline-block"
    },
    inlineFlex: {
        display: "inline-flex"
    },
    floatRight: {
        float: "right"
    },
    info: {
        marginLeft: 8
    },
    marginBottom: {
        marginBottom: 16
    },
    providerWrapper: {
        position: "relative"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white",
         borderRadius: 24
    },
    marginLeft: {
        marginLeft: 8
    }
}));

const AccommodationCardVerdie = ({prices, diff_day, language, provider_loading, getRoomPrices}) => {
    console.log("verdie render");
    const classes = useStyles();
    const { t } = useTranslation();
    const currency = useSelector(state => state.trip.currency);
    const currency_list = useSelector(state => state.base.currency_list);
    let preferred_array = {
        1: null,
        2: null,
        3: null
    };
    prices.map((price) => {
        if (price.data.custom) {
            preferred_array["1"] = price;
        } else if (price.data.code === 'iol_iwtx' || price.data.code === 'koedia_magic_travels') {
            if (preferred_array["2"] === null) {
                preferred_array["2"] = price;
            } else if (preferred_array["2"].price > price.price) {
                preferred_array["2"] = price;
            }
        } else if (preferred_array["3"] === null) {
            preferred_array["3"] = price;
        } else if (preferred_array["3"].price > price.price) {
            preferred_array["3"] = price;
        }
    });
    const getIsoCode = (price_currency) => {
        let iso_code = currency.iso_code;
        if (price_currency !== null) {
            iso_code = currency_list.find((currency) => {
                return currency.id === price_currency;
            }).iso_code;
        }
        return iso_code;
    };
    return (
        <Fragment>
            <br/>
            {
                preferred_array["1"] !== null && (
                    <Tooltip title={ t("accommodation.verdie_preferred_1_text") } arrow>
                        <div className={ classes.marginBottom }>
                            <Typography className={ classes.inlineFlex }>{ t("accommodation.verdie_service") } <Info className={ classes.info }/> : </Typography>
                            <div className={ `${classes.inline} ${classes.floatRight}` }>
                                <Typography className={ `${classes.inlineFlex} ${classes.bold}` }>{ new Intl.NumberFormat(language, { style: 'currency', currency: getIsoCode(preferred_array["1"].price_currency) }).format(Math.ceil(parseFloat( preferred_array["1"].price / diff_day ))) }</Typography>
                                <div className={ `${classes.providerWrapper} ${classes.inline} ${classes.marginLeft}` }>
                                    <Button variant={ "contained" } size={ "small" } className={ classes.orangeButton } disabled={ provider_loading === preferred_array["1"].data.id } onClick={() => { getRoomPrices(preferred_array["1"].data); } }>{ t("accommodation.choose") }</Button>
                                    { provider_loading === preferred_array["1"].data.id && <CircularProgress size={24} className={ classes.buttonProgress } /> }
                                </div>
                            </div>
                        </div>
                    </Tooltip>
                )
            }
            {
                preferred_array["2"] !== null && (
                    <Tooltip title={ t("accommodation.verdie_preferred_2_text") } arrow>
                        <div className={ classes.marginBottom }>
                            <Typography className={ classes.inlineFlex }>{ t("accommodation.verdie_partners") } <Info className={ classes.info }/> : </Typography>
                            <div className={ `${classes.inline} ${classes.floatRight}` }>
                                <Typography className={ `${classes.inlineFlex} ${classes.bold}` }>{ new Intl.NumberFormat(language, { style: 'currency', currency: getIsoCode(preferred_array["2"].price_currency) }).format(Math.ceil(parseFloat( preferred_array["2"].price / diff_day ))) }</Typography>
                                <div className={ `${classes.providerWrapper} ${classes.inline} ${classes.marginLeft}` }>
                                    <Button variant={ "contained" } size={ "small" } className={ classes.orangeButton } disabled={ provider_loading === preferred_array["2"].data.id } onClick={() => { getRoomPrices(preferred_array["2"].data); } }>{ t("accommodation.choose") }</Button>
                                    { provider_loading === preferred_array["2"].data.id && <CircularProgress size={24} className={ classes.buttonProgress } /> }
                                </div>
                            </div>
                        </div>
                    </Tooltip>
                )
            }
            {
                preferred_array["1"] === null && preferred_array["3"] !== null && (
                    <Tooltip title={ t("accommodation.verdie_preferred_3_text") } arrow>
                        <div className={ classes.marginBottom }>
                            <Typography className={ classes.inlineFlex }>{ t("accommodation.verdie_bedbanks") } <Info className={ classes.info }/> : </Typography>
                            <div className={ `${classes.inline} ${classes.floatRight}` }>
                                <Typography className={ `${classes.inlineFlex} ${classes.bold}` }>{ new Intl.NumberFormat(language, { style: 'currency', currency: getIsoCode(preferred_array["3"].price_currency) }).format(Math.ceil(parseFloat( preferred_array["3"].price / diff_day ))) }</Typography>
                                <div className={ `${classes.providerWrapper} ${classes.inline} ${classes.marginLeft}` }>
                                    <Button variant={ "contained" } size={ "small" } className={ classes.orangeButton } disabled={ provider_loading === preferred_array["3"].data.id } onClick={() => { getRoomPrices(preferred_array["3"].data); } }>{ t("accommodation.choose") }</Button>
                                    { provider_loading === preferred_array["3"].data.id && <CircularProgress size={24} className={ classes.buttonProgress } /> }
                                </div>
                            </div>
                        </div>
                    </Tooltip>
                )
            }
        </Fragment>
    )
};

export default React.memo(AccommodationCardVerdie);