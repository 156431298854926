import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid";
import Tooltip from '@material-ui/core/Tooltip';

import LuggageCheckedIcon from "./LuggageCheckedIcon.js";
import ArrowIcon from "./ArrowIcon.js"

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    bold: {
        fontWeight: "bold"
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize9: {
        fontSize: 9
    },
    fontSize12: {
        fontSize: 12
    },
    textCenter: {
        textAlign: "center"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    borderTableCell:{
        borderBottom: "none"
    },
    luggageIcon: {
        fontSize: 24,
        color: '#E6592F'
    },
    luggageIconSmall: {
        fontSize: 20,
        color: '#E6592F'
    },
    luggageNumber: {
        marginRight: -18,
        zIndex: 1,
        color: "#FFFFFF",
        fontSize: 10
    },
    luggageNumberSmall: {
        marginRight: -15,
        zIndex: 1,
        color: "#FFFFFF",
        fontSize: 9
    },
    arrow: {
        fontSize: 90,
        margin: "-38px 20px",
    },
    arrowTablet: {
        fontSize: 80,
        margin: -36
    },
    arrowMini: {
        fontSize: 60,
        margin: "-26px 10px",
    },
    arrowSmall: {
        fontSize: 80,
        margin: "-35px 20px"
    },
    ellipsis: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: 122,
        cursor: "pointer"
    }
}))

const PaidLuggageByPax = (({ small, paidOptions, travelers }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const isSmallLaptop = useMediaQuery(theme.breakpoints.between(960, 1025));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    return (
        <Fragment>
        {
            Object.keys(paidOptions).map((key, index) => {
                let current_traveler = travelers.find((el) => {return el.id === parseInt(key)});
                console.log('current_traveler:', current_traveler);
                return (
                    <TableRow style={{ verticalAlign: "baseline" }} key={key}>
                        <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter} ${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.uppercase}`}>
                            { current_traveler.age_label + " :"}
                        </TableCell>
                        <TableCell padding="none" className={`${index === Object.keys(paidOptions).length - 1 ? classes.borderTableCell : ""} ${classes.textCenter}`} style={{paddingTop: 5}}>
                        {
                            paidOptions[key].map((option, option_index) => {
                                return (
                                    <Grid container alignItems="center" justify="flex-start" key={option_index}>
                                        <Grid item className={small ? classes.luggageNumberSmall : classes.luggageNumber}>
                                            { "x" + option.quantity }
                                        </Grid>
                                        <Grid item>
                                            { option.baggage_is_carry_on && <div className={`icon-cabin-luggage ${small ? classes.luggageIconSmall : classes.luggageIcon}`} /> }
                                            { !option.baggage_is_carry_on && <LuggageCheckedIcon className={ small ? classes.luggageIconSmall : classes.luggageIcon} /> }
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title={option.description}>
                                                <div className={`${classes.genericText}  ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.uppercase} ${classes.textCenter} ${classes.ellipsis}`}>{option.description}</div>
                                            </Tooltip>
                                        </Grid>
                                        {
                                            option.weight !== null && (
                                                <Grid item>
                                                    <span className={`${classes.genericText}  ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.uppercase} ${classes.textCenter}`}>{option.weight + "kg"}</span>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                )
                            })
                        }
                        </TableCell>
                    </TableRow>
                )
            })
        }
        </Fragment>
    )
})

export default React.memo(PaidLuggageByPax);