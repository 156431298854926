import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { FormHelperText, makeStyles, useTheme } from "@material-ui/core";
import { FormControl, Grid, OutlinedInput, MenuItem, Select, InputLabel } from '@material-ui/core';
import { DatePicker , LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as date_locales from 'date-fns/locale';
import format from "date-fns/format";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TextField } from '@mui/material';
import { ViatorLocation } from './ViatorLocation';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400:{
        fontWeight: 400
    },
    fontSize12: {
        fontSize: 12
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter:{
        textAlign: "center"
    },
    genericPadding:{
        padding: "8px 0px"
    },
    formControlRootSmall: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "&& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '90%',
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    formControlRootSmallRed: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "&& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '90%',
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    },
        "&& .MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        }
    },
    circleBorder:{
        borderRadius: 8
    },
    validateButton: {
        color: "#FFFFFF",
        backgroundColor: "#2C98F0",
        float: "right",
        marginTop: 8
    },
    errorInfo:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        letterSpacing: 1.25,
        color: "red",
        width: "100%",
        fontSize: 10
    },
}))
type ViatorLocation = {
    meeting_type: string;
    locations: any[];
    start: any[];
    end: any[];
}
type ActivitiesQuestionsItemProps = {
    product: any;
    question: any;
    error: string[];
    editProduct: any;
    location: ViatorLocation | null;
    arrivalMode: any;
    departureMode: any;
}
const locales = [
    'beBY',
    'csCZ',
    'deDE',
    'enUS',
    'esES',
    'faIR',
    'fiFI',
    'frFR',
    'isIS',
    'itIT',
    'jaJP',
    'koKR',
    'nbNO',
    'nlNL',
    'plPL',
    'ptBR',
    'ruRU',
    'svSE',
    'trTR',
    'ukUA',
    'urPK',
    'zhCN'
];

export function ActivitiesQuestionsItem(props: ActivitiesQuestionsItemProps) : JSX.Element | null {
    const { question, product, error, editProduct, location, arrivalMode, departureMode } = props;
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const locale = i18n.language.split('-')[0] ?? 'fr';
	const locales_cpy : { [key: string]: any } = date_locales;
    const textsLocale = locales.find((item) => {
        return item.startsWith(locale);
    }) ?? locale;
    const texts = require(`@mui/x-date-pickers/locales/${textsLocale}.js`)[textsLocale];

    const [value, setValue] = useState(question.answer_text !== null ? typeof question.answer_text === 'string' ? JSON.parse(question.answer_text).value : question.answer_text.value : "");
    const [unit, setUnit] = useState('kg')
    const questionLabel = useRef(null);
    const questionLabelWidth = questionLabel.current ? questionLabel.current.clientWidth : 100;
    let rules_tojson = question.rules_answer !== null ? (typeof question.rules_answer === 'string' ? JSON.parse(question.rules_answer) : question.rules_answer) : null;
    const mandatory = rules_tojson !== null ? rules_tojson.required === "MANDATORY" : null;

    const handleAnswer = (event: { target: { value: any; }; }) => {
        let tmp_product = {...product};
        setValue(event.target.value);
        let questions = [...product.questions];
        questions.map((cpy_question: any) => {
            if (cpy_question.id === question.id) {
                cpy_question.answer_text = {value: event.target.value, unit: unit};
            }
        });
        tmp_product.questions = questions;
        editProduct(tmp_product);
    }
    const handleValidate = () => {
        let tmp_product = {...product};
        let questions = [...product.questions];
        questions.map((cpy_question: any) => {
            if (cpy_question.id === question.id) {
                cpy_question.answer_text = value
                let question_rules_tojson = question.rules_answer !== null ? JSON.parse(question.rules_answer) : null;
                if (question_rules_tojson !== null && question_rules_tojson.units !== undefined) {
                    cpy_question.answer_text = {value: value, units: 'FREETEXT'};
                } else {
                    cpy_question.answer_text = {value: value};
                }
            }
        });
        tmp_product.questions = questions;
        editProduct(tmp_product);
        // dispatch({ type: 'EDIT_ITEM_TO_BOOK', payload: {product: product} });
    }
    const handleValidateSelect = (event: { target: { value: any; }; }) => {
        let tmp_product = {...product};
        setValue(event.target.value);
        let questions = [...product.questions];
        questions.map((cpy_question: any) => {
            if (cpy_question.id === question.id) {
                cpy_question.answer_text = {value: event.target.value};
            }
        });
        tmp_product.questions = questions;
        editProduct(tmp_product);
        // dispatch({ type: 'EDIT_ITEM_TO_BOOK', payload: {product: tmp_product} });
    }
    const handleValidateDate = (date: any) => {
        setValue(date);
        let tmp_product = {...product};
        let questions = [...product.questions];
        questions.map((cpy_question: any) => {
            if (cpy_question.id === question.id) {
                cpy_question.answer_text = {value: window.moment.utc(date).format('YYYY-MM-DD')};
            }
        });
        tmp_product.questions = questions;
        editProduct(tmp_product);
        // dispatch({ type: 'EDIT_ITEM_TO_BOOK', payload: {product: tmp_product} });
    }
    const onTimeChange = (time:string|number|Date|null) => {
        if (time === null) return;
        let tmp_product = {...product};
        let questions = [...product.questions];
        questions.map((cpy_question: any) => {
            if (cpy_question.id === question.id) {
                cpy_question.answer_text = {value: format(new Date(time), 'p', {locale: locales_cpy[i18n.language]})};
            }
        });
        tmp_product.questions = questions;
        editProduct(tmp_product);
        // dispatch({ type: 'EDIT_ITEM_TO_BOOK', payload: {product: tmp_product} });
        setValue(format(new Date(time), 'p', {locale: locales_cpy[i18n.language]}));
    };
    const handleUnit = (event: { target: { value: any; }; }) => {
        let tmp_product = {...product};
        setUnit(event.target.value);
        let questions = [...product.questions];
        questions.map((cpy_question: any) => {
            if (cpy_question.id === question.id) {
                cpy_question.answer_text = {value: value, unit: event.target.value};
            }
        });
        tmp_product.questions = questions;
        editProduct(tmp_product);
    }
    if (rules_tojson === null) return null;
    return (
        <Grid container alignItems="baseline" justify="space-between" className={classes.genericPadding} style={!downDesktop ? { paddingTop: 16 } : {}}>
            <Grid item md={5} xs={12} className={`${!downDesktop ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight400} ${classes.fontSize12} ${classes.letterSpacing}`}>
                { `${t('poi.questions.' + question.question_code.toLowerCase())}` }
            </Grid>
            <Grid item md={7} xs={12}>
                {/* <FormControl variant="outlined" className={classes.formControlRootSmall}> */}
                {
                    (rules_tojson.type === 'STRING' || rules_tojson.type === 'LOCATION_REF_OR_FREE_TEXT') && !['TRANSFER_DEPARTURE_PICKUP', 'TRANSFER_ARRIVAL_PICKUP', 'PICKUP_POINT'].includes(question.question_code) &&
                    <Fragment>
                    {
                        rules_tojson.choice === undefined ?
                        <FormControl variant="outlined" className={ error.includes(question.question_code) ? classes.formControlRootSmallRed : classes.formControlRootSmall}>
                            <InputLabel id={`question-label-${question.id}`}>{ `${t('poi.questions.' + question.question_code.toLowerCase())}` }</InputLabel>
                            <OutlinedInput
                                type={question.rules_answer !== null && (rules_tojson.type === 'STRING' || rules_tojson.type === 'LOCATION_REF_OR_FREE_TEXT') ? "text" : undefined}
                                name={`question-label-${question.id}`}
                                id={`question-input-${question.id}`}
                                value={value}
                                className={`${classes.circleBorder}`}
                                labelWidth={questionLabelWidth}
                                onChange={handleAnswer}
                                onBlur={handleValidate}
                                label={`${t('poi.questions.' + question.question_code.toLowerCase())}`}
                                autoComplete="off"
                            />
                            {
                                mandatory && 
                                <FormHelperText>{t<string>('cart-material.mandatory-field')}</FormHelperText>
                            }
                        </FormControl> : 
                        <FormControl variant={ 'outlined' } fullWidth className={ error.includes(question.question_code) ? classes.formControlRootSmallRed : classes.formControlRootSmall}>
                            <InputLabel id={`question-label-${question.id}`}>{ `${t('poi.questions.' + question.question_code.toLowerCase())}` }</InputLabel>
                            <Select
                                labelId={`question-label-${question.id}`}
                                id={`question-input-${question.id}`}
                                value={value}
                                className={classes.circleBorder}
                                label={`${t('poi.questions.' + question.question_code.toLowerCase())}`}
                                onChange={handleValidateSelect}
                            >
                                {
                                    rules_tojson.choice !== undefined && rules_tojson.choice.map((choice: any, index: number) => {
                                        return (
                                            <MenuItem key={index} value={choice}>{choice}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            {
                                mandatory && 
                                <FormHelperText>{t<string>('cart-material.mandatory-field')}</FormHelperText>
                            }
                        </FormControl>
                    }
                    </Fragment>
                }
                {
                    location !== null && ['TRANSFER_DEPARTURE_PICKUP', 'TRANSFER_ARRIVAL_PICKUP', 'PICKUP_POINT'].includes(question.question_code)  &&
                        <ViatorLocation location={location} type={'start'} departureMode={departureMode} arrivalMode={arrivalMode}/>
                    // value !== null && value !== '' && (location !== null && question.question_code === 'TRANSFER_ARRIVAL_MODE' || question.question_code === 'TRANSFER_DEPARTURE_MODE') &&
                    //     <ViatorLocation location={location} type={question.question_code === 'TRANSFER_ARRIVAL_MODE' ? 'start' : 'end'} departureMode={departureMode} arrivalMode={arrivalMode}/>
                }
                {
                    rules_tojson.type === 'DATE' &&
                    <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={locale}
                        localeText={
                            texts.components.MuiLocalizationProvider.defaultProps.localeText
                        }
                    >
                        <DatePicker 
                            value={window.moment.utc(value)}
                            renderInput={(params) => <TextField {...params} fullWidth className={ error.includes(question.question_code) ? classes.formControlRootSmallRed : classes.formControlRootSmall}/>}
                            onChange={(value) => value && handleValidateDate(value.toISOString())}
                        />
                    </LocalizationProvider>
                }
                {
                    rules_tojson.type ===  'TIME' &&
                    <LocalizationProvider dateAdapter={ AdapterMoment } adapterLocale={locale}
                        localeText={
                            texts.components.MuiLocalizationProvider.defaultProps.localeText
                        }>
                        <TimePicker
                            value={value === '' ? null : moment(new Date('1970-01-01T' + value))}
                            onChange={ onTimeChange }
                            slotProps={{
                                textField: {
                                    variant: 'outlined',
                                    className: error.includes(question.question_code) ? classes.formControlRootSmallRed : classes.formControlRootSmall,
                                },
                            }}
                        />
                    </LocalizationProvider>
                }
                {
                    question.question_code === 'WEIGHT' &&
                    <Grid container spacing={ 4 }>
                        <Grid item xs={9}>
                            <FormControl variant="outlined" className={ error.includes(question.question_code) ? classes.formControlRootSmallRed : classes.formControlRootSmall}>
                                <InputLabel id={`question-label-${question.id}`}>{ `${t('poi.questions.' + question.question_code.toLowerCase())}` }</InputLabel>
                                <OutlinedInput
                                    type={"text"}
                                    name={`question-label-${question.id}`}
                                    id={`question-input-${question.id}`}
                                    value={value}
                                    className={`${classes.circleBorder}`}
                                    labelWidth={questionLabelWidth}
                                    onChange={handleAnswer}
                                    onBlur={handleValidate}
                                    label={`${t('poi.questions.' + question.question_code.toLowerCase())}`}
                                    autoComplete="off"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant={ 'outlined' } fullWidth className={ error.includes(question.question_code) ? classes.formControlRootSmallRed : classes.formControlRootSmall}>
                                <Select className={classes.circleBorder} value={unit} onChange={handleUnit}>
                                    <MenuItem value={'kg'}>kg</MenuItem>
                                    <MenuItem value={'lbs'}>lbs</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}