import React, { useRef } from "react";
import { useDragLayer, XYCoord } from "react-dnd";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material";
import {
} from "./itineraryStepItem";
import {
    ItineraryStepItemDragPreview,
    ItineraryStepItemDragPreviewProps
} from "./itineraryStepItemDragPreview";

type Props = {
    parentRef: React.RefObject<HTMLElement>
}

export function ItineraryStepItemDragLayer(props: Props): JSX.Element | null {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const { isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        isDragging: monitor.isDragging(),
        item: monitor.getItem() as ItineraryStepItemDragPreviewProps | null,
        initialOffset: monitor.getInitialClientOffset(),
        currentOffset: monitor.getClientOffset()
    }));

    if (
        !isDragging ||
        !item?.data ||
        item.drag === 'add' ||
        props.parentRef.current?.offsetWidth === undefined
    ) {
        return null;
    }

    return (
        <Container
            sx={
                getStyles({
                    initialOffset,
                    currentOffset,
                    parentWidth: props.parentRef.current.offsetWidth,
                    height: ref.current?.offsetHeight ?? 0
                })
            }
        >
            <ItineraryStepItemDragPreview
                ref={ref}
                drag="reorder"
                data={{
                    ...item.data,
                    title: item.data.isBloc ?
                        t<string>('itinerary.block-title') :
                        t<string>('itinerary.step-title')
                }}
                sx={{ backgroundColor: '#fff', opacity: 0.75 }}
            />
        </Container>
    );
}

type Options = {
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null,
    parentWidth: number,
    height: number,
}

function getStyles(options: Options) {
    if (!options.initialOffset || !options.currentOffset) {
        return {
            display: 'none'
        };
    }

    const transform = `translate(32px, ${options.currentOffset.y - (options.height / 2)}px)`;

    return {
        width: options.parentWidth - 64,
        transform,
        WebkitTransform: transform
    };
}

const Container = styled('div')((props) => ({
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: props.theme.zIndex.drawer + 1,
    top: 0
}));
