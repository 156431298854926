//---- Dependencies ----//
import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";

import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import localeText from '../../Functions/localeText';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    dialogSize: {
        "& .mui-jss-MuiDialog-paper": {
            width: 900
        },
        "& .mui-jss-MuiDialog-paperWidthSm": {
            maxWidth: 900
        }
    }
}));

type SalePitchDialogProps = {
    salePitch: boolean;
    setSalePitch: (value: boolean) => void;
    infos: any;
};

const SalePitchDialog = (props: SalePitchDialogProps) => {
    const { salePitch, setSalePitch, infos } = props;
    const classes = useStyles();
 	const theme = useTheme();
    const { t, i18n } = useTranslation();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const locales = useSelector(state => state.user.locales);
    
    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });
    let pitch = infos !== null ? localeText(current_locale.id, infos.localization, infos.sale_pitch, 'sale_pitch') : "";
    return (
        <Dialog open={salePitch} onClose={() => {
            setSalePitch(false);
        }} fullScreen={fullScreen} className={classes.dialogSize}>
            <DialogTitle>
                <Grid container>
				    <Grid item style={{ position: "absolute", top: 9, right: 6 }}>
				        <IconButton edge={ "start" } onClick={() => {
                            setSalePitch(false);
                        }}><CloseIcon/></IconButton>
				    </Grid>
				    <Grid item>
				        <Typography variant="h5" className={classes.genericText}>
                            {t<string>('shared.circuit-sales-pitch')}
				        </Typography>
				    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <div dangerouslySetInnerHTML={ { __html: pitch } } /> 
            </DialogContent>
        </Dialog>
    );
};
export default SalePitchDialog;
