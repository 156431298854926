//---- Dependencies ----//
import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

@connect((store) => {
    return {
        airports: store.flight_search.airports,
        airlines: store.flight_search.airlines
    };
})
@withTranslation()
export default class CartDetailedItinerary extends React.Component {
    detailedItineraryDate(date, time) {
        let split_date = date.split(" ");
        let month = split_date[2].split("", 3);
        let day = split_date[0].split("", 3);
        return (
		    <span>{day} {split_date[1]} {month}, {time}</span>
        );
    }
    detailedItineraryDateOutside(departure_time, destination_time) {
        const { t } = this.props;
        let departure_date = moment.utc(departure_time).format("MM[/]DD[/]YYYY");
        let arrival_date = moment.utc(destination_time).format("MM[/]DD[/]YYYY");
        let day = moment.utc(new Date(arrival_date)).diff(new Date(departure_date), 'days');
        if (day >= 1) {
            return (<span className="itinerary-day-outside">+{day}{t("global.abrev_day")}</span>);
        }
    }
    render() {
        const { segment, airports, airlines, t } = this.props;
        let arr = [];
        console.log('segment:', segment);
        segment.legs.map((leg, leg_index) => {
            let detailed_logo = "https://storagefacilitatrip.blob.core.windows.net/iata-company/" + leg.marketing_airline.airline_code + ".png";
            let departure_locale = leg.departure_time.split("Z");
            let arrival_locale = leg.arrival_time.split("Z");
            let departure_date = moment.utc(departure_locale[0]).format("dddd Do MMMM");
            let departure_time = moment.utc(departure_locale[0]).format("HH[h]mm");
            let arrival_date = moment.utc(arrival_locale[0]).format("dddd Do MMMM");
            let arrival_time = moment.utc(arrival_locale[0]).format("HH[h]mm");
            let origin_time = moment.utc(departure_locale[0]);
            let dest_time = moment.utc(arrival_locale[0]);
            let flight_duration = (parseInt(leg.flight_time / 60) < 10 ? "0" + parseInt(leg.flight_time / 60) : parseInt(leg.flight_time / 60)) + 'h' + ((leg.flight_time % 60 < 10) ? "0" + (leg.flight_time % 60) : (leg.flight_time % 60));
            arr.push(
			    <div className={ "detailed-flight" } key={leg_index}>
			    	{
			    		leg_index > 0 && (
			    			<div className={ "detailed-flight-stopover" }>
			    			    <span className={ "icon-couple-of-arrows-changing-places"}/>
			    			    <div className={ "flight-stopover-detail"}>
			    			        {leg.origin !== null ? t("flight_search.change_airport") : t("flight_search.change_train")} <span className={ "stopover-detail" }>{leg.origin !== null ? leg.origin.iata_city.international_name : leg.origin_station.iata_city.international_name} - {leg.origin !== null ? (leg.origin.name !== undefined ? leg.origin.name : leg.origin.international_name) : leg.origin_station.international_name} ({leg.origin !== null ? leg.origin.airport_code : leg.origin_station.station_code})</span>,
			    			        {t("flight_search.stopover_duration")}<span className={ " stopover-detail" }>{moment.utc(segment.stopover_duration[leg_index - 1]).format("HH[h]mm")}</span>. {t("flight_search.stopover_change_airport")}
			    			    </div>
			    			</div>
			    		)
			    	}
			        <div className={ "detailed-company-info"}>
			            <div className={ "detailed-itinerary-company-logo" }><img src={detailed_logo}/></div>
			            <div className={ "detailed-company" }>
			                <span>{leg.marketing_airline.commercial_name}</span>, {leg.marketing_airline.airline_code}-{leg.flight_number}<br/>
			                <span className={"airline-text"}>{t("flight_search.operate_by")} {leg.operating_airline?.commercial_name}</span>
			            </div>
			        </div>
			        <div className={ "detailed-flight-outbound" }>
			            {leg.origin !== null ? leg.origin.iata_city.international_name : leg.origin_station.iata_city.international_name} ({leg.origin !== null ? leg.origin.airport_code : leg.origin_station.station_code})<br/>
			            <span>{this.detailedItineraryDate(departure_date, departure_time)}</span><br/>
			            {leg.origin !== null ? (leg.origin.name !== undefined ? leg.origin.name : leg.origin.international_name) : leg.origin_station.international_name}<br/>
			            {leg.origin_terminal !== "No data" && (
			            	t("flight_search.terminal") + " " + leg.origin_terminal
			            	) 
			            }
			        </div>
			        <div className={ "detailed-flight-path-info"}>
			            <div className={ "detailed-flight-path-info-top"}><span className={ "icon-069-time" }/>{flight_duration} | {segment.legs.length === 1 ? <span>{t("flight_search.no_stopover")}</span> : <span>{t("flight_search.stopover")}</span>} 
			            {
			            	//| <span>{leg.flight_details.meals.length t("flight_search.meal")}</span> 
			            }
		            	</div>
			            <div className={ "path-info-line" }>
			                <div className={ "path-info-line-left"}/>
			                <div className={ "path-info-line-center" }/>
			                <div className={ "path-info-line-right"}/>
			                <span className={ "icon-044-transport-3 path-info-line"}/>
			            </div>
			            <div className={ "detailed-flight-path-info-bottom-cart"}>{t("global.class")}{leg.class_name}{leg.aircraft ? " | " + leg.aircraft.commercial_name : <span/>}</div>
			        </div>
			        <div className={ "detailed-flight-arrival" }>
			            {leg.destination !== null ? leg.destination.iata_city.international_name : leg.destination_station.iata_city.international_name} ({leg.destination !== null ? leg.destination.airport_code : leg.destination_station.station_code})<br/>
			            <span>{this.detailedItineraryDate(arrival_date, arrival_time)}{this.detailedItineraryDateOutside(origin_time, dest_time)}</span><br/>
			            {leg.destination !== null ? (leg.destination.name !== undefined ? leg.destination.name : leg.destination.international_name) : leg.destination_station.international_name}<br/>
			            {
			            	leg.destination_terminal !== "No data" && (
			           		 	t("flight_search.terminal") + " " + leg.destination_terminal
			            	)
			            }
			        </div>
			    </div>
            );
        });
        return (
            <div className={ "detailed-itinerary" }>
                {arr}
                {segment.outside_day ? <div className= { "flight-warning" }><span>{t("flight_search.important")}</span>{t("flight_search.this_flight_arrive")}{segment.outside_day === 1 ? segment.outside_day + t("global.day") : segment.outside_day + t("global.days")}{t("flight_search.after_departure")}</div> : <span/>}
            </div>
        );
    }
}
