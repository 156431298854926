import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import { keyframes, styled, Typography } from "@mui/material";
import { isFunction } from "lodash";
import { RegisteredBlocks } from "./utils/registered-blocks";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

type Props = {
    position: 'top' | 'bottom'
}

export const MailTemplateVisualEditorScrollIndicator = React.forwardRef<HTMLDivElement, Props>(
    function MailTemplateVisualEditorScrollIndicator(props, containerRef): JSX.Element | null {
        if (isFunction(containerRef)) {
            return null;
        }

        const { t } = useTranslation();
        const ref = useRef<HTMLDivElement>(null);
        const [
            styles,
            setStyles
        ] = useState<Pick<React.CSSProperties, 'top' | 'left' | 'opacity'>>({});
        const [collected, drop] = useDrop(() => ({
            accept: RegisteredBlocks.map((item) => item.type),
            collect: (monitor) => ({
                choosingBlock: monitor.canDrop()
            })
        }));

        const updateStyles = () => {
            switch (props.position) {
                case 'top': updateTopIndicator(); break;
                case 'bottom': updateBottomIndicator(); break;
            }
        };

        const updateBottomIndicator = () => {
            const indicatorHeight = ref.current?.offsetHeight ?? 0;
            const indicatorWidth = ref.current?.offsetWidth ?? 0;
            const containerWidth = containerRef?.current?.offsetWidth ?? 0;
            const rect = containerRef?.current?.getBoundingClientRect();
            setStyles({
                top: (rect?.bottom ?? 0) - indicatorHeight + 'px',
                left: ((rect?.left ?? 0) + containerWidth * 30 / 100 - indicatorWidth / 2) + 'px'
            });
        };

        const updateTopIndicator = () => {
            const indicatorWidth = ref.current?.offsetWidth ?? 0;
            const containerWidth = containerRef?.current?.offsetWidth ?? 0;
            const rect = containerRef?.current?.getBoundingClientRect();
            setStyles({
                top: (rect?.top ?? 0) + 'px',
                left: ((rect?.left ?? 0) + containerWidth * 30 / 100 - indicatorWidth / 2) + 'px'
            });
        };

        useEffect(() => {
            if (containerRef?.current) {
                updateStyles();
            }
        }, [containerRef?.current]);

        useEffect(() => {
            if (containerRef?.current) {
                const observer = new ResizeObserver(updateStyles);
                observer.observe(containerRef.current);
                return () => {
                    if (containerRef.current) {
                        observer.unobserve(containerRef.current);
                    }
                };
            }
        }, [containerRef?.current]);

        useLayoutEffect(() => {
            window.addEventListener('resize', updateStyles);
            updateStyles();
            return () => window.removeEventListener('resize', updateStyles);
        }, []);

        drop(ref);

        return (
            <ScrollIndicator
                ref={ref}
                style={{
                    ...styles,
                    opacity: collected.choosingBlock ?
                        1 :
                        0
                }}
            >
                <div className="icon">
                    {
                        props.position === 'top' &&
                        <KeyboardDoubleArrowUpIcon className="top" color="action" />
                    }
                    {
                        props.position === 'bottom' &&
                        <KeyboardDoubleArrowDownIcon className="down" color="action" />
                    }
                </div>
                <Typography variant="caption" color="text.secondary" component="div">
                    {t<string>('shared.mail-template-visual-editor-scroll-indicator')}
                </Typography>
            </ScrollIndicator>
        );
    }
);

const ScrollTopAnimation = keyframes(`
    0% { top: 24px }
    100% { top: -24px }
`);

const ScrollDownAnimation = keyframes(`
    0% { top: -24px }
    100% { top: 24px }
`);

const ScrollIndicator = styled('div')((props) => ({
    "position": 'fixed',
    "pointerEvents": 'none',
    "display": 'flex',
    "alignItems": 'center',
    "backdropFilter": 'blur(1px)',
    "transition": props.theme.transitions.create(
        'opacity',
        {
            duration: 500,
            easing: props.theme.transitions.easing.easeInOut
        }
    ),
    '& .icon': {
        "position": 'relative',
        "width": 24,
        "height": 24,
        "overflow": 'hidden',
        '& > svg': {
            "position": 'absolute',
            "animationDuration": '1.5s',
            "animationIterationCount": 'infinite',
            '&.top': {
                animationName: ScrollTopAnimation
            },
            '&.down': {
                animationName: ScrollDownAnimation
            }
        }
    }
}));
