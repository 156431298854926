//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from "axios";
import { useSnackbar } from 'notistack';

import Grid from "@material-ui/core/Grid";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import PictureAsPdfOutlined from '@material-ui/icons/PictureAsPdfOutlined';
import Add from '@material-ui/icons/Add';

import CheckBeforeRequest from '../../Common/CheckBeforeRequest';
import { AddOtherDocument, DeleteDocumentId, SetTripDocsDoc } from '../../../Actions/Menu';
import { ModalDeleteCustomVoucher } from './ModalDeleteCustomVoucher';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400: {
        fontWeight: 400
    },
    fontWeight700: {
        fontWeight: 700
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize20: {
        fontSize: 20
    },
    upperCase: {
        textTransform: "uppercase",
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter: {
        textAlign: "center"
    },
    genericBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box"
    },
    genericPadding: {
        padding: "0px 20px"
    },
    genericPaddingMobile: {
        padding: "0px 8px"
    },
    categoryPadding: {
        padding: 8
    },
    spaceTop: {
        marginTop: 20
    },
    spaceTopMobile: {
        marginTop: 10
    },
    spacer: {
        margin: "16px 0px"
    },
    spacerMobile: {
        margin: "8px 0px"
    },
    indentation: {
        paddingLeft: 16
    },
    borderTableCell: {
        borderBottom: "none"
    },
    colorBlue: {
        color: '#4A75DA'
    },
    colorRed: {
        color: 'red'
    }
}));

const TripListOtherDocuments = ({ trip_displayed_version }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(768));
    const { enqueueSnackbar } = useSnackbar();

    const trip_info = useSelector(store => store.menu.trip_info);
    const trip_docs = useSelector(store => store.menu.trip_docs);

    const [otherDocs, setOtherDocs] = useState([]);
    const [seeSpinner, setSeeSpinner] = useState(false);
    const [downloadSpinner, setDownloadSpinner] = useState(false);
    const [uploadSpinner, setUploadSpinner] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(null);

    const bucketName = "facilitatrip-documents";
    const uploadInputRef = useRef(null);

    const checkFile = (file) => {
        const trim_file = file.target.value.replaceAll(" ", "").toLowerCase();
        const allowedFiles = [".png", ".jpg", ".jpeg", ".pdf", ".csv", ".xml", ".xls", ".xlsx", ".doc", ".docx", ".heic", ".heif"];
        const allowedExtensions = new RegExp("([a-zA-Z0-9\s_\\.\-:])+(" + allowedFiles.join('|') + ")$");
        if (!allowedExtensions.exec(trim_file)) {
            return false;
        }
        return true;
    };
    const checkSize = (file) => {
        if ((file.target.files[0].size/1024)/1024 > 10) {
            return false;
        }
        return true;
    };
    const onDeleteDocument = (doc) => () => {
        setDocumentToDelete(doc);
        setModalDelete(true);
    };
    const deleteDocument = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "DELETE",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/${documentToDelete.id}/`,
                headers: headers
            }).then(function () {
                dispatch(DeleteDocumentId(documentToDelete.id));
                setDocumentToDelete(null);
                setModalDelete(false);
                enqueueSnackbar(t("menu.trip_list.success_doc_delete"), { variant: "success", disableWindowBlurListener: true });
            }).catch(function (error) {
                console.log(error);
                enqueueSnackbar(t("menu.trip_list.failed_doc_delete"), { variant: "warning", disableWindowBlurListener: true });
            });
        }
    };
    const downloadDocument = (doc) => () => {
        setDownloadSpinner(true);
        let object_name = (doc.object_name).replaceAll("#", "%23");
        let file_name = doc.file_name !== null ? doc.file_name : `${doc.type}-TRIP%23${trip_info.id}-VER%23${trip_displayed_version}`;

        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            const domain = window.location.host.replace("www.", "").split('.');
            axios({
                method: "GET",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/download_from_aws/?bucket_name=${bucketName}&object_name=${object_name}&file_name=${file_name}`,
                // url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/download_from_aws/?bucket_name=${bucketName}&object_name=${object_name}&file_name=${file_name}`,
                headers: headers,
                responseType: 'arraybuffer'
            }).then(function (response) {
                let blob = new Blob([response.data], { type: "application/pdf" });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = file_name;
                link.click();
                setDownloadSpinner(false);
            }).catch(function (error) {
                console.log(error.responseJSON);
                enqueueSnackbar(t("menu.trip_list.failed_doc_download"), { variant: "warning", disableWindowBlurListener: true });
                setDownloadSpinner(false);
            });
        }
    };

    const seeDocument = (doc) => () => {
        setSeeSpinner(true);
        let object_name = (doc.object_name).replaceAll("#", "%23");
        let file_name = doc.file_name !== null ? doc.file_name : `${doc.type}-TRIP%23${trip_info.id}-VER%23${trip_displayed_version}`;
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            const domain = window.location.host.replace("www.", "").split('.');
            axios({
                method: "GET",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/download_from_aws/?bucket_name=${bucketName}&object_name=${object_name}&file_name=${file_name}`,
                // url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/download_from_aws/?bucket_name=${bucketName}&object_name=${object_name}&file_name=${file_name}`,
                headers: headers,
                responseType: 'arraybuffer'
            }).then(function (response) {
                let blob = new Blob([response.data], { type: "application/pdf" });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                setSeeSpinner(false);
                window.open(link);
            }).catch(function (error) {
                console.log(error.responseJSON);
                enqueueSnackbar(t("menu.trip_list.failed_doc_preview"), { variant: "warning", disableWindowBlurListener: true });
                setSeeSpinner(false);
            });
        }
    };
    const uploadDocument = (e, doc) => {
        let selected_file = e.target.files[0];
        let check_format = checkFile(e);
        let check_size = checkSize(e);
        if (check_format && check_size) {
            let formData = new FormData();
            const domain = window.location.host.replace("www.", "").split('.');
            formData.append("file_object", selected_file);
            formData.append("bucket_name", bucketName);
            formData.append("prefix", `${domain[0]}-${domain[2] === 'com' ? 'prod' : 'pre-prod'}`);
            formData.append("type", doc !== undefined && doc.type !== undefined && doc.type !== null && doc.type === "CUSTOM_FILE" ? "CUSTOM_FILE" : "OTHER");
            formData.append("file_name", selected_file.name);
            if (doc !== undefined) {
                formData.append("tripdocument_id", doc.id);
            }
            let { pass_check, headers } = CheckBeforeRequest();
            headers["Content-Type"] = "multipart/form-data";
            if (pass_check) {
                axios({
                    method: "POST",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/upload_to_aws/`,
                    // url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/upload_to_aws/`,
                    headers: headers,
                    data: formData
                }).then(function (response) {
                    enqueueSnackbar(t("menu.trip_list.success_doc_upload"), { variant: "success", disableWindowBlurListener: true });
                    if (doc !== undefined) {
                        dispatch(SetTripDocsDoc(response.data));
                    } else {
                        dispatch(AddOtherDocument(response.data));
                    }
                }).catch(function (error) {
                    console.log(error.responseJSON);
                    enqueueSnackbar(t("menu.trip_list.failed_doc_upload"), { variant: "error", disableWindowBlurListener: true });
                });
            }
            e.target.value = null;
        } else {
            e.target.value = null;
            if (!check_format) {
                enqueueSnackbar(t("menu.trip_list.failed_doc_upload_type"), { variant: "error", disableWindowBlurListener: true });
            }
            if (!check_size) {
                enqueueSnackbar(t("menu.trip_list.failed_doc_upload_size"), { variant: "error", disableWindowBlurListener: true });
            }
        }
    };
    useEffect(() => {
        if (trip_docs !== null && trip_docs.length !== 0) {
            let other_docs = [];
            trip_docs.map((doc) => {
                if (doc.type === "OTHER") {
                    other_docs.push(doc);
                }
            });
            setOtherDocs(other_docs);
        }
    }, [trip_docs]);
    return (
        <Grid className={`${classes.genericBorder} ${!mobile ? classes.genericPadding : classes.genericPaddingMobile} ${!mobile ? classes.spaceTop : classes.spaceTopMobile}`}>
            <Grid className={`${!mobile ? classes.spaceTop : classes.spaceTopMobile} ${!mobile ? "" : classes.textCenter}`}>
                <span className={`${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize20 : classes.fontSize16} ${classes.upperCase} ${classes.letterSpacing}`}>{ t("menu.trip_list.other_documents") }</span>
                <span className={`${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10}`}>{ ` (${t("menu.trip_list.hidden_to_travelers")})` }</span>
            </Grid>
            <Grid>
                <Alert severity={ 'info' }>
                    { t('menu.trip_list.doc_format_information') }
                </Alert>
            </Grid>
            <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${classes.genericBorder}`}>
                <TableContainer>
                    <Table id={'other_documents'} padding="none">
                        <TableBody>
                            {
                                otherDocs.map((doc, doc_index) => {
                                    return (
                                        <TableRow key={`other-docs-${doc_index}`}>
                                            <TableCell className={`${!mobile ? classes.indentation : ""} ${classes.borderTableCell} ${classes.genericText} ${!mobile ? classes.fontWeight700 : classes.fontWeight400} ${!mobile ? classes.fontSize12 : classes.fontSize10}`}>
                                                {doc.file_name !== null ? doc.file_name : t("menu.trip_list.document") + "  " + (doc_index + 1)}
                                            </TableCell>
                                            <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter}`}>
                                                {doc.file_name !== null && doc.modified_date !== null ? moment(doc.modified_date).format("L") : ""}
                                            </TableCell>
                                            <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                                                {
                                                    doc.type === "CUSTOM_FILE" && (
                                                        <Tooltip title={t("global.upload")}>
                                                            <IconButton size="small" className={classes.colorBlue} component="label">
                                                                { uploadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <PublishOutlinedIcon /> }
                                                                <input hidden type="file" onChange={(e) => uploadDocument(e, doc)} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                                                {
                                                    doc.object_name.includes(".pdf") && (
                                                        <Tooltip title={t("menu.trip_list.see_document")}>
                                                            <span>
                                                                <IconButton size="small" className={classes.colorBlue} onClick={seeDocument(doc)}>
                                                                    { seeSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <PictureAsPdfOutlined /> }
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                                                <Tooltip title={t("global.download")}>
                                                    <span>
                                                        <IconButton size="small" className={classes.colorBlue} onClick={downloadDocument(doc)}>
                                                            { downloadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <SaveAltOutlinedIcon /> }
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                            {
                                                <TableCell className={`${classes.borderTableCell} ${classes.textCenter}`} style={{ width: 0 }}>
                                                    <Tooltip title={t("global.delete")}>
                                                        <span>
                                                            <IconButton size="small" className={classes.colorRed} onClick={onDeleteDocument(doc)}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container alignItems={ "center" } justify={ "space-between" } direction={ "row-reverse" }>
                    <Grid item>
                        <Fragment>
                            <input hidden ref={uploadInputRef} type="file" onChange={uploadDocument}/>
                            <Button startIcon={<Add />} color={ "primary" } size={ "small" } component={ "label" } onClick={() => uploadInputRef.current && uploadInputRef.current.click()}>
                                {t("menu.trip_list.add_document")}
                            </Button>
                        </Fragment>
                    </Grid>
                </Grid>
            </Grid>
            <ModalDeleteCustomVoucher deleteDocument={deleteDocument} modalDelete={modalDelete} setModalDelete={setModalDelete}/>
        </Grid>
    );
};
export default TripListOtherDocuments;
