export default (main_locale, localization, default_text, text_key) => {
	let displayed_text = default_text;
	if (localization !== undefined) {
		for (let i = 0; i < localization.length; i++) {
			if (localization[i].locale === main_locale) {
				if (!!localization[i][text_key]) {
					displayed_text = localization[i][text_key];
				}
			}
		}
	}
	return displayed_text === null ? '' : displayed_text;
};