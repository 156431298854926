// Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Core & Lab
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Box, Tooltip, Typography, makeStyles } from '@material-ui/core';

const useStyle = makeStyles({
    label: {
        width: "100%",
    },
  });

const CartAssistancePlus = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyle();
    const assistance_package = useSelector(state => state.cart.assistance_package);
    const onToggleAssistance = () => {
        dispatch({type: 'CART_ASSISTANCE_PACKAGE', payload: 'Plus'});
    };
    const Label = (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className={classes.label}
        >
            <Typography>{t('cart-material.assistance-plus-option')}</Typography>
            <Tooltip placement={"right"} title={'Ce forfait permet à vos clients un accès illimité aux services d’assistance et de conciergerie en voyage OnSpot, via téléphone, messagerie WhatsApp et email, un service de médiation voyage en cas de vol retardé, annulé ou de connexion manquée ainsi qu’un service de notification instantané à l’agence en cas d’intervention à destination.'}>
                <InfoIcon style={{marginLeft: 5}}/>
            </Tooltip>
        </Box>
      );
    return (
        <FormControl fullWidth>
            <FormControlLabel control={ <Checkbox checked={ assistance_package === 'Plus' } onChange={ onToggleAssistance } color={ 'primary' }/> } label={ Label }/>
        </FormControl>
    );
};

export default CartAssistancePlus;