import axios, { AxiosError } from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { SelectedRoom } from "../../ItineraryType/RoomTypePicker";

type CallbackOptions = {
    sourceTripId: number,
    sourceTripVersion: number,
    targetTripVersion: number,
    startDate: string,
    products?: number[],
    adalteRooms?: SelectedRoom[]
}

type Callback = (options: CallbackOptions) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: () => void,
    onError: (error: AxiosError) => void,
    onFinally: () => void
}>

export function useItineraryDuplicateTripProducts(options: Options): Callback {
    return async (callbackOptions) => {
        if (options.onTrigger) {
            options.onTrigger();
        }
        try {
            await makeRequest({
                sourceTripId: callbackOptions.sourceTripId,
                sourceTripVersion: callbackOptions.sourceTripVersion,
                targetTripVersion: callbackOptions.targetTripVersion,
                tripStartDate: callbackOptions.startDate,
                products: callbackOptions.products
            });
            if (options.onSuccess) {
                options.onSuccess();
            }
        } catch (error: any) {
            if (options.onError) {
                options.onError(error);
            }
        } finally {
            if (options.onFinally) {
                options.onFinally();
            }
        }
    };
}

type RequestOptions = {
    sourceTripId: number,
    sourceTripVersion: number,
    targetTripVersion: number,
    tripStartDate: string,
    products?: number[],
    adalteRooms?: SelectedRoom[]
}

async function makeRequest(options: RequestOptions): Promise<void> {
    const { pass_check, headers } = CheckBeforeRequest();
    const url = new URL(
        API_HREF.startsWith('http') ?
            `${API_HREF}client/${window.id_owner}/trip/duplication_to_other_trip/` :
            `${window.location.origin}${API_HREF}client/${window.id_owner}/trip/duplication_to_other_trip/`
    );
    for (const id of options.products ?? []) {
        url.searchParams.append('pid', id.toString());
    }
    url.searchParams.append('id', options.sourceTripId.toString());
    url.searchParams.append('version_id', options.sourceTripVersion.toString());
    url.searchParams.append('target_trip_version', options.targetTripVersion.toString());
    url.searchParams.append('start_date', options.tripStartDate.toString());

    if (pass_check) {
        await axios.post(
            url.toString(),
            { adalte_rooms: options.adalteRooms },
            { headers }
        );
    }
}
