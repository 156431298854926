//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";
import { useSnackbar } from "notistack";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import FlightIcon from '@material-ui/icons/Flight';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import WorkIcon from '@material-ui/icons/Work';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import RenderFlightLegDetail from './RenderFlightLegDetail';
import LuggageByPax from '../../FlightMaterial/FlightSearch/LuggageByPax';
import CountAgePaxType from '../../FlightMaterial/Functions/CountAgePaxType';
import LuggageIcon from '../../FlightMaterial/FlightSearch/LuggageIcon';
import RenderFlightLegDetailMobile from './RenderFlightLegDetailMobile';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    hoverContainer: {
        "&:hover": {
            borderLeft: '6px solid #E6592F'
        }
    },
    bookingContainer: {
        borderLeft: '6px solid #E6592F'
    },
    checkboxContainer: {
        position: 'relative',
        height: '100%',
        width: 30
    },
    checkbox: {
        position: 'absolute',
        top: 'calc(50% - 19px)',
        margin: '0 4px',
        left: '-16px'
    },
    font9: {
        fontSize: 9
    },
    font11: {
        fontSize: 11
    },
    font12: {
        fontSize: 12
    },
    font14: {
        fontSize: 14
    },
    font16: {
        fontSize: 16
    },
    font18: {
        fontSize: 18
    },
    bold: {
        fontWeight: "bold"
    },
    relative: {
        position: 'relative',
        textAlign: 'center'
    },
    noBaggage: {
        color: 'gray',
        fontSize: 25,
    },
    baggage: {
        color: '#E6592F',
        fontSize: 25
    },
    baggageNumber: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white'
    },
    underline: {
        textDecoration: "underline"
    },
    pointer: {
        cursor: "pointer"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    luggageIcon: {
        fontSize: 24,
        color: '#E6592F'
    },
    luggageIconSmall: {
        fontSize: 20,
        color: '#E6592F'
    },
    fontWeight900: {
        fontWeight: 900
    },
    spacer: {
        padding: "8px 0px"
    },
    filterButton:{
        border: "1.03872px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: 31.1617,
        padding: "8px 12px"
    },
    filterButtonSmall:{
        border: "1.03872px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: 31.1617
    },
    paddingBottom10: {
        paddingBottom: 10
    }
}));

const FlightSummaryManual = ({outbound, flight, type, hasOption, travelFlight, position, selectedProducts, setSelectedProducts, step_index, from, otherProduct}) => {
    const classes = useStyles();
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
	const { enqueueSnackbar } = useSnackbar();
    const small = true;
    const trip_info = useSelector(state => state.summary.trip_info);
    const currency_list = useSelector(state => state.base.currency_list);

    const [showDetails, setShowDetails] = useState(false);
    const [carryOnLuggages, setCarryOnLuggages] = useState(0);
    const [checkLuggages, setCheckLuggages] = useState(0);

    const travelers_obj = CountAgePaxType(flight.group_passenger.travelers_list, trip_info.end_date);
    console.log('outbound:', outbound);
    useEffect(() => {
        let temp_carry_on = 0;
        let temp_check = 0
        // if (outbound.legs[0].nb_baggages !== undefined){
        //     outbound.legs[0].baggage.map((luggage) => {
        //         if(luggage.is_carry_on){
        //             temp_carry_on += luggage.quantity;
        //         }else{
        //             temp_check += luggage.quantity;
        //         }
        //     })
        // }else{
        //     temp_check = outbound.legs[0].baggage_allowance.number_of_pieces;
        // }
        
        setCarryOnLuggages(temp_carry_on);
        setCheckLuggages(temp_check);
    }, [])

    const variantIsChecked = (product) => {
        if (position !== "step") {
            return selectedProducts.mainFlights[step_index].includes(product.id);
        }
        else {
            return selectedProducts.step_flight[step_index].includes(product.id);
        }
    }
    // const getStopover = () => {
    //     let stopover_iata = [];
    //     for (let i = 1; i < outbound.legs.length; i++) {
    //         if (outbound.legs[i].origin!== undefined) {
    //             stopover_iata.push (outbound.legs[i].origin.airport_code)
    //         }
    //     }
    //     return stopover_iata;
    // }
    const getClientPrice = (prices) => {
        let current_price = prices.find((el) => {
            return !el.is_commissioned && !el.is_network && !el.is_surcom && !el.master_price;
        });
        if (current_price === undefined) {
            current_price = prices.find((el) => {
                return !el.is_commissioned && !el.is_network && !el.is_surcom && el.master_price;
            });
        }
        return current_price;
    }
    const handleChange = () => {
        let tmp_selectedProducts = Object.assign({}, selectedProducts);
        let tmp_flights = position !== "step" ? tmp_selectedProducts.mainFlights.slice() : tmp_selectedProducts.step_flight.slice();
		if (travelFlight.length === 0 && tmp_flights[step_index].includes(flight.id)) {
			tmp_flights[step_index] = [];
		} else {
            if (position === "top") {
                enqueueSnackbar(t("summary.changed_flight_return"), { variant: "warning", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                } });
            } else if (position === "bottom") {
                enqueueSnackbar(t("summary.changed_flight_departure"), { variant: "warning", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                } });
            }
            tmp_flights[step_index] = [flight.id];
		}
        if (position !== "step") {
            tmp_selectedProducts.mainFlights = tmp_flights;
        }
        else {
            tmp_selectedProducts.step_flight = tmp_flights;
        }
        setSelectedProducts(tmp_selectedProducts);
    }
    const OpenDetails = () => {
        setShowDetails(!showDetails);
    }
    // const stopover_iata = getStopover();
    // const last_index = outbound.legs.length - 1;
    let price = parseFloat(getClientPrice(flight.prices).selling_price);
    let selling_currency = currency_list.find((currency) => currency.id === getClientPrice(flight.prices).selling_currency);
    return (
        <Grid container item spacing={2} direction={"row"} alignItems={"center"} justify={"space-between"} className={clsx(classes.font11, {[classes.paddingBottom10]: type === "main"})} style={{marginBottom: from !== "tripSummary" && flight.is_optional ? 11 : 0}}>
            {
                from !== "tripSummary" && otherProduct === undefined && trip_info.status !== "USER_VALID" && (
                    <Grid item>
                        <div className={ classes.checkboxContainer }>
                            <Checkbox
                                className={ classes.checkbox }
                                edge={ 'start' }
                                checked={ variantIsChecked(flight) }
                                tabIndex={ -1 }
                                disableRipple 
                                onChange={ handleChange }
                                icon={<CircleUnchecked />}
                                checkedIcon={<RadioButtonCheckedIcon  />}
                            />
                        </div>
                    </Grid>
                )
            }
            <Grid item>
                <FlightIcon/>
            </Grid>
            <Grid item>
                <img src={ `https://storagefacilitatrip.blob.core.windows.net/iata-company/${outbound.airline.airline_code}.png` } alt={ 'company logo' } width={ '100%' } style={{display: "block", marginLeft: "auto", marginRight: "auto"}}/>
            </Grid>
            <Grid item xs>
                <Grid container direction={'column'} alignItems={"center"} justify={"center"}>
                    <Grid item>
                        { outbound.airline.airline_code }-{ outbound.flight_number }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={"column"} className={classes.bold} alignItems={"center"}>
                    <Grid item>
                        {outbound.origin_airport.iata_city.name}
                    </Grid>
                    <Grid item>
                        {moment.utc(outbound.start_date).format("hh[H]mm")}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <ArrowRightAltIcon/>
            </Grid>
            <Grid item>
                <Grid container direction={"column"} className={classes.bold} alignItems={"center"}>
                    <Grid item>
                        {outbound.destination_airport.iata_city.name}
                    </Grid>
                    <Grid item>
                        {moment.utc(outbound.end_date).format("hh[H]mm")}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.bold} style={{textAlign: "center"}} xs>
                { outbound.cabin_class !== null ? t("flight_search.cabin_class." + outbound.cabin_class) : ""}
            </Grid>
            <Grid item xs style={{textAlign: "center", textTransform: "capitalize"}}>
                {t("flight_search.direct_flight")}
            </Grid>
            <Grid item xs className={classes.relative}>
                <WorkIcon className={ `${outbound.nb_baggages !== null ? classes.noBaggage : classes.baggage}` }/>
                <Typography className={ `${classes.baggageNumber} ${classes.font11}` }>{ outbound.nb_baggages }</Typography>
            </Grid>
            <Grid item xs>
            </Grid>
            {
                position !== "step" && (
                    <Grid item></Grid>
                )
            }
        </Grid>
    )
}
export default FlightSummaryManual