//---- Dependencies ----//
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";
import Notify from "../../../Common/Notify";

//---- Actions ----//

export default (traveler, t, id, trip_token) => {
	console.log('tra:', traveler);
	let { pass_check, headers } = CheckBeforeRequest();
	if (pass_check) {
		let request;
		request = {
			birth_date: traveler.birth_date !== null ? moment.utc(traveler.birth_date).format("YYYY-MM-DD") : null,
			first_name: traveler.first_name,
			last_name: traveler.last_name,
			title: traveler.title,
			gender: traveler.gender !== undefined ? traveler.gender : "M",
			address: traveler.address,
			country: traveler.country !== null ? traveler.country.id : null,
			city: traveler.city,
			postal_code: traveler.postal_code,				
			phone_number: traveler.phone_number,
			email: traveler.email
		};
		$.ajax({
            method: "PATCH",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/travelers/${id}/?token=${trip_token}`,
            data: JSON.stringify(request),
            success: function (data) {

            },
            error: function (error) {
                console.log("err", error);/*
                let text = t("booking.error_lead_trav") + " : " + error.responseJSON.detail;
	          	Notify(text, {status: "danger", pos: "top-center"});	*/          	
            }
        });
	}
};
