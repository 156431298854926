//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Axios from 'axios';
import { useSnackbar } from 'notistack';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import FormControl  from '@material-ui/core/FormControl';
import InputLabel   from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem   from '@material-ui/core/MenuItem';

import MaterialProviderHeader from './MaterialProviderHeader';
import GetTripInfo from './Functions/GetTripInfo';
import GetAcco from './Functions/GetAcco';
import GetPoi from './Functions/GetPoi';
import GetFlight from './Functions/GetFlight';
import GetCar from './Functions/GetCar';
import GetManual from './Functions/GetManual';
import GetCustom from './Functions/GetCustom';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import GetCookie from '../Common/Functions/GetCookie';
import { Alert } from '@mui/material';


const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white',
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    bold: {
        fontWeight: "bold"
    },
    underline: {
        textDecoration: "underline"
    },
    booking: {
        color: "#4caf50",
        borderColor: "#4caf50"
    },
    bookingActive: {
        color: "white",
        borderColor: "#4caf50",
        backgroundColor: "#4caf50"
    },
    pending: {
        color: "#ff9800",
        borderColor: "#ff9800"
    },
    pendingActive: {
        color: "white",
        borderColor: "#ff9800",
        backgroundColor: "#ff9800"
    },
    declined: {
        color: "#f44336",
        borderColor: "#f44336"
    },
    declinedActive: {
        color: "white",
        borderColor: "#f44336",
        backgroundColor: "#f44336"
    },
    vaidateButton: {
        backgroundColor: '#E6592F',
        color: 'white',
        float: "right",
        marginBottom: 20
    },
    textCenter: {
        textAlign: "center"
    }
}));

const MaterialProviderWebsite = (props) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const currency_list = useSelector(state => state.base.currency_list);

    const [tripInfo, setTripInfo] = useState(null);
    const [product, setProduct] = useState(null);
    const [group, setGroup] = useState(null);
    const [compo, setCompo] = useState(null);
    const [status, setStatus] = useState(0);
    const [newPrice, setNewPrice] = useState("");
    const [bookingRef, setBookingRef] = useState("");
    const [moreInfo, setMoreInfo] = useState("");
    const [productStatus, setProductStatus] = useState("");

    useEffect(() => {
        GetTripInfo(props.params.trip_id, props.params.trip_token, setTripInfo);
        getProduct();
    }, []);
    const getProduct = () => {
        if (props.params.product_type === "accommodation") {
            GetAcco(props.params.trip_id, props.params.trip_id_version, setProduct, setGroup, props.params.product_id, props.params.trip_token)
        }
        if (props.params.product_type === "poi") {
            GetPoi(props.params.trip_id, props.params.trip_id_version, setProduct, setGroup, props.params.product_id, props.params.trip_token);
        }
        if (props.params.product_type === "flight") {
            GetFlight(props.params.trip_id, props.params.trip_id_version, setProduct, setGroup, props.params.product_id, props.params.trip_token);
        }
        if (props.params.product_type === "car") {
            GetCar(props.params.trip_id, props.params.trip_id_version, setProduct, setGroup, props.params.product_id, props.params.trip_token);
        }
        if (props.params.product_type === "manual-products") {
            GetManual(props.params.trip_id, props.params.trip_id_version, setProduct, setGroup, props.params.product_id, props.params.trip_token);
        }

        if (props.params.product_type === "custom-products") {
            GetCustom(props.params.trip_id, props.params.trip_id_version, setProduct, setGroup, props.params.product_id, props.params.trip_token);
        }
    }
    useEffect(() => {
        if (group !== null) {
            let adults = 0;
            let children = 0;
            let today = new Date();
            let year = today.getFullYear();

            group.map((traveler) => {
                let birth = traveler.birth_date;
                let birth_date = new Date(birth);
                let birth_year = birth_date.getFullYear();
                if ((year - birth_year) > 17) {
                    adults++;
                }
                else {
                    children++;
                }
            });
            setCompo({
                adults: adults,
                children: children
            });
        }
    }, [group]);
    useEffect(() => {
        if (product !== null) {
            if (product.booking_status !== undefined && product.booking_status !== null) {
                // let status = product.booking_status.status_booking;
                // if (status === "CONFIRMED") {
                //     setStatus(1);
                // }
                // else if (status === "WAITING") {
                //     setStatus(2);
                // }
                // else {
                //     setStatus(3);
                // }
                if (product.booking_status.details !== undefined && product.booking_status.details !== null) {
                    setMoreInfo(product.booking_status.details);
                }
                setProductStatus(product.booking_status.status_booking);
                setBookingRef(product.booking_status.item_reference);
            }
        }
    }, [product])
    const getPricesId = (prices) => {
        if (prices.length > 0) {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].master_price === true) {
                    return i;
                }
            }
        }
        return 0;
    }

    const confirmBooking = (type) => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let price = parseFloat(product.prices[getPricesId(product.prices)].purchase_price);
            let request = {
                "id": product.id,
                "status": type === 1 ? "CONFIRMED" : (type === 2 ? "WAITING" : "REFUSED"),
                "ref": bookingRef,
                "details": moreInfo,
                "price": newPrice !== "" ? newPrice : null
            };
            // let url = `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/booking/valid_booking/`;
            let url = newPrice !== "" ? `${API_HREF}client/${window.id_owner}/trip/${product.trip_info.id}/versions/${product.trip_info.current_version}/send_changed_price/` : `${API_HREF}client/${window.id_owner}/trip/${product.trip_info.id}/versions/${product.trip_info.current_version}/booking/valid_booking/`;
            Axios({
                method: "POST",
                headers: headers,
                url: url,
                data: JSON.stringify(request),
            }).then(function (response) {
                setStatus(0);
                setNewPrice('');
                getProduct();
                setProductStatus(type === 1 ? "CONFIRMED" : (type === 2 ? "WAITING" : "REFUSED"));
                enqueueSnackbar(t("provider_website.success_change_status"), {
                    variant: "success",
                });
            }).catch(function (error) {
                console.log('err:', error);
                enqueueSnackbar(t("provider_website.error_change_status"), {
                    variant: "error",
                });
            });
        }
    }
    const handleChange = (event) => {
        setStatus(parseInt(event.target.value));
    };
    let name = "-";
    let my_currency = null;
    let price = 0;
    let reference = product !== null ? (product.booking_status !== null ? product.booking_status.item_reference : 0) : 0;
    if (product !== null) {
        price = parseFloat(product.prices[getPricesId(product.prices)].purchase_price);
        if (product.name !== undefined && product.name !== null) {
            name = product.name;
        }
        else {
            if (product.product_type === 0) {
                name = product.hotel[0].name + " " + product.description;
            }
            if (product.product_type === 6) {
                if (product.segments !== undefined && product.segments !== null) {
                    name = product.segments[0].marketing_airline.commercial_name + " : " + product.segments[0].origin.airport_code + " - " + product.segments[0].destination.airport_code
                }
            }
            if (product.custom_product !== undefined && product.custom_product.title !== undefined) {
                name = product.custom_product.title;
            }
        }
        my_currency = currency_list.find((currency) => {return currency.id === product.prices[getPricesId(product.prices)].purchase_currency});
        return (
            <Grid container style={{display: "block"}}>
                <Grid item>
                    <MaterialProviderHeader tripInfo={tripInfo}/>
                </Grid>
                <Grid item>
                    <Container className={classes.container}>
                        <Grid container direction={"row"} alignItems={"center"} justify={"center"} spacing={4} style={{marginTop: 70}}>
                            <Grid item style={{padding: 15}}>
                                <Typography className={`${classes.genericText} ${classes.bold}`} variant={"h5"}>{t("provider_website.request_booking_confirmation")}</Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                <Grid item>
                    <Container className={classes.container}>
                        <Grid container direction={"column"} style={{marginTop: 20}}>
                            {
                                tripInfo !== null && (
                                    <Grid item style={{marginBottom: 20}}>
                                        <Typography className={`${classes.genericText} ${classes.textCenter}`} variant={"h6"}>{tripInfo.target_client.name + t("provider_website.to_client_requested")}</Typography>
                                    </Grid>
                                )
                            }
                            <Grid item>
                                <Typography className={`${classes.genericText} ${classes.bold} ${classes.underline}`} variant={"h6"}>{t("provider_website.product_info")}</Typography>
                            </Grid>
                            <Grid item>
                                <span className={classes.bold}>{t("provider_website.product_name") + " : "}</span>
                                <span>{name}</span>
                            </Grid>
                            <Grid item>
                                <span className={classes.bold}>{t("provider_website.product_date") + " : "}</span>
                                <span>{t("cart.from") + " " + (product !== null ? (moment.utc(product.start_date).format("L") + " " + t("cart.to") + " " + moment.utc(product.end_date).format("L")) : null)}</span>
                            </Grid>
                            <Grid item>
                                <span className={classes.bold}>{ t("provider_website.product_hours") + " : "}</span>
                                <span>{t("provider_website.starting_hours") + " " + (product !== null ? (moment.utc(product.start_date).format("HH:mm") + " - " + t("provider_website.end_hours") + " " + moment.utc(product.end_date).format("HH:mm")) : null)}</span>
                            </Grid>
                            {/* <Grid item>
                                <span className={classes.bold}>{ t("provider_website.internal_ref") + " : "}</span>
                                <span>{reference}</span>
                            </Grid> */}
                            <Grid item>
                                <span className={classes.bold}>{ t("provider_website.product_price") + " : "}</span>
                                <span>
                                {
                                    (my_currency && price) && (
                                        Intl.NumberFormat(i18n.language, { style: "currency", currency: my_currency.iso_code }).format(Math.round(price))                                                                             
                                    )
                                }
                                {
                                    (my_currency === null || price === null) && (
                                        Math.round(price)                                                                             
                                    )
                                }
                                </span>
                            </Grid>
                            {
                                product.provider_wanted_price &&
                                <Grid item>
                                    <span className={classes.bold}>{ t("provider_website.new_product_price") + " : "}</span>
                                    <span>
                                    {
                                        (my_currency && product.provider_wanted_price) && (
                                            Intl.NumberFormat(i18n.language, { style: "currency", currency: my_currency.iso_code }).format(Math.round(parseFloat(product.provider_wanted_price)))
                                        )
                                    }
                                    {
                                        (my_currency === null || product.provider_wanted_price === null) && (
                                            Math.round(parseFloat(product.provider_wanted_price))                                                                             
                                        )
                                    }
                                    </span>
                                </Grid>
                            }
                            {
                                productStatus !== "" && (
                                    <Grid item>
                                        <span className={classes.bold}>{t("provider_website.product_status") + " : "}</span>
                                        {
                                            productStatus === "CONFIRMED" && (
                                                <span style={{color: "#4caf50", fontWeight: "bold", textTransform: "capitalize" }}>{t("menu.history.CONFIRMED")}</span>
                                            )
                                        }
                                        {
                                            productStatus === "WAITING" && (
                                                <span style={{color: "#ff9800", fontWeight: "bold", textTransform: "capitalize" }}>{t("menu.history.PENDING")}</span>
                                            )
                                        }
                                        {
                                            productStatus === "REFUSED" && (
                                                <span style={{color: "#f44336", fontWeight: "bold", textTransform: "capitalize" }}>{t("menu.history.CANCELLED")}</span>
                                            )
                                        }
                                    </Grid>
                                )
                            }
                            {
                                bookingRef !== "" && (
                                    <Grid item>
                                        <span className={classes.bold}>{t("provider_website.booking_number") + " : "}</span>
                                        <span className={classes.bold}>{bookingRef}</span>
                                    </Grid>
                                )
                            }
                            {
                                moreInfo !== "" && (
                                    <Grid item>
                                        <span className={classes.bold}>{t("provider_website.complementary_info") + " : "}</span>
                                        <span>{moreInfo}</span>
                                    </Grid>
                                )
                            }
                            <Grid item style={{marginTop: 10}}>
                                <Grid container direction={"row"} alignItems={"center"} spacing={1}>
                                    <Grid item>
                                        <Typography className={`${classes.genericText} ${classes.bold} ${classes.underline}`} variant={"h6"}>{t("provider_website.traveler_infos") + " : "}</Typography>
                                    </Grid>
                                    {
                                        compo !== null && (
                                            <Grid item className={classes.genericText}>
                                                <span>(</span>
                                                {
                                                    compo.adults > 0 && (
                                                        <span>{compo.adults + " " + (compo.adults > 1 ? t("poi.adults") : t("poi.adult"))}</span>
                                                    )
                                                }
                                                {
                                                    compo.children > 0 && (
                                                        <span>{" " + compo.children + " " + (compo.children > 1 ? t("poi.enfants") : t("poi.enfant"))}</span>
                                                    )
                                                }
                                                <span>)</span>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                            {
                                group !== null && compo !== null && (
                                    <Grid item>
                                        <Grid container direction={"column"}>
                                        {
                                            group.map((traveler) => {
                                                let today = new Date();
                                                let year = today.getFullYear();
                                                let birth = traveler.birth_date;
                                                let birth_date = new Date(birth);
                                                let birth_year = birth_date.getFullYear();
                                                let age = year - birth_year;
                                                return (
                                                    <Grid item container direction={"row"} key={traveler.id} spacing={1}>
                                                        <Grid item className={classes.genericText}>{age > 18 ? traveler.title : t("poi.child")}</Grid>
                                                        <Grid item className={classes.genericText}>{traveler.first_name}</Grid>
                                                        <Grid item className={classes.genericText}>{traveler.last_name}</Grid>
                                                        <Grid item className={classes.genericText}>{moment(traveler.birth_date).format("L") + " (" + age + " " + (age > 1 ? t("global.years") : t("global.year")) + ")"}</Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid item style={{marginTop: 40}}>
                        {
                            // productStatus === 'CONFIRMED' && (
                            //     <Alert severity='info'>{t('provider_website.product_already_confirmed')}</Alert>
                            // )
                        }
                        {
                            // productStatus !== "CONFIRMED" && (
                                <FormControl variant={"outlined"}>
                                    <InputLabel shrink id="booking-status">
                                        {t("provider_website.product_status")}
                                    </InputLabel>
                                    <Select
                                        native
                                        labelId="booking-status"
                                        value={status}
                                        onChange={handleChange}
                                        label={t("provider_website.product_status")}
                                    >
                                        <option value={0}>{t("cart.edit_booking.edit")}</option>
                                        <option value={2}>{t("provider_website.booking_on_suspend")}</option>
                                        <option value={1}>{t("provider_website.booking_confirmation")}</option>
                                        <option value={3}>{t("provider_website.booking_not_confirmation")}</option>
                                    </Select>
                                </FormControl>
                            // )
                        }
                        {/* <Grid container direction={"row"} spacing={4}>
                            <Grid item>
                                <Button variant="outlined" className={`${status === 1 ? classes.bookingActive : classes.booking}`} onClick={() => setStatus(1)}>{t("provider_website.booking_confirmation")}</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" className={`${status === 2 ? classes.pendingActive : classes.pending}`} onClick={() => setStatus(2)}>{t("provider_website.booking_on_suspend")}</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" className={`${status === 3 ? classes.declinedActive : classes.declined}`} onClick={() => setStatus(3)}>{t("provider_website.booking_not_confirmation")}</Button>
                            </Grid>
                        </Grid> */}
                        </Grid>
                        {
                            status !== 0 && (
                                <Grid item style={{marginTop: 40, width: "100%"}}>
                                    <Grid container direction={"column"} spacing={2}>
                                        {
                                            status === 2 && (
                                                <Grid item>
                                                    <TextField
                                                        variant={"outlined"}
                                                        fullWidth
                                                        label={`${t("provider_website.new_product_price")} ${t('global.in')} ${my_currency.international_name} (${my_currency.iso_code})` }
                                                        onChange={(e) => {setNewPrice(e.target.value)}}
                                                    />
                                                </Grid>
                                            )
                                        }
                                        {
                                            (status === 1 || status === 2) && (
                                                <Grid item>
                                                    <TextField
                                                        variant={"outlined"}
                                                        fullWidth
                                                        label={t("provider_website.booking_number")}
                                                        defaultValue={bookingRef}
                                                        onChange={(e) => {setBookingRef(e.target.value)}}
                                                    />
                                                </Grid>
                                            )
                                        }
                                        <Grid item>
                                            <TextField
                                                variant={"outlined"}
                                                rows={ 5 }
                                                multiline
                                                fullWidth
                                                label={t("provider_website.complementary_info")}
                                                defaultValue={moreInfo}
                                                onChange={(e) => {setMoreInfo(e.target.value)}}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button className={ classes.vaidateButton } onClick={() => {confirmBooking(status)}}>{ t('global.validate') }</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Container>
                </Grid>
            </Grid>
        )
    }
}
export default React.memo(MaterialProviderWebsite);