import React, { useContext } from "react";

export function withContext(targetContext: React.Context<unknown>): (Component: React.ComponentType<unknown>) => React.FC {
    return (Component) => {
        const ResultComponent = (props: unknown) => {
            const context = useContext(targetContext);

            const newProps = {
                ...(props ?? {}),
                context
            } as any;

            return (
                <Component {...newProps} />
            );
        };
        ResultComponent.displayName = `withContext(${Component.name})`;
        return ResultComponent;
    };
}
