import { Destination } from "../objects/destination";
import { getLocalizedValue } from "./getLocalizedValue";

export function getDestinationName(
    locale: number | null,
    destination: Destination
): string {
    const value = getLocalizedValue(
        locale ?? 1,
        destination?.data ?? {
            name: '',
            localization: []
        },
        'name'
    ) ?? '';
    return value.length > 0 ?
        value :
        (
            destination?.data?.international_name ?? ''
        );
}
