import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

export default(props) => {
	return (
		<SvgIcon {...props} viewBox={ "0 0 53.545 53.545" }>
            <g fill="#010002">
                <circle cx="26.686" cy="4.507" r="4.507"/>
                <path d="M28.256 11.163c-1.123-.228-2.344-.218-3.447.042-7.493.878-9.926 9.551-9.239 16.164.298 2.859 4.805 2.889 4.504 0-.25-2.41-.143-6.047 1.138-8.632v9.425c0 .111.011.215.016.322-.003.051-.015.094-.015.146 0 7.479-.013 14.955-.322 22.428-.137 3.322 5.014 3.309 5.15 0 .242-5.857.303-11.717.317-17.578.244.016.488.016.732.002.015 5.861.074 11.721.314 17.576.137 3.309 5.288 3.322 5.15 0-.309-7.473-.32-14.949-.32-22.428 0-.232-.031-.443-.078-.646-.007-3.247-.131-6.497-.093-9.742 1.534 2.597 1.674 6.558 1.408 9.125-.302 2.887 4.206 2.858 4.504 0 .703-6.75-1.847-15.648-9.719-16.204z"/>
            </g>
        </SvgIcon>
	)
}