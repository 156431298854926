import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core";

import Grid from '@material-ui/core/Grid';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import RefreshToBook from './Functions/RefreshToBook';

import { SetTravelerGroups, EditTravelerGroups } from "../../Actions/Trip";
import { EditItemToBook } from "../../Actions/Cart";
import { ReloadTempTravelers } from '../../Actions/Booking';
import { LoadingBackDrop } from "../Common/LoadingBackdrop";

import axios from "axios";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400: {
        fontWeight: 400
    },
    fontSize14: {
        fontSize: 14
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    formControlRootSmall: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    formControlRootSmallRed: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(20px, 10px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    circleBorder: {
        borderRadius: 8
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    errorInfo: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        letterSpacing: 1.25,
        color: "red",
        width: "100%",
        fontSize: 10
    }
}));

const SelectLead = ({ group, mode, product, product_list, tempTravelers }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const checks_passed = useSelector(store => store.booking.checks_passed);
    const end_date = useSelector(store => store.trip.end_date);
    const trip_id = useSelector(store => store.trip.trip_id);
    const version = useSelector(store => store.trip.data_trip);
    const traveler_groups = useSelector(store => store.trip.traveler_groups);
    const travelers = useSelector(store => store.trip.travelers);

    const leadLabel = useRef(null);
    const leadLabelWidth = leadLabel.current ? leadLabel.current.clientWidth : 132;
    const driverLabel = useRef(null);
    const [loading, setLoading] = useState(false);
    const driverLabelWidth = driverLabel.current ? driverLabel.current.clientWidth : 132;

    useEffect(() => {
        //refreshChoices
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            setLoading(true);
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${version.id}/travelers-group/?ordering=-default&limit=100`
            }).then(function (response) {
                dispatch(SetTravelerGroups(response.data.results));
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [travelers]);

    const handleLeadChange = (e) => {
        let { pass_check, headers } = CheckBeforeRequest();
        let request = {
            lead_traveler: e.target.value
        };

        if (pass_check) {
            axios({
                method: "PATCH",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${version.id}/travelers-group/${group.id}/`,
                data: request
            }).then(function (response) {
                dispatch(ReloadTempTravelers(false));
                traveler_groups.map((room, room_index) => {
                    if (room.id === group.id) {
                        dispatch(EditTravelerGroups(response.data, room_index));
                    }
                });
                if (mode !== "group" && product !== null) {
                    RefreshToBook(product, end_date, dispatch, null);
                } else if (mode === "group" && product_list !== null && product_list !== undefined) {
                    product_list.map((product_of_group) => {
                        RefreshToBook(product_of_group, end_date, dispatch, "SelectLead");
                    });
                }
            });
        }     
    };

    const handleDriver = (e) => {
        let { pass_check, headers } = CheckBeforeRequest();
        let request = {
            driver: e.target.value
        };

        if (pass_check) {
            axios({
                method: "PATCH",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_id}/versions/${version.id}/car/${product.id}/`,
                data: request
            }).then(function (response) {
                console.log("");
                dispatch(EditItemToBook(response.data));
            });
        } 
    };

    if (loading) {
        return (
            <LoadingBackDrop open />
        );
    }

    if (mode === "driver") {
        return (
            <FormControl variant="outlined" className={checks_passed === false && product.driver.id === "" ? classes.formControlRootSmallRed : classes.formControlRootSmall }>
                <InputLabel id={`lead-label`} ref={driverLabel} className={classes.fontSize14}>
                    {t("booking.is_contact_lead_car")}
                </InputLabel>
                <Select
                    labelId={`lead-label`}
                    id={`driver-choose-${product.id}`}
                    value={product.driver.id}
                    className={classes.circleBorder}
                    onChange={handleDriver}
                    MenuProps={{ disableScrollLock: true }}
                    input={
                        <OutlinedInput
                            name="lead"
                            id={`driver-input-${product.id}`}
                            labelWidth={driverLabelWidth}
                            autoComplete="off"
                        />
                    }>
                    {
                        tempTravelers.map((traveler) => {
                            let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
                            let title = traveler.title;
                            let first_name = traveler.first_name;
                            let last_name = traveler.last_name;

                            if (age >= 18) {
                                return (
                                    <MenuItem key={`car-${product.id}-driver-${traveler.id}`} value={traveler.id} >
                                        <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                            {t(`title_value.${title}`) + " " + first_name + " " + last_name}
                                        </Grid>
                                    </MenuItem>
                                );
                            }
                        })
                    }
                </Select>
                {
                    checks_passed === false && product.driver.id === "" && (
                        <Grid className={classes.errorInfo}>
                            { t("booking.driver_err") }
                        </Grid>
                    )
                }
            </FormControl>
        );
    }
    return (
        <FormControl variant="outlined" className={checks_passed === false && group.lead_traveler === "" ? classes.formControlRootSmallRed : classes.formControlRootSmall }>
            <InputLabel id={`lead-label`} ref={leadLabel} className={classes.fontSize14}>
                {t("booking.contact_lead")}
            </InputLabel>
            <Select
                labelId={`lead-label`}
                id={`lead-choose-${group.id}`}
                value={group.lead_traveler}
                className={classes.circleBorder}
                onChange={handleLeadChange}
                MenuProps={{ disableScrollLock: true }}
                input={
                    <OutlinedInput
                        name="lead"
                        id={`lead-input-${group.id}-${product !== null ? "product-" + product.id : ""}`}
                        labelWidth={leadLabelWidth}
                        autoComplete="off"
                    />
                }>
                {
                    tempTravelers.map((traveler) => {
                        let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
                        let title = traveler.title;
                        let first_name = traveler.first_name;
                        let last_name = traveler.last_name;

                        if (age >= 18 && group.travelers.includes(traveler.id)) {
                            return (
                                <MenuItem key={`group-${group.id}-lead${traveler.id}-${product !== null ? "product-" + product.id : ""}`} value={traveler.id} >
                                    <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                        {t(`title_value.${title}`) + " " + first_name + " " + last_name}
                                    </Grid>
                                </MenuItem>
                            );
                        }
                    })
                }
            </Select>
            {
                checks_passed === false && group.lead_traveler === "" && (
                    <Grid className={classes.errorInfo}>
                        { t("booking.contact_lead_info_err") }
                    </Grid>
                )
            }
        </FormControl>
    );
};

export default React.memo(SelectLead);
