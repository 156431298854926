import React, { Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import DialogContent from "@material-ui/core/DialogContent";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

const AccommodationAdditionalInfo = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const info = useSelector(state => state.accommodation.additional_info);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const onClose = () => {
        dispatch({type: 'MATERIAL_SET_ADDITIONAL_INFO', payload: null});
    };
    return (
        <Dialog fullScreen={ fullScreen } PaperProps={{style: { borderRadius: fullScreen ? 0 : 16 } }} open={ info !== null } fullWidth maxWidth={ "md" } onClose={ onClose } disableEscapeKeyDown>
            <DialogTitle onClose={ onClose }>
                <Grid container justify={ "space-between" } alignItems={ "center" }>
                    {
                        fullScreen ? (
                            <Grid item>
                                <IconButton edge={ "start" } onClick={ onClose }><NavigateBefore/></IconButton> { t("accommodation.additional_information") }
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item>
                                    { t("accommodation.additional_information") } :
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={ onClose }><Close/></IconButton>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Typography>{ info }</Typography>
                <br/>
            </DialogContent>
        </Dialog>
    );
};

export default React.memo(AccommodationAdditionalInfo);