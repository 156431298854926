export default (event) => {
	let clientEvents = $("#calendar").fullCalendar("clientEvents");
	clientEvents.sort((a, b) => {
		return a.start - b.start;
	});
	if (event) {
		// let arr = $("#calendar").fullCalendar("getEventSources");
		clientEvents.map((client, client_index) => {
			if (client._id === event._id) {
				console.log("client_index:", client_index);
				console.log("client-1:", clientEvents[client_index - 1]);
				console.log("client:", client);
				console.log("client+1:", clientEvents[client_index + 1]);
			}
		});
	}
	else {
		console.log("all");
	}
}