//Dependencies
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
//Core
import Alert from '@material-ui/lab/Alert';

const CartNotCancellableNotification = () => {
    const { t } = useTranslation();
    const has_not_cancellable_items = useSelector(state => state.cart.has_not_cancellable_items);
    if (has_not_cancellable_items > 0) {
        return <Fragment><Alert variant={ 'filled' } severity={ 'warning' }>{ t('cart-material.has-not-cancellable-items') }</Alert></Fragment>;
    }
    return null;
};

export default CartNotCancellableNotification;
