export default (elem, category, quotation_code) => {
	let min = null;
	let currency = null;
	elem.map((provider) => {
		if (!(quotation_code === "marcovasco" && (provider.subprovider_name === "Budget" || provider.subprovider_name === "Dollar" || provider.subprovider_name === "Sixt" || provider.subprovider_name === "sixt" || provider.subprovider_name === "Thrifty" || provider.subprovider_name === "Ucar" || provider.subprovider_name === "IWS" || provider.subprovider_name === "Via Route" || provider.subprovider_name === "Discount Car"))) {
			if (provider.vehicles[category] !== undefined && provider.vehicles[category][0].station_prices !== null) {
				let station_prices = provider.vehicles[category][0].station_prices;
				let min_price = null;
				let min_currency = null;
				station_prices.map((station) => {
					if (min_price === null) {
						min_price = parseFloat(station.prices[0].selling_price);
						min_currency = station.prices[0].selling_currency;
					}
					else if (parseFloat(station.prices[0].selling_price) < min_price) {
						min_price = parseFloat(station.prices[0].selling_price);
						min_currency = station.prices[0].selling_currency;
					}
				});
				if (min === null) {
					min = parseFloat(min_price);
					currency = min_currency;
				}
				else if (parseFloat(min_price) < min) {
					min = parseFloat(min_price);
					currency = min_currency;
				}
			}
		}
	});
	return { min, currency };
}