import i18n from "../../../../i18n.jsx";
import GetCookie from "../../../Common/Functions/GetCookie";

//---- Actions ----//
import { SetUser } from "../../../../Actions/User";

export default (dispatch, id, client) => {
    $.ajax({
        method: "GET",
        headers: {
            "Authorization": "Token " + GetCookie("token"),
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Accept-Language": i18n.language
        },
        url: `${API_HREF}client/${client}/user/${id}/`,
        success: function (data) {
            dispatch(SetUser(data));
        },
        error: function (error) {
            console.log(error);
        }
    });        
}

