const initial_state = {
    users: [],
    selected_note: null,
    selected_user: null,
    selected_demand: null,
    selected_email: null,
    selected_row: -1,
    last_page: 1,
    selected_contact_type: "",
    countries: null,
    active_user_notes: {
        user_id: null,
        notes: []
    }
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "CRM_USERS": {
            new_state.users = action.payload;
            return new_state;
        }
        case "CRM_SELECTED_USER": {
            new_state.selected_user = action.payload;
            return new_state;
        }
        case "CRM_SELECTED_DEMAND": {
            new_state.selected_demand = action.payload;
            return new_state;
        }
        case "CRM_SELECTED_NOTE": {
            new_state.selected_note = action.payload.note;
            return new_state;
        }
        case "CRM_ACTIVE_USER_NOTES": {
            new_state.active_user_notes = action.payload;
            return new_state;
        }
        case "CRM_SELECTED_EMAIL": {
            new_state.selected_email = action.payload;
            return new_state;
        }
        case "CRM_SELECTED_ROW": {
            new_state.selected_row = action.payload;
            return new_state;
        }
        case "CRM_LAST_PAGE": {
            new_state.last_page = action.payload;
            return new_state;
        }
        case "CRM_COUNTRIES": {
            new_state.countries = action.payload;
            return new_state;
        }
        case "CRM_SELECTED_CONTACT_TYPE": {
            new_state.selected_contact_type = action.payload;
            return new_state;
        }
    }
    return new_state;
}
