//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import {
    Fab,
    Grid,
    IconButton,
    Stack,
    Step,
    StepButton,
    StepContent,
    StepLabel,
    Stepper,
    styled,
    Tooltip,
    Typography,
} from '@mui/material';
import i18next from 'i18next';

import localeText from '../../Functions/localeText.js';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { findLastIndex } from 'lodash';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    bold: {
        fontWeight: 'bold'
    },
    capitalize: {
        textTransform: "capitalize"
    },
    fabColorSelected: {
        boxShadow: 'none !important',
        zIndex: '1 !important',
        backgroundColor: `${main_color} !important`,
        color: 'white !important',
        "&:hover": {
            backgroundColor: `${main_color}CA !important`
        }
    },
    fabColor: {
        boxShadow: 'none !important',
        zIndex: '1 !important',
    },
    stepper: {
        '& .MuiStepConnector-root.MuiStepConnector-horizontal': {
            top: 19
        },
        '& .MuiStepConnector-line.MuiStepConnector-lineHorizontal': {
            marginRight: 10,
            marginLeft: 10
        }
    },
    main_color: {
        color: `${main_color}`
    }
}));

type CircuitStepperProps = {
    filterDay: any,
    setStep: any,
    step: number
};

const CircuitStepper = (props: CircuitStepperProps) => {
    const { filterDay, setStep, step } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const locales = useSelector(state => state.user.locales);

    const current_locale = locales.find((el) => {return el.language_code === i18next.language});

    const containerRef = useRef<HTMLDivElement>(null);
    const itemsRef = useRef<HTMLDivElement[]>([]);
    const [areElementsAllVisible, setAreElementsAllVisible] = useState<boolean>(false);

    const capitalize = (str:string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const handleStep = (index: number) => {
        setStep(index + 1);
    }
    const isElementVisible = (containerElement: HTMLDivElement | null, itemElement: HTMLDivElement) => {
        if (containerElement) {
            const container = containerElement.getBoundingClientRect();
            const element = itemElement.getBoundingClientRect();
            const containerLeft = Math.trunc(container.left);
            const containerRight = Math.trunc(container.right);
            const elementLeft = Math.trunc(element.left);
            const elementRight = Math.trunc(element.right);
            return (
                containerLeft <= elementLeft &&
                elementLeft <= containerRight
            ) && (
                containerLeft <= elementRight &&
                elementRight <= containerRight
            );
        }
        return false;
    };
    const onPrev = () => {
        const firstVisibleElementIndex = itemsRef.current.findIndex((item) => {
            return isElementVisible(containerRef.current, item);
        });
        itemsRef.current[firstVisibleElementIndex - 1]?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center'
        });
    };

    const onNext = () => {
        const lastVisibleElementIndex = findLastIndex(
            itemsRef.current,
            (item) => {
                return isElementVisible(containerRef.current, item);
            }
        );
        itemsRef.current[lastVisibleElementIndex + 1]?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center'
        });
    };
    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, filterDay.length);
    }, [filterDay]);
    return (
        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{width: '100%'}}>
            {
                !areElementsAllVisible &&
                <IconButton onClick={onPrev} style={{marginBottom: 40}}>
                    <ArrowBackIosNew className={classes.main_color}/>
                </IconButton>
            }
            <Stepper nonLinear activeStep={ step - 1 } alternativeLabel ref={containerRef} sx={{ overflow: 'hidden' }} className={classes.stepper}>
            {
                filterDay.map((day: any, index: number) => {
                    if (day.step_type === undefined || day.step_type === 'STEP') {
                        let days = day.days !== undefined ? day.days : day.day;
                        let localization = day.localization !== undefined ? day.localization : (day.destination !== undefined && day.destination !== null ? day.destination.data.localization : '-');
                        let name = day.title !== undefined ? day.title : (day.destination !== undefined && day.destination !== null ? day.destination.data.international_name : '-');
                        const content = (
                            <>
                                <StepLabel
                                    StepIconComponent={ () => <Fab className={clsx({[classes.fabColorSelected]: step - 1 === index, [classes.fabColor]: step - 1 !== index})} size={'small'} disableRipple={true} disableFocusRipple={true}>{ index + 1 }</Fab> }
                                    optional={
                                        <Stack>
                                            <Tooltip title={localeText(current_locale.id, localization, name , 'title')}>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                >
                                                    {
                                                        // t<string>(
                                                        //     'shared.circuit-step-destination',
                                                        //     { name: localeText(current_locale.id, localization, name , 'title') }
                                                        // )
                                                        localeText(current_locale.id, localization, name , 'title')
                                                    }
                                                </Typography>
                                            </Tooltip>
                                        </Stack>
                                    }
                                >
                                { days.length > 1 ? capitalize(t<string>("global.from")) + " " + t<string>("global.day1") + " " + days[0] + " " + t<string>("global.to") + " " + t<string>("global.day1") + " " + days[days.length - 1] : capitalize(t<string>("global.day1")) + " " + days[0] }
                                </StepLabel>
                            </>
                        )
                        return (
                            <StepContainer key={ index }
                                ref={(element) => {
                                    if (element) {
                                        itemsRef.current[index] = element;
                                        setAreElementsAllVisible(
                                            itemsRef.current.every((item) => {
                                                return isElementVisible(containerRef.current, item);
                                            })
                                        );
                                    }
                                }}
                                onClick={ () => handleStep(index) }
                            >
                                {content}
                                {/* <Step style={{minWidth: 175, cursor: 'pointer'}}>
                                    <Typography variant="body2" className={ classes.bold }>
                                        <span>{ days.length > 1 ? capitalize(t<string>("global.from")) + " " + t<string>("global.day1") + " " + days[0] + " " + t<string>("global.to") + " " + t<string>("global.day1") + " " + days[days.length - 1] : capitalize(t<string>("global.day1")) + " " + days[0] }</span>
                                    </Typography>
                                    <Typography className={`${classes.genericText} ${classes.capitalize} ${classes.bold}`} >
                                        <span dangerouslySetInnerHTML={ {__html: localeText(current_locale.id, localization, name , 'title')} }/>
                                    </Typography>
                                </Step> */}
                            </StepContainer> 
                            // <StepContainer
                                
                            //     onClick={() => props.setStep && props.setStep(index)}
                            // >
                            //     {content}
                            // </StepContainer>
                        )
                    }
                })
            }
            </Stepper>
            {
                !areElementsAllVisible && 
                <IconButton onClick={onNext} style={{marginBottom: 40}}>
                    <ArrowForwardIos className={classes.main_color}/>
                </IconButton>
            }
        </Stack>
    );
};
export default CircuitStepper;

const StepContainer = styled(Step)(() => ({
    minWidth: 175,
    cursor: 'pointer'
}));