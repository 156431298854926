import { flatten, flattenDeep } from "lodash";
import { TFunction } from "i18next";
import { useCartProducts } from "../../../Itinerary/network/cartProducts";
import { Flight } from "../../../Itinerary/network/flight";
import Icon from "../../../../Img/noun-plane-687055-1.png?roadbook";

export function transformFlightsToCalendarTransports(
    options: {
        cart: ReturnType<typeof useCartProducts>,
        dateString: string,
        t: TFunction
    }
) {
    const date = window.moment.utc(options.dateString, "YYYY-MM-DD").startOf('day');
    const flights = flattenDeep(
        options.cart.flights.map((item) => {
            if (item.type === 'normal') {
                return item.flight.outbounds.map((item) => {
                    return item.legs;
                });
            }
            return item.flight.flight_segment.map((item) => ({
                origin: {
                    international_name: item.origin_airport?.international_name ??
                        item.origin_station?.international_name ??
                        '',
                    iata_city: item.origin_airport?.iata_city ??
                        item.origin_station?.iata_city ??
                        {
                            id: -1,
                            city_code: '(UNKNOWN)',
                            iata_country: {} as any,
                            international_name: 'UNKNOWN',
                            name: 'UNKNOWN',
                            tsv: null
                        }
                },
                destination: {
                    international_name: item.destination_airport?.international_name ??
                        item.destination_station?.international_name ??
                        '',
                    iata_city: item.destination_airport?.iata_city ??
                        item.destination_station?.iata_city ??
                        {
                            id: -1,
                            city_code: '(UNKNOWN)',
                            iata_country: {} as any,
                            international_name: 'UNKNOWN',
                            name: 'UNKNOWN',
                            tsv: null
                        }
                },
                operating_airline: {
                    airline_code: item.airline.airline_code,
                    commercial_name: item.airline.commercial_name
                },
                departure_time: item.start_date,
                arrival_time: item.end_date,
                origin_terminal: item.origin_terminal ?? '',
                destination_terminal: item.destination_terminal ?? '',
                flight_number: item.flight_number.toString() ?? ''
            }));
        })
    ) as Flight['outbounds'][number]['legs'];
    const correspondingFlights = flights.filter((flight) => {
        return window.moment.utc(flight.departure_time).startOf('day').isSame(
            date,
            'days'
        ) || window.moment.utc(flight.arrival_time).startOf('day').isSame(
            date,
            'days'
        );
    });
    return flatten(
        correspondingFlights.map((flight) => {
            const departure = window.moment.utc(flight.departure_time);
            const arrival = window.moment.utc(flight.arrival_time);
            const result = [
                departure.clone().startOf('day').isSame(
                    date,
                    'days'
                ) ?
                    {
                        dateOrder: flight.departure_time,
                        url: Icon,
                        hourly: departure.format('HH:mm'),
                        description: options.t(
                            'roadbook.flight-departure-description',
                            {
                                city: flight.origin?.iata_city.name ??
                                      flight.origin?.iata_city.international_name ??
                                      flight.origin_station?.iata_city?.name ??
                                      flight.origin_station?.iata_city?.international_name,
                                date: departure.format('DD MMMM'),
                                airline: flight.operating_airline?.commercial_name,
                                flight: 'None'
                            }
                        )
                    } :
                    null,
                arrival.clone().startOf('day').isSame(
                    date,
                    'days'
                ) ?
                    {
                        dateOrder: flight.arrival_time,
                        url: Icon,
                        hourly: arrival.format('HH:mm'),
                        description: options.t(
                            'roadbook.flight-arrival-description',
                            {
                                city: flight.destination?.iata_city.name ??
                                      flight.destination?.iata_city.international_name ??
                                      flight.destination_station?.iata_city?.name ??
                                      flight.destination_station?.iata_city?.international_name,
                                date: arrival.format('DD MMMM'),
                                airline: flight.operating_airline?.commercial_name
                            }
                        )
                    } :
                    null
            ];
            //eslint-disable-next-line max-nested-callbacks
            return result.filter((item): item is NonNullable<typeof result[0]> => {
                return !!item;
            });
        })
    );
}
