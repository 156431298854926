import React from "react";
import { ListItemText, MenuItem, Select, SelectChangeEvent, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";

const BorderStyles = [
    "solid",
    "dashed",
    "dotted"
] as const;

export type MailTemplateVisualEditorBorderStyle = typeof BorderStyles[number];

type Props = {
    style: MailTemplateVisualEditorBorderStyle,
    onChangeStyle: (style: MailTemplateVisualEditorBorderStyle | null) => void
}

export function MailTemplateVisualEditorBorderStyleOption(props: Props): JSX.Element {
    const { t } = useTranslation();

    const onChange = (event: SelectChangeEvent) => {
        if (event.target.value === '') {
            props.onChangeStyle(null);
        } else {
            props.onChangeStyle(event.target.value as MailTemplateVisualEditorBorderStyle);
        }
    };

    return (
        <MailTemplateVisualEditorOptionsSection>
            <Typography>{t<string>('shared.mail-template-visual-editor-border-style')}</Typography>
            <Select
                value={props.style}
                onChange={onChange}
                renderValue={(value) => (
                    <div>
                        <ListItemText>{value}</ListItemText>
                        <Preview
                            sx={{
                                borderTopStyle: value,
                                width: '100%'
                            }}
                        />
                    </div>
                )}
                MenuProps={{ disablePortal: true }}
            >
                {
                    BorderStyles.map((item) => (
                        <MenuItem key={item} value={item}>
                            <ListItemText>{item}</ListItemText>
                            <Preview sx={{ borderTopStyle: item }} />
                        </MenuItem>
                    ))
                }
            </Select>
        </MailTemplateVisualEditorOptionsSection>
    );
}

const Preview = styled('div')(() => ({
    borderTopWidth: 2,
    borderTopColor: '#000',
    width: 100,
    height: 0
}));
