/*eslint-disable @typescript-eslint/no-unused-vars */
/*eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTripById, patchTrip } from "../Functions/TripFunction";

import { SetSelectedDemand } from "../../../../Actions/Crm";

import Container from '@material-ui/core/Container';
import { Button, Grid, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Popover from '@mui/material/Popover';
import InfoIcon from '@mui/icons-material/Info';
import NoteIcon from '@mui/icons-material/Note';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import SmsIcon from '@mui/icons-material/Sms';

import '../crm.css';

import moment from "moment";
import CrmDemandField from "./CrmDemandField";
import CrmDemandLeftPanel from "./CrmDemandLeftPanel";
import CrmDemandRightPanel from "./CrmDemandRightPanel";
import CrmDemandActivity from "./CrmDemandActivity";
import CrmDemandFormDialog from "../CrmDemandList/CrmDemandFormDialog";

const CrmDemandDetail = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = useSelector(store => store.user.user);
    const selected_demand = useSelector(store => store.crm.selected_demand);
    const [currentAction, setCurrentAction] = useState(null);
    const [openTripForm, setOpenTripForm] = React.useState(false);

    const [expand, setExpand] = useState(null);
    const handleExpand = (type) => {
        if (type === expand) {
            setExpand(null);
        } else {
            setExpand(type);
        }
    };

    const returnToList = () => {
        props.router.push(`/${window.url_name}/menu/crm-demands`);
    };

    useEffect(() => {
        if (user?.client && props.params?.demand_id) {
            getTripById(user.client, "is_sketch=true", props.params.demand_id, (resp) => {
                if (resp?.data) {
                    dispatch(SetSelectedDemand(resp.data));
                }
            });
        }
    }, [user, props.params]);

    //Handle popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    /**ACTIONS */
    const actionCreate = (type) => {
        setCurrentAction(type);
    };

    /**HANDLE UPDATE DEMAND */
    const updateDemand = (field, value, callback) => {
        if (selected_demand) {
            let dem_temp = JSON.parse(JSON.stringify(selected_demand));
            dem_temp[field] = value;
            patchTrip(user.client, dem_temp.id, dem_temp, () => {
                dispatch(SetSelectedDemand(dem_temp));
                if (callback !== null && callback !== undefined) {
                    callback();
                }
            });
        }
    };

    const closeDialog = () => {
        setOpenTripForm(false);
    };
    const createDemand = (trip) => {

    };

    if (selected_demand) {
        return (
            <Container className="crm-user-detail">
                <Grid container>
                    <Grid xs={3} item className="crm-user-detail-contact">
                        <Button variant="text" size="small" style={{ textTransform: "unset" }} onClick={returnToList}><ArrowBackIosIcon fontSize="inherit" />{t("crm.demand.back_to_demand_list")}</Button>
                        <Grid className="crm-user-detail-contact-header">
                            <Grid>
                                <CrmDemandField value={`${selected_demand.name}`}
                                    callback={(v) => updateDemand('name', v.newValue, v.onFinish)}
                                    expandable={true} title={true}
                                />
                                <IconButton className="info-btn" onClick={handleClick}><InfoIcon /></IconButton>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom'
                                    }}
                                >
                                    <div style={{ padding: 15 }}>
                                        <p>{t("crm.created_on")} : {selected_demand.created_date ? moment.utc(selected_demand.created_date).format("DD/MM/YYYY HH:mm") : ''}</p>
                                        <p>{t("crm.created_by")} : {selected_demand.creator?.username}</p>
                                        <p>{t("crm.edited_on")} : {selected_demand.modified_date ? moment.utc(selected_demand.modified_date).format("DD/MM/YYYY HH:mm") : ''}</p>
                                        <p>{t("crm.edited_by")} : {selected_demand.last_author?.username}</p>
                                    </div>
                                </Popover>
                            </Grid>
                        </Grid>
                        <hr />
                        <div style={{ display: "flex", justifyContent: "center", gap: "5px", flexWrap: "wrap" }}>
                            <Button style={{ textTransform: "none", margin: 2 }} onClick={() => setOpenTripForm(true)} variant="outlined">{t("crm.create_trip")}</Button>
                        </div>
                        <div className="user-detail-actions">
                            <div>
                                <IconButton onClick={() => actionCreate('call')}><CallIcon /></IconButton>
                                <p>{t('crm.title.call')}</p>
                            </div>
                            <div>
                                <IconButton onClick={() => actionCreate('email')}><EmailIcon /></IconButton>
                                <p>{t('crm.title.email')}</p>
                            </div>
                            <div>
                                <IconButton onClick={() => actionCreate('sms')}><SmsIcon /></IconButton>
                                <p>{t('crm.title.sms')}</p>
                            </div>
                            <div>
                                <IconButton onClick={() => actionCreate('note')}><NoteIcon /></IconButton>
                                <p>{t('crm.title.note')}</p>
                            </div>
                            <div>
                                <IconButton onClick={() => actionCreate('remark')}><TextSnippetIcon /></IconButton>
                                <p>{t('crm.title.remark')}</p>
                            </div>
                        </div>
                        <CrmDemandLeftPanel />
                    </Grid>

                    <Grid xs={expand === 'activity' ? 8.5 : 5} display={expand && expand !== "activity" ? "none" : "block"} item className="crm-user-detail-activity">
                        <CrmDemandActivity />
                    </Grid>

                    <Grid xs={expand === 'history' ? 9 : (expand ? 0.5 : 4)} item className="crm-user-detail-history">
                        {
                            (!expand || expand === 'activity') &&
                            <div className="expand-button" onClick={() => handleExpand('activity')} style={{ top: 70 }} >
                                {expand === 'activity' ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                            </div>
                        }
                        {
                            (!expand || expand === 'history') &&
                            <div className="expand-button" onClick={() => handleExpand('history')}>
                                {expand === 'history' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </div>
                        }
                        <CrmDemandRightPanel />
                    </Grid>
                    <CrmDemandFormDialog open={openTripForm} handleClose={closeDialog} onConfirm={createDemand} title={t('crm.create_trip')} makeTrip={true} />
                </Grid>
            </Container>
        );
    }
    return <></>;
};
export default CrmDemandDetail;
