import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

//---- Actions ----//
import { SetManagementStepAndUser } from "../../../Actions/Menu";

import CheckBeforeRequest from "../../Common/CheckBeforeRequest.js";
import HasPermission from "../../Common/Functions/HasPermission.js";
import CreateUser from "./Functions/CreateUser";
import EditUserRequest from "./Functions/EditUser";

const useRowStyles = makeStyles({
    returnButton: {
	    "color": "grey",
	    "backgroundColor": "white",
	    '&:hover': {
	        color: "white",
	        backgroundColor: "grey"
	    }
    },
    inputBorder: {
	    "&& .mui-jss-MuiOutlinedInput-root": {
	        borderRadius: 8
	    }
    },
    genericText: {
        color: "#0000008A",
        fontStyle: "normal",
        fontFamily: "Roboto"
    },
    bold: {
    	fontWeight: "bold"
    },
    footer: {
        position: "sticky",
        bottom: 0,
        zIndex: 2,
        backgroundColor: "white"
    },
    footerMobile: {
        position: "sticky",
        bottom: 64,
        zIndex: 2,
        backgroundColor: "white"
    },
    orangeButton: {
        color: "white",
        backgroundColor: "#E6592F"
    }
});

const EditUser = () => {
    const { t } = useTranslation();
    const classes = useRowStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down("sm"));

    const user = useSelector(store => store.user.user);
    const edit_user = useSelector(store => store.menu.edit_user);
    const permissions = useSelector(store => store.user.permissions);

    const [userPermission, setUserPermission] = useState([]);
    const [userId, setUserId] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [userNameError, setUserNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});

    const [viewUser, setViewUser] = useState(false);
    const [addUser, setAddUser] = useState(false);
    const [changeUser, setChangeUser] = useState(false);
    const [deleteUser, setDeleteUser] = useState(false);
    const [viewMarginPrice, setViewMarginPrice] = useState(false);
    const [addMarginPrice, setAddMarginPrice] = useState(false);
    const [changeMarginPrice, setChangeMarginPrice] = useState(false);
    const [deleteMarginPrice, setDeleteMarginPrice] = useState(false);
    const [viewAuthGroup, setViewAuthGroup] = useState(false);
    const [addAuthGroup, setAddAuthGroup] = useState(false);
    const [changeAuthGroup, setChangeAuthGroup] = useState(false);
    const [deleteAuthGroup, setDeleteAuthGroup] = useState(false);
    const [viewTrip, setViewTrip] = useState(false);
    const [addTrip, setAddTrip] = useState(false);
    const [changeTrip, setChangeTrip] = useState(false);
    const [deleteTrip, setDeleteTrip] = useState(false);

    useEffect(() => {
        if (edit_user !== null) {
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
			    axios({
			        method: "GET",
			        headers: headers,
			        url: `${API_HREF}client/${user.client}/user/${edit_user.id}/permissions/?all=true`
			    }).then(function (response) {
			        setUserPermission(response.data);
			    }).catch(function (error) {
			        //TODO: show snackbar error
			        console.log('error:', error);
			    });
            }
        } else {
            reset();
        }
        return () => {
            dispatch(SetManagementStepAndUser(1, null));
        };
    }, []);
    useEffect(() => {
        reset();
    }, [userPermission]);
    useEffect(() => {
        if (Object.keys(errorMessages).length > 0) {
            console.log('errorMessages:', errorMessages);
            Object.keys(errorMessages).map((key) => {
                if (key === "password") {
                    setPasswordError(true);
                }
                if (key === "email") {
                    setEmailError(true);
                }
            });
        }
    }, [errorMessages]);
    const reset = () => {
        if (edit_user !== null) {
            setUserId(edit_user.id);
            setFirstName(edit_user.first_name);
            setLastName(edit_user.last_name);
            setEmail(edit_user.email);
            setUserName(edit_user.username);
            setPassword("");
            setConfirmPassword("");

            setFirstNameError(false);
            setLastNameError(false);
            setEmailError(false);
            setUserNameError(false);
            setPasswordError(false);


            setViewUser(HasPermission(userPermission, "view_user", edit_user));
            setAddUser(HasPermission(userPermission, "add_user", edit_user));
            setChangeUser(HasPermission(userPermission, "change_user", edit_user));
            setDeleteUser(HasPermission(userPermission, "delete_user", edit_user));
            setViewMarginPrice(HasPermission(userPermission, "view_marginprice", edit_user));
            setAddMarginPrice(HasPermission(userPermission, "add_marginprice", edit_user));
            setChangeMarginPrice(HasPermission(userPermission, "change_marginprice", edit_user));
            setDeleteMarginPrice(HasPermission(userPermission, "delete_marginprice", edit_user));
            setViewAuthGroup(HasPermission(userPermission, "view_authgroup", edit_user));
            setAddAuthGroup(HasPermission(userPermission, "add_authgroup", edit_user));
            setChangeAuthGroup(HasPermission(userPermission, "change_authgroup", edit_user));
            setDeleteAuthGroup(HasPermission(userPermission, "delete_authgroup", edit_user));
            setViewTrip(HasPermission(userPermission, "view_trip", edit_user));
            setAddTrip(HasPermission(userPermission, "add_trip", edit_user));
            setChangeTrip(HasPermission(userPermission, "change_trip", edit_user));
            setDeleteTrip(HasPermission(userPermission, "delete_trip", edit_user));
        } else {
            setUserId(null);
            setFirstName("");
            setLastName("");
            setEmail("");
            setUserName("");
            setPassword("");
            setConfirmPassword("");

            setFirstNameError(false);
            setLastNameError(false);
            setEmailError(false);
            setUserNameError(false);
            setPasswordError(false);


            setViewUser(false);
            setAddUser(false);
            setChangeUser(false);
            setDeleteUser(false);
            setViewMarginPrice(false);
            setAddMarginPrice(false);
            setChangeMarginPrice(false);
            setDeleteMarginPrice(false);
            setViewAuthGroup(false);
            setAddAuthGroup(false);
            setChangeAuthGroup(false);
            setDeleteAuthGroup(false);
            setViewTrip(false);
            setAddTrip(false);
            setChangeTrip(false);
            setDeleteTrip(false);
        }
    };
    const returnButton = () => {
        dispatch(SetManagementStepAndUser(1, null));
    };
    const checkPassword = () => {
	    if (password !== confirmPassword) {
	        setPasswordError(true);
	    } else if (password === "" && confirmPassword === "") {
	        setPasswordError(false);
	    } else {
            setPasswordError(false);
        }
    };
    const checkEmail = () => {
        let re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(email)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    };
    const checkSave = () => {
        let error = false;
        if (firstName === "") {
            error = true;
            setFirstNameError(true);
        }
        if (lastName === "") {
            error = true;
            setLastNameError(true);
        }
        if (email === "") {
            error = true;
            setEmailError(true);
        }
        if (userName === "") {
            error = true;
            setUserNameError(true);
        }
        if (edit_user === null) {
            if (password === "") {
                error = true;
                setPasswordError(true);
            }
        }
        if (error) {
            enqueueSnackbar(t("global.empty_fields"), { variant: "error" });
        } else if (edit_user === null) {
            CreateUser(firstName, lastName, email, userName, password, user.client, permissions, userPermission, viewUser, addUser, changeUser,	deleteUser,	viewMarginPrice, addMarginPrice, changeMarginPrice, deleteMarginPrice, viewAuthGroup, addAuthGroup, changeAuthGroup, deleteAuthGroup, viewTrip, addTrip, changeTrip, deleteTrip, setErrorMessages, dispatch, enqueueSnackbar, t);
        } else {
            EditUserRequest(firstName, lastName, email, userName, password, user.client, permissions, userPermission, viewUser, addUser, changeUser,	deleteUser,	viewMarginPrice, addMarginPrice, changeMarginPrice, deleteMarginPrice, viewAuthGroup, addAuthGroup, changeAuthGroup, deleteAuthGroup, viewTrip, addTrip, changeTrip, deleteTrip, userId, setErrorMessages, dispatch, enqueueSnackbar, t);
        }
    };
    return (
        <Fragment>
            <Grid item xs={12}>
                <Grid container direction={"row"} justify={"flex-start"} alignItems={"center"}>
                    <Grid item>
                        <Button
                            variant={"contained"}
                            className={classes.returnButton}
                            onClick={returnButton}
                        >
                            {t("menu.user_management.return_to_tab")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <Typography variant="h4" gutterBottom className={classes.genericText}>
                    {edit_user !== null ? t("menu.user_management.edit_user") : t("menu.user_management.create_user")}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid container direction="column" spacing={4} style={{ marginBottom: 10 }}>
                    <Grid item><Typography variant={"h6"}>{t("global.personal_information")}</Typography></Grid>
                    <Grid item>
                        <TextField className={classes.inputBorder} fullWidth error={userNameError} value={userName} helperText={userNameError ? t("global.empty_check") : ""} onChange={(e) => {
                            setUserName(e.target.value); setUserNameError(false);
                        }} label={ t("menu.profile.username") } variant="outlined" />
                    </Grid>
                    <Grid item>
                        <TextField className={classes.inputBorder} fullWidth error={firstNameError} value={firstName} helperText={firstNameError ? t("global.empty_check") : ""} onChange={(e) => {
                            setFirstName(e.target.value); setFirstNameError(false);
                        }} label={ t("menu.profile.surname") } variant="outlined" />
                    </Grid>
                    <Grid item>
                        <TextField className={classes.inputBorder} fullWidth error={lastNameError} value={lastName} helperText={lastNameError ? t("global.empty_check") : ""} onChange={(e) => {
                            setLastName(e.target.value); setLastNameError(false);
                        }} label={ t("global.name") } variant="outlined" />
                    </Grid>
                    <Grid item>
                        <TextField className={classes.inputBorder} onBlur={checkEmail} error={emailError} helperText={emailError ? (Object.keys(errorMessages).length > 0 ? errorMessages.email !== undefined && errorMessages.email.join(" ") : t("global.empty_check")) : ""} fullWidth value={email} onChange={(e) => {
                            setEmail(e.target.value); setEmailError(false);
                        }} label={ t("global.email") } variant="outlined" />
                    </Grid>
                    <Grid item>
                        <TextField className={classes.inputBorder} autoComplete={"new-password"} error={passwordError} helperText={passwordError ? (Object.keys(errorMessages).length > 0 ? errorMessages.password !== undefined && errorMessages.password.join(" ") : t("global.empty_check")) : ""} fullWidth value={password} type={"password"} onChange={(e) => {
                            setPassword(e.target.value); setPasswordError(false);
                        }} label={ t("menu.tmp_pwd") } variant="outlined" />
                    </Grid>
                    <Grid item>
                        <TextField className={classes.inputBorder} onBlur={checkPassword} error={passwordError} fullWidth value={confirmPassword} type={"password"} onChange={(e) => setConfirmPassword(e.target.value)} label={ t("menu.confirm_pwd") } variant="outlined" />
                    </Grid>
                </Grid>
            </Grid>
            <Divider/>
            <Grid item xs={12} style={{ marginTop: 20 }}>
            	<Grid container direction={"column"} spacing={4} style={{ marginBottom: 10 }}>
            		<Grid item><Typography variant={"h6"}>{ t("menu.user_management.rights") }</Typography></Grid>
            		{
            			(HasPermission(permissions, "view_user", user) || HasPermission(permissions, "add_user", user) 
            			|| HasPermission(permissions, "change_user", user) || HasPermission(permissions, "delete_user", user)) && (
	            			<Fragment>
        						<Grid item><Typography variant={"subtitle1"} className={classes.bold}>{t("menu.user_management.users")}</Typography></Grid>
	            				<Grid item>
	            					<Grid container direction={"row"} justify={"flex-start"} alignItems={"center"}>
	            					{
	            					    (HasPermission(permissions, "view_user", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={viewUser} onChange={() => {
                                                            setViewUser(!viewUser);
                                                        }} name="viewUser" />}
	            					    	        label={t("menu.user_management.list")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					{
	            					    (HasPermission(permissions, "add_user", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={addUser} onChange={() => {
                                                            setAddUser(!addUser);
                                                        }} name="addUser" />}
	            					    	        label={t("menu.user_management.addition")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					{
	            					    (HasPermission(permissions, "change_user", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={changeUser} onChange={() => {
                                                            setChangeUser(!changeUser);
                                                        }} name="changeUser" />}
	            					    	        label={t("menu.user_management.edition")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					{
	            					    (HasPermission(permissions, "delete_user", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={deleteUser} onChange={() => {
                                                            setDeleteUser(!deleteUser);
                                                        }} name="deleteUser" />}
	            					    	        label={t("menu.user_management.remove")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					</Grid>
	            				</Grid>
	            			</Fragment>
            			)
            		}
            		{
            		    (HasPermission(permissions, "view_authgroup", user) || HasPermission(permissions, "add_authgroup", user) 
            		    || HasPermission(permissions, "change_authgroup", user) || HasPermission(permissions, "delete_authgroup", user)) && (
            		    	<Fragment>
        						<Grid item><Typography variant={"subtitle1"} className={classes.bold}>{t("menu.user_management.roles")}</Typography></Grid>
	            				<Grid item>
	            					<Grid container direction={"row"} justify={"flex-start"} alignItems={"center"}>
	            					{
	            					    (HasPermission(permissions, "view_authgroup", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={viewAuthGroup} onChange={() => {
                                                            setViewAuthGroup(!viewAuthGroup);
                                                        }} name="viewAuthGroup" />}
	            					    	        label={t("menu.user_management.list")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					{
	            					    (HasPermission(permissions, "add_authgroup", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={addAuthGroup} onChange={() => {
                                                            setAddAuthGroup(!addAuthGroup);
                                                        }} name="addAuthGroup" />}
	            					    	        label={t("menu.user_management.addition")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					{
	            					    (HasPermission(permissions, "change_authgroup", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={changeAuthGroup} onChange={() => {
                                                            setChangeAuthGroup(!changeAuthGroup);
                                                        }} name="changeAuthGroup" />}
	            					    	        label={t("menu.user_management.edition")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					{
	            					    (HasPermission(permissions, "delete_authgroup", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={deleteAuthGroup} onChange={() => {
                                                            setDeleteAuthGroup(!deleteAuthGroup);
                                                        }} name="deleteAuthGroup" />}
	            					    	        label={t("menu.user_management.remove")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					</Grid>
	            				</Grid>
	            			</Fragment>
            		    )
            		}
            		{
            		    (HasPermission(permissions, "view_marginprice", user) || HasPermission(permissions, "add_marginprice", user)
                        || HasPermission(permissions, "change_marginprice", user) || HasPermission(permissions, "delete_marginprice", user)) && (
            		    	<Fragment>
        						<Grid item><Typography variant={"subtitle1"} className={classes.bold}>{t("menu.margin_title")}</Typography></Grid>
	            				<Grid item>
	            					<Grid container direction={"row"} justify={"flex-start"} alignItems={"center"}>
	            					{
	            					    (HasPermission(permissions, "view_marginprice", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={viewMarginPrice} onChange={() => {
                                                            setViewMarginPrice(!viewMarginPrice);
                                                        }} name="viewMarginPrice" />}
	            					    	        label={t("menu.user_management.list")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					{
	            					    (HasPermission(permissions, "add_marginprice", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={addMarginPrice} onChange={() => {
                                                            setAddMarginPrice(!addMarginPrice);
                                                        }} name="addMarginPrice" />}
	            					    	        label={t("menu.user_management.addition")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					{
	            					    (HasPermission(permissions, "change_marginprice", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={changeMarginPrice} onChange={() => {
                                                            setChangeMarginPrice(!changeMarginPrice);
                                                        }} name="changeMarginPrice" />}
	            					    	        label={t("menu.user_management.edition")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					{
	            					    (HasPermission(permissions, "delete_marginprice", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={deleteMarginPrice} onChange={() => {
                                                            setDeleteMarginPrice(!deleteMarginPrice);
                                                        }} name="deleteMarginPrice" />}
	            					    	        label={t("menu.user_management.remove")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					</Grid>
	            				</Grid>
	            			</Fragment>
            		    )
            		}
            		{
            		    (HasPermission(permissions, "view_trip", user) || HasPermission(permissions, "add_trip", user) 
                        || HasPermission(permissions, "change_trip", user) || HasPermission(permissions, "delete_trip", user)) && (
            		    	<Fragment>
        						<Grid item><Typography variant={"subtitle1"} className={classes.bold}>{t("menu.user_management.trip")}</Typography></Grid>
	            				<Grid item>
	            					<Grid container direction={"row"} justify={"flex-start"} alignItems={"center"}>
	            					{
	            					    (HasPermission(permissions, "view_trip", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={viewTrip} onChange={() => {
                                                            setViewTrip(!viewTrip);
                                                        }} name="viewTrip" />}
	            					    	        label={t("menu.user_management.list")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					{
	            					    (HasPermission(permissions, "add_trip", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={addTrip} onChange={() => {
                                                            setAddTrip(!addTrip);
                                                        }} name="addTrip" />}
	            					    	        label={t("menu.user_management.addition")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					{
	            					    (HasPermission(permissions, "change_trip", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={changeTrip} onChange={() => {
                                                            setChangeTrip(!changeTrip);
                                                        }} name="changeTrip" />}
	            					    	        label={t("menu.user_management.edition")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					{
	            					    (HasPermission(permissions, "delete_trip", user)) && (
	            					    	<Grid item>
		            					    	<FormControlLabel
	            					    	        control={<Switch checked={deleteTrip} onChange={() => {
                                                            setDeleteTrip(!deleteTrip);
                                                        }} name="deleteTrip" />}
	            					    	        label={t("menu.user_management.remove")}
	        					    	      	/>
	            					    	</Grid>
	            					    )
	            					}
	            					</Grid>
	            				</Grid>
	            			</Fragment>
            		    )
            		}
            	</Grid>
            	<Grid item xs={12} className={!downDesktop ? classes.footer : classes.footerMobile}>
            	    <Grid container direction="row" justify={"flex-end"} spacing={2} style={{ width: "100%" }}>
            	        <Grid item>
            	            <Button onClick={reset}>{t("accommodation.cancel")}</Button>
            	        </Grid>
            	        <Grid item>
            	            <Button onClick={checkSave} className={classes.orangeButton} variant="contained">{t("header.save")}</Button>
            	        </Grid>
            	    </Grid>
            	</Grid>
            </Grid>
        </Fragment>
    );
};
export default React.memo(EditUser);
