import { Itinerary } from "../../../Itinerary/objects/itinerary";

export function itineraryToPoints(steps: Itinerary[]): google.maps.LatLng[] {
    const points: google.maps.LatLng[] = [];
    for (const step of steps) {
        const segments = step.r2r_json?.segments ?? [];
        for (let i = 0; i < segments.length; i++) {
            const segment = segments[i]!;

            const isPlane = ['plane', 'air-taxi'].includes(
                step.r2r_json?.data?.vehicles[segment.vehicle]?.kind ?? ''
            );

            let path = segment.path ? google.maps.geometry.encoding.decodePath(segment.path) : [];
            if (isPlane && path.length === 0) {
                path = [
                    new google.maps.LatLng(step.r2r_json?.data?.places[segment.depPlace]?.lat ?? 0, step.r2r_json?.data?.places[segment.depPlace]?.lng),
                    new google.maps.LatLng(step.r2r_json?.data?.places[segment.arrPlace]?.lat ?? 0, step.r2r_json?.data?.places[segment.arrPlace]?.lng)
                ];
            }

            for (let i = 0; i < path.length; i++) {
                points.push(path[i]!);
            }
        }
    }
    return points;
}
