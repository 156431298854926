import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from '@material-ui/core/Grid';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";

import GetHistoryActionLabel from "./Functions/GetHistoryActionLabel";

import CustomAutoComplete from './CustomAutoComplete.js';

import i18n from "../../../i18n.jsx";

import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#000000"
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize20: {
        fontSize: 20
    },
    fontWeight500: {
        fontWeight: 500
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    dateLabel: {
        marginTop: -10,
        marginLeft: 8
    },
    dateLabelShrink: {
        margin: 0
    },
    formControlRoot: {
        "&& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: "bold",
            color: "black"
        },
        "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(18px, 8px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)',
            zIndex: 1
	    }
    },
    formControlRoot1: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: "bold",
            color: "black"
        }
    }
}));

const TripListHistoryFilter = (({ setOpenFilter, setHistory, all_history, setDates, all_dates }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down("sm"));
    const mobile = useMediaQuery(theme.breakpoints.down(768));

    const [userChoices, setUserChoices] = useState([]);
    const [chosenUsers, setChosenUsers] = useState([]);
    const [dateType, setDateType] = useState("");
    const [oneDate, setOneDate] = useState(moment(new Date(), "YYYY-MM-DD"));
    const [oneMonth, setOneMonth] = useState(moment(new Date(), "YYYY-MM-DD"));
    const [startDate, setStartDate] = useState(moment(new Date(), "YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(new Date(), "YYYY-MM-DD"));
    const [actionChoices, setActionChoices] = useState([]);
    const [chosenActions, setChosenActions] = useState([]);

    const inputLabel = useRef(null);
    const labelWidth = inputLabel.current ? inputLabel.current.clientWidth : 0;

    moment.locale(i18n.language);
    const today = moment().utc();

    const handleDateTypeChange = (e) => {
        setDateType(e.target.value);
    };

    const handleSelectAll = (isSelected, type) => {
        if (type === "userTO") {
            setChosenUsers(isSelected ? userChoices : []);
        }
        if (type === "action") {
            setChosenActions(isSelected ? actionChoices : []);
        }
    };

    useEffect(() =>{
        if (all_history.length !== 0) {
            let temp_users = [];
            let temp_actions = [];
            for (date_index = 0; date_index < all_history.length; date_index++) {
                for (action_index = 0; action_index < all_history[date_index].length; action_index++) {
                    if (!temp_users.some(item => item.id === all_history[date_index][action_index].user.id)) {
                        let temp_user = Object.assign({}, all_history[date_index][action_index].user);
                        temp_user.label = temp_user.first_name + " " + temp_user.last_name;
                        temp_users.push(temp_user);
                    }
                    if (!temp_actions.some(item => item.id === all_history[date_index][action_index].action)) {
                        let action_object = GetHistoryActionLabel(t, all_history[date_index][action_index].action);
                        if (action_object !== undefined && action_object.label !== "") {
                            temp_actions.push(action_object);
                        }
                    }
                }
            }
            setUserChoices(temp_users);
            setActionChoices(temp_actions);
        }
    }, [all_history]);

    
    useEffect(() => {
        let temp_history = [];
        let temp_dates = [];

        if (dateType === "today") {
            for (date_index = 0; date_index < all_dates.length; date_index++) {
                if (moment.utc(all_dates[date_index]).format("L") === today.format("L")) {
                    temp_history.push(all_history[date_index]);
                    temp_dates.push(all_dates[date_index]);
                }
            }
        } else if (dateType === "last_week") {
            let one_week = moment().subtract(7, 'days');
            for (date_index = 0; date_index < all_dates.length; date_index++) {
                if (moment.utc(all_dates[date_index]).isAfter(one_week) && moment.utc(all_dates[date_index]).isBefore(today)) {
                    temp_history.push(all_history[date_index]);
                    temp_dates.push(all_dates[date_index]);
                }
            }
        } else if (dateType === "last_month") {
            let one_month = moment().subtract(1, 'month');
            for (date_index = 0; date_index < all_dates.length; date_index++) {
                if (moment.utc(all_dates[date_index]).isBetween(one_month.utc(), today, "days", '[]')) {
                    temp_history.push(all_history[date_index]);
                    temp_dates.push(all_dates[date_index]);
                }
            }
        } else if (dateType === "last_three_months") {
            let months = moment().subtract(3, 'month');
            for (date_index = 0; date_index < all_dates.length; date_index++) {
                if (moment.utc(all_dates[date_index]).isBetween(months.utc(), today, "days", '[]')) {
                    temp_history.push(all_history[date_index]);
                    temp_dates.push(all_dates[date_index]);
                }
            }
        } else if (dateType === "specific_month" && oneMonth !== null) {
            for (date_index = 0; date_index < all_dates.length; date_index++) {
                if (moment.utc(all_dates[date_index]).month() === moment.utc(oneMonth).month()) {
                    temp_history.push(all_history[date_index]);
                    temp_dates.push(all_dates[date_index]);
                }
            }
        } else if (dateType === "specific_date" && oneDate !== null) {
            for (date_index = 0; date_index < all_dates.length; date_index++) {
                if (moment.utc(all_dates[date_index]).format("L") === moment.utc(oneDate).format("L")) {
                    temp_history.push(all_history[date_index]);
                    temp_dates.push(all_dates[date_index]);
                }
            }
        } else if (dateType === "specific_period" && startDate !== null && endDate !== null) {
            for (date_index = 0; date_index < all_dates.length; date_index++) {
                if (moment.utc(all_dates[date_index]).isBetween(startDate.utc(), endDate.utc(), "days", '[]')) {
                    temp_history.push(all_history[date_index]);
                    temp_dates.push(all_dates[date_index]);
                }
            }
        } else if (dateType === "") {
            temp_history = all_history.slice();
            temp_dates = all_dates.slice();
        }


        if (chosenUsers.length !== 0) {
            let temp_filter_history_user = [];
            let temp_filter_dates_user = [];

            for (date_index = 0; date_index < temp_dates.length; date_index++) {
                let temp_history_by_date = [];
                for (action_index = 0; action_index < temp_history[date_index].length; action_index++) {
                    if (chosenUsers.some(item => item.id === temp_history[date_index][action_index].user.id)) {
                        temp_history_by_date.push(temp_history[date_index][action_index]);
                        
                        if (!temp_filter_dates_user.includes(temp_dates[date_index])) {
                            temp_filter_dates_user.push(temp_dates[date_index]);
                        }
                    }
                }
                if (temp_history_by_date.length !== 0) {
                    temp_filter_history_user.push(temp_history_by_date);
                }
            }
            temp_history = temp_filter_history_user;
            temp_dates = temp_filter_dates_user;
        }

        if (chosenActions.length !== 0) {
            let temp_filter_history_action = [];
            let temp_filter_dates_action = [];

            for (date_index = 0; date_index < temp_dates.length; date_index++) {
                let temp_history_by_date = [];
                for (action_index = 0; action_index < temp_history[date_index].length; action_index++) {
                    if (chosenActions.some(item => item.id === temp_history[date_index][action_index].action)) {
                        temp_history_by_date.push(temp_history[date_index][action_index]);
                        
                        if (!temp_filter_dates_action.includes(temp_dates[date_index])) {
                            temp_filter_dates_action.push(temp_dates[date_index]);
                        }
                    }
                }
                if (temp_history_by_date.length !== 0) {
                    temp_filter_history_action.push(temp_history_by_date);
                }
            }
            temp_history = temp_filter_history_action;
            temp_dates = temp_filter_dates_action;
        }
        
        setHistory(temp_history);
        setDates(temp_dates);
    }, [dateType, oneDate, oneMonth, startDate, endDate, chosenUsers, chosenActions]);

    return (
        <div style={ !mobile ? { width: 320 } : {}}>
            <DialogTitle>
                <Grid container justify={ downDesktop === true ? "flex-start" : "space-between" } alignItems={ "center" }>
                    {
                        downDesktop && (
                            <Grid item style={{ marginRight: "5%" }}>
                                <IconButton onClick={() => {
                                    setOpenFilter(false);
                                }} size="small"> <NavigateBeforeIcon /> </IconButton>
                            </Grid>
                        )
                    }
                    <Grid item className={`${classes.genericText} ${!mobile ? classes.fontSize20 : classes.fontSize16}`}>
                        { t("menu.filter.filters") }
                    </Grid>
                    {
                        !downDesktop && (
                            <Grid item>
                                <IconButton onClick={() => {
                                    setOpenFilter(false);
                                    setHistory(all_history);
                                    setDates(all_dates);
                                }} size="small"> <CloseIcon style={{ float: "right" }}/> </IconButton>
                            </Grid>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="date_label" ref={inputLabel} className={`${classes.dateLabel} ${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`} classes={{ shrink: classes.dateLabelShrink }}>
                        {t("menu.history.by_date")}
                    </InputLabel>
                    <Select
                        labelId="date_label"
                        id="date_filter"
                        value={ dateType }
                        className={classes.formControlRoot1}
                        onChange={ handleDateTypeChange }
                        MenuProps={{ disableScrollLock: true }}
                        input={
                            <OutlinedInput
                                name="date"
                                id="date-input"
                                labelWidth={labelWidth}
                            />
                        }
                    >

                        <MenuItem value={"today"}>
                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                                {t("global.today")}
                            </Grid>
                        </MenuItem>
                        <MenuItem value={"last_week"}>
                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                                {t("menu.history.last_week")}
                            </Grid>
                        </MenuItem>
                        <MenuItem value={"last_month"}>
                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                                {t("menu.history.last_month")}
                            </Grid>
                        </MenuItem>
                        <MenuItem value={"last_three_months"}>
                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                                {t("menu.history.last_three_months")}
                            </Grid>
                        </MenuItem>
                        <MenuItem value={"specific_date"}>
                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                                {t("menu.history.specific_date")}
                            </Grid>
                        </MenuItem>
                        <MenuItem value={"specific_month"}>
                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                                {t("menu.history.specific_month")}
                            </Grid>
                        </MenuItem>
                        <MenuItem value={"specific_period"}>
                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12} ${classes.noWrap}`}>
                                {t("menu.history.specific_period")}
                            </Grid>
                        </MenuItem>
                    </Select>
                </FormControl>
                {
                    dateType === "specific_date" && (
                        <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ i18n.language }>
                            <Grid style={{ marginTop: 20, width: "100%" }}>
                                <DatePicker
                                    label={t("menu.history.choose_date")}
                                    value={oneDate}
                                    onChange={(newValue) => setOneDate(newValue)}
                                    views={["year", "month", "date"]}
                                    renderInput={(props) => 
                                        (<TextField
                                            {...props}
                                            label={t("menu.history.choose_date")}
                                            variant={"outlined"}
                                            className={classes.formControlRoot}
                                            helperText={""}
                                            error={false}
                                            fullWidth={true}
                                        />)
                                    }
                                />
                            </Grid>
                        </LocalizationProvider>
                    )
                }
                {
                    dateType === "specific_month" && (
                        <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ i18n.language }>
                            <Grid style={{ marginTop: 20, width: "100%" }}>
                                <DatePicker
                                    label={t("menu.history.choose_month")}
                                    value={oneMonth}
                                    onChange={(newValue) => setOneMonth(newValue)}
                                    views={["year", "month"]}
                                    renderInput={(props) => 
                                        (<TextField
                                            {...props}
                                            label={t("menu.history.choose_month")}
                                            variant={"outlined"}
                                            className={classes.formControlRoot}
                                            helperText={""}
                                            error={false}
                                            fullWidth={true}
                                        />)
                                    }
                                />
                            </Grid>
                        </LocalizationProvider>
                    )
                }
                {
                    dateType === "specific_period" && (
                        <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ i18n.language }>
                            <Grid item container spacing={2} style={{ marginTop: 20 }}>
                                <Grid item style={{ width: "100%" }}>
                                    <DatePicker
                                        label={t("header.responsive.start")}
                                        value={startDate}
                                        onChange={(newValue) => setStartDate(newValue)}
                                        maxDate={endDate}
                                        views={["year", "month", "date"]}
                                        renderInput={(props) => 
                                            (<TextField
                                                {...props}
                                                label={t("header.responsive.start")}
                                                variant={"outlined"}
                                                className={classes.formControlRoot}
                                                helperText={""}
                                                error={false}
                                                fullWidth={true}
                                            />)
                                        }
                                    />
                                </Grid>
                                <Grid item style={{ width: "100%" }}>
                                    <DatePicker
                                        label={t("header.responsive.end")}
                                        value={endDate}
                                        onChange={(newValue) => setEndDate(newValue)}
                                        minDate={startDate}
                                        views={["year", "month", "date"]}
                                        renderInput={(props) => 
                                            (<TextField
                                                {...props}
                                                label={t("header.responsive.end")}
                                                variant={"outlined"}
                                                helperText={""}
                                                className={classes.formControlRoot}
                                                error={false}
                                                fullWidth={true}
                                            />)
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                    )
                }
                {
                    userChoices.length !== 0 && (
                        <Grid style={{ marginTop: 40 }}>
                            <CustomAutoComplete 
                                options={userChoices}
                                values={chosenUsers}
                                type={"userTO"}
                                allSelected={chosenUsers.length === userChoices.length}
                                customSetter={setChosenUsers}
                                handleSelectAll={handleSelectAll}
                                customLabel={t("menu.history.all_users")}
                                label={t("menu.history.by_user")}
                            />
                        </Grid>
                    )
                }
                {
                    actionChoices.length !== 0 && (
                        <Grid style={{ marginTop: 40 }}>
                            <CustomAutoComplete 
                                options={actionChoices}
                                values={chosenActions}
                                type={"action"}
                                allSelected={chosenActions.length === actionChoices.length}
                                customSetter={setChosenActions}
                                handleSelectAll={handleSelectAll}
                                customLabel={t("menu.history.all_actions")}
                                label={t("menu.history.by_action")}
                            />
                        </Grid>
                    )
                }
                <Grid style={{ paddingTop: 25 }} >
                    <Button
                        variant={"outlined"}
                        color={"primary"}
                        fullWidth
                        className={`${classes.fontSize12} ${classes.noWrap}`}
                        onClick={() => {
                            setHistory(all_history);
                            setDates(all_dates);
                            setDateType("");
                            setChosenUsers([]);
                            setChosenActions([]);
                        }}>
                        {t("global.clear")}
                    </Button>
                </Grid>
            </DialogContent>
        </div>
    );
});

export default React.memo(TripListHistoryFilter);
