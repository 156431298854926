import React from "react";
import { SvgIcon } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export function TripListSendIcon(props) {
    return (
        <IconWrapper {...props}>
            <g transform="matrix(1 0 0 1 14.18 8.41)" id="oTA8ofpc44UpJbUMP2kCD" >
                <g vectorEffect="non-scaling-stroke" >
                    <g transform="matrix(1 0 0 1 -175.24 52.04)" id="amdnQChv3OxUdXhWUU4Cy" >
                        <path
                            style={{
                                stroke: 'none',
                                strokeWidth: 1,
                                strokeDasharray: 'none',
                                strokeLinecap: 'butt',
                                strokeDashoffset: 0,
                                strokeLinejoin: 'miter',
                                strokeMiterlimit: 4,
                                fill: 'rgb(16,0,0)',
                                fillRule: 'nonzero',
                                opacity: 1
                            }}
                            vectorEffect="non-scaling-stroke"
                            transform=" translate(157.74, -115.04)"
                            //eslint-disable-next-line max-len
                            d="M -145.235 114.997 C -145.213 121.9 -150.791 127.515 -157.694 127.538 C -164.598 127.56 -170.212 121.982 -170.235 115.079 C -170.258 108.175 -164.679 102.56 -157.776 102.538 C -150.872 102.515 -145.258 108.093 -145.235 114.997 Z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 11.05 -56.49)" id="LnR1uMB5tpt1SWcehrdAL" >
                        <path
                            style={{
                                stroke: 'none',
                                strokeWidth: 1,
                                strokeDasharray: 'none',
                                strokeLinecap: 'butt',
                                strokeDashoffset: 0,
                                strokeLinejoin: 'miter',
                                strokeMiterlimit: 4,
                                fill: 'rgb(16, 0, 0)',
                                fillRule: 'nonzero',
                                opacity: 1
                            }}
                            vectorEffect="non-scaling-stroke"
                            transform=" translate(-28.55, -6.51)"
                            //eslint-disable-next-line max-len
                            d="M 24.9287 11.9398 L 22.8446 12.4467 L 22.8361 9.86091 C 22.8352 9.5957 23.1286 9.73855 23.4205 9.44474 L 30.8956 1.79899 C 31.7213 0.967863 32.5615 0.0863112 33.3927 0.912024 C 34.2238 1.73774 34.6404 2.45831 33.8147 3.28944 L 25.4283 11.8169 C 25.2414 12.0051 25.1939 11.9389 24.9287 11.9398 Z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 12.6 -50.4)" id="-kShqKz_eYax0_f9lRI_X" >
                        <path
                            style={{
                                stroke: 'rgb(0,0,0)',
                                strokeWidth: 0.2,
                                strokeDasharray: 'none',
                                strokeLinecap: 'round',
                                strokeDashoffset: 0,
                                strokeLinejoin: 'miter',
                                strokeMiterlimit: 4,
                                fill: 'none',
                                fillRule: 'nonzero',
                                opacity: 1
                            }}
                            vectorEffect="non-scaling-stroke"
                            transform=" translate(-30.1, -12.6)"
                            d="M 26.4312 12.7011 C 27.5523 10.6297 29.4907 12.7514 30.5956 13.2381 C 31.7794 13.7596 32.8339 12.7496 33.7641 12.2807"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 8.31 -48.81)" id="lo656SQ2EgA2KpD8-PCFN" >
                        <path
                            style={{
                                stroke: 'rgb(0,0,0)',
                                strokeWidth: 0.2,
                                strokeDasharray: 'none',
                                strokeLinecap: 'round',
                                strokeDashoffset: 0,
                                strokeLinejoin: 'miter',
                                strokeMiterlimit: 4,
                                fill: 'none',
                                fillRule: 'nonzero',
                                opacity: 1
                            }}
                            vectorEffect="non-scaling-stroke"
                            transform=" translate(-25.81, -14.19)"
                            d="M 22.6353 14.1003 C 23.2626 13.4689 23.8222 13.2157 24.6159 13.6093 C 25.2133 13.9056 25.605 14.3932 26.1171 14.6836 C 26.9998 15.1842 28.5203 15.0032 28.9783 14.0796"
                            strokeLinecap="round"
                        />
                    </g>
                </g>
            </g>
            <g transform="matrix(0 0 0 0 11.72 11.44)" id="WTPXRGLzX9X9aagyYEoqi" >
                <g vectorEffect="non-scaling-stroke" >
                    <g transform="matrix(1 0 0 1 -129.56 -29.71)" id="CTgBRO5UmXeg-tNe1pQsp" >
                        <path
                            style={{
                                stroke: 'none',
                                strokeWidth: 1,
                                strokeDasharray: 'none',
                                strokeLinecap: 'butt',
                                strokeDashoffset: 0,
                                strokeLinejoin: 'miter',
                                strokeMiterlimit: 4,
                                fill: 'rgb(137, 67, 156)',
                                fillRule: 'nonzero',
                                opacity: 1
                            }}
                            vectorEffect="non-scaling-stroke"
                            transform=" translate(-170.44, -270.29)"
                            d="M 137.531 219.225 L 137.531 321.356 L 81.918 321.356 L 81.918 219.225 L 137.531 219.225 z M 258.962 321.355 L 258.962 219.225 L 238.423 219.225 L 238.423 321.356 L 258.962 321.356 z"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 0 0)" id="n6afZ7jTEeO5ahFgfZOwE" >
                        <path
                            style={{
                                stroke: 'none',
                                strokeWidth: 1,
                                strokeDasharray: 'none',
                                strokeLinecap: 'butt',
                                strokeDashoffset: 0,
                                strokeLinejoin: 'miter',
                                strokeMiterlimit: 4,
                                fill: 'rgb(169, 89, 162)',
                                fillRule: 'nonzero',
                                opacity: 1
                            }}
                            vectorEffect="non-scaling-stroke"
                            transform=" translate(-300, -300)"
                            //eslint-disable-next-line max-len
                            d="M 518.082 409.614 C 518.082 511.68199999999996 437.755 594.987 336.869 599.776 C 333.829 599.918 330.77200000000005 600 327.692 600 C 225.616 600 142.31900000000002 519.677 137.53 418.791 L 137.53 321.355 L 137.53 219.225 L 137.53 158.059 L 91.154 158.059 C 83.714 158.059 79.33699999999999 149.699 83.58 143.584 L 180.399 3.967 C 184.068 -1.323 191.886 -1.323 195.556 3.967 L 292.375 143.584 C 296.618 149.699 292.242 158.059 284.801 158.059 L 238.42499999999998 158.059 L 238.42499999999998 219.225 L 238.42499999999998 321.356 L 238.42499999999998 415.626 L 239.439 416.37199999999996 C 242.896 462.10799999999995 281.074 498.15299999999996 327.69399999999996 498.15299999999996 C 329.972 498.15299999999996 332.21899999999994 498.039 334.455 497.866 C 380.191 494.409 416.23199999999997 456.235 416.23199999999997 409.615 C 416.23199999999997 407.333 416.118 405.086 415.94899999999996 402.85 C 412.49199999999996 357.11400000000003 374.31399999999996 321.07300000000004 327.69399999999996 321.07300000000004 C 325.991 321.07300000000004 316.90999999999997 321.35600000000005 316.90999999999997 321.35600000000005 L 258.96399999999994 321.35600000000005 L 258.96399999999994 219.225 C 258.96399999999994 219.225 325.99699999999996 219.225 327.69399999999996 219.225 C 429.765 219.225 513.0709999999999 299.548 517.8599999999999 400.43399999999997 C 518 403.474 518.082 406.534 518.082 409.614 z"
                            strokeLinecap="round"
                        />
                    </g>
                </g>
            </g>
            <g transform="matrix(1.71 0 0 1.71 12.03 12.01)" id="E6LkuUDLaoDOPHj0eA9pX" >
                <g vectorEffect="non-scaling-stroke" >
                    <g transform="matrix(1 0 0 1 -1.78 0.31)" id="S-ngbozpHvHGRkX5NYFK4" >
                        <path
                            style={{
                                stroke: 'rgb(74,75,87)',
                                strokeWidth: 2,
                                strokeDasharray: 'none',
                                strokeLinecap: 'round',
                                strokeDashoffset: 0,
                                strokeLinejoin: 'round',
                                strokeMiterlimit: 4,
                                fill: 'none',
                                fillRule: 'nonzero',
                                opacity: 1
                            }}
                            vectorEffect="non-scaling-stroke"
                            transform=" translate(-5.22, -7.31)"
                            d="M 3.35001 7.31433 L 7.09999 7.30207"
                            strokeLinecap="round"
                        />
                    </g>
                    <g transform="matrix(1 0 0 1 0.09 0.32)" id="4X5UMj3Y9RdAf8ecdIfhh" >
                        <path
                            style={{
                                stroke: 'rgb(74,75,87)',
                                strokeWidth: 2,
                                strokeDasharray: 'none',
                                strokeLinecap: 'round',
                                strokeDashoffset: 0,
                                strokeLinejoin: 'round',
                                strokeMiterlimit: 4,
                                fill: 'none',
                                fillRule: 'nonzero',
                                opacity: 1
                            }}
                            vectorEffect="non-scaling-stroke"
                            transform=" translate(-7.09, -7.32)"
                            d="M 12.725 7.28361 L 1.45663 1.69543 L 3.08217 7.31515 L 1.49343 12.9454 L 12.725 7.28361 Z"
                            strokeLinecap="round"
                        />
                    </g>
                </g>
            </g>
        </IconWrapper>
    );
}

const IconWrapper = styled(SvgIcon)(() => ({
    padding: 2.5
}));
