import { ProviderQuotationProductStatus } from "../objects/providerQuotationProductStatus";

type Product = {
    agent_accepted: boolean,
    agent_refused: boolean,
    provider_accepted: boolean,
    provider_refused: boolean
}

export function getProviderQuotationStatus(product: Product): ProviderQuotationProductStatus {
    let agent: ProviderQuotationProductStatus['agent'] = null;
    let provider: ProviderQuotationProductStatus['provider'] = null;

    if (product.agent_accepted) {
        agent = 'accepted';
    } else if (product.agent_refused) {
        agent = 'refused';
    }

    if (product.provider_accepted) {
        provider = 'accepted';
    } else if (product.provider_refused) {
        provider = 'refused';
    }

    return { agent, provider };
}

export function reverseProviderQuotationStatus(status: ProviderQuotationProductStatus): Product {
    const product: Product = {
        agent_accepted: false,
        agent_refused: false,
        provider_accepted: false,
        provider_refused: false
    };

    if (status.agent === 'accepted') {
        product.agent_accepted = true;
        product.agent_refused = false;
    } else if (status.agent === 'refused') {
        product.agent_accepted = false;
        product.agent_refused = true;
    }

    if (status.provider === 'accepted') {
        product.provider_accepted = true;
        product.provider_refused = false;
    } else if (status.provider === 'refused') {
        product.provider_accepted = false;
        product.provider_refused = true;
    }

    return product;
}
