/*eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import CrmDemandField from './CrmDemandField';

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

const CrmDemandDescription = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const language = useSelector((store) => store.header.tmp_language);
    const selected_demand = useSelector(store => store.crm.selected_demand);
    const user_client = useSelector(store => store.user.user);

    const getStatus = (value) => {
        return t(`menu.trip_list.${value}`);
    };

    if (selected_demand) {
        return (
            <div className="contact-field-container">
                <CrmDemandField label={t("crm.demand.budget")} expandable={true}
                    value={new Intl.NumberFormat(language, { style: 'currency', currency: selected_demand.currency?.iso_code ? selected_demand.currency.iso_code : "EUR" })
                        .format(Math.ceil(parseFloat((selected_demand.data?.length && selected_demand.data[selected_demand.data.length - 1]?.budget ? selected_demand.data[selected_demand.data.length - 1].budget : "0"))))} />
                <CrmDemandField label={t("crm.demand.status")} expandable={true}
                    value={selected_demand.data?.length && selected_demand.data[selected_demand.data.length - 1]?.status ? getStatus(selected_demand.data[selected_demand.data.length - 1].status) : null} />
                <CrmDemandField label={t("crm.demand.accounting_reference")} value={selected_demand.accounting_reference} expandable={true} />
                <CrmDemandField label={t("crm.demand.destination")} expandable={true}
                    value={(selected_demand.data?.length && selected_demand.data[selected_demand.data.length - 1]?.destination?.data?.international_name)
                        ? selected_demand.data[selected_demand.data.length - 1].destination.data.international_name : null} />
                <CrmDemandField label={t("crm.demand.start_date")} is_date={true} expandable={true}
                    value={selected_demand.data?.length && selected_demand.data[selected_demand.data.length - 1]?.start_date ? selected_demand.data[selected_demand.data.length - 1].start_date : null} />
                <CrmDemandField label={t("crm.demand.end_date")} is_date={true} expandable={true}
                    value={selected_demand.data?.length && selected_demand.data[selected_demand.data.length - 1]?.end_date ? selected_demand.data[selected_demand.data.length - 1].end_date : null} />
                <CrmDemandField label={t("crm.demand.validity_date")} is_date={true} expandable={true}
                    value={selected_demand.data?.length && selected_demand.data[selected_demand.data.length - 1]?.validity_date ? selected_demand.data[selected_demand.data.length - 1].validity_date : null} />
            </div>
        );
    }
    return null;
};
export default CrmDemandDescription;
