//---- Dependencies ----//
import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import DirectionIcon from './Icons/DirectionIcon';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { AddHistorydest, SetItineraryTypeDetailsDataAndDayAndNight } from '../../Actions/ItineraryType';
import axios from 'axios';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import VisiteursMoreInfo from '../VisiteursHome/VisiteursMoreInfo';
import localeText from "../Functions/localeText";

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    cardContainer: {
        "width": "100%",
        "height": "auto",
        "boxShadow": "1px 1px 3px rgb(0 0 0 / 10%)",
        "overflow": "hidden",
        "transition": "transform .2s",
        "&:hover": {
            "&& .mui-jss-MuiCardActionArea-root": {
                transform: "scale(1.2)"
            },
            "&& .mui-jss-MuiCardActions-root": {
                backgroundColor: `${main_color}`,
                color: "white !important",
                transition: "all .2s ease",
                transformOrigin: "top"
            },
            "&& .lower-price": {
                color: "white !important"
            }
        }
    },
    cardContainerV: {
        "width": "100%",
        "height": "auto",
        "boxShadow": "1px 1px 3px rgb(0 0 0 / 10%)",
        "overflow": "hidden",
        "transition": "transform .2s",
        "&:hover": {
            "&& .mui-jss-MuiCardActionArea-root": {
                transform: "scale(1.2)"
            },
            "&& .mui-jss-MuiCardActions-root": {
                backgroundColor: "#BF202D",
                color: "white !important",
                transition: "all .2s ease",
                transformOrigin: "top"
            },
            "&& .lower-price": {
                color: "white !important"
            }
        }
    },
    cardImg: {
        height: "auto",
        zIndex: 1,
        position: "relative",
        transition: "all .6s ease-out",
        display: "block",
        width: "100%",
        objectFit: "cover"
    },
    cardTitle: {
        width: "100%",
        background: "white",
        height: "auto",
        display: "flex",
        zIndex: 2,
        padding: "5px",
        position: "relative",
        cursor: "pointer"
    },
    pink: {
        color: "#E0368D"
    }
}));

const MaterialItiTypeCard = ({ iti_info, router, desti_id }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const config = JSON.parse(localStorage.getItem("config"));
    const quotation_code = config.quotation_code;
    const locales = useSelector(state => state.user.locales);

    // const [itinerary_list, setItineraryList] = useState([]);
    const [totalNights, setTotalNights] = useState(null);
    const [totalDays, setTotalDays] = useState(null);
    
    const [itinerary_list, setItineraryList] = useState([]);
    const [moreInfoModal, setMoreInfoModal] = useState(false);

    const current_locale = locales.find((el) => {
        return el.language_code === i18n.language;
    });
    useEffect(() => {
        const { pass_check, headers } = CheckBeforeRequest();
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if (pass_check) {
            let data = iti_info.data.find(el => el.id === iti_info.current_version);
            if (data.start_date !== null && data.end_date !== null) {
                setTotalDays(moment.utc(data.end_date).diff(moment.utc(data.start_date), "d") + 1);
            }
            if (data.nights !== undefined && data.nights !== null) {
                setTotalNights(data.nights);
            } else {
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${iti_info.id}/versions/${iti_info.current_version}/itinerary/get_nb_nights/?token=${iti_info.token}`,
                    cancelToken: source.token
                }).then(function (response) {
                    setTotalNights(response.data.nights);
                }).catch(function (error) {
                    if (axios.isCancel(error)) {
                        console.log("cancelled");
                    } else {
                        console.log('error itinerary:', error);
                    }
                });
            }
        }
        return () => {
            source.cancel();
        };
    }, []);

    const replaceAll = (str, find, replace) => {
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    };
    const escapeRegExp = (str) => {
        return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    };
    const handleMoreInfo = () => {
        setMoreInfoModal(!moreInfoModal);
    };
    let cover_picture = null;
    if (iti_info !== null && iti_info.pictures !== null && iti_info.pictures.length !== 0) {
        cover_picture = iti_info.pictures[0];
    }
    let destination = null;
    if (iti_info.data.length !== 0 && iti_info.data[0].destination !== undefined && iti_info.data[0].destination !== null) {
        destination = localeText(current_locale.id, iti_info.data[0].destination.data.localization, iti_info.data[0].destination.data.international_name !== undefined ? iti_info.data[0].destination.data.international_name : iti_info.data[0].destination.data.name, 'name').split(',');
    }
    return (
        <Grid item sm={['visiteurs'].includes(quotation_code) ? 3 : 4} xs={12}>
            <Card className={`${quotation_code === 'visiteurs' ? classes.cardContainerV : classes.cardContainer} ${classes.genericText}`}>
                <CardActionArea className={classes.cardImg} onClick={() => {
                    //dispatch(SetTripToDuplicateAndOpenDuplicateModalAndSetStep(iti, true, 1));
                    let tmp_obj = {
					    name: iti_info.name,
					    data: iti_info,
					    need_redirect: true
                    };
                    dispatch(AddHistorydest(tmp_obj));
                    dispatch(SetItineraryTypeDetailsDataAndDayAndNight(iti_info, 0, 0));
                    let a = replaceAll(iti_info.name, ' ', '-');
                    let clean_name = replaceAll(a, '/', '');
                    console.log('clean_name:', clean_name);
                    router.push(`/${window.url_name}/itinerary-type-details/${encodeURIComponent(clean_name)}/${iti_info.id}`);
                }}>
                    <CardMedia
                        image={iti_info !== null ? ((cover_picture !== null) ? (cover_picture.thumbnail_big !== null ? cover_picture.thumbnail_big : cover_picture.url) : "/Img/default_dest1.jpeg") : "/Img/default_dest1.jpeg"} className={"ft-cover-img ft-card-img"}
                        title={ iti_info.name !== undefined ? iti_info.name : "-"}
                    />
                </CardActionArea>
                <CardActions className={classes.cardTitle}>
                    <Grid container direction={"column"} justify={"center"} alignItems={"flex-start"}>
                        <Grid item style={{ width: "100%" }}>
                            <Grid container direction={"column"} justify={"space-between"}>
                                <Grid item xs={12}>
                                    {
                                        iti_info.name !== undefined &&
                                        <Tooltip title={iti_info.name}>
                                            <Typography variant="body2" noWrap component="p" style={{ fontWeight: "bold", fontSize: 16, paddingLeft: 4 }}>
                                            { iti_info.name !== undefined ? iti_info.name : "-"}
                                            </Typography>
                                        </Tooltip>
                                    }
                                </Grid>
                                {
                                    destination !== undefined && destination !== null && (
                                        <Grid item xs={12}>
                                            <Typography variant="body2" component="p" style={{ fontSize: 14, textAlign: "left", paddingLeft: 4 }}>
                                                { destination[0]}
                                            </Typography>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                        <Grid item>
                            <DirectionIcon fontSize={"small"}/>
                            <span>  { t("itineraryType.itinerary_type") } <InfoOutlinedIcon style={{ marginLeft: 5, verticalAlign: 'bottom' }} onClick={handleMoreInfo}/></span>
                        </Grid>
                        <Grid item xs={12}><br/></Grid>
                        <Grid item style={{ width: "100%" }}>
                            <Grid container direction={"row"} justify={"space-between"} alignItems={"center"} spacing={fullScreen ? 1 : 0}>
                                <Grid item sm={7} xs={12}>
        							<span style={{ paddingLeft: 4, fontSize: 14 }}> { totalDays + " " + (totalDays > 1 ? t("global.days") : t("global.day")) } / { totalNights + " " + (totalNights > 1 ? t("itinerary.nights") : t("itinerary.night")) } </span>
                                </Grid>
                                {
								    // iti_info.data.length !== 0 && parseFloat(iti_info.data[0].total_cost) > 0 && (
                                    //     <Grid item sm={5} xs={12}>
                                    //         <span style={{paddingLeft: fullScreen ? 4 : 0}} >{ t("global.starting_from") + " "}</span>
                                    //         <span>{Math.round(iti_info.data[0].total_cost) + " " + iti_info.currency.symbol }</span>
                                    //     </Grid>
                                    // )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
            <VisiteursMoreInfo info={iti_info} moreInfoModal={moreInfoModal} setMoreInfoModal={setMoreInfoModal} type={'itiType'}/>
        </Grid>
    );
};
export default React.memo(MaterialItiTypeCard);
